import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/codigo-situacao-tributaria-pis-cofins-auto-complete';

interface ICodigoSituacaoTributariaPisCofinsAutoCompleteComunicadorIndex {
  params: any;
}

class CodigoSituacaoTributariaPisCofinsAutoCompleteComunicador {
  public async index(
    params: ICodigoSituacaoTributariaPisCofinsAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoSituacaoTributariaPisCofinsAutoCompleteComunicador();
