import React from 'react';
import Divisor from '../../../../../../Componentes/Divisor';
import ConhecimentoTransporteTerceiroServicoHook from './ListaDetalheServico/Hooks';
import ListaDetalheServico from './ListaDetalheServico/Lista';

interface IServicoTab {
  bloquearCamposPorImportacao: boolean;
}

const ServicoTab: React.FC<IServicoTab> = ({ bloquearCamposPorImportacao }) => {
  return (
    <div>
      <Divisor>
        <ConhecimentoTransporteTerceiroServicoHook>
          <ListaDetalheServico
            name="listaConhecimentoTransporteTerceiroServico"
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </ConhecimentoTransporteTerceiroServicoHook>
      </Divisor>
    </div>
  );
};

export default ServicoTab;
