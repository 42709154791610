import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/regime-tributario';

interface IEmpresaVigenciaRegimeTributarioComunicadorShow {
  id: string;
}

interface IEmpresaVigenciaRegimeTributarioComunicadorStore {
  params: any;
}

interface IEmpresaVigenciaRegimeTributarioComunicadorUpdate {
  id: string;
  params: any;
}

interface IEmpresaVigenciaRegimeTributarioComunicadorDeletar {
  id: string;
}

class EmpresaVigenciaRegimeTributarioComunicador {
  public async show(
    params: IEmpresaVigenciaRegimeTributarioComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IEmpresaVigenciaRegimeTributarioComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IEmpresaVigenciaRegimeTributarioComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEmpresaVigenciaRegimeTributarioComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmpresaVigenciaRegimeTributarioComunicador();
