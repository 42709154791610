import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import DemonstrativoResultadoComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/DemonstrativoResultado/Comunicador/DemonstrativoResultadoComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';

export interface IListaItem {
  id: string;
  descricao: string;
  codigo: string;
  idDemonstrativoResultadoPai?: string;
  filhos?: IListaItem[];
  isOpen?: boolean;
}

interface IExcluirItemParametros {
  id: string;
  hasFilhos: boolean;
}

interface ITreeContextProps {
  idShowItem: string;
  setIdShowItem: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  setIdItemPai: React.Dispatch<React.SetStateAction<string>>;
  idItemPai: string;
  excluirItem: ({ id, hasFilhos }: IExcluirItemParametros) => Promise<void>;
  listaDados: IListaItem[];
  buscarDados: () => Promise<void>;
  refresh: () => void;
}

const TreeContext = createContext({} as ITreeContextProps);

export const TreeContextProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [listaDados, setListaDados] = useState<IListaItem[]>([]);
  const [idShowItem, setIdShowItem] = useState({ id: '' });
  const [idItemPai, setIdItemPai] = useState('');
  const { abrirJanela } = UseConfirmacao();

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleBuscarDados = useCallback(async (parametros?: any) => {
    const response = await DemonstrativoResultadoComunicador.index({
      params: { ...parametros },
    });

    setListaDados([...response.listaRegistro]);
  }, []);

  const handleExcluirItem = useCallback(
    async ({ id, hasFilhos }: IExcluirItemParametros): Promise<void> => {
      if (!id) return;

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            {hasFilhos &&
              'Ao excluir esse registro todos os registros filhos também serão excluídos. '}
            Deseja excluir o item?
          </span>
        ),
      });

      if (!resposta) {
        return;
      }

      try {
        await DemonstrativoResultadoComunicador.delete({
          id,
        });
        ToastSucesso('Registro Deletado!');

        setIdShowItem({ id: '' });
      } catch (error) {
        TratarErros(error);
      }

      await handleBuscarDados();

      setIdItemPai('');
      setIdShowItem({ id: '' });
    },
    [abrirJanela, handleBuscarDados]
  );

  useEffect(() => {
    (async () => {
      await handleBuscarDados();
    })();
  }, [handleBuscarDados]);

  return (
    <TreeContext.Provider
      value={{
        idShowItem: idShowItem.id,
        setIdShowItem,
        excluirItem: handleExcluirItem,
        listaDados,
        buscarDados: handleBuscarDados,
        setIdItemPai,
        idItemPai,
        refresh,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export const UseTreeContext = (): ITreeContextProps => {
  const context = useContext(TreeContext);
  return context;
};
