import { ITransferenciaEntrePortadoresValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompletePortador from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<ITransferenciaEntrePortadoresValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroMovimentacoesTransferenciaEntrePortadores: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const [listaRemoverPortadorOrigem, setListaRemoverPortadorOrigem] = useState<
    string[]
  >([]);

  const [listaRemoverPortadorDestino, setListaRemoverPortadorDestino] =
    useState<string[]>([]);

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleChangePortador = useCallback(
    (item: any, isPortadorOrigem: boolean) => {
      if (isPortadorOrigem) {
        if (!item) {
          setListaRemoverPortadorDestino([]);
          return;
        }
        setListaRemoverPortadorDestino((prevState) => [...prevState, item.id]);
      } else {
        if (!item) {
          setListaRemoverPortadorOrigem([]);
          return;
        }
        setListaRemoverPortadorOrigem((prevState) => [...prevState, item.id]);
      }
    },
    []
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <InputHiddenHtml name="percentualLiquidado" />
          <Row>
            <Col xl={2} lg={12} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Número Documento"
                name="numeroDocumento"
                placeholder="Número Documento"
              />
            </Col>

            <Col xl={3} lg={12} sm={12}>
              <InputDateTime label="Data e hora" name="dataHora" />
            </Col>
            <Col xl={2} lg={12} sm={12}>
              <InputDecimal
                name="valor"
                label="Valor"
                casasDecimais={2}
                casasInteiras={18}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12} sm={12}>
              <InputAutoCompletePortador
                name="movimentoPortadorOrigem.idPortador"
                label="(-) Portador de Origem"
                placeholder="Portador de Origem"
                nomeObjeto="movimentoPortadorOrigem.portador"
                listaIdParaRemover={listaRemoverPortadorOrigem}
                onChangeItemAtual={(event) => {
                  handleChangePortador(event.itemAtual, true);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12} sm={12}>
              <InputAutoCompletePortador
                name="movimentoPortadorDestino.idPortador"
                label="(+) Portador de Destino"
                placeholder="Portador de Destino"
                nomeObjeto="movimentoPortadorDestino.portador"
                listaIdParaRemover={listaRemoverPortadorDestino}
                onChangeItemAtual={(event) => {
                  handleChangePortador(event.itemAtual, false);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12} sm={12}>
              <Textarea
                name="observacoes"
                label="Observações"
                maxLength={500}
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
