import ILogPesquisaParametro from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-producao/log';

interface IOrdemProducaoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IOrdemProducaoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class OrdemProducaoLogComunicador {
  public async index(
    params: IOrdemProducaoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IOrdemProducaoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new OrdemProducaoLogComunicador();
