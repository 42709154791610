"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoOrcamentoEnum;
(function (SituacaoOrcamentoEnum) {
    SituacaoOrcamentoEnum["pendente"] = "Pendente";
    SituacaoOrcamentoEnum["aprovado"] = "Aprovado";
    SituacaoOrcamentoEnum["rejeitado"] = "Rejeitado";
    SituacaoOrcamentoEnum["cancelado"] = "Cancelado";
    SituacaoOrcamentoEnum["aprovadoPendente"] = "Pendente e Aprovado";
    SituacaoOrcamentoEnum["rejeitadoPendente"] = "Pendente e Rejeitado";
    SituacaoOrcamentoEnum["aprovadoRejeitado"] = "Aprovado e Rejeitado";
    SituacaoOrcamentoEnum["pendenteAprovadoRejeitado"] = "Pendente, Aprovado e Rejeitado";
})(SituacaoOrcamentoEnum || (SituacaoOrcamentoEnum = {}));
exports.default = SituacaoOrcamentoEnum;
