import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/cest-auto-complete-tag';

interface ICestAutoCompleteTagComunicadorIndex {
  params: any;
}

class CestAutoCompleteTagComunicador {
  public async index(
    params: ICestAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CestAutoCompleteTagComunicador();
