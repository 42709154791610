import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/padronizacao-descricao/atributo';
const rotaNovo = 'suprimentos/produtos/padronizacao-descricao';

interface IPadronizacaoDescricaoAtributoComunicadorShow {
  id: string;
}

interface IPadronizacaoDescricaoAtributoComunicadorStore {
  idPai: string;
  params: any;
}

interface IPadronizacaoDescricaoAtributoComunicadorUpdate {
  id: string;
  params: any;
}

interface IPadronizacaoDescricaoAtributoComunicadorDelete {
  id: string;
}

class PadronizacaoDescricaoAtributoComunicador {
  public async show(
    params: IPadronizacaoDescricaoAtributoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IPadronizacaoDescricaoAtributoComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/atributo`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IPadronizacaoDescricaoAtributoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IPadronizacaoDescricaoAtributoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PadronizacaoDescricaoAtributoComunicador();
