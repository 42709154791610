import api from '../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/centro-custo';

interface ICentroCustoComunicadorIndex {
  params?: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ICentroCustoComunicadorShow {
  id: string;
}

interface ICentroCustoComunicadorDelete {
  id: string;
}

interface ICentroCustoComunicadorStore {
  params: any;
}

interface ICentroCustoComunicadorUpdate {
  id: string;
  params: any;
}

class CentroCustoComunicador {
  public async index(params: ICentroCustoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ICentroCustoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(params: ICentroCustoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(params: ICentroCustoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(params: ICentroCustoComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new CentroCustoComunicador();
