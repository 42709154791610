import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/documento-anterior';

interface IConhecimentoTransporteDocumentoAnteriorComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteDocumentoAnteriorComunicadorDelete {
  id: string;
}

class ConhecimentoTransporteDocumentoAnteriorComunicador {
  public async store(
    params: IConhecimentoTransporteDocumentoAnteriorComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteDocumentoAnteriorComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteDocumentoAnteriorComunicador();
