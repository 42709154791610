"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoEventoEnum;
(function (SituacaoEventoEnum) {
    SituacaoEventoEnum["naoTransmitida"] = "N\u00E3o Transmitida";
    SituacaoEventoEnum["autorizada"] = "Autorizada";
    SituacaoEventoEnum["rejeitada"] = "Rejeitada";
})(SituacaoEventoEnum || (SituacaoEventoEnum = {}));
exports.default = SituacaoEventoEnum;
