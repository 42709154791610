"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoTransportadorEnum;
(function (TipoTransportadorEnum) {
    TipoTransportadorEnum["etc"] = "1 - ETC";
    TipoTransportadorEnum["tac"] = "2 - TAC";
    TipoTransportadorEnum["ctc"] = "3 - CTC";
})(TipoTransportadorEnum || (TipoTransportadorEnum = {}));
exports.default = TipoTransportadorEnum;
