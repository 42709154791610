/* eslint-disable default-case */
import {
  IImpressaoEtiquetasPedidoVendaItemLista,
  IImpressaoEtiquetasPedidoVendaItemImpressao,
  FormatarDataParaPtBr,
  TipoEtiquetaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import { UseImpressaoEtiquetasPedidoVendaItemLista } from '../Hooks/ImpressaoEtiquetasPedidoVendaItemListaHook';
import AcoesImpressaoEtiquetasPedidoVendaItem from '../AcoesImpressaoEtiquetasPedidoVendaItem';
import InputTabelaInteiro from '../../../../../Componentes/Inputs/InputTabela/InputTabelaInteiro';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteEtiqueta from '../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteEtiqueta';
import InputTabelaAutoCompleteTransportador from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Comercial/InputTabelaAutoCompleteTransportador';
import ImpressaoEtiquetasPedidoVendaObterDadosPadraoComunicador from '../../../../../Comunicador/Comercial/Vendas/ImpressaoEtiquetasPedidoVenda/Comunicador/ImpressaoEtiquetasPedidoVendaObterDadosPadraoComunicador';
import ImpressaoEtiquetasPedidoVendaItemComunicador from '../../../../../Comunicador/Comercial/Vendas/ImpressaoEtiquetasPedidoVendaItem/Comunicador/ImpressaoEtiquetasPedidoVendaItemComunicador';

interface IControlarFocusParametros {
  index: number;
  nomeCampo: string;
  listaTh: ITh[];
  listaItens: IImpressaoEtiquetasPedidoVendaItemLista[];
}

interface IControlarFocusRetorno {
  linhaIndexAnterior: number;
  linhaIndexProxima: number;
  listaRefAnterior: any;
  listaRefProxima: any;
}

type IProps = {
  idPedidoVendaEtiquetaSelecionada?: string | null;
};

const ImpressaoEtiquetasPedidoVendaItemLista: React.FC<IProps> = ({
  idPedidoVendaEtiquetaSelecionada,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasImpressaoEtiquetasPedidoVendaItem: permissao } =
    permissoes;

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    listaQuantidadeRef,
    listaTransportadoraRef,
  } = UseImpressaoEtiquetasPedidoVendaItemLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [carregarDados, setCarregarDados] = useState(false);
  const [listaColunas, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const formRef = useRef<IFormCiaHandles>(null);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleCarregarDadosPadrao = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response =
        await ImpressaoEtiquetasPedidoVendaObterDadosPadraoComunicador.index();

      formRef.current?.setDataInicial({
        etiqueta: response.etiqueta,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleCarregarDadosPadrao();
  }, [handleCarregarDadosPadrao]);

  const handleControlarFocus = useCallback(
    ({
      index,
      nomeCampo,
      listaTh,
      listaItens,
    }: IControlarFocusParametros): IControlarFocusRetorno | null => {
      const listaColunasSelecionaveis = listaTh.filter(
        (coluna) => coluna.visivel && coluna.nomeCampo === 'quantidade'
      );

      if (
        !listaColunasSelecionaveis ||
        listaColunasSelecionaveis.length === 0
      ) {
        return null;
      }

      const colunaIndexAtual = listaColunasSelecionaveis.findIndex(
        (coluna) => coluna.nomeCampo === nomeCampo
      );

      let linhaIndexAnterior = index;
      let colunaIndexAnterior = colunaIndexAtual;
      let listaRefAnterior: any;

      do {
        colunaIndexAnterior -= 1;
        if (colunaIndexAnterior < 0) {
          linhaIndexAnterior -= 1;
          if (linhaIndexAnterior < 0) {
            linhaIndexAnterior = listaItens.length - 1;
          }

          colunaIndexAnterior = listaColunasSelecionaveis.length - 1;
        }

        switch (listaColunasSelecionaveis[colunaIndexAnterior].nomeCampo) {
          case 'quantidade':
            listaRefAnterior = listaQuantidadeRef;
            break;
        }
      } while (!listaRefAnterior.current[linhaIndexAnterior]);

      let linhaIndexProxima = index;
      let colunaIndexProxima = colunaIndexAtual;
      let listaRefProxima: any;

      do {
        colunaIndexProxima += 1;
        if (colunaIndexProxima >= listaColunasSelecionaveis.length) {
          linhaIndexProxima += 1;
          if (linhaIndexProxima >= listaItens.length) {
            linhaIndexProxima = 0;
          }

          colunaIndexProxima = 0;
        }

        switch (listaColunasSelecionaveis[colunaIndexProxima].nomeCampo) {
          case 'quantidade':
            listaRefProxima = listaQuantidadeRef;
            break;
        }
      } while (!listaRefProxima.current[linhaIndexProxima]);

      return {
        linhaIndexAnterior,
        linhaIndexProxima,
        listaRefAnterior,
        listaRefProxima,
      };
    },
    [listaQuantidadeRef]
  );

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (
          item &&
          checked &&
          listaQuantidadeRef.current &&
          listaQuantidadeRef.current[index].value.ConverterParaNumber() > 0
        ) {
          item.checked = checked;
        } else if (item && !checked) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef, listaQuantidadeRef]
  );

  const handleObterItensSelecionados = useCallback(async (): Promise<
    IImpressaoEtiquetasPedidoVendaItemImpressao[]
  > => {
    const listaIndexItemSelecionado: number[] = [];
    const listaItemSelecionado: IImpressaoEtiquetasPedidoVendaItemImpressao[] =
      [];

    const etiqueta = formRef.current?.getFieldValueNomeObjeto('etiqueta');

    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (
        item &&
        item.checked &&
        listaQuantidadeRef.current &&
        listaQuantidadeRef.current[index] &&
        listaQuantidadeRef.current[index].value.ConverterParaNumber() > 0
      ) {
        listaIndexItemSelecionado.push(index);
      }
    });

    listaIndexItemSelecionado.forEach((index) => {
      const retorno: IImpressaoEtiquetasPedidoVendaItemImpressao = {
        selecionado: true,
        id: listaValor[index].id,

        quantidade:
          listaQuantidadeRef.current && listaQuantidadeRef.current[index]
            ? listaQuantidadeRef.current[index].value.ConverterParaNumber()
            : 0,

        produto: {
          codigo: listaValor[index].produto.codigo,
          descricao: listaValor[index].produto.descricao,

          produtoEngenharia: {
            codigoBarras:
              listaValor[index].produto.produtoEngenharia.codigoBarras,
          },
        },

        pedidoVenda: {
          numero: listaValor[index].pedidoVenda.numero,
          dataHoraEmissao: listaValor[index].pedidoVenda.dataHoraEmissao,
          pedidoVendaPessoa: listaValor[index].pedidoVenda.pedidoVendaPessoa,

          pedidoVendaTransporteTransportadora: {
            pessoaTransportadora:
              listaTransportadoraRef.current &&
              listaTransportadoraRef.current[index]
                ? listaTransportadoraRef.current[index].getItemAtual()
                : undefined,
          },
          etiqueta,
        },
      };

      listaItemSelecionado.push(retorno);
    });

    return listaItemSelecionado;
  }, [
    listaItemSelecionadoRef,
    listaQuantidadeRef,
    listaTransportadoraRef,
    listaValor,
  ]);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);

        const response =
          await ImpressaoEtiquetasPedidoVendaItemComunicador.index({
            params: {
              idPedidoVendaEtiquetaSelecionada,
              pesquisaAvancada,
              order,
              descAsc,
              limite,
              pagina,
              textoPesquisa,
            },
          });

        localStorage.removeItem('@EloGestorle:etiquetaPedidoVenda');

        const listaDados = response.dados[0].map(
          (data: IImpressaoEtiquetasPedidoVendaItemLista) => {
            return {
              ...data,
              quantidade: data.quantidade,
              selecionado: true,
              pedidoVenda: {
                ...data.pedidoVenda,
              },
            };
          }
        );

        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);
        if (listaDados.length > 0) {
          if (selecionarTodosRef.current) {
            selecionarTodosRef.current.checked = true;
          }

          listaItemSelecionadoRef.current?.forEach((item, index) => {
            if (
              item &&
              listaDados[index] &&
              listaDados[index].quantidade === 0
            ) {
              item.checked = false;
            }
          });
        }
        handleAlterouSelecionado();

        const dados = listaDados.map(
          (data: IImpressaoEtiquetasPedidoVendaItemLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              'pedidoVenda.numero': (
                <div>
                  <div className="lista-valor">{data.pedidoVenda.numero}</div>
                </div>
              ),
              'pedidoVenda.dataHoraEmissao': (
                <div>
                  <div className="lista-data">
                    {FormatarDataParaPtBr(data.pedidoVenda.dataHoraEmissao)}
                  </div>
                </div>
              ),
              'pedidoVenda.pedidoVendaPessoa.pessoa.codigoNomeRazaoSocial': (
                <div>
                  <div className="lista-texto">
                    {`${data.pedidoVenda.pedidoVendaPessoa.pessoa.codigo} - ${data.pedidoVenda.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                  </div>
                </div>
              ),

              'produto.codigo': (
                <div>
                  <div className="lista-texto">{data.produto.codigo}</div>
                </div>
              ),

              'produto.descricao': (
                <div>
                  <div className="lista-texto">{data.produto.descricao}</div>
                </div>
              ),

              quantidade: (
                <div className="lista-valor">
                  <InputTabelaInteiro
                    valorPadrao={Math.trunc(data.quantidade)}
                    obterRef={(instance) => {
                      if (instance.current && listaQuantidadeRef.current) {
                        listaQuantidadeRef.current[index] = instance.current;
                        listaQuantidadeRef.current[index].value = String(
                          Math.trunc(data.quantidade)
                        );
                      }
                    }}
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo: 'quantidade',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (!listaQuantidadeRef.current?.[proximo]);

                        listaQuantidadeRef.current?.[proximo]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event) => {
                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];
                      listaValor[index].quantidade = Math.trunc(
                        event.currentTarget.value.ConverterParaNumber()
                      );

                      if (
                        Number(event.currentTarget.value) === 0 &&
                        itemSelecionadoRef &&
                        itemSelecionadoRef.checked
                      ) {
                        itemSelecionadoRef.checked = false;
                        handleAlterouSelecionado();
                      } else if (
                        Number(event.currentTarget.value) > 0 &&
                        itemSelecionadoRef &&
                        !itemSelecionadoRef.checked
                      ) {
                        itemSelecionadoRef.checked = true;
                        handleAlterouSelecionado();
                      }
                    }}
                  />
                </div>
              ),
              'pedidoVenda.pedidoVendaTransporteTransportadora.pessoaTransportadora.codigoNomeRazaoSocial':
                (
                  <div className="lista-texto">
                    <InputTabelaAutoCompleteTransportador
                      style={{ height: 25 }}
                      obterRef={(instance) => {
                        if (
                          instance.current &&
                          listaTransportadoraRef.current
                        ) {
                          listaTransportadoraRef.current[index] =
                            instance.current;
                          listaTransportadoraRef.current[
                            index
                          ].selecionarItemSemEvento(
                            data.pedidoVenda.pedidoVendaTransporteTransportadora
                              .pessoaTransportadora
                          );
                        }
                      }}
                      onChangeItemAtual={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  </div>
                ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleAlterouSelecionado,
      handleControlarFocus,
      setListaValor,
      listaValor.length,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialImpressaoEtiquetasPedidoVendaItem' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Impressão de Etiquetas do Pedido de Venda por Item"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesImpressaoEtiquetasPedidoVendaItem
                formRef={formRef}
                loading={loading}
                setLoading={setLoading}
                possuiItemSelecionado={possuiItemSelecionado}
                handleObterItensSelecionados={handleObterItensSelecionados}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          headerLista={
            <Container style={{ padding: 0 }}>
              <Divisor>
                <FormCia
                  ref={formRef}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Row style={{ width: '100%' }}>
                    <Col lg={6} md={6} sm={12} style={{ minWidth: '230px' }}>
                      <InputAutoCompleteEtiqueta
                        label="Modelo de Etiqueta"
                        nomeObjeto="etiqueta"
                        placeholder="Modelo de Etiqueta"
                        name="idEtiqueta"
                        listaTipoEtiqueta={[TipoEtiquetaEnum.pedidoVendaItem]}
                      />
                    </Col>
                  </Row>
                </FormCia>
              </Divisor>
            </Container>
          }
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default ImpressaoEtiquetasPedidoVendaItemLista;
