import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-calculo-cartao';

interface IRegraCalculoCartaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraCalculoCartaoComunicadorShow {
  id: string;
}

interface IRegraCalculoCartaoComunicadorStore {
  params: any;
}

interface IRegraCalculoCartaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraCalculoCartaoComunicadorDeletar {
  id: string;
}

class RegraCalculoCartaoComunicador {
  public async index(
    params: IRegraCalculoCartaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IRegraCalculoCartaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraCalculoCartaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraCalculoCartaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraCalculoCartaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraCalculoCartaoComunicador();
