import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import * as Yup from 'yup';
import { Table, Col, Button, Row } from 'react-bootstrap';
import { FormatarTelefone } from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { Container, CabecalhoListaEmailTelefone } from './styles';
import Modal, { IJanelaRef } from '../../../JanelaAutocomplete';
import TratarErros from '../../../../Util/Erro/TratarErros';
import IPesquisaPessoaNomeFantasiaDetalhe from './Interface/IPesquisaPessoaNomeFantasiaDetalhe';
import IPesquisaPessoaNomeFantasiaDetalheRef from './Interface/IPesquisaPessoaNomeFantasiaDetalheRef';
import IPessoa from './Interface/IPessoa';
import { TBody } from '../../../Styles/TBody';
import PessoaNomeFantasiaComunicador from '../../../../Comunicador/Comum/Comunicador/PessoaNomeFantasiaComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import PaginacaoPessoaNomeFantasia from './PaginacaoPessoaNomeFantasia';
import { UsePesquisaPessoaNomeFantasia } from './Hooks/PesquisaPessoaNomeFantasiaProvider';
import JanelaLoading from '../../../JanelaLoading';
import ToastErro from '../../../../Util/Toasts/ToastErro';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';
import { Sleep } from '../../../Padrao/MenuPrincipal/Scripts';

const PesquisaPessoaNomeFantasiaDetalhe: React.ForwardRefRenderFunction<
  IPesquisaPessoaNomeFantasiaDetalheRef,
  IPesquisaPessoaNomeFantasiaDetalhe
> = ({ handleOnSelecionarPessoa, pararLoading }, ref) => {
  const { paginaAtual, setTotalPaginas, limite } =
    UsePesquisaPessoaNomeFantasia();

  const janelaRef = useRef<IJanelaRef>(null);

  const [loadingDetalhe, setLoadingDetalhe] = useState(true);

  const [listaPessoa, setListaPessoa] = useState<IPessoa[]>([]);

  const [listaTelefoneSemDuplicata, setListaTelefoneSemDuplicata] = useState<
    any[]
  >([]);
  const [listaEmailSemDuplicata, setListaEmailSemDuplicata] = useState<any[]>(
    []
  );

  const [listaEmailSelecionado, setListaEmailSelecionado] = useState<any[]>([]);
  const [listaTelefoneSelecionado, setListaTelefoneSelecionado] = useState<
    any[]
  >([]);

  const listaUfRef = useRef<Array<HTMLInputElement | null>>([]);

  const [pessoaCadastrada, setPessoaCadastrada] = useState<any>([]);

  const [origemIndex, setOrigemIndex] = useState(0);
  const [cnpjCpfIndex, setCnpjCpfIndex] = useState(0);
  const [nomeRazaoSocialIndex, setNomeRazaoSocialIndex] = useState(0);
  const [nomeFantasiaIndex, setNomeFantasiaIndex] = useState(0);
  const [cepIndex, setCepIndex] = useState(0);
  const [cidadeIndex, setCidadeIndex] = useState(0);
  const [bairroIndex, setBairroIndex] = useState(0);
  const [logradouroIndex, setLogradouroIndex] = useState(0);
  const [complementoIndex, setComplementoIndex] = useState(0);
  const [numeroIndex, setNumeroIndex] = useState(0);

  const [paginacaoBlock, setPaginacaoBlock] = useState(false);

  const { telaGrande } = UseReactSizeMeBodyHook();

  const pesquisarPessoaNomeFantasia = useCallback(
    async ({
      cnpjCpf,
      inscricaoEstadual,
      nomeRazaoSocial,
      nomeFantasia,
      cep,
      cidade,
      logradouro,
      bairro,
      complemento,
      numero,
      listaEmail,
      listaTelefone,
    }: any) => {
      try {
        if (pessoaCadastrada.nomeFantasia) {
          setOrigemIndex(0);
          setCnpjCpfIndex(0);
          setNomeRazaoSocialIndex(0);
          setNomeFantasiaIndex(0);
          setCepIndex(0);
          setCidadeIndex(0);
          setBairroIndex(0);
          setLogradouroIndex(0);
          setComplementoIndex(0);
          setNumeroIndex(0);

          setListaPessoa([]);
          setLoadingDetalhe(true);
          const response = await PessoaNomeFantasiaComunicador.show({
            params: {
              cnpjCpf,
              inscricaoEstadual,
              nomeRazaoSocial,
              nomeFantasia,
              cep,
              cidade,
              logradouro,
              bairro,
              complemento,
              numero,
              listaEmail,
              listaTelefone,
              limite,
              paginaAtual,
            },
          });

          setTotalPaginas(response.totalPaginas);

          if (response.mensagem === 'Dados do registro já estão atualizados!') {
            pararLoading();
            ToastInfo('Dados do registro já estão atualizados!');
          } else if (
            response.listaPessoa.length === 0 ||
            (response.listaPessoa.length === 1 &&
              response.listaPessoa[0].origem === 'Dados do Cadastro')
          ) {
            await Sleep(1000);

            pararLoading();
            setLoadingDetalhe(false);
            ToastErro('Nenhum registro foi encontrado!');
          } else if (response.listaPessoa.length === 1) {
            await Sleep(1000);

            pararLoading();
            setLoadingDetalhe(false);
            handleOnSelecionarPessoa(response.listaPessoa[0]);
          } else {
            setListaPessoa(response.listaPessoa);
            setListaTelefoneSemDuplicata(response.listaTelefoneSemDuplicata);
            setListaEmailSemDuplicata(response.listaEmailSemDuplicata);

            const listaTelefoneCadastro =
              await response.listaTelefoneSemDuplicata.filter(
                (telefone: any) => {
                  return telefone.origem === 'Cadastro';
                }
              );
            setListaTelefoneSelecionado(listaTelefoneCadastro);

            const listaEmailCadastro =
              await response.listaEmailSemDuplicata.filter((email: any) => {
                return email.origem === 'Cadastro';
              });
            setListaEmailSelecionado(listaEmailCadastro);

            pararLoading();
            janelaRef.current?.toggleJanela(true);
            setLoadingDetalhe(false);
          }
        }
      } catch (erro) {
        TratarErros(erro);
      }
    },
    [
      handleOnSelecionarPessoa,
      limite,
      paginaAtual,
      pararLoading,
      pessoaCadastrada.nomeFantasia,
      setTotalPaginas,
    ]
  );

  const pesquisarNomeFantasia = useCallback(
    async ({
      cnpjCpf,
      inscricaoEstadual,
      nomeRazaoSocial,
      nomeFantasia,
      cep,
      cidade,
      logradouro,
      bairro,
      complemento,
      numero,
      listaEmail,
      listaTelefone,
    }: any) => {
      setPessoaCadastrada({
        cnpjCpf,
        inscricaoEstadual,
        nomeRazaoSocial,
        nomeFantasia,
        cep,
        cidade,
        logradouro,
        bairro,
        complemento,
        numero,
        listaEmail,
        listaTelefone,
      });
    },
    []
  );

  useImperativeHandle(ref, () => ({
    pesquisarNomeFantasia,
  }));

  useEffect(() => {
    async function pesquisarNomeFantasiaAsync(): Promise<void> {
      await pesquisarPessoaNomeFantasia({
        cnpjCpf: pessoaCadastrada.cnpjCpf,
        inscricaoEstadual: pessoaCadastrada.inscricaoEstadual,
        nomeRazaoSocial: pessoaCadastrada.nomeRazaoSocial,
        nomeFantasia: pessoaCadastrada.nomeFantasia,
        cep: pessoaCadastrada.cep,
        cidade: pessoaCadastrada.cidade,
        logradouro: pessoaCadastrada.logradouro,
        bairro: pessoaCadastrada.bairro,
        complemento: pessoaCadastrada.complemento,
        numero: pessoaCadastrada.numero,
        listaEmail: pessoaCadastrada.listaEmail,
        listaTelefone: pessoaCadastrada.listaTelefone,
      });
    }

    pesquisarNomeFantasiaAsync();
  }, [
    pesquisarPessoaNomeFantasia,
    pessoaCadastrada,
    pessoaCadastrada.bairro,
    pessoaCadastrada.cep,
    pessoaCadastrada.cidade,
    pessoaCadastrada.cnpjCpf,
    pessoaCadastrada.complemento,
    pessoaCadastrada.inscricaoEstadual,
    pessoaCadastrada.listaEmail,
    pessoaCadastrada.listaTelefone,
    pessoaCadastrada.logradouro,
    pessoaCadastrada.nomeFantasia,
    pessoaCadastrada.nomeRazaoSocial,
    pessoaCadastrada.numero,
  ]);

  useEffect(() => {
    if (
      origemIndex ||
      cnpjCpfIndex ||
      nomeRazaoSocialIndex ||
      nomeFantasiaIndex ||
      cepIndex ||
      cidadeIndex ||
      bairroIndex ||
      logradouroIndex ||
      complementoIndex ||
      numeroIndex
    ) {
      setPaginacaoBlock(true);
    } else {
      setPaginacaoBlock(false);
    }
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    cnpjCpfIndex,
    complementoIndex,
    logradouroIndex,
    nomeFantasiaIndex,
    nomeRazaoSocialIndex,
    numeroIndex,
    origemIndex,
  ]);

  const handleAdicionarItemListaEmail = useCallback(
    (email: any): void => {
      if (listaEmailSelecionado.find((item) => item.email === email.email)) {
        setListaEmailSelecionado((state) => {
          return [...state];
        });
      } else {
        setListaEmailSelecionado((state) => {
          return [...state, email];
        });
      }
    },
    [listaEmailSelecionado]
  );

  const handleRemoveItemListaEmail = useCallback(
    (email: { email: string }): void => {
      setListaEmailSelecionado((state) => {
        return state.filter((item) => item.email !== email.email);
      });
    },
    []
  );

  const handleAdicionarTodosItemListaEmail = useCallback((): void => {
    listaEmailSemDuplicata.forEach((email) => {
      if (
        cnpjCpfIndex === email.pessoaIndex ||
        nomeRazaoSocialIndex === email.pessoaIndex ||
        nomeFantasiaIndex === email.pessoaIndex ||
        cepIndex === email.pessoaIndex ||
        cidadeIndex === email.pessoaIndex ||
        bairroIndex === email.pessoaIndex ||
        logradouroIndex === email.pessoaIndex ||
        complementoIndex === email.pessoaIndex ||
        numeroIndex === email.pessoaIndex
      ) {
        handleAdicionarItemListaEmail(email);
      } else {
        handleRemoveItemListaEmail(email);
      }
    });
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    cnpjCpfIndex,
    complementoIndex,
    handleAdicionarItemListaEmail,
    handleRemoveItemListaEmail,
    listaEmailSemDuplicata,
    logradouroIndex,
    nomeFantasiaIndex,
    nomeRazaoSocialIndex,
    numeroIndex,
  ]);

  const handleAdicionarItemListaTelefone = useCallback(
    (telefone: any): void => {
      if (
        listaTelefoneSelecionado.find(
          (item) => item.ddd === telefone.ddd && item.numero === telefone.numero
        )
      ) {
        setListaTelefoneSelecionado((state) => {
          return [...state];
        });
      } else {
        setListaTelefoneSelecionado((state) => {
          return [...state, telefone];
        });
      }
    },
    [listaTelefoneSelecionado]
  );

  const handleRemoveItemListaTelefone = useCallback(
    (telefone: { numero: string; ddd: string }): void => {
      setListaTelefoneSelecionado((state) => {
        return state.filter(
          (item) =>
            `${item.ddd}${item.numero}` !== `${telefone.ddd}${telefone.numero}`
        );
      });
    },
    []
  );

  const handleAdicionarTodosItemListaTelefone = useCallback((): void => {
    listaTelefoneSemDuplicata.forEach((telefone) => {
      if (
        cnpjCpfIndex === telefone.pessoaIndex ||
        nomeRazaoSocialIndex === telefone.pessoaIndex ||
        nomeFantasiaIndex === telefone.pessoaIndex ||
        cepIndex === telefone.pessoaIndex ||
        cidadeIndex === telefone.pessoaIndex ||
        bairroIndex === telefone.pessoaIndex ||
        logradouroIndex === telefone.pessoaIndex ||
        complementoIndex === telefone.pessoaIndex ||
        numeroIndex === telefone.pessoaIndex
      ) {
        handleAdicionarItemListaTelefone(telefone);
      } else {
        handleRemoveItemListaTelefone(telefone);
      }
    });
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    cnpjCpfIndex,
    complementoIndex,
    handleAdicionarItemListaTelefone,
    handleRemoveItemListaTelefone,
    listaTelefoneSemDuplicata,
    logradouroIndex,
    nomeFantasiaIndex,
    nomeRazaoSocialIndex,
    numeroIndex,
  ]);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      const schema = Yup.object().shape({
        nomeFantasia: Yup.string()
          .nullable()
          .max(60, 'Nome Fantasia selecionado é inválido!')
          .required('Nome Fantasia é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    const itensSelecionados = {
      nomeRazaoSocial: listaPessoa[nomeRazaoSocialIndex]
        ?.nomeRazaoSocial as string,
      nomeFantasia: listaPessoa[nomeFantasiaIndex]?.nomeFantasia || '',
      cnpjCpf: listaPessoa[cnpjCpfIndex]?.cnpjCpf as string,

      cidade: listaPessoa[cidadeIndex]?.cidade as any,

      logradouro: listaPessoa[logradouroIndex]?.logradouro || '',
      bairro: listaPessoa[bairroIndex]?.bairro || '',
      complemento: listaPessoa[complementoIndex]?.complemento || '',
      numero: listaPessoa[numeroIndex]?.numero || '',
      cep: listaPessoa[cepIndex]?.cep || '',
      listaEmail: listaEmailSelecionado,
      listaTelefone: listaTelefoneSelecionado,
    } as IPessoa;

    const pessoaValida = await handleValidar(itensSelecionados);
    if (!pessoaValida) {
      return;
    }

    handleOnSelecionarPessoa(itensSelecionados);
    janelaRef.current?.toggleJanela(false);
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    cnpjCpfIndex,
    complementoIndex,
    handleOnSelecionarPessoa,
    handleValidar,
    listaEmailSelecionado,
    listaPessoa,
    listaTelefoneSelecionado,
    logradouroIndex,
    nomeFantasiaIndex,
    nomeRazaoSocialIndex,
    numeroIndex,
  ]);

  return (
    <Modal
      titulo="Selecione os dados corretos"
      ref={janelaRef}
      onHide={() => {
        setListaEmailSelecionado([]);
        setListaTelefoneSelecionado([]);
      }}
      tamanho="xl"
    >
      <Container $telaGrande={telaGrande}>
        <Row>
          <Col lg={12}>
            <div style={{ overflow: 'auto' }} className="lista-pessoas">
              <JanelaLoading isLoading={loadingDetalhe} />

              <Table
                striped
                hover
                bordered
                variant="light"
                style={{ marginTop: loadingDetalhe ? 20 : 0 }}
              >
                <thead>
                  <tr>
                    <th className="lista-texto">
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto">
                      <span>CPF/CNPJ</span>
                    </th>
                    <th className="lista-texto">
                      <span>Nome</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Nome Fantasia</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Cep</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Cidade</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>UF</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Logradouro</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Bairro</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Complemento</span>
                    </th>
                    <th className={`lista-texto `}>
                      <span>Número</span>
                    </th>
                  </tr>
                </thead>

                {loadingDetalhe === false && (
                  <TBody>
                    {listaPessoa.map((pessoa, index) => {
                      return (
                        <tr>
                          <td
                            className={`${
                              origemIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={(event) => {
                              const input =
                                event.currentTarget.querySelector('input');
                              input?.click();
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="origem"
                                value={pessoa.origem}
                                checked={index === origemIndex}
                                onChange={() => {
                                  setOrigemIndex(index);
                                  setCnpjCpfIndex(index);
                                  setNomeRazaoSocialIndex(index);
                                  setNomeFantasiaIndex(index);
                                  setCepIndex(index);
                                  setCidadeIndex(index);
                                  setLogradouroIndex(index);
                                  setBairroIndex(index);
                                  setComplementoIndex(index);
                                  setNumeroIndex(index);
                                }}
                              />
                              <span>{pessoa.origem}</span>
                            </div>
                          </td>
                          <td
                            className={`${
                              cnpjCpfIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setCnpjCpfIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="cnpjCpf"
                                checked={index === cnpjCpfIndex}
                                value={pessoa?.cnpjCpf}
                                onChange={() => {
                                  setCnpjCpfIndex(index);
                                }}
                              />
                              <span>{pessoa?.cnpjCpf}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              nomeRazaoSocialIndex === index
                                ? 'selecionado '
                                : ''
                            }lista-texto`}
                            onClick={() => {
                              setNomeRazaoSocialIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="nomeRazaoSocial"
                                checked={index === nomeRazaoSocialIndex}
                                value={pessoa?.nomeRazaoSocial}
                                onChange={() => {
                                  setNomeRazaoSocialIndex(index);
                                }}
                              />
                              <span>{pessoa?.nomeRazaoSocial}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              nomeFantasiaIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setNomeFantasiaIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="nomeFantasia"
                                checked={index === nomeFantasiaIndex}
                                value={pessoa?.nomeFantasia}
                                onChange={() => {
                                  setNomeFantasiaIndex(index);
                                }}
                              />
                              <span>{pessoa?.nomeFantasia}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              cepIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setCepIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="cep"
                                checked={index === cepIndex}
                                value={pessoa?.cep}
                                onChange={() => {
                                  setCepIndex(index);
                                }}
                              />
                              <span>{pessoa?.cep}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              cidadeIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setCidadeIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="cidade"
                                checked={index === cidadeIndex}
                                onChange={() => {
                                  setCidadeIndex(index);
                                }}
                              />
                              <span>{pessoa?.cidade?.nome}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              cidadeIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                          >
                            <div>
                              <input
                                type="radio"
                                name="cidadeEstadoSigla"
                                checked={index === cidadeIndex}
                                value={pessoa?.cidade?.estado?.sigla}
                                disabled
                                ref={(instance) => {
                                  listaUfRef.current[index] = instance;
                                }}
                              />
                              <span>{pessoa?.cidade?.estado?.sigla}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              logradouroIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setLogradouroIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="logradouro"
                                checked={index === logradouroIndex}
                                value={pessoa?.logradouro}
                                onChange={() => {
                                  setLogradouroIndex(index);
                                }}
                              />
                              <span>{pessoa?.logradouro}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              bairroIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setBairroIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="bairro"
                                checked={index === bairroIndex}
                                value={pessoa?.bairro}
                                onChange={() => {
                                  setBairroIndex(index);
                                }}
                              />
                              <span>{pessoa?.bairro}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              complementoIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setComplementoIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="complemento"
                                checked={index === complementoIndex}
                                value={pessoa?.complemento}
                                onChange={() => {
                                  setComplementoIndex(index);
                                }}
                              />
                              <span>{pessoa?.complemento}</span>
                            </div>
                          </td>

                          <td
                            className={`${
                              numeroIndex === index ? 'selecionado ' : ''
                            }lista-texto`}
                            onClick={() => {
                              setNumeroIndex(index);
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                name="numero"
                                checked={index === numeroIndex}
                                value={pessoa?.numero}
                                onChange={() => {
                                  setNumeroIndex(index);
                                }}
                              />
                              <span>{pessoa?.numero}</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </TBody>
                )}
              </Table>
            </div>
            <PaginacaoPessoaNomeFantasia paginacaoBlock={paginacaoBlock} />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div style={{ overflow: 'auto' }} className="lista-telefone-email">
              <CabecalhoListaEmailTelefone>
                <h4>Selecione os números de telefone</h4>
              </CabecalhoListaEmailTelefone>
              <Table>
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '5%' }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleAdicionarTodosItemListaTelefone();
                          } else {
                            setListaTelefoneSelecionado([]);
                          }
                        }}
                      />
                    </th>
                    <th className="lista-texto" style={{ width: '35%' }}>
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto" style={{ width: '60%' }}>
                      <span>Telefone</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="lista-dado-selecionavel">
                  {listaTelefoneSemDuplicata &&
                    listaTelefoneSemDuplicata.map((telefone, telefoneIndex) => {
                      if (
                        cnpjCpfIndex === telefone.pessoaIndex ||
                        nomeRazaoSocialIndex === telefone.pessoaIndex ||
                        nomeFantasiaIndex === telefone.pessoaIndex ||
                        cepIndex === telefone.pessoaIndex ||
                        cidadeIndex === telefone.pessoaIndex ||
                        bairroIndex === telefone.pessoaIndex ||
                        logradouroIndex === telefone.pessoaIndex ||
                        complementoIndex === telefone.pessoaIndex ||
                        numeroIndex === telefone.pessoaIndex ||
                        telefone.pessoaIndex === 0
                      ) {
                        return (
                          <tr
                            onClick={(event) => {
                              const input =
                                event.currentTarget.querySelector('input');
                              input?.click();
                            }}
                          >
                            <td>
                              <input
                                value={telefone}
                                type="checkbox"
                                checked={listaTelefoneSelecionado.includes(
                                  telefone
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(event) => {
                                  if (event.currentTarget.checked) {
                                    handleAdicionarItemListaTelefone(telefone);
                                  } else {
                                    handleRemoveItemListaTelefone(telefone);
                                  }
                                }}
                              />
                            </td>
                            <td>{telefone.origem}</td>
                            <td>
                              <div>
                                <span>
                                  {FormatarTelefone(
                                    `${telefone.ddd}${telefone.numero}`
                                  )}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                      return false;
                    })}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col lg={6}>
            <div style={{ overflow: 'auto' }} className="lista-telefone-email">
              <CabecalhoListaEmailTelefone>
                <h4>Selecione os e-mails</h4>
              </CabecalhoListaEmailTelefone>
              <Table>
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '5%' }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleAdicionarTodosItemListaEmail();
                          } else {
                            setListaEmailSelecionado([]);
                          }
                        }}
                      />
                    </th>
                    <th className="lista-texto" style={{ width: '35%' }}>
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto" style={{ width: '60%' }}>
                      <span>E-mail</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="lista-dado-selecionavel">
                  {listaEmailSemDuplicata &&
                    listaEmailSemDuplicata.map((email, emailIndex) => {
                      if (
                        cnpjCpfIndex === email.pessoaIndex ||
                        nomeRazaoSocialIndex === email.pessoaIndex ||
                        nomeFantasiaIndex === email.pessoaIndex ||
                        cepIndex === email.pessoaIndex ||
                        cidadeIndex === email.pessoaIndex ||
                        bairroIndex === email.pessoaIndex ||
                        logradouroIndex === email.pessoaIndex ||
                        complementoIndex === email.pessoaIndex ||
                        numeroIndex === email.pessoaIndex ||
                        email.pessoaIndex === 0
                      ) {
                        return (
                          <tr
                            onClick={(event) => {
                              const input =
                                event.currentTarget.querySelector('input');
                              input?.click();
                            }}
                          >
                            <td>
                              <input
                                value={email}
                                checked={listaEmailSelecionado.includes(email)}
                                type="checkbox"
                                onChange={(event) => {
                                  if (event.currentTarget.checked) {
                                    handleAdicionarItemListaEmail(email);
                                  } else {
                                    handleRemoveItemListaEmail(email);
                                  }
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </td>
                            <td>{email.origem}</td>
                            <td>
                              <div>
                                <span>{email.email}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                      return false;
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Col lg={12} style={{ padding: 0 }}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="espacamento-interno-para-esquerda-15"
          >
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={() => {
                janelaRef.current?.toggleJanela(false);
                setListaEmailSelecionado([]);
                setListaTelefoneSelecionado([]);
              }}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={() => {
                setListaEmailSelecionado([]);
                setListaTelefoneSelecionado([]);
                handleSubmit();
              }}
            >
              <AiOutlineCheck />
              <span style={{ marginLeft: 10 }}>OK</span>
            </Button>
          </div>
        </Col>
      </Container>
    </Modal>
  );
};

export default forwardRef(PesquisaPessoaNomeFantasiaDetalhe);
