import api from '../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/conta-contabil';

interface IContaContabilComunicadorIndex {
  params?: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IContaContabilComunicadorShow {
  id: string;
}

interface IContaContabilComunicadorDelete {
  id: string;
}

interface IContaContabilComunicadorStore {
  params: any;
}

interface IContaContabilComunicadorUpdate {
  id: string;
  params: any;
}

class ContaContabilComunicador {
  public async index(params: IContaContabilComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IContaContabilComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(params: IContaContabilComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(params: IContaContabilComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(params: IContaContabilComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new ContaContabilComunicador();
