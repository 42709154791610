import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/obter-cfop-fiscal-entrada';

interface IConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicadorShow {
  idOperacaoFiscalSaida: string;
}

class ConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicador {
  public async show({
    idOperacaoFiscalSaida,
  }: IConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${idOperacaoFiscalSaida}`);
    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicador();
