"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatarLinhaDigitavel = FormatarLinhaDigitavel;
function FormatarLinhaDigitavel(linhaDigitavelNaoFormatada) {
    // #region Definições
    /* AAABC.CCDDX.DDDDD.DEFFFY.FGGGG.GGHHHZ.K.UUUUVVVVVVVVVV
     * ------------------------------------------------------
     * Campo 1
     * AAABC.CCDDX
     * AAA - Código do Banco
     * B   - Moeda
     * CCC - Carteira
     * DD  - 2 primeiros números Nosso Número
     * X   - DAC Campo 1 (AAABC.CCDD) Mod10
     
     * Campo 2
     * DDDDD.DEFFFY
     * DDDDD.D - Restante Nosso Número
     * E       - DAC (Agência/Conta/Carteira/Nosso Número)
     * FFF     - Três primeiros da agência
     * Y       - DAC Campo 2 (DDDDD.DEFFF) Mod10
     
     * Campo 3
     * FGGGG.GGHHHZ
     * F       - Restante da Agência
     * GGGG.GG - Número Conta Corrente + DAC
     * HHH     - Zeros (Não utilizado)
     * Z       - DAC Campo 3
     
     * Campo 4
     * K       - DAC Código de Barras
     
     * Campo 5
     * UUUUVVVVVVVVVV
     * UUUU       - Fator Vencimento
     * VVVVVVVVVV - Valor do Título
     */
    // #endregion Definições
    const linhaDigitavelFormatada = `${linhaDigitavelNaoFormatada.substring(0, 5)}.${linhaDigitavelNaoFormatada.substring(5, 10)} ${linhaDigitavelNaoFormatada.substring(10, 15)}.${linhaDigitavelNaoFormatada.substring(15, 21)} ${linhaDigitavelNaoFormatada.substring(21, 26)}.${linhaDigitavelNaoFormatada.substring(26, 32)} ${linhaDigitavelNaoFormatada.substring(32, 33)} ${linhaDigitavelNaoFormatada.substring(33, 47)}`;
    return linhaDigitavelFormatada;
}
