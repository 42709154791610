import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/exportar-xml';

interface INotaFiscalEntradaExportarXmlComunicadorShow {
  id: string;
}

class NotaFiscalEntradaExportarXmlComunicador {
  public async show(
    params: INotaFiscalEntradaExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaExportarXmlComunicador();
