import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  FormatarEnum,
  MovimentacaoEntreProdutosTipoEstoqueEnum,
  StringConverterParaEnum,
} from '@elogestor/util';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputAutoCompletePessoa from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputAutoCompleteLote from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import InputAutoCompleteProduto, {
  IOnChangeItemAtualAutoCompleteProdutoEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import Select from '../../../../../../../Componentes/Select';
import { useCustos } from '../Hooks/CustosHook';
import ProdutoObterLocalEstoquePadraoComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterLocalEstoquePadraoComunicador';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ProdutoObterUnidadeMedidaComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterUnidadeMedidaComunicador';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';

interface IGeralTabProps {
  idEditar: string | null;
}

const GeralTab: React.FC<IGeralTabProps> = ({ idEditar }) => {
  const { refresh } = UseForm();

  const {
    handleSetarCustoProdutoSaida,
    handleChangeQuantidadeSaida,
    handleCalcularCustosEntrada,
    handleClearCustoFields,
    handleClearCustosEntrada,
    handleClearCustosTotalSaida,
  } = useCustos();

  const [tipoLocalEstoqueValue, setTipoLocalEstoqueValue] = useState(
    MovimentacaoEntreProdutosTipoEstoqueEnum.proprioEmPoderProprio
  );

  const { formRef, setLoading, inputRefFocus } = UseForm();

  const produtoOrigem =
    formRef.current?.getFieldValueNomeObjeto('produtoOrigem');
  const produtoDestino =
    formRef.current?.getFieldValueNomeObjeto('produtoDestino');

  const handleChangeProdutOrigem = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteProdutoEvent) => {
      if (!event.itemAtual) {
        handleClearCustoFields();

        formRef.current?.setFieldValue(
          'produtoOrigem.produtoEstoque.unidadeMedida.sigla',
          null
        );
      } else {
        formRef.current?.setFieldValue(
          'produtoOrigem.produtoEstoque.unidadeMedida.sigla',
          event.itemAtual.produtoEstoque.unidadeMedida.sigla
        );

        try {
          setLoading(true);

          const localEstoquePadrao =
            await ProdutoObterLocalEstoquePadraoComunicador.show({
              id: event.itemAtual.id,
            });

          formRef.current?.setFieldValue(
            'localEstoqueOrigem',
            localEstoquePadrao
          );

          setLoading(false);
        } catch (error) {
          TratarErros(error);
          setLoading(false);
        }
      }

      refresh();
    },
    [formRef, handleClearCustoFields, refresh, setLoading]
  );

  const handleChangeProdutDestino = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteProdutoEvent) => {
      formRef.current?.clearField(
        'produtoDestino.produtoEstoque.unidadeMedida.sigla'
      );

      if (event.itemAtual) {
        const unidadeMedida = await ProdutoObterUnidadeMedidaComunicador.show({
          id: event.itemAtual.id,
        });

        formRef.current?.setFieldValue(
          'produtoDestino.produtoEstoque.unidadeMedida.sigla',
          unidadeMedida.sigla
        );

        try {
          setLoading(true);

          const localEstoquePadrao =
            await ProdutoObterLocalEstoquePadraoComunicador.show({
              id: event.itemAtual.id,
            });

          formRef.current?.setFieldValue(
            'localEstoqueDestino',
            localEstoquePadrao
          );

          setLoading(false);
        } catch (error) {
          TratarErros(error);
          setLoading(false);
        }
      }

      refresh();
    },
    [formRef, refresh, setLoading]
  );

  const handleCarregarCustosSaida = useCallback(async () => {
    const dataMovimento = formRef.current?.getFieldValue('dataHora');
    const idPessoa = formRef.current?.getFieldValue('idPessoa');
    const idProduto = formRef.current?.getFieldValue('idProdutoOrigem');
    const indicadorTipoEstoque = formRef.current?.getFieldValue(
      'indicadorTipoEstoque'
    );

    if (!dataMovimento || !idProduto || !indicadorTipoEstoque) return;

    const custosUnitariosSaida = await handleSetarCustoProdutoSaida({
      dataMovimento,
      idProduto,
      indicadorTipoEstoque,
      idPessoa,
    });

    if (!custosUnitariosSaida) {
      handleClearCustoFields();
      return;
    }

    const quantidadeDestino =
      formRef.current?.getFieldValue('quantidadeDestino');
    if (quantidadeDestino) handleCalcularCustosEntrada(quantidadeDestino);

    const quantidadeOrigem = formRef.current?.getFieldValue('quantidadeOrigem');
    if (quantidadeOrigem)
      handleChangeQuantidadeSaida(quantidadeOrigem, custosUnitariosSaida);
  }, [
    formRef,
    handleCalcularCustosEntrada,
    handleChangeQuantidadeSaida,
    handleClearCustoFields,
    handleSetarCustoProdutoSaida,
  ]);

  const currentTipoLocalEstoque = formRef.current?.getFieldValue(
    'indicadorTipoEstoque'
  );

  useEffect(() => {
    const valor = StringConverterParaEnum<
      typeof MovimentacaoEntreProdutosTipoEstoqueEnum,
      MovimentacaoEntreProdutosTipoEstoqueEnum
    >(MovimentacaoEntreProdutosTipoEstoqueEnum, currentTipoLocalEstoque);

    setTipoLocalEstoqueValue(valor);
  }, [currentTipoLocalEstoque]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={2} lg={6} sm={12}>
            <InputInteiro
              label="Número"
              name="numero"
              placeholder="Número"
              disabled
            />
          </Col>

          <Col xl={3} lg={6} sm={12}>
            <Select
              label="Tipo de Estoque"
              name="indicadorTipoEstoque"
              disabled={!!idEditar}
              options={FormatarEnum({
                enumObj: MovimentacaoEntreProdutosTipoEstoqueEnum,
              })}
              onChange={async (event) => {
                formRef.current?.setErrors({});

                const valor = StringConverterParaEnum<
                  typeof MovimentacaoEntreProdutosTipoEstoqueEnum,
                  MovimentacaoEntreProdutosTipoEstoqueEnum
                >(MovimentacaoEntreProdutosTipoEstoqueEnum, event.target.value);

                setTipoLocalEstoqueValue(valor);
                handleCarregarCustosSaida();
              }}
            />
          </Col>

          <Col xl={3} lg={6} sm={12}>
            <InputDateTime
              ref={inputRefFocus}
              label="Data e hora"
              name="dataHora"
              disabled={!!idEditar}
              onChange={() => {
                handleCarregarCustosSaida();
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            display: `${
              tipoLocalEstoqueValue ===
              MovimentacaoEntreProdutosTipoEstoqueEnum.terceiroEmPoderProprio
                ? 'flex'
                : 'none'
            }`,
          }}
        >
          <Col xl={6}>
            <InputAutoCompletePessoa
              name="idPessoa"
              label="Pessoa (terceiro)"
              placeholder="Pessoa (terceiro)"
              nomeObjeto="pessoa"
              permitirAdicionar={false}
              disabled={!!idEditar}
              onChangeItemAtual={() => {
                if (idEditar) return;

                handleCarregarCustosSaida();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={8}>
            <InputAutoCompleteProduto
              name="idProdutoOrigem"
              nomeObjeto="produtoOrigem"
              label="Produto (origem)"
              placeholder="Produto (origem)"
              disabled={!!idEditar}
              controlaEstoque
              onChangeItemAtual={(event) => {
                handleChangeProdutOrigem(event);
                handleCarregarCustosSaida();
              }}
            />
          </Col>

          <Col xl={3} lg={4}>
            <Input
              name="produtoOrigem.produtoEstoque.unidadeMedida.sigla"
              label="Unidade"
              placeholder="Unidade"
              disabled
            />
          </Col>

          <Col
            xl={3}
            lg={6}
            sm={6}
            xs={12}
            hidden={!produtoOrigem?.produtoEstoque.controlarLote}
          >
            <InputAutoCompleteLote
              name="idLoteOrigem"
              nomeObjeto="loteOrigem"
              label="Lote (origem)"
              placeholder="Lote (origem)"
              permitirAdicionar={false}
              disabled={!!idEditar}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6}>
            <InputAutoCompleteLocalEstoque
              name="idLocalEstoqueOrigem"
              label="Local Estoque (origem)"
              placeholder="Local Estoque (origem)"
              nomeObjeto="localEstoqueOrigem"
              disabled={!!idEditar}
            />
          </Col>

          <Col xl={3} lg={6}>
            <InputDecimalNulavel
              name="quantidadeOrigem"
              label="Quantidade (origem)"
              placeholder="Quantidade (origem)"
              casasDecimais={4}
              casasInteiras={11}
              disabled={!!idEditar}
              onChangeValue={(event) => {
                if (!event.valor) {
                  handleClearCustosTotalSaida();
                } else {
                  handleChangeQuantidadeSaida(event.valor);
                }
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={8}>
            <InputAutoCompleteProduto
              name="idProdutoDestino"
              nomeObjeto="produtoDestino"
              label="Produto (destino)"
              placeholder="Produto (destino)"
              disabled={!!idEditar}
              controlaEstoque
              onChangeItemAtual={(event) => {
                handleChangeProdutDestino(event);
              }}
            />
          </Col>

          <Col xl={3} lg={4}>
            <Input
              name="produtoDestino.produtoEstoque.unidadeMedida.sigla"
              label="Unidade"
              placeholder="Unidade"
              disabled
            />
          </Col>

          <Col
            xl={3}
            lg={6}
            sm={6}
            xs={12}
            hidden={!produtoDestino?.produtoEstoque.controlarLote}
          >
            <InputAutoCompleteLote
              name="idLoteDestino"
              nomeObjeto="loteDestino"
              label="Lote (destino)"
              placeholder="Lote (destino)"
              disabled={!!idEditar}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6}>
            <InputAutoCompleteLocalEstoque
              name="idLocalEstoqueDestino"
              label="Local Estoque (destino)"
              placeholder="Local Estoque (destino)"
              nomeObjeto="localEstoqueDestino"
              disabled={!!idEditar}
            />
          </Col>

          <Col xl={3} lg={6}>
            <InputDecimalNulavel
              name="quantidadeDestino"
              label="Quantidade (destino)"
              placeholder="Quantidade (destino)"
              casasDecimais={4}
              casasInteiras={11}
              disabled={!!idEditar}
              onChangeValue={(event) => {
                if (!event.valor) {
                  handleClearCustosEntrada();
                } else {
                  handleCalcularCustosEntrada(event.valor);
                }
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Textarea
              name="observacao"
              label="Observação"
              placeholder="Observação"
              maxLength={300}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default GeralTab;
