import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch/index';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IConjuntoVariavelModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  // onLimparFormModal(): void;
}

const ConjuntoVariavelDetalhe: React.FC<IConjuntoVariavelModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaConfiguradorConjuntoVariaveis: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataRecuperarFormulario();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Variáveis do Conjunto"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={12} md={12}>
                  <Input
                    ref={inputRefFocus}
                    label="Descrição"
                    name="descricao"
                    placeholder="Descrição"
                    maxLength={100}
                  />
                </Col>
                <Col style={{ marginTop: 10 }} lg={10} md={10} sm={10} xs={10}>
                  <Input
                    label="Composição da Máscara"
                    name="mascara"
                    placeholder="Composição da Máscara"
                    maxLength={100}
                  />
                </Col>
                <Col style={{ marginTop: 10 }} lg={2} md={2} sm={2} xs={2}>
                  <InputSwitch label="Ativo" name="ativo" />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ConjuntoVariavelDetalhe;
