/* eslint-disable prefer-const */
import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { DefaultInput, SpanErro } from '../../Styles';
import { UseForm } from '../../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputTabelaInteiroProps {
  valorAnterior: string;
}

export interface IOnChangeValueInputTabelaInteiroEvent {
  mudou: boolean;
  valorAnterior: string;
}

export interface IInputTabelaInteiroRef {
  focus(): void;
}

interface IInputTabelaInteiroProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valorPadrao: number;
  valueRef?: React.RefObject<HTMLInputElement>;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTabelaInteiroProps
  ) => void | Promise<void>;
  onChangeValue?: (event: IOnChangeValueInputTabelaInteiroEvent) => void;
  obterRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}

const InputTabelaInteiro: React.ForwardRefRenderFunction<
  IInputTabelaInteiroRef,
  IInputTabelaInteiroProps
> = (
  {
    valorPadrao,
    valueRef,
    error,

    onFocus,
    onChange,
    onChangeValue,
    onBlur,
    obterRef,
    ...rest
  },
  _ref
) => {
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [mudou, setMudou] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(inputRef);
      }
    }
  }, [obterRef, valueRef]);

  useEffect(() => {
    if (valueRef) {
      valorAnterior.current = valueRef?.current?.value || '';
    } else {
      valorAnterior.current = inputRef?.current?.value || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (valueRef) {
        valorAnterior.current = valueRef?.current?.value || '';
      } else {
        valorAnterior.current = inputRef?.current?.value || '';
      }

      if (onFocus) onFocus(event);
      const tamanho = event.target.value.length;

      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus, valueRef]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setMudou(true);

      const valor = event.target.value.replace(/\D+/g, '');
      event.target.value = valor || '0';

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
      if (onChangeValue)
        onChangeValue({ mudou, valorAnterior: valorAnterior.current });
    },
    [mudou, onChange, onChangeValue]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setMudou(true);
      if (onBlur) onBlur(event);

      let valor = String(event.target.value);
      event.target.value = valor;

      setIsFilled(!!valor);
      setIsFocused(false);
    },
    [onBlur]
  );

  return (
    <div>
      <DefaultInput
        style={{ textAlign: 'right', boxSizing: 'border-box', height: 25 }}
        defaultValue={Number(valorPadrao)}
        type="text"
        ref={valueRef || inputRef}
        $isErrored={!!erroInterno}
        $isFocused={isFocused}
        $isFilled={isFilled}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
    </div>
  );
};

export default forwardRef(InputTabelaInteiro);
