"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloNotaProdutorEnum;
(function (ModeloNotaProdutorEnum) {
    ModeloNotaProdutorEnum["nfe"] = "NF-e";
    ModeloNotaProdutorEnum["notaFiscalProdutor"] = "Nota Fiscal Produtor";
    ModeloNotaProdutorEnum["notaFiscalAvulsa"] = "Nota Fiscal Avulsa";
})(ModeloNotaProdutorEnum || (ModeloNotaProdutorEnum = {}));
exports.default = ModeloNotaProdutorEnum;
