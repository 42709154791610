import { IContaParcelaBoletoLista } from '@elogestor/util';
import React, {
  createContext,
  RefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IContaListaParcelaBoletoContext {
  validador: boolean;

  listaParcela: IContaParcelaBoletoLista[];
  setListaParcela(listaValor: IContaParcelaBoletoLista[]): void;

  listaParcelaSelecionadaRef: RefObject<Array<HTMLInputElement | null>>;
}

const ContaListaParcelaBoletoContext =
  createContext<IContaListaParcelaBoletoContext>(
    {} as IContaListaParcelaBoletoContext
  );

const ContaListaParcelaBoletoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaParcela, setListaParcela] = useState<IContaParcelaBoletoLista[]>(
    []
  );
  const listaParcelaSelecionadaRef = useRef<Array<HTMLInputElement | null>>([]);

  return (
    <ContaListaParcelaBoletoContext.Provider
      value={{
        listaParcela,
        setListaParcela,
        listaParcelaSelecionadaRef,

        validador: true,
      }}
    >
      {children}
    </ContaListaParcelaBoletoContext.Provider>
  );
};

function UseContaParcelaBoleto(): Omit<
  IContaListaParcelaBoletoContext,
  'validador'
> {
  const context = useContext(ContaListaParcelaBoletoContext);

  if (!context.validador) {
    throw new Error(
      'UseContaParcelaBoleto deve ser usado com um ContaListaParcelaBoletoHook'
    );
  }

  return context;
}

export { ContaListaParcelaBoletoHook, UseContaParcelaBoleto };
