import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/produto-obter-imposto';

interface IProdutoObterImpostoComunicadorShow {
  id: string;
}

class ProdutoObterImpostoComunicador {
  public async show(params: IProdutoObterImpostoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ProdutoObterImpostoComunicador();
