import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-producao/validar-estoque';

interface IOrdemProducaoComunicadorStore {
  params: any[];
}

class ValidarEstoqueMovimentoProducaoComunicador {
  public async store(params: IOrdemProducaoComunicadorStore): Promise<boolean> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ValidarEstoqueMovimentoProducaoComunicador();
