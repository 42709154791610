import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IOrdemProducaoResumoProdutosProduzidosRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IOrdemProducaoResumoProdutosProduzidosRelatorioPDF {
  dados: IOrdemProducaoResumoProdutosProduzidosRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const OrdemProducaoResumoProdutosProduzidosRelatorioPDF: React.FC<
  IOrdemProducaoResumoProdutosProduzidosRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Data: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '10%' }}>
              <Text>Número</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%' }}>
              <Text>Situação</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '18%' }}>
              <Text>Data de Lanç.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '18%' }}>
              <Text>Data de Início</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '18%',
              }}
            >
              <Text>Data de Término</Text>
            </View>

            <View style={{ width: '3%' }} />

            <View
              style={{
                width: '19%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Quantidade</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dotted black' : '',
                        padding: '6pt 0pt 0pt 0pt',
                        marginBottom: 2,
                      }}
                    >
                      <View style={{ width: '100%' }}>
                        <Text>{dado.chave}</Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaOrdemProducao.map(
                        (ordemProducao, index: number) => {
                          const colorBackGround =
                            index % 2 === 0 ? '#E4E4E4' : '#fff';

                          return (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              key={String(index)}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: colorBackGround,
                                }}
                              >
                                <View style={{ width: '10%' }}>
                                  <Text>{ordemProducao.numero}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '10%' }}>
                                  <Text>{ordemProducao.situacao}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View style={{ width: '18%' }}>
                                  <Text>{ordemProducao.dataLancamento}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '18%' }}>
                                  <Text>{ordemProducao.dataHoraInicio}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '18%' }}>
                                  <Text>{ordemProducao.dataHoraTermino}</Text>
                                </View>
                                <View style={{ width: '3%' }} />

                                <View
                                  style={{
                                    width: '19%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{ordemProducao.quantidade}</Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        borderTop:
                          dados.listaDados.length === 1
                            ? '1pt dotted black'
                            : '',
                        marginTop: '5pt',

                        fontWeight: 'bold',
                      }}
                    >
                      <View style={{ width: '81%' }} />

                      <View
                        style={{
                          width: '19%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>{dado.valores.somatorioValorQuantidade}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrdemProducaoResumoProdutosProduzidosRelatorioPDF;
