import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/logotipo';

interface IEmpresaLogotipoComunicadorUpdate {
  params: any;
}

class EmpresaLogotipoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async update(params: IEmpresaLogotipoComunicadorUpdate): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaLogotipoComunicador();
