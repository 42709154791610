/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { addMinutes } from 'date-fns';
import GetValidationErrors from '../../Util/Erro/GetValidationErrors';
import { UseAuth } from '../../Hooks/Auth';
import Input from '../../Componentes/Inputs/Input';
import { Container, FormCiaLogin, Button } from './style';
import TratarErros from '../../Util/Erro/TratarErros';
import ILoginDados from './Interface/ILoginDados';
import ILoginUsuarioSubdominioTentativa from './Interface/IUsuarioSubdominioTentativaLogin';
import ToastErro from '../../Util/Toasts/ToastErro';
import UsuarioSubdominioTentativaLoginComunicador from '../../Comunicador/Configuracao/UsuarioSubdominioTentativaLogin/Comunicador/UsuarioSubdominioTentativaLoginComunicador';
import { UseParametros } from '../../Hooks/ParametrosHook';
import LoadingDiv from '../../Componentes/LoadingDiv';
import { UseLiberacoes } from '../../Hooks/LiberacoesHook';

const LabelEsqueceuSenha: React.FC = () => (
  <span
    style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
  >
    Senha * <Link to="/esqueceu-senha">Esqueceu a senha?</Link>
  </span>
);

const Login: React.FC = () => {
  const [usuarioSubdominioTentativaLogin, setUsuarioSubdominioTentativaLogin] =
    useState<ILoginUsuarioSubdominioTentativa | null>(null);
  const navigate = useNavigate();
  const { signIn } = UseAuth();
  const [loading, setLoading] = useState(false);

  const { carregarParametros } = UseParametros();
  const { carregarLiberacoes } = UseLiberacoes();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cnpjCpfSubdominio: Yup.string().required(
            'CNPJ/CPF ou Subdomínio é obrigatório'
          ),
          usuario: Yup.string().required('Usuário é obrigatório'),
          senha: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const validarTempoRestante = useCallback(() => {
    if (usuarioSubdominioTentativaLogin) {
      let minutosParaAguardar = 0;
      if (usuarioSubdominioTentativaLogin.tentativas === 6) {
        minutosParaAguardar = 1;
      } else if (usuarioSubdominioTentativaLogin.tentativas === 7) {
        minutosParaAguardar = 10;
      } else if (usuarioSubdominioTentativaLogin.tentativas >= 8) {
        minutosParaAguardar = 100;
      }

      const ultimaTentativa = new Date(
        usuarioSubdominioTentativaLogin.dataHoraUltimaTentativa
      );
      const dataDeLiberacao = addMinutes(ultimaTentativa, minutosParaAguardar);

      const tempoRestanteParaLiberacaoMs =
        dataDeLiberacao.getTime() - Date.now();
      if (tempoRestanteParaLiberacaoMs > 0) {
        const tempoRestanteParaLiberacaoMinutos = Math.ceil(
          tempoRestanteParaLiberacaoMs / 60000
        );
        ToastErro(
          `Você realizou ${usuarioSubdominioTentativaLogin.tentativas} tentativas de login, aguarde ${tempoRestanteParaLiberacaoMinutos} minutos para tentar novamente`
        );
        return false;
      }
    }
    return true;
  }, [usuarioSubdominioTentativaLogin]);

  useEffect(() => {
    validarTempoRestante();
  }, [usuarioSubdominioTentativaLogin, validarTempoRestante]);

  const handleSubmit = useCallback(async () => {
    const data = formRef.current?.getData() as ILoginDados;

    try {
      if (!validarTempoRestante()) return;
      if (!(await handleValidar(data))) return;

      setLoading(true);
      await signIn({
        cnpjCpfSubdominio: data.cnpjCpfSubdominio,
        usuario: data.usuario,
        senha: data.senha,
      });

      localStorage.setItem(
        '@EloGestorle:cnpjCpfSubdominio',
        data.cnpjCpfSubdominio
      );
      navigate('/home');

      await carregarParametros();
      await carregarLiberacoes();
    } catch (error) {
      const response = await UsuarioSubdominioTentativaLoginComunicador.show({
        params: {
          nomeSubdominio: data.cnpjCpfSubdominio,
          nomeUsuario: data.usuario,
        },
      });
      setUsuarioSubdominioTentativaLogin(response);

      TratarErros(error);
    } finally {
      setLoading(false);
    }
  }, [
    carregarLiberacoes,
    carregarParametros,
    handleValidar,
    navigate,
    signIn,
    validarTempoRestante,
  ]);

  useEffect(() => {
    function submit(event: KeyboardEvent): void {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    }
    document.addEventListener('keypress', submit);

    return () => {
      document.removeEventListener('keypress', submit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    const cnpjCpfSubdominio = localStorage.getItem(
      '@EloGestorle:cnpjCpfSubdominio'
    );

    formRef.current?.setFieldValue('cnpjCpfSubdominio', cnpjCpfSubdominio);
  }, []);

  return (
    <Container>
      <div>
        <LoadingDiv isLoading={loading} />
        <h1>Login</h1>
        <FormCiaLogin ref={formRef}>
          <Input
            name="cnpjCpfSubdominio"
            placeholder="CNPJ/CPF ou Subdomínio"
            label="CNPJ/CPF ou Subdomínio *"
            tabIndex={1}
            maiuscula={false}
          />
          <Input
            name="usuario"
            placeholder="Usuário"
            label="Usuário *"
            tabIndex={2}
            maiuscula={false}
          />
          <Input
            type="password"
            name="senha"
            placeholder="Senha"
            label={<LabelEsqueceuSenha />}
            tabIndex={3}
            maiuscula={false}
          />

          <Button
            style={{ marginTop: 15 }}
            type="button"
            onClick={handleSubmit}
          >
            Acessar
          </Button>
        </FormCiaLogin>
      </div>
    </Container>
  );
};

export default Login;
