import { IEmpresaTrayValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { Col, Row } from 'react-bootstrap';
import { BtnContainer } from './styles';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';

type IProps = IMain<IEmpresaTrayValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();

  const { ConfiguracaoIntegracaoEmpresaTray: permissao } = permissoes;

  const navigate = useNavigate();

  const rotas = UseRota();

  const {
    formRef,
    loading,
    inputRefFocus,
    refresh,
    handleSubmit,
    handleCarregarDados,
    setIdDetalheRegistro,
  } = UseForm();

  const code = formRef.current?.getFieldValue('code');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosObrigatorios,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <Divisor>
        <Row>
          <Col xl={12} lg={12}>
            <Input
              ref={inputRefFocus}
              label="Code"
              name="code"
              required
              onChange={refresh}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={4} lg={4}>
            <InputInteiroNulavel
              label="Tempo Sinc. Produtos (Min)"
              name="tempoSincronizarProduto"
              onChange={refresh}
            />
          </Col>
          <Col xl={4} lg={4}>
            <InputInteiroNulavel
              label="Tempo Sinc. Var. Prod. (Min)"
              name="tempoSincronizarVariacao"
              onChange={refresh}
            />
          </Col>
          <Col xl={4} lg={4}>
            <InputInteiroNulavel
              label="Tempo Sinc. Pedido/Estoque (Min)"
              name="tempoSincronizarPedidoEstoque"
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <BtnContainer>
            <button
              type="button"
              onClick={handleClickSalvar}
              className="btn-padrao btn-verde"
              disabled={
                loading || !permissao?.altera || !permissao?.inclui || !code
              }
            >
              <TextoLoadingSalvar loading={loading} />
            </button>
          </BtnContainer>
        </Row>
      </Divisor>
    </FormCia>
  );
};

export default Main;
