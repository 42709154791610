import React from 'react';
import { ComunicadorProvider } from '../../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import LoteComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/Lote/Comunicador/LoteComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const LoteHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: LoteComunicador }}>
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default LoteHook;
