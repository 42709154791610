"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EnumObterValorNumericoOuUndefined;
const Erros_1 = require("../Erros");
function EnumObterValorNumericoOuUndefined(valor) {
    if (!valor)
        return undefined;
    let valorString = String(valor);
    valorString = valorString.RemoverNaoNumericos();
    if (!valorString) {
        throw new Erros_1.AppErro({
            mensagem: 'Não foi possivel localizar um valor númerico no enum',
        });
    }
    return Number(valorString);
}
