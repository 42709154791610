import api from '../../../Comum/Services/Api';

const rota = 'obter-valores-ibpt';

interface IObterValoresIbptComunicadorIndex {
  params: {
    idProduto: string;
    dataHoraEmissao: Date;
    valor: number;
  };
}

class ObterValoresIbptComunicador {
  public async index(params: IObterValoresIbptComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ObterValoresIbptComunicador();
