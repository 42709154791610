"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoAdiantamentoEnum;
(function (TipoAdiantamentoEnum) {
    TipoAdiantamentoEnum["antecipacao"] = "Antecipa\u00E7\u00E3o";
    TipoAdiantamentoEnum["devolucao"] = "Devolu\u00E7\u00E3o";
    TipoAdiantamentoEnum["viagem"] = "Viagem";
    TipoAdiantamentoEnum["valePresente"] = "Vale Presente";
})(TipoAdiantamentoEnum || (TipoAdiantamentoEnum = {}));
exports.default = TipoAdiantamentoEnum;
