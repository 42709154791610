import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataHoraParaPtBr,
  IOrdemDesmontagemImpressao,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';

const Document: any = _document;
const Page: any = _page;

interface IOrdemDesmontagemImpressaoPDF {
  dados: IOrdemDesmontagemImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const OrdemDesmontagemImpressaoPDF: React.FC<IOrdemDesmontagemImpressaoPDF> = ({
  dados,
}) => {
  const canvasBarCode = document.createElement('canvas');
  JsBarcode(
    canvasBarCode,
    String(dados.numero).CompletaCaracteres(4, '0', false),
    {
      fontSize: 2,
      format: 'CODE128C',
    }
  );
  const barCode = canvasBarCode.toDataURL();

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'flex-start',
              marginTop: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                source={dados.caminhoLogo}
                style={{ marginLeft: 5, width: 100, borderRadius: 0 }}
              />
            )}
          </View>
          <View
            style={{
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                ORDEM DE DESMONTAGEM
              </Text>
            </View>
            <View style={{ marginTop: '5pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                {dados.empresa.nomeRazaoSocial}
              </Text>
            </View>

            <View style={{ marginTop: '5pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 10 }}>
                {`Data de Início: ${FormatarDataHoraParaPtBr(
                  dados.dataHoraInicio
                )}`}
              </Text>
            </View>
            {dados.dataHoraTermino && (
              <View style={{ marginTop: '5pt', alignItems: 'center' }}>
                <Text style={{ fontSize: 10 }}>
                  {`Data de Término: ${FormatarDataHoraParaPtBr(
                    dados.dataHoraTermino
                  )}`}
                </Text>
              </View>
            )}
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: '10pt',
              }}
            >
              <View
                style={{ height: '75%', fontSize: 8, marginBottom: '10pt' }}
              >
                <View style={{ width: '80%', height: 55 }}>
                  <Image src={barCode} />
                </View>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {`Número: `}
                  </Text>
                </View>
                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {dados.numero}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Data Emissão: `}
                  </Text>
                </View>

                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 10 }}>{dados.dataEmissao}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            justifyContent: 'center',
            marginBottom: 10,
            borderTop: '1pt solid black',
          }}
        >
          {dados.listaOrdemDesmontagem?.map((dado, index) => {
            return (
              <View
                key={String(index)}
                style={{
                  flexDirection: 'row',
                  fontSize: 10,
                  fontWeight: 'bold',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '63%' }}>
                  <Text>{`Produto: ${dado.produto.codigo}-${dado.produto.descricao}`}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%' }}>
                  <Text>{`Lote: ${dado.lote ? dado.lote.codigo : ''}`}</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '20%', alignItems: 'flex-end' }}>
                  <Text>{`QTDE: ${Number(
                    dado.quantidade
                  ).FormatarParaPtBr()}`}</Text>
                </View>
              </View>
            );
          })}

          {!dados.listaOrdemDesmontagem ||
            (dados.listaOrdemDesmontagem?.length === 0 && (
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 11,
                  fontWeight: 'bold',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <View style={{ width: '58%' }}>
                  <Text>{`Produto: ${dados.produto.codigo}-${dados.produto.descricao}`}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '20%' }}>
                  <Text>{`Lote: ${dados.lote ? dados.lote.codigo : ''}`}</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '20%', alignItems: 'flex-end' }}>
                  <Text>{`QTDE: ${Number(
                    dados.quantidade
                  ).FormatarParaPtBr()}`}</Text>
                </View>
              </View>
            ))}
        </View>

        {dados.listaOrdemDesmontagemItem &&
          dados.listaOrdemDesmontagemItem.length > 0 && (
            <View
              style={{
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <View style={{ width: '100%', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11 }}>
                  Itens Desmontados
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  borderBottom: '1pt solid black',
                  backgroundColor: '#cdcdcd',
                  fontSize: 9,
                }}
              >
                <View style={{ width: '5%', alignItems: 'flex-end' }}>
                  <Text>Seq.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Código</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '50%' }}>
                  <Text>Descrição</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%' }}>
                  <Text>Lote</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>UN</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>Qtde.</Text>
                </View>
              </View>

              {dados.listaOrdemDesmontagemItem?.map((dado, index) => {
                if (
                  dado.listaOrdemDesmontagemItemLocalEstoque &&
                  dado.listaOrdemDesmontagemItemLocalEstoque.length > 0
                ) {
                  const div = dado.listaOrdemDesmontagemItemLocalEstoque.map(
                    (item) => {
                      return (
                        <View
                          key={dado.ordem}
                          style={{
                            flexDirection: 'row',
                            backgroundColor:
                              index % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <View style={{ width: '5%', alignItems: 'flex-end' }}>
                            <Text>{dado.ordem}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View
                            style={{ width: '15%', alignItems: 'flex-end' }}
                          >
                            <Text>{dado.produto.codigo}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '50%' }}>
                            <Text>{dado.produto.descricao}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '11%' }}>
                            <Text>{item.lote?.codigo}</Text>
                          </View>

                          <View style={{ width: '5%' }}>
                            <Text>{dado.unidadeMedida?.sigla}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View
                            style={{ width: '12%', alignItems: 'flex-end' }}
                          >
                            <Text>
                              {Number(
                                dado.quantidadeRetorno
                              ).FormatarParaPtBr()}
                            </Text>
                          </View>
                        </View>
                      );
                    }
                  );

                  return div;
                }
                return (
                  <View
                    key={dado.ordem}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>{dado.ordem}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '15%', alignItems: 'flex-end' }}>
                      <Text>{dado.produto.codigo}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '50%' }}>
                      <Text>{dado.produto.descricao}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '11%' }} />

                    <View style={{ width: '5%' }}>
                      <Text>{dado.unidadeMedida?.sigla}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '12%', alignItems: 'flex-end' }}>
                      <Text>
                        {Number(dado.quantidadeRetorno).FormatarParaPtBr()}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}

        {dados.observacoes && dados.observacoes !== '' && (
          <View
            style={{
              marginTop: 10,
              minHeight: 70,
              fontSize: 10,
              width: '100%',
              borderBottom: '1pt solid black',
              borderTop: '1pt solid black',
            }}
          >
            <View>
              <Text>{`Observações: ${dados.observacoes}`}</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default OrdemDesmontagemImpressaoPDF;
