import React from 'react';
import { RotasProvider } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '',
        detalhes: '/suprimentos/produtos/impressao-etiquetas-produtos/detalhe',
        listagem: '/suprimentos/produtos/impressao-etiquetas-produtos/detalhe',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
