import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda/impressao/retrato-com-foto-produto-com-sem-valor-itens';

interface IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicadorShow {
  id: string;
}

class PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicador {
  public async show(
    params: IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicador();
