import ILogPesquisaParametro from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/movimento-manual-estoque/log';

interface IMovimentoManualEstoqueLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IMovimentoManualEstoqueLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class MovimentoManualEstoqueLogComunicador {
  public async index(
    params: IMovimentoManualEstoqueLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IMovimentoManualEstoqueLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new MovimentoManualEstoqueLogComunicador();
