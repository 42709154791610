import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/serie-auto-complete-tag';

interface ISerieAutoCompleteTagComunicadorIndex {
  params: any;
}

class SerieAutoCompleteTagComunicador {
  public async index(
    params: ISerieAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SerieAutoCompleteTagComunicador();
