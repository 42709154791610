"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ConfiguracaoTributacaoTipoLancamentoEnum;
(function (ConfiguracaoTributacaoTipoLancamentoEnum) {
    ConfiguracaoTributacaoTipoLancamentoEnum["orcamento"] = "Or\u00E7amento";
    ConfiguracaoTributacaoTipoLancamentoEnum["pedidoVenda"] = "Pedido de Venda";
    ConfiguracaoTributacaoTipoLancamentoEnum["notaFiscalSaida"] = "Nota Fiscal de Sa\u00EDda";
    ConfiguracaoTributacaoTipoLancamentoEnum["cotacaoCompra"] = "Cota\u00E7\u00E3o de Compra";
    ConfiguracaoTributacaoTipoLancamentoEnum["pedidoCompra"] = "Pedido de Compra";
    ConfiguracaoTributacaoTipoLancamentoEnum["notaFiscalEntrada"] = "Nota Fiscal de Entrada";
    ConfiguracaoTributacaoTipoLancamentoEnum["ordemServico"] = "Ordem Servi\u00E7o";
})(ConfiguracaoTributacaoTipoLancamentoEnum || (ConfiguracaoTributacaoTipoLancamentoEnum = {}));
exports.default = ConfiguracaoTributacaoTipoLancamentoEnum;
