import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiMailSend } from 'react-icons/bi/index.mjs';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';

interface IAcoesGeracaoEnvioBoletos {
  loading: boolean;
  setLoading: (value: boolean) => void;
  possuiItemSelecionado: boolean;
}

const AcoesGeracaoEnvioBoletos: React.FC<IAcoesGeracaoEnvioBoletos> = ({
  loading,
  setLoading,
  possuiItemSelecionado,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  // const handleImprimirBoletos = useCallback(async () => {
  //   try {
  //     setLoading(true);

  //     setLoading(false);
  //   } catch (error) {
  //     TratarErros(error);
  //     setLoading(false);
  //   }
  // }, [setLoading]);

  // const handleEnviarBoletosPorEmail = useCallback(async () => {
  //   try {
  //     setLoading(true);

  //     setLoading(false);
  //   } catch (error) {
  //     TratarErros(error);
  //     setLoading(false);
  //   }
  // }, [setLoading]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled
          // onClick={handleImprimirEtiquetasProdutos}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Gerar/Imprimir Boletos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled
          // onClick={handleEnviarBoletosPorEmail}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesGeracaoEnvioBoletos;
