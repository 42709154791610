import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/substituto-tributario';

interface IEmpresaSubstitutoTributarioComunicadorStore {
  params: any;
}

interface IEmpresaSubstitutoTributarioComunicadorDelete {
  id: string;
}

class EmpresaSubstitutoTributarioComunicador {
  public async store(
    params: IEmpresaSubstitutoTributarioComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEmpresaSubstitutoTributarioComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmpresaSubstitutoTributarioComunicador();
