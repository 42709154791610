import {
  FormatarRemovendoEnum,
  IPesagemAvaliacaoLista,
  StringConverterParaEnum,
  TipoAvaliacaoPesagemEnum,
  TipoAvaliacaoPesagemValorEnum,
} from '@elogestor/util';
import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FormCia, IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import Select from '../../../../../../../../Componentes/Select';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IListaDetalheAutorizacaoProps {
  name: string;
}

const PesagemAvaliacaoLista: React.FC<IListaDetalheAutorizacaoProps> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { refresh } = UseForm();

  const valorRef = useRef<IPesagemAvaliacaoLista[]>([]);
  const formRefLista = useRef<IFormCiaHandles>(null);

  const [listaValor, setListaValor] = useState<IPesagemAvaliacaoLista[]>([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPesagemAvaliacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormCia ref={formRefLista}>
        <Tabela style={{ overflow: 'auto', marginTop: 12 }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '60%' }}>
                  <span>Descrição</span>
                </th>
                <th className="lista-texto" style={{ width: '40%' }}>
                  <span>Valor</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaValor.map((item, index) => {
                return (
                  <tr key={v4()}>
                    <td className="lista-texto">
                      <div>{item.itemAvaliacaoPesagem.descricao}</div>
                    </td>
                    <td className="lista-texto">
                      <Select
                        name="valorPadrao"
                        defaultValue={item.valor ? item.valor : undefined}
                        options={
                          item.itemAvaliacaoPesagem.tipoAvaliacao ===
                          TipoAvaliacaoPesagemEnum.SimNao
                            ? FormatarRemovendoEnum({
                                enumObj: TipoAvaliacaoPesagemValorEnum,
                                enumRemover: [
                                  TipoAvaliacaoPesagemValorEnum.bom,
                                  TipoAvaliacaoPesagemValorEnum.otimo,
                                  TipoAvaliacaoPesagemValorEnum.razoavel,
                                  TipoAvaliacaoPesagemValorEnum.ruim,
                                ],
                                nullavel: true,
                              })
                            : FormatarRemovendoEnum({
                                enumObj: TipoAvaliacaoPesagemValorEnum,
                                enumRemover: [
                                  TipoAvaliacaoPesagemValorEnum.sim,
                                  TipoAvaliacaoPesagemValorEnum.nao,
                                ],
                                nullavel: true,
                              })
                        }
                        onChange={(event) => {
                          refresh();

                          const valorEnum = StringConverterParaEnum<
                            typeof TipoAvaliacaoPesagemValorEnum,
                            TipoAvaliacaoPesagemValorEnum
                          >(
                            TipoAvaliacaoPesagemValorEnum,
                            event.currentTarget.value
                          );

                          const valor = listaValor[index];
                          if (valorEnum) {
                            valor.valor = valorEnum;
                          } else {
                            valor.valor = null;
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </FormCia>
    </>
  );
};

export default PesagemAvaliacaoLista;
