import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/etiqueta/elemento';

interface IEtiquetaElementoComunicadorIndex {
  id: string;
}

class EtiquetaElementoComunicador {
  public async index(params: IEtiquetaElementoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new EtiquetaElementoComunicador();
