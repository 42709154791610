import { ImportacaoXmlTerceiroTipoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/consultar-nsu-faltante';

interface ImportacaoXmlTerceiroConsultarNSUFaltantesComunicadorIndex {
  params: {
    tipoDocumento?: ImportacaoXmlTerceiroTipoEnum | null;
  };
}

class ImportacaoXmlTerceiroConsultarNSUFaltantesComunicador {
  public async index(
    params: ImportacaoXmlTerceiroConsultarNSUFaltantesComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroConsultarNSUFaltantesComunicador();
