import styled from 'styled-components';
import { InputContainer } from '../Styles';

interface IInputInteiroIncrementalContainerProps {
  disabled?: boolean;
}

export const InputInteiroIncrementalContainer = styled(
  InputContainer
)<IInputInteiroIncrementalContainerProps>`
  position: relative;

  input {
    text-align: center;
  }

  & > div {
    cursor: pointer;
    position: absolute;
    padding: 5px;
    display: flex;
    border-radius: 4px;

    transition: background-color 0.2s ease;
    top: 50%;
  }

  svg {
    color: #7c7a79;
  }

  & > div:hover {
    ${({ disabled }) => {
      if (disabled) return '';

      return 'background-color: #d3d3d3;';
    }}
  }

  & > div:active {
    ${({ disabled }) => {
      if (disabled) return '';

      return 'background-color: #e4e4e4;';
    }}
  }

  .incr-box {
    left: 28%;
  }

  .decr-box {
    right: 28%;
    transform: rotate(180deg);
  }
`;
