import { INotaFiscalEntradaItemLista } from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { produce } from 'immer';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import NotaFiscalEntradaItemOrdemComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/NotaFiscalEntradaItemOrdemComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemIpiTabContext {
  validador: boolean;

  listaValor: INotaFiscalEntradaItemLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<INotaFiscalEntradaItemLista[]>
  >;

  listaItemNotaFiscalEntrada: any[];
  setListaItemNotaFiscalEntrada: React.Dispatch<React.SetStateAction<any[]>>;

  idNotaFiscalItem: string;
  setIdNotaFiscalItem: React.Dispatch<React.SetStateAction<string>>;

  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(lista?: INotaFiscalEntradaItemLista[]): Promise<void>;

  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const ItemIpiTabContext = createContext<IItemIpiTabContext>(
  {} as IItemIpiTabContext
);

const ListaNotaFiscalEntradaDraggableHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const formPrincipal = UseForm();

  const [listaValor, setListaValor] = useState<INotaFiscalEntradaItemLista[]>(
    []
  );

  const [idNotaFiscalItem, setIdNotaFiscalItem] = useState('');

  const [listaItemNotaFiscalEntrada, setListaItemNotaFiscalEntrada] = useState<
    any[]
  >([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(
    async (lista: INotaFiscalEntradaItemLista[] = listaValor) => {
      const id = formPrincipal.getIdDetalheRegistro();
      if (!id) return;

      const listaItem = lista.map((valor) => {
        return {
          id: valor.id,
          ordem: valor.ordem,
        };
      });

      await NotaFiscalEntradaItemOrdemComunicador.update({
        id,
        params: { listaItem },
      });
    },
    [formPrincipal, listaValor]
  );

  return (
    <ItemIpiTabContext.Provider
      value={{
        listaValor,
        setListaValor,
        listaItemNotaFiscalEntrada,
        setListaItemNotaFiscalEntrada,
        idNotaFiscalItem,
        setIdNotaFiscalItem,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </ItemIpiTabContext.Provider>
  );
};

function UseListaNotaFiscalEntradaDraggable(): Omit<
  IItemIpiTabContext,
  'validador'
> {
  const context = useContext(ItemIpiTabContext);

  if (!context.validador) {
    throw new Error(
      'UseItemIpiTab deve ser usado com um ListaNotaFiscalEntradaDraggableHook'
    );
  }

  return context;
}

export {
  ListaNotaFiscalEntradaDraggableHook,
  UseListaNotaFiscalEntradaDraggable,
};
