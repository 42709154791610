import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/tipo-nota';

interface ITipoNotaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ITipoNotaComunicadorShow {
  id: string;
}

interface ITipoNotaComunicadorStore {
  params: any;
}

interface ITipoNotaComunicadorUpdate {
  id: string;
  params: any;
}

interface ITipoNotaComunicadorDeletar {
  id: string;
}

class TipoNotaComunicador {
  public async index(params: ITipoNotaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ITipoNotaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ITipoNotaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: ITipoNotaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ITipoNotaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new TipoNotaComunicador();
