import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/upload-pdf';

interface IConhecimentoTransporteUploadPdfComunicadorUpdate {
  params: any;
}

class ConhecimentoTransporteUploadPdfComunicador {
  public async update(
    params: IConhecimentoTransporteUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ConhecimentoTransporteUploadPdfComunicador();
