import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/imposto-custo';

interface IEmpresaVigenciaImpostoCustoComunicadorShow {
  id: string;
}

interface IEmpresaVigenciaImpostoCustoComunicadorStore {
  params: any;
}

interface IEmpresaVigenciaImpostoCustoComunicadorUpdate {
  id: string;
  params: any;
}

interface IEmpresaVigenciaImpostoCustoComunicadorDeletar {
  id: string;
}

class EmpresaVigenciaImpostoCustoComunicador {
  public async show(
    params: IEmpresaVigenciaImpostoCustoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IEmpresaVigenciaImpostoCustoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IEmpresaVigenciaImpostoCustoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEmpresaVigenciaImpostoCustoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmpresaVigenciaImpostoCustoComunicador();
