import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/impressao-etiquetas-produtos/obter-dados-padrao';

class ImpressaoEtiquetasProdutosObterDadosPadraoComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new ImpressaoEtiquetasProdutosObterDadosPadraoComunicador();
