import React from 'react';
import ImpressaoEtiquetasPedidoVendaItemLista from './Lista';
import ImpressaoEtiquetasPedidoVendaItemHook from './Hooks';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';

const ImpressaoEtiquetasPedidoVendaItemListagem: React.FC = () => {
  const storage = localStorage.getItem('@EloGestorle:etiquetaPedidoVenda');

  return (
    <ListaProvider>
      <ImpressaoEtiquetasPedidoVendaItemHook>
        <ImpressaoEtiquetasPedidoVendaItemLista
          idPedidoVendaEtiquetaSelecionada={storage}
        />
      </ImpressaoEtiquetasPedidoVendaItemHook>
    </ListaProvider>
  );
};

export default ImpressaoEtiquetasPedidoVendaItemListagem;
