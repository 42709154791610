/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UseItemIcmsOperacaoInterestadualTab } from '../../../Hook/ItemIcmsOperacaoInterestadualTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputHidden from '../../../../../../../../../../Componentes/Inputs/InputHidden';
import { DefaultInput } from '../../../../../../../../../../Componentes/Inputs/Styles';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';

const ItemImpostoIcmsOperacaoInterestadualTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();

  const {
    camposEscondidosIcmsOperacaoInterestadual,
    camposBloqueadosIcmsOperacaoInterestadual,
  } = UseItemIcmsOperacaoInterestadualTab();

  const {
    calcularIcmsOperacaoInterestadual,
    obterRegraEscolhaAliquotaOperacaoInterestadual,
  } = UseItemTab();

  const aliquotaOrigemDestino = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino'
  );

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  useEffect(() => {
    formRefDetalhe.current?.setFieldValue(
      'aliquotaOrigem',
      aliquotaOrigemDestino
    );
    formRefDetalhe.current?.setFieldValue(
      'aliquotaDestino',
      aliquotaOrigemDestino
    );
  }, [aliquotaOrigemDestino, formRefDetalhe]);

  const handleCalcularIcmsOperacaoInterestadual = useCallback(() => {
    calcularIcmsOperacaoInterestadual();
  }, [calcularIcmsOperacaoInterestadual]);

  const handleUtilizaIcmsOperacaoInterestadual = useCallback(
    (value: boolean) => {
      if (value) {
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.calcular',
          true
        );
      }

      obterRegraEscolhaAliquotaOperacaoInterestadual();
    },
    [formRefDetalhe, obterRegraEscolhaAliquotaOperacaoInterestadual]
  );

  const aliquotaOrigem = InputHidden({
    name: 'aliquotaOrigem',
    getValue: () => undefined,
  });
  const aliquotaDestino = InputHidden({
    name: 'aliquotaDestino',
    getValue: () => undefined,
  });

  const aliquotaOrigemFormatado = useMemo(() => {
    return Number(aliquotaOrigem).FormatarParaPtBr();
  }, [aliquotaOrigem]);

  const aliquotaDestinoFormatado = useMemo(() => {
    return Number(aliquotaDestino).FormatarParaPtBr();
  }, [aliquotaDestino]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Utiliza Op. Interestadual"
                name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.utiliza"
                onChange={(event) => {
                  refresh();
                  handleUtilizaIcmsOperacaoInterestadual(
                    event.currentTarget.checked
                  );
                }}
                disabled={situacaoBloquearCampos}
              />
            </div>
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcmsOperacaoInterestadual.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosIcmsOperacaoInterestadual.calcular ||
                  situacaoBloquearCampos
                }
                label="Calcular ICMS Op. Interestadual"
                name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.calcular"
                onChange={() => {
                  refresh();
                  handleCalcularIcmsOperacaoInterestadual();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.aliquotaEstadoDestino
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.aliquotaEstadoDestino ||
                situacaoBloquearCampos
              }
              label="Alíquota Estado de Destino"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino"
              casasInteiras={3}
              casasDecimais={2}
              onBlur={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.aliquotaInterestadual
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.aliquotaInterestadual ||
                situacaoBloquearCampos
              }
              label="Alíquota Interestadual"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual"
              casasInteiras={3}
              casasDecimais={2}
              onBlur={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.aliquotaOrigemDestino
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.aliquotaOrigemDestino ||
                situacaoBloquearCampos
              }
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino"
              casasInteiras={3}
              casasDecimais={2}
              onChange={() => {
                refresh();
                handleCalcularIcmsOperacaoInterestadual();
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcmsOperacaoInterestadual.estadoOrigemBaseCalculo &&
          camposEscondidosIcmsOperacaoInterestadual.aliquotaOrigemDestino &&
          camposEscondidosIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha &&
          camposEscondidosIcmsOperacaoInterestadual.estadoOrigemValorIcms
        }
      >
        <h5>Estado de Origem</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoOrigemBaseCalculo
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoOrigemBaseCalculo ||
                situacaoBloquearCampos
              }
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.aliquotaOrigemDestino
            }
          >
            <label>Alíquota</label>
            <DefaultInput
              style={{ textAlign: 'right' }}
              $isFocused={false}
              $isFilled={false}
              $isErrored={false}
              disabled
              value={aliquotaDestinoFormatado}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha
            }
          >
            <InputDecimalNulavel
              disabled
              label="Percentual Partilha"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoOrigemValorIcms
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoOrigemValorIcms ||
                situacaoBloquearCampos
              }
              label="Valor ICMS"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoBaseCalculo &&
          camposEscondidosIcmsOperacaoInterestadual.aliquotaOrigemDestino &&
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha &&
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp &&
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoPercentualFcp &&
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorFcp &&
          camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp
        }
      >
        <h5>Estado de Destino</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoBaseCalculo
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoDestinoBaseCalculo ||
                situacaoBloquearCampos
              }
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.aliquotaOrigemDestino
            }
          >
            <label>Alíquota</label>
            <DefaultInput
              style={{ textAlign: 'right' }}
              $isFocused={false}
              $isFilled={false}
              $isErrored={false}
              disabled
              value={aliquotaOrigemFormatado}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha
            }
          >
            <InputDecimalNulavel
              disabled
              label="Percentual Partilha"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp ||
                situacaoBloquearCampos
              }
              label="Valor ICMS sem FCP"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoPercentualFcp
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoDestinoPercentualFcp ||
                situacaoBloquearCampos
              }
              label="Percentual FCP"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorFcp
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOperacaoInterestadual.estadoDestinoValorFcp ||
                situacaoBloquearCampos
              }
              label="Valor FCP"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularIcmsOperacaoInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp
            }
          >
            <InputDecimalNulavel
              disabled
              label="Valor ICMS com FCP"
              name="notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoIcmsOperacaoInterestadualTab;
