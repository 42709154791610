import styled from 'styled-components';
import { Modal as ModalB } from 'react-bootstrap';

export const Container = styled.div`
  padding: 20px;
  position: relative;
`;

export const Modal = styled(ModalB)`
  label {
    margin-top: 10px;
  }

  input,
  select {
    background: #fff;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin: 0 0 0 5px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  label {
    margin: 0 0 0 5px;
  }
`;
