/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputInteiroNulavelProps {
  valorAnterior: string;
}

export interface IOnBlurCiaInputInteiroNulavelEvent {
  mudou: boolean;
  valor: string;
}

export interface IInputInteiroNulavelRef {
  focus(): void;
}

interface IInputInteiroNulavelProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  castParaNumberOnGetValue?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputInteiroNulavelProps
  ) => void | Promise<void>;
  onBlurCia?: (event: IOnBlurCiaInputInteiroNulavelEvent) => void;
}

const InputInteiroNulavel: React.ForwardRefRenderFunction<
  IInputInteiroNulavelRef,
  IInputInteiroNulavelProps
> = (
  {
    name,
    label,
    castParaNumberOnGetValue,

    onFocus,
    onChange,
    onBlur,
    onBlurCia,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';
      if (onFocus) onFocus(event);
      const tamanho = event.target.value.length;

      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      setIsFocused(false);
      setIsFilled(!!inputRef.current?.value);
      if (onBlur) onBlur(event);

      if (onBlurCia) {
        const mudou = inputRef.current?.value !== valorAnterior.current;
        onBlurCia({
          mudou,
          valor: inputRef.current?.value || '',
        });
      }
    },
    [onBlur, onBlurCia]
  );

  useEffect(() => {
    registerField<number | null | string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        if (inputRef.current?.value || inputRef.current?.value === '0') {
          return castParaNumberOnGetValue
            ? Number(inputRef.current.value)
            : inputRef.current.value;
        }

        return null;
      },

      setValue(_, value = null) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (value === null) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = String(value);
        }
      },

      setSemExecutarEvento(_, value = null) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (value === null) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = String(value);
        }
      },

      clearValue(_, valorInicial = null) {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        style={{ textAlign: 'right' }}
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={(event) => {
          setErro('');
          event.target.value = event.target.value.replace(/\D+/g, '');
          if (onChange)
            onChange(event, { valorAnterior: valorAnterior.current });
        }}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputInteiroNulavel);
