import React, { useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { BiSave } from 'react-icons/bi/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { UseListaDetalheForm } from '../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../Componentes/LoadingDiv';
import Divisor from '../../../Componentes/Divisor';
import InputSenha from '../../../Componentes/Inputs/InputSenha';
import TextoLoading from '../../../Componentes/TextoLoading';

interface IAlterarSenhaModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const AlterarSenhaDetalhe: React.FC<IAlterarSenhaModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { formRefDetalhe, loading, handleSubmit, inputRefFocus } =
    UseListaDetalheForm();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  return (
    <JanelaDetalhe
      titulo="Alteração de Senha"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputSenha
                    ref={inputRefFocus}
                    autoComplete="off"
                    label="Senha Atual"
                    name="senhaAtual"
                    placeholder="Senha Atual"
                    maxLength={200}
                  />
                </Col>
              </Row>
            </Divisor>
            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputSenha
                    autoComplete="off"
                    label="Nova Senha"
                    name="novaSenha"
                    placeholder="Nova Senha"
                    maxLength={200}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputSenha
                    autoComplete="off"
                    label="Repita a Senha"
                    name="repetirSenha"
                    placeholder="Repita a Senha"
                    maxLength={200}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              style={{ fontWeight: 'bold' }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <MdCancel />
                <span style={{ marginLeft: 10 }}>Cancelar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                margin: '0px 15px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Salvar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default AlterarSenhaDetalhe;
