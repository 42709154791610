import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/importar';

interface IImportacaoXmlTerceiroImportarComunicadorUpdate {
  id: string;
  params?: any;
}

class ImportacaoXmlTerceiroImportarComunicador {
  public async update(
    params: IImportacaoXmlTerceiroImportarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroImportarComunicador();
