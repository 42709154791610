import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/cte/salvar-cte-terceiro';

interface IImportacaoXmlTerceiroCteSalvarImportadoComunicadorStore {
  params: any;
  idXmlCteImportado: string;
}

class ImportacaoXmlTerceiroCteSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlTerceiroCteSalvarImportadoComunicadorStore
  ): Promise<{ id: string; listaMensagem: string[] }> {
    const { idXmlCteImportado, params: body } = params;

    const response = await api.post(rota, body, {
      params: { idXmlCteImportado },
    });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroCteSalvarImportadoComunicador();
