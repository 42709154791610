"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ArraysPossuemMesmosValores;
function ArraysPossuemMesmosValores(dados) {
    if (dados.array1.length !== dados.array2.length) {
        return false;
    }
    // Ordena os arrays em ordem crescente
    dados.array1.sort();
    dados.array2.sort();
    // Compara cada elemento dos arrays
    for (let i = 0; i < dados.array1.length; i++) {
        if (dados.array1[i] !== dados.array2[i]) {
            return false;
        }
    }
    return true;
}
