const PedidoVendaResumoPorDataEmissaoPlanilha = [
  { titulo: 'Data de Emissao', valor: 'chave' },

  {
    titulo: 'Val Sub Total',
    valor: 'valores.somatorioDataEmissaoValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'valores.somatorioDataEmissaoValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'valores.somatorioDataEmissaoValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'valores.somatorioDataEmissaoValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'valores.somatorioDataEmissaoValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'valores.somatorioDataEmissaoValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'valores.somatorioDataEmissaoValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'valores.somatorioDataEmissaoValorTotal',
  },
];

export default PedidoVendaResumoPorDataEmissaoPlanilha;
