/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  EstoqueIndicadorTipoEnum,
  INotaFiscalSaidaImportacaoOrdemServicoEtapa1Lista,
  INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista,
  INotaFiscalSaidaImportacaoOrdemServicoEtapa3Lista,
  ModalidadeFreteEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServico/Comunicador/NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicador';
import NotaFiscalSaidaImportacaoOrdemServicoGerarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServico/Comunicador/NotaFiscalSaidaImportacaoOrdemServicoGerarComunicador';
import NotaFiscalSaidaImportacaoOrdemServicoEtapa1, {
  INotaFiscalSaidaImportacaoOrdemServicoEtapa1Ref,
} from './Etapa1';
import NotaFiscalSaidaImportacaoOrdemServicoEtapa2, {
  INotaFiscalSaidaImportacaoOrdemServicoEtapa2Ref,
} from './Etapa2';
import NotaFiscalSaidaImportacaoOrdemServicoEtapa3, {
  INotaFiscalSaidaImportacaoOrdemServicoEtapa3Ref,
} from './Etapa3';
import NotaFiscalSaidaImportacaoOrdemServicoImportarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServico/Comunicador/NotaFiscalSaidaImportacaoOrdemServicoImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import NotaFiscalSaidaImportacaoOrdemServicoEtapa4, {
  INotaFiscalSaidaImportacaoOrdemServicoEtapa4Ref,
} from './Etapa4';
import NotaFiscalSaidaObterDadosPadraoComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterDadosPadraoComunicador';
import { Sleep } from '../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import TratarInfo from '../../../../Util/Info/TratarInfo';
import NotaFiscalSaidaComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';
import OrdemServicoComunicador from '../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Comunicador/OrdemServicoComunicador';
import NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServico/Comunicador/NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicador';
import NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServico/Comunicador/NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicador';

export interface IAbrirNotaFiscalSaidaImportacaoOrdemServico {
  idPessoa: string;
  idOrdemServico?: string;
  idNotaFiscalSaida?: string;
}

interface INotaFiscalSaidaImportacaoOrdemServicoContext {
  setTitulo(titulo: string): void;
  abrirNotaFiscalSaidaImportacaoOrdemServico({
    idPessoa,
    idOrdemServico,
    idNotaFiscalSaida,
  }: IAbrirNotaFiscalSaidaImportacaoOrdemServico): Promise<any>;
  fecharNotaFiscalSaidaImportacaoOrdemServico(): void;
}

const NotaFiscalSaidaImportacaoOrdemServicoContext =
  createContext<INotaFiscalSaidaImportacaoOrdemServicoContext>(
    {} as INotaFiscalSaidaImportacaoOrdemServicoContext
  );

const NotaFiscalSaidaImportacaoOrdemServicoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idOrdemServicoOrigem, setIdOrdemServicoOrigem] = useState<
    string | undefined
  >();
  const [idNotaFiscalSaidaOrigem, setIdNotaFiscalSaidaOrigem] = useState<
    string | undefined
  >();
  const [origemOrdemServico, setOrigemOrdemServico] = useState(true);
  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);
  // #region Etapa 1 - Pedido Vendas

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref =
    useRef<INotaFiscalSaidaImportacaoOrdemServicoEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(
    async (idPessoa: string, idNotaFiscalSaida?: string) => {
      try {
        setLoading(true);

        const response =
          await NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicador.index(
            { idPessoa, idNotaFiscalSaida }
          );

        const listaOrdenada = response.listaOrdemServico.Ordenar([
          { campo: 'numero', tipo: 'desc' },
        ]);

        await formRefEtapa1.current?.setDataInicial({
          listaEtapa1: listaOrdenada,
        });
        etapa1Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 1 - Pedido Vendas

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref =
    useRef<INotaFiscalSaidaImportacaoOrdemServicoEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idOrdemServico?: string) => {
      try {
        setLoading(true);

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoOrdemServicoEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[];

        let listaIdOrdemServicoSelecionados: string[] = [];
        if (idOrdemServico) {
          listaIdOrdemServicoSelecionados.push(idOrdemServico);
        } else {
          listaIdOrdemServicoSelecionados = listaItemEtapa1.map(
            (item) => item.id
          );
        }

        const response =
          await NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicador.index(
            { listaIdOrdemServico: listaIdOrdemServicoSelecionados }
          );

        if (listaItemEtapa2) {
          response.listaOrdemServicoItem = response.listaOrdemServicoItem.map(
            (
              itemEtapa2Novo: INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista
            ) => {
              const itemExistente = listaItemEtapa2.find(
                (
                  itemEtapa2Atual: INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista
                ) => itemEtapa2Atual.id === itemEtapa2Novo.id
              );

              if (itemExistente) {
                itemExistente.ordem = itemEtapa2Novo.ordem;
                return itemExistente;
              }

              return itemEtapa2Novo;
            }
          );
        }

        const listaOrdenada = response.listaOrdemServicoItem.Ordenar([
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicial({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 2 - Itens

  // #region Etapa 3 - Estoques

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref =
    useRef<INotaFiscalSaidaImportacaoOrdemServicoEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[];

      const listaItemEtapa2Selecionado: any[] = [];

      const listaValoresEtapa3Novo = listaItemEtapa2.map((item) => {
        listaItemEtapa2Selecionado.push({
          idOrdemServicoItem: item.id,
          produto: {
            id: item.produto.id,

            produtoEstoque: {
              controlaLote: item.produto.produtoEstoque.controlarLote,
            },
          },
          quantidade: item.quantidadeTransferir,
        });

        const retorno: INotaFiscalSaidaImportacaoOrdemServicoEtapa3Lista = {
          id: item.id,
          ordem: item.ordem,

          pessoa: item.pessoa,
          produto: item.produto,
          unidadeMedida: item.unidadeMedida,
          quantidade: item.quantidade,
          quantidadeTransferir: item.quantidadeTransferir,
          valorUnitario: item.valorUnitario,
          valorTotal: item.valorTotal,
          modalidadeFrete: item.modalidadeFrete,

          ordemServicoItemEstoque: {
            movimentarEstoque: item.produto.produtoEstoque.controlarEstoque,
            unidadeMedida: item.unidadeMedida,
            indicadorTipoEstoque:
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
            fatorConversao: 1,
            quantidadeEstoque: item.quantidadeTransferir,
            quantidadeTotalEstoqueLocalEstoque: 0,
            porContaOrdemTerceiro: false,

            listaEstoqueLocalEstoque: [],
          },
        };

        return retorno;
      });

      const response =
        await NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicador.index(
          { listaItemSelecionado: listaItemEtapa2Selecionado }
        );

      for (let i = 0; i < response.length; i++) {
        const estoqueRetorno = response[i];

        const itemEtapa3Novo = listaValoresEtapa3Novo.find(
          (element) => element.id === estoqueRetorno.idOrdemServicoItem
        );

        if (itemEtapa3Novo) {
          itemEtapa3Novo.ordemServicoItemEstoque.movimentarEstoque =
            estoqueRetorno.ordemServicoItemEstoque.movimentarEstoque;
          itemEtapa3Novo.ordemServicoItemEstoque.unidadeMedida =
            estoqueRetorno.ordemServicoItemEstoque.unidadeMedida;
          itemEtapa3Novo.ordemServicoItemEstoque.pessoaTerceiro =
            estoqueRetorno.ordemServicoItemEstoque.pessoaTerceiro;

          itemEtapa3Novo.ordemServicoItemEstoque.indicadorTipoEstoque =
            estoqueRetorno.ordemServicoItemEstoque.indicadorTipoEstoque;
          itemEtapa3Novo.ordemServicoItemEstoque.fatorConversao =
            estoqueRetorno.ordemServicoItemEstoque.fatorConversao;
          itemEtapa3Novo.ordemServicoItemEstoque.quantidadeEstoque =
            estoqueRetorno.ordemServicoItemEstoque.quantidadeEstoque;
          itemEtapa3Novo.ordemServicoItemEstoque.quantidadeTotalEstoqueLocalEstoque =
            estoqueRetorno.ordemServicoItemEstoque.quantidadeTotalEstoqueLocalEstoque;
          itemEtapa3Novo.ordemServicoItemEstoque.porContaOrdemTerceiro =
            estoqueRetorno.ordemServicoItemEstoque.porContaOrdemTerceiro;

          itemEtapa3Novo.ordemServicoItemEstoque.listaEstoqueLocalEstoque =
            estoqueRetorno.ordemServicoItemEstoque.listaEstoqueLocalEstoque;
        }
      }

      const listaOrdenada = listaValoresEtapa3Novo.Ordenar([
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa3.current?.setDataInicial({
        listaEtapa3: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicial({
        listaEtapa3: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 3 - Estoques

  // #region Etapa 4 - Serie

  const formRefEtapa4 = useRef<IFormCiaHandles>(null);
  const etapa4Ref =
    useRef<INotaFiscalSaidaImportacaoOrdemServicoEtapa4Ref>(null);

  const handleCarregarDadosEtapa4 = useCallback(async () => {
    try {
      setLoading(true);
      let idModeloDocumento = '';
      let finalidadeEmissao = '';
      if (idOrdemServicoOrigem) {
        const ordemServico = await OrdemServicoComunicador.show({
          id: idOrdemServicoOrigem,
        });
        if (ordemServico) {
          idModeloDocumento = ordemServico.idModeloDocumento;
          finalidadeEmissao = ordemServico.finalidadeEmissao;
        }
      }

      const valoresEtapa4 = formRefEtapa4.current?.getData() as any;
      if (!valoresEtapa4 || !valoresEtapa4.etapa4?.idSerie) {
        const response = await NotaFiscalSaidaObterDadosPadraoComunicador.show({
          params: {
            idModeloDocumento: idModeloDocumento ?? undefined,
          },
        });
        await formRefEtapa4.current?.setDataInicial({
          etapa4: {
            serie: response.serie,
            finalidadeEmissao,
          },
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idOrdemServicoOrigem]);

  // #endregion Etapa 4 - Serie

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!etapa1Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;

      case 2:
        if (!etapa2Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa3();
        break;

      case 3:
        if (!etapa3Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa4();
        break;
    }
  }, [
    handleCarregarDadosEtapa2,
    handleCarregarDadosEtapa3,
    handleCarregarDadosEtapa4,
    proximoStep,
    step,
  ]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      idOrdemServico,
      idNotaFiscalSaida,
    }: IAbrirNotaFiscalSaidaImportacaoOrdemServico): Promise<any> => {
      setAberto(true);
      setIdOrdemServicoOrigem(idOrdemServico);
      setIdNotaFiscalSaidaOrigem(idNotaFiscalSaida);

      if (!idOrdemServico) {
        setOrigemOrdemServico(false);
        setStep(1);
        await handleCarregarDadosEtapa1(idPessoa, idNotaFiscalSaida);
      } else {
        setOrigemOrdemServico(true);
        setStep(2);
        await handleCarregarDadosEtapa2(idOrdemServico);
      }

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, handleCarregarDadosEtapa2, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFreteOrdemServico = useCallback(
    async (
      valoresEtapa3: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFretePedido: ModalidadeFreteEnum | null = null;

      if (valoresEtapa3) {
        if (valoresEtapa3.listaEtapa3.length > 1) {
          valoresEtapa3.listaEtapa3.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFretePedido = null;
                } else {
                  modalidadeFretePedido = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFretePedido = null;
              }
            }
          });
        } else {
          modalidadeFretePedido = valoresEtapa3.listaEtapa3[0].modalidadeFrete;
        }
      }

      if (!isModalidadeFreteDiferente && modalidadeFretePedido) {
        const notaFiscalSaida = await NotaFiscalSaidaComunicador.show({
          id: String(idNotaFiscalSaidaOrigem),
        });
        if (
          modalidadeFretePedido !==
          notaFiscalSaida.notaFiscalSaidaTransporteTransportadora
            .modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade de Frete da Nota Fiscal: ${notaFiscalSaida.notaFiscalSaidaTransporteTransportadora.modalidadeFrete} e do Pedido ${modalidadeFretePedido}, deseja atualizar a Nota Fiscal para ${modalidadeFretePedido}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idNotaFiscalSaidaOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const valoresEtapa3 = formRefEtapa3.current?.getData();
      let response: any = null;
      if (origemOrdemServico) {
        if (!etapa4Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const valoresEtapa4 = formRefEtapa4.current?.getData();

        response =
          await NotaFiscalSaidaImportacaoOrdemServicoGerarComunicador.store({
            params: { valoresEtapa3, valoresEtapa4, idOrdemServicoOrigem },
          });
      } else {
        if (!etapa3Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFreteOrdemServico(valoresEtapa3);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção Pedidos com modalidade de frete diferente entre si, favor verificar a modalidade de frete da Nota Fiscal!'
          );
        }

        response =
          await NotaFiscalSaidaImportacaoOrdemServicoImportarComunicador.store({
            params: {
              valoresEtapa3,
              idNotaFiscalSaidaOrigem,
              alterarModalidadeFrete: resposta,
            },
          });
      }

      if (
        response.listaMensagemAviso &&
        response.listaMensagemAviso.length > 0
      ) {
        TratarInfo(response.listaMensagemAviso);
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleValidarModalidadeFreteOrdemServico,
    idNotaFiscalSaidaOrigem,
    idOrdemServicoOrigem,
    origemOrdemServico,
  ]);

  return (
    <NotaFiscalSaidaImportacaoOrdemServicoContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirNotaFiscalSaidaImportacaoOrdemServico: handleAbrir,
        fecharNotaFiscalSaidaImportacaoOrdemServico: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Ordens de Serviço</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>

              {origemOrdemServico && (
                <Step>
                  <StepBox type="button" $selecionado={step === 4}>
                    4
                  </StepBox>
                  <StepNome>Série</StepNome>
                </Step>
              )}
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <NotaFiscalSaidaImportacaoOrdemServicoEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <NotaFiscalSaidaImportacaoOrdemServicoEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <NotaFiscalSaidaImportacaoOrdemServicoEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 4 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa4}>
              <NotaFiscalSaidaImportacaoOrdemServicoEtapa4
                name="etapa4"
                formRef={formRefEtapa4}
                ref={etapa4Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {origemOrdemServico && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handleFechar}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                          }}
                        >
                          <MdCancel />
                          <span style={{ marginLeft: 10 }}>Cancelar</span>
                        </span>
                      </button>
                    )}

                    {!origemOrdemServico && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handlePassoAnterior}
                      >
                        <IoMdReturnLeft />
                        <span style={{ marginLeft: 10 }}>Voltar</span>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    {origemOrdemServico && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleProximoPasso}
                        style={{
                          marginLeft: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ImArrowRight />
                        <span style={{ marginLeft: 10 }}>Próximo passo</span>
                      </button>
                    )}

                    {!origemOrdemServico && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleSubmit}
                        style={{ marginLeft: 10 }}
                      >
                        <FiRefreshCcw />
                        <span style={{ marginLeft: 10 }}>Concluir</span>
                      </button>
                    )}
                  </div>
                </div>

                <div style={{ display: step === 4 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </NotaFiscalSaidaImportacaoOrdemServicoContext.Provider>
  );
};

function UseNotaFiscalSaidaImportacaoOrdemServico(): INotaFiscalSaidaImportacaoOrdemServicoContext {
  const context = useContext(NotaFiscalSaidaImportacaoOrdemServicoContext);

  if (!context) {
    throw new Error(
      'UseNotaFiscalSaidaImportacaoOrdemServico deve ser usado com um OrdemServicoProvider'
    );
  }

  return context;
}

export {
  NotaFiscalSaidaImportacaoOrdemServicoHook,
  UseNotaFiscalSaidaImportacaoOrdemServico,
};
