const MovimentacaoPorPortadorPlanilha = [
  {
    titulo: 'Portador',
    valor: 'chave',
  },
  {
    titulo: 'Saldo Anterior',
    valor: 'listaMovimentacaoPortador.saldoAnterior',
  },

  {
    titulo: 'Núm Documento',
    valor: 'listaMovimentacaoPortador.numeroDocumento',
  },
  {
    titulo: 'Data Liquidação',
    valor: 'listaMovimentacaoPortador.data',
  },
  {
    titulo: 'Data Contábil',
    valor: 'listaMovimentacaoPortador.dataContabil',
  },
  {
    titulo: 'Cliente/Fornecedor',
    valor: 'listaMovimentacaoPortador.nomeRazaoSocial',
  },
  {
    titulo: 'Observações',
    valor: 'listaMovimentacaoPortador.observacoes',
  },
  {
    titulo: 'Forma Pagamento',
    valor: 'listaMovimentacaoPortador.formaPagamento',
  },

  {
    titulo: 'Tipo',
    valor: 'listaMovimentacaoPortador.tipo',
  },
  {
    titulo: 'Valor',
    valor: 'listaMovimentacaoPortador.valor',
  },
  {
    titulo: 'Saldo',
    valor: 'listaMovimentacaoPortador.saldo',
  },
];

export default MovimentacaoPorPortadorPlanilha;
