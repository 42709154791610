import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda/impressao/retrato-com-sem-valor-itens-transporte';

interface IPedidoVendaRetratoComSemValoresItenstranspFotoImpressaoComunicadorShow {
  id: string;
}

class PedidoVendaRetratoComSemValoresItenstranspFotoImpressaoComunicador {
  public async show(
    params: IPedidoVendaRetratoComSemValoresItenstranspFotoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaRetratoComSemValoresItenstranspFotoImpressaoComunicador();
