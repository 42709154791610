/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useReducer } from 'react';

interface IAcao {
  tipo: 'abrir' | 'fechar' | 'toggle';
}

function reducer(state: boolean, acao: IAcao) {
  switch (acao.tipo) {
    case 'abrir':
      return true;

    case 'fechar':
      return false;

    case 'toggle':
      return !state;

    default:
      throw new Error('Nenhuma ação valida foi passada!');
  }
}

function UseModalAberta(valorInicial = false) {
  return useReducer(reducer, valorInicial);
}

export default UseModalAberta;
