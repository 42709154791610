import { INotaFiscalCartaCorrecaoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../Util/Relatorios/PDF';
import NotaFiscalCartaCorrecaoImpressaoPDF from './NotaFiscalCartaCorrecaoImpressaoPDF';

interface INotaFiscalImpressaoCartaCorrecao {
  handleVisualizarPdf(dados: INotaFiscalCartaCorrecaoImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: INotaFiscalCartaCorrecaoImpressao
  ): Promise<Blob | undefined>;
}

const NotaFiscalImpressaoCartaCorrecao =
  (): INotaFiscalImpressaoCartaCorrecao => {
    async function handleVisualizarPdf(
      dados: INotaFiscalCartaCorrecaoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: <NotaFiscalCartaCorrecaoImpressaoPDF dados={dados} />,
      });
      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: INotaFiscalCartaCorrecaoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <NotaFiscalCartaCorrecaoImpressaoPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default NotaFiscalImpressaoCartaCorrecao();
