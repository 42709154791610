const PessoaPorCidadePlanilha = [
  {
    titulo: 'Cidade',
    valor: 'listaPessoa.pessoaEnderecoPrincipal.cidade.nome',
  },
  {
    titulo: 'Código',
    valor: 'listaPessoa.codigo',
  },
  {
    titulo: 'Nome / RazãoSocial',
    valor: 'listaPessoa.nomeRazaoSocial',
  },
  {
    titulo: 'CNPJ/CPF',
    valor: 'listaPessoa.cnpjCpfVirtual',
  },
  {
    titulo: 'Telefone',
    valor: 'listaPessoa.telefone',
  },
  {
    titulo: 'Inscr Estadual',
    valor: 'listaPessoa.inscricaoEstadual',
  },
  {
    titulo: 'Email',
    valor: 'listaPessoa.email',
  },
  {
    titulo: 'Logradouro',
    valor: 'listaPessoa.pessoaEnderecoPrincipal.logradouro',
  },
  { titulo: 'Número', valor: 'listaPessoa.pessoaEnderecoPrincipal.numero' },
  {
    titulo: 'Complemento',
    valor: 'listaPessoa.pessoaEnderecoPrincipal.complemento',
  },
  { titulo: 'CEP', valor: 'listaPessoa.pessoaEnderecoPrincipal.cep' },
  {
    titulo: 'Contato',
    valor: 'listaPessoa.listaPessoaContato.nome',
  },
  {
    titulo: 'Contato Telefone',
    valor: 'listaPessoa.listaPessoaContato.telefone',
  },
  {
    titulo: 'Contato Email',
    valor: 'listaPessoa.listaPessoaContato.email',
  },
];

export default PessoaPorCidadePlanilha;
