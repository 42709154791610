import { AppErro, TipoCrGrupoEnum } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import UseFuncoesPadrao from '../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import ConfiguracaoBebidaRastreabilidadeSisdevinComunicador from '../../../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/ConfiguracaoBebidaRastreabilidadeSisdevin/Comunicador/ConfiguracaoBebidaRastreabilidadeSisdevinComunicador';
import { UseAutoComplete } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { permissoes } = UsePermissoes();
  const { SuprimentosSisdevinConfiguracaoCrEnologicoCultivar: permissao } =
    permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheCodigoRastreabilidade"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          tipoCrGrupo: Yup.string().required(
            'O Tipo CR ou Grupo é obrigatório!'
          ),

          codigoRastreabilidadeSisdevin: Yup.object().shape({
            codigoRastreabilidade: Yup.mixed()
              .nullable()
              .test({
                message: 'O Código de Rastreabilidade é obrigatório!',
                test: () => {
                  if (
                    data.tipoCrGrupo ===
                      TipoCrGrupoEnum.codigoRastreabilidade &&
                    !data.codigoRastreabilidadeSisdevin.codigoRastreabilidade
                  ) {
                    return false;
                  }
                  return true;
                },
              }),
          }),

          grupo: Yup.object().shape({
            grupoProdutoSisdevin: Yup.mixed()
              .nullable()
              .test({
                message: 'O Grupo de Produto é obrigatório!',
                test: () => {
                  if (
                    data.tipoCrGrupo === TipoCrGrupoEnum.grupo &&
                    !data.grupo.grupoProdutoSisdevin
                  ) {
                    return false;
                  }
                  return true;
                },
              }),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const id = getIdDetalheRegistro() || '';
        const idProdutoConfiguracaoBebidaSisdevin =
          formPrincipal.getIdDetalheRegistro() || '';

        const params = {
          idCodigoRastreabilidadeSisdevin:
            data.codigoRastreabilidadeSisdevin?.codigoRastreabilidade ??
            undefined,
          idGrupoProdutoSisdevin: data.grupo?.grupoProdutoSisdevin ?? undefined,
          idProdutoConfiguracaoBebidaSisdevin,
        };

        const response =
          await ConfiguracaoBebidaRastreabilidadeSisdevinComunicador.store({
            ...params,
          });
        setIdDetalheRegistro(response.id);

        formPrincipal.handleCarregarDados();
        await formRef?.reset();

        const toastSucessoMensagem =
          data.tipoCrGrupo === TipoCrGrupoEnum.grupo
            ? 'Grupo de Produto salvo!'
            : 'Código de Rastreabilidade salvo!';

        ToastSucesso(toastSucessoMensagem);
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleSalvarContinuar,
      handleValidar,
      isJanelaAutoComplete,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        await ConfiguracaoBebidaRastreabilidadeSisdevinComunicador.delete({
          id,
        });

        ToastSucesso('Registro Deletado!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
