import { IcmsCteCalculoPorCst } from '@elogestor/util';
import React, { createContext, useContext, useCallback } from 'react';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IImpostoIcmsHookProps {
  handleCalcularIcms: () => Promise<void>;
  handleAtualizarImpostoIcmsFiscalConformeCst: (cst: string) => void;
}

const ImpostoIcmsContext = createContext({} as IImpostoIcmsHookProps);

const ImpostoIcmsHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  const handleCalcularIcms = useCallback(async () => {
    const cst =
      formRef.current?.getFieldValueNomeObjeto(
        `conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria`
      )?.codigo ?? '';

    const percentualReducaoBaseCalculo = formRef.current?.getFieldValue(
      `conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo`
    );

    const valorTotal = formRef.current?.getFieldValue('valorTotalServico');
    const baseCalculo = formRef.current?.getFieldValue(
      `conhecimentoTransporteTerceiroImpostoIcms.baseCalculo`
    );

    const calcularIcms = formRef.current?.getFieldValue(
      `conhecimentoTransporteTerceiroImpostoIcms.calcularIcms`
    );

    const aliquota = formRef.current?.getFieldValue(
      `conhecimentoTransporteTerceiroImpostoIcms.aliquota`
    );

    const valor = formRef.current?.getFieldValue(
      `conhecimentoTransporteTerceiroImpostoIcms.valor`
    );

    const retorno = IcmsCteCalculoPorCst({
      cst,
      aliquota: Number(aliquota),
      baseCalculo: Number(baseCalculo),
      calcular: calcularIcms,
      percentualReducaoBaseCalculo: Number(percentualReducaoBaseCalculo),
      valor: Number(valor),
      valorTotal: Number(valorTotal),
    });

    await formRef.current?.setSemExecutarEvento({
      conhecimentoTransporteTerceiroImpostoIcms: {
        valor: retorno.valor,
        baseCalculo: retorno.baseCalculo,
      },
    });
  }, [formRef]);

  const handleAtualizarImpostoIcmsFiscalConformeCst = useCallback(
    (cst: string) => {
      const impostoIcmsFiscalPath =
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal';

      if (cst === '40' || cst === '41' || cst === '51' || cst === '60') {
        formRef.current?.setFieldValue(
          `${impostoIcmsFiscalPath}.baseCalculo`,
          null
        );
        formRef.current?.setFieldValue(
          `${impostoIcmsFiscalPath}.percentualReducaoBaseCalculo`,
          null
        );
        formRef.current?.setFieldValue(`${impostoIcmsFiscalPath}.valor`, null);
        formRef.current?.setFieldValue(
          `${impostoIcmsFiscalPath}.aliquota`,
          null
        );
      }

      if (cst === '00') {
        formRef.current?.setFieldValue(
          `${impostoIcmsFiscalPath}.percentualReducaoBaseCalculo`,
          null
        );
      }
    },
    [formRef]
  );

  return (
    <ImpostoIcmsContext.Provider
      value={{
        handleCalcularIcms,
        handleAtualizarImpostoIcmsFiscalConformeCst,
      }}
    >
      {children}
    </ImpostoIcmsContext.Provider>
  );
};

function UseImpostoIcms(): IImpostoIcmsHookProps {
  const context = useContext(ImpostoIcmsContext);
  return context;
}

export { ImpostoIcmsHook, UseImpostoIcms };
