"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoOrcamentoPedidoVendaEnum;
(function (SituacaoOrcamentoPedidoVendaEnum) {
    SituacaoOrcamentoPedidoVendaEnum["atendido"] = "Atendido";
    SituacaoOrcamentoPedidoVendaEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoOrcamentoPedidoVendaEnum["naoAtendido"] = "N\u00E3o Atendido";
})(SituacaoOrcamentoPedidoVendaEnum || (SituacaoOrcamentoPedidoVendaEnum = {}));
exports.default = SituacaoOrcamentoPedidoVendaEnum;
