import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { ListaRoteiroFabricacaoOperacaoDraggableHook } from './ListaRoteiroFabricacaoOperacaoDraggableHook';
import RotasHook from './RotasHook';

const RoteiroFabricacaoOperacaoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <RotasHook>
      <ListaRoteiroFabricacaoOperacaoDraggableHook>
        <FormHook>{children}</FormHook>
      </ListaRoteiroFabricacaoOperacaoDraggableHook>
    </RotasHook>
  );
};

export default RoteiroFabricacaoOperacaoHook;
