import styled, { css } from 'styled-components';
import { DefaultInputDiv } from '../../Styles';

interface IItem {
  $navegacao: boolean;
}

export const InputDiv = styled(DefaultInputDiv)``;

export const ContainerListaItemAtual = styled.ul`
  display: flex;
  overflow: auto;
  align-items: center;
  flex-wrap: wrap;
  margin: 4px 0px 4px 0px;
  height: auto;

  li {
    margin-right: 10px;

    &.li-valor {
      background: #add8e6;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #76bed5;
      font-size: 12px;
      word-wrap: break-word;
      button {
        background: transparent;
        border: 0;
        margin-left: 5px;
      }
    }

    input {
      width: 1ch;
      height: 35px;
      border: 0;
      margin: 0;
      padding: 0;
      color: #000;
    }
  }
`;

export const ContainerListaItem = styled.ul`
  position: absolute;
  background: #fff;
  color: #000;
  width: 100%;
  max-height: 150px;

  overflow-y: hidden;

  margin-top: 10px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  border-radius: 5px;

  z-index: 10;
  > li {
    width: 100%;
    border-bottom: 1px solid lightgray;

    button {
      display: flex;
      width: 100%;
      border: none;
      background: transparent;
    }
  }
`;

export const Item = styled.span<IItem>`
  font-size: 17px;
  width: 100%;
  text-align: left;
  padding: 5px;
  &:hover {
    background: lightgray;
  }

  ${({ $navegacao }) =>
    $navegacao &&
    css`
      background: lightgray;
    `}
`;
