import { FinalidadeEmissaoEnum, TipoMercadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/obter-nota-fiscal-saida-item-operacao-fiscal';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemOperacaoFiscalComunicadorIndex {
  idPessoa: string;
  idNotaFiscalSaida?: string;
  idNotaFiscalEntrada?: string;
  finalidadeEmissao?: FinalidadeEmissaoEnum;
  tipoMercado?: TipoMercadoEnum;
  listaItemSelecionado: any[];
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemOperacaoFiscalComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemOperacaoFiscalComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemOperacaoFiscalComunicador();
