import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/consulta-aliquota-ipi-por-ncm/importacao-tipi';

interface IImportacaoTipiComunicadorStore {
  params: any;
}

class ImportacaoTipiComunicador {
  public async store({
    params,
  }: IImportacaoTipiComunicadorStore): Promise<any> {
    const response = await api.post(rota, { params });
    return response.data;
  }
}

export default new ImportacaoTipiComunicador();
