const ExtratoPorProdutoLote = [
  { titulo: 'Código Produto', valor: 'produto.codigo' },
  { titulo: 'Descrição Produto', valor: 'produto.descricao' },
  { titulo: 'Lote', valor: 'lote.codigo' },
  { titulo: 'Tipo de Estoque', valor: 'tipo' },
  {
    titulo: 'Número Documento',
    valor: 'listaMovimentos.numeroDocumento',
  },
  { titulo: 'Data Movimento', valor: 'listaMovimentos.dataMovimento' },
  {
    titulo: 'Histórico',
    valor: 'listaMovimentos.historico',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaMovimentos.quantidade',
  },
  { titulo: 'Saldo', valor: 'listaMovimentos.saldo' },
];

export default ExtratoPorProdutoLote;
