import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  TipoPropriedadeVeiculoEnum,
  IVeiculoValoresAlterar,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import VeiculoComunicador from '../../../../../Comunicador/Transporte/Veiculo/Comunicador/VeiculoComunicador';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseVeiculoDuplicar } from './DuplicarHook';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { abrirVeiculoDuplicar } = UseVeiculoDuplicar();
  const rota = UseRota();
  const navigate = useNavigate();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const isTerceiro =
    formRef.current?.getFieldValue('tipoPropriedade') ===
    TipoPropriedadeVeiculoEnum.terceiro;

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IVeiculoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;

        dadosDuplicados.listaVeiculoMotorista.forEach((dados: any) => {
          dados.id = undefined;
          dados.idVeiculo = undefined;
        });

        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await VeiculoComunicador.show({ id: idEditar });
        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({ ativo: true, ...dadosPadrao });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          placa: Yup.string().required('Placa é obrigatória!'),
          idEstado: Yup.string().nullable().required('UF é obrigatório!'),

          idPessoaProprietario: Yup.mixed()
            .nullable()
            .test({
              message:
                'Proprietário é obrigatório em caso de tipo de propriedade terceiro!',
              test: (value: any) => {
                if (!value && isTerceiro) {
                  return false;
                }
                return true;
              },
            }),

          rntrc: Yup.mixed()
            .nullable()
            .test({
              message:
                'RNTRC é obrigatório em caso de tipo de propriedade terceiro!',
              test: (value: any) => {
                if (!value && isTerceiro) {
                  return false;
                }
                return true;
              },
            }),

          tipoProprietario: Yup.mixed()
            .nullable()
            .test({
              message:
                'Tipo de proprietário é obrigatório em caso de tipo de propriedade terceiro!',
              test: (value: any) => {
                if (!value && isTerceiro) {
                  return false;
                }
                return true;
              },
            }),

          tara: Yup.number().nullable().min(0, 'Tara não pode ser negativa!'),
          capacidadeKg: Yup.number()
            .nullable()
            .min(0, 'Capacidade (Kg) não pode ser negativa!'),
          capacidadeM3: Yup.number()
            .nullable()
            .min(0, 'Capacidade M3 não pode ser negativa!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [isTerceiro]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await VeiculoComunicador.update({ id, params: data });
        } else {
          const response = await VeiculoComunicador.store({ params: data });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const dadosDuplicados = formRef.current?.getData();
      if (!dadosDuplicados) return;

      dadosDuplicados.ativo = true;

      dadosDuplicados.listaVeiculoMotorista.forEach((dados: any) => {
        dados.id = undefined;
        dados.idVeiculo = undefined;
      });

      const placa = await abrirVeiculoDuplicar(dadosDuplicados.placa);

      if (!placa) {
        setLoading(false);
        return;
      }

      dadosDuplicados.placa = placa;

      const response = await VeiculoComunicador.store({
        params: dadosDuplicados,
      });
      setIdDetalheRegistro(response.id);

      navigate({
        pathname: `${rota.detalhes}/${response.id}`,
      });

      await formRef.current?.setDisableAll(false);
      handleCarregarDados();
      ToastSucesso('Registro Duplicado e Salvo!');

      setLoading(false);
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    abrirVeiculoDuplicar,
    handleCarregarDados,
    navigate,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
