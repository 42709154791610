import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { ShowTabsHook } from './ShowTabsHook';
import { ParticipantesHook } from './ParticipantesHook';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import ConhecimentoTransporteTerceiroComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const ConhecimentoTransporteTerceiroHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: ConhecimentoTransporteTerceiroComunicador }}
      >
        <RotasHook>
          <ShowTabsHook>
            <RegimeTributarioHook>
              <FormHook>
                <ParticipantesHook>
                  <SubHeaderHook>{children}</SubHeaderHook>
                </ParticipantesHook>
              </FormHook>
            </RegimeTributarioHook>
          </ShowTabsHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default ConhecimentoTransporteTerceiroHook;
