import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa/certificado/obter-certificado';

interface ITerminalCaixaEmpresaObterCertificadoComunicadorShow {
  idTerminalCaixa: string;
}

class TerminalCaixaEmpresaObterCertificadoComunicador {
  public async show(
    params: ITerminalCaixaEmpresaObterCertificadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.idTerminalCaixa}`);

    return response.data;
  }
}

export default new TerminalCaixaEmpresaObterCertificadoComunicador();
