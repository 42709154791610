import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TipoPedidoVendaEnum } from '@elogestor/util';
import IPermissao from '../../../Hooks/Interfaces/IPermissao';
import TratarErros from '../../../Util/Erro/TratarErros';
import ToastInfo from '../../../Util/Toasts/ToastInfo';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../Confirmacao/HooksConfirmacao';
import { UseComunicador } from './ComunicadorContext';
import { UseForm } from './FormContext';
import { UseRota } from './RotasContext';
import ContaRevertidaComunicador from '../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';
import ToastErro from '../../../Util/Toasts/ToastErro';
import { UseLiberacoes } from '../../../Hooks/LiberacoesHook';
import NotaFiscalSaidaObterVendaPdvComunicador from '../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterVendaPdvComunicador';
import PedidoVendaObterRetiradaConsignadoComunicador from '../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaObterRetiradaConsignadoComunicador';

interface IExcluir {
  isAberturaFechamentoCaixa?: boolean;
  isTerminalCaixa?: boolean;
  isOrcamento?: boolean;
  isPedidoVenda?: boolean;
  isNotaFiscalSaida?: boolean;
  isNotaFiscalEntrada?: boolean;
  isConhecimentoTransporte?: boolean;
  isConhecimentoTransporteTerceiro?: boolean;
  isOrdemServico?: boolean;
}

interface IUseFuncoesPadrao {
  handleSalvar(): Promise<void>;
  handleSalvarContinuar(
    isJanelaAutoComplete?: boolean
  ): Promise<{ erro: boolean }>;
  handleSalvarNovo(): Promise<void>;
  handleDuplicar(): Promise<void>;
  handleDuplicarSalvar(): Promise<void>;
  handleExcluir(excluirOptions?: IExcluir): Promise<void>;
}

const UseFuncoesPadrao = (
  permissao: IPermissao | undefined
): IUseFuncoesPadrao => {
  const navigate = useNavigate();
  const { abrirJanela } = UseConfirmacao();
  const { comunicador } = UseComunicador();
  const rotas = UseRota();
  const location = useLocation();
  const liberacoes = UseLiberacoes();

  const {
    loading,
    setLoading,
    handleSubmit,
    formRef,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleDuplicarSalvar: handleDuplicarSalvarForm,
    handleDuplicar: handleDuplicarForm,
    setIdDetalheRegistro,
  } = UseForm();

  const handleSalvar = useCallback(async (): Promise<void> => {
    if (loading) return;
    const data = formRef.current?.getData();
    const { erro } = await handleSubmit(data);
    if (erro) return;

    navigate(rotas.listagem);
  }, [formRef, handleSubmit, navigate, loading, rotas.listagem]);

  const handleSalvarContinuar = useCallback(
    async (
      isJanelaAutoComplete?: boolean
    ): Promise<{
      erro: boolean;
    }> => {
      if (loading) return { erro: false };
      const data = formRef.current?.getData();
      const idRegistro = getIdDetalheRegistro();
      const { erro } = await handleSubmit(data);

      if (erro) {
        return { erro: true };
      }

      if (!idRegistro && !isJanelaAutoComplete) {
        navigate(`${location.pathname}/${getIdDetalheRegistro()}`, {
          replace: true,
        });
      }

      await handleCarregarDados();
      return { erro: false };
    },
    [
      loading,
      formRef,
      getIdDetalheRegistro,
      handleSubmit,
      handleCarregarDados,
      navigate,
      location.pathname,
    ]
  );

  const handleSalvarNovo = useCallback(async (): Promise<void> => {
    if (loading) return;
    const data = formRef.current?.getData();
    const { erro } = await handleSubmit(data);

    if (erro) return;

    navigate(rotas.detalhes);
    setIdDetalheRegistro('');
    handleCarregarDados();
  }, [
    formRef,
    handleCarregarDados,
    handleSubmit,
    navigate,
    loading,
    rotas.detalhes,
    setIdDetalheRegistro,
  ]);

  const handleDuplicar = useCallback(async (): Promise<void> => {
    if (loading) return;
    const data = formRef.current?.getDataDuplicar() as any;

    if (permissao?.altera) {
      if (formRef.current?.validarSeAlterou()) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: <span style={{ fontSize: 20 }}>Deseja salvar o item?</span>,
        });

        if (resposta) {
          const { erro } = await handleSubmit(formRef.current.getData());
          if (erro) return;
        } else {
          await formRef.current.atualizarDataInicial();
        }
      }
    }

    delete data?.id;

    navigate('/home', { replace: true });
    navigate(rotas.detalhes, {
      state: {
        dadosDuplicados: data,
      },
    });

    ToastInfo('Registro Duplicado!');
  }, [
    abrirJanela,
    formRef,
    handleSubmit,
    navigate,
    loading,
    permissao,
    rotas.detalhes,
  ]);

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    if (loading) return;
    if (permissao?.altera) {
      if (formRef.current?.validarSeAlterou()) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: <span style={{ fontSize: 20 }}>Deseja salvar o item?</span>,
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (resposta) {
          const { erro } = await handleSubmit(formRef.current.getData());
          if (erro) return;
        } else {
          await formRef.current.atualizarDataInicial();
        }
      }
    }

    if (!handleDuplicarSalvarForm) {
      throw new Error(
        'Erro ao Duplicar o Registro. Função handleDuplicarSalvarForm não implementada!'
      );
    }

    await handleDuplicarSalvarForm();
  }, [
    loading,
    permissao,
    handleDuplicarSalvarForm,
    formRef,
    abrirJanela,
    handleSubmit,
  ]);

  const handleExcluir = useCallback(
    async (dados: IExcluir) => {
      if (loading) return;

      const id = getIdDetalheRegistro();
      if (!id) return;

      const retorno1 = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (!retorno1) {
        setLoading(false);
        return;
      }

      if (dados) {
        if (dados.isNotaFiscalSaida) {
          if (!liberacoes.permiteExcluirCupon) {
            const responseNotaFiscalSaida =
              await NotaFiscalSaidaObterVendaPdvComunicador.show({ id });

            if (responseNotaFiscalSaida.vendaPdv) {
              ToastErro(
                'Usuário sem permissão para excluir Cupom/Nota de Consumidor!'
              );
              return;
            }
          }
        }

        if (dados.isPedidoVenda) {
          if (!liberacoes.permiteExcluirConsignado) {
            const responsePedidoVenda =
              await PedidoVendaObterRetiradaConsignadoComunicador.show({ id });

            if (
              responsePedidoVenda.tipoPedidoVenda ===
              TipoPedidoVendaEnum.consignado
            ) {
              ToastErro('Usuário sem permissão para excluir Consignado!');
              return;
            }
          }
        }

        if (dados.isAberturaFechamentoCaixa) {
          const storage = localStorage.getItem('@EloGestorle:turno');
          const turno = JSON.parse(String(storage));

          if (turno && turno.id === id) {
            localStorage.removeItem('@EloGestorle:turno');
          }
        }

        if (dados.isTerminalCaixa) {
          const storage = localStorage.getItem('@EloGestorle:terminalCaixa');
          const terminalCaixa = JSON.parse(String(storage));

          if (terminalCaixa && terminalCaixa.id === id) {
            localStorage.removeItem('@EloGestorle:terminalCaixa');
          }
        }

        if (
          dados.isOrcamento ||
          dados.isPedidoVenda ||
          dados.isNotaFiscalSaida ||
          dados.isNotaFiscalEntrada ||
          dados.isConhecimentoTransporte ||
          dados.isConhecimentoTransporteTerceiro ||
          dados.isOrdemServico
        ) {
          const retorno2 = await ContaRevertidaComunicador.index({
            params: {
              idOrcamento: dados.isOrcamento ? id : undefined,
              idPedidoVenda: dados.isPedidoVenda ? id : undefined,
              idNotaFiscalSaida: dados.isNotaFiscalSaida ? id : undefined,
              idNotaFiscalEntrada: dados.isNotaFiscalEntrada ? id : undefined,
              idConhecimentoTransporte: dados.isConhecimentoTransporte
                ? id
                : undefined,
              idConhecimentoTransporteTerceiro:
                dados.isConhecimentoTransporteTerceiro ? id : undefined,
              idOrdemServico: dados.isOrdemServico ? id : undefined,
            },
          });

          if (retorno2.possuiContaParcelaRevertida) {
            const resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 20 }}>
                  Essa conta possui uma reversão. Deseja excluir a conta
                  revertida?
                </span>
              ),
              confimar: 'Sim',
              cancelar: 'Não',
            });

            if (!resposta) {
              setLoading(false);
              return;
            }
          }
        }
      }

      try {
        setLoading(true);
        await formRef.current?.atualizarDataInicial();

        await comunicador.delete({ id });
        navigate(rotas.listagem);

        ToastSucesso('Registro Deletado!');
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      loading,
      getIdDetalheRegistro,
      abrirJanela,
      setLoading,
      liberacoes.permiteExcluirCupon,
      liberacoes.permiteExcluirConsignado,
      formRef,
      comunicador,
      navigate,
      rotas.listagem,
    ]
  );

  return {
    handleSalvar,
    handleSalvarContinuar,
    handleSalvarNovo,
    handleDuplicar: handleDuplicarForm || handleDuplicar,
    handleDuplicarSalvar,
    handleExcluir,
  };
};

export default UseFuncoesPadrao;
