import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/certificado/upload';

interface IEmpresaCertificadoUploadComunicadorUpdate {
  params: any;
}

class EmpresaCertificadoUploadComunicador {
  public async update(
    params: IEmpresaCertificadoUploadComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new EmpresaCertificadoUploadComunicador();
