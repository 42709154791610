const EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha = [
  { titulo: 'Código', valor: 'estoqueCombinacao.produto.codigo' },
  { titulo: 'Descrição', valor: 'estoqueCombinacao.produto.descricao' },
  {
    titulo: 'Unidade de Medida',
    valor: 'estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'saldo' },
  { titulo: 'Nec Produção', valor: 'necessidadeProducao' },
  { titulo: 'Nec Compra', valor: 'necessidadeCompra' },
];

export default EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha;
