import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';

const ObservacaoInternaTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 125 }}
              label="Informações Gerais"
              name="informacoesGerais"
              maxLength={1500}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 125 }}
              label="Informações do Problema Relatado"
              name="informacoesProblemaRelatado"
              maxLength={1500}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 125 }}
              label="Informações Laudo Técnico"
              name="informacoesLaudoTecnico"
              maxLength={1500}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 125 }}
              label="Informações Internas"
              name="informacoesInternas"
              maxLength={1500}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ObservacaoInternaTab;
