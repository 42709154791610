import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';
import { UseNotaFiscalEntrada } from '../Hook/NotaFiscalEntradaHook';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const TotaisTab: React.FC = () => {
  const {
    setFormaRateioValorFrete,
    setFormaRateioValorSeguro,
    setFormaRateioValorOutrasDespesas,
    setFormaRateioValorDesconto,
    atualizarRateiosListaItem,
  } = UseNotaFiscalEntrada();

  const { formRef } = UseForm();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  InputHidden({ name: 'freteFormaCalculoPorRateio' });
  InputHidden({ name: 'seguroFormaCalculoPorRateio' });
  InputHidden({ name: 'outrasDespesasFormaCalculoPorRateio' });
  InputHidden({ name: 'descontoFormaCalculoPorRateio' });

  const handleAtualizarRateiosListaItem = useCallback(() => {
    atualizarRateiosListaItem();
  }, [atualizarRateiosListaItem]);

  const handleSetFormaRateioValorFrete = useCallback(() => {
    setFormaRateioValorFrete(true);
  }, [setFormaRateioValorFrete]);

  const handleSetFormaRateioValorSeguro = useCallback(() => {
    setFormaRateioValorSeguro(true);
  }, [setFormaRateioValorSeguro]);

  const handleSetFormaRateioValorOutrasDespesas = useCallback(() => {
    setFormaRateioValorOutrasDespesas(true);
  }, [setFormaRateioValorOutrasDespesas]);

  const handleSetFormaRateioValorDesconto = useCallback(() => {
    setFormaRateioValorDesconto(true);
  }, [setFormaRateioValorDesconto]);

  const handleCalcularValorDesconto = useCallback(() => {
    const percentualDesconto =
      formRef.current?.getFieldValue('percentualDesconto');

    const valorSubTotal = formRef.current?.getFieldValue('valorTotalProdutos');

    const valorDesconto =
      Number(valorSubTotal) * (Number(percentualDesconto) / 100);

    if (valorDesconto > 0) {
      handleSetFormaRateioValorDesconto();
      formRef.current?.setFieldValue('valorDesconto', valorDesconto);
      handleAtualizarRateiosListaItem();
    }
  }, [
    formRef,
    handleAtualizarRateiosListaItem,
    handleSetFormaRateioValorDesconto,
  ]);

  const handleCalcularValorOutrasDespesas = useCallback(() => {
    const percentualOutrasDespesas = formRef.current?.getFieldValue(
      'percentualOutrasDespesas'
    );

    const valorSubTotal = formRef.current?.getFieldValue('valorTotalProdutos');

    const valorOutrasDespesas =
      Number(valorSubTotal) * (Number(percentualOutrasDespesas) / 100);

    if (valorOutrasDespesas > 0) {
      handleSetFormaRateioValorOutrasDespesas();
      formRef.current?.setFieldValue(
        'valorOutrasDespesas',
        valorOutrasDespesas
      );
      handleAtualizarRateiosListaItem();
    }
  }, [
    formRef,
    handleAtualizarRateiosListaItem,
    handleSetFormaRateioValorOutrasDespesas,
  ]);

  const handleCalcularPercentualDesconto = useCallback(() => {
    const valorDesconto = formRef.current?.getFieldValue('valorDesconto');
    const valorSubTotal = formRef.current?.getFieldValue('valorTotalProdutos');

    const percentualCalculado = valorSubTotal
      ? (Number(valorDesconto) / Number(valorSubTotal)) * 100
      : 0;

    formRef.current?.setFieldValue('percentualDesconto', percentualCalculado);
  }, [formRef]);

  const handleCalcularPercentualOutrasDespesas = useCallback(() => {
    const valorOutrasDespesas = formRef.current?.getFieldValue(
      'valorOutrasDespesas'
    );
    const valorSubTotal = formRef.current?.getFieldValue('valorTotalProdutos');

    const percentualCalculado = valorSubTotal
      ? (Number(valorOutrasDespesas) / Number(valorSubTotal)) * 100
      : 0;

    formRef.current?.setFieldValue(
      'percentualOutrasDespesas',
      percentualCalculado
    );
  }, [formRef]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="Valor Frete"
              name="valorFrete"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleSetFormaRateioValorFrete}
              onBlur={handleAtualizarRateiosListaItem}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="Valor Seguro"
              name="valorSeguro"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleSetFormaRateioValorSeguro}
              onBlur={handleAtualizarRateiosListaItem}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="Valor Outras Despesas"
              name="valorOutrasDespesas"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleSetFormaRateioValorOutrasDespesas}
              onBlur={() => {
                handleAtualizarRateiosListaItem();
                handleCalcularPercentualOutrasDespesas();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="% Outras Despesas"
              name="percentualOutrasDespesas"
              casasInteiras={11}
              casasDecimais={6}
              onBlur={handleCalcularValorOutrasDespesas}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="Valor Desconto"
              name="valorDesconto"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleSetFormaRateioValorDesconto}
              onBlur={() => {
                handleAtualizarRateiosListaItem();
                handleCalcularPercentualDesconto();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimal
              label="% Desconto"
              name="percentualDesconto"
              casasInteiras={11}
              casasDecimais={6}
              onBlur={handleCalcularValorDesconto}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>ICMS / ICMS ST.</h5>

        <Row>
          <Col lg={2} md={4} sm={12}>
            <InputDecimal
              label="Base Calculo ICMS"
              name="valorTotalBaseCalculoIcms"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputDecimal
              label="Total ICMS"
              name="valorTotalIcms"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputDecimalNulavel
              label="Total ICMS FCP"
              name="valorTotalFcpIcms"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputDecimal
              label="Base Calculo ICMS ST."
              name="valorTotalBaseCalculoIcmsSt"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputDecimal
              label="Total ICMS ST."
              name="valorTotalIcmsSt"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputDecimalNulavel
              label="Total ICMS ST. FCP"
              name="valorTotalFcpIcmsSt"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <div>
          <h5>IPI / PIS / COFINS</h5>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Base Calculo IPI"
                name="valorTotalBaseCalculoIpi"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Total IPI"
                name="valorTotalIpi"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Base Calculo PIS"
                name="valorTotalBaseCalculoPis"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Total PIS"
                name="valorTotalPis"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Base Calculo COFINS"
                name="valorTotalBaseCalculoCofins"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Total COFINS"
                name="valorTotalCofins"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 25 }}>
          <h5>PIS / COFINS Retido</h5>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Base Calculo PIS Retido"
                name="valorTotalBaseCalculoPisRetido"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Total PIS Retido"
                name="valorTotalPisRetido"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Base Calculo COFINS Retido"
                name="valorTotalBaseCalculoCofinsRetido"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimal
                label="Total COFINS Retido"
                name="valorTotalCofinsRetido"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Divisor>

      <Divisor>
        <div style={{ marginTop: 25 }}>
          <h5>Funrural</h5>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Valor Funrural"
                name="valorFunrural"
                casasInteiras={13}
                casasDecimais={2}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Divisor>

      <Divisor>
        <div>
          <h5>Retenções</h5>
          <Row>
            <Col lg={2} md={4} sm={12}>
              <InputDecimal
                label="Base de Cálculo IR Retido"
                name="valorTotalBaseCalculoIRRetido"
                casasInteiras={15}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={4} sm={12}>
              <InputDecimal
                label="Valor IR Retido"
                name="valorTotalIRRetido"
                casasInteiras={15}
                casasDecimais={2}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Divisor>

      <Divisor>
        <div>
          <Row>
            <Col lg={2} md={4} sm={12}>
              <InputDecimal
                label="Valor Sub. Total"
                name="valorTotalProdutos"
                casasInteiras={15}
                casasDecimais={2}
                disabled
              />
            </Col>
            <Col lg={2} md={4} sm={12}>
              <InputDecimal
                label="Valor Total"
                name="valorTotalBruto"
                casasInteiras={15}
                casasDecimais={2}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Divisor>
    </div>
  );
};

export default TotaisTab;
