/* eslint-disable no-restricted-syntax */
import {
  FormatarDataHoraParaPtBr,
  IEstoqueFiltroRelatorioBackend,
  IEstoqueFiltroRelatorioFrontend,
  IExtratoPorProdutoLoteCabecalhoRelatorio,
  IExtratoPorProdutoLoteRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoOrdenacaoEnum,
} from '@elogestor/util';
import React from 'react';
import ExtratoPorProdutoLoteRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/Estoques/ExtratoPorProdutoLoteRelatorio/ExtratoPorProdutoLoteRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ExtratoPorProdutoLoteRelatorioPDF from './ExtratoPorProdutoLoteRelatorioPDF';
import ExtratoPorProdutoLoteRelatorioPlanilha from './ExtratoPorProdutoLoteRelatorioPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ExtratoPorProdutoLoteRelatorio = (): IRelatorios => {
  function ValidarFiltrosObrigatorios(filtroBack: any): boolean {
    // Criado essa função nos relatórios de estoque que sejam de extrato, para diminuir a quantidade de dados (Atividade - 2466 )
    let possuiFiltrosObrigatorios = false;
    for (const chave in filtroBack) {
      if (
        ![
          'data',
          'somenteProdutosEstoqueMinimo',
          'somenteProdutosEstoqueMaximo',
          'tipoBuscaCodigoDescricao',
        ].includes(chave) &&
        filtroBack[chave] &&
        filtroBack[chave].length > 0
      ) {
        possuiFiltrosObrigatorios = true;
      }
    }
    return possuiFiltrosObrigatorios;
  }

  const ObterRelatorio = async (
    filtros: IEstoqueFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IExtratoPorProdutoLoteRelatorio | null> => {
    const filtroBack: IEstoqueFiltroRelatorioBackend = {
      data: filtros.data,

      descricao: filtros.descricao,
      codigo: filtros.codigo,

      somenteProdutosComSaldo: filtros?.somenteProdutosComSaldo,

      produtoAtivo: filtros?.produtoAtivo,

      reservaComprometimento: filtros?.reservaComprometimento,
      somenteProdutosEstoqueMinimo: filtros?.somenteProdutosEstoqueMinimo,
      somenteProdutosEstoqueMaximo: filtros?.somenteProdutosEstoqueMaximo,
    };
    if (filtros?.listaMarcaProduto) {
      filtroBack.listaIdMarcaProduto =
        filtros.listaMarcaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.reservaComprometimento) {
      filtroBack.reservaComprometimento = filtros.reservaComprometimento;
    }

    if (filtros?.listaPessoa) {
      filtroBack.listaIdPessoa =
        filtros.listaPessoa.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaLote) {
      filtroBack.listaIdLote =
        filtros.listaLote.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaGrupoProdutoServico) {
      filtroBack.listaIdGrupoProdutoServico =
        filtros.listaGrupoProdutoServico.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaLocalEstoque) {
      filtroBack.listaIdLocalEstoque =
        filtros.listaLocalEstoque.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoOrdenacao) {
      filtroBack.tipoOrdenacao = StringConverterParaEnum<
        typeof TipoOrdenacaoEnum,
        TipoOrdenacaoEnum
      >(TipoOrdenacaoEnum, String(filtros.tipoOrdenacao));
    }

    if (!ValidarFiltrosObrigatorios(filtroBack)) {
      ToastInfo(
        'Informe pelo menos um filtro a mais dos Selecionados para Permitir gerar o relatório!'
      );
      return null;
    }

    const response = await ExtratoPorProdutoLoteRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    return response as IExtratoPorProdutoLoteRelatorio;
  };

  function FormatarCabecalho(
    filtros: IEstoqueFiltroRelatorioFrontend,
    cabecalho: IExtratoPorProdutoLoteCabecalhoRelatorio
  ): IExtratoPorProdutoLoteCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.data) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroData = FormatarDataHoraParaPtBr(filtros.data);
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }

      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (filtros.listaPessoa && filtros.listaPessoa.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPessoa = filtros.listaPessoa.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (
      filtros.listaGrupoProdutoServico &&
      filtros.listaGrupoProdutoServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGrupoProdutoServico.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaLocalEstoque &&
      filtros.listaLocalEstoque.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroLocalEstoque = filtros.listaLocalEstoque.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaLote && filtros.listaLote.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroLote = filtros.listaLote.listaValor
        .map((valor: any) => {
          return valor.codigo;
        })
        .join(', ');
    }

    if (
      filtros.somenteProdutosComSaldo !== undefined &&
      filtros.somenteProdutosComSaldo !== null
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteProdutoComSaldo = Boolean(
        filtros.somenteProdutosComSaldo
      );
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: ExtratoPorProdutoLoteRelatorioPlanilha,
      nomeArquivo: 'ExtratoPorProdutoLote',
      valores: dados.listaDados,
      unwind: ['listaMovimentos'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: ExtratoPorProdutoLoteRelatorioPlanilha,
      nomeArquivo: 'ExtratoPorProdutoLote',
      valores: dados.listaDados as any,
      unwind: ['listaMovimentos'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ExtratoPorProdutoLoteRelatorioPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ExtratoPorProdutoLoteRelatorioPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ExtratoPorProdutoLoteRelatorio();
