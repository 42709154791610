import { FinalidadeEmissaoEnum, TipoMercadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/obter-nota-fiscal-entrada-item-operacao-fiscal';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicadorIndex {
  idPessoa: string;
  idNotaFiscalSaida?: string;
  idNotaFiscalEntrada?: string;
  finalidadeEmissao?: FinalidadeEmissaoEnum;
  tipoMercado?: TipoMercadoEnum;
  listaItemSelecionado: any[];
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador();
