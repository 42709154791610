"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CorCultivarSisdevinEnum;
(function (CorCultivarSisdevinEnum) {
    CorCultivarSisdevinEnum["tinto"] = "Tinto";
    CorCultivarSisdevinEnum["branco"] = "Branco";
    CorCultivarSisdevinEnum["rosado"] = "Rosado";
})(CorCultivarSisdevinEnum || (CorCultivarSisdevinEnum = {}));
exports.default = CorCultivarSisdevinEnum;
