import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-item-operacao-fiscal';

interface IImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicadorShow {
  params: {
    codigoSpedTipoProduto: string;
    cfop: string;
  };
}

class ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador {
  public async show(
    params: IImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador();
