import { InputGroup } from 'react-bootstrap';
import styled, { css } from 'styled-components';

interface IDefaultInput {
  $isFocused: boolean;
  $isFilled: boolean;
  $isErrored: boolean;
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */

  border-radius: 10px;

  width: 100%;
  height: 100%;

  color: #000;

  & + div {
    margin-top: 15px;
  }

  label {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const DefaultInput = styled.input<IDefaultInput>`
  background: #fff;
  height: 35px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder {
    color: #666360;
  }

  ${(props) =>
    props.$isFilled &&
    css`
      border-color: rgb(0, 123, 255);
    `}
  ${(props) =>
    props.$isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.$isFocused &&
    css`
      border-color: rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    `}

  ${(props) =>
    props.$isFocused &&
    props.$isErrored &&
    css`
      border-color: #c53030;
      box-shadow: 0 0 0 0.2rem #c5303020;
    `}

  &:disabled {
    opacity: 0.7;
    border-color: #d3d3d3;
    box-shadow: none;
  }
`;

export const CustomDefaultInput = styled.input<IDefaultInput>`
  background: #fff;
  height: 35px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder {
    color: #666360;
  }

  ${(props) =>
    props.$isFilled &&
    css`
      border-color: rgb(0, 123, 255);
    `}
  ${(props) =>
    props.$isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.$isFocused &&
    css`
      border-color: rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      clip-path: inset(-0.2rem 0px -0.2rem -0.2rem);
      border-right: none;
    `}

  ${(props) =>
    props.$isFocused &&
    props.$isErrored &&
    css`
      border-color: #c53030;
      box-shadow: 0 0 0 0.2rem #c5303020;
      clip-path: inset(-0.2rem 0px -0.2rem -0.2rem);
    `}

  &:disabled {
    opacity: 0.7;
    border-color: #d3d3d3;
    box-shadow: none;
  }
`;

export const CustomInputGroupText = styled(InputGroup.Text)`
  background: #fff;
  height: 35px;
  width: auto;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  & .fw-bold {
    color: #666360;
  }

  ${(props) =>
    props.$isFilled &&
    css`
      border-color: rgb(0, 123, 255);
    `}
  ${(props) =>
    props.$isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.$isFocused &&
    css`
      border-color: rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      clip-path: inset(-0.2rem -0.2rem -0.2rem 0px);
    `}

  ${(props) =>
    props.$isFocused &&
    props.$isErrored &&
    css`
      border-color: #c53030;
      box-shadow: 0 0 0 0.2rem #c5303020;
      clip-path: inset(-0.2rem -0.2rem -0.2rem 0px);
    `}

  &:disabled {
    opacity: 0.7;
    border-color: #d3d3d3;
    box-shadow: none;
  }
`;

export const DefaultTextArea = styled.textarea<IDefaultInput>`
  color: #000;
  background: #fff;
  height: 35px;
  width: 100%;

  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder {
    color: #666360;
  }

  &:disabled {
    opacity: 0.7;
  }

  ${(props) =>
    props.$isFilled &&
    css`
      border-color: rgb(0, 123, 255);
    `}
  ${(props) =>
    props.$isErrored &&
    css`
      border-color: #c53030;
    `}

${(props) =>
    props.$isFocused &&
    css`
      border-color: rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    `}

${(props) =>
    props.$isFocused &&
    props.$isErrored &&
    css`
      border-color: #c53030;
      box-shadow: 0 0 0 0.2rem #c5303020;
    `}
`;

export const DefaultSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;

  &:disabled {
    opacity: 0.7;
    border-color: #d3d3d3;
    box-shadow: none;
  }
`;

interface IDefaultInputDiv extends IDefaultInput {
  $isDisabled?: boolean;
}

export const DefaultInputDiv = styled.div<IDefaultInputDiv>`
  color: #000;
  background: #fff;
  height: 35px;
  width: 100%;

  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  input {
    height: 33px; // Não é 35px, pois como removemos as bordas em cima e embaixo precisamos remover 2px
    border: 0;
    margin: 0;
    padding: 0;
    color: #000;
  }

  &::placeholder {
    color: #666360;
  }

  &:disabled {
    opacity: 0.7;
  }

  ${(props) =>
    props.$isFilled &&
    css`
      border-color: rgb(0, 123, 255);
    `}
  ${(props) =>
    props.$isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.$isFocused &&
    css`
      border-color: rgb(0, 123, 255);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    `}

  ${(props) =>
    props.$isFocused &&
    props.$isErrored &&
    css`
      border-color: #c53030;
      box-shadow: 0 0 0 0.2rem #c5303020;
    `}

    ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.7;
      border-color: #d3d3d3;
      box-shadow: none;
    `}
`;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
  label {
    margin: 0 0 0 5px;
  }

  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
`;
