import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IpiObterVisibilidadeBloqueioCamposPorCst } from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const camposEscondidosIpiPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
  ipiCodigoEnquadramentoLegal: true,
  classeEnquadramento: true,
  cnpjProdutor: true,
  codigoSelo: true,
  quantidadeSelo: true,
};

const camposBloqueadosIpiPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
  ipiCodigoEnquadramentoLegal: true,
  classeEnquadramento: true,
  cnpjProdutor: true,
  codigoSelo: true,
  quantidadeSelo: true,
};

type ICamposEscondidosIpi = typeof camposEscondidosIpiPadrao;
type ICamposBloqueadosIpi = typeof camposBloqueadosIpiPadrao;

interface IItemIpiTabContext {
  validador: boolean;
  camposEscondidosIpi: ICamposEscondidosIpi;
  camposBloqueadosIpi: ICamposBloqueadosIpi;
  obterVisibilidadeBloqueioCamposPorIpiCst(): void;
}

const ItemIpiTabContext = createContext<IItemIpiTabContext>(
  {} as IItemIpiTabContext
);

const ItemIpiTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe } = UseListaDetalheForm();

  const [camposEscondidosIpi, setCamposEscondidosIpi] = useState(
    camposEscondidosIpiPadrao
  );

  const [camposBloqueadosIpi, setCamposBloqueadosIpi] = useState(
    camposBloqueadosIpiPadrao
  );

  const calcular = formRefDetalhe.current?.getFieldValue(
    'ordemServicoItemImpostoIpi.calcular'
  );

  const tipoCalculo = formRefDetalhe.current?.getFieldValue(
    'ordemServicoItemImpostoIpi.tipoCalculo'
  );

  const cst =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'ordemServicoItemImpostoIpi.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorIpiCst = useCallback(() => {
    const calcularIpi = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIpi.calcular'
    );

    const tipoCalculoIpi = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIpi.tipoCalculo'
    );

    const cstIpi =
      formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemImpostoIpi.situacaoTributaria'
      )?.codigo || '';

    const retorno = IpiObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularIpi,
      tipoCalculo: tipoCalculoIpi,
      cst: cstIpi,
    });

    setCamposEscondidosIpi(retorno.escondido);
    setCamposBloqueadosIpi(retorno.bloqueado);
  }, [formRefDetalhe]);

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorIpiCst();
  }, [
    calcular,
    tipoCalculo,
    cst,
    handleObterVisibilidadeBloqueioCamposPorIpiCst,
  ]);

  return (
    <ItemIpiTabContext.Provider
      value={{
        camposEscondidosIpi,
        camposBloqueadosIpi,
        obterVisibilidadeBloqueioCamposPorIpiCst:
          handleObterVisibilidadeBloqueioCamposPorIpiCst,
        validador: true,
      }}
    >
      {children}
    </ItemIpiTabContext.Provider>
  );
};

function UseItemIpiTab(): Omit<IItemIpiTabContext, 'validador'> {
  const context = useContext(ItemIpiTabContext);

  if (!context.validador) {
    throw new Error('UseItemIpiTab deve ser usado com um ItemIpiTabHook');
  }

  return context;
}

export { ItemIpiTabHook, UseItemIpiTab };
