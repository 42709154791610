import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/configurador/conjunto-auto-complete';

interface IConjuntoAutoCompleteComunicadorIndex {
  params: any;
}

class ConjuntoAutoCompleteComunicador {
  public async index(
    params: IConjuntoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ConjuntoAutoCompleteComunicador();
