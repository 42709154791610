import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/transmitir';

interface IManifestoTransporteTransmitirComunicadorUpdate {
  id: string;
}

class ManifestoTransporteTransmitirComunicador {
  public async update(
    params: IManifestoTransporteTransmitirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteTransmitirComunicador();
