import { IProdutoPadronizacaoDescricaoAtributoLista } from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Button, Container, Col, Row, Table } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline, IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import InputAutoCompletePadronizacaoDescricao from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompletePadronizacaoDescricao';
import Input from '../../../../../../../Componentes/Inputs/Input';
import PadronizacaoDescricaoComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/PadronizacaoDescricao/Comunicador/PadronizacaoDescricaoComunicador';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import InputTabela from '../../../../../../../Componentes/Inputs/InputTabela/InputTabela';
import InputHiddenHtml from '../../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UsePadronizacaoDescricaoLista } from '../Hooks/PadronizacaoDescricaoListaHook';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IPadronizacaoDescricaoAtributoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const PadronizacaoDescricaoAtributoDetalhe: React.FC<
  IPadronizacaoDescricaoAtributoModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { permissoes } = UsePermissoes();
  const formPrincipal = UseForm();
  const { SuprimentosProdutosProduto: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { listaConteudoRef } = UsePadronizacaoDescricaoLista();

  const idDetalheRegistro = getIdDetalheRegistro();

  const [listaDados, setListaDados] = useState<
    IProdutoPadronizacaoDescricaoAtributoLista[]
  >([]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataRecuperarFormulario();
    if (
      data.listaPadronizacaoDescricaoAtributo &&
      data.listaPadronizacaoDescricaoAtributo.length > 0
    ) {
      data.listaPadronizacaoDescricaoAtributo.forEach(
        (item: any, index: number) => {
          item.conteudo =
            listaConteudoRef.current && listaConteudoRef.current[index]
              ? listaConteudoRef.current[index].value
              : null;
        }
      );
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, listaConteudoRef, onSalvarFormModal]);

  const handleCarregarAtributosPadronizacaoDescricao = useCallback(
    async (itemAtual: any): Promise<void> => {
      const listaValores: any[] = [];

      const response = await PadronizacaoDescricaoComunicador.show({
        id: itemAtual.id,
      });
      const listaPadronizacaoDescricaoAtributo =
        response?.listaPadronizacaoDescricaoAtributo;

      if (listaPadronizacaoDescricaoAtributo.length) {
        for (let i = 0; i < listaPadronizacaoDescricaoAtributo.length; i++) {
          const item = listaPadronizacaoDescricaoAtributo[i];

          if (!listaValores[i]) {
            listaValores.push({
              idPadronizacaoDescricaoAtributo: item.id,
              idPadronizacaoDescricao: itemAtual.id,
              descricao: item.descricao,
              tamanho: item.tamanho,
              obrigatorio: item.obrigatorio,
              espacoAntesTextoAnterior: item.espacoAntesTextoAnterior,
              textoAnterior: item.textoAnterior,
              espacoDepoisTextoAnterior: item.espacoDepoisTextoAnterior,
              conteudo:
                listaDados[i] &&
                listaConteudoRef.current &&
                listaConteudoRef.current[i]?.id === itemAtual.id
                  ? listaConteudoRef.current[i].value
                  : '',
              espacoAntesTextoPosterior: item.espacoAntesTextoPosterior,
              textoPosterior: item.textoPosterior,
              espacoDepoisTextoPosterior: item.espacoDepoisTextoPosterior,
            });
          }

          item.idPadronizacaoDescricaoAtributo = item.id;
          item.padronizacaoDescricao = itemAtual;
          delete item.id;
          delete item.createdAt;
          delete item.updatedAt;
        }
      }

      if (listaValores) {
        setListaDados(listaValores);
      }

      formPrincipal.formRef.current?.setFieldValorInicialSemExecutarEvento(
        'listaPadronizacaoDescricaoAtributo',
        response?.listaPadronizacaoDescricaoAtributo
      );

      formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
        'listaPadronizacaoDescricaoAtributo',
        response?.listaPadronizacaoDescricaoAtributo
      );
      if (
        listaConteudoRef.current &&
        listaConteudoRef.current[0]?.id !== itemAtual.id
      ) {
        formRefDetalhe.current?.setFieldValue('descricao', null);
      }

      formRefDetalhe.current?.setFieldDisabled('descricao', true);
    },
    [formPrincipal.formRef, formRefDetalhe, listaConteudoRef, listaDados]
  );

  const handleLimparAtributosPadronizacaoDescricao =
    useCallback(async (): Promise<void> => {
      setListaDados([]);

      formPrincipal.formRef.current?.setFieldValorInicialSemExecutarEvento(
        'listaPadronizacaoDescricaoAtributo',
        []
      );
      formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
        'listaPadronizacaoDescricaoAtributo',
        []
      );

      formRefDetalhe.current?.setFieldValue('descricao', null);
      formRefDetalhe.current?.setFieldDisabled('descricao', false);
    }, [formPrincipal.formRef, formRefDetalhe]);

  const handleOnChangeFormatarDescricaoProduto = useCallback(() => {
    let descricaoProduto = '';
    for (let i = 0; i < listaDados.length; i++) {
      const valor = listaDados[i];
      if (
        listaConteudoRef &&
        listaConteudoRef.current &&
        listaConteudoRef.current[i].value
      ) {
        if (valor.textoAnterior === undefined || valor.textoAnterior === null)
          valor.textoAnterior = '';

        if (valor.textoPosterior === undefined || valor.textoPosterior === null)
          valor.textoPosterior = '';

        descricaoProduto += `${valor.espacoAntesTextoAnterior ? ' ' : ''}${
          valor.textoAnterior
        }${valor.espacoDepoisTextoAnterior ? ' ' : ''}${
          listaConteudoRef.current[i].value
        }${valor.espacoAntesTextoPosterior ? ' ' : ''}${valor.textoPosterior}${
          valor.espacoDepoisTextoPosterior ? ' ' : ''
        }`;
      }
    }
    formRefDetalhe.current?.setFieldValue(
      'descricao',
      descricaoProduto.RemoverExcessoEspacos()
    );
  }, [formRefDetalhe, listaConteudoRef, listaDados]);

  const handleCarregarDados = useCallback(() => {
    const listaProdutoPadronizacaoDescricaoAtributo =
      formPrincipal.formRef.current?.getFieldValue(
        'listaProdutoPadronizacaoDescricaoAtributo'
      );
    const descricaoProduto =
      formPrincipal.formRef.current?.getFieldValue('descricao');

    if (
      listaProdutoPadronizacaoDescricaoAtributo &&
      listaProdutoPadronizacaoDescricaoAtributo.length > 0
    ) {
      if (
        listaProdutoPadronizacaoDescricaoAtributo[0]
          .padronizacaoDescricaoAtributo
      ) {
        listaProdutoPadronizacaoDescricaoAtributo.forEach((it: any) => {
          setListaDados((prevState) => {
            return [
              ...prevState,
              {
                idPadronizacaoDescricaoAtributo:
                  it.idPadronizacaoDescricaoAtributo,
                idPadronizacaoDescricao:
                  it.padronizacaoDescricaoAtributo.padronizacaoDescricao.id,
                descricao: it.padronizacaoDescricaoAtributo.descricao,
                tamanho: it.padronizacaoDescricaoAtributo.tamanho,
                obrigatorio: it.padronizacaoDescricaoAtributo.obrigatorio,
                espacoAntesTextoAnterior:
                  it.padronizacaoDescricaoAtributo.espacoAntesTextoAnterior,
                textoAnterior: it.padronizacaoDescricaoAtributo.textoAnterior,
                espacoDepoisTextoAnterior:
                  it.padronizacaoDescricaoAtributo.espacoDepoisTextoAnterior,
                conteudo: it.conteudo ? it.conteudo : '',
                espacoAntesTextoPosterior:
                  it.padronizacaoDescricaoAtributo.espacoAntesTextoPosterior,
                textoPosterior: it.padronizacaoDescricaoAtributo.textoPosterior,
                espacoDepoisTextoPosterior:
                  it.padronizacaoDescricaoAtributo.espacoDepoisTextoPosterior,
              },
            ];
          });
        });
        formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
          'padronizacaoDescricao',
          listaProdutoPadronizacaoDescricaoAtributo[0]
            .padronizacaoDescricaoAtributo.padronizacaoDescricao
        );
      } else {
        listaProdutoPadronizacaoDescricaoAtributo.forEach((it: any) => {
          setListaDados((prevState) => {
            return [
              ...prevState,
              {
                idPadronizacaoDescricaoAtributo:
                  it.idPadronizacaoDescricaoAtributo,
                idPadronizacaoDescricao: it.idPadronizacaoDescricao,
                descricao: it.descricao,
                tamanho: it.tamanho,
                obrigatorio: it.obrigatorio,
                espacoAntesTextoAnterior: it.espacoAntesTextoAnterior,
                textoAnterior: it.textoAnterior,
                espacoDepoisTextoAnterior: it.espacoDepoisTextoAnterior,
                conteudo: it.conteudo,
                espacoAntesTextoPosterior: it.espacoAntesTextoPosterior,
                textoPosterior: it.textoPosterior,
                espacoDepoisTextoPosterior: it.espacoDepoisTextoPosterior,
              },
            ];
          });
        });

        formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
          'padronizacaoDescricao',
          listaProdutoPadronizacaoDescricaoAtributo[0].padronizacaoDescricao
        );
      }

      formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
        'descricao',
        descricaoProduto
      );
      formRefDetalhe.current?.setFieldDisabled('descricao', true);

      formRefDetalhe.current?.setFieldValorInicialSemExecutarEvento(
        'listaPadronizacaoDescricaoAtributo',
        listaProdutoPadronizacaoDescricaoAtributo
      );
    }
  }, [formPrincipal.formRef, formRefDetalhe]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Padronização de Descrição"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <InputHiddenHtml name="listaPadronizacaoDescricaoAtributo" />
                <Col lg={12} md={12}>
                  <InputAutoCompletePadronizacaoDescricao
                    ref={inputRefFocus}
                    label="Padronização da Descrição"
                    name="idPadronizacaoDescricao"
                    nomeObjeto="padronizacaoDescricao"
                    placeholder="Padronização da Descrição"
                    onChangeItemAtual={async (event) => {
                      if (event.itemAtual) {
                        await handleCarregarAtributosPadronizacaoDescricao(
                          event.itemAtual
                        );
                      } else {
                        await handleLimparAtributosPadronizacaoDescricao();
                      }
                    }}
                  />
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Tabela style={{ overflow: 'auto' }}>
                    <Table striped hover bordered variant="light">
                      <thead>
                        <tr>
                          <th className="lista-texto" style={{ width: '25%' }}>
                            <span>Atributo</span>
                          </th>
                          <th className="lista-texto" style={{ width: '10%' }}>
                            <span>Tam</span>
                          </th>
                          <th className="lista-texto" style={{ width: '10%' }}>
                            <span>Obrig</span>
                          </th>
                          <th className="lista-texto" style={{ width: '15%' }}>
                            <span>Text. Ant.</span>
                          </th>
                          <th className="lista-texto" style={{ width: '25%' }}>
                            <span>Conteúdo</span>
                          </th>
                          <th className="lista-texto" style={{ width: '15%' }}>
                            <span>Text. Post.</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {listaDados.map((item, index) => {
                          return (
                            <tr key={v4()}>
                              <td className="lista-texto">{item.descricao}</td>
                              <td className="lista-texto">{item.tamanho}</td>
                              <td
                                className="lista-texto"
                                style={{
                                  color: item.obrigatorio ? 'green' : 'red',
                                }}
                              >
                                {item.obrigatorio ? 'Sim' : 'Não'}
                              </td>
                              <td className="lista-texto">
                                {item.textoAnterior}
                              </td>
                              <td className="lista-texto">
                                <InputTabela
                                  valorPadrao={item.conteudo.RemoverExcessoEspacos()}
                                  maxLength={
                                    item.tamanho > 0 ? item.tamanho : 60
                                  }
                                  onChange={
                                    handleOnChangeFormatarDescricaoProduto
                                  }
                                  obterRef={(instance) => {
                                    if (
                                      instance.current &&
                                      listaConteudoRef.current
                                    ) {
                                      instance.current.id =
                                        item.idPadronizacaoDescricao ?? '';
                                      listaConteudoRef.current[index] =
                                        instance.current;
                                    }
                                  }}
                                />
                              </td>
                              <td className="lista-texto">
                                {item.textoPosterior}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tabela>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Input
                    label="Descrição do Produto"
                    name="descricao"
                    placeholder="Descrição do Produto"
                    maxLength={60}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PadronizacaoDescricaoAtributoDetalhe;
