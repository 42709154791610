import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { BuscaProdutosCadastradosEnum, FormatarEnum } from '@elogestor/util';
import { v4 } from 'uuid';
import { CgImport, CgExport, CgMoreO } from 'react-icons/cg/index.mjs';
import { BiErrorCircle } from 'react-icons/bi/index.mjs';
import { BsArrowRight } from 'react-icons/bs/index.mjs';
import { Container, ContainerDados } from './styles';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import SubHeaderGeral from '../../../../../../../Componentes/SubHeaderGeral';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../../Componentes/Select';
import ContainerButtons from '../../../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import SplitAcoes from '../../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import { UseReactSizeMeBodyHook } from '../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import EstoqueExportacaoPlanilha from './Template/EstoqueExportacao/EstoqueExportacaoPlanilha';
import SelecionarEstoquesCSVImportacao from './SelecionarEstoquesCSVImportacao';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import Divisor from '../../../../../../../Componentes/Divisor';
import ImportacaoExportacaoCSVEstoquesComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/ImportacaoExportacaoCSVEstoques/Comunicador/ImportacaoExportacaoCSVEstoquesComunicador';

const ImportacaoExportacaoCSVEstoquesMain: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosProdutosEstoqueImportacaoExportacaoCsvEstoques: permissao,
  } = permissoes;

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const buscaProdutosCadastrados = formRef.current?.getFieldValue(
    'buscaProdutosCadastrados'
  );

  const [listaErros, setListaErros] = useState<any[]>([]);

  // #region Exportação CSV

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const buscarProdutosAtivos = formRef.current?.getFieldValue(
        'buscarProdutosAtivos'
      );
      const buscarProdutosComSaldo = formRef.current?.getFieldValue(
        'buscarProdutosComSaldo'
      );

      const response = await ImportacaoExportacaoCSVEstoquesComunicador.index({
        params: { buscarProdutosAtivos, buscarProdutosComSaldo },
      });

      if (response) {
        const exportacao = await CSV({
          campos: EstoqueExportacaoPlanilha,
          nomeArquivo: 'EstoqueExportacaoPlanilha',
          valores: response.listaDados,
        });

        exportacao.AbrirArquivo();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, []);

  // #endregion Exportação CSV

  // #region Importação CSV

  const [showModalImportacaoCSV, setShowModalImportacaoCSV] = useState(false);

  const handleFecharImportacaoCSV = useCallback(() => {
    setShowModalImportacaoCSV(false);
  }, []);

  const handleAbrirImportacaoCSV = useCallback(() => {
    setShowModalImportacaoCSV(true);
  }, []);

  // #endregion Importação CSV

  useEffect(() => {
    formRef.current?.setDataInicial({
      buscarProdutosAtivos: true,
      buscarProdutosComSaldo: false,
      buscaProdutosCadastrados: BuscaProdutosCadastradosEnum.codigoDescricao,
      atualizarProdutosExistentes: false,
    });
  }, []);

  return (
    <>
      <Container>
        <LoadingDiv isLoading={loading} />

        <SubHeaderGeral>
          <h1>Importação e exportação de CSV de Estoques</h1>

          <ContainerButtons>
            <SplitAcoes
              id={v4()}
              className="btn-group"
              title={
                <div>
                  <TextoLoading loading={loading}>
                    <CgMoreO />
                    {telaGrande && (
                      <span style={{ marginLeft: 10, marginRight: 10 }}>
                        Ações
                      </span>
                    )}
                  </TextoLoading>
                </div>
              }
            >
              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleExportCSV}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgExport style={{ marginRight: 5 }} />
                  Exportar CSV de Estoques
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleAbrirImportacaoCSV}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgImport style={{ marginRight: 5 }} />
                  Importar CSV de Estoques
                </span>
              </Dropdown.Item>
            </SplitAcoes>
          </ContainerButtons>
        </SubHeaderGeral>

        <FormCia ref={formRef}>
          <ContainerDados>
            <Divisor>
              <h6 style={{ fontWeight: 'bold', paddingTop: 10 }}>
                Filtros para Exportação de Estoques
              </h6>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Exportar Somente Produtos Ativos"
                    name="buscarProdutosAtivos"
                    onChange={() => {
                      refresh();
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Exportar Somente Produtos com Saldo"
                    name="buscarProdutosComSaldo"
                    onChange={() => {
                      refresh();
                    }}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <h6 style={{ fontWeight: 'bold', paddingTop: 10 }}>
                Filtros para Importação de Estoques
              </h6>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Select
                    label="Busca de Produtos Cadastrados"
                    name="buscaProdutosCadastrados"
                    options={FormatarEnum({
                      enumObj: BuscaProdutosCadastradosEnum,
                    })}
                    onChange={() => {
                      refresh();
                    }}
                  />
                </Col>
              </Row>
            </Divisor>

            {showModalImportacaoCSV && (
              <SelecionarEstoquesCSVImportacao
                onFecharFormModalLista={handleFecharImportacaoCSV}
                filtro={{
                  buscaProdutosCadastrados,
                }}
                setListaErros={setListaErros}
              />
            )}

            {listaErros.length > 0 && (
              <Modal
                show
                size="lg"
                onHide={() => setListaErros([])}
                scrollable
                centered
              >
                <Modal.Header>
                  <Modal.Title>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ marginRight: 10 }}>
                        <BiErrorCircle />
                      </span>
                      <span>Linhas com erro</span>
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {listaErros.map((item, index) => (
                    <div key={index}>
                      <p>
                        {item.identificador}
                        <BsArrowRight
                          className="mx-2"
                          style={{ color: 'red' }}
                        />
                        {item.erro}
                      </p>
                    </div>
                  ))}
                </Modal.Body>

                <Modal.Footer>
                  <button
                    type="button"
                    className="btn-padrao btn-cinza-claro"
                    onClick={() => {
                      setListaErros([]);
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 16,
                      }}
                    >
                      OK
                    </span>
                  </button>
                </Modal.Footer>
              </Modal>
            )}
          </ContainerDados>
        </FormCia>
      </Container>
    </>
  );
};

export default ImportacaoExportacaoCSVEstoquesMain;
