import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/serie/ordem';

interface ISerieOrdemComunicadorUpdate {
  params: any;
}

class SerieOrdemComunicador {
  public async update(params: ISerieOrdemComunicadorUpdate): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new SerieOrdemComunicador();
