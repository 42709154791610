import api from '../../../../../../Comum/Services/Api';

const rota = '/suprimentos/pesagens/pesagem/avaliacao';

class PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador();
