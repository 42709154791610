import React, { createContext, useCallback, useContext, useState } from 'react';
import { produce } from 'immer';
import { AppErro, IRoteiroFabricacaoOperacaoLista } from '@elogestor/util';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IRoteiroFabricacaoOperacaoContext {
  validador: boolean;

  listaValor: IRoteiroFabricacaoOperacaoLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IRoteiroFabricacaoOperacaoLista[]>
  >;

  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(
    lista?: IRoteiroFabricacaoOperacaoLista[]
  ): Promise<void>;

  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const RoteiroFabricacaoOperacaoContext =
  createContext<IRoteiroFabricacaoOperacaoContext>(
    {} as IRoteiroFabricacaoOperacaoContext
  );

const ListaRoteiroFabricacaoOperacaoDraggableHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IRoteiroFabricacaoOperacaoLista[]
  >([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(() => {
    throw new AppErro({
      mensagem:
        'A função "handleAtualizarOrdem" não foi implementada no "ListaRoteiroFabricacaoOperacaoDraggableHook"',
    });
  }, []);

  return (
    <RoteiroFabricacaoOperacaoContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </RoteiroFabricacaoOperacaoContext.Provider>
  );
};

function UseListaRoteiroFabricacaoOperacaoDraggable(): Omit<
  IRoteiroFabricacaoOperacaoContext,
  'validador'
> {
  const context = useContext(RoteiroFabricacaoOperacaoContext);

  if (!context.validador) {
    throw new Error(
      `UseListaRoteiroFabricacaoOperacaoDraggable deve ser usado
       com um ListaRoteiroFabricacaoOperacaoDraggableHook`
    );
  }

  return context;
}

export {
  ListaRoteiroFabricacaoOperacaoDraggableHook,
  UseListaRoteiroFabricacaoOperacaoDraggable,
};
