import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ReprocessamentoProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/ReprocessamentoProducao/Comunicador/ReprocessamentoProducaoComunicador';
import FormHook from './FormHook';
import { ReprocessamentoProducaoHook } from './ReprocessamentoProducaoHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const ReprocessamentoProducaoHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: ReprocessamentoProducaoComunicador }}
    >
      <RotasHook>
        <ReprocessamentoProducaoHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </ReprocessamentoProducaoHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default ReprocessamentoProducaoHooks;
