import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompleteEstado from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import Input from '../../../../../../Componentes/Inputs/Input';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';

const ExportacaoTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <InputAutoCompleteEstado
              label="UF de Embarque"
              name="idEstadoEmbarque"
              placeholder="UF de Embarque"
              nomeObjeto="estadoEmbarque"
              disabled={situacaoBloquearCampos}
            />
          </Col>

          <Col lg={6} md={6} sm={6}>
            <Input
              label="Local de Embarque"
              name="localEmbarque"
              placeholder="Local de Embarque"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ExportacaoTab;
