"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CampoAlteradoBoletoEnum;
(function (CampoAlteradoBoletoEnum) {
    CampoAlteradoBoletoEnum["desconto"] = "A";
    CampoAlteradoBoletoEnum["jurosPorDia"] = "B";
    CampoAlteradoBoletoEnum["descontoPorAntecipacao"] = "C";
    CampoAlteradoBoletoEnum["dataLimiteParaConcessaoDesconto"] = "D";
    CampoAlteradoBoletoEnum["cancelamentoProtestoAutomatico"] = "E";
})(CampoAlteradoBoletoEnum || (CampoAlteradoBoletoEnum = {}));
exports.default = CampoAlteradoBoletoEnum;
