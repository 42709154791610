import { IProdutoMovimentacoesVendasLista } from '@elogestor/util';
import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';

interface IListaContext {
  validador: boolean;

  listaValor: IProdutoMovimentacoesVendasLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IProdutoMovimentacoesVendasLista[]>
  >;

  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const ListaContext = createContext<IListaContext>({} as IListaContext);

const ListaDraggableHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaValor, setListaValor] = useState<
    IProdutoMovimentacoesVendasLista[]
  >([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  return (
    <ListaContext.Provider
      value={{
        listaValor,
        setListaValor,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </ListaContext.Provider>
  );
};

function UseListaDraggable(): Omit<IListaContext, 'validador'> {
  const context = useContext(ListaContext);

  if (!context.validador) {
    throw new Error(
      'UseListaDraggable deve ser usado com um ListaDraggableHook'
    );
  }

  return context;
}

export { ListaDraggableHook, UseListaDraggable };
