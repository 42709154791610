import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/origem-icms-obter-por-nome';

interface IOrigemIcmsObterPorNomeComunicadorIndex {
  params: {
    nome: string;
  };
}

class OrigemIcmsObterPorNomeComunicador {
  public async index(
    params: IOrigemIcmsObterPorNomeComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OrigemIcmsObterPorNomeComunicador();
