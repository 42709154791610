import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-pedido-compra/obter-pedido-compra-item';

interface INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicadorIndex {
  listaIdPedidoCompra: string[];
}

class NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicador();
