import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/obter-nota-fiscal-saida';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaComunicadorIndex {
  idPessoa: string;
  idNotaFiscalSaida?: string;
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaComunicador();
