/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useRef, useState } from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Col, Row } from 'react-bootstrap';
import {
  AppErro,
  FormatarDataParaBoleto,
  FormatarHoraParaBoleto,
  ITerminalCaixaValoresInserir,
} from '@elogestor/util';
import axios, { AxiosRequestConfig } from 'axios';
import InputAutoCompleteTerminalCaixa from '../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteTerminalCaixa';
import {
  ICertificadoTEF,
  ISiTefContinuarTransacaoRetorno,
  ISiTefCriarTransacaoRetorno,
  ISiTefFinalizarTransacaoParametros,
  ISiTefFinalizarTransacaoRetorno,
} from '../../../Paginas/Comercial/Vendas/FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import TratarErros from '../../../Util/Erro/TratarErros';
import TerminalCaixaComunicador from '../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaComunicador';
import TerminalCaixaEmpresaObterCertificadoComunicador from '../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaObterCertificadoComunicador';
import Input from '../../../Componentes/Inputs/Input';

const https = require('https-browserify');

export interface ISiTefCriarSessaoParametros {
  certificado: ICertificadoTEF;
  terminalCaixa: ITerminalCaixaValoresInserir;
}

export interface ISiTefCriarSessaoRetorno {
  clisitefStatus: number;
  serviceStatus: number;
  sessionId: string;
}

export interface ISiTefFinalizarSessaoParametros {
  certificado: ICertificadoTEF;
}

export interface ISiTefFinalizarSessaoRetorno {
  clisitefStatus: number;
  serviceStatus: number;
}

const TesteAldoir: React.FC = () => {
  const formRef = useRef<IFormCiaHandles>(null);

  const [idSessao, setIdSessao] = useState('');

  const handleCriarSessao = useCallback(
    async ({
      certificado,
      terminalCaixa,
    }: ISiTefCriarSessaoParametros): Promise<ISiTefCriarSessaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL('https://127.0.0.1/agente/clisitef/session');

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sitefIp: '127.0.0.1',
          storeId: terminalCaixa.storeIdSiTef,
          terminalId: terminalCaixa.terminalIdSiTef,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleFinalizarSessao = useCallback(
    async ({
      certificado,
    }: ISiTefFinalizarSessaoParametros): Promise<ISiTefFinalizarSessaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL('https://127.0.0.1/agente/clisitef/session');

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        const response = await axios.delete(url.toString(), config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleCriarTransacao = useCallback(
    async ({
      certificado,
      terminalCaixa,
      sessionId,
    }: any): Promise<ISiTefCriarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/startTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sitefIp: '127.0.0.1',
          storeId: terminalCaixa.storeIdSiTef,
          terminalId: terminalCaixa.terminalIdSiTef,

          sessionId,

          functionId: 130,
          trnAmount: '',
          taxInvoiceNumber: '1234',
          taxInvoiceDate: '20170304',
          taxInvoiceTime: '170000',
          cashierOperator: 'CAIXA',
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleContinuarTransacao = useCallback(
    async ({
      certificado,
      sessionId,
      dados,
    }: any): Promise<ISiTefContinuarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/continueTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sessionId,
          data: dados,
          continue: '0',
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleFinalizarTransacao = useCallback(
    async ({
      certificado,
      sessionId,
      numeroVenda,
      dataHoraEmissao,
      confirmar,
    }: ISiTefFinalizarTransacaoParametros): Promise<ISiTefFinalizarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/finishTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sessionId,
          taxInvoiceNumber: numeroVenda,
          taxInvoiceDate: FormatarDataParaBoleto(dataHoraEmissao),
          taxInvoiceTime: FormatarHoraParaBoleto(dataHoraEmissao),
          confirm: confirmar ? 1 : 0,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  return (
    <div style={{ backgroundColor: 'lightgray', margin: 50, padding: 50 }}>
      <h1>Teste Aldoir</h1>

      <FormCia ref={formRef}>
        <Row style={{ marginTop: 30 }}>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTerminalCaixa
              label="Terminal Caixa"
              name="idTerminalCaixa"
            />
          </Col>
          <Col lg={3} md={3} sm={3}>
            <Input label="Dados" name="dados" />
          </Col>

          <Col lg={12} md={12} sm={12}>
            <button
              type="button"
              onClick={async () => {
                const idTerminalCaixa =
                  formRef.current?.getFieldValue('idTerminalCaixa');

                const terminalCaixa = await TerminalCaixaComunicador.show({
                  id: idTerminalCaixa,
                });

                const certificado =
                  await TerminalCaixaEmpresaObterCertificadoComunicador.show({
                    idTerminalCaixa,
                  });

                const sessao = await handleCriarSessao({
                  certificado,
                  terminalCaixa,
                });

                console.log('sessao', sessao);
                setIdSessao(String(sessao?.sessionId));

                const transacao = await handleCriarTransacao({
                  certificado,
                  terminalCaixa,
                  sessionId: sessao?.sessionId,
                });

                console.log('transacao', transacao);
              }}
              className="btn-padrao btn-verde"
              style={{ minWidth: 200, marginTop: 10 }}
            >
              INICIAR
            </button>
          </Col>

          <Col lg={12} md={12} sm={12}>
            <button
              type="button"
              onClick={async () => {
                const idTerminalCaixa =
                  formRef.current?.getFieldValue('idTerminalCaixa');

                const certificado =
                  await TerminalCaixaEmpresaObterCertificadoComunicador.show({
                    idTerminalCaixa,
                  });

                let numeroVenda = '';
                let data = '';
                let hora = '';

                for (let i = 0; i < 8; i++) {
                  const continuar = await handleContinuarTransacao({
                    certificado,
                    sessionId: idSessao,
                    dados: formRef.current?.getFieldValue('dados'),
                  });

                  console.log('continuar', continuar);

                  if (continuar?.fieldId === 160) {
                    numeroVenda = continuar?.data;
                  }
                  if (continuar?.fieldId === 163) {
                    data = continuar?.data;
                  }
                  if (continuar?.fieldId === 164) {
                    hora = continuar?.data;
                  }
                }

                const combinedDateTime = `${data.slice(0, 4)}-${data.slice(4, 6)}-${data.slice(6)}T${hora.slice(0, 2)}:${hora.slice(2, 4)}:${hora.slice(4)}`;
                const dateTime = new Date(combinedDateTime);

                const finalizar = await handleFinalizarTransacao({
                  certificado,
                  sessionId: idSessao,
                  dataHoraEmissao: dateTime,
                  numeroVenda,
                  confirmar: false,
                });

                console.log('finalizar', finalizar);
              }}
              className="btn-padrao btn-verde"
              style={{ minWidth: 200, marginTop: 10 }}
            >
              CONTINUAR
            </button>
          </Col>

          <Col lg={12} md={12} sm={12}>
            <button
              type="button"
              onClick={async () => {
                const idTerminalCaixa =
                  formRef.current?.getFieldValue('idTerminalCaixa');

                const certificado =
                  await TerminalCaixaEmpresaObterCertificadoComunicador.show({
                    idTerminalCaixa,
                  });

                const finalizar = await handleFinalizarTransacao({
                  certificado,
                  sessionId: idSessao,
                  dataHoraEmissao: new Date('2024/01/18 10:20:36'),
                  numeroVenda: '0',
                  confirmar: false,
                });

                console.log('finalizar', finalizar);
              }}
              className="btn-padrao btn-verde"
              style={{ minWidth: 200, marginTop: 10 }}
            >
              FINALIZAR
            </button>
          </Col>
        </Row>
      </FormCia>
    </div>
  );
};

export default TesteAldoir;
