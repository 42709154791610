"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoPedidoVendaNotaFiscalSaidaEnum;
(function (SituacaoPedidoVendaNotaFiscalSaidaEnum) {
    SituacaoPedidoVendaNotaFiscalSaidaEnum["atendido"] = "Atendido";
    SituacaoPedidoVendaNotaFiscalSaidaEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoPedidoVendaNotaFiscalSaidaEnum["naoAtendido"] = "N\u00E3o Atendido";
    SituacaoPedidoVendaNotaFiscalSaidaEnum["pedidoCancelado"] = "Ped. Cancelado";
})(SituacaoPedidoVendaNotaFiscalSaidaEnum || (SituacaoPedidoVendaNotaFiscalSaidaEnum = {}));
exports.default = SituacaoPedidoVendaNotaFiscalSaidaEnum;
