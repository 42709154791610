import styled, { css } from 'styled-components';
import { mobile } from '../../Comum/Styles/Global';

const Disivor = styled.div`
  background-color: #eeeeee;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 0 0 25px 0;
  &:last-child {
    margin-bottom: 15px;
  }
  & > div > div {
    margin-top: 10px;
  }
  ${mobile &&
  css`
    padding: 10px;
  `}

  input,
  select,
  .btn-adicionar {
    width: 100%;
    height: 35px;
    margin-bottom: 5px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    label {
      margin: 0 0 0 5px;
    }

    input {
      width: 20px;
      height: 20px;
      margin-bottom: 0;
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
  }

  .div-duas-linhas {
    & > div {
      margin-top: 0;

      & > div > div {
        margin-top: 10px;
      }
    }
  }

  ${mobile &&
  css`
    .div-checkbox > div {
      margin-top: 15px;
    }
  `}

  input, select {
    background: #fff;
  }
`;

export default Disivor;
