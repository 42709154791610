import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  IPessoaPorRepresentanteRelatorio,
  FormatarCep,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPessoaPorRepresentantePDF {
  dados: IPessoaPorRepresentanteRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PessoaPorCidadePDF: React.FC<IPessoaPorRepresentantePDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina}>
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRepresentante && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Representante: '}
                      </Text>
                      {dados.cabecalho.filtroRepresentante}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroTag && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tags: '}</Text>
                      {dados.cabecalho.filtroTag}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '20%' }}>
              <Text>Nome/RazaoSocial</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Cnpj/Cpf</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text>Inscr. Estadual</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Telefone</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '20%' }}>
              <Text>Email</Text>
            </View>
          </View>

          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%' }} />
            <View style={{ width: '30%' }}>
              <Text>Logradouro/Número</Text>
            </View>

            <View style={{ width: '5%' }} />
            <View style={{ width: '19%' }}>
              <Text>Complemento</Text>
            </View>

            <View style={{ width: '5%' }} />
            <View style={{ width: '20%' }}>
              <Text>Cidade</Text>
            </View>
            <View style={{ width: '5%' }} />

            <View style={{ width: '10%' }}>
              <Text>CEP</Text>
            </View>
          </View>
          {dados.listaDados.map((grupoRepresentante) => {
            return (
              <View key={grupoRepresentante.chave}>
                <Text style={{ marginTop: 5, fontWeight: 'bold' }}>
                  {`Representante: ${grupoRepresentante.listaPessoa[0].pessoaRepresentante?.nomeRazaoSocial}`}
                </Text>

                {grupoRepresentante.listaPessoa.map((pessoaObj, index) => {
                  return (
                    <View
                      key={pessoaObj.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#e4e4e4' : '#fff',
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '5%', textAlign: 'right' }}>
                          <Text>{pessoaObj.pessoaCliente.pessoa.codigo}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '20%' }}>
                          <Text>
                            {pessoaObj.pessoaCliente.pessoa.nomeRazaoSocial}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }}>
                          <Text>
                            {pessoaObj.pessoaCliente.pessoa.cnpjCpfVirtual}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '13%' }}>
                          <Text>
                            {pessoaObj.pessoaCliente.pessoa.inscricaoEstadual}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }}>
                          <Text>{pessoaObj.telefone}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '25%' }}>
                          <Text>{pessoaObj.email}</Text>
                        </View>
                      </View>

                      <View fixed style={{ flexDirection: 'row' }}>
                        <View style={{ width: '10%' }} />
                        <View style={{ width: '30%' }}>
                          <Text>
                            {
                              pessoaObj.pessoaCliente.pessoa
                                .pessoaEnderecoPrincipal.logradouro
                            }
                            {pessoaObj.pessoaCliente.pessoa
                              .pessoaEnderecoPrincipal?.logradouro &&
                              pessoaObj.pessoaCliente.pessoa
                                .pessoaEnderecoPrincipal?.numero &&
                              ', '}
                            {
                              pessoaObj.pessoaCliente.pessoa
                                .pessoaEnderecoPrincipal?.numero
                            }
                          </Text>
                        </View>

                        <View style={{ width: '5%' }} />

                        <View style={{ width: '19%' }}>
                          <Text>
                            {
                              pessoaObj.pessoaCliente.pessoa
                                .pessoaEnderecoPrincipal?.complemento
                            }
                          </Text>
                        </View>

                        <View style={{ width: '5%' }} />

                        <View style={{ width: '20%' }}>
                          <Text>
                            {`${pessoaObj.pessoaCliente.pessoa.pessoaEnderecoPrincipal.cidade?.nome}-${pessoaObj.pessoaCliente.pessoa.pessoaEnderecoPrincipal.cidade?.estado.sigla}`}
                          </Text>
                        </View>
                        <View style={{ width: '5%' }} />

                        <View style={{ width: '10%' }}>
                          <Text>
                            {FormatarCep(
                              pessoaObj.pessoaCliente.pessoa
                                .pessoaEnderecoPrincipal?.cep
                            )}
                          </Text>
                        </View>
                      </View>

                      {pessoaObj.listaPessoaContato.map((contato) => {
                        return (
                          <View key={contato.id}>
                            <View fixed style={{ flexDirection: 'row' }}>
                              <View style={{ width: '32%' }}>
                                <Text>Contato: {contato.nome}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '32%' }}>
                                <Text>Telefone: {contato.telefone}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '32%' }}>
                                <Text>Email: {contato.email}</Text>
                              </View>
                              <View style={{ width: '1%' }} />
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  );
                })}

                <View
                  style={{ borderBottom: '1pt dashed black', width: '100%' }}
                />
              </View>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às
            { ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PessoaPorCidadePDF;
