/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  ITerminalCaixaValoresInserir,
  SiTefContinuacaoEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import {
  ICertificadoTEF,
  UseSiTef,
} from '../../../../../FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import TerminalCaixaEmpresaObterCertificadoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaObterCertificadoComunicador';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import ImpressaoComprovanteTef from '../../../../../../../DocumentosEletronicos/NFCe/ImpressaoComprovanteTef';

interface IMenuSiTefModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const MenuSiTefDetalhe: React.FC<IMenuSiTefModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { formRef: formRefPrincipal, getIdDetalheRegistro } = UseForm();
  const {
    criarSessao,
    finalizarSessao,
    criarTransacao,
    continuarTransacao,
    finalizarTransacao,
  } = UseSiTef();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const [sessionId, setSessionId] = useState<string>();
  const [certificado, setCertificado] = useState<ICertificadoTEF>();

  const [listaEscolha, setListaEscolha] = useState<string[]>([]);
  const [nivel, setNivel] = useState(0);

  const [exibirOpcao, setExibirOpcao] = useState(true);
  const [exibirBotoaoCancelar, setExibirBotaoCancelar] = useState(true);
  const [exibirBotoaoOk, setExibirBotaoOk] = useState(true);

  const [finalizadaConsulta, setFinalizadaConsulta] = useState(false);

  const opcao = formRef.current?.getFieldValue('opcao');

  const handleFechar = useCallback(async (): Promise<void> => {
    try {
      if (sessionId && certificado) {
        await continuarTransacao({
          certificado: certificado as ICertificadoTEF,
          sessionId,
          dados: '',
          continuacao: SiTefContinuacaoEnum.encerar,
        });
      }

      onFecharFormModal();
    } catch (error) {
      TratarErros(error);
      onFecharFormModal();
    }
  }, [certificado, continuarTransacao, onFecharFormModal, sessionId]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const novoIdPagamentoTef = v4().substring(0, 20);

      const idTerminalCaixa = getIdDetalheRegistro() || '';
      const data = formRefPrincipal.current?.getData();

      const terminalCaixaCertificado =
        await TerminalCaixaEmpresaObterCertificadoComunicador.show({
          idTerminalCaixa,
        });

      const transacao = await criarTransacao({
        certificado: terminalCaixaCertificado as ICertificadoTEF,
        terminalCaixa: data as ITerminalCaixaValoresInserir,
        codigoFuncao: 110,
        valorTotal: 0,
        numeroVenda: novoIdPagamentoTef,
        dataHoraEmissao: new Date(),
        operador: 'CAIXA',
      });

      if (!transacao) {
        ToastErro('Erro ao obter o Menu do SiTef!');
        handleFechar();
        setLoading(false);
        return;
      }

      setSessionId(transacao.sessionId);
      setCertificado(terminalCaixaCertificado);

      const resposta1 = await continuarTransacao({
        certificado: terminalCaixaCertificado as ICertificadoTEF,
        sessionId: transacao.sessionId,
        dados: '',
        continuacao: SiTefContinuacaoEnum.continuar,
      });

      if (
        !resposta1 ||
        resposta1.clisitefStatus !== 10000 ||
        !resposta1.data ||
        !resposta1.data.includes('Selecione a opcao desejada')
      ) {
        ToastErro('Erro ao obter o Menu do SiTef!');
        handleFechar();
        setLoading(false);
        return;
      }

      const resposta2 = await continuarTransacao({
        certificado: terminalCaixaCertificado as ICertificadoTEF,
        sessionId: transacao.sessionId,
        dados: '',
        continuacao: SiTefContinuacaoEnum.continuar,
      });

      if (!resposta2 || resposta2.clisitefStatus !== 10000 || !resposta2.data) {
        ToastErro('Erro ao obter o Menu do SiTef!');
        handleFechar();
        setLoading(false);
        return;
      }

      const items = resposta2.data
        .split(';')
        .map((item) => item.trim())
        .join('\n');

      const texto = `${String(resposta1.data)}\n\n${items}`;

      formRef.current?.setFieldValue('texto', texto);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    continuarTransacao,
    criarTransacao,
    formRefPrincipal,
    getIdDetalheRegistro,
    handleFechar,
  ]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinuar = useCallback(async (): Promise<void> => {
    if (finalizadaConsulta) {
      handleFechar();
      return;
    }

    try {
      if (!sessionId || !certificado) {
        ToastErro('Erro ao obter o Menu do SiTef!');
        handleFechar();
        return;
      }

      const nivelAtual = nivel + 1;
      setNivel(nivelAtual);

      let opcaoAtual = formRef.current?.getFieldValue('opcao') || '';
      formRef.current?.clearField('opcao');

      const listaEscolhaAtual = [...listaEscolha, opcaoAtual];
      setListaEscolha(listaEscolhaAtual);

      let quantidadeInteracao = 1;
      let texto = '';

      switch (listaEscolhaAtual[0]) {
        case '1':
          if (nivelAtual === 1) {
            quantidadeInteracao = 3;
            setExibirOpcao(false);
          } else if (nivelAtual === 2) {
            quantidadeInteracao = 2;
            opcaoAtual = '0';
          }
          break;

        case '2':
          if (nivelAtual === 1) {
            quantidadeInteracao = 4;
          } else if (nivelAtual === 2) {
            if (listaEscolhaAtual[1] === '1') {
              quantidadeInteracao = 4;
            } else {
              quantidadeInteracao = 3;
            }
          } else if (nivelAtual === 3) {
            if (listaEscolhaAtual[1] === '1') {
              quantidadeInteracao = 3;
            } else {
              quantidadeInteracao = 2;
              setExibirOpcao(false);
            }
          } else if (nivelAtual === 4) {
            if (listaEscolhaAtual[1] === '1') {
              quantidadeInteracao = 1;
            } else {
              quantidadeInteracao = 2;
              opcaoAtual = '0';
            }
          } else if (nivelAtual === 5) {
            if (listaEscolhaAtual[1] === '1') {
              quantidadeInteracao = 1;
            }
          } else if (nivelAtual === 6) {
            if (listaEscolhaAtual[1] === '1') {
              quantidadeInteracao = 10;
              setExibirOpcao(false);
              setExibirBotaoOk(false);
              setExibirBotaoCancelar(false);
            }
          }
          break;

        case '11':
          quantidadeInteracao = 1000;

          setExibirOpcao(false);
          setExibirBotaoOk(false);
          setExibirBotaoCancelar(false);
          break;

        case '15':
          if (nivelAtual === 1) {
            quantidadeInteracao = 3;
          } else if (nivelAtual === 2) {
            quantidadeInteracao = 10;
          }
          break;

        default:
          ToastErro('Opção não implementada!');
          handleFechar();
          return;
      }

      let continuar = true;
      let interacao = 0;

      while (continuar) {
        const resposta = await continuarTransacao({
          certificado: certificado as ICertificadoTEF,
          sessionId,
          dados: opcaoAtual,
          continuacao: SiTefContinuacaoEnum.continuar,
        });

        opcaoAtual = '';

        if (!resposta || resposta.clisitefStatus !== 10000) {
          continuar = false;
          setExibirBotaoOk(true);
          setExibirBotaoCancelar(true);
          setFinalizadaConsulta(true);
          return;
        }

        if (listaEscolhaAtual[0] === '1' && nivelAtual === 2) {
          if (resposta.data === 'Servidor Ativo') {
            texto = resposta.data;
          } else {
            texto = 'Servidor Inativo';
          }

          formRef.current?.setFieldValue('texto', texto);
          setFinalizadaConsulta(true);
        } else if (listaEscolhaAtual[0] === '2') {
          if (nivelAtual === 1 && resposta.data && resposta.commandId === 21) {
            const items = resposta.data
              .split(';')
              .map((item) => item.trim())
              .join('\n');

            texto += `\n\n${items}`;
          } else if (
            nivelAtual === 2 &&
            listaEscolhaAtual[1] === '1' &&
            resposta.commandId === 21
          ) {
            const items = resposta.data
              .split(';')
              .map((item) => item.trim())
              .join('\n');

            texto += `\n\n${items}`;
          } else if (nivelAtual === 3 && listaEscolhaAtual[1] === '2') {
            if (resposta.commandId === 20) {
              texto += resposta.data;
            }
          } else if (nivelAtual === 4 && listaEscolhaAtual[1] === '2') {
            texto = resposta.data;

            formRef.current?.setFieldValue('texto', texto);

            if (resposta.fieldId === 121) {
              await ImpressaoComprovanteTef.handleVisualizarPdf(resposta.data);
            }
          } else if (nivelAtual === 6) {
            texto = resposta.data;

            formRef.current?.setFieldValue('texto', texto);

            if (resposta.fieldId === 121) {
              await ImpressaoComprovanteTef.handleVisualizarPdf(resposta.data);
            }
          } else {
            texto += resposta.data;
          }
        } else if (listaEscolhaAtual[0] === '11') {
          texto = resposta.data;
          formRef.current?.setFieldValue('texto', texto);
        } else {
          if (!texto.IsNullOrEmpty()) {
            texto += '\n';
          }

          texto += resposta.data;
        }

        interacao += 1;
        if (interacao === quantidadeInteracao) continuar = false;
      }

      formRef.current?.setFieldValue('texto', texto);

      refresh();
    } catch (error) {
      TratarErros(error);
    }
  }, [
    certificado,
    continuarTransacao,
    finalizadaConsulta,
    handleFechar,
    listaEscolha,
    nivel,
    refresh,
    sessionId,
  ]);

  return (
    <JanelaDetalhe
      titulo="Menu SiTef"
      tamanho="lg"
      onFecharFormModal={handleFechar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body style={{ height: 500 }}>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <InputLabel
                  name="texto"
                  label=""
                  formatarValor={(value = '') => {
                    return (
                      <div
                        style={{ whiteSpace: 'pre-wrap', fontWeight: 'normal' }}
                      >
                        {value}
                      </div>
                    );
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col
                lg={5}
                md={6}
                sm={12}
                style={{ marginTop: 20, display: exibirOpcao ? '' : 'none' }}
              >
                <Input label="" name="opcao" onChange={refresh} />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <div className="alinhar-direita espacamento-interno-para-esquerda-15">
                <Container style={{ display: 'flex' }}>
                  <Button
                    style={{
                      fontWeight: 'bold',
                      marginLeft: -10,
                      display: exibirBotoaoCancelar ? 'flex' : 'none',
                      alignItems: 'center',
                      minWidth: 150,
                      justifyContent: 'center',
                    }}
                    type="button"
                    className="btn-padrao btn-cinza-claro"
                    onClick={handleFechar}
                    disabled={loading}
                  >
                    <TextoLoading loading={loading}>
                      <MdCancel />
                      <span style={{ marginLeft: 10 }}>Cancelar</span>
                    </TextoLoading>
                  </Button>

                  <Button
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 10,
                      display: exibirBotoaoOk ? 'flex' : 'none',
                      alignItems: 'center',
                      minWidth: 150,
                      justifyContent: 'center',
                    }}
                    type="button"
                    onClick={handleContinuar}
                    disabled={loading || (!opcao && exibirOpcao)}
                  >
                    <TextoLoading loading={loading}>
                      <AiOutlineCheck />
                      <span style={{ marginLeft: 10 }}>OK</span>
                    </TextoLoading>
                  </Button>
                </Container>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default MenuSiTefDetalhe;
