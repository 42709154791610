import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-ordem-servico/obter-ordem-servico';

interface INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicadorIndex {
  idPessoa: string;
  idNotaFiscalSaida?: string;
}

class NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoComunicador();
