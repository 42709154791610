import React from 'react';
import { ComunicadorProvider } from '../../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import LocalEstoqueComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/LocalEstoque/Comunicador/LocalEstoqueComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const LocalEstoqueHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: LocalEstoqueComunicador }}>
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default LocalEstoqueHook;
