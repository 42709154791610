"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MoverItensPosicaoArray;
function MoverItensPosicaoArray({ array, indexDestino, indexItemMovimentar, }) {
    if (indexDestino === indexItemMovimentar)
        return array;
    const prev = array.filter((_, idx) => idx < indexDestino && idx !== indexItemMovimentar);
    const next = array.filter((_, idx) => idx > indexDestino && idx !== indexItemMovimentar);
    const direcaoMovimento = indexDestino > indexItemMovimentar ? 'direita' : 'esquerda';
    if (direcaoMovimento === 'direita') {
        return [...prev, array[indexDestino], array[indexItemMovimentar], ...next];
    }
    return [...prev, array[indexItemMovimentar], array[indexDestino], ...next];
}
