import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { IPesagemLocalEstoqueLista } from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteLocalEstoque from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheGeral: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosPesagensPesagem: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setLoading,
    setIdDetalheRegistro,
    refresh,
    handleSubmit,
  } = UseListaDetalheForm();

  const { abrirJanela } = UseConfirmacao();

  const valorRef = useRef<IPesagemLocalEstoqueLista[]>([]);
  const [listaValor, setListaValor] = useState<IPesagemLocalEstoqueLista[]>([]);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getDataDuplicar();

    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  const listaPesagemLocalEstoque = formPrincipal.formRef.current?.getFieldValue(
    'listaPesagemLocalEstoque'
  );

  const pesoLiquidoLocalEstoque =
    formRefLista.current?.getFieldValue('pesoLiquido');

  const pesoLiquidoPesagem =
    formPrincipal.formRef.current?.getFieldValue('pesoLiquido');

  const handleSugerirPesoLiquido = useCallback(async () => {
    let somaPesoLiquidoLocalEstoque = 0;
    let diferencaPesoLiquidoLocalEstoque = 0;

    if (listaPesagemLocalEstoque) {
      for (let index = 0; index < listaPesagemLocalEstoque.length; index++) {
        const valor = listaPesagemLocalEstoque[index];
        somaPesoLiquidoLocalEstoque += valor.pesoLiquido;

        if (pesoLiquidoPesagem > 0) {
          diferencaPesoLiquidoLocalEstoque =
            pesoLiquidoPesagem - somaPesoLiquidoLocalEstoque;
        }
      }

      if (listaPesagemLocalEstoque.length === 0 && pesoLiquidoPesagem > 0) {
        formRefLista.current?.setFieldValue('pesoLiquido', pesoLiquidoPesagem);
      } else if (
        listaPesagemLocalEstoque.length > 0 &&
        pesoLiquidoPesagem > 0
      ) {
        formRefLista.current?.setFieldValue(
          'pesoLiquido',
          diferencaPesoLiquidoLocalEstoque
        );
      }
    }
  }, [formRefLista, listaPesagemLocalEstoque, pesoLiquidoPesagem]);

  const handleRemoverLocalEstoque = async (index: string): Promise<void> => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item ?</span>,
      });

      if (resposta) {
        listaPesagemLocalEstoque.splice(Number(index), 1);
      }

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  };

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPesagemLocalEstoqueLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Row>
            <Col xl={8} lg={12}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoque"
                label="Local de Estoque"
                placeholder="Local de Estoque"
                nomeObjeto="localEstoque"
                permitirAdicionar
                onBlurInput={() => {
                  if (
                    pesoLiquidoLocalEstoque === 0 ||
                    pesoLiquidoLocalEstoque === null
                  ) {
                    handleSugerirPesoLiquido();
                  }
                }}
              />
            </Col>
            <Col xl={2} lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Peso Líquido (KG)"
                placeholder="Peso Líquido (KG)"
                name="pesoLiquido"
                casasDecimais={3}
                casasInteiras={15}
              />
            </Col>
            <Col
              xl={2}
              lg={6}
              md={6}
              sm={12}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <BtnPadraoButton
                type="button"
                onClick={handleAdicionar}
                className="btn-padrao btn-verde-claro btn-adicionar"
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAdd />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '55%' }}>
                    <span>Local de estoque</span>
                  </th>
                  <th className="lista-valor" style={{ width: '45%' }}>
                    <span>Peso Líquido</span>
                  </th>
                  <th className="lista-texto">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item, index) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">
                        {item.localEstoque.descricao}
                      </td>
                      <td className="lista-valor">
                        {Number(item.pesoLiquido).FormatarParaPtBr()}
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleRemoverLocalEstoque(String(index));
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <IoMdRemoveCircleOutline
                                  style={{ marginRight: 5 }}
                                />
                                Remover
                              </span>
                            </Dropdown.Item>
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheGeral;
