import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/nota-fiscal-entrada/nota-fiscal-entrada-por-cfop-csosn-cst-icms';

interface INotaFiscalEntradaPorCfopCsosnCstIcmsRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalEntradaPorCfopCsosnCstIcmsRelatorioComunicador {
  public async index(
    params: INotaFiscalEntradaPorCfopCsosnCstIcmsRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalEntradaPorCfopCsosnCstIcmsRelatorioComunicador();
