/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  SelectHTMLAttributes,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { Container, SpanErro } from './styles';
import { DefaultSelect } from '../Inputs/Styles';

interface IOption {
  title: string;
  value: string;
  hidden?: boolean;
}

interface ISelectEventoCia {
  valor: string | null;
  input: HTMLSelectElement;
}

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string | JSX.Element;
  options: IOption[];
  removerOpcoes?: string[];
  onChangeValue?: (evento: ISelectEventoCia) => void;
}

const Select: React.FC<ISelectProps> = ({
  label,
  name,
  options,
  removerOpcoes = [],
  onChange,
  onChangeValue,
  ...rest
}) => {
  const { fieldName, error, registerField } = UseFieldCia(name);

  const inputRef = useRef<HTMLSelectElement>(null);

  const [erro, setErro] = useState(error);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    setErro(error);
  }, [error]);

  useEffect(() => {
    registerField<string | boolean | null>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        if (inputRef.current?.value) {
          return inputRef.current.value;
        }
        return null;
      },

      setValue(_, novoValor) {
        setErro('');
        if (inputRef.current) {
          if (typeof novoValor === 'boolean') {
            inputRef.current.value = String(novoValor);
            return;
          }
          if (onChangeValue)
            onChangeValue({
              input: inputRef.current,
              valor: inputRef.current.value ?? null,
            });
          inputRef.current.value = novoValor || '';
        }
      },

      setSemExecutarEvento(_, novoValor) {
        setErro('');
        if (inputRef.current) {
          if (typeof novoValor === 'boolean') {
            inputRef.current.value = String(novoValor);
            return;
          }

          inputRef.current.value = novoValor || '';
        }
      },

      clearValue(_, valorInicial = null) {
        setErro('');
        this.setValue(_, valorInicial || '');
      },

      validarSeAlterou(_, valorInicial = null) {
        const valor = this.getValue(_);
        return valor !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const opcoesFiltradas = options.filter(
    (option) => !removerOpcoes.includes(option.value)
  );

  return (
    <Container
      $isErrored={!!erro}
      $isFocused={isFocused}
      $isFilled={isFilled}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
    >
      {label && <label>{label}</label>}
      <DefaultSelect
        ref={inputRef}
        name={name}
        onChange={(event) => {
          setErro('');
          if (onChange) onChange(event);
        }}
        {...rest}
      >
        {opcoesFiltradas.map((option) => {
          return (
            <option
              className={option.hidden ? 'hidden' : ''}
              value={option.value}
              key={option.value}
            >
              {option.title}
            </option>
          );
        })}
      </DefaultSelect>
      {erro && <SpanErro>{erro}</SpanErro>}
    </Container>
  );
};

export default Select;
