/* eslint-disable array-callback-return */
/* eslint-disable no-continue */
/* eslint-disable default-case */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import PessoaObterValoresEmAbertoComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterValoresEmAbertoComunicador';

interface IConfirmacaoValores {
  idCliente: any;
  loading: boolean;
  setLoading(loading: boolean): void;
  onResponse(resposta: boolean): void;
}

const ConfirmacaoValores: React.FC<IConfirmacaoValores> = ({
  idCliente,
  loading,
  setLoading,
  onResponse,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);

  const [show, setShow] = useState(false);
  const [valorEmContas, setValorEmContas] = useState('0');
  const [valorEmConsignados, setValorEmConsignados] = useState('0');

  const handleValidarValoresEmAbertoCliente = useCallback(async () => {
    const { valorTotalAbertoEmConsignados, valorTotalAbertoEmContas } =
      await PessoaObterValoresEmAbertoComunicador.show({
        idCliente,
      });

    const valorAbertoEmContas = Number(
      valorTotalAbertoEmContas.somatorioValorTotalAbertoEmContas
    ).FormatarParaPtBr();

    const valorAbertoEmConsignados = Number(
      valorTotalAbertoEmConsignados.somatorioValorTotalAbertoEmConsignados
    ).FormatarParaPtBr();

    if (valorAbertoEmContas > '0,00' || valorAbertoEmConsignados > '0,00') {
      setValorEmContas(valorAbertoEmContas);
      setValorEmConsignados(valorAbertoEmConsignados);

      return true;
    }

    return false;
  }, [idCliente]);

  useEffect(() => {
    async function handleShowModal(): Promise<void> {
      const response = await handleValidarValoresEmAbertoCliente();

      if (!response) onResponse(true);

      setShow(response);
    }

    handleShowModal();
  }, [handleValidarValoresEmAbertoCliente, onResponse]);

  const handleClickConfirmar = useCallback(async (): Promise<void> => {
    onResponse(true);
    setShow(false);
  }, [onResponse]);

  const handleClickCancelar = useCallback(async (): Promise<void> => {
    onResponse(false);
    setShow(false);
  }, [onResponse]);

  return (
    <Modal show={show}>
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Header>
          <Modal.Title>Confirmação de Valores em Aberto</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row
            style={{
              display: valorEmContas > '0,00' ? 'flex' : 'none',
            }}
          >
            <span style={{ fontSize: 18 }}>
              Cliente possui R$ {valorEmContas} em contas em atraso!
            </span>
          </Row>
          <Row
            style={{
              display: valorEmConsignados > '0,00' ? 'flex' : 'none',
            }}
          >
            <span style={{ fontSize: 18, marginTop: 10 }}>
              Cliente possui R$ {valorEmConsignados} em consignados em aberto!
            </span>
          </Row>

          <Row>
            <span style={{ fontSize: 18, marginTop: 20 }}>
              Deseja continuar operação?
            </span>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <Button
                style={{
                  fontWeight: 'bold',
                  width: 95,
                }}
                variant="secondary"
                type="button"
                onClick={handleClickCancelar}
              >
                <TextoLoading loading={loading}>
                  <span>Não</span>
                </TextoLoading>
              </Button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  width: 95,
                }}
                variant="primary"
                type="button"
                onClick={handleClickConfirmar}
              >
                <TextoLoading loading={loading}>
                  <span>Sim</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </Modal>
  );
};

export default ConfirmacaoValores;
