import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-pessoa-representante-por-usuario';

class PessoaObterPessoaRepresentantePorUsuarioComunicador {
  public async show(): Promise<any[]> {
    const response = await api.get(`${rota}`);

    return response.data;
  }
}

export default new PessoaObterPessoaRepresentantePorUsuarioComunicador();
