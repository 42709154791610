import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPesagemPorDataLocalDeEstoqueRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPesagemPorDataLocalDeEstoqueRelatorioPDF {
  dados: IPesagemPorDataLocalDeEstoqueRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PesagemPorDataLocalDeEstoqueRelatorioPDF: React.FC<
  IPesagemPorDataLocalDeEstoqueRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5pt',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataInicial &&
                  dados.cabecalho.filtroDataFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataInicial} até ${dados.cabecalho.filtroDataFinal}`}
                      </Text>
                    </View>
                  )}
                {dados.cabecalho.filtroSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tipo: '}</Text>
                      {dados.cabecalho.filtroTipo}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cliente: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estado: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidade: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroGrupos && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupos}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroLocaisDeEstoque && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Locais De Estoque: '}
                      </Text>
                      {dados.cabecalho.filtroLocaisDeEstoque}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPessoaTerceiro && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Pessoa (Terceiro): '}
                      </Text>
                      {dados.cabecalho.filtroPessoaTerceiro}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '12%' }}>
              <Text>Data de emissão</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '34%' }}>
              <Text>Produto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-start' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '30%', alignItems: 'flex-end' }}>
              <Text>Peso líquido</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View>
                    <View style={{ paddingTop: '13pt', paddingBottom: '3pt' }}>
                      <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                        Data: {dado.data}
                      </Text>
                    </View>
                    {dado.listaEstoques.map((item) => {
                      return (
                        <View>
                          <View style={{ paddingLeft: '5pt' }}>
                            <Text
                              style={{
                                flexDirection: 'row',
                                padding: '5pt 0',
                                paddingLeft: '5pt',
                                fontWeight: 'bold',
                                marginLeft: 10,
                                fontSize: 9,
                              }}
                            >
                              Local de Estoque: {item.descricao}
                            </Text>
                          </View>

                          <View style={{ paddingLeft: '1pt' }}>
                            {item.listaPesagem.map((pesagem, pesagemIndex) => {
                              return (
                                <View key={String(2)}>
                                  <View
                                    style={{
                                      paddingLeft: '5pt',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      fontSize: 9,
                                      backgroundColor:
                                        pesagemIndex % 2 === 0
                                          ? '#E4E4E4'
                                          : '#fff',
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: '5%',
                                        paddingLeft: '5pt',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <Text>{pesagem.numero}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View
                                      style={{
                                        width: '12%',
                                        paddingLeft: '5pt',
                                      }}
                                    >
                                      <Text>{pesagem.dataHoraEmissao}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View
                                      style={{
                                        width: '6%',
                                        paddingLeft: '5pt',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      <Text>{pesagem.produto.codigo}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View
                                      style={{
                                        width: '34%',
                                        paddingLeft: '5pt',
                                      }}
                                    >
                                      <Text>{pesagem.produto.descricao}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View
                                      style={{
                                        width: '7%',
                                        paddingLeft: '5pt',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <Text>
                                        {
                                          pesagem.produto.produtoEstoque
                                            .unidadeMedida.sigla
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View
                                      style={{
                                        width: '29.5%',
                                        paddingLeft: '5pt',
                                        alignItems: 'flex-end',
                                        fontSize: 9,
                                      }}
                                    >
                                      <Text>{pesagem.pesoLiquido}</Text>
                                    </View>
                                    <View style={{ width: '1.5%' }} />
                                  </View>
                                </View>
                              );
                            })}
                            <View
                              style={{
                                paddingLeft: '5pt',
                                display: 'flex',
                                flexDirection: 'row',
                                fontSize: 9,
                                borderBottom: '1pt dashed black',
                              }}
                            >
                              <View
                                style={{ paddingLeft: '5pt', width: '91%' }}
                              >
                                <Text
                                  style={{
                                    paddingLeft: '5pt',
                                    fontWeight: 'bold',
                                    paddingTop: '5pt',
                                    marginBottom: 5,
                                    marginLeft: 3,
                                    fontSize: 9,
                                  }}
                                >
                                  {'Total do local de estoque: '}
                                  {item.totalQuantidadePesagem}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: '8%',
                                  paddingLeft: '5pt',
                                  paddingTop: '5pt',
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                    fontSize: 9,
                                  }}
                                >
                                  {item.totalPesoLiquido}
                                </Text>
                              </View>
                              <View style={{ width: '1.5%' }} />
                            </View>
                          </View>
                        </View>
                      );
                    })}
                    <View
                      style={{
                        paddingLeft: '5pt',
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom:
                          dados.listaDados.length - 1 === dadoIndex
                            ? ''
                            : '1pt dashed black',
                      }}
                    >
                      <View
                        style={{
                          fontWeight: 'bold',
                          fontSize: 9,
                          width: '91%',
                          paddingTop: '8pt',
                          paddingBottom: '5pt',
                          paddingLeft: '5pt',
                        }}
                      >
                        <Text
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontSize: 9,
                          }}
                        >
                          Total da data: {dado.totalQuantidadeEstoquePorData}
                        </Text>
                        <View style={{ width: '1%' }} />
                      </View>
                      <View
                        style={{
                          paddingLeft: '5pt',
                          width: '8%',
                          paddingTop: '8pt',
                          paddingBottom: '5pt',
                        }}
                      >
                        <Text
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                            fontSize: 9,
                          }}
                        >
                          {dado.totalPesoLiquidoEstoque}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
            }}
          >
            <View style={{ width: '89%' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                  marginLeft: 2,
                }}
              >
                Total Geral: {dados.quantidadePesagemGeral}
              </Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                paddingLeft: '5pt',
                width: '9%',
                alignItems: 'flex-end',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>
                {dados.somatorioPesoLiquidoGeral}
              </Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PesagemPorDataLocalDeEstoqueRelatorioPDF;
