import { createContext, useContext } from 'react';

interface IItemNavegacaoContext {
  posicaoNavegacao: number;
  totalNavegacao: number;
  handleProximo(): void;
  handleAnterior(): void;
  handleUltimo(): void;
  handlePrimeiro(): void;
  mostrarNavegacao: boolean;
}

const ItemNavegacaoContext = createContext<IItemNavegacaoContext>(
  {} as IItemNavegacaoContext
);

const NavegacaoProvider = ItemNavegacaoContext.Provider;

function UseItemNavegacao(): Omit<IItemNavegacaoContext, 'validador'> {
  const context = useContext(ItemNavegacaoContext);

  if (!context) {
    throw new Error('UseItemNavegacao deve ser usado com um ItemNavegacaoHook');
  }

  return context;
}

export { NavegacaoProvider, UseItemNavegacao };
