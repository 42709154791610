"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsObterVisibilidadeBloqueioCamposPorCst;
function IcmsObterVisibilidadeBloqueioCamposPorCst(dados) {
    const retorno = {
        escondido: {
            // #region ICMS
            calcularIcms: true,
            modalidadeBaseCalculoIcms: true,
            percentualReducaoBaseCalculoIcms: true,
            baseCalculoIcms: true,
            aliquotaIcms: true,
            valorIcms: true,
            baseCalculoFcpIcms: true,
            aliquotaFcpIcms: true,
            valorFcpIcms: true,
            valorIcmsEValorFcpIcms: true,
            valorSemDiferimentoIcms: true,
            percentualDiferimentoIcms: true,
            valorDiferidoIcms: true,
            valorUnitarioPautaIcms: true,
            valorTotalPautaIcms: true,
            calcularIcmsEfetivo: true,
            percentualReducaoBaseCalculoIcmsEfetivo: true,
            baseCalculoIcmsEfetivo: true,
            aliquotaIcmsEfetivo: true,
            valorIcmsEfetivo: true,
            calcularIcmsSubstituto: true,
            valorUnitarioIcmsSubstituto: true,
            valorIcmsSubstituto: true,
            motivoDesoneracaoIcms: true,
            valorDesoneracaoIcms: true,
            // baseCalculoSemReducaoIcms: true,
            // baseCalculoReducaoIcms: true,
            // valorSemReducaoIcms: true,
            // valorReducaoIcms: true,
            // #endregion ICMS
            // #region ICMS ST
            calcularIcmsSt: true,
            modalidadeBaseCalculoIcmsSt: true,
            percentualMvaIcmsSt: true,
            percentualReducaoBaseCalculoIcmsSt: true,
            aliquotaInterestadualIcmsSt: true,
            aliquotaInternaIcmsSt: true,
            baseCalculoIcmsSt: true,
            valorIcmsSt: true,
            baseCalculoFcpIcmsSt: true,
            aliquotaFcpIcmsSt: true,
            valorFcpIcmsSt: true,
            valorIcmsStEValorFcpIcmsSt: true,
            calcularIcmsStRetido: true,
            baseCalculoIcmsStRetido: true,
            baseCalculoUnitariaProdutoIcmsStRetido: true,
            aliquotaIcmsStRetido: true,
            valorIcmsStRetido: true,
            valorUnitarioProdutoIcmsStRetido: true,
            baseCalculoFcpIcmsStRetido: true,
            baseCalculoUnitariaProdutoFcpIcmsStRetido: true,
            aliquotaFcpIcmsStRetido: true,
            valorFcpIcmsStRetido: true,
            valorUnitarioProdutoFcpIcmsStRetido: true,
            valorUnitarioPautaIcmsSt: true,
            valorTotalPautaIcmsSt: true,
            // #endregion ICMS ST
            // #region ICMS SN
            calcularCreditoIcmsSn: true,
            percentualCreditoIcmsSn: true,
            valorCreditoIcmsSn: true,
            // #endregion ICMS SN
            abaIcmsSt: true,
        },
        bloqueado: {
            // #region ICMS
            calcularIcms: false,
            modalidadeBaseCalculoIcms: true,
            percentualReducaoBaseCalculoIcms: true,
            baseCalculoIcms: true,
            aliquotaIcms: true,
            valorIcms: true,
            baseCalculoFcpIcms: true,
            aliquotaFcpIcms: true,
            valorFcpIcms: true,
            valorIcmsEValorFcpIcms: true,
            valorSemDiferimentoIcms: true,
            percentualDiferimentoIcms: true,
            valorDiferidoIcms: true,
            valorUnitarioPautaIcms: true,
            valorTotalPautaIcms: true,
            calcularIcmsEfetivo: false,
            percentualReducaoBaseCalculoIcmsEfetivo: true,
            baseCalculoIcmsEfetivo: true,
            aliquotaIcmsEfetivo: true,
            valorIcmsEfetivo: true,
            calcularIcmsSubstituto: false,
            valorUnitarioIcmsSubstituto: true,
            valorIcmsSubstituto: true,
            motivoDesoneracaoIcms: false,
            valorDesoneracaoIcms: false,
            // baseCalculoSemReducaoIcms: true,
            // baseCalculoReducaoIcms: true,
            // valorSemReducaoIcms: true,
            // valorReducaoIcms: true,
            // #endregion ICMS
            // #region ICMS ST
            calcularIcmsSt: false,
            modalidadeBaseCalculoIcmsSt: true,
            percentualMvaIcmsSt: true,
            percentualReducaoBaseCalculoIcmsSt: true,
            aliquotaInterestadualIcmsSt: true,
            aliquotaInternaIcmsSt: true,
            baseCalculoIcmsSt: true,
            valorIcmsSt: true,
            baseCalculoFcpIcmsSt: true,
            aliquotaFcpIcmsSt: true,
            valorFcpIcmsSt: true,
            valorIcmsStEValorFcpIcmsSt: true,
            calcularIcmsStRetido: false,
            baseCalculoIcmsStRetido: true,
            baseCalculoUnitariaProdutoIcmsStRetido: true,
            aliquotaIcmsStRetido: true,
            valorIcmsStRetido: true,
            valorUnitarioProdutoIcmsStRetido: true,
            baseCalculoFcpIcmsStRetido: true,
            baseCalculoUnitariaProdutoFcpIcmsStRetido: true,
            aliquotaFcpIcmsStRetido: true,
            valorFcpIcmsStRetido: true,
            valorUnitarioProdutoFcpIcmsStRetido: true,
            valorUnitarioPautaIcmsSt: true,
            valorTotalPautaIcmsSt: true,
            // #endregion ICMS ST
            // #region ICMS SN
            calcularCreditoIcmsSn: false,
            percentualCreditoIcmsSn: true,
            valorCreditoIcmsSn: true,
            // #endregion ICMS SN
            abaIcmsSt: true,
        },
    };
    // #region ICMS
    if (!dados.calcularIcms) {
        retorno.bloqueado.modalidadeBaseCalculoIcms = false;
        retorno.bloqueado.percentualReducaoBaseCalculoIcms = false;
        retorno.bloqueado.baseCalculoIcms = false;
        retorno.bloqueado.aliquotaIcms = false;
        retorno.bloqueado.valorIcms = false;
        retorno.bloqueado.baseCalculoFcpIcms = false;
        retorno.bloqueado.aliquotaFcpIcms = false;
        retorno.bloqueado.valorFcpIcms = false;
        retorno.bloqueado.valorIcmsEValorFcpIcms = false;
        retorno.bloqueado.valorSemDiferimentoIcms = false;
        retorno.bloqueado.percentualDiferimentoIcms = false;
        retorno.bloqueado.valorDiferidoIcms = false;
        retorno.bloqueado.valorUnitarioPautaIcms = false;
        retorno.bloqueado.valorTotalPautaIcms = false;
    }
    if (!dados.calcularIcmsEfetivo) {
        retorno.bloqueado.percentualReducaoBaseCalculoIcmsEfetivo = false;
        retorno.bloqueado.baseCalculoIcmsEfetivo = false;
        retorno.bloqueado.aliquotaIcmsEfetivo = false;
        retorno.bloqueado.valorIcmsEfetivo = false;
    }
    if (!dados.calcularIcmsSubstituto) {
        retorno.bloqueado.valorUnitarioIcmsSubstituto = false;
        retorno.bloqueado.valorIcmsSubstituto = false;
    }
    // #endregion ICMS
    // #region ICMS ST
    if (!dados.calcularIcmsSt) {
        retorno.bloqueado.modalidadeBaseCalculoIcmsSt = false;
        retorno.bloqueado.percentualMvaIcmsSt = false;
        retorno.bloqueado.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.bloqueado.aliquotaInterestadualIcmsSt = false;
        retorno.bloqueado.aliquotaInternaIcmsSt = false;
        retorno.bloqueado.baseCalculoIcmsSt = false;
        retorno.bloqueado.valorIcmsSt = false;
        retorno.bloqueado.baseCalculoFcpIcmsSt = false;
        retorno.bloqueado.aliquotaFcpIcmsSt = false;
        retorno.bloqueado.valorFcpIcmsSt = false;
        retorno.bloqueado.valorIcmsStEValorFcpIcmsSt = false;
        retorno.bloqueado.valorUnitarioPautaIcmsSt = false;
        retorno.bloqueado.valorTotalPautaIcmsSt = false;
    }
    if (!dados.calcularIcmsStRetido) {
        retorno.bloqueado.baseCalculoIcmsStRetido = false;
        retorno.bloqueado.baseCalculoUnitariaProdutoIcmsStRetido = false;
        retorno.bloqueado.aliquotaIcmsStRetido = false;
        retorno.bloqueado.valorIcmsStRetido = false;
        retorno.bloqueado.valorUnitarioProdutoIcmsStRetido = false;
        retorno.bloqueado.baseCalculoFcpIcmsStRetido = false;
        retorno.bloqueado.baseCalculoUnitariaProdutoFcpIcmsStRetido = false;
        retorno.bloqueado.aliquotaFcpIcmsStRetido = false;
        retorno.bloqueado.valorFcpIcmsStRetido = false;
        retorno.bloqueado.valorUnitarioProdutoFcpIcmsStRetido = false;
    }
    // #endregion ICMS ST
    // #region ICMS SN
    if (!dados.calcularCreditoIcmsSn) {
        retorno.bloqueado.percentualCreditoIcmsSn = false;
        retorno.bloqueado.valorCreditoIcmsSn = false;
    }
    // #endregion ICMS SN
    if (dados.cst === '00') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.baseCalculoFcpIcms = false;
        retorno.escondido.aliquotaFcpIcms = false;
        retorno.escondido.valorFcpIcms = false;
        retorno.escondido.valorIcmsEValorFcpIcms = false;
        retorno.escondido.valorUnitarioPautaIcms = false;
        retorno.escondido.valorTotalPautaIcms = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
    }
    if (dados.cst === '10') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.valorUnitarioPautaIcmsSt = false;
        retorno.escondido.valorTotalPautaIcmsSt = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '20') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.percentualReducaoBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.valorUnitarioPautaIcms = false;
        retorno.escondido.valorTotalPautaIcms = false;
        retorno.escondido.baseCalculoFcpIcms = false;
        retorno.escondido.aliquotaFcpIcms = false;
        retorno.escondido.valorFcpIcms = false;
        retorno.escondido.valorIcmsEValorFcpIcms = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '30') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.valorUnitarioPautaIcmsSt = false;
        retorno.escondido.valorTotalPautaIcmsSt = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '40' || dados.cst === '41' || dados.cst === '50') {
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
    }
    if (dados.cst === '51') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.percentualReducaoBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.valorSemDiferimentoIcms = false;
        retorno.escondido.percentualDiferimentoIcms = false;
        retorno.escondido.valorDiferidoIcms = false;
        retorno.escondido.baseCalculoFcpIcms = false;
        retorno.escondido.aliquotaFcpIcms = false;
        retorno.escondido.valorFcpIcms = false;
        retorno.escondido.valorIcmsEValorFcpIcms = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
    }
    if (dados.cst === '60') {
        retorno.escondido.calcularIcmsEfetivo = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsEfetivo = false;
        retorno.escondido.baseCalculoIcmsEfetivo = false;
        retorno.escondido.aliquotaIcmsEfetivo = false;
        retorno.escondido.valorIcmsEfetivo = false;
        retorno.escondido.calcularIcmsSubstituto = false;
        retorno.escondido.valorUnitarioIcmsSubstituto = false;
        retorno.escondido.valorIcmsSubstituto = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.calcularIcmsStRetido = false;
        retorno.escondido.baseCalculoIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoIcmsStRetido = false;
        retorno.escondido.aliquotaIcmsStRetido = false;
        retorno.escondido.valorIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoIcmsStRetido = false;
        retorno.escondido.baseCalculoFcpIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoFcpIcmsStRetido = false;
        retorno.escondido.aliquotaFcpIcmsStRetido = false;
        retorno.escondido.valorFcpIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoFcpIcmsStRetido = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '70') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.percentualReducaoBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '90') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.percentualReducaoBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.baseCalculoFcpIcms = false;
        retorno.escondido.aliquotaFcpIcms = false;
        retorno.escondido.valorFcpIcms = false;
        retorno.escondido.valorIcmsEValorFcpIcms = false;
        retorno.escondido.calcularIcmsEfetivo = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsEfetivo = false;
        retorno.escondido.baseCalculoIcmsEfetivo = false;
        retorno.escondido.aliquotaIcmsEfetivo = false;
        retorno.escondido.valorIcmsEfetivo = false;
        retorno.escondido.calcularIcmsSubstituto = false;
        retorno.escondido.valorUnitarioIcmsSubstituto = false;
        retorno.escondido.valorIcmsSubstituto = false;
        retorno.escondido.motivoDesoneracaoIcms = false;
        retorno.escondido.valorDesoneracaoIcms = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.calcularIcmsStRetido = false;
        retorno.escondido.baseCalculoIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoIcmsStRetido = false;
        retorno.escondido.aliquotaIcmsStRetido = false;
        retorno.escondido.valorIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoIcmsStRetido = false;
        retorno.escondido.baseCalculoFcpIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoFcpIcmsStRetido = false;
        retorno.escondido.aliquotaFcpIcmsStRetido = false;
        retorno.escondido.valorFcpIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoFcpIcmsStRetido = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '101') {
        retorno.escondido.calcularCreditoIcmsSn = false;
        retorno.escondido.percentualCreditoIcmsSn = false;
        retorno.escondido.valorCreditoIcmsSn = false;
    }
    if (dados.cst === '102' ||
        dados.cst === '103' ||
        dados.cst === '300' ||
        dados.cst === '400') {
        return retorno;
    }
    if (dados.cst === '201') {
        retorno.escondido.calcularCreditoIcmsSn = false;
        retorno.escondido.percentualCreditoIcmsSn = false;
        retorno.escondido.valorCreditoIcmsSn = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '202' || dados.cst === '203') {
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '500') {
        retorno.escondido.calcularIcmsEfetivo = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsEfetivo = false;
        retorno.escondido.baseCalculoIcmsEfetivo = false;
        retorno.escondido.aliquotaIcmsEfetivo = false;
        retorno.escondido.valorIcmsEfetivo = false;
        retorno.escondido.calcularIcmsStRetido = false;
        retorno.escondido.baseCalculoIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoIcmsStRetido = false;
        retorno.escondido.aliquotaIcmsStRetido = false;
        retorno.escondido.valorIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoIcmsStRetido = false;
        retorno.escondido.baseCalculoFcpIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoFcpIcmsStRetido = false;
        retorno.escondido.aliquotaFcpIcmsStRetido = false;
        retorno.escondido.valorFcpIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoFcpIcmsStRetido = false;
        retorno.escondido.calcularIcmsSubstituto = false;
        retorno.escondido.valorUnitarioIcmsSubstituto = false;
        retorno.escondido.valorIcmsSubstituto = false;
        retorno.escondido.abaIcmsSt = false;
    }
    if (dados.cst === '900') {
        retorno.escondido.calcularIcms = false;
        retorno.escondido.modalidadeBaseCalculoIcms = false;
        retorno.escondido.percentualReducaoBaseCalculoIcms = false;
        retorno.escondido.baseCalculoIcms = false;
        retorno.escondido.aliquotaIcms = false;
        retorno.escondido.valorIcms = false;
        retorno.escondido.baseCalculoFcpIcms = false;
        retorno.escondido.aliquotaFcpIcms = false;
        retorno.escondido.valorFcpIcms = false;
        retorno.escondido.valorIcmsEValorFcpIcms = false;
        retorno.escondido.calcularCreditoIcmsSn = false;
        retorno.escondido.percentualCreditoIcmsSn = false;
        retorno.escondido.valorCreditoIcmsSn = false;
        retorno.escondido.calcularIcmsSt = false;
        retorno.escondido.modalidadeBaseCalculoIcmsSt = false;
        retorno.escondido.percentualMvaIcmsSt = false;
        retorno.escondido.percentualReducaoBaseCalculoIcmsSt = false;
        retorno.escondido.aliquotaInterestadualIcmsSt = false;
        retorno.escondido.aliquotaInternaIcmsSt = false;
        retorno.escondido.baseCalculoIcmsSt = false;
        retorno.escondido.valorIcmsSt = false;
        retorno.escondido.baseCalculoFcpIcmsSt = false;
        retorno.escondido.aliquotaFcpIcmsSt = false;
        retorno.escondido.valorFcpIcmsSt = false;
        retorno.escondido.valorIcmsStEValorFcpIcmsSt = false;
        retorno.escondido.calcularIcmsStRetido = false;
        retorno.escondido.baseCalculoIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoIcmsStRetido = false;
        retorno.escondido.aliquotaIcmsStRetido = false;
        retorno.escondido.valorIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoIcmsStRetido = false;
        retorno.escondido.baseCalculoFcpIcmsStRetido = false;
        retorno.escondido.baseCalculoUnitariaProdutoFcpIcmsStRetido = false;
        retorno.escondido.aliquotaFcpIcmsStRetido = false;
        retorno.escondido.valorFcpIcmsStRetido = false;
        retorno.escondido.valorUnitarioProdutoFcpIcmsStRetido = false;
        retorno.escondido.abaIcmsSt = false;
    }
    return retorno;
}
