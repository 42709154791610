import {
  CalcularValorTotalParcela,
  SituacaoBoletoEnum,
  SituacaoContaEnum,
  TipoLancamentoEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteBandeiraCartao from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteBandeiraCartao';
import InputAutoCompleteFormaPagamento from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import InputAutoCompletePortador from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import InputAutoCompleteRegraEscolhaIntegracaoPagamento from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteRegraEscolhaIntegracaoPagamento';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../../../Componentes/Inputs/InputDate';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import InputSwitch from '../../../../../../../../Componentes/Inputs/InputSwitch';
import RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaIntegracaoPagamento/Comunicador/RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseContaListaCategoria } from '../../../Hooks/ContaListaCategoriaHook';
import { UseContaListaParcelas } from '../Hook/ContaListaParcelasHook';
import ContaParcelaLiquidacaoHook from './Liquidacao/Hook';
import ListaDetalheContaParcelaLiquidacao from './Liquidacao/Lista';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseAntecipacaoPrevisao } from '../../../Hooks/AntecipacaoPrevisaoHook';
import RegraEscolhaPortadorObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorObterConformeCriteriosComunicador';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';

interface IProps {
  campo: string;
  index: number;
  valor: any;
}

interface IAtualizarValoresListaContaCategoria {
  valorAtual: number;
  valorAnterior: number;
  campo: string;
  conteudoEnum: string;
}

interface IContaDetalheProps {
  bloquearCamposReversao?: boolean;
}

const ParcelaDetalhe: React.FC<IContaDetalheProps> = ({
  bloquearCamposReversao = false,
}) => {
  const formPrincipal = UseForm();
  const {
    formRefLista,
    setLoading,
    getIdDetalheRegistro,
    handleExcluir,
    loading,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { permissoes } = UsePermissoes();
  const { redirecionar } = UseRedirecionar();

  const isAdiantamentoAntecipacao =
    formPrincipal.formRef.current?.getFieldValue('isAdiantamentoAntecipacao');

  const isBoletoRegistrado = formPrincipal.formRef.current
    ?.getFieldValue('listaContaParcela')
    .some((event: any) => {
      return event.boleto?.situacao === SituacaoBoletoEnum.registrado;
    });

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const formaPagamentoCartao =
    formRefLista.current?.getFieldValue('idFormaPagamento')?.descricao;

  const isCartaoCredito = formaPagamentoCartao === 'CARTÃO DE CRÉDITO';
  const isCartaoDebito = formaPagamentoCartao === 'CARTÃO DE DÉBITO';

  const { abrirJanela } = UseConfirmacao();
  const { listaValor } = UseContaListaParcelas();
  const {
    calcularValorTotalCategoria,
    validarListaContaParcelaCategoria,
    validarCategoriasAvista,
    atualizarCategoriasParcelaLiquidacao,
  } = UseContaListaCategoria();
  const { atualizaAntecipacaoPrevisao } = UseAntecipacaoPrevisao();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleAlterarDadosListaParcelas = useCallback(
    async ({ campo, valor, index }: IProps) => {
      const listaDados =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      if (listaDados && listaDados[index]) {
        listaDados[index][`${campo}`] = valor;
      }
    },
    [formPrincipal.formRef]
  );

  const handleCalcularTotalAberto = useCallback(async () => {
    const valorTotalParcela =
      formRefLista.current?.getFieldValue('valorTotalParcela');
    const valorTotalMovimentos = formRefLista.current?.getFieldValue(
      'valorTotalMovimentos'
    );

    if (valorTotalMovimentos >= 0 && valorTotalParcela >= 0) {
      const valorTotalAberto =
        Number(valorTotalParcela).Arredondar() -
        Number(valorTotalMovimentos).Arredondar();

      formRefLista.current?.setFieldValue('valorTotalAberto', valorTotalAberto);
    }
  }, [formRefLista]);

  const handleCalcularTotalConta = useCallback(async () => {
    const listaDados =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    const valorTotalDasParcelas = listaDados
      .reduce((acc: number, item: any) => {
        acc += Number(item.valorTotalParcela);
        return acc;
      }, 0)
      .Arredondar();
    formPrincipal.formRef.current?.setFieldValue(
      'valorTotal',
      valorTotalDasParcelas
    );
  }, [formPrincipal]);

  const handleCalcularTotalParcela = useCallback(async () => {
    const index = Number(getIdDetalheRegistro());
    const listaContaParcela =
      formPrincipal.formRef?.current?.getFieldValue('listaContaParcela');

    await Sleep(1);

    const {
      valorParcela,
      valorJurosSoma,
      valorDescontoSubtrai,
      valorMultaSoma,
      valorOutrasDespesasSoma,
      valorOutrasDespesasSubtrai,
      valorDespesasCartaoSubtrai,
      valorDespesasCartorioSoma,
      valorDespesasEnvioSoma,
      valorComissoesSoma,
      valorDespesasEnvioSubtrai,
      valorComissoesSubtrai,
    } = listaContaParcela[index];

    const valorTotalParcela = CalcularValorTotalParcela({
      valorParcela: Number(valorParcela),
      valorJurosSoma: Number(valorJurosSoma),
      valorDescontoSubtrai: Number(valorDescontoSubtrai),
      valorMultaSoma: Number(valorMultaSoma),
      valorOutrasDespesasSoma: Number(valorOutrasDespesasSoma),
      valorOutrasDespesasSubtrai: Number(valorOutrasDespesasSubtrai),
      valorDespesasCartaoSubtrai: Number(valorDespesasCartaoSubtrai),
      valorDespesasCartorioSoma: Number(valorDespesasCartorioSoma),
      valorDespesasEnvioSoma: Number(valorDespesasEnvioSoma),
      valorComissoesSoma: Number(valorComissoesSoma),
      valorDespesasEnvioSubtrai: Number(valorDespesasEnvioSubtrai),
      valorComissoesSubtrai: Number(valorComissoesSubtrai),
    }).Arredondar();
    formRefLista.current?.setFieldValue('valorTotalParcela', valorTotalParcela);
  }, [formPrincipal.formRef, formRefLista, getIdDetalheRegistro]);

  const handleAlterarListaContaParcelaLiquidacao = useCallback(
    async (avista: boolean) => {
      if (avista) {
        const index = Number(idDetalheRegistro);
        const novaListaContaParcelaLiquidacao: any[] = [];
        const dataHoraEmissao =
          formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        listaContaParcela[index].dataVencimento = dataHoraEmissao;
        formRefLista.current?.setFieldValue('dataVencimento', dataHoraEmissao);
        if (listaContaParcela[index].listaContaParcelaLiquidacao.length === 0) {
          const portador =
            formRefLista.current?.getFieldValueNomeObjeto('portador');
          const formaPagamento =
            formRefLista.current?.getFieldValueNomeObjeto('formaPagamento');
          const tipoAdiantamento =
            formRefLista.current?.getFieldValueNomeObjeto('tipoAdiantamento');
          const {
            valorParcela,
            valorTotalParcela,
            valorJurosSoma,
            valorDescontoSubtrai,
            valorMultaSoma,
            valorOutrasDespesasSoma,
            valorOutrasDespesasSubtrai,
            valorDespesasCartaoSubtrai,
            valorDespesasCartorioSoma,
            valorDespesasEnvioSoma,
            valorComissoesSoma,
            valorDespesasEnvioSubtrai,
            valorComissoesSubtrai,
          } = listaContaParcela[index];

          listaContaParcela[index].valorTotalMovimentos = valorTotalParcela;
          listaContaParcela[index].situacao = SituacaoContaEnum.liquidada;
          formRefLista.current?.setFieldValue(
            'situacao',
            SituacaoContaEnum.liquidada
          );
          formRefLista.current?.setFieldValue(
            'valorTotalMovimentos',
            valorTotalParcela
          );

          novaListaContaParcelaLiquidacao.push({
            data: dataHoraEmissao,
            dataContabil: dataHoraEmissao,
            valor: valorTotalParcela,
            percentualLiquidado: 100,
            formaPagamento: formaPagamento ?? null,
            portador: portador ?? null,
            tipoAdiantamento: tipoAdiantamento ?? null,
            idContaAdiantamento: tipoAdiantamento ? tipoAdiantamento.id : null,
            idFormaPagamento: formaPagamento ? formaPagamento.id : null,
            idPortador: portador ? portador.id : null,
            valorGeral: valorParcela,
            valorJurosSoma,
            valorDescontoSubtrai,
            valorMultaSoma,
            valorOutrasDespesasSoma,
            valorOutrasDespesasSubtrai,
            valorDespesasCartaoSubtrai,
            valorDespesasCartorioSoma,
            valorDespesasEnvioSoma,
            valorComissoesSoma,
            valorDespesasEnvioSubtrai,
            valorComissoesSubtrai,
            listaMovimentoPortadorCategoria: [],
          });

          formRefLista.current?.setFieldValue(
            'listaContaParcelaLiquidacao',
            novaListaContaParcelaLiquidacao
          );

          listaContaParcela[index].listaContaParcelaLiquidacao =
            novaListaContaParcelaLiquidacao;

          if (listaContaParcela) {
            formPrincipal.formRef.current?.setFieldValue(
              'listaContaParcela',
              listaContaParcela
            );
          }
        } else {
          for (
            let i = 0;
            i < listaContaParcela[index].listaContaParcelaLiquidacao.length;
            i++
          ) {
            const contaParcelaLiquidacao =
              listaContaParcela[index].listaContaParcelaLiquidacao[i];

            contaParcelaLiquidacao.data = dataHoraEmissao;
            contaParcelaLiquidacao.dataContabil = dataHoraEmissao;

            listaContaParcela[index].listaContaParcelaLiquidacao[i] =
              contaParcelaLiquidacao;
          }
        }
      }
    },
    [formPrincipal.formRef, formRefLista, idDetalheRegistro]
  );

  const handleAtualizarListaContaParcelaLiquidacaoFormaPagamento =
    useCallback(async (): Promise<void> => {
      const formaPagamento =
        formRefLista.current?.getFieldValueNomeObjeto('formaPagamento');

      if (formaPagamento) {
        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        const listaContaParcelaLiquidacao = formRefLista.current?.getFieldValue(
          'listaContaParcelaLiquidacao'
        );

        if (listaContaParcelaLiquidacao.length > 0) {
          const formaPagamentoDiferente = listaContaParcelaLiquidacao.some(
            (item: any) => {
              return item.idFormaPagamento !== formaPagamento.id;
            }
          );

          if (formaPagamentoDiferente) {
            listaContaParcelaLiquidacao.forEach((item: any) => {
              if (item.idFormaPagamento !== formaPagamento.id) {
                item.formaPagamento = formaPagamento;
                item.idFormaPagamento = formaPagamento.id;
              }
            });

            formRefLista.current?.setFieldValue(
              'listaContaParcelaLiquidacao',
              listaContaParcelaLiquidacao
            );

            listaContaParcela[idDetalheRegistro].listaContaParcelaLiquidacao =
              listaContaParcelaLiquidacao;

            formPrincipal.formRef.current?.setFieldValorInicialSemExecutarEvento(
              'listaContaParcela',
              listaContaParcela
            );
          }

          refresh();
        }
      }
    }, [formPrincipal.formRef, formRefLista, idDetalheRegistro, refresh]);

  const handleAtualizarListaContaParcelaLiquidacaoPortador =
    useCallback(async (): Promise<void> => {
      const portador =
        formRefLista.current?.getFieldValueNomeObjeto('portador');

      if (portador) {
        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        const listaContaParcelaLiquidacao = formRefLista.current?.getFieldValue(
          'listaContaParcelaLiquidacao'
        );

        if (listaContaParcelaLiquidacao.length > 0) {
          const portadorDiferente = listaContaParcelaLiquidacao.some(
            (item: any) => {
              return item.idPortador !== portador.id;
            }
          );

          if (portadorDiferente) {
            listaContaParcelaLiquidacao.forEach((item: any) => {
              if (item.idPortador !== portador.id) {
                item.portador = portador;
                item.idPortador = portador.id;
              }
            });

            formRefLista.current?.setFieldValue(
              'listaContaParcelaLiquidacao',
              listaContaParcelaLiquidacao
            );

            listaContaParcela[idDetalheRegistro].listaContaParcelaLiquidacao =
              listaContaParcelaLiquidacao;

            formPrincipal.formRef.current?.setFieldValorInicialSemExecutarEvento(
              'listaContaParcela',
              listaContaParcela
            );
          }

          refresh();
        }
      }
    }, [formPrincipal.formRef, formRefLista, idDetalheRegistro, refresh]);

  const handleCalcularPercentualParcela = useCallback(async () => {
    const valorParcela = formRefLista.current?.getFieldValue('valorParcela');
    const valorTotalConta =
      formPrincipal.formRef.current?.getFieldValue('valor');
    const percentualParcela =
      valorParcela > 0 && valorTotalConta > 0
        ? (Number(valorParcela) / Number(valorTotalConta)) * 100
        : 0;

    formRefLista.current?.setFieldValue('percentualParcela', percentualParcela);

    handleAlterarDadosListaParcelas({
      campo: 'percentualParcela',
      valor: percentualParcela,
      index: Number(getIdDetalheRegistro()),
    });
  }, [
    formPrincipal.formRef,
    formRefLista,
    getIdDetalheRegistro,
    handleAlterarDadosListaParcelas,
  ]);

  const handleAtualizarValoresListaContaCategoria = useCallback(
    async ({
      valorAtual,
      valorAnterior,
      campo,
      conteudoEnum,
    }: IAtualizarValoresListaContaCategoria) => {
      const listaContaCategoria = formPrincipal.formRef.current?.getFieldValue(
        'listaContaCategoria'
      );

      if (listaContaCategoria && listaContaCategoria.length > 0) {
        const quantidadeCategoriaIgual = listaContaCategoria.filter(
          (item: any) => {
            return item.tipoLancamento === conteudoEnum;
          }
        ).length;

        if (quantidadeCategoriaIgual === 1) {
          const listaContaParcela =
            formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

          const valorTotalCampoSelecionado = listaContaParcela
            .reduce((acc: number, item: any) => {
              acc += Number(item[`${campo}`]);
              return acc;
            }, 0)
            .Arredondar();

          let valorComparado = valorTotalCampoSelecionado - valorAtual;
          valorComparado += valorAnterior;

          listaContaCategoria.forEach((item: any) => {
            if (
              item.tipoLancamento === conteudoEnum &&
              Number(item.valor) === valorComparado
            ) {
              item.valor = valorTotalCampoSelecionado;
            }
          });

          formPrincipal.refresh();
        }

        calcularValorTotalCategoria(listaContaCategoria);
      }
    },
    [calcularValorTotalCategoria, formPrincipal]
  );

  const handleAtualizarFormaPagamentoParcelas = useCallback(async () => {
    try {
      setLoading(true);
      formPrincipal.setLoading(true);

      const index = Number(idDetalheRegistro);

      const listaContaParcela =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      const dataHoraEmissao =
        formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

      if (
        listaContaParcela &&
        listaContaParcela.length > 0 &&
        listaContaParcela[index].idFormaPagamento
      ) {
        listaContaParcela.map(async (item: any) => {
          if (!item.idFormaPagamento && !item.formaPagamento) {
            item.idFormaPagamento = listaContaParcela[index].idFormaPagamento;
            item.formaPagamento = listaContaParcela[index].formaPagamento;

            if (listaContaParcela[index].regraEscolhaIntegracaoPagamento) {
              item.idRegraEscolhaIntegracaoPagamento = null;
              item.regraEscolhaIntegracaoPagamento = null;
            }
          }
        });

        const portadorVazio = listaContaParcela.some((it: any) => {
          return !it.idPortador || !it.portador;
        });

        const response =
          await RegraEscolhaPortadorObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: dataHoraEmissao,
              idFormaPagamento: listaContaParcela[index].idFormaPagamento,
            },
          });

        if (response && response.regraEscolhaPortador) {
          let resposta = false;
          if (!portadorVazio) {
            resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Atualizar o Portador de Todas as Parcelas Conforme a
                  Regra do Portador?
                </span>
              ),
            });
          }
          listaContaParcela.map(async (item: any) => {
            if ((!item.idPortador && !item.portador) || resposta) {
              item.idPortador = response.regraEscolhaPortador.idPortador;
              item.portador = {
                id: response.regraEscolhaPortador.idPortador,
                descricao: response.regraEscolhaPortador.descricao,
                portadorBoleto: response.regraEscolhaPortador.utilizaBoleto
                  ? {
                      utilizaBoleto:
                        response.regraEscolhaPortador.utilizaBoleto,
                    }
                  : undefined,
              };
            }
          });
        }
      }

      formRefLista.current?.setDataInicial(listaContaParcela[index]);

      setLoading(false);
      formPrincipal.setLoading(false);
      refresh();
      formPrincipal.refresh();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  }, [
    abrirJanela,
    formPrincipal,
    formRefLista,
    idDetalheRegistro,
    redirecionar,
    refresh,
    setLoading,
  ]);

  const handleAtualizaPortadorParcelas = useCallback(async () => {
    const portador = formRefLista.current?.getFieldValueNomeObjeto('portador');
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    if (portador && listaContaParcela.length > 1) {
      listaContaParcela.forEach((item: any) => {
        if (!item.idPortador && !item.portador) {
          item.idPortador = portador.id;
          item.portador = portador;
        }
      });
    }
  }, [formPrincipal.formRef, formRefLista]);

  const handleAtualizarBandeiraCartaoParcelas = useCallback(async () => {
    const index = Number(idDetalheRegistro);
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    if (
      listaContaParcela &&
      listaContaParcela.length > 1 &&
      listaContaParcela[index].idBandeiraCartao
    ) {
      listaContaParcela.forEach((item: any) => {
        if (!item.idBandeiraCartao && !item.bandeiraCartao) {
          item.idBandeiraCartao = listaContaParcela[index].idBandeiraCartao;
          item.bandeiraCartao = listaContaParcela[index].bandeiraCartao;

          if (listaContaParcela[index].regraEscolhaIntegracaoPagamento) {
            item.idRegraEscolhaIntegracaoPagamento = null;
            item.regraEscolhaIntegracaoPagamento = null;
          }
        }
      });
    }
  }, [formPrincipal.formRef, idDetalheRegistro]);

  const handleAtualizarRegraEscolhaIntegracaoPagamentoParcelas =
    useCallback(async () => {
      const index = Number(idDetalheRegistro);

      const listaContaParcela =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      if (
        listaContaParcela &&
        listaContaParcela.length > 1 &&
        listaContaParcela[index].idRegraEscolhaIntegracaoPagamento
      ) {
        listaContaParcela.forEach((item: any) => {
          if (
            !item.idRegraEscolhaIntegracaoPagamento &&
            !item.regraEscolhaIntegracaoPagamento
          ) {
            item.idRegraEscolhaIntegracaoPagamento =
              listaContaParcela[index].idRegraEscolhaIntegracaoPagamento;
            item.regraEscolhaIntegracaoPagamento =
              listaContaParcela[index].regraEscolhaIntegracaoPagamento;
          }
        });
      }
    }, [formPrincipal.formRef, idDetalheRegistro]);

  const handleValidarListaCategoria = useCallback(async () => {
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');
    const listaContaCategoria = formPrincipal.formRef.current?.getFieldValue(
      'listaContaCategoria'
    );

    if (
      listaContaParcela &&
      listaContaCategoria &&
      listaContaParcela.length > 0 &&
      listaContaCategoria.length > 0
    ) {
      await validarListaContaParcelaCategoria({
        listaContaCategoria,
        listaContaParcela,
      });
    }
  }, [formPrincipal.formRef, validarListaContaParcelaCategoria]);

  const handleObterRegraEscolhaIntegradorPagamento = useCallback(async () => {
    const quantidadeParcelas =
      formPrincipal.formRef.current?.getFieldValue('quantidadeParcelas');
    const dataHoraEmissao =
      formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');
    const formaPagamento =
      formRefLista.current?.getFieldValue('idFormaPagamento');
    const idBandeiraCartao =
      formRefLista.current?.getFieldValue('idBandeiraCartao');

    try {
      const response =
        await RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador.show(
          {
            params: {
              idBandeiraCartao,
              idFormaPagamento: formaPagamento ? formaPagamento.id : null,
              quantidadeParcelas,
              dataPesquisaVigencia: new Date(dataHoraEmissao),
            },
          }
        );

      if (response && response.regraEscolhaIntegracaoPagamento) {
        const regraEscolhaIntegracaoPagamento = {
          id: response.regraEscolhaIntegracaoPagamento.id,
          regraEscolhaIntegracaoPagamentoResultado: {
            id: response.regraEscolhaIntegracaoPagamento.id,
            cnpjInstituicaoPagamento:
              response.regraEscolhaIntegracaoPagamento.cnpjInstituicaoPagamento,
          },
        };

        formRefLista.current?.setFieldValue(
          'regraEscolhaIntegracaoPagamento',
          regraEscolhaIntegracaoPagamento
        );
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [formPrincipal.formRef, formRefLista]);

  const handleAtualizaAntecipacaoPrevisao =
    useCallback(async (): Promise<void> => {
      await atualizaAntecipacaoPrevisao();
    }, [atualizaAntecipacaoPrevisao]);

  const handleAtualizarLiquidacoesComAvista = useCallback(async () => {
    const listaContaCategoria = formPrincipal.formRef.current?.getFieldValue(
      'listaContaCategoria'
    );
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    const listaContaParcelaNova = await atualizarCategoriasParcelaLiquidacao({
      listaContaCategoria,
      listaContaParcela,
    });

    await validarCategoriasAvista({
      listaContaCategoria,
      listaContaParcela: listaContaParcelaNova,
    });
    formPrincipal.formRef.current?.setFieldValue(
      'listaContaParcela',
      listaContaParcelaNova
    );

    refresh();
  }, [
    atualizarCategoriasParcelaLiquidacao,
    formPrincipal.formRef,
    refresh,
    validarCategoriasAvista,
  ]);

  return (
    <div>
      <Row>
        <InputHiddenHtml name="percentualParcela" />

        <Col lg={2} md={3} sm={3}>
          <InputLabel
            label="Parcela"
            name="sequencia"
            valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
            formatarValor={(sequencia = '') => {
              const quantidadeParcelas =
                formPrincipal.formRef.current?.getFieldValue(
                  'quantidadeParcelas'
                );

              return <h5>{`${sequencia}/${quantidadeParcelas}`}</h5>;
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputLabel
            label="Total Parcela"
            name="valorTotalParcela"
            valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorTotalParcela',
                valor: Number(event.valor),
                index: Number(getIdDetalheRegistro()),
              });

              await handleCalcularTotalAberto();
              await handleCalcularTotalConta();
            }}
            formatarValor={(valor = 0) => {
              return Number(valor).FormatarParaPtBr();
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputLabel
            label="Total Movimentos"
            name="valorTotalMovimentos"
            valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorTotalMovimentos',
                valor: Number(event.valor),
                index: Number(getIdDetalheRegistro()),
              });

              await handleCalcularTotalAberto();
            }}
            formatarValor={(valor = 0) => {
              return Number(valor).FormatarParaPtBr();
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputLabel
            label="Total em Aberto"
            name="valorTotalAberto"
            valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorTotalAberto',
                valor: Number(event.valor),
                index: Number(getIdDetalheRegistro()),
              });

              const valorTotalParcela =
                formRefLista.current?.getFieldValue('valorTotalParcela');

              if (Number(event.valor) === 0) {
                formRefLista.current?.setFieldValue(
                  'situacao',
                  SituacaoContaEnum.liquidada
                );
              } else if (Number(event.valor) === Number(valorTotalParcela)) {
                formRefLista.current?.setFieldValue(
                  'situacao',
                  SituacaoContaEnum.aberta
                );
              } else {
                formRefLista.current?.setFieldValue(
                  'situacao',
                  SituacaoContaEnum.parcialmenteLiquidada
                );
              }
            }}
            formatarValor={(valor = 0) => {
              return Number(valor).FormatarParaPtBr();
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputLabel
            name="situacao"
            label="Situação"
            valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
            formatarValor={(situacao = '') => {
              let aberta = false;
              let parcialmenteLiquidada = false;
              let liquidada = false;

              if (situacao === 'Aberta') aberta = true;
              if (situacao === 'Parcialmente Liquidada')
                parcialmenteLiquidada = true;
              if (situacao === 'Liquidada') liquidada = true;

              return (
                <div className="lista-texto">
                  {aberta && (
                    <span style={{ color: 'darkgoldenrod' }}>Aberta</span>
                  )}

                  {parcialmenteLiquidada && (
                    <span>
                      <span style={{ color: 'orange' }}>
                        Parcialmente Liquidada
                      </span>
                    </span>
                  )}

                  {liquidada && (
                    <span>
                      <span style={{ color: 'green' }}>Liquidada</span>
                    </span>
                  )}
                </div>
              );
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={2} md={4} sm={4}>
          <Input
            name="numeroDocumento"
            label="Número Documento"
            placeholder="Número Documento"
            onBlurCia={async (event) => {
              if (event.mudou)
                await handleAlterarDadosListaParcelas({
                  campo: 'numeroDocumento',
                  valor: event.valor,
                  index: Number(getIdDetalheRegistro()),
                });
            }}
            disabled={isBoletoRegistrado || bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDate
            ref={inputRefFocus}
            label="Data Vencimento"
            name="dataVencimento"
            disabled={bloquearCamposReversao}
            onBlur={async () => {
              const dataVencimento =
                formRefLista.current?.getFieldValue('dataVencimento');

              await handleAlterarDadosListaParcelas({
                campo: 'dataVencimento',
                valor: dataVencimento,
                index: Number(getIdDetalheRegistro()),
              });
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="Val. da Parcela"
            name="valorParcela"
            onChangeValue={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorParcela',
                valor: Number(event.valor),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
              await handleCalcularPercentualParcela();
              await handleAtualizaAntecipacaoPrevisao();
            }}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorParcela',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
              await handleCalcularPercentualParcela();
              await handleAtualizaAntecipacaoPrevisao();
            }}
            onBlurCia={async (_, props) => {
              if (props.mudou) {
                await handleValidarListaCategoria();
              }
            }}
            disabled={isBoletoRegistrado || bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputSwitch
            name="avista"
            label="A Vista"
            ativo="Sim"
            inativo="Não"
            onChangeValue={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'avista',
                valor: event.valor,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarListaContaParcelaLiquidacao(event.valor);
            }}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'avista',
                valor: event.target.checked,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarListaContaParcelaLiquidacao(
                event.target.checked
              );
              await handleAtualizarLiquidacoesComAvista();
              refresh();
            }}
            disabled={
              isBoletoRegistrado ||
              formPrincipal.formRef.current?.getFieldValue(
                'condicaoPagamento'
              ) ||
              bloquearCamposReversao
            }
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={6}>
          <InputAutoCompleteFormaPagamento
            label="Forma de Pagamento"
            placeholder="Forma de Pagamento"
            name="idFormaPagamento"
            nomeObjeto="formaPagamento"
            onChangeItemAtualAposCarregarSemClear={async (ev) => {
              if (!ev.itemAtual) {
                formRefLista.current?.clearField('idBandeiraCartao');
                formRefLista.current?.clearField('bandeiraCartao');
                formRefLista.current?.setFieldDisabled(
                  'idBandeiraCartao',
                  true
                );
                formRefLista.current?.setFieldDisabled('bandeiraCartao', true);
              } else {
                const descricao = ev.itemAtual.descricao
                  .RemoverAcentos()
                  .toUpperCase();
                if (
                  descricao.includes('DEBITO') ||
                  descricao.includes('CREDITO')
                ) {
                  formRefLista.current?.setFieldDisabled(
                    'idBandeiraCartao',
                    false
                  );
                  formRefLista.current?.setFieldDisabled(
                    'bandeiraCartao',
                    false
                  );
                }
              }

              const formaPagamento =
                formRefLista.current?.getFieldValueNomeObjeto('formaPagamento');

              await handleAlterarDadosListaParcelas({
                campo: 'formaPagamento',
                valor: formaPagamento,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarDadosListaParcelas({
                campo: 'idFormaPagamento',
                valor: formaPagamento ? formaPagamento.id : null,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAtualizaAntecipacaoPrevisao();
              refresh();
            }}
            onBlurInput={async () => {
              await handleAtualizarListaContaParcelaLiquidacaoFormaPagamento();
              await handleObterRegraEscolhaIntegradorPagamento();
              await handleAtualizarFormaPagamentoParcelas();
              refresh();
            }}
            disabled={isBoletoRegistrado || bloquearCamposReversao}
          />
        </Col>
        <Col lg={6} md={6} sm={6}>
          <InputAutoCompletePortador
            label="Portador"
            placeholder="Portador"
            name="idPortador"
            nomeObjeto="portador"
            permitirAdicionar={false}
            onChangeItemAtualAposCarregarSemClear={async () => {
              const portador =
                formRefLista.current?.getFieldValueNomeObjeto('portador');

              await handleAlterarDadosListaParcelas({
                campo: 'portador',
                valor: portador ?? null,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarDadosListaParcelas({
                campo: 'idPortador',
                valor: portador ? portador.id : null,
                index: Number(getIdDetalheRegistro()),
              });
              refresh();
            }}
            onBlurInput={async () => {
              await handleAtualizarListaContaParcelaLiquidacaoPortador();
              await handleAtualizaPortadorParcelas();
              refresh();
            }}
            disabled={isBoletoRegistrado || bloquearCamposReversao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={6}>
          <InputAutoCompleteBandeiraCartao
            label="Bandeira Cartão"
            placeholder="Bandeira Cartão"
            name="idBandeiraCartao"
            nomeObjeto="bandeiraCartao"
            permitirAdicionar={false}
            onChangeItemAtualAposCarregarSemClear={async () => {
              const bandeiraCartao =
                formRefLista.current?.getFieldValueNomeObjeto('bandeiraCartao');

              await handleAlterarDadosListaParcelas({
                campo: 'bandeiraCartao',
                valor: bandeiraCartao,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarDadosListaParcelas({
                campo: 'idBandeiraCartao',
                valor: bandeiraCartao ? bandeiraCartao.id : null,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAtualizarBandeiraCartaoParcelas();
              refresh();
            }}
            onBlurInput={async () => {
              await handleObterRegraEscolhaIntegradorPagamento();
              await handleAtualizarBandeiraCartaoParcelas();
              refresh();
            }}
            disabled={
              (!isCartaoCredito && !isCartaoDebito) || bloquearCamposReversao
            }
          />
        </Col>
        <Col lg={6} md={6} sm={6}>
          <InputAutoCompleteRegraEscolhaIntegracaoPagamento
            label="Integrador do Pagamento"
            placeholder="Integrador do Pagamento"
            name="idRegraEscolhaIntegracaoPagamento"
            nomeObjeto="regraEscolhaIntegracaoPagamento"
            onChangeItemAtualAposCarregarSemClear={async () => {
              const regraEscolhaIntegracaoPagamento =
                formRefLista.current?.getFieldValueNomeObjeto(
                  'regraEscolhaIntegracaoPagamento'
                );

              await handleAlterarDadosListaParcelas({
                campo: 'regraEscolhaIntegracaoPagamento',
                valor: regraEscolhaIntegracaoPagamento ?? null,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAlterarDadosListaParcelas({
                campo: 'idRegraEscolhaIntegracaoPagamento',
                valor: regraEscolhaIntegracaoPagamento
                  ? regraEscolhaIntegracaoPagamento.id
                  : null,
                index: Number(getIdDetalheRegistro()),
              });
              await handleAtualizarRegraEscolhaIntegracaoPagamentoParcelas();
            }}
            onBlurInput={async () => {
              await handleAtualizarRegraEscolhaIntegracaoPagamentoParcelas();
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Juros"
            name="valorJurosSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorJurosSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                Number(event.valor) !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: Number(event.valor),
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorJurosSoma',
                  conteudoEnum: TipoLancamentoEnum.jurosSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorJurosSoma',
                  conteudoEnum: TipoLancamentoEnum.jurosSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desconto"
            name="valorDescontoSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorDescontoSubtrai',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorDescontoSubtrai',
                  conteudoEnum: TipoLancamentoEnum.descontosSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorDescontoSubtrai',
                  conteudoEnum: TipoLancamentoEnum.descontosSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Multa"
            name="valorMultaSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorMultaSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorMultaSoma',
                  conteudoEnum: TipoLancamentoEnum.multaSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorMultaSoma',
                  conteudoEnum: TipoLancamentoEnum.multaSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Outras Despesas"
            name="valorOutrasDespesasSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorOutrasDespesasSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorOutrasDespesasSoma',
                  conteudoEnum: TipoLancamentoEnum.outrasDespesasSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorOutrasDespesasSoma',
                  conteudoEnum: TipoLancamentoEnum.outrasDespesasSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Outras Despesas"
            name="valorOutrasDespesasSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorOutrasDespesasSubtrai',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorOutrasDespesasSoma',
                  conteudoEnum: TipoLancamentoEnum.outrasDespesasSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorOutrasDespesasSubtrai',
                  conteudoEnum: TipoLancamentoEnum.outrasDespesasSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desp. de Cartão"
            name="valorDespesasCartaoSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorDespesasCartaoSubtrai',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorDespesasCartaoSubtrai',
                  conteudoEnum: TipoLancamentoEnum.despesasCartaoSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorDespesasCartaoSubtrai',
                  conteudoEnum: TipoLancamentoEnum.despesasCartaoSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Desp. de Cartório"
            name="valorDespesasCartorioSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorDespesasCartorioSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorDespesasCartorioSoma',
                  conteudoEnum: TipoLancamentoEnum.despesasCartorioSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorDespesasCartorioSoma',
                  conteudoEnum: TipoLancamentoEnum.despesasCartorioSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Desp. de Envio"
            name="valorDespesasEnvioSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorDespesasEnvioSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorDespesasEnvioSoma',
                  conteudoEnum: TipoLancamentoEnum.despesasEnvioSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorDespesasEnvioSoma',
                  conteudoEnum: TipoLancamentoEnum.despesasEnvioSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desp. de Envio"
            name="valorDespesasEnvioSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorDespesasEnvioSubtrai',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorDespesasEnvioSubtrai',
                  conteudoEnum: TipoLancamentoEnum.despesasEnvioSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorDespesasEnvioSubtrai',
                  conteudoEnum: TipoLancamentoEnum.despesasEnvioSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Comissões Pagas"
            name="valorComissoesSoma"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorComissoesSoma',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorComissoesSoma',
                  conteudoEnum: TipoLancamentoEnum.comissoesPagasSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorComissoesSoma',
                  conteudoEnum: TipoLancamentoEnum.comissoesPagasSoma,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Comissões Pagas"
            name="valorComissoesSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            onChange={async (event) => {
              await handleAlterarDadosListaParcelas({
                campo: 'valorComissoesSubtrai',
                valor: event.target.value.ConverterParaNumber(),
                index: Number(getIdDetalheRegistro()),
              });
              await handleCalcularTotalParcela();
            }}
            onChangeValue={async (event, props) => {
              if (
                event.valor !==
                props.valorAnteriorOnChange.ConverterParaNumber()
              ) {
                await handleCalcularTotalParcela();
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.valor,
                  valorAnterior:
                    props.valorAnteriorOnChange.ConverterParaNumber(),
                  campo: 'valorComissoesSubtrai',
                  conteudoEnum: TipoLancamentoEnum.comissoesPagasSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            onBlurCia={async (event, props) => {
              if (props.mudou) {
                await handleAtualizarValoresListaContaCategoria({
                  valorAtual: event.target.value.ConverterParaNumber(),
                  valorAnterior:
                    props.valorAnteriorOnBlur.ConverterParaNumber(),
                  campo: 'valorComissoesSubtrai',
                  conteudoEnum: TipoLancamentoEnum.comissoesPagasSubtrai,
                });
                await handleValidarListaCategoria();
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <p style={{ fontWeight: 'bold' }}>Ações</p>
          <BtnAcoesButton>
            <Dropdown.Item
              onClick={async () => {
                await handleExcluir(String(getIdDetalheRegistro()));
              }}
              disabled={
                !permissao?.exclui ||
                formPrincipal.loading ||
                loading ||
                listaValor.length === 1 ||
                bloquearCamposReversao
              }
            >
              <span style={{ display: 'flex', fontSize: 16 }}>
                <IoMdRemoveCircleOutline style={{ marginRight: 5 }} />
                Remover
              </span>
            </Dropdown.Item>
          </BtnAcoesButton>
        </Col>
      </Row>

      <InputHiddenHtml name="listaContaParcelaCategoria" />

      <ContaParcelaLiquidacaoHook>
        <ListaDetalheContaParcelaLiquidacao
          name="listaContaParcelaLiquidacao"
          indexParcela={Number(idDetalheRegistro)}
        />
      </ContaParcelaLiquidacaoHook>
    </div>
  );
};

export default ParcelaDetalhe;
