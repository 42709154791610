import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IGeracaoEnvioBoletosLista } from '@elogestor/util';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IGeracaoEnvioBoletosListaContext {
  validador: boolean;

  listaValor: IGeracaoEnvioBoletosLista[];
  setListaValor(lista: IGeracaoEnvioBoletosLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
}

const GeracaoEnvioBoletosListaContext =
  createContext<IGeracaoEnvioBoletosListaContext>(
    {} as IGeracaoEnvioBoletosListaContext
  );

const GeracaoEnvioBoletosListaHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaValor, setListaValor] = useState<IGeracaoEnvioBoletosLista[]>([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleSetListaValor = useCallback(
    async (lista: IGeracaoEnvioBoletosLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <GeracaoEnvioBoletosListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,

        validador: true,
      }}
    >
      {children}
    </GeracaoEnvioBoletosListaContext.Provider>
  );
};

function UseGeracaoEnvioBoletosLista(): Omit<
  IGeracaoEnvioBoletosListaContext,
  'validador'
> {
  const context = useContext(GeracaoEnvioBoletosListaContext);

  if (!context.validador) {
    throw new Error(
      'UseGeracaoEnvioBoletosLista deve ser usado com um GeracaoEnvioBoletosListaHook'
    );
  }

  return context;
}

export { GeracaoEnvioBoletosListaHook, UseGeracaoEnvioBoletosLista };
