"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SisdevinTipoOperacaoGranelfadosEnum;
(function (SisdevinTipoOperacaoGranelfadosEnum) {
    SisdevinTipoOperacaoGranelfadosEnum["venda"] = "01 - Venda";
    SisdevinTipoOperacaoGranelfadosEnum["vendaInterna"] = "02 - Venda Interna";
    SisdevinTipoOperacaoGranelfadosEnum["saidaRemessa"] = "44 - Sa\u00EDda de Remessa";
    SisdevinTipoOperacaoGranelfadosEnum["saidaDeposito"] = "04 - Sa\u00EDda Dep\u00F3sito";
    SisdevinTipoOperacaoGranelfadosEnum["saidaDevolucao"] = "12 - Sa\u00EDda Devolu\u00E7\u00E3o";
    SisdevinTipoOperacaoGranelfadosEnum["saidaDevolucaoIndustrializacao"] = "22 - Sa\u00EDda de Devolu\u00E7\u00E3o de Industrializa\u00E7\u00E3o";
    SisdevinTipoOperacaoGranelfadosEnum["saidaParaIndustrializacao"] = "17 - Sa\u00EDda Para Industrializa\u00E7\u00E3o";
    SisdevinTipoOperacaoGranelfadosEnum["saidaTransferenciaMatrizParaFilial"] = "13 - Sa\u00EDda de Transfer\u00EAncia Matriz p/ Filial";
    SisdevinTipoOperacaoGranelfadosEnum["compra"] = "05 - Compra";
    SisdevinTipoOperacaoGranelfadosEnum["entradaDeposito"] = "10 - Entrada Dep\u00F3sito";
    SisdevinTipoOperacaoGranelfadosEnum["entradaDevolucao"] = "11 - Entrada Devolu\u00E7\u00E3o";
    SisdevinTipoOperacaoGranelfadosEnum["entradaTransfer\u00EAnciaMatrizParaFilial"] = "14 - Entrada de Transfer\u00EAncia Matriz p/ Filial";
    SisdevinTipoOperacaoGranelfadosEnum["entradaParaIndustrializacao"] = "18 - Entrada Para Industrializa\u00E7\u00E3o";
    SisdevinTipoOperacaoGranelfadosEnum["entradaDevolucaoIndustrializacao"] = "23 - Entrada de Devolu\u00E7\u00E3o de Industrializa\u00E7\u00E3o";
})(SisdevinTipoOperacaoGranelfadosEnum || (SisdevinTipoOperacaoGranelfadosEnum = {}));
exports.default = SisdevinTipoOperacaoGranelfadosEnum;
