import { ILoteLista, FormatarDataParaPtBrSemTimeZone } from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../../Componentes/Lista';
import IPesquisa from '../../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import LoteComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/Lote/Comunicador/LoteComunicador';
import LoteLogComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/Lote/Log/Comunicador/LoteLogComunicador';

const LoteLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await LoteComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: ILoteLista) => {
          return {
            id: data.id,
            codigo: <div className="lista-texto">{data.codigo}</div>,
            dataValidade: (
              <div className="lista-data">
                {FormatarDataParaPtBrSemTimeZone(data.dataValidade)}
              </div>
            ),

            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await LoteComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = LoteLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    permissoes.SuprimentosProdutosEstoqueLote?.exclui
                  }
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.SuprimentosProdutosEstoqueLote]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosLote' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Lote"
          permissao={permissoes.SuprimentosProdutosEstoqueLote}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return LoteLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default LoteLista;
