import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { UseParametros } from '../../../../Hooks/ParametrosHook';
import { DefaultInput, SpanErro } from '../../Styles';
import { UseForm } from '../../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputTabelaProps {
  valorAnterior: string;
}

export interface IOnChangeValueInputTabelaEvent {
  mudou: boolean;
  valor: string;
}

export interface IOnBlurCiaInputTabelaEvent {
  mudou: boolean;
  valor: string;
}

export interface IInputTabelaRef {
  focus(): void;
}

interface IInputTabelaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valorPadrao?: string;
  valueRef?: React.RefObject<HTMLInputElement>;
  maiuscula?: boolean;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTabelaProps
  ) => void;
  onChangeValue?: (event: IOnChangeValueInputTabelaEvent) => void;
  onBlurCia?: (event: IOnBlurCiaInputTabelaEvent) => void;
  formatarValor?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  obterRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}

const InputTabela: React.ForwardRefRenderFunction<
  IInputTabelaRef,
  IInputTabelaProps
> = (
  {
    valorPadrao,
    valueRef,
    maiuscula,
    error,

    onFocus,
    onChange,
    onChangeValue,
    onBlur,
    onBlurCia,
    formatarValor,
    obterRef,
    ...rest
  },
  _ref
) => {
  const { terminouCarregarDados } = UseForm();
  const parametros = UseParametros();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [mudou, setMudou] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef<string>('');

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(inputRef);
      }
    }
  }, [obterRef, valueRef]);

  useEffect(() => {
    if (valueRef) {
      valorAnterior.current = valueRef.current?.value || '';
    } else {
      valorAnterior.current = inputRef.current?.value || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  const handleInputFocus = useCallback(
    (event: any) => {
      if (valueRef) {
        valorAnterior.current = valueRef.current?.value || '';
      } else {
        valorAnterior.current = inputRef.current?.value || '';
      }

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus, valueRef]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (formatarValor) {
        event.target.value = formatarValor(event);
      }

      setMudou(true);
      if (typeof maiuscula === 'boolean') {
        if (maiuscula) {
          const pontoInicialSelecionado = event.target.selectionStart;
          const pontoFinalSelecionado = event.target.selectionEnd;
          event.target.value = event.target.value.toUpperCase();
          event.target.selectionStart = pontoInicialSelecionado;
          event.target.selectionEnd = pontoFinalSelecionado;
        }
      } else if (parametros.CamposLetraMaiuscula) {
        const pontoInicialSelecionado = event.target.selectionStart;
        const pontoFinalSelecionado = event.target.selectionEnd;
        event.target.value = event.target.value.toUpperCase();
        event.target.selectionStart = pontoInicialSelecionado;
        event.target.selectionEnd = pontoFinalSelecionado;
      }

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
      if (onChangeValue) {
        if (valueRef) {
          onChangeValue({ mudou, valor: valueRef.current?.value || '' });
        } else {
          onChangeValue({ mudou, valor: inputRef.current?.value || '' });
        }
      }
    },
    [
      formatarValor,
      maiuscula,
      mudou,
      onChange,
      onChangeValue,
      parametros.CamposLetraMaiuscula,
      valueRef,
    ]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setMudou(false);
      setIsFocused(false);

      if (valueRef) {
        setIsFilled(!!valueRef.current?.value);
      } else {
        setIsFilled(!!inputRef.current?.value);
      }

      if (onBlur) onBlur(event);
      if (onBlurCia) {
        if (valueRef) {
          onBlurCia({ mudou, valor: valueRef.current?.value || '' });
        } else {
          onBlurCia({ mudou, valor: inputRef.current?.value || '' });
        }
      }
    },
    [mudou, onBlur, onBlurCia, valueRef]
  );

  return (
    <div>
      <DefaultInput
        style={{ textAlign: 'left', boxSizing: 'border-box', height: 25 }}
        defaultValue={valorPadrao}
        ref={valueRef || inputRef}
        type="text"
        $isErrored={!!erroInterno}
        $isFocused={isFocused}
        $isFilled={isFilled}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
    </div>
  );
};

export default forwardRef(InputTabela);
