import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-nota-fiscal-saida/curva-abc-nota-fiscal-saida-grupo-produto-com-produto-por-quantidade';

interface ICurvaABCNotaFiscalSaidaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalSaidaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalSaidaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalSaidaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador();
