import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/familia';

interface IFamiliaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFamiliaComunicadorShow {
  id: string;
}

interface IFamiliaComunicadorStore {
  params: any;
}

interface IFamiliaComunicadorUpdate {
  id: string;
  params: any;
}

interface IFamiliaComunicadorDeletar {
  id: string;
}

class FamiliaComunicador {
  public async index(params: IFamiliaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IFamiliaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IFamiliaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IFamiliaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IFamiliaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FamiliaComunicador();
