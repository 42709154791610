import React from 'react';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import { ImportacaoCteTerceiroHook } from '../Paginas/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Lista/ImportacaoCteTerceiro';
import { ImportacaoNfeTerceiroHook } from '../Paginas/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Lista/ImportacaoNfeTerceiro';

const JanelasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ImportacaoNfeTerceiroHook>
      <ImportacaoCteTerceiroHook>{children}</ImportacaoCteTerceiroHook>;
    </ImportacaoNfeTerceiroHook>
  );
};

export default JanelasHook;
