import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  position: relative;
  color: #ffff;
  margin-left: 50px;
`;

export const Image = styled.img`
  width: 120px;
  margin-bottom: 20px;
`;
