import { IPedidoCompraRetratoComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoCompraRetratoComFotoProdutoPDF from './PedidoCompraRetratoComFotoProdutoImpressaoPDF';

interface IImpressaoPedidoCompraRetratoComFotoProduto {
  handleVisualizarPdf(
    data: IPedidoCompraRetratoComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IPedidoCompraRetratoComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPedidoCompraRetratoComFotoProduto =
  (): IImpressaoPedidoCompraRetratoComFotoProduto => {
    async function handleVisualizarPdf(
      dados: IPedidoCompraRetratoComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoCompraRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IPedidoCompraRetratoComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoCompraRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoCompraRetratoComFotoProduto();
