"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ObterTipoMercado;
const Enum_1 = require("../Enum");
function ObterTipoMercado(dados) {
    if (dados.indicadorPresenca &&
        dados.indicadorPresenca === Enum_1.IndicadorPresencaPadraoEnum.operacaoPresencial) {
        return Enum_1.TipoMercadoEnum.dentroEstado;
    }
    if (!dados.ufEstadoOrigem ||
        !dados.ufEstadoDestino ||
        dados.ufEstadoOrigem.IsNullOrEmpty() ||
        dados.ufEstadoDestino.IsNullOrEmpty()) {
        return Enum_1.TipoMercadoEnum.dentroEstado;
    }
    if ((dados.ufEstadoOrigem !== 'EX' && dados.ufEstadoDestino === 'EX') ||
        (dados.ufEstadoOrigem === 'EX' && dados.ufEstadoDestino !== 'EX')) {
        return Enum_1.TipoMercadoEnum.exterior;
    }
    if (dados.ufEstadoOrigem !== dados.ufEstadoDestino) {
        return Enum_1.TipoMercadoEnum.foraEstado;
    }
    return Enum_1.TipoMercadoEnum.dentroEstado;
}
