import ILogPesquisaParametro from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/marca-veiculo/log';

interface IMarcaVeiculoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IMarcaVeiculoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class MarcaVeiculoLogComunicador {
  public async index(
    params: IMarcaVeiculoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IMarcaVeiculoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new MarcaVeiculoLogComunicador();
