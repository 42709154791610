import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin: 50px -10px 15px -10px;
  //overflow: auto;

  table {
    .nome {
      width: 100%;
    }

    th > div {
      display: flex;
    }
    td {
      vertical-align: middle;
    }
    tr {
      cursor: pointer;
    }
    td:last-child {
      button {
        margin: 0 5px;
      }
    }
    td.tdButton {
      padding: 8px;
      pointer-events: none;
      /* width: 70px; */
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      a,
      button {
        pointer-events: all;
      }
    }

    .true,
    .false {
      font-weight: 600;
    }

    .true {
      color: #218838;
    }
    .false {
      color: #ff0000;
    }
  }
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;
