import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-por-cnpj-cpf';

interface IPessoaObterPorCnpjCpfComunicadorIndex {
  params: {
    cnpjCpf: string;
    listaIdRemover: string[];
  };
}
class PessoaObterPorCnpjCpfComunicador {
  public async index(
    params: IPessoaObterPorCnpjCpfComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaObterPorCnpjCpfComunicador();
