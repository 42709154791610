import api from '../../../../Comum/Services/Api';

const rota = 'fiscal/relatorios/diario-auxiliar/clientes-por-documento';

interface IDiarioAuxiliarRelatorioComunicadorIndex {
  params: any;
}

class DiarioAuxiliarComunicador {
  public async index(
    params: IDiarioAuxiliarRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new DiarioAuxiliarComunicador();
