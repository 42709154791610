import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/orcamento/impressao/paisagem-com-financeiro-previsao-entrega-representante';

interface IOrcamentopaisagemComFinanceiroPrevisaoEntregaRepShow {
  id: string;
}

class OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepComunicador {
  public async show(
    params: IOrcamentopaisagemComFinanceiroPrevisaoEntregaRepShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepComunicador();
