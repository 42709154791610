import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import { ConverterMilimitroParaPonto } from '@elogestor/util';

const Document: any = _document;
const Page: any = _page;

interface INotaFiscalImpressaoComprovanteTefPDF {
  dados: any;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: 8,
  },
});

const NotaFiscalImpressaoComprovanteTefPDF: React.FC<
  INotaFiscalImpressaoComprovanteTefPDF
> = ({ dados }) => {
  const dadosSemSpaco: string[] = dados
    .split('\r\n')
    .map((it: any) => String(it).RemoverExcessoEspacos());

  let alturaMilimitro = 170;
  if (dadosSemSpaco.length > 38) {
    alturaMilimitro += (dadosSemSpaco.length - 38) * 2.4;
  }
  const alturaTotal = ConverterMilimitroParaPonto(alturaMilimitro);

  const size = {
    width: ConverterMilimitroParaPonto(80),
    height: alturaTotal,
  };

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait" size={size}>
        <View>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1pt dotted gray',
            }}
          >
            <Text style={{ fontSize: 8 }}>{dados}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default NotaFiscalImpressaoComprovanteTefPDF;
