import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 10px;

  tr.selecionado {
    background-color: #b2f3d3 !important;
  }

  td {
    cursor: pointer;
    white-space: nowrap;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        flex: 1;
      }
    }
  }

  td.selecionado {
    background-color: #b2f3d3 !important;
  }

  input[type='checkbox'] {
    appearance: none;
    height: 14px;
    width: 14px;
    background: #cbd1d8;
    color: #fff;
    border: none;
    margin: 0;
  }
  input[type='checkbox']:hover {
    background: #9faab7;
  }
  input[type='checkbox']:checked {
    background: #3397c4;
  }
  input[type='checkbox']:checked::before {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    content: '✔';
    font-size: 10px;
  }
  input[type='checkbox']:checked::after {
    content: '';
    display: flex;
  }
  input[type='checkbox'] {
    border-radius: 50%;
  }
  input[type='checkbox']::after {
    border-radius: 50%;
  }
`;

export const Tr = styled.tr`
  &.selecionado {
    background: ${darken(0.1, 'rgb(208,228,255)')} !important;
  }
`;
