import React, { createContext, useCallback, useContext } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ProdutoObterUnidadeMedidaComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterUnidadeMedidaComunicador';

interface IProdutoUnidadeMedidaContext {
  validador: boolean;
  obterProdutoUnidadeMedida(): void;
  obterEnologicoUnidadeMedida(): void;
}

const ProdutoUnidadeMedidaContext = createContext(
  {} as IProdutoUnidadeMedidaContext
);

const UnidadeMedidaHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  const handleObterProdutoUnidadeMedida = useCallback(async () => {
    const produto = formRef.current?.getFieldValueNomeObjeto('produto');
    formRef.current?.setFieldError('produtoEstoque.idUnidadeMedidaEstoque', '');
    formRef.current?.clearField('produtoEstoque.unidadeMedida');

    if (!produto) return;

    const unidadeMedida = await ProdutoObterUnidadeMedidaComunicador.show({
      id: produto.id,
    });

    if (unidadeMedida)
      formRef.current?.setFieldValue(
        'produtoEstoque.unidadeMedida',
        unidadeMedida
      );
  }, [formRef]);

  const handleObterEnologicoUnidadeMedida = useCallback(async () => {
    const produtoEnologico = formRef.current?.getFieldValueNomeObjeto(
      'produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico'
    );
    formRef.current?.clearField(
      'produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico.siglaUnidadeMedida'
    );
    formRef.current?.setFieldError(
      'produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico.siglaUnidadeMedida',
      ''
    );

    formRef.current?.setFieldValue(
      'produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico.siglaUnidadeMedida',
      produtoEnologico?.siglaUnidadeMedida
    );
  }, [formRef]);

  return (
    <ProdutoUnidadeMedidaContext.Provider
      value={{
        validador: true,
        obterProdutoUnidadeMedida: handleObterProdutoUnidadeMedida,
        obterEnologicoUnidadeMedida: handleObterEnologicoUnidadeMedida,
      }}
    >
      {children}
    </ProdutoUnidadeMedidaContext.Provider>
  );
};

function UseProdutoUnidadeMedida(): Omit<
  IProdutoUnidadeMedidaContext,
  'validador'
> {
  const context = useContext(ProdutoUnidadeMedidaContext);

  if (!context.validador) {
    throw new Error(
      'UseProdutoUnidadeMedida deve ser usado com um UnidadeMedidaHook'
    );
  }

  return context;
}

export { UseProdutoUnidadeMedida, UnidadeMedidaHook };
