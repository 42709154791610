import React from 'react';
import { RotasProvider } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '/transporte/veiculo',
        detalhes: '/transporte/veiculo/detalhe',
        listagem: '/transporte/veiculo',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
