import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/transmitir';

interface IConhecimentoTransporteTransmitirComunicador {
  id: string;
}

class ConhecimentoTransporteTransmitirComunicador {
  public async update(
    params: IConhecimentoTransporteTransmitirComunicador
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new ConhecimentoTransporteTransmitirComunicador();
