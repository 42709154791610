import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import NotaFiscalEntradaComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaComunicador';
import { NotaFiscalSaidaImportacaoNotaFiscalEntradaHook } from '../../../../../Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntradaHook';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { NotaFiscalEntradaImportacaoPedidoCompraHook } from '../../../NotaFiscalEntradaImportacaoPedidoCompraHook';
import { NotaFiscalEntradaImportacaoNotaFiscalSaidaHook } from '../../../NotaFiscalEntradaImportacaoNotaFiscalSaidaHook';
import { NotaFiscalEntradaDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { NotaFiscalEntradaHook } from './NotaFiscalEntradaHook';
import RotasHook from './RotasHook';
import { SituacaoBloquearCamposHook } from './SituacaoBloquearCamposHook';
import SubHeaderHook from './SubHeaderHook';

const NotaFiscalEntradaHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: NotaFiscalEntradaComunicador }}>
      <ButtonsSubHeaderProvider>
        <RotasHook>
          <RegimeTributarioHook>
            <NotaFiscalEntradaDuplicarHook>
              <NotaFiscalEntradaImportacaoPedidoCompraHook>
                <NotaFiscalSaidaImportacaoNotaFiscalEntradaHook>
                  <NotaFiscalEntradaImportacaoNotaFiscalSaidaHook>
                    <FormHook>
                      <SituacaoBloquearCamposHook>
                        <SubHeaderHook>
                          <NotaFiscalEntradaHook>
                            {children}
                          </NotaFiscalEntradaHook>
                        </SubHeaderHook>
                      </SituacaoBloquearCamposHook>
                    </FormHook>
                  </NotaFiscalEntradaImportacaoNotaFiscalSaidaHook>
                </NotaFiscalSaidaImportacaoNotaFiscalEntradaHook>
              </NotaFiscalEntradaImportacaoPedidoCompraHook>
            </NotaFiscalEntradaDuplicarHook>
          </RegimeTributarioHook>
        </RotasHook>
      </ButtonsSubHeaderProvider>
    </ComunicadorProvider>
  );
};

export default NotaFiscalEntradaHooks;
