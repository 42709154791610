import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/sisdevin-estoque-movimento/atualizar-codigo-rastreabilidade';

interface ISisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeUpdate {
  id: string;
  params: any;
}

class SisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeComunicador {
  public async update(
    params: ISisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new SisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeComunicador();
