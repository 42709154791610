import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/item';
const rotaNovo = 'comercial/vendas/orcamento';

interface IOrcamentoItemComunicadorShow {
  id: string;
}

interface IOrcamentoItemComunicadorStore {
  idPai: string;
  params: any;
}

interface IOrcamentoItemComunicadorUpdate {
  id: string;
  params: any;
}

interface IOrcamentoItemComunicadorDelete {
  id: string;
}

class OrcamentoItemComunicador {
  public async show(params: IOrcamentoItemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IOrcamentoItemComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(params: IOrcamentoItemComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IOrcamentoItemComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoItemComunicador();
