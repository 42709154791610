/* eslint-disable no-restricted-syntax */
import {
  FormatarDataHoraParaPtBr,
  IEstoqueFiltroRelatorioBackend,
  IEstoqueFiltroRelatorioFrontend,
  IEstoqueExtratoDeMovimentosComCustoCabecalhoRelatorio,
  IEstoqueExtratoDeMovimentosComCustoRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoCustoEnum,
  TipoOrdenacaoEnum,
} from '@elogestor/util';
import React from 'react';
import ExtratoDeMovimentosComCustoRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/Estoques/ExtratoDeMovimentosComCustoRelatorio/ExtratoDeMovimentosComCustoRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ExtratoDeMovimentosComCustoPDF from './ExtratoDeMovimentosComCustoPDF';
import ExtratoDeMovimentosComCustoPlanilha from './ExtratoDeMovimentosComCustoPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ExtratoDeMovimentosComCusto = (): IRelatorios => {
  function ValidarFiltrosObrigatorios(filtroBack: any): boolean {
    // Criado essa função nos relatórios de estoque que sejam de extrato, para diminuir a quantidade de dados (Atividade - 2466 )
    let possuiFiltrosObrigatorios = false;
    for (const chave in filtroBack) {
      if (
        ![
          'data',
          'somenteProdutosEstoqueMinimo',
          'somenteProdutosEstoqueMaximo',
          'tipoBuscaCodigoDescricao',
          'tipoCusto',
          'listaRegimeTributario',
          'tipoOrdenacao',
        ].includes(chave) &&
        filtroBack[chave] &&
        filtroBack[chave].length > 0
      ) {
        possuiFiltrosObrigatorios = true;
      }
    }

    return possuiFiltrosObrigatorios;
  }

  const ObterRelatorio = async (
    filtros: IEstoqueFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IEstoqueExtratoDeMovimentosComCustoRelatorio | null> => {
    const filtroBack: IEstoqueFiltroRelatorioBackend = {
      data: filtros.data,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
      produtoAtivo: filtros?.produtoAtivo,

      regimeTributario: filtros?.regimeTributario,
      somenteProdutosComSaldo: filtros?.somenteProdutosComSaldo,
      reservaComprometimento: filtros?.reservaComprometimento,
      somenteProdutosEstoqueMinimo: filtros?.somenteProdutosEstoqueMinimo,
      somenteProdutosEstoqueMaximo: filtros?.somenteProdutosEstoqueMaximo,
    };

    if (filtros?.listaMarcaProduto) {
      filtroBack.listaIdMarcaProduto =
        filtros.listaMarcaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoCusto) {
      filtroBack.tipoCusto = StringConverterParaEnum<
        typeof TipoCustoEnum,
        TipoCustoEnum
      >(TipoCustoEnum, String(filtros.tipoCusto));
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.reservaComprometimento) {
      filtroBack.reservaComprometimento = filtros.reservaComprometimento;
    }

    if (filtros?.listaPessoa) {
      filtroBack.listaIdPessoa =
        filtros.listaPessoa.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaLote) {
      filtroBack.listaIdLote =
        filtros.listaLote.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaGrupoProdutoServico) {
      filtroBack.listaIdGrupoProdutoServico =
        filtros.listaGrupoProdutoServico.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaLocalEstoque) {
      filtroBack.listaIdLocalEstoque =
        filtros.listaLocalEstoque.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoOrdenacao) {
      filtroBack.tipoOrdenacao = StringConverterParaEnum<
        typeof TipoOrdenacaoEnum,
        TipoOrdenacaoEnum
      >(TipoOrdenacaoEnum, String(filtros.tipoOrdenacao));
    }

    if (!ValidarFiltrosObrigatorios(filtroBack)) {
      ToastInfo(
        'Informe pelo menos um filtro a mais dos Selecionados para Permitir gerar o relatório!'
      );
      return null;
    }

    const response =
      await ExtratoDeMovimentosComCustoRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IEstoqueExtratoDeMovimentosComCustoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const listaMovimentos = dados.listaMovimentos.map((movimentos) => {
          return {
            ...movimentos,
            quantidade: Number(movimentos.quantidade).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            saldo: Number(movimentos.saldo).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            valorUnitarioCustoMovimento: Number(
              movimentos.valorUnitarioCustoMovimento
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            valorTotalCustoMovimento: Number(
              movimentos.valorTotalCustoMovimento
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            valorUnitarioCusto: Number(
              movimentos.valorUnitarioCusto
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            valorTotalCusto: Number(
              movimentos.valorTotalCusto
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
          };
        });

        return {
          ...dados,
          listaMovimentos,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IEstoqueFiltroRelatorioFrontend,
    cabecalho: IEstoqueExtratoDeMovimentosComCustoCabecalhoRelatorio
  ): IEstoqueExtratoDeMovimentosComCustoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.data) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroData = FormatarDataHoraParaPtBr(filtros.data);
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }

      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (filtros.listaPessoa && filtros.listaPessoa.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPessoa = filtros.listaPessoa.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (
      filtros.listaGrupoProdutoServico &&
      filtros.listaGrupoProdutoServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGrupoProdutoServico.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.tipoCusto) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTipoCusto = filtros.tipoCusto;
    }

    if (filtros.tipoOrdenacao) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTipoOrdenacao = filtros.tipoOrdenacao;
    }

    if (
      filtros.somenteProdutosComSaldo !== undefined &&
      filtros.somenteProdutosComSaldo !== null
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteProdutoComSaldo = Boolean(
        filtros.somenteProdutosComSaldo
      );
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: ExtratoDeMovimentosComCustoPlanilha,
      nomeArquivo: 'ExtratoDeMovimentosComCusto',
      valores: dados.listaDados,
      unwind: ['listaMovimentos'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: ExtratoDeMovimentosComCustoPlanilha,
      nomeArquivo: 'ExtratoDeMovimentosComCusto',
      valores: dados.listaDados as any,
      unwind: ['listaMovimentos'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ExtratoDeMovimentosComCustoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ExtratoDeMovimentosComCustoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ExtratoDeMovimentosComCusto();
