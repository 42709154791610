import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/engenharia/operacoes-roteiros-fabricacao-auto-complete-tag';

interface IOperacaoRoteiroFabricacaoAutoCompleteTagComunicadorIndex {
  params: any;
}

class OperacaoRoteiroFabricacaoAutoCompleteTagComunicador {
  public async index(
    params: IOperacaoRoteiroFabricacaoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OperacaoRoteiroFabricacaoAutoCompleteTagComunicador();
