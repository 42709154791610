import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IMovimentacoesFinanceirasResumoDiarioPrevistoRealizadoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IResumoDiariaPrevistoRealizadoRelatorioPDF {
  dados: IMovimentacoesFinanceirasResumoDiarioPrevistoRealizadoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ResumoDiariaPrevistoRealizadoPDF: React.FC<
  IResumoDiariaPrevistoRealizadoRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: styles.data.fontSize,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',

                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataHoraEmissaoInicial &&
                  dados.cabecalho.filtroDataHoraEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataHoraEmissaoInicial} até ${dados.cabecalho.filtroDataHoraEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo de Data de Liquidação: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPortadores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Portadores: '}
                      </Text>
                      {dados.cabecalho.filtroPortadores}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTiposLancamento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipos de Lançamento: '}
                      </Text>
                      {dados.cabecalho.filtroTiposLancamento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSaldoInicial && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Saldo Inicial: '}
                      </Text>
                      {Number(
                        dados.cabecalho.filtroSaldoInicial
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: styles.data.fontSize }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View
          style={{ fontSize: styles.data.fontSize, width: '100%', flex: 1 }}
        >
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '8%' }}>
              <Text>Data</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Receber Previsto</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Receber Realizado</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Pagar Previsto</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Pagar Realizado</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text style={{ textAlign: 'right' }}>Adiantamento Recebido</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text style={{ textAlign: 'right' }}>Adiantamento Pago</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Transf. Recebida</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Transf. Paga</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Saldo Previsto</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Saldo Realizado</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado: any, dadoIndex: number) => {
                return (
                  <View key={dado.data}>
                    <View
                      style={{
                        display: 'flex',
                        marginTop: '2pt',
                        paddingTop: '2pt',
                        flexDirection: 'row',
                        fontSize: styles.data.fontSize,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor:
                            dadoIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                        }}
                      >
                        <View
                          style={{
                            width: '8%',
                          }}
                        >
                          <Text>{dado.data}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.receberPrevisto}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.receberRealizado}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.pagarPrevisto}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.pagarRealizado}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.adiantamentoRecebido}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.adiantamentoPago}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.transferenciaRecebida}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.transferenciaPaga}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.saldoPrevisto}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '8%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text>{dado.saldoRealizado}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
              <View>
                <View
                  style={{
                    display: 'flex',
                    marginTop: '2pt ',
                    paddingTop: '2pt',
                    borderTop: '1pt solid black',
                    flexDirection: 'row',
                    fontSize: styles.data.fontSize,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>Total</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralReceberPrevisto}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralReceberRealizado}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralPagarPrevisto}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralPagarRealizado}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralAdiantamentoRecebido}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralAdiantamentoPago}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralTransferenciaRecebida}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralTransferenciaPaga}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralSaldoPrevisto}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '8%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {dados.somatorioGeralSaldoRealizado}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: styles.data.fontSize,
              }}
            />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 8,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ResumoDiariaPrevistoRealizadoPDF;
