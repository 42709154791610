import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ContasParcelaReversaoListaHook } from './ContasParcelaReversaoListaHook';

const GeracaoEnvioBoletosHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ContasParcelaReversaoListaHook>{children}</ContasParcelaReversaoListaHook>
  );
};

export default GeracaoEnvioBoletosHook;
