"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line no-shadow
var PermiteLancarContaSemPortadorEnum;
(function (PermiteLancarContaSemPortadorEnum) {
    PermiteLancarContaSemPortadorEnum["permitir"] = "Permitir";
    PermiteLancarContaSemPortadorEnum["permitirGerarAviso"] = "Permitir, mas Gerar Aviso";
    PermiteLancarContaSemPortadorEnum["naoPermitir"] = "N\u00E3o Permitir";
})(PermiteLancarContaSemPortadorEnum || (PermiteLancarContaSemPortadorEnum = {}));
exports.default = PermiteLancarContaSemPortadorEnum;
