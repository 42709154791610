"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsSubstitutoCalculo;
function IcmsSubstitutoCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { valorSubstitutoItem: null });
    if (dados.valorUnitarioSubstitutoItem != null &&
        dados.quantidadeItem != null) {
        retorno.valorSubstitutoItem =
            Number(dados.quantidadeItem) * Number(dados.valorUnitarioSubstitutoItem);
    }
    return retorno;
}
