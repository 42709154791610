import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const RegistrosTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Calcular Base de Cálculo do IPI por unidade"
                name="arquivoCompletoCalcularBaseCalculoIpiPorUnidade"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Incluir Itens de Serviço (D110) para Conhecimentos de Transporte"
                name="arquivoCompletoConhecimentosTransporteIncluirItensServico"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RegistrosTab;
