"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoContatoEnum;
(function (TipoContatoEnum) {
    TipoContatoEnum["boletos"] = "Boletos";
    TipoContatoEnum["documentosFiscais"] = "Documentos Fiscais";
    TipoContatoEnum["orcamento"] = "Or\u00E7amento";
    TipoContatoEnum["pedidoVenda"] = "Pedido de Venda";
    TipoContatoEnum["cotacaoCompra"] = "Cota\u00E7\u00E3o de Compra";
    TipoContatoEnum["pedidoCompra"] = "Pedido de Compra";
    TipoContatoEnum["ordemServico"] = "Pedido de Compra";
})(TipoContatoEnum || (TipoContatoEnum = {}));
exports.default = TipoContatoEnum;
