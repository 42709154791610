import {
  FormatarEnum,
  IPesagemValoresAlterar,
  SituacaoPesagemEnum,
  TipoPesagemEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import Select from '../../../../../../Componentes/Select';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import GeralTab from '../GeralTab';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import AvaliacaoTab from '../AvaliacaoTab';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputAutoCompleteEstado from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';

type IProps = IMain<IPesagemValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosPesagensPesagem: permissao } = permissoes;
  const liberacoes = UseLiberacoes();
  const navigate = useNavigate();
  const rotas = UseRota();

  const [tabSelecionada, setTabSelecionada] = useState('geral');

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();
  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data: any = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleChangeCalcular = useCallback(async () => {
    const pesoBruto = formRef.current?.getFieldValue('pesoBruto');

    const pesoTara = formRef.current?.getFieldValue('pesoTara');

    // Limpa o erro se estiver correto.
    if (pesoTara < pesoBruto) {
      formRef.current?.setFieldError('pesoLiquido', '');

      formRef.current?.setFieldError('pesoTara', '');
    }

    if (pesoBruto === null || pesoTara === null) {
      formRef.current?.setFieldValue('pesoLiquido', null);

      return;
    }

    if (pesoTara > pesoBruto) {
      ToastErro('Peso líquido deve ser Positivo!');

      ToastErro('Tara deve ser menor que Peso Bruto!');

      formRef.current?.setFieldError(
        'pesoTara',
        'Tara deve ser menor que Peso Bruto'
      );

      formRef.current?.setFieldError(
        'pesoLiquido',
        'Peso Líquido deve ser Positivo'
      );

      return;
    }
    const pesoLiquido = pesoBruto - pesoTara;

    formRef.current?.setFieldValue('pesoLiquido', pesoLiquido);
  }, [formRef]);

  const handleChangeCalcularPesoLiquido = useCallback(async () => {
    const pesoLiquido = formRef.current?.getFieldValue('pesoLiquido');
    const listaPesagemPessoa =
      formRef.current?.getFieldValue('listaPesagemPessoa');

    const listaPesagemLocalEstoque = formRef.current?.getFieldValue(
      'listaPesagemLocalEstoque'
    );

    for (let index = 0; index < listaPesagemPessoa.length; index++) {
      const valor = listaPesagemPessoa[index];
      if (listaPesagemPessoa.length > 0) {
        valor.pesoLiquido = (valor.percentual / 100) * pesoLiquido;
      }
    }

    if (listaPesagemLocalEstoque.length === 1) {
      listaPesagemLocalEstoque[0].pesoLiquido = pesoLiquido;
    }
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col xl={2} lg={2} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col xl={4} lg={4} md={6} sm={12}>
              <InputDateTime label="Data Hora Emissão" name="dataHoraEmissao" />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                options={FormatarEnum({
                  enumObj: TipoPesagemEnum,
                })}
                onChange={() => {
                  refresh();
                }}
              />
            </Col>
            <Col xl={2} lg={2} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let naoUtilizada = false;
                  let parcialmenteUtilizada = false;
                  let utilizada = false;
                  let cancelada = false;

                  if (situacao.includes('Não Utilizada')) naoUtilizada = true;
                  if (situacao === SituacaoPesagemEnum.parcialmenteUtilizada)
                    parcialmenteUtilizada = true;
                  if (situacao === SituacaoPesagemEnum.utilizada)
                    utilizada = true;
                  if (situacao === SituacaoPesagemEnum.cancelada)
                    cancelada = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      {naoUtilizada && (
                        <span style={{ color: 'black' }}>Não Utilizada</span>
                      )}
                      {parcialmenteUtilizada && (
                        <span>
                          <span style={{ color: 'darkgoldenrod' }}>
                            Parcialmente Utilizada
                          </span>
                        </span>
                      )}
                      {utilizada && (
                        <span style={{ color: 'green' }}>Utilizada</span>
                      )}
                      {cancelada && (
                        <span style={{ color: 'red' }}>Cancelado</span>
                      )}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                name="idProduto"
                label="Produto"
                placeholder="Produto"
                nomeObjeto="produto"
              />
            </Col>
            <Col>
              <Input
                label="Placa"
                name="placa"
                placeholder="Placa"
                maxLength={7}
                maiuscula
                formatarValor={(event) => {
                  return event.target.value.replace(/[^a-zA-Z0-9]/gi, '');
                }}
              />
            </Col>
            <Col>
              <InputAutoCompleteEstado
                name="idEstadoPlaca"
                label="UF"
                placeholder="UF"
                nomeObjeto="estadoPlaca"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Peso Bruto (KG)"
                name="pesoBruto"
                placeholder="Peso Bruto (KG)"
                casasDecimais={3}
                casasInteiras={15}
                onBlur={handleChangeCalcular}
                disabled={
                  !liberacoes.permiteDigitarManualmentePesosNoCadastroDePesagem
                }
              />
            </Col>
            <Col xl={2} lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Tara (KG)"
                name="pesoTara"
                placeholder="Tara (KG)"
                casasDecimais={3}
                casasInteiras={15}
                onBlur={handleChangeCalcular}
                disabled={
                  !liberacoes.permiteDigitarManualmentePesosNoCadastroDePesagem
                }
              />
            </Col>
            <Col xl={2} lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Peso Líquido (KG)"
                name="pesoLiquido"
                placeholder="Peso Líquido (KG)"
                disabled
                casasDecimais={3}
                casasInteiras={15}
                onChangeValue={() => {
                  refresh();
                  handleChangeCalcularPesoLiquido();
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <InputHiddenHtml name="listaPessoaMudou" />
        <InputHiddenHtml name="listaEstoqueMudou" />

        <Tabs
          id={v4()}
          defaultActiveKey="geral"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="geral" title="Geral">
            <GeralTab />
          </Tab>

          <Tab eventKey="avaliacao" title="Avaliação">
            <AvaliacaoTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
