"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoAutorizacaoEnum;
(function (SituacaoAutorizacaoEnum) {
    SituacaoAutorizacaoEnum["naoConsultada"] = "N\u00E3o Consultada";
    SituacaoAutorizacaoEnum["indisponivel"] = "Indispon\u00EDvel";
    SituacaoAutorizacaoEnum["autorizado"] = "Autorizado";
    SituacaoAutorizacaoEnum["denegado"] = "Denegado";
    SituacaoAutorizacaoEnum["cancelado"] = "Cancelado";
})(SituacaoAutorizacaoEnum || (SituacaoAutorizacaoEnum = {}));
exports.default = SituacaoAutorizacaoEnum;
