import React from 'react';
import ListaDetalheCteComplementado from './ListaDetalheCteComplementado/Lista';
import Divisor from '../../../../../../Componentes/Divisor';
import ConhecimentoTransporteTerceiroCteComplementadoHook from './ListaDetalheCteComplementado/Hooks';

interface ICteComplementadoTab {
  bloquearCamposPorImportacao: boolean;
}

const CteComplementadoTab: React.FC<ICteComplementadoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <Divisor>
      <ConhecimentoTransporteTerceiroCteComplementadoHook>
        <ListaDetalheCteComplementado
          name="listaConhecimentoTransporteTerceiroCteComplementado"
          bloquearCamposPorImportacao={bloquearCamposPorImportacao}
        />
      </ConhecimentoTransporteTerceiroCteComplementadoHook>
    </Divisor>
  );
};

export default CteComplementadoTab;
