import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro, UnidadeMedidaCargaEnum } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import { UseAutoComplete } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import UseFuncoesPadrao from '../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ConhecimentoTransporteCargaComunicador from '../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Carga/Comunicador/ConhecimentoTransporteCargaComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheCarga"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          unidadeMedidaCarga: Yup.mixed()
            .oneOf(
              Object.values(UnidadeMedidaCargaEnum),
              'Unidade de Medida é obrigatório!'
            )
            .required('Unidade de Medida é obrigatório!'),
          tipoMedida: Yup.string()
            .max(20, 'Tipo de Medida deve ter no máximo 20 caracteres!')
            .required('Tipo de Medida é obrigatório!'),
          quantidade: Yup.number()
            .nullable()
            .required('Quantidade é obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        let id = getIdDetalheRegistro() || '';
        const idConhecimentoTransporte =
          formPrincipal.getIdDetalheRegistro() || '';

        const params = {
          ...data,
          idConhecimentoTransporte,
        };

        const response = await ConhecimentoTransporteCargaComunicador.store({
          params,
        });

        setIdDetalheRegistro(response.id);
        id = response.id;

        formPrincipal.handleCarregarDados();
        await formRef?.reset();
        ToastSucesso('Carga Salva!');
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleSalvarContinuar,
      handleValidar,
      isJanelaAutoComplete,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return;
        }

        await ConhecimentoTransporteCargaComunicador.delete({ id });

        ToastSucesso('Carga Deletada!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
