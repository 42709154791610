import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  RegraEscolhaTipoEnum,
  IRegraEscolhaAliquotaValoresAlterar,
  RegimeTributarioEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import RegraEscolhaAliquotaComunicador from '../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IRegraEscolhaAliquotaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.listaRegraEscolhaAliquotaEmpresa[0].ativo = true;
        dadosDuplicados.tipo = RegraEscolhaTipoEnum.empresa;

        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await RegraEscolhaAliquotaComunicador.show({
          id: idEditar,
        });
        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          listaRegraEscolhaAliquotaEmpresa: [{ ativo: true }],
          tipo: RegraEscolhaTipoEnum.empresa,
          quantidadeCriterios: 0,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const listaCriteriosVaziaSemModeloDocumento =
          data.listaRegraEscolhaAliquotaDestinado.listaValor.length === 0 &&
          data.listaRegraEscolhaAliquotaNcm.listaValor.length === 0 &&
          data.listaRegraEscolhaAliquotaCest.listaValor.length === 0 &&
          data.listaRegraEscolhaAliquotaProduto.listaValor.length === 0;

        const listaModeloDocumentoSomenteItemCTe =
          data.listaRegraEscolhaAliquotaModeloDocumento.listaValor.length ===
            1 &&
          data.listaRegraEscolhaAliquotaModeloDocumento.listaValor[0]
            ?.tipoDocumentoFiscal ===
            ModeloDocumentoTipoEnum.conhecimentoTransporte;

        const schema = Yup.object().shape({
          tipo: Yup.string().nullable().required('Tipo é obrigatório!'),

          listaRegraEscolhaAliquotaDestinado: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe pelo menos um dos critérios: Destinado, NCM, CEST ou Produto!',
              test: () => {
                if (
                  listaCriteriosVaziaSemModeloDocumento &&
                  listaModeloDocumentoSomenteItemCTe
                )
                  return true;

                if (listaCriteriosVaziaSemModeloDocumento) return false;

                return true;
              },
            }),

          listaRegraEscolhaAliquotaNcm: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe pelo menos um dos critérios: Destinado, NCM, CEST ou Produto!',
              test: () => {
                if (
                  listaCriteriosVaziaSemModeloDocumento &&
                  listaModeloDocumentoSomenteItemCTe
                )
                  return true;

                if (listaCriteriosVaziaSemModeloDocumento) return false;

                return true;
              },
            }),

          listaRegraEscolhaAliquotaCest: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe pelo menos um dos critérios: Destinado, NCM, CEST ou Produto!',
              test: () => {
                if (
                  listaCriteriosVaziaSemModeloDocumento &&
                  listaModeloDocumentoSomenteItemCTe
                )
                  return true;

                if (listaCriteriosVaziaSemModeloDocumento) return false;

                return true;
              },
            }),

          listaRegraEscolhaAliquotaProduto: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe pelo menos um dos critérios: Destinado, NCM, CEST ou Produto!',
              test: () => {
                if (
                  listaCriteriosVaziaSemModeloDocumento &&
                  listaModeloDocumentoSomenteItemCTe
                )
                  return true;

                if (listaCriteriosVaziaSemModeloDocumento) return false;

                return true;
              },
            }),

          listaRegraEscolhaAliquotaRegimeTributarioEmitente: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe pelo menos um Regime Tributário!',
              test: () => {
                return !(
                  data.listaRegraEscolhaAliquotaRegimeTributarioEmitente
                    .listaValor.length === 0
                );
              },
            })
            .test({
              message:
                'Não é possivel selecionar eses Regimes Tributários em conjunto!',
              test: () => {
                const listaValor =
                  data.listaRegraEscolhaAliquotaRegimeTributarioEmitente.listaValor.map(
                    (valor: any) => {
                      return valor.descricao;
                    }
                  );

                if (listaValor.length === 1) return true;

                if (
                  listaValor.includes(
                    RegimeTributarioEnum.simplesNacionalExcessoSublimiteReceitaBruta
                  )
                )
                  return false;

                if (
                  (listaValor.includes(
                    RegimeTributarioEnum.simplesNacionalMicroEmpresa
                  ) ||
                    listaValor.includes(
                      RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte
                    )) &&
                  (listaValor.includes(
                    RegimeTributarioEnum.lucroPresumidoRegimeNormal
                  ) ||
                    listaValor.includes(
                      RegimeTributarioEnum.lucroRealRegimeNormal
                    ))
                )
                  return false;

                return true;
              },
            }),

          regraEscolhaAliquotaResultado: Yup.object().shape({
            modalidadeBaseCalculoIcmsSt: Yup.mixed()
              .nullable()
              .test({
                message: 'Modalidade Base Calculo ICMS ST. é obrigatória!',
                test: (value: any) => {
                  return !(
                    (data.regraEscolhaAliquotaResultado.aliquotaInternaIcmsSt ||
                      data.regraEscolhaAliquotaResultado
                        .aliquotaInterestadualIcmsSt ||
                      data.regraEscolhaAliquotaResultado
                        .mvaSubstituicaoTributaria ||
                      data.regraEscolhaAliquotaResultado
                        .mvaSubstituicaoTributariaSimplesNacional ||
                      data.regraEscolhaAliquotaResultado
                        .valorUnitarioPautaIcmsSt ||
                      data.regraEscolhaAliquotaResultado
                        .percentualReducaoBaseCalculoIcmsStRegimeNormal ||
                      data.regraEscolhaAliquotaResultado
                        .percentualReducaoBaseCalculoIcmsStSimplesNacional) &&
                    !value
                  );
                },
              }),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await RegraEscolhaAliquotaComunicador.update({ id, params: data });
        } else {
          const response = await RegraEscolhaAliquotaComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
