import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TipoCrGrupoEnum,
  FormatarEnum,
  IConfiguracaoBebidaRastreabilidadeSisdevinLista,
} from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  AiOutlineDelete,
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
} from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputAutoCompleteCodigoRastreabilidade, {
  IInputAutoCompleteCodigoRastreabilidadeRef,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteCodigoRastreabilidade';
import TrDraggable from '../../../../../../../Componentes/TrDraggable';
import { UseListaGrupoProdutoCodigoRastreabilidadeDraggable } from '../Hooks/ListaCodigoRastreabilidadeGrupoProdutoDraggableHook';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import Select from '../../../../../../../Componentes/Select';
import InputAutoCompleteGrupoProdutoSisdevin, {
  IInputAutoCompleteGrupoProdutoSisdevinRef,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteGrupoProdutoSisdevin';
import ConfiguracaoBebidaRastreabilidadeSisdevinComunicador from '../../../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/ConfiguracaoBebidaRastreabilidadeSisdevin/Comunicador/ConfiguracaoBebidaRastreabilidadeSisdevinComunicador';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import Divisor from '../../../../../../../Componentes/Divisor';

interface IListaDetalheCodigoRastreabilidadeProps {
  name: string;
}

const ListaConfiguracaoBebidaRastreabilidadeSisdevin: React.FC<
  IListaDetalheCodigoRastreabilidadeProps
> = ({ name }) => {
  const [isCodigoRastreabilidade, setIsCodigoRastreabilidade] = useState(true);

  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosSisdevinCodigoRastreabilidade: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const { listaValor, handleAtualizarOrdem, handleMover, setListaValor } =
    UseListaGrupoProdutoCodigoRastreabilidadeDraggable();

  const valorRef = useRef<IConfiguracaoBebidaRastreabilidadeSisdevinLista[]>(
    []
  );

  const inputAutoCompleteCodigoRastreabilidadeRef =
    useRef<IInputAutoCompleteCodigoRastreabilidadeRef>(null);

  const inputAutoCompleteGrupoProdutoSisdevinRef =
    useRef<IInputAutoCompleteGrupoProdutoSisdevinRef>(null);

  const listaIdlGrupoProdutoSisdevin = useMemo(() => {
    const listaId = listaValor.map((valor: any) => {
      return valor.idGrupoProdutoSisdevin;
    });

    return listaId.filter((id) => id !== null);
  }, [listaValor]);

  const listaIdCodigoRastreabilidade = useMemo(() => {
    const listaId = listaValor?.map((valor: any) => {
      return valor.idCodigoRastreabilidadeSisdevin;
    });

    return listaId.filter((id) => id !== null);
  }, [listaValor]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      IConfiguracaoBebidaRastreabilidadeSisdevinLista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;

    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
    if (
      formRefLista.current?.getFieldValue('tipoCrGrupo') ===
      TipoCrGrupoEnum.codigoRastreabilidade
    ) {
      setIsCodigoRastreabilidade(true);
    }
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  const handleAtivar = useCallback(
    async (id: string): Promise<void> => {
      try {
        await ConfiguracaoBebidaRastreabilidadeSisdevinComunicador.update({
          id,
          params: { ativo: true },
        });

        ToastSucesso('Registro Ativado!');
        formPrincipal.handleCarregarDados();
      } catch (error) {
        TratarErros(error, { redirecionar });
      }
    },
    [formPrincipal, redirecionar]
  );

  const handleInativar = useCallback(
    async (id: string): Promise<void> => {
      try {
        await ConfiguracaoBebidaRastreabilidadeSisdevinComunicador.update({
          id,
          params: { ativo: false },
        });

        ToastSucesso('Registro Inativado!');
        formPrincipal.handleCarregarDados();
      } catch (error) {
        TratarErros(error, { redirecionar });
      }
    },
    [formPrincipal, redirecionar]
  );

  useEffect(() => {
    if (isCodigoRastreabilidade) {
      formRefLista.current?.setDataInicial({
        tipoCrGrupo: TipoCrGrupoEnum.codigoRastreabilidade,
      });
    }
  }, [formRefLista, isCodigoRastreabilidade]);

  return (
    <>
      <Divisor style={{ width: '100%' }}>
        <FormCia ref={formRefLista}>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Select
                name="tipoCrGrupo"
                label="Tipo CR ou Grupo"
                onChange={(event) => {
                  setIsCodigoRastreabilidade(
                    event.target.value === TipoCrGrupoEnum.codigoRastreabilidade
                  );
                }}
                options={FormatarEnum({
                  enumObj: TipoCrGrupoEnum,
                })}
              />
            </Col>
            <Col lg={7} md={6} sm={12}>
              {isCodigoRastreabilidade ? (
                <InputAutoCompleteCodigoRastreabilidade
                  label="Código de Rastreabilidade (CR)"
                  name="codigoRastreabilidadeSisdevin.codigoRastreabilidade"
                  placeholder="Código de Rastreabilidade (CR)"
                  permitirAdicionar
                  listaIdParaRemover={listaIdCodigoRastreabilidade}
                  ref={inputAutoCompleteCodigoRastreabilidadeRef}
                />
              ) : (
                <InputAutoCompleteGrupoProdutoSisdevin
                  name="grupo.grupoProdutoSisdevin"
                  label="Grupo"
                  placeholder="Grupo"
                  permitirAdicionar
                  listaIdParaRemover={listaIdlGrupoProdutoSisdevin}
                  ref={inputAutoCompleteGrupoProdutoSisdevinRef}
                />
              )}
            </Col>
            <Col
              lg={2}
              md={12}
              sm={12}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <BtnPadraoButton
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading
                  loading={loading || formPrincipal.loading || loading}
                >
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </FormCia>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Tipo</span>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Descrição</span>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Códigos de Rastreabilidade</span>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Ativo</span>
                </th>
                <th className="lista-texto">
                  <span>Ações</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaValor &&
                listaValor.map((item, index) => {
                  const grupoProduto = item.grupoProdutoSisdevin !== null;
                  const listaCodigoRastreabilidadeFormatada =
                    item.grupoProdutoSisdevin?.listaGrupoProdutoCodigoRastreabilidade.reduce(
                      (acc: string, atual) => {
                        acc += `${atual.codigoRastreabilidadeSisdevin.codigoRastreabilidade}; `;
                        return acc;
                      },
                      ''
                    );

                  return (
                    <TrDraggable
                      idDrag={v4()}
                      index={index}
                      onMover={handleMover}
                      onDragEnd={() => {
                        if (formPrincipal.loading || loading) return;
                        handleAtualizarOrdem();
                      }}
                      key={v4()}
                      className={
                        formPrincipal.loading || loading
                          ? 'tabela-desabilitada'
                          : ''
                      }
                    >
                      <td className="lista-texto">
                        <div>
                          {grupoProduto ? 'Grupo' : 'Cód. rastreabilidade (CR)'}
                        </div>
                      </td>
                      <td className="lista-texto">
                        <div>
                          {grupoProduto
                            ? item.grupoProdutoSisdevin?.descricao
                            : item.codigoRastreabilidadeSisdevin?.descricao}
                        </div>
                      </td>
                      <td className="lista-texto">
                        <div>
                          {grupoProduto
                            ? listaCodigoRastreabilidadeFormatada
                            : item.codigoRastreabilidadeSisdevin
                                ?.codigoRastreabilidade}
                        </div>
                      </td>
                      <td
                        className="lista-booleano"
                        style={{ color: item.ativo ? 'green' : 'red' }}
                      >
                        <div>{item.ativo ? 'Sim' : 'Não'}</div>
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={() => {
                                handleExcluir(item.id);
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <AiOutlineDelete style={{ marginRight: 5 }} />
                                Excluir
                              </span>
                            </Dropdown.Item>

                            {!item.ativo && (
                              <Dropdown.Item
                                onClick={() => {
                                  handleAtivar(item.id);
                                }}
                                disabled={
                                  !permissao?.exclui ||
                                  formPrincipal.loading ||
                                  loading
                                }
                              >
                                <span style={{ display: 'flex', fontSize: 16 }}>
                                  <AiOutlineCheckSquare
                                    style={{ marginRight: 5 }}
                                  />
                                  Ativar
                                </span>
                              </Dropdown.Item>
                            )}
                            {item.ativo && (
                              <Dropdown.Item
                                onClick={() => {
                                  handleInativar(item.id);
                                }}
                                disabled={
                                  !permissao?.exclui ||
                                  formPrincipal.loading ||
                                  loading
                                }
                              >
                                <span style={{ display: 'flex', fontSize: 16 }}>
                                  <AiOutlineCloseSquare
                                    style={{ marginRight: 5 }}
                                  />
                                  Inativar
                                </span>
                              </Dropdown.Item>
                            )}
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </TrDraggable>
                  );
                })}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>
    </>
  );
};

export default ListaConfiguracaoBebidaRastreabilidadeSisdevin;
