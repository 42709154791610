import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PedidoCompraComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Comunicador/PedidoCompraComunicador';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { NotaFiscalEntradaImportacaoPedidoCompraHook } from '../../../NotaFiscalEntradaImportacaoPedidoCompraHook';
import { PedidoCompraImportacaoCotacaoCompraHook } from '../../../PedidoCompraImportacaoCotacaoCompraHook';
import { PedidoCompraAtualizarSituacaoHook } from './AtualizarSitucaoHook';
import { PedidoCompraDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { PedidoCompraHook } from './PedidoCompraHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const PedidoCompraHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: PedidoCompraComunicador }}>
      <RotasHook>
        <RegimeTributarioHook>
          <PedidoCompraDuplicarHook>
            <PedidoCompraAtualizarSituacaoHook>
              <PedidoCompraImportacaoCotacaoCompraHook>
                <NotaFiscalEntradaImportacaoPedidoCompraHook>
                  <FormHook>
                    <SubHeaderHook>
                      <PedidoCompraHook>{children}</PedidoCompraHook>
                    </SubHeaderHook>
                  </FormHook>
                </NotaFiscalEntradaImportacaoPedidoCompraHook>
              </PedidoCompraImportacaoCotacaoCompraHook>
            </PedidoCompraAtualizarSituacaoHook>
          </PedidoCompraDuplicarHook>
        </RegimeTributarioHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default PedidoCompraHooks;
