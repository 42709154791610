import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';

const TrayTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={12} sm={12}>
            <Input
              label="Sku (Id Tray)"
              name="produtoEcommerce.skuIdTray"
              maxLength={20}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Input
              label="Variação"
              name="produtoEcommerce.variacaoIdTray"
              maxLength={20}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <InputInteiroNulavel
              label="Product_id (Variação)"
              name="produtoEcommerce.productIdVariacao"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TrayTab;
