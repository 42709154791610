import { IPedidoVendaRetratoSemFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoVendaRetratoSemFotoProdutoPDF from './PedidoVendaRetratoSemFotoProdutoImpressaoPDF';

interface IImpressaoPedidoVendaRetratoSemFotoProduto {
  handleVisualizarPdf(
    data: IPedidoVendaRetratoSemFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IPedidoVendaRetratoSemFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPedidoVendaRetratoSemFotoProduto =
  (): IImpressaoPedidoVendaRetratoSemFotoProduto => {
    async function handleVisualizarPdf(
      dados: IPedidoVendaRetratoSemFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IPedidoVendaRetratoSemFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoVendaRetratoSemFotoProduto();
