import styled, { css } from 'styled-components';

interface IStepBox {
  $selecionado: boolean;
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  label {
    margin: 0 0 0 5px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepBox = styled.button<IStepBox>`
  height: 60px;
  width: 60px;
  background: white;
  border: 1px solid lightblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;

  ${({ $selecionado }) =>
    $selecionado &&
    css`
      background: lightblue;
    `}
`;

export const StepNome = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
`;
