import React from 'react';
import {
  Text,
  View,
  Document as DocumentPDF,
  Page as PagePDF,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  IPedidoVendaRetratoRetiradaConsignadoDuasViasImpressao,
} from '@elogestor/util';

const Document = DocumentPDF;
const Page = PagePDF;

interface IPedidoVendaRetratoRetiradaConsignadoDuasViasImpressaoPDF {
  dados: IPedidoVendaRetratoRetiradaConsignadoDuasViasImpressao;
}

const fontSize = 8;

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    fontSize,
    padding: '10 15 10 10',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 8,
  },

  data: {
    fontSize,
    width: '100%',
    flex: 1,
  },
});

const PedidoVendaRetratoRetiradaConsignadoDuasViasImpressaoPDF: React.FC<
  IPedidoVendaRetratoRetiradaConsignadoDuasViasImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View
          style={{
            minHeight: '100%',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: '49%', display: 'flex' }}>
            <View style={styles.headerEmpresa} fixed>
              <View>
                {dados.caminhoLogo !== '' && (
                  <Image
                    src={dados.caminhoLogo}
                    style={{
                      width: 120,
                      borderRadius: 0,
                      marginRight: '10pt',
                    }}
                  />
                )}
              </View>
              <View style={{ width: '100%', alignItems: 'center' }}>
                <View style={{ marginTop: '10pt', alignItems: 'center' }}>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {dados.empresa.nomeRazaoSocial}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`CNPJ: `}
                    </Text>

                    <Text style={{ fontSize }}>{dados.empresa?.cnpjCpf}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', paddingLeft: '5pt' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`IE: `}
                    </Text>
                    <Text style={{ fontSize }}>
                      {dados.empresa?.inscricaoEstadual}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '100%' }}>
                  <Text
                    style={{
                      fontSize,
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.empresa?.empresaEndereco?.logradouro}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.numero}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.bairro}
                  </Text>
                </View>

                <View style={{ width: '100%' }}>
                  <Text
                    style={{
                      fontSize,
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.empresa?.empresaEndereco?.cep}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.cidade?.nome}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.cidade?.estado?.sigla}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', width: '100%' }}>
                  {dados.empresa?.telefone && (
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '50%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Text
                        style={{
                          fontSize,
                          fontWeight: 'bold',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {`Telefone: `}
                      </Text>
                    </View>
                  )}
                  <View style={{ flexDirection: 'row', width: '50%' }}>
                    <Text style={{ fontSize }}>{dados.empresa?.telefone}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                alignItems: 'center',
                borderTop: '1pt solid black',
                flexDirection: 'row',
              }}
            >
              <Text style={{ width: '15%' }} />
              <Text
                style={{
                  width: '70%',
                  fontSize,
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                RETIRADA DE VENDA CONSIGNADA
              </Text>
              <Text style={{ fontSize, width: '15%', textAlign: 'right' }}>
                1ª via: Loja
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                borderBottom: '1pt solid black',
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize, fontWeight: 'bold' }}>
                  {`Número: `}
                </Text>

                <Text style={{ fontSize }}>{dados.numero}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize, fontWeight: 'bold' }}>
                  {`Data Emissão: `}
                </Text>
                <Text style={{ fontSize }}>
                  {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                </Text>
              </View>
            </View>

            <View style={styles.headerCliente}>
              <View style={{ width: '100%' }}>
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      backgroundColor: '#E4E4E4',
                    }}
                  >
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cliente: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {`${dados.pedidoVendaPessoa.pessoa.codigo} - ${dados.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`Cnpj/Cpf: `}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoa.pessoa.cnpjCpf}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`Endereço: `}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco.logradouro &&
                        `${dados.pedidoVendaPessoaEndereco.logradouro}, `}
                      {dados.pedidoVendaPessoaEndereco.numero &&
                        `${dados.pedidoVendaPessoaEndereco.numero}, `}
                      {dados.pedidoVendaPessoaEndereco.bairro &&
                        `${dados.pedidoVendaPessoaEndereco.bairro}, `}
                      {dados.pedidoVendaPessoaEndereco.complemento &&
                        dados.pedidoVendaPessoaEndereco.complemento}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cidade: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco?.cidade &&
                        `${dados.pedidoVendaPessoaEndereco?.cidade?.nome} - ${dados.pedidoVendaPessoaEndereco?.cidade?.estado.sigla}`}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cep: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco.cep}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontSize, fontWeight: 'bold' }}>
                        {'E-mail: '}
                      </Text>
                      <Text style={{ fontSize, maxWidth: '40%' }}>
                        {dados.pedidoVendaPessoa.pessoa.email}
                      </Text>
                    </View>

                    {dados.finalidadeUsoCliente && (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontSize, fontWeight: 'bold' }}>
                          {'Finalidade de Uso do Cliente: '}
                        </Text>
                        <Text style={{ fontSize, maxWidth: '40%' }}>
                          {dados.finalidadeUsoCliente}
                        </Text>
                      </View>
                    )}

                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontSize, fontWeight: 'bold' }}>
                        {'Telefone: '}
                      </Text>
                      <Text style={{ fontSize }}>
                        {dados.pedidoVendaPessoa.pessoa.telefone}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.data}>
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                  fontSize,
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>Seq.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>Código</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '38%' }}>
                  <Text>Descrição</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>UN</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>Qtde.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Val. Unit. (R$)</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Val. Total. (R$)</Text>
                </View>
                <View style={{ width: '1%' }} />
              </View>

              <View
                style={{ marginTop: 5, height: 'auto', position: 'relative' }}
              >
                {dados.listaPedidoVendaItem?.map((dado, index) => {
                  return (
                    <View
                      key={dado.ordem}
                      style={{
                        minHeight: '12pt',
                        flexDirection: 'row',
                        backgroundColor:
                          dado.ordem % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ width: '4%', alignItems: 'flex-end' }}>
                        <Text>{dado.ordem}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '10%', alignItems: 'flex-end' }}>
                        <Text>{dado.produto.codigo}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '38%' }}>
                        <Text>{dado.produto.descricao}</Text>
                        <Text>{dado.informacoesAdicionaisProduto}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%' }}>
                        <Text>{dado.unidadeMedida.sigla}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.quantidade).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '15%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorUnitario).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '15%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />
                    </View>
                  );
                })}

                <View
                  style={{ flexDirection: 'row', borderTop: '1pt solid black' }}
                >
                  <View style={{ width: '85%', alignItems: 'flex-end' }}>
                    <Text
                      style={{ fontSize: fontSize + 2, fontWeight: 'bold' }}
                    >
                      {`Valor Total: `}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: '15%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontSize + 2,
                        paddingRight: 4,
                        fontWeight: 'bold',
                      }}
                    >
                      {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View
                    style={{
                      width: '45%',
                      alignItems: 'center',
                      borderTop: '1pt solid black',
                      marginTop: '40pt',
                    }}
                  >
                    <Text
                      style={{
                        fontSize,
                        fontWeight: 'bold',
                      }}
                    >
                      Assinatura do Cliente
                    </Text>
                  </View>
                  <View style={{ width: '10%' }} />
                  <View
                    style={{
                      width: '45%',
                      alignItems: 'center',
                      borderTop: '1pt solid black',
                      marginTop: '40pt',
                    }}
                  >
                    <Text
                      style={{
                        fontSize,
                        fontWeight: 'bold',
                      }}
                    >
                      Assinatura do Conferente
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    marginTop: '10pt',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      width: '100%',
                      fontSize,
                      marginBottom: '10pt',
                      marginTop: '10pt',
                      alignItems: 'center',
                    }}
                  >
                    {dados?.textoPadrao}
                  </Text>
                  <Text
                    style={{
                      width: '100%',
                      fontSize,
                      marginBottom: '10pt',
                      alignItems: 'center',
                    }}
                  >
                    Atendido por:
                    {
                      dados.pedidoVendaComercial
                        ?.listaPedidoVendaComercialRepresentante?.[0]
                        ?.pessoaRepresentante?.nomeRazaoSocial
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              width: '1%',
              height: '100%',
              borderRight: '1pt dashed black',
              marginRight: '1%',
            }}
          />
          <View style={{ width: '49%', display: 'flex' }}>
            <View style={styles.headerEmpresa} fixed>
              <View>
                {dados.caminhoLogo !== '' && (
                  <Image
                    src={dados.caminhoLogo}
                    style={{
                      width: 120,
                      borderRadius: 0,
                      marginRight: '10pt',
                    }}
                  />
                )}
              </View>
              <View style={{ width: '100%', alignItems: 'center' }}>
                <View style={{ marginTop: '10pt', alignItems: 'center' }}>
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {dados.empresa.nomeRazaoSocial}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`CNPJ: `}
                    </Text>

                    <Text style={{ fontSize }}>{dados.empresa?.cnpjCpf}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', paddingLeft: '5pt' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`IE: `}
                    </Text>
                    <Text style={{ fontSize }}>
                      {dados.empresa?.inscricaoEstadual}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '100%' }}>
                  <Text
                    style={{
                      fontSize,
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.empresa?.empresaEndereco?.logradouro}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.numero}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.bairro}
                  </Text>
                </View>

                <View style={{ width: '100%' }}>
                  <Text
                    style={{
                      fontSize,
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.empresa?.empresaEndereco?.cep}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.cidade?.nome}
                    {` - `}
                    {dados.empresa?.empresaEndereco?.cidade?.estado?.sigla}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', width: '100%' }}>
                  {dados.empresa?.telefone && (
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '50%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Text
                        style={{
                          fontSize,
                          fontWeight: 'bold',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {`Telefone: `}
                      </Text>
                    </View>
                  )}
                  <View style={{ flexDirection: 'row', width: '50%' }}>
                    <Text style={{ fontSize }}>{dados.empresa?.telefone}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                alignItems: 'center',
                borderTop: '1pt solid black',
                flexDirection: 'row',
              }}
            >
              <Text style={{ width: '15%' }} />
              <Text
                style={{
                  width: '70%',
                  fontSize,
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                RETIRADA DE VENDA CONSIGNADA
              </Text>
              <Text style={{ fontSize, width: '15%', textAlign: 'right' }}>
                2ª via: Cliente
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                borderBottom: '1pt solid black',
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize, fontWeight: 'bold' }}>
                  {`Número: `}
                </Text>

                <Text style={{ fontSize }}>{dados.numero}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize, fontWeight: 'bold' }}>
                  {`Data Emissão: `}
                </Text>
                <Text style={{ fontSize }}>
                  {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                </Text>
              </View>
            </View>

            <View style={styles.headerCliente}>
              <View style={{ width: '100%' }}>
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      backgroundColor: '#E4E4E4',
                    }}
                  >
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cliente: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {`${dados.pedidoVendaPessoa.pessoa.codigo} - ${dados.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`Cnpj/Cpf: `}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoa.pessoa.cnpjCpf}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {`Endereço: `}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco.logradouro &&
                        `${dados.pedidoVendaPessoaEndereco.logradouro}, `}
                      {dados.pedidoVendaPessoaEndereco.numero &&
                        `${dados.pedidoVendaPessoaEndereco.numero}, `}
                      {dados.pedidoVendaPessoaEndereco.bairro &&
                        `${dados.pedidoVendaPessoaEndereco.bairro}, `}
                      {dados.pedidoVendaPessoaEndereco.complemento &&
                        dados.pedidoVendaPessoaEndereco.complemento}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cidade: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco?.cidade &&
                        `${dados.pedidoVendaPessoaEndereco?.cidade?.nome} - ${dados.pedidoVendaPessoaEndereco?.cidade?.estado.sigla}`}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize, fontWeight: 'bold' }}>
                      {'Cep: '}
                    </Text>
                    <Text style={{ fontSize, maxWidth: '90%' }}>
                      {dados.pedidoVendaPessoaEndereco.cep}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontSize, fontWeight: 'bold' }}>
                        {'E-mail: '}
                      </Text>
                      <Text style={{ fontSize, maxWidth: '40%' }}>
                        {dados.pedidoVendaPessoa.pessoa.email}
                      </Text>
                    </View>

                    {dados.finalidadeUsoCliente && (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontSize, fontWeight: 'bold' }}>
                          {'Finalidade de Uso do Cliente: '}
                        </Text>
                        <Text style={{ fontSize, maxWidth: '40%' }}>
                          {dados.finalidadeUsoCliente}
                        </Text>
                      </View>
                    )}

                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontSize, fontWeight: 'bold' }}>
                        {'Telefone: '}
                      </Text>
                      <Text style={{ fontSize }}>
                        {dados.pedidoVendaPessoa.pessoa.telefone}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.data}>
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                  fontSize,
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>Seq.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>Código</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '38%' }}>
                  <Text>Descrição</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>UN</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>Qtde.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Val. Unit. (R$)</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Val. Total. (R$)</Text>
                </View>
                <View style={{ width: '1%' }} />
              </View>

              <View
                style={{ marginTop: 5, height: 'auto', position: 'relative' }}
              >
                {dados.listaPedidoVendaItem?.map((dado, index) => {
                  return (
                    <View
                      key={dado.ordem}
                      style={{
                        minHeight: '12pt',
                        flexDirection: 'row',
                        backgroundColor:
                          dado.ordem % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ width: '4%', alignItems: 'flex-end' }}>
                        <Text>{dado.ordem}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '10%', alignItems: 'flex-end' }}>
                        <Text>{dado.produto.codigo}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '38%' }}>
                        <Text>{dado.produto.descricao}</Text>
                        <Text>{dado.informacoesAdicionaisProduto}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%' }}>
                        <Text>{dado.unidadeMedida.sigla}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.quantidade).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '15%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorUnitario).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '15%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />
                    </View>
                  );
                })}

                <View
                  style={{ flexDirection: 'row', borderTop: '1pt solid black' }}
                >
                  <View style={{ width: '85%', alignItems: 'flex-end' }}>
                    <Text
                      style={{ fontSize: fontSize + 2, fontWeight: 'bold' }}
                    >
                      {`Valor Total: `}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: '15%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontSize + 2,
                        paddingRight: 4,
                        fontWeight: 'bold',
                      }}
                    >
                      {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View
                    style={{
                      width: '45%',
                      alignItems: 'center',
                      borderTop: '1pt solid black',
                      marginTop: '40pt',
                    }}
                  >
                    <Text
                      style={{
                        fontSize,
                        fontWeight: 'bold',
                      }}
                    >
                      Assinatura do Cliente
                    </Text>
                  </View>
                  <View style={{ width: '10%' }} />
                  <View
                    style={{
                      width: '45%',
                      alignItems: 'center',
                      borderTop: '1pt solid black',
                      marginTop: '40pt',
                    }}
                  >
                    <Text
                      style={{
                        fontSize,
                        fontWeight: 'bold',
                      }}
                    >
                      Assinatura do Conferente
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    marginTop: '10pt',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      width: '100%',
                      fontSize,
                      marginBottom: '10pt',
                      marginTop: '10pt',
                      alignItems: 'center',
                    }}
                  >
                    {dados?.textoPadrao}
                  </Text>
                  <Text
                    style={{
                      width: '100%',
                      fontSize,
                      marginBottom: '10pt',
                      alignItems: 'center',
                    }}
                  >
                    Atendido por:
                    {
                      dados.pedidoVendaComercial
                        ?.listaPedidoVendaComercialRepresentante?.[0]
                        ?.pessoaRepresentante?.nomeRazaoSocial
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          fixed
          style={{
            position: 'absolute',
            flexDirection: 'row',
            fontSize: 6,
            bottom: 5,
            left: 5,
            width: '100%',
          }}
        >
          <View style={{ width: '49%', flexDirection: 'row' }}>
            <Text style={{ fontSize, width: '50%', textAlign: 'left' }}>
              https://www.elogestor.com.br
            </Text>

            <Text
              style={{ fontSize, width: '50%', textAlign: 'right' }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
            />
          </View>

          <View style={{ width: '3%' }} />

          <View style={{ width: '49%', flexDirection: 'row' }}>
            <Text style={{ fontSize, width: '50%', textAlign: 'left' }}>
              https://www.elogestor.com.br
            </Text>

            <Text
              style={{ fontSize, width: '50%', textAlign: 'right' }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaRetratoRetiradaConsignadoDuasViasImpressaoPDF;
