import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-pedido-compra/obter-pedido-compra-item-estoque';

interface INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicadorIndex {
  dataHoraEmissao: Date;
  idPedidoCompraItem: string;
  quantidade: number;
}

class NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicador();
