import React, { createContext, useState, useContext } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IShowTabsContextProps {
  showNotaReferenciadaTab: boolean | null;
  setShowNotaReferenciadaTab: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;
}

const ShowTabsContext = createContext({} as IShowTabsContextProps);

const ShowTabsHook: React.FC<IPadraoProps> = ({ children }) => {
  const [showNotaReferenciadaTab, setShowNotaReferenciadaTab] = useState<
    boolean | null
  >(null);

  return (
    <ShowTabsContext.Provider
      value={{
        setShowNotaReferenciadaTab,
        showNotaReferenciadaTab,
      }}
    >
      {children}
    </ShowTabsContext.Provider>
  );
};

const UseShowTabs = (): IShowTabsContextProps => {
  const context = useContext(ShowTabsContext);
  return context;
};

export { UseShowTabs, ShowTabsHook };
