import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  AppErro,
  SituacaoConhecimentoTransporteEnum,
  IConhecimentoTransporteTerceiroValoresAlterar,
  TipoCteEnum,
  TipoServicoEnum,
  TipoTomadorEnum,
  IsSimplesNacional,
  ValidarChaveAcesso,
  DestinadoEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import ConhecimentoTransporteTerceiroDuplicarComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroDuplicarComunicador';
import { UseShowTabs } from './ShowTabsHook';
import ConhecimentoTransporteTerceiroComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroComunicador';
import ConhecimentoTransporteTerceiroObterDadosPadraoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroObterDadosPadraoComunicador';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { ObterImpostoFiscalConformeImpostoDocumento } from './ObterImpostoFiscalConformeImpostoDocumento';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const rota = UseRota();
  const { redirecionar } = UseRedirecionar();
  const { setShowNotaReferenciadaTab } = UseShowTabs();
  const navigate = useNavigate();
  const { setRegimeTributarioPorData, regimeTributario } =
    UseRegimeTributario();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback(
    (valor: string | null) => {
      idDetalheRegistro.current = valor;
      refresh();
    },
    [refresh]
  );

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IConhecimentoTransporteTerceiroValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        setLoading(true);

        const response = await ConhecimentoTransporteTerceiroComunicador.show({
          id: idEditar,
        });

        await setRegimeTributarioPorData(response.dataHoraEmissao);

        if (
          response.listaConhecimentoTransporteTerceiroNotaReferenciada?.length
        ) {
          setShowNotaReferenciadaTab(true);
        }

        if (
          response.listaConhecimentoTransporteTerceiroDocumentoReferenciado
            ?.length
        ) {
          setShowNotaReferenciadaTab(false);
        }

        if (
          !response.listaConhecimentoTransporteTerceiroDocumentoReferenciado
            ?.length &&
          !response.listaConhecimentoTransporteTerceiroNotaReferenciada?.length
        ) {
          setShowNotaReferenciadaTab(null);
        }

        await formRef.current?.setSemExecutarEvento(response);
        await formRef.current?.atualizarDataInicial();

        setLoading(false);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        setLoading(true);

        const response =
          await ConhecimentoTransporteTerceiroObterDadosPadraoComunicador.show({
            params: {
              dataHoraEmissao:
                formRef.current?.getFieldValue('dataHoraEmissao') || new Date(),
            },
          });

        const impostoIcmsFiscalDadosIniciais = response
          .conhecimentoTransporteTerceiroImpostoIcmsFiscal
          .creditoConformeDocumento
          ? {
              conhecimentoTransporteTerceiroImpostoIcmsFiscal: {
                conhecimentoTransporteTerceiroImpostoIcmsFiscalSnSub: {
                  ...response.conhecimentoTransporteTerceiroImpostoIcmsFiscal,
                  situacaoTributaria: {
                    ...response.conhecimentoTransporteTerceiroImpostoIcms
                      .situacaoTributaria,
                  },
                },
                conhecimentoTransporteTerceiroImpostoIcmsFiscalSn: {
                  ...response.conhecimentoTransporteTerceiroImpostoIcmsFiscal,
                  situacaoTributaria: {
                    ...response.conhecimentoTransporteTerceiroImpostoIcms
                      .situacaoTributaria,
                  },
                },
                conhecimentoTransporteTerceiroImpostoIcmsFiscalPresu: {
                  ...response.conhecimentoTransporteTerceiroImpostoIcmsFiscal,
                  situacaoTributaria: {
                    ...response.conhecimentoTransporteTerceiroImpostoIcms
                      .situacaoTributaria,
                  },
                },
                conhecimentoTransporteTerceiroImpostoIcmsFiscalReal: {
                  ...response.conhecimentoTransporteTerceiroImpostoIcmsFiscal,
                  situacaoTributaria: {
                    ...response.conhecimentoTransporteTerceiroImpostoIcms
                      .situacaoTributaria,
                  },
                },
              },
            }
          : null;

        let date = new Date();
        const dataAtualSetada =
          formRef.current?.getFieldValue('dataHoraEmissao');

        if (dataAtualSetada) {
          date = dataAtualSetada;
        }

        await setRegimeTributarioPorData(date);

        await formRef.current?.setDataInicial({
          ...response,
          ...impostoIcmsFiscalDadosIniciais,
          tipoCte: TipoCteEnum.normal,
          tipoServico: TipoServicoEnum.normal,
          situacao: SituacaoConhecimentoTransporteEnum.naoTransmitido,
          retira: false,
          dataHoraEmissao: date,
          tipoTomador: TipoTomadorEnum.remetente,

          financeiroConferido: false,
          valorFinanceiro: 0,

          conhecimentoTransporteTerceiroImpostoIcms: {
            destinado: DestinadoEnum.comercializacao,
          },

          ...dadosPadrao,
        });

        setLoading(false);

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
      setRegimeTributarioPorData,
      setShowNotaReferenciadaTab,
    ]
  );

  const makeSchemaDadosPessoa = (pessoa: string, condicao: boolean): any => {
    if (!condicao) {
      return Yup.object().optional();
    }

    return Yup.object({
      cnpjCpfVirtual: Yup.string().required(
        `O CNPJ/CPF do ${pessoa} é obrigatório!`
      ),
    });
  };

  const makeSchemaDadosEnderecoPessoa = (
    pessoa: string,
    condicao: boolean
  ): any => {
    if (!condicao) {
      return Yup.object().optional();
    }

    return Yup.object({
      idCidade: Yup.string()
        .nullable()
        .required(`A Cidade do ${pessoa} é obrigatória!`),
      logradouro: Yup.string().required(
        `O Logradouro do ${pessoa} é obrigatório!`
      ),
      numero: Yup.string().required(
        `O Número do Endereço do ${pessoa} é obrigatório!`
      ),
      bairro: Yup.string().required(`O Bairro do ${pessoa} é obrigatório!`),
    });
  };

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    const tipoTomador = formRef.current?.getFieldValue('tipoTomador');
    const tipoCte = formRef.current?.getFieldValue('tipoCte');

    const listaCamposSubstituto: Array<string | undefined> = [
      data.substitutoChaveAcessoNfe,
      data.substitutoChaveAcessoCteAnulacao,
      data.substitutoChaveAcessoCteEmitTomador,
    ];

    const minimoUmCampoInformado =
      listaCamposSubstituto.filter(Boolean).length > 0;

    const errorMessage = 'Informar no mínimo um campo!';
    const chavesSubstitutoErrors = {
      substitutoChaveAcessoNfe: errorMessage,
      substitutoChaveAcessoCteAnulacao: errorMessage,
      substitutoChaveAcessoCteEmitTomador: errorMessage,
    };
    const hasErroSubstituto =
      tipoCte === TipoCteEnum.substituto && !minimoUmCampoInformado;

    try {
      formRef.current?.setErrors({});
      if (hasErroSubstituto) {
        TratarErros(
          new AppErro({
            mensagem:
              'Quando Tipo CTe - Substituto: Informar no mínimo uma chave de acesso do CTe!',
          })
        );

        formRef.current?.setErrors({ ...chavesSubstitutoErrors });
      }

      const schema = Yup.object().shape({
        serie: Yup.string().nullable().required('Série é obrigatória!'),
        idModeloDocumento: Yup.string()
          .nullable()
          .required('O Modelo de Documento é obrigatório!'),

        numero: Yup.number().required('Número é obrigatório!'),

        dataHoraEmissao: Yup.date()
          .nullable()
          .required('Data de Emissão é obrigatória!'),

        dataLancamento: Yup.date()
          .nullable()
          .required('Data de Lançamento é obrigatória!'),

        dataAquisicaoPrestacaoServico: Yup.date()
          .nullable()
          .required('Data de Aq/Prest. Serviço é obrigatória!'),

        tipoCte: Yup.mixed()
          .oneOf(Object.values(TipoCteEnum))
          .required('Tipo de CTe é obrigatório!'),

        tipoServico: Yup.mixed()
          .oneOf(Object.values(TipoServicoEnum))
          .required('Tipo de Serviço é obrigatório!'),

        tipoTomador: Yup.mixed()
          .oneOf(
            Object.values(TipoTomadorEnum),
            'Tipo de Tomador é obrigatório!'
          )
          .required('Tipo de Tomador é obrigatório!'),

        rntrc: Yup.string()
          .nullable()
          .length(8, 'RNTRC deve conter 8 dígitos!')
          .required('RNTRC não informado!'),

        idPessoaRemetente: Yup.string()
          .nullable()
          .required('Remetente é obrigatório!'),
        idPessoaEmitente: Yup.string()
          .nullable()
          .required('Emitente é obrigatório!'),
        idPessoaDestinatario: Yup.string()
          .nullable()
          .required('Destinatario é obrigatório!'),

        idPessoaExpedidor: Yup.string()
          .nullable()
          .test({
            message:
              'Quanto Tipo de Tomador: Expedidor - Informar o Expedidor!',
            test: (value: any) => {
              return !(tipoTomador === TipoTomadorEnum.expedidor && !value);
            },
          }),

        idPessoaRecebedor: Yup.string()
          .nullable()
          .test({
            message:
              'Quanto Tipo de Tomador: Recebedor - Informar o Recebedor!',
            test: (value: any) => {
              return !(tipoTomador === TipoTomadorEnum.recebedor && !value);
            },
          }),

        idPessoaTomadorOutro: Yup.string()
          .nullable()
          .test({
            message: 'Quanto Tipo de Tomador: Outros - Informar o tomador!',
            test: (value: any) => {
              return !(tipoTomador === TipoTomadorEnum.outros && !value);
            },
          }),

        valorTotalServico: Yup.number().required('Insira ao menos um Serviço!'),

        valorCarga: Yup.number()
          .nullable()
          .required('Valor da Carga não informado!'),

        observacoes: Yup.string()
          .nullable()
          .max(2000, 'As Observações devem ter no máximo 2000 caracteres!'),

        informacoesInteresseFisco: Yup.string()
          .nullable()
          .max(
            2000,
            'As Informações do Interesse Fisco devem ter no máximo 2000 caracteres!'
          ),

        produtoPredominante: Yup.string()
          .nullable()
          .max(60, 'O Produto Predominante deve ter no máximo 60 caracteres!')
          .required('O Produto Predominante é obrigatório!'),

        conhecimentoTransporteTerceiroImpostoIcms: Yup.object({
          idOperacaoFiscal: Yup.string()
            .nullable()
            .required('CFOP do Documento é obrigatória!'),
          idSituacaoTributaria: Yup.string()
            .nullable()
            .required('CST do Documento é obrigatória!'),
          percentualReducaoBaseCalculo: Yup.number().nullable(),
          destinado: Yup.mixed()
            .oneOf(Object.values(DestinadoEnum), 'Destinado é obrigatório!')
            .required('Destinado é obrigatório!'),
        }),

        conhecimentoTransporteTerceiroImpostoIcmsFiscal: Yup.object({
          idOperacaoFiscal: Yup.string()
            .nullable()
            .required('CFOP Fiscal/Contábil é obrigatória!'),
          conhecimentoTransporteTerceiroImpostoIcmsFiscalSn: Yup.object({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST SN. é Obrigatória!'),
          }),
          conhecimentoTransporteTerceiroImpostoIcmsFiscalSnSub: Yup.object({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST SN. Excesso Sublimite é Obrigatória!'),
          }),
          conhecimentoTransporteTerceiroImpostoIcmsFiscalReal: Yup.object({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST Lucro Real é Obrigatória!'),
          }),
          conhecimentoTransporteTerceiroImpostoIcmsFiscalPresu: Yup.object({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST Lucro Presumido é Obrigatória!'),
          }),
        }),

        anulacaoChaveAcesso: Yup.string()
          .nullable()
          .test({
            message: 'A Chave de Acesso do CT-e de anulação obrigatória!',
            test: (value: any) => {
              return !(tipoCte === TipoCteEnum.anulacao && !value);
            },
          })
          .min(44, 'Chave de Acesso inválida!'),

        anulacaoDataEmissao: Yup.string()
          .nullable()
          .test({
            message: 'A Data de Emissão do CT-e de anulação é obrigatória!',
            test: (value: any) => {
              return !(tipoCte === TipoCteEnum.anulacao && !value);
            },
          }),

        substitutoChaveAcessoCteOriginal: Yup.string()
          .nullable()
          .test({
            message:
              'A Chave de Acesso do CT-e a ser Substituído (original) é obrigatória!',
            test: (value: any) => {
              if (tipoCte === TipoCteEnum.substituto && !value) {
                return false;
              }
              return true;
            },
          })
          .test({
            message: 'Chave de Acesso inválida!',
            test: (value: any) => {
              if (tipoCte === TipoCteEnum.substituto && value) {
                return value.length === 44;
              }
              return true;
            },
          }),

        substitutoChaveAcessoCteAnulacao: Yup.string()
          .nullable()
          .test({
            message: 'Chave de Acesso inválida!',
            test: (value: any) => {
              if (tipoCte === TipoCteEnum.substituto && value) {
                return value.length === 44;
              }
              return true;
            },
          }),

        substitutoChaveAcessoNfe: Yup.string()
          .nullable()
          .test({
            message: 'Chave de Acesso inválida!',
            test: (value: any) => {
              if (tipoCte === TipoCteEnum.substituto && value) {
                return value.length === 44;
              }
              return true;
            },
          }),

        substitutoChaveAcessoCteEmitTomador: Yup.string()
          .nullable()
          .test({
            message: 'Chave de Acesso inválida!',
            test: (value: any) => {
              if (tipoCte === TipoCteEnum.substituto && value) {
                return value.length === 44;
              }
              return true;
            },
          }),

        conhecimentoTransporteTerceiroTransmissao: Yup.object({
          chaveAcesso: Yup.string()
            .nullable()
            .test({
              message: 'Chave de Acesso da Autorização inválida!',
              test: (value: any) => {
                if (!value) return true;
                const valido = ValidarChaveAcesso(value);
                return valido;
              },
            }),
          numeroProtocolo: Yup.string().nullable(),
          dataAutorizacao: Yup.date().nullable(),
        }),

        conhecimentoTransporteTerceiroDestinatarioEndereco:
          makeSchemaDadosEnderecoPessoa('Destinatário', true),
        conhecimentoTransporteTerceiroDestinatario: makeSchemaDadosPessoa(
          'Destinatário',
          true
        ),

        conhecimentoTransporteTerceiroEmitenteEndereco:
          makeSchemaDadosEnderecoPessoa('Emitente', true),
        conhecimentoTransporteTerceiroEmitente: makeSchemaDadosPessoa(
          'Emitente',
          true
        ),

        conhecimentoTransporteTerceiroRemetenteEndereco:
          makeSchemaDadosEnderecoPessoa('Remetente', true),
        conhecimentoTransporteTerceiroRemetente: makeSchemaDadosPessoa(
          'Remetente',
          true
        ),

        conhecimentoTransporteTerceiroExpedidor: makeSchemaDadosPessoa(
          'Expedidor',
          data.idPessoaExpedidor
        ),
        conhecimentoTransporteTerceiroExpedidorEndereco:
          makeSchemaDadosEnderecoPessoa('Expedidor', data.idPessoaExpedidor),

        conhecimentoTransporteTerceiroRecebedor: makeSchemaDadosPessoa(
          'Recebedor',
          data.idPessoaRecebedor
        ),
        conhecimentoTransporteTerceiroRecebedorEndereco:
          makeSchemaDadosEnderecoPessoa('Recebedor', data.idPessoaRecebedor),

        conhecimentoTransporteTerceiroTomadorOutro: makeSchemaDadosPessoa(
          'Tomador',
          data.idPessoaTomadorOutro
        ),
        conhecimentoTransporteTerceiroTomadorOutroEndereco:
          makeSchemaDadosEnderecoPessoa('Tomador', data.idPessoaTomadorOutro),
      });

      await schema.validate(data, { abortEarly: false });

      if (hasErroSubstituto) return false;

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);

      if (hasErroSubstituto) {
        formRef.current?.setErrors({ ...chavesSubstitutoErrors });
      }

      const erroSubstituto = hasErroSubstituto
        ? { ...chavesSubstitutoErrors }
        : {};

      formRef.current?.setErrors({ ...errors, ...erroSubstituto });
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';

        if (regimeTributario && IsSimplesNacional(regimeTributario)) {
          data.conhecimentoTransporteTerceiroImpostoIcmsFiscal.percentualReducaoBaseCalculo =
            null;
          data.conhecimentoTransporteTerceiroImpostoIcmsFiscal.baseCalculo =
            null;
          data.conhecimentoTransporteTerceiroImpostoIcmsFiscal.aliquota = null;
          data.conhecimentoTransporteTerceiroImpostoIcmsFiscal.valor = null;
        } else if (
          formRef.current?.getFieldValue(
            'conhecimentoTransporteTerceiroImpostoIcmsFiscal.creditoConformeDocumento'
          )
        ) {
          const impostoFiscal =
            ObterImpostoFiscalConformeImpostoDocumento(formRef);
          data.conhecimentoTransporteTerceiroImpostoIcmsFiscal = {
            ...data.conhecimentoTransporteTerceiroImpostoIcmsFiscal,
            ...impostoFiscal,
          };
        }

        if (
          data.conhecimentoTransporteTerceiroImpostoIcms.emitenteSimplesNacional
        ) {
          data.conhecimentoTransporteTerceiroImpostoIcms.calcularIcms = false;
        }

        if (data.tipoCte !== TipoCteEnum.anulacao) {
          data.anulacaoChaveAcesso = null;
          data.anulacaoDataEmissao = null;
        }

        if (data.tipoCte !== TipoCteEnum.substituto) {
          data.substitutoChaveAcessoCteOriginal = null;
          data.substitutoChaveAcessoCteAnulacao = null;
          data.substitutoChaveAcessoNfe = null;
          data.substitutoChaveAcessoCteEmitTomador = null;
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        data.conhecimentoTransporteTerceiroImpostoIcms.emitenteSimplesNacional =
          data.conhecimentoTransporteTerceiroImpostoIcms
            .emitenteSimplesNacional ?? false;

        data.conhecimentoTransporteTerceiroTomadorOutro =
          data.idPessoaTomadorOutro
            ? data.conhecimentoTransporteTerceiroTomadorOutro
            : null;
        data.conhecimentoTransporteTerceiroTomadorOutroEndereco =
          data.idPessoaTomadorOutro
            ? data.conhecimentoTransporteTerceiroTomadorOutroEndereco
            : null;

        data.conhecimentoTransporteTerceiroExpedidor = data.idPessoaExpedidor
          ? data.conhecimentoTransporteTerceiroExpedidor
          : null;
        data.conhecimentoTransporteTerceiroExpedidorEndereco =
          data.idPessoaExpedidor
            ? data.conhecimentoTransporteTerceiroExpedidorEndereco
            : null;

        data.conhecimentoTransporteTerceiroRecebedor = data.idPessoaRecebedor
          ? data.conhecimentoTransporteTerceiroRecebedor
          : null;
        data.conhecimentoTransporteTerceiroRecebedorEndereco =
          data.idPessoaRecebedor
            ? data.conhecimentoTransporteTerceiroRecebedorEndereco
            : null;

        if (id) {
          await ConhecimentoTransporteTerceiroComunicador.update({
            id,
            params: data,
          });
        } else {
          const response =
            await ConhecimentoTransporteTerceiroComunicador.store({
              params: data,
            });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Conhecimento Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
      regimeTributario,
      setIdDetalheRegistro,
    ]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const id = getIdDetalheRegistro() || '';

      if (id) {
        const response =
          await ConhecimentoTransporteTerceiroDuplicarComunicador.store({
            idRegistroDuplicar: id,
          });
        setIdDetalheRegistro(response.id);

        navigate({
          pathname: `${rota.detalhes}/${response.id}`,
        });

        await formRef.current?.setDisableAll(false);
        handleCarregarDados();
      }

      ToastSucesso('Registro Duplicado e Salvo!');

      setLoading(false);
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    navigate,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
