import React, { useEffect, useState } from 'react';
import { Spring, config, animated } from 'react-spring';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import * as Icons from './icons';
import { TreeContainer } from './styles';
import TreeViewActionButton from './TreeViewActionButton';

export interface ITreeViewProps extends IPadraoProps {
  open?: boolean;
  title: string;
  onToggle?: () => void | Promise<void>;
  onClickTitle?: () => void | Promise<void>;
  actions?: React.ReactNode;
}

interface IStyles {
  [key: string]: React.CSSProperties;
}

type IconType = keyof typeof Icons;

const styles: IStyles = {
  tree: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
  },
  toggle: {
    width: '1em',
    height: '1em',
    marginRight: 10,
    verticalAlign: 'middle',
  },
  title: {
    marginLeft: 6,
    paddingLeft: '14px',
    borderLeft: '1px dashed #00000066',
  },
};

const TreeView: React.FC<ITreeViewProps> = ({
  children,
  open: propsOpen = false,
  title,
  onClickTitle,
  onToggle,
  actions,
}) => {
  const [open, setOpen] = useState(propsOpen);
  const [immediate, setImmediate] = useState(false);

  function toggle(): void {
    if (children) {
      setOpen((prevState) => !prevState);
      setImmediate(false);
    }
  }

  useEffect(() => {
    setOpen(propsOpen ?? false);
  }, [propsOpen]);

  const iconKey = `${open ? 'Minus' : 'Plus'}SquareO` as IconType;
  const Icone = Icons[iconKey];

  return (
    <TreeContainer style={{ ...styles.tree }}>
      <div className="box">
        <Icone
          className={children ? 'toggle-enabled' : ''}
          style={{ ...styles.toggle, opacity: children ? 1 : 0.3 }}
          onClick={async () => {
            toggle();
            onToggle && (await onToggle());
          }}
        />
        <span
          style={{ verticalAlign: 'middle' }}
          className="content"
          onClick={onClickTitle}
        >
          {title}
        </span>
        {actions && <TreeViewActionButton>{actions}</TreeViewActionButton>}
      </div>
      <Spring
        // native
        immediate={immediate}
        config={{ ...config.default, precision: 0.1, duration: 150 }}
        to={{
          opacity: open ? 1 : 0,
        }}
      >
        {(style: any) => (
          <animated.div
            style={{
              ...style,
              ...styles.title,
              display: `${open ? 'block' : 'none'}`,
            }}
          >
            {children}
          </animated.div>
        )}
      </Spring>
    </TreeContainer>
  );
};

export default TreeView;
