import {
  FormatarDataParaPtBrSemTimeZone,
  IEmpresaSubstitutoTributarioLista,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import InputAutoCompleteEstado from '../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import InputDateSemTimeZone from '../../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import { Container } from './styles';
import InputInscricaoEstadual from '../../../../../../../Componentes/Inputs/InputInscricaoEstadual';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';

interface IListaDetalheSubstitutoTributario {
  name: string;
}

const ListaDetalheSubstitutoTributario: React.FC<
  IListaDetalheSubstitutoTributario
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const formPrincipal = UseForm();
  const [uf, setUf] = useState('');

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IEmpresaSubstitutoTributarioLista[]>([]);
  const [listaValor, setListaValor] = useState<
    IEmpresaSubstitutoTributarioLista[]
  >([]);

  useEffect(() => {
    registerField<IEmpresaSubstitutoTributarioLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col lg={2}>
            <InputDateSemTimeZone
              name="dataInicioVigencia"
              label="Vigente a partir de:"
            />
          </Col>

          <Col lg={5}>
            <InputAutoCompleteEstado
              name="idEstado"
              nomeObjeto="estado"
              label="Estado"
              placeholder="Estado"
              onChangeItemAtual={({ itemAtual }) => {
                setUf(itemAtual?.sigla || '');
              }}
            />
          </Col>

          <Col lg={2}>
            <InputInscricaoEstadual
              label="Inscrição Estadual"
              name="inscricaoEstadual"
              placeholder="Inscrição Estadual"
              maxLength={14}
              uf={uf}
            />
          </Col>

          <Col lg={3} className="bottom">
            <button
              className="btn-padrao btn-verde btn-adicionar"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              type="button"
              onClick={handleAdicionar}
              disabled={!permissao?.inclui || formPrincipal.loading || loading}
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </button>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '17%' }}>
                <span>Vigente a parir de</span>
              </th>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Estado</span>
              </th>
              <th className="lista-texto" style={{ width: '35%' }}>
                <span>Inscrição Estadual</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr key={v4()}>
                  <td className="lista-data">
                    {FormatarDataParaPtBrSemTimeZone(item.dataInicioVigencia)}
                  </td>
                  <td className="lista-texto">{item.estado.nome}</td>
                  <td className="lista-texto">{item.inscricaoEstadual}</td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(item.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default ListaDetalheSubstitutoTributario;
