import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/movimentacoes/conta/tipo-adiantamento-enum-auto-complete';

interface IContaTipoAdiantamentoEnumAutoCompleteComunicadorIndex {
  params: any;
}

class ContaTipoAdiantamentoEnumAutoCompleteComunicador {
  public async index(
    params: IContaTipoAdiantamentoEnumAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ContaTipoAdiantamentoEnumAutoCompleteComunicador();
