import { SituacaoAberturaFechamentoCaixaEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/abertura-fechamento-caixa/situacao';

interface IAberturaFechamentoCaixaSituacaoComunicadorUpdate {
  id: string;
  params: {
    situacao: SituacaoAberturaFechamentoCaixaEnum;
  };
}

class AberturaFechamentoCaixaSituacaoComunicador {
  public async update(
    params: IAberturaFechamentoCaixaSituacaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new AberturaFechamentoCaixaSituacaoComunicador();
