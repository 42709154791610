"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var AberturaFechamentoCaixaEnum;
(function (AberturaFechamentoCaixaEnum) {
    AberturaFechamentoCaixaEnum["naoObrigaAberturaFechamentoCaixa"] = "N\u00E3o Obriga Abertura Fechamento do Caixa";
    AberturaFechamentoCaixaEnum["caixaFechadoUmaVezDia"] = "Caixa Fechado 1 vez ao Dia";
    AberturaFechamentoCaixaEnum["caixaFechadoDuasVezesDia"] = "Caixa Fechado 2 vezes ao Dia";
    AberturaFechamentoCaixaEnum["caixaFechadoTresVezesDia"] = "Caixa Fechado 3 vezes ao Dia";
    AberturaFechamentoCaixaEnum["aberturaFechamentoIndeterminado"] = "Obriga Abertura do Caixa e Fechamento Indeterminado";
})(AberturaFechamentoCaixaEnum || (AberturaFechamentoCaixaEnum = {}));
exports.default = AberturaFechamentoCaixaEnum;
