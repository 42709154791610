import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/veiculo-auto-complete-tag';

interface IVeiculoAutoCompleteTagComunicadorIndex {
  params: any;
}

class VeiculoAutoCompleteTagComunicador {
  public async index(
    params: IVeiculoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new VeiculoAutoCompleteTagComunicador();
