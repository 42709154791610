import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/ordem-servico';

interface IOrdemServicoItemOrdemComunicadorUpdate {
  id: string;
  params: {
    listaItem: Array<{
      id: string;
      ordem: number;
    }>;
  };
}

class OrdemServicoItemOrdemComunicador {
  public async update(
    params: IOrdemServicoItemOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(
      `${rota}/${params.id}/item/ordem`,
      params.params
    );

    return response.data;
  }
}

export default new OrdemServicoItemOrdemComunicador();
