import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/transferencia-entre-portadores';

interface ITransferenciaEntrePortadoresComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ITransferenciaEntrePortadoresComunicadorShow {
  id: string;
}

interface ITransferenciaEntrePortadoresComunicadorStore {
  params: any;
}

interface ITransferenciaEntrePortadoresComunicadorUpdate {
  id: string;
  params: any;
}

interface ITransferenciaEntrePortadoresComunicadorDeletar {
  id: string;
}

class TransferenciaEntrePortadoresComunicador {
  public async index(
    params: ITransferenciaEntrePortadoresComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ITransferenciaEntrePortadoresComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: ITransferenciaEntrePortadoresComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: ITransferenciaEntrePortadoresComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: ITransferenciaEntrePortadoresComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new TransferenciaEntrePortadoresComunicador();
