import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/calcular-valor-total';

interface IManifestoTransporteCalcularValorTotalComunicadorShow {
  id: string;
}

class ManifestoTransporteCalcularValorTotalComunicador {
  public async show(
    params: IManifestoTransporteCalcularValorTotalComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteCalcularValorTotalComunicador();
