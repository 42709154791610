/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormatarTelefone } from '@elogestor/util';
import TagInputBase, {
  IOnChangeListaItemAtualTagEvent,
  IOnAdicionarTagEvent,
  IOnChangeTextoTagEvent,
  IOnMoverTagEvent,
  IOnRemoverTagEvent,
} from '../TagInputBase';
import UseRegisterTagsInput from '../TagInputBase/Hooks/UseRegisterTagsInput';

interface IOnChange {
  valorAnterior: any;
}

interface IInputEmailProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualTagEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarTagEvent) => void;
  onRemoverItem?: (evento: IOnRemoverTagEvent) => void;
  onMoverItem?: (evento: IOnMoverTagEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoTagEvent) => void;
}

interface IInputTelefone {
  id?: string;
  ddd: string;
  numero: string;
}

const InputEmail: React.FC<IInputEmailProps> = ({
  name,
  label,
  onChangeListaItemAtual,
  onAdicionarItem,
  onRemoverItem,
  onMoverItem,
  onChangeTexto,
}) => {
  const handleObterChaveUnica = useCallback((item: IInputTelefone) => {
    return `${item.ddd}${item.numero}`;
  }, []);

  const { tagInputRef, error: erroUnform } = UseRegisterTagsInput({
    nome: name,
    handleObterChaveUnica,
  });

  const [error, setError] = useState(erroUnform);

  useEffect(() => {
    setError(erroUnform);
  }, [erroUnform]);

  const handleObterLabel = useCallback((item: IInputTelefone) => {
    const valorFormatado = FormatarTelefone(`${item.ddd}${item.numero}`);

    return valorFormatado;
  }, []);

  const handleFormatarValidarString = useCallback(
    (valor: string): IInputTelefone => {
      const valorSoNumeros = valor.replace(/\D/g, '');
      if (valorSoNumeros.length !== 10 && valorSoNumeros.length !== 11) {
        throw new Error('Telefone incorreto!');
      }
      return {
        ddd: valorSoNumeros.substr(0, 2),
        numero: valorSoNumeros.substr(2),
      };
    },
    []
  );

  const handleChangeTexto = useCallback(
    (evento: IOnChangeTextoTagEvent) => {
      const { input } = evento;
      if (input) {
        input.value = FormatarTelefone(input.value);
      }
      if (onChangeTexto) onChangeTexto(evento);
    },
    [onChangeTexto]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div style={{ display: 'flex' }}>
          <TagInputBase
            ref={tagInputRef}
            error={error}
            obterChaveUnica={handleObterChaveUnica}
            obterLabel={handleObterLabel}
            formatarValidarString={handleFormatarValidarString}
            onChangeListaItemAtual={onChangeListaItemAtual}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeTexto={handleChangeTexto}
          />
        </div>
      </div>
    </div>
  );
};

export default InputEmail;
