import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/engenharia/operacoes-roteiros-fabricacao-auto-complete';

interface IOperacaoRoteiroFabricacaoAutoCompleteComunicadorIndex {
  params: any;
}

class OperacaoRoteiroFabricacaoAutoCompleteComunicador {
  public async index(
    params: IOperacaoRoteiroFabricacaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OperacaoRoteiroFabricacaoAutoCompleteComunicador();
