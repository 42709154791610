import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import InputAutoCompletePessoaFinalidadeUsoCliente from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteFinalidadeUsoCliente';

const ObservacaoInternaTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 250 }}
              label="Observações Internas"
              name="observacoesInternas"
              maxLength={1500}
            />
          </Col>
        </Row>

        <Row
          style={{
            marginTop: '10pt',
          }}
        >
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompletePessoaFinalidadeUsoCliente
              label="Finalidade de Uso"
              name="idFinalidadeUsoCliente"
              nomeObjeto="finalidadeUsoCliente"
              placeholder="Finalidade de Uso"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ObservacaoInternaTab;
