import React from 'react';
import {
  Text,
  Image,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';

const Document: any = _document;
const Page: any = _page;

export interface IReciboPDF {
  pagador: string;
  valor: string;
  valorExtenso: string;
  correspondente: string | '';
  atravesDe: string | '';
  cidade: string | '';
  dataRecibo: string;
  dataExtensa: string;
  recebedor: string;
  numeroVias: number;
  logotipoUrl: string;
}

interface IImpressaoReciboPDF {
  dados: IReciboPDF;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    fontSize: 11,
    padding: '4mm',
  },

  viaRecibo: {
    height: '32%',
    border: '1 solid black',
    borderRadius: '5',
    flexDirection: 'row',
  },

  canhoto: {
    padding: '5',
    width: '25%',
    borderRight: '1 dashed black',
    height: '100%',
  },

  recibo: {
    flex: 1,
    padding: '10, 10, 5, 10',
    marginLeft: 10,
    marginRight: 10,
  },
});

const ImpressaoReciboPDF: React.FC<IImpressaoReciboPDF> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        {Array.from({ length: dados.numeroVias }).map(() => {
          return (
            <View style={styles.viaRecibo} wrap={false}>
              <View style={styles.canhoto}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: '14',
                    fontWeight: 'bold',
                  }}
                >
                  RECIBO
                </Text>
                <Text style={{ marginTop: '10' }}>
                  Recebi de{' '}
                  <Text style={{ fontWeight: 'bold' }}>{dados.pagador}</Text> a
                  quantia de{' '}
                  <Text style={{ fontWeight: 'bold' }}>{dados.valor}</Text>{' '}
                  reais
                </Text>

                <Text style={{ marginTop: '50' }}>
                  Correspondente a{' '}
                  <Text style={{ fontWeight: 'bold' }}>
                    {dados.correspondente}
                  </Text>
                </Text>

                <Text style={{ marginTop: '8' }}>
                  Através de{' '}
                  <Text style={{ fontWeight: 'bold' }}>{dados.atravesDe}</Text>
                </Text>

                <Text
                  style={{
                    marginTop: '25',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  {dados.dataRecibo}
                </Text>
                <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                  <Text
                    style={{ textAlign: 'center', borderTop: '1 solid black' }}
                  >
                    Assinatura
                  </Text>
                </View>
              </View>

              <View style={styles.recibo}>
                <View style={{ flexDirection: 'row' }}>
                  {dados.logotipoUrl && (
                    <Image
                      src={dados.logotipoUrl}
                      style={{
                        width: 120,
                        padding: '5 30 5 30',
                        borderRadius: 0,
                      }}
                    />
                  )}
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Text style={{ fontSize: 22, fontWeight: 'bold' }}>
                      RECIBO
                    </Text>
                    <Text style={{ fontSize: 18 }}>R$ {dados.valor}</Text>
                  </View>
                </View>

                <View style={{ marginTop: 10 }}>
                  <Text>
                    Recebi de{' '}
                    <Text style={{ fontWeight: 'bold' }}>{dados.pagador}</Text>,
                    a quantia de{' '}
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.valorExtenso}
                    </Text>
                  </Text>
                  <Text style={{ marginTop: 40 }}>
                    Correspondente a{' '}
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.correspondente}
                    </Text>
                  </Text>
                  <Text style={{ marginTop: 10 }}>
                    Através de{' '}
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.atravesDe}
                    </Text>
                  </Text>
                  <Text style={{ marginTop: 20, fontWeight: 'bold' }}>
                    {dados.cidade ? `${dados.cidade}, ` : ''}
                    {dados.dataExtensa}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      borderTop: '1 solid black',
                      width: 200,
                      textAlign: 'center',
                    }}
                  >
                    {dados.recebedor}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default ImpressaoReciboPDF;
