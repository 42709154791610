import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div {
    width: max-content;
    > * {
      margin: 0px 4px 0px 4px;
    }
  }
`;
