import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/pessoas/pessoa-por-estado-cidade';

interface IPessoaPorEstadoCidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PessoaPorEstadoCidadeRelatorioComunicador {
  public async index(
    params: IPessoaPorEstadoCidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PessoaPorEstadoCidadeRelatorioComunicador();
