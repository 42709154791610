import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import InputAutoCompleteProdutoServico from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import ProdutoComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoComunicador';
import ComprasTab from './ComprasTab';
import VendasTab from './VendasTab';
import EstoqueTab from './EstoqueTab';
import ComprasListaHook from './ComprasTab/Hook';
import VendasListaHook from './VendasTab/Hook';

interface ProdutosMovimentacoesLista {
  onFecharFormModal(): void;
  setLoading(loading: boolean): void;
  loading: boolean;
}

const ProdutosMovimentacoesLista: React.FC<ProdutosMovimentacoesLista> = ({
  onFecharFormModal,
  loading,
  setLoading,
}) => {
  const formPrincipal = UseForm();
  const formRefLista = useRef<IFormCiaHandles>(null);

  const [tabSelecionada, setTabSelecionada] = useState('compras');

  const handleCarregarDados = useCallback(async () => {
    try {
      setLoading(true);

      const produto = await ProdutoComunicador.show({
        id: String(formPrincipal.getIdDetalheRegistro()),
      });

      formRefLista.current?.setFieldValue('produto', produto);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [formPrincipal, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Movimentações"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefLista}>
        <Modal.Body>
          <Divisor>
            <div style={{ overflow: 'auto', marginBottom: 10 }}>
              <InputAutoCompleteProdutoServico
                label="Produto/Serviço"
                name="idProduto"
                placeholder="Produto/Serviço"
                nomeObjeto="produto"
                disabled
                permitirAdicionar={false}
                permitirConsultar={false}
              />
            </div>
          </Divisor>

          <Tabs
            id={v4()}
            defaultActiveKey="compras"
            activeKey={tabSelecionada}
            onSelect={(k) => setTabSelecionada(k || '')}
          >
            <Tab eventKey="compras" title="Compras">
              <ComprasListaHook>
                <ComprasTab />
              </ComprasListaHook>
            </Tab>

            <Tab eventKey="vendas" title="Vendas">
              <VendasListaHook>
                <VendasTab />
              </VendasListaHook>
            </Tab>

            <Tab eventKey="estoque" title="Estoque">
              <EstoqueTab />
            </Tab>
          </Tabs>
        </Modal.Body>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default ProdutosMovimentacoesLista;
