import { IReprocessamentoProducaoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ReprocessamentoProducaoImpressaoPDF from './ReprocessamentoProducaoImpressaoPDF';

interface IImpressaoReprocessamentoProducao {
  handleVisualizarPdf(dados: IReprocessamentoProducaoImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: IReprocessamentoProducaoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoReprocessamentoProducao =
  (): IImpressaoReprocessamentoProducao => {
    async function handleVisualizarPdf(
      dados: IReprocessamentoProducaoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: <ReprocessamentoProducaoImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IReprocessamentoProducaoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <ReprocessamentoProducaoImpressaoPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoReprocessamentoProducao();
