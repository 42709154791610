"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var DadosLote02TipoEnum;
(function (DadosLote02TipoEnum) {
    DadosLote02TipoEnum["umDebitoParaVariosCreditos"] = "D";
    DadosLote02TipoEnum["umCreditoParaVariosDebitos"] = "C";
    DadosLote02TipoEnum["umDebitoParaUmCredito"] = "X";
    DadosLote02TipoEnum["variosDebitosParaVariosCreditos"] = "V";
})(DadosLote02TipoEnum || (DadosLote02TipoEnum = {}));
exports.default = DadosLote02TipoEnum;
