"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DiferencialAliquotaCalculo;
const Enum_1 = require("../../Enum");
function ObterDestinadoPorCodigoSpedTipoProduto(codigoSpedTipoProduto) {
    if (codigoSpedTipoProduto === '01' ||
        codigoSpedTipoProduto === '02' ||
        codigoSpedTipoProduto === '03' ||
        codigoSpedTipoProduto === '04' ||
        codigoSpedTipoProduto === '05' ||
        codigoSpedTipoProduto === '06' ||
        codigoSpedTipoProduto === '10') {
        return Enum_1.DestinadoEnum.industrializacao;
    }
    if (codigoSpedTipoProduto === '00') {
        return Enum_1.DestinadoEnum.comercializacao;
    }
    if (codigoSpedTipoProduto === '07' ||
        codigoSpedTipoProduto === '09' ||
        codigoSpedTipoProduto === '11') {
        return Enum_1.DestinadoEnum.usoeConsumo;
    }
    if (codigoSpedTipoProduto === '08') {
        return Enum_1.DestinadoEnum.ativoImobilizado;
    }
    return Enum_1.DestinadoEnum.industrializacao;
}
function DiferencialAliquotaCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { aliquotaDiferencial: null, baseCalculo: null, valorDiferencial: null, aliquotaInterestadual: null, aliquotaInterna: null, valorIcmsOrigem: null });
    const destinado = ObterDestinadoPorCodigoSpedTipoProduto(String(dados.codigoSpedTipoProduto));
    if (destinado === Enum_1.DestinadoEnum.industrializacao ||
        destinado === Enum_1.DestinadoEnum.comercializacao) {
        const baseCalculo = Number(dados.valorProduto) +
            Number(dados.valorFrete) +
            Number(dados.valorSeguro) +
            Number(dados.valorOutrasDespesas) -
            Number(dados.valorDesconto);
        const aliquotaDiferencial = Number(dados.valorAliquotaInterna) -
            Number(dados.valorAliquotaInterestadual);
        retorno.baseCalculo = baseCalculo.Arredondar();
        retorno.aliquotaDiferencial = aliquotaDiferencial;
        retorno.aliquotaInterna = dados.valorAliquotaInterna;
        retorno.aliquotaInterestadual = dados.valorAliquotaInterestadual;
        if (aliquotaDiferencial > 0) {
            retorno.valorDiferencial = (baseCalculo *
                (aliquotaDiferencial / 100)).Arredondar();
        }
        else {
            retorno.valorDiferencial = 0;
        }
        return retorno;
    }
    if (destinado === Enum_1.DestinadoEnum.usoeConsumo ||
        destinado === Enum_1.DestinadoEnum.ativoImobilizado) {
        const baseCalculo = Number(dados.valorProduto) +
            Number(dados.valorIpi) +
            Number(dados.valorFrete) +
            Number(dados.valorSeguro) +
            Number(dados.valorOutrasDespesas) -
            Number(dados.valorDesconto);
        if (!dados.valorIcmsOrigem) {
            retorno.valorIcmsOrigem =
                baseCalculo * (Number(dados.valorAliquotaInterestadual) / 100);
        }
        else {
            retorno.valorIcmsOrigem = Number(dados.valorIcmsOrigem);
        }
        const valorParcial = ((baseCalculo - Number(retorno.valorIcmsOrigem)) /
            (1 - Number(dados.valorAliquotaInterna) / 100)) *
            (Number(dados.valorAliquotaInterna) / 100);
        retorno.aliquotaInterna = dados.valorAliquotaInterna;
        retorno.aliquotaInterestadual = dados.valorAliquotaInterestadual;
        const valorDiferencial = valorParcial -
            baseCalculo * (Number(dados.valorAliquotaInterestadual) / 100);
        retorno.baseCalculo = baseCalculo.Arredondar();
        retorno.valorDiferencial = valorDiferencial.Arredondar();
        return retorno;
    }
    return retorno;
}
