import {
  FormatarEnum,
  PisCofinsObterVisibilidadeBloqueioCamposPorCst,
  TipoCalculoEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaPisCofins from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaPisCofins';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import Select from '../../../../../../../../../../Componentes/Select';
import NotaFiscalEntradaItemImpostoCofinsComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/Imposto/NotaFiscalEntradaItemImpostoCofinsComunicador';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';

const camposEscondidosCofinsPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const camposBloqueadosCofinsPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const CofinsTab: React.FC = () => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, setLoading } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();

  const [camposEscondidosCofins, setCamposEscondidosCofins] = useState(
    camposEscondidosCofinsPadrao
  );

  const [camposBloqueadosCofins, setCamposBloqueadosCofins] = useState(
    camposBloqueadosCofinsPadrao
  );

  const calcularCofins = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoCofinsFiscalPresu.carregarCreditarCofinsConformeDocumento'
  );

  const tipoCalculoCofins = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoCofinsFiscalPresu.tipoCalculo'
  );

  const cstCofins =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoCofinsFiscalPresu.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorCofinsCst = useCallback(() => {
    const retorno = PisCofinsObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularCofins,
      calcularRetido: false,
      tipoCalculo: tipoCalculoCofins,
      cst: cstCofins,
    });

    setCamposEscondidosCofins(retorno.escondido);
    setCamposBloqueadosCofins(retorno.bloqueado);
  }, [calcularCofins, cstCofins, tipoCalculoCofins]);

  const handleObterDadosItemImpostoCofins = useCallback(
    async (creditarCofins: boolean) => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() ?? '';
        const retorno =
          await NotaFiscalEntradaItemImpostoCofinsComunicador.show({
            id,
            params: {
              creditarCofins,
            },
          });
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.tipoCalculo',
          retorno.tipoCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.aliquota',
          retorno.aliquota
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.baseCalculo',
          retorno.baseCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.quantidadeUnidade',
          retorno.quantidadeUnidade
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.valor',
          retorno.valor
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoCofinsFiscalPresu.valorUnidade',
          retorno.valorUnidade
        );
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [formPrincipal, formRefDetalhe, redirecionar, setLoading]
  );

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorCofinsCst();
  }, [handleObterVisibilidadeBloqueioCamposPorCofinsCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.id" />
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaPisCofins
              label="CST"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="notaFiscalEntradaItemImpostoCofinsFiscalPresu.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                handleObterVisibilidadeBloqueioCamposPorCofinsCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosCofins.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosCofins.calcular}
                label="Carregar e Creditar Conforme Documento"
                name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.carregarCreditarCofinsConformeDocumento"
                onChange={async (event) => {
                  handleObterVisibilidadeBloqueioCamposPorCofinsCst();
                  if (event.target.checked) {
                    await handleObterDadosItemImpostoCofins(
                      event.target.checked
                    );
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.tipoCalculo}
          >
            <Select
              disabled={camposBloqueadosCofins.tipoCalculo}
              label="Tipo de Cálculo"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                handleObterVisibilidadeBloqueioCamposPorCofinsCst();
              }}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.baseCalculo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.baseCalculo}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosCofins.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.aliquota}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.quantidadeUnidade}
              label="Quantidade Unidade"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.valorUnidade}
          >
            <InputDecimalNulavel
              disabled={camposEscondidosCofins.valorUnidade}
              label="Valor Unidade"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosCofins.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.valor}
              label="Valor"
              name="notaFiscalEntradaItemImpostoCofinsFiscalPresu.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default CofinsTab;
