import api from '../../../../../../Comum/Services/Api';

const rota = '/manufatura/engenharia/estrutura-produto/replicar-para';

interface IEstruturaProdutoCopiarDeComunicadorUpdate {
  params: any;
}

class EstruturaProdutoReplicarParaComunicador {
  public async update(
    params: IEstruturaProdutoCopiarDeComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new EstruturaProdutoReplicarParaComunicador();
