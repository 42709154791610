import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  IManifestoTransporteValoresAlterar,
  SituacaoManifestoTransporteEnum,
  TipoCalculoManifestoTransporteEnum,
  TipoDocumentoEnum,
  TipoEmitenteEnum,
  UnidadeMedidaPesoBrutoEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseParametros } from '../../../../../Hooks/ParametrosHook';
import ManifestoTransporteComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteComunicador';
import ManifestoTransporteObterDadosPadraoComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteObterDadosPadraoComunicador';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import ManifestoTransporteDuplicarComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteDuplicarComunicador';
import { UseButtonsSubHeader } from '../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const rota = UseRota();
  const { redirecionar } = UseRedirecionar();
  const parametros = UseParametros();
  const navigate = useNavigate();
  const { setDisableExcluir, setDisableSalvar } = UseButtonsSubHeader();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IManifestoTransporteValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await ManifestoTransporteComunicador.show({
          id: idEditar,
        });
        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response =
          await ManifestoTransporteObterDadosPadraoComunicador.show();

        await formRef.current?.setDataInicial({
          serie: response.serie,
          estadoEmissao: response.estadoEmissao,
          estadoInicioPercurso: response.estadoEmissao,
          numero: response.numero,

          situacao: SituacaoManifestoTransporteEnum.naoTransmitido,
          dataHoraEmissao: new Date(),
          dataHoraInicioViagem: new Date(),
          tipoEmitente: parametros.TipoEmitentePadraoManifestoTransporte,
          unidadeMedidaPesoBruto: UnidadeMedidaPesoBrutoEnum.kg,
          tipoDocumento: TipoDocumentoEnum.cte,

          tipoCalculoValorTotalMercadoriaTransportada:
            TipoCalculoManifestoTransporteEnum.calculado,
          tipoCalculoPesoBrutoTotalCarga:
            TipoCalculoManifestoTransporteEnum.calculado,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      parametros.TipoEmitentePadraoManifestoTransporte,
      redirecionar,
      refresh,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idSerie: Yup.string().nullable().required('Série é obrigatória!'),

        idEstadoEmissao: Yup.string()
          .nullable()
          .required('Estado de Emissão é obrigatório!'),

        idVeiculoTracao: Yup.string()
          .nullable()
          .required('Veiculo de Tração é obrigatório!'),

        idEstadoInicioPercurso: Yup.string()
          .nullable()
          .required('Estado de Início do Percurso é obrigatório!'),

        numero: Yup.number().required('Número é obrigatório!'),

        dataHoraEmissao: Yup.date()
          .nullable()
          .required('Data de Emissão é obrigatória!'),

        dataHoraInicioViagem: Yup.date()
          .nullable()
          .required('Data de Inicio da Viagem é obrigatória!'),

        valorTotalMercadoriaTransportada: Yup.number()
          .min(0)
          .required('Valor da Mercadoria Transportada é obrigatório!'),

        pesoBrutoTotalCarga: Yup.number()
          .min(0)
          .required('Peso Total da Carga é obrigatório!'),

        tipoEmitente: Yup.mixed()
          .oneOf(
            Object.values(TipoEmitenteEnum),
            'Tipo Emitente é obrigatório!'
          )
          .required('Tipo Emitente é obrigatório!'),

        tipoCalculoValorTotalMercadoriaTransportada: Yup.mixed()
          .oneOf(
            Object.values(TipoCalculoManifestoTransporteEnum),
            'Tipo de Calculo do Valor Total é obrigatório!'
          )
          .required('Tipo de Calculo do Valor Total é obrigatório!'),

        tipoCalculoPesoBrutoTotalCarga: Yup.mixed()
          .oneOf(
            Object.values(TipoCalculoManifestoTransporteEnum),
            'Tipo Calculo Pesso Bruto é obrigatório!'
          )
          .required('Tipo Calculo Pesso Bruto é obrigatório!'),

        unidadeMedidaPesoBruto: Yup.mixed()
          .oneOf(
            Object.values(UnidadeMedidaPesoBrutoEnum),
            'Unidade Medida Peso Bruto é obrigatória!'
          )
          .required('Unidade Medida Peso Bruto é obrigatória!'),

        listaManifestoTransporteLocalCarregamento: Yup.mixed()
          .nullable()
          .test({
            message: 'Obrigatório informar o Local de Carregamento!',
            test: (value: any) => {
              if (!value || value.listaValor.length === 0) {
                return false;
              }
              return true;
            },
          }),

        manifestoTransporteProdutoPredominante: Yup.object().shape({
          tipoCarga: Yup.mixed()
            .nullable()
            .test({
              message: 'Tipo de Carga é obrigatório!',
              test: (value: any) => {
                if (
                  !value &&
                  (data.manifestoTransporteProdutoPredominante
                    .produtoPredominante ||
                    data.manifestoTransporteProdutoPredominante
                      .cepCarregamento ||
                    data.manifestoTransporteProdutoPredominante
                      .cepDescarregamento)
                ) {
                  return false;
                }
                return true;
              },
            }),

          produtoPredominante: Yup.mixed()
            .nullable()
            .test({
              message: 'Produto Predominante é obrigatório!',
              test: (value: any) => {
                if (
                  !value &&
                  (data.manifestoTransporteProdutoPredominante.tipoCarga ||
                    data.manifestoTransporteProdutoPredominante
                      .cepCarregamento ||
                    data.manifestoTransporteProdutoPredominante
                      .cepDescarregamento)
                ) {
                  return false;
                }
                return true;
              },
            }),

          cepCarregamento: Yup.mixed()
            .nullable()
            .test({
              message: 'CEP do Carregamento é obrigatório!',
              test: (value: any) => {
                if (
                  !value &&
                  (data.manifestoTransporteProdutoPredominante.tipoCarga ||
                    data.manifestoTransporteProdutoPredominante
                      .produtoPredominante ||
                    data.manifestoTransporteProdutoPredominante
                      .cepDescarregamento)
                ) {
                  return false;
                }
                return true;
              },
            }),

          cepDescarregamento: Yup.mixed()
            .nullable()
            .test({
              message: 'CEP do Descarregamento é obrigatório!',
              test: (value: any) => {
                if (
                  !value &&
                  (data.manifestoTransporteProdutoPredominante.tipoCarga ||
                    data.manifestoTransporteProdutoPredominante
                      .produtoPredominante ||
                    data.manifestoTransporteProdutoPredominante.cepCarregamento)
                ) {
                  return false;
                }
                return true;
              },
            }),
        }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await ManifestoTransporteComunicador.update({ id, params: data });
        } else {
          const response = await ManifestoTransporteComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const id = getIdDetalheRegistro() || 0;

      if (id) {
        const response = await ManifestoTransporteDuplicarComunicador.store({
          idRegistroParaDuplicar: id,
          params: {
            duplicarInformacoesSeguro:
              parametros.DuplicarInformacoesSeguroManifestoTransporte,
          },
        });
        setIdDetalheRegistro(response.id);

        navigate('/');
        navigate({
          pathname: `${rota.detalhes}/${response.id}`,
        });

        await formRef.current?.setDisableAll(false);
        handleCarregarDados();
        ToastSucesso('Registro Duplicado e Salvo!');
      }

      setLoading(false);
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    navigate,
    parametros.DuplicarInformacoesSeguroManifestoTransporte,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  const situacao = formRef.current?.getFieldValue('situacao');
  useEffect(() => {
    const situacaoBloquearCampos =
      situacao === SituacaoManifestoTransporteEnum.autorizado ||
      situacao === SituacaoManifestoTransporteEnum.encerrado ||
      situacao === SituacaoManifestoTransporteEnum.cancelado;

    setDisableExcluir(situacaoBloquearCampos);
    setDisableSalvar(situacaoBloquearCampos);

    if (situacaoBloquearCampos) {
      formRef.current?.setDisableAll(true);
    }
  }, [setDisableExcluir, setDisableSalvar, situacao]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
