import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque-saldo-proprio-terceiro-por-local-estoque-lote';

interface IEstoqueSaldoProprioTerceiroPorLocalEstoqueLoteComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoProprioTerceiroPorLocalEstoqueLoteComunicador {
  public async index(
    params: IEstoqueSaldoProprioTerceiroPorLocalEstoqueLoteComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoProprioTerceiroPorLocalEstoqueLoteComunicador();
