import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-pedido-venda/importar';

interface INotaFiscalSaidaImportacaoPedidoVendaImportarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoPedidoVendaImportarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoPedidoVendaImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoPedidoVendaImportarComunicador();
