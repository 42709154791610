"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoPedidoCompraEnum;
(function (SituacaoPedidoCompraEnum) {
    SituacaoPedidoCompraEnum["pendente"] = "Pendente";
    SituacaoPedidoCompraEnum["aprovado"] = "Aprovado";
    SituacaoPedidoCompraEnum["cancelado"] = "Cancelado";
    SituacaoPedidoCompraEnum["aprovadoPendente"] = "Pendente e Aprovado";
})(SituacaoPedidoCompraEnum || (SituacaoPedidoCompraEnum = {}));
exports.default = SituacaoPedidoCompraEnum;
