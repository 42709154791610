"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCarroceriaVeiculoEnum;
(function (TipoCarroceriaVeiculoEnum) {
    TipoCarroceriaVeiculoEnum["naoAplicavel"] = "0 - N\u00E3o Aplicavel";
    TipoCarroceriaVeiculoEnum["aberta"] = "1 - Aberta";
    TipoCarroceriaVeiculoEnum["fachadaBau"] = "2 - Fechada Ba\u00FA";
    TipoCarroceriaVeiculoEnum["graneleira"] = "3 - Graneleira";
    TipoCarroceriaVeiculoEnum["portaContainer"] = "4 - Porta Container";
    TipoCarroceriaVeiculoEnum["slider"] = "5 - Slider";
})(TipoCarroceriaVeiculoEnum || (TipoCarroceriaVeiculoEnum = {}));
exports.default = TipoCarroceriaVeiculoEnum;
