/* eslint-disable jsx-a11y/label-has-associated-control */
import { UseFieldCia } from '@elogestor/unformcia';
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { DefaultInput, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputDateSemDiaProps {
  valorAnterior: string | null;
}

export interface IInputDateSemDiaRef {
  inputMesRef: React.RefObject<HTMLInputElement | null>;
  inputAnoRef: React.RefObject<HTMLInputElement | null>;
}

interface IInputDateSemDiaProps {
  labelMes: string;
  labelAno: string;
  name: string;
  error?: string;

  onChangeMes?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateSemDiaProps
  ) => void | Promise<void>;
  onChangeAno?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateSemDiaProps
  ) => void | Promise<void>;
}

const InputDateSemDia: React.ForwardRefRenderFunction<
  IInputDateSemDiaRef,
  IInputDateSemDiaProps
> = (
  { labelMes, labelAno, name, error: errorPai, onChangeMes, onChangeAno },
  ref
) => {
  const { fieldName, error, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const inputMesRef = useRef<HTMLInputElement | null>(null);
  const inputAnoRef = useRef<HTMLInputElement | null>(null);
  const valorAnterior = useRef<string | null>('');

  const [erro, setErro] = useState(error);

  useEffect(() => {
    if (inputMesRef.current?.value && inputAnoRef.current?.value) {
      valorAnterior.current = `${inputMesRef.current.value}/${inputAnoRef.current.value}`;
    }
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(error);
  }, [error]);

  useEffect(() => {
    setErro(errorPai);
  }, [errorPai]);

  useEffect(() => {
    registerField<string | null>({
      name: fieldName,
      ref: {
        inputMesRef: inputMesRef.current,
        inputAnoRef: inputAnoRef.current,
      },

      getValue() {
        if (inputMesRef.current?.value && inputAnoRef.current?.value) {
          return `${inputMesRef.current.value}/${inputAnoRef.current.value}`;
        }
        return null;
      },

      setValue(_, novoValor) {
        setErro('');
        valorAnterior.current = this.getValue(_);
        if (!inputMesRef.current || !inputAnoRef.current) return;

        if (!novoValor) {
          inputMesRef.current.value = '';
          inputAnoRef.current.value = '';
          return;
        }
        const [mes, ano] = novoValor.split('/');

        inputMesRef.current.value = mes;
        inputAnoRef.current.value = ano;
      },

      setSemExecutarEvento(_, novoValor) {
        setErro('');
        valorAnterior.current = this.getValue(_);
        if (!inputMesRef.current || !inputAnoRef.current) return;

        if (!novoValor) {
          inputMesRef.current.value = '';
          inputAnoRef.current.value = '';
          return;
        }
        const [mes, ano] = novoValor.split('-');

        inputMesRef.current.value = mes;
        inputAnoRef.current.value = ano;
      },

      clearValue(_, valorInicial = null) {
        setErro('');
        this.setValue(_, valorInicial || '');
      },

      validarSeAlterou(_, valorInicial = null) {
        const valor = this.getValue(_);
        return valor !== valorInicial;
      },

      setDisabled(valor) {
        if (!inputMesRef.current || !inputAnoRef.current) return;

        inputMesRef.current.disabled = valor;
        inputAnoRef.current.disabled = valor;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => {
    return {
      inputMesRef,
      inputAnoRef,
    };
  });

  const handleInputFocus = useCallback(() => {
    if (inputMesRef.current?.value && inputAnoRef.current?.value) {
      valorAnterior.current = `${inputMesRef.current.value}/${inputAnoRef.current.value}`;
    }
  }, []);

  const handleInputChange = useCallback(() => {
    setErro('');

    const mes = inputMesRef.current?.value;
    const ano = inputAnoRef.current?.value;

    if (
      !mes ||
      !ano ||
      Number(mes) > 12 ||
      Number(mes) < 1 ||
      ano.length !== 4
    ) {
      setErro('Périodo Invalido!');
    }
  }, []);

  return (
    <Row>
      <Col sm={6} xs={6}>
        {labelMes && <label>{labelMes}</label>}
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={false}
          $isFilled={false}
          type="text"
          onFocus={handleInputFocus}
          onChange={(event) => {
            const valor = event.target.value.replace(/\D+/g, '');
            event.target.value = valor || '';
            handleInputChange();

            if (onChangeMes)
              onChangeMes(event, { valorAnterior: valorAnterior.current });
          }}
          ref={inputMesRef}
          maxLength={2}
        />
      </Col>
      <Col sm={6} xs={6}>
        {labelAno && <label>{labelAno}</label>}
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={false}
          $isFilled={false}
          type="text"
          onFocus={handleInputFocus}
          onChange={(event) => {
            const valor = event.target.value.replace(/\D+/g, '');
            event.target.value = valor || '';
            handleInputChange();

            if (onChangeAno)
              onChangeAno(event, { valorAnterior: valorAnterior.current });
          }}
          ref={inputAnoRef}
          maxLength={4}
        />
      </Col>

      {erro && <SpanErro>{erro}</SpanErro>}
    </Row>
  );
};

export default forwardRef(InputDateSemDia);
