import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/obter-notas-utilizadas';

interface IConhecimentoTransporteObterNotasUtilizadasComunicadorIndex {
  chaveAcesso: string;
}

class ConhecimentoTransporteObterNotasUtilizadasComunicador {
  public async index(
    params: IConhecimentoTransporteObterNotasUtilizadasComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });
    return response.data;
  }
}

export default new ConhecimentoTransporteObterNotasUtilizadasComunicador();
