import {
  FormatarDataHoraParaPtBr,
  IDevolucaoNotasEntradaPorItensRelatorio,
  IDevolucaoNotasEntradaPorItensCabecalhoRelatorio,
  IDevolucaoNotasEntradaFiltroRelatorioFrontend,
  IDevolucaoNotasEntradaFiltroRelatorioBackend,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import DevolucaoNotasEntradasPorItensRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/DevolucaoNotasEntradas/DevolucaoNotasEntradasPorItensRelatorio/DevolucaoNotasEntradasPorItensRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import DevolucaoNotasEntradaPorItensRelatorioPDF from './DevolucaoNotasEntradaPorItensRelatorioPDF';
import DevolucaoNotasEntradaPorItensRelatorioPlanilha from './DevolucaoNotasEntradaPorItensRelatorioPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const NotasEntradaPorItensRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IDevolucaoNotasEntradaPorItensRelatorio | null> => {
    const filtroBack: IDevolucaoNotasEntradaFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,
      movimentarEstoqueFiscal: filtros.movimentarEstoqueFiscal,
      numeroNfEntrada: filtros.numeroNfEntrada,
      numeroNfSaida: filtros.numeroNfSaida,
      serieNfEntrada: filtros.serieNfEntrada,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao =
        filtros.listaSituacao.listaValor.map((value) => value.descricao) || [];
    }

    if (filtros?.listaClienteFornecedor) {
      filtroBack.listaIdClienteFornecedor =
        filtros.listaClienteFornecedor.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }
    const response =
      await DevolucaoNotasEntradasPorItensRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });
    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno = response as IDevolucaoNotasEntradaPorItensRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const listaItens = dados.listaItens.map((item) => {
          return {
            ...item,
            quantidade: Number(item.quantidade).FormatarParaPtBr(),
            saldo: Number(item.saldo).FormatarParaPtBr(),
            quantidadeDevolvida: Number(
              item.quantidadeDevolvida
            ).FormatarParaPtBr(),
          };
        });

        return {
          ...dados,
          listaItens,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend,
    cabecalho: IDevolucaoNotasEntradaPorItensCabecalhoRelatorio
  ): IDevolucaoNotasEntradaPorItensCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroEmissaoDataInicial = '--/--/--';
      cabecalho.filtroEmissaoDataFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroEmissaoDataInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroEmissaoDataFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (filtros.movimentarEstoqueFiscal) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroMovimentarEstoqueFiscal =
        filtros.movimentarEstoqueFiscal === 'true' ? 'Sim' : 'Não';
    }

    if (filtros.numeroNfEntrada) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroNumeroNfEntrada = filtros.numeroNfEntrada;
    }
    if (filtros.numeroNfSaida) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroNumeroNfSaida = filtros.numeroNfSaida;
    }
    if (filtros.serieNfEntrada) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSerieNfEntrada = filtros.serieNfEntrada;
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaClienteFornecedor &&
      filtros.listaClienteFornecedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtrosClienteFornecedore =
        filtros.listaClienteFornecedor.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: DevolucaoNotasEntradaPorItensRelatorioPlanilha,
      nomeArquivo: 'DevolucaoNotasEntradaPorItens',
      valores: dados.listaDados,
      unwind: ['listaItens'],
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await Planilha({
      campos: DevolucaoNotasEntradaPorItensRelatorioPlanilha,
      nomeArquivo: 'DevolucaoNotasEntradaPorItens',
      valores: dados.listaDados as any,
      unwind: ['listaItens'],
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DevolucaoNotasEntradaPorItensRelatorioPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IDevolucaoNotasEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DevolucaoNotasEntradaPorItensRelatorioPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default NotasEntradaPorItensRelatorio();
