import api from '../../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico/impressao/paisagem-sem-foto-produto';

interface IOrdemServicoPaisagemSemFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class OrdemServicoPaisagemSemFotoProdutoImpressaoComunicador {
  public async show(
    params: IOrdemServicoPaisagemSemFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoPaisagemSemFotoProdutoImpressaoComunicador();
