import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/cliente-auto-complete';

interface IPessoaClienteAutoCompleteComunicadorIndex {
  params: any;
}

class PessoaClienteAutoCompleteComunicador {
  public async index(
    params: IPessoaClienteAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaClienteAutoCompleteComunicador();
