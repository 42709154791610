import { INotaFiscalConsumidorImpressao } from '@elogestor/util';
import React from 'react';
import ImprimirDiretoPDF from '../../../../Util/Relatorios/ImprimirDiretoPDF';
import PDF from '../../../../Util/Relatorios/PDF';
import NotaFiscalConsumidorImpressaoPDF from './NotaFiscalConsumidorImpressaoPDF';

interface IImpressaoNotaFiscalConsumidor {
  handleVisualizarPdf(data: INotaFiscalConsumidorImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: INotaFiscalConsumidorImpressao
  ): Promise<Blob | undefined>;
  handleImprimirDireto(dados: INotaFiscalConsumidorImpressao): Promise<void>;
}

const ImpressaoNotaFiscalConsumidor = (): IImpressaoNotaFiscalConsumidor => {
  async function handleVisualizarPdf(
    dados: INotaFiscalConsumidorImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <NotaFiscalConsumidorImpressaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: INotaFiscalConsumidorImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <NotaFiscalConsumidorImpressaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  async function handleImprimirDireto(
    dados: INotaFiscalConsumidorImpressao
  ): Promise<void> {
    if (!dados) return;

    const relatorio = await PDF({
      documento: <NotaFiscalConsumidorImpressaoPDF dados={dados} />,
    });

    const blob = relatorio.GetBlob();

    await ImprimirDiretoPDF({ blob });
  }

  return { handleVisualizarPdf, handleObterBlobPdf, handleImprimirDireto };
};

export default ImpressaoNotaFiscalConsumidor();
