/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  INotaFiscalSaidaValoresAlterar,
  FormatarEnum,
  FinalidadeEmissaoEnum,
  IndicadorPresencaPadraoEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import TotalTab from '../TotaisTab/index';
import TransporteTab from '../TransporteTab/index';
import InformacaoComercialTab from '../InformacaoComercialTab/index';
import InformacaoContribuinteTab from '../InformacaoContribuinteTab/index';
import ObservacaoInternaTab from '../ObservacaoInternaTab/index';
import ItemTab from '../ItemTab/index';
import InputAutoCompleteCliente, {
  IOnChangeItemAtualAutoCompleteClienteEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import Select from '../../../../../../Componentes/Select';
import PessoaTab from '../PessoaTab';
import { UseNotaFiscalSaida } from '../Hook/NotaFiscalSaidaHook';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import AutorizacaoTab from '../AutorizacaoTab';
import InputAutoCompleteSerie from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import InformacaoFiscoTab from '../InformacaoFiscoTab/index';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';
import NotaFiscalSaidaObterSerieComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterSerieComunicador';
import ExportacaoTab from '../ExportacaoTab';
import { UseSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/SubHeaderContext';
import NotaFiscalSaidaObterNumeroComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterNumeroComunicador';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IConfirmacaoContextData from '../../../../../../Componentes/Interface/IConfirmacaoContextData';
import ConfirmacaoValores from '../../../../Pessoas/PessoaValoresEmAberto/ConfirmacaoValores';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';

type IProps = IMain<INotaFiscalSaidaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { situacaoBloquearCampos, situacaoBloquearCamposContaRevertida } =
    UseSituacaoBloquearCampos();
  const { atualizarDadosClienteConformeCadastro, obterDadosPadraoCliente } =
    UseNotaFiscalSaida();
  const { setRegimeTributarioPorData } = UseRegimeTributario();
  const { abrirJanela } = UseAlerta();
  const [showConfirmacaoValores, setShowConfirmacaoValores] = useState(<></>);
  const parametros = UseParametros();

  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const { subHeaderButtonsHabilitado } = UseSubHeader();
  const idDetalheRegistro = getIdDetalheRegistro();

  const [tabSelecionada, setTabSelecionada] = useState('itens');
  const [idClienteAnterior, setIdClienteAnterior] = useState('');

  const finalidadeEmissao = formRef.current?.getFieldValue('finalidadeEmissao');
  const serie = formRef.current?.getFieldValueNomeObjeto('serie');

  const uf = formRef.current?.getFieldValue(
    'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
  );

  const vendaPdv = formRef.current?.getFieldValue('vendaPdv');

  useEffect(() => {
    if (vendaPdv) {
      subHeaderButtonsHabilitado.DuplicarSalvarHabilitado = false;
    }
  }, [subHeaderButtonsHabilitado, vendaPdv]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idEditar,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarDadosClienteConformeCadastro = useCallback(async () => {
    atualizarDadosClienteConformeCadastro();
  }, [atualizarDadosClienteConformeCadastro]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    obterDadosPadraoCliente();
  }, [obterDadosPadraoCliente]);

  const handleOnAlterarDataHoraEmissao = useCallback(async () => {
    try {
      setLoading(true);

      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');

      await setRegimeTributarioPorData(new Date(dataHoraEmissao));

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading, setRegimeTributarioPorData]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaNotaFiscalSaidaItem = formRef.current?.getFieldValue(
      'listaNotaFiscalSaidaItem'
    );
    if (listaNotaFiscalSaidaItem && listaNotaFiscalSaidaItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração do Cliente ou Indicador de Presença, as
            informações tributárias e valores podem estar incorretos. Salve para
            atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  const handleAtualizarSerie = useCallback(
    async (finalidadeEmissaoAlterada: FinalidadeEmissaoEnum) => {
      try {
        setLoading(true);

        const response = await NotaFiscalSaidaObterSerieComunicador.show({
          finalidadeEmissao: finalidadeEmissaoAlterada,
        });

        formRef.current?.setFieldValue('serie', response);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, setLoading]
  );

  const handleAtualizarNumero = useCallback(
    async (idSerie?: string) => {
      try {
        setLoading(true);

        const idNotaFiscalSaida = getIdDetalheRegistro();

        if (idNotaFiscalSaida) {
          if (!idSerie) {
            formRef.current.setFieldValue('numero', 0);
          } else {
            const response = await NotaFiscalSaidaObterNumeroComunicador.show({
              idNotaFiscalSaida,
              idSerie,
            });

            formRef.current.setFieldValue('numero', response);
          }
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, getIdDetalheRegistro, setLoading]
  );

  const handleConfirmacaoValores = useCallback(
    async (idCliente: string): Promise<boolean> => {
      return new Promise((resolve) => {
        setShowConfirmacaoValores(
          <ConfirmacaoValores
            idCliente={idCliente}
            loading={loading}
            setLoading={setLoading}
            onResponse={(resposta: boolean) => resolve(resposta)}
          />
        );
      });
    },
    [loading, setLoading]
  );

  const handleValidarCliente = async (
    event: IOnChangeItemAtualAutoCompleteClienteEvent
  ): Promise<boolean> => {
    if (event.itemAtual) {
      if (
        parametros.ExibirMensagemDeContasEConsignadosEmAberto &&
        event.itemAtual.id !==
          parametros.ClienteConsumidorFinalFrenteCaixaPdv?.id
      ) {
        const response = await handleConfirmacaoValores(event.itemAtual.id);
        setShowConfirmacaoValores(<></>);
        if (!response)
          formRef.current.setFieldValue('notaFiscalSaidaPessoa.pessoa', null);
        return response;
      }
      return true;
    }
    return false;
  };

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <InputHiddenHtml name="atualizarTabelaPrecoItens" />
        <InputHiddenHtml name="financeiroConferido" />
        <InputHiddenHtml name="valorFinanceiro" />
        <InputHiddenHtml name="listaConta" />
        <InputHiddenHtml name="vendaPdv" />
        <InputHiddenHtml
          name="terminalCaixa"
          validarSeMudou={() => {
            return false;
          }}
        />
        <InputHiddenHtml
          name="usuarioAbertura"
          validarSeMudou={() => {
            return false;
          }}
        />

        <Divisor>
          <Row>
            <InputHiddenHtml name="idModeloDocumento" />

            <Col lg={2} md={6} sm={12}>
              <InputAutoCompleteSerie
                label="Série"
                name="idSerie"
                placeholder="Série"
                nomeObjeto="serie"
                listaTipoDocumentoFiscal={[
                  ModeloDocumentoTipoEnum.notaFiscal,
                  ModeloDocumentoTipoEnum.notaFiscalServico,
                ]}
                utilizavelNotaFiscalSaida
                utilizavelNotaFiscalAjuste={
                  finalidadeEmissao === FinalidadeEmissaoEnum.ajuste
                    ? true
                    : undefined
                }
                utilizavelNotaFiscalComplementar={
                  finalidadeEmissao === FinalidadeEmissaoEnum.complemento
                    ? true
                    : undefined
                }
                onChangeItemAtual={(event) => {
                  if (event.itemAtual?.modeloDocumento) {
                    formRef.current?.setFieldValue(
                      'idModeloDocumento',
                      event.itemAtual?.modeloDocumento.id
                    );
                  }

                  refresh();
                  handleAtualizarNumero(event.itemAtual?.id);
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled={serie ? serie.incrementarAutomaticamente : true}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Emissão"
                name="dataHoraEmissao"
                onChange={handleOnAlterarDataHoraEmissao}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Saída"
                name="dataHoraSaida"
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let emTransmissao = false;
                  let autorizada = false;
                  let denegada = false;
                  let cancelada = false;
                  let rejeitada = false;

                  if (situacao.includes('Em Transmissão')) emTransmissao = true;
                  if (situacao.includes('Autorizada')) autorizada = true;
                  if (situacao.includes('Denegada')) denegada = true;
                  if (situacao.includes('Cancelada')) cancelada = true;
                  if (situacao.includes('Rejeitada')) rejeitada = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{
                        fontWeight: 'bold',
                        alignItems: 'flex-start',
                        color: emTransmissao
                          ? 'gray'
                          : autorizada
                            ? 'green'
                            : denegada
                              ? 'blue'
                              : cancelada
                                ? 'red'
                                : rejeitada
                                  ? 'goldenrod'
                                  : 'black',
                      }}
                    >
                      {situacao}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={7} md={12} sm={12}>
              <InputAutoCompleteCliente
                ref={inputRefFocus}
                label="Cliente"
                name="notaFiscalSaidaPessoa.idPessoa"
                nomeObjeto="notaFiscalSaidaPessoa.pessoa"
                placeholder="Cliente"
                informacaoAdicional
                onChangeItemAtual={(event) => {
                  if (event.itemAtual) {
                    setIdClienteAnterior(event.itemAtual.id);
                  } else {
                    setIdClienteAnterior('');
                  }
                }}
                onChangeItemAtualAposCarregarSemClear={async (event) => {
                  refresh();
                  if (
                    event.itemAtual &&
                    event.itemAtual.id !== idClienteAnterior
                  ) {
                    if (await handleValidarCliente(event)) {
                      handleAtualizarDadosClienteConformeCadastro();
                      handleExibirMensagemAtualizacao();
                      formRef.current?.setFieldValue(
                        'atualizarTabelaPrecoItens',
                        true
                      );
                      handleObterDadosPadraoCliente();
                    }
                  }
                }}
                disabled={
                  situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Finalidade Emissão"
                name="finalidadeEmissao"
                options={FormatarEnum({
                  enumObj: FinalidadeEmissaoEnum,
                  nullavel: true,
                })}
                disabled={situacaoBloquearCampos}
                onChange={async (event) => {
                  refresh();
                  await handleAtualizarSerie(
                    event.currentTarget.value as FinalidadeEmissaoEnum
                  );
                }}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Indicador de Presença"
                name="indicadorPresenca"
                options={FormatarEnum({
                  enumObj: IndicadorPresencaPadraoEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                  handleExibirMensagemAtualizacao();
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="itens" title="Itens">
            <ItemTab />
          </Tab>

          <Tab eventKey="totais" title="Totais">
            <TotalTab />
          </Tab>

          <Tab eventKey="transporte" title="Transporte">
            <TransporteTab />
          </Tab>

          <Tab eventKey="pessoa" title="Cliente">
            <PessoaTab />
          </Tab>

          <Tab eventKey="informacoesComerciais" title="Inf. Comerciais">
            <InformacaoComercialTab />
          </Tab>

          <Tab eventKey="informacoesContribuinte" title="Inf. Contribuinte">
            <InformacaoContribuinteTab />
          </Tab>

          <Tab eventKey="informacoesFisco" title="Inf. Fisco">
            <InformacaoFiscoTab />
          </Tab>

          <Tab eventKey="observacoesInternas" title="Obs. Internas">
            <ObservacaoInternaTab />
          </Tab>

          <Tab
            eventKey="exportacao"
            title="Exportação"
            tabClassName={uf === 'EX' ? '' : 'hidden'}
          >
            <ExportacaoTab />
          </Tab>

          <Tab eventKey="autorizacao" title="Autorização">
            <AutorizacaoTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>

      {showConfirmacaoValores}
    </FormCia>
  );
};

export default Main;
