import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IOrcamentoPorDataEmissaoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IOrcamentoPorDataEmissaoPDF {
  dados: IOrcamentoPorDataEmissaoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const OrcamentoPorDataEmissaoPDF: React.FC<IOrcamentoPorDataEmissaoPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroSituacaoOrcamentoPedidoVenda && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Situação do Pedido: '}
                      </Text>
                      {dados.cabecalho.filtroSituacaoOrcamentoPedidoVenda}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroOperacaoFiscal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Operacao Fiscal: '}
                      </Text>
                      {dados.cabecalho.filtroOperacaoFiscal}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '12%', alignItems: 'flex-end' }}>
              <Text>Mod. Documento</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '4%' }}>
              <Text>Hora</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%' }}>
              <Text>Situação Item</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '33%', alignItems: 'flex-start' }}>
              <Text>Cliente</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Subtotal</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>Desconto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Total</Text>
            </View>
          </View>

          <View style={{ borderBottom: '1pt solid black' }}>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View>
                      <Text
                        style={{
                          borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                          flexDirection: 'row',
                          paddingTop: 5,
                          fontWeight: 'bold',
                          marginLeft: 2,
                          fontSize: 9,
                        }}
                      >
                        Data de Emissão: {dado.chave}
                      </Text>
                    </View>

                    <View>
                      {dado.listaOrcamentoItem.map(
                        (orcamentoItem, orcamentoItemIndex) => {
                          return (
                            <View key={String(orcamentoItemIndex)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor:
                                    orcamentoItemIndex % 2 === 0
                                      ? '#E4E4E4'
                                      : '#fff',
                                }}
                              >
                                <View
                                  style={{ width: '12%', alignItems: 'center' }}
                                >
                                  <Text>
                                    {orcamentoItem.orcamentoModeloDocumento}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{ width: '6%', alignItems: 'center' }}
                                >
                                  <Text>{orcamentoItem.orcamentoNumero}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '4%' }}>
                                  <Text>
                                    {String(orcamentoItem.orcamentoHoraEmissao)}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '10%' }}>
                                  <Text>
                                    {String(
                                      orcamentoItem.orcamentoSituacaoItem
                                    )}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '33%' }}>
                                  <Text>
                                    {`${orcamentoItem.clienteCodigo} - ${orcamentoItem.clienteNomeRazaoSocial}`}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '10%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{orcamentoItem.valorSubtotal}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '8%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{orcamentoItem.valorDesconto}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '10%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{orcamentoItem.valorTotal}</Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}

                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 9,
                        }}
                      >
                        <View style={{ width: '60%' }}>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                          >
                            Total Por Data de Emissão: {dado.valores.total}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '8%', alignItems: 'flex-end' }}>
                          <Text style={{ fontWeight: 'bold' }} />
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '10%', alignItems: 'flex-end' }}>
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioValorSubtotal}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '8%' }} />
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '10%', alignItems: 'flex-end' }}>
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioValorTotal}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            >
              <View style={{ width: '60%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                    marginLeft: 2,
                  }}
                >
                  Total Geral: {dados.totalGeral}
                </Text>
              </View>
              <View style={{ width: '1%' }} />
              <View style={{ width: '8%', alignItems: 'flex-end' }} />
              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorSubtotal}
                </Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '8%' }} />
              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorTotal}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrcamentoPorDataEmissaoPDF;
