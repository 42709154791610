import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/regra-escolha-sisdevin';

interface IRegraEscolhaSisdevinComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaSisdevinComunicadorShow {
  id: string;
}

interface IRegraEscolhaSisdevinComunicadorStore {
  params: any;
}

interface IRegraEscolhaSisdevinComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaSisdevinComunicadorDeletar {
  id: string;
}

class RegraEscolhaSisdevinComunicador {
  public async index(
    params: IRegraEscolhaSisdevinComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaSisdevinComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaSisdevinComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaSisdevinComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaSisdevinComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaSisdevinComunicador();
