/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Container, Col, Row, Table } from 'react-bootstrap';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraCompletaParaPtBr,
  INotaFiscalEntradaCartaCorrecaoLista,
  SituacaoEventoEnum,
  SituacaoNotaFiscalEntradaEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import CartaCorrecaoDetalhe from '../Detalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Acoes from '../../../../../../../../Componentes/Lista/Acoes';
import ListaProvider from '../../../../../../../../Componentes/Lista/ListaProvider';
import NotaFiscalEntradaTransmissaoEventoLogComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Transmissao/Evento/Log/Comunicador/NotaFiscalEntradaTransmissaoEventoLogComunicador';
import NotaFiscalEntradaTransmissaoEventoComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Transmissao/Evento/NotaFiscalEntradaTransmissaoEventoComunicador';

interface ICartaCorrecaoListaModal {
  onFecharFormModal(): void;
}

const CartaCorrecaoLista: React.FC<ICartaCorrecaoListaModal> = ({
  onFecharFormModal,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const {
    formRefLista,
    setLoading,
    loading,
    handleSubmit,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
  } = UseListaDetalheForm();

  const [listaValor, setListaValor] = useState<
    INotaFiscalEntradaCartaCorrecaoLista[]
  >([]);
  const [showDetalheCartaCorrecao, setShowDetalheCartaCorrecao] =
    useState(false);

  const idDetalheRegistro = getIdDetalheRegistro();
  const idPrincipal = formPrincipal.getIdDetalheRegistro();

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      formPrincipal.setLoading(true);

      const response =
        await NotaFiscalEntradaTransmissaoEventoComunicador.index({
          params: {
            idValor: String(idPrincipal),
          },
        });

      if (!response) return;

      setListaValor(response);

      formPrincipal.setLoading(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      formPrincipal.setLoading(false);
      setLoading(false);
    }
  }, [formPrincipal, idPrincipal, setLoading]);

  const handleAdicionarCartaCorrecao = useCallback(async () => {
    setShowDetalheCartaCorrecao(true);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleCartaCorrecaoFecharFormModal = useCallback(async () => {
    setShowDetalheCartaCorrecao(false);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleCartaCorrecaoSalvarFormModal = useCallback(async () => {
    const data = formRefLista.current?.getData();

    const { erro } = await handleSubmit(data, formRefLista.current);

    if (!erro) {
      await handleCarregarDados();
      setShowDetalheCartaCorrecao(false);
      setIdDetalheRegistro('');
    }
  }, [formRefLista, handleCarregarDados, handleSubmit, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (index: string): Promise<void> => {
      setIdDetalheRegistro(index);

      setShowDetalheCartaCorrecao(true);
    },
    [setIdDetalheRegistro]
  );

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDetalheRegistro]);

  return (
    <JanelaDetalhe
      titulo="Carta Correção"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefLista}>
        <Modal.Body>
          <Divisor>
            <Container>
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <BtnPadraoButton
                    type="button"
                    onClick={handleAdicionarCartaCorrecao}
                    className="btn-padrao btn-verde-claro btn-adicionar"
                    disabled={
                      !permissoes.SuprimentosComprasNotaFiscalEntrada?.inclui ||
                      formPrincipal.loading ||
                      situacao === SituacaoNotaFiscalEntradaEnum.cancelada
                    }
                    style={{ width: '60%' }}
                  >
                    <TextoLoading loading={formPrincipal.loading}>
                      <IoMdAdd />
                      <span style={{ marginLeft: 10 }}>Adicionar</span>
                    </TextoLoading>
                  </BtnPadraoButton>
                </Col>
              </Row>

              <ListaProvider>
                <Tabela style={{ overflow: 'auto' }}>
                  <Table
                    striped
                    hover
                    bordered
                    variant="light"
                    style={{ minHeight: 135 }}
                  >
                    <thead>
                      <tr>
                        <th className="lista-valor" style={{ width: '5%' }}>
                          <span>Seq.</span>
                        </th>
                        <th className="lista-texto" style={{ width: '50%' }}>
                          <span>Descrição da Correção</span>
                        </th>
                        <th className="lista-texto" style={{ width: '20%' }}>
                          <span>Situação</span>
                        </th>
                        <th className="lista-data" style={{ width: '35%' }}>
                          <span>Data Autorização</span>
                        </th>
                        <th className="lista-texto">
                          <span>Ações</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaValor.map((item) => {
                        return (
                          <tr key={v4()} onClick={() => handleEditar(item.id)}>
                            <td className="lista-valor">
                              {item.numeroSequencialEvento}
                            </td>
                            <td className="lista-texto">
                              {item.descricaoCorrecao}
                            </td>
                            <td
                              className="lista-texto"
                              style={{
                                fontWeight: 'bold',
                                color:
                                  item.situacao ===
                                  SituacaoEventoEnum.autorizada
                                    ? 'green'
                                    : item.situacao ===
                                        SituacaoEventoEnum.naoTransmitida
                                      ? 'black'
                                      : 'red',
                              }}
                            >
                              {item.situacao}
                            </td>
                            <td className="lista-data">
                              {item.situacao ===
                                SituacaoEventoEnum.autorizada &&
                                FormatarDataHoraCompletaParaPtBr(
                                  item.dataHoraEvento
                                )}
                            </td>
                            <td className="tdButton lista-acoes">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Acoes
                                  deletarFuncao={async () => {
                                    try {
                                      setLoading(true);

                                      await NotaFiscalEntradaTransmissaoEventoComunicador.delete(
                                        {
                                          id: item.id,
                                        }
                                      );
                                      formPrincipal.setLoading(false);
                                      formPrincipal.refresh();

                                      await formPrincipal.handleCarregarDados();
                                      handleCarregarDados();

                                      setLoading(false);
                                    } catch (error) {
                                      setLoading(false);
                                      throw error;
                                    }
                                  }}
                                  buscarLogs={async (params: any) => {
                                    try {
                                      setLoading(true);

                                      const responseLog =
                                        NotaFiscalEntradaTransmissaoEventoLogComunicador.show(
                                          {
                                            id: item.id,
                                            params,
                                          }
                                        );

                                      setLoading(false);
                                      return responseLog;
                                    } catch (error) {
                                      setLoading(false);
                                      throw error;
                                    }
                                  }}
                                  disableDeletar={
                                    permissoes
                                      .SuprimentosComprasNotaFiscalEntrada
                                      ?.exclui &&
                                    item.situacao !==
                                      SituacaoEventoEnum.autorizada
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tabela>
              </ListaProvider>
            </Container>
          </Divisor>
        </Modal.Body>

        {showDetalheCartaCorrecao && (
          <CartaCorrecaoDetalhe
            onFecharFormModal={handleCartaCorrecaoFecharFormModal}
            onSalvarFormModal={handleCartaCorrecaoSalvarFormModal}
          />
        )}
      </FormCia>
    </JanelaDetalhe>
  );
};

export default CartaCorrecaoLista;
