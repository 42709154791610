import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacaoRelatorio,
  TipoContaEnum,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacaoRelatorioPDF {
  dados: IContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacaoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ContasRecPagParcPorCliFornVencLiqPDF: React.FC<
  IContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacaoRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataVencimentoInicial &&
                  dados.cabecalho.filtroDataVencimentoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataVencimentoInicial} até ${dados.cabecalho.filtroDataVencimentoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroDataFinalLiquidacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Final Liquidacao Posição: '}
                      </Text>
                      {dados.cabecalho.filtroDataFinalLiquidacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoData &&
                  dados.cabecalho.filtroDataFinalLiquidacao && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Tipo de Data de Liquidação: '}
                        </Text>
                        {dados.cabecalho.filtroTipoData}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroTipoConta && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo da Conta: '}
                      </Text>
                      {dados.cabecalho.filtroTipoConta}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSituacaoParcela && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Situacao Parcela: '}
                      </Text>
                      {dados.cabecalho.filtroSituacaoParcela}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroClientesFornecedores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroClientesFornecedores}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPortadoresParcela && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Portadores (Parcela): '}
                      </Text>
                      {dados.cabecalho.filtroPortadoresParcela}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPortadoresLiquidacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Portadores (Liquidacao): '}
                      </Text>
                      {dados.cabecalho.filtroPortadoresLiquidacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroFormasPagamentoParcela && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Forma Pagamento (Parcela): '}
                      </Text>
                      {dados.cabecalho.filtroFormasPagamentoParcela}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroFormasPagamentoLiquidacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Forma Pagamento (Liquidacao): '}
                      </Text>
                      {dados.cabecalho.filtroFormasPagamentoLiquidacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCartoes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cartões: '}</Text>
                      {dados.cabecalho.filtroCartoes}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCategorias && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Categorias: '}
                      </Text>
                      {dados.cabecalho.filtroCategorias}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTiposSituacoes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipos Situações: '}
                      </Text>
                      {dados.cabecalho.filtroTiposSituacoes}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '9%' }}>
              <Text>N. Documento</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '4%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Parc.</Text>
            </View>

            <View style={{ width: '4%' }} />

            <View
              style={{
                width: '10%',
              }}
            >
              <Text>Dt. Emissão</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
              }}
            >
              <Text>Dt. Venc</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Parcela</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Tot. Parcela</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Tot. Liquidado</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Tot. em Aberto</Text>
            </View>
            <View style={{ width: '5%' }} />

            <View
              style={{
                width: '12%',
              }}
            >
              <Text>Situação</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dotted black' : '',
                        padding: '6pt 0pt 0pt 0pt',
                        marginBottom: 2,
                      }}
                    >
                      <View style={{ width: '100%' }}>
                        <Text>
                          {dados.cabecalho.filtroTipoConta ===
                          TipoContaEnum.pagar
                            ? `Fornecedor: ${dado.chave}`
                            : `Cliente: ${dado.chave}`}
                        </Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaConta.map((conta, contaIndex) => {
                        const colorBackGround =
                          contaIndex % 2 === 0 ? '#E4E4E4' : '#fff';

                        return (
                          <View
                            style={{ display: 'flex', flexDirection: 'column' }}
                            key={String(contaIndex)}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: colorBackGround,
                              }}
                            >
                              <View
                                style={{
                                  width: '9%',
                                }}
                              >
                                <Text>{conta.numeroDocumento}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '4%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>{`${conta.sequencia}/${conta.quantidadeParcelas}`}</Text>
                              </View>

                              <View style={{ width: '4%' }} />

                              <View style={{ width: '10%' }}>
                                <Text>{conta.dataEmissao}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                }}
                              >
                                <Text>{conta.dataVencimento}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>{conta.valorParcela}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>{conta.valorTotalParcela}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>{conta.valorTotalLiquidado}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>{conta.valorTotalAberto}</Text>
                              </View>
                              <View style={{ width: '5%' }} />

                              <View
                                style={{
                                  width: '12%',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <Text>{conta.situacao}</Text>
                              </View>
                            </View>
                            {conta.listaLiquidacao.map((liquidacao) => {
                              return (
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',

                                    backgroundColor: colorBackGround,
                                  }}
                                >
                                  <View style={{ width: '4%' }} />
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '10%',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      D. Liquid.
                                    </Text>
                                    <Text>{liquidacao.dataLiquidacao}</Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '10%',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      D. Cont.
                                    </Text>
                                    <Text>{liquidacao.dataContabil}</Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '10%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (+) Geral
                                    </Text>
                                    <Text>{liquidacao.valorGeral}</Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '10%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (+) Juros
                                    </Text>
                                    <Text>{liquidacao.valorJurosSoma}</Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '9%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (+) Multa
                                    </Text>
                                    <Text>{liquidacao.valorMultaSoma}</Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '9%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (-) Desc
                                    </Text>
                                    <Text>
                                      {liquidacao.valorDescontoSubtrai}
                                    </Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '9%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (+) Out. Desp
                                    </Text>
                                    <Text>
                                      {liquidacao.valorOutrasDespesasSoma}
                                    </Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '9%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (-) Out. Desp
                                    </Text>
                                    <Text>
                                      {liquidacao.valorOutrasDespesasSubtrai}
                                    </Text>
                                  </View>

                                  <View style={{ width: '1%' }} />

                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '12%',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text style={{ fontWeight: 'bold' }}>
                                      (-) Desp. Cartão
                                    </Text>
                                    <Text>
                                      {liquidacao.valorDespesasCartaoSubtrai}
                                    </Text>
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        );
                      })}
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        marginTop: '2pt',
                        paddingTop: '2pt',
                        flexDirection: 'row',
                        fontSize: 9,
                      }}
                    >
                      <View style={{ width: '39%' }}>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            marginBottom: 5,
                          }}
                        >
                          {dados.cabecalho.filtroTipoConta ===
                          TipoContaEnum.pagar
                            ? `Total Fornecedor: ${dado.valores.somatorioQuantidadeParcelasPorClienteFornecedor}`
                            : `Total Cliente: ${dado.valores.somatorioQuantidadeParcelasPorClienteFornecedor}`}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '10%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {dado.valores.somatorioValorParcela}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '10%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {dado.valores.somatorioValorTotalParcela}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '10%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {dado.valores.somatorioValorTotalLiquidado}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '10%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {dado.valores.somatorioValorTotalAberto}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            >
              <View style={{ width: '39%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                    marginLeft: 2,
                  }}
                >
                  Total Geral: {dados.somatorioGeralQuantidadeParcelas}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '10%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorParcela}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '10%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorTotalParcela}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '10%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorTotalLiquidado}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '10%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorTotalAberto}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ContasRecPagParcPorCliFornVencLiqPDF;
