import {
  IEstruturaProdutoValoresAlterar,
  TipoPerdaEstruturaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import EstruturaProdutoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoComunicador';
import { UseTreeContext } from './TreeContext';
import EstruturaProdutoDetalheComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoDetalheComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import TratarInfo from '../../../../../../Util/Info/TratarInfo';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const {
    buscarDados,
    idShowItem,
    idItemPai,
    listaDados,
    setListaDados,
    setIdShowItem,
    setIdSelecionado,
    setEstruturaSelecionada,
    setIdItemPai,
    setOrdemMaxima,
    encontrarLimiteOrdem,
  } = UseTreeContext();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IEstruturaProdutoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();

        await buscarDados(dadosRecuperados.produtoPai.id);

        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const { response } = await EstruturaProdutoComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicialSemExecutarEvento({
          ...response,
          produtoPaiDois: response.produtoPai,
          unidadeMedida: response.produtoFilho.produtoEstoque.unidadeMedida
            ? response.produtoFilho.produtoEstoque.unidadeMedida
            : null,
        });

        await buscarDados(idEditar);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        setListaDados([]);
        await formRef.current?.setDataInicial({
          tipoPerda: TipoPerdaEstruturaEnum.percentual,
          tipoPerdaSetup: TipoPerdaEstruturaEnum.quantidade,
          quantidade: 1,
          ordem: 1,
          fantasma: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      buscarDados,
      setListaDados,
      handleSetarFocus,
      refresh,
      redirecionar,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idProdutoPai: Yup.string()
          .required('Produto (Pai) é Obrigatório!')
          .nullable(),
        idProdutoFilho: Yup.string()
          .required('Produto (Filho) é Obrigatório!')
          .nullable(),
        fantasma: Yup.boolean().required('Fantasma é Obrigatório!'),
        ordem: Yup.number().required('Ordem é Obrigatória!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSetarDados = useCallback(
    async (id: string) => {
      const response = await EstruturaProdutoDetalheComunicador.show({
        id,
      });

      if (response) {
        await formRef.current?.setDataInicialSemExecutarEvento({
          ...response,
          unidadeMedida: response.produtoFilho.produtoEstoque.unidadeMedida
            ? response.produtoFilho.produtoEstoque.unidadeMedida
            : null,
          produtoPaiDois: response.produtoPai,
          quantidade: response.resultadoFormula ?? response.quantidade,
        });

        refresh();
      }
    },
    [refresh]
  );

  const handleSetarNegrito = useCallback(() => {
    const produtoFilho =
      formRef.current?.getFieldValueNomeObjeto('produtoFilho');
    if (produtoFilho) {
      const item = listaDados[0].filhos.find(
        (it) => it.produtoEstoque.id === produtoFilho.id
      );

      if (item) {
        setIdShowItem({ id: item.id });
        setIdSelecionado(item.id);
        setEstruturaSelecionada(produtoFilho.descricao);
        setIdItemPai('');
        setOrdemMaxima(
          encontrarLimiteOrdem({ item, listaEstrutura: listaDados })
        );
      }
    }
  }, [
    encontrarLimiteOrdem,
    listaDados,
    setEstruturaSelecionada,
    setIdItemPai,
    setIdSelecionado,
    setIdShowItem,
    setOrdemMaxima,
  ]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      await handleSetarDados(idShowItem);
    }
    if (idShowItem) fetchData();
  }, [handleSetarDados, idShowItem]);

  useEffect(() => {
    if (listaDados && listaDados.length > 0) handleSetarNegrito();
  }, [handleSetarNegrito, listaDados]);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = listaDados[0] ? listaDados[0].id : '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id && !idItemPai) {
          const response = await EstruturaProdutoComunicador.update({
            id: id !== idShowItem && idShowItem ? idShowItem : id,
            params: data,
          });

          if (response.listaMensagemAviso) {
            TratarInfo(response.listaMensagemAviso);
          }
        } else if (!id || idItemPai) {
          const response = await EstruturaProdutoComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          setIdSelecionado(response.id);
          setEstruturaSelecionada(data.produtoFilho.descricao);
          id = response.id;

          if (response.listaMensagemAviso) {
            TratarInfo(response.listaMensagemAviso);
          }
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      handleValidar,
      idItemPai,
      idShowItem,
      listaDados,
      redirecionar,
      setEstruturaSelecionada,
      setIdDetalheRegistro,
      setIdSelecionado,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
