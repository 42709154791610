import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IProdutosEmUsoContext {
  validador: boolean;
  listaProdutosEmUso: string[];
  setListaProdutosEmUso: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProdutosEmUsoContext = createContext({} as IProdutosEmUsoContext);

const ProdutosEmUsoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaProdutosEmUso, setListaProdutosEmUso] = useState<string[]>([]);

  return (
    <ProdutosEmUsoContext.Provider
      value={{
        validador: true,
        listaProdutosEmUso,
        setListaProdutosEmUso,
      }}
    >
      {children}
    </ProdutosEmUsoContext.Provider>
  );
};

const UseProdutosEmUso = (): Omit<IProdutosEmUsoContext, 'validador'> => {
  const context = useContext(ProdutosEmUsoContext);

  if (!context.validador) {
    throw new Error(
      'O UseProdutosEmUso deve ser usado dentro do ProdutosEmUseHook'
    );
  }

  return context;
};

export { ProdutosEmUsoHook, UseProdutosEmUso };
