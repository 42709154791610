import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import {
  FormatarDataHoraCompletaParaPtBr,
  ITerminalCaixaValoresAlterar,
} from '@elogestor/util';
import { PiCertificateBold } from 'react-icons/pi/index.mjs';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { isAfter, parseISO } from 'date-fns';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import TerminalCaixaEmpresaCertificadoHook from './CertificadoDetalhe/Hooks';
import TerminalCaixaEmpresaCertificadoDetalhe from './CertificadoDetalhe/Detalhe';
import { UseAutoComplete } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import UseFuncoesPadrao from '../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import ButtonDeletar from '../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import TerminalCaixaEmpresaCertificadoComunicador from '../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaCertificadoComunicador';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<ITerminalCaixaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasTerminalCaixa: permissao } = permissoes;
  const navigate = useNavigate();

  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
    setLoading,
  } = UseForm();

  const { isJanelaAutoComplete } = UseAutoComplete();
  const { abrirJanela } = UseConfirmacao();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // #region Certificado

  const possuiCertificado = formRef.current?.getFieldValue(
    'informacoesCertificado.emitidoPor'
  );

  const certificadoIsValido = useCallback(() => {
    const dataHoraValidadeFinal = formRef.current?.getFieldValue(
      'informacoesCertificado.dataHoraValidadeFinal'
    );

    if (!dataHoraValidadeFinal) return false;

    const dataFinal = parseISO(dataHoraValidadeFinal);

    return isAfter(dataFinal, new Date());
  }, [formRef]);

  const [showFormModalCertificado, setShowFormModalCertificado] =
    useState(false);

  const handleNovoCertificado = useCallback(async () => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModalCertificado(true);
  }, [handleSalvarContinuar, isJanelaAutoComplete]);

  const handleFecharFormModal = useCallback((): void => {
    setShowFormModalCertificado(false);
  }, []);

  const handleExcluirCertificado = useCallback(async () => {
    try {
      const nomeCertificado = formRef.current?.getFieldValue(
        'informacoesCertificado.nomeCertificado'
      );

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja excluir o certificado?</span>
        ),
      });

      if (resposta) {
        setLoading(true);
        await TerminalCaixaEmpresaCertificadoComunicador.delete({
          nomeCertificado,
        });

        ToastSucesso('Certificado Deletado!');
        handleCarregarDados();
        setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [abrirJanela, formRef, handleCarregarDados, setLoading]);

  // #endregion Certificado

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Descrição"
                name="descricao"
                placeholder="Descrição"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <Input
                label="Id Integração TEF (ControlPay)"
                name="idIntegracaoTefControlPay"
                placeholder="Id Integração TEF (ControlPay)"
                maxLength={20}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Input
                label="Store Id (SiTef)"
                name="storeIdSiTef"
                placeholder="Store Id (SiTef)"
                maxLength={20}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Input
                label="Terminal Id (SiTef)"
                name="terminalIdSiTef"
                placeholder="Terminal Id (SiTef)"
                maxLength={8}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>
      </div>

      <Divisor>
        <Row>
          <Col lg={6}>
            <div style={{ width: 260, display: 'flex' }}>
              <button
                type="button"
                style={{
                  width: 201,
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleNovoCertificado}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <PiCertificateBold />
                  <span style={{ marginLeft: 10 }}>Novo Certificado</span>
                </TextoLoading>
              </button>

              <ButtonDeletar
                type="button"
                onClick={handleExcluirCertificado}
                disabled={loading}
                style={{
                  height: '35px',
                  marginLeft: '5pt',
                }}
              >
                <TextoLoading loading={loading}>
                  <RiDeleteBin6Line />
                </TextoLoading>
              </ButtonDeletar>
            </div>
          </Col>
        </Row>

        <div
          style={{
            display: possuiCertificado ? 'block' : 'none',
          }}
        >
          <Row>
            <Col sm={12} lg={12} xl="auto">
              <Card
                style={{
                  borderColor: `${certificadoIsValido() ? 'green' : 'red'}`,
                  marginTop: '10pt',
                  width: '100%',
                }}
              >
                <CardHeader>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: `${certificadoIsValido() ? 'green' : 'red'}`,
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Certificado do Terminal
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {certificadoIsValido() ? 'Válido' : 'Inválido'}
                    </span>
                  </div>
                </CardHeader>
                <CardBody>
                  <InputHiddenHtml name="informacoesCertificado.nomeCertificado" />

                  <div style={{ fontWeight: 'bold', display: 'flex' }}>
                    <span style={{ marginRight: 5 }}>Emitido Por:</span>
                    <InputLabel
                      name="informacoesCertificado.emitidoPor"
                      label=""
                    />
                  </div>

                  <div style={{ fontWeight: 'bold', display: 'flex' }}>
                    <span style={{ marginRight: 5 }}>Valido de</span>
                    <InputLabel
                      name="informacoesCertificado.dataHoraValidadeInicial"
                      label=""
                      formatarValor={(value) => {
                        if (!value) return '';
                        return FormatarDataHoraCompletaParaPtBr(value);
                      }}
                    />
                    <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                    <InputLabel
                      name="informacoesCertificado.dataHoraValidadeFinal"
                      label=""
                      formatarValor={(value) => {
                        if (!value) return '';
                        return FormatarDataHoraCompletaParaPtBr(value);
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {showFormModalCertificado && (
          <TerminalCaixaEmpresaCertificadoHook>
            <TerminalCaixaEmpresaCertificadoDetalhe
              onFecharFormModal={handleFecharFormModal}
            />
          </TerminalCaixaEmpresaCertificadoHook>
        )}
      </Divisor>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
