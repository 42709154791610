import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/operacao-fiscal-auto-complete';

interface IOperacaoFiscalAutoCompleteComunicadorIndex {
  params: any;
}

class OperacaoFiscalAutoCompleteComunicador {
  public async index(
    params: IOperacaoFiscalAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OperacaoFiscalAutoCompleteComunicador();
