import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/consulta-estoque/obter-produto-estoque';

interface IConsultaEstoqueObterProdutoEmEstoqueComunicadorShow {
  id: string;
}

class ConsultaEstoqueObterProdutoEmEstoqueComunicador {
  public async show(
    params: IConsultaEstoqueObterProdutoEmEstoqueComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConsultaEstoqueObterProdutoEmEstoqueComunicador();
