import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';
import ConhecimentoTransporteCargaHook from './ListaDetalheCarga/Hooks';
import ListaDetalheCarga from './ListaDetalheCarga/Lista';

const CargaTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={2} lg={6} md={12}>
            <InputDecimalNulavel
              name="valorCarga"
              label="Valor da Carga"
              placeholder="Valor da Carga"
              casasDecimais={2}
              casasInteiras={13}
              maxLength={15}
            />
          </Col>

          <Col xl={5} lg={6} md={12}>
            <Input
              label="Produto Predominante"
              name="produtoPredominante"
              placeholder="Produto Predominante"
              maxLength={60}
            />
          </Col>

          <Col xl={5} lg={12}>
            <Input
              label="Outras Características do Produto"
              name="outrasCaracteristicasProduto"
              placeholder="Outras Características do Produto"
              maxLength={60}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <ConhecimentoTransporteCargaHook>
          <ListaDetalheCarga name="listaConhecimentoTransporteCarga" />
        </ConhecimentoTransporteCargaHook>
      </Divisor>
    </div>
  );
};

export default CargaTab;
