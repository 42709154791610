import { ICotacaoCompraValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra/obter-itens-aprovar-rejeitar';

interface IObterCotacaoCompraItensParaAprovarRejeitarComunicadorIndex {
  id: string;
}

class ObterCotacaoCompraItensParaAprovarRejeitarComunicador {
  public async index(
    params: IObterCotacaoCompraItensParaAprovarRejeitarComunicadorIndex
  ): Promise<ICotacaoCompraValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterCotacaoCompraItensParaAprovarRejeitarComunicador();
