const AdiantamentosPorEmissaoComUtilacaoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'chave',
  },

  {
    titulo: 'Número documento',
    valor: 'listaConta.numeroDocumento',
  },

  {
    titulo: 'Cliente/Fornecedor',
    valor: 'listaConta.cliente',
  },

  {
    titulo: 'Tipo Adiantamento',
    valor: 'listaConta.tipoAdiantamento',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaConta.valorTotal',
  },

  {
    titulo: 'Valor Utilizado',
    valor: 'listaConta.valorUtilizado',
  },

  {
    titulo: 'Data Emissão Utilização',
    valor: 'listaConta.utilizacoesAdiantamento.dataHoraEmissao',
  },

  {
    titulo: 'Cliente Utilização',
    valor: 'listaConta.utilizacoesAdiantamento.cliente',
  },

  {
    titulo: 'Valor Utilizado Utilização',
    valor: 'listaConta.utilizacoesAdiantamento.valorUtilizadoAdiantamento',
  },
];

export default AdiantamentosPorEmissaoComUtilacaoPlanilha;
