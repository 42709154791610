import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/item';
const rotaNovo = 'suprimentos/compras/nota-fiscal-entrada';

interface INotaFiscalEntradaItemComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaItemComunicadorStore {
  idPai: string;
  params: any;
}

interface INotaFiscalEntradaItemComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalEntradaItemComunicadorDelete {
  id: string;
}

class NotaFiscalEntradaItemComunicador {
  public async show(
    params: INotaFiscalEntradaItemComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalEntradaItemComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaItemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalEntradaItemComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaItemComunicador();
