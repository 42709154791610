import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/engenharia/roteiro-fabricacao-padrao-auto-complete-tag';

interface IRoteiroFabricacaoPadraoAutoCompleteTagComunicadorIndex {
  params: any;
}

class RoteiroFabricacaoPadraoAutoCompleteTagComunicador {
  public async index(
    params: IRoteiroFabricacaoPadraoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RoteiroFabricacaoPadraoAutoCompleteTagComunicador();
