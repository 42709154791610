import {
  FormatarDataHoraParaPtBr,
  IEstoqueFiltroRelatorioBackend,
  IEstoqueFiltroRelatorioFrontend,
  IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraCabecalhoRelatorio,
  IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoOrdenacaoEnum,
} from '@elogestor/util';
import React from 'react';
import EstoqueSaldoProprioComNecessidadeCompraRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/Estoques/EstoqueSaldoProprioComNecessidadeCompraRelatorio/EstoqueSaldoProprioComNecessidadeCompraRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPDF from './EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPDF';
import EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha from './EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IEstoqueFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IEstoqueFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const EstoqueSaldoProprioEmPoderProprioComNecessidadeCompraRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IEstoqueFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraRelatorio | null> => {
      const filtroBack: IEstoqueFiltroRelatorioBackend = {
        data: filtros.data,

        produtoAtivo: filtros?.produtoAtivo,

        descricao: filtros.descricao,
        codigo: filtros.codigo,
        somenteProdutosComSaldo: filtros?.somenteProdutosComSaldo,
        reservaComprometimento: filtros?.reservaComprometimento,
        somenteProdutosEstoqueMinimo: filtros?.somenteProdutosEstoqueMinimo,
        somenteProdutosEstoqueMaximo: filtros?.somenteProdutosEstoqueMaximo,
        somenteProdutosComNecessidadeCompra:
          filtros?.somenteProdutosComNecessidadeCompra,
      };

      if (filtros?.listaMarcaProduto) {
        filtroBack.listaIdMarcaProduto =
          filtros.listaMarcaProduto.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaProduto) {
        filtroBack.listaIdProduto =
          filtros.listaProduto.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.reservaComprometimento) {
        filtroBack.reservaComprometimento = filtros.reservaComprometimento;
      }

      if (filtros?.tipoBuscaCodigoDescricao) {
        filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
          typeof TipoBuscaCodigoDescricaoEnum,
          TipoBuscaCodigoDescricaoEnum
        >(
          TipoBuscaCodigoDescricaoEnum,
          String(filtros.tipoBuscaCodigoDescricao)
        );
      }

      if (filtros?.listaLote) {
        filtroBack.listaIdLote =
          filtros.listaLote.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaGrupoProdutoServico) {
        filtroBack.listaIdGrupoProdutoServico =
          filtros.listaGrupoProdutoServico.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaLocalEstoque) {
        filtroBack.listaIdLocalEstoque =
          filtros.listaLocalEstoque.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.tipoOrdenacao) {
        filtroBack.tipoOrdenacao = StringConverterParaEnum<
          typeof TipoOrdenacaoEnum,
          TipoOrdenacaoEnum
        >(TipoOrdenacaoEnum, String(filtros.tipoOrdenacao));
      }

      const response =
        await EstoqueSaldoProprioComNecessidadeCompraRelatorioComunicador.index(
          {
            params: { filtro: filtroBack },
          }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      return response as IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraRelatorio;
    };

    function FormatarCabecalho(
      filtros: IEstoqueFiltroRelatorioFrontend,
      cabecalho: IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraCabecalhoRelatorio
    ): IEstoqueSaldoProprioEmPoderProprioComNecessidadeCompraCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.data) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroData = FormatarDataHoraParaPtBr(filtros.data);
      }

      if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroProduto = filtros.listaProduto.listaValor
          .map((valor: any) => {
            return `${valor.codigo} - ${valor.descricao}`;
          })
          .join(', ');
      } else {
        if (filtros.codigo) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroCodigo = filtros.codigo;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }

        if (filtros.descricao) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroDescricao = filtros.descricao;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }
      }

      if (
        filtros.listaGrupoProdutoServico &&
        filtros.listaGrupoProdutoServico.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroGrupo = filtros.listaGrupoProdutoServico.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaLocalEstoque &&
        filtros.listaLocalEstoque.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroLocalEstoque = filtros.listaLocalEstoque.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (filtros.tipoOrdenacao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroTipoOrdenacao = filtros.tipoOrdenacao;
      }

      if (
        filtros.somenteProdutosComNecessidadeCompra !== undefined &&
        filtros.somenteProdutosComNecessidadeCompra !== null
      ) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroSomenteProdutosComNecessidadeCompra = Boolean(
          filtros.somenteProdutosComNecessidadeCompra
        );
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IEstoqueFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha,
        nomeArquivo: 'EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompra',
        valores: dados.listaDados,
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IEstoqueFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPlanilha,
        nomeArquivo: 'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra',
        valores: dados.listaDados as any,
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IEstoqueFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPDF
            dados={dados}
          />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IEstoqueFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <EstoqueSaldoPropriorEmPoderProprioComNecessidadeCompraPDF
            dados={dados}
          />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default EstoqueSaldoProprioEmPoderProprioComNecessidadeCompraRelatorio();
