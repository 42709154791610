import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';

interface ICodigoRastreabilidadeSisdevinContext {
  abrirCodigoRastreabilidadeSisdevinDuplicar(
    descricao: string
  ): Promise<string | null>;
  fecharCodigoRastreabilidadeSisdevinDuplicar(): void;
}

const CodigoRastreabilidadeSisdevinContext =
  createContext<ICodigoRastreabilidadeSisdevinContext>(
    {} as ICodigoRastreabilidadeSisdevinContext
  );

const CodigoRastreabilidadeSisdevinHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const formRefDetalhe = useRef<IFormCiaHandles>(null);
  const resolveRef = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [aberto, setAberto] = useState(false);
  const [descricaoDetalhe, setDescricaoDetalhe] = useState('');

  const handleAbrir = useCallback(
    async (descricao: string) => {
      setAberto(true);
      setDescricaoDetalhe(descricao);
      formRefDetalhe.current?.setFieldError('descricao', '');

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [setDescricaoDetalhe]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSalvar = useCallback(async () => {
    setLoading(true);

    const descricao = formRefDetalhe.current?.getFieldValue('descricao');

    if (!descricao) {
      ToastErro('Descrição é Obrigatória!');
      formRefDetalhe.current?.setFieldError(
        'descricao',
        'Descrição é Obrigatória!'
      );
      setLoading(false);
      return;
    }

    if (descricao === descricaoDetalhe) {
      ToastErro('Descrição já cadastrada!');
      formRefDetalhe.current?.setFieldError(
        'descricao',
        'Descrição já cadastrada!'
      );
      setLoading(false);
      return;
    }

    resolveRef.current(descricao);
    setAberto(false);
    setLoading(false);
  }, [descricaoDetalhe]);

  return (
    <CodigoRastreabilidadeSisdevinContext.Provider
      value={{
        abrirCodigoRastreabilidadeSisdevinDuplicar: handleAbrir,
        fecharCodigoRastreabilidadeSisdevinDuplicar: handleFechar,
      }}
    >
      <FormCia ref={formRefDetalhe}>
        <Modal show={aberto} onHide={handleFechar} size="lg">
          <Modal.Header
            style={{
              background: '#3397c4',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Modal.Title>
              Duplicar Grupo de Códigos da Rastreabilidade
            </Modal.Title>
            <BtnCloseButton type="button" onClick={handleFechar}>
              <IoIosCloseCircleOutline />
            </BtnCloseButton>
          </Modal.Header>

          <Modal.Body style={{ maxHeight: 500 }}>
            <Divisor>
              <Row>
                <Col lg={10} md={8} sm={12}>
                  <Input
                    label="Descrição"
                    name="descricao"
                    placeholder="Descrição"
                    maxLength={100}
                  />
                </Col>
              </Row>
            </Divisor>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={handleFechar}
                  disabled={loading}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <TextoLoading loading={loading}>
                      <MdCancel />
                      <span style={{ marginLeft: 10 }}>Cancelar</span>
                    </TextoLoading>
                  </span>
                </button>

                <Button
                  style={{
                    fontWeight: 'bold',
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type="button"
                  onClick={handleSalvar}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <AiOutlineCheck />
                    <span style={{ marginLeft: 10 }}>OK</span>
                  </TextoLoading>
                </Button>
              </Container>
            </div>
          </Modal.Footer>
        </Modal>
      </FormCia>
      {children}
    </CodigoRastreabilidadeSisdevinContext.Provider>
  );
};

function UseCodigoRastreabilidadeSisdevinDuplicar(): ICodigoRastreabilidadeSisdevinContext {
  const context = useContext(CodigoRastreabilidadeSisdevinContext);

  if (!context) {
    throw new Error(
      'UseCodigoRastreabilidadeSisdevinDuplicar deve ser Usado com CodigoRastreabilidadeSisdevinProvider'
    );
  }

  return context;
}

export {
  CodigoRastreabilidadeSisdevinHook,
  UseCodigoRastreabilidadeSisdevinDuplicar,
};
