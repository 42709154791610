import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/situacao-auto-complete-tag';

interface ISituacaoAutoCompleteTagComunicadorIndex {
  params: any;
}

class SituacaoAutoCompleteTagComunicador {
  public async index(
    params: ISituacaoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SituacaoAutoCompleteTagComunicador();
