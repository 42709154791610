import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/obter-conta';

interface IConhecimentoTransporteTerceiroObterContaComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTerceiroObterContaComunicador {
  public async show(
    params: IConhecimentoTransporteTerceiroObterContaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroObterContaComunicador();
