import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/tributacao/configuracao-tributaria/obter-cte-tipo-nota-motivo';

interface IConfiguracaoTributariaCteObterTipoNotaMotivoComunicadorShow {
  params: {
    dataPesquisaVigencia: Date;
    idPessoaDestinatario: string;
    idPessoaRemetente: string;
  };
}

class ConfiguracaoTributariaCteObterTipoNotaMotivoComunicador {
  public async show(
    params: IConfiguracaoTributariaCteObterTipoNotaMotivoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new ConfiguracaoTributariaCteObterTipoNotaMotivoComunicador();
