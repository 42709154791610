"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var PermiteDuplicarCadastrosEnum;
(function (PermiteDuplicarCadastrosEnum) {
    PermiteDuplicarCadastrosEnum["permitirApenasComNomeRazaoSocialDiferente"] = "Permitir CNPJ / CPF e Inscri\u00E7\u00E3o Estadual mas com  Nome / Raz\u00E3o Social diferente";
    PermiteDuplicarCadastrosEnum["permitirSempre"] = "Permitir sempre";
    PermiteDuplicarCadastrosEnum["naoPermitir"] = "N\u00E3o permitir CNPJ / CPF e Inscri\u00E7\u00E3o Estadual";
    PermiteDuplicarCadastrosEnum["naoPermitirCnpjCpfInscricaoEstadualOuNomeRazaoSocial"] = "N\u00E3o Permitir CNPJ / CPF e Inscri\u00E7\u00E3o Estadual ou  Nome / Raz\u00E3o Social";
})(PermiteDuplicarCadastrosEnum || (PermiteDuplicarCadastrosEnum = {}));
exports.default = PermiteDuplicarCadastrosEnum;
