import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  FormatarEnum,
  ICotacaoCompraFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFornecedor';
import InputAutoCompleteTagEstado from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import InputAutoCompleteTagSituacaoItemEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoItemEnum';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import Input from '../../../../../Componentes/Inputs/Input';
import Select from '../../../../../Componentes/Select';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import InputAutoCompleteTagEmpresa from '../../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import { UseAuth } from '../../../../../Hooks/Auth';

interface IComprasCotacaoCompraRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const ComprasCotacaoCompraRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();
  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraEmissaoRef = useRef<IInputPeriodoDataHoraRef>(null);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState(
    'CotacaoCompraResumo'
  );
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [listaIdEmpresa, setListaIdEmpresa] = useState<string[]>([]);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data inicial',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora final',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComprasCotacaoCompraRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as ICotacaoCompraFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComprasCotacaoCompraRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as ICotacaoCompraFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComprasCotacaoCompraRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as ICotacaoCompraFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      opcaoRelatorio: 'CotacaoCompraResumo',
      dataEmissaoInicial: dataInicial,
      dataEmissaoFinal: dataFinal,
      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],
    });
  }, [empresa.id, empresa.nomeRazaoSocial, formRef]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  const handleChangeOpcaoSelecionada = useCallback(() => {
    const opcaoRelatorio = formRef.current?.getFieldValue('opcaoRelatorio');
    if (
      opcaoRelatorio !== 'CotacaoCompraPorFornecedor' &&
      opcaoRelatorio !== 'CotacaoCompraPorProduto'
    ) {
      [
        'listaProduto',
        'codigo',
        'descricao',
        'tipoBuscaCodigoDescricao',
      ].forEach((chave) => {
        formRef.current?.clearField(chave);
      });
    }
  }, []);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Cotação de Compra
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Resumo',
                        value: 'CotacaoCompraResumo',
                      },
                      {
                        title: 'Por Fornecedor',
                        value: 'CotacaoCompraPorFornecedor',
                      },
                      {
                        title: 'Por Produto',
                        value: 'CotacaoCompraPorProduto',
                      },
                      {
                        title: 'Totais por Modelo Documento',
                        value: 'CotacaoCompraTotalPorModeloDocumento',
                      },
                      {
                        title: 'Totais por Data de Emissão',
                        value: 'CotacaoCompraTotalPorDataEmissao',
                      },
                      {
                        title: 'Resumo por Data de Emissão',
                        value: 'CotacaoCompraResumoPorDataEmissao',
                      },
                      {
                        title: 'Resumo por Mês/Ano de Emissão',
                        value: 'CotacaoCompraResumoPorMesAnoEmissao',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.currentTarget.value);
                      handleChangeOpcaoSelecionada();
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Emissão Inicial"
                      labelDataHoraFinal="Data Emissão Final"
                      nomeDataHoraInicial="dataEmissaoInicial"
                      nomeDataHoraFinal="dataEmissaoFinal"
                      ref={periodoDataHoraEmissaoRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSituacaoItemEnum
                      label="Situação do Item"
                      name="listaSituacaoItem"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagModeloDocumento
                      label="Modelo Documento"
                      name="listaModeloDocumento"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFornecedor
                      label="Fornecedores"
                      name="listaFornecedor"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagRamoAtividadeProfissao
                      label="Ramo Atividade/Profissão"
                      name="listaRamoAtividadeProfissao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEstado
                      label="Estados"
                      name="listaEstado"
                      onChangeListaItemAtual={({ listaItemAtual }) => {
                        handleChangeListaItemAtualEstado(listaItemAtual);
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCidade
                      label="Cidades"
                      name="listaCidade"
                      listaIdEstado={listaIdEstado}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CotacaoCompraPorFornecedor' ||
                      inputOpcaoRelatorio === 'CotacaoCompraPorProduto'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} lg={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagProduto
                      label="Produtos"
                      name="listaProduto"
                      onChangeListaItemAtual={handleChangeProduto}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CotacaoCompraPorFornecedor' ||
                      inputOpcaoRelatorio === 'CotacaoCompraPorProduto'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={3} lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Código"
                      name="codigo"
                      placeholder="Código"
                      maxLength={60}
                    />
                  </Col>

                  <Col xl={5} lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={120}
                    />
                  </Col>

                  <Col xl={4} lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Filtro Codigo/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CotacaoCompraPorFornecedor' ||
                      inputOpcaoRelatorio === 'CotacaoCompraPorProduto'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      label="Grupos"
                      name="listaGrupo"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagOperacaoFiscal
                      label="Operação Fiscal"
                      name="listaOperacaoFiscal"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                      onChangeListaItemAtual={(ev) => {
                        if (ev.listaItemAtual) {
                          if (ev.listaItemAtual.length === 0) {
                            setListaIdEmpresa([]);
                          } else {
                            ev.listaItemAtual.forEach((it) =>
                              setListaIdEmpresa((prevState) => {
                                const lista = prevState.filter(
                                  (item) => item !== it.id
                                );
                                lista.push(it.id);

                                return lista;
                              })
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default ComprasCotacaoCompraRelatorio;
