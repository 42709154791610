import { FormatarCnpjCpf, IPessoaDadosBancariosLista } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheDadosBancariosProps {
  name: string;
}

const PessoaDadosBancariosLista: React.FC<IListaDetalheDadosBancariosProps> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { isJanelaAutoComplete } = UseAutoComplete();

  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);
  const { telaGrande } = UseReactSizeMeBodyHook();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IPessoaDadosBancariosLista[]>([]);

  const [listaValor, setListaValor] = useState<IPessoaDadosBancariosLista[]>(
    []
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPessoaDadosBancariosLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [handleSalvarContinuar, isJanelaAutoComplete, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formPrincipal.formRef.current?.getData();

        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  return (
    <>
      <Row>
        <Col lg={3} md={6} sm={12} xs={12}>
          <BtnPadraoButton
            style={{ maxWidth: telaGrande ? 250 : '' }}
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Banco</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Agência</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Conta</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Tipo de Conta</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>CNPJ/CPF Titular</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Nome do Titular</span>
              </th>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>Observações</span>
              </th>
              <th className="lista-acoes">
                <span>Ações</span>
              </th>
            </tr>
          </thead>
          <TBody>
            {listaValor.map((dadosBancarios, index) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(dadosBancarios.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>
                      {dadosBancarios.banco.codigo} -{' '}
                      {dadosBancarios.banco.nome}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{dadosBancarios.codigoAgencia}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{dadosBancarios.conta}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{dadosBancarios.tipoConta}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{FormatarCnpjCpf(dadosBancarios.cnpjCpfTitular)}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{dadosBancarios.titular}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{dadosBancarios.observacoes}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(dadosBancarios.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </>
  );
};

export default PessoaDadosBancariosLista;
