"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VinculoTransferenciaNotaFiscalSaidaEnum;
(function (VinculoTransferenciaNotaFiscalSaidaEnum) {
    VinculoTransferenciaNotaFiscalSaidaEnum["pedidoVenda"] = "Pedido Venda";
    VinculoTransferenciaNotaFiscalSaidaEnum["ordemServico"] = "Ordem Servi\u00E7o";
    VinculoTransferenciaNotaFiscalSaidaEnum["notaFiscalEntrada"] = "Nota Fiscal Entrada";
})(VinculoTransferenciaNotaFiscalSaidaEnum || (VinculoTransferenciaNotaFiscalSaidaEnum = {}));
exports.default = VinculoTransferenciaNotaFiscalSaidaEnum;
