import {
  FormatarDataHoraParaPtBr,
  IComissoesNotaFiscalFiltroRelatorioBackend,
  IComissoesNotaFiscalFiltroRelatorioFrontend,
  IComissoesNotaFiscalPorLiquidezCabecalhoRelatorio,
  IComissoesNotaFiscalPorLiquidezRelatorio,
} from '@elogestor/util';
import React from 'react';
import ComissoesNotaFiscalPorLiquidezRelatorioComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Comissoes/ComissoesNotaFiscal/ComissoesNotaFiscalPorLiquidezRelatorio/ComissoesNotaFiscalPorLiquidezRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import ComissoesNotaFiscalPorLiquidezPDF from './ComissoesNotaFiscalPorLiquidezPDF';
import ComissoesNotaFiscalPorLiquidezPlanilha from './ComissoesNotaFiscalPorLiquidezPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ComissoesNotaFiscalPorLiquidezRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IComissoesNotaFiscalPorLiquidezRelatorio | null> => {
    const filtroBack: IComissoesNotaFiscalFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCliente) {
      filtroBack.listaIdCliente =
        filtros.listaCliente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRepresentante) {
      filtroBack.listaIdRepresentante =
        filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaEmpresa) {
      filtroBack.listaIdEmpresa =
        filtros.listaEmpresa.listaValor.map((value) => value.id) || [];
    }

    const response =
      await ComissoesNotaFiscalPorLiquidezRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IComissoesNotaFiscalPorLiquidezRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somaTotalBaseCalculoPorRepresentante: Number(
            dados.valores.somaTotalBaseCalculoPorRepresentante
          ).FormatarParaPtBr(),
          somaTotalValorPorRepresentante: Number(
            dados.valores.somaTotalValorPorRepresentante
          ).FormatarParaPtBr(),
        };

        const listaComissoesNotaFiscal = dados.listaComissoesNotaFiscal.map(
          (item) => {
            return {
              ...item,
              aliquota: Number(item.aliquota).FormatarParaPtBr(),
              baseCalculo: Number(item.baseCalculo).FormatarParaPtBr(),
              valor: Number(item.valor).FormatarParaPtBr(),
            };
          }
        );

        return {
          ...dados,
          valores,
          listaComissoesNotaFiscal,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      somaTotalGeralBaseCalculo: Number(
        retorno.somaTotalGeralBaseCalculo
      ).FormatarParaPtBr(),
      somaTotalGeralValor: Number(
        retorno.somaTotalGeralValor
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend,
    cabecalho: IComissoesNotaFiscalPorLiquidezCabecalhoRelatorio
  ): IComissoesNotaFiscalPorLiquidezCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCliente = filtros.listaCliente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (
      filtros.listaRepresentante &&
      filtros.listaRepresentante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (filtros.listaEmpresa && filtros.listaEmpresa.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresa = filtros.listaEmpresa.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: ComissoesNotaFiscalPorLiquidezPlanilha,
      nomeArquivo: 'ComissoesNotaFiscalPorLiquidez',
      valores: dados.listaDados,
      unwind: ['listaComissoesNotaFiscal'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: ComissoesNotaFiscalPorLiquidezPlanilha,
      nomeArquivo: 'ComissoesNotaFiscalPorLiquidez',
      valores: dados.listaDados as any,
      unwind: ['listaComissoesNotaFiscal'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <ComissoesNotaFiscalPorLiquidezPDF dados={dados} />,
    });
    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IComissoesNotaFiscalFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ComissoesNotaFiscalPorLiquidezPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ComissoesNotaFiscalPorLiquidezRelatorio();
