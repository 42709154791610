const BoletoResumoPlanilha = [
  { titulo: 'Data Vencimento', valor: 'dataVencimento' },
  { titulo: 'Seu Número', valor: 'seuNumero' },
  { titulo: 'Nosso Número', valor: 'nossoNumero' },
  { titulo: 'Código', valor: 'codigoCliente' },
  { titulo: 'Cliente', valor: 'nomeRazaoSocialCliente' },
  { titulo: 'Valor', valor: 'valor' },
];

export default BoletoResumoPlanilha;
