import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  DestinadoEnum,
  FormatarEnum,
  IsSimplesNacional,
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputAutoCompleteOperacaoFiscal, {
  IOperacaoFiscal,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputAutoCompleteTipoNota from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteCodigoSituacaoTributariaIcms, {
  ICodigoSituacaoTributariaIcms,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import { UseRegimeTributario } from '../../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import InputCheckbox from '../../../../../../../Componentes/Inputs/InputCheckbox';
import { UseImpostoIcms } from '../Hooks/ImpostoIcmsHook';
import SituacaoTributariaObterPorCstComunicador from '../../../../../../../Comunicador/Fiscal/SituacaoTributaria/Comunicador/SituacaoTributariaObterPorCstComunicador';
import ConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicador';
import Select from '../../../../../../../Componentes/Select';

interface IDocumentoTab {
  bloquearCamposPorImportacao: boolean;
}

const DocumentoTab: React.FC<IDocumentoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  const { formRef } = UseForm();
  const { regimeTributario } = UseRegimeTributario();
  const { handleCalcularIcms } = UseImpostoIcms();

  const [notaMotivoIdTipoNota, setNotaMotivoIdTipoNota] = useState('');
  const [idTipoNota, setIdTipoNota] = useState('');

  const [showCamposIcms, setShowCamposIcms] = useState(true);
  const [showReducaoBaseCalculo, setShowReducaoBaseCalculo] = useState(true);

  const ufEstadoOrigem = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroRemetenteEndereco.cidade.estado.sigla'
  );
  const ufEstadoDestino = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroDestinatarioEndereco.cidade.estado.sigla'
  );

  const handleChangeTipoNota = useCallback(
    (id: string): void => {
      if (!id || notaMotivoIdTipoNota !== id) {
        formRef.current?.clearField(
          'conhecimentoTransporteTerceiroImpostoIcms.tipoNotaMotivo'
        );
      }

      setIdTipoNota(id);
    },
    [formRef, notaMotivoIdTipoNota]
  );

  const handleChangeCst = useCallback(
    (cst?: ICodigoSituacaoTributariaIcms) => {
      if (!cst?.codigo) {
        setShowCamposIcms(true);
        setShowReducaoBaseCalculo(true);
      }

      if (cst?.codigo === '00') {
        setShowCamposIcms(true);
        setShowReducaoBaseCalculo(false);
        formRef.current?.setFieldValorInicial(
          'conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo',
          null
        );
      }

      if (cst?.codigo === '20' || cst?.codigo === '90') {
        setShowCamposIcms(true);
        setShowReducaoBaseCalculo(true);
      }

      if (
        cst?.codigo === '40' ||
        cst?.codigo === '41' ||
        cst?.codigo === '51' ||
        cst?.codigo === '60'
      ) {
        setShowCamposIcms(false);
        setShowReducaoBaseCalculo(false);

        formRef.current?.setFieldValorInicial(
          'conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo',
          null
        );
        formRef.current?.setFieldValorInicial(
          'conhecimentoTransporteTerceiroImpostoIcms.aliquota',
          null
        );
        formRef.current?.setFieldValorInicial(
          'conhecimentoTransporteTerceiroImpostoIcms.baseCalculo',
          null
        );
        formRef.current?.setFieldValorInicial(
          'conhecimentoTransporteTerceiroImpostoIcms.valor',
          null
        );
      }
    },
    [formRef]
  );

  const handleObterCfopFiscal = useCallback(
    async (cfopAtual: IOperacaoFiscal | null) => {
      const cfopImpostoFiscal = formRef.current?.getFieldValueNomeObjeto(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.operacaoFiscal'
      );
      if (!cfopAtual || !!cfopImpostoFiscal) return;

      const cfopFiscal =
        await ConhecimentoTransporteTerceiroObterCfopFiscalEntradaComunicador.show(
          { idOperacaoFiscalSaida: cfopAtual.id }
        );
      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.operacaoFiscal',
        cfopFiscal
      );
    },
    [formRef]
  );

  const handleBloquearCamposIcms = useCallback(
    (calcularIcms: boolean) => {
      const fieldPath = 'conhecimentoTransporteTerceiroImpostoIcms';

      formRef.current?.setFieldDisabled(
        `${fieldPath}.percentualReducaoBaseCalculo`,
        calcularIcms
      );

      formRef.current?.setFieldDisabled(
        `${fieldPath}.baseCalculo`,
        calcularIcms
      );

      formRef.current?.setFieldDisabled(`${fieldPath}.aliquota`, calcularIcms);
      formRef.current?.setFieldDisabled(`${fieldPath}.valor`, calcularIcms);
    },
    [formRef]
  );

  const handleChangeCalcularIcms = useCallback(
    (calcularIcms: boolean) => {
      handleCalcularIcms();
      handleBloquearCamposIcms(calcularIcms);
    },
    [handleBloquearCamposIcms, handleCalcularIcms]
  );

  const handleChangeEmitenteSimplesNacional = useCallback(
    async (isEmitenteSimplesNacional: boolean) => {
      setShowCamposIcms(!isEmitenteSimplesNacional);

      formRef.current?.setFieldDisabled(
        'conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria',
        isEmitenteSimplesNacional
      );

      if (isEmitenteSimplesNacional) {
        formRef.current?.setFieldValue(
          'conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo',
          null
        );
        formRef.current?.setFieldValue(
          'conhecimentoTransporteTerceiroImpostoIcms.baseCalculo',
          null
        );
        formRef.current?.setFieldValue(
          'conhecimentoTransporteTerceiroImpostoIcms.aliquota',
          null
        );
        formRef.current?.setFieldValue(
          'conhecimentoTransporteTerceiroImpostoIcms.valor',
          null
        );

        const situacaoTributaria =
          await SituacaoTributariaObterPorCstComunicador.show({
            params: { cst: '90' },
          });
        await formRef.current?.setSemExecutarEvento({
          conhecimentoTransporteTerceiroImpostoIcms: { situacaoTributaria },
        });

        const creditoConformeDocumento = formRef.current?.getFieldValue(
          'conhecimentoTransporteTerceiroImpostoIcmsFiscal.creditoConformeDocumento'
        );
        if (creditoConformeDocumento) {
          formRef.current?.setFieldValue(
            'conhecimentoTransporteTerceiroImpostoIcmsFiscal.situacaoTributaria',
            situacaoTributaria
          );
        }
      }
    },
    [formRef]
  );

  const currentCst = formRef.current?.getFieldValueNomeObjeto(
    'conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria'
  );

  useEffect(() => {
    const isEmitenteSimplesNacional = formRef.current?.getFieldValue(
      'conhecimentoTransporteTerceiroImpostoIcms.emitenteSimplesNacional'
    );
    if (!isEmitenteSimplesNacional) {
      handleChangeCst(currentCst);
    }
  }, [currentCst, formRef, handleChangeCst]);

  const currentBloquearCamposIcms = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroImpostoIcms.calcularIcms'
  );

  useEffect(() => {
    handleBloquearCamposIcms(currentBloquearCamposIcms);
  }, [currentBloquearCamposIcms, handleBloquearCamposIcms]);

  const currentEmitenteSimplesNacional = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroImpostoIcms.emitenteSimplesNacional'
  );

  useEffect(() => {
    handleChangeEmitenteSimplesNacional(currentEmitenteSimplesNacional);
  }, [currentEmitenteSimplesNacional, handleChangeEmitenteSimplesNacional]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6}>
            <InputAutoCompleteTipoNota
              label="Tipo de Nota / Conhecimento"
              name="conhecimentoTransporteTerceiroImpostoIcms.idTipoNota"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcms.tipoNota"
              placeholder="Tipo de Nota / Conhecimento"
              permitirAdicionar={false}
              onChangeItemAtual={(event) => {
                const id = event.itemAtual?.id ?? '';
                handleChangeTipoNota(id);
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col lg={6}>
            <InputAutoCompleteTipoNotaMotivo
              label="Motivo"
              name="conhecimentoTransporteTerceiroImpostoIcms.idTipoNotaMotivo"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcms.tipoNotaMotivo"
              placeholder="Motivo"
              idTipoNota={idTipoNota}
              onChangeItemAtual={(event) => {
                const id = event.itemAtual?.idTipoNota ?? '';
                setNotaMotivoIdTipoNota(id);
              }}
              disabled={
                bloquearCamposPorImportacao ||
                !formRef.current?.getFieldValue(
                  'conhecimentoTransporteTerceiroImpostoIcms.idTipoNota'
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Select
              name="conhecimentoTransporteTerceiroImpostoIcms.destinado"
              label="Destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
                nullavel: true,
              })}
              // disabled={bloquearCamposPorImportacao}
            />
          </Col>
          <Col lg={4}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="conhecimentoTransporteTerceiroImpostoIcms.idOperacaoFiscal"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcms.operacaoFiscal"
              placeholder="CFOP"
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.saida}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem,
                ufEstadoDestino,
              })}
              onChangeItemAtualAposCarregarSemClear={(event) => {
                handleObterCfopFiscal(event.itemAtual);

                const creditoConformeDocumento = formRef.current?.getFieldValue(
                  'conhecimentoTransporteTerceiroImpostoIcmsFiscal.creditoConformeDocumento'
                );

                if (!creditoConformeDocumento) return;

                const cfop = formRef.current?.getFieldValueNomeObjeto(
                  'conhecimentoTransporteTerceiroImpostoIcms.operacaoFiscal'
                );

                formRef.current?.setFieldValue(
                  'conhecimentoTransporteTerceiroImpostoIcmsFiscal.operacaoFiscal',
                  cfop
                );
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col lg={4}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CST"
              placeholder="CST"
              name="conhecimentoTransporteTerceiroImpostoIcms.idSituacaoTributaria"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria"
              icmsConhecimentoTransporte
              onChangeItemAtualAposCarregarSemClear={(event) => {
                handleChangeCst(event.itemAtual ?? undefined);
                handleChangeCalcularIcms(
                  formRef.current?.getFieldValue(
                    'conhecimentoTransporteTerceiroImpostoIcms.calcularIcms'
                  )
                );

                const creditoConformeDocumento = formRef.current?.getFieldValue(
                  'conhecimentoTransporteTerceiroImpostoIcmsFiscal.creditoConformeDocumento'
                );

                if (
                  !creditoConformeDocumento ||
                  (regimeTributario && IsSimplesNacional(regimeTributario))
                ) {
                  return;
                }

                const cst = formRef.current?.getFieldValueNomeObjeto(
                  'conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria'
                );

                formRef.current?.setFieldValue(
                  'conhecimentoTransporteTerceiroImpostoIcmsFiscal.situacaoTributaria',
                  cst
                );
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Emitente pertencente ao Simples Nacional"
                name="conhecimentoTransporteTerceiroImpostoIcms.emitenteSimplesNacional"
                onChangeValue={(event) => {
                  handleChangeEmitenteSimplesNacional(event.valorAtual);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </div>
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            style={{
              display: `${showCamposIcms ? 'flex' : 'none'}`,
            }}
          >
            <div className="checkbox-container">
              <InputCheckbox
                label="Calcular ICMS"
                name="conhecimentoTransporteTerceiroImpostoIcms.calcularIcms"
                onChangeValue={(event) => {
                  handleChangeCalcularIcms(event.valorAtual);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </div>
          </Col>
        </Row>

        <Row
          style={{
            display: `${showCamposIcms ? 'flex' : 'none'}`,
          }}
        >
          <Col
            lg={3}
            style={{
              display: `${showReducaoBaseCalculo ? 'flex' : 'none'}`,
            }}
          >
            <InputDecimalNulavel
              label="Percentual Redução Base Cálculo"
              casasDecimais={6}
              casasInteiras={5}
              placeholder="0,000000"
              name="conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col lg={3}>
            <InputDecimalNulavel
              label="Base Cálculo"
              casasDecimais={2}
              casasInteiras={13}
              placeholder="0,00"
              name="conhecimentoTransporteTerceiroImpostoIcms.baseCalculo"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col lg={3}>
            <InputDecimalNulavel
              label="Alíquota"
              casasDecimais={2}
              casasInteiras={3}
              placeholder="0,00"
              name="conhecimentoTransporteTerceiroImpostoIcms.aliquota"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col lg={3}>
            <InputDecimalNulavel
              label="Valor Total"
              casasDecimais={2}
              casasInteiras={13}
              placeholder="0,00"
              name="conhecimentoTransporteTerceiroImpostoIcms.valor"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default DocumentoTab;
