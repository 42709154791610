"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarDataParaPtBrSemTimeZone;
const date_fns_1 = require("date-fns");
function FormatarDataParaPtBrSemTimeZone(value) {
    if (!value)
        return '';
    const dataSemUtc = new Date(value);
    const dataComUtc = new Date(dataSemUtc.getTime() + dataSemUtc.getTimezoneOffset() * 60000);
    return (0, date_fns_1.format)(dataComUtc, 'dd/MM/yyyy');
}
