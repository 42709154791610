import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/produtos/produto-por-tipo-sped';

interface IProdutoPorTipoSpedRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ProdutoPorTipoSpedRelatorioComunicador {
  public async index(
    params: IProdutoPorTipoSpedRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ProdutoPorTipoSpedRelatorioComunicador();
