const EstoqueExportacaoPlanilha = [
  {
    titulo: 'produto.codigo',
    valor: 'produto.codigo',
  },
  {
    titulo: 'produto.descricao',
    valor: 'produto.descricao',
  },
  {
    titulo: 'produto.ativo',
    valor: 'produto.ativo',
  },
  {
    titulo: 'saldo',
    valor: 'saldo',
  },
  {
    titulo: 'estoqueIndicadorTipo',
    valor: 'estoqueIndicadorTipo',
  },
  {
    titulo: 'localEstoque.descricao',
    valor: 'localEstoque.descricao',
  },
  {
    titulo: 'lote.codigo',
    valor: 'lote.codigo',
  },
  {
    titulo: 'pessoa.codigo',
    valor: 'pessoa.codigo',
  },
  {
    titulo: 'pessoa.nomeRazaoSocial',
    valor: 'pessoa.nomeRazaoSocial',
  },
  {
    titulo: 'pessoa.cnpj',
    valor: 'pessoa.cnpj',
  },
  {
    titulo: 'pessoa.cpf',
    valor: 'pessoa.cpf',
  },
  {
    titulo: 'valorUnitarioCustoContabilIr',
    valor: 'valorUnitarioCustoContabilIr',
  },
  {
    titulo: 'valorUnitarioCustoContabilIcmsIpi',
    valor: 'valorUnitarioCustoContabilIcmsIpi',
  },
  {
    titulo: 'valorUnitarioCustoUltimaCompra',
    valor: 'valorUnitarioCustoUltimaCompra',
  },
  {
    titulo: 'valorUnitarioCustoMedio',
    valor: 'valorUnitarioCustoMedio',
  },
];

export default EstoqueExportacaoPlanilha;
