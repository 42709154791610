import api from '../../../../../Comum/Services/Api';

const rota =
  'transporte/manifesto-transporte/documento-referenciado/lista-documentos';
const rotaNovo = 'transporte/manifesto-transporte';

interface IManifestoTransporteDocumentoReferenciadoListaDocumentoComunicadorIndex {
  params: any;
}

interface IManifestoTransporteDocumentoReferenciadoListaDocumentoComunicadorStore {
  idPai: string;
  params: any;
}

class ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador {
  public async index(
    params: IManifestoTransporteDocumentoReferenciadoListaDocumentoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }

  public async store(
    params: IManifestoTransporteDocumentoReferenciadoListaDocumentoComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/documento-referenciado/inserir-lista-documentos`,
      params.params
    );

    return response.data;
  }
}

export default new ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador();
