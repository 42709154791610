import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-financeiro';

interface IImportacaoXmlTerceiroNfeObterFinanceiroComunicadorIndex {
  params: {
    idImportacaoXml: string;
    somaTotalFinanceiroItens: number;
  };
}

class ImportacaoXmlTerceiroNfeObterFinanceiroComunicador {
  public async index(
    params: IImportacaoXmlTerceiroNfeObterFinanceiroComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterFinanceiroComunicador();
