import React, { useState, useEffect } from 'react';
import { Tab, Table, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import {
  IAberturaFechamentoCaixaValoresFechamentoLista,
  MeioPagamentoEnum,
} from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';
import { UltimaTr } from '../Main/styles';

const FechamentoTab: React.FC = () => {
  const { formRef: formPrincipal } = UseForm();

  const [listaValoresRecebidos, setListaValoresRecebidos] = useState<
    IAberturaFechamentoCaixaValoresFechamentoLista[]
  >([]);
  const [listaMovimentos, setListaMovimentos] = useState<
    IAberturaFechamentoCaixaValoresFechamentoLista[]
  >([]);

  const valorEmDinheiro =
    formPrincipal.current?.getFieldValue('valorEmDinheiro');

  useEffect(() => {
    setListaValoresRecebidos(
      formPrincipal.current?.getFieldValue('listaValoresRecebidos')
    );
    setListaMovimentos(formPrincipal.current?.getFieldValue('listaMovimentos'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Tabs id={v4()} defaultActiveKey="valoresRecebidos">
        <Tab eventKey="valoresRecebidos" title="Valores Recebidos">
          <Tabela style={{ overflow: 'auto', width: 'fit-content' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '60%' }}>
                    <span>Forma Pagamento</span>
                  </th>
                  <th className="lista-valor" style={{ width: '40%' }}>
                    <span>Valor</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValoresRecebidos &&
                  listaValoresRecebidos.length > 0 &&
                  listaValoresRecebidos.map((item) => {
                    return (
                      <tr key={String(item.id)}>
                        <td className="lista-texto">{item.descricao}</td>
                        <td className="lista-valor">
                          {Number(item.valor).FormatarParaPtBr()}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>

              <tfoot>
                <UltimaTr>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 16 }}
                    >
                      Total em Dinheiro
                    </div>
                    <div
                      className="lista-valor"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'green',
                      }}
                    >
                      (Abertura + Receb. Dinheiro)
                    </div>
                  </th>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {Number(
                        valorEmDinheiro +
                          listaValoresRecebidos.reduce(
                            (
                              acumulador: number,
                              item: IAberturaFechamentoCaixaValoresFechamentoLista
                            ) => {
                              if (
                                item.meioPagamento ===
                                MeioPagamentoEnum.dinheiro
                              )
                                acumulador += Number(item.valor);
                              return acumulador;
                            },
                            0
                          )
                      ).FormatarParaPtBr()}
                    </div>
                  </th>
                </UltimaTr>

                <UltimaTr>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 16 }}
                    >
                      Total Geral
                    </div>
                    <div
                      className="lista-valor"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'green',
                      }}
                    >
                      (Abertura + Pagamentos)
                    </div>
                  </th>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {Number(
                        valorEmDinheiro +
                          listaValoresRecebidos.reduce(
                            (
                              acumulador: number,
                              item: IAberturaFechamentoCaixaValoresFechamentoLista
                            ) => {
                              acumulador += Number(item.valor);
                              return acumulador;
                            },
                            0
                          )
                      ).FormatarParaPtBr()}
                    </div>
                  </th>
                </UltimaTr>
              </tfoot>
            </Table>
          </Tabela>
        </Tab>

        <Tab eventKey="movimentos" title="Movimentos (Parcelas)">
          <Tabela style={{ overflow: 'auto', width: 'fit-content' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '60%' }}>
                    <span>Forma Pagamento</span>
                  </th>
                  <th className="lista-valor" style={{ width: '40%' }}>
                    <span>Valor</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaMovimentos &&
                  listaMovimentos.length > 0 &&
                  listaMovimentos.map((item) => {
                    return (
                      <tr key={String(item.id)}>
                        <td className="lista-texto">{item.descricao}</td>
                        <td className="lista-valor">
                          {Number(item.valor).FormatarParaPtBr()}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>

              <tfoot>
                <UltimaTr>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 16 }}
                    >
                      Total em Dinheiro
                    </div>
                    <div
                      className="lista-valor"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'green',
                      }}
                    >
                      (Abertura + Receb. Dinheiro)
                    </div>
                  </th>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {Number(
                        valorEmDinheiro +
                          listaMovimentos.reduce(
                            (
                              acumulador: number,
                              item: IAberturaFechamentoCaixaValoresFechamentoLista
                            ) => {
                              if (
                                item.meioPagamento ===
                                MeioPagamentoEnum.dinheiro
                              )
                                acumulador += Number(item.valor);
                              return acumulador;
                            },
                            0
                          )
                      ).FormatarParaPtBr()}
                    </div>
                  </th>
                </UltimaTr>

                <UltimaTr>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 16 }}
                    >
                      Total Geral
                    </div>
                    <div
                      className="lista-valor"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'green',
                      }}
                    >
                      (Abertura + Pagamentos)
                    </div>
                  </th>
                  <th>
                    <div
                      className="lista-valor"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {Number(
                        valorEmDinheiro +
                          listaMovimentos.reduce(
                            (
                              acumulador: number,
                              item: IAberturaFechamentoCaixaValoresFechamentoLista
                            ) => {
                              acumulador += Number(item.valor);
                              return acumulador;
                            },
                            0
                          )
                      ).FormatarParaPtBr()}
                    </div>
                  </th>
                </UltimaTr>
              </tfoot>
            </Table>
          </Tabela>
        </Tab>
      </Tabs>
    </div>
  );
};

export default FechamentoTab;
