/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  INotaFiscalEntradaValoresAlterar,
  FormatarEnum,
  IndicadorPresencaPadraoEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
  SituacaoNotaFiscalEntradaEnum,
  FinalidadeEmissaoEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import TotalTab from '../TotaisTab/index';
import TransporteTab from '../TransporteTab/index';
import InformacaoContribuinteTab from '../InformacaoComercialTab/index';
import InformacaoFiscoTab from '../informacaoFiscoTab';
import ObservacaoComercialTab from '../informacaoContribuinteTab/index';
import ObservacaoInternaTab from '../ObservacaoInternaTab/index';
import ItemTab from '../ItemTab/index';
import InputAutoCompleteFornecedor from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteFornecedor';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import Select from '../../../../../../Componentes/Select';
import PessoaTab from '../PessoaTab';
import { UseNotaFiscalEntrada } from '../Hook/NotaFiscalEntradaHook';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import AutorizacaoTab from '../AutorizacaoTab';
import InputAutoCompleteSerie from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import Input from '../../../../../../Componentes/Inputs/Input';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';
import NotaFiscalEntradaObterSerieComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterSerieComunicador';
import InputAutoCompleteModeloDocumento from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import NotaFiscalEntradaObterNumeroComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterNumeroComunicador';

type IProps = IMain<INotaFiscalEntradaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { atualizarDadosFornecedorConformeCadastro, obterDadosPadraoCliente } =
    UseNotaFiscalEntrada();
  const { setRegimeTributarioPorData } = UseRegimeTributario();
  const { abrirJanela } = UseAlerta();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();
  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const [tabSelecionada, setTabSelecionada] = useState('itens');
  const [idFornecedorAnterior, setIdFornecedorAnterior] = useState('');

  const finalidadeEmissao = formRef.current?.getFieldValue('finalidadeEmissao');

  const serie = formRef.current?.getFieldValueNomeObjeto('serie');
  const incrementarSeire = serie ? serie.incrementarAutomaticamente : true;

  const tipoNotaFiscalEntradaTerceiros =
    formRef.current?.getFieldValue('tipoNotaFiscalEntrada') ===
    TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros;

  const tipoNotaFiscalEntradaPropria =
    formRef.current?.getFieldValue('tipoNotaFiscalEntrada') ===
    TipoPropriaTerceiroNotaFiscalEntradaEnum.propria;

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idEditar,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarDadosFornecedorConformeCadastro =
    useCallback(async () => {
      atualizarDadosFornecedorConformeCadastro();
    }, [atualizarDadosFornecedorConformeCadastro]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    obterDadosPadraoCliente();
  }, [obterDadosPadraoCliente]);

  const handleOnAlterarDataHoraEmissao = useCallback(async () => {
    try {
      setLoading(true);

      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');

      await setRegimeTributarioPorData(new Date(dataHoraEmissao));

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading, setRegimeTributarioPorData]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaNotaFiscalEntradaItem = formRef.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    if (listaNotaFiscalEntradaItem && listaNotaFiscalEntradaItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração do Fornecedor ou Indicador de Presença, as
            informações tributárias e valores podem estar incorretos. Salve para
            atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  // Toda vez que o tipo de entrada muda, a série deve ser resetada
  useEffect(() => {
    if (tipoNotaFiscalEntradaPropria) {
      formRef.current?.setFieldValue('idSerie', '');
      formRef.current?.setFieldValue('serieCodigo', '');
    } else {
      formRef.current?.setFieldValue('idSerie', '');
    }
  }, [formRef, tipoNotaFiscalEntradaPropria]);

  const handleAtualizarSerie = useCallback(
    async (finalidadeEmissaoAlterada: FinalidadeEmissaoEnum) => {
      try {
        setLoading(true);

        const response = await NotaFiscalEntradaObterSerieComunicador.show({
          finalidadeEmissao: finalidadeEmissaoAlterada,
        });

        formRef.current?.setFieldValue('serie', response);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, setLoading]
  );

  const handleAtualizarNumero = useCallback(
    async (idSerie?: string) => {
      try {
        setLoading(true);

        const idNotaFiscalEntrada = getIdDetalheRegistro();

        if (idNotaFiscalEntrada) {
          if (!idSerie) {
            formRef.current.setFieldValue('numero', 0);
          } else {
            const response = await NotaFiscalEntradaObterNumeroComunicador.show(
              {
                idNotaFiscalEntrada,
                idSerie,
              }
            );

            formRef.current.setFieldValue('numero', response);
          }
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, getIdDetalheRegistro, setLoading]
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <InputHiddenHtml name="id" />
            {/* <InputHiddenHtml name="idModeloDocumento" /> */}
            <InputHiddenHtml name="financeiroConferido" />
            <InputHiddenHtml name="valorFinanceiro" />
            <InputHiddenHtml name="possuiPedidosVinculados" />

            <Col xl={2} lg={3} md={6} sm={12}>
              <Select
                label="Tipo de Entrada"
                name="tipoNotaFiscalEntrada"
                options={FormatarEnum({
                  enumObj: TipoPropriaTerceiroNotaFiscalEntradaEnum,
                })}
                onChange={(event) => {
                  refresh();

                  if (
                    event.currentTarget.value ===
                    TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros
                  ) {
                    formRef.current?.setFieldValue(
                      'dataHoraEntrada',
                      new Date()
                    );
                    formRef.current?.setFieldValue(
                      'dataHoraEntradaEstabelecimento',
                      new Date()
                    );
                    formRef.current?.setFieldValue(
                      'dataHoraLancamento',
                      new Date()
                    );

                    formRef.current?.setFieldValue(
                      'situacao',
                      SituacaoNotaFiscalEntradaEnum.autorizada
                    );
                  } else {
                    formRef.current?.setFieldValue(
                      'situacao',
                      SituacaoNotaFiscalEntradaEnum.naoTransmitida
                    );
                  }
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              style={{
                display: tipoNotaFiscalEntradaTerceiros ? '' : 'none',
              }}
            >
              <InputAutoCompleteModeloDocumento
                label="Modelo de Documento"
                name="idModeloDocumento"
                nomeObjeto="modeloDocumento"
                placeholder="Modelo de Documento"
                listaTipoDocumentoFiscal={[
                  ModeloDocumentoTipoEnum.notaFiscal,
                  ModeloDocumentoTipoEnum.notaFiscalServico,
                ]}
              />
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: tipoNotaFiscalEntradaTerceiros ? 'none' : '',
              }}
            >
              <InputAutoCompleteSerie
                label="Série"
                name="idSerie"
                placeholder="Série"
                nomeObjeto="serie"
                listaTipoDocumentoFiscal={[
                  ModeloDocumentoTipoEnum.notaFiscal,
                  ModeloDocumentoTipoEnum.notaFiscalServico,
                ]}
                utilizavelNotaFiscalEntrada
                utilizavelNotaFiscalAjuste={
                  finalidadeEmissao === FinalidadeEmissaoEnum.ajuste
                    ? true
                    : undefined
                }
                utilizavelNotaFiscalComplementar={
                  finalidadeEmissao === FinalidadeEmissaoEnum.complemento
                    ? true
                    : undefined
                }
                onChangeItemAtualAposCarregarSemClear={(event) => {
                  if (event.itemAtual?.modeloDocumento) {
                    formRef.current?.setFieldValue(
                      'modeloDocumento',
                      event.itemAtual?.modeloDocumento
                    );
                  }

                  formRef.current?.setFieldValue(
                    'serieCodigo',
                    event.itemAtual?.codigo
                  );

                  refresh();
                  handleAtualizarNumero(event.itemAtual?.id);
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: tipoNotaFiscalEntradaPropria ? 'none' : '',
              }}
            >
              <Input
                label="Série"
                name="serieCodigo"
                placeholder="Série"
                maxLength={3}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled={tipoNotaFiscalEntradaPropria && incrementarSeire}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let emTransmissao = false;
                  let autorizada = false;
                  let denegada = false;
                  let cancelada = false;
                  let rejeitada = false;

                  if (situacao.includes('Em Transmissão')) emTransmissao = true;
                  if (situacao.includes('Autorizada')) autorizada = true;
                  if (situacao.includes('Denegada')) denegada = true;
                  if (situacao.includes('Cancelada')) cancelada = true;
                  if (situacao.includes('Rejeitada')) rejeitada = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{
                        fontWeight: 'bold',
                        alignItems: 'flex-start',
                        color: emTransmissao
                          ? 'gray'
                          : autorizada
                            ? 'green'
                            : denegada
                              ? 'blue'
                              : cancelada
                                ? 'red'
                                : rejeitada
                                  ? 'goldenrod'
                                  : 'black',
                      }}
                    >
                      {situacao}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Emissão"
                name="dataHoraEmissao"
                onChange={handleOnAlterarDataHoraEmissao}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Entrada"
                name="dataHoraEntrada"
                disabled={situacaoBloquearCampos}
              />
            </Col>

            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: tipoNotaFiscalEntradaTerceiros ? '' : 'none',
              }}
            >
              <InputDateTime
                label="Data Hora Lançamento"
                name="dataHoraLancamento"
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: tipoNotaFiscalEntradaTerceiros ? '' : 'none',
              }}
            >
              <InputDateTime
                label="Data Hora Ent. Est."
                name="dataHoraEntradaEstabelecimento"
                onChange={handleOnAlterarDataHoraEmissao}
                disabled={situacaoBloquearCampos}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={7} md={12} sm={12}>
              <InputAutoCompleteFornecedor
                ref={inputRefFocus}
                label="Fornecedor"
                name="notaFiscalEntradaPessoa.idPessoa"
                nomeObjeto="notaFiscalEntradaPessoa.pessoa"
                placeholder="Fornecedor"
                informacaoAdicional
                onChangeItemAtual={(event) => {
                  if (event.itemAtual) {
                    setIdFornecedorAnterior(event.itemAtual.id);
                  } else {
                    setIdFornecedorAnterior('');
                  }
                }}
                onChangeItemAtualAposCarregarSemClear={(event) => {
                  refresh();

                  if (
                    event.itemAtual &&
                    event.itemAtual.id !== idFornecedorAnterior
                  ) {
                    handleAtualizarDadosFornecedorConformeCadastro();
                    handleExibirMensagemAtualizacao();
                    handleObterDadosPadraoCliente();
                  }
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Finalidade Emissão"
                name="finalidadeEmissao"
                options={FormatarEnum({
                  enumObj: FinalidadeEmissaoEnum,
                  nullavel: true,
                })}
                disabled={situacaoBloquearCampos}
                onChange={async (event) => {
                  refresh();
                  await handleAtualizarSerie(
                    event.currentTarget.value as FinalidadeEmissaoEnum
                  );
                }}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Indicador de Presença"
                name="indicadorPresenca"
                options={FormatarEnum({
                  enumObj: IndicadorPresencaPadraoEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                  handleExibirMensagemAtualizacao();
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="itens" title="Itens">
            <ItemTab />
          </Tab>

          <Tab eventKey="totais" title="Totais">
            <TotalTab />
          </Tab>

          <Tab eventKey="transporte" title="Transporte">
            <TransporteTab />
          </Tab>

          <Tab eventKey="pessoa" title="Fornecedor">
            <PessoaTab />
          </Tab>

          <Tab eventKey="informacoesComerciais" title="Inf. Comerciais">
            <InformacaoContribuinteTab />
          </Tab>

          <Tab eventKey="informacoesContribuinte" title="Inf. Contribuinte">
            <ObservacaoComercialTab />
          </Tab>

          <Tab eventKey="informacoesFisco" title="Inf. Fisco">
            <InformacaoFiscoTab />
          </Tab>

          <Tab eventKey="observacoesInternas" title="Obs. Internas">
            <ObservacaoInternaTab />
          </Tab>

          <Tab eventKey="autorizacao" title="Autorização">
            <AutorizacaoTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
