import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const Container = styled.div``;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;

export const UltimaTr = styled.tr`
  background-color: white;
  border-color: transparent;

  th,
  div {
    background-color: white;
  }
`;

export const ButtonAtualizarDados = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2bcebe !important;
  color: #fff;
  font-weight: 600;
  border: 0;
  &:hover {
    background: #26b9ab !important;
  }
  &:active {
    background: #22a498 !important;
  }
  &:focus {
    background: #2bcebe !important;
    box-shadow: 0 0 0 0.2rem rgba(30, 144, 133, 0.25) !important;
  }
  &:disabled {
    opacity: 0.7;
    color: #b9b9b9;
    background: rgba(30, 144, 133, 0.2) !important;
  }
`;
