import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IEstoqueMovimentoSisdevinLista } from '@elogestor/util';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface ISisdevinEstoqueMovimentoListaContext {
  validador: boolean;

  listaValor: IEstoqueMovimentoSisdevinLista[];
  setListaValor(lista: IEstoqueMovimentoSisdevinLista[]): void;

  possuiItemSelecionado: boolean;
  setPossuiItemSelecionado(possui: boolean): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
}

const SisdevinEstoqueMovimentoListaContext =
  createContext<ISisdevinEstoqueMovimentoListaContext>(
    {} as ISisdevinEstoqueMovimentoListaContext
  );

const SisdevinEstoqueMovimentoListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IEstoqueMovimentoSisdevinLista[]
  >([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleSetListaValor = useCallback(
    async (lista: IEstoqueMovimentoSisdevinLista[]) => {
      setListaValor(lista);
    },
    []
  );

  const handleSetPossuiItemSelecionado = useCallback(
    async (possui: boolean) => {
      setPossuiItemSelecionado(possui);
    },
    []
  );

  return (
    <SisdevinEstoqueMovimentoListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        possuiItemSelecionado,
        setPossuiItemSelecionado: handleSetPossuiItemSelecionado,

        listaItemSelecionadoRef,

        validador: true,
      }}
    >
      {children}
    </SisdevinEstoqueMovimentoListaContext.Provider>
  );
};

function UseSisdevinEstoqueMovimentoLista(): Omit<
  ISisdevinEstoqueMovimentoListaContext,
  'validador'
> {
  const context = useContext(SisdevinEstoqueMovimentoListaContext);

  if (!context.validador) {
    throw new Error(
      'UseSisdevinEstoqueMovimentoLista deve ser usado com um SisdevinEstoqueMovimentoListaHook'
    );
  }

  return context;
}

export { SisdevinEstoqueMovimentoListaHook, UseSisdevinEstoqueMovimentoLista };
