import { INotaFiscalEntradaValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/obter-itens-calculo-rateios';

interface IObterNotaFiscalEntradaItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterNotaFiscalEntradaItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterNotaFiscalEntradaItensParaCalculoRateiosComunicadorIndex
  ): Promise<INotaFiscalEntradaValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterNotaFiscalEntradaItensParaCalculoRateiosComunicador();
