import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';
import ListaDetalheAutorizacao from './ListaDetalheAutorizacao/Lista';

interface IAutorizacaoTab {
  bloquearCamposPorImportacao: boolean;
}

const AutorizacaoTab: React.FC<IAutorizacaoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  InputHidden({
    name: 'conhecimentoTransporteTerceiroTransmissao.idImportacaoXmlAutorizacao',
  });

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Input
              label="Chave de Acesso"
              name="conhecimentoTransporteTerceiroTransmissao.chaveAcesso"
              maxLength={44}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Input
              label="Protocolo"
              name="conhecimentoTransporteTerceiroTransmissao.numeroProtocolo"
              maxLength={15}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={12}>
            <InputDateTime
              label="Data e Hora"
              name="conhecimentoTransporteTerceiroTransmissao.dataHoraAutorizacao"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <ListaDetalheAutorizacao
          name="listaConhecimentoTransporteTerceiroTransmissaoEvento"
          bloquearCamposPorImportacao={bloquearCamposPorImportacao}
        />
      </Divisor>
    </div>
  );
};

export default AutorizacaoTab;
