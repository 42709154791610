import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IsSimplesNacional } from '@elogestor/util';
import { UseRegimeTributario } from '../../../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { UseImpostoIcms } from '../../Hooks/ImpostoIcmsHook';
import Divisor from '../../../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import SituacaoTributariaObterPorCstComunicador from '../../../../../../../../Comunicador/Fiscal/SituacaoTributaria/Comunicador/SituacaoTributariaObterPorCstComunicador';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';

const LucroRealTab: React.FC = () => {
  const { formRef } = UseForm();

  const { regimeTributario } = UseRegimeTributario();
  const { handleAtualizarImpostoIcmsFiscalConformeCst } = UseImpostoIcms();

  const [showCamposIcms, setShowCamposIcms] = useState(true);
  const [showReducaoBaseCalculo, setShowReducaoBaseCalculo] = useState(true);

  const handleChangeCst = useCallback((cst: string) => {
    if (!cst) {
      setShowCamposIcms(true);
      setShowReducaoBaseCalculo(true);
    }

    if (cst === '00') {
      setShowCamposIcms(true);
      setShowReducaoBaseCalculo(false);
    }

    if (cst === '20' || cst === '90') {
      setShowCamposIcms(true);
      setShowReducaoBaseCalculo(true);
    }

    if (cst === '40' || cst === '41' || cst === '51' || cst === '60') {
      setShowCamposIcms(false);
      setShowReducaoBaseCalculo(false);
    }
  }, []);

  const handleCreditoConformeDocumento = useCallback(
    (opcao: boolean) => {
      formRef.current?.setFieldDisabled(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.percentualReducaoBaseCalculo',
        opcao
      );
      formRef.current?.setFieldDisabled(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.baseCalculo',
        opcao
      );
      formRef.current?.setFieldDisabled(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.aliquota',
        opcao
      );
      formRef.current?.setFieldDisabled(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.valor',
        opcao
      );
      if (!opcao) return;

      const situacaoTributariaDocumento =
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteTerceiroImpostoIcms.situacaoTributaria'
        );

      if (
        situacaoTributariaDocumento &&
        regimeTributario &&
        !IsSimplesNacional(regimeTributario)
      ) {
        handleChangeCst(situacaoTributariaDocumento.codigo);
      }

      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.situacaoTributaria',
        situacaoTributariaDocumento
      );

      formRef.current?.setFieldValorInicial(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.percentualReducaoBaseCalculo',
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteTerceiroImpostoIcms.percentualReducaoBaseCalculo'
        )
      );

      formRef.current?.setFieldValorInicial(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.baseCalculo',
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteTerceiroImpostoIcms.baseCalculo'
        )
      );

      formRef.current?.setFieldValorInicial(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.aliquota',
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteTerceiroImpostoIcms.aliquota'
        )
      );

      formRef.current?.setFieldValorInicial(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.valor',
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteTerceiroImpostoIcms.valor'
        )
      );
    },
    [formRef, handleChangeCst, regimeTributario]
  );

  const creditoConformeDocumento = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.creditoConformeDocumento'
  );

  useEffect(() => {
    formRef.current?.setFieldDisabled(
      'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.percentualReducaoBaseCalculo',
      creditoConformeDocumento
    );
    formRef.current?.setFieldDisabled(
      'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.baseCalculo',
      creditoConformeDocumento
    );
    formRef.current?.setFieldDisabled(
      'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.aliquota',
      creditoConformeDocumento
    );
    formRef.current?.setFieldDisabled(
      'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.valor',
      creditoConformeDocumento
    );
  }, [creditoConformeDocumento, formRef]);

  const currentCst = formRef.current?.getFieldValueNomeObjeto(
    'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.situacaoTributaria'
  );

  useEffect(() => {
    handleChangeCst(currentCst?.codigo);
  }, [currentCst, handleChangeCst]);

  useEffect(() => {
    (async () => {
      const cstAtual = formRef.current?.getFieldValueNomeObjeto(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.situacaoTributaria'
      );

      if (
        !regimeTributario ||
        (regimeTributario && !IsSimplesNacional(regimeTributario))
      ) {
        return;
      }

      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.creditoConformeDocumento',
        false
      );

      if (cstAtual?.codigo === '90') {
        return;
      }

      const situacaoTributaria =
        await SituacaoTributariaObterPorCstComunicador.show({
          params: { cst: '90' },
        });

      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.situacaoTributaria',
        situacaoTributaria
      );
    })();
  }, [formRef, regimeTributario]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.id" />
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Crédito Conforme Documento Fiscal"
                name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.creditoConformeDocumento"
                onChangeValue={(event) => {
                  handleCreditoConformeDocumento(event.valorAtual);
                }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CST"
              placeholder="CST"
              name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.idSituacaoTributaria"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.situacaoTributaria"
              icmsConhecimentoTransporte
              onChangeItemAtual={(event) => {
                handleChangeCst(event.itemAtual?.codigo || '');
                handleAtualizarImpostoIcmsFiscalConformeCst(
                  event.itemAtual?.codigo || ''
                );
              }}
            />
          </Col>
        </Row>
        <div>
          <Row
            style={{
              display: `${showCamposIcms ? 'flex' : 'none'}`,
            }}
          >
            <Col
              lg={3}
              style={{
                display: `${showReducaoBaseCalculo ? 'flex' : 'none'}`,
              }}
            >
              <InputDecimalNulavel
                label="Percentual Redução Base Cálculo"
                casasDecimais={6}
                casasInteiras={5}
                placeholder="0,000000"
                name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.percentualReducaoBaseCalculo"
              />
            </Col>

            <Col lg={3}>
              <InputDecimalNulavel
                label="Base Cálculo"
                casasDecimais={2}
                casasInteiras={13}
                placeholder="0,00"
                name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.baseCalculo"
              />
            </Col>

            <Col lg={3}>
              <InputDecimalNulavel
                label="Alíquota"
                casasDecimais={2}
                casasInteiras={3}
                placeholder="0,00"
                name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.aliquota"
              />
            </Col>

            <Col lg={3}>
              <InputDecimalNulavel
                label="Valor Total"
                casasDecimais={2}
                casasInteiras={13}
                placeholder="0,00"
                name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalReal.valor"
              />
            </Col>
          </Row>
        </div>
      </Divisor>
    </div>
  );
};

export default LucroRealTab;
