/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Modal,
  Container,
  Button,
  Table,
  OverlayTrigger,
  Popover,
  Tooltip,
  Dropdown,
} from 'react-bootstrap';
import { v4 } from 'uuid';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataHoraParaPtBr,
  IFrenteCaixaPdvPesquisarLista,
  TipoPedidoVendaEnum,
  TipoPesquisaFrenteCaixaPdvEnum,
} from '@elogestor/util';
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineFileSearch,
  AiOutlineSearch,
} from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import Paginacao from './Paginacao';
import PesquisaAvancada from './PesquisaAvancada';
import FrenteCaixaPdvObterNotaFiscalSaidaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterNotaFiscalSaidaComunicador';
import {
  Botoes,
  PesquisaGrande,
} from '../../../../../../../Componentes/Lista/SubHeader/styles';
import { UseReactSizeMeBodyHook } from '../../../../../../../Hooks/ReactSizeMeBodyHook';
import OverlayPesquisaAvancada from '../../../../../../../Componentes/Lista/OverlayPesquisaAvancada';
import { UseLista } from '../../../../../../../Componentes/Lista/ListaHooks';
import { UseTableDraggable } from '../../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import FrenteCaixaPdvObterPedidoVendaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPedidoVendaComunicador';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import PedidoVendaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IPesquisarListaModal {
  titulo: string;
  tipoPesquisa: TipoPesquisaFrenteCaixaPdvEnum;
  onSelecionarItemFormModal(idItemSelecionado: string): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const PesquisarLista: React.FC<IPesquisarListaModal> = ({
  titulo,
  tipoPesquisa,
  onFecharFormModal,
  onSelecionarItemFormModal,
  loading,
  setLoading,
}) => {
  const {
    listaValor,
    setListaValor,
    paginaAtual,
    setPaginaAtual,
    limite,
    setLimite,
    setTotalPaginas,
  } = UseListaDraggable();
  const { telaPequena, telaGrande } = UseReactSizeMeBodyHook();
  const { abrirPesquisaAvancada } = UseLista();
  const { parametros, setParametros, overlayPesquisaAvancadaValores } =
    UseTableDraggable();
  const { abrirJanela } = UseConfirmacao();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      let listaDados: IFrenteCaixaPdvPesquisarLista[] = [];
      let quantidadePaginas = 1;

      switch (tipoPesquisa) {
        case TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida:
          {
            const response =
              await FrenteCaixaPdvObterNotaFiscalSaidaComunicador.index({
                params: {
                  pesquisaAvancada: parametros.pesquisaAvancada,
                  order: parametros.order,
                  descAsc: parametros.descAsc,
                  limite,
                  pagina: paginaAtual,
                  textoPesquisa: parametros.textoPesquisa,
                },
              });

            listaDados = response.dados[0].map((data: any) => {
              const retorno: IFrenteCaixaPdvPesquisarLista = {
                id: data.id,
                numero: data.numero,
                dataHoraEmissao: data.dataHoraEmissao,
                cliente: {
                  id: data.notaFiscalSaidaPessoa.pessoa.id,
                  codigo: data.notaFiscalSaidaPessoa.pessoa.codigo,
                  nomeRazaoSocial:
                    data.notaFiscalSaidaPessoa.pessoa.nomeRazaoSocial,
                },
                valorProdutos: Number(data.valorTotalProdutos),
                valorTotal: Number(data.valorTotalBruto),
                situacao: data.situacao,
              };

              return retorno;
            });

            quantidadePaginas = response.dados[1];
          }
          break;

        case TipoPesquisaFrenteCaixaPdvEnum.preVenda:
          {
            const response =
              await FrenteCaixaPdvObterPedidoVendaComunicador.index({
                params: {
                  tipoPedidoVenda: TipoPedidoVendaEnum.preVenda,
                  pesquisaAvancada: parametros.pesquisaAvancada,
                  order: undefined,
                  descAsc: undefined,
                  limite,
                  pagina: paginaAtual,
                  textoPesquisa: parametros.textoPesquisa,
                },
              });

            listaDados = response.dados[0].map((data: any) => {
              const retorno: IFrenteCaixaPdvPesquisarLista = {
                id: data.id,
                numero: data.numero,
                dataHoraEmissao: data.dataHoraEmissao,
                cliente: {
                  id: data.pedidoVendaPessoa.pessoa.id,
                  codigo: data.pedidoVendaPessoa.pessoa.codigo,
                  nomeRazaoSocial:
                    data.pedidoVendaPessoa.pessoa.nomeRazaoSocial,
                },
                valorProdutos: Number(data.valorTotalProdutos),
                valorTotal: Number(data.valorTotalBruto),
                situacao: data.situacaoPedidoVendaNotaFiscalSaida,
              };

              return retorno;
            });

            quantidadePaginas = response.dados[1];
          }
          break;

        case TipoPesquisaFrenteCaixaPdvEnum.consignado:
          {
            const response =
              await FrenteCaixaPdvObterPedidoVendaComunicador.index({
                params: {
                  tipoPedidoVenda: TipoPedidoVendaEnum.consignado,
                  pesquisaAvancada: parametros.pesquisaAvancada,
                  order: undefined,
                  descAsc: undefined,
                  limite,
                  pagina: paginaAtual,
                  textoPesquisa: parametros.textoPesquisa,
                },
              });

            listaDados = response.dados[0].map((data: any) => {
              const retorno: IFrenteCaixaPdvPesquisarLista = {
                id: data.id,
                numero: data.numero,
                dataHoraEmissao: data.dataHoraEmissao,
                cliente: {
                  id: data.pedidoVendaPessoa.pessoa.id,
                  codigo: data.pedidoVendaPessoa.pessoa.codigo,
                  nomeRazaoSocial:
                    data.pedidoVendaPessoa.pessoa.nomeRazaoSocial,
                },
                valorProdutos: Number(data.valorTotalProdutos),
                valorTotal: Number(data.valorTotalBruto),
                situacao: data.situacaoPedidoVendaNotaFiscalSaida,
              };

              return retorno;
            });

            quantidadePaginas = response.dados[1];
          }
          break;
      }

      setListaValor(listaDados);
      setTotalPaginas(quantidadePaginas);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    limite,
    paginaAtual,
    parametros.descAsc,
    parametros.order,
    parametros.pesquisaAvancada,
    parametros.textoPesquisa,
    setListaValor,
    setLoading,
    setTotalPaginas,
    tipoPesquisa,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [paginaAtual, limite, parametros, handleCarregarDados]);

  // #region Pesquisa Avancada

  const [mostrarPesquisaPequena, setMostrarPesquisaPequena] = useState(true);

  const toggleMostrarPesquisaPequena = useCallback(() => {
    setMostrarPesquisaPequena((state) => !state);
  }, []);

  const mostrarPesquisaPequenaComTamanhoMaior = useMemo(() => {
    return mostrarPesquisaPequena || telaGrande;
  }, [mostrarPesquisaPequena, telaGrande]);

  const debounceChangeRef = useRef<number | null>(null);
  const [campoPesquisa, setCampoPesquisa] = useState('');

  const handleSetarParametros = useCallback(
    async (texto: string): Promise<void> => {
      if (parametros.textoPesquisa !== texto) {
        if (debounceChangeRef.current) {
          clearTimeout(debounceChangeRef.current);
        }

        debounceChangeRef.current = window.setTimeout(async () => {
          setPaginaAtual(1);
          setParametros({
            pagina: 1,
            textoPesquisa: texto,
          });
        }, 400);
      } else {
        setPaginaAtual(1);
        setParametros({
          pagina: 1,
          textoPesquisa: texto,
        });
      }

      setPaginaAtual(1);
      setCampoPesquisa(texto);
    },
    [parametros.textoPesquisa, setPaginaAtual, setParametros]
  );

  useEffect(() => {
    setPaginaAtual(1);
    setParametros({
      pagina: 1,
      textoPesquisa: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion Pesquisa Avancada

  const handleAtualizarPadraoModal = useCallback(async (): Promise<void> => {
    setListaValor([]);
    setPaginaAtual(1);
    setTotalPaginas(1);
    setLimite(10);
    setParametros({
      pagina: 1,
      pesquisaAvancada: undefined,
    });
  }, [
    setLimite,
    setListaValor,
    setPaginaAtual,
    setParametros,
    setTotalPaginas,
  ]);

  const handleClickSelecionarItem = useCallback(
    async (index: number): Promise<void> => {
      const idItemSelecionado = listaValor[index].id;
      handleAtualizarPadraoModal();

      if (onSelecionarItemFormModal)
        onSelecionarItemFormModal(idItemSelecionado);
    },
    [handleAtualizarPadraoModal, listaValor, onSelecionarItemFormModal]
  );

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    handleAtualizarPadraoModal();

    if (onFecharFormModal) onFecharFormModal();
  }, [handleAtualizarPadraoModal, onFecharFormModal]);

  const handleExcluir = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>
          ),
        });

        if (resposta) {
          const response = await PedidoVendaComunicador.delete({ id });
          await handleCarregarDados();

          ToastSucesso(response.mensagem);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [abrirJanela, handleCarregarDados, setLoading]
  );

  return (
    <JanelaDetalhe
      titulo={titulo}
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Header>
          <Divisor
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            {!parametros.pesquisaAvancada && (
              <Botoes $telaPequena={telaPequena}>
                <div className="pesquisa" style={{ marginTop: 0 }}>
                  <div>
                    {(!mostrarPesquisaPequena || telaGrande) && (
                      <input
                        type="text"
                        value={campoPesquisa}
                        onChange={(event) => {
                          handleSetarParametros(event.target.value);
                        }}
                      />
                    )}

                    <button
                      style={{
                        position:
                          !mostrarPesquisaPequena || telaGrande
                            ? 'absolute'
                            : 'initial',
                      }}
                      type="button"
                      onClick={toggleMostrarPesquisaPequena}
                    >
                      {mostrarPesquisaPequena || telaGrande ? (
                        <AiOutlineSearch />
                      ) : (
                        <AiOutlineClose />
                      )}
                    </button>
                  </div>
                </div>
              </Botoes>
            )}

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Popover id={v4()}>
                  <OverlayPesquisaAvancada
                    pesquisaAvancada={overlayPesquisaAvancadaValores || []}
                  />
                </Popover>
              }
            >
              <PesquisaGrande
                style={{
                  display: parametros.pesquisaAvancada ? 'flex' : 'none',
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    fontSize: 22,
                    height: 38,
                    width: 38,
                  }}
                  variant="secondary"
                  onClick={abrirPesquisaAvancada}
                >
                  <AiOutlineFileSearch />
                </Button>

                <div style={{ marginTop: 0 }}>
                  <span>Pesquisa Avançada</span>
                  <button
                    type="button"
                    onClick={async () => {
                      setParametros({
                        pagina: 1,
                        pesquisaAvancada: undefined,
                      });
                    }}
                  >
                    Remover Filtro
                  </button>
                </div>
              </PesquisaGrande>
            </OverlayTrigger>

            {mostrarPesquisaPequenaComTamanhoMaior && (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id={v4()}>Pesquisa Avançada</Tooltip>}
              >
                <Button
                  variant="secondary"
                  style={{
                    display: parametros.pesquisaAvancada ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    fontSize: 22,
                    height: 38,
                    width: 38,
                    marginRight: 15,
                    marginLeft: telaPequena ? 10 : 0,
                  }}
                  onClick={() => {
                    abrirPesquisaAvancada();
                  }}
                >
                  <AiOutlineFileSearch />
                </Button>
              </OverlayTrigger>
            )}

            <PesquisaAvancada tipoPesquisa={tipoPesquisa} />
          </Divisor>
        </Modal.Header>

        <Modal.Body style={{ height: 450, display: 'flex' }}>
          <Divisor style={{ width: '100%', display: 'grid' }}>
            <Tabela style={{ overflow: 'auto', width: '100%' }}>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th
                      className="lista-valor"
                      style={{
                        width: '5%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Número</span>
                    </th>
                    <th
                      className="lista-data"
                      style={{
                        width: '20%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Data Hora Emissão</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '30%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Cliente</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '15%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Valor Produtos</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '15%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Valor Total</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '15%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Situação</span>
                    </th>
                    {tipoPesquisa !==
                      TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida && (
                      <th className="lista-acoes">
                        <span>Ações</span>
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {listaValor?.map((item, index) => {
                    let emTransmissao = false;
                    let autorizada = false;
                    let denegada = false;
                    let cancelada = false;
                    let rejeitada = false;

                    if (item.situacao.includes('Em Transmissão'))
                      emTransmissao = true;
                    if (item.situacao.includes('Autorizada')) autorizada = true;
                    if (item.situacao.includes('Denegada')) denegada = true;
                    if (item.situacao.includes('Cancelada')) cancelada = true;
                    if (item.situacao.includes('Rejeitada')) rejeitada = true;

                    let atendido = false;
                    let naoAtendido = false;
                    let parcialmenteAtendido = false;

                    if (item.situacao.includes('Parcialmente Atendido'))
                      parcialmenteAtendido = true;
                    if (item.situacao.includes('Atendido')) atendido = true;
                    if (item.situacao.includes('Não Atendido'))
                      naoAtendido = true;

                    return (
                      <>
                        <tr
                          key={v4()}
                          onClick={async () => {
                            await handleClickSelecionarItem(index);
                          }}
                        >
                          <td className="lista-valor">{item.numero}</td>
                          <td className="lista-data">
                            {FormatarDataHoraParaPtBr(item.dataHoraEmissao)}
                          </td>
                          <td className="lista-texto">
                            {`${item.cliente.codigo} - ${item.cliente.nomeRazaoSocial}`}
                          </td>
                          <td className="lista-valor">
                            {item.valorProdutos.FormatarParaPtBr()}
                          </td>
                          <td className="lista-valor">
                            {item.valorTotal.FormatarParaPtBr()}
                          </td>
                          <td
                            className="lista-texto"
                            style={{
                              fontWeight: 'bold',
                              color: emTransmissao
                                ? 'gray'
                                : cancelada || naoAtendido
                                  ? 'red'
                                  : rejeitada || parcialmenteAtendido
                                    ? 'goldenrod'
                                    : denegada
                                      ? 'blue'
                                      : autorizada || atendido
                                        ? 'green'
                                        : 'black',
                            }}
                          >
                            {item.situacao}
                          </td>

                          {tipoPesquisa !==
                            TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida && (
                            <td className="tdButton lista-acoes">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <BtnAcoesButton>
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleExcluir(item.id);
                                    }}
                                    disabled={loading}
                                  >
                                    <span
                                      style={{ display: 'flex', fontSize: 16 }}
                                    >
                                      <AiOutlineDelete
                                        style={{ marginRight: 5 }}
                                      />
                                      Excluir
                                    </span>
                                  </Dropdown.Item>
                                </BtnAcoesButton>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Tabela>

            <Paginacao />
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default PesquisarLista;
