import { Sleep } from '../../Componentes/Padrao/MenuPrincipal/Scripts';

interface IImprimirDiretoPDF {
  dados?: any;
  blob?: Blob;
}

export default async function ImprimirDiretoPDF({
  dados,
  blob,
}: IImprimirDiretoPDF): Promise<void> {
  if (!dados && !blob) return;

  if (dados) {
    const data = new Uint8Array(dados.data);
    blob = new Blob([data], { type: 'application/pdf' });
  }

  if (!blob) return;

  const url = window.URL.createObjectURL(blob);
  const janelaImpressao = window.open(url);

  await Sleep(5000);
  janelaImpressao?.print();
  await Sleep(2000);
  janelaImpressao?.close();
}
