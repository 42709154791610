const DiarioAuxiliarFornecedoresPorDocumentoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaDiarioAuxiliarItem.dataHora',
  },

  {
    titulo: 'Fornecedor ',
    valor: 'listaDiarioAuxiliarItem.clienteFornecedor',
  },

  {
    titulo: 'Número Documento ',
    valor: 'listaDiarioAuxiliarItem.numeroDocumento',
  },

  {
    titulo: 'Histórico',
    valor: 'listaDiarioAuxiliarItem.historico',
  },

  {
    titulo: 'Valor Débito',
    valor: 'listaDiarioAuxiliarItem.valorDebito',
  },

  {
    titulo: 'Valor Crédito',
    valor: 'listaDiarioAuxiliarItem.valorCredito',
  },

  {
    titulo: 'Saldo Inicial',
    valor: 'listaDiarioAuxiliarItem.saldoInicial',
  },

  {
    titulo: 'Saldo Geral',
    valor: 'listaDiarioAuxiliarItem.saldoGeral',
  },
];

export default DiarioAuxiliarFornecedoresPorDocumentoPlanilha;
