import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/marca-produto-auto-complete-tag';

interface IMarcaProdutoAutoCompleteTagComunicadorIndex {
  params: any;
}

class MarcaProdutoAutoCompleteTagComunicador {
  public async index(
    params: IMarcaProdutoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new MarcaProdutoAutoCompleteTagComunicador();
