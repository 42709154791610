const ManifestoTransporteResumoRelatorioPlanilha = [
  { titulo: 'Série', valor: 'serie' },
  { titulo: 'Número', valor: 'numero' },
  { titulo: 'Data Emissão', valor: 'dataEmissao' },
  { titulo: 'Data Hora Inicio Viagem', valor: 'dataHoraInicioViagem' },
  { titulo: 'Placa', valor: 'veiculoTracao.placa' },
  { titulo: 'Peso Bruto Total Carga', valor: 'pesoBrutoTotalCarga' },
  { titulo: 'Valor Total Produtos', valor: 'valorTotalMercadoriaTransportada' },
  { titulo: 'Situação', valor: 'situacao' },
];

export default ManifestoTransporteResumoRelatorioPlanilha;
