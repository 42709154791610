import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/tributacao/regra-escolha-aliquota/obter-aliquota-interestadual';

interface IRegraEscolhaAliquotaObterAliquotaInterestadualComunicadorShow {
  params: {
    idPessoaOrigem: string;
    idPessoaDestino: string;
    dataVigencia: Date;
  };
}

class RegraEscolhaAliquotaObterAliquotaInterestadualComunicador {
  public async show(
    params: IRegraEscolhaAliquotaObterAliquotaInterestadualComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaAliquotaObterAliquotaInterestadualComunicador();
