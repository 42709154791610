import { IPessoaValoresAlterar } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import PessoaComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaComunicador';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const parametros = UseParametros();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const isOutroEnderecoEntrega = formRef.current?.getFieldValue(
    'pessoaEnderecoEntrega.outroEnderecoEntrega'
  );

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPessoaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        dadosDuplicados.codigo = null;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await PessoaComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial({
          ...response,
          naoRecuperarFormulario: false,
        });
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          cliente: true,
          ativo: true,
          dataCadastro: new Date(),
          naoRecuperarFormulario: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nomeRazaoSocial: Yup.string().required(
            'Nome / Razão Social é obrigatório!'
          ),

          cnpjCpfVirtual: Yup.mixed()
            .nullable()
            .test({
              message: 'CPF ou CNPJ é obrigatório!',
              test: (value: any) => {
                if (!value && parametros.CNPJouCPFObrigatorio) {
                  return false;
                }
                return true;
              },
            }),

          pessoaEnderecoPrincipal: Yup.object().shape({
            logradouro: Yup.mixed()
              .nullable()
              .test({
                message: 'Logradouro é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.DadosEnderecoObrigatorios) {
                    return false;
                  }
                  return true;
                },
              }),

            numero: Yup.mixed()
              .nullable()
              .test({
                message: 'Número é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.DadosEnderecoObrigatorios) {
                    return false;
                  }
                  return true;
                },
              }),

            bairro: Yup.mixed()
              .nullable()
              .test({
                message: 'Bairro é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.DadosEnderecoObrigatorios) {
                    return false;
                  }
                  return true;
                },
              }),

            cep: Yup.mixed()
              .nullable()
              .test({
                message: 'CEP é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.DadosEnderecoObrigatorios) {
                    return false;
                  }
                  return true;
                },
              }),

            idCidade: Yup.mixed()
              .nullable()
              .test({
                message: 'Cidade é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.DadosEnderecoObrigatorios) {
                    return false;
                  }
                  return true;
                },
              }),
          }),

          listaPessoaTelefone: Yup.object().shape({
            listaValor: Yup.mixed()
              .nullable()
              .test({
                message: 'Telefone é obrigatório!',
                test: (value: any) => {
                  if (
                    (!value || value.length === 0) &&
                    parametros.DadosEnderecoObrigatorios
                  ) {
                    return false;
                  }
                  return true;
                },
              }),
          }),

          pessoaEnderecoEntrega: Yup.object().shape({
            logradouro: Yup.mixed()
              .nullable()
              .test({
                message:
                  'É necessário informar o logradouro do endereço de entrega!',
                test: (value: any) => {
                  if (!value && isOutroEnderecoEntrega) {
                    return false;
                  }
                  return true;
                },
              }),

            numero: Yup.mixed()
              .nullable()
              .test({
                message:
                  'É necessário informar o número do endereço de entrega!',
                test: (value: any) => {
                  if (!value && isOutroEnderecoEntrega) {
                    return false;
                  }
                  return true;
                },
              }),

            bairro: Yup.mixed()
              .nullable()
              .test({
                message:
                  'É necessário informar o bairro do endereço de entrega!',
                test: (value: any) => {
                  if (!value && isOutroEnderecoEntrega) {
                    return false;
                  }
                  return true;
                },
              }),

            idCidade: Yup.mixed()
              .nullable()
              .test({
                message:
                  'É necessário informar o cidade do endereço de entrega!',
                test: (value: any) => {
                  if (!value && isOutroEnderecoEntrega) {
                    return false;
                  }
                  return true;
                },
              }),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [
      isOutroEnderecoEntrega,
      parametros.CNPJouCPFObrigatorio,
      parametros.DadosEnderecoObrigatorios,
    ]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await PessoaComunicador.update({ id, params: data });
        } else {
          const response = await PessoaComunicador.store({
            params: data,
          });
          formRef.current?.setFieldValue('codigo', response.codigo);
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
