const PesagemPorDataLocalDeEstoqueRelatorioPlanilha = [
  { titulo: 'Data', valor: 'data' },

  { titulo: 'Local de Estoque', valor: 'listaEstoques.descricao' },

  { titulo: 'Número', valor: 'listaEstoques.listaPesagem.numero' },
  { titulo: 'Data/Hora', valor: 'listaEstoques.listaPesagem.dataHoraEmissao' },
  {
    titulo: 'Código',
    valor: 'listaEstoques.listaPesagem.produto.codigo',
  },
  {
    titulo: 'Produto',
    valor: 'listaEstoques.listaPesagem.produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor:
      'listaEstoques.listaPesagem.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Peso Líquido',
    valor: 'listaEstoques.listaPesagem.pesoLiquido',
  },
];

export default PesagemPorDataLocalDeEstoqueRelatorioPlanilha;
