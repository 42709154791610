import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  ICurvaABCPedidoVendaAtributoProdPorValRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface ICurvaABCPedidoVendaAtributoProdutoPorValorRelatorioPDF {
  dados: ICurvaABCPedidoVendaAtributoProdPorValRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const CurvaABCPedidoVendaGrupoProdutoComProdutoPorValorPDF: React.FC<
  ICurvaABCPedidoVendaAtributoProdutoPorValorRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroAtributo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Atributo: '}</Text>
                      {dados.cabecalho.filtroAtributo}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '3%' }}>
              <Text>Seq.</Text>
            </View>

            <View style={{ width: '7%' }} />

            <View style={{ width: '34%' }}>
              <Text>Atributo</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Valor</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Perc.(%)</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '11%', alignItems: 'flex-end' }}>
              <Text>Perc.(%) Acum</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dotted black' : '',
                        padding: '6pt 0pt 0pt 0pt',
                      }}
                    >
                      <View style={{ width: '3%', alignItems: 'center' }}>
                        <Text>{dado.valores.sequenciaGrupo}</Text>
                      </View>

                      <View style={{ width: '7%' }} />

                      <View style={{ width: '34%' }}>
                        <Text>{dado.valores.chaveFormatada}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.valores.somatorioValorTotalGrupo}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>
                          {dado.valores.somatorioPercentualTotalGrupo}
                        </Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '11%', alignItems: 'flex-end' }}>
                        <Text>
                          {dado.valores.somatorioPercentualAcumuladoTotalGrupo}
                        </Text>
                      </View>
                    </View>
                    <View fixed style={{ flexDirection: 'row', marginTop: 1 }}>
                      <View style={{ width: '3%', alignItems: 'flex-end' }}>
                        <Text>Seq.</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%' }}>
                        <Text>Código</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '30%' }}>
                        <Text>Descrição Produto</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '4%' }}>
                        <Text>Unid.</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '5%', alignItems: 'flex-end' }}>
                        <Text>Valor</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>Perc.(%)</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '11%', alignItems: 'flex-end' }}>
                        <Text>Perc.(%) Acum</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '11%', alignItems: 'flex-end' }}>
                        <Text>Perc.(%) Grupo</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '17%', alignItems: 'flex-end' }}>
                        <Text>Perc.(%) Acum Grupo</Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaProduto.map((produto, produtoIndex) => {
                        return (
                          <View key={String(produtoIndex)}>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 1,
                                backgroundColor:
                                  produtoIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                              }}
                            >
                              <View
                                style={{ width: '3%', alignItems: 'flex-end' }}
                              >
                                <Text>{produto.sequenciaProduto}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '5%' }}>
                                {produto.produtoCodigo.InserirEspacoRepetindoPorNumeroCaracteres(
                                  6
                                )}
                              </View>

                              <View style={{ width: '1%' }} />

                              <View style={{ width: '30%' }}>
                                <Text>{produto.produtoDescricao}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '4%' }}>
                                <Text>{produto.unidadeMedida}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '5%', alignItems: 'flex-end' }}
                              >
                                <Text>{produto.valorProduto}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '6%', alignItems: 'flex-end' }}
                              >
                                <Text>{produto.percentualTotalGeral}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '11%', alignItems: 'flex-end' }}
                              >
                                <Text>
                                  {produto.percentualAcumuladoTotalGeral}
                                </Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '11%', alignItems: 'flex-end' }}
                              >
                                <Text>{produto.percentualGrupo}</Text>
                              </View>
                              <View
                                style={{ width: '18%', alignItems: 'flex-end' }}
                              >
                                <Text>{produto.percentualAcumuladoGrupo}</Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            >
              <View style={{ width: '43%' }}>
                <Text
                  style={{ fontWeight: 'bold', marginBottom: 5, marginLeft: 2 }}
                >
                  Total Geral: {dados.totalGeral}
                </Text>
              </View>
              <View style={{ width: '1%' }} />
              <View style={{ width: '7%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>{dados.valorTotal}</Text>
              </View>
              <View style={{ width: '1%' }} />

              <View style={{ width: '14%' }} />

              <View style={{ width: '1%' }} />
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CurvaABCPedidoVendaGrupoProdutoComProdutoPorValorPDF;
