import React from 'react';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { useNavigate } from 'react-router-dom';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';

type IBtnVoltar = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const BtnVoltar: React.FC<IBtnVoltar> = ({ children, ...rest }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        navigate(-1);
      }}
      type="button"
      className="btn-padrao btn-cinza-claro"
      {...rest}
    >
      {children || (
        <>
          <IoMdReturnLeft />
          {telaGrande && <span style={{ marginLeft: 10 }}>Voltar</span>}
        </>
      )}
    </button>
  );
};

export default BtnVoltar;
