import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IAutoCompleteContext {
  isJanelaAutoComplete: boolean;
  setIsJanelaAutoComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutoCompleteContext = createContext({} as IAutoCompleteContext);

const AutoCompleteProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [isJanelaAutoComplete, setIsJanelaAutoComplete] = useState(true);

  return (
    <AutoCompleteContext.Provider
      value={{
        isJanelaAutoComplete,
        setIsJanelaAutoComplete,
      }}
    >
      {children}
    </AutoCompleteContext.Provider>
  );
};

function UseAutoComplete(): IAutoCompleteContext {
  const context = useContext(AutoCompleteContext);

  return context;
}

export { AutoCompleteProvider, UseAutoComplete };
