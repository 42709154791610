import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/pesagens/pesagem/impressao/a4-com-avaliacao';

interface IPesagemA4AvaliacaoImpressaoComunicadorShow {
  id: string;
}

class PesagemA4AvaliacaoImpressaoComunicador {
  public async show(
    params: IPesagemA4AvaliacaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PesagemA4AvaliacaoImpressaoComunicador();
