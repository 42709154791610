import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro, ICaracteristicaProdutoItemLista } from '@elogestor/util';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadronizacaoDescricaoRef } from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompletePadronizacaoDescricao';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const [listaValor, setListaValor] = useState<
    ICaracteristicaProdutoItemLista[]
  >([]);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus =
    useRef<IInputAutoCompletePadronizacaoDescricaoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);
  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ProdutoPadronizacaoDescricaoAtributo"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          // idPadronizacaoDescricao: Yup.string()
          //   .required('Padronização da Descrição é Obrigatória!')
          //   .nullable(),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleValidarListaAtributoConteudo = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];
      const { listaPadronizacaoDescricaoAtributo } = data;
      listaPadronizacaoDescricaoAtributo.forEach((item: any) => {
        if (!item.padronizacaoDescricaoAtributo) {
          if (!item.conteudo && item.obrigatorio) {
            listaErro.push(
              `Atributo: ${item.descricao} não teve o Conteúdo não Informado!`
            );
          }
        } else if (item.padronizacaoDescricaoAtributo) {
          if (
            !item.conteudo &&
            item.padronizacaoDescricaoAtributo.obrigatorio
          ) {
            listaErro.push(
              `Atributo: ${item.padronizacaoDescricaoAtributo.descricao} não teve o Conteúdo não Informado!`
            );
          }
        }
      });

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        const id = getIdDetalheRegistro() || '';
        data.idPadronizacaoDescricao = data.padronizacaoDescricao
          ? data.padronizacaoDescricao.id
          : null;
        if (
          !(await handleValidar(data, formRef)) ||
          !(await handleValidarListaAtributoConteudo(data))
        ) {
          setLoading(false);
          return { id, erro: true };
        }

        formPrincipal.formRef.current?.setFieldValue(
          'descricao',
          data.descricao
        );

        formPrincipal.formRef.current?.setFieldValue(
          'listaProdutoPadronizacaoDescricaoAtributo',
          data.listaPadronizacaoDescricaoAtributo
        );

        await formRef?.reset();

        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal.formRef,
      getIdDetalheRegistro,
      handleValidar,
      handleValidarListaAtributoConteudo,
      redirecionar,
    ]
  );

  const handleExcluir = async (idx: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setListaValor((estadoAnterior) => {
          const listaConjunto = estadoAnterior.filter((_, index) => {
            return index !== Number(idx);
          });

          return listaConjunto;
        });
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
        listaValor,
        setListaValor,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
