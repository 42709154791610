import React, { useState, useCallback } from 'react';
import { Table, Col, Button, Row } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { Container } from './styles';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import IEnderecoPessoa from './Interface/IEnderecoPessoa';
import IPesquisaEnderecoCepDetalhe from './Interface/ISelecaoEnderecoPessoaDetalhe';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

const SelecaoEnderecoPessoaDetalhe: React.FC<IPesquisaEnderecoCepDetalhe> = ({
  onSelecionarEndereco,
  onFecharFormModal,
  listaEndereco,
}) => {
  const [selecionadoIndex, setSelecionadoIndex] = useState(0);

  const handleSubmit = useCallback(async () => {
    const itemSelecionado = listaEndereco[selecionadoIndex] as IEnderecoPessoa;
    onSelecionarEndereco(itemSelecionado);
    onFecharFormModal();
  }, [
    listaEndereco,
    onFecharFormModal,
    onSelecionarEndereco,
    selecionadoIndex,
  ]);

  return (
    <JanelaDetalhe
      titulo="Selecione o Endereço"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <Container>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th>
                  <span />
                </th>
                <th className="lista-texto">
                  <span>CEP</span>
                </th>
                <th className="lista-texto">
                  <span>Cidade</span>
                </th>
                <th className="lista-texto">
                  <span>UF</span>
                </th>
                <th className="lista-texto">
                  <span>Logradouro</span>
                </th>
                <th className="lista-texto">
                  <span>Número</span>
                </th>
                <th className="lista-texto">
                  <span>Bairro</span>
                </th>
                <th className="lista-texto">
                  <span>Complemento</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaEndereco.map((endereco, index) => {
                return (
                  <tr
                    key={v4()}
                    onClick={(event) => {
                      const input = event.currentTarget.querySelector('input');
                      input?.click();
                    }}
                  >
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <input
                          type="radio"
                          name="origem"
                          checked={index === selecionadoIndex}
                          onChange={() => {
                            setSelecionadoIndex(index);
                          }}
                        />
                      </div>
                    </td>

                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.cep}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.cidade?.nome}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.cidade?.estado.sigla}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.logradouro}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.numero}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.bairro}</span>
                      </div>
                    </td>
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>{endereco.complemento}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>

        <Row>
          <Col lg={12}>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              className="espacamento-interno-para-esquerda-15"
            >
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={() => {
                  onFecharFormModal();
                }}
              >
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSubmit}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </JanelaDetalhe>
  );
};

export default SelecaoEnderecoPessoaDetalhe;
