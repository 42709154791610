import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import { OptionsButtonContainer, ActionsContainer } from './styles';

const TreeViewActionButton: React.FC<IPadraoProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const actionsRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  function toggle(): void {
    setIsOpen((prevState) => !prevState);
  }

  useEffect(() => {
    if (isOpen && iconRef.current) {
      iconRef.current.style.backgroundColor = '#d3d3d3';
    } else if (iconRef.current) {
      iconRef.current.style.backgroundColor = '';
    }

    function clickListener(event: MouseEvent): void {
      if (
        !actionsRef.current?.contains(event.target as any) &&
        !iconRef.current?.contains(event.target as any)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', clickListener);

    if (!isOpen) {
      document.removeEventListener('click', clickListener);
    }

    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [isOpen]);

  return (
    <div style={{ position: isOpen ? 'relative' : 'static' }}>
      <OptionsButtonContainer
        onClick={() => {
          toggle();
        }}
        ref={iconRef}
      >
        <HiOutlineDotsVertical />
      </OptionsButtonContainer>
      {isOpen && children && (
        <ActionsContainer
          ref={actionsRef}
          onClick={() => {
            toggle();
          }}
        >
          {children}
        </ActionsContainer>
      )}
    </div>
  );
};

export default TreeViewActionButton;
