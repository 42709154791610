import styled from 'styled-components';
import { Divisor } from '../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';

export const Container = styled.div`
  padding: 20px 10px 20px 10px;
  position: relative;
`;

export const OpcoesRelatorio = styled.div`
  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const DivisorRelatorio = styled(Divisor)`
  margin: 50px 0px 15px 0px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  label {
    margin: 0 0 0 5px;
  }
`;
