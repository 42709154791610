import styled from 'styled-components';

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin: 0 0 0 5px;
  }
`;

export const DefaultInput = styled.input`
  background: #fff;
  width: 200;
  height: 25;

  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder {
    color: #666360;
  }

  &:disabled {
    opacity: 0.7;
    border-color: #d3d3d3;
    box-shadow: none;
  }
`;

export const DivElemento = styled.div`
  cursor: move;
  color: black;
  width: 200px;
  min-height: 25px;
  border-radius: 5px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;
  padding: 15px;
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 5px;
  }

  label {
    margin: 0 0 0 5px;
  }

  input {
    height: 20px;
    width: auto;
    margin: 0;
  }
`;
