import {
  BooleanEnum,
  FormatarEnum,
  TipoConfiguracaoCREnologicoCultivarEnum,
  TipoRecipienteBebidaSisdevinEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../../Componentes/Select';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagCodigoRastreabilidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagCodigoRastreabilidadeSisdevin';
import InputAutoCompleteTagCultivar from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagCultivar';
import InputAutoCompleteTagProdutoEnologico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProdutoEnologico';
import InputAutoCompleteTagGrupoProdutoSisdevin from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoSisdevin';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const codigo = parametros?.pesquisaAvancada.codigo;
      if (codigo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código',
          valor: codigo,
        });
      }

      const descricao = parametros.pesquisaAvancada?.descricao;
      if (descricao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Descrição',
          valor: descricao,
        });
      }

      const tipo = parametros.pesquisaAvancada?.tipo;
      if (tipo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo',
          valor: tipo,
        });
      }

      const tipoRecipiente = parametros.pesquisaAvancada?.tipoRecipiente;
      if (tipoRecipiente) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Recipiente',
          valor: tipoRecipiente,
        });
      }

      const listaCodigoRastreabilidade =
        parametros.pesquisaAvancada?.listaCodigoRastreabilidade;
      if (listaCodigoRastreabilidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Codigo da Rastreabilidade(CR)',
          valor: listaCodigoRastreabilidade.reduce(
            (acc: string, atual: any) => {
              acc += `${atual.codigoRastreabilidade}; `;
              return acc;
            },
            ''
          ),
        });
      }

      const listaGrupoProdutoCodigosRastreabilidade =
        parametros?.pesquisaAvancada.listaGrupoProdutoCodigosRastreabilidade;
      if (listaGrupoProdutoCodigosRastreabilidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Grupo de Codigos da Rastreabilidade (CR)',
          valor: listaGrupoProdutoCodigosRastreabilidade.reduce(
            (acc: string, atual: any) => {
              acc += `${atual.descricao}, `;
              return acc;
            },
            ''
          ),
        });
      }

      const listaEnologico = parametros?.pesquisaAvancada.listaEnologico;
      if (listaEnologico.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Enlógicos',
          valor: listaEnologico.reduce((acc: string, atual: any) => {
            acc += `${atual.codigoDescricao}, `;
            return acc;
          }, ''),
        });
      }

      const listaCultivar = parametros?.pesquisaAvancada.listaCultivar;
      if (listaCultivar.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cultivar',
          valor: listaCultivar.reduce((acc: string, atual: any) => {
            acc += `${atual.codigoDescricao}, `;
            return acc;
          }, ''),
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Input name="codigo" label="Código" type="text" />
              </Col>

              <Col lg={6} md={12}>
                <Input name="descricao" label="Descrição" type="text" />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="tipo"
                  label="Tipo"
                  options={FormatarEnum({
                    enumObj: TipoConfiguracaoCREnologicoCultivarEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="tipoRecipiente"
                  label="Tipo Recipiente"
                  options={FormatarEnum({
                    enumObj: TipoRecipienteBebidaSisdevinEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCodigoRastreabilidade
                  name="listaCodigoRastreabilidade"
                  label="Código Rastreabilidade (CR)"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagGrupoProdutoSisdevin
                  name="listaGrupoProdutoCodigosRastreabilidade"
                  label="Grupo de Códigos da Rastreabilidade (CR)"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagProdutoEnologico
                  name="listaEnologico"
                  label="Enológicos"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCultivar
                  name="listaCultivar"
                  label="Cultivares"
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
