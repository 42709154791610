import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/exportar-xml';

interface IConhecimentoTransporteTerceiroExportarXmlComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTerceiroExportarXmlComunicador {
  public async show(
    params: IConhecimentoTransporteTerceiroExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroExportarXmlComunicador();
