import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/local-estoque';

interface ILocalEstoqueComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ILocalEstoqueComunicadorShow {
  id: string;
}

interface ILocalEstoqueComunicadorStore {
  params: any;
}

interface ILocalEstoqueComunicadorUpdate {
  id: string;
  params: any;
}

interface ILocalEstoqueComunicadorDeletar {
  id: string;
}

class LocalEstoqueComunicador {
  public async index(params: ILocalEstoqueComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ILocalEstoqueComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ILocalEstoqueComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: ILocalEstoqueComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ILocalEstoqueComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new LocalEstoqueComunicador();
