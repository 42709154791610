import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { IFormCiaHandles } from '@elogestor/unformcia';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompleteProdutoServico from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

interface IAnaliseCustoProdutoTabProps {
  formRef: React.MutableRefObject<IFormCiaHandles>;
  atualizarValores(): void;
  refresh: () => void;
}

const AnaliseCustoProdutoTab: React.FC<IAnaliseCustoProdutoTabProps> = ({
  formRef,
  atualizarValores,
  refresh,
}) => {
  return (
    <div>
      <InputHiddenHtml name="itemVendaSelecionado.sequencia" />

      <Row>
        <Col lg={6} md={6} sm={12}>
          <InputAutoCompleteProdutoServico
            label="Produto"
            name="itemVendaSelecionado.idProduto"
            nomeObjeto="itemVendaSelecionado.produto"
            disabled
            permitirAdicionar={false}
            permitirConsultar={false}
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Quantidade"
            name="itemVendaSelecionado.quantidade"
            placeholder="Quantidade"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Val. Unit. Venda"
            name="itemVendaSelecionado.valorUnitarioVenda"
            placeholder="Val. Unit. Venda"
            casasDecimais={2}
            onBlur={async (event) => {
              const quantidade = formRef.current.getFieldValue(
                'itemVendaSelecionado.quantidade'
              );
              const valorTotalProduto =
                Number(quantidade) *
                String(event.currentTarget.value).ConverterParaNumber();

              formRef.current.setFieldValue(
                'itemVendaSelecionado.valorTotalProduto',
                valorTotalProduto
              );
              formRef.current.setFieldValue(
                'itemVendaSelecionado.recalcularImpostos',
                true
              );

              refresh();

              atualizarValores();
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Val. Unit. Custo"
            name="itemVendaSelecionado.valorUnitariaCusto"
            placeholder="Val. Unit. Custo"
            casasDecimais={2}
            disabled
          />
        </Col>

        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Total Custo"
            name="itemVendaSelecionado.valorTotalCusto"
            placeholder="Total Custo"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Val. Prod (Qtde. * Val. Unit)"
            name="itemVendaSelecionado.valorTotalProduto"
            placeholder="Val. Produto"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Frete"
            name="itemVendaSelecionado.valorFrete"
            placeholder="Valor Frete"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Seguro"
            name="itemVendaSelecionado.valorSeguro"
            placeholder="Valor Seguro"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Outras Despesas"
            name="itemVendaSelecionado.valorOutrasDespesas"
            placeholder="Valor Outras Despesas"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Desconto"
            name="itemVendaSelecionado.valorDesconto"
            placeholder="Valor Desconto"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor ICMS ST"
            name="itemVendaSelecionado.valorIcmsStEValorFcpIcmsSt"
            placeholder="Valor ICMS ST"
            casasDecimais={2}
            disabled
          />
        </Col>

        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor IPI"
            name="itemVendaSelecionado.valorIpi"
            placeholder="Valor IPI"
            casasDecimais={2}
            disabled
          />
        </Col>

        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Total Bruto"
            name="itemVendaSelecionado.valorTotalBruto"
            placeholder="Valor Total Bruto"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor ICMS"
            name="itemVendaSelecionado.valorIcms"
            placeholder="Valor ICMS"
            casasDecimais={2}
            disabled
          />
        </Col>

        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor PIS"
            name="itemVendaSelecionado.valorPis"
            placeholder="Valor PIS"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor COFINS"
            name="itemVendaSelecionado.valorCofins"
            placeholder="Valor COFINS"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor IRPJ"
            name="itemVendaSelecionado.valorIrpj"
            placeholder="Valor IRPJ"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor CSLL"
            name="itemVendaSelecionado.valorCsll"
            placeholder="Valor CSLL"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor ICMS SN"
            name="itemVendaSelecionado.valorIcmsSn"
            placeholder="Valor ICMS SN"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Valor Outros"
            name="itemVendaSelecionado.valorOutros"
            placeholder="Valor Outros"
            casasDecimais={2}
            disabled
          />
        </Col>

        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Total dos Imp. e Outros"
            name="itemVendaSelecionado.valorTotalImpostosOutros"
            placeholder="Total dos Impostos e Outros"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Margem Líquida"
            name="itemVendaSelecionado.margemLiquidaValor"
            placeholder="Margem Líquida"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Margem Líquida %"
            name="itemVendaSelecionado.margemLiquidaPercentual"
            placeholder="Margem Líquida %"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default AnaliseCustoProdutoTab;
