import React from 'react';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import GeracaoEnvioBoletosHook from './Hooks';
import GeracaoEnvioBoletosLista from './Lista';

const GeracaoEnvioBoletosListagem: React.FC = () => {
  return (
    <ListaProvider>
      <GeracaoEnvioBoletosHook>
        <GeracaoEnvioBoletosLista />
      </GeracaoEnvioBoletosHook>
    </ListaProvider>
  );
};

export default GeracaoEnvioBoletosListagem;
