import React, { useCallback } from 'react';

interface ITrDraggableProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  index: number;
  onMover(de: number, para: number): void;
  idDrag: string;
  permitirDrag?: () => boolean;
}

let indexDragStart: number;
let idDragAtual: string | number;

const TrDraggable: React.FC<ITrDraggableProps> = ({
  children,
  index,
  onMover,
  idDrag,
  onDragEnd,
  permitirDrag,
  ...rest
}) => {
  const handleDragStart = useCallback(() => {
    if (permitirDrag && !permitirDrag()) {
      return;
    }
    idDragAtual = idDrag;
    indexDragStart = index;
  }, [idDrag, index, permitirDrag]);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLTableRowElement>) => {
      event.preventDefault();
      // if (index === indexDragStart || idDragAtual !== idDrag) return;
      if (index === indexDragStart) return;

      const alvoTamanho = event.currentTarget.getBoundingClientRect();
      const meio = alvoTamanho.y + alvoTamanho.height / 2;
      if (index > indexDragStart && event.clientY < meio) return;
      if (index < indexDragStart && event.clientY > meio) return;

      onMover(index, indexDragStart);
      indexDragStart = index;
    },
    [index, onMover]
  );

  return (
    <tr
      style={{ cursor: 'grab' }}
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnter={(event) => {
        event.preventDefault();
      }}
      // onDragEnd={(event) => {
      //   if (onDragEnd) onDragEnd(event);
      //   idDragAtual = '';
      // }}
      onDrop={(event) => {
        if (onDragEnd) onDragEnd(event);
        idDragAtual = '';
      }}
      {...rest}
    >
      {children}
    </tr>
  );
};

export default TrDraggable;
