import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '/transporte/manifesto-transporte/seguro',
        novo: '/transporte/manifesto-transporte',
        alterar: '/transporte/manifesto-transporte/seguro',
        excluir: '/transporte/manifesto-transporte/seguro',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
