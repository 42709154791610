import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PedidoVendaHooks from './Hook';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import AcoesPedidoVenda from './AcoesPedidoVenda';

const PedidoVendaDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <PedidoVendaHooks>
        <SubHeader
          titulo={params.id ? 'Editar Pedido de Venda' : 'Pedido de Venda'}
          permissao={permissoes.ComercialVendasPedidoVenda}
          botoes={
            <AcoesPedidoVenda
              permissao={permissoes.ComercialVendasPedidoVenda}
              permissaoHook={permissoes}
            />
          }
          isPedidoVenda
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </PedidoVendaHooks>
    </Container>
  );
};

export default PedidoVendaDetalhe;
