import styled from 'styled-components';

interface IButton {
  $mostra: boolean;
}

export const Button = styled.button<IButton>`
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: none;
  border: none;
  svg {
    transition: all 0.3s;
    transform: rotate(${({ $mostra }) => ($mostra ? 90 : 0)}deg);
  }

  &:hover {
    background: #d3d3d3;
  }
`;

export const Ul = styled.ul``;
