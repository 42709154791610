import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Modal, Container, Button, Row, Col } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import {
  DestinadoEnum,
  FormatarEnum,
  IndicadorPresencaPadraoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Select from '../../../../../../../../Componentes/Select';
import InputAutoCompleteTipoNota from '../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import FrenteCaixaPdvObterDadosPadraoNotaFiscalSaidaComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterDadosPadraoNotaFiscalSaidaComunicador';

export interface IDadosPadraoNotaFiscalSaida {
  destinado: DestinadoEnum;
  indicadorPresenca: IndicadorPresencaPadraoEnum;
  idTipoNota: string;
  idTipoNotaMotivo: string;
}

interface IGerarNotaFiscalSaidaDetalheModal {
  onSalvarFormModal(dados: IDadosPadraoNotaFiscalSaida): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const GerarNotaFiscalSaidaDetalhe: React.FC<
  IGerarNotaFiscalSaidaDetalheModal
> = ({ onFecharFormModal, onSalvarFormModal, loading, setLoading }) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response =
        await FrenteCaixaPdvObterDadosPadraoNotaFiscalSaidaComunicador.show();

      formRef.current?.setDataInicial({
        ...response,
        destinado: DestinadoEnum.usoeConsumo,
        indicadorPresenca: IndicadorPresencaPadraoEnum.operacaoPresencial,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idTipoNota: Yup.string()
          .nullable()
          .required('Tipo de Nota é obrigatório!'),
        idTipoNotaMotivo: Yup.string()
          .nullable()
          .required('Motivo é obrigatório!'),

        destinado: Yup.mixed().required('Destinado é Obrigatório!'),
        indicadorPresenca: Yup.mixed().required(
          'Indicador de Presença é Obrigatório!'
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleGerarRetiradaNotaFiscalSaida = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current?.getData() as any;

      if (!(await handleValidar(data))) {
        setLoading(false);
        return;
      }

      if (onSalvarFormModal) onSalvarFormModal(data);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleValidar, onSalvarFormModal, setLoading]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) return;
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  return (
    <JanelaDetalhe
      titulo="Gerar Nota Fiscal Saída"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <Select
                  label="Destinado"
                  name="destinado"
                  options={FormatarEnum({
                    enumObj: DestinadoEnum,
                  })}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <Select
                  label="Indicador de Presença"
                  name="indicadorPresenca"
                  options={FormatarEnum({
                    enumObj: IndicadorPresencaPadraoEnum,
                  })}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12} sm={12}>
                <InputAutoCompleteTipoNota
                  label="Tipo de Nota"
                  name="idTipoNota"
                  placeholder="Tipo de Nota"
                  nomeObjeto="tipoNota"
                  filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.saida}
                  onChangeItemAtualAposCarregarSemClear={() => {
                    refresh();
                    formRef.current?.clearField('tipoNotaMotivo', {
                      setValorInicial: false,
                    });
                  }}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <InputAutoCompleteTipoNotaMotivo
                  label="Motivo"
                  name="idTipoNotaMotivo"
                  placeholder="Motivo"
                  nomeObjeto="tipoNotaMotivo"
                  idTipoNota={formRef.current?.getFieldValue('idTipoNota')}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleGerarRetiradaNotaFiscalSaida}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default GerarNotaFiscalSaidaDetalhe;
