"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PisCofinsObterVisibilidadeBloqueioCamposPorCst;
const Enum_1 = require("../../Enum");
function PisCofinsObterVisibilidadeBloqueioCamposPorCst(dados) {
    const retorno = {
        escondido: {
            calcular: false,
            tipoCalculo: true,
            quantidadeUnidade: true,
            valorUnidade: true,
            baseCalculo: true,
            aliquota: true,
            valor: true,
            calcularRetido: false,
            baseCalculoRetido: false,
            aliquotaRetido: false,
            valorRetido: false,
        },
        bloqueado: {
            calcular: false,
            tipoCalculo: true,
            quantidadeUnidade: true,
            valorUnidade: true,
            baseCalculo: true,
            aliquota: true,
            valor: true,
            calcularRetido: false,
            baseCalculoRetido: true,
            aliquotaRetido: true,
            valorRetido: true,
        },
    };
    if (!dados.calcular) {
        retorno.bloqueado.tipoCalculo = false;
        retorno.bloqueado.quantidadeUnidade = false;
        retorno.bloqueado.valorUnidade = false;
        retorno.bloqueado.baseCalculo = false;
        retorno.bloqueado.aliquota = false;
        retorno.bloqueado.valor = false;
    }
    if (!dados.calcularRetido) {
        retorno.bloqueado.baseCalculoRetido = false;
        retorno.bloqueado.aliquotaRetido = false;
        retorno.bloqueado.valorRetido = false;
    }
    if (dados.cst === '01' || dados.cst === '02') {
        retorno.bloqueado.tipoCalculo = true;
        retorno.escondido.tipoCalculo = false;
        retorno.escondido.baseCalculo = false;
        retorno.escondido.aliquota = false;
        retorno.escondido.valor = false;
    }
    if (dados.cst === '03') {
        retorno.bloqueado.tipoCalculo = true;
        retorno.escondido.tipoCalculo = false;
        retorno.escondido.quantidadeUnidade = false;
        retorno.escondido.valorUnidade = false;
        retorno.escondido.valor = false;
    }
    if (dados.cst === '04' ||
        dados.cst === '05' ||
        dados.cst === '06' ||
        dados.cst === '07' ||
        dados.cst === '08' ||
        dados.cst === '09') {
        retorno.escondido.calcular = true;
        retorno.bloqueado.calcular = true;
        retorno.bloqueado.tipoCalculo = true;
        retorno.bloqueado.quantidadeUnidade = true;
        retorno.bloqueado.valorUnidade = true;
        retorno.bloqueado.baseCalculo = true;
        retorno.bloqueado.aliquota = true;
        retorno.bloqueado.valor = true;
    }
    if (dados.cst === '49' ||
        dados.cst === '50' ||
        dados.cst === '51' ||
        dados.cst === '52' ||
        dados.cst === '53' ||
        dados.cst === '54' ||
        dados.cst === '55' ||
        dados.cst === '56' ||
        dados.cst === '60' ||
        dados.cst === '61' ||
        dados.cst === '62' ||
        dados.cst === '63' ||
        dados.cst === '64' ||
        dados.cst === '65' ||
        dados.cst === '66' ||
        dados.cst === '67' ||
        dados.cst === '70' ||
        dados.cst === '71' ||
        dados.cst === '72' ||
        dados.cst === '73' ||
        dados.cst === '74' ||
        dados.cst === '75' ||
        dados.cst === '98' ||
        dados.cst === '99') {
        if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.percentual) {
            retorno.escondido.baseCalculo = false;
            retorno.escondido.aliquota = false;
        }
        if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.valor) {
            retorno.escondido.quantidadeUnidade = false;
            retorno.escondido.valorUnidade = false;
        }
        retorno.escondido.tipoCalculo = false;
        retorno.escondido.valor = false;
    }
    return retorno;
}
