import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/codigo-situacao-tributaria-icms-auto-complete';

interface ICodigoSituacaoTributariaIcmsAutoCompleteComunicadorIndex {
  params: any;
}

class CodigoSituacaoTributariaIcmsAutoCompleteComunicador {
  public async index(
    params: ICodigoSituacaoTributariaIcmsAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoSituacaoTributariaIcmsAutoCompleteComunicador();
