"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCaracteristicaConfiguradorEnum;
(function (TipoCaracteristicaConfiguradorEnum) {
    TipoCaracteristicaConfiguradorEnum["escolhaConjunto"] = "Escolha de um Conjunto";
    TipoCaracteristicaConfiguradorEnum["numerica"] = "Num\u00E9rica";
    TipoCaracteristicaConfiguradorEnum["opcaoSimNao"] = "Op\u00E7\u00E3o Sim / N\u00E3o";
})(TipoCaracteristicaConfiguradorEnum || (TipoCaracteristicaConfiguradorEnum = {}));
exports.default = TipoCaracteristicaConfiguradorEnum;
