/* eslint-disable default-param-last */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CompararStringsIguaisIgnoraAcentuacaoMinusculas,
  CalculateSize,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { produce } from 'immer';
import { MdClose } from 'react-icons/md/index.mjs';
import { ContainerListaItem, ContainerListaItemAtual, Item } from './styles';
import { DefaultInputDiv, SpanErro } from '../../Styles';
import LiDrag from '../../TagsInput/Componentes/LiDrag';
import UseListaAcao, { IListaAcao } from './Hooks/UseListaAcao';

interface IOnChange {
  valorAnterior: any;
}

interface IItem {
  chave: string | number;
  label: string;
  valor: any;
}

export interface IFiltrarItens {
  valor: string;
  offset: number;
  limite: number;
  listaValorAtual: any[];
}

interface IPersonalizarItem {
  item: any;
  index: number;
  navegacao: boolean;
}

export interface IOnChangeListaItemAtualEvent {
  listaItemAtual: any[] | null;
  input: HTMLInputElement | null;
}

export interface IOnAdicionarEvent extends IOnChangeListaItemAtualEvent {
  itemAdicionado: any;
}

export interface IOnRemoverEvent extends IOnChangeListaItemAtualEvent {
  itemRemovido: any;
}

export interface IOnMoverEvent extends IOnChangeListaItemAtualEvent {
  to: number;
  from: number;
}

export interface IOnChangeTextoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteTagBase {
  personalizarItem?: (data: IPersonalizarItem) => JSX.Element;
  filtrarItens(dados: IFiltrarItens): Promise<any[]>;
  obterChaveUnica(item: any): string | number;
  obterLabel(item: any): string;

  limite?: number;
  permitirExcluir?: boolean;
  error?: string;

  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarEvent) => void;
  onRemoverItem?: (evento: IOnRemoverEvent) => void;
  onMoverItem?: (evento: IOnMoverEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;

  setPesquisando?: (vaor: boolean) => void;
}

export interface IInputAutoCompleteTagBaseRef {
  obterListaItemAtual(): any[];
  obterListaAcao: () => IListaAcao;
  obterInput(): HTMLInputElement | null;
  alterarListaItemAtualListaAcao(
    listaItem: any[],
    listaAcao?: IListaAcao
  ): void;
  alterarListaItemAtualListaAcaoSemEvento(
    listaItem: any[],
    listaAcao?: IListaAcao
  ): void;
  adicionarItem(item: any): void;
  removerItem(item: any): void;
  moverItem(from: number, to: number): void;
  alterarListaAcao(novaListaAcao: IListaAcao): void;

  permitirExcluir?: boolean;
  disabled: boolean;
  setDisabled: (valor: boolean) => void;
}

const AutoCompleteTagBase: React.ForwardRefRenderFunction<
  IInputAutoCompleteTagBaseRef,
  IInputAutoCompleteTagBase
> = (
  {
    personalizarItem,
    permitirExcluir = false,
    filtrarItens,
    obterChaveUnica,
    obterLabel,
    limite = 20,
    error,
    onChangeTexto,
    onChangeListaItemAtual,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    setPesquisando,
  },
  ref
) => {
  const [id] = useState(v4());
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [listaItem, setListaItem] = useState<IItem[]>([]);
  const [indexItemNavegacao, setIndexItemNavegacao] = useState(-1);

  const [listaItemAtual, alterarListaItemAtual] = useState<IItem[]>([]);
  const listaItemAtualRef = useRef<IItem[]>([]);

  const setListaItemAtual = useCallback((novaLista: IItem[]) => {
    alterarListaItemAtual(novaLista);
    listaItemAtualRef.current = novaLista;
  }, []);

  const {
    handleAdicionarAcaoDeletar,
    handleAdicionarAcaoInserir,
    handleAdicionarAcaoMover,
    obterListaAcao,
    setListaAcao,
  } = UseListaAcao();
  const inputRef = useRef<HTMLInputElement>(null);

  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [carregandoMaisRegistros, setCarregandoMaisRegistros] = useState(false);
  const debounceMaisRegistrosRef = useRef<number | null>(null);
  const cliqueDentro = useRef(false);

  const listaItemContainerRef = useRef<HTMLUListElement>(null);
  const itemNavegacaoContainerRef = useRef<any>(null);

  const debounceChangeRef = useRef<number | null>(null);
  const requestIdRef = useRef(0);

  const formatarItem = useCallback(
    (valor: any) => {
      return {
        chave: obterChaveUnica(valor),
        label: obterLabel(valor),
        valor,
      };
    },
    [obterChaveUnica, obterLabel]
  );

  const formatarListaItem = useCallback(
    (lista: any[]) => {
      if (Array.isArray(lista)) {
        return lista?.map(formatarItem);
      }

      return [];
    },
    [formatarItem]
  );

  const carregarMaisItens = useCallback(
    async (offset: number) => {
      if (debounceMaisRegistrosRef.current) {
        return;
      }

      setCarregandoMaisRegistros(true);
      debounceMaisRegistrosRef.current = window.setTimeout(
        async () => {
          const listaValor = await filtrarItens({
            valor: inputRef.current?.value || '',
            offset,
            limite,
            listaValorAtual: listaItemAtual.map((itemAtual) => itemAtual.valor),
          });

          const listaValorFormatado = formatarListaItem(listaValor);
          setListaItem((state) => [...state, ...listaValorFormatado]);

          if (debounceMaisRegistrosRef.current) {
            clearTimeout(debounceMaisRegistrosRef.current);
            debounceMaisRegistrosRef.current = null;
          }

          setCarregandoMaisRegistros(false);
        },
        500 * (offset / 20)
      );
    },
    [filtrarItens, formatarListaItem, limite, listaItemAtual]
  );

  const limparListaItemAtual = useCallback(() => {
    listaItemAtual.forEach((item) => {
      handleAdicionarAcaoDeletar(item);
    });
  }, [handleAdicionarAcaoDeletar, listaItemAtual]);

  const alterarListaItemAtualListaAcao = useCallback(
    (novaLista: any[], novaListaAcao?: IListaAcao) => {
      setErroInterno('');
      const novaListaFormatada = formatarListaItem(novaLista);

      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(
          {
            input: inputRef.current,
            listaItemAtual: novaLista,
          },
          {
            valorAnterior: listaItemAtual.map((valor) => valor.valor),
          }
        );
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (novaListaAcao) {
        setListaAcao(novaListaAcao);
      } else {
        limparListaItemAtual();

        novaListaFormatada.forEach((item, index) => {
          handleAdicionarAcaoInserir({
            chave: item.chave,
            valor: item.valor,
            ordem: index,
          });
        });
      }
      setListaItemAtual(novaListaFormatada);
    },
    [
      formatarListaItem,
      handleAdicionarAcaoInserir,
      limparListaItemAtual,
      listaItemAtual,
      onChangeListaItemAtual,
      setListaAcao,
      setListaItemAtual,
    ]
  );

  const alterarListaItemAtualListaAcaoSemEvento = useCallback(
    (novaLista: any[], novaListaAcao?: IListaAcao) => {
      setErroInterno('');
      const novaListaFormatada = formatarListaItem(novaLista);

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (novaListaAcao) {
        setListaAcao(novaListaAcao);
      } else {
        limparListaItemAtual();

        novaListaFormatada.forEach((item, index) => {
          handleAdicionarAcaoInserir({
            chave: item.chave,
            valor: item.valor,
            ordem: index,
          });
        });
      }
      setListaItemAtual(novaListaFormatada);
    },
    [
      formatarListaItem,
      handleAdicionarAcaoInserir,
      limparListaItemAtual,
      setListaAcao,
      setListaItemAtual,
    ]
  );

  const adicionarItem = useCallback(
    (item: IItem) => {
      if (inputRef.current) {
        inputRef.current.style.width = '1ch';
      }
      setErroInterno('');

      const valorAnterior = listaItemAtual.map((valor) => valor.valor);
      const novoValor: IItem[] = [...listaItemAtual, item];
      setListaItemAtual(novoValor);

      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(
          {
            input: inputRef.current,
            listaItemAtual: novoValor.map((valor) => valor.valor),
          },
          {
            valorAnterior,
          }
        );
      }

      if (onAdicionarItem) {
        onAdicionarItem({
          input: inputRef.current,
          listaItemAtual: novoValor.map((valor) => valor.valor),
          itemAdicionado: item.valor,
        });
      }

      handleAdicionarAcaoInserir({
        chave: item.chave,
        valor: item.valor,
        ordem: novoValor.length,
      });

      if (inputRef.current) {
        inputRef.current.value = '';
        setIndexItemNavegacao(-1);
      }
    },
    [
      handleAdicionarAcaoInserir,
      listaItemAtual,
      onAdicionarItem,
      onChangeListaItemAtual,
      setListaItemAtual,
    ]
  );

  const removerItem = useCallback(
    (item: IItem) => {
      if (disabled && !permitirExcluir) return;

      setIsFocused(false);
      const novaLista = produce(listaItemAtual, (draft) => {
        const index = draft.findIndex((itemAchar) => {
          return itemAchar.chave === item.chave;
        });
        draft.splice(index, 1);

        handleAdicionarAcaoDeletar({ chave: item.chave, valor: item.valor });
        for (let i = index; i < draft.length; i++) {
          const itemMover = JSON.parse(JSON.stringify(draft[i]));

          handleAdicionarAcaoMover({
            chave: itemMover.chave,
            valor: itemMover.valor,
            ordem: i,
          });
        }
      });

      setListaItemAtual(novaLista);

      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(
          {
            input: inputRef.current,
            listaItemAtual: novaLista,
          },
          {
            valorAnterior: listaItemAtual.map((valor) => valor.valor),
          }
        );
      }

      if (onRemoverItem) {
        onRemoverItem({
          input: inputRef.current,
          listaItemAtual: novaLista,
          itemRemovido: item.valor,
        });
      }
    },
    [
      disabled,
      handleAdicionarAcaoDeletar,
      handleAdicionarAcaoMover,
      listaItemAtual,
      onChangeListaItemAtual,
      onRemoverItem,
      permitirExcluir,
      setListaItemAtual,
    ]
  );

  const moverItem = useCallback(
    (from: number, to: number) => {
      if (disabled) return;

      const novaLista = produce(listaItemAtual, (draft) => {
        const valorDe = JSON.parse(JSON.stringify(draft[from]));
        const valorPara = JSON.parse(JSON.stringify(draft[to]));

        draft.splice(from, 1, valorPara);
        draft.splice(to, 1, valorDe);

        handleAdicionarAcaoMover({
          chave: valorDe.chave,
          valor: valorDe.valor,
          ordem: to,
        });

        handleAdicionarAcaoMover({
          chave: valorPara.chave,
          valor: valorPara.valor,
          ordem: from,
        });

        if (onChangeListaItemAtual) {
          onChangeListaItemAtual(
            {
              input: inputRef.current,
              listaItemAtual: draft.map((valor) => valor.valor),
            },
            {
              valorAnterior: listaItemAtual.map((valor) => valor.valor),
            }
          );
        }

        if (onMoverItem) {
          onMoverItem({
            input: inputRef.current,
            listaItemAtual: draft.map((valor) => valor.valor),
            from,
            to,
          });
        }
      });

      setListaItemAtual(novaLista);
    },
    [
      disabled,
      handleAdicionarAcaoMover,
      listaItemAtual,
      onChangeListaItemAtual,
      onMoverItem,
      setListaItemAtual,
    ]
  );

  const procurarItemPorLabel = useCallback(
    (valor: string) => {
      if (loading) return;

      const itemAchado = listaItem.find((item) => {
        return CompararStringsIguaisIgnoraAcentuacaoMinusculas(
          item.label,
          valor
        );
      });

      if (itemAchado) {
        adicionarItem(itemAchado);
      } else {
        setErroInterno('Registro não encontrado!');
      }
    },
    [listaItem, loading, adicionarItem]
  );

  const alterarIndexNavegacao = useCallback(
    (valor: number) => {
      setIndexItemNavegacao((state) => {
        const soma = state + valor;
        const tamanhoLista = listaItem.length - 1;
        if (soma < 0) {
          return 0;
        }

        if (soma > tamanhoLista) {
          carregarMaisItens(tamanhoLista + 1);
          return tamanhoLista;
        }

        return soma;
      });
    },
    [carregarMaisItens, listaItem.length]
  );

  const mapeamentoDeNavegacao = useMemo<{ [key: string]: any }>(() => {
    function adicionar(): void {
      if (loading) return;
      setIsFocused(false);
      if (indexItemNavegacao >= 0 && listaItem[indexItemNavegacao]) {
        adicionarItem(listaItem[indexItemNavegacao]);
      }
    }

    return {
      ArrowDown: () => {
        alterarIndexNavegacao(1);
      },

      ArrowUp: () => {
        alterarIndexNavegacao(-1);
      },

      PageDown: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(4);
      },

      PageUp: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(-4);
      },

      Tab: () => {
        adicionar();
      },

      Enter: (event: any) => {
        event.preventDefault();
        adicionar();
      },
    };
  }, [
    alterarIndexNavegacao,
    indexItemNavegacao,
    listaItem,
    loading,
    adicionarItem,
  ]);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setIsFocused(true);
      setIndexItemNavegacao(0);

      const input = event.currentTarget;
      setErroInterno('');

      if (onChangeTexto) {
        onChangeTexto({
          input,
        });
      }
      const size = CalculateSize(input.value, {
        font: '"Roboto", sans-serif',
        fontSize: '14px',
      });

      input.style.width = `${size.width}px`;

      if (debounceChangeRef.current) {
        clearTimeout(debounceChangeRef.current);
      }

      debounceChangeRef.current = window.setTimeout(async () => {
        const currentRequestId = ++requestIdRef.current;

        const listaValor = await filtrarItens({
          valor: input.value,
          offset: 0,
          limite,
          listaValorAtual: listaItemAtual.map((itemAtual) => itemAtual.valor),
        });

        if (currentRequestId === requestIdRef.current) {
          const listaValorFormatado = formatarListaItem(listaValor);
          setListaItem(listaValorFormatado);
          setLoading(false);
          inputRef.current?.focus();
          setIsFocused(true);

          if (setPesquisando) setPesquisando(false);
        }
      }, 400);
    },
    [
      filtrarItens,
      formatarListaItem,
      limite,
      listaItemAtual,
      onChangeTexto,
      setPesquisando,
    ]
  );

  const handleMouseDown = useCallback(async () => {
    if (disabled) return;

    if (!isFocused) {
      setIsFocused(true);
    }
    cliqueDentro.current = true;

    const listaValor = await filtrarItens({
      valor: inputRef.current?.value || '',
      offset: 0,
      limite,
      listaValorAtual: listaItemAtual.map((itemAtual) => itemAtual.valor),
    });

    const listaValorFormatado = formatarListaItem(listaValor);
    setListaItem(listaValorFormatado);
  }, [
    disabled,
    filtrarItens,
    formatarListaItem,
    isFocused,
    limite,
    listaItemAtual,
  ]);

  const handleBlur = useCallback(async () => {
    setIsFilled(!!inputRef.current?.value);
    setIndexItemNavegacao(-1);

    if (inputRef.current?.value) {
      procurarItemPorLabel(inputRef.current.value);
    }
  }, [inputRef, procurarItemPorLabel]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const chave = event.key;
      const funcao = mapeamentoDeNavegacao[chave];
      if (funcao) {
        funcao(event);
      }
    },
    [mapeamentoDeNavegacao]
  );

  const handleWheel = useCallback(
    (event: React.WheelEvent<HTMLUListElement>) => {
      if (event.deltaY >= 0) {
        alterarIndexNavegacao(1);
      } else {
        alterarIndexNavegacao(-1);
      }
    },
    [alterarIndexNavegacao]
  );

  const handleClickDivInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  useEffect(() => {
    function handleValidarClick(): void {
      if (!cliqueDentro.current) {
        setIndexItemNavegacao(0);
        setIsFocused(false);
        inputRef.current?.blur();
      } else {
        cliqueDentro.current = false;
      }
    }

    if (isFocused) {
      document.addEventListener('click', handleValidarClick);
    }

    return () => {
      document.removeEventListener('click', handleValidarClick);
    };
  }, [isFocused]);

  useEffect(() => {
    listaItemContainerRef.current?.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    function ScrollSeNecessario(): void {
      if (!itemNavegacaoContainerRef.current || !listaItemContainerRef.current)
        return;

      listaItemContainerRef.current.scrollTo(
        0,
        itemNavegacaoContainerRef.current.offsetTop +
          itemNavegacaoContainerRef.current.offsetHeight -
          listaItemContainerRef.current.offsetHeight
      );
    }

    ScrollSeNecessario();
  }, [indexItemNavegacao]);

  useImperativeHandle(ref, () => ({
    obterListaItemAtual() {
      return listaItemAtualRef.current.map((valor) => valor.valor);
    },
    obterListaAcao,
    obterInput() {
      return inputRef.current;
    },
    alterarListaItemAtualListaAcao(listaItemAtualNova = [], novaListaAcao) {
      alterarListaItemAtualListaAcao(listaItemAtualNova, novaListaAcao);
    },
    alterarListaItemAtualListaAcaoSemEvento(
      listaItemAtualNova = [],
      novaListaAcao
    ) {
      alterarListaItemAtualListaAcaoSemEvento(
        listaItemAtualNova,
        novaListaAcao
      );
    },
    adicionarItem: (item) => {
      if (item) {
        const itemFormatado = formatarItem(item);
        adicionarItem(itemFormatado);
      }
    },
    removerItem(item: any) {
      const itemFormatado = formatarItem(item);
      removerItem(itemFormatado);
    },
    moverItem(from: number, to: number) {
      moverItem(from, to);
    },
    alterarListaAcao(novaListaAcao) {
      setListaAcao(novaListaAcao);
    },
    disabled,
    setDisabled,
  }));

  const handleScrolled = useCallback(() => {
    if (!listaItemContainerRef.current) return;

    if (
      listaItemContainerRef.current.offsetHeight +
        listaItemContainerRef.current.scrollTop >=
      listaItemContainerRef.current.scrollHeight
    ) {
      carregarMaisItens(listaItem.length);
    }
  }, [carregarMaisItens, listaItem.length]);

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
    >
      <DefaultInputDiv
        style={{ height: 'auto' }}
        onClick={handleClickDivInput}
        $isFocused={isFocused}
        $isFilled={isFilled}
        $isErrored={!!erroInterno}
        $isDisabled={disabled}
      >
        <ContainerListaItemAtual>
          {listaItemAtual.map((valor, index) => {
            return (
              <LiDrag
                onFocus={(event) => {
                  event.stopPropagation();
                }}
                onBlur={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                id={id}
                key={valor.chave}
                index={index}
                mover={moverItem}
              >
                <span
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {valor.label}
                </span>
                <button
                  type="button"
                  style={{ height: '100%' }}
                  disabled={false}
                  onClick={(event) => {
                    event.stopPropagation();
                    removerItem(valor);
                  }}
                >
                  <span
                    style={{
                      background: 'red',
                      height: 16,
                      width: 16,
                      borderRadius: '50%',
                      border: '1px solid #000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MdClose color="#fff" />
                  </span>
                </button>
              </LiDrag>
            );
          })}
          <li>
            <input
              style={{ margin: 0 }}
              type="text"
              ref={inputRef}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={disabled}
            />
          </li>
        </ContainerListaItemAtual>
      </DefaultInputDiv>
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
      <ContainerListaItem
        ref={listaItemContainerRef}
        onWheel={handleWheel}
        onScroll={handleScrolled}
      >
        {isFocused &&
          (!loading ? (
            listaItem.map((item, index) => {
              const navegacao = indexItemNavegacao === index;
              if (index >= listaItem.length - 1 && carregandoMaisRegistros) {
                return (
                  <li
                    key="carregando"
                    ref={navegacao ? itemNavegacaoContainerRef : undefined}
                  >
                    <button type="button">
                      <Item $navegacao={false}>Carregando...</Item>
                    </button>
                  </li>
                );
              }
              return (
                <li
                  key={item.chave}
                  ref={navegacao ? itemNavegacaoContainerRef : undefined}
                >
                  <button
                    type="button"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      setIsFocused(false);
                      adicionarItem(item);
                    }}
                  >
                    {personalizarItem ? (
                      personalizarItem({
                        item: item.valor,
                        navegacao,
                        index,
                      })
                    ) : (
                      <Item $navegacao={navegacao}>{item.label}</Item>
                    )}
                  </button>
                </li>
              );
            })
          ) : (
            <li>
              <button type="button">
                <Item $navegacao={false}>Carregando...</Item>
              </button>
            </li>
          ))}
      </ContainerListaItem>
    </div>
  );
};

export default forwardRef(AutoCompleteTagBase);
