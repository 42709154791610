/* eslint-disable no-nested-ternary */
import {
  FormatarDataHoraParaPtBr,
  IPesagemLista,
  SituacaoPesagemEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import PesagemComunicador from '../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Comunicador/PesagemComunicador';
import PesagemLogComunicador from '../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Log/Comunicador/PesagemLogComunicador';

const PesagemLista: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await PesagemComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });
        const dados = response.dados[0].map((data: IPesagemLista) => {
          return {
            id: data.id,
            numero: <div className="lista-valor">{data.numero}</div>,
            dataHoraEmissao: (
              <div className="lista-data">
                {FormatarDataHoraParaPtBr(data.dataHoraEmissao) || ''}
              </div>
            ),
            'produto.codigoDescricao': (
              <div className="lista-texto">
                {data.produto
                  ? data.produto.map((valor) => {
                      return (
                        <div key={valor.codigo}>
                          {`${valor.codigo} - ${valor.descricao}`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            listaPesagemPessoaNomeRazaoSocial: (
              <div className="lista-texto">
                {data.listaPesagemPessoaNomeRazaoSocial.join(', ')}
              </div>
            ),

            pesoBruto: (
              <div className="lista-valor">
                {data.pesoBruto
                  ? Number(data.pesoBruto).FormatarParaPtBr()
                  : ''}
              </div>
            ),
            pesoTara: (
              <div className="lista-valor">
                {data.pesoTara ? Number(data.pesoTara).FormatarParaPtBr() : ''}
              </div>
            ),
            pesoLiquido: (
              <div className="lista-valor">
                {data.pesoLiquido
                  ? Number(data.pesoLiquido).FormatarParaPtBr()
                  : ''}
              </div>
            ),

            situacao: (
              <div
                className="lista-texto"
                style={{
                  fontWeight: 'bold',
                  color:
                    data.situacao === SituacaoPesagemEnum.utilizada
                      ? 'green'
                      : data.situacao ===
                          SituacaoPesagemEnum.parcialmenteUtilizada
                        ? 'darkgoldenrod'
                        : data.situacao === SituacaoPesagemEnum.cancelada
                          ? 'red'
                          : 'black',
                }}
              >
                {data.situacao}
              </div>
            ),
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await PesagemComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = PesagemLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={permissoes.SuprimentosPesagensPesagem?.exclui}
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.SuprimentosPesagensPesagem]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosPesagem' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Pesagem"
          permissao={permissoes.SuprimentosPesagensPesagem}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return PesagemLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default PesagemLista;
