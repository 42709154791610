const SaldoPorPortadorPlanilha = [
  {
    titulo: 'Portador',
    valor: 'listaSaldoPorPortador.portador',
  },
  {
    titulo: 'Saldo',
    valor: 'listaSaldoPorPortador.saldo',
  },
];

export default SaldoPorPortadorPlanilha;
