"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDocumentoFiltroContaEnum;
(function (TipoDocumentoFiltroContaEnum) {
    TipoDocumentoFiltroContaEnum["manual"] = "Manual";
    TipoDocumentoFiltroContaEnum["notaFiscalEntradaTerceiros"] = "Nota Fiscal de Entrada (Terceiros)";
    TipoDocumentoFiltroContaEnum["notaFiscalEntradaPropria"] = "Nota Fiscal de Entrada (Pr\u00F3pria)";
    TipoDocumentoFiltroContaEnum["notaFiscalSaidaPropria"] = "Nota Fiscal de Sa\u00EDda (Pr\u00F3pria)";
    TipoDocumentoFiltroContaEnum["notaFiscalConsumidorPropria"] = "Nota Fiscal de Consumidor (Pr\u00F3pria)";
    TipoDocumentoFiltroContaEnum["conhecimentoTransporteProprio"] = "Conhecimento de Transporte (Pr\u00F3prio)";
    TipoDocumentoFiltroContaEnum["conhecimentoTransporteTerceiros"] = "Conhecimento de Transporte (Terceiros)";
})(TipoDocumentoFiltroContaEnum || (TipoDocumentoFiltroContaEnum = {}));
exports.default = TipoDocumentoFiltroContaEnum;
