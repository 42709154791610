import React from 'react';
import CentroCustoLista from './Lista';
import { TreeContextProvider } from './Hooks/TreeContext';

const CentroCusto: React.FC = () => {
  return (
    <TreeContextProvider>
      <CentroCustoLista />
    </TreeContextProvider>
  );
};

export default CentroCusto;
