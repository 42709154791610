import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa/possui-integracao-tef';

interface ITerminalCaixaPossuiIntegracaoTefComunicadorShow {
  id: string;
}

class TerminalCaixaPossuiIntegracaoTefComunicador {
  public async show(
    params: ITerminalCaixaPossuiIntegracaoTefComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new TerminalCaixaPossuiIntegracaoTefComunicador();
