import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/relatorios/ordem-servico-veiculo/ordem-servico-veiculo-por-itens';

interface IOrdemServicoVeiculoPorItensRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrdemServicoVeiculoPorItensRelatorioComunicador {
  public async index(
    params: IOrdemServicoVeiculoPorItensRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrdemServicoVeiculoPorItensRelatorioComunicador();
