import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  ConverterMilimitroParaPonto,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPesagemTicket80mmImpressao,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPesagemTicket80mmImpressaoPDF {
  dados: IPesagemTicket80mmImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
  },

  headerEmpresa: {
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 5,
    width: '100%',
    flex: 1,
  },
});

const PesagemTicket80mmImpressaoPDF: React.FC<
  IPesagemTicket80mmImpressaoPDF
> = ({ dados }) => {
  const larguraTotal = ConverterMilimitroParaPonto(80);

  let alturaTotalPessoa = 0;
  let alturaTotalEstoque = 0;
  let alturaTotal = 0;

  if (dados.listaPesagemPessoa) {
    dados.listaPesagemPessoa.forEach((item, index) => {
      if (index === 0 || index === 1) {
        alturaTotalPessoa = ConverterMilimitroParaPonto(80);
      } else {
        alturaTotalPessoa += 28.346438836889;
      }
    });
  }
  if (dados.listaPesagemLocalEstoque) {
    dados.listaPesagemLocalEstoque.forEach((item, index) => {
      if (index === 0 || index === 1) {
        alturaTotalEstoque = alturaTotalPessoa;
      } else {
        alturaTotalEstoque += 14.1732194184445;
      }
    });
  }
  alturaTotal =
    alturaTotalEstoque === 0 ? alturaTotalPessoa : alturaTotalEstoque;

  const size = {
    width: larguraTotal,
    height: alturaTotal === 0 ? ConverterMilimitroParaPonto(80) : alturaTotal,
  };

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait" size={size}>
        <View style={styles.headerEmpresa}>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
              {dados.empresa.nomeRazaoSocial}
            </Text>
            <Text style={{ fontSize: 5 }}>
              {dados.empresa.empresaEndereco.logradouro}
              {`, ${dados.empresa.empresaEndereco.numero}`}
              {`, ${dados.empresa.empresaEndereco.bairro}`}
            </Text>
            <Text style={{ fontSize: 5 }}>
              {dados.empresa.empresaEndereco.complemento}
            </Text>
            <Text style={{ fontSize: 5 }}>
              {dados.empresa.empresaEndereco.cep}
              {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
              {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
            </Text>
            <Text style={{ fontSize: 5 }}>
              {dados.empresa.telefone && `Telefone: ${dados.empresa.telefone}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            width: '100%',
            padding: 2,
            justifyContent: 'center',
            flexDirection: 'row',
            fontSize: 5,
            borderBottom: '1pt solid black',
            borderTop: '1pt solid black',
          }}
        >
          <Text>COMPROVANTE DE PESAGEM</Text>
        </View>

        <View style={{ flexDirection: 'row', padding: 3 }}>
          <View style={{ width: '20%' }}>
            <Text style={{ fontSize: 4, fontWeight: 'bold' }}>
              {`Número: ${dados.numero}`}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 4 }}>
              {`Data Emissão: ${FormatarDataParaPtBr(dados.dataHoraEmissao)}`}
            </Text>
          </View>
        </View>

        <View
          style={{ width: '100%', borderTop: '1pt solid black', padding: 3 }}
        >
          <Text style={{ fontSize: 4 }}>
            {`Produto: ${dados.produto.codigo} - ${dados.produto.descricao}`}
          </Text>

          <View
            style={{ flexDirection: 'row', marginTop: 2, fontWeight: 'bold' }}
          >
            <Text style={{ fontSize: 4, width: '20%' }}>
              {`Peso Bruto: ${Number(dados.pesoBruto).FormatarParaPtBr()} KG`}
            </Text>
            <Text style={{ fontSize: 4, width: '20%' }}>
              {`Peso Tara: ${Number(dados.pesoTara).FormatarParaPtBr()} KG`}
            </Text>
            <Text style={{ fontSize: 4, width: '20%' }}>
              {`Peso Líquido: ${Number(
                dados.pesoLiquido
              ).FormatarParaPtBr()} KG`}
            </Text>
          </View>
        </View>

        <View style={styles.data}>
          {dados.listaPesagemPessoa?.length !== 0 && (
            <View
              style={{
                width: '100%',
                borderTop: '1pt solid black',
                borderBottom: '1pt solid black',
                padding: 3,
              }}
            >
              {dados.listaPesagemPessoa?.map((pessoas) => {
                return (
                  <View style={{ fontSize: 4, marginBottom: 3 }}>
                    <Text style={{ fontWeight: 'bold' }}>
                      Produtor: {pessoas.pessoa.nomeRazaoSocial}
                    </Text>
                    <Text>
                      {pessoas.pessoa.listaPessoaEndereco[0].logradouro}
                    </Text>
                    {pessoas.pessoa.listaPessoaEndereco[0].complemento !==
                      '' && (
                      <Text>
                        {`${pessoas.pessoa.listaPessoaEndereco[0].complemento} - ${pessoas.pessoa.listaPessoaEndereco[0].numero}`}
                      </Text>
                    )}
                    {pessoas.pessoa.listaPessoaEndereco[0].cep !== '' && (
                      <Text>{`${pessoas.pessoa.listaPessoaEndereco[0].cep} - ${pessoas.pessoa.listaPessoaEndereco[0].cidade.nome} - ${pessoas.pessoa.listaPessoaEndereco[0].cidade.estado.sigla}`}</Text>
                    )}
                    <Text style={{ fontWeight: 'bold' }}>
                      Peso Líquido:{' '}
                      {Number(pessoas.pesoLiquido).FormatarParaPtBr()}
                    </Text>
                  </View>
                );
              })}
            </View>
          )}
          {dados.listaPesagemLocalEstoque?.length !== 0 && (
            <View
              fixed
              style={{ flexDirection: 'row', fontSize: 4, padding: 3 }}
            >
              <View style={{ width: '30%', fontWeight: 'bold' }}>
                <Text>Locais Estoque</Text>
              </View>
              <View style={{ width: '1%' }} />

              <View style={{ width: '20%', alignItems: 'flex-end' }}>
                <Text>Peso Líquido</Text>
              </View>
              <View style={{ width: '1%' }} />
            </View>
          )}

          {dados.listaPesagemLocalEstoque?.map((dado) => {
            return (
              <View
                key={dado.id}
                style={{
                  flexDirection: 'row',
                  fontSize: 4,
                  marginBottom: 10,
                  padding: 3,
                }}
              >
                <View style={{ width: '30%' }}>
                  <Text>{dado.localEstoque.descricao}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '20%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.pesoLiquido).FormatarParaPtBr()}</Text>
                </View>

                <View style={{ width: '1%' }} />
              </View>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 4,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PesagemTicket80mmImpressaoPDF;
