import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda/impressao/paisagem-sem-foto-produto';

interface IPedidoVendaPaisagemSemFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class PedidoVendaPaisagemSemFotoProdutoImpressaoComunicador {
  public async show(
    params: IPedidoVendaPaisagemSemFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaPaisagemSemFotoProdutoImpressaoComunicador();
