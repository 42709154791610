import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/atualizar-observacao-cliente';

interface IFrenteCaixaPdvAtualizarObservacaoClienteComunicadorUpdate {
  params: {
    idCliente: string;
    observacoes: string;
  };
}

class FrenteCaixaPdvAtualizarObservacaoClienteComunicador {
  public async update(
    params: IFrenteCaixaPdvAtualizarObservacaoClienteComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);
    return response.data;
  }
}

export default new FrenteCaixaPdvAtualizarObservacaoClienteComunicador();
