import { v4 } from 'uuid';
import { Tab, Tabs } from 'react-bootstrap';
import React, { useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ButtonAtualizarDados } from '../styles';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import RemetenteTab from './RemetenteTab';
import DestinatarioTab from './DestinatarioTab';
import RecebedorTab from './ExpedidorTab';
import ExpedidorTab from './RecebedorTab';
import TomadorOutroTab from './TomadorOutroTab';
import EmitenteTab from './EmitenteTab';
import { IParticipante, UseParticipantes } from '../Hooks/ParticipantesHook';

interface IParticipantesTab {
  bloquearCamposPorImportacao: boolean;
}

const ParticipantesTab: React.FC<IParticipantesTab> = ({
  bloquearCamposPorImportacao,
}) => {
  const { loading } = UseForm();
  const { handleAtualizarDadosParticipantesConformeCadastro } =
    UseParticipantes();
  const { permissoes } = UsePermissoes();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const [currentTab, setCurrentTab] = useState<IParticipante>('emitente');

  return (
    <div>
      <ButtonAtualizarDados
        type="button"
        style={{
          minWidth: telaGrande ? 350 : '',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
        }}
        className="btn-padrao btn-verde btn-adicionar"
        onClick={() => {
          handleAtualizarDadosParticipantesConformeCadastro(currentTab);
        }}
        disabled={!permissao?.altera || bloquearCamposPorImportacao || loading}
      >
        <TextoLoading loading={loading}>
          <FiRefreshCcw />
          <span style={{ marginLeft: 10 }}>
            Atualizar Dados Conforme Cadastro
          </span>
        </TextoLoading>
      </ButtonAtualizarDados>

      <Tabs
        id={v4()}
        defaultActiveKey="emitente"
        activeKey={currentTab}
        onSelect={(k) => {
          if (!k) return;
          setCurrentTab(k as IParticipante);
        }}
      >
        <Tab eventKey="emitente" title="Emitente">
          <EmitenteTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="remetente" title="Remetente">
          <RemetenteTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="expedidor" title="Expedidor">
          <ExpedidorTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="recebedor" title="Recebedor">
          <RecebedorTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="destinatario" title="Destinatário">
          <DestinatarioTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="tomadorOutro" title="Tomador Outros">
          <TomadorOutroTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ParticipantesTab;
