"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PisCofinsCalculo;
const Enum_1 = require("../../Enum");
function PisCofinsCalculo(dados) {
    const retorno = Object.assign({}, dados);
    const baseCalculoPisCofinsNormal = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
        Number(dados.valorFreteItem) -
        Number(dados.valorIcms) -
        Number(dados.valorDescontoItem) -
        Number(dados.estadoDestinoValorIcmsSemFcp);
    if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.valor) {
        retorno.baseCalculo = null;
        retorno.aliquota = null;
        retorno.quantidadeUnidadePisCofins = Number(dados.quantidadeUnidadePisCofins);
        retorno.valorUnidadePisCofins = Number(dados.valorUnidadePisCofins);
        if (dados.calcular ||
            (retorno.quantidadeUnidadePisCofins > 0 &&
                retorno.valorUnidadePisCofins > 0)) {
            const valorTotal = retorno.quantidadeUnidadePisCofins * retorno.valorUnidadePisCofins;
            retorno.valor = valorTotal.Arredondar();
            if (dados.calcular) {
                if (retorno.valorUnidadePisCofins === 0 && retorno.valor === 0)
                    retorno.quantidadeUnidadePisCofins = 0;
                if (retorno.quantidadeUnidadePisCofins === 0 && retorno.valor === 0)
                    retorno.valorUnidadePisCofins = 0;
            }
        }
        return retorno;
    }
    if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.percentual) {
        retorno.quantidadeUnidadePisCofins = null;
        retorno.valorUnidadePisCofins = null;
        retorno.baseCalculo = Number(dados.baseCalculo);
        retorno.aliquota = Number(dados.aliquota);
        retorno.valor = Number(dados.valor);
        if (dados.calcular) {
            retorno.baseCalculo = baseCalculoPisCofinsNormal.Arredondar();
            const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
            retorno.valor = valorPercentual.Arredondar();
            if (retorno.aliquota === 0 && retorno.valor === 0)
                retorno.baseCalculo = 0;
            if (retorno.baseCalculo === 0 && retorno.valor === 0)
                retorno.aliquota = 0;
        }
        else if (retorno.baseCalculo > 0 && retorno.aliquota > 0) {
            const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
            retorno.valor = valorPercentual.Arredondar();
        }
        return retorno;
    }
    return retorno;
}
