"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ValorTotalDocumentoCalculo;
function ValorTotalDocumentoCalculo(dados) {
    const resultado = dados.valorSubtotalItem -
        Number(dados.valorDescontoItem) +
        Number(dados.valorFreteItem) +
        Number(dados.valorSeguroItem) +
        Number(dados.valorOutrasDespesasItem) +
        Number(dados.valorIcmsSt) +
        Number(dados.valorFcpIcmsSt) +
        Number(dados.valorIpi) +
        Number(dados.valorImpostoImportacao) +
        Number(dados.valorServicos);
    return resultado.Arredondar();
}
