/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useState } from 'react';

function UseStep(totalDeSteps: number) {
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep((state) => {
      if (state > totalDeSteps) {
        return totalDeSteps;
      }
      return state;
    });
  }, [totalDeSteps]);

  const proximoStep = useCallback(() => {
    setStep((state) => {
      if (state > totalDeSteps) {
        return totalDeSteps;
      }
      return state + 1;
    });
  }, [totalDeSteps]);

  const stepAnterior = useCallback(() => {
    setStep((state) => {
      if (state < 1) return 1;
      return state - 1;
    });
  }, []);

  return {
    proximoStep,
    stepAnterior,
    setStep,
    ultimoStep: step === totalDeSteps,
    step,
  };
}

export default UseStep;
