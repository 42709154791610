import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/custo/configuracao/item-creditar';
const rotaNovo = 'suprimentos/custo/configuracao';

interface ICustoConfiguracaoItemCreditarComunicadorShow {
  id: string;
}

interface ICustoConfiguracaoItemCreditarComunicadorStore {
  idDetalheRegistroPrincipal: string;
  params: any;
}

interface ICustoConfiguracaoItemCreditarComunicadorDelete {
  id: string;
}

class CustoConfiguracaoItemCreditarComunicador {
  public async show(
    params: ICustoConfiguracaoItemCreditarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: ICustoConfiguracaoItemCreditarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idDetalheRegistroPrincipal}/item-creditar`,
      params.params
    );

    return response.data;
  }

  public async delete(
    params: ICustoConfiguracaoItemCreditarComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CustoConfiguracaoItemCreditarComunicador();
