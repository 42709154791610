import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/serie-auto-complete';

interface ISerieAutoCompleteComunicadorIndex {
  params: any;
}

class SerieAutoCompleteComunicador {
  public async index(params: ISerieAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SerieAutoCompleteComunicador();
