import api from '../../../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/seguro/averbacao';
const rotaNovo = 'transporte/manifesto-transporte/seguro';

interface IManifestoTransporteSeguroAverbacaoComunicadorShow {
  id: string;
}

interface IManifestoTransporteSeguroAverbacaoComunicadorStore {
  idPai: string;
  params: any;
}

interface IManifestoTransporteSeguroAverbacaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IManifestoTransporteSeguroAverbacaoComunicadorDelete {
  id: string;
}

class ManifestoTransporteSeguroAverbacaoComunicador {
  public async show(
    params: IManifestoTransporteSeguroAverbacaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IManifestoTransporteSeguroAverbacaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/averbacao`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IManifestoTransporteSeguroAverbacaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IManifestoTransporteSeguroAverbacaoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteSeguroAverbacaoComunicador();
