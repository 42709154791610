/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteTagBase, {
  IInputAutoCompleteTagBaseRef,
  IOnChangeListaItemAtualEvent,
  IOnAdicionarEvent,
  IOnMoverEvent,
  IOnRemoverEvent,
  IOnChangeTextoEvent,
  IFiltrarItens,
} from '../../AutoCompleteTagBase';
import RamoAtividadeProfissaoAutoCompleteTagComunicador from '../../../../../Comunicador/Comercial/Pessoas/RamoAtividadeProfissao/AutoCompleteTag/RamoAtividadeProfissaoAutoCompleteTag/RamoAtividadeProfissaoAutoCompleteTagComunicador';
import UseRegisterAutoCompleteTag from '../../AutoCompleteTagBase/Hooks/UseRegisterAutoCompleteTag';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IRamoAtividadeProfissao {
  id: string;
  descricao: string;
}

interface IInputAutoCompleteTagRamoAtividadeProfissaoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualEvent,
    props: IOnChange
  ) => void;
  onChangeListaItemAtualAposCarregarSemClear?: (
    evento: IOnChangeListaItemAtualEvent
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarEvent) => void;
  onRemoverItem?: (evento: IOnRemoverEvent) => void;
  onMoverItem?: (evento: IOnMoverEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;
}

export interface IInputAutoCompleteTagRamoAtividadeProfissaoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteTagBaseRef>;
}

const InputAutoCompleteTagRamoAtividadeProfissao: React.ForwardRefRenderFunction<
  IInputAutoCompleteTagRamoAtividadeProfissaoRef,
  IInputAutoCompleteTagRamoAtividadeProfissaoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    ativo = true,
    listaIdParaRemover = [],

    onChangeListaItemAtual,
    onChangeListaItemAtualAposCarregarSemClear,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    onChangeTexto,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const handleObterChave = useCallback((item: IRamoAtividadeProfissao) => {
    return item.id;
  }, []);

  const {
    autoCompleteRef,
    flags,
    error: erroUnform,
  } = UseRegisterAutoCompleteTag(
    {
      nome: name,
      handleObterChaveUnica: handleObterChave,
    },
    {
      nomeObjeto,

      setSemExecutarEvento() {
        quantidadeAlterado.current++;
      },
    }
  );

  const [erro, setErro] = useState(erroUnform);

  const handleObterLabel = useCallback((item: IRamoAtividadeProfissao) => {
    if (!item.descricao) return '';

    return `${item.descricao}`;
  }, []);

  const handleChangeListaItemAtual = useCallback(
    (event: IOnChangeListaItemAtualEvent, { valorAnterior }: IOnChange) => {
      quantidadeAlterado.current++;
      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(event, { valorAnterior });
      }
      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeListaItemAtualAposCarregarSemClear
      ) {
        onChangeListaItemAtualAposCarregarSemClear(event);
      }
    },
    [flags, onChangeListaItemAtual, onChangeListaItemAtualAposCarregarSemClear]
  );

  const handleFiltrarItens = useCallback(
    async ({ valor, offset, limite, listaValorAtual }: IFiltrarItens) => {
      setPesquisando(true);

      try {
        const response =
          await RamoAtividadeProfissaoAutoCompleteTagComunicador.index({
            params: {
              offset,
              limite,
              texto: valor,
              ativo,
              listaIdParaRemover: [
                ...listaIdParaRemover,
                ...listaValorAtual.map(
                  (valorAtual: IRamoAtividadeProfissao) => valorAtual.id
                ),
              ],
            },
          });

        return response;
      } catch (err) {
        TratarErros(err);
        return false;
      }
    },
    [ativo, listaIdParaRemover]
  );

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    if (rest.disabled) {
      autoCompleteRef.current?.setDisabled(true);
    } else {
      autoCompleteRef.current?.setDisabled(false);
    }
  }, [autoCompleteRef, erroUnform, rest, rest.disabled]);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteTagBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={handleFiltrarItens}
            obterChaveUnica={handleObterChave}
            obterLabel={handleObterLabel}
            onChangeListaItemAtual={handleChangeListaItemAtual}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeTexto={onChangeTexto}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteTagRamoAtividadeProfissao);
