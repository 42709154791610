import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';

const BtnSalvar: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const { size } = UseReactSizeMeBodyHook();

  return (
    <Button type="button" {...rest}>
      {children || (
        <div
          style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
        >
          <BiSave />
          {size.width && size.width > 800 && (
            <span style={{ marginLeft: 10 }}>Salvar</span>
          )}
        </div>
      )}
    </Button>
  );
};

export default BtnSalvar;
