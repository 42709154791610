"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // #region Comercial
    comercialPessoasFinalidadeUsoCliente: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    comercialPessoa: [
        {
            nomeCampo: 'codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'nomeRazaoSocial',
            titulo: 'Nome/Razão Social',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'nomeFantasia',
            titulo: 'Nome Fantasia',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjCpfVirtual',
            titulo: 'CNPJ/CPF',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'inscricaoEstadual',
            titulo: 'Inscrição Estadual',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaPessoaTelefone.dddNumeroVirtual',
            titulo: 'Telefone',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaEnderecoPrincipal.cidade.nome',
            titulo: 'Cidade',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaEnderecoPrincipal.cidade.estado.sigla',
            titulo: 'UF',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ramoAtividadeProfissao.descricao',
            titulo: 'Ramo Atividade/Profissão',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
    ],
    comercialRamoAtividadeProfissao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoRamoAtividadeProfissao',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    comercialRegraEscolhaTabelaPreco: [
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Pessoas',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao.ramoAtividadeProfissao.descricao',
            titulo: 'Ramo Atividade/Profissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoEstado.estado.sigla',
            titulo: 'Estados',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'contribuinte',
            titulo: 'Contribuinte',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoDestinado.destinado',
            titulo: 'Destinado',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoPessoaRepresentante.pessoa.codigoNomeRazaoSocial',
            titulo: 'Representantes',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupo de Produtos',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'casasDecimais',
            titulo: 'Casas Decimais',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaTabelaPrecoEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
    ],
    comercialOrcamento: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'orcamentoPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoOrcamentoPedidoVenda',
            titulo: 'Situação Pedido',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    comercialPedidoVenda: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pedidoVendaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataPrevistaFaturamento',
            titulo: 'Data Prevista Faturamento',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoPedidoVendaNotaFiscalSaida',
            titulo: 'Situação Nota',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroPedidoIntegracao',
            titulo: 'Num. Ped. Integ.',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
    ],
    comercialPedidoVendaImportacaoLog: [
        {
            nomeCampo: 'numeroPedidoIntegracao',
            titulo: 'Núm. Ped. Integ.',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pedidoVenda.numero',
            titulo: 'Núm. Pedido',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'data',
            titulo: 'Data',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'mensagem',
            titulo: 'Mensagem',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    comercialNotaFiscalSaida: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serie.codigo',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'notaFiscalSaidaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    comercialRegraCalculoComissao: [
        {
            nomeCampo: 'listaRegraCalculoComissaoPessoaRepresentante.pessoa.codigoNomeRazaoSocial',
            titulo: 'Representantes',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoComissaoPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Clientes',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoComissaoProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoComissaoGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupos de Produto',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoComissaoRegraEscolhaTabelaPreco.regraEscolhaTabelaPreco.descricao',
            titulo: 'Regra Escolha Tabela Preço',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Núm. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'percentualDescontoInicial',
            titulo: 'Perc. Desc. Inic.',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'percentualDescontoFinal',
            titulo: 'Perc. Desc. Fin.',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraCalculoComissaoResultado.percentualComissao',
            titulo: 'Perc. de Comissão',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoComissaoEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
    ],
    comercialImpressaoEtiquetasNotaFiscal: [
        {
            nomeCampo: 'numero',
            titulo: 'Núm. Nota',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Emissäo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'notaFiscalSaidaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'notaFiscalSaidaTransporteVolume.quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'notaFiscalSaidaTransporteTransportadora.pessoaTransportadora.codigoNomeRazaoSocial',
            titulo: 'Transportadora',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: false,
        },
    ],
    comercialImpressaoEtiquetasPedidoVenda: [
        {
            nomeCampo: 'numero',
            titulo: 'Núm. Pedido',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Emissäo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVendaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVendaTransporteVolume.quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVendaTransporteTransportadora.pessoaTransportadora.codigoNomeRazaoSocial',
            titulo: 'Transportadora',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: false,
        },
    ],
    comercialImpressaoEtiquetasPedidoVendaItem: [
        {
            nomeCampo: 'pedidoVenda.numero',
            titulo: 'Núm. Pedido',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVenda.dataHoraEmissao',
            titulo: 'Data Emissäo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVenda.pedidoVendaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'pedidoVenda.pedidoVendaTransporteTransportadora.pessoaTransportadora.codigoNomeRazaoSocial',
            titulo: 'Transportadora',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: false,
        },
    ],
    comercialAberturaFechamentoCaixa: [
        {
            nomeCampo: 'terminalCaixa.descricao',
            titulo: 'Terminal Caixa',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraAbertura',
            titulo: 'Data Hora Abertura',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'usuarioAbertura.nome',
            titulo: 'Usuário Abertura',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraFechamento',
            titulo: 'Data Hora Fechamento',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'usuarioFechamento.nome',
            titulo: 'Usuário Fechamento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorEmDinheiro',
            titulo: 'Valor em Dinheiro',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'situação',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    comercialTerminalCaixa: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'idIntegracaoTefControlPay',
            titulo: 'Id Integração TEF (ControlPay)',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'storeIdSiTef',
            titulo: 'Store Id (SiTef)',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'terminalIdSiTef',
            titulo: 'Terminal Id (SiTef)',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    // #endregion Comercial
    // #region Configuracao
    configuracaoUsuario: [
        {
            nomeCampo: 'nome',
            titulo: 'Nome',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'login',
            titulo: 'Usuário',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'email',
            titulo: 'E-mail',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    configuracaoTabelaCodigo: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'campo',
            titulo: 'Campo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'proximoValor',
            titulo: 'Próximo Valor',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    configuracaoEtiqueta: [
        {
            nomeCampo: 'descricaoModeloEtiqueta',
            titulo: 'Descrição Modelo Etiqueta',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoEtiqueta',
            titulo: 'Tipo de Etiqueta',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeEtiquetasLinha',
            titulo: 'Quantidade de Etiquetas por Linha',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'larguraTotal',
            titulo: 'Largura Total',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'alturaTotal',
            titulo: 'Altura Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'larguraEtiqueta',
            titulo: 'Largura da Etiqueta',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'alturaEtiqueta',
            titulo: 'Altura da Etiqueta',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    configuracaoContaEmail: [
        {
            nomeCampo: 'email',
            titulo: 'E-mail',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'direcionarEmail',
            titulo: 'Direcionar E-mail',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'verificado',
            titulo: 'Verificado',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    // #endregion Configuracao
    // #region Contabil
    contabilContabilista: [
        {
            nomeCampo: 'dataInicial',
            titulo: 'Data Inicial',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'nome',
            titulo: 'Nome do Contabilista',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpj',
            titulo: 'CNPJ',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cpf',
            titulo: 'CPF',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'crc',
            titulo: 'CRC',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    // #endregion Contabil
    // #region Financeiro
    financeiroGeralFormaPagamento: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'meioPagamento',
            titulo: 'Meio de Pagamento',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoTituloSped',
            titulo: 'Tipo de Título Sped',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'formaPagamentoParcelasNasInformacoesContribuinte',
            titulo: 'Forma de Pagamento das Parcelas nas Informações Contribuinte',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'diarioAuxiliar',
            titulo: 'Diário Auxiliar',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'padraoSistema',
            titulo: 'Padrão do Sistema',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaFormaPagamentoEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    financeiroGeralPortador: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'banco.codigoNome',
            titulo: 'Banco',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'agencia',
            titulo: 'Agência',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'contaBancaria',
            titulo: 'Conta',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'portadorBoleto.utilizaBoleto',
            titulo: 'Utiliza Boleto',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
    ],
    financeiroGeralRegraEscolhaIntegracaoPagamento: [
        {
            nomeCampo: 'listaRegraEscolhaIntegracaoPagamentoBandeiraCartao.bandeiraCartao.descricao',
            titulo: 'Bandeiras de Cartão',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaIntegracaoPagamentoFormaPagamento.formaPagamento.descricao',
            titulo: 'Forma de Pagamento',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'operacao',
            titulo: 'Operação',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeParcelasInicial',
            titulo: 'Qtde. Parc. Inicial',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeParcelasFinal',
            titulo: 'Qtde. Parc. Final',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaIntegracaoPagamentoResultado.pessoa.codigoNomeRazaoSocial',
            titulo: 'Pessoa',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaIntegracaoPagamentoResultado.cnpjInstituicaoPagamento',
            titulo: 'CNPJ Inst. Pag.',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaIntegracaoPagamentoResultado.tipoIntegracaoPagamento',
            titulo: 'Tipo de Integ. para Pagamento',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaIntegracaoPagamentoResultado.adquirente',
            titulo: 'Adquirente',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaIntegracaoPagamentoEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
    ],
    financeiroRegraEscolhaPortador: [
        {
            nomeCampo: 'listaRegraEscolhaPortadorFormaPagamento.formaPagamento.descricao',
            titulo: 'Forma de Pagamento',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaPortadorResultado.portador.descricao',
            titulo: 'Portador',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaPortadorEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    financeiroGeralRegraCalculoCartao: [
        {
            nomeCampo: 'listaRegraCalculoCartaoBandeiraCartao.bandeiraCartao.descricao',
            titulo: 'Bandeiras',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoCartaoRegraEscolhaIntegracaoPgto.regraEscolhaIntegracaoPagamento.regraEscolhaIntegracaoPagamentoResultado.pessoa.nomeRazaoSocial',
            titulo: 'Integradores',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'operacao',
            titulo: 'Operação (Déb./Créd)',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'operacaoVendaAntecipacao',
            titulo: 'Operação (Ven./Antec.)',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeParcelasInicial',
            titulo: 'Qtde. Parc. Inicial',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeParcelasFinal',
            titulo: 'Qtde. Parc. Final',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraCalculoCartaoResultado.taxaAdministrativaVenda',
            titulo: 'Taxa Adm. (Venda)',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraCalculoCartaoResultado.diasCreditoVenda',
            titulo: 'Dias p/ Créd. (Venda)',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraCalculoCartaoResultado.considerarProximoDiaUtil',
            titulo: 'Cons. próx. dia útil',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraCalculoCartaoResultado.taxaAdministrativaAntecipacao',
            titulo: 'Taxa Adm. (Antec.)',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraCalculoCartaoEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
    ],
    financeiroTransferenciaEntrePortadores: [
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Número Doc.',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHora',
            titulo: 'Data Hora',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valor',
            titulo: 'Valor',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'movimentoPortadorOrigem.portador.descricao',
            titulo: '(-) Portador Origem',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'movimentoPortadorDestino.portador.descricao',
            titulo: '(+) Portador Destino',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'observacoes',
            titulo: 'Observações',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
    ],
    financeiroGeracaoEnvioBoletos: [
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Num. Doc.',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.dataHoraEmissao',
            titulo: 'Dt. Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataVencimento',
            titulo: 'Dt. Vencimento',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.pessoa.nomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalParcela',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'boleto.situacao',
            titulo: 'Sit. Boleto',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'portador.descricao',
            titulo: 'Portador',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    financeiroGeralBandeiraCartao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'bandeiraCartao',
            titulo: 'Bandeira (NF-e/NCF-e)',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    financeiroContaParcelaReversao: [
        {
            nomeCampo: 'conta.tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sequencia',
            titulo: 'Parcela',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataVencimento',
            titulo: 'Data Vencimento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorParcela',
            titulo: 'Val. Parcela',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente/Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    financeiroContaParcela: [
        {
            nomeCampo: 'conta.tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sequencia',
            titulo: 'Parcela',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataVencimento',
            titulo: 'Data Vencimento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorParcela',
            titulo: 'Val. Parcela',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalParcela',
            titulo: 'Val. Total Parcela',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalMovimentos',
            titulo: 'Val. Total Movimentos',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalAberto',
            titulo: 'Val. Total Aberto',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente/Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.observacoes',
            titulo: 'Observações',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
    ],
    financeiroLiquidacaoContasEmLote: [
        {
            nomeCampo: 'conta.tipo',
            titulo: 'Tipo',
            visivel: false,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sequencia',
            titulo: 'Parcela',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataVencimento',
            titulo: 'Data Vencimento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorParcela',
            titulo: 'Val. Parcela',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalParcela',
            titulo: 'Val. Total Parcela',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalMovimentos',
            titulo: 'Val. Total Movimentos',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalAberto',
            titulo: 'Val. Total Aberto',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente/Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.observacoes',
            titulo: 'Observações',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaContaParcelaReversao.conta.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente/Fornecedor Conta Vinculada',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.notaFiscalSaida.listaNotaFiscalSaidaItem.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.pedidoVendaItem.pedidoVenda.numeroPedidoIntegracao',
            titulo: 'Núm, Pedido de Integração',
            visivel: false,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
    ],
    financeiroAdiantamentoPrevisao: [
        {
            nomeCampo: 'conta.tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroDocumento',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sequencia',
            titulo: 'Parcela',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataVencimento',
            titulo: 'Data Vencimento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorParcela',
            titulo: 'Val. Parcela',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalParcela',
            titulo: 'Val. Total Parcela',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalMovimentos',
            titulo: 'Val. Total Movimentos',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalAberto',
            titulo: 'Val. Total Aberto',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente/Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.tipoAdiantamento',
            titulo: 'Tipo de Adiantamento',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.valorTotalAdiantamento',
            titulo: 'Val. Total Adiantamento',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.valorUtilizadoAdiantamento',
            titulo: 'Val. Utilizado Adiantamento',
            visivel: true,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.valorDisponivelAdiantamento',
            titulo: 'Val. Disponivel Adiantamento',
            visivel: true,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conta.situacaoAdiantamento',
            titulo: 'Situação Adiantamento',
            visivel: true,
            ordenar: true,
            ordem: 14,
            permitirEsconder: true,
        },
    ],
    financeiroCategoriaDreRegraEscolhaCategoria: [
        {
            nomeCampo: 'tipoConta',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCategoriaResultado.categoria.codigoDescricao',
            titulo: 'Categorias',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'avista',
            titulo: 'A Vista',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaTipoLancamento.tipoLancamento',
            titulo: 'Tipos de Lançamento',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaFormaPagamento.formaPagamento.descricao',
            titulo: 'Formas de Pagamento',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Pessoas',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaRamoAtividadeProfissao.ramoAtividadeProfissao.descricao',
            titulo: 'Ramos de Atividade',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelos de Documento',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipo Sped',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal (CFOP)',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaOperacaoFiscal.operacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupos de Produto',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: true,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCategoriaEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 15,
            permitirEsconder: true,
        },
    ],
    // #endregion Financeiro
    // #region Fiscal
    fiscalTipoNota: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição do Tipo de Nota',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaTipoNotaMotivo.descricao',
            titulo: 'Motivos do Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sugestaoPadrao',
            titulo: 'Sugestão Padrão',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaOperacaoFiscal: [
        {
            nomeCampo: 'tipoNota.descricao',
            titulo: 'Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaMotivo.descricao',
            titulo: 'Motivo',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalDestinado.destinado',
            titulo: 'Destinado à',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temMva',
            titulo: 'Tem MVA',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temCest',
            titulo: 'Tem CEST',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sujeitoASt',
            titulo: 'Sujeito a ST.',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'contribuinte',
            titulo: 'Contribuinte',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temCodigoAnp',
            titulo: 'Tem Código ANP',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaSpedTipoProdutoCodigoDescricao.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de SPED',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalNcm.ncm.codigo',
            titulo: 'NCM',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalCest.cest.codigo',
            titulo: 'CEST',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Pessoas',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalEstado.estado.sigla',
            titulo: 'Estados',
            visivel: true,
            ordenar: false,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalCidade.cidade.nome',
            titulo: 'Cidades',
            visivel: true,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelo Documento',
            visivel: true,
            ordenar: false,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalSerie.serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: false,
            ordem: 18,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaOperacaoFiscalResultado.listaRegraEscolhaOperacaoFiscalResultadoOperacaoFiscal.operacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 19,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalEstadoEmpresaLogada.estado.sigla',
            titulo: 'Estados Empresa Logada',
            visivel: true,
            ordenar: false,
            ordem: 20,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaOperacaoFiscalResultado.naturezaOperacao',
            titulo: 'Natureza da Operação',
            visivel: true,
            ordenar: false,
            ordem: 21,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaOperacaoFiscalResultado.finalidadeEmissao',
            titulo: 'Finalidade da Emissão',
            visivel: true,
            ordenar: false,
            ordem: 22,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 23,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 24,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 25,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaOperacaoFiscalEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 26,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaCstCreditoIcms: [
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsCst.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST (Nota Fiscal Emitida)',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsRegimeTributario.regimeTributario',
            titulo: 'Regimes Tributários',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsDestinado.destinado',
            titulo: 'Destinado',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de Sped',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal (CFOP)',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupos de Produtos',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsNcm.ncm.codigo',
            titulo: 'NCMs',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoIcmsResultado.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST do ICMS',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoIcmsResultado.creditarIcms',
            titulo: 'Cred. ICMS',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoIcmsResultado.atualizarCreditarIcmsSubstitutoRetidoEfetivo',
            titulo: 'At/Cred. ICMS Subs/Efetivo/Retido',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIcmsEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaCstCreditoIpi: [
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiCst.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST (Nota Fiscal Emitida)',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiRegimeTributario.regimeTributario',
            titulo: 'Regimes Tributários',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiDestinado.destinado',
            titulo: 'Destinado',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de Sped',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal(CFOP)',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupos de Produtos',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiNcm.ncm.codigo',
            titulo: 'NCMs',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoIpiResultado.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST do IPI',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoIpiResultado.creditarIpi',
            titulo: 'Cred. IPI',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoIpiEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
    ],
    fiscalConsultaAliquotaIcmsInternaInterestadual: [
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data início vigência',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'estadoOrigem.siglaNome',
            titulo: 'Estado de Origem',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'estadoDestino.siglaNome',
            titulo: 'Estado de Destino',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'aliquota',
            titulo: 'Aliquota',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
    ],
    fiscalRegraCstCreditoPisCofins: [
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsCst.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST (Nota Fiscal Emitida)',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsRegimeTributario.regimeTributario',
            titulo: 'Regimes Tributários',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsDestinado.destinado',
            titulo: 'Destinado',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de Sped',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal (CFOP)',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsGrupoProdutoServico.grupoProdutoServico.descricao',
            titulo: 'Grupos de Produtos',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsNcm.ncm.codigo',
            titulo: 'NCMs',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoPisCofinsResultado.codigoSituacaoTributaria.codigoDescricao',
            titulo: 'CST de PIS/COFINS',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstCreditoPisCofinsResultado.creditarPisCofins',
            titulo: 'Cred. PIS/COFINS',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCreditoPisCofinsEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
    ],
    fiscalConsultaAliquotaIpiPorNcm: [
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data início vigência',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ncm.codigoDescricao',
            titulo: 'Nomenclatura comum do Mercosul (NCM)',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'percentual',
            titulo: 'Aliquota',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaCst: [
        {
            nomeCampo: 'tipoNota.descricao',
            titulo: 'Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaMotivo.descricao',
            titulo: 'Motivo',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstDestinado.destinado',
            titulo: 'Destinado à',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstRegimeTributarioEmitente.regimeTributario',
            titulo: 'Regime Tributário',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temMva',
            titulo: 'Tem MVA',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temCest',
            titulo: 'Tem CEST',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'sujeitoASt',
            titulo: 'Sujeito a ST.',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'contribuinte',
            titulo: 'Contribuinte',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temCodigoAnp',
            titulo: 'Tem Código ANP',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstOperacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de SPED',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstNcm.codigo',
            titulo: 'NCM',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCest.codigo',
            titulo: 'CEST',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: false,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Pessoas',
            visivel: true,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstRamoAtividadeProfissao.descricao',
            titulo: 'Ramo Atividade/Profissão',
            visivel: true,
            ordenar: false,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstEstado.estado.sigla',
            titulo: 'Estados',
            visivel: true,
            ordenar: false,
            ordem: 18,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstCidade.cidade.nome',
            titulo: 'Cidades',
            visivel: true,
            ordenar: false,
            ordem: 19,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelo Documento',
            visivel: true,
            ordenar: false,
            ordem: 20,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstSerie.serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: false,
            ordem: 21,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstEstadoEmpresaLogada.estado.sigla',
            titulo: 'Estados Empresa Logada',
            visivel: true,
            ordenar: false,
            ordem: 22,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.codigoSituacaoTributariaIcms.codigoDescricao',
            titulo: 'Código ST. ICMS',
            visivel: true,
            ordenar: false,
            ordem: 23,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIcms',
            titulo: 'Calcular ICMS',
            visivel: true,
            ordenar: false,
            ordem: 24,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularCreditoIcmsSn',
            titulo: 'Calcular Aproveitamento Crédito ICMS SN.',
            visivel: true,
            ordenar: false,
            ordem: 25,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularSt',
            titulo: 'Calcular ST.',
            visivel: true,
            ordenar: false,
            ordem: 26,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.utilizaIcmsOperacaoInterestadual',
            titulo: 'Utiliza ICMS Operação Interestadual',
            visivel: true,
            ordenar: false,
            ordem: 27,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIcmsOperacaoInterestadual',
            titulo: 'Calcular ICMS Operaçao Interestadual',
            visivel: true,
            ordenar: false,
            ordem: 28,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIcmsStRetido',
            titulo: 'Calcular ICMS ST. Retido',
            visivel: true,
            ordenar: false,
            ordem: 29,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIcmsEfetivo',
            titulo: 'Calcular ICMS Efetivo',
            visivel: true,
            ordenar: false,
            ordem: 30,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIcmsSubstituto',
            titulo: 'Calcular ICMS Substituto',
            visivel: true,
            ordenar: false,
            ordem: 31,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.modalidadeBaseCalculoIcms',
            titulo: 'Modalidade da Base de Cálculo',
            visivel: true,
            ordenar: false,
            ordem: 32,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.modalidadeBaseCalculoIcmsSt',
            titulo: 'Modalidade da Base de Cálculo ST',
            visivel: true,
            ordenar: false,
            ordem: 33,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.motivoDesoneracaoIcms',
            titulo: 'Motivo Desoneração',
            visivel: true,
            ordenar: false,
            ordem: 34,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.codigoSituacaoTributariaIpi.codigoDescricao',
            titulo: 'Código ST. IPI',
            visivel: true,
            ordenar: true,
            ordem: 35,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.ipiCodigoEnquadramentoLegal.codigoDescricao',
            titulo: 'IPI Código Enquadramento Legal',
            visivel: true,
            ordenar: false,
            ordem: 36,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularIpi',
            titulo: 'Calcular IPI',
            visivel: true,
            ordenar: false,
            ordem: 37,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins.codigoDescricao',
            titulo: 'Código ST. PIS COFINS',
            visivel: true,
            ordenar: false,
            ordem: 38,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularPisCofins',
            titulo: 'Calcular PIS COFINS',
            visivel: true,
            ordenar: false,
            ordem: 39,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularPisCofinsRetido',
            titulo: 'Calcular PIS COFINS Retido',
            visivel: true,
            ordenar: false,
            ordem: 40,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularFunrural',
            titulo: 'Calcular FUNRURAL',
            visivel: true,
            ordenar: false,
            ordem: 41,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.calcularImpostoAproximado',
            titulo: 'Calcular Valor Aprox. de Tributos',
            visivel: true,
            ordenar: false,
            ordem: 42,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 43,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 44,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 45,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.codigoBeneficiarioFiscalSped',
            titulo: 'Código Beneficiário Fiscal SPED',
            visivel: false,
            ordenar: false,
            ordem: 46,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.baseLegalIcms',
            titulo: 'Base Legal ICMS',
            visivel: false,
            ordenar: false,
            ordem: 47,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.baseLegalIpi',
            titulo: 'Base Legal IPI',
            visivel: false,
            ordenar: false,
            ordem: 48,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.outraBaseLegal',
            titulo: 'Outra Base Legal',
            visivel: false,
            ordenar: false,
            ordem: 49,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaCstResultado.informacoesContribuinte',
            titulo: 'Informações do Contribuinte',
            visivel: false,
            ordenar: false,
            ordem: 50,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaCstEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 51,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'temIpiAliquotaZero',
            titulo: 'Tem IPI Alíquota Zero',
            visivel: true,
            ordenar: false,
            ordem: 52,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaParametro: [
        {
            nomeCampo: 'tipoNota.descricao',
            titulo: 'Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaMotivo.descricao',
            titulo: 'Motivo',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoMovimento',
            titulo: 'Tipo Entrada/Saída ',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroDestinado.destinado',
            titulo: 'Destinado à',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroOperacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de SPED',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal (CFOP)',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroNcm.codigo',
            titulo: 'NCM',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelo Documento',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroSerie.serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.gerarFinanceiro',
            titulo: 'Gerar Financeiro',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.gerarFaturamento',
            titulo: 'Gerar Faturamento',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.movimentarEstoqueFisico',
            titulo: 'Movimentar Estoque Físico',
            visivel: true,
            ordenar: false,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.movimentarEstoqueFiscal',
            titulo: 'Movimentar Estoque Fiscal',
            visivel: true,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.porContaOrdemTerceiro',
            titulo: 'Por Conta e Ordem de Terceiro',
            visivel: true,
            ordenar: false,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.gerarComissao',
            titulo: 'Gerar Comissão',
            visivel: true,
            ordenar: false,
            ordem: 18,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaParametroResultado.indicadorTipoEstoque',
            titulo: 'Tipo Movimento de Estoque',
            visivel: true,
            ordenar: false,
            ordem: 19,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 20,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 21,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaParametroEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 22,
            permitirEsconder: true,
        },
    ],
    fiscalRegraEscolhaAliquota: [
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNota.descricao',
            titulo: 'Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaMotivo.descricao',
            titulo: 'Motivo',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaDestinado.destinado',
            titulo: 'Destinado à',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaRegimeTributarioEmitente.regimeTributario',
            titulo: 'Regime Tributário',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'contribuinte',
            titulo: 'Contribuinte',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaNcm.codigo',
            titulo: 'NCM',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaCest.codigo',
            titulo: 'CEST',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaCstIcms.codigo',
            titulo: 'CST ICMS',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaProduto.produto.codigoDescricao',
            titulo: 'Produtos',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelos de Documento',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaEstado.estado.sigla',
            titulo: 'Estados',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaCidade.cidade.nome',
            titulo: 'Cidades',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaEstadoEmpresaLogada.estado.sigla',
            titulo: 'Estados Empresa Logada',
            visivel: true,
            ordenar: false,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcms',
            titulo: 'Perc. Red. Base Calc. ICMS',
            visivel: true,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaIcms',
            titulo: 'Alíquota ICMS',
            visivel: true,
            ordenar: false,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaFcpIcms',
            titulo: 'Alíquota FCP ICMS',
            visivel: true,
            ordenar: false,
            ordem: 18,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.modalidadeBaseCalculoIcmsSt',
            titulo: 'Modalidade Base Calculo ICMS ST.',
            visivel: true,
            ordenar: false,
            ordem: 19,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaInternaIcmsSt',
            titulo: 'Alíquota Interna ICMS ST.',
            visivel: true,
            ordenar: false,
            ordem: 20,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaInterestadualIcmsSt',
            titulo: 'Alíquota Interestadual ICMS ST.',
            visivel: true,
            ordenar: false,
            ordem: 21,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.mvaSubstituicaoTributaria',
            titulo: 'MVA ST.',
            visivel: true,
            ordenar: false,
            ordem: 22,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.mvaSubstituicaoTributariaSimplesNacional',
            titulo: 'MVA ST. SN.',
            visivel: true,
            ordenar: false,
            ordem: 23,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.valorUnitarioPautaIcmsSt',
            titulo: 'Valor Unitário Pauta ICMS ST.',
            visivel: true,
            ordenar: false,
            ordem: 24,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStRegimeNormal',
            titulo: 'Perc. Red. Base Calc. ICMS ST. Regime Normal',
            visivel: true,
            ordenar: false,
            ordem: 25,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStSimplesNacional',
            titulo: 'Perc. Red. Base Calc. ICMS ST. SN.',
            visivel: true,
            ordenar: false,
            ordem: 26,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaIpi',
            titulo: 'Alíquota IPI',
            visivel: true,
            ordenar: false,
            ordem: 27,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaPis',
            titulo: 'Alíquota PIS',
            visivel: true,
            ordenar: false,
            ordem: 28,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaPisRetido',
            titulo: 'Alíquota PIS Retido',
            visivel: true,
            ordenar: false,
            ordem: 29,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaCofins',
            titulo: 'Alíquota COFINS',
            visivel: true,
            ordenar: false,
            ordem: 30,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaAliquotaResultado.aliquotaCofinsRetido',
            titulo: 'Alíquota COFINS Retido',
            visivel: true,
            ordenar: false,
            ordem: 31,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjContabilidade',
            titulo: 'CNPJ Contabilidade',
            visivel: true,
            ordenar: false,
            ordem: 32,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: false,
            ordem: 33,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 34,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 35,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaAliquotaEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 36,
            permitirEsconder: true,
        },
    ],
    fiscalSerie: [
        {
            nomeCampo: 'codigo',
            titulo: 'Cód. Série',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'subSerie',
            titulo: 'Sub-Série',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'modeloDocumentoCodigoDescricao',
            titulo: 'Modelo Documento',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'proximoNumero',
            titulo: 'Próximo Número',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'incrementarAutomaticamente',
            titulo: 'Inc. Aut',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'utilizacao',
            titulo: 'Utilização',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
    ],
    fiscalInutilizacaoNumeracao: [
        {
            nomeCampo: 'serie.modeloDocumento.codigo',
            titulo: 'Modelo',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serie.codigo',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroInicial',
            titulo: 'Núm. Inicial',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroFinal',
            titulo: 'Núm. Final',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoInutilizacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ambiente',
            titulo: 'Ambiente',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraTransmissao',
            titulo: 'Data Transmissão',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'justificativa',
            titulo: 'Justificativa',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    fiscalManutencaoDocumentosFiscais: [
        {
            nomeCampo: 'tipoDocumento',
            titulo: 'Tipo de Documento',
            visivel: true,
            arrastavel: false,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serie',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjCpf',
            titulo: 'CNPJ/CPF',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoa.nomeRazaoSocial',
            titulo: 'Nome Razão Social',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Emissão',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'total',
            titulo: 'Total',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação Autorização',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'chaveAcesso',
            titulo: 'Chave de Acesso',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoManifestacao',
            titulo: 'Situação Manifestação',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
    ],
    contabilGeracaoArquivosContabeis: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    fiscalSpedConfiguracao: [
        {
            nomeCampo: 'dataInicial',
            titulo: 'Vig. Data Inic.',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'perfilSpedFiscal',
            titulo: 'Perfil',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'indicadorAtividadeSpedFiscal',
            titulo: 'Indicador de Atividade',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    // #endregion Fiscal
    // #region Geral
    // #endregion Geral
    // #region Suprimentos
    suprimentosUnidadeMedida: [
        {
            nomeCampo: 'sigla',
            titulo: 'Sigla',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    suprimentosMarcaProduto: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    suprimentosColecao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicial',
            titulo: 'Data Inicial',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinal',
            titulo: 'Data Final',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    suprimentosGrupoProdutoServico: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'associacao',
            titulo: 'Associação',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    suprimentosFamilia: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'associacao',
            titulo: 'Associação',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    suprimentosLocalEstoque: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'disponivelParaVenda',
            titulo: 'Disponível para Venda',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'considerarSped',
            titulo: 'SPED Fiscal',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'associacao',
            titulo: 'Associação',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
    ],
    suprimentosMovimentoManualEstoque: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.produtoEstoque.unidadeMedida.sigla',
            titulo: 'Un. Medida',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHora',
            titulo: 'Data',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoMovimento',
            titulo: 'Tipo Movimento',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'indicadorTipoEstoque',
            titulo: 'Indicador de Tipo',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'localEstoque.descricao',
            titulo: 'Local de Estoque',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'lote.codigo',
            titulo: 'Lote',
            visivel: false,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaEstabelecimento.codigo',
            titulo: 'Cód. Pessoa',
            visivel: false,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaEstabelecimento.nomeRazaoSocial',
            titulo: 'Nome Razão Social',
            visivel: false,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'observacoes',
            titulo: 'Observações',
            visivel: false,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorUnitarioCustoContabilIr',
            titulo: 'Val. Unit. Custo Contábil IR',
            visivel: false,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalCustoContabilIr',
            titulo: 'Val. Tot. Custo Contábil IR',
            visivel: false,
            ordenar: true,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorUnitarioCustoContabilIcmsIpi',
            titulo: 'Val. Unit. Custo Contábil Icms Ipi',
            visivel: false,
            ordenar: true,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalCustoContabilIcmsIpi',
            titulo: 'Val. Tot. Custo Contábil Icms Ipi',
            visivel: false,
            ordenar: true,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorUnitarioCustoMedioeUltimaCompra',
            titulo: 'Val. Unit. Custo Médio e Ult. compra',
            visivel: false,
            ordenar: true,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalCustoMedioeUltimaCompra',
            titulo: 'Val. Total. Custo Médio e Ult. Compra',
            visivel: false,
            ordenar: true,
            ordem: 18,
            permitirEsconder: true,
        },
    ],
    suprimentosLote: [
        {
            nomeCampo: 'codigo',
            titulo: 'Lote',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataValidade',
            titulo: 'Data Validade',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    suprimentosTransferenciaLocalEstoque: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHora',
            titulo: 'Data',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'indicadorTipoEstoque',
            titulo: 'Indicador Tipo',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'localEstoqueOrigem.descricao',
            titulo: 'Local de Origem',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'localEstoqueDestino.descricao',
            titulo: 'Local de Destino',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'lote.codigo',
            titulo: 'Lote',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoa.nomeRazaoSocial',
            titulo: 'Pessoa',
            visivel: false,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'observacoes',
            titulo: 'Observação',
            visivel: false,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
    ],
    suprimentosMovimentacaoEntreProdutos: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHora',
            titulo: 'Data',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'indicadorTipoEstoque',
            titulo: 'Tipo de Estoque',
            visivel: false,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoOrigem.descricao',
            titulo: 'Produto Origem',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'localEstoqueOrigem.descricao',
            titulo: 'Local Estoque Origem',
            visivel: false,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'loteOrigem.codigo',
            titulo: 'Lote Origem',
            visivel: false,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeOrigem',
            titulo: 'Quantidade Origem',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoDestino.descricao',
            titulo: 'Produto Destino',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'localEstoqueDestino.descricao',
            titulo: 'Local Estoque Destino',
            visivel: false,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'loteDestino.codigo',
            titulo: 'Lote Destino',
            visivel: false,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeDestino',
            titulo: 'Quantidade Destino',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoa.nomeRazaoSocial',
            titulo: 'Pessoa',
            visivel: false,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'observacao',
            titulo: 'Observação',
            visivel: false,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
    ],
    suprimentosPadronizacaoDescricao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    suprimentosProduto: [
        {
            nomeCampo: 'codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoEstoque.unidadeMedida.sigla',
            titulo: 'Un. Medida',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoComercial.valorUnitarioVenda',
            titulo: 'Preço de Venda',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'grupoProdutoServico.descricao',
            titulo: 'Grupo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.spedTipoProduto.descricao',
            titulo: 'Tipo SPED',
            visivel: false,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.origemIcms.codigo',
            titulo: 'Origem',
            visivel: false,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.ncm.codigo',
            titulo: 'NCM',
            visivel: false,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.cest.codigo',
            titulo: 'CEST',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoComercial.marcaProduto.descricao',
            titulo: 'Marca',
            visivel: false,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoComercial.modeloProduto',
            titulo: 'Modelo',
            visivel: false,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
    ],
    suprimentosCodigoRastreabilidadeSisdevin: [
        {
            nomeCampo: 'codigoRastreabilidade',
            titulo: 'Código de Rastreabilidade(CR)',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    suprimentosMovimentoManualSisdevin: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'codigoRastreabilidadeSisdevin.codigoRastreabilidade',
            titulo: 'CR',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'codigoRastreabilidadeSisdevin.descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHora',
            titulo: 'Data',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoMovimento',
            titulo: 'Tipo Movimento',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoMovimentoSisdevin',
            titulo: 'Tipo Movimento Sisdevin',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'observacoes',
            titulo: 'Observações',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    suprimentosRegraEscolhaSisdevin: [
        {
            nomeCampo: 'tipoNota.descricao',
            titulo: 'Tipo de Nota',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaMotivo.descricao',
            titulo: 'Motivo',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoMovimento',
            titulo: 'Tipo Entrada/Saída ',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeCriterios',
            titulo: 'Num. Critérios',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinDestinado.destinado',
            titulo: 'Destinado à',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinOperacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinSped.spedTipoProduto.codigoDescricao',
            titulo: 'Tipos de SPED',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinTipoTransacao.tipoTransacao',
            titulo: 'Tipos de Trans. da Op. Fiscal (CFOP)',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinTipoMercado.tipoMercado',
            titulo: 'Tipo Mercado',
            visivel: true,
            ordenar: false,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinModeloDocumento.modeloDocumento.codigoDescricao',
            titulo: 'Modelo Documento',
            visivel: true,
            ordenar: false,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinSerie.serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: false,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaSisdevinResultado.operacaoCultivar',
            titulo: 'Operação Cultivar',
            visivel: true,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaSisdevinResultado.operacaoGranel',
            titulo: 'Operação Granel',
            visivel: true,
            ordenar: false,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaSisdevinResultado.operacaoEngarrafados',
            titulo: 'Operação Engarrafados',
            visivel: true,
            ordenar: false,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'regraEscolhaSisdevinResultado.movimentarSisdevin',
            titulo: 'Movimentar Sisdevin',
            visivel: true,
            ordenar: false,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataInicioVigencia',
            titulo: 'Data Vigência Inicial',
            visivel: true,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataFinalVigencia',
            titulo: 'Data Vigência Final',
            visivel: true,
            ordenar: false,
            ordem: 17,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRegraEscolhaSisdevinEmpresa.ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: false,
            ordem: 18,
            permitirEsconder: true,
        },
    ],
    suprimentosSisdevinEstoqueMovimento: [
        {
            nomeCampo: 'tipoMovimento',
            titulo: 'Tipo de Documento',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroNota',
            titulo: 'Núm. NF',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serieDocumento',
            titulo: 'Série',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataMovimento',
            titulo: 'Data Emissão',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ordemItem',
            titulo: 'Ord. Item',
            visivel: true,
            ordenar: false,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'notaFiscalItemSisdevin.notaFiscalItem.produto.codigoDescricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: false,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'notaFiscalItemSisdevin.notaFiscalItem.quantidade',
            titulo: 'Qtde.',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'codigoRastreabilidadeSisdevin.codigoRastreabilidadeDescricao',
            titulo: 'Código de Rastreabilidade (CR)',
            visivel: true,
            ordenar: false,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'litragem',
            titulo: 'Litragem',
            visivel: true,
            ordenar: false,
            ordem: 8,
            permitirEsconder: true,
        },
    ],
    suprimentosImportacaoXmlTerceiro: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serie',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'modeloDocumento.codigo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cnpjCpfVirtual',
            titulo: 'CNPJ/CPF',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'nomeRazaoSocial',
            titulo: 'Nome Razão Social',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Emissão',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotal',
            titulo: 'Total',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoAutorizacao',
            titulo: 'Situação Autorização',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoManifestacao',
            titulo: 'Situação Manifestação',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'chaveAcesso',
            titulo: 'Chave de Acesso',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
    ],
    suprimentosConhecimentoTransporteTerceiro: [
        {
            nomeCampo: 'serie',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'modeloDocumento.codigo',
            titulo: 'Modelo de Documento',
            visivel: false,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataLancamento',
            titulo: 'Data de Lançamento',
            visivel: false,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataAquisicaoPrestacaoServico',
            titulo: 'Dt. Aq/Prest. Serviço',
            visivel: false,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conhecimentoTransporteTerceiroImpostoIcms.operacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaEmitente.nomeRazaoSocial',
            titulo: 'Emitente',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaDestinatario.nomeRazaoSocial',
            titulo: 'Destinatário',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaRemetente.nomeRazaoSocial',
            titulo: 'Remetente',
            visivel: false,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaExpedidor.nomeRazaoSocial',
            titulo: 'Expedidor',
            visivel: false,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaRecebedor.nomeRazaoSocial',
            titulo: 'Recebedor',
            visivel: false,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaTomadorOutro.nomeRazaoSocial',
            titulo: 'Tomador Outro',
            visivel: false,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoTomador',
            titulo: 'Ind. Tomador',
            visivel: true,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalServico',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 15,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'retira',
            titulo: 'Recebedor retira no Aeroporto, Filial, Porto ou Estação de Destino',
            visivel: false,
            ordenar: false,
            ordem: 16,
            permitirEsconder: true,
        },
    ],
    suprimentosGrupoProdutoCodigoRastreabilidade: [
        {
            nomeCampo: 'codigoRastreabilidade',
            titulo: 'Código de Rastreabilidade(CR)',
            visivel: true,
            ordenar: false,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    suprimentosCustoConfiguracao: [
        {
            nomeCampo: 'regimeTributario',
            titulo: 'Regime Tributário',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoCusto',
            titulo: 'Tipo Custo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'modalidadeCalculo',
            titulo: 'Modalidade de Cálculo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaCustoConfiguracaoItemCreditar.tipoImposto',
            titulo: 'Configuração do Créditos',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    suprimentosConfiguracaoCREnologicoCultivar: [
        {
            nomeCampo: 'codigo',
            ordenar: true,
            tamanho: null,
            titulo: 'Código',
            visivel: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            ordenar: true,
            tamanho: null,
            titulo: 'Descrição do Produto',
            visivel: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            ordenar: false,
            tamanho: null,
            titulo: 'Tipo',
            visivel: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'crCodigoCultivar',
            ordenar: false,
            tamanho: null,
            titulo: 'CR/Código/Cultivar',
            visivel: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'crEnologicoCultivar',
            ordenar: false,
            tamanho: null,
            titulo: 'CR/Enológico/Cultivar',
            visivel: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'litragemGrauFator',
            ordenar: false,
            tamanho: null,
            titulo: 'Lit./Grau/Fat. Conv.',
            visivel: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoConfiguracaoBebidaSisdevin.tipoRecipiente',
            ordenar: true,
            tamanho: null,
            titulo: 'Recipiente',
            visivel: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    suprimentosPesagem: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            ordenar: true,
            visivel: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            ordenar: true,
            visivel: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigoDescricao',
            titulo: 'Produto',
            ordenar: true,
            visivel: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaPesagemPessoaNomeRazaoSocial',
            titulo: 'Clientes / Produtores',
            ordenar: true,
            visivel: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pesoBruto',
            titulo: 'P. Bruto',
            ordenar: true,
            visivel: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pesoTara',
            titulo: 'Tara',
            ordenar: true,
            visivel: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pesoLiquido',
            titulo: 'P. Líquido',
            ordenar: true,
            visivel: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            ordenar: true,
            visivel: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    suprimentosItemAvaliacaoPesagem: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            ordenar: true,
            visivel: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorPadrao',
            titulo: 'Valor Padrão',
            ordenar: true,
            visivel: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoAvaliacao',
            titulo: 'Tipo Avaliação',
            ordenar: true,
            visivel: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            ordenar: true,
            visivel: true,
            ordem: 3,
            permitirEsconder: true,
        },
    ],
    suprimentosAtualizacaoPrecoCustoProduto: [
        {
            nomeCampo: 'codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoEstoque.unidadeMedida.sigla',
            titulo: 'Un. Medida',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.sujeitoASt',
            titulo: 'Sujeito a ST',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.regraEscolhaTabelaPreco.descricao',
            titulo: 'Tabela de Preço',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda',
            titulo: 'Preço de Venda',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual',
            titulo: 'Aumento em %',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.aplicandoMarkup',
            titulo: 'Aplicando Markup em %',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual',
            titulo: 'Diminuindo em %',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.valorUnitarioBase',
            titulo: 'Val. Unit. Base',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoRegraEscolhaTabelaPreco.regraEscolhaTabelaPreco.origemValoresTabela',
            titulo: 'Origem Val. Unit. Base',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoCusto.valorUnitarioCustoReposicao',
            titulo: 'Val. Unit. Custo Reposição',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: false,
        },
        {
            nomeCampo: 'produtoEngenharia.codigoBarras',
            titulo: 'Código de Barras',
            visivel: true,
            ordenar: true,
            ordem: 12,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.ncm',
            titulo: 'NCM',
            visivel: true,
            ordenar: true,
            ordem: 13,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.cest',
            titulo: 'CEST',
            visivel: true,
            ordenar: true,
            ordem: 14,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 15,
            permitirEsconder: true,
        },
    ],
    suprimentosAtualizacaoEstoqueProduto: [
        {
            nomeCampo: 'codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoEstoque.unidadeMedida.sigla',
            titulo: 'Un. Medida',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoEngenharia.codigoBarras',
            titulo: 'Código de Barras',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.ncm',
            titulo: 'NCM',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoFiscal.cest',
            titulo: 'CEST',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'estoqueCombinacao.localEstoque.descricao',
            titulo: 'Local de Estoque',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'estoqueCombinacao.lote.codigo',
            titulo: 'Lote',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'estoqueCombinacao.quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
    ],
    suprimentosImpressaoEtiquetasProdutos: [
        {
            nomeCampo: 'codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'comercialtabelaPreco',
            titulo: 'Tabela de Preço',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valor',
            titulo: 'Valor',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'percentualDescontoAvista',
            titulo: 'Perc. Desc. a Vista',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
    ],
    suprimentosCotacaoCompra: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'cotacaoCompraPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoCotacaoCompraPedidoCompra',
            titulo: 'Situação Pedido',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    suprimentosPedidoCompra: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pedidoCompraPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoPedidoCompraNotaFiscalEntrada',
            titulo: 'Situação Nota',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    suprimentosNotaFiscalEntrada: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'serieCodigo',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'notaFiscalEntradaPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Fornecedor',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoNotaFiscalEntrada',
            titulo: 'Tipo de Entrada',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    // #endregion Suprimentos
    // #region Servicos
    servicosMarcaVeiculo: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    servicosEquipamento: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    servicosAcessorio: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    servicosSituacao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'permiteGerarNota',
            titulo: 'Permite Gerar Nota',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    servicosOrdemServico: [
        {
            nomeCampo: 'numero',
            titulo: 'Numero',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ordemServicoPessoa.pessoa.codigoNomeRazaoSocial',
            titulo: 'Cliente',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalProdutos',
            titulo: 'Valor Produtos',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalBruto',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataTermino',
            titulo: 'Data Término',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacaoOrdemServicoNotaFiscalSaida',
            titulo: 'Situação Nota',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numeroPedidoIntegracao',
            titulo: 'Num. Ped. Integ.',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
    ],
    // #endregion Servicos
    // #region Tranporte
    transporteVeiculo: [
        {
            nomeCampo: 'placa',
            titulo: 'Placa',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'renavam',
            titulo: 'Renavam',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tara',
            titulo: 'Tara',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'capacidadeKg',
            titulo: 'Cap. (Kg)',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'capacidadeM3',
            titulo: 'Cap. (M3)',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoVeiculo',
            titulo: 'Tipo Veículo',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoRodado',
            titulo: 'Tipo Rodado',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoCarroceria',
            titulo: 'Tipo Carroceria',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoPropriedade',
            titulo: 'Tipo Propriedade',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
    ],
    transporteManifestoTransporte: [
        {
            nomeCampo: 'serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraInicioViagem',
            titulo: 'Data Hora Início Viagem',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'placa',
            titulo: 'Placa',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pesoBrutoTotalCarga',
            titulo: 'Peso Bruto Total Carga',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalMercadoriaTransportada',
            titulo: 'Valor Total Produtos',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    transporteConhecimentoTransporte: [
        {
            nomeCampo: 'serie.codigoDescricao',
            titulo: 'Série',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraEmissao',
            titulo: 'Data Hora Emissão',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conhecimentoTransporteImpostoIcms.operacaoFiscal.cfop',
            titulo: 'CFOP',
            visivel: true,
            ordenar: false,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaDestinatario.nomeRazaoSocial',
            titulo: 'Destinatário',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaRemetente.nomeRazaoSocial',
            titulo: 'Remetente',
            visivel: false,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaExpedidor.nomeRazaoSocial',
            titulo: 'Expedidor',
            visivel: false,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaRecebedor.nomeRazaoSocial',
            titulo: 'Recebedor',
            visivel: false,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'pessoaTomadorOutro.nomeRazaoSocial',
            titulo: 'Tomador Outro',
            visivel: false,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipoTomador',
            titulo: 'Ind. Tomador',
            visivel: true,
            ordenar: true,
            ordem: 9,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'valorTotalServico',
            titulo: 'Valor Total',
            visivel: true,
            ordenar: true,
            ordem: 10,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 11,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'retira',
            titulo: 'Recebedor retira no Aeroporto, Filial, Porto ou Estação de Destino',
            visivel: false,
            ordenar: false,
            ordem: 12,
            permitirEsconder: true,
        },
    ],
    // #endregion Tranporte
    // #region Manufatura
    manufaturaRoteiroFabricacao: [
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRoteiroFabricacaoOperacao.operacaoRoteiroFabricacao.descricao',
            titulo: 'Operações',
            visivel: true,
            ordenar: false,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'roteiroFabricacaoPadrao.descricao',
            titulo: 'Roteiro de Fabricação Padrão',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    manufaturaRoteiroFabricacaoPadrao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaRoteiroFabricacaoPadraoOperacao.operacaoRoteiroFabricacao.descricao',
            titulo: 'Operações',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    manufaturaCentroTrabalho: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    manufaturaOperacaoRoteiroFabricacao: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'centroTrabalho.descricao',
            titulo: 'Centro de Trabalho',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'origem',
            titulo: 'Origem',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    manufaturaConjunto: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição do Conjunto',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaConjuntoVariavel.descricao',
            titulo: 'Descrição das Variáveis',
            visivel: true,
            ordenar: false,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
    ],
    manufaturaCaracteristica: [
        {
            nomeCampo: 'descricao',
            titulo: 'Descrição',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'mascara',
            titulo: 'Máscara',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'tipo',
            titulo: 'Tipo',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'conjunto.descricao',
            titulo: 'Conjunto',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'opcaoSim',
            titulo: 'Opção Sim',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'opcaoNao',
            titulo: 'Opção Não',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'ativo',
            titulo: 'Ativo',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
    ],
    manufaturaCaracteristicaProduto: [
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'listaCaracteristicaProdutoItem.caracteristica.descricao',
            titulo: 'Características',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
    ],
    manufaturaOrdemDesmontagem: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataLancamento',
            titulo: 'Data Lançamento',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraInicio',
            titulo: 'Data de Início',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraTermino',
            titulo: 'Data de Término',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    manufaturaOrdemProducao: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataLancamento',
            titulo: 'Data Lançamento',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidade',
            titulo: 'Quantidade',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraInicio',
            titulo: 'Data de Início',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraTermino',
            titulo: 'Data de Término',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
    ],
    manufaturaReprocessamentoProducao: [
        {
            nomeCampo: 'numero',
            titulo: 'Número',
            visivel: true,
            ordenar: true,
            ordem: 0,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataLancamento',
            titulo: 'Data Lançamento',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produto.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeRetirada',
            titulo: 'Qtde (-) Origem',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'quantidadeRetorno',
            titulo: 'Qtde (+) Destino',
            visivel: true,
            ordenar: true,
            ordem: 5,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraRetirada',
            titulo: 'Data Retirada',
            visivel: true,
            ordenar: true,
            ordem: 6,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'dataHoraRetorno',
            titulo: 'Data Retorno',
            visivel: true,
            ordenar: true,
            ordem: 7,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'situacao',
            titulo: 'Situação',
            visivel: true,
            ordenar: true,
            ordem: 8,
            permitirEsconder: true,
        },
    ],
    manufaturaEstruturaProduto: [
        {
            nomeCampo: 'produtoPai.codigo',
            titulo: 'Código',
            visivel: true,
            ordenar: true,
            ordem: 1,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoPai.descricao',
            titulo: 'Produto',
            visivel: true,
            ordenar: true,
            ordem: 2,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoPai.produtoEstoque.unidadeMedida.sigla',
            titulo: 'Un. Medida',
            visivel: true,
            ordenar: true,
            ordem: 3,
            permitirEsconder: true,
        },
        {
            nomeCampo: 'produtoPai.grupoProdutoServico.descricao',
            titulo: 'Grupo',
            visivel: true,
            ordenar: true,
            ordem: 4,
            permitirEsconder: true,
        },
    ],
    // #endregion Manufatura
};
