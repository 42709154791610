import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  IEtiquetaElementoDisponivelValoresInserir,
  IEtiquetaElementoValoresInserir,
} from '@elogestor/util';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IEtiquetaElementoContext {
  validador: boolean;

  listaEtiquetaElementoDisponivel: IEtiquetaElementoDisponivelValoresInserir[];
  setListaEtiquetaElementoDisponivel(
    lista: IEtiquetaElementoDisponivelValoresInserir[]
  ): void;

  listaEtiquetaElemento: IEtiquetaElementoValoresInserir[];
  setListaEtiquetaElemento(lista: IEtiquetaElementoValoresInserir[]): void;
}

const EtiquetaElementoContext = createContext<IEtiquetaElementoContext>(
  {} as IEtiquetaElementoContext
);

const EtiquetaElementoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaEtiquetaElementoDisponivel, setListaEtiquetaElementoDisponivel] =
    useState<IEtiquetaElementoDisponivelValoresInserir[]>([]);
  const [listaEtiquetaElemento, setListaEtiquetaElemento] = useState<
    IEtiquetaElementoValoresInserir[]
  >([]);

  const handleSetListaEtiquetaElementoDisponivel = useCallback(
    async (lista: IEtiquetaElementoDisponivelValoresInserir[]) => {
      setListaEtiquetaElementoDisponivel(lista);
    },
    []
  );

  const handleSetListaEtiquetaElemento = useCallback(
    async (lista: IEtiquetaElementoValoresInserir[]) => {
      setListaEtiquetaElemento(lista);
    },
    []
  );

  return (
    <EtiquetaElementoContext.Provider
      value={{
        listaEtiquetaElementoDisponivel,
        setListaEtiquetaElementoDisponivel:
          handleSetListaEtiquetaElementoDisponivel,

        listaEtiquetaElemento,
        setListaEtiquetaElemento: handleSetListaEtiquetaElemento,

        validador: true,
      }}
    >
      {children}
    </EtiquetaElementoContext.Provider>
  );
};

function UseEtiquetaElemento(): Omit<IEtiquetaElementoContext, 'validador'> {
  const context = useContext(EtiquetaElementoContext);

  if (!context.validador) {
    throw new Error(
      'UseEtiquetaElemento deve ser usado com um EtiquetaElementoHook'
    );
  }

  return context;
}

export { EtiquetaElementoHook, UseEtiquetaElemento };
