import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/geral/regra-escolha-integracao-pagamento-auto-complete-tag';

interface IRegraEscolhaIntegracaoPagamentoAutoCompleteTagComunicadorIndex {
  params: any;
}

class RegraEscolhaIntegracaoPagamentoAutoCompleteTagComunicador {
  public async index(
    params: IRegraEscolhaIntegracaoPagamentoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaIntegracaoPagamentoAutoCompleteTagComunicador();
