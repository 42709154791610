/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Container } from 'react-bootstrap';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Detalhe from '../../../../../../../../Componentes/Detalhe';
import ContaHook from '../../../../../../../Financeiro/Movimentacoes/Conta/Detalhe/Hooks';
import Main from '../../../../../../../Financeiro/Movimentacoes/Conta/Detalhe/Main';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import OrcamentoObterContaComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoObterContaComunicador';

interface IFinanceiroModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const FinanceiroDetalhe: React.FC<IFinanceiroModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formPrincipal = UseForm();
  const { abrirJanela } = UseConfirmacao();

  const [idContaRegistro, setIdContaRegistro] = useState<string | null>(null);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (onSalvarFormModal) onSalvarFormModal();
  }, [onSalvarFormModal]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja fechar o Financeiro sem Salvar?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) return;

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirJanela, onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      formPrincipal.setLoading(true);

      const idDetalheRegistro = formPrincipal.getIdDetalheRegistro() || '';

      const response = await OrcamentoObterContaComunicador.show({
        id: idDetalheRegistro,
      });
      setIdContaRegistro(response);

      formPrincipal.setLoading(false);
    } catch (error) {
      TratarErros(error);
      formPrincipal.setLoading(false);
    }
  }, [formPrincipal]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Adiantamento/Previsão (Receber/Pagar)"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <Modal.Body>
        <Container>
          <ContaHook>
            <Detalhe
              style={{ paddingTop: 0 }}
              onSairDoFormulario={() => {}}
              onDescartarFormulario={() => {}}
              onRecuperarFormulario={() => {}}
            >
              <Main
                idEditar={idContaRegistro}
                dadosRecuperados={null}
                dadosDuplicados={null}
                onClickSalvar={handleClickSalvar}
                onClickVoltar={handleClickVoltar}
                bloquearCampos
                isAdiantamentoAntecipacao
                isJanela
              />
            </Detalhe>
          </ContaHook>
        </Container>
      </Modal.Body>
    </JanelaDetalhe>
  );
};

export default FinanceiroDetalhe;
