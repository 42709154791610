import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/estrutura-produto/log';

interface IEstruturaProdutoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IEstruturaProdutoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class EstruturaProdutoLogComunicador {
  public async index(
    params: IEstruturaProdutoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IEstruturaProdutoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new EstruturaProdutoLogComunicador();
