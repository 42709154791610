"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CompararStringsIguaisIgnoraAcentuacaoMinusculas;
function CompararStringsIguaisIgnoraAcentuacaoMinusculas(texto1, texto2) {
    if (!texto1 && !texto2) {
        return true;
    }
    if (texto1 && !texto2) {
        return false;
    }
    if (!texto1 && texto2) {
        return false;
    }
    return typeof texto1 === 'string' && typeof texto2 === 'string'
        ? texto1.toUpperCase().RemoverCaracteresEspeciais() ===
            texto2.toUpperCase().RemoverCaracteresEspeciais()
        : texto1 === texto2;
}
