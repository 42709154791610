import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import InputDinheiro from '../../../../../../Componentes/Inputs/InputDecimal';

const TrabalhoConjugeTab: React.FC = () => {
  return (
    <Divisor>
      <Row>
        <Col lg={8} md={12}>
          <Input
            label="Local de Trabalho"
            name="pessoaFisica.localTrabalho"
            placeholder="Local de Trabalho"
            maxLength={60}
          />
        </Col>
        <Col lg={2} md={12}>
          <InputDateSemTimeZone
            label="Data Admissão"
            name="pessoaFisica.dataAdmissao"
            placeholder="Data Admissão"
          />
        </Col>
        <Col lg={2} md={12}>
          <InputDinheiro
            label="Salário"
            name="pessoaFisica.salario"
            placeholder="Salário"
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default TrabalhoConjugeTab;
