import ILogPesquisaParametro from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/veiculo/log';

interface IVeiculoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IVeiculoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class VeiculoLogComunicador {
  public async index(
    params: IVeiculoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IVeiculoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new VeiculoLogComunicador();
