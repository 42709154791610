import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import {
  FinalidadeEmissaoEnum,
  IOrdemServicoItemEstoqueLocalEstoqueValoresInserir,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimal, {
  IInputDecimalRef,
} from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputDateTime from '../../../../../../../../Componentes/Inputs/InputDateTime';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../../../Componentes/Divisor';
import ItemInformacaoAdicionalTab from './ItemInformacaoAdicionalTab/index';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseItemTab } from '../Hook/ItemTabHook';
import ItemOutrosValoresTab from './ItemOutrosValoresTab';
import ItemImpostoTab from './ItemImpostoTab';
import ItemEstoqueTab from './ItemEstoqueTab';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseItemOrdemServico } from '../Hook/ItemOrdemServicoHook';
import { UseItemEstoque } from '../Hook/ItemEstoqueHook';
import ItemComercialTab from './ItemComercialTab';
import InputCheckbox from '../../../../../../../../Componentes/Inputs/InputCheckbox';
import ObterOrigemIcmsComunicador from '../../../../../../../../Comunicador/Fiscal/Icms/Origem/ObterOrigemIcmsComunicador/ObterOrigemIcmsComunicador';
import ItemEngenhariaTab from './ItemEngenhariaTab';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import { UseLiberacoes } from '../../../../../../../../Hooks/LiberacoesHook';
import { UseSituacaoBloquearCampos } from '../../../Hook/SituacaoBloquearCamposHook';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import InputAutoCompleteProdutoServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';

interface IOrdemServicoItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const OrdemServicoItemDetalhe: React.FC<IOrdemServicoItemModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
  onLimparFormModal,
}) => {
  const parametros = UseParametros();
  const { permissoes } = UsePermissoes();
  const { ServicoOrdensServicosOrdemServico: permissao } = permissoes;
  const liberacoes = UseLiberacoes();
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    refresh,
    inputRefFocus,
    handleSetarFocus,
  } = UseListaDetalheForm();

  const { formRef: formPrincipal } = UseForm();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const {
    calculaValorTotalProduto,
    calcularRateioItem,
    setItemOrdem,
    obterConfiguracaoTributariaConformeProduto,
    calcularQuantidadeTributadoProdutoTributado,
    calcularValorUnitarioTributadoProdutoTributado,
    obterRegraEscolhaAliquota,
    calcularBaseCalculoItemComercialRepresentante,
    obterRegraEscolhaTabelaPreco,
    calcularItemEngenhariaPesagem,
    calcularItemEngenhariaMetragem,
    limparConfiguracaoTributaria,
  } = UseItemTab();

  const { telaPequena } = UseReactSizeMeBodyHook();

  const { setProdutoControlaLote, listaLocalEstoque, setListaLocalEstoque } =
    UseItemOrdemServico();
  const { selecionarItemEstoqueLocalEstoque } = UseItemEstoque();

  const idDetalheRegistro = getIdDetalheRegistro();

  const subtotalRef = useRef<IInputDecimalRef>(null);

  const [tabSelecionada, setTabSelecionada] = useState('impostos');
  const [buttonSelecionado, setButtonSelecionado] = useState('');
  const [comercializado, setComercializado] = useState(
    formPrincipal.current?.getFieldValue('finalidadeEmissao') ===
      FinalidadeEmissaoEnum.normal
  );

  const handleObterValoresProdutoEngenharia = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    const produtoEngenharia = produto?.produtoEngenharia;

    const pesoBruto = produtoEngenharia?.pesoBruto;
    const pesoLiquido = produtoEngenharia?.pesoLiquido;
    const quantidadeVolumes =
      produtoEngenharia?.quantidadeVolumes === null &&
      parametros.ConsiderarQuantidadeVolumesUnitarioComo1
        ? 1
        : produtoEngenharia?.quantidadeVolumes;
    const metroQuadrado = produtoEngenharia?.metroQuadrado;
    const metroCubico = produtoEngenharia?.metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.pesoBrutoUnitario',
      pesoBruto
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.pesoLiquidoUnitario',
      pesoLiquido
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.quantidadeVolumesUnitario',
      quantidadeVolumes
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.metroQuadradoUnitario',
      metroQuadrado
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.metroCubicoUnitario',
      metroCubico
    );
  }, [formRefDetalhe, parametros.ConsiderarQuantidadeVolumesUnitarioComo1]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.ordemServicoItemEstoque.listaOrdemServicoItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: IOrdemServicoItemEstoqueLocalEstoqueValoresInserir = {
          id: itemLocalEstoque.id,
          idLocalEstoque: itemLocalEstoque.localEstoque.id,
          idLote: itemLocalEstoque.lote?.id,
          quantidade: Number(itemLocalEstoque.quantidade),
          tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
          indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
        };

        return retorno;
      });

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
    setButtonSelecionado('');
  }, [formRefDetalhe, handleSubmit, listaLocalEstoque, onSalvarFormModal]);

  const handleClickSalvarENovo = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.ordemServicoItemEstoque.listaOrdemServicoItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: IOrdemServicoItemEstoqueLocalEstoqueValoresInserir = {
          id: itemLocalEstoque.id,
          idLocalEstoque: itemLocalEstoque.localEstoque.id,
          idLote: itemLocalEstoque.lote?.id,
          quantidade: Number(itemLocalEstoque.quantidade),
          tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
          indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
        };

        return retorno;
      });

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await formRefDetalhe.current?.reset();
    if (onLimparFormModal) onLimparFormModal();

    setButtonSelecionado('');
    handleCarregarDados();

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleCarregarDados,
    handleSetarFocus,
    handleSubmit,
    listaLocalEstoque,
    onLimparFormModal,
  ]);

  const handleClickSalvarEContinuar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.ordemServicoItemEstoque.listaOrdemServicoItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: IOrdemServicoItemEstoqueLocalEstoqueValoresInserir = {
          id: itemLocalEstoque.id,
          idLocalEstoque: itemLocalEstoque.localEstoque.id,
          idLote: itemLocalEstoque.lote?.id,
          quantidade: Number(itemLocalEstoque.quantidade),
          tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
          indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
        };

        return retorno;
      });

    const { erro, id } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    setIdDetalheRegistro(id);
    setButtonSelecionado('');

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleSetarFocus,
    handleSubmit,
    listaLocalEstoque,
    setIdDetalheRegistro,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados, idDetalheRegistro]);

  useEffect(() => {
    if (!idDetalheRegistro) setItemOrdem();
  }, [idDetalheRegistro, setItemOrdem]);

  useEffect(() => {
    if (formPrincipal.current) {
      const finalidadeEmissao =
        formPrincipal.current.getFieldValue('finalidadeEmissao');
      if (
        finalidadeEmissao === FinalidadeEmissaoEnum.ajuste ||
        finalidadeEmissao === FinalidadeEmissaoEnum.complemento
      ) {
        const inputRef =
          formRefDetalhe.current?.getFieldRef('valorTotalProduto');
        inputRef.disabled = false;
      }
    }
  }, [formPrincipal, formRefDetalhe]);

  const handleCalcularValorTotalProdutos = useCallback(() => {
    calculaValorTotalProduto();
  }, [calculaValorTotalProduto]);

  const handleObterTabelaPreco = useCallback(async () => {
    obterRegraEscolhaTabelaPreco();
  }, [obterRegraEscolhaTabelaPreco]);

  const handleCalcularRateioItem = useCallback(() => {
    calcularRateioItem();
  }, [calcularRateioItem]);

  const handleObterConfiguracaoTributariaConformeProduto = useCallback(() => {
    obterConfiguracaoTributariaConformeProduto();
  }, [obterConfiguracaoTributariaConformeProduto]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleCalcularQuantidadeTributadoProdutoTributado = useCallback(() => {
    calcularQuantidadeTributadoProdutoTributado();
  }, [calcularQuantidadeTributadoProdutoTributado]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(() => {
      calcularValorUnitarioTributadoProdutoTributado();
    }, [calcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota = useCallback(() => {
    obterRegraEscolhaAliquota();
  }, [obterRegraEscolhaAliquota]);

  const handleCalcularQuantidadeEstoque =
    useCallback(async (): Promise<void> => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const fatorConversao = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.fatorConversao'
      );
      const quantidadeEstoque = (
        Number(quantidade) * Number(fatorConversao)
      ).Arredondar(4);

      formRefDetalhe.current?.setFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque',
        quantidadeEstoque
      );
    }, [formRefDetalhe]);

  const handleSetControleEstoque = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    if (!produto) {
      setProdutoControlaLote(false);
    } else {
      formRefDetalhe.current?.setFieldValue(
        'ordemServicoItemEstoque.fatorConversao',
        1
      );
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      formRefDetalhe.current?.setFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque',
        Number(quantidade)
      );

      setProdutoControlaLote(produto.produtoEstoque.controlarLote);
    }
  }, [formRefDetalhe, setProdutoControlaLote]);

  const handleSelecionarItemEstoqueLocalEstoque = useCallback(
    async (limparLista?: boolean) => {
      selecionarItemEstoqueLocalEstoque(limparLista);
    },
    [selecionarItemEstoqueLocalEstoque]
  );

  useEffect(() => {
    const movimentarEstoque = Boolean(
      formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.movimentarEstoque'
      )
    );
    if (buttonSelecionado && buttonSelecionado === 'salvar') {
      if (
        movimentarEstoque &&
        listaLocalEstoque &&
        listaLocalEstoque.length > 0
      ) {
        handleClickSalvar();
      } else if (!movimentarEstoque) {
        handleClickSalvar();
      }
    } else if (buttonSelecionado && buttonSelecionado === 'salvarENovo') {
      if (
        movimentarEstoque &&
        listaLocalEstoque &&
        listaLocalEstoque.length > 0
      ) {
        handleClickSalvarENovo();
      } else if (!movimentarEstoque) {
        handleClickSalvarENovo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaLocalEstoque, buttonSelecionado]);

  const handleObterOrigemIcms = useCallback(async () => {
    try {
      const origemIcms = await ObterOrigemIcmsComunicador.index({
        params: {
          codigo: 0,
        },
      });

      if (origemIcms) {
        formRefDetalhe.current?.setFieldValue(
          'ordemServicoItemImpostoIcms.origemIcms',
          origemIcms
        );
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe]);

  const handleCalcularItemEngenhariaPesagem = useCallback(() => {
    calcularItemEngenhariaPesagem();
  }, [calcularItemEngenhariaPesagem]);

  const handleCalcularItemEngenhariaMetragem = useCallback(() => {
    calcularItemEngenhariaMetragem();
  }, [calcularItemEngenhariaMetragem]);

  return (
    <JanelaDetalhe
      titulo="Item"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <JanelaNavegacao
                containerStyle={
                  telaPequena
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                }
              />
            </Col>
          </Row>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12} className="div-checkbox">
                  <div className="checkbox-container">
                    <InputCheckbox
                      label="Filtrar Somente itens Disponíveis para Venda"
                      name="filtrarProdutosDisponiveisVenda"
                      onChange={(ev) => {
                        setComercializado(ev.target.checked);
                        refresh();
                      }}
                      onChangeValue={(ev) => {
                        setComercializado(ev.valorAtual);
                        refresh();
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12}>
                  <InputAutoCompleteProdutoServico
                    ref={inputRefFocus}
                    label="Produto/Serviço"
                    name="idProduto"
                    placeholder="Produto/Serviço"
                    nomeObjeto="produto"
                    onChangeItemAtual={() => {
                      setListaLocalEstoque([]);
                    }}
                    onChangeItemAtualAposCarregarSemClear={(ev) => {
                      handleLimparConfiguracaoTributaria();
                      handleObterConfiguracaoTributariaConformeProduto();
                      handleSetControleEstoque();
                      handleSelecionarItemEstoqueLocalEstoque(true);
                      handleObterTabelaPreco();
                      handleObterValoresProdutoEngenharia();

                      if (
                        ev.itemAtual &&
                        ev.itemAtual.tipoProdutoServico ===
                          TipoProdutoServicoEnum.servico
                      )
                        handleObterOrigemIcms();
                    }}
                    comercializado={comercializado}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <InputAutoCompleteUnidadeMedida
                    label="Unidade de Medida"
                    name="idUnidadeMedida"
                    placeholder="Unidade de Medida"
                    nomeObjeto="unidadeMedida"
                    permitirAdicionar={false}
                  />
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <InputInteiro
                    label="Ordem"
                    name="ordem"
                    placeholder="Ordem"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Quantidade"
                    name="quantidade"
                    casasInteiras={11}
                    casasDecimais={4}
                    disabled={situacaoBloquearCampos}
                    onChange={async () => {
                      await handleCalcularQuantidadeEstoque();
                      handleCalcularItemEngenhariaPesagem();
                      handleCalcularItemEngenhariaMetragem();
                    }}
                    onBlurCia={async (event: any, props) => {
                      if (event?.relatedTarget) {
                        setButtonSelecionado(event.relatedTarget.id);
                        if (
                          event.relatedTarget.id === 'voltar' ||
                          event.relatedTarget.id === 'btnClose'
                        ) {
                          onFecharFormModal();
                          return;
                        }
                      }
                      const valorUnitario =
                        formRefDetalhe.current?.getFieldValue('valorUnitario');

                      handleCalcularValorTotalProdutos();
                      handleCalcularQuantidadeTributadoProdutoTributado();
                      await handleSelecionarItemEstoqueLocalEstoque();

                      if (
                        props.valorAnteriorOnBlur.ConverterParaNumber() === 0 &&
                        event.currentTarget?.value.ConverterParaNumber() !==
                          0 &&
                        valorUnitario > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Unitário"
                    name="valorUnitario"
                    disabled={
                      !liberacoes.permiteAlterarValorUnitarioVendaTabelaPrecoInformada ||
                      situacaoBloquearCampos
                    }
                    casasInteiras={11}
                    casasDecimais={10}
                    onChangeValue={(event, props) => {
                      const quantidade =
                        formRefDetalhe.current?.getFieldValue('quantidade');

                      if (
                        props.valorAnteriorOnChange.ConverterParaNumber() ===
                          0 &&
                        event.valorFormatado.ConverterParaNumber() !== 0 &&
                        quantidade > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }

                      handleCalcularValorTotalProdutos();
                      handleCalcularValorUnitarioTributadoProdutoTributado();

                      for (let i = 0; i < listaLocalEstoque.length; i++) {
                        listaLocalEstoque[i].id = '';
                      }
                    }}
                    onBlurCia={async (event: any, props) => {
                      if (
                        event.relatedTarget &&
                        props.valorAnteriorOnBlur.ConverterParaNumber() === 0
                      ) {
                        setButtonSelecionado(event.relatedTarget.id);
                        if (
                          event.relatedTarget.id === 'voltar' ||
                          event.relatedTarget.id === 'btnClose'
                        ) {
                          onFecharFormModal();
                          return;
                        }
                      }
                      const quantidade =
                        formRefDetalhe.current?.getFieldValue('quantidade');

                      if (
                        props?.valorAnteriorOnBlur?.ConverterParaNumber() ===
                          0 &&
                        event?.target?.value?.ConverterParaNumber() !== 0 &&
                        quantidade > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }

                      handleCalcularValorTotalProdutos();
                      handleCalcularValorUnitarioTributadoProdutoTributado();

                      if (
                        event.target &&
                        event.target.value !== props.valorAnteriorOnBlur
                      ) {
                        formRefDetalhe.current?.setFieldValue(
                          'ordemServicoItemComercial.utilizarValorTabelaPreco',
                          false
                        );
                      }
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Subtotal (Qtde. * Val. Unit.)"
                    name="valorTotalProduto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                    ref={subtotalRef}
                    onChangeValue={(event, { valorAnteriorOnChange }) => {
                      if (
                        valorAnteriorOnChange.ConverterParaNumber() === 0 &&
                        event.valor > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }
                      handleCalcularRateioItem();
                      subtotalRef.current && subtotalRef.current.focus();
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Total Ordem de Serviço"
                    name="valorTotalBruto"
                    casasInteiras={13}
                    casasDecimais={2}
                    onChangeValue={() => {
                      calcularBaseCalculoItemComercialRepresentante();
                    }}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputDateTime label="Data Término" name="dataTermino" />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Input
                    label="Num. do Pedido de Compra"
                    name="pedidoCompraNumero"
                    placeholder="Num. do Pedido de Compra"
                    maxLength={15}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputInteiroNulavel
                    label="Item do Pedido de Compra"
                    name="pedidoCompraNumeroItem"
                    placeholder="Item do Pedido de Compra"
                  />
                </Col>
              </Row>
            </Divisor>

            <Tabs
              id={v4()}
              defaultActiveKey="impostos"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="impostos" title="Impostos">
                <ItemImpostoTab />
              </Tab>

              <Tab eventKey="outrosValores" title="Outros Valores">
                <ItemOutrosValoresTab />
              </Tab>

              <Tab eventKey="estoques" title="Estoques">
                <ItemEstoqueTab formRefItem={formRefDetalhe} />
              </Tab>

              <Tab eventKey="informacoesAdicionais" title="Inf. Adicionais">
                <ItemInformacaoAdicionalTab />
              </Tab>

              <Tab eventKey="comercial" title="Comercial">
                <ItemComercialTab />
              </Tab>

              <Tab eventKey="engenharia" title="Engenharia">
                <ItemEngenhariaTab />
              </Tab>
            </Tabs>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer style={telaPequena ? { display: 'block' } : {}}>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              id="voltar"
              disabled={loading}
              style={
                telaPequena
                  ? {
                      width: '100%',
                      marginBottom: 5,
                    }
                  : {}
              }
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              id="salvarENovo"
              onClick={handleClickSalvarENovo}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAll />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar e Novo' : 'Adicionar e Novo'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarEContinuar}
              id="salvarEContinuar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvar}
              id="salvar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default OrdemServicoItemDetalhe;
