import {
  IReprocessamentoProducaoItemValoresInserir,
  IReprocessamentoProducaoLocalEstoqueOrigemValoresInserir,
  IReprocessamentoProducaoLocalEstoqueDestinoValoresInserir,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ValidarEstoqueMovimentoProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/ReprocessamentoProducao/ValidarEstoqueMovimentoProducaoComunicador/ValidarEstoqueMovimentoProducaoComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IProps {
  listaReprocessamentoProducaoItem: IReprocessamentoProducaoItemValoresInserir[];
  listaReprocessamentoProducaoLocalEstoqueOrigem: IReprocessamentoProducaoLocalEstoqueOrigemValoresInserir[];
  quantidadeRetirada: number;
  listaReprocessamentoProducaoLocalEstoqueDestino: IReprocessamentoProducaoLocalEstoqueDestinoValoresInserir[];
  quantidadeRetorno: number;
  dataHoraRetorno: any;
  dataHoraRetirada: any;
}

interface IReprocessamentoProducaoContext {
  validador: boolean;

  listaLocalEstoqueRemover: string[];
  setListaLocalEstoqueRemover(lista: string[]): string[];
  listaIdEstoqueMovimentoRemover: string[];
  setListaIdEstoqueMovimentoRemover(lista: string[]): void;
  handleValidarExclusao(valor: string): Promise<boolean>;
  handleValidarExclusaoLista(lista: string[]): Promise<boolean>;
  handleAlterarSituacao({
    listaReprocessamentoProducaoItem,
    listaReprocessamentoProducaoLocalEstoqueOrigem,
    listaReprocessamentoProducaoLocalEstoqueDestino,
    quantidadeRetirada,
    quantidadeRetorno,
    dataHoraRetorno,
    dataHoraRetirada,
  }: IProps): boolean;
}

const ReprocessamentoProducaoContext =
  createContext<IReprocessamentoProducaoContext>(
    {} as IReprocessamentoProducaoContext
  );

const ReprocessamentoProducaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaLocalEstoqueRemover, setListaLocalEstoqueRemover] = useState<
    string[]
  >([]);

  const [listaIdEstoqueMovimentoRemover, setListaIdEstoqueMovimentoRemover] =
    useState<string[]>([]);

  const handleSetListaLocalEstoqueRemover = useCallback(
    (lista: string[]): string[] => {
      const listaValor = [...lista];
      setListaLocalEstoqueRemover(lista);
      return listaValor;
    },
    []
  );

  const handleValidarExclusaoLista = useCallback(
    async (lista: string[]): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaIdEstoqueMovimentoRemover, ...lista];
        const response = await ValidarEstoqueMovimentoProducaoComunicador.store(
          {
            params: lista,
          }
        );

        if (response) {
          setListaIdEstoqueMovimentoRemover(listaIdRemover);
          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaIdEstoqueMovimentoRemover]
  );

  const handleValidarExclusao = useCallback(
    async (valor: string): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaIdEstoqueMovimentoRemover, valor];
        const response = await ValidarEstoqueMovimentoProducaoComunicador.store(
          {
            params: listaIdRemover,
          }
        );

        if (response) {
          listaIdRemover.forEach((it) => {
            setListaIdEstoqueMovimentoRemover((prevState) => {
              return [...prevState, it];
            });
          });

          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaIdEstoqueMovimentoRemover]
  );

  const handleAlterarSituacao = useCallback(
    ({
      listaReprocessamentoProducaoItem,
      listaReprocessamentoProducaoLocalEstoqueOrigem, // Retirada
      listaReprocessamentoProducaoLocalEstoqueDestino, // Retorno
      quantidadeRetirada,
      quantidadeRetorno,
      dataHoraRetorno,
      dataHoraRetirada,
    }: IProps): boolean => {
      if (
        listaReprocessamentoProducaoItem.length === 0 ||
        // listaReprocessamentoProducaoLocalEstoqueOrigem.length === 0 ||
        listaReprocessamentoProducaoLocalEstoqueDestino.length === 0 ||
        // !quantidadeRetirada ||
        !quantidadeRetorno ||
        !dataHoraRetorno
        // || !dataHoraRetirada
      ) {
        return false;
      }

      let totaisMarcados = 0;
      listaReprocessamentoProducaoItem.forEach((valor: any) => {
        if (valor.consumido) {
          totaisMarcados++;
        }
      });

      let somaQuantidadeLocalEstoqueOrigem = 0;

      if (
        listaReprocessamentoProducaoLocalEstoqueOrigem.length !== 0 &&
        quantidadeRetorno !== 0
      ) {
        somaQuantidadeLocalEstoqueOrigem =
          listaReprocessamentoProducaoLocalEstoqueOrigem.reduce(
            (acumulador: number, item) => {
              acumulador += Number(item.quantidade) || 0;
              return acumulador;
            },
            0
          );
      }

      const somaQuantidadeLocalEstoqueDestino =
        listaReprocessamentoProducaoLocalEstoqueDestino.reduce(
          (acumulador: number, item) => {
            acumulador += Number(item.quantidade) || 0;
            return acumulador;
          },
          0
        );

      if (quantidadeRetirada !== 0 && !dataHoraRetirada) {
        return false;
      }

      if (
        totaisMarcados === listaReprocessamentoProducaoItem.length &&
        quantidadeRetirada === somaQuantidadeLocalEstoqueOrigem &&
        quantidadeRetorno === somaQuantidadeLocalEstoqueDestino &&
        dataHoraRetorno
      ) {
        return true;
      }
      return false;
    },
    []
  );

  return (
    <ReprocessamentoProducaoContext.Provider
      value={{
        listaLocalEstoqueRemover,
        setListaLocalEstoqueRemover: handleSetListaLocalEstoqueRemover,
        listaIdEstoqueMovimentoRemover,
        setListaIdEstoqueMovimentoRemover,
        handleValidarExclusao,
        handleValidarExclusaoLista,
        handleAlterarSituacao,
        validador: true,
      }}
    >
      {children}
    </ReprocessamentoProducaoContext.Provider>
  );
};

function UseReprocessamentoProducao(): Omit<
  IReprocessamentoProducaoContext,
  'validador'
> {
  const context = useContext(ReprocessamentoProducaoContext);

  if (!context.validador) {
    throw new Error(
      'UseReprocessamentoProducao deve ser usado com um ReprocessamentoProducaoHook'
    );
  }

  return context;
}

export { ReprocessamentoProducaoHook, UseReprocessamentoProducao };
