import React from 'react';
import { RotasProvider } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '/comercial/pessoas/finalidade-uso-cliente',
        detalhes: '/comercial/pessoas/finalidade-uso-cliente/detalhe',
        listagem: '/comercial/pessoas/finalidade-uso-cliente',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
