import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  ICurvaABCPedidoVendaClientePorQuantidadeRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface ICurvaABCPedidoVendaClientePorQuantidadePDF {
  dados: ICurvaABCPedidoVendaClientePorQuantidadeRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const CurvaABCPedidoVendaClientePorQuantidadePDF: React.FC<
  ICurvaABCPedidoVendaClientePorQuantidadePDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '4%', alignItems: 'flex-start' }}>
              <Text>Seq.</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '54%' }}>
              <Text>Nome do Cliente</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '16%', alignItems: 'flex-end' }}>
              <Text>Quantidade</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '7%' }}>
              <Text>Perc.(%)</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '11%', alignItems: 'flex-end' }}>
              <Text>Perc.(%)Acum</Text>
            </View>
          </View>
          {dados.listaDados.map((dado, index) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-start' }}>
                  <Text>{dado.sequencia}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '54%' }}>
                  <Text>{dado.nomeCliente}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '16%', alignItems: 'flex-end' }}>
                  <Text>{dado.quantidade}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dado.percentual}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>{dado.percentualAcumulado}</Text>
                </View>
              </View>
            );
          })}
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '15%' }}>
              <Text
                style={{ fontWeight: 'bold', marginBottom: 5, marginLeft: 2 }}
              >
                Total Geral: {dados.clientesTotal}
              </Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '61%', alignItems: 'flex-end' }}>
              <Text>{dados.quantidadeTotal}</Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CurvaABCPedidoVendaClientePorQuantidadePDF;
