import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import { LoadingContainer } from './styles';

interface IJanelaLoading extends IPadraoProps {
  isLoading: boolean;
  tamanhoFonte?: number;
  mensagem?: string;
}

const JanelaLoading: React.FC<IJanelaLoading> = ({
  isLoading = true,
  children,
  tamanhoFonte = 20,
  mensagem = 'Por favor, aguarde, estamos procurando por possíveis resultados!',
}) => {
  if (isLoading) {
    return (
      <Row>
        <Col>
          <LoadingContainer style={{ display: 'flex' }}>
            <Spinner
              className="spinner"
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span
              style={{
                fontSize: tamanhoFonte,
                marginLeft: 10,
                alignSelf: 'center',
                color: '#3397c4',
              }}
            >
              {mensagem}
            </span>
          </LoadingContainer>
        </Col>
      </Row>
    );
  }
  return <>{children}</>;
};

export default JanelaLoading;
