import {
  CalcularValorTotalParcela,
  DiferencaDiasEntreDatas,
  JurosCalculoPorDentro,
  JurosSimples,
  RateioComCasaDecimalCalculo,
  TipoLancamentoEnum,
} from '@elogestor/util';
import React, { useCallback, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteContaTipoAdiantamentoEnum from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteContaTipoAdiantamentoEnum';
import InputAutoCompleteFormaPagamento from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import InputAutoCompletePortador from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import InputDecimal from '../../../../../../../../../../../Componentes/Inputs/InputDecimal';
import InputHiddenHtml from '../../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador from '../../../../../../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaIntegracaoPagamento/Comunicador/RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador';
import { UseListaDetalheForm } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputDate from '../../../../../../../../../../../Componentes/Inputs/InputDate';
import { UseParametros } from '../../../../../../../../../../../Hooks/ParametrosHook';

interface IProps {
  indexParcela: number;
  bloquearCamposReversao?: boolean;
}

const GeralTab: React.FC<IProps> = ({
  indexParcela,
  bloquearCamposReversao = false,
}) => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, formRefLista, refresh, inputRefFocus } =
    UseListaDetalheForm();
  const dataVencimento = formRefLista.current?.getFieldValue('dataVencimento');
  const idPessoa = formPrincipal.formRef.current?.getFieldValue('idPessoa');
  const valorLiquidacao = formRefDetalhe.current?.getFieldValue('valor');
  let formaCalculoJurosManual = formRefDetalhe.current?.getFieldValue(
    'formaCalculoJurosManual'
  );
  const idDetalheRegistro = formPrincipal.getIdDetalheRegistro();

  const parametros = UseParametros();

  const listaIdParcelaRemoverContaAdiantamento = useMemo(() => {
    if (idDetalheRegistro) {
      return [idDetalheRegistro];
    }

    return [];
  }, [idDetalheRegistro]);

  const listaAdiantamentoUtilizado = useMemo(() => {
    const listaContaParcelas =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

    const listaRetorno: any[] = [];
    for (let i = 0; i < listaContaParcelas.length; i++) {
      const contaParcela = listaContaParcelas[i];

      const listaLiquidacaoAdiantamento =
        contaParcela.listaContaParcelaLiquidacao.filter(
          (e: any) => e.idContaAdiantamento && !e.id
        );

      if (
        listaLiquidacaoAdiantamento &&
        listaLiquidacaoAdiantamento.length > 0
      ) {
        for (let j = 0; j < listaLiquidacaoAdiantamento.length; j++) {
          const liquidacaoAdiantamento = listaLiquidacaoAdiantamento[j];
          listaRetorno.push({
            idContaAdiantamento: liquidacaoAdiantamento.idContaAdiantamento,
            valor: liquidacaoAdiantamento.valor,
          });
        }
      }
    }

    return listaRetorno;
  }, [formPrincipal.formRef]);

  const handleObterRegraEscolhaIntegradorPagamento = useCallback(
    async (idFormaPagamento: string) => {
      const quantidadeParcelas =
        formPrincipal.formRef.current?.getFieldValue('quantidadeParcelas');
      const dataHoraEmissao =
        formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

      const idBandeiraCartao =
        formRefLista.current?.getFieldValue('idBandeiraCartao');

      const response =
        await RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador.show(
          {
            params: {
              idBandeiraCartao,
              idFormaPagamento,
              quantidadeParcelas,
              dataPesquisaVigencia: new Date(dataHoraEmissao),
            },
          }
        );

      if (response && response.regraEscolhaIntegracaoPagamento) {
        const regraEscolhaIntegracaoPagamento = {
          id: response.regraEscolhaIntegracaoPagamento.id,
          regraEscolhaIntegracaoPagamentoResultado: {
            id: response.regraEscolhaIntegracaoPagamento.id,
            cnpjInstituicaoPagamento:
              response.regraEscolhaIntegracaoPagamento.cnpjInstituicaoPagamento,
          },
        };

        return regraEscolhaIntegracaoPagamento;
      }

      return null;
    },
    [formPrincipal.formRef, formRefLista]
  );

  const handleCalcularPercentualLiquidado = useCallback(async () => {
    const valorTotalParcela =
      formRefLista.current?.getFieldValue('valorTotalParcela');

    const percentualLiquidado = valorTotalParcela
      ? Number(valorLiquidacao / valorTotalParcela) * 100
      : 0;

    formRefDetalhe.current?.setFieldValue(
      'percentualLiquidado',
      percentualLiquidado
    );
  }, [formRefDetalhe, formRefLista, valorLiquidacao]);

  const handleCalcularValorLiquidacao = useCallback(async () => {
    const {
      valorGeral,
      valorJurosSoma,
      valorDescontoSubtrai,
      valorMultaSoma,
      valorOutrasDespesasSoma,
      valorOutrasDespesasSubtrai,
      valorDespesasCartaoSubtrai,
      valorDespesasCartorioSoma,
      valorDespesasEnvioSoma,
      valorComissoesSoma,
      valorDespesasEnvioSubtrai,
      valorComissoesSubtrai,
    } = formRefDetalhe.current?.getData() as any;

    const valorTotalLiquidacao = CalcularValorTotalParcela({
      valorParcela: Number(valorGeral),
      valorJurosSoma: Number(valorJurosSoma),
      valorDescontoSubtrai: Number(valorDescontoSubtrai),
      valorMultaSoma: Number(valorMultaSoma),
      valorOutrasDespesasSoma: Number(valorOutrasDespesasSoma),
      valorOutrasDespesasSubtrai: Number(valorOutrasDespesasSubtrai),
      valorDespesasCartaoSubtrai: Number(valorDespesasCartaoSubtrai),
      valorDespesasCartorioSoma: Number(valorDespesasCartorioSoma),
      valorDespesasEnvioSoma: Number(valorDespesasEnvioSoma),
      valorComissoesSoma: Number(valorComissoesSoma),
      valorDespesasEnvioSubtrai: Number(valorDespesasEnvioSubtrai),
      valorComissoesSubtrai: Number(valorComissoesSubtrai),
    });
    formRefDetalhe.current?.setFieldValue('valor', valorTotalLiquidacao);
  }, [formRefDetalhe]);

  const handleCalcularValorCategoriaLiquidacao = useCallback(
    async (valor: number, tipoLancamento: TipoLancamentoEnum) => {
      const listaMovimentoPortadorCategoria =
        formRefDetalhe.current?.getFieldValue(
          'listaMovimentoPortadorCategoria'
        );
      const listaContaParcela =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      const { listaContaParcelaCategoria } = listaContaParcela[indexParcela];

      const listaValor: number[] = [];
      const novaLista: any[] = [];
      if (listaContaParcelaCategoria && listaContaParcelaCategoria.length > 0) {
        const somaValores = listaContaParcelaCategoria
          .reduce((acc: any, item: any) => {
            if (item.tipoLancamento === tipoLancamento) {
              acc += Number(item.valor);
            }
            return acc;
          }, 0)
          .Arredondar();

        for (let i = 0; i < listaContaParcelaCategoria.length; i++) {
          novaLista.push({ ...listaContaParcelaCategoria[i] });
        }

        for (let i = 0; i < novaLista.length; i++) {
          const item = novaLista[i];
          const index = listaMovimentoPortadorCategoria.findIndex(
            (e: any) =>
              e.idCategoria === item.idCategoria &&
              e.tipoLancamento === tipoLancamento
          );

          if (item.tipoLancamento === tipoLancamento && index >= 0) {
            const valorCategoria = Number(item.valor);
            const resultado = (
              (Number(valor) / somaValores) *
              valorCategoria
            ).Arredondar();

            listaMovimentoPortadorCategoria[index].valor = resultado;

            listaValor.push(resultado);
          }
        }

        if (listaValor.length > 0) {
          const listaRateio = RateioComCasaDecimalCalculo({
            valor,
            valores: listaValor,
            casasDecimais: 2,
          });
          const listaReferencia = listaMovimentoPortadorCategoria.filter(
            (item: any) => item.tipoLancamento === tipoLancamento
          );
          for (let i = 0; i < listaReferencia.length; i++) {
            const element = listaReferencia[i];
            element.valor = listaRateio[i];
          }
        }

        formRefDetalhe.current?.setFieldValue(
          'listaMovimentoPortadorCategoria',
          listaMovimentoPortadorCategoria
        );

        refresh();
      }
    },
    [formPrincipal.formRef, formRefDetalhe, indexParcela, refresh]
  );

  const handleSugerirValorLiquidacao = useCallback(async () => {
    const valor = formRefDetalhe.current?.getFieldValue('valor');
    const contaAdiantamento =
      formRefDetalhe.current?.getFieldValueNomeObjeto('contaAdiantamento');
    if (contaAdiantamento) {
      const { valorDisponivelAdiantamento } = contaAdiantamento;

      if (Number(valor) > Number(valorDisponivelAdiantamento)) {
        formRefDetalhe.current?.setFieldValue(
          'valor',
          valorDisponivelAdiantamento
        );
        formRefDetalhe.current?.setFieldValue(
          'valorGeral',
          valorDisponivelAdiantamento
        );
      }
    }
  }, [formRefDetalhe]);

  const handleValidaSeCalculaJuros = useCallback(async () => {
    const jurosParametro = Number(
      parametros.JurosAoMesPadraoParaContasEmAtraso
    );

    const dataLiquidacao = formRefDetalhe.current?.getFieldValue('data');

    let utcFront = new Date().toTimeString().slice(12, 17);
    utcFront = utcFront.Insert(3, ':');

    const diasEmAtraso = DiferencaDiasEntreDatas(
      dataVencimento,
      dataLiquidacao,
      utcFront
    );

    return { diasEmAtraso, jurosParametro };
  }, [
    dataVencimento,
    formRefDetalhe,
    parametros.JurosAoMesPadraoParaContasEmAtraso,
  ]);

  const handleCalcularJurosSimples = useCallback(async () => {
    const { diasEmAtraso, jurosParametro } = await handleValidaSeCalculaJuros();

    if (diasEmAtraso > 0 && jurosParametro > 0) {
      const valorGeralLiquidacao =
        formRefDetalhe.current?.getFieldValue('valorGeral');

      const jurosCalculados = JurosSimples({
        valorPagar: valorGeralLiquidacao,
        diasAtraso: diasEmAtraso,
        taxaMensal: jurosParametro,
      });

      formRefDetalhe.current.setFieldValue('valorJurosSoma', jurosCalculados);
    } else {
      formRefDetalhe.current.setFieldValue('valorJurosSoma', 0.0);
    }
  }, [formRefDetalhe, handleValidaSeCalculaJuros]);

  const handleCalcularJurosPorDentro = useCallback(
    async (valorGeralInicial: number) => {
      const { diasEmAtraso, jurosParametro } =
        await handleValidaSeCalculaJuros();

      if (diasEmAtraso > 0 && jurosParametro > 0) {
        const jurosCalculados = JurosCalculoPorDentro({
          valorPagar: valorGeralInicial,
          diasAtraso: diasEmAtraso,
          taxaMensal: jurosParametro,
        });

        formRefDetalhe.current.setFieldValue('valorJurosSoma', jurosCalculados);
      }
    },
    [formRefDetalhe, handleValidaSeCalculaJuros]
  );

  const handleCalcularValorGeral = useCallback(async () => {
    const {
      valor,
      valorJurosSoma,
      valorDescontoSubtrai,
      valorMultaSoma,
      valorOutrasDespesasSoma,
      valorOutrasDespesasSubtrai,
      valorDespesasCartaoSubtrai,
      valorDespesasCartorioSoma,
      valorDespesasEnvioSoma,
      valorComissoesSoma,
      valorDespesasEnvioSubtrai,
      valorComissoesSubtrai,
    } = formRefDetalhe.current?.getData() as any;

    const valorTotalParcela = CalcularValorTotalParcela({
      valorParcela: 0,
      valorJurosSoma: formaCalculoJurosManual ? Number(valorJurosSoma) : 0,
      valorDescontoSubtrai: Number(valorDescontoSubtrai),
      valorMultaSoma: Number(valorMultaSoma),
      valorOutrasDespesasSoma: Number(valorOutrasDespesasSoma),
      valorOutrasDespesasSubtrai: Number(valorOutrasDespesasSubtrai),
      valorDespesasCartaoSubtrai: Number(valorDespesasCartaoSubtrai),
      valorDespesasCartorioSoma: Number(valorDespesasCartorioSoma),
      valorDespesasEnvioSoma: Number(valorDespesasEnvioSoma),
      valorComissoesSoma: Number(valorComissoesSoma),
      valorDespesasEnvioSubtrai: Number(valorDespesasEnvioSubtrai),
      valorComissoesSubtrai: Number(valorComissoesSubtrai),
    });

    let valorGeral = 0;

    if (formaCalculoJurosManual) {
      valorGeral = Number(valor) - valorTotalParcela;
    } else {
      const valorGeralInicial = Number(valor) - valorTotalParcela;

      await handleCalcularJurosPorDentro(valorGeralInicial);

      const jurosAtualizados =
        formRefDetalhe.current.getFieldValue('valorJurosSoma');

      valorGeral = valorGeralInicial - jurosAtualizados;
    }

    formRefDetalhe.current?.setFieldValue(
      'valorGeral',
      valorGeral /* > 0 ? valorGeral : 0 */
    );
  }, [formRefDetalhe, handleCalcularJurosPorDentro, formaCalculoJurosManual]);

  const handleSugerirDataContabil = useCallback(async () => {
    const dataLiquidacao = formRefDetalhe.current?.getFieldValue('data');
    formRefDetalhe.current?.setFieldValue('dataContabil', dataLiquidacao);

    if (!formaCalculoJurosManual) {
      await handleCalcularJurosSimples();
      handleCalcularValorLiquidacao();
    }
  }, [
    formRefDetalhe,
    handleCalcularJurosSimples,
    handleCalcularValorLiquidacao,
    formaCalculoJurosManual,
  ]);

  return (
    <Divisor>
      <Row>
        <InputHiddenHtml name="id" />
        <InputHiddenHtml name="formaCalculoJurosManual" />
        <InputHiddenHtml
          name="percentualLiquidado"
          validarSeMudou={() => {
            return false;
          }}
        />

        <Col lg={4} md={4} sm={4}>
          <InputDecimal
            ref={inputRefFocus}
            name="valor"
            label="Valor Liquidação"
            placeholder="Valor Liquidação"
            casasInteiras={18}
            casasDecimais={2}
            onChangeValue={() => {
              handleCalcularPercentualLiquidado();
            }}
            onBlur={() => {
              handleCalcularValorGeral();
              handleCalcularPercentualLiquidado();
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <InputDate
            name="data"
            label="Data Liquidação"
            onChange={handleSugerirDataContabil}
            disabled={bloquearCamposReversao}
          />
        </Col>
        <Col lg={4} md={4} sm={4}>
          <InputDate
            name="dataContabil"
            label="Data Contábil"
            disabled={bloquearCamposReversao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} sm={12}>
          <InputAutoCompleteFormaPagamento
            label="Forma de Pagamento"
            placeholder="Forma de Pagamento"
            name="idFormaPagamento"
            nomeObjeto="formaPagamento"
            disabled
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <InputAutoCompletePortador
            label="Portador"
            placeholder="Portador"
            name="idPortador"
            nomeObjeto="portador"
            permitirAdicionar={false}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} sm={12}>
          <InputAutoCompleteContaTipoAdiantamentoEnum
            label="Adiantamento"
            placeholder="Adiantamento"
            name="idContaAdiantamento"
            nomeObjeto="contaAdiantamento"
            idPessoa={idPessoa}
            listaIdParcelaParaRemover={listaIdParcelaRemoverContaAdiantamento}
            listaAdiantamentoUtilizado={listaAdiantamentoUtilizado}
            onChangeItemAtualAposCarregarSemClear={handleSugerirValorLiquidacao}
            onChangeItemAtual={(ev) => {
              if (ev.itemAtual) {
                formRefDetalhe.current?.setFieldValue('portador', null);
                formRefDetalhe.current?.setFieldValue('formaPagamento', null);
              }
            }}
            disabled={bloquearCamposReversao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="Geral"
            name="valorGeral"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={async (event) => {
              if (!formaCalculoJurosManual) await handleCalcularJurosSimples();
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.geral
              );
            }}
            onChangeValue={(event) => {
              handleCalcularValorCategoriaLiquidacao(
                event.valor,
                TipoLancamentoEnum.geral
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Juros"
            name="valorJurosSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.jurosSoma
              );
            }}
            onChange={() => {
              formaCalculoJurosManual = true;
              formRefDetalhe.current.setFieldValue(
                'formaCalculoJurosManual',
                true
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desconto"
            name="valorDescontoSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.descontosSubtrai
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Multa"
            name="valorMultaSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.multaSoma
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Outras Despesas"
            name="valorOutrasDespesasSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.outrasDespesasSoma
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Outras Despesas"
            name="valorOutrasDespesasSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.outrasDespesasSubtrai
              );
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desp. de Cartão"
            name="valorDespesasCartaoSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.despesasCartaoSubtrai
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Desp. de Cartório"
            name="valorDespesasCartorioSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.despesasCartorioSoma
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Desp. de Envio"
            name="valorDespesasEnvioSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.despesasEnvioSoma
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Desp. de Envio"
            name="valorDespesasEnvioSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.despesasEnvioSubtrai
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(+) Comissões Pagas"
            name="valorComissoesSoma"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.comissoesPagasSoma
              );
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={4}>
          <InputDecimal
            label="(-) Comissões Pagas"
            name="valorComissoesSubtrai"
            casasDecimais={2}
            casasInteiras={18}
            disabled={bloquearCamposReversao}
            onBlur={(event) => {
              handleCalcularValorLiquidacao();
              handleCalcularValorCategoriaLiquidacao(
                event.target.value.ConverterParaNumber(),
                TipoLancamentoEnum.comissoesPagasSubtrai
              );
            }}
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default GeralTab;
