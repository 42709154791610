import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { IGrupoProdutoCodigoRastreabilidadeLista } from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  AiOutlineDelete,
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
} from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputAutoCompleteCodigoRastreabilidade, {
  IInputAutoCompleteCodigoRastreabilidadeRef,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteCodigoRastreabilidade';
import TrDraggable from '../../../../../../../Componentes/TrDraggable';
import { UseListaGrupoProdutoCodigoRastreabilidadeDraggable } from '../Hooks/ListaCodigoRastreabilidadeDraggableHook';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import GrupoProdutoCodigoRastreabilidadeComunicador from '../../../../../../../Comunicador/Suprimentos/Sisdevin/GrupoProdutoSisdevin/GrupoProdutoCodigoRastreabilidade/Comunicador/GrupoProdutoCodigoRastreabilidadeComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheCodigoRastreabilidadeProps {
  name: string;
}

const CodigoRastreabilidadeLista: React.FC<
  IListaDetalheCodigoRastreabilidadeProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosSisdevinCodigoRastreabilidade: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const { listaValor, handleAtualizarOrdem, handleMover, setListaValor } =
    UseListaGrupoProdutoCodigoRastreabilidadeDraggable();

  const valorRef = useRef<IGrupoProdutoCodigoRastreabilidadeLista[]>([]);
  const itemRef = useRef<IInputAutoCompleteCodigoRastreabilidadeRef>(null);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  const listaId = useMemo(
    () => listaValor.map((valor) => valor.codigoRastreabilidadeSisdevin.id),
    [listaValor]
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IGrupoProdutoCodigoRastreabilidadeLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;
    await handleSubmit(
      {
        idCodigoRastreabilidadeSisdevin: data.idCodigoRastreabilidadeSisdevin,
      },
      formRefLista.current
    );
    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  const handleAtivar = useCallback(
    async (id: string): Promise<void> => {
      try {
        await GrupoProdutoCodigoRastreabilidadeComunicador.update({
          id,
          ativo: true,
        });

        ToastSucesso('Registro Ativado!');
        formPrincipal.handleCarregarDados();
      } catch (error) {
        TratarErros(error, { redirecionar });
      }
    },
    [formPrincipal, redirecionar]
  );

  const handleInativar = useCallback(
    async (id: string): Promise<void> => {
      try {
        await GrupoProdutoCodigoRastreabilidadeComunicador.update({
          id,
          ativo: false,
        });

        ToastSucesso('Registro Inativar!');
        formPrincipal.handleCarregarDados();
      } catch (error) {
        TratarErros(error, { redirecionar });
      }
    },
    [formPrincipal, redirecionar]
  );

  return (
    <>
      <Row>
        <Col lg={10} md={8} sm={12}>
          <FormCia ref={formRefLista}>
            <InputAutoCompleteCodigoRastreabilidade
              label="Código de Rastreabilidade (CR)"
              name="idCodigoRastreabilidadeSisdevin"
              placeholder="Código de Rastreabilidade"
              permitirAdicionar
              listaIdParaRemover={listaId}
              ref={itemRef}
            />
          </FormCia>
        </Col>
        <Col
          lg={2}
          md={4}
          sm={12}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '33%' }}>
                <span>Código</span>
              </th>
              <th className="lista-texto" style={{ width: '34%' }}>
                <span>Descrição (CR)</span>
              </th>
              <th className="lista-texto" style={{ width: '33%' }}>
                <span>Ativo</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor &&
              listaValor.map((item, index) => {
                return (
                  <TrDraggable
                    idDrag={v4()}
                    index={index}
                    onMover={handleMover}
                    onDragEnd={() => {
                      if (formPrincipal.loading || loading) return;
                      handleAtualizarOrdem();
                    }}
                    key={v4()}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>
                        {
                          item.codigoRastreabilidadeSisdevin
                            .codigoRastreabilidade
                        }
                      </div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.codigoRastreabilidadeSisdevin.descricao}</div>
                    </td>
                    <td
                      className="lista-booleano"
                      style={{ color: item.ativo ? 'green' : 'red' }}
                    >
                      <div>{item.ativo ? 'Sim' : 'Não'}</div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(item.id);
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>

                          {!item.ativo && (
                            <Dropdown.Item
                              onClick={() => {
                                handleAtivar(item.id);
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <AiOutlineCheckSquare
                                  style={{ marginRight: 5 }}
                                />
                                Ativar
                              </span>
                            </Dropdown.Item>
                          )}
                          {item.ativo && (
                            <Dropdown.Item
                              onClick={() => {
                                handleInativar(item.id);
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <AiOutlineCloseSquare
                                  style={{ marginRight: 5 }}
                                />
                                Inativar
                              </span>
                            </Dropdown.Item>
                          )}
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </TrDraggable>
                );
              })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default CodigoRastreabilidadeLista;
