import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import InputDecimalNulavel, {
  IInputDecimalNulavelRef,
} from '../InputDecimalNulavel';

interface IInputIntervaloValoresProps {
  nomeValorInicial: string;
  nomeValorFinal: string;
  labelValorInicial?: string;
  labelValorFinal?: string;
}

export interface IInputIntervaloValoresRef {
  focus(): void;
  getIntervalo(): string;
}

const InputIntervaloValores: React.ForwardRefRenderFunction<
  IInputIntervaloValoresRef,
  IInputIntervaloValoresProps
> = (
  { nomeValorInicial, nomeValorFinal, labelValorInicial, labelValorFinal },
  ref
) => {
  const valorInicioRef = useRef<IInputDecimalNulavelRef>(null);
  const valorFimRef = useRef<IInputDecimalNulavelRef>(null);

  const validarValores = useCallback(() => {
    valorInicioRef.current?.setErro('');
    valorFimRef.current?.setErro('');

    const valorInicio = valorInicioRef.current?.value();
    const valorFim = valorFimRef.current?.value();

    if (!valorInicio || !valorFim) return;

    if (valorFim < valorInicio) {
      valorInicioRef.current?.setErro('Valor final menor que valor inicial');
      valorFimRef.current?.setErro('Valor final menor que valor inicial');
    }
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      valorInicioRef.current?.focus();
    },

    getIntervalo() {
      const valorInicio = valorInicioRef.current?.getValueFormatado();
      const valorFim = valorFimRef.current?.getValueFormatado();

      if (
        (valorInicio === undefined || valorInicio === null) &&
        (valorFim === undefined || valorFim === null)
      ) {
        return '';
      }

      if (!valorInicio && valorFim) {
        return Number(valorFim).FormatarParaPtBr();
      }

      if (!valorFim && valorInicio) {
        return Number(valorInicio).FormatarParaPtBr();
      }

      return `${Number(valorInicio).FormatarParaPtBr()} até ${Number(
        valorFim
      ).FormatarParaPtBr()}`;
    },
  }));

  return (
    <>
      <Row>
        <Col>
          <InputDecimalNulavel
            ref={valorInicioRef}
            name={nomeValorInicial}
            label={labelValorInicial}
            onChange={validarValores}
          />
        </Col>
        <Col>
          <InputDecimalNulavel
            ref={valorFimRef}
            name={nomeValorFinal}
            label={labelValorFinal}
            onChange={validarValores}
          />
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(InputIntervaloValores);
