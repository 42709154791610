import ILogPesquisaParametro from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/log';

interface IManifestoTransporteLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IManifestoTransporteLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ManifestoTransporteLogComunicador {
  public async index(
    params: IManifestoTransporteLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IManifestoTransporteLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ManifestoTransporteLogComunicador();
