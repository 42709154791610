/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { CheckSwitch, Container } from './styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

interface IOnChangeInputSwitchProps {
  valorAnterior: boolean;
}

interface IOnChangeValueInputSwitchProps {
  valorAnterior: boolean;
}

export interface IInputSwitchEvent {
  valor: boolean;
}

interface IInputSwitch
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string;
  ativo?: string;
  inativo?: string;
  largura?: number;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputSwitchProps
  ) => void | Promise<void>;
  onChangeValue?: (
    event: IInputSwitchEvent,
    props: IOnChangeValueInputSwitchProps
  ) => void | Promise<void>;
}

const InputSwitch: React.FC<IInputSwitch> = ({
  ativo = 'Ativo',
  inativo = 'Inativo',
  largura = 90,
  label,
  name,

  onFocus,
  onBlur,
  onChange,
  onChangeValue,
  ...rest
}) => {
  const { fieldName, error, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef<boolean>(false);

  useEffect(() => {
    valorAnterior.current = inputRef.current?.checked || false;
  }, [terminouCarregarDados]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.checked || false;
      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      setIsFocused(false);

      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<boolean>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return !!inputRef.current?.checked;
      },

      setValue(_, valor = false) {
        if (inputRef?.current) {
          valorAnterior.current = inputRef.current.checked;
          inputRef.current.checked = valor;

          onChangeValue &&
            onChangeValue({ valor }, { valorAnterior: valorAnterior.current });
        }
      },

      setSemExecutarEvento(_, valor = false) {
        if (inputRef?.current) {
          valorAnterior.current = inputRef.current.checked;
          inputRef.current.checked = valor;
        }
      },

      clearValue(_, valorInicial = false) {
        this.setValue(_, !!valorInicial);
      },

      validarSeAlterou(_, valorInicial = false) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      $isErrored={!!error}
      $isFocused={isFocused}
      $isFilled={isFilled}
      style={{ minWidth: largura }}
    >
      {label && <label>{label}</label>}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckSwitch
          $ativo={ativo}
          $inativo={inativo}
          $largura={largura}
          style={{ width: largura }}
        >
          <input
            type="checkbox"
            onFocus={handleInputFocus}
            onChange={(event) => {
              if (onChange)
                onChange(event, { valorAnterior: valorAnterior.current });
            }}
            onBlur={handleInputBlur}
            {...rest}
            ref={inputRef}
          />
          <div />
        </CheckSwitch>
      </div>
    </Container>
  );
};

export default InputSwitch;
