import React from 'react';
import ListaDetalheItemExportacao from './ListaDetalheItemExportacao/Lista';
import ItemExportacaoHook from './ListaDetalheItemExportacao/Hook';

const ItemExportacaoTab: React.FC = () => {
  return (
    <>
      <ItemExportacaoHook>
        <ListaDetalheItemExportacao name="listaNotaFiscalSaidaItemExportacao" />
      </ItemExportacaoHook>
    </>
  );
};

export default ItemExportacaoTab;
