"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoRodadoVeiculoEnum;
(function (TipoRodadoVeiculoEnum) {
    TipoRodadoVeiculoEnum["truck"] = "1 - Truck";
    TipoRodadoVeiculoEnum["toco"] = "2 - Toco";
    TipoRodadoVeiculoEnum["cavaloMecanico"] = "3 - Cavalo Mec\u00E2nico";
    TipoRodadoVeiculoEnum["van"] = "4 - VAN";
    TipoRodadoVeiculoEnum["utilitario"] = "5 - Utilit\u00E1rio";
    TipoRodadoVeiculoEnum["outros"] = "6 - Outros";
})(TipoRodadoVeiculoEnum || (TipoRodadoVeiculoEnum = {}));
exports.default = TipoRodadoVeiculoEnum;
