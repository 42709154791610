import { IRoteiroFabricacaoValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import RoteiroFabricacaoOperacaoLista from '../ListaDetalheRoteiroFabricacao/Lista';
import RoteiroFabricacaoOperacaoHook from '../ListaDetalheRoteiroFabricacao/Hooks';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputAutoCompleteRoteiroFabricacaoPadrao from '../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteRoteiroFabricacaoPadrao';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<IRoteiroFabricacaoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaEngenhariaRoteiroDeFabricacao: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { abrirJanela } = UseConfirmacao();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={8} md={12} sm={12}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                label="Produto"
                nomeObjeto="produto"
                name="idProduto"
                placeholder="Produto"
              />
            </Col>
            <Col lg={4} md={12}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={12}>
              <InputAutoCompleteRoteiroFabricacaoPadrao
                label="Roteiro de Fabricação Padrão"
                nomeObjeto="roteiroFabricacaoPadrao"
                name="idRoteiroFabricacaoPadrao"
                placeholder="Roteiro de Fabricação Padrão"
                onChangeItemAtualAposCarregarSemClear={async (item) => {
                  if (!item.itemAtual) {
                    return;
                  }
                  const listaOperacoes = formRef.current?.getFieldValue(
                    'listaRoteiroFabricacaoOperacao'
                  );
                  if (listaOperacoes.length) {
                    const retorno = await abrirJanela({
                      titulo: <h2>Confirmação</h2>,
                      mensagem: (
                        <span style={{ fontSize: 18 }}>
                          Deseja substituir as operações atuais pelas do roteiro
                          padrão?
                        </span>
                      ),
                      cancelar: 'Não',
                      confimar: 'Sim',
                      padraoSelecionadoSim: true,
                    });
                    if (!retorno) {
                      return;
                    }
                  }
                  const listaRoteiroFabricacaoOperacao =
                    item.itemAtual.listaRoteiroFabricacaoPadraoOperacao.map(
                      (valor) => {
                        return {
                          ...valor,
                          id: undefined,
                          idRoteiroFabricacaoPadrao: undefined,
                        };
                      }
                    );
                  formRef.current?.setFieldValue(
                    'listaRoteiroFabricacaoOperacao',
                    listaRoteiroFabricacaoOperacao
                  );
                }}
              />
            </Col>
          </Row>

          <InputHiddenHtml name="listaMudou" />

          <Row>
            <Col>
              <RoteiroFabricacaoOperacaoHook>
                <RoteiroFabricacaoOperacaoLista name="listaRoteiroFabricacaoOperacao" />
              </RoteiroFabricacaoOperacaoHook>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
