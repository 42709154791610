import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/marca-produto';

interface IMarcaProdutoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IMarcaProdutoComunicadorShow {
  id: string;
}

interface IMarcaProdutoComunicadorStore {
  params: any;
}

interface IMarcaProdutoComunicadorUpdate {
  id: string;
  params: any;
}

interface IMarcaProdutoComunicadorDeletar {
  id: string;
}

class MarcaProdutoComunicador {
  public async index(params: IMarcaProdutoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IMarcaProdutoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IMarcaProdutoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IMarcaProdutoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IMarcaProdutoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new MarcaProdutoComunicador();
