import { DestinadoEnum, TipoMovimentoEntradaSaidaEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/tributacao/regra-escolha-parametro/obter-conforme-criterios';

interface IRegraEscolhaParametroObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia: Date;
    idTipoNota: string;
    idTipoNotaMotivo: string;
    destinado?: DestinadoEnum | null;

    idOperacaoFiscal?: string | null;
    idSpedTipoProduto?: string | null;
    idNcm?: string | null;
    idProduto?: string;
    idModeloDocumento?: string | null;
    idSerie?: string | null;
    tipoMovimento: TipoMovimentoEntradaSaidaEnum | null;
  };
}

class RegraEscolhaParametroObterConformeCriteriosComunicador {
  public async show(
    params: IRegraEscolhaParametroObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaParametroObterConformeCriteriosComunicador();
