import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  INotaFiscalFaturaImpressao,
} from '@elogestor/util';

const NotaFiscalImpressaoFaturaPDF: React.FC<{
  fatura?: INotaFiscalFaturaImpressao;
}> = ({ fatura }) => {
  return (
    <View style={{ flexDirection: 'row', height: '10.5px' }}>
      <View
        style={{
          justifyContent: 'center',
          marginLeft: '3px',
          marginRight: '3px',
          width: '59.5px',
          borderRight: '1 solid black',
        }}
      >
        <Text>{fatura?.numeroDuplicata || ' '}</Text>
      </View>

      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '59.5px',
          borderRight: '1 solid black',
        }}
      >
        <Text>
          {fatura?.dataVencimento
            ? FormatarDataParaPtBr(fatura.dataVencimento)
            : ' '}
        </Text>
      </View>

      <View
        style={{
          alignItems: 'flex-end',
          justifyContent: 'center',
          width: '55px',
          marginLeft: '3px',
          marginRight: '3px',
        }}
      >
        <Text>
          {fatura?.valor ? Number(fatura.valor).FormatarParaPtBr() : ' '}
        </Text>
      </View>
    </View>
  );
};

export default NotaFiscalImpressaoFaturaPDF;
