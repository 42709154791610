import {
  IPesagemLocalEstoqueLista,
  IPesagemPessoaLista,
  IPesagemValoresAlterar,
  SituacaoPesagemEnum,
  TipoPesagemEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import PesagemComunicador from '../../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Comunicador/PesagemComunicador';
import PesagemAvaliacaoComunicador from '../../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Avaliacao/Comunicador/PesagemAvaliacaoComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPesagemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        dadosDuplicados.numero = null;

        await formRef.current?.setDataDuplicar(dadosDuplicados);

        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await PesagemComunicador.show({ id: idEditar });
        await formRef.current?.setDataInicial({
          ...response,
          listaPessoaMudou: false,
          listaEstoqueMudou: false,
        });

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response = await PesagemAvaliacaoComunicador.index();

        await formRef.current?.setDataInicial({
          dataHoraEmissao: new Date(),
          tipo: TipoPesagemEnum.propria,
          situacao: SituacaoPesagemEnum.naoUtilizada,
          listaPesagemAvaliacao: response,
          listaPessoaMudou: false,
          listaEstoqueMudou: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        dataHoraEmissao: Yup.date()
          .required('Data Hora Emissão é Obrigatório!')
          .nullable(),
        tipo: Yup.string()
          .required('Tipo da Pesagem é Obrigatório!')
          .nullable(),
        idProduto: Yup.string().required('Produto não informado!').nullable(),
        idPessoaTerceiroProprietario: Yup.string()
          .nullable()
          .test({
            message:
              'Quando Tipo for de Terceiros - Informar a Pessoa (Terceiro Proprietário)',
            test: (value: any) => {
              return !(data.tipo === TipoPesagemEnum.terceiros && !value);
            },
          }),
        pesoBruto: Yup.number()
          .required('Peso Bruto é Obrigatório!')
          .positive('Peso Bruto deve ser Positivo!')
          .nullable(),
        pesoTara: Yup.number().min(0).nullable(),
        pesoLiquido: Yup.number()
          .positive('Peso Líquido deve ser Positivo!')
          .nullable(),
        placa: Yup.string().nullable(),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleValidarTotais = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];
      const { listaPesagemLocalEstoque, listaPesagemPessoa, pesoLiquido } =
        data;

      const somaPesoLiquidoLocalEstoque = listaPesagemLocalEstoque.reduce(
        (acumulador: number, item: IPesagemLocalEstoqueLista) => {
          acumulador += Number(item.pesoLiquido) || 0;
          return acumulador;
        },
        0
      );

      const somaPercentualProdutores = listaPesagemPessoa.reduce(
        (acumulador: number, item: IPesagemPessoaLista) => {
          acumulador += Number(item.percentual) || 0;
          return acumulador;
        },
        0
      );

      if (
        listaPesagemLocalEstoque.length > 0 &&
        pesoLiquido > 0 &&
        somaPesoLiquidoLocalEstoque > pesoLiquido
      ) {
        listaErro.push(
          'A soma dos pesos dos locais de estoque é maior do que o peso líquido da pesagem!'
        );
      } else if (
        listaPesagemLocalEstoque.length > 0 &&
        pesoLiquido > 0 &&
        somaPesoLiquidoLocalEstoque !== pesoLiquido
      ) {
        listaErro.push(
          'A soma dos pesos dos locais de estoque é diferente do peso líquido da pesagem!'
        );
      }

      if (
        listaPesagemPessoa.length > 0 &&
        (somaPercentualProdutores < 100 || somaPercentualProdutores > 100)
      ) {
        listaErro.push('A Soma dos percentuais dos produtores deve ser 100%!');
      }

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidarTotais(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (data.tipo !== TipoPesagemEnum.terceiros) {
          data.idPessoaTerceiroProprietario = null;
        }

        if (id) {
          await PesagemComunicador.update({ id, params: data });
        } else {
          const response = await PesagemComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
          formRef.current?.setFieldValue('numero', response.numero);
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleValidar,
      handleValidarTotais,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
