import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTagPessoa from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';

const ContratanteTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagPessoa
              label="Contratante"
              name="listaManifestoTransporteContratante"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ContratanteTab;
