const NotaFiscalSaidaResumoPorAnoEmissaoPlanilha = [
  { titulo: 'Data de Emissao', valor: 'chave' },

  {
    titulo: 'Val Sub Total',
    valor: 'valores.somatorioAnoEmissaoValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'valores.somatorioAnoEmissaoValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'valores.somatorioAnoEmissaoValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'valores.somatorioAnoEmissaoValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'valores.somatorioAnoEmissaoValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'valores.somatorioAnoEmissaoValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'valores.somatorioAnoEmissaoValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'valores.somatorioAnoEmissaoValorTotal',
  },
];

export default NotaFiscalSaidaResumoPorAnoEmissaoPlanilha;
