import { DestinadoEnum, TipoMercadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/obter-regras';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaObterRegrasComunicadorIndexIndex {
  idPessoa?: string;
  idNotaFiscalSaida?: string;
  idNotaFiscalEntrada?: string;
  dataHoraEmissao: Date;
  idTipoNota: string;
  idTipoNotaMotivo: string;
  destinado: DestinadoEnum;
  tipoMercado: TipoMercadoEnum;
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaObterRegrasComunicadorIndex {
  public async index(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaObterRegrasComunicadorIndexIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaObterRegrasComunicadorIndex();
