import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/replicar-dados';

interface IUsuarioReplicarDadosComunicadorUpdate {
  params: any;
}

class UsuarioReplicarDadosComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async update(
    params: IUsuarioReplicarDadosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new UsuarioReplicarDadosComunicador();
