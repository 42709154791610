import React from 'react';
import { ComunicadorProvider } from '../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import ManifestoTransporteComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const ManifestoTransporteHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: ManifestoTransporteComunicador }}
      >
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default ManifestoTransporteHook;
