"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AppErro {
    constructor({ mensagem = null, stack = null, listaMensagem = null, sql = null, parameters = null, statusCode = 400, salvarLogErro = false, }) {
        if (listaMensagem)
            this.listaMensagem = listaMensagem;
        if (mensagem) {
            if (!listaMensagem)
                this.listaMensagem = [];
            this.listaMensagem.push(mensagem);
        }
        this.statusCode = statusCode;
        if (stack)
            this.stack = stack;
        if (sql)
            this.sql = sql;
        if (parameters)
            this.parameters = parameters;
        if (salvarLogErro)
            this.salvarLogErro = salvarLogErro;
    }
}
exports.default = AppErro;
