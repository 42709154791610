import ILogPesquisaParametro from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/comissao/regra-calculo-comissao/log';

interface IRegraCalculoComissaoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRegraCalculoComissaoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RegraCalculoComissaoLogComunicador {
  public async index(
    params: IRegraCalculoComissaoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraCalculoComissaoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RegraCalculoComissaoLogComunicador();
