/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from 'react';
import { Modal, Container } from 'react-bootstrap';
import { IContaValoresAlterar } from '@elogestor/util';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import ContaHook from '../../../../Conta/Detalhe/Hooks';
import Main from '../../../../Conta/Detalhe/Main';
import Detalhe from '../../../../../../../Componentes/Detalhe';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseContasParcelaReversaoLista } from '../../../Hooks/ContasParcelaReversaoListaHook';
import ContasParcelaReversaoComunicador from '../../../../../../../Comunicador/Financeiro/Movimentacoes/ContasParcelaReversao/Comunicador/ContasParcelaReversaoComunicador';

interface IFinanceiroModal {
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
  dadosPadrao: IContaValoresAlterar | undefined | null;
  isAdiantamentoAntecipacao?: boolean;
}

const FinanceiroDetalhe: React.FC<IFinanceiroModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  dadosPadrao,
  isAdiantamentoAntecipacao = false,
}) => {
  const { abrirJanela } = UseConfirmacao();

  const { listaValor, listaItemSelecionadoRef } =
    UseContasParcelaReversaoLista();

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja fechar o Financeiro sem Salvar?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) return;

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirJanela, onFecharFormModal]);

  const handleClickSalvar = useCallback(
    async (data: any): Promise<void> => {
      const listaIdConta: string[] = [];
      const listaIdParcela: string[] = [];
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item?.checked) {
          listaIdParcela.push(listaValor[index].id);
          if (!listaIdConta.includes(String(listaValor[index].conta.id)))
            listaIdConta.push(listaValor[index].conta.id);
        }
      });

      await ContasParcelaReversaoComunicador.store({
        params: { listaIdConta, listaIdParcela, idContaDestino: data.idConta },
      });
      if (onSalvarFormModal) onSalvarFormModal();
    },
    [listaItemSelecionadoRef, listaValor, onSalvarFormModal]
  );

  return (
    <JanelaDetalhe
      titulo={
        isAdiantamentoAntecipacao
          ? 'Adiantamento/Previsão (Receber/Pagar)'
          : 'Contas (Receber/Pagar)'
      }
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <Modal.Body>
        <Container>
          <ContaHook>
            <Detalhe
              style={{ paddingTop: 0 }}
              onSairDoFormulario={() => {}}
              onDescartarFormulario={() => {}}
              onRecuperarFormulario={() => {}}
            >
              <Main
                idEditar={null}
                dadosRecuperados={null}
                dadosDuplicados={null}
                dadosPadrao={dadosPadrao}
                onClickVoltar={handleClickVoltar}
                onClickSalvar={handleClickSalvar}
                bloquearCamposReversao
                isJanela
                isAdiantamentoAntecipacao={isAdiantamentoAntecipacao}
              />
            </Detalhe>
          </ContaHook>
        </Container>
      </Modal.Body>
    </JanelaDetalhe>
  );
};

export default FinanceiroDetalhe;
