import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/gerar-orcamento';

interface IFrenteCaixaPdvGerarOrcamentoComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarOrcamentoComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarOrcamentoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarOrcamentoComunicador();
