import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavegacaoProvider } from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao/Hooks/NavegacaoHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { UseListaDraggable } from './ListaDraggableHook';

const ItemNavegacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaValor, totalPaginas } = UseListaDraggable();

  const [posicaoNavegacao, setPosicaoNavegacao] = useState(1);
  const [mostrarNavegacao, setMostrarNavegacao] = useState(false);
  const totalNavegacao = useMemo(() => {
    return listaValor.length;
  }, [listaValor.length]);

  const handleSetPosicaoNavegacao = useCallback((novaPosicao: number) => {
    setPosicaoNavegacao(novaPosicao);
  }, []);

  useEffect(() => {
    if (totalPaginas > 1) {
      setMostrarNavegacao(true);
    } else {
      setMostrarNavegacao(false);
    }
  }, [totalPaginas]);

  const handleAnterior = useCallback(async () => {
    if (posicaoNavegacao - 1 <= 1) {
      handleSetPosicaoNavegacao(1);
      return;
    }
    handleSetPosicaoNavegacao(posicaoNavegacao - 1);
  }, [posicaoNavegacao, handleSetPosicaoNavegacao]);

  const handlePrimeiro = useCallback(async () => {
    handleSetPosicaoNavegacao(1);
  }, [handleSetPosicaoNavegacao]);

  const handleProximo = useCallback(async () => {
    if (posicaoNavegacao + 1 >= totalNavegacao) {
      handleSetPosicaoNavegacao(totalNavegacao);
      return;
    }

    handleSetPosicaoNavegacao(posicaoNavegacao + 1);
  }, [posicaoNavegacao, totalNavegacao, handleSetPosicaoNavegacao]);

  const handleUltimo = useCallback(async () => {
    handleSetPosicaoNavegacao(totalNavegacao);
  }, [handleSetPosicaoNavegacao, totalNavegacao]);

  return (
    <NavegacaoProvider
      value={{
        totalNavegacao,
        posicaoNavegacao,
        mostrarNavegacao,
        handleAnterior,
        handlePrimeiro,
        handleProximo,
        handleUltimo,
      }}
    >
      {children}
    </NavegacaoProvider>
  );
};

export { ItemNavegacaoHook };
