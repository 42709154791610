import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IcmsObterVisibilidadeBloqueioCamposPorCst } from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const camposEscondidosIcmsPadrao = {
  // #region ICMS

  calcularIcms: true,
  modalidadeBaseCalculoIcms: true,

  percentualReducaoBaseCalculoIcms: true,
  baseCalculoIcms: true,
  aliquotaIcms: true,
  valorIcms: true,

  baseCalculoFcpIcms: true,
  aliquotaFcpIcms: true,
  valorFcpIcms: true,
  valorIcmsEValorFcpIcms: true,

  valorSemDiferimentoIcms: true,
  percentualDiferimentoIcms: true,
  valorDiferidoIcms: true,

  valorUnitarioPautaIcms: true,
  valorTotalPautaIcms: true,

  calcularIcmsEfetivo: true,
  percentualReducaoBaseCalculoIcmsEfetivo: true,
  baseCalculoIcmsEfetivo: true,
  aliquotaIcmsEfetivo: true,
  valorIcmsEfetivo: true,

  calcularIcmsSubstituto: true,
  valorUnitarioIcmsSubstituto: true,
  valorIcmsSubstituto: true,

  motivoDesoneracaoIcms: true,
  valorDesoneracaoIcms: true,

  // baseCalculoSemReducaoIcms: true,
  // baseCalculoReducaoIcms: true,
  // valorSemReducaoIcms: true,
  // valorReducaoIcms: true,

  // #endregion ICMS

  // #region ICMS ST

  calcularIcmsSt: true,
  modalidadeBaseCalculoIcmsSt: true,
  percentualMvaIcmsSt: true,
  percentualReducaoBaseCalculoIcmsSt: true,
  aliquotaInterestadualIcmsSt: true,
  aliquotaInternaIcmsSt: true,
  baseCalculoIcmsSt: true,
  valorIcmsSt: true,

  baseCalculoFcpIcmsSt: true,
  aliquotaFcpIcmsSt: true,
  valorFcpIcmsSt: true,
  valorIcmsStEValorFcpIcmsSt: true,

  calcularIcmsStRetido: true,
  baseCalculoIcmsStRetido: true,
  baseCalculoUnitariaProdutoIcmsStRetido: true,
  aliquotaIcmsStRetido: true,
  valorIcmsStRetido: true,
  valorUnitarioProdutoIcmsStRetido: true,

  baseCalculoFcpIcmsStRetido: true,
  baseCalculoUnitariaProdutoFcpIcmsStRetido: true,
  aliquotaFcpIcmsStRetido: true,
  valorFcpIcmsStRetido: true,
  valorUnitarioProdutoFcpIcmsStRetido: true,

  valorUnitarioPautaIcmsSt: true,
  valorTotalPautaIcmsSt: true,

  // #endregion ICMS ST

  // #region ICMS SN

  calcularCreditoIcmsSn: true,
  percentualCreditoIcmsSn: true,
  valorCreditoIcmsSn: true,

  // #endregion ICMS SN

  abaIcmsSt: true,
};

const camposBloqueadosIcmsPadrao = {
  // #region ICMS

  calcularIcms: true,
  modalidadeBaseCalculoIcms: true,

  percentualReducaoBaseCalculoIcms: true,
  baseCalculoIcms: true,
  aliquotaIcms: true,
  valorIcms: true,

  baseCalculoFcpIcms: true,
  aliquotaFcpIcms: true,
  valorFcpIcms: true,
  valorIcmsEValorFcpIcms: true,

  valorSemDiferimentoIcms: true,
  percentualDiferimentoIcms: true,
  valorDiferidoIcms: true,

  valorUnitarioPautaIcms: true,
  valorTotalPautaIcms: true,

  calcularIcmsEfetivo: true,
  percentualReducaoBaseCalculoIcmsEfetivo: true,
  baseCalculoIcmsEfetivo: true,
  aliquotaIcmsEfetivo: true,
  valorIcmsEfetivo: true,

  calcularIcmsSubstituto: true,
  valorUnitarioIcmsSubstituto: true,
  valorIcmsSubstituto: true,

  motivoDesoneracaoIcms: true,
  valorDesoneracaoIcms: true,

  // baseCalculoSemReducaoIcms: true,
  // baseCalculoReducaoIcms: true,
  // valorSemReducaoIcms: true,
  // valorReducaoIcms: true,

  // #endregion ICMS

  // #region ICMS ST

  calcularIcmsSt: true,
  modalidadeBaseCalculoIcmsSt: true,
  percentualMvaIcmsSt: true,
  percentualReducaoBaseCalculoIcmsSt: true,
  aliquotaInterestadualIcmsSt: true,
  aliquotaInternaIcmsSt: true,
  baseCalculoIcmsSt: true,
  valorIcmsSt: true,

  baseCalculoFcpIcmsSt: true,
  aliquotaFcpIcmsSt: true,
  valorFcpIcmsSt: true,
  valorIcmsStEValorFcpIcmsSt: true,

  calcularIcmsStRetido: true,
  baseCalculoIcmsStRetido: true,
  baseCalculoUnitariaProdutoIcmsStRetido: true,
  aliquotaIcmsStRetido: true,
  valorIcmsStRetido: true,
  valorUnitarioProdutoIcmsStRetido: true,

  baseCalculoFcpIcmsStRetido: true,
  baseCalculoUnitariaProdutoFcpIcmsStRetido: true,
  aliquotaFcpIcmsStRetido: true,
  valorFcpIcmsStRetido: true,
  valorUnitarioProdutoFcpIcmsStRetido: true,

  valorUnitarioPautaIcmsSt: true,
  valorTotalPautaIcmsSt: true,

  // #endregion ICMS ST

  // #region ICMS SN

  calcularCreditoIcmsSn: true,
  percentualCreditoIcmsSn: true,
  valorCreditoIcmsSn: true,

  // #endregion ICMS SN

  abaIcmsSt: true,
};

type ICamposEscondidosIcms = typeof camposEscondidosIcmsPadrao;
type ICamposBloqueadosIcms = typeof camposBloqueadosIcmsPadrao;

interface IItemIcmsTabContext {
  validador: boolean;
  camposEscondidosIcms: ICamposEscondidosIcms;
  camposBloqueadosIcms: ICamposBloqueadosIcms;
  obterVisibilidadeBloqueioCamposPorIcmsCst(): void;
}

const ItemIcmsTabContext = createContext<IItemIcmsTabContext>(
  {} as IItemIcmsTabContext
);

const ItemIcmsTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe } = UseListaDetalheForm();

  const [camposEscondidosIcms, setCamposEscondidosIcms] = useState(
    camposEscondidosIcmsPadrao
  );

  const [camposBloqueadosIcms, setCamposBloqueadosIcms] = useState(
    camposBloqueadosIcmsPadrao
  );

  const calcularIcms = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularIcms'
  );
  const calcularIcmsEfetivo = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularIcmsEfetivo'
  );
  const calcularIcmsSubstituto = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularIcmsSubstituto'
  );

  const calcularIcmsSt = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularIcmsSt'
  );
  const calcularIcmsStRetido = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularIcmsStRetido'
  );

  const calcularCreditoIcmsSn = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcms.calcularCreditoIcmsSn'
  );

  const cst =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoIcms.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorIcmsCst = useCallback(() => {
    const retorno = IcmsObterVisibilidadeBloqueioCamposPorCst({
      calcularIcms: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularIcms'
      ),
      calcularIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularIcmsEfetivo'
      ),
      calcularIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularIcmsSubstituto'
      ),
      calcularIcmsSt: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularIcmsSt'
      ),
      calcularIcmsStRetido: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularIcmsStRetido'
      ),
      calcularCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemImpostoIcms.calcularCreditoIcmsSn'
      ),
      cst:
        formRefDetalhe.current?.getFieldValueNomeObjeto(
          'notaFiscalEntradaItemImpostoIcms.situacaoTributaria'
        )?.codigo || '',
    });

    setCamposEscondidosIcms(retorno.escondido);
    setCamposBloqueadosIcms(retorno.bloqueado);
  }, [formRefDetalhe]);

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorIcmsCst();
  }, [
    calcularIcms,
    calcularIcmsEfetivo,
    calcularIcmsSubstituto,
    calcularIcmsSt,
    calcularIcmsStRetido,
    calcularCreditoIcmsSn,
    cst,
    handleObterVisibilidadeBloqueioCamposPorIcmsCst,
  ]);

  return (
    <ItemIcmsTabContext.Provider
      value={{
        camposEscondidosIcms,
        camposBloqueadosIcms,
        obterVisibilidadeBloqueioCamposPorIcmsCst:
          handleObterVisibilidadeBloqueioCamposPorIcmsCst,
        validador: true,
      }}
    >
      {children}
    </ItemIcmsTabContext.Provider>
  );
};

function UseItemIcmsTab(): Omit<IItemIcmsTabContext, 'validador'> {
  const context = useContext(ItemIcmsTabContext);

  if (!context.validador) {
    throw new Error('UseItemIcmsTab deve ser usado com um ItemIcmsTabHook');
  }

  return context;
}

export { ItemIcmsTabHook, UseItemIcmsTab };
