/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista } from '@elogestor/util';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import { Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { Tabela } from '../../../../../Componentes/Tabela/styles';
import { UltimaTr } from '../styles';
import InputTabelaDecimal from '../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';

export interface INotaFiscalSaidaImportacaoOrdemServicoEtapa2Ref {
  handleSelecionarTodosEtapa(valor: boolean): void;
  handleValidar(): boolean;
  handleObterListaValorSelecionados(): INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[];
}

interface INotaFiscalSaidaImportacaoOrdemServicoEtapa2Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const NotaFiscalSaidaImportacaoOrdemServicoEtapa2: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoOrdemServicoEtapa2Ref,
  INotaFiscalSaidaImportacaoOrdemServicoEtapa2Props
> = ({ name, refresh }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);
  const listaSelecionadoRef = useRef<HTMLInputElement[]>([]);
  const valorRef = useRef<INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[]>(
    []
  );
  const quantidadeRef = useRef<HTMLInputElement>(null);

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[]
  >([]);
  const [valorSubTotal, setValorSubTotal] = useState<number>(0);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterListaValorSelecionados =
    useCallback((): INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista[] => {
      return listaValor.filter((item) => {
        return item?.selecionado;
      });
    }, [listaValor]);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const listaSelecionados = handleObterListaValorSelecionados();

    const valorTotal = listaSelecionados.reduce(
      (
        acumulador: number,
        item: INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista
      ) => {
        acumulador += Number(item.valorTotal) || 0;
        return acumulador;
      },
      0
    );

    setValorSubTotal(valorTotal);
  }, [handleObterListaValorSelecionados]);

  const handleSelecionarTodos = useCallback(
    (valor: boolean) => {
      listaSelecionadoRef.current.forEach((e) => {
        e.checked = valor;
      });
      listaValor.forEach((e) => {
        e.selecionado = valor;
      });

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleSelecionarTodosEtapa = useCallback(
    (valor: boolean) => {
      if (selecionarTodosRef.current) {
        selecionarTodosRef.current.checked = valor;
      }

      handleSelecionarTodos(valor);
    },
    [handleSelecionarTodos]
  );

  const handleAlterarSelecionado = useCallback(
    (valor: boolean, index: number) => {
      listaValor[index].selecionado = valor;

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];

    const listaSelecionados = handleObterListaValorSelecionados();

    if (listaSelecionados.length === 0) {
      listaMensagemErro.push(
        'Selecione pelo menos um item para poder prosseguir!'
      );
    }

    listaSelecionados.forEach((valor) => {
      if (valor.quantidadeTransferir > valor.quantidade)
        listaMensagemErro.push(
          `Não é possível transferir uma quantidade maior que a disponível para o Item: ${valor.ordem}, Produto: ${valor.produto.codigo} - ${valor.produto.descricao}!`
        );
    });

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, [handleObterListaValorSelecionados]);

  const handleAlterarQuantidade = useCallback(
    (valor: string, index: number) => {
      const item = listaValor[index];
      const quantidade = valor.ConverterParaNumber();
      const valorTotal = quantidade * item.valorUnitario;

      item.valorTotal = valorTotal;
      item.quantidadeTransferir = quantidade;

      handleAtualizarValorSubTotal();
      refresh();
    },
    [handleAtualizarValorSubTotal, listaValor, refresh]
  );

  useEffect(() => {
    handleAtualizarValorSubTotal();
  }, [handleAtualizarValorSubTotal]);

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
      handleSelecionarTodosEtapa,
      handleObterListaValorSelecionados,
    };
  });

  return (
    <Tabela style={{ overflow: 'auto', height: 500 }}>
      <Table striped bordered variant="light">
        <thead>
          <tr>
            <th
              className="lista-texto"
              style={{
                width: '5%',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <input
                type="checkbox"
                ref={selecionarTodosRef}
                onChange={(event) => {
                  handleSelecionarTodos(event.target.checked);
                }}
              />
            </th>
            <th className="lista-valor" style={{ width: '2%' }}>
              <span>Ord.</span>
            </th>
            <th className="lista-texto" style={{ width: '20%' }}>
              <span>Produto</span>
            </th>
            <th className="lista-texto" style={{ width: '10%' }}>
              <span>UN</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Quantidade</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Qtde. Transferir</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Valor Unitário</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Sub. Total</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {listaValor &&
            listaValor.map(
              (
                item: INotaFiscalSaidaImportacaoOrdemServicoEtapa2Lista,
                index
              ) => {
                return (
                  <tr key={v4()}>
                    <td
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={item.selecionado}
                        ref={(instance) => {
                          if (instance)
                            listaSelecionadoRef.current[index] = instance;
                        }}
                        onChange={(event) => {
                          handleAlterarSelecionado(event.target.checked, index);
                        }}
                      />
                    </td>
                    <td className="lista-valor">
                      <div>{item.ordem}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{`${item.produto.codigo} - ${item.produto.descricao}`}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.unidadeMedida.sigla}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidade).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor" style={{ padding: 5 }}>
                      <div>
                        <InputTabelaDecimal
                          valorPadrao={item.quantidadeTransferir}
                          valueRef={quantidadeRef}
                          casasDecimais={4}
                          onBlur={(event) => {
                            handleAlterarQuantidade(event.target.value, index);
                          }}
                        />
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorUnitario).FormatarParaPtBr({
                          minimoCasasDecimais: 10,
                          maximoCasasDecimais: 10,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>{Number(item.valorTotal).FormatarParaPtBr()}</div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>

        <tfoot>
          <UltimaTr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>Valor Sub. Total</div>
                <div style={{ alignSelf: 'flex-end' }}>
                  {valorSubTotal.FormatarParaPtBr()}
                </div>
              </div>
            </th>
          </UltimaTr>
        </tfoot>
      </Table>
    </Tabela>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoOrdemServicoEtapa2);
