import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/documentos-entrada/documentos-entrada-por-cfop';

interface IDocumentosEntradaPorCfopRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class DocumentosEntradaPorCfopRelatorioComunicador {
  public async index(
    params: IDocumentosEntradaPorCfopRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new DocumentosEntradaPorCfopRelatorioComunicador();
