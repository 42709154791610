import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/ordem-servico/log';

interface IOrdemServicoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IOrdemServicoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class OrdemServicoLogComunicador {
  public async index(
    params: IOrdemServicoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IOrdemServicoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new OrdemServicoLogComunicador();
