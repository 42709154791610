import { IOrdemDesmontagemImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import OrdemDesmontagemImpressaoPDF from './OrdemDesmontagemImpressaoPDF';

interface IImpressaoOrdemDesmontagem {
  handleVisualizarPdf(dados: IOrdemDesmontagemImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemDesmontagemImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemDesmontagem = (): IImpressaoOrdemDesmontagem => {
  async function handleVisualizarPdf(
    dados: IOrdemDesmontagemImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <OrdemDesmontagemImpressaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: IOrdemDesmontagemImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <OrdemDesmontagemImpressaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return { handleVisualizarPdf, handleObterBlobPdf };
};

export default ImpressaoOrdemDesmontagem();
