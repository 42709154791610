import { IPessoaProdutorLista } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import { Container } from './styles';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheProdutorProps {
  name: string;
}

const PessoaProdutorLista: React.FC<IListaDetalheProdutorProps> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IPessoaProdutorLista[]>([]);

  const [listaValor, setListaValor] = useState<IPessoaProdutorLista[]>([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPessoaProdutorLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },
      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData();
    await handleSubmit(
      { ...data, ordem: listaValor.length + 1 },
      formRefLista.current
    );

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, listaValor.length, setIdDetalheRegistro]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col lg={3} md={4} sm={12}>
            <Input
              label="Núm. Cad. Vitícola"
              name="numeroCadastroViticola"
              placeholder="Núm. Cad. Vitícola"
              maxLength={11}
              disabled={formPrincipal.loading}
            />
          </Col>
          <Col lg={7} md={5} sm={12}>
            <Input
              label="Observação"
              name="observacoes"
              placeholder="Observação"
              maxLength={100}
              disabled={formPrincipal.loading}
            />
          </Col>
          <Col
            lg={2}
            md={3}
            sm={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              className="btn-padrao btn-verde btn-adicionar"
              onClick={handleAdicionar}
              disabled={!permissao?.inclui || formPrincipal.loading || loading}
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '30%' }}>
                <span>Núm. Cad. Vitícola</span>
              </th>
              <th className="lista-texto" style={{ width: '70%' }}>
                <span>Observacoes</span>
              </th>
              <th className="lista-acoes">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((pessoaProdutor) => {
              return (
                <tr
                  key={v4()}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{pessoaProdutor.numeroCadastroViticola}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{pessoaProdutor.observacoes}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(pessoaProdutor?.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default PessoaProdutorLista;
