import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '/configuracao/empresa/certificado',
        novo: '/configuracao/empresa/certificado',
        alterar: '/configuracao/empresa/certificado',
        excluir: '/configuracao/empresa/certificado',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
