import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './styles';

const PaginaNaoEcontrada: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <h1>Página não encontrada!</h1>
      <button
        type="button"
        onClick={() => {
          navigate('/home');
        }}
      >
        Voltar para Home!
      </button>
    </Container>
  );
};

export default PaginaNaoEcontrada;
