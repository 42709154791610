import { SplitButton } from 'react-bootstrap';
import styled from 'styled-components';

const SplitSalvar = styled(SplitButton)`
  > button {
    background: #05bd61 !important;
    color: #fff;
    font-weight: 600;
    border: 0;
    &:hover {
      background: #03a953 !important;
    }
    &:active {
      background: #038841 !important;
    }
    &:focus {
      background: #05bd61 !important;
      box-shadow: 0 0 0 0.2rem rgba(3, 168, 80, 0.25) !important;
    }
    &:disabled {
      opacity: 0.7;
      color: #b9b9b9;
      background: rgb(3, 136, 65, 0.2) !important;
    }
  }
`;

export default SplitSalvar;
