import React, { useEffect } from 'react';
import { FormCia } from '@elogestor/unformcia';
import { Col, Row } from 'react-bootstrap';
import { IJuncaoPessoasValoresAlterar } from '@elogestor/util';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import Divisor from '../../../../../Componentes/Divisor';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompletePessoa from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';

type IProps = IMain<IJuncaoPessoasValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,

  dadosRecuperados,

  dadosDuplicados,

  dadosObrigatorios,

  dadosPadrao,
}) => {
  const { formRef, handleCarregarDados, setIdDetalheRegistro } = UseForm();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosObrigatorios,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  return (
    <FormCia ref={formRef}>
      <Divisor>
        <Row>
          <Col xl={12} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaBase"
              label="Pessoa Base (a ser mantida)"
              nomeObjeto="pessoaBase"
              required
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaJuntarExcluir"
              label="Pessoa (a ser excluída)"
              nomeObjeto="pessoaJuntarExcluir"
              required
            />
          </Col>
        </Row>
      </Divisor>
    </FormCia>
  );
};

export default Main;
