import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '/suprimentos/compras/nota-fiscal-entrada/item',
        novo: '/suprimentos/compras/nota-fiscal-entrada',
        alterar: '/suprimentos/compras/nota-fiscal-entrada/item',
        excluir: '/suprimentos/compras/nota-fiscal-entrada/item',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
