import styled, { css } from 'styled-components';

interface IContainer {
  'data-is-dragging': number;
  width: number | null;
}

export const Container = styled.th<IContainer>`
  position: relative;
  &.asc div div > svg:first-child {
    opacity: 0;
  }

  &.desc div div > svg:last-child {
    opacity: 0;
  }
  cursor: grab;

  ${(props) =>
    props['data-is-dragging'] &&
    css`
      opacity: 0;
      cursor: grabbing;
    `}

  ${(props) => {
    if (!props.width)
      return css`
        width: auto;
      `;
    if (props.width <= 0)
      return css`
        width: 1px;
      `;

    return ``;
  }}
`;

export const Retangulo = styled.div`
  position: absolute;
  height: 100%;
  width: 16px;
  background: transparent;

  right: -8px;
  top: 0;
  z-index: 2;

  cursor: e-resize !important;
`;
