import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraParaPtBr,
  IOrdemProducaoLocalEstoqueLista,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompleteLote from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheLocalEstoque: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemProducao: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IOrdemProducaoLocalEstoqueLista[]>([]);
  const [listaValor, setListaValor] = useState<
    IOrdemProducaoLocalEstoqueLista[]
  >([]);

  const [showLotes, setShowLotes] = useState(true);

  const produtoObjeto =
    formPrincipal.formRef.current?.getFieldValueNomeObjeto('produto');

  const listaOrdemProducaoLocalEstoque =
    formPrincipal.formRef.current?.getFieldValue(
      'listaOrdemProducaoLocalEstoque'
    );

  const quantidadePrincipal =
    formPrincipal.formRef.current?.getFieldValue('quantidade');

  const handleAdicionar = useCallback(async () => {
    const idProduto = formPrincipal.formRef.current?.getFieldValue('idProduto');
    if (!idProduto) {
      ToastErro('Informe o Produto a ser Produzido!');
      formPrincipal.formRef.current?.setFieldError(
        'idProduto',
        'Informe o Produto a ser Produzido!'
      );

      return;
    }
    const data = formRefLista.current?.getDataDuplicar();

    if (!showLotes) {
      data.lote = null;
      data.idLote = null;
    } else {
      data.idLote = data.lote ? data.lote.id : null;
    }

    data.idLocalEstoque = data.localEstoque ? data.localEstoque.id : null;

    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [
    formPrincipal.formRef,
    formRefLista,
    handleSubmit,
    setIdDetalheRegistro,
    showLotes,
  ]);

  const handleSugerirQuantidade = useCallback(async () => {
    let somaQuantidadeLocalEstoque = 0;
    let diferencaQuantidadeLocalEstoque = 0;
    const dataHoraTermino =
      formPrincipal.formRef?.current?.getFieldValue('dataHoraTermino');
    if (listaOrdemProducaoLocalEstoque) {
      somaQuantidadeLocalEstoque += listaOrdemProducaoLocalEstoque.reduce(
        (acumulador: number, item: IOrdemProducaoLocalEstoqueLista) => {
          acumulador += Number(item.quantidade);
          return acumulador;
        },
        0
      );
    }

    if (quantidadePrincipal > 0) {
      diferencaQuantidadeLocalEstoque =
        quantidadePrincipal - somaQuantidadeLocalEstoque;
    }

    if (
      listaOrdemProducaoLocalEstoque.length > 0 &&
      quantidadePrincipal > 0 &&
      diferencaQuantidadeLocalEstoque > 0
    ) {
      formRefLista.current?.setFieldValue(
        'quantidade',
        diferencaQuantidadeLocalEstoque
      );
    } else if (
      listaOrdemProducaoLocalEstoque.length === 0 &&
      quantidadePrincipal > 0
    ) {
      formRefLista.current?.setFieldValue('quantidade', quantidadePrincipal);
    }

    if (dataHoraTermino && dataHoraTermino.getTime() < new Date().getTime()) {
      formRefLista.current?.setFieldValue('dataHoraReporte', dataHoraTermino);
    } else {
      formRefLista.current?.setFieldValue(
        'dataHoraReporte',
        new Date().SetarSegundosMilisegundosZero()
      );
    }
  }, [
    formPrincipal.formRef,
    formRefLista,
    listaOrdemProducaoLocalEstoque,
    quantidadePrincipal,
  ]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IOrdemProducaoLocalEstoqueLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (produtoObjeto) {
      setShowLotes(produtoObjeto.produtoEstoque.controlarLote);
    } else {
      setShowLotes(true);
    }
  }, [produtoObjeto]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Row>
            <Col xl={showLotes ? 3 : 5} lg={12}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoque"
                label="Local de Estoque"
                nomeObjeto="localEstoque"
                placeholder="Local de Estoque"
                onBlurInput={handleSugerirQuantidade}
              />
            </Col>
            <Col xl={2} lg={4} style={{ display: showLotes ? '' : 'none' }}>
              <InputAutoCompleteLote
                name="idLote"
                label="Lote"
                nomeObjeto="lote"
                placeholder="Lote"
                permitirAdicionar={false}
              />
            </Col>
            <Col xl={2} lg={4}>
              <InputDecimal
                label="Quantidade"
                placeholder="Quantidade"
                name="quantidade"
                casasDecimais={4}
                casasInteiras={15}
              />
            </Col>
            <Col xl={3} lg={4}>
              <InputDateTime
                label="Data e Hora de Reporte"
                name="dataHoraReporte"
              />
            </Col>
            <Col
              xl={2}
              lg={4}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <BtnPadraoButton
                type="button"
                onClick={handleAdicionar}
                className="btn-padrao btn-verde-claro btn-adicionar"
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAdd />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th
                    className="lista-texto"
                    style={{ width: showLotes ? '40%' : '60%' }}
                  >
                    <span>Local de Estoque</span>
                  </th>
                  {showLotes && (
                    <th className="lista-texto" style={{ width: '20%' }}>
                      <span>Lote</span>
                    </th>
                  )}
                  <th className="lista-valor" style={{ width: '20%' }}>
                    <span>Quantidade</span>
                  </th>
                  <th className="lista-data" style={{ width: '20%' }}>
                    <span>Data Hora Reporte</span>
                  </th>
                  <th className="lista-texto">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item, index) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">
                        {item.localEstoque?.descricao}
                      </td>
                      {showLotes && (
                        <td className="lista-texto">
                          {item.lote && item.lote.codigo}
                        </td>
                      )}
                      <td className="lista-valor">
                        {Number(item.quantidade).FormatarParaPtBr({
                          maximoCasasDecimais: 4,
                          minimoCasasDecimais: 4,
                        })}
                      </td>
                      <td className="lista-data">
                        {FormatarDataHoraParaPtBr(item.dataHoraReporte)}
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleExcluir(String(index));
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <IoMdRemoveCircleOutline
                                  style={{ marginRight: 5 }}
                                />
                                Remover
                              </span>
                            </Dropdown.Item>
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheLocalEstoque;
