import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/pedido-venda/pedido-venda-com-notas-vinculadas';

interface IPedidoVendaComNotasVinculadasRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoVendaComNotasVinculadasRelatorioComunicador {
  public async index(
    params: IPedidoVendaComNotasVinculadasRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoVendaComNotasVinculadasRelatorioComunicador();
