import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa/certificado/upload';

interface ITerminalCaixaEmpresaCertificadoUploadComunicador {
  params: any;
}

class TerminalCaixaEmpresaCertificadoUploadComunicador {
  public async update(
    params: ITerminalCaixaEmpresaCertificadoUploadComunicador
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new TerminalCaixaEmpresaCertificadoUploadComunicador();
