import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-portador/obter-conforme-criterios';

interface IRegraEscolhaPortadorObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia?: Date;
    idFormaPagamento?: string | null;
  };
}

class RegraEscolhaPortadorObterConformeCriteriosComunicador {
  public async show(
    params: IRegraEscolhaPortadorObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaPortadorObterConformeCriteriosComunicador();
