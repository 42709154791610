import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/seguro';
const rotaNovo = 'transporte/manifesto-transporte';

interface IManifestoTransporteSeguroComunicadorShow {
  id: string;
}

interface IManifestoTransporteSeguroComunicadorStore {
  idPai: string;
  params: any;
}

interface IManifestoTransporteSeguroComunicadorUpdate {
  id: string;
  params: any;
}

interface IManifestoTransporteSeguroComunicadorDelete {
  id: string;
}

class ManifestoTransporteSeguroComunicador {
  public async show(
    params: IManifestoTransporteSeguroComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IManifestoTransporteSeguroComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/seguro`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IManifestoTransporteSeguroComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IManifestoTransporteSeguroComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteSeguroComunicador();
