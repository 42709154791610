import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/configuracao-cr-enologico-cultivar/produto-configuraca-bebida-rastreabilidade-sisdevin';

interface IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorStore {
  idCodigoRastreabilidadeSisdevin?: string;
  idGrupoProdutoSisdevin?: string;
  idProdutoConfiguracaoBebidaSisdevin: string;
}

interface IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorDelete {
  id: string;
}

interface IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorUpdate {
  id: string;
  params: {
    ativo: boolean;
  };
}

class ConfiguracaoBebidaRastreabilidadeSisdevinComunicador {
  async store(
    params: IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params);
    return response.data;
  }

  async delete(
    params: IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }

  async update(
    params: IConfiguracaoBebidaRastreabilidadeSisdevinComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params);
    return response.data;
  }
}

export default new ConfiguracaoBebidaRastreabilidadeSisdevinComunicador();
