import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UseItemTab } from '../../../Hook/ItemTabHook';

const ItemImpostoRetidoTab: React.FC = () => {
  const { calcularImpostoRetido } = UseItemTab();

  return (
    <div>
      <Divisor>
        <h3>IR Retido</h3>
        <Row>
          <Col lg={3} md={12} sm={12}>
            <InputDecimalNulavel
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoRetido.baseCalculoIRRetido"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>

          <Col lg={3} md={12} sm={12}>
            <InputDecimalNulavel
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoRetido.aliquotaIRRetido"
              placeholder="Alíquota"
              casasInteiras={13}
              casasDecimais={2}
              onChangeValue={() => {
                calcularImpostoRetido();
              }}
            />
          </Col>

          <Col lg={3} md={12} sm={12}>
            <InputDecimalNulavel
              label="Valor"
              name="notaFiscalEntradaItemImpostoRetido.valorIRRetido"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              disabled
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoRetidoTab;
