import {
  FormatarEnum,
  IsSimplesNacional,
  ModalidadeBaseCalculoIcmsEnum,
  MotivoDesoneracaoIcmsEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputAutoCompleteOrigemIcms from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOrigemIcms';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsTab } from '../../../Hook/ItemIcmsTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRegimeTributario } from '../../../../../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';

const ItemImpostoIcmsTab: React.FC = () => {
  const { refresh, formRefDetalhe } = UseListaDetalheForm();
  const { camposEscondidosIcms, camposBloqueadosIcms } = UseItemIcmsTab();
  const { calcularImpostosPorCst, obterConfiguracaoTributaria } = UseItemTab();
  const { situacaoBloquearCampos, situacaoBloquearCamposContaRevertida } =
    UseSituacaoBloquearCampos();

  const { regimeTributario } = UseRegimeTributario();
  const [isEmitenteSimplesNacional, setIsEmitenteSimplesNacional] =
    useState(false);

  useEffect(() => {
    if (regimeTributario) {
      if (IsSimplesNacional(regimeTributario)) {
        setIsEmitenteSimplesNacional(true);
      } else {
        setIsEmitenteSimplesNacional(false);
      }
    }
  }, [isEmitenteSimplesNacional, regimeTributario]);

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterConfiguracaoTributaria = useCallback(() => {
    obterConfiguracaoTributaria();
  }, [obterConfiguracaoTributaria]);

  const handleAlterarModalidadeBaseCalculo = useCallback(() => {
    const calcular = formRefDetalhe.current?.getFieldValue(
      'notaFiscalSaidaItemImpostoIcms.calcularIcms'
    );

    const aliquota = formRefDetalhe.current?.getFieldValue(
      'notaFiscalSaidaItemImpostoIcms.aliquotaIcms'
    );

    const modalidadeBaseCalculo = formRefDetalhe.current?.getFieldValue(
      'notaFiscalSaidaItemImpostoIcms.modalidadeBaseCalculoIcms'
    );

    if (calcular && aliquota && !modalidadeBaseCalculo) {
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoIcms.modalidadeBaseCalculoIcms',
        ModalidadeBaseCalculoIcmsEnum.valorOperacao
      );
    }
  }, [formRefDetalhe]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteOrigemIcms
              label="Origem"
              name="notaFiscalSaidaItemImpostoIcms.idOrigemIcms"
              placeholder="Origem"
              nomeObjeto="notaFiscalSaidaItemImpostoIcms.origemIcms"
              onBlurInput={handleObterConfiguracaoTributaria}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CSOSN ou CST"
              name="notaFiscalSaidaItemImpostoIcms.idSituacaoTributaria"
              placeholder="CSOSN ou CST"
              nomeObjeto="notaFiscalSaidaItemImpostoIcms.situacaoTributaria"
              icms={!isEmitenteSimplesNacional}
              icmsSimplesNacional={isEmitenteSimplesNacional}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcms}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosIcms.calcularIcms ||
                  situacaoBloquearCampos ||
                  situacaoBloquearCamposContaRevertida
                }
                label="Calcular ICMS"
                name="notaFiscalSaidaItemImpostoIcms.calcularIcms"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) {
                    handleAlterarModalidadeBaseCalculo();
                    handleCalcularImpostosPorCst();
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            hidden={camposEscondidosIcms.modalidadeBaseCalculoIcms}
          >
            <Select
              disabled={
                camposBloqueadosIcms.modalidadeBaseCalculoIcms ||
                situacaoBloquearCampos
              }
              label="Modalidade da Base de Cálculo"
              name="notaFiscalSaidaItemImpostoIcms.modalidadeBaseCalculoIcms"
              options={FormatarEnum({
                enumObj: ModalidadeBaseCalculoIcmsEnum,
                nullavel: true,
              })}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualReducaoBaseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualReducaoBaseCalculoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Percentual Redução Base"
              name="notaFiscalSaidaItemImpostoIcms.percentualReducaoBaseCalculoIcms"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.baseCalculoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Base de Cálculo"
              name="notaFiscalSaidaItemImpostoIcms.baseCalculoIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.aliquotaIcms}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.aliquotaIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Alíquota"
              name="notaFiscalSaidaItemImpostoIcms.aliquotaIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={() => {
                handleAlterarModalidadeBaseCalculo();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorIcms}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Total"
              name="notaFiscalSaidaItemImpostoIcms.valorIcms"
              placeholder="Valor Total"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorSemDiferimentoIcms &&
          camposEscondidosIcms.percentualDiferimentoIcms &&
          camposEscondidosIcms.valorDiferidoIcms
        }
      >
        <h5>Diferimento</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorSemDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorSemDiferimentoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Sem Diferimento"
              name="notaFiscalSaidaItemImpostoIcms.valorSemDiferimentoIcms"
              placeholder="Valor Sem Diferimento"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualDiferimentoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Percentual Diferimento"
              name="notaFiscalSaidaItemImpostoIcms.percentualDiferimentoIcms"
              placeholder="Percentual Diferimento"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorDiferidoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorDiferidoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Diferido"
              name="notaFiscalSaidaItemImpostoIcms.valorDiferidoIcms"
              placeholder="Valor Diferido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorUnitarioPautaIcms &&
          camposEscondidosIcms.valorTotalPautaIcms
        }
      >
        <h5>Pauta</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorUnitarioPautaIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorUnitarioPautaIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Unitário"
              name="notaFiscalSaidaItemImpostoIcms.valorUnitarioPautaIcms"
              placeholder="Valor Unitário"
              casasInteiras={8}
              casasDecimais={10}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorTotalPautaIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorTotalPautaIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Total"
              name="notaFiscalSaidaItemImpostoIcms.valorTotalPautaIcms"
              placeholder="Total"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.baseCalculoFcpIcms &&
          camposEscondidosIcms.aliquotaFcpIcms &&
          camposEscondidosIcms.valorFcpIcms &&
          camposEscondidosIcms.valorIcmsEValorFcpIcms
        }
      >
        <h5>Fundo de Combate a Pobreza</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.baseCalculoFcpIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Base de Cálculo"
              name="notaFiscalSaidaItemImpostoIcms.baseCalculoFcpIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.aliquotaFcpIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Alíquota"
              name="notaFiscalSaidaItemImpostoIcms.aliquotaFcpIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorFcpIcms}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorFcpIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor"
              name="notaFiscalSaidaItemImpostoIcms.valorFcpIcms"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEValorFcpIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorIcmsEValorFcpIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor e Valor ICMS"
              name="notaFiscalSaidaItemImpostoIcms.valorIcmsEValorFcpIcms"
              placeholder="Valor e Valor ICMS"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsEfetivo &&
          camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo &&
          camposEscondidosIcms.baseCalculoIcmsEfetivo &&
          camposEscondidosIcms.aliquotaIcmsEfetivo &&
          camposEscondidosIcms.valorIcmsEfetivo
        }
      >
        <h5>Efetivo</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsEfetivo}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosIcms.calcularIcmsEfetivo ||
                  situacaoBloquearCampos ||
                  situacaoBloquearCamposContaRevertida
                }
                label="Calcular ICMS Efetivo"
                name="notaFiscalSaidaItemImpostoIcms.calcularIcmsEfetivo"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualReducaoBaseCalculoIcmsEfetivo ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Percentual Redução Base"
              name="notaFiscalSaidaItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.baseCalculoIcmsEfetivo ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Base de Cálculo"
              name="notaFiscalSaidaItemImpostoIcms.baseCalculoIcmsEfetivo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.aliquotaIcmsEfetivo ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Alíquota"
              name="notaFiscalSaidaItemImpostoIcms.aliquotaIcmsEfetivo"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorIcmsEfetivo ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor ICMS Efetivo"
              name="notaFiscalSaidaItemImpostoIcms.valorIcmsEfetivo"
              placeholder="Valor ICMS Efetivo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsSubstituto &&
          camposEscondidosIcms.valorUnitarioIcmsSubstituto &&
          camposEscondidosIcms.valorIcmsSubstituto
        }
      >
        <h5>Substituto</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsSubstituto}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosIcms.calcularIcmsSubstituto ||
                  situacaoBloquearCampos ||
                  situacaoBloquearCamposContaRevertida
                }
                label="Calcular ICMS Substituto"
                name="notaFiscalSaidaItemImpostoIcms.calcularIcmsSubstituto"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorUnitarioIcmsSubstituto}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorUnitarioIcmsSubstituto ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Unitário ICMS Substituto"
              name="notaFiscalSaidaItemImpostoIcms.valorUnitarioIcmsSubstituto"
              placeholder="Valor Unitário ICMS Substituto"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsSubstituto}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorIcmsSubstituto ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor ICMS Substituto"
              name="notaFiscalSaidaItemImpostoIcms.valorIcmsSubstituto"
              placeholder="Valor ICMS Substituto"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularCreditoIcmsSn &&
          camposEscondidosIcms.percentualCreditoIcmsSn &&
          camposEscondidosIcms.valorCreditoIcmsSn
        }
      >
        <Col
          lg={6}
          md={12}
          sm={12}
          className="div-checkbox"
          hidden={camposEscondidosIcms.calcularCreditoIcmsSn}
        >
          <div className="checkbox-container">
            <InputCheckbox
              disabled={
                camposBloqueadosIcms.calcularCreditoIcmsSn ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Calcular Aproveitamento Crédito ICMS SN."
              name="notaFiscalSaidaItemImpostoIcms.calcularCreditoIcmsSn"
              onChange={(event) => {
                refresh();
                if (event.target.checked) handleCalcularImpostosPorCst();
              }}
            />
          </div>
        </Col>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualCreditoIcmsSn}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualCreditoIcmsSn ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Percentual de Crédito"
              name="notaFiscalSaidaItemImpostoIcms.percentualCreditoIcmsSn"
              placeholder="Base de Cálculo"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorCreditoIcmsSn}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorCreditoIcmsSn ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor de Crédito"
              name="notaFiscalSaidaItemImpostoIcms.valorCreditoIcmsSn"
              placeholder="Valor de Crédito"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.motivoDesoneracaoIcms &&
          camposEscondidosIcms.valorDesoneracaoIcms
        }
      >
        <h5>Desoneração</h5>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            hidden={camposEscondidosIcms.motivoDesoneracaoIcms}
          >
            <Select
              disabled={
                camposBloqueadosIcms.motivoDesoneracaoIcms ||
                situacaoBloquearCampos
              }
              label="Motivo Desoneração"
              name="notaFiscalSaidaItemImpostoIcms.motivoDesoneracaoIcms"
              options={FormatarEnum({
                enumObj: MotivoDesoneracaoIcmsEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            hidden={camposEscondidosIcms.valorDesoneracaoIcms}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.valorDesoneracaoIcms ||
                situacaoBloquearCampos ||
                situacaoBloquearCamposContaRevertida
              }
              label="Valor Desoneração"
              name="notaFiscalSaidaItemImpostoIcms.valorDesoneracaoIcms"
              placeholder="Valor Desoneração"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoIcmsTab;
