import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa/produtor';
const rotaNovo = 'comercial/pessoas/pessoa';

interface IPessoaProdutorComunicadorIndex {
  idPessoa: string;
}

interface IPessoaProdutorComunicadorStore {
  params: any;
  idDetalheRegistro: string;
}

interface IPessoaProdutorComunicadorDelete {
  id: string;
}

class PessoaProdutorComunicador {
  public async index(params: IPessoaProdutorComunicadorIndex): Promise<any> {
    const response = await api.get(`${rota}/${params.idPessoa}`);

    return response.data;
  }

  public async store(params: IPessoaProdutorComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idDetalheRegistro}/produtor`,
      params.params
    );

    return response.data;
  }

  public async delete(params: IPessoaProdutorComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaProdutorComunicador();
