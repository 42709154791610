/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import {
  ConverterMilimitroParaPixel,
  FonteEtiquetaEnum,
  FormatarEnum,
  IEtiquetaElementoValoresInserir,
  IEtiquetaValoresAlterar,
  StringConverterParaEnum,
  TipoDataHoraEnum,
  TipoElementoEnum,
  TipoEtiquetaEnum,
  TipoPapelEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Draggable, { DraggableData } from 'react-draggable';
import { BiItalic } from 'react-icons/bi/index.mjs';
import {
  BsTextCenter,
  BsTextLeft,
  BsTextRight,
} from 'react-icons/bs/index.mjs';
import {
  AiOutlineVerticalAlignBottom,
  AiOutlineVerticalAlignMiddle,
  AiOutlineVerticalAlignTop,
} from 'react-icons/ai/index.mjs';
import { FaExchangeAlt } from 'react-icons/fa/index.mjs';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { BtnContainer } from '../../../../Suprimentos/Produtos/UnidadeMedida/Detalhe/Main/styles';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputInteiro from '../../../../../Componentes/Inputs/InputInteiro';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import Select from '../../../../../Componentes/Select';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import { DefaultInput, DivElemento } from './styles';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import EtiquetaElementoDisponivelComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/ElementoDisponivel/EtiquetaElementoDisponivelComunicador';
import { UseEtiquetaElemento } from '../Hooks/EtiquetaElementoHook';

type IProps = IMain<IEtiquetaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const optionsFonte = FormatarEnum({ enumObj: FonteEtiquetaEnum });
  const optionsTipoDataHora = FormatarEnum({ enumObj: TipoDataHoraEnum });

  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEtiqueta: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    setLoading,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const {
    listaEtiquetaElementoDisponivel,
    setListaEtiquetaElementoDisponivel,
    listaEtiquetaElemento,
    setListaEtiquetaElemento,
  } = UseEtiquetaElemento();

  const idDetalheRegistro = getIdDetalheRegistro();

  const tipoPapel = formRef.current?.getFieldValue('tipoPapel');
  const quantidadeEtiquetasLinha = formRef.current?.getFieldValue(
    'quantidadeEtiquetasLinha'
  );

  const larguraEtiqueta = ConverterMilimitroParaPixel(
    formRef.current?.getFieldValue('larguraEtiqueta')
  );
  const alturaEtiqueta = ConverterMilimitroParaPixel(
    formRef.current?.getFieldValue('alturaEtiqueta')
  );

  const nodeRef = useRef(null);
  const itemRef = useRef(null);
  const divEtiquetaRef = useRef<HTMLInputElement>(null);

  const [itemEtiquetaElementoSelecionado, setItemEtiquetaElementoSelecionado] =
    useState<IEtiquetaElementoValoresInserir>();
  const [itemEtiquetaElementoCopiado, setItemEtiquetaElementoCopiado] =
    useState<IEtiquetaElementoValoresInserir>();

  const [clickElemento, setClickElemento] = useState(false);
  const [linhaHorizontal, setLinhaHorizontal] = useState(true);
  const [linhaVertical, setLinhaVertical] = useState(false);

  const posicaoXRef = useRef<HTMLInputElement>(null);
  const posicaoYRef = useRef<HTMLInputElement>(null);
  const larguraRef = useRef<HTMLInputElement>(null);
  const alturaRef = useRef<HTMLInputElement>(null);

  const textoRef = useRef<HTMLInputElement>(null);
  const fonteRef = useRef<HTMLSelectElement>(null);
  const tamanhoFonteRef = useRef<HTMLInputElement>(null);

  const espessuraBordaDireitaRef = useRef<HTMLInputElement>(null);
  const espessuraBordaEsquerdaRef = useRef<HTMLInputElement>(null);
  const espessuraBordaSuperiorRef = useRef<HTMLInputElement>(null);
  const espessuraBordaInferiorRef = useRef<HTMLInputElement>(null);
  const espessuraRef = useRef<HTMLInputElement>(null);
  const arredondamentoBordaRef = useRef<HTMLInputElement>(null);

  const casasDecimaisRef = useRef<HTMLInputElement>(null);
  const numeroDigitosRef = useRef<HTMLInputElement>(null);
  const tipoDataHoraRef = useRef<HTMLSelectElement>(null);

  const inputRefImagem = useRef<HTMLInputElement>(null);

  const handleObterListaEtiquetaElementoDisponivel =
    useCallback(async (): Promise<void> => {
      try {
        setLoading(true);

        const tipoEtiqueta = formRef.current?.getFieldValue('tipoEtiqueta');

        const response = await EtiquetaElementoDisponivelComunicador.index({
          tipoEtiqueta,
        });
        setListaEtiquetaElementoDisponivel(response);

        setListaEtiquetaElemento([]);
        setItemEtiquetaElementoSelecionado(undefined);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [
      formRef,
      setListaEtiquetaElementoDisponivel,
      setListaEtiquetaElemento,
      setLoading,
    ]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
    handleObterListaEtiquetaElementoDisponivel,
  ]);

  // #region Elemento

  const handleStartElemento = (index: number): void => {
    const itemSelecionado = listaEtiquetaElementoDisponivel[index];

    if (itemSelecionado) {
      itemSelecionado.selecionado = true;
      refresh();
    }
  };

  const handleStopElemento = (index: number, data: DraggableData): void => {
    const itemSelecionado = listaEtiquetaElementoDisponivel[index];
    if (itemSelecionado) {
      const posicaoX = data.x;
      itemSelecionado.selecionado = false;

      if (posicaoX > 100) {
        const etiqueta: IEtiquetaElementoValoresInserir = {
          index: listaEtiquetaElemento.length,
          selecionado: false,

          posicaoX: 0,
          posicaoY: 0,
          largura: 120,
          altura: 20,

          inverterCores: false,

          espessuraBordaEsquerda: 0,
          espessuraBordaDireita: 0,
          espessuraBordaSuperior: 0,
          espessuraBordaInferior: 0,
          espessura: 0,
          arredondamentoBorda: 0,

          etiquetaElementoDisponivel: itemSelecionado,
        };

        if (
          itemSelecionado.tipoElemento === TipoElementoEnum.textoLivre ||
          itemSelecionado.tipoElemento === TipoElementoEnum.campo ||
          itemSelecionado.tipoElemento === TipoElementoEnum.cnpjCpf ||
          itemSelecionado.tipoElemento === TipoElementoEnum.cep ||
          itemSelecionado.tipoElemento === TipoElementoEnum.data ||
          itemSelecionado.tipoElemento === TipoElementoEnum.dataHora ||
          itemSelecionado.tipoElemento === TipoElementoEnum.chaveAcesso ||
          itemSelecionado.tipoElemento === TipoElementoEnum.telefone ||
          itemSelecionado.tipoElemento === TipoElementoEnum.numeroInteiro ||
          itemSelecionado.tipoElemento === TipoElementoEnum.numeroDecimal
        ) {
          etiqueta.texto = itemSelecionado.descricaoElemento;
          etiqueta.fonte = FonteEtiquetaEnum.tinos;
          etiqueta.tamanhoFonte = 10;

          etiqueta.negrito = false;
          etiqueta.italico = false;
          etiqueta.sublinhado = false;

          etiqueta.alinharEsquerda = true;
          etiqueta.alinharCentro = false;
          etiqueta.alinharDireita = false;

          etiqueta.justificarSuperior = false;
          etiqueta.justificarCentro = true;
          etiqueta.justificarInferior = false;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.formaLinha) {
          etiqueta.largura = Number(larguraEtiqueta).Arredondar(0);
          etiqueta.altura = 1;
          etiqueta.espessuraBordaEsquerda = 2;
          etiqueta.espessuraBordaSuperior = 2;
          etiqueta.espessura = 2;
        }

        if (
          itemSelecionado.tipoElemento ===
          TipoElementoEnum.formaQuadradoRetangulo
        ) {
          etiqueta.largura = 50;
          etiqueta.altura = 50;
          etiqueta.espessuraBordaEsquerda = 1;
          etiqueta.espessuraBordaDireita = 1;
          etiqueta.espessuraBordaSuperior = 1;
          etiqueta.espessuraBordaInferior = 1;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.formaCirculo) {
          etiqueta.largura = 50;
          etiqueta.altura = 50;
          etiqueta.espessuraBordaEsquerda = 1;
          etiqueta.espessuraBordaDireita = 1;
          etiqueta.espessuraBordaSuperior = 1;
          etiqueta.espessuraBordaInferior = 1;
          etiqueta.arredondamentoBorda = 100;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.imagem) {
          etiqueta.urlImagem = '';
          etiqueta.largura = 50;
          etiqueta.altura = 50;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.codigoBarras) {
          etiqueta.texto = itemSelecionado.descricaoElemento;
          etiqueta.largura = 100;
          etiqueta.altura = 50;

          etiqueta.tamanhoFonte = 10;
          etiqueta.alinharCentro = true;
          etiqueta.justificarCentro = true;
        }

        if (
          itemSelecionado.tipoElemento ===
            TipoElementoEnum.codigoBarrasCodigoProduto ||
          itemSelecionado.tipoElemento ===
            TipoElementoEnum.codigoBarrasInicioCodigoProduto
        ) {
          etiqueta.texto = itemSelecionado.descricaoElemento;
          etiqueta.largura = 100;
          etiqueta.altura = 50;
          etiqueta.numeroDigitos = 14;

          etiqueta.tamanhoFonte = 10;
          etiqueta.alinharCentro = true;
          etiqueta.justificarCentro = true;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.numeroInteiro) {
          etiqueta.alinharEsquerda = false;
          etiqueta.alinharCentro = false;
          etiqueta.alinharDireita = true;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.numeroDecimal) {
          etiqueta.casasDecimais = 2;

          etiqueta.alinharEsquerda = false;
          etiqueta.alinharCentro = false;
          etiqueta.alinharDireita = true;
        }

        if (itemSelecionado.tipoElemento === TipoElementoEnum.dataHora) {
          etiqueta.tipoDataHora = TipoDataHoraEnum.dataHora;
        }

        setListaEtiquetaElemento([...listaEtiquetaElemento, etiqueta]);
      }

      refresh();
    }
  };

  // #endregion Elemento

  // #region Etiqueta

  const handleStartEtiqueta = (index: number): void => {
    const itemSelecionado = listaEtiquetaElemento[index];
    if (itemSelecionado) {
      itemSelecionado.selecionado = true;
      setItemEtiquetaElementoSelecionado(itemSelecionado);

      if (posicaoXRef.current) {
        posicaoXRef.current.value = String(itemSelecionado.posicaoX);
      }
      if (posicaoYRef.current) {
        posicaoYRef.current.value = String(itemSelecionado.posicaoY);
      }
      if (larguraRef.current) {
        larguraRef.current.value = String(itemSelecionado.largura);
      }
      if (alturaRef.current) {
        alturaRef.current.value = String(itemSelecionado.altura);
      }

      if (textoRef.current) {
        textoRef.current.value = itemSelecionado.texto
          ? itemSelecionado.texto
          : '';
      }
      if (fonteRef.current) {
        fonteRef.current.value = String(itemSelecionado.fonte);
      }
      if (tamanhoFonteRef.current) {
        tamanhoFonteRef.current.value = String(itemSelecionado.tamanhoFonte);
      }

      if (espessuraBordaEsquerdaRef.current) {
        espessuraBordaEsquerdaRef.current.value = String(
          itemSelecionado.espessuraBordaEsquerda
        );
      }
      if (espessuraBordaDireitaRef.current) {
        espessuraBordaDireitaRef.current.value = String(
          itemSelecionado.espessuraBordaDireita
        );
      }
      if (espessuraBordaSuperiorRef.current) {
        espessuraBordaSuperiorRef.current.value = String(
          itemSelecionado.espessuraBordaSuperior
        );
      }
      if (espessuraBordaInferiorRef.current) {
        espessuraBordaInferiorRef.current.value = String(
          itemSelecionado.espessuraBordaInferior
        );
      }
      if (espessuraRef.current) {
        espessuraRef.current.value = String(itemSelecionado.espessura);
      }
      if (arredondamentoBordaRef.current) {
        arredondamentoBordaRef.current.value = String(
          itemSelecionado.arredondamentoBorda
        );
      }
      if (casasDecimaisRef.current) {
        casasDecimaisRef.current.value = String(itemSelecionado.casasDecimais);
      }
      if (tipoDataHoraRef.current) {
        tipoDataHoraRef.current.value = String(itemSelecionado.tipoDataHora);
      }

      if (inputRefImagem.current) {
        inputRefImagem.current.value = '';
      }

      if (itemSelecionado.altura > itemSelecionado.largura) {
        setLinhaVertical(true);
        setLinhaHorizontal(false);
      } else {
        setLinhaHorizontal(true);
        setLinhaVertical(false);
      }

      refresh();
    }
  };

  const handleStopEtiqueta = (index: number): void => {
    const itemSelecionado = listaEtiquetaElemento[index];

    if (itemSelecionado) {
      itemSelecionado.selecionado = false;

      if (
        itemSelecionado.posicaoX < 0 ||
        itemSelecionado.posicaoY < 0 ||
        itemSelecionado.posicaoY > alturaEtiqueta ||
        itemSelecionado.posicaoX > larguraEtiqueta
      ) {
        listaEtiquetaElemento.splice(index, 1);
        setItemEtiquetaElementoSelecionado(undefined);
      }

      refresh();
    }
  };

  const trackPositionEtiqueta = (index: number, data: DraggableData): void => {
    const itemSelecionado = listaEtiquetaElemento[index];
    if (itemSelecionado) {
      itemSelecionado.posicaoX = data.x;
      itemSelecionado.posicaoY = data.y;

      if (posicaoXRef.current) {
        posicaoXRef.current.value = String(itemSelecionado.posicaoX);
      }
      if (posicaoYRef.current) {
        posicaoYRef.current.value = String(itemSelecionado.posicaoY);
      }

      refresh();
    }
  };

  const handleOnSelecionarImagem = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        try {
          setLoading(true);

          if (itemEtiquetaElementoSelecionado) {
            itemEtiquetaElementoSelecionado.file = file;

            const reader = new FileReader();
            reader.onloadend = () => {
              if (reader.result) {
                const base64String = (reader.result as any)
                  .replace('data:', '')
                  .replace(/^.+,/, '');

                let tipo = 'png';
                const contentType = file.type?.split('/');
                if (contentType && contentType.length > 1) {
                  tipo = contentType[1];
                }

                const url = `data:image/${tipo};base64,${base64String}`;
                listaEtiquetaElemento[
                  Number(itemEtiquetaElementoSelecionado?.index)
                ].urlImagem = url;
              }
            };
            reader.readAsDataURL(file);
          }

          setLoading(false);
        } catch (error) {
          ToastErro(error);
          setLoading(false);
        }
      }
    },
    [itemEtiquetaElementoSelecionado, listaEtiquetaElemento, setLoading]
  );

  // #endregion Etiqueta

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // const obterTamanhoEtiqueta = (): number => {
  //   return Number(divEtiquetaRef.current?.offsetWidth) - 250;
  // };

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Input
                ref={inputRefFocus}
                name="descricaoModeloEtiqueta"
                label="Descrição do Modelo de Etiqueta"
                placeholder="Descrição do Modelo de Etiqueta"
                maxLength={60}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo de Etiqueta"
                name="tipoEtiqueta"
                options={FormatarEnum({
                  enumObj: TipoEtiquetaEnum,
                  nullavel: false,
                })}
                onChange={() => {
                  refresh();
                  handleObterListaEtiquetaElementoDisponivel();
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo de Papel"
                name="tipoPapel"
                options={FormatarEnum({
                  enumObj: TipoPapelEnum,
                  nullavel: false,
                })}
                onChange={(e) => {
                  if (e.currentTarget.value === TipoPapelEnum.a4) {
                    formRef.current?.setFieldValue('larguraTotal', 210);
                    formRef.current?.setFieldValue('alturaTotal', 297);
                  } else {
                    formRef.current?.setFieldValue('margemEsquerda', undefined);
                    formRef.current?.setFieldValue('margemDireita', undefined);
                    formRef.current?.setFieldValue('margemSuperior', undefined);
                    formRef.current?.setFieldValue('margemInferior', undefined);
                  }
                  refresh();
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiro
                label="Qtde. de Etiquetas por Linha"
                name="quantidadeEtiquetasLinha"
                placeholder="Quantidade de Etiquetas por Linha"
                onChange={() => {
                  refresh();
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col
              lg={2}
              md={6}
              sm={12}
              className={tipoPapel === TipoPapelEnum.a4 ? '' : 'hidden'}
            >
              <InputInteiroNulavel
                name="margemEsquerda"
                label="Margem Esquerda (mm)"
                placeholder="Margem Esquerda (mm)"
              />
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              className={tipoPapel === TipoPapelEnum.a4 ? '' : 'hidden'}
            >
              <InputInteiroNulavel
                name="margemDireita"
                label="Margem Direita (mm)"
                placeholder="Margem Direita (mm)"
              />
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              className={tipoPapel === TipoPapelEnum.a4 ? '' : 'hidden'}
            >
              <InputInteiroNulavel
                name="margemSuperior"
                label="Margem Superior (mm)"
                placeholder="Margem Superior (mm)"
              />
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              className={tipoPapel === TipoPapelEnum.a4 ? '' : 'hidden'}
            >
              <InputInteiroNulavel
                name="margemInferior"
                label="Margem Inferior (mm)"
                placeholder="Margem Inferior (mm)"
              />
            </Col>

            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                name="larguraEtiqueta"
                label="Largura Etiqueta (mm)"
                placeholder="Largura Etiqueta (mm)"
                onBlur={(event) => {
                  if (quantidadeEtiquetasLinha <= 1) {
                    formRef.current?.setFieldValue(
                      'larguraTotal',
                      event.currentTarget.value
                    );
                  }
                  refresh();
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                name="alturaEtiqueta"
                label="Altura Etiqueta (mm)"
                placeholder="Altura Etiqueta (mm)"
                onBlur={(event) => {
                  if (quantidadeEtiquetasLinha <= 1) {
                    formRef.current?.setFieldValue(
                      'alturaTotal',
                      event.currentTarget.value
                    );
                  }
                  refresh();
                }}
              />
            </Col>

            <Col
              lg={2}
              md={6}
              sm={12}
              className={
                tipoPapel === TipoPapelEnum.bobina &&
                quantidadeEtiquetasLinha > 1
                  ? ''
                  : 'hidden'
              }
            >
              <InputInteiroNulavel
                name="larguraTotal"
                label="Largura Total (mm)"
                placeholder="Largura Total (mm)"
              />
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              className={
                tipoPapel === TipoPapelEnum.bobina &&
                quantidadeEtiquetasLinha > 1
                  ? ''
                  : 'hidden'
              }
            >
              <InputInteiroNulavel
                name="alturaTotal"
                label="Altura Total (mm)"
                placeholder="Altura Total (mm)"
              />
            </Col>

            <Col
              lg={2}
              md={6}
              sm={12}
              className={quantidadeEtiquetasLinha > 1 ? '' : 'hidden'}
            >
              <InputInteiro
                name="larguraEntreEtiquetas"
                label="Largura entre Etiquetas (mm)"
                placeholder="Largura entre Etiquetas (mm)"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiro
                name="alturaEntreEtiquetas"
                label="Altura entre Etiquetas (mm)"
                placeholder="Altura entre Etiquetas (mm)"
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor
          ref={divEtiquetaRef}
          style={{
            display:
              larguraEtiqueta > 0 && alturaEtiqueta > 0 ? 'flex' : 'none',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            paddingBottom: 20,
          }}
        >
          <div style={{ width: 250, height: '100%', flexDirection: 'column' }}>
            <div
              style={{
                width: '100%',
                height: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                borderRadius: 5,
              }}
            >
              <h6 style={{ margin: 0, fontWeight: 'bold' }}>ELEMENTOS</h6>
            </div>

            <div
              style={{
                width: '100%',
                height: alturaEtiqueta + 100,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderRadius: 5,
                padding: 10,
                overflow: 'auto',
                overflowX: 'hidden',
              }}
            >
              {listaEtiquetaElementoDisponivel.length > 0 &&
                listaEtiquetaElementoDisponivel.map((item, index) => {
                  return (
                    <Draggable
                      nodeRef={nodeRef}
                      onStart={() => {
                        handleStartElemento(index);
                      }}
                      onStop={(e, data) => {
                        handleStopElemento(index, data);
                      }}
                      position={{ x: 0, y: 0 }}
                    >
                      <DivElemento
                        ref={nodeRef}
                        className="box"
                        style={{
                          opacity: item.selecionado ? '0.4' : '1',
                          backgroundColor:
                            item.tipoElemento === TipoElementoEnum.textoLivre ||
                            item.tipoElemento === TipoElementoEnum.formaLinha ||
                            item.tipoElemento ===
                              TipoElementoEnum.formaQuadradoRetangulo ||
                            item.tipoElemento ===
                              TipoElementoEnum.formaCirculo ||
                            item.tipoElemento ===
                              TipoElementoEnum.codigoBarras ||
                            item.tipoElemento ===
                              TipoElementoEnum.codigoBarrasCodigoProduto ||
                            item.tipoElemento === TipoElementoEnum.imagem
                              ? '#40E0D0'
                              : '#00CED1',
                        }}
                      >
                        <div>{item.descricaoElemento}</div>
                      </DivElemento>
                    </Draggable>
                  );
                })}
            </div>
          </div>

          <div
            style={{
              // width: obterTamanhoEtiqueta(),
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              marginLeft: 10,
            }}
          >
            <div
              style={{
                width: '100%',
                height: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                borderRadius: 5,
              }}
            >
              <h6 style={{ margin: 0, fontWeight: 'bold' }}>ETIQUETA</h6>
            </div>

            <div
              style={{
                width: '100% ',
                height: '50%',
                paddingTop: 50,
                paddingBottom: 50,
                borderRadius: 5,
                backgroundColor: 'lightgrey',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: larguraEtiqueta,
                  height: alturaEtiqueta,
                  borderRadius: 5,
                  backgroundColor: 'white',
                  margin: 'auto',
                  position: 'relative',
                }}
                onClick={() => {
                  if (!clickElemento) {
                    setItemEtiquetaElementoSelecionado(undefined);
                  }
                  setClickElemento(false);
                }}
              >
                {listaEtiquetaElemento.length > 0 &&
                  listaEtiquetaElemento.map((item, index) => {
                    return (
                      <Draggable
                        nodeRef={nodeRef}
                        onDrag={(e, data) => {
                          trackPositionEtiqueta(index, data);
                        }}
                        onStart={() => {
                          handleStartEtiqueta(index);
                          setClickElemento(true);
                        }}
                        onStop={() => {
                          handleStopEtiqueta(index);
                        }}
                        position={{
                          x: item.posicaoX,
                          y: item.posicaoY,
                        }}
                      >
                        <div
                          onKeyDown={(e) => {
                            e.stopPropagation();

                            if (itemEtiquetaElementoSelecionado) {
                              if (e.key === 'Delete' || e.key === 'Backspace') {
                                listaEtiquetaElemento.splice(index, 1);
                                setItemEtiquetaElementoSelecionado(undefined);
                              } else if (e.shiftKey) {
                                switch (e.key) {
                                  case 'ArrowUp':
                                    if (alturaRef && alturaRef.current) {
                                      let valor =
                                        Number(alturaRef.current.value) - 1;

                                      if (valor < 0) valor = 0;

                                      alturaRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.altura =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowDown':
                                    if (
                                      itemEtiquetaElementoSelecionado
                                        .etiquetaElementoDisponivel
                                        ?.tipoElemento ===
                                        TipoElementoEnum.formaLinha &&
                                      linhaHorizontal
                                    ) {
                                      return;
                                    }

                                    if (alturaRef && alturaRef.current) {
                                      const valor =
                                        Number(alturaRef.current.value) + 1;

                                      alturaRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.altura =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowLeft':
                                    if (larguraRef && larguraRef.current) {
                                      let valor =
                                        Number(larguraRef.current.value) - 1;

                                      if (valor < 0) valor = 0;

                                      larguraRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.largura =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowRight':
                                    if (
                                      itemEtiquetaElementoSelecionado
                                        .etiquetaElementoDisponivel
                                        ?.tipoElemento ===
                                        TipoElementoEnum.formaLinha &&
                                      linhaVertical
                                    ) {
                                      return;
                                    }

                                    if (larguraRef && larguraRef.current) {
                                      const valor =
                                        Number(larguraRef.current.value) + 1;

                                      larguraRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.largura =
                                        valor;
                                    }
                                    break;
                                }
                              } else if (e.ctrlKey) {
                                switch (e.key) {
                                  case 'ArrowUp':
                                    if (posicaoYRef && posicaoYRef.current) {
                                      let valor =
                                        Number(posicaoYRef.current.value) - 1;

                                      if (valor < 0) valor = 0;

                                      posicaoYRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.posicaoY =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowDown':
                                    if (posicaoYRef && posicaoYRef.current) {
                                      let valor =
                                        Number(posicaoYRef.current.value) + 1;

                                      if (valor > alturaEtiqueta)
                                        valor = alturaEtiqueta.Arredondar(0);

                                      posicaoYRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.posicaoY =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowLeft':
                                    if (posicaoXRef && posicaoXRef.current) {
                                      let valor =
                                        Number(posicaoXRef.current.value) - 1;

                                      if (valor < 0) valor = 0;

                                      posicaoXRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.posicaoX =
                                        valor;
                                    }
                                    break;

                                  case 'ArrowRight':
                                    if (posicaoXRef && posicaoXRef.current) {
                                      let valor =
                                        Number(posicaoXRef.current.value) + 1;

                                      if (valor > larguraEtiqueta)
                                        valor = larguraEtiqueta.Arredondar(0);

                                      posicaoXRef.current.value = String(valor);
                                      itemEtiquetaElementoSelecionado.posicaoX =
                                        valor;
                                    }
                                    break;

                                  case 'c':
                                  case 'C':
                                    setItemEtiquetaElementoCopiado({
                                      ...itemEtiquetaElementoSelecionado,
                                    });
                                    break;

                                  case 'v':
                                  case 'V':
                                    if (itemEtiquetaElementoCopiado) {
                                      const novoItem = {
                                        ...itemEtiquetaElementoCopiado,
                                        id: undefined,
                                        index: listaEtiquetaElemento.length,
                                        posicaoX: 0,
                                        posicaoY: 0,
                                        urlImagem: undefined,
                                        file: undefined,
                                      };

                                      setListaEtiquetaElemento([
                                        ...listaEtiquetaElemento,
                                        novoItem,
                                      ]);
                                    }
                                    break;
                                }
                              }
                            }

                            refresh();
                          }}
                          tabIndex={0}
                          ref={itemRef}
                          style={{
                            position: 'absolute',
                            cursor: 'move',
                            display: 'flex',
                            opacity: item.selecionado ? '0.4' : '1',
                            justifyContent: item.alinharEsquerda
                              ? 'flex-start'
                              : item.alinharDireita
                                ? 'flex-end'
                                : 'center',
                            textAlign: item.alinharEsquerda
                              ? 'start'
                              : item.alinharDireita
                                ? 'end'
                                : 'center',
                            alignItems: item.justificarCentro
                              ? 'center'
                              : item.justificarInferior
                                ? 'flex-end'
                                : 'flex-start',
                            width: item.largura,
                            height: item.altura,
                            fontSize: `${Number(item.tamanhoFonte).Arredondar(
                              1
                            )}pt`,
                            fontWeight: item.negrito ? 'bold' : 'normal',
                            fontStyle: item.italico ? 'italic' : 'normal',
                            textDecorationLine: item.sublinhado
                              ? 'underline'
                              : 'none',
                            borderRight: `${item.espessuraBordaDireita}px solid`,
                            borderLeft: `${item.espessuraBordaEsquerda}px solid`,
                            borderTop: `${item.espessuraBordaSuperior}px solid`,
                            borderBottom: `${item.espessuraBordaInferior}px solid`,
                            borderRadius: item.arredondamentoBorda,
                            backgroundColor: item.inverterCores
                              ? 'black'
                              : '#ebebeb',
                            color: item.inverterCores ? 'white' : 'black',
                            fontFamily: item.fonte,
                            boxShadow:
                              item.index ===
                              itemEtiquetaElementoSelecionado?.index
                                ? '4px 3px 8px 1px #969696'
                                : 'none',
                          }}
                        >
                          {item.etiquetaElementoDisponivel?.tipoElemento !==
                          TipoElementoEnum.imagem ? (
                            <div>{item.texto}</div>
                          ) : (
                            <img
                              style={{
                                maxWidth: item.largura,
                                maxHeight: item.altura,
                                cursor: 'move',
                              }}
                              src={item.urlImagem}
                              alt=""
                            />
                          )}
                        </div>
                      </Draggable>
                    );
                  })}
              </div>
            </div>
          </div>
        </Divisor>

        <Divisor
          style={{ display: itemEtiquetaElementoSelecionado ? '' : 'none' }}
        >
          <h6 style={{ fontWeight: 'bold' }}>PROPRIEDADES</h6>

          <Row
            style={{
              display:
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaLinha ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.imagem ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarras ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarrasCodigoProduto
                  ? 'none'
                  : '',
            }}
          >
            <Col
              xl={2}
              lg={6}
              md={6}
              sm={6}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento ===
                    TipoElementoEnum.formaQuadradoRetangulo ||
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaCirculo
                    ? 'none'
                    : '',
              }}
            >
              <button
                data-tip
                data-for="negritoToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor: !itemEtiquetaElementoSelecionado?.negrito
                    ? '#66b9c8'
                    : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.negrito =
                      !itemEtiquetaElementoSelecionado.negrito;
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    width: 16,
                    height: 16,
                  }}
                >
                  N
                </span>
              </button>
              <ReactTooltip id="negritoToolTip" place="top">
                Negrito
              </ReactTooltip>

              <button
                data-tip
                data-for="italicoToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor: !itemEtiquetaElementoSelecionado?.italico
                    ? '#66b9c8'
                    : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.italico =
                      !itemEtiquetaElementoSelecionado.italico;
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              >
                <span style={{ display: 'flex' }}>
                  <BiItalic />
                </span>
              </button>
              <ReactTooltip id="italicoToolTip" place="top">
                Itálico
              </ReactTooltip>

              <button
                data-tip
                data-for="sublinhadoToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor: !itemEtiquetaElementoSelecionado?.sublinhado
                    ? '#66b9c8'
                    : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.sublinhado =
                      !itemEtiquetaElementoSelecionado.sublinhado;
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    width: 16,
                    height: 16,
                    textDecorationLine: 'underline',
                  }}
                >
                  S
                </span>
              </button>
              <ReactTooltip id="sublinhadoToolTip" place="top">
                Sublinhado
              </ReactTooltip>
            </Col>

            <Col
              xl={2}
              lg={6}
              md={6}
              sm={6}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento ===
                    TipoElementoEnum.formaQuadradoRetangulo ||
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaCirculo
                    ? 'none'
                    : '',
              }}
            >
              <button
                data-tip
                data-for="alinharEsquerdaToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.alinharEsquerda
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.alinharEsquerda =
                      !itemEtiquetaElementoSelecionado.alinharEsquerda;
                    itemEtiquetaElementoSelecionado.alinharCentro = false;
                    itemEtiquetaElementoSelecionado.alinharDireita = false;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.alinharEsquerda
                }
              >
                <span style={{ display: 'flex' }}>
                  <BsTextLeft />
                </span>
              </button>
              <ReactTooltip id="alinharEsquerdaToolTip" place="top">
                Alinhar à Esquerda
              </ReactTooltip>

              <button
                data-tip
                data-for="alinharCentroToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.alinharCentro
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.alinharEsquerda = false;
                    itemEtiquetaElementoSelecionado.alinharCentro =
                      !itemEtiquetaElementoSelecionado.alinharCentro;
                    itemEtiquetaElementoSelecionado.alinharDireita = false;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.alinharCentro
                }
              >
                <span style={{ display: 'flex' }}>
                  <BsTextCenter />
                </span>
              </button>
              <ReactTooltip id="alinharCentroToolTip" place="top">
                Centralizar
              </ReactTooltip>

              <button
                data-tip
                data-for="alinharDireitaToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.alinharDireita
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.alinharEsquerda = false;
                    itemEtiquetaElementoSelecionado.alinharCentro = false;
                    itemEtiquetaElementoSelecionado.alinharDireita =
                      !itemEtiquetaElementoSelecionado.alinharDireita;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.alinharDireita
                }
              >
                <span style={{ display: 'flex' }}>
                  <BsTextRight />
                </span>
              </button>
              <ReactTooltip id="alinharDireitaToolTip" place="top">
                Alinhar à Direita
              </ReactTooltip>
            </Col>

            <Col
              xl={2}
              lg={6}
              md={6}
              sm={6}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento ===
                    TipoElementoEnum.formaQuadradoRetangulo ||
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaCirculo
                    ? 'none'
                    : '',
              }}
            >
              <button
                data-tip
                data-for="justificarSuperiorToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.justificarSuperior
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.justificarSuperior =
                      !itemEtiquetaElementoSelecionado.justificarSuperior;
                    itemEtiquetaElementoSelecionado.justificarCentro = false;
                    itemEtiquetaElementoSelecionado.justificarInferior = false;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.justificarSuperior
                }
              >
                <span style={{ display: 'flex' }}>
                  <AiOutlineVerticalAlignTop />
                </span>
              </button>
              <ReactTooltip id="justificarSuperiorToolTip" place="top">
                Justificar Superior
              </ReactTooltip>

              <button
                data-tip
                data-for="justificarCentroToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.justificarCentro
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.justificarSuperior = false;
                    itemEtiquetaElementoSelecionado.justificarCentro =
                      !itemEtiquetaElementoSelecionado.justificarCentro;
                    itemEtiquetaElementoSelecionado.justificarInferior = false;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.justificarCentro
                }
              >
                <span style={{ display: 'flex' }}>
                  <AiOutlineVerticalAlignMiddle />
                </span>
              </button>
              <ReactTooltip id="justificarCentroToolTip" place="top">
                Justificar Centralizado
              </ReactTooltip>

              <button
                data-tip
                data-for="justificarInferiorToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.justificarInferior
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.justificarSuperior = false;
                    itemEtiquetaElementoSelecionado.justificarCentro = false;
                    itemEtiquetaElementoSelecionado.justificarInferior =
                      !itemEtiquetaElementoSelecionado.justificarInferior;
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  itemEtiquetaElementoSelecionado.justificarInferior
                }
              >
                <span style={{ display: 'flex' }}>
                  <AiOutlineVerticalAlignBottom />
                </span>
              </button>
              <ReactTooltip id="justificarInferiorToolTip" place="top">
                Justificar Inferior
              </ReactTooltip>
            </Col>

            <Col xl={2} lg={6} md={6} sm={6}>
              <button
                data-tip
                data-for="inverterCoresToolTip"
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{
                  marginRight: 20,
                  marginTop: 5,
                  padding: 6,
                  backgroundColor:
                    !itemEtiquetaElementoSelecionado?.inverterCores
                      ? '#66b9c8'
                      : '#3d707a',
                  opacity: 1,
                }}
                onClick={() => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.inverterCores =
                      !itemEtiquetaElementoSelecionado.inverterCores;
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 16,
                  }}
                >
                  <FaExchangeAlt />
                </span>
              </button>
              <ReactTooltip id="inverterCoresToolTip" place="top">
                Inverter as Cores
              </ReactTooltip>
            </Col>
          </Row>

          <Row>
            <Col
              xl={3}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.imagem
                    ? ''
                    : 'none',
              }}
            >
              <button
                type="button"
                className="btn-padrao btn-azul-claro"
                style={{ minWidth: 200, padding: 6, opacity: 1 }}
                onClick={() => {
                  inputRefImagem.current?.click();
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 16,
                  }}
                >
                  ... selecionar imagem
                </span>
              </button>

              <input
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
                ref={inputRefImagem}
                onChange={handleOnSelecionarImagem}
              />
            </Col>
          </Row>

          <Row
            style={{
              display:
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaLinha ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaQuadradoRetangulo ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaCirculo ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.imagem ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarras ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarrasCodigoProduto
                  ? 'none'
                  : '',
            }}
          >
            <Col xl={6} lg={8} md={8} sm={12}>
              <label>Texto</label>
              <DefaultInput
                type="text"
                ref={textoRef}
                defaultValue={itemEtiquetaElementoSelecionado?.texto}
                onChange={(event) => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.texto = event.target.value;
                    refresh();
                  }
                }}
                maxLength={60}
                disabled={
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.campo
                }
              />
            </Col>
          </Row>

          <Row
            style={{
              display:
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaLinha ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaQuadradoRetangulo ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.formaCirculo ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.imagem ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarras ||
                itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                  ?.tipoElemento === TipoElementoEnum.codigoBarrasCodigoProduto
                  ? 'none'
                  : '',
            }}
          >
            <Col xl={4} lg={5} md={8} sm={12}>
              <label>Fonte</label>
              <select
                ref={fonteRef}
                onChange={(event) => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.fonte =
                      StringConverterParaEnum<
                        typeof FonteEtiquetaEnum,
                        FonteEtiquetaEnum
                      >(FonteEtiquetaEnum, String(event.target.value));
                    refresh();
                  }
                }}
              >
                {optionsFonte.map((option) => {
                  return (
                    <option
                      className={option.hidden ? 'hidden' : ''}
                      value={option.value}
                      key={option.title}
                    >
                      {option.title}
                    </option>
                  );
                })}
              </select>
            </Col>

            <Col xl={2} lg={3} md={4} sm={12}>
              <label>Tam</label>
              <DefaultInput
                type="number"
                ref={tamanhoFonteRef}
                defaultValue={itemEtiquetaElementoSelecionado?.tamanhoFonte}
                onChange={(event) => {
                  const valor = Number(event.target.value).Arredondar(1);
                  event.target.value = String(valor) || '0,0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.tamanhoFonte =
                      Number(valor);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={3} md={6} sm={12}>
              <label>Posição X (Px)</label>
              <DefaultInput
                type="number"
                ref={posicaoXRef}
                defaultValue={itemEtiquetaElementoSelecionado?.posicaoX}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.posicaoX = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col xl={2} lg={3} md={6} sm={12}>
              <label>Posição Y (Px)</label>
              <DefaultInput
                type="number"
                ref={posicaoYRef}
                defaultValue={itemEtiquetaElementoSelecionado?.posicaoY}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.posicaoY = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col xl={2} lg={3} md={6} sm={12}>
              <label>Largura Y (Px)</label>
              <DefaultInput
                type="number"
                ref={larguraRef}
                defaultValue={itemEtiquetaElementoSelecionado?.largura}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.largura = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  (itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha &&
                    !linhaHorizontal)
                }
              />
            </Col>

            <Col xl={2} lg={3} md={6} sm={12}>
              <label>Altura Y (Px)</label>
              <DefaultInput
                type="number"
                ref={alturaRef}
                defaultValue={itemEtiquetaElementoSelecionado?.altura}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.altura = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={
                  !itemEtiquetaElementoSelecionado ||
                  (itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha &&
                    !linhaVertical)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? 'none'
                    : '',
              }}
            >
              <label>Borda Direita</label>
              <DefaultInput
                type="number"
                ref={espessuraBordaDireitaRef}
                defaultValue={
                  itemEtiquetaElementoSelecionado?.espessuraBordaDireita
                }
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.espessuraBordaDireita =
                      Number(event.target.value);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? 'none'
                    : '',
              }}
            >
              <label>Borda Esquerda</label>
              <DefaultInput
                type="number"
                ref={espessuraBordaEsquerdaRef}
                defaultValue={
                  itemEtiquetaElementoSelecionado?.espessuraBordaEsquerda
                }
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.espessuraBordaEsquerda =
                      Number(event.target.value);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? 'none'
                    : '',
              }}
            >
              <label>Borda Superior</label>
              <DefaultInput
                type="number"
                ref={espessuraBordaSuperiorRef}
                defaultValue={
                  itemEtiquetaElementoSelecionado?.espessuraBordaSuperior
                }
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.espessuraBordaSuperior =
                      Number(event.target.value);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? 'none'
                    : '',
              }}
            >
              <label>Borda Inferior</label>
              <DefaultInput
                type="number"
                ref={espessuraBordaInferiorRef}
                defaultValue={
                  itemEtiquetaElementoSelecionado?.espessuraBordaInferior
                }
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.espessuraBordaInferior =
                      Number(event.target.value);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha ||
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaCirculo
                    ? 'none'
                    : '',
              }}
            >
              <label>Arredondamento</label>
              <DefaultInput
                type="number"
                ref={arredondamentoBordaRef}
                defaultValue={
                  itemEtiquetaElementoSelecionado?.arredondamentoBorda
                }
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.arredondamentoBorda =
                      Number(event.target.value);
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? ''
                    : 'none',
              }}
            >
              <label>Espessura</label>
              <DefaultInput
                type="number"
                ref={espessuraRef}
                defaultValue={itemEtiquetaElementoSelecionado?.espessura}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.espessuraBordaSuperior =
                      Number(event.target.value);
                    itemEtiquetaElementoSelecionado.espessuraBordaEsquerda =
                      Number(event.target.value);
                    itemEtiquetaElementoSelecionado.espessura = Number(
                      event.target.value
                    );

                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.formaLinha
                    ? 'flex'
                    : 'none',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  style={{
                    width: 'auto',
                    height: 20,
                    margin: 0,
                    marginRight: 5,
                  }}
                  type="radio"
                  checked={linhaHorizontal}
                  onChange={(event) => {
                    setLinhaHorizontal(event.target.checked);
                    setLinhaVertical(false);

                    if (larguraRef.current) larguraRef.current.value = '100';
                    if (alturaRef.current) alturaRef.current.value = '1';

                    if (itemEtiquetaElementoSelecionado) {
                      itemEtiquetaElementoSelecionado.largura = 100;
                      itemEtiquetaElementoSelecionado.altura = 1;
                    }

                    refresh();
                  }}
                />
                <label>Horizontal</label>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  style={{
                    width: 'auto',
                    height: 20,
                    margin: 0,
                    marginRight: 5,
                  }}
                  type="radio"
                  checked={linhaVertical}
                  onChange={(event) => {
                    setLinhaVertical(event.target.checked);
                    setLinhaHorizontal(false);

                    if (alturaRef.current) alturaRef.current.value = '100';
                    if (larguraRef.current) larguraRef.current.value = '1';

                    if (itemEtiquetaElementoSelecionado) {
                      itemEtiquetaElementoSelecionado.altura = 100;
                      itemEtiquetaElementoSelecionado.largura = 1;
                    }

                    refresh();
                  }}
                />
                <label>Vertical</label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.numeroDecimal
                    ? ''
                    : 'none',
              }}
            >
              <label>Casas Decimais</label>
              <DefaultInput
                type="number"
                ref={casasDecimaisRef}
                defaultValue={itemEtiquetaElementoSelecionado?.casasDecimais}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.casasDecimais = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento ===
                  TipoElementoEnum.codigoBarrasCodigoProduto
                    ? ''
                    : 'none',
              }}
            >
              <label>Numero de Dígitos</label>
              <DefaultInput
                type="number"
                ref={numeroDigitosRef}
                defaultValue={itemEtiquetaElementoSelecionado?.numeroDigitos}
                onChange={(event) => {
                  const valor = event.target.value.replace(/\D+/g, '');
                  event.target.value = valor || '0';

                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.numeroDigitos = Number(
                      event.target.value
                    );
                    refresh();
                  }
                }}
                disabled={!itemEtiquetaElementoSelecionado}
              />
            </Col>

            <Col
              xl={2}
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  itemEtiquetaElementoSelecionado?.etiquetaElementoDisponivel
                    ?.tipoElemento === TipoElementoEnum.dataHora
                    ? ''
                    : 'none',
              }}
            >
              <label>Tipo Data Hora</label>
              <select
                ref={tipoDataHoraRef}
                onChange={(event) => {
                  if (itemEtiquetaElementoSelecionado) {
                    itemEtiquetaElementoSelecionado.tipoDataHora =
                      StringConverterParaEnum<
                        typeof TipoDataHoraEnum,
                        TipoDataHoraEnum
                      >(TipoDataHoraEnum, String(event.target.value));
                    refresh();
                  }
                }}
              >
                {optionsTipoDataHora.map((option) => {
                  return (
                    <option
                      className={option.hidden ? 'hidden' : ''}
                      value={option.value}
                      key={option.title}
                    >
                      {option.title}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
