import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/obter-retirada-consignado';

interface IFrenteCaixaPdvObterRetiradaConsignadoComunicadorIndex {
  params: {
    dataEmissaoInicial?: Date;
    dataEmissaoFinal?: Date;
    idCliente?: string;
    listaNumero?: number[];
    listaIdProduto?: string[];
    listaIdFinalidadeUsoCliente?: any[];
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFrenteCaixaPdvObterRetiradaConsignadoComunicadorShow {
  params: {
    listaIdItemConsignado: string[];
  };
}

class FrenteCaixaPdvObterRetiradaConsignadoComunicador {
  public async index(
    params: IFrenteCaixaPdvObterRetiradaConsignadoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IFrenteCaixaPdvObterRetiradaConsignadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/carregar`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new FrenteCaixaPdvObterRetiradaConsignadoComunicador();
