import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';

const CustosTab: React.FC = () => {
  return (
    <div>
      <h6 style={{ marginTop: '15px' }}>Produto de Origem</h6>
      <Divisor>
        <Row>
          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorUnitarioCustoUltimaCompra"
              label="Val. unit. custo última compra"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>

          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorTotalCustoUltimaCompra"
              label="Val. total custo última compra"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorUnitarioCustoMedio"
              label="Val. unit. custo médio"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>

          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorTotalCustoMedio"
              label="Val. total custo médio"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorUnitarioCustoContabilIr"
              label="Val. unit. custo Contábil IR"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>

          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorTotalCustoContabilIr"
              label="Val. total custo Contábil IR"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorUnitarioCustoContabilIcmsIpi"
              label="Val. unit. custo Contábil ICMS/IPI"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>

          <Col>
            <InputDecimal
              name="produtoOrigemCustos.valorTotalCustoContabilIcmsIpi"
              label="Val. total custo Contábil ICMS/IPI"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>
      </Divisor>

      <h6>Produto de Destino</h6>
      <Divisor>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioMovimentoCustoUltimaCompra"
              label="Val. Unit. Movimento Custo Última Compra"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorTotalMovimentoCustoUltimaCompra"
              label="Val. Total Movimento Custo Última Compra"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioCustoCalculadoCustoUltimaCompra"
              label="Val. Unit. Calculado Custo Última Compra"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioMovimentoCustoMedio"
              label="Val. Unit. Movimento Custo Médio"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorTotalMovimentoCustoMedio"
              label="Val. Total Movimento Custo Médio"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioCustoCalculadoCustoMedio"
              label="Val. Unit. Calculado Custo Médio"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioMovimentoCustoContabilIr"
              label="Val. Unit. Movimento Custo Contábil IR"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorTotalMovimentoCustoContabilIr"
              label="Val. Total Movimento Custo Contábil IR"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioCustoCalculadoCustoContabilIr"
              label="Val. Unit. Calculado Custo Contábil IR"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioMovimentoCustoContabilIcmsIpi"
              label="Val. Unit. Movimento Custo Contábil ICMS/IPI"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorTotalMovimentoCustoContabilIcmsIpi"
              label="Val. Total Movimento Custo Contábil ICMS/IPI"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimal
              name="produtoDestinoCustos.valorUnitarioCustoCalculadoCustoContabilIcmsIpi"
              label="Val. Unit. Calculado Custo Contábil ICMS/IPI"
              casasDecimais={2}
              casasInteiras={14}
              disabled
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default CustosTab;
