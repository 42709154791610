"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarChaveAcesso;
function FormatarChaveAcesso(value) {
    if (!value)
        return '';
    const re = /^([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4}) ?([\d]{4})/;
    if (value)
        return value.replace(re, '$1 $2 $3 $4 $5 $6 $7 $8 $9 $10 $11');
    return '';
}
