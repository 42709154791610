import api from '../../../Comum/Services/Api';

const rota = 'endereco/buscar-cep';

interface IEnderecoCepComunicadorShow {
  params: {
    cep: any;
    logradouro: any;
    bairro: any;
    idCidade: any;
    nomeCidade: any;
    siglaEstado: any;
  };
}

class EnderecoCepComunicador {
  public async show(params: IEnderecoCepComunicadorShow): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new EnderecoCepComunicador();
