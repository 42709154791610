import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/transferencia-entre-portadores/log';

interface ITransferenciaEntrePortadoresLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface ITransferenciaEntrePortadoresLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class TransferenciaEntrePortadoresLogComunicador {
  public async index(
    params: ITransferenciaEntrePortadoresLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ITransferenciaEntrePortadoresLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new TransferenciaEntrePortadoresLogComunicador();
