import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/cancelar';

interface IConhecimentoTransporteCancelarComunicadorStore {
  id: string;
  params: {
    motivoCancelamento: string;
  };
}

interface IConhecimentoTransporteCancelarComunicadorShow {
  id: string;
}

class ConhecimentoTransporteCancelarComunicador {
  public async store(
    params: IConhecimentoTransporteCancelarComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async show(
    param: IConhecimentoTransporteCancelarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${param.id}`);
    return response.data;
  }
}

export default new ConhecimentoTransporteCancelarComunicador();
