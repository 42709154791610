import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const BtnAcoes = styled(DropdownButton)`
  button {
    padding: 0px;
    width: 40px;
    height: 26px;
  }
  button::after {
    display: none;
  }
`;
