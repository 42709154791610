import { AppErro, IImpressaoEtiquetasProdutosLista } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { UseImpressaoEtiqueta } from './ImpressaoEtiquetaHook';
import ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Comunicador/ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador';
import ImpressaoEtiquetasProdutosObterDadosPadraoComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Comunicador/ImpressaoEtiquetasProdutosObterDadosPadraoComunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaEtiqueta, setListaEtiqueta } = UseImpressaoEtiqueta();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      { dadosPadrao, dadosObrigatorios } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IImpressaoEtiquetasProdutosLista
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosBackend(): Promise<void> {
        throw new AppErro({
          mensagem: 'A função "SetarDadosBackend" não foi implementada!',
        });
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response =
          await ImpressaoEtiquetasProdutosObterDadosPadraoComunicador.index();

        await formRef.current?.setDataInicial({
          quantidade: 1,
          regraEscolhaTabelaPreco: response.regraEscolhaTabelaPreco,
          etiqueta: response.etiqueta,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          await SetarDadosPadrao();

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error);
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, isProduto?: boolean): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idRegraEscolhaTabelaPreco: Yup.string()
            .nullable()
            .required('Tabela de Preço é Obrigatória!'),
          idProduto: Yup.string().nullable().required('Produto é Obrigatório!'),
          quantidade: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade é Obrigatória!',
              test: (value: any) => {
                if (value > 0) {
                  return true;
                }

                return false;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isProduto !== false) {
          const errors = GetValidationErrors(error as any);
          formRef.current?.setErrors(errors);
        }
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        const response =
          await ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador.index(
            {
              params: {
                idRegraEscolhaTabelaPreco: data.idRegraEscolhaTabelaPreco,
                listaIdProduto: [data.idProduto],
              },
            }
          );

        const produto = formRef.current?.getFieldValueNomeObjeto('produto');
        const regraEscolhaTabelaPreco =
          formRef.current?.getFieldValueNomeObjeto('regraEscolhaTabelaPreco');
        const etiqueta = formRef.current?.getFieldValueNomeObjeto('etiqueta');

        const dados: IImpressaoEtiquetasProdutosLista = {
          ...data,
          selecionado: true,

          regraEscolhaTabelaPreco,
          etiqueta,
          produto,

          valorUnitarioVenda: response[0],
        };

        setListaEtiqueta([...listaEtiqueta, dados]);

        formRef.current?.clearField('produto');
        formRef.current?.clearField('quantidade');

        await formRef.current?.atualizarDataInicial();
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, listaEtiqueta, setListaEtiqueta]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
