import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/bandeira-cartao';

interface IBandeiraCartaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IBandeiraCartaoComunicadorShow {
  id: string;
}

interface IBandeiraCartaoComunicadorStore {
  params: any;
}

interface IBandeiraCartaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IBandeiraCartaoComunicadorDeletar {
  id: string;
}

class BandeiraCartaoComunicador {
  public async index(params: IBandeiraCartaoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IBandeiraCartaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IBandeiraCartaoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IBandeiraCartaoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IBandeiraCartaoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new BandeiraCartaoComunicador();
