import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/movimentacoes/movimento-portador-tef/cancelar-pagamento';

interface IMovimentoPortadorTefCancelarPagamentoComunicadorUpdate {
  id: string;
  params?: {
    comprovanteClienteCancelamento?: string;
    comprovanteEstabelecimentoCancelamento?: string;
  };
}

class MovimentoPortadorTefCancelarPagamentoComunicador {
  public async update(
    params: IMovimentoPortadorTefCancelarPagamentoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new MovimentoPortadorTefCancelarPagamentoComunicador();
