import React, { useEffect, useCallback, useRef } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import {
  FormatarDataHoraParaArquivo,
  IGeracaoArquivosContabeisValoresAlterar,
  ObjectIsEmpty,
} from '@elogestor/util';
import * as Yup from 'yup';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import XmlPdfTerceiroTab from '../XmlPdfTerceiroTab';
import RelatorioEstoqueTab from '../RelatorioEstoqueTab';
import RelatorioNotaFiscalSaidaTab from '../RelatorioNotaFiscalSaidaTab';
import RelatorioConhecimentoTransporteEmitidosTab from '../RelatorioConhecimentoTransporteEmitidosTab';
import RelatorioManifestoTransporteResumoTab from '../RelatorioManifestoTransporteResumoTab';
import RelatorioContasReceberPagarTab from '../RelatorioContasReceberPagarTab';
import XmlPdfNotasEmitidasTab from '../XmlPdfNotasEmitidasTab';
import SpedFiscalTab from '../SpedFiscal';
import SisdevinTab from '../SisdevinTab';
import RelatorioNotaFiscalEntradaTab from '../RelatorioNotaFiscalEntradaTab';
import RelatorioDocumentoEntradaTab from '../RelatorioDocumentoEntradaTab';
import XmlPdfConhecimentoTransporteEmitidosTab from '../XmlPdfConhecimentoTransporteEmitidosTab';

type IProps = IMain<IGeracaoArquivosContabeisValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const periodoDataHoraEmissaoRef = useRef<IInputPeriodoDataHoraRef>(null);
  const { permissoes } = UsePermissoes();
  const { ServicoOrdensServicosAcessorio: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({});

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handAtualizarNomeArquivo = useCallback(() => {
    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');
    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');
    let descricao = formRef.current?.getFieldValue('descricao');

    if (!descricao) {
      descricao = 'arquivo';
    }

    const nomeArquivo = `${descricao}_${FormatarDataHoraParaArquivo(
      dataEmissaoInicial
    )}_a_${FormatarDataHoraParaArquivo(dataEmissaoFinal)}`;

    formRef.current?.setFieldValue('nomeArquivo', nomeArquivo);
  }, [formRef]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const formErro = formRef.current?.getErrors();
    if (!ObjectIsEmpty(formErro as any)) {
      return;
    }
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
    handleValidarDataHora(formRef.current?.getData());
  }, [
    formRef,
    handleSubmit,
    handleValidarDataHora,
    navigate,
    onClickSalvar,
    rotas.listagem,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={10} md={6} sm={6}>
              <Input
                ref={inputRefFocus}
                label="Descrição"
                name="descricao"
                placeholder="Descrição"
                maxLength={100}
                onBlur={handAtualizarNomeArquivo}
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={6} sm={6}>
              <InputPeriodoDataHora
                labelDataHoraInicial="Data Emissão Inicial"
                labelDataHoraFinal="Data Emissão Final"
                nomeDataHoraInicial="dataEmissaoInicial"
                nomeDataHoraFinal="dataEmissaoFinal"
                ref={periodoDataHoraEmissaoRef}
                onBlur={handAtualizarNomeArquivo}
              />
            </Col>
            <Col>
              <Input name="nomeArquivo" label="Nome do Arquivo" />
            </Col>
          </Row>
        </Divisor>

        <Tabs>
          <Tab eventKey="xmlPdfNotasEmitidas" title="Xml e Pdf Notas Emitidas">
            <XmlPdfNotasEmitidasTab />
          </Tab>

          <Tab eventKey="xmlPdfTerceiros" title="Xml e Pdf Terceiro">
            <XmlPdfTerceiroTab />
          </Tab>

          <Tab
            eventKey="XmlPdfConhecimentoTransporteEmitidos"
            title="Xml e Pdf de Conhec. Transp. Emitidos"
          >
            <XmlPdfConhecimentoTransporteEmitidosTab />
          </Tab>

          <Tab eventKey="relatorioEstoque" title="Rel. Estoque">
            <RelatorioEstoqueTab />
          </Tab>

          <Tab
            eventKey="relatorioNotaFiscalSaida"
            title="Rel. Nota Fiscal (Saída)"
          >
            <RelatorioNotaFiscalSaidaTab />
          </Tab>

          <Tab
            eventKey="relatorioNotaFiscalEntrada"
            title="Rel. Nota Fiscal (Entrada)"
          >
            <RelatorioNotaFiscalEntradaTab />
          </Tab>

          <Tab
            eventKey="relatorioDocumentosEntrada"
            title="Rel. Documento (Entrada)"
          >
            <RelatorioDocumentoEntradaTab />
          </Tab>

          <Tab
            eventKey="relConhecimentoTransporteEmitidosTomador"
            title="Rel. Conhec. Transp. Emitidos"
          >
            <RelatorioConhecimentoTransporteEmitidosTab />
          </Tab>

          <Tab
            eventKey="relatorioManifestoTransporteResumo"
            title="Rel. Manif. Transp."
          >
            <RelatorioManifestoTransporteResumoTab />
          </Tab>

          <Tab
            eventKey="relContasReceberPagarParcelaPorEmissao"
            title="Rel. Contas(Receber/Pagar)"
          >
            <RelatorioContasReceberPagarTab />
          </Tab>

          <Tab eventKey="spedFiscal" title="Sped Fiscal">
            <SpedFiscalTab />
          </Tab>

          <Tab eventKey="sisdevin" title="Sisdevin">
            <SisdevinTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
