import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/cancelar';

interface IManifestoTransporteCancelarComunicadorShow {
  id: string;
}

interface IManifestoTransporteCancelarComunicadorStore {
  id: string;
  params: any;
}

class ManifestoTransporteCancelarComunicador {
  public async show(
    params: IManifestoTransporteCancelarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IManifestoTransporteCancelarComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ManifestoTransporteCancelarComunicador();
