import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';

const SimplesNacionalTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalSn.id" />
          <Col lg={6}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CST"
              placeholder="CST"
              name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalSn.idSituacaoTributaria"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcmsFiscal.conhecimentoTransporteTerceiroImpostoIcmsFiscalSn.situacaoTributaria"
              icmsConhecimentoTransporte
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default SimplesNacionalTab;
