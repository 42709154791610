import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro, ValidarChaveAcesso } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ConhecimentoTransporteTerceiroNotaReferenciadaComunicador from '../../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/NotaReferenciada/Comunicador/ConhecimentoTransporteTerceiroNotaReferenciadaComunicador';
import ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador from '../../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const { isJanelaAutoComplete } = UseAutoComplete();
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const [loading, setLoading] = useState(false);
  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      isConhecimentoCadastrado?: boolean
    ): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          chaveAcesso: Yup.string()
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (!value) return true;
                const valido = ValidarChaveAcesso(value);
                return valido;
              },
            })
            .min(44, 'Chave de Acesso inválida!')
            .required('Chave de Acesso é obrigatória!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isConhecimentoCadastrado !== false) {
          const errors = GetValidationErrors(error as any);
          formRef?.setErrors(errors);
        }
        return false;
      }
    },
    []
  );

  const handleValidarChaveAcessoNotasUtilizadas = useCallback(
    async (
      chaveAcesso: string,
      isChaveDuplicada: boolean
    ): Promise<boolean> => {
      if (!isChaveDuplicada) return true;

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 18 }}>
            Chaves de acesso: {chaveAcesso} já utilizada em outro conhecimento
            de transporte, deseja continuar?
          </span>
        ),
      });

      return resposta;
    },
    [abrirJanela]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        if (await handleValidar(data, formRef, false)) {
          const { chaveDuplicada: isChaveDuplicada } =
            await ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador.index(
              { chaveAcesso: data.chaveAcesso }
            );

          if (
            !(await handleValidarChaveAcessoNotasUtilizadas(
              data.chaveAcesso,
              isChaveDuplicada
            ))
          ) {
            formRefLista.current?.clearField('chaveAcesso');
            formRefLista.current?.clearField('numero');

            setLoading(false);
            formPrincipal.setLoading(false);
            return { id: '', erro: false };
          }

          const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
          if (erro) {
            setLoading(false);
            formPrincipal.setLoading(false);
            return { id: '', erro: true };
          }
        }

        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef, true))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const idConhecimentoTransporteTerceiro =
          formPrincipal.getIdDetalheRegistro() || '';

        const params = {
          ...data,
          idConhecimentoTransporteTerceiro,
        };

        const response =
          await ConhecimentoTransporteTerceiroNotaReferenciadaComunicador.store(
            { params }
          );

        setIdDetalheRegistro(response.id);
        id = response.id;

        formPrincipal.handleCarregarDados();
        await formRef?.reset();
        ToastSucesso('Nota Salva!');
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleSalvarContinuar,
      handleValidar,
      handleValidarChaveAcessoNotasUtilizadas,
      isJanelaAutoComplete,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return;
        }

        await ConhecimentoTransporteTerceiroNotaReferenciadaComunicador.delete({
          id,
        });

        ToastSucesso('Nota Deletada!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
