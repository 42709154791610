import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Col, Row, Tabs, Tab, Button } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import {
  DestinadoEnum,
  FormatarEnum,
  IAnaliseCustoVendaLista,
  IndicadorPresencaPadraoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import JanelaDetalhe from '../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import Select from '../../../../../Componentes/Select';
import AnaliseCustoProdutoTab from './AnaliseCustoProdutoTab';
import AnaliseCustoVendaTab from './AnaliseCustoVendaTab';
import InputHiddenHtml from '../../../../../Componentes/Inputs/InputHiddenHtml';
import InputAutoCompleteTipoNota from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import AnaliseCustoComunicador from '../../../../../Comunicador/Suprimentos/Custo/AnaliseCusto/Comunicador/AnaliseCustoComunicador';
import ObterTipoNotaMotivoPadraoComunicador from '../../../../../Comunicador/Fiscal/Tributacao/TipoNota/Comunicador/ObterTipoNotaMotivoPadraoComunicador';
import Input from '../../../../../Componentes/Inputs/Input';

interface IAnaliseCustoModal {
  sequenciaItemSelecionado: number;
  listaItem: IAnaliseCustoVendaLista[];
  onFecharFormModal(): void;
  onSalvarFormModal(valorUnitarioVenda: number): void;
}

const AnaliseCusto: React.FC<IAnaliseCustoModal> = ({
  sequenciaItemSelecionado,
  listaItem,
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const [tabSelecionada, setTabSelecionada] = useState('produto');
  const [isFrenteCaixa, setIsFrenteCaixa] = useState(false);

  const idTipoNota = formRef.current?.getFieldValue(
    'itemVendaSelecionado.idTipoNota'
  );

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (onFecharFormModal) onFecharFormModal();
  }, [onFecharFormModal]);

  const handleAtualizarValores = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const data = formRef.current.getDataRecuperarFormulario();

      if (
        !data.itemVendaSelecionado.tipoNota ||
        !data.itemVendaSelecionado.tipoNotaMotivo
      ) {
        setLoading(false);
        return;
      }

      const listaItemVenda = formRef.current.getFieldValue('listaItemVenda');
      const novalistaItemVenda = listaItemVenda.map((item) =>
        item.sequencia === data.itemVendaSelecionado.sequencia
          ? {
              ...item,
              destinado: data.itemVendaSelecionado.destinado,
              indicadorPresenca: data.itemVendaSelecionado.indicadorPresenca,
              tipoNota: data.itemVendaSelecionado.tipoNota,
              tipoNotaMotivo: data.itemVendaSelecionado.tipoNotaMotivo,
              valorUnitarioVenda: data.itemVendaSelecionado.valorUnitarioVenda,
              valorTotalProduto: data.itemVendaSelecionado.valorTotalProduto,
              recalcularImpostos: data.itemVendaSelecionado.recalcularImpostos,
            }
          : item
      );

      const response = await AnaliseCustoComunicador.index({
        listaItemVenda: novalistaItemVenda,
      });

      if (response) {
        const itemVendaSelecionado = response.listaItemVenda.find(
          (e) => Number(e.sequencia) === sequenciaItemSelecionado
        );

        formRef.current.setSemExecutarEvento({
          listaItemVenda: response.listaItemVenda,
          itemVendaSelecionado,
          margemLiquidaValorTotal: response.margemLiquidaValorTotal,
          margemLiquidaPercentualTotal: response.margemLiquidaPercentualTotal,
        });
      }

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [refresh, sequenciaItemSelecionado]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const itemVendaSelecionado = listaItem.find(
        (e) => e.sequencia === sequenciaItemSelecionado
      );

      if (itemVendaSelecionado.isFrenteCaixa) {
        const responseTipoNotaMotivoPadrao =
          await ObterTipoNotaMotivoPadraoComunicador.show({
            params: {
              descricaoTipoNota: 'VENDA',
              descricaoTipoNotaMotivo: 'NORMAL',
              tipoMovimento: TipoMovimentoEntradaSaidaEnum.saida,
            },
          });

        if (responseTipoNotaMotivoPadrao) {
          for (let i = 0; i < listaItem.length; i++) {
            listaItem[i].tipoNota = responseTipoNotaMotivoPadrao.tipoNota;
            listaItem[i].tipoNotaMotivo =
              responseTipoNotaMotivoPadrao.tipoNotaMotivo;
          }
        }
      }

      formRef.current.setDataInicial({
        listaItemVenda: listaItem,
        itemVendaSelecionado,
      });

      setIsFrenteCaixa(itemVendaSelecionado.isFrenteCaixa);

      await handleAtualizarValores();

      setLoading(false);
      refresh();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleAtualizarValores, listaItem, refresh, sequenciaItemSelecionado]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSalvar = useCallback(() => {
    const valorUnitarioVenda = formRef.current.getFieldValue(
      'itemVendaSelecionado.valorUnitarioVenda'
    );

    onSalvarFormModal(valorUnitarioVenda);
  }, [onSalvarFormModal]);

  return (
    <JanelaDetalhe
      titulo="Análise de Custos"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <InputHiddenHtml name="listaItemVenda" />
          <InputHiddenHtml name="itemVendaSelecionado.isFrenteCaixa" />
          <InputHiddenHtml name="itemVendaSelecionado.recalcularImpostos" />

          <Divisor>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Select
                  label="Destinado"
                  name="itemVendaSelecionado.destinado"
                  options={FormatarEnum({
                    enumObj: DestinadoEnum,
                    nullavel: true,
                  })}
                  onChange={() => {
                    refresh();
                    handleAtualizarValores();
                  }}
                  disabled={!isFrenteCaixa}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Select
                  label="Indicador de Presença"
                  name="itemVendaSelecionado.indicadorPresenca"
                  options={FormatarEnum({
                    enumObj: IndicadorPresencaPadraoEnum,
                    nullavel: true,
                  })}
                  onChange={() => {
                    refresh();
                    handleAtualizarValores();
                  }}
                  disabled={!isFrenteCaixa}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <InputAutoCompleteTipoNota
                  label="Tipo de Nota"
                  name="itemVendaSelecionado.idTipoNota"
                  placeholder="Tipo de Nota"
                  nomeObjeto="itemVendaSelecionado.tipoNota"
                  filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.saida}
                  onChangeItemAtualAposCarregarSemClear={(event) => {
                    formRef.current?.setSemExecutarEvento({
                      tipoNotaMotivo: null,
                    });

                    const listaItemVenda =
                      formRef.current.getFieldValue('listaItemVenda');

                    for (let i = 0; i < listaItemVenda.length; i++) {
                      listaItem[i].tipoNota = event.itemAtual;
                      listaItem[i].tipoNotaMotivo = null;
                    }

                    formRef.current?.setSemExecutarEvento({
                      listaItemVenda,
                    });

                    refresh();
                    handleAtualizarValores();
                  }}
                  disabled={!isFrenteCaixa}
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <InputAutoCompleteTipoNotaMotivo
                  label="Motivo"
                  name="itemVendaSelecionado.idTipoNotaMotivo"
                  placeholder="Motivo"
                  nomeObjeto="itemVendaSelecionado.tipoNotaMotivo"
                  disabled={!idTipoNota || !isFrenteCaixa}
                  idTipoNota={idTipoNota}
                  onChangeItemAtualAposCarregarSemClear={(event) => {
                    refresh();

                    const listaItemVenda =
                      formRef.current.getFieldValue('listaItemVenda');

                    for (let i = 0; i < listaItemVenda.length; i++) {
                      listaItem[i].tipoNotaMotivo = event.itemAtual;
                    }

                    formRef.current.setSemExecutarEvento({
                      listaItemVenda,
                    });

                    handleAtualizarValores();
                  }}
                />
              </Col>
            </Row>
          </Divisor>

          <Divisor>
            <Row>
              <Tabs
                id={v4()}
                defaultActiveKey="produto"
                activeKey={tabSelecionada}
                onSelect={(k) => setTabSelecionada(k || '')}
              >
                <Tab eventKey="produto" title="Produto (Item)">
                  <AnaliseCustoProdutoTab
                    formRef={formRef}
                    atualizarValores={handleAtualizarValores}
                    refresh={refresh}
                  />
                </Tab>

                <Tab eventKey="venda" title="Venda">
                  <AnaliseCustoVendaTab formRef={formRef} refresh={refresh} />
                </Tab>
              </Tabs>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSalvar}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default AnaliseCusto;
