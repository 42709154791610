import React, { createContext, useContext, RefObject } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { IHandleCarregarDadosParametros } from '../../Componentes/Detalhe/Hooks/FormContext';

export interface ISubmitProps {
  id: string;
  erro: boolean;
}

export interface IListaDetalheFormContext<T = any> {
  formRefLista: RefObject<IFormCiaHandles>;
  formRefDetalhe: RefObject<IFormCiaHandles>;
  handleCarregarDados(data?: IHandleCarregarDadosParametros): Promise<void>;
  handleValidar(data: any, formRef: IFormCiaHandles | null): Promise<boolean>;

  handleSubmit(
    data: any,
    formRef?: IFormCiaHandles | null
  ): Promise<ISubmitProps>;
  handleExcluir(id: string): Promise<void>;

  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  refresh: () => void;

  getIdDetalheRegistro(): string;
  setIdDetalheRegistro(valor: string): void;
  listaValor?: T[];
  setListaValor?: React.Dispatch<React.SetStateAction<T[]>>;

  terminouCarregarDados?: boolean;
  inputRefFocus?: React.RefObject<any>;
  handleSetarFocus?: () => Promise<void>;
}

const formContext = createContext<IListaDetalheFormContext>(
  {} as IListaDetalheFormContext
);
const ListaDetalheFormProvider = formContext.Provider;

function UseListaDetalheForm<T = any>(): IListaDetalheFormContext<T> {
  const context = useContext(formContext);

  return context;
}

export { ListaDetalheFormProvider, UseListaDetalheForm };
