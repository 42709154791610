import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/documento-anterior';

interface IConhecimentoTransporteTerceiroDocumentoAnteriorComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroDocumentoAnteriorComunicadorDelete {
  id: string;
}

class ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador {
  public async store(
    params: IConhecimentoTransporteTerceiroDocumentoAnteriorComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroDocumentoAnteriorComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador();
