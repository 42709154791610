"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoAdiantamentoEnum;
(function (SituacaoAdiantamentoEnum) {
    SituacaoAdiantamentoEnum["disponivel"] = "Dispon\u00EDvel";
    SituacaoAdiantamentoEnum["parcialmenteUtilizado"] = "Parcialmente Utilizado";
    SituacaoAdiantamentoEnum["utilizado"] = "Utilizado";
})(SituacaoAdiantamentoEnum || (SituacaoAdiantamentoEnum = {}));
exports.default = SituacaoAdiantamentoEnum;
