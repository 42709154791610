import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/bandeira-cartao-auto-complete';

interface IBandeiraCartaoAutoCompleteComunicadorIndex {
  params: any;
}

class BandeiraCartaoAutoCompleteComunicador {
  public async index(
    params: IBandeiraCartaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new BandeiraCartaoAutoCompleteComunicador();
