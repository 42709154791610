import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/confirmar-pagamento-sitef';

interface IFrenteCaixaPdvConfirmarPagamentoSiTefComunicadorUpdate {
  params: any;
}

class FrenteCaixaPdvConfirmarPagamentoSiTefComunicador {
  public async update(
    params: IFrenteCaixaPdvConfirmarPagamentoSiTefComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvConfirmarPagamentoSiTefComunicador();
