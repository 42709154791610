/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  DestinadoEnum,
  FinalidadeEmissaoEnum,
  FormatarEnum,
  IndicadorPresencaPadraoEnum,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMercadoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import { Col, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { Tabela } from '../../../../../Componentes/Tabela/styles';
import { UltimaTr } from '../styles';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTipoNota from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteOperacaoFiscal from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador from '../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador';
import Input from '../../../../../Componentes/Inputs/Input';

export interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Ref {
  handleValidar(): Promise<boolean>;
  handleObterListaValorSelecionados(): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[];
}

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;

  idPessoaNotaFiscal?: string;
  idNotaFiscalSaidaOrigem?: string;
  idNotaFiscalEntradaOrigem?: string;
  indicadorPresenca?: IndicadorPresencaPadraoEnum;
  finalidadeEmissao?: FinalidadeEmissaoEnum;
  tipoMercado?: TipoMercadoEnum;
}

const NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Ref,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Props
> = (
  {
    name,
    formRef,
    refresh,
    setLoading,
    idPessoaNotaFiscal,
    idNotaFiscalSaidaOrigem,
    idNotaFiscalEntradaOrigem,
    indicadorPresenca,
    finalidadeEmissao,
    tipoMercado,
  },
  ref
) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const valorRef = useRef<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[]
  >([]);

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[]
  >([]);
  const [valorSubTotal, setValorSubTotal] = useState<number>(0);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterListaValorSelecionados =
    useCallback((): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[] => {
      return listaValor.filter((item) => {
        return item?.selecionado;
      });
    }, [listaValor]);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const listaSelecionados = handleObterListaValorSelecionados();

    const valorTotal = listaSelecionados.reduce(
      (
        acumulador: number,
        item: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista
      ) => {
        acumulador += Number(item.valorTotal) || 0;
        return acumulador;
      },
      0
    );

    setValorSubTotal(valorTotal);
  }, [handleObterListaValorSelecionados]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const listaMensagemErro: string[] = [];
      let etapaValida = true;

      const listaSelecionados = handleObterListaValorSelecionados();

      const data = formRef.current?.getData() as any;

      formRef.current?.setErrors({});

      data.listaEtapa3.forEach(
        (item: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista) => {
          if (!item.tipoNota) {
            listaMensagemErro.push(
              `Tipo Nota do Item: ${item.ordem} é obrigatório!`
            );
          }
          if (!item.tipoNotaMotivo) {
            listaMensagemErro.push(
              `Motivo do Item: ${item.ordem} é obrigatório!`
            );
          }
          if (!item.destinado) {
            listaMensagemErro.push(
              `Destinado do Item: ${item.ordem} é obrigatório!`
            );
          }
          if (!item.operacaoFiscal) {
            listaMensagemErro.push(
              `CFOP do Item: ${item.ordem} é obrigatório!`
            );
          }
          if (!item.naturezaOperacao) {
            listaMensagemErro.push(
              `Natureza da Operação do Item: ${item.ordem} é obrigatória!`
            );
          }
        }
      );

      if (listaSelecionados.length === 0) {
        listaMensagemErro.push(
          'Selecione pelo menos um item para poder prosseguir!'
        );
      }

      listaSelecionados.forEach((valor) => {
        if (valor.quantidadeTransferir > valor.quantidade)
          listaMensagemErro.push(
            `Não é possível transferir uma quantidade maior que a disponível para o Item: ${valor.ordem}, Produto: ${valor.produto.codigo} - ${valor.produto.descricao}!`
          );
      });

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        etapaValida = false;
      }

      const schema = Yup.object().shape({
        listaEtapa3: Yup.array().of(
          Yup.object().shape({
            idTipoNota: Yup.string()
              .nullable()
              .required('Tipo de Nota é obrigatória!'),
            idTipoNotaMotivo: Yup.string()
              .nullable()
              .required('Motivo é obrigatório!'),
            destinado: Yup.string()
              .nullable()
              .required('Destinado é obrigatório!'),
            idOperacaoFiscal: Yup.string()
              .nullable()
              .required('CFOP é obrigatória!'),
            naturezaOperacao: Yup.string().required(
              'Natureza da Operação é obrigatória!'
            ),
          })
        ),
      });

      await schema.validate(data, { abortEarly: false });

      return etapaValida;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef, handleObterListaValorSelecionados]);

  useEffect(() => {
    handleAtualizarValorSubTotal();
  }, [handleAtualizarValorSubTotal]);

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
      handleObterListaValorSelecionados,
    };
  });

  const handleAtualizarOperacaoFiscal = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];

      if (
        !itemValor.tipoNota ||
        !itemValor.tipoNotaMotivo ||
        !itemValor.destinado ||
        !finalidadeEmissao ||
        !tipoMercado
      )
        return;

      try {
        setLoading(true);

        const response =
          await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador.index(
            {
              idPessoa: String(idPessoaNotaFiscal),
              idNotaFiscalSaida: idNotaFiscalSaidaOrigem,
              idNotaFiscalEntrada: idNotaFiscalEntradaOrigem,
              finalidadeEmissao,
              tipoMercado,
              listaItemSelecionado: [itemValor],
            }
          );

        formRef.current?.setFieldValue(
          `listaEtapa3[${index}].operacaoFiscal`,
          response.listaItem[0].operacaoFiscal
        );
        itemValor.operacaoFiscal = response.listaItem[0].operacaoFiscal;

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      finalidadeEmissao,
      formRef,
      idNotaFiscalEntradaOrigem,
      idNotaFiscalSaidaOrigem,
      idPessoaNotaFiscal,
      listaValor,
      setLoading,
      tipoMercado,
    ]
  );

  // #region Obter

  const handleIdObterTipoNota = useCallback(
    (index: number): any => {
      const tipoNota = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].tipoNota`
      );

      return tipoNota?.id;
    },
    [formRef]
  );

  // #endregion Obter

  return (
    <Tabela style={{ overflow: 'auto', height: 500 }}>
      <Table striped bordered variant="light">
        <thead>
          <tr>
            <th className="lista-texto" style={{ width: '9%' }}>
              <span>Núm. Nota</span>
            </th>
            <th className="lista-valor" style={{ width: '2%' }}>
              <span>Ord.</span>
            </th>
            <th className="lista-texto" style={{ width: '23%' }}>
              <span>Produto</span>
            </th>
            <th className="lista-texto" style={{ width: '9%' }}>
              <span>UN</span>
            </th>
            <th className="lista-valor" style={{ width: '12%' }}>
              <span>Quantidade</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Qtde. Transferir</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Valor Unitário</span>
            </th>
            <th className="lista-valor" style={{ width: '15%' }}>
              <span>Sub. Total</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {listaValor &&
            listaValor.map(
              (
                item: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista,
                index
              ) => {
                return (
                  <>
                    <tr>
                      <td className="lista-texto">
                        <div>{item.numero}</div>
                      </td>
                      <td className="lista-valor">
                        <div>{item.ordem}</div>
                      </td>
                      <td className="lista-texto">
                        <div>{`${item.produto.codigo} - ${item.produto.descricao}`}</div>
                      </td>
                      <td className="lista-texto">
                        <div>{item.unidadeMedida.sigla}</div>
                      </td>
                      <td className="lista-valor">
                        <div>
                          {Number(item.quantidade).FormatarParaPtBr({
                            minimoCasasDecimais: 4,
                            maximoCasasDecimais: 4,
                          })}
                        </div>
                      </td>
                      <td className="lista-valor" style={{ padding: 5 }}>
                        <div>
                          {Number(item.quantidadeTransferir).FormatarParaPtBr({
                            minimoCasasDecimais: 4,
                            maximoCasasDecimais: 4,
                          })}
                        </div>
                      </td>
                      <td className="lista-valor">
                        <div>
                          {Number(item.valorUnitario).FormatarParaPtBr({
                            minimoCasasDecimais: 10,
                            maximoCasasDecimais: 10,
                          })}
                        </div>
                      </td>
                      <td className="lista-valor">
                        <div>{Number(item.valorTotal).FormatarParaPtBr()}</div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={8} style={{ padding: 0, paddingBottom: 20 }}>
                        <Divisor style={{ borderRadius: 0, marginBottom: 0 }}>
                          <Row>
                            <Col lg={6} md={6} sm={12}>
                              <InputAutoCompleteTipoNota
                                label="Tipo de Nota"
                                name={`listaEtapa3[${index}].idTipoNota`}
                                placeholder="Tipo de Nota"
                                nomeObjeto={`listaEtapa3[${index}].tipoNota`}
                                filtroTipoMovimento={
                                  TipoMovimentoEntradaSaidaEnum.saida
                                }
                                onChangeItemAtualAposCarregarSemClear={async (
                                  event
                                ) => {
                                  formRef.current?.setFieldValue(
                                    `listaEtapa3[${index}].tipoNotaMotivo`,
                                    null
                                  );
                                  listaValor[index].tipoNotaMotivo = undefined;

                                  if (event.itemAtual) {
                                    listaValor[index].tipoNota =
                                      event.itemAtual;
                                  } else {
                                    listaValor[index].tipoNota = undefined;
                                  }

                                  refresh();

                                  await handleAtualizarOperacaoFiscal(index);
                                }}
                              />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <InputAutoCompleteTipoNotaMotivo
                                label="Motivo"
                                name={`listaEtapa3[${index}].idTipoNotaMotivo`}
                                placeholder="Motivo"
                                nomeObjeto={`listaEtapa3[${index}].tipoNotaMotivo`}
                                idTipoNota={handleIdObterTipoNota(index)}
                                onChangeItemAtualAposCarregarSemClear={async (
                                  event
                                ) => {
                                  if (event.itemAtual) {
                                    listaValor[index].tipoNotaMotivo =
                                      event.itemAtual;
                                  } else {
                                    listaValor[index].tipoNotaMotivo =
                                      undefined;
                                  }

                                  refresh();

                                  await handleAtualizarOperacaoFiscal(index);
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={4} md={12} sm={12}>
                              <Select
                                label="Destinado"
                                name={`listaEtapa3[${index}].destinado`}
                                options={FormatarEnum({
                                  enumObj: DestinadoEnum,
                                  nullavel: true,
                                })}
                                onChange={async (event) => {
                                  if (event.currentTarget.value) {
                                    listaValor[index].destinado = event
                                      .currentTarget.value as DestinadoEnum;
                                  } else {
                                    listaValor[index].destinado = undefined;
                                  }

                                  refresh();

                                  await handleAtualizarOperacaoFiscal(index);
                                }}
                              />
                            </Col>
                            <Col lg={8} md={12} sm={12}>
                              <InputAutoCompleteOperacaoFiscal
                                label="CFOP"
                                name={`listaEtapa3[${index}].idOperacaoFiscal`}
                                placeholder="CFOP"
                                nomeObjeto={`listaEtapa3[${index}].operacaoFiscal`}
                                indicadorPresenca={indicadorPresenca}
                                tipoEntradaSaida={
                                  TipoEntradaSaidaOperacaoFiscalEnum.saida
                                }
                                tipoMercado={tipoMercado}
                                onChangeItemAtualAposCarregarSemClear={async (
                                  event
                                ) => {
                                  if (event.itemAtual) {
                                    listaValor[index].operacaoFiscal =
                                      event.itemAtual;

                                    const naturezaOperacao =
                                      event.itemAtual.descricao.substring(
                                        0,
                                        60
                                      );
                                    listaValor[index].naturezaOperacao =
                                      naturezaOperacao;
                                    formRef.current?.setFieldValue(
                                      `listaEtapa3[${index}].naturezaOperacao`,
                                      naturezaOperacao
                                    );
                                  } else {
                                    listaValor[index].operacaoFiscal =
                                      undefined;
                                  }

                                  refresh();
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={12} md={12} sm={12}>
                              <Input
                                label="Natureza Operação"
                                name={`listaEtapa3[${index}].naturezaOperacao`}
                                maxLength={60}
                                onChange={async (event) => {
                                  if (event.currentTarget.value) {
                                    listaValor[index].naturezaOperacao =
                                      event.currentTarget.value;
                                  } else {
                                    listaValor[index].naturezaOperacao =
                                      undefined;
                                  }

                                  refresh();
                                }}
                              />
                            </Col>
                          </Row>
                        </Divisor>
                      </td>
                    </tr>
                  </>
                );
              }
            )}
        </tbody>

        <tfoot>
          <UltimaTr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>Valor Sub. Total</div>
                <div style={{ alignSelf: 'flex-end' }}>
                  {valorSubTotal.FormatarParaPtBr()}
                </div>
              </div>
            </th>
          </UltimaTr>
        </tfoot>
      </Table>
    </Tabela>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3);
