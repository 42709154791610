"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoContaEnum;
(function (SituacaoContaEnum) {
    SituacaoContaEnum["aberta"] = "Aberta";
    SituacaoContaEnum["parcialmenteLiquidada"] = "Parcialmente Liquidada";
    SituacaoContaEnum["liquidada"] = "Liquidada";
})(SituacaoContaEnum || (SituacaoContaEnum = {}));
exports.default = SituacaoContaEnum;
