import { ImportacaoXmlTerceiroTipoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/consultar-governo';

interface IImportacaoXmlTerceiroConsultarGovernoComunicadorIndex {
  params: {
    tipoDocumento?: ImportacaoXmlTerceiroTipoEnum | null;
  };
}

class ImportacaoXmlTerceiroConsultarGovernoComunicador {
  public async index(
    params: IImportacaoXmlTerceiroConsultarGovernoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroConsultarGovernoComunicador();
