import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { IVeiculoMotoristaLista } from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputAutoCompleteMotorista, {
  IInputAutoCompleteMotoristaRef,
} from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteMotorista';
import UseFuncoesPadrao from '../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import TrDraggable from '../../../../../../Componentes/TrDraggable';
import { UseListaVeiculoMotoristaDraggable } from '../Hooks/ListaVeiculoMotoristaDraggableHook';
import BtnAcoesButton from '../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../Componentes/Tabela/styles';

interface IListaDetalheMotoristaProps {
  name: string;
}

const VeiculoMotoristaLista: React.FC<IListaDetalheMotoristaProps> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { TransporteVeiculo: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const { listaValor, handleAtualizarOrdem, handleMover, setListaValor } =
    UseListaVeiculoMotoristaDraggable();

  const valorRef = useRef<IVeiculoMotoristaLista[]>([]);
  const itemRef = useRef<IInputAutoCompleteMotoristaRef>(null);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  const listaId = useMemo(
    () => listaValor.map((valor) => valor.pessoaMotorista.id),
    [listaValor]
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IVeiculoMotoristaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();

    const data = formRefLista.current?.getData() as any;
    await handleSubmit(
      {
        idPessoaMotorista: data.idPessoaMotorista,
        idVeiculo: idDetalheRegistroPrincipal,
      },
      formRefLista.current
    );
    setIdDetalheRegistro('');
  }, [
    handleSalvarContinuar,
    isJanelaAutoComplete,
    formPrincipal,
    formRefLista,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  return (
    <>
      <Row>
        <Col lg={10} md={8} sm={12}>
          <FormCia ref={formRefLista}>
            <InputAutoCompleteMotorista
              label="Motorista"
              name="idPessoaMotorista"
              placeholder="Nome"
              permitirAdicionar
              listaIdParaRemover={listaId}
              ref={itemRef}
            />
          </FormCia>
        </Col>
        <Col
          lg={2}
          md={4}
          sm={12}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '100%' }}>
                <span>Motorista</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor &&
              listaValor.map((motorista, index) => {
                const { pessoaMotorista } = motorista;
                return (
                  <TrDraggable
                    idDrag={v4()}
                    index={index}
                    onMover={handleMover}
                    onDragEnd={() => {
                      if (formPrincipal.loading || loading) return;
                      handleAtualizarOrdem();
                    }}
                    key={v4()}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>{pessoaMotorista.nomeRazaoSocial}</div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(motorista.id);
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </TrDraggable>
                );
              })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default VeiculoMotoristaLista;
