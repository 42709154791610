import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import PessoaDadosBancariosHook from './ListaDetalheDadosBancarios/Hooks';
import ListaDetalheDadosBancarios from './ListaDetalheDadosBancarios/Lista';

const DadosBancariosTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12}>
            <PessoaDadosBancariosHook>
              <ListaDetalheDadosBancarios name="listaPessoaDadosBancarios" />
            </PessoaDadosBancariosHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default DadosBancariosTab;
