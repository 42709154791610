import React from 'react';
import { FaEllipsisH } from 'react-icons/fa/index.mjs';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import { BtnAcoes } from './styles';

const BtnAcoesButton: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <BtnAcoes
      onMouseDown={(event: any) => {
        event.stopPropagation();
      }}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      title={<FaEllipsisH />}
    >
      {children}
    </BtnAcoes>
  );
};

export default BtnAcoesButton;
