import api from '../../../Comum/Services/Api';

const rota = 'pessoa/buscar-nomeFantasia';

interface IPessoaNomeFantasiaComunicadorShow {
  params: {
    cnpjCpf: any;
    inscricaoEstadual: any;
    nomeRazaoSocial: any;
    nomeFantasia: any;
    cep: any;
    cidade: any;
    logradouro: any;
    bairro: any;
    complemento: any;
    numero: any;
    listaEmail: any;
    listaTelefone: any;

    limite: number;
    paginaAtual: number;
  };
}

class PessoaNomeFantasiaComunicador {
  public async show(params: IPessoaNomeFantasiaComunicadorShow): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaNomeFantasiaComunicador();
