import api from '../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/ordem-servico/duplicar';

interface IOrdemServicoDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class OrdemServicoDuplicarComunicador {
  public async store(
    params: IOrdemServicoDuplicarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new OrdemServicoDuplicarComunicador();
