import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import RegraEscolhaPortadorComunicador from '../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorComunicador';

const RegraEscolhaPortadorHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: RegraEscolhaPortadorComunicador }}
    >
      <ButtonsSubHeaderProvider>
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ButtonsSubHeaderProvider>
    </ComunicadorProvider>
  );
};

export default RegraEscolhaPortadorHook;
