import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormatarEnum,
  EstadoCivilEnum,
  SexoEnum,
  RegimeTributarioPessoaEnum,
  CompararStringsIguaisIgnoraAcentuacaoMinusculas,
} from '@elogestor/util';
import { IoMdAddCircle } from 'react-icons/io/index.mjs';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import Select from '../../../../../../Componentes/Select';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputCnpjCpf, {
  IOnBlurInputCnpjCpfValidoEvent,
} from '../../../../../../Componentes/Inputs/InputCnpjCpf';
import InputCep from '../../../../../../Componentes/Inputs/InputCep';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputTelefone from '../../../../../../Componentes/Inputs/TagsInput/InputTelefone';
import InputEmailTag from '../../../../../../Componentes/Inputs/TagsInput/InputEmailTag';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputVisualSemValor from '../../../../../../Componentes/Inputs/InputVisualSemValor';
import InputInscricaoEstadual, {
  IOnChangeValueInputInscricaoEstadualEvent,
} from '../../../../../../Componentes/Inputs/InputInscricaoEstadual';
import ModalPessoa, { IModalPessoaRef } from './ListaPessoasRepetidas';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import PesquisaEnderecoCepDetalhe from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';
import IEndereco from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteRamoAtividadeProfissao from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRamoAtividadeProfissao';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import IPesquisaPessoaCnpjCpfDetalheRef from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPesquisaPessoaCnpjCpfDetalheRef';
import PesquisaPessoaCnpjCpfDetalhe from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe';
import IPessoa from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPessoa';
import {
  PesquisaCnpjCpfPessoaEnderecoEntrega,
  PesquisaCnpjCpf,
  SelecionarPessoaEnderecoEntrega,
  SelecionarPessoa,
} from './PesquisaCnpjCpf';
import { PesquisarCep, SelecionarEndereco } from './PesquisaCep';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import UseFuncoesPadrao from '../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import Lista from './ListaDetalheEnderecos/Lista';
import PessoaEnderecoHook from './ListaDetalheEnderecos/Hooks';
import InputBuscaNomeRazaoSocial from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial';
import { PesquisaNomeRazaoSocial } from './PesquisaNomeRazaoSocial';
import { PesquisaNomeFantasia } from './PesquisaNomeFantasia';
import IPesquisaPessoaNomeRazaoSocialDetalheRef from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial/PesquisaPessoaNomeRazaoSocialDetalhe/Interface/IPesquisaPessoaNomeRazaoSocialDetalheRef';
import PesquisaPessoaNomeRazaoSocialDetalhe from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial/PesquisaPessoaNomeRazaoSocialDetalhe';
import PessoaObterPorCnpjCpfComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorCnpjCpfComunicador';
import PessoaObterPorNomeRazaoSocialComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorNomeRazaoSocialComunicador';
import PessoaObterPorNomeFantasiaComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorNomeFantasiaComunicador';
import { PesquisaPessoaNomeRazaoSocialProvider } from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial/PesquisaPessoaNomeRazaoSocialDetalhe/Hooks/PesquisaPessoaNomeRazaoSocialProvider';
import JanelaLoading from '../../../../../../Componentes/JanelaLoading';
import Janela, {
  IJanelaRef,
} from '../../../../../../Componentes/JanelaAutocomplete';
import InputBuscaNomeFantasia from '../../../../../../Componentes/Inputs/InputBuscaNomeFantasia';
import { PesquisaPessoaNomeFantasiaProvider } from '../../../../../../Componentes/Inputs/InputBuscaNomeFantasia/PesquisaPessoaNomeFantasiaDetalhe/Hooks/PesquisaPessoaNomeFantasiaProvider';
import PesquisaPessoaNomeFantasiaDetalhe from '../../../../../../Componentes/Inputs/InputBuscaNomeFantasia/PesquisaPessoaNomeFantasiaDetalhe';
import IPesquisaPessoaNomeFantasiaDetalheRef from '../../../../../../Componentes/Inputs/InputBuscaNomeFantasia/PesquisaPessoaNomeFantasiaDetalhe/Interface/IPesquisaPessoaNomeFantasiaDetalheRef';
import { UseAutoComplete } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';

const GeralTab: React.FC = () => {
  const {
    getIdDetalheRegistro,
    formRef,
    refresh,
    loading,
    setLoading,
    inputRefFocus,
  } = UseForm();
  const idDetalheRegistro = getIdDetalheRegistro();
  const { permissoes } = UsePermissoes();
  const { isJanelaAutoComplete } = UseAutoComplete();

  const { ComercialPessoasPessoa: permissao } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const janelaCnpjCpfRef = useRef<IPesquisaPessoaCnpjCpfDetalheRef>(null);
  const janelaLoadingRef = useRef<IJanelaRef>(null);

  const janelaNomeRazaoSocialRef =
    useRef<IPesquisaPessoaNomeRazaoSocialDetalheRef>(null);

  const janelaNomeFantasiaRef =
    useRef<IPesquisaPessoaNomeFantasiaDetalheRef>(null);

  const janelaCepRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const janelaCnpjCpfEnderecoEntregaRef =
    useRef<IPesquisaPessoaCnpjCpfDetalheRef>(null);
  const janelaCepEnderecoEntregaRef =
    useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const modalPessoaRef = useRef<IModalPessoaRef>(null);

  const [uf, setUf] = useState('');

  const isCnpj = formRef.current?.getFieldValue('cnpjCpfVirtual').length === 14;
  const isExterior = CompararStringsIguaisIgnoraAcentuacaoMinusculas(
    formRef.current?.getFieldValueNomeObjeto('pessoaEnderecoPrincipal.cidade')
      ?.estado.sigla,
    'EX'
  );

  const isOutroEnderecoEntrega = formRef.current?.getFieldValue(
    'pessoaEnderecoEntrega.outroEnderecoEntrega'
  );

  useEffect(() => {
    if (isCnpj) {
      formRef.current?.setFieldValue('motorista', false);
      formRef.current?.setFieldValue('tecnico', false);
    }
  });

  // #region Pesquisa CPF/CNPJ e Pessoa

  const handlePesquisarCnpjCpf = useCallback(async (): Promise<void> => {
    await PesquisaCnpjCpf(formRef, janelaCnpjCpfRef);
  }, [formRef]);

  const handleOnSelecionarPessoa = useCallback(
    async (pessoa: IPessoa) => {
      await SelecionarPessoa(formRef, pessoa);
    },
    [formRef]
  );

  const handleChangeCnpjCpf = useCallback(
    async (event: IOnBlurInputCnpjCpfValidoEvent) => {
      try {
        if (!event.isZero && event.mudou && event.valor) {
          const response = await PessoaObterPorCnpjCpfComunicador.index({
            params: {
              cnpjCpf: event.valor,
              listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
            },
          });

          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  const handleChangeInscricaoEstadual = useCallback(
    (event: IOnChangeValueInputInscricaoEstadualEvent) => {
      if (event.valido) {
        const icmsRef = formRef.current?.getFieldRef('contribuinteIcms');
        if (icmsRef && event.value) {
          icmsRef.checked = true;
        }
      }
    },
    [formRef]
  );

  const handlePesquisarNomeRazaoSocial =
    useCallback(async (): Promise<void> => {
      setLoading(true);
      janelaLoadingRef.current?.toggleJanela(true);
      await PesquisaNomeRazaoSocial(formRef, janelaNomeRazaoSocialRef);
    }, [formRef, setLoading]);

  const handleChangeNomeRazaoSocial = useCallback(
    async (event: any) => {
      try {
        if (event.mudou) {
          const response = await PessoaObterPorNomeRazaoSocialComunicador.index(
            {
              params: {
                nomeRazaoSocial: event.valor,
                listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
              },
            }
          );

          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  const handlePesquisarNomeFantasia = useCallback(async (): Promise<void> => {
    setLoading(true);
    janelaLoadingRef.current?.toggleJanela(true);
    await PesquisaNomeFantasia(formRef, janelaNomeFantasiaRef);
  }, [formRef, setLoading]);

  const pararLoading = useCallback(async (): Promise<void> => {
    janelaLoadingRef.current?.toggleJanela(false);
    setLoading(false);
  }, [setLoading]);

  const handleChangeNomeFantasia = useCallback(
    async (event: any) => {
      try {
        if (event.mudou) {
          const response = await PessoaObterPorNomeFantasiaComunicador.index({
            params: {
              nomeFantasia: event.valor,
              listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
            },
          });

          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  // #endregion Pesquisa CPF/CNPJ e Pessoa

  // #region Pesquisa CEP e Endereco

  const handlePesquisarCep = useCallback(async (): Promise<void> => {
    await PesquisarCep(formRef, janelaCepRef);
  }, [formRef]);

  const handleOnSelecionarEndereco = useCallback(
    async (endereco: IEndereco) => {
      await SelecionarEndereco(formRef, endereco);
    },
    [formRef]
  );

  const handleChangeCidade = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'pessoaEnderecoPrincipal.cidade.estado.sigla',
        event.itemAtual?.estado?.sigla || ''
      );

      setUf(event.itemAtual?.estado.sigla || '');
    },
    [formRef]
  );

  const handleChangeCidadeNaturalidade = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'pessoaFisica.cidadeNaturalidade.estado.sigla',
        event.itemAtual?.estado?.sigla || ''
      );
    },
    [formRef]
  );

  // #endregion Pesquisa CEP e Endereco

  // #region Pesquisa CEP Endereco Entrega e CPF/CNPJ Endereco Entrega

  const handlePesquisarCepEnderecoEntrega =
    useCallback(async (): Promise<void> => {
      const dados = formRef.current?.getDataPesquisa();

      const cidade = formRef.current?.getFieldValueNomeObjeto(
        'pessoaEnderecoEntrega.cidade'
      );

      await janelaCepEnderecoEntregaRef.current?.pesquisarCep({
        cep: dados.pessoaEnderecoEntrega.cep,
        logradouro: dados.pessoaEnderecoEntrega.logradouro,
        bairro: dados.pessoaEnderecoEntrega.bairro,
        idCidade: cidade?.id || '',
        nomeCidade: cidade?.nome || '',
        siglaEstado: cidade?.estado.sigla || '',
      });
    }, [formRef]);

  const handleOnSelecionarEnderecoEntrega = useCallback(
    (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'pessoaEnderecoEntrega.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue(
        'pessoaEnderecoEntrega.bairro',
        endereco.bairro
      );
      formRef.current?.setFieldValue(
        'pessoaEnderecoEntrega.cidade',
        endereco.cidade
      );
    },
    [formRef]
  );

  const handlePesquisarCnpjCpfPessoaEnderecoEntrega = useCallback(async () => {
    await PesquisaCnpjCpfPessoaEnderecoEntrega(
      formRef,
      janelaCnpjCpfEnderecoEntregaRef
    );
  }, [formRef]);

  const handleOnSelecionarPessoaEnderecoEntrega = useCallback(
    async (enderecoEntrega: IPessoa) => {
      await SelecionarPessoaEnderecoEntrega(formRef, enderecoEntrega);
    },
    [formRef]
  );

  const handleChangeCidadeEnderecoEntrega = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'pessoaEnderecoEntrega.cidade.estado.sigla',
        event.itemAtual?.estado?.sigla || ''
      );
    },
    [formRef]
  );

  // #endregion Pesquisa CEP Endereco Entrega e CPF/CNPJ Endereco Entrega

  // #region Lista Enderecos

  const [showFormModalEnderecos, setShowFormModalEnderecos] = useState(false);

  const handleAdicionarEnderecos = useCallback(async (): Promise<void> => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModalEnderecos(true);
  }, [handleSalvarContinuar, isJanelaAutoComplete]);

  const handleFecharFormModalAdicionarEnderecos =
    useCallback(async (): Promise<void> => {
      setShowFormModalEnderecos(false);
    }, []);

  // #endregion Lista Enderecos

  return (
    <>
      <div>
        <Divisor>
          <Row className="div-duas-linhas">
            <Col xl={10} lg={12}>
              <Row>
                <Col xl={2} lg={12}>
                  <InputInteiroNulavel
                    label="Código"
                    name="codigo"
                    placeholder="Código"
                  />
                </Col>
                <Col xl={6} lg={12} className={isExterior ? 'hidden' : ''}>
                  <Row>
                    <Col xl={6} lg={12}>
                      <InputCnpjCpf
                        ref={inputRefFocus}
                        label="CNPJ/CPF"
                        name="cnpjCpfVirtual"
                        placeholder="CNPJ/CPF"
                        loading={loading}
                        onBlurValido={async (event) => {
                          refresh();
                          await handleChangeCnpjCpf(event);
                        }}
                        onPesquisarCnpjCpf={handlePesquisarCnpjCpf}
                      />
                      <PesquisaPessoaCnpjCpfDetalhe
                        handleOnSelecionarPessoa={handleOnSelecionarPessoa}
                        ref={janelaCnpjCpfRef}
                      />
                    </Col>
                    <Col xl={6} lg={12} className={isExterior ? 'hidden' : ''}>
                      <InputInscricaoEstadual
                        label="Inscrição Estadual"
                        name="inscricaoEstadual"
                        placeholder="Inscrição Estadual"
                        maxLength={14}
                        uf={uf}
                        onChangeValue={async (event) => {
                          handleChangeInscricaoEstadual(event);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={2}
                  lg={12}
                  className={isExterior ? 'hidden' : 'bottom'}
                >
                  <div className="checkbox-container">
                    <InputCheckbox
                      label="Contribuinte Icms"
                      name="contribuinteIcms"
                    />
                  </div>
                </Col>

                <Col
                  xl={2}
                  lg={12}
                  className={isExterior || !isCnpj ? 'hidden' : ''}
                >
                  <Input
                    label="Inscrição Municipal"
                    name="inscricaoMunicipal"
                    placeholder="Inscrição Municipal"
                    maxLength={15}
                    disabled
                  />
                </Col>

                {!isCnpj && (
                  <Col xl={2} lg={12} className={isExterior ? 'hidden' : ''}>
                    <Input
                      label="RG"
                      name="rg"
                      placeholder="RG"
                      maxLength={11}
                    />
                  </Col>
                )}

                <Col xl={10} lg={12} className={isExterior ? '' : 'hidden'}>
                  <Input
                    label="Número do Passaporte, ou outro documento legal do Estrangeiro"
                    name="documentoIdentificacaoEstrangeiro"
                    maxLength={20}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={isCnpj ? 6 : 9} lg={12}>
                  <InputBuscaNomeRazaoSocial
                    name="nomeRazaoSocial"
                    label={isCnpj ? 'Nome Razão Social' : 'Nome'}
                    placeholder={isCnpj ? 'Nome Razão Social' : 'Nome'}
                    onPesquisarNomeRazaoSocial={handlePesquisarNomeRazaoSocial}
                    loading={loading}
                    onBlur={async (event) => {
                      await handleChangeNomeRazaoSocial(event);
                    }}
                    permitirPesquisar={false}
                  />
                  <PesquisaPessoaNomeRazaoSocialProvider>
                    <PesquisaPessoaNomeRazaoSocialDetalhe
                      handleOnSelecionarPessoa={handleOnSelecionarPessoa}
                      pararLoading={pararLoading}
                      ref={janelaNomeRazaoSocialRef}
                    />
                  </PesquisaPessoaNomeRazaoSocialProvider>
                </Col>

                {!isCnpj && (
                  <Col xl={3} lg={12}>
                    <Select
                      label="Sexo"
                      name="pessoaFisica.sexo"
                      options={FormatarEnum({
                        enumObj: SexoEnum,
                        nullavel: true,
                      })}
                    />
                  </Col>
                )}

                <Col
                  xl={isExterior ? 12 : 6}
                  lg={12}
                  className={isCnpj || isExterior ? '' : 'hidden'}
                >
                  <InputBuscaNomeFantasia
                    name="nomeFantasia"
                    label={isCnpj ? 'Nome Fantasia' : 'Nome'}
                    placeholder={isCnpj ? 'Nome Fantasia' : 'Nome'}
                    onPesquisarNomeFantasia={handlePesquisarNomeFantasia}
                    loading={loading}
                    onBlur={async (event) => {
                      await handleChangeNomeFantasia(event);
                    }}
                  />
                  <PesquisaPessoaNomeFantasiaProvider>
                    <PesquisaPessoaNomeFantasiaDetalhe
                      handleOnSelecionarPessoa={handleOnSelecionarPessoa}
                      pararLoading={pararLoading}
                      ref={janelaNomeFantasiaRef}
                    />
                  </PesquisaPessoaNomeFantasiaProvider>
                </Col>
              </Row>

              <Janela titulo="Buscando..." ref={janelaLoadingRef}>
                <JanelaLoading isLoading={loading} />
              </Janela>
            </Col>
            <Col xl={2} lg={12}>
              <div>
                <div className="checkbox-container">
                  <InputCheckbox
                    label="Cliente"
                    name="cliente"
                    onChange={() => {
                      refresh();
                    }}
                  />
                </div>
                <div className="checkbox-container">
                  <InputCheckbox label="Fornecedor" name="fornecedor" />
                </div>
                <div className="checkbox-container">
                  <InputCheckbox label="Transportador" name="transportador" />
                </div>
                <div className="checkbox-container">
                  <InputCheckbox label="Representante" name="representante" />
                </div>
                <div className="checkbox-container">
                  <InputCheckbox
                    label="Produtor"
                    name="produtor"
                    onChange={() => {
                      refresh();
                    }}
                  />
                </div>
              </div>
              <div style={{ display: isCnpj ? 'none' : 'block' }}>
                <div className="checkbox-container">
                  <InputCheckbox label="Motorista" name="motorista" />
                </div>
                <div className="checkbox-container">
                  <InputCheckbox label="Técnico" name="tecnico" />
                </div>
              </div>
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col xl={3} lg={12}>
              <InputCep
                name="pessoaEnderecoPrincipal.cep"
                label="CEP"
                placeholder="CEP"
                loading={loading}
                onPesquisarCep={handlePesquisarCep}
              />
              <PesquisaEnderecoCepDetalhe
                handleOnSelecionarEndereco={handleOnSelecionarEndereco}
                ref={janelaCepRef}
              />
            </Col>
            <Col xl={7} lg={12}>
              <InputAutoCompleteCidadeUf
                label="Cidade"
                placeholder="Cidade"
                name="pessoaEnderecoPrincipal.idCidade"
                nomeObjeto="pessoaEnderecoPrincipal.cidade"
                onChangeItemAtual={handleChangeCidade}
                onChangeItemAtualAposCarregarSemClear={() => {
                  refresh();
                }}
              />
            </Col>

            <Col xl={2} lg={12}>
              <InputVisualSemValor
                name="pessoaEnderecoPrincipal.cidade.estado.sigla"
                label="UF"
                disabled
                type="text"
                placeholder="UF"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12}>
              <Input
                label="Logradouro"
                name="pessoaEnderecoPrincipal.logradouro"
                placeholder="Logradouro"
                maxLength={60}
              />
            </Col>
            <Col xl={2} lg={12}>
              <Input
                label="Número"
                name="pessoaEnderecoPrincipal.numero"
                placeholder="Número"
                maxLength={60}
              />
            </Col>
            <Col xl={4} lg={12}>
              <Input
                label="Bairro"
                name="pessoaEnderecoPrincipal.bairro"
                type="text"
                placeholder="Bairro"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Input
                label="Complemento"
                name="pessoaEnderecoPrincipal.complemento"
                type="text"
                placeholder="Complemento"
                maxLength={60}
              />
            </Col>
            <Col xl={6}>
              <Input
                label="Site"
                name="pessoaEnderecoPrincipal.site"
                type="text"
                placeholder="Site"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6} md={6} sm={6} className="bottom">
              <button
                type="button"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionarEnderecos}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdAddCircle />
                  <span style={{ marginLeft: 10 }}>Mais Endereços</span>
                </TextoLoading>
              </button>
            </Col>
            <Col
              xl={4}
              lg={6}
              md={6}
              sm={12}
              className={isExterior ? 'hidden' : 'bottom'}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Outro endereço de entrega"
                  name="pessoaEnderecoEntrega.outroEnderecoEntrega"
                  onChange={() => {
                    refresh();
                  }}
                />
              </div>
            </Col>
          </Row>
        </Divisor>

        <Divisor
          className={!isOutroEnderecoEntrega || isExterior ? 'hidden' : ''}
        >
          <h6>Dados do Endereço de Entrega</h6>

          <Row>
            <Col xl={3} lg={12}>
              <InputCnpjCpf
                label="CNPJ/CPF"
                name="pessoaEnderecoEntrega.cnpjCpfVirtual"
                type="text"
                placeholder="CNPJ/CPF"
                onPesquisarCnpjCpf={handlePesquisarCnpjCpfPessoaEnderecoEntrega}
                loading={loading}
              />
              <PesquisaPessoaCnpjCpfDetalhe
                handleOnSelecionarPessoa={
                  handleOnSelecionarPessoaEnderecoEntrega
                }
                ref={janelaCnpjCpfEnderecoEntregaRef}
              />
            </Col>

            <Col xl={3} lg={12}>
              <Input
                label="Inscrição Estadual"
                name="pessoaEnderecoEntrega.inscricaoEstadual"
                type="text"
                placeholder="Inscrição Estadual"
                maxLength={14}
              />
            </Col>
            <Col xl={6} lg={12}>
              <Input
                label="Nome Razão Social"
                name="pessoaEnderecoEntrega.nomeRazaoSocial"
                type="text"
                placeholder="Nome Razão Social"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={12}>
              <InputCep
                name="pessoaEnderecoEntrega.cep"
                label="CEP"
                placeholder="CEP"
                loading={loading}
                onPesquisarCep={handlePesquisarCepEnderecoEntrega}
              />
              <PesquisaEnderecoCepDetalhe
                handleOnSelecionarEndereco={handleOnSelecionarEnderecoEntrega}
                ref={janelaCepEnderecoEntregaRef}
              />
            </Col>
            <Col xl={7} lg={12}>
              <InputAutoCompleteCidadeUf
                label="Cidade"
                placeholder="Cidade"
                name="pessoaEnderecoEntrega.idCidade"
                nomeObjeto="pessoaEnderecoEntrega.cidade"
                onChangeItemAtual={handleChangeCidadeEnderecoEntrega}
              />
            </Col>
            <Col xl={2} lg={12}>
              <InputVisualSemValor
                name="pessoaEnderecoEntrega.cidade.estado.sigla"
                label="UF"
                disabled
                type="text"
                placeholder="UF"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12}>
              <Input
                label="Logradouro"
                name="pessoaEnderecoEntrega.logradouro"
                placeholder="Logradouro"
                maxLength={60}
              />
            </Col>
            <Col xl={2} lg={12}>
              <Input
                label="Número"
                name="pessoaEnderecoEntrega.numero"
                placeholder="Número"
                maxLength={60}
              />
            </Col>
            <Col xl={4} lg={12}>
              <Input
                label="Bairro"
                name="pessoaEnderecoEntrega.bairro"
                type="text"
                placeholder="Bairro"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12}>
              <Input
                label="Complemento"
                name="pessoaEnderecoEntrega.complemento"
                type="text"
                placeholder="Complemento"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} sm={12}>
              <InputTelefone
                label="Telefones"
                name="listaPessoaEnderecoEntregaTelefone"
              />
            </Col>
            <Col xl={6} lg={6} sm={12}>
              <InputEmailTag
                label="E-mails"
                name="listaPessoaEnderecoEntregaEmail"
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col xl={6} lg={6} sm={12}>
              <InputTelefone label="Telefones" name="listaPessoaTelefone" />
            </Col>
            <Col xl={6} lg={6} sm={12}>
              <InputEmailTag label="E-mails" name="listaPessoaEmail" />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={12} sm={12}>
              <InputAutoCompleteRamoAtividadeProfissao
                label={!isCnpj ? 'Profissão' : 'Ramo de Atividade'}
                name="idRamoAtividadeProfissao"
                nomeObjeto="ramoAtividadeProfissao"
                placeholder={!isCnpj ? 'Profissão' : 'Ramo de Atividade'}
                filtroTipo={!isCnpj ? 'Profissao' : 'RamoAtividade'}
              />
            </Col>

            <Col xl={2} lg={12}>
              <Input
                label="Reg. Minist. Agricultura"
                name="registroMinisterioAgricultura"
                placeholder="Reg. Minist. Agricultura"
                maxLength={10}
              />
            </Col>
            <Col xl={2} lg={12}>
              <Input
                label="Inscrição Suframa"
                name="inscricaoSuframa"
                placeholder="Inscrição Suframa"
                maxLength={9}
              />
            </Col>
            <Col xl={2} lg={12}>
              <Select
                label="Regime Tributário"
                name="regimeTributario"
                options={FormatarEnum({
                  enumObj: RegimeTributarioPessoaEnum,
                  nullavel: true,
                })}
              />
            </Col>
          </Row>
        </Divisor>

        {!isCnpj && (
          <Divisor>
            <Row>
              <Col xl={2} lg={12}>
                <Select
                  label="Estado Civil"
                  name="pessoaFisica.estadoCivil"
                  options={FormatarEnum({
                    enumObj: EstadoCivilEnum,
                    nullavel: true,
                  })}
                />
              </Col>
              <Col xl={2} lg={12}>
                <InputDateSemTimeZone
                  label="Data Nascimento"
                  name="pessoaFisica.dataNascimento"
                />
              </Col>
              <Col xl={6} lg={12}>
                <InputAutoCompleteCidadeUf
                  label="Cidade Naturalidade"
                  placeholder="Cidade Naturalidade"
                  name="pessoaFisica.idCidadeNaturalidade"
                  nomeObjeto="pessoaFisica.cidadeNaturalidade"
                  onChangeItemAtual={handleChangeCidadeNaturalidade}
                />
              </Col>
              <Col xl={2} lg={12}>
                <InputVisualSemValor
                  name="pessoaFisica.cidadeNaturalidade.estado.sigla"
                  label="UF"
                  disabled
                  placeholder="UF"
                />
              </Col>
            </Row>

            <Row>
              <Col xl={6} lg={12}>
                <Input
                  label="Nome do Pai"
                  name="pessoaFisica.nomePai"
                  placeholder="Nome do Pai"
                  maxLength={60}
                />
              </Col>
              <Col xl={6} lg={12}>
                <Input
                  label="Nome da Mãe"
                  name="pessoaFisica.nomeMae"
                  type="text"
                  placeholder="Nome da Mãe"
                  maxLength={60}
                />
              </Col>
            </Row>
          </Divisor>
        )}

        <Divisor>
          <Row>
            <Col xl={7} lg={12}>
              <Textarea
                label="Observação"
                name="observacoes"
                placeholder="Observação"
                maxLength={10000}
                onChange={(event) => {
                  const quantidadeDeLinhas =
                    event.target.value.split('\n').length;
                  const height = `${20 + 15 * quantidadeDeLinhas}px`;
                  event.target.style.height = height;
                }}
              />
            </Col>

            <Col xl={3} lg={6}>
              <InputDate label="Data do Cadastro" name="dataCadastro" />
            </Col>

            <Col xl={2} lg={6}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>
        <ModalPessoa ref={modalPessoaRef} />
      </div>

      {showFormModalEnderecos && (
        <PessoaEnderecoHook>
          <Lista
            onFecharFormModalLista={handleFecharFormModalAdicionarEnderecos}
          />
        </PessoaEnderecoHook>
      )}
    </>
  );
};

export default GeralTab;
