import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '/transporte/conhecimento-transporte/documento-anterior',
        novo: '/transporte/conhecimento-transporte',
        alterar: '/transporte/conhecimento-transporte/documento-anterior',
        excluir: '/transporte/conhecimento-transporte/documento-anterior',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
