import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioPDF {
  dados: IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const EstoqueSaldoProprioTerceiroPorLocalEstoquePDF: React.FC<
  IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPessoa && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Pessoa: '}</Text>
                      {dados.cabecalho.filtroPessoa}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroLocalEstoque && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Local de Estoque: '}
                      </Text>
                      {dados.cabecalho.filtroLocalEstoque}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Somente Produtos com Saldo
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '6%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '24%' }}>
              <Text>Produto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%' }}>
              <Text>Unidade</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Saldo</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '22%' }}>
              <Text>Tipo</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '23%' }}>
              <Text>Terceiro</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          {dados.listaDados.map((dado) => {
            return (
              <View
                key={dado.chave}
                style={{
                  borderBottom: '1pt dashed black',
                  paddingVertical: '5pt',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingHorizontal: '10pt',
                    marginBottom: '5pt',
                  }}
                >
                  <View style={{ alignSelf: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold' }}>
                      {`Local de Estoque: ${dado.listaEstoqueCombinacao[0].localEstoqueDescricao} `}
                    </Text>
                  </View>
                </View>

                {dado.listaEstoqueCombinacao.map((item, i) => (
                  <View
                    key={String(i)}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: i % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '6%', alignItems: 'flex-end' }}>
                      <Text>
                        {item.estoqueCombinacao.produto.codigo.InserirEspacoRepetindoPorNumeroCaracteres(
                          9
                        )}
                      </Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '24%' }}>
                      <Text>{item.estoqueCombinacao.produto.descricao}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '6%', alignItems: 'center' }}>
                      <Text>
                        {
                          item.estoqueCombinacao.produto.produtoEstoque
                            .unidadeMedida.sigla
                        }
                      </Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '13%', alignItems: 'flex-end' }}>
                      <Text>{item.saldo}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '22%' }}>
                      <Text>{item.estoqueCombinacao.estoqueIndicadorTipo}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '23%' }}>
                      <Text>
                        {item.estoqueCombinacao.pessoa.nomeRazaoSocial}
                      </Text>
                    </View>
                    <View style={{ width: '1%' }} />
                  </View>
                ))}
              </View>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default EstoqueSaldoProprioTerceiroPorLocalEstoquePDF;
