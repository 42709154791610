import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/pedido-compra-importacao-cotacao-compra/gerar';

interface IPedidoCompraImportacaoCotacaoCompraGerarComunicadorStore {
  params: any;
}

class PedidoCompraImportacaoCotacaoCompraGerarComunicador {
  public async store(
    params: IPedidoCompraImportacaoCotacaoCompraGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new PedidoCompraImportacaoCotacaoCompraGerarComunicador();
