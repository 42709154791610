import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/impressao';

interface IManifestoTransporteImpressaoComunicadorShow {
  id: string;
}

class ManifestoTransporteImpressaoComunicador {
  public async show(
    params: IManifestoTransporteImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteImpressaoComunicador();
