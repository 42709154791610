const EstoqueSaldoProprioEmPoderProprioPorGrupoPlanilha = [
  {
    titulo: 'Grupo',
    valor:
      'listaEstoqueCombinacao.estoqueCombinacao.produto.grupoProdutoServico.descricao',
  },
  {
    titulo: 'Código',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.produto.codigo',
  },
  {
    titulo: 'Descrição',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor:
      'listaEstoqueCombinacao.estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'listaEstoqueCombinacao.saldo' },
];

export default EstoqueSaldoProprioEmPoderProprioPorGrupoPlanilha;
