import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/nota-fiscal-entrada/nota-fiscal-entrada-resumo-por-cfop';

interface INotaFiscalEntradaResumoPorCfopRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalEntradaResumoPorCfopRelatorioComunicador {
  public async index(
    params: INotaFiscalEntradaResumoPorCfopRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalEntradaResumoPorCfopRelatorioComunicador();
