import React from 'react';
import CategoriaLista from './Lista';
import { TreeContextProvider } from './Hooks/TreeContext';

const Categoria: React.FC = () => {
  return (
    <TreeContextProvider>
      <CategoriaLista />
    </TreeContextProvider>
  );
};

export default Categoria;
