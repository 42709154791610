import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/obter-dados-padrao';

class ConhecimentoTransporteObterDadosPadraoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(`${rota}`);
    return response.data;
  }
}

export default new ConhecimentoTransporteObterDadosPadraoComunicador();
