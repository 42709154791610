import React from 'react';
import { RotasProvider } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '/financeiro/geral/portador',
        detalhes: '/financeiro/geral/portador/detalhe',
        listagem: '/financeiro/geral/portador',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
