const NotaFiscalSaidaCfopCsosnCstIcmsComOrigemPlanilha = [
  { titulo: 'CFOP', valor: 'valores.chaveFormatada' },

  { titulo: 'Numero', valor: 'listaNotaFiscalSaidaItem.numero' },
  {
    titulo: 'Série',
    valor: 'listaNotaFiscalSaidaItem.serie',
  },

  {
    titulo: 'Data de Emissão',
    valor: 'listaNotaFiscalSaidaItem.dataHoraEmissao',
  },

  {
    titulo: 'CNPJ/CPF',
    valor: 'listaNotaFiscalSaidaItem.cnpjCpfVirtual',
  },

  {
    titulo: 'Inscr Est.',
    valor: 'listaNotaFiscalSaidaItem.inscricaoEstadual',
  },

  {
    titulo: 'Destinado',
    valor: 'listaNotaFiscalSaidaItem.destinado',
  },

  {
    titulo: 'Código',
    valor: 'listaNotaFiscalSaidaItem.produto.codigo',
  },

  {
    titulo: 'Descrição',
    valor: 'listaNotaFiscalSaidaItem.produto.descricao',
  },

  {
    titulo: 'Origem',
    valor: 'listaNotaFiscalSaidaItem.origemIcms.codigo',
  },

  {
    titulo: 'Aliquota ICMS',
    valor: 'listaNotaFiscalSaidaItem.aliquotaIcms',
  },

  {
    titulo: 'CSOSN/CST',
    valor: 'listaNotaFiscalSaidaItem.codigoCsosnCst',
  },

  {
    titulo: 'Val Tot Produtos',
    valor: 'listaNotaFiscalSaidaItem.valorTotalProduto',
  },

  {
    titulo: 'Val Tot Bruto',
    valor: 'listaNotaFiscalSaidaItem.valorTotalBruto',
  },
];

export default NotaFiscalSaidaCfopCsosnCstIcmsComOrigemPlanilha;
