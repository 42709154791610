import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IOrdemServicoRetratoComFotoProdutoComSemValoresItensImpressao,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IOrdemServicoRetratoComFotoProdutoSemValoresItensImpressaoPDF {
  dados: IOrdemServicoRetratoComFotoProdutoComSemValoresItensImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const OrdemServicoRetratoComFotoProdutoSemValoresItensImpressaoPDF: React.FC<
  IOrdemServicoRetratoComFotoProdutoSemValoresItensImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                style={{
                  width: 120,
                  borderRadius: 0,
                }}
              />
            )}
          </View>
          <View
            style={{
              width: '50%',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                marginTop: '10pt',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                }}
              >
                ORDEM DE SERVIÇO
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {`CNPJ/CPF: ${dados.empresa.cnpjCpf} `}
                {dados.empresa.inscricaoEstadual &&
                  `/ IE: ${dados.empresa.inscricaoEstadual}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {`${dados.empresa.empresaEndereco.logradouro}`}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`, ${dados.empresa.empresaEndereco.bairro}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.telefone && `${dados.empresa.telefone}, `}
                {dados.empresa.email && dados.empresa.email}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '25%',
              height: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',

                alignItems: 'flex-end',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 33,
                }}
              >
                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}
                  >
                    {`Número: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-start',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}
                  >
                    {dados.numero}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: 2,
                }}
              >
                <View
                  style={{
                    width: '50%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    {`Data Emissão: `}
                  </Text>
                </View>

                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    width: '50%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    {`Prev. Entrega: `}
                  </Text>
                </View>

                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {FormatarDataParaPtBr(dados.dataTermino)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View
            style={{
              width: '100%',
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'Cliente: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {`${dados.ordemServicoPessoa.pessoa.codigo} - ${dados.ordemServicoPessoa.pessoa.nomeRazaoSocial}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', width: '60%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '20%' }}
                  >
                    {`CNPJ/CPF: `}
                  </Text>
                  <Text style={{ fontSize: 10, width: '80%' }}>
                    {dados.ordemServicoPessoa.pessoa.cnpjCpf}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {`Endereço: `}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.ordemServicoPessoaEndereco.logradouro &&
                    `${dados.ordemServicoPessoaEndereco.logradouro}, `}
                  {dados.ordemServicoPessoaEndereco.numero &&
                    `${dados.ordemServicoPessoaEndereco.numero}, `}
                  {dados.ordemServicoPessoaEndereco.bairro &&
                    `${dados.ordemServicoPessoaEndereco.bairro}, `}
                  {dados.ordemServicoPessoaEndereco.complemento &&
                    dados.ordemServicoPessoaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'Cidade: '}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.ordemServicoPessoaEndereco?.cidade &&
                    `${dados.ordemServicoPessoaEndereco?.cidade?.nome} - ${dados.ordemServicoPessoaEndereco?.cidade?.estado.sigla}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'CEP: '}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.ordemServicoPessoaEndereco.cep}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '60%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      width: '20%',
                    }}
                  >
                    {'E-mail: '}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: '80%',
                    }}
                  >
                    {dados.ordemServicoPessoa.pessoa.email}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '40%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      width: '20%',
                    }}
                  >
                    {'Telefone: '}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: '80%',
                    }}
                  >
                    {dados.ordemServicoPessoa.pessoa.telefone}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '60%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      width: '25%',
                    }}
                  >
                    {'Representante: '}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: '85%',
                      paddingLeft: 1,
                    }}
                  >
                    {dados.ordemServicoComercial &&
                    dados.ordemServicoComercial
                      .listaOrdemServicoComercialRepresentante &&
                    dados.ordemServicoComercial
                      .listaOrdemServicoComercialRepresentante.length > 0
                      ? dados.ordemServicoComercial
                          .listaOrdemServicoComercialRepresentante[0]
                          .pessoaRepresentante.nomeRazaoSocial
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              backgroundColor: '#cdcdcd',
              fontSize: 9,
            }}
          >
            <View
              style={{
                width: '4%',
                alignItems: 'flex-end',
              }}
            >
              <Text>SEQ</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>CÓDIGO</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '72%' }}>
              <Text>DESCRIÇÃO DO PRODUTO</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Qtde.</Text>
            </View>
          </View>

          {dados.listaOrdemServicoItem?.map((dado, index) => {
            return (
              <View
                style={{
                  flexDirection: 'column',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View
                  key={dado.ordem}
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <View style={{ width: '4%', alignItems: 'flex-end' }}>
                    <Text>{dado.ordem}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>{dado.produto.codigo}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '72%' }}>
                    <Text>{dado.produto.descricao}</Text>
                    <Text>{dado.informacoesAdicionaisProduto}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '5%' }}>
                    <Text>{dado.unidadeMedida.sigla}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View
                    style={{
                      width: '7%',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      width: '12%',
                    }}
                  />
                  <View
                    style={{
                      width: '12%',
                      alignItems: 'center',
                      marginTop: '5pt',
                      marginBottom: '5pt',
                    }}
                  >
                    {dados.listaOrdemServicoItem
                      ? dados.listaOrdemServicoItem[index].produto
                          .produtoFoto &&
                        dados.listaOrdemServicoItem[index].produto.produtoFoto
                          ?.caminhoFoto !== '' && (
                          <Image
                            src={
                              dados.listaOrdemServicoItem
                                ? dados.listaOrdemServicoItem[index].produto
                                    .produtoFoto?.caminhoFoto
                                : undefined
                            }
                            style={{
                              width: '80%',
                            }}
                          />
                        )
                      : undefined}
                  </View>
                </View>
              </View>
            );
          })}

          <View style={{ marginTop: '5pt' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 10,
                  width: '100%',
                  alignItems: 'flex-end',
                }}
              >
                {dados.observacoesComerciais ? dados.observacoesComerciais : ''}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: '5pt',
            marginBottom: '5pt',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 8,
          }}
        >
          <Text style={{ width: '3%' }}>ASS: </Text>
          <View style={{ width: '35%', borderBottom: '1pt dotted black' }} />
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrdemServicoRetratoComFotoProdutoSemValoresItensImpressaoPDF;
