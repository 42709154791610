import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/orcamento/orcamento-total-por-modelo-documento';

interface IOrcamentoTotalPorModeloDocumentoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrcamentoTotalPorModeloDocumentoRelatorioComunicador {
  public async index(
    params: IOrcamentoTotalPorModeloDocumentoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrcamentoTotalPorModeloDocumentoRelatorioComunicador();
