import {
  FormatarDataHoraParaPtBr,
  IRetiradaConsignadoFiltroRelatorioBackend,
  IRetiradaConsignadoFiltroRelatorioFrontend,
  IRetiradaConsignadoResumoPorClienteEValorFaturarCabecalhoRelatorio,
  IRetiradaConsignadoResumoPorClienteEValorFaturarRelatorio,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import RetiradaConsignadoResumoPorClienteEValorFaturarPlanilha from './RetiradaConsignadoResumoPorClienteEValorFaturarPlanilha';
import RetiradaConsignadoResumoPorClienteEValorFaturarPDF from './RetiradaConsignadoResumoPorClienteEValorFaturarPDF';
import RetiradaConsignadoResumoPorClienteEValorFaturarRelatorioComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Vendas/RetiradaConsignado/RetiradaConsignadoResumoPorClienteEValorFaturarRelatorio/RetiradaConsignadoResumoPorClienteEValorFaturarRelatorioComunicador';

interface IRelatorios {
  handleExportCSV(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const RetiradaConsignadoResumoPorClienteEValorFaturarRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IRetiradaConsignadoResumoPorClienteEValorFaturarRelatorio | null> => {
      const filtroBack: IRetiradaConsignadoFiltroRelatorioBackend = {
        dataEmissaoInicial: filtros.dataEmissaoInicial,
        dataEmissaoFinal: filtros.dataEmissaoFinal,
      };

      if (filtros?.listaCidade) {
        filtroBack.listaIdCidade =
          filtros.listaCidade.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaEstado) {
        filtroBack.listaIdEstado =
          filtros.listaEstado.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaCliente) {
        filtroBack.listaIdCliente =
          filtros.listaCliente.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaRamoAtividadeProfissao) {
        filtroBack.listaIdRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      const response =
        await RetiradaConsignadoResumoPorClienteEValorFaturarRelatorioComunicador.index(
          {
            params: { filtro: filtroBack },
          }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as IRetiradaConsignadoResumoPorClienteEValorFaturarRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          return {
            ...dados,
            valorTotalAFaturar: Number(
              dados.valorTotalAFaturar
            ).FormatarParaPtBr(),
          };
        })
      );

      return {
        ...retorno,
        listaDados,
        valorTotalAFaturarSomatorio: Number(
          retorno.valorTotalAFaturarSomatorio
        ).FormatarParaPtBr(),
      };
    };

    function FormatarCabecalho(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend,
      cabecalho: IRetiradaConsignadoResumoPorClienteEValorFaturarCabecalhoRelatorio
    ): IRetiradaConsignadoResumoPorClienteEValorFaturarCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataEmissaoInicial = '--/--/--';
        cabecalho.filtroDataEmissaoFinal = '--/--/--';

        if (filtros.dataEmissaoInicial) {
          cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoInicial
          );
        }

        if (filtros.dataEmissaoFinal) {
          cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoFinal
          );
        }
      }

      if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCliente = filtros.listaCliente.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (
        filtros.listaRamoAtividadeProfissao &&
        filtros.listaRamoAtividadeProfissao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEstado = filtros.listaEstado.listaValor
          .map((valor: any) => {
            return valor.siglaNome;
          })
          .join(', ');
      }

      if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCidade = filtros.listaCidade.listaValor
          .map((valor: any) => {
            return valor.cidadeUf;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: RetiradaConsignadoResumoPorClienteEValorFaturarPlanilha,
        nomeArquivo: 'RetiradaConsignadoResumoPorClienteEValorFaturar',
        valores: dados.listaDados,
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: RetiradaConsignadoResumoPorClienteEValorFaturarPlanilha,
        nomeArquivo: 'RetiradaConsignadoResumoPorClienteEValorFaturar',
        valores: dados.listaDados as any,
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <RetiradaConsignadoResumoPorClienteEValorFaturarPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <RetiradaConsignadoResumoPorClienteEValorFaturarPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default RetiradaConsignadoResumoPorClienteEValorFaturarRelatorio();
