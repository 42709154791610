import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/codigo-situacao-tributaria-ipi-auto-complete';

interface ICodigoSituacaoTributariaIpiAutoCompleteComunicadorIndex {
  params: any;
}

class CodigoSituacaoTributariaIpiAutoCompleteComunicador {
  public async index(
    params: ICodigoSituacaoTributariaIpiAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoSituacaoTributariaIpiAutoCompleteComunicador();
