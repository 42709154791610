import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/movimentacoes-financeiras-portadores/movimentacao-por-portador';

interface IMovimentacoesFinanceirasPortadoresRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class MovimentacaoPorPortadorRelatorioComunicador {
  public async index(
    params: IMovimentacoesFinanceirasPortadoresRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new MovimentacaoPorPortadorRelatorioComunicador();
