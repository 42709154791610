import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/situacao-auto-complete';

interface ISituacaoAutoCompleteComunicadorIndex {
  params: any;
}

class SituacaoAutoCompleteComunicador {
  public async index(
    params: ISituacaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SituacaoAutoCompleteComunicador();
