import React from 'react';
import ListaDetalheCteComplementado from './ListaDetalheCteComplementado/Lista';
import Divisor from '../../../../../Componentes/Divisor';
import ConhecimentoTransporteCteComplementadoHook from './ListaDetalheCteComplementado/Hooks';

const CteComplementadoTab: React.FC = () => {
  return (
    <Divisor>
      <ConhecimentoTransporteCteComplementadoHook>
        <ListaDetalheCteComplementado name="listaConhecimentoTransporteCteComplementado" />
      </ConhecimentoTransporteCteComplementadoHook>
    </Divisor>
  );
};

export default CteComplementadoTab;
