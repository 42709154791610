"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoItemEnum;
(function (SituacaoItemEnum) {
    SituacaoItemEnum["pendente"] = "Pendente";
    SituacaoItemEnum["aprovado"] = "Aprovado";
    SituacaoItemEnum["rejeitado"] = "Rejeitado";
    SituacaoItemEnum["cancelado"] = "Cancelado";
})(SituacaoItemEnum || (SituacaoItemEnum = {}));
exports.default = SituacaoItemEnum;
