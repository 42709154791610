import {
  BlocoHFormaGeracaoValoresEnum,
  FormatarEnum,
  RegistroH005MotivoInventario,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputDateSemDia from '../../../../../../Componentes/Inputs/InputDateSemDia';
import Select from '../../../../../../Componentes/Select';

const SpedFiscalTab: React.FC = () => {
  const { formRef, refresh } = UseForm();

  const spedFiscal = formRef.current?.getFieldValue('spedFiscal');
  const spedFiscalCompleto =
    formRef.current?.getFieldValue('spedFiscalCompleto');
  const spedFiscalComBlocoH = formRef.current?.getFieldValue(
    'spedFiscalComBlocoH'
  );

  const hadleAtualizarSped = useCallback(() => {
    if (!spedFiscal && !spedFiscalCompleto) {
      formRef.current?.setFieldValue('spedFiscalTodosProdutos', false);
      formRef.current?.setFieldValue('spedFiscalComBlocoH', false);

      formRef.current?.setFieldValue('spedFiscalMotivoInventario', false);
      formRef.current?.setFieldValue('spedFiscalPeriodoBlocoH', false);
      formRef.current?.setFieldValue(
        'spedFiscalFormatoGeracaoValoresH20',
        false
      );
    }

    refresh();
  }, [formRef, refresh, spedFiscal, spedFiscalCompleto]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="spedFiscal"
                label="Sped Fiscal"
                onChange={() => {
                  refresh();
                  hadleAtualizarSped();
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="spedFiscalCompleto"
                label="Completo (Notas de Saída Completas para Integração Fiscal/Contábil)"
                onChange={() => {
                  refresh();
                  hadleAtualizarSped();
                }}
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={4}
            sm={6}
            style={{ display: spedFiscal || spedFiscalCompleto ? '' : 'none' }}
          >
            <div className="checkbox-container">
              <InputCheckbox
                name="spedFiscalTodosProdutos"
                label="Todos os Produtos (Para Integração Fiscal/Contábil)"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={4}
            sm={6}
            style={{ display: spedFiscal || spedFiscalCompleto ? '' : 'none' }}
          >
            <div className="checkbox-container">
              <InputCheckbox
                name="spedFiscalComBlocoH"
                label="Com Bloco H"
                onChange={() => {
                  refresh();
                }}
              />
            </div>
          </Col>
        </Row>

        <div
          style={{ marginTop: 20, display: spedFiscalComBlocoH ? '' : 'none' }}
        >
          <h5>Bloco H</h5>

          <Row>
            <Col lg={6} md={4} sm={6}>
              <Select
                label="Motivo do Inventário"
                name="spedFiscalMotivoInventario"
                options={FormatarEnum({
                  enumObj: RegistroH005MotivoInventario,
                })}
              />
            </Col>
            <Col lg={6} md={4} sm={6}>
              <InputDateSemDia
                labelMes="Período Bloco H (Mês)"
                labelAno="Período Bloco H (Ano)"
                name="spedFiscalPeriodoBlocoH"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={4} sm={6}>
              <Select
                label="Formato Geraçäo Valores H20"
                name="spedFiscalFormatoGeracaoValoresH20"
                options={FormatarEnum({
                  enumObj: BlocoHFormaGeracaoValoresEnum,
                })}
              />
            </Col>
          </Row>
        </div>
      </Divisor>
    </div>
  );
};

export default SpedFiscalTab;
