import { ICotacaoCompraValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra/obter-itens-calculo-rateios';

interface IObterCotacaoCompraItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterCotacaoCompraItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterCotacaoCompraItensParaCalculoRateiosComunicadorIndex
  ): Promise<ICotacaoCompraValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterCotacaoCompraItensParaCalculoRateiosComunicador();
