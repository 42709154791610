import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/estoques/extrato-por-produto';

interface IExtratoPorProdutoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ExtratoPorProdutoRelatorioComunicador {
  public async index(
    params: IExtratoPorProdutoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ExtratoPorProdutoRelatorioComunicador();
