import { AppErro, INotaFiscalEntradaItemValoresAlterar } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaNotaFiscalEntradaDraggable } from '../../../ItemTab/ListaDetalheItem/Hook/ListaNotaFiscalEntradaDraggableHook';
import NotaFiscalEntradaItemImpostoFiscalComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/NotaFiscalEntradaItemImpostoFiscalComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { setListaValor } = UseListaNotaFiscalEntradaDraggable();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const listaNotaFiscalEntradaItem =
    formPrincipal.formRef.current?.getFieldValue('listaNotaFiscalEntradaItem');

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = formPrincipal.getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof INotaFiscalEntradaItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const response =
          await NotaFiscalEntradaItemImpostoFiscalComunicador.show({
            id: idEditar ?? '',
          });
        await formRefDetalhe.current?.setDataInicial(response);

        await formRefDetalhe.current?.atualizarDataInicial();
        setListaValor(listaNotaFiscalEntradaItem);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);
          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      formPrincipal,
      handleSetarFocus,
      listaNotaFiscalEntradaItem,
      redirecionar,
      refresh,
      setListaValor,
    ]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          notaFiscalEntradaItemImpostoIcmsFiscalSn: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do ICMS do Simples Nacional é Obrigatório!')
              .nullable(),
            carregarCreditarIcmsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar ICMS Conforme Documento é Obrigatório!'
            ),
            creditarIcmsSubstitutoeRetidoConformeDocumento:
              Yup.boolean().required(
                'Calcular ICMS Substituto e Retido Conforme Documento!'
              ),
            calcularIcmsEfetivoConformeDocumento: Yup.boolean().required(
              'Calcular ICMS Efetivo Conforme Documento!'
            ),
          }),

          notaFiscalEntradaItemImpostoIcmsFiscalSnSub: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required(
                'CST do ICMS do Simples Nacional Excesso do Sublimite é Obrigatório!'
              )
              .nullable(),
            carregarCreditarIcmsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar ICMS Conforme Documento é Obrigatório!'
            ),
            creditarIcmsSubstitutoeRetidoConformeDocumento:
              Yup.boolean().required(
                'Calcular ICMS Substituto e Retido Conforme Documento!'
              ),
            calcularIcmsEfetivoConformeDocumento: Yup.boolean().required(
              'Calcular ICMS Efetivo Conforme Documento!'
            ),
          }),

          notaFiscalEntradaItemImpostoIcmsFiscalPresu: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do ICMS do Lucro Presumido é Obrigatório!')
              .nullable(),
            carregarCreditarIcmsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar ICMS Conforme Documento é Obrigatório!'
            ),
            creditarIcmsSubstitutoeRetidoConformeDocumento:
              Yup.boolean().required(
                'Calcular ICMS Substituto e Retido Conforme Documento!'
              ),
            calcularIcmsEfetivoConformeDocumento: Yup.boolean().required(
              'Calcular ICMS Efetivo Conforme Documento!'
            ),
          }),

          notaFiscalEntradaItemImpostoIcmsFiscalReal: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do ICMS do Lucro Real é Obrigatório!')
              .nullable(),
            carregarCreditarIcmsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar ICMS Conforme Documento é Obrigatório!'
            ),
            creditarIcmsSubstitutoeRetidoConformeDocumento:
              Yup.boolean().required(
                'Calcular ICMS Substituto e Retido Conforme Documento!'
              ),
            calcularIcmsEfetivoConformeDocumento: Yup.boolean().required(
              'Calcular ICMS Efetivo Conforme Documento!'
            ),
          }),

          notaFiscalEntradaItemImpostoIpiFiscalSn: Yup.object().shape({
            carregarCreditarIpiConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar IPI do Simples Nacional Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoIpiFiscalSnSub: Yup.object().shape({
            carregarCreditarIpiConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar IPI do Simples Nacional Excesso do Sublimite Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoIpiFiscalPresu: Yup.object().shape({
            carregarCreditarIpiConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar IPI do Lucro Presumido Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoIpiFiscalReal: Yup.object().shape({
            carregarCreditarIpiConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar IPI do Lucro Real Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoPisFiscalSn: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do PIS do Simples Nacional é Obrigatório!')
              .nullable(),
            carregarCreditarPisConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar PIS do Simples Nacional Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoPisFiscalSnSub: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required(
                'CST do PIS do Simples Nacional Excesso do Sublimite é Obrigatório!'
              )
              .nullable(),
            carregarCreditarPisConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar PIS do Simples Nacional Excesso do Sublimite Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoPisFiscalPresu: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do PIS do Lucro Presumido é Obrigatório!')
              .nullable(),
            carregarCreditarPisConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar PIS do Lucro Presumido Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoPisFiscalReal: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do PIS do Lucro Real é Obrigatório!')
              .nullable(),
            carregarCreditarPisConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar PIS do Lucro Real Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoCofinsFiscalSn: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do COFINS do Simples Nacional é Obrigatório!')
              .nullable(),
            carregarCreditarCofinsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar COFINS do Simples Nacional Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoCofinsFiscalSnSub: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required(
                'CST do COFINS do Simples Nacional Excesso do Sublimite é Obrigatório!'
              )
              .nullable(),
            carregarCreditarCofinsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar COFINS do Simples Nacional Excesso do Sublimite Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoCofinsFiscalPresu: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do COFINS do Lucro Presumido é Obrigatório!')
              .nullable(),
            carregarCreditarCofinsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar COFINS do Lucro Presumido Conforme Documento'
            ),
          }),

          notaFiscalEntradaItemImpostoCofinsFiscalReal: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .required('CST do COFINS do Lucro Real é Obrigatório!')
              .nullable(),
            carregarCreditarCofinsConformeDocumento: Yup.boolean().required(
              'Carregar e Creditar COFINS do Lucro Real Conforme Documento'
            ),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id: '', erro: true };
        }

        const response =
          await NotaFiscalEntradaItemImpostoFiscalComunicador.update({
            id,
            params: data,
          });

        ToastSucesso(response.mensagem);
        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, handleValidar]
  );

  const handleExcluir = async (): Promise<void> => {
    throw new AppErro({
      mensagem: 'A função "handleExcluir" não foi implementada no "Cancelar"',
    });
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
