import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/log';

interface INotaFiscalSaidaLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface INotaFiscalSaidaLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class NotaFiscalSaidaLogComunicador {
  public async index(
    params: INotaFiscalSaidaLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: INotaFiscalSaidaLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalSaidaLogComunicador();
