/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { format } from 'date-fns';
import { FormatarDataHoraParaAmericano } from '@elogestor/util';
import { DefaultInput, InputContainer, SpanErro } from '../../Styles';
import { UseForm } from '../../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputTabelaDateProps {
  valorAnterior: string;
}

export interface IInputTabelaDateRef {
  focus(): void;
  value(): Date | null;
  setErro(error: string): void;
  getValueFormatado(): string | null;
}

interface IInputTabelaDateProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valorPadrao: Date;
  valueRef?: React.RefObject<HTMLInputElement>;
  horasDefault?: string;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTabelaDateProps
  ) => void | Promise<void>;
  obterRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}

const InputTabelaDate: React.ForwardRefRenderFunction<
  IInputTabelaDateRef,
  IInputTabelaDateProps
> = (
  {
    valorPadrao,
    valueRef,
    horasDefault = '00:00:00',
    error,

    onFocus,
    onChange,
    onBlur,
    obterRef,
    ...rest
  },
  ref
) => {
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(inputRef);
      }
    }
  }, [obterRef, valueRef]);

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },

    value() {
      return inputRef.current?.value ? new Date(inputRef.current.value) : null;
    },

    setErro(errorInterno: string) {
      setErroInterno(errorInterno);
    },

    getValueFormatado() {
      if (!inputRef.current?.value) return null;

      const data = new Date(
        `${inputRef.current.value} ${horasDefault}`.replace(/-/g, '/')
      );

      const dataFormatada = format(data, 'dd/MM/yyyy');
      return dataFormatada;
    },
  }));

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErroInterno('');
      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(event);
      setIsFocused(false);
      setIsFilled(!!event.target.value);
    },
    [onBlur]
  );

  return (
    <InputContainer>
      <DefaultInput
        defaultValue={FormatarDataHoraParaAmericano(valorPadrao).substring(
          0,
          10
        )}
        ref={valueRef || inputRef}
        $isErrored={!!erroInterno}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="date"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        max="9999-12-31"
        {...rest}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputTabelaDate);
