"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoConhecimentoTransporteEnum;
(function (SituacaoConhecimentoTransporteEnum) {
    SituacaoConhecimentoTransporteEnum["naoTransmitido"] = "N\u00E3o Transmitido";
    SituacaoConhecimentoTransporteEnum["autorizado"] = "Autorizado";
    SituacaoConhecimentoTransporteEnum["denegado"] = "Denegado";
    SituacaoConhecimentoTransporteEnum["cancelado"] = "Cancelado";
    SituacaoConhecimentoTransporteEnum["rejeitado"] = "Rejeitado";
    SituacaoConhecimentoTransporteEnum["emProcessamento"] = "Em Processamento";
})(SituacaoConhecimentoTransporteEnum || (SituacaoConhecimentoTransporteEnum = {}));
exports.default = SituacaoConhecimentoTransporteEnum;
