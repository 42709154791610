"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoMotivoRetornoOcorrenciaEnum;
(function (TipoMotivoRetornoOcorrenciaEnum) {
    TipoMotivoRetornoOcorrenciaEnum["entarada"] = "Entrada";
    TipoMotivoRetornoOcorrenciaEnum["recusa"] = "Recusa";
    TipoMotivoRetornoOcorrenciaEnum["baixa"] = "Baixa";
})(TipoMotivoRetornoOcorrenciaEnum || (TipoMotivoRetornoOcorrenciaEnum = {}));
exports.default = TipoMotivoRetornoOcorrenciaEnum;
