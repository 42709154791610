import {
  EstoqueIndicadorTipoEnum,
  FormatarEnum,
  FormatarRemovendoEnum,
  IRegraEscolhaParametroValoresAlterar,
  RegraEscolhaTipoEnum,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTipoNota from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagNcm from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputAutoCompleteTagModeloDocumento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagSerie from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';

type IProps = IMain<IRegraEscolhaParametroValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { FiscalTributacaoRegraEscolhaParametro: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { setDisableExcluir } = UseButtonsSubHeader();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const tipoMovimento = formRef.current?.getFieldValue('tipoMovimento');
  const regraEscolhaTipo = formRef.current?.getFieldValue('tipo');
  const indicadorTipoEstoque = formRef.current?.getFieldValue(
    'regraEscolhaParametroResultado.indicadorTipoEstoque'
  );

  useEffect(() => {
    if (tipoUsuario !== 'Elo Gestor' && regraEscolhaTipo === 'Padrão') {
      setDisableExcluir(regraEscolhaTipo);
    }
  }, [regraEscolhaTipo, setDisableExcluir, tipoUsuario]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // Não Remover os await - são nescessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (formRef.current?.getFieldValue('idTipoNota')) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('idTipoNotaMotivo')) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroDestinado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroOperacaoFiscal')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroSped')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroNcm').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroProduto')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaParametroModeloDocumento'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroSerie')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaParametroTipoTransacao')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
                options={
                  (FormatarEnum({
                    enumObj: RegraEscolhaTipoEnum,
                  }),
                  [
                    {
                      title: 'Contabilidade',
                      value: RegraEscolhaTipoEnum.contabilidade,
                    },
                    { title: 'Empresa', value: RegraEscolhaTipoEnum.empresa },
                    {
                      title: 'Padrão',
                      value: RegraEscolhaTipoEnum.padrao,
                      hidden: tipoUsuario !== 'Elo Gestor',
                    },
                  ])
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                ref={inputRefFocus}
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaParametroEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={5} md={6} sm={12}>
              <InputAutoCompleteTipoNota
                label="Tipo de Nota"
                name="idTipoNota"
                placeholder="Tipo de Nota"
                nomeObjeto="tipoNota"
                onChangeItemAtual={() => {
                  formRef.current?.clearField('tipoNotaMotivo', {
                    setValorInicial: false,
                  });
                  refresh();
                }}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={5} md={6} sm={12}>
              <InputAutoCompleteTipoNotaMotivo
                label="Motivo"
                name="idTipoNotaMotivo"
                placeholder="Motivo"
                nomeObjeto="tipoNotaMotivo"
                disabled={
                  !formRef.current?.getFieldValue('idTipoNota') ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                idTipoNota={formRef.current?.getFieldValue('idTipoNota')}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo Entrada/Saída"
                name="tipoMovimento"
                options={FormatarEnum({
                  enumObj: TipoMovimentoEntradaSaidaEnum,
                })}
                onChange={() => {
                  refresh();
                  formRef.current?.clearField(
                    'listaRegraEscolhaParametroOperacaoFiscal'
                  );
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaParametroDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagOperacaoFiscal
                label="Lista CFOP"
                name="listaRegraEscolhaParametroOperacaoFiscal"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                tipoEntradaSaida={
                  tipoMovimento === TipoMovimentoEntradaSaidaEnum.entrada
                    ? TipoEntradaSaidaOperacaoFiscalEnum.entrada
                    : TipoEntradaSaidaOperacaoFiscalEnum.saida
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum
                label="Tipo de Transação da Operação Fiscal (CFOP)"
                name="listaRegraEscolhaParametroTipoTransacao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSpedTipoProduto
                label="Tipos SPED"
                name="listaRegraEscolhaParametroSped"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagNcm
                label="NCMs"
                name="listaRegraEscolhaParametroNcm"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                name="listaRegraEscolhaParametroProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagModeloDocumento
                label="Modelos de Documento"
                name="listaRegraEscolhaParametroModeloDocumento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagSerie
                label="Séries"
                name="listaRegraEscolhaParametroSerie"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Tipo Movimento de Estoque"
                name="regraEscolhaParametroResultado.indicadorTipoEstoque"
                options={FormatarRemovendoEnum({
                  enumObj: EstoqueIndicadorTipoEnum,
                  enumRemover:
                    tipoMovimento === TipoMovimentoEntradaSaidaEnum.entrada
                      ? []
                      : [
                          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                        ],
                })}
                onChange={() => {
                  refresh();
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12} className="div-checkbox">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Gerar Financeiro"
                  name="regraEscolhaParametroResultado.gerarFinanceiro"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="div-checkbox"
              style={{
                display:
                  tipoMovimento === TipoMovimentoEntradaSaidaEnum.entrada
                    ? 'none'
                    : '',
              }}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Gerar Faturamento"
                  name="regraEscolhaParametroResultado.gerarFaturamento"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="div-checkbox"
              style={{
                display:
                  tipoMovimento === TipoMovimentoEntradaSaidaEnum.entrada
                    ? 'none'
                    : '',
              }}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Gerar Comissão"
                  name="regraEscolhaParametroResultado.gerarComissao"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="div-checkbox">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Movimenta Estoque Físico"
                  name="regraEscolhaParametroResultado.movimentarEstoqueFisico"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="div-checkbox"
              style={{
                display:
                  indicadorTipoEstoque !==
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
                    ? 'none'
                    : '',
              }}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Movimenta Estoque Fiscal"
                  name="regraEscolhaParametroResultado.movimentarEstoqueFiscal"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="div-checkbox"
              style={{
                display:
                  indicadorTipoEstoque ===
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                    ? 'none'
                    : '',
              }}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Por Conta e Ordem de Terceiro"
                  name="regraEscolhaParametroResultado.porContaOrdemTerceiro"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
