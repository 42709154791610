"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IrRetidoCalculo;
function IrRetidoCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { baseCalculoIrRetido: null, valorIrRetido: null });
    if (dados.aliquotaIrRetido === null) {
        retorno.baseCalculoIrRetido = null;
        retorno.valorIrRetido = null;
    }
    retorno.baseCalculoIrRetido = (Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
        Number(dados.valorFreteItem) +
        Number(dados.valorSeguroItem) +
        Number(dados.valorOutrasDespesasItem) -
        Number(dados.valorDescontoItem)).Arredondar();
    if (dados.aliquotaIrRetido && dados.aliquotaIrRetido > 0) {
        retorno.valorIrRetido = retorno.baseCalculoIrRetido
            .CalcularPercentual(dados.aliquotaIrRetido)
            .Arredondar();
    }
    return retorno;
}
