import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/estoques/extrato-por-produto-local-estoque';

interface IExtratoPorProdutoLocalEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ExtratoPorProdutoLocalEstoqueRelatorioComunicador {
  public async index(
    params: IExtratoPorProdutoLocalEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ExtratoPorProdutoLocalEstoqueRelatorioComunicador();
