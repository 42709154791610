import api from '../../../../../../../Comum/Services/Api';

const rota = 'fiscal/tipo-nota-motivo-auto-complete';

interface ITipoNotaMotivoAutoCompleteComunicadorIndex {
  params: any;
}

class TipoNotaMotivoAutoCompleteComunicador {
  public async index(
    params: ITipoNotaMotivoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TipoNotaMotivoAutoCompleteComunicador();
