"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ObjectIsEmpty;
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
function ObjectIsEmpty(obj) {
    for (const prop in obj)
        if (obj.hasOwnProperty(prop) && obj[prop] !== '')
            return false;
    return true;
}
