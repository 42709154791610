"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoSisdevinEstoqueMovimentoEnum;
(function (SituacaoSisdevinEstoqueMovimentoEnum) {
    SituacaoSisdevinEstoqueMovimentoEnum["vinculado"] = "Vinculado";
    SituacaoSisdevinEstoqueMovimentoEnum["parcialmenteVinculado"] = "Parcialmente Vinculado";
    SituacaoSisdevinEstoqueMovimentoEnum["naoVinculado"] = "N\u00E3o Vinculado";
})(SituacaoSisdevinEstoqueMovimentoEnum || (SituacaoSisdevinEstoqueMovimentoEnum = {}));
exports.default = SituacaoSisdevinEstoqueMovimentoEnum;
