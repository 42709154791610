import {
  IGrupoProdutoValoresAlterar,
  TipoConfiguracaoCREnologicoCultivarEnum,
  TipoRecipienteBebidaSisdevinEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import ConfiguracaCREnologicoCultivarComunicador from '../../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Comunicador/ConfiguracaCREnologicoCultivarComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IGrupoProdutoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await ConfiguracaCREnologicoCultivarComunicador.show({
          id: idEditar,
        });
        await formRef.current?.setDataInicial(response);

        formRef.current?.setFieldDisabled('produto', true);
        formRef.current?.setFieldDisabled('tipo', true);

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          tipo: TipoConfiguracaoCREnologicoCultivarEnum.bebida,
          produtoConfiguracaoBebidaSisdevin: {
            tipoRecipiente: TipoRecipienteBebidaSisdevinEnum.engarrafados,
          },

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idProduto: Yup.string().nullable().required('O produto é obrigatório!'),
        produtoEstoque: Yup.object({
          idUnidadeMedidaEstoque: Yup.string()
            .nullable()
            .required('Selecione um produto que forneça uma unidade!'),
        }).nullable(),
        tipo: Yup.string().nullable().required('O tipo é obrigatório!'),

        produtoConfiguracaoCultivarSisdevin: Yup.object({
          idCultivarSisdevin: Yup.string()
            .nullable()
            .required('O Cultivar é obrigatório!'),
          grau: Yup.number()
            .nullable()
            .min(1, 'O Grau Babo deve ser maior que zero!')
            .required('O Grau Babo é obrigatório!'),
        }).nullable(),

        produtoConfiguracaoProdutoEnologicoSisdevin: Yup.object({
          idProdutoEnologicoSisdevin: Yup.string()
            .nullable()
            .required('O Produto Enológico é obrigatório!'),
          fatorConversao: Yup.number()
            .nullable()
            .min(0.00000001, 'O fator de conversão deve ser maior que zero!'),
          produtoEnologico: Yup.object({
            siglaUnidadeMedida: Yup.string().required(
              'Selecione um produto enológico que forneça uma unidade!'
            ),
          }).nullable(),
        }).nullable(),

        produtoConfiguracaoBebidaSisdevin: Yup.object({
          tipoRecipiente: Yup.string()
            .nullable()
            .required('O tipo de recipiente é obrigatório!'),
          litragem: Yup.number()
            .nullable()
            .min(0.001, 'A litragem deve ser maior que zero!')
            .required('A litragem é obrigatória!'),
        }).nullable(),
      });

      if (data.tipo === TipoConfiguracaoCREnologicoCultivarEnum.enologico) {
        data.produtoConfiguracaoBebidaSisdevin = null;
        data.produtoConfiguracaoCultivarSisdevin = null;
      } else if (
        data.tipo === TipoConfiguracaoCREnologicoCultivarEnum.cultivar
      ) {
        data.produtoConfiguracaoBebidaSisdevin = null;
        data.produtoConfiguracaoProdutoEnologicoSisdevin = null;
      } else {
        data.produtoConfiguracaoCultivarSisdevin = null;
        data.produtoConfiguracaoProdutoEnologicoSisdevin = null;
      }

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        const tipoFormatado = ((tipo) => {
          if (tipo === 'Enológico') return 'enologico';
          return tipo.toLowerCase();
        })(data.tipo);

        if (id) {
          await ConfiguracaCREnologicoCultivarComunicador.update({
            id: data.idProduto,
            tipo: tipoFormatado,
            params: {
              produtoConfiguracaoBebidaSisdevin:
                data?.produtoConfiguracaoBebidaSisdevin,
              produtoConfiguracaoProdutoEnologicoSisdevin:
                data?.produtoConfiguracaoProdutoEnologicoSisdevin,
              produtoConfiguracaoCultivarSisdevin:
                data?.produtoConfiguracaoCultivarSisdevin,
            },
          });
        } else {
          const response =
            await ConfiguracaCREnologicoCultivarComunicador.store({
              tipo: tipoFormatado,
              params: {
                id: data.idProduto,
                produtoConfiguracaoBebidaSisdevin:
                  data?.produtoConfiguracaoBebidaSisdevin,
                produtoConfiguracaoProdutoEnologicoSisdevin:
                  data?.produtoConfiguracaoProdutoEnologicoSisdevin,
                produtoConfiguracaoCultivarSisdevin:
                  data?.produtoConfiguracaoCultivarSisdevin,
              },
            });

          setIdDetalheRegistro(response.id);
        }

        setLoading(false);
        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Produto salvo!');
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
