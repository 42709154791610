import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Rota from './Rota';
import Login from '../Paginas/Login';
import TesteAldoir from '../Comum/Teste/TesteAldoir';
import TesteAndre from '../Comum/Teste/TesteAndre';
import Home from '../Paginas/Home';
import PaginaNaoEcontrada from '../Paginas/PaginaNaoEncontrada';
import OrcamentoLista from '../Paginas/Comercial/Vendas/Orcamento/Lista';
import OrcamentoDetalhe from '../Paginas/Comercial/Vendas/Orcamento/Detalhe';
import PedidoVendaLista from '../Paginas/Comercial/Vendas/PedidoVenda/Lista';
import PedidoVendaDetalhe from '../Paginas/Comercial/Vendas/PedidoVenda/Detalhe';
import NotaFiscalSaidaLista from '../Paginas/Comercial/Vendas/NotaFiscalSaida/Lista';
import NotaFiscalSaidaDetalhe from '../Paginas/Comercial/Vendas/NotaFiscalSaida/Detalhe';
import PessoaLista from '../Paginas/Comercial/Pessoas/Pessoa/Lista';
import PessoaDetalhe from '../Paginas/Comercial/Pessoas/Pessoa/Detalhe';
import PessoaRelatorio from '../Paginas/Comercial/Relatorios/Pessoas';
import VendasOrcamentoRelatorio from '../Paginas/Comercial/Relatorios/Vendas/Orcamento';
import VendasPedidoVendaRelatorio from '../Paginas/Comercial/Relatorios/Vendas/PedidoVenda';
import VendasNotaFiscalSaidaRelatorio from '../Paginas/Comercial/Relatorios/Vendas/NotaFiscalSaida';
import RamoAtividadeProfissaoLista from '../Paginas/Comercial/Pessoas/RamoAtividadeProfissao/Lista';
import RamoAtividadeProfissaoDetalhe from '../Paginas/Comercial/Pessoas/RamoAtividadeProfissao/Detalhe';
import UsuarioLista from '../Paginas/Configuracao/Usuario/Lista';
import UsuarioDetalhe from '../Paginas/Configuracao/Usuario/Detalhe';
import EmpresaDetalhe from '../Paginas/Configuracao/Empresa/Detalhe';
import ParametroDetalhe from '../Paginas/Configuracao/Parametro/Detalhe';
import UnidadeMedidaLista from '../Paginas/Suprimentos/Produtos/UnidadeMedida/Lista';
import UnidadeMedidaDetalhe from '../Paginas/Suprimentos/Produtos/UnidadeMedida/Detalhe';
import MarcaProdutoLista from '../Paginas/Suprimentos/Produtos/MarcaProduto/Lista';
import MarcaProdutoDetalhe from '../Paginas/Suprimentos/Produtos/MarcaProduto/Detalhe';
import LocalEstoqueLista from '../Paginas/Suprimentos/Produtos/Estoque/LocalEstoque/Lista';
import LocalEstoqueDetalhe from '../Paginas/Suprimentos/Produtos/Estoque/LocalEstoque/Detalhe';
import MovimentoManualEstoqueLista from '../Paginas/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Lista';
import MovimentoManualEstoqueDetalhe from '../Paginas/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Detalhe';
import TransferenciaLocalEstoqueLista from '../Paginas/Suprimentos/Produtos/Estoque/TransferenciaLocalEstoque/Lista';
import TransferenciaLocalEstoqueDetalhe from '../Paginas/Suprimentos/Produtos/Estoque/TransferenciaLocalEstoque/Detalhe';
import LoteLista from '../Paginas/Suprimentos/Produtos/Estoque/Lote/Lista';
import LoteDetalhe from '../Paginas/Suprimentos/Produtos/Estoque/Lote/Detalhe';
import GrupoProdutoServicoLista from '../Paginas/Suprimentos/Produtos/GrupoProdutoServico/Lista';
import GrupoProdutoServicoDetalhe from '../Paginas/Suprimentos/Produtos/GrupoProdutoServico/Detalhe';
import PadronizacaoDescricaoLista from '../Paginas/Suprimentos/Produtos/PadronizacaoDescricao/Lista';
import PadronizacaoDescricaoDetalhe from '../Paginas/Suprimentos/Produtos/PadronizacaoDescricao/Detalhe';
import ProdutoLista from '../Paginas/Suprimentos/Produtos/Produto/Lista';
import ProdutoDetalhe from '../Paginas/Suprimentos/Produtos/Produto/Detalhe';
import TipoNotaLista from '../Paginas/Fiscal/Tributacao/TipoNota/Lista';
import TipoNotaDetalhe from '../Paginas/Fiscal/Tributacao/TipoNota/Detalhe';
import RegraEscolhaOperacaoFiscalLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaOperacaoFiscal/Lista';
import RegraEscolhaOperacaoFiscalDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaOperacaoFiscal/Detalhe';
import RegraEscolhaCstLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaCst/Lista';
import RegraEscolhaCstDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaCst/Detalhe';
import RegraEscolhaParametroLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaParametro/Lista';
import RegraEscolhaParametroDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaParametro/Detalhe';
import RegraEscolhaAliquotaLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaAliquota/Lista';
import RegraEscolhaAliquotaDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaAliquota/Detalhe';
import VeiculoLista from '../Paginas/Transporte/Veiculo/Lista';
import VeiculoDetalhe from '../Paginas/Transporte/Veiculo/Detalhe';
import ManifestoTransporteLista from '../Paginas/Transporte/ManifestoTransporte/Lista';
import ManifestoTransporteDetalhe from '../Paginas/Transporte/ManifestoTransporte/Detalhe';
import ContabilistaLista from '../Paginas/Contabil/Geral/Contabilista/Lista';
import ContabilistaDetalhe from '../Paginas/Contabil/Geral/Contabilista/Detalhe';
import SerieLista from '../Paginas/Fiscal/Tributacao/Serie/Lista';
import SerieDetalhe from '../Paginas/Fiscal/Tributacao/Serie/Detalhe';
import SpedConfiguracaoLista from '../Paginas/Fiscal/Sped/Configuracao/Lista';
import SpedConfiguracaoDetalhe from '../Paginas/Fiscal/Sped/Configuracao/Detalhe';
import InutilizacaoNumeracaoLista from '../Paginas/Fiscal/DocumentosEletronicos/InutilizacaoNumeracao/Lista';
import InutilizacaoNumeracaoDetalhe from '../Paginas/Fiscal/DocumentosEletronicos/InutilizacaoNumeracao/Detalhe';
import ConsultaAliquotaIcmsInternaInterestadualLista from '../Paginas/Fiscal/Tributacao/ConsultaAliquotaIcmsInternaInterestadual/Lista';
import CodigoeSequenciaLista from '../Paginas/Configuracao/CodigoeSequencia/Lista';
import CodigoeSequenciaDetalhe from '../Paginas/Configuracao/CodigoeSequencia/Detalhe';
import EtiquetaLista from '../Paginas/Configuracao/Etiqueta/Lista';
import EtiquetaDetalhe from '../Paginas/Configuracao/Etiqueta/Detalhe';
import ImpressaoRecibo from '../Paginas/Financeiro/Geral/ImpressaoRecibo';
import ProdutoRelatorio from '../Paginas/Suprimentos/Relatorios/Produtos';
import EstoqueRelatorio from '../Paginas/Suprimentos/Relatorios/Estoques';
import EsqueceuSenha from '../Paginas/EsqueceuSenha';
import RecuperarSenha from '../Paginas/Login/RecuperarSenha';
import ConsultaAliquotaIpiPorNcmLista from '../Paginas/Fiscal/Tributacao/ConsultaAliquotaIpiPorNcm/Lista';
import FormaPagamentoLista from '../Paginas/Financeiro/Geral/FormaPagamento/Lista';
import FormaPagamentoDetalhe from '../Paginas/Financeiro/Geral/FormaPagamento/Detalhe';
import CodigoRastreabilidadeLista from '../Paginas/Suprimentos/Sisdevin/CodigoRastreabilidade/Lista';
import CodigoRastreabilidadeDetalhe from '../Paginas/Suprimentos/Sisdevin/CodigoRastreabilidade/Detalhe';
import GrupoCodigoRastreabilidadeLista from '../Paginas/Suprimentos/Sisdevin/GrupoCodigoRastreabilidade/Lista';
import GrupoCodigoRastreabilidadeDetalhe from '../Paginas/Suprimentos/Sisdevin/GrupoCodigoRastreabilidade/Detalhe';
import ConfiguracaoCREnologicoCultivarLista from '../Paginas/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Lista';
import ConfiguracaoCREnologicoCultivarDetalhe from '../Paginas/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Detalhe';
import ImportacaoXmlTerceiroLista from '../Paginas/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Lista';
import AtualizacaoEstoqueProdutoListagem from '../Paginas/Suprimentos/Produtos/AtualizacaoEstoqueProduto';
import AtualizacaoPrecoCustoProdutoListagem from '../Paginas/Suprimentos/Produtos/AtualizacaoPrecoCustoProduto';
import CustoConfiguracaoLista from '../Paginas/Suprimentos/Custo/Configuracao/Lista';
import CustoConfiguracaoDetalhe from '../Paginas/Suprimentos/Custo/Configuracao/Detalhe';
import ManutencaoDocumentosFiscaisLista from '../Paginas/Fiscal/DocumentosEletronicos/ManutencaoDocumentosFiscais/Lista';
import MovimentacaoEntreProdutos from '../Paginas/Suprimentos/Produtos/Estoque/MovimentacaoEntreProdutos/Lista';
import MovimentacaoEntreProdutosDetalhe from '../Paginas/Suprimentos/Produtos/Estoque/MovimentacaoEntreProdutos/Detalhe';
import ConhecimentoTransporteLista from '../Paginas/Transporte/ConhecimentoTransporte/Lista';
import ConhecimentoTransporteDetalhe from '../Paginas/Transporte/ConhecimentoTransporte/Detalhe';
import ContaContabil from '../Paginas/Contabil/ContasContabeis/ContaContabil';
import CentroCusto from '../Paginas/Contabil/ContasContabeis/CentroCusto';
import DemonstrativoResultado from '../Paginas/Financeiro/CategoriaDRE/DemonstrativoResultado';
import Categoria from '../Paginas/Financeiro/CategoriaDRE/Categoria';
import VendasCurvaABCPedidoVenda from '../Paginas/Comercial/Relatorios/Vendas/CurvaABCPedidoVenda';
import ConhecimentoTransporteEntradaTerceiroDetalhe from '../Paginas/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteEntradaTerceiro/Detalhe';
import ConhecimentoTransporteEntradaTerceiroLista from '../Paginas/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteEntradaTerceiro/Lista';
import VendasCurvaABCNotaFiscalSaida from '../Paginas/Comercial/Relatorios/Vendas/CurvaABCNotaFiscalSaida';
import TransporteConhecimentoTransporte from '../Paginas/Transporte/Relatorios/ConhecimentoTransporte';
import SuprimentoConhecimentoTransporteTerceiros from '../Paginas/Suprimentos/Relatorios/ConhecimentoTransporteTerceiros';
import CentroTrabalhoLista from '../Paginas/Manufatura/Engenharia/CentroTrabalho/Lista';
import CentroTrabalhoDetalhe from '../Paginas/Manufatura/Engenharia/CentroTrabalho/Detalhe';
import RoteiroFabricacaoPadraoLista from '../Paginas/Manufatura/Engenharia/RoteiroFabricaoPadrao/Lista';
import RoteiroFabricacaoPadraoDetalhe from '../Paginas/Manufatura/Engenharia/RoteiroFabricaoPadrao/Detalhe';
import OperacaoRoteiroFabricacaoDetalhe from '../Paginas/Manufatura/Engenharia/OperacaoRoteiroFabricacao/Detalhe';
import OperacoesRoteiroFabricacaoLista from '../Paginas/Manufatura/Engenharia/OperacaoRoteiroFabricacao/Lista';
import PesagemLista from '../Paginas/Suprimentos/Pesagens/Pesagem/Lista';
import PesagemDetalhe from '../Paginas/Suprimentos/Pesagens/Pesagem/Detalhe';
import ManifestoTransporteRelatorio from '../Paginas/Transporte/Relatorios/ManifestoTransporte';
import PesagemRelatorio from '../Paginas/Suprimentos/Relatorios/Pesagem';
import ItemAvaliacaoPesagensLista from '../Paginas/Suprimentos/Pesagens/ItemAvaliacaoPesagens/Lista';
import ItemAvaliacaoPesagemDetalhe from '../Paginas/Suprimentos/Pesagens/ItemAvaliacaoPesagens/Detalhe';
import CotacaoCompraLista from '../Paginas/Suprimentos/Compras/CotacaoCompra/Lista';
import CotacaoCompraDetalhe from '../Paginas/Suprimentos/Compras/CotacaoCompra/Detalhe';
import PedidoCompraLista from '../Paginas/Suprimentos/Compras/PedidoCompra/Lista';
import PedidoCompraDetalhe from '../Paginas/Suprimentos/Compras/PedidoCompra/Detalhe';
import NotaFiscalEntradaLista from '../Paginas/Suprimentos/Compras/NotaFiscalEntrada/Lista';
import NotaFiscalEntradaDetalhe from '../Paginas/Suprimentos/Compras/NotaFiscalEntrada/Detalhe';
import ComprasCotacaoCompraRelatorio from '../Paginas/Suprimentos/Relatorios/Compras/CotacaoCompra';
import ComprasPedidoCompraRelatorio from '../Paginas/Suprimentos/Relatorios/Compras/PedidoCompra';
import ComprasNotaFiscalEntradaRelatorio from '../Paginas/Suprimentos/Relatorios/Compras/NotaFiscalEntrada';
import EquipamentoLista from '../Paginas/Servicos/OrdensServicos/Equipamento/Lista';
import EquipamentoDetalhe from '../Paginas/Servicos/OrdensServicos/Equipamento/Detalhe';
import AcessorioLista from '../Paginas/Servicos/OrdensServicos/Acessorio/Lista';
import AcessorioDetalhe from '../Paginas/Servicos/OrdensServicos/Acessorio/Detalhe';
import ConjuntoLista from '../Paginas/Manufatura/Configurador/Conjunto/Lista';
import ConjuntoDetalhe from '../Paginas/Manufatura/Configurador/Conjunto/Detalhe';
import CaracteristicaLista from '../Paginas/Manufatura/Configurador/Caracteristica/Lista';
import CaracteristicaDetalhe from '../Paginas/Manufatura/Configurador/Caracteristica/Detalhe';
import CaracteristicaProdutoLista from '../Paginas/Manufatura/Configurador/CaracteristicaProduto/Lista';
import CaracteristicaProdutoDetalhe from '../Paginas/Manufatura/Configurador/CaracteristicaProduto/Detalhe';
import OrdemDesmontagemLista from '../Paginas/Manufatura/Producao/OrdemDesmontagem/Lista';
import OrdemDesmontagemDetalhe from '../Paginas/Manufatura/Producao/OrdemDesmontagem/Detalhe';
import RoteiroFabricacaoLista from '../Paginas/Manufatura/Engenharia/RoteiroFabricacao/Lista';
import RoteiroFabricacaoDetalhe from '../Paginas/Manufatura/Engenharia/RoteiroFabricacao/Detalhe';
import PortadorDetalhe from '../Paginas/Financeiro/Geral/Portador/Detalhe';
import PortadorLista from '../Paginas/Financeiro/Geral/Portador/Lista';
import GeracaoArquivosContabeisDetahe from '../Paginas/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Detalhe';
import GeracaoArquivosContabeisLista from '../Paginas/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Lista';
import EstruturaProdutoLista from '../Paginas/Manufatura/Engenharia/EstruturaProduto/Lista';
import EstruturaProdutoDetalhe from '../Paginas/Manufatura/Engenharia/EstruturaProduto/Detalhe';
import ContaEmailLista from '../Paginas/Configuracao/ContaEmail/Lista';
import ContaEmailDetalhe from '../Paginas/Configuracao/ContaEmail/Detalhe';
import VerificarEmail from '../Paginas/Configuracao/ContaEmail/VerificarEmail';
import EstruturaProdutoRelatorio from '../Paginas/Manufatura/Relatorio/Engenharia/EstruturaProduto';
import OrdemProducaoLista from '../Paginas/Manufatura/Producao/OrdemProducao/Lista';
import OrdemProducaoDetalhe from '../Paginas/Manufatura/Producao/OrdemProducao/Detalhe';
import RegraCalculoComissaoLista from '../Paginas/Comercial/Comissao/RegraCalculoComissoes/Lista';
import RegraCalculoComissaoDetalhe from '../Paginas/Comercial/Comissao/RegraCalculoComissoes/Detalhe';
import RegraEscolhaTabelaPrecoLista from '../Paginas/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Lista';
import RegraEscolhaTabelaPrecoDetalhe from '../Paginas/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Detalhe';
import BandeiraCartaoLista from '../Paginas/Financeiro/Geral/BandeiraCartao/Lista';
import BandeiraCartaoDetalhe from '../Paginas/Financeiro/Geral/BandeiraCartao/Detalhe';
import TransferenciaEntrePortadoresLista from '../Paginas/Financeiro/Movimentacoes/TransferenciaEntrePortadores/Lista';
import TransferenciaEntrePortadoresDetalhe from '../Paginas/Financeiro/Movimentacoes/TransferenciaEntrePortadores/Detalhe';
import RegraEscolhaIntegracaoPagamentoDetalhe from '../Paginas/Financeiro/Geral/RegraEscolhaIntegracaoPagamento/Detalhe';
import RegraEscolhaIntegracaoPagamentoLista from '../Paginas/Financeiro/Geral/RegraEscolhaIntegracaoPagamento/Lista';
import ContaLista from '../Paginas/Financeiro/Movimentacoes/Conta/Lista';
import ContaDetalhe from '../Paginas/Financeiro/Movimentacoes/Conta/Detalhe';
import AdiantamentoLista from '../Paginas/Financeiro/AdiantamentoPrevisao/Lista';
import AdiantamentoDetalhe from '../Paginas/Financeiro/AdiantamentoPrevisao/Detalhe';
import RegraCalculoCartaoDetalhe from '../Paginas/Financeiro/Geral/RegraCalculoCartao/Detalhe';
import RegraCalculoCartaoLista from '../Paginas/Financeiro/Geral/RegraCalculoCartao/Lista';
import MovimentacoesContasReceberPagar from '../Paginas/Financeiro/Relatorios/Movimentacoes/ContasReceberPagar';
import RegraEscolhaCategoriaLista from '../Paginas/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Lista';
import RegraEscolhaCategoriaDetalhe from '../Paginas/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Detalhe';
import MovimentacoesFinanceirasPortadores from '../Paginas/Financeiro/Relatorios/Movimentacoes/MovimentacoesFinanceirasPortadores';
import RegraEscolhaCstCreditoIcmsLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoIcms/Lista';
import RegraEscolhaCstCreditoIcmsDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoIcms/Detalhe';
import RegraEscolhaCstCreditoIpiLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoIpi/Lista';
import RegraEscolhaCstCreditoIpiDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoIpi/Detalhe';
import RegraEscolhaCstCreditoPisCofinsLista from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoPisCofins/Lista';
import RegraEscolhaCstCreditoPisCofinsDetalhe from '../Paginas/Fiscal/Tributacao/RegraEscolhaCstCreditoPisCofins/Detalhe';
import ImportacaoXmlEmitidosLista from '../Paginas/Fiscal/DocumentosEletronicos/ImportacaoXmlEmitidos';
import ColecaoLista from '../Paginas/Suprimentos/Produtos/Colecao/Lista';
import ColecaoDetalhe from '../Paginas/Suprimentos/Produtos/Colecao/Detalhe';
import FamiliaLista from '../Paginas/Suprimentos/Produtos/Familia/Lista';
import FamiliaDetalhe from '../Paginas/Suprimentos/Produtos/Familia/Detalhe';
import ImpressaoEtiquetasProdutosDetalhe from '../Paginas/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Detalhe';
import FrenteCaixaPdvDetalhe from '../Paginas/Comercial/Vendas/FrenteCaixaPdv/Detalhe';
import DevolucaoNotasEntradaRelatorio from '../Paginas/Suprimentos/Relatorios/DevolucaoNotasEntrada';
import GeracaoEnvioBoletosListagem from '../Paginas/Financeiro/Movimentacoes/GeracaoEnvioBoletos';
import ImpressaoEtiquetasNotaFiscalListagem from '../Paginas/Comercial/Vendas/ImpressaoEtiquetasNotaFiscal';
import ImpressaoEtiquetasPedidoVendaListagem from '../Paginas/Comercial/Vendas/ImpressaoEtiquetasPedidoVenda';
import ReprocessamentoProducaoDetalhe from '../Paginas/Manufatura/Producao/ReprocessamentoProducao/Detalhe';
import ReprocessamentoProducaoLista from '../Paginas/Manufatura/Producao/ReprocessamentoProducao/Lista';
import ComissoesNotaFiscal from '../Paginas/Comercial/Relatorios/Comissoes/ComissoesNotaFiscal';
import TabelaPrecoRelatorio from '../Paginas/Comercial/Relatorios/TabelaPreco';
import CurvaABCNFEntrada from '../Paginas/Suprimentos/Relatorios/Compras/CurvaABCNFEntrada';
import MovimentoManualSisdevinLista from '../Paginas/Suprimentos/Sisdevin/MovimentoManualSisdevin/Lista';
import MovimentoManualSisdevinDetalhe from '../Paginas/Suprimentos/Sisdevin/MovimentoManualSisdevin/Detalhe';
import AberturaFechamentoCaixaLista from '../Paginas/Comercial/Vendas/AberturaFechamentoCaixa/Lista';
import AberturaFechamentoCaixaDetalhe from '../Paginas/Comercial/Vendas/AberturaFechamentoCaixa/Detalhe';
import ContaParcelaReversaoListagem from '../Paginas/Financeiro/Movimentacoes/ContasParcelaReversao';
import Boletos from '../Paginas/Financeiro/Relatorios/Boletos';
import LiquidacaoContasEmLoteListagem from '../Paginas/Financeiro/Movimentacoes/LiquidacaoContasEmLote';
import TerminalCaixaLista from '../Paginas/Comercial/Vendas/TerminalCaixa/Lista';
import TerminalCaixaDetalhe from '../Paginas/Comercial/Vendas/TerminalCaixa/Detalhe';
import RegraEscolhaPortadorLista from '../Paginas/Financeiro/Geral/RegraEscolhaPortador/Lista';
import RegraEscolhaPortadorDetalhe from '../Paginas/Financeiro/Geral/RegraEscolhaPortador/Detalhe';
import ImpressaoEtiquetasPedidoVendaItemListagem from '../Paginas/Comercial/Vendas/ImpressaoEtiquetasPedidoVendaItem';
import RegraEscolhaSisdevinLista from '../Paginas/Suprimentos/Sisdevin/RegraEscolhaSisdevin/Lista';
import RegraEscolhaSisdevinDetalhe from '../Paginas/Suprimentos/Sisdevin/RegraEscolhaSisdevin/Detalhe';
import SisdevinEstoqueMovimentoListagem from '../Paginas/Suprimentos/Sisdevin/SisdevinEstoqueMovimento';
import PedidoVendaImportacaoLogLista from '../Paginas/Comercial/Vendas/PedidoVendaImportacaoLog/Lista';
import ComprasDocumentosEntradaRelatorio from '../Paginas/Suprimentos/Relatorios/Compras/DocumentosEntrada';
import MarcaVeiculoLista from '../Paginas/Servicos/OrdensServicos/MarcaVeiculo/Lista';
import MarcaVeiculoDetalhe from '../Paginas/Servicos/OrdensServicos/MarcaVeiculo/Detalhe';
import SituacaoLista from '../Paginas/Servicos/OrdensServicos/Situacao/Lista';
import SituacaoDetalhe from '../Paginas/Servicos/OrdensServicos/Situacao/Detalhe';
import OrdemServicoLista from '../Paginas/Servicos/OrdensServicos/OrdemServico/Lista';
import OrdemServicoDetalhe from '../Paginas/Servicos/OrdensServicos/OrdemServico/Detalhe';
import OrdemProducaoRelatorio from '../Paginas/Manufatura/Relatorio/Producao/OrdemProducao';
import FinalidadeUsoClienteLista from '../Paginas/Comercial/Pessoas/FinalidadeUsoCliente/Lista';
import FinalidadeUsoClienteDetalhe from '../Paginas/Comercial/Pessoas/FinalidadeUsoCliente/Detalhe';
import { SalvarPDFHook } from '../Paginas/DocumentosEletronicos/SalvarPDF/SalvarPDFHook';
import VendasRetiradaConsignadoRelatorio from '../Paginas/Comercial/Relatorios/Vendas/RetiradaConsignado';
import ImportacaoExportacaoCSVProdutos from '../Paginas/Suprimentos/Produtos/ImportacaoExportacaoCSVProdutos/Detalhe';
import ImportacaoExportacaoCSVEstoques from '../Paginas/Suprimentos/Produtos/Estoque/ImportacaoExportacaoCSVEstoques/Detalhe';
import { SiTefHook } from '../Paginas/Comercial/Vendas/FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import EmpresaCrmSimplesDetalhe from '../Paginas/Configuracao/Integracao/EmpresaCrmSimples/Detalhe';
import ImportacaoExportacaoCSVPessoas from '../Paginas/Comercial/Pessoas/ImportacaoExportacaoCSVPessoas/Detalhe';
import EmpresaTrayDetalhe from '../Paginas/Configuracao/Integracao/EmpresaTray';
import JuncaoPessoas from '../Paginas/Comercial/Pessoas/JuncaoPessoas';
import JuncaoProdutos from '../Paginas/Suprimentos/Produtos/JuncaoProdutos';
import DiarioAuxiliarRelatorio from '../Paginas/Fiscal/Relatorios/DiarioAuxiliar';
import MargensVendaRelatorio from '../Paginas/Comercial/Relatorios/Vendas/MargensVenda';
import OrdemServicoVeiculoRelatorio from '../Paginas/Servicos/Relatorios/OrdemServicoVeiculo';
import Adiantamentos from '../Paginas/Financeiro/Relatorios/Movimentacoes/Adiantamentos';

const RoutesElo: React.FC = () => {
  return (
    <Routes>
      {/* ROTAS PUBLICAS SEM LOGIN */}

      <Route element={<Rota isPrivate={false} />}>
        <Route path="/" element={<Login />} />

        <Route path="/esqueceu-senha" element={<EsqueceuSenha />} />

        <Route path="/redefinir-senha/:id" element={<RecuperarSenha />} />

        <Route
          path="/conta-email-verificado/:id"
          element={<VerificarEmail />}
        />

        <Route
          path="/teste_aldoir"
          element={
            <SiTefHook>
              <TesteAldoir />
            </SiTefHook>
          }
        />

        <Route
          path="/teste_andre"
          element={
            <SalvarPDFHook>
              <TesteAndre />
            </SalvarPDFHook>
          }
        />
      </Route>

      {/* GERAL */}

      <Route element={<Rota isPrivate />}>
        <Route path="/home" element={<Home />} />
        {/* COMERCIAL */}
        <Route
          path="/comercial/comissao/regra-calculo-comissao"
          element={<RegraCalculoComissaoLista />}
        />
        <Route
          path="/comercial/comissao/regra-calculo-comissao/detalhe/:id"
          element={<RegraCalculoComissaoDetalhe />}
        />
        <Route
          path="/comercial/comissao/regra-calculo-comissao/detalhe"
          element={<RegraCalculoComissaoDetalhe />}
        />
        <Route path="/comercial/pessoas/pessoa" element={<PessoaLista />} />
        <Route
          path="/comercial/pessoas/pessoa/detalhe/:id"
          element={<PessoaDetalhe />}
        />
        <Route
          path="/comercial/pessoas/pessoa/detalhe"
          element={<PessoaDetalhe />}
        />
        <Route
          path="/comercial/pessoas/ramo-atividade-profissao"
          element={<RamoAtividadeProfissaoLista />}
        />
        <Route
          path="/comercial/pessoas/ramo-atividade-profissao/detalhe/:id"
          element={<RamoAtividadeProfissaoDetalhe />}
        />
        <Route
          path="/comercial/pessoas/ramo-atividade-profissao/detalhe/"
          element={<RamoAtividadeProfissaoDetalhe />}
        />
        <Route
          path="/comercial/pessoas/finalidade-uso-cliente"
          element={<FinalidadeUsoClienteLista />}
        />
        <Route
          path="/comercial/pessoas/finalidade-uso-cliente/detalhe/:id"
          element={<FinalidadeUsoClienteDetalhe />}
        />
        <Route
          path="/comercial/pessoas/finalidade-uso-cliente/detalhe/"
          element={<FinalidadeUsoClienteDetalhe />}
        />
        <Route
          path="/comercial/pessoas/juncao-pessoas"
          element={<JuncaoPessoas />}
        />
        <Route
          path="/comercial/pessoas/importacao-exportacao-csv-pessoas/"
          element={<ImportacaoExportacaoCSVPessoas />}
        />
        <Route
          path="/comercial/vendas/orcamento"
          element={<OrcamentoLista />}
        />
        <Route
          path="/comercial/vendas/orcamento/detalhe/:id"
          element={<OrcamentoDetalhe />}
        />
        <Route
          path="/comercial/vendas/orcamento/detalhe"
          element={<OrcamentoDetalhe />}
        />
        <Route
          path="/comercial/vendas/pedido-venda"
          element={<PedidoVendaLista />}
        />
        <Route
          path="/comercial/vendas/pedido-venda/detalhe/:id"
          element={<PedidoVendaDetalhe />}
        />
        <Route
          path="/comercial/vendas/pedido-venda/detalhe"
          element={<PedidoVendaDetalhe />}
        />
        <Route
          path="/comercial/vendas/nota-fiscal-saida"
          element={<NotaFiscalSaidaLista />}
        />
        <Route
          path="/comercial/vendas/nota-fiscal-saida/detalhe/:id"
          element={<NotaFiscalSaidaDetalhe />}
        />
        <Route
          path="/comercial/vendas/nota-fiscal-saida/detalhe"
          element={<NotaFiscalSaidaDetalhe />}
        />
        <Route
          path="/comercial/vendas/terminal-caixa"
          element={<TerminalCaixaLista />}
        />
        <Route
          path="/comercial/vendas/terminal-caixa/detalhe/:id"
          element={<TerminalCaixaDetalhe />}
        />
        <Route
          path="/comercial/vendas/terminal-caixa/detalhe"
          element={<TerminalCaixaDetalhe />}
        />
        <Route
          path="/comercial/tabela-preco/regra-escolha-tabela-preco"
          element={<RegraEscolhaTabelaPrecoLista />}
        />
        <Route
          path="/comercial/tabela-preco/regra-escolha-tabela-preco/detalhe/:id"
          element={<RegraEscolhaTabelaPrecoDetalhe />}
        />
        <Route
          path="/comercial/tabela-preco/regra-escolha-tabela-preco/detalhe"
          element={<RegraEscolhaTabelaPrecoDetalhe />}
        />
        <Route
          path="/comercial/relatorios/pessoa"
          element={<PessoaRelatorio />}
        />
        <Route
          path="/comercial/relatorios/vendas/orcamento"
          element={<VendasOrcamentoRelatorio />}
        />
        <Route
          path="/comercial/relatorios/vendas/pedido-venda"
          element={<VendasPedidoVendaRelatorio />}
        />
        <Route
          path="/comercial/relatorios/vendas/nota-fiscal-saida"
          element={<VendasNotaFiscalSaidaRelatorio />}
        />
        <Route
          path="/comercial/relatorios/vendas/curva-abc-pedido-venda"
          element={<VendasCurvaABCPedidoVenda />}
        />
        <Route
          path="/comercial/relatorios/vendas/retirada-consignado"
          element={<VendasRetiradaConsignadoRelatorio />}
        />
        <Route
          path="/comercial/relatorios/vendas/curva-abc-nota-fiscal-saida"
          element={<VendasCurvaABCNotaFiscalSaida />}
        />
        <Route
          path="/comercial/relatorios/vendas/margens-venda"
          element={<MargensVendaRelatorio />}
        />
        <Route
          path="/comercial/relatorios/comissoes/comissoes-nota-fiscal"
          element={<ComissoesNotaFiscal />}
        />
        <Route
          path="/comercial/relatorios/tabela-preco"
          element={<TabelaPrecoRelatorio />}
        />
        <Route
          path="/comercial/vendas/frente-caixa-pdv"
          element={<FrenteCaixaPdvDetalhe />}
        />
        <Route
          path="/comercial/vendas/impressao-etiquetas-nota-fiscal"
          element={<ImpressaoEtiquetasNotaFiscalListagem />}
        />
        <Route
          path="/comercial/vendas/impressao-etiquetas-pedido-venda"
          element={<ImpressaoEtiquetasPedidoVendaListagem />}
        />
        <Route
          path="/comercial/vendas/impressao-etiquetas-pedido-venda-por-item"
          element={<ImpressaoEtiquetasPedidoVendaItemListagem />}
        />
        ,
        <Route
          path="/comercial/vendas/abertura-fechamento-caixa"
          element={<AberturaFechamentoCaixaLista />}
        />
        <Route
          path="/comercial/vendas/abertura-fechamento-caixa/detalhe/:id"
          element={<AberturaFechamentoCaixaDetalhe />}
        />
        <Route
          path="/comercial/vendas/abertura-fechamento-caixa/detalhe"
          element={<AberturaFechamentoCaixaDetalhe />}
        />
        <Route
          path="/comercial/vendas/logs-integracao-pedidos"
          element={<PedidoVendaImportacaoLogLista />}
        />
        {/* SUPRIMENTOS */}
        <Route
          path="/suprimentos/relatorios/produtos"
          element={<ProdutoRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/estoques"
          element={<EstoqueRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/conhecimento-transporte-terceiro"
          element={<SuprimentoConhecimentoTransporteTerceiros />}
        />
        <Route
          path="/suprimentos/relatorios/devolucao-notas-entrada"
          element={<DevolucaoNotasEntradaRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/pesagem"
          element={<PesagemRelatorio />}
        />
        {/* CONFIGURACAO */}
        <Route path="/configuracao/usuario" element={<UsuarioLista />} />
        <Route
          path="/configuracao/usuario/detalhe/:id"
          element={<UsuarioDetalhe />}
        />
        <Route
          path="/configuracao/usuario/detalhe"
          element={<UsuarioDetalhe />}
        />
        <Route
          path="/configuracao/conta-de-email"
          element={<ContaEmailLista />}
        />
        <Route
          path="/configuracao/conta-de-email/detalhe/:id"
          element={<ContaEmailDetalhe />}
        />
        <Route
          path="/configuracao/conta-de-email/detalhe"
          element={<ContaEmailDetalhe />}
        />
        <Route
          path="/configuracao/integracao/empresa-crm-simples"
          element={<EmpresaCrmSimplesDetalhe />}
        />
        <Route
          path="/configuracao/integracao/empresa-tray"
          element={<EmpresaTrayDetalhe />}
        />
        {/* SUPRIMENTOS */}
        <Route
          path="/suprimentos/produtos/unidade-medida"
          element={<UnidadeMedidaLista />}
        />
        <Route
          path="/suprimentos/produtos/unidade-medida/detalhe/:id"
          element={<UnidadeMedidaDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/unidade-medida/detalhe"
          element={<UnidadeMedidaDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/marca-produto"
          element={<MarcaProdutoLista />}
        />
        <Route
          path="/suprimentos/produtos/marca-produto/detalhe/:id"
          element={<MarcaProdutoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/marca-produto/detalhe"
          element={<MarcaProdutoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/local-estoque"
          element={<LocalEstoqueLista />}
        />
        <Route
          path="/suprimentos/produtos/estoque/local-estoque/detalhe/:id"
          element={<LocalEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/local-estoque/detalhe"
          element={<LocalEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimento-manual-estoque"
          element={<MovimentoManualEstoqueLista />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimento-manual-estoque/detalhe/:id"
          element={<MovimentoManualEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimento-manual-estoque/detalhe"
          element={<MovimentoManualEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/lote"
          element={<LoteLista />}
        />
        <Route
          path="/suprimentos/produtos/estoque/lote/detalhe/:id"
          element={<LoteDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/lote/detalhe"
          element={<LoteDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/transferencia-local-estoque"
          element={<TransferenciaLocalEstoqueLista />}
        />
        <Route
          path="/suprimentos/produtos/estoque/transferencia-local-estoque/detalhe/:id"
          element={<TransferenciaLocalEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/transferencia-local-estoque/detalhe"
          element={<TransferenciaLocalEstoqueDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimentacao-entre-produtos"
          element={<MovimentacaoEntreProdutos />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimentacao-entre-produtos/detalhe/:id"
          element={<MovimentacaoEntreProdutosDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/movimentacao-entre-produtos/detalhe"
          element={<MovimentacaoEntreProdutosDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/estoque/importacao-exportacao-csv-estoques"
          element={<ImportacaoExportacaoCSVEstoques />}
        />
        <Route
          path="/suprimentos/produtos/grupo-produto-servico"
          element={<GrupoProdutoServicoLista />}
        />
        <Route
          path="/suprimentos/produtos/grupo-produto-servico/detalhe/:id"
          element={<GrupoProdutoServicoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/grupo-produto-servico/detalhe"
          element={<GrupoProdutoServicoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/importacao-exportacao-csv-produtos"
          element={<ImportacaoExportacaoCSVProdutos />}
        />
        <Route
          path="/suprimentos/produtos/juncao-produtos"
          element={<JuncaoProdutos />}
        />
        <Route
          path="/suprimentos/produtos/familia"
          element={<FamiliaLista />}
        />
        <Route
          path="/suprimentos/produtos/familia/detalhe/:id"
          element={<FamiliaDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/familia/detalhe"
          element={<FamiliaDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/colecao"
          element={<ColecaoLista />}
        />
        <Route
          path="/suprimentos/produtos/colecao/detalhe/:id"
          element={<ColecaoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/colecao/detalhe"
          element={<ColecaoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/padronizacao-descricao"
          element={<PadronizacaoDescricaoLista />}
        />
        <Route
          path="/suprimentos/produtos/padronizacao-descricao/detalhe/:id"
          element={<PadronizacaoDescricaoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/padronizacao-descricao/detalhe"
          element={<PadronizacaoDescricaoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/produto"
          element={<ProdutoLista />}
        />
        <Route
          path="/suprimentos/produtos/produto/detalhe/:id"
          element={<ProdutoDetalhe />}
        />
        <Route
          path="/suprimentos/produtos/produto/detalhe"
          element={<ProdutoDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/codigo-rastreabilidade"
          element={<CodigoRastreabilidadeLista />}
        />
        <Route
          path="/suprimentos/sisdevin/codigo-rastreabilidade/detalhe/:id"
          element={<CodigoRastreabilidadeDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/codigo-rastreabilidade/detalhe"
          element={<CodigoRastreabilidadeDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/grupo-codigo-rastreabilidade"
          element={<GrupoCodigoRastreabilidadeLista />}
        />
        <Route
          path="/suprimentos/sisdevin/grupo-codigo-rastreabilidade/detalhe/:id"
          element={<GrupoCodigoRastreabilidadeDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/grupo-codigo-rastreabilidade/detalhe"
          element={<GrupoCodigoRastreabilidadeDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/configuracao-cr-enologico-cultivar"
          element={<ConfiguracaoCREnologicoCultivarLista />}
        />
        <Route
          path="/suprimentos/sisdevin/configuracao-cr-enologico-cultivar/detalhe/:id"
          element={<ConfiguracaoCREnologicoCultivarDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/configuracao-cr-enologico-cultivar/detalhe"
          element={<ConfiguracaoCREnologicoCultivarDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/movimento-manual-perdas-sisdevin"
          element={<MovimentoManualSisdevinLista />}
        />
        <Route
          path="/suprimentos/sisdevin/movimento-manual-perdas-sisdevin/detalhe/:id"
          element={<MovimentoManualSisdevinDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/movimento-manual-perdas-sisdevin/detalhe"
          element={<MovimentoManualSisdevinDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/regra-escolha-parametros-sisdevin"
          element={<RegraEscolhaSisdevinLista />}
        />
        <Route
          path="/suprimentos/sisdevin/regra-escolha-parametros-sisdevin/detalhe/:id"
          element={<RegraEscolhaSisdevinDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/regra-escolha-parametros-sisdevin/detalhe"
          element={<RegraEscolhaSisdevinDetalhe />}
        />
        <Route
          path="/suprimentos/sisdevin/manutencao-movimentos-sisdevin"
          element={<SisdevinEstoqueMovimentoListagem />}
        />
        <Route
          path="/suprimentos/pesagens/pesagem"
          element={<PesagemLista />}
        />
        <Route
          path="/suprimentos/pesagens/pesagem/detalhe/:id"
          element={<PesagemDetalhe />}
        />
        <Route
          path="/suprimentos/pesagens/pesagem/detalhe"
          element={<PesagemDetalhe />}
        />
        <Route
          path="/suprimentos/pesagens/itens-avaliacao-pesagem"
          element={<ItemAvaliacaoPesagensLista />}
        />
        <Route
          path="/suprimentos/pesagens/itens-avaliacao-pesagem/detalhe/:id"
          element={<ItemAvaliacaoPesagemDetalhe />}
        />
        <Route
          path="/suprimentos/pesagens/itens-avaliacao-pesagem/detalhe"
          element={<ItemAvaliacaoPesagemDetalhe />}
        />
        <Route
          path="/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro"
          element={<ConhecimentoTransporteEntradaTerceiroLista />}
        />
        <Route
          path="/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro/detalhe/:id"
          element={<ConhecimentoTransporteEntradaTerceiroDetalhe />}
        />
        <Route
          path="/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro/detalhe"
          element={<ConhecimentoTransporteEntradaTerceiroDetalhe />}
        />
        <Route
          path="/suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro"
          element={<ImportacaoXmlTerceiroLista />}
        />
        <Route
          path="/suprimentos/produtos/atualizacao-estoque-produto"
          element={<AtualizacaoEstoqueProdutoListagem />}
        />
        <Route
          path="/suprimentos/produtos/atualizacao-preco-custo-produto"
          element={<AtualizacaoPrecoCustoProdutoListagem />}
        />
        <Route
          path="/suprimentos/produtos/impressao-etiquetas-produtos"
          element={<ImpressaoEtiquetasProdutosDetalhe />}
        />
        <Route
          path="/suprimentos/custo/configuracao"
          element={<CustoConfiguracaoLista />}
        />
        <Route
          path="/suprimentos/custo/configuracao/detalhe/:id"
          element={<CustoConfiguracaoDetalhe />}
        />
        <Route
          path="/suprimentos/custo/configuracao/detalhe"
          element={<CustoConfiguracaoDetalhe />}
        />
        <Route
          path="/suprimentos/compras/cotacao-compra"
          element={<CotacaoCompraLista />}
        />
        <Route
          path="/suprimentos/compras/cotacao-compra/detalhe/:id"
          element={<CotacaoCompraDetalhe />}
        />
        <Route
          path="/suprimentos/compras/cotacao-compra/detalhe"
          element={<CotacaoCompraDetalhe />}
        />
        <Route
          path="/suprimentos/compras/pedido-compra"
          element={<PedidoCompraLista />}
        />
        <Route
          path="/suprimentos/compras/pedido-compra/detalhe/:id"
          element={<PedidoCompraDetalhe />}
        />
        <Route
          path="/suprimentos/compras/pedido-compra/detalhe"
          element={<PedidoCompraDetalhe />}
        />
        <Route
          path="/suprimentos/compras/nota-fiscal-entrada"
          element={<NotaFiscalEntradaLista />}
        />
        <Route
          path="/suprimentos/compras/nota-fiscal-entrada/detalhe/:id"
          element={<NotaFiscalEntradaDetalhe />}
        />
        <Route
          path="/suprimentos/compras/nota-fiscal-entrada/detalhe"
          element={<NotaFiscalEntradaDetalhe />}
        />
        <Route
          path="/suprimentos/relatorios/produtos"
          element={<ProdutoRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/estoques"
          element={<EstoqueRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/conhecimento-transporte-terceiro"
          element={<SuprimentoConhecimentoTransporteTerceiros />}
        />
        <Route
          path="/suprimentos/relatorios/pesagem"
          element={<PesagemRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/compras/cotacao-compra"
          element={<ComprasCotacaoCompraRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/compras/pedido-compra"
          element={<ComprasPedidoCompraRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/compras/nota-fiscal-entrada"
          element={<ComprasNotaFiscalEntradaRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/compras/documentos-entrada"
          element={<ComprasDocumentosEntradaRelatorio />}
        />
        <Route
          path="/suprimentos/relatorios/compras/curva-abc-nota-fiscal-entrada"
          element={<CurvaABCNFEntrada />}
        />
        {/* END SUPRIMENTOS */}
        {/* FINANCEIRO */}
        <Route
          path="/financeiro/geral/forma-pagamento"
          element={<FormaPagamentoLista />}
        />
        <Route
          path="/financeiro/geral/forma-pagamento/detalhe/:id"
          element={<FormaPagamentoDetalhe />}
        />
        <Route
          path="/financeiro/geral/forma-pagamento/detalhe"
          element={<FormaPagamentoDetalhe />}
        />
        <Route path="/financeiro/geral/portador" element={<PortadorLista />} />
        <Route
          path="/financeiro/geral/portador/detalhe/:id"
          element={<PortadorDetalhe />}
        />
        <Route
          path="/financeiro/geral/portador/detalhe"
          element={<PortadorDetalhe />}
        />
        <Route
          path="/financeiro/geral/impressao-de-recibo"
          element={<ImpressaoRecibo />}
        />
        <Route
          path="/financeiro/geral/bandeira-cartao"
          element={<BandeiraCartaoLista />}
        />
        <Route
          path="/financeiro/geral/bandeira-cartao/detalhe/:id"
          element={<BandeiraCartaoDetalhe />}
        />
        <Route
          path="/financeiro/geral/bandeira-cartao/detalhe"
          element={<BandeiraCartaoDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-integracao-pagamento"
          element={<RegraEscolhaIntegracaoPagamentoLista />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-integracao-pagamento/detalhe/:id"
          element={<RegraEscolhaIntegracaoPagamentoDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-integracao-pagamento/detalhe"
          element={<RegraEscolhaIntegracaoPagamentoDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-portador"
          element={<RegraEscolhaPortadorLista />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-portador/detalhe/:id"
          element={<RegraEscolhaPortadorDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-escolha-portador/detalhe"
          element={<RegraEscolhaPortadorDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-calculo-cartao"
          element={<RegraCalculoCartaoLista />}
        />
        <Route
          path="/financeiro/geral/regra-calculo-cartao/detalhe/:id"
          element={<RegraCalculoCartaoDetalhe />}
        />
        <Route
          path="/financeiro/geral/regra-calculo-cartao/detalhe"
          element={<RegraCalculoCartaoDetalhe />}
        />
        <Route
          path="/financeiro/movimentacoes/transferencia-entre-portadores"
          element={<TransferenciaEntrePortadoresLista />}
        />
        <Route
          path="/financeiro/movimentacoes/transferencia-entre-portadores/detalhe/:id"
          element={<TransferenciaEntrePortadoresDetalhe />}
        />
        <Route
          path="/financeiro/movimentacoes/transferencia-entre-portadores/detalhe"
          element={<TransferenciaEntrePortadoresDetalhe />}
        />
        <Route
          path="/financeiro/movimentacoes/contas-receber-pagar"
          element={<ContaLista />}
        />
        <Route
          path="/financeiro/movimentacoes/contas-receber-pagar/detalhe/:id"
          element={<ContaDetalhe />}
        />
        <Route
          path="/financeiro/movimentacoes/contas-receber-pagar/detalhe"
          element={<ContaDetalhe />}
        />
        <Route
          path="/financeiro/adiantamento-previsao"
          element={<AdiantamentoLista />}
        />
        <Route
          path="/financeiro/adiantamento-previsao/detalhe/:id"
          element={<AdiantamentoDetalhe />}
        />
        <Route
          path="/financeiro/adiantamento-previsao/detalhe"
          element={<AdiantamentoDetalhe />}
        />
        <Route
          path="/financeiro/categoria-dre/categoria"
          element={<Categoria />}
        />
        <Route
          path="/financeiro/categoria-dre/regra-escolha-categoria"
          element={<RegraEscolhaCategoriaLista />}
        />
        <Route
          path="/financeiro/categoria-dre/regra-escolha-categoria/detalhe/:id"
          element={<RegraEscolhaCategoriaDetalhe />}
        />
        <Route
          path="/financeiro/categoria-dre/regra-escolha-categoria/detalhe"
          element={<RegraEscolhaCategoriaDetalhe />}
        />
        <Route
          path="/financeiro/categoria-dre/demonstrativo-resultado"
          element={<DemonstrativoResultado />}
        />
        <Route
          path="/financeiro/relatorios/movimentacoes/contas-receber-pagar"
          element={<MovimentacoesContasReceberPagar />}
        />
        <Route
          path="/financeiro/movimentacoes/geracao-envio-boletos"
          element={<GeracaoEnvioBoletosListagem />}
        />
        <Route
          path="/financeiro/movimentacoes/reversao-contas"
          element={<ContaParcelaReversaoListagem />}
        />
        <Route
          path="/financeiro/relatorios/movimentacoes/movimentacoes-financeiras-portadores"
          element={<MovimentacoesFinanceirasPortadores />}
        />
        <Route path="/financeiro/relatorios/boletos" element={<Boletos />} />
        <Route
          path="/financeiro/relatorios/movimentacoes/adiantamentos"
          element={<Adiantamentos />}
        />
        <Route
          path="/financeiro/movimentacoes/liquidacao-contas-em-lote"
          element={<LiquidacaoContasEmLoteListagem />}
        />
        {/* END FINANCEIRO */}
        {/* CONFIGURACAO */}
        <Route path="/configuracao/usuario" element={<UsuarioLista />} />
        <Route
          path="/configuracao/usuario/detalhe/:id"
          element={<UsuarioDetalhe />}
        />
        <Route
          path="/configuracao/usuario/detalhe"
          element={<UsuarioDetalhe />}
        />
        <Route
          path="/configuracao/codigo-e-sequencia"
          element={<CodigoeSequenciaLista />}
        />
        <Route
          path="/configuracao/codigo-e-sequencia/detalhe/:id"
          element={<CodigoeSequenciaDetalhe />}
        />
        <Route
          path="/configuracao/codigo-e-sequencia/detalhe"
          element={<CodigoeSequenciaDetalhe />}
        />
        <Route path="/configuracao/etiqueta" element={<EtiquetaLista />} />
        <Route
          path="/configuracao/etiqueta/detalhe/:id"
          element={<EtiquetaDetalhe />}
        />
        <Route
          path="/configuracao/etiqueta/detalhe"
          element={<EtiquetaDetalhe />}
        />
        <Route
          path="/configuracao/empresa/detalhe"
          element={<EmpresaDetalhe />}
        />
        <Route
          path="/configuracao/parametro/detalhe"
          element={<ParametroDetalhe />}
        />
        {/* END CONFIGURACAO */}
        {/* SERVICOS */}
        <Route
          path="/servico/ordens-servicos/marca-veiculo"
          element={<MarcaVeiculoLista />}
        />
        <Route
          path="/servico/ordens-servicos/marca-veiculo/detalhe/:id"
          element={<MarcaVeiculoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/marca-veiculo/detalhe"
          element={<MarcaVeiculoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/equipamento"
          element={<EquipamentoLista />}
        />
        <Route
          path="/servico/ordens-servicos/equipamento/detalhe/:id"
          element={<EquipamentoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/equipamento/detalhe"
          element={<EquipamentoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/acessorio"
          element={<AcessorioLista />}
        />
        <Route
          path="/servico/ordens-servicos/acessorio/detalhe/:id"
          element={<AcessorioDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/acessorio/detalhe"
          element={<AcessorioDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/situacao"
          element={<SituacaoLista />}
        />
        <Route
          path="/servico/ordens-servicos/situacao/detalhe/:id"
          element={<SituacaoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/situacao/detalhe"
          element={<SituacaoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/ordem-servico"
          element={<OrdemServicoLista />}
        />
        <Route
          path="/servico/ordens-servicos/ordem-servico/detalhe/:id"
          element={<OrdemServicoDetalhe />}
        />
        <Route
          path="/servico/ordens-servicos/ordem-servico/detalhe"
          element={<OrdemServicoDetalhe />}
        />
        <Route
          path="/servico/relatorios/ordem-servico-veiculo"
          element={<OrdemServicoVeiculoRelatorio />}
        />
        {/* END SERVICOS */}
        {/* FISCAL */}
        <Route
          path="/fiscal/documentos-eletronicos/importacao-xml-emitidos"
          element={<ImportacaoXmlEmitidosLista />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/inutilizacao-numeracao"
          element={<InutilizacaoNumeracaoLista />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/inutilizacao-numeracao/detalhe/:id"
          element={<InutilizacaoNumeracaoDetalhe />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/inutilizacao-numeracao/detalhe"
          element={<InutilizacaoNumeracaoDetalhe />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/geracao-de-arquivos-contabeis"
          element={<GeracaoArquivosContabeisLista />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/geracao-de-arquivos-contabeis/detalhe/:id"
          element={<GeracaoArquivosContabeisDetahe />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/geracao-de-arquivos-contabeis/detalhe"
          element={<GeracaoArquivosContabeisDetahe />}
        />
        <Route
          path="/fiscal/documentos-eletronicos/manutencao-documentos-fiscais"
          element={<ManutencaoDocumentosFiscaisLista />}
        />
        <Route path="/fiscal/tributacao/serie" element={<SerieLista />} />
        <Route
          path="/fiscal/tributacao/serie/detalhe/:id"
          element={<SerieDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/serie/detalhe"
          element={<SerieDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/tipo-nota"
          element={<TipoNotaLista />}
        />
        <Route
          path="/fiscal/tributacao/tipo-nota/detalhe/:id"
          element={<TipoNotaDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/tipo-nota/detalhe"
          element={<TipoNotaDetalhe />}
        />
        <Route
          path="/fiscal/relatorios/diario-auxiliar"
          element={<DiarioAuxiliarRelatorio />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-operacao-fiscal"
          element={<RegraEscolhaOperacaoFiscalLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-operacao-fiscal/detalhe/:id"
          element={<RegraEscolhaOperacaoFiscalDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-operacao-fiscal/detalhe"
          element={<RegraEscolhaOperacaoFiscalDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-cst"
          element={<RegraEscolhaCstLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-cst/detalhe/:id"
          element={<RegraEscolhaCstDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-cst/detalhe"
          element={<RegraEscolhaCstDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-parametro"
          element={<RegraEscolhaParametroLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-parametro/detalhe/:id"
          element={<RegraEscolhaParametroDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-parametro/detalhe"
          element={<RegraEscolhaParametroDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-aliquota"
          element={<RegraEscolhaAliquotaLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-aliquota/detalhe/:id"
          element={<RegraEscolhaAliquotaDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-aliquota/detalhe"
          element={<RegraEscolhaAliquotaDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-icms"
          element={<RegraEscolhaCstCreditoIcmsLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-icms/detalhe/:id"
          element={<RegraEscolhaCstCreditoIcmsDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-icms/detalhe"
          element={<RegraEscolhaCstCreditoIcmsDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-ipi"
          element={<RegraEscolhaCstCreditoIpiLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-ipi/detalhe/:id"
          element={<RegraEscolhaCstCreditoIpiDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-ipi/detalhe"
          element={<RegraEscolhaCstCreditoIpiDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/consulta-aliquota-icms-interna-interestadual"
          element={<ConsultaAliquotaIcmsInternaInterestadualLista />}
        />
        <Route
          path="/fiscal/tributacao/consulta-aliquota-ipi-por-ncm"
          element={<ConsultaAliquotaIpiPorNcmLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-pis-cofins"
          element={<RegraEscolhaCstCreditoPisCofinsLista />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-pis-cofins/detalhe/:id"
          element={<RegraEscolhaCstCreditoPisCofinsDetalhe />}
        />
        <Route
          path="/fiscal/tributacao/regra-escolha-csts-creditos-pis-cofins/detalhe"
          element={<RegraEscolhaCstCreditoPisCofinsDetalhe />}
        />
        <Route
          path="/fiscal/sped/configuracao"
          element={<SpedConfiguracaoLista />}
        />
        <Route
          path="/fiscal/sped/configuracao/detalhe/:id"
          element={<SpedConfiguracaoDetalhe />}
        />
        <Route
          path="/fiscal/sped/configuracao/detalhe"
          element={<SpedConfiguracaoDetalhe />}
        />
        {/* END FISCAL */}
        {/* CONTABIL */}
        <Route
          path="/contabil/geral/contabilista"
          element={<ContabilistaLista />}
        />
        <Route
          path="/contabil/geral/contabilista/detalhe/:id"
          element={<ContabilistaDetalhe />}
        />
        <Route
          path="/contabil/geral/contabilista/detalhe"
          element={<ContabilistaDetalhe />}
        />
        <Route
          path="/contabil/contas-contabeis/conta-contabil"
          element={<ContaContabil />}
        />
        <Route
          path="/contabil/contas-contabeis/centro-custo"
          element={<CentroCusto />}
        />
        {/* END CONTABIL */}
        {/* TRANSPORTE */}
        <Route path="/transporte/veiculo" element={<VeiculoLista />} />
        <Route
          path="/transporte/veiculo/detalhe/:id"
          element={<VeiculoDetalhe />}
        />
        <Route
          path="/transporte/veiculo/detalhe"
          element={<VeiculoDetalhe />}
        />
        <Route
          path="/transporte/manifesto-transporte"
          element={<ManifestoTransporteLista />}
        />
        <Route
          path="/transporte/manifesto-transporte/detalhe/:id"
          element={<ManifestoTransporteDetalhe />}
        />
        <Route
          path="/transporte/manifesto-transporte/detalhe"
          element={<ManifestoTransporteDetalhe />}
        />
        <Route
          path="/transporte/conhecimento-transporte"
          element={<ConhecimentoTransporteLista />}
        />
        <Route
          path="/transporte/conhecimento-transporte/detalhe/:id"
          element={<ConhecimentoTransporteDetalhe />}
        />
        <Route
          path="/transporte/conhecimento-transporte/detalhe/"
          element={<ConhecimentoTransporteDetalhe />}
        />
        <Route
          path="/transporte/relatorios/conhecimento-transporte"
          element={<TransporteConhecimentoTransporte />}
        />
        <Route
          path="/transporte/relatorios/manifesto-transporte"
          element={<ManifestoTransporteRelatorio />}
        />
        {/* END TRANSPORTE */}
        {/* MANUFATURA */}
        <Route
          path="/manufatura/engenharia/centro-trabalho"
          element={<CentroTrabalhoLista />}
        />
        <Route
          path="/manufatura/engenharia/centro-trabalho/detalhe/:id"
          element={<CentroTrabalhoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/centro-trabalho/detalhe"
          element={<CentroTrabalhoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao-padrao"
          element={<RoteiroFabricacaoPadraoLista />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao-padrao/detalhe/:id"
          element={<RoteiroFabricacaoPadraoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao-padrao/detalhe"
          element={<RoteiroFabricacaoPadraoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao"
          element={<RoteiroFabricacaoLista />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao/detalhe/:id"
          element={<RoteiroFabricacaoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/roteiro-de-fabricacao/detalhe"
          element={<RoteiroFabricacaoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/operacoes-roteiros-fabricacao"
          element={<OperacoesRoteiroFabricacaoLista />}
        />
        <Route
          path="/manufatura/engenharia/operacoes-roteiros-fabricacao/detalhe/:id"
          element={<OperacaoRoteiroFabricacaoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/operacoes-roteiros-fabricacao/detalhe"
          element={<OperacaoRoteiroFabricacaoDetalhe />}
        />
        <Route
          path="/manufatura/configurador/caracteristica"
          element={<CaracteristicaLista />}
        />
        <Route
          path="/manufatura/configurador/caracteristica/detalhe/:id"
          element={<CaracteristicaDetalhe />}
        />
        <Route
          path="/manufatura/configurador/caracteristica/detalhe"
          element={<CaracteristicaDetalhe />}
        />
        <Route
          path="/manufatura/configurador/conjunto-variaveis"
          element={<ConjuntoLista />}
        />
        <Route
          path="/manufatura/configurador/conjunto-variaveis/detalhe/:id"
          element={<ConjuntoDetalhe />}
        />
        <Route
          path="/manufatura/configurador/conjunto-variaveis/detalhe"
          element={<ConjuntoDetalhe />}
        />
        <Route
          path="/manufatura/configurador/config-caracteristicas-produto"
          element={<CaracteristicaProdutoLista />}
        />
        <Route
          path="/manufatura/configurador/config-caracteristicas-produto/detalhe/:id"
          element={<CaracteristicaProdutoDetalhe />}
        />
        <Route
          path="/manufatura/configurador/config-caracteristicas-produto/detalhe"
          element={<CaracteristicaProdutoDetalhe />}
        />
        <Route
          path="/manufatura/producao/ordem-producao"
          element={<OrdemProducaoLista />}
        />
        <Route
          path="/manufatura/producao/ordem-producao/detalhe/:id"
          element={<OrdemProducaoDetalhe />}
        />
        <Route
          path="/manufatura/producao/ordem-producao/detalhe"
          element={<OrdemProducaoDetalhe />}
        />
        <Route
          path="/manufatura/producao/reprocessamento-producao"
          element={<ReprocessamentoProducaoLista />}
        />
        <Route
          path="/manufatura/producao/reprocessamento-producao/detalhe/:id"
          element={<ReprocessamentoProducaoDetalhe />}
        />
        <Route
          path="/manufatura/producao/reprocessamento-producao/detalhe"
          element={<ReprocessamentoProducaoDetalhe />}
        />
        <Route
          path="/manufatura/producao/ordem-desmontagem"
          element={<OrdemDesmontagemLista />}
        />
        <Route
          path="/manufatura/producao/ordem-desmontagem/detalhe/:id"
          element={<OrdemDesmontagemDetalhe />}
        />
        <Route
          path="/manufatura/producao/ordem-desmontagem/detalhe"
          element={<OrdemDesmontagemDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/estrutura-produto"
          element={<EstruturaProdutoLista />}
        />
        <Route
          path="/manufatura/engenharia/estrutura-produto/detalhe/:id"
          element={<EstruturaProdutoDetalhe />}
        />
        <Route
          path="/manufatura/engenharia/estrutura-produto/detalhe"
          element={<EstruturaProdutoDetalhe />}
        />
        <Route
          path="/manufatura/relatorios/engenharia/estrutura-produto"
          element={<EstruturaProdutoRelatorio />}
        />
        <Route
          path="/manufatura/relatorios/producao/ordem-producao"
          element={<OrdemProducaoRelatorio />}
        />
        {/* END MANUFATURA */}
        <Route path="*" element={<PaginaNaoEcontrada />} />
      </Route>
    </Routes>
  );
};

export default RoutesElo;
