/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import {
  Modal,
  Button,
  Container,
  Col,
  Row,
  Table,
  Dropdown,
} from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import {
  IFrenteCaixaPdvFinalizarVendaPagamentoLista,
  SituacaoNotaFiscalSaidaEnum,
  StatusTefEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseSiTef } from '../../../AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import FrenteCaixaPdvObterPagamentosSiTefComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPagamentosSiTefComunicador';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import MovimentoPortadorTefCancelarPagamentoComunicador from '../../../../../../../../Comunicador/Financeiro/Movimentacoes/MovimentoPortador/Tef/Comunicador/MovimentoPortadorTefCancelarPagamentoComunicador';
import { UseLiberacoes } from '../../../../../../../../Hooks/LiberacoesHook';
import ImpressaoNotaFiscalComprovanteTef from '../../../../../../../DocumentosEletronicos/NFCe/ImpressaoComprovanteTef';

interface ICancelarModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const CancelarDetalhe: React.FC<ICancelarModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida: permissao } = permissoes;
  const liberacoes = UseLiberacoes();
  const {
    formRefDetalhe,
    loading,
    setLoading,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
    refresh,
  } = UseListaDetalheForm();
  const { formRef: formPrincipal } = UseForm();
  const { idNotaFiscalSaida } = UseFrenteCaixaPdv();
  const { abrirJanela } = UseConfirmacao();
  const { realizarCancelamentoSiTef } = UseSiTef();

  const [listaItemPagamento, setListaItemPagamento] = useState<
    IFrenteCaixaPdvFinalizarVendaPagamentoLista[]
  >([]);

  const situacao = formPrincipal.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoNotaFiscalSaidaEnum.cancelada;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleCarregar = useCallback(async (): Promise<void> => {
    const response = await FrenteCaixaPdvObterPagamentosSiTefComunicador.show({
      id: String(idNotaFiscalSaida),
    });

    setListaItemPagamento(response);
  }, [idNotaFiscalSaida]);

  useEffect(() => {
    handleCarregarDados();
    handleCarregar();
  }, [handleCarregar, handleCarregarDados]);

  const handleCancelarItemPagamento = useCallback(
    async (index: number) => {
      try {
        setLoading(true);

        const itemPagamento = listaItemPagamento[index];

        if (!itemPagamento.idTef && !itemPagamento.autorizacao) {
          ToastErro('Pagamento não esta vinculado a um venda TEF');
          setLoading(false);
          return;
        }

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja realmente cancelar o pagamento?
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (!resposta) {
          setLoading(false);
          return;
        }

        if (itemPagamento.idTef) {
          const numero = formPrincipal.current?.getFieldValue('numero');
          const dataHoraEmissao =
            formPrincipal.current?.getFieldValue('dataHoraEmissao');
          const terminalCaixa =
            formPrincipal.current?.getFieldValue('terminalCaixa');
          const usuarioAbertura =
            formPrincipal.current?.getFieldValue('usuarioAbertura');

          const responseCancelamentoSiTef = await realizarCancelamentoSiTef({
            idTerminalCaixa: terminalCaixa.id,
            meioPagamento: itemPagamento.formaPagamento.meioPagamento,
            valorTotal: Number(itemPagamento.valor),
            numeroVenda: numero,
            dataHoraEmissao,
            operador: usuarioAbertura.nome,
            nsuHost: itemPagamento.idTef,
          });

          if (responseCancelamentoSiTef) {
            const comprovanteClienteCancelamento =
              responseCancelamentoSiTef.find((e) => e.fieldId === 121);
            const comprovanteEstabelecimentoCancelamento =
              responseCancelamentoSiTef.find((e) => e.fieldId === 122);

            await MovimentoPortadorTefCancelarPagamentoComunicador.update({
              id: String(itemPagamento.idPagamentoTef),
              params: {
                comprovanteClienteCancelamento:
                  comprovanteClienteCancelamento?.data,
                comprovanteEstabelecimentoCancelamento:
                  comprovanteEstabelecimentoCancelamento?.data,
              },
            });

            // #region Impressao

            if (
              comprovanteClienteCancelamento &&
              comprovanteEstabelecimentoCancelamento
            ) {
              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                comprovanteClienteCancelamento.data
              );

              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                comprovanteEstabelecimentoCancelamento.data
              );
            }

            // #endregion Impressao
          }
        } else {
          await MovimentoPortadorTefCancelarPagamentoComunicador.update({
            id: String(itemPagamento.idPagamentoTef),
          });
        }

        listaItemPagamento.splice(index, 1);
        const novaListaPagamento = [...listaItemPagamento];
        setListaItemPagamento(novaListaPagamento);

        refresh();
        setLoading(false);

        if (
          novaListaPagamento.length === 0 &&
          situacao !== SituacaoNotaFiscalSaidaEnum.autorizada
        )
          onFecharFormModal();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      abrirJanela,
      formPrincipal,
      listaItemPagamento,
      onFecharFormModal,
      realizarCancelamentoSiTef,
      refresh,
      setLoading,
      situacao,
    ]
  );

  return (
    <JanelaDetalhe
      titulo="Cancelar NF-e"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              {listaItemPagamento.length > 0 && (
                <Divisor>
                  <h6>Pagamentos TEF</h6>

                  <Row>
                    <Col
                      lg={9}
                      md={12}
                      sm={12}
                      style={{
                        overflow: 'auto',
                        height: 200,
                        width: '100%',
                        marginBottom: 10,
                      }}
                    >
                      <Tabela>
                        <Table
                          striped
                          hover
                          bordered
                          style={{ border: 'transparent' }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="lista-texto"
                                style={{
                                  width: '40%',
                                  textAlign: 'start',
                                  backgroundColor: 'white',
                                }}
                              >
                                <span>Forma Pagamento</span>
                              </th>
                              <th
                                className="lista-valor"
                                style={{
                                  width: '20%',
                                  textAlign: 'start',
                                  backgroundColor: 'white',
                                }}
                              >
                                <span>Valor</span>
                              </th>
                              <th
                                className="lista-valor"
                                style={{
                                  width: '10%',
                                  textAlign: 'start',
                                  backgroundColor: 'white',
                                }}
                              >
                                <span>Parcelas</span>
                              </th>
                              <th
                                className="lista-texto"
                                style={{
                                  width: '30%',
                                  textAlign: 'start',
                                  backgroundColor: 'white',
                                }}
                              >
                                <span>Bandeira</span>
                              </th>

                              <th
                                className="lista-acoes"
                                style={{
                                  textAlign: 'start',
                                  backgroundColor: 'white',
                                }}
                              >
                                <span>Ações</span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {listaItemPagamento?.map((item: any, index) => {
                              return (
                                <tr
                                  key={v4()}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? '#edecee' : '#f9f8fa',
                                  }}
                                >
                                  <td className="lista-texto">
                                    <div>
                                      <span>
                                        {item.formaPagamento.descricao}
                                      </span>
                                      {item.statusTef && (
                                        <span
                                          style={{
                                            color:
                                              item.statusTef ===
                                              StatusTefEnum.pago
                                                ? 'green'
                                                : item.statusTef ===
                                                      StatusTefEnum.cancelamentoIniciado ||
                                                    item.statusTef ===
                                                      StatusTefEnum.emCancelamento ||
                                                    item.statusTef ===
                                                      StatusTefEnum.cancelado
                                                  ? 'red'
                                                  : 'darkgoldenrod',
                                            fontWeight: 'bold',
                                          }}
                                        >{` (${item.statusTef})`}</span>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className="lista-valor"
                                    style={{ padding: 5 }}
                                  >
                                    {Number(item.valor).FormatarParaPtBr()}
                                  </td>
                                  <td
                                    className="lista-valor"
                                    style={{ padding: 5 }}
                                  >
                                    {item.quantidadeParcelas}
                                  </td>
                                  <td
                                    className="lista-texto"
                                    style={{ padding: 5 }}
                                  >
                                    {item.bandeira}
                                  </td>
                                  <td className="tdButton lista-acoes">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <BtnAcoesButton>
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleCancelarItemPagamento(index);
                                          }}
                                          disabled={
                                            loading ||
                                            item.situacaoPagamento ===
                                              StatusTefEnum.cancelado
                                          }
                                        >
                                          <span
                                            style={{
                                              display: 'flex',
                                              fontSize: 16,
                                            }}
                                          >
                                            <MdCancel
                                              style={{ marginRight: 5 }}
                                            />
                                            Cancelar Pagamento
                                          </span>
                                        </Dropdown.Item>
                                      </BtnAcoesButton>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tabela>
                    </Col>
                  </Row>
                </Divisor>
              )}

              {situacao !== SituacaoNotaFiscalSaidaEnum.naoTransmitida &&
                situacao !== SituacaoNotaFiscalSaidaEnum.emTransmissao && (
                  <Divisor>
                    {listaItemPagamento.length > 0 && (
                      <h6
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: 10,
                          marginBottom: 10,
                          color: 'red',
                        }}
                      >
                        Só é possível realizar o cancelamento da NF-e após
                        cancelar os pagamentos!
                      </h6>
                    )}

                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <Textarea
                          ref={inputRefFocus}
                          style={{ minHeight: 250 }}
                          label="Motivo do Cancelamento"
                          name="motivoCancelamento"
                          maxLength={255}
                          disabled={listaItemPagamento.length > 0}
                        />
                      </Col>
                    </Row>
                  </Divisor>
                )}
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{ marginLeft: 15, display: 'flex', alignItems: 'center' }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                !permissao?.altera ||
                loading ||
                situacaoBloquearCampos ||
                listaItemPagamento.length > 0
              }
            >
              <MdCancel />
              <span style={{ marginLeft: 10 }}>Cancelar NF-e</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default CancelarDetalhe;
