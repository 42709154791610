import api from '../../../../../Comum/Services/Api';

const rota = '/suprimentos/produtos/atualizacao-preco-custo-produto';

interface IAtualizacaoPrecoCustoProdutoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoDocumento?: string;
  };
}

interface IAtualizacaoPrecoCustoProdutoComunicadorUpdate {
  params: any;
}

class AtualizacaoPrecoCustoProdutoComunicador {
  public async index(
    params: IAtualizacaoPrecoCustoProdutoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async update(
    params: IAtualizacaoPrecoCustoProdutoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new AtualizacaoPrecoCustoProdutoComunicador();
