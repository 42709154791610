import {
  FormatarDataHoraCompletaParaPtBr,
  INotaFiscalSaidaAutorizacaoLista,
} from '@elogestor/util';
import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheAutorizacaoProps {
  name: string;
}

const NotaFiscalSaidaAutorizacaoLista: React.FC<
  IListaDetalheAutorizacaoProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const valorRef = useRef<INotaFiscalSaidaAutorizacaoLista[]>([]);

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaAutorizacaoLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<INotaFiscalSaidaAutorizacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabela style={{ overflow: 'auto', marginTop: 12 }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Tipo de Evento</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Seq.</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Data e Hora do Evento</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Protocolo Retorno Evento</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Retorno</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((evento) => {
              return (
                <tr key={v4()}>
                  <td className="lista-texto">
                    <div>{evento.tipoEvento}</div>
                  </td>
                  <td className="lista-valor">
                    <div>{evento.numeroSequencialEvento}</div>
                  </td>
                  <td className="lista-texto">
                    <div>
                      {FormatarDataHoraCompletaParaPtBr(evento.dataHoraEvento)}
                    </div>
                  </td>

                  <td className="lista-texto">
                    <div>{evento.numeroProtocoloRetorno}</div>
                  </td>
                  <td className="lista-texto">
                    <div>
                      {evento.codigoRetorno
                        ? `${evento.codigoRetorno} - ${evento.descricaoRetorno}`
                        : ''}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default NotaFiscalSaidaAutorizacaoLista;
