import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { Dropdown } from 'react-bootstrap';
import { UseTableDraggable } from '../../TableDraggable/HooksTable/TableDraggableContext';
import { UseConfirmacao } from '../../Confirmacao/HooksConfirmacao';
import TratarErros from '../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import { UseLog } from '../../LogModal/Hooks/LogProvider';
import BtnAcoesButton from '../../Buttons/BtnAcoesButton';

interface IAcoes {
  deletarFuncao?: () => Promise<any>;
  buscarLogs?: (params: any) => Promise<any>;
  disableDeletar?: boolean;
  disableLog?: boolean;
  botoes?: any;
}

const Acoes: React.FC<IAcoes> = ({
  deletarFuncao,
  buscarLogs,
  disableDeletar,
  disableLog,
  botoes,
}) => {
  const { selecionarDados } = UseTableDraggable();
  const { abrirJanela } = UseConfirmacao();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  return (
    <>
      <BtnAcoesButton>
        {botoes && botoes}

        {!!deletarFuncao && (
          <Dropdown.Item
            disabled={!disableDeletar}
            onClick={async () => {
              const resposta = await abrirJanela({
                titulo: <h2>Confirmação</h2>,
                mensagem: (
                  <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>
                ),
              });

              if (resposta) {
                try {
                  const sucesso = await deletarFuncao();

                  if (sucesso === true || sucesso === undefined) {
                    await selecionarDados();

                    ToastSucesso('Registro Deletado!');
                  }
                } catch (error) {
                  TratarErros(error);
                }
              }
            }}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineDelete style={{ marginRight: 5 }} />
              Excluir
            </span>
          </Dropdown.Item>
        )}

        {!!buscarLogs && (
          <Dropdown.Item
            disabled={disableLog}
            onClick={async () => {
              try {
                abrirJanelaLog(buscarLogs);
              } catch (error) {
                TratarErros(error);
              }
            }}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <CgPlayListSearch style={{ marginRight: 5 }} />
              Logs
            </span>
          </Dropdown.Item>
        )}
      </BtnAcoesButton>
    </>
  );
};

export default Acoes;
