import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IOrdemServicoVeiculoResumoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IOrdemServicoVeiculoResumoPDF {
  dados: IOrdemServicoVeiculoResumoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const OrcamentoResumoPDF: React.FC<IOrdemServicoVeiculoResumoPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNumeroOrdemServico && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Números de Ordem de Serviço: '}
                      </Text>
                      {dados.cabecalho.filtroNumeroOrdemServico}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '6%' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '5%' }} />

            <View style={{ width: '15%' }}>
              <Text>Dt. Emissão</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '40%' }}>
              <Text>Cliente</Text>
            </View>
            <View style={{ width: '4%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Val. Subtotal</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Valor Total</Text>
            </View>
          </View>

          {dados.listaDados.map((dado, index) => {
            return (
              <View
                key={String(index)}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '6%' }}>
                  <Text>{dado.numero}</Text>
                </View>
                <View style={{ width: '5%' }} />

                <View style={{ width: '15%' }}>
                  <Text>{dado.dataHoraEmissao}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '40%' }}>
                  <Text>{dado.nomeRazaoSocial}</Text>
                </View>
                <View style={{ width: '4%' }} />

                <View style={{ width: '13%', alignItems: 'flex-end' }}>
                  <Text>{dado.valorSubtotal}</Text>
                </View>
                <View style={{ width: '2%' }} />

                <View style={{ width: '13%', alignItems: 'flex-end' }}>
                  <Text>{dado.valorTotalBruto}</Text>
                </View>
              </View>
            );
          })}

          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '70%' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                  marginLeft: 2,
                }}
              >
                Total Geral: {dados.totalOrdemServicoVeiculos}
              </Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>{dados.valorSubtotalSomatorio}</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>{dados.valorTotalBrutoSomatorio}</Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrcamentoResumoPDF;
