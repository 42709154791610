import {
  ICaracteristicaProdutoItemLista,
  ICaracteristicaProdutoItemValoresAlterar,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { IInputAutoCompletePadraoRef } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const [listaValor, setListaValor] = useState<
    ICaracteristicaProdutoItemLista[]
  >([]);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof ICaracteristicaProdutoItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const response = listaValor[Number(idEditar)];
        await formRefDetalhe.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          sugestaoPadrao: true,
          ativo: true,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, listaValor, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idCaracteristica: Yup.string()
            .required('Característica é Obrigatória!')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        const id = getIdDetalheRegistro() || '';

        data.idCaracteristica = data.caracteristica
          ? data.caracteristica.id
          : null;

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          listaValor[Number(id)].caracteristica = data.caracteristica;
          listaValor[Number(id)].caracteristicaPai = data.caracteristicaPai;
        } else {
          setListaValor((estadoAnterior) => {
            return [...estadoAnterior, data];
          });
        }

        formPrincipal.formRef.current?.setFieldValue(
          'listaCaracteristicaMudou',
          true
        );

        await formRef?.reset();

        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal.formRef,
      getIdDetalheRegistro,
      handleValidar,
      listaValor,
      redirecionar,
    ]
  );

  const handleExcluir = async (idx: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setListaValor((estadoAnterior) => {
          const listaConjunto = estadoAnterior.filter((_, index) => {
            return index !== Number(idx);
          });

          return listaConjunto;
        });
      }

      formPrincipal.formRef.current?.setFieldValue(
        'listaCaracteristicaMudou',
        true
      );
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
        listaValor,
        setListaValor,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
