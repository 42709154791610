import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/orcamento/orcamento-por-cliente';

interface IOrcamentoPorClienteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrcamentoPorClienteRelatorioComunicador {
  public async index(
    params: IOrcamentoPorClienteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrcamentoPorClienteRelatorioComunicador();
