import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/pesagem/por-data-local-de-estoque';

interface IPesagemPorDataLocalDeEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PesagemPorDataLocalDeEstoqueRelatorioComunicador {
  public async index(
    params: IPesagemPorDataLocalDeEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PesagemPorDataLocalDeEstoqueRelatorioComunicador();
