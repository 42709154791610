"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum;
(function (LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum) {
    LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum["pedidoVenda"] = "Pedido Venda";
    LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum["notaFiscalSaida"] = "Nota Fiscal Sa\u00EDda";
    LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum["notaFiscalEntrada"] = "Nota Fiscal Entrada";
})(LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum || (LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum = {}));
exports.default = LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum;
