import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin : 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  label {
    margin-bottom: 8px;
  }

  html, body, #root {
    height: 100%;
    overflow-y: hidden;
  }

  body {
    background: rgb(45,50,62)!important;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button, textarea {
    font: 14px 'Roboto', sans-serif;
  }

  th div, th span, td div, td span {
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  button {
    cursor: pointer;
  }

  button, button * {
    font-size: 16px;
  }

  button:focus,button:active {
   outline: none;
   box-shadow: none;
  }

  .div-input{
    cursor: text;
  }

  input, textarea, select, .div-input{
    background-color: white;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 10px 0 10px;
  }

  /* START BTN */

    .btn-padrao{
      padding: .375rem .75rem;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: .25rem;
      font-size: 1rem;
      line-height: 1.5;
    }

    /* START BTN-CINZA-CLARO */

    .btn-cinza-claro {
      background: #d6d3d6;
      color: #272727;
      font-weight: 600;
      border-color: #d6d3d6;
    }

    .btn-cinza-claro:hover {
      background: #c4c0c4;
      border-color: #c4c0c4;
    }

    .btn-cinza-claro:active{
      background: #adaaad;
      border-color: #adaaad;
    }

    .btn-cinza-claro:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 170, 173, .25);
      border-color: rgba(173, 170, 173, .25);
    }

    /* END BTN-CINZA-CLARO */

    /* START BTN-AZUL */

    .btn-azul {
      background: #00B0FF;
      font-weight:600;
      color: #fff;
      border-color: #00B0FF;
    }

    .btn-azul:hover {
      background: #4296FF;
      border-color: #4296FF;
    }

    .btn-azul:active{
      background: #4280FF;
      border-color: #4280FF;
    }

    .btn-azul:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 128, 255, .25);
      border-color: rgba(66, 128, 255, .25);
    }

    .btn-azul:disabled {
      color: #b9b9b9;
      background: rgba(66, 128, 255, .25);
      border-color: rgba(66, 128, 255, .25);
    }


    /* END BTN-AZUL */

    /* START BTN-AZUL-ESCURO */

      .btn-azul-escuro {
      background: #007bff;
      font-weight:600;
      color: #fff;
      border-color: #007bff;
    }

    .btn-azul-escuro:hover {
      background: #1988ff;
      border-color: #1988ff;
    }

    .btn-azul-escuro:active{
      background: #007bff;
      border-color: #007bff;
    }

    .btn-azul-escuro:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 128, 255, .25);
      border-color: rgba(66, 128, 255, .25);
    }

    .btn-azul-escuro:disabled {
      color: #b9b9b9;
      background: rgba(66, 128, 255, .25);
      border-color: rgba(66, 128, 255, .25);
    }

    /* END BTN-AZUL-ESCURO */

    /* START BTN-AZUL-CLARO */

    .btn-azul-claro {
      background: #66b9c8;
      color: #fff;
      font-weight: 600;
      border-color: #66b9c8;
    }

    .btn-azul-claro:hover {
      background: #539ca9;
      border-color: #539ca9;
    }

    .btn-azul-claro:active{
      background: #3d707a;
      border-color: #3d707a;
    }

    .btn-azul-claro:focus {
      box-shadow: 0 0 0 0.2rem rgba(61, 112, 122, .25);
      border-color: rgba(61, 112, 122, .25);
    }

    .btn-azul-claro:disabled {
      color: #b9b9b9;
      background: rgba(61, 112, 122, .25);
      border-color: rgba(61, 112, 122, .25);
    }

    /* END BTN-AZUL-CLARO */

    /* START BTN-VERDE */

    .btn-verde {
      background: #05bd61;
      font-weight:600;
      color: #fff;
      border-color: #05bd61;
    }

    .btn-verde:hover {
      background: #03a953;
      border-color: #03a953;
    }

    .btn-verde:active{
      background: #038841;
      border-color: #038841;
    }

    .btn-verde:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 168, 80, .25);
      border-color: rgba(3, 168, 80, .25);
    }

    .btn-verde:disabled {
      color: #b9b9b9;
      background: rgba(3, 168, 80, .25);
      border-color: rgba(3, 168, 80, .25);
    }

    /* END BTN-VERDE */

    /* START BTN-VERDE-CLARO */

    .btn-verde-claro {
      background: #7ac8ae ;
      font-weight:600;
      color: #fff;
      border-color: #7ac8ae;
    }


    .btn-verde-claro:hover {
      background: #68c1a3;
      border-color: #68c1a3;
    }

    .btn-verde-claro:active{
      background: #56b998;
      border-color: #56b998;
    }

    .btn-verde-claro:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 174, 140, .25);
      border-color: rgba(72, 174, 140, .25);
    }

    .btn-verde-claro:disabled {
      color: #b9b9b9;
      background: rgba(72, 174, 140, .25);
      border-color: rgba(72, 174, 140, .25);
    }

    /* END BTN-VERDE */

    /* BTN-VERMELHO */

    .btn-vermelho-claro {
      background: #fa8d8b ;
      font-weight:600;
      color: #fff;
      border-color: #fa8d8b;
    }


    .btn-vermelho-claro:hover {
      background: #db6e6b;
      border-color: #db6e6b;
    }

    .btn-vermelho-claro:active{
      background: #c26361;
      border-color: #c26361;
    }

    .btn-vermelho-claro:focus {
      box-shadow: 0 0 0 0.2rem rgba(219, 110, 107, .3);
      border-color: rgba(219, 110, 107, .3);
    }

    .btn-vermelho-claro:disabled {
      color: #b9b9b9;
      background: rgba(219, 110, 107, .3);
      border-color: rgba(219, 110, 107, .3);
    }


    /* ENDBTN-VERMELHO */


  /* END BTN */

  .hidden {
    display: none;
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .hover {
    transition: .1s filter;

    &:hover {
      filter: brightness(0.95);
    }
  }

  .flex {
    display: flex;
  }

  .lista-data{
    text-align: left;
    vertical-align: middle !important;
  }

  .lista-texto{
    text-align: left;
    vertical-align: middle !important;
  }

  .lista-valor{
    text-align: right;
    vertical-align: middle !important;
  }

  .lista-booleano{
    text-align: middle;
    vertical-align: middle !important;
    font-weight: bold;
  }

  .lista-acoes{
    text-align: middle;
    vertical-align: middle !important;
    justify-content: center;
  }

 .icon-color path {
    stroke: white;
  }
`;

const mobile =
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i);

export { mobile };
