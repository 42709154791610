import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento';

interface IOrcamentoItemOrdemComunicadorUpdate {
  id: string;
  params: {
    listaItem: Array<{
      id: string;
      ordem: number;
    }>;
  };
}

class OrcamentoItemOrdemComunicador {
  public async update(
    params: IOrcamentoItemOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(
      `${rota}/${params.id}/item/ordem`,
      params.params
    );

    return response.data;
  }
}

export default new OrcamentoItemOrdemComunicador();
