import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/produto-enologico-auto-complete';

interface IProdutoEnologicoAutoCompleteComunicadorIndex {
  params: any;
}

class ProdutoEnologicoAutoCompleteComunicador {
  public async index(
    params: IProdutoEnologicoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ProdutoEnologicoAutoCompleteComunicador();
