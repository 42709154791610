import { FormatarEnum, ModeloImpressaoPesagemEnum } from '@elogestor/util';
import React, { useEffect } from 'react';
import Select from '../../../../../../../../../Componentes/Select';
import ITipoDados from '../../../../../Main/Interface/ITipoDados';

const SelectModeloImpressaoPesagem: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return (
    <Select
      name={nome}
      options={FormatarEnum({
        enumObj: ModeloImpressaoPesagemEnum,
      })}
    />
  );
};

export default SelectModeloImpressaoPesagem;
