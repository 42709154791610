import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { PadronizacaoDescricaoListaHook } from './PadronizacaoDescricaoListaHook';
import RotasHook from './RotasHook';

const PadronizacaoDescricaoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <PadronizacaoDescricaoListaHook>
        <FormHook>{children}</FormHook>
      </PadronizacaoDescricaoListaHook>
    </RotasHook>
  );
};

export default PadronizacaoDescricaoHook;
