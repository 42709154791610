const OrcamentoTotalPorDataEmissaoPlanilha = [
  { titulo: 'Data de Emissao', valor: 'chave' },

  { titulo: 'Numero', valor: 'listaOrcamentoItem.orcamentoNumero' },

  {
    titulo: 'Modelo de Documento',
    valor: 'listaOrcamentoItem.modeloDocumento',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaOrcamentoItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaOrcamentoItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaOrcamentoItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaOrcamentoItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaOrcamentoItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaOrcamentoItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaOrcamentoItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaOrcamentoItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaOrcamentoItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaOrcamentoItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaOrcamentoItem.somatorioValorTotal',
  },
];

export default OrcamentoTotalPorDataEmissaoPlanilha;
