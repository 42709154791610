import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { InputContainer } from '../Styles';
import './index.css';

export interface IInputTextEditorRef {
  obterValor: () => string;
}

interface IInputTextEditorProps {
  label?: string | JSX.Element;
  placeholder?: string | JSX.Element;
  defaultValue?: string;
}

const InputTextEditor: React.ForwardRefRenderFunction<
  IInputTextEditorRef,
  IInputTextEditorProps
> = ({ defaultValue, placeholder, label, ...rest }, ref) => {
  const content = ContentState.createFromText(defaultValue ?? '');

  const [state, setState] = useState({
    editorState: EditorState.createWithContent(content),
  });

  useImperativeHandle(ref, () => ({
    obterValor: () => {
      return draftToHtml(convertToRaw(state.editorState.getCurrentContent()));
    },
  }));

  return (
    <InputContainer>
      {label && <div>{label}</div>}
      {placeholder && <div>{placeholder}</div>}
      <Editor
        editorStyle={{
          height: 250,
          backgroundColor: 'white',
          maxHeight: 600,
          padding: 10,
        }}
        editorState={state.editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbarClassName="toobar-className"
        onEditorStateChange={(e) => {
          setState({ editorState: e });
        }}
        {...rest}
      />
    </InputContainer>
  );
};

export default forwardRef(InputTextEditor);
