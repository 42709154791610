import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { INotaFiscalImpressaoListaProdutoServicoPDF } from './NotaFiscalImpressaoPDF';

const styles = StyleSheet.create({
  headerGrupo: {
    marginTop: '5px',
    fontSize: 6,
    fontWeight: 'bold',
  },
});

const NotaFiscalImpressaoProdutoServicoPDF: React.FC<
  INotaFiscalImpressaoListaProdutoServicoPDF
> = ({ listaProdutoServico }) => {
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.headerGrupo}>
        <Text>DADOS DO PRODUTO / SERVIÇO</Text>
      </View>

      <View
        style={{
          flexDirection: 'column',
          border: '1 solid black',
          borderRadius: 5,
          flex: 1,
          position: 'relative',
        }}
      >
        <View style={{ flexDirection: 'row', fontSize: 6 }}>
          <View
            style={{
              width: '9%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>CÓD. PROD</Text>
          </View>

          <View
            style={{
              width: '22%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>DESCRIÇÃO DO PRODUTO / SERVIÇO</Text>
          </View>

          <View
            style={{
              width: '6%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>NCM</Text>
          </View>

          <View
            style={{
              width: '4%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>CST</Text>
          </View>

          <View
            style={{
              width: '4%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>CFOP</Text>
          </View>

          <View
            style={{
              width: '6%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>UNID.</Text>
          </View>

          <View
            style={{
              width: '8%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>QUANT.</Text>
          </View>

          <View
            style={{
              width: '8%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>V. UNITÁRIO</Text>
          </View>

          <View
            style={{
              width: '8%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>V. TOTAL</Text>
          </View>

          <View
            style={{
              width: '5%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>BC. ICMS</Text>
          </View>

          <View
            style={{
              width: '5%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>V. ICMS</Text>
          </View>

          <View
            style={{
              width: '5%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>V. IPI</Text>
          </View>

          <View
            style={{
              width: '5%',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1 solid black',
              borderBottom: '1 solid black',
            }}
          >
            <Text>AL. ICMS</Text>
          </View>

          <View
            style={{
              width: '5%',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
            }}
          >
            <Text>AL. IPI</Text>
          </View>
        </View>

        {listaProdutoServico?.map((dado, index) => {
          return (
            <View
              key={dado.ordem}
              style={{
                flexDirection: 'row',
                fontSize: 6,
                flex: listaProdutoServico.length - 1 === index ? 1 : undefined,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: dado.cor,
                }}
              >
                <View
                  style={{
                    width: '9%',
                    borderRight: '1 solid black',
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        dado.produtoServico.codigo.InserirEspacoRepetindoPorNumeroCaracteres(
                          12
                        )}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '22%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        dado.produtoServico.descricao}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '6%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>{!dado.isProdutoTributado && dado.ncm}</Text>
                  </View>
                </View>

                <View style={{ width: '4%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>{!dado.isProdutoTributado && dado.cstCsosn}</Text>
                  </View>
                </View>

                <View style={{ width: '4%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>{!dado.isProdutoTributado && dado.cfop}</Text>
                  </View>
                </View>

                <View style={{ width: '6%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>{dado.unidadeMedida.sigla}</Text>
                  </View>
                </View>

                <View style={{ width: '8%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {Number(dado.quantidade).FormatarParaPtBr({
                        minimoCasasDecimais: 4,
                        maximoCasasDecimais: 4,
                      })}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '8%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {Number(dado.valorUnitario).FormatarParaPtBr({
                        minimoCasasDecimais: 4,
                        maximoCasasDecimais: 4,
                      })}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '8%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.valorTotal).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '5%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.baseCalculoIcms).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '5%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.valorIcms).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '5%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.valorIpi).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '5%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.aliqutoaIcms).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '5%' }}>
                  <View
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }}
                  >
                    <Text>
                      {!dado.isProdutoTributado &&
                        Number(dado.aliqutoaIpi).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default NotaFiscalImpressaoProdutoServicoPDF;
