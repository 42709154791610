import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaCabecalhoRelatorio,
  IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaRelatorio,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
} from '@elogestor/util';
import React from 'react';
import PlanoCategoriasRegimeCaixaRelatorioComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/MovimentacoesFinanceirasPortadores/PlanoCategoriasRegimeCaixaRelatorioComunicador/PlanoCategoriasRegimeCaixaRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import PlanoCategoriasRegimeCaixaPDF from './PlanoCategoriasRegimeCaixaPDF';
import PlanoCategoriasRegimeCaixaPlanilha from './PlanoCategoriasRegimeCaixaPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PlanoCategoriasRegimeCaixaRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaRelatorio | null> => {
    const filtroBack: IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend =
      {
        dataHoraInicial: filtros.dataInicial,
        dataHoraFinal: filtros.dataFinal,

        somenteCategoriasComValor: filtros.somenteCategoriasComValor,
      };

    if (filtros?.listaPortadoresLiquidacao) {
      filtroBack.listaIdPortadoresLiquidacao =
        filtros.listaPortadoresLiquidacao.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = filtros.tipoData;
    }

    const response = await PlanoCategoriasRegimeCaixaRelatorioComunicador.index(
      { params: { filtro: filtroBack } }
    );

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno =
      response as IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados: any) => {
        return {
          ...dados,
          valorRealizado: Number(dados.valorRealizado).FormatarParaPtBr(),
          percentualCategoriaPaiRealizado: Number(
            dados.percentualCategoriaPaiRealizado
          ).FormatarParaPtBrPercentual(),
          percentualSaidaRealizado: Number(
            dados.percentualSaidaRealizado
          ).FormatarParaPtBrPercentual(),
          percentualEntradaRealizado: Number(
            dados.percentualEntradaRealizado
          ).FormatarParaPtBrPercentual(),

          valorPrevisto: Number(dados.valorPrevisto).FormatarParaPtBr(),

          percentualCategoriaPaiPrevisto: Number(
            dados.percentualCategoriaPaiPrevisto
          ).FormatarParaPtBrPercentual(),
          percentualSaidaPrevisto: Number(
            dados.percentualSaidaPrevisto
          ).FormatarParaPtBrPercentual(),
          percentualEntradaPrevisto: Number(
            dados.percentualEntradaPrevisto
          ).FormatarParaPtBrPercentual(),
        };
      })
    );

    return {
      ...retorno,
      totalEntradasPrevisto: Number(
        retorno.totalEntradasPrevisto
      ).FormatarParaPtBr(),
      totalEntradasRealizado: Number(
        retorno.totalEntradasRealizado
      ).FormatarParaPtBr(),
      totalSaidasPrevisto: Number(
        retorno.totalSaidasPrevisto
      ).FormatarParaPtBr(),
      totalSaidasRealizado: Number(
        retorno.totalSaidasRealizado
      ).FormatarParaPtBr(),
      totalResultadoPrevisto: Number(
        retorno.totalResultadoPrevisto
      ).FormatarParaPtBr(),
      totalResultadoRealizado: Number(
        retorno.totalResultadoRealizado
      ).FormatarParaPtBr(),

      percentualResultadoPrevisto: Number(
        retorno.percentualResultadoPrevisto
      ).FormatarParaPtBrPercentual(),
      percentualResultadoRealizado: Number(
        retorno.percentualResultadoRealizado
      ).FormatarParaPtBrPercentual(),
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    cabecalho: IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaCabecalhoRelatorio
  ): IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataInicial || filtros.dataFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataInicial = '--/--/--';
      cabecalho.filtroDataFinal = '--/--/--';

      if (filtros.dataInicial) {
        cabecalho.filtroDataInicial = FormatarDataHoraParaPtBr(
          filtros.dataInicial
        );
      }

      if (filtros.dataFinal) {
        cabecalho.filtroDataFinal = FormatarDataHoraParaPtBr(filtros.dataFinal);
      }
    }

    if (filtros.dataFinalPosicaoLiquidacao) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataFinalLiquidacao = FormatarDataParaPtBr(
        filtros.dataFinalPosicaoLiquidacao
      );
    }

    if (filtros.tipoData) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoData = filtros.tipoData;
    }

    if (filtros.somenteCategoriasComValor) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteCategoriasComValor =
        filtros.somenteCategoriasComValor;
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PlanoCategoriasRegimeCaixaPlanilha,
      nomeArquivo: 'PlanoCategoriasRegimeCaixa',
      valores: dados.listaDados,
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PlanoCategoriasRegimeCaixaPlanilha,
      nomeArquivo: 'PlanoCategoriasRegimeCaixa',
      valores: dados.listaDados as any,
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados: any = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <PlanoCategoriasRegimeCaixaPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PlanoCategoriasRegimeCaixaPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PlanoCategoriasRegimeCaixaRelatorio();
