"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarNumberParaXmlSemCasasDecimais;
function FormatarNumberParaXmlSemCasasDecimais(valor) {
    const valorString = Number(valor).FormatarParaPtBr();
    const valorFormatado = valorString.replace(/[.]+/g, '').replace(',', '.');
    const index = valorFormatado.indexOf('.');
    const retorno = valorFormatado.substring(0, index);
    return retorno;
}
