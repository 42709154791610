import api from '../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/marca-veiculo';

interface IMarcaVeiculoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IMarcaVeiculoComunicadorShow {
  id: string;
}

interface IMarcaVeiculoComunicadorStore {
  params: any;
}

interface IMarcaVeiculoComunicadorUpdate {
  id: string;
  params: any;
}

interface IMarcaVeiculoComunicadorDeletar {
  id: string;
}

class MarcaVeiculoComunicador {
  public async index(params: IMarcaVeiculoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IMarcaVeiculoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IMarcaVeiculoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IMarcaVeiculoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IMarcaVeiculoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new MarcaVeiculoComunicador();
