import {
  FormatarDataHoraParaPtBr,
  IPedidoVendaFiltroRelatorioFrontend,
  IRetiradaConsignadoFiltroRelatorioBackend,
  IRetiradaConsignadoFiltroRelatorioFrontend,
  IRetiradaConsignadoPorClienteComQtdFaturadaFaturarCabecalhoRel,
  IRetiradaConsignadoPorClienteComQtdFaturadaFaturarRel,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import RetiradaConsignadoPorClienteComQtdFaturadaFaturarPlanilha from './RetiradaConsignadoPorClienteComQtdFaturadaFaturarPlanilha';
import RetiradaConsignadoPorClienteComQtdFaturadaFaturarPDF from './RetiradaConsignadoPorClienteComQtdFaturadaFaturarPDF';
import RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Vendas/RetiradaConsignado/RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador/RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador';

interface IRelatorios {
  handleExportCSV(filtros: IPedidoVendaFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IRetiradaConsignadoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IRetiradaConsignadoPorClienteComQtdFaturadaFaturarRel | null> => {
      const filtroBack: IRetiradaConsignadoFiltroRelatorioBackend = {
        dataEmissaoInicial: filtros.dataEmissaoInicial,
        dataEmissaoFinal: filtros.dataEmissaoFinal,

        descricao: filtros.descricao,
        codigo: filtros.codigo,
      };

      if (filtros?.listaSituacaoConsignado) {
        filtroBack.listaSituacaoConsignado =
          filtros.listaSituacaoConsignado.listaValor.map(
            (value) => value.descricao
          ) || [];
      }

      if (filtros?.listaNumeroConsignado) {
        filtroBack.listaIdNumeroConsignado =
          filtros.listaNumeroConsignado.listaValor.map(
            (value) => value.numero
          ) || [];
      }

      if (filtros?.idFinalidadeUsoCliente) {
        filtroBack.idFinalidadeUsoCliente = filtros.idFinalidadeUsoCliente;
      }

      if (filtros?.listaCidade) {
        filtroBack.listaIdCidade =
          filtros.listaCidade.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaSituacaoItem) {
        filtroBack.listaDescricaoSituacaoItem =
          await filtros.listaSituacaoItem.listaValor.map(
            (value) => value.descricao
          );
      }
      if (filtros?.listaSituacaoPedidoVendaNotaFiscalSaida) {
        filtroBack.listaDescricaoSituacaoPedidoVendaNotaFiscalSaida =
          await filtros.listaSituacaoPedidoVendaNotaFiscalSaida.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaEstado) {
        filtroBack.listaIdEstado =
          filtros.listaEstado.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaCliente) {
        filtroBack.listaIdCliente =
          filtros.listaCliente.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaModeloDocumento) {
        filtroBack.listaIdModeloDocumento =
          filtros.listaModeloDocumento.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaProduto) {
        filtroBack.listaIdProduto =
          filtros.listaProduto.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.tipoBuscaCodigoDescricao) {
        filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
          typeof TipoBuscaCodigoDescricaoEnum,
          TipoBuscaCodigoDescricaoEnum
        >(
          TipoBuscaCodigoDescricaoEnum,
          String(filtros.tipoBuscaCodigoDescricao)
        );
      }

      if (filtros?.listaGrupo) {
        filtroBack.listaIdGrupo =
          filtros.listaGrupo.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaRamoAtividadeProfissao) {
        filtroBack.listaIdRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaOperacaoFiscal) {
        filtroBack.listaIdOperacaoFiscal =
          filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
      }

      const response =
        await RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador.index(
          {
            params: { filtro: filtroBack },
          }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as IRetiradaConsignadoPorClienteComQtdFaturadaFaturarRel;

      retorno.somatorioGeralQuantidadeFaturar = Number(
        retorno.somatorioGeralQuantidadeFaturar
      ).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      retorno.somatorioGeralValorSubtotal = Number(
        retorno.somatorioGeralValorSubtotal
      ).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      retorno.somatorioGeralValorTotal = Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      retorno.somatorioGeralValorSubtotalFaturar = Number(
        retorno.somatorioGeralValorSubtotalFaturar
      ).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      retorno.somatorioGeralValorTotalFaturar = Number(
        retorno.somatorioGeralValorTotalFaturar
      ).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      retorno.totalGeral = Number(retorno.totalGeral).FormatarParaPtBr({
        maximoCasasDecimais: 4,
        minimoCasasDecimais: 4,
      }) as any;

      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          const valores = {
            ...dados.valores,
            somatorioValorSubtotal: Number(
              dados.valores.somatorioValorSubtotal
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            somatorioValorTotal: Number(
              dados.valores.somatorioValorTotal
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            somatorioValorQuantidadeFaturar: Number(
              dados.valores.somatorioValorQuantidadeFaturar
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            somatorioValorSubtotalFaturar: Number(
              dados.valores.somatorioValorSubtotalFaturar
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
            somatorioValorTotalFaturar: Number(
              dados.valores.somatorioValorTotalFaturar
            ).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
          };

          const listaPedidoVendaItem = dados.listaPedidoVendaItem.map(
            (pedidoVenda) => {
              return {
                ...pedidoVenda,
                quantidadeRetirada: Number(
                  pedidoVenda.quantidadeRetirada
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),
                quantidadeDevolvida: Number(
                  pedidoVenda.quantidadeDevolvida
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),
                quantidadeFaturada: Number(
                  pedidoVenda.quantidadeFaturada
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),
                quantidadeFaturar: Number(
                  pedidoVenda.quantidadeFaturar
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),

                valorUnitario: Number(
                  pedidoVenda.valorUnitario
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),

                valorSubtotal: Number(
                  pedidoVenda.valorSubtotal
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),
                valorTotal: Number(pedidoVenda.valorTotal).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),

                subtotalFaturar: Number(
                  pedidoVenda.subtotalFaturar
                ).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                }),
                totalFaturar: Number(pedidoVenda.totalFaturar).FormatarParaPtBr(
                  {
                    maximoCasasDecimais: 4,
                    minimoCasasDecimais: 4,
                  }
                ),

                pedidoVendaDataHoraEmissao: FormatarDataHoraParaPtBr(
                  pedidoVenda.pedidoVendaDataHoraEmissao
                ),
              };
            }
          );

          return {
            ...dados,
            valores,
            listaPedidoVendaItem,
          };
        })
      );

      return {
        ...retorno,
        listaDados: listaDados as any,
        somatorioGeralQuantidade: retorno.somatorioGeralQuantidade,
        somatorioGeralValorSubtotal: retorno.somatorioGeralValorSubtotal,
        somatorioGeralValorTotal: retorno.somatorioGeralValorTotal,
      };
    };

    function FormatarCabecalho(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend,
      cabecalho: IRetiradaConsignadoPorClienteComQtdFaturadaFaturarCabecalhoRel
    ): IRetiradaConsignadoPorClienteComQtdFaturadaFaturarCabecalhoRel {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataEmissaoInicial = '--/--/--';
        cabecalho.filtroDataEmissaoFinal = '--/--/--';

        if (filtros.dataEmissaoInicial) {
          cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoInicial
          );
        }

        if (filtros.dataEmissaoFinal) {
          cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoFinal
          );
        }
      }

      if (
        filtros.listaSituacaoConsignado &&
        filtros.listaSituacaoConsignado.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoConsignado =
          filtros.listaSituacaoConsignado.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaSituacaoItem &&
        filtros.listaSituacaoItem.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaSituacaoPedidoVendaNotaFiscalSaida &&
        filtros.listaSituacaoPedidoVendaNotaFiscalSaida.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoPedidoVendaNotaFiscalSaida =
          filtros.listaSituacaoPedidoVendaNotaFiscalSaida.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaModeloDocumento &&
        filtros.listaModeloDocumento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroModeloDocumento =
          filtros.listaModeloDocumento.listaValor
            .map((valor: any) => {
              return valor.codigoDescricao;
            })
            .join(', ');
      }

      if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCliente = filtros.listaCliente.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (
        filtros.listaRamoAtividadeProfissao &&
        filtros.listaRamoAtividadeProfissao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEstado = filtros.listaEstado.listaValor
          .map((valor: any) => {
            return valor.siglaNome;
          })
          .join(', ');
      }

      if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCidade = filtros.listaCidade.listaValor
          .map((valor: any) => {
            return valor.cidadeUf;
          })
          .join(', ');
      }

      if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroProduto = filtros.listaProduto.listaValor
          .map((valor: any) => {
            return `${valor.codigo} - ${valor.descricao}`;
          })
          .join(', ');
      } else {
        if (filtros.codigo) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroCodigo = filtros.codigo;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }
        if (filtros.descricao) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroDescricao = filtros.descricao;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }
      }

      if (filtros.listaGrupo && filtros.listaGrupo.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroGrupo = filtros.listaGrupo.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaOperacaoFiscal &&
        filtros.listaOperacaoFiscal.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
          .map((valor: any) => {
            return valor.cfopDescricao;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IRetiradaConsignadoFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: RetiradaConsignadoPorClienteComQtdFaturadaFaturarPlanilha,
        nomeArquivo: 'RetiradaConsignadoPorClienteComQtdFaturadaFaturar',
        valores: dados.listaDados,
        unwind: ['listaPedidoVendaItem'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IPedidoVendaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: RetiradaConsignadoPorClienteComQtdFaturadaFaturarPlanilha,
        nomeArquivo: 'RetiradaConsignadoPorClienteComQtdFaturadaFaturar',
        valores: dados.listaDados as any,
        unwind: ['listaPedidoVendaItem'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IPedidoVendaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <RetiradaConsignadoPorClienteComQtdFaturadaFaturarPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IPedidoVendaFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <RetiradaConsignadoPorClienteComQtdFaturadaFaturarPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelatorio();
