import { IOrdemServicoPaisagemSemFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import OrdemServicoPaisagemSemFotoProdutoImpressaoPDF from './OrdemServicoPaisagemSemFotoProdutoImpressaoPDF';

interface IImpressaoOrdemServicoPaisagemSemFotoProduto {
  handleVisualizarPdf(
    data: IOrdemServicoPaisagemSemFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemServicoPaisagemSemFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemServicoPaisagemSemFotoProduto =
  (): IImpressaoOrdemServicoPaisagemSemFotoProduto => {
    async function handleVisualizarPdf(
      dados: IOrdemServicoPaisagemSemFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <OrdemServicoPaisagemSemFotoProdutoImpressaoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrdemServicoPaisagemSemFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <OrdemServicoPaisagemSemFotoProdutoImpressaoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoPaisagemSemFotoProduto();
