import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import { IRoteiroFabricacaoOperacaoLista } from '@elogestor/util';
import { UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import Detalhe from '../Detalhe';
import TrDraggable from '../../../../../../../Componentes/TrDraggable';
import { UseListaRoteiroFabricacaoOperacaoDraggable } from '../Hooks/ListaRoteiroFabricacaoOperacaoDraggableHook';

interface IListaRoteiroFabricacaoOperacaoProps {
  name: string;
}

const RoteiroFabricacaoOperacaoLista: React.FC<
  IListaRoteiroFabricacaoOperacaoProps
> = ({ name }) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaEngenhariaRoteiroDeFabricacao: permissao } = permissoes;
  const formPrincipal = UseForm();
  const valorRef = useRef<IRoteiroFabricacaoOperacaoLista[]>([]);
  const { fieldName, registerField } = UseFieldCia(name);
  const { listaValor, handleMover, setListaValor } =
    UseListaRoteiroFabricacaoOperacaoDraggable();

  const { loading, handleExcluir, formRefDetalhe, setIdDetalheRegistro } =
    UseListaDetalheForm();
  const [showFormModal, setShowFormModal] = useState(false);
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const handleAdicionar = useCallback(async () => {
    setIdDetalheRegistro('');
    setShowFormModal(true);
  }, [setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string) => {
      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback(() => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);
  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IRoteiroFabricacaoOperacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BtnPadraoButton
        className="btn-padrao btn-verde-claro btn-adicionar"
        style={{ maxWidth: 250 }}
        type="button"
        onClick={handleAdicionar}
        disabled={!permissao?.inclui || formPrincipal.loading || loading}
      >
        <TextoLoading loading={formPrincipal.loading || loading}>
          <IoMdAdd />
          <span style={{ marginLeft: 10 }}>Adicionar</span>
        </TextoLoading>
      </BtnPadraoButton>
      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '5%' }}>
                <span>Ordem</span>
              </th>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Operação</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Centro de Trabalho</span>
              </th>
              <th className="lista-texto" style={{ width: '30%' }}>
                <span>Observação</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item, index) => {
              return (
                <TrDraggable
                  idDrag={v4()}
                  index={index}
                  onMover={handleMover}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(String(index));
                  }}
                  key={v4()}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">{item.ordem}</td>
                  <td className="lista-texto">
                    {item.operacaoRoteiroFabricacao.descricao}
                  </td>
                  <td className="lista-texto">
                    {item.centroTrabalho.descricao}
                  </td>
                  <td className="lista-texto">{item.observacao}</td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(String(index));
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <IoMdRemoveCircleOutline
                              style={{ marginRight: 5 }}
                            />
                            Remover
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </TrDraggable>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
      {showFormModal && (
        <Detalhe
          onFecharFormModal={handleFecharFormModal}
          onSalvarFormModal={handleSalvarFormModal}
        />
      )}
    </>
  );
};

export default RoteiroFabricacaoOperacaoLista;
