import { INotaFiscalImpressao } from '@elogestor/util';
import React from 'react';
import DownloadArquivo from '../../../../Util/Arquivos/DownloadArquivo';
import PDF from '../../../../Util/Relatorios/PDF';
import ImpressaoNotaFiscalPDF from './NotaFiscalImpressaoPDF';

interface IImpressaoNotaFiscal {
  handleExportarXml(
    dados: INotaFiscalImpressao,
    nomeArquivo: string
  ): Promise<void>;
  handleVisualizarPdf(dados: INotaFiscalImpressao): Promise<void>;
  handleObterBlobPdf(dados: INotaFiscalImpressao): Promise<Blob | undefined>;
}

const ImpressaoNotaFiscal = (): IImpressaoNotaFiscal => {
  async function handleExportarXml(
    dados: INotaFiscalImpressao,
    nomeArquivo: string
  ): Promise<void> {
    const utf8Content = new TextEncoder().encode(dados as any);
    const blob = new Blob([utf8Content], { type: 'text/xml;charset=UTF-8' });

    DownloadArquivo(blob, `${nomeArquivo}.xml`);
  }

  async function handleVisualizarPdf(
    dados: INotaFiscalImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <ImpressaoNotaFiscalPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: INotaFiscalImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <ImpressaoNotaFiscalPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return { handleExportarXml, handleVisualizarPdf, handleObterBlobPdf };
};

export default ImpressaoNotaFiscal();
