import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/aprovar';

interface IPedidoVendaAprovarComunicadorUpdate {
  id: string;
  params?: any;
}

class PedidoVendaAprovarComunicador {
  public async update(
    params: IPedidoVendaAprovarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new PedidoVendaAprovarComunicador();
