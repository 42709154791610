/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  EstoqueIndicadorTipoEnum,
  IPedidoVendaImportacaoOrcamentoEtapa1Lista,
  IPedidoVendaImportacaoOrcamentoEtapa2Lista,
  IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista,
  IPedidoVendaImportacaoOrcamentoEtapa3Lista,
  ModalidadeFreteEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoOrcamento/Comunicador/PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador';
import PedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoOrcamento/Comunicador/PedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import { UseParametros } from '../../../../Hooks/ParametrosHook';
import PedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoOrcamento/Comunicador/PedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador';
import PedidoVendaImportacaoOrcamentoGerarComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoOrcamento/Comunicador/PedidoVendaImportacaoOrcamentoGerarComunicador';
import PedidoVendaImportacaoOrcamentoEtapa1, {
  IPedidoVendaImportacaoOrcamentoEtapa1Ref,
} from './Etapa1';
import PedidoVendaImportacaoOrcamentoEtapa2, {
  IPedidoVendaImportacaoOrcamentoEtapa2Ref,
} from './Etapa2';
import PedidoVendaImportacaoOrcamentoEtapa3, {
  IPedidoVendaImportacaoOrcamentoEtapa3Ref,
} from './Etapa3';
import PedidoVendaImportacaoOrcamentoImportarComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVendaImportacaoOrcamento/Comunicador/PedidoVendaImportacaoOrcamentoImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import { Sleep } from '../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import TratarInfo from '../../../../Util/Info/TratarInfo';
import PedidoVendaComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';

export interface IAbrirPedidoVendaImportacaoOrcamento {
  idPessoa: string;
  idOrcamento?: string;
  idPedidoVenda?: string;
}

interface IPedidoVendaImportacaoOrcamentoContext {
  setTitulo(titulo: string): void;
  abrirPedidoVendaImportacaoOrcamento({
    idPessoa,
    idOrcamento,
    idPedidoVenda,
  }: IAbrirPedidoVendaImportacaoOrcamento): Promise<any>;
  fecharPedidoVendaImportacaoOrcamento(): void;
}

const PedidoVendaImportacaoOrcamentoContext =
  createContext<IPedidoVendaImportacaoOrcamentoContext>(
    {} as IPedidoVendaImportacaoOrcamentoContext
  );

const PedidoVendaImportacaoOrcamentoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();
  const parametros = UseParametros();

  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idOrcamentoOrigem, setIdOrcamentoOrigem] = useState<
    string | undefined
  >();
  const [idPedidoVendaOrigem, setIdPedidoVendaOrigem] = useState<
    string | undefined
  >();
  const [origemOrcamento, setOrigemOrcamento] = useState(true);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Etapa 1 - Orcamentos

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref = useRef<IPedidoVendaImportacaoOrcamentoEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(async (idPessoa: string) => {
    try {
      setLoading(true);

      const response =
        await PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador.index({
          idPessoa,
        });

      const listaOrdenada = response.listaOrcamento.Ordenar([
        { campo: 'numero', tipo: 'desc' },
      ]);

      await formRefEtapa1.current?.setDataInicial({
        listaEtapa1: listaOrdenada,
      });
      etapa1Ref.current?.handleSelecionarTodosEtapa(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 1 - Orcamentos

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref = useRef<IPedidoVendaImportacaoOrcamentoEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idOrcamento?: string) => {
      try {
        setLoading(true);

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as IPedidoVendaImportacaoOrcamentoEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as IPedidoVendaImportacaoOrcamentoEtapa2Lista[];

        let listaIdOrcamentoSelecionados: string[] = [];
        if (idOrcamento) {
          listaIdOrcamentoSelecionados.push(idOrcamento);
        } else if (listaItemEtapa1) {
          listaIdOrcamentoSelecionados = listaItemEtapa1.map((item) => item.id);
        }

        const response =
          await PedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicador.index(
            { listaIdOrcamento: listaIdOrcamentoSelecionados }
          );

        if (listaItemEtapa2) {
          response.listaOrcamentoItem = response.listaOrcamentoItem.map(
            (itemEtapa2Novo: IPedidoVendaImportacaoOrcamentoEtapa2Lista) => {
              const itemExistente = listaItemEtapa2.find(
                (itemEtapa2Atual: IPedidoVendaImportacaoOrcamentoEtapa2Lista) =>
                  itemEtapa2Atual.id === itemEtapa2Novo.id
              );

              if (itemExistente) {
                itemExistente.ordem = itemEtapa2Novo.ordem;
                return itemExistente;
              }

              return itemEtapa2Novo;
            }
          );
        }

        const listaOrdenada = response.listaOrcamentoItem.Ordenar([
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicialSemExecutarEvento({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 2 - Itens

  // #region Etapa 3 - Estoques

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref = useRef<IPedidoVendaImportacaoOrcamentoEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as IPedidoVendaImportacaoOrcamentoEtapa2Lista[];
      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaValorSelecionados() as IPedidoVendaImportacaoOrcamentoEtapa3Lista[];

      const listaItemEtapa3Novo: IPedidoVendaImportacaoOrcamentoEtapa3Lista[] =
        [];

      for (let index = 0; index < listaItemEtapa2.length; index++) {
        const itemEtapa2 = listaItemEtapa2[index];

        const itemExistente = listaItemEtapa3.find(
          (itemEtapa3Atual: IPedidoVendaImportacaoOrcamentoEtapa3Lista) =>
            itemEtapa3Atual.id === itemEtapa2.id
        );

        if (itemExistente) {
          // Retorna o item da etapa 3 que ja existe ajustando as quantidades
          itemExistente.quantidadeTransferir = itemEtapa2.quantidadeTransferir;
          itemExistente.valorTotal = itemEtapa2.valorTotal;
          itemExistente.modalidadeFrete = itemEtapa2.modalidadeFrete;

          const quantidadeEstoque =
            Number(itemEtapa2.quantidadeTransferir) *
            Number(itemExistente.orcamentoItemEstoque.fatorConversao);

          itemExistente.orcamentoItemEstoque.quantidadeEstoque =
            quantidadeEstoque;

          if (
            itemExistente.orcamentoItemEstoque.listaEstoqueLocalEstoque &&
            itemExistente.orcamentoItemEstoque.listaEstoqueLocalEstoque
              .length === 1
          ) {
            itemExistente.orcamentoItemEstoque.listaEstoqueLocalEstoque[0].quantidade =
              quantidadeEstoque;
            itemExistente.orcamentoItemEstoque.quantidadeTotalEstoqueLocalEstoque =
              quantidadeEstoque;
          }

          if (
            itemExistente.orcamentoItemEstoque.estoqueTipoEntradaSaida ===
            TipoMovimentoEntradaSaidaEnum.entrada
          ) {
            const totalEntrada =
              itemExistente.orcamentoItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.entrada
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalEntrada || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.orcamentoItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          } else {
            const totalSaida =
              itemExistente.orcamentoItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.saida
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalSaida || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.orcamentoItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          }

          listaItemEtapa3Novo.push(itemExistente);
        } else {
          // Cria um novo item apartir da etapa 2
          const response =
            await PedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador.index(
              {
                dataHoraEmissao: new Date(),
                idOrcamentoItem: itemEtapa2.id,
                quantidade: itemEtapa2.quantidadeTransferir,
              }
            );

          const retorno: IPedidoVendaImportacaoOrcamentoEtapa3Lista = {
            id: itemEtapa2.id,
            ordem: itemEtapa2.ordem,
            pessoa: itemEtapa2.pessoa,
            produto: itemEtapa2.produto,
            unidadeMedida: itemEtapa2.unidadeMedida,
            quantidade: itemEtapa2.quantidade,
            quantidadeTransferir: itemEtapa2.quantidadeTransferir,
            valorUnitario: itemEtapa2.valorUnitario,
            valorTotal: itemEtapa2.valorTotal,
            modalidadeFrete: itemEtapa2.modalidadeFrete,

            orcamentoItemEstoque: {
              movimentarEstoque: response.produto.estoque.movimentarEstoque,
              reservarEstoque: response.produto.estoque.movimentarEstoque
                ? Boolean(parametros.ReservaEstoquePadraoPedidoVenda)
                : false,
              unidadeMedida: itemEtapa2.unidadeMedida,
              indicadorTipoEstoque:
                response.produto.estoque.indicadorTipoEstoque,
              fatorConversao: 1,
              quantidadeEstoque: itemEtapa2.quantidadeTransferir,
              quantidadeTotalEstoqueLocalEstoque: 0,
              porContaOrdemTerceiro:
                response.produto.estoque.porContaOrdemTerceiro,
              estoqueTipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              estoqueIndicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              estoqueQuantidade: 0,

              listaEstoqueLocalEstoque: [],
            },
          };

          let estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
          let estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          let estoqueQuantidade = itemEtapa2.quantidadeTransferir;
          let quantidadeTotalEstoqueLocalEstoque = 0;

          const listaEstoqueLocalEstoque: IPedidoVendaImportacaoOrcamentoEtapa3EstoqueLocalEstoqueLista[] =
            [];

          switch (response.produto.estoque.indicadorTipoEstoque) {
            case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  }
                );
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                  pessoaTerceiro: itemEtapa2.pessoa,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  }
                );
              }
              break;
          }

          retorno.orcamentoItemEstoque.listaEstoqueLocalEstoque =
            listaEstoqueLocalEstoque;
          retorno.orcamentoItemEstoque.estoqueTipoEntradaSaida =
            estoqueTipoEntradaSaida;
          retorno.orcamentoItemEstoque.estoqueIndicadorTipoEstoque =
            estoqueIndicadorTipoEstoque;
          retorno.orcamentoItemEstoque.estoqueQuantidade = estoqueQuantidade;
          retorno.orcamentoItemEstoque.quantidadeTotalEstoqueLocalEstoque =
            quantidadeTotalEstoqueLocalEstoque;

          listaItemEtapa3Novo.push(retorno);
        }
      }

      const listaOrdenada = listaItemEtapa3Novo.Ordenar([
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [parametros.ReservaEstoquePadraoPedidoVenda]);

  // #endregion Etapa 3 - Estoques

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!etapa1Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;

      case 2:
        if (!etapa2Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa3();
        break;
    }
  }, [handleCarregarDadosEtapa2, handleCarregarDadosEtapa3, proximoStep, step]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      idOrcamento,
      idPedidoVenda,
    }: IAbrirPedidoVendaImportacaoOrcamento): Promise<any> => {
      setAberto(true);
      setIdOrcamentoOrigem(idOrcamento);
      setIdPedidoVendaOrigem(idPedidoVenda);

      if (!idOrcamento) {
        setOrigemOrcamento(false);
        setStep(1);
        await handleCarregarDadosEtapa1(idPessoa);
      } else {
        setOrigemOrcamento(true);
        setStep(2);
        await handleCarregarDadosEtapa2(idOrcamento);
      }

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, handleCarregarDadosEtapa2, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFreteOrcamento = useCallback(
    async (
      valoresEtapa3: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFreteOrcamento: ModalidadeFreteEnum | null = null;
      if (valoresEtapa3) {
        if (valoresEtapa3.listaEtapa3.length > 1) {
          valoresEtapa3.listaEtapa3.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFreteOrcamento = null;
                } else {
                  modalidadeFreteOrcamento = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFreteOrcamento = null;
              }
            }
          });
        } else {
          modalidadeFreteOrcamento =
            valoresEtapa3.listaEtapa3[0].modalidadeFrete;
        }
      }

      if (!isModalidadeFreteDiferente && modalidadeFreteOrcamento) {
        const pedidoVenda = await PedidoVendaComunicador.show({
          id: String(idPedidoVendaOrigem),
        });
        if (
          pedidoVenda.pedidoVendaTransporteTransportadora.modalidadeFrete &&
          modalidadeFreteOrcamento !==
            pedidoVenda.pedidoVendaTransporteTransportadora.modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade de Frete do Pedido Venda: ${
                pedidoVenda.pedidoVendaTransporteTransportadora
                  .modalidadeFrete ?? ''
              } e do Orçamento ${modalidadeFreteOrcamento}, deseja atualizar o Pedido para ${modalidadeFreteOrcamento}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idPedidoVendaOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!etapa3Ref.current?.handleValidar()) return;

      setLoading(true);

      const valoresEtapa3 = formRefEtapa3.current?.getData();

      let response: any = null;
      if (origemOrcamento) {
        response = await PedidoVendaImportacaoOrcamentoGerarComunicador.store({
          params: { valoresEtapa3, idOrcamentoOrigem },
        });
      } else {
        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFreteOrcamento(valoresEtapa3);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção Orçamentos com modalidade de frete diferente entre si, favor verificar a modalidade de frete do Pedido Venda!'
          );
        }

        response =
          await PedidoVendaImportacaoOrcamentoImportarComunicador.store({
            params: {
              valoresEtapa3,
              idPedidoVendaOrigem,
              alterarModalidadeFrete: resposta,
            },
          });
      }

      if (
        response.listaMensagemAviso &&
        response.listaMensagemAviso.length > 0
      ) {
        TratarInfo(response.listaMensagemAviso);
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleValidarModalidadeFreteOrcamento,
    idOrcamentoOrigem,
    idPedidoVendaOrigem,
    origemOrcamento,
  ]);

  return (
    <PedidoVendaImportacaoOrcamentoContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirPedidoVendaImportacaoOrcamento: handleAbrir,
        fecharPedidoVendaImportacaoOrcamento: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Orçamentos</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <PedidoVendaImportacaoOrcamentoEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <PedidoVendaImportacaoOrcamentoEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <PedidoVendaImportacaoOrcamentoEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {!origemOrcamento && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handlePassoAnterior}
                      >
                        <IoMdReturnLeft />
                        <span style={{ marginLeft: 10 }}>Voltar</span>
                      </button>
                    )}

                    {origemOrcamento && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handleFechar}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                          }}
                        >
                          <MdCancel />
                          <span style={{ marginLeft: 10 }}>Cancelar</span>
                        </span>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </PedidoVendaImportacaoOrcamentoContext.Provider>
  );
};

function UsePedidoVendaImportacaoOrcamento(): IPedidoVendaImportacaoOrcamentoContext {
  const context = useContext(PedidoVendaImportacaoOrcamentoContext);

  if (!context) {
    throw new Error(
      'UsePedidoVendaImportacaoOrcamento deve ser usado com um OrcamentoProvider'
    );
  }

  return context;
}

export {
  PedidoVendaImportacaoOrcamentoHook,
  UsePedidoVendaImportacaoOrcamento,
};
