const ConhecimentoTransportePorTomadorPlanilha = [
  { titulo: 'Tomador', valor: 'chave' },
  { titulo: 'Série', valor: 'listaConhecimentoTransporte.serie' },
  { titulo: 'Número', valor: 'listaConhecimentoTransporte.numero' },
  { titulo: 'Data Emissão', valor: 'listaConhecimentoTransporte.dataEmissao' },
  {
    titulo: 'Notas Fiscais',
    valor: 'listaConhecimentoTransporte.numeroNotasFiscais',
  },
  { titulo: 'Remetente', valor: 'listaConhecimentoTransporte.remetente' },
  { titulo: 'Destinatário', valor: 'listaConhecimentoTransporte.destinatario' },
  { titulo: 'Valor', valor: 'listaConhecimentoTransporte.valorTotal' },
];

export default ConhecimentoTransportePorTomadorPlanilha;
