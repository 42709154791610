import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/forma-pagamento/frente-caixa-pdv';

class FormaPagamentoFrenteCaixaPdvComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new FormaPagamentoFrenteCaixaPdvComunicador();
