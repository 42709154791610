/* eslint-disable prefer-destructuring */
import {
  ConverterPixelParaPonto,
  ConverterPontoParaPixel,
  IEtiquetaElementoValoresInserir,
  ITabelaCodigoValorEmpresaValoresAlterar,
  TipoEtiquetaEnum,
  TipoPapelEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import imageCompression from 'browser-image-compression';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import EtiquetaComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/EtiquetaComunicador';
import { UseEtiquetaElemento } from './EtiquetaElementoHook';
import EtiquetaElementoComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/Elemento/EtiquetaElementoComunicador';
import EtiquetaElementoDisponivelComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/ElementoDisponivel/EtiquetaElementoDisponivelComunicador';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import EtiquetaUpdateImagensComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/EtiquetaUpdateImagensComunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const {
    listaEtiquetaElemento,
    setListaEtiquetaElemento,
    setListaEtiquetaElementoDisponivel,
  } = UseEtiquetaElemento();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof ITabelaCodigoValorEmpresaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await EtiquetaComunicador.show({ id: idEditar });
        await formRef.current?.setDataInicial(response);

        // #region Elemento

        const responseListaEtiquetaElemento =
          await EtiquetaElementoComunicador.index({ id: idEditar });

        const listaEtiquetaElementoFormatado =
          responseListaEtiquetaElemento.map((etiquetaElemento: any) => {
            return {
              ...etiquetaElemento,
              posicaoX: ConverterPontoParaPixel(
                etiquetaElemento.posicaoX
              ).Arredondar(0),
              posicaoY: ConverterPontoParaPixel(
                etiquetaElemento.posicaoY
              ).Arredondar(0),
              largura: ConverterPontoParaPixel(
                etiquetaElemento.largura
              ).Arredondar(0),
              altura: ConverterPontoParaPixel(
                etiquetaElemento.altura
              ).Arredondar(0),
            };
          });

        setListaEtiquetaElemento(listaEtiquetaElementoFormatado);

        // #endregion Elemento

        // #region Elemento Disponivel

        const responseListaEtiquetaElementoDisponivel =
          await EtiquetaElementoDisponivelComunicador.index({
            tipoEtiqueta: response.tipoEtiqueta,
          });

        setListaEtiquetaElementoDisponivel(
          responseListaEtiquetaElementoDisponivel
        );

        // #endregion Elemento Disponivel

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          tipoEtiqueta: TipoEtiquetaEnum.produto,
          tipoPapel: TipoPapelEnum.bobina,
          quantidadeEtiquetasLinha: 1,
          larguraEntreEtiquetas: 0,
          alturaEntreEtiquetas: 0,
          ...dadosPadrao,
        });

        const responseListaEtiquetaElementoDisponivel =
          await EtiquetaElementoDisponivelComunicador.index({
            tipoEtiqueta: TipoEtiquetaEnum.produto,
          });

        setListaEtiquetaElementoDisponivel(
          responseListaEtiquetaElementoDisponivel
        );

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      setListaEtiquetaElemento,
      setListaEtiquetaElementoDisponivel,
      handleSetarFocus,
      refresh,
      redirecionar,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        descricaoModeloEtiqueta: Yup.string().required(
          'Descrição Modelo Etiqueta é obrigatório!'
        ),
        quantidadeEtiquetasLinha: Yup.number().required(
          'Quantidade de Etiqueta po Linha é obrigatória!'
        ),

        margemEsquerda: Yup.mixed()
          .nullable()
          .test({
            message: 'Margem Esquerda é obrigatória!',
            test: () => {
              if (data.tipoPapel === TipoPapelEnum.a4 && !data.margemEsquerda) {
                return false;
              }
              return true;
            },
          }),
        margemDireita: Yup.mixed()
          .nullable()
          .test({
            message: 'Margem Direita é obrigatória!',
            test: () => {
              if (data.tipoPapel === TipoPapelEnum.a4 && !data.margemDireita) {
                return false;
              }
              return true;
            },
          }),
        margemSuperior: Yup.mixed()
          .nullable()
          .test({
            message: 'Margem Superior é obrigatória!',
            test: () => {
              if (data.tipoPapel === TipoPapelEnum.a4 && !data.margemSuperior) {
                return false;
              }
              return true;
            },
          }),
        margemInferior: Yup.mixed()
          .nullable()
          .test({
            message: 'Margem Inferior é obrigatória!',
            test: () => {
              if (data.tipoPapel === TipoPapelEnum.a4 && !data.margemInferior) {
                return false;
              }
              return true;
            },
          }),

        larguraEtiqueta: Yup.number()
          .nullable()
          .required('Largura da Etiqueta é obrigatória!'),
        alturaEtiqueta: Yup.number()
          .nullable()
          .required('Altura da Etiqueta é obrigatória!'),

        larguraTotal: Yup.mixed()
          .nullable()
          .test({
            message: 'Largura Total é obrigatória!',
            test: () => {
              if (data.quantidadeEtiquetasLinha > 1 && !data.larguraTotal) {
                return false;
              }
              return true;
            },
          }),
        alturaTotal: Yup.mixed()
          .nullable()
          .test({
            message: 'Altura Total é obrigatória!',
            test: () => {
              if (data.quantidadeEtiquetasLinha > 1 && !data.alturaTotal) {
                return false;
              }
              return true;
            },
          }),

        alturaEntreEtiquetas: Yup.number()
          .nullable()
          .required('Altura entre Etiquetas é obrigatória!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        const formData = new FormData();

        const optionsViewSelec = {
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const listaEtiquetaElementoFormatada: IEtiquetaElementoValoresInserir[] =
          [];

        for (let i = 0; i < listaEtiquetaElemento.length; i++) {
          const etiquetaElemento = listaEtiquetaElemento[i];

          let nomeArquivo: string | undefined;

          if (etiquetaElemento.file) {
            const compressedFile = await imageCompression(
              etiquetaElemento.file,
              optionsViewSelec
            );
            const convertedBlobFile = new File(
              [compressedFile],
              etiquetaElemento.file.name,
              {
                type: compressedFile.type,
                lastModified: Date.now(),
              }
            );

            let tipo = 'png';
            const contentType = etiquetaElemento.file.type?.split('/');
            if (contentType && contentType.length > 1) {
              tipo = contentType[1];
            }

            nomeArquivo = `${etiquetaElemento.index}.${tipo}`;
            formData.append('imagens', convertedBlobFile, nomeArquivo);
          }

          listaEtiquetaElementoFormatada.push({
            ...etiquetaElemento,
            posicaoX: ConverterPixelParaPonto(
              etiquetaElemento.posicaoX
            ).Arredondar(0),
            posicaoY: ConverterPixelParaPonto(
              etiquetaElemento.posicaoY
            ).Arredondar(0),
            largura: ConverterPixelParaPonto(
              etiquetaElemento.largura
            ).Arredondar(0),
            altura: ConverterPixelParaPonto(etiquetaElemento.altura).Arredondar(
              0
            ),
            file: undefined,
            urlImagem: nomeArquivo,
          });
        }

        data = {
          ...data,
          listaEtiquetaElemento: listaEtiquetaElementoFormatada,
        };

        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        await EtiquetaUpdateImagensComunicador.update({ params: formData });

        if (id) {
          await EtiquetaComunicador.update({ id, params: data });
        } else {
          const response = await EtiquetaComunicador.store({ params: data });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleValidar,
      listaEtiquetaElemento,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
