/* eslint-disable jsx-a11y/label-has-associated-control */
import { isBefore } from 'date-fns';
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import InputDate, { IInputDateRef } from '../InputDate';

export interface IInputPeriodoDataRef {
  focus(): void;
}

interface IInputPeriodoDataProps {
  nomeDataInicial: string;
  nomeDataFinal: string;
  labelDataInicial?: string;
  labelDataFinal?: string;
}

const InputPeriodoData: React.ForwardRefRenderFunction<
  IInputPeriodoDataRef,
  IInputPeriodoDataProps
> = (
  { nomeDataInicial, nomeDataFinal, labelDataInicial, labelDataFinal },
  ref
) => {
  const dataInicioRef = useRef<IInputDateRef>(null);
  const dataFimRef = useRef<IInputDateRef>(null);

  const validarDatas = useCallback(() => {
    dataInicioRef.current?.setErro('');
    dataFimRef.current?.setErro('');

    const dataInicioString = dataInicioRef.current?.value();
    const dataFimString = dataFimRef.current?.value();

    if (!dataInicioString || !dataFimString) return;

    const dataInicio = new Date(dataInicioString);
    const dataFim = new Date(dataFimString);

    if (isBefore(dataFim, dataInicio)) {
      dataInicioRef.current?.setErro('Data final menor que data inicial');
      dataFimRef.current?.setErro('Data final menor que data inicial');
    }
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      dataInicioRef.current?.focus();
    },

    getPeriodo() {
      const dataInicio = dataInicioRef.current?.getValueFormatado();
      const dataFim = dataFimRef.current?.getValueFormatado();

      if (!dataInicio && !dataFim) {
        return '';
      }

      if ((!dataInicio && dataFim) || (dataInicio && !dataFim)) {
        return dataInicio || (dataFim as string);
      }

      return `${dataInicio} até ${dataFim}`;
    },
  }));

  return (
    <>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <InputDate
            ref={dataInicioRef}
            name={nomeDataInicial}
            label={labelDataInicial}
            onChange={validarDatas}
          />
        </Col>
        <Col>
          <InputDate
            ref={dataFimRef}
            name={nomeDataFinal}
            label={labelDataFinal}
            onChange={validarDatas}
            horasDefault="23:59:59"
          />
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(InputPeriodoData);
