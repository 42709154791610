import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import OrdemProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/OrdemProducaoComunicador';
import FormHook from './FormHook';
import { OrdemProducaoHook } from './OrdemProducaoHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const OrdemProducaoHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: OrdemProducaoComunicador }}>
      <RotasHook>
        <OrdemProducaoHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </OrdemProducaoHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default OrdemProducaoHooks;
