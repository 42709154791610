import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-pis-cofins';

interface IRegraEscolhaCstCreditoPisCofinsComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaCstCreditoPisCofinsComunicadorShow {
  id: string;
}

interface IRegraEscolhaCstCreditoPisCofinsComunicadorStore {
  params: any;
}

interface IRegraEscolhaCstCreditoPisCofinsComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaCstCreditoPisCofinsComunicadorDeletar {
  id: string;
}

class RegraEscolhaCstCreditoPisCofinsComunicador {
  public async index(
    params: IRegraEscolhaCstCreditoPisCofinsComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCstCreditoPisCofinsComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaCstCreditoPisCofinsComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaCstCreditoPisCofinsComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaCstCreditoPisCofinsComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoPisCofinsComunicador();
