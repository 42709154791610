import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaNotaFiscalEntradaDraggableHook } from '../../../ItemTab/ListaDetalheItem/Hook/ListaNotaFiscalEntradaDraggableHook';
import FormHook from './FormHook';
import { ItemNavegacaoHook } from './ItemNavegacaoHook';
import RotasHook from './RotasHook';

const ImpostosCreditosHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <ListaNotaFiscalEntradaDraggableHook>
        <FormHook>
          <ItemNavegacaoHook>{children}</ItemNavegacaoHook>
        </FormHook>
      </ListaNotaFiscalEntradaDraggableHook>
    </RotasHook>
  );
};

export default ImpostosCreditosHook;
