import ILogPesquisaParametro from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/transmissao/evento/log';

interface INotaFiscalSaidaTransmissaoEventoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class NotaFiscalSaidaTransmissaoEventoLogComunicador {
  public async show(
    params: INotaFiscalSaidaTransmissaoEventoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalSaidaTransmissaoEventoLogComunicador();
