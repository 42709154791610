import React from 'react';

type IBtnDuplicar = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const BtnDuplicar: React.FC<IBtnDuplicar> = ({ children, ...rest }) => {
  return (
    <button type="button" className="btn-padrao btn-azul-claro" {...rest}>
      {children}
    </button>
  );
};

export default BtnDuplicar;
