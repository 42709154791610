import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/pedido-compra-importacao-cotacao-compra/obter-cotacao-compra-item';

interface IPedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicadorIndex {
  listaIdCotacaoCompra: string[];
}

class PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicador {
  public async index(
    params: IPedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicador();
