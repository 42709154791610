import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda-importacao-orcamento/obter-orcamento';

interface IPedidoVendaImportacaoOrcamentoObterOrcamentoComunicadorIndex {
  idPessoa: string;
}

class PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador {
  public async index(
    params: IPedidoVendaImportacaoOrcamentoObterOrcamentoComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoObterOrcamentoComunicador();
