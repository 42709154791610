import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/movimentacoes/movimento-portador/obter-lista-utilizacao-adiantamento';

interface IMovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicadorShow {
  params: any;
}

class MovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicador {
  public async show(
    params: IMovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.params.id}`);

    return response.data;
  }
}

export default new MovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicador();
