import React, { createContext, useCallback, useContext } from 'react';
import { TipoContaEnum } from '@elogestor/util';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import RegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicador from '../../../../../../Comunicador/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Comunicador/RegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicador';
import { UseContaListaCategoria } from './ContaListaCategoriaHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IContaCategoriaContext {
  validador: boolean;

  adicionarListaCategoria(): Promise<void>;
}

const ContaCategoriaContext = createContext<IContaCategoriaContext>(
  {} as IContaCategoriaContext
);

const ContaCategoriaHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef, setLoading, refresh } = UseForm();
  const { calcularValorTotalCategoria, validarListaContaParcelaCategoria } =
    UseContaListaCategoria();

  const handleAdicionarListaCategoria = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const listaContaParcela =
        formRef.current?.getFieldValue('listaContaParcela');

      const { id } = listaContaParcela[0];
      if (!id) {
        setLoading(false);
        return;
      }
      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');

      const listaRegraEscolharCategoriaAtualizar =
        await RegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicador.show(
          {
            params: {
              idContaParcela: id,
              dataPesquisaVigencia: dataHoraEmissao,
            },
          }
        );

      if (
        !listaRegraEscolharCategoriaAtualizar ||
        listaRegraEscolharCategoriaAtualizar.length === 0 ||
        listaRegraEscolharCategoriaAtualizar.some((e: any) => !e.idCategoria)
      ) {
        setLoading(false);
        return;
      }

      // #region Remover Categorias

      const listaContaCategoriaRemover = formRef.current?.getFieldValue(
        'listaContaCategoria'
      );

      const listaContaParcelaRemover =
        formRef.current?.getFieldValue('listaContaParcela');

      for (let i = 0; i < listaContaCategoriaRemover.length; i++) {
        const contaCategoria = listaContaCategoriaRemover[i];

        if (listaContaParcelaRemover.length > 0) {
          listaContaParcelaRemover.forEach((parcela: any) => {
            if (parcela.listaContaParcelaCategoria.length > 0) {
              const indexArrayParcelaCategoria =
                parcela.listaContaParcelaCategoria
                  .map((e: any) => e.idCategoria)
                  .indexOf(contaCategoria.idCategoria);

              if (indexArrayParcelaCategoria > -1) {
                parcela.listaContaParcelaCategoria.splice(
                  indexArrayParcelaCategoria,
                  1
                );
              }
            }

            if (parcela.listaContaParcelaLiquidacao.length > 0) {
              parcela.listaContaParcelaLiquidacao.forEach((liquidacao: any) => {
                const indexArrayLiquidacaoCategoria =
                  liquidacao.listaMovimentoPortadorCategoria
                    .map((e: any) => e.idCategoria)
                    .indexOf(contaCategoria.idCategoria);

                if (indexArrayLiquidacaoCategoria > -1) {
                  liquidacao.listaMovimentoPortadorCategoria.splice(
                    indexArrayLiquidacaoCategoria,
                    1
                  );
                }
              });
            }
          });
        }
      }

      formRef.current?.setFieldValue('listaContaCategoria', []);
      formRef.current?.setFieldValue('listaCategoriaMudou', true);

      // #endregion Remover Categorias

      await Sleep(50);
      refresh();

      // #region Adicionar Categorias

      for (let i = 0; i < listaRegraEscolharCategoriaAtualizar.length; i++) {
        const data = listaRegraEscolharCategoriaAtualizar[i];

        let deveAgrupar = false;
        let indexAgrupamento = 0;

        const valoresInserir = {
          idCategoria: data.idCategoria,
          categoria: data.categoria,
          tipoLancamento: data.tipoLancamento,
          tipo: data.tipoConta === TipoContaEnum.receber ? 1 : -1,
          valor: Number(data.valorRateioCategoria),
          percentualCategoria: Number(data.totalParcelaPercentual),
          obtidaPorRegra: true,
          avista: data.avista,

          listaContaCategoriaFormaPagamento: {
            listaAcao: {
              listaInserir: data.idForamPagamento
                ? [
                    {
                      chave: data.idForamPagamento,
                      ordem: 1,
                      valor: {
                        ...data.formaPagamento,
                      },
                    },
                  ]
                : [],
              listaExcluir: [],
              listaMover: [],
            },
            listaValor: data.idForamPagamento
              ? [
                  {
                    ...data.formaPagamento,
                  },
                ]
              : [],
          },
        };

        const listaContaCategoria = formRef.current?.getFieldValue(
          'listaContaCategoria'
        );

        listaContaCategoria.forEach((item: any, index: number) => {
          if (
            item.idCategoria === valoresInserir.idCategoria &&
            item.tipoLancamento === valoresInserir.tipoLancamento &&
            item.avista === valoresInserir.avista
          ) {
            deveAgrupar = true;
            indexAgrupamento = index;
          }
        });

        if (deveAgrupar) {
          listaContaCategoria[indexAgrupamento] = {
            ...listaContaCategoria[indexAgrupamento],
            valor:
              Number(listaContaCategoria[indexAgrupamento].valor) +
              valoresInserir.valor,
            percentualCategoria:
              Number(
                listaContaCategoria[indexAgrupamento].percentualCategoria
              ) + valoresInserir.percentualCategoria,
          };

          calcularValorTotalCategoria(listaContaCategoria);

          await validarListaContaParcelaCategoria({
            listaContaCategoria,
            listaContaParcela,
          });
        } else {
          formRef.current?.setFieldValue('listaContaCategoria', [
            ...listaContaCategoria,
            valoresInserir,
          ]);

          await validarListaContaParcelaCategoria({
            listaContaCategoria: [...listaContaCategoria, valoresInserir],
            listaContaParcela,
          });
        }

        formRef.current?.setFieldValue('listaCategoriaMudou', true);

        await Sleep(50);
        refresh();
      }

      // #endregion Adicionar Categorias

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    calcularValorTotalCategoria,
    formRef,
    refresh,
    setLoading,
    validarListaContaParcelaCategoria,
  ]);

  return (
    <ContaCategoriaContext.Provider
      value={{
        adicionarListaCategoria: handleAdicionarListaCategoria,
        validador: true,
      }}
    >
      {children}
    </ContaCategoriaContext.Provider>
  );
};

function UseContaCategoria(): Omit<IContaCategoriaContext, 'validador'> {
  const context = useContext(ContaCategoriaContext);

  if (!context.validador) {
    throw new Error(
      'UseContaCategoria deve ser usado com um ContaCategoriaHook'
    );
  }

  return context;
}

export { ContaCategoriaHook, UseContaCategoria };
