import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-ordem-servico/obter-ordem-servico-item-estoque';

interface INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicadorIndex {
  listaItemSelecionado: any[];
}

class NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemEstoqueComunicador();
