import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoUsuarioEnum } from '@elogestor/util';
import Divisor from '../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../Componentes/Inputs/InputCheckbox';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';

const LiberacoesTab: React.FC = () => {
  const { tipoUsuario: tipoUsuarioLogado } = UsePermissoes();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteExcluirCupon"
                label="Exclusão de Cupon"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteCancelarCupon"
                label="Cancelamento de Cupon"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteExcluirConsignado"
                label="Exclusão de Consignado"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteDevolucaoConsignado"
                label="Devolução de Consignado"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteRealizarVendaClienteSemCredito"
                label="Permitir Realizar Vendas a Clientes sem Crédito"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteAlteraValorLimiteCreditoNoCliente"
                label="Permite Alterar Valor do Limite de Crédito no Cliente"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteVendaParaClienteEmAtraso"
                label="Permite Venda para Clientes em Atraso"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteRealizarConsignadoClienteSemCredito"
                label="Permitir Realizar Consignados a Clientes sem Crédito"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteExcluirBoleto"
                label="Permitir Excluir Boletos"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteVisualizarComissoesOutrosRepresentantes"
                label="Permite Visualizar Comissões de Outros Representantes"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteAlterarValorUnitarioVendaTabelaPrecoInformada"
                label="Permite Alterar Valor Unitário de Venda, com Tabela de Preço Informada"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteDigitarManualmentePesosNoCadastroDePesagem"
                label="Permite Digitar Manualmente Pesos no Cadastro de Pesagens"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.mostrarValorTotalVendasFrenteCaixa"
                label="Mostrar Valor Total das Vendas Junto ao Frente de Caixa"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteGerarNotaFrenteCaixa"
                label="Permite Gerar Nota Fiscal pelo Frente de Caixa (PDV)"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                name="usuarioLiberacao.permiteGerarCupomFrenteCaixa"
                label="Permite Gerar Cupom Fiscal pelo Frente de Caixa (PDV)"
                disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default LiberacoesTab;
