import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/regra-escolha-sisdevin/ordem';

interface IRegraEscolhaSisdevinOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaSisdevinOrdemComunicador {
  public async update(
    params: IRegraEscolhaSisdevinOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaSisdevinOrdemComunicador();
