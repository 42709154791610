import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/obter-pagamentos-sitef';

interface IFrenteCaixaPdvObterPagamentosSiTefComunicadorShow {
  id: string;
}

class FrenteCaixaPdvObterPagamentosSiTefComunicador {
  public async show(
    params: IFrenteCaixaPdvObterPagamentosSiTefComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterPagamentosSiTefComunicador();
