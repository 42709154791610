import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/movimentacoes-financeiras-portadores/resumo-diario-previsto-realizado';

interface IMovimentacoesFinanceirasResumoDiarioPrevistoRealizadoComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ResumoDiarioPrevistoRealizadoComunicador {
  public async index(
    params: IMovimentacoesFinanceirasResumoDiarioPrevistoRealizadoComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ResumoDiarioPrevistoRealizadoComunicador();
