import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/veiculo-auto-complete';

interface IVeiculoAutoCompleteComunicadorIndex {
  params: any;
}

class VeiculoAutoCompleteComunicador {
  public async index(
    params: IVeiculoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new VeiculoAutoCompleteComunicador();
