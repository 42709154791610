import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/comissoes/comissoes-nota-fiscal/comissoes-nota-fiscal-por-faturamento-por-item';

interface IComissoesNotaFiscalPorFaturamentoPorItemRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ComissoesNotaFiscalPorFaturamentoPorItemRelatorioComunicador {
  public async index(
    params: IComissoesNotaFiscalPorFaturamentoPorItemRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ComissoesNotaFiscalPorFaturamentoPorItemRelatorioComunicador();
