import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputInteiroNulavel from '../../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseItemTab } from '../../Hook/ItemTabHook';

const ItemEngenhariaTab: React.FC = () => {
  const { calcularItemEngenhariaPesagem, calcularItemEngenhariaMetragem } =
    UseItemTab();

  const handleCalcularItemEngenhariaPesagem = useCallback(() => {
    calcularItemEngenhariaPesagem();
  }, [calcularItemEngenhariaPesagem]);
  const handleCalcularItemEngenhariaMetragem = useCallback(() => {
    calcularItemEngenhariaMetragem();
  }, [calcularItemEngenhariaMetragem]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Quantidade (Volumes) Unitário"
              name="cotacaoCompraItemEngenharia.quantidadeVolumesUnitario"
              placeholder="Quantidade (Volumes) Unitário"
              onChange={handleCalcularItemEngenhariaPesagem}
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Peso Bruto Unitário"
              name="cotacaoCompraItemEngenharia.pesoBrutoUnitario"
              placeholder="Peso Bruto Unitário"
              casasInteiras={18}
              casasDecimais={3}
              onChange={handleCalcularItemEngenhariaPesagem}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Peso Líquido Unitário"
              name="cotacaoCompraItemEngenharia.pesoLiquidoUnitario"
              placeholder="Peso Líquido Unitário"
              casasInteiras={18}
              casasDecimais={3}
              onChange={handleCalcularItemEngenhariaPesagem}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12}>
            <InputInteiroNulavel
              label="Quantidade (Volumes)"
              name="cotacaoCompraItemEngenharia.quantidadeVolumes"
              placeholder="Quantidade (Volumes)"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Peso Bruto"
              name="cotacaoCompraItemEngenharia.pesoBruto"
              placeholder="Peso Bruto"
              casasInteiras={18}
              casasDecimais={3}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Peso Líquido"
              name="cotacaoCompraItemEngenharia.pesoLiquido"
              placeholder="Peso Líquido"
              casasInteiras={18}
              casasDecimais={3}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Metro Quadrado Unitário (m2)"
              name="cotacaoCompraItemEngenharia.metroQuadradoUnitario"
              placeholder="Metro Quadrado Unitário (m2)"
              casasInteiras={18}
              casasDecimais={3}
              onChange={handleCalcularItemEngenhariaMetragem}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Metro Cúbico Unitário (m3)"
              name="cotacaoCompraItemEngenharia.metroCubicoUnitario"
              placeholder="Metro Cúbico Unitário (m3)"
              casasInteiras={18}
              casasDecimais={3}
              onChange={handleCalcularItemEngenhariaMetragem}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Metro Quadrado (m2)"
              name="cotacaoCompraItemEngenharia.metroQuadrado"
              placeholder="Metro Quadrado (m2)"
              casasInteiras={18}
              casasDecimais={3}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputDecimalNulavel
              label="Metro Cúbico (m3)"
              name="cotacaoCompraItemEngenharia.metroCubico"
              placeholder="Metro Cúbico (m3)"
              casasInteiras={18}
              casasDecimais={3}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemEngenhariaTab;
