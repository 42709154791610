/* eslint-disable no-nested-ternary */
import {
  SituacaoConhecimentoTransporteEnum,
  FormatarEnum,
  IConhecimentoTransporteValoresAlterar,
  StringConverterParaEnum,
  TipoCteEnum,
  TipoServicoEnum,
  TipoTomadorEnum,
  TipoMovimentoEntradaSaidaEnum,
  FormatarRemovendoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import Divisor from '../../../../../Componentes/Divisor';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import PrincipalTab from '../PrincipalTab';
import InputAutoCompleteSerie from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import SerieObterProximoNumeroIncrementarComunicador from '../../../../../Comunicador/Fiscal/Tributacao/Serie/Comunicador/SerieObterProximoNumeroIncrementarComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import InputInteiro from '../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../Componentes/Inputs/InputDateTime';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';
import Select from '../../../../../Componentes/Select';
import { ServicoTab } from '../ServicoTab';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import CargaTab from '../CargaTab';
import NotaReferenciadaTab from '../NotaReferenciadaTab';
import DocumentoReferenciadoTab from '../DocumentoReferenciadoTab';
import InformacaoAdicionalTab from '../InformacaoAdicionalTab';
import ImpostoTab from '../ImpostoTab';
import ParticipantesTab from '../ParticipantesTab';
import AnulacaoTab from '../AnulacaoTab';
import SubstitutoTab from '../SubstitutoTab';
import DocumentoAnteriorTab from '../DocumentoAnteriorTab';
import ConfiguracaoTributariaCteObterTipoNotaMotivoComunicador from '../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaCteObterTipoNotaMotivoComunicador';
import ConfiguracaoTributariaComunicador from '../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaComunicador';
import { UseSituacaoBloquearCampos } from '../Hooks/SituacaoBloquearCamposHook';
import { UseShowTabs } from '../Hooks/ShowTabsHook';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { UseRegimeTributario } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import AutorizacaoTab from '../AutorizacaoTab';
import InputHiddenHtml from '../../../../../Componentes/Inputs/InputHiddenHtml';
import CteComplementadoTab from '../CteComplementadoTab';
import RegraEscolhaAliquotaObterAliquotaInterestadualComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaObterAliquotaInterestadualComunicador';
import Input from '../../../../../Componentes/Inputs/Input';

type IProps = IMain<IConhecimentoTransporteValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
    setLoading,
    inputRefFocus,
    refresh,
  } = UseForm();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();
  const { showNotaReferenciadaTab } = UseShowTabs();
  const { setRegimeTributarioPorData } = UseRegimeTributario();

  const [tabSelecionada, setTabSelecionada] = useState('principal');
  const [tipoTomador, setTipoTomador] = useState<TipoTomadorEnum | null>(null);
  const [tipoCte, setTipoCte] = useState<TipoCteEnum>(TipoCteEnum.normal);
  const [tipoServico, setTipoServico] = useState<TipoServicoEnum>(
    TipoServicoEnum.normal
  );

  const serie = formRef.current?.getFieldValueNomeObjeto('serie');
  const transmissaoVersaoLayout = formRef.current?.getFieldValue(
    'transmissao.versaoLayout'
  );

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleAtualizarNumero = useCallback(
    async (event: any) => {
      if (idDetalheRegistro || formRef.current?.getFieldValue('numero')) {
        return;
      }

      try {
        if (!event.itemAtual?.id) {
          await formRef.current?.setSemExecutarEvento({ numero: 0 });
          return;
        }

        setLoading(true);

        const proximoNumero =
          await SerieObterProximoNumeroIncrementarComunicador.show({
            id: event.itemAtual.id,
          });

        await formRef.current?.setSemExecutarEvento({
          numero: proximoNumero,
        });
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    },
    [formRef, idDetalheRegistro, setLoading]
  );

  const handleCarregarTipoNotaMotivo = useCallback(async () => {
    const idPessoaRemetente =
      formRef.current?.getFieldValue('idPessoaRemetente');
    const idPessoaDestinatario = formRef.current?.getFieldValue(
      'idPessoaDestinatario'
    );
    const dataPesquisaVigencia =
      formRef.current?.getFieldValue('dataHoraEmissao');

    const idSerie = formRef.current?.getFieldValue('idSerie');

    if (
      !dataPesquisaVigencia ||
      !idPessoaRemetente ||
      !idPessoaDestinatario ||
      !idSerie
    ) {
      return;
    }

    try {
      const response =
        await ConfiguracaoTributariaCteObterTipoNotaMotivoComunicador.show({
          params: {
            dataPesquisaVigencia,
            idPessoaDestinatario,
            idPessoaRemetente,
          },
        });

      if (!response) {
        setLoading(false);
        return;
      }

      const { tipoNota, tipoNotaMotivo } = response;

      if (tipoNota)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.tipoNota',
          tipoNota
        );

      if (tipoNotaMotivo)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.tipoNotaMotivo',
          tipoNotaMotivo
        );
    } catch (error) {
      TratarErros(error);
    } finally {
      setLoading(false);
    }
  }, [formRef, setLoading]);

  const handleCarregarConfiguracaoTributaria = useCallback(async () => {
    const idPessoaRemetente =
      formRef.current?.getFieldValue('idPessoaRemetente');
    const idPessoaDestinatario = formRef.current?.getFieldValue(
      'idPessoaDestinatario'
    );
    const dataPesquisaVigencia =
      formRef.current?.getFieldValue('dataHoraEmissao');

    const idSerie = formRef.current?.getFieldValue('idSerie');
    const idTipoNota = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.idTipoNota'
    );
    const idTipoNotaMotivo = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.idTipoNotaMotivo'
    );

    if (
      !idPessoaRemetente ||
      !idPessoaDestinatario ||
      !dataPesquisaVigencia ||
      !idSerie ||
      !idTipoNota ||
      !idTipoNotaMotivo ||
      !serie ||
      !serie.modeloDocumento.id
    ) {
      return;
    }

    try {
      setLoading(true);

      const response = await ConfiguracaoTributariaComunicador.show({
        params: {
          dataPesquisaVigencia,
          idTipoNota,
          idTipoNotaMotivo,
          idPessoaDestinatario,
          idSerie,
          idPessoaRemetente,
          idModeloDocumento: serie.modeloDocumento.id,
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
        },
      });

      if (!response) {
        setLoading(false);
        return;
      }

      const responseAliqutoInterestadual =
        await RegraEscolhaAliquotaObterAliquotaInterestadualComunicador.show({
          params: {
            idPessoaOrigem: idPessoaRemetente,
            idPessoaDestino: idPessoaDestinatario,
            dataVigencia: dataPesquisaVigencia,
          },
        });

      if (responseAliqutoInterestadual?.aliquota)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.aliquotaIcmsOpInterestadual',
          responseAliqutoInterestadual?.aliquota
        );

      const {
        regraEscolhaOperacaoFiscal,
        regraEscolhaCst,
        regraEscolhaAliquota,
      } = response;

      if (regraEscolhaOperacaoFiscal?.operacaoFiscal)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.operacaoFiscal',
          regraEscolhaOperacaoFiscal?.operacaoFiscal
        );

      if (regraEscolhaOperacaoFiscal?.naturezaOperacao)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.naturezaOperacao',
          regraEscolhaOperacaoFiscal?.naturezaOperacao
        );

      if (regraEscolhaCst?.codigoSituacaoTributariaIcms)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.situacaoTributaria',
          regraEscolhaCst?.codigoSituacaoTributariaIcms
        );

      if (regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculo',
          regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms
        );

      if (regraEscolhaAliquota?.aliquotaIcms)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.aliquota',
          regraEscolhaAliquota?.aliquotaIcms
        );

      if (regraEscolhaCst?.calcularIcms)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.calcularIcms',
          regraEscolhaCst?.calcularIcms
        );

      if (regraEscolhaCst?.utilizaIcmsOperacaoInterestadual)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.utilizaIcmsOpInterestadual',
          regraEscolhaCst?.utilizaIcmsOperacaoInterestadual
        );

      if (regraEscolhaCst?.calcularIcmsOperacaoInterestadual)
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.calcularIcmsOpInterestadual',
          regraEscolhaCst?.calcularIcmsOperacaoInterestadual
        );
    } catch (error) {
      TratarErros(error);
    } finally {
      setLoading(false);
    }
  }, [formRef, serie, setLoading]);

  const handleChangeTipoTomador = useCallback((value?: string | null) => {
    if (!value) return;

    const tipoTomadorValue = StringConverterParaEnum<
      typeof TipoTomadorEnum,
      TipoTomadorEnum
    >(TipoTomadorEnum, value);

    setTipoTomador(tipoTomadorValue);
  }, []);

  const handleChangeTipoServico = useCallback(
    (value: string) => {
      const tipoServicoValue = StringConverterParaEnum<
        typeof TipoServicoEnum,
        TipoServicoEnum
      >(TipoServicoEnum, value);

      if (
        tipoServicoValue !== TipoServicoEnum.subcontratacao &&
        tipoServicoValue !== TipoServicoEnum.redespacho &&
        tipoServicoValue !== TipoServicoEnum.redespachoIntermediario
      ) {
        if (tabSelecionada === 'documentosAnteriores')
          setTabSelecionada('principal');
      }

      setTipoServico(tipoServicoValue);
    },
    [tabSelecionada]
  );

  const handleChangeTipoCte = useCallback(
    (value: string) => {
      const tipoCteValue = StringConverterParaEnum<
        typeof TipoCteEnum,
        TipoCteEnum
      >(TipoCteEnum, value);

      if (tipoCteValue !== TipoCteEnum.complementoValores) {
        if (tabSelecionada === 'complemento') setTabSelecionada('principal');
      }

      if (tipoCteValue !== TipoCteEnum.anulacao) {
        formRef.current?.setFieldError('anulacaoChaveAcesso', '');
        formRef.current?.setFieldError('anulacaoDataEmissao', '');

        if (tabSelecionada === 'anulacao') setTabSelecionada('principal');
      }

      if (tipoCteValue !== TipoCteEnum.substituto) {
        formRef.current?.setFieldError('substitutoChaveAcessoCteOriginal', '');
        formRef.current?.setFieldError('substitutoChaveAcessoCteAnulacao', '');
        formRef.current?.setFieldError('substitutoChaveAcessoNfe', '');
        formRef.current?.setFieldError(
          'substitutoChaveAcessoCteEmitTomador',
          ''
        );

        if (tabSelecionada === 'substituto') setTabSelecionada('principal');
      }

      setTipoCte(tipoCteValue);
    },
    [formRef, tabSelecionada]
  );

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, refresh, setIdDetalheRegistro]);

  const currentTipoTomador = formRef.current?.getFieldValue('tipoTomador');

  useEffect(() => {
    handleChangeTipoTomador(currentTipoTomador);
  }, [currentTipoTomador, handleChangeTipoTomador]);

  const currentTipoCte = formRef.current?.getFieldValue('tipoCte');
  useEffect(() => {
    handleChangeTipoCte(currentTipoCte);
  }, [currentTipoCte, handleChangeTipoCte]);

  const currentTipoServico = formRef.current?.getFieldValue('tipoServico');
  useEffect(() => {
    handleChangeTipoServico(currentTipoServico);
  }, [currentTipoServico, handleChangeTipoServico]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosDuplicados, dadosObrigatorios, dadosPadrao, dadosRecuperados]);

  const handleOnAlterarDataHoraEmissao = useCallback(async () => {
    try {
      setLoading(true);

      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');

      await setRegimeTributarioPorData(new Date(dataHoraEmissao));
      await handleCarregarTipoNotaMotivo();
      await handleCarregarConfiguracaoTributaria();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleCarregarConfiguracaoTributaria,
    handleCarregarTipoNotaMotivo,
    setLoading,
    setRegimeTributarioPorData,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <InputHiddenHtml name="financeiroConferido" />
        <InputHiddenHtml name="valorFinanceiro" />

        <Divisor>
          <Row>
            <Col xl={3} md={6} sm={12}>
              <InputAutoCompleteSerie
                ref={inputRefFocus}
                label="Série"
                name="idSerie"
                placeholder="Série"
                nomeObjeto="serie"
                codigoModeloDocumento="57"
                onChangeItemAtual={(event) => {
                  refresh();
                  handleAtualizarNumero(event);
                }}
                onChangeItemAtualAposCarregarSemClear={async ({
                  itemAtual,
                }) => {
                  refresh();
                  if (!itemAtual) return;
                  await handleCarregarConfiguracaoTributaria();
                }}
              />
            </Col>
            <Col xl={2} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled={serie ? serie.incrementarAutomaticamente : true}
              />
            </Col>
            <Col xl={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Emissão"
                name="dataHoraEmissao"
                onChangeData={handleOnAlterarDataHoraEmissao}
              />
            </Col>
            <Col xl={3} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                formatarValor={(valor) => {
                  return (
                    <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                      <span
                        style={{
                          color:
                            valor ===
                            SituacaoConhecimentoTransporteEnum.autorizado
                              ? 'green'
                              : valor ===
                                    SituacaoConhecimentoTransporteEnum.denegado ||
                                  valor ===
                                    SituacaoConhecimentoTransporteEnum.emProcessamento
                                ? 'blue'
                                : valor ===
                                    SituacaoConhecimentoTransporteEnum.cancelado
                                  ? 'red'
                                  : valor ===
                                      SituacaoConhecimentoTransporteEnum.rejeitado
                                    ? 'goldenrod'
                                    : 'black',
                        }}
                      >
                        {valor}
                      </span>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3}>
              <Select
                name="tipoCte"
                label="Tipo"
                options={FormatarRemovendoEnum({
                  enumObj: TipoCteEnum,
                  enumRemover:
                    !transmissaoVersaoLayout ||
                    transmissaoVersaoLayout === '4.00'
                      ? [TipoCteEnum.anulacao]
                      : [],
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoCte(event.target.value);
                }}
              />
            </Col>

            <Col xl={3}>
              <Select
                name="tipoServico"
                label="Tipo de Serviço"
                options={FormatarEnum({
                  enumObj: TipoServicoEnum,
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoServico(event.target.value);
                }}
              />
            </Col>

            <Col xl={3}>
              <Select
                name="tipoTomador"
                label="Tomador"
                options={FormatarEnum({
                  enumObj: TipoTomadorEnum,
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoTomador(event.target.value);
                }}
              />
            </Col>

            <Col xl={2}>
              <InputInteiroNulavel
                name="rntrc"
                label="RNTRC"
                placeholder="RNTRC"
                castParaNumberOnGetValue={false}
                maxLength={8}
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <Tabs
        id={v4()}
        defaultActiveKey="principal"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="principal" title="Principal">
          <PrincipalTab
            tipoTomador={tipoTomador}
            handleCarregarConfiguracaoTributaria={
              handleCarregarConfiguracaoTributaria
            }
            handleCarregarTipoNotaMotivo={handleCarregarTipoNotaMotivo}
          />
        </Tab>

        <Tab eventKey="carga" title="Carga">
          <CargaTab />
        </Tab>

        <Tab eventKey="servico" title="Serviços">
          <ServicoTab />
        </Tab>

        <Tab eventKey="imposto" title="Imposto">
          <ImpostoTab
            handleCarregarConfiguracaoTributaria={
              handleCarregarConfiguracaoTributaria
            }
          />
        </Tab>

        {typeof showNotaReferenciadaTab !== 'boolean' ||
        (typeof showNotaReferenciadaTab === 'boolean' &&
          showNotaReferenciadaTab) ? (
          <Tab eventKey="notaReferenciada" title="Notas Referenciadas">
            <NotaReferenciadaTab />
          </Tab>
        ) : null}

        {typeof showNotaReferenciadaTab !== 'boolean' ||
        (typeof showNotaReferenciadaTab === 'boolean' &&
          !showNotaReferenciadaTab) ? (
          <Tab eventKey="documentoReferenciado" title="Outros Documentos">
            <DocumentoReferenciadoTab />
          </Tab>
        ) : null}

        <Tab
          eventKey="documentosAnteriores"
          title="Doc. Anteriores"
          tabClassName={
            tipoServico === TipoServicoEnum.subcontratacao ||
            tipoServico === TipoServicoEnum.redespacho ||
            tipoServico === TipoServicoEnum.redespachoIntermediario
              ? ''
              : 'hidden'
          }
        >
          <DocumentoAnteriorTab />
        </Tab>

        <Tab
          eventKey="complemento"
          title="Complemento"
          tabClassName={
            tipoCte === TipoCteEnum.complementoValores ? '' : 'hidden'
          }
        >
          <CteComplementadoTab />
        </Tab>

        <Tab
          eventKey="anulacao"
          title="Anulação"
          tabClassName={tipoCte === TipoCteEnum.anulacao ? '' : 'hidden'}
        >
          <AnulacaoTab />
        </Tab>

        <Tab
          eventKey="substituto"
          title="Substituto"
          tabClassName={tipoCte === TipoCteEnum.substituto ? '' : 'hidden'}
        >
          <SubstitutoTab />
        </Tab>

        <Tab eventKey="informacoesAdicionais" title="Informações Adicionais">
          <InformacaoAdicionalTab />
        </Tab>

        <Tab eventKey="participantes" title="Participantes">
          <ParticipantesTab />
        </Tab>

        <Tab eventKey="autorizacao" title="Autorização">
          <AutorizacaoTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui) ||
            situacaoBloquearCampos
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
