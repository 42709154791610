import React from 'react';
import { ComunicadorProvider } from '../../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import TransferenciaLocalEstoqueComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/TransferenciaLocalEstoque/Comunicador/TransferenciaLocalEstoqueComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const TransferenciaLocalEstoqueHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: TransferenciaLocalEstoqueComunicador }}
    >
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default TransferenciaLocalEstoqueHook;
