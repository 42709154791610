"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloImpressaoPedidoCompraEnum;
(function (ModeloImpressaoPedidoCompraEnum) {
    ModeloImpressaoPedidoCompraEnum["retratoSemFotoProduto"] = "Retrato (sem foto produto)";
    ModeloImpressaoPedidoCompraEnum["retratoComFotoProduto"] = "Retrato (com foto produto)";
    ModeloImpressaoPedidoCompraEnum["paisagemSemFotoProduto"] = "Paisagem (sem foto produto)";
    ModeloImpressaoPedidoCompraEnum["paisagemComFotoProduto"] = "Paisagem (com foto produto)";
    ModeloImpressaoPedidoCompraEnum["paisagemPrevisaoRecebimento"] = "Paisagem (com transporte e previs\u00E3o de recebimento)";
})(ModeloImpressaoPedidoCompraEnum || (ModeloImpressaoPedidoCompraEnum = {}));
exports.default = ModeloImpressaoPedidoCompraEnum;
