import React from 'react';
import { RotasProvider } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '/fiscal/sped/configuracao',
        detalhes: '/fiscal/sped/configuracao/detalhe',
        listagem: '/fiscal/sped/configuracao',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
