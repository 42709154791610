/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  ModeloImpressaoCotacaoCompraEnum,
  SituacaoCotacaoCompraEnum,
  SituacaoCotacaoCompraPedidoCompraEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend, BiXCircle } from 'react-icons/bi/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoCotacaoCompraRetratoSemFotoProduto from './Impressao/Template/CotacaoCompraRetratoSemFotoProdutoImpressao';
import ImpressaoCotacaoCompraPaisagemSemFotoProduto from './Impressao/Template/CotacaoCompraPaisagemSemFotoProdutoImpressao';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseCotacaoCompraAtualizarSituacao } from '../Hook/AtualizarSitucaoHook';
import CotacaoCompraRetratoSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Impressao/CotacaoCompraRetratoSemFotoProdutoImpressao/CotacaoCompraRetratoSemFotoProdutoImpressaoComunicador';
import CotacaoCompraPaisagemSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Impressao/CotacaoCompraPaisagemSemFotoProdutoImpressao/CotacaoCompraPaisagemSemFotoProdutoImpressaoComunicador';
import CotacaoCompraAprovarComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Comunicador/CotacaoCompraAprovarComunicador';
import CotacaoCompraRejeitarComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Comunicador/CotacaoCompraRejeitarComunicador';
import CotacaoCompraCancelarComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Comunicador/CotacaoCompraCancelarComunicador';
import { UsePedidoCompraImportacaoCotacaoCompra } from '../../../PedidoCompraImportacaoCotacaoCompraHook';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import CotacaoCompraRetratoComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Impressao/CotacaoCompraRetratoComFotoProdutoImpressao/CotacaoCompraRetratoComFotoProdutoImpressaoComunicador';
import CotacaoCompraPaisagemComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Impressao/CotacaoCompraPaisagemComFotoProdutoImpressao/CotacaoCompraPaisagemComFotoProdutoImpressaoComunicador';
import ImpressaoCotacaoCompraRetratoComFotoProduto from './Impressao/Template/CotacaoCompraRetratoComFotoProdutoImpressao';
import ImpressaoCotacaoCompraPaisagemComFotoProduto from './Impressao/Template/CotacaoCompraPaisagemComFotoProdutoImpressao';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';

interface IAcoesCotacaoCompra {
  permissao?: IPermissao;
}

const AcoesCotacaoCompra: React.FC<IAcoesCotacaoCompra> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();

  const { abrirCotacaoCompraAtualizarSituacao, setTitulo } =
    UseCotacaoCompraAtualizarSituacao();

  const {
    setTitulo: setTituloPedidoCompraImportacaoCotacaoCompra,
    abrirPedidoCompraImportacaoCotacaoCompra,
  } = UsePedidoCompraImportacaoCotacaoCompra();

  const idRegistroDetalhe = getIdDetalheRegistro();
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const listaCotacaoCompraItem = formRef.current?.getFieldValue(
    'listaCotacaoCompraItem'
  );

  const situacaoCotacaoCompra = formRef.current?.getFieldValue('situacao');
  const situacaoCotacaoCompraPedidoCompra = formRef.current?.getFieldValue(
    'situacaoCotacaoCompraPedidoCompra'
  );
  const idPessoa = formRef.current?.getFieldValue(
    'cotacaoCompraPessoa.idPessoa'
  );
  const numero = formRef.current?.getFieldValue('numero');

  const handleValidar = useCallback(
    async (
      situacaoCotacaoCompraValidar?: SituacaoCotacaoCompraEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoCotacaoCompraValidar &&
          situacaoCotacaoCompraValidar !== SituacaoCotacaoCompraEnum.pendente
        )
          return true;

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef]
  );

  const handleSalvar = useCallback(
    async (
      situacaoCotacaoCompraValidar?: SituacaoCotacaoCompraEnum
    ): Promise<boolean> => {
      try {
        if (!handleValidar(situacaoCotacaoCompraValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const idRegistro = getIdDetalheRegistro() || '';

      if (listaCotacaoCompraItem && listaCotacaoCompraItem.length === 0) {
        ToastInfo('Informe pelo menos um item para realizar a impressão!');
      } else {
        switch (parametros.ModeloParaImpressaoCotacaoCompra) {
          case ModeloImpressaoCotacaoCompraEnum.retratoSemFotoProduto:
            {
              const response =
                await CotacaoCompraRetratoSemFotoProdutoImpressaoComunicador.show(
                  { id: idRegistro }
                );

              if (visualizarPdf) {
                await ImpressaoCotacaoCompraRetratoSemFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoCotacaoCompraRetratoSemFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoCotacaoCompraEnum.paisagemSemFotoProduto: {
            const response =
              await CotacaoCompraPaisagemSemFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoCotacaoCompraPaisagemSemFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoCotacaoCompraPaisagemSemFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
          case ModeloImpressaoCotacaoCompraEnum.retratoComFotoProduto:
            {
              const response =
                await CotacaoCompraRetratoComFotoProdutoImpressaoComunicador.show(
                  { id: idRegistro }
                );

              if (visualizarPdf) {
                await ImpressaoCotacaoCompraRetratoComFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoCotacaoCompraRetratoComFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoCotacaoCompraEnum.paisagemComFotoProduto: {
            const response =
              await CotacaoCompraPaisagemComFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoCotacaoCompraPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoCotacaoCompraPaisagemComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
        }
      }
      return undefined;
    },
    [
      getIdDetalheRegistro,
      listaCotacaoCompraItem,
      parametros.ModeloParaImpressaoCotacaoCompra,
    ]
  );

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoCotacaoCompra))) {
        return;
      }

      setLoading(true);

      await handleVisualizarOuObterBlob(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    handleVisualizarOuObterBlob,
    setLoading,
    situacaoCotacaoCompra,
  ]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: true,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: true,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      const anexo = await handleVisualizarOuObterBlob(false);

      await formRefEmail.current?.setDataInicial({
        assunto: `Cotação de Compra número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo: [
          {
            nomeArquivo: `Cotacao Compra ${numero}.pdf`,
            file: anexo,
            permitirExcluir: false,
          },
        ],
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numero]
  );

  // #endregion Email

  const handleAprovar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTitulo('Selecione os Itens a serem Aprovados');
      const atualizarSituacao =
        await abrirCotacaoCompraAtualizarSituacao(idRegistro);

      if (!atualizarSituacao) {
        setLoading(false);
        return;
      }

      const listaIdItem = atualizarSituacao.listaValorSelecionado.map(
        (item) => {
          return item.id;
        }
      );

      let rejeitarNaoSelecionador = false;

      if (listaIdItem.length !== atualizarSituacao.listaValor.length) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          confimar: 'Sim',
          cancelar: 'Não',
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja rejeitar os itens não aprovados?
            </span>
          ),
        });

        rejeitarNaoSelecionador = resposta;
      }

      const response = await CotacaoCompraAprovarComunicador.update({
        id: idRegistro,
        params: { listaIdItem, rejeitarNaoSelecionador },
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    setLoading,
    handleSalvar,
    getIdDetalheRegistro,
    setTitulo,
    abrirCotacaoCompraAtualizarSituacao,
    handleCarregarDados,
    abrirJanela,
  ]);

  const handleRejeitar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTitulo('Selecione os Itens a serem Rejeitados');
      const atualizarSituacao =
        await abrirCotacaoCompraAtualizarSituacao(idRegistro);

      if (!atualizarSituacao) {
        setLoading(false);
        return;
      }

      const listaIdItem = atualizarSituacao.listaValorSelecionado.map(
        (item) => {
          return item.id;
        }
      );

      let aprovarNaoSelecionador = false;

      if (listaIdItem.length !== atualizarSituacao.listaValor.length) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          confimar: 'Sim',
          cancelar: 'Não',
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja aprovar os itens não rejeitados?
            </span>
          ),
        });

        aprovarNaoSelecionador = resposta;
      }

      const response = await CotacaoCompraRejeitarComunicador.update({
        id: idRegistro,
        params: { listaIdItem, aprovarNaoSelecionador },
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    setLoading,
    handleSalvar,
    getIdDetalheRegistro,
    setTitulo,
    abrirCotacaoCompraAtualizarSituacao,
    handleCarregarDados,
    abrirJanela,
  ]);

  const handleGerarPedidoCompra = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloPedidoCompraImportacaoCotacaoCompra('Gerar Pedido de Compra');
      const response = await abrirPedidoCompraImportacaoCotacaoCompra({
        idPessoa: String(idPessoa),
        idCotacaoCompra: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/suprimentos/compras/pedido-compra/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    setLoading,
    handleSalvar,
    getIdDetalheRegistro,
    setTituloPedidoCompraImportacaoCotacaoCompra,
    abrirPedidoCompraImportacaoCotacaoCompra,
    idPessoa,
    navigate,
  ]);

  const handleCancelar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente cancelar essa Cotação de Compra?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await CotacaoCompraCancelarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.aprovado
          }
          onClick={handleAprovar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Aprovar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.rejeitado
          }
          onClick={handleRejeitar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiXCircle style={{ marginRight: 5 }} />
            Rejeitar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.pendente ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.rejeitado ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.cancelado ||
            situacaoCotacaoCompraPedidoCompra ===
              SituacaoCotacaoCompraPedidoCompraEnum.atendido
          }
          onClick={handleGerarPedidoCompra}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Pedido de Compra
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoCotacaoCompra === SituacaoCotacaoCompraEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Cotação de Compra por E-mail"
          mensagemPadrao={`Segue em anexo a Cotação de Compra número: ${numero}`}
          direcionarCotacaoCompra
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}
    </>
  );
};

export default AcoesCotacaoCompra;
