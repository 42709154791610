/* eslint-disable jsx-a11y/label-has-associated-control */
import { ValidarEmail } from '@elogestor/util';
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputEmailProps {
  valorAnterior: string;
}

export interface IInputEmailRef {
  focus(): void;
}

interface IInputEmailProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputEmailProps
  ) => void | Promise<void>;
}

const InputEmail: React.ForwardRefRenderFunction<
  IInputEmailRef,
  IInputEmailProps
> = ({ name, label, onFocus, onChange, onBlur, ...rest }, ref) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      if (!ValidarEmail(inputRef.current?.value || '')) {
        setErro('E-mail inválido!');
      }

      setIsFocused(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.value || '';
      },

      setValue(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      setSemExecutarEvento(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="email"
        onFocus={handleInputFocus}
        onChange={(event) => {
          setErro('');
          if (onChange)
            onChange(event, { valorAnterior: valorAnterior.current });
        }}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputEmail);
