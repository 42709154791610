"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoPedidoCompraNotaFiscalEntradaEnum;
(function (SituacaoPedidoCompraNotaFiscalEntradaEnum) {
    SituacaoPedidoCompraNotaFiscalEntradaEnum["atendido"] = "Atendido";
    SituacaoPedidoCompraNotaFiscalEntradaEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoPedidoCompraNotaFiscalEntradaEnum["naoAtendido"] = "N\u00E3o Atendido";
})(SituacaoPedidoCompraNotaFiscalEntradaEnum || (SituacaoPedidoCompraNotaFiscalEntradaEnum = {}));
exports.default = SituacaoPedidoCompraNotaFiscalEntradaEnum;
