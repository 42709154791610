import {
  IcmsCteCalculoPorCst,
  IcmsCteObterVisibilidadeBloqueioCamposPorCst,
} from '@elogestor/util';
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { UseRegimeTributario } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';

interface ICampoState {
  calcularIcms: boolean;
  percentualReducaoBaseCalculoIcms: boolean;
  baseCalculoIcms: boolean;
  aliquotaIcms: boolean;
  valorIcms: boolean;
}

interface IImpostoIcmsHookProps {
  handleCalcularIcms: () => Promise<void>;
  obterVisivbilidadeBloqueioCamposIcms: () => Promise<void>;
  camposBloqueadosIcms: ICampoState;
  camposEscondidosIcms: ICampoState;
}

const camposEscondidosIcmsPadrao: ICampoState = {
  aliquotaIcms: false,
  baseCalculoIcms: false,
  calcularIcms: false,
  percentualReducaoBaseCalculoIcms: false,
  valorIcms: false,
};

const camposBloqueadosIcmsPadrao: ICampoState = {
  aliquotaIcms: false,
  baseCalculoIcms: false,
  calcularIcms: false,
  percentualReducaoBaseCalculoIcms: false,
  valorIcms: false,
};

const ImpostoIcmsContext = createContext({} as IImpostoIcmsHookProps);

const ImpostoIcmsHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();
  const { regimeTributario } = UseRegimeTributario();

  const [camposBloqueadosIcms, setCamposBloqueadosIcms] = useState(
    camposBloqueadosIcmsPadrao
  );
  const [camposEscondidosIcms, setCamposEscondidosIcms] = useState(
    camposEscondidosIcmsPadrao
  );

  const obterVisivbilidadeBloqueioCamposIcms =
    useCallback(async (): Promise<void> => {
      const { bloqueado, visivel } =
        IcmsCteObterVisibilidadeBloqueioCamposPorCst({
          calcularIcms: formRef.current?.getFieldValue(
            'conhecimentoTransporteImpostoIcms.calcularIcms'
          ),
          regimeTributario,
          cst:
            formRef.current?.getFieldValueNomeObjeto(
              'conhecimentoTransporteImpostoIcms.situacaoTributaria'
            )?.codigo || '',
        });

      setCamposBloqueadosIcms(bloqueado);
      setCamposEscondidosIcms(visivel);
    }, [formRef, regimeTributario]);

  const handleCalcularIcms = useCallback(async () => {
    const calcularIcms = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.calcularIcms'
    );

    const cst =
      formRef.current?.getFieldValueNomeObjeto(
        'conhecimentoTransporteImpostoIcms.situacaoTributaria'
      )?.codigo ?? '';

    const percentualReducaoBaseCalculo = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculo'
    );

    const baseCalculo = calcularIcms
      ? formRef.current?.getFieldValue('valorTotalServico')
      : formRef.current?.getFieldValue(
          'conhecimentoTransporteImpostoIcms.baseCalculo'
        );

    const valorTotal = formRef.current?.getFieldValue('valorTotalServico');

    const aliquota = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.aliquota'
    );

    const valor = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.valor'
    );

    const retorno = IcmsCteCalculoPorCst({
      cst,
      aliquota: Number(aliquota),
      baseCalculo: Number(baseCalculo),
      calcular: calcularIcms,
      percentualReducaoBaseCalculo: Number(percentualReducaoBaseCalculo),
      valor: Number(valor),
      valorTotal: Number(valorTotal),
    });

    await formRef.current?.setSemExecutarEvento({
      conhecimentoTransporteImpostoIcms: {
        valor: retorno.valor,
        baseCalculo: retorno.baseCalculo,
      },
    });

    obterVisivbilidadeBloqueioCamposIcms();
  }, [formRef, obterVisivbilidadeBloqueioCamposIcms]);

  const calcularIcms = formRef.current?.getFieldValue(
    'conhecimentoTransporteImpostoIcms.calcularIcms'
  );

  const cst =
    formRef.current?.getFieldValueNomeObjeto(
      'conhecimentoTransporteImpostoIcms.situacaoTributaria'
    )?.codigo || '';

  useEffect(() => {
    (async () => {
      await obterVisivbilidadeBloqueioCamposIcms();
    })();
  }, [obterVisivbilidadeBloqueioCamposIcms, cst, calcularIcms]);

  return (
    <ImpostoIcmsContext.Provider
      value={{
        obterVisivbilidadeBloqueioCamposIcms,
        camposBloqueadosIcms,
        camposEscondidosIcms,
        handleCalcularIcms,
      }}
    >
      {children}
    </ImpostoIcmsContext.Provider>
  );
};

function UseImpostoIcms(): IImpostoIcmsHookProps {
  const context = useContext(ImpostoIcmsContext);
  return context;
}

export { ImpostoIcmsHook, UseImpostoIcms };
