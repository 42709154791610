"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoManifestacaoEnum;
(function (SituacaoManifestacaoEnum) {
    SituacaoManifestacaoEnum["naoManifestado"] = "N\u00E3o Manifestado";
    SituacaoManifestacaoEnum["cienciaDaOperacao"] = "Ci\u00EAncia da Opera\u00E7\u00E3o";
    SituacaoManifestacaoEnum["operacaoConfirmada"] = "Opera\u00E7\u00E3o Confirmada";
    SituacaoManifestacaoEnum["operacaoDesconhecida"] = "Opera\u00E7\u00E3o Desconhecida";
    SituacaoManifestacaoEnum["operacaoNaoRealizada"] = "Opera\u00E7\u00E3o n\u00E3o Realizada";
    SituacaoManifestacaoEnum["servicoEmDesacordo"] = "Servi\u00E7o em Desacordo";
})(SituacaoManifestacaoEnum || (SituacaoManifestacaoEnum = {}));
exports.default = SituacaoManifestacaoEnum;
