import React from 'react';
import { ImportacaoXmlEmitidosHook } from './Hook/ImportacaoXmlEmitidosHook';
import ImportacaoXmlEmitidosMain from './Lista';

const ImportacaoXmlEmitidosLista: React.FC = () => {
  return (
    <ImportacaoXmlEmitidosHook>
      <ImportacaoXmlEmitidosMain />
    </ImportacaoXmlEmitidosHook>
  );
};

export default ImportacaoXmlEmitidosLista;
