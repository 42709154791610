import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  FormaBuscaReciboEnum,
  FormatarDataParaDataExtensa,
  FormatarDataParaPtBr,
  FormatarEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio } from './styles';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import Input from '../../../../Componentes/Inputs/Input';
import InputAutoCompleteCidade from '../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputDecimalNulavel from '../../../../Componentes/Inputs/InputDecimalNulavel';
import InputVisualSemValor from '../../../../Componentes/Inputs/InputVisualSemValor';
import ImpressaoReciboPDF from './Template/ImpressaoRecibo';
import { IOnChangeValueInputDecimalEvent } from '../../../../Componentes/Inputs/InputDecimal';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import InputInteiro from '../../../../Componentes/Inputs/InputInteiro';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import EmpresaLogotipoComunicador from '../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaLogotipoComunicador';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import InputDate from '../../../../Componentes/Inputs/InputDate';
import Select from '../../../../Componentes/Select';
import InputAutoCompletePessoa from '../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputAutoCompleteEmpresa from '../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteEmpresa';
import { UseAuth } from '../../../../Hooks/Auth';
import ImpressaoReciboComunicador from '../../../../Comunicador/Financeiro/Geral/ImpressaoRecibo/Comunicador/ImpressaoReciboComunicador';

interface IImpressaoReciboDados {
  atravesDe: string;
  correspondente: string;
  dataRecibo: Date;
  pagador: string;
  recebedor: string;
  valor: number;
  valorExtenso: string;

  cidade: {
    nome: string;
  };

  numeroVias: number;
  formaBuscaRecebedor: FormaBuscaReciboEnum;
  formaBuscaRecebiDe: FormaBuscaReciboEnum;
}

const ImpressaoRecibo: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const formRef = useRef<IFormCiaHandles>(null);
  const [loading, setLoading] = useState(false);
  const { empresa } = UseAuth();

  const [campoRecebiDe, setCampoRecebiDe] = useState(
    <InputAutoCompletePessoa
      label="Recebi de"
      name="pagador"
      permitirAdicionar={false}
    />
  );
  const [campoRecebedor, setCampoRecebedor] = useState(
    <InputAutoCompleteEmpresa
      label="Recebedor"
      name="recebedor"
      onChangeItemAtual={() => {
        handleChangeOpcaoRecebedorSelecionado();
      }}
    />
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        dataRecibo: Yup.string().nullable().required('Data é obrigatória!'),
        pagador: Yup.mixed()
          .nullable()
          .test({
            message: 'Pagador é obrigatório!',
            test: (value: any) => {
              if (!value) {
                return false;
              }
              return true;
            },
          }),
        recebedor: Yup.mixed()
          .nullable()
          .test({
            message: 'Recebedor é obrigatório!',
            test: (value: any) => {
              if (!value) {
                return false;
              }
              return true;
            },
          }),
        valor: Yup.number().nullable().required('Valor é obrigatório!'),

        numeroVias: Yup.number()
          .nullable()
          .min(1, 'É necessário imprimir pelo menos uma via!')
          .max(12, 'Máximo de 12 vias por PDF permitido!')
          .required('Número de Vias é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleExportar = useCallback(async () => {
    setLoading(true);

    const dadosForm =
      formRef.current?.getDataPesquisa() as IImpressaoReciboDados;

    if (await handleValidar(dadosForm)) {
      const response = await EmpresaLogotipoComunicador.show();

      const dadosFormatados = {
        pagador:
          dadosForm.formaBuscaRecebiDe !== FormaBuscaReciboEnum.manual
            ? (dadosForm.pagador as any).nomeRazaoSocial
            : dadosForm.pagador,
        valor: dadosForm.valor.FormatarParaPtBr(),
        valorExtenso: dadosForm.valor.ObterValorExtenso(),
        correspondente: dadosForm.correspondente,
        atravesDe: dadosForm.atravesDe,
        cidade: dadosForm.cidade?.nome,
        dataRecibo: FormatarDataParaPtBr(dadosForm.dataRecibo),
        dataExtensa: FormatarDataParaDataExtensa(dadosForm.dataRecibo),
        recebedor:
          dadosForm.formaBuscaRecebedor !== FormaBuscaReciboEnum.manual
            ? (dadosForm.recebedor as any).nomeRazaoSocial
            : dadosForm.recebedor,
        numeroVias: dadosForm.numeroVias,
        logotipoUrl: response,
      };

      await ImpressaoReciboPDF.handleVisualizarPdf(dadosFormatados);
    }

    setLoading(false);
  }, [handleValidar]);

  const handleChangeValorExtenso = useCallback(
    (event: IOnChangeValueInputDecimalEvent) => {
      formRef.current?.setFieldValue(
        'valorExtenso',
        String(event.valor).ObterValorExtenso()
      );
    },
    [formRef]
  );

  const handleChangeOpcaoFormaBuscaRecebiDeSelecionada = useCallback(() => {
    const opcaoFormaBuscaRecebiDe =
      formRef.current?.getFieldValue('formaBuscaRecebiDe');

    if (opcaoFormaBuscaRecebiDe === FormaBuscaReciboEnum.manual) {
      setCampoRecebiDe(<Input label="Recebi de" name="pagador" />);
    } else if (opcaoFormaBuscaRecebiDe === FormaBuscaReciboEnum.empresa) {
      setCampoRecebiDe(
        <InputAutoCompleteEmpresa label="Recebi de" name="pagador" />
      );
    } else {
      setCampoRecebiDe(
        <InputAutoCompletePessoa
          label="Recebi de"
          name="pagador"
          permitirAdicionar={false}
        />
      );
    }
  }, []);

  const handleChangeOpcaoRecebedorSelecionado = useCallback(async () => {
    const valorCampoRecebedor = formRef.current?.getFieldValue('recebedor');

    const valorCampoFormaBusca = formRef.current?.getFieldValue(
      'formaBuscaRecebedor'
    );

    formRef.current.setFieldValue('cidade', '');
    if (valorCampoRecebedor && valorCampoRecebedor !== '') {
      const response = await ImpressaoReciboComunicador.show({
        params: {
          idRecebedor: valorCampoRecebedor,
          formaBusca: valorCampoFormaBusca,
        },
      });
      formRef.current.setFieldValue('cidade', response);
    }
  }, []);

  const handleChangeOpcaoFormaBuscaRecebedorSelecionada = useCallback(() => {
    const opcaoFormaBuscaRecebedor = formRef.current?.getFieldValue(
      'formaBuscaRecebedor'
    );

    formRef.current.setFieldValue('cidade', '');
    if (opcaoFormaBuscaRecebedor === FormaBuscaReciboEnum.manual) {
      setCampoRecebedor(<Input label="Recebedor" name="recebedor" />);
    } else if (opcaoFormaBuscaRecebedor === FormaBuscaReciboEnum.empresa) {
      setCampoRecebedor(
        <InputAutoCompleteEmpresa
          label="Recebedor"
          name="recebedor"
          onChangeItemAtual={() => {
            handleChangeOpcaoRecebedorSelecionado();
          }}
        />
      );
    } else {
      setCampoRecebedor(
        <InputAutoCompletePessoa
          label="Recebedor"
          name="recebedor"
          permitirAdicionar={false}
          onChangeItemAtual={() => {
            handleChangeOpcaoRecebedorSelecionado();
          }}
        />
      );
    }
  }, [handleChangeOpcaoRecebedorSelecionado]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      dataRecibo: Date.now(),
      numeroVias: 1,
      formaBuscaRecebiDe: FormaBuscaReciboEnum.cadClienteFornTransp,
      formaBuscaRecebedor: FormaBuscaReciboEnum.empresa,
      recebedor: {
        id: empresa.id,
        nomeRazaoSocial: empresa.nomeRazaoSocial,
      },
    });
  }, [empresa.id, empresa.nomeRazaoSocial]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Impressão de Recibo
          </h1>

          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }} disabled={loading}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar disabled={loading}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportar}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={2} lg={12}>
                <Select
                  label="Forma Busca Recebi de"
                  name="formaBuscaRecebiDe"
                  options={FormatarEnum({
                    enumObj: FormaBuscaReciboEnum,
                    nullavel: false,
                  })}
                  onChange={() => {
                    handleChangeOpcaoFormaBuscaRecebiDeSelecionada();
                  }}
                />
              </Col>

              <Col xl={7} lg={12}>
                {campoRecebiDe}
              </Col>
            </Row>

            <Row>
              <Col xl={3} lg={12}>
                <InputDecimalNulavel
                  label="Valor"
                  name="valor"
                  casasInteiras={10}
                  casasDecimais={2}
                  maxLength={17}
                  onChangeValue={handleChangeValorExtenso}
                />
              </Col>
              <Col xl={6} lg={12}>
                <InputVisualSemValor
                  label="A quantia de"
                  name="valorExtenso"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xl={9} lg={12}>
                <Input label="Através de" name="atravesDe" />
              </Col>
            </Row>

            <Row>
              <Col xl={9} lg={12}>
                <Input label="Correspondente à" name="correspondente" />
              </Col>
            </Row>

            <Row>
              <Col xl={2} lg={12}>
                <Select
                  label="Forma Busca Recebedor"
                  name="formaBuscaRecebedor"
                  options={FormatarEnum({
                    enumObj: FormaBuscaReciboEnum,
                    nullavel: false,
                  })}
                  onChange={() => {
                    handleChangeOpcaoFormaBuscaRecebedorSelecionada();
                  }}
                />
              </Col>
              <Col xl={7} lg={9}>
                {campoRecebedor}
              </Col>
            </Row>

            <Row>
              <Col xl={5} lg={6}>
                <InputAutoCompleteCidade label="Cidade" name="cidade" />
              </Col>
              <Col xl={3} lg={6}>
                <InputDate label="Data" name="dataRecibo" />
              </Col>
              <Col xl={1} lg={3}>
                <InputInteiro
                  label="Num. Vias"
                  name="numeroVias"
                  min={1}
                  max={9}
                />
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            type="button"
            onClick={handleExportar}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default ImpressaoRecibo;
