import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/copiar-de';

interface IUsuarioCopiarDeComunicadorShow {
  params: any;
}

class UsuarioCopiarDeComunicador {
  public async show(params: IUsuarioCopiarDeComunicadorShow): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new UsuarioCopiarDeComunicador();
