/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  EstoqueIndicadorTipoEnum,
  FormatarRemovendoEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../../../../Componentes/Select';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import OrdemServicoItemEstoqueLocalEstoqueHook from './ListaDetalheItemEstoqueLocalEstoque/Hooks';
import ListaDetalheItemEstoqueLocalEstoque from './ListaDetalheItemEstoqueLocalEstoque/Lista';
import { UseItemEstoque } from '../../Hook/ItemEstoqueHook';
import { UseItemOrdemServico } from '../../Hook/ItemOrdemServicoHook';
import InputAutoCompletePessoa from '../../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IItemEstoqueTab {
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const ItemEstoqueTab: React.FC<IItemEstoqueTab> = ({ formRefItem }) => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const { selecionarItemEstoqueLocalEstoque } = UseItemEstoque();
  const { setListaLocalEstoque } = UseItemOrdemServico();
  const { formRef: formPrincipal } = UseForm();

  const movimentarEstoque = formRefDetalhe.current?.getFieldValue(
    'ordemServicoItemEstoque.movimentarEstoque'
  );
  const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
    'ordemServicoItemEstoque.porContaOrdemTerceiro'
  );
  const indicadorTipoEstoque = formRefItem.current?.getFieldValue(
    'ordemServicoItemEstoque.indicadorTipoEstoque'
  );

  const handleOnAlterarFatorConversao = useCallback(async (): Promise<void> => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const fatorConversao = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEstoque.fatorConversao'
    );
    const quantidadeEstoque = (
      Number(quantidade) * Number(fatorConversao)
    ).Arredondar(4);

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEstoque.quantidadeEstoque',
      quantidadeEstoque
    );
  }, [formRefDetalhe]);

  const handleOnAlterarQuantidadeEstoque =
    useCallback(async (): Promise<void> => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const quantidadeEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque'
      );
      const fatorConversao = (
        quantidade ? Number(quantidadeEstoque) / Number(quantidade) : 0
      ).Arredondar(10);

      formRefDetalhe.current?.setFieldValue(
        'ordemServicoItemEstoque.fatorConversao',
        fatorConversao
      );
    }, [formRefDetalhe]);

  const handleSelecionarItemEstoqueLocalEstoque = useCallback(
    async (limparLista?: boolean) => {
      selecionarItemEstoqueLocalEstoque(limparLista);
    },
    [selecionarItemEstoqueLocalEstoque]
  );

  const handleSetarPorContaOrdemTerceiro = useCallback(
    async (estoqueIndicadorTipoEstoque: string) => {
      switch (estoqueIndicadorTipoEstoque) {
        case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
          formRefDetalhe.current?.setFieldValue(
            'ordemServicoItemEstoque.porContaOrdemTerceiro',
            false
          );
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
          formRefDetalhe.current?.setFieldValue(
            'ordemServicoItemEstoque.porContaOrdemTerceiro',
            true
          );
          break;
      }
    },
    [formRefDetalhe]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={3} sm={12}>
            <InputSwitch
              name="ordemServicoItemEstoque.movimentarEstoque"
              label="Movimentar Estoque"
              ativo="Sim"
              inativo="Não"
              onChange={(event) => {
                refresh();
                if (event.target.checked)
                  handleSelecionarItemEstoqueLocalEstoque(true);
              }}
            />
          </Col>
          <Col
            lg={2}
            md={3}
            sm={12}
            style={{ display: !movimentarEstoque ? 'none' : '' }}
          >
            <InputSwitch
              name="ordemServicoItemEstoque.reservarEstoque"
              label="Reservar Estoque"
              ativo="Sim"
              inativo="Não"
            />
          </Col>
          <Col
            lg={8}
            md={6}
            sm={12}
            style={{ display: !movimentarEstoque ? 'none' : '' }}
          >
            <InputAutoCompleteUnidadeMedida
              label="Unidade de Medida do Estoque"
              name="ordemServicoItemEstoque.idUnidadeMedidaEstoque"
              placeholder="Unidade de Medida do Estoque"
              nomeObjeto="ordemServicoItemEstoque.unidadeMedidaEstoque"
              permitirAdicionar={false}
            />
          </Col>
        </Row>

        <Row style={{ display: !movimentarEstoque ? 'none' : '' }}>
          <Col lg={4} md={12} sm={12}>
            <Select
              label="Tipo de Estoque"
              name="ordemServicoItemEstoque.indicadorTipoEstoque"
              options={FormatarRemovendoEnum({
                enumObj: EstoqueIndicadorTipoEnum,
                enumRemover: [
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                ],
              })}
              onChangeValue={() => {
                refresh();
              }}
              onChange={(event) => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
                handleSetarPorContaOrdemTerceiro(event.currentTarget.value);
              }}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimalNulavel
              label="Fator de Conversão"
              name="ordemServicoItemEstoque.fatorConversao"
              casasInteiras={11}
              casasDecimais={10}
              onChange={handleOnAlterarFatorConversao}
              onBlur={() => {
                refresh();
                handleSelecionarItemEstoqueLocalEstoque();
              }}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimalNulavel
              label="Qtde. Estoque (Qtde. * Fat. Conv.)"
              name="ordemServicoItemEstoque.quantidadeEstoque"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleOnAlterarQuantidadeEstoque}
              onBlur={() => {
                refresh();
                handleSelecionarItemEstoqueLocalEstoque();
              }}
            />
          </Col>
        </Row>

        <Row style={{ display: !movimentarEstoque ? 'none' : '' }}>
          <Col lg={2} md={3} sm={12}>
            <InputSwitch
              name="ordemServicoItemEstoque.porContaOrdemTerceiro"
              label="Por Conta e Ord. Terc."
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
              }}
              disabled={
                indicadorTipoEstoque ===
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                indicadorTipoEstoque ===
                  EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
              }
            />
          </Col>
          <Col
            lg={10}
            md={9}
            sm={12}
            style={{ display: !porContaOrdemTerceiro ? 'none' : '' }}
          >
            <InputAutoCompletePessoa
              label="Pessoa Terceiro"
              name="ordemServicoItemEstoque.idPessoaTerceiro"
              placeholder="Pessoa Terceiro"
              nomeObjeto="ordemServicoItemEstoque.pessoaTerceiro"
              permitirAdicionar={false}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ display: !movimentarEstoque ? 'none' : '' }}>
        <Row>
          <Col md={12}>
            <OrdemServicoItemEstoqueLocalEstoqueHook>
              <ListaDetalheItemEstoqueLocalEstoque
                name="ordemServicoItemEstoque.listaOrdemServicoItemEstoqueLocalEstoque"
                formRefItem={formRefItem}
              />
            </OrdemServicoItemEstoqueLocalEstoqueHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemEstoqueTab;
