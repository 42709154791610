const ContasRecPagParcPorCliFornVencLiqPlanilha = [
  {
    titulo: 'Cliente/Fornecedor',
    valor: 'chave',
  },
  {
    titulo: 'Núm Documento',
    valor: 'listaConta.numeroDocumento',
  },
  {
    titulo: 'Sequência',
    valor: 'listaConta.sequencia',
  },
  {
    titulo: 'Quantidade Parcelas',
    valor: 'listaConta.quantidadeParcelas',
  },
  {
    titulo: 'Data Emissao',
    valor: 'listaConta.dataEmissao',
  },
  {
    titulo: 'Data Vencimento',
    valor: 'listaConta.dataVencimento',
  },

  {
    titulo: 'Valor Parcela',
    valor: 'listaConta.valorParcela',
  },

  {
    titulo: 'Total Parcela',
    valor: 'listaConta.valorTotalParcela',
  },
  {
    titulo: 'Total Liquidado',
    valor: 'listaConta.valorTotalLiquidado',
  },
  {
    titulo: 'Total Aberto',
    valor: 'listaConta.valorTotalAberto',
  },
  {
    titulo: 'Situação',
    valor: 'listaConta.situacao',
  },
  {
    titulo: 'Data Liquidacao',
    valor: 'listaConta.listaLiquidacao.dataLiquidacao',
  },
  {
    titulo: 'Data Contabil',
    valor: 'listaConta.listaLiquidacao.dataContabil',
  },
  {
    titulo: '(+) Geral',
    valor: 'listaConta.listaLiquidacao.valorGeral',
  },
  {
    titulo: '(+) Multa',
    valor: 'listaConta.listaLiquidacao.valorMultaSoma',
  },
  {
    titulo: '(+) Juros',
    valor: 'listaConta.listaLiquidacao.valorJurosSoma',
  },
  {
    titulo: '(-) Desconto',
    valor: 'listaConta.listaLiquidacao.valorDescontoSubtrai',
  },
  {
    titulo: '(+) Out Despesas',
    valor: 'listaConta.listaLiquidacao.valorOutrasDespesasSoma',
  },
  {
    titulo: '(-) Out Despesas',
    valor: 'listaConta.listaLiquidacao.valorOutrasDespesasSubtrai',
  },
  {
    titulo: '(-) Despesas Cartao',
    valor: 'listaConta.listaLiquidacao.valorDespesasCartaoSubtrai',
  },
];

export default ContasRecPagParcPorCliFornVencLiqPlanilha;
