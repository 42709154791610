import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/duplicar';

interface IPedidoCompraDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class PedidoCompraDuplicarComunicador {
  public async store(
    params: IPedidoCompraDuplicarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new PedidoCompraDuplicarComunicador();
