"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoRestricaoEnum;
(function (VeiculoRestricaoEnum) {
    VeiculoRestricaoEnum["naoHa"] = "0 - N\u00E3o h\u00E1";
    VeiculoRestricaoEnum["aliena\u00E7\u00E3oFiduciaria"] = "1 - Aliena\u00E7\u00E3o Fiduci\u00E1ria";
    VeiculoRestricaoEnum["arrendamentoMercantil"] = "2 - Arrendamento Mercantil";
    VeiculoRestricaoEnum["reservaDominio"] = "3 - Reserva de Dom\u00EDnio";
    VeiculoRestricaoEnum["penhorVeiculos"] = "4 - Penhor de Ve\u00EDculos";
    VeiculoRestricaoEnum["outras"] = "9 - Outras";
})(VeiculoRestricaoEnum || (VeiculoRestricaoEnum = {}));
exports.default = VeiculoRestricaoEnum;
