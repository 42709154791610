import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 10px;

  width: 100%;
  color: #000;

  & + div {
    margin-top: 15px;
  }

  label {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  input {
    /* background: transparent; */
    border: 0;

    color: #000;
    height: 35px;
    width: 100%;

    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 10px;

    &::placeholder {
      color: #666360;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
  svg {
    margin-right: 16px;
  }
`;
