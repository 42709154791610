import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/origem-icms-auto-complete-tag';

interface IOrigemIcmsAutoCompleteTagComunicadorIndex {
  params: any;
}

class OrigemIcmsAutoCompleteTagComunicador {
  public async index(
    params: IOrigemIcmsAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OrigemIcmsAutoCompleteTagComunicador();
