import {
  IImportacaoXmlTerceiroListaDadosImpressao,
  ImportacaoXmlTerceiroTipoEnum,
  ISelecionarXmlTerceiroArmazenarLista,
} from '@elogestor/util';
import React, { useRef, useState, useCallback } from 'react';
import { Table, Modal, Button, Row, Col, Container } from 'react-bootstrap';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ImportacaoXmlTerceiroUploadXmlComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroUploadXmlComunicador';
import ImportacaoXmlTerceiroArmazenarComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroArmazenarComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ImpressaoConhecimentoTransporteModalRodoviario from '../../../../../../DocumentosEletronicos/CTe/ImpressaoModalRodoviario/index';
import ImpressaoConhecimentoTransporteModalAereo from '../../../../../../DocumentosEletronicos/CTe/ImpressaoModalAereo/index';
import ImpressaoNotaFiscal from '../../../../../../DocumentosEletronicos/NFe/Impressao/index';
import ImportacaoXmlTerceiroUploadPdfComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroUploadPdfComunicador';

interface ISelecionarXmlTerceiroArmazenarListaProps {
  onFecharFormModalLista(): void;
}

const SelecionarXmlTerceiroArmazenarLista: React.FC<
  ISelecionarXmlTerceiroArmazenarListaProps
> = ({ onFecharFormModalLista }) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosNotaFiscalConhecimentoImportacaoXmlTerceiro: permissao } =
    permissoes;

  const [loading, setLoading] = useState(false);
  const [listaValor, setListaValor] = useState<
    ISelecionarXmlTerceiroArmazenarLista[]
  >([]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleOnSelecionarXml = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files) return;

    try {
      setLoading(true);

      const formData = new FormData();
      for (let i = 0; i < inputFileRef.current.files.length; i++) {
        formData.append('xmls', inputFileRef.current.files[i] as any);
      }
      const response = await ImportacaoXmlTerceiroUploadXmlComunicador.update({
        params: formData,
      });

      setListaValor(response.dados);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [setLoading]);

  const handleConcluir = useCallback(async (): Promise<void> => {
    if (listaValor.length === 0) return;

    try {
      setLoading(true);

      const response = await ImportacaoXmlTerceiroArmazenarComunicador.store({
        params: listaValor,
      });

      // #region Fazer Upload dos PDF

      if (response.listaImpressao && response.listaImpressao.length > 0) {
        const quantidadeVezesInserir = Math.ceil(
          response.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.listaImpressao.length) {
            fim = response.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlTerceiroListaDadosImpressao =
              response.listaImpressao[i];
            if (
              impressao.tipoImportacaoXmlTerceiro ===
              ImportacaoXmlTerceiroTipoEnum.cte
            ) {
              if (impressao.dados.rodo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalRodoviario.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              } else if (impressao.dados.aereo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalAereo.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              }
            } else {
              const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                impressao.dados
              );

              formData.append(
                'bloblPdf',
                blobPdf as any,
                impressao.chaveAcesso
              );
            }
          }

          await ImportacaoXmlTerceiroUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF

      setListaValor([]);
      ToastSucesso(response.mensagem);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }

    if (onFecharFormModalLista) onFecharFormModalLista();
  }, [listaValor, onFecharFormModalLista, setLoading]);

  return (
    <JanelaDetalhe
      titulo="Selecionar arquivos XML a serem armazenados"
      tamanho="xl"
      onFecharFormModal={onFecharFormModalLista}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body style={{ maxHeight: 500, overflow: 'auto' }}>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <input
              type="file"
              accept=".xml"
              multiple
              style={{ display: 'none' }}
              ref={inputFileRef}
              onChange={handleOnSelecionarXml}
            />
            <button
              type="button"
              style={{
                border: '0',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                marginTop: '25px',
                marginBottom: '10px',
                padding: '6px 20px',
                height: '35px',
              }}
              className="btn-azul-escuro"
              onClick={() => {
                inputFileRef.current?.click();
              }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                Selecionar arquivos XML &#187;
              </TextoLoading>
            </button>
          </Col>
        </Row>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '35%' }}>
                  <span>Arquivo</span>
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Número</span>
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Série</span>
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Situação</span>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Observação</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaValor.map((valor, index) => {
                return (
                  <tr
                    key={v4()}
                    className={loading ? 'tabela-desabilitada' : ''}
                  >
                    <td className="lista-texto">
                      <div>{valor.arquivo}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{valor.numero}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{valor.serie}</div>
                    </td>
                    <td
                      className="lista-texto"
                      style={{
                        color: valor.situacao === 'OK' ? 'green' : 'red',
                      }}
                    >
                      <div>{valor.situacao}</div>
                    </td>
                    <td className="lista-texto">
                      <pre
                        style={{
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: '14px',
                          margin: 0,
                        }}
                      >
                        {valor.observacao}
                      </pre>
                    </td>
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModalLista}
              disabled={loading}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                <TextoLoading loading={loading}>
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </TextoLoading>
              </span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleConcluir}
              disabled={
                listaValor.length === 0 || loading || !permissao?.inclui
              }
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>Confirmar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default SelecionarXmlTerceiroArmazenarLista;
