import { IMovimentacaoEntreProdutosValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import { Tab, Tabs } from 'react-bootstrap';
import { UseRota } from '../../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IMain from '../../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../../Componentes/TextoLoadingSalvar';
import GeralTab from '../GeralTab';
import CustosTab from '../CustosTab';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

type IProps = IMain<IMovimentacaoEntreProdutosValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosEstoqueMovimentacaoEntreProdutos: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <Tabs id={v4()} defaultActiveKey="geral">
        <Tab eventKey="geral" title="Geral">
          <GeralTab idEditar={idEditar} />
        </Tab>

        <Tab eventKey="custos" title="Custos">
          <CustosTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
