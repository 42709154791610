import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-por-nome-razao-social';

interface IPessoaObterPorNomeRazaoSocialComunicadorIndex {
  params: {
    nomeRazaoSocial: string;
    listaIdRemover: string[];
  };
}
class PessoaObterPorNomeRazaoSocialComunicador {
  public async index(
    params: IPessoaObterPorNomeRazaoSocialComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaObterPorNomeRazaoSocialComunicador();
