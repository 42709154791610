import React, { useCallback, useEffect } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import {
  FormatarEnum,
  TipoDocumentoConhecimentoTransporteEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UseSituacaoBloquearCampos } from '../../../Hooks/SituacaoBloquearCamposHook';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../Componentes/Divisor';
import Select from '../../../../../../../Componentes/Select';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IConhecimentoTransporteDocumentoReferenciadoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const ConhecimentoTransporteDocumentoReferenciadoDetalhe: React.FC<
  IConhecimentoTransporteDocumentoReferenciadoModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseListaDetalheForm();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Documento Referenciado"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={4}>
                  <Select
                    name="tipoDocumento"
                    label="Tipo de Documento"
                    options={FormatarEnum({
                      enumObj: TipoDocumentoConhecimentoTransporteEnum,
                      todos: false,
                    })}
                  />
                </Col>
                <Col lg={8}>
                  <Input
                    name="descricaoDocumento"
                    label="Descrição"
                    placeholder="Descrição"
                    maxLength={100}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <Input
                    name="numeroDocumento"
                    label="Número"
                    placeholder="Número"
                    maxLength={20}
                  />
                </Col>

                <Col lg={4}>
                  <InputDate name="dataEmissao" label="Data Emissão" />
                </Col>

                <Col lg={4}>
                  <InputDecimalNulavel
                    name="valorDocumento"
                    label="Valor Documento"
                    casasDecimais={2}
                    casasInteiras={13}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <InputDate
                    name="dataPrevistaEntrega"
                    label="Data Prevista Entrega"
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ConhecimentoTransporteDocumentoReferenciadoDetalhe;
