import api from '../../../../../Comum/Services/Api';

interface IConhecimentoTransporteTerceiroObterDadosPadraoComunicadorShow {
  params: {
    dataHoraEmissao: Date;
  };
}

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/obter-dados-padrao';

class ConhecimentoTransporteTerceiroObterDadosPadraoComunicador {
  public async show(
    params: IConhecimentoTransporteTerceiroObterDadosPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, params);
    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroObterDadosPadraoComunicador();
