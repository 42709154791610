import {
  FormatarDataHoraParaPtBr,
  IReprocessamentoProducaoLista,
  SituacaoReprocessamentoProducaoEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ReprocessamentoProducaoComunicador from '../../../../../Comunicador/Manufatura/Producao/ReprocessamentoProducao/Comunicador/ReprocessamentoProducaoComunicador';
import ReprocessamentoProducaoLogComunicador from '../../../../../Comunicador/Manufatura/Producao/ReprocessamentoProducao/Log/ReprocessamentoProducaoLogComunicador';

const ReprocessamentoProducaoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ReprocessamentoProducaoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IReprocessamentoProducaoLista) => {
            return {
              id: data.id,
              numero: <div className="lista-valor">{data.numero}</div>,
              dataLancamento: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.dataLancamento) || ''}
                </div>
              ),
              'produto.codigo': (
                <div className="lista-texto">{data.produto.codigo || ''}</div>
              ),
              'produto.descricao': (
                <div className="lista-texto">
                  {data.produto.descricao || ''}
                </div>
              ),
              quantidadeRetirada: (
                <div className="lista-valor">
                  {Number(data.quantidadeRetirada).FormatarParaPtBr({
                    maximoCasasDecimais: 4,
                    minimoCasasDecimais: 4,
                  })}
                </div>
              ),
              quantidadeRetorno: (
                <div className="lista-valor">
                  {Number(data.quantidadeRetorno).FormatarParaPtBr({
                    maximoCasasDecimais: 4,
                    minimoCasasDecimais: 4,
                  })}
                </div>
              ),
              dataHoraRetirada: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.dataHoraRetirada) || ''}
                </div>
              ),
              dataHoraRetorno: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.dataHoraRetorno) || ''}
                </div>
              ),

              situacao: (
                <div
                  className="lista-texto"
                  style={{
                    fontWeight: 'bold',
                    color:
                      data.situacao ===
                      SituacaoReprocessamentoProducaoEnum.concluido
                        ? 'green'
                        : 'black',
                  }}
                >
                  {data.situacao}
                </div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await ReprocessamentoProducaoComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          ReprocessamentoProducaoLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.ManufaturaProducaoReprocessamentoProducao
                        ?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ManufaturaProducaoReprocessamentoProducao]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'manufaturaReprocessamentoProducao' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Reprocessamento de Produção"
          permissao={permissoes.ManufaturaProducaoReprocessamentoProducao}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ReprocessamentoProducaoLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ReprocessamentoProducaoLista;
