"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegimeTributarioEnum;
(function (RegimeTributarioEnum) {
    RegimeTributarioEnum["simplesNacionalMicroEmpresa"] = "Simples Nacional - Micro Empresa";
    RegimeTributarioEnum["simplesNacionalEmpresaPequenoPorte"] = "Simples Nacional - Empresa de Pequeno Porte";
    RegimeTributarioEnum["simplesNacionalExcessoSublimiteReceitaBruta"] = "Simples Nacional \u2013 excesso de sublimite de receita bruta";
    RegimeTributarioEnum["lucroPresumidoRegimeNormal"] = "Lucro Presumido (Regime Normal)";
    RegimeTributarioEnum["lucroRealRegimeNormal"] = "Lucro Real (Regime Normal)";
})(RegimeTributarioEnum || (RegimeTributarioEnum = {}));
exports.default = RegimeTributarioEnum;
