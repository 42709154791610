import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IcmsOperacaoInterestadualObterVisibilidadeBloqueioCampos } from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const camposEscondidosIcmsOperacaoInterestadualPadrao = {
  calcular: true,
  aliquotaEstadoDestino: true,
  aliquotaInterestadual: true,
  aliquotaOrigemDestino: true,
  estadoOrigemBaseCalculo: true,
  estadoOrigemPercentualPartilha: true,
  estadoOrigemValorIcms: true,
  estadoDestinoBaseCalculo: true,
  estadoDestinoPercentualPartilha: true,
  estadoDestinoValorIcmsSemFcp: true,
  estadoDestinoPercentualFcp: true,
  estadoDestinoValorFcp: true,
  estadoDestinoValorIcmsComFcp: true,

  abaIcmsOperacaoInterestadual: true,
};

const camposBloqueadosIcmsOperacaoInterestadualPadrao = {
  calcular: true,
  aliquotaEstadoDestino: true,
  aliquotaInterestadual: true,
  aliquotaOrigemDestino: true,
  estadoOrigemBaseCalculo: true,
  estadoOrigemPercentualPartilha: true,
  estadoOrigemValorIcms: true,
  estadoDestinoBaseCalculo: true,
  estadoDestinoPercentualPartilha: true,
  estadoDestinoValorIcmsSemFcp: true,
  estadoDestinoPercentualFcp: true,
  estadoDestinoValorFcp: true,
  estadoDestinoValorIcmsComFcp: true,

  abaIcmsOperacaoInterestadual: true,
};

type ICamposEscondidosIcmsOperacaoInterestadual =
  typeof camposEscondidosIcmsOperacaoInterestadualPadrao;
type ICamposBloqueadosIcmsOperacaoInterestadual =
  typeof camposBloqueadosIcmsOperacaoInterestadualPadrao;

interface IItemIcmsOperacaoInterestadualTabContext {
  validador: boolean;
  camposEscondidosIcmsOperacaoInterestadual: ICamposEscondidosIcmsOperacaoInterestadual;
  camposBloqueadosIcmsOperacaoInterestadual: ICamposBloqueadosIcmsOperacaoInterestadual;
  obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual(): void;
}

const ItemIcmsOperacaoInterestadualTabContext =
  createContext<IItemIcmsOperacaoInterestadualTabContext>(
    {} as IItemIcmsOperacaoInterestadualTabContext
  );

const ItemIcmsOperacaoInterestadualTabHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { formRefDetalhe } = UseListaDetalheForm();
  const { formRef: formPrincipal } = UseForm();

  const [
    camposEscondidosIcmsOperacaoInterestadual,
    setCamposEscondidosIcmsOperacaoInterestadual,
  ] = useState(camposEscondidosIcmsOperacaoInterestadualPadrao);

  const [
    camposBloqueadosIcmsOperacaoInterestadual,
    setCamposBloqueadosIcmsOperacaoInterestadual,
  ] = useState(camposBloqueadosIcmsOperacaoInterestadualPadrao);

  const utiliza = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.utiliza'
  );

  const calcular = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.calcular'
  );

  const handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual =
    useCallback(() => {
      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'notaFiscalEntradaPessoa.pessoa'
      );
      const operacaoFiscal =
        formRefDetalhe.current?.getFieldValueNomeObjeto('operacaoFiscal');

      const retorno = IcmsOperacaoInterestadualObterVisibilidadeBloqueioCampos({
        utiliza: formRefDetalhe.current?.getFieldValue(
          'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.utiliza'
        ),
        calcular: formRefDetalhe.current?.getFieldValue(
          'notaFiscalEntradaItemImpostoIcmsOperacaoInterestadual.calcular'
        ),
        destinado: formRefDetalhe.current?.getFieldValue('destinado'),
        tipoMercado: operacaoFiscal?.tipoMercado,
        contribuinteIcms: pessoa?.contribuinteIcms,
      });

      setCamposEscondidosIcmsOperacaoInterestadual(retorno.escondido);
      setCamposBloqueadosIcmsOperacaoInterestadual(retorno.bloqueado);
    }, [formPrincipal, formRefDetalhe]);

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
  }, [
    utiliza,
    calcular,
    formRefDetalhe,
    handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual,
  ]);

  return (
    <ItemIcmsOperacaoInterestadualTabContext.Provider
      value={{
        camposEscondidosIcmsOperacaoInterestadual,
        camposBloqueadosIcmsOperacaoInterestadual,
        obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual:
          handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual,
        validador: true,
      }}
    >
      {children}
    </ItemIcmsOperacaoInterestadualTabContext.Provider>
  );
};

function UseItemIcmsOperacaoInterestadualTab(): Omit<
  IItemIcmsOperacaoInterestadualTabContext,
  'validador'
> {
  const context = useContext(ItemIcmsOperacaoInterestadualTabContext);

  if (!context.validador) {
    throw new Error('UseItemIcmsTab deve ser usado com um ItemIcmsTabProvider');
  }

  return context;
}

export {
  ItemIcmsOperacaoInterestadualTabHook,
  UseItemIcmsOperacaoInterestadualTab,
};
