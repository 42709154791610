import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/forma-pagamento';

interface IFormaPagamentoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFormaPagamentoComunicadorShow {
  id: string;
}

interface IFormaPagamentoComunicadorStore {
  params: any;
}

interface IFormaPagamentoComunicadorUpdate {
  id: string;
  params: any;
}

interface IFormaPagamentoComunicadorDeletar {
  id: string;
}

class FormaPagamentoComunicador {
  public async index(params: IFormaPagamentoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IFormaPagamentoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IFormaPagamentoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IFormaPagamentoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IFormaPagamentoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FormaPagamentoComunicador();
