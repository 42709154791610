import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import { UseFrenteCaixaPdvCalculo } from '../../../Hooks/FrenteCaixaPdvCalculoHook';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';

interface IDescontoAcrescimoDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const DescontoAcrescimoDetalhe: React.FC<IDescontoAcrescimoDetalheModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  loading,
  setLoading,
}) => {
  const { formRef: formRefPrincipal, refresh: refreshPrincipal } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { listaItem, idNotaFiscalSaida } = UseFrenteCaixaPdv();
  const { calcularRateiosItens } = UseFrenteCaixaPdvCalculo();

  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const bloquearCampos = useMemo(() => {
    if (idNotaFiscalSaida) return true;

    return false;
  }, [idNotaFiscalSaida]);

  const [alterouDesconto, setAlterouDesconto] = useState(false);
  const [alterouAcrescimo, setAlterouAcrescimo] = useState(false);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});
      const data = formRef.current?.getData() as any;

      const schema = Yup.object().shape({
        valorTotalGeral: Yup.mixed()
          .nullable()
          .test({
            message: 'Valor Total deve se maior que Zero!',
            test: (value: any) => {
              if (value === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (!(await handleValidar())) {
      return;
    }

    if (alterouDesconto || alterouAcrescimo) {
      if (alterouDesconto) {
        formRefPrincipal.current?.setFieldValue(
          'descontoFormaCalculoPorRateio',
          true
        );
      }
      if (alterouAcrescimo) {
        formRefPrincipal.current?.setFieldValue(
          'outrasDespesasFormaCalculoPorRateio',
          true
        );
      }

      const data = formRef.current?.getData() as any;

      formRefPrincipal.current?.setFieldValorInicialSemExecutarEvento(
        'percentualDescontoGeral',
        data.percentualDescontoGeral
      );
      formRefPrincipal.current?.setFieldValorInicialSemExecutarEvento(
        'valorDescontoGeral',
        data.valorDescontoGeral
      );
      formRefPrincipal.current?.setFieldValorInicialSemExecutarEvento(
        'percentualAcrescimoGeral',
        data.percentualAcrescimoGeral
      );
      formRefPrincipal.current?.setFieldValorInicialSemExecutarEvento(
        'valorAcrescimoGeral',
        data.valorAcrescimoGeral
      );
      formRefPrincipal.current?.setFieldValorInicialSemExecutarEvento(
        'valorTotalGeral',
        data.valorTotalGeral
      );
    }

    await calcularRateiosItens(listaItem);
    refreshPrincipal();

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    alterouAcrescimo,
    alterouDesconto,
    calcularRateiosItens,
    formRefPrincipal,
    handleValidar,
    listaItem,
    onSalvarFormModal,
    refreshPrincipal,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const valorSubTotalGeral =
        formRefPrincipal.current?.getFieldValue('valorSubTotalGeral');
      const percentualDescontoGeral = formRefPrincipal.current?.getFieldValue(
        'percentualDescontoGeral'
      );
      const valorDescontoGeral =
        formRefPrincipal.current?.getFieldValue('valorDescontoGeral');
      const percentualAcrescimoGeral = formRefPrincipal.current?.getFieldValue(
        'percentualAcrescimoGeral'
      );
      const valorAcrescimoGeral = formRefPrincipal.current?.getFieldValue(
        'valorAcrescimoGeral'
      );
      const valorTotalGeral =
        formRefPrincipal.current?.getFieldValue('valorTotalGeral');

      formRef.current?.setDataInicial({
        valorSubTotalGeral,
        percentualDescontoGeral,
        valorDescontoGeral,
        percentualAcrescimoGeral,
        valorAcrescimoGeral,
        valorTotalGeral,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRefPrincipal, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtualizarValores = useCallback(async () => {
    const subTotalGeral = formRef.current?.getFieldValue('valorSubTotalGeral');
    const percentualDescontoGeral = formRef.current?.getFieldValue(
      'percentualDescontoGeral'
    );
    const percentualAcrescimoGeral = formRef.current?.getFieldValue(
      'percentualAcrescimoGeral'
    );

    const valorDescontoGeral = (
      subTotalGeral *
      (Number(percentualDescontoGeral) / 100)
    ).Arredondar(2);

    const valorAcrescimoGeral =
      subTotalGeral * (Number(percentualAcrescimoGeral) / 100);
    const valorTotalGeral =
      subTotalGeral - valorDescontoGeral + valorAcrescimoGeral;

    formRef.current?.setFieldValorInicialSemExecutarEvento(
      'valorDescontoGeral',
      valorDescontoGeral
    );
    formRef.current?.setFieldValorInicialSemExecutarEvento(
      'valorAcrescimoGeral',
      valorAcrescimoGeral
    );
    formRef.current?.setFieldValorInicialSemExecutarEvento(
      'valorTotalGeral',
      valorTotalGeral
    );
  }, []);

  return (
    <JanelaDetalhe
      titulo="Desconto / Acréscimo"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Sub. Total"
                  name="valorSubTotalGeral"
                  placeholder="Sub. Total"
                  casasDecimais={2}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Desconto (%)"
                  name="percentualDescontoGeral"
                  placeholder="Desconto (%)"
                  casasDecimais={6}
                  onChange={async () => {
                    setAlterouDesconto(true);

                    await handleAtualizarValores();
                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Desconto (R$)"
                  name="valorDescontoGeral"
                  placeholder="Desconto (R$)"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouDesconto(true);

                    const valorDescontoGeral = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();
                    const valorSubTotalGeral =
                      formRef.current?.getFieldValue('valorSubTotalGeral');

                    const percentualDescontoGeral = valorSubTotalGeral
                      ? (valorDescontoGeral * 100) / valorSubTotalGeral
                      : 0;
                    formRef.current?.setFieldValorInicialSemExecutarEvento(
                      'percentualDescontoGeral',
                      percentualDescontoGeral
                    );

                    await handleAtualizarValores();
                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Acréscimo (%)"
                  name="percentualAcrescimoGeral"
                  placeholder="Acréscimo (%)"
                  casasDecimais={6}
                  onChange={async () => {
                    setAlterouAcrescimo(true);

                    await handleAtualizarValores();
                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Acréscimo (R$)"
                  name="valorAcrescimoGeral"
                  placeholder="Acréscimo (R$)"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouAcrescimo(true);

                    const valorAcrescimoGeral = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();
                    const valorSubTotalGeral =
                      formRef.current?.getFieldValue('valorSubTotalGeral');

                    const percentualAcrescimoGeral = valorSubTotalGeral
                      ? (valorAcrescimoGeral * 100) / valorSubTotalGeral
                      : 0;
                    formRef.current?.setFieldValorInicialSemExecutarEvento(
                      'percentualAcrescimoGeral',
                      percentualAcrescimoGeral
                    );

                    await handleAtualizarValores();
                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Valor Total"
                  name="valorTotalGeral"
                  placeholder="Valor Total"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouDesconto(true);
                    setAlterouAcrescimo(true);

                    const valorTotalGeral = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();
                    const valorSubTotalGeral =
                      formRef.current?.getFieldValue('valorSubTotalGeral');

                    if (valorTotalGeral > valorSubTotalGeral) {
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualDescontoGeral',
                        0
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorDescontoGeral',
                        0
                      );

                      const valorAcrescimoGeral =
                        valorTotalGeral - valorSubTotalGeral;

                      const percentualAcrescimoGeral = valorSubTotalGeral
                        ? (valorAcrescimoGeral * 100) / valorSubTotalGeral
                        : 0;

                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualAcrescimoGeral',
                        percentualAcrescimoGeral
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorAcrescimoGeral',
                        valorAcrescimoGeral
                      );
                    } else if (valorTotalGeral < valorSubTotalGeral) {
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualAcrescimoGeral',
                        0
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorAcrescimoGeral',
                        0
                      );

                      const valorDescontoGeral =
                        valorSubTotalGeral - valorTotalGeral;

                      const percentualDescontoGeral = valorSubTotalGeral
                        ? (valorDescontoGeral * 100) / valorSubTotalGeral
                        : 0;

                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualDescontoGeral',
                        percentualDescontoGeral
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorDescontoGeral',
                        valorDescontoGeral
                      );
                    } else {
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualDescontoGeral',
                        0
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorDescontoGeral',
                        0
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'percentualAcrescimoGeral',
                        0
                      );
                      formRef.current?.setFieldValorInicialSemExecutarEvento(
                        'valorAcrescimoGeral',
                        0
                      );
                    }

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickSalvar}
                disabled={bloquearCampos}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default DescontoAcrescimoDetalhe;
