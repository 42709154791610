import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/nota-fiscal-saida/nota-fiscal-saida-por-transportadora';

interface INotaFiscalSaidaPorTransportadoraRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalSaidaPorTransportadoraRelatorioComunicador {
  public async index(
    params: INotaFiscalSaidaPorTransportadoraRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalSaidaPorTransportadoraRelatorioComunicador();
