/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { DesestruturarPesquisa } from '@elogestor/util';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import ProdutoFrenteCaixaPdvAutoCompleteComunicador from '../../../../../Comunicador/Suprimentos/Produtos/Produto/AutoComplete/ProdutoFrenteCaixaPdvAutoComplete/ProdutoFrenteCaixaPdvAutoCompleteComunicador';
import { UseParametros } from '../../../../../Hooks/ParametrosHook';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { Item } from '../../AutoCompleteBase/styles';

interface IOnChange {
  valorAnterior: any;
  key: string;
}

export interface IProdutoFrenteCaixaPdv {
  id: string;
  codigo: string;
  descricao: string;

  produtoEstoque: {
    unidadeMedida: {
      id: string;
      sigla: string;
      descricao: string;
    };

    controlarEstoque: boolean;
    controlarLote: boolean;
  };

  produtoEngenharia: {
    codigoBarras?: string | null;
  };

  produtoComercial: {
    comercializado: boolean;
  };

  detalhesEstoque?: string;
  valorUnitarioVenda: number;
}

export interface IOnChangeItemAtualAutoCompleteProdutoFrenteCaixaPdvEvent {
  itemAtual: IProdutoFrenteCaixaPdv | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteProdutoFrenteCaixaPdvEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteProdutoFrenteCaixaPdvEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteProdutoFrenteCaixaPdvProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  ativo?: boolean;
  controlaLote?: boolean;
  controlaEstoque?: boolean;
  comercializado?: boolean;
  listaIdParaRemover?: string[];
  listaIdFiltrar?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteProdutoFrenteCaixaPdvEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteProdutoFrenteCaixaPdvEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteProdutoFrenteCaixaPdvEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregar?: (
    event: IOnChangeItemAtualAutoCompleteProdutoFrenteCaixaPdvEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteProdutoFrenteCaixaPdvEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteProdutoFrenteCaixaPdvRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteProdutoFrenteCaixaPdv: React.ForwardRefRenderFunction<
  IInputAutoCompleteProdutoFrenteCaixaPdvRef,
  IInputAutoCompleteProdutoFrenteCaixaPdvProps
> = (
  {
    name,
    nomeObjeto,
    label,
    ativo = true,
    controlaEstoque = null,
    controlaLote = null,
    comercializado = null,
    listaIdParaRemover = [],
    listaIdFiltrar = [],
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    ...rest
  },
  ref
) => {
  const parametros = UseParametros();

  const [idRegistro, setIdRegistro] = useState('');

  const quantidadeAlterado = useRef(0);

  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    disabled,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<IProdutoFrenteCaixaPdv | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        if (value) setIdRegistro(value.id);
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior, key }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (itemAtual) {
        setIdRegistro(itemAtual?.id);
      } else {
        setIdRegistro('');
      }

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior, key });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              const informacoes = DesestruturarPesquisa({
                texto: valor,
                removerZerosEsquerda: parametros.RemoverZerosAEsquerda
                  ? parametros.RemoverZerosAEsquerda
                  : false,
              });

              try {
                const response =
                  await ProdutoFrenteCaixaPdvAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite: 10,
                      texto: informacoes.textoPesquisa,
                      ativo,
                      listaIdParaRemover,
                      listaIdFiltrar,
                      controlaLote,
                      controlaEstoque,
                      comercializado,
                    },
                  });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: IProdutoFrenteCaixaPdv) => {
              return item.id;
            }}
            obterLabel={(item: IProdutoFrenteCaixaPdv) => {
              const valorPesquisa = autoCompleteRef.current?.getInput()?.value;

              if (!item.codigo && !item.descricao) return '';
              if (item.codigo && !item.descricao) return item.codigo;
              if (!item.codigo && item.descricao) return item.descricao;

              if (valorPesquisa) {
                return `${valorPesquisa} ⬅ ${item.codigo} - ${item.descricao}`;
              }

              return `${item.codigo} - ${item.descricao}`;
            }}
            personalizarItem={({ selecionado, navegacao, item }) => {
              const partesDetalhesEstoque = item.detalhesEstoque?.split('\n');
              return (
                <Item $selecionado={selecionado} $navegacao={navegacao}>
                  <strong>{`${item.codigo} - ${item.descricao}`}</strong>
                  <br />
                  {partesDetalhesEstoque?.map((detalheEstoque: any): any => {
                    const partes = detalheEstoque.split(', Qtde: ');
                    const local = partes[0];
                    const quantidade = partes[1];

                    return (
                      <span style={{ fontSize: 12, marginLeft: 20 }}>
                        {`${local}, Qtde: ${Number(quantidade).FormatarParaPtBr({ maximoCasasDecimais: 4, minimoCasasDecimais: 4 })}`}
                        <br />
                      </span>
                    );
                  })}

                  <span
                    style={{
                      fontSize: 12,
                      marginLeft: 35,
                      fontStyle: 'italic',
                    }}
                  >
                    {`Val. Unitário: ${Number(item.valorUnitarioVenda).FormatarParaPtBr({ maximoCasasDecimais: 4, minimoCasasDecimais: 4 })}`}
                    <br />
                  </span>
                </Item>
              );
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            inputProps={rest}
            obterNovoItem={(valor) => {
              return {
                id: '',
                codigo: '',
                descricao: valor,

                produtoEstoque: {
                  unidadeMedida: {
                    id: '',
                    sigla: '',
                    descricao: '',
                  },

                  controlarEstoque: true,
                  controlarLote: true,
                },

                produtoEngenharia: {
                  codigoBarras: undefined,
                },

                producoComercial: {
                  comercializado: false,
                },
              };
            }}
            onBlurInput={handleBlurInput}
            habilitaEnterBlur={false}
            tempoDebounce={0}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteProdutoFrenteCaixaPdv);
