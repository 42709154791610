"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IsSimplesNacional;
const __1 = require("..");
function IsSimplesNacional(regimeTributario) {
    if (regimeTributario === __1.RegimeTributarioEnum.simplesNacionalMicroEmpresa ||
        regimeTributario === __1.RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte) {
        return true;
    }
    return false;
}
