/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import ToastErro from '../../../../Util/Toasts/ToastErro';
import BtnPadraoButton from '../../../Buttons/BtnPadraoButton';
import TagInputBase, {
  IOnChangeListaItemAtualTagEvent,
  IOnAdicionarTagEvent,
  IOnChangeTextoTagEvent,
  IOnMoverTagEvent,
  IOnRemoverTagEvent,
} from '../TagInputBase';
import UseRegisterTagsInput from '../TagInputBase/Hooks/UseRegisterTagsInput';

interface IOnChange {
  valorAnterior: any;
}

interface IInputArquivoTagProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualTagEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarTagEvent) => void;
  onRemoverItem?: (evento: IOnRemoverTagEvent) => void;
  onMoverItem?: (evento: IOnMoverTagEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoTagEvent) => void;
}

interface IInputArquivoTag {
  id?: string;
  nomeArquivo: string;
}

const InputArquivoTag: React.FC<IInputArquivoTagProps> = ({
  name,
  label,
  onChangeListaItemAtual,
  onAdicionarItem,
  onRemoverItem,
  onMoverItem,
  onChangeTexto,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleObterChaveUnica = useCallback((item: IInputArquivoTag) => {
    return item.nomeArquivo;
  }, []);

  const { tagInputRef, error: erroUnform } = UseRegisterTagsInput({
    nome: name,
    handleObterChaveUnica,
  });

  const [error, setError] = useState(erroUnform);

  useEffect(() => {
    setError(erroUnform);
  }, [erroUnform]);

  const handleObterLabel = useCallback((item: IInputArquivoTag) => {
    return item.nomeArquivo;
  }, []);

  const handleFormatarValidarString = useCallback(
    (valor: string): IInputArquivoTag => {
      if (valor.length > 150) {
        throw new Error('Tag deve ter no máximo 150 caracteres');
      }
      return {
        nomeArquivo: valor,
      };
    },
    []
  );

  const handleOnSelecionarArquivo = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) return;

    for (let index = 0; index < inputFileRef.current.files.length; index++) {
      const file = inputFileRef.current.files[index];

      const listaInput = tagInputRef.current
        ?.obterListaItemAtual()
        .filter((inputList) => inputList.nomeArquivo === file.name);

      if (listaInput && listaInput.length > 0) {
        ToastErro('Já existe um anexo com esse nome!');
        return;
      }

      tagInputRef.current?.adicionarItem({
        nomeArquivo: file.name,
        file,
        permitirExcluir: true,
      });
    }
  }, [tagInputRef]);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Row style={{ width: '100%', margin: 0 }}>
        <Col lg={9} md={12} style={{ padding: 0 }}>
          <div style={{ width: '100%' }}>
            {label && <label>{label}</label>}
            <div style={{ display: 'flex' }}>
              <TagInputBase
                ref={tagInputRef}
                error={error}
                obterChaveUnica={handleObterChaveUnica}
                obterLabel={handleObterLabel}
                formatarValidarString={handleFormatarValidarString}
                onAdicionarItem={onAdicionarItem}
                onRemoverItem={onRemoverItem}
                onMoverItem={onMoverItem}
                onChangeListaItemAtual={onChangeListaItemAtual}
                onChangeTexto={onChangeTexto}
                permitirDigitar={false}
              />
            </div>
          </div>
        </Col>
        <Col
          lg={3}
          md={12}
          style={{ display: 'flex', alignItems: 'flex-end', padding: 0 }}
        >
          <input
            type="file"
            accept=".pdf, .zip, .jpg, .jpeg, .gif, .png, .rar, .xml"
            style={{ display: 'none' }}
            ref={inputFileRef}
            onChange={handleOnSelecionarArquivo}
          />
          <BtnPadraoButton
            style={{
              maxWidth: telaGrande ? 500 : '',
              marginLeft: telaGrande ? 10 : 0,
              marginTop: !telaGrande ? 10 : 0,
              marginBottom: telaGrande ? 2 : 0,
              height: telaGrande ? 40 : '',
            }}
            onClick={() => {
              inputFileRef.current?.click();
            }}
            type="button"
            className="btn-padrao btn-verde-claro btn-adicionar"
          >
            <IoMdAdd />
            <span style={{ margin: 20 }}>Novo Anexo</span>
          </BtnPadraoButton>
        </Col>
      </Row>
    </div>
  );
};

export default InputArquivoTag;
