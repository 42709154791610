import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/curva-abc-nota-fiscal-entrada/curva-abc-nota-fiscal-entrada-grupo-produto-com-produto-por-valor';

interface ICurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorValorRelatorioComunicador();
