import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/impressao-etiquetas-nota-fiscal';

interface IImpressaoEtiquetasNotaFiscalComunicadorIndex {
  params: {
    idNotaFiscalEtiquetaSelecionada?: string | null;
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IImpressaoEtiquetasNotaFiscalComunicadorShow {
  id: string;
}

interface IImpressaoEtiquetasNotaFiscalComunicadorStore {
  params: any;
}

interface IImpressaoEtiquetasNotaFiscalComunicadorUpdate {
  id: string;
  params: any;
}

interface IImpressaoEtiquetasNotaFiscalComunicadorDeletar {
  id: string;
}

class ImpressaoEtiquetasNotaFiscalComunicador {
  public async index(
    params: IImpressaoEtiquetasNotaFiscalComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IImpressaoEtiquetasNotaFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IImpressaoEtiquetasNotaFiscalComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IImpressaoEtiquetasNotaFiscalComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IImpressaoEtiquetasNotaFiscalComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ImpressaoEtiquetasNotaFiscalComunicador();
