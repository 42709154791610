import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/banco-auto-complete-tag';

interface IBancoAutoCompleteTagComunicadorIndex {
  params: any;
}

class BancoAutoCompleteTagComunicador {
  public async index(
    params: IBancoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new BancoAutoCompleteTagComunicador();
