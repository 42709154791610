import React from 'react';
import { IPersonalizarItem } from '../../../Inputs/AutoComplete/AutoCompleteBase';
import { Container } from './styles';

const ItemMenuAutoComplete: React.FC<IPersonalizarItem> = ({
  item,
  navegacao,
}) => {
  return (
    <Container $navegacao={navegacao}>
      {`${item.descricao} (${item.menu.descricao})`}
    </Container>
  );
};

export default ItemMenuAutoComplete;
