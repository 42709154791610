import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/movimentacoes/conta/tipo-adiantamento-enum-auto-complete-tag';

interface IContaTipoAdiantamentoEnumAutoCompleteTagComunicadorIndex {
  params: any;
}

class ContaTipoAdiantamentoEnumAutoCompleteTagComunicador {
  public async index(
    params: IContaTipoAdiantamentoEnumAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ContaTipoAdiantamentoEnumAutoCompleteTagComunicador();
