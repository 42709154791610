import { Row } from 'react-bootstrap';
import React from 'react';
import Divisor from '../../../../../../Componentes/Divisor';
import InputTag from '../../../../../../Componentes/Inputs/TagsInput/InputTag';

const EcommerceTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <InputTag
            name="listaFormaPagamentoEmpresa[0].listaFormaPagamentoEmpresaIdEcommerce"
            maxLength={8}
            nomeCampo="idEcommerce"
            label="Ids das Formas de Pagamento do Tray"
          />
        </Row>

        <Row>
          <InputTag
            name="listaFormaPagamentoEmpresa[0].listaFormaPagamentoEmpresaIdEcommerceWake"
            maxLength={8}
            nomeCampo="idEcommerce"
            label="Ids das Formas de Pagamento do Wake"
          />
        </Row>
      </Divisor>
    </div>
  );
};

export default EcommerceTab;
