import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/configuracao-cr-enologico-cultivar/obter-produtos-configurados';

class ObterProdutosConfigurados {
  public async index(): Promise<string[]> {
    const response = await api.get<string[]>(rota);
    return response.data;
  }
}

export default new ObterProdutosConfigurados();
