import React, { useEffect } from 'react';
import InputAutoCompleteModeloDocumento from '../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import ITipoDados from '../../../../../Main/Interface/ITipoDados';

const AutoCompleteModeloDocumento: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return <InputAutoCompleteModeloDocumento name={nome} nomeObjeto={nome} />;
};

export default AutoCompleteModeloDocumento;
