import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const Container = styled.div``;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;
