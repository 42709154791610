/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col } from 'react-bootstrap';
import {
  INotaFiscalEntradaItemCustosCompraLista,
  TipoCustoEnum,
} from '@elogestor/util';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';

interface ICustoCompraLista {
  name: string;
}

const CustoCompraLista: React.FC<ICustoCompraLista> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const formPrincipal = UseForm();

  const { formRefLista, formRefDetalhe, refresh } = UseListaDetalheForm();

  const valorRef = useRef<INotaFiscalEntradaItemCustosCompraLista[]>([]);

  const [listaValor, setListaValor] = useState<
    INotaFiscalEntradaItemCustosCompraLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<INotaFiscalEntradaItemCustosCompraLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCalcularValoresDespesasItens = useCallback(
    (valor: number, valorAnterior: number, indexItem: number) => {
      let valorOutrasDespesas =
        formRefDetalhe.current?.getFieldValue('valorOutrasDespesas') ?? 0;

      const diferenca = Number(valor) - Number(valorAnterior);

      listaValor[indexItem].listaNotaFiscalEntradaItemCusto.forEach(
        (item: any, index: number) => {
          if (
            item.tipoCusto === TipoCustoEnum.ultimaCompra ||
            item.tipoCusto === TipoCustoEnum.valorCustoMedio
          ) {
            listaValor[indexItem].listaNotaFiscalEntradaItemCusto[
              index
            ].valorOutrasDespesas = Number(valor);
            formRefLista.current?.setFieldValue(
              `listaNotaFiscalEntradaItem[${indexItem}].listaNotaFiscalEntradaItemCusto[${index}].valorOutrasDespesas`,
              Number(valor)
            );
          }
        }
      );
      valorOutrasDespesas += diferenca;

      formRefDetalhe.current?.setFieldValue(
        'valorOutrasDespesas',
        valorOutrasDespesas
      );

      refresh();
    },
    [formRefDetalhe, formRefLista, listaValor, refresh]
  );

  const handleCalcularValoresDescontosItens = useCallback(
    (valor: number, valorAnterior: number, indexItem: number) => {
      let valorOutrosDescontos =
        formRefDetalhe.current?.getFieldValue('valorOutrosDescontos') ?? 0;

      const diferenca = Number(valor) - Number(valorAnterior);

      listaValor[indexItem].listaNotaFiscalEntradaItemCusto.forEach(
        (item: any, index: number) => {
          if (
            item.tipoCusto === TipoCustoEnum.ultimaCompra ||
            item.tipoCusto === TipoCustoEnum.valorCustoMedio
          ) {
            listaValor[indexItem].listaNotaFiscalEntradaItemCusto[
              index
            ].valorOutrosDescontos = Number(valor);
            formRefLista.current?.setFieldValue(
              `listaNotaFiscalEntradaItem[${indexItem}].listaNotaFiscalEntradaItemCusto[${index}].valorOutrosDescontos`,
              Number(valor)
            );
          }
        }
      );
      valorOutrosDescontos += diferenca;

      formRefDetalhe.current?.setFieldValue(
        'valorOutrosDescontos',
        valorOutrosDescontos
      );

      refresh();
    },
    [formRefDetalhe, formRefLista, listaValor, refresh]
  );

  const handleCalcularValoresDespesasContabeisItens = useCallback(
    (valor: number, valorAnterior: number, indexItem: number) => {
      let valorOutrasDespesasContabeis =
        formRefDetalhe.current?.getFieldValue('valorOutrasDespesasContabeis') ??
        0;

      const diferenca = Number(valor) - Number(valorAnterior);

      listaValor[indexItem].listaNotaFiscalEntradaItemCusto.forEach(
        (item: any, index: number) => {
          listaValor[indexItem].listaNotaFiscalEntradaItemCusto[
            index
          ].valorOutrasDespesasContabeis = Number(valor);
          formRefLista.current?.setFieldValue(
            `listaNotaFiscalEntradaItem[${indexItem}].listaNotaFiscalEntradaItemCusto[${index}].valorOutrasDespesasContabeis`,
            Number(valor)
          );
        }
      );
      valorOutrasDespesasContabeis += diferenca;

      formRefDetalhe.current?.setFieldValue(
        'valorOutrasDespesasContabeis',
        valorOutrasDespesasContabeis
      );

      refresh();
    },
    [formRefDetalhe, formRefLista, listaValor, refresh]
  );

  return (
    <FormCia ref={formRefLista}>
      <Divisor>
        <Col lg={12} md={6} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                backgroundColor: '#fff',
                flexDirection: 'row',
                padding: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '5%',
                  alignItems: 'flex-end',
                }}
              >
                <span>Ordem </span>
              </div>

              <div
                style={{
                  width: '1%',
                }}
              />

              <div style={{ width: '15%' }}>
                <span>Código</span>
              </div>

              <div
                style={{
                  width: '1%',
                }}
              />

              <div style={{ width: '30%' }}>
                <span>Produto</span>
              </div>

              <div
                style={{
                  width: '1%',
                }}
              />

              <div
                style={{
                  display: 'flex',
                  width: '15%',
                  alignItems: 'flex-end',
                }}
              >
                <span>Quantidade</span>
              </div>

              <div
                style={{
                  width: '1%',
                }}
              />

              <div
                style={{
                  display: 'flex',
                  width: '15%',
                  alignItems: 'flex-end',
                }}
              >
                <span>Valor Unitário</span>
              </div>

              <div
                style={{
                  width: '1%',
                }}
              />

              <div
                style={{
                  display: 'flex',
                  width: '15%',
                  alignItems: 'flex-end',
                }}
              >
                <span>Valor Total Produtos</span>
              </div>
            </div>

            {listaValor.map((item, index) => {
              return (
                <div
                  style={{
                    backgroundColor: index % 2 === 0 ? '#fff' : '#E4E4E4',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 5,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '5%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>{item.ordem}</span>
                    </div>

                    <div
                      style={{
                        width: '1%',
                      }}
                    />

                    <div
                      style={{
                        width: '15%',
                      }}
                    >
                      <span>
                        {String(
                          item.produto.codigo
                        ).InserirEspacoRepetindoPorNumeroCaracteres(20)}
                      </span>
                    </div>

                    <div
                      style={{
                        width: '1%',
                      }}
                    />

                    <div
                      style={{
                        width: '30%',
                      }}
                    >
                      <span>{item.produto.descricao}</span>
                    </div>

                    <div
                      style={{
                        width: '1%',
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        width: '15%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>{Number(item.quantidade).FormatarParaPtBr()}</span>
                    </div>

                    <div
                      style={{
                        width: '1%',
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        width: '15%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>
                        {Number(item.valorUnitario).FormatarParaPtBr()}
                      </span>
                    </div>

                    <div
                      style={{
                        width: '1%',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        width: '15%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <span>
                        {Number(item.valorTotalProduto).FormatarParaPtBr()}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      border: '2px solid black',
                      flexDirection: 'column',
                      borderRadius: '4px',
                      padding: 4,
                      margin: '0 10px 10px 110px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '20%' }}>
                        <span>Tipo de Custo </span>
                      </div>

                      <div style={{ width: '20%' }}>
                        <span>Val. Out. Desp. </span>
                      </div>

                      <div style={{ width: '20%' }}>
                        <span>Val. Out. Desc. </span>
                      </div>

                      <div style={{ width: '20%' }}>
                        <span>Val. Out. Desp. Cont. </span>
                      </div>

                      <div style={{ width: '20%' }}>
                        <span>Val. Frete Conh. </span>
                      </div>
                    </div>

                    {item.listaNotaFiscalEntradaItemCusto.map(
                      (custo, custoIndex) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor:
                                custoIndex % 2 === 0 ? '#fff' : '#E4E4E4 ',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '25%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <span>{custo.tipoCusto}</span>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '25%',
                                }}
                              >
                                <InputDecimal
                                  style={{
                                    height: 25,
                                    width: '75%',
                                    boxSizing: 'border-box',
                                  }}
                                  onChange={(ev) => {
                                    item.listaNotaFiscalEntradaItemCusto[
                                      custoIndex
                                    ].valorOutrasDespesas =
                                      ev.currentTarget.value.ConverterParaNumber();
                                  }}
                                  onBlurCia={(ev, props) => {
                                    handleCalcularValoresDespesasItens(
                                      ev.currentTarget.value.ConverterParaNumber(),
                                      props.valorAnteriorOnBlur.ConverterParaNumber(),
                                      index
                                    );
                                  }}
                                  name={`listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesas`}
                                  disabled={
                                    custo.tipoCusto ===
                                      TipoCustoEnum.contabilIcmsIpi ||
                                    custo.tipoCusto === TipoCustoEnum.contabilIr
                                  }
                                  casasDecimais={4}
                                />
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  width: '25%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <InputDecimal
                                  style={{
                                    height: 25,
                                    width: '75%',
                                    boxSizing: 'border-box',
                                  }}
                                  onChange={(ev) => {
                                    item.listaNotaFiscalEntradaItemCusto[
                                      custoIndex
                                    ].valorOutrosDescontos =
                                      ev.currentTarget.value.ConverterParaNumber();
                                  }}
                                  onBlurCia={(ev, props) => {
                                    handleCalcularValoresDescontosItens(
                                      ev.currentTarget.value.ConverterParaNumber(),
                                      props.valorAnteriorOnBlur.ConverterParaNumber(),
                                      index
                                    );
                                  }}
                                  name={`listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrosDescontos`}
                                  disabled={
                                    custo.tipoCusto ===
                                      TipoCustoEnum.contabilIcmsIpi ||
                                    custo.tipoCusto === TipoCustoEnum.contabilIr
                                  }
                                  casasDecimais={4}
                                />
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  width: '25%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <InputDecimal
                                  style={{
                                    height: 25,
                                    width: '75%',
                                    boxSizing: 'border-box',
                                  }}
                                  onChange={(ev) => {
                                    item.listaNotaFiscalEntradaItemCusto[
                                      custoIndex
                                    ].valorOutrasDespesasContabeis =
                                      ev.currentTarget.value.ConverterParaNumber();
                                  }}
                                  onBlurCia={(ev, props) => {
                                    handleCalcularValoresDespesasContabeisItens(
                                      ev.currentTarget.value.ConverterParaNumber(),
                                      props.valorAnteriorOnBlur.ConverterParaNumber(),
                                      index
                                    );
                                  }}
                                  name={`listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesasContabeis`}
                                  casasDecimais={4}
                                />
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  width: '25%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <InputDecimal
                                  style={{
                                    height: 25,
                                    width: '75%',
                                    boxSizing: 'border-box',
                                  }}
                                  onChange={(ev) => {
                                    item.listaNotaFiscalEntradaItemCusto[
                                      custoIndex
                                    ].valorConhecimentoFrete =
                                      ev.currentTarget.value.ConverterParaNumber();
                                  }}
                                  onBlurCia={(ev, props) => {
                                    handleCalcularValoresDespesasContabeisItens(
                                      ev.currentTarget.value.ConverterParaNumber(),
                                      props.valorAnteriorOnBlur.ConverterParaNumber(),
                                      index
                                    );
                                  }}
                                  name={`listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorConhecimentoFrete`}
                                  casasDecimais={4}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Divisor>
    </FormCia>
  );
};

export default CustoCompraLista;
