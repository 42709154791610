import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro, ValidarChaveAcesso } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      isPesagemCadastrado?: boolean
    ): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idPessoa: Yup.string()
            .nullable()
            .required('Pessoa / Produtor é Obrigatório!'),

          codigoBarrasChaveAcessoEntradaIndustrializacao: Yup.string().test({
            message:
              'Chave de acesso da entrada para industrialização inválida!',
            test: (value: any) => {
              if (!value) return true;
              const valido = ValidarChaveAcesso(value);
              return valido;
            },
          }),
          codigoBarrasChaveAcesso: Yup.string().test({
            message: ' Chave de acesso da compra inválida!',
            test: (value: any) => {
              if (!value) return true;
              const valido = ValidarChaveAcesso(value);
              return valido;
            },
          }),
          percentual: Yup.mixed()
            .nullable()
            .test({
              message: 'Percentual deve ser menor que 100%',
              test: () => {
                if (data.percentual > 100) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message:
                'Não permitido adicionar este produtor, pois o percentual de todos os produtores ultrapassará 100%',
              test: () => {
                const dataPrincipal =
                  formPrincipal.formRef.current?.getData() as any;
                const total = dataPrincipal?.listaPesagemPessoa.reduce(
                  (acumulador: number, item: any) => {
                    acumulador += Number(item.percentual);
                    return acumulador;
                  },

                  0
                );
                if (total + data.percentual > 100) return false;
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isPesagemCadastrado !== false) {
          const errors = GetValidationErrors(error as any);
          formRef?.setErrors(errors);
        }
        return false;
      }
    },
    [formPrincipal.formRef]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        const id = getIdDetalheRegistro() || '';

        data.idPessoa = data.pessoa ? data.pessoa.id : null;

        if (!(await handleValidar(data, formRef, true))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const listaDados =
          formPrincipal.formRef.current?.getFieldValue('listaPesagemPessoa');

        formPrincipal.formRef.current?.setFieldValue('listaPesagemPessoa', [
          ...listaDados,
          data,
        ]);

        formPrincipal.formRef.current?.setFieldValue('listaPessoaMudou', true);

        await formRef?.reset();

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, getIdDetalheRegistro, handleValidar, redirecionar]
  );
  const handleExcluir = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleExcluir" não foi implementada no "ListaDetalhe"',
    });
  }, []);

  return (
    <ListaDetalheFormProvider
      value={{
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
