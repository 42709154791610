/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { ValidarInscricaoEstadual } from '@elogestor/util';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputInscricaoEstadualProps {
  valorAnterior: string;
}

export interface IOnChangeValueInputInscricaoEstadualEvent {
  value: string;
  valido: boolean;
}

export interface IInputInscricaoEstadualRef {
  focus(): void;
}

interface IInputInscricaoEstadualProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  uf?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputInscricaoEstadualProps
  ) => void | Promise<void>;
  onChangeValue?: (
    event: IOnChangeValueInputInscricaoEstadualEvent
  ) => Promise<void>;
}

const InputInscricaoEstadual: React.ForwardRefRenderFunction<
  IInputInscricaoEstadualRef,
  IInputInscricaoEstadualProps
> = (
  { name, label, uf, onFocus, onChange, onChangeValue, onBlur, ...rest },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      const valor = event.target.value.replace(/\D/g, '');

      event.target.value = valor;

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
      let valido = true;
      if (uf && valor && !ValidarInscricaoEstadual(valor, uf)) {
        valido = false;
      }
      if (onChangeValue) onChangeValue({ valido, value: valor });
    },
    [onChange, onChangeValue, uf]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      setIsFocused(false);
      const valor = inputRef.current?.value.replace(/\D/g, '');
      if (uf && valor && !ValidarInscricaoEstadual(valor, uf)) {
        setErro('Inscrição Estadual inválida!');
      } else {
        setIsFilled(!!valor);
        if (onBlur) onBlur(event);
      }
    },
    [onBlur, uf]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        let valor = inputRef.current?.value || '';
        valor = valor.replace(/\D/g, '');

        return valor;
      },

      setValue(_, value = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }
      },

      setSemExecutarEvento(_, value = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
        maxLength={14}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputInscricaoEstadual);
