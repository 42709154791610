import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { NavegacaoProvider } from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao/Hooks/NavegacaoHook';
import { UseListaNotaFiscalEntradaDraggable } from '../../../ItemTab/ListaDetalheItem/Hook/ListaNotaFiscalEntradaDraggableHook';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';

const ItemNavegacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaValor } = UseListaNotaFiscalEntradaDraggable();
  const { handleCarregarDados, formRefDetalhe, refresh } =
    UseListaDetalheForm();
  const formPrincipal = UseForm();
  const idDetalheRegistro = formPrincipal.getIdDetalheRegistro();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const [posicaoNavegacao, setPosicaoNavegacao] = useState(1);
  const [mostrarNavegacao, setMostrarNavegacao] = useState(false);

  const totalNavegacao = useMemo(() => {
    return listaValor.length;
  }, [listaValor.length]);

  const handleSetPosicaoNavegacao = useCallback(
    (novaPosicao: number) => {
      setPosicaoNavegacao(novaPosicao);
      const item = listaValor.find((valor) => valor.ordem === novaPosicao);
      if (item) {
        formPrincipal.setIdDetalheRegistro(item.id);
        refresh();
      }
    },
    [formPrincipal, listaValor, refresh]
  );

  useEffect(() => {
    if (idDetalheRegistro !== '') {
      const item = listaValor.find((valor) => valor.id === idDetalheRegistro);

      if (item && item.ordem >= 1) {
        handleSetPosicaoNavegacao(item.ordem);
      }

      setMostrarNavegacao(true);
    } else {
      setMostrarNavegacao(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaValor, handleSetPosicaoNavegacao]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados, idDetalheRegistro]);

  const handleRecuperarFormulario = useCallback(async (): Promise<boolean> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return true;
      }
    }
    return false;
  }, [abrirRecuperarFormulario, formRefDetalhe]);

  const handleAnterior = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }

    if (posicaoNavegacao - 1 <= 1) {
      handleSetPosicaoNavegacao(1);
      return;
    }
    handleSetPosicaoNavegacao(posicaoNavegacao - 1);
  }, [handleRecuperarFormulario, posicaoNavegacao, handleSetPosicaoNavegacao]);

  const handlePrimeiro = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }

    handleSetPosicaoNavegacao(1);
  }, [handleRecuperarFormulario, handleSetPosicaoNavegacao]);

  const handleProximo = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }

    if (posicaoNavegacao + 1 >= totalNavegacao) {
      handleSetPosicaoNavegacao(totalNavegacao);
      return;
    }

    handleSetPosicaoNavegacao(posicaoNavegacao + 1);
  }, [
    handleRecuperarFormulario,
    posicaoNavegacao,
    totalNavegacao,
    handleSetPosicaoNavegacao,
  ]);

  const handleUltimo = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }

    handleSetPosicaoNavegacao(totalNavegacao);
  }, [handleRecuperarFormulario, handleSetPosicaoNavegacao, totalNavegacao]);

  return (
    <NavegacaoProvider
      value={{
        totalNavegacao,
        posicaoNavegacao,
        mostrarNavegacao,
        handleAnterior,
        handlePrimeiro,
        handleProximo,
        handleUltimo,
      }}
    >
      {children}
    </NavegacaoProvider>
  );
};

export { ItemNavegacaoHook };
