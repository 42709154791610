import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import ParametroValorComunicador from '../Comunicador/Configuracao/Parametro/Comunicador/ParametroValorComunicador';
import IParametrosContextData from '../Paginas/Configuracao/Parametro/Detalhe/Tipos/Interface/IParametrosContextData';
import TratarErros from '../Util/Erro/TratarErros';
import { usuarioLogado } from './Auth';

const ParametrosContext = createContext<IParametrosContextData>(
  {} as IParametrosContextData
);

const ParametrosProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [parametros, setParametros] = useState<IParametrosContextData | null>(
    null
  );

  const carregarParametros = useCallback(async () => {
    try {
      const usuario = usuarioLogado as any;
      if (!usuario || !usuario.id) return;

      const response = await ParametroValorComunicador.index();
      setParametros(response);
    } catch (error) {
      TratarErros(error);
    }
  }, []);

  useEffect(() => {
    carregarParametros();
  }, [carregarParametros]);

  return (
    <ParametrosContext.Provider value={{ ...parametros, carregarParametros }}>
      {children}
    </ParametrosContext.Provider>
  );
};

function UseParametros(): IParametrosContextData {
  const context = useContext(ParametrosContext);

  if (!context) {
    throw new Error('UseParametros must be used within an ParametrosProvider');
  }

  return context;
}

export { ParametrosProvider, UseParametros };
