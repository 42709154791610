"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = GroupBy;
function GroupBy({ dados, nomeChaveDados = 'valor', getChave, formatarItem = (item) => item, }) {
    const obj = dados.reduce((acumulador, item) => {
        const groupByVal = getChave(item);
        const groupedItems = (acumulador[groupByVal] || []);
        const itemFormatado = formatarItem(item, groupByVal);
        groupedItems.push(itemFormatado);
        acumulador[groupByVal] = groupedItems;
        return acumulador;
    }, {});
    return Object.keys(obj).map((chave) => ({
        chave,
        [nomeChaveDados]: obj[chave],
    }));
}
