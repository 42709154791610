import React, { useEffect } from 'react';
import InputAutoCompleteProduto from '../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import ITipoDados from '../../../../../Main/Interface/ITipoDados';

const AutoCompleteProduto: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return <InputAutoCompleteProduto name={nome} permitirAdicionar={false} />;
};

export default AutoCompleteProduto;
