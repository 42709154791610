import api from '../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/ordem-servico/obter-conta';

interface IOrdemServicoObterContaComunicadorShow {
  id: string;
}

class OrdemServicoObterContaComunicador {
  public async show(
    params: IOrdemServicoObterContaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoObterContaComunicador();
