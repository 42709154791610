import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react';
import { INotaFiscalEntradaItemDuplicarLista } from '@elogestor/util';
import { v4 } from 'uuid';
import { CheckSwitch } from '../../../../../../../../Componentes/Switch/styles';
import InputTabelaDecimal from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';

interface IItemDuplicar {
  item: INotaFiscalEntradaItemDuplicarLista;
  onChange: () => void;
  onKeyDownQuantidade?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface IItemDuplicarRef {
  setSelecionado(valor: boolean): void;
  handleObterValores(): INotaFiscalEntradaItemDuplicarLista;
  handleSetFocusQuantidade(): void;
}

const ItemDuplicar: React.ForwardRefRenderFunction<
  IItemDuplicarRef,
  IItemDuplicar
> = ({ item, onChange, onKeyDownQuantidade }, ref) => {
  const selecionadoRef = useRef<HTMLInputElement>(null);
  const quantidadeRef = useRef<HTMLInputElement>(null);
  const valorTotalDivRef = useRef<HTMLDivElement>(null);
  const movimentarEstoqueRef = useRef<HTMLInputElement>(null);

  const valorTotalProduto = useRef(item.valorTotalProduto);

  const [bloquearReservarEstoque, setBloquearReservarEstoque] =
    useState<boolean>(false);

  const handleObterValores =
    useCallback((): INotaFiscalEntradaItemDuplicarLista => {
      const quantidade = quantidadeRef.current?.value.ConverterParaNumber();

      item.selecionado = !!selecionadoRef.current?.checked;
      item.quantidade = Number(quantidade);
      item.valorTotalProduto = valorTotalProduto.current;
      item.notaFiscalEntradaItemEstoque = {
        movimentarEstoque: !!movimentarEstoqueRef.current?.checked,
      };

      return {
        ...item,
      };
    }, [item]);

  const setSelecionado = useCallback((valor: boolean) => {
    if (selecionadoRef.current) {
      selecionadoRef.current.checked = valor;
    }
  }, []);

  const handleSetFocusQuantidade = useCallback(() => {
    if (quantidadeRef.current) {
      quantidadeRef.current.focus();
    }
  }, []);

  const handleOnKeyDownQuantidade = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDownQuantidade) onKeyDownQuantidade(event);
    },
    [onKeyDownQuantidade]
  );

  const setValorTotalProduto = useCallback((valor: number) => {
    if (valorTotalProduto.current && valorTotalDivRef.current) {
      valorTotalProduto.current = valor;
      valorTotalDivRef.current.innerHTML = valor.FormatarParaPtBr();
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleObterValores,
      setSelecionado,
      handleSetFocusQuantidade,
    };
  });

  const handleAlterarSelecionado = useCallback(
    (valor: boolean) => {
      setSelecionado(valor);

      if (onChange) onChange();
    },
    [onChange, setSelecionado]
  );

  const handleAlterarQuantidade = useCallback(
    (valor: string) => {
      const valorTotal = valor.ConverterParaNumber() * item.valorUnitario;
      setValorTotalProduto(valorTotal);

      if (onChange) onChange();
    },
    [item.valorUnitario, onChange, setValorTotalProduto]
  );

  return (
    <tr key={v4()}>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <input
          type="checkbox"
          defaultChecked={item.selecionado}
          ref={selecionadoRef}
          onChange={(event) => {
            item.selecionado = event.target.checked;
            handleAlterarSelecionado(event.target.checked);
          }}
        />
      </td>
      <td className="lista-texto">
        <div>{item.produto.codigo}</div>
      </td>
      <td className="lista-texto">
        <div>{item.produto.descricao}</div>
      </td>
      <td className="lista-texto">
        <div>{item.unidadeMedida.sigla}</div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <div>
          <InputTabelaDecimal
            valorPadrao={item.quantidade}
            valueRef={quantidadeRef}
            casasDecimais={4}
            onBlur={(event) => {
              handleAlterarQuantidade(event.target.value);
            }}
            onKeyDown={handleOnKeyDownQuantidade}
          />
        </div>
      </td>
      <td className="lista-valor">
        <div>
          {Number(item.valorUnitario).FormatarParaPtBr({
            minimoCasasDecimais: 10,
            maximoCasasDecimais: 10,
          })}
        </div>
      </td>
      <td className="lista-valor">
        <div ref={valorTotalDivRef}>
          {Number(item.valorTotalProduto).FormatarParaPtBr()}
        </div>
      </td>
      <td className="lista-texto">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CheckSwitch
            $ativo="Sim"
            $inativo="Não"
            $largura={70}
            style={{ width: 70 }}
          >
            <input
              type="checkbox"
              ref={movimentarEstoqueRef}
              defaultChecked={
                item.notaFiscalEntradaItemEstoque.movimentarEstoque
              }
              disabled={bloquearReservarEstoque}
            />
            <div />
          </CheckSwitch>
        </div>
      </td>
    </tr>
  );
};

export default forwardRef(ItemDuplicar);
