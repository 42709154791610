import React from 'react';

type IBtnDuplicarSalvar = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const BtnDuplicarSalvar: React.FC<IBtnDuplicarSalvar> = ({
  children,
  ...rest
}) => {
  return (
    <button type="button" className="btn-padrao btn-azul-claro" {...rest}>
      {children}
    </button>
  );
};

export default BtnDuplicarSalvar;
