import { INotaFiscalEntradaEtiquetaImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import NotaFiscalEntradaEtiquetaImpressaoPDF from './NotaFiscalEntradaEtiquetaImpressaoPDF';

interface IImpressaoNotaFiscalEntradaEtiqueta {
  handleVisualizarPdf(data: INotaFiscalEntradaEtiquetaImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: INotaFiscalEntradaEtiquetaImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoNotaFiscalEntradaEtiqueta =
  (): IImpressaoNotaFiscalEntradaEtiqueta => {
    async function handleVisualizarPdf(
      dados: INotaFiscalEntradaEtiquetaImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: <NotaFiscalEntradaEtiquetaImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: INotaFiscalEntradaEtiquetaImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <NotaFiscalEntradaEtiquetaImpressaoPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoNotaFiscalEntradaEtiqueta();
