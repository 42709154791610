import React, { useEffect, useRef, useState } from 'react';
import { IUsuarioMenuPermissaoValoresAlterar } from '@elogestor/util';
import { Table } from 'react-bootstrap';
import { UseFieldCia } from '@elogestor/unformcia';
import ListaMenuComponent, {
  IListaMenuComponentRef,
} from './ListaMenuComponent';
import { TBody } from '../../../../../../Componentes/Styles/TBody';
import { Tabela } from '../../../../../../Componentes/Tabela/styles';

interface IInputPermissoesProps {
  name: string;
  validarSeMudou?: (ref: any, valorInicial: any) => boolean;
}

const InputPermissoes: React.FC<IInputPermissoesProps> = ({
  name,
  validarSeMudou,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const [listaMenu, setListaMenu] = useState<
    IUsuarioMenuPermissaoValoresAlterar[]
  >([]);
  const listaMenuRef = useRef<IListaMenuComponentRef | null>(null);

  useEffect(() => {
    registerField<IUsuarioMenuPermissaoValoresAlterar[]>({
      name: fieldName,
      ref: listaMenuRef.current,

      getValue() {
        return listaMenuRef.current?.mostrarValores() || [];
      },

      setValue(_, value) {
        setListaMenu(value || []);
      },

      setSemExecutarEvento(_, value) {
        setListaMenu(value || []);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou(_, valorInicial) {
        if (validarSeMudou) return validarSeMudou(_, valorInicial);

        return this.getValue(_) !== valorInicial;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabela style={{ overflow: 'auto' }}>
      <Table striped hover bordered variant="light">
        <thead>
          <tr>
            <th>
              <span>Descricao</span>
            </th>
            <th>
              <span>Acessa</span>
            </th>
            <th>
              <span>Inclui</span>
            </th>
            <th>
              <span>Altera</span>
            </th>
            <th>
              <span>Exclui</span>
            </th>
          </tr>
        </thead>

        <TBody>
          <ListaMenuComponent
            listaMenu={listaMenu}
            ref={(instace) => {
              if (instace) {
                listaMenuRef.current = instace;
              }
            }}
          />
        </TBody>
      </Table>
    </Tabela>
  );
};

export default InputPermissoes;
