"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var DestinadoEnum;
(function (DestinadoEnum) {
    DestinadoEnum["industrializacao"] = "Industrializa\u00E7\u00E3o";
    DestinadoEnum["comercializacao"] = "Comercializa\u00E7\u00E3o";
    DestinadoEnum["usoeConsumo"] = "Uso e Consumo";
    DestinadoEnum["ativoImobilizado"] = "Ativo Imobilizado";
})(DestinadoEnum || (DestinadoEnum = {}));
exports.default = DestinadoEnum;
