"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsCteCalculoPorCst;
function IcmsCteCalculoPorCst({ valorTotal = null, cst, calcular = false, percentualReducaoBaseCalculo = null, baseCalculo = null, aliquota = null, valor = null, }) {
    const retorno = {
        calcularIcms: false,
        aliquota: null,
        baseCalculo: null,
        percentualReducaoBaseCalculo: null,
        valor: null,
    };
    if (calcular) {
        if (!valorTotal || !aliquota) {
            retorno.aliquota = aliquota;
            retorno.calcularIcms = calcular;
            retorno.baseCalculo = valorTotal || baseCalculo;
            retorno.percentualReducaoBaseCalculo = percentualReducaoBaseCalculo;
            retorno.valor = 0;
            return retorno;
        }
        if (cst === '00' || cst === '20' || cst === '90' || cst === '900') {
            let baseCalculada = valorTotal;
            if (cst === '00') {
                percentualReducaoBaseCalculo = null;
            }
            if (percentualReducaoBaseCalculo) {
                baseCalculada =
                    valorTotal - valorTotal * (percentualReducaoBaseCalculo / 100);
            }
            retorno.calcularIcms = calcular;
            retorno.baseCalculo = baseCalculada;
            retorno.aliquota = aliquota;
            retorno.valor = baseCalculada * (aliquota / 100);
            retorno.percentualReducaoBaseCalculo = percentualReducaoBaseCalculo;
            return retorno;
        }
    }
    else if (cst === '40' ||
        cst === '41' ||
        cst === '45' ||
        cst === '51' ||
        cst === '60' ||
        !cst) {
        retorno.aliquota = null;
        retorno.calcularIcms = false;
        retorno.baseCalculo = null;
        retorno.percentualReducaoBaseCalculo = null;
        retorno.valor = null;
    }
    else {
        retorno.aliquota = aliquota;
        retorno.calcularIcms = false;
        retorno.baseCalculo = baseCalculo;
        retorno.percentualReducaoBaseCalculo = percentualReducaoBaseCalculo;
        retorno.valor = valor;
    }
    return retorno;
}
