import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/veiculo/motorista';

interface IVeiculoMotoristaOrdemComunicadorUpdate {
  id: string;
  params: any;
}

class VeiculoMotoristaOrdemComunicador {
  public async update(
    params: IVeiculoMotoristaOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}/ordem`, params.params);

    return response.data;
  }
}

export default new VeiculoMotoristaOrdemComunicador();
