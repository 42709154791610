import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/ramo-atividade-profissao';

interface IRamoAtividadeProfissaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRamoAtividadeProfissaoComunicadorShow {
  id: string;
}

interface IRamoAtividadeProfissaoComunicadorStore {
  params: any;
}

interface IRamoAtividadeProfissaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IRamoAtividadeProfissaoComunicadorDeletar {
  id: string;
}

class RamoAtividadeProfissaoComunicador {
  public async index(
    params: IRamoAtividadeProfissaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRamoAtividadeProfissaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRamoAtividadeProfissaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRamoAtividadeProfissaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRamoAtividadeProfissaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RamoAtividadeProfissaoComunicador();
