import api from '../../../../../Comum/Services/Api';

const rota = '/configuracao/etiqueta/etiqueta-auto-complete';

interface IEtiquetaAutoCompleteComunicadorIndex {
  params: any;
}

class EtiquetaAutoCompleteComunicador {
  public async index(
    params: IEtiquetaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new EtiquetaAutoCompleteComunicador();
