import ToastSucesso from '../Toasts/ToastSucesso';

export default function TratarSucesso(listaSucesso: string[]): void {
  if (listaSucesso.length > 0) {
    for (let i = 0; i < listaSucesso.length; i += 1) {
      const mensagem = listaSucesso[i];
      ToastSucesso(mensagem, { autoClose: 4000 + i * 1000 });
    }
  }
}
