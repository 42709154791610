import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/impressao-etiquetas-produtos/obter-valor-unitario-venda';

interface IImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicadorIndex {
  params: any;
}

class ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador {
  public async index(
    params: IImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador();
