import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IEstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IEstoqueSaldoComCustoConferenciaBlocoHComNotasPDF {
  dados: IEstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const EstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioPDF: React.FC<
  IEstoqueSaldoComCustoConferenciaBlocoHComNotasPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoCusto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Custo: '}
                      </Text>
                      {dados.cabecalho.filtroTipoCusto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRegimeTributario && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Regime Tributario: '}
                      </Text>
                      {dados.cabecalho.filtroRegimeTributario}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroLocalEstoque && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Local de Estoque: '}
                      </Text>
                      {dados.cabecalho.filtroLocalEstoque}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoOrdenacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ordenação: '}
                      </Text>
                      {dados.cabecalho.filtroTipoOrdenacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroFormatoGeracaoValoresH20 && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Formato Geraçäo Valores H20: '}
                      </Text>
                      {dados.cabecalho.filtroFormatoGeracaoValoresH20}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Somente Produtos com Saldo
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '7%' }}>
              <Text>Código</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '21%' }}>
              <Text>Produto</Text>
            </View>

            <View style={{ width: '3%' }} />

            <View style={{ width: '5%' }}>
              <Text>Unidade</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Saldo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Unit. Custo ICMS IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Custo Est. ICMS IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Unit. Custo IR</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Custo Est. IR</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%' }}>
              <Text>Tipo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '19%' }}>
              <Text>Terceiro</Text>
            </View>
          </View>

          {dados.listaDados.map((dado) => {
            return (
              <View>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    marginBottom: '5pt',
                    marginTop: '5pt',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>
                    {`Tipo de Sped: ${dado.chave} `}
                  </Text>
                </View>

                {dado.listaSped.map((item, i) => (
                  <View>
                    <View
                      key={i}
                      style={{
                        flexDirection: 'row',
                        backgroundColor: i % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ width: '7%' }}>
                        <Text>
                          {item.estoqueCombinacao.produto.codigo.InserirEspacoRepetindoPorNumeroCaracteres(
                            17
                          )}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '21%' }}>
                        <Text>{item.estoqueCombinacao.produto.descricao}</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '5%' }}>
                        <Text>
                          {
                            item.estoqueCombinacao.produto.produtoEstoque
                              .unidadeMedida.sigla
                          }
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '10%', alignItems: 'flex-end' }}>
                        <Text>{item.saldo}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(
                            item.valorUnitarioCustoIcmsIpiCalculado
                          ).FormatarParaPtBr({
                            minimoCasasDecimais: 4,
                            maximoCasasDecimais: 4,
                          })}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%', alignItems: 'flex-end' }}>
                        <Text>
                          {String(item.valorCustoEstoqueICMSIPI)
                            .ConverterParaNumber()
                            .FormatarParaPtBr({
                              minimoCasasDecimais: 2,
                              maximoCasasDecimais: 2,
                            })}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(
                            item.valorUnitarioCustoIrCalculado
                          ).FormatarParaPtBr({
                            minimoCasasDecimais: 4,
                            maximoCasasDecimais: 4,
                          })}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '5%', alignItems: 'flex-end' }}>
                        <Text>
                          {String(item.valorCustoEstoqueIR)
                            .ConverterParaNumber()
                            .FormatarParaPtBr({
                              minimoCasasDecimais: 2,
                              maximoCasasDecimais: 2,
                            })}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>
                          {item.estoqueCombinacao.estoqueIndicadorTipo}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '19%' }}>
                        <Text>
                          {item.estoqueCombinacao.pessoa.codigo &&
                          item.estoqueCombinacao.pessoa.nomeRazaoSocial
                            ? `${item.estoqueCombinacao.pessoa.codigo} - ${item.estoqueCombinacao.pessoa.nomeRazaoSocial}`
                            : ''}
                        </Text>
                      </View>
                    </View>

                    {item.listaNotas && item.listaNotas?.length > 0 && (
                      <View
                        style={{
                          marginBottom: '15pt',
                          borderBottom: '1px dashed #858585',
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: i % 2 === 0 ? '#E4E4E4' : '#fff',
                            padding: '10pt',
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              fontWeight: 'bold',
                            }}
                          >
                            <View style={{ width: '10%' }} />

                            <View style={{ width: '10%' }}>
                              <Text>Número NF</Text>
                            </View>

                            <View style={{ width: '1%' }} />

                            <View style={{ width: '10%' }}>
                              <Text>Data</Text>
                            </View>

                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>CST</Text>
                            </View>

                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>QTD</Text>
                            </View>

                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>B.C. ICMS</Text>
                            </View>

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>Valor ICMS</Text>
                            </View>

                            <View style={{ width: '1%' }} />

                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>B.C IPI</Text>
                            </View>

                            <View
                              style={{ width: '10%', alignItems: 'flex-end' }}
                            >
                              <Text>Valor IPI</Text>
                            </View>

                            <View style={{ width: '1%' }} />
                          </View>

                          {item.listaNotas.map((nota, j) => (
                            <View
                              key={j}
                              style={{
                                flexDirection: 'row',
                                fontWeight: j % 2 === 0 ? 'normal' : 'bold',
                              }}
                            >
                              <View style={{ width: '10%' }} />

                              <View style={{ width: '10%' }}>
                                <Text>{nota.numero}</Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View style={{ width: '10%' }}>
                                <Text>{FormatarDataParaPtBr(nota.data)}</Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '10%', alignItems: 'flex-end' }}
                              >
                                <Text>{String(nota.cstIcms)}</Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '10%', alignItems: 'flex-end' }}
                              >
                                <Text>
                                  {String(nota.quantidade)
                                    .ConverterParaNumber()
                                    .FormatarParaPtBr({
                                      minimoCasasDecimais: 6,
                                      maximoCasasDecimais: 6,
                                    })}
                                </Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '10%', alignItems: 'flex-end' }}
                              >
                                <Text>
                                  {String(nota.baseCalculoIcms)
                                    .ConverterParaNumber()
                                    .FormatarParaPtBr({
                                      minimoCasasDecimais: 2,
                                      maximoCasasDecimais: 2,
                                    })}
                                </Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View
                                style={{
                                  width: '10%',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Text>
                                  {String(nota.valorIcms)
                                    .ConverterParaNumber()
                                    .FormatarParaPtBr({
                                      minimoCasasDecimais: 2,
                                      maximoCasasDecimais: 2,
                                    })}
                                </Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '10%', alignItems: 'flex-end' }}
                              >
                                <Text>
                                  {String(nota.baseCalculoIpi)
                                    .ConverterParaNumber()
                                    .FormatarParaPtBr({
                                      minimoCasasDecimais: 2,
                                      maximoCasasDecimais: 2,
                                    })}
                                </Text>
                              </View>

                              <View style={{ width: '1%' }} />

                              <View
                                style={{ width: '10%', alignItems: 'flex-end' }}
                              >
                                <Text>
                                  {String(nota.valorIpi)
                                    .ConverterParaNumber()
                                    .FormatarParaPtBr({
                                      minimoCasasDecimais: 2,
                                      maximoCasasDecimais: 2,
                                    })}
                                </Text>
                              </View>
                            </View>
                          ))}

                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginTop: 10,
                              fontWeight: 'bold',
                            }}
                          >
                            <View style={{ width: '65%' }} />
                            <View
                              style={{
                                width: '10%',
                                alignItems: 'flex-end',
                                borderTop: '1px dashed black',
                              }}
                            >
                              <Text>{item.somatorioTotalNotasIcms}</Text>
                            </View>
                            <View style={{ width: '12%' }} />
                            <View
                              style={{
                                width: '10%',
                                alignItems: 'flex-end',
                                borderTop: '1px dashed black',
                              }}
                            >
                              <Text>{item.somatorioTotalNotasIpi}</Text>
                            </View>
                            <View style={{ width: '1%' }} />
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                ))}

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'flex-start',
                    marginBottom: '5pt',
                    marginTop: '5pt',
                    borderBottom: '1px dashed black',
                  }}
                >
                  <View style={{ width: '55%' }}>
                    <Text style={{ fontWeight: 'bold' }}>
                      Total Por Tipo Sped
                    </Text>
                  </View>
                  <View
                    style={{
                      alignItems: 'flex-start',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                      }}
                    >{`${String(dado.somatorioItensICMSIPI)
                      .ConverterParaNumber()
                      .FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })}`}</Text>
                  </View>
                  <View style={{ width: '7%' }} />
                  <View>
                    <Text
                      style={{
                        fontWeight: 'bold',
                      }}
                    >{`${String(dado.somatorioItensIR)
                      .ConverterParaNumber()
                      .FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })}`}</Text>
                  </View>
                  <View style={{ width: '29%' }} />
                </View>
              </View>
            );
          })}

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-start',
              marginBottom: '5pt',
              marginTop: '5pt',
            }}
          >
            <View style={{ width: '55%' }}>
              <Text style={{ fontWeight: 'bold' }}>Total Geral</Text>
            </View>
            <View style={{ alignItems: 'flex-start' }}>
              <Text style={{ fontWeight: 'bold' }}>
                {`${String(dados.totais.totalGeralICMSIPI)
                  .ConverterParaNumber()
                  .FormatarParaPtBr({
                    minimoCasasDecimais: 2,
                    maximoCasasDecimais: 2,
                  })}`}
              </Text>
            </View>
            <View style={{ width: '7%' }} />
            <View>
              <Text style={{ fontWeight: 'bold' }}>{`${String(
                dados.totais.totalGeralIR
              )
                .ConverterParaNumber()
                .FormatarParaPtBr({
                  minimoCasasDecimais: 2,
                  maximoCasasDecimais: 2,
                })}`}</Text>
            </View>
            <View style={{ width: '29%' }} />
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%' }}>
              <Text>Total Geral ICMS: </Text>
            </View>
            <View style={{ width: '5%', textAlign: 'right' }}>
              <Text>{dados.totais.totalGeralNotasIcms}</Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%' }}>
              <Text>Total Geral IPI: </Text>
            </View>
            <View style={{ width: '5%', textAlign: 'right' }}>
              <Text>{dados.totais.totalGeralNotasIpi}</Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default EstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioPDF;
