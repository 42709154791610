"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarRemovendoEnum;
function FormatarRemovendoEnum({ enumObj, enumRemover, nullavel, todos, }) {
    const enumFormatado = [];
    if (nullavel)
        enumFormatado.push({ title: '', value: '' });
    if (todos)
        enumFormatado.push({ title: 'Todos', value: '' });
    for (const k of Object.keys(enumObj)) {
        const value = enumObj[k];
        if (enumRemover && !enumRemover.includes(value)) {
            enumFormatado.push({ title: enumObj[k], value });
        }
    }
    return enumFormatado;
}
