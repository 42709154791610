import {
  SituacaoConhecimentoTransporteEnum,
  FormatarEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../Componentes/Select';
import { Modal } from './styles';
import Divisor from '../../../../Componentes/Divisor';
import InputAutoCompleteTagSerie from '../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputInteiroNulavel from '../../../../Componentes/Inputs/InputInteiroNulavel';
import InputPeriodoData from '../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagPessoa from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataEmissaoRef = useRef<any>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaSerie = parametros.pesquisaAvancada?.listaSerie;
      if (listaSerie.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Séries',
          valor: listaSerie.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }
      const listaRemetentes = parametros.pesquisaAvancada?.listaRemetentes;
      if (listaRemetentes.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaRemetentes.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaExpedidores = parametros.pesquisaAvancada?.listaExpedidores;
      if (listaExpedidores.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Expedidores',
          valor: listaExpedidores.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaRecebedores = parametros.pesquisaAvancada?.listaRecebedores;
      if (listaRecebedores.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Recebedores',
          valor: listaRecebedores.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaDestinatarios =
        parametros.pesquisaAvancada?.listaDestinatarios;
      if (listaDestinatarios.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Destinatários',
          valor: listaDestinatarios.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaTomadoresOutros =
        parametros.pesquisaAvancada?.listaTomadoresOutros;
      if (listaTomadoresOutros.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tomadores Outros',
          valor: listaTomadoresOutros.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
              return acumulador;
            }
          ),
        });
      }

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const situacao = parametros.pesquisaAvancada?.situacao;
      if (!situacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: situacao,
        });
      }

      const chaveAcessoNFe = parametros.pesquisaAvancada?.chaveAcessoNFe;
      if (chaveAcessoNFe) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Chave de acesso NF-e',
          valor: chaveAcessoNFe,
        });
      }

      const chaveAcessoCTe = parametros.pesquisaAvancada?.chaveAcessoCTe;
      if (chaveAcessoCTe) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Chave de acesso CT-e',
          valor: chaveAcessoCTe,
        });
      }

      const periodoDataEmissao = periodoDataEmissaoRef.current?.getPeriodo();
      if (periodoDataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Emissão',
          valor: periodoDataEmissao,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagSerie label="Séries" name="listaSerie" />
              </Col>
              <Col lg={6} md={12}>
                <InputInteiroNulavel name="numero" label="Número" />
              </Col>

              <Col lg={12}>
                <Select
                  label="Situação"
                  name="situacao"
                  options={FormatarEnum({
                    enumObj: SituacaoConhecimentoTransporteEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={12} md={12}>
                <Input
                  name="chaveAcessoCTe"
                  label="Chave de acesso CT-e"
                  maxLength={44}
                  type="text"
                />
              </Col>

              <Col lg={12} md={12}>
                <Input
                  name="chaveAcessoNFe"
                  label="Chave de acesso NF-e"
                  maxLength={44}
                  type="text"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Remetentes"
                  name="listaRemetentes"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Expedidores"
                  name="listaExpedidores"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Recebedores"
                  name="listaRecebedores"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Destinatários"
                  name="listaDestinatarios"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Tomadores Outros"
                  name="listaTomadoresOutros"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataInicialEmissao"
                  nomeDataFinal="dataFinalEmissao"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
