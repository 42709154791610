import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/aprovar';

interface IOrcamentoAprovarComunicadorUpdate {
  id: string;
  params: any;
}

class OrcamentoAprovarComunicador {
  public async update(
    params: IOrcamentoAprovarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new OrcamentoAprovarComunicador();
