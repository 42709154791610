import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/item/impostos-fiscais';

interface INotaFiscalEntradaItemImpostoFiscalComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaItemImpostoFiscalComunicadorUpdate {
  id: string;
  params: any;
}

class NotaFiscalEntradaItemImpostoFiscalComunicador {
  public async show(
    params: INotaFiscalEntradaItemImpostoFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaItemImpostoFiscalComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaItemImpostoFiscalComunicador();
