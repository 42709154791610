import React from 'react';
import { ComunicadorProvider } from '../../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import MovimentacaoEntreProdutosComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/MovimentacaoEntreProdutos/Comunicador/MovimentacaoEntreProdutosComunicador';
import { CustosHook } from './CustosHook';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const MovimentacaoEntreProdutosHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: MovimentacaoEntreProdutosComunicador }}
    >
      <RotasHook>
        <FormHook>
          <SubHeaderHook>
            <CustosHook>{children}</CustosHook>
          </SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default MovimentacaoEntreProdutosHook;
