import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  FormatarEnum,
  IndicadorTipoTituloSpedEnum,
  MeioPagamentoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import Input from '../../../../../../Componentes/Inputs/Input';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Select from '../../../../../../Componentes/Select';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';

const GeralTab: React.FC = () => {
  const { formRef, inputRefFocus, refresh } = UseForm();

  const isPadrao = formRef.current?.getFieldValue('padraoSistema');

  const { abrirJanela } = UseConfirmacao();

  const sugerirPagamentoTef = useCallback(async () => {
    const meioPagamentoSelecionado =
      formRef.current?.getFieldValue('meioPagamento');

    const meiosPagamentoAceitosTef = [
      MeioPagamentoEnum.cartaoCredito,
      MeioPagamentoEnum.cartaoDebito,
      MeioPagamentoEnum.pagamentoInstantaneoPix,
    ];

    if (meiosPagamentoAceitosTef.includes(meioPagamentoSelecionado)) {
      const respostaUsuario = await abrirJanela({
        titulo: <h2>Sugestão de Pagamento TEF</h2>,
        mensagem: (
          <span>
            A forma de pagamento selecionada possibilita o pagamento via TEF.
            {'\n'}
            Gostaria de ativá-lo?
          </span>
        ),
        confimar: 'Sim',
        cancelar: 'Näo',
      });

      if (respostaUsuario) {
        formRef.current?.setFieldValue('pagamentoIntegradoTef', true);
      } else {
        formRef.current?.setFieldValue('pagamentoIntegradoTef', false);
      }
    }
  }, [abrirJanela, formRef]);

  const sugerirPermiteGerarParcelas = useCallback(async () => {
    const opcaoPermiteGerarParcelas = formRef.current?.getFieldValue(
      'permiteGerarParcelas'
    );

    if (isPadrao === false) return;

    const meioPagamentoSelecionado =
      formRef.current?.getFieldValue('meioPagamento');

    const meiosPagamentoPermiteGerarParcelas = [
      MeioPagamentoEnum.cartaoCredito,
      MeioPagamentoEnum.creditoLoja,
      MeioPagamentoEnum.boletoBancario,
      MeioPagamentoEnum.depositoBancario,
      MeioPagamentoEnum.pagamentoInstantaneoPix,
    ];

    if (meiosPagamentoPermiteGerarParcelas.includes(meioPagamentoSelecionado)) {
      formRef.current?.setFieldValue('permiteGerarParcelas', true);
    } else {
      formRef.current?.setFieldValue('permiteGerarParcelas', false);
    }
  }, [formRef, isPadrao]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="padraoSistema" />

          <Col lg={8} md={12} sm={12}>
            <Input
              ref={inputRefFocus}
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              maxLength={100}
              disabled={isPadrao}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={5} md={12} sm={12}>
            <Select
              label="Meio de Pagamento"
              name="meioPagamento"
              options={FormatarEnum({
                enumObj: MeioPagamentoEnum,
                nullavel: true,
              })}
              onChange={() => {
                refresh();
                sugerirPagamentoTef();
                sugerirPermiteGerarParcelas();
              }}
              disabled={isPadrao}
            />
          </Col>

          <Col lg={3} md={12} sm={12}>
            <Select
              label="Indicador do tipo de título (SPED)"
              name="tipoTituloSped"
              options={FormatarEnum({
                enumObj: IndicadorTipoTituloSpedEnum,
                nullavel: true,
              })}
              disabled={isPadrao}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                label="Forma de Pagamento das Parcelas nas Informações do Contribuinte Nos Documentos Fiscais"
                name="formaPagamentoParcelasNasInformacoesContribuinte"
                disabled={isPadrao}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                label="Diário Auxiliar"
                name="diarioAuxiliar"
                disabled={isPadrao}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                label="Disponível no Frente Caixa/PDV"
                name="listaFormaPagamentoEmpresa[0].disponivelFrenteCaixaPDV"
                disabled={isPadrao}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                label="Permite Gerar Parcelas no Frente Caixa/PDV"
                name="permiteGerarParcelas"
                disabled={isPadrao}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="checkbox-container">
              <InputCheckbox
                label="Pagamento Integrado ao TEF"
                name="pagamentoIntegradoTef"
                disabled={isPadrao}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputSwitch
              name="listaFormaPagamentoEmpresa[0].ativo"
              label="Ativo"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default GeralTab;
