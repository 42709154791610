import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-numero';

interface INotaFiscalSaidaObterNumeroComunicadorShow {
  idNotaFiscalSaida: string;
  idSerie: string;
}

class NotaFiscalSaidaObterNumeroComunicador {
  public async show(
    params: INotaFiscalSaidaObterNumeroComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });

    return response.data;
  }
}

export default new NotaFiscalSaidaObterNumeroComunicador();
