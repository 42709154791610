"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegistroC100IndicadorTipoPagamentoEnum;
(function (RegistroC100IndicadorTipoPagamentoEnum) {
    RegistroC100IndicadorTipoPagamentoEnum[RegistroC100IndicadorTipoPagamentoEnum["vista"] = 0] = "vista";
    RegistroC100IndicadorTipoPagamentoEnum[RegistroC100IndicadorTipoPagamentoEnum["prazo"] = 1] = "prazo";
    RegistroC100IndicadorTipoPagamentoEnum[RegistroC100IndicadorTipoPagamentoEnum["outros"] = 2] = "outros";
})(RegistroC100IndicadorTipoPagamentoEnum || (RegistroC100IndicadorTipoPagamentoEnum = {}));
exports.default = RegistroC100IndicadorTipoPagamentoEnum;
