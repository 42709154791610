import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/inutilizacao-numeracao/salvar-importado';

interface IImportacaoXmlInutilizacaoSalvarImportadoComunicadorStore {
  params: any;
}

class ImportacaoXmlInutilizacaoSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlInutilizacaoSalvarImportadoComunicadorStore
  ): Promise<{
    id: string;
    listaValor: string[];
    listaMensagemSucesso: string[];
    listaMensagemAviso: string[];
    listaMensagemErro: string[];
  }> {
    const { params: body } = params;

    const response = await api.post(rota, body);

    return response.data;
  }
}

export default new ImportacaoXmlInutilizacaoSalvarImportadoComunicador();
