import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/servico-auto-complete';

interface IServicoAutoCompleteComunicadorIndex {
  params: any;
}

class ServicoAutoCompleteComunicador {
  public async index(
    params: IServicoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ServicoAutoCompleteComunicador();
