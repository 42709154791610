import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-cst/log';

interface IRegraEscolhaCstLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRegraEscolhaCstLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RegraEscolhaCstLogComunicador {
  public async index(
    params: IRegraEscolhaCstLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCstLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RegraEscolhaCstLogComunicador();
