/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { Container } from './styles';

export interface IOnChangeInputVisualSemValorProps {
  valorAnterior: string;
}

export interface IInputVisualSemValorRef {
  focus(): void;
}

interface IInputVisualSemValorProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputVisualSemValorProps
  ) => void | Promise<void>;
}

const InputVisualSemValor: React.ForwardRefRenderFunction<
  IInputVisualSemValorRef,
  IInputVisualSemValorProps
> = ({ name, label, onChange, ...rest }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useEffect(() => {
    registerField<string | undefined>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return undefined;
      },

      getDataDuplicar() {
        return inputRef.current?.value || '';
      },

      getDataRecuperarFormulario() {
        return inputRef.current?.value || '';
      },

      setValue(refInput, val = '') {
        valorAnterior.current = refInput.value;
        if (!val) {
          refInput.value = '';
        } else {
          refInput.value = val;
        }
      },

      setSemExecutarEvento(refInput, val = '') {
        valorAnterior.current = refInput.value;
        if (!val) {
          refInput.value = '';
        } else {
          refInput.value = val;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou() {
        return false;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  return (
    <Container>
      {label && <label>{label}</label>}
      <input
        type="text"
        onChange={(event) => {
          if (onChange)
            onChange(event, { valorAnterior: valorAnterior.current });
        }}
        {...rest}
        ref={inputRef}
      />
    </Container>
  );
};

export default forwardRef(InputVisualSemValor);
