import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/cancelamento-carta-correcao/upload-xml';

interface IImportacaoXmlCancelamentoCartaCorrecaoUploadComunicadorUpdate {
  params: any;
}

class ImportacaoXmlCancelamentoCartaCorrecaoUploadComunicador {
  public async update(
    params: IImportacaoXmlCancelamentoCartaCorrecaoUploadComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlCancelamentoCartaCorrecaoUploadComunicador();
