import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/configurador/config-caracteristicas-produto';

interface ICaracteristicaProdutoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ICaracteristicaProdutoComunicadorShow {
  id: string;
}

interface ICaracteristicaProdutoComunicadorStore {
  params: any;
}

interface ICaracteristicaProdutoComunicadorUpdate {
  id: string;
  params: any;
}

interface ICaracteristicaProdutoComunicadorDeletar {
  id: string;
}

class CaracteristicaProdutoComunicador {
  public async index(
    params: ICaracteristicaProdutoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ICaracteristicaProdutoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: ICaracteristicaProdutoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: ICaracteristicaProdutoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: ICaracteristicaProdutoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CaracteristicaProdutoComunicador();
