import api from '../../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/conta-contabil-auto-complete';

interface IContaContabiAutoCompleteComunicadorIndex {
  params: any;
}

class ContaContabiAutoCompleteComunicador {
  public async index(
    params: IContaContabiAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ContaContabiAutoCompleteComunicador();
