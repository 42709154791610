import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/importar';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicador();
