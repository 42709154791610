import React from 'react';
import RotasHook from './RotasHook';
import FormHook from './FormHook';
import { ListaOrdemDesmontagemItemDraggableHook } from './ListaOrdemDesmontagemItemDraggableHook';
import { ItemNavegacaoHook } from './ItemNavegacaoHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const ItemHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <ListaOrdemDesmontagemItemDraggableHook>
        <FormHook>
          <ItemNavegacaoHook>{children}</ItemNavegacaoHook>
        </FormHook>
      </ListaOrdemDesmontagemItemDraggableHook>
    </RotasHook>
  );
};

export default ItemHook;
