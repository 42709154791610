import React, { createContext, useCallback, useContext, useState } from 'react';
import { produce } from 'immer';
import { IVeiculoMotoristaLista } from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import VeiculoMotoristaOrdemComunicador from '../../../../../../Comunicador/Transporte/Veiculo/Motorista/Comunicador/VeiculoMotoristaOrdemComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IVeiculoMotoristaTabContext {
  validador: boolean;

  listaValor: IVeiculoMotoristaLista[];
  setListaValor: React.Dispatch<React.SetStateAction<IVeiculoMotoristaLista[]>>;

  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(lista?: IVeiculoMotoristaLista[]): Promise<void>;

  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const VeiculoMotoristaTabContext = createContext<IVeiculoMotoristaTabContext>(
  {} as IVeiculoMotoristaTabContext
);

const ListaVeiculoMotoristaDraggableHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const formPrincipal = UseForm();

  const [listaValor, setListaValor] = useState<IVeiculoMotoristaLista[]>([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(
    async (lista: IVeiculoMotoristaLista[] = listaValor) => {
      const id = formPrincipal.getIdDetalheRegistro();
      if (!id) return;

      const listaItem = lista.map((valor) => {
        return {
          id: valor.id,
          ordem: valor.ordem,
        };
      });

      await VeiculoMotoristaOrdemComunicador.update({
        id,
        params: { listaItem },
      });
    },
    [formPrincipal, listaValor]
  );

  return (
    <VeiculoMotoristaTabContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </VeiculoMotoristaTabContext.Provider>
  );
};

function UseListaVeiculoMotoristaDraggable(): Omit<
  IVeiculoMotoristaTabContext,
  'validador'
> {
  const context = useContext(VeiculoMotoristaTabContext);

  if (!context.validador) {
    throw new Error(
      'UseListaVeiculoMotoristaDraggable deve ser usado com um ListaVeiculoMotoristaDraggableHook'
    );
  }

  return context;
}

export {
  ListaVeiculoMotoristaDraggableHook,
  UseListaVeiculoMotoristaDraggable,
};
