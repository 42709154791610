/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormatarCep } from '@elogestor/util';
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { FaSearchLocation } from 'react-icons/fa/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { ContainerCep } from './styles';
import { DefaultInput, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputCepProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IInputCepRef {
  focus(): void;
}

interface IInputCepProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  loading?: boolean;
  hideIcon?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputCepProps
  ) => void | Promise<void>;
  onPesquisarCep(): Promise<void>;
}

const InputCep: React.ForwardRefRenderFunction<IInputCepRef, IInputCepProps> = (
  {
    name,
    label,
    loading = false,
    hideIcon,

    onFocus,
    onChange,
    onBlur,
    onPesquisarCep,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [desabilitado, setDesabilitado] = useState(true);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnteriorOnBlur.current = inputRef.current?.value || '';
    valorAnteriorOnChange.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);

    if (inputRef.current?.value) {
      setDesabilitado(loading);
    }
  }, [desabilitado, erroUnform, loading]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let valor = event.target.value.replace(/\D/g, '');
      if (valor.length > 8) {
        valor = valor.slice(0, -1);
      }

      valor = FormatarCep(valor);
      event.target.value = valor;

      if (inputRef.current && inputRef.current.value.length === 10) {
        setDesabilitado(false);
      } else {
        setDesabilitado(true);
      }

      if (onChange) {
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);

      setIsFocused(false);

      const valor = inputRef.current?.value.replace(/\D/g, '');

      if (valor && valor.length !== 8) {
        setErro('CEP inválido!');
      } else {
        setIsFilled(!!valor);
      }
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        let valor = inputRef.current?.value || '';
        valor = valor.replace(/\D/g, '');

        return valor;
      },

      setValue(_, value = '') {
        value = FormatarCep(value);
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = value;

          if (value.length === 10) {
            setDesabilitado(false);
          }
        }
      },

      setSemExecutarEvento(_, value = '') {
        value = FormatarCep(value);
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = value;

          if (value.length === 10) {
            setDesabilitado(false);
          }
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerCep>
      {label && <label>{label}</label>}
      <div>
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="text"
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          {...rest}
          ref={inputRef}
          maxLength={10}
        />
        {!hideIcon && (
          <button
            type="button"
            className="btn-editar"
            onClick={() => {
              onPesquisarCep();
            }}
            disabled={desabilitado}
          >
            <FaSearchLocation />
          </button>
        )}
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </ContainerCep>
  );
};

export default forwardRef(InputCep);
