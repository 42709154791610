/* eslint-disable @typescript-eslint/no-empty-function */
import { FormatarEnum, TipoCargaEnum } from '@elogestor/util';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import Select from '../../../../../Componentes/Select';
import InputCep from '../../../../../Componentes/Inputs/InputCep';

const ProdutoPredominanteLocacaoTab: React.FC = () => {
  const { refresh } = UseForm();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo de Carga"
              name="manifestoTransporteProdutoPredominante.tipoCarga"
              options={FormatarEnum({
                enumObj: TipoCargaEnum,
                nullavel: true,
              })}
              onChange={() => {
                refresh();
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Produto Predominante"
              name="manifestoTransporteProdutoPredominante.produtoPredominante"
              placeholder="Produto Predominante"
              maxLength={120}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Carregamento</h5>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputCep
              name="manifestoTransporteProdutoPredominante.cepCarregamento"
              label="CEP"
              placeholder="CEP"
              hideIcon
              onPesquisarCep={async () => {}}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Latitude"
              name="manifestoTransporteProdutoPredominante.latitudeCarregamento"
              placeholder="Latitude"
              maxLength={11}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Longitude"
              name="manifestoTransporteProdutoPredominante.longitudeCarregamento"
              placeholder="Longitude"
              maxLength={11}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Descarregamento</h5>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputCep
              name="manifestoTransporteProdutoPredominante.cepDescarregamento"
              label="CEP"
              placeholder="CEP"
              hideIcon
              onPesquisarCep={async () => {}}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Latitude"
              name="manifestoTransporteProdutoPredominante.latitudeDescarregamento"
              placeholder="Latitude"
              maxLength={11}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Longitude"
              name="manifestoTransporteProdutoPredominante.longitudeDescarregamento"
              placeholder="Longitude"
              maxLength={11}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ProdutoPredominanteLocacaoTab;
