import api from '../../../../../Comum/Services/Api';

const rota = 'geral/estado-auto-complete';

interface IEstadoAutoCompleteComunicadorIndex {
  params: any;
}

class EstadoAutoCompleteComunicador {
  public async index(
    params: IEstadoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new EstadoAutoCompleteComunicador();
