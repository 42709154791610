import React, { createContext, useContext, useEffect, useState } from 'react';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import IPadraoProps from '../Comum/Interface/IPadraoProps';

interface IReactSizeMeBodyHookContextData {
  size: {
    width: number | null;
    height: number | null;
  };
  telaPequena: boolean;
  telaGrande: boolean;
}

interface ISizeMeProps extends SizeMeProps, IPadraoProps {}

const ReactSizeMeBodyHookContext =
  createContext<IReactSizeMeBodyHookContextData>(
    {} as IReactSizeMeBodyHookContextData
  );

const ReactSizeMeHook: React.FC<ISizeMeProps> = ({ children, size }) => {
  const [telaPequena, setTelaPequena] = useState(
    !!(size.width && size.width < 800)
  );
  const [telaGrande, setTelaGrande] = useState(
    !!(size.width && size.width > 800)
  );

  useEffect(() => {
    setTelaPequena(!!(size.width && size.width < 800));
    setTelaGrande(!!(size.width && size.width > 800));
  }, [size.width]);

  return (
    <ReactSizeMeBodyHookContext.Provider
      value={{ size, telaPequena, telaGrande }}
    >
      {children}
    </ReactSizeMeBodyHookContext.Provider>
  );
};

const ReactSizeMeBodyHookProvider = sizeMe({ monitorHeight: true })(
  ReactSizeMeHook
);

function UseReactSizeMeBodyHook(): IReactSizeMeBodyHookContextData {
  const context = useContext(ReactSizeMeBodyHookContext);

  if (!context) {
    throw new Error(
      'UseReactSizeMeBodyHook must be used within an ReactSizeMeBodyHookProvider'
    );
  }

  return context;
}

export { ReactSizeMeBodyHookProvider, UseReactSizeMeBodyHook };
