import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/produtos/produto-por-grupo-referencias';

interface IProdutoPorGrupoComReferenciasRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ProdutoPorGrupoComReferenciasRelatorioComunicador {
  public async index(
    params: IProdutoPorGrupoComReferenciasRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ProdutoPorGrupoComReferenciasRelatorioComunicador();
