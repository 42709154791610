import api from '../../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/item/impostos-fiscais/pis';

interface INotaFiscalEntradaItemImpostoPisComunicadorShow {
  id: string;
  params: any;
}

class NotaFiscalEntradaItemImpostoPisComunicador {
  public async show(
    params: INotaFiscalEntradaItemImpostoPisComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalEntradaItemImpostoPisComunicador();
