import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import FinalidadeUsoClienteComunicador from '../../../../../../Comunicador/Comercial/Pessoas/FinalidadeUso/Comunicador/FinalidadeUsoClienteComunicador';

const FinalidadeUsoClienteHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: FinalidadeUsoClienteComunicador }}
    >
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default FinalidadeUsoClienteHook;
