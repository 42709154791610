import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/plano-fundo-redefinir';

class EmpresaPlanoFundoRedefinirComunicador {
  public async update(): Promise<any> {
    const response = await api.put(rota);

    return response.data;
  }
}

export default new EmpresaPlanoFundoRedefinirComunicador();
