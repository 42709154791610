import {
  FormatarDataParaPtBr,
  IGeracaoEnvioBoletosLista,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiMailSend } from 'react-icons/bi/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import GeracaoEnvioBoletosComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/GeracaoEnvioBoletos/Comunicador/GeracaoEnvioBoletosComunicador';
import AcoesGeracaoEnvioBoletos from '../AcoesGeracaoEnvioBoletos';
import BtnAcoesButton from '../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseGeracaoEnvioBoletosLista } from '../Hooks/GeracaoEnvioBoletosListaHook';

const GeracaoEnvioBoletosLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroMovimentacoesGeracaoEnvioBoletos: permissao } = permissoes;

  const { listaValor, setListaValor, listaItemSelecionadoRef } =
    UseGeracaoEnvioBoletosLista();

  const [refresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );
    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef]
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);

        let listaDados: IGeracaoEnvioBoletosLista[] = [];

        const itensSelecionados = listaDados.filter(
          (item: IGeracaoEnvioBoletosLista) => {
            return item.selecionado;
          }
        );

        setPossuiItemSelecionado(itensSelecionados.length > 0);

        const response = await GeracaoEnvioBoletosComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        listaDados = response.dados[0].map(
          (data: IGeracaoEnvioBoletosLista) => {
            return { ...data, selecionado: false };
          }
        );

        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);
        const dados = listaDados.map(
          (data: IGeracaoEnvioBoletosLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: data.exibirRegistro ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              numeroDocumento: (
                <div>
                  {data.exibirRegistro && (
                    <div className="lista-texto">{data.numeroDocumento}</div>
                  )}
                </div>
              ),

              'conta.dataHoraEmissao': (
                <div className="lista-data">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-data">
                        {FormatarDataParaPtBr(data.conta.dataHoraEmissao)}
                      </div>
                    )}
                  </div>
                </div>
              ),

              dataVencimento: (
                <div className="lista-data">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-data">
                        {FormatarDataParaPtBr(data.dataVencimento)}
                      </div>
                    )}
                  </div>
                </div>
              ),
              'conta.pessoa.nomeRazaoSocial': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">
                        {data.conta.pessoa.nomeRazaoSocial}
                      </div>
                    )}
                  </div>
                </div>
              ),
              valorTotalParcela: (
                <div className="lista-valor">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-valor">
                        {data.valorTotalParcela
                          ? Number(data.valorTotalParcela).FormatarParaPtBr({
                              minimoCasasDecimais: 2,
                              maximoCasasDecimais: 2,
                            })
                          : ''}
                      </div>
                    )}
                  </div>
                </div>
              ),
              'boleto.situacao': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">{data.boleto.situacao}</div>
                    )}
                  </div>
                </div>
              ),
              'portador.descricao': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">
                        {data.portador.descricao}
                      </div>
                    )}
                  </div>
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <BtnAcoesButton>
                    <Dropdown.Item
                      disabled
                      // onClick={handleImprimirBoletos}
                    >
                      <span style={{ display: 'flex', fontSize: 16 }}>
                        <AiOutlinePrinter style={{ marginRight: 5 }} />
                        Gerar/Imprimir Boletos
                      </span>
                    </Dropdown.Item>

                    <Dropdown.Item
                      disabled
                      // onClick={handleEnviarPorEmail}
                    >
                      <span style={{ display: 'flex', fontSize: 16 }}>
                        <BiMailSend style={{ marginRight: 5 }} />
                        Enviar por E-mail
                      </span>
                    </Dropdown.Item>
                  </BtnAcoesButton>
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAlterouSelecionado, setListaValor, listaValor.length]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'financeiroGeracaoEnvioBoletos' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      listaTh.splice(8, 8, {
        id: 'acao-key',
        arrastavel: false,
        nomeCampo: 'acoes',
        ordenar: false,
        tamanho: null,
        titulo: 'Ações',
        visivel: true,
        permitirEsconder: true,
        style: { width: 75 },
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Geração e Envio de Boletos"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesGeracaoEnvioBoletos
                loading={loading}
                setLoading={setLoading}
                possuiItemSelecionado={possuiItemSelecionado}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default GeracaoEnvioBoletosLista;
