import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../../../AutoComplete/AutoCompleteBase';
import PessoaTransportadorAutoCompleteComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/AutoComplete/PessoaTransportadorAutoComplete/PessoaTransportadorAutoCompleteComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface ITransportadora {
  id: string;
  codigo: number;
  nomeRazaoSocial: string;
  cnpjCpfVirtual: string;
  contribuinteIcms: boolean;
}

export interface IOnChangeItemAtualAutoCompleteTransportadoraEvent {
  itemAtual: ITransportadora | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteTransportadoraEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputTabelaAutoCompleteTransportadoraEvent {
  input: HTMLInputElement | null;
}

interface IInputTabelaAutoCompleteTransportadorProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  valueRef?: React.RefObject<IInputAutoCompleteBaseRef>;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteTransportadoraEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteTransportadoraEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteTransportadoraEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputTabelaAutoCompleteTransportadoraEvent
  ) => void | Promise<void>;
  obterRef?: (inputRef: React.RefObject<IInputAutoCompleteBaseRef>) => void;
}

export interface IInputTabelaAutoCompleteTransportadorRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputTabelaAutoCompleteTransportador: React.ForwardRefRenderFunction<
  IInputTabelaAutoCompleteTransportadorRef,
  IInputTabelaAutoCompleteTransportadorProps
> = (
  {
    valueRef,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    obterRef,
    ...rest
  },
  ref
) => {
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef | null>(null);

  const flags = useRef({ clearValue: false });
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(autoCompleteRef);
      }
    }
  }, [obterRef, valueRef]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div
        style={{ width: '100%', display: 'flex' }}
        onKeyDown={(e) => {
          if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
          }
        }}
      >
        <AutoCompleteBase
          ref={(instance) => {
            if (instance) {
              autoCompleteRef.current = instance;
            }
          }}
          setPesquisando={(valor) => {
            setPesquisando(valor);
          }}
          filtrarItens={async ({ valor, offset, limite }) => {
            setPesquisando(true);

            try {
              const response =
                await PessoaTransportadorAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite,
                    texto: valor,
                    ativo,
                    listaIdParaRemover,
                  },
                });

              setPesquisando(false);
              return response;
            } catch (err) {
              TratarErros(err);
              setPesquisando(false);
              return false;
            }
          }}
          obterChaveUnica={(item: ITransportadora) => {
            return item.id;
          }}
          obterLabel={(item: ITransportadora) => {
            if (!item.codigo && !item.nomeRazaoSocial) return '';
            if (item.codigo && !item.nomeRazaoSocial)
              return String(item.codigo);
            if (!item.codigo && item.nomeRazaoSocial)
              return item.nomeRazaoSocial;

            return `${item.nomeRazaoSocial} - ${item.codigo}`;
          }}
          onChangeItemAtual={handleChangeItemAtual}
          onChangeTexto={handleChangeTexto}
          onBlurInput={handleBlurInput}
          inputProps={rest}
        />
      </div>
    </div>
  );
};

export default forwardRef(InputTabelaAutoCompleteTransportador);
