/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import CategoriaAutoCompleteComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/Categoria/AutoComplete/CategoriaAutoComplete/CategoriaAutoCompleteComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface ICategoria {
  id: string;
  codigo: string;
  descricao: string;
  idCategoriaPai: string;
}

export interface IOnChangeItemAtualAutoCompleteCategoriaEvent {
  itemAtual: ICategoria | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteCategoriaEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteCategoriaEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteCategoriaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  codigoCompletoAtual?: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  filtroTipoSinteticaAnalitica?: string;

  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteCategoriaEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteCategoriaEvent
  ) => void | Promise<void>;

  onBlurInput?: (
    event: IOnBlurInputAutoCompleteCategoriaEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteCategoriaEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteCategoriaRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteCategoria: React.ForwardRefRenderFunction<
  IInputAutoCompleteCategoriaRef,
  IInputAutoCompleteCategoriaProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaIdParaRemover = [],
    filtroTipoSinteticaAnalitica = '',
    ativo = true,
    onChangeItemAtual,
    onChangeTexto,
    onBlurInput,
    onChangeItemAtualAposCarregarSemClear,
    codigoCompletoAtual,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<ICategoria | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response = await CategoriaAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite,
                    texto: valor,
                    ativo,
                    listaIdParaRemover,
                    filtroTipoSinteticaAnalitica,
                    codigoCompletoAtual,
                  },
                });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: ICategoria) => {
              return item.id;
            }}
            obterLabel={(item: ICategoria) => {
              if (!item.codigo && !item.descricao) return '';

              return `${item.codigo} - ${item.descricao}`;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteCategoria);
