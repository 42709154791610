"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoMercadoEnum;
(function (TipoMercadoEnum) {
    TipoMercadoEnum["dentroEstado"] = "1 - Dentro do Estado";
    TipoMercadoEnum["foraEstado"] = "2 - Fora do Estado";
    TipoMercadoEnum["exterior"] = "3 - Exterior";
})(TipoMercadoEnum || (TipoMercadoEnum = {}));
exports.default = TipoMercadoEnum;
