"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var PerfilSpedFiscalEnum;
(function (PerfilSpedFiscalEnum) {
    PerfilSpedFiscalEnum["perfilA"] = "A-Perfil A";
    PerfilSpedFiscalEnum["perfilB"] = "B-Perfil B";
    PerfilSpedFiscalEnum["perfilC"] = "C-Perfil C";
})(PerfilSpedFiscalEnum || (PerfilSpedFiscalEnum = {}));
exports.default = PerfilSpedFiscalEnum;
