import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './Comum/Styles/Global';
import AppProvider from './Hooks';
import Rotas from './Rotas';

const App: React.FC = () => {
  const GlobalStyleProxy: any = GlobalStyle;

  return (
    <Router>
      <AppProvider>
        <Rotas />
      </AppProvider>
      <GlobalStyleProxy />
    </Router>
  );
};

export default App;
