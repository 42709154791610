import {
  FormatarEnum,
  TipoCarroceriaVeiculoEnum,
  TipoPropriedadeVeiculoEnum,
  TipoProprietarioVeiculoEnum,
  TipoVeiculoEnum,
  IVeiculoValoresAlterar,
  TipoRodadoVeiculoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import InputAutoCompleteEstado from '../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import Select from '../../../../../Componentes/Select';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';
import ListaDetalheMotorista from '../ListaDetalheMotorista/Lista';
import InputAutoCompletePessoa from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import VeiculoMotoristaHook from '../ListaDetalheMotorista/Hooks';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';

type IProps = IMain<IVeiculoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { TransporteVeiculo: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const isTerceiro =
    formRef.current?.getFieldValue('tipoPropriedade') ===
    TipoPropriedadeVeiculoEnum.terceiro;

  const isReboque =
    formRef.current?.getFieldValue('tipoVeiculo') === TipoVeiculoEnum.reboque;

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Placa"
                name="placa"
                placeholder="Placa"
                maxLength={7}
                maiuscula
                formatarValor={(event) => {
                  return event.target.value.replace(/[^a-zA-Z0-9]/gi, '');
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputAutoCompleteEstado
                label="UF"
                name="idEstado"
                placeholder="UF"
                nomeObjeto="estado"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                label="Renavam"
                name="renavam"
                placeholder="Renavam"
                maxLength={11}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Tara (Kg)"
                name="tara"
                placeholder="Tara"
                casasDecimais={5}
                casasInteiras={6}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Capacidade (Kg)"
                name="capacidadeKg"
                placeholder="Capacidade (Kg)"
                casasDecimais={5}
                casasInteiras={6}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Capacidade (M3)"
                name="capacidadeM3"
                placeholder="Capacidade (M3)"
                casasDecimais={3}
                casasInteiras={3}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo de Veículo"
                name="tipoVeiculo"
                options={FormatarEnum({
                  enumObj: TipoVeiculoEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className={isReboque ? 'hidden' : ''}>
              <Select
                label="Tipo de Rodado"
                name="tipoRodado"
                options={FormatarEnum({
                  enumObj: TipoRodadoVeiculoEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo de Carroceria"
                name="tipoCarroceria"
                options={FormatarEnum({
                  enumObj: TipoCarroceriaVeiculoEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo de Propriedade"
                name="tipoPropriedade"
                options={FormatarEnum({
                  enumObj: TipoPropriedadeVeiculoEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>

        <Divisor className={isTerceiro ? '' : 'hidden'}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompletePessoa
                label="Proprietário"
                name="idPessoaProprietario"
                placeholder="Proprietário"
                nomeObjeto="pessoaProprietario"
                maxLength={60}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Input
                label="RNTRC"
                name="rntrc"
                placeholder="RNTRC"
                maxLength={8}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo de Proprietário"
                name="tipoProprietario"
                options={FormatarEnum({
                  enumObj: TipoProprietarioVeiculoEnum,
                  nullavel: true,
                })}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor className={isReboque ? 'hidden' : ''}>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <VeiculoMotoristaHook>
                <ListaDetalheMotorista name="listaVeiculoMotorista" />
              </VeiculoMotoristaHook>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
