import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaRelatorio,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPlanoCategoriasRegimeCaixaPDF {
  dados: IMovimentacoesFinanceirasPlanoCategoriasPorRegimeCaixaRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});
const PlanoCategoriasRegimeCaixaPDF: React.FC<
  IPlanoCategoriasRegimeCaixaPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataInicial &&
                  dados.cabecalho.filtroDataFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataInicial} até ${dados.cabecalho.filtroDataFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroDataFinalLiquidacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Data Final: '}
                      </Text>
                      {dados.cabecalho.filtroDataFinalLiquidacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo de Data de Liquidação: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteCategoriasComValor && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Somente Categorias com Valor: '}
                      </Text>
                      {dados.cabecalho.filtroSomenteCategoriasComValor === true
                        ? 'Sim'
                        : ''}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <View
              fixed
              style={{
                flexDirection: 'row',
                fontWeight: 'bold',
              }}
            >
              <View style={{ width: '40%' }} />

              <View
                style={{
                  width: '20%',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Realizado</Text>
              </View>

              <View style={{ width: '20%' }} />

              <View
                style={{
                  width: '20%',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  alignItems: 'center',
                }}
              >
                <Text>Previsto</Text>
              </View>
            </View>

            <View
              fixed
              style={{
                flexDirection: 'row',
                borderBottom: '1pt solid black',
                fontWeight: 'bold',
              }}
            >
              <View style={{ width: '40%' }}>
                <Text>Descrição</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '8%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Valor</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Cat. Pai</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Saídas</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Entradas</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '8%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Valor</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Cat. Pai</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Saídas</Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '6%',
                  alignItems: 'flex-end',
                }}
              >
                <Text>Percentual Entradas</Text>
              </View>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={String(dadoIndex)}>
                    <View
                      style={{
                        flexDirection: 'row',
                        backgroundColor:
                          dadoIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                        fontWeight:
                          dado.tipoSinteticaAnalitica ===
                          TipoSinteticaAnaliticaEnum.sintetica
                            ? 'bold'
                            : 'normal',
                      }}
                    >
                      <View
                        style={{
                          width: '40%',
                          paddingLeft: dado.tamanhoCodigo * 2,
                        }}
                      >
                        <Text>{`${dado.codigo} - ${dado.descricao}`}</Text>
                      </View>

                      <View
                        style={{
                          width: '1%',
                        }}
                      />

                      <View style={{ width: '8%', alignItems: 'flex-end' }}>
                        <Text>{dado.valorRealizado}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualCategoriaPaiRealizado}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualSaidaRealizado}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualEntradaRealizado}</Text>
                      </View>

                      <View
                        style={{
                          width: '1%',
                        }}
                      />

                      <View style={{ width: '8%', alignItems: 'flex-end' }}>
                        <Text>{dado.valorPrevisto}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualCategoriaPaiPrevisto}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualSaidaPrevisto}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View style={{ width: '6%', alignItems: 'flex-end' }}>
                        <Text>{dado.percentualEntradaPrevisto}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                flexDirection: 'column',
                fontWeight: 'bold',
                fontSize: 9,
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '30%' }} />

                <View
                  style={{
                    width: '6%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>Entradas</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View
                  style={{
                    width: '6%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>Saídas</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View
                  style={{
                    width: '6%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>Resultado</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View
                  style={{
                    width: '12%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>Percentual Resultado</Text>
                </View>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '15%' }} />
                <View
                  style={{
                    width: '14%',
                  }}
                >
                  <Text>Previsto:</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View
                  style={{
                    width: '6%',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>{dados.totalEntradasPrevisto}</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dados.totalSaidasPrevisto}</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dados.totalResultadoPrevisto}</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>{dados.percentualResultadoPrevisto}</Text>
                </View>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '15%' }} />

                <View style={{ width: '14%' }}>
                  <Text>Realizado:</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dados.totalEntradasRealizado}</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dados.totalSaidasRealizado}</Text>
                </View>

                <View style={{ width: '5%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dados.totalResultadoRealizado}</Text>
                </View>

                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>{dados.percentualResultadoRealizado}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PlanoCategoriasRegimeCaixaPDF;
