import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto-auto-complete-tag';

interface IProdutoAutoCompleteTagComunicadorIndex {
  params: any;
}

class ProdutoAutoCompleteTagComunicador {
  public async index(
    params: IProdutoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ProdutoAutoCompleteTagComunicador();
