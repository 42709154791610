import { FormCia } from '@elogestor/unformcia';
import {
  FormatarEnum,
  IContaContabilValoresAlterar,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import Select from '../../../../../../Componentes/Select';
import { BtnContainer } from '../styles';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputAutoCompleteContaContabil, {
  IOnChangeItemAtualAutoCompleteContaContabilEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Contabil/InputAutoCompleteContaContabil';
import ContaContabilObterDadosPadraoComunicador from '../../../../../../Comunicador/Contabil/ContasContabeis/ContaContabil/Comunicador/ContaContabilObterDadosPadraoComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseTreeContext } from '../../Hooks/TreeContext';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import ParametroValorComunicador from '../../../../../../Comunicador/Configuracao/Parametro/Comunicador/ParametroValorComunicador';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<IContaContabilValoresAlterar>;

const Main: React.FC<IProps> = ({
  dadosDuplicados,
  dadosRecuperados,
  idEditar,
  dadosObrigatorios,
  dadosPadrao,
  onClickSalvar,
}) => {
  const {
    formRef,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    setLoading,
    refresh,
  } = UseForm();
  const { permissoes } = UsePermissoes();
  const { ContabilContasContabeisContaContabil: permissao } = permissoes;
  const idDetalheRegistro = getIdDetalheRegistro();
  const rotas = UseRota();

  const inputRefDescricao = useRef<HTMLInputElement>(null);

  const [mascara, setMascara] = useState('');

  const { idShowItem, idItemPai, setIdItemPai } = UseTreeContext();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    setIdItemPai('');
    onClickSalvar && onClickSalvar({ ...data, id });
  }, [formRef, handleSubmit, onClickSalvar, setIdItemPai]);

  const handleAdicionarContaContabilPai = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteContaContabilEvent) => {
      try {
        setLoading(true);

        const response = await ContaContabilObterDadosPadraoComunicador.show({
          idContaContabilPai: event.itemAtual?.id || idItemPai,
        });

        if (
          idItemPai &&
          formRef.current?.getFieldValueNomeObjeto('contaContabilPai')
        ) {
          return;
        }

        if (idItemPai) {
          const dadosAtuais = formRef.current?.getDataDuplicar();
          await formRef.current?.setSemExecutarEvento({
            ...dadosAtuais,
            contaContabilPai: response.contaContabilPai,
          });
        }

        formRef.current?.setFieldValue('codigo', response.codigo);
        formRef.current?.setFieldValue('codigoConta', response.codigoConta);

        formRef.current?.setFieldValue(
          'contaContabilPai.codigoReduzido',
          response.contaContabilPai?.codigoReduzido
        );

        formRef.current?.setFieldValue(
          'contaContabilPai.codigoIntegracaoContabil',
          response.contaContabilPai?.codigoIntegracaoContabil
        );
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    },
    [formRef, idItemPai, setLoading]
  );

  const handleChangeCodigo = useCallback(
    (codigo?: string): void => {
      const codigoConta = formRef.current?.getFieldValue('codigoConta');
      if (!codigoConta || !mascara || !codigo) return;

      if (Array.from(codigo).every((letra) => letra === '0')) {
        formRef.current?.setFieldValue('codigo', null);
        return;
      }

      const nivelAtual = codigoConta.split('.').length;
      const subGruposCodigoConta = codigoConta.split('.');

      if (codigo.length > subGruposCodigoConta[nivelAtual - 1].length) return;

      subGruposCodigoConta[subGruposCodigoConta.length - 1] = codigo.padStart(
        subGruposCodigoConta[nivelAtual - 1].length,
        '0'
      );

      const valor = subGruposCodigoConta.join('.');

      formRef.current?.setFieldValue('codigoConta', valor);
    },
    [formRef, mascara]
  );

  const handleSetCodigoMaxLength = useCallback((): number | undefined => {
    const codigoConta = formRef.current?.getFieldValue('codigoConta');

    if (!mascara || !codigoConta) {
      return undefined;
    }

    const nivelAtual = codigoConta.split('.').length;

    const subGruposMascara = mascara.split('.');
    const nivelAtualMascara = subGruposMascara[nivelAtual - 1];

    if (!nivelAtualMascara) {
      return undefined;
    }

    return nivelAtualMascara.length;
  }, [formRef, mascara]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    (async () => {
      if (idItemPai) {
        await formRef.current?.setDataInicial({
          ativo: true,
          dataCadastro: new Date(),
          tipoSinteticaAnalitica: TipoSinteticaAnaliticaEnum.analitica,
        });

        return;
      }

      try {
        setLoading(true);
        await handleCarregarDados({
          dadosDuplicados,
          dadosObrigatorios,
          dadosPadrao,
          dadosRecuperados,
        });
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    })();

    inputRefDescricao.current?.focus();
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idItemPai,
    idShowItem,
    refresh,
    rotas.detalhes,
    rotas.listagem,
    setLoading,
  ]);

  useEffect(() => {
    (async () => {
      const valor = await ParametroValorComunicador.show({
        identificador: 'MascaraContaContabil',
      });

      setMascara(valor);
    })();
  }, []);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHiddenHtml
        name="id"
        validarSeMudou={() => {
          return false;
        }}
      />

      <Divisor>
        <Row>
          <Col lg={6}>
            <InputAutoCompleteContaContabil
              label="Conta Sintética (Pai)"
              name="idContaContabilPai"
              nomeObjeto="contaContabilPai"
              placeholder="Conta Sintética (Pai)"
              disabled={!!idItemPai}
              codigoContaAtual={formRef.current?.getFieldValue('codigoConta')}
              listaIdParaRemover={
                formRef.current?.getFieldValue('id')
                  ? [formRef.current?.getFieldValue('id')]
                  : undefined
              }
              onChangeItemAtual={async (event) => {
                await handleAdicionarContaContabilPai(event);
              }}
            />
          </Col>

          <Col lg={3}>
            <InputInteiroNulavel
              label="Cód. Reduzido"
              placeholder="Cód. Reduzido"
              name="contaContabilPai.codigoReduzido"
              disabled
            />
          </Col>

          <Col lg={3}>
            <InputInteiroNulavel
              label="Cód. Integ. Cont."
              placeholder="Cód. Integ. Cont."
              name="contaContabilPai.codigoIntegracaoContabil"
              disabled
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ padding: '15px', marginBottom: '-0px' }}>
        <Row>
          <Col lg={3}>
            <InputInteiroNulavel
              label="Conta"
              name="codigo"
              maxLength={handleSetCodigoMaxLength()}
              min={1}
              onChange={(event) => {
                handleChangeCodigo(event.target.value);
              }}
            />
          </Col>
          <Col lg={9}>
            <Input
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              ref={inputRefDescricao}
              maxLength={60}
            />
          </Col>
          <Col />
        </Row>

        <Row>
          <Col lg={3}>
            <InputLabel label="Código" name="codigoConta" />
          </Col>
          <Col lg={6}>
            <Select
              label="Tipo de Conta"
              name="tipoSinteticaAnalitica"
              options={FormatarEnum({
                enumObj: TipoSinteticaAnaliticaEnum,
                todos: false,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputInteiroNulavel
              label="Código Reduzido"
              name="codigoReduzido"
              placeholder="Código Reduzido"
            />
          </Col>
          <Col>
            <InputInteiroNulavel
              label="Cód. Integração Contábil"
              name="codigoIntegracaoContabil"
              placeholder="Cód. Integração Contábil"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputDateSemTimeZone label="Data Cadastro" name="dataCadastro" />
          </Col>
          <Col>
            <InputSwitch name="ativo" label="Ativo" />
          </Col>
        </Row>

        <BtnContainer>
          <button
            type="button"
            onClick={handleClickSalvar}
            className="btn-padrao btn-verde"
            disabled={
              loading ||
              (idDetalheRegistro && !permissao?.altera) ||
              (!idDetalheRegistro && !permissao?.inclui)
            }
          >
            <TextoLoadingSalvar loading={loading} />
          </button>
        </BtnContainer>
      </Divisor>
    </FormCia>
  );
};

export default Main;
