import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/abertura-fechamento-caixa';

interface IAberturaFechamentoCaixaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IAberturaFechamentoCaixaComunicadorShow {
  id: string;
}

interface IAberturaFechamentoCaixaComunicadorStore {
  params: any;
}

interface IAberturaFechamentoCaixaComunicadorUpdate {
  id: string;
  params: any;
}

interface IAberturaFechamentoCaixaComunicadorDeletar {
  id: string;
}

class AberturaFechamentoCaixaComunicador {
  public async index(
    params: IAberturaFechamentoCaixaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IAberturaFechamentoCaixaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IAberturaFechamentoCaixaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IAberturaFechamentoCaixaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IAberturaFechamentoCaixaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new AberturaFechamentoCaixaComunicador();
