import {
  IAberturaFechamentoCaixaValoresAlterar,
  SituacaoAberturaFechamentoCaixaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteUsuario from '../../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteUsuario';
import InputAutoCompletePortador from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteTerminalCaixa from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteTerminalCaixa';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import FechamentoTab from '../FechamentoTab';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<IAberturaFechamentoCaixaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasAberturaFechamentoCaixa: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const situacaoAberturaFechamentoCaixa =
    formRef.current?.getFieldValue('situacao');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHiddenHtml name="listaValoresRecebidos" />
      <InputHiddenHtml name="listaMovimentos" />

      <div>
        <Divisor>
          <Row>
            <Col lg={9} md={6} sm={12}>
              <InputAutoCompleteTerminalCaixa
                label="Terminal Caixa"
                placeholder="Terminal Caixa"
                name="idTerminalCaixa"
                nomeObjeto="terminalCaixa"
                disabled={!!idDetalheRegistro}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
                formatarValor={(situacao = '') => {
                  return (
                    <div
                      className="lista-texto"
                      style={{
                        fontWeight: 'bold',
                        alignItems: 'flex-start',
                        color:
                          situacao ===
                          SituacaoAberturaFechamentoCaixaEnum.aberto
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {situacao}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Abertura"
                name="dataHoraAbertura"
                disabled
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteUsuario
                label="Usuário Abertura"
                placeholder="Usuário Abertura"
                name="idUsuarioAbertura"
                nomeObjeto="usuarioAbertura"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Fechamento"
                name="dataHoraFechamento"
                disabled
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteUsuario
                label="Usuário Fechamento"
                placeholder="Usuário Fechamento"
                name="idUsuarioFechamento"
                nomeObjeto="usuarioFechamento"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputDecimal
                ref={inputRefFocus}
                label="Valor em Dinheiro"
                name="valorEmDinheiro"
                placeholder="Valor em Dinheiro"
                casasInteiras={13}
                casasDecimais={2}
                disabled={
                  situacaoAberturaFechamentoCaixa ===
                  SituacaoAberturaFechamentoCaixaEnum.fechado
                }
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      {situacaoAberturaFechamentoCaixa ===
        SituacaoAberturaFechamentoCaixaEnum.fechado && (
        <Divisor>
          <h4>Fechamento</h4>

          <FechamentoTab />
        </Divisor>
      )}

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
