const PesagemComProdutoresRelatorioPlanilha = [
  { titulo: 'Numero', valor: 'numero' },
  { titulo: 'Data/Hora', valor: 'dataHoraEmissao' },
  {
    titulo: 'Peso Bruto',
    valor: 'pesoBruto',
  },
  {
    titulo: 'Peso Tara',
    valor: 'pesoTara',
  },
  {
    titulo: 'Peso Líquido',
    valor: 'pesoLiquido',
  },
  {
    titulo: 'Código',
    valor: 'produto.codigo',
  },
  {
    titulo: 'Produtor',
    valor: 'listaPesagemPessoa.pessoa.nomeRazaoSocial',
  },
  {
    titulo: 'Referência',
    valor: 'produto.codigo',
  },
  {
    titulo: 'Descrição',
    valor: 'produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor: 'produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Produtor Peso Bruto',
    valor: 'listaPesagemPessoa.pesoBruto',
  },
  {
    titulo: 'Produtor Peso Tara',
    valor: 'listaPesagemPessoa.pesoTara',
  },
  {
    titulo: 'Produtor Peso Líquido',
    valor: 'listaPesagemPessoa.pesoLiquido',
  },
];

export default PesagemComProdutoresRelatorioPlanilha;
