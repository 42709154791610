import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/impressao/etiqueta';

interface INotaFiscalSaidaEtiquetaImpressaoComunicadorShow {
  id: string;
}

class NotaFiscalSaidaEtiquetaImpressaoComunicador {
  public async show(
    params: INotaFiscalSaidaEtiquetaImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaEtiquetaImpressaoComunicador();
