import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/obter-pedidos-compras-vinculados';

interface INotaFiscalEntradaObterPedidosComprasVinculadosComunicadorShow {
  params: {
    listaIdNotaFiscalEntradaItem: string[];
  };
}

interface INotaFiscalEntradaObterPedidosComprasVinculadosComunicadorIndex {
  params: {
    listaIdNotaFiscalEntradaItem: string[];
  };
}

class NotaFiscalEntradaObterPedidosComprasVinculadosComunicador {
  public async index(
    params: INotaFiscalEntradaObterPedidosComprasVinculadosComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}/quantidade`, {
      params: params.params,
    });

    return response.data;
  }

  public async show(
    params: INotaFiscalEntradaObterPedidosComprasVinculadosComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalEntradaObterPedidosComprasVinculadosComunicador();
