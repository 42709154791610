import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import OperacaoRoteiroFabricacaoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/OperacaoRoteiroFabricacao/Comunicador/OperacaoRoteiroFabricacaoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const OperacaoRoteiroFabricacaoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: OperacaoRoteiroFabricacaoComunicador }}
    >
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default OperacaoRoteiroFabricacaoHook;
