const AdiantamentosPorEmissaoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'chave',
  },

  {
    titulo: 'Número documento',
    valor: 'listaConta.numeroDocumento',
  },

  {
    titulo: 'Cliente/Fornecedor',
    valor: 'listaConta.cliente',
  },

  {
    titulo: 'Tipo Adiantamento',
    valor: 'listaConta.tipoAdiantamento',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaConta.valorTotal',
  },

  {
    titulo: 'Valor Utilizado',
    valor: 'listaConta.valorUtilizado',
  },
];

export default AdiantamentosPorEmissaoPlanilha;
