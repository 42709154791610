import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/profissao-auto-complete';

interface IProfissaoAutoCompleteComunicadorIndex {
  params: any;
}

class ProfissaoAutoCompleteComunicador {
  public async index(
    params: IProfissaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ProfissaoAutoCompleteComunicador();
