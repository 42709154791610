import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  SituacaoPedidoCompraEnum,
  IPedidoCompraValoresAlterar,
  IPedidoCompraItemValoresAlterar,
  IPedidoCompraItemDuplicarLista,
  SituacaoPadraoPedidoCompraEnum,
  SituacaoPedidoCompraNotaFiscalEntradaEnum,
  ModeloDocumentoTipoEnum,
  TipoPesoEnum,
} from '@elogestor/util';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { ListaItemProvider } from './ListaItemContext';
import { UsePedidoCompraDuplicar } from './DuplicarHook';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import PedidoCompraComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Comunicador/PedidoCompraComunicador';
import SerieObterPrimeiraSerieComunicador from '../../../../../../Comunicador/Fiscal/Tributacao/Serie/Comunicador/SerieObterPrimeiraSerieComunicador';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const rota = UseRota();
  const { redirecionar } = UseRedirecionar();
  const { abrirPedidoCompraDuplicar, fecharPedidoCompraDuplicar } =
    UsePedidoCompraDuplicar();
  const navigate = useNavigate();
  const parametros = UseParametros();
  const { setRegimeTributarioPorData } = UseRegimeTributario();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [listaItem, setListaItem] = useState<IPedidoCompraItemValoresAlterar[]>(
    []
  );

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPedidoCompraValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await PedidoCompraComunicador.show({
          id: idEditar,
        });
        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
        setListaItem(response.listaPedidoCompraItem || []);
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response = await SerieObterPrimeiraSerieComunicador.show({
          listaTipoDocumentoFiscal: [ModeloDocumentoTipoEnum.notaFiscal],
        });

        const situacaoPadrao =
          parametros.SituacaoPadraoPedidoCompra ===
          SituacaoPadraoPedidoCompraEnum.aprovado
            ? SituacaoPedidoCompraEnum.aprovado
            : SituacaoPedidoCompraEnum.pendente;

        await formRef.current?.setDataInicial({
          modeloDocumento: response?.modeloDocumento,
          situacao: situacaoPadrao,
          dataHoraEmissao: new Date(),
          freteFormaCalculoPorRateio: true,
          seguroFormaCalculoPorRateio: true,
          outrasDespesasFormaCalculoPorRateio: true,
          descontoFormaCalculoPorRateio: true,
          situacaoPedidoCompraNotaFiscalEntrada:
            SituacaoPedidoCompraNotaFiscalEntradaEnum.naoAtendido,
          indicadorPresenca: parametros.IndicadorPresencaPadraoPedidoCompra,
          observacoesComerciais:
            parametros.TextoPadraoObservacaoComercialPedidoCompra,
          observacoesInternas:
            parametros.TextoPadraoObservacaoInternaPedidoCompra,
          pedidoCompraTransporteTransportadora: {
            modalidadeFrete: parametros.ModalidadeFretePedidoCompra,
          },

          pedidoCompraTransporteVolume: {
            tipoCalculoPesos: TipoPesoEnum.calculado,
            quantidadeFormaCalculoPorItem: true,
            metroCubicoFormaCalculoPorItem: true,
            metroQuadradoFormaCalculoPorItem: true,
          },

          valorTotalFcpIcms: 0,
          valorTotalFcpIcmsSt: 0,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          const dataHoraEmissao =
            formRef.current?.getFieldValue('dataHoraEmissao');
          await setRegimeTributarioPorData(dataHoraEmissao);

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      parametros.IndicadorPresencaPadraoPedidoCompra,
      parametros.ModalidadeFretePedidoCompra,
      parametros.SituacaoPadraoPedidoCompra,
      parametros.TextoPadraoObservacaoComercialPedidoCompra,
      parametros.TextoPadraoObservacaoInternaPedidoCompra,
      redirecionar,
      refresh,
      setRegimeTributarioPorData,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const dados = formRef.current?.getDataDuplicar();

      const schema = Yup.object().shape({
        dataHoraEmissao: Yup.date()
          .nullable()
          .required('Data Hora Emissão é obrigatória!'),

        valorFrete: Yup.number()
          .nullable()
          .required('Valor do Frete é obrigatório!'),
        valorSeguro: Yup.number()
          .nullable()
          .required('Valor do Seguro é obrigatório!'),
        valorOutrasDespesas: Yup.number()
          .nullable()
          .required('Valor das outras Despesas é obrigatório!'),
        valorDesconto: Yup.number()
          .nullable()
          .required('Valor do Desconto é obrigatório!'),

        valorTotalIcms: Yup.number()
          .nullable()
          .required('Valor Total do ICMS é obrigatório!'),
        valorTotalBaseCalculoIpi: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do IPI é obrigatório!'),
        valorTotalIpi: Yup.number()
          .nullable()
          .required('Valor Total do IPI é obrigatório!'),
        valorTotalBaseCalculoPis: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do PIS é obrigatório!'),
        valorTotalPis: Yup.number()
          .nullable()
          .required('Valor Total do PIS é obrigatório!'),
        valorTotalBaseCalculoCofins: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do COFINS é obrigatório!'),
        valorTotalCofins: Yup.number()
          .nullable()
          .required('Valor Total do COFINS é obrigatório!'),
        valorTotalBaseCalculoPisRetido: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo Retido do PIS é obrigatório!'),
        valorTotalPisRetido: Yup.number()
          .nullable()
          .required('Valor Total Retido do PIS é obrigatório!'),
        valorTotalBaseCalculoCofinsRetido: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo Retido do COFINS é obrigatório!'),
        valorTotalCofinsRetido: Yup.number()
          .nullable()
          .required('Valor Total Retido do COFINS é obrigatório!'),
        valorTotalBaseCalculoIcmsSt: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo da ST. é obrigatório!'),
        valorTotalIcmsSt: Yup.number()
          .nullable()
          .required('Valor Total da ST. é obrigatório!'),

        valorTotalBruto: Yup.number()
          .nullable()
          .required('Valor Total Bruto é obrigatório!'),
        valorTotalProdutos: Yup.number()
          .nullable()
          .required('Valor Total dos Produto é obrigatório!'),

        pedidoCompraPessoa: Yup.object().shape({
          idPessoa: Yup.string()
            .nullable()
            .required('Fornecedor é obrigatório!'),
        }),
        pedidoCompraTransporteTransportadora: Yup.object().shape({
          placa: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe a Placa no Transporte ou remova a UF referente a Placa!',
              test: (value: any) => {
                if (
                  dados.pedidoCompraTransporteTransportadora.estadoPlaca &&
                  !value
                ) {
                  return false;
                }
                return true;
              },
            }),
        }),
      });

      await schema.validate(data, { abortEarly: false });

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await PedidoCompraComunicador.update({ id, params: data });
        } else {
          const response = await PedidoCompraComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          formRef.current?.setFieldValue('numero', response.numero);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      const id = getIdDetalheRegistro();
      if (!id) return;

      setLoading(true);

      const idDuplicado = await abrirPedidoCompraDuplicar(
        id,
        listaItem as unknown as IPedidoCompraItemDuplicarLista[]
      );

      if (!idDuplicado) {
        setLoading(false);
        fecharPedidoCompraDuplicar();
        return;
      }

      setIdDetalheRegistro(idDuplicado);
      navigate({
        pathname: `${rota.detalhes}/${idDuplicado}`,
      });

      handleCarregarDados();

      ToastSucesso('Registro Duplicado e Salvo!');
      setLoading(false);
      fecharPedidoCompraDuplicar();
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    abrirPedidoCompraDuplicar,
    fecharPedidoCompraDuplicar,
    getIdDetalheRegistro,
    handleCarregarDados,
    navigate,
    listaItem,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      <ListaItemProvider value={{ listaItem }}>{children}</ListaItemProvider>
    </FormProvider>
  );
};

export default FormHook;
