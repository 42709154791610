import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/cliente-auto-complete-tag';

interface IPessoaClienteAutoCompleteTagComunicadorIndex {
  params: any;
}

class PessoaClienteAutoCompleteTagComunicador {
  public async index(
    params: IPessoaClienteAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaClienteAutoCompleteTagComunicador();
