"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = RateioItemComCasaDecimalCalculo;
const Erros_1 = require("../Erros");
function RateioItemComCasaDecimalCalculo(dados) {
    if (dados.valorItem > dados.valorTotal)
        throw new Erros_1.AppErro({
            mensagem: 'O valor do item deve ser menor que o valor total!',
        });
    const valorPorcentagem = dados.valorTotal.CalcularPorcentagem(dados.valorItem);
    const valorItem = dados.valorRateado.CalcularPercentual(valorPorcentagem);
    return valorItem.Arredondar(dados.casasDecimais);
}
