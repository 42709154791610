import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import {
  IOrdemDesmontagemItemLocalEstoqueLista,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import { VscSaveAs } from 'react-icons/vsc/index.mjs';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import EstoqueTab from './EstoqueTab';
import CustoTab from './CustosTab';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import InputAutoCompleteProdutoServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';

interface IOrdemDesmontagemItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const OrdemDesmontagemItemDetalhe: React.FC<IOrdemDesmontagemItemModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemDesmontagem: permissao } = permissoes;
  const formPrincipal = UseForm();
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();

  const [tabSelecionada, setTabSelecionada] = useState('estoques');

  const idDetalheRegistro = getIdDetalheRegistro();
  const { telaPequena } = UseReactSizeMeBodyHook();

  const produtoObjetoPrincipal =
    formPrincipal.formRef.current?.getFieldValueNomeObjeto('produto');
  const idProdutoRemover = produtoObjetoPrincipal
    ? [produtoObjetoPrincipal.id]
    : [];

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataDuplicar();

    const listaItemLocalEstoque = formRefDetalhe.current?.getFieldValue(
      'listaOrdemDesmontagemItemLocalEstoque'
    );

    const quantidadeRetornoDetalhe =
      formRefDetalhe.current?.getFieldValue('quantidadeRetorno');

    const somaQuantidadeLocalEstoque = listaItemLocalEstoque.reduce(
      (acumulador: number, item: IOrdemDesmontagemItemLocalEstoqueLista) => {
        acumulador += Number(item.quantidade) || 0;
        return acumulador;
      },
      0
    );

    if (somaQuantidadeLocalEstoque === quantidadeRetornoDetalhe) {
      data.desmontado = true;
    } else {
      data.desmontado = false;
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleClickSalvarContinuar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataDuplicar();

    const listaItemLocalEstoque = formRefDetalhe.current?.getFieldValue(
      'listaOrdemDesmontagemItemLocalEstoque'
    );

    const quantidadeRetornoDetalhe =
      formRefDetalhe.current?.getFieldValue('quantidadeRetorno');

    const somaQuantidadeLocalEstoque = listaItemLocalEstoque.reduce(
      (acumulador: number, item: IOrdemDesmontagemItemLocalEstoqueLista) => {
        acumulador += Number(item.quantidade) || 0;
        return acumulador;
      },
      0
    );

    if (somaQuantidadeLocalEstoque === quantidadeRetornoDetalhe) {
      data.desmontado = true;
    } else {
      data.desmontado = false;
    }

    const { id } = await handleSubmit(data, formRefDetalhe.current);

    const listaDados = formPrincipal.formRef.current?.getFieldValue(
      'listaOrdemDesmontagemItem'
    );

    setIdDetalheRegistro(id);

    formRefDetalhe.current?.setDataInicial(listaDados[Number(id)]);
  }, [
    formPrincipal.formRef,
    formRefDetalhe,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  const handleOnBlurCarregar = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    const quantidadePrincipal =
      formPrincipal.formRef.current?.getFieldValue('quantidade');

    const quantidadeRetorno =
      formRefDetalhe.current?.getFieldValue('quantidadeRetorno');

    if (!quantidadePrincipal || !quantidadeRetorno) return;

    const quantidadeRetornoPorUnidade = quantidadeRetorno / quantidadePrincipal;

    formRefDetalhe.current?.setFieldValue(
      'quantidadeRetornoPorUnidade',
      quantidadeRetornoPorUnidade
    );

    const listaItemLocalEstoque = formRefDetalhe.current?.getFieldValue(
      'listaOrdemDesmontagemItemLocalEstoque'
    );

    const quantidadeRetornoDetalhe =
      formRefDetalhe.current?.getFieldValue('quantidadeRetorno');

    const somaQuantidadeLocalEstoque = listaItemLocalEstoque.reduce(
      (acumulador: number, item: IOrdemDesmontagemItemLocalEstoqueLista) => {
        acumulador += Number(item.quantidade) || 0;
        return acumulador;
      },
      0
    );

    if (
      produto?.tipoProdutoServico === TipoProdutoServicoEnum.servico ||
      (listaItemLocalEstoque.length > 0 &&
        somaQuantidadeLocalEstoque === quantidadeRetornoDetalhe)
    ) {
      formRefDetalhe.current?.setFieldValue('desmontado', true);
    } else {
      formRefDetalhe.current?.setFieldValue('desmontado', false);
    }
  }, [formPrincipal.formRef, formRefDetalhe]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Itens Resultantes do Desmonte"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <JanelaNavegacao
                  containerStyle={
                    telaPequena
                      ? {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                      : {
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                  }
                />
              </Col>
            </Row>
            <Divisor>
              <InputHiddenHtml name="id" />
              <InputHiddenHtml name="listaItemEstoqueMudou" />
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <InputAutoCompleteProdutoServico
                    ref={inputRefFocus}
                    label="Produto/Serviço"
                    name="idProduto"
                    nomeObjeto="produto"
                    controlaEstoque
                    placeholder="Produto/Serviço"
                    listaIdParaRemover={idProdutoRemover}
                    onChangeItemAtualAposCarregarSemClear={async (ev) => {
                      if (ev.itemAtual?.id) {
                        formRefDetalhe.current?.setFieldValue(
                          'unidadeMedida',
                          ev.itemAtual.produtoEstoque.unidadeMedida
                        );

                        formRefDetalhe.current?.setFieldValue(
                          'desmontado',
                          ev.itemAtual?.tipoProdutoServico ===
                            TipoProdutoServicoEnum.servico
                        );
                      } else {
                        formRefDetalhe.current?.setFieldValue(
                          'desmontado',
                          false
                        );

                        formRefDetalhe.current?.clearField('unidadeMedida');
                      }

                      refresh();
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputAutoCompleteUnidadeMedida
                    label="Unidade de Medida"
                    name="idUnidadeMedida"
                    nomeObjeto="unidadeMedida"
                    placeholder="Unidade de Medida"
                    disabled
                    permitirAdicionar={false}
                  />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <InputInteiro label="Ordem" name="ordem" disabled />
                </Col>
              </Row>

              <Row>
                <Col xl={3} lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Qtde Retorno"
                    name="quantidadeRetorno"
                    onBlur={handleOnBlurCarregar}
                    casasInteiras={15}
                    casasDecimais={4}
                  />
                </Col>
                <Col xl={2} lg={3} md={6} sm={12}>
                  <InputLabel
                    name="desmontado"
                    label="Desmontado"
                    valorStyle={{ alignItems: 'center', marginTop: 5 }}
                    formatarValor={(desmontado = false) => {
                      return (
                        <div
                          className="lista-texto"
                          style={{
                            fontWeight: 'bold',
                            alignItems: 'flex-center',
                          }}
                        >
                          <span style={{ color: desmontado ? 'green' : 'red' }}>
                            {desmontado ? 'Sim' : 'Não'}
                          </span>
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    label="Qtde Retorno por Unidade"
                    name="quantidadeRetornoPorUnidade"
                    disabled
                    casasInteiras={15}
                    casasDecimais={4}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    label="Qtde da Estrutura"
                    name="quantidadeEstrutura"
                    casasInteiras={15}
                    casasDecimais={4}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    label="Qtde da Estrutura por Unidade"
                    name="quantidadeEstruturaPorUnidade"
                    casasInteiras={15}
                    casasDecimais={4}
                  />
                </Col>
              </Row>
            </Divisor>

            <Tabs
              id={v4()}
              defaultActiveKey="itens"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="estoques" title="Estoques">
                <EstoqueTab />
              </Tab>

              <Tab eventKey="custos" title="Custos">
                <CustoTab />
              </Tab>
            </Tabs>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvarContinuar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default OrdemDesmontagemItemDetalhe;
