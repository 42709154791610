import { RegimeTributarioEnum } from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import EmpresaRegimeTributarioVigenteComunicador from '../../../../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaRegimeTributarioVigenteComunicador';

interface IRegimeTributarioContextProps {
  regimeTributario: RegimeTributarioEnum | undefined;
  setRegimeTributario: React.Dispatch<
    React.SetStateAction<RegimeTributarioEnum | undefined>
  >;
  setRegimeTributarioPorData: (
    dataFinalVigencia?: Date | undefined
  ) => Promise<RegimeTributarioEnum | undefined>;
}

const RegimeTributarioContext = createContext(
  {} as IRegimeTributarioContextProps
);

const RegimeTributarioHook: React.FC<IPadraoProps> = ({ children }) => {
  const [regimeTributario, setRegimeTributario] = useState<
    RegimeTributarioEnum | undefined
  >(undefined);

  const setRegimeTributarioPorData = useCallback(
    async (
      dataFinalVigencia?: Date
    ): Promise<RegimeTributarioEnum | undefined> => {
      const item = await EmpresaRegimeTributarioVigenteComunicador.index({
        params: { dataFinalVigencia },
      });

      setRegimeTributario(item?.regimeTributario);
      return item?.regimeTributario;
    },
    []
  );

  return (
    <RegimeTributarioContext.Provider
      value={{
        setRegimeTributarioPorData,
        regimeTributario,
        setRegimeTributario,
      }}
    >
      {children}
    </RegimeTributarioContext.Provider>
  );
};

function UseRegimeTributario(): IRegimeTributarioContextProps {
  const context = useContext(RegimeTributarioContext);
  return context;
}

export { UseRegimeTributario, RegimeTributarioHook };
