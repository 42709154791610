import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteEstado from '../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagEstado from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';

const PercursoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagCidade
              label="Locais de Carregamento"
              name="listaManifestoTransporteLocalCarregamento"
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagEstado
              label="UF Percurso (Origem ➡ Destino)"
              name="listaManifestoTransporteEstadoPercurso"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteEstado
              label="Estado de Início do Percurso"
              name="idEstadoInicioPercurso"
              placeholder="Estado de Início do Percurso"
              nomeObjeto="estadoInicioPercurso"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteEstado
              label="Estado de Fim do Percurso"
              name="idEstadoFimPercurso"
              placeholder="Estado de Fim do Percurso"
              nomeObjeto="estadoFimPercurso"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PercursoTab;
