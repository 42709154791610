import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-pedido-venda/curva-abc-pedido-venda-atributo-produto-por-quantidade';

interface ICurvaABCPedidoVendaAtributoProdutoPorQuantidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCPedidoVendaAtributoProdutoPorQuantidadeRelatorioComunicador {
  public async index(
    params: ICurvaABCPedidoVendaAtributoProdutoPorQuantidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCPedidoVendaAtributoProdutoPorQuantidadeRelatorioComunicador();
