import ILogPesquisaParametro from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/local-estoque/log';

interface ILocalEstoqueLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface ILocalEstoqueLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class LocalEstoqueLogComunicador {
  public async index(
    params: ILocalEstoqueLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ILocalEstoqueLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new LocalEstoqueLogComunicador();
