import React from 'react';
import IPadraoProps from '../../../../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '',
        novo: '/comercial/vendas/pedido-venda/item/',
        alterar: '',
        excluir: '/comercial/vendas/pedido-venda/item/estoque-local-estoque',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
