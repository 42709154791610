import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  IContasReceberPagarFiltroRelatorioBackend,
  IContasReceberPagarFiltroRelatorioFrontend,
  IContasReceberPagarParcelasPorClienteFornecedorEmissaoCabecalhoRelatorio,
  IContasReceberPagarParcelasPorClienteFornecedorEmissaoRelatorio,
  StringConverterParaEnum,
  TipoContaEnum,
} from '@elogestor/util';
import React from 'react';
import ContasRecPagParcPorCliFornEmisRelComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/ContasReceberPagar/ContasRecPagParcPorCliFornEmisRelComunicador/ContasRecPagParcPorCliFornEmisRelComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import ContasReceberPagarParcelasPorClienteFornecedorEmissaoPDF from './ContasReceberPagarParcelasPorClienteFornecedorEmissaoPDF';
import ContasReceberPagarParcelasPorClienteFornecedorEmissaoPlanilha from './ContasReceberPagarParcelasPorClienteFornecedorEmissaoPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ContasReceberPagarParcelasPorClienteFornecedorEmissaoRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IContasReceberPagarFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IContasReceberPagarParcelasPorClienteFornecedorEmissaoRelatorio | null> => {
      const filtroBack: IContasReceberPagarFiltroRelatorioBackend = {
        dataHoraEmissaoInicial: filtros.dataHoraEmissaoInicial,
        dataHoraEmissaoFinal: filtros.dataHoraEmissaoFinal,
        dataVencimentoInicial: filtros.dataVencimentoInicial,
        dataVencimentoFinal: filtros.dataVencimentoFinal,
        dataFinalPosicaoLiquidacao: filtros.dataFinalPosicaoLiquidacao,
      };

      if (filtros?.listaSituacaoParcela) {
        filtroBack.listaDescricaoSituacaoParcela =
          filtros.listaSituacaoParcela.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaTiposLancamento) {
        filtroBack.listaTiposLancamento =
          filtros.listaTiposLancamento.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaClienteFornecedor) {
        filtroBack.listaIdClienteFornecedor =
          filtros.listaClienteFornecedor.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaPortadoresParcela) {
        filtroBack.listaIdPortadoresParcela =
          filtros.listaPortadoresParcela.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaPortadoresLiquidacao) {
        filtroBack.listaIdPortadoresLiquidacao =
          filtros.listaPortadoresLiquidacao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaFormasPagamentoParcela) {
        filtroBack.listaIdFormasPagamentoParcela =
          filtros.listaFormasPagamentoParcela.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaFormasPagamentoLiquidacao) {
        filtroBack.listaIdFormasPagamentoLiquidacao =
          filtros.listaFormasPagamentoLiquidacao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaCartoes) {
        filtroBack.listaIdCartoes =
          filtros.listaCartoes.listaValor.map((value: any) => value.id) || [];
      }

      if (filtros?.listaCategorias) {
        filtroBack.listaIdCategorias =
          filtros.listaCategorias.listaValor.map((value: any) => value.id) ||
          [];
      }

      if (filtros?.listaEmpresas) {
        filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
          (value: any) => value.id
        );
      }

      if (filtros?.listaTiposLancamento) {
        filtroBack.listaTiposLancamento =
          filtros.listaTiposLancamento.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.tipoConta) {
        filtroBack.tipoConta = StringConverterParaEnum<
          typeof TipoContaEnum,
          TipoContaEnum
        >(TipoContaEnum, String(filtros.tipoConta));
      }

      if (filtros?.tipoData) {
        filtroBack.tipoData = filtros.tipoData;
      }

      const response = await ContasRecPagParcPorCliFornEmisRelComunicador.index(
        {
          params: { filtro: filtroBack },
        }
      );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as IContasReceberPagarParcelasPorClienteFornecedorEmissaoRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados: any) => {
          const valores = {
            ...dados.valores,
            somatorioValorParcela: Number(
              dados.valores.somatorioValorParcela
            ).FormatarParaPtBr(),
            somatorioValorTotalParcela: Number(
              dados.valores.somatorioValorTotalParcela
            ).FormatarParaPtBr(),
            somatorioValorTotalLiquidado: Number(
              dados.valores.somatorioValorTotalLiquidado
            ).FormatarParaPtBr(),
            somatorioValorTotalAberto: Number(
              dados.valores.somatorioValorTotalAberto
            ).FormatarParaPtBr(),
          };

          const listaConta = dados.listaConta.map((conta: any) => {
            return {
              ...conta,
              dataVencimento: FormatarDataParaPtBr(conta.dataVencimento),
              valorParcela: Number(conta.valorParcela).FormatarParaPtBr(),
              valorTotalParcela: Number(
                conta.valorTotalParcela
              ).FormatarParaPtBr(),
              valorTotalLiquidado: Number(
                conta.valorTotalLiquidado
              ).FormatarParaPtBr(),
              valorTotalAberto: Number(
                conta.valorTotalAberto
              ).FormatarParaPtBr(),
            };
          });

          return {
            ...dados,
            valores,
            listaConta,
          };
        })
      );

      return {
        ...retorno,
        listaDados,
        somatorioGeralValorParcela: Number(
          retorno.somatorioGeralValorParcela
        ).FormatarParaPtBr(),
        somatorioGeralValorTotalParcela: Number(
          retorno.somatorioGeralValorTotalParcela
        ).FormatarParaPtBr(),
        somatorioGeralValorTotalLiquidado: Number(
          retorno.somatorioGeralValorTotalLiquidado
        ).FormatarParaPtBr(),
        somatorioGeralValorTotalAberto: Number(
          retorno.somatorioGeralValorTotalAberto
        ).FormatarParaPtBr(),
      };
    };

    function FormatarCabecalho(
      filtros: IContasReceberPagarFiltroRelatorioFrontend,
      cabecalho: IContasReceberPagarParcelasPorClienteFornecedorEmissaoCabecalhoRelatorio
    ): IContasReceberPagarParcelasPorClienteFornecedorEmissaoCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataHoraEmissaoInicial || filtros.dataHoraEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataHoraEmissaoInicial = '--/--/--';
        cabecalho.filtroDataHoraEmissaoFinal = '--/--/--';

        if (filtros.dataHoraEmissaoInicial) {
          cabecalho.filtroDataHoraEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataHoraEmissaoInicial
          );
        }

        if (filtros.dataHoraEmissaoFinal) {
          cabecalho.filtroDataHoraEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataHoraEmissaoFinal
          );
        }
      }

      if (filtros.dataFinalPosicaoLiquidacao) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataFinalLiquidacao = FormatarDataParaPtBr(
          filtros.dataFinalPosicaoLiquidacao
        );
      }

      if (filtros.tipoConta) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroTipoConta = filtros.tipoConta;
      }

      if (filtros.tipoData) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroTipoData = filtros.tipoData;
      }

      if (
        filtros.listaSituacaoParcela &&
        filtros.listaSituacaoParcela.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoParcela =
          filtros.listaSituacaoParcela.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaTiposLancamento &&
        filtros.listaTiposLancamento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroTiposSituacoes = filtros.listaTiposLancamento.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaClienteFornecedor &&
        filtros.listaClienteFornecedor.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroClientesFornecedores =
          filtros.listaClienteFornecedor.listaValor
            .map((valor: any) => {
              return valor.nomeRazaoSocialCodigo;
            })
            .join(', ');
      }

      if (
        filtros.listaPortadoresParcela &&
        filtros.listaPortadoresParcela.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroPortadoresParcela =
          filtros.listaPortadoresParcela.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaPortadoresLiquidacao &&
        filtros.listaPortadoresLiquidacao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroPortadoresLiquidacao =
          filtros.listaPortadoresLiquidacao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaFormasPagamentoParcela &&
        filtros.listaFormasPagamentoParcela.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroFormasPagamentoParcela =
          filtros.listaFormasPagamentoParcela.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaFormasPagamentoLiquidacao &&
        filtros.listaFormasPagamentoLiquidacao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroFormasPagamentoLiquidacao =
          filtros.listaFormasPagamentoLiquidacao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaCartoes && filtros.listaCartoes.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCartoes = filtros.listaCartoes.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaCategorias &&
        filtros.listaCategorias.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCategorias = filtros.listaCategorias.listaValor
          .map((valor: any) => {
            return valor.codigoDescricao;
          })
          .join(', ');
      }

      if (
        filtros.listaEmpresas &&
        filtros.listaEmpresas.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocial;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: ContasReceberPagarParcelasPorClienteFornecedorEmissaoPlanilha,
        nomeArquivo: 'ContasReceberPagarParcelasPorClienteFornecedorEmissao',
        valores: dados.listaDados,
        unwind: ['listaConta'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: ContasReceberPagarParcelasPorClienteFornecedorEmissaoPlanilha,
        nomeArquivo: 'ContasReceberPagarParcelasPorClienteFornecedorEmissao',
        valores: dados.listaDados as any,
        unwind: ['listaConta'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;
      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
      const relatorio = await PDF({
        documento: (
          <ContasReceberPagarParcelasPorClienteFornecedorEmissaoPDF
            dados={dados}
          />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <ContasReceberPagarParcelasPorClienteFornecedorEmissaoPDF
            dados={dados}
          />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default ContasReceberPagarParcelasPorClienteFornecedorEmissaoRelatorio();
