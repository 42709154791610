"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDataConhecimentoTransporteTerceiroEnum;
(function (TipoDataConhecimentoTransporteTerceiroEnum) {
    TipoDataConhecimentoTransporteTerceiroEnum["emissao"] = "Emissao";
    TipoDataConhecimentoTransporteTerceiroEnum["lancamento"] = "Lancamento";
    TipoDataConhecimentoTransporteTerceiroEnum["aquisicaoPrestacaoServico"] = "Aquisicao/Prest. Servico";
})(TipoDataConhecimentoTransporteTerceiroEnum || (TipoDataConhecimentoTransporteTerceiroEnum = {}));
exports.default = TipoDataConhecimentoTransporteTerceiroEnum;
