import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import BtnCloseButton from '../Buttons/BtnCloseButton';
import { Container } from './styles';

interface IRecuperarFormulario {
  cb(resposta: boolean): any | Promise<any>;
  mensagem?: string;
  titulo?: string;
  confimar?: string;
  cancelar?: string;
  fecharJanela(): void;
}

const RecuperarFormulario: React.FC<IRecuperarFormulario> = ({
  cb,
  mensagem = 'Você fechou o formulário sem salvar as alterações!',
  titulo = 'Alerta!',
  confimar = 'Recuperar Formulário',
  fecharJanela,
}) => {
  return (
    <Modal
      show
      onHide={async () => {
        fecharJanela();
        await cb(false);
      }}
    >
      <Container>
        <div>
          <h3>{titulo}</h3>
          <BtnCloseButton
            style={{
              marginLeft: 'auto',
            }}
            type="button"
            onClick={async () => {
              fecharJanela();
              await cb(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </div>
        <p>{mensagem}</p>
        <Button
          variant="warning"
          onClick={async () => {
            fecharJanela();
            await cb(true);
          }}
        >
          {confimar}
        </Button>
      </Container>
    </Modal>
  );
};

export default RecuperarFormulario;
