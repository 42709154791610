import styled from 'styled-components';

export const OptionsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #7c7a79;

  pointer-events: auto;

  svg {
    font-size: 1.3em;
  }

  &:hover {
    background: #d3d3d3;
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  z-index: 9999;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
`;

export const ActionBox = styled.div`
  background: #f2f3f8;
`;

export const ActionItem = styled.div`
  padding: 6px 15px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 16px;
  transition: background 0.2s;
  background: #f2f3f8;

  &:hover {
    background: #e8e9ef;
  }
`;
