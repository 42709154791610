import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

export default function ToastSucesso(
  mensagem: string,
  opcoes?: ToastOptions
): void {
  const mensagemComIcone = (
    <span style={{ display: 'flex', alignItems: 'center' }}>{mensagem}</span>
  );
  toast.success(mensagemComIcone, opcoes);
}
