"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCalculoCustoBlocoHEnum;
(function (TipoCalculoCustoBlocoHEnum) {
    TipoCalculoCustoBlocoHEnum["custoCalculadoZeradoConsideraArbitramento"] = "Custo Calculado Zerado cons. Arbitramento";
    TipoCalculoCustoBlocoHEnum["arbitramentoZeradoConsideraCustoCalculado"] = "Arbitramento Zerado cons. Custo Calculado";
    TipoCalculoCustoBlocoHEnum["arbitramento"] = "Arbitramento";
    TipoCalculoCustoBlocoHEnum["calculado"] = "Calculado";
})(TipoCalculoCustoBlocoHEnum || (TipoCalculoCustoBlocoHEnum = {}));
exports.default = TipoCalculoCustoBlocoHEnum;
