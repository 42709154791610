import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerGrupo: {
    marginTop: '5px',
    fontSize: 6,
    fontWeight: 'bold',
  },
});

const NotaFiscalImpressaoDadosAdicionaisPDF: React.FC<{
  dadosAdicionais?: string;
}> = ({ dadosAdicionais }) => {
  return (
    <View>
      <View style={styles.headerGrupo}>
        <Text>DADOS ADICIONAIS</Text>
      </View>

      <View style={{ flexDirection: 'row', height: 100 }}>
        <View
          style={{ width: '60%', border: '1 solid black', borderRadius: 5 }}
        >
          <View
            style={{
              marginLeft: '5px',
              marginRight: '5px',
              fontSize: 6,
              alignItems: 'stretch',
            }}
          >
            <Text>INFORMAÇÕES COMPLEMENTARES</Text>
          </View>
          <View
            style={{
              flex: 1,
              fontSize: 7,
              marginLeft: '5px',
              marginRight: '5px',
              textAlign: 'justify',
            }}
          >
            <Text>{dadosAdicionais}</Text>
          </View>
        </View>

        <View
          style={{ width: '40%', border: '1 solid black', borderRadius: 5 }}
        >
          <View style={{ marginLeft: '5px', fontSize: 6 }}>
            <Text>RESERVADO AO FISCO</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default NotaFiscalImpressaoDadosAdicionaisPDF;
