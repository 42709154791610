import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  ArraysPossuemMesmosValores,
  IContaCategoriaValoresAlterar,
  TipoLancamentoEnum,
} from '@elogestor/util';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseContaListaCategoria } from '../../../Hooks/ContaListaCategoriaHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const { calcularValorTotalCategoria, validarListaContaParcelaCategoria } =
    UseContaListaCategoria();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      { dadosPadrao, dadosObrigatorios } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IContaCategoriaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefLista.current?.setDataInicial({
          tipoLancamento: TipoLancamentoEnum.geral,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          SetarDadosPadrao();

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idCategoria: Yup.string()
            .required('Categoria é Obrigatória!')
            .nullable(),
          tipoLancamento: Yup.string()
            .required('Tipo Lançamento é Obrigatório!')
            .nullable(),
          valor: Yup.number()
            .required('Valor é Obrigatório!')
            .positive('Valor é Obrigatório!'),
          tipo: Yup.number().required('Tipo da Categoria é Obrigatório!'),
          percentualCategoria: Yup.number(),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);

        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        let deveAgrupar = false;
        let indexAgrupamento = 0;

        data.idCategoria = data.categoria ? data.categoria.id : null;
        data.obtidaPorRegra = false;

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'listaContaCategoria'
        );
        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        const listaIdFormaPagamento =
          data.listaContaCategoriaFormaPagamento.listaValor.map(
            (e: any) => e.id
          );

        listaDados.forEach((item: any, index: number) => {
          if (
            item.idCategoria === data.idCategoria &&
            item.tipoLancamento === data.tipoLancamento &&
            item.avista === data.avista
          ) {
            const listaIdFormaPagamentoItem =
              item.listaContaCategoriaFormaPagamento.listaValor.map(
                (e: any) => e.id
              );

            if (
              ArraysPossuemMesmosValores({
                array1: listaIdFormaPagamento,
                array2: listaIdFormaPagamentoItem,
              })
            ) {
              deveAgrupar = true;
              indexAgrupamento = index;
            }
          }
        });

        if (deveAgrupar) {
          listaDados[indexAgrupamento] = {
            ...listaDados[indexAgrupamento],
            valor: Number(listaDados[indexAgrupamento].valor) + data.valor,
            percentualCategoria:
              Number(listaDados[indexAgrupamento].percentualCategoria) +
              data.percentualCategoria,
          };
          calcularValorTotalCategoria(listaDados);

          await validarListaContaParcelaCategoria({
            listaContaCategoria: listaDados,
            listaContaParcela,
          });
        } else {
          formPrincipal.formRef.current?.setFieldValue('listaContaCategoria', [
            ...listaDados,
            data,
          ]);

          await validarListaContaParcelaCategoria({
            listaContaCategoria: [...listaDados, data],
            listaContaParcela,
          });
        }

        formPrincipal.formRef.current?.setFieldValue(
          'listaCategoriaMudou',
          true
        );

        await formRef?.reset();

        setLoading(false);
        formPrincipal.setLoading(false);

        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      calcularValorTotalCategoria,
      formPrincipal,
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
      validarListaContaParcelaCategoria,
    ]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const indice = Number(index);

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'listaContaCategoria'
        );

        const categoriaRemovida = listaDados[indice];

        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        listaDados.splice(indice, 1);

        if (listaContaParcela.length > 0) {
          listaContaParcela.forEach((item: any) => {
            if (item.listaContaParcelaCategoria.length > 0) {
              const indexArrayParcelaCategoria = item.listaContaParcelaCategoria
                .map((e: any) => e.idCategoria)
                .indexOf(categoriaRemovida.idCategoria);

              if (indexArrayParcelaCategoria > -1) {
                item.listaContaParcelaCategoria.splice(
                  indexArrayParcelaCategoria,
                  1
                );
              }
            }

            if (item.listaContaParcelaLiquidacao.length > 0) {
              item.listaContaParcelaLiquidacao.forEach((valor: any) => {
                const indexArrayLiquidacaoCategoria =
                  valor.listaMovimentoPortadorCategoria
                    .map((e: any) => e.idCategoria)
                    .indexOf(categoriaRemovida.idCategoria);

                if (indexArrayLiquidacaoCategoria > -1) {
                  valor.listaMovimentoPortadorCategoria.splice(
                    indexArrayLiquidacaoCategoria,
                    1
                  );
                }
              });
            }
          });
        }

        calcularValorTotalCategoria(listaDados);

        formPrincipal.formRef.current?.setFieldValue(
          'listaContaCategoria',
          listaDados
        );

        formPrincipal.formRef.current?.setFieldValue(
          'listaCategoriaMudou',
          true
        );

        await validarListaContaParcelaCategoria({
          listaContaCategoria: listaDados,
          listaContaParcela,
        });

        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });

      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
