import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import FormHook from './FormHook';
import SubHeaderHook from './SubHeaderHook';
import RotasHook from './RotasHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import AberturaFechamentoCaixaComunicador from '../../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Comunicador/AberturaFechamentoCaixaComunicador';

const AberturaFechamentoCaixaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: AberturaFechamentoCaixaComunicador }}
    >
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default AberturaFechamentoCaixaHook;
