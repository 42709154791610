"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = TratarErrosValidacaoSchema;
function TratarErrosValidacaoSchema(mensagemErro) {
    const listaMensagem = [];
    mensagemErro = mensagemErro.replace('invalid xml (status=WITH_ERRORS)\n\t', '');
    const listaErros = mensagemErro.split('[error]');
    for (let i = 0; i < listaErros.length; i++) {
        let erro = listaErros[i];
        const indexDoisPontos = erro.indexOf(':');
        if (indexDoisPontos > 0) {
            const textoSubstituir = erro.slice(0, indexDoisPontos);
            erro = erro.replace(textoSubstituir, 'ERRO');
            const indexParentesesFinal = erro.lastIndexOf('(');
            if (indexParentesesFinal > 0) {
                const textoSubstituirFinal = erro.slice(indexParentesesFinal);
                erro = erro.replace(textoSubstituirFinal, '');
            }
            listaMensagem.push(erro);
        }
    }
    return listaMensagem;
}
