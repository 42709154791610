import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/configuracao-cr-enologico-cultivar/produto-configuraca-bebida-rastreabilidade-sisdevin';

interface IConfiguracaoBebidaRastreabilidadeSisdevinOrdemComunicadorStore {
  id: string;
  params: any;
}

class ConfiguracaoBebidaRastreabilidadeSisdevinOrdemComunicador {
  async update(
    params: IConfiguracaoBebidaRastreabilidadeSisdevinOrdemComunicadorStore
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}/ordem`, params.params);
    return response.data;
  }
}

export default new ConfiguracaoBebidaRastreabilidadeSisdevinOrdemComunicador();
