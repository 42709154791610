import {
  FormatarDataHoraParaPtBr,
  IAberturaFechamentoCaixaLista,
  SituacaoAberturaFechamentoCaixaEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AberturaFechamentoCaixaLogComunicador from '../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Log/Comunicador/AberturaFechamentoCaixaLogComunicador';
import AberturaFechamentoCaixaComunicador from '../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Comunicador/AberturaFechamentoCaixaComunicador';

const AberturaFechamentoCaixaLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await AberturaFechamentoCaixaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IAberturaFechamentoCaixaLista) => {
            return {
              id: data.id,
              'terminalCaixa.descricao': (
                <div className="lista-texto">
                  {data.terminalCaixa.descricao}
                </div>
              ),
              'usuarioAbertura.nome': (
                <div className="lista-texto">{data.usuarioAbertura.nome}</div>
              ),
              'usuarioFechamento.nome': (
                <div className="lista-texto">
                  {data.usuarioFechamento?.nome}
                </div>
              ),
              dataHoraAbertura: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.dataHoraAbertura)}
                </div>
              ),
              dataHoraFechamento: (
                <div className="lista-data">
                  {FormatarDataHoraParaPtBr(data.dataHoraFechamento)}
                </div>
              ),
              valorEmDinheiro: (
                <div className="lista-valor">
                  {Number(data.valorEmDinheiro).FormatarParaPtBr()}
                </div>
              ),
              situacao: (
                <div
                  className="lista-texto"
                  style={{
                    fontWeight: 'bold',
                    color:
                      data.situacao ===
                      SituacaoAberturaFechamentoCaixaEnum.aberto
                        ? 'green'
                        : 'red',
                  }}
                >
                  {data.situacao}
                </div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await AberturaFechamentoCaixaComunicador.delete({
                          id: String(data.id),
                        });

                        const storage =
                          localStorage.getItem('@EloGestorle:turno');
                        const turno = JSON.parse(String(storage));

                        if (turno && turno.id === String(data.id)) {
                          localStorage.removeItem('@EloGestorle:turno');
                        }

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          AberturaFechamentoCaixaLogComunicador.show({
                            id: String(data.id),
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.ComercialVendasAberturaFechamentoCaixa?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ComercialVendasAberturaFechamentoCaixa]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'comercialAberturaFechamentoCaixa',
        },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Abertura / Fechamento do Caixa"
          permissao={permissoes.ComercialVendasAberturaFechamentoCaixa}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return AberturaFechamentoCaixaLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default AberturaFechamentoCaixaLista;
