import { TipoMovimentoEntradaSaidaEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/tipo-nota/obter-tipo-nota-motivo-padrao';

interface IObterTipoNotaMotivoPadraoComunicadorShow {
  params: {
    descricaoTipoNota: string;
    descricaoTipoNotaMotivo: string;
    tipoMovimento: TipoMovimentoEntradaSaidaEnum;
  };
}

class ObterTipoNotaMotivoPadraoComunicador {
  public async show(
    params: IObterTipoNotaMotivoPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ObterTipoNotaMotivoPadraoComunicador();
