import React from 'react';
import ImpressaoEtiquetasPedidoVendaLista from './Lista';
import ImpressaoEtiquetasPedidoVendaHook from './Hooks';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';

const ImpressaoEtiquetasPedidoVendaListagem: React.FC = () => {
  const storage = localStorage.getItem('@EloGestorle:etiquetaPedidoVenda');

  return (
    <ListaProvider>
      <ImpressaoEtiquetasPedidoVendaHook>
        <ImpressaoEtiquetasPedidoVendaLista
          idPedidoVendaEtiquetaSelecionada={storage}
        />
      </ImpressaoEtiquetasPedidoVendaHook>
    </ListaProvider>
  );
};

export default ImpressaoEtiquetasPedidoVendaListagem;
