import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  AmbienteEnum,
  DiasUteisOuCorridosProtestarEnum,
  FormatarEnum,
  LayoultImpressaoEnum,
  LayoutCobrancaEnum,
  TipoNossoNumeroEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../Componentes/Select';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import InputAutoCompleteEmpresa from '../../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteEmpresa';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import BntVariaveisBoletoButton from '../../../../../../Componentes/Buttons/BntVariaveisBoletoButton';

const BoletoTab: React.FC = () => {
  const { formRef, refresh } = UseForm();

  const utilizaBoleto = formRef.current?.getFieldValue(
    'portadorBoleto.utilizaBoleto'
  );

  const handleAdicionarVariaveis = useCallback(
    async (valor: string, campo: string) => {
      let valorCampo = formRef.current?.getFieldValue(campo);
      valorCampo += ` ${valor}`;
      formRef.current?.setFieldValue(campo, valorCampo.RemoverExcessoEspacos());
    },
    [formRef]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={2} lg={2} md={3} sm={12}>
            <InputSwitch
              name="portadorBoleto.utilizaBoleto"
              label="Utiliza Boleto"
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                refresh();
              }}
            />
          </Col>
          <Col
            xl={5}
            lg={10}
            md={10}
            sm={12}
            style={{ display: utilizaBoleto ? '' : 'none' }}
          >
            <Input
              label="Local Pagamento"
              name="portadorBoleto.localPagamento"
              maxLength={90}
              placeholder="Local Pagamento"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col
            xl={3}
            lg={6}
            md={6}
            sm={12}
            style={{ display: utilizaBoleto ? '' : 'none' }}
          >
            <Input
              label="Cód Cedente / Beneficiário"
              name="portadorBoleto.codigoCedente"
              maxLength={14}
              placeholder="Cód Cedente / Beneficiário"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col
            xl={2}
            lg={3}
            md={6}
            sm={12}
            style={{ display: utilizaBoleto ? '' : 'none' }}
          >
            <InputSwitch
              name="portadorBoleto.aceite"
              label="Aceite"
              ativo="Sim"
              inativo="Não"
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Carteira"
              name="portadorBoleto.carteira"
              maxLength={4}
              placeholder="Carteira"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Número Variação Carteira"
              name="portadorBoleto.numeroVariacaoCarteira"
              maxLength={3}
              placeholder="Número Variação Carteira"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Núm. Conv. Cobrança"
              name="portadorBoleto.numeroConvenioCobrancaCedente"
              maxLength={7}
              placeholder="Núm. Conv. Cobrança"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Posto"
              name="portadorBoleto.posto"
              maxLength={2}
              placeholder="Posto"
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Select
              label="Tipo de Nosso Número"
              name="portadorBoleto.tipoNossoNumero"
              options={FormatarEnum({
                enumObj: TipoNossoNumeroEnum,
                nullavel: true,
              })}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Select
              label="Layout Impressão"
              name="portadorBoleto.layoultImpressao"
              options={FormatarEnum({
                enumObj: LayoultImpressaoEnum,
                nullavel: true,
              })}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Select
              label="Layout de Cobrança"
              name="portadorBoleto.layoutCobranca"
              options={FormatarEnum({
                enumObj: LayoutCobrancaEnum,
                nullavel: true,
              })}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <InputSwitch
              name="portadorBoleto.impressoPelaAgencia"
              label="Impresso Pela Agência"
              ativo="Sim"
              inativo="Não"
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={2} lg={3} md={4} sm={12}>
            <InputSwitch
              name="portadorBoleto.protestar"
              label="Protestar"
              ativo="Sim"
              inativo="Não"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={2} lg={3} md={4} sm={12}>
            <InputInteiroNulavel
              name="portadorBoleto.diasProtesto"
              label="Núm. Dias Protesto"
              placeholder="Núm. Dias Protesto"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={4} sm={12}>
            <Select
              label="Form de Dias p/ Protesto"
              name="portadorBoleto.diasUteisOuCorridosProtestar"
              options={FormatarEnum({
                enumObj: DiasUteisOuCorridosProtestarEnum,
                nullavel: true,
              })}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={2} lg={3} md={4} sm={12}>
            <InputSwitch
              name="portadorBoleto.negativar"
              label="Negativar"
              ativo="Sim"
              inativo="Não"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={4} sm={12}>
            <InputInteiroNulavel
              name="portadorBoleto.diasNegativacao"
              label="Núm. Dias Negativação"
              placeholder="Núm. Dias Negativação"
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={3} lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              name="portadorBoleto.percentualJurosAoMes"
              label="Percentual Juros ao Mês"
              placeholder="Percentual Juros ao mês"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              name="portadorBoleto.percentualMulta"
              label="Percentual de Multa"
              placeholder="Percentual de Multa"
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={9} lg={9} md={12}>
            <Textarea
              name="portadorBoleto.descricaoLivre"
              maxLength={200}
              label="Descrição Livre"
              placeholder="Descrição Livre"
              disabled={!utilizaBoleto}
              style={{ marginBottom: 5 }}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <BntVariaveisBoletoButton
              onClick={(valor) => {
                handleAdicionarVariaveis(
                  valor,
                  'portadorBoleto.descricaoLivre'
                );
              }}
              exibirPercentualMulta={false}
              exibirValorJurosCalculado={false}
              exibirValorMultaCalculado={false}
              exibirPrazoNegativacao={false}
              exibirPrazoProtesto={false}
            />
          </Col>
        </Row>

        <Row style={{ display: utilizaBoleto ? '' : 'none' }}>
          <Col xl={12} lg={12} md={12}>
            <InputAutoCompleteEmpresa
              name="portadorBoleto.idEmpresaCedente"
              nomeObjeto="portadorBoleto.empresaCedente"
              placeholder="Empresa Cedente/ Beneficiário"
              label="Empresa Cedente/ Beneficiário"
              empresaSubdominio
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ display: utilizaBoleto ? '' : 'none' }}>
        <Row>
          <Col xl={2} lg={2} md={6} sm={12}>
            <Input
              name="portadorBoleto.codigoInstrucaoCobranca1"
              placeholder="Código Inst. 1"
              label="Código Inst. 1"
              maxLength={3}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={7} lg={7} md={6} sm={12}>
            <Input
              name="portadorBoleto.descricaoInstrucaoCobranca1"
              label="Descrição da Instrução Cobrança 1"
              placeholder="Descrição da Instrução Cobrança 1"
              maxLength={100}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <BntVariaveisBoletoButton
              onClick={(valor) => {
                handleAdicionarVariaveis(
                  valor,
                  'portadorBoleto.descricaoInstrucaoCobranca1'
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={2} lg={2} md={6} sm={12}>
            <Input
              name="portadorBoleto.codigoInstrucaoCobranca2"
              placeholder="Código Inst. 2"
              label="Código Inst. 2"
              maxLength={3}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={7} lg={7} md={6} sm={12}>
            <Input
              name="portadorBoleto.descricaoInstrucaoCobranca2"
              label="Descrição da Instrução Cobrança 2"
              placeholder="Descrição da Instrução Cobrança 2"
              maxLength={100}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <BntVariaveisBoletoButton
              onClick={(valor) => {
                handleAdicionarVariaveis(
                  valor,
                  'portadorBoleto.descricaoInstrucaoCobranca2'
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={2} lg={2} md={6} sm={12}>
            <Input
              name="portadorBoleto.codigoInstrucaoCobranca3"
              placeholder="Código Inst. 3"
              label="Código Inst. 3"
              maxLength={3}
              autoComplete="off"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={7} lg={7} md={6} sm={12}>
            <Input
              name="portadorBoleto.descricaoInstrucaoCobranca3"
              label="Descrição da Instrução Cobrança 3"
              placeholder="Descrição da Instrução Cobrança 3"
              maxLength={100}
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <BntVariaveisBoletoButton
              onClick={(valor) => {
                handleAdicionarVariaveis(
                  valor,
                  'portadorBoleto.descricaoInstrucaoCobranca3'
                );
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ display: utilizaBoleto ? '' : 'none' }}>
        <Row>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputInteiroNulavel
              name="portadorBoleto.proximoNumeroBoleto"
              label="Próximo Num. Boleto"
              placeholder="Próximo Num. Boleto"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputInteiroNulavel
              name="portadorBoleto.proximoNumeroRemessa"
              label="Próximo Num. Remessa"
              placeholder="Próximo Num. Remessa"
              disabled={!utilizaBoleto}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <Select
              label="Ambiente"
              name="portadorBoleto.ambiente"
              options={FormatarEnum({
                enumObj: AmbienteEnum,
                nullavel: true,
              })}
              disabled={!utilizaBoleto}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default BoletoTab;
