import { IOrdemServicoPaisagemComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import OrdemServicoPaisagemComFotoProdutoImpressaoPDF from './OrdemServicoPaisagemComFotoProdutoImpressaoPDF';

interface IImpressaoOrdemServicoPaisagemComFotoProduto {
  handleVisualizarPdf(
    data: IOrdemServicoPaisagemComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemServicoPaisagemComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemServicoPaisagemComFotoProduto =
  (): IImpressaoOrdemServicoPaisagemComFotoProduto => {
    async function handleVisualizarPdf(
      dados: IOrdemServicoPaisagemComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <OrdemServicoPaisagemComFotoProdutoImpressaoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrdemServicoPaisagemComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <OrdemServicoPaisagemComFotoProdutoImpressaoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoPaisagemComFotoProduto();
