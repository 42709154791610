import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/conhecimento-transporte-terceiros/conhecimento-transporte-terceiro-por-tomador';

interface IConhecimentoTransporteTerceirosPorTomadorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ConhecimentoTransporteTerceirosPorTomadorRelatorioComunicador {
  public async index(
    params: IConhecimentoTransporteTerceirosPorTomadorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ConhecimentoTransporteTerceirosPorTomadorRelatorioComunicador();
