import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/configuracao-tributaria/obter-por-produto';

interface IConfiguracaoTributariaObterConformeProdutoComunicadorShow {
  params: {
    idProduto: string;
    idPessoa: string;
    tipoLancamento: string;
    idLancamento: string | null;
  };
}

class ConfiguracaoTributariaObterConformeProdutoComunicador {
  public async show(
    params: IConfiguracaoTributariaObterConformeProdutoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new ConfiguracaoTributariaObterConformeProdutoComunicador();
