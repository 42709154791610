import { FormatarEnum, TipoCalculoEnum } from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaPisCofins from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaPisCofins';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../../../../../Componentes/Select/index';
import { UseItemCofinsTab } from '../../../Hook/ItemCofinsTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

const ItemImpostoCofinsTab: React.FC = () => {
  const { refresh } = UseListaDetalheForm();
  const { camposEscondidosCofins, camposBloqueadosCofins } = UseItemCofinsTab();
  const { calcularImpostosPorCst } = UseItemTab();

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaPisCofins
              label="CST"
              name="orcamentoItemImpostoCofins.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="orcamentoItemImpostoCofins.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosCofins.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosCofins.calcular}
                label="Calcular COFINS"
                name="orcamentoItemImpostoCofins.calcular"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.tipoCalculo}
          >
            <Select
              disabled={camposBloqueadosCofins.tipoCalculo}
              label="Tipo de Cálculo"
              name="orcamentoItemImpostoCofins.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.baseCalculo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.baseCalculo}
              label="Base de Cálculo"
              name="orcamentoItemImpostoCofins.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosCofins.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.aliquota}
              label="Alíquota"
              name="orcamentoItemImpostoCofins.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.quantidadeUnidade}
              label="Quantidade Unidade"
              name="orcamentoItemImpostoCofins.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.valorUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.valorUnidade}
              label="Valor Unidade"
              name="orcamentoItemImpostoCofins.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosCofins.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.valor}
              label="Valor"
              name="orcamentoItemImpostoCofins.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Retido</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosCofins.calcularRetido}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosCofins.calcularRetido}
                label="Calcular COFINS Retido"
                name="orcamentoItemImpostoCofins.calcularRetido"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.baseCalculoRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.baseCalculoRetido}
              label="Base de Cálculo"
              name="orcamentoItemImpostoCofins.baseCalculoRetido"
              placeholder="Base de Cálculo "
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.aliquotaRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.aliquotaRetido}
              label="Alíquota"
              name="orcamentoItemImpostoCofins.aliquotaRetido"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosCofins.valorRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosCofins.valorRetido}
              label="Valor"
              name="orcamentoItemImpostoCofins.valorRetido"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoCofinsTab;
