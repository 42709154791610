"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var MotivoDesoneracaoIcmsEnum;
(function (MotivoDesoneracaoIcmsEnum) {
    MotivoDesoneracaoIcmsEnum["taxi"] = "1 - T\u00E1xi";
    MotivoDesoneracaoIcmsEnum["deficienteFisico"] = "2 - Deficiente F\u00EDsico";
    MotivoDesoneracaoIcmsEnum["produtorAgropecuario"] = "3 - Produtor Agropecu\u00E1rio";
    MotivoDesoneracaoIcmsEnum["frotistaLocadora"] = "4 - Frotista/Locadora";
    MotivoDesoneracaoIcmsEnum["diplomaticoConsular"] = "5 - Diplom\u00E1tico/Consular";
    MotivoDesoneracaoIcmsEnum["utilitarios"] = "6 - Utilit\u00E1rios e Motocicletas";
    MotivoDesoneracaoIcmsEnum["sufram"] = "7 - SUFRAMA";
    MotivoDesoneracaoIcmsEnum["vendaOrgaoPublico"] = "8 - Venda a \u00D3rg\u00E3os P\u00FAblicos";
    MotivoDesoneracaoIcmsEnum["outros"] = "9 - Outros";
    MotivoDesoneracaoIcmsEnum["deficienteCondutor"] = "10 - Deficiente Condutor (Conv\u00EAnio ICMS 38/12)";
    MotivoDesoneracaoIcmsEnum["deficienteNaoCondutor"] = "11 - Deficiente N\u00E3o Condutor (Conv\u00EAnio ICMS 38/12)";
    MotivoDesoneracaoIcmsEnum["orgaoFomentoDesenvolvimentoAgropecuario"] = "12 - \u00D3rg\u00E3o de Fomento e Desenvolvimento Agropecu\u00E1rio";
})(MotivoDesoneracaoIcmsEnum || (MotivoDesoneracaoIcmsEnum = {}));
exports.default = MotivoDesoneracaoIcmsEnum;
