import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  IBoletosFiltroRelatorioBackend,
  IBoletosFiltroRelatorioFrontend,
  IBoletosResumoCabecalhoRelatorio,
  IBoletosResumoRelatorio,
} from '@elogestor/util';
import React from 'react';
import BoletosResumoRelatorioComunicador from '../../../../../../Comunicador/Financeiro/Relatorios/Boletos/BoletosResumoRelatorio/BoletosResumoRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import BoletosResumoPDF from './BoletosResumoPDF';
import BoletosResumoPlanilha from './BoletosResumoPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IBoletosFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IBoletosFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IBoletosFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IBoletosFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const BoletosResumoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IBoletosFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IBoletosResumoRelatorio | null> => {
    const filtroBack: IBoletosFiltroRelatorioBackend = {
      dataHoraInicial: filtros.dataHoraInicial,
      dataHoraFinal: filtros.dataHoraFinal,
    };

    if (filtros?.listaClientes) {
      filtroBack.listaIdClientes =
        filtros.listaClientes.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaPortadoresLiquidacao) {
      filtroBack.listaIdPortadoresLiquidacao =
        (await filtros.listaPortadoresLiquidacao.listaValor.map(
          (value) => value.id
        )) || [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas =
        filtros.listaEmpresas.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = filtros.tipoData;
    }

    const response = await BoletosResumoRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IBoletosResumoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        return {
          ...dados,
          dataVencimento: FormatarDataParaPtBr(dados.dataVencimento),
          valor: Number(dados.valor).FormatarParaPtBr(),
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      valorTotalSomatorioBoletos: Number(
        retorno.valorTotalSomatorioBoletos
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IBoletosFiltroRelatorioFrontend,
    cabecalho: IBoletosResumoCabecalhoRelatorio
  ): IBoletosResumoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraInicial || filtros.dataHoraFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataHoraInicial = '--/--/--';
      cabecalho.filtroDataHoraFinal = '--/--/--';

      if (filtros.dataHoraInicial) {
        cabecalho.filtroDataHoraInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraInicial
        );
      }

      if (filtros.dataHoraFinal) {
        cabecalho.filtroDataHoraFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraFinal
        );
      }
    }

    if (filtros.tipoData) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoData = filtros.tipoData;
    }

    if (filtros.listaClientes && filtros.listaClientes.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroClientes = filtros.listaClientes.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigoCliente;
        })
        .join(', ');
    }

    if (
      filtros.listaPortadoresLiquidacao &&
      filtros.listaPortadoresLiquidacao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPortadoresLiquidacao =
        filtros.listaPortadoresLiquidacao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IBoletosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: BoletosResumoPlanilha,
      nomeArquivo: 'BoletosResumo',
      valores: dados.listaDados,
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IBoletosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: BoletosResumoPlanilha,
      nomeArquivo: 'BoletosResumo',
      valores: dados.listaDados as any,
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IBoletosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <BoletosResumoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IBoletosFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <BoletosResumoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default BoletosResumoRelatorio();
