import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container } from './styles';
import SubHeader from '../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import IParametroRotaDetalhe from '../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import ContaHook from '../../Movimentacoes/Conta/Detalhe/Hooks';
import Main from '../../Movimentacoes/Conta/Detalhe/Main';
import AcoesConta from '../../Movimentacoes/Conta/Detalhe/AcoesConta';

const AdiantamentoPrevisaoDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <ContaHook>
        <SubHeader
          titulo={
            params.id
              ? 'Editar Adiantamento/Previsão (Receber/Pagar)'
              : 'Adiantamento/Previsão (Receber/Pagar)'
          }
          permissao={permissoes.FinanceiroAdiantamentoPrevisao}
          botoes={
            <AcoesConta
              permissao={permissoes.FinanceiroMovimentacoesContasReceberPagar}
            />
          }
          rotasAtualizadas={{
            backend: '/financeiro/adiantamento-previsao',
            detalhes: '/financeiro/adiantamento-previsao/detalhe',
            listagem: '/financeiro/adiantamento-previsao',
          }}
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
            isAdiantamentoAntecipacao
          />
        </Detalhe>
      </ContaHook>
    </Container>
  );
};

export default AdiantamentoPrevisaoDetalhe;
