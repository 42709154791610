import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IDevolucaoNotasEntradaComNotasDevolvidasRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IDevolucaoNotasEntradaDevolvidasRelatorioPDF {
  dados: IDevolucaoNotasEntradaComNotasDevolvidasRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const DevolucaoNotasEntradaComNotasDevolvidasRelatorioPDF: React.FC<
  IDevolucaoNotasEntradaDevolvidasRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroEmissaoDataInicial &&
                  dados.cabecalho.filtroEmissaoDataFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroEmissaoDataInicial} até ${dados.cabecalho.filtroEmissaoDataFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroMovimentarEstoqueFiscal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Movimenta Estoque Fiscal: '}
                      </Text>
                      {dados.cabecalho.filtroMovimentarEstoqueFiscal}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSerieNfEntrada && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Serie NF Entrada: '}
                      </Text>
                      {dados.cabecalho.filtroSerieNfEntrada}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNumeroNfEntrada && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Número NF Entrada: '}
                      </Text>
                      {dados.cabecalho.filtroNumeroNfEntrada}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNumeroNfSaida && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Número NF Saída: '}
                      </Text>
                      {dados.cabecalho.filtroNumeroNfSaida}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtrosClienteFornecedore && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Clientes/Fornecedores: '}
                      </Text>
                      {dados.cabecalho.filtrosClienteFornecedore}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produtos: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Busca: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '9%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '4%' }} />

            <View style={{ width: '32%' }}>
              <Text>Descrição</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '11%', alignItems: 'flex-end' }}>
              <Text>Quantidade</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '15%', alignItems: 'flex-end' }}>
              <Text>Qtde. Devolvida</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '11%', alignItems: 'flex-end' }}>
              <Text>Saldo</Text>
            </View>
            <View style={{ width: '3%' }} />

            <View style={{ width: '12%' }}>
              <Text>Situacao</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={String(dadoIndex)}>
                    <View
                      style={{
                        borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                        flexDirection: 'row',
                        padding: '5pt 0',
                        fontWeight: 'bold',
                        marginLeft: 2,
                        fontSize: 9,
                      }}
                    >
                      <View style={{ width: '10%' }}>
                        <Text>{`Série: ${dado.serie}`}</Text>
                      </View>

                      <View style={{ width: '2%' }} />

                      <View style={{ width: '15%' }}>
                        <Text>{`Número: ${dado.numero}`}</Text>
                      </View>

                      <View style={{ width: '2%' }} />

                      <View style={{ width: '71%' }}>
                        <Text>{`Cliente: ${dado.codigo} - ${dado.nomeRazaoSocial}`}</Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaItens.map((item, itemIndex) => {
                        return (
                          <View key={String(itemIndex)}>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: 9,
                                backgroundColor:
                                  itemIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <View style={{ width: '9%' }}>
                                  <Text>{item.produto.codigo}</Text>
                                </View>
                                <View style={{ width: '4%' }} />

                                <View style={{ width: '32%' }}>
                                  <Text>{item.produto.descricao}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '11%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{item.quantidade}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '15%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{item.quantidadeDevolvida}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '11%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{item.saldo}</Text>
                                </View>

                                <View style={{ width: '3%' }} />

                                <View style={{ width: '12%' }}>
                                  <Text>{item.situacao}</Text>
                                </View>
                              </View>

                              {item.listaNotaFiscalSaidaItemImportacaoNotaFiscalEntradaItem.map(
                                (importacao) => {
                                  return (
                                    <View
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: 5,
                                      }}
                                    >
                                      <View style={{ width: '5%' }} />

                                      <View
                                        style={{
                                          width: '15%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-end',
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: 4,
                                          }}
                                        >
                                          Num. Nota
                                        </Text>
                                        <Text>
                                          {
                                            importacao.notaFiscalSaidaItem
                                              .notaFiscalSaida.numero
                                          }
                                        </Text>
                                      </View>

                                      <View style={{ width: '5%' }} />

                                      <View
                                        style={{
                                          width: '15%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: 4,
                                          }}
                                        >
                                          Serie
                                        </Text>
                                        <Text>
                                          {
                                            importacao.notaFiscalSaidaItem
                                              .notaFiscalSaida.serie.codigo
                                          }
                                        </Text>
                                      </View>

                                      <View style={{ width: '5%' }} />

                                      <View
                                        style={{
                                          width: '15%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: 4,
                                          }}
                                        >
                                          Dt. Emissao
                                        </Text>
                                        <Text>
                                          {
                                            importacao.notaFiscalSaidaItem
                                              .notaFiscalSaida.dataEmissao
                                          }
                                        </Text>
                                      </View>

                                      <View style={{ width: '5%' }} />

                                      <View
                                        style={{
                                          width: '15%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-end',
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: 4,
                                          }}
                                        >
                                          Qtd. Devolvida
                                        </Text>
                                        <Text>{importacao.quantidade}</Text>
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
            }}
          />
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default DevolucaoNotasEntradaComNotasDevolvidasRelatorioPDF;
