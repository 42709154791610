import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IPadronizacaoDescricaoLista } from '@elogestor/util';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IPadronizacaoDescricaoListaContext {
  validador: boolean;

  listaValor: IPadronizacaoDescricaoLista[];
  setListaValor(lista: IPadronizacaoDescricaoLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaConteudoRef: RefObject<HTMLInputElement[]>;
}

const PadronizacaoDescricaoListaContext =
  createContext<IPadronizacaoDescricaoListaContext>(
    {} as IPadronizacaoDescricaoListaContext
  );

const PadronizacaoDescricaoListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<IPadronizacaoDescricaoLista[]>(
    []
  );

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaConteudoRef = useRef<HTMLInputElement[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IPadronizacaoDescricaoLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <PadronizacaoDescricaoListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaConteudoRef,

        validador: true,
      }}
    >
      {children}
    </PadronizacaoDescricaoListaContext.Provider>
  );
};

function UsePadronizacaoDescricaoLista(): Omit<
  IPadronizacaoDescricaoListaContext,
  'validador'
> {
  const context = useContext(PadronizacaoDescricaoListaContext);

  if (!context.validador) {
    throw new Error(
      'UsePadronizacaoDescricaoLista deve ser usado com um PadronizacaoDescricaoListaHook'
    );
  }

  return context;
}

export { PadronizacaoDescricaoListaHook, UsePadronizacaoDescricaoLista };
