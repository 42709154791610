import {
  DestinadoEnum,
  FormatarEnum,
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMovimentoEntradaSaidaEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputAutoCompleteOperacaoFiscal, {
  IOperacaoFiscal,
} from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputAutoCompleteTipoNota from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsOperacaoInterestadualTab } from '../../../Hook/ItemIcmsOperacaoInterestadualTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseAuth } from '../../../../../../../../../../Hooks/Auth';
import NotaFiscalEntradaObterCfopFiscalEntradaComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterCfopFiscalEntradaComunicador';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';

const ItemImpostoConfiguracaoTributacaoTab: React.FC = () => {
  const { formRefDetalhe, refresh, setLoading } = UseListaDetalheForm();
  const {
    calcularImpostosPorCst,
    obterConfiguracaoTributaria,
    obterRegraEscolhaParametros,
    limparConfiguracaoTributaria,
  } = UseItemTab();
  const { obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();
  const { formRef: formPrincipal } = UseForm();
  const { empresa } = UseAuth();

  const tipoNotaFiscalEntradaTerceiros =
    formPrincipal.current?.getFieldValue('tipoNotaFiscalEntrada') ===
    TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros;
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const indicadorPresenca =
    formPrincipal.current?.getFieldValue('indicadorPresenca');

  const ufPessoa =
    formPrincipal.current?.getFieldValue(
      'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
    ) || null;
  const ufPessoaEntrega =
    formPrincipal.current?.getFieldValue(
      'notaFiscalEntradaPessoaEnderecoEntrega.cidade.estado.sigla'
    ) || null;

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual =
    useCallback(() => {
      obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
    }, [obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual]);

  const handleObterConfiguracaoTributaria = useCallback(() => {
    obterConfiguracaoTributaria();
  }, [obterConfiguracaoTributaria]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleObterOperacaoFiscalEntrada = useCallback(
    async (cfopAtual: IOperacaoFiscal) => {
      const cfopEntrada =
        formRefDetalhe.current?.getFieldValue('idOperacaoFiscal');

      const destinado = formRefDetalhe.current?.getFieldValue('destinado');

      if (
        !cfopEntrada &&
        cfopAtual.tipoEntradaSaida ===
          TipoEntradaSaidaOperacaoFiscalEnum.entrada
      ) {
        formRefDetalhe.current?.setFieldValorInicial(
          'operacaoFiscal',
          cfopAtual
        );
        return;
      }

      if (
        !cfopEntrada &&
        cfopAtual.tipoEntradaSaida === TipoEntradaSaidaOperacaoFiscalEnum.saida
      ) {
        try {
          setLoading(true);

          const cfop =
            await NotaFiscalEntradaObterCfopFiscalEntradaComunicador.show({
              params: {
                idOperacaoFiscalSaida: cfopAtual.id,
                destinado: destinado ?? undefined,
              },
            });

          formRefDetalhe.current?.setFieldValorInicial('operacaoFiscal', cfop);
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar: false });
          setLoading(false);
        }
      }
    },
    [formRefDetalhe, setLoading]
  );

  const handleObterRegraEscolhaParametros = useCallback(() => {
    obterRegraEscolhaParametros();
  }, [obterRegraEscolhaParametros]);

  const handleAtualizarNaturezaOperacao = useCallback(
    (descricao?: string) => {
      if (!descricao) return;

      const naturezaOperacao =
        formRefDetalhe.current?.getFieldValue('naturezaOperacao');
      if (!naturezaOperacao) {
        formRefDetalhe.current?.setFieldValue(
          'naturezaOperacao',
          String(descricao.substring(0, 60))
        );
      }
    },
    [formRefDetalhe]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNota
              label="Tipo de Nota"
              name="idTipoNota"
              placeholder="Tipo de Nota"
              nomeObjeto="tipoNota"
              filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.entrada}
              onChangeItemAtualAposCarregarSemClear={() => {
                formRefDetalhe.current?.clearField('tipoNotaMotivo', {
                  setValorInicial: false,
                });
                refresh();
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNotaMotivo
              label="Motivo"
              name="idTipoNotaMotivo"
              placeholder="Motivo"
              nomeObjeto="tipoNotaMotivo"
              disabled={
                situacaoBloquearCampos ||
                !formRefDetalhe.current?.getFieldValue('idTipoNota')
              }
              idTipoNota={formRefDetalhe.current?.getFieldValue('idTipoNota')}
              onChangeItemAtualAposCarregarSemClear={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Select
              label="Destinado"
              name="destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
                nullavel: true,
              })}
              onChange={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={8} md={12} sm={12} hidden={!tipoNotaFiscalEntradaTerceiros}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP (Documento de Terceiros)"
              name="idOperacaoFiscalDocumentoTerceiros"
              placeholder="CFOP (Documento de Terceiros)"
              nomeObjeto="operacaoFiscalDocumentoTerceiros"
              indicadorPresenca={indicadorPresenca}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem: empresa.uf,
                ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
                indicadorPresenca,
              })}
              onChangeItemAtual={async (event) => {
                if (event.itemAtual) {
                  await handleObterOperacaoFiscalEntrada(event.itemAtual);
                }
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={8} md={12} sm={12}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="idOperacaoFiscal"
              placeholder="CFOP"
              nomeObjeto="operacaoFiscal"
              indicadorPresenca={indicadorPresenca}
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.entrada}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem: empresa.uf,
                ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
                indicadorPresenca,
              })}
              onChangeItemAtualAposCarregarSemClear={(event) => {
                handleObterRegraEscolhaParametros();
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
                handleAtualizarNaturezaOperacao(event.itemAtual?.descricao);
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Input
              label="Natureza Operação"
              name="naturezaOperacao"
              placeholder="Natureza Operação"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoConfiguracaoTributacaoTab;
