import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/engenharia/operacoes-roteiros-fabricacao';

interface IOperacaoRoteiroFabricacaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IOperacaoRoteiroFabricacaoComunicadorShow {
  id: string;
}

interface IOperacaoRoteiroFabricacaoComunicadorStore {
  params: any;
}

interface IOperacaoRoteiroFabricacaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IOperacaoRoteiroFabricacaoComunicadorDeletar {
  id: string;
}

class OperacaoRoteiroFabricacaoComunicador {
  public async index(
    params: IOperacaoRoteiroFabricacaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IOperacaoRoteiroFabricacaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IOperacaoRoteiroFabricacaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IOperacaoRoteiroFabricacaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IOperacaoRoteiroFabricacaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OperacaoRoteiroFabricacaoComunicador();
