import { SplitButton } from 'react-bootstrap';
import styled from 'styled-components';

const SplitBotao = styled(SplitButton)`
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2bcebe !important;
    color: #fff;
    font-weight: 600;
    border: 0;
    &:hover {
      background: #26b9ab !important;
    }
    &:active {
      background: #22a498 !important;
    }
    &:focus {
      background: #2bcebe !important;
      box-shadow: 0 0 0 0.2rem rgba(30, 144, 133, 0.25) !important;
    }
    &:disabled {
      opacity: 0.7;
      color: #b9b9b9;
      background: rgba(30, 144, 133, 0.2) !important;
    }
  }
`;

export default SplitBotao;
