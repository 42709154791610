import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import {
  DesestruturarChaveAcesso,
  SituacaoManifestoTransporteEnum,
  TipoEmitenteEnum,
} from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteCidade from '../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import Input from '../../../../../../../Componentes/Inputs/Input';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IManifestoTransporteDocumentoReferenciadoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const ManifestoTransporteDocumentoReferenciadoDetalhe: React.FC<
  IManifestoTransporteDocumentoReferenciadoModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const formPrincipal = UseForm();
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const tipoEmitente =
    formPrincipal.formRef.current?.getFieldValue('tipoEmitente');

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleObterNumeroChaveAcesso = useCallback(
    (chaveAcesso: string): void => {
      try {
        const informacoesChaveAcesso = DesestruturarChaveAcesso({
          chaveAcesso,
        });

        formRefDetalhe.current?.setFieldValue(
          'numero',
          informacoesChaveAcesso.numero
        );
      } catch (error) {
        const erro = error as any;
        ToastErro(erro.listaMensagem);
        formRefDetalhe.current?.setFieldValue('numero', null);
      }
    },
    [formRefDetalhe]
  );

  return (
    <JanelaDetalhe
      titulo="Documento Fiscal"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              <Divisor>
                <Row>
                  <Col lg={5} md={6} sm={12}>
                    <Input
                      ref={inputRefFocus}
                      label={
                        tipoEmitente ===
                        TipoEmitenteEnum.transportadorCargaPropria
                          ? 'Chave de Acesso NF-e'
                          : 'Chave de Acesso CT-e'
                      }
                      name="chaveAcesso"
                      placeholder={
                        tipoEmitente ===
                        TipoEmitenteEnum.transportadorCargaPropria
                          ? 'Chave de Acesso NF-e'
                          : 'Chave de Acesso CT-e'
                      }
                      maxLength={44}
                      onBlur={(event) => {
                        handleObterNumeroChaveAcesso(event.currentTarget.value);
                      }}
                      disabled={situacaoBloquearCampos}
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <InputInteiroNulavel
                      label="Número"
                      name="numero"
                      placeholder="Número"
                      disabled
                    />
                  </Col>
                  <Col lg={5} md={6} sm={12}>
                    <InputAutoCompleteCidade
                      label="Cidade Descarregamento"
                      name="idCidade"
                      nomeObjeto="cidade"
                      placeholder="Cidade Descarregamento"
                      disabled={situacaoBloquearCampos}
                    />
                  </Col>
                </Row>
              </Divisor>
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <BiSave />
              <span style={{ marginLeft: 10 }}>
                {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
              </span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ManifestoTransporteDocumentoReferenciadoDetalhe;
