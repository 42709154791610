import React, {
  useState,
  forwardRef,
  useRef,
  useCallback,
  useImperativeHandle,
  useEffect,
} from 'react';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ISpedBlocoRegistro from './Interfaces/ISpedBlocoRegistro';

interface IItemSpedBlocoRegistro {
  blocoRegistro: ISpedBlocoRegistro;
  alterarInputPai: (valor: boolean) => void;
}

export interface ISpedBlocoRegistroRef {
  alterarValorInput: (valor: boolean) => void;
  obterValor(): ISpedBlocoRegistro;
  setMudouRef(valor: boolean): void;
  obterMarcado(): ISpedBlocoRegistro | undefined;
}

const ItemSpedBlocoRegistro: React.ForwardRefRenderFunction<
  ISpedBlocoRegistroRef,
  IItemSpedBlocoRegistro
> = ({ blocoRegistro, alterarInputPai }, ref) => {
  const formPrincipal = UseForm();

  const mudouRef = useRef(!!blocoRegistro.mudou);

  const [valorPadrao] = useState(
    blocoRegistro.mudou ? !blocoRegistro.gerar : blocoRegistro.gerar
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    mudouRef.current = !!blocoRegistro.mudou;
  }, [blocoRegistro.mudou]);

  useEffect(() => {
    if (inputRef.current?.checked) {
      inputRef.current.checked = blocoRegistro.gerar;
    }
  }, [blocoRegistro.gerar]);

  const setMudouRef = useCallback((valor: boolean) => {
    mudouRef.current = valor;
  }, []);

  const alterarValorInput = useCallback(
    (valor: boolean) => {
      mudouRef.current = valorPadrao !== valor;

      if (inputRef.current) {
        inputRef.current.checked = valor;
      }
    },
    [valorPadrao]
  );

  const obterValor = useCallback((): ISpedBlocoRegistro => {
    return {
      ...blocoRegistro,
      gerar: inputRef.current?.checked || false,
      mudou: mudouRef.current,
    };
  }, [blocoRegistro]);

  const obterMarcado = useCallback((): ISpedBlocoRegistro | undefined => {
    if (!inputRef.current?.checked) return undefined;

    return {
      ...blocoRegistro,
      gerar: inputRef.current.checked,
      mudou: mudouRef.current,
    };
  }, [blocoRegistro]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      mudouRef.current = valorPadrao !== event.target.checked;
      alterarInputPai(event.target.checked);
    },
    [alterarInputPai, valorPadrao]
  );

  useImperativeHandle(ref, () => {
    return {
      obterValor,
      alterarValorInput,
      setMudouRef,
      obterMarcado,
    };
  });

  return (
    <tr key={v4()}>
      <td>
        <input
          style={{ height: 30 }}
          type="checkbox"
          ref={(instance) => {
            if (instance) {
              inputRef.current = instance;
            }
          }}
          defaultChecked={blocoRegistro.gerar}
          onChange={handleChange}
          disabled={blocoRegistro.desabilitado || formPrincipal.loading}
        />
      </td>
      <td style={{ paddingLeft: 100 }}>
        <span>{`${blocoRegistro.registro} - ${blocoRegistro.descricao}`}</span>
      </td>
    </tr>
  );
};

export default forwardRef(ItemSpedBlocoRegistro);
