import React, { useCallback, useState } from 'react';
import {
  FormatarCnpj,
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaCstCreditoIpiLista,
  RegraEscolhaTipoEnum,
  TipoUsuarioEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaCstCreditoIpiOrdemComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoIpi/Comunicador/RegraEscolhaCstCreditoIpiOrdemComunicador';
import RegraEscolhaCstCreditoIpiLogComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoIpi/Log/Comunicador/RegraEscolhaCstCreditoIpiLogComunicador';
import RegraEscolhaCstCreditoIpiComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoIpi/Comunicador/RegraEscolhaCstCreditoIpiComunicador';

const RegraEscolhaCstCreditoIpiLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const [loading, setLoading] = useState(false);
  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaCstCreditoIpiComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });
        const dados = response.dados[0].map(
          (data: IRegraEscolhaCstCreditoIpiLista) => {
            return {
              id: data.id,

              quantidadeCriterios: (
                <div className="lista-valor">{data.quantidadeCriterios}</div>
              ),

              'listaRegraEscolhaCstCreditoIpiCst.codigoSituacaoTributaria.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoIpiCst
                      ? data.listaRegraEscolhaCstCreditoIpiCst?.map((valor) => {
                          return (
                            <div key={valor.codigoSituacaoTributaria.codigo}>
                              {`${valor.codigoSituacaoTributaria.codigo} - ${valor.codigoSituacaoTributaria.descricao}; `}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoIpiRegimeTributario.regimeTributario':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoIpiRegimeTributario
                      ? data.listaRegraEscolhaCstCreditoIpiRegimeTributario.map(
                          (valor) => {
                            return (
                              <div key={valor.regimeTributario}>
                                {`${valor.regimeTributario}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoIpiDestinado.destinado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstCreditoIpiDestinado
                    ? data.listaRegraEscolhaCstCreditoIpiDestinado.map(
                        (valor) => {
                          return (
                            <div key={valor.destinado}>
                              {`${valor.destinado}; `}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCstCreditoIpiSped.spedTipoProduto.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoIpiSped
                      ? data.listaRegraEscolhaCstCreditoIpiSped.map((valor) => {
                          return (
                            <div key={valor.spedTipoProduto.codigo}>
                              {`${valor.spedTipoProduto.codigo} - ${valor.spedTipoProduto.descricao}; `}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoIpiTipoTransacao.tipoTransacao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstCreditoIpiTipoTransacao
                    ? data.listaRegraEscolhaCstCreditoIpiTipoTransacao.map(
                        (valor) => {
                          return (
                            <div key={valor.tipoTransacao}>
                              {`${valor.tipoTransacao}; `}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCstCreditoIpiGrupoProdutoServico.grupoProdutoServico.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoIpiGrupoProdutoServico
                      ? data.listaRegraEscolhaCstCreditoIpiGrupoProdutoServico.map(
                          (valor) => {
                            return (
                              <div key={valor.grupoProdutoServico.descricao}>
                                {`${valor.grupoProdutoServico.descricao}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoIpiNcm.ncm.codigo': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstCreditoIpiNcm
                    ? data.listaRegraEscolhaCstCreditoIpiNcm.map((valor) => {
                        return (
                          <div key={valor.ncm.codigo}>
                            {`${valor.ncm.codigo}; `}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'regraEscolhaCstCreditoIpiResultado.codigoSituacaoTributaria.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.regraEscolhaCstCreditoIpiResultado &&
                    data.regraEscolhaCstCreditoIpiResultado
                      .codigoSituacaoTributaria
                      ? `${data.regraEscolhaCstCreditoIpiResultado.codigoSituacaoTributaria.codigo} - ${data.regraEscolhaCstCreditoIpiResultado.codigoSituacaoTributaria.descricao}`
                      : ''}
                  </div>
                ),

              'regraEscolhaCstCreditoIpiResultado.creditarIpi': (
                <div
                  className="lista-booleano"
                  style={{
                    color:
                      data.regraEscolhaCstCreditoIpiResultado &&
                      data.regraEscolhaCstCreditoIpiResultado.creditarIpi
                        ? 'green'
                        : 'reed',
                  }}
                >
                  {data.regraEscolhaCstCreditoIpiResultado &&
                  data.regraEscolhaCstCreditoIpiResultado.creditarIpi
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),

              cnpjContabilidade: (
                <div className="lista-texto">
                  {FormatarCnpj(String(data.cnpjContabilidade))}
                </div>
              ),
              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaCstCreditoIpiEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaCstCreditoIpiEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaCstCreditoIpiEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div className="lista-texto">
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaCstCreditoIpiComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaCstCreditoIpiLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      (permissoes
                        .FiscalTributacaoRegraEscolhaCstsCreditosPisCofins
                        ?.exclui &&
                        tipoUsuario === TipoUsuarioEnum.eloGestor) ||
                      (tipoUsuario === TipoUsuarioEnum.contabilidade &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao) ||
                      (tipoUsuario === TipoUsuarioEnum.colaborador &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao) ||
                      (tipoUsuario === TipoUsuarioEnum.gestor &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao)
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      permissoes.FiscalTributacaoRegraEscolhaCstsCreditosPisCofins?.exclui,
      tipoUsuario,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'fiscalRegraEscolhaCstCreditoIpi',
        },
      });
      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaCstCreditoIpiOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra dos CSTs/Créditos de IPI das Entradas"
          permissao={permissoes.FiscalTributacaoRegraEscolhaCstsCreditosIpi}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaCstCreditoIpiLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaCstCreditoIpiLista;
