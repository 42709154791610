import api from '../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/estrutura-produto';

interface IEstruturaProdutoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IEstruturaProdutoComunicadorShow {
  id: string;
}

interface IEstruturaProdutoComunicadorStore {
  params: any;
}

interface IEstruturaProdutoComunicadorUpdate {
  id: string;
  params: any;
}

interface IEstruturaProdutoComunicadorDeletar {
  id: string;
}

class EstruturaProdutoComunicador {
  public async index(params: IEstruturaProdutoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }

  public async show(params: IEstruturaProdutoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IEstruturaProdutoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IEstruturaProdutoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEstruturaProdutoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EstruturaProdutoComunicador();
