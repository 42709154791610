import api from '../../../../../Comum/Services/Api';

const rota =
  'transporte/conhecimento-transporte/transmissao/evento/exportar-xml';

interface IConhecimentoTransporteTransmissaoEventoExportarXmlComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTransmissaoEventoExportarXmlComunicador {
  public async show(
    params: IConhecimentoTransporteTransmissaoEventoExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoExportarXmlComunicador();
