import {
  FormatarEnum,
  IGrupoProdutoValoresAlterar,
  TipoRecipienteBebidaSisdevinEnum,
  TipoConfiguracaoCREnologicoCultivarEnum,
  StringConverterParaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import Select from '../../../../../../Componentes/Select';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import { UseProdutoUnidadeMedida } from '../Hooks/UnidadeMedidaHook';
import ListaConfiguracaoBebidaRastreabilidadeSisdevin from '../ListaDetalheConfiguracaoBebidaRastreabilidadeSisdevin/Lista';
import GrupoProdutoCodigoRastreabilidadeHook from '../ListaDetalheConfiguracaoBebidaRastreabilidadeSisdevin/Hooks/index';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputAutoCompleteCultivarSisdevin from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteCultivarSisdevin';
import InputAutoCompleteProdutoEnologicoSisdevin from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoEnologicoSisdevin';
import InputAutoCompleteUnidadeMedida from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import ObterProdutosConfiguradosComunicador from '../../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/ObterProdutosConfigurados/Comunicador/ObterProdutosConfiguradosComunicador';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';

type IProps = IMain<IGrupoProdutoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosSisdevinCodigoRastreabilidade: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const { obterProdutoUnidadeMedida, obterEnologicoUnidadeMedida } =
    UseProdutoUnidadeMedida();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    if (!idDetalheRegistro) {
      formRef.current?.setFieldDisabled('produto', false);
      formRef.current?.setFieldDisabled('tipo', false);
    }
  }, [formRef, idDetalheRegistro]);

  const [tipoAtual, setTipoAtual] =
    useState<TipoConfiguracaoCREnologicoCultivarEnum>(
      TipoConfiguracaoCREnologicoCultivarEnum.bebida
    );

  const [listaProdutosConfigurados, setListaProdutosConfigurados] = useState<
    string[]
  >([]);

  useEffect(() => {
    (async () => {
      const lista = await ObterProdutosConfiguradosComunicador.index();
      setListaProdutosConfigurados([...lista]);
    })();
  }, []);

  const selectTipoValue = formRef.current?.getFieldValue('tipo');
  useEffect(() => {
    const tipoConfiguracao = StringConverterParaEnum<
      typeof TipoConfiguracaoCREnologicoCultivarEnum,
      TipoConfiguracaoCREnologicoCultivarEnum
    >(TipoConfiguracaoCREnologicoCultivarEnum, String(selectTipoValue));
    setTipoAtual(tipoConfiguracao);
  }, [selectTipoValue]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData() as any;
    if (!data) return;

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleObterProdutoUnidadeMedida = useCallback(() => {
    if (idDetalheRegistro) return;

    obterProdutoUnidadeMedida();
  }, [idDetalheRegistro, obterProdutoUnidadeMedida]);

  const handleObterEnologicoUnidadeMedida = useCallback(() => {
    if (idDetalheRegistro) return;

    obterEnologicoUnidadeMedida();
  }, [idDetalheRegistro, obterEnologicoUnidadeMedida]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={7} md={12} sm={12}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                label="Produto"
                name="idProduto"
                nomeObjeto="produto"
                placeholder="Produto"
                listaIdParaRemover={listaProdutosConfigurados}
                onChangeItemAtual={handleObterProdutoUnidadeMedida}
                disabled={!!idEditar}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteUnidadeMedida
                label="Unidade"
                name="produtoEstoque.idUnidadeMedidaEstoque"
                nomeObjeto="produtoEstoque.unidadeMedida"
                placeholder="Unidade"
                permitirAdicionar={false}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                name="tipo"
                label="Tipo"
                disabled={!!idEditar}
                onChange={(event) => {
                  formRef?.current?.setErrors({});

                  const tipoConfiguracao = StringConverterParaEnum<
                    typeof TipoConfiguracaoCREnologicoCultivarEnum,
                    TipoConfiguracaoCREnologicoCultivarEnum
                  >(
                    TipoConfiguracaoCREnologicoCultivarEnum,
                    String(event.target.value)
                  );

                  setTipoAtual(tipoConfiguracao);
                }}
                options={FormatarEnum({
                  enumObj: TipoConfiguracaoCREnologicoCultivarEnum,
                })}
              />
            </Col>
          </Row>

          <Row
            style={{
              display: `${
                tipoAtual === TipoConfiguracaoCREnologicoCultivarEnum.bebida
                  ? 'flex'
                  : 'none'
              }`,
            }}
          >
            <Col lg={3} md={6} sm={12}>
              <Select
                name="produtoConfiguracaoBebidaSisdevin.tipoRecipiente"
                label="Tipo Recipiente"
                options={FormatarEnum({
                  enumObj: TipoRecipienteBebidaSisdevinEnum,
                })}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputDecimal
                label="Litragem"
                name="produtoConfiguracaoBebidaSisdevin.litragem"
                casasDecimais={3}
                casasInteiras={13}
              />
            </Col>
          </Row>

          <Row
            style={{
              display: `${
                tipoAtual === TipoConfiguracaoCREnologicoCultivarEnum.cultivar
                  ? 'flex'
                  : 'none'
              }`,
            }}
          >
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteCultivarSisdevin
                name="produtoConfiguracaoCultivarSisdevin.idCultivarSisdevin"
                label="Cultivar do Sisdevin"
                placeholder="Cultivar do Sisdevin"
                nomeObjeto="produtoConfiguracaoCultivarSisdevin.cultivarSisdevin"
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputInteiro
                label="Grau babo"
                name="produtoConfiguracaoCultivarSisdevin.grau"
                placeholder="0"
              />
            </Col>
          </Row>

          <Row
            style={{
              display: `${
                tipoAtual === TipoConfiguracaoCREnologicoCultivarEnum.enologico
                  ? 'flex'
                  : 'none'
              }`,
            }}
          >
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteProdutoEnologicoSisdevin
                name="produtoConfiguracaoProdutoEnologicoSisdevin.idProdutoEnologicoSisdevin"
                label="Produto Enológico do Sisdevin"
                placeholder="Produto Enológico do Sisdevin"
                nomeObjeto="produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico"
                onChangeItemAtual={handleObterEnologicoUnidadeMedida}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Input
                label="Unidade"
                placeholder="Unidade"
                name="produtoConfiguracaoProdutoEnologicoSisdevin.produtoEnologico.siglaUnidadeMedida"
                disabled
                maxLength={2}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDecimal
                label="Fator de Conversão"
                name="produtoConfiguracaoProdutoEnologicoSisdevin.fatorConversao"
                casasDecimais={8}
                casasInteiras={4}
              />
            </Col>
          </Row>
        </Divisor>

        <div
          style={{
            display: `${
              tipoAtual === TipoConfiguracaoCREnologicoCultivarEnum.bebida
                ? 'flex'
                : 'none'
            }`,
          }}
        >
          <GrupoProdutoCodigoRastreabilidadeHook>
            <ListaConfiguracaoBebidaRastreabilidadeSisdevin name="produtoConfiguracaoBebidaSisdevin.listaProdutoConfiguracaoBebidaRastreabilidadeSisdevin" />
          </GrupoProdutoCodigoRastreabilidadeHook>
        </div>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
