import api from '../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/situacao';

interface ISituacaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ISituacaoComunicadorShow {
  id: string;
}

interface ISituacaoComunicadorStore {
  params: any;
}

interface ISituacaoComunicadorUpdate {
  id: string;
  params: any;
}

interface ISituacaoComunicadorDeletar {
  id: string;
}

class SituacaoComunicador {
  public async index(params: ISituacaoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ISituacaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ISituacaoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: ISituacaoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ISituacaoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new SituacaoComunicador();
