import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/obter-pessoa-padrao-transferencia-nota-fiscal-saida';

interface INotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicadorShow {
  id: string;
}

class NotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicador {
  public async show(
    params: INotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicador();
