"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = RateioComCasaDecimalCalculo;
function RateioComCasaDecimalCalculo(dados) {
    const vetor = [];
    let total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    let diferencaValoreseValorTotal = 0;
    if (dados.valorIgualSomaValores &&
        dados.valorIgualSomaValores === true &&
        dados.diferencaUltimoValor &&
        dados.diferencaUltimoValor === true) {
        const diferencaValoreseValorTotalNaoArredondado = Number(dados.valor) - Number(total).Arredondar(2);
        diferencaValoreseValorTotal =
            diferencaValoreseValorTotalNaoArredondado.Arredondar(dados.casasDecimais);
        // Atualiza o valor total
        total = dados.valor;
        // ultima posicao
        dados.valores.splice(dados.valores.length - 1, 1, dados.valores[dados.valores.length - 1] + diferencaValoreseValorTotal);
    }
    if (total === 0) {
        for (let i = 0; i < dados.valores.length; i++)
            dados.valores.splice(i, 1, 1);
        total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    }
    for (let i = 0; i < dados.valores.length; i++) {
        const peso = (dados.valores[i] / total) * 100;
        const valorPercentual = dados.valor.CalcularPercentual(peso);
        vetor.push(valorPercentual.Arredondar(dados.casasDecimais));
    }
    const somatorio = vetor.reduce((totalAcumulado, currentElement) => Number(totalAcumulado) + Number(currentElement), 0);
    let diferenca = (dados.valor - somatorio).Arredondar(dados.casasDecimais);
    const fator = 1 / Math.pow(10, dados.casasDecimais);
    if (diferenca > 0) {
        let j = vetor.length - 1;
        while (diferenca > 0) {
            const valor = vetor[j] + fator;
            vetor.splice(j, 1, valor);
            diferenca -= fator;
            if (dados.diferencaUltimoValor !== true)
                j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    else if (diferenca < 0) {
        let j = vetor.length - 1;
        while (diferenca < 0) {
            const valor = vetor[j] - fator;
            vetor.splice(j, 1, valor);
            diferenca += fator;
            if (dados.diferencaUltimoValor !== true)
                j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    return vetor;
}
