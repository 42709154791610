"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IpiObterVisibilidadeBloqueioCamposPorCst;
const Enum_1 = require("../../Enum");
function IpiObterVisibilidadeBloqueioCamposPorCst(dados) {
    const retorno = {
        escondido: {
            calcular: false,
            tipoCalculo: true,
            quantidadeUnidade: true,
            valorUnidade: true,
            baseCalculo: true,
            aliquota: true,
            valor: true,
            ipiCodigoEnquadramentoLegal: false,
            classeEnquadramento: false,
            cnpjProdutor: false,
            quantidadeSelo: false,
            codigoSelo: false,
        },
        bloqueado: {
            calcular: false,
            tipoCalculo: true,
            quantidadeUnidade: true,
            valorUnidade: true,
            baseCalculo: true,
            aliquota: true,
            valor: true,
            ipiCodigoEnquadramentoLegal: false,
            classeEnquadramento: true,
            cnpjProdutor: false,
            quantidadeSelo: false,
            codigoSelo: false,
        },
    };
    if (!dados.calcular) {
        retorno.bloqueado.tipoCalculo = false;
        retorno.bloqueado.quantidadeUnidade = false;
        retorno.bloqueado.valorUnidade = false;
        retorno.bloqueado.baseCalculo = false;
        retorno.bloqueado.aliquota = false;
        retorno.bloqueado.valor = false;
    }
    if (dados.cst === '00' ||
        dados.cst === '49' ||
        dados.cst === '50' ||
        dados.cst === '99') {
        if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.percentual) {
            retorno.escondido.baseCalculo = false;
            retorno.escondido.aliquota = false;
        }
        if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.valor) {
            retorno.escondido.quantidadeUnidade = false;
            retorno.escondido.valorUnidade = false;
        }
        retorno.escondido.tipoCalculo = false;
        retorno.escondido.valor = false;
    }
    if (dados.cst === '01' ||
        dados.cst === '02' ||
        dados.cst === '03' ||
        dados.cst === '04' ||
        dados.cst === '05' ||
        dados.cst === '51' ||
        dados.cst === '52' ||
        dados.cst === '53' ||
        dados.cst === '54' ||
        dados.cst === '55') {
        retorno.escondido.calcular = true;
        retorno.bloqueado.calcular = true;
        retorno.bloqueado.tipoCalculo = true;
        retorno.bloqueado.quantidadeUnidade = true;
        retorno.bloqueado.valorUnidade = true;
        retorno.bloqueado.baseCalculo = true;
        retorno.bloqueado.aliquota = true;
        retorno.bloqueado.valor = true;
    }
    return retorno;
}
