import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/veiculo/motorista';

interface IVeiculoMotoristaComunicadorStore {
  params: any;
}

interface IVeiculoMotoristaComunicadorDelete {
  id: string;
}

class VeiculoMotoristaComunicador {
  public async store(params: IVeiculoMotoristaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IVeiculoMotoristaComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new VeiculoMotoristaComunicador();
