import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { IFrenteCaixaPdvItemLista } from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import FrenteCaixaPdvDevolucaoItemRetiradaConsignadoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvDevolucaoItemRetiradaConsignadoComunicador';

interface IDevolucaoItemModal {
  item: IFrenteCaixaPdvItemLista;
  onSalvarFormModal(sequencia: number, quantidade: number): void;
  onFecharFormModal(): void;
}

const DevolucaoItemDetalhe: React.FC<IDevolucaoItemModal> = ({
  item,
  onSalvarFormModal,
  onFecharFormModal,
}) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        quantidadeDevolver: Yup.mixed()
          .nullable()
          .test({
            message: 'Quantidade deve se maior que Zero!',
            test: (value: any) => {
              if (value === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(item, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef, item]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (!(await handleValidar())) {
      return;
    }

    try {
      setLoading(true);

      const quantidadeDevolver =
        formRef.current?.getFieldValue('quantidadeDevolver');

      await FrenteCaixaPdvDevolucaoItemRetiradaConsignadoComunicador.update({
        id: String(item.id),
        params: { quantidadeDevolver },
      });

      if (onSalvarFormModal)
        onSalvarFormModal(item.sequencia, quantidadeDevolver);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleValidar, item.id, item.sequencia, onSalvarFormModal]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, formRef, onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      formRef.current?.setDataInicialSemExecutarEvento({
        quantidadeNaoDevolvida: item.quantidade,
        quantidadeDevolver: item.quantidade,
        quantidadeRestante: 0,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [item]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtualizarQuantidadeRestante = useCallback(
    async (quantidadeDevolver: number) => {
      const quantidadeNaoDevolvida = formRef.current?.getFieldValue(
        'quantidadeNaoDevolvida'
      );

      const quantidadeRestante =
        Number(quantidadeNaoDevolvida) - Number(quantidadeDevolver);

      formRef.current?.setFieldValue('quantidadeRestante', quantidadeRestante);
      refresh();
    },
    [refresh]
  );

  return (
    <JanelaDetalhe
      titulo="Devolução de Item do Consignado"
      tamanho="lg"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={3} md={4} sm={12}>
                <InputLabel
                  label="Quantidade não Devolvida"
                  name="quantidadeNaoDevolvida"
                  formatarValor={(value) => {
                    return (
                      <div>
                        {Number(value).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    );
                  }}
                  valorStyle={{ justifyContent: 'flex-end' }}
                />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <InputDecimal
                  label="Quantidade Devolver"
                  name="quantidadeDevolver"
                  placeholder="Quantidade Devolver"
                  casasDecimais={4}
                  onChange={(event) => {
                    let valor = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    const quantidadeNaoDevolvida =
                      formRef.current?.getFieldValue('quantidadeNaoDevolvida');

                    if (valor > Number(quantidadeNaoDevolvida)) {
                      valor = quantidadeNaoDevolvida;

                      formRef.current?.setFieldValue(
                        'quantidadeDevolver',
                        valor
                      );
                    }

                    handleAtualizarQuantidadeRestante(valor);
                  }}
                />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <InputLabel
                  label="Saldo"
                  name="quantidadeRestante"
                  formatarValor={(value) => {
                    return (
                      <div>
                        {Number(value).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    );
                  }}
                  valorStyle={{ justifyContent: 'flex-end' }}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickSalvar}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default DevolucaoItemDetalhe;
