/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  AmbienteEnum,
  AppErro,
  EnumObterValue,
  ModeloImpressaoNotaFiscalSaidaEnum,
  SituacaoNotaFiscalSaidaEnum,
  ObterTipoMercado,
} from '@elogestor/util';
import { GiMoneyStack } from 'react-icons/gi';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { MdCancel, MdOutlineAttachEmail } from 'react-icons/md/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { FiFile } from 'react-icons/fi/index.mjs';
import { useNavigate } from 'react-router-dom';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoNotaFiscalSaidaEtiqueta from './Impressao/Template/NotaFiscalSaidaEtiquetaImpressao';
import ImpressaoNotaFiscalConsumidor from '../../../../../DocumentosEletronicos/NFCe/Impressao';
import ImpressaoNotaFiscalComprovanteTef from '../../../../../DocumentosEletronicos/NFCe/ImpressaoComprovanteTef';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import NotaFiscalSaidaA4ImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalSaidaA4Impressao/NotaFiscalSaidaA4ImpressaoComunicador';
import NotaFiscalSaidaRomaneioCargaComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterRomaneioCargaComunicador';
import NotaFiscalSaidaEtiquetaImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalSaidaEtiquetaImpressao/NotaFiscalSaidaEtiquetaImpressaoComunicador';
import { UseNotaFiscalSaida } from '../Hook/NotaFiscalSaidaHook';
import { UseNotaFiscalSaidaImportacaoPedidoVenda } from '../../../NotaFiscalSaidaImportacaoPedidoVendaHook';
import CancelarHook from './Cancelar/Hooks';
import CancelarDetalhe from './Cancelar/Detalhe';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import NotaFiscalSaidaTransmitirComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaTransmitirComunicador';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ImpressaoNotaFiscal from '../../../../../DocumentosEletronicos/NFe/Impressao/index';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import NotaFiscalSaidaExportarXmlComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaExportarXmlComunicador';
import VisualizarPDF from '../../../../../../Util/Relatorios/VisualizarPDF';
import NotaFiscalSaidaUploadPdfComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaUploadPdfComunicador';
import CartaCorrecaoLista from './CartaCorrecao/Lista';
import CartaCorrecaoHook from './CartaCorrecao/Hook';
import NotaFiscalSaidaConsultarProtocoloComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaConsultarProtocoloComunicador';
import DocumentosReferenciadosHook from './DocumentosReferenciados/Hook';
import DocumentosReferenciadosLista from './DocumentosReferenciados/Lista';
import { UseNotaFiscalSaidaImportacaoNotaFiscalEntrada } from '../../../NotaFiscalSaidaImportacaoNotaFiscalEntradaHook';
import { UseAuth } from '../../../../../../Hooks/Auth';
import NotaFiscalConsumidorImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalConsumidorImpressao/NotaFiscalConsumidorImpressaoComunicador';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import { UseNotaFiscalEntradaImportacaoNotaFiscalSaida } from '../../../../../Suprimentos/Compras/NotaFiscalEntradaImportacaoNotaFiscalSaidaHook';
import NotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicador';
import ContaParcelaBoletoHook from '../../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Hook';
import ContaParcelaBoletoLista from '../../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Lista';
import { ContaListaParcelaBoletoHook } from '../../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Hook/ContaListaParcelaBoletoHook';
import NotaFiscalSaidaObterDadosContaParcelaBoletoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterDadosContaParcelaBoletoComunicador';
import { UseNotaFiscalSaidaImportacaoOrdemServico } from '../../../NotaFiscalSaidaImportacaoOrdemServicoHook';
import ImpressaoNotaFiscalSaidaRomaneioCarga from './Impressao/Template/RomaneioDeCarga';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';
import FrenteCaixaPdvObterPagamentosSiTefComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPagamentosSiTefComunicador';

interface IAcoesNotaFiscalSaida {
  permissao?: IPermissao;
}

const AcoesNotaFiscalSaida: React.FC<IAcoesNotaFiscalSaida> = ({
  permissao,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    handleValidar: handleValidarForm,
  } = UseForm();
  const parametros = UseParametros();
  const liberacoes = UseLiberacoes();
  const { abrirJanela } = UseConfirmacao();
  const { empresa } = UseAuth();
  const navigate = useNavigate();

  const { listaRepresentante } = UseNotaFiscalSaida();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showCancelar, setShowCancelar] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showCartaCorrecao, setShowCartaCorrecao] = useState(false);
  const [showDocumentosReferenciados, setShowDocumentosReferenciados] =
    useState(false);
  const [transmitirNotaFiscal, setTransmitirNotaFiscal] = useState(false);
  const [showContaParcelaBoleto, setShowContaParcelaBoleto] = useState(false);
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [idConta, setIdConta] = useState('');
  const [idPessoaConta, setIdPessoaConta] = useState('');
  const [idNotaFiscalSaida, setIdNotaFiscalSaida] = useState('');
  const [idConhecimentoTransporte, setIdConhecimentoTransporte] = useState('');

  const [possuiPagamentoSiTef, setPossuiPagamentoSiTef] = useState(false);

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoPedidoVenda,
    abrirNotaFiscalSaidaImportacaoPedidoVenda,
  } = UseNotaFiscalSaidaImportacaoPedidoVenda();

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoOrdemServico,
    abrirNotaFiscalSaidaImportacaoOrdemServico,
  } = UseNotaFiscalSaidaImportacaoOrdemServico();

  const {
    setTitulo: setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida,
    abrirNotaFiscalEntradaImportacaoNotaFiscalSaida,
  } = UseNotaFiscalEntradaImportacaoNotaFiscalSaida();

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada,
    abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada,
  } = UseNotaFiscalSaidaImportacaoNotaFiscalEntrada();

  const situacaoNotaFiscalSaida = formRef.current?.getFieldValue('situacao');
  const idPessoa = formRef.current?.getFieldValue(
    'notaFiscalSaidaPessoa.idPessoa'
  );

  const vendaPdv = formRef.current?.getFieldValue('vendaPdv');

  const modalidadeFrete = formRef.current?.getFieldValue(
    'notaFiscalSaidaTransporteTransportadora.modalidadeFrete'
  );
  const numero = formRef.current?.getFieldValue('numero');
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');

  const handleCarregar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (idRegistroDetalhe) {
        const response =
          await FrenteCaixaPdvObterPagamentosSiTefComunicador.show({
            id: idRegistroDetalhe,
          });

        if (response && response.length > 0) {
          setPossuiPagamentoSiTef(true);
        } else {
          setPossuiPagamentoSiTef(false);
        }
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idRegistroDetalhe, setLoading]);

  useEffect(() => {
    handleCarregar();
  }, [handleCarregar, handleCarregarDados, idNotaFiscalSaida]);

  const handleValidar = useCallback(
    async (
      situacaoNotaFiscalSaidaValidar?: SituacaoNotaFiscalSaidaEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});
        if (
          situacaoNotaFiscalSaidaValidar &&
          situacaoNotaFiscalSaidaValidar !==
            SituacaoNotaFiscalSaidaEnum.naoTransmitida
        )
          return true;

        if (
          parametros.RepresentanteObrigatorioNotaFiscalSaida &&
          listaRepresentante.length < 1
        ) {
          throw new AppErro({
            mensagem: 'No mínimo um Representante é obrigatório!',
          });
        }

        if (!modalidadeFrete) {
          return true;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [
      formRef,
      listaRepresentante.length,
      modalidadeFrete,
      parametros.RepresentanteObrigatorioNotaFiscalSaida,
    ]
  );

  const handleSalvar = useCallback(
    async (
      situacaoNotaFiscalSaidaValidar?: SituacaoNotaFiscalSaidaEnum
    ): Promise<boolean> => {
      try {
        const data: any = formRef.current?.getData();

        if (!(await handleValidarForm(data))) {
          setLoading(false);
          return false;
        }

        if (!(await handleValidar(situacaoNotaFiscalSaidaValidar)))
          return false;

        if (formRef.current?.validarSeAlterou()) {
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar, handleValidarForm, setLoading]
  );

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      if (!vendaPdv) {
        switch (parametros.ModeloParaImpressaoNotaFiscalSaida) {
          case ModeloImpressaoNotaFiscalSaidaEnum.a4:
            {
              const response = await NotaFiscalSaidaA4ImpressaoComunicador.show(
                { id: idRegistro }
              );

              if (response.pdf) {
                if (visualizarPdf) {
                  VisualizarPDF({ dados: response.pdf });
                }

                blobPdf = response.pdf;
                pdfSalvo = true;
              } else {
                if (visualizarPdf) {
                  await ImpressaoNotaFiscal.handleVisualizarPdf(
                    response.impressao
                  );
                }

                blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                  response.impressao
                );
                pdfSalvo = false;
              }
            }
            break;

          case ModeloImpressaoNotaFiscalSaidaEnum.etiqueta:
            {
              const response =
                await NotaFiscalSaidaEtiquetaImpressaoComunicador.show({
                  id: idRegistro,
                });
              if (response.pdf) {
                if (visualizarPdf) {
                  VisualizarPDF({ dados: response.pdf });
                }

                blobPdf = response.pdf;
                pdfSalvo = true;
              } else {
                if (visualizarPdf) {
                  await ImpressaoNotaFiscalSaidaEtiqueta.handleVisualizarPdf(
                    response.impressao
                  );
                }

                blobPdf =
                  await ImpressaoNotaFiscalSaidaEtiqueta.handleObterBlobPdf(
                    response.impressao
                  );
                pdfSalvo = false;
              }
            }
            break;
        }
      } else {
        const response = await NotaFiscalConsumidorImpressaoComunicador.show({
          id: idRegistro,
        });

        if (response.pdf) {
          if (visualizarPdf) {
            VisualizarPDF({ dados: response.pdf });
          }

          blobPdf = response.pdf;
          pdfSalvo = true;
        } else {
          if (visualizarPdf) {
            await ImpressaoNotaFiscalConsumidor.handleVisualizarPdf(
              response.impressao
            );
          }

          blobPdf = await ImpressaoNotaFiscalConsumidor.handleObterBlobPdf(
            response.impressao
          );
          pdfSalvo = false;
        }

        if (
          response.listaMovimentoPortadorTef &&
          response.listaMovimentoPortadorTef.length > 0
        ) {
          for (let i = 0; i < response.listaMovimentoPortadorTef.length; i++) {
            const movimentoPortadorTef = response.listaMovimentoPortadorTef[i];

            if (movimentoPortadorTef.comprovanteCliente) {
              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                movimentoPortadorTef.comprovanteCliente
              );
            }

            if (movimentoPortadorTef.comprovanteEstabelecimento) {
              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                movimentoPortadorTef.comprovanteEstabelecimento
              );
            }

            if (movimentoPortadorTef.comprovanteClienteCancelamento) {
              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                movimentoPortadorTef.comprovanteClienteCancelamento
              );
            }

            if (movimentoPortadorTef.comprovanteEstabelecimentoCancelamento) {
              await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                movimentoPortadorTef.comprovanteEstabelecimentoCancelamento
              );
            }
          }
        }
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [
      getIdDetalheRegistro,
      parametros.ModeloParaImpressaoNotaFiscalSaida,
      vendaPdv,
    ]
  );

  const handleVisualizarImpressao = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoNotaFiscalSaida))) {
        return;
      }

      setLoading(true);

      await handleVisualizarObterBlobPdf(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    handleVisualizarObterBlobPdf,
    setLoading,
    situacaoNotaFiscalSaida,
  ]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoNotaFiscalSaida))) {
        return;
      }

      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formRef.current?.getFieldValue('transmissao.ambiente');

        const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
        const situacaoNotaFiscalSaidaFormatado = EnumObterValue(
          SituacaoNotaFiscalSaidaEnum,
          situacaoNotaFiscalSaida
        );

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#ambiente:${ambienteFormatado}#situacao:${situacaoNotaFiscalSaidaFormatado}`
        );

        await NotaFiscalSaidaUploadPdfComunicador.update({
          params: formData,
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleSalvar,
    handleVisualizarObterBlobPdf,
    setLoading,
    situacaoNotaFiscalSaida,
  ]);

  const handleImprimirEtiquetasNotaFiscal = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      window.open('/comercial/vendas/impressao-etiquetas-nota-fiscal');
      localStorage.setItem('@EloGestorle:etiquetaNotaFiscal', idRegistro);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  const handleImprimirRomanioCarga = useCallback(async () => {
    try {
      setLoading(true);
      const idRegistro = getIdDetalheRegistro() || '';

      const dadosRomaneioCarga =
        await NotaFiscalSaidaRomaneioCargaComunicador.show({ id: idRegistro });

      ImpressaoNotaFiscalSaidaRomaneioCarga.handleVisualizarPdf(
        dadosRomaneioCarga
      );

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  const handleExportarXml = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const listaResponse = await NotaFiscalSaidaExportarXmlComunicador.show({
        id: idRegistro,
      });

      for (let i = 0; i < listaResponse.length; i++) {
        const response = listaResponse[i];

        await ImpressaoNotaFiscal.handleExportarXml(
          response.xml,
          response.nomeArquivo
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idRegistro = getIdDetalheRegistro() || '';

      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: true,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarObterBlobPdf(false);

      const data = new Uint8Array((anexo as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `Nota Fiscal (Saida) ${numero}.pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml = await NotaFiscalSaidaExportarXmlComunicador.show(
        { id: idRegistro }
      );

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml.xml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Nota Fiscal (Saída) número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [getIdDetalheRegistro, handleVisualizarObterBlobPdf, idPessoa, numero]
  );

  // #endregion Email

  const handleTransmitirNotaFiscal = useCallback(async () => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente transmitir essa Nota Fiscal de Saída?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response = await NotaFiscalSaidaTransmitirComunicador.update({
        id: idRegistro,
      });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    setLoading,
  ]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();
    await handleSubmit(data);

    setShowFinanceiro(false);

    if (transmitirNotaFiscal) {
      await handleTransmitirNotaFiscal();
      setTransmitirNotaFiscal(false);
    }
  }, [formRef, handleSubmit, handleTransmitirNotaFiscal, transmitirNotaFiscal]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleImportarPedidosVenda = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalSaidaImportacaoPedidoVenda('Importar Pedido de Venda');
      const response = await abrirNotaFiscalSaidaImportacaoPedidoVenda({
        idPessoa: String(idPessoa),
        idNotaFiscalSaida: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoPedidoVenda,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoPedidoVenda,
  ]);

  const handleImportarOrdensServico = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalSaidaImportacaoOrdemServico(
        'Importar Ordem de Serviço'
      );

      const response = await abrirNotaFiscalSaidaImportacaoOrdemServico({
        idPessoa: String(idPessoa),
        idNotaFiscalSaida: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoOrdemServico,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoOrdemServico,
  ]);

  const handleGerarNotaFiscalEntrada = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const pessoaPadraoTransferencia =
        await NotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicador.show(
          { id: idRegistro }
        );

      const finalidadeEmissao =
        formRef.current?.getFieldValue('finalidadeEmissao');
      const indicadorPresenca =
        formRef.current?.getFieldValue('indicadorPresenca');

      const tipoMercado = ObterTipoMercado({
        ufEstadoOrigem: empresa.uf,
        ufEstadoDestino:
          pessoaPadraoTransferencia.pessoaEnderecoPrincipal.cidade.estado.sigla,
        indicadorPresenca,
      });

      setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida(
        'Gerar Nota Fiscal de Entrada'
      );
      const response = await abrirNotaFiscalEntradaImportacaoNotaFiscalSaida({
        idPessoa: pessoaPadraoTransferencia.id,
        pessoa: pessoaPadraoTransferencia,
        idNotaFiscalSaida: idRegistro,
        indicadorPresenca,
        tipoMercado,
        finalidadeEmissao,
      });

      if (response) {
        navigate({
          pathname: `/suprimentos/compras/nota-fiscal-entrada/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalEntradaImportacaoNotaFiscalSaida,
    empresa.uf,
    formRef,
    getIdDetalheRegistro,
    handleSalvar,
    navigate,
    setLoading,
    setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida,
  ]);

  const handleImportarNotaFiscalEntrada = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const finalidadeEmissao =
        formRef.current?.getFieldValue('finalidadeEmissao');
      const indicadorPresenca =
        formRef.current?.getFieldValue('indicadorPresenca');
      const ufPessoa =
        formRef.current?.getFieldValue(
          'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
        ) || null;
      const ufPessoaEntrega =
        formRef.current?.getFieldValue(
          'notaFiscalSaidaPessoaEnderecoEntrega.cidade.estado.sigla'
        ) || null;

      const tipoMercado = ObterTipoMercado({
        ufEstadoOrigem: empresa.uf,
        ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
        indicadorPresenca,
      });

      const idRegistro = getIdDetalheRegistro() || '';
      setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada(
        'Importar Nota Fiscal de Entrada'
      );
      const response = await abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada({
        idPessoa: String(idPessoa),
        idNotaFiscalSaida: idRegistro,
        indicadorPresenca,
        tipoMercado,
        finalidadeEmissao,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada,
    empresa.uf,
    formRef,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada,
  ]);

  const handleTransmitir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setTransmitirNotaFiscal(true);
        return;
      }

      // #endregion Financeiro

      await handleTransmitirNotaFiscal();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleSalvar,
    handleTransmitirNotaFiscal,
    setLoading,
    valorFinanceiro,
  ]);

  // #region Carta correção

  const handleCartaCorrecao = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowCartaCorrecao(true);
  }, [handleSalvar]);

  const handleCartaCorrecaoFecharFormModal = useCallback(async () => {
    setShowCartaCorrecao(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Carta Correção

  // #region Cancelar

  const handleCancelar = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    if (!liberacoes.permiteCancelarCupon && vendaPdv) {
      ToastErro(
        'Usuário sem permissão para cancelar Cupom/Nota de Consumidor!'
      );

      setShowCancelar(false);
    } else {
      setShowCancelar(true);
    }
  }, [handleSalvar, liberacoes.permiteCancelarCupon, vendaPdv]);

  const handleCancelarSalvarFormModal = useCallback(async () => {
    setShowCancelar(false);
    handleCarregarDados();

    await handleImprimir();
    await handleCarregar();
  }, [handleCarregar, handleCarregarDados, handleImprimir]);

  const handleCancelarFecharFormModal = useCallback(async () => {
    setShowCancelar(false);

    await handleCarregar();
  }, [handleCarregar]);

  // #endregion Cancelar

  const handleConsultarProtocolo = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response =
        await NotaFiscalSaidaConsultarProtocoloComunicador.update({
          id: idRegistro,
        });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleSalvar,
    setLoading,
  ]);

  // #region Documentos Referenciados

  const handleVisualizarDocReferenciados = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowDocumentosReferenciados(true);
  }, [handleSalvar]);

  const handleDocReferenciadosFecharFormModal = useCallback(async () => {
    setShowDocumentosReferenciados(false);
  }, []);

  // #endregion Documentos Referenciados

  // #region Boletos

  const handleContaParcelaBoleto = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const response =
        await NotaFiscalSaidaObterDadosContaParcelaBoletoComunicador.show({
          id: idRegistro,
        });

      setShowContaParcelaBoleto(true);
      setNumeroDocumento(response.numeroDocumento);
      setIdConta(response.id);
      setIdPessoaConta(response.idPessoa);
      setIdNotaFiscalSaida(response.idNotaFiscalSaida);
      setIdConhecimentoTransporte(
        response.idConhecimentpsetIdConhecimentoTransporte
      );

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, setLoading]);

  const handleContaParcelaBoletoFecharFormModal = useCallback(async () => {
    setShowContaParcelaBoleto(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Boletos

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida !==
              SituacaoNotaFiscalSaidaEnum.autorizada ||
            Number(valorFinanceiro) === 0
          }
          onClick={handleContaParcelaBoleto}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Boletos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacaoNotaFiscalSaida !==
              SituacaoNotaFiscalSaidaEnum.naoTransmitida &&
              situacaoNotaFiscalSaida !==
                SituacaoNotaFiscalSaidaEnum.emTransmissao &&
              situacaoNotaFiscalSaida !== SituacaoNotaFiscalSaidaEnum.rejeitada)
          }
          onClick={handleVisualizarImpressao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Visualizar Impressão
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.emTransmissao ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.rejeitada
          }
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacaoNotaFiscalSaida !==
              SituacaoNotaFiscalSaidaEnum.naoTransmitida &&
              situacaoNotaFiscalSaida !==
                SituacaoNotaFiscalSaidaEnum.autorizada)
          }
          onClick={handleImprimirEtiquetasNotaFiscal}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir Etiquetas Nota Fiscal
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleImprimirRomanioCarga}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir Romaneio de Carga
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            formRef.current?.getFieldValue('listaNotaFiscalSaidaItem')
              .length === 0
          }
          onClick={handleExportarXml}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Exportar XML (Download)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.emTransmissao ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.rejeitada
          }
          onClick={handleEnviarPorEmail}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.autorizada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.cancelada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.denegada
          }
          onClick={handleImportarPedidosVenda}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Pedidos de Venda
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.autorizada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.cancelada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.denegada
          }
          onClick={handleImportarOrdensServico}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Ordens de Serviço
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida !== SituacaoNotaFiscalSaidaEnum.autorizada
          }
          onClick={handleGerarNotaFiscalEntrada}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Ret./Dev. (Gerar Nota de Entrada)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.autorizada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.cancelada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.denegada
          }
          onClick={handleImportarNotaFiscalEntrada}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Imp./Dev. de Notas de Entrada
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            formRef.current?.getFieldValue('listaNotaFiscalSaidaItem')
              .length === 0 ||
            situacaoNotaFiscalSaida ===
              SituacaoNotaFiscalSaidaEnum.autorizada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.denegada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.cancelada
          }
          onClick={handleTransmitir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Transmitir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            (situacaoNotaFiscalSaida !==
              SituacaoNotaFiscalSaidaEnum.autorizada &&
              situacaoNotaFiscalSaida !==
                SituacaoNotaFiscalSaidaEnum.cancelada) ||
            vendaPdv
          }
          onClick={handleCartaCorrecao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <MdOutlineAttachEmail style={{ marginRight: 5 }} />
            Carta de Correção
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            (!possuiPagamentoSiTef &&
              (situacaoNotaFiscalSaida ===
                SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
                situacaoNotaFiscalSaida ===
                  SituacaoNotaFiscalSaidaEnum.emTransmissao ||
                situacaoNotaFiscalSaida ===
                  SituacaoNotaFiscalSaidaEnum.rejeitada ||
                situacaoNotaFiscalSaida ===
                  SituacaoNotaFiscalSaidaEnum.denegada))
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.denegada ||
            situacaoNotaFiscalSaida === SituacaoNotaFiscalSaidaEnum.cancelada
          }
          onClick={handleConsultarProtocolo}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Consultar Protocolo NF-e
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleVisualizarDocReferenciados}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Documentos Referenciados
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showCancelar && (
        <CancelarHook>
          <CancelarDetalhe
            onSalvarFormModal={handleCancelarSalvarFormModal}
            onFecharFormModal={handleCancelarFecharFormModal}
          />
        </CancelarHook>
      )}

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Nota Fiscal (Saída) por E-mail"
          mensagemPadrao={`Segue em anexo a Nota Fiscal (Saída) número: ${numero}`}
          direcionarDocumentosFiscais
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}

      {showCartaCorrecao && (
        <CartaCorrecaoHook>
          <CartaCorrecaoLista
            onFecharFormModal={handleCartaCorrecaoFecharFormModal}
          />
        </CartaCorrecaoHook>
      )}

      {showDocumentosReferenciados && (
        <DocumentosReferenciadosHook>
          <DocumentosReferenciadosLista
            onFecharFormModal={handleDocReferenciadosFecharFormModal}
          />
        </DocumentosReferenciadosHook>
      )}

      {showContaParcelaBoleto && (
        <ContaListaParcelaBoletoHook>
          <ContaParcelaBoletoHook>
            <ContaParcelaBoletoLista
              onFecharFormModal={handleContaParcelaBoletoFecharFormModal}
              numeroDocumento={numeroDocumento}
              idConta={idConta}
              idPessoa={idPessoaConta}
              idNotaFiscalSaida={idNotaFiscalSaida}
              idConhecimentoTransporte={idConhecimentoTransporte}
              salvarFormPrincipal={false}
            />
          </ContaParcelaBoletoHook>
        </ContaListaParcelaBoletoHook>
      )}
    </>
  );
};

export default AcoesNotaFiscalSaida;
