import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-pedido-compra/obter-pedido-compra';

interface INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicadorIndex {
  idPessoa: string;
}

class NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicador();
