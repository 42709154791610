"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var FiltroDocumentoEnum;
(function (FiltroDocumentoEnum) {
    FiltroDocumentoEnum["inicia"] = "Inicia";
    FiltroDocumentoEnum["contem"] = "Cont\u00E9m";
    FiltroDocumentoEnum["igual"] = "Igual";
})(FiltroDocumentoEnum || (FiltroDocumentoEnum = {}));
exports.default = FiltroDocumentoEnum;
