import {
  AppErro,
  IManifestoTransporteDocumentoReferenciadoValoresAlterar,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import ManifestoTransporteEncerrarComunicador from '../../../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteEncerrarComunicador';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = formPrincipal.getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IManifestoTransporteDocumentoReferenciadoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          dataEncerramento: new Date(),
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SetarDadosBackend(): Promise<void> {
        const response = await ManifestoTransporteEncerrarComunicador.show({
          id: String(idEditar),
        });

        if (response) {
          await formRefDetalhe.current?.setDataInicial(response);
          SetarDadosObrigatorios();
        } else {
          SetarDadosPadrao();
        }
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [formPrincipal, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          dataEncerramento: Yup.date()
            .nullable()
            .required('Data de Encerramento é obrigatória!'),
          idCidadeEncerramento: Yup.string()
            .nullable()
            .required('Cidade de Encerramento é obrigatória!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id: '', erro: true };
        }

        const idRegistroDetalhePrincipal = formPrincipal.getIdDetalheRegistro();

        const response = await ManifestoTransporteEncerrarComunicador.store({
          id: String(idRegistroDetalhePrincipal),
          params: data,
        });

        ToastSucesso(response.mensagem);
        handleCarregarDados();
        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, handleCarregarDados, handleValidar]
  );

  const handleExcluir = async (): Promise<void> => {
    throw new AppErro({
      mensagem: 'A função "handleExcluir" não foi implementada no "Encerrar"',
    });
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
