"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SiTefContinuacaoEnum;
(function (SiTefContinuacaoEnum) {
    SiTefContinuacaoEnum["continuar"] = "0";
    SiTefContinuacaoEnum["retornar"] = "1";
    SiTefContinuacaoEnum["cancelar"] = "2";
    SiTefContinuacaoEnum["encerar"] = "-1";
})(SiTefContinuacaoEnum || (SiTefContinuacaoEnum = {}));
exports.default = SiTefContinuacaoEnum;
