import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/transportador-auto-complete-tag';

interface IPessoaTransportadorAutoCompleteTagComunicadorIndex {
  params: any;
}

class PessoaTransportadorAutoCompleteTagComunicador {
  public async index(
    params: IPessoaTransportadorAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaTransportadorAutoCompleteTagComunicador();
