import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda-importacao-orcamento/obter-orcamento-item';

interface IPedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicadorIndex {
  listaIdOrcamento: string[];
}

class PedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicador {
  public async index(
    params: IPedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoObterOrcamentoItemComunicador();
