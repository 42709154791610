"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var IndicadorPresencaPadraoEnum;
(function (IndicadorPresencaPadraoEnum) {
    IndicadorPresencaPadraoEnum["naoSeAplica"] = "0 - N\u00E3o se Aplica";
    IndicadorPresencaPadraoEnum["operacaoPresencial"] = "1 - Opera\u00E7\u00E3o Presencial";
    IndicadorPresencaPadraoEnum["operacaoNaoPresencialPelaInternet"] = "2 - Opera\u00E7\u00E3o n\u00E3o Presencial pela Internet";
    IndicadorPresencaPadraoEnum["operacaoNaoPresencialTeleatendimento"] = "3 - Opera\u00E7\u00E3o n\u00E3o Presencial Tele-Atendimento";
    IndicadorPresencaPadraoEnum["nfceOperacaoEntregaDomicilio"] = "4 - NFC-e Opera\u00E7\u00E3o Entrega Domic\u00EDlio";
    IndicadorPresencaPadraoEnum["operacaoPresenciaForaEstabelecimento"] = "5 - Opera\u00E7\u00E3o Presencial Fora do Estabelecimento";
    IndicadorPresencaPadraoEnum["operacaoNaoPresencialOutros"] = "9 - Opera\u00E7\u00E3o n\u00E3o Presencial (Outros)";
})(IndicadorPresencaPadraoEnum || (IndicadorPresencaPadraoEnum = {}));
exports.default = IndicadorPresencaPadraoEnum;
