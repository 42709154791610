import styled from 'styled-components';

export const Tabela = styled.div`
  table {
    tbody {
      border-color: white;

      .tabela-desabilitada:hover td {
        background-color: #eeeeee;
      }
    }

    thead {
      border-color: white;

      tr {
        th {
          background-color: white;
          padding: 12px;
        }
      }
    }
  }
`;
