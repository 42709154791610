const EstoqueSaldoProprioTerceiroPorLocalEstoquePlanilha = [
  {
    titulo: 'Local de Estoque',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.localEstoque.descricao',
  },
  {
    titulo: 'Código Produto',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.produto.codigo',
  },
  {
    titulo: 'Descrição Produto',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor:
      'listaEstoqueCombinacao.estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Tipo de Estoque',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.estoqueIndicadorTipo',
  },
  {
    titulo: 'Terceiro',
    valor: 'listaEstoqueCombinacao.estoqueCombinacao.pessoa.nomeRazaoSocial',
  },
  { titulo: 'Saldo', valor: 'listaEstoqueCombinacao.saldo' },
];

export default EstoqueSaldoProprioTerceiroPorLocalEstoquePlanilha;
