import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/duplicar';

interface IManifestoTransporteDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class ManifestoTransporteDuplicarComunicador {
  public async store(
    params: IManifestoTransporteDuplicarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new ManifestoTransporteDuplicarComunicador();
