import {
  IImpressaoEtiquetasProdutosLista,
  IImpressaoEtiquetasProdutosTransferirLista,
  TipoEtiquetaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useRef } from 'react';
import { Dropdown, Row, Col, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import BtnAcoesButton from '../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../Componentes/Buttons/BtnPadraoButton';
import Divisor from '../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteRegraEscolhaTabelaPreco from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRegraEscolhaTabelaPreco';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';
import { UseImpressaoEtiqueta } from '../Hooks/ImpressaoEtiquetaHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import InputAutoCompleteEtiqueta from '../../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteEtiqueta';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Comunicador/ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import InputTabelaInteiro from '../../../../../../Componentes/Inputs/InputTabela/InputTabelaInteiro';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';

interface IImpressaoEtiquetasProdutosListaProps
  extends IMain<IImpressaoEtiquetasProdutosLista> {
  listaProdutoEtiquetaSelecionado?: IImpressaoEtiquetasProdutosTransferirLista[];
}

const Main: React.FC<IImpressaoEtiquetasProdutosListaProps> = ({
  idEditar,
  dadosObrigatorios,
  listaProdutoEtiquetaSelecionado,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosImpressaoEtiquetasProdutos: permissao } =
    permissoes;
  const { abrirJanela } = UseConfirmacao();
  const { listaEtiqueta, setListaEtiqueta, listaItemSelecionadoRef } =
    UseImpressaoEtiqueta();
  const selecionarTodosRef = useRef<HTMLInputElement>(null);
  const {
    loading,
    handleSubmit,
    handleCarregarDados,
    formRef,
    refresh,
    setLoading,
    setIdDetalheRegistro,
  } = UseForm();

  const idProduto = formRef.current?.getFieldValue('idProduto');

  const listaQuantidadeRef = useRef<HTMLInputElement[]>([]);

  const handleChangeTabelaPreco = useCallback(
    async (event: any) => {
      try {
        if (!event.itemAtual || listaEtiqueta.length === 0) {
          return;
        }

        const listaIdProduto = listaEtiqueta.map((item) => item.produto.id);

        const response =
          await ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador.index(
            {
              params: {
                idRegraEscolhaTabelaPreco: event.itemAtual.id,
                listaIdProduto,
              },
            }
          );

        for (let i = 0; i < listaEtiqueta.length; i++) {
          const etiqueta = listaEtiqueta[i];
          const novoValor = response[i];

          etiqueta.regraEscolhaTabelaPreco = event.itemAtual;
          etiqueta.valorUnitarioVenda = novoValor;
        }

        refresh();

        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [listaEtiqueta, refresh, setLoading]
  );

  const handleChangePercentualDesconto = useCallback(() => {
    const percentualDescontoAVista = formRef.current?.getFieldValue(
      'percentualDescontoAVista'
    );

    if (listaEtiqueta.length > 0) {
      for (let i = 0; i < listaEtiqueta.length; i++) {
        const valor = listaEtiqueta[i];

        valor.percentualDescontoAVista = percentualDescontoAVista;
      }
    }
  }, [formRef, listaEtiqueta]);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRef.current?.getData();
    await handleSubmit(data);
  }, [formRef, handleSubmit]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      for (let i = 0; i < listaEtiqueta.length; i++) {
        listaEtiqueta[i].selecionado = checked;
      }
    },
    [listaEtiqueta, listaItemSelecionadoRef]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        listaEtiqueta.splice(Number(index), 1);
      }

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  };

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  const handleCarregarListaProdutosSelecionados =
    useCallback(async (): Promise<void> => {
      try {
        setLoading(true);

        if (
          listaProdutoEtiquetaSelecionado &&
          listaProdutoEtiquetaSelecionado.length > 0
        ) {
          await Sleep(300);
          const listaEtiquetaProduto: IImpressaoEtiquetasProdutosLista[] = [];

          for (let i = 0; i < listaProdutoEtiquetaSelecionado.length; i++) {
            const produtoEtiquetaSelecionado =
              listaProdutoEtiquetaSelecionado[i];

            const regraEscolhaTabelaPreco =
              formRef.current?.getFieldValueNomeObjeto(
                'regraEscolhaTabelaPreco'
              );
            const etiqueta =
              formRef.current?.getFieldValueNomeObjeto('etiqueta');
            const percentualDescontoAVista = formRef.current?.getFieldValue(
              'percentualDescontoAVista'
            );

            let valorUnitarioVenda = 0;
            if (produtoEtiquetaSelecionado.valorUnitarioVenda) {
              valorUnitarioVenda =
                produtoEtiquetaSelecionado.valorUnitarioVenda;
            } else {
              const response =
                await ImpressaoEtiquetasProdutosObterValorUnitarioVendaComunicador.index(
                  {
                    params: {
                      idRegraEscolhaTabelaPreco: regraEscolhaTabelaPreco.id,
                      listaIdProduto: [produtoEtiquetaSelecionado.produto.id],
                    },
                  }
                );

              valorUnitarioVenda = Number(response[0]);
            }

            const dados: IImpressaoEtiquetasProdutosLista = {
              selecionado: produtoEtiquetaSelecionado.selecionado,
              quantidade: produtoEtiquetaSelecionado.quantidade,
              percentualDescontoAVista,
              valorUnitarioVenda,

              produto: produtoEtiquetaSelecionado.produto,
              regraEscolhaTabelaPreco,
              etiqueta,
            };

            listaEtiquetaProduto.push(dados);
          }

          setListaEtiqueta(listaEtiquetaProduto);
        }

        localStorage.removeItem('@EloGestorle:etiquetas');
        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [
      formRef,
      listaProdutoEtiquetaSelecionado,
      refresh,
      setListaEtiqueta,
      setLoading,
    ]);

  const handleCarregarDadosEtiquetas = useCallback(async (): Promise<void> => {
    await handleCarregarDados({ dadosObrigatorios });
    await handleCarregarListaProdutosSelecionados();
  }, [
    dadosObrigatorios,
    handleCarregarDados,
    handleCarregarListaProdutosSelecionados,
  ]);

  useEffect(() => {
    handleCarregarDadosEtiquetas();
  }, [
    dadosObrigatorios,
    handleCarregarDados,
    handleCarregarDadosEtiquetas,
    handleCarregarListaProdutosSelecionados,
    setListaEtiqueta,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />
      <Divisor>
        <Row>
          <Col xl={4} lg={2} md={6} sm={12}>
            <InputAutoCompleteRegraEscolhaTabelaPreco
              label="Tabela de Preço"
              placeholder="Tabela de Preço"
              name="idRegraEscolhaTabelaPreco"
              idProduto={idProduto}
              nomeObjeto="regraEscolhaTabelaPreco"
              permitirAdicionar={false}
              onChangeItemAtual={(event) => {
                handleChangeTabelaPreco(event);
              }}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputAutoCompleteEtiqueta
              label="Modelo de Etiqueta"
              nomeObjeto="etiqueta"
              placeholder="Modelo de Etiqueta"
              name="idEtiqueta"
              listaTipoEtiqueta={[TipoEtiquetaEnum.produto]}
              onChangeItemAtual={() => {
                refresh();
              }}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputDecimalNulavel
              label="Perc. Desconto A Vista"
              placeholder="Perc. Desconto A Vista"
              name="percentualDescontoAVista"
              casasDecimais={3}
              casasInteiras={15}
              onBlur={() => {
                handleChangePercentualDesconto();
                refresh();
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompleteProduto
              name="idProduto"
              label="Produto"
              placeholder="Produto"
              nomeObjeto="produto"
              permitirAdicionar={false}
            />
          </Col>
          <Col xl={3} lg={12}>
            <InputInteiro
              label="Quantidade"
              name="quantidade"
              placeholder="Quantidade"
            />
          </Col>
          <Col
            xl={3}
            lg={6}
            md={6}
            sm={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionar}
              className="btn-padrao btn-verde btn-adicionar"
              disabled={!permissao?.inclui || loading}
            >
              <TextoLoading loading={loading}>
                <IoMdAdd />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </Divisor>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th
                className="lista-texto"
                style={{ width: '4%', textAlign: 'center' }}
              >
                <span>
                  <div style={{ alignItems: 'center' }}>
                    <input
                      ref={selecionarTodosRef}
                      name="selecionado"
                      type="checkbox"
                      onChange={(event) => {
                        handleSelecionarTodos(event.target.checked);
                        refresh();
                      }}
                    />
                  </div>
                </span>
              </th>
              <th
                className="lista-valor"
                style={{ width: '5%', textAlign: 'start' }}
              >
                <span>Código</span>
              </th>
              <th
                className="lista-texto"
                style={{ width: '25%', textAlign: 'start' }}
              >
                <span>Descrição</span>
              </th>
              <th
                className="lista-valor"
                style={{ width: '15%', textAlign: 'start' }}
              >
                <span>Quantidade</span>
              </th>
              <th
                className="lista-texto"
                style={{ width: '25%', textAlign: 'start' }}
              >
                <span>Tabela de Preço</span>
              </th>
              <th
                className="lista-valor"
                style={{ width: '12%', textAlign: 'start' }}
              >
                <span>Valor</span>
              </th>
              <th
                className="lista-valor"
                style={{ width: '12%', textAlign: 'start' }}
              >
                <span>Perc. Desc. A Vista</span>
              </th>

              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaEtiqueta?.map((item, index) => {
              return (
                <tr key={v4()} style={{ width: '4%', textAlign: 'center' }}>
                  <td className="checkbox-container">
                    <input
                      name="selecionado"
                      type="checkbox"
                      defaultChecked={item.selecionado}
                      ref={(instance) => {
                        if (
                          listaItemSelecionadoRef &&
                          listaItemSelecionadoRef.current
                        ) {
                          listaItemSelecionadoRef.current[index] = instance;
                        }
                      }}
                      onChange={(event) => {
                        listaEtiqueta[index].selecionado =
                          event.currentTarget.checked;
                        refresh();
                      }}
                    />
                  </td>
                  <td className="lista-valor">{item.produto.codigo}</td>
                  <td className="lista-texto">{item.produto.descricao}</td>
                  <td className="lista-valor">
                    <InputTabelaInteiro
                      obterRef={(instance) => {
                        if (instance.current && listaQuantidadeRef.current) {
                          listaQuantidadeRef.current[index] = instance.current;
                          listaQuantidadeRef.current[index].value = String(
                            item.quantidade
                          );
                        }
                      }}
                      valorPadrao={item.quantidade}
                      onChange={(event) => {
                        listaEtiqueta[index].quantidade = String(
                          event.currentTarget.value
                        ).ConverterParaNumber();
                      }}
                    />
                  </td>
                  <td className="lista-texto">
                    {item.regraEscolhaTabelaPreco.descricao}
                  </td>
                  <td className="lista-valor">
                    {Number(item.valorUnitarioVenda).FormatarParaPtBr()}
                  </td>
                  <td className="lista-valor">
                    {item?.percentualDescontoAVista > 0
                      ? Number(
                          item?.percentualDescontoAVista
                        ).FormatarParaPtBrPercentual()
                      : null}
                  </td>

                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={async () => {
                            handleExcluir(String(index));
                            setLoading(true);
                          }}
                          disabled={!permissao?.exclui || loading}
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <IoMdRemoveCircleOutline
                              style={{ marginRight: 5 }}
                            />
                            Remover
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </FormCia>
  );
};

export default Main;
