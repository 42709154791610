"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoNotaFiscalSaidaEnum;
(function (SituacaoNotaFiscalSaidaEnum) {
    SituacaoNotaFiscalSaidaEnum["naoTransmitida"] = "N\u00E3o Transmitida";
    SituacaoNotaFiscalSaidaEnum["emTransmissao"] = "Em Transmiss\u00E3o";
    SituacaoNotaFiscalSaidaEnum["autorizada"] = "Autorizada";
    SituacaoNotaFiscalSaidaEnum["denegada"] = "Denegada";
    SituacaoNotaFiscalSaidaEnum["cancelada"] = "Cancelada";
    SituacaoNotaFiscalSaidaEnum["rejeitada"] = "Rejeitada";
})(SituacaoNotaFiscalSaidaEnum || (SituacaoNotaFiscalSaidaEnum = {}));
exports.default = SituacaoNotaFiscalSaidaEnum;
