/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  IPedidoCompraImportacaoCotacaoCompraEtapa1Lista,
  IPedidoCompraImportacaoCotacaoCompraEtapa2Lista,
  ModalidadeFreteEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicador from '../../../../Comunicador/Suprimentos/Compras/PedidoCompraImportacaoCotacaoCompra/Comunicador/PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicador';
import PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicador from '../../../../Comunicador/Suprimentos/Compras/PedidoCompraImportacaoCotacaoCompra/Comunicador/PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import PedidoCompraImportacaoCotacaoCompraGerarComunicador from '../../../../Comunicador/Suprimentos/Compras/PedidoCompraImportacaoCotacaoCompra/Comunicador/PedidoCompraImportacaoCotacaoCompraGerarComunicador';
import PedidoCompraImportacaoCotacaoCompraEtapa1, {
  IPedidoCompraImportacaoCotacaoCompraEtapa1Ref,
} from './Etapa1';
import PedidoCompraImportacaoCotacaoCompraEtapa2, {
  IPedidoCompraImportacaoCotacaoCompraEtapa2Ref,
} from './Etapa2';
import PedidoCompraImportacaoCotacaoCompraImportarComunicador from '../../../../Comunicador/Suprimentos/Compras/PedidoCompraImportacaoCotacaoCompra/Comunicador/PedidoCompraImportacaoCotacaoCompraImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import PedidoCompraComunicador from '../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Comunicador/PedidoCompraComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';

export interface IAbrirPedidoCompraImportacaoCotacaoCompra {
  idPessoa: string;
  idCotacaoCompra?: string;
  idPedidoCompra?: string;
}

interface IPedidoCompraImportacaoCotacaoCompraContext {
  setTitulo(titulo: string): void;
  abrirPedidoCompraImportacaoCotacaoCompra({
    idPessoa,
    idCotacaoCompra,
    idPedidoCompra,
  }: IAbrirPedidoCompraImportacaoCotacaoCompra): Promise<any>;
  fecharPedidoCompraImportacaoCotacaoCompra(): void;
}

const PedidoCompraImportacaoCotacaoCompraContext =
  createContext<IPedidoCompraImportacaoCotacaoCompraContext>(
    {} as IPedidoCompraImportacaoCotacaoCompraContext
  );

const PedidoCompraImportacaoCotacaoCompraHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idCotacaoCompraOrigem, setIdCotacaoCompraOrigem] = useState<
    string | undefined
  >();
  const [idPedidoCompraOrigem, setIdPedidoCompraOrigem] = useState<
    string | undefined
  >();
  const [origemCotacaoCompra, setOrigemCotacaoCompra] = useState(true);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Etapa 1 - CotacaoCompras

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref = useRef<IPedidoCompraImportacaoCotacaoCompraEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(async (idPessoa: string) => {
    try {
      setLoading(true);

      const response =
        await PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicador.index(
          { idPessoa }
        );

      const listaOrdenada = response.listaCotacaoCompra.Ordenar([
        { campo: 'numero', tipo: 'desc' },
      ]);

      await formRefEtapa1.current?.setDataInicial({
        listaEtapa1: listaOrdenada,
      });
      etapa1Ref.current?.handleSelecionarTodosEtapa(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 1 - CotacaoCompras

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref = useRef<IPedidoCompraImportacaoCotacaoCompraEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idCotacaoCompra?: string) => {
      try {
        setLoading(true);

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as IPedidoCompraImportacaoCotacaoCompraEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as IPedidoCompraImportacaoCotacaoCompraEtapa2Lista[];

        let listaIdCotacaoCompraSelecionados: string[] = [];
        if (idCotacaoCompra) {
          listaIdCotacaoCompraSelecionados.push(idCotacaoCompra);
        } else {
          listaIdCotacaoCompraSelecionados = listaItemEtapa1.map(
            (item) => item.id
          );
        }

        const response =
          await PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraItemComunicador.index(
            { listaIdCotacaoCompra: listaIdCotacaoCompraSelecionados }
          );

        if (listaItemEtapa2) {
          response.listaCotacaoCompraItem = response.listaCotacaoCompraItem.map(
            (
              itemEtapa2Novo: IPedidoCompraImportacaoCotacaoCompraEtapa2Lista
            ) => {
              const itemExistente = listaItemEtapa2.find(
                (
                  itemEtapa2Atual: IPedidoCompraImportacaoCotacaoCompraEtapa2Lista
                ) => itemEtapa2Atual.id === itemEtapa2Novo.id
              );

              if (itemExistente) {
                itemExistente.ordem = itemEtapa2Novo.ordem;
                return itemExistente;
              }

              return itemEtapa2Novo;
            }
          );
        }

        const listaOrdenada = response.listaCotacaoCompraItem.Ordenar([
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicial({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 2 - Itens

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!etapa1Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;
    }
  }, [handleCarregarDadosEtapa2, proximoStep, step]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      idCotacaoCompra,
      idPedidoCompra,
    }: IAbrirPedidoCompraImportacaoCotacaoCompra): Promise<any> => {
      setAberto(true);
      setIdCotacaoCompraOrigem(idCotacaoCompra);
      setIdPedidoCompraOrigem(idPedidoCompra);

      if (!idCotacaoCompra) {
        setOrigemCotacaoCompra(false);
        setStep(1);
        await handleCarregarDadosEtapa1(idPessoa);
      } else {
        setOrigemCotacaoCompra(true);
        setStep(2);
        await handleCarregarDadosEtapa2(idCotacaoCompra);
      }

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, handleCarregarDadosEtapa2, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFreteCotacaoCompra = useCallback(
    async (
      valoresEtapa2: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFreteCotacao: ModalidadeFreteEnum | null = null;
      if (valoresEtapa2) {
        if (valoresEtapa2.length > 1) {
          valoresEtapa2.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa2[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa2[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFreteCotacao = null;
                } else {
                  modalidadeFreteCotacao = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFreteCotacao = null;
              }
            }
          });
        } else {
          modalidadeFreteCotacao = valoresEtapa2[0].modalidadeFrete;
        }
      }

      if (!isModalidadeFreteDiferente && modalidadeFreteCotacao) {
        const pedidoCompra = await PedidoCompraComunicador.show({
          id: String(idPedidoCompraOrigem),
        });
        if (
          pedidoCompra.pedidoCompraTransporteTransportadora.modalidadeFrete &&
          modalidadeFreteCotacao !==
            pedidoCompra.pedidoCompraTransporteTransportadora.modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade do Pedido: ${pedidoCompra.pedidoCompraTransporteTransportadora.modalidadeFrete} e da Cotação ${modalidadeFreteCotacao}, deseja atualizar o Pedido para ${modalidadeFreteCotacao}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idPedidoCompraOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!etapa2Ref.current?.handleValidar()) return;

      setLoading(true);

      const valoresEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados();
      let response: any = null;
      if (origemCotacaoCompra) {
        response =
          await PedidoCompraImportacaoCotacaoCompraGerarComunicador.store({
            params: { valoresEtapa2, idCotacaoCompraOrigem },
          });
      } else {
        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFreteCotacaoCompra(valoresEtapa2);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção Pedidos com modalidade de frete diferente entre si, favor verificar a modalidade de frete da Nota Fiscal!'
          );
        }

        response =
          await PedidoCompraImportacaoCotacaoCompraImportarComunicador.store({
            params: { valoresEtapa2, idPedidoCompraOrigem, resposta },
          });
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleValidarModalidadeFreteCotacaoCompra,
    idCotacaoCompraOrigem,
    idPedidoCompraOrigem,
    origemCotacaoCompra,
  ]);

  return (
    <PedidoCompraImportacaoCotacaoCompraContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirPedidoCompraImportacaoCotacaoCompra: handleAbrir,
        fecharPedidoCompraImportacaoCotacaoCompra: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Cotações de Compra</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <PedidoCompraImportacaoCotacaoCompraEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <PedidoCompraImportacaoCotacaoCompraEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {!origemCotacaoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handlePassoAnterior}
                      >
                        <IoMdReturnLeft />
                        <span style={{ marginLeft: 10 }}>Voltar</span>
                      </button>
                    )}

                    {origemCotacaoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handleFechar}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                          }}
                        >
                          <MdCancel />
                          <span style={{ marginLeft: 10 }}>Cancelar</span>
                        </span>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </PedidoCompraImportacaoCotacaoCompraContext.Provider>
  );
};

function UsePedidoCompraImportacaoCotacaoCompra(): IPedidoCompraImportacaoCotacaoCompraContext {
  const context = useContext(PedidoCompraImportacaoCotacaoCompraContext);

  if (!context) {
    throw new Error(
      'UsePedidoCompraImportacaoCotacaoCompra deve ser usado com um CotacaoCompraProvider'
    );
  }

  return context;
}

export {
  PedidoCompraImportacaoCotacaoCompraHook,
  UsePedidoCompraImportacaoCotacaoCompra,
};
