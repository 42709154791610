import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    border: 0;
    background: transparent;
    color: blue;
    text-decoration-line: underline;
    font-size: 20px;
  }

  button:hover {
    color: darkblue;
  }
`;
