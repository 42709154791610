import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/lote-auto-complete-tag';

interface ILoteAutoCompleteTagComunicadorIndex {
  params: any;
}

class LoteAutoCompleteTagComunicador {
  public async index(
    params: ILoteAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new LoteAutoCompleteTagComunicador();
