import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/conta-de-email/conta-de-email-obter-dados';

interface IContaEmailObterDadosComunicadorIndex {
  params: {
    verificado: boolean;
    direcionarBoletos: boolean;
    direcionarDocumentosFiscais: boolean;
    direcionarOrcamento: boolean;
    direcionarPedidoVenda: boolean;
    direcionarCotacaoCompra: boolean;
    direcionarPedidoCompra: boolean;
    direcionarOrdemServico: boolean;
    ativo: boolean;
  };
}

class ContaEmailObterDadosComunicador {
  public async index(
    params: IContaEmailObterDadosComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ContaEmailObterDadosComunicador();
