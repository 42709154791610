import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/curva-abc-nota-fiscal-entrada/curva-abc-nota-fiscal-entrada-grupo-produto-com-produto-por-quantidade';

interface ICurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalEntradaGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador();
