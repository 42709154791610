import {
  FormatarDataParaPtBr,
  FormatarEnum,
  IContasParcelaReversaoLista,
  TipoContaAdiantamentoPrevisaoEnum,
  TipoContaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Col, Row } from 'react-bootstrap';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AcoesContasParcelaReversao from '../AcoesContasParcelaReversao';
import { UseContasParcelaReversaoLista } from '../Hooks/ContasParcelaReversaoListaHook';
import ContasParcelaReversaoComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/ContasParcelaReversao/Comunicador/ContasParcelaReversaoComunicador';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';

const ContaParcelaReversaoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroMovimentacoesReversaoContas: permissao } = permissoes;

  const { listaValor, setListaValor, listaItemSelecionadoRef } =
    UseContasParcelaReversaoLista();

  const formRef = useRef<IFormCiaHandles>(null);

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleAlterouSelecionado = useCallback(async () => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );
    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item) {
          listaValor[index].selecionado = checked;
          item.checked = checked;
        }
      });
      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef, listaValor]
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);
        const tipoContaAdiantamentoPrevisao = formRef.current?.getFieldValue(
          'tipoContaAdiantamentoPrevisao'
        );

        let listaDados: IContasParcelaReversaoLista[] = [];

        const itensSelecionados = listaDados.filter(
          (item: IContasParcelaReversaoLista) => {
            return item.selecionado;
          }
        );

        setPossuiItemSelecionado(itensSelecionados.length > 0);

        const response = await ContasParcelaReversaoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
            tipoContaAdiantamentoPrevisao,
          },
        });

        listaDados = response.dados[0].map(
          (data: IContasParcelaReversaoLista) => {
            return { ...data, selecionado: false };
          }
        );

        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);

        const dados = listaDados.map(
          (data: IContasParcelaReversaoLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={(ev) => {
                      handleAlterouSelecionado();
                      listaDados[index].selecionado = ev.target.checked;
                    }}
                  />
                </div>
              ),

              'conta.tipo': (
                <div
                  className="lista-texto"
                  style={{
                    color:
                      data.conta.tipo === TipoContaEnum.receber
                        ? 'green'
                        : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {data.conta.tipo}
                </div>
              ),

              numeroDocumento: (
                <div className="lista-texto">{data.numeroDocumento}</div>
              ),

              sequencia: (
                <div className="lista-texto">{`${data.sequencia}/${data.conta.quantidadeParcelas}`}</div>
              ),

              'conta.dataHoraEmissao': (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.conta.dataHoraEmissao)}
                </div>
              ),

              dataVencimento: (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.dataVencimento)}
                </div>
              ),

              valorParcela: (
                <div className="lista-valor">
                  {data.valorParcela
                    ? Number(data.valorParcela).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),

              'conta.pessoa.codigoNomeRazaoSocial': (
                <div className="lista-texto">
                  {data.conta.pessoa &&
                    `${data.conta.pessoa.codigo} - ${data.conta.pessoa.nomeRazaoSocial}`}
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAlterouSelecionado, setListaValor, listaValor.length]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'financeiroContaParcelaReversao' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  useEffect(() => {
    if (refresh) handlePesquisarDados({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Reversão de Contas"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesContasParcelaReversao
                loading={loading}
                setLoading={setLoading}
                setRefresh={setRefresh}
                possuiItemSelecionado={possuiItemSelecionado}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          headerLista={
            <Container style={{ padding: 0 }}>
              <Divisor>
                <FormCia
                  ref={formRef}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Row style={{ width: '100%' }}>
                    <Col lg={3} md={6} sm={12}>
                      <Select
                        name="tipoContaAdiantamentoPrevisao"
                        label="Tipo Conta"
                        options={FormatarEnum({
                          enumObj: TipoContaAdiantamentoPrevisaoEnum,
                        })}
                        onChange={() => {
                          setRefresh((state) => !state);
                        }}
                      />
                    </Col>
                  </Row>
                </FormCia>
              </Divisor>
            </Container>
          }
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          paginacaoPadrao={100}
          opcao1000
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default ContaParcelaReversaoLista;
