import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/baixar';

interface IImportacaoXmlTerceiroBaixarComunicadorStore {
  params: any;
}

class ImportacaoXmlTerceiroBaixarComunicador {
  public async store(
    params: IImportacaoXmlTerceiroBaixarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroBaixarComunicador();
