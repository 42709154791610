import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/equipamento/log';

interface IEquipamentoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IEquipamentoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class EquipamentoLogComunicador {
  public async index(
    params: IEquipamentoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IEquipamentoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new EquipamentoLogComunicador();
