import { AppErro } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../../../Util/Erro/TratarErros';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import ToastSucesso from '../../../../../../../../../Util/Toasts/ToastSucesso';
import ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador from '../../../../../../../../../Comunicador/Transporte/ManifestoTransporte/DocumentoReferenciado/Comunicador/ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaPesquisaDocumentosEmitidos"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const idDetalheRegistroPrincipal =
          formPrincipal.getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id: '', erro: true };
        }

        await ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador.store(
          {
            idPai: idDetalheRegistroPrincipal,
            params: data,
          }
        );

        formPrincipal.handleCarregarDados();
        ToastSucesso('Documentos Referenciados Salvos!');
        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, handleValidar]
  );

  const handleExcluir = async (): Promise<void> => {
    throw new AppErro({
      mensagem:
        'A função "handleExcluir" não foi implementada no "ListaPesquisaDocumentosEmitidos"',
    });
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
