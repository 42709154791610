import { Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { UseAuth } from '../Hooks/Auth';
import Padrao from '../Componentes/Padrao';
import Login from '../Paginas/Login';

interface IRouteProps {
  isPrivate?: boolean;
  path?: string;
}

const Rota: React.FC<IRouteProps> = ({ isPrivate }) => {
  const { usuario } = UseAuth();
  const location = useLocation();
  const { pathname } = location;

  const layout = isPrivate ? (
    <Padrao>
      <Outlet />
    </Padrao>
  ) : (
    <Outlet />
  );

  if (isPrivate === !!usuario) {
    return layout;
  }

  if (pathname.includes('/conta-email-verificado/')) {
    return layout;
  }

  if (isPrivate) {
    return <Login />;
  }

  return layout;
};

export default Rota;
