import { ICotacaoCompraPaisagemComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoCotacaoCompraPaisagemComFotoProdutoPDF from './CotacaoCompraPaisagemComFotoProdutoImpressaoPDF';

interface IImpressaoCotacaoCompraPaisagemComFotoProduto {
  handleVisualizarPdf(
    data: ICotacaoCompraPaisagemComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: ICotacaoCompraPaisagemComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoCotacaoCompraPaisagemComFotoProduto =
  (): IImpressaoCotacaoCompraPaisagemComFotoProduto => {
    async function handleVisualizarPdf(
      dados: ICotacaoCompraPaisagemComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoCotacaoCompraPaisagemComFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: ICotacaoCompraPaisagemComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoCotacaoCompraPaisagemComFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoCotacaoCompraPaisagemComFotoProduto();
