import React from 'react';
import styled, { keyframes } from 'styled-components';

const AnimacaoCarregamento: React.FC = () => {
  return (
    <ContainerCarregamento>
      <Ponto1 />
      <Ponto2 />
      <Ponto3 />
    </ContainerCarregamento>
  );
};

const animacaoQuicar = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const ContainerCarregamento = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 4px;
`;

const Ponto = styled.div`
  width: 9px;
  height: 9px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${animacaoQuicar} 0.6s infinite;
`;

const Ponto1 = styled(Ponto)``;
const Ponto2 = styled(Ponto)`
  animation-delay: 0.2s;
`;
const Ponto3 = styled(Ponto)`
  animation-delay: 0.4s;
`;

export default AnimacaoCarregamento;
