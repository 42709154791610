import { IFrenteCaixaPdvItemLista } from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IFrenteCaixaPdvContext {
  validador: boolean;

  listaItem: IFrenteCaixaPdvItemLista[];
  setListaItem(lista: IFrenteCaixaPdvItemLista[]): Promise<void>;
  itemSelecionado: IFrenteCaixaPdvItemLista | null;
  setItemSelecionado: React.Dispatch<
    React.SetStateAction<IFrenteCaixaPdvItemLista | null>
  >;

  idNotaFiscalSaida: string | null;
  setIdNotaFiscalSaida: React.Dispatch<React.SetStateAction<string | null>>;
  idPreVenda: string | null;
  setIdPreVenda: React.Dispatch<React.SetStateAction<string | null>>;
  listaIdItemConsignado: string[] | null;
  setListaIdItemConsignado: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;

  possuiTerminalCaixaComIntegracaoTef: boolean;
  setPossuiTerminalCaixaComIntegracaoTef: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const FrenteCaixaPdvContext = createContext<IFrenteCaixaPdvContext>(
  {} as IFrenteCaixaPdvContext
);

const FrenteCaixaPdvHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaItem, setListaItem] = useState<IFrenteCaixaPdvItemLista[]>([]);
  const [itemSelecionado, setItemSelecionado] =
    useState<IFrenteCaixaPdvItemLista | null>(null);

  const [idNotaFiscalSaida, setIdNotaFiscalSaida] = useState<string | null>(
    null
  );
  const [idPreVenda, setIdPreVenda] = useState<string | null>(null);
  const [listaIdItemConsignado, setListaIdItemConsignado] = useState<
    string[] | null
  >(null);

  const [
    possuiTerminalCaixaComIntegracaoTef,
    setPossuiTerminalCaixaComIntegracaoTef,
  ] = useState<boolean>(false);

  const handleSetListaItem = useCallback(
    async (lista: IFrenteCaixaPdvItemLista[]) => {
      const novaLista = lista.Ordenar([{ campo: 'sequencia', tipo: 'DESC' }]);

      setListaItem(novaLista);
    },
    []
  );

  return (
    <FrenteCaixaPdvContext.Provider
      value={{
        listaItem,
        setListaItem: handleSetListaItem,
        itemSelecionado,
        setItemSelecionado,

        idNotaFiscalSaida,
        setIdNotaFiscalSaida,
        idPreVenda,
        setIdPreVenda,
        listaIdItemConsignado,
        setListaIdItemConsignado,

        possuiTerminalCaixaComIntegracaoTef,
        setPossuiTerminalCaixaComIntegracaoTef,

        validador: true,
      }}
    >
      {children}
    </FrenteCaixaPdvContext.Provider>
  );
};

function UseFrenteCaixaPdv(): Omit<IFrenteCaixaPdvContext, 'validador'> {
  const context = useContext(FrenteCaixaPdvContext);

  if (!context.validador) {
    throw new Error(
      'UseFrenteCaixaPdv deve ser usado com um FrenteCaixaPdvProvider'
    );
  }

  return context;
}

export { FrenteCaixaPdvHook, UseFrenteCaixaPdv };
