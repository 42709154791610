import React, { useEffect } from 'react';
import InputAutoCompleteCliente from '../../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import ITipoDados from '../../../../../Main/Interface/ITipoDados';

const AutoCompleteClienteConsumidorFinalFrenteCaixaPdv: React.FC<
  ITipoDados
> = ({ nome, valor, formRef }) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return (
    <InputAutoCompleteCliente
      name={nome}
      nomeObjeto={nome}
      permitirAdicionar={false}
    />
  );
};

export default AutoCompleteClienteConsumidorFinalFrenteCaixaPdv;
