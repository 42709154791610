import styled from 'styled-components';
import { SplitButton } from 'react-bootstrap';

export const SplitDuplicar = styled(SplitButton)`
  > button {
    background: #66b9c8;
    color: #fff;
    font-weight: 600;
    border: 0;

    &:hover {
      background: #539ca9;
    }

    &:active {
      background: #3d707a;
    }

    &:focus {
      background: #3d707a !important;
      box-shadow: 0 0 0 0.2rem rgba(61, 112, 122, 0.25) !important;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;
