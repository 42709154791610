/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Container, Table, Row, Col } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import TratarErros from '../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../Componentes/TextoLoading';
import Divisor from '../../../../Componentes/Divisor';
import { Tabela } from '../../../../Componentes/Styles/Tabela';
import ConsultaEstoqueObterProdutoEmEstoqueComunicador from '../../../../Comunicador/Suprimentos/Produtos/ConsultaEstoque/Comunicador/ConsultaEstoqueObterProdutoEmEstoqueComunicador';
import InputAutoCompleteProduto from '../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import { UseListaDetalheForm } from '../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UltimaTr } from './styles';
import ProdutoAutoCompleteComunicador from '../../../../Comunicador/Suprimentos/Produtos/Produto/AutoComplete/ProdutoAutoComplete/ProdutoAutoCompleteComunicador';

interface IPesquisarListaModal {
  titulo: string;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
  idProdutoInicial?: string;
}

const ConsultaEstoqueLista: React.FC<IPesquisarListaModal> = ({
  titulo,
  onFecharFormModal,
  loading,
  setLoading,
  idProdutoInicial,
}) => {
  const { listaValor, setListaValor } = UseListaDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const { formRefDetalhe } = UseListaDetalheForm();

  const handleCarregarDadosIniciais = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      let produto: any;

      if (idProdutoInicial) {
        const response = await ProdutoAutoCompleteComunicador.index({
          params: {
            limite: 1,
            idInicial: idProdutoInicial,
          },
        });

        if (response && response.length > 0) {
          produto = response[0];
        }
      } else if (formRefDetalhe && formRefDetalhe.current) {
        produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      }

      if (produto) {
        formRef.current?.setFieldValue('produto', produto);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRefDetalhe, idProdutoInicial, setLoading]);

  useEffect(() => {
    handleCarregarDadosIniciais();
  }, [handleCarregarDadosIniciais]);

  const handleCarregarDadosListaEstoque =
    useCallback(async (): Promise<void> => {
      try {
        setLoading(true);

        const idProduto = formRef.current?.getFieldValue('idProduto');

        if (idProduto) {
          const response =
            await ConsultaEstoqueObterProdutoEmEstoqueComunicador.show({
              id: idProduto,
            });

          setListaValor(response);
        } else {
          setListaValor([]);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [setListaValor, setLoading]);

  const quantidadeTotal = listaValor.reduce((acumulador: number, item: any) => {
    acumulador += Number(item.quantidade);
    return acumulador;
  }, 0);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (onFecharFormModal) onFecharFormModal();
  }, [onFecharFormModal]);

  return (
    <JanelaDetalhe
      titulo={titulo}
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Header>
          <Divisor style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Col lg={12} md={12} sm={12}>
                <InputAutoCompleteProduto
                  name="idProduto"
                  label="Produto"
                  nomeObjeto="produto"
                  permitirAdicionar={false}
                  onChangeItemAtual={() => {
                    handleCarregarDadosListaEstoque();
                  }}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Header>

        <Modal.Body style={{ height: 400, display: 'flex' }}>
          <Divisor style={{ overflow: 'auto', width: '100%' }}>
            <Tabela>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th
                      className="lista-texto"
                      style={{
                        width: '15%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Tipo de Estoque</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '12%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Local de Estoque</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '15%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Quantidade</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '15%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Terceiro</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '18%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Empresa</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '8%',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Lote</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaValor?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td className="lista-texto">
                            {item.indicadorTipoEstoque}
                          </td>
                          <td className="lista-texto">
                            {item.descricaoLocalEstoque}
                          </td>
                          <td className="lista-valor">
                            {Number(item.quantidade).FormatarParaPtBr({
                              maximoCasasDecimais: 4,
                              minimoCasasDecimais: 4,
                            })}
                          </td>
                          <td className="lista-texto">
                            {item.codigoTerceiro
                              ? `${item.codigoTerceiro} - ${item.nomeRazaoSocialTerceiro}`
                              : ''}
                          </td>
                          <td className="lista-texto">
                            {item.nomeRazaoSocialEmpresa}
                          </td>
                          <td className="lista-texto">{item.codigoLote}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>

                <tfoot>
                  <UltimaTr>
                    <th />
                    <th />
                    <th>
                      <div
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{ fontSize: '13px' }}>Qtde. Total</div>
                        <div
                          style={{ alignSelf: 'flex-end', fontSize: '13px' }}
                        >
                          {quantidadeTotal.FormatarParaPtBr({
                            maximoCasasDecimais: 4,
                            minimoCasasDecimais: 4,
                          })}
                        </div>
                      </div>
                    </th>
                    <th />
                    <th />
                    <th />
                  </UltimaTr>
                </tfoot>
              </Table>
            </Tabela>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default ConsultaEstoqueLista;
