import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-ipi/ordem';

interface IRegraEscolhaCstCreditoIpiOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaCstCreditoIpiOrdemComunicador {
  public async update(
    params: IRegraEscolhaCstCreditoIpiOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoIpiOrdemComunicador();
