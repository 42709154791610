import React from 'react';
import { VscSaveAs } from 'react-icons/vsc/index.mjs';
import TextoLoading from '../TextoLoading';

interface ITextoLoadingSalvarContinuar {
  loading: boolean;
}

const TextoLoadingSalvarContinuar: React.FC<ITextoLoadingSalvarContinuar> = ({
  loading,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextoLoading loading={loading}>
        <VscSaveAs />
        <span style={{ marginLeft: 10 }}>Salvar e Continuar</span>
      </TextoLoading>
    </div>
  );
};

export default TextoLoadingSalvarContinuar;
