import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico-importacao-orcamento/obter-orcamento-item-estoque';

interface IOrdemServicoImportacaoOrcamentoObterOrcamentoItemEstoqueComunicadorIndex {
  dataHoraEmissao: Date;
  idOrcamentoItem: string;
  quantidade: number;
}

class OrdemServicoImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador {
  public async index(
    params: IOrdemServicoImportacaoOrcamentoObterOrcamentoItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new OrdemServicoImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador();
