import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/obter-cfop-fiscal-entrada';

interface INotaFiscalEntradaObterCfopFiscalEntradaComunicadorShow {
  params: {
    idOperacaoFiscalSaida: string;
    destinado: string;
  };
}

class NotaFiscalEntradaObterCfopFiscalEntradaComunicador {
  public async show(
    params: INotaFiscalEntradaObterCfopFiscalEntradaComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new NotaFiscalEntradaObterCfopFiscalEntradaComunicador();
