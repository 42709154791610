/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useCallback,
  useState,
  SelectHTMLAttributes,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Container, SpanErro } from './styles';

interface IOption {
  title: string;
  value: string;
  hidden?: boolean;
}

interface ISelectEventoCia {
  valor: string | null;
  input: HTMLSelectElement;
}

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string | JSX.Element;
  options: IOption[];
  erro?: string;
  onChangeValue?: (evento: ISelectEventoCia) => void;
}

const SelectSemRegister: React.ForwardRefRenderFunction<
  HTMLSelectElement | null,
  ISelectProps
> = ({ label, options, onChange, erro, ...rest }, ref) => {
  const inputRef = useRef<HTMLSelectElement | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useImperativeHandle(ref, (): any => {
    return inputRef.current;
  });

  return (
    <Container
      $isErrored={!!erro}
      $isFocused={isFocused}
      $isFilled={isFilled}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
    >
      {label && <label>{label}</label>}
      <select
        ref={inputRef}
        onChange={(event) => {
          if (onChange) onChange(event);
        }}
        {...rest}
      >
        {options.map((option) => {
          return (
            <option
              className={option.hidden ? 'hidden' : ''}
              value={option.value}
              key={option.value}
            >
              {option.title}
            </option>
          );
        })}
      </select>
      {erro && <SpanErro>{erro}</SpanErro>}
    </Container>
  );
};

export default forwardRef(SelectSemRegister);
