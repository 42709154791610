import React, { createContext, useCallback, useState, useContext } from 'react';
import RecuperarFormulario from '..';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';

interface IRecuperarFormularioState {
  titulo?: string;
  mensagem?: string;
  confimar?: string;
  cb(resposta: boolean): any;
}

export type IOnAbrirRecuperarFormulario = (
  recuperarFormulario: Omit<IRecuperarFormularioState, 'cb'>
) => Promise<boolean>;

interface IRecuperarFormularioContextData {
  abrirRecuperarFormulario: IOnAbrirRecuperarFormulario;
  fecharRecuperarFormulario(): void;
}

const RecuperarFormularioContext =
  createContext<IRecuperarFormularioContextData>(
    {} as IRecuperarFormularioContextData
  );

const RecuperarFormularioProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [janela, setJanela] = useState<IRecuperarFormularioState>();

  const abrirRecuperarFormulario = useCallback(
    ({
      titulo,
      mensagem,
      confimar,
    }: Omit<IRecuperarFormularioState, 'cb'>): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        const cb = (resposta: boolean): void => {
          resolve(resposta);
        };

        setJanela({ titulo, mensagem, confimar, cb });
      });
    },
    []
  );

  const fecharRecuperarFormulario = useCallback(() => {
    setJanela(undefined);
  }, []);

  return (
    <RecuperarFormularioContext.Provider
      value={{ abrirRecuperarFormulario, fecharRecuperarFormulario }}
    >
      {children}

      {janela && (
        <RecuperarFormulario
          fecharJanela={() => {
            fecharRecuperarFormulario();
          }}
          confimar={janela.confimar}
          titulo={janela.titulo}
          mensagem={janela.mensagem}
          cb={janela.cb}
        />
      )}
    </RecuperarFormularioContext.Provider>
  );
};

function UseRecuperarFormulario(): IRecuperarFormularioContextData {
  const context = useContext(RecuperarFormularioContext);

  if (!context) {
    throw new Error(
      'UseRecuperarFormulario must be used within an RecuperarFormularioProvider'
    );
  }

  return context;
}

export { RecuperarFormularioProvider, UseRecuperarFormulario };
