import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/nota-fiscal-saida/nota-fiscal-saida-resumo-por-data-emissao';

interface INotaFiscalSaidaResumoPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalSaidaResumoPorDataEmissaoRelatorioComunicador {
  public async index(
    params: INotaFiscalSaidaResumoPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalSaidaResumoPorDataEmissaoRelatorioComunicador();
