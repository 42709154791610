/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Container, Col, Row, Table } from 'react-bootstrap';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraCompletaParaPtBr,
  IConhecimentoTransporteCartaCorrecaoLista,
  SituacaoConhecimentoTransporteEnum,
  SituacaoEventoEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../../Componentes/Divisor';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import Acoes from '../../../../../../../Componentes/Lista/Acoes';
import CartaCorrecaoDetalhe from '../Detalhe';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ConhecimentoTransporteTransmissaoEventoComunicador from '../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/Transmissao/Evento/ConhecimentoTransporteTransmissaoEventoComunicador';
import ConhecimentoTransporteTransmissaoEventoLogComunicador from '../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/Transmissao/Evento/Log/Comunicador/ConhecimentoTransporteTransmissaoEventoLogComunicador';
import ListaProvider from '../../../../../../../Componentes/Lista/ListaProvider';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

interface ICartaCorrecaoListaModal {
  onFecharFormModal(): void;
}

const CartaCorrecaoLista: React.FC<ICartaCorrecaoListaModal> = ({
  onFecharFormModal,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;

  const {
    formRefLista,
    setLoading,
    loading,
    handleSubmit,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
  } = UseListaDetalheForm();

  const [listaValor, setListaValor] = useState<
    IConhecimentoTransporteCartaCorrecaoLista[]
  >([]);
  const [showDetalheCartaCorrecao, setShowDetalheCartaCorrecao] =
    useState(false);

  const idDetalheRegistro = getIdDetalheRegistro();
  const idPrincipal = formPrincipal.getIdDetalheRegistro();
  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      formPrincipal.setLoading(true);

      const response =
        await ConhecimentoTransporteTransmissaoEventoComunicador.index({
          params: {
            idValor: String(idPrincipal),
          },
        });

      if (!response) return;

      setListaValor(response);

      formPrincipal.setLoading(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      formPrincipal.setLoading(false);
      setLoading(false);
    }
  }, [formPrincipal, idPrincipal, setLoading]);

  const handleAdicionarCartaCorrecao = useCallback(async () => {
    setShowDetalheCartaCorrecao(true);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleCartaCorrecaoFecharFormModal = useCallback(async () => {
    setShowDetalheCartaCorrecao(false);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleCartaCorrecaoSalvarFormModal = useCallback(async () => {
    const data = formRefLista.current?.getData();

    const { erro } = await handleSubmit(data, formRefLista.current);

    if (!erro) {
      await handleCarregarDados();
      setShowDetalheCartaCorrecao(false);
      setIdDetalheRegistro('');
    }
  }, [formRefLista, handleCarregarDados, handleSubmit, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (index: string): Promise<void> => {
      setIdDetalheRegistro(index);

      setShowDetalheCartaCorrecao(true);
    },
    [setIdDetalheRegistro]
  );

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDetalheRegistro]);

  return (
    <JanelaDetalhe
      titulo="Carta Correção"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefLista}>
        <Modal.Body>
          <Divisor>
            <Container>
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <BtnPadraoButton
                    type="button"
                    onClick={handleAdicionarCartaCorrecao}
                    className="btn-padrao btn-verde-claro btn-adicionar"
                    disabled={
                      !permissao?.inclui ||
                      formPrincipal.loading ||
                      situacao === SituacaoConhecimentoTransporteEnum.cancelado
                    }
                    style={{ width: '60%' }}
                  >
                    <TextoLoading loading={formPrincipal.loading}>
                      <IoMdAdd />
                      <span style={{ marginLeft: 10 }}>Adicionar</span>
                    </TextoLoading>
                  </BtnPadraoButton>
                </Col>
              </Row>

              <ListaProvider>
                <Tabela style={{ overflow: 'auto' }}>
                  <Table
                    striped
                    hover
                    bordered
                    variant="light"
                    style={{ minHeight: 135 }}
                  >
                    <thead>
                      <tr>
                        <th className="lista-valor" style={{ width: '5%' }}>
                          <span>Seq.</span>
                        </th>
                        <th className="lista-texto" style={{ width: '18%' }}>
                          <span>Grupo a ser Alterado</span>
                        </th>
                        <th className="lista-texto" style={{ width: '18%' }}>
                          <span>Campo a ser Alterado</span>
                        </th>
                        <th className="lista-texto" style={{ width: '16%' }}>
                          <span>Valor a ser Alterado</span>
                        </th>
                        <th className="lista-texto" style={{ width: '18%' }}>
                          <span>Número Item Alterado</span>
                        </th>
                        <th className="lista-texto" style={{ width: '10%' }}>
                          <span>Situação</span>
                        </th>
                        <th className="lista-data" style={{ width: '14%' }}>
                          <span>Data Autorização</span>
                        </th>
                        <th className="lista-texto">
                          <span>Ações</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaValor.map((item) => {
                        return (
                          <tr key={v4()} onClick={() => handleEditar(item.id)}>
                            <td className="lista-valor">
                              {item.numeroSequencialEvento}
                            </td>
                            <td className="lista-texto">
                              {item.grupoAlterado}
                            </td>
                            <td className="lista-texto">
                              {item.campoAlterado}
                            </td>
                            <td className="lista-texto">
                              {item.valorAlterado}
                            </td>
                            <td className="lista-texto">
                              {item.numeroItemAlterado}
                            </td>

                            <td
                              className="lista-texto"
                              style={{
                                fontWeight: 'bold',
                                color:
                                  item.situacao ===
                                  SituacaoEventoEnum.autorizada
                                    ? 'green'
                                    : item.situacao ===
                                        SituacaoEventoEnum.naoTransmitida
                                      ? 'black'
                                      : 'red',
                              }}
                            >
                              {item.situacao}
                            </td>
                            <td className="lista-data">
                              {item.situacao ===
                                SituacaoEventoEnum.autorizada &&
                                FormatarDataHoraCompletaParaPtBr(
                                  item.dataHoraEvento
                                )}
                            </td>
                            <td className="tdButton lista-acoes">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Acoes
                                  deletarFuncao={async () => {
                                    try {
                                      setLoading(true);

                                      await ConhecimentoTransporteTransmissaoEventoComunicador.delete(
                                        { id: item.id }
                                      );
                                      formPrincipal.setLoading(false);
                                      formPrincipal.refresh();

                                      await formPrincipal.handleCarregarDados();
                                      handleCarregarDados();

                                      setLoading(false);
                                    } catch (error) {
                                      setLoading(false);
                                      throw error;
                                    }
                                  }}
                                  buscarLogs={async (params: any) => {
                                    try {
                                      setLoading(true);

                                      const responseLog =
                                        ConhecimentoTransporteTransmissaoEventoLogComunicador.show(
                                          {
                                            id: item.id,
                                            params,
                                          }
                                        );

                                      setLoading(false);
                                      return responseLog;
                                    } catch (error) {
                                      setLoading(false);
                                      throw error;
                                    }
                                  }}
                                  disableDeletar={
                                    permissoes.TransporteConhecimentoTransporte
                                      ?.exclui &&
                                    item.situacao !==
                                      SituacaoEventoEnum.autorizada
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tabela>
              </ListaProvider>
            </Container>
          </Divisor>
        </Modal.Body>

        {showDetalheCartaCorrecao && (
          <CartaCorrecaoDetalhe
            onFecharFormModal={handleCartaCorrecaoFecharFormModal}
            onSalvarFormModal={handleCartaCorrecaoSalvarFormModal}
          />
        )}
      </FormCia>
    </JanelaDetalhe>
  );
};

export default CartaCorrecaoLista;
