import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IContaEmailVerificacaoContext {
  validador: boolean;

  emailInicial: string;
  setEmailInicial(value: string): void;

  verificadoInicial: boolean;
  setVerificadoInicial(value: boolean): void;
}

const ContaEmailVerificacaoContext =
  createContext<IContaEmailVerificacaoContext>(
    {} as IContaEmailVerificacaoContext
  );

const ContaEmailVerificacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [emailInicial, setEmailInicial] = useState('');
  const [verificadoInicial, setVerificadoInicial] = useState(false);

  return (
    <ContaEmailVerificacaoContext.Provider
      value={{
        emailInicial,
        setEmailInicial,

        verificadoInicial,
        setVerificadoInicial,

        validador: true,
      }}
    >
      {children}
    </ContaEmailVerificacaoContext.Provider>
  );
};

function UseContaEmailVerificacao(): Omit<
  IContaEmailVerificacaoContext,
  'validador'
> {
  const context = useContext(ContaEmailVerificacaoContext);

  if (!context.validador) {
    throw new Error(
      'UseContaEmailVerificacao deve ser usado com um ContaEmailVerificacaoProvider'
    );
  }

  return context;
}

export { ContaEmailVerificacaoHook, UseContaEmailVerificacao };
