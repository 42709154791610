import api from '../../../../../../../Comum/Services/Api';

const rota =
  'transporte/conhecimento-transporte/transmissao/evento/impressao/carta-correcao';

interface IConhecimentoTransporteTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTransmissaoEventoCartaCorrecaoImpressaoComunicador {
  public async show(
    params: IConhecimentoTransporteTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoCartaCorrecaoImpressaoComunicador();
