import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/comissoes/comissoes-nota-fiscal/comissoes-nota-fiscal-por-liquidez-com-cliente';

interface IComissoesNotaFiscalPorLiquidezComClienteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ComissoesNotaFiscalPorLiquidezComClienteRelatorioComunicador {
  public async index(
    params: IComissoesNotaFiscalPorLiquidezComClienteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ComissoesNotaFiscalPorLiquidezComClienteRelatorioComunicador();
