import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FormatarEnum, TipoCustoEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import Select from '../../../../../../Componentes/Select';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const RelatorioEstoqueTab: React.FC = () => {
  const { formRef, refresh } = UseForm();

  const tipoCustoEnabled = formRef?.current?.getFieldValue(
    'relEstoqueSaldoProprioPoderProprioComCusto'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relEstoqueSaldoProprioTerceiro"
                label="Saldo Próprio e Terceiro"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={6} className="d-flex flex-row">
            <div className="checkbox-container">
              <InputCheckbox
                name="relEstoqueSaldoProprioPoderProprioComCusto"
                label="Saldo Próprio em Poder Próprio - Com Custo"
                onChange={refresh}
              />
            </div>
          </Col>
          <Col
            lg={3}
            md={6}
            sm={6}
            style={{ display: tipoCustoEnabled ? 'unset' : 'none' }}
          >
            <Select
              label="Tipo Custo"
              name="relEstoqueSaldoProprioPoderProprioComCustoTipoCusto"
              options={FormatarEnum({
                enumObj: TipoCustoEnum,
              })}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RelatorioEstoqueTab;
