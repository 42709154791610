import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaDataExtensa,
  FormatarDataParaPtBr,
  IOrdemServicoRetratoComProdutosServicosPagamentosImpressao,
} from '@elogestor/util';

const Document: any = _document;
const Page: any = _page;

interface IOrdemServicoRetratoComProdutosServicosPagamentosImpressaoPDF {
  dados: IOrdemServicoRetratoComProdutosServicosPagamentosImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
    border: '1px solid grey',
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
    fontSize: 9,
    border: '1px solid grey',
  },

  data: {
    fontSize: 8,
    width: '100%',
    border: '1px solid grey',
  },
});

const OrdemServicoRetratoComProdutosServicosPagamentosImpressaoPDF: React.FC<
  IOrdemServicoRetratoComProdutosServicosPagamentosImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                style={{ width: 80, borderRadius: 0 }}
              />
            )}
          </View>

          <View
            style={{
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            <View>
              <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 9 }}>
                {`CNPJ: ${dados.empresa.cnpjCpf} `}
                {dados.empresa.inscricaoEstadual &&
                  `/ IE: ${dados.empresa.inscricaoEstadual}`}
              </Text>
              <Text style={{ fontSize: 9 }}>
                {`${dados.empresa.empresaEndereco.logradouro}`}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`, ${dados.empresa.empresaEndereco.bairro}`}
              </Text>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              width: '25%',
              justifyContent: 'center',
              margin: '10pt 0 10pt 5pt',
              fontSize: 9,
            }}
          >
            <Text>
              {dados.empresa.primeiroTelefone &&
                `${dados.empresa.primeiroTelefone}`}
            </Text>
            <Text>
              {dados.empresa.segundoTelefone &&
                `${dados.empresa.segundoTelefone}`}
            </Text>
            <Text>{dados.empresa.email && dados.empresa.email}</Text>
          </View>
        </View>

        <View style={{ ...styles.headerEmpresa, backgroundColor: '#d1d1d1' }}>
          <View
            style={{
              width: '85%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              ORDEM DE SERVIÇO
            </Text>
          </View>

          <View
            style={{
              width: '15%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'space-around',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                marginBottom: '10pt',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '50%',
                }}
              >
                <View style={{ width: '50%' }}>
                  <Text>{`Nº: `}</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text>{dados.numero}</Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row', height: '50%' }}>
                <View style={{ width: '50%' }}>
                  <Text>{`DATA: `}</Text>
                </View>

                <View style={{ width: '50%' }}>
                  <Text>{FormatarDataParaPtBr(dados.dataHoraEmissao)}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View
              style={{
                alignItems: 'center',
                backgroundColor: '#d1d1d1',
                borderBottom: '1px solid grey',
              }}
            >
              <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                INFORMAÇÕES DO CLIENTE
              </Text>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '50%' }}>
                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Nome/ Razão Social: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${dados.ordemServicoPessoa.pessoa.codigo} - ${dados.ordemServicoPessoa.pessoa.nomeRazaoSocial}`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Endereço: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoaEndereco.logradouro &&
                      `${dados.ordemServicoPessoaEndereco.logradouro}, `}
                    {dados.ordemServicoPessoaEndereco.numero &&
                      `${dados.ordemServicoPessoaEndereco.numero}`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Complemento: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoaEndereco.complemento
                      ? dados.ordemServicoPessoaEndereco.complemento
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Bairro: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoaEndereco.bairro
                      ? dados.ordemServicoPessoaEndereco.bairro
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Município: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoaEndereco.cidade
                      ? dados.ordemServicoPessoaEndereco.cidade.nome
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Email: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoa.pessoa.email
                      ? dados.ordemServicoPessoa.pessoa.email
                      : ''}
                  </Text>
                </View>
              </View>

              <View style={{ width: '50%' }}>
                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'CNPJ/CPF: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoPessoa.pessoa.cnpjCpf
                        ? dados.ordemServicoPessoa.pessoa.cnpjCpf
                        : ''
                    }`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Inscrição Estadual: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoa.pessoa.inscricaoEstadual
                      ? dados.ordemServicoPessoa.pessoa.inscricaoEstadual
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Inscrição Municipal: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoa.pessoa.inscricaoMunicipal
                      ? dados.ordemServicoPessoa.pessoa.inscricaoMunicipal
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`CEP: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoaEndereco.cep
                      ? dados.ordemServicoPessoaEndereco.cep
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Telefone Fixo: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoa.pessoa.telefone
                      ? dados.ordemServicoPessoa.pessoa.telefone
                      : ''}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {`Celular: `}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {dados.ordemServicoPessoa.pessoa.celular
                      ? dados.ordemServicoPessoa.pessoa.celular
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View
              style={{
                alignItems: 'center',
                backgroundColor: '#d1d1d1',
                borderBottom: '1px solid grey',
              }}
            >
              <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                DADOS DO VEÍCULO
              </Text>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '50%' }}>
                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Marca: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.marcaVeiculo
                        ? dados.ordemServicoVeiculo.marcaVeiculo.descricao
                        : ''
                    }`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Modelo: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.modelo
                        ? dados.ordemServicoVeiculo.modelo
                        : ''
                    }`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Combustivel: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.combustivel
                        ? dados.ordemServicoVeiculo.combustivel
                        : ''
                    }`}
                  </Text>
                </View>
              </View>

              <View style={{ width: '50%' }}>
                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Placa: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.placa
                        ? dados.ordemServicoVeiculo.placa
                        : ''
                    }`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Ano: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.ano
                        ? dados.ordemServicoVeiculo.ano
                        : ''
                    }`}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', padding: 2 }}>
                  <Text style={{ fontWeight: 'bold', width: '30%' }}>
                    {'Km Atual: '}
                  </Text>
                  <Text style={{ width: '70%' }}>
                    {`${
                      dados.ordemServicoVeiculo.quilometragem
                        ? dados.ordemServicoVeiculo.quilometragem
                        : ''
                    }`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            style={{
              alignItems: 'center',
              padding: 2,
              backgroundColor: '#d1d1d1',
              borderBottom: '1px solid grey',
            }}
          >
            <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
              SERVIÇOS (MÃO DE OBRA)
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              borderBottom: '1px solid grey',
              padding: 2,
              fontSize: 9,
            }}
          >
            <View style={{ width: '14%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '50%' }}>
              <Text>Descrição do Serviço</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Tempo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val./Hora</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '12%', alignItems: 'flex-end' }}>
              <Text>Val. Total (R$)</Text>
            </View>
          </View>

          {dados.listaOrdemServicoItemServico?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                  padding: 2,
                }}
              >
                <View style={{ width: '14%' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '50%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            );
          })}

          <View style={{ borderTop: '1pt solid grey' }}>
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#d1d1d1',
                padding: 2,
              }}
            >
              <View style={{ width: '88%', alignItems: 'center' }}>
                <Text style={{ fontSize: 8, fontWeight: 'bold' }}>
                  {`TOTAL DO SERVIÇO`}
                </Text>
              </View>

              <View
                style={{
                  width: '12%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 8, fontWeight: 'bold' }}>
                  {Number(
                    dados.valorTotalOrdemServicoItemServico
                  ).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.data, marginTop: '10pt' }}>
          <View
            style={{
              alignItems: 'center',
              padding: 2,
              backgroundColor: '#d1d1d1',
              borderBottom: '1px solid grey',
            }}
          >
            <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
              PEÇAS E LUBRIFICANTES
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              borderBottom: '1px solid grey',
              padding: 2,
              fontSize: 9,
            }}
          >
            <View style={{ width: '14%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '50%' }}>
              <Text>Descrição do Item</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Quantidade</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Valor Un.</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '12%', alignItems: 'flex-end' }}>
              <Text>Val. Total (R$)</Text>
            </View>
          </View>

          {dados.listaOrdemServicoItemProduto?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                  padding: 2,
                }}
              >
                <View style={{ width: '14%' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '50%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            );
          })}

          <View style={{ borderTop: '1pt solid grey' }}>
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#d1d1d1',
                padding: 2,
                fontSize: 8,
                fontWeight: 'bold',
              }}
            >
              <View style={{ width: '88%', alignItems: 'center' }}>
                <Text>{`TOTAL DE PEÇAS E LUBRIFICANTES`}</Text>
              </View>

              <View
                style={{
                  width: '12%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{}}>
                  {Number(
                    dados.valorTotalOrdemServicoItemProduto
                  ).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'row', marginTop: '10pt' }}>
          <View style={{ width: '65%', border: '1px solid grey', fontSize: 9 }}>
            <View
              style={{
                fontSize: 10,
                fontWeight: 'bold',
                padding: 2,
                backgroundColor: '#d1d1d1',
              }}
            >
              <Text>Pagamento</Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                borderBottom: '1px solid grey',
                padding: 2,
              }}
            >
              <View style={{ width: '25%' }}>
                <Text>Vencimento</Text>
              </View>
              <View style={{ width: '1%' }} />

              <View style={{ width: '30%', alignItems: 'flex-end' }}>
                <Text>Valor</Text>
              </View>
              <View style={{ width: '5%' }} />

              <View style={{ width: '39%' }}>
                <Text>Forma de Pagamento</Text>
              </View>
            </View>

            {dados.listaConta &&
              dados.listaConta[0].listaContaParcela?.map((dado, index) => {
                return (
                  <View
                    key={String(index)}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                      padding: 2,
                    }}
                  >
                    <View style={{ width: '25%' }}>
                      <Text>{FormatarDataParaPtBr(dado.dataVencimento)}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '30%', alignItems: 'flex-end' }}>
                      <Text>
                        {Number(dado.valorTotalParcela).FormatarParaPtBr()}
                      </Text>
                    </View>
                    <View style={{ width: '5%' }} />

                    <View style={{ width: '39%' }}>
                      <Text>
                        {dado.formaPagamento && dado.formaPagamento.descricao}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>

          <View style={{ width: '35%', fontSize: 9 }}>
            <View style={{ marginTop: '10pt' }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '65%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Sub. Total: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalProdutos).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>

              {dados.valorFrete && dados.valorFrete > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Frete: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorFrete).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              {dados.valorSeguro && dados.valorSeguro > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Seguro: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorSeguro).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              {dados.valorOutrasDespesas && dados.valorOutrasDespesas > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Outras Desp.: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorOutrasDespesas).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              {dados.valorDesconto && dados.valorDesconto > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Desconto: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorDesconto).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              {dados.valorTotalIcmsSt && dados.valorTotalIcmsSt > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Total ICMS ST.: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorTotalIcmsSt).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              {dados.valorTotalIpi && dados.valorTotalIpi > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '65%', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`Valor Total IPI: `}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '35%',
                      alignItems: 'flex-end',
                      backgroundColor: '#cdcdcd',
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>
                      {Number(dados.valorTotalIpi).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              )}

              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '65%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Total: `}
                  </Text>
                </View>

                <View
                  style={{
                    width: '35%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {dados.observacoesComerciais && (
          <View
            style={{
              margin: '15pt 0 15pt 0',
              border: '1px solid grey',
              padding: 5,
              backgroundColor: '#d1d1d1',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  width: '100%',
                  alignItems: 'flex-end',
                }}
              >
                {dados.observacoesComerciais ? dados.observacoesComerciais : ''}
              </Text>
            </View>
          </View>
        )}

        <View
          fixed
          style={{
            marginTop: '10pt',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 9,
            border: '1px solid grey',
          }}
        >
          <Text style={{ marginTop: '15pt' }}>
            {`${
              dados.empresa.empresaEndereco.cidade.nome
            }, ${FormatarDataParaDataExtensa(dados.dataHoraEmissao)}`}
          </Text>

          <View style={{ flexDirection: 'row', margin: '20pt 0 15pt 0' }}>
            <View style={{ alignItems: 'center', width: '45%' }}>
              <View
                style={{
                  width: '80%',
                  borderBottom: '1px solid grey',
                  marginBottom: '5pt',
                }}
              />
              <Text>{dados.ordemServicoPessoa.pessoa.nomeRazaoSocial}</Text>
            </View>

            <View style={{ alignItems: 'center', width: '5%' }} />

            <View style={{ alignItems: 'center', width: '45%' }}>
              <View
                style={{
                  width: '80%',
                  borderBottom: '1px solid grey',
                  marginBottom: '5pt',
                }}
              />
              <Text>{`${dados.empresa.nomeRazaoSocial}`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OrdemServicoRetratoComProdutosServicosPagamentosImpressaoPDF;
