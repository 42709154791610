import {
  DesestruturarChaveAcesso,
  IManifestoTransporteDocumentoReferenciadoLista,
  SituacaoManifestoTransporteEnum,
  TipoDocumentoEnum,
  TipoEmitenteEnum,
} from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete, AiOutlineFileSearch } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { Container } from './styles';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import UseFuncoesPadrao from '../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteCidade from '../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputHiddenHtml from '../../../../../../../Componentes/Inputs/InputHiddenHtml';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import Input from '../../../../../../../Componentes/Inputs/Input';
import ListaPesquisaDocumentosEmitidos from './ListaPesquisaDocumentosEmitidos/Lista';
import ListaPesquisaDocumentosEmitidosHook from './ListaPesquisaDocumentosEmitidos/Hooks';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheDocumentoReferenciadoProps {
  name: string;
  className?: string;
}

const ManifestoTransporteDocumentoReferenciadoLista: React.FC<
  IListaDetalheDocumentoReferenciadoProps
> = ({ name, className }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const formPrincipal = UseForm();
  const {
    formRefLista,
    loading,
    refresh,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [showFormModal, setShowFormModal] = useState(false);
  const [
    showFormModalPesquisaDocumentosEmitidos,
    setShowFormModalPesquisaDocumentosEmitidos,
  ] = useState(false);

  const valorRef = useRef<IManifestoTransporteDocumentoReferenciadoLista[]>([]);

  const tipoEmitente =
    formPrincipal.formRef.current?.getFieldValue('tipoEmitente');
  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const [listaValor, setListaValor] = useState<
    IManifestoTransporteDocumentoReferenciadoLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IManifestoTransporteDocumentoReferenciadoLista[] | undefined>(
      {
        name: fieldName,
        ref: valorRef,

        getValue() {
          return undefined;
        },

        getDataNomeObjeto() {
          return valorRef.current;
        },

        getDataRecuperarFormulario() {
          return valorRef.current;
        },

        setValue(_, value) {
          if (!value) setListaValor([]);
          else setListaValor(value);
        },

        setSemExecutarEvento(_, value) {
          if (!value) setListaValor([]);
          else setListaValor(value);
        },

        clearValue(_, valorInicial) {
          this.setValue(_, valorInicial || []);
        },

        validarSeAlterou() {
          return false;
        },

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setDisabled() {},
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const estadoFimPercurso =
      formPrincipal.formRef.current?.getFieldValueNomeObjeto(
        'estadoFimPercurso'
      );

    const cidade = formRefLista.current?.getFieldValueNomeObjeto('cidade');

    if (!estadoFimPercurso && cidade) {
      formPrincipal.formRef.current?.setFieldValue(
        'estadoFimPercurso',
        cidade.estado
      );
    }

    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [
    formPrincipal,
    formRefLista,
    handleSalvarContinuar,
    handleSubmit,
    isJanelaAutoComplete,
    setIdDetalheRegistro,
  ]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      if (
        situacao === SituacaoManifestoTransporteEnum.naoTransmitido ||
        situacao === SituacaoManifestoTransporteEnum.rejeitado
      ) {
        const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [
      handleSalvarContinuar,
      isJanelaAutoComplete,
      setIdDetalheRegistro,
      situacao,
    ]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  useEffect(() => {
    formRefLista.current?.setDataInicial({});
  }, [formRefLista]);

  const handlePesquisarDocumentosEmitidos =
    useCallback(async (): Promise<void> => {
      const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
      if (erro) return;

      setShowFormModalPesquisaDocumentosEmitidos(true);
    }, [handleSalvarContinuar, isJanelaAutoComplete]);

  const handleSalvarFormModalPesquisaDocumentosEmitidos =
    useCallback(async (): Promise<void> => {
      setShowFormModalPesquisaDocumentosEmitidos(false);
    }, []);

  const handleFecharFormModalPesquisaDocumentosEmitidos =
    useCallback((): void => {
      setShowFormModalPesquisaDocumentosEmitidos(false);
    }, []);

  const handleObterNumeroChaveAcesso = useCallback(
    (chaveAcesso: string): void => {
      try {
        const informacoesChaveAcesso = DesestruturarChaveAcesso({
          chaveAcesso,
        });

        formRefLista.current?.setFieldValue(
          'numeroNota',
          informacoesChaveAcesso.numero
        );
      } catch (error) {
        const erro = error as any;
        ToastErro(erro.listaMensagem);
        formRefLista.current?.setFieldValue('numeroNota', null);
      }
    },
    [formRefLista]
  );

  useEffect(() => {
    let tipoDocumento = TipoDocumentoEnum.cte;

    if (tipoEmitente === TipoEmitenteEnum.transportadorCargaPropria)
      tipoDocumento = TipoDocumentoEnum.nfe;

    formRefLista.current?.setFieldValue('tipoDocumento', tipoDocumento);
  }, [formRefLista, tipoEmitente]);

  return (
    <Container className={className}>
      <FormCia ref={formRefLista}>
        <div style={{ width: '100%' }}>
          <InputHiddenHtml name="tipoDocumento" />
          <Row>
            <Col lg={2} md={6} sm={12} style={{ alignSelf: 'flex-end' }}>
              <BtnPadraoButton
                style={{ minWidth: 250, marginBottom: 10 }}
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handlePesquisarDocumentosEmitidos}
                disabled={
                  !permissao?.inclui ||
                  situacaoBloquearCampos ||
                  formPrincipal.loading ||
                  loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <AiOutlineFileSearch />
                  <span style={{ marginLeft: 10 }}>
                    {tipoEmitente === TipoEmitenteEnum.transportadorCargaPropria
                      ? 'Pesquisar NF-e Emitidos'
                      : 'Pesquisar CT-e Emitidos'}
                  </span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <Input
                label={
                  tipoEmitente === TipoEmitenteEnum.transportadorCargaPropria
                    ? 'Chave de Acesso NF-e'
                    : 'Chave de Acesso CT-e'
                }
                name="chaveAcesso"
                placeholder={
                  tipoEmitente === TipoEmitenteEnum.transportadorCargaPropria
                    ? 'Chave de Acesso NF-e'
                    : 'Chave de Acesso CT-e'
                }
                maxLength={44}
                onBlur={(event) => {
                  handleObterNumeroChaveAcesso(event.currentTarget.value);
                }}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                label="Número"
                name="numeroNota"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <InputAutoCompleteCidade
                label="Cidade Descarregamento"
                name="idCidade"
                nomeObjeto="cidade"
                placeholder="Cidade Descarregamento"
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12} style={{ alignSelf: 'flex-end' }}>
              <BtnPadraoButton
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui ||
                  situacaoBloquearCampos ||
                  formPrincipal.loading ||
                  loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </div>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Chave de Acesso:</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Número</span>
              </th>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Cidade de Descarregamento</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((valor) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(valor.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{valor.chaveAcesso}</div>
                  </td>
                  <td className="lista-valor">
                    <div>{valor.numeroNota}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{`${valor.cidade.nome} - ${valor.cidade.estado.sigla}`}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(valor.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            situacaoBloquearCampos ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}

      {showFormModalPesquisaDocumentosEmitidos && (
        <ListaPesquisaDocumentosEmitidosHook>
          <ListaPesquisaDocumentosEmitidos
            onSalvarFormModal={handleSalvarFormModalPesquisaDocumentosEmitidos}
            onFecharFormModal={handleFecharFormModalPesquisaDocumentosEmitidos}
            tipoEmitente={tipoEmitente}
            listaManifestoTransporteDocumentoReferenciado={listaValor}
          />
        </ListaPesquisaDocumentosEmitidosHook>
      )}
    </Container>
  );
};

export default ManifestoTransporteDocumentoReferenciadoLista;
