import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/regra-escolha-sisdevin/log';

interface IRegraEscolhaSisdevinLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRegraEscolhaSisdevinLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RegraEscolhaSisdevinLogComunicador {
  public async index(
    params: IRegraEscolhaSisdevinLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaSisdevinLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RegraEscolhaSisdevinLogComunicador();
