import { FormatarDataParaPtBr, IContasVinculadasLista } from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';
import ContasParcelaReversaoComunicador from '../../../../../../Comunicador/Financeiro/Movimentacoes/ContasParcelaReversao/Comunicador/ContasParcelaReversaoComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import FinanceiroDetalhe from './Financeiro';

const ContasVinculadasTab: React.FC = () => {
  const formPrincipal = UseForm();

  const [listaValor, setListaValor] = useState<IContasVinculadasLista[]>([]);
  const [idParcelaEditar, setIdParcelaEditar] = useState('');
  const [showModalContaVinculada, setShowModalContaVinculada] = useState(false);
  const idConta = formPrincipal.formRef.current?.getFieldValue('idConta');

  const handleCarregarDados = useCallback(async () => {
    try {
      if (idConta) {
        const response = await ContasParcelaReversaoComunicador.show({
          id: String(idConta),
        });
        setListaValor(response);
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [idConta]);

  useEffect(() => {
    if (formPrincipal.terminouCarregarDados && idConta) handleCarregarDados();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPrincipal.terminouCarregarDados, idConta]);

  const handleAbrirContaVinculada = useCallback(
    async (idContaParcela: string) => {
      setIdParcelaEditar(idContaParcela);
      setShowModalContaVinculada(true);
    },
    []
  );

  const handleFinanceiroFecharSalvarFormModal = useCallback(async () => {
    handleCarregarDados();
    setShowModalContaVinculada(false);
  }, [handleCarregarDados]);

  return (
    <>
      <Tabela style={{ overflow: 'auto', maxWidth: '100%' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Número</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Parcela</span>
              </th>
              <th className="lista-data" style={{ width: '10%' }}>
                <span>Data Hora Emissão</span>
              </th>
              <th className="lista-data" style={{ width: '10%' }}>
                <span>Data Vencimento</span>
              </th>
              <th className="lista-valor" style={{ width: '12%' }}>
                <span>Valor Parcela</span>
              </th>
              <th className="lista-valor" style={{ width: '12%' }}>
                <span>Valor Total Parcela</span>
              </th>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>Cliente/Fornecedor</span>
              </th>
              <th className="lista-texto" style={{ width: '16%' }}>
                <span>Tipo</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr
                  key={v4()}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleAbrirContaVinculada(String(item.parcela.id));
                  }}
                >
                  <td className="lista-valor">{item.conta.numeroDocumento}</td>
                  <td className="lista-texto">{`${item.parcela.sequencia}/${item.conta.quantidadeParcelas}`}</td>
                  <td className="lista-data">
                    {FormatarDataParaPtBr(item.conta.dataHoraEmissao)}
                  </td>
                  <td className="lista-data">
                    {FormatarDataParaPtBr(item.parcela.dataVencimento)}
                  </td>
                  <td className="lista-valor">
                    {Number(item.parcela.valorParcela).FormatarParaPtBr()}
                  </td>
                  <td className="lista-valor">
                    {Number(item.parcela.valorTotalParcela).FormatarParaPtBr()}
                  </td>

                  <td className="lista-texto">
                    {item.conta.pessoa
                      ? `${item.conta.pessoa.codigo}-${item.conta.pessoa.nomeRazaoSocial}`
                      : ''}
                  </td>
                  <td className="lista-texto">{item.tipoConta}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>

      {showModalContaVinculada && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroFecharSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharSalvarFormModal}
          idParcela={idParcelaEditar}
        />
      )}
    </>
  );
};

export default ContasVinculadasTab;
