import { IFormCiaHandles } from '@elogestor/unformcia';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Container } from 'react-bootstrap';
import { IContaValoresAlterar, IContaValoresInserir } from '@elogestor/util';
import Divisor from '../../../../../../../Componentes/Divisor';
import ContaHook from '../../../../../../Financeiro/Movimentacoes/Conta/Detalhe/Hooks';
import Main, {
  IContaDetalheRef,
} from '../../../../../../Financeiro/Movimentacoes/Conta/Detalhe/Main';

export interface IImportacaoNfeTerceiroEtapa4Ref {
  handleSetarDadosPadrao(valores: IContaValoresAlterar | null): Promise<void>;
  handlePossuiFinanceiro(): Promise<boolean>;
  handleObterValidarDados(): Promise<{
    dados: IContaValoresInserir | null;
    valido: boolean;
  }>;
}

interface IImportacaoNfeTerceiroEtapa4Props {
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoNfeTerceiroEtapa4: React.ForwardRefRenderFunction<
  IImportacaoNfeTerceiroEtapa4Ref,
  IImportacaoNfeTerceiroEtapa4Props
> = ({ formRef }, ref) => {
  const contaRef = useRef<IContaDetalheRef>(null);

  const [dadosPadrao, setDadosPadrao] = useState<IContaValoresAlterar | null>(
    null
  );

  const handleSetarDadosPadrao = useCallback(
    async (valores: IContaValoresAlterar | null): Promise<void> => {
      if (valores) {
        setDadosPadrao({ ...valores });
      }
    },
    []
  );

  const handlePossuiFinanceiro = useCallback(async (): Promise<boolean> => {
    if (contaRef.current) return true;
    return false;
  }, []);

  const handleObterValidarDados = useCallback(async (): Promise<{
    dados: IContaValoresInserir | null;
    valido: boolean;
  }> => {
    if (contaRef.current) {
      const dadosConta = await contaRef.current.handleObterValidarDadosConta();

      return {
        dados: dadosConta.dados,
        valido: dadosConta.valido,
      };
    }

    return { dados: null, valido: true };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleSetarDadosPadrao,
      handlePossuiFinanceiro,
      handleObterValidarDados,
    };
  });

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      {!dadosPadrao && (
        <Divisor style={{ fontWeight: 'bold', margin: 10, padding: 10 }}>
          XML Não Possui Financeiro!
        </Divisor>
      )}

      {dadosPadrao && (
        <Container>
          <ContaHook>
            <Main
              ref={contaRef}
              idEditar={null}
              dadosRecuperados={null}
              dadosDuplicados={null}
              dadosPadrao={dadosPadrao}
              exibirBotoes={false}
              bloquearCampos
              isJanela
            />
          </ContaHook>
        </Container>
      )}
    </div>
  );
};

export default forwardRef(ImportacaoNfeTerceiroEtapa4);
