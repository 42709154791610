import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/servico';

interface IConhecimentoTransporteServicoComunicadorShow {
  id: string;
}

interface IConhecimentoTransporteServicoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteServicoComunicadorUpdate {
  params: any;
  id: string;
}

interface IConhecimentoTransporteServicoComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteServicoComunicador {
  public async show(
    params: IConhecimentoTransporteServicoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(
    params: IConhecimentoTransporteServicoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteServicoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteServicoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteServicoComunicador();
