import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/sped/configuracao';

interface ISpedConfiguracaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ISpedConfiguracaoComunicadorShow {
  id: string;
}

interface ISpedConfiguracaoComunicadorStore {
  params: any;
}

interface ISpedConfiguracaoComunicadorUpdate {
  id: string;
  params: any;
}

interface ISpedConfiguracaoComunicadorDeletar {
  id: string;
}

class SpedConfiguracaoComunicador {
  public async index(params: ISpedConfiguracaoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ISpedConfiguracaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ISpedConfiguracaoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: ISpedConfiguracaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: ISpedConfiguracaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new SpedConfiguracaoComunicador();
