import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import BtnCloseButton from '../../../../../../Componentes/Buttons/BtnCloseButton';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';

interface IVeiculoContext {
  abrirVeiculoDuplicar(placa: string): Promise<string | null>;
  fecharVeiculoDuplicar(): void;
}

const VeiculoContext = createContext<IVeiculoContext>({} as IVeiculoContext);

const VeiculoHookDuplicar: React.FC<IPadraoProps> = ({ children }) => {
  const formRefDetalhe = useRef<IFormCiaHandles>(null);
  const resolveRef = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [aberto, setAberto] = useState(false);
  const [placaDetalhe, setPlacaDetalhe] = useState('');

  const handleAbrir = useCallback(async (placa: string) => {
    setAberto(true);
    setPlacaDetalhe(placa);
    formRefDetalhe.current?.setFieldError('placa', '');

    return new Promise<any>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSalvar = useCallback(async () => {
    setLoading(true);

    const placa = formRefDetalhe.current?.getFieldValue('placa');

    if (!placa) {
      ToastErro('Placa é Obrigatória!');
      formRefDetalhe.current?.setFieldError('placa', 'Placa é Obrigatória!');
      setLoading(false);
      return;
    }

    if (placa === placaDetalhe) {
      ToastErro('Placa já cadastrada!');
      formRefDetalhe.current?.setFieldError('placa', 'Placa já cadastrada!');
      setLoading(false);
      return;
    }

    resolveRef.current(placa);
    setAberto(false);
    setLoading(false);
  }, [placaDetalhe]);

  return (
    <VeiculoContext.Provider
      value={{
        abrirVeiculoDuplicar: handleAbrir,
        fecharVeiculoDuplicar: handleFechar,
      }}
    >
      <FormCia ref={formRefDetalhe}>
        <Modal show={aberto} onHide={handleFechar} size="lg">
          <Modal.Header
            style={{
              background: '#3397c4',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Modal.Title>Duplicar Veículo</Modal.Title>
            <BtnCloseButton type="button" onClick={handleFechar}>
              <IoIosCloseCircleOutline />
            </BtnCloseButton>
          </Modal.Header>

          <Modal.Body style={{ maxHeight: 500 }}>
            <Divisor>
              <Row>
                <Col lg={8} md={8} sm={12}>
                  <Input
                    label="Placa"
                    name="placa"
                    placeholder="Placa"
                    maxLength={7}
                    maiuscula
                    formatarValor={(event) => {
                      return event.target.value.replace(/[^a-zA-Z0-9]/gi, '');
                    }}
                  />
                </Col>
              </Row>
            </Divisor>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={handleFechar}
                  disabled={loading}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <TextoLoading loading={loading}>
                      <MdCancel />
                      <span style={{ marginLeft: 10 }}>Cancelar</span>
                    </TextoLoading>
                  </span>
                </button>

                <Button
                  style={{
                    fontWeight: 'bold',
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type="button"
                  onClick={handleSalvar}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <AiOutlineCheck />
                    <span style={{ marginLeft: 10 }}>OK</span>
                  </TextoLoading>
                </Button>
              </Container>
            </div>
          </Modal.Footer>
        </Modal>
      </FormCia>
      {children}
    </VeiculoContext.Provider>
  );
};

function UseVeiculoDuplicar(): IVeiculoContext {
  const context = useContext(VeiculoContext);

  if (!context) {
    throw new Error('UseVeiculoDuplicar deve ser Usado com VeiculoProvider');
  }

  return context;
}

export { VeiculoHookDuplicar, UseVeiculoDuplicar };
