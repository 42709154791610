import { Font } from '@react-pdf/renderer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import carlito from './Assets/fonts/carlito/carlito.ttf';
import carlitoBold from './Assets/fonts/carlito/carlito_bold.ttf';
import carlitoItalic from './Assets/fonts/carlito/carlito_italic.ttf';
import carlitoBoldItalic from './Assets/fonts/carlito/carlito_bold_italic.ttf';

import roboto from './Assets/fonts/roboto/roboto.ttf';
import robotoBold from './Assets/fonts/roboto/roboto_bold.ttf';
import robotoItalic from './Assets/fonts/roboto/roboto_italic.ttf';
import robotoBoldItalic from './Assets/fonts/roboto/roboto_bold_italic.ttf';

import tinos from './Assets/fonts/tinos/tinos.ttf';
import tinosBold from './Assets/fonts/tinos/tinos_bold.ttf';
import tinosItalic from './Assets/fonts/tinos/tinos_italic.ttf';
import tinosBoldItalic from './Assets/fonts/tinos/tinos_bold_italic.ttf';

Font.register({
  family: 'Carlito',
  fonts: [
    {
      src: carlito,
    },
    {
      src: carlitoBold,
      fontWeight: 'bold',
    },
    {
      src: carlitoItalic,
      fontStyle: 'italic',
    },
    {
      src: carlitoBoldItalic,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: roboto,
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
    {
      src: robotoItalic,
      fontStyle: 'italic',
    },
    {
      src: robotoBoldItalic,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

Font.register({
  family: 'Tinos',
  fonts: [
    {
      src: tinos,
    },
    {
      src: tinosBold,
      fontWeight: 'bold',
    },
    {
      src: tinosItalic,
      fontStyle: 'italic',
    },
    {
      src: tinosBoldItalic,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

const rootElement = document.getElementById('root') as any;
const root = ReactDOM.createRoot(rootElement);

root.render(<App />);
