import { createContext, useContext } from 'react';

interface ISubHeaderButtonsVisivel {
  ExcluirVisivel: boolean;
  DuplicarVisivel: boolean;
  DuplicarSalvarVisivel: boolean;
  SalvarContinuarVisivel: boolean;
  SalvareNovoVisivel: boolean;
  SalvareFecharVisivel: boolean;
  VoltarVisivel: boolean;
  SalvarVisivel: boolean;
}

interface ISubHeaderButtonsHabilitado {
  ExcluirHabilitado: boolean;
  DuplicarHabilitado: boolean;
  DuplicarSalvarHabilitado: boolean;
  SalvarContinuarHabilitado: boolean;
  SalvareNovoHabilitado: boolean;
  SalvareFecharHabilitado: boolean;
  SalvareHabilitado: boolean;
}

interface ISubHeaderContext {
  subHeaderButtonsVisivel: ISubHeaderButtonsVisivel;
  subHeaderButtonsHabilitado: ISubHeaderButtonsHabilitado;
}

const subHeaderContext = createContext<ISubHeaderContext>(
  {} as ISubHeaderContext
);
const SubHeaderProvider = subHeaderContext.Provider;

function UseSubHeader(): ISubHeaderContext {
  const context = useContext(subHeaderContext);

  return context;
}

export { SubHeaderProvider, UseSubHeader };
