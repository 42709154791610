import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const XmlPdfNotasEmitidasTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="xmlNfePropriaEmitidas"
                label="Xml NF-e Emitidas (Autorizadas/Denegadas)"
                placeholder="Xml Nfe Emitidas"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="danfeNfePropriaPdf"
                label="Danfe NF-e (PDF)"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="incluirNfePropriaCanceladas"
                label="Incluir Eventos NF-e (Canceladas/Cartas de Correção)"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default XmlPdfNotasEmitidasTab;
