import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque-saldo-proprio-em-poder-proprio-estoque-maximo-minimo';

interface IEstoqueSaldoProprioEmPoderProprioMinimoMaximoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoProprioEmPoderProprioMinimoMaximoRelatorioComunicador {
  public async index(
    params: IEstoqueSaldoProprioEmPoderProprioMinimoMaximoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoProprioEmPoderProprioMinimoMaximoRelatorioComunicador();
