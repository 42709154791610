import React from 'react';
import { BiSave } from 'react-icons/bi/index.mjs';
import TextoLoading from '../TextoLoading';

interface ITextoLoadingSalvar {
  loading: boolean;
}

const TextoLoadingSalvar: React.FC<ITextoLoadingSalvar> = ({ loading }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextoLoading loading={loading}>
        <BiSave />
        <span style={{ marginLeft: 10 }}>Salvar</span>
      </TextoLoading>
    </div>
  );
};

export default TextoLoadingSalvar;
