import {
  IDiarioAuxiliarEmissaoCabecalhoRelatorio,
  IDiarioAuxiliarEmissaoRelatorio,
  IDiarioAuxiliarFiltroRelatorioFrontend,
  IDiarioAuxiliarFiltroRelatorioBackend,
} from '@elogestor/util';
import React from 'react';
import DiarioAuxiliarComunicador from '../../../../../../Comunicador/Fiscal/Relatorios/DiarioAuxiliar/DiarioAuxiliarRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import DiarioAuxiliarClientesPorDocumentoPlanilha from './DiarioAuxiliarClientesPorDocumentoPlanilha';
import DiarioAuxiliarClientesPorDocumentoPDF from './DiarioAuxiliarClientesPorDocumentoPDF';

interface IRelatorios {
  handleExportCSV(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const DiarioAuxiliarClientesPorDocumento = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IDiarioAuxiliarEmissaoRelatorio | null> => {
    const filtroBack: IDiarioAuxiliarFiltroRelatorioBackend = {};

    if (filtros.dataEmissaoInicial) {
      filtroBack.dataEmissaoInicial = new Date(filtros.dataEmissaoInicial);
    }
    if (filtros.dataEmissaoFinal) {
      filtroBack.dataEmissaoFinal = new Date(filtros.dataEmissaoFinal);
    }

    if (filtros?.tipoRelatorio) {
      filtroBack.tipoRelatorio = filtros.tipoRelatorio;
    }

    if (filtros?.considerarAdiantamentos !== undefined) {
      filtroBack.considerarAdiantamentos = filtros.considerarAdiantamentos;
    }

    if (filtros?.somenteLancamentosComDocumento !== undefined) {
      filtroBack.somenteLancamentosComDocumento =
        filtros.somenteLancamentosComDocumento;
    }

    if (filtros?.listarPagamentos !== undefined) {
      filtroBack.listarPagamentos = filtros.listarPagamentos;
    }

    if (filtros?.listaPessoaCliente) {
      filtroBack.listaIdPessoaCliente =
        filtros.listaPessoaCliente?.listaValor.map((pessoa: any) => pessoa.id);
    }

    const response: IDiarioAuxiliarEmissaoRelatorio =
      await DiarioAuxiliarComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    response.listaDados.forEach((dado) => {
      dado.listaDiarioAuxiliarItem.forEach((item) => {
        item.saldoInicial = Number(item.saldoInicial).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        });
        item.saldoGeral = Number(item.saldoGeral).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        });

        item.valorCredito = Number(item.valorCredito).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        });

        item.valorDebito = Number(item.valorDebito).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        });
      });

      dado.valores.somatorioDiarioAuxiliarSaldoGeral = Number(
        dado.valores.somatorioDiarioAuxiliarSaldoGeral
      ).FormatarParaPtBr({
        maximoCasasDecimais: 2,
        minimoCasasDecimais: 2,
      });

      dado.valores.somatorioDiarioAuxiliarValorCredito = Number(
        dado.valores.somatorioDiarioAuxiliarValorCredito
      ).FormatarParaPtBr({
        maximoCasasDecimais: 2,
        minimoCasasDecimais: 2,
      });

      dado.valores.somatorioDiarioAuxiliarValorDebito = Number(
        dado.valores.somatorioDiarioAuxiliarValorDebito
      ).FormatarParaPtBr({
        maximoCasasDecimais: 2,
        minimoCasasDecimais: 2,
      });
    });

    response.somatorioGeralSaldoGeral = Number(
      response.somatorioGeralSaldoGeral
    ).FormatarParaPtBr({
      maximoCasasDecimais: 2,
      minimoCasasDecimais: 2,
    });

    response.somatorioGeralValorCredito = Number(
      response.somatorioGeralValorCredito
    ).FormatarParaPtBr({
      maximoCasasDecimais: 2,
      minimoCasasDecimais: 2,
    });

    response.somatorioGeralValorDebito = Number(
      response.somatorioGeralValorDebito
    ).FormatarParaPtBr({
      maximoCasasDecimais: 2,
      minimoCasasDecimais: 2,
    });

    const retorno = response as IDiarioAuxiliarEmissaoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dado) => {
        return {
          ...dado,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend,
    cabecalho: IDiarioAuxiliarEmissaoCabecalhoRelatorio
  ): IDiarioAuxiliarEmissaoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (
      filtros.considerarAdiantamentos !== undefined &&
      filtros.considerarAdiantamentos !== null
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroConsiderarAdiantamentos = Boolean(
        filtros.considerarAdiantamentos
      );
    }

    if (
      filtros.somenteLancamentosComDocumento !== undefined &&
      filtros.somenteLancamentosComDocumento !== null
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteLancamentosComDocumento = Boolean(
        filtros.somenteLancamentosComDocumento
      );
    }

    if (
      filtros.listarPagamentos !== undefined &&
      filtros.listarPagamentos !== null
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroListarPagamentos = Boolean(filtros.listarPagamentos);
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: DiarioAuxiliarClientesPorDocumentoPlanilha,
      nomeArquivo: 'DiarioAuxiliarClientesPorDocumento',
      valores: dados.listaDados,
      unwind: ['listaDiarioAuxiliarItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: DiarioAuxiliarClientesPorDocumentoPlanilha,
      nomeArquivo: 'DiarioAuxiliarClientesPorDocumento',
      valores: dados.listaDados as any,
      unwind: ['listaDiarioAuxiliarItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DiarioAuxiliarClientesPorDocumentoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IDiarioAuxiliarFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DiarioAuxiliarClientesPorDocumentoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default DiarioAuxiliarClientesPorDocumento();
