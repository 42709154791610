"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegimeTributarioCustoEnum;
(function (RegimeTributarioCustoEnum) {
    RegimeTributarioCustoEnum["simplesNacional"] = "Simples Nacional";
    RegimeTributarioCustoEnum["simplesNacionalExcessoSublimiteReceitaBruta"] = "Simples Nacional \u2013 excesso de sublimite de receita bruta";
    RegimeTributarioCustoEnum["lucroPresumido"] = "Lucro Presumido";
    RegimeTributarioCustoEnum["lucroReal"] = "Lucro Real";
})(RegimeTributarioCustoEnum || (RegimeTributarioCustoEnum = {}));
exports.default = RegimeTributarioCustoEnum;
