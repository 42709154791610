import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/obter-conta';

interface INotaFiscalEntradaObterContaComunicadorShow {
  id: string;
}

class NotaFiscalEntradaObterContaComunicador {
  public async show(
    params: INotaFiscalEntradaObterContaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaObterContaComunicador();
