"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCteEnum;
(function (TipoCteEnum) {
    TipoCteEnum["normal"] = "0 - Normal";
    TipoCteEnum["complementoValores"] = "1 - Complemento de Valores";
    TipoCteEnum["anulacao"] = "2 - Anula\u00E7\u00E3o";
    TipoCteEnum["substituto"] = "3 - Substituto";
})(TipoCteEnum || (TipoCteEnum = {}));
exports.default = TipoCteEnum;
