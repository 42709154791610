const ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha = [
  {
    titulo: 'Data Liquidacao',
    valor: 'valores.dataLiquidacaoOuContabil',
  },
  {
    titulo: 'Núm Documento',
    valor: 'listaConta.numeroDocumento',
  },
  {
    titulo: 'Sequência',
    valor: 'listaConta.sequencia',
  },
  {
    titulo: 'Quantidade Parcelas',
    valor: 'listaConta.quantidadeParcelas',
  },

  {
    titulo: 'Valor Total Liquidado',
    valor: 'listaConta.valorTotalLiquidado',
  },

  {
    titulo: 'Juros',
    valor: 'listaConta.valorJurosSoma',
  },

  {
    titulo: 'Descontos',
    valor: 'listaConta.valorDescontoSubtrai',
  },

  {
    titulo: 'Despesas Outros (+)',
    valor: 'listaConta.despesasOutrosSoma',
  },

  {
    titulo: 'Despesas Outros (-)',
    valor: 'listaConta.despesasOutrosSubtrai',
  },

  {
    titulo: 'Cliente',
    valor: 'listaConta.cliente',
  },

  {
    titulo: 'CNPJ / CPF',
    valor: 'listaConta.cnpjCpf',
  },

  {
    titulo: 'Banco',
    valor: 'listaConta.valorTotalParcela',
  },
];

export default ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha;
