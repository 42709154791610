import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/transmissao/evento/transmitir';

interface IConhecimentoTransporteTransmissaoEventoTransmitirComunicadorUpdate {
  id: string;
  params: any;
}

class ConhecimentoTransporteTransmissaoEventoTransmitirComunicador {
  public async update(
    params: IConhecimentoTransporteTransmissaoEventoTransmitirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoTransmitirComunicador();
