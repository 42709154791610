"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarDataHoraComTimeZone;
const date_fns_tz_1 = require("date-fns-tz");
function FormatarDataHoraComTimeZone(value, timeZone) {
    if (!value)
        return '';
    if (typeof value === 'string') {
        let parsedDate = `${value}`;
        if (value.length === 10) {
            if (!value.includes('/')) {
                parsedDate += 'T00:00:00';
                if (timeZone)
                    parsedDate += timeZone;
            }
            else {
                parsedDate += ' 00:00:00';
                if (timeZone)
                    parsedDate += timeZone;
            }
        }
        else {
            const parcial = value.split(' ');
            const parcialData = parcial[0].split('-');
            const parcialHora = parcial[1];
            if (parcialData[0].length === 2) {
                parsedDate = `${parcialData[2]}-${parcialData[1]}-${parcialData[0]} ${parcialHora}`;
                if (parsedDate.length === 19) {
                    if (timeZone)
                        parsedDate += timeZone;
                }
            }
        }
        value = new Date(parsedDate);
    }
    if (timeZone) {
        const dataParcial = (0, date_fns_tz_1.utcToZonedTime)(value, timeZone);
        return (0, date_fns_tz_1.format)(dataParcial, 'yyyy-MM-dd HH:mm:ssxxx', {
            timeZone,
        }).replace(' ', 'T');
    }
    return (0, date_fns_tz_1.format)(value, 'yyyy-MM-dd HH:mm:ssxxx').replace(' ', 'T');
}
