import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteProduto from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import SimplesNacionalTab from './SimplesNacionalTab';
import SimplesNacionalExcessoSubLimiteTab from './SimplesNacionaExcessoSubLimite';
import LucroPresumidoTab from './LucroPresumidoTab';
import LucroRealTab from './LucroRealTab';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import DiferencialAliquotaTab from './DiferencialAliquotaTab';

interface IImpostosCreditosModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const ImpostosCreditosDetalhe: React.FC<IImpostosCreditosModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;
  const { formRefDetalhe, loading, handleCarregarDados, handleSubmit } =
    UseListaDetalheForm();
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const [tabSelecionada, setTabSelecionada] = useState('simplesNacional');
  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleFecharModal = useCallback(async () => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
      onFecharFormModal();
    } else {
      onFecharFormModal();
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, onFecharFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Impostos / Créditos"
      tamanho="xl"
      onFecharFormModal={handleFecharModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <JanelaNavegacao
                  containerStyle={
                    telaPequena
                      ? {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                      : {
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                  }
                />
              </Col>
            </Row>

            <Divisor>
              <Row>
                <InputHiddenHtml name="idNotaFiscalEntrada" />
                <Col lg={8} md={6} sm={6}>
                  <InputAutoCompleteProduto
                    label="Produto/Serviço"
                    name="idProduto"
                    nomeObjeto="produto"
                    disabled
                    permitirAdicionar={false}
                  />
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <InputInteiro label="Ordem" name="ordem" disabled />
                </Col>
              </Row>
            </Divisor>
            <Tabs
              id={v4()}
              defaultActiveKey="impostos"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="simplesNacional" title="Simples Nacional">
                <SimplesNacionalTab />
              </Tab>

              <Tab
                eventKey="simplesNacionalExcessoSubLimite"
                title="Simples Nacional Excesso do Sublimite"
              >
                <SimplesNacionalExcessoSubLimiteTab />
              </Tab>

              <Tab eventKey="lucroPresumido" title="Lucro Presumido">
                <LucroPresumidoTab />
              </Tab>

              <Tab eventKey="lucroReal" title="Lucro Real">
                <LucroRealTab />
              </Tab>

              <Tab eventKey="diferencialAliquota" title="Diferencial Alíquota">
                <DiferencialAliquotaTab />
              </Tab>
            </Tabs>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{ marginLeft: 15, display: 'flex', alignItems: 'center' }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading}
            >
              <BiSave />
              <span style={{ marginLeft: 10 }}>Salvar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ImpostosCreditosDetalhe;
