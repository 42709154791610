import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/engenharia/roteiro-fabricacao-padrao';

interface IRoteiroFabricacaoPadraoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRoteiroFabricacaoPadraoComunicadorShow {
  id: string;
}

interface IRoteiroFabricacaoPadraoComunicadorStore {
  params: any;
}

interface IRoteiroFabricacaoPadraoComunicadorUpdate {
  id: string;
  params: any;
}

interface IRoteiroFabricacaoPadraoComunicadorDeletar {
  id: string;
}

class RoteiroFabricacaoPadraoComunicador {
  public async index(
    params: IRoteiroFabricacaoPadraoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRoteiroFabricacaoPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRoteiroFabricacaoPadraoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRoteiroFabricacaoPadraoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRoteiroFabricacaoPadraoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RoteiroFabricacaoPadraoComunicador();
