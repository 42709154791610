import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Container, Button, Row, Col } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { ModeloImpressaoConsignadoEnum } from '@elogestor/util';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { UseFrenteCaixaPdvCalculo } from '../../../Hooks/FrenteCaixaPdvCalculoHook';
import FrenteCaixaPdvGerarPedidoVendaComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarPedidoVendaComunicador';
import InputAutoCompleteFinalidadeUsoCliente from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteFinalidadeUsoCliente';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoRetiradaConsignadoImpressao/PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador';
import PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao from '../../../../../PedidoVenda/Detalhe/AcoesPedidoVenda/Impressao/Template/PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao';
import ToastAviso from '../../../../../../../../Util/Toasts/ToastAviso';
import PedidoVendaRetratoRetiradaConsignadoImpressao from '../../../../../PedidoVenda/Detalhe/AcoesPedidoVenda/Impressao/Template/PedidoVendaRetratoRetiradaConsignadoImpressao';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import FrenteCaixaPdvAtualizarObservacaoClienteComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvAtualizarObservacaoClienteComunicador';
import FrenteCaixaPdvObterObservacaoClienteParaConsignadoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterObservacaoClienteParaConsignadoComunicador';

interface IGerarConsignadoDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
  idCliente: string;
}

const GerarConsignadoDetalhe: React.FC<IGerarConsignadoDetalheModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  loading,
  setLoading,
  idCliente,
}) => {
  const { formRef: formRefPrincipal } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const parametros = UseParametros();
  const formRef = useRef<IFormCiaHandles>(null);

  const { listaItem } = UseFrenteCaixaPdv();

  const { liberar } = UseFrenteCaixaPdvCalculo();

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response =
        await FrenteCaixaPdvObterObservacaoClienteParaConsignadoComunicador.show(
          { id: idCliente }
        );

      formRef.current?.setDataInicial({
        observacoes: response.observacoes,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idCliente, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImprimir = useCallback(
    async (idRegistro: string) => {
      try {
        setLoading(true);

        const texoPadraoRetiradaConsignado =
          parametros.TextoPadraoRetiradaConsignado;

        switch (parametros.ModeloImpressaoConsignado) {
          case ModeloImpressaoConsignadoEnum.termica80mm:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { id: idRegistro } }
                );

              await PedidoVendaRetratoRetiradaConsignadoImpressao.handleVisualizarPdf(
                { ...response, textoPadrao: texoPadraoRetiradaConsignado }
              );
            }
            break;

          case ModeloImpressaoConsignadoEnum.A4Paisagem2Vias:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { id: idRegistro } }
                );

              await PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao.handleVisualizarPdf(
                { ...response, textoPadrao: texoPadraoRetiradaConsignado }
              );
            }
            break;

          default:
            ToastAviso(
              'Selecione um Modelo de Impressão para a Retirada Consignada, nos Parâmetros!'
            );
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      parametros.ModeloImpressaoConsignado,
      parametros.TextoPadraoRetiradaConsignado,
      setLoading,
    ]
  );

  const handleGerarRetiradaConsignado = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRefPrincipal.current?.getData() as any;
      const props = formRef.current?.getData() as any;

      const dados = {
        ...data,
        idFinalidadeUsoCliente: props?.idFinalidadeUsoCliente,
        listaItem,
        gerarPedidoVenda: true,
        gerarRetiradaConsignado: true,
      };

      const response = await FrenteCaixaPdvGerarPedidoVendaComunicador.store({
        params: dados,
      });

      await FrenteCaixaPdvAtualizarObservacaoClienteComunicador.update({
        params: { idCliente, observacoes: props.observacoes },
      });

      ToastSucesso(response.mensagem);

      await handleImprimir(response.id);
      await liberar();

      if (onSalvarFormModal) onSalvarFormModal();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRefPrincipal,
    handleImprimir,
    idCliente,
    liberar,
    listaItem,
    onSalvarFormModal,
    setLoading,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  return (
    <JanelaDetalhe
      titulo="Gerar Retirada Consignada"
      tamanho="lg"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={12} md={6} sm={12}>
                <InputAutoCompleteFinalidadeUsoCliente
                  label="Finalidade de Uso do Cliente"
                  name="idFinalidadeUsoCliente"
                  nomeObjeto="finalidadeUsoCliente"
                />
              </Col>
            </Row>
          </Divisor>
          <Divisor>
            <Row>
              <Col lg={12} md={6} sm={12}>
                <Textarea
                  style={{ minHeight: 150 }}
                  label="Observação do Cliente"
                  name="observacoes"
                  maxLength={10000}
                  onChange={(event) => {
                    const quantidadeDeLinhas =
                      event.target.value.split('\n').length;
                    const height = `${20 + 15 * quantidadeDeLinhas}px`;
                    event.target.style.height = height;
                  }}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleGerarRetiradaConsignado}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default GerarConsignadoDetalhe;
