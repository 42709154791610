import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/imagens';

interface IProdutoImagemComunicadorStore {
  params: any;
}

interface IProdutoImagemComunicadorIndex {
  params: { idProduto: string };
}

class ProdutoImagemComunicador {
  public async store({ params }: IProdutoImagemComunicadorStore): Promise<any> {
    const response = await api.post(rota, params);
    return response.data;
  }

  public async index({ params }: IProdutoImagemComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });
    return response.data;
  }
}

export default new ProdutoImagemComunicador();
