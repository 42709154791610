import {
  FormatarDataHoraParaPtBr,
  IOrdemProducaoLista,
  SituacaoOrdemProducaoEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import OrdemProducaoComunicador from '../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/OrdemProducaoComunicador';
import OrdemProducaoLogComunicador from '../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Log/OrdemProducaoLogComunicador';

const OrdemProducaoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await OrdemProducaoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: IOrdemProducaoLista) => {
          return {
            id: data.id,
            numero: <div className="lista-valor">{data.numero}</div>,
            dataLancamento: (
              <div className="lista-data">
                {FormatarDataHoraParaPtBr(data.dataLancamento) || ''}
              </div>
            ),
            'produto.codigo': (
              <div className="lista-texto">{data.produto.codigo || ''}</div>
            ),
            'produto.descricao': (
              <div className="lista-texto">{data.produto.descricao || ''}</div>
            ),
            quantidade: (
              <div className="lista-valor">
                {Number(data.quantidade).FormatarParaPtBr({
                  maximoCasasDecimais: 4,
                  minimoCasasDecimais: 4,
                })}
              </div>
            ),
            dataHoraInicio: (
              <div className="lista-data">
                {FormatarDataHoraParaPtBr(data.dataHoraInicio) || ''}
              </div>
            ),
            dataHoraTermino: (
              <div className="lista-data">
                {FormatarDataHoraParaPtBr(data.dataHoraTermino) || ''}
              </div>
            ),

            situacao: (
              <div
                className="lista-texto"
                style={{
                  fontWeight: 'bold',
                  color:
                    data.situacao === SituacaoOrdemProducaoEnum.concluida
                      ? 'green'
                      : 'black',
                }}
              >
                {data.situacao}
              </div>
            ),

            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await OrdemProducaoComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = OrdemProducaoLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    permissoes.ManufaturaProducaoOrdemProducao?.exclui
                  }
                />
              </div>
            ),
          };
        });
        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ManufaturaProducaoOrdemProducao]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'manufaturaOrdemProducao' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Ordem de Produção"
          permissao={permissoes.ManufaturaProducaoOrdemProducao}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return OrdemProducaoLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default OrdemProducaoLista;
