const PesagemPorLocalDeEstoqueRelatorioPlanilha = [
  { titulo: 'Local De Estoque', valor: 'descricao' },
  { titulo: 'Número', valor: 'listaPesagem.numero' },
  {
    titulo: 'Data Emissão',
    valor: 'listaPesagem.dataHoraEmissao',
  },
  {
    titulo: 'Código',
    valor: 'listaPesagem.produto.codigo',
  },
  {
    titulo: 'Produto',
    valor: 'listaPesagem.produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor: 'listaPesagem.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Peso Líquido',
    valor: 'listaPesagem.pesoLiquido',
  },
];

export default PesagemPorLocalDeEstoqueRelatorioPlanilha;
