import styled, { css } from 'styled-components';

interface IContainer {
  $telaPequena: boolean;
  $telaGrande: boolean;
}

export const Container = styled.div<IContainer>`
  display: grid;

  ${({ $telaGrande }) =>
    $telaGrande &&
    css`
      height: 100%;
      width: auto;
      grid-template-columns: 250px auto;
      grid-template-rows: 70px auto;
      grid-template-areas:
        'p b'
        'a b';
    `}

  ${({ $telaPequena }) =>
    $telaPequena &&
    css`
      height: auto;
      width: auto;
      grid-auto-columns: auto;
      grid-auto-rows: max-content;
      grid-template-areas: 'p' 'a' 'b';
      margin-bottom: 10px;
    `}

  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
`;

export const Aside = styled.aside`
  grid-area: a;
  min-width: 200px;
  height: 100%;
  border-right: 1px solid #dee2e6;
  border-left: 0;
  border-bottom: 0;
  border-bottom-left-radius: 5px;
  padding: 5px;
  overflow: auto;
`;

export const Body = styled.div`
  grid-area: b;
  padding: 15px;
  overflow: auto;
  :empty {
    padding: 0;
  }
`;

export const Pesquisar = styled.div`
  grid-area: p;
  padding: 5px;
  border-right: 1px solid #dee2e6;
  border-top-left-radius: 5px;

  span {
    font-weight: bold;
  }
  input {
    height: 30px;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin: 0 0 0 5px;
  }
`;
