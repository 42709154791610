import { AppErro, INotaFiscalEntradaItemValoresAlterar } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import NotaFiscalEntradaItemCustosCompraComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/NotaFiscalEntradaItemCustosCompraComunicador';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = formPrincipal.getIdDetalheRegistro();
      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof INotaFiscalEntradaItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const response =
          await NotaFiscalEntradaItemCustosCompraComunicador.show({
            id: idEditar ?? '',
          });

        let somaValoresOutrasDespesas = 0;
        let somaValoresOutrosDescontos = 0;
        let somaValoresOutrasDespesasContabeis = 0;
        let somaValoresConhecimentoFrete = 0;

        await formRefDetalhe.current?.setDataInicial(response);
        await Sleep(50);
        if (response.listaNotaFiscalEntradaItem) {
          for (
            let index = 0;
            index < response.listaNotaFiscalEntradaItem.length;
            index++
          ) {
            const item = response.listaNotaFiscalEntradaItem[index];

            if (
              item.listaNotaFiscalEntradaItemCusto &&
              item.listaNotaFiscalEntradaItemCusto.length > 0
            ) {
              somaValoresOutrasDespesas += Number(
                item.listaNotaFiscalEntradaItemCusto[0].valorOutrasDespesas
              );
              somaValoresOutrosDescontos += Number(
                item.listaNotaFiscalEntradaItemCusto[0].valorOutrosDescontos
              );
              somaValoresOutrasDespesasContabeis += Number(
                item.listaNotaFiscalEntradaItemCusto[0]
                  .valorOutrasDespesasContabeis
              );
              somaValoresConhecimentoFrete += Number(
                item.listaNotaFiscalEntradaItemCusto[0].valorConhecimentoFrete
              );

              for (
                let custoIndex = 0;
                custoIndex < item.listaNotaFiscalEntradaItemCusto.length;
                custoIndex++
              ) {
                const custo = item.listaNotaFiscalEntradaItemCusto[custoIndex];

                formRefLista.current?.setFieldValue(
                  `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesas`,
                  custo.valorOutrasDespesas
                );
                formRefLista.current?.setFieldValue(
                  `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrosDescontos`,
                  custo.valorOutrosDescontos
                );
                formRefLista.current?.setFieldValue(
                  `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesasContabeis`,
                  custo.valorOutrasDespesasContabeis
                );
                formRefLista.current?.setFieldValue(
                  `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorConhecimentoFrete`,
                  custo.valorConhecimentoFrete
                );
              }
            }
          }
        }

        formRefDetalhe.current?.setFieldValue(
          'valorOutrasDespesas',
          somaValoresOutrasDespesas
        );
        formRefDetalhe.current?.setFieldValue(
          'valorOutrosDescontos',
          somaValoresOutrosDescontos
        );
        formRefDetalhe.current?.setFieldValue(
          'valorOutrasDespesasContabeis',
          somaValoresOutrasDespesasContabeis
        );
        formRefDetalhe.current?.setFieldValue(
          'valorConhecimentoFrete',
          somaValoresConhecimentoFrete
        );

        await formRefDetalhe.current?.atualizarDataInicial();
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);
          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [formPrincipal, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({});

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id: '', erro: true };
        }

        await NotaFiscalEntradaItemCustosCompraComunicador.update({
          id,
          params: data.listaNotaFiscalEntradaItem,
        });

        ToastSucesso('Registro Alterado com Sucesso!');
        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, handleValidar]
  );

  const handleExcluir = async (): Promise<void> => {
    throw new AppErro({
      mensagem: 'A função "handleExcluir" não foi implementada no "Cancelar"',
    });
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
