"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloImpressaoOrdemServicoEnum;
(function (ModeloImpressaoOrdemServicoEnum) {
    ModeloImpressaoOrdemServicoEnum["retratoComRepresentante"] = "Retrato Com Representante";
    ModeloImpressaoOrdemServicoEnum["retratoSemFotoProduto"] = "Retrato (sem foto produto)";
    ModeloImpressaoOrdemServicoEnum["retratoComFotoProduto"] = "Retrato (com foto produto)";
    ModeloImpressaoOrdemServicoEnum["paisagemSemFotoProduto"] = "Paisagem (sem foto produto)";
    ModeloImpressaoOrdemServicoEnum["paisagemComFotoProduto"] = "Paisagem (com foto produto)";
    ModeloImpressaoOrdemServicoEnum["retratoComSemValoresItens"] = "Retrato Com e Sem Valores nos Itens";
    ModeloImpressaoOrdemServicoEnum["retratoComFotoProdutoComSemValoresItens"] = "Retrato Com e Sem Valores e Foto do Produto";
    ModeloImpressaoOrdemServicoEnum["retratoComProdutosServicosPagamentos"] = "Retrato Com Produtos e Servi\u00E7os e Pagamentos";
})(ModeloImpressaoOrdemServicoEnum || (ModeloImpressaoOrdemServicoEnum = {}));
exports.default = ModeloImpressaoOrdemServicoEnum;
