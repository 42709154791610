import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/engenharia-codigo-referencia';
const rotaNovo = 'suprimentos/produtos/produto';

interface IProdutoEngenhariaCodigoReferenciaComunicadorShow {
  id: string;
}

interface IProdutoEngenhariaCodigoReferenciaComunicadorStore {
  idPai: string;
  params: any;
}

interface IProdutoEngenhariaCodigoReferenciaComunicadorUpdate {
  id: string;
  params: any;
}

interface IProdutoEngenhariaCodigoReferenciaComunicadorDelete {
  id: string;
}

class ProdutoEngenhariaCodigoReferenciaComunicador {
  public async show(
    params: IProdutoEngenhariaCodigoReferenciaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IProdutoEngenhariaCodigoReferenciaComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/engenharia-codigo-referencia`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IProdutoEngenhariaCodigoReferenciaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IProdutoEngenhariaCodigoReferenciaComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ProdutoEngenhariaCodigoReferenciaComunicador();
