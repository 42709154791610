import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa-auto-complete-tag';

interface ITerminalCaixaAutoCompleteTagComunicadorIndex {
  params: any;
}

class TerminalCaixaAutoCompleteTagComunicador {
  public async index(
    params: ITerminalCaixaAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TerminalCaixaAutoCompleteTagComunicador();
