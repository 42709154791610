import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/cte/salvar-cte-selecionados-terceiro';

interface IImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicadorUpdate {
  params?: any;
}

class ImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicador {
  public async update(
    params: IImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}`, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicador();
