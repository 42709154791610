import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { SituacaoOrdemProducaoEnum } from '@elogestor/util';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import GerarOrdemProducaoItem from './GerarOrdemProducaoItem';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import GerarOrdemProducaoAutomaticaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/GerarOrdemProducaoAutomaticaComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseParametros } from '../../../../../../../Hooks/ParametrosHook';

interface IProps {
  show: boolean;
  onClose: () => void;
}

export interface IOrdemProducaoItem {
  id: string;
  idProduto: string;
  idPessoa: string;
  dataHoraEmissao: Date;
  selecionado: boolean;
  codigo: string;
  descricao: string;
  unidadeMedida: string;
  quantidadePedido: number;
  quantidade: number;
  quantidadeDisponivel: number;
}

const GerarOrdemProducao: React.FC<IProps> = ({ show, onClose }) => {
  const { formRef, loading, setLoading, handleCarregarDados } = UseForm();

  const parametros = UseParametros();

  const [marcarTodos, setMarcarTodos] = useState<boolean>(false);

  const [listaOrdemProducaoItem, setListaOrdemProducaoItem] = useState<
    IOrdemProducaoItem[]
  >([]);

  const mainFormData = formRef?.current?.getData();

  const obterDadosIniciais = useCallback(() => {
    const listaItem = formRef?.current?.getFieldValue('listaPedidoVendaItem');
    listaItem.forEach((item: any) => {
      const existeNaLista = listaOrdemProducaoItem.some(
        (ordemProducaoItem) => ordemProducaoItem.codigo === item.produto.codigo
      );

      if (!existeNaLista && item.quantidadeDisponivel > 0) {
        setListaOrdemProducaoItem((listaAnterior) => [
          ...listaAnterior,
          {
            id: item.id,
            idProduto: item.idProduto,
            codigo: item.produto.codigo,
            dataHoraEmissao: new Date(),
            descricao: item.produto.descricao,
            unidadeMedida: item.unidadeMedida.sigla,
            quantidadePedido: item.quantidade,
            quantidade: item.quantidade,
            situacao: parametros.GerarOrdemProducaoFinalizada
              ? SituacaoOrdemProducaoEnum.concluida
              : SituacaoOrdemProducaoEnum.emAndamento,
            calcularQuantidadeConsumoItens: true,
            selecionado: true,
            idPessoa: mainFormData
              ? mainFormData.pedidoVendaPessoa?.idPessoa
              : null,
            quantidadeDisponivel: item.quantidadeDisponivel,
          },
        ]);
      }
    });
  }, [
    formRef,
    listaOrdemProducaoItem,
    mainFormData,
    parametros.GerarOrdemProducaoFinalizada,
  ]);

  useEffect(() => {
    obterDadosIniciais();
  }, [obterDadosIniciais]);

  const handleOnChange = (item: IOrdemProducaoItem): void => {
    setListaOrdemProducaoItem((prevList) => {
      const index = prevList.findIndex((_item) => _item.id === item.id);
      if (index !== -1) {
        const updatedList = [...prevList];
        updatedList[index] = item;
        return updatedList;
      }
      return [...prevList, item];
    });
  };

  const handleValidarSalvar = async (): Promise<void> => {
    setLoading(true);
    const itensValidos: IOrdemProducaoItem[] = [];

    let erro = false;

    listaOrdemProducaoItem.forEach((item) => {
      const quantidadeAProduzir = item.quantidade;

      const quantidadePedido = Number(item.quantidadePedido);
      if (
        (quantidadeAProduzir > quantidadePedido ||
          quantidadeAProduzir > item.quantidadeDisponivel ||
          quantidadeAProduzir === 0) &&
        item.selecionado
      ) {
        ToastErro(
          `Erro no item ${item.codigo}\n
          ${item.descricao}\n
          Quantidade a produzir não pode ser maior que a quantidade disponível.`
        );
        erro = true;
      } else if (item.selecionado === true) {
        itensValidos.push({
          ...item,
          quantidade: item.quantidade,
        });
      }
    });

    if (erro) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await GerarOrdemProducaoAutomaticaComunicador.store({
        params: itensValidos,
        gerarFinalizado: parametros.GerarOrdemProducaoFinalizada,
      });

      if (response.listaOrdensProducaoGeradas) {
        response.listaOrdensProducaoGeradas.forEach((item: any) => {
          ToastSucesso(`Ordem de produção número ${item} gerada com sucesso.`);
        });
      }
      handleCarregarDados();
      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const listaMensagem = error?.response.data.listaMensagem;

      if (listaMensagem) {
        listaMensagem.forEach((item: string) => {
          ToastErro(item);
        });
      }

      setLoading(false);
    }
  };

  return (
    <Modal show={show} size="xl">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Gerar Ordem de Produção</Modal.Title>
        <BtnCloseButton type="button" onClick={onClose}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>
      <Modal.Body>
        <Tabela style={{ overflow: 'auto', maxHeight: 400 }}>
          <Table striped bordered variant="light">
            <thead>
              <tr>
                <th
                  className="lista-texto"
                  style={{
                    width: '5%',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      setMarcarTodos(event.target.checked);
                    }}
                  />
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Código</span>
                </th>
                <th className="lista-texto" style={{ width: '22%' }}>
                  <span>Descrição Produto/Serviço</span>
                </th>
                <th className="lista-texto" style={{ width: '8%' }}>
                  <span>UN</span>
                </th>
                <th className="lista-valor" style={{ width: '15%' }}>
                  <span>Qtde. Pedido</span>
                </th>
                <th className="lista-valor" style={{ width: '15%' }}>
                  <span>Qtde. Disponível</span>
                </th>
                <th className="lista-valor" style={{ width: '13%' }}>
                  <span>Qtde. a Produzir</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {listaOrdemProducaoItem.map((item: any, index: any) => {
                return (
                  <GerarOrdemProducaoItem
                    key={index}
                    item={item}
                    onChange={handleOnChange}
                    index={index}
                    marcarTodos={marcarTodos}
                  />
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </Modal.Body>
      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onClose}
              disabled={loading}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                <TextoLoading loading={loading}>
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </TextoLoading>
              </span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleValidarSalvar}
              disabled={
                loading ||
                !listaOrdemProducaoItem.some(
                  (item) => item.selecionado === true
                )
              }
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GerarOrdemProducao;
