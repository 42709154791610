"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var BuscaPessoasCadastradasEnum;
(function (BuscaPessoasCadastradasEnum) {
    BuscaPessoasCadastradasEnum["codigo"] = "C\u00F3digo";
    BuscaPessoasCadastradasEnum["cnpjCpf"] = "CNPJ / CPF ";
    BuscaPessoasCadastradasEnum["cnpjCpfInscricaoEstadual"] = "CNPJ / CPF e Inscri\u00E7\u00E3o Estadual";
})(BuscaPessoasCadastradasEnum || (BuscaPessoasCadastradasEnum = {}));
exports.default = BuscaPessoasCadastradasEnum;
