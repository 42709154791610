import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-por-nome-fantasia';

interface IPessoaObterPorNomeFantasiaComunicadorIndex {
  params: {
    nomeFantasia: string;
    listaIdRemover: string[];
  };
}
class PessoaObterPorNomeFantasiaComunicador {
  public async index(
    params: IPessoaObterPorNomeFantasiaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaObterPorNomeFantasiaComunicador();
