/* eslint-disable default-case */
import React, {
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { IContaValoresAlterar } from '@elogestor/util';
import UseStep from '../../../../../../Hooks/UseStep';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { CteInfoBox, Step, StepBox, StepContainer, StepNome } from './styles';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import BtnCloseButton from '../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import ImportacaoXmlTerceiroImportarComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroImportarComunicador';
import ImportacaoCteTerceiroEtapa1, {
  IImportacaoCteTerceiroEtapa1Ref,
  IImportacaoCteTerceiroEtapa1ServicoItem,
} from './Etapa1';
import ImportacaoCteTerceiroEtapa2, {
  IImportacaoCteTerceiroEtapa2Ref,
} from './Etapa2';
import ImportacaoCteTerceiroEtapa3, {
  IImportacaoCteTerceiroEtapa3Ref,
} from './Etapa3';
import ConhecimentoTransporteTerceiroObterDadosPadraoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroObterDadosPadraoComunicador';
import ImportacaoXmlTerceiroCteSalvarImportadoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoCteTerceiro/Comunicador/ImportacaoXmlTerceiroCteSalvarImportadoComunicador';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TratarInfo from '../../../../../../Util/Info/TratarInfo';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

export interface IAbrirImportacaoCteTerceiro {
  idImportacaoXmlAutorizacao: string;
}

interface IImportacaoCteTerceiroContext {
  abrirImportacaoCteTerceiro({
    idImportacaoXmlAutorizacao,
  }: IAbrirImportacaoCteTerceiro): Promise<any>;
  fecharImportacaoCteTerceiro(): void;
}

const ImportacaoCteTerceiroContext =
  createContext<IImportacaoCteTerceiroContext>(
    {} as IImportacaoCteTerceiroContext
  );

const ImportacaoCteTerceiroHook: React.FC<IPadraoProps> = ({ children }) => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);

  const resolveRef = useRef<any>();

  const [idImportacaoXml, setIdImportacaoXml] = useState('');
  const [aberto, setAberto] = useState(false);

  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Carregar Dados

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref = useRef<IImportacaoCteTerceiroEtapa1Ref>(null);

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref = useRef<IImportacaoCteTerceiroEtapa2Ref>(null);

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref = useRef<IImportacaoCteTerceiroEtapa3Ref>(null);

  const handleCarregarDados = useCallback(
    async (idImportacaoXmlAutorizacao: string) => {
      try {
        setLoading(true);

        const response = await ImportacaoXmlTerceiroImportarComunicador.update({
          id: idImportacaoXmlAutorizacao,
        });

        setNumero(response.numero);
        setSerie(response.serie);

        // #region Etapa 1

        await formRefEtapa1.current?.setDataInicial({
          ...response,

          conhecimentoTransporteTerceiroImpostoIcmsFiscal: {
            cfop: response?.cfop,
          },
        });

        const listaServicoFiltrados = response.listaServico.filter(
          (item: any) => item.valor > 0
        );

        etapa1Ref.current?.handleSetarListaServico(listaServicoFiltrados);

        const total = listaServicoFiltrados.reduce(
          (
            acumulador: number,
            item: IImportacaoCteTerceiroEtapa1ServicoItem
          ) => {
            acumulador += Number(item.valor);
            return acumulador;
          },
          0
        );

        const valorFinal =
          Number(response?.valorTotalServico) - Number(total).Arredondar(2);

        formRefEtapa1.current?.setFieldValue('valorAdicionar', valorFinal);

        // #endregion Etapa 1

        // #region Etapa 2

        await formRefEtapa2.current?.setDataInicial({
          ...response,
        });

        etapa2Ref.current?.handleSetarListaNfe(response.listaNfe);

        // #endregion Etapa 2

        // #region Etapa 3

        let dadosPadrao: IContaValoresAlterar | null = null;
        if (response.financeiro) {
          dadosPadrao = { ...response.financeiro };
        }

        await etapa3Ref.current?.handleSetarDadosPadrao(dadosPadrao);

        // #endregion Etapa 3

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Carregar Dados

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!(await etapa1Ref.current?.handleValidar())) return;

        proximoStep();
        break;

      case 2:
        if (!(await etapa2Ref.current?.handleValidar())) return;

        proximoStep();
        break;
    }
  }, [proximoStep, step]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleAbrir = useCallback(
    async ({
      idImportacaoXmlAutorizacao,
    }: IAbrirImportacaoCteTerceiro): Promise<any> => {
      setAberto(true);
      setIdImportacaoXml(idImportacaoXmlAutorizacao);

      setStep(1);
      handleCarregarDados(idImportacaoXmlAutorizacao);

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDados, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const dadosEtapa3 = await etapa3Ref.current?.handleObterValidarDados();
      if (!dadosEtapa3?.valido) {
        setLoading(false);
        return;
      }

      const valoresEtapa1 = formRefEtapa1.current?.getData() as any;

      const listaServico = etapa1Ref.current?.handleObterListaServico();
      const listaNfe = etapa2Ref.current?.handleObterListaNfe();

      const { id: idConhecimentoTransporteTerceiro, listaMensagem } =
        await ImportacaoXmlTerceiroCteSalvarImportadoComunicador.store({
          params: {
            ...valoresEtapa1,
            numero,
            serie,
            listaServico: listaServico || [],
            listaNfe: listaNfe ?? [],
            financeiro: dadosEtapa3.dados,
          },
          idXmlCteImportado: idImportacaoXml,
        });

      ToastSucesso('CT-e Importado!');
      TratarInfo(listaMensagem);

      if (resolveRef.current) {
        resolveRef.current(idConhecimentoTransporteTerceiro);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idImportacaoXml, numero, serie]);

  return (
    <ImportacaoCteTerceiroContext.Provider
      value={{
        abrirImportacaoCteTerceiro: handleAbrir,
        fecharImportacaoCteTerceiro: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>
            Importar Conhecimento de Transporte de Terceiros
          </Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <CteInfoBox>
                <p>CT-e: {numero}</p>
                <p>Série: {serie}</p>
              </CteInfoBox>

              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Serviços</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Notas</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Financeiro</StepNome>
              </Step>
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <ImportacaoCteTerceiroEtapa1
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <ImportacaoCteTerceiroEtapa2
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <ImportacaoCteTerceiroEtapa3
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </ImportacaoCteTerceiroContext.Provider>
  );
};

function UseImportacaoCteTerceiro(): IImportacaoCteTerceiroContext {
  const context = useContext(ImportacaoCteTerceiroContext);

  if (!context) {
    throw new Error(
      'UseImportacaoCteTerceiro deve ser usado com um ImportacaoCteTerceiroProvider'
    );
  }

  return context;
}

export { ImportacaoCteTerceiroHook, UseImportacaoCteTerceiro };
