import api from '../../../../Comum/Services/Api';

const rota = 'usuario-subdominio-tentativa-login';

interface IUsuarioSubdominioTentativaLoginComunicadorShow {
  params: any;
}

class UsuarioSubdominioTentativaLoginComunicador {
  public async show(
    params: IUsuarioSubdominioTentativaLoginComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new UsuarioSubdominioTentativaLoginComunicador();
