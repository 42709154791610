import { INotaFiscalSaidaEtiquetaImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import NotaFiscalSaidaEtiquetaImpressaoPDF from './NotaFiscalSaidaEtiquetaImpressaoPDF';

interface IImpressaoNotaFiscalSaidaEtiqueta {
  handleVisualizarPdf(data: INotaFiscalSaidaEtiquetaImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: INotaFiscalSaidaEtiquetaImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoNotaFiscalSaidaEtiqueta =
  (): IImpressaoNotaFiscalSaidaEtiqueta => {
    async function handleVisualizarPdf(
      dados: INotaFiscalSaidaEtiquetaImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: <NotaFiscalSaidaEtiquetaImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: INotaFiscalSaidaEtiquetaImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <NotaFiscalSaidaEtiquetaImpressaoPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoNotaFiscalSaidaEtiqueta();
