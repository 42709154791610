import { ISerieValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { BtnContainer } from '../../../../../Suprimentos/Produtos/UnidadeMedida/Detalhe/Main/styles';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteModeloDocumento from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';

type IProps = IMain<ISerieValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FiscalTributacaoSerie: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const isIncrementarAutomaticamente =
    formRef.current?.getFieldValue('incrementarAutomaticamente') === true;

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleIncrementarAutomaticamente = useCallback(async () => {
    refresh();
    formRef.current?.setFieldValue('proximoNumero', '');
  }, [formRef, refresh]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Código Série"
                name="codigo"
                maxLength={3}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Input label="Sub-Série" name="subSerie" maxLength={3} />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteModeloDocumento
                label="Modelo Documento"
                name="idModeloDocumento"
                placeholder="Modelo Documento"
                nomeObjeto="modeloDocumento"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={7} md={6} sm={12}>
              <Input label="Descrição" name="descricao" />
            </Col>
            <Col lg={3} md={6} sm={12} className="bottom">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Incrementar Automaticamente"
                  name="incrementarAutomaticamente"
                  onChange={handleIncrementarAutomaticamente}
                />
              </div>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                disabled={!isIncrementarAutomaticamente}
                label="Próximo Número"
                name="proximoNumero"
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Col lg={12} md={12} className="div-checkbox">
            <h6>Utilização</h6>
            <div className="checkbox-container">
              <InputCheckbox
                label="Nota Fiscal de Entrada"
                name="utilizavelNotaFiscalEntrada"
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                label="Nota Fiscal de Saída"
                name="utilizavelNotaFiscalSaida"
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                label="Nota Fiscal de Ajuste"
                name="utilizavelNotaFiscalAjuste"
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                label="Nota Fiscal Complementar"
                name="utilizavelNotaFiscalComplementar"
                onChange={() => {
                  refresh();
                }}
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                label="Frente de Caixa"
                name="utilizavelFrenteCaixa"
                onChange={() => {
                  refresh();
                }}
              />
            </div>
          </Col>
        </Divisor>

        <Divisor>
          <Row>
            <Col md={12}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
