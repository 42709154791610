import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import IPop from '../Interface/IPop';

const Alerta: React.FC<IPop> = ({
  cb,
  mensagem,
  titulo,
  confimar = 'OK',
  fecharJanela,
}) => {
  return (
    <Modal show onHide={fecharJanela}>
      <Modal.Header>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{mensagem}</Modal.Body>

      <Modal.Footer>
        <Button
          style={{ width: 95 }}
          variant="primary"
          onClick={async () => {
            fecharJanela();
            await cb(true);
          }}
        >
          {confimar}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Alerta;
