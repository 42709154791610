import React from 'react';
import { ListaProvider as ListaProviderHook } from './ListaHooks';
import { TableDraggableProvider } from '../TableDraggable/HooksTable/TableDraggableContext';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';

const ListaProvider: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaProviderHook>
      <TableDraggableProvider>{children}</TableDraggableProvider>
    </ListaProviderHook>
  );
};

export default ListaProvider;
