import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ImpressaoEtiquetasNotaFiscalListaHook } from './ImpressaoEtiquetasNotaFiscalListaHook';

const ImpressaoEtiquetasNotaFiscalHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ImpressaoEtiquetasNotaFiscalListaHook>
      {children}
    </ImpressaoEtiquetasNotaFiscalListaHook>
  );
};

export default ImpressaoEtiquetasNotaFiscalHook;
