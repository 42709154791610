import React, { useImperativeHandle, forwardRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FinalidadeEmissaoEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import InputAutoCompleteSerie from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import Divisor from '../../../../../Componentes/Divisor';
import InputHiddenHtml from '../../../../../Componentes/Inputs/InputHiddenHtml';

export interface INotaFiscalSaidaImportacaoOrdemServicoEtapa4Ref {
  handleValidar(): boolean;
}

interface INotaFiscalSaidaImportacaoOrdemServicoEtapa4Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const NotaFiscalSaidaImportacaoOrdemServicoEtapa4: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoOrdemServicoEtapa4Ref,
  INotaFiscalSaidaImportacaoOrdemServicoEtapa4Props
> = ({ formRef }, ref) => {
  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];

    const serie = formRef.current?.getFieldValueNomeObjeto('etapa4.serie');

    if (!serie || !serie.id) {
      listaMensagemErro.push('Série é obrigatória!');
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, [formRef]);
  const finalidadeEmissao = formRef.current?.getFieldValue(
    'etapa4.finalidadeEmissao'
  );

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
    };
  });

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <InputHiddenHtml name="etapa4.finalidadeEmissao" />
      <Divisor style={{ paddingBottom: 30 }}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteSerie
              label="Série"
              name="etapa4.idSerie"
              placeholder="Série"
              nomeObjeto="etapa4.serie"
              listaTipoDocumentoFiscal={[
                ModeloDocumentoTipoEnum.notaFiscal,
                ModeloDocumentoTipoEnum.notaFiscalServico,
              ]}
              utilizavelNotaFiscalSaida
              utilizavelNotaFiscalAjuste={
                finalidadeEmissao === FinalidadeEmissaoEnum.ajuste
                  ? true
                  : undefined
              }
              utilizavelNotaFiscalComplementar={
                finalidadeEmissao === FinalidadeEmissaoEnum.complemento
                  ? true
                  : undefined
              }
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoOrdemServicoEtapa4);
