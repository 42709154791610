import React from 'react';
import { RotasProvider } from '../../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend: '/suprimentos/produtos/estoque/movimento-manual-estoque',
        detalhes:
          '/suprimentos/produtos/estoque/movimento-manual-estoque/detalhe',
        listagem: '/suprimentos/produtos/estoque/movimento-manual-estoque',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
