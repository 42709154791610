import React from 'react';
import { useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import EmpresaHook from './Hooks';
import AcoesExcluir from './AcoesExcluir';

const EmpresaCrmSimplesDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <EmpresaHook>
        <SubHeader
          titulo="CRM Simples"
          permissao={permissoes.ConfiguracaoIntegracaoEmpresaCrmSimples}
          botoes={
            <AcoesExcluir
              permissao={permissoes.ConfiguracaoIntegracaoEmpresaCrmSimples}
            />
          }
        />
        <Detalhe>
          <Main
            idEditar={null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </EmpresaHook>
    </Container>
  );
};

export default EmpresaCrmSimplesDetalhe;
