import {
  IOrdemDesmontagemLocalEstoqueLista,
  IOrdemDesmontagemValoresAlterar,
  SituacaoOrdemDesmontagemEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import OrdemDesmontagemComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemDesmontagem/Comunicador/OrdemDesmontagemComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IOrdemDesmontagemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        dadosDuplicados.numeroDocumento = undefined;

        if (dadosDuplicados.listaCustos) {
          dadosDuplicados.listaCustos.map(async (item: any) => {
            item.id = undefined;
          });
        }

        if (dadosDuplicados.listaOrdemDesmontagemLocalEstoque) {
          dadosDuplicados.listaOrdemDesmontagemLocalEstoque.map(
            async (item: any) => {
              item.id = undefined;
            }
          );
        }

        if (dadosDuplicados.listaOrdemDesmontagemItem) {
          dadosDuplicados.listaOrdemDesmontagemItem.map(async (item: any) => {
            item.id = undefined;

            if (item.listaCusto) {
              item.listaCusto.map(async (valor: any) => {
                valor.id = undefined;
              });
            }

            if (item.listaOrdemDesmontagemItemLocalEstoque) {
              item.listaOrdemDesmontagemItemLocalEstoque.map(
                async (valor: any) => {
                  valor.id = undefined;
                }
              );
            }
          });
        }

        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const { response, listaCustos } =
          await OrdemDesmontagemComunicador.show({
            id: idEditar,
          });
        await formRef.current?.setDataInicialSemExecutarEvento({
          ...response,
          listaCustos,
          listaEstoqueMudou: false,
          listaItemMudou: false,
        });
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicialSemExecutarEvento({
          dataLancamento: new Date(),
          situacao: SituacaoOrdemDesmontagemEnum.emAndamento,
          calcularQuantidadeRetornoItens: true,
          dataHoraInicio: new Date().SetarSegundosMilisegundosZero(),
          quantidade: 1,
          listaEstoqueMudou: false,
          listaItemMudou: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        dataLancamento: Yup.date().required(
          'Data de Lançamento é Obrigatória!'
        ),
        idProduto: Yup.string().required('Produto é Obrigatório!'),
        quantidade: Yup.number()
          .required('Quantidade é Obrigatória!')
          .positive('Quantidade é Obrigatória!'),

        calcularQuantidadeRetornoItens: Yup.boolean().required(
          'Calcular a Quantidade de Retorno é Obrigatório'
        ),
        dataHoraInicio: Yup.date().required(
          'Data de Lançamento é Obrigatória!'
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleValidarHorasDatas = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];

      const {
        dataHoraInicio,
        dataHoraTermino,
        listaOrdemDesmontagemLocalEstoque,
        listaOrdemDesmontagemItem,
      } = data;

      for (let i = 0; i < listaOrdemDesmontagemLocalEstoque.length; i++) {
        const ordemDesmontagemLocalEstoque =
          listaOrdemDesmontagemLocalEstoque[i];

        if (
          dataHoraInicio >
          new Date(ordemDesmontagemLocalEstoque.dataHoraConsumo)
        ) {
          listaErro.push(
            `Data e Hora de Início maior que a Data de Consumo do Local de Estoque de Ordem ${
              i + 1
            }!`
          );
          formRef.current?.setFieldError(
            'dataHoraInicio',
            'Data e Hora de Início maior que a Data de Consumo!'
          );
        }

        if (
          dataHoraTermino &&
          dataHoraTermino <
            new Date(ordemDesmontagemLocalEstoque.dataHoraConsumo)
        ) {
          listaErro.push(
            `Data e Hora de Término menor que a Data de Consumo do Local de Estoque de Ordem ${
              i + 1
            }!`
          );
          formRef.current?.setFieldError(
            'dataHoraTermino',
            'Data e Hora de Término menor que a Data de Consumo!'
          );
        }
      }

      for (let i = 0; i < listaOrdemDesmontagemItem.length; i++) {
        const ordemDesmontagemItem = listaOrdemDesmontagemItem[i];

        for (
          let j = 0;
          j < ordemDesmontagemItem.listaOrdemDesmontagemItemLocalEstoque.length;
          j++
        ) {
          const ordemDesmontagemItemLocalEstoque =
            ordemDesmontagemItem.listaOrdemDesmontagemItemLocalEstoque[j];

          if (
            dataHoraInicio >
            new Date(ordemDesmontagemItemLocalEstoque.dataHoraReporte)
          ) {
            listaErro.push(
              `Data e Hora de Início maior que a Data de Reporte do Item de Ordem ${ordemDesmontagemItem.ordem}!`
            );
            formRef.current?.setFieldError(
              'dataHoraInicio',
              'Data e Hora de Início maior que a Data de Reporte!'
            );
          }

          if (
            dataHoraTermino &&
            dataHoraTermino <
              new Date(ordemDesmontagemItemLocalEstoque.dataHoraReporte)
          ) {
            listaErro.push(
              `Data e Hora de Término menor que a Data de Reporte do Item de Ordem ${ordemDesmontagemItem.ordem}!`
            );
            formRef.current?.setFieldError(
              'dataHoraTermino',
              'Data e Hora de Término menor que a Data de Reporte!'
            );
          }
        }
      }

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleValidarTotais = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];
      const { listaOrdemDesmontagemLocalEstoque, quantidade } = data;

      const somaQuantidadeLocalEstoque =
        listaOrdemDesmontagemLocalEstoque.reduce(
          (acumulador: number, item: IOrdemDesmontagemLocalEstoqueLista) => {
            acumulador += Number(item.quantidade) || 0;
            return acumulador;
          },
          0
        );
      if (
        somaQuantidadeLocalEstoque !== quantidade &&
        listaOrdemDesmontagemLocalEstoque.length > 0
      ) {
        listaErro.push(
          'A Quantidade dos Locais de Estoque é Diferente da Quantidade'
        );
      }

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';
        if (!(await handleValidarTotais(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!(await handleValidarHorasDatas(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await OrdemDesmontagemComunicador.update({ id, params: data });
        } else {
          const response = await OrdemDesmontagemComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);

        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleValidar,
      handleValidarHorasDatas,
      handleValidarTotais,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
