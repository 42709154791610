/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { format } from 'date-fns';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputDateProps {
  valorAnterior: string;
}

export interface IOnBlurCiaInputDateProps {
  valorAnterior: string;
  mudou: boolean;
}

export interface IInputDateRef {
  focus(): void;
  value(): Date | null;
  setErro(error: string): void;
  getValueFormatado(): string | null;
}

interface IInputDateProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  horasDefault?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateProps
  ) => void | Promise<void>;
  onBlurCia?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnBlurCiaInputDateProps
  ) => void | Promise<void>;
}

const InputDate: React.ForwardRefRenderFunction<
  IInputDateRef,
  IInputDateProps
> = (
  {
    name,
    label,
    horasDefault = '00:00:00',

    onFocus,
    onChange,
    onBlur,
    onBlurCia,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },

    value() {
      return inputRef.current?.value ? new Date(inputRef.current.value) : null;
    },

    setErro(error: string) {
      setErro(error);
    },

    getValueFormatado() {
      if (!inputRef.current?.value) return null;

      const data = new Date(
        `${inputRef.current.value} ${horasDefault}`.replace(/-/g, '/')
      );

      const dataFormatada = format(data, 'dd/MM/yyyy');
      return dataFormatada;
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');
      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(event);
      if (onBlurCia) {
        const mudou = event.target.value !== valorAnterior.current;
        onBlurCia(event, { valorAnterior: valorAnterior.current, mudou });
      }

      setIsFilled(!!inputRef.current?.value);
      setIsFocused(false);
    },
    [onBlur, onBlurCia]
  );

  useEffect(() => {
    registerField<Date | null | string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        if (!inputRef.current?.value) {
          return null;
        }

        const data = `${inputRef.current.value} ${horasDefault}`.replace(
          /-/g,
          '/'
        );
        const timestamp = Date.parse(data);
        if (isNaN(timestamp) === false) {
          return new Date(data);
        }

        return null;
      },

      setValue(_, valor = '') {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (!valor) {
          inputRef.current.value = '';
          return;
        }

        const data = new Date(valor);

        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        const parsedDate = `${ano}-${mes}-${dia}`;
        inputRef.current.value = parsedDate;
      },

      setSemExecutarEvento(_, valor = '') {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (!valor) {
          inputRef.current.value = '';
          return;
        }

        const data = new Date(valor);

        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        const parsedDate = `${ano}-${mes}-${dia}`;
        inputRef.current.value = parsedDate;
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_)?.toString() !== valorInicial?.toString();
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="date"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        max="9999-12-31"
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputDate);
