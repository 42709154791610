import React, { createContext, useContext } from 'react';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';
import {
  UseListaDetalheForm,
  IListaDetalheFormContext,
} from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

const FormSeguroContext = createContext<IListaDetalheFormContext>(
  {} as IListaDetalheFormContext
);

const FormSeguroProvider: React.FC<IPadraoProps> = ({ children }) => {
  const listaDetalheForm = UseListaDetalheForm();

  return (
    <FormSeguroContext.Provider value={listaDetalheForm}>
      {children}
    </FormSeguroContext.Provider>
  );
};

function UseFormSeguro(): IListaDetalheFormContext {
  const context = useContext(FormSeguroContext);

  if (!context) {
    throw new Error('UseFormSeguro must be used within an FormSeguroProvider');
  }

  return context;
}

export { FormSeguroProvider, UseFormSeguro };
