"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCustoEnum;
(function (TipoCustoEnum) {
    TipoCustoEnum["contabilIcmsIpi"] = "Contabil ICMS/IPI";
    TipoCustoEnum["contabilIr"] = "Contabil IR";
    TipoCustoEnum["valorCustoMedio"] = "Valor Custo M\u00E9dio";
    TipoCustoEnum["ultimaCompra"] = "\u00DAltima Compra";
    TipoCustoEnum["reposicao"] = "Reposi\u00E7\u00E3o";
})(TipoCustoEnum || (TipoCustoEnum = {}));
exports.default = TipoCustoEnum;
