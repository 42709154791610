import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import NotaFiscalSaidaHooks from './Hook';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import AcoesNotaFiscalSaida from './AcoesNotaFiscalSaida';

const NotaFiscalSaidaDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <NotaFiscalSaidaHooks>
        <SubHeader
          titulo={
            params.id ? 'Editar Nota Fiscal de Saída' : 'Nota Fiscal de Saída'
          }
          permissao={permissoes.ComercialVendasNotaFiscalSaida}
          botoes={
            <AcoesNotaFiscalSaida
              permissao={permissoes.ComercialVendasNotaFiscalSaida}
            />
          }
          isNotaFiscalSaida
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </NotaFiscalSaidaHooks>
    </Container>
  );
};

export default NotaFiscalSaidaDetalhe;
