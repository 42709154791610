import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/empresa-auto-complete-tag';

interface IEmpresaAutoCompleteTagComunicadorIndex {
  params: any;
}

class EmpresaAutoCompleteTagComunicador {
  public async index(
    params: IEmpresaAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new EmpresaAutoCompleteTagComunicador();
