/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { RiCheckboxMultipleLine } from 'react-icons/ri/index.mjs';
import { TipoMovimentoEstoqueSisdevinEnum } from '@elogestor/util';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UseSisdevinEstoqueMovimentoLista } from '../Hooks/SisdevinEstoqueMovimentoListaHook';
import SisdevinEstoqueMovimentoEditarDetalhe from './AtualizarSelecionados/Detalhe';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import SisdevinEstoqueMovimentoAtualizarConformeRegraComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/SisdevinEstoqueMovimento/Comunicador/SisdevinEstoqueMovimentoAtualizarConformeRegraComunicador';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import ToastInfo from '../../../../../Util/Toasts/ToastInfo';

interface IAcoesContasParcelaReversao {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setRefresh: (value: any) => void;
  possuiItemSelecionado: boolean;
}

const AcoesContasParcelaReversao: React.FC<IAcoesContasParcelaReversao> = ({
  loading,
  setLoading,
  setRefresh,
  possuiItemSelecionado,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { listaValor, listaItemSelecionadoRef, setPossuiItemSelecionado } =
    UseSisdevinEstoqueMovimentoLista();

  const [
    showAtualizarCodigoRastreabilidade,
    setShowAtualizarCodigoRastreabilidade,
  ] = useState(false);

  const handleLimparListaValor = useCallback(() => {
    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (item) item.checked = false;
    });
    setPossuiItemSelecionado(false);
  }, [listaItemSelecionadoRef, setPossuiItemSelecionado]);

  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];
    if (!possuiItemSelecionado) {
      listaMensagemErro.push(
        `Selecione pelo menos uma Parcela para Realizar a Reversão!`
      );
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      handleLimparListaValor();
      return false;
    }

    return true;
  }, [handleLimparListaValor, possuiItemSelecionado]);

  const handleAtualizarCodigoRastreabilidadeSalvarFormModal =
    useCallback(async () => {
      setShowAtualizarCodigoRastreabilidade(false);
      handleLimparListaValor();
      setRefresh(true);
    }, [handleLimparListaValor, setRefresh]);

  const handleAtualizarCodigoRastreabilidade = useCallback(async () => {
    try {
      setLoading(true);
      setRefresh(false);
      if (!handleValidar()) {
        setLoading(false);
        return;
      }
      setShowAtualizarCodigoRastreabilidade(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleValidar, setLoading, setRefresh]);

  const handleCodigoRastreabilidadeFecharFormModal = useCallback(async () => {
    setShowAtualizarCodigoRastreabilidade(false);
    handleLimparListaValor();
    setRefresh(true);
  }, [handleLimparListaValor, setRefresh]);

  const handleAtualizarConformeARegra = useCallback(async () => {
    try {
      setLoading(true);
      setRefresh(false);
      if (!handleValidar()) {
        setLoading(false);
        return;
      }

      const listaItensFiltrados = listaValor.filter(
        (valor) => valor.selecionado
      );

      const possuiItensEntrada = listaItensFiltrados.some(
        (item) =>
          item.tipoMovimento === TipoMovimentoEstoqueSisdevinEnum.nfEntrada
      );

      if (possuiItensEntrada) {
        ToastErro(
          'Selecione Apenas Itens que sejam de Saída para Atualizar Conforme a Regra!'
        );
        handleLimparListaValor();
        setLoading(false);
        return;
      }

      const response =
        await SisdevinEstoqueMovimentoAtualizarConformeRegraComunicador.update({
          id: listaItensFiltrados[0].id,
          params: listaItensFiltrados,
        });

      setRefresh(true);
      setLoading(false);
      handleLimparListaValor();
      if (response.foiInseridoNovosItens) {
        ToastSucesso('Códigos de Rastreabilidade Atualizados!');
      } else {
        ToastInfo(
          'Códigos de Rastreabilidade Não Atualizados por falta de Saldo!'
        );
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      handleLimparListaValor();
    }
  }, [
    handleLimparListaValor,
    handleValidar,
    listaValor,
    setLoading,
    setRefresh,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item>
          <span
            style={{ display: 'flex', fontSize: 16 }}
            onClick={handleAtualizarCodigoRastreabilidade}
          >
            <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
            Atualizar Selecionados
          </span>
        </Dropdown.Item>

        <Dropdown.Item>
          <span
            style={{ display: 'flex', fontSize: 16 }}
            onClick={handleAtualizarConformeARegra}
          >
            <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
            Atualizar Conforme a Regra
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showAtualizarCodigoRastreabilidade && (
        <SisdevinEstoqueMovimentoEditarDetalhe
          onSalvarFormModal={
            handleAtualizarCodigoRastreabilidadeSalvarFormModal
          }
          onFecharFormModal={handleCodigoRastreabilidadeFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesContasParcelaReversao;
