import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PisCofinsObterVisibilidadeBloqueioCamposPorCst } from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const camposEscondidosCofinsPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,

  calcularRetido: true,
  baseCalculoRetido: true,
  aliquotaRetido: true,
  valorRetido: true,
};

const camposBloqueadosCofinsPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,

  calcularRetido: true,
  baseCalculoRetido: true,
  aliquotaRetido: true,
  valorRetido: true,
};

type ICamposEscondidosCofins = typeof camposEscondidosCofinsPadrao;
type ICamposBloqueadosCofins = typeof camposBloqueadosCofinsPadrao;

interface IItemCofinsTabContext {
  validador: boolean;
  camposEscondidosCofins: ICamposEscondidosCofins;
  camposBloqueadosCofins: ICamposBloqueadosCofins;
  obterVisibilidadeBloqueioCamposPorCofinsCst(): void;
}

const ItemCofinsTabContext = createContext<IItemCofinsTabContext>(
  {} as IItemCofinsTabContext
);

const ItemCofinsTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe } = UseListaDetalheForm();

  const [camposEscondidosCofins, setCamposEscondidosCofins] = useState(
    camposEscondidosCofinsPadrao
  );

  const [camposBloqueadosCofins, setCamposBloqueadosCofins] = useState(
    camposBloqueadosCofinsPadrao
  );

  const calcular = formRefDetalhe.current?.getFieldValue(
    'orcamentoItemImpostoCofins.calcular'
  );
  const calcularRetido = formRefDetalhe.current?.getFieldValue(
    'orcamentoItemImpostoCofins.calcularRetido'
  );

  const tipoCalculo = formRefDetalhe.current?.getFieldValue(
    'orcamentoItemImpostoCofins.tipoCalculo'
  );

  const cst =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'orcamentoItemImpostoCofins.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorCofinsCst = useCallback(() => {
    const calcularCofins = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoCofins.calcular'
    );
    const calcularCofinsRetido = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoCofins.calcularRetido'
    );

    const tipoCalculoCofins = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoCofins.tipoCalculo'
    );

    const cstCofins =
      formRefDetalhe.current?.getFieldValueNomeObjeto(
        'orcamentoItemImpostoCofins.situacaoTributaria'
      )?.codigo || '';

    const retorno = PisCofinsObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularCofins,
      calcularRetido: calcularCofinsRetido,
      tipoCalculo: tipoCalculoCofins,
      cst: cstCofins,
    });

    setCamposEscondidosCofins(retorno.escondido);
    setCamposBloqueadosCofins(retorno.bloqueado);
  }, [formRefDetalhe]);

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorCofinsCst();
  }, [
    calcular,
    calcularRetido,
    tipoCalculo,
    cst,
    handleObterVisibilidadeBloqueioCamposPorCofinsCst,
  ]);

  return (
    <ItemCofinsTabContext.Provider
      value={{
        camposEscondidosCofins,
        camposBloqueadosCofins,
        obterVisibilidadeBloqueioCamposPorCofinsCst:
          handleObterVisibilidadeBloqueioCamposPorCofinsCst,
        validador: true,
      }}
    >
      {children}
    </ItemCofinsTabContext.Provider>
  );
};

function UseItemCofinsTab(): Omit<IItemCofinsTabContext, 'validador'> {
  const context = useContext(ItemCofinsTabContext);

  if (!context.validador) {
    throw new Error('UseItemCofinsTab deve ser usado com um ItemCofinsTabHook');
  }

  return context;
}

export { ItemCofinsTabHook, UseItemCofinsTab };
