import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-pedido-venda/obter-pedido-venda-item-estoque';

interface INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicadorIndex {
  listaItemSelecionado: any[];
}

class NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicador();
