import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida';

interface INotaFiscalSaidaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface INotaFiscalSaidaComunicadorShow {
  id: string;
}

interface INotaFiscalSaidaComunicadorStore {
  params: any;
}

interface INotaFiscalSaidaComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalSaidaComunicadorDeletar {
  id: string;
}

class NotaFiscalSaidaComunicador {
  public async index(params: INotaFiscalSaidaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: INotaFiscalSaidaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: INotaFiscalSaidaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: INotaFiscalSaidaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalSaidaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaComunicador();
