import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PessoaImportacaoDeParaManualItem from './PessoaImportacaoDeParaManualItem';

type Props = {
  listaOpcoesCSV: any[];
  onConfirmarImportacao?: (listaDePara: any[]) => void;
};

const PessoaImportacaoDeParaManual: React.FC<Props> = ({
  listaOpcoesCSV,
  onConfirmarImportacao,
}) => {
  // #region Opções internas

  const opcoesInternas = [
    {
      id: 0,
      nome: 'ativo',
      tooltip: 'Indica se a pessoa está ativa ou inativa',
    },
    {
      id: 1,
      nome: 'codigo',
      tooltip: 'Código único que identifica a pessoa',
    },
    {
      id: 2,
      nome: 'nomeRazaoSocial',
      tooltip: 'Nome ou Razão Social da pessoa',
    },
    {
      id: 3,
      nome: 'nomeFantasia',
      tooltip: 'Nome Fantasia da pessoa',
    },
    {
      id: 4,
      nome: 'cnpj',
      tooltip: 'CNPJ da pessoa',
    },
    {
      id: 5,
      nome: 'cpf',
      tooltip: 'CPF da pessoa',
    },
    {
      id: 6,
      nome: 'cnpjCpf',
      tooltip: 'CNPJ ou CPF da pessoa',
    },
    {
      id: 7,
      nome: 'inscricaoEstadual',
      tooltip: 'Inscrição Estadual da pessoa',
    },
    {
      id: 8,
      nome: 'inscricaoMunicipal',
      tooltip: 'Inscrição Municipal da pessoa',
    },
    {
      id: 9,
      nome: 'contribuinteIcms',
      tooltip: 'Indica se a pessoa é contribuinte de ICMS',
    },
    {
      id: 10,
      nome: 'cliente',
      tooltip: 'Indica se a pessoa é cliente',
    },
    {
      id: 11,
      nome: 'fornecedor',
      tooltip: 'Indica se a pessoa é fornecedor',
    },
    {
      id: 12,
      nome: 'transportador',
      tooltip: 'Indica se a pessoa é transportador',
    },
    {
      id: 13,
      nome: 'representante',
      tooltip: 'Indica se a pessoa é representante',
    },
    {
      id: 14,
      nome: 'produtor',
      tooltip: 'Indica se a pessoa é produtor',
    },
    {
      id: 33,
      nome: 'motorista',
      tooltip: 'Indica se a pessoa é motorista',
    },
    {
      id: 34,
      nome: 'tecnico',
      tooltip: 'Indica se a pessoa é tecnico',
    },
    {
      id: 15,
      nome: 'pessoaEnderecoPrincipal.cep',
      tooltip: 'CEP do endereço da pessoa',
    },
    {
      id: 16,
      nome: 'pessoaEnderecoPrincipal.cidade.nome',
      tooltip: 'Nome da cidade do endereço da pessoa',
    },
    {
      id: 17,
      nome: 'pessoaEnderecoPrincipal.cidade.estado.sigla',
      tooltip: 'Sigla do estado do endereço da pessoa',
    },
    {
      id: 18,
      nome: 'pessoaEnderecoPrincipal.cidade.ibge',
      tooltip: 'Código IBGE da cidade do endereço da pessoa',
    },
    {
      id: 19,
      nome: 'pessoaEnderecoPrincipal.logradouro',
      tooltip: 'Logradouro do endereço da pessoa',
    },
    {
      id: 20,
      nome: 'pessoaEnderecoPrincipal.numero',
      tooltip: 'Número do endereço da pessoa',
    },
    {
      id: 21,
      nome: 'pessoaEnderecoPrincipal.bairro',
      tooltip: 'Bairro do endereço da pessoa',
    },
    {
      id: 22,
      nome: 'pessoaEnderecoPrincipal.complemento',
      tooltip: 'Complemento do endereço da pessoa',
    },
    {
      id: 23,
      nome: 'pessoaEnderecoPrincipal.site',
      tooltip: 'Site da pessoa',
    },
    {
      id: 24,
      nome: 'listaPessoaTelefone.numero',
      tooltip: 'Número de telefone da pessoa',
    },
    {
      id: 25,
      nome: 'listaPessoaTelefone.numero2',
      tooltip: 'Segundo Número de telefone da pessoa',
    },
    {
      id: 26,
      nome: 'listaPessoaEmail.email',
      tooltip: 'Endereço de email da pessoa',
    },
    {
      id: 27,
      nome: 'ramoAtividadeProfissao.descricao',
      tooltip: 'Descrição do ramo de atividade ou profissão',
    },
    {
      id: 28,
      nome: 'registroMinisterioAgricultura',
      tooltip: 'Registro no Ministério da Agricultura',
    },
    {
      id: 29,
      nome: 'inscricaoSuframa',
      tooltip: 'Inscrição na SUFRAMA',
    },
    {
      id: 30,
      nome: 'regimeTributario',
      tooltip: 'Regime Tributário da pessoa',
    },
    {
      id: 31,
      nome: 'observacoes',
      tooltip: 'Observações sobre a pessoa',
    },
    {
      id: 32,
      nome: 'dataCadastro',
      tooltip: 'Data de cadastro da pessoa',
    },
    {
      id: 35,
      nome: 'pessoaFisica.dataNascimento',
      tooltip: 'Data de Nascimento',
    },
    {
      id: 36,
      nome: 'pessoaFisica.nomePai',
      tooltip: 'Nome do Pai',
    },
    {
      id: 37,
      nome: 'pessoaFisica.nomeMae',
      tooltip: 'Nome da Mãe',
    },
    {
      id: 38,
      nome: 'rg',
      tooltip: 'Registro Geral',
    },
    {
      id: 39,
      nome: 'pessoaFisica.sexo',
      tooltip: 'Sexo',
    },
  ];

  // #endregion Opções internas

  // #region Estados

  const [itensSelecionados, setItensSelecionados] = useState<any[]>([]);
  const [opcoesCSVFiltradas, setOpcoesCSVFiltradas] = useState<any[]>([]);

  // #endregion Estados

  // #region Funções

  const handleItemSelecionado = (item: any): any => {
    setItensSelecionados((prevItems) => [...prevItems, item]);
  };

  const handleItemRemoval = (item: any): void => {
    const updatedItensSelecionados = itensSelecionados.filter(
      (selectedItem) => {
        return selectedItem.value !== item;
      }
    );
    setItensSelecionados(updatedItensSelecionados);
  };

  useEffect(() => {
    const resposta = listaOpcoesCSV.filter(
      (item) =>
        !itensSelecionados.some((selecionado) => selecionado.value === item)
    );

    setOpcoesCSVFiltradas(resposta);
    onConfirmarImportacao && onConfirmarImportacao(itensSelecionados);
  }, [itensSelecionados, listaOpcoesCSV, onConfirmarImportacao]);

  // #endregion Funções

  return (
    <>
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos aceitos pelo Elo Gestor</b>
          </div>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos do seu arquivo CSV</b>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          {opcoesInternas.map((itemInterno, i) => (
            <PessoaImportacaoDeParaManualItem
              key={i}
              listaOpcoesCsv={opcoesCSVFiltradas}
              listaOpcoesCsvIniciais={listaOpcoesCSV}
              tooltip={itemInterno.tooltip}
              opcaoInterna={itemInterno}
              onItemSelecionado={handleItemSelecionado}
              onItemRemoval={handleItemRemoval}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default PessoaImportacaoDeParaManual;
