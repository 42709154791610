import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import UnidadeMedidaHook from './Hooks';

const UnidadeMedidaDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <UnidadeMedidaHook>
        <SubHeader
          titulo={params.id ? 'Editar Unidade de Medida' : 'Unidade de Medida'}
          permissao={permissoes.SuprimentosProdutosUnidadeMedida}
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </UnidadeMedidaHook>
    </Container>
  );
};

export default UnidadeMedidaDetalhe;
