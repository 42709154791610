import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const Container = styled.div`
  form {
    display: flex;
    align-items: flex-end;
    > div {
      margin-top: 0;
    }
    > * + * {
      margin-left: 10px;
    }
  }
`;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;
