"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDataHoraEnum;
(function (TipoDataHoraEnum) {
    TipoDataHoraEnum["dataHora"] = "Data Hora";
    TipoDataHoraEnum["data"] = "Data";
    TipoDataHoraEnum["hora"] = "Hora";
})(TipoDataHoraEnum || (TipoDataHoraEnum = {}));
exports.default = TipoDataHoraEnum;
