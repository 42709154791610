import {
  FormatarDataHoraParaPtBr,
  IManifestoTransportePorResumoRelatorio,
  IManifestoTransportePorResumoCabecalhoRelatorio,
  IManifestoTransporteFiltroRelatorioFrontend,
  IManifestoTransporteFiltroRelatorioBackend,
} from '@elogestor/util';
import React from 'react';
import ManifestoTransportePorResumoRelatorioComunicador from '../../../../../../Comunicador/Transporte/Relatorios/ManifestoTransporte/ManifestoTransportePorResumooRelatorio/ManifestoTransportePorResumoRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ManifestoTransportePorResumoPDF from './ManifestoTransportePorResumoPDF';
import ManifestoTransporteResumoRelatorioPlanilha from './ManifestoTransporteResumoRelatorioPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ManifestoTransportePorResumoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IManifestoTransporteFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IManifestoTransportePorResumoRelatorio | null> => {
    const filtroBack: IManifestoTransporteFiltroRelatorioBackend = {
      dataHoraEmissaoInicial: filtros.dataHoraEmissaoInicial,
      dataHoraEmissaoFinal: filtros.dataHoraEmissaoFinal,
    };

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao =
        filtros.listaSituacao.listaValor.map((value) => value.descricao) || [];
    }

    if (filtros?.listaPlaca) {
      filtroBack.listaIdPlaca =
        filtros.listaPlaca.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaContratante) {
      filtroBack.listaIdContratante =
        filtros.listaContratante.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaNumerosCtes) {
      filtroBack.listaTextoNumerosCtes =
        filtros.listaNumerosCtes.listaValor.map((value) => value.textoTag) ||
        [];
    }

    if (filtros?.listaNumerosNotas) {
      filtroBack.listaTextoNumerosNotas =
        filtros.listaNumerosNotas.listaValor.map((value) => value.textoTag) ||
        [];
    }

    if (filtros?.listaChavesAcesso) {
      filtroBack.listaTextoChavesAcesso =
        filtros.listaChavesAcesso.listaValor.map((value) => value.textoTag) ||
        [];
    }

    const response =
      await ManifestoTransportePorResumoRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const listaDados = response.listaDados.map((listaMap: any) => {
      return {
        ...listaMap,
        dataHoraEmissao: FormatarDataHoraParaPtBr(listaMap.dataHoraEmissao),
        dataHoraInicioViagem: FormatarDataHoraParaPtBr(
          listaMap.dataHoraInicioViagem
        ),
        valorTotalMercadoriaTransportada: Number(
          listaMap.valorTotalMercadoriaTransportada
        ).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        }),
        pesoBrutoTotalCarga: Number(
          listaMap.pesoBrutoTotalCarga
        ).FormatarParaPtBr({
          maximoCasasDecimais: 2,
          minimoCasasDecimais: 2,
        }),
      };
    });

    const retorno = response as IManifestoTransportePorResumoRelatorio;

    return {
      ...retorno,
      listaDados,
      valorTotalProdutos: Number(response.valorTotalProdutos).FormatarParaPtBr({
        maximoCasasDecimais: 2,
        minimoCasasDecimais: 2,
      }),
    };
  };

  function FormatarCabecalho(
    filtros: IManifestoTransporteFiltroRelatorioFrontend,
    cabecalho: IManifestoTransportePorResumoCabecalhoRelatorio
  ): IManifestoTransportePorResumoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraEmissaoInicial || filtros.dataHoraEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.dataHoraEmissaoInicial = '--/--/--';
      cabecalho.dataHoraEmissaoFinal = '--/--/--';

      if (filtros.dataHoraEmissaoInicial) {
        cabecalho.dataHoraEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraEmissaoInicial
        );
      }

      if (filtros.dataHoraEmissaoFinal) {
        cabecalho.dataHoraEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraEmissaoFinal
        );
      }
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.listaSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaPlaca && filtros.listaPlaca.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;
      cabecalho.listaPlacas = filtros.listaPlaca.listaValor
        .map((valor: any) => {
          return valor.placa;
        })
        .join(', ');
    }

    if (
      filtros.listaContratante &&
      filtros.listaContratante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.listaContratante = filtros.listaContratante.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (
      filtros.listaChavesAcesso &&
      filtros.listaChavesAcesso.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.listaChavesAcesso = filtros.listaChavesAcesso.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    if (
      filtros.listaNumerosCtes &&
      filtros.listaNumerosCtes.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.listaNumerosCtes = filtros.listaNumerosCtes.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    if (
      filtros.listaNumerosNotas &&
      filtros.listaNumerosNotas.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.listaNumerosNotas = filtros.listaNumerosNotas.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: ManifestoTransporteResumoRelatorioPlanilha,
      nomeArquivo: 'ManifestoTransporteResumo',
      valores: dados.listaDados,
      unwind: ['listaManifestoTransporte'],
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const data = await ObterRelatorio(filtros);
    if (!data) return;

    const planilha = await Planilha({
      campos: ManifestoTransporteResumoRelatorioPlanilha,
      nomeArquivo: 'ManifestoTransporteResumo',
      valores: data.listaDados as any,
      unwind: ['listaManifestoTransporte'],
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ManifestoTransportePorResumoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IManifestoTransporteFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ManifestoTransportePorResumoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ManifestoTransportePorResumoRelatorio();
