import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
  FormatarTelefone,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';
import { INotaFiscalImpressaoPDF } from './NotaFiscalImpressaoPDF';

const NotaFiscalImpressaoCabecalhoPDF: React.FC<INotaFiscalImpressaoPDF> = ({
  dados,
}) => {
  const canvasBarCode = document.createElement('canvas');

  JsBarcode(canvasBarCode, dados.chaveAcesso, {
    fontSize: 0,
    format: 'CODE128C',
  });

  const barCode = canvasBarCode.toDataURL();

  return (
    <View style={{ flexDirection: 'row', height: 105 }}>
      <View style={{ width: '55%' }}>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ height: '55%' }}>
            <View style={{ flexDirection: 'row', height: '100%' }}>
              <View
                style={{
                  width: '72%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ flexDirection: 'row', height: '100%' }}>
                  <View
                    style={{
                      width: '25%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '5px',
                    }}
                  >
                    {dados.caminhoLogo && (
                      <Image
                        src={dados.caminhoLogo}
                        style={{ borderRadius: 0 }}
                      />
                    )}
                  </View>

                  <View
                    style={{ width: '70%', justifyContent: 'space-between' }}
                  >
                    <View
                      style={{
                        marginLeft: '5px',
                        marginTop: '5px',
                        fontSize: 7,
                        fontWeight: 'bold',
                      }}
                    >
                      <Text>{dados.empresa.nomeRazaoSocial}</Text>
                    </View>

                    <View
                      style={{
                        marginLeft: '5px',
                        marginBottom: '5px',
                        fontSize: 6,
                      }}
                    >
                      <Text>
                        {`${dados.empresa.empresaEndereco.logradouro}, ${dados.empresa.empresaEndereco.numero}, ${dados.empresa.empresaEndereco.bairro}`}
                      </Text>
                      <Text>
                        {`${dados.empresa.empresaEndereco.cidade.nome} - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
                      </Text>
                      <Text>
                        {'FONE: '}
                        {FormatarTelefone(dados.empresa.empresaEndereco.fone)}
                      </Text>
                      <Text>
                        {'CEP: '}
                        {FormatarCep(dados.empresa.empresaEndereco.cep)}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ width: '28%', fontSize: 6 }}>
                <View
                  style={{
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>DANFE</Text>
                </View>

                <View style={{ marginLeft: '5px', justifyContent: 'center' }}>
                  <Text>DOCUMENTO AUXILIAR DA</Text>
                  <Text>NOTA FISCAL ELETRONICA</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: '50%' }}>
                    <View
                      style={{
                        marginLeft: '5px',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>0 - ENTRADA</Text>
                      <Text>1 - SAIDA</Text>
                    </View>
                  </View>

                  <View style={{ width: '50%' }}>
                    <View
                      style={{
                        width: '10px',
                        height: '12px',
                        marginLeft: '5px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1 solid black',
                      }}
                    >
                      <Text>{dados.tipoNotaFiscal}</Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    marginLeft: '5px',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                  }}
                >
                  <Text>
                    {'Nº: '}
                    {dados.numero}
                  </Text>
                </View>

                <View style={{ marginLeft: '5px', justifyContent: 'center' }}>
                  <Text>
                    {'SÉRIE: '}
                    {dados.serie.codigo}
                  </Text>
                </View>

                <View style={{ marginLeft: '5px', justifyContent: 'center' }}>
                  <Text
                    render={({ pageNumber, totalPages }) => {
                      return `F/L: ${pageNumber}/${totalPages}`;
                    }}
                    fixed
                  />
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              height: '22.5%',
              border: '1 solid black',
              borderRadius: 5,
            }}
          >
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>NATUREZA DA OPERAÇÃO</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginLeft: '5px',
                justifyContent: 'center',
              }}
            >
              <Text>{dados.naturezaOperacao}</Text>
            </View>
          </View>

          <View style={{ height: '22.5%' }}>
            <View style={{ flexDirection: 'row', height: '100%' }}>
              <View
                style={{
                  width: '30%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>INSCRIÇÃO ESTADUAL</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.empresa.inscricaoEstadual}</Text>
                </View>
              </View>

              <View
                style={{
                  width: '40%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>INSC. ESTADUAL DO SUBST. TRIBUT.</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>
                    {dados.empresa.inscricaoEstadualSubstitutoTributario}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: '30%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>CNPJ</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{FormatarCnpjCpf(dados.empresa.cnpjCpf)}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ width: '45%' }}>
        <View style={{ flexDirection: 'column' }}>
          <View
            style={{
              height: '77.5%',
              border: '1 solid black',
              borderRadius: 5,
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              <View
                style={{
                  height: '42.5%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <View style={{ width: '75%', height: '90%' }}>
                  <Image src={barCode} />
                </View>
              </View>

              <View
                style={{
                  height: '30%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>CHAVE DE ACESSO</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{FormatarChaveAcesso(dados.chaveAcesso)}</Text>
                </View>
              </View>

              <View style={{ height: '27.5%' }}>
                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>Consulta de autenticidade no portal da NF-e</Text>
                  <Text>http://www.nfe.fazenda.gov.br/portal/</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              height: '22.5%',
              border: '1 solid black',
              borderRadius: 5,
            }}
          >
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>PROTOCOLO DE AUTORIZAÇÃO DE USO</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>
                {dados.numeroProtocolo &&
                  `${
                    dados.numeroProtocolo
                  } - ${FormatarDataHoraCompletaParaPtBr(
                    dados.dataHoraRetorno
                  )}`}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default NotaFiscalImpressaoCabecalhoPDF;
