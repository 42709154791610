"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoConsignadoRetiradaConsignadoEnum;
(function (SituacaoConsignadoRetiradaConsignadoEnum) {
    SituacaoConsignadoRetiradaConsignadoEnum["faturado"] = "Faturado";
    SituacaoConsignadoRetiradaConsignadoEnum["naoFaturado"] = "N\u00E3o Faturado";
    SituacaoConsignadoRetiradaConsignadoEnum["totalmenteDevolvido"] = "Totalmente Devolvido";
    SituacaoConsignadoRetiradaConsignadoEnum["todos"] = "Todos";
})(SituacaoConsignadoRetiradaConsignadoEnum || (SituacaoConsignadoRetiradaConsignadoEnum = {}));
exports.default = SituacaoConsignadoRetiradaConsignadoEnum;
