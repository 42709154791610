import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/banco-auto-complete';

interface IBancoAutoCompleteComunicadorIndex {
  params: any;
}

class BancoAutoCompleteComunicador {
  public async index(params: IBancoAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new BancoAutoCompleteComunicador();
