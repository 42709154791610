import { IUnformFieldCiaUserProps, UseFieldCia } from '@elogestor/unformcia';
import { useEffect, useRef, useState } from 'react';
import { IInputAutoCompleteBaseRef } from '../..';

interface IUseRegisterAutoComplete<T> {
  nome: string;
}

function UseRegisterAutoComplete<T = any>(
  { nome }: IUseRegisterAutoComplete<T>,
  UnformCiaField: Partial<IUnformFieldCiaUserProps<any>> = {}
): {
  autoCompleteRef: React.MutableRefObject<IInputAutoCompleteBaseRef | null>;
  error: string | undefined;
  disabled: boolean;
  flags: React.MutableRefObject<{ clearValue: boolean }>;
} {
  const flags = useRef({ clearValue: false });
  const [disabled, setDisabled] = useState(false);
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef | null>(null);
  const { fieldName, error, registerField } = UseFieldCia(nome);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: autoCompleteRef,

      getValue() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual?.id ?? null;
      },

      getDataDuplicar() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataPesquisa() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataRecuperarFormulario() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataNomeObjeto() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      setValue(_, value) {
        autoCompleteRef.current?.selecionarItem(value);
      },

      setSemExecutarEvento(_, value) {
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },

      clearValue(_, valorInicial) {
        flags.current.clearValue = true;
        this.setValue(_, valorInicial ?? null);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        const inputRef = autoCompleteRef.current?.getInput();

        if (inputRef) {
          inputRef.disabled = valor;
          setDisabled(valor);
        }
      },

      ...UnformCiaField,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    autoCompleteRef,
    error,
    disabled,
    flags,
  };
}

export default UseRegisterAutoComplete;
