"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ObterTextoImpostoAproximado;
function ObterTextoImpostoAproximado({ valorFederal, valorEstadual, valorMunicipal, fonte, chave, }) {
    if ((!valorFederal || Number(valorFederal) === 0) &&
        (!valorEstadual || Number(valorEstadual) === 0) &&
        (!valorMunicipal || Number(valorMunicipal) === 0)) {
        return '';
    }
    let retorno = '';
    if (valorFederal && valorFederal > 0)
        retorno += `R$${Number(valorFederal).FormatarParaPtBr()} Federal`;
    if (valorEstadual && valorEstadual > 0) {
        if (retorno !== '')
            retorno += ', ';
        retorno += `R$${Number(valorEstadual).FormatarParaPtBr()} Estadual`;
    }
    if (valorMunicipal && valorMunicipal > 0) {
        if (retorno !== '')
            retorno += ', ';
        retorno += `R$${Number(valorMunicipal).FormatarParaPtBr()} Municipal`;
    }
    retorno = `Trib Aprox: ${retorno} Fonte: ${fonte} ${chave}`;
    return retorno;
}
