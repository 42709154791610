import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-producao';

interface IOrdemProducaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IOrdemProducaoComunicadorShow {
  id: string;
}

interface IOrdemProducaoComunicadorStore {
  params: any;
}

interface IOrdemProducaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IOrdemProducaoComunicadorDeletar {
  id: string;
}

class OrdemProducaoComunicador {
  public async index(params: IOrdemProducaoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IOrdemProducaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IOrdemProducaoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IOrdemProducaoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IOrdemProducaoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemProducaoComunicador();
