import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IOrcamentoComercialRepresentanteLista,
  CalculoBaseComissao,
} from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { UseFieldCia, FormCia } from '@elogestor/unformcia';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import InputAutoCompleteRepresentante, {
  IInputAutoCompleteRepresentanteRef,
} from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRepresentante';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import { UseOrcamento } from '../../../Hook/OrcamentoHook';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputSwitch from '../../../../../../../../Componentes/Inputs/InputSwitch';
import { UseListaItem } from '../../../Hook/ListaItemContext';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';

interface IListaDetalheComercialRepresentanteProps {
  name: string;
}

const OrcamentoComercialRepresentanteLista: React.FC<
  IListaDetalheComercialRepresentanteProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { listaItem } = UseListaItem();
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { ComercialVendasOrcamento: permissao } = permissoes;

  const { setListaRepresentante } = UseOrcamento();
  const {
    formRefLista,
    handleCarregarDados,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const itemRef = useRef<IInputAutoCompleteRepresentanteRef>(null);
  const valorRef = useRef<IOrcamentoComercialRepresentanteLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IOrcamentoComercialRepresentanteLista[]
  >([]);
  const [formaCalculoPorRegra, setFormaCalculoPorRegra] = useState(true);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  const listaId = useMemo(
    () =>
      listaValor.map((valor) => {
        return valor.pessoaRepresentante.id;
      }),
    [listaValor]
  );

  useEffect(() => {
    registerField<IOrcamentoComercialRepresentanteLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) {
          setListaValor([]);
          setListaRepresentante([]);
        } else {
          setListaValor(value);
          setListaRepresentante(value);
        }
      },

      setSemExecutarEvento(_, value) {
        if (!value) {
          setListaValor([]);
          setListaRepresentante([]);
        } else {
          setListaValor(value);
          setListaRepresentante(value);
        }
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleObterDadosRegraPorCalculoComissao = useCallback(
    async (data: any) => {
      if (listaItem && listaItem.length > 0) {
        let valor = 0;
        if (data.formaCalculoPorRegra) {
          const idPessoa = formPrincipal.formRef.current?.getFieldValue(
            'orcamentoPessoa.idPessoa'
          );

          const dataHoraEmissao =
            formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');
          await Promise.all(
            listaItem.map(async (item) => {
              if (
                item.orcamentoItemComercial &&
                item.orcamentoItemComercial?.gerarComissao &&
                item.orcamentoItemComercial
                  .listaOrcamentoItemComercialRepresentante
              ) {
                const baseCalculo = CalculoBaseComissao({
                  valorTotalBruto: item.valorTotalBruto,
                  valorImpostoIcms:
                    item.orcamentoItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt ??
                    0,
                  valorImpostoIpi: item.orcamentoItemImpostoIpi?.valor ?? 0,
                  valorFrete: item.valorFrete ?? 0,
                  valorOutrasDespesas: item.valorOutrasDespesas ?? 0,
                  valorSeguro: item.valorSeguro ?? 0,
                });
                const response =
                  await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                    {
                      params: {
                        idPessoa,
                        idPessoaRepresentante: data.idPessoaRepresentante,
                        idProduto: item.idProduto,
                        idGrupoProdutoServico: item.produto
                          ? item.produto.idGrupoProdutoServico
                          : null,
                        dataPesquisaVigencia: new Date(dataHoraEmissao),
                        percentualDesconto: 0,
                      },
                    }
                  );

                if (response.regraCalculoComissao) {
                  valor +=
                    baseCalculo *
                    (Number(response.regraCalculoComissao.percentualComissao) /
                      100);
                }
              }
            })
          );

          data.valor = valor;
          data.aliquota =
            valor > 0 && data.baseCalculo > 0
              ? (valor / Number(data.baseCalculo)) * 100
              : 0;
        }
      }
    },
    [formPrincipal, listaItem]
  );

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getDataDuplicar() as any;

    data.idPessoaRepresentante = data.pessoaRepresentante
      ? data.pessoaRepresentante.id
      : null;

    if (data.idPessoaRepresentante) {
      await handleObterDadosRegraPorCalculoComissao(data);
    }

    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [
    formRefLista,
    handleObterDadosRegraPorCalculoComissao,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  const handleCarregarBaseCalculo = useCallback(() => {
    let baseCalculo = 0;

    if (listaItem && listaItem.length > 0) {
      listaItem.forEach((item: any) => {
        if (
          item.orcamentoItemComercial &&
          item.orcamentoItemComercial.gerarComissao
        ) {
          const valorTotalBruto = Number(item.valorTotalBruto);
          const valorImpostoIcms =
            Number(item.orcamentoItemImpostoIcms.valorIcmsStEValorFcpIcmsSt) ??
            0;
          const valorImpostoIpi =
            Number(item.orcamentoItemImpostoIpi.valor) ?? 0;

          const valorFrete = Number(item.valorFrete);
          const valorOutrasDespesas = Number(item.valorOutrasDespesas);
          const valorSeguro = Number(item.valorSeguro);

          baseCalculo +=
            valorTotalBruto -
            (valorFrete +
              valorOutrasDespesas +
              valorSeguro +
              valorImpostoIcms +
              valorImpostoIpi);
        }
      });

      formRefLista.current?.setFieldValue('baseCalculo', baseCalculo);
    }
  }, [formRefLista, listaItem]);

  const handleCalcularValor = useCallback(() => {
    const baseCalculo = formRefLista.current?.getFieldValue('baseCalculo');
    const aliquota = formRefLista.current?.getFieldValue('aliquota');

    let valor = 0;

    if (baseCalculo && aliquota) {
      valor = baseCalculo * (aliquota / 100);

      formRefLista.current?.setFieldValue('valor', valor);
    }
  }, [formRefLista]);

  return (
    <>
      <FormCia ref={formRefLista}>
        <Row>
          <Col lg={8} md={8}>
            <InputAutoCompleteRepresentante
              label="Representante"
              name="idPessoaRepresentante"
              nomeObjeto="pessoaRepresentante"
              permitirAdicionar
              listaIdParaRemover={listaId}
              onChangeItemAtual={() => {
                handleCarregarBaseCalculo();
              }}
              ref={itemRef}
            />
          </Col>

          <Col lg={4} md={4}>
            <InputSwitch
              label="Cálculo por Regra"
              ativo="Sim"
              inativo="Não"
              name="formaCalculoPorRegra"
              onChange={(event) => {
                setFormaCalculoPorRegra(event.target.checked);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={formaCalculoPorRegra ? 4 : 3} md={3}>
            <InputDecimal
              label="Base de Cálculo"
              name="baseCalculo"
              casasInteiras={15}
              casasDecimais={2}
              disabled
            />
          </Col>

          <Col
            lg={3}
            md={3}
            style={{ display: formaCalculoPorRegra ? 'none' : ' flex' }}
          >
            <InputDecimal
              label="Alíquota"
              name="aliquota"
              casasInteiras={5}
              casasDecimais={2}
              onChange={() => {
                handleCalcularValor();
              }}
            />
          </Col>

          <Col
            lg={3}
            md={3}
            style={{ display: formaCalculoPorRegra ? 'none' : ' flex' }}
          >
            <InputDecimal
              label="Valor"
              name="valor"
              casasInteiras={18}
              casasDecimais={2}
              disabled
            />
          </Col>

          <Col
            lg={formaCalculoPorRegra ? 4 : 3}
            md={3}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              className="btn-padrao btn-verde-claro btn-adicionar"
              onClick={handleAdicionar}
              disabled={!permissao?.inclui || formPrincipal.loading || loading}
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAdd />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '40%' }}>
                <span>Representante</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Báse de Cálculo</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Alíquota</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Valor</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Cálculo por Regra</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor &&
              listaValor.map((orcamentoComercialRepresentante, index) => {
                const { pessoaRepresentante } = orcamentoComercialRepresentante;
                return (
                  <tr
                    key={v4()}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>{pessoaRepresentante.nomeRazaoSocial}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(
                          orcamentoComercialRepresentante.baseCalculo
                        ).FormatarParaPtBr({
                          maximoCasasDecimais: 2,
                          minimoCasasDecimais: 2,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(
                          orcamentoComercialRepresentante.aliquota
                        ).FormatarParaPtBrPercentual()}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(
                          orcamentoComercialRepresentante.valor
                        ).FormatarParaPtBr({
                          maximoCasasDecimais: 2,
                          minimoCasasDecimais: 2,
                        })}
                      </div>
                    </td>
                    <td
                      className="lista-booleano"
                      style={{
                        color:
                          orcamentoComercialRepresentante.formaCalculoPorRegra
                            ? 'green'
                            : 'red',
                      }}
                    >
                      <div>
                        {orcamentoComercialRepresentante.formaCalculoPorRegra
                          ? 'Sim'
                          : 'Não'}
                      </div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(String(index));
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default OrcamentoComercialRepresentanteLista;
