import React, { useEffect, useCallback, useState } from 'react';
import { FormCia } from '@elogestor/unformcia';
import {
  BooleanEnum,
  CampoAtualizarTabelaPrecoEnum,
  FormatarEnum,
  IRegraEscolhaTabelaPrecoValoresAlterar,
  OrigemValorBaseTabelaPrecoEnum,
} from '@elogestor/util';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Select from '../../../../../../Componentes/Select';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { Divisor } from '../../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import { BtnContainer } from './styles';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagEstado from '../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import InputAutoCompleteTagRepresentante from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRepresentante';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

type IProps = IMain<IRegraEscolhaTabelaPrecoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialTabelaPrecoRegraEscolhaTabelaPreco: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    refresh,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const { setDisableExcluir, setDisableDuplicar } = UseButtonsSubHeader();

  const idDetalheRegistro = getIdDetalheRegistro();

  const campoAtualizar = formRef.current?.getFieldValue('campoAtualizar');
  const vinculadaAoCadastroProduto = formRef.current?.getFieldValue(
    'vinculadaAoCadastroProduto'
  );

  const [
    origemValorTabelaPrecoVendaCadastro,
    setOrigemValorTabelaPrecoVendaCadastro,
  ] = useState(
    formRef.current?.getFieldValue('origemValoresTabela') ===
      OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos
  );

  useEffect(() => {
    setDisableExcluir(vinculadaAoCadastroProduto);
    setDisableDuplicar(vinculadaAoCadastroProduto);
  }, [setDisableDuplicar, setDisableExcluir, vinculadaAoCadastroProduto]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    if (data.aumentandoEmPercentual === 0) {
      data.aumentandoEmPercentual = null;
      refresh();
    }

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, refresh, rotas.listagem]);

  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaTabelaPrecoPessoa')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaTabelaPrecoEstado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('contribuinte') !== null) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaTabelaPrecoDestinado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaTabelaPrecoPessoaRepresentante'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaTabelaPrecoProduto')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaTabelaPrecoGrupoProdutoServico'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />
      <div>
        <Divisor>
          <InputHiddenHtml name="vinculadaAoCadastroProduto" />
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Descrição da Tabela de Preço"
                name="descricao"
                placeholder="Descrição da Tabela de Preco"
                maxLength={100}
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Origem do Valor de Base desta Tabela"
                name="origemValoresTabela"
                options={FormatarEnum({
                  enumObj: OrigemValorBaseTabelaPrecoEnum,
                })}
                onChange={() => {
                  const origemValoresTabela = formRef.current?.getFieldValue(
                    'origemValoresTabela'
                  );
                  if (
                    origemValoresTabela ===
                      OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos &&
                    vinculadaAoCadastroProduto
                  ) {
                    formRef.current.setFieldValue(
                      'campoAtualizar',
                      CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda
                    );
                    formRef.current?.setFieldValue(
                      'aumentandoEmPercentual',
                      null
                    );
                    formRef.current?.setFieldValue('aplicandoMarkup', null);
                    formRef.current?.setFieldValue(
                      'diminuindoEmPercentual',
                      null
                    );
                  }
                  setOrigemValorTabelaPrecoVendaCadastro(
                    origemValoresTabela ===
                      OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos
                  );
                }}
                onChangeValue={() => {
                  setOrigemValorTabelaPrecoVendaCadastro(
                    formRef.current?.getFieldValue('origemValoresTabela') ===
                      OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos
                  );
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaTabelaPrecoEmpresa[0].ativo"
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <InputSwitch
                label="Vinculada ao preço principal do produto"
                name="vinculadaAoCadastroProduto"
                disabled
                ativo="Sim"
                inativo="Não"
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagPessoa
                label="Pessoa"
                name="listaRegraEscolhaTabelaPrecoPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagRamoAtividadeProfissao
                label="Ramo Atividade/Profissão"
                name="listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagEstado
                label="Estados"
                name="listaRegraEscolhaTabelaPrecoEstado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Contribuinte"
                name="contribuinte"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaTabelaPrecoDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={12} sm={12}>
              <InputAutoCompleteTagRepresentante
                label="Representantes"
                placeholder="Representantes"
                name="listaRegraEscolhaTabelaPrecoPessoaRepresentante"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
            <Col lg={6} md={12} sm={12}>
              <InputAutoCompleteTagGrupoProdutoServico
                label="Grupos de Produto"
                placeholder="Grupo de Produtos"
                name="listaRegraEscolhaTabelaPrecoGrupoProdutoServico"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                placeholder="Produtos"
                name="listaRegraEscolhaTabelaPrecoProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={vinculadaAoCadastroProduto}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={2} md={12} sm={12}>
              <InputInteiro label="Casas Decimais" name="casasDecimais" />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={6}>
              <Select
                label="Campo Atualizar"
                name="campoAtualizar"
                options={FormatarEnum({
                  enumObj: CampoAtualizarTabelaPrecoEnum,
                })}
                onChange={(event) => {
                  refresh();
                  if (
                    event.currentTarget.value ===
                    CampoAtualizarTabelaPrecoEnum.percentual
                  ) {
                    formRef.current?.setFieldValue(
                      'aumentandoEmPercentual',
                      null
                    );
                    formRef.current?.setFieldValue('aplicandoMarkup', null);
                    formRef.current?.setFieldValue(
                      'diminuindoEmPercentual',
                      null
                    );
                  }
                }}
                disabled={
                  vinculadaAoCadastroProduto &&
                  origemValorTabelaPrecoVendaCadastro
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Aumentando em %"
                name="aumentandoEmPercentual"
                casasInteiras={7}
                casasDecimais={4}
                onBlur={(event) => {
                  const { value } = event.currentTarget;

                  if (value) {
                    if (value === '0,0000') {
                      formRef.current?.setFieldValue(
                        'aumentandoEmPercentual',
                        null
                      );
                      refresh();
                    }

                    formRef.current?.setFieldValue('aplicandoMarkup', null);
                    formRef.current?.setFieldValue(
                      'diminuindoEmPercentual',
                      null
                    );
                  }
                }}
                disabled={
                  campoAtualizar === CampoAtualizarTabelaPrecoEnum.percentual ||
                  (vinculadaAoCadastroProduto &&
                    origemValorTabelaPrecoVendaCadastro)
                }
              />
            </Col>
            <Col
              lg={0.5}
              md={0.5}
              sm={0.5}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <p>OU</p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Aplicando Markup em %"
                name="aplicandoMarkup"
                casasInteiras={7}
                casasDecimais={4}
                onBlur={(event) => {
                  if (event.currentTarget.value) {
                    formRef.current?.setFieldValue(
                      'aumentandoEmPercentual',
                      null
                    );
                    formRef.current?.setFieldValue(
                      'diminuindoEmPercentual',
                      null
                    );
                  }
                }}
                disabled={
                  campoAtualizar === CampoAtualizarTabelaPrecoEnum.percentual ||
                  (vinculadaAoCadastroProduto &&
                    origemValorTabelaPrecoVendaCadastro)
                }
              />
            </Col>
            <Col
              lg={0.5}
              md={0.5}
              sm={0.5}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <p>OU</p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDecimalNulavel
                label="Diminuindo em %"
                name="diminuindoEmPercentual"
                casasInteiras={7}
                casasDecimais={4}
                onBlur={(event) => {
                  if (event.currentTarget.value) {
                    formRef.current?.setFieldValue(
                      'aumentandoEmPercentual',
                      null
                    );
                    formRef.current?.setFieldValue('aplicandoMarkup', null);
                  }
                }}
                disabled={
                  campoAtualizar === CampoAtualizarTabelaPrecoEnum.percentual ||
                  (vinculadaAoCadastroProduto &&
                    origemValorTabelaPrecoVendaCadastro)
                }
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
