import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from 'react';
import { IPessoaModalLista } from '@elogestor/util';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import Modal, {
  IJanelaRef,
} from '../../../../../../../Componentes/JanelaAutocomplete';
import { Tr } from './styles';
import { Sleep } from '../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';

export interface IModalPessoaRef {
  alterarListaPessoa(lista: IPessoaModalLista[]): void;
}

const ListaPessoasRepetidas: React.ForwardRefRenderFunction<IModalPessoaRef> = (
  props,
  ref
) => {
  const navigate = useNavigate();
  const { formRef, setLoading } = UseForm();

  const modalRef = useRef<IJanelaRef>(null);
  const [listaPessoa, setListaPessoa] = useState<IPessoaModalLista[]>([]);
  const [pessoaIdSelecionada, setPessoaIdSelecionada] = useState('');

  useImperativeHandle(ref, () => ({
    alterarListaPessoa(lista: IPessoaModalLista[]) {
      setListaPessoa(lista);
    },
  }));

  useEffect(() => {
    if (listaPessoa.length > 0) {
      modalRef.current?.toggleJanela(true);
    } else {
      modalRef.current?.toggleJanela(false);
    }
  }, [listaPessoa]);

  const handleSelecionarPessoa = useCallback((id: string) => {
    setPessoaIdSelecionada(id);
  }, []);

  const handleOk = useCallback(async () => {
    if (pessoaIdSelecionada) {
      setLoading(true);

      formRef.current?.setFieldValue('naoRecuperarFormulario', true);

      navigate(`/home`, { replace: true });
      await Sleep(50);
      navigate(`/comercial/pessoas/pessoa/detalhe/${pessoaIdSelecionada}`, {
        replace: true,
      });

      setListaPessoa([]);
      setLoading(false);
    }
  }, [pessoaIdSelecionada, setLoading, formRef, navigate]);

  const handleFechar = useCallback(() => {
    setListaPessoa([]);
    setPessoaIdSelecionada('');
  }, []);

  return (
    <Modal
      titulo="Pessoa já cadastrada, selecione uma caso desejar"
      ref={modalRef}
      tamanho="xl"
      onHide={handleFechar}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="lista-valor">
              <span>Código</span>
            </th>
            <th className="lista-texto">
              <span>CNPJ/CPF</span>
            </th>
            <th className="lista-texto">
              <span>Inscrição Estadual</span>
            </th>
            <th className="lista-texto">
              <span>Nome/Razão Social</span>
            </th>
            <th className="lista-texto">
              <span>CEP</span>
            </th>
            <th className="lista-texto">
              <span>Cidade</span>
            </th>
            <th className="lista-texto">
              <span>UF</span>
            </th>
            <th className="lista-texto">
              <span>Logradouro</span>
            </th>
            <th className="lista-texto">
              <span>Bairro</span>
            </th>
            <th className="lista-texto">
              <span>Número</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {listaPessoa.map((pessoa) => {
            return (
              <Tr
                key={v4()}
                className={
                  pessoa.id === pessoaIdSelecionada ? 'selecionado' : ''
                }
                onClick={() => {
                  handleSelecionarPessoa(pessoa.id);
                }}
              >
                <td className="lista-valor">
                  <div>{pessoa.codigo}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.cnpjCpfVirtual}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.inscricaoEstadual}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.nomeRazaoSocial}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.pessoaEnderecoPrincipal.cep}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.pessoaEnderecoPrincipal.cidade?.nome || ''}</div>
                </td>
                <td className="lista-texto">
                  <div>
                    {pessoa.pessoaEnderecoPrincipal.cidade?.estado.sigla || ''}
                  </div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.pessoaEnderecoPrincipal.logradouro}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.pessoaEnderecoPrincipal.bairro}</div>
                </td>
                <td className="lista-texto">
                  <div>{pessoa.pessoaEnderecoPrincipal.numero}</div>
                </td>
              </Tr>
            );
          })}
        </tbody>
      </Table>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <button
          type="button"
          onClick={handleFechar}
          style={{ marginRight: 15 }}
          className="btn-padrao btn-cinza-claro"
        >
          <IoMdReturnLeft />
          <span style={{ marginLeft: 10 }}>Voltar</span>
        </button>

        <Button
          style={{
            fontWeight: 'bold',
            marginLeft: 10,
            display: 'flex',
            alignItems: 'center',
          }}
          type="button"
          onClick={handleOk}
          disabled={!pessoaIdSelecionada}
        >
          <AiOutlineCheck />
          <span style={{ marginLeft: 10 }}>OK</span>
        </Button>
      </div>
    </Modal>
  );
};

export default forwardRef(ListaPessoasRepetidas);
