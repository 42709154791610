import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import {
  FormatarEnum,
  IConhecimentoTransporteTerceiroCargaLista,
  UnidadeMedidaCargaEnum,
} from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import Select from '../../../../../../../../Componentes/Select';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';

interface IListaDetalheCarga {
  name: string;
  bloquearCamposPorImportacao: boolean;
}

const ListaDetalheCarga: React.FC<IListaDetalheCarga> = ({
  name,
  bloquearCamposPorImportacao,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IConhecimentoTransporteTerceiroCargaLista[]>([]);
  const [listaValor, setListaValor] = useState<
    IConhecimentoTransporteTerceiroCargaLista[]
  >([]);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IConhecimentoTransporteTerceiroCargaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formRefLista.current?.setDataInicial({
      unidadeMedidaCarga: UnidadeMedidaCargaEnum.M3,
    });
  }, [formRefLista]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col xl={2} lg={6}>
            <Select
              label="Unidade de Medida"
              name="unidadeMedidaCarga"
              options={FormatarEnum({
                enumObj: UnidadeMedidaCargaEnum,
                todos: false,
              })}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col xl={4} lg={6}>
            <Input
              name="tipoMedida"
              label="Tipo de Medida"
              placeholder="Tipo de Medida"
              maxLength={20}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col xl={4} lg={6}>
            <InputDecimalNulavel
              name="quantidade"
              label="Quantidade"
              placeholder="Quantidade"
              casasDecimais={4}
              casasInteiras={7}
              maxLength={11}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col
            xl={2}
            lg={6}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              className="btn-padrao btn-verde btn-adicionar"
              onClick={handleAdicionar}
              disabled={
                !permissao?.inclui ||
                formPrincipal.loading ||
                bloquearCamposPorImportacao ||
                loading
              }
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '17%' }}>
                <span>Unidade de Medida</span>
              </th>
              <th className="lista-texto" style={{ width: '35%' }}>
                <span>Tipo de Medida</span>
              </th>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Quantidade</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr key={v4()}>
                  <td className="lista-texto">{item.unidadeMedidaCarga}</td>
                  <td className="lista-texto">{item.tipoMedida}</td>
                  <td className="lista-texto">
                    {Number(item.quantidade).FormatarParaPtBr({
                      minimoCasasDecimais: 4,
                      maximoCasasDecimais: 4,
                    })}
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(item.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            bloquearCamposPorImportacao ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default ListaDetalheCarga;
