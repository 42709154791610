import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { INotaFiscalEntradaItemDeclaracaoImportacaoAdicaoLista } from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Input from '../../../../../../../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDecimalNulavel from '../../../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheDeclaracaoImportacaoAdicao: React.FC<IListaDetalheGeral> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemDesmontagem: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<
    INotaFiscalEntradaItemDeclaracaoImportacaoAdicaoLista[]
  >([]);
  const [listaValor, setListaValor] = useState<
    INotaFiscalEntradaItemDeclaracaoImportacaoAdicaoLista[]
  >([]);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getDataDuplicar();

    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalEntradaItemDeclaracaoImportacaoAdicaoLista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <InputHiddenHtml name="id" />
        <Row>
          <Col lg={2} md={4} sm={6}>
            <InputInteiroNulavel
              label="Número Adição"
              placeholder="Número Adição"
              name="numeroAdicao"
            />
          </Col>

          <Col lg={2} md={4} sm={6}>
            <InputInteiroNulavel
              label="Seq. Item"
              placeholder="Número Sequencial"
              name="numeroSequencialAdicao"
            />
          </Col>

          <Col lg={2} md={4} sm={6}>
            <Input
              label="Cód. Fabri. Estrang."
              placeholder="Cód. Fabricante Estrang."
              name="codigoFabricanteEstrangeiro"
              maxLength={60}
            />
          </Col>

          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="Valor Desc. Item DI"
              placeholder="Valor Desc. Item DI"
              name="valorDescontoItemDeclaracaoImportacao"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>

          <Col lg={2} md={4} sm={6}>
            <Input
              label="Número Drawback"
              placeholder="Número Drawback"
              name="numeroDrawback"
              maxLength={11}
            />
          </Col>

          <Col
            lg={2}
            md={4}
            sm={6}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionar}
              className="btn-padrao btn-verde-claro btn-adicionar"
              disabled={!permissao?.inclui || formPrincipal.loading || loading}
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAdd />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Núm. Adição</span>
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Seq. Item</span>
                </th>
                <th className="lista-text" style={{ width: '20%' }}>
                  <span>Cód. Fabricante Estrang.</span>
                </th>

                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Valor Desc. Item DI</span>
                </th>

                <th className="lista-texto" style={{ width: '20%' }}>
                  <span>Número Drawback</span>
                </th>
                <th className="lista-valor">
                  <span>Ações</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaValor.map((item, index) => {
                return (
                  <tr key={v4()}>
                    <td className="lista-valor">{item.numeroAdicao}</td>
                    <td className="lista-valor">
                      {item.numeroSequencialAdicao}
                    </td>
                    <td className="lista-texto">
                      {item.codigoFabricanteEstrangeiro}
                    </td>
                    <td className="lista-valor">
                      {Number(
                        item.valorDescontoItemDeclaracaoImportacao
                      ).FormatarParaPtBr()}
                    </td>
                    <td className="lista-texto">{item.numeroDrawback}</td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleExcluir(String(index));
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <IoMdRemoveCircleOutline
                                style={{ marginRight: 5 }}
                              />
                              Remover
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheDeclaracaoImportacaoAdicao;
