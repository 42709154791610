import React, { createContext, useCallback, useState, useContext } from 'react';
import Alerta from '..';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import IAlertaContextData from '../../Interface/IAlertaContextData';
import IAlertaState from '../../Interface/IAlertaState';

const alertaContext = createContext<IAlertaContextData>(
  {} as IAlertaContextData
);

const AlertaProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [janela, setJanela] = useState<IAlertaState>();

  const abrirJanela = useCallback(
    ({
      titulo,
      mensagem,
      confimar,
    }: Omit<IAlertaState, 'cb'>): Promise<boolean> => {
      let retorno: boolean;
      const p1 = new Promise((resolve) => {
        const cb = (resposta: boolean): void => {
          retorno = resposta;
          resolve(null);
        };

        setJanela({ titulo, mensagem, confimar, cb });
      });

      return p1.then(() => {
        return retorno || false;
      });
    },
    []
  );

  const fecharJanela = useCallback(() => {
    setJanela(undefined);
  }, []);

  return (
    <alertaContext.Provider value={{ abrirJanela, fecharJanela }}>
      {children}

      {janela && (
        <Alerta
          fecharJanela={() => {
            fecharJanela();
          }}
          confimar={janela.confimar}
          titulo={janela.titulo}
          mensagem={janela.mensagem}
          cb={janela.cb}
        />
      )}
    </alertaContext.Provider>
  );
};

function UseAlerta(): IAlertaContextData {
  const context = useContext(alertaContext);

  if (!context) {
    throw new Error('UseAlerta must be used within an AlertaProvider');
  }

  return context;
}

export { AlertaProvider, UseAlerta };
