import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import UsuarioLiberacoesComunicador from '../Comunicador/Configuracao/Usuario/Comunicador/UsuarioLiberacoesComunicador';
import { usuarioLogado } from './Auth';
import ILiberacoesContextData from '../Paginas/Configuracao/Usuario/Detalhe/LiberacoesTab/ILiberacoesContextData';
import TratarErros from '../Util/Erro/TratarErros';

const LiberacoesContext = createContext<ILiberacoesContextData>(
  {} as ILiberacoesContextData
);

const LiberacoesProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [liberacoes, setLiberacoes] = useState<ILiberacoesContextData | null>(
    null
  );

  const carregarLiberacoes = useCallback(async () => {
    try {
      const usuario = usuarioLogado as any;
      if (!usuario || !usuario.id) return;

      const response = await UsuarioLiberacoesComunicador.show({
        id: usuario?.id,
      });

      setLiberacoes(response);
    } catch (error) {
      TratarErros(error);
    }
  }, []);

  useEffect(() => {
    carregarLiberacoes();
  }, [carregarLiberacoes]);

  return (
    <LiberacoesContext.Provider value={{ ...liberacoes, carregarLiberacoes }}>
      {children}
    </LiberacoesContext.Provider>
  );
};

function UseLiberacoes(): ILiberacoesContextData {
  const context = useContext(LiberacoesContext);

  if (!context) {
    throw new Error('UseLiberacoes must be used within an LiberacoesProvider');
  }

  return context;
}

export { LiberacoesProvider, UseLiberacoes };
