import styled, { css } from 'styled-components';

interface IContainer {
  $telaGrande?: boolean;
}

export const CabecalhoListaEmailTelefone = styled.div`
  background: '#3397c4';
  display: 'flex';
  align-items: 'center';
  h4 {
    background: rgb(51, 151, 196);
    border: 2px solid rgb(51, 151, 196);
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    font-size: 20px;
    padding: 0.5rem 1rem;
    margin-bottom: 0px;
  }
`;

export const Container = styled.div<IContainer>`
  padding: 10px;

  ${({ $telaGrande }) =>
    $telaGrande &&
    css`
      .lista-pessoas {
        /* max-height: 100%; */
        max-height: 240px;
      }
      .lista-telefone-email {
        /* max-height: 100%; */
        max-height: 260px;
      }
    `}

  .lista-telefone-email {
    border-radius: 10px 10px 10px 10px;
    margin: 20px 0px;
    border: 1px solid #e4e4e4;
  }

  .lista-telefone-email td + td {
    border-left: 1px solid #fbfcfc;
  }

  .lista-pessoas td {
    cursor: pointer;
    white-space: nowrap;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        flex: 1;
      }
    }
  }

  .lista-pessoas td.selecionado {
    background-color: #b2f3d3 !important;
  }

  .lista-pessoas input {
    appearance: none;
    height: 14px;
    width: 14px;
    background: #cbd1d8;
    color: #fff;
    border: none;
    margin-right: 7px;
    padding: 0px;
  }
  .lista-pessoas input:hover {
    background: #9faab7;
  }
  .lista-pessoas input:checked {
    background: #3397c4;
  }
  .lista-pessoas input:checked::before {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    content: '✔';
    font-size: 10px;
  }
  .lista-pessoas input:checked::after {
    content: '';
    display: flex;
  }
  .lista-pessoas input {
    border-radius: 50%;
  }
  .lista-pessoas input::after {
    border-radius: 50%;
  }
`;
