/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  EstoqueIndicadorTipoEnum,
  INotaFiscalEntradaImportacaoPedidoCompraEtapa1Lista,
  INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista,
  INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista,
  INotaFiscalEntradaImportacaoPedidoCompraEtapa3Lista,
  ModalidadeFreteEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntradaImportacaoPedidoCompra/Comunicador/NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicador';
import NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntradaImportacaoPedidoCompra/Comunicador/NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntradaImportacaoPedidoCompra/Comunicador/NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicador';
import NotaFiscalEntradaImportacaoPedidoCompraGerarComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntradaImportacaoPedidoCompra/Comunicador/NotaFiscalEntradaImportacaoPedidoCompraGerarComunicador';
import NotaFiscalEntradaImportacaoPedidoCompraEtapa1, {
  INotaFiscalEntradaImportacaoPedidoCompraEtapa1Ref,
} from './Etapa1';
import NotaFiscalEntradaImportacaoPedidoCompraEtapa2, {
  INotaFiscalEntradaImportacaoPedidoCompraEtapa2Ref,
} from './Etapa2';
import NotaFiscalEntradaImportacaoPedidoCompraEtapa3, {
  INotaFiscalEntradaImportacaoPedidoCompraEtapa3Ref,
} from './Etapa3';
import NotaFiscalEntradaImportacaoPedidoCompraImportarComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntradaImportacaoPedidoCompra/Comunicador/NotaFiscalEntradaImportacaoPedidoCompraImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import NotaFiscalEntradaImportacaoPedidoCompraEtapa4, {
  INotaFiscalEntradaImportacaoPedidoCompraEtapa4Ref,
} from './Etapa4';
import NotaFiscalEntradaObterDadosPadraoComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterDadosPadraoComunicador';
import { Sleep } from '../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import NotaFiscalEntradaComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';

export interface IAbrirNotaFiscalEntradaImportacaoPedidoCompra {
  idPessoa: string;
  idPedidoCompra?: string;
  idNotaFiscalEntrada?: string;
}

interface INotaFiscalEntradaImportacaoPedidoCompraContext {
  setTitulo(titulo: string): void;
  abrirNotaFiscalEntradaImportacaoPedidoCompra({
    idPessoa,
    idPedidoCompra,
    idNotaFiscalEntrada,
  }: IAbrirNotaFiscalEntradaImportacaoPedidoCompra): Promise<any>;
  fecharNotaFiscalEntradaImportacaoPedidoCompra(): void;
}

const NotaFiscalEntradaImportacaoPedidoCompraContext =
  createContext<INotaFiscalEntradaImportacaoPedidoCompraContext>(
    {} as INotaFiscalEntradaImportacaoPedidoCompraContext
  );

const NotaFiscalEntradaImportacaoPedidoCompraHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idPedidoCompraOrigem, setIdPedidoCompraOrigem] = useState<
    string | undefined
  >();
  const [idNotaFiscalEntradaOrigem, setIdNotaFiscalEntradaOrigem] = useState<
    string | undefined
  >();
  const [origemPedidoCompra, setOrigemPedidoCompra] = useState(true);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Etapa 1 - Pedido Compras

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref =
    useRef<INotaFiscalEntradaImportacaoPedidoCompraEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(async (idPessoa: string) => {
    try {
      setLoading(true);

      const response =
        await NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraComunicador.index(
          { idPessoa }
        );

      const listaOrdenada = response.listaPedidoCompra.Ordenar([
        { campo: 'numero', tipo: 'desc' },
      ]);

      await formRefEtapa1.current?.setDataInicial({
        listaEtapa1: listaOrdenada,
      });
      etapa1Ref.current?.handleSelecionarTodosEtapa(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 1 - Pedido Compras

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref =
    useRef<INotaFiscalEntradaImportacaoPedidoCompraEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idPedidoCompra?: string) => {
      try {
        setLoading(true);

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as INotaFiscalEntradaImportacaoPedidoCompraEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista[];

        let listaIdPedidoCompraSelecionados: string[] = [];
        if (idPedidoCompra) {
          listaIdPedidoCompraSelecionados.push(idPedidoCompra);
        } else {
          listaIdPedidoCompraSelecionados = listaItemEtapa1.map(
            (item) => item.id
          );
        }

        const response =
          await NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemComunicador.index(
            { listaIdPedidoCompra: listaIdPedidoCompraSelecionados }
          );
        let listaItemEtapa2Novo = response.listaPedidoCompraItem.map(
          (
            item: INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista,
            index: number
          ) => {
            return {
              ...item,
              sequencia: index + 1,
            };
          }
        );

        if (listaItemEtapa2) {
          listaItemEtapa2Novo = listaItemEtapa2Novo.map(
            (
              itemEtapa2Novo: INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista
            ) => {
              const itemExistente = listaItemEtapa2.find(
                (
                  itemEtapa2Atual: INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista
                ) => itemEtapa2Atual.id === itemEtapa2Novo.id
              );

              if (itemExistente) {
                itemExistente.ordem = itemEtapa2Novo.ordem;
                return itemExistente;
              }

              return itemEtapa2Novo;
            }
          );
        }

        const listaOrdenada = listaItemEtapa2Novo.Ordenar([
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicial({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 2 - Itens

  // #region Etapa 3 - Estoques

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref =
    useRef<INotaFiscalEntradaImportacaoPedidoCompraEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalEntradaImportacaoPedidoCompraEtapa2Lista[];
      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaValorSelecionados() as INotaFiscalEntradaImportacaoPedidoCompraEtapa3Lista[];

      const listaItemEtapa3Novo: INotaFiscalEntradaImportacaoPedidoCompraEtapa3Lista[] =
        [];

      for (let index = 0; index < listaItemEtapa2.length; index++) {
        const itemEtapa2 = listaItemEtapa2[index];

        const itemExistente = listaItemEtapa3.find(
          (
            itemEtapa3Atual: INotaFiscalEntradaImportacaoPedidoCompraEtapa3Lista
          ) => itemEtapa3Atual.id === itemEtapa2.id
        );

        if (itemExistente) {
          // Retorna o item da etapa 3 que ja existe ajustando as quantidades
          itemExistente.ordem = itemEtapa2.ordem;
          itemExistente.quantidadeTransferir = itemEtapa2.quantidadeTransferir;
          itemExistente.valorTotal = itemEtapa2.valorTotal;
          itemExistente.modalidadeFrete = itemEtapa2.modalidadeFrete;

          const quantidadeEstoque =
            Number(itemEtapa2.quantidadeTransferir) *
            Number(itemExistente.pedidoCompraItemEstoque.fatorConversao);

          itemExistente.pedidoCompraItemEstoque.quantidadeEstoque =
            quantidadeEstoque;

          if (
            itemExistente.pedidoCompraItemEstoque.listaEstoqueLocalEstoque &&
            itemExistente.pedidoCompraItemEstoque.listaEstoqueLocalEstoque
              .length === 1
          ) {
            itemExistente.pedidoCompraItemEstoque.listaEstoqueLocalEstoque[0].quantidade =
              quantidadeEstoque;
            itemExistente.pedidoCompraItemEstoque.quantidadeTotalEstoqueLocalEstoque =
              quantidadeEstoque;
          }

          if (
            itemExistente.pedidoCompraItemEstoque.estoqueTipoEntradaSaida ===
            TipoMovimentoEntradaSaidaEnum.entrada
          ) {
            const totalEntrada =
              itemExistente.pedidoCompraItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.entrada
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalEntrada || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.pedidoCompraItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          } else {
            const totalSaida =
              itemExistente.pedidoCompraItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.saida
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalSaida || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.pedidoCompraItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          }

          listaItemEtapa3Novo.push(itemExistente);
        } else {
          // Cria um novo item apartir da etapa 2
          const response =
            await NotaFiscalEntradaImportacaoPedidoCompraObterPedidoCompraItemEstoqueComunicador.index(
              {
                dataHoraEmissao: new Date(),
                idPedidoCompraItem: itemEtapa2.id,
                quantidade: itemEtapa2.quantidadeTransferir,
              }
            );

          const retorno: INotaFiscalEntradaImportacaoPedidoCompraEtapa3Lista = {
            id: itemEtapa2.id,
            ordem: itemEtapa2.ordem,
            pessoa: itemEtapa2.pessoa,
            produto: itemEtapa2.produto,
            unidadeMedida: itemEtapa2.unidadeMedida,
            quantidade: itemEtapa2.quantidade,
            quantidadeTransferir: itemEtapa2.quantidadeTransferir,
            valorUnitario: itemEtapa2.valorUnitario,
            valorTotal: itemEtapa2.valorTotal,
            modalidadeFrete: itemEtapa2.modalidadeFrete,

            pedidoCompraItemEstoque: {
              movimentarEstoque: response.produto.estoque.movimentarEstoque,
              unidadeMedida: itemEtapa2.unidadeMedida,
              indicadorTipoEstoque:
                response.produto.estoque.indicadorTipoEstoque,
              fatorConversao: 1,
              quantidadeEstoque: itemEtapa2.quantidadeTransferir,
              quantidadeTotalEstoqueLocalEstoque: 0,
              porContaOrdemTerceiro:
                response.produto.estoque.porContaOrdemTerceiro,

              estoqueTipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              estoqueIndicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              estoqueQuantidade: 0,

              listaEstoqueLocalEstoque: [],
            },
          };

          let estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
          let estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          let estoqueQuantidade = itemEtapa2.quantidadeTransferir;
          let quantidadeTotalEstoqueLocalEstoque = 0;

          const listaEstoqueLocalEstoque: INotaFiscalEntradaImportacaoPedidoCompraEtapa3EstoqueLocalEstoqueLista[] =
            [];

          switch (response.produto.estoque.indicadorTipoEstoque) {
            case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  }
                );
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                  pessoaTerceiro: itemEtapa2.pessoa,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                  pessoaTerceiro: itemEtapa2.pessoa,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa2.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                  pessoaTerceiro: itemEtapa2.pessoa,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa2.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa2.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoa,
                  }
                );
              }
              break;
          }

          retorno.pedidoCompraItemEstoque.listaEstoqueLocalEstoque =
            listaEstoqueLocalEstoque;
          retorno.pedidoCompraItemEstoque.estoqueTipoEntradaSaida =
            estoqueTipoEntradaSaida;
          retorno.pedidoCompraItemEstoque.estoqueIndicadorTipoEstoque =
            estoqueIndicadorTipoEstoque;
          retorno.pedidoCompraItemEstoque.estoqueQuantidade = estoqueQuantidade;
          retorno.pedidoCompraItemEstoque.quantidadeTotalEstoqueLocalEstoque =
            quantidadeTotalEstoqueLocalEstoque;

          listaItemEtapa3Novo.push(retorno);
        }
      }

      const listaOrdenada = listaItemEtapa3Novo.Ordenar([
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 3 - Estoques

  // #region Etapa 4 - Serie

  const formRefEtapa4 = useRef<IFormCiaHandles>(null);
  const etapa4Ref =
    useRef<INotaFiscalEntradaImportacaoPedidoCompraEtapa4Ref>(null);

  const handleCarregarDadosEtapa4 = useCallback(async () => {
    try {
      setLoading(true);

      const valoresEtapa4 = formRefEtapa4.current?.getData() as any;
      if (!valoresEtapa4 || !valoresEtapa4.etapa4?.idSerie) {
        const response =
          await NotaFiscalEntradaObterDadosPadraoComunicador.show();

        await formRefEtapa4.current?.setDataInicial({
          etapa4: {
            serie: response.serie,
          },
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 4 - Serie

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!etapa1Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;

      case 2:
        if (!etapa2Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa3();
        break;

      case 3:
        if (!etapa3Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa4();
        break;
    }
  }, [
    handleCarregarDadosEtapa2,
    handleCarregarDadosEtapa3,
    handleCarregarDadosEtapa4,
    proximoStep,
    step,
  ]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      idPedidoCompra,
      idNotaFiscalEntrada,
    }: IAbrirNotaFiscalEntradaImportacaoPedidoCompra): Promise<any> => {
      setAberto(true);
      setIdPedidoCompraOrigem(idPedidoCompra);
      setIdNotaFiscalEntradaOrigem(idNotaFiscalEntrada);

      if (!idPedidoCompra) {
        setOrigemPedidoCompra(false);
        setStep(1);
        await handleCarregarDadosEtapa1(idPessoa);
      } else {
        setOrigemPedidoCompra(true);
        setStep(2);
        await handleCarregarDadosEtapa2(idPedidoCompra);
      }

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, handleCarregarDadosEtapa2, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFretePedidoCompra = useCallback(
    async (
      valoresEtapa3: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFretePedido: ModalidadeFreteEnum | null = null;
      if (valoresEtapa3) {
        if (valoresEtapa3.listaEtapa3.length > 1) {
          valoresEtapa3.listaEtapa3.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFretePedido = null;
                } else {
                  modalidadeFretePedido = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFretePedido = null;
              }
            }
          });
        } else {
          modalidadeFretePedido = valoresEtapa3.listaEtapa3[0].modalidadeFrete;
        }
      }

      if (!isModalidadeFreteDiferente && modalidadeFretePedido) {
        const notaFiscalEntrada = await NotaFiscalEntradaComunicador.show({
          id: String(idNotaFiscalEntradaOrigem),
        });
        if (
          notaFiscalEntrada.notaFiscalEntradaTransporteTransportadora
            .modalidadeFrete &&
          modalidadeFretePedido !==
            notaFiscalEntrada.notaFiscalEntradaTransporteTransportadora
              .modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade de Frete da Nota Fiscal: ${notaFiscalEntrada.notaFiscalEntradaTransporteTransportadora.modalidadeFrete} e do Pedido ${modalidadeFretePedido}, deseja atualizar a Nota Fiscal para ${modalidadeFretePedido}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idNotaFiscalEntradaOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const valoresEtapa3 = formRefEtapa3.current?.getData();

      let response: any = null;
      if (origemPedidoCompra) {
        if (!etapa4Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const valoresEtapa4 = formRefEtapa4.current?.getData();
        response =
          await NotaFiscalEntradaImportacaoPedidoCompraGerarComunicador.store({
            params: { valoresEtapa3, valoresEtapa4, idPedidoCompraOrigem },
          });
      } else {
        if (!etapa3Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFretePedidoCompra(valoresEtapa3);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção Pedidos com modalidade de frete diferente entre si, favor verificar a modalidade de frete da Nota Fiscal!'
          );
        }

        response =
          await NotaFiscalEntradaImportacaoPedidoCompraImportarComunicador.store(
            {
              params: {
                valoresEtapa3,
                idNotaFiscalEntradaOrigem,
                alterarModalidadeFrete: resposta,
              },
            }
          );
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    origemPedidoCompra,
    idPedidoCompraOrigem,
    handleValidarModalidadeFretePedidoCompra,
    idNotaFiscalEntradaOrigem,
  ]);

  return (
    <NotaFiscalEntradaImportacaoPedidoCompraContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirNotaFiscalEntradaImportacaoPedidoCompra: handleAbrir,
        fecharNotaFiscalEntradaImportacaoPedidoCompra: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Pedidos de Compra</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>

              {origemPedidoCompra && (
                <Step>
                  <StepBox type="button" $selecionado={step === 4}>
                    4
                  </StepBox>
                  <StepNome>Série</StepNome>
                </Step>
              )}
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <NotaFiscalEntradaImportacaoPedidoCompraEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <NotaFiscalEntradaImportacaoPedidoCompraEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <NotaFiscalEntradaImportacaoPedidoCompraEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 4 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa4}>
              <NotaFiscalEntradaImportacaoPedidoCompraEtapa4
                name="etapa4"
                formRef={formRefEtapa4}
                ref={etapa4Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {origemPedidoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handleFechar}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                          }}
                        >
                          <MdCancel />
                          <span style={{ marginLeft: 10 }}>Cancelar</span>
                        </span>
                      </button>
                    )}

                    {!origemPedidoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handlePassoAnterior}
                      >
                        <IoMdReturnLeft />
                        <span style={{ marginLeft: 10 }}>Voltar</span>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    {origemPedidoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleProximoPasso}
                        style={{
                          marginLeft: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ImArrowRight />
                        <span style={{ marginLeft: 10 }}>Próximo passo</span>
                      </button>
                    )}

                    {!origemPedidoCompra && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleSubmit}
                        style={{ marginLeft: 10 }}
                      >
                        <FiRefreshCcw />
                        <span style={{ marginLeft: 10 }}>Concluir</span>
                      </button>
                    )}
                  </div>
                </div>

                <div style={{ display: step === 4 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </NotaFiscalEntradaImportacaoPedidoCompraContext.Provider>
  );
};

function UseNotaFiscalEntradaImportacaoPedidoCompra(): INotaFiscalEntradaImportacaoPedidoCompraContext {
  const context = useContext(NotaFiscalEntradaImportacaoPedidoCompraContext);

  if (!context) {
    throw new Error(
      'UseNotaFiscalEntradaImportacaoPedidoCompra deve ser usado com um PedidoCompraProvider'
    );
  }

  return context;
}

export {
  NotaFiscalEntradaImportacaoPedidoCompraHook,
  UseNotaFiscalEntradaImportacaoPedidoCompra,
};
