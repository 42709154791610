const ExtratoPorProdutoPlanilha = [
  { titulo: 'Código Produto', valor: 'produto.codigo' },
  { titulo: 'Descrição Produto', valor: 'produto.descricao' },
  { titulo: 'Tipo de Estoque', valor: 'tipo' },
  {
    titulo: 'Número Documento',
    valor: 'listaMovimentos.numeroDocumento',
  },
  { titulo: 'Data Movimento', valor: 'listaMovimentos.dataMovimento' },
  {
    titulo: 'Tipo de Movimento',
    valor: 'listaMovimentos.tipoMovimento',
  },
  { titulo: 'Lote', valor: 'listaMovimentos.lote' },
  {
    titulo: 'Quantidade',
    valor: 'listaMovimentos.quantidade',
  },
  { titulo: 'Saldo', valor: 'listaMovimentos.saldo' },
];

export default ExtratoPorProdutoPlanilha;
