import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { GiMoneyStack } from 'react-icons/gi';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UseLiquidacaoContasEmLoteLista } from '../Hooks/LiquidacaoContasEmLoteListaHook';
import FinanceiroDetalhe from './Financeiro/Detalhe';

interface IAcoesContasParcelaReversao {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setRefresh: (value: any) => void;
  possuiItemSelecionado: boolean;
}

const AcoesContasParcelaReversao: React.FC<IAcoesContasParcelaReversao> = ({
  loading,
  setLoading,
  setRefresh,
  possuiItemSelecionado,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { listaValor, listaItemSelecionadoRef, setPossuiItemSelecionado } =
    UseLiquidacaoContasEmLoteLista();

  const [showFinanceiro, setShowFinanceiro] = useState(false);

  const handleLimparListaValor = useCallback(() => {
    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (item) item.checked = false;
    });

    setPossuiItemSelecionado(false);
  }, [listaItemSelecionadoRef, setPossuiItemSelecionado]);

  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];
    if (!possuiItemSelecionado) {
      listaMensagemErro.push(
        `Selecione pelo menos uma Parcela para Realizar a Reversão!`
      );
    } else {
      const listaItensFiltrados = listaValor.filter((it) => it.selecionado);

      const { tipo } = listaItensFiltrados[0].conta;

      listaValor.forEach((item) => {
        if (item.selecionado && item.conta.tipo !== tipo) {
          listaMensagemErro.push(
            `Selecione somente um tipo de contas (Receber/Pagar)`
          );
        }
      });
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      handleLimparListaValor();
      return false;
    }

    return true;
  }, [handleLimparListaValor, listaValor, possuiItemSelecionado]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    setShowFinanceiro(false);
    handleLimparListaValor();
    setRefresh(true);
  }, [handleLimparListaValor, setRefresh]);

  const handleGerarNovaConta = useCallback(async () => {
    try {
      setLoading(true);
      setRefresh(false);
      if (!handleValidar()) {
        setLoading(false);
        return;
      }

      setShowFinanceiro(true);
      handleLimparListaValor();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleLimparListaValor, handleValidar, setLoading, setRefresh]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
    handleLimparListaValor();
    setRefresh(true);
  }, [handleLimparListaValor, setRefresh]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item onClick={handleGerarNovaConta}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Liquidar Selecionadas
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFinanceiro && (
        <FinanceiroDetalhe
          onFecharFormModal={handleFinanceiroFecharFormModal}
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
        />
      )}
    </>
  );
};

export default AcoesContasParcelaReversao;
