import ILogPesquisaParametro from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/transmissao/evento/log';

interface IConhecimentoTransporteTransmissaoEventoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ConhecimentoTransporteTransmissaoEventoLogComunicador {
  public async show(
    params: IConhecimentoTransporteTransmissaoEventoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoLogComunicador();
