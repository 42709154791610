import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/finalidade-uso-cliente-auto-complete';

interface IFinalidadeUsoClienteAutoCompleteComunicadorIndex {
  params: any;
}

class FinalidadeUsoClienteAutoCompleteComunicador {
  public async index(
    params: IFinalidadeUsoClienteAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FinalidadeUsoClienteAutoCompleteComunicador();
