import styled from 'styled-components';

export const TreeContainer = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;

  .toggle-enabled {
    pointer-events: all;
    cursor: pointer;
  }

  .toggle-enabled:hover {
    color: #63b1de;
    fill: #63b1de;
  }

  .box {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .type-styles {
    display: flex;
    align-items: center;
    font-size: 1.3em;
    margin-left: 10px;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #7c7a79;
  }

  .content:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
