import React from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { FrenteCaixaPdvCalculoHook } from './FrenteCaixaPdvCalculoHook';
import { FrenteCaixaPdvHook } from './FrenteCaixaPdvHook';
import RotasHook from './RotasHook';
import { SelecaoItemEstoqueLocalEstoqueHook } from './SelecaoItemEstoqueLocalEstoqueHook';
import { SelecaoItemEstoqueLoteHook } from './SelecaoItemEstoqueLoteHook';
import SubHeaderHook from './SubHeaderHook';
import { SiTefHook } from '../AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import { SelecaoCaixaHook } from './SelecaoCaixaHook';

const FrenteCaixaPdvsHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <SelecaoCaixaHook>
        <SiTefHook>
          <FrenteCaixaPdvHook>
            <FormHook>
              <FrenteCaixaPdvCalculoHook>
                <SelecaoItemEstoqueLocalEstoqueHook>
                  <SelecaoItemEstoqueLoteHook>
                    <SubHeaderHook>{children}</SubHeaderHook>
                  </SelecaoItemEstoqueLoteHook>
                </SelecaoItemEstoqueLocalEstoqueHook>
              </FrenteCaixaPdvCalculoHook>
            </FormHook>
          </FrenteCaixaPdvHook>
        </SiTefHook>
      </SelecaoCaixaHook>
    </RotasHook>
  );
};

export default FrenteCaixaPdvsHook;
