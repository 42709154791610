import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/cancelar';

interface IOrcamentoCancelarComunicadorUpdate {
  id: string;
}

class OrcamentoCancelarComunicador {
  public async update(
    params: IOrcamentoCancelarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoCancelarComunicador();
