import { SituacaoConhecimentoTransporteEnum } from '@elogestor/util';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseButtonsSubHeader } from '../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface ISituacaoBloquearCamposContext {
  situacaoBloquearCampos: boolean;
  setSituacaoBloquearCampos: React.Dispatch<React.SetStateAction<boolean>>;
}

const SituacaoBloquearCamposContext = createContext(
  {} as ISituacaoBloquearCamposContext
);

const SituacaoBloquearCamposHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  const { setDisableExcluir, setDisableSalvar } = UseButtonsSubHeader();

  const [situacaoBloquearCampos, setSituacaoBloquearCampos] = useState(false);

  const situacao = formRef.current?.getFieldValue('situacao');

  useEffect(() => {
    const bloquear =
      situacao === SituacaoConhecimentoTransporteEnum.autorizado ||
      situacao === SituacaoConhecimentoTransporteEnum.denegado ||
      situacao === SituacaoConhecimentoTransporteEnum.cancelado ||
      situacao === SituacaoConhecimentoTransporteEnum.emProcessamento;

    setDisableExcluir(bloquear);
    setDisableSalvar(bloquear);

    if (bloquear) {
      formRef.current?.setDisableAll(true);
      setSituacaoBloquearCampos(true);
    } else {
      setSituacaoBloquearCampos(false);
    }
  }, [formRef, setDisableExcluir, setDisableSalvar, situacao]);

  return (
    <SituacaoBloquearCamposContext.Provider
      value={{
        situacaoBloquearCampos,
        setSituacaoBloquearCampos,
      }}
    >
      {children}
    </SituacaoBloquearCamposContext.Provider>
  );
};

function UseSituacaoBloquearCampos(): ISituacaoBloquearCamposContext {
  const context = useContext(SituacaoBloquearCamposContext);
  return context;
}

export { SituacaoBloquearCamposHook, UseSituacaoBloquearCampos };
