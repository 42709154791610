const NotaFiscalEntradaCfopCsosnCstIcmsPlanilha = [
  { titulo: 'CFOP', valor: 'valores.chaveFormatada' },

  { titulo: 'Numero', valor: 'listaNotaFiscalEntradaItem.numero' },
  {
    titulo: 'Série',
    valor: 'listaNotaFiscalEntradaItem.serie',
  },

  {
    titulo: 'Data de Emissão',
    valor: 'listaNotaFiscalEntradaItem.dataHoraEmissao',
  },

  {
    titulo: 'CNPJ/CPF',
    valor: 'listaNotaFiscalEntradaItem.cnpjCpfVirtual',
  },

  {
    titulo: 'Inscr Est.',
    valor: 'listaNotaFiscalEntradaItem.inscricaoEstadual',
  },

  {
    titulo: 'Destinado',
    valor: 'listaNotaFiscalEntradaItem.destinado',
  },
  {
    titulo: 'CSOSN/CST',
    valor: 'listaNotaFiscalEntradaItem.codigoCsosnCst',
  },

  {
    titulo: 'Val Tot Produtos',
    valor: 'listaNotaFiscalEntradaItem.valorTotalProduto',
  },

  {
    titulo: 'Val Tot Bruto',
    valor: 'listaNotaFiscalEntradaItem.valorTotalBruto',
  },
];

export default NotaFiscalEntradaCfopCsosnCstIcmsPlanilha;
