"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var EspecieDocumentoTituloBoletoEnum;
(function (EspecieDocumentoTituloBoletoEnum) {
    EspecieDocumentoTituloBoletoEnum["duplicataMercantilIndicacao"] = "A";
    EspecieDocumentoTituloBoletoEnum["duplicataRural"] = "B";
    EspecieDocumentoTituloBoletoEnum["notaPromissoria"] = "C";
    EspecieDocumentoTituloBoletoEnum["notaPromissoriaRural"] = "D";
    EspecieDocumentoTituloBoletoEnum["notaSeguros"] = "E";
    EspecieDocumentoTituloBoletoEnum["recibo"] = "G";
    EspecieDocumentoTituloBoletoEnum["letraCambio"] = "H";
    EspecieDocumentoTituloBoletoEnum["notaDebito"] = "I";
    EspecieDocumentoTituloBoletoEnum["duplicataServicoPorIndicacao"] = "J";
    EspecieDocumentoTituloBoletoEnum["outros"] = "K";
    EspecieDocumentoTituloBoletoEnum["boletoProposta"] = "O";
})(EspecieDocumentoTituloBoletoEnum || (EspecieDocumentoTituloBoletoEnum = {}));
exports.default = EspecieDocumentoTituloBoletoEnum;
