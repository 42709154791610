import {
  IOrdemDesmontagemValoresAlterar,
  SituacaoOrdemDesmontagemEnum,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import LocalEstoqueHook from '../ListaDetalheLocalEstoque/Hooks';
import ListaDetalheLocalEstoque from '../ListaDetalheLocalEstoque/Lista';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import ItensTab from '../ItensTab';
import ObservacaoTab from '../ObservacoesTab';
import CustosTab from '../CustosTab';
import EstruturaProdutoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoComunicador';
import { UseOrdemDesmontagem } from '../Hooks/OrdemDesmontagemHook';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<IOrdemDesmontagemValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemDesmontagem: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const [tabSelecionada, setTabSelecionada] = useState('itens');

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();
  const { handleAlterarSituacao } = UseOrdemDesmontagem();
  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data: any = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleOnChangeItemCarregarProdutosFilhos = useCallback(
    async (idProdutoPai: string) => {
      const { listaEstruturaProdutoObtida } =
        await EstruturaProdutoComunicador.show({
          id: idProdutoPai,
        });

      const quantidadeOrdemDesmontagem =
        formRef.current?.getFieldValue('quantidade');
      const listaOrdemDesmontagemItem = formRef.current?.getFieldValue(
        'listaOrdemDesmontagemItem'
      );
      if (
        listaEstruturaProdutoObtida &&
        listaEstruturaProdutoObtida.length > 0
      ) {
        const listaFormatada = listaEstruturaProdutoObtida[0].map(
          (item: any, index: number) => {
            return {
              ...listaOrdemDesmontagemItem[index],
              ordem: index + 1,
              produto: {
                id: item.idProdutoPai,
                codigo: item.codigo,
                descricao: item.descricao,
                produtoEstoque: {
                  controlarLote: item.produtoEstoque.controlarLote,
                },
              },
              unidadeMedida: {
                id: item.produtoEstoque.unidadeMedida.id,
                descricao: item.produtoEstoque.unidadeMedida.descricao,
                sigla: item.produtoEstoque.unidadeMedida.sigla,
              },

              listaOrdemDesmontagemItemLocalEstoque:
                listaOrdemDesmontagemItem[index]
                  ?.listaOrdemDesmontagemItemLocalEstoque ?? [],

              quantidadeEstruturaPorUnidade: item.quantidade,
              quantidadeEstrutura: item.quantidade * quantidadeOrdemDesmontagem,
              quantidadeRetorno: item.quantidade * quantidadeOrdemDesmontagem,
              quantidadeRetornoPorUnidade: item.quantidade,
              desmontado:
                item.tipoProdutoServico !== TipoProdutoServicoEnum.produto,
              dataHoraDesmontagem: new Date(),
            };
          }
        );

        formRef.current?.setFieldValue(
          'listaOrdemDesmontagemItem',
          listaFormatada
        );
      }
    },
    [formRef]
  );

  const handleOnBlurCalcularQuantidade = useCallback(() => {
    const quantidadeOrdemDesmontagem =
      formRef.current?.getFieldValue('quantidade');
    const calcularQuantidadeRetornoItens = formRef.current?.getFieldValue(
      'calcularQuantidadeRetornoItens'
    );

    const listaOrdemDesmontagemItem = formRef.current?.getFieldValue(
      'listaOrdemDesmontagemItem'
    );

    const listaOrdemDesmontagemLocalEstoque = formRef.current?.getFieldValue(
      'listaOrdemDesmontagemLocalEstoque'
    );

    if (
      listaOrdemDesmontagemItem &&
      listaOrdemDesmontagemLocalEstoque.length === 1
    ) {
      listaOrdemDesmontagemLocalEstoque[0].quantidade =
        quantidadeOrdemDesmontagem;
    }

    if (listaOrdemDesmontagemItem && listaOrdemDesmontagemItem.length > 0) {
      for (let i = 0; i < listaOrdemDesmontagemItem.length; i++) {
        const valor = listaOrdemDesmontagemItem[i];
        if (calcularQuantidadeRetornoItens) {
          valor.quantidadeEstrutura =
            valor.quantidadeEstruturaPorUnidade * quantidadeOrdemDesmontagem;
          valor.quantidadeRetorno =
            valor.quantidadeEstruturaPorUnidade * quantidadeOrdemDesmontagem;

          if (
            valor.listaOrdemDesmontagemItemLocalEstoque &&
            valor.listaOrdemDesmontagemItemLocalEstoque.length === 1
          ) {
            valor.listaOrdemDesmontagemItemLocalEstoque[0].quantidade =
              valor.quantidadeRetorno;
          }
        }
      }
    }
  }, [formRef]);

  const handleAlterarSituacaoDesmontagem = useCallback(() => {
    const listaOrdemDesmontagemItem = formRef.current?.getFieldValue(
      'listaOrdemDesmontagemItem'
    );
    const listaOrdemDesmontagemLocalEstoque = formRef.current?.getFieldValue(
      'listaOrdemDesmontagemLocalEstoque'
    );
    const dataHoraInicio = formRef.current?.getFieldValue('dataHoraInicio');
    const dataHoraTermino = formRef.current?.getFieldValue('dataHoraTermino');
    const quantidade = formRef.current?.getFieldValue('quantidade');

    const alterarSituacao = handleAlterarSituacao({
      listaOrdemDesmontagemItem,
      listaOrdemDesmontagemLocalEstoque,
      dataHoraInicio,
      dataHoraTermino,
      quantidade,
    });

    if (alterarSituacao) {
      formRef.current?.setFieldValue(
        'situacao',
        SituacaoOrdemDesmontagemEnum.concluida
      );
    } else {
      formRef.current?.setFieldValue(
        'situacao',
        SituacaoOrdemDesmontagemEnum.emAndamento
      );
    }
  }, [formRef, handleAlterarSituacao]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col xl={1} lg={2} md={2} sm={2}>
              <InputInteiro
                label="Número"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col xl={4} lg={7} md={7} sm={7}>
              <InputDateTime label="Data de Lançamento" name="dataLancamento" />
            </Col>
            <Col xl={2} lg={3} md={3} sm={3}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let emAndamento = false;
                  let concluida = false;

                  if (situacao.includes('Em andamento')) emAndamento = true;
                  if (situacao.includes('Concluída')) concluida = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      {emAndamento && (
                        <span style={{ color: 'black' }}>Em andamento</span>
                      )}
                      {concluida && (
                        <span>
                          <span style={{ color: 'green' }}>Concluída</span>
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={12}>
              <InputAutoCompleteProduto
                name="idProduto"
                ref={inputRefFocus}
                label="Produto a ser Desmontado"
                nomeObjeto="produto"
                controlaEstoque
                placeholder="Produto a ser Desmontado"
                onChangeItemAtual={async (event) => {
                  if (event.itemAtual?.id) {
                    await handleOnChangeItemCarregarProdutosFilhos(
                      event.itemAtual.id
                    );
                  }
                  refresh();
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputDateTime
                label="Data e Hora de Início"
                name="dataHoraInicio"
                onChange={handleAlterarSituacaoDesmontagem}
              />
            </Col>
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputDateTime
                label="Data e Hora de Término"
                name="dataHoraTermino"
                onChange={handleAlterarSituacaoDesmontagem}
              />
            </Col>
            <Col xl={2} lg={6} sm={8}>
              <InputDecimal
                label="Quantidade"
                name="quantidade"
                casasInteiras={15}
                casasDecimais={4}
                onBlur={() => {
                  handleOnBlurCalcularQuantidade();
                  handleAlterarSituacaoDesmontagem();
                  refresh();
                }}
              />
            </Col>

            <Col xl={2} lg={4} sm={4}>
              <InputSwitch
                label="Calcular Quantidade dos Itens"
                name="calcularQuantidadeRetornoItens"
                ativo="Sim"
                inativo="Não"
              />
            </Col>
          </Row>
        </Divisor>

        <InputHiddenHtml name="listaEstoqueMudou" />
        <InputHiddenHtml name="listaItemMudou" />

        <LocalEstoqueHook>
          <ListaDetalheLocalEstoque name="listaOrdemDesmontagemLocalEstoque" />
        </LocalEstoqueHook>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="itens" title="Itens (Resultante do desmonte)">
            <ItensTab />
          </Tab>

          <Tab eventKey="observacoes" title="Observações">
            <ObservacaoTab />
          </Tab>

          <Tab eventKey="custos" title="Custos">
            <CustosTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
