import api from '../../../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/upload-pdf';

interface NotaFiscalEntradaTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate {
  params: any;
}

class NotaFiscalEntradaTransmissaoEventoCartaCorrecaoUploadPdfComunicador {
  public async update(
    params: NotaFiscalEntradaTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoCartaCorrecaoUploadPdfComunicador();
