"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var MesesEnum;
(function (MesesEnum) {
    MesesEnum["janeiro"] = "Janeiro";
    MesesEnum["fevereiro"] = "Fevereiro";
    MesesEnum["marco"] = "Mar\u00E7o";
    MesesEnum["abril"] = "Abril";
    MesesEnum["maio"] = "Maio";
    MesesEnum["junho"] = "Junho";
    MesesEnum["julho"] = "Julho";
    MesesEnum["agosto"] = "Agosto";
    MesesEnum["setembro"] = "Setembro";
    MesesEnum["outubro"] = "Outubro";
    MesesEnum["novembro"] = "Novembro";
    MesesEnum["dezembro"] = "Dezembro";
})(MesesEnum || (MesesEnum = {}));
exports.default = MesesEnum;
