import React, { createContext, useCallback, useContext } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PessoaObterDadosCadastroComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosCadastroComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

export type IParticipante =
  | 'remetente'
  | 'destinatario'
  | 'expedidor'
  | 'recebedor'
  | 'tomadorOutro'
  | 'emitente';

interface IParticipantesContextProps {
  handleAtualizarDadosParticipantesConformeCadastro: (
    participante: IParticipante
  ) => Promise<void>;
}

interface IParticipanteField {
  field: string;
  dadosEndereco: string;
  dadosPessoa: string;
}

const ParticipantesContext = createContext({} as IParticipantesContextProps);

const ParticipantesHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef, setLoading } = UseForm();

  const handleAtualizarDadosParticipantesConformeCadastro = useCallback(
    async (participante: IParticipante): Promise<void> => {
      const fieldParticipantes: Record<IParticipante, IParticipanteField> = {
        remetente: {
          field: 'pessoaRemetente',
          dadosEndereco: 'conhecimentoTransporteTerceiroRemetenteEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroRemetente',
        },
        destinatario: {
          field: 'pessoaDestinatario',
          dadosEndereco: 'conhecimentoTransporteTerceiroDestinatarioEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroDestinatario',
        },
        emitente: {
          field: 'pessoaEmitente',
          dadosEndereco: 'conhecimentoTransporteTerceiroEmitenteEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroEmitente',
        },
        expedidor: {
          field: 'pessoaExpedidor',
          dadosEndereco: 'conhecimentoTransporteTerceiroExpedidorEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroExpedidor',
        },
        recebedor: {
          field: 'pessoaRecebedor',
          dadosEndereco: 'conhecimentoTransporteTerceiroRecebedorEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroRecebedor',
        },
        tomadorOutro: {
          field: 'pessoaTomadorOutro',
          dadosEndereco: 'conhecimentoTransporteTerceiroTomadorOutroEndereco',
          dadosPessoa: 'conhecimentoTransporteTerceiroTomadorOutro',
        },
      };

      const pessoaDados = fieldParticipantes[participante];

      const pessoaObject = formRef.current?.getFieldValueNomeObjeto(
        pessoaDados.field
      );

      if (pessoaObject) {
        setLoading(true);
      }

      try {
        const response = pessoaObject
          ? await PessoaObterDadosCadastroComunicador.show({
              id: pessoaObject.id,
            })
          : null;

        await formRef.current?.setSemExecutarEvento({
          [pessoaDados.dadosPessoa]: {
            cnpjCpfVirtual: response?.cnpjCpfVirtual ?? null,
            inscricaoEstadual: response?.inscricaoEstadual ?? null,
            nomeRazaoSocial: response?.nomeRazaoSocial ?? null,
          },
          [pessoaDados.dadosEndereco]: {
            cep: response?.listaPessoaEndereco[0].cep ?? null,
            cidade: response?.listaPessoaEndereco[0].cidade ?? null,
            logradouro: response?.listaPessoaEndereco[0].logradouro ?? null,
            numero: response?.listaPessoaEndereco[0].numero ?? null,
            bairro: response?.listaPessoaEndereco[0].bairro ?? null,
            complemento: response?.listaPessoaEndereco[0].complemento ?? null,
          },
        });

        // clear nos erros do participante atual
        const errors = formRef.current?.getErrors() as any;
        Object.keys(errors).forEach((key) => {
          if (
            key.includes(pessoaDados.dadosPessoa) ||
            key.includes(pessoaDados.dadosEndereco)
          ) {
            errors[key] = '';
          }
        });
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    },
    [formRef, setLoading]
  );

  return (
    <ParticipantesContext.Provider
      value={{ handleAtualizarDadosParticipantesConformeCadastro }}
    >
      {children}
    </ParticipantesContext.Provider>
  );
};

const UseParticipantes = (): IParticipantesContextProps => {
  const context = useContext(ParticipantesContext);
  return context;
};

export { ParticipantesHook, UseParticipantes };
