import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/impressao/etiqueta';

interface INotaFiscalEntradaEtiquetaImpressaoComunicadorShow {
  id: string;
}

class NotaFiscalEntradaEtiquetaImpressaoComunicador {
  public async show(
    params: INotaFiscalEntradaEtiquetaImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaEtiquetaImpressaoComunicador();
