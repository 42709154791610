import {
  FormatarDataHoraParaPtBr,
  IConhecimentoTransporteTerceiroPorEmitenteRelatorio,
  IConhecimentoTransporteTerceiroPorEmitenteCabecalhoRelatorio,
  FormatarDataParaPtBr,
  IConhecimentoTransporteTerceirosFiltroRelatorioFrontend,
  IConhecimentoTransporteTerceirosFiltroRelatorioBackend,
  StringConverterParaEnum,
  TipoDataConhecimentoTransporteTerceiroEnum,
} from '@elogestor/util';
import React from 'react';
import ConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/ConhecimentoTransporteTerceiros/ConhecimentoTransporteTerceirosPorEmitenteRelatorio/ConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ConhecimentoTransporteTerceiroPorEmitentePDF from './ConhecimentoTransporteTerceirosPorEmitentePDF';
import ConhecimentoTransporteTerceirosPorEmitentePlanilha from './ConhecimentoTransporteTerceirosPorEmitentePlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ConhecimentoTransporteTerceiroPorEmitenteRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IConhecimentoTransporteTerceiroPorEmitenteRelatorio | null> => {
    const filtroBack: IConhecimentoTransporteTerceirosFiltroRelatorioBackend = {
      dataHoraInicial: filtros.dataHoraInicial,
      dataHoraFinal: filtros.dataHoraFinal,
    };

    if (filtros?.tipoData) {
      filtroBack.tipoData = StringConverterParaEnum<
        typeof TipoDataConhecimentoTransporteTerceiroEnum,
        TipoDataConhecimentoTransporteTerceiroEnum
      >(TipoDataConhecimentoTransporteTerceiroEnum, String(filtros.tipoData));
    }

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao =
        filtros.listaSituacao.listaValor.map((value) => value.descricao) || [];
    }

    if (filtros?.listaTomador) {
      filtroBack.listaIdTomador =
        filtros.listaTomador.listaValor.map((value) => value.id) || [];
    }
    if (filtros?.listaEmitente) {
      filtroBack.listaIdEmitente =
        filtros.listaEmitente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRemetente) {
      filtroBack.listaIdRemetente =
        filtros.listaRemetente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaDestinatario) {
      filtroBack.listaIdDestinatario =
        filtros.listaDestinatario.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRecebedor) {
      filtroBack.listaIdRecebedor =
        filtros.listaRecebedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaExpedidor) {
      filtroBack.listaIdExpedidor =
        filtros.listaExpedidor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaNumerosNotas) {
      filtroBack.listaTextoNumerosNotas =
        filtros.listaNumerosNotas.listaValor.map((value) => value.textoTag) ||
        [];
    }

    if (filtros?.listaChavesAcesso) {
      filtroBack.listaTextoChavesAcesso =
        filtros.listaChavesAcesso.listaValor.map((value) => value.textoTag) ||
        [];
    }

    const response =
      await ConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicador.index(
        { params: { filtro: filtroBack } }
      );

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno =
      response as IConhecimentoTransporteTerceiroPorEmitenteRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValor: Number(
            dados.valores.somatorioValor
          ).FormatarParaPtBr(),
        };
        const listaConhecimentoTransporteTerceiros =
          dados.listaConhecimentoTransporteTerceiros.map((transporte) => {
            return {
              ...transporte,
              valorTotal: Number(transporte.valorTotal).FormatarParaPtBr(),
              dataEmissao: FormatarDataParaPtBr(transporte.dataEmissao),
            };
          });

        return {
          ...dados,
          listaConhecimentoTransporteTerceiros,
          valores,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend,
    cabecalho: IConhecimentoTransporteTerceiroPorEmitenteCabecalhoRelatorio
  ): IConhecimentoTransporteTerceiroPorEmitenteCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraInicial || filtros.dataHoraFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataInicial = '--/--/--';
      cabecalho.filtroDataFinal = '--/--/--';

      if (filtros.tipoData) {
        cabecalho.filtroTipoData = filtros.tipoData;
      }

      if (filtros.dataHoraInicial) {
        cabecalho.filtroDataInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraInicial
        );
      }

      if (filtros.dataHoraFinal) {
        cabecalho.filtroDataFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraFinal
        );
      }
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaTomador && filtros.listaTomador.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTomadores = filtros.listaTomador.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (filtros.listaEmitente && filtros.listaEmitente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmitentes = filtros.listaEmitente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRemetente &&
      filtros.listaRemetente.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRemetentes = filtros.listaRemetente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaDestinatario &&
      filtros.listaDestinatario.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroDestinatarios = filtros.listaDestinatario.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRecebedor &&
      filtros.listaRecebedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRecebedores = filtros.listaRecebedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaExpedidor &&
      filtros.listaExpedidor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroExpedidores = filtros.listaExpedidor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaNumerosNotas &&
      filtros.listaNumerosNotas.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroNumerosNotas = filtros.listaNumerosNotas.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    if (
      filtros.listaChavesAcesso &&
      filtros.listaChavesAcesso.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroChavesAcesso = filtros.listaChavesAcesso.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: ConhecimentoTransporteTerceirosPorEmitentePlanilha,
      nomeArquivo: 'ConhecimentoTransporteTerceirosPorEmitente',
      valores: dados.listaDados,
      unwind: ['listaConhecimentoTransporteTerceiros'],
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await Planilha({
      campos: ConhecimentoTransporteTerceirosPorEmitentePlanilha,
      nomeArquivo: 'ConhecimentoTransporteTerceirosPorEmitente',
      valores: dados.listaDados as any,
      unwind: ['listaConhecimentoTransporteTerceiros'],
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ConhecimentoTransporteTerceiroPorEmitentePDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ConhecimentoTransporteTerceiroPorEmitentePDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ConhecimentoTransporteTerceiroPorEmitenteRelatorio();
