import {
  FormatarDataHoraParaPtBr,
  IContasReceberPagarFiltroRelatorioBackend,
  IContasReceberPagarFiltroRelatorioFrontend,
  IContasReceberPagarPorLiquidacaoContabilComJurosCabecalhoRelatorio,
  IContasReceberPagarPorLiquidacaoContabilComJurosRelatorio,
  StringConverterParaEnum,
  TipoContaEnum,
} from '@elogestor/util';
import React from 'react';
import contasReceberPagarPorLiquidacaoContabilComJurosComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/ContasReceberPagar/ContasRecPagParcPorLiquidContabRelJurosComunicador/ContasRecPagParcPorLiquidContabRelComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha from './ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha';
import ContasReceberPagarPorLiquidacaoContabilComJurosPDF from './ContasReceberPagarPorLiquidacaoContabilComJurosPDF';

interface IRelatorios {
  handleExportCSV(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IContasReceberPagarFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: IContasReceberPagarFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<IContasReceberPagarPorLiquidacaoContabilComJurosRelatorio | null> => {
      const filtroBack: IContasReceberPagarFiltroRelatorioBackend = {
        dataHoraEmissaoInicial: filtros.dataHoraEmissaoInicial,
        dataHoraEmissaoFinal: filtros.dataHoraEmissaoFinal,
        dataVencimentoInicial: filtros.dataVencimentoInicial,
        dataVencimentoFinal: filtros.dataVencimentoFinal,
        dataInicial: filtros.dataInicial,
        dataFinal: filtros.dataFinal,
      };

      // if (filtros?.listaSituacaoParcela) {
      //   filtroBack.listaDescricaoSituacaoParcela =
      //     filtros.listaSituacaoParcela.listaValor.map(
      //       (value) => value.descricao
      //     );
      // }

      if (filtros?.listaTiposLancamento) {
        filtroBack.listaTiposLancamento =
          filtros.listaTiposLancamento.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaClienteFornecedor) {
        filtroBack.listaIdClienteFornecedor =
          filtros.listaClienteFornecedor.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaPortadoresParcela) {
        filtroBack.listaIdPortadoresParcela =
          filtros.listaPortadoresParcela.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaPortadoresLiquidacao) {
        filtroBack.listaIdPortadoresLiquidacao =
          filtros.listaPortadoresLiquidacao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaFormasPagamentoParcela) {
        filtroBack.listaIdFormasPagamentoParcela =
          filtros.listaFormasPagamentoParcela.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaFormasPagamentoLiquidacao) {
        filtroBack.listaIdFormasPagamentoLiquidacao =
          filtros.listaFormasPagamentoLiquidacao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaCartoes) {
        filtroBack.listaIdCartoes =
          filtros.listaCartoes.listaValor.map((value: any) => value.id) || [];
      }

      if (filtros?.listaCategorias) {
        filtroBack.listaIdCategorias =
          filtros.listaCategorias.listaValor.map((value: any) => value.id) ||
          [];
      }

      if (filtros?.listaEmpresas) {
        filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
          (value: any) => value.id
        );
      }

      if (filtros?.tipoConta) {
        filtroBack.tipoConta = StringConverterParaEnum<
          typeof TipoContaEnum,
          TipoContaEnum
        >(TipoContaEnum, String(filtros.tipoConta));
      }
      if (filtros?.tipoData) {
        filtroBack.tipoData = filtros.tipoData;
      }

      const response =
        await contasReceberPagarPorLiquidacaoContabilComJurosComunicador.index({
          params: { filtro: filtroBack },
        });
      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as IContasReceberPagarPorLiquidacaoContabilComJurosRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          const valores = {
            ...dados.valores,
            dataLiquidacaoOuContabil: dados.chave,
          };

          return {
            ...dados,
            valores,
            listaConta: dados.listaConta,
          };
        })
      );

      return {
        ...retorno,
        listaDados,

        somatorioGeralValorTotalLiquidado: Number(
          retorno.somatorioGeralValorTotalLiquidado
        ).FormatarParaPtBr(),
      };
    };

    function FormatarCabecalho(
      filtros: IContasReceberPagarFiltroRelatorioFrontend,
      cabecalho: IContasReceberPagarPorLiquidacaoContabilComJurosCabecalhoRelatorio
    ): IContasReceberPagarPorLiquidacaoContabilComJurosCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataInicial || filtros.dataFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataInicial = '--/--/--';
        cabecalho.filtroDataFinal = '--/--/--';

        if (filtros.dataInicial) {
          cabecalho.filtroDataInicial = FormatarDataHoraParaPtBr(
            filtros.dataInicial
          );
        }

        if (filtros.dataFinal) {
          cabecalho.filtroDataFinal = FormatarDataHoraParaPtBr(
            filtros.dataFinal
          );
        }
      }

      if (
        filtros.listaTiposLancamento &&
        filtros.listaTiposLancamento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroTiposSituacoes = filtros.listaTiposLancamento.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (filtros.tipoConta) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroTipoConta = filtros.tipoConta;
      }

      if (filtros.tipoData) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroTipoData = filtros.tipoData;
      }

      if (
        filtros.listaClienteFornecedor &&
        filtros.listaClienteFornecedor.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroClientesFornecedores =
          filtros.listaClienteFornecedor.listaValor
            .map((valor: any) => {
              return valor.nomeRazaoSocialCodigo;
            })
            .join(', ');
      }

      if (
        filtros.listaPortadoresParcela &&
        filtros.listaPortadoresParcela.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroPortadoresParcela =
          filtros.listaPortadoresParcela.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaPortadoresLiquidacao &&
        filtros.listaPortadoresLiquidacao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroPortadoresLiquidacao =
          filtros.listaPortadoresLiquidacao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaFormasPagamentoParcela &&
        filtros.listaFormasPagamentoParcela.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroFormasPagamentoParcela =
          filtros.listaFormasPagamentoParcela.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (
        filtros.listaFormasPagamentoLiquidacao &&
        filtros.listaFormasPagamentoLiquidacao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroFormasPagamentoLiquidacao =
          filtros.listaFormasPagamentoLiquidacao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaCartoes && filtros.listaCartoes.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCartoes = filtros.listaCartoes.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaCategorias &&
        filtros.listaCategorias.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCategorias = filtros.listaCategorias.listaValor
          .map((valor: any) => {
            return valor.codigoDescricao;
          })
          .join(', ');
      }

      if (
        filtros.listaEmpresas &&
        filtros.listaEmpresas.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocial;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha,
        nomeArquivo: 'ContasReceberPagarPorLiquidacaoContabilComJuros',
        valores: dados.listaDados,
        unwind: ['listaConta'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: ContasReceberPagarPorLiquidacaoContabilComJurosPlanilha,
        nomeArquivo: 'ContasReceberPagarPorLiquidacaoContabilComJuros',
        valores: dados.listaDados as any,
        unwind: ['listaConta'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;
      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
      const relatorio = await PDF({
        documento: (
          <ContasReceberPagarPorLiquidacaoContabilComJurosPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: IContasReceberPagarFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <ContasReceberPagarPorLiquidacaoContabilComJurosPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio();
