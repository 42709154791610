import { FormCia } from '@elogestor/unformcia';
import React, { useCallback } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { SituacaoBoletoEnum } from '@elogestor/util';
import Divisor from '../../../../../../../../Componentes/Divisor';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

interface IContaParcelaBoletoDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  situacao?: SituacaoBoletoEnum;
}

const ContaParcelaBoletoDetalhe: React.FC<IContaParcelaBoletoDetalheModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
  situacao,
}) => {
  const { loading, formRefDetalhe, inputRefFocus } = UseListaDetalheForm();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (onSalvarFormModal) onSalvarFormModal();
  }, [onSalvarFormModal]);

  return (
    <>
      <JanelaDetalhe
        titulo="Boletos"
        tamanho="xl"
        onFecharFormModal={onFecharFormModal}
      >
        <LoadingDiv isLoading={loading} isToggleJanela />

        <FormCia ref={formRefDetalhe}>
          <Modal.Body>
            <Container>
              <Divisor>
                <Row>
                  <InputHiddenHtml name="id" />
                  <InputHiddenHtml name="idContaParcela" />

                  <Col lg={12} md={12} sm={12}>
                    <Textarea
                      ref={inputRefFocus}
                      style={{ minHeight: 150 }}
                      label="Mensagem (Texto Livre)"
                      name="descricaoLivre"
                      maxLength={1000}
                      disabled={situacao !== SituacaoBoletoEnum.naoGerado}
                    />
                  </Col>
                </Row>
              </Divisor>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={onFecharFormModal}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <IoMdReturnLeft />
                    <span style={{ marginLeft: 10 }}>Voltar</span>
                  </TextoLoading>
                </button>

                <Button
                  style={{
                    marginLeft: 15,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="btn-padrao btn-verde btn-adicionar"
                  type="button"
                  onClick={handleClickSalvar}
                  disabled={situacao !== SituacaoBoletoEnum.naoGerado}
                >
                  <TextoLoading loading={loading}>
                    <BiSave />
                    <span style={{ marginLeft: 10 }}>Salvar</span>
                  </TextoLoading>
                </Button>
              </Container>
            </div>
          </Modal.Footer>
        </FormCia>
      </JanelaDetalhe>
    </>
  );
};

export default ContaParcelaBoletoDetalhe;
