/* eslint-disable prefer-const */
import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { DefaultInput, SpanErro } from '../../Styles';
import { UseForm } from '../../../Detalhe/Hooks/FormContext';

export interface IOnChangeValueInputTabelaDecimalEvent {
  mudou: boolean;
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnChangeInputTabelaDecimalProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnBlurCiaInputTabelaDecimalEvent {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
  valor: string;
}

export interface IInputTabelaDecimalEvent {
  valor: number;
  valorFormatado: string;
}

export interface IInputTabelaDecimalRef {
  focus(): void;
}

interface IInputTabelaDecimalProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valorPadrao: number;
  valueRef?: React.RefObject<HTMLInputElement>;
  casasDecimais?: number;
  casasInteiras?: number;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTabelaDecimalProps
  ) => void | Promise<void>;
  onChangeValor?: (event: IOnChangeValueInputTabelaDecimalEvent) => void;
  onBlurCia?: (event: IOnBlurCiaInputTabelaDecimalEvent) => void;
  obterRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}

const InputTabelaDecimal: React.ForwardRefRenderFunction<
  IInputTabelaDecimalRef,
  IInputTabelaDecimalProps
> = (
  {
    valorPadrao,
    valueRef,
    casasDecimais = 2,
    casasInteiras = 15,
    error,
    onChange,
    onChangeValor,
    onFocus,
    onBlur,
    onBlurCia,
    obterRef,
    ...rest
  },
  _ref
) => {
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [mudou, setMudou] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(inputRef);
      }
    }
  }, [obterRef, valueRef]);

  useEffect(() => {
    if (valueRef) {
      valorAnteriorOnBlur.current = valueRef?.current?.value || '';
      valorAnteriorOnChange.current = valueRef?.current?.value || '';
    } else {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (valueRef) {
        valorAnteriorOnBlur.current = valueRef?.current?.value || '';
        valorAnteriorOnChange.current = valueRef?.current?.value || '';
      } else {
        valorAnteriorOnBlur.current = inputRef?.current?.value || '';
        valorAnteriorOnChange.current = inputRef?.current?.value || '';
      }

      if (onFocus) onFocus(event);
      const tamanho = event.target.value.length;

      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus, valueRef]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setMudou(true);

      let posicaoDoCursor = event.currentTarget.selectionStart || 0;
      const quantidadeDePontosAntes = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      let valor: string | number = event.target.value
        .replace(/\./g, '')
        .replace(',', '.');

      if (valor.includes(',')) {
        valor = valor.replace(/[^\d.-]/g, '');
        valor = Number(valor).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        event.target.value = valor;
        event.currentTarget.selectionStart = posicaoDoCursor;
        event.currentTarget.selectionEnd = posicaoDoCursor;

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }
        if (onChangeValor) {
          onChangeValor({
            mudou,
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        return;
      }

      valor = valor.replace(/[^\d.-]/g, '');

      if (!valor.includes('.') && valor.length > 1) {
        const val = event.target.value;
        const inicio = val.substr(0, val.length - casasDecimais);
        const fim = val.substr(casasDecimais * -1);

        event.target.value = `${inicio},${fim}`;
        posicaoDoCursor = val.length - casasDecimais;
        event.currentTarget.selectionStart = posicaoDoCursor;
        event.currentTarget.selectionEnd = posicaoDoCursor;

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }
        if (onChangeValor) {
          onChangeValor({
            mudou,
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        return;
      }

      let [inteirosValor, decimaisValor = ''.padEnd(casasDecimais, '0')] =
        String(valor).split('.');

      if (inteirosValor.length > casasInteiras) {
        inteirosValor = inteirosValor.substr(0, casasInteiras);
      }

      valor = Number(
        `${inteirosValor}.${decimaisValor.substr(0, casasDecimais)}`
      );

      valor = valor.FormatarParaPtBr({
        minimoCasasDecimais: casasDecimais,
        maximoCasasDecimais: casasDecimais,
      });

      event.target.value = String(valor);
      const quantidadeDePontosDepois = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      if (valor[posicaoDoCursor - 1] === ',') {
        posicaoDoCursor -= 1;
      } else {
        posicaoDoCursor -= quantidadeDePontosAntes - quantidadeDePontosDepois;
      }

      event.currentTarget.selectionStart = posicaoDoCursor;
      event.currentTarget.selectionEnd = posicaoDoCursor;

      if (onChange) {
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }
      if (onChangeValor) {
        onChangeValor({
          mudou,
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [casasDecimais, casasInteiras, mudou, onChange, onChangeValor]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setMudou(true);
      if (onBlur) onBlur(event);

      if (onBlurCia) {
        onBlurCia({
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
          valor: inputRef.current?.value || '',
        });
      }

      let valor: string | number = event.target.value
        .replace(/\./g, '')
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

      valor = Number(valor).FormatarParaPtBr({
        minimoCasasDecimais: casasDecimais,
        maximoCasasDecimais: casasDecimais,
      });

      event.target.value = valor;
      setIsFilled(!!valor);
      setIsFocused(false);
    },
    [casasDecimais, onBlur, onBlurCia]
  );

  return (
    <div>
      <DefaultInput
        style={{ textAlign: 'right', boxSizing: 'border-box', height: 25 }}
        defaultValue={Number(valorPadrao).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        })}
        type="text"
        ref={valueRef || inputRef}
        $isErrored={!!erroInterno}
        $isFocused={isFocused}
        $isFilled={isFilled}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
    </div>
  );
};

export default forwardRef(InputTabelaDecimal);
