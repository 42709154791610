import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import { INotaFiscalCartaCorrecaoImpressao } from '@elogestor/util';
import CartaCorrecaoImpressaoCabecalhoPDF from './NotaFiscalCartaCorrecaoImpressaoCabecalhoPDF';

const Document: any = _document;
const Page: any = _page;

export interface INotaFiscalCartaCorrecaoImpressaoPDF {
  dados: INotaFiscalCartaCorrecaoImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerAssinatura: {
    flexDirection: 'row',
    height: 35,
    fontSize: 5,
    fontWeight: 'bold',
    justifyContent: 'center',
  },

  headerGrupo: {
    marginTop: '5px',
    fontSize: 6,
    fontWeight: 'bold',
  },
});

const NotaFiscalCartaCorrecaoImpressaoPDF: React.FC<
  INotaFiscalCartaCorrecaoImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            border: '1pt solid black',
            borderRadius: 4,
          }}
        >
          <View style={{ margin: '5px 5px 0 5px', height: '20%' }}>
            <CartaCorrecaoImpressaoCabecalhoPDF dados={dados} />
          </View>
          <View
            style={{
              height: '20%',
              flexDirection: 'column',
              border: '1pt solid black',
              borderRadius: 4,
              margin: '5px 5px 0 5px',
            }}
          >
            <View
              style={{ margin: '8px 0 0 5px', fontSize: 6, fontWeight: 'bold' }}
            >
              <Text>CONDIÇÕES DE USO</Text>
            </View>
            <View style={{ margin: '8px 5px 0 5px', fontSize: 9 }}>
              <Text>{dados.condicaoUsoCartaCorrecao}</Text>
            </View>
          </View>

          <View
            style={{
              height: '60%',
              flexDirection: 'column',
              border: '1pt solid black',
              borderRadius: 4,
              margin: '5px 5px 5px 5px',
            }}
          >
            <View
              style={{ margin: '8px 0 0 5px', fontSize: 6, fontWeight: 'bold' }}
            >
              <Text>CARTA DE CORREÇÃO</Text>
            </View>
            <View style={{ margin: '8px 0 0 5px', fontSize: 9 }}>
              <Text>{dados.descricaoCorrecao}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default NotaFiscalCartaCorrecaoImpressaoPDF;
