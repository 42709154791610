import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-icms/log';

interface IRegraEscolhaCstCreditoIcmsLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRegraEscolhaCstCreditoIcmsLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RegraEscolhaCstCreditoIcmsLogComunicador {
  public async index(
    params: IRegraEscolhaCstCreditoIcmsLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCstCreditoIcmsLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoIcmsLogComunicador();
