import {
  FormatarDataHoraParaPtBr,
  IDocumentosEntradaFiltroRelatorioBackend,
  INotaFiscalEntradaFiltroRelatorioFrontend,
  IDocumentoEntradaPorCFOPCabecalhoRelatorio,
  StringConverterParaEnum,
  IDocumentosEntradaFiltroRelatorioFrontend,
  IDocumentoEntradaPorCFOPRelatorio,
  TipoDataEnum,
  FormatarDataParaPtBr,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import DocumentosEntradaPorCFOPPDF from './DocumentosEntradaPorCFOPPDF';
import DocumentosEntradaPorCFOPPlanilha from './DocumentosEntradaPorCFOPPlanilha';
import DocumentosEntradaPorCfopRelatorioComunicador from '../../../../../../../Comunicador/Suprimentos/Relatorios/Compras/DocumentosEntrada/DocumentosEntradaPorCfopRelatorio/DocumentosEntradaPorCfopRelatorioComunicador';

interface IRelatorios {
  handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
  handleObterBlobPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const DocumentosEntradaPorCfopRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IDocumentosEntradaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IDocumentoEntradaPorCFOPRelatorio | null> => {
    const filtroBack: IDocumentosEntradaFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaFornecedor) {
      filtroBack.listaIdFornecedor =
        filtros.listaFornecedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = StringConverterParaEnum<
        typeof TipoDataEnum,
        TipoDataEnum
      >(TipoDataEnum, String(filtros.tipoData));
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaOperacaoFiscal) {
      filtroBack.listaIdOperacaoFiscal =
        filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
    }

    const response = await DocumentosEntradaPorCfopRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IDocumentoEntradaPorCFOPRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValorTotalProduto: Number(
            dados.valores.somatorioValorTotalProduto
          ).FormatarParaPtBr(),
          somatorioValorTotalIcms: Number(
            dados.valores.somatorioValorTotalIcms
          ).FormatarParaPtBr(),
          somatorioValorTotalIpi: Number(
            dados.valores.somatorioValorTotalIpi
          ).FormatarParaPtBr(),
          somatorioValorTotalSubstuitcaoTributaria: Number(
            dados.valores.somatorioValorTotalSubstuitcaoTributaria
          ).FormatarParaPtBr(),
          somatorioValorTotalValorFrete: Number(
            dados.valores.somatorioValorTotalValorFrete
          ).FormatarParaPtBr(),
          somatorioValorTotalValorDesconto: Number(
            dados.valores.somatorioValorTotalValorDesconto
          ).FormatarParaPtBr(),
          somatorioValorTotalValorOutrasDespesas: Number(
            dados.valores.somatorioValorTotalValorOutrasDespesas
          ).FormatarParaPtBr(),
          somatorioValorTotalValorTotalItem: Number(
            dados.valores.somatorioValorTotalValorTotalItem
          ).FormatarParaPtBr(),
        };

        const listaDocumentosEntrada = dados.listaDocumentosEntrada.map(
          (documentosEntrada) => {
            return {
              ...documentosEntrada,
              dataHora: FormatarDataParaPtBr(documentosEntrada.dataHora),
              valorTotalProduto: Number(
                documentosEntrada.valorTotalProduto
              ).FormatarParaPtBr(),
              valorIcms: Number(documentosEntrada.valorIcms).FormatarParaPtBr(),
              valorIpi: Number(documentosEntrada.valorIpi).FormatarParaPtBr(),
              valorSubstituicaoTributaria: Number(
                documentosEntrada.valorSubstituicaoTributaria
              ).FormatarParaPtBr(),
              valorFrete: Number(
                documentosEntrada.valorFrete
              ).FormatarParaPtBr(),
              valorDesconto: Number(
                documentosEntrada.valorDesconto
              ).FormatarParaPtBr(),
              valorOutrasDespesa: Number(
                documentosEntrada.valorOutrasDespesa
              ).FormatarParaPtBr(),
              valorTotalItem: Number(
                documentosEntrada.valorTotalItem
              ).FormatarParaPtBr(),
            };
          }
        );

        return {
          ...dados,
          valores,
          listaDocumentosEntrada,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IDocumentosEntradaFiltroRelatorioFrontend,
    cabecalho: IDocumentoEntradaPorCFOPCabecalhoRelatorio
  ): IDocumentoEntradaPorCFOPCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }
    if (filtros.tipoData) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoData = filtros.tipoData;
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (
      filtros.listaFornecedor &&
      filtros.listaFornecedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroFornecedor = filtros.listaFornecedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaOperacaoFiscal &&
      filtros.listaOperacaoFiscal.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
        .map((valor: any) => {
          return valor.cfopDescricao;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: DocumentosEntradaPorCFOPPlanilha,
      nomeArquivo: 'NotaFiscalEntradaResumoPorCfop',
      valores: dados.listaDados,
      unwind: ['listaDocumentosEntrada'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);

    if (!dados) return;
    const relatorio = await Planilha({
      campos: DocumentosEntradaPorCFOPPlanilha,
      nomeArquivo: 'NotaFiscalEntradaResumoPorCfopPlanilha',
      valores: dados.listaDados as any,
      unwind: ['listaDocumentosEntrada'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros);

    if (!dados) return undefined;
    const relatorio = await Planilha({
      campos: DocumentosEntradaPorCFOPPlanilha,
      nomeArquivo: 'NotaFiscalEntradaResumoPorCfopPlanilha',
      valores: dados.listaDados as any,
      unwind: ['listaDocumentosEntrada'],
    });

    return relatorio.GetBlob();
  }

  async function handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados: any = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DocumentosEntradaPorCFOPPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados: any = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <DocumentosEntradaPorCFOPPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
    handleObterBlobPlanilha,
  };
};

export default DocumentosEntradaPorCfopRelatorio();
