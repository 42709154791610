import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../../../AutoComplete/AutoCompleteBase';
import CestAutoCompleteComunicador from '../../../../../../Comunicador/Fiscal/Cest/AutoComplete/CestAutoComplete/CestAutoCompleteComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface ICest {
  id: string;
  codigo: string;
  descricao: string;
  associacao: string[];
}

export interface IOnChangeItemAtualAutoCompleteCestEvent {
  itemAtual: ICest | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteCestEvent {
  input: HTMLInputElement | null;
}

interface IInputTabelaAutoCompleteCestProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valueRef?: React.RefObject<IInputAutoCompleteBaseRef>;
  ativo?: boolean;
  idNcmPadrao?: string;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteCestEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteCestEvent
  ) => void | Promise<void>;
  obterRef?: (
    inputRef: React.RefObject<IInputAutoCompleteBaseRef>,
    setIdNcm: React.Dispatch<React.SetStateAction<string>>
  ) => void;
}

export interface IInputTabelaAutoCompleteCestRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
  setIdNcm: (idNcm: string) => void;
}

const InputTabelaAutoCompleteCest: React.ForwardRefRenderFunction<
  IInputTabelaAutoCompleteCestRef,
  IInputTabelaAutoCompleteCestProps
> = (
  {
    valueRef,
    ativo = true,
    idNcmPadrao,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    obterRef,
    ...rest
  },
  ref
) => {
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef>(null);
  const quantidadeAlterado = useRef(0);

  const [pesquisando, setPesquisando] = useState(false);

  const [idNcm, setIdNcm] = useState('');

  useEffect(() => {
    if (idNcmPadrao) {
      setIdNcm(idNcmPadrao);
    }
  }, [idNcmPadrao, obterRef, valueRef]);

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef, setIdNcm);
      } else {
        obterRef(autoCompleteRef, setIdNcm);
      }
    }
  }, [obterRef, valueRef]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });
    },
    [onChangeItemAtual]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,

    setIdNcm: (valor: string) => {
      setIdNcm(valor);
    },
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div
        style={{ width: '100%', display: 'flex' }}
        onKeyDown={(e) => {
          if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
          }
        }}
      >
        <AutoCompleteBase
          ref={valueRef || autoCompleteRef}
          setPesquisando={(valor) => {
            setPesquisando(valor);
          }}
          filtrarItens={async ({ valor, offset, limite }) => {
            setPesquisando(true);

            try {
              const response = await CestAutoCompleteComunicador.index({
                params: {
                  offset,
                  limite,
                  texto: valor,
                  ativo,
                  listaIdParaRemover,
                  idNcm,
                },
              });

              setPesquisando(false);
              return response;
            } catch (err) {
              TratarErros(err);
              setPesquisando(false);
              return false;
            }
          }}
          obterChaveUnica={(item: ICest) => {
            return item.id;
          }}
          obterLabel={(item: ICest) => {
            if (!item.codigo && !item.descricao) return '';

            return `${item.codigo} - ${item.descricao}`;
          }}
          onChangeItemAtual={handleChangeItemAtual}
          onChangeTexto={handleChangeTexto}
          inputProps={rest}
        />
      </div>
    </div>
  );
};

export default forwardRef(InputTabelaAutoCompleteCest);
