import api from '../../../../Comum/Services/Api';

const rota = 'financeiro/boleto/gerar-imprimir';

interface IBoletoGerarImprimirComunicadorUpdate {
  params: { listaIdBoleto: string[] };
}

class BoletoGerarImprimirComunicador {
  public async update(
    params: IBoletoGerarImprimirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new BoletoGerarImprimirComunicador();
