import api from '../../../../../../Comum/Services/Api';

const rota =
  '/financeiro/relatorio/adiantamentos/adiantamentos-por-emissao-com-utilizacao';

interface IAdiantamentosPorEmissaoComUtilizacaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class AdiantamentosPorEmissaoComUtilizacaoRelatorioComunicador {
  public async index(
    params: IAdiantamentosPorEmissaoComUtilizacaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new AdiantamentosPorEmissaoComUtilizacaoRelatorioComunicador();
