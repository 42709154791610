"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FatorVencimento = FatorVencimento;
exports.Modulo11Peso2a9NossoNumero = Modulo11Peso2a9NossoNumero;
/* eslint-disable prefer-const */
const Erros_1 = require("../../Erros");
function FatorVencimento(dataVencimento) {
    const dateBase = new Date(1997, 9, 7);
    const dateDiferenca = dataVencimento.getTime() - dateBase.getTime();
    const totalDias = Math.floor(dateDiferenca / (1000 * 60 * 60 * 24));
    if (totalDias < 0) {
        throw new Erros_1.AppErro({
            mensagem: 'Calculo do Fator de Vencimento Inválido, favor verificar a Data de Vencimento do Titulo!',
        });
    }
    return totalDias;
}
function Modulo11Peso2a9NossoNumero(sequencia) {
    let digito;
    let soma = 0;
    let peso = 2;
    const base = 9;
    for (let i = sequencia.length - 1; i >= 0; i--) {
        const numero = Number(sequencia.charAt(i));
        soma += numero * peso;
        if (peso < base) {
            peso += 1;
        }
        else {
            peso = 2;
        }
    }
    const resto = soma % 11;
    if (resto === 10 || resto === 11) {
        digito = 1;
    }
    else {
        digito = 11 - resto;
    }
    return digito;
}
