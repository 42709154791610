import { Parser, transforms as transform } from 'json2csv';
import DownloadArquivo from '../Arquivos/DownloadArquivo';
import ICampo from './Interface/ICampo';
import IRelatorio from './Interface/IRelatorio';

interface ICSV {
  campos: ICampo[];
  valores: any[];
  unwind?: string[];
  nomeArquivo: string;
}

const GetNestedValue = (obj: any, path: string): any => {
  const value = path
    .split('.')
    .reduce(
      (acc: { [x: string]: any }, part: string | number) => acc && acc[part],
      obj
    );
  return value !== undefined ? value : '';
};

const BooleanToSimNao = (value: any): any => {
  if (typeof value === 'boolean') {
    return value ? 'Sim' : 'Nao';
  }

  return value;
};

export default async function CSV({
  campos,
  valores,
  nomeArquivo,
  unwind = [],
}: ICSV): Promise<IRelatorio> {
  const fields = campos.map(({ titulo, valor }) => ({
    label: titulo,
    value: (row: any) => {
      const fieldValue = GetNestedValue(row, valor);
      return BooleanToSimNao(fieldValue);
    },
  }));

  const transforms = [transform.unwind({ paths: unwind })];

  const parser = new Parser({
    fields,
    transforms,
    delimiter: ';',
    eol: '\r\n',
  });

  const data = parser.parse(valores);

  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
    type: 'text/csv;charset=utf-8',
  });

  return {
    AbrirArquivo: () => {
      DownloadArquivo(blob, `${nomeArquivo}.csv`);
    },
    DownloadArquivo: () => {
      DownloadArquivo(blob, `${nomeArquivo}.csv`);
    },
    GetBlob: () => {
      return blob;
    },
  };
}
