import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import {
  BooleanEnum,
  FormatarEnum,
  ITabelaPrecoFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import Select from '../../../../Componentes/Select';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';
import InputDate from '../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteTagRegraEscolhaTabelaPreco from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRegraEscolhaTabelaPreco';
import { IOnChangeListaItemAtualEvent } from '../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import Input from '../../../../Componentes/Inputs/Input';
import InputAutoCompleteTagProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputCheckbox from '../../../../Componentes/Inputs/InputCheckbox';
import InputInteiroNulavel from '../../../../Componentes/Inputs/InputInteiroNulavel';

interface ITabelaPrecoRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const TabelaPrecoRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const formRef = useRef<IFormCiaHandles>(null);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState(
    'TabelaPrecoProdutosValores'
  );
  const [loading, setLoading] = useState(false);

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as ITabelaPrecoRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as ITabelaPrecoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as ITabelaPrecoRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as ITabelaPrecoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as ITabelaPrecoRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as ITabelaPrecoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      opcaoRelatorio: 'TabelaPrecoProdutosValores',
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      produtosAtivos: true,
    });
  }, []);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Tabela de Preços
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Produtos e Valores',
                        value: 'TabelaPrecoProdutosValores',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.target.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>
              <Col>
                <Row>
                  <Col lg={4} md={6}>
                    <InputDate name="data" label="Data" />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12}>
                    <InputAutoCompleteTagRegraEscolhaTabelaPreco
                      name="listaTabelasPreco"
                      label="Tabela de Preço"
                      filtrarPorRegras={false}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12}>
                    <InputAutoCompleteTagProduto
                      name="listaProduto"
                      label="Produtos"
                      onChangeListaItemAtual={handleChangeProduto}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={3} lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Código"
                      name="codigo"
                      placeholder="Código"
                      maxLength={60}
                    />
                  </Col>

                  <Col xl={3} lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={120}
                    />
                  </Col>

                  <Col xl={3} lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Filtro Codigo/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                    />
                  </Col>
                  <Col xl={3} lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Produtos Ativos"
                      name="produtosAtivos"
                      options={FormatarEnum({
                        enumObj: BooleanEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      name="listaGruposProdutoServico"
                      label="Grupos"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={3} lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputInteiroNulavel
                      label="Núm. NF Terceiro"
                      name="numeroNotaFiscalTerceiro"
                      placeholder="Num. NF Terceiro"
                    />
                  </Col>

                  <Col xl={3} lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Série NF Terceiro"
                      name="serieNotaFiscalTerceiro"
                      placeholder="Série NF Terceiro"
                      maxLength={3}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      name="listaFornecedores"
                      label="Fornecedores"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={6} sm={6} style={{ marginTop: 10 }}>
                    <div className="checkbox-container">
                      <InputCheckbox
                        label="Somente Produtos Com Valor de Venda"
                        name="somenteProdutosComValorDeVenda"
                        onChange={(ev) => {
                          formRef.current?.setFieldDisabled(
                            'somenteProdutosComValorDeVendaZerados',
                            ev.target.checked
                          );
                          formRef.current?.clearField(
                            'somenteProdutosComValorDeVendaZerados'
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={6} sm={6} style={{ marginTop: 10 }}>
                    <div className="checkbox-container">
                      <InputCheckbox
                        label="Somente Produtos Com Valor de Venda Zerado"
                        name="somenteProdutosComValorDeVendaZerados"
                        onChange={(ev) => {
                          formRef.current?.setFieldDisabled(
                            'somenteProdutosComValorDeVenda',
                            ev.target.checked
                          );
                          formRef.current?.clearField(
                            'somenteProdutosComValorDeVenda'
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default TabelaPrecoRelatorio;
