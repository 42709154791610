"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoTipoEnum;
(function (VeiculoTipoEnum) {
    VeiculoTipoEnum["ciclomoto"] = "02 - Ciclomoto";
    VeiculoTipoEnum["motoneta"] = "03 - Motoneta";
    VeiculoTipoEnum["motociclo"] = "04 - Motociclo";
    VeiculoTipoEnum["triciclo"] = "05 - Triciclo";
    VeiculoTipoEnum["automovel"] = "06 - Autom\u00F3vel";
    VeiculoTipoEnum["microonibus"] = "07 - Micro \u00D4nibus";
    VeiculoTipoEnum["onibus"] = "08 - \u00D4nibus";
    VeiculoTipoEnum["reboque"] = "10 - Reboque";
    VeiculoTipoEnum["semirreboque"] = "11 - Semirreboque";
    VeiculoTipoEnum["camioneta"] = "13 - Caminhoneta";
    VeiculoTipoEnum["caminhao"] = "14 - Caminh\u00E3o";
    VeiculoTipoEnum["caminhaoTrator"] = "17 - Caminh\u00E3o Trator";
    VeiculoTipoEnum["tratorRodas"] = "18 - Trator Rodas";
    VeiculoTipoEnum["tratorEsteiras"] = "19 - Trator Esteiras";
    VeiculoTipoEnum["tratorMisto"] = "20 - Trator Misto";
    VeiculoTipoEnum["quadriciclo"] = "21 - Quadriciclo";
    VeiculoTipoEnum["especialOnibus"] = "22 - Esp / \u00D4nibus";
    VeiculoTipoEnum["mistoCam"] = "23 - Misto / Cam";
    VeiculoTipoEnum["cargaCam"] = "24 - Carga / Cam";
    VeiculoTipoEnum["utilitario"] = "25 - Utilit\u00E1rio";
    VeiculoTipoEnum["motorCasa"] = "26 - Motor-Home";
})(VeiculoTipoEnum || (VeiculoTipoEnum = {}));
exports.default = VeiculoTipoEnum;
