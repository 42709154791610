import {
  FormatarCep,
  FormatarCnpjCpf,
  FormatarTelefone,
  IPessoaFiltroRelatorioBackend,
  IPessoaPorEstadoCidadeCabecalhoRelatorio,
  IPessoaPorEstadoCidadeRelatorio,
  IPessoaFiltroRelatorioFrontend,
} from '@elogestor/util';
import React from 'react';
import PessoaPorEstadoCidadeRelatorioComunicador from '../../../../../../Comunicador/Comercial/Relatorios/Pessoas/PessoaPorEstadoCidadeRelatorio/PessoaPorEstadoCidadeRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PessoaPorEstadoCidadePDF from './PessoaPorEstadoCidadePDF';
import PessoaPorEstadoCidadePlanilha from './PessoaPorEstadoCidadePlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PessoaPorEstadoCidadeRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPessoaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPessoaPorEstadoCidadeRelatorio | null> => {
    const filtroBack: IPessoaFiltroRelatorioBackend = {
      mesDiaInicial: filtros.mesDiaInicial,
      mesDiaFinal: filtros.mesDiaFinal,
    };

    if (filtros.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaRepresentante) {
      filtroBack.listaIdRepresentante =
        filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaTags) {
      filtroBack.listaTextoTags =
        filtros.listaTags.listaValor.map((value) => value.textoTag) || [];
    }

    if (filtros?.cliente === 'true' || filtros?.cliente === 'false') {
      filtroBack.cliente = filtros?.cliente === 'true';
    }

    if (filtros?.fornecedor === 'true' || filtros?.fornecedor === 'false') {
      filtroBack.fornecedor = filtros?.fornecedor === 'true';
    }

    if (
      filtros?.transportador === 'true' ||
      filtros?.transportador === 'false'
    ) {
      filtroBack.transportador = filtros?.transportador === 'true';
    }

    if (
      filtros?.representante === 'true' ||
      filtros?.representante === 'false'
    ) {
      filtroBack.representante = filtros?.representante === 'true';
    }

    if (filtros?.produtor === 'true' || filtros?.produtor === 'false') {
      filtroBack.produtor = filtros?.produtor === 'true';
    }

    if (filtros?.motorista === 'true' || filtros?.motorista === 'false') {
      filtroBack.motorista = filtros?.motorista === 'true';
    }

    if (filtros?.tecnico === 'true' || filtros?.tecnico === 'false') {
      filtroBack.tecnico = filtros?.tecnico === 'true';
    }

    if (filtros?.ativo === 'true' || filtros?.ativo === 'false') {
      filtroBack.ativo = filtros?.ativo === 'true';
    }

    const response = await PessoaPorEstadoCidadeRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IPessoaPorEstadoCidadeRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dado) => {
        const listaAgrupadaPorCidade = dado.listaAgrupadaPorCidade.map(
          (cidade) => {
            const listaPessoa = cidade.listaPessoa.map((pessoa) => {
              const listaPessoaContato = pessoa.listaPessoaContato.map(
                (contato) => {
                  return {
                    ...contato,
                    telefone: FormatarTelefone(contato.telefone),
                  };
                }
              );

              const pessoaEnderecoPrincipal = {
                ...pessoa.pessoaEnderecoPrincipal,
                cep: FormatarCep(pessoa.pessoaEnderecoPrincipal.cep),
              };

              return {
                ...pessoa,
                cnpjCpfVirtual: FormatarCnpjCpf(pessoa.cnpjCpfVirtual),
                telefone: FormatarTelefone(pessoa.telefone),
                pessoaEnderecoPrincipal,
                listaPessoaContato,
              };
            });

            return {
              ...cidade,
              listaPessoa,
            };
          }
        );

        return {
          ...dado,
          listaAgrupadaPorCidade,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IPessoaFiltroRelatorioFrontend,
    cabecalho: IPessoaPorEstadoCidadeCabecalhoRelatorio
  ): IPessoaPorEstadoCidadeCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';
    cabecalho.filtroTipoPessoa = '';

    if (filtros.cliente) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoPessoa += 'Cliente';
    }

    if (filtros.fornecedor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Fornecedor';
    }

    if (filtros.transportador) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Transportador';
    }

    if (filtros.representante) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Representante';
    }

    if (filtros.produtor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Produtor';
    }

    if (filtros.motorista) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Motorista';
    }

    if (filtros.tecnico) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa += 'Técnico';
    }

    if (filtros.ativo) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.ativo === 'true' ? 'Ativo: Sim' : 'Ativo: Não';
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaRepresentante &&
      filtros.listaRepresentante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.nomeRazaoSocial}`;
        })
        .join(', ');
    }

    if (filtros.listaTags && filtros.listaTags.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTag = filtros.listaTags.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PessoaPorEstadoCidadePlanilha,
      valores: dados.listaDados,
      nomeArquivo: 'PessoaPorEstado',
      unwind: [
        'listaAgrupadaPorCidade',
        'listaAgrupadaPorCidade.listaPessoa',
        'listaAgrupadaPorCidade.listaPessoa.listaPessoaContato',
      ],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PessoaPorEstadoCidadePlanilha,
      valores: dados.listaDados as any,
      nomeArquivo: 'PessoaPorEstado',
      unwind: [
        'listaAgrupadaPorCidade',
        'listaAgrupadaPorCidade.listaPessoa',
        'listaAgrupadaPorCidade.listaPessoa.listaPessoaContato',
      ],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorEstadoCidadePDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorEstadoCidadePDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PessoaPorEstadoCidadeRelatorio();
