"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoNotaReferenciaEnum;
(function (TipoNotaReferenciaEnum) {
    TipoNotaReferenciaEnum["nfe"] = "NF-e";
    TipoNotaReferenciaEnum["cte"] = "CT-e";
    TipoNotaReferenciaEnum["notaProdutor"] = "Nota de Produtor";
    TipoNotaReferenciaEnum["notaModelo1Aou2"] = "NF Modelo 1/1A ou NF Modelo 2";
})(TipoNotaReferenciaEnum || (TipoNotaReferenciaEnum = {}));
exports.default = TipoNotaReferenciaEnum;
