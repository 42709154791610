import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputTag from '../../../../../../Componentes/Inputs/TagsInput/InputTag';

const EcommerceTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputTag
              label="Ids transportadora Wake"
              name="listaPessoaEmpresa[0].listaPessoaEmpresaEcommerceWake"
              nomeCampo="idTransportadoraWake"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputTag
              label="Ids transportadora Tray"
              name="listaPessoaEmpresa[0].listaPessoaEmpresaEcommerceTray"
              nomeCampo="idTransportadoraTray"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default EcommerceTab;
