/* eslint-disable @typescript-eslint/no-empty-function */
import {
  BooleanEnum,
  FormatarEnum,
  TipoRodadoVeiculoEnum,
  TipoVeiculoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../Componentes/Select';
import { Modal } from './styles';
import Divisor from '../../../../Componentes/Divisor';
import InputAutoCompleteTagEstado from '../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const placa = parametros.pesquisaAvancada?.placa;
      if (placa) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Placa',
          valor: placa,
        });
      }

      const listaEstado = parametros.pesquisaAvancada?.listaEstado;
      if (listaEstado.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Estados',
          valor: listaEstado.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.sigla}; `;
            return acumulador;
          }, ''),
        });
      }

      const renavam = parametros.pesquisaAvancada?.renavam;
      if (renavam) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Renavam',
          valor: renavam,
        });
      }

      const tipoVeiculo = parametros.pesquisaAvancada?.tipoVeiculo;
      if (tipoVeiculo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Veiculo',
          valor: tipoVeiculo,
        });
      }

      const tipoRodado = parametros.pesquisaAvancada?.tipoRodado;
      if (tipoRodado) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Rodado',
          valor: tipoRodado,
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia
            ref={formRef}
            // onPointerEnterCapture={() => {}}
            // onPointerLeaveCapture={() => {}}
            // placeholder=""
          >
            <Row>
              <Col lg={6} md={12}>
                <Input name="placa" label="Placa" type="text" maiuscula />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagEstado
                  label="Estados"
                  name="listaEstado"
                />
              </Col>

              <Col lg={6} md={12}>
                <Input name="renavam" label="Renavam" type="text" />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  label="Tipo de Veículo"
                  name="tipoVeiculo"
                  options={FormatarEnum({
                    enumObj: TipoVeiculoEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Tipo de Rodado"
                  name="tipoRodado"
                  options={FormatarEnum({
                    enumObj: TipoRodadoVeiculoEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
