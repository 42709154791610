import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IMovimentacoesFinanceirasSaldoPorPortadorRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface ISaldoPorPortadorRelatorioPDF {
  dados: IMovimentacoesFinanceirasSaldoPorPortadorRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});
const SaldoPorPortadorPDF: React.FC<ISaldoPorPortadorRelatorioPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroPortadores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Portadores: '}
                      </Text>
                      {dados.cabecalho.filtroPortadores}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPortadorAtivo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Somente Portadores Ativos: '}
                      </Text>
                      {dados.cabecalho.filtroPortadorAtivo === true
                        ? 'Sim'
                        : ''}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomentePortadorComSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Somente Portadores com Saldo: '}
                      </Text>
                      {dados.cabecalho.filtroSomentePortadorComSaldo === true
                        ? 'Sim'
                        : ''}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDataSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Data Saldo: '}
                      </Text>
                      {dados.cabecalho.filtroDataSaldo}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '0.5%' }} />

            <View style={{ width: '49%' }}>
              <Text>Portador</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View
              style={{
                width: '49%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Saldo</Text>
            </View>
            <View style={{ width: '0.5%' }} />
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado: any, dadoIndex: number) => {
                return (
                  <View
                    key={dado.chave}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: dadoIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '0.5%' }} />
                    <View style={{ width: '49%' }}>
                      <Text>{dado.listaSaldoPorPortador[0].portador}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View
                      style={{
                        width: '49%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text>
                        {Number(
                          dado.listaSaldoPorPortador[0].saldo
                        ).FormatarParaPtBr()}
                      </Text>
                    </View>
                    <View style={{ width: '0.5%' }} />
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            />
            <View style={{ width: '70%' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                  marginLeft: 2,
                }}
              >
                Total Geral: {dados.totalGeralPortadores}
              </Text>
            </View>
            <View style={{ width: '2%' }} />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default SaldoPorPortadorPDF;
