import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseConfirmacao } from '../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { NavegacaoProvider } from '../../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao/Hooks/NavegacaoHook';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';
import { UseListaDraggable } from './ListaDraggableHook';

const ItemNavegacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaValor, totalPaginas, setPaginaAtual, paginaAtual } =
    UseListaDraggable();

  const { abrirJanela } = UseConfirmacao();

  const [posicaoNavegacao, setPosicaoNavegacao] = useState(1);
  const [mostrarNavegacao, setMostrarNavegacao] = useState(false);

  const totalNavegacao = useMemo(() => {
    return listaValor.length;
  }, [listaValor.length]);

  const handleSetPosicaoNavegacao = useCallback(
    (novaPosicao: number) => {
      setPaginaAtual(novaPosicao);
    },
    [setPaginaAtual]
  );

  useEffect(() => {
    if (totalPaginas > 1) {
      setMostrarNavegacao(true);
    } else {
      setMostrarNavegacao(false);
    }
  }, [totalPaginas]);

  const handleRecuperarFormulario = useCallback(async (): Promise<boolean> => {
    const possuiItensSelecionados = listaValor.some((item) => item.selecionado);

    if (possuiItensSelecionados) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja cancelar o vínculo dos item do Pedido de Compra?
          </span>
        ),
      });

      if (resposta) {
        return true;
      }

      return false;
    }

    return possuiItensSelecionados;
  }, [abrirJanela, listaValor]);

  const handleAnterior = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }

    if (paginaAtual - 1 <= 1) {
      handleSetPosicaoNavegacao(1);
      return;
    }
    handleSetPosicaoNavegacao(paginaAtual - 1);
  }, [handleRecuperarFormulario, paginaAtual, handleSetPosicaoNavegacao]);

  const handlePrimeiro = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }
    handleSetPosicaoNavegacao(1);
  }, [handleRecuperarFormulario, handleSetPosicaoNavegacao]);

  const handleProximo = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }
    if (paginaAtual + 1 >= totalNavegacao) {
      handleSetPosicaoNavegacao(totalNavegacao);
      return;
    }

    handleSetPosicaoNavegacao(paginaAtual + 1);
  }, [
    handleRecuperarFormulario,
    paginaAtual,
    totalNavegacao,
    handleSetPosicaoNavegacao,
  ]);

  const handleUltimo = useCallback(async () => {
    if (await handleRecuperarFormulario()) {
      return;
    }
    handleSetPosicaoNavegacao(totalNavegacao);
  }, [handleRecuperarFormulario, handleSetPosicaoNavegacao, totalNavegacao]);

  return (
    <NavegacaoProvider
      value={{
        totalNavegacao,
        posicaoNavegacao,
        mostrarNavegacao,
        handleAnterior,
        handlePrimeiro,
        handleProximo,
        handleUltimo,
      }}
    >
      {children}
    </NavegacaoProvider>
  );
};

export { ItemNavegacaoHook };
