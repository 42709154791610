import {
  ICotacaoCompraItemValoresAlterar,
  SituacaoItemEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import CotacaoCompraItemComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Item/Comunicador/CotacaoCompraItemComunicador';
import { IInputAutoCompletePadraoRef } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<any> = ({ children, atualizarCampos }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback(
    (valor: string) => {
      idDetalheRegistro.current = valor;
      refresh();
    },
    [refresh]
  );

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof ICotacaoCompraItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const response = await CotacaoCompraItemComunicador.show({
          id: idEditar,
        });
        await formRefDetalhe.current?.setSemExecutarEvento(response);
        await formRefDetalhe.current?.atualizarDataInicial();
        atualizarCampos && atualizarCampos();

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          situacao: SituacaoItemEnum.pendente,
          quantidadeUnitariaTributado: 1,
          valorFrete: 0,
          valorSeguro: 0,
          valorOutrasDespesas: 0,
          valorDesconto: 0,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      atualizarCampos,
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
    ]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idProduto: Yup.string().nullable().required('Produto é obrigatório!'),
          idUnidadeMedida: Yup.string()
            .nullable()
            .required('Unidade de Medida é obrigatória!'),
          idProdutoTributado: Yup.string()
            .nullable()
            .required('Produto Tributado é obrigatório!'),
          idUnidadeMedidaTributado: Yup.string()
            .nullable()
            .required('Unidade de Medida Tributada é obrigatória!'),
          idTipoNota: Yup.string()
            .nullable()
            .required('Tipo de Nota é obrigatório!'),
          idTipoNotaMotivo: Yup.string()
            .nullable()
            .required('Motivo é obrigatório!'),
          idOperacaoFiscal: Yup.string()
            .nullable()
            .required('Operação Fiscal é obrigatório!'),

          ordem: Yup.number().nullable().required('Ordem é obrigatória!'),
          quantidade: Yup.number()
            .nullable()
            .required('Quantidade é obrigatória!'),
          valorUnitario: Yup.number()
            .nullable()
            .required('Valor Unitário é obrigatório!'),
          valorTotalProduto: Yup.number()
            .nullable()
            .required('Valor Total do Produto é obrigatório!'),
          valorTotalBruto: Yup.number()
            .nullable()
            .required('Valor Total Bruto é obrigatório!'),

          quantidadeUnitariaTributado: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade Unitária Tributada deve ser maior que Zero!',
              test: () => {
                return !(
                  data.quantidade > 0 && data.quantidadeUnitariaTributado === 0
                );
              },
            }),

          quantidadeTributado: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade Tributada deve ser maior que Zero!',
              test: () => {
                return !(data.quantidade > 0 && data.quantidadeTributado === 0);
              },
            }),

          valorUnitarioTributado: Yup.number()
            .nullable()
            .required('Valor Unitário Tributado é obrigatório!'),

          cotacaoCompraItemImpostoIcms: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do ICMS é obrigatória!'),

            idOrigemIcms: Yup.string()
              .nullable()
              .required('Origem do ICMS é obrigatória!'),
          }),

          cotacaoCompraItemImpostoIpi: Yup.object()
            .shape({
              idIpiCodigoEnquadramentoLegal: Yup.mixed()
                .nullable()
                .test({
                  message: 'Código Enquadramento Legal é obrigatória!',
                  test: (value: any) => {
                    if (
                      data.cotacaoCompraItemImpostoIpi.idSituacaoTributaria &&
                      !value
                    ) {
                      return false;
                    }

                    return true;
                  },
                }),
            })
            .nullable(),

          cotacaoCompraItemImpostoPis: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do PIS é obrigatória!'),
          }),

          cotacaoCompraItemImpostoCofins: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do COFINS é obrigatória!'),
          }),
        });
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';
        const idDetalheRegistroPrincipal =
          formPrincipal.getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id, erro: true };
        }

        data.cotacaoCompra = {
          freteFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'freteFormaCalculoPorRateio'
            ),
          seguroFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'seguroFormaCalculoPorRateio'
            ),
          outrasDespesasFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'outrasDespesasFormaCalculoPorRateio'
            ),
          descontoFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'descontoFormaCalculoPorRateio'
            ),
        };

        if (!data.cotacaoCompraItemImpostoIpi.idSituacaoTributaria) {
          data.cotacaoCompraItemImpostoIpi = {
            ...data.cotacaoCompraItemImpostoIpi,
            calcular: false,
            tipoCalculo: null,
            baseCalculo: null,
            aliquota: null,
            quantidadeUnidade: null,
            valorUnidade: null,
            valor: null,
          };
        }

        if (id) {
          await CotacaoCompraItemComunicador.update({ id, params: data });
        } else {
          const response = await CotacaoCompraItemComunicador.store({
            idPai: idDetalheRegistroPrincipal,
            params: data,
          });
          id = response.id;
        }

        formPrincipal.handleCarregarDados();
        formRef?.atualizarDataInicial();
        ToastSucesso('Item Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, getIdDetalheRegistro, handleValidar, redirecionar]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const dataPrincipal = formPrincipal.formRef.current?.getData();
        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;

        await CotacaoCompraItemComunicador.delete({ id });

        ToastSucesso('Item Deletado!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
