import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico-importacao-orcamento/gerar';

interface IOrdemServicoImportacaoOrcamentoGerarComunicadorStore {
  params: any;
}

class OrdemServicoImportacaoOrcamentoGerarComunicador {
  public async store(
    params: IOrdemServicoImportacaoOrcamentoGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new OrdemServicoImportacaoOrcamentoGerarComunicador();
