import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/salvar-nfe-terceiro';

interface IImportacaoXmlTerceiroNfeSalvarImportadoComunicadorStore {
  params: any;
  idXmlNfeImportado: string;
}

class ImportacaoXmlTerceiroNfeSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlTerceiroNfeSalvarImportadoComunicadorStore
  ): Promise<{ id: string; listaMensagem: string[] }> {
    const { idXmlNfeImportado, params: body } = params;

    const response = await api.post(rota, body, {
      params: { idXmlNfeImportado },
    });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeSalvarImportadoComunicador();
