import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/movimentacoes-financeiras-portadores/categorias-por-emissao';

interface ICategoriasPorEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CategoriasPorEmissaoRelatorioComunicador {
  public async index(
    params: ICategoriasPorEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CategoriasPorEmissaoRelatorioComunicador();
