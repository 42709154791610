import api from '../../../../Comum/Services/Api';

const rota = '/conta-email-verificado';

interface IContaEmailComunicadorUpdate {
  id: string;
}

class EmailVerificadoComunicador {
  public async update(params: IContaEmailComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmailVerificadoComunicador();
