/* eslint-disable no-bitwise */
import json2xls from 'json2xls';
import { Unwind as UnwindUtil } from '@elogestor/util';
import dottie from 'dottie';
import DownloadArquivo from '../Arquivos/DownloadArquivo';
import ICampo from './Interface/ICampo';
import IRelatorio from './Interface/IRelatorio';

interface IPlanilha {
  campos: ICampo[];
  valores: Array<{ [key: string]: string | number }>;
  unwind?: string[];
  nomeArquivo: string;
}

function s2ab(s: any): ArrayBuffer {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; i += 1) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

export default async function Planilha({
  campos,
  valores,
  unwind = [],
  nomeArquivo,
}: IPlanilha): Promise<IRelatorio> {
  unwind.forEach((campo) => {
    valores = UnwindUtil(valores, campo);
  });

  const dadosFormatados = valores.map((valor: any) => {
    const valorFormatado: any = {};
    campos.forEach((campo) => {
      valorFormatado[campo.titulo] = dottie.get(valor, campo.valor);
    });

    return valorFormatado;
  });

  const xls = json2xls(dadosFormatados);

  const blob = new Blob([s2ab(xls)], {
    type: 'text/xlsx;charset=utf-8',
  });

  return {
    AbrirArquivo: () => {
      DownloadArquivo(blob, `${nomeArquivo}.xlsx`);
    },
    DownloadArquivo: () => {
      DownloadArquivo(blob, `${nomeArquivo}.xlsx`);
    },
    GetBlob: () => {
      return blob;
    },
  };
}
