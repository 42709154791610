import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';
import ListaDetalheAutorizacao from './ListaDetalheAutorizacao/Lista';
import InputHiddenHtml from '../../../../../Componentes/Inputs/InputHiddenHtml';

const AutorizacaoTab: React.FC = () => {
  return (
    <div>
      <InputHiddenHtml name="transmissao.versaoLayout" />

      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Input
              label="Chave de Acesso"
              name="transmissao.chaveAcesso"
              disabled
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Ambiente"
              name="transmissao.ambiente"
              formatarValor={(ambiente = '') => {
                return (
                  <div style={{ fontWeight: 'bold' }}>
                    <span>{ambiente}</span>
                  </div>
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Protocolo"
              name="transmissao.numeroProtocolo"
              disabled
            />
          </Col>
          <Col xl={3} lg={6} md={6} sm={12}>
            <InputLabel
              label="Data e Hora"
              name="transmissao.dataHoraRetorno"
              formatarValor={(dataHoraRetorno = '') => {
                return (
                  <div style={{ fontWeight: 'bold' }}>
                    <span>
                      {FormatarDataHoraCompletaParaPtBr(dataHoraRetorno)}
                    </span>
                  </div>
                );
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <ListaDetalheAutorizacao name="listaConhecimentoTransporteTransmissaoEvento" />
      </Divisor>
    </div>
  );
};

export default AutorizacaoTab;
