import api from '../../../../../../../Comum/Services/Api';

const rota =
  'financeiro/condicao-pagamento/sugestao/condicao-pagamento-sugestao-auto-complete';

interface ICondicaoPagamentoSugestaoAutoCompleteComunicadorIndex {
  params: any;
}

class CondicaoPagamentoSugestaoAutoCompleteComunicador {
  public async index(
    params: ICondicaoPagamentoSugestaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CondicaoPagamentoSugestaoAutoCompleteComunicador();
