"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoTipoOperacaoEnum;
(function (VeiculoTipoOperacaoEnum) {
    VeiculoTipoOperacaoEnum["vendaConcessionaria"] = "1 - Venda Concession\u00E1ria";
    VeiculoTipoOperacaoEnum["faturamentoDiretoConsumidorFinal"] = "2 - Faturamento Direto para Consumidor Final";
    VeiculoTipoOperacaoEnum["vendaDiretaGrandesConsumidoresFrotista"] = "3 - Venda Direta para Grandes Consumidores (Frotista)";
    VeiculoTipoOperacaoEnum["outros"] = "0 - Outros";
})(VeiculoTipoOperacaoEnum || (VeiculoTipoOperacaoEnum = {}));
exports.default = VeiculoTipoOperacaoEnum;
