/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useState, InputHTMLAttributes } from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { Container } from './styles';
import { SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

interface IOption {
  title: string;
  value: any;
  hidden?: boolean;
}

export interface IOnChangeInputRadioProps {
  valorAnterior: string;
}

interface IInputRadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  options: IOption[];

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputRadioProps
  ) => void | Promise<void>;
}

const InputRadio: React.FC<IInputRadioProps> = ({
  label,
  name,
  options,
  onChange,
  ...rest
}) => {
  const { fieldName, error, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [valorState, setValorState] = useState<string | null>('');
  const [erro, setErro] = useState(error);

  const valorRef = useRef<string | null>('');
  const listaInputRef = useRef<Array<HTMLInputElement | null>>([]);
  const valorAnterior = useRef<string | null>('');

  useEffect(() => {
    valorAnterior.current = valorRef.current;
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(error);
  }, [error]);

  useEffect(() => {
    registerField<string | null>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        if (valorRef.current) {
          return valorRef.current;
        }
        return null;
      },

      setValue(_, novoValor = '') {
        setErro('');
        valorAnterior.current = valorRef.current;
        valorRef.current = novoValor;
        setValorState(novoValor);
      },

      setSemExecutarEvento(_, novoValor = '') {
        setErro('');
        valorAnterior.current = valorRef.current;
        valorRef.current = novoValor;
        setValorState(novoValor);
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        listaInputRef.current.forEach((input) => {
          if (input) {
            input.disabled = valor;
          }
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {label && <label>{label}</label>}
      {options.map((option, index) => {
        const checkboxId = `${fieldName}-${option.value}`;

        return (
          <Container key={checkboxId}>
            <input
              {...rest}
              type="radio"
              id={checkboxId}
              name={fieldName}
              value={option.value}
              checked={option.value === valorState}
              ref={(instance) => {
                listaInputRef.current[index] = instance;
              }}
              onChange={(event) => {
                setValorState(event.target.value);
                valorAnterior.current = valorRef.current;
                valorRef.current = event.target.value;
                if (onChange)
                  onChange(event, {
                    valorAnterior: valorAnterior.current || '',
                  });
              }}
            />
            <label htmlFor={checkboxId}>{option.title}</label>
          </Container>
        );
      })}

      {erro && <SpanErro>{erro}</SpanErro>}
    </>
  );
};

export default InputRadio;
