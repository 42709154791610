import React, { useCallback, useState } from 'react';
import {
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaCategoriaLista,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import RegraEscolhaCategoriaComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Comunicador/RegraEscolhaCategoriaComunicador';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaCategoriaOrdemComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Comunicador/RegraEscolhaCategoriaOrdemComunicador';
import RegraEscolhaCategoriaLogComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/RegraEscolhaCategoria/Log/Comunicador/RegraEscolhaCategoriaLogComunicador';

const RegraEscolhaCategoriaLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaCategoriaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });
        const dados = response.dados[0].map(
          (data: IRegraEscolhaCategoriaLista) => {
            return {
              id: data.id,

              tipoConta: <div className="lista-valor">{data.tipoConta}</div>,

              avista: (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.avista ? 'green' : 'red',
                  }}
                >
                  {data.avista ? 'Sim' : 'Não'}
                </div>
              ),

              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),
              'listaRegraEscolhaCategoriaFormaPagamento.formaPagamento.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCategoriaFormaPagamento
                      ? data.listaRegraEscolhaCategoriaFormaPagamento.map(
                          (valor) => {
                            return (
                              <div key={valor.formaPagamento.descricao}>
                                {`${valor.formaPagamento.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCategoriaSped.spedTipoProduto.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCategoriaSped
                      ? data.listaRegraEscolhaCategoriaSped.map((valor) => {
                          return (
                            <div key={valor.spedTipoProduto.codigo}>
                              {`${valor.spedTipoProduto.codigo} - ${valor.spedTipoProduto.descricao};`}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                ),
              'listaRegraEscolhaCategoriaTipoTransacao.tipoTransacao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCategoriaTipoTransacao
                    ? data.listaRegraEscolhaCategoriaTipoTransacao.map(
                        (valor) => {
                          return (
                            <div key={valor.tipoTransacao}>
                              {`${valor.tipoTransacao};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),
              'listaRegraEscolhaCategoriaTipoLancamento.tipoLancamento': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCategoriaTipoLancamento
                    ? data.listaRegraEscolhaCategoriaTipoLancamento.map(
                        (valor) => {
                          return (
                            <div key={valor.tipoLancamento}>
                              {`${valor.tipoLancamento};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCategoriaOperacaoFiscal.operacaoFiscal.cfop': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCategoriaOperacaoFiscal
                    ? data.listaRegraEscolhaCategoriaOperacaoFiscal.map(
                        (valor) => {
                          return (
                            <div key={valor.operacaoFiscal.cfop}>
                              {valor.operacaoFiscal.cfop}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCategoriaProduto.produto.codigoDescricao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCategoriaProduto
                    ? data.listaRegraEscolhaCategoriaProduto.map((valor) => {
                        return (
                          <div key={valor.produto.codigo}>
                            {`${valor.produto.codigo} - ${valor.produto.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCategoriaGrupoProdutoServico.grupoProdutoServico.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCategoriaGrupoProdutoServico
                      ? data.listaRegraEscolhaCategoriaGrupoProdutoServico.map(
                          (valor) => {
                            return (
                              <div key={valor.grupoProdutoServico.descricao}>
                                {`${valor.grupoProdutoServico.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCategoriaModeloDocumento.modeloDocumento.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCategoriaModeloDocumento
                      ? data.listaRegraEscolhaCategoriaModeloDocumento.map(
                          (valor) => {
                            return (
                              <div key={valor.modeloDocumento.codigo}>
                                {`${valor.modeloDocumento.codigo} - ${valor.modeloDocumento.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCategoriaPessoa.pessoa.codigoNomeRazaoSocial': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCategoriaPessoa
                    ? data.listaRegraEscolhaCategoriaPessoa.map((valor) => {
                        return (
                          <div key={valor.pessoa.codigo}>
                            {`${valor.pessoa.codigo} - ${valor.pessoa.nomeRazaoSocial};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCategoriaRamoAtividadeProfissao.ramoAtividadeProfissao.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCategoriaRamoAtividadeProfissao
                      ? data.listaRegraEscolhaCategoriaRamoAtividadeProfissao.map(
                          (valor) => {
                            return (
                              <div key={valor.ramoAtividadeProfissao.descricao}>
                                {`${valor.ramoAtividadeProfissao.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'regraEscolhaCategoriaResultado.categoria.codigoDescricao': (
                <div className="lista-texto">
                  {data.regraEscolhaCategoriaResultado?.categoria
                    ? `${data.regraEscolhaCategoriaResultado?.categoria.codigo} - ${data.regraEscolhaCategoriaResultado.categoria.descricao}`
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCategoriaEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaCategoriaEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaCategoriaEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaCategoriaComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaCategoriaLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.FinanceiroCategoriaDreRegraEscolhaCategoria
                        ?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.FinanceiroCategoriaDreRegraEscolhaCategoria]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'financeiroCategoriaDreRegraEscolhaCategoria' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaCategoriaOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha de Categoria"
          permissao={permissoes.FinanceiroCategoriaDreRegraEscolhaCategoria}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaCategoriaLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaCategoriaLista;
