import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  INotaFiscalSaidaTotalPorModeloDocumentoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface INotaFiscalSaidaTotalPorModeloDocumentoPDF {
  dados: INotaFiscalSaidaTotalPorModeloDocumentoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const NotaFiscalSaidaTotalPorModeloDocumentoPDF: React.FC<
  INotaFiscalSaidaTotalPorModeloDocumentoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroOperacaoFiscal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Operacao Fiscal: '}
                      </Text>
                      {dados.cabecalho.filtroOperacaoFiscal}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSerie && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Série: '}</Text>
                      {dados.cabecalho.filtroSerie}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomaFaturamento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Soma Faturamento: '}
                      </Text>
                      {dados.cabecalho.filtroSomaFaturamento}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroDevolucoes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Devolucoes: '}
                      </Text>
                      {dados.cabecalho.filtroDevolucoes}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>Dt. Emissão</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '15%' }}>
              <Text>Cliente</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%' }}>
              <Text>Cfops</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Subtotal</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Frete</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Seguro</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Out. Desp.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Desconto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Valor Ipi</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Valor ST.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Valor Total</Text>
            </View>
          </View>

          {dados.listaDados.map((dado, indexDado) => {
            return (
              <View key={dado.chave}>
                <View
                  style={{
                    borderTop: indexDado !== 0 ? '1pt dashed black' : '',
                  }}
                  key={String(indexDado)}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginLeft: 2,
                      fontSize: 9,
                      width: '50%',
                    }}
                  >
                    Modelo Documento: {dado.valores.chaveFormatada}
                  </Text>
                </View>

                {dado.listaNotaFiscalSaidaItem.map(
                  (notaFiscalSaidaItem, notaFiscalSaidaItemIndex) => {
                    return (
                      <View
                        key={String(notaFiscalSaidaItemIndex)}
                        style={{
                          flexDirection: 'row',
                          backgroundColor:
                            notaFiscalSaidaItemIndex % 2 === 0
                              ? '#E4E4E4'
                              : '#fff',
                        }}
                      >
                        <View fixed style={{ flexDirection: 'row' }}>
                          <View style={{ width: '5%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.notaFiscalSaidaNumero}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%' }}>
                            <Text>
                              {
                                notaFiscalSaidaItem.notaFiscalSaidaDataHoraEmissao
                              }
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '15%' }}>
                            <Text>
                              {`${notaFiscalSaidaItem.pessoaCodigo} - ${notaFiscalSaidaItem.pessoaNomeRazaoSocial}`}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '6%' }}>
                            <Text>{notaFiscalSaidaItem.cfops}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorSubtotal}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorFrete}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorSeguro}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorOutrasDespesas}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorDesconto}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>{notaFiscalSaidaItem.somatorioValorIpi}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>{notaFiscalSaidaItem.somatorioValorSt}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '7%', alignItems: 'flex-end' }}>
                            <Text>
                              {notaFiscalSaidaItem.somatorioValorTotal}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  }
                )}

                <View
                  style={{
                    flexDirection: 'row',
                    fontWeight: 'bold',
                    marginBottom: 5,
                    fontSize: 9,
                  }}
                >
                  <View style={{ width: '36 %' }}>
                    <Text style={{ marginLeft: 5 }}>
                      Total do Modelo Documento: {dado.valores.total}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorSubtotal}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorFrete}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorSeguro}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorOutrasDespesas}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorDesconto}
                    </Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioModeloDocumentoValorIpi}</Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioModeloDocumentoValorSt}</Text>
                  </View>

                  <View style={{ width: '8%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioModeloDocumentoValorTotal}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '36%' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                  marginLeft: 2,
                }}
              >
                Total Geral: {dados.totalGeral}
              </Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorSubtotal}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorFrete}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorSeguro}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorOutrasDespesas}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorDesconto}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorIpi}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorSt}</Text>
            </View>

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>{dados.somatorioGeralValorTotal}</Text>
            </View>
          </View>

          <View style={{ borderBottom: '1pt solid black' }} />
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default NotaFiscalSaidaTotalPorModeloDocumentoPDF;
