/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { TipoAdiantamentoEnum, TipoContaEnum } from '@elogestor/util';
import AutoCompleteTagBase, {
  IFiltrarItens,
  IInputAutoCompleteTagBaseRef,
  IOnAdicionarEvent,
  IOnChangeListaItemAtualEvent,
  IOnChangeTextoEvent,
  IOnMoverEvent,
  IOnRemoverEvent,
} from '../../AutoCompleteTag/AutoCompleteTagBase';
import UseRegisterAutoCompleteTag from '../../AutoCompleteTag/AutoCompleteTagBase/Hooks/UseRegisterAutoCompleteTag';
import ContaTipoAdiantamentoEnumAutoCompleteComunicador from '../../../../Comunicador/Financeiro/Movimentacoes/Conta/AutoComplete/ContaTipoAdiantamentoEnumAutoComplete/ContaTipoAdiantamentoEnumAutoCompleteComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import { Item } from '../../AutoCompleteTag/AutoCompleteTagBase/styles';
import ContaTipoAdiantamentoEnumAutoCompleteTagComunicador from '../../../../Comunicador/Financeiro/Movimentacoes/Conta/AutoCompleteTag/ContaTipoAdiantamentoEnumAutoCompleteTag/ContaTipoAdiantamentoEnumAutoCompleteTagComunicador';

interface IOnChange {
  valorAnterior: any;
}

export interface IContaTipoAdiantamento {
  id: string;
  numeroDocumento: string;
  tipoAdiantamento: TipoAdiantamentoEnum;
  pessoa?: {
    id: string;
    nomeRazaoSocial: string;
  };
  valorDisponivelAdiantamento: number;
  valorUtilizadoAdiantamento: number;
  tipo: TipoContaEnum | null;
}

export interface IOnChangeItemAtualAutoCompleteTagContaTipoAdiantamentoEvent {
  itemAtual: IContaTipoAdiantamento | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteTagContaTipoAdiantamentoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteTagContaTipoAdiantamentoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  listaIdParaRemover?: string[];
  listaIdParcelaParaRemover?: string[];
  listaAdiantamentoUtilizado?: Array<{
    idContaAdiantamento: string;
    valor: number;
  }>;
  listaTipoContaAdiantamento?: TipoContaEnum[];
  idPessoa?: string;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualEvent,
    props: IOnChange
  ) => void;
  onChangeListaItemAtualAposCarregarSemClear?: (
    evento: IOnChangeListaItemAtualEvent
  ) => void;
  onChangeTexto?: (
    evento: IOnChangeTextoAutoCompleteTagContaTipoAdiantamentoEvent
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarEvent) => void;
  onRemoverItem?: (evento: IOnRemoverEvent) => void;
  onMoverItem?: (evento: IOnMoverEvent) => void;
}

interface IInputAutoCompleteTagContaTipoAdiantamentoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteTagBaseRef>;
}

const InputAutoCompleteTagContaTipoAdiantamentoEnum: React.ForwardRefRenderFunction<
  IInputAutoCompleteTagContaTipoAdiantamentoRef,
  IInputAutoCompleteTagContaTipoAdiantamentoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaIdParaRemover = [],
    listaIdParcelaParaRemover = [],
    listaAdiantamentoUtilizado = [],
    listaTipoContaAdiantamento = [],
    idPessoa,
    onChangeListaItemAtual,
    onChangeListaItemAtualAposCarregarSemClear,
    onChangeTexto,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const handleObterChave = useCallback((item: IContaTipoAdiantamento) => {
    return item.id;
  }, []);

  const {
    autoCompleteRef,
    flags,
    error: erroUnform,
  } = UseRegisterAutoCompleteTag<IContaTipoAdiantamento | null>(
    {
      nome: name,
      handleObterChaveUnica: handleObterChave,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;
        // autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );

  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleObterLabel = useCallback((item: IContaTipoAdiantamento) => {
    if (!item.tipoAdiantamento) return '';

    let retorno = item.numeroDocumento;
    if (item.pessoa && item.pessoa.nomeRazaoSocial) {
      retorno += ` - ${item.pessoa.nomeRazaoSocial}`;
    }

    retorno += ` - ${item.tipoAdiantamento}`;

    return retorno;
  }, []);

  const handleChangeListaItemAtual = useCallback(
    (evento: IOnChangeListaItemAtualEvent, { valorAnterior }: IOnChange) => {
      quantidadeAlterado.current++;
      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(evento, { valorAnterior });
      }
      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeListaItemAtualAposCarregarSemClear
      ) {
        onChangeListaItemAtualAposCarregarSemClear(evento);
      }
    },
    [flags, onChangeListaItemAtual, onChangeListaItemAtualAposCarregarSemClear]
  );

  const handleFiltrarItens = useCallback(
    async ({ valor, offset, limite, listaValorAtual }: IFiltrarItens) => {
      setPesquisando(true);

      try {
        const response =
          await ContaTipoAdiantamentoEnumAutoCompleteTagComunicador.index({
            params: {
              offset,
              limite,
              texto: valor,
              listaIdParaRemover: [
                ...listaIdParaRemover,
                ...listaValorAtual.map(
                  (valorAtual: IContaTipoAdiantamento) => valorAtual.id
                ),
              ],
              listaIdParcelaParaRemover,
              listaAdiantamentoUtilizado,
              listaTipoContaAdiantamento,
              idPessoa,
            },
          });

        return response;
      } catch (err) {
        TratarErros(err);
        return false;
      }
    },
    [
      idPessoa,
      listaAdiantamentoUtilizado,
      listaIdParaRemover,
      listaIdParcelaParaRemover,
      listaTipoContaAdiantamento,
    ]
  );

  useEffect(() => {
    if (rest.disabled) {
      autoCompleteRef.current?.setDisabled(true);
    } else {
      autoCompleteRef.current?.setDisabled(false);
    }
  }, [autoCompleteRef, erroUnform, rest, rest.disabled]);

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteTagBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={handleFiltrarItens}
            obterChaveUnica={handleObterChave}
            obterLabel={handleObterLabel}
            onChangeListaItemAtual={handleChangeListaItemAtual}
            onChangeTexto={handleChangeTexto}
            {...rest}
            personalizarItem={({ navegacao, item }) => {
              let identificacao = '';
              if (item.tipoAdiantamento) {
                identificacao = item.numeroDocumento;
                if (item.pessoa && item.pessoa.nomeRazaoSocial) {
                  identificacao += ` - ${item.pessoa.nomeRazaoSocial}`;
                }

                identificacao += ` - ${item.tipoAdiantamento}`;
              }

              return (
                <Item $navegacao={navegacao}>
                  <strong>{identificacao}</strong>
                  <span>
                    {identificacao
                      ? ` ⬌ Saldo: ${Number(
                          item.valorDisponivelAdiantamento
                        ).FormatarParaPtBr()}`
                      : ''}
                  </span>
                  <br />
                  <span>{item.associacao}</span>
                </Item>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteTagContaTipoAdiantamentoEnum);
