import React from 'react';
import EmpresaSubstitutoTributarioHook from './ListaDetalheSubstitutoTributario/Hooks';
import ListaDetalheSubstitutoTributario from './ListaDetalheSubstitutoTributario/Lista';
import Divisor from '../../../../../Componentes/Divisor';

const SubstitutoTributarioTab: React.FC = () => {
  return (
    <Divisor>
      <EmpresaSubstitutoTributarioHook>
        <ListaDetalheSubstitutoTributario name="listaEmpresaSubstitutoTributario" />
      </EmpresaSubstitutoTributarioHook>
    </Divisor>
  );
};

export default SubstitutoTributarioTab;
