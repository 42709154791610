import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const RelatorioContasReceberPagarTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relContasReceberParcelaPorEmissao"
                label="Parcelas Por Emissão (Receber)"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relContasPagarParcelaPorEmissao"
                label="Parcelas Por Emissão (Pagar)"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RelatorioContasReceberPagarTab;
