import { IFormCiaHandles } from '@elogestor/unformcia';
import React from 'react';

export function AtualizarImpostoFiscalConformeImpostoDocumento(
  formRef: React.RefObject<IFormCiaHandles>
): void {
  const impostoIcmsFiscalPath =
    'conhecimentoTransporteTerceiroImpostoIcmsFiscal';

  const impostoIcmsPath = 'conhecimentoTransporteTerceiroImpostoIcms';

  formRef.current?.setFieldValue(
    `${impostoIcmsFiscalPath}.percentualReducaoBaseCalculo`,
    formRef.current?.getFieldValue(
      `${impostoIcmsPath}.percentualReducaoBaseCalculo`
    )
  );

  formRef.current?.setFieldValorInicial(
    `${impostoIcmsFiscalPath}.baseCalculo`,
    formRef.current?.getFieldValue(`${impostoIcmsPath}.baseCalculo`)
  );

  formRef.current?.setFieldValue(
    `${impostoIcmsFiscalPath}.aliquota`,
    formRef.current?.getFieldValue(`${impostoIcmsPath}.aliquota`)
  );

  formRef.current?.setFieldValue(
    `${impostoIcmsFiscalPath}.valor`,
    formRef.current?.getFieldValue(`${impostoIcmsPath}.valor`)
  );
  formRef.current?.setFieldValue(
    `${impostoIcmsFiscalPath}.destinado`,
    formRef.current?.getFieldValue(`${impostoIcmsPath}.destinado`)
  );
}
