import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IContaParcelaValoresInserir } from '@elogestor/util';
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { useParams } from 'react-router-dom';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IParametroRotaDetalhe from '../../../../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import ParcelaDetalhe from '../Detalhe';
import { UseContaListaParcelas } from '../Hook/ContaListaParcelasHook';

interface IListaDetalheProps {
  name: string;
}

const ParcelaLista: React.FC<IListaDetalheProps> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();

  const isAdiantamentoAntecipacao =
    formPrincipal.formRef.current?.getFieldValue('isAdiantamentoAntecipacao');

  const revertida = formPrincipal.formRef.current?.getFieldValue('revertida');

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const { telaPequena } = UseReactSizeMeBodyHook();
  const { formRefLista, setIdDetalheRegistro, handleSubmit, refresh } =
    UseListaDetalheForm();
  const params: IParametroRotaDetalhe = useParams();

  const valorRef = useRef<IContaParcelaValoresInserir[]>([]);

  const { listaValor, setListaValor } = UseContaListaParcelas();

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IContaParcelaValoresInserir[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getDataDuplicar();

    await handleSubmit(data, formRefLista.current);
  }, [formRefLista, handleSubmit]);

  const handleObterIndexParcela = useCallback(async () => {
    const idOrcamento =
      formPrincipal.formRef.current?.getFieldValue('idOrcamento');
    const idPedidoVenda =
      formPrincipal.formRef.current?.getFieldValue('idPedidoVenda');
    const idNotaFiscalSaida =
      formPrincipal.formRef.current?.getFieldValue('idNotaFiscalSaida');
    const idNotaFiscalEntrada = formPrincipal.formRef.current?.getFieldValue(
      'idNotaFiscalEntrada'
    );
    const idConhecimentoTransporteTerceiro =
      formPrincipal.formRef.current?.getFieldValue(
        'idConhecimentoTransporteTerceiro'
      );
    const idConhecimentoTransporte =
      formPrincipal.formRef.current?.getFieldValue('idConhecimentoTransporte');
    const idEditar = formPrincipal.getIdDetalheRegistro();

    let indexParcela = listaValor.findIndex(
      (item) => item.id === params.id || item.id === idEditar
    );

    if (
      indexParcela < 0 &&
      (idOrcamento ||
        idPedidoVenda ||
        idNotaFiscalSaida ||
        idNotaFiscalEntrada ||
        idConhecimentoTransporteTerceiro ||
        idConhecimentoTransporte)
    ) {
      indexParcela = 0;
    }
    setIdDetalheRegistro(String(indexParcela));
    refresh();
  }, [formPrincipal, listaValor, params.id, refresh, setIdDetalheRegistro]);
  useEffect(() => {
    if (!params.id) {
      setIdDetalheRegistro('0');
    } else {
      handleObterIndexParcela();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaValor]);

  return (
    <FormCia ref={formRefLista}>
      <Divisor>
        <Row>
          <Col
            xl={3}
            lg={4}
            md={4}
            style={{ display: 'flex', alignItems: 'flex-end', minWidth: 250 }}
          >
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionar}
              className="btn-padrao btn-verde-claro btn-adicionar"
              disabled={
                !permissao?.inclui || formPrincipal.loading || revertida
              }
            >
              <TextoLoading loading={formPrincipal.loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar Parcela</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
          <Col>
            <JanelaNavegacao
              containerStyle={
                telaPequena
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: 10,
                    }
                  : {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      marginBottom: 10,
                    }
              }
            />
          </Col>
        </Row>

        <ParcelaDetalhe bloquearCamposReversao={revertida} />
      </Divisor>
    </FormCia>
  );
};

export default ParcelaLista;
