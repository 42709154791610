import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ImpressaoEtiquetasProdutosComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Comunicador/ImpressaoEtiquetasProdutosComunicador';
import FormHook from './FormHook';
import { ImpressaoEtiquetaHook } from './ImpressaoEtiquetaHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const ImpressaoEtiquetasProdutosHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: ImpressaoEtiquetasProdutosComunicador }}
    >
      <RotasHook>
        <ImpressaoEtiquetaHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </ImpressaoEtiquetaHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default ImpressaoEtiquetasProdutosHook;
