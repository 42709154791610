import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-ipi';

interface IRegraEscolhaCstCreditoIpiComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaCstCreditoIpiComunicadorShow {
  id: string;
}

interface IRegraEscolhaCstCreditoIpiComunicadorStore {
  params: any;
}

interface IRegraEscolhaCstCreditoIpiComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaCstCreditoIpiComunicadorDeletar {
  id: string;
}

class RegraEscolhaCstCreditoIpiComunicador {
  public async index(
    params: IRegraEscolhaCstCreditoIpiComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCstCreditoIpiComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaCstCreditoIpiComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaCstCreditoIpiComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaCstCreditoIpiComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoIpiComunicador();
