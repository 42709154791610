/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputHiddenProps {
  valorAnterior: string;
}

export interface IOnChangeInputHiddenEvent<T = any> {
  valor: T;
}

interface IInputProps {
  name: string;

  onChange?: (
    event: IOnChangeInputHiddenEvent,
    props: IOnChangeInputHiddenProps
  ) => void;
  getValue?: ({ valor }: IOnChangeInputHiddenEvent) => void;
  getDataDuplicar?: ({ valor }: IOnChangeInputHiddenEvent) => void;
  getDataRecuperarFormulario?: ({ valor }: IOnChangeInputHiddenEvent) => void;
  setValue?: (valor: any) => any;
  validarSeMudou?: (ref: any, valorInicial: any) => boolean;
}

const InputHidden = ({
  name,
  onChange,
  getValue,
  getDataDuplicar,
  getDataRecuperarFormulario,
  setValue,
  validarSeMudou,
}: IInputProps): any => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [valorRetorno, setValorRetorno] = useState<any>();

  const valorRef = useRef<any>();
  const valorAnterior = useRef('');

  useEffect(() => {
    valorAnterior.current = valorRef.current;
  }, [terminouCarregarDados]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: valorRef,

      getValue() {
        if (getValue) {
          return getValue(valorRef.current);
        }

        return valorRef.current;
      },

      getDataDuplicar() {
        if (getDataDuplicar) {
          return getDataDuplicar(valorRef.current);
        }

        if (getValue) {
          return getValue(valorRef.current);
        }

        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        if (getDataRecuperarFormulario) {
          return getDataRecuperarFormulario(valorRef.current);
        }

        if (getValue) {
          return getValue(valorRef.current);
        }
        return valorRef.current;
      },

      setValue(_: any, valor: any) {
        if (setValue) {
          valor = setValue(valor);
        }
        valorAnterior.current = valorRef.current;
        valorRef.current = valor;
        setValorRetorno(valor);
        onChange &&
          onChange({ valor }, { valorAnterior: valorAnterior.current });
      },

      setSemExecutarEvento(_: any, valor: any) {
        if (setValue) {
          valor = setValue(valor);
        }

        valorAnterior.current = valorRef.current;
        valorRef.current = valor;
        setValorRetorno(valor);
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial) {
        if (validarSeMudou) return validarSeMudou(_, valorInicial);

        return this.getValue(_) !== valorInicial;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return valorRetorno;
};

export default InputHidden;
