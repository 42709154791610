"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoProprietarioVeiculoEnum;
(function (TipoProprietarioVeiculoEnum) {
    TipoProprietarioVeiculoEnum["tacAgregado"] = "0 - TAC Agregado";
    TipoProprietarioVeiculoEnum["tacIndependente"] = "1 - TAC Independente";
    TipoProprietarioVeiculoEnum["outros"] = "2 - Outros";
})(TipoProprietarioVeiculoEnum || (TipoProprietarioVeiculoEnum = {}));
exports.default = TipoProprietarioVeiculoEnum;
