import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/ramo-atividade-profissao-auto-complete';

interface IRamoAtividadeProfissaoAutoCompleteComunicadorIndex {
  params: any;
}

class RamoAtividadeProfissaoAutoCompleteComunicador {
  public async index(
    params: IRamoAtividadeProfissaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RamoAtividadeProfissaoAutoCompleteComunicador();
