import React from 'react';
import { ListaProvider } from '../../../../../Componentes/Lista/ListaHooks';
import { TableDraggableProvider } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ListaDraggableHook } from './ListaDraggableHook';

const ConsultaEstoqueListaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <TableDraggableProvider>
      <ListaProvider>
        <ListaDraggableHook>{children}</ListaDraggableHook>
      </ListaProvider>
    </TableDraggableProvider>
  );
};

export default ConsultaEstoqueListaHook;
