"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModalidadeBaseCalculoIcmsStEnum;
(function (ModalidadeBaseCalculoIcmsStEnum) {
    ModalidadeBaseCalculoIcmsStEnum["pre\u00E7oTabeladoM\u00E1ximoSugerido"] = "0 - Pre\u00E7o Tabelado ou M\u00E1ximo Sugerido";
    ModalidadeBaseCalculoIcmsStEnum["listaNegativa"] = "1 - Lista Negativa (valor)";
    ModalidadeBaseCalculoIcmsStEnum["listaPositiva"] = "2 - Lista Positiva (valor)";
    ModalidadeBaseCalculoIcmsStEnum["listaNeutra"] = "3 - Lista Neutra (valor)";
    ModalidadeBaseCalculoIcmsStEnum["margemValorAgregado"] = "4 - Margem Valor Agregado (%)";
    ModalidadeBaseCalculoIcmsStEnum["pauta"] = "5 - Pauta (valor)";
    ModalidadeBaseCalculoIcmsStEnum["valorOperacao"] = "6 - Valor Opera\u00E7\u00E3o";
})(ModalidadeBaseCalculoIcmsStEnum || (ModalidadeBaseCalculoIcmsStEnum = {}));
exports.default = ModalidadeBaseCalculoIcmsStEnum;
