import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Container, Button, Row, Col } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { UseFrenteCaixaPdvCalculo } from '../../../Hooks/FrenteCaixaPdvCalculoHook';
import InputDate from '../../../../../../../../Componentes/Inputs/InputDate';
import FrenteCaixaPdvGerarOrcamentoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarOrcamentoComunicador';

interface IGerarOrcamentoDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const GerarOrcamentoDetalhe: React.FC<IGerarOrcamentoDetalheModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  loading,
  setLoading,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);

  const { formRef: formRefPrincipal } = UseForm();

  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const { listaItem } = UseFrenteCaixaPdv();

  const { liberar } = UseFrenteCaixaPdvCalculo();

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      formRef.current?.setDataInicial({});

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGerarOrcamento = useCallback(async () => {
    try {
      setLoading(true);

      const dadosFormPrincipal = formRefPrincipal.current?.getData() as any;
      const dadosDetalhe = formRef.current?.getData() as any;

      const dados = {
        ...dadosFormPrincipal,
        dataPrevistaFaturamento: dadosDetalhe.data,
        listaItem,
      };

      const response = await FrenteCaixaPdvGerarOrcamentoComunicador.store({
        params: dados,
      });

      ToastSucesso(response.mensagem);

      await liberar();

      if (response) {
        window.open(`/comercial/vendas/orcamento/detalhe/${response.id}`);

        ToastSucesso(response.mensagem);
      }

      if (onSalvarFormModal) onSalvarFormModal();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRefPrincipal, liberar, listaItem, onSalvarFormModal, setLoading]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, formRef, onFecharFormModal]);

  return (
    <JanelaDetalhe
      titulo="Gerar Orçamento"
      tamanho="lg"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={12} md={6} sm={12}>
                <InputDate name="data" label="Data Prevista de Faturamento" />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleGerarOrcamento}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default GerarOrcamentoDetalhe;
