/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  IReprocessamentoProducaoValoresAlterar,
  SituacaoReprocessamentoProducaoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import ItensTab from '../ItensTab';
import ObservacaoTab from '../ObservacoesTab';
import CustosRetiradaTab from '../CustosRetiradaTab';
import CustosRetornoTab from '../CustosRetornoTab';
import EstruturaProdutoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoComunicador';
import { UseReprocessamentoProducao } from '../Hooks/ReprocessamentoProducaoHook';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import RoteiroTab from '../RoteiroTab';
import LocalEstoqueOrigemHook from '../ListaDetalheLocalEstoqueOrigem/Hooks';
import ListaDetalheLocalEstoqueOrigem from '../ListaDetalheLocalEstoqueOrigem/Lista';
import LocalEstoqueDestinoHook from '../ListaDetalheLocalEstoqueDestino/Hooks';
import ListaDetalheLocalEstoqueDestino from '../ListaDetalheLocalEstoqueDestino/Lista';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';

type IProps = IMain<IReprocessamentoProducaoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoReprocessamentoProducao: permissao } = permissoes;
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();
  const rotas = UseRota();

  const [tabSelecionada, setTabSelecionada] = useState('itens');

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();
  const { handleAlterarSituacao } = UseReprocessamentoProducao();
  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data: any = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleOnChangeItemCarregarProdutosFilhos = useCallback(
    async (idProdutoPai: string) => {
      const { listaEstruturaProdutoObtida } =
        await EstruturaProdutoComunicador.show({
          id: idProdutoPai,
        });

      const quantidadeReprocessamentoProducao =
        formRef.current?.getFieldValue('quantidadeRetirada');
      const listaReprocessamentoProducaoItem = formRef.current?.getFieldValue(
        'listaReprocessamentoProducaoItem'
      );
      if (
        listaEstruturaProdutoObtida &&
        listaEstruturaProdutoObtida.length > 0
      ) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja utilizar a Estrutura de Produto Cadastrada?
            </span>
          ),
        });

        if (resposta) {
          const listaFormatada = listaEstruturaProdutoObtida[0].map(
            (item: any, index: number) => {
              return {
                ...listaReprocessamentoProducaoItem[index],
                ordem: index + 1,
                produto: {
                  id: item.idProdutoPai,
                  codigo: item.codigo,
                  descricao: item?.descricao,
                  produtoEstoque: {
                    controlarLote: item.produtoEstoque.controlarLote,
                  },
                },
                unidadeMedida: {
                  id: item.produtoEstoque.unidadeMedida.id,
                  descricao: item.produtoEstoque.unidadeMedida?.descricao,
                  sigla: item.produtoEstoque.unidadeMedida.sigla,
                },
                listaReprocessamentoProducaoItemLocalEstoque:
                  listaReprocessamentoProducaoItem[index]
                    ?.listaReprocessamentoProducaoItemLocalEstoque ?? [],

                quantidade: item.quantidade * quantidadeReprocessamentoProducao,
                quantidadePorUnidade: item.quantidade,
                consumido: false,
                dataHoraProducao: new Date(),
              };
            }
          );

          formRef.current?.setFieldValue(
            'listaReprocessamentoProducaoItem',
            listaFormatada
          );
        }
      }
    },
    [abrirJanela, formRef]
  );

  const handleOnBlurCalcularQuantidadeDestino = useCallback(() => {
    const quantidade = formRef.current?.getFieldValue('quantidadeRetorno');

    const listaReprocessamentoProducaoLocalEstoqueDestino =
      formRef.current?.getFieldValue(
        'listaReprocessamentoProducaoLocalEstoqueDestino'
      );

    if (
      listaReprocessamentoProducaoLocalEstoqueDestino &&
      listaReprocessamentoProducaoLocalEstoqueDestino.length === 1
    ) {
      listaReprocessamentoProducaoLocalEstoqueDestino[0].quantidade =
        quantidade;

      refresh();
    }
  }, [formRef, refresh]);

  const handleOnBlurCalcularQuantidadeOrigem = useCallback(() => {
    const quantidade = formRef.current?.getFieldValue('quantidadeRetirada');

    const listaReprocessamentoProducaoLocalEstoqueOrigem =
      formRef.current?.getFieldValue(
        'listaReprocessamentoProducaoLocalEstoqueOrigem'
      );

    if (
      listaReprocessamentoProducaoLocalEstoqueOrigem &&
      listaReprocessamentoProducaoLocalEstoqueOrigem.length === 1
    ) {
      listaReprocessamentoProducaoLocalEstoqueOrigem[0].quantidade = quantidade;

      refresh();
    }
  }, [formRef, refresh]);

  const handleAlterarSituacaoProducao = useCallback(() => {
    const listaReprocessamentoProducaoItem = formRef.current?.getFieldValue(
      'listaReprocessamentoProducaoItem'
    );
    const listaReprocessamentoProducaoLocalEstoqueOrigem =
      formRef.current?.getFieldValue(
        'listaReprocessamentoProducaoLocalEstoqueOrigem'
      );

    const listaReprocessamentoProducaoLocalEstoqueDestino =
      formRef.current?.getFieldValue(
        'listaReprocessamentoProducaoLocalEstoqueDestino'
      );
    const dataHoraRetirada = formRef.current?.getFieldValue('dataHoraRetirada');
    const dataHoraRetorno = formRef.current?.getFieldValue('dataHoraRetorno');
    const quantidadeRetorno =
      formRef.current?.getFieldValue('quantidadeRetorno');
    const quantidadeRetirada =
      formRef.current?.getFieldValue('quantidadeRetirada');

    const alterarSituacao = handleAlterarSituacao({
      listaReprocessamentoProducaoItem,
      listaReprocessamentoProducaoLocalEstoqueOrigem,
      listaReprocessamentoProducaoLocalEstoqueDestino,
      dataHoraRetirada,
      dataHoraRetorno,
      quantidadeRetorno,
      quantidadeRetirada,
    });

    if (alterarSituacao) {
      formRef.current?.setFieldValue(
        'situacao',
        SituacaoReprocessamentoProducaoEnum.concluido
      );
    } else {
      formRef.current?.setFieldValue(
        'situacao',
        SituacaoReprocessamentoProducaoEnum.emAndamento
      );
    }
  }, [formRef, handleAlterarSituacao]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col xl={1} lg={2} md={2} sm={2}>
              <InputInteiro
                label="Número"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col xl={4} lg={7} md={7} sm={7}>
              <InputDateTime label="Data de Lançamento" name="dataLancamento" />
            </Col>
            <Col xl={2} lg={3} md={3} sm={3}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let emAndamento = false;
                  let concluido = false;

                  if (situacao.includes('Em andamento')) emAndamento = true;
                  if (situacao.includes('Concluído')) concluido = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      {emAndamento && (
                        <span style={{ color: 'black' }}>Em andamento</span>
                      )}
                      {concluido && (
                        <span>
                          <span style={{ color: 'green' }}>Concluída</span>
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={12}>
              <InputAutoCompleteProduto
                name="idProduto"
                ref={inputRefFocus}
                label="Produto a ser Reprocessado"
                nomeObjeto="produto"
                controlaEstoque
                placeholder="Produto a ser Reprocessado"
                onChangeItemAtual={async (event) => {
                  if (event.itemAtual?.id) {
                    await handleOnChangeItemCarregarProdutosFilhos(
                      event.itemAtual.id
                    );
                  }
                  refresh();
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <InputHiddenHtml name="listaEstoqueMudou" />
        <InputHiddenHtml name="listaItemMudou" />

        <Divisor>
          <Row>
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputDateTime
                label="Data de Retirada (-) Origem"
                name="dataHoraRetirada"
                onChange={handleAlterarSituacaoProducao}
              />
            </Col>
            <Col xl={3} lg={6} md={6} sm={6}>
              <InputDecimal
                label="Quantidade (-) Origem"
                name="quantidadeRetirada"
                onChange={handleAlterarSituacaoProducao}
                onBlur={() => handleOnBlurCalcularQuantidadeOrigem()}
                casasInteiras={15}
                casasDecimais={4}
              />
            </Col>
          </Row>

          <Row>
            <LocalEstoqueOrigemHook>
              <ListaDetalheLocalEstoqueOrigem name="listaReprocessamentoProducaoLocalEstoqueOrigem" />
            </LocalEstoqueOrigemHook>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col xl={4} lg={6} md={6} sm={6}>
              <InputDateTime
                label="Data de Retorno (+) Destino"
                name="dataHoraRetorno"
                onChange={handleAlterarSituacaoProducao}
              />
            </Col>
            <Col xl={3} lg={6} md={6} sm={6}>
              <InputDecimal
                label="Quantidade (+) Destino"
                name="quantidadeRetorno"
                casasInteiras={15}
                casasDecimais={4}
                onBlur={handleOnBlurCalcularQuantidadeDestino}
                onChange={handleAlterarSituacaoProducao}
              />
            </Col>
          </Row>

          <Row>
            <LocalEstoqueDestinoHook>
              <ListaDetalheLocalEstoqueDestino name="listaReprocessamentoProducaoLocalEstoqueDestino" />
            </LocalEstoqueDestinoHook>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="itens" title="Itens (Consumidos)">
            <ItensTab />
          </Tab>

          <Tab eventKey="observacoes" title="Observações">
            <ObservacaoTab />
          </Tab>

          <Tab eventKey="custosRetirada" title="Custos (Retirada)">
            <CustosRetiradaTab />
          </Tab>

          <Tab eventKey="custosRetorno" title="Custos (Retorno)">
            <CustosRetornoTab />
          </Tab>

          <Tab eventKey="roteiro" title="Roteiro">
            <RoteiroTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
