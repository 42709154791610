const ResumoDiariaPrevistoRealizadoPlanilha = [
  {
    titulo: 'Data',
    valor: 'data',
  },
  {
    titulo: 'Receber Previsto',
    valor: 'receberPrevisto',
  },
  {
    titulo: 'Pagar Previsto',
    valor: 'pagarPrevisto',
  },
  {
    titulo: 'Receber Realizado',
    valor: 'receberRealizado',
  },
  {
    titulo: 'Pagar Realizado',
    valor: 'pagarRealizado',
  },
  {
    titulo: 'Adiantamento Recebido',
    valor: 'adiantamentoRecebido',
  },
  {
    titulo: 'Adiantamento Pago',
    valor: 'adiantamentoPago',
  },
  {
    titulo: 'Transferencia Recebida',
    valor: 'transferenciaRecebida',
  },
  {
    titulo: 'Transferencia Paga',
    valor: 'transferenciaPaga',
  },
  {
    titulo: 'Saldo Previsto',
    valor: 'saldoPrevisto',
  },
  {
    titulo: 'Saldo Realizado',
    valor: 'saldoRealizado',
  },
];

export default ResumoDiariaPrevistoRealizadoPlanilha;
