import api from '../../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa/cliente';

interface IPessoaClienteRepresentanteComunicadorStore {
  idDetalheRegistroPrincipal: string;
  params: any;
}

interface IPessoaClienteRepresentanteComunicadorDelete {
  id: string;
}

class PessoaClienteRepresentanteComunicador {
  public async store(
    params: IPessoaClienteRepresentanteComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idDetalheRegistroPrincipal}/representante`,
      params.params
    );

    return response.data;
  }

  public async delete(
    params: IPessoaClienteRepresentanteComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/representante/${params.id}`);

    return response.data;
  }
}

export default new PessoaClienteRepresentanteComunicador();
