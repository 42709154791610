import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/marca-veiculo-auto-complete';

interface IMarcaVeiculoAutoCompleteComunicadorIndex {
  params: any;
}

class MarcaVeiculoAutoCompleteComunicador {
  public async index(
    params: IMarcaVeiculoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new MarcaVeiculoAutoCompleteComunicador();
