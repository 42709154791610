import { FinalidadeEmissaoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-serie';

interface INotaFiscalSaidaObterSerieComunicadorShow {
  finalidadeEmissao: FinalidadeEmissaoEnum;
}

class NotaFiscalSaidaObterSerieComunicador {
  public async show(
    params: INotaFiscalSaidaObterSerieComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });

    return response.data;
  }
}

export default new NotaFiscalSaidaObterSerieComunicador();
