/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  EstoqueIndicadorTipoEnum,
  INotaFiscalSaidaImportacaoPedidoVendaEtapa1Lista,
  INotaFiscalSaidaImportacaoPedidoVendaEtapa2Lista,
  INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista,
  ModalidadeFreteEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoPedidoVenda/Comunicador/NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicador';
import NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoPedidoVenda/Comunicador/NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoPedidoVenda/Comunicador/NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicador';
import NotaFiscalSaidaImportacaoPedidoVendaGerarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoPedidoVenda/Comunicador/NotaFiscalSaidaImportacaoPedidoVendaGerarComunicador';
import NotaFiscalSaidaImportacaoPedidoVendaEtapa1, {
  INotaFiscalSaidaImportacaoPedidoVendaEtapa1Ref,
} from './Etapa1';
import NotaFiscalSaidaImportacaoPedidoVendaEtapa2, {
  INotaFiscalSaidaImportacaoPedidoVendaEtapa2Ref,
} from './Etapa2';
import NotaFiscalSaidaImportacaoPedidoVendaEtapa3, {
  INotaFiscalSaidaImportacaoPedidoVendaEtapa3Ref,
} from './Etapa3';
import NotaFiscalSaidaImportacaoPedidoVendaImportarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoPedidoVenda/Comunicador/NotaFiscalSaidaImportacaoPedidoVendaImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import NotaFiscalSaidaImportacaoPedidoVendaEtapa4, {
  INotaFiscalSaidaImportacaoPedidoVendaEtapa4Ref,
} from './Etapa4';
import NotaFiscalSaidaObterDadosPadraoComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterDadosPadraoComunicador';
import { Sleep } from '../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import TratarInfo from '../../../../Util/Info/TratarInfo';
import PedidoVendaComunicador from '../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import NotaFiscalSaidaComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';

export interface IAbrirNotaFiscalSaidaImportacaoPedidoVenda {
  idPessoa: string;
  idPedidoVenda?: string;
  idNotaFiscalSaida?: string;
}

interface INotaFiscalSaidaImportacaoPedidoVendaContext {
  setTitulo(titulo: string): void;
  abrirNotaFiscalSaidaImportacaoPedidoVenda({
    idPessoa,
    idPedidoVenda,
    idNotaFiscalSaida,
  }: IAbrirNotaFiscalSaidaImportacaoPedidoVenda): Promise<any>;
  fecharNotaFiscalSaidaImportacaoPedidoVenda(): void;
}

const NotaFiscalSaidaImportacaoPedidoVendaContext =
  createContext<INotaFiscalSaidaImportacaoPedidoVendaContext>(
    {} as INotaFiscalSaidaImportacaoPedidoVendaContext
  );

const NotaFiscalSaidaImportacaoPedidoVendaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idPedidoVendaOrigem, setIdPedidoVendaOrigem] = useState<
    string | undefined
  >();
  const [idNotaFiscalSaidaOrigem, setIdNotaFiscalSaidaOrigem] = useState<
    string | undefined
  >();
  const [origemPedidoVenda, setOrigemPedidoVenda] = useState(true);
  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);
  // #region Etapa 1 - Pedido Vendas

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref =
    useRef<INotaFiscalSaidaImportacaoPedidoVendaEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(
    async (idPessoa: string, idNotaFiscalSaida?: string) => {
      try {
        setLoading(true);

        const response =
          await NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicador.index(
            { idPessoa, idNotaFiscalSaida }
          );

        const listaOrdenada = response.listaPedidoVenda.Ordenar([
          { campo: 'numero', tipo: 'desc' },
        ]);

        await formRefEtapa1.current?.setDataInicial({
          listaEtapa1: listaOrdenada,
        });
        etapa1Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 1 - Pedido Vendas

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref =
    useRef<INotaFiscalSaidaImportacaoPedidoVendaEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idPedidoVenda?: string) => {
      try {
        setLoading(true);

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoPedidoVendaEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoPedidoVendaEtapa2Lista[];

        let listaIdPedidoVendaSelecionados: string[] = [];
        if (idPedidoVenda) {
          listaIdPedidoVendaSelecionados.push(idPedidoVenda);
        } else {
          listaIdPedidoVendaSelecionados = listaItemEtapa1.map(
            (item) => item.id
          );
        }

        const response =
          await NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicador.index(
            { listaIdPedidoVenda: listaIdPedidoVendaSelecionados }
          );
        if (listaItemEtapa2) {
          response.listaPedidoVendaItem = response.listaPedidoVendaItem.map(
            (
              itemEtapa2Novo: INotaFiscalSaidaImportacaoPedidoVendaEtapa2Lista
            ) => {
              const itemExistente = listaItemEtapa2.find(
                (
                  itemEtapa2Atual: INotaFiscalSaidaImportacaoPedidoVendaEtapa2Lista
                ) => itemEtapa2Atual.id === itemEtapa2Novo.id
              );

              if (itemExistente) {
                itemExistente.ordem = itemEtapa2Novo.ordem;
                return itemExistente;
              }

              return itemEtapa2Novo;
            }
          );
        }

        const listaOrdenada = response.listaPedidoVendaItem.Ordenar([
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicial({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 2 - Itens

  // #region Etapa 3 - Estoques

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref =
    useRef<INotaFiscalSaidaImportacaoPedidoVendaEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoPedidoVendaEtapa2Lista[];

      const listaItemEtapa2Selecionado: any[] = [];

      const listaValoresEtapa3Novo = listaItemEtapa2.map((item) => {
        listaItemEtapa2Selecionado.push({
          idPedidoVendaItem: item.id,
          produto: {
            id: item.produto.id,

            produtoEstoque: {
              controlaLote: item.produto.produtoEstoque.controlarLote,
            },
          },
          quantidade: item.quantidadeTransferir,
        });

        const retorno: INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista = {
          id: item.id,
          ordem: item.ordem,

          pessoa: item.pessoa,
          produto: item.produto,
          unidadeMedida: item.unidadeMedida,
          quantidade: item.quantidade,
          quantidadeTransferir: item.quantidadeTransferir,
          valorUnitario: item.valorUnitario,
          valorTotal: item.valorTotal,
          modalidadeFrete: item.modalidadeFrete,

          pedidoVendaItemEstoque: {
            movimentarEstoque: item.produto.produtoEstoque.controlarEstoque,
            unidadeMedida: item.unidadeMedida,
            indicadorTipoEstoque:
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
            fatorConversao: 1,
            quantidadeEstoque: item.quantidadeTransferir,
            quantidadeTotalEstoqueLocalEstoque: 0,
            porContaOrdemTerceiro: false,

            listaEstoqueLocalEstoque: [],
          },
        };

        return retorno;
      });

      const response =
        await NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemEstoqueComunicador.index(
          { listaItemSelecionado: listaItemEtapa2Selecionado }
        );

      for (let i = 0; i < response.length; i++) {
        const estoqueRetorno = response[i];

        const itemEtapa3Novo = listaValoresEtapa3Novo.find(
          (element) => element.id === estoqueRetorno.idPedidoVendaItem
        );

        if (itemEtapa3Novo) {
          itemEtapa3Novo.pedidoVendaItemEstoque.movimentarEstoque =
            estoqueRetorno.pedidoVendaItemEstoque.movimentarEstoque;
          itemEtapa3Novo.pedidoVendaItemEstoque.unidadeMedida =
            estoqueRetorno.pedidoVendaItemEstoque.unidadeMedida;
          itemEtapa3Novo.pedidoVendaItemEstoque.pessoaTerceiro =
            estoqueRetorno.pedidoVendaItemEstoque.pessoaTerceiro;

          itemEtapa3Novo.pedidoVendaItemEstoque.indicadorTipoEstoque =
            estoqueRetorno.pedidoVendaItemEstoque.indicadorTipoEstoque;
          itemEtapa3Novo.pedidoVendaItemEstoque.fatorConversao =
            estoqueRetorno.pedidoVendaItemEstoque.fatorConversao;
          itemEtapa3Novo.pedidoVendaItemEstoque.quantidadeEstoque =
            estoqueRetorno.pedidoVendaItemEstoque.quantidadeEstoque;
          itemEtapa3Novo.pedidoVendaItemEstoque.quantidadeTotalEstoqueLocalEstoque =
            estoqueRetorno.pedidoVendaItemEstoque.quantidadeTotalEstoqueLocalEstoque;
          itemEtapa3Novo.pedidoVendaItemEstoque.porContaOrdemTerceiro =
            estoqueRetorno.pedidoVendaItemEstoque.porContaOrdemTerceiro;

          itemEtapa3Novo.pedidoVendaItemEstoque.listaEstoqueLocalEstoque =
            estoqueRetorno.pedidoVendaItemEstoque.listaEstoqueLocalEstoque;
        }
      }

      const listaOrdenada = listaValoresEtapa3Novo.Ordenar([
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa3.current?.setDataInicial({
        listaEtapa3: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicial({
        listaEtapa3: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 3 - Estoques

  // #region Etapa 4 - Serie

  const formRefEtapa4 = useRef<IFormCiaHandles>(null);
  const etapa4Ref =
    useRef<INotaFiscalSaidaImportacaoPedidoVendaEtapa4Ref>(null);

  const handleCarregarDadosEtapa4 = useCallback(async () => {
    try {
      setLoading(true);
      let idModeloDocumento = '';
      let finalidadeEmissao = '';
      if (idPedidoVendaOrigem) {
        const pedidoVenda = await PedidoVendaComunicador.show({
          id: idPedidoVendaOrigem,
        });
        if (pedidoVenda) {
          idModeloDocumento = pedidoVenda.idModeloDocumento;
          finalidadeEmissao = pedidoVenda.finalidadeEmissao;
        }
      }

      const valoresEtapa4 = formRefEtapa4.current?.getData() as any;
      if (!valoresEtapa4 || !valoresEtapa4.etapa4?.idSerie) {
        const response = await NotaFiscalSaidaObterDadosPadraoComunicador.show({
          params: {
            idModeloDocumento: idModeloDocumento ?? undefined,
          },
        });
        await formRefEtapa4.current?.setDataInicial({
          etapa4: {
            serie: response.serie,
            finalidadeEmissao,
          },
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idPedidoVendaOrigem]);

  // #endregion Etapa 4 - Serie

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!etapa1Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;

      case 2:
        if (!etapa2Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa3();
        break;

      case 3:
        if (!etapa3Ref.current?.handleValidar()) return;

        proximoStep();
        await handleCarregarDadosEtapa4();
        break;
    }
  }, [
    handleCarregarDadosEtapa2,
    handleCarregarDadosEtapa3,
    handleCarregarDadosEtapa4,
    proximoStep,
    step,
  ]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      idPedidoVenda,
      idNotaFiscalSaida,
    }: IAbrirNotaFiscalSaidaImportacaoPedidoVenda): Promise<any> => {
      setAberto(true);
      setIdPedidoVendaOrigem(idPedidoVenda);
      setIdNotaFiscalSaidaOrigem(idNotaFiscalSaida);

      if (!idPedidoVenda) {
        setOrigemPedidoVenda(false);
        setStep(1);
        await handleCarregarDadosEtapa1(idPessoa, idNotaFiscalSaida);
      } else {
        setOrigemPedidoVenda(true);
        setStep(2);
        await handleCarregarDadosEtapa2(idPedidoVenda);
      }

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, handleCarregarDadosEtapa2, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFretePedidoVenda = useCallback(
    async (
      valoresEtapa3: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFretePedido: ModalidadeFreteEnum | null = null;

      if (valoresEtapa3) {
        if (valoresEtapa3.listaEtapa3.length > 1) {
          valoresEtapa3.listaEtapa3.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFretePedido = null;
                } else {
                  modalidadeFretePedido = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFretePedido = null;
              }
            }
          });
        } else {
          modalidadeFretePedido = valoresEtapa3.listaEtapa3[0].modalidadeFrete;
        }
      }

      if (!isModalidadeFreteDiferente && modalidadeFretePedido) {
        const notaFiscalSaida = await NotaFiscalSaidaComunicador.show({
          id: String(idNotaFiscalSaidaOrigem),
        });
        if (
          modalidadeFretePedido !==
          notaFiscalSaida.notaFiscalSaidaTransporteTransportadora
            .modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade de Frete da Nota Fiscal: ${notaFiscalSaida.notaFiscalSaidaTransporteTransportadora.modalidadeFrete} e do Pedido ${modalidadeFretePedido}, deseja atualizar a Nota Fiscal para ${modalidadeFretePedido}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idNotaFiscalSaidaOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const valoresEtapa3 = formRefEtapa3.current?.getData();
      let response: any = null;
      if (origemPedidoVenda) {
        if (!etapa4Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const valoresEtapa4 = formRefEtapa4.current?.getData();

        response =
          await NotaFiscalSaidaImportacaoPedidoVendaGerarComunicador.store({
            params: { valoresEtapa3, valoresEtapa4, idPedidoVendaOrigem },
          });
      } else {
        if (!etapa3Ref.current?.handleValidar()) {
          setLoading(false);
          return;
        }

        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFretePedidoVenda(valoresEtapa3);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção Pedidos com modalidade de frete diferente entre si, favor verificar a modalidade de frete da Nota Fiscal!'
          );
        }

        response =
          await NotaFiscalSaidaImportacaoPedidoVendaImportarComunicador.store({
            params: {
              valoresEtapa3,
              idNotaFiscalSaidaOrigem,
              alterarModalidadeFrete: resposta,
            },
          });
      }

      if (
        response.listaMensagemAviso &&
        response.listaMensagemAviso.length > 0
      ) {
        TratarInfo(response.listaMensagemAviso);
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleValidarModalidadeFretePedidoVenda,
    idNotaFiscalSaidaOrigem,
    idPedidoVendaOrigem,
    origemPedidoVenda,
  ]);

  return (
    <NotaFiscalSaidaImportacaoPedidoVendaContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirNotaFiscalSaidaImportacaoPedidoVenda: handleAbrir,
        fecharNotaFiscalSaidaImportacaoPedidoVenda: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Pedidos de Venda</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>

              {origemPedidoVenda && (
                <Step>
                  <StepBox type="button" $selecionado={step === 4}>
                    4
                  </StepBox>
                  <StepNome>Série</StepNome>
                </Step>
              )}
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <NotaFiscalSaidaImportacaoPedidoVendaEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <NotaFiscalSaidaImportacaoPedidoVendaEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <NotaFiscalSaidaImportacaoPedidoVendaEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 4 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa4}>
              <NotaFiscalSaidaImportacaoPedidoVendaEtapa4
                name="etapa4"
                formRef={formRefEtapa4}
                ref={etapa4Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {origemPedidoVenda && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handleFechar}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 16,
                          }}
                        >
                          <MdCancel />
                          <span style={{ marginLeft: 10 }}>Cancelar</span>
                        </span>
                      </button>
                    )}

                    {!origemPedidoVenda && (
                      <button
                        type="button"
                        className="btn-padrao btn-cinza-claro"
                        onClick={handlePassoAnterior}
                      >
                        <IoMdReturnLeft />
                        <span style={{ marginLeft: 10 }}>Voltar</span>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    {origemPedidoVenda && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleProximoPasso}
                        style={{
                          marginLeft: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ImArrowRight />
                        <span style={{ marginLeft: 10 }}>Próximo passo</span>
                      </button>
                    )}

                    {!origemPedidoVenda && (
                      <button
                        type="button"
                        className="btn-padrao btn-azul-claro"
                        onClick={handleSubmit}
                        style={{ marginLeft: 10 }}
                      >
                        <FiRefreshCcw />
                        <span style={{ marginLeft: 10 }}>Concluir</span>
                      </button>
                    )}
                  </div>
                </div>

                <div style={{ display: step === 4 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </NotaFiscalSaidaImportacaoPedidoVendaContext.Provider>
  );
};

function UseNotaFiscalSaidaImportacaoPedidoVenda(): INotaFiscalSaidaImportacaoPedidoVendaContext {
  const context = useContext(NotaFiscalSaidaImportacaoPedidoVendaContext);

  if (!context) {
    throw new Error(
      'UseNotaFiscalSaidaImportacaoPedidoVenda deve ser usado com um PedidoVendaProvider'
    );
  }

  return context;
}

export {
  NotaFiscalSaidaImportacaoPedidoVendaHook,
  UseNotaFiscalSaidaImportacaoPedidoVenda,
};
