import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-pedido-venda/curva-abc-pedido-venda-sub-grupo-grupo-com-produto-por-quantidade';

interface ICurvaABCPedidoVendaSubGrupoGrupoComProdutoPorQuantidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCPedidoVendaSubGrupoGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador {
  public async index(
    params: ICurvaABCPedidoVendaSubGrupoGrupoComProdutoPorQuantidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCPedidoVendaSubGrupoGrupoProdutoComProdutoPorQuantidadeRelatorioComunicador();
