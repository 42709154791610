import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import OrdemDesmontagemComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemDesmontagem/Comunicador/OrdemDesmontagemComunicador';
import FormHook from './FormHook';
import { OrdemDesmontagemHook } from './OrdemDesmontagemHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const OrdemDesmontagemHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: OrdemDesmontagemComunicador }}>
      <RotasHook>
        <OrdemDesmontagemHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </OrdemDesmontagemHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default OrdemDesmontagemHooks;
