import React, { createContext, useContext, useState, useCallback } from 'react';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import IListaContext from './Interface/IListaContext';

const ListaContext = createContext<IListaContext>({} as IListaContext);

const ListaProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const [showPesquisaAvancada, setShowPesquisaAvancada] = useState(false);

  const alterarTotalPaginas = useCallback((value: number) => {
    setTotalPaginas(value);
  }, []);

  const alterarLimite = useCallback((value: number) => {
    setLimite(value);
  }, []);

  const abrirPesquisaAvancada = useCallback(() => {
    setShowPesquisaAvancada(true);
  }, []);

  const fecharPesquisaAvancada = useCallback(() => {
    setShowPesquisaAvancada(false);
  }, []);

  return (
    <ListaContext.Provider
      value={{
        abrirPesquisaAvancada,
        fecharPesquisaAvancada,
        showPesquisaAvancada,
        validador: true,
        paginacao: {
          totalPaginas,
          alterarTotalPaginas,
          limite,
          alterarLimite,
        },
      }}
    >
      {children}
    </ListaContext.Provider>
  );
};

function UseLista(): Omit<IListaContext, 'validador'> {
  const context = useContext(ListaContext);

  if (!context.validador) {
    throw new Error('UseLista/Lista deve ser usado com um ListaProvider');
  }

  return context;
}

export { ListaProvider, UseLista };
