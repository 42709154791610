import {
  FormatarDataParaPtBrSemTimeZone,
  IConsultaAliquotaIcmsInternaInterestadualLista,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import Lista from '../../../../../Componentes/Lista';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ConsultaAliquotaIcmsInternaInterestadualComunicador from '../../../../../Comunicador/Fiscal/Tributacao/ConsultaAliquotaIcmsInternaInterestadual/Comunicador/ConsultaAliquotaIcmsInternaInterestadualComunicador';

const ConsultaAliquotaIcmsInternaInterestadualLista: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response =
          await ConsultaAliquotaIcmsInternaInterestadualComunicador.index({
            params: {
              pesquisaAvancada,
              order,
              descAsc,
              limite,
              pagina,
              textoPesquisa,
            },
          });
        const dados = response.dados[0].map(
          (data: IConsultaAliquotaIcmsInternaInterestadualLista) => {
            return {
              id: data.id,
              dataInicioVigencia: (
                <div className="lista-texto">
                  {FormatarDataParaPtBrSemTimeZone(data.dataInicioVigencia)}
                </div>
              ),
              'estadoOrigem.siglaNome': (
                <div className="lista-texto">
                  {data.estadoOrigem.sigla} - {data.estadoOrigem.nome}
                </div>
              ),
              'estadoDestino.siglaNome': (
                <div className="lista-texto">
                  {data.estadoDestino.sigla} - {data.estadoDestino.nome}
                </div>
              ),
              aliquota: (
                <div className="lista-texto">
                  {data.aliquota
                    ? Number(data.aliquota)?.FormatarParaPtBr()
                    : data.aliquota}
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    []
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'fiscalConsultaAliquotaIcmsInternaInterestadual',
        },
      });

      const listaTh: ITh[] = response.campos;

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Consulta Alíquota de ICMS Interna e Interestadual"
          permissao={
            permissoes.FiscalTributacaoConsultaAliquotaIcmsInternaInterestadual
          }
          botaoNovo={false}
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ConsultaAliquotaIcmsInternaInterestadualLista;
