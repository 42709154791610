"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoRelatorioDevolucaoNotaEntradaEnum;
(function (SituacaoRelatorioDevolucaoNotaEntradaEnum) {
    SituacaoRelatorioDevolucaoNotaEntradaEnum["naoDevolvida"] = "N\u00E3o Devolvida";
    SituacaoRelatorioDevolucaoNotaEntradaEnum["parcialmenteDevolvida"] = "Parcialmente Devolvida";
    SituacaoRelatorioDevolucaoNotaEntradaEnum["devolvida"] = "Devolvida";
})(SituacaoRelatorioDevolucaoNotaEntradaEnum || (SituacaoRelatorioDevolucaoNotaEntradaEnum = {}));
exports.default = SituacaoRelatorioDevolucaoNotaEntradaEnum;
