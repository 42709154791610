import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
  FormatarTelefone,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { IConhecimentoTransporteImpressaoModalAereoPDF } from './ConhecimentoTransporteImpressaoModalAereoPDF';

const ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF: React.FC<
  IConhecimentoTransporteImpressaoModalAereoPDF
> = ({ dados }) => {
  const canvasBarCode = document.createElement('canvas');
  const canvasQrCode = document.createElement('canvas');

  JsBarcode(canvasBarCode, dados.chaveAcesso, {
    fontSize: 0,
    format: 'CODE128C',
  });
  QRCode.toCanvas(canvasQrCode, dados.chaveAcesso);

  const barCode = canvasBarCode.toDataURL();
  const qrCode = canvasQrCode.toDataURL();

  return (
    <>
      <View style={{ flexDirection: 'row', height: 140 }}>
        <View style={{ width: '45%' }}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ height: '70%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '100%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '25%',
                        margin: '5px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dados.caminhoLogo && (
                        <Image
                          src={dados.caminhoLogo}
                          style={{ borderRadius: 0 }}
                        />
                      )}
                    </View>

                    <View style={{ width: '70%' }}>
                      <View
                        style={{
                          marginLeft: '5px',
                          marginTop: '5px',
                          fontWeight: 'bold',
                          fontSize: 8,
                        }}
                      >
                        <Text>{dados.empresa.nomeRazaoSocial}</Text>
                      </View>

                      <View
                        style={{
                          marginTop: '15px',
                          marginLeft: '5px',
                          marginBottom: '5px',
                          fontSize: 7,
                        }}
                      >
                        <Text>
                          {`${dados.empresa.empresaEndereco.logradouro}, ${
                            dados.empresa.empresaEndereco.numero
                          }, ${
                            dados.empresa.empresaEndereco.bairro
                          }, ${FormatarCep(dados.empresa.empresaEndereco.cep)}`}
                        </Text>
                        <Text>
                          {'MUNICÍPIO: '}
                          {`${dados.empresa.empresaEndereco.cidade.nome} - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
                        </Text>
                        <Text>
                          {'CNPJ: '}
                          {FormatarCnpjCpf(dados.empresa.cnpjCpf)}
                        </Text>
                        <Text>
                          {'IE: '}
                          {dados.empresa.inscricaoEstadual}
                        </Text>
                        <Text>
                          {'FONE: '}
                          {FormatarTelefone(dados.empresa.empresaEndereco.fone)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                height: '30%',
                border: '1 solid black',
                borderRadius: 5,
                fontSize: 6,
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    borderBottom: '1 solid black',
                    height: '50%',
                  }}
                >
                  <View style={{ width: '30%', borderRight: '1 solid black' }}>
                    <View style={{ marginLeft: '5px' }}>
                      <Text>TIPO DO CT-E</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        fontSize: 9,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>{dados.tipoCte}</Text>
                    </View>
                  </View>

                  <View style={{ width: '30%', borderRight: '1 solid black' }}>
                    <View style={{ marginLeft: '5px' }}>
                      <Text>TIPO DO SERVIÇO</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        fontSize: 9,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>{dados.tipoServico}</Text>
                    </View>
                  </View>

                  <View style={{ width: '40%' }}>
                    <View style={{ marginLeft: '5px' }}>
                      <Text>TOMADOR DO SERVIÇO</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        fontSize: 9,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>{dados.tomadorServico}</Text>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: 'row', height: '50%' }}>
                  <View style={{ width: '60%', borderRight: '1 solid black' }}>
                    <View style={{ marginLeft: '5px' }}>
                      <Text>INDICADOR DO CT-E GLOBALIZADO</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        fontSize: 9,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: '13px',
                          height: '13px',
                          marginRight: '5px',
                          marginTop: '1px',
                          marginBottom: '2px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1 solid black',
                        }}
                      />
                      <View style={{ marginRight: '15px' }}>
                        <Text>SIM</Text>
                      </View>

                      <View
                        style={{
                          width: '13px',
                          height: '13px',
                          marginRight: '5px',
                          marginTop: '1px',
                          marginBottom: '2px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1 solid black',
                        }}
                      >
                        <Text>X</Text>
                      </View>
                      <View>
                        <Text>NÃO</Text>
                      </View>
                    </View>
                  </View>

                  <View style={{ width: '40%' }}>
                    <View style={{ marginLeft: '5px' }}>
                      <Text>INF. CT-E GLOBALIZADO</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ width: '55%' }}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ height: '20%' }}>
              <View style={{ flexDirection: 'row', marginLeft: '5px' }}>
                <View
                  style={{
                    width: '70%',
                    border: '1 solid black',
                    borderRadius: 5,
                    alignItems: 'center',
                    fontSize: 6,
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>DACTE</Text>
                  <Text>DOCUMENTO AUXILIAR DO CONHECIMENTO</Text>
                  <Text>DE TRANSPORTE ELETRÔNICO</Text>
                </View>

                <View
                  style={{
                    width: '30%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>MODAL</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>AÉREO</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ height: '15%' }}>
              <View
                style={{
                  flexDirection: 'row',
                  border: '1 solid black',
                  marginLeft: '5px',
                  height: '100%',
                  borderRadius: 5,
                }}
              >
                <View style={{ width: '11%', borderRight: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>MODELO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.modeloDocumento.codigo}</Text>
                  </View>
                </View>

                <View style={{ width: '9%', borderRight: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>SÉRIE</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.serie.codigo}</Text>
                  </View>
                </View>

                <View style={{ width: '14%', borderRight: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>NÚMERO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.numero}</Text>
                  </View>
                </View>

                <View style={{ width: '9%', borderRight: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>F/L</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      render={({ pageNumber, totalPages }) => {
                        return `${pageNumber}/${totalPages}`;
                      }}
                      fixed
                    />
                  </View>
                </View>

                <View style={{ width: '27.5%', borderRight: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>DATA E HORA EMISSÃO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {FormatarDataHoraCompletaParaPtBr(dados.dataHoraEmissao)}
                    </Text>
                  </View>
                </View>

                <View style={{ width: '29.5%' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>INSCRIÇÃO SUFRAMA DEST.</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.destinatario?.inscricaoSuframa}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                height: '65%',
                border: '1 solid black',
                borderRadius: 5,
                marginLeft: '5px',
              }}
            >
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '70.5%',
                    borderRight: '1 solid black',
                    fontSize: 6,
                  }}
                >
                  <View style={{ flexDirection: 'column' }}>
                    <View
                      style={{
                        height: '54%',
                        borderBottom: '1 solid black',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <View style={{ width: '95%', height: '90%' }}>
                        <Image src={barCode} />
                      </View>
                      <View style={{ fontSize: 4 }}>
                        <Text>
                          Chave de acesso para consulta de autenticidade no site
                          www.cte.fazenda.gov.br
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        height: '23%',
                        borderBottom: '1 solid black',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 8,
                      }}
                    >
                      <Text>{FormatarChaveAcesso(dados.chaveAcesso)}</Text>
                    </View>

                    <View style={{ height: '23%' }}>
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>PROTOCOLO / DATA E HORA AUTORIZAÇÃO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 8,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {`${
                            dados.numeroProtocolo
                          } - ${FormatarDataHoraCompletaParaPtBr(
                            dados.dataHoraRetorno
                          )}`}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: '29.5%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View>
                    <Image
                      style={{ height: '100%', width: '100%' }}
                      src={qrCode}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{ border: '1 solid black', borderRadius: 5, marginTop: '5px' }}
      >
        <View style={{ marginLeft: '5px', fontSize: 6 }}>
          <Text>CFOP - NATUREZA DA PRESTACAO</Text>
        </View>
        <View
          style={{
            flex: 1,
            fontSize: 9,
            marginLeft: '5px',
            justifyContent: 'center',
          }}
        >
          <Text>{`${dados.cfop} - ${dados.naturezaOperacao}`}</Text>
        </View>
      </View>
    </>
  );
};

export default ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF;
