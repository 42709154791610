import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { IOrdemProducaoItemValoresAlterar } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompleteOperacaoRoteiroFabricacaoRef } from '../../../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteOperacaoRoteiroFabricacao';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus =
    useRef<IInputAutoCompleteOperacaoRoteiroFabricacaoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listaOrdemProducaoRoteiroFabricacaoOperacao =
    formPrincipal.formRef.current?.getFieldValue(
      'listaOrdemProducaoRoteiroFabricacaoOperacao'
    ) ?? [];

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IOrdemProducaoItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const response =
          listaOrdemProducaoRoteiroFabricacaoOperacao[Number(idEditar)];
        await formRefDetalhe.current?.setDataInicial({
          ...response,
        });
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          ordem: listaOrdemProducaoRoteiroFabricacaoOperacao.length + 1,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      listaOrdemProducaoRoteiroFabricacaoOperacao,
      redirecionar,
      refresh,
    ]
  );

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      isOrdemProducaoCadastrado?: boolean
    ): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idCentroTrabalho: Yup.string()
            .required('Centro de Trabalho é Obrigatório!')
            .nullable(),
          idOperacaoRoteiroFabricacao: Yup.string()
            .required('Operação do Roteiro de Fabrição é Obrigatório!')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isOrdemProducaoCadastrado !== false) {
          const errors = GetValidationErrors(error as any);
          formRef?.setErrors(errors);
        }
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'listaOrdemProducaoRoteiroFabricacaoOperacao'
        );

        if (!id) {
          data.ordem = listaDados.length + 1;

          formPrincipal.formRef.current?.setFieldValue(
            'listaOrdemProducaoRoteiroFabricacaoOperacao',
            [...listaDados, data]
          );

          id = String([...listaDados, data].length - 1);
        } else {
          listaDados[id] = data;
        }

        await formRef?.reset();

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, getIdDetalheRegistro, handleValidar, redirecionar]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (!resposta) return;

      setLoading(true);
      formPrincipal.setLoading(true);

      const listaDados = formPrincipal.formRef.current?.getFieldValue(
        'listaOrdemProducaoRoteiroFabricacaoOperacao'
      );

      listaDados.splice(Number(index), 1);

      formPrincipal.formRef.current?.setFieldValue(
        'listaOrdemProducaoRoteiroFabricacaoOperacao',
        listaDados
      );

      setLoading(false);
      formPrincipal.setLoading(false);
      formPrincipal.refresh();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
