import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/impressao';

interface IConhecimentoTransporteTerceiroImpressaoComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTerceiroImpressaoComunicador {
  public async show(
    params: IConhecimentoTransporteTerceiroImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroImpressaoComunicador();
