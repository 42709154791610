import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/custo/configuracao/log';

interface ICustoConfiguracaoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface ICustoConfiguracaoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class CustoConfiguracaoLogComunicador {
  public async index(
    params: ICustoConfiguracaoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ICustoConfiguracaoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new CustoConfiguracaoLogComunicador();
