import {
  FormatarCnpjCpf,
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  INotaFiscalSaidaFiltroRelatorioBackend,
  INotaFiscalSaidaFiltroRelatorioFrontend,
  INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemCabecalhoRelatorio,
  INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorioComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Vendas/NotaFiscalSaida/NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio/NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPlanilha from './NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPlanilha';
import NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPDF from './NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPDF';

interface IRelatorios {
  handleExportCSV(
    filtros: INotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: INotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: INotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: INotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
  handleObterBlobPlanilha(
    filtros: INotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio | null> => {
      const filtroBack: INotaFiscalSaidaFiltroRelatorioBackend = {
        dataEmissaoInicial: filtros.dataEmissaoInicial,
        dataEmissaoFinal: filtros.dataEmissaoFinal,

        descricao: filtros.descricao,
        codigo: filtros.codigo,

        devolucoes: filtros.devolucoes,
      };

      if (filtros?.listaTipoNota) {
        filtroBack.listaIdTipoNota =
          filtros?.listaTipoNota.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaTipoNotaMotivo) {
        filtroBack.listaIdTipoNotaMotivo =
          filtros?.listaTipoNotaMotivo.listaValor.map((value) => value.id) ||
          [];
      }

      if (
        filtros?.somaFaturamento === 'true' ||
        filtros?.somaFaturamento === 'false'
      ) {
        filtroBack.somaFaturamento = filtros?.somaFaturamento === 'true';
      }

      if (filtros?.listaCidade) {
        filtroBack.listaIdCidade =
          filtros.listaCidade.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaSituacaoItem) {
        filtroBack.listaDescricaoSituacaoItem =
          await filtros.listaSituacaoItem.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaEstado) {
        filtroBack.listaIdEstado =
          filtros.listaEstado.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaCliente) {
        filtroBack.listaIdCliente =
          filtros.listaCliente.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaModeloDocumento) {
        filtroBack.listaIdModeloDocumento =
          filtros.listaModeloDocumento.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaSerie) {
        filtroBack.listaIdSerie =
          filtros.listaSerie.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaProduto) {
        filtroBack.listaIdProduto =
          filtros.listaProduto.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.tipoBuscaCodigoDescricao) {
        filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
          typeof TipoBuscaCodigoDescricaoEnum,
          TipoBuscaCodigoDescricaoEnum
        >(
          TipoBuscaCodigoDescricaoEnum,
          String(filtros.tipoBuscaCodigoDescricao)
        );
      }

      if (filtros?.listaGrupo) {
        filtroBack.listaIdGrupo =
          filtros.listaGrupo.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaRamoAtividadeProfissao) {
        filtroBack.listaIdRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      if (filtros?.listaOperacaoFiscal) {
        filtroBack.listaIdOperacaoFiscal =
          filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaEmpresas) {
        filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
          (value: any) => value.id
        );
      }

      const response =
        await NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorioComunicador.index(
          { params: { filtro: filtroBack } }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          const valores = {
            ...dados.valores,

            somatorioValorTotalProdutoPorCsosnCst: Number(
              dados.valores.somatorioValorTotalProdutoPorCsosnCst
            ).FormatarParaPtBr(),
            somatorioValorTotalBrutoPorCsosnCst: Number(
              dados.valores.somatorioValorTotalBrutoPorCsosnCst
            ).FormatarParaPtBr(),
            somatorioValorTotalProdutoPorCfop: Number(
              dados.valores.somatorioValorTotalProdutoPorCfop
            ).FormatarParaPtBr(),
            somatorioValorTotalBrutoPorCfop: Number(
              dados.valores.somatorioValorTotalBrutoPorCfop
            ).FormatarParaPtBr(),
          };

          const listaNotaFiscalSaidaItem = dados.listaNotaFiscalSaidaItem.map(
            (notaFiscal) => {
              return {
                ...notaFiscal,
                cnpjCpfVirtual: FormatarCnpjCpf(notaFiscal.cnpjCpfVirtual),
                dataHoraEmissao: FormatarDataParaPtBr(
                  notaFiscal.dataHoraEmissao
                ),
                valorTotalProduto: Number(
                  notaFiscal.valorTotalProduto
                ).FormatarParaPtBr(),
                valorTotalBruto: Number(
                  notaFiscal.valorTotalBruto
                ).FormatarParaPtBr(),
              };
            }
          );

          return {
            ...dados,
            valores,
            listaNotaFiscalSaidaItem,
          };
        })
      );

      return {
        ...retorno,
        listaDados,
        somatorioGeralValorTotalProduto: Number(
          retorno.somatorioGeralValorTotalProduto
        ).FormatarParaPtBr(),
        somatorioGeralValorTotalBruto: Number(
          retorno.somatorioGeralValorTotalBruto
        ).FormatarParaPtBr(),
      };
    };

    function FormatarCabecalho(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend,
      cabecalho: INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemCabecalhoRelatorio
    ): INotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataEmissaoInicial = '--/--/--';
        cabecalho.filtroDataEmissaoFinal = '--/--/--';

        if (filtros.dataEmissaoInicial) {
          cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoInicial
          );
        }

        if (filtros.dataEmissaoFinal) {
          cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoFinal
          );
        }
      }

      if (
        filtros.listaSituacaoItem &&
        filtros.listaSituacaoItem.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaModeloDocumento &&
        filtros.listaModeloDocumento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroModeloDocumento =
          filtros.listaModeloDocumento.listaValor
            .map((valor: any) => {
              return valor.codigoDescricao;
            })
            .join(', ');
      }

      if (filtros.listaSerie && filtros.listaSerie.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSerie = filtros.listaSerie.listaValor
          .map((valor: any) => {
            return valor.codigoDescricao;
          })
          .join(', ');
      }

      if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCliente = filtros.listaCliente.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (
        filtros.listaRamoAtividadeProfissao &&
        filtros.listaRamoAtividadeProfissao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEstado = filtros.listaEstado.listaValor
          .map((valor: any) => {
            return valor.siglaNome;
          })
          .join(', ');
      }

      if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCidade = filtros.listaCidade.listaValor
          .map((valor: any) => {
            return valor.cidadeUf;
          })
          .join(', ');
      }

      if (
        filtros.listaOperacaoFiscal &&
        filtros.listaOperacaoFiscal.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
          .map((valor: any) => {
            return valor.cfopDescricao;
          })
          .join(', ');
      }

      if (filtros.somaFaturamento) {
        cabecalho.filtroSomaFaturamento =
          filtros.somaFaturamento === 'true' ? 'Sim' : 'Não';
      }

      if (filtros.devolucoes) {
        cabecalho.filtroDevolucoes = filtros.devolucoes;
      }

      if (
        filtros.listaEmpresas &&
        filtros.listaEmpresas.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocial;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPlanilha,
        nomeArquivo: 'NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigem',
        valores: dados.listaDados,
        unwind: ['listaNotaFiscalSaidaItem'],
      });

      relatorio.AbrirArquivo();
    }
    async function handleExportPlanilha(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPlanilha,
        nomeArquivo: 'NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigem',
        valores: dados.listaDados as any,
        unwind: ['listaNotaFiscalSaidaItem'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPlanilha(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return undefined;

      const relatorio = await Planilha({
        campos: NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPlanilha,
        nomeArquivo: 'NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigem',
        valores: dados.listaDados as any,
        unwind: ['listaNotaFiscalSaidaItem'],
      });

      return relatorio.GetBlob();
    }

    async function handleVisualizarPdf(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;
      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: INotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
      handleObterBlobPlanilha,
    };
  };

export default NotaFiscalSaidaPorCfopCsosnCstIcmsComOrigemRelatorio();
