import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/categoria-dre/regra-escolha-categoria/obter-conforme-criterios-atualizar-categorias';

interface IRegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicadorShow {
  params: {
    idContaParcela: string;
    dataPesquisaVigencia: Date;
  };
}

class RegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicador {
  public async show(
    params: IRegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaCategoriaObterConformeCriteriosAtualizarCategoriasComunicador();
