import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/carga';

interface IConhecimentoTransporteTerceiroCargaComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroCargaComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteTerceiroCargaComunicador {
  public async store(
    params: IConhecimentoTransporteTerceiroCargaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroCargaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroCargaComunicador();
