import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-pis-cofins/ordem';

interface IRegraEscolhaCstCreditoPisCofinsOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaCstCreditoPisCofinsOrdemComunicador {
  public async update(
    params: IRegraEscolhaCstCreditoPisCofinsOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoPisCofinsOrdemComunicador();
