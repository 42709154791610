import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/empresa-selecionada';
interface IUsuarioSubdominioEmpresaSelecionadaComunicadorUpdate {
  id: string;
  params: {
    utcFront?: string;
  };
}

class UsuarioSubdominioEmpresaSelecionadaComunicador {
  public async update(
    params: IUsuarioSubdominioEmpresaSelecionadaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new UsuarioSubdominioEmpresaSelecionadaComunicador();
