"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var Registro0200TipoItemEnum;
(function (Registro0200TipoItemEnum) {
    Registro0200TipoItemEnum["mercadoriaRevenda"] = "00";
    Registro0200TipoItemEnum["materiaPrima"] = "01";
    Registro0200TipoItemEnum["embalagem"] = "02";
    Registro0200TipoItemEnum["produtoEmProcesso"] = "03";
    Registro0200TipoItemEnum["produtoAcabado"] = "04";
    Registro0200TipoItemEnum["subproduto"] = "05";
    Registro0200TipoItemEnum["produtoIntermediario"] = "06";
    Registro0200TipoItemEnum["materialUsoConsumo"] = "07";
    Registro0200TipoItemEnum["ativoImobilizado"] = "08";
    Registro0200TipoItemEnum["servicos"] = "09";
    Registro0200TipoItemEnum["outrosInsumos"] = "10";
    Registro0200TipoItemEnum["outras"] = "99";
})(Registro0200TipoItemEnum || (Registro0200TipoItemEnum = {}));
exports.default = Registro0200TipoItemEnum;
