import { IOrdemServicoRetratoComRepresentanteImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrdemServicoRetratoComRepresentantePDF from './OrdemServicoRetratoComRepresentanteImpressaoPDF';

interface IImpressaoOrdemServicoRetratoComRepresentante {
  handleVisualizarPdf(
    data: IOrdemServicoRetratoComRepresentanteImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemServicoRetratoComRepresentanteImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemServicoRetratoComRepresentante =
  (): IImpressaoOrdemServicoRetratoComRepresentante => {
    async function handleVisualizarPdf(
      dados: IOrdemServicoRetratoComRepresentanteImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoComRepresentantePDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrdemServicoRetratoComRepresentanteImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoComRepresentantePDF dados={dados} />
        ),
      });
      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoRetratoComRepresentante();
