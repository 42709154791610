import api from '../../../Comum/Services/Api';

const rota = 'enviar-email';

interface IEnviarEmailStore {
  params: any;
}

class EnviarEmailComunicador {
  public async store({ params }: IEnviarEmailStore): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new EnviarEmailComunicador();
