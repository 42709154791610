import { AppErro, IPessoaContatoValoresAlterar } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import { UseRedirecionar } from '../../../Hooks/RedirecionarContext';
import { UseAuth } from '../../../Hooks/Auth';
import GetValidationErrors from '../../../Util/Erro/GetValidationErrors';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import UsuarioAlterarSenhaComunicador from '../../../Comunicador/Configuracao/Usuario/Comunicador/UsuarioAlterarSenhaComunicador';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../Util/Erro/TratarErros';
import { IHandleCarregarDadosParametros } from '../../../Componentes/Detalhe/Hooks/FormContext';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { usuario } = UseAuth();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPessoaContatoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          senhaAtual: Yup.string().required('Senha atual é obrigatória!'),

          novaSenha: Yup.mixed()
            .test({
              message: 'Nova senha é obrigatório!',
              test: (value: any) => {
                if (!value) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Nova senha não pode ser igual a senha atual!',
              test: (value: any) => {
                if (value && value === data.senhaAtual) {
                  return false;
                }
                return true;
              },
            }),

          repetirSenha: Yup.mixed()
            .test({
              message: 'Repetir senha é obrigatório!',
              test: (value: any) => {
                if (!value) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'As novas senhas não conferem!',
              test: (value: any) => {
                if (value !== data.novaSenha) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id: '', erro: true };
        }

        // CHAMA BACKEND
        await UsuarioAlterarSenhaComunicador.update({
          id: usuario.id,
          params: data,
        });

        await formRef?.reset();
        ToastSucesso('Senha alterada com sucesso!');
        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [handleValidar, redirecionar, usuario.id]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    throw new AppErro({ mensagem: 'Função não implementada!' });
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
