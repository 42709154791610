"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ComparaBancoComFrontFk;
function ComparaBancoComFrontFk(dados) {
    const inserir = dados.front.filter((element) => {
        const aux = dados.banco.find((e) => {
            return element.id === e[dados.colunaNome];
        });
        return !aux;
    });
    const excluir = dados.banco.filter((element) => {
        const aux = dados.front.find((e) => {
            return e.id === element[dados.colunaNome];
        });
        return !aux;
    });
    return { inserir, excluir };
}
