"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEmissaoEnum;
(function (TipoEmissaoEnum) {
    TipoEmissaoEnum["normal"] = "1";
    TipoEmissaoEnum["contingenciaFS"] = "2";
    TipoEmissaoEnum["contingenciaSCAN"] = "3";
    TipoEmissaoEnum["contingenciaDPEC"] = "4";
    TipoEmissaoEnum["contingenciaFSDA"] = "5";
    TipoEmissaoEnum["contingenciaSVCAN"] = "6";
    TipoEmissaoEnum["contingenciaSCVRS"] = "7";
    TipoEmissaoEnum["contingenciaOfflineNFCe"] = "9";
})(TipoEmissaoEnum || (TipoEmissaoEnum = {}));
exports.default = TipoEmissaoEnum;
