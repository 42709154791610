import { Dropdown } from 'react-bootstrap';
import styled, { css } from 'styled-components';

interface IContainer {
  $telaPequena: boolean;
}

interface IBotoes {
  $telaPequena: boolean;
}

export const Container = styled.div<IContainer>`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 60px;
  padding: 0 15px;

  display: flex;
  align-items: center;

  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 20px;
    margin: 0;
    color: #434349;
    margin: 0px 15px;
  }

  ${({ $telaPequena }) =>
    $telaPequena &&
    css`
      flex-direction: column;

      & > *:last-child {
        padding-bottom: 5px;
      }

      h1 {
        margin-left: 10px;
        color: #434349;
        padding: 2px 0px;
        width: 100%;
        font-size: 14px;
      }
    `}
`;

export const Botoes = styled.div<IBotoes>`
  ${({ $telaPequena }) =>
    $telaPequena
      ? css`
          display: flex;
          align-items: center;
        `
      : css`
          display: contents;
        `}

  .pesquisa {
    div {
      position: relative;
      input {
        margin: 0 15px 0 0;
        width: 300px;
        height: 30px;
        border-radius: 5px;
        padding-right: 35px;
      }

      button {
        position: absolute;
        display: flex;
        align-items: center;

        right: 25px;
        top: 0;
        height: 100%;
        font-size: 25px;

        background: transparent;
        border: 0;
      }
    }
  }
  .btn-pesquisa {
    padding: 0;
    font-size: 22px;
    height: 38px;
    width: 38px;
  }
  .pesquisa-grande {
    display: flex;
    background: #6c757d;
    border-radius: 5px;
    width: 180px;
    & > div {
      display: flex;
      flex-direction: column;
      /* justify-self: center; */
      width: calc(100% - 38px);
      align-items: center;
      justify-content: center;

      span {
        color: white;
      }
      button {
        background: transparent;
        border: 0;
        color: rgb(255, 255, 0);
      }
    }
  }
  .fechar-pesquisa {
    display: none;
  }
`;

export const ContainerPersonalizacaoLista = styled.div`
  position: relative;

  button {
    height: 100%;
  }
`;

export const Container2 = styled.div`
  position: absolute;
  right: 0;
  padding: 15px;
  margin-top: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: max-content;

  height: 300px;
  overflow: auto;
  &::before {
    content: ' ';
    background: transparent;
    position: absolute;
    right: 30px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
  }
`;

export const BotoesDireita = styled.div`
  display: flex;
  margin-left: auto;
  > * + * {
    margin-left: 15px;
  }
`;

export const PesquisaGrande = styled.div`
  display: flex;
  background: #6c757d;
  border-radius: 5px;
  width: 180px;

  & > div {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    width: calc(100% - 38px);
    align-items: center;
    justify-content: center;

    span {
      color: white;
    }
    button {
      background: transparent;
      border: 0;
      color: rgb(255, 255, 0);
    }
  }
`;

export const ContainerOverlayConfig = styled.div`
  position: absolute;
  right: 0;
  padding: 15px;
  margin-top: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transform: translateX(3%);
  height: 500px;
  overflow: auto;
  z-index: 100;

  td {
    vertical-align: middle;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &::before {
    content: ' ';
    background: transparent;
    position: absolute;
    right: 30px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
  }
`;

export const DropdownConfiguracao = styled(Dropdown)`
  button::after {
    display: none;
  }
`;
