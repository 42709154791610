import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/contas-receber-pagar/contas-receber-pagar-parcela-por-vencimento';

interface IContasReceberPagarParcelaPorVencimentoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ContasRecPagParcPorVencRelComunicador {
  public async index(
    params: IContasReceberPagarParcelaPorVencimentoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ContasRecPagParcPorVencRelComunicador();
