import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {
  FormatarDataHoraParaPtBr,
  ILiquidacaoContasEmLoteLista,
  TipoContaEnum,
} from '@elogestor/util';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseLiquidacaoContasEmLoteLista } from '../../../Hooks/LiquidacaoContasEmLoteListaHook';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteFormaPagamento from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import InputAutoCompletePortador from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import InputAutoCompleteContaTipoAdiantamentoEnum from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteContaTipoAdiantamentoEnum';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import InputTabelaDecimal from '../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import InputHidden from '../../../../../../../Componentes/Inputs/InputHidden';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LiquidacaoContasEmLoteComunicador from '../../../../../../../Comunicador/Financeiro/Movimentacoes/LiquidacaoContasEmLote/Comunicador/LiquidacaoContasEmLoteComunicador';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseContaListaCategoria } from '../../../../Conta/Detalhe/Hooks/ContaListaCategoriaHook';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';

interface IFinanceiroModal {
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
}

const FinanceiroDetalhe: React.FC<IFinanceiroModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { abrirJanela } = UseConfirmacao();

  const formRef = useRef<IFormCiaHandles>(null);

  const { listaValor } = UseLiquidacaoContasEmLoteLista();
  const {
    calcularValorTotalCategoriaParaLiquidacao,
    calcularValorCampoCategoriaParcela,
    calcularRateioCategoriasNaoLiquidada,
  } = UseContaListaCategoria();

  const [listaDados, setListaDados] = useState<ILiquidacaoContasEmLoteLista[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const listaConteudoRef = useRef<HTMLInputElement[]>([]);

  const handleClickVoltar = useCallback(async (): Promise<any> => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja fechar o Financeiro sem Salvar?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) return;
    if (onFecharFormModal) onFecharFormModal();
    setListaDados([]);
  }, [abrirJanela, onFecharFormModal]);

  const handleValidar = useCallback((data: any): boolean => {
    const listaMensagemErro: string[] = [];
    if (
      Number(data.valorLiquidacao) > Number(data.valorTotalParcelasSelecionadas)
    ) {
      formRef.current?.setFieldError(
        'valorLiquidacao',
        'Valor Liquidacao não pode ser maior que o Valor Total das Parcelas!'
      );
      listaMensagemErro.push(
        'Valor Liquidacao não pode ser maior que o Valor Total das Parcelas!'
      );
      formRef.current?.setFieldValue('valorLiquidacao', 0);
    }

    if (Number(data.valorLiquidacao) === 0) {
      formRef.current?.setFieldError(
        'valorLiquidacao',
        'Valor Liquidacao Deve ser Maior que Zero!'
      );
      listaMensagemErro.push('Valor Liquidacao Deve ser Maior que Zero!');
    }

    if (!data.considerarDataVencimento && !data.dataLiquidacao) {
      formRef.current?.setFieldError(
        'dataLiquidacao',
        'Data Liquidação é Obrigatória!'
      );

      listaMensagemErro.push('Data Liquidação é Obrigatória!');
    }

    if (!data.considerarDataVencimento && !data.dataContabil) {
      formRef.current?.setFieldError(
        'dataContabil',
        'Data Contábil é Obrigatória!'
      );

      listaMensagemErro.push('Data Contábil é Obrigatória!');
    }

    if (!data.idFormaPagamento) {
      formRef.current?.setFieldError(
        'idFormaPagamento',
        'Forma de Pagamento é Obrigatória!'
      );

      listaMensagemErro.push('Forma de Pagamento é Obrigatória!');
    }

    if (!data.idPortador) {
      formRef.current?.setFieldError('idPortador', 'Portador é Obrigatório!');

      listaMensagemErro.push('Portador é Obrigatório!');
    }

    if (data.listaLiquidacaoContasEmLote) {
      data.listaLiquidacaoContasEmLote.forEach((item: any) => {
        if (String(item.valorTotalAberto).ConverterParaNumber() < 0) {
          listaMensagemErro.push(
            `Valor Total Aberto da Parcela ${item.numeroDocumento} deve ser maior que Zero!`
          );
        }
      });
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, []);

  const handleClickSalvar = useCallback(
    async (data: any): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const dados = formRef.current?.getDataDuplicar();
        if (dados) {
          dados.listaLiquidacaoContasEmLote = listaDados;
          dados.idPortador = dados.portador ? dados.portador.id : null;
          dados.idFormaPagamento = dados.formaPagamento
            ? dados.formaPagamento.id
            : null;
        }

        if (dados.listaLiquidacaoContasEmLote) {
          for (let i = 0; i < dados.listaLiquidacaoContasEmLote.length; i++) {
            const dado = dados.listaLiquidacaoContasEmLote[i];

            if (
              dado.listaContaParcelaCategoria &&
              dado.listaContaParcelaCategoria.length > 0
            ) {
              const valoresCategoria = await calcularValorCampoCategoriaParcela(
                dado.listaContaParcelaCategoria
              );

              const listaMovimentoPortadorCategoria: any[] = [];

              dado.valorGeral = dado.valorParcela;

              dado.listaContaParcelaCategoria.map(async (item: any) => {
                const valorCategoria =
                  await calcularValorTotalCategoriaParaLiquidacao(
                    item,
                    dado,
                    valoresCategoria
                  );

                if (valorCategoria > 0) {
                  const categoriaFiltrada = dado.conta.listaContaCategoria.find(
                    (it: any) =>
                      it.idCategoria === item.idCategoria &&
                      it.tipoLancamento === item.tipoLancamento
                  );

                  listaMovimentoPortadorCategoria.push({
                    ...item,
                    id: undefined,
                    valor: valorCategoria,
                    percentualCategoria:
                      (valorCategoria / Number(categoriaFiltrada.valor)) * 100,
                  });
                }
              });

              dado.listaMovimentoPortadorCategoria =
                listaMovimentoPortadorCategoria;

              calcularRateioCategoriasNaoLiquidada(
                dado,
                listaMovimentoPortadorCategoria
              );
            }
          }
        }

        if (!handleValidar(dados)) {
          setLoading(false);
          return;
        }

        await LiquidacaoContasEmLoteComunicador.store({
          params: dados,
        });

        if (onSalvarFormModal) onSalvarFormModal();
        setListaDados([]);
        setLoading(false);
        ToastSucesso('Liquidação Salva!');
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularRateioCategoriasNaoLiquidada,
      calcularValorCampoCategoriaParcela,
      calcularValorTotalCategoriaParaLiquidacao,
      handleValidar,
      listaDados,
      onSalvarFormModal,
    ]
  );

  const handleCarregarDados = useCallback(() => {
    // Es6 - Remover referência do Array Original

    const listaDadosSemReferencia = JSON.parse(
      JSON.stringify(listaValor)
    ) as ILiquidacaoContasEmLoteLista[];

    const listaValoresFiltrados = listaDadosSemReferencia.filter((item) => {
      return item.selecionado;
    });

    const valorTotalParcelas = listaValoresFiltrados.reduce((acc, item) => {
      acc += Number(item.valorTotalAberto);

      return acc;
    }, 0);

    formRef.current?.setDataInicial({
      considerarDataVencimento: false,
      valorTotalParcelasSelecionadas: valorTotalParcelas,
      quantidadeParcelasSelecionadas: listaValoresFiltrados.length,
    });

    setListaDados(listaValoresFiltrados);
  }, [formRef, listaValor]);

  const handleCalculo = useCallback(
    (valorTotalLiquidado: number, valorAnteriorLiquidacao: number) => {
      if (valorTotalLiquidado !== valorAnteriorLiquidacao) {
        let diferencaAnterior = valorTotalLiquidado - valorAnteriorLiquidacao;
        // aumentando o valor
        if (diferencaAnterior > 0) {
          for (let i = 0; i < listaDados.length; i++) {
            const dado = listaDados[i];

            const valorTotalAberto = Number(dado.valorTotalAberto);
            const valorTotalMovimentos = Number(dado.valorTotalMovimentos);
            const valorLiquidacao = Number(dado.valorLiquidacao ?? 0);

            if (valorTotalAberto > 0 && diferencaAnterior > 0) {
              // se a diferenca for maior que o total em aberto da linha
              if (diferencaAnterior > valorTotalAberto) {
                dado.valorTotalMovimentos = dado.valorTotalAberto;
                dado.valorLiquidacao = dado.valorTotalAberto;
                dado.valorTotalAberto = 0;
                diferencaAnterior -= valorTotalAberto;
              } else {
                // se a diferenca for menor que o total em aberto da linha
                dado.valorTotalMovimentos =
                  valorTotalMovimentos + diferencaAnterior;
                dado.valorLiquidacao = diferencaAnterior;
                dado.valorTotalAberto -= diferencaAnterior;
                diferencaAnterior = 0;
              }
              listaConteudoRef.current[i].value = Number(
                dado.valorLiquidacao
              ).FormatarParaPtBr();
            }
          }
        }
        // diminuindo o valor
        else if (diferencaAnterior < 0) {
          let diferencaAnteriorPositiva = diferencaAnterior * -1;
          for (let i = listaDados.length - 1; i >= 0; i--) {
            const dado = listaDados[i];

            const valorTotalMovimentos = Number(dado.valorTotalMovimentos);
            const valorLiquidacao = Number(dado.valorLiquidacao ?? 0);

            if (valorTotalMovimentos > 0 && diferencaAnteriorPositiva > 0) {
              // se a diferenca for maior que o total em movimentos
              if (diferencaAnteriorPositiva > valorTotalMovimentos) {
                dado.valorTotalAberto += dado.valorTotalMovimentos;
                diferencaAnteriorPositiva -= dado.valorTotalMovimentos;
                dado.valorTotalMovimentos = 0;
                dado.valorLiquidacao = 0;
              } else {
                // se a diferenca for menor que o total dos movimentos

                dado.valorTotalMovimentos -= diferencaAnteriorPositiva;
                dado.valorLiquidacao =
                  valorLiquidacao - diferencaAnteriorPositiva;
                dado.valorTotalAberto += diferencaAnteriorPositiva;
                diferencaAnteriorPositiva = 0;
              }

              listaConteudoRef.current[i].value = Number(
                dado.valorLiquidacao
              ).FormatarParaPtBr();
            }
          }
        }
      }
    },
    [listaDados]
  );

  const handleCalcularValorLiquidacao = useCallback(
    async (valorTotalLiquidado: number, valorAnteriorLiquidacao: number) => {
      handleCalculo(0, valorAnteriorLiquidacao);
      handleCalculo(valorTotalLiquidado, 0);

      setListaDados([...listaDados]);
    },
    [handleCalculo, listaDados]
  );

  const handleCalcularValorLista = useCallback(
    (
      valorLiquidacao: number,
      valorLiquidacaoAnterior: number,
      index: number
    ) => {
      const diferenca = (
        Number(valorLiquidacao) - Number(valorLiquidacaoAnterior)
      ).Arredondar();

      listaDados[index].valorTotalMovimentos =
        Number(listaDados[index].valorTotalMovimentos) + diferenca;

      if (diferenca > 0) {
        listaDados[index].valorTotalAberto -= diferenca;
      } else {
        listaDados[index].valorTotalAberto += diferenca * -1;
      }

      let valorTotalLiquidacao =
        formRef.current?.getFieldValue('valorLiquidacao');

      valorTotalLiquidacao += diferenca;

      formRef.current?.setFieldValue('valorLiquidacao', valorTotalLiquidacao);

      setListaDados([...listaDados]);
    },
    [listaDados]
  );

  const handleDesabilitarHabilitarCamposData = useCallback(() => {
    const considerarDataVencimento = formRef.current?.getFieldValue(
      'considerarDataVencimento'
    );
    const listaCampos = ['dataLiquidacao', 'dataContabil'];
    listaCampos.forEach((campo) =>
      formRef.current?.setFieldDisabled(campo, considerarDataVencimento)
    );

    if (considerarDataVencimento) {
      listaCampos.forEach((campo) => {
        formRef.current?.clearField(campo);
      });
    }
  }, []);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Liquidação das Parcelas"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRef}>
            <Divisor>
              <InputHidden name="alterouLista" />
              <Row>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorTotalParcelasSelecionadas"
                    label="Valor Total das Parcelas Selecionadas"
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputInteiro
                    name="quantidadeParcelasSelecionadas"
                    label="Quantidade de Parcelas Selecionadas"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorLiquidacao"
                    label="Valor Liquidação"
                    onBlurCia={(ev, props) => {
                      handleCalcularValorLiquidacao(
                        ev.target.value.ConverterParaNumber(),
                        props.valorAnteriorOnBlur.ConverterParaNumber()
                      );
                    }}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} sm={6}>
                  <InputDate name="dataLiquidacao" label="Data Liquidação" />
                </Col>
                <Col xl={2} lg={4} md={6} sm={6}>
                  <InputDate name="dataContabil" label="Data Contábil" />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputSwitch
                    name="considerarDataVencimento"
                    label="Considerar Data Vencimento"
                    ativo="Sim"
                    inativo="Não"
                    onChange={handleDesabilitarHabilitarCamposData}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompleteFormaPagamento
                    name="idFormaPagamento"
                    label="Forma de Pagamento"
                    nomeObjeto="formaPagamento"
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompletePortador
                    name="idPortador"
                    label="Portador"
                    nomeObjeto="portador"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompleteContaTipoAdiantamentoEnum
                    nomeObjeto="contaAdiantamento"
                    name="idContaAdiantamento"
                    label="Adiantamento"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Tabela style={{ overflow: 'auto' }}>
                    <Table striped hover bordered variant="light">
                      <thead>
                        <tr>
                          <th className="lista-texto" style={{ width: '9%' }}>
                            <span>Tipo</span>
                          </th>
                          <th className="lista-valor" style={{ width: '6%' }}>
                            <span>Número</span>
                          </th>
                          <th className="lista-valor" style={{ width: '6%' }}>
                            <span>Parcela</span>
                          </th>
                          <th className="lista-data" style={{ width: '9%' }}>
                            <span>Data Hora Emissao</span>
                          </th>
                          <th className="lista-data" style={{ width: '9%' }}>
                            <span>Data Vencimento</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Parcela</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Parcela</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Mov.</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Liquid.</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Aberto</span>
                          </th>
                          <th className="lista-valor" style={{ width: '12%' }}>
                            <span>Cliente/Fornecedor</span>
                          </th>
                          <th className="lista-valor" style={{ width: '12%' }}>
                            <span>Cliente/Fornecedor Conta Vinculada</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {listaDados.map((item, index) => {
                          const listaCodigoNomeRazaoSocial: string[] = [];

                          item.conta.listaContaParcelaReversao?.map(
                            // eslint-disable-next-line array-callback-return
                            (data: any) => {
                              const valor = `${data.contaParcelaOrigem.conta.pessoa.codigo} - ${data.contaParcelaOrigem.conta.pessoa.nomeRazaoSocial}`;

                              if (!listaCodigoNomeRazaoSocial.includes(valor)) {
                                listaCodigoNomeRazaoSocial.push(valor);
                              }
                            }
                          );

                          return (
                            <tr key={v4()}>
                              <td
                                className="lista-texto"
                                style={{
                                  color:
                                    item.conta.tipo === TipoContaEnum.receber
                                      ? 'green'
                                      : 'red',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.conta.tipo}
                              </td>
                              <td className="lista-valor">
                                {item.numeroDocumento}
                              </td>
                              <td className="lista-texto">
                                {`${item.sequencia}/${item.conta.quantidadeParcelas}`}
                              </td>
                              <td className="lista-texto">
                                {FormatarDataHoraParaPtBr(
                                  item.conta.dataHoraEmissao
                                )}
                              </td>
                              <td className="lista-texto">
                                {FormatarDataHoraParaPtBr(item.dataVencimento)}
                              </td>
                              <td className="lista-valor">
                                {Number(item.valorParcela).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalParcela
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalMovimentos
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                <InputTabelaDecimal
                                  valorPadrao={
                                    item.valorLiquidacao &&
                                    item.valorLiquidacao >= 0
                                      ? item.valorLiquidacao
                                      : 0
                                  }
                                  onBlurCia={(ev) => {
                                    handleCalcularValorLista(
                                      String(ev.valor).ConverterParaNumber(),
                                      String(
                                        ev.valorAnteriorOnBlur
                                      ).ConverterParaNumber(),
                                      index
                                    );
                                  }}
                                  onChange={(ev) => {
                                    item.valorLiquidacao = String(
                                      ev.currentTarget.value
                                    ).ConverterParaNumber();
                                  }}
                                  name="valorLiquidacao"
                                  obterRef={(instance) => {
                                    if (
                                      instance.current &&
                                      listaConteudoRef.current
                                    ) {
                                      listaConteudoRef.current[index] =
                                        instance.current;
                                    }
                                  }}
                                  casasDecimais={4}
                                />
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalAberto
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {item.conta.pessoa
                                  ? `${item.conta.pessoa.codigo}-${item.conta.pessoa.nomeRazaoSocial}`
                                  : ''}
                              </td>
                              <td className="lista-valor">
                                {listaCodigoNomeRazaoSocial.length > 0
                                  ? listaCodigoNomeRazaoSocial.map((valor) => {
                                      return (
                                        <div key={valor}>{`${valor};`}</div>
                                      );
                                    })
                                  : ''}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tabela>
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleClickVoltar}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Salvar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default FinanceiroDetalhe;
