"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoProducaoEnum;
(function (TipoProducaoEnum) {
    TipoProducaoEnum["propria"] = "Pr\u00F3pria";
    TipoProducaoEnum["porTerceiros"] = "Por Terceiros";
    TipoProducaoEnum["industrializacaoParaTerceiros"] = "Indust. para Terceiros";
})(TipoProducaoEnum || (TipoProducaoEnum = {}));
exports.default = TipoProducaoEnum;
