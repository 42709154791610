import {
  ITabelaPrecoFiltroRelatorioBackend,
  ITabelaPrecoProdutosValoresCabecalhoRelatorio,
  ITabelaPrecoProdutosValoresRelatorio,
  ITabelaPrecoFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
  StringConverterParaEnum,
  FormatarDataParaPtBr,
} from '@elogestor/util';
import React from 'react';
import TabelaPrecoProdutosValoresRelatorioComunicador from '../../../../../../Comunicador/Comercial/Relatorios/TabelaPreco/TabelaPrecoProdutoValoresRelatorio/TabelaPrecoProdutoValoresRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import TabelaPrecoProdutosValoresPDF from './TabelaPrecoProdutosValoresPDF';
import TabelaPrecoProdutosValoresPlanilha from './TabelaPrecoProdutosValoresPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: ITabelaPrecoFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const TabelaPrecoProdutosValoresRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: ITabelaPrecoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<ITabelaPrecoProdutosValoresRelatorio | null> => {
    const filtroBack: ITabelaPrecoFiltroRelatorioBackend = {
      data: filtros.data,
      numeroNotaFiscalTerceiro: filtros.numeroNotaFiscalTerceiro,
      serieNotaFiscalTerceiro: filtros.serieNotaFiscalTerceiro,

      somenteProdutosComValorDeVenda: filtros.somenteProdutosComValorDeVenda,
      somenteProdutosComValorDeVendaZerados:
        filtros.somenteProdutosComValorDeVendaZerados,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros.listaFornecedores) {
      filtroBack.listaIdFornecedores =
        filtros.listaFornecedores.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaGruposProdutoServico) {
      filtroBack.listaIdGrupos =
        filtros.listaGruposProdutoServico.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaTabelasPreco) {
      filtroBack.listaIdTabelaPreco =
        filtros.listaTabelasPreco.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (
      filtros?.produtosAtivos === 'true' ||
      filtros?.produtosAtivos === 'false'
    ) {
      filtroBack.produtosAtivos = filtros?.produtosAtivos === 'true';
    }
    const response = await TabelaPrecoProdutosValoresRelatorioComunicador.index(
      {
        params: { filtro: filtroBack },
      }
    );

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno = response as ITabelaPrecoProdutosValoresRelatorio;
    const listaRegraTabelaPreco = await Promise.all(
      retorno.listaDados.map((dados) => {
        const listaProdutoRegraEscolhaTabelaPreco =
          dados.listaProdutoRegraEscolhaTabelaPreco.map((item) => {
            return {
              ...item,
              valorUnitarioVenda: Number(
                item.valorUnitarioVenda
              ).FormatarParaPtBr(),
            };
          });

        return {
          ...dados,
          listaProdutoRegraEscolhaTabelaPreco,
          dataFinalVigencia: dados.dataFinalVigencia
            ? FormatarDataParaPtBr(dados.dataFinalVigencia)
            : '--/--/--',
          dataInicioVigencia: dados.dataInicioVigencia
            ? FormatarDataParaPtBr(dados.dataInicioVigencia)
            : '--/--/--',
        };
      })
    );

    return {
      ...retorno,
      listaDados: listaRegraTabelaPreco,
    };
  };

  function FormatarCabecalho(
    filtros: ITabelaPrecoFiltroRelatorioFrontend,
    cabecalho: ITabelaPrecoProdutosValoresCabecalhoRelatorio
  ): ITabelaPrecoProdutosValoresCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.produtosAtivos) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroProdutosAtivos =
        filtros.produtosAtivos === 'true'
          ? 'Produtos Ativos: Sim'
          : 'Produtos Ativos';
    }

    if (filtros.somenteProdutosComValorDeVenda) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteProdutoComValorVenda =
        filtros.somenteProdutosComValorDeVenda
          ? 'Somente Produtos Com Valor De Venda: Sim'
          : 'Somente Produtos Com Valor De Venda: Não';
    }

    if (filtros.somenteProdutosComValorDeVendaZerados) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomenteProdutoComValorVendaZerado =
        filtros.somenteProdutosComValorDeVendaZerados
          ? 'Somente Produtos Com Valor De Venda Zerado: Sim'
          : 'Somente Produtos Com Valor De Venda Zerado: Não';
    }

    if (filtros.data) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroData = FormatarDataParaPtBr(filtros.data);
    }

    if (filtros.numeroNotaFiscalTerceiro) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroNumeroNotaFiscalTerceiro =
        filtros.numeroNotaFiscalTerceiro;
    }

    if (filtros.serieNotaFiscalTerceiro) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSerieNotaFiscalTerceiro = filtros.serieNotaFiscalTerceiro;
    }

    if (
      filtros.listaTabelasPreco &&
      filtros.listaTabelasPreco.listaValor.length > 0
    ) {
      cabecalho.filtroTabelaPreco = filtros.listaTabelasPreco.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (
      filtros.listaFornecedores &&
      filtros.listaFornecedores.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroFornecedor = filtros.listaFornecedores.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (
      filtros.listaGruposProdutoServico &&
      filtros.listaGruposProdutoServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGruposProdutoServico.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: TabelaPrecoProdutosValoresPlanilha,
      valores: dados.listaDados,
      nomeArquivo: 'TabelaPrecProdutosoValores',
      unwind: ['listaProdutoRegraEscolhaTabelaPreco'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: TabelaPrecoProdutosValoresPlanilha,
      nomeArquivo: 'TabelaPrecoProdutosValores',
      valores: dados.listaDados as any,
      unwind: ['listaProdutoRegraEscolhaTabelaPreco'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <TabelaPrecoProdutosValoresPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: ITabelaPrecoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <TabelaPrecoProdutosValoresPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default TabelaPrecoProdutosValoresRelatorio();
