import React, { useState, useEffect } from 'react';
import { FaSearch, FaUser } from 'react-icons/fa/index.mjs';
import { MdNotificationsNone } from 'react-icons/md/index.mjs';
import { IoIosArrowDown } from 'react-icons/io/index.mjs';
import { AiOutlineClose, AiOutlineMenuFold } from 'react-icons/ai/index.mjs';
import { FiLogOut } from 'react-icons/fi/index.mjs';
import loadable from '@loadable/component';
import { useNavigate, Link } from 'react-router-dom';
import { RiLockPasswordLine } from 'react-icons/ri/index.mjs';
import logo from '../../Assets/logo.svg';
import { UseAuth, usuarioLogado } from '../../Hooks/Auth';
import {
  Container,
  Header,
  Main,
  Aside,
  Notificacoes,
  EmpresaPessoa,
  Usuario,
  Modal,
  Pessoa,
  Empresa,
  DivTodaTela,
  ButtonHeader,
} from './styles';
import TratarErros from '../../Util/Erro/TratarErros';
import { ReactSizeMeBodyHookProvider } from '../../Hooks/ReactSizeMeBodyHook';
import { UseReactSizeMeGlobalHook } from '../../Hooks/ReactSizeMeGlobalHook';
import { UseMenuPrincipal } from '../../Hooks/MenuPrincipalHook';
import Conexao from './Conexao';
import InputAutoCompleteMenu from './AutoCompleteMenu';
import SubdominioEmpresaMenuComunicador from '../../Comunicador/Configuracao/Subdominio/Comunicador/SubdominioEmpresaMenuComunicador';
import EmpresaNaoAutenticadaComunicador from '../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaNaoAutenticadaComunicador';
import UsuarioSubdominioEmpresaSelecionadaComunicador from '../../Comunicador/Configuracao/Usuario/SubdominioEmpresaSelecionada/Comunicador/UsuarioSubdominioEmpresaSelecionadaComunicador';
import EmpresaPlanoFundoComunicador from '../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaPlanoFundoComunicador';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import AlterarSenhaHook from '../../Paginas/AlterarSenha/Hooks';
import AlterarSenhaDetalhe from '../../Paginas/AlterarSenha/Detalhe';

const MenuPrincipal = loadable(() => import('./MenuPrincipal'));

interface IEmpresa {
  id: string;
  nomeSistema: string;
}

type IListaEmpresa = Array<{
  empresa: IEmpresa;
}>;

const PadaoLayout: React.FC<IPadraoProps> = ({ children }) => {
  const {
    menuMinimizado,
    handleToggleMenu,
    handleMouseEntrou,
    handleMouseSaiu,
  } = UseMenuPrincipal();
  const { telaGrande, telaPequena } = UseReactSizeMeGlobalHook();
  const { signOut } = UseAuth();
  const navigate = useNavigate();

  const [listaEmpresa, setListaEmpresa] = useState<IListaEmpresa>([]);
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [planoFundo, setUrlPlanoFundo] = useState<IEmpresa>();

  const [empresaVisivel, setEmpresaVisivel] = useState(false);
  const [pessoaVisivel, setPessoaVisivel] = useState(false);
  const [alterarSenhaShow, setAlterarSenhaShow] = useState(false);

  const [pesquisaMenuAberta, setPesquisaMenuAberta] = useState(false);

  useEffect(() => {
    async function BuscarEmpresas(): Promise<void> {
      try {
        const response = await SubdominioEmpresaMenuComunicador.index();
        setListaEmpresa(response.listaEmpresaSubdominio);
      } catch (error) {
        TratarErros(error);
      }
    }

    async function BuscarEmpresa(): Promise<void> {
      try {
        const response = await EmpresaNaoAutenticadaComunicador.show();
        setEmpresa(response);
      } catch (error) {
        TratarErros(error);
      }
    }

    BuscarEmpresas();
    BuscarEmpresa();
  }, []);

  useEffect(() => {
    async function buscarPlanoFundo(): Promise<void> {
      try {
        const response = await EmpresaPlanoFundoComunicador.show();
        setUrlPlanoFundo(response);
      } catch (error) {
        TratarErros(error);
      }
    }

    buscarPlanoFundo();
  }, []);

  return (
    <Container $telaPequena={telaPequena} $menuMinimizado={menuMinimizado}>
      <Conexao />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <a className="logo" href="/">
          <img width="100" height="100" src={logo} alt="" />
        </a> */}
        <Link to="/home" className="logo">
          <img width="100" height="100" src={logo} alt="" />
        </Link>
        {menuMinimizado && telaPequena && (
          <button
            type="button"
            onClick={handleToggleMenu}
            style={{ background: 'none', border: 0, marginRight: 10 }}
          >
            <AiOutlineClose size={35} color="#fff" />
          </button>
        )}
      </div>

      <Header $menuMinimizado={menuMinimizado}>
        <div className="primeiro">
          <ButtonHeader
            type="button"
            className="menuToggle"
            onClick={handleToggleMenu}
          >
            <AiOutlineMenuFold />
          </ButtonHeader>

          <InputAutoCompleteMenu
            hidden={telaPequena && !pesquisaMenuAberta}
            name="listaMenu"
            pesquisaMenuAberta={pesquisaMenuAberta}
            setPesquisaMenuAberta={(value: boolean) => {
              setPesquisaMenuAberta(value);
            }}
          />

          {telaPequena && !pesquisaMenuAberta && (
            <ButtonHeader
              type="button"
              onClick={() => {
                setPesquisaMenuAberta((state) => !state);
              }}
              style={{
                border: 0,
                borderColor: 'none',
                boxShadow: 'none',
                color: '#fff',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
              }}
            >
              <FaSearch style={{ height: 22, width: 22 }} />
            </ButtonHeader>
          )}
        </div>

        <div
          style={{
            display: telaGrande || !pesquisaMenuAberta ? 'flex' : 'none',
          }}
          className="segundo"
        >
          <Notificacoes>
            <ButtonHeader type="button">
              <MdNotificationsNone color="#fff" />
            </ButtonHeader>
          </Notificacoes>

          <EmpresaPessoa>
            {telaGrande && (
              <ButtonHeader
                type="button"
                onClick={() => {
                  setPessoaVisivel((state) => !state);
                  setEmpresaVisivel(false);
                }}
              >
                <span
                  style={{ marginRight: 10, fontSize: telaGrande ? 14 : 11 }}
                >
                  {usuarioLogado.nome}
                </span>
                <IoIosArrowDown style={{ height: 20, width: 20 }} />
              </ButtonHeader>
            )}

            <ButtonHeader
              type="button"
              onClick={() => {
                setEmpresaVisivel((state) => !state);
                setPessoaVisivel(false);
              }}
            >
              <span style={{ marginRight: 10, fontSize: telaGrande ? 14 : 11 }}>
                {empresa?.nomeSistema}
              </span>
              <IoIosArrowDown style={{ height: 20, width: 20 }} />
            </ButtonHeader>

            <Modal $visivel={empresaVisivel}>
              <DivTodaTela
                onClick={() => {
                  setEmpresaVisivel(false);
                }}
              />
              <Empresa>
                <ul>
                  {listaEmpresa.map((valor) => (
                    <li key={valor.empresa.id}>
                      <ButtonHeader
                        type="button"
                        onClick={async () => {
                          try {
                            let utcFront = new Date()
                              .toTimeString()
                              .slice(12, 17);
                            utcFront = utcFront.Insert(3, ':');

                            const response =
                              await UsuarioSubdominioEmpresaSelecionadaComunicador.update(
                                { id: valor.empresa.id, params: { utcFront } }
                              );

                            if (response && response.token) {
                              localStorage.setItem(
                                '@EloGestorle:token',
                                response.token
                              );
                            }
                            if (response && response.empresa) {
                              localStorage.setItem(
                                '@EloGestorle:empresa',
                                JSON.stringify(response.empresa)
                              );
                            }
                            document.location.reload();
                            sessionStorage.removeItem(
                              '@EloGestorle:pesquisaAvancada'
                            );
                          } catch (error) {
                            TratarErros(error);
                          }
                        }}
                      >
                        {valor.empresa.nomeSistema}
                      </ButtonHeader>
                    </li>
                  ))}
                </ul>
              </Empresa>
            </Modal>
          </EmpresaPessoa>

          <Usuario>
            <button
              type="button"
              onClick={() => {
                setPessoaVisivel((state) => !state);
              }}
            >
              <FaUser color="#fff" size={30} />
            </button>

            <Modal $visivel={pessoaVisivel}>
              <DivTodaTela
                onClick={() => {
                  setPessoaVisivel(false);
                }}
              />
              <Pessoa>
                <ul>
                  <li>
                    <ButtonHeader
                      type="button"
                      onClick={() => {
                        navigate(
                          `/configuracao/usuario/detalhe/${usuarioLogado.id}`
                        );
                        setPessoaVisivel(false);
                      }}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <FaUser size={15} /> Meu Usuário
                    </ButtonHeader>
                  </li>
                  <li>
                    <ButtonHeader
                      type="button"
                      onClick={() => {
                        navigate(`/home`);
                        setAlterarSenhaShow(true);
                      }}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <RiLockPasswordLine size={15} /> Alterar Senha
                    </ButtonHeader>
                  </li>
                  <li>
                    <ButtonHeader
                      type="button"
                      onClick={signOut}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <FiLogOut size={15} /> Sair
                    </ButtonHeader>
                  </li>
                </ul>
              </Pessoa>
            </Modal>

            {alterarSenhaShow && (
              <AlterarSenhaHook>
                <AlterarSenhaDetalhe
                  onFecharFormModal={() => {
                    setAlterarSenhaShow(false);
                  }}
                  onSalvarFormModal={() => {
                    setAlterarSenhaShow(false);
                  }}
                />
              </AlterarSenhaHook>
            )}
          </Usuario>
        </div>
      </Header>

      <ReactSizeMeBodyHookProvider>
        <Main
          id="main"
          style={{
            backgroundImage: `url(${planoFundo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
            backgroundBlendMode: 'overlay',
          }}
        >
          {children}
        </Main>
      </ReactSizeMeBodyHookProvider>

      <Aside
        $menuMinimizado={menuMinimizado}
        $telaGrande={telaGrande}
        $telaPequena={telaPequena}
        className="aside"
        onMouseEnter={handleMouseEntrou}
        onMouseLeave={handleMouseSaiu}
      >
        <MenuPrincipal />
      </Aside>
    </Container>
  );
};

export default PadaoLayout;
