import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/conta/conta-revertida';

interface IContaRevertidaComunicadorIndex {
  params: {
    idOrcamento?: string;
    idPedidoVenda?: string;
    idNotaFiscalSaida?: string;
    idNotaFiscalEntrada?: string;
    idConhecimentoTransporte?: string;
    idConhecimentoTransporteTerceiro?: string;
    idOrdemServico?: string;
  };
}

class ContaRevertidaComunicador {
  public async index(params: IContaRevertidaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ContaRevertidaComunicador();
