/* eslint-disable prefer-destructuring */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Modal,
  Container,
  Button,
  Table,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataParaPtBr,
  IImportacaoNfeTerceiroEtapa1PedidoCompraLista,
} from '@elogestor/util';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineFileSearch,
  AiOutlineSearch,
} from 'react-icons/ai/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import Paginacao from './Paginacao';
import {
  Botoes,
  PesquisaGrande,
} from '../../../../../../../../Componentes/Lista/SubHeader/styles';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import OverlayPesquisaAvancada from '../../../../../../../../Componentes/Lista/OverlayPesquisaAvancada';
import { UseLista } from '../../../../../../../../Componentes/Lista/ListaHooks';
import { UseTableDraggable } from '../../../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import PesquisaAvancada from '../../../../../../Compras/PedidoCompra/Lista/PesquisaAvancada';
import ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicador from '../../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicador';
import InputTabelaDecimal from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IPesquisarListaModal {
  titulo: string;
  onConcluirVinculoPedido(listaIdItensSelecionados: any[]): void;
  listaEtapa1: any[];
  onFecharFormModal(): void;
  formRefPai: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const VinculoPedidoCompraLista: React.FC<IPesquisarListaModal> = ({
  titulo,
  onFecharFormModal,
  listaEtapa1,
  onConcluirVinculoPedido,
  formRefPai,
  loading,
  setLoading,
}) => {
  const {
    listaValor,
    setListaValor,
    paginaAtual,
    setPaginaAtual,
    limite,
    setLimite,
    setTotalPaginas,
  } = UseListaDraggable();

  const { telaPequena, telaGrande } = UseReactSizeMeBodyHook();

  const { abrirPesquisaAvancada } = UseLista();
  const { parametros, setParametros, overlayPesquisaAvancadaValores } =
    UseTableDraggable();
  const { abrirJanela } = UseConfirmacao();

  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaValorUtilizadoRef = useRef<HTMLInputElement[]>([]);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      let listaDados: IImportacaoNfeTerceiroEtapa1PedidoCompraLista[] = [];
      let quantidadePaginas = 1;

      const listaPedidosComprasVinculadosFront: any[] = [];

      listaEtapa1.forEach((etapa1, indexEtapa1) => {
        etapa1.listaItemDesmembrado.forEach(
          (_: any, indexDesmembrado: number) => {
            const listaPedidoCompraItem = formRefPai.current?.getFieldValue(
              `listaEtapa1[${indexEtapa1}].listaItemDesmembrado[${indexDesmembrado}].listaPedidoCompraItem`
            ).listaValor;
            if (listaPedidoCompraItem) {
              listaPedidoCompraItem.forEach((pedidoCompraItem: any) => {
                listaPedidosComprasVinculadosFront.push({
                  id: pedidoCompraItem.id,
                  quantidade: pedidoCompraItem.quantidadeUtilizada,
                });
              });
            }
          }
        );
      });
      const response =
        await ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicador.index(
          {
            params: {
              pesquisaAvancada: parametros.pesquisaAvancada,
              order: parametros.order,
              descAsc: parametros.descAsc,
              limite,
              pagina: paginaAtual,
              textoPesquisa: parametros.textoPesquisa,
              listaPedidosComprasVinculadosFront,
            },
          }
        );

      listaDados = response.dados[0].map((data: any) => {
        const retorno: IImportacaoNfeTerceiroEtapa1PedidoCompraLista = {
          ...data,
          selecionado: false,
          quantidadeUtilizada: 0,
        };

        return retorno;
      });

      quantidadePaginas = response.dados[1];
      setListaValor(listaDados);
      setTotalPaginas(quantidadePaginas);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRefPai,
    limite,
    listaEtapa1,
    paginaAtual,
    parametros.descAsc,
    parametros.order,
    parametros.pesquisaAvancada,
    parametros.textoPesquisa,
    setListaValor,
    setLoading,
    setTotalPaginas,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [paginaAtual, limite, parametros, handleCarregarDados]);

  // #region Pesquisa Avancada

  const [mostrarPesquisaPequena, setMostrarPesquisaPequena] = useState(false);

  const toggleMostrarPesquisaPequena = useCallback(() => {
    setMostrarPesquisaPequena((state) => !state);
  }, []);

  const mostrarPesquisaPequenaComTamanhoMaior = true;

  const debounceChangeRef = useRef<number | null>(null);
  const [campoPesquisa, setCampoPesquisa] = useState('');

  const handleSetarParametros = useCallback(
    async (texto: string): Promise<void> => {
      if (parametros.textoPesquisa !== texto) {
        if (debounceChangeRef.current) {
          clearTimeout(debounceChangeRef.current);
        }

        debounceChangeRef.current = window.setTimeout(async () => {
          setPaginaAtual(1);
          setParametros({
            pagina: 1,
            textoPesquisa: texto,
          });
        }, 400);
      } else {
        setPaginaAtual(1);
        setParametros({
          pagina: 1,
          textoPesquisa: texto,
        });
      }

      setPaginaAtual(1);
      setCampoPesquisa(texto);
    },
    [parametros.textoPesquisa, setPaginaAtual, setParametros]
  );

  // #endregion Pesquisa Avancada

  const handleAtualizarPadraoModal = useCallback(async (): Promise<void> => {
    setListaValor([]);
    setPaginaAtual(1);
    setTotalPaginas(1);
    setLimite(10);
    setParametros({
      pagina: 1,
      pesquisaAvancada: undefined,
    });
  }, [
    setLimite,
    setListaValor,
    setPaginaAtual,
    setParametros,
    setTotalPaginas,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    const possuiItensSelecionados = listaItemSelecionadoRef.current?.some(
      (item) => {
        return item?.checked;
      }
    );
    if (possuiItensSelecionados) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja cancelar o vínculo dos item do Pedido de Compra?
          </span>
        ),
      });

      if (resposta) {
        handleAtualizarPadraoModal();
        if (onFecharFormModal) onFecharFormModal();
      }
    } else {
      handleAtualizarPadraoModal();
      if (onFecharFormModal) onFecharFormModal();
    }
  }, [abrirJanela, handleAtualizarPadraoModal, onFecharFormModal]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item) {
          item.checked = checked;
          listaValor[index].selecionado = checked;
        }
      });
    },
    [listaValor]
  );

  const handleValidar = useCallback(
    (
      listaValoresSelecionados: IImportacaoNfeTerceiroEtapa1PedidoCompraLista[]
    ): boolean => {
      const listaMensagemErro: string[] = [];

      if (listaValoresSelecionados) {
        listaValoresSelecionados.forEach((item) => {
          if (Number(item.quantidade) - Number(item.quantidadeVinculada) < 0) {
            listaMensagemErro.push(
              `Quantidade do Pedido de Número ${item.pedidoCompra.numero} do Item ${item.ordem} deve ser maior que Zero!`
            );
          }
        });
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const listaValoresSelecionados = listaValor.filter(
      (item) => item.selecionado
    );

    if (!handleValidar(listaValoresSelecionados)) {
      return;
    }

    if (onConcluirVinculoPedido)
      onConcluirVinculoPedido(listaValoresSelecionados);

    onFecharFormModal();
  }, [handleValidar, listaValor, onConcluirVinculoPedido, onFecharFormModal]);

  const handleCalcularValorLista = useCallback(
    (
      valorLiquidacao: number,
      valorLiquidacaoAnterior: number,
      index: number
    ) => {
      const diferenca =
        Number(valorLiquidacao) - Number(valorLiquidacaoAnterior);

      if (diferenca > 0) {
        listaValor[index].quantidade -= diferenca;
      } else {
        listaValor[index].quantidade += diferenca * -1;
      }

      refresh();
    },
    [listaValor, refresh]
  );

  return (
    <JanelaDetalhe
      titulo={titulo}
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            {!parametros.pesquisaAvancada && (
              <Botoes $telaPequena={telaPequena}>
                <div className="pesquisa" style={{ marginTop: 0 }}>
                  <div>
                    {(!mostrarPesquisaPequena || telaGrande) && (
                      <input
                        type="text"
                        value={campoPesquisa}
                        onChange={(event) => {
                          handleSetarParametros(event.target.value);
                        }}
                      />
                    )}

                    <button
                      style={{
                        position:
                          !mostrarPesquisaPequena || telaGrande
                            ? 'absolute'
                            : 'initial',
                      }}
                      type="button"
                      onClick={toggleMostrarPesquisaPequena}
                    >
                      {mostrarPesquisaPequena || telaGrande ? (
                        <AiOutlineSearch />
                      ) : (
                        <AiOutlineClose />
                      )}
                    </button>
                  </div>
                </div>
              </Botoes>
            )}

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Popover id={v4()}>
                  <OverlayPesquisaAvancada
                    pesquisaAvancada={overlayPesquisaAvancadaValores || []}
                  />
                </Popover>
              }
            >
              <PesquisaGrande
                style={{
                  display: parametros.pesquisaAvancada ? 'flex' : 'none',
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    fontSize: 22,
                    height: 38,
                    width: 38,
                  }}
                  variant="secondary"
                  onClick={abrirPesquisaAvancada}
                >
                  <AiOutlineFileSearch />
                </Button>

                <div style={{ marginTop: 0 }}>
                  <span>Pesquisa Avançada</span>
                  <button
                    type="button"
                    onClick={async () => {
                      setParametros({
                        pagina: 1,
                        pesquisaAvancada: undefined,
                      });
                    }}
                  >
                    Remover Filtro
                  </button>
                </div>
              </PesquisaGrande>
            </OverlayTrigger>

            {mostrarPesquisaPequenaComTamanhoMaior && (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id={v4()}>Pesquisa Avançada</Tooltip>}
              >
                <Button
                  variant="secondary"
                  style={{
                    display: parametros.pesquisaAvancada ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    fontSize: 22,
                    height: 38,
                    width: 38,
                    marginRight: 15,
                    marginLeft: telaPequena ? 10 : 0,
                  }}
                  onClick={() => {
                    abrirPesquisaAvancada();
                  }}
                >
                  <AiOutlineFileSearch />
                </Button>
              </OverlayTrigger>
            )}

            <PesquisaAvancada />
          </Divisor>

          <Divisor>
            <div style={{ overflow: 'auto', height: 500, marginBottom: 10 }}>
              <Tabela>
                <Table striped hover bordered variant="light">
                  <thead>
                    <tr>
                      <th>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            ref={selecionarTodosRef}
                            type="checkbox"
                            onChange={(event) => {
                              handleSelecionarTodos(event.target.checked);
                            }}
                          />
                        </div>
                      </th>
                      <th
                        className="lista-valor"
                        style={{
                          width: '5%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Número</span>
                      </th>
                      <th
                        className="lista-valor"
                        style={{
                          width: '10%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Ordem</span>
                      </th>
                      <th
                        className="lista-data"
                        style={{
                          width: '15%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Data Hora Emissão</span>
                      </th>
                      <th
                        className="lista-texto"
                        style={{
                          width: '10%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Código</span>
                      </th>
                      <th
                        className="lista-texto"
                        style={{
                          width: '20%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Descrição</span>
                      </th>
                      <th
                        className="lista-valor"
                        style={{
                          width: '10%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Qtde Disp.</span>
                      </th>
                      <th
                        className="lista-texto"
                        style={{
                          width: '15%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Qtde Utiliz.</span>
                      </th>
                      <th
                        className="lista-texto"
                        style={{
                          width: '10%',
                          textAlign: 'start',
                          backgroundColor: 'white',
                        }}
                      >
                        <span>Unid.</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listaValor?.map((item, index) => {
                      return (
                        <>
                          <tr key={v4()}>
                            <td>
                              <input
                                type="checkbox"
                                defaultChecked={item.selecionado}
                                ref={(instance) => {
                                  if (
                                    listaItemSelecionadoRef &&
                                    listaItemSelecionadoRef.current
                                  ) {
                                    listaItemSelecionadoRef.current[index] =
                                      instance;
                                  }
                                }}
                                onChange={(event) => {
                                  item.selecionado =
                                    event.currentTarget.checked;
                                }}
                              />
                            </td>
                            <td className="lista-valor">
                              {item.pedidoCompra.numero}
                            </td>
                            <td className="lista-valor">{item.ordem}</td>
                            <td className="lista-data">
                              {FormatarDataParaPtBr(
                                item.pedidoCompra.dataHoraEmissao
                              )}
                            </td>
                            <td className="lista-valor">
                              {item.produto.codigo}
                            </td>
                            <td className="lista-texto">
                              {item.produto.descricao}
                            </td>
                            <td className="lista-valor">
                              {Number(
                                item.quantidade - item.quantidadeVinculada
                              ).FormatarParaPtBr({
                                maximoCasasDecimais: 4,
                                minimoCasasDecimais: 4,
                              })}
                            </td>
                            <td className="lista-valor">
                              <InputTabelaDecimal
                                valorPadrao={item.quantidadeUtilizada ?? 0}
                                max={Number(item.quantidade)}
                                onBlurCia={(ev) => {
                                  handleCalcularValorLista(
                                    String(ev.valor).ConverterParaNumber(),
                                    String(
                                      ev.valorAnteriorOnBlur
                                    ).ConverterParaNumber(),
                                    index
                                  );
                                }}
                                onChange={(ev) => {
                                  item.quantidadeUtilizada = String(
                                    ev.currentTarget.value
                                  ).ConverterParaNumber();

                                  if (!item.selecionado) {
                                    item.selecionado = true;
                                  }
                                }}
                                name="quantidadeUtilizada"
                                obterRef={(instance) => {
                                  if (
                                    instance.current &&
                                    listaValorUtilizadoRef.current
                                  ) {
                                    listaValorUtilizadoRef.current[index] =
                                      instance.current;
                                  }
                                }}
                                casasDecimais={4}
                              />
                            </td>
                            <td className="lista-texto">
                              {item.unidadeMedida.sigla}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Tabela>
            </div>

            <Paginacao />
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
              >
                <MdCancel />
                <span style={{ marginLeft: 10 }}>Cancelar</span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSubmit}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default VinculoPedidoCompraLista;
