import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { produce } from 'immer';
import { Spinner } from 'react-bootstrap';
import TratarErros from '../../Util/Erro/TratarErros';
import TableDraggableComDragTr from '../TableDraggableComDragTr';
import { UseLista } from '../Lista/ListaHooks';
import ITh from '../Lista/Interface/ITh';
import { Container } from '../Lista/styles';
import Paginacao from '../Lista/Paginacao';
import IPesquisa from '../Lista/Interface/IPesquisa';
import PersonalizacaoListaCamposSalvarTodosComunicador from '../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposSalvarTodosComunicador';
import PersonalizacaoListaCamposComunicador from '../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';

interface IDados {
  id: string;
  [key: string]: string | number | JSX.Element;
}

interface IPesquisarDadosResposta {
  dados: IDados[];
  totalPaginas: number;
}

interface ILista {
  salvarTh?: (newTh: ITh) => Promise<void>;
  salvarListaTh?: (newListaTh: ITh[]) => Promise<void>;
  pesquisarDados(params: IPesquisa): Promise<IPesquisarDadosResposta>;
  pesquisarListaTh(): Promise<ITh[]>;
  onAtualizarOrdem(
    listaComOrdem: Array<{ id: string; ordem: number }>
  ): Promise<void>;
  opcao1000?: boolean;
  paginacaoPadrao?: number;
  loading?: boolean;
}

const handleSalvarTh = async (newTh: ITh): Promise<void> => {
  try {
    const { id, ...rest } = newTh;
    await PersonalizacaoListaCamposComunicador.update({ id, params: rest });
  } catch (error) {
    TratarErros(error);
  }
};

const handleSalvarListaTh = async (newListaTh: ITh[]): Promise<void> => {
  try {
    await PersonalizacaoListaCamposSalvarTodosComunicador.update({
      params: newListaTh,
    });
  } catch (error) {
    TratarErros(error);
  }
};

const ListaComTrDraggable: React.FC<ILista> = ({
  salvarTh = handleSalvarTh,
  salvarListaTh = handleSalvarListaTh,
  pesquisarDados,
  pesquisarListaTh,
  onAtualizarOrdem,
  opcao1000 = false,
  paginacaoPadrao = 10,
  loading = false,
}) => {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  const {
    paginacao: { alterarTotalPaginas, limite },
  } = UseLista();

  const [dados, setDados] = useState<IDados[]>([]);

  const [listaTh, setListaTh] = useState<ITh[]>([]);

  const handlePesquisarDados = useCallback(
    async (parametros: IPesquisa) => {
      const reponse = await pesquisarDados(parametros);
      setDados(reponse.dados);
      alterarTotalPaginas(reponse.totalPaginas);
    },
    [alterarTotalPaginas, pesquisarDados]
  );

  useEffect(() => {
    async function SelecionarTh(): Promise<void> {
      const resposta = await pesquisarListaTh();
      setListaTh(resposta);
    }

    SelecionarTh();
  }, [pesquisarListaTh]);

  const handleChangeOrdem = useCallback(
    async (newListaThs: ITh[]) => {
      salvarListaTh(newListaThs);
    },
    [salvarListaTh]
  );

  const handleChangeTamanho = useCallback(
    async (newTh: ITh) => {
      salvarTh(newTh);
    },
    [salvarTh]
  );

  const handleChangeVisivel = useCallback(
    async (newTh: ITh) => {
      salvarTh(newTh);
    },
    [salvarTh]
  );

  const handleClickLinha = useCallback(
    (e: any) => {
      navigate(`${path}/detalhe/${e.id}`);
    },
    [navigate, path]
  );

  const handleGetKey = useCallback((item: any) => {
    return item.id;
  }, []);

  const handleMover = useCallback(async (de: number, para: number) => {
    setDados((state) =>
      produce(state, (draft) => {
        const valorDe = draft[de];
        const valorPara = draft[para];

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(
    async (paginaAtual: number) => {
      const ordemInicial = limite * (paginaAtual - 1);

      const listaDadoOrdem = dados.map((dado, index) => {
        return {
          id: dado.id,
          ordem: ordemInicial + index,
        };
      });
      await onAtualizarOrdem(listaDadoOrdem);
    },
    [dados, limite, onAtualizarOrdem]
  );

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '91vh',
            position: 'absolute',
            marginTop: -20,
            marginLeft: -20,
            zIndex: 999,
            backgroundColor: '#d3d3d3',
            opacity: 0.6,
          }}
        >
          <Spinner
            className="spinner"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{ width: 100, height: 100, color: '#3397ff' }}
          />
        </div>
      )}

      <Container>
        <div style={{ overflow: 'auto', minHeight: 180 }}>
          <TableDraggableComDragTr
            listaTh={listaTh}
            listaDado={dados}
            getKey={handleGetKey}
            onChangeOrdem={handleChangeOrdem}
            onChangeTamanho={handleChangeTamanho}
            onChangeVisivel={handleChangeVisivel}
            onClickLinha={handleClickLinha}
            onPesquisar={handlePesquisarDados}
            onMover={handleMover}
            onAtualizarOrdem={handleAtualizarOrdem}
          />
        </div>
        <Paginacao opcao1000={opcao1000} paginacaoPadrao={paginacaoPadrao} />
      </Container>
    </>
  );
};

export default ListaComTrDraggable;
