import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/roteiro-fabricacao-padrao-auto-complete';

interface IRoteiroFabricacaoPadraoAutoCompleteComunicadorIndex {
  params: any;
}

class RoteiroFabricacaoPadraoAutoCompleteComunicador {
  public async index(
    params: IRoteiroFabricacaoPadraoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RoteiroFabricacaoPadraoAutoCompleteComunicador();
