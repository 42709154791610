import { IImpressaoEtiquetasProdutosLista } from '@elogestor/util';
import React, {
  createContext,
  RefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IImpressaoEtiquetaContext {
  validador: boolean;

  listaEtiqueta: IImpressaoEtiquetasProdutosLista[];
  setListaEtiqueta(listaValro: IImpressaoEtiquetasProdutosLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
}

const ImpressaoEtiquetaContext = createContext<IImpressaoEtiquetaContext>(
  {} as IImpressaoEtiquetaContext
);

const ImpressaoEtiquetaHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaEtiqueta, setListaEtiqueta] = useState<
    IImpressaoEtiquetasProdutosLista[]
  >([]);
  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  return (
    <ImpressaoEtiquetaContext.Provider
      value={{
        listaEtiqueta,
        setListaEtiqueta,
        listaItemSelecionadoRef,
        validador: true,
      }}
    >
      {children}
    </ImpressaoEtiquetaContext.Provider>
  );
};

function UseImpressaoEtiqueta(): Omit<IImpressaoEtiquetaContext, 'validador'> {
  const context = useContext(ImpressaoEtiquetaContext);

  if (!context.validador) {
    throw new Error(
      'UseImpressaoEtiqueta deve ser usado com um ImpressaoEtiquetaHook'
    );
  }

  return context;
}

export { ImpressaoEtiquetaHook, UseImpressaoEtiqueta };
