/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import InputHidden from '../InputHidden';

export interface IOnChangeInputLabelEvent<T = any> {
  valor: T;
}

interface IInputProps {
  name: string;
  label: string;
  labelStyle?: React.CSSProperties;
  valorStyle?: React.CSSProperties;

  onChange?: ({ valor }: IOnChangeInputLabelEvent) => void;
  getValue?: ({ valor }: IOnChangeInputLabelEvent) => void;
  getDataDuplicar?: ({ valor }: IOnChangeInputLabelEvent) => void;
  getDataRecuperarFormulario?: ({ valor }: IOnChangeInputLabelEvent) => void;
  setValue?: (valor: any) => any;
  formatarValor?: (valor: any) => any;
}

const InputLabel = ({
  name,
  label,
  labelStyle,
  valorStyle,

  onChange,
  getValue,
  getDataDuplicar,
  getDataRecuperarFormulario,
  setValue,
  formatarValor,
}: IInputProps): any => {
  const valor = InputHidden({
    name,
    onChange,
    getValue,
    getDataDuplicar,
    getDataRecuperarFormulario,
    setValue,
    validarSeMudou: () => {
      return false;
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {label && <div style={{ marginBottom: 8, ...labelStyle }}>{label}</div>}
      <div
        style={{
          flex: 1,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 5,
          ...valorStyle,
        }}
      >
        {formatarValor ? formatarValor(valor) : valor}
      </div>
    </div>
  );
};

export default InputLabel;
