import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IOrcamentoRetratoComFinanceiroTransporteImpressao,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IOrcamentoRetratoComFinanceiroTransporteImpressaoPDF {
  dados: IOrcamentoRetratoComFinanceiroTransporteImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const OrcamentoRetratoSemFotoProdutoImpressaoPDF: React.FC<
  IOrcamentoRetratoComFinanceiroTransporteImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                style={{ width: 120, borderRadius: 0 }}
              />
            )}
          </View>
          <View style={{ width: '50%', alignItems: 'center' }}>
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                ORÇAMENTO
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {`CNPJ/CPF: ${dados.empresa.cnpjCpf} `}
                {dados.empresa.inscricaoEstadual &&
                  `/ IE: ${dados.empresa.inscricaoEstadual}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.logradouro}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`, ${dados.empresa.empresaEndereco.bairro}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.telefone && `${dados.empresa.telefone}, `}
                {dados.empresa.email && dados.empresa.email}
              </Text>
            </View>
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: '10pt',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {`Número: `}
                  </Text>
                </View>
                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {dados.numero}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Data Emissão: `}
                  </Text>
                </View>

                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 10 }}>
                    {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cliente: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {`${dados.orcamentoPessoa.pessoa.codigo} - ${dados.orcamentoPessoa.pessoa.nomeRazaoSocial}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {`Cnpj/Cpf: `}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.orcamentoPessoa.pessoa.cnpjCpf}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {`Endereço: `}
                </Text>
                <Text style={{ fontSize: 10, width: '61%' }}>
                  {dados.orcamentoPessoaEndereco.logradouro &&
                    `${dados.orcamentoPessoaEndereco.logradouro}, `}
                  {dados.orcamentoPessoaEndereco.numero &&
                    `${dados.orcamentoPessoaEndereco.numero}, `}
                  {dados.orcamentoPessoaEndereco.bairro &&
                    `${dados.orcamentoPessoaEndereco.bairro}`}
                </Text>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '7%' }}>
                  {`Compl.: `}
                </Text>
                <Text style={{ fontSize: 10, width: '23%' }}>
                  {dados.orcamentoPessoaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cidade: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.orcamentoPessoaEndereco?.cidade &&
                    `${dados.orcamentoPessoaEndereco?.cidade?.nome} - ${dados.orcamentoPessoaEndereco?.cidade?.estado.sigla}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cep: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.orcamentoPessoaEndereco.cep}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', width: '70%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '13%' }}
                  >
                    {'E-mail: '}
                  </Text>
                  <Text style={{ fontSize: 10, width: '87%' }}>
                    {dados.orcamentoPessoa.pessoa.email}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', width: '30%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '25%' }}
                  >
                    {'Telefone: '}
                  </Text>
                  <Text style={{ fontSize: 10, width: '75%' }}>
                    {dados.orcamentoPessoa.pessoa.telefone}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexDirection: 'row', width: '60%' }}>
              <Text style={{ fontSize: 10, fontWeight: 'bold', width: '25%' }}>
                {'Representante: '}
              </Text>
              <Text style={{ fontSize: 10, width: '85%', paddingLeft: 1 }}>
                {dados.orcamentoComercial &&
                dados.orcamentoComercial.listaOrcamentoComercialRepresentante &&
                dados.orcamentoComercial.listaOrcamentoComercialRepresentante
                  .length > 0
                  ? dados.orcamentoComercial
                      .listaOrcamentoComercialRepresentante[0]
                      .pessoaRepresentante.nomeRazaoSocial
                  : ''}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              backgroundColor: '#cdcdcd',
              fontSize: 9,
            }}
          >
            <View style={{ width: '4%', alignItems: 'flex-end' }}>
              <Text>Seq.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '48%' }}>
              <Text>Descrição</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Qtde.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Unit. (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Total (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          {dados.listaOrcamentoItem?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>{dado.ordem}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '48%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>{dado.unidadeMedida.sigla}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />
              </View>
            );
          })}

          <View
            style={{
              marginTop: '10pt',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '85%', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Valor Sub. Total: `}
                </Text>
              </View>
              <View
                style={{
                  width: '15%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.valorTotalProdutos).FormatarParaPtBr()}
                </Text>
              </View>
            </View>

            {dados.valorFrete && dados.valorFrete > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Frete: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorFrete).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorSeguro && dados.valorSeguro > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Seguro: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorSeguro).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorOutrasDespesas && dados.valorOutrasDespesas > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Outras Desp.: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorOutrasDespesas).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorDesconto && dados.valorDesconto > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Desconto: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorDesconto).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorTotalIcmsSt && dados.valorTotalIcmsSt > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Total ICMS ST.: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalIcmsSt).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorTotalIpi && dados.valorTotalIpi > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Total IPI: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalIpi).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '85%', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Valor Total: `}
                </Text>
              </View>

              <View
                style={{
                  width: '15%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>

          {dados.listaFormaPagamento &&
            dados.listaFormaPagamento?.length > 0 && (
              <View style={{ marginTop: '5pt' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{ fontSize: 10, width: '18%', fontWeight: 'bold' }}
                  >
                    Forma de Pagamento:
                  </Text>
                  <Text style={{ fontSize: 10, width: '85%' }}>
                    {dados.listaFormaPagamento
                      ? `${dados.listaFormaPagamento.reduce(
                          (acumulador: string, valorAtual: any) => {
                            acumulador += `${valorAtual.descricao}; `;
                            return acumulador;
                          },
                          ''
                        )}`
                      : ''}
                  </Text>
                </View>
              </View>
            )}

          {dados.listaConta &&
            dados.listaConta?.length > 0 &&
            dados.listaConta[0].listaContaParcela &&
            dados.listaConta[0].listaContaParcela.length > 0 && (
              <View style={{ marginTop: '5pt' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{ fontSize: 10, width: '8%', fontWeight: 'bold' }}
                  >
                    Portador:
                  </Text>
                  <Text style={{ fontSize: 10, width: '92%' }}>
                    {`${dados.listaConta[0].listaContaParcela.reduce(
                      (acumulador: string, valorAtual: any) => {
                        if (
                          valorAtual.portador &&
                          !acumulador.includes(valorAtual.portador.descricao)
                        ) {
                          acumulador += `${valorAtual.portador.descricao}; `;
                        }
                        return acumulador;
                      },
                      ''
                    )}`}
                  </Text>
                </View>
              </View>
            )}

          {dados.listaConta &&
            dados.listaConta?.length > 0 &&
            dados.listaConta[0].condicaoPagamento && (
              <View style={{ marginTop: '5pt' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{ fontSize: 10, width: '20%', fontWeight: 'bold' }}
                  >
                    Condição de Pagamento:
                  </Text>
                  <Text style={{ fontSize: 10, width: '80%' }}>
                    {dados.listaConta[0].condicaoPagamento}
                  </Text>
                </View>
              </View>
            )}

          {dados.listaConta && dados.listaConta?.length > 0 && (
            <View style={{ marginTop: '10pt' }}>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {dados.listaConta[0].listaContaParcela?.map((parcela) => {
                  return (
                    <Text style={{ fontSize: 10, width: '25%' }}>
                      {`${FormatarDataParaPtBr(
                        parcela.dataVencimento
                      )} - R$ ${Number(
                        parcela.valorTotalParcela
                      ).FormatarParaPtBr()}`}
                    </Text>
                  );
                })}
              </View>
            </View>
          )}

          {dados.orcamentoTransporteTransportadora && (
            <View style={{ marginTop: '5pt' }}>
              <View>
                <Text
                  style={{ fontSize: 10, width: '15%', fontWeight: 'bold' }}
                >
                  Transporte:
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, width: '12%' }}>
                  Transportadora:
                </Text>
                <Text style={{ fontSize: 10, width: '38%' }}>
                  {
                    dados.orcamentoTransporteTransportadora.pessoaTransportadora
                      ?.nomeRazaoSocial
                  }
                </Text>

                <Text style={{ fontSize: 10, width: '10%' }}>Modalidade:</Text>
                <Text style={{ fontSize: 10, width: '40%' }}>
                  {dados.orcamentoTransporteTransportadora?.modalidadeFrete}
                </Text>
              </View>
            </View>
          )}

          {dados.orcamentoTransporteVolume && (
            <View style={{ marginTop: '5pt' }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, width: '20%' }}>
                  {`Espécie: ${dados.orcamentoTransporteVolume.especie}`}
                </Text>

                <Text style={{ fontSize: 10, width: '20%' }}>
                  {`Quantidade: ${dados.orcamentoTransporteVolume.quantidade}`}
                </Text>

                <Text style={{ fontSize: 10, width: '30%' }}>
                  {`Peso Bruto: ${Number(
                    dados.orcamentoTransporteVolume.pesoBruto
                  ).FormatarParaPtBr()}`}
                </Text>

                <Text style={{ fontSize: 10, width: '30%' }}>
                  {`Peso Líquido: ${Number(
                    dados.orcamentoTransporteVolume.pesoBruto
                  ).FormatarParaPtBr()}`}
                </Text>
              </View>
            </View>
          )}

          {dados.observacoesComerciais && (
            <View
              style={{
                marginTop: '10pt',
                padding: '4pt',
                backgroundColor: '#eeeeee',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                  {dados.observacoesComerciais
                    ? 'OBSERVAÇÕES COMERCIAIS: '
                    : ''}
                </Text>
                <Text style={{ fontSize: 8, width: '100%', marginTop: '5pt' }}>
                  {dados.observacoesComerciais
                    ? dados.observacoesComerciais
                    : ''}
                </Text>
              </View>
            </View>
          )}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrcamentoRetratoSemFotoProdutoImpressaoPDF;
