"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var StatusTefEnum;
(function (StatusTefEnum) {
    StatusTefEnum["pendente"] = "Pendente";
    StatusTefEnum["aguardandoPagamento"] = "Aguardando Pagamento";
    StatusTefEnum["pago"] = "Pago";
    StatusTefEnum["cancelamentoIniciado"] = "Cancelamento Iniciado";
    StatusTefEnum["emCancelamento"] = "Em Cancelamento";
    StatusTefEnum["cancelado"] = "Cancelado";
})(StatusTefEnum || (StatusTefEnum = {}));
exports.default = StatusTefEnum;
