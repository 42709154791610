import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  INotaFiscalEntradaFiltroRelatorioBackend,
  INotaFiscalEntradaFiltroRelatorioFrontend,
  INotaFiscalEntradaTotalPorModeloDocumentoCabecalhoRelatorio,
  INotaFiscalEntradaTotalPorModeloDocumentoRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoDataEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
} from '@elogestor/util';
import React from 'react';
import NotaFiscalEntradaTotalPorModeloDocumentoRelatorioComunicador from '../../../../../../../Comunicador/Suprimentos/Relatorios/Compras/NotaFiscalEntrada/NotaFiscalEntradaTotalPorModeloDocumentoRelatorio/NotaFiscalEntradaTotalPorModeloDocumentoRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import NotaFiscalEntradaTotalPorModeloDocumentoPDF from './NotaFiscalEntradaTotalPorModeloDocumentoPDF';
import NotaFiscalEntradaTotalPorModeloDocumentoPlanilha from './NotaFiscalEntradaTotalPorModeloDocumentoPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const NotaFiscalEntradaTotalPorModeloDocumentoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<INotaFiscalEntradaTotalPorModeloDocumentoRelatorio | null> => {
    const filtroBack: INotaFiscalEntradaFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaFornecedor) {
      filtroBack.listaIdFornecedor =
        filtros.listaFornecedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = StringConverterParaEnum<
        typeof TipoDataEnum,
        TipoDataEnum
      >(TipoDataEnum, String(filtros.tipoData));
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }
    if (filtros?.tipoNotaFiscalEntrada) {
      filtroBack.tipoNotaFiscalEntrada = StringConverterParaEnum<
        typeof TipoPropriaTerceiroNotaFiscalEntradaEnum,
        TipoPropriaTerceiroNotaFiscalEntradaEnum
      >(
        TipoPropriaTerceiroNotaFiscalEntradaEnum,
        String(filtros.tipoNotaFiscalEntrada)
      );
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupo =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaOperacaoFiscal) {
      filtroBack.listaIdOperacaoFiscal =
        filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    const response =
      await NotaFiscalEntradaTotalPorModeloDocumentoRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }
    const retorno =
      response as INotaFiscalEntradaTotalPorModeloDocumentoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioModeloDocumentoValorSubtotal: Number(
            dados.valores.somatorioModeloDocumentoValorSubtotal
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorFrete: Number(
            dados.valores.somatorioModeloDocumentoValorFrete
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorSeguro: Number(
            dados.valores.somatorioModeloDocumentoValorSeguro
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorOutrasDespesas: Number(
            dados.valores.somatorioModeloDocumentoValorOutrasDespesas
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorDesconto: Number(
            dados.valores.somatorioModeloDocumentoValorDesconto
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorIpi: Number(
            dados.valores.somatorioModeloDocumentoValorIpi
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorSt: Number(
            dados.valores.somatorioModeloDocumentoValorSt
          ).FormatarParaPtBr(),
          somatorioModeloDocumentoValorTotal: Number(
            dados.valores.somatorioModeloDocumentoValorTotal
          ).FormatarParaPtBr(),
        };

        const listaNotaFiscalEntradaItem = dados.listaNotaFiscalEntradaItem.map(
          (notaFiscal) => {
            return {
              ...notaFiscal,
              notaFiscalEntradaDataHoraEmissao: FormatarDataParaPtBr(
                notaFiscal.notaFiscalEntradaDataHoraEmissao
              ),

              somatorioValorSubtotal: Number(
                notaFiscal.somatorioValorSubtotal
              ).FormatarParaPtBr(),
              somatorioValorFrete: Number(
                notaFiscal.somatorioValorFrete
              ).FormatarParaPtBr(),
              somatorioValorSeguro: Number(
                notaFiscal.somatorioValorSeguro
              ).FormatarParaPtBr(),
              somatorioValorOutrasDespesas: Number(
                notaFiscal.somatorioValorOutrasDespesas
              ).FormatarParaPtBr(),
              somatorioValorDesconto: Number(
                notaFiscal.somatorioValorDesconto
              ).FormatarParaPtBr(),
              somatorioValorIpi: Number(
                notaFiscal.somatorioValorIpi
              ).FormatarParaPtBr(),
              somatorioValorSt: Number(
                notaFiscal.somatorioValorSt
              ).FormatarParaPtBr(),
              somatorioValorTotal: Number(
                notaFiscal.somatorioValorTotal
              ).FormatarParaPtBr(),
            };
          }
        );

        return {
          ...dados,
          valores,
          listaNotaFiscalEntradaItem,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      somatorioGeralValorSubtotal: Number(
        retorno.somatorioGeralValorSubtotal
      ).FormatarParaPtBr(),
      somatorioGeralValorFrete: Number(
        retorno.somatorioGeralValorFrete
      ).FormatarParaPtBr(),
      somatorioGeralValorSeguro: Number(
        retorno.somatorioGeralValorSeguro
      ).FormatarParaPtBr(),
      somatorioGeralValorOutrasDespesas: Number(
        retorno.somatorioGeralValorOutrasDespesas
      ).FormatarParaPtBr(),
      somatorioGeralValorDesconto: Number(
        retorno.somatorioGeralValorDesconto
      ).FormatarParaPtBr(),
      somatorioGeralValorIpi: Number(
        retorno.somatorioGeralValorIpi
      ).FormatarParaPtBr(),
      somatorioGeralValorSt: Number(
        retorno.somatorioGeralValorSt
      ).FormatarParaPtBr(),
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend,
    cabecalho: INotaFiscalEntradaTotalPorModeloDocumentoCabecalhoRelatorio
  ): INotaFiscalEntradaTotalPorModeloDocumentoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (filtros.tipoNotaFiscalEntrada) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoNotaFiscalEntrada = filtros.tipoNotaFiscalEntrada;
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (
      filtros.listaFornecedor &&
      filtros.listaFornecedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroFornecedor = filtros.listaFornecedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaOperacaoFiscal &&
      filtros.listaOperacaoFiscal.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
        .map((valor: any) => {
          return valor.cfopDescricao;
        })
        .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: NotaFiscalEntradaTotalPorModeloDocumentoPlanilha,
      nomeArquivo: 'NotaFiscalEntradaTotalPorModeloDocumento',
      valores: dados.listaDados,
      unwind: ['listaNotaFiscalEntradaItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: NotaFiscalEntradaTotalPorModeloDocumentoPlanilha,
      nomeArquivo: 'NotaFiscalEntradaTotalPorModeloDocumento',
      valores: dados.listaDados as any,
      unwind: ['listaNotaFiscalEntradaItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <NotaFiscalEntradaTotalPorModeloDocumentoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <NotaFiscalEntradaTotalPorModeloDocumentoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default NotaFiscalEntradaTotalPorModeloDocumentoRelatorio();
