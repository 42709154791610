const ProdutoExportacaoPlanilha = [
  {
    titulo: 'codigo',
    valor: 'codigo',
  },
  {
    titulo: 'descricao',
    valor: 'descricao',
  },
  {
    titulo: 'grupoProdutoServico.id',
    valor: 'grupoProdutoServico.id',
  },
  {
    titulo: 'grupoProdutoServico.descricao',
    valor: 'grupoProdutoServico.descricao',
  },
  {
    titulo: 'tipoProdutoServico',
    valor: 'tipoProdutoServico',
  },
  {
    titulo: 'ativo',
    valor: 'ativo',
  },
  {
    titulo: 'produtoCusto.valorUnitarioCustoReposicao',
    valor: 'produtoCusto.valorUnitarioCustoReposicao',
  },
  {
    titulo: 'produtoCusto.tipoCusto',
    valor: 'produtoCusto.tipoCusto',
  },
  {
    titulo: 'produtoComercial.valorUnitarioVenda',
    valor: 'produtoComercial.valorUnitarioVenda',
  },
  {
    titulo: 'disponivelParaVenda',
    valor: 'produtoComercial.comercializado',
  },
  {
    titulo: 'produtoComercial.marcaProduto.descricao',
    valor: 'produtoComercial.marcaProduto.descricao',
  },
  {
    titulo: 'produtoEstoque.controlarEstoque',
    valor: 'produtoEstoque.controlarEstoque',
  },
  {
    titulo: 'produtoEstoque.controlarLote',
    valor: 'produtoEstoque.controlarLote',
  },
  {
    titulo: 'produtoEstoque.quantidadeEstoqueMinimo',
    valor: 'produtoEstoque.quantidadeEstoqueMinimo',
  },
  {
    titulo: 'produtoEstoque.quantidadeEstoqueMaximo',
    valor: 'produtoEstoque.quantidadeEstoqueMaximo',
  },
  {
    titulo: 'produtoEstoque.unidadeMedidaEstoque.sigla',
    valor: 'produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'produtoEngenharia.pesoLiquido',
    valor: 'produtoEngenharia.pesoLiquido',
  },
  {
    titulo: 'produtoEngenharia.pesoBruto',
    valor: 'produtoEngenharia.pesoBruto',
  },
  {
    titulo: 'produtoEngenharia.codigoBarras',
    valor: 'produtoEngenharia.codigoBarras',
  },
  {
    titulo: 'produtoFiscal.ncm.codigo',
    valor: 'produtoFiscal.ncm.codigo',
  },
  {
    titulo: 'produtoFiscal.ncm.descricao',
    valor: 'produtoFiscal.ncm.descricao',
  },
  {
    titulo: 'produtoFiscal.cest.codigo',
    valor: 'produtoFiscal.cest.codigo',
  },
  {
    titulo: 'produtoFiscal.cest.descricao',
    valor: 'produtoFiscal.cest.descricao',
  },
  {
    titulo: 'produtoFiscal.spedTipoProduto.codigo',
    valor: 'produtoFiscal.spedTipoProduto.codigo',
  },
  {
    titulo: 'produtoFiscal.spedTipoProduto.descricao',
    valor: 'produtoFiscal.spedTipoProduto.descricao',
  },
  {
    titulo: 'produtoFiscal.origemIcms.codigo',
    valor: 'produtoFiscal.origemIcms.codigo',
  },
  {
    titulo: 'fci',
    valor: 'produtoFiscal.fci',
  },
  {
    titulo: 'produtoEngenharia.codigoReferencia',
    valor: 'produtoEngenharia.codigoReferencia',
  },
  {
    titulo: 'produtoEngenharia.codigoReferencia',
    valor: 'produtoEngenharia.codigoReferencia',
  },
  {
    titulo: 'produtoFiscal.sujeitoASt',
    valor: 'produtoFiscal.sujeitoASt',
  },
];

export default ProdutoExportacaoPlanilha;
