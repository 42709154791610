import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/grupo-produto-servico';

interface IGrupoProdutoServicoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IGrupoProdutoServicoComunicadorShow {
  id: string;
}

interface IGrupoProdutoServicoComunicadorStore {
  params: any;
}

interface IGrupoProdutoServicoComunicadorUpdate {
  id: string;
  params: any;
}

interface IGrupoProdutoServicoComunicadorDeletar {
  id: string;
}

class GrupoProdutoServicoComunicador {
  public async index(
    params: IGrupoProdutoServicoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IGrupoProdutoServicoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IGrupoProdutoServicoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IGrupoProdutoServicoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IGrupoProdutoServicoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new GrupoProdutoServicoComunicador();
