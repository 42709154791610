import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  FormatarEnum,
  FormatarRemovendoEnum,
  IItemAvaliacaoPesagemValoresAlterar,
  TipoAvaliacaoPesagemEnum,
  TipoAvaliacaoPesagemValorEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Select from '../../../../../../Componentes/Select';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';

type IProps = IMain<IItemAvaliacaoPesagemValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosPesagensItensAvaliacaoPesagem: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [mostrarValoresPadraoSimOuNao, setMostrarValoresPadraoSimOuNao] =
    useState(true);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  const tipoAvaliacao = formRef.current?.getFieldValue('tipoAvaliacao');

  useEffect(() => {
    if (tipoAvaliacao === TipoAvaliacaoPesagemEnum.SimNao) {
      setMostrarValoresPadraoSimOuNao(true);
    } else {
      setMostrarValoresPadraoSimOuNao(false);
    }
  }, [tipoAvaliacao]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Descrição"
                name="descricao"
                type="text"
                placeholder="Descrição"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={4} sm={4}>
              <Select
                label="Tipo de Avaliação"
                name="tipoAvaliacao"
                options={FormatarEnum({
                  enumObj: TipoAvaliacaoPesagemEnum,
                })}
                onChange={(event) => {
                  const valor = event.target.value;
                  if (valor === TipoAvaliacaoPesagemEnum.SimNao) {
                    setMostrarValoresPadraoSimOuNao(true);
                  } else {
                    setMostrarValoresPadraoSimOuNao(false);
                  }
                }}
              />
            </Col>
            <Col lg={4} md={4} sm={4}>
              <div
                style={{
                  display: mostrarValoresPadraoSimOuNao ? 'flex' : 'none',
                }}
              >
                <Select
                  label="Valor Padrão"
                  name="valorPadraoSimNao"
                  options={FormatarRemovendoEnum({
                    enumObj: TipoAvaliacaoPesagemValorEnum,
                    enumRemover: [
                      TipoAvaliacaoPesagemValorEnum.bom,
                      TipoAvaliacaoPesagemValorEnum.otimo,
                      TipoAvaliacaoPesagemValorEnum.razoavel,
                      TipoAvaliacaoPesagemValorEnum.ruim,
                    ],
                    nullavel: true,
                  })}
                />
              </div>
              <div
                style={{
                  display: mostrarValoresPadraoSimOuNao ? 'none' : 'flex',
                }}
              >
                <Select
                  label="Valor Padrão"
                  name="valorPadraoOtimoBom"
                  options={FormatarRemovendoEnum({
                    enumObj: TipoAvaliacaoPesagemValorEnum,
                    enumRemover: [
                      TipoAvaliacaoPesagemValorEnum.sim,
                      TipoAvaliacaoPesagemValorEnum.nao,
                    ],
                    nullavel: true,
                  })}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
