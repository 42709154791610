import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/relatorio/adiantamentos/adiantamentos-por-emissao';

interface IAdiantamentosPorEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class AdiantamentosPorEmissaoRelatorioComunicador {
  public async index(
    params: IAdiantamentosPorEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new AdiantamentosPorEmissaoRelatorioComunicador();
