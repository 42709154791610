import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/pesagem/por-local-de-estoque';

interface IPesagemPorLocalDeEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PesagemPorLocalDeEstoqueRelatorioComunicador {
  public async index(
    params: IPesagemPorLocalDeEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PesagemPorLocalDeEstoqueRelatorioComunicador();
