import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/padronizacao-descricao-atributo-auto-complete';

interface IPadronizacaoDescricaoAtributoAutoCompleteComunicadorIndex {
  params: any;
}

class PadronizacaoDescricaoAtributoAutoCompleteComunicador {
  public async index(
    params: IPadronizacaoDescricaoAtributoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PadronizacaoDescricaoAtributoAutoCompleteComunicador();
