import React from 'react';
import { useLocation } from 'react-router-dom';
import AtualizacaoEstoqueProdutoLista from './Lista';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import AtualizacaoEstoqueProdutoHook from './Hooks';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';

const AtualizacaoEstoqueProdutoListagem: React.FC = () => {
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <ListaProvider>
      <AtualizacaoEstoqueProdutoHook>
        <AtualizacaoEstoqueProdutoLista
          dadosRecuperados={state?.dadosRecuperados ?? null}
        />
      </AtualizacaoEstoqueProdutoHook>
    </ListaProvider>
  );
};

export default AtualizacaoEstoqueProdutoListagem;
