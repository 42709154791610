import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormatarDataParaPtBr } from '@elogestor/util';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import JanelaDetalhe from '../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseSisdevinEstoqueMovimentoLista } from '../Hooks/SisdevinEstoqueMovimentoListaHook';
import SisdevinEstoqueMovimentoComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/SisdevinEstoqueMovimento/Comunicador/SisdevinEstoqueMovimentoComunicador';
import ListaDetalheNotaFiscalSaidaItemSisdevin from './ListaDetalheNotaFiscalItemSisdevin/Lista';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IEditarNotaFiscalItemSisdevinModal {
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
  index: number;
}

const EditarNotaFiscalItemSisdevin: React.FC<
  IEditarNotaFiscalItemSisdevinModal
> = ({ onFecharFormModal, onSalvarFormModal, index }) => {
  const { permissoes } = UsePermissoes();

  const { SuprimentosSisdevinManutencaoMovimentosSisdevin: permissao } =
    permissoes;
  const { listaValor } = UseSisdevinEstoqueMovimentoLista();

  const formRef = useRef<IFormCiaHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleClickVoltar = useCallback(async (): Promise<any> => {
    if (onFecharFormModal) onFecharFormModal();
  }, [onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      const dadosFiltrados = listaValor[Number(index ?? 0)];
      setLoading(true);
      if (!dadosFiltrados) return;

      const notaFiscalItemId =
        dadosFiltrados.notaFiscalItemSisdevin.notaFiscalItem.id;

      const response = await SisdevinEstoqueMovimentoComunicador.show({
        id: notaFiscalItemId ?? '',
        params: { tipoMovimento: dadosFiltrados.tipoMovimento },
      });
      await formRef.current?.setData(response);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [index, listaValor]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Editar"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRef}>
            <Row>
              <Col xl={2} lg={3} md={3} sm={3}>
                <InputLabel
                  name="ordem"
                  label="Núm. Doc."
                  valorStyle={{ alignItems: 'flex-end', marginTop: 5 }}
                />
              </Col>

              <Col xl={2} lg={3} md={3} sm={3}>
                <InputLabel
                  name="notaFiscal.dataHoraEmissao"
                  label="Data Emissão"
                  valorStyle={{
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    marginTop: 5,
                  }}
                  formatarValor={(dataHoraEmissao = '') => {
                    return dataHoraEmissao
                      ? FormatarDataParaPtBr(dataHoraEmissao)
                      : '';
                  }}
                />
              </Col>

              <Col xl={8} lg={6} md={6} sm={6}>
                <InputLabel
                  name="produto"
                  label="Produto"
                  valorStyle={{
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    marginTop: 5,
                  }}
                  formatarValor={(produto = '') => {
                    return `${produto.codigo} - ${produto.descricao}`;
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={3} lg={3} md={3} sm={3}>
                <InputLabel
                  name="quantidade"
                  label="Qtde Item NF"
                  valorStyle={{
                    alignItems: 'flex-end',
                    fontWeight: 'bold',
                    marginTop: 5,
                  }}
                  formatarValor={(quantidade = 0) => {
                    return Number(quantidade).FormatarParaPtBr({
                      maximoCasasDecimais: 4,
                      minimoCasasDecimais: 4,
                    });
                  }}
                />
              </Col>
              <Col xl={3} lg={3} md={3} sm={3}>
                <InputLabel
                  name="produto.produtoConfiguracaoBebidaSisdevin.litragem"
                  label="Litros por Unidade"
                  valorStyle={{ alignItems: 'flex-end', marginTop: 5 }}
                  formatarValor={(litragem = 0) => {
                    return Number(litragem).FormatarParaPtBr({
                      maximoCasasDecimais: 4,
                      minimoCasasDecimais: 4,
                    });
                  }}
                />
              </Col>

              <Col xl={3} lg={3} md={3} sm={3}>
                <InputLabel
                  name="quantidadeLitros"
                  label="Qtde Litros"
                  valorStyle={{ alignItems: 'flex-end', marginTop: 5 }}
                  formatarValor={(quantidadeLitros = 0) => {
                    return Number(quantidadeLitros).FormatarParaPtBr({
                      maximoCasasDecimais: 4,
                      minimoCasasDecimais: 4,
                    });
                  }}
                />
              </Col>
            </Row>

            <ListaDetalheNotaFiscalSaidaItemSisdevin
              name="listaNotaFiscalItemSisdevin"
              index={index}
              onHandleCarregarDados={handleCarregarDados}
            />
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleClickVoltar}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-azul btn-adicionar"
              type="button"
              onClick={() => {
                if (onFecharFormModal) onFecharFormModal();
              }}
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default EditarNotaFiscalItemSisdevin;
