import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-aliquota';

interface IRegraEscolhaAliquotaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaAliquotaComunicadorShow {
  id: string;
}

interface IRegraEscolhaAliquotaComunicadorStore {
  params: any;
}

interface IRegraEscolhaAliquotaComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaAliquotaComunicadorDeletar {
  id: string;
}

class RegraEscolhaAliquotaComunicador {
  public async index(
    params: IRegraEscolhaAliquotaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaAliquotaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaAliquotaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaAliquotaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaAliquotaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaAliquotaComunicador();
