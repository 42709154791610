import styled from 'styled-components';

export const Button = styled.button`
  background: none;
  font-size: 16px;
  padding: 5px 15px;
  border: 1px solid black;
  border-color: #dee2e6;
  color: #007bff;
  transition: 0.3s;

  &:hover {
    background-color: #e9ecef;
  }
  &:disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0;
  }
`;
