"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StringConverterParaEnumAtravesValor;
function TextoFormatadoParaValorStringEnum(stringEnum, value) {
    for (let i = 0; i < Object.values(stringEnum).length; i++) {
        const valor = Object.values(stringEnum)[i];
        const chave = Object.keys(stringEnum)[i];
        if (valor === value) {
            return chave;
        }
    }
    return undefined;
}
function StringConverterParaEnumAtravesValor(enumObj, str) {
    const textoEnum = String(TextoFormatadoParaValorStringEnum(enumObj, str));
    return enumObj[textoEnum];
}
