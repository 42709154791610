"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarStringParaXml;
require("./String.extensions");
function FormatarStringParaXml(texto) {
    let retorno = texto;
    retorno = retorno.RemoverExcessoEspacos();
    retorno = retorno.replace(/\n+/g, ' ');
    retorno = retorno.replace(/—+/g, '-');
    return retorno;
}
