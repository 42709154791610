import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tipo-nota-auto-complete-tag';

interface ITipoNotaAutoCompleteTagComunicadorIndex {
  params: any;
}

class TipoNotaAutoCompleteTagComunicador {
  public async index(
    params: ITipoNotaAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TipoNotaAutoCompleteTagComunicador();
