import api from '../../../../../Comum/Services/Api';

const rota =
  'transporte/relatorio/manifesto-transporte/manifesto-transporte-por-resumo';

interface IManifestoTransportePorResumoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ManifestoTransportePorResumoRelatorioComunicador {
  public async index(
    params: IManifestoTransportePorResumoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ManifestoTransportePorResumoRelatorioComunicador();
