"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var FonteEtiquetaEnum;
(function (FonteEtiquetaEnum) {
    FonteEtiquetaEnum["carlito"] = "Carlito";
    FonteEtiquetaEnum["roboto"] = "Roboto";
    FonteEtiquetaEnum["tinos"] = "Tinos";
})(FonteEtiquetaEnum || (FonteEtiquetaEnum = {}));
exports.default = FonteEtiquetaEnum;
