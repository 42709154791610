import React from 'react';

const Carregando: React.FC = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: 40, color: '#fff' }}>Carregando...</span>
    </div>
  );
};

export default Carregando;
