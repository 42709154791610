import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/cultivar-auto-complete-tag';

interface ICultivarAutoCompleteTagComunidacadorIndex {
  params: {
    texto: string;
    limite: number;
    offset: number;
    listaIdInicial?: string[];
    listaIdParaRemover?: string[];
  };
}

class CultivarAutoCompleteTagComunicador {
  public async index(
    params: ICultivarAutoCompleteTagComunidacadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CultivarAutoCompleteTagComunicador();
