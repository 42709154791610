import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/produto-enologico-auto-complete-tag';

interface IProdutoEnologicoAutoCompleteTagComunidacadorIndex {
  params: {
    texto: string;
    ativo: any;
    limite: number;
    offset: number;
    listaIdInicial?: string[];
    listaIdParaRemover?: string[];
  };
}

class ProdutoEnologicoAutoCompleteTagComunicador {
  public async index(
    params: IProdutoEnologicoAutoCompleteTagComunidacadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ProdutoEnologicoAutoCompleteTagComunicador();
