import {
  FormatarEnum,
  IRegraEscolhaCstCreditoIpiValoresAlterar,
  RegraEscolhaTipoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagNcm from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputCnpj from '../../../../../../Componentes/Inputs/InputCnpj';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputAutoCompleteTagRegimeTributarioCustoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagRegimeTributarioCustoEnum';
import InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagCodigoSituacaoTributariaIpi from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaIpi';
import InputAutoCompleteCodigoSituacaoTributariaIpi from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIpi';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

type IProps = IMain<IRegraEscolhaCstCreditoIpiValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { FiscalTributacaoRegraEscolhaCstsCreditosIpi: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const { setDisableExcluir } = UseButtonsSubHeader();

  const idDetalheRegistro = getIdDetalheRegistro();
  const regraEscolhaTipo = formRef.current?.getFieldValue('tipo');

  useEffect(() => {
    if (tipoUsuario !== 'Elo Gestor' && regraEscolhaTipo === 'Padrão') {
      setDisableExcluir(regraEscolhaTipo);
    }
  }, [regraEscolhaTipo, setDisableExcluir, tipoUsuario]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // Não Remover os await - são nescessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCreditoIpiCst')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCstCreditoIpiRegimeTributario'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCreditoIpiDestinado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCreditoIpiSped')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCstCreditoIpiTipoTransacao'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCstCreditoIpiGrupoProdutoServico'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCreditoIpiNcm')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
                options={
                  (FormatarEnum({
                    enumObj: RegraEscolhaTipoEnum,
                  }),
                  [
                    {
                      title: 'Contabilidade',
                      value: RegraEscolhaTipoEnum.contabilidade,
                    },
                    { title: 'Empresa', value: RegraEscolhaTipoEnum.empresa },
                    {
                      title: 'Padrão',
                      value: RegraEscolhaTipoEnum.padrao,
                      hidden: tipoUsuario !== 'Elo Gestor',
                    },
                  ])
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputCnpj
                ref={inputRefFocus}
                label="CNPJ Contabilidade"
                name="cnpjContabilidade"
                placeholder="CNPJ Contabilidade"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaCstCreditoIpiEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagCodigoSituacaoTributariaIpi
                label="CSTs (Nota Fiscal Emitida)"
                name="listaRegraEscolhaCstCreditoIpiCst"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagRegimeTributarioCustoEnum
                label="Regimes Tributários"
                name="listaRegraEscolhaCstCreditoIpiRegimeTributario"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaCstCreditoIpiDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSpedTipoProduto
                label="Tipos SPED"
                name="listaRegraEscolhaCstCreditoIpiSped"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum
                label="Tipo de Transação da Operação Fiscal (CFOP)"
                name="listaRegraEscolhaCstCreditoIpiTipoTransacao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagGrupoProdutoServico
                label="Grupos de Produto"
                name="listaRegraEscolhaCstCreditoIpiGrupoProdutoServico"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagNcm
                label="NCMs"
                name="listaRegraEscolhaCstCreditoIpiNcm"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteCodigoSituacaoTributariaIpi
                label="CST do IPI"
                name="regraEscolhaCstCreditoIpiResultado.idCodigoSituacaoTributaria"
                nomeObjeto="regraEscolhaCstCreditoIpiResultado.codigoSituacaoTributaria"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={5} md={6} sm={6}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Creditar IPI Conforme Documento"
                  name="regraEscolhaCstCreditoIpiResultado.creditarIpi"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
