import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: 150px;

  display: flex;
  flex-direction: row;

  position: fixed;
  left: calc(50% - 250px);

  border-radius: 10px;

  background: rgba(0, 0, 0, 0.8);
  color: #fff;

  margin-top: 30px;
  padding: 10px;

  z-index: 100;

  @media screen and (max-width: 500px) {
    width: 100vw;
    height: auto;
    left: calc(50% - 50vw);
  }

  svg {
    font-size: 100px;
    align-self: center;
    margin-right: 15px;
  }
`;

export const Conteundo = styled.div`
  text-align: center;
  p {
    font-size: 20px;
  }
  button {
    font-weight: 600;
  }
`;
