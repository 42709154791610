import {
  FormatarEnum,
  StringConverterParaEnum,
  EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum,
  ITransferenciaLocalEstoqueValoresAlterar,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseRota } from '../../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../../Componentes/TextoLoadingSalvar';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';
import Select from '../../../../../../../Componentes/Select';
import InputAutoCompleteProduto, {
  IOnChangeItemAtualAutoCompleteProdutoEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputAutoCompletePessoa from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import InputAutoCompleteLote from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';

type IProps = IMain<ITransferenciaLocalEstoqueValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosEstoqueTransferenciaLocalEstoque: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const [tipoLocalEstoqueValue, setTipoLocalEstoqueValue] = useState(
    EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum.proprioEmPoderProprio
  );

  const [showLote, setShowLote] = useState(true);
  const [listaRemoverLocalEstoqueOrigem, setListaRemoverLocalEstoqueOrigem] =
    useState<string[]>([]);

  const [listaRemoverLocalEstoqueDestino, setListaRemoverLocalEstoqueDestino] =
    useState<string[]>([]);

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleChangeProduto = useCallback(
    async ({ itemAtual }: IOnChangeItemAtualAutoCompleteProdutoEvent) => {
      if (!itemAtual) {
        formRef.current?.clearField(
          'produto.produtoEstoque.unidadeMedida.sigla'
        );

        setShowLote(true);
      } else {
        formRef.current?.setFieldValue(
          'produto.produtoEstoque.unidadeMedida.sigla',
          itemAtual.produtoEstoque.unidadeMedida.sigla
        );

        setShowLote(itemAtual.produtoEstoque.controlarLote);
      }
    },
    [formRef]
  );

  const handleChangeLocalEstoque = useCallback(
    (item: any, isLocalEstoqueOrigem: boolean) => {
      if (isLocalEstoqueOrigem) {
        if (!item) {
          setListaRemoverLocalEstoqueDestino([]);
          return;
        }

        setListaRemoverLocalEstoqueDestino((prevState) => [
          ...prevState,
          item.id,
        ]);
      } else {
        if (!item) {
          setListaRemoverLocalEstoqueOrigem([]);
          return;
        }

        setListaRemoverLocalEstoqueOrigem((prevState) => [
          ...prevState,
          item.id,
        ]);
      }
    },
    []
  );

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col xl={2} lg={6} sm={12}>
              <InputInteiro
                label="Número"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col xl={3} lg={6} sm={12}>
              <Select
                label="Tipo de Estoque"
                name="indicadorTipoEstoque"
                disabled={!!idEditar}
                options={FormatarEnum({
                  enumObj: EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum,
                })}
                onChange={(event) => {
                  formRef.current?.setErrors({});

                  const valor = StringConverterParaEnum<
                    typeof EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum,
                    EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum
                  >(
                    EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum,
                    event.target.value
                  );

                  setTipoLocalEstoqueValue(valor);
                }}
              />
            </Col>
            <Col xl={3} lg={6} sm={12}>
              <InputDateTime
                label="Data e hora"
                name="dataHora"
                disabled={!!idEditar}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={5} lg={8}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                name="idProduto"
                nomeObjeto="produto"
                label="Produto"
                placeholder="Produto"
                onChangeItemAtual={handleChangeProduto}
                permitirAdicionar={false}
                disabled={!!idEditar}
                controlaEstoque
              />
            </Col>
            <Col xl={2} lg={4}>
              <Input
                name="produto.produtoEstoque.unidadeMedida.sigla"
                label="Unidade"
                placeholder="Unidade"
                disabled
              />
            </Col>

            {showLote && (
              <Col xl={3} lg={6} md={12} sm={12}>
                <InputAutoCompleteLote
                  name="idLote"
                  nomeObjeto="lote"
                  label="Lote"
                  placeholder="Lote"
                  disabled={!!idEditar}
                />
              </Col>
            )}
          </Row>

          <Row
            style={{
              display: `${
                tipoLocalEstoqueValue ===
                EstoqueIndicadorTipoTransferenciaLocalEstoqueEnum.terceiroEmPoderProprio
                  ? 'flex'
                  : 'none'
              }`,
            }}
          >
            <Col xl={5}>
              <InputAutoCompletePessoa
                name="idPessoa"
                label="Pessoa (terceiro)"
                placeholder="Pessoa (terceiro)"
                nomeObjeto="pessoa"
                permitirAdicionar={false}
                disabled={!!idEditar}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={5}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoqueOrigem"
                label="Local Estoque Origem"
                placeholder="Local Estoque Origem"
                nomeObjeto="localEstoqueOrigem"
                disabled={!!idEditar}
                listaIdParaRemover={listaRemoverLocalEstoqueOrigem}
                onChangeItemAtual={(event) => {
                  handleChangeLocalEstoque(event.itemAtual, true);
                }}
              />
            </Col>
            <Col xl={5}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoqueDestino"
                label="Local Estoque Destino"
                placeholder="Local Estoque Destino"
                nomeObjeto="localEstoqueDestino"
                disabled={!!idEditar}
                listaIdParaRemover={listaRemoverLocalEstoqueDestino}
                onChangeItemAtual={(event) => {
                  handleChangeLocalEstoque(event.itemAtual, false);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={6}>
              <InputDecimalNulavel
                name="quantidade"
                label="Quantidade"
                placeholder="Quantidade"
                casasDecimais={4}
                casasInteiras={11}
                disabled={!!idEditar}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Textarea
                name="observacoes"
                label="Observação"
                placeholder="Observações"
                maxLength={300}
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
