const OrdemProducaoResumoProdutosProduzidosPlanilha = [
  {
    titulo: 'Produto',
    valor: 'chave',
  },
  {
    titulo: 'Situação',
    valor: 'listaOrdemProducao.situacao',
  },

  {
    titulo: 'Data Lançamento',
    valor: 'listaOrdemProducao.dataLancamento',
  },

  {
    titulo: 'Data Início',
    valor: 'listaOrdemProducao.dataHoraInicio',
  },

  {
    titulo: 'Data de Término',
    valor: 'listaOrdemProducao.dataHoraTermino',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaOrdemProducao.quantidade',
  },
];

export default OrdemProducaoResumoProdutosProduzidosPlanilha;
