"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoContaPrevisaoAdiantamentoEnum;
(function (TipoContaPrevisaoAdiantamentoEnum) {
    TipoContaPrevisaoAdiantamentoEnum["conta"] = "Conta";
    TipoContaPrevisaoAdiantamentoEnum["adiantamento"] = "Adiantamento";
    TipoContaPrevisaoAdiantamentoEnum["previsao"] = "Previs\u00E3o";
})(TipoContaPrevisaoAdiantamentoEnum || (TipoContaPrevisaoAdiantamentoEnum = {}));
exports.default = TipoContaPrevisaoAdiantamentoEnum;
