import { TipoEtiquetaEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/etiqueta/elemento-disponivel';

interface IEtiquetaElementoDisponivelComunicadorIndex {
  tipoEtiqueta: TipoEtiquetaEnum;
}

class EtiquetaElementoDisponivelComunicador {
  public async index(
    params: IEtiquetaElementoDisponivelComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new EtiquetaElementoDisponivelComunicador();
