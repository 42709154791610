import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/parametro/valor';

interface IParametroValorComunicadorUpdate {
  params: any;
}

interface IParametroValorComunicadorShow {
  identificador: string;
}

class ParametroValorComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async update(params: IParametroValorComunicadorUpdate): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }

  public async show({
    identificador,
  }: IParametroValorComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${identificador}`);

    return response.data;
  }
}

export default new ParametroValorComunicador();
