import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/plano-fundo';

interface IEmpresaPlanoFundoComunicadorUpdate {
  params: any;
}

class EmpresaPlanoFundoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async update(
    params: IEmpresaPlanoFundoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaPlanoFundoComunicador();
