import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import {
  FormatarEnum,
  IPesagemFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
  TipoPesagemEnum,
} from '@elogestor/util';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagCliente from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import Select from '../../../../Componentes/Select';
import InputAutoCompleteTagSituacaoPesagemEnum from '../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoPesagemEnum';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagEstado from '../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagCidade from '../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import Input from '../../../../Componentes/Inputs/Input';
import InputAutoCompleteTagLocalEstoque from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLocalEstoque';
import InputAutoCompleteTagPessoa from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputInteiroNulavel from '../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';

interface IPesagemRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const PesagemRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraRef = useRef<IInputPeriodoDataHoraRef>(null);
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataInicial() &&
                  !periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data inicial',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataInicial() &&
                  !!periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora final',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataFinal() &&
                  !periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataFinal() &&
                  !!periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPesagemRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IPesagemFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPesagemRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IPesagemFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPesagemRelatorioDados;
      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IPesagemFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
    formRef.current?.setDataInicial({
      opcaoRelatorio: 'PesagemPorLocalDeEstoque',
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      tipo: null,
      dataHoraInicial: dataInicial,
      dataHoraFinal: dataFinal,
    });
  }, [formRef]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Pesagem
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Local de Estoque',
                        value: 'PesagemPorLocalDeEstoque',
                      },
                      {
                        title: 'Com Produtores',
                        value: 'PesagemComProdutores',
                      },
                      {
                        title: 'Por Data e Local de Estoque',
                        value: 'PesagemPorDataLocalDeEstoque',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Inicial"
                      labelDataHoraFinal="Data Final"
                      nomeDataHoraInicial="dataHoraInicial"
                      nomeDataHoraFinal="dataHoraFinal"
                      ref={periodoDataHoraRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSituacaoPesagemEnum
                      label="Situação"
                      name="listaSituacao"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Tipo"
                      name="tipo"
                      options={FormatarEnum({
                        enumObj: TipoPesagemEnum,
                        todos: true,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Clientes"
                      name="listaCliente"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagRamoAtividadeProfissao
                      label="Ramo Atividade/Profissão"
                      name="listaRamoAtividadeProfissao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEstado
                      label="Estados"
                      name="listaEstado"
                      onChangeListaItemAtual={({ listaItemAtual }) => {
                        handleChangeListaItemAtualEstado(listaItemAtual);
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCidade
                      listaIdEstado={listaIdEstado}
                      label="Cidades"
                      name="listaCidade"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} lg={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagProduto
                      label="Produtos"
                      name="listaProduto"
                      onChangeListaItemAtual={({ listaItemAtual }) => {
                        if (listaItemAtual?.length) {
                          setProdutoSelecionado(true);
                        } else {
                          setProdutoSelecionado(false);
                        }
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputInteiroNulavel
                      label="Código"
                      name="codigo"
                      disabled={produtoSelecionado}
                    />
                  </Col>
                  <Col xl={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      disabled={produtoSelecionado}
                    />
                  </Col>
                  <Col xl={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                      label="Filtro Código/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      disabled={produtoSelecionado}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      label="Grupos"
                      name="listaGrupo"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagLocalEstoque
                      label="Locais de Estoque"
                      name="listaLocaisEstoque"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPessoa
                      label="Pessoa (terceiro)"
                      name="listaPessoaTerceiro"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default PesagemRelatorio;
