import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import InputTelefone from '../../../../../../../../Componentes/Inputs/TagsInput/InputTelefone';
import InputEmailTag from '../../../../../../../../Componentes/Inputs/TagsInput/InputEmailTag';
import InputCheckbox from '../../../../../../../../Componentes/Inputs/InputCheckbox';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { CheckboxContainer } from '../Lista/styles';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';

interface IPessoaContatoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const PessoaContatoDetalhe: React.FC<IPessoaContatoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Contato"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={6} md={12}>
                  <Input
                    ref={inputRefFocus}
                    label="Nome"
                    name="nome"
                    placeholder="Nome"
                    maxLength={60}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Textarea
                    label="Observação"
                    name="observacao"
                    placeholder="Observação"
                    maxLength={300}
                  />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <InputTelefone label="Telefones" name="listaTelefone" />
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <InputEmailTag label="E-mails" name="listaEmail" />
                </Col>
              </Row>

              <Row style={{ width: '100%', marginTop: 10 }}>
                <Col lg={6} md={12} className="div-checkbox">
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Documentos Fiscais"
                      name="direcionarDocumentosFiscais"
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <InputCheckbox label="Boletos" name="direcionarBoletos" />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Orçamento"
                      name="direcionarOrcamento"
                    />
                  </CheckboxContainer>
                </Col>
                <Col lg={6} md={12} className="div-checkbox">
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Pedido de Venda"
                      name="direcionarPedidoVenda"
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Cotação de Compra"
                      name="direcionarCotacaoCompra"
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Pedido de Compra"
                      name="direcionarPedidoCompra"
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <InputCheckbox
                      label="Ordem de Serviço (Veículos)"
                      name="direcionarOrdemServico"
                    />
                  </CheckboxContainer>
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              style={{ fontWeight: 'bold' }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                margin: '0px 15px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PessoaContatoDetalhe;
