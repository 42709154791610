import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Input from '../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import ListaDetalheEngenhariaCodigoReferencia from './ListaDetalheEngenhariaCodigoReferencia/Lista';
import ProdutoEngenhariaCodigoReferenciaHook from './ListaDetalheEngenhariaCodigoReferencia/Hooks';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const EngenhariaTab: React.FC = () => {
  const { formRef, refresh } = UseForm();

  const calculoMetragem = useCallback(() => {
    const altura = formRef.current?.getFieldValue('produtoEngenharia.altura');
    const largura = formRef.current?.getFieldValue('produtoEngenharia.largura');
    const comprimento = formRef.current?.getFieldValue(
      'produtoEngenharia.comprimento'
    );

    if (largura && comprimento) {
      const metroQuadrado = (largura / 100) * (comprimento / 100);
      formRef.current?.setFieldValue(
        'produtoEngenharia.metroQuadrado',
        metroQuadrado
      );
      refresh();
    } else {
      formRef.current?.clearField('produtoEngenharia.metroQuadrado');
    }

    if (largura && comprimento && altura) {
      const metroCubico =
        (largura / 100) * (comprimento / 100) * (altura / 100);
      formRef.current?.setFieldValue(
        'produtoEngenharia.metroCubico',
        metroCubico
      );

      refresh();
    } else {
      formRef.current?.clearField('produtoEngenharia.metroCubico');
    }
  }, [formRef, refresh]);

  const handleCalcularVolumes = useCallback(
    ({
      quantidadeMaximaPorVolume,
      quantidadeVolumes,
    }: {
      quantidadeMaximaPorVolume?: number;
      quantidadeVolumes?: number;
    }) => {
      if (quantidadeMaximaPorVolume === 0 || quantidadeVolumes === 0) {
        formRef.current?.setFieldValue(
          'produtoEngenharia.quantidadeVolumes',
          null
        );
        formRef.current?.setFieldValue(
          'produtoEngenharia.quantidadeMaximaPorVolume',
          null
        );
        refresh();

        return;
      }

      if (quantidadeMaximaPorVolume) {
        const calculo = 1 / quantidadeMaximaPorVolume;
        formRef.current?.setFieldValue(
          'produtoEngenharia.quantidadeVolumes',
          calculo
        );

        refresh();
      }
      if (quantidadeVolumes) {
        const calculo = 1 / quantidadeVolumes;
        formRef.current?.setFieldValue(
          'produtoEngenharia.quantidadeMaximaPorVolume',
          calculo
        );

        refresh();
      }
    },
    [formRef, refresh]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Peso Líquido (Kg)"
              name="produtoEngenharia.pesoLiquido"
              placeholder="Peso Líquido (Kg)"
              casasInteiras={15}
              casasDecimais={3}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Peso Bruto (Kg)"
              name="produtoEngenharia.pesoBruto"
              placeholder="Peso Bruto (Kg)"
              casasInteiras={15}
              casasDecimais={3}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Código de Barras"
              name="produtoEngenharia.codigoBarras"
              placeholder="Código de Barras"
              maxLength={14}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Registro Min. Agricultura"
              name="produtoEngenharia.registroMinisterioAgricultura"
              placeholder="Registro Min. Agricultura"
              maxLength={18}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Altura (cm)"
              name="produtoEngenharia.altura"
              placeholder="Altura (cm)"
              casasInteiras={13}
              casasDecimais={2}
              onChangeValue={calculoMetragem}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Largura (cm)"
              name="produtoEngenharia.largura"
              placeholder="Largura (cm)"
              casasInteiras={13}
              casasDecimais={2}
              onChangeValue={calculoMetragem}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Comprimento (cm)"
              name="produtoEngenharia.comprimento"
              placeholder="Comprimento (cm)"
              casasInteiras={13}
              casasDecimais={2}
              onChangeValue={calculoMetragem}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Metro Cúbico (m3)"
              name="produtoEngenharia.metroCubico"
              placeholder="Metro Cúbico (m3)"
              casasInteiras={15}
              casasDecimais={3}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Metro Quadrado (m2)"
              name="produtoEngenharia.metroQuadrado"
              placeholder="Metro Quadrado (m2)"
              casasInteiras={15}
              casasDecimais={3}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Quantidade de Volumes"
              name="produtoEngenharia.quantidadeVolumes"
              placeholder="Quantidade de Volumes"
              onBlurCia={(e) => {
                handleCalcularVolumes({
                  quantidadeVolumes: String(e.valor).ConverterParaNumber(),
                });
              }}
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Quantidade Máxima Por Volume"
              name="produtoEngenharia.quantidadeMaximaPorVolume"
              placeholder="Quantidade Máxima Por Volume"
              onBlurCia={(e) => {
                handleCalcularVolumes({
                  quantidadeMaximaPorVolume: String(
                    e.valor
                  ).ConverterParaNumber(),
                });
              }}
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <ProdutoEngenhariaCodigoReferenciaHook>
              <ListaDetalheEngenhariaCodigoReferencia name="produtoEngenharia.listaProdutoEngenhariaCodigoBarrasReferenciaPesquisa" />
            </ProdutoEngenhariaCodigoReferenciaHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default EngenhariaTab;
