import api from '../../../../Comum/Services/Api';

const rota = 'esqueceu-senha';

interface IUsuarioEsqueceuSenhaComunicadorStore {
  params: any;
}

interface IUsuarioEsqueceuSenhaComunicadorUpdate {
  id: string;
  params: any;
}

class UsuarioEsqueceuSenhaComunicador {
  public async store(
    params: IUsuarioEsqueceuSenhaComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}`, params.params);

    return response.data;
  }

  public async update(
    params: IUsuarioEsqueceuSenhaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(
      `${rota}/redefinir-senha/${params.id}`,
      params.params
    );

    return response.data;
  }
}

export default new UsuarioEsqueceuSenhaComunicador();
