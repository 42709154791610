"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var InformacaoTipoLeiauteEnum;
(function (InformacaoTipoLeiauteEnum) {
    InformacaoTipoLeiauteEnum["leiauteSimplificado"] = "0 - Leiaute Simplificado";
    InformacaoTipoLeiauteEnum["leiauteCompleto"] = "1 - Leiaute Completo";
    InformacaoTipoLeiauteEnum["leiauteRestritoSaldoEstoque"] = "2 - Leiaute Restrito aos Saldos de Estoque";
})(InformacaoTipoLeiauteEnum || (InformacaoTipoLeiauteEnum = {}));
exports.default = InformacaoTipoLeiauteEnum;
