import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }

  label {
    margin: 0 0 0 5px;
  }
`;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;

export const UltimaTr = styled.tr`
  background-color: white;
  border-color: transparent;

  th,
  div {
    background-color: white;
  }
`;
