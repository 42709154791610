import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  DiasUteisOuCorridosProtestarEnum,
  IPortadorValoresAlterar,
  LayoultImpressaoEnum,
  TipoNossoNumeroEnum,
} from '@elogestor/util';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import PortadorComunicador from '../../../../../../Comunicador/Financeiro/Geral/Portador/Comunicador/PortadorComunicador';
import { UseAuth } from '../../../../../../Hooks/Auth';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPortadorValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await PortadorComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          ativo: true,
          considerarFluxoCaixa: true,
          portadorBoleto: {
            aceite: false,
            utilizaBoleto: false,
            negativar: false,
            protestar: false,
            impressoPelaAgencia: false,
            tipoNossoNumero: TipoNossoNumeroEnum.sequencial,
            layoultImpressao: LayoultImpressaoEnum.duasViasPorPagina,
            diasUteisOuCorridosProtestar:
              DiasUteisOuCorridosProtestarEnum.diasUteis,
            empresaCedente: empresa,
            ...dadosPadrao,
          },
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [empresa, getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const banco = formRef.current?.getFieldValueNomeObjeto('banco');

      const schema = Yup.object().shape({
        descricao: Yup.string().required('Descrição é obrigatória!'),
        saldoInicial: Yup.number().required('Saldo inicial é obrigatório'),
        dataSaldoInicial: Yup.date()
          .nullable()
          .required('Data do saldo inicial é obrigatório'),

        portadorBoleto: Yup.object().shape({
          idEmpresaCedente: Yup.string()
            .nullable()
            .required('Empresa Cedente é Obrigatória'),
          utilizaBoleto: Yup.boolean(),

          proximoNumeroBoleto: Yup.mixed()
            .nullable()
            .test({
              message: 'Próximo número do boleto não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          proximoNumeroRemessa: Yup.mixed()
            .nullable()
            .test({
              message: 'Próximo numero da remessa não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          localPagamento: Yup.mixed()
            .nullable()
            .test({
              message: 'Local de pagamento não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          carteira: Yup.mixed()
            .nullable()
            .test({
              message: 'Carteira não informada!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          codigoCedente: Yup.mixed()
            .nullable()
            .test({
              message: 'Cod Cedente / Beneficiário não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          layoutCobranca: Yup.mixed()
            .nullable()
            .test({
              message: 'Layout de Cobrança não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),
          digitoByteSicredi: Yup.mixed()
            .nullable()
            .test({
              message: 'Digito Byte não informado!',
              test: (value: any) => {
                return !(
                  data.portadorBoleto.utilizaBoleto &&
                  !value &&
                  banco?.codigo === '748'
                );
              },
            }),
          posto: Yup.mixed()
            .nullable()
            .test({
              message: 'Posto não informado!',
              test: (value: any) => {
                return !(
                  data.portadorBoleto.utilizaBoleto &&
                  !value &&
                  banco?.codigo === '748'
                );
              },
            }),

          negativar: Yup.boolean().required(' Negativar é obrigatório'),
          protestar: Yup.boolean().required(' Protestar é obrigatório'),

          ambiente: Yup.mixed()
            .nullable()
            .test({
              message: 'Ambiente não informado!',
              test: (value: any) => {
                return !(data.portadorBoleto.utilizaBoleto && !value);
              },
            }),

          descricaoInstrucaoCobranca1: Yup.mixed()
            .nullable()
            .test({
              message:
                'Descrição da Instrução Cobrança 1 deve possuir no máximo 40 caracteres!',
              test: (value: any) => {
                const valorFormatado = value
                  .replace('<#NUMERONOTA#>', '12345678')
                  .replace('<#PERCENTUALMULTA#>', '12345')
                  .replace('<#VALORJUROSCALCULADO#>', '123456')
                  .replace('<#VALORMULTACALCULADO#>', '123456')
                  .replace('<#PRAZONEGATIVACAO#>', '12')
                  .replace('<#PRAZOPROTESTO#>', '12');

                return !(
                  data.portadorBoleto.utilizaBoleto &&
                  valorFormatado.length > 40 &&
                  banco?.codigo === '756'
                );
              },
            }),
          descricaoInstrucaoCobranca2: Yup.mixed()
            .nullable()
            .test({
              message:
                'Descrição da Instrução Cobrança 2 deve possuir no máximo 40 caracteres!',
              test: (value: any) => {
                const valorFormatado = value
                  .replace('<#NUMERONOTA#>', '12345678')
                  .replace('<#PERCENTUALMULTA#>', '12345')
                  .replace('<#VALORJUROSCALCULADO#>', '123456')
                  .replace('<#VALORMULTACALCULADO#>', '123456')
                  .replace('<#PRAZONEGATIVACAO#>', '12')
                  .replace('<#PRAZOPROTESTO#>', '12');

                return !(
                  data.portadorBoleto.utilizaBoleto &&
                  valorFormatado.length > 40 &&
                  banco?.codigo === '756'
                );
              },
            }),
          descricaoInstrucaoCobranca3: Yup.mixed()
            .nullable()
            .test({
              message:
                'Descrição da Instrução Cobrança 3 deve possuir no máximo 40 caracteres!',
              test: (value: any) => {
                const valorFormatado = value
                  .replace('<#NUMERONOTA#>', '12345678')
                  .replace('<#PERCENTUALMULTA#>', '12345')
                  .replace('<#VALORJUROSCALCULADO#>', '123456')
                  .replace('<#VALORMULTACALCULADO#>', '123456')
                  .replace('<#PRAZONEGATIVACAO#>', '12')
                  .replace('<#PRAZOPROTESTO#>', '12');

                return !(
                  data.portadorBoleto.utilizaBoleto &&
                  valorFormatado.length > 40 &&
                  banco?.codigo === '756'
                );
              },
            }),
        }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await PortadorComunicador.update({ id, params: data });
        } else {
          const response = await PortadorComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
