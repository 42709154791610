import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/transferencia-local-estoque';

interface ITransferenciaLocalEstoqueComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ITransferenciaLocalEstoqueComunicadorShow {
  id: string;
}

interface ITransferenciaLocalEstoqueComunicadorStore {
  params: any;
}

interface ITransferenciaLocalEstoqueComunicadorUpdate {
  id: string;
  params: any;
}

interface ITransferenciaLocalEstoqueComunicadorDeletar {
  id: string;
}

class TransferenciaLocalEstoqueComunicador {
  public async index(
    params: ITransferenciaLocalEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ITransferenciaLocalEstoqueComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: ITransferenciaLocalEstoqueComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: ITransferenciaLocalEstoqueComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: ITransferenciaLocalEstoqueComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new TransferenciaLocalEstoqueComunicador();
