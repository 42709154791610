import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/serie';

interface ISerieComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ISerieComunicadorShow {
  id: string;
}

interface ISerieComunicadorStore {
  params: any;
}

interface ISerieComunicadorUpdate {
  id: string;
  params: any;
}

interface ISerieComunicadorDeletar {
  id: string;
}

class SerieComunicador {
  public async index(params: ISerieComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ISerieComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ISerieComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: ISerieComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ISerieComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new SerieComunicador();
