"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsOperacoesInterestaduaisCalculo;
const Erros_1 = require("../../Erros");
function IcmsOperacoesInterestaduaisCalculo(dados) {
    const retorno = Object.assign({}, dados);
    if (!dados.utiliza) {
        retorno.calcular = false;
        retorno.aliquotaEstadoDestino = null;
        retorno.aliquotaInterestadual = null;
        retorno.aliquotaOrigemDestino = null;
        retorno.estadoOrigemBaseCalculo = null;
        retorno.estadoOrigemPercentualPartilha = null;
        retorno.estadoOrigemValorIcms = null;
        retorno.estadoDestinoBaseCalculo = null;
        retorno.estadoDestinoPercentualPartilha = null;
        retorno.estadoDestinoValorIcmsSemFcp = null;
        retorno.estadoDestinoPercentualFcp = null;
        retorno.estadoDestinoValorFcp = null;
        retorno.estadoDestinoValorIcmsComFcp = null;
        return retorno;
    }
    // if (
    //   Number(dados.aliquotaInterestadual) > Number(dados.aliquotaEstadoDestino) &&
    //   Number(dados.aliquotaEstadoDestino) > 0
    // ) {
    //   throw new AppErro({
    //     mensagem:
    //       'A Aliquota do Estado de Destino deve ser maior que a Aliquota Interestadual!',
    //   });
    // }
    if (dados.calcular) {
        if (Number(dados.aliquotaInterestadual) >
            Number(dados.aliquotaEstadoDestino) &&
            Number(dados.aliquotaEstadoDestino) > 0) {
            throw new Erros_1.AppErro({
                mensagem: 'A Aliquota do Estado de Destino deve ser maior que a Aliquota Interestadual!',
            });
        }
        if (dados.aliquotaEstadoDestino && dados.aliquotaInterestadual) {
            retorno.aliquotaOrigemDestino =
                Number(dados.aliquotaEstadoDestino) -
                    Number(dados.aliquotaInterestadual);
        }
        if (dados.valorIpiItem === null)
            dados.valorIpiItem = 0;
        const baseCalculo = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
            Number(dados.valorFreteItem) +
            Number(dados.valorSeguroItem) +
            Number(dados.valorIpiItem) +
            Number(dados.valorOutrasDespesasItem) -
            Number(dados.valorDescontoItem);
        retorno.estadoOrigemBaseCalculo = baseCalculo;
        retorno.estadoDestinoBaseCalculo = baseCalculo;
        retorno.estadoOrigemValorIcms = baseCalculo
            .CalcularPercentual(Number(retorno.aliquotaOrigemDestino))
            .CalcularPercentual(Number(dados.estadoOrigemPercentualPartilha))
            .Arredondar();
        retorno.estadoDestinoValorIcmsSemFcp = baseCalculo
            .CalcularPercentual(Number(retorno.aliquotaOrigemDestino))
            .CalcularPercentual(Number(dados.estadoDestinoPercentualPartilha))
            .Arredondar();
        retorno.estadoDestinoValorFcp = baseCalculo
            .CalcularPercentual(Number(dados.estadoDestinoPercentualFcp))
            .Arredondar();
    }
    else {
        if (dados.aliquotaEstadoDestino &&
            dados.aliquotaEstadoDestino > 0 &&
            dados.aliquotaInterestadual &&
            dados.aliquotaInterestadual > 0 &&
            Number(dados.aliquotaEstadoDestino) > Number(dados.aliquotaInterestadual)) {
            retorno.aliquotaOrigemDestino =
                Number(dados.aliquotaEstadoDestino) -
                    Number(dados.aliquotaInterestadual);
        }
        // if (
        //   dados.estadoOrigemBaseCalculo &&
        //   dados.estadoOrigemBaseCalculo > 0 &&
        //   dados.aliquotaOrigemDestino &&
        //   dados.aliquotaOrigemDestino > 0
        // ) {
        //   retorno.estadoOrigemValorIcms = Number(dados.estadoOrigemBaseCalculo)
        //     .CalcularPercentual(Number(retorno.aliquotaOrigemDestino))
        //     .CalcularPercentual(Number(dados.estadoOrigemPercentualPartilha))
        //     .Arredondar();
        // }
        // if (
        //   dados.estadoDestinoBaseCalculo &&
        //   dados.estadoDestinoBaseCalculo > 0 &&
        //   dados.aliquotaOrigemDestino &&
        //   dados.aliquotaOrigemDestino > 0
        // ) {
        //   retorno.estadoDestinoValorIcmsSemFcp = Number(
        //     dados.estadoDestinoBaseCalculo
        //   )
        //     .CalcularPercentual(Number(retorno.aliquotaOrigemDestino))
        //     .CalcularPercentual(Number(dados.estadoDestinoPercentualPartilha))
        //     .Arredondar();
        // }
        // if (
        //   dados.estadoDestinoBaseCalculo &&
        //   dados.estadoDestinoBaseCalculo > 0 &&
        //   dados.estadoDestinoPercentualFcp &&
        //   dados.estadoDestinoPercentualFcp > 0
        // ) {
        //   retorno.estadoDestinoValorFcp = Number(dados.estadoDestinoBaseCalculo)
        //     .CalcularPercentual(Number(dados.estadoDestinoPercentualFcp))
        //     .Arredondar();
        // }
    }
    if (retorno.estadoDestinoValorIcmsSemFcp || retorno.estadoDestinoValorFcp) {
        retorno.estadoDestinoValorIcmsComFcp =
            Number(retorno.estadoDestinoValorIcmsSemFcp) +
                Number(retorno.estadoDestinoValorFcp);
    }
    else {
        retorno.estadoDestinoValorIcmsComFcp = null;
    }
    return retorno;
}
