import React from 'react';
import CategoriaHook from './ListaDetalheCategoria/Hooks';
import ListaDetalheCategoria from './ListaDetalheCategoria/Lista';

const CategoriaTab: React.FC = () => {
  return (
    <>
      <CategoriaHook>
        <ListaDetalheCategoria name="listaContaCategoria" />
      </CategoriaHook>
    </>
  );
};

export default CategoriaTab;
