import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';

const ItemInformacaoSpedTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={8} sm={12}>
            <Input
              label="Código Beneficiário Fiscal SPED"
              name="ordemServicoItemInformacaoSped.codigoBeneficiarioFiscalSped"
              placeholder="Código Beneficiário Fiscal SPED"
              maxLength={10}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Input
              label="Base Legal ICMS"
              name="ordemServicoItemInformacaoSped.baseLegalIcms"
              placeholder="Base Legal ICMS"
              maxLength={500}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemInformacaoSpedTab;
