import { IPedidoVendaRetratoComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoVendaRetratoComFotoProdutoPDF from './PedidoVendaRetratoComFotoProdutoImpressaoPDF';

interface IImpressaoPedidoVendaRetratoComFotoProduto {
  handleVisualizarPdf(
    data: IPedidoVendaRetratoComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IPedidoVendaRetratoComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPedidoVendaRetratoComFotoProduto =
  (): IImpressaoPedidoVendaRetratoComFotoProduto => {
    async function handleVisualizarPdf(
      dados: IPedidoVendaRetratoComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IPedidoVendaRetratoComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoVendaRetratoComFotoProduto();
