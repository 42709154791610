import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/tabela-preco/regra-escolha-tabela-preco/ordem';

interface IRegraEscolhaTabelaPrecoOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaTabelaPrecoOrdemComunicador {
  public async update(
    params: IRegraEscolhaTabelaPrecoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaTabelaPrecoOrdemComunicador();
