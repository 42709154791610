import React from 'react';
import Divisor from '../../../../../Componentes/Divisor';
import ConhecimentoTransporteServicoHook from './ListaDetalheServico/Hooks';
import ListaDetalheServico from './ListaDetalheServico/Lista';

export const ServicoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <ConhecimentoTransporteServicoHook>
          <ListaDetalheServico name="listaConhecimentoTransporteServico" />
        </ConhecimentoTransporteServicoHook>
      </Divisor>
    </div>
  );
};
