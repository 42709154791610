import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/compras/pedido-compra/pedido-compra-resumo';

interface IPedidoCompraResumoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoCompraResumoRelatorioComunicador {
  public async index(
    params: IPedidoCompraResumoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoCompraResumoRelatorioComunicador();
