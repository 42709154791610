/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable default-case */
import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import {
  IImportacaoNfeTerceiroEtapa3ItemLista,
  FormatarEnum,
  EstoqueIndicadorTipoEnum,
  TipoMovimentoEntradaSaidaEnum,
  IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista,
  IListaEstoqueUtilizadoAutoComplete,
  ObterRegraIndicadorTipoEstoqueEntrada,
  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum,
  FormatarRemovendoEnum,
} from '@elogestor/util';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import InputLabel from '../../../../../../../Componentes/Inputs/InputLabel';
import Divisor from '../../../../../../../Componentes/Divisor';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarAvisos from '../../../../../../../Util/Aviso/TratarAvisos';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../../Componentes/Select';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompletePessoa from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputAutoCompleteLote from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UltimaTr } from '../styles';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseReactSizeMeBodyHook } from '../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicador';
import { Sleep } from '../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

export interface IImportacaoNfeTerceiroEtapa3Ref {
  handleSetarListaItem(listaValor: any[]): void;
  handleObterListaItem(): IImportacaoNfeTerceiroEtapa3ItemLista[];
  handleValidar(): Promise<boolean>;
}

interface IImportacaoNfeTerceiroEtapa3Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoNfeTerceiroEtapa3: React.ForwardRefRenderFunction<
  IImportacaoNfeTerceiroEtapa3Ref,
  IImportacaoNfeTerceiroEtapa3Props
> = ({ name, formRef, loading, setLoading, refresh }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const [listaItem, setListaItem] = useState<
    IImportacaoNfeTerceiroEtapa3ItemLista[]
  >([]);

  const valorRef = useRef<IImportacaoNfeTerceiroEtapa3ItemLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IImportacaoNfeTerceiroEtapa3ItemLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IImportacaoNfeTerceiroEtapa3ItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    const listaMensagemAviso: string[] = [];
    const listaMensagemErro: string[] = [];

    listaItem.forEach((item, index) => {
      item.listaItemDesmembrado.forEach(
        (itemDesmembrado, indexItemDesmembrado) => {
          if (itemDesmembrado.estoque.movimentarEstoque) {
            const indicadorTipoEstoque = formRef.current?.getFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
            );

            if (
              itemDesmembrado.estoque.porContaOrdemTerceiro &&
              !itemDesmembrado.estoque.pessoaTerceiro?.id
            ) {
              listaMensagemErro.push('Pessoa Terceiro é obrigatória!');
            }

            if (
              indicadorTipoEstoque !==
              EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio
            ) {
              const totalEntrada =
                itemDesmembrado.estoque.listaEstoqueLocalEstoque
                  ?.filter(
                    (
                      estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                    ) => {
                      return (
                        estoque.tipoEntradaSaida ===
                        TipoMovimentoEntradaSaidaEnum.entrada
                      );
                    }
                  )
                  .reduce(
                    (
                      acumulador: number,
                      estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                    ) => {
                      acumulador += Number(estoque.quantidade);
                      return acumulador;
                    },
                    0
                  );

              if (
                itemDesmembrado.estoque.movimentarEstoque &&
                Number(totalEntrada) >
                  itemDesmembrado.estoque.quantidadeEntradaEstoque
              ) {
                listaMensagemErro.push(
                  `Soma da quantidade dos locais de estoque das entradas é maior que a quantidade de estoque do Item Desmembrado: ${itemDesmembrado.sequencia}!`
                );
              }

              if (
                itemDesmembrado.estoque.movimentarEstoque &&
                Number(totalEntrada) <
                  itemDesmembrado.estoque.quantidadeEntradaEstoque
              ) {
                listaMensagemErro.push(
                  `Soma da quantidade dos locais de estoque das entradas é diferente da quantidade do estoque do Item Desmembrado: ${itemDesmembrado.sequencia}!`
                );
              }
            }

            if (
              indicadorTipoEstoque ===
                EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro ||
              indicadorTipoEstoque ===
                EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio ||
              indicadorTipoEstoque ===
                EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
            ) {
              const totalSaida =
                itemDesmembrado.estoque.listaEstoqueLocalEstoque
                  ?.filter(
                    (
                      estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                    ) => {
                      return (
                        estoque.tipoEntradaSaida ===
                        TipoMovimentoEntradaSaidaEnum.saida
                      );
                    }
                  )
                  .reduce(
                    (
                      acumulador: number,
                      estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                    ) => {
                      acumulador += Number(estoque.quantidade);
                      return acumulador;
                    },
                    0
                  );

              if (
                itemDesmembrado.estoque.movimentarEstoque &&
                Number(totalSaida) >
                  itemDesmembrado.estoque.quantidadeEntradaEstoque
              ) {
                listaMensagemErro.push(
                  `Soma da quantidade dos locais de estoque das saídas é maior que a quantidade de estoque do Item Desmembrado: ${itemDesmembrado.sequencia}!`
                );
              }

              if (
                itemDesmembrado.estoque.movimentarEstoque &&
                Number(totalSaida) <
                  itemDesmembrado.estoque.quantidadeEntradaEstoque
              ) {
                listaMensagemErro.push(
                  `Soma da quantidade dos locais de estoque das saídas é diferente da quantidade do estoque do Item Desmembrado: ${itemDesmembrado.sequencia}!`
                );
              }
            }
          }
        }
      );
    });

    if (listaMensagemAviso.length > 0) {
      TratarAvisos(listaMensagemAviso);
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, [formRef, listaItem]);

  const handleSetarListaItem = useCallback((listaValorItem: any[]): void => {
    setListaItem(listaValorItem);
  }, []);

  const handleObterListaItem =
    useCallback((): IImportacaoNfeTerceiroEtapa3ItemLista[] => {
      return listaItem;
    }, [listaItem]);

  useImperativeHandle(ref, () => {
    return {
      handleSetarListaItem,
      handleObterListaItem,
      handleValidar,
    };
  });

  const handleAtualizarEstoqueQuantidade = useCallback(
    async (index: number, indexItemDesmembrado: number): Promise<void> => {
      const itemDesmembrado =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`
      );
      const quantidadeEntradaEstoque = formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.quantidadeEntradaEstoque`
      );

      if (estoqueTipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada) {
        const totalEntrada = itemDesmembrado.estoque.listaEstoqueLocalEstoque
          ?.filter(
            (
              estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
            ) => {
              return (
                estoque.tipoEntradaSaida ===
                TipoMovimentoEntradaSaidaEnum.entrada
              );
            }
          )
          .reduce(
            (
              acumulador: number,
              estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(estoque.quantidade);
              return acumulador;
            },
            0
          );

        let estoqueQuantidade = quantidadeEntradaEstoque - totalEntrada;
        if (estoqueQuantidade < 0) estoqueQuantidade = 0;

        formRef.current?.setFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueQuantidade`,
          estoqueQuantidade
        );
      } else {
        const totalSaida = itemDesmembrado.estoque.listaEstoqueLocalEstoque
          ?.filter(
            (
              estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
            ) => {
              return (
                estoque.tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.saida
              );
            }
          )
          .reduce(
            (
              acumulador: number,
              estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(estoque.quantidade);
              return acumulador;
            },
            0
          );

        let estoqueQuantidade = quantidadeEntradaEstoque - totalSaida;
        if (estoqueQuantidade < 0) estoqueQuantidade = 0;

        formRef.current?.setFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueQuantidade`,
          estoqueQuantidade
        );
      }
    },
    [formRef, listaItem]
  );

  // #region Obter

  const handleObterProdutoJaCadastrado = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { produto } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (produto && produto.id) return true;

      return false;
    },
    [listaItem]
  );

  const handleObterMovimentarEstoque = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoque`
      );
    },
    [formRef]
  );

  const handleObterControlarLote = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.controlarLote`
      );
    },
    [formRef]
  );

  const handleObterMovimentarEstoqueFiscal = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoqueFiscal`
      );
    },
    [formRef]
  );

  const handleObterIndicadorTipoEstoque = useCallback(
    (index: number, indexItemDesmembrado: number): EstoqueIndicadorTipoEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
      );
    },
    [formRef]
  );

  const handleObterPorContaOrdemTerceiro = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`
      );
    },
    [formRef]
  );

  const handleObterPessoaTerceiroFormatada = useCallback(
    (
      index: number,
      indexItemDesmembrado: number,
      indicadorTipoEstoqueGeral?: boolean
    ): any | undefined => {
      const { pessoaNf } = listaItem[index];

      const porContaOrdemTerceiro = formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`
      );
      const pessoaTerceiro = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.pessoaTerceiro`
      );

      const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`
      );

      let indicadorTipoEstoque: any;
      if (indicadorTipoEstoqueGeral) {
        indicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
        );
      } else {
        indicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`
        );
      }

      if (
        indicadorTipoEstoque === EstoqueIndicadorTipoEnum.proprioEmPoderProprio
      )
        return undefined;

      if (porContaOrdemTerceiro && pessoaTerceiro) {
        if (
          indicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro &&
          estoqueTipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.saida
        )
          return pessoaNf;

        return pessoaTerceiro;
      }

      return pessoaNf;
    },
    [formRef, listaItem]
  );

  const handleObterEstoqueTipoEntradaSaida = useCallback(
    (
      index: number,
      indexItemDesmembrado: number
    ): TipoMovimentoEntradaSaidaEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`
      );
    },
    [formRef]
  );

  const handleObterEstoqueIndicadorTipoEstoque = useCallback(
    (index: number, indexItemDesmembrado: number): EstoqueIndicadorTipoEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`
      );
    },
    [formRef]
  );

  const handleObterEstoqueLocalEstoque = useCallback(
    (index: number, indexItemDesmembrado: number): any | undefined => {
      return formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`
      );
    },
    [formRef]
  );

  const handleObterListaEstoqueUtilizado = useCallback(
    (
      index: number,
      indexItemDesmembrado: number
    ): IListaEstoqueUtilizadoAutoComplete[] => {
      const itemDesmembrado =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (!itemDesmembrado.estoque.listaEstoqueLocalEstoque) return [];

      const retorno = itemDesmembrado.estoque.listaEstoqueLocalEstoque.map(
        (valor) => {
          return {
            idItemEstoqueLocalEstoque: '',
            idLocalEstoque: valor.localEstoque?.id,
            idLote: valor.lote?.id,
            quantidade: valor.quantidade,
            tipoEntradaSaida: valor.tipoEntradaSaida,
            indicadorTipoEstoque: valor.indicadorTipoEstoque,
          };
        }
      );

      return retorno;
    },
    [listaItem]
  );

  const handleObterPodeAlterarEstoque = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { pessoaNf } = listaItem[index];
      const indicadorTipoEstoque = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
      );
      const porContaOrdemTerceiro = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`
      );
      const pessoaTerceiro = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.pessoaTerceiro`
      );

      if (
        (indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro ||
          indicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio ||
          indicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte) &&
        !pessoaNf.id &&
        (!porContaOrdemTerceiro || (porContaOrdemTerceiro && !pessoaTerceiro))
      )
        return false;

      if (
        indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro &&
        !pessoaNf.id
      )
        return false;

      return true;
    },
    [formRef, listaItem]
  );

  // #endregion Obter

  // #region Estoque

  const handleAtualizarQuantidadeTotalEstoqueLocalEstoque = useCallback(
    async (index: number, indexItemDesmembrado: number): Promise<void> => {
      const itemDesmembrado =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      const total = itemDesmembrado.estoque.listaEstoqueLocalEstoque?.reduce(
        (acumulador: number, item: any) => {
          acumulador += Number(item.quantidade);
          return acumulador;
        },
        0
      );

      itemDesmembrado.estoque.quantidadeTotalEstoqueLocalEstoque =
        Number(total);

      await handleAtualizarEstoqueQuantidade(index, indexItemDesmembrado);
      refresh();
    },
    [handleAtualizarEstoqueQuantidade, listaItem, refresh]
  );

  const handleAtualizarListaEstoqueLocalEstoque = useCallback(
    async (index: number, indexItemDesmembrado: number): Promise<void> => {
      try {
        setLoading(true);

        const item = listaItem[index];
        const itemDesmembrado =
          listaItem[index].listaItemDesmembrado[indexItemDesmembrado];
        itemDesmembrado.estoque.listaEstoqueLocalEstoque = [];
        itemDesmembrado.estoque.quantidadeTotalEstoqueLocalEstoque = 0;

        const indicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
        );
        const movimentarEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoque`
        );
        const controlarLote = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.controlarLote`
        );

        const pessoa = handleObterPessoaTerceiroFormatada(
          index,
          indexItemDesmembrado,
          true
        );

        const response =
          await ImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicador.index(
            {
              params: {
                idPessoa: pessoa?.id,
                idProduto: itemDesmembrado.produto?.id,
                indicadorTipoEstoque,
                dataHoraEmissao: item.dataLancamento,
                quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                movimentarEstoque,
                controlarLote,
              },
            }
          );

        if (response) {
          switch (indicadorTipoEstoque) {
            case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
              itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                {
                  ...response,
                  quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                },
              ];
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
              if (pessoa.id) {
                itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  },
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: pessoa,
                  },
                ];
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
              if (pessoa.id) {
                itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: pessoa,
                  },
                ];
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
              if (pessoa.id) {
                itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: pessoa,
                  },
                ];
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
              if (pessoa.id) {
                itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: pessoa,
                  },
                ];
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
              if (pessoa.id) {
                itemDesmembrado.estoque.listaEstoqueLocalEstoque = [
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: pessoa,
                  },
                  {
                    ...response,
                    quantidade: itemDesmembrado.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                    pessoaTerceiro: item.pessoaNf,
                  },
                ];
              }
              break;
          }
        }

        handleAtualizarQuantidadeTotalEstoqueLocalEstoque(
          index,
          indexItemDesmembrado
        );

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [
      formRef,
      handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
      handleObterPessoaTerceiroFormatada,
      listaItem,
      refresh,
      setLoading,
    ]
  );

  const handleOnAlterarMovimentarEstoque = useCallback(
    async (
      movimentarEstoque: boolean,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (!produto?.id) {
        for (let i = 0; i < listaItem.length; i++) {
          const itemLista = listaItem[i];

          for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
            const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

            if (
              itemDesmembradoLista.sequencia !== sequencia &&
              itemDesmembradoLista.produtoCodigoDescricao ===
                produtoCodigoDescricao &&
              itemDesmembradoLista.produto?.id === produto?.id &&
              itemDesmembradoLista.produto?.codigo === produto?.codigo &&
              itemDesmembradoLista.produto?.descricao === produto?.descricao &&
              itemDesmembradoLista.codigoBarras === codigoBarras
            ) {
              itemDesmembradoLista.estoque.movimentarEstoque =
                movimentarEstoque;

              formRef.current?.setFieldValorInicialSemExecutarEvento(
                `listaEtapa3[${i}].listaItemDesmembrado[${j}].estoque.movimentarEstoque`,
                movimentarEstoque
              );

              itemDesmembradoLista.estoque.listaEstoqueLocalEstoque = [];

              handleAtualizarQuantidadeTotalEstoqueLocalEstoque(
                index,
                indexItemDesmembrado
              );
            }
          }
        }
      }
    },
    [formRef, handleAtualizarQuantidadeTotalEstoqueLocalEstoque, listaItem]
  );

  const handleOnAlterarControlarLote = useCallback(
    async (
      controlarLote: boolean,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (!produto?.id) {
        for (let i = 0; i < listaItem.length; i++) {
          const itemLista = listaItem[i];

          for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
            const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

            if (
              itemDesmembradoLista.sequencia !== sequencia &&
              itemDesmembradoLista.produtoCodigoDescricao ===
                produtoCodigoDescricao &&
              itemDesmembradoLista.produto?.id === produto?.id &&
              itemDesmembradoLista.produto?.codigo === produto?.codigo &&
              itemDesmembradoLista.produto?.descricao === produto?.descricao &&
              itemDesmembradoLista.codigoBarras === codigoBarras
            ) {
              itemDesmembradoLista.estoque.controlarLote = controlarLote;

              formRef.current?.setFieldValorInicialSemExecutarEvento(
                `listaEtapa3[${i}].listaItemDesmembrado[${j}].estoque.controlarLote`,
                controlarLote
              );

              itemDesmembradoLista.estoque.listaEstoqueLocalEstoque = [];

              handleAtualizarQuantidadeTotalEstoqueLocalEstoque(
                index,
                indexItemDesmembrado
              );
            }
          }
        }
      }
    },
    [formRef, handleAtualizarQuantidadeTotalEstoqueLocalEstoque, listaItem]
  );

  const handleOnAlterarIndicadorTipoEstoque = useCallback(
    async (index: number, indexItemDesmembrado: number): Promise<void> => {
      const itemDesmembrado =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];
      itemDesmembrado.estoque.listaEstoqueLocalEstoque = [];
      itemDesmembrado.estoque.quantidadeTotalEstoqueLocalEstoque = 0;

      const indicadorTipoEstoque = formRef.current?.getFieldValue(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
      );

      if (
        indicadorTipoEstoque !== EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
      ) {
        formRef.current?.setFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoqueFiscal`,
          false
        );
      }

      switch (indicadorTipoEstoque) {
        case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`,
            false
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.entrada;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          itemDesmembrado.estoque.porContaOrdemTerceiro = false;
          break;

        case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.entrada;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          break;

        case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.saida;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;
          break;

        case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.entrada;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.entrada;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
          );
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`,
            true
          );

          itemDesmembrado.estoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.entrada;
          itemDesmembrado.estoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
          itemDesmembrado.estoque.porContaOrdemTerceiro = true;
          break;
      }

      formRef.current?.clearField(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`
      );
      formRef.current?.clearField(
        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`
      );
    },
    [formRef, listaItem]
  );

  const handleValidarEstoqueLocalEstoque = useCallback(
    async (
      data: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const produtoControlaLote = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.controlarLote`
        );

        const schema = Yup.object().shape({
          localEstoque: Yup.mixed()
            .nullable()
            .test({
              message: 'Local de Estoque é obrigatório!',
              test: () => {
                return !!data.localEstoque?.id;
              },
            }),

          lote: Yup.mixed()
            .nullable()
            .test({
              message: 'Lote é obrigatório!',
              test: () => {
                return !(!data.lote?.id && produtoControlaLote);
              },
            }),

          quantidade: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade deve ser maior que zero!',
              test: () => {
                return !(data.quantidade === 0);
              },
            }),

          tipoEntradaSaida: Yup.string()
            .oneOf(Object.values(TipoMovimentoEntradaSaidaEnum))
            .required('Tipo Entrada/Saída é obrigatório!'),

          indicadorTipoEstoque: Yup.string()
            .oneOf(Object.values(EstoqueIndicadorTipoEnum))
            .required('Tipo de Estoque é obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleAdicionarEstoqueLocalEstoque = useCallback(
    async (index: number, indexItemDesmembrado: number): Promise<void> => {
      try {
        setLoading(true);

        const itemDesmembrado =
          listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

        const indicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`
        );

        const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`
        );
        const estoqueIndicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`
        );
        const estoqueLocalEstoque = formRef.current?.getFieldValueNomeObjeto(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`
        );
        const estoqueLote = formRef.current?.getFieldValueNomeObjeto(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`
        );
        const estoqueQuantidade = formRef.current?.getFieldValue(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueQuantidade`
        );

        const dados: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista =
          {
            localEstoque: estoqueLocalEstoque,
            lote: estoqueLote ?? undefined,
            quantidade: Number(estoqueQuantidade),
            tipoEntradaSaida: estoqueTipoEntradaSaida,
            indicadorTipoEstoque: estoqueIndicadorTipoEstoque,
          };

        const pessoaTerceiroFormatada = handleObterPessoaTerceiroFormatada(
          index,
          indexItemDesmembrado
        );

        if (pessoaTerceiroFormatada) {
          dados.pessoaTerceiro = pessoaTerceiroFormatada;
        }

        if (
          !(await handleValidarEstoqueLocalEstoque(
            dados,
            index,
            indexItemDesmembrado
          ))
        ) {
          setLoading(false);
          return;
        }

        itemDesmembrado.estoque.listaEstoqueLocalEstoque?.push(dados);

        switch (indicadorTipoEstoque) {
          case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
            );
            break;

          case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
            );
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
            formRef.current?.setFieldValue(
              `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
            );
            break;
        }

        if (
          indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio
        ) {
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
        } else {
          formRef.current?.setFieldValue(
            `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.entrada
          );
        }

        formRef.current?.clearField(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`
        );
        formRef.current?.clearField(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`
        );
        formRef.current?.clearField(
          `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueQuantidade`
        );

        handleAtualizarQuantidadeTotalEstoqueLocalEstoque(
          index,
          indexItemDesmembrado
        );

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [
      formRef,
      handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
      handleObterPessoaTerceiroFormatada,
      handleValidarEstoqueLocalEstoque,
      listaItem,
      refresh,
      setLoading,
    ]
  );

  const handleExcluirEstoqueLocalEstoque = useCallback(
    async (
      index: number,
      indexItemDesmembrado: number,
      indexEstoqueLocalEstoque: number
    ): Promise<void> => {
      try {
        setLoading(true);

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>Deseja remover o item?</span>
          ),
        });

        if (resposta) {
          const itemDesmembrado =
            listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

          itemDesmembrado.estoque.listaEstoqueLocalEstoque?.splice(
            indexEstoqueLocalEstoque,
            1
          );

          handleAtualizarQuantidadeTotalEstoqueLocalEstoque(
            index,
            indexItemDesmembrado
          );
        }

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [
      abrirJanela,
      handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
      listaItem,
      refresh,
      setLoading,
    ]
  );

  // #endregion Estoque

  const handleOnReplicarTerceiro = useCallback(
    async (
      pessoaTerceiro: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      try {
        await Sleep(50);

        setLoading(true);

        let isNecessarioReplicarTerceiros = false;
        const { indicadorTipoEstoque } =
          listaItem[index].listaItemDesmembrado[indexItemDesmembrado].estoque;

        listaItem.forEach((item) => {
          item.listaItemDesmembrado.forEach((desmembrado) => {
            if (!desmembrado.estoque.pessoaTerceiro) {
              if (
                desmembrado.estoque.indicadorTipoEstoque ===
                  indicadorTipoEstoque &&
                desmembrado.estoque.porContaOrdemTerceiro
              ) {
                isNecessarioReplicarTerceiros = true;
              }
            }
          });
        });

        if (isNecessarioReplicarTerceiros) {
          if (pessoaTerceiro) {
            const resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 20 }}>
                  Deseja carregar o Por Conta e Ordem de Terceiros e o Terceiro
                  para todos os itens com mesmo tipo de estoque?
                </span>
              ),
            });

            if (resposta) {
              await Promise.all(
                listaItem.map(async (itemLista, i) => {
                  itemLista.listaItemDesmembrado.map(
                    async (itemDesmembradoLista, j) => {
                      if (
                        itemDesmembradoLista.estoque.indicadorTipoEstoque ===
                        indicadorTipoEstoque
                      ) {
                        if (
                          itemDesmembradoLista.estoque.porContaOrdemTerceiro
                        ) {
                          itemDesmembradoLista.estoque.pessoaTerceiro =
                            pessoaTerceiro;

                          formRef.current?.setFieldValue(
                            `listaEtapa3[${i}].listaItemDesmembrado[${j}].estoque.pessoaTerceiro`,
                            pessoaTerceiro
                          );
                        }
                      }
                    }
                  );
                })
              );
            }
          }
        }

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [abrirJanela, formRef, listaItem, refresh, setLoading]
  );

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <Divisor>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <tbody>
              {listaItem?.map((item, index) => (
                <>
                  <tr>
                    <td colSpan={4} style={{ padding: 0 }}>
                      <Divisor style={{ marginBottom: 0 }}>
                        <Row>
                          <Col
                            lg={1}
                            md={6}
                            sm={6}
                            style={{ fontWeight: 'normal' }}
                          >
                            <InputLabel
                              label="Seq."
                              name={`listaEtapa3[${index}].itemNf.sequencia`}
                            />
                          </Col>
                          <Col lg={3} md={6} sm={6}>
                            <InputLabel
                              label="Cód. (NF.)"
                              name={`listaEtapa3[${index}].itemNf.produtoCodigo`}
                            />
                          </Col>
                          <Col lg={7} md={6} sm={12}>
                            <InputLabel
                              label="Descrição (NF.)"
                              name={`listaEtapa3[${index}].itemNf.produtoDescricao`}
                            />
                          </Col>
                          <Col lg={1} md={6} sm={12}>
                            <InputLabel
                              label="CFOP"
                              name={`listaEtapa3[${index}].itemNf.cfop`}
                            />
                          </Col>
                        </Row>
                      </Divisor>
                    </td>
                  </tr>

                  {item.listaItemDesmembrado.map(
                    (itemDesmembrado, indexItemDesmembrado) => (
                      <tr>
                        <td
                          colSpan={4}
                          style={{ padding: 0, paddingBottom: 0 }}
                        >
                          <Divisor
                            style={{
                              borderRadius: 0,
                              marginBottom: 0,
                              backgroundColor:
                                indexItemDesmembrado % 2 === 0
                                  ? '#fff'
                                  : '#EEEEEE',
                            }}
                          >
                            <Row>
                              <Col
                                lg={1}
                                md={2}
                                sm={2}
                                style={{ fontWeight: 'normal' }}
                              >
                                <InputLabel
                                  label="Seq."
                                  name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sequencia`}
                                  labelStyle={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    color: 'darkBlue',
                                    fontWeight: 'bold',
                                  }}
                                  valorStyle={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    color: 'darkBlue',
                                    fontWeight: 'bold',
                                    fontSize: 22,
                                  }}
                                />
                              </Col>
                              <Col lg={11} md={10} sm={10}>
                                <InputLabel
                                  label={`Produto ${
                                    handleObterProdutoJaCadastrado(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'Cadastrado'
                                      : 'a ser Cadastrado'
                                  }`}
                                  name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].produtoCodigoDescricao`}
                                  labelStyle={{
                                    color: !handleObterProdutoJaCadastrado(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'red'
                                      : '',
                                  }}
                                />
                              </Col>
                            </Row>

                            <Divisor style={{ backgroundColor: 'lightgray' }}>
                              <Row>
                                <Col lg={4} md={6} sm={12}>
                                  <Select
                                    label="Tipo de Estoque"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.indicadorTipoEstoque`}
                                    options={FormatarEnum({
                                      enumObj: EstoqueIndicadorTipoEnum,
                                    })}
                                    onChange={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.indicadorTipoEstoque = event
                                        .currentTarget
                                        .value as EstoqueIndicadorTipoEnum;

                                      handleOnAlterarIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );
                                      handleAtualizarListaEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={3} md={3} sm={12}>
                                  <InputSwitch
                                    label="Movimentar Estoque"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoque`}
                                    ativo="Sim"
                                    inativo="Não"
                                    onChange={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.movimentarEstoque =
                                        event.currentTarget.checked;

                                      handleAtualizarListaEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );

                                      handleOnAlterarMovimentarEstoque(
                                        event.currentTarget.checked,
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                    disabled={handleObterProdutoJaCadastrado(
                                      index,
                                      indexItemDesmembrado
                                    )}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={12}
                                  style={{
                                    display: !handleObterMovimentarEstoque(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'none'
                                      : '',
                                  }}
                                >
                                  <InputSwitch
                                    label="Controlar Lotes"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.controlarLote`}
                                    ativo="Sim"
                                    inativo="Não"
                                    onChange={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.controlarLote =
                                        event.currentTarget.checked;

                                      handleAtualizarListaEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );

                                      handleOnAlterarControlarLote(
                                        event.currentTarget.checked,
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                    disabled={handleObterProdutoJaCadastrado(
                                      index,
                                      indexItemDesmembrado
                                    )}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={12}
                                  style={{
                                    display:
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) !==
                                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
                                        ? 'none'
                                        : '',
                                  }}
                                >
                                  <InputSwitch
                                    label="Movimentar Estoque Fiscal"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.movimentarEstoqueFiscal`}
                                    ativo="Sim"
                                    inativo="Não"
                                    onChange={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.movimentarEstoqueFiscal =
                                        event.currentTarget.checked;
                                    }}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  md={6}
                                  sm={12}
                                  style={{
                                    display:
                                      !handleObterMovimentarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) &&
                                      !handleObterMovimentarEstoqueFiscal(
                                        index,
                                        indexItemDesmembrado
                                      )
                                        ? 'none'
                                        : '',
                                  }}
                                >
                                  <InputLabel
                                    label="Qtde. Entrada/Estoque"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.quantidadeEntradaEstoque`}
                                    formatarValor={(valor) => {
                                      if (!valor) valor = 0;
                                      return Number(valor).FormatarParaPtBr();
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  display:
                                    !handleObterMovimentarEstoque(
                                      index,
                                      indexItemDesmembrado
                                    ) &&
                                    !handleObterMovimentarEstoqueFiscal(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'none'
                                      : '',
                                }}
                              >
                                <Col lg={2} md={3} sm={12}>
                                  <InputSwitch
                                    label="Por Conta e Ord. Terc."
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.porContaOrdemTerceiro`}
                                    ativo="Sim"
                                    inativo="Não"
                                    onChange={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.porContaOrdemTerceiro =
                                        event.currentTarget.checked;

                                      handleAtualizarListaEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                    disabled={
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
                                    }
                                  />
                                </Col>
                                <Col
                                  lg={10}
                                  md={9}
                                  sm={12}
                                  style={{
                                    display: !handleObterPorContaOrdemTerceiro(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'none'
                                      : '',
                                  }}
                                >
                                  <InputAutoCompletePessoa
                                    label="Pessoa Terceiro"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.idPessoaTerceiro`}
                                    nomeObjeto={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.pessoaTerceiro`}
                                    placeholder="Pessoa Terceiro"
                                    permitirAdicionar={false}
                                    onChangeItemAtual={(event) => {
                                      refresh();

                                      listaItem[index].listaItemDesmembrado[
                                        indexItemDesmembrado
                                      ].estoque.pessoaTerceiro =
                                        event.itemAtual ?? undefined;

                                      handleAtualizarListaEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );

                                      handleOnReplicarTerceiro(
                                        event.itemAtual,
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Divisor>

                            <Divisor
                              style={{
                                backgroundColor: 'lightgray',
                                display: !handleObterMovimentarEstoque(
                                  index,
                                  indexItemDesmembrado
                                )
                                  ? 'none'
                                  : '',
                              }}
                            >
                              {!handleObterPodeAlterarEstoque(
                                index,
                                indexItemDesmembrado
                              ) && (
                                <h6
                                  style={{ color: 'red', fontWeight: 'bold' }}
                                >
                                  A pessoa da Nota Fiscal não esta Cadastrada.
                                  Essa configuração de estoque não pode ser
                                  utilizada!
                                </h6>
                              )}

                              <Row>
                                <Col lg={3} md={6} sm={12}>
                                  <Select
                                    label="Tipo Entrada/Saída"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueTipoEntradaSaida`}
                                    options={FormatarEnum({
                                      enumObj: TipoMovimentoEntradaSaidaEnum,
                                    })}
                                    onChangeValue={() => {
                                      refresh();
                                    }}
                                    onChange={async (event) => {
                                      refresh();

                                      formRef.current?.clearField(
                                        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`
                                      );
                                      formRef.current?.clearField(
                                        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`
                                      );

                                      const indicadorEstoqueAtualizado =
                                        ObterRegraIndicadorTipoEstoqueEntrada({
                                          indicadorTipoEstoque:
                                            handleObterIndicadorTipoEstoque(
                                              index,
                                              indexItemDesmembrado
                                            ),
                                          tipoEntradaSaida: event.currentTarget
                                            .value as TipoMovimentoEntradaSaidaEnum,
                                        });

                                      formRef.current?.setFieldValue(
                                        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`,
                                        indicadorEstoqueAtualizado
                                      );

                                      await handleAtualizarEstoqueQuantidade(
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                    disabled={
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio ||
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte ||
                                      handleObterIndicadorTipoEstoque(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                        EstoqueIndicadorTipoEnum.terceiroEmPoderProprio ||
                                      !handleObterPodeAlterarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      )
                                    }
                                  />
                                </Col>
                                <Col lg={3} md={6} sm={12}>
                                  <Select
                                    label="Tipo de Estoque"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIndicadorTipoEstoque`}
                                    options={FormatarRemovendoEnum({
                                      enumObj: EstoqueIndicadorTipoEnum,
                                      enumRemover: [
                                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                                      ],
                                    })}
                                    disabled
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col
                                  lg={
                                    handleObterControlarLote(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? '5'
                                      : '8'
                                  }
                                  md={12}
                                >
                                  <InputAutoCompleteLocalEstoque
                                    label="Local de Estoque"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIdLocalEstoque`}
                                    nomeObjeto={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLocalEstoque`}
                                    permitirAdicionar={
                                      handleObterEstoqueTipoEntradaSaida(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                      TipoMovimentoEntradaSaidaEnum.entrada
                                    }
                                    saldoEstoque={
                                      itemDesmembrado.produto?.id
                                        ? {
                                            somenteProdutoComSaldoEstoque:
                                              handleObterEstoqueTipoEntradaSaida(
                                                index,
                                                indexItemDesmembrado
                                              ) ===
                                              TipoMovimentoEntradaSaidaEnum.saida,
                                            idPessoa:
                                              handleObterPessoaTerceiroFormatada(
                                                index,
                                                indexItemDesmembrado
                                              )?.id,
                                            idProduto:
                                              itemDesmembrado.produto?.id,
                                            indicadorTipoEstoque:
                                              handleObterEstoqueIndicadorTipoEstoque(
                                                index,
                                                indexItemDesmembrado
                                              ),
                                            dataHoraEmissao:
                                              item.dataLancamento,
                                            tipoEntradaSaida:
                                              handleObterEstoqueTipoEntradaSaida(
                                                index,
                                                indexItemDesmembrado
                                              ),

                                            localUtilizado:
                                              LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.notaFiscalEntrada,
                                            listaEstoqueUtilizado:
                                              handleObterListaEstoqueUtilizado(
                                                index,
                                                indexItemDesmembrado
                                              ),
                                            exibirSaldoEstoque: true,
                                          }
                                        : undefined
                                    }
                                    onChangeItemAtual={() => {
                                      refresh();
                                      formRef.current?.clearField(
                                        `listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`
                                      );
                                    }}
                                    disabled={
                                      !handleObterPodeAlterarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      )
                                    }
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  md={12}
                                  style={{
                                    display: !handleObterControlarLote(
                                      index,
                                      indexItemDesmembrado
                                    )
                                      ? 'none'
                                      : '',
                                  }}
                                >
                                  <InputAutoCompleteLote
                                    label="Lote"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueIdLote`}
                                    nomeObjeto={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueLote`}
                                    permitirAdicionar={
                                      handleObterEstoqueTipoEntradaSaida(
                                        index,
                                        indexItemDesmembrado
                                      ) ===
                                      TipoMovimentoEntradaSaidaEnum.entrada
                                    }
                                    saldoEstoque={
                                      itemDesmembrado.produto?.id
                                        ? {
                                            somenteProdutoComSaldoEstoque:
                                              handleObterEstoqueTipoEntradaSaida(
                                                index,
                                                indexItemDesmembrado
                                              ) ===
                                              TipoMovimentoEntradaSaidaEnum.saida,
                                            idPessoa:
                                              handleObterPessoaTerceiroFormatada(
                                                index,
                                                indexItemDesmembrado
                                              )?.id,
                                            idProduto:
                                              itemDesmembrado.produto?.id,
                                            indicadorTipoEstoque:
                                              handleObterEstoqueIndicadorTipoEstoque(
                                                index,
                                                indexItemDesmembrado
                                              ),
                                            idLocalEstoque:
                                              handleObterEstoqueLocalEstoque(
                                                index,
                                                indexItemDesmembrado
                                              )?.id,
                                            dataHoraEmissao:
                                              item.dataLancamento,
                                            tipoEntradaSaida:
                                              handleObterEstoqueTipoEntradaSaida(
                                                index,
                                                indexItemDesmembrado
                                              ),

                                            localUtilizado:
                                              LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.notaFiscalEntrada,
                                            listaEstoqueUtilizado:
                                              handleObterListaEstoqueUtilizado(
                                                index,
                                                indexItemDesmembrado
                                              ),
                                            exibirSaldoEstoque: true,
                                          }
                                        : undefined
                                    }
                                    disabled={
                                      !handleObterPodeAlterarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      )
                                    }
                                  />
                                </Col>
                                <Col lg={2} md={12}>
                                  <InputDecimal
                                    label="Quantidade"
                                    name={`listaEtapa3[${index}].listaItemDesmembrado[${indexItemDesmembrado}].estoque.estoqueQuantidade`}
                                    casasInteiras={11}
                                    casasDecimais={4}
                                    disabled={
                                      !handleObterPodeAlterarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      )
                                    }
                                  />
                                </Col>
                                <Col
                                  lg={2}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <BtnPadraoButton
                                    style={{ maxWidth: telaGrande ? 250 : '' }}
                                    type="button"
                                    onClick={() => {
                                      handleAdicionarEstoqueLocalEstoque(
                                        index,
                                        indexItemDesmembrado
                                      );
                                    }}
                                    disabled={
                                      !handleObterPodeAlterarEstoque(
                                        index,
                                        indexItemDesmembrado
                                      )
                                    }
                                    className="btn-padrao btn-verde-claro btn-adicionar"
                                  >
                                    <TextoLoading loading={loading}>
                                      <IoMdAdd />
                                      <span style={{ marginLeft: 10 }}>
                                        Adicionar
                                      </span>
                                    </TextoLoading>
                                  </BtnPadraoButton>
                                </Col>
                              </Row>

                              <Tabela style={{ overflow: 'auto' }}>
                                <Table striped hover bordered variant="light">
                                  <thead>
                                    <tr>
                                      <th
                                        className="lista-texto"
                                        style={{ width: '25%' }}
                                      >
                                        <span>Tipo de Estoque</span>
                                      </th>
                                      <th
                                        className="lista-texto"
                                        style={{ width: '10%' }}
                                      >
                                        <span>Ent./Saída</span>
                                      </th>
                                      <th
                                        className="lista-texto"
                                        style={{
                                          width: handleObterControlarLote(
                                            index,
                                            indexItemDesmembrado
                                          )
                                            ? '35%'
                                            : '50%',
                                        }}
                                      >
                                        <span>Local de Estoque</span>
                                      </th>

                                      {handleObterControlarLote(
                                        index,
                                        indexItemDesmembrado
                                      ) && (
                                        <th
                                          className="lista-texto"
                                          style={{ width: '15%' }}
                                        >
                                          <span>Lote</span>
                                        </th>
                                      )}

                                      <th
                                        className="lista-valor"
                                        style={{ width: '15%' }}
                                      >
                                        <span>Quantidade</span>
                                      </th>
                                      <th className="lista-texto">
                                        <span>Ações</span>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {itemDesmembrado.estoque.listaEstoqueLocalEstoque.map(
                                      (
                                        estoqueLocalEstoque,
                                        indexEstoqueLocalEstoque
                                      ) => {
                                        return (
                                          <>
                                            <tr
                                              style={{
                                                backgroundColor:
                                                  indexEstoqueLocalEstoque %
                                                    2 ===
                                                  0
                                                    ? '#f0f0f1'
                                                    : '#fff',
                                              }}
                                            >
                                              <td className="lista-texto">
                                                <div>
                                                  {
                                                    estoqueLocalEstoque.indicadorTipoEstoque
                                                  }
                                                </div>
                                              </td>
                                              <td className="lista-texto">
                                                <div>
                                                  {
                                                    estoqueLocalEstoque.tipoEntradaSaida
                                                  }
                                                </div>
                                              </td>
                                              <td className="lista-texto">
                                                <div>
                                                  {
                                                    estoqueLocalEstoque
                                                      .localEstoque.descricao
                                                  }
                                                </div>
                                              </td>

                                              {handleObterControlarLote(
                                                index,
                                                indexItemDesmembrado
                                              ) && (
                                                <td className="lista-texto">
                                                  <div>
                                                    {
                                                      estoqueLocalEstoque.lote
                                                        ?.codigo
                                                    }
                                                  </div>
                                                </td>
                                              )}

                                              <td className="lista-valor">
                                                <div>
                                                  {Number(
                                                    estoqueLocalEstoque.quantidade
                                                  )?.FormatarParaPtBr({
                                                    minimoCasasDecimais: 4,
                                                    maximoCasasDecimais: 4,
                                                  })}
                                                </div>
                                              </td>
                                              <td className="tdButton lista-acoes">
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <BtnAcoesButton>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        handleExcluirEstoqueLocalEstoque(
                                                          index,
                                                          indexItemDesmembrado,
                                                          indexEstoqueLocalEstoque
                                                        );
                                                      }}
                                                      disabled={
                                                        loading ||
                                                        !handleObterPodeAlterarEstoque(
                                                          index,
                                                          indexItemDesmembrado
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          display: 'flex',
                                                          fontSize: 16,
                                                        }}
                                                      >
                                                        <IoMdRemoveCircleOutline
                                                          style={{
                                                            marginRight: 5,
                                                          }}
                                                        />
                                                        Remover
                                                      </span>
                                                    </Dropdown.Item>
                                                  </BtnAcoesButton>
                                                </div>
                                              </td>
                                            </tr>

                                            <tr
                                              style={{
                                                backgroundColor:
                                                  indexEstoqueLocalEstoque %
                                                    2 ===
                                                  0
                                                    ? '#f0f0f1'
                                                    : '#fff',
                                                display:
                                                  estoqueLocalEstoque.indicadorTipoEstoque ===
                                                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                                                    ? 'none'
                                                    : '',
                                              }}
                                            >
                                              <td
                                                colSpan={7}
                                                style={{ paddingTop: 0 }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {`PESSOA: `}
                                                </span>
                                                <span>
                                                  {
                                                    estoqueLocalEstoque
                                                      .pessoaTerceiro
                                                      ?.nomeRazaoSocial
                                                  }
                                                </span>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </tbody>

                                  <UltimaTr
                                    style={{
                                      borderTop: '2px solid lightgray',
                                    }}
                                  >
                                    <th />
                                    <th />
                                    <th />
                                    {handleObterControlarLote(
                                      index,
                                      indexItemDesmembrado
                                    ) && <th />}
                                    <th>
                                      <div
                                        style={{
                                          fontWeight: 'bold',
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <div style={{ fontSize: '13px' }}>
                                          Qtde. Total
                                        </div>
                                        <div
                                          style={{
                                            alignSelf: 'flex-end',
                                            fontSize: '13px',
                                          }}
                                        >
                                          {Number(
                                            itemDesmembrado.estoque
                                              .quantidadeTotalEstoqueLocalEstoque
                                          )
                                            .Arredondar(4)
                                            .FormatarParaPtBr({
                                              minimoCasasDecimais: 4,
                                              maximoCasasDecimais: 4,
                                            })}
                                        </div>
                                      </div>
                                    </th>
                                    <th />
                                  </UltimaTr>
                                </Table>
                              </Tabela>
                            </Divisor>
                          </Divisor>
                        </td>
                      </tr>
                    )
                  )}

                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        padding: 0,
                        paddingBottom: 20,
                        backgroundColor: '#3397C4',
                      }}
                    />
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>
    </div>
  );
};

export default forwardRef(ImportacaoNfeTerceiroEtapa3);
