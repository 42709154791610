"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var FinalidadeEmissaoEnum;
(function (FinalidadeEmissaoEnum) {
    FinalidadeEmissaoEnum["normal"] = "1 - Normal";
    FinalidadeEmissaoEnum["complemento"] = "2 - Complemento";
    FinalidadeEmissaoEnum["ajuste"] = "3 - Ajuste";
    FinalidadeEmissaoEnum["devolucao"] = "4 - Devolu\u00E7\u00E3o";
})(FinalidadeEmissaoEnum || (FinalidadeEmissaoEnum = {}));
exports.default = FinalidadeEmissaoEnum;
