import styled, { css } from 'styled-components';

interface IItem {
  $selecionado: boolean;
  $navegacao: boolean;
}

export const ContainerListaItem = styled.ul`
  position: absolute;
  background: #fff;
  color: #000;
  width: 100%;
  max-height: 150px;

  overflow-y: auto;

  margin-top: 10px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  border-radius: 5px;

  z-index: 10;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-track {
    background-color: #36404a;
    border-radius: 0 5px 5px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: gray;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
  > li {
    width: 100%;
    border-bottom: 1px solid lightgray;

    button {
      display: flex;
      width: 100%;
      border: none;
      background: transparent;
    }
  }
`;

export const Item = styled.span<IItem>`
  font-size: 17px;
  width: 100%;
  text-align: left;
  padding: 5px;
  &:hover {
    background: lightblue;
  }

  ${({ $navegacao }) =>
    $navegacao &&
    css`
      background: lightgray;
    `}

  ${({ $selecionado }) =>
    $selecionado &&
    css`
      background: #b2f3d3;
    `}
`;
