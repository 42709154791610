import { createContext, useContext } from 'react';

interface IRedirecionar {
  redirecionar: boolean;
}

const detalheContext = createContext<IRedirecionar>({} as IRedirecionar);
const RedirecionarProvider = detalheContext.Provider;

function UseRedirecionar(): IRedirecionar {
  const context = useContext(detalheContext);

  return context;
}

export { RedirecionarProvider, UseRedirecionar };
