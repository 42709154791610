import { IPadronizacaoDescricaoAtributoLista } from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { produce } from 'immer';
import PadronizacaoDescricaoAtributoOrdemComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/PadronizacaoDescricao/Atributo/Comunicador/PadronizacaoDescricaoAtributoOrdemComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IHandleAtualizarOrdem {
  lista?: IPadronizacaoDescricaoAtributoLista[];
  id: string;
}

interface IItemIpiTabContext {
  validador: boolean;

  listaValor: IPadronizacaoDescricaoAtributoLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IPadronizacaoDescricaoAtributoLista[]>
  >;

  handleMover(de: number, para: number): void;
  handleAtualizarOrdem({ lista, id }: IHandleAtualizarOrdem): Promise<any>;
}

const ItemIpiTabContext = createContext<IItemIpiTabContext>(
  {} as IItemIpiTabContext
);

const ListaAtributoDraggableHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaValor, setListaValor] = useState<
    IPadronizacaoDescricaoAtributoLista[]
  >([]);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(
    async ({ lista = listaValor, id }: IHandleAtualizarOrdem) => {
      if (!id) return;

      const listaAtributo = lista.map((valor) => {
        return {
          id: valor.id,
          ordem: valor.ordem,
        };
      });

      await PadronizacaoDescricaoAtributoOrdemComunicador.update({
        id,
        params: { listaAtributo },
      });
    },
    [listaValor]
  );

  return (
    <ItemIpiTabContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        validador: true,
      }}
    >
      {children}
    </ItemIpiTabContext.Provider>
  );
};

function UseListaAtributoDraggable(): Omit<IItemIpiTabContext, 'validador'> {
  const context = useContext(ItemIpiTabContext);

  if (!context.validador) {
    throw new Error(
      'UseItemIpiTab deve ser usado com um ListaAtributoDraggableHook'
    );
  }

  return context;
}

export { ListaAtributoDraggableHook, UseListaAtributoDraggable };
