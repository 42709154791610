import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ICheckSwitch {
  $largura: number;
  $inativo: string;
  $ativo: string;
}

export const CheckSwitch = styled.label<ICheckSwitch>`
  ${(props) => css`
    width: ${props.$largura}px;
  `}
  height: 25px;
  border: 1px solid black;
  border-radius: 50px;
  transition: all 0.5s;
  position: relative;
  margin: 0;
  div {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff0000;
    border-radius: 50px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
  }

  div:before {
    position: absolute;
    content: '';
    height: 23px;
    width: 23px;
    background-color: white;
    border: 1px solid black;
    border-radius: 50px;
    transition: all 0.5s;
  }

  input[type='checkbox'] {
    padding: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + div {
    background-color: lightgreen;
    color: black;
  }

  input:checked + div:before {
    ${(props) => css`
      transform: translateX(${props.$largura - 25}px);
    `}
  }

  div:after {
    ${(props) =>
      props.$inativo &&
      css`
        content: '${props.$inativo}';
      `};

    margin-left: auto;
    margin-right: 10px;
  }

  input:checked + div:after {
    ${(props) =>
      props.$ativo &&
      css`
        content: '${props.$ativo}';
      `};

    margin-left: 10px;
    margin-right: 0;
  }
`;
