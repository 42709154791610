/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { IoTriangle } from 'react-icons/io5/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, SpanErro } from '../Styles';
import { InputInteiroIncrementalContainer } from './styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputInteiroIncrementalProps {
  valorAnterior: string;
}

export interface IOnChangeInputInteiroIncrementalEvent {
  target: { value: string };
}

export interface IInputInteiroIncrementalRef {
  focus(): void;
}

interface IInputInteiroIncrementalProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  castParaNumberOnGetValue?: boolean;
  minimo?: number;
  maximo?: number;

  onChange?: (
    event: IOnChangeInputInteiroIncrementalEvent,
    props: IOnChangeInputInteiroIncrementalProps
  ) => void | Promise<void>;
}

const InputInteiroIncremental: React.ForwardRefRenderFunction<
  IInputInteiroIncrementalRef,
  IInputInteiroIncrementalProps
> = (
  {
    name,
    label,
    castParaNumberOnGetValue,
    min = 1,
    max,

    onFocus,
    onChange,
    onBlur,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const incrementarBtnRef = useRef<HTMLDivElement>(null);
  const decrementarBtnRef = useRef<HTMLDivElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';
      if (onFocus) onFocus(event);
      const tamanho = event.target.value.length;

      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setErro('');
      if (onChange)
        onChange(
          { target: { value } },
          { valorAnterior: valorAnterior.current }
        );
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      setIsFocused(false);

      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  const handleIncrementar = (): void => {
    if (!inputRef.current) return;
    const { value } = inputRef.current;

    const valorIncrementado = String(Number(value || 0) + 1);

    if (!!max && valorIncrementado > max) return;

    inputRef.current.value = valorIncrementado;
    handleInputChange(String(valorIncrementado));
  };

  const handleDecrementar = (): void => {
    if (!inputRef.current) return;
    const { value } = inputRef.current;

    const valorDecrementado = String(Number(value || 0) - 1);

    if (valorDecrementado < min) return;

    inputRef.current.value = valorDecrementado;
    handleInputChange(String(valorDecrementado));
  };

  const handleClickButtons = (operacao: 1 | -1): void => {
    setIsFocused(true);

    if (operacao === 1) {
      handleIncrementar();
    } else {
      handleDecrementar();
    }
  };

  useEffect(() => {
    registerField<number | null | string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        if (inputRef.current?.value || inputRef.current?.value === '0') {
          return castParaNumberOnGetValue
            ? Number(inputRef.current.value)
            : inputRef.current.value;
        }

        return null;
      },

      setValue(_, value = null) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (!value) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = String(value);
        }
      },

      setSemExecutarEvento(_, value = null) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;
        if (!value) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = String(value);
        }
      },

      clearValue(_, valorInicial = null) {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // removendo o focus ao clicar fora do input
    const handleClick = (event: MouseEvent): void => {
      if (
        event.target !== inputRef.current &&
        event.target !== incrementarBtnRef.current &&
        event.target !== decrementarBtnRef.current
      ) {
        const eventTarget = String(event.target);

        if (
          eventTarget === '[object SVGPathElement]' ||
          eventTarget === '[object SVGSVGElement]'
        ) {
          return;
        }

        setIsFocused(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <InputInteiroIncrementalContainer disabled={rest.disabled}>
      {label && <label>{label}</label>}

      <div
        className="incr-box"
        ref={incrementarBtnRef}
        onClick={() => {
          handleClickButtons(1);
        }}
      >
        <IoTriangle id="incr" />
      </div>

      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        onKeyDown={(event) => {
          event.preventDefault();

          if (!inputRef.current) return;

          const { key } = event;

          if (key === 'ArrowUp') handleIncrementar();
          if (key === 'ArrowDown') handleDecrementar();
        }}
        type="text"
        onFocus={handleInputFocus}
        onChange={(event) => {
          handleInputChange(event.target.value);
        }}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />

      <div
        className="decr-box"
        ref={decrementarBtnRef}
        onClick={() => {
          handleClickButtons(-1);
        }}
      >
        <IoTriangle id="decr" />
      </div>

      {erro && <SpanErro>{erro}</SpanErro>}
    </InputInteiroIncrementalContainer>
  );
};

export default forwardRef(InputInteiroIncremental);
