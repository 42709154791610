import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-cst/ordem';

interface IRegraEscolhaCstOrdemUpdate {
  params: any;
}

class RegraEscolhaCstOrdem {
  public async update(params: IRegraEscolhaCstOrdemUpdate): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaCstOrdem();
