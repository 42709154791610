import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import EstoqueImportacaoDeParaManualItem from './EstoqueImportacaoDeParaManualItem';

type Props = {
  listaOpcoesCSV: any[];
  onConfirmarImportacao?: (listaDePara: any[]) => void;
};

const EstoqueImportacaoDeParaManual: React.FC<Props> = ({
  listaOpcoesCSV,
  onConfirmarImportacao,
}) => {
  // #region Opções internas

  const opcoesInternas = [
    {
      id: 0,
      nome: 'produto.codigo',
      tooltip: 'Código único que identifica o produto',
    },
    {
      id: 1,
      nome: 'produto.descricao',
      tooltip: 'Descrição detalhada do produto',
    },
    {
      id: 2,
      nome: 'produto.ativo',
      tooltip: 'Indica se o produto está ativo ou inativo',
    },
    { id: 3, nome: 'saldo', tooltip: 'Saldo de estoque do produto' },
    {
      id: 4,
      nome: 'estoqueIndicadorTipo',
      tooltip: 'Indicador de Estoque',
    },
    {
      id: 5,
      nome: 'localEstoque.descricao',
      tooltip: 'Descrição do local de estoque',
    },
    {
      id: 6,
      nome: 'lote.codigo',
      tooltip: 'Código do lote',
    },
    {
      id: 7,
      nome: 'pessoa.codigo',
      tooltip: 'Código da pessoa',
    },
    {
      id: 8,
      nome: 'pessoa.nomeRazaoSocial',
      tooltip: 'Nome ou Razão Social da pessoa',
    },
    {
      id: 9,
      nome: 'pessoa.cnpj',
      tooltip: 'CNPJ da pessoa',
    },
    {
      id: 10,
      nome: 'pessoa.cpf',
      tooltip: 'CPF da pessoa',
    },
    {
      id: 11,
      nome: 'valorUnitarioCustoContabilIr',
      tooltip: 'Valor Unitário Custo Contabil IR',
    },
    {
      id: 12,
      nome: 'valorUnitarioCustoContabilIcmsIpi',
      tooltip: 'Valor Unitário Custo Contabil ICMS IPI',
    },
    {
      id: 13,
      nome: 'valorUnitarioCustoUltimaCompra',
      tooltip: 'Valor Unitário Custo Última Compra',
    },
    {
      id: 14,
      nome: 'valorUnitarioCustoMedio',
      tooltip: 'Valor Unitário Custo Médio',
    },
  ];

  // #endregion Opções internas

  // #region Estados

  const [itensSelecionados, setItensSelecionados] = useState<any[]>([]);
  const [opcoesCSVFiltradas, setOpcoesCSVFiltradas] = useState<any[]>([]);

  // #endregion Estados

  // #region Funções

  const handleItemSelecionado = (item: any): any => {
    setItensSelecionados((prevItems) => [...prevItems, item]);
  };

  const handleItemRemoval = (item: any): void => {
    const updatedItensSelecionados = itensSelecionados.filter(
      (selectedItem) => {
        return selectedItem.value !== item;
      }
    );
    setItensSelecionados(updatedItensSelecionados);
  };

  useEffect(() => {
    const resposta = listaOpcoesCSV.filter(
      (item) =>
        !itensSelecionados.some((selecionado) => selecionado.value === item)
    );

    setOpcoesCSVFiltradas(resposta);
    onConfirmarImportacao && onConfirmarImportacao(itensSelecionados);
  }, [itensSelecionados, listaOpcoesCSV, onConfirmarImportacao]);

  // #endregion Funções

  return (
    <>
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos aceitos pelo Elo Gestor</b>
          </div>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos do seu arquivo CSV</b>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          {opcoesInternas.map((itemInterno, i) => (
            <EstoqueImportacaoDeParaManualItem
              key={i}
              listaOpcoesCsv={opcoesCSVFiltradas}
              listaOpcoesCsvIniciais={listaOpcoesCSV}
              tooltip={itemInterno.tooltip}
              opcaoInterna={itemInterno}
              onItemSelecionado={handleItemSelecionado}
              onItemRemoval={handleItemRemoval}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default EstoqueImportacaoDeParaManual;
