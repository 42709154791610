"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarPeriodoEntreDuasDatasFormatadas;
function FormatarPeriodoEntreDuasDatasFormatadas(dataInicio, dataFim) {
    if (!dataInicio && !dataFim) {
        return '';
    }
    if ((!dataInicio && dataFim) || (dataInicio && !dataFim)) {
        return dataInicio || dataFim;
    }
    return `${dataInicio} até ${dataFim}`;
}
