/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { ValidarCnpj, FormatarCnpj } from '@elogestor/util';
import { FaSearch } from 'react-icons/fa/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, SpanErro } from '../Styles';
import { ContainerCpf } from './styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

interface IOnChangeInputBuscaCnpjProps {
  valorAnterior: string;
}

export interface IOnChangeInputBuscaCnpjValidoEvent {
  valor: string;
  isZero: boolean;
}

export interface IOnBlurInputBuscaCnpjValidoEvent {
  valor: string;
  isZero: boolean;
  mudou: boolean;
}

export interface IInputBuscaCnpjRef {
  focus(): void;
}

interface IInputBuscaCnpjProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  loading?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputBuscaCnpjProps
  ) => void | Promise<void>;
  onChangeValido?: (event: IOnChangeInputBuscaCnpjValidoEvent) => Promise<void>;
  onBlurValido?: (event: IOnBlurInputBuscaCnpjValidoEvent) => Promise<void>;
  onPesquisarCnpj?: () => Promise<void>;
}

const InputBuscaCnpj: React.ForwardRefRenderFunction<
  IInputBuscaCnpjRef,
  IInputBuscaCnpjProps
> = (
  {
    name,
    label,
    loading = false,

    onFocus,
    onChange,
    onChangeValido,
    onBlur,
    onBlurValido,
    onPesquisarCnpj,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [mudou, setMudou] = useState(false);
  const [erro, setErro] = useState(erroUnform);
  const [desabilitado, setDesabilitado] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
    if (inputRef.current?.value) {
      setDesabilitado(loading);
    }
  }, [erroUnform, loading]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let valor = event.target.value;

      if (valor.replace(/\D/g, '').length > 14) {
        valor = valor.slice(0, -1);
      }

      if (ValidarCnpj(valor)) {
        setDesabilitado(false);
        setMudou(true);

        if (onChangeValido) {
          let isZero = false;
          if (valor === '00000000000000') {
            isZero = true;
          }

          if (inputRef.current && inputRef.current.value.length === 14) {
            setDesabilitado(false);
          } else {
            setDesabilitado(true);
          }

          onChangeValido({ isZero, valor });
        }
      } else {
        setDesabilitado(true);
      }

      valor = FormatarCnpj(valor);
      event.target.value = valor;

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange, onChangeValido]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      setIsFocused(false);

      const valor = inputRef.current?.value.replace(/\D/g, '');

      if (valor && !ValidarCnpj(valor)) {
        setErro('CNPJ inválido!');
      } else {
        setIsFilled(!!valor);
        if (onBlur) onBlur(event);

        if (onBlurValido) {
          let isZero = false;
          if (valor === '00000000000000') {
            isZero = true;
          }

          onBlurValido({ isZero, valor: valor || '', mudou });
        }
      }

      setMudou(false);
    },
    [mudou, onBlur, onBlurValido]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        let valor = inputRef.current?.value || '';
        valor = valor.replace(/\D/g, '');

        return valor;
      },

      setValue(_, value = '') {
        value = FormatarCnpj(value);
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (ValidarCnpj(value)) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      setSemExecutarEvento(_, value = '') {
        value = FormatarCnpj(value);
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (ValidarCnpj(value)) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerCpf>
      {label && <label>{label}</label>}
      <div>
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="text"
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          {...rest}
          ref={inputRef}
          maxLength={18}
        />
        <button
          type="button"
          className="btn-editar"
          onClick={() => {
            if (onPesquisarCnpj) {
              onPesquisarCnpj();
            }
          }}
          disabled={desabilitado}
        >
          <FaSearch />
        </button>
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </ContainerCpf>
  );
};

export default forwardRef(InputBuscaCnpj);
