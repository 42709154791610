import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/conhecimentos-emitidos/upload-xml';

interface IImportacaoXmlConhecimentosEmitidosUploadXmlComunicadorUpdate {
  params: any;
}

class ImportacaoXmlConhecimentosEmitidosUploadXmlComunicador {
  public async update(
    params: IImportacaoXmlConhecimentosEmitidosUploadXmlComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlConhecimentosEmitidosUploadXmlComunicador();
