import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/impressao-etiquetas-produtos/obter-informacoes-produto';

interface IImpressaoEtiquetasProdutosObterInformacoesProdutoComunicadorShow {
  params: any;
}

class ImpressaoEtiquetasProdutosObterInformacoesProdutoComunicador {
  public async index(
    params: IImpressaoEtiquetasProdutosObterInformacoesProdutoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImpressaoEtiquetasProdutosObterInformacoesProdutoComunicador();
