"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObterInformacoesBoletos = ObterInformacoesBoletos;
/* eslint-disable default-case */
const BancoBrasil_1 = require("./BancoBrasil");
const Itau_1 = require("./Itau");
function ObterInformacoesBoletos({ codigoBanco, numeroConvenio, nossoNumero, carteira, dataVencimento, valorBoleto, agencia, conta, moeda, }) {
    const retorno = {};
    switch (codigoBanco) {
        case '001': // Banco do Brasil
            retorno.nossoNumero = (0, BancoBrasil_1.CalcularNossoNumeroBancoBrasil)({
                numeroConvenio,
                nossoNumero,
            });
            retorno.codigoBarras = (0, BancoBrasil_1.ObterCodigoBarrasBancoBrasil)({
                carteira,
                numeroConvenio,
                nossoNumero: retorno.nossoNumero,
                dataVencimento,
                valorBoleto,
                agencia,
                conta,
            });
            retorno.linhaDigitavel = (0, BancoBrasil_1.ObterLinhaDigitavelFormatadaBancoBrasil)({
                dataVencimento,
                valorBoleto,
                codigoBarras: retorno.codigoBarras,
                moeda,
            });
            break;
        case '341': // Itau
            retorno.nossoNumero = (0, Itau_1.CalcularNossoNumeroItau)({
                nossoNumero,
            });
            break;
        case '748': // Sicredi
            break;
        case '756': // Sicoob
            break;
    }
    return retorno;
}
