import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import InputCep from '../../../../../../../../Componentes/Inputs/InputCep';
import PesquisaEnderecoCepDetalhe from '../../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputVisualSemValor from '../../../../../../../../Componentes/Inputs/InputVisualSemValor';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import IEndereco from '../../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import InputSwitch from '../../../../../../../../Componentes/Inputs/InputSwitch';
import { PesquisarCep, SelecionarEndereco } from './PesquisaCep';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';

interface IPessoaEnderecoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const PessoaEnderecoDetalhe: React.FC<IPessoaEnderecoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const janelaCepRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  // #region Pesquisa CEP e Endereco

  const handlePesquisarCep = useCallback(async (): Promise<void> => {
    await PesquisarCep(formRefDetalhe, janelaCepRef);
  }, [formRefDetalhe]);

  const handleOnSelecionarEndereco = useCallback(
    async (endereco: IEndereco) => {
      await SelecionarEndereco(formRefDetalhe, endereco);
    },
    [formRefDetalhe]
  );

  const handleChangeCidade = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRefDetalhe.current?.setFieldValue(
        'cidade.estado.sigla',
        event.itemAtual?.estado?.sigla || ''
      );
    },
    [formRefDetalhe]
  );

  // #endregion Pesquisa CEP e Endereco

  return (
    <JanelaDetalhe
      titulo="Endereco"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={3} md={12}>
                  <InputCep
                    ref={inputRefFocus}
                    name="cep"
                    label="CEP"
                    placeholder="CEP"
                    loading={loading}
                    onPesquisarCep={handlePesquisarCep}
                  />
                  <PesquisaEnderecoCepDetalhe
                    handleOnSelecionarEndereco={handleOnSelecionarEndereco}
                    ref={janelaCepRef}
                  />
                </Col>
                <Col lg={7} md={12}>
                  <InputAutoCompleteCidadeUf
                    label="Cidade"
                    placeholder="Cidade"
                    name="idCidade"
                    nomeObjeto="cidade"
                    onChangeItemAtual={handleChangeCidade}
                  />
                </Col>

                <Col lg={2} md={12}>
                  <InputVisualSemValor
                    name="cidade.estado.sigla"
                    label="UF"
                    disabled
                    type="text"
                    placeholder="UF"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12}>
                  <Input
                    label="Logradouro"
                    name="logradouro"
                    placeholder="Logradouro"
                    maxLength={60}
                  />
                </Col>
                <Col lg={2} md={12}>
                  <Input
                    label="Número"
                    name="numero"
                    placeholder="Número"
                    maxLength={60}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Input
                    label="Bairro"
                    name="bairro"
                    type="text"
                    placeholder="Bairro"
                    maxLength={60}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12}>
                  <Input
                    label="Complemento"
                    name="complemento"
                    type="text"
                    placeholder="Complemento"
                    maxLength={60}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Input
                    label="Site"
                    name="site"
                    type="text"
                    placeholder="Site"
                    maxLength={60}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={2} md={12}>
                  <InputSwitch
                    name="enderecoPrincipal"
                    label="Endereço Principal"
                    ativo="Sim"
                    inativo="Não"
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PessoaEnderecoDetalhe;
