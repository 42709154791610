import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/produto-obter-unidade-medida';

interface IProdutoObterUnidadeMedidaComunicadorShow {
  id: string;
}

class ProdutoObterUnidadeMedidaComunicador {
  public async show(
    params: IProdutoObterUnidadeMedidaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ProdutoObterUnidadeMedidaComunicador();
