import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { UseFieldCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import { TBody } from '../../../../../../Componentes/Styles/TBody';
import IBlocoSped from './Interfaces/IBlocoSped';
import ISpedBlocoRegistro from './Interfaces/ISpedBlocoRegistro';
import ListaSpedBlocoRegistro, {
  IListaSpedBlocoRegistroRef,
} from './ListaSpedBlocoRegistro';
import { Tabela } from '../../../../../../Componentes/Tabela/styles';

const ConfiguracoesTab: React.FC = () => {
  const [listaSpedBloco, setListaSpedBloco] = useState<IBlocoSped[]>([]);
  const listaBlocoRef = useRef<Array<IListaSpedBlocoRegistroRef>>([]);
  const { fieldName, registerField } = UseFieldCia('listaSpedBloco');

  const handleChange = useCallback(
    (listaValor: IBlocoSped[]) => {
      if (listaValor) {
        const valorFormatado = listaValor.map((valor) => {
          if (valor.bloco === 'Bloco 0') {
            valor.gerar = true;
            valor.desabilitado = true;

            valor.listaSpedBlocoRegistro.forEach((spedBlocoRegistro) => {
              if (
                ['0000', '0001', '0990'].includes(spedBlocoRegistro.registro)
              ) {
                spedBlocoRegistro.gerar = true;
                spedBlocoRegistro.desabilitado = true;
              }
            });
          } else if (valor.bloco === 'Bloco 9') {
            valor.gerar = true;
            valor.desabilitado = true;

            valor.listaSpedBlocoRegistro.forEach((spedBlocoRegistro) => {
              spedBlocoRegistro.gerar = true;
              spedBlocoRegistro.desabilitado = true;
            });
          }

          return valor;
        });

        setListaSpedBloco(valorFormatado);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const obterListaValorBloco = useCallback((): IBlocoSped[] => {
    const listaBloco = listaBlocoRef.current.map((blocoRef) => {
      return blocoRef.obterValorPai();
    });

    return listaBloco;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtualizarValorInicial = useCallback(() => {
    listaBlocoRef.current.forEach((blocoRef) => {
      blocoRef.setFilhosMudou(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterDuplicados = useCallback(() => {
    const listaMarcados = listaBlocoRef.current
      .map((blocoRef) => {
        return blocoRef.obterMarcado();
      })
      .filter((filho) => filho);

    return listaMarcados as IBlocoSped[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registerField<any>({
      name: fieldName,

      getValue() {
        const listaValorBloco = obterListaValorBloco();

        listaValorBloco.forEach((valorBloco) => {
          valorBloco.listaSpedBlocoRegistro =
            valorBloco.listaSpedBlocoRegistro.filter(
              (blocoRegistro) => blocoRegistro.mudou
            );
        });

        const listaValorFiltrado = listaValorBloco.filter(
          (valorBloco) => valorBloco.listaSpedBlocoRegistro.length > 0
        );

        return listaValorFiltrado;
      },

      getDataDuplicar() {
        const listaMarcados = handleObterDuplicados().reduce(
          (acumulador, duplicado) => {
            return acumulador.concat(duplicado.listaSpedBlocoRegistro);
          },
          [] as ISpedBlocoRegistro[]
        );

        const listaBloco = obterListaValorBloco().map((bloco) => {
          bloco.listaSpedBlocoRegistro.forEach((registro) => {
            const achou = !!listaMarcados.find((marcado) => {
              return marcado.id === registro.id;
            });
            registro.mudou = achou;
          });

          return bloco;
        });

        return listaBloco;
      },

      getDataRecuperarFormulario() {
        return obterListaValorBloco();
      },

      setValue(_, valor) {
        handleChange(valor);
      },

      setSemExecutarEvento(_, valor) {
        handleChange(valor);
      },

      validarSeAlterou(_) {
        const valor: IBlocoSped[] = this.getValue(_) || [];
        return valor.length > 0;
      },

      clearValue(_, valorInicial = []) {
        this.setValue(_, valorInicial);
      },

      onAtualizarValorInicial() {
        handleAtualizarValorInicial();
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Divisor>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th>
                  <span>Gerar</span>
                </th>
                <th>
                  <span>Descrição</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaSpedBloco.map((bloco, index) => {
                return (
                  <ListaSpedBlocoRegistro
                    key={bloco.id}
                    bloco={bloco}
                    ref={(instance) => {
                      if (instance) {
                        listaBlocoRef.current[index] = instance;
                      }
                    }}
                  />
                );
              })}
            </TBody>
          </Table>
        </Tabela>
      </Divisor>
    </div>
  );
};

export default ConfiguracoesTab;
