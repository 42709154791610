import api from '../../../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/transmissao/evento/upload-pdf';

interface IConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate {
  params: any;
}

class ConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicador {
  public async update(
    params: IConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicador();
