import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/logs-integracao-pedidos/sincronizacao';

interface IPedidoVendaImportacaoLogComunicadorIndex {
  params: {
    data?: string;
    idPedidoEspecifico?: string;
    tipoPesquisaIntegracao?: string;
  };
}

class PedidoVendaImportacaoLogComunicador {
  public async store(
    params: IPedidoVendaImportacaoLogComunicadorIndex
  ): Promise<any> {
    const response = await api.put(rota, { params: params.params });

    return response.data;
  }
}

export default new PedidoVendaImportacaoLogComunicador();
