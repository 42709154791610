/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import TagInputBase, {
  IOnChangeListaItemAtualTagEvent,
  IOnAdicionarTagEvent,
  IOnChangeTextoTagEvent,
  IOnMoverTagEvent,
  IOnRemoverTagEvent,
} from '../TagInputBase';
import UseRegisterTagsInput from '../TagInputBase/Hooks/UseRegisterTagsInput';

interface IOnChange {
  valorAnterior: any;
}

interface IInputTagProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  nomeCampo?: string;
  label?: string;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualTagEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarTagEvent) => void;
  onRemoverItem?: (evento: IOnRemoverTagEvent) => void;
  onMoverItem?: (evento: IOnMoverTagEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoTagEvent) => void;
}

interface IInputTag {
  id?: string;
}

const InputTag: React.FC<IInputTagProps> = ({
  name,
  nomeCampo = 'textoTag',
  label,
  onChangeListaItemAtual,
  onAdicionarItem,
  onRemoverItem,
  onMoverItem,
  onChangeTexto,
}) => {
  const handleObterChaveUnica = useCallback(
    (item: any) => {
      return item[`${nomeCampo}`];
    },
    [nomeCampo]
  );

  const { tagInputRef, error: erroUnform } = UseRegisterTagsInput({
    nome: name,
    handleObterChaveUnica,
  });

  const [error, setError] = useState(erroUnform);

  useEffect(() => {
    setError(erroUnform);
  }, [erroUnform]);

  const handleObterLabel = useCallback(
    (item: any) => {
      return item[`${nomeCampo}`];
    },
    [nomeCampo]
  );

  const handleFormatarValidarString = useCallback(
    (valor: any): IInputTag => {
      if (valor.length > 150) {
        throw new Error('Tag deve ter no máximo 150 caracteres');
      }
      return {
        [nomeCampo]: valor,
      };
    },
    [nomeCampo]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div style={{ display: 'flex' }}>
          <TagInputBase
            ref={tagInputRef}
            error={error}
            obterChaveUnica={handleObterChaveUnica}
            obterLabel={handleObterLabel}
            formatarValidarString={handleFormatarValidarString}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeListaItemAtual={onChangeListaItemAtual}
            onChangeTexto={onChangeTexto}
          />
        </div>
      </div>
    </div>
  );
};

export default InputTag;
