import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const RelatorioNotaFiscalEntradaTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox name="relNotaFiscalEntradaResumo" label="Resumo" />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalEntradaResumoPorCfop"
                label="Resumo Por CFOP"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalEntradaPorFornecedor"
                label="Por Fornecedor"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalEntradaPorCfopCsosnCst"
                label="Por CFOP e CSOSN/CST"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalEntradaPorProduto"
                label="Por Produto"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalEntradaTotalModeloDocumento"
                label="Totais por Modelo Documento"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RelatorioNotaFiscalEntradaTab;
