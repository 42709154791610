"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsOperacaoInterestadualObterVisibilidadeBloqueioCampos;
const Enum_1 = require("../../Enum");
function IcmsOperacaoInterestadualObterVisibilidadeBloqueioCampos(dados) {
    const retorno = {
        escondido: {
            calcular: true,
            aliquotaEstadoDestino: true,
            aliquotaInterestadual: true,
            aliquotaOrigemDestino: true,
            estadoOrigemBaseCalculo: true,
            estadoOrigemPercentualPartilha: true,
            estadoOrigemValorIcms: true,
            estadoDestinoBaseCalculo: true,
            estadoDestinoPercentualPartilha: true,
            estadoDestinoValorIcmsSemFcp: true,
            estadoDestinoPercentualFcp: true,
            estadoDestinoValorFcp: true,
            estadoDestinoValorIcmsComFcp: true,
            abaIcmsOperacaoInterestadual: true,
        },
        bloqueado: {
            calcular: false,
            aliquotaEstadoDestino: true,
            aliquotaInterestadual: true,
            aliquotaOrigemDestino: true,
            estadoOrigemBaseCalculo: true,
            estadoOrigemPercentualPartilha: true,
            estadoOrigemValorIcms: true,
            estadoDestinoBaseCalculo: true,
            estadoDestinoPercentualPartilha: true,
            estadoDestinoValorIcmsSemFcp: true,
            estadoDestinoPercentualFcp: true,
            estadoDestinoValorFcp: true,
            estadoDestinoValorIcmsComFcp: true,
            abaIcmsOperacaoInterestadual: true,
        },
    };
    if ((dados.destinado === Enum_1.DestinadoEnum.ativoImobilizado ||
        dados.destinado === Enum_1.DestinadoEnum.usoeConsumo) &&
        dados.tipoMercado === Enum_1.TipoMercadoEnum.foraEstado &&
        !dados.contribuinteIcms) {
        retorno.escondido.calcular = false;
        retorno.escondido.aliquotaEstadoDestino = false;
        retorno.escondido.aliquotaInterestadual = false;
        retorno.escondido.aliquotaOrigemDestino = false;
        retorno.escondido.estadoOrigemBaseCalculo = false;
        retorno.escondido.estadoOrigemPercentualPartilha = false;
        retorno.escondido.estadoOrigemValorIcms = false;
        retorno.escondido.estadoDestinoBaseCalculo = false;
        retorno.escondido.estadoDestinoPercentualPartilha = false;
        retorno.escondido.estadoDestinoValorIcmsSemFcp = false;
        retorno.escondido.estadoDestinoPercentualFcp = false;
        retorno.escondido.estadoDestinoValorFcp = false;
        retorno.escondido.estadoDestinoValorIcmsComFcp = false;
        retorno.escondido.abaIcmsOperacaoInterestadual = false;
    }
    if (dados.utiliza) {
        retorno.escondido.calcular = false;
        retorno.escondido.aliquotaEstadoDestino = false;
        retorno.escondido.aliquotaInterestadual = false;
        retorno.escondido.aliquotaOrigemDestino = false;
        retorno.escondido.estadoOrigemBaseCalculo = false;
        retorno.escondido.estadoOrigemPercentualPartilha = false;
        retorno.escondido.estadoOrigemValorIcms = false;
        retorno.escondido.estadoDestinoBaseCalculo = false;
        retorno.escondido.estadoDestinoPercentualPartilha = false;
        retorno.escondido.estadoDestinoValorIcmsSemFcp = false;
        retorno.escondido.estadoDestinoPercentualFcp = false;
        retorno.escondido.estadoDestinoValorFcp = false;
        retorno.escondido.estadoDestinoValorIcmsComFcp = false;
    }
    else {
        retorno.escondido.calcular = true;
        retorno.escondido.aliquotaEstadoDestino = true;
        retorno.escondido.aliquotaInterestadual = true;
        retorno.escondido.aliquotaOrigemDestino = true;
        retorno.escondido.estadoOrigemBaseCalculo = true;
        retorno.escondido.estadoOrigemPercentualPartilha = true;
        retorno.escondido.estadoOrigemValorIcms = true;
        retorno.escondido.estadoDestinoBaseCalculo = true;
        retorno.escondido.estadoDestinoPercentualPartilha = true;
        retorno.escondido.estadoDestinoValorIcmsSemFcp = true;
        retorno.escondido.estadoDestinoPercentualFcp = true;
        retorno.escondido.estadoDestinoValorFcp = true;
        retorno.escondido.estadoDestinoValorIcmsComFcp = true;
    }
    if (!dados.calcular) {
        retorno.bloqueado.aliquotaEstadoDestino = false;
        retorno.bloqueado.aliquotaInterestadual = false;
        retorno.bloqueado.aliquotaOrigemDestino = false;
        retorno.bloqueado.estadoOrigemBaseCalculo = false;
        retorno.bloqueado.estadoOrigemPercentualPartilha = false;
        retorno.bloqueado.estadoOrigemValorIcms = false;
        retorno.bloqueado.estadoDestinoBaseCalculo = false;
        retorno.bloqueado.estadoDestinoPercentualPartilha = false;
        retorno.bloqueado.estadoDestinoValorIcmsSemFcp = false;
        retorno.bloqueado.estadoDestinoPercentualFcp = false;
        retorno.bloqueado.estadoDestinoValorFcp = false;
        retorno.bloqueado.estadoDestinoValorIcmsComFcp = false;
    }
    return retorno;
}
