import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPedidoVendaResumoPorMesAnoEmissaoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPedidoVendaResumoPorMesAnoEmissaoPDF {
  dados: IPedidoVendaResumoPorMesAnoEmissaoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PedidoVendaResumoPorMesAnoEmissaoPDF: React.FC<
  IPedidoVendaResumoPorMesAnoEmissaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroOperacaoFiscal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Operacao Fiscal: '}
                      </Text>
                      {dados.cabecalho.filtroOperacaoFiscal}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%' }}>
              <Text>Mês/Ano</Text>
            </View>
            <View style={{ width: '3%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Subtotal</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Frete</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Seguro</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Out. Desp.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Desconto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Valor Ipi</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Valor ST.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Valor Total</Text>
            </View>
          </View>

          <View>
            {dados.listaDados.map((dado, indexDado) => {
              return (
                <View
                  key={String(indexDado)}
                  style={{
                    flexDirection: 'row',
                    backgroundColor: indexDado % 2 === 0 ? '#E4E4E4' : '#fff',
                  }}
                >
                  <View style={{ width: '10%' }}>
                    <Text>{dado.chave}</Text>
                  </View>
                  <View style={{ width: '3%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioDataEmissaoValorSubtotal}
                    </Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioDataEmissaoValorFrete}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioDataEmissaoValorSeguro}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioDataEmissaoValorOutrasDespesas}
                    </Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>
                      {dado.valores.somatorioDataEmissaoValorDesconto}
                    </Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioDataEmissaoValorIpi}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioDataEmissaoValorSt}</Text>
                  </View>
                  <View style={{ width: '1%' }} />

                  <View style={{ width: '10%', alignItems: 'flex-end' }}>
                    <Text>{dado.valores.somatorioDataEmissaoValorTotal}</Text>
                  </View>
                </View>
              );
            })}

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              <View style={{ width: '12%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                    marginLeft: 2,
                  }}
                >
                  Total Geral: {dados.listaDados.length}
                </Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorSubtotal}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorFrete}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorSeguro}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorOutrasDespesas}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorDesconto}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorIpi}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorSt}</Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '10%', alignItems: 'flex-end' }}>
                <Text>{dados.somatorioGeralValorTotal}</Text>
              </View>
            </View>
          </View>

          <View style={{ borderBottom: '1pt solid black' }} />
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaResumoPorMesAnoEmissaoPDF;
