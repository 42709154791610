import api from '../../../../Comum/Services/Api';

const rota = 'financeiro/boleto/obter-descricao-livre';

interface IBoletoObterDescricaoLivreComunicadorShow {
  id: string;
}

interface IBoletoObterDescricaoLivreComunicadorUpdate {
  id: string;
  params: any;
}

class BoletoObterDescricaoLivreComunicador {
  public async show(
    params: IBoletoObterDescricaoLivreComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async update(
    params: IBoletoObterDescricaoLivreComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new BoletoObterDescricaoLivreComunicador();
