import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ProdutoComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoComunicador';
import PadronizacaoDescricaoHook from '../PadronizacaoDescricaoAtributoDetalhe/Hooks';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const ProdutoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: ProdutoComunicador }}>
      <RotasHook>
        <FormHook>
          <PadronizacaoDescricaoHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </PadronizacaoDescricaoHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default ProdutoHook;
