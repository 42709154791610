import {
  FormatarDataParaPtBr,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
  IMovimentacoesFinanceirasSaldoPorPortadorCabecalhoRelatorio,
  IMovimentacoesFinanceirasSaldoPorPortadorRelatorio,
} from '@elogestor/util';
import React from 'react';
import SaldoPorPortadorRelatorioComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/MovimentacoesFinanceirasPortadores/SaldoPorPortadorRelatorioComunicador/SaldoPorPortadorRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import SaldoPorPortadorPDF from './SaldoPorPortadorPDF';
import SaldoPorPortadorPlanilha from './SaldoPorPortadorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const SaldoPorPortadorRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IMovimentacoesFinanceirasSaldoPorPortadorRelatorio | null> => {
    const filtroBack: IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend =
      {
        portadorAtivo: filtros.portadorAtivo,
        somentePortadorComSaldo: filtros?.somentePortadorComSaldo,
        dataSaldo: filtros?.dataSaldo,
      };

    if (filtros?.listaPortadores) {
      filtroBack.listaIdPortador =
        filtros.listaPortadores.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    const response = await SaldoPorPortadorRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });
    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno =
      response as IMovimentacoesFinanceirasSaldoPorPortadorRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados: any) => {
        const valores = {
          ...dados.valores,
        };

        const listaSaldoPorPortador = dados.listaSaldoPorPortador.map(
          (saldo: any) => {
            return {
              ...saldo,
            };
          }
        );

        return {
          ...dados,
          valores,
          listaSaldoPorPortador,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };
  function FormatarCabecalho(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    cabecalho: IMovimentacoesFinanceirasSaldoPorPortadorCabecalhoRelatorio
  ): IMovimentacoesFinanceirasSaldoPorPortadorCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (
      filtros.listaPortadores &&
      filtros.listaPortadores.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPortadores = filtros.listaPortadores.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (filtros.dataSaldo) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataSaldo = FormatarDataParaPtBr(filtros.dataSaldo);
    }

    if (filtros.portadorAtivo) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroPortadorAtivo = filtros.portadorAtivo;
    }

    if (filtros.somentePortadorComSaldo) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSomentePortadorComSaldo = filtros.somentePortadorComSaldo;
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: SaldoPorPortadorPlanilha,
      nomeArquivo: 'SaldoPorPortador',
      valores: dados.listaDados,
      unwind: ['listaSaldoPorPortador'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: SaldoPorPortadorPlanilha,
      nomeArquivo: 'SaldoPorPortador',
      valores: dados.listaDados as any,
      unwind: ['listaSaldoPorPortador'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <SaldoPorPortadorPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <SaldoPorPortadorPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default SaldoPorPortadorRelatorio();
