const ProdutoPorGrupoComReferenciasPlanilha = [
  { titulo: 'Código', valor: 'listaProduto.codigo' },
  {
    titulo: 'Referências',
    valor: 'listaProduto.produtoEngenharia.listaCodigoReferencia',
  },
  { titulo: 'Descrição', valor: 'listaProduto.descricao' },
  { titulo: 'Unidade de Medida', valor: 'listaProduto.unidadeMedida' },
  {
    titulo: 'NCM (Nomenclatura Comum do Mercosul)',
    valor: 'listaProduto.produtoFiscal.ncm',
  },
  {
    titulo: 'CEST (Código Especificador da Substituição Tributária)',
    valor: 'listaProduto.produtoFiscal.cest',
  },
  {
    titulo: 'Código de Barras',
    valor: 'listaProduto.produtoEngenharia.codigoBarras',
  },
  {
    titulo: 'Peso Liquido',
    valor: 'listaProduto.produtoEngenharia.pesoLiquido',
  },
  { titulo: 'Peso Bruto', valor: 'listaProduto.produtoEngenharia.pesoBruto' },
  {
    titulo: 'Tipo de Produto (SPED)',
    valor: 'listaProduto.produtoFiscal.tipoProdutoSped',
  },
  {
    titulo: 'Grupo',
    valor: 'listaProduto.grupoProdutoServico.descricao',
  },
  { titulo: 'Origem ', valor: 'listaProduto.produtoFiscal.origemIcms' },
  { titulo: 'Tags', valor: 'listaProduto.tags' },
];

export default ProdutoPorGrupoComReferenciasPlanilha;
