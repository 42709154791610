"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SegmentoPlanoEnum;
(function (SegmentoPlanoEnum) {
    SegmentoPlanoEnum["industria"] = "Ind\u00FAstria";
    SegmentoPlanoEnum["comercio"] = "Com\u00E9rcio";
    SegmentoPlanoEnum["industriaComercio"] = "Ind\u00FAstria e Com\u00E9rcio";
    SegmentoPlanoEnum["prestacaoServicos"] = "Presta\u00E7\u00E3o de Servi\u00E7os";
})(SegmentoPlanoEnum || (SegmentoPlanoEnum = {}));
exports.default = SegmentoPlanoEnum;
