import {
  FormatarCep,
  FormatarCnpjCpf,
  FormatarTelefone,
  IPessoaFiltroRelatorioBackend,
  IPessoaPorDataAniversarioCabecalhoRelatorio,
  IPessoaPorDataAniversarioRelatorio,
  IPessoaFiltroRelatorioFrontend,
} from '@elogestor/util';
import { format } from 'date-fns';
import React from 'react';
import PessoaPorDataAniversarioRelatorioComunicador from '../../../../../../Comunicador/Comercial/Relatorios/Pessoas/PessoaPorDataAniversarioRelatorio/PessoaPorDataAniversarioRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PessoaPorDataAniversarioPDF from './PessoaPorDataAniversarioPDF';
import PessoaPorDataAniversarioPlanilha from './PessoaPorDataAniversarioPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PessoaPorDataAniversarioRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPessoaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPessoaPorDataAniversarioRelatorio | null> => {
    const filtroBack: IPessoaFiltroRelatorioBackend = {
      mesDiaInicial: filtros.mesDiaInicial,
      mesDiaFinal: filtros.mesDiaFinal,
    };

    if (filtros.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaRepresentante) {
      filtroBack.listaIdRepresentante =
        filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaTags) {
      filtroBack.listaTextoTags =
        filtros.listaTags.listaValor.map((value) => value.textoTag) || [];
    }

    if (filtros?.cliente === 'true' || filtros?.cliente === 'false') {
      filtroBack.cliente = filtros?.cliente === 'true';
    }

    if (filtros?.fornecedor === 'true' || filtros?.fornecedor === 'false') {
      filtroBack.fornecedor = filtros?.fornecedor === 'true';
    }

    if (
      filtros?.transportador === 'true' ||
      filtros?.transportador === 'false'
    ) {
      filtroBack.transportador = filtros?.transportador === 'true';
    }

    if (
      filtros?.representante === 'true' ||
      filtros?.representante === 'false'
    ) {
      filtroBack.representante = filtros?.representante === 'true';
    }

    if (filtros?.produtor === 'true' || filtros?.produtor === 'false') {
      filtroBack.produtor = filtros?.produtor === 'true';
    }

    if (filtros?.motorista === 'true' || filtros?.motorista === 'false') {
      filtroBack.motorista = filtros?.motorista === 'true';
    }

    if (filtros?.tecnico === 'true' || filtros?.tecnico === 'false') {
      filtroBack.tecnico = filtros?.tecnico === 'true';
    }

    if (filtros?.ativo === 'true' || filtros?.ativo === 'false') {
      filtroBack.ativo = filtros?.ativo === 'true';
    }

    const response = await PessoaPorDataAniversarioRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (
      !response.listaPessoasAgrupadasPorDataAniversario ||
      response.listaPessoasAgrupadasPorDataAniversario.length === 0
    ) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno = response as IPessoaPorDataAniversarioRelatorio;
    const listaPessoasAgrupadasPorDataAniversario = await Promise.all(
      retorno.listaPessoasAgrupadasPorDataAniversario.map(
        (pessoasAniversario) => {
          const listaPessoa = pessoasAniversario.listaPessoa.map((pessoa) => {
            let pessoaEnderecoPrincipal;
            if (pessoa.pessoaEnderecoPrincipal) {
              pessoaEnderecoPrincipal = {
                ...pessoa.pessoaEnderecoPrincipal,
                cep: FormatarCep(pessoa.pessoaEnderecoPrincipal?.cep),
              };
            }

            const listaPessoaContato = pessoa.listaPessoaContato.map(
              (contato) => {
                return {
                  ...contato,
                  telefone: FormatarTelefone(contato.telefone),
                };
              }
            );

            return {
              ...pessoa,
              cnpjCpfVirtual: FormatarCnpjCpf(pessoa.cnpjCpfVirtual),
              telefone: FormatarTelefone(pessoa.telefone),
              pessoaEnderecoPrincipal,
              listaPessoaContato,
            };
          });
          let filtrosFormatada = pessoasAniversario.chave;
          if (pessoasAniversario.chave !== 'SEM - DATA DE ANIVERSÁRIO') {
            const filtrosString = new Date(
              `${pessoasAniversario.chave} ${'00:00:00'}`.replace(/-/g, '/')
            );

            filtrosFormatada = format(filtrosString, 'dd/MM');
          }

          return {
            ...pessoasAniversario,
            listaPessoa,
            chave: filtrosFormatada,
          };
        }
      )
    );

    return {
      ...retorno,
      listaPessoasAgrupadasPorDataAniversario,
    };
  };

  function FormatarCabecalho(
    filtros: IPessoaFiltroRelatorioFrontend,
    cabecalho: IPessoaPorDataAniversarioCabecalhoRelatorio
  ): IPessoaPorDataAniversarioCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';
    cabecalho.filtroTipoPessoa = '';

    if (filtros.cliente) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoPessoa +=
        filtros.cliente === 'true' ? 'Cliente: Sim' : 'Cliente: Não';
    }

    if (filtros.fornecedor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.fornecedor === 'true' ? 'Fornecedor: Sim' : 'Fornecedor: Não';
    }

    if (filtros.transportador) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.transportador === 'true'
          ? 'Transportador: Sim'
          : 'Transportador: Não';
    }

    if (filtros.representante) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.representante === 'true'
          ? 'Representante: Sim'
          : 'Representante: Não';
    }

    if (filtros.produtor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.produtor === 'true' ? 'Produtor: Sim' : 'Produtos: Não';
    }

    if (filtros.motorista) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.motorista === 'true' ? 'Motorista: Sim' : 'Motorista: Não';
    }

    if (filtros.tecnico) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.tecnico === 'true' ? 'Técnico: Sim' : 'Técnico: Não';
    }

    if (filtros.ativo) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.ativo === 'true' ? 'Ativo: Sim' : 'Ativo: Não';
    }

    if (filtros.mesDiaInicial || filtros.mesDiaFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroMesDiaInicial = '--/--';
      cabecalho.filtroMesDiaFinal = '--/--';
      if (filtros.mesDiaInicial) {
        const mesDiaInicialFormatado = filtros.mesDiaInicial.split('-');
        cabecalho.filtroMesDiaInicial = `${mesDiaInicialFormatado[1]}/${mesDiaInicialFormatado[0]}`;
      }

      if (filtros.mesDiaFinal) {
        const mesDiaFinalFormatado = filtros.mesDiaFinal.split('-');
        cabecalho.filtroMesDiaFinal = `${mesDiaFinalFormatado[1]}/${mesDiaFinalFormatado[0]}`;
      }
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (
      filtros.listaRepresentante &&
      filtros.listaRepresentante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.nomeRazaoSocial}`;
        })
        .join(', ');
    }

    if (filtros.listaTags && filtros.listaTags.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTag = filtros.listaTags.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PessoaPorDataAniversarioPlanilha,
      valores: dados.listaPessoasAgrupadasPorDataAniversario,
      nomeArquivo: 'PessoaPorDataAniversario',
      unwind: ['listaPessoa', 'listaPessoa.listaPessoaContato'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PessoaPorDataAniversarioPlanilha,
      nomeArquivo: 'PessoaPorDataAniversario',
      valores: dados.listaPessoasAgrupadasPorDataAniversario as any,
      unwind: ['listaPessoa', 'listaPessoa.listaPessoaContato'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <PessoaPorDataAniversarioPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorDataAniversarioPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PessoaPorDataAniversarioRelatorio();
