import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/documentos-eletronicos/inutilizacao-numeracao/transmitir';

interface IInutilizacaoNumeracaoTransmitirComunicadorUpdate {
  id: string;
}

class InutilizacaoNumeracaoTransmitirComunicador {
  public async update(
    params: IInutilizacaoNumeracaoTransmitirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new InutilizacaoNumeracaoTransmitirComunicador();
