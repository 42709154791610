import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/cancelar';

interface INotaFiscalSaidaCancelarComunicadorShow {
  id: string;
}

interface INotaFiscalSaidaCancelarComunicadorStore {
  id: string;
  params: any;
}

class NotaFiscalSaidaCancelarComunicador {
  public async show(
    params: INotaFiscalSaidaCancelarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalSaidaCancelarComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaCancelarComunicador();
