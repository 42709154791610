import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import EstruturaProdutoHook from './Hooks';
import { TreeContextProvider } from './Hooks/TreeContext';

const EstruturaProdutoDetalhe: React.FC = () => {
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <TreeContextProvider>
        <EstruturaProdutoHook>
          <Detalhe>
            <Main
              idEditar={params.id ?? null}
              dadosRecuperados={state?.dadosRecuperados ?? null}
              dadosDuplicados={state?.dadosDuplicados ?? null}
            />
          </Detalhe>
        </EstruturaProdutoHook>
      </TreeContextProvider>
    </Container>
  );
};

export default EstruturaProdutoDetalhe;
