import { IOrdemServicoRetratoComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrdemServicoRetratoComFotoProdutoPDF from './OrdemServicoRetratoComFotoProdutoImpressaoPDF';

interface IImpressaoOrdemServicoRetratoComFotoProduto {
  handleVisualizarPdf(
    data: IOrdemServicoRetratoComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemServicoRetratoComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemServicoRetratoComFotoProduto =
  (): IImpressaoOrdemServicoRetratoComFotoProduto => {
    async function handleVisualizarPdf(
      dados: IOrdemServicoRetratoComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrdemServicoRetratoComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoComFotoProdutoPDF dados={dados} />
        ),
      });
      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoRetratoComFotoProduto();
