const EstoqueSaldoProprioEmPoderProprioPlanilha = [
  { titulo: 'Código', valor: 'estoqueCombinacao.produto.codigo' },
  { titulo: 'Descrição', valor: 'estoqueCombinacao.produto.descricao' },
  {
    titulo: 'Unidade de Medida',
    valor: 'estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'saldo' },
];

export default EstoqueSaldoProprioEmPoderProprioPlanilha;
