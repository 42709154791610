import { FormCia } from '@elogestor/unformcia';
import {
  FormatarEnum,
  ICategoriaValoresAlterar,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import Select from '../../../../../../Componentes/Select';
import { BtnContainer } from '../styles';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import CategoriaObterDadosPadraoComunicador from '../../../../../../Comunicador/Financeiro/CategoriaDRE/Categoria/Comunicador/CategoriaObterDadosPadraoComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseTreeContext } from '../../Hooks/TreeContext';
import ParametroValorComunicador from '../../../../../../Comunicador/Configuracao/Parametro/Comunicador/ParametroValorComunicador';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputAutoCompleteCategoria, {
  IOnChangeItemAtualAutoCompleteCategoriaEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCategoria';
import InputAutoCompleteDemonstrativoResultado from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteDemonstrativoResultado';

type IProps = IMain<ICategoriaValoresAlterar>;

const Main: React.FC<IProps> = ({
  dadosDuplicados,
  dadosRecuperados,
  idEditar,
  dadosObrigatorios,
  dadosPadrao,
  onClickSalvar,
}) => {
  const {
    formRef,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    setLoading,
  } = UseForm();
  const { permissoes } = UsePermissoes();
  const { FinanceiroCategoriaDreCategoria: permissao } = permissoes;
  const idDetalheRegistro = getIdDetalheRegistro();
  const inputRefDescricao = useRef<HTMLInputElement>(null);

  const [mascara, setMascara] = useState('');

  const { idShowItem, idItemPai, setIdItemPai } = UseTreeContext();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    setIdItemPai('');
    onClickSalvar && onClickSalvar({ ...data, id });
  }, [formRef, handleSubmit, onClickSalvar, setIdItemPai]);

  const handleAdicionarCategoriaPai = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteCategoriaEvent) => {
      try {
        setLoading(true);

        const response = await CategoriaObterDadosPadraoComunicador.show({
          idCategoriaPai: event.itemAtual?.id || idItemPai,
        });

        if (
          idItemPai &&
          formRef.current?.getFieldValueNomeObjeto('categoriaPai')
        ) {
          return;
        }

        if (idItemPai) {
          const dadosAtuais = formRef.current?.getDataDuplicar();
          await formRef.current?.setSemExecutarEvento({
            ...dadosAtuais,
            categoriaPai: response.categoriaPai,
          });
        }

        formRef.current?.setFieldValue('codigoAtual', response.codigoAtual);
        formRef.current?.setFieldValue('codigo', response.codigo);
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    },
    [formRef, idItemPai, setLoading]
  );

  const handleChangeCodigoAtual = useCallback(
    (valor?: string): void => {
      const codigo = formRef.current?.getFieldValue('codigo');
      if (!codigo || !mascara || !valor) return;

      if (Array.from(valor).every((letra) => letra === '0')) {
        formRef.current?.setFieldValue('codigoAtual', null);
        return;
      }

      const nivelAtual = codigo.split('.').length;
      const subGruposCodigo = codigo.split('.');

      if (valor.length > subGruposCodigo[nivelAtual - 1].length) return;

      subGruposCodigo[subGruposCodigo.length - 1] = valor.padStart(
        subGruposCodigo[nivelAtual - 1].length,
        '0'
      );

      const codigoGerado = subGruposCodigo.join('.');

      formRef.current?.setFieldValue('codigo', codigoGerado);
    },
    [formRef, mascara]
  );

  const handleSetCodigoAtualMaxLength = useCallback((): number | undefined => {
    const codigo = formRef.current?.getFieldValue('codigo');
    if (!mascara || !codigo) {
      return undefined;
    }

    const nivelAtual = codigo.split('.').length;

    const subGruposMascara = mascara.split('.');
    const nivelAtualMascara = subGruposMascara[nivelAtual - 1];

    if (!nivelAtualMascara) {
      return undefined;
    }

    return nivelAtualMascara.length;
  }, [formRef, mascara]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    (async () => {
      if (idItemPai) {
        await formRef.current?.setDataInicial({
          ativo: true,
          tipoSinteticaAnalitica: TipoSinteticaAnaliticaEnum.analitica,
        });

        return;
      }
      try {
        setLoading(true);
        await handleCarregarDados({
          dadosDuplicados,
          dadosObrigatorios,
          dadosPadrao,
          dadosRecuperados,
        });
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    })();

    inputRefDescricao.current?.focus();
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idItemPai,
    idShowItem,
    setLoading,
  ]);

  useEffect(() => {
    (async () => {
      const valor = await ParametroValorComunicador.show({
        identificador: 'MascaraCategoria',
      });

      setMascara(valor);
    })();
  }, []);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHiddenHtml
        name="id"
        validarSeMudou={() => {
          return false;
        }}
      />

      <Divisor>
        <Row>
          <Col lg={12}>
            <InputAutoCompleteCategoria
              label="Conta Pai"
              name="idCategoriaPai"
              nomeObjeto="categoriaPai"
              placeholder="Conta Pai"
              disabled={!!idItemPai}
              codigoCompletoAtual={formRef.current?.getFieldValue('codigo')}
              listaIdParaRemover={
                formRef.current?.getFieldValue('id')
                  ? [formRef.current?.getFieldValue('id')]
                  : undefined
              }
              onChangeItemAtual={async (event) => {
                await handleAdicionarCategoriaPai(event);
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ padding: '15px', marginBottom: '-0px' }}>
        <Row>
          <Col lg={3}>
            <InputInteiroNulavel
              label="Código"
              name="codigoAtual"
              maxLength={handleSetCodigoAtualMaxLength()}
              min={1}
              onChange={(event) => {
                handleChangeCodigoAtual(event.target.value);
              }}
            />
          </Col>
          <Col lg={9}>
            <Input
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              ref={inputRefDescricao}
              maxLength={60}
            />
          </Col>
          <Col />
        </Row>

        <Row>
          <Col lg={3}>
            <InputLabel label="Código Completo" name="codigo" />
          </Col>
          <Col lg={9}>
            <Select
              label="Tipo de Conta"
              name="tipoSinteticaAnalitica"
              options={FormatarEnum({
                enumObj: TipoSinteticaAnaliticaEnum,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={9}>
            <InputAutoCompleteDemonstrativoResultado
              label="Associação com DRE"
              name="idDemonstrativoResultado"
              nomeObjeto="demonstrativoResultado"
              placeholder="Associação com DRE"
            />
          </Col>

          <Col lg={3}>
            <InputSwitch name="ativo" label="Ativo" />
          </Col>
        </Row>

        {/* <Row>
          <Col lg={12}>
            <ListaCategoriaRateioCentroCustoHook>
              <ListaDetalheCategoriaRateioCentroCusto name="listaCategoriaRateioCentroCusto" />
            </ListaCategoriaRateioCentroCustoHook>
          </Col>
        </Row> */}

        <BtnContainer>
          <button
            type="button"
            onClick={handleClickSalvar}
            className="btn-padrao btn-verde"
            disabled={
              loading ||
              (idDetalheRegistro && !permissao?.altera) ||
              (!idDetalheRegistro && !permissao?.inclui)
            }
          >
            <TextoLoadingSalvar loading={loading} />
          </button>
        </BtnContainer>
      </Divisor>
    </FormCia>
  );
};

export default Main;
