import api from '../../../../../Comum/Services/Api';

const rota =
  '/manufatura/producao/ordem-desmontagem/reportar-retorno-desmontagem';

interface IOrdemDesmontagemReportarRetornoDesmontagemComunicadorShow {
  id: string;
}

class OrdemDesmontagemReportarRetornoDesmontagemComunicador {
  public async show(
    params: IOrdemDesmontagemReportarRetornoDesmontagemComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemDesmontagemReportarRetornoDesmontagemComunicador();
