import {
  FormatarEnum,
  TipoPesoEnum,
  ModalidadeFreteEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompleteTransportador from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteTransportador';
import InputAutoCompleteEstado from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import Select from '../../../../../../Componentes/Select/index';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';

const TransporteTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const { formRef } = UseForm();
  InputHidden({
    name: 'notaFiscalEntradaTransporteVolume.quantidadeFormaCalculoPorItem',
  });
  InputHidden({
    name: 'notaFiscalEntradaTransporteVolume.metroCubicoFormaCalculoPorItem',
  });
  InputHidden({
    name: 'notaFiscalEntradaTransporteVolume.metroQuadradoFormaCalculoPorItem',
  });

  const handleAlterarCampos = useCallback(
    (campo: string, valor?: string) => {
      if (campo === 'pesoLiquido' || campo === 'pesoBruto') {
        formRef.current?.setFieldValue(
          `notaFiscalEntradaTransporteVolume.tipoCalculoPesos`,
          TipoPesoEnum.informado
        );
      } else if (
        campo === 'tipoCalculoPesos' &&
        valor === TipoPesoEnum.calculado
      ) {
        const listaItem = formRef.current?.getFieldValue(
          'listaNotaFiscalEntradaItem'
        );

        const pesoLiquido = listaItem.reduce(
          (acumulador: number, item: any) => {
            acumulador += item.notaFiscalEntradaItemEngenharia?.pesoLiquido
              ? Number(item.notaFiscalEntradaItemEngenharia?.pesoLiquido)
              : 0;
            return acumulador;
          },
          0
        );

        const pesoBruto = listaItem.reduce((acumulador: number, item: any) => {
          acumulador += item.notaFiscalEntradaItemEngenharia?.pesoBruto
            ? Number(item.notaFiscalEntradaItemEngenharia?.pesoBruto)
            : 0;
          return acumulador;
        }, 0);

        formRef.current?.setFieldValue(
          'notaFiscalEntradaTransporteVolume.pesoLiquido',
          pesoLiquido
        );

        formRef.current?.setFieldValue(
          'notaFiscalEntradaTransporteVolume.pesoBruto',
          pesoBruto
        );
      } else {
        formRef.current?.setFieldValue(
          `notaFiscalEntradaTransporteVolume.${campo}FormaCalculoPorItem`,
          false
        );
      }
    },
    [formRef]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTransportador
              label="Transportadora"
              name="notaFiscalEntradaTransporteTransportadora.idPessoaTransportadora"
              placeholder="Transportadora"
              nomeObjeto="notaFiscalEntradaTransporteTransportadora.pessoaTransportadora"
              disabled={situacaoBloquearCampos}
              informacaoAdicional
            />
          </Col>
        </Row>

        <Row>
          <Col lg={5} md={8} sm={12}>
            <Select
              label="Modalidade Frete"
              name="notaFiscalEntradaTransporteTransportadora.modalidadeFrete"
              options={FormatarEnum({
                enumObj: ModalidadeFreteEnum,
                nullavel: true,
              })}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <Input
              label="Placa"
              name="notaFiscalEntradaTransporteTransportadora.placa"
              placeholder="Placa"
              maxLength={7}
              maiuscula
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputAutoCompleteEstado
              label="UF"
              name="notaFiscalEntradaTransporteTransportadora.idEstadoPlaca"
              placeholder="UF"
              nomeObjeto="notaFiscalEntradaTransporteTransportadora.estadoPlaca"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Input
              label="RNTC"
              name="notaFiscalEntradaTransporteTransportadora.rntc"
              placeholder="RNTC"
              maxLength={20}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Volume</h5>

        <Row>
          <Col lg={4} md={6} sm={12}>
            <Input
              label="Espécie"
              name="notaFiscalEntradaTransporteVolume.especie"
              placeholder="Espécie"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Input
              label="Marca"
              name="notaFiscalEntradaTransporteVolume.marca"
              placeholder="Marca"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Input
              label="Númeração"
              name="notaFiscalEntradaTransporteVolume.numeracao"
              placeholder="Númeração"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputInteiroNulavel
              label="Quantidade"
              name="notaFiscalEntradaTransporteVolume.quantidade"
              placeholder="Quantidade"
              disabled={situacaoBloquearCampos}
              onBlurCia={(ev) => {
                if (ev.mudou) {
                  handleAlterarCampos('quantidade');
                }
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Peso Bruto"
              name="notaFiscalEntradaTransporteVolume.pesoBruto"
              placeholder="Peso Bruto"
              casasInteiras={15}
              casasDecimais={3}
              disabled={situacaoBloquearCampos}
              onBlurCia={(ev) => {
                if (ev.mudou) {
                  handleAlterarCampos('pesoBruto');
                }
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Peso Líquido"
              name="notaFiscalEntradaTransporteVolume.pesoLiquido"
              placeholder="Peso Líquido"
              casasInteiras={15}
              casasDecimais={3}
              disabled={situacaoBloquearCampos}
              onBlurCia={(ev) => {
                if (ev.mudou) {
                  handleAlterarCampos('pesoLiquido');
                }
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo dos Pesos"
              name="notaFiscalEntradaTransporteVolume.tipoCalculoPesos"
              options={FormatarEnum({
                enumObj: TipoPesoEnum,
                nullavel: true,
              })}
              disabled={situacaoBloquearCampos}
              onChange={(ev) => {
                handleAlterarCampos('tipoCalculoPesos', ev.target.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Metro Quadrado"
              name="notaFiscalEntradaTransporteVolume.metroQuadrado"
              placeholder="Metro Quadrado"
              casasInteiras={18}
              casasDecimais={3}
              disabled={situacaoBloquearCampos}
              onBlurCia={(ev) => {
                if (ev.mudou) {
                  handleAlterarCampos('metroQuadrado');
                }
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Metro Cúbico"
              name="notaFiscalEntradaTransporteVolume.metroCubico"
              placeholder="Metro Cúbico"
              casasInteiras={18}
              casasDecimais={3}
              disabled={situacaoBloquearCampos}
              onBlurCia={(ev) => {
                if (ev.mudou) {
                  handleAlterarCampos('metroCubico');
                }
              }}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TransporteTab;
