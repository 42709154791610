import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/exportar-xml';

interface INotaFiscalEntradaTransmissaoEventoExportarXmlComunicadorShow {
  id: string;
}

class NotaFiscalEntradaTransmissaoEventoExportarXmlComunicador {
  public async show(
    params: INotaFiscalEntradaTransmissaoEventoExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoExportarXmlComunicador();
