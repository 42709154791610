import React from 'react';
import ContaContabilLista from './Lista';
import { TreeContextProvider } from './Hooks/TreeContext';

const ContaContabil: React.FC = () => {
  return (
    <TreeContextProvider>
      <ContaContabilLista />
    </TreeContextProvider>
  );
};

export default ContaContabil;
