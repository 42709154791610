import { IFinalidadeUsoClienteEmpresaLista } from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import FinalidadeUsoClienteComunicador from '../../../../../Comunicador/Comercial/Pessoas/FinalidadeUso/Comunicador/FinalidadeUsoClienteComunicador';
import FinalidadeUsoClienteLogComunicador from '../../../../../Comunicador/Comercial/Pessoas/FinalidadeUso/Log/Comunicador/FInalidadeUsoClienteLogComunicador';

const FinalidadeUsoClienteLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await FinalidadeUsoClienteComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IFinalidadeUsoClienteEmpresaLista) => {
            return {
              id: data.id,
              descricao: <div className="lista-texto">{data.descricao}</div>,
              ativo: (
                <div
                  className="lista-booleano"
                  style={{ color: data.ativo ? 'green' : 'red' }}
                >
                  {data.ativo ? 'Sim' : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await FinalidadeUsoClienteComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          FinalidadeUsoClienteLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.ComercialPessoasFinalidadeUsoCliente?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ComercialPessoasFinalidadeUsoCliente?.exclui]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialPessoasFinalidadeUsoCliente' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Finalidade de Uso do Cliente"
          permissao={permissoes.ComercialPessoasFinalidadeUsoCliente}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return FinalidadeUsoClienteLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default FinalidadeUsoClienteLista;
