import React from 'react';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import { FormSeguroProvider } from './FormSeguroHook';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';

const ManifestoTransporteSeguroAverbacaoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <FormSeguroProvider>
      <RotasHook>
        <FormHook>{children}</FormHook>
      </RotasHook>
    </FormSeguroProvider>
  );
};

export default ManifestoTransporteSeguroAverbacaoHook;
