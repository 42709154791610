import {
  FormatarDataHoraParaPtBr,
  IPedidoCompraFiltroRelatorioBackend,
  IPedidoCompraFiltroRelatorioFrontend,
  IPedidoCompraResumoPorDataEmissaoCabecalhoRelatorio,
  IPedidoCompraResumoPorDataEmissaoRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import PedidoCompraResumoPorDataEmissaoRelatorioComunicador from '../../../../../../../Comunicador/Suprimentos/Relatorios/Compras/PedidoCompra/PedidoCompraResumoPorDataEmissaoRelatorio/PedidoCompraResumoPorDataEmissaoRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import PedidoCompraResumoPorDataEmissaoPDF from './PedidoCompraResumoPorDataEmissaoPDF';
import PedidoCompraResumoPorDataEmissaoPlanilha from './PedidoCompraResumoPorDataEmissaoPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IPedidoCompraFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PedidoCompraResumoPorDataEmissaoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPedidoCompraFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPedidoCompraResumoPorDataEmissaoRelatorio | null> => {
    const filtroBack: IPedidoCompraFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaFornecedor) {
      filtroBack.listaIdFornecedor =
        filtros.listaFornecedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupo =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaOperacaoFiscal) {
      filtroBack.listaIdOperacaoFiscal =
        filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    const response =
      await PedidoCompraResumoPorDataEmissaoRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }
    const retorno = response as IPedidoCompraResumoPorDataEmissaoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioDataEmissaoValorSubtotal: Number(
            dados.valores.somatorioDataEmissaoValorSubtotal
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorFrete: Number(
            dados.valores.somatorioDataEmissaoValorFrete
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorSeguro: Number(
            dados.valores.somatorioDataEmissaoValorSeguro
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorOutrasDespesas: Number(
            dados.valores.somatorioDataEmissaoValorOutrasDespesas
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorDesconto: Number(
            dados.valores.somatorioDataEmissaoValorDesconto
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorIpi: Number(
            dados.valores.somatorioDataEmissaoValorIpi
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorSt: Number(
            dados.valores.somatorioDataEmissaoValorSt
          ).FormatarParaPtBr(),
          somatorioDataEmissaoValorTotal: Number(
            dados.valores.somatorioDataEmissaoValorTotal
          ).FormatarParaPtBr(),
        };

        const listaPedidoCompraItem = dados.listaPedidoCompraItem.map(
          (pedidoCompra) => {
            return {
              ...pedidoCompra,
              somatorioValorSubtotal: Number(
                pedidoCompra.somatorioValorSubtotal
              ).FormatarParaPtBr(),
              somatorioValorFrete: Number(
                pedidoCompra.somatorioValorFrete
              ).FormatarParaPtBr(),
              somatorioValorSeguro: Number(
                pedidoCompra.somatorioValorSeguro
              ).FormatarParaPtBr(),
              somatorioValorOutrasDespesas: Number(
                pedidoCompra.somatorioValorOutrasDespesas
              ).FormatarParaPtBr(),
              somatorioValorDesconto: Number(
                pedidoCompra.somatorioValorDesconto
              ).FormatarParaPtBr(),
              somatorioValorIpi: Number(
                pedidoCompra.somatorioValorIpi
              ).FormatarParaPtBr(),
              somatorioValorSt: Number(
                pedidoCompra.somatorioValorSt
              ).FormatarParaPtBr(),
              somatorioValorTotal: Number(
                pedidoCompra.somatorioValorTotal
              ).FormatarParaPtBr(),
            };
          }
        );

        return {
          ...dados,
          valores,
          listaPedidoCompraItem,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      somatorioGeralValorSubtotal: Number(
        retorno.somatorioGeralValorSubtotal
      ).FormatarParaPtBr(),
      somatorioGeralValorFrete: Number(
        retorno.somatorioGeralValorFrete
      ).FormatarParaPtBr(),
      somatorioGeralValorSeguro: Number(
        retorno.somatorioGeralValorSeguro
      ).FormatarParaPtBr(),
      somatorioGeralValorOutrasDespesas: Number(
        retorno.somatorioGeralValorOutrasDespesas
      ).FormatarParaPtBr(),
      somatorioGeralValorDesconto: Number(
        retorno.somatorioGeralValorDesconto
      ).FormatarParaPtBr(),
      somatorioGeralValorIpi: Number(
        retorno.somatorioGeralValorIpi
      ).FormatarParaPtBr(),
      somatorioGeralValorSt: Number(
        retorno.somatorioGeralValorSt
      ).FormatarParaPtBr(),
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IPedidoCompraFiltroRelatorioFrontend,
    cabecalho: IPedidoCompraResumoPorDataEmissaoCabecalhoRelatorio
  ): IPedidoCompraResumoPorDataEmissaoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (
      filtros.listaFornecedor &&
      filtros.listaFornecedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroFornecedor = filtros.listaFornecedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaOperacaoFiscal &&
      filtros.listaOperacaoFiscal.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
        .map((valor: any) => {
          return valor.cfopDescricao;
        })
        .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PedidoCompraResumoPorDataEmissaoPlanilha,
      nomeArquivo: 'PedidoCompraResumoPorDataEmissao',
      valores: dados.listaDados,
      unwind: ['listaPedidoCompraItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PedidoCompraResumoPorDataEmissaoPlanilha,
      nomeArquivo: 'PedidoCompraResumoPorDataEmissao',
      valores: dados.listaDados as any,
      unwind: ['listaPedidoCompraItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PedidoCompraResumoPorDataEmissaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPedidoCompraFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PedidoCompraResumoPorDataEmissaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PedidoCompraResumoPorDataEmissaoRelatorio();
