"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var InstrucaoBoletoBanrisulRemessaEnum;
(function (InstrucaoBoletoBanrisulRemessaEnum) {
    InstrucaoBoletoBanrisulRemessaEnum["remessa"] = "01";
    InstrucaoBoletoBanrisulRemessaEnum["pedidoBaixa"] = "02";
    InstrucaoBoletoBanrisulRemessaEnum["concessaoAbatimento"] = "04";
    InstrucaoBoletoBanrisulRemessaEnum["cancelamentoAbatimento"] = "05";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoVencimento"] = "06";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoUsoEmpresa"] = "07";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoSeuNumero"] = "08";
    InstrucaoBoletoBanrisulRemessaEnum["protestarImediatamente"] = "09";
    InstrucaoBoletoBanrisulRemessaEnum["sustacaoProtesto"] = "10";
    InstrucaoBoletoBanrisulRemessaEnum["naoCobrarJurosMora"] = "11";
    InstrucaoBoletoBanrisulRemessaEnum["reembolsoTransferenciaDescontoVendor"] = "12";
    InstrucaoBoletoBanrisulRemessaEnum["reembolsoDevolucaoDescontoVendor"] = "13";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoNumeroDiasProtesto"] = "16";
    InstrucaoBoletoBanrisulRemessaEnum["protestarImediatamenteFinsFalencia"] = "17";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoNomePagador"] = "18";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoEnderecoPagador"] = "19";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoCidadePagador"] = "20";
    InstrucaoBoletoBanrisulRemessaEnum["alteracaoCepPagador"] = "21";
    InstrucaoBoletoBanrisulRemessaEnum["acertoDadosRateioCredito"] = "68";
    InstrucaoBoletoBanrisulRemessaEnum["cancelamentoDadosRateio"] = "69";
})(InstrucaoBoletoBanrisulRemessaEnum || (InstrucaoBoletoBanrisulRemessaEnum = {}));
exports.default = InstrucaoBoletoBanrisulRemessaEnum;
