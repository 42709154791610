import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import IParametroRotaDetalhe from '../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import ManifestoTransporteHook from './Hooks';
import AcoesManifestoTransporte from './AcoesManifestoTransporte';

const ManifestoTransporteDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <ManifestoTransporteHook>
        <SubHeader
          titulo={
            params.id
              ? 'Editar Manifesto de Transporte'
              : 'Manifesto de Transporte'
          }
          permissao={permissoes.TransporteManifestoTransporte}
          botoes={
            <AcoesManifestoTransporte
              permissao={permissoes.TransporteManifestoTransporte}
            />
          }
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </ManifestoTransporteHook>
    </Container>
  );
};

export default ManifestoTransporteDetalhe;
