import api from '../../../../Comum/Services/Api';

const rota = 'transporte/veiculo';

interface IVeiculoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IVeiculoComunicadorShow {
  id: string;
}

interface IVeiculoComunicadorStore {
  params: any;
}

interface IVeiculoComunicadorUpdate {
  id: string;
  params: any;
}

interface IVeiculoComunicadorDeletar {
  id: string;
}

class VeiculoComunicador {
  public async index(params: IVeiculoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IVeiculoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IVeiculoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IVeiculoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IVeiculoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new VeiculoComunicador();
