import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-calculo-cartao/ordem';

interface IRegraCalculoCartaoOrdemComunicadorUpdate {
  params: any;
}

class RegraCalculoCartaoOrdemComunicador {
  public async update(
    params: IRegraCalculoCartaoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraCalculoCartaoOrdemComunicador();
