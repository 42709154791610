import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { RiFileList2Line } from 'react-icons/ri/index.mjs';
import { v4 } from 'uuid';
import { TipoAdiantamentoEnum, TipoContaEnum } from '@elogestor/util';
import { GiMoneyStack } from 'react-icons/gi';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ContaParcelaBoletoHook from './ContaParcelaBoleto/Hook';
import ContaParcelaBoletoLista from './ContaParcelaBoleto/Lista';
import MovimentoPortadorLista from './UtilizacaoAdiantamento/Lista';
import MovimentoPortadorHook from './UtilizacaoAdiantamento/Hook';

interface IAcoesConta {
  permissao?: IPermissao;
}

const AcoesConta: React.FC<IAcoesConta> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showContaParcelaBoleto, setShowContaParcelaBoleto] = useState(false);

  const [showUtilizacaoAdiantamento, setShowUtilizacaoAdiantamento] =
    useState(false);

  const tipoConta = formRef?.current?.getFieldValue('tipo');

  const tipoAdiantamento = formRef?.current?.getFieldValue('tipoAdiantamento');

  const possuiBoleto = formRef?.current
    ?.getFieldValue('listaContaParcela')
    .some((e: any) => e.formaPagamento && e.portador);

  const handleValidar = useCallback(
    async (tipoContaValidar?: TipoContaEnum): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (tipoContaValidar && tipoContaValidar !== TipoContaEnum.pagar)
          return true;

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef]
  );

  const handleSalvar = useCallback(
    async (tipoContaValidar?: TipoContaEnum): Promise<boolean> => {
      try {
        if (!handleValidar(tipoContaValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  // #region Boletos

  const handleContaParcelaBoleto = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowContaParcelaBoleto(true);
  }, [handleSalvar]);

  const handleUtilizacaoAdiantamento = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowUtilizacaoAdiantamento(true);
  }, [handleSalvar]);

  const handleContaParcelaBoletoFecharFormModal = useCallback(async () => {
    setShowContaParcelaBoleto(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleMovimentoPortadorFecharFormModal = useCallback(async () => {
    setShowUtilizacaoAdiantamento(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Boletos

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            tipoConta === TipoContaEnum.pagar ||
            !possuiBoleto
          }
          onClick={handleContaParcelaBoleto}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Boletos
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            !Object.values(TipoAdiantamentoEnum).includes(tipoAdiantamento)
          }
          onClick={handleUtilizacaoAdiantamento}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiFileList2Line style={{ marginRight: 5 }} />
            Utilização do Adiantamento
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showContaParcelaBoleto && (
        <ContaParcelaBoletoHook>
          <ContaParcelaBoletoLista
            onFecharFormModal={handleContaParcelaBoletoFecharFormModal}
            numeroDocumento={formRef.current?.getFieldValue('numeroDocumento')}
            idConta={formRef.current?.getFieldValue('id')}
            idPessoa={formRef.current?.getFieldValue('idPessoa')}
            idConhecimentoTransporte={formRef.current?.getFieldValue(
              'idConhecimentoTransporte'
            )}
            idNotaFiscalSaida={formRef.current?.getFieldValue(
              'idNotaFiscalSaida'
            )}
          />
        </ContaParcelaBoletoHook>
      )}

      {showUtilizacaoAdiantamento && (
        <MovimentoPortadorHook>
          <MovimentoPortadorLista
            onFecharFormModal={handleMovimentoPortadorFecharFormModal}
            idConta={formRef.current?.getFieldValue('id')}
          />
        </MovimentoPortadorHook>
      )}
    </>
  );
};

export default AcoesConta;
