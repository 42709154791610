import React, { createContext, useCallback, useState, useContext } from 'react';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import LoginComunicador from '../Comunicador/Configuracao/Login/Comunicador/LoginComunicador';
import ToastErro from '../Util/Toasts/ToastErro';
import { UseParametros } from './ParametrosHook';
import { UseLiberacoes } from './LiberacoesHook';

interface IAuthState {
  token: string;

  usuario: {
    id: string;
    nome: string;
  };

  empresa: {
    id: string;
    nomeRazaoSocial: string;
    cnpjCpf: string;
    uf: string;
  };
}

interface ISignInCredentials {
  cnpjCpfSubdominio: string;
  usuario: string;
  senha: string;
}

interface IAuthContextData {
  usuario: { id: string; nome: string };
  empresa: {
    id: string;
    nomeRazaoSocial: string;
    cnpjCpf: string;
    uf: string;
  };
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

interface IUsuarioLogado {
  id?: string;
  nome?: string;
}

interface IEmpresaLogada {
  id?: string;
  nomeRazaoSocial?: string;
  cnpjCpf?: string;
  uf?: string;
}

const usuarioLogado: IUsuarioLogado = {};
const empresaLogada: IEmpresaLogada = {};

const AuthProvider: React.FC<IPadraoProps> = ({ children }) => {
  const { carregarParametros } = UseParametros();
  const { carregarLiberacoes } = UseLiberacoes();

  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@EloGestorle:token');
    const usuarioString = localStorage.getItem('@EloGestorle:usuario');
    const empresaString = localStorage.getItem('@EloGestorle:empresa');

    if (token && usuarioString && empresaString) {
      const usuario = JSON.parse(usuarioString);
      Object.assign(usuarioLogado, usuario);

      const empresa = JSON.parse(empresaString);
      Object.assign(empresaLogada, empresa);
      return { token, usuario, empresa };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(
    async ({ cnpjCpfSubdominio, usuario, senha }: ISignInCredentials) => {
      try {
        let utcFront = new Date().toTimeString().slice(12, 17);
        utcFront = utcFront.Insert(3, ':');

        const response = await LoginComunicador.store({
          params: {
            cnpjCpfSubdominio,
            usuario,
            senha,
            utcFront,
          },
        });

        const { token, user, empresa } = response;

        if (token) localStorage.setItem('@EloGestorle:token', token);

        if (user)
          localStorage.setItem('@EloGestorle:usuario', JSON.stringify(user));

        if (empresa)
          localStorage.setItem('@EloGestorle:empresa', JSON.stringify(empresa));

        setData({ token, usuario: user, empresa });
        Object.assign(usuarioLogado, user);
        Object.assign(empresaLogada, empresa);

        sessionStorage.removeItem('@EloGestorle:pesquisaAvancada');

        await carregarParametros();
        await carregarLiberacoes();
      } catch (err) {
        const error = err as Error;

        if (error.message === 'Network Error') {
          if (navigator.onLine) {
            ToastErro(
              'Falha ao conectar com o servidor, tente novamente mais tarde!',
              { autoClose: 6000 }
            );
          } else {
            ToastErro(
              'Falha ao conectar com o servidor, verifique sua conexão com a internet!',
              { autoClose: 6000 }
            );
          }
        } else {
          throw err;
        }
      }
    },
    [carregarLiberacoes, carregarParametros]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@EloGestorle:token');
    localStorage.removeItem('@EloGestorle:usuario');
    localStorage.removeItem('@EloGestorle:empresa');

    setData({} as IAuthState);
    Object.assign(usuarioLogado, { id: undefined, nome: undefined });
    Object.assign(empresaLogada, {
      id: undefined,
      nomeRazaoSocial: undefined,
      cnpjCpf: undefined,
      uf: undefined,
    });

    sessionStorage.removeItem('@EloGestorle:pesquisaAvancada');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        usuario: data.usuario as any,
        empresa: data.empresa as any,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function UseAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('UseAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, UseAuth, usuarioLogado, empresaLogada };
