import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-pedido-venda/curva-abc-pedido-venda-grupo-produto-com-produto-por-valor';

interface ICurvaABCPedidoVendaGrupoProdutoComProdutoPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCPedidoVendaGrupoProdutoComProdutoPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCPedidoVendaGrupoProdutoComProdutoPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCPedidoVendaGrupoProdutoComProdutoPorValorRelatorioComunicador();
