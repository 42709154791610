import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import {
  FinalidadeEmissaoEnum,
  INotaFiscalEntradaItemEstoqueLocalEstoqueValoresInserir,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimal, {
  IInputDecimalRef,
} from '../../../../../../../../Componentes/Inputs/InputDecimal';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../../../Componentes/Divisor';
import ItemInformacaoAdicionalTab from './ItemInformacaoAdicionalTab/index';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseItemTab } from '../Hook/ItemTabHook';
import ItemOutrosValoresTab from './ItemOutrosValoresTab';
import ItemImpostoTab from './ItemImpostoTab';
import ItemEstoqueTab from './ItemEstoqueTab';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseItemNotaFiscalEntrada } from '../Hook/ItemNotaFiscalEntradaHook';
import { UseItemEstoque } from '../Hook/ItemEstoqueHook';
import ItemInformacaoXmlNFFornecedorTab from './ItemInformacaoXmlNFFornecedorTab';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ItemComercialTab from './ItemComercialTab';
import { UseSituacaoBloquearCampos } from '../../../Hook/SituacaoBloquearCamposHook';
import InputAutoCompleteProdutoServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import ObterOrigemIcmsComunicador from '../../../../../../../../Comunicador/Fiscal/Icms/Origem/ObterOrigemIcmsComunicador/ObterOrigemIcmsComunicador';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ItemDeclaracaoImportacaoTab from './ItemDeclaracaoImportacaoTab';
import ItemEngenhariaTab from './ItemEngenhariaTab';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import ItemVeiculoTab from './ItemVeiculoTab';

interface INotaFiscalEntradaItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const NotaFiscalEntradaItemDetalhe: React.FC<INotaFiscalEntradaItemModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
  onLimparFormModal,
}) => {
  const parametros = UseParametros();
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
    handleSetarFocus,
  } = UseListaDetalheForm();

  const { formRef: formPrincipal } = UseForm();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const {
    calculaValorTotalProduto,
    calcularRateioItem,
    setItemOrdem,
    obterConfiguracaoTributariaConformeProduto,
    calcularQuantidadeTributadoProdutoTributado,
    calcularValorUnitarioTributadoProdutoTributado,
    obterRegraEscolhaAliquota,
    calcularItemEngenhariaPesagem,
    calcularItemEngenhariaMetragem,
    limparConfiguracaoTributaria,
  } = UseItemTab();

  const { formRef: formRefPrincipal } = UseForm();

  const { telaPequena } = UseReactSizeMeBodyHook();

  const { setProdutoControlaLote, listaLocalEstoque, setListaLocalEstoque } =
    UseItemNotaFiscalEntrada();
  const { selecionarItemEstoqueLocalEstoque } = UseItemEstoque();

  const idDetalheRegistro = getIdDetalheRegistro();

  const subtotalRef = useRef<IInputDecimalRef>(null);
  const debounceChangeRef = useRef<number | null>(null);

  const idImportacaoXmlAutorizacao = formRefPrincipal.current?.getFieldValue(
    'transmissao.idImportacaoXmlAutorizacao'
  );

  const uf = formRefPrincipal.current?.getFieldValue(
    'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
  );

  const listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem =
    formRefDetalhe.current?.getFieldValue(
      'listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem'
    );

  const [tabSelecionada, setTabSelecionada] = useState('impostos');
  ('tipoNotaFiscalEntrada');

  const serie = formPrincipal.current?.getFieldValueNomeObjeto('serie');
  const modeloDocumento =
    formPrincipal.current?.getFieldValueNomeObjeto('modeloDocumento');
  let somenteServico = false;
  if (
    serie?.modeloDocumento?.descricao &&
    String(serie.modeloDocumento.descricao).toUpperCase().includes('SERVIÇO')
  )
    somenteServico = true;
  if (
    modeloDocumento?.descricao &&
    String(modeloDocumento.descricao).toUpperCase().includes('SERVIÇO')
  )
    somenteServico = true;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.notaFiscalEntradaItemEstoque.listaNotaFiscalEntradaItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: INotaFiscalEntradaItemEstoqueLocalEstoqueValoresInserir =
          {
            id: itemLocalEstoque.id,
            idLocalEstoque: itemLocalEstoque.localEstoque.id,
            idLote: itemLocalEstoque.lote?.id,
            quantidade: Number(itemLocalEstoque.quantidade),
            tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
            indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
          };

        return retorno;
      });

    if (uf !== 'EX') {
      data.listaNotaFiscalEntradaItemDeclaracaoImportacao = [];
      data.notaFiscalEntradaItemImpostoImportacao = {
        ...data.notaFiscalEntradaItemImpostoImportacao,
        baseCalculo: null,
        aliquota: null,
        valorTotal: null,
        valorDespesasAduaneiras: null,
        valorImpostoOperacoesFinanceiras: null,
      };
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, listaLocalEstoque, onSalvarFormModal, uf]);

  const handleClickSalvarENovo = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.notaFiscalEntradaItemEstoque.listaNotaFiscalEntradaItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: INotaFiscalEntradaItemEstoqueLocalEstoqueValoresInserir =
          {
            id: itemLocalEstoque.id,
            idLocalEstoque: itemLocalEstoque.localEstoque.id,
            idLote: itemLocalEstoque.lote?.id,
            quantidade: Number(itemLocalEstoque.quantidade),
            tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
            indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
          };

        return retorno;
      });

    if (uf !== 'EX') {
      data.listaNotaFiscalEntradaItemDeclaracaoImportacao = [];
      data.notaFiscalEntradaItemImpostoImportacao = {
        ...data.notaFiscalEntradaItemImpostoImportacao,
        baseCalculo: null,
        aliquota: null,
        valorTotal: null,
        valorDespesasAduaneiras: null,
        valorImpostoOperacoesFinanceiras: null,
      };
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await formRefDetalhe.current?.reset();
    if (onLimparFormModal) onLimparFormModal();

    handleCarregarDados();

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleCarregarDados,
    handleSetarFocus,
    handleSubmit,
    listaLocalEstoque,
    onLimparFormModal,
    uf,
  ]);

  const handleClickSalvarEContinuar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    data.notaFiscalEntradaItemEstoque.listaNotaFiscalEntradaItemEstoqueLocalEstoque =
      listaLocalEstoque.map((itemLocalEstoque) => {
        const retorno: INotaFiscalEntradaItemEstoqueLocalEstoqueValoresInserir =
          {
            id: itemLocalEstoque.id,
            idLocalEstoque: itemLocalEstoque.localEstoque.id,
            idLote: itemLocalEstoque.lote?.id,
            quantidade: Number(itemLocalEstoque.quantidade),
            tipoEntradaSaida: itemLocalEstoque.tipoEntradaSaida,
            indicadorTipoEstoque: itemLocalEstoque.indicadorTipoEstoque,
          };

        return retorno;
      });

    if (uf !== 'EX') {
      data.listaNotaFiscalEntradaItemDeclaracaoImportacao = [];
      data.notaFiscalEntradaItemImpostoImportacao = {
        ...data.notaFiscalEntradaItemImpostoImportacao,
        baseCalculo: null,
        aliquota: null,
        valorTotal: null,
        valorDespesasAduaneiras: null,
        valorImpostoOperacoesFinanceiras: null,
      };
    }

    const { erro, id } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    setIdDetalheRegistro(id);

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleSetarFocus,
    handleSubmit,
    listaLocalEstoque,
    setIdDetalheRegistro,
    uf,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados, idDetalheRegistro]);

  useEffect(() => {
    if (!idDetalheRegistro) setItemOrdem();
  }, [idDetalheRegistro, setItemOrdem]);

  useEffect(() => {
    if (formPrincipal.current) {
      const finalidadeEmissao =
        formPrincipal.current.getFieldValue('finalidadeEmissao');
      if (
        finalidadeEmissao === FinalidadeEmissaoEnum.ajuste ||
        finalidadeEmissao === FinalidadeEmissaoEnum.complemento
      ) {
        const inputRef =
          formRefDetalhe.current?.getFieldRef('valorTotalProduto');
        inputRef.disabled = false;
      }
    }
  }, [formPrincipal, formRefDetalhe]);

  const handleCalcularValorTotalProdutos = useCallback(() => {
    calculaValorTotalProduto();
  }, [calculaValorTotalProduto]);

  const handleCalcularRateioItem = useCallback(() => {
    calcularRateioItem();
  }, [calcularRateioItem]);

  const handleCalcularItemEngenhariaPesagem = useCallback(() => {
    calcularItemEngenhariaPesagem();
  }, [calcularItemEngenhariaPesagem]);

  const handleCalcularItemEngenhariaMetragem = useCallback(() => {
    calcularItemEngenhariaMetragem();
  }, [calcularItemEngenhariaMetragem]);

  const handleObterConfiguracaoTributariaConformeProduto = useCallback(() => {
    obterConfiguracaoTributariaConformeProduto();
  }, [obterConfiguracaoTributariaConformeProduto]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleCalcularQuantidadeTributadoProdutoTributado = useCallback(() => {
    calcularQuantidadeTributadoProdutoTributado();
  }, [calcularQuantidadeTributadoProdutoTributado]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(() => {
      calcularValorUnitarioTributadoProdutoTributado();
    }, [calcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota = useCallback(async () => {
    await obterRegraEscolhaAliquota();
  }, [obterRegraEscolhaAliquota]);

  const handleCalcularQuantidadeEstoque =
    useCallback(async (): Promise<void> => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const fatorConversao = formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemEstoque.fatorConversao'
      );
      const quantidadeEstoque = (
        Number(quantidade) * Number(fatorConversao)
      ).Arredondar(4);

      formRefDetalhe.current?.setFieldValue(
        'notaFiscalEntradaItemEstoque.quantidadeEstoque',
        quantidadeEstoque
      );
    }, [formRefDetalhe]);

  const handleSetControleEstoque = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    if (!produto) {
      setProdutoControlaLote(false);
    } else {
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalEntradaItemEstoque.fatorConversao',
        1
      );
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalEntradaItemEstoque.quantidadeEstoque',
        Number(quantidade)
      );

      setProdutoControlaLote(produto.produtoEstoque.controlarLote);
    }
  }, [formRefDetalhe, setProdutoControlaLote]);

  const handleSelecionarItemEstoqueLocalEstoque = useCallback(
    async (limparLista?: boolean) => {
      selecionarItemEstoqueLocalEstoque(limparLista);
    },
    [selecionarItemEstoqueLocalEstoque]
  );

  const handleObterOrigemIcms = useCallback(async () => {
    try {
      const origemIcms = await ObterOrigemIcmsComunicador.index({
        params: {
          codigo: 0,
        },
      });
      if (origemIcms) {
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcms.origemIcms',
          origemIcms
        );
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe]);

  const handleObterValoresProdutoEngenharia = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    const produtoEngenharia = produto?.produtoEngenharia;

    const pesoBruto = produtoEngenharia?.pesoBruto;
    const pesoLiquido = produtoEngenharia?.pesoLiquido;
    const quantidadeVolumes =
      produtoEngenharia?.quantidadeVolumes === null &&
      parametros.ConsiderarQuantidadeVolumesUnitarioComo1
        ? 1
        : produtoEngenharia?.quantidadeVolumes;
    const metroQuadrado = produtoEngenharia?.metroQuadrado;
    const metroCubico = produtoEngenharia?.metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEngenharia.pesoBrutoUnitario',
      pesoBruto
    );

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEngenharia.pesoLiquidoUnitario',
      pesoLiquido
    );

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEngenharia.quantidadeVolumesUnitario',
      quantidadeVolumes
    );

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEngenharia.metroQuadradoUnitario',
      metroQuadrado
    );

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEngenharia.metroCubicoUnitario',
      metroCubico
    );
  }, [formRefDetalhe, parametros.ConsiderarQuantidadeVolumesUnitarioComo1]);

  return (
    <JanelaDetalhe
      titulo="Item"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <JanelaNavegacao
                containerStyle={
                  telaPequena
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                }
              />
            </Col>
          </Row>

          <FormCia ref={formRefDetalhe}>
            <InputHiddenHtml name="listaNotaFiscalEntradaItemCusto" />
            <InputHiddenHtml name="listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem" />

            <Divisor>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <InputAutoCompleteProdutoServico
                    ref={inputRefFocus}
                    label="Produto/Serviço"
                    name="idProduto"
                    placeholder="Produto/Serviço"
                    nomeObjeto="produto"
                    onChangeItemAtual={() => {
                      setListaLocalEstoque([]);
                    }}
                    onChangeItemAtualAposCarregarSemClear={(ev) => {
                      handleLimparConfiguracaoTributaria();
                      handleObterConfiguracaoTributariaConformeProduto();
                      handleSetControleEstoque();
                      handleSelecionarItemEstoqueLocalEstoque(true);
                      handleObterValoresProdutoEngenharia();

                      if (
                        ev.itemAtual &&
                        ev.itemAtual.tipoProdutoServico ===
                          TipoProdutoServicoEnum.servico
                      )
                        handleObterOrigemIcms();
                    }}
                    filtroProduto={!somenteServico}
                    filtroServico={somenteServico}
                    disabled={situacaoBloquearCampos}
                    permitirConsultar={false}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <InputAutoCompleteUnidadeMedida
                    label="Unidade de Medida"
                    name="idUnidadeMedida"
                    placeholder="Unidade de Medida"
                    nomeObjeto="unidadeMedida"
                    permitirAdicionar={false}
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <InputInteiro
                    label="Ordem"
                    name="ordem"
                    placeholder="Ordem"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Quantidade"
                    name="quantidade"
                    casasInteiras={11}
                    casasDecimais={4}
                    onChange={(event) => {
                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          await handleCalcularQuantidadeEstoque();
                          handleCalcularItemEngenhariaPesagem();
                          handleCalcularItemEngenhariaMetragem();

                          const valorUnitario =
                            formRefDetalhe.current?.getFieldValue(
                              'valorUnitario'
                            );

                          if (
                            event.target?.value?.ConverterParaNumber() !== 0 &&
                            valorUnitario > 0
                          ) {
                            await handleObterRegraEscolhaAliquota();
                          }

                          handleCalcularValorTotalProdutos();
                          handleCalcularQuantidadeTributadoProdutoTributado();
                          handleSelecionarItemEstoqueLocalEstoque();
                        },
                        170
                      );
                    }}
                    disabled={
                      situacaoBloquearCampos ||
                      (listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem &&
                        listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem.length !==
                          0)
                    }
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Unitário"
                    name="valorUnitario"
                    casasInteiras={11}
                    casasDecimais={10}
                    onChange={async (event) => {
                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          const quantidade =
                            formRefDetalhe.current?.getFieldValue('quantidade');

                          if (
                            event.target?.value?.ConverterParaNumber() !== 0 &&
                            quantidade > 0
                          ) {
                            await handleObterRegraEscolhaAliquota();
                          }

                          handleCalcularValorTotalProdutos();
                          handleCalcularValorUnitarioTributadoProdutoTributado();

                          for (let i = 0; i < listaLocalEstoque.length; i++) {
                            listaLocalEstoque[i].id = '';
                          }
                        },
                        170
                      );
                    }}
                    disabled={
                      situacaoBloquearCampos ||
                      (listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem &&
                        listaNotaFiscalEntradaItemImportacaoNotaFiscalSaidaItem.length !==
                          0)
                    }
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Subtotal (Qtde. * Val. Unit.)"
                    name="valorTotalProduto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                    ref={subtotalRef}
                    onChangeValue={(event, { valorAnteriorOnChange }) => {
                      if (
                        valorAnteriorOnChange.ConverterParaNumber() === 0 &&
                        event.valor > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }
                      handleCalcularRateioItem();
                      subtotalRef.current && subtotalRef.current.focus();
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Total Nota Fiscal Entrada"
                    name="valorTotalBruto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Input
                    label="Num. do Pedido de Compra"
                    name="pedidoCompraNumero"
                    placeholder="Num. do Pedido de Compra"
                    maxLength={15}
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputInteiroNulavel
                    label="Item do Pedido de Compra"
                    name="pedidoCompraNumeroItem"
                    placeholder="Item do Pedido de Compra"
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
              </Row>
            </Divisor>

            <Tabs
              id={v4()}
              defaultActiveKey="impostos"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="impostos" title="Impostos">
                <ItemImpostoTab />
              </Tab>

              <Tab eventKey="outrosValores" title="Outros Valores">
                <ItemOutrosValoresTab />
              </Tab>

              <Tab eventKey="estoques" title="Estoques">
                <ItemEstoqueTab formRefItem={formRefDetalhe} />
              </Tab>

              <Tab eventKey="informacoesAdicionais" title="Inf. Adicionais">
                <ItemInformacaoAdicionalTab />
              </Tab>

              <Tab eventKey="comercial" title="Comercial">
                <ItemComercialTab />
              </Tab>

              <Tab
                eventKey="declaracaoImportacao"
                title="Declaração Importação"
                tabClassName={uf === 'EX' ? '' : 'hidden'}
              >
                <ItemDeclaracaoImportacaoTab formRefItem={formRefDetalhe} />
              </Tab>

              {idImportacaoXmlAutorizacao && (
                <Tab
                  eventKey="informacoesXmlNFFornecedor"
                  title="Inf. do Xml da NF. Fornec."
                >
                  <ItemInformacaoXmlNFFornecedorTab />
                </Tab>
              )}

              <Tab eventKey="engenharia" title="Engenharia">
                <ItemEngenhariaTab />
              </Tab>

              <Tab eventKey="veiculo" title="Veículos Novos">
                <ItemVeiculoTab />
              </Tab>
            </Tabs>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer style={telaPequena ? { display: 'block' } : {}}>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
              id="voltar"
              style={
                telaPequena
                  ? {
                      width: '100%',
                      marginBottom: 5,
                    }
                  : {}
              }
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarENovo}
              id="salvarENovo"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAll />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar e Novo' : 'Adicionar e Novo'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarEContinuar}
              id="salvarEContinuar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvar}
              id="salvar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default NotaFiscalEntradaItemDetalhe;
