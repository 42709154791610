import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import {
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';
import { IConhecimentoTransporteCartaCorrecaoImpressaoPDF } from './ConhecimentoTransporteCartaCorrecaoImpressaoPDF';

const ConhecimentoTransporteCartaCorrecaoImpressaoCabecalhoPDF: React.FC<
  IConhecimentoTransporteCartaCorrecaoImpressaoPDF
> = ({ dados }) => {
  const canvasBarCode = document.createElement('canvas');

  JsBarcode(canvasBarCode, dados.chaveAcesso, {
    fontSize: 0,
    format: 'CODE128C',
  });

  const barCode = canvasBarCode.toDataURL();

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ width: '55%' }}>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{ flexDirection: 'column', height: '100%', width: '48%' }}
          >
            <View style={{ width: '100%', height: '50%', margin: '5px' }}>
              {dados.caminhoLogo && (
                <Image src={dados.caminhoLogo} style={{ borderRadius: 0 }} />
              )}
            </View>
            <View style={{ width: '100%', height: '25%', margin: '5px' }}>
              <View style={{ margin: '5px 0 0 5px', fontSize: 9 }}>
                <Text>{`CNPJ do Emitente: ${FormatarCnpjCpf(
                  dados.empresa.cnpjCpf
                )}`}</Text>
              </View>
            </View>

            <View
              style={{
                width: '100%',
                height: '25%',
                margin: '5px',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ margin: '5px 0 0 5px', fontSize: 9 }}>
                <Text>{`CNPJ / CPF do Destinatário: ${FormatarCnpjCpf(
                  dados.destinatario?.cnpjCpf
                )}`}</Text>
              </View>
            </View>
          </View>

          <View style={{ width: '2%' }} />

          <View
            style={{ flexDirection: 'column', height: '100%', width: '48%' }}
          >
            <View style={{ width: '100%', textAlign: 'center' }}>
              <View style={{ height: '3%' }} />
              <View style={{ height: '10%', fontSize: 12, fontWeight: 'bold' }}>
                <Text>DACCe</Text>
              </View>
              <View style={{ height: '1%' }} />
              <View style={{ height: '36%', fontSize: 12, fontWeight: 'bold' }}>
                <Text>Documento Auxiliar da Carta de Correção Eletrônica</Text>
              </View>

              <View
                style={{
                  height: '10%',
                  margin: '5px',
                  fontSize: 9,
                  fontWeight: 'bold',
                }}
              >
                <Text>{`Sequência da Correção: ${dados.numeroSequencialEvento}`}</Text>
              </View>

              <View
                style={{
                  height: '10%',
                  margin: '5px',
                  fontSize: 9,
                  fontWeight: 'bold',
                }}
              >
                <Text>{`Número da Nota: ${dados.conhecimentoTransporte.numero}`}</Text>
              </View>

              <View
                style={{
                  height: '10%',
                  margin: '5px',
                  fontSize: 9,
                  fontWeight: 'bold',
                }}
              >
                <Text>{`Série da Nota: ${dados.conhecimentoTransporte.serie.codigo}`}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ width: '45%' }}>
        <View style={{ flexDirection: 'column' }}>
          <View
            style={{
              width: '100%',
              height: '100%',
              border: '1 solid black',
              borderRadius: 5,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                height: '25%',
                border: '1 solid black',
                borderRadius: 5,
                margin: 2,
              }}
            >
              <View style={{ width: '80%', margin: 'auto' }}>
                <Image src={barCode} />
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                height: '25%',
                border: '1 solid black',
                borderRadius: 5,
                margin: 2,
              }}
            >
              <View style={{ width: '100%', margin: '5px' }}>
                <View style={{ fontSize: 6 }}>
                  <Text>CHAVE DE ACESSO DA CT-E:</Text>
                </View>
                <View
                  style={{ fontSize: 9, marginTop: '5px', fontWeight: 'bold' }}
                >
                  <Text>{FormatarChaveAcesso(dados.chaveAcesso)}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                height: '25%',
                border: '1 solid black',
                justifyContent: 'space-between',
                borderRadius: 5,
                margin: 2,
              }}
            >
              <View style={{ margin: '5px' }}>
                <View style={{ fontSize: 6 }}>
                  <Text>PROTOCOLO DO CT-E</Text>
                </View>
                <View
                  style={{ fontSize: 9, marginTop: '5px', fontWeight: 'bold' }}
                >
                  <Text>{dados.numeroProtocolo}</Text>
                </View>
              </View>

              <View style={{ margin: '5px' }}>
                <View style={{ fontSize: 6 }}>
                  <Text>DATA DO REGISTRO DO EVENTO</Text>
                </View>
                <View
                  style={{ fontSize: 9, marginTop: '5px', fontWeight: 'bold' }}
                >
                  <Text>
                    {FormatarDataHoraCompletaParaPtBr(dados.dataHoraRetorno)}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                height: '25%',
                border: '1 solid black',
                borderRadius: 5,
                margin: 2,
              }}
            >
              <View style={{ width: '100%', margin: '5px' }}>
                <View style={{ fontSize: 6 }}>
                  <Text>E-MAIL:</Text>
                </View>
                <View style={{ fontSize: 9, marginTop: '5px' }}>
                  <Text>{dados.empresa.email}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ConhecimentoTransporteCartaCorrecaoImpressaoCabecalhoPDF;
