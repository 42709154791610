import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-pedido-venda/curva-abc-pedido-venda-estado-por-valor';

interface ICurvaABCPedidoVendaEstadoPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCPedidoVendaEstadoPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCPedidoVendaEstadoPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCPedidoVendaEstadoPorValorRelatorioComunicador();
