/* eslint-disable default-case */
import {
  IContaCategoriaLista,
  IContaParcelaValoresInserir,
  IMovimentoPortadorCategoriaValoresInserir,
  RateioComCasaDecimalCalculo,
  TipoLancamentoEnum,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface IProps {
  listaContaCategoria: any[];
  listaContaParcela: IContaParcelaValoresInserir[];
}

interface IContaListaCategoriaContext {
  validador: boolean;

  valorTotalCategoria: number;
  categoriaValida: boolean;
  setCategoriaValida(value: boolean): void;
  setValorTotalCategoria(value: number): void;
  calcularValorTotalCategoria(lista: IContaCategoriaLista[]): Promise<number>;
  calcularValorCampoCategoriaParcela(lista: any[]): Promise<any>;
  calcularValorCampoParcelas(lista: any[]): any;
  calcularValorTotalCategoriaParaLiquidacao(
    item: any,
    valor: any,
    valoresCategoriaParcela: any
  ): Promise<number>;
  validarListaContaParcelaCategoria({
    listaContaCategoria,
    listaContaParcela,
  }: IProps): Promise<any>;
  validarCategoriasAvista({
    listaContaCategoria,
    listaContaParcela,
  }: IProps): Promise<any>;

  atualizarCategoriasParcelaLiquidacao({
    listaContaCategoria,
    listaContaParcela,
  }: IProps): Promise<any>;
  calcularRateioCategoriasNaoLiquidada(
    valor: any,
    listaMovimentoPortadorCategoria: IMovimentoPortadorCategoriaValoresInserir[]
  ): Promise<void>;
}

const ContaListaCategoriaContext = createContext<IContaListaCategoriaContext>(
  {} as IContaListaCategoriaContext
);

const ContaListaCategoriaHook: React.FC<IPadraoProps> = ({ children }) => {
  const [valorTotalCategoria, setValorTotalCategoria] = useState<number>(0);
  const [categoriaValida, setCategoriaValida] = useState<boolean>(false);

  const handleCalcularValorTotalCategoria = useCallback(
    async (listaValor: IContaCategoriaLista[]): Promise<number> => {
      const valorTotalCategorias = listaValor
        .reduce((acumulador, item) => {
          if (item.tipoLancamento[1] === '-') {
            acumulador -= Number(item.valor);
          } else {
            acumulador += Number(item.valor);
          }

          return acumulador;
        }, 0)
        .Arredondar();

      setValorTotalCategoria(valorTotalCategorias);
      return valorTotalCategorias;
    },
    []
  );

  const handleCalcularValorCampoCategorias = useCallback(
    async (listaContaCategoria: any[]): Promise<any> => {
      const retorno = {
        geral: 0,
        jurosSoma: 0,
        descontosSubtrai: 0,
        multaSoma: 0,
        outrasDespesasSoma: 0,
        outrasDespesasSubtrai: 0,
        despesasCartaoSubtrai: 0,
        despesasCartorioSoma: 0,
        despesasEnvioSoma: 0,
        despesasEnvioSubtrai: 0,
        comissoesPagasSoma: 0,
        comissoesPagasSubtrai: 0,
      } as any;

      listaContaCategoria.map(async (item: any) => {
        switch (item.tipoLancamento) {
          case TipoLancamentoEnum.geral:
            retorno.geral += Number(item.valor);
            break;

          case TipoLancamentoEnum.jurosSoma:
            retorno.jurosSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.descontosSubtrai:
            retorno.descontosSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.multaSoma:
            retorno.multaSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.outrasDespesasSoma:
            retorno.outrasDespesasSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.outrasDespesasSubtrai:
            retorno.outrasDespesasSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasCartaoSubtrai:
            retorno.despesasCartaoSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasCartorioSoma:
            retorno.despesasCartorioSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasEnvioSoma:
            retorno.despesasEnvioSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasEnvioSubtrai:
            retorno.despesasEnvioSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.comissoesPagasSoma:
            retorno.comissoesPagasSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.comissoesPagasSubtrai:
            retorno.comissoesPagasSubtrai += Number(item.valor);
            break;
        }
      });

      Object.keys(retorno).forEach((item) => {
        retorno[item] = retorno[item].Arredondar();
      });

      return retorno;
    },
    []
  );

  const handleCalcularValorCampoCategoriaParcela = useCallback(
    async (listaCategoriaParcela: any[]): Promise<any> => {
      const retorno = {
        geral: 0,
        jurosSoma: 0,
        descontosSubtrai: 0,
        multaSoma: 0,
        outrasDespesasSoma: 0,
        outrasDespesasSubtrai: 0,
        despesasCartaoSubtrai: 0,
        despesasCartorioSoma: 0,
        despesasEnvioSoma: 0,
        comissoesPagasSoma: 0,
      } as any;

      listaCategoriaParcela.map(async (item: any) => {
        switch (item.tipoLancamento) {
          case TipoLancamentoEnum.geral:
            retorno.geral += Number(item.valor);
            break;

          case TipoLancamentoEnum.jurosSoma:
            retorno.jurosSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.descontosSubtrai:
            retorno.descontosSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.multaSoma:
            retorno.multaSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.outrasDespesasSoma:
            retorno.outrasDespesasSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.outrasDespesasSubtrai:
            retorno.outrasDespesasSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasCartaoSubtrai:
            retorno.despesasCartaoSubtrai += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasCartorioSoma:
            retorno.despesasCartorioSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.despesasEnvioSoma:
            retorno.despesasEnvioSoma += Number(item.valor);
            break;

          case TipoLancamentoEnum.comissoesPagasSoma:
            retorno.comissoesPagasSoma += Number(item.valor);
            break;
        }
      });

      Object.keys(retorno).forEach((item) => {
        retorno[item] = retorno[item].Arredondar();
      });

      return retorno;
    },
    []
  );

  const handleCalcularValorCampoParcelas = useCallback(
    (listaParcela: any[]): Promise<any> => {
      const retorno = {
        valorParcela: 0,
        valorJurosSoma: 0,
        valorDescontoSubtrai: 0,
        valorMultaSoma: 0,
        valorOutrasDespesasSoma: 0,
        valorOutrasDespesasSubtrai: 0,
        valorDespesasCartaoSubtrai: 0,
        valorDespesasCartorioSoma: 0,
        valorDespesasEnvioSoma: 0,
        valorComissoesSoma: 0,
        valorDespesasEnvioSubtrai: 0,
        valorComissoesSubtrai: 0,
      } as any;

      listaParcela.map(async (item: any) => {
        retorno.valorParcela += Number(item.valorParcela);
        retorno.valorJurosSoma += Number(item.valorJurosSoma);
        retorno.valorDescontoSubtrai += Number(item.valorDescontoSubtrai);
        retorno.valorMultaSoma += Number(item.valorMultaSoma);
        retorno.valorOutrasDespesasSoma += Number(item.valorOutrasDespesasSoma);
        retorno.valorOutrasDespesasSubtrai += Number(
          item.valorOutrasDespesasSubtrai
        );
        retorno.valorDespesasCartaoSubtrai += Number(
          item.valorDespesasCartaoSubtrai
        );
        retorno.valorDespesasCartorioSoma += Number(
          item.valorDespesasCartorioSoma
        );
        retorno.valorDespesasEnvioSoma += Number(item.valorDespesasEnvioSoma);
        retorno.valorComissoesSoma += Number(item.valorComissoesSoma);
        retorno.valorDespesasEnvioSubtrai += Number(
          item.valorDespesasEnvioSubtrai
        );
        retorno.valorComissoesSubtrai += Number(item.valorComissoesSubtrai);
      });

      Object.keys(retorno).forEach((item) => {
        retorno[item] = retorno[item].Arredondar();
      });

      return retorno;
    },
    []
  );

  const handleCalcularValorTotalCategoriaParaLiquidacao = useCallback(
    async (
      categoriaParcela: any,
      liquidacao: any,
      valoresCategoriaParcela: any
    ): Promise<number> => {
      let valorCategoria = 0;
      switch (categoriaParcela.tipoLancamento) {
        case TipoLancamentoEnum.geral:
          valorCategoria =
            (Number(liquidacao.valorGeral) /
              Number(valoresCategoriaParcela.geral)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.jurosSoma:
          valorCategoria =
            (Number(liquidacao.valorJurosSoma) /
              Number(valoresCategoriaParcela.jurosSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.descontosSubtrai:
          valorCategoria =
            (Number(liquidacao.valorDescontoSubtrai) /
              Number(valoresCategoriaParcela.descontosSubtrai)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.multaSoma:
          valorCategoria =
            (Number(liquidacao.valorMultaSoma) /
              Number(valoresCategoriaParcela.multaSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.outrasDespesasSoma:
          valorCategoria =
            (Number(liquidacao.valorOutrasDespesasSoma) /
              Number(valoresCategoriaParcela.outrasDespesasSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.outrasDespesasSubtrai:
          valorCategoria =
            (Number(liquidacao.valorOutrasDespesasSubtrai) /
              Number(valoresCategoriaParcela.outrasDespesasSubtrai)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.despesasCartaoSubtrai:
          valorCategoria =
            (Number(liquidacao.valorDespesasCartaoSubtrai) /
              Number(valoresCategoriaParcela.despesasCartaoSubtrai)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.despesasCartorioSoma:
          valorCategoria =
            (Number(liquidacao.valorDespesasCartorioSoma) /
              Number(valoresCategoriaParcela.despesasCartorioSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.despesasEnvioSoma:
          valorCategoria =
            (Number(liquidacao.valorDespesasEnvioSoma) /
              Number(valoresCategoriaParcela.despesasEnvioSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.comissoesPagasSoma:
          valorCategoria =
            (Number(liquidacao.valorComissoesSoma) /
              Number(valoresCategoriaParcela.comissoesPagasSoma)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.despesasEnvioSubtrai:
          valorCategoria =
            (Number(liquidacao.valorDespesasEnvioSubtrai) /
              Number(valoresCategoriaParcela.despesasEnvioSubtrai)) *
            Number(categoriaParcela.valor);
          break;

        case TipoLancamentoEnum.comissoesPagasSubtrai:
          valorCategoria =
            (Number(liquidacao.valorComissoesSubtrai) /
              Number(valoresCategoriaParcela.comissoesPagasSubtrai)) *
            Number(categoriaParcela.valor);
          break;
      }

      return valorCategoria.Arredondar();
    },
    []
  );

  const handleValidarListaContaParcelaCategoria = useCallback(
    async ({
      listaContaCategoria,
      listaContaParcela,
    }: IProps): Promise<{
      listaMensagemErro: String[];
      categoriaValida: boolean;
    }> => {
      const listaMensagemErro: String[] = [];

      const valoresCategoria =
        await handleCalcularValorCampoCategorias(listaContaCategoria);
      const valoresParcela =
        await handleCalcularValorCampoParcelas(listaContaParcela);

      // #region Validar

      if (valoresParcela.valorParcela !== valoresCategoria.geral) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.geral
          } é R$${Number(
            valoresCategoria.geral
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorParcela
          ).FormatarParaPtBr()}`
        );
      }

      if (valoresParcela.valorJurosSoma !== valoresCategoria.jurosSoma) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.jurosSoma
          } é R$${Number(
            valoresCategoria.jurosSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorJurosSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorDescontoSubtrai !==
        valoresCategoria.descontosSubtrai
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.descontosSubtrai
          } é R$${Number(
            valoresCategoria.descontosSubtrai
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorDescontoSubtrai
          ).FormatarParaPtBr()}`
        );
      }

      if (valoresParcela.valorMultaSoma !== valoresCategoria.multaSoma) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.multaSoma
          } é R$${Number(
            valoresCategoria.multaSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorMultaSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorOutrasDespesasSoma !==
        valoresCategoria.outrasDespesasSoma
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.outrasDespesasSoma
          } é R$${Number(
            valoresCategoria.outrasDespesasSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorOutrasDespesasSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorOutrasDespesasSubtrai !==
        valoresCategoria.outrasDespesasSubtrai
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.outrasDespesasSubtrai
          } é R$${Number(
            valoresCategoria.outrasDespesasSubtrai
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorOutrasDespesasSubtrai
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorDespesasCartaoSubtrai !==
        valoresCategoria.despesasCartaoSubtrai
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.despesasCartaoSubtrai
          } é R$${Number(
            valoresCategoria.despesasCartaoSubtrai
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorDespesasCartaoSubtrai
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorDespesasCartorioSoma !==
        valoresCategoria.despesasCartorioSoma
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.despesasCartorioSoma
          } é R$${Number(
            valoresCategoria.despesasCartorioSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorDespesasCartorioSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorDespesasEnvioSoma !==
        valoresCategoria.despesasEnvioSoma
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.despesasEnvioSoma
          } é R$${Number(
            valoresCategoria.despesasEnvioSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorDespesasEnvioSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorComissoesSoma !==
        valoresCategoria.comissoesPagasSoma
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.comissoesPagasSoma
          } é R$${Number(
            valoresCategoria.comissoesPagasSoma
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorComissoesSoma
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorDespesasEnvioSubtrai !==
        valoresCategoria.despesasEnvioSubtrai
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.despesasEnvioSubtrai
          } é R$${Number(
            valoresCategoria.despesasEnvioSubtrai
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorDespesasEnvioSubtrai
          ).FormatarParaPtBr()}`
        );
      }

      if (
        valoresParcela.valorComissoesSubtrai !==
        valoresCategoria.comissoesPagasSubtrai
      ) {
        listaMensagemErro.push(
          `Soma dos Valores da Categoria com Tipo Lancamento ${
            TipoLancamentoEnum.comissoesPagasSubtrai
          } é R$${Number(
            valoresCategoria.comissoesPagasSubtrai
          ).FormatarParaPtBr()} e é Diferente da Soma dos Valores das Parcelas que é R$${Number(
            valoresParcela.valorComissoesSubtrai
          ).FormatarParaPtBr()}`
        );
      }

      // #endregion Validar

      if (listaMensagemErro.length > 0) {
        setCategoriaValida(false);
        return { listaMensagemErro, categoriaValida: false };
      }

      if (listaContaCategoria.length === 0) {
        setCategoriaValida(false);
        return { listaMensagemErro, categoriaValida: false };
      }

      setCategoriaValida(true);
      return { listaMensagemErro, categoriaValida: true };
    },
    [handleCalcularValorCampoCategorias, handleCalcularValorCampoParcelas]
  );

  const handleValidarCategoriasAvista = useCallback(
    async ({ listaContaParcela, listaContaCategoria }: IProps) => {
      const listaMensagemErro: string[] = [];

      for (let i = 0; i < listaContaCategoria.length; i++) {
        const contaCategoria = listaContaCategoria[i];
        const valorCategoria = Number(contaCategoria.valor).Arredondar();

        const listaValorParcelaCategoria: number[] = [];

        for (let j = 0; j < listaContaParcela.length; j++) {
          const contaParcela = listaContaParcela[j];

          if (contaParcela.listaContaParcelaCategoria) {
            const { listaContaParcelaCategoria } = contaParcela;

            for (let k = 0; k < listaContaParcelaCategoria.length; k++) {
              const contaParcelaCategoria = listaContaParcelaCategoria[k];

              if (
                contaCategoria.idCategoria ===
                  contaParcelaCategoria.idCategoria &&
                contaCategoria.tipoLancamento ===
                  contaParcelaCategoria.tipoLancamento &&
                contaCategoria.avista === contaParcela.avista
              ) {
                if (
                  contaCategoria.listaContaCategoriaFormaPagamento.listaValor
                    .length > 0 &&
                  contaParcelaCategoria.listaContaParcelaCategoriaFormaPagamento &&
                  contaParcelaCategoria.listaContaParcelaCategoriaFormaPagamento
                    ?.listaValor.length > 0 &&
                  contaCategoria.listaContaCategoriaFormaPagamento.listaValor
                    .map((e: any) => e.id)
                    .includes(contaParcela.idFormaPagamento)
                ) {
                  listaValorParcelaCategoria.push(
                    Number(contaParcelaCategoria.valor)
                  );
                } else if (
                  contaCategoria.listaContaCategoriaFormaPagamento.listaValor
                    .length === 0 &&
                  contaParcelaCategoria.listaContaParcelaCategoriaFormaPagamento
                    ?.listaValor.length === 0
                ) {
                  listaValorParcelaCategoria.push(
                    Number(contaParcelaCategoria.valor)
                  );
                }
              }
            }
          }
        }

        const somaValorTotalCategoriasParcela = listaValorParcelaCategoria
          .reduce((acc, valor) => {
            acc += Number(valor);
            return acc;
          }, 0)
          .Arredondar();

        if (somaValorTotalCategoriasParcela !== valorCategoria) {
          listaMensagemErro.push(
            `Os Valores da Categoria do Tipo ${contaCategoria.tipoLancamento} Divergem dos Valores das Parcelas!`
          );
        }
      }

      if (listaMensagemErro.length > 0) {
        setCategoriaValida(false);
        return { listaMensagemErro, categoriaValida: false };
      }

      if (listaContaCategoria.length === 0) {
        setCategoriaValida(false);
        return { listaMensagemErro, categoriaValida: false };
      }

      setCategoriaValida(true);
      return { listaMensagemErro, categoriaValida: true };
    },
    []
  );

  const handleObterListaValoresTipoLancamento = useCallback(
    async (
      listaMovimentoPortadorCategoria: any[],
      tipoLancamento: TipoLancamentoEnum
    ): Promise<any> => {
      const listaValores: number[] = [];

      for (let i = 0; i < listaMovimentoPortadorCategoria.length; i++) {
        const movimentoPortadorCategoria = listaMovimentoPortadorCategoria[i];

        if (movimentoPortadorCategoria.tipoLancamento === tipoLancamento) {
          listaValores.push(Number(movimentoPortadorCategoria.valor));
        }
      }

      return listaValores;
    },
    []
  );

  const handleCalcularRateioCategoriasNaoLiquidada = useCallback(
    async (
      valor: any,
      listaMovimentoPortadorCategoria: IMovimentoPortadorCategoriaValoresInserir[]
    ): Promise<void> => {
      // #region Lista Categorias

      const listaCategoriasGerais = await handleObterListaValoresTipoLancamento(
        listaMovimentoPortadorCategoria,
        TipoLancamentoEnum.geral
      );

      const listaCategoriasJuros = await handleObterListaValoresTipoLancamento(
        listaMovimentoPortadorCategoria,
        TipoLancamentoEnum.jurosSoma
      );

      const listaCategoriaDescontos =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.descontosSubtrai
        );

      const listaCategoriaMulta = await handleObterListaValoresTipoLancamento(
        listaMovimentoPortadorCategoria,
        TipoLancamentoEnum.multaSoma
      );

      const listaCategoriaOutrasDespesasSoma =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.outrasDespesasSoma
        );

      const listaCategoriaOutrasDespesasSubtrai =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.outrasDespesasSubtrai
        );

      const listaCategoriaDespesasCartao =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.despesasCartaoSubtrai
        );

      const listaCategoriaDespesasCartorio =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.despesasCartorioSoma
        );

      const listaCategoriaDespesasEnvioSoma =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.despesasEnvioSoma
        );

      const listaCategoriaComissoesPagasSoma =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.comissoesPagasSoma
        );

      const listaCategoriaDespesasEnvioSubtrai =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.despesasEnvioSubtrai
        );

      const listaCategoriaComissoesPagasSubtrai =
        await handleObterListaValoresTipoLancamento(
          listaMovimentoPortadorCategoria,
          TipoLancamentoEnum.comissoesPagasSubtrai
        );

      // #endregion Lista Categorias

      // #region Rateios

      if (listaCategoriasGerais.length > 0) {
        const listaRateioGeral = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorGeral) ?? 0,
          valores: listaCategoriasGerais,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) => item.tipoLancamento === TipoLancamentoEnum.geral
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioGeral[index];
        });
      }

      if (listaCategoriasJuros.length > 0) {
        const listaRateioJuros = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorJurosSoma) ?? 0,
          valores: listaCategoriasJuros,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) => item.tipoLancamento === TipoLancamentoEnum.jurosSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioJuros[index];
        });
      }

      if (listaCategoriaDescontos.length > 0) {
        const listaRateioDescontos = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorDescontoSubtrai) ?? 0,
          valores: listaCategoriaDescontos,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) => item.tipoLancamento === TipoLancamentoEnum.descontosSubtrai
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioDescontos[index];
        });
      }

      if (listaCategoriaMulta.length > 0) {
        const listaRateioMulta = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorMultaSoma) ?? 0,
          valores: listaCategoriaMulta,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) => item.tipoLancamento === TipoLancamentoEnum.multaSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioMulta[index];
        });
      }

      if (listaCategoriaOutrasDespesasSoma.length > 0) {
        const listaRateioOutrasDespesasSoma = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorOutrasDespesasSoma) ?? 0,
          valores: listaCategoriaOutrasDespesasSoma,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.outrasDespesasSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioOutrasDespesasSoma[index];
        });
      }

      if (listaCategoriaOutrasDespesasSubtrai.length > 0) {
        const listaRateioOutrasDespesasSubtrai = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorOutrasDespesasSubtrai) ?? 0,
          valores: listaCategoriaOutrasDespesasSubtrai,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.outrasDespesasSubtrai
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioOutrasDespesasSubtrai[index];
        });
      }

      if (listaCategoriaDespesasCartao.length > 0) {
        const listaRateioDespesasCartao = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorDespesasCartaoSubtrai) ?? 0,
          valores: listaCategoriaDespesasCartao,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.despesasCartaoSubtrai
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioDespesasCartao[index];
        });
      }

      if (listaCategoriaDespesasCartorio.length > 0) {
        const listaRateioDespesasCartorio = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorDespesasCartorioSoma) ?? 0,
          valores: listaCategoriaDespesasCartorio,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.despesasCartorioSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioDespesasCartorio[index];
        });
      }

      if (listaCategoriaDespesasEnvioSoma.length > 0) {
        const listaRateioDespesasEnvioSoma = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorDespesasEnvioSoma) ?? 0,
          valores: listaCategoriaDespesasEnvioSoma,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) => item.tipoLancamento === TipoLancamentoEnum.despesasEnvioSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioDespesasEnvioSoma[index];
        });
      }

      if (listaCategoriaComissoesPagasSoma.length > 0) {
        const listaRateioComissoesPagasSoma = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorComissoesSoma) ?? 0,
          valores: listaCategoriaComissoesPagasSoma,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.comissoesPagasSoma
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioComissoesPagasSoma[index];
        });
      }

      if (listaCategoriaDespesasEnvioSubtrai.length > 0) {
        const listaRateioDespesasEnvioSubtrai = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorDespesasEnvioSubtrai) ?? 0,
          valores: listaCategoriaDespesasEnvioSubtrai,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.despesasEnvioSubtrai
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioDespesasEnvioSubtrai[index];
        });
      }

      if (listaCategoriaComissoesPagasSubtrai.length > 0) {
        const listaRateioComissoesPagasSubtrai = RateioComCasaDecimalCalculo({
          valor: Number(valor.valorComissoesSubtrai) ?? 0,
          valores: listaCategoriaComissoesPagasSubtrai,
          casasDecimais: 2,
        });

        const listaReferencia = listaMovimentoPortadorCategoria.filter(
          (item) =>
            item.tipoLancamento === TipoLancamentoEnum.comissoesPagasSubtrai
        );
        listaReferencia.forEach((element, index) => {
          element.valor = listaRateioComissoesPagasSubtrai[index];
        });
      }

      // #endregion Rateios
    },
    [handleObterListaValoresTipoLancamento]
  );

  const handleAtualizarCategoriasParcelaLiquidacao = useCallback(
    async ({
      listaContaCategoria,
      listaContaParcela,
    }: IProps): Promise<any> => {
      // #region Exclusão da Categoria

      for (let i = 0; i < listaContaParcela.length; i++) {
        const contaParcela = listaContaParcela[i];

        contaParcela.listaContaParcelaCategoria = [];

        if (contaParcela.listaContaParcelaLiquidacao) {
          for (
            let j = 0;
            j < contaParcela.listaContaParcelaLiquidacao.length;
            j++
          ) {
            const liquidacao = contaParcela.listaContaParcelaLiquidacao[j];

            liquidacao.listaMovimentoPortadorCategoria = [];
          }
        }
      }

      // #endregion Exclusão da Categoria

      // #region Inserção e Alteração da Categoria da Parcela

      const listaContaCategoriaComFormaPagamento = listaContaCategoria.filter(
        (contaCategoria) =>
          contaCategoria.listaContaCategoriaFormaPagamento &&
          contaCategoria.listaContaCategoriaFormaPagamento.listaValor &&
          contaCategoria.listaContaCategoriaFormaPagamento.listaValor.length > 0
      );

      const listaContaCategoriaSemFormaPagamento = listaContaCategoria.filter(
        (contaCategoria) =>
          !contaCategoria.listaContaCategoriaFormaPagamento ||
          !contaCategoria.listaContaCategoriaFormaPagamento.listaValor ||
          contaCategoria.listaContaCategoriaFormaPagamento.listaValor.length ===
            0
      );

      for (let i = 0; i < listaContaCategoriaComFormaPagamento.length; i++) {
        const contaCategoria = listaContaCategoriaComFormaPagamento[i];

        const listaIdFormaPagamento: string[] =
          contaCategoria.listaContaCategoriaFormaPagamento.listaValor.map(
            (e: any) => e.id
          );

        const listaContaParcelaComFormaPagamento = listaContaParcela.filter(
          (contaParcela) =>
            contaParcela.avista === contaCategoria.avista &&
            contaParcela.idFormaPagamento &&
            listaIdFormaPagamento.includes(contaParcela.idFormaPagamento)
        );

        // #region Calculo Valor Total

        const valorTotalGeral = listaContaParcelaComFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.geral
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorParcela) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalJurosSoma = listaContaParcelaComFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.jurosSoma
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorJurosSoma) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalDescontoSubtrai =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.descontosSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDescontoSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalMultaSoma = listaContaParcelaComFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.multaSoma
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorMultaSoma) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalOutrasDespesasSoma =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.outrasDespesasSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorOutrasDespesasSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalOutrasDespesasSubtrai =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.outrasDespesasSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorOutrasDespesasSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasCartaoSubtrai =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasCartaoSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasCartaoSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasCartorioSoma =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasCartorioSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasCartorioSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasEnvioSoma =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasEnvioSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasEnvioSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasEnvioSubtrai =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasEnvioSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasEnvioSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalComissoesPagasSoma =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.comissoesPagasSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorComissoesSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalComissoesPagasSubtrai =
          listaContaParcelaComFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.comissoesPagasSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorComissoesSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        // #endregion Calculo Valor Total

        for (let j = 0; j < listaContaParcelaComFormaPagamento.length; j++) {
          const contaParcela = listaContaParcelaComFormaPagamento[j];

          let valorCategoria = 0;
          switch (contaCategoria.tipoLancamento) {
            case TipoLancamentoEnum.geral:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.geral
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualGeral =
                  valorTotalGeral > 0
                    ? ((Number(contaParcela.valorParcela) - somaCategoria) *
                        100) /
                      valorTotalGeral
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualGeral) / 100;
              }
              break;

            case TipoLancamentoEnum.jurosSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.jurosSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualJurosSoma = valorTotalJurosSoma
                  ? ((Number(contaParcela.valorJurosSoma) - somaCategoria) *
                      100) /
                    valorTotalJurosSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualJurosSoma) / 100;
              }
              break;

            case TipoLancamentoEnum.descontosSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.descontosSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDescontoSubtrai = valorTotalDescontoSubtrai
                  ? ((Number(contaParcela.valorDescontoSubtrai) -
                      somaCategoria) *
                      100) /
                    valorTotalDescontoSubtrai
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualDescontoSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.multaSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.multaSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualMultaSoma = valorTotalMultaSoma
                  ? ((Number(contaParcela.valorMultaSoma) - somaCategoria) *
                      100) /
                    valorTotalMultaSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualMultaSoma) / 100;
              }
              break;

            case TipoLancamentoEnum.outrasDespesasSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.outrasDespesasSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualOutrasDespesasSoma =
                  valorTotalOutrasDespesasSoma
                    ? ((Number(contaParcela.valorOutrasDespesasSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalOutrasDespesasSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualOutrasDespesasSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.outrasDespesasSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.outrasDespesasSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualOutrasDespesasSubtrai =
                  valorTotalOutrasDespesasSubtrai
                    ? ((Number(contaParcela.valorOutrasDespesasSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalOutrasDespesasSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualOutrasDespesasSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasCartaoSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasCartaoSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasCartaoSubtrai =
                  valorTotalDespesasCartaoSubtrai
                    ? ((Number(contaParcela.valorDespesasCartaoSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasCartaoSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasCartaoSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasCartorioSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasCartorioSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasCartorioSoma =
                  valorTotalDespesasCartorioSoma
                    ? ((Number(contaParcela.valorDespesasCartorioSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasCartorioSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasCartorioSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasEnvioSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasEnvioSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasEnvioSoma = valorTotalDespesasEnvioSoma
                  ? ((Number(contaParcela.valorDespesasEnvioSoma) -
                      somaCategoria) *
                      100) /
                    valorTotalDespesasEnvioSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualDespesasEnvioSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasEnvioSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasEnvioSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasEnvioSubtrai =
                  valorTotalDespesasEnvioSubtrai
                    ? ((Number(contaParcela.valorDespesasEnvioSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasEnvioSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasEnvioSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.comissoesPagasSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.comissoesPagasSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualComissoesPagasSoma =
                  valorTotalComissoesPagasSoma
                    ? ((Number(contaParcela.valorComissoesSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalComissoesPagasSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualComissoesPagasSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.comissoesPagasSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.comissoesPagasSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualComissoesPagasSubtrai =
                  valorTotalComissoesPagasSubtrai
                    ? ((Number(contaParcela.valorComissoesSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalComissoesPagasSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualComissoesPagasSubtrai) /
                  100;
              }
              break;
          }

          if (valorCategoria > 0) {
            const listaInserir: any[] = [];
            for (
              let k = 0;
              k <
              contaCategoria.listaContaCategoriaFormaPagamento.listaValor
                .length;
              k++
            ) {
              const valor =
                contaCategoria.listaContaCategoriaFormaPagamento.listaValor[k];

              listaInserir.push({
                chave: valor.id,
                ordem: k,
                valor: {
                  ...valor,
                },
              });
            }

            const valoresContaParcelaCategoria = {
              ...contaCategoria,
              id: undefined,
              valor: valorCategoria,

              listaContaParcelaCategoriaFormaPagamento: {
                listaValor:
                  contaCategoria.listaContaCategoriaFormaPagamento.listaValor,
                listaAcao: {
                  listaInserir,
                  listaMover: [],
                  listaDeletar: [],
                },
              },
            };

            delete valoresContaParcelaCategoria.listaContaCategoriaFormaPagamento;
            delete valoresContaParcelaCategoria.percentualCategoria;

            if (!contaParcela.listaContaParcelaCategoria) {
              contaParcela.listaContaParcelaCategoria = [];
            }

            contaParcela.listaContaParcelaCategoria.push(
              valoresContaParcelaCategoria
            );
          }
        }
      }

      for (let i = 0; i < listaContaCategoriaSemFormaPagamento.length; i++) {
        const contaCategoria = listaContaCategoriaSemFormaPagamento[i];

        const listaContaParcelaSemFormaPagamento = listaContaParcela.filter(
          (contaParcela) => contaParcela.avista === contaCategoria.avista
        );

        // #region Calculo Valor Total

        const valorTotalGeral = listaContaParcelaSemFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.geral
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorParcela) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalJurosSoma = listaContaParcelaSemFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.jurosSoma
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorJurosSoma) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalDescontoSubtrai =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.descontosSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDescontoSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalMultaSoma = listaContaParcelaSemFormaPagamento.reduce(
          (acumulador, contaParcela) => {
            const somaCategoria =
              contaParcela.listaContaParcelaCategoria
                ?.filter(
                  (contaParcelaCategoria) =>
                    contaParcelaCategoria.tipoLancamento ===
                    TipoLancamentoEnum.multaSoma
                )
                .reduce((acc, contaParcelaCategoria) => {
                  acc += Number(contaParcelaCategoria.valor);
                  return acc;
                }, 0) ?? 0;

            acumulador += Number(contaParcela.valorMultaSoma) - somaCategoria;

            return acumulador;
          },
          0
        );

        const valorTotalOutrasDespesasSoma =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.outrasDespesasSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorOutrasDespesasSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalOutrasDespesasSubtrai =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.outrasDespesasSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorOutrasDespesasSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasCartaoSubtrai =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasCartaoSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasCartaoSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasCartorioSoma =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasCartorioSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasCartorioSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasEnvioSoma =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasEnvioSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasEnvioSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalDespesasEnvioSubtrai =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.despesasEnvioSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorDespesasEnvioSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalComissoesPagasSoma =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.comissoesPagasSoma
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorComissoesSoma) - somaCategoria;

              return acumulador;
            },
            0
          );

        const valorTotalComissoesPagasSubtrai =
          listaContaParcelaSemFormaPagamento.reduce(
            (acumulador, contaParcela) => {
              const somaCategoria =
                contaParcela.listaContaParcelaCategoria
                  ?.filter(
                    (contaParcelaCategoria) =>
                      contaParcelaCategoria.tipoLancamento ===
                      TipoLancamentoEnum.comissoesPagasSubtrai
                  )
                  .reduce((acc, contaParcelaCategoria) => {
                    acc += Number(contaParcelaCategoria.valor);
                    return acc;
                  }, 0) ?? 0;

              acumulador +=
                Number(contaParcela.valorComissoesSubtrai) - somaCategoria;

              return acumulador;
            },
            0
          );

        // #endregion Calculo Valor Total

        for (let j = 0; j < listaContaParcelaSemFormaPagamento.length; j++) {
          const contaParcela = listaContaParcelaSemFormaPagamento[j];

          let valorCategoria = 0;
          switch (contaCategoria.tipoLancamento) {
            case TipoLancamentoEnum.geral:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.geral
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualGeral =
                  valorTotalGeral > 0
                    ? ((Number(contaParcela.valorParcela) - somaCategoria) *
                        100) /
                      valorTotalGeral
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualGeral) / 100;
              }
              break;

            case TipoLancamentoEnum.jurosSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.jurosSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualJurosSoma = valorTotalJurosSoma
                  ? ((Number(contaParcela.valorJurosSoma) - somaCategoria) *
                      100) /
                    valorTotalJurosSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualJurosSoma) / 100;
              }
              break;

            case TipoLancamentoEnum.descontosSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.descontosSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDescontoSubtrai = valorTotalDescontoSubtrai
                  ? ((Number(contaParcela.valorDescontoSubtrai) -
                      somaCategoria) *
                      100) /
                    valorTotalDescontoSubtrai
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualDescontoSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.multaSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.multaSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualMultaSoma = valorTotalMultaSoma
                  ? ((Number(contaParcela.valorMultaSoma) - somaCategoria) *
                      100) /
                    valorTotalMultaSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualMultaSoma) / 100;
              }
              break;

            case TipoLancamentoEnum.outrasDespesasSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.outrasDespesasSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualOutrasDespesasSoma =
                  valorTotalOutrasDespesasSoma
                    ? ((Number(contaParcela.valorOutrasDespesasSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalOutrasDespesasSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualOutrasDespesasSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.outrasDespesasSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.outrasDespesasSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualOutrasDespesasSubtrai =
                  valorTotalOutrasDespesasSubtrai
                    ? ((Number(contaParcela.valorOutrasDespesasSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalOutrasDespesasSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualOutrasDespesasSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasCartaoSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasCartaoSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasCartaoSubtrai =
                  valorTotalDespesasCartaoSubtrai
                    ? ((Number(contaParcela.valorDespesasCartaoSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasCartaoSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasCartaoSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasCartorioSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasCartorioSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasCartorioSoma =
                  valorTotalDespesasCartorioSoma
                    ? ((Number(contaParcela.valorDespesasCartorioSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasCartorioSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasCartorioSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasEnvioSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasEnvioSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasEnvioSoma = valorTotalDespesasEnvioSoma
                  ? ((Number(contaParcela.valorDespesasEnvioSoma) -
                      somaCategoria) *
                      100) /
                    valorTotalDespesasEnvioSoma
                  : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) * percentualDespesasEnvioSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.despesasEnvioSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.despesasEnvioSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualDespesasEnvioSubtrai =
                  valorTotalDespesasEnvioSubtrai
                    ? ((Number(contaParcela.valorDespesasEnvioSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalDespesasEnvioSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualDespesasEnvioSubtrai) /
                  100;
              }
              break;

            case TipoLancamentoEnum.comissoesPagasSoma:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.comissoesPagasSoma
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualComissoesPagasSoma =
                  valorTotalComissoesPagasSoma
                    ? ((Number(contaParcela.valorComissoesSoma) -
                        somaCategoria) *
                        100) /
                      valorTotalComissoesPagasSoma
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualComissoesPagasSoma) /
                  100;
              }
              break;

            case TipoLancamentoEnum.comissoesPagasSubtrai:
              {
                const somaCategoria =
                  contaParcela.listaContaParcelaCategoria
                    ?.filter(
                      (contaParcelaCategoria) =>
                        contaParcelaCategoria.tipoLancamento ===
                        TipoLancamentoEnum.comissoesPagasSubtrai
                    )
                    .reduce((acc, contaParcelaCategoria) => {
                      acc += Number(contaParcelaCategoria.valor);
                      return acc;
                    }, 0) ?? 0;

                const percentualComissoesPagasSubtrai =
                  valorTotalComissoesPagasSubtrai
                    ? ((Number(contaParcela.valorComissoesSubtrai) -
                        somaCategoria) *
                        100) /
                      valorTotalComissoesPagasSubtrai
                    : 0;
                valorCategoria =
                  (Number(contaCategoria.valor) *
                    percentualComissoesPagasSubtrai) /
                  100;
              }
              break;
          }

          if (valorCategoria > 0) {
            const valoresContaParcelaCategoria = {
              ...contaCategoria,
              id: undefined,
              valor: valorCategoria,
              listaContaParcelaCategoriaFormaPagamento:
                contaCategoria.listaContaCategoriaFormaPagamento,
            };

            delete valoresContaParcelaCategoria.listaContaCategoriaFormaPagamento;
            delete valoresContaParcelaCategoria.percentualCategoria;

            if (!contaParcela.listaContaParcelaCategoria) {
              contaParcela.listaContaParcelaCategoria = [];
            }

            contaParcela.listaContaParcelaCategoria.push(
              valoresContaParcelaCategoria
            );
          }
        }
      }

      // #endregion Inserção e Alteração da Categoria da Parcela

      // #region Inserção e Alteração da Categoria da Liquidação

      for (let i = 0; i < listaContaParcela.length; i++) {
        const contaParcela = listaContaParcela[i];

        if (contaParcela.listaContaParcelaCategoria) {
          for (
            let j = 0;
            j < contaParcela.listaContaParcelaCategoria.length;
            j++
          ) {
            const contaParcelaCategoria =
              contaParcela.listaContaParcelaCategoria[j];

            if (contaParcela.listaContaParcelaLiquidacao) {
              for (
                let k = 0;
                k < contaParcela.listaContaParcelaLiquidacao.length;
                k++
              ) {
                const liquidacao = contaParcela.listaContaParcelaLiquidacao[k];

                let valorCategoria = 0;
                switch (contaParcelaCategoria.tipoLancamento) {
                  case TipoLancamentoEnum.geral:
                    {
                      const percentualGeral =
                        Number(contaParcela.valorParcela) > 0
                          ? (Number(liquidacao.valorGeral) * 100) /
                            Number(contaParcela.valorParcela)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualGeral) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.jurosSoma:
                    {
                      const percentualJurosSoma =
                        Number(contaParcela.valorJurosSoma) > 0
                          ? (Number(liquidacao.valorJurosSoma) * 100) /
                            Number(contaParcela.valorJurosSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualJurosSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.descontosSubtrai:
                    {
                      const percentualDescontoSubtrai =
                        Number(contaParcela.valorDescontoSubtrai) > 0
                          ? (Number(liquidacao.valorDescontoSubtrai) * 100) /
                            Number(contaParcela.valorDescontoSubtrai)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualDescontoSubtrai) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.multaSoma:
                    {
                      const percentualMultaSoma =
                        Number(contaParcela.valorMultaSoma) > 0
                          ? (Number(liquidacao.valorMultaSoma) * 100) /
                            Number(contaParcela.valorMultaSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualMultaSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.outrasDespesasSoma:
                    {
                      const percentualOutrasDespesasSoma =
                        Number(contaParcela.valorOutrasDespesasSoma) > 0
                          ? (Number(liquidacao.valorOutrasDespesasSoma) * 100) /
                            Number(contaParcela.valorOutrasDespesasSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualOutrasDespesasSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.outrasDespesasSubtrai:
                    {
                      const percentualOutrasDespesasSubtrai =
                        Number(contaParcela.valorOutrasDespesasSubtrai) > 0
                          ? (Number(liquidacao.valorOutrasDespesasSubtrai) *
                              100) /
                            Number(contaParcela.valorOutrasDespesasSubtrai)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualOutrasDespesasSubtrai) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.despesasCartaoSubtrai:
                    {
                      const percentualDespesasCartaoSubtrai =
                        Number(contaParcela.valorDespesasCartaoSubtrai) > 0
                          ? (Number(liquidacao.valorDespesasCartaoSubtrai) *
                              100) /
                            Number(contaParcela.valorDespesasCartaoSubtrai)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualDespesasCartaoSubtrai) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.despesasCartorioSoma:
                    {
                      const percentualDespesasCartorioSoma =
                        Number(contaParcela.valorDespesasCartorioSoma) > 0
                          ? (Number(liquidacao.valorDespesasCartorioSoma) *
                              100) /
                            Number(contaParcela.valorDespesasCartorioSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualDespesasCartorioSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.despesasEnvioSoma:
                    {
                      const percentualDespesasEnvioSoma =
                        Number(contaParcela.valorDespesasEnvioSoma) > 0
                          ? (Number(liquidacao.valorDespesasEnvioSoma) * 100) /
                            Number(contaParcela.valorDespesasEnvioSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualDespesasEnvioSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.despesasEnvioSubtrai:
                    {
                      const percentualDespesasEnvioSubtrai =
                        Number(contaParcela.valorDespesasEnvioSubtrai) > 0
                          ? (Number(liquidacao.valorDespesasEnvioSubtrai) *
                              100) /
                            Number(contaParcela.valorDespesasEnvioSubtrai)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualDespesasEnvioSubtrai) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.comissoesPagasSoma:
                    {
                      const percentualComissoesPagasSoma =
                        Number(contaParcela.valorComissoesSoma) > 0
                          ? (Number(liquidacao.valorComissoesSoma) * 100) /
                            Number(contaParcela.valorComissoesSoma)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualComissoesPagasSoma) /
                        100;
                    }
                    break;

                  case TipoLancamentoEnum.comissoesPagasSubtrai:
                    {
                      const percentualComissoesPagasSubtrai =
                        Number(contaParcela.valorComissoesSubtrai) > 0
                          ? (Number(liquidacao.valorComissoesSubtrai) * 100) /
                            Number(contaParcela.valorComissoesSubtrai)
                          : 0;

                      valorCategoria =
                        (Number(contaParcelaCategoria.valor) *
                          percentualComissoesPagasSubtrai) /
                        100;
                    }
                    break;
                }

                if (valorCategoria > 0) {
                  const valoresLiquidacaoCategoria:
                    | IMovimentoPortadorCategoriaValoresInserir
                    | any = {
                    ...contaParcelaCategoria,
                    id: undefined,
                    valor: valorCategoria,
                    percentualCategoria: contaParcelaCategoria
                      ? (valorCategoria / contaParcelaCategoria.valor) * 100
                      : 0,
                    listaMovimentoPortadorCategoriaFormaPagamento:
                      contaParcelaCategoria.listaContaParcelaCategoriaFormaPagamento ??
                      [],
                  };

                  delete valoresLiquidacaoCategoria.listaContaParcelaCategoriaFormaPagamento;

                  if (!liquidacao.listaMovimentoPortadorCategoria) {
                    liquidacao.listaMovimentoPortadorCategoria = [];
                  }

                  liquidacao.listaMovimentoPortadorCategoria.push(
                    valoresLiquidacaoCategoria
                  );
                }
              }
            }
          }
        }
      }

      // #endregion Inserção e Alteração da Categoria da Liquidação

      return listaContaParcela;
    },
    []
  );

  return (
    <ContaListaCategoriaContext.Provider
      value={{
        valorTotalCategoria,
        setValorTotalCategoria,
        categoriaValida,
        setCategoriaValida,
        calcularValorTotalCategoria: handleCalcularValorTotalCategoria,
        calcularValorCampoCategoriaParcela:
          handleCalcularValorCampoCategoriaParcela,
        calcularValorTotalCategoriaParaLiquidacao:
          handleCalcularValorTotalCategoriaParaLiquidacao,
        calcularValorCampoParcelas: handleCalcularValorCampoParcelas,

        calcularRateioCategoriasNaoLiquidada:
          handleCalcularRateioCategoriasNaoLiquidada,
        atualizarCategoriasParcelaLiquidacao:
          handleAtualizarCategoriasParcelaLiquidacao,
        validarListaContaParcelaCategoria:
          handleValidarListaContaParcelaCategoria,
        validarCategoriasAvista: handleValidarCategoriasAvista,
        validador: true,
      }}
    >
      {children}
    </ContaListaCategoriaContext.Provider>
  );
};

function UseContaListaCategoria(): Omit<
  IContaListaCategoriaContext,
  'validador'
> {
  const context = useContext(ContaListaCategoriaContext);

  if (!context.validador) {
    throw new Error(
      'UseContaCategoriaValorTotalLista deve ser usado com um ContaCategoriaListaHook'
    );
  }

  return context;
}

export { ContaListaCategoriaHook, UseContaListaCategoria };
