import { FinalidadeEmissaoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/obter-serie';

interface INotaFiscalEntradaObterSerieComunicadorShow {
  finalidadeEmissao: FinalidadeEmissaoEnum;
}

class NotaFiscalEntradaObterSerieComunicador {
  public async show(
    params: INotaFiscalEntradaObterSerieComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });

    return response.data;
  }
}

export default new NotaFiscalEntradaObterSerieComunicador();
