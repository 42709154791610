import React from 'react';
import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import BtnCloseButton from '../Buttons/BtnCloseButton';

interface IJanela {
  titulo: string;
  tamanho?: 'lg' | 'sm' | 'xl';
  children: any;
  onFecharFormModal: (() => void) | undefined;
  styleHeader?: React.CSSProperties;
  backdrop?: boolean | 'static';
  centralizado?: boolean;
}

const JanelaDetalhe: React.FC<IJanela> = ({
  children,
  titulo,
  tamanho = 'lg',
  styleHeader,
  onFecharFormModal,
  backdrop,
  centralizado,
}) => {
  return (
    <Modal
      centered={centralizado && centralizado}
      show
      onHide={onFecharFormModal}
      size={tamanho}
      backdrop={backdrop || 'static'}
    >
      <Modal.Header
        style={
          styleHeader || {
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }
        }
      >
        <Modal.Title>{titulo}</Modal.Title>
        {onFecharFormModal && (
          <BtnCloseButton
            type="button"
            onClick={onFecharFormModal}
            id="btnClose"
          >
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        )}
      </Modal.Header>
      {children}
    </Modal>
  );
};

export default JanelaDetalhe;
