/* eslint-disable import/extensions */
import axios from 'axios';
import { compareAsc, addHours } from 'date-fns';
import jwt from 'jsonwebtoken';
import ToastAviso from '../../Util/Toasts/ToastAviso';
import { Sleep } from '../../Componentes/Padrao/MenuPrincipal/Scripts';
import server from '../../config/server';

const api = axios.create({
  baseURL: server.server,
  // baseURL: 'http://localhost:3333',
  // baseURL: 'https://www.gestao.ciasistemas.com.br/gestao',
});

const apiRefresh = axios.create({
  baseURL: server.server,
  // baseURL: 'http://localhost:3333',
  // baseURL: 'https://www.gestao.ciasistemas.com.br/gestao',
});

function TokenExp(token: string): boolean {
  const { exp } = jwt.decode(token) as any;

  const tokenDate = new Date(exp * 1000);
  const dataAtual = new Date();
  if (compareAsc(tokenDate, dataAtual) > 0) {
    if (compareAsc(addHours(tokenDate, -1), dataAtual) <= 0) {
      return true;
    }
  }

  return false;
}

function TokenInvalido(token: string): boolean {
  const { exp } = jwt.decode(token) as any;

  const tokenDate = new Date(exp * 1000);
  const dataAtual = new Date();
  if (compareAsc(tokenDate, dataAtual) > 0) {
    return false;
  }

  return true;
}

apiRefresh.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem('@EloGestorle:token');

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

let renovacaoEmAndamento: any = false;

api.interceptors.request.use(async (config: any) => {
  let token = localStorage.getItem('@EloGestorle:token');

  if (renovacaoEmAndamento) {
    const response = await renovacaoEmAndamento;
    token = response.data.token;
  } else if (token) {
    if (TokenInvalido(token)) {
      localStorage.setItem('@EloGestorle:token', '');

      ToastAviso('Sessão Expirada!');

      await Sleep(3000);

      window.location.reload();
    } else if (TokenExp(token)) {
      let utcFront = new Date().toTimeString().slice(12, 17);
      utcFront = utcFront.Insert(3, ':');

      renovacaoEmAndamento = apiRefresh.put('/refresh-token', { utcFront });
      const response = await renovacaoEmAndamento;
      token = response.data.token;
      localStorage.setItem('@EloGestorle:token', token || '');
      renovacaoEmAndamento = false;
    }
  }

  config.headers.Authorization = `Bearer ${token}`;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  config.headers['client-timezone'] = timezone;

  return config;
});

export default api;
