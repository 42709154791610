"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var IndicadorTipoFreteEnum;
(function (IndicadorTipoFreteEnum) {
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["contratacaoFretePorContaRemetente"] = 0] = "contratacaoFretePorContaRemetente";
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["contratacaoFretePorContaDestinatario"] = 1] = "contratacaoFretePorContaDestinatario";
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["contratacaoPorContaTerceiros"] = 2] = "contratacaoPorContaTerceiros";
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["transportePropriosPorContaRemetente"] = 3] = "transportePropriosPorContaRemetente";
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["transporteProprioPorContaDestinatario"] = 4] = "transporteProprioPorContaDestinatario";
    IndicadorTipoFreteEnum[IndicadorTipoFreteEnum["semOcorrenciaTransporte"] = 9] = "semOcorrenciaTransporte";
})(IndicadorTipoFreteEnum || (IndicadorTipoFreteEnum = {}));
exports.default = IndicadorTipoFreteEnum;
