"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloDocumentoFiscalEnum;
(function (ModeloDocumentoFiscalEnum) {
    ModeloDocumentoFiscalEnum["NFe"] = "55";
    ModeloDocumentoFiscalEnum["CTe"] = "57";
    ModeloDocumentoFiscalEnum["MDFe"] = "58";
    ModeloDocumentoFiscalEnum["NFCe"] = "65";
})(ModeloDocumentoFiscalEnum || (ModeloDocumentoFiscalEnum = {}));
exports.default = ModeloDocumentoFiscalEnum;
