import { IPedidoCompraValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/obter-itens-calculo-rateios';

interface IObterPedidoCompraItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterPedidoCompraItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterPedidoCompraItensParaCalculoRateiosComunicadorIndex
  ): Promise<IPedidoCompraValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterPedidoCompraItensParaCalculoRateiosComunicador();
