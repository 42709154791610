/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Table, Modal, Button, Container } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { IOrcamentoItemDuplicarLista } from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { UltimaTr } from './styles';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import ItemDuplicar, { IItemDuplicarRef } from './ItemDuplicar';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import OrcamentoDuplicarComunicador from '../../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoDuplicarComunicador';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IOrcamentoDuplicarContext {
  abrirOrcamentoDuplicar(
    idRegistroDuplicar: string,
    listaParaDuplicar: IOrcamentoItemDuplicarLista[]
  ): Promise<string | null>;
  fecharOrcamentoDuplicar(): void;
}

const OrcamentoDuplicarContext = createContext<IOrcamentoDuplicarContext>(
  {} as IOrcamentoDuplicarContext
);

const OrcamentoDuplicarHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [loading, setLoading] = useState(false);

  const resolveRef = useRef<any>();

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const listaItemDuplicarRef = useRef<Array<IItemDuplicarRef | null>>([]);

  const [aberto, setAberto] = useState(false);
  const [listaValor, setListaValor] = useState<IOrcamentoItemDuplicarLista[]>(
    []
  );
  const [valorSubTotal, setValorSubTotal] = useState<number>(0);
  const [idDuplicar, setIdDuplicar] = useState<string>('');

  const handleObterListaValoresSelecionados = useCallback(() => {
    return listaItemDuplicarRef.current
      .map((item) => {
        return item?.handleObterValores();
      })
      .filter((item) => {
        return item?.selecionado;
      }) as IOrcamentoItemDuplicarLista[];
  }, []);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const valor = listaValor
      .filter((it) => it.selecionado)
      .reduce((acumulador: number, item: IOrcamentoItemDuplicarLista) => {
        acumulador += Number(item.valorTotalProduto) || 0;
        return acumulador;
      }, 0);
    setValorSubTotal(valor);
  }, [listaValor]);

  const handleSelecionarTodos = useCallback(
    (valor: boolean) => {
      listaItemDuplicarRef.current.forEach((item, index) => {
        item?.setSelecionado(valor);
        if (listaValor[index]) listaValor[index].selecionado = valor;
      });

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleAbrir = useCallback(
    async (
      idRegistroDuplicar: string,
      novaLista: IOrcamentoItemDuplicarLista[]
    ): Promise<any> => {
      setIdDuplicar(idRegistroDuplicar);
      setListaValor(novaLista);
      setAberto(true);

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    []
  );

  useEffect(() => {
    if (aberto) {
      selecionarTodosRef.current?.click();
    }
  }, [aberto, handleSelecionarTodos]);

  const handleFechar = useCallback(() => {
    setListaValor([]);
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSalvar = useCallback(async () => {
    try {
      setLoading(true);

      const data = handleObterListaValoresSelecionados();

      const response = await OrcamentoDuplicarComunicador.store({
        idRegistroParaDuplicar: idDuplicar,
        params: data,
      });

      resolveRef.current(response.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleObterListaValoresSelecionados, idDuplicar, redirecionar]);

  useEffect(() => {
    handleAtualizarValorSubTotal();
  }, [handleAtualizarValorSubTotal, listaValor]);

  return (
    <OrcamentoDuplicarContext.Provider
      value={{
        abrirOrcamentoDuplicar: handleAbrir,
        fecharOrcamentoDuplicar: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>Duplicar Itens</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: 500 }}>
          <Tabela style={{ overflow: 'auto', maxHeight: 400 }}>
            <Table striped bordered variant="light">
              <thead>
                <tr>
                  <th
                    className="lista-texto"
                    style={{
                      width: '5%',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    <input
                      type="checkbox"
                      ref={selecionarTodosRef}
                      onChange={(event) => {
                        handleSelecionarTodos(event.target.checked);
                      }}
                    />
                  </th>
                  <th className="lista-texto" style={{ width: '10%' }}>
                    <span>Código</span>
                  </th>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Descrição Produto/Serviço</span>
                  </th>
                  <th className="lista-texto" style={{ width: '8%' }}>
                    <span>UN</span>
                  </th>
                  <th className="lista-valor" style={{ width: '12%' }}>
                    <span>Qtde.</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Val. Unitário</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Sub. Total</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor &&
                  listaValor.map((item: IOrcamentoItemDuplicarLista, index) => {
                    return (
                      <ItemDuplicar
                        item={item}
                        key={item.id}
                        ref={(instance) => {
                          listaItemDuplicarRef.current[index] = instance;
                        }}
                        onKeyDownQuantidade={(e) => {
                          if (e.shiftKey && e.key === 'Tab') {
                            e.preventDefault();

                            let proximaLinha = index - 1;
                            if (proximaLinha < 0)
                              proximaLinha = listaValor.length - 1;

                            listaItemDuplicarRef.current[
                              proximaLinha
                            ]?.handleSetFocusQuantidade();
                          } else if (e.key === 'Enter' || e.key === 'Tab') {
                            e.preventDefault();

                            let proximaLinha = index + 1;
                            if (proximaLinha >= listaValor.length)
                              proximaLinha = 0;

                            listaItemDuplicarRef.current[
                              proximaLinha
                            ]?.handleSetFocusQuantidade();
                          }
                        }}
                        onChange={handleAtualizarValorSubTotal}
                      />
                    );
                  })}
              </tbody>

              <tfoot>
                <UltimaTr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th>
                    <div
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Valor Sub. Total</div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        {valorSubTotal.FormatarParaPtBr()}
                      </div>
                    </div>
                  </th>
                </UltimaTr>
              </tfoot>
            </Table>
          </Tabela>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleFechar}
                disabled={loading}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 16,
                  }}
                >
                  <TextoLoading loading={loading}>
                    <MdCancel />
                    <span style={{ marginLeft: 10 }}>Cancelar</span>
                  </TextoLoading>
                </span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSalvar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </OrcamentoDuplicarContext.Provider>
  );
};

function UseOrcamentoDuplicar(): IOrcamentoDuplicarContext {
  const context = useContext(OrcamentoDuplicarContext);

  if (!context) {
    throw new Error('UseOrcamento deve ser usado com um OrcamentoProvider');
  }

  return context;
}

export { OrcamentoDuplicarHook, UseOrcamentoDuplicar };
