import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/grupo-produto-auto-complete-tag';

interface IGrupoProdutoSisdevinAutoCompleteTagComunidacadorIndex {
  params: {
    texto: string;
    limite: number;
    offset: number;
    ativo: any;
    listaIdInicial?: string[];
    listaIdParaRemover?: string[];
  };
}

class GrupoProdutoSisdevinAutoCompleteTagComunicador {
  public async index(
    params: IGrupoProdutoSisdevinAutoCompleteTagComunidacadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new GrupoProdutoSisdevinAutoCompleteTagComunicador();
