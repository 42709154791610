import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IDiarioAuxiliarEmissaoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;
interface IPessoaGeralPDF {
  dados: IDiarioAuxiliarEmissaoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PessoaGeralPDF: React.FC<IPessoaGeralPDF> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroListarPagamentos && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'normal' }}>
                        {'Listar Pagamentos: '}
                      </Text>
                      {dados.cabecalho.filtroListarPagamentos ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroConsiderarAdiantamentos && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'normal' }}>
                        {'Considerar Adiantamentos: '}
                      </Text>
                      {dados.cabecalho.filtroConsiderarAdiantamentos
                        ? 'Sim'
                        : 'Não'}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroSomenteLancamentosComDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'normal' }}>
                        {'Somente Lançamentos com Documento: '}
                      </Text>
                      {dados.cabecalho.filtroSomenteLancamentosComDocumento
                        ? 'Sim'
                        : 'Não'}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroDataEmissaoInicial && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'normal' }}>
                        {'Data Emissão inicial: '}
                      </Text>
                      {FormatarDataParaPtBr(
                        dados.cabecalho.filtroDataEmissaoInicial
                      )}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroDataEmissaoFinal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'normal' }}>
                        {'Data Emissão Final: '}
                      </Text>
                      {FormatarDataParaPtBr(
                        dados.cabecalho.filtroDataEmissaoFinal
                      )}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          }

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'normal',
            }}
          >
            <View style={{ width: '12%' }}>
              <Text>Data</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '24%' }}>
              <Text>Cliente</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '10%',
              }}
            >
              <Text>Nº Doc.</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>Histórico</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text
                style={{
                  textAlign: 'right',
                }}
              >
                Valor Débito
              </Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text
                style={{
                  textAlign: 'right',
                }}
              >
                Valor Crédito
              </Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text
                style={{
                  textAlign: 'right',
                }}
              >
                Saldo Geral
              </Text>
            </View>

            <View style={{ width: '1%' }} />
          </View>

          <View
            style={{
              flexDirection: 'column',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'normal',
              width: '100%',
            }}
          >
            <View
              style={{
                fontSize: 9,
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '82%' }} />

              <View
                style={{
                  width: '18%',
                  borderBottom: '1pt solid black',
                  fontWeight: 'bold',
                }}
              >
                <Text>
                  Saldo Inicial:{' '}
                  {dados.listaDados[0].listaDiarioAuxiliarItem[0].saldoInicial}
                </Text>
              </View>
            </View>
            {dados.listaDados.map((dado) => {
              return (
                <View
                  key={dado.chave}
                  style={{
                    width: '100%',
                  }}
                >
                  {dado.listaDiarioAuxiliarItem.map((item, index: number) => {
                    return (
                      <View
                        style={{
                          flexDirection: 'row',
                          backgroundColor:
                            index % 2 === 0 ? 'transparent' : 'lightgray',
                          fontSize: 9,
                        }}
                      >
                        <View style={{ width: '12%' }}>
                          <Text>{FormatarDataHoraParaPtBr(item.dataHora)}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '24%' }}>
                          <Text>{item.clienteFornecedor}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '10%' }}>
                          <Text>{item.numeroDocumento}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '7%' }}>
                          <Text>{item.historico}</Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '13%' }}>
                          <Text
                            style={{
                              textAlign: 'right',
                            }}
                          >
                            {item.valorDebito}
                          </Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '13%' }}>
                          <Text
                            style={{
                              textAlign: 'right',
                            }}
                          >
                            {item.valorCredito}
                          </Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '13%' }}>
                          <Text
                            style={{
                              textAlign: 'right',
                            }}
                          >
                            {item.saldoGeral}
                          </Text>
                        </View>

                        <View style={{ width: '1%' }} />
                      </View>
                    );
                  })}
                  <View
                    style={{
                      fontSize: 9,
                      width: '100%',
                      flexDirection: 'row',
                      borderTop: '1pt solid black',
                      borderBottom: '1pt solid black',
                      marginBottom: 15,
                      fontWeight: 'bold',
                    }}
                  >
                    <View style={{ width: '20%', flexDirection: 'row' }}>
                      <Text>Total do Dia: {dado.valores.chaveFormatada}</Text>
                    </View>
                    <View style={{ width: '29%' }} />
                    <View style={{ width: '21%' }}>
                      <Text
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {dado.valores.somatorioDiarioAuxiliarValorDebito}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '13%' }}>
                      <Text
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {dado.valores.somatorioDiarioAuxiliarValorCredito}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '13%' }}>
                      <Text
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {dado.valores.somatorioDiarioAuxiliarSaldoGeral}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
            <View
              style={{
                fontSize: 9,
                width: '100%',
                flexDirection: 'row',
                borderTop: '1pt solid black',
                borderBottom: '1pt solid black',
                fontWeight: 'bold',
              }}
            >
              <View style={{ width: '15%' }}>
                <Text>Total do Período:</Text>
              </View>

              <View style={{ width: '34%' }} />

              <View style={{ width: '21%' }}>
                <Text
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {dados.somatorioGeralValorDebito}
                </Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '13%' }}>
                <Text
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {dados.somatorioGeralValorCredito}
                </Text>
              </View>

              <View style={{ width: '1%' }} />

              <View style={{ width: '13%' }}>
                <Text
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {dados.somatorioGeralSaldoGeral}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PessoaGeralPDF;
