import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/validar-notas-utilizadas';

interface IConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicadorIndex {
  chaveAcesso: string;
}

class ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador {
  public async index(
    params: IConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });
    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador();
