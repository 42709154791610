import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/tabela-preco/regra-escolha-tabela-preco-auto-complete';

interface IRegraEscolhaTabelaPrecoAutoCompleteComunicadorIndex {
  params: any;
}

class RegraEscolhaTabelaPrecoAutoCompleteComunicador {
  public async index(
    params: IRegraEscolhaTabelaPrecoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaTabelaPrecoAutoCompleteComunicador();
