import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
  UseListaDetalheForm,
} from '../../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();

  const { formRefDetalhe } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          numeroAdicao: Yup.mixed().required('Número da Adição é Obrigatório'),
          numeroSequencialAdicao: Yup.mixed().required(
            'Seq. Item é Obrigatório'
          ),
          codigoFabricanteEstrangeiro: Yup.string().required(
            'Cód. Fabri. Estrang. é Obrigatório'
          ),
        });
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);

        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        const id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formRefDetalhe.current?.getFieldValue(
          'listaNotaFiscalEntradaItemDeclaracaoImportacaoAdicao'
        );

        formRefDetalhe.current?.setFieldValue(
          'listaNotaFiscalEntradaItemDeclaracaoImportacaoAdicao',
          [...listaDados, data]
        );

        await formRef?.reset();
        setLoading(false);
        formPrincipal.setLoading(false);
        formPrincipal.refresh();
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      formRefDetalhe,
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
    ]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja Remover o Item?</span>,
      });

      if (!resposta) return;

      setLoading(true);
      formPrincipal.setLoading(true);

      const listaDados = formRefDetalhe.current?.getFieldValue(
        'listaNotaFiscalEntradaItemDeclaracaoImportacaoAdicao'
      );
      listaDados.splice(Number(index), 1);

      formRefDetalhe.current?.setFieldValue(
        'listaNotaFiscalEntradaItemDeclaracaoImportacaoAdicao',
        listaDados
      );

      setLoading(false);
      formPrincipal.setLoading(false);
      formPrincipal.refresh();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
