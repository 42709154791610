import {
  FormatarEnum,
  SituacaoManifestoTransporteEnum,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoDocumentoManutencaoDocumentoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagPessoa from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import Input from '../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteTagTransportador from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagTransportador';
import InputAutoCompleteSerie from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import InputAutoCompleteTagServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagServico';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';

interface IPesquisaAvancadaProps {
  tipoDocumentoAtual: TipoDocumentoManutencaoDocumentoEnum;
  onFilter: () => void;
}

const PesquisaAvancada: React.FC<IPesquisaAvancadaProps> = ({
  tipoDocumentoAtual,
  onFilter,
}) => {
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();

  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const [selectTipoDocumentoValue, setSelectTipoDocumentoValue] =
    useState<TipoDocumentoManutencaoDocumentoEnum>(tipoDocumentoAtual);

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataEmissaoRef = useRef<any>(null);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();

    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    onFilter();
    handleFecharPesquisaAvancada();
  }, [handleFecharPesquisaAvancada, onFilter, setParametros]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const tipoDocumento = parametros.pesquisaAvancada?.tipoDocumento;
      if (tipoDocumento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Documento',
          valor: tipoDocumento,
        });
      }

      const situacaoManifestoTransporte =
        parametros.pesquisaAvancada?.situacaoManifestoTransporte;
      if (situacaoManifestoTransporte) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização - Manifesto Transporte',
          valor: situacaoManifestoTransporte,
        });
      }

      const situacaoNotaFiscalPropria =
        parametros.pesquisaAvancada?.situacaoNotaFiscalPropria;
      if (situacaoNotaFiscalPropria) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização - Nota Fiscal Própria',
          valor: situacaoNotaFiscalPropria,
        });
      }

      const situacaoConhecimentoTransporte =
        parametros.pesquisaAvancada?.situacaoConhecimentoTransporte;
      if (situacaoConhecimentoTransporte) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização - Conhecimento Transporte',
          valor: situacaoConhecimentoTransporte,
        });
      }

      const situacaoNotaFiscalConsumidorPropria =
        parametros.pesquisaAvancada?.situacaoNotaFiscalConsumidorPropria;
      if (situacaoNotaFiscalConsumidorPropria) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização - Nota Fiscal de Consumidor Própria',
          valor: situacaoNotaFiscalConsumidorPropria,
        });
      }

      const situacaoNotaFiscalTerceiros =
        parametros.pesquisaAvancada?.situacaoNotaFiscalTerceiros;
      if (situacaoNotaFiscalTerceiros) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização - Nota Fiscal de Terceiros',
          valor: situacaoNotaFiscalTerceiros,
        });
      }

      const listaPessoa = parametros.pesquisaAvancada?.listaPessoa;
      if (listaPessoa && listaPessoa.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pessoas',
          valor: listaPessoa.reduce((acc: string, item: any) => {
            acc += `${item.nomeRazaoSocialCodigo}; `;
            return acc;
          }, ''),
        });
      }

      const listaTransportadora =
        parametros.pesquisaAvancada?.listaTransportadora;
      if (listaTransportadora && listaTransportadora.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Transportadoras',
          valor: listaTransportadora.reduce((acc: string, item: any) => {
            acc += `${item.nomeRazaoSocialCodigo}; `;
            return acc;
          }, ''),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto && listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acc: string, item: any) => {
            acc += `${item.codigoDescricao}; `;
            return acc;
          }, ''),
        });
      }

      const chaveAcesso = parametros.pesquisaAvancada?.chaveAcesso;
      if (chaveAcesso) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Chave Acesso',
          valor: chaveAcesso,
        });
      }

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const serie = parametros.pesquisaAvancada?.serie;
      if (serie) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Série',
          valor: serie.codigoDescricao,
        });
      }

      if (periodoDataEmissaoRef.current) {
        const periodoDataEmissao = periodoDataEmissaoRef.current.getPeriodo();
        if (periodoDataEmissao) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Emissáo',
            valor: periodoDataEmissao,
          });
        }
      }

      const numeroInicial = parametros.pesquisaAvancada?.numeroInicial;
      if (numeroInicial) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Inicial',
          valor: numeroInicial,
        });
      }

      const numeroFinal = parametros.pesquisaAvancada?.numeroFinal;
      if (numeroFinal) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Final',
          valor: numeroFinal,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }

    setSelectTipoDocumentoValue(tipoDocumentoAtual);

    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      tipoDocumento: tipoDocumentoAtual,
    });
  }, [parametros.pesquisaAvancada, showPesquisaAvancada, tipoDocumentoAtual]);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="xl"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <Divisor>
            <Row>
              <Col lg={6} md={12}>
                <Select
                  name="tipoDocumento"
                  label="Tipo Documento"
                  options={FormatarEnum({
                    enumObj: TipoDocumentoManutencaoDocumentoEnum,
                  })}
                  onChange={(event) => {
                    const { value } = event.target;
                    const valorFormatado = StringConverterParaEnum<
                      typeof TipoDocumentoManutencaoDocumentoEnum,
                      TipoDocumentoManutencaoDocumentoEnum
                    >(TipoDocumentoManutencaoDocumentoEnum, value);

                    setSelectTipoDocumentoValue(valorFormatado);
                  }}
                />
              </Col>

              {selectTipoDocumentoValue ===
                TipoDocumentoManutencaoDocumentoEnum.manifestoTransporte && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoManifestoTransporte"
                    label="Situação Autorização - Manifesto Transporte"
                    options={FormatarEnum({
                      enumObj: SituacaoManifestoTransporteEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              {selectTipoDocumentoValue ===
                TipoDocumentoManutencaoDocumentoEnum.notaFiscalPropriaEntrada && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoNotaFiscalPropria"
                    label="Situação Autorização - Nota Fiscal Própria"
                    options={FormatarEnum({
                      enumObj: SituacaoManifestoTransporteEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              {selectTipoDocumentoValue ===
                TipoDocumentoManutencaoDocumentoEnum.conhecimentoTransporte && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoConhecimentoTransporte"
                    label="Situação Autorização - Conhecimento de Transporte"
                    options={FormatarEnum({
                      enumObj: SituacaoManifestoTransporteEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              {selectTipoDocumentoValue ===
                TipoDocumentoManutencaoDocumentoEnum.notaFiscalConsumidorPropria && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoNotaFiscalConsumidorPropria"
                    label="Situação Autorização - Nota Fiscal de Consumidor Própria"
                    options={FormatarEnum({
                      enumObj: SituacaoManifestoTransporteEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              {selectTipoDocumentoValue ===
                TipoDocumentoManutencaoDocumentoEnum.notaFiscalTerceiros && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoNotaFiscalTerceiros"
                    label="Situação Autorização - Nota Fiscal de Terceiros"
                    options={FormatarEnum({
                      enumObj: SituacaoManifestoTransporteEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}
            </Row>

            {!(
              selectTipoDocumentoValue ===
              TipoDocumentoManutencaoDocumentoEnum.manifestoTransporte
            ) && (
              <Row>
                <Col lg={6} md={12}>
                  <InputAutoCompleteTagPessoa
                    name="listaPessoa"
                    label="Pessoas"
                  />
                </Col>

                <Col
                  lg={6}
                  md={12}
                  hidden={
                    selectTipoDocumentoValue ===
                    TipoDocumentoManutencaoDocumentoEnum.conhecimentoTransporte
                  }
                >
                  <InputAutoCompleteTagTransportador
                    name="listaTransportadora"
                    label="Transportadoras"
                  />
                </Col>
              </Row>
            )}
          </Divisor>

          {!(
            selectTipoDocumentoValue ===
            TipoDocumentoManutencaoDocumentoEnum.manifestoTransporte
          ) && (
            <Divisor>
              <Row>
                <Col
                  lg={6}
                  md={12}
                  hidden={
                    selectTipoDocumentoValue ===
                    TipoDocumentoManutencaoDocumentoEnum.conhecimentoTransporte
                  }
                >
                  <InputAutoCompleteTagProduto
                    name="listaProduto"
                    label="Produtos"
                    onChangeListaItemAtual={handleChangeProduto}
                  />
                </Col>

                <Col
                  lg={6}
                  md={12}
                  hidden={
                    !(
                      selectTipoDocumentoValue ===
                      TipoDocumentoManutencaoDocumentoEnum.conhecimentoTransporte
                    )
                  }
                >
                  <InputAutoCompleteTagServico
                    name="listaServicos"
                    label="Serviços"
                    onChangeListaItemAtual={handleChangeProduto}
                  />
                </Col>

                <Col lg={6} md={12}>
                  <Select
                    label="Filtro Código/Descrição"
                    name="tipoBuscaCodigoDescricao"
                    options={FormatarEnum({
                      enumObj: TipoBuscaCodigoDescricaoEnum,
                      nullavel: false,
                      todos: false,
                    })}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12}>
                  <Input name="codigo" label="Código" placeholder="Código" />
                </Col>

                <Col lg={6} md={12}>
                  <Input
                    name="descricao"
                    label="Descrição"
                    placeholder="Descrição"
                  />
                </Col>
              </Row>
            </Divisor>
          )}

          <Divisor>
            <Row>
              <Col lg={6} md={12}>
                <Input
                  name="chaveAcesso"
                  label="Chave de Acesso"
                  placeholder="Chave de Acesso"
                />
              </Col>

              <Col lg={3} md={6}>
                <InputInteiroNulavel
                  name="numero"
                  label="Número"
                  placeholder="Número"
                />
              </Col>

              <Col lg={3} md={6}>
                <InputAutoCompleteSerie
                  name="serie"
                  label="Série"
                  placeholder="Série"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>

              <Col lg={3} md={6}>
                <InputInteiroNulavel
                  name="numeroInicial"
                  label="Número Inicial"
                  placeholder="Número Inicial"
                />
              </Col>

              <Col lg={3} md={6}>
                <InputInteiroNulavel
                  name="numeroFinal"
                  label="Número Final"
                  placeholder="Número Final"
                />
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
