import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/curva-abc-nota-fiscal-entrada/curva-abc-nota-fiscal-entrada-fornecedor-por-valor';

interface ICurvaABCNotaFiscalEntradaFornecedorPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalEntradaFornecedorPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalEntradaFornecedorPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalEntradaFornecedorPorValorRelatorioComunicador();
