import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/armazenar';

interface IImportacaoXmlTerceiroArmazenarComunicadorStore {
  params: any;
}

class ImportacaoXmlTerceiroArmazenarComunicador {
  public async store(
    params: IImportacaoXmlTerceiroArmazenarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroArmazenarComunicador();
