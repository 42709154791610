import {
  BooleanEnum,
  FormatarEnum,
  RegraEscolhaTipoEnum,
  IRegraEscolhaAliquotaValoresAlterar,
  ModalidadeBaseCalculoIcmsStEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTagNcm from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputAutoCompleteTagCest from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCest';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputCnpj from '../../../../../../Componentes/Inputs/InputCnpj';
import InputAutoCompleteTagEstado from '../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagCidade from '../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputAutoCompleteTagRegimeTributarioEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagRegimeTributarioEnum';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagModeloDocumento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputAutoCompleteTipoNota from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTagCodigoSituacaoTributariaIcms from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaIcms';

type IProps = IMain<IRegraEscolhaAliquotaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { FiscalTributacaoRegraEscolhaAliquota: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const { setDisableExcluir } = UseButtonsSubHeader();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [listaModeloDocumentoFiscal, setListaModeloDocumentoFiscal] = useState<
    ModeloDocumentoTipoEnum[]
  >([]);
  const regraEscolhaTipo = formRef.current?.getFieldValue('tipo');
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [listaTipoDocumentoFiscal, setListaTipoDocumentoFiscal] = useState<
    ModeloDocumentoTipoEnum[]
  >([]);

  useEffect(() => {
    if (tipoUsuario !== 'Elo Gestor' && regraEscolhaTipo === 'Padrão') {
      setDisableExcluir(regraEscolhaTipo);
    }
  }, [regraEscolhaTipo, setDisableExcluir, tipoUsuario]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  // Não Remover os await - são nescessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (formRef.current?.getFieldValue('idTipoNota')) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('idTipoNotaMotivo')) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaAliquotaRegimeTributarioEmitente'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaDestinado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaAliquotaEstadoEmpresaLogada'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaNcm').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaCest').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (formRef.current?.getFieldValue('contribuinte') !== null) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaProduto')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaEstado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaCidade')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaPessoa')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaAliquotaRamoAtividadeProfissao'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaAliquotaCstIcms')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                options={
                  (FormatarEnum({
                    enumObj: RegraEscolhaTipoEnum,
                  }),
                  [
                    {
                      title: 'Contabilidade',
                      value: RegraEscolhaTipoEnum.contabilidade,
                    },
                    { title: 'Empresa', value: RegraEscolhaTipoEnum.empresa },
                    {
                      title: 'Padrão',
                      value: RegraEscolhaTipoEnum.padrao,
                      hidden: tipoUsuario !== 'Elo Gestor',
                    },
                  ])
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputCnpj
                ref={inputRefFocus}
                label="CNPJ Contabilidade"
                name="cnpjContabilidade"
                placeholder="CNPJ Contabilidade"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaAliquotaEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={6} sm={12}>
              <Input
                label="Descrição"
                name="descricao"
                placeholder="Descrição"
                maxLength={100}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Contribuinte"
                name="contribuinte"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTipoNota
                label="Tipo de Nota"
                name="idTipoNota"
                placeholder="Tipo de Nota"
                nomeObjeto="tipoNota"
                onChangeItemAtual={() => {
                  formRef.current?.clearField('tipoNotaMotivo', {
                    setValorInicial: false,
                  });
                  refresh();
                }}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTipoNotaMotivo
                label="Motivo"
                name="idTipoNotaMotivo"
                placeholder="Motivo"
                nomeObjeto="tipoNotaMotivo"
                disabled={
                  !formRef.current?.getFieldValue('idTipoNota') ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                idTipoNota={formRef.current?.getFieldValue('idTipoNota')}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagCodigoSituacaoTributariaIcms
                label="CST do ICMS"
                name="listaRegraEscolhaAliquotaCstIcms"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                icms
                icmsSimplesNacional
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaAliquotaDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagRegimeTributarioEnum
                label="Regime Tributário"
                name="listaRegraEscolhaAliquotaRegimeTributarioEmitente"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagEstado
                label="Estados (UF) da Empresa Logada"
                name="listaRegraEscolhaAliquotaEstadoEmpresaLogada"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagNcm
                label="NCMs"
                name="listaRegraEscolhaAliquotaNcm"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagCest
                label="CESTs"
                name="listaRegraEscolhaAliquotaCest"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                name="listaRegraEscolhaAliquotaProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagEstado
                label="Estados"
                name="listaRegraEscolhaAliquotaEstado"
                onChangeListaItemAtual={({ listaItemAtual }) => {
                  handleChangeListaItemAtualEstado(listaItemAtual);
                }}
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagCidade
                label="Cidades"
                name="listaRegraEscolhaAliquotaCidade"
                listaIdEstado={listaIdEstado}
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <InputAutoCompleteTagModeloDocumento
                label="Modelos de Documento"
                name="listaRegraEscolhaAliquotaModeloDocumento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                onChangeListaItemAtual={({ listaItemAtual }) => {
                  setListaModeloDocumentoFiscal(() => {
                    if (!listaItemAtual?.length) return [];

                    return listaItemAtual.map((item: any) => {
                      if (item.valor) {
                        return item.valor.tipoDocumentoFiscal;
                      }
                      return item.tipoDocumentoFiscal;
                    });
                  });

                  const listaModeloDocumento: ModeloDocumentoTipoEnum[] = [];
                  if (
                    listaItemAtual?.some(
                      (item) =>
                        item.tipoDocumentoFiscal ===
                        ModeloDocumentoTipoEnum.conhecimentoTransporte
                    )
                  ) {
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.conhecimentoTransporte
                    );
                  } else if (
                    listaItemAtual?.some(
                      (item) =>
                        item.tipoDocumentoFiscal ===
                          ModeloDocumentoTipoEnum.notaFiscal ||
                        item.tipoDocumentoFiscal ===
                          ModeloDocumentoTipoEnum.cupomFiscal
                    )
                  ) {
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.notaFiscal
                    );
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.cupomFiscal
                    );
                  }

                  setListaTipoDocumentoFiscal(listaModeloDocumento);
                }}
                listaTipoDocumentoFiscal={listaTipoDocumentoFiscal}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <InputAutoCompleteTagPessoa
                label="Pessoas"
                name="listaRegraEscolhaAliquotaPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <InputAutoCompleteTagRamoAtividadeProfissao
                label="Ramo Atividade Profissão"
                name="listaRegraEscolhaAliquotaRamoAtividadeProfissao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <div style={{ marginTop: 30 }}>
            <h5>ICMS</h5>
            <Row>
              <Col lg={3} md={6} sm={12}>
                <InputDecimalNulavel
                  label="Perc. Red. Base Calc. ICMS"
                  name="regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcms"
                  placeholder="Perc. Red. Base Calc. ICMS"
                  casasInteiras={5}
                  casasDecimais={6}
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <InputDecimalNulavel
                  label="Perc. Diferimento ICMS"
                  name="regraEscolhaAliquotaResultado.percentualDiferimentoIcms"
                  placeholder="Perc. Diferimento ICMS"
                  casasInteiras={11}
                  casasDecimais={2}
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <InputDecimalNulavel
                  label="Alíquota ICMS"
                  name="regraEscolhaAliquotaResultado.aliquotaIcms"
                  placeholder="Alíquota ICMS"
                  casasInteiras={3}
                  casasDecimais={2}
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </Col>

              {!(
                listaModeloDocumentoFiscal.length === 1 &&
                listaModeloDocumentoFiscal[0] ===
                  ModeloDocumentoTipoEnum.conhecimentoTransporte
              ) && (
                <Col lg={3} md={6} sm={12}>
                  <InputDecimalNulavel
                    label="Alíquota FCP ICMS"
                    name="regraEscolhaAliquotaResultado.aliquotaFcpIcms"
                    placeholder="Alíquota FCP ICMS"
                    casasInteiras={5}
                    casasDecimais={2}
                    disabled={
                      regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor'
                    }
                  />
                </Col>
              )}
            </Row>
          </div>

          {!(
            listaModeloDocumentoFiscal.length === 1 &&
            listaModeloDocumentoFiscal[0] ===
              ModeloDocumentoTipoEnum.conhecimentoTransporte
          ) && (
            <div>
              <div style={{ marginTop: 25 }}>
                <h5>ICMS ST.</h5>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <Select
                      label="Modalidade Base Calculo ICMS ST."
                      name="regraEscolhaAliquotaResultado.modalidadeBaseCalculoIcmsSt"
                      options={FormatarEnum({
                        enumObj: ModalidadeBaseCalculoIcmsStEnum,
                        nullavel: true,
                      })}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota Interestadual ICMS ST."
                      name="regraEscolhaAliquotaResultado.aliquotaInterestadualIcmsSt"
                      placeholder="Alíquota Interestadual ICMS ST."
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota Interna ICMS ST."
                      name="regraEscolhaAliquotaResultado.aliquotaInternaIcmsSt"
                      placeholder="Alíquota Interna ICMS ST."
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="MVA ST."
                      name="regraEscolhaAliquotaResultado.mvaSubstituicaoTributaria"
                      placeholder="MVA ST."
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="MVA ST. SN."
                      name="regraEscolhaAliquotaResultado.mvaSubstituicaoTributariaSimplesNacional"
                      placeholder="MVA ST. SN."
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ alignItems: 'flex-end' }}>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Valor Unitário Pauta ICMS ST."
                      name="regraEscolhaAliquotaResultado.valorUnitarioPautaIcmsSt"
                      placeholder="Valor Unitário Pauta ICMS ST."
                      casasInteiras={8}
                      casasDecimais={10}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Perc. Red. Base Calc. ICMS ST. Regime Normal"
                      name="regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStRegimeNormal"
                      placeholder="Perc. Red. Base Calc. ICMS ST. Regime Normal"
                      casasInteiras={5}
                      casasDecimais={6}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Perc. Red. Base Calc. ICMS ST. SN."
                      name="regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStSimplesNacional"
                      placeholder="Perc. Red. Base Calc. ICMS ST. SN."
                      casasInteiras={5}
                      casasDecimais={6}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 25 }}>
                <h5>IPI</h5>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota IPI"
                      name="regraEscolhaAliquotaResultado.aliquotaIpi"
                      placeholder="Alíquota IPI"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 25 }}>
                <h5>PIS/COFINS</h5>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota PIS"
                      name="regraEscolhaAliquotaResultado.aliquotaPis"
                      placeholder="Alíquota PIS"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota COFINS"
                      name="regraEscolhaAliquotaResultado.aliquotaCofins"
                      placeholder="Alíquota COFINS"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 25 }}>
                <h5>PIS/COFINS Retido</h5>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota PIS Retido"
                      name="regraEscolhaAliquotaResultado.aliquotaPisRetido"
                      placeholder="Alíquota PIS Retido"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota COFINS Retido"
                      name="regraEscolhaAliquotaResultado.aliquotaCofinsRetido"
                      placeholder="Alíquota COFINS Retido"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 25 }}>
                <h5>IR Retido</h5>
                <Row>
                  <Col lg={4} md={6} sm={12}>
                    <InputDecimalNulavel
                      label="Alíquota do IR Retido"
                      name="regraEscolhaAliquotaResultado.aliquotaIrRetido"
                      placeholder="Alíquota do IR Retido"
                      casasInteiras={3}
                      casasDecimais={2}
                      disabled={
                        regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
