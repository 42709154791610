import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/aprovar';

interface IPedidoCompraAprovarComunicadorUpdate {
  id: string;
  params?: any;
}

class PedidoCompraAprovarComunicador {
  public async update(
    params: IPedidoCompraAprovarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new PedidoCompraAprovarComunicador();
