import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/categorias/obter-dados-padrao';

interface ICategoriaObterDadosPadraoComunicadorShow {
  idCategoriaPai?: string;
}

class CategoriaObterDadosPadraoComunicador {
  public async show(
    params?: ICategoriaObterDadosPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params?.idCategoriaPai || ''}`);
    return response.data;
  }
}

export default new CategoriaObterDadosPadraoComunicador();
