import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import CofinsTab from './COFINSTab';
import ICMSTab from './ICMSTab';
import IPITab from './IPITab';
import PISTab from './PISTab';

const LucroPresumidoTab: React.FC = () => {
  return (
    <div>
      <Tabs id={v4()} defaultActiveKey="icmsLucroPresumido">
        <Tab eventKey="icmsLucroPresumido" title="ICMS">
          <ICMSTab />
        </Tab>

        <Tab eventKey="ipiLucroPresumido" title="IPI">
          <IPITab />
        </Tab>

        <Tab eventKey="pisLucroPresumido" title="PIS">
          <PISTab />
        </Tab>

        <Tab eventKey="cofinsLucroPresumido" title="COFINS">
          <CofinsTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default LucroPresumidoTab;
