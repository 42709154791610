import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/impressao-etiquetas-pedido-venda/obter-informacoes-pedido-venda';

interface IImpressaoEtiquetasPedidoVendaObterInformacoesPedidoVendaComunicadorShow {
  params: any;
}

class ImpressaoEtiquetasPedidoVendaObterInformacoesPedidoVendaComunicador {
  public async index(
    params: IImpressaoEtiquetasPedidoVendaObterInformacoesPedidoVendaComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImpressaoEtiquetasPedidoVendaObterInformacoesPedidoVendaComunicador();
