import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-cst';

interface IRegraEscolhaCstComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaCstComunicadorShow {
  id: string;
}

interface IRegraEscolhaCstComunicadorStore {
  params: any;
}

interface IRegraEscolhaCstComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaCstComunicadorDeletar {
  id: string;
}

class RegraEscolhaCstComunicador {
  public async index(params: IRegraEscolhaCstComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IRegraEscolhaCstComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IRegraEscolhaCstComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IRegraEscolhaCstComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaCstComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaCstComunicador();
