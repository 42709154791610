/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import ListaDetalheVigenciaRegimeTributario from './ListaDetalheVigenciaImpostoCusto/Lista';
import EmpresaVigenciaImpostoCustoHook from './ListaDetalheVigenciaImpostoCusto/Hooks';

const TributacaoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12}>
            <EmpresaVigenciaImpostoCustoHook>
              <ListaDetalheVigenciaRegimeTributario name="listaEmpresaVigenciaImpostoCusto" />
            </EmpresaVigenciaImpostoCustoHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TributacaoTab;
