import React from 'react';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import ContaParcelaReversaoHook from './Hooks';
import ContaParcelaReversaoLista from './Lista';

const ContaParcelaReversaoListagem: React.FC = () => {
  return (
    <ListaProvider>
      <ContaParcelaReversaoHook>
        <ContaParcelaReversaoLista />
      </ContaParcelaReversaoHook>
    </ListaProvider>
  );
};

export default ContaParcelaReversaoListagem;
