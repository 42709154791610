import styled, { css } from 'styled-components';

interface ILoadingContainer {
  $isToggleJanela?: boolean;
}

export const LoadingContainer = styled.div<ILoadingContainer>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* margin-top: -16px;
  margin-left: -28px; */
  position: absolute;
  z-index: 999;
  background-color: #d3d3d3;
  opacity: 0.6;

  ${({ $isToggleJanela }) =>
    $isToggleJanela === false &&
    css`
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      min-height: calc(100vh - 66px) !important;
    `}

  .spinner {
    width: 100px;
    height: 100px;
    color: #3397ff;
  }
`;
