import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/estoques/estoque-saldo-proprio-terceiro';

interface IEstoqueSaldoProprioTerceiroRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoProprioTerceiroRelatorioComunicador {
  public async index(
    params: IEstoqueSaldoProprioTerceiroRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoProprioTerceiroRelatorioComunicador();
