import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = '/suprimentos/pesagens/item-avaliacao-pesagem/log';

interface IItemAvaliacaoPesagemLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IItemAvaliacaoPesagemLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ItemAvaliacaoPesagemLogComunicador {
  public async index(
    params: IItemAvaliacaoPesagemLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IItemAvaliacaoPesagemLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ItemAvaliacaoPesagemLogComunicador();
