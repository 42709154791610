import { IOrdemServicoRetratoSemFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrdemServicoRetratoSemFotoProdutoPDF from './OrdemServicoRetratoSemFotoProdutoImpressaoPDF';

interface IImpressaoOrdemServicoRetratoSemFotoProduto {
  handleVisualizarPdf(
    data: IOrdemServicoRetratoSemFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrdemServicoRetratoSemFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrdemServicoRetratoSemFotoProduto =
  (): IImpressaoOrdemServicoRetratoSemFotoProduto => {
    async function handleVisualizarPdf(
      dados: IOrdemServicoRetratoSemFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrdemServicoRetratoSemFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });
      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoRetratoSemFotoProduto();
