import React, { useEffect } from 'react';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import ITipoDados from '../../../Main/Interface/ITipoDados';

const SimNao: React.FC<ITipoDados> = ({ nome, valor, formRef }) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, String(valor) === 'true');
  }, [formRef, nome, valor]);

  return <InputSwitch ativo="Sim" inativo="Não" name={nome} />;
};

export default SimNao;
