import { IOrcamentoValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/obter-itens-aprovar-rejeitar';

interface IObterOrcamentoItensParaAprovarRejeitarComunicadorIndex {
  id: string;
}

class ObterOrcamentoItensParaAprovarRejeitarComunicador {
  public async index(
    params: IObterOrcamentoItensParaAprovarRejeitarComunicadorIndex
  ): Promise<IOrcamentoValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterOrcamentoItensParaAprovarRejeitarComunicador();
