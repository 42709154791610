import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/pedido-venda/pedido-venda-total-por-data-emissao';

interface IPedidoVendaTotalPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoVendaTotalPorDataEmissaoRelatorioComunicador {
  public async index(
    params: IPedidoVendaTotalPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoVendaTotalPorDataEmissaoRelatorioComunicador();
