import styled from 'styled-components';

export const FundoPreto = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000070;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 100;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  width: 95%;
  height: 95%;
  overflow: auto;

  border-radius: 10px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 15px;
  background: #005fff;

  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    padding: 0;
    margin: 0;
  }
`;

export const Body = styled.div`
  flex: 1;
  overflow: auto;

  padding: 20px;
`;
