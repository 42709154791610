import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-valor-unitario-regra-escolha-tabela-preco';

interface IFrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicadorIndex {
  params: {
    idRegraEscolhaTabelaPreco: string;
    idProduto: string;
  };
}

class FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador {
  public async show(
    params: IFrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador();
