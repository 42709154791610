import styled, { css } from 'styled-components';

interface IContainerProps {
  $navegacao: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  min-height: 35px;
  padding: 5px 0 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #36404a;
  color: #fff;
  font-size: 15px;
  text-align: left;
  overflow: hidden;
  :hover {
    background: #5f7b96;
  }

  ${({ $navegacao }) => {
    return (
      $navegacao &&
      css`
        background: #6c757d;
      `
    );
  }}
`;
