const CurvaABCNotaFiscalEntradaProdutoPorQuantidadePlanilha = [
  { titulo: 'Sequência', valor: 'sequencia' },
  { titulo: 'Código', valor: 'codigo' },
  { titulo: 'Descrição Produto', valor: 'descricaoProduto' },
  { titulo: 'Unidade', valor: 'unidadeMedida' },
  { titulo: 'Quantidade', valor: 'quantidade' },
  { titulo: 'Perc(%)', valor: 'percentual' },
  { titulo: 'Perc(%) Acum', valor: 'percentualAcumulado' },
];

export default CurvaABCNotaFiscalEntradaProdutoPorQuantidadePlanilha;
