import {
  BlocoHFormaGeracaoValoresEnum,
  RegistroH005MotivoInventario,
} from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  '/fiscal/documentos-eletronicos/geracao-de-arquivo-contabeis/sped-fiscal';

interface IGeracaoArquivosContabeisSpedFiscalComunicadorShow {
  params: {
    dataEmissaoInicial: Date;
    dataEmissaoFinal: Date;
    spedFiscal: boolean;
    spedFiscalCompleto: boolean;
    spedFiscalTodosProdutos: boolean;
    spedFiscalComBlocoH: boolean;

    spedFiscalMotivoInventario?: RegistroH005MotivoInventario;
    spedFiscalPeriodoBlocoH?: string;
    spedFiscalFormatoGeracaoValoresH20?: BlocoHFormaGeracaoValoresEnum;
  };
}

class GeracaoArquivosContabeisSpedFiscalComunicador {
  public async show(
    params: IGeracaoArquivosContabeisSpedFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new GeracaoArquivosContabeisSpedFiscalComunicador();
