import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { GeracaoEnvioBoletosListaHook } from './GeracaoEnvioBoletosListaHook';

const GeracaoEnvioBoletosHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <GeracaoEnvioBoletosListaHook>{children}</GeracaoEnvioBoletosListaHook>
  );
};

export default GeracaoEnvioBoletosHook;
