import { IEmpresaCrmSimplesValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { Button, Col, Row } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi/index.mjs';
import { BtnContainer } from './styles';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import EmpresaCrmSimplesComunicador from '../../../../../../Comunicador/Configuracao/Integracao/CrmSimples/Comunicador/EmpresaCrmSimplesComunicador';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TextoLoading from '../../../../../../Componentes/TextoLoading';

type IProps = IMain<IEmpresaCrmSimplesValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();

  const { ConfiguracaoIntegracaoEmpresaCrmSimples: permissao } = permissoes;

  const navigate = useNavigate();

  const rotas = UseRota();

  const {
    formRef,
    loading,
    inputRefFocus,
    refresh,
    handleSubmit,
    handleCarregarDados,
    setIdDetalheRegistro,
  } = UseForm();

  const token = formRef.current?.getFieldValue('token');
  const idEtapaNegociacao = formRef.current?.getFieldValue('idEtapaNegociacao');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosObrigatorios,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <Divisor>
        <Row>
          <Col xl={12} lg={12}>
            <Input
              ref={inputRefFocus}
              label="Token"
              name="token"
              required
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12}>
            <Input
              label="Id Etapa Negociação"
              name="idEtapaNegociacao"
              required
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <BtnContainer>
            <button
              type="button"
              onClick={handleClickSalvar}
              className="btn-padrao btn-verde"
              disabled={
                loading ||
                !permissao?.altera ||
                !permissao?.inclui ||
                !token ||
                !idEtapaNegociacao
              }
            >
              <TextoLoadingSalvar loading={loading} />
            </button>
          </BtnContainer>
        </Row>
      </Divisor>
    </FormCia>
  );
};

export default Main;
