import styled from 'styled-components';

export const LoadingContainer = styled.div`
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .spinner {
    border: 3px solid white;
    font-size: 8;
    border-radius: 50%;
    border-top-color: #0e8276;
    animation: spin 1s ease-in-out infinite;
  }
`;
