const NotaFiscalEntradaPorProdutoPlanilha = [
  {
    titulo: 'Codigo do Produto',
    valor: 'listaNotaFiscalEntradaItem.produtoCodigo',
  },

  {
    titulo: 'Nome do Produto',
    valor: 'listaNotaFiscalEntradaItem.produtoDescricao',
  },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaDataHoraEmissao',
  },

  {
    titulo: 'Numero do Orçamento',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaNumero',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaNotaFiscalEntradaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaNotaFiscalEntradaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaNotaFiscalEntradaItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaNotaFiscalEntradaItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaNotaFiscalEntradaItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaNotaFiscalEntradaItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalEntradaItem.valorTotal',
  },
];

export default NotaFiscalEntradaPorProdutoPlanilha;
