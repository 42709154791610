import { DropdownButton } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  label {
    margin: 0 0 0 5px;
  }
`;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  button::after {
    display: none;
  }
`;

export const UltimaTr = styled.tr`
  background-color: white;
  border-color: transparent;

  th,
  div {
    background-color: white;
  }
`;

interface IStepBox {
  $selecionado: boolean;
}

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepBox = styled.button<IStepBox>`
  height: 40px;
  width: 40px;
  background: white;
  border: 1px solid lightblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;

  ${({ $selecionado }) =>
    $selecionado &&
    css`
      background: lightblue;
    `}
`;

export const StepNome = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
`;

export const NfeInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-weight: bold;
    font-size: 16px;

    &:last-child {
      margin-top: 6px;
    }
  }
`;
