import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPesagemComProdutoresRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPesagemComProdutoresRelatorioPDF {
  dados: IPesagemComProdutoresRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PesagemComProdutoresRelatorioPDF: React.FC<
  IPesagemComProdutoresRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataInicial &&
                  dados.cabecalho.filtroDataFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataInicial} até ${dados.cabecalho.filtroDataFinal}`}
                      </Text>
                    </View>
                  )}
                {dados.cabecalho.filtroSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tipo: '}</Text>
                      {dados.cabecalho.filtroTipo}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cliente: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estado: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidade: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroGrupos && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupos}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroLocaisDeEstoque && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Locais De Estoque: '}
                      </Text>
                      {dados.cabecalho.filtroLocaisDeEstoque}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPessoaTerceiro && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Pessoa (Terceiro): '}
                      </Text>
                      {dados.cabecalho.filtroPessoaTerceiro}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '25%' }}>
              <Text>Produtor</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Referência</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '22%' }}>
              <Text>Descrição</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-start' }}>
              <Text>Un.Medida</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '9%', alignItems: 'flex-end' }}>
              <Text>P.Bruto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '9%', alignItems: 'flex-end' }}>
              <Text>P.Tara</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '9%', alignItems: 'flex-end' }}>
              <Text>P.Líquido</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                      }}
                    >
                      <View style={{ width: '8%' }}>
                        <Text>Número</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '10%' }}>
                        <Text>Data/Hora</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>Peso Bruto</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>Peso Tara</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>Peso Líquido</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        // backgroundColor:
                        //   dadoIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ width: '8%' }}>
                        <Text>{dado.numero}</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '10%' }}>
                        <Text>{dado.dataHoraEmissao}</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>{dado.pesoBruto}</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>{dado.pesoTara}</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                      <View style={{ width: '12%' }}>
                        <Text>{dado.pesoLiquido}</Text>
                      </View>
                      <View style={{ width: '1%' }} />
                    </View>

                    {dado.listaPesagemPessoa.map((item, index) => {
                      return (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontSize: 9,
                            backgroundColor:
                              index % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <View style={{ width: '5%', alignItems: 'flex-end' }}>
                            <Text>{dado.produto.codigo}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '25%' }}>
                            <Text>{item.pessoa.nomeRazaoSocial}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '6%', alignItems: 'flex-end' }}>
                            <Text>{dado.produto.codigo}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '22%' }}>
                            <Text>{dado.produto.descricao}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View
                            style={{ width: '7%', alignItems: 'flex-start' }}
                          >
                            <Text>
                              {dado.produto.produtoEstoque.unidadeMedida.sigla}
                            </Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '9%', alignItems: 'flex-end' }}>
                            <Text>{item.pesoBruto}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '9%', alignItems: 'flex-end' }}>
                            <Text>{item.pesoTara}</Text>
                          </View>
                          <View style={{ width: '1%' }} />

                          <View style={{ width: '9%', alignItems: 'flex-end' }}>
                            <Text>{item.pesoLiquido}</Text>
                          </View>
                          <View style={{ width: '1%' }} />
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
            }}
          >
            <View
              style={{
                width: '69%',
                alignItems: 'flex-start',
                fontSize: 10,
                fontWeight: 'bold',
              }}
            >
              <Text>Total Geral das Pesagens:</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '9%',
                alignItems: 'flex-end',
                fontSize: 10,
                fontWeight: 'bold',
              }}
            >
              <Text>{dados.totalPesoBruto}</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '9%',
                alignItems: 'flex-end',
                fontSize: 10,
                fontWeight: 'bold',
              }}
            >
              <Text>{dados.totalPesoTara}</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '9%',
                alignItems: 'flex-end',
                fontSize: 10,
                fontWeight: 'bold',
              }}
            >
              <Text>{dados.totalPesoLiquido}</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PesagemComProdutoresRelatorioPDF;
