import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { AtualizacaoPrecoCustoProdutoListaHook } from './AtualizacaoPrecoCustoProdutoListaHook';
import AtualizacaoPrecoCustoProdutoListaRecuperarHook from './AtualizacaoPrecoCustoProdutoListaRecuperarHook';

const AtualizacaoPrecoCustoProdutoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <AtualizacaoPrecoCustoProdutoListaHook>
      <AtualizacaoPrecoCustoProdutoListaRecuperarHook>
        {children}
      </AtualizacaoPrecoCustoProdutoListaRecuperarHook>
    </AtualizacaoPrecoCustoProdutoListaHook>
  );
};

export default AtualizacaoPrecoCustoProdutoHook;
