import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/obter-lista-boleto';

interface IConhecimentoTransporteObterDadosContaParcelaBoletoComunicadorShow {
  id: string;
}

class ConhecimentoTransporteObterDadosContaParcelaBoletoComunicador {
  public async show(
    params: IConhecimentoTransporteObterDadosContaParcelaBoletoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteObterDadosContaParcelaBoletoComunicador();
