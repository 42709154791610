"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = UnirListaRemoverRepetidos;
const lodash_1 = require("lodash");
function UnirListaRemoverRepetidos(getChave, primeiroArray, segundoArray, ...listaArray) {
    const arrayCompleto = (0, lodash_1.concat)(primeiroArray, segundoArray, ...listaArray);
    const set = new Set();
    const arraySemDuplicata = arrayCompleto.filter((item) => {
        const chave = getChave(item);
        const duplicate = set.has(chave);
        set.add(chave);
        return !duplicate;
    });
    return arraySemDuplicata;
}
