import { IPesagemA4AvaliacaoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPesagemA4AvaliacaoPDF from './PesagemA4AvaliacaoImpressaoPDF';

interface IImpressaoPesagem {
  handleVisualizarPdf(dados: IPesagemA4AvaliacaoImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: IPesagemA4AvaliacaoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPesagemA4Avaliacao = (): IImpressaoPesagem => {
  async function handleVisualizarPdf(
    dados: IPesagemA4AvaliacaoImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <ImpressaoPesagemA4AvaliacaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: IPesagemA4AvaliacaoImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <ImpressaoPesagemA4AvaliacaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return { handleVisualizarPdf, handleObterBlobPdf };
};

export default ImpressaoPesagemA4Avaliacao();
