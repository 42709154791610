import {
  IProdutoFiltroRelatorioBackend,
  IProdutoFiltroRelatorioFrontend,
  IProdutoPorCestCabecalhoRelatorio,
  IProdutoPorCestRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import ProdutoPorCestRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/Produtos/ProdutoPorCestRelatorio/ProdutoPorCestRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ProdutoPorCestPDF from './ProdutoPorCestPDF';
import ProdutoPorCestPlanilha from './ProdutoPorCestPlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IProdutoFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IProdutoFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IProdutoFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IProdutoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ProdutoPorCestRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IProdutoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IProdutoPorCestRelatorio | null> => {
    const filtroBack: IProdutoFiltroRelatorioBackend = {
      dataVigencia: filtros.dataVigencia,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
      ativo: filtros.ativo,
    };

    if (filtros?.listaMarca) {
      filtroBack.listaIdMarca =
        filtros.listaMarca.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaUnidadeMedida) {
      filtroBack.listaIdUnidadeMedida =
        filtros.listaUnidadeMedida.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaGrupoProdutoServico) {
      filtroBack.listaIdGrupoProdutoServico =
        filtros.listaGrupoProdutoServico.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaNcm) {
      filtroBack.listaIdNcm =
        filtros.listaNcm.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCest) {
      filtroBack.listaIdCest =
        filtros.listaCest.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaTipoSped) {
      filtroBack.listaIdTipoSped =
        filtros.listaTipoSped.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaLocalEstoque) {
      filtroBack.listaIdLocalEstoque =
        filtros.listaLocalEstoque.listaValor.map((value) => value.id) || [];
    }

    const response = await ProdutoPorCestRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    return response as IProdutoPorCestRelatorio;
  };

  function FormatarCabecalho(
    filtros: IProdutoFiltroRelatorioFrontend,
    cabecalho: IProdutoPorCestCabecalhoRelatorio
  ): IProdutoPorCestCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }

      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }

      if (filtros.ativo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroAtivo = String(filtros.ativo);
      }
    }

    if (filtros.listaMarca && filtros.listaMarca.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroMarca = filtros.listaMarca.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaUnidadeMedida &&
      filtros.listaUnidadeMedida.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroUnidadeMedida = filtros.listaUnidadeMedida.listaValor
        .map((valor: any) => {
          return valor.sigla;
        })
        .join(', ');
    }

    if (
      filtros.listaGrupoProdutoServico &&
      filtros.listaGrupoProdutoServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGrupoProdutoServico.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaNcm && filtros.listaNcm.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroNcm = filtros.listaNcm.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    }

    if (filtros.listaCest && filtros.listaCest.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCest = filtros.listaCest.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    }

    if (filtros.listaTipoSped && filtros.listaTipoSped.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTipoSped = filtros.listaTipoSped.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: ProdutoPorCestPlanilha,
      nomeArquivo: 'ProdutoPorCest',
      valores: dados.listaDados,
      unwind: ['listaProduto'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: ProdutoPorCestPlanilha,
      nomeArquivo: 'ProdutoPorCest',
      valores: dados.listaDados as any,
      unwind: ['listaProduto'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ProdutoPorCestPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IProdutoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ProdutoPorCestPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ProdutoPorCestRelatorio();
