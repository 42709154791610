import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch/index';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import { UseListaAtributoDraggable } from '../../Hooks/ListaAtributoDraggableHook';

interface IPadronizacaoDescricaoAtributoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const PadronizacaoDescricaoAtributoDetalhe: React.FC<
  IPadronizacaoDescricaoAtributoModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosPadronizacaoDescricao: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { listaValor } = UseListaAtributoDraggable();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    if (!idDetalheRegistro && data) {
      data.ordem = listaValor.length + 1;
    }

    const { erro } = await handleSubmit({ ...data }, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    formRefDetalhe,
    handleSubmit,
    idDetalheRegistro,
    listaValor.length,
    onSalvarFormModal,
  ]);

  useEffect(() => {
    handleCarregarDados();
    formRefDetalhe.current.setDataInicial({
      espacoAntesTextoAnterior: true,
      espacoDepoisTextoAnterior: true,
      espacoAntesTextoPosterior: true,
      espacoDepoisTextoPosterior: true,
      obrigatorio: true,
      variacao: false,
    });
  }, [formRefDetalhe, handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Padronização Descrição"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              <Divisor>
                <Row>
                  <Col lg={1} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Espaço"
                      name="espacoAntesTextoAnterior"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                  <Col lg={4} md={12} style={{ marginTop: 8 }}>
                    <Input
                      label="Texto Anterior"
                      name="textoAnterior"
                      placeholder="Texto Anterior"
                      maxLength={30}
                    />
                  </Col>
                  <Col lg={1} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Espaço"
                      name="espacoDepoisTextoAnterior"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                  <Col lg={5} md={12} style={{ marginTop: 8 }}>
                    <Input
                      ref={inputRefFocus}
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={60}
                    />
                  </Col>
                  <Col lg={1} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Espaço"
                      name="espacoAntesTextoPosterior"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                  <Col lg={4} md={12} style={{ marginTop: 8 }}>
                    <Input
                      label="Texto Posterior"
                      name="textoPosterior"
                      placeholder="Texto Posterior"
                      maxLength={30}
                    />
                  </Col>
                  <Col lg={1} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Espaço"
                      name="espacoDepoisTextoPosterior"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                  <Col lg={3} md={12} style={{ marginTop: 8 }}>
                    <InputInteiro
                      label="Tamanho"
                      name="tamanho"
                      placeholder="Tamanho"
                    />
                  </Col>
                  <Col lg={1} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Obrigatório"
                      name="obrigatorio"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={12} style={{ marginTop: 8, marginRight: 15 }}>
                    <InputSwitch
                      label="Variação Ecommerce"
                      name="variacao"
                      ativo="Sim"
                      inativo="Não"
                      largura={65}
                    />
                  </Col>
                </Row>
              </Divisor>
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PadronizacaoDescricaoAtributoDetalhe;
