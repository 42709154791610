import api from '../../../../Comum/Services/Api';

const rota = 'financeiro/boleto/consultar';

interface IBoletoConsultarComunicadorUpdate {
  params: { idBoleto: string };
}

class BoletoConsultarComunicador {
  public async update(params: IBoletoConsultarComunicadorUpdate): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new BoletoConsultarComunicador();
