import api from '../../../../Comum/Services/Api';

const rota =
  'comercial/comissao/regra-calculo-comissao/obter-conforme-criterios';

interface IRegraCalculoComissaoObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia?: Date | null;

    idPessoaRepresentante?: string | null;
    idPessoa?: string | null;
    idProduto?: string | null;
    idGrupoProdutoServico?: string | null;

    percentualDesconto?: number | null;
  };
}

class RegraCalculoComissaoObterConformeCriteriosComunicador {
  public async show(
    params: IRegraCalculoComissaoObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraCalculoComissaoObterConformeCriteriosComunicador();
