import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import GeracaoArquivosContabeisComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { SalvarPDFHook } from '../../../../../DocumentosEletronicos/SalvarPDF/SalvarPDFHook';

const GeracaoArquivosContabeisHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: GeracaoArquivosContabeisComunicador }}
      >
        <RotasHook>
          <FormHook>
            <SubHeaderHook>
              <SalvarPDFHook>{children}</SalvarPDFHook>
            </SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default GeracaoArquivosContabeisHook;
