import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import {
  DesestruturarChaveAcesso,
  IConhecimentoTransporteTerceiroNotaReferenciadaLista,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';

interface IListaDetalheNotaReferenciada {
  name: string;
  bloquearCamposPorImportacao: boolean;
}

const ListaDetalheNotaReferenciada: React.FC<IListaDetalheNotaReferenciada> = ({
  name,
  bloquearCamposPorImportacao,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<
    IConhecimentoTransporteTerceiroNotaReferenciadaLista[]
  >([]);
  const [listaValor, setListaValor] = useState<
    IConhecimentoTransporteTerceiroNotaReferenciadaLista[]
  >([]);

  const handleObterNumeroChaveAcesso = useCallback(
    (chaveAcesso: string): void => {
      try {
        if (!chaveAcesso) {
          formRefLista.current?.setFieldValue('numeroNota', null);
          return;
        }

        const informacoesChaveAcesso = DesestruturarChaveAcesso({
          chaveAcesso,
        });

        formRefLista.current?.setFieldValue(
          'numeroNota',
          informacoesChaveAcesso.numero
        );
      } catch (error) {
        ToastErro((error as any).listaMensagem);
        formRefLista.current?.setFieldValue('numeroNota', null);
      }
    },
    [formRefLista]
  );

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      IConhecimentoTransporteTerceiroNotaReferenciadaLista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col xl={6} lg={12}>
            <Input
              label="Chave de Acesso NF-e"
              placeholder="Chave de Acesso NF-e"
              name="chaveAcesso"
              maxLength={44}
              onBlur={(event) => {
                handleObterNumeroChaveAcesso(event.currentTarget.value);
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col xl={4} lg={6} md={12}>
            <InputInteiroNulavel
              label="Número"
              placeholder="Número"
              name="numeroNota"
              maxLength={0}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>

          <Col
            xl={2}
            lg={6}
            md={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              className="btn-padrao btn-verde btn-adicionar"
              onClick={handleAdicionar}
              disabled={
                !permissao?.inclui ||
                formPrincipal.loading ||
                bloquearCamposPorImportacao ||
                loading
              }
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '50%' }}>
                <span>Chave de Acesso</span>
              </th>
              <th className="lista-texto" style={{ width: '45%' }}>
                <span>Número</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr key={v4()}>
                  <td className="lista-texto">{item.chaveAcesso}</td>
                  <td className="lista-texto">{item.numeroNota}</td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={async () => {
                            await handleExcluir(item.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            bloquearCamposPorImportacao ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default ListaDetalheNotaReferenciada;
