const NotaFiscalEntradaPorFornecedorPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaDataHoraEmissao',
  },

  {
    titulo: 'Número do Orçamento',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaNotaFiscalEntradaItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaNotaFiscalEntradaItem.produtoDescricao',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaNotaFiscalEntradaItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaNotaFiscalEntradaItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaNotaFiscalEntradaItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaNotaFiscalEntradaItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalEntradaItem.valorTotal',
  },
];

export default NotaFiscalEntradaPorFornecedorPlanilha;
