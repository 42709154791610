/* eslint-disable react-hooks/exhaustive-deps */
import { IEmpresaVigenciaRegimeTributarioValoresAlterar } from '@elogestor/util';
import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import ListaDetalheVigenciaRegimeTributario from './ListaDetalheVigenciaRegimeTributario/Lista';
import ListaDetalheVigenciaAliquotaCreditoSn from './ListaDetalheVigenciaAliquotaCreditoSn/Lista';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import EmpresaVigenciaRegimeTributarioHook from './ListaDetalheVigenciaRegimeTributario/Hooks';
import EmpresaVigenciaAliquotaCreditoSnHook from './ListaDetalheVigenciaAliquotaCreditoSn/Hooks';

const TributacaoTab: React.FC = () => {
  const { formRef } = UseForm();

  const listaEmpresaVigenciaRegimeTributario: IEmpresaVigenciaRegimeTributarioValoresAlterar[] =
    formRef.current?.getFieldRef('listaEmpresaVigenciaRegimeTributario')
      .current || [];

  const esconderListaEmpresaVigenciaAliquotaCreditoSn = useMemo(
    () =>
      listaEmpresaVigenciaRegimeTributario.filter(
        (valor) =>
          valor.regimeTributario ===
          'Simples Nacional - Empresa de Pequeno Porte'
      ).length < 1,
    [listaEmpresaVigenciaRegimeTributario]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12}>
            <EmpresaVigenciaRegimeTributarioHook>
              <ListaDetalheVigenciaRegimeTributario name="listaEmpresaVigenciaRegimeTributario" />
            </EmpresaVigenciaRegimeTributarioHook>
          </Col>
        </Row>
      </Divisor>

      <Divisor
        className={
          esconderListaEmpresaVigenciaAliquotaCreditoSn ? 'hidden' : ''
        }
      >
        <Row>
          <Col lg={12} md={12}>
            <EmpresaVigenciaAliquotaCreditoSnHook>
              <ListaDetalheVigenciaAliquotaCreditoSn name="listaEmpresaVigenciaAliquotaCreditoSn" />
            </EmpresaVigenciaAliquotaCreditoSnHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TributacaoTab;
