import { IAtualizacaoPrecoCustoProdutoLista } from '@elogestor/util';
import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { UseRecuperarFormulario } from '../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { UseAtualizacaoPrecoCustoProdutoLista } from './AtualizacaoPrecoCustoProdutoListaHook';

const AtualizacaoPrecoCustoProdutoListaRecuperarHook: React.FC<
  IPadraoProps
> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { parametros } = UseTableDraggable();

  const {
    listaValor,
    listaItemSelecionadoRef,
    listaDescricaoRef,
    listaValorUnitarioVendaRef,
    listaAumentandoEmPercentualRef,
    listaAplicandoMarkupRef,
    listaDiminuindoEmPercentualRef,
    listaValorUnitarioBaseRef,
    listaValorUnitarioCustoReposicaoRef,
    listaCodigoBarrasRef,
    listaAtivoRef,
    listaNcmRef,
    listaCestRef,
  } = UseAtualizacaoPrecoCustoProdutoLista();
  const { paginacao } = UseLista();

  const handlePedirParaRecuperarFormulario = useCallback((): boolean => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    return itensSelecionados?.length !== 0;
  }, [listaItemSelecionadoRef]);

  const handleObterItens =
    useCallback((): IAtualizacaoPrecoCustoProdutoLista[] => {
      const listaItem: IAtualizacaoPrecoCustoProdutoLista[] = [];

      listaValor.forEach((valor, index) => {
        const retorno: IAtualizacaoPrecoCustoProdutoLista = {
          ...valor,
          selecionado:
            listaItemSelecionadoRef.current &&
            listaItemSelecionadoRef.current[index]
              ? Boolean(listaItemSelecionadoRef.current[index]?.checked)
              : false,
          descricao:
            listaDescricaoRef.current && listaDescricaoRef.current[index]
              ? listaDescricaoRef.current[index].value
              : '',
          ativo:
            listaAtivoRef.current && listaAtivoRef.current[index]
              ? Boolean(listaAtivoRef.current?.[index].checked)
              : false,

          produtoCusto: {
            ...valor.produtoCusto,
            valorUnitarioCustoReposicao:
              listaValorUnitarioCustoReposicaoRef.current &&
              listaValorUnitarioCustoReposicaoRef.current[index]
                ? listaValorUnitarioCustoReposicaoRef.current[
                    index
                  ].value.ConverterParaNumber()
                : 0,
          },

          produtoEngenharia: {
            ...valor.produtoEngenharia,
            codigoBarras:
              listaCodigoBarrasRef.current &&
              listaCodigoBarrasRef.current[index]
                ? listaCodigoBarrasRef.current?.[index].value
                : undefined,
          },

          produtoFiscal: {
            ...valor.produtoFiscal,
            ncm:
              listaNcmRef.current && listaNcmRef.current[index]
                ? listaNcmRef.current?.[index].getItemAtual()
                : null,

            cest:
              listaCestRef.current && listaCestRef.current[index]
                ? listaCestRef.current[
                    index
                  ].autoCompleteRef.current?.getItemAtual()
                : null,
          },

          produtoRegraEscolhaTabelaPreco: [],
        };

        if (valor.produtoRegraEscolhaTabelaPreco.length > 0) {
          retorno.produtoRegraEscolhaTabelaPreco.push({
            ...valor.produtoRegraEscolhaTabelaPreco[0],
            valorUnitarioVenda:
              listaValorUnitarioVendaRef.current &&
              listaValorUnitarioVendaRef.current[index]
                ? listaValorUnitarioVendaRef.current[
                    index
                  ].value.ConverterParaNumber()
                : 0,
            aumentandoEmPercentual:
              listaAumentandoEmPercentualRef.current &&
              listaAumentandoEmPercentualRef.current[index]
                ? listaAumentandoEmPercentualRef.current[
                    index
                  ].value.ConverterParaNumber()
                : undefined,
            aplicandoMarkup:
              listaAplicandoMarkupRef.current &&
              listaAplicandoMarkupRef.current[index]
                ? listaAplicandoMarkupRef.current[
                    index
                  ].value.ConverterParaNumber()
                : undefined,
            diminuindoEmPercentual:
              listaDiminuindoEmPercentualRef.current &&
              listaDiminuindoEmPercentualRef.current[index]
                ? listaDiminuindoEmPercentualRef.current[
                    index
                  ].value.ConverterParaNumber()
                : undefined,
            valorUnitarioBase:
              listaValorUnitarioBaseRef.current &&
              listaValorUnitarioBaseRef.current[index]
                ? listaValorUnitarioBaseRef.current[
                    index
                  ].value.ConverterParaNumber()
                : 0,
          });
        }

        listaItem.push(retorno);
      });

      return listaItem;
    }, [
      listaAplicandoMarkupRef,
      listaAtivoRef,
      listaAumentandoEmPercentualRef,
      listaCestRef,
      listaCodigoBarrasRef,
      listaDescricaoRef,
      listaDiminuindoEmPercentualRef,
      listaItemSelecionadoRef,
      listaNcmRef,
      listaValor,
      listaValorUnitarioBaseRef,
      listaValorUnitarioCustoReposicaoRef,
      listaValorUnitarioVendaRef,
    ]);

  useLayoutEffect(() => {
    async function ExecutaHandleChange(): Promise<void> {
      const listaItem = handleObterItens();

      if (handlePedirParaRecuperarFormulario()) {
        const resposta = await abrirRecuperarFormulario({});
        if (resposta) {
          navigate(pathname, {
            state: {
              dadosRecuperados: {
                listaDados: listaItem,
                totalPaginas: paginacao.totalPaginas,
                limite: parametros.limite,
                pagina: parametros.pagina,
              },
            },
          });
        }
      }
    }

    return () => {
      ExecutaHandleChange();
    };
  }, [
    abrirRecuperarFormulario,
    handleObterItens,
    handlePedirParaRecuperarFormulario,
    navigate,
    listaItemSelecionadoRef,
    listaValor,
    paginacao.totalPaginas,
    parametros.limite,
    parametros.pagina,
    pathname,
    state,
  ]);

  return <div>{children}</div>;
};

export default AtualizacaoPrecoCustoProdutoListaRecuperarHook;
