import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/cancelamento-carta-correcao/upload-pdf';

interface IImportacaoXmlCancelamentoCartaCorrecaoUploadPdfComunicadorUpdate {
  params: any;
}

class ImportacaoXmlCancelamentoCartaCorrecaoUploadPdfComunicador {
  public async update(
    params: IImportacaoXmlCancelamentoCartaCorrecaoUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlCancelamentoCartaCorrecaoUploadPdfComunicador();
