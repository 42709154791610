import {
  EstoqueIndicadorTipoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/estoque/local-estoque/obter-por-estoque-combinacao';

interface ILocalEstoqueObterPorEstoqueCombinacaComunicadorIndex {
  idPessoa: string;
  idProduto: string;
  indicadorTipoEstoque: EstoqueIndicadorTipoEnum;
  dataHoraEmissao: Date;
  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum;
}

class LocalEstoqueObterPorEstoqueCombinacaComunicador {
  public async index(
    params: ILocalEstoqueObterPorEstoqueCombinacaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new LocalEstoqueObterPorEstoqueCombinacaComunicador();
