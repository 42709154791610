/* eslint-disable no-alert */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
  FormatarEnum,
  IManutencaoDocumentosFiscaisLista,
  SituacaoConhecimentoTransporteEnum,
  SituacaoManifestoTransporteEnum,
  SituacaoNotaFiscalEntradaEnum,
  SituacaoNotaFiscalSaidaEnum,
  StringConverterParaEnum,
  TipoDocumentoManutencaoDocumentoEnum,
} from '@elogestor/util';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import Divisor from '../../../../../Componentes/Divisor';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ManutencaoDocumentosFiscaisComunicador from '../../../../../Comunicador/Fiscal/DocumentosEletronicos/ManutencaoDocumentosFiscais/Comunicador/ManutencaoDocumentosFiscaisComunicador';
import InutilizacaoNumeracaoLogComunicador from '../../../../../Comunicador/Fiscal/DocumentosEletronicos/InutilizacaoNumeracao/Log/Comunicador/InutilizacaoNumeracaoLogComunicador';
import Select from '../../../../../Componentes/Select';
import AcoesManutencaoDocumentosFiscais from './AcoesManutencaoDocumentosFiscais';

export interface IListaItens extends IManutencaoDocumentosFiscaisLista {
  checked: boolean;
}

interface IHandleUpdateItem {
  selecionarTodos?: boolean;
  selecionarTodosValue?: boolean;
  itemId?: string;
}

const ManutencaoDocumentosFiscaisLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState(
    TipoDocumentoManutencaoDocumentoEnum.manifestoTransporte
  );
  const [showTipoDocumento, setShowTipoDocumento] = useState(true);
  const [listaItens, setListaItens] = useState<IListaItens[]>([]);
  const [, setListaColunas] = useState<ITh[]>([]);

  const formRef = useRef<IFormCiaHandles>(null);
  const listaItensRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleUpdateItem = useCallback(
    ({ selecionarTodos, selecionarTodosValue, itemId }: IHandleUpdateItem) => {
      setListaItens((prevState) => {
        if (selecionarTodos && typeof selecionarTodosValue === 'boolean') {
          listaItensRef.current?.forEach((item) => {
            if (item) {
              item.checked = selecionarTodosValue;
            }
          });

          return prevState.map((item) => {
            return { ...item, checked: selecionarTodosValue };
          });
        }

        const lista = prevState.filter((item) => item.id !== itemId);
        const data = prevState.find((item) => item.id === itemId);
        if (!data) return prevState;

        const value = selecionarTodos ?? data.checked;

        return [...lista, { ...data, checked: !value }];
      });
    },
    []
  );

  const handleObterSelecionados = useCallback((): IListaItens[] => {
    return listaItens.filter((item) => item.checked === true);
  }, [listaItens]);

  const handleSelecionarTodos = useCallback(
    (value: boolean) => {
      handleUpdateItem({ selecionarTodos: true, selecionarTodosValue: value });
    },
    [handleUpdateItem]
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ManutencaoDocumentosFiscaisComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
            tipoDocumento,
          },
        });

        const obterColorPorSituacao = (
          data: IManutencaoDocumentosFiscaisLista
        ): string => {
          const {
            manifestoTransporte,
            conhecimentoTransporte,
            notaFiscalPropriaTerceiro,
            notaFiscalConsumidorPropria,
            notaFiscalSaidaPropria,
          } = data;

          if (manifestoTransporte) {
            const { situacaoAutorizacao: situacao } = manifestoTransporte;
            if (situacao === SituacaoManifestoTransporteEnum.autorizado)
              return 'green';

            if (situacao === SituacaoManifestoTransporteEnum.encerrado)
              return 'blue';

            if (situacao === SituacaoManifestoTransporteEnum.cancelado)
              return 'red';

            if (situacao === SituacaoManifestoTransporteEnum.rejeitado)
              return 'orange';

            return 'darkgoldenrod';
          }

          if (conhecimentoTransporte) {
            const { situacaoAutorizacao: situacao } = conhecimentoTransporte;
            if (situacao === SituacaoConhecimentoTransporteEnum.autorizado)
              return 'green';

            if (
              situacao === SituacaoConhecimentoTransporteEnum.denegado ||
              situacao === SituacaoConhecimentoTransporteEnum.emProcessamento
            )
              return 'blue';

            if (situacao === SituacaoConhecimentoTransporteEnum.cancelado)
              return 'red';

            if (situacao === SituacaoConhecimentoTransporteEnum.rejeitado)
              return 'goldenrod';

            return 'darkgoldenrod';
          }

          if (notaFiscalPropriaTerceiro) {
            const { situacaoAutorizacao: situacao } = notaFiscalPropriaTerceiro;
            if (situacao === SituacaoNotaFiscalEntradaEnum.autorizada)
              return 'green';

            if (situacao === SituacaoNotaFiscalEntradaEnum.denegada)
              return 'blue';

            if (situacao === SituacaoNotaFiscalEntradaEnum.cancelada)
              return 'red';

            if (situacao === SituacaoNotaFiscalEntradaEnum.rejeitada)
              return 'goldenrod';

            if (situacao === SituacaoNotaFiscalEntradaEnum.emTransmissao)
              return 'gray';

            return 'darkgoldenrod';
          }

          if (notaFiscalConsumidorPropria) {
            const { situacaoAutorizacao: situacao } =
              notaFiscalConsumidorPropria;
            if (situacao === SituacaoNotaFiscalSaidaEnum.autorizada)
              return 'green';

            if (situacao === SituacaoNotaFiscalSaidaEnum.denegada)
              return 'blue';

            if (situacao === SituacaoNotaFiscalSaidaEnum.cancelada)
              return 'red';

            if (situacao === SituacaoNotaFiscalSaidaEnum.rejeitada)
              return 'goldenrod';

            if (situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao)
              return 'gray';

            return 'darkgoldenrod';
          }

          if (notaFiscalSaidaPropria) {
            const { situacaoAutorizacao: situacao } = notaFiscalSaidaPropria;
            if (situacao === SituacaoNotaFiscalSaidaEnum.autorizada)
              return 'green';

            if (situacao === SituacaoNotaFiscalSaidaEnum.denegada)
              return 'blue';

            if (situacao === SituacaoNotaFiscalSaidaEnum.cancelada)
              return 'red';

            if (situacao === SituacaoNotaFiscalSaidaEnum.rejeitada)
              return 'goldenrod';

            if (situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao)
              return 'gray';

            return 'darkgoldenrod';
          }

          return 'darkgoldenrod';
        };

        const listaDados = [...response.dados[0]];

        setListaItens([...listaDados]);

        const dados = listaDados.map((data: IListaItens) => {
          const {
            manifestoTransporte,
            conhecimentoTransporte,
            notaFiscalPropriaTerceiro,
            notaFiscalConsumidorPropria,
            notaFiscalSaidaPropria,
          } = data;
          const situacaoStyle: React.CSSProperties = {
            fontWeight: 'bold',
            color: obterColorPorSituacao(data),
          };

          return {
            id: data.id,

            selecionado: (
              <div className="center flex">
                <input
                  type="checkbox"
                  defaultChecked={data.checked}
                  ref={(instance) => {
                    listaItensRef.current?.push(instance);
                  }}
                  onClick={() => {
                    handleUpdateItem({ itemId: data.id });
                  }}
                />
              </div>
            ),

            tipoDocumento: (
              <div className="lista-texto">{data.tipoDocumento}</div>
            ),
            numero: (
              <div className="lista-valor">
                {manifestoTransporte && manifestoTransporte?.numero}
                {conhecimentoTransporte && conhecimentoTransporte.numero}
                {notaFiscalPropriaTerceiro && notaFiscalPropriaTerceiro.numero}
                {notaFiscalConsumidorPropria &&
                  notaFiscalConsumidorPropria.numero}
                {notaFiscalSaidaPropria && notaFiscalSaidaPropria.numero}
              </div>
            ),
            serie: (
              <div className="lista-valor">
                {manifestoTransporte &&
                  `${manifestoTransporte?.serie.codigo} - ${manifestoTransporte?.serie.descricao}`}
                {conhecimentoTransporte &&
                  `${conhecimentoTransporte?.serie.codigo} - ${conhecimentoTransporte.serie.descricao}`}
                {data.tipoDocumento === 'Nota fiscal própria de Entrada' &&
                  notaFiscalPropriaTerceiro &&
                  notaFiscalPropriaTerceiro.serie &&
                  `${notaFiscalPropriaTerceiro?.serie.codigo} - ${notaFiscalPropriaTerceiro.serie.descricao}`}
                {data.tipoDocumento === 'Nota fiscal de terceiros' &&
                  notaFiscalPropriaTerceiro &&
                  notaFiscalPropriaTerceiro?.serieCodigo}
                {notaFiscalConsumidorPropria &&
                  notaFiscalConsumidorPropria.serie &&
                  `${notaFiscalConsumidorPropria?.serie.codigo} - ${notaFiscalConsumidorPropria.serie.descricao}`}
                {notaFiscalSaidaPropria &&
                  notaFiscalSaidaPropria.serie &&
                  `${notaFiscalSaidaPropria?.serie.codigo} - ${notaFiscalSaidaPropria.serie.descricao}`}
              </div>
            ),
            cnpjCpf: (
              <div className="lista-valor">
                {notaFiscalPropriaTerceiro?.notaFiscalPessoa &&
                  FormatarCnpjCpf(
                    notaFiscalPropriaTerceiro.notaFiscalPessoa.cnpjCpf
                  )}
                {notaFiscalConsumidorPropria?.notaFiscalSaidaPessoa &&
                  FormatarCnpjCpf(
                    notaFiscalConsumidorPropria.notaFiscalSaidaPessoa.cnpjCpf
                  )}
                {notaFiscalSaidaPropria?.notaFiscalSaidaPessoa &&
                  FormatarCnpjCpf(
                    notaFiscalSaidaPropria.notaFiscalSaidaPessoa.cnpjCpf
                  )}
              </div>
            ),
            'pessoa.nomeRazaoSocial': (
              <div className="lista-texto">
                {notaFiscalPropriaTerceiro?.notaFiscalPessoa &&
                  notaFiscalPropriaTerceiro?.notaFiscalPessoa.pessoa &&
                  `${notaFiscalPropriaTerceiro?.notaFiscalPessoa.pessoa.codigo}-${notaFiscalPropriaTerceiro?.notaFiscalPessoa.pessoa.nomeRazaoSocial}`}
                {notaFiscalConsumidorPropria?.notaFiscalSaidaPessoa &&
                  notaFiscalConsumidorPropria?.notaFiscalSaidaPessoa.pessoa &&
                  `${notaFiscalConsumidorPropria?.notaFiscalSaidaPessoa.pessoa.codigo}-${notaFiscalConsumidorPropria?.notaFiscalSaidaPessoa.pessoa.nomeRazaoSocial}`}
                {notaFiscalSaidaPropria?.notaFiscalSaidaPessoa &&
                  notaFiscalSaidaPropria?.notaFiscalSaidaPessoa.pessoa &&
                  `${notaFiscalSaidaPropria?.notaFiscalSaidaPessoa.pessoa.codigo}-${notaFiscalSaidaPropria?.notaFiscalSaidaPessoa.pessoa.nomeRazaoSocial}`}
              </div>
            ),
            dataHoraEmissao: (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(
                  manifestoTransporte && manifestoTransporte.dataHoraEmissao
                )}
                {FormatarDataHoraCompletaParaPtBr(
                  conhecimentoTransporte &&
                    conhecimentoTransporte.dataHoraEmissao
                )}
                {FormatarDataHoraCompletaParaPtBr(
                  notaFiscalPropriaTerceiro &&
                    notaFiscalPropriaTerceiro.dataHoraEmissao
                )}
                {FormatarDataHoraCompletaParaPtBr(
                  notaFiscalConsumidorPropria &&
                    notaFiscalConsumidorPropria.dataHoraEmissao
                )}
                {FormatarDataHoraCompletaParaPtBr(
                  notaFiscalSaidaPropria &&
                    notaFiscalSaidaPropria.dataHoraEmissao
                )}
              </div>
            ),
            total: (
              <div className="lista-valor">
                {manifestoTransporte &&
                  Number(manifestoTransporte.valor).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
                {conhecimentoTransporte &&
                  Number(conhecimentoTransporte.valor).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
                {notaFiscalPropriaTerceiro &&
                  Number(notaFiscalPropriaTerceiro.valor).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
                {notaFiscalConsumidorPropria &&
                  Number(notaFiscalConsumidorPropria.valor).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
                {notaFiscalSaidaPropria &&
                  Number(notaFiscalSaidaPropria.valor).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
              </div>
            ),
            situacao: (
              <div className="lista-texto" style={situacaoStyle}>
                {manifestoTransporte && manifestoTransporte.situacaoAutorizacao}
                {conhecimentoTransporte &&
                  conhecimentoTransporte.situacaoAutorizacao}
                {notaFiscalPropriaTerceiro &&
                  notaFiscalPropriaTerceiro.situacaoAutorizacao}
                {notaFiscalConsumidorPropria &&
                  notaFiscalConsumidorPropria.situacaoAutorizacao}
                {notaFiscalSaidaPropria &&
                  notaFiscalSaidaPropria.situacaoAutorizacao}
              </div>
            ),
            chaveAcesso: (
              <div className="lista-texto">
                {manifestoTransporte &&
                  manifestoTransporte.manifestoTransporteTransmissao
                    ?.chaveAcesso}
                {conhecimentoTransporte &&
                  conhecimentoTransporte.conhecimentoTransporteTransmissao
                    ?.chaveAcesso}
                {notaFiscalPropriaTerceiro &&
                  notaFiscalPropriaTerceiro.notaFiscalEntradaTransmissao
                    ?.chaveAcesso}
                {notaFiscalConsumidorPropria &&
                  notaFiscalConsumidorPropria.notaFiscalSaidaTransmissao
                    ?.chaveAcesso}
                {notaFiscalSaidaPropria &&
                  notaFiscalSaidaPropria.notaFiscalSaidaTransmissao
                    ?.chaveAcesso}
              </div>
            ),

            situacaoManifestacao: <div className="lista-texto">{}</div>,
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await ManutencaoDocumentosFiscaisComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    alert('Não implementado :/');

                    try {
                      setLoading(true);

                      const responseLog =
                        InutilizacaoNumeracaoLogComunicador.show({
                          id: data.id,
                          params,
                        });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    permissoes
                      .FiscalDocumentosEletronicosManutencaoDocumentosFiscais
                      ?.exclui
                  }
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      handleUpdateItem,
      permissoes.FiscalDocumentosEletronicosManutencaoDocumentosFiscais,
      tipoDocumento,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'fiscalManutencaoDocumentosFiscais' },
      });
      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, [handleSelecionarTodos]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      tipoDocumento: TipoDocumentoManutencaoDocumentoEnum.manifestoTransporte,
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Manutenção de Documentos Fiscais"
          permissao={
            permissoes.FiscalDocumentosEletronicosManutencaoDocumentosFiscais
          }
          quantidadeColunaIncrementar={1}
          botoes={
            <AcoesManutencaoDocumentosFiscais
              obterItensSelecionados={handleObterSelecionados}
              loadingListagem={loading}
            />
          }
          onRemoverFiltro={() => setShowTipoDocumento(true)}
          botaoNovo={false}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return InutilizacaoNumeracaoLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />

        <PesquisaAvancada
          tipoDocumentoAtual={tipoDocumento}
          onFilter={() => setShowTipoDocumento(false)}
        />

        <Lista
          headerLista={
            showTipoDocumento && (
              <Container style={{ padding: 0 }}>
                <Divisor style={{ maxWidth: '500px' }}>
                  <FormCia ref={formRef}>
                    <Row>
                      <Col lg={12}>
                        <Select
                          name="tipoDocumento"
                          label="Tipo de Documento"
                          options={FormatarEnum({
                            enumObj: TipoDocumentoManutencaoDocumentoEnum,
                          })}
                          onChange={(event) => {
                            const { value } = event.target;
                            const valorFormatado = StringConverterParaEnum<
                              typeof TipoDocumentoManutencaoDocumentoEnum,
                              TipoDocumentoManutencaoDocumentoEnum
                            >(TipoDocumentoManutencaoDocumentoEnum, value);

                            setTipoDocumento(valorFormatado);
                          }}
                        />
                      </Col>
                    </Row>
                  </FormCia>
                </Divisor>
              </Container>
            )
          }
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
          detalhe={false}
        />
      </ListaProvider>
    </Container>
  );
};

export default ManutencaoDocumentosFiscaisLista;
