import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/nota-referenciada';

interface IConhecimentoTransporteNotaReferenciadaComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteNotaReferenciadaComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteNotaReferenciadaComunicador {
  public async store(
    params: IConhecimentoTransporteNotaReferenciadaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteNotaReferenciadaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteNotaReferenciadaComunicador();
