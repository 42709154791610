import React, { useCallback, useState } from 'react';
import {
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaSisdevinLista,
  RegraEscolhaTipoEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaSisdevinComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/RegraEscolhaSisdevin/Comunicador/RegraEscolhaSisdevinComunicador';
import RegraEscolhaSisdevinLogComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/RegraEscolhaSisdevin/Log/Comunicador/RegraEscolhaSisdevinLogComunicador';
import RegraEscolhaSisdevinOrdemComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/RegraEscolhaSisdevin/Comunicador/RegraEscolhaSisdevinOrdemComunicador';

const RegraEscolhaSisdevinLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaSisdevinComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IRegraEscolhaSisdevinLista) => {
            return {
              id: data.id,

              'listaRegraEscolhaSisdevinDestinado.destinado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinDestinado
                    ? data.listaRegraEscolhaSisdevinDestinado.map((valor) => {
                        return (
                          <div key={valor.destinado}>
                            {`${valor.destinado};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              quantidadeCriterios: (
                <div className="lista-valor">{data.quantidadeCriterios}</div>
              ),

              'tipoNota.descricao': (
                <div className="lista-texto">
                  {data.tipoNota?.descricao || ''}
                </div>
              ),
              'tipoNotaMotivo.descricao': (
                <div className="lista-texto">
                  {data.tipoNotaMotivo?.descricao || ''}
                </div>
              ),
              tipoMovimento: (
                <div className="lista-texto">{data.tipoMovimento}</div>
              ),

              tipo: <div className="lista-texto">{data.tipo}</div>,

              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaSisdevinOperacaoFiscal.cfop': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinOperacaoFiscal
                    ? data.listaRegraEscolhaSisdevinOperacaoFiscal.map(
                        (valor) => {
                          return (
                            <div key={valor.operacaoFiscal.cfop}>
                              {`${valor.operacaoFiscal.cfop};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaSisdevinSped.spedTipoProduto.codigoDescricao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinSped
                    ? data.listaRegraEscolhaSisdevinSped.map((valor) => {
                        return (
                          <div key={valor.spedTipoProduto.codigo}>
                            {`${valor.spedTipoProduto.codigo} - ${valor.spedTipoProduto.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),
              'listaRegraEscolhaSisdevinTipoTransacao.tipoTransacao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinTipoTransacao
                    ? data.listaRegraEscolhaSisdevinTipoTransacao.map(
                        (valor) => {
                          return (
                            <div key={valor.tipoTransacao}>
                              {`${valor.tipoTransacao};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaSisdevinTipoMercado.tipoMercado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinTipoMercado
                    ? data.listaRegraEscolhaSisdevinTipoMercado.map((valor) => {
                        return (
                          <div key={valor.tipoMercado}>
                            {`${valor.tipoMercado};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaSisdevinModeloDocumento.modeloDocumento.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaSisdevinModeloDocumento
                      ? data.listaRegraEscolhaSisdevinModeloDocumento.map(
                          (valor) => {
                            return (
                              <div key={valor.modeloDocumento.codigo}>
                                {`${valor.modeloDocumento.codigo} - ${valor.modeloDocumento.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaSisdevinSerie.serie.codigoDescricao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaSisdevinSerie
                    ? data.listaRegraEscolhaSisdevinSerie.map((valor) => {
                        return (
                          <div key={valor.serie.codigo}>
                            {`${valor.serie.codigo} - ${valor.serie.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'regraEscolhaSisdevinResultado.movimentarSisdevin': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.regraEscolhaSisdevinResultado
                      ?.movimentarSisdevin
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.regraEscolhaSisdevinResultado?.movimentarSisdevin
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),

              'regraEscolhaSisdevinResultado.operacaoCultivar': (
                <div className="lista-texto">
                  {data.regraEscolhaSisdevinResultado?.operacaoCultivar}
                </div>
              ),
              'regraEscolhaSisdevinResultado.operacaoGranel': (
                <div className="lista-texto">
                  {data.regraEscolhaSisdevinResultado?.operacaoGranel}
                </div>
              ),

              'regraEscolhaSisdevinResultado.operacaoEngarrafados': (
                <div className="lista-texto">
                  {data.regraEscolhaSisdevinResultado?.operacaoEngarrafados}
                </div>
              ),

              'listaRegraEscolhaSisdevinEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaSisdevinEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaSisdevinEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaSisdevinComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaSisdevinLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      !permissoes
                        .SuprimentosSisdevinRegraEscolhaParametrosSisdevin
                        ?.exclui ||
                      !(
                        data.tipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      )
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      permissoes.SuprimentosSisdevinRegraEscolhaParametrosSisdevin?.exclui,
      tipoUsuario,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosRegraEscolhaSisdevin' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaSisdevinOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha de Sisdevin"
          permissao={
            permissoes.SuprimentosSisdevinRegraEscolhaParametrosSisdevin
          }
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaSisdevinLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaSisdevinLista;
