import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/transmissao/evento/transmitir';

interface INotaFiscalSaidaTransmissaoEventoTransmitirComunicadorUpdate {
  id: string;
  params: any;
}

class NotaFiscalSaidaTransmissaoEventoTransmitirComunicador {
  public async update(
    params: INotaFiscalSaidaTransmissaoEventoTransmitirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaTransmissaoEventoTransmitirComunicador();
