import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IRoteiroFabricacaoPadraoValoresAlterar } from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseListaRoteiroFabricacaoPadraoOperacaoDraggable } from './ListaRoteiroFabricacaoPadraoOperacaoDraggableHook';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { IInputAutoCompleteOperacaoRoteiroFabricacaoRef } from '../../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteOperacaoRoteiroFabricacao';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const { setListaValor, listaValor } =
    UseListaRoteiroFabricacaoPadraoOperacaoDraggable();
  const formPrincipal = UseForm();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus =
    useRef<IInputAutoCompleteOperacaoRoteiroFabricacaoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);
  const formRefLista = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IRoteiroFabricacaoPadraoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const item = listaValor[Number(idEditar)];
        await formRefDetalhe.current?.setDataInicial(item);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          ativo: true,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, listaValor, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idOperacaoRoteiroFabricacao: Yup.string()
            .nullable()
            .required('Operação é obrigatória!'),

          idCentroTrabalho: Yup.string()
            .nullable()
            .required('Centro de Trabalho é obrigatório!'),
        });

        await schema.validate(
          {
            idOperacaoRoteiroFabricacao: data.operacaoRoteiroFabricacao?.id,
            idCentroTrabalho: data.centroTrabalho?.id,
          },
          { abortEarly: false }
        );

        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          return { id, erro: true };
        }

        if (id) {
          setListaValor((estadoAnterior) => {
            estadoAnterior[Number(id)] = data;
            return estadoAnterior;
          });
        } else {
          setListaValor((estadoAnterior) => {
            return [...estadoAnterior, data];
          });
        }

        formPrincipal.formRef.current?.setFieldValue('listaMudou', true);

        await formRef?.reset();
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal.formRef,
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
      setListaValor,
    ]
  );

  const handleExcluir = async (idx: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setListaValor((estadoAnterior) => {
          const listaRoteiroFabricacaoPadrao = estadoAnterior.filter(
            (_, index) => {
              return index !== Number(idx);
            }
          );

          return listaRoteiroFabricacaoPadrao;
        });

        formPrincipal.formRef.current?.setFieldValue('listaMudou', true);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefDetalhe,
        formRefLista,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
