import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/grupo-produto-sidevin/grupo-produto-codigo-rastreabiliadade-sisdevin';

interface IGrupoProdutoCodigoRastreablidadeComunicadorStore {
  params: {
    idGrupoProdutoSisdevin: string;
    idCodigoRastreabilidadeSisdevin: string;
  };
}

interface IGrupoProdutoCodigoRastreabilidadeComunicadorDelete {
  id: string;
}

interface IGrupoProdutoCodigoRastreabilidadeComunicadorUpdate {
  ativo: boolean;
  id: string;
}

class GrupoProdutoCodigoRastreabilidadeComunicador {
  public async store(
    params: IGrupoProdutoCodigoRastreablidadeComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(
    params: IGrupoProdutoCodigoRastreabilidadeComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params);
    return response.data;
  }

  public async delete(
    params: IGrupoProdutoCodigoRastreabilidadeComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new GrupoProdutoCodigoRastreabilidadeComunicador();
