"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var EstadoCivilEnum;
(function (EstadoCivilEnum) {
    EstadoCivilEnum["solteiro"] = "Solteiro(a)";
    EstadoCivilEnum["casado"] = "Casado(a)";
    EstadoCivilEnum["divorciado"] = "Divorciado(a)";
    EstadoCivilEnum["viuvo"] = "Vi\u00FAvo(a)";
})(EstadoCivilEnum || (EstadoCivilEnum = {}));
exports.default = EstadoCivilEnum;
