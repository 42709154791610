import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/obter-nota-fiscal-saida-item';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemComunicadorIndex {
  idPessoa: string;
  listaIdNotaFiscalSaida: string[];
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemComunicador();
