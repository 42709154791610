import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../../../AutoComplete/AutoCompleteBase';
import { Item } from '../../../../AutoComplete/AutoCompleteBase/styles';
import NcmAutoCompleteComunicador from '../../../../../../Comunicador/Fiscal/Ncm/AutoComplete/NcmAutoComplete/NcmAutoCompleteComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface INcm {
  id: string;
  codigo: string;
  descricao: string;
  associacao: string[];
}

export interface IOnChangeItemAtualAutoCompleteNcmEvent {
  itemAtual: INcm | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteNcmEvent {
  input: HTMLInputElement | null;
}

interface IInputTabelaAutoCompleteNcmProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valueRef?: React.RefObject<IInputAutoCompleteBaseRef>;
  utilizavelProduto?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteNcmEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteNcmEvent
  ) => void | Promise<void>;
  obterRef?: (inputRef: React.RefObject<IInputAutoCompleteBaseRef>) => void;
}

export interface IInputTabelaAutoCompleteNcmRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputTabelaAutoCompleteNcm: React.ForwardRefRenderFunction<
  IInputTabelaAutoCompleteNcmRef,
  IInputTabelaAutoCompleteNcmProps
> = (
  {
    valueRef,
    utilizavelProduto,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    obterRef,
    ...rest
  },
  ref
) => {
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef>(null);
  const quantidadeAlterado = useRef(0);

  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(autoCompleteRef);
      }
    }
  }, [obterRef, valueRef]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });
    },
    [onChangeItemAtual]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div
        style={{ width: '100%', display: 'flex' }}
        onKeyDown={(e) => {
          if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
          }
        }}
      >
        <AutoCompleteBase
          ref={valueRef || autoCompleteRef}
          setPesquisando={(valor) => {
            setPesquisando(valor);
          }}
          filtrarItens={async ({ valor, offset }) => {
            setPesquisando(true);

            try {
              const response = await NcmAutoCompleteComunicador.index({
                params: {
                  offset,
                  limite: 5,
                  utilizavelProduto,
                  texto: valor,
                  ativo,
                  listaIdParaRemover,
                },
              });

              setPesquisando(false);
              return response;
            } catch (err) {
              TratarErros(err);
              setPesquisando(false);
              return false;
            }
          }}
          obterChaveUnica={(item: INcm) => {
            return item.id;
          }}
          obterLabel={(item: INcm) => {
            if (!item.codigo && !item.descricao) return '';

            return `${item.codigo} - ${item.descricao}`;
          }}
          personalizarItem={({ selecionado, navegacao, item }) => {
            return (
              <Item $selecionado={selecionado} $navegacao={navegacao}>
                <strong>{`${item.codigo} - ${item.descricao}`}</strong>
                <br />

                <div>
                  {item.associacao
                    ? item.associacao.map((valor: any) => {
                        return (
                          <div key={valor} style={{ fontSize: 'small' }}>
                            {valor}
                          </div>
                        );
                      })
                    : ''}
                </div>
              </Item>
            );
          }}
          onChangeItemAtual={handleChangeItemAtual}
          onChangeTexto={handleChangeTexto}
          inputProps={rest}
        />
      </div>
    </div>
  );
};

export default forwardRef(InputTabelaAutoCompleteNcm);
