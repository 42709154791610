import { IBoleto } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../Util/Relatorios/PDF';
import ImpressaoBoletoPDF from './ImpressaoBoletoPDF';

interface IRelatorios {
  handleVisualizarPdf(dados: IBoleto): Promise<void>;
  handleObterBlobPdf(dados: IBoleto): Promise<Blob | undefined>;
}

const ImpressaoBoleto = (): IRelatorios => {
  async function handleVisualizarPdf(dados: IBoleto): Promise<void> {
    const relatorio = await PDF({
      documento: <ImpressaoBoletoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(dados: IBoleto): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <ImpressaoBoletoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ImpressaoBoleto();
