import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import ConhecimentoTransporteTerceiroDocumentoReferenciadoHook from './ListaDetalheDocumentoReferenciadoTab/Hooks';
import ConhecimentoTransporteTerceiroDocumentoReferenciadoLista from './ListaDetalheDocumentoReferenciadoTab/Lista';

interface IDocumentoReferenciadoTab {
  bloquearCamposPorImportacao: boolean;
}

const DocumentoReferenciadoTab: React.FC<IDocumentoReferenciadoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <Divisor>
      <ConhecimentoTransporteTerceiroDocumentoReferenciadoHook>
        <Row>
          <Col lg={12}>
            <ConhecimentoTransporteTerceiroDocumentoReferenciadoLista
              name="listaConhecimentoTransporteTerceiroDocumentoReferenciado"
              bloquearCamposPorImportacao={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </ConhecimentoTransporteTerceiroDocumentoReferenciadoHook>
    </Divisor>
  );
};

export default DocumentoReferenciadoTab;
