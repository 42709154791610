/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 } from 'uuid';
import { BiEdit } from 'react-icons/bi/index.mjs';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import {
  StringConverterParaEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import TipoNotaAutoCompleteComunicador from '../../../../../Comunicador/Fiscal/Tributacao/TipoNota/AutoComplete/TipoNotaAutoComplete/TipoNotaAutoCompleteComunicador';
import Janela, { IJanelaRef } from '../../../../JanelaAutocomplete';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { RedirecionarProvider } from '../../../../../Hooks/RedirecionarContext';
import TipoNotaHook from '../../../../../Paginas/Fiscal/Tributacao/TipoNota/Detalhe/Hooks';
import { AutoCompleteProvider } from '../../AutoCompleteBase/Hooks/AutoCompleteHook';
import Detalhe from '../../../../Detalhe';
import Main from '../../../../../Paginas/Fiscal/Tributacao/TipoNota/Detalhe/Main';
import { ContainerAutoComplete } from './styles';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface ITipoNota {
  id: string;
  descricao: string;
}

interface IDadosPadrao {
  descricao: string;
  tipoMovimento?: TipoMovimentoEntradaSaidaEnum;
}

export interface IOnChangeItemAtualAutoCompleteTipoNotaEvent {
  itemAtual: ITipoNota | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteTipoNotaEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteTipoNotaEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteTipoNotaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  permitirAdicionar?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  filtroTipoMovimento?: TipoMovimentoEntradaSaidaEnum;
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteTipoNotaEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteTipoNotaEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteTipoNotaEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteTipoNotaEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteTipoNotaRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteTipoNota: React.ForwardRefRenderFunction<
  IInputAutoCompleteTipoNotaRef,
  IInputAutoCompleteTipoNotaProps
> = (
  {
    name,
    nomeObjeto,
    label,
    ativo = true,
    permitirAdicionar = true,
    listaIdParaRemover = [],
    filtroTipoMovimento = '',
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    ...rest
  },
  ref
) => {
  const { permissoes } = UsePermissoes();
  const [idRegistro, setIdRegistro] = useState('');

  const janelaRef = useRef<IJanelaRef>(null);

  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const [dadosRecuperados, setDadosRecuperados] = useState(null);
  const [dadosPadrao, setDadosPadrao] = useState<IDadosPadrao | null>(null);

  const {
    autoCompleteRef,

    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<ITipoNota | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        if (value) setIdRegistro(value.id);
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (itemAtual) {
        setIdRegistro(itemAtual?.id);
        setDadosPadrao(null);
      } else {
        setIdRegistro('');
      }
      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      setDadosPadrao({
        descricao: input?.value || '',
      });
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleSalva = useCallback(
    (data: any) => {
      janelaRef.current?.toggleJanela();
      autoCompleteRef.current?.selecionarItem(data);
    },
    [autoCompleteRef]
  );

  const handleRecuperarFormulario = useCallback((dadosParaRecuparar: any) => {
    setDadosRecuperados(dadosParaRecuparar);
    janelaRef.current?.toggleJanela();
  }, []);

  const handleDescartarFormulario = useCallback(() => {
    setDadosRecuperados(null);
  }, []);

  const permiteAdicionarAlterar = useMemo<boolean>(() => {
    const itemAtual = autoCompleteRef.current?.getItemAtual();

    return (
      permitirAdicionar &&
      ((permissoes?.FiscalTributacaoTipoNota?.altera && itemAtual?.id) ||
        (permissoes?.FiscalTributacaoTipoNota?.inclui && !itemAtual?.id))
    );
  }, [autoCompleteRef, permissoes, permitirAdicionar]);

  const dadosObrigatorios = useMemo(() => {
    return {
      ativo: true,
      tipoMovimento: filtroTipoMovimento
        ? StringConverterParaEnum<
            typeof TipoMovimentoEntradaSaidaEnum,
            TipoMovimentoEntradaSaidaEnum
          >(TipoMovimentoEntradaSaidaEnum, String(filtroTipoMovimento))
        : TipoMovimentoEntradaSaidaEnum.saida,
    };
  }, [filtroTipoMovimento]);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response = await TipoNotaAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite,
                    texto: valor,
                    ativo,
                    listaIdParaRemover,
                    filtroTipoMovimento,
                  },
                });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: ITipoNota) => {
              return item.id;
            }}
            obterLabel={(item: ITipoNota) => {
              if (!item.descricao) return '';

              return item.descricao;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />

          {permiteAdicionarAlterar && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={v4()}>
                  {idRegistro ? 'Editar' : 'Adicionar'}
                </Tooltip>
              }
            >
              <ContainerAutoComplete>
                <div>
                  <Button
                    onClick={() => {
                      janelaRef.current?.toggleJanela();
                    }}
                    style={{ marginLeft: 10, marginBottom: 5, height: 35 }}
                  >
                    {idRegistro ? <BiEdit /> : <IoMdAddCircleOutline />}
                  </Button>
                </div>
              </ContainerAutoComplete>
            </OverlayTrigger>
          )}

          {permiteAdicionarAlterar && (
            <Janela titulo="Tipo de Nota" ref={janelaRef} tamanho="xl">
              <RedirecionarProvider value={{ redirecionar: false }}>
                <TipoNotaHook>
                  <AutoCompleteProvider>
                    <Detalhe
                      style={{}}
                      onRecuperarFormulario={handleRecuperarFormulario}
                      onDescartarFormulario={handleDescartarFormulario}
                    >
                      <Main
                        idEditar={idRegistro ?? null}
                        dadosRecuperados={dadosRecuperados}
                        dadosDuplicados={null}
                        dadosObrigatorios={dadosObrigatorios}
                        dadosPadrao={dadosPadrao}
                        onClickSalvar={handleSalva}
                      />
                    </Detalhe>
                  </AutoCompleteProvider>
                </TipoNotaHook>
              </RedirecionarProvider>
            </Janela>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteTipoNota);
