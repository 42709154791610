import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Container, Row } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FormCia } from '@elogestor/unformcia';
import { SituacaoConhecimentoTransporteEnum } from '@elogestor/util';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ContaRevertidaComunicador from '../../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface ICancelarDetalhe {
  onFecharFormModal: () => void;
  onSalvarFormModal: () => Promise<void>;
}

const CancelarDetalhe: React.FC<ICancelarDetalhe> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { abrirJanela } = UseConfirmacao();

  const [situacaoBloquearCampos, setSituacaoBloquearCampos] = useState(false);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const idRegistro = formPrincipal.getIdDetalheRegistro() || '';

    const retorno = await ContaRevertidaComunicador.index({
      params: {
        idConhecimentoTransporte: idRegistro,
      },
    });

    if (retorno.possuiContaParcelaRevertida) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Essa conta possui uma reversão. Deseja excluir a conta revertida?
          </span>
        ),
        confimar: 'Sim',
        cancelar: 'Não',
      });

      if (!resposta) {
        return;
      }
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await onSalvarFormModal();
  }, [
    abrirJanela,
    formPrincipal,
    formRefDetalhe,
    handleSubmit,
    onSalvarFormModal,
  ]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  useEffect(() => {
    setSituacaoBloquearCampos(
      situacao === SituacaoConhecimentoTransporteEnum.cancelado
    );
  }, [situacao]);

  return (
    <JanelaDetalhe
      titulo="Cancelar CT-e"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <FormCia ref={formRefDetalhe}>
          <Divisor>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Textarea
                  ref={inputRefFocus}
                  style={{ minHeight: 250 }}
                  label="Motivo do Cancelamento"
                  name="motivoCancelamento"
                  disabled={situacaoBloquearCampos}
                  maxLength={255}
                />
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{ marginLeft: 15, display: 'flex', alignItems: 'center' }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading || situacaoBloquearCampos}
            >
              <MdCancel />
              <span style={{ marginLeft: 10 }}>Cancelar CT-e</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default CancelarDetalhe;
