import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/desconhecer-operacao';

interface IImportacaoXmlTerceiroDesconhecerOperacaoComunicadorUpdate {
  params?: any;
}

class ImportacaoXmlTerceiroDesconhecerOperacaoComunicador {
  public async update(
    params: IImportacaoXmlTerceiroDesconhecerOperacaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroDesconhecerOperacaoComunicador();
