import api from '../../../../../Comum/Services/Api';

const rota =
  'transporte/relatorio/conhecimento-transporte/conhecimento-transporte-por-tomador';

interface IConhecimentoTransportePorTomadorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ConhecimentoTransportePorTomadorRelatorioComunicador {
  public async index(
    params: IConhecimentoTransportePorTomadorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ConhecimentoTransportePorTomadorRelatorioComunicador();
