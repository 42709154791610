"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoTransacaoOperacaoFiscalEnum;
(function (TipoTransacaoOperacaoFiscalEnum) {
    TipoTransacaoOperacaoFiscalEnum["compra"] = "Compra";
    TipoTransacaoOperacaoFiscalEnum["entradaParaIndustrializacao"] = "Entrada para Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaComodato"] = "Entrada em Comodato";
    TipoTransacaoOperacaoFiscalEnum["entradaDevolucaoVenda"] = "Entrada de Devolu\u00E7\u00E3o de Venda";
    TipoTransacaoOperacaoFiscalEnum["entradaParaConsertoOuReparo"] = "Entrada para Conserto ou Reparo";
    TipoTransacaoOperacaoFiscalEnum["entradaDeRetornoDeConsertoOuReparo"] = "Entrada de Retorno de Conserto ou Reparo";
    TipoTransacaoOperacaoFiscalEnum["entradaBonificacaoOuBrinde"] = "Entrada de Bonifica\u00E7\u00E3o ou Brinde";
    TipoTransacaoOperacaoFiscalEnum["entradaTransferencia"] = "Entrada de Transfer\u00EAncia";
    TipoTransacaoOperacaoFiscalEnum["entradaCobrancaIndustrializacao"] = "Entrada cobran\u00E7a de Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaRetornoIndustrializacao"] = "Entrada de Retorno de Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaDeRetornoDeComodato"] = "Entrada de Retorno de Comodato";
    TipoTransacaoOperacaoFiscalEnum["entradaDeVasilhamesOuSacarias"] = "Entrada de Vasilhames ou Sacarias";
    TipoTransacaoOperacaoFiscalEnum["entradaDeRetDeVasilhamesOuSacarias"] = "Entrada de Retorno de Vasilhames ou Sacarias";
    TipoTransacaoOperacaoFiscalEnum["entradaAnulacaoFrete"] = "Entrada Anula\u00E7\u00E3o de Frete";
    TipoTransacaoOperacaoFiscalEnum["entradaParaDemonstracao"] = "Entrada para Demonstra\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaDeRetParaDemonstracao"] = "Entrada de Retorno para Demonstra\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaDeAmostraGratis"] = "Entrada de amostra Gr\u00E1tis";
    TipoTransacaoOperacaoFiscalEnum["entradaDeRetDeExposicaoOuFeira"] = "Entrada de Retorno de Exposi\u00E7\u00E3o ou Feira";
    TipoTransacaoOperacaoFiscalEnum["entradaDeDevolucaoDeConsignacao"] = "Entrada de Devolu\u00E7\u00E3o de Consigna\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaComFimEspecificoDeExportacao"] = "Entrada com fim Espec\u00EDfico de Exporta\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["entradaDeAtivoRemParaUsoForaDoEstab"] = "Entrada de Ativo Remetido para uso fora do Estab";
    TipoTransacaoOperacaoFiscalEnum["entradaeRetDeMerRemParaVendaForaDoEstab"] = "Entrada e Retorno de Merc Remetida para venda fora do Estab";
    TipoTransacaoOperacaoFiscalEnum["entradaeRetDeAtivoRemParaUsoForaDoEstab"] = "Entrada e Retorno de Ativo Remetido para uso fora do Estab";
    TipoTransacaoOperacaoFiscalEnum["entradaDeSimplesFaturamento"] = "Entrada de simples Faturamento";
    TipoTransacaoOperacaoFiscalEnum["entradaDePrestacaoDeServicos"] = "Entrada de Presta\u00E7\u00E3o de Servi\u00E7os";
    TipoTransacaoOperacaoFiscalEnum["outrasEntradas"] = "Outras Entradas";
    TipoTransacaoOperacaoFiscalEnum["venda"] = "Venda";
    TipoTransacaoOperacaoFiscalEnum["vendaForaEstabelecimento"] = "Venda Fora do Estabelecimento";
    TipoTransacaoOperacaoFiscalEnum["remessaVasilhameOuSacaria"] = "Remessa de Vasilhame ou Sacaria";
    TipoTransacaoOperacaoFiscalEnum["remessaParaDemonstracao"] = "Remessa para Demonstra\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["remessaParaBonificacaoOuBrinde"] = "Remessa para Bonifica\u00E7\u00E3o ou Brinde";
    TipoTransacaoOperacaoFiscalEnum["remessaParaIndustrializacao"] = "Remessa para Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["remessaParaConserto"] = "Remessa para Conserto";
    TipoTransacaoOperacaoFiscalEnum["remessaComFimEspecExportacao"] = "Remessa com fim espec\u00EDfico de exporta\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["remessaMercEmConsignacao"] = "Remessa de merc. em Consigna\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["remessaDeAmostraGratis"] = "Remessa de Amostra Gr\u00E1tis";
    TipoTransacaoOperacaoFiscalEnum["remessaParaExposicaoOuFeira"] = "Remessa para exposi\u00E7\u00E3o ou feira";
    TipoTransacaoOperacaoFiscalEnum["remessaRetAtivoParaUsoForaEstab"] = "Remessa e Retorno de ativo para uso fora do Estab";
    TipoTransacaoOperacaoFiscalEnum["remessaRetMercParaUsoForaEstab"] = "Remessa e Retorno de merc. para uso fora do Estab";
    TipoTransacaoOperacaoFiscalEnum["remessaComodato"] = "Remessa de Comodato";
    TipoTransacaoOperacaoFiscalEnum["cobran\u00E7aIndustrializacao"] = "Cobran\u00E7a de Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["retornoIndustrializacao"] = "Retorno de Industrializa\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["retornoComodato"] = "Retorno de Comodato";
    TipoTransacaoOperacaoFiscalEnum["retornoDemonstracao"] = "Retorno de Demonstra\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["retornoConserto"] = "Retorno de Conserto";
    TipoTransacaoOperacaoFiscalEnum["retornoVasilhameOuSacaria"] = "Retorno de Vasilhame ou Sacaria";
    TipoTransacaoOperacaoFiscalEnum["saidaDevolucaoCompra"] = "Sa\u00EDda de Devolu\u00E7\u00E3o de Compra";
    TipoTransacaoOperacaoFiscalEnum["saidaTransferencia"] = "Sa\u00EDda de Transfer\u00EAncia";
    TipoTransacaoOperacaoFiscalEnum["prestacaoServico"] = "Presta\u00E7\u00E3o de Servi\u00E7o";
    TipoTransacaoOperacaoFiscalEnum["anulacaoServi\u00E7oTransporte"] = "Anula\u00E7\u00E3o de Servi\u00E7o de Transporte";
    TipoTransacaoOperacaoFiscalEnum["simplesFaturamento"] = "Simples Faturamento";
    TipoTransacaoOperacaoFiscalEnum["devolucaoDeConsigna\u00E7\u00E3o"] = "Devolu\u00E7\u00E3o de Consigna\u00E7\u00E3o";
    TipoTransacaoOperacaoFiscalEnum["outrasSaidas"] = "Outras Sa\u00EDdas";
})(TipoTransacaoOperacaoFiscalEnum || (TipoTransacaoOperacaoFiscalEnum = {}));
exports.default = TipoTransacaoOperacaoFiscalEnum;
