"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDataOrdemProducaoEnum;
(function (TipoDataOrdemProducaoEnum) {
    TipoDataOrdemProducaoEnum["lancamento"] = "Lan\u00E7amento";
    TipoDataOrdemProducaoEnum["inicio"] = "In\u00EDcio";
    TipoDataOrdemProducaoEnum["termino"] = "Termino";
})(TipoDataOrdemProducaoEnum || (TipoDataOrdemProducaoEnum = {}));
exports.default = TipoDataOrdemProducaoEnum;
