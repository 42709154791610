const PessoaComTagPlanilha = [
  { titulo: 'Código', valor: 'codigo' },
  { titulo: 'Nome / RazãoSocial', valor: 'nomeRazaoSocial' },
  { titulo: 'CNPJ/CPF', valor: 'cnpjCpfVirtual' },
  { titulo: 'Telefone', valor: 'telefone' },
  { titulo: 'Cidade', valor: 'pessoaEnderecoPrincipal.cidade.nome' },
  { titulo: 'Insc. Estadual', valor: 'inscricaoEstadual' },
  { titulo: 'Estado', valor: 'pessoaEnderecoPrincipal.cidade.estado.sigla' },
  { titulo: 'Email', valor: 'email' },
  { titulo: 'Tags', valor: 'tags' },
];

export default PessoaComTagPlanilha;
