import React from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import RotasHook from './RotasHook';
import FormHook from './FormHook';
import IPadraoProps from '../../../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemDeclaracaoImportacaoHook extends IPadraoProps {
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const ItemDeclaracaoImportacaoHook: React.FC<IItemDeclaracaoImportacaoHook> = ({
  children,
  formRefItem,
}) => {
  return (
    <RotasHook>
      <FormHook formRefItem={formRefItem}>{children}</FormHook>
    </RotasHook>
  );
};

export default ItemDeclaracaoImportacaoHook;
