import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/consulta-aliquota-icms-interna-interestadual';

interface IConsultaAliquotaIcmsInternaInterestadualComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

class ConsultaAliquotaIcmsInternaInterestadualComunicador {
  public async index(
    params: IConsultaAliquotaIcmsInternaInterestadualComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ConsultaAliquotaIcmsInternaInterestadualComunicador();
