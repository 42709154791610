import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/conhecimentos-emitidos/upload-pdf';

interface IImportacaoXmlConhecimentosEmitidosUploadPdfComunicadorUpdate {
  params: any;
}

class ImportacaoXmlConhecimentosEmitidosUploadPdfComunicador {
  public async update(
    params: IImportacaoXmlConhecimentosEmitidosUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlConhecimentosEmitidosUploadPdfComunicador();
