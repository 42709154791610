import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteBanco from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteBanco';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const GeralTab: React.FC = () => {
  const { inputRefFocus, refresh } = UseForm();

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={12} lg={12} sm={12}>
            <Input
              ref={inputRefFocus}
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              maxLength={100}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6}>
            <InputAutoCompleteBanco
              name="idBanco"
              nomeObjeto="banco"
              label="Banco"
              placeholder="Banco"
              onChangeItemAtual={refresh}
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <Input
              label="Agência"
              name="agencia"
              placeholder="Agência"
              maxLength={20}
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <Input
              label="Conta"
              name="contaBancaria"
              placeholder="Conta"
              maxLength={20}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={2} lg={3} md={3}>
            <InputDecimal
              name="saldoInicial"
              label="Saldo Inicial"
              placeholder="Saldo Inicial"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <InputDate
              label="Data Saldo Inicial"
              name="dataSaldoInicial"
              placeholder="Data Saldo Inicial"
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <InputDecimalNulavel
              name="limiteCredito"
              label="Limite de Crédito"
              placeholder="Limite De Crédito"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <InputSwitch
              name="considerarFluxoCaixa"
              label="Considerar Fluxo Caixa"
              ativo="Sim"
              inativo="Não"
            />
          </Col>
          <Col xl={2} lg={3} md={3}>
            <InputSwitch name="ativo" label="Ativo" />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default GeralTab;
