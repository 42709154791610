import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import {
  FormatarEnum,
  IConhecimentoTransporteDocumentoAnteriorLista,
  TipoDocumentoAnteriorEnum,
  FormatarDataParaPtBrSemTimeZone,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompletePessoa from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../../Componentes/Select';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseSituacaoBloquearCampos } from '../../../Hooks/SituacaoBloquearCamposHook';
import { Container } from './styles';

interface IListaDetalheDocumentoAnterior {
  name: string;
}

const ListaDetalheDocumentoAnterior: React.FC<
  IListaDetalheDocumentoAnterior
> = ({ name }) => {
  const [tipoDocumentoAnteriorPapel, setTipoDocumentoAnteriorPapel] =
    useState(false);

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;
  const { fieldName, registerField } = UseFieldCia(name);

  const valorRef = useRef<IConhecimentoTransporteDocumentoAnteriorLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IConhecimentoTransporteDocumentoAnteriorLista[]
  >([]);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    if (situacaoBloquearCampos) {
      formRefLista.current?.setDisableAll(true);
    } else {
      formRefLista.current?.setDisableAll(false);
    }
  }, [formRefLista, situacaoBloquearCampos]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IConhecimentoTransporteDocumentoAnteriorLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col xl={1} lg={2} style={{ marginBottom: '10px' }}>
            <InputSwitch
              name="documentoAnteriorPapel"
              label="Doc. ant. papel"
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                setTipoDocumentoAnteriorPapel((prevState) => !prevState);
              }}
            />
          </Col>

          {!tipoDocumentoAnteriorPapel && (
            <>
              <Col xl={11} lg={10} md={12}>
                <Input
                  name="chaveAcessoCte"
                  label="Chave de Acesso CT-e"
                  placeholder="Chave de Acesso CT-e"
                  maxLength={44}
                />
              </Col>
            </>
          )}

          {tipoDocumentoAnteriorPapel && (
            <>
              <Col xl={3}>
                <Select
                  name="tipoDocumentoAnterior"
                  label="Tipo Documento"
                  options={FormatarEnum({
                    enumObj: TipoDocumentoAnteriorEnum,
                    todos: false,
                    nullavel: true,
                  })}
                />
              </Col>

              <Col xl={2}>
                <Input
                  name="documentoAnteriorSerie"
                  label="Série"
                  placeholder="Série"
                  maxLength={3}
                />
              </Col>

              <Col xl={2}>
                <Input
                  name="subSerie"
                  label="Sub-série"
                  placeholder="Sub-série"
                  maxLength={2}
                />
              </Col>

              <Col xl={2}>
                <Input
                  name="numeroDocumento"
                  label="Núm. Doc."
                  placeholder="Núm. Doc"
                  maxLength={30}
                />
              </Col>

              <Col xl={2}>
                <InputDate name="dataEmissao" label="Data Emissão" />
              </Col>
            </>
          )}
        </Row>

        <Row style={{ marginBottom: '10px' }}>
          <Col xl={8}>
            <InputAutoCompletePessoa
              name="idPessoa"
              nomeObjeto="pessoa"
              label="Expedidor Documento Anterior"
              placeholder="Expedidor Documento Anterior"
            />
          </Col>
          <Col xl={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <BtnPadraoButton
              className="btn-padrao btn-verde btn-adicionar"
              type="button"
              onClick={handleAdicionar}
              disabled={
                !permissao?.inclui ||
                formPrincipal.loading ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAddCircleOutline />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>
      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-booleano" style={{ width: '8%' }}>
                <span>Doc ant. papel</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Chave de Acesso</span>
              </th>
              <th className="lista-texto" style={{ width: '13%' }}>
                <span>Expedidor</span>
              </th>
              <th className="lista-texto" style={{ width: '13%' }}>
                <span>Tipo Documento</span>
              </th>
              <th className="lista-texto" style={{ width: '5%' }}>
                <span>Série</span>
              </th>
              <th className="lista-texto" style={{ width: '6%' }}>
                <span>Sub-série</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Núm. Doc.</span>
              </th>
              <th className="lista-data" style={{ width: '10%' }}>
                <span>Data Emissão</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr key={v4()}>
                  <td
                    className="lista-booleano"
                    style={{
                      color: item.tipoDocumentoAnterior ? 'green' : 'red',
                    }}
                  >
                    {item.tipoDocumentoAnterior ? 'Sim' : 'Não'}
                  </td>
                  <td className="lista-texto">{item?.chaveAcessoCte}</td>
                  <td className="lista-texto">
                    {item?.pessoa.nomeRazaoSocial}
                  </td>
                  <td className="lista-texto">{item?.tipoDocumentoAnterior}</td>
                  <td className="lista-texto">{item?.serie}</td>
                  <td className="lista-texto">{item?.subserie}</td>
                  <td className="lista-texto">{item?.numeroDocumento}</td>
                  <td className="lista-data">
                    {FormatarDataParaPtBrSemTimeZone(item?.dataEmissao)}
                  </td>

                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(item.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            situacaoBloquearCampos ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default ListaDetalheDocumentoAnterior;
