import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/consultar-protocolo';

interface IManifestoTransporteConsultarProtocoloComunicadorUpdate {
  id: string;
}

class ManifestoTransporteConsultarProtocoloComunicador {
  public async update(
    params: IManifestoTransporteConsultarProtocoloComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteConsultarProtocoloComunicador();
