/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa/index.mjs';
import { AiOutlineClose } from 'react-icons/ai/index.mjs';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
} from '../../Inputs/AutoComplete/AutoCompleteBase';
import { Container as ContainerItem } from './ItemMenuAutoComplete/styles';
import ItemMenuAutoComplete from './ItemMenuAutoComplete';
import { Container } from './styles';
import MenuAutoCompleteComunicador from '../../../Comunicador/Geral/Menu/AutoComplete/MenuAutoComplete/MenuAutoCompleteComunicador';
import { ButtonHeader } from '../styles';
import { UseReactSizeMeGlobalHook } from '../../../Hooks/ReactSizeMeGlobalHook';
import TratarErros from '../../../Util/Erro/TratarErros';
import AnimacaoCarregamento from '../../CarregandoAnimado';

interface IMenu {
  id: string;
  descricao: string;
  rota: string;
}

export interface IOnChangeItemAtualAutoCompleteMenuEvent {
  itemAtual: IMenu | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteMenuEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteMenuProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  hidden?: boolean;
  label?: string | JSX.Element;
  pesquisaMenuAberta: boolean;
  setPesquisaMenuAberta: (value: boolean) => void;
}

export interface IInputAutoCompleteMenuRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteMenu: React.ForwardRefRenderFunction<
  IInputAutoCompleteMenuRef,
  IInputAutoCompleteMenuProps
> = (
  { label, hidden, pesquisaMenuAberta, setPesquisaMenuAberta, ...rest },
  ref
) => {
  const { telaPequena } = UseReactSizeMeGlobalHook();

  const navigate = useNavigate();
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef | null>(null);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div
      style={{
        display: hidden ? 'none' : 'flex',
        alignItems: 'flex-end',
        maxWidth: 300,
        width: '100%',
      }}
    >
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <Container>
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            onChangeItemAtual={({ itemAtual }) => {
              if (itemAtual) {
                navigate(itemAtual.rota);
                autoCompleteRef.current?.selecionarItem(null);
              }
            }}
            filtrarItens={async ({ valor, offset, limite }) => {
              try {
                const response = await MenuAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite,
                    texto: valor,
                  },
                });

                return response;
              } catch (err) {
                TratarErros(err);
                return false;
              }
            }}
            obterChaveUnica={(item: IMenu) => {
              return item.id;
            }}
            obterLabel={(item: IMenu) => {
              return `${item.descricao} (${item.rota})`;
            }}
            inputProps={{
              placeholder: 'Pesquisar no menu',
              className: 'autocomplete-menu',
              ...rest,
            }}
            personalizarItem={ItemMenuAutoComplete}
            personalizarCarregando={() => {
              return (
                <ContainerItem
                  $navegacao={false}
                  className="d-flex flex-row align-items-center justify-content-center"
                >
                  <AnimacaoCarregamento />
                </ContainerItem>
              );
            }}
          />

          <ButtonHeader
            type="button"
            onClick={(event) => {
              setPesquisaMenuAberta(!event);
            }}
            style={{
              border: 0,
              borderColor: 'none',
              boxShadow: 'none',
              background: '#5d6166',
              color: '#fff',

              height: '35px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              borderRadius: 5,
              marginLeft: -38,
              position: 'relative',
            }}
          >
            {telaPequena && pesquisaMenuAberta ? (
              <AiOutlineClose style={{ height: 18, width: 18 }} />
            ) : (
              <FaSearch style={{ height: 18, width: 18 }} />
            )}
          </ButtonHeader>
        </Container>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteMenu);
