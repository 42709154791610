const PedidoVendaPorProdutoDataPrevistaFaturamentoPlanilha = [
  { titulo: 'Codigo do Produto', valor: 'listaPedidoVendaItem.produtoCodigo' },

  { titulo: 'Nome do Produto', valor: 'listaPedidoVendaItem.produtoDescricao' },

  {
    titulo: 'Data Hora Prevista Faturamento',
    valor: 'listaPedidoVendaItem.pedidoVendaDataPrevistaFaturamento',
  },

  {
    titulo: 'Número do Pedido',
    valor: 'listaPedidoVendaItem.pedidoVendaNumero',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaPedidoVendaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaPedidoVendaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaPedidoVendaItem.valorQuantidade',
  },
];

export default PedidoVendaPorProdutoDataPrevistaFaturamentoPlanilha;
