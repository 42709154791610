import styled from 'styled-components';

export const ContainerAutoComplete = styled.div`
  > div {
    display: flex;
    align-items: flex-end;
    width: 100%;

    input {
      height: 35px;
      flex: 1;
    }

    button {
      line-height: 1;
      svg {
        margin: 0;
        color: #fff;
        font-size: 22px;
        line-height: normal;
        vertical-align: middle;
      }

      &:disabled {
        background: #dddddd;
      }
    }
  }
`;
