import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IAtualizacaoEstoqueProdutoLista } from '@elogestor/util';
import { IInputAutoCompleteBaseRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import { IInputTabelaAutoCompleteCestRef } from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteCest';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IAtualizacaoEstoqueProdutoListaContext {
  validador: boolean;

  listaValor: IAtualizacaoEstoqueProdutoLista[];
  setListaValor(lista: IAtualizacaoEstoqueProdutoLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaDescricaoRef: RefObject<HTMLInputElement[]>;
  listaCodigoBarrasRef: RefObject<HTMLInputElement[]>;
  listaAtivoRef: RefObject<HTMLInputElement[]>;
  listaEstoqueQuantidadeRef: RefObject<HTMLInputElement[]>;

  listaNcmRef: RefObject<IInputAutoCompleteBaseRef[]>;
  listaCestRef: RefObject<IInputTabelaAutoCompleteCestRef[]>;
}

const AtualizacaoEstoqueProdutoListaContext =
  createContext<IAtualizacaoEstoqueProdutoListaContext>(
    {} as IAtualizacaoEstoqueProdutoListaContext
  );

const AtualizacaoEstoqueProdutoListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IAtualizacaoEstoqueProdutoLista[]
  >([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaDescricaoRef = useRef<HTMLInputElement[]>([]);
  const listaCodigoBarrasRef = useRef<HTMLInputElement[]>([]);
  const listaAtivoRef = useRef<HTMLInputElement[]>([]);
  const listaEstoqueQuantidadeRef = useRef<HTMLInputElement[]>([]);

  const listaNcmRef = useRef<IInputAutoCompleteBaseRef[]>([]);
  const listaCestRef = useRef<IInputTabelaAutoCompleteCestRef[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IAtualizacaoEstoqueProdutoLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <AtualizacaoEstoqueProdutoListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaDescricaoRef,
        listaCodigoBarrasRef,
        listaAtivoRef,
        listaEstoqueQuantidadeRef,

        listaNcmRef,
        listaCestRef,

        validador: true,
      }}
    >
      {children}
    </AtualizacaoEstoqueProdutoListaContext.Provider>
  );
};

function UseAtualizacaoEstoqueProdutoLista(): Omit<
  IAtualizacaoEstoqueProdutoListaContext,
  'validador'
> {
  const context = useContext(AtualizacaoEstoqueProdutoListaContext);

  if (!context.validador) {
    throw new Error(
      'UseAtualizacaoEstoqueProdutoLista deve ser usado com um AtualizacaoEstoqueProdutoListaHook'
    );
  }

  return context;
}

export {
  AtualizacaoEstoqueProdutoListaHook,
  UseAtualizacaoEstoqueProdutoLista,
};
