import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import ConhecimentoTransporteDocumentoReferenciadoHook from './ListaDetalheDocumentoReferenciadoTab/Hooks';
import ConhecimentoTransporteDocumentoReferenciadoLista from './ListaDetalheDocumentoReferenciadoTab/Lista';

const DocumentoReferenciadoTab: React.FC = () => {
  return (
    <Divisor>
      <ConhecimentoTransporteDocumentoReferenciadoHook>
        <Row>
          <Col lg={12}>
            <ConhecimentoTransporteDocumentoReferenciadoLista name="listaConhecimentoTransporteDocumentoReferenciado" />
          </Col>
        </Row>
      </ConhecimentoTransporteDocumentoReferenciadoHook>
    </Divisor>
  );
};

export default DocumentoReferenciadoTab;
