import styled from 'styled-components';
import { Modal as ModalB } from 'react-bootstrap';

export const Container = styled.div`
  padding: 30px;
  margin: 50px 0 15px 0;
  overflow: hidden;

  .principal {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }
  }

  .tree-container {
    padding: 0 15px 15px 15px;
    max-width: 700px;
    width: 100%;
    height: fit-content;
  }

  .detalhe-container {
    width: 100%;
    margin-left: auto;
  }
`;

export const Modal = styled(ModalB)`
  label {
    margin-top: 10px;
  }

  input,
  select {
    background: #fff;
  }
`;
