import { IOrcamentoPaisagemSemFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrcamentoPaisagemSemFotoProdutoPDF from './OrcamentoPaisagemSemFotoProdutoImpressaoPDF';

interface IImpressaoOrcamentoPaisagemSemFotoProduto {
  handleVisualizarPdf(
    data: IOrcamentoPaisagemSemFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrcamentoPaisagemSemFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrcamentoPaisagemSemFotoProduto =
  (): IImpressaoOrcamentoPaisagemSemFotoProduto => {
    async function handleVisualizarPdf(
      dados: IOrcamentoPaisagemSemFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoOrcamentoPaisagemSemFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrcamentoPaisagemSemFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrcamentoPaisagemSemFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrcamentoPaisagemSemFotoProduto();
