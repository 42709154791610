import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as Yup from 'yup';
import { Table, Col, Button, Row } from 'react-bootstrap';
import { BuscaCnpjCpfEnum, FormatarTelefone } from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { Container, CabecalhoListaEmailTelefone } from './styles';
import Modal, { IJanelaRef } from '../../../JanelaAutocomplete';
import TratarErros from '../../../../Util/Erro/TratarErros';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';
import IPesquisaPessoaCnpjDetalhe from './Interface/IPesquisaPessoaCnpjDetalhe';
import IPesquisaPessoaCnpjDetalheRef from './Interface/IPesquisaPessoaCnpjDetalheRef';
import IPessoa from './Interface/IPessoa';
import { TBody } from '../../../Styles/TBody';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import PessoaCnpjCpfComunicador from '../../../../Comunicador/Comum/Comunicador/PessoaCnpjCpfComunicador';

const PesquisaPessoaCnpjDetalhe: React.ForwardRefRenderFunction<
  IPesquisaPessoaCnpjDetalheRef,
  IPesquisaPessoaCnpjDetalhe
> = ({ handleOnSelecionarPessoa, tipoBusca }, ref) => {
  const janelaRef = useRef<IJanelaRef>(null);

  const [listaPessoa, setListaPessoa] = useState<IPessoa[]>([]);

  const [listaTelefoneSemDuplicata, setListaTelefoneSemDuplicata] = useState<
    any[]
  >([]);
  const [listaEmailSemDuplicata, setListaEmailSemDuplicata] = useState<any[]>(
    []
  );

  const [listaEmailSelecionado, setListaEmailSelecionado] = useState<any[]>([]);
  const [listaTelefoneSelecionado, setListaTelefoneSelecionado] = useState<
    any[]
  >([]);

  const listaUfRef = useRef<Array<HTMLInputElement | null>>([]);
  const [origemIndex, setOrigemIndex] = useState(0);
  const [cnpjCpfIndex, setCnpjCpfIndex] = useState(0);
  const [nomeRazaoSocialIndex, setNomeRazaoSocialIndex] = useState(0);
  const [inscricaoEstadualIndex, setInscricaoEstadualIndex] = useState(0);
  const [nomeFantasiaIndex, setNomeFantasiaIndex] = useState(0);
  const [cepIndex, setCepIndex] = useState(0);
  const [cidadeIndex, setCidadeIndex] = useState(0);
  const [bairroIndex, setBairroIndex] = useState(0);
  const [logradouroIndex, setLogradouroIndex] = useState(0);
  const [complementoIndex, setComplementoIndex] = useState(0);
  const [numeroIndex, setNumeroIndex] = useState(0);

  const { telaGrande } = UseReactSizeMeBodyHook();

  const pesquisarCnpj = useCallback(
    async ({
      cnpjCpf,
      inscricaoEstadual,
      nomeRazaoSocial,
      nomeFantasia,
      cep,
      cidade,
      logradouro,
      bairro,
      complemento,
      numero,
      listaEmail,
      listaTelefone,
    }: any) => {
      try {
        const response = await PessoaCnpjCpfComunicador.show({
          params: {
            cnpjCpf,
            inscricaoEstadual,
            nomeRazaoSocial,
            nomeFantasia,
            cep,
            cidade,
            logradouro,
            bairro,
            complemento,
            numero,
            listaEmail,
            listaTelefone,
          },
        });
        if (response.mensagem === 'Dados do registro já estão atualizados!') {
          ToastInfo('Dados do registro já estão atualizados!');
        } else if (response.listaPessoa.length === 1) {
          handleOnSelecionarPessoa(response.listaPessoa[0]);
        } else {
          janelaRef.current?.toggleJanela();
          setListaPessoa(response.listaPessoa);
          setListaTelefoneSemDuplicata(response.listaTelefoneSemDuplicata);
          setListaEmailSemDuplicata(response.listaEmailSemDuplicata);

          const listaTelefoneCadastro = response.listaTelefoneSemDuplicata
            ? response.listaTelefoneSemDuplicata.filter((telefone: any) => {
                return telefone.origem === 'Cadastro';
              })
            : [];
          setListaTelefoneSelecionado(listaTelefoneCadastro);

          const listaEmailCadastro = response.listaEmailSemDuplicata
            ? response.listaEmailSemDuplicata.filter((email: any) => {
                return email.origem === 'Cadastro';
              })
            : [];
          setListaEmailSelecionado(listaEmailCadastro);
        }
      } catch (erro) {
        TratarErros(erro);
      }
    },
    [handleOnSelecionarPessoa]
  );

  useImperativeHandle(ref, () => ({
    pesquisarCnpj,
  }));

  const handleAdicionarItemListaEmail = useCallback(
    (email: any): void => {
      if (
        listaEmailSelecionado.find(
          (item) => item.ddd === email.ddd && item.numero === email.numero
        )
      ) {
        setListaEmailSelecionado((state) => {
          return [...state];
        });
      } else {
        setListaEmailSelecionado((state) => {
          return [...state, email];
        });
      }
    },
    [listaEmailSelecionado]
  );

  const handleRemoveItemListaEmail = useCallback(
    (email: { email: string }): void => {
      setListaEmailSelecionado((state) => {
        return state.filter((item) => item.email !== email.email);
      });
    },
    []
  );

  const handleAdicionarItemListaTelefone = useCallback(
    (telefone: any): void => {
      if (
        listaTelefoneSelecionado.find(
          (item) => item.ddd === telefone.ddd && item.numero === telefone.numero
        )
      ) {
        setListaTelefoneSelecionado((state) => {
          return [...state];
        });
      } else {
        setListaTelefoneSelecionado((state) => {
          return [...state, telefone];
        });
      }
    },
    [listaTelefoneSelecionado]
  );

  const handleRemoveItemListaTelefone = useCallback(
    (telefone: { numero: string; ddd: string }): void => {
      setListaTelefoneSelecionado((state) => {
        return state.filter(
          (item) =>
            `${item.ddd}${item.numero}` !== `${telefone.ddd}${telefone.numero}`
        );
      });
    },
    []
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      const schema = Yup.object().shape({
        nomeRazaoSocial: Yup.string().nullable().max(60),
        cnpjCpf: Yup.string().nullable().max(14).min(14),
        cidade: Yup.object().shape({
          id: Yup.string().nullable(),
          nome: Yup.string().max(60).nullable(),
          estado: Yup.object().shape({
            sigla: Yup.string().nullable().max(2),
          }),
        }),
        logradouro: Yup.string().nullable().max(60),
        numero: Yup.string().nullable().max(60),
        cep: Yup.string().nullable().max(8),
        bairro: Yup.string().nullable().max(60),
        complemento: Yup.string().nullable().max(60),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    const itensSelecionados = {
      nomeRazaoSocial: listaPessoa[nomeRazaoSocialIndex]
        ?.nomeRazaoSocial as string,
      nomeFantasia: listaPessoa[nomeFantasiaIndex]?.nomeFantasia || '',
      inscricaoEstadual:
        listaPessoa[inscricaoEstadualIndex]?.inscricaoEstadual || '',
      cnpjCpf: listaPessoa[cnpjCpfIndex]?.cnpjCpf as string,

      cidade: listaPessoa[cidadeIndex]?.cidade as any,

      logradouro: listaPessoa[logradouroIndex]?.logradouro || '',
      bairro: listaPessoa[bairroIndex]?.bairro || '',
      complemento: listaPessoa[complementoIndex]?.complemento || '',
      numero: listaPessoa[numeroIndex]?.numero || '',
      cep: listaPessoa[cepIndex]?.cep || '',
      listaEmail: listaEmailSelecionado,
      listaTelefone: listaTelefoneSelecionado,
    } as IPessoa;

    const pessoaValida = await handleValidar(itensSelecionados);
    if (!pessoaValida) {
      return;
    }

    handleOnSelecionarPessoa(itensSelecionados);
    janelaRef.current?.toggleJanela();
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    cnpjCpfIndex,
    complementoIndex,
    handleOnSelecionarPessoa,
    handleValidar,
    inscricaoEstadualIndex,
    listaEmailSelecionado,
    listaPessoa,
    listaTelefoneSelecionado,
    logradouroIndex,
    nomeFantasiaIndex,
    nomeRazaoSocialIndex,
    numeroIndex,
  ]);

  return (
    <Modal
      titulo="Selecione os dados corretos"
      ref={janelaRef}
      onHide={() => {
        setListaEmailSelecionado([]);
        setListaTelefoneSelecionado([]);
      }}
      tamanho="xl"
    >
      <Container $telaGrande={telaGrande}>
        <Row>
          <Col lg={12}>
            <div style={{ overflow: 'auto' }} className="lista-pessoas">
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th className="lista-texto">
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto">
                      <span>CPF/CNPJ</span>
                    </th>
                    <th
                      className={
                        tipoBusca ? 'hidden lista-texto' : 'lista-texto'
                      }
                    >
                      <span>Inscrição Estadual</span>
                    </th>
                    <th className="lista-texto">
                      <span>Nome</span>
                    </th>
                    <th
                      className={
                        tipoBusca ? 'hidden lista-texto' : 'lista-texto'
                      }
                    >
                      <span>Nome Fantasia</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Cep</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Cidade</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>UF</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Logradouro</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Bairro</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Complemento</span>
                    </th>
                    <th
                      className={
                        tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                          ? 'hidden lista-texto'
                          : 'lista-texto'
                      }
                    >
                      <span>Número</span>
                    </th>
                  </tr>
                </thead>

                <TBody>
                  {listaPessoa.map((pessoa, index) => {
                    return (
                      <tr>
                        <td
                          className={
                            origemIndex === index
                              ? 'selecionado lista-texto'
                              : 'lista-texto'
                          }
                          onClick={(event) => {
                            const input =
                              event.currentTarget.querySelector('input');
                            input?.click();
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="origem"
                              value={pessoa.origem}
                              checked={index === origemIndex}
                              onChange={() => {
                                setOrigemIndex(index);
                                setCnpjCpfIndex(index);
                                setNomeRazaoSocialIndex(index);
                                setNomeFantasiaIndex(index);
                                setInscricaoEstadualIndex(index);
                                setCepIndex(index);
                                setCidadeIndex(index);
                                setLogradouroIndex(index);
                                setBairroIndex(index);
                                setComplementoIndex(index);
                                setNumeroIndex(index);
                              }}
                            />
                            <span>{pessoa.origem}</span>
                          </div>
                        </td>
                        <td
                          className={
                            cnpjCpfIndex === index
                              ? 'selecionado lista-texto'
                              : 'lista-texto'
                          }
                          onClick={() => {
                            setCnpjCpfIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="cnpjCpf"
                              checked={index === cnpjCpfIndex}
                              value={pessoa.cnpjCpf}
                              onChange={() => {
                                setCnpjCpfIndex(index);
                              }}
                            />
                            <span>{pessoa.cnpjCpf}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            inscricaoEstadualIndex === index
                              ? 'selecionado '
                              : ''
                          }lista-texto ${tipoBusca ? 'hidden ' : ''}`}
                          onClick={() => {
                            setInscricaoEstadualIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="inscricaoEstadual"
                              checked={index === inscricaoEstadualIndex}
                              value={pessoa.inscricaoEstadual}
                              onChange={() => {
                                setInscricaoEstadualIndex(index);
                              }}
                            />
                            <span>{pessoa.inscricaoEstadual}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            nomeRazaoSocialIndex === index ? 'selecionado ' : ''
                          }lista-texto`}
                          onClick={() => {
                            setNomeRazaoSocialIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="nomeRazaoSocial"
                              checked={index === nomeRazaoSocialIndex}
                              value={pessoa.nomeRazaoSocial}
                              onChange={() => {
                                setNomeRazaoSocialIndex(index);
                              }}
                            />
                            <span>{pessoa.nomeRazaoSocial}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            nomeFantasiaIndex === index ? 'selecionado ' : ''
                          }lista-texto ${tipoBusca ? 'hidden ' : ''}`}
                          onClick={() => {
                            setNomeFantasiaIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="nomeFantasia"
                              checked={index === nomeFantasiaIndex}
                              value={pessoa.nomeFantasia}
                              onChange={() => {
                                setNomeFantasiaIndex(index);
                              }}
                            />
                            <span>{pessoa.nomeFantasia}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            cepIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setCepIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="cep"
                              checked={index === cepIndex}
                              value={pessoa.cep}
                              onChange={() => {
                                setCepIndex(index);
                              }}
                            />
                            <span>{pessoa.cep}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            cidadeIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setCidadeIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="cidade"
                              checked={index === cidadeIndex}
                              onChange={() => {
                                setCidadeIndex(index);
                              }}
                            />
                            <span>{pessoa.cidade.nome}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            cidadeIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                        >
                          <div>
                            <input
                              type="radio"
                              name="cidadeEstadoSigla"
                              checked={index === cidadeIndex}
                              value={pessoa.cidade.estado.sigla}
                              disabled
                              ref={(instance) => {
                                listaUfRef.current[index] = instance;
                              }}
                            />
                            <span>{pessoa.cidade.estado.sigla}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            logradouroIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setLogradouroIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="logradouro"
                              checked={index === logradouroIndex}
                              value={pessoa.logradouro}
                              onChange={() => {
                                setLogradouroIndex(index);
                              }}
                            />
                            <span>{pessoa.logradouro}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            bairroIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setBairroIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="bairro"
                              checked={index === bairroIndex}
                              value={pessoa.bairro}
                              onChange={() => {
                                setBairroIndex(index);
                              }}
                            />
                            <span>{pessoa.bairro}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            complementoIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setComplementoIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="complemento"
                              checked={index === complementoIndex}
                              value={pessoa.complemento}
                              onChange={() => {
                                setComplementoIndex(index);
                              }}
                            />
                            <span>{pessoa.complemento}</span>
                          </div>
                        </td>

                        <td
                          className={`${
                            numeroIndex === index ? 'selecionado ' : ''
                          }lista-texto ${
                            tipoBusca === BuscaCnpjCpfEnum.dadosBancarios
                              ? 'hidden '
                              : ''
                          }`}
                          onClick={() => {
                            setNumeroIndex(index);
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="numero"
                              checked={index === numeroIndex}
                              value={pessoa.numero}
                              onChange={() => {
                                setNumeroIndex(index);
                              }}
                            />
                            <span>{pessoa.numero}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </TBody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div style={{ overflow: 'auto' }} className="lista-telefone-email">
              <CabecalhoListaEmailTelefone>
                <h4>Selecione os números de telefone</h4>
              </CabecalhoListaEmailTelefone>
              <Table>
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '5%' }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setListaTelefoneSelecionado(
                              listaTelefoneSemDuplicata
                            );
                          } else {
                            setListaTelefoneSelecionado([]);
                          }
                        }}
                      />
                    </th>
                    <th className="lista-texto" style={{ width: '35%' }}>
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto" style={{ width: '60%' }}>
                      <span>Telefone</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaTelefoneSemDuplicata &&
                    listaTelefoneSemDuplicata.map((telefone, telefoneIndex) => {
                      return (
                        <tr
                          onClick={(event) => {
                            const input =
                              event.currentTarget.querySelector('input');
                            input?.click();
                          }}
                          style={{
                            backgroundColor:
                              telefoneIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <td>
                            <input
                              value={telefone}
                              type="checkbox"
                              checked={listaTelefoneSelecionado.includes(
                                telefone
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(event) => {
                                if (event.currentTarget.checked) {
                                  handleAdicionarItemListaTelefone(telefone);
                                } else {
                                  handleRemoveItemListaTelefone(telefone);
                                }
                              }}
                            />
                          </td>
                          <td>{telefone.origem}</td>
                          <td>
                            <div>
                              <span>
                                {FormatarTelefone(
                                  `${telefone.ddd}${telefone.numero}`
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col lg={6}>
            <div style={{ overflow: 'auto' }} className="lista-telefone-email">
              <CabecalhoListaEmailTelefone>
                <h4>Selecione os e-mails</h4>
              </CabecalhoListaEmailTelefone>
              <Table>
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '5%' }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setListaEmailSelecionado(listaEmailSemDuplicata);
                          } else {
                            setListaEmailSelecionado([]);
                          }
                        }}
                      />
                    </th>
                    <th className="lista-texto" style={{ width: '35%' }}>
                      <span>Origem</span>
                    </th>
                    <th className="lista-texto" style={{ width: '60%' }}>
                      <span>E-mail</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaEmailSemDuplicata &&
                    listaEmailSemDuplicata.map((email, emailIndex) => {
                      return (
                        <tr
                          onClick={(event) => {
                            const input =
                              event.currentTarget.querySelector('input');
                            input?.click();
                          }}
                          style={{
                            backgroundColor:
                              emailIndex % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <td>
                            <input
                              value={email}
                              checked={listaEmailSelecionado.includes(email)}
                              type="checkbox"
                              onChange={(event) => {
                                if (event.currentTarget.checked) {
                                  handleAdicionarItemListaEmail(email);
                                } else {
                                  handleRemoveItemListaEmail(email);
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </td>
                          <td>{email.origem}</td>
                          <td>
                            <div>
                              <span>{email.email}</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Col lg={12} style={{ padding: 0 }}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="espacamento-interno-para-esquerda-15"
          >
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={() => {
                janelaRef.current?.toggleJanela();
                setListaEmailSelecionado([]);
                setListaTelefoneSelecionado([]);
              }}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={() => {
                setListaEmailSelecionado([]);
                setListaTelefoneSelecionado([]);
                handleSubmit();
              }}
            >
              <AiOutlineCheck />
              <span style={{ marginLeft: 10 }}>OK</span>
            </Button>
          </div>
        </Col>
      </Container>
    </Modal>
  );
};

export default forwardRef(PesquisaPessoaCnpjDetalhe);
