import api from '../../../../../../Comum/Services/Api';

const rota = '/comercial/pessoas/pessoa/contato/obter-email';

interface IPessoaContatoObterEmailComunicadorIndex {
  params: {
    listaIdPessoa: string[];
    direcionarBoletos: boolean;
    direcionarDocumentosFiscais: boolean;
    direcionarOrcamento: boolean;
    direcionarPedidoVenda: boolean;
    direcionarCotacaoCompra: boolean;
    direcionarPedidoCompra: boolean;
    direcionarOrdemServico: boolean;
  };
}

class PessoaComunicador {
  public async index(
    params: IPessoaContatoObterEmailComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new PessoaComunicador();
