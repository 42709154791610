import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: 'servico/ordens-servicos/ordem-servico/item',
        novo: 'servico/ordens-servicos/ordem-servico',
        alterar: 'servico/ordens-servicos/ordem-servico/item',
        excluir: 'servico/ordens-servicos/ordem-servico/item',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
