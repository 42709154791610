import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/ciencia-operacao';

interface IImportacaoXmlTerceiroCienciaOperacaoComunicadorUpdate {
  params: any;
}

class ImportacaoXmlTerceiroCienciaOperacaoComunicador {
  public async update(
    params: IImportacaoXmlTerceiroCienciaOperacaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroCienciaOperacaoComunicador();
