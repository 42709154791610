import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/custo/configuracao';

interface ICustoConfiguracaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ICustoConfiguracaoComunicadorShow {
  id: string;
}

interface ICustoConfiguracaoComunicadorStore {
  params: any;
}

interface ICustoConfiguracaoComunicadorUpdate {
  id: string;
  params: any;
}

interface ICustoConfiguracaoComunicadorDeletar {
  id: string;
}

class CustoConfiguracaoComunicador {
  public async index(params: ICustoConfiguracaoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ICustoConfiguracaoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ICustoConfiguracaoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: ICustoConfiguracaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: ICustoConfiguracaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CustoConfiguracaoComunicador();
