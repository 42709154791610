import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/obter-dados-padrao';

class ManifestoTransporteObterDadosPadraoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(`${rota}`);

    return response.data;
  }
}

export default new ManifestoTransporteObterDadosPadraoComunicador();
