import React from 'react';
import {
  Text,
  View,
  Font,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarCnpjCpf,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  FormatarTelefone,
  INotaFiscalImpressao,
  INotaFiscalProdutoServicoImpressao,
  CalculateSize,
  SituacaoNotaFiscalSaidaEnum,
  SituacaoNotaFiscalEntradaEnum,
} from '@elogestor/util';
import NotaFiscalImpressaoCabecalhoPDF from './NotaFiscalImpressaoCabecalhoPDF';
import NotaFiscalImpressaoDadosAdicionaisPDF from './NotaFiscalImpressaoDadosAdicionaisPDF';
import NotaFiscalImpressaoProdutoServicoPDF from './NotaFiscalImpressaoProdutoServicoPDF';
import NotaFiscalImpressaoFaturaPDF from './NotaFiscalImpressaoFaturaPDF';

const Document: any = _document;
const Page: any = _page;

export interface INotaFiscalImpressaoPDF {
  dados: INotaFiscalImpressao;
}

export interface INotaFiscalImpressaoListaProdutoServicoPDF {
  listaProdutoServico: INotaFiscalProdutoServicoImpressao[];
}

function ObterListaDadosAdicionais(dadosAdicionais?: string): string[] {
  if (!dadosAdicionais) return [];

  const tamanhoDadosAcicionais = 1283;
  const quantidadePaginas = Math.ceil(
    dadosAdicionais.length / tamanhoDadosAcicionais
  );

  const listaPagina: string[] = [];
  let inicio = 0;

  for (let i = 0; i < quantidadePaginas; i++) {
    const fim = inicio + tamanhoDadosAcicionais;

    const dados = dadosAdicionais.slice(inicio, fim);
    listaPagina.push(dados);

    inicio = fim;
  }

  return listaPagina;
}

function ObterFatura(listaFatura?: any[]): {
  tamanho: number;
  quantidadeLinhas: number;
  listaFatura: any[];
} {
  let tamanho = 10;

  listaFatura = listaFatura ?? [];

  const quantidadeLinhas = Math.ceil(listaFatura.length / 3);
  tamanho += quantidadeLinhas * 11;

  return { tamanho, quantidadeLinhas, listaFatura };
}

function ObterListaProdutoServico(
  dados: INotaFiscalImpressao,
  quantidadeLinhasFatura: number
): any[] {
  const listaProdutoServico: any[] = [];

  const tamanhoCodigo = 40;
  const tamanhoDescricao = 80;
  let quantidadeItemPrimeiraPagina = 31;
  const quantidadeItemPorPagina = 81;

  if (dados.localRetirada) quantidadeItemPrimeiraPagina -= 12;
  if (dados.localEntrega) quantidadeItemPrimeiraPagina -= 12;

  if (quantidadeLinhasFatura > 0) {
    quantidadeItemPrimeiraPagina -= quantidadeLinhasFatura + 3;
    if (quantidadeLinhasFatura >= 3) quantidadeItemPrimeiraPagina -= 1;
    if (quantidadeLinhasFatura >= 4) quantidadeItemPrimeiraPagina -= 1;
    if (quantidadeLinhasFatura >= 5) quantidadeItemPrimeiraPagina -= 1;
  }

  let inicio = 0;
  let listaItemPrimeiraPagina: INotaFiscalProdutoServicoImpressao[] = [];
  if (dados.listaProdutoServico && dados.listaProdutoServico.length > 0) {
    listaItemPrimeiraPagina = dados.listaProdutoServico.slice(
      inicio,
      quantidadeItemPrimeiraPagina
    );
  } else {
    listaItemPrimeiraPagina = [];
  }

  let numeroLinhas = 0;
  let numeroItens = 0;

  for (let i = 0; i < listaItemPrimeiraPagina.length; i++) {
    const sizeCodigo = CalculateSize(
      listaItemPrimeiraPagina[i].produtoServico.codigo,
      { font: 'Tinos', fontSize: '6px', fontWeight: 'bold' }
    );
    const sizeDescricao = CalculateSize(
      listaItemPrimeiraPagina[i].produtoServico.descricao,
      { font: 'Tinos', fontSize: '6px', fontWeight: 'bold' }
    );
    const numeroLinhasCodigo = Math.ceil(sizeCodigo.width / tamanhoCodigo);

    const numeroLinhasDescricao = Math.ceil(
      sizeDescricao.width / tamanhoDescricao
    );

    numeroLinhas +=
      numeroLinhasCodigo > numeroLinhasDescricao
        ? numeroLinhasCodigo
        : numeroLinhasDescricao;

    if (numeroLinhas > quantidadeItemPrimeiraPagina) break;
    numeroItens += 1;
  }

  if (numeroItens < listaItemPrimeiraPagina.length) {
    quantidadeItemPrimeiraPagina = numeroItens;

    listaItemPrimeiraPagina = dados.listaProdutoServico.slice(
      inicio,
      quantidadeItemPrimeiraPagina
    );
  }

  listaProdutoServico.push(
    listaItemPrimeiraPagina as INotaFiscalProdutoServicoImpressao[]
  );

  let possuiItensInserir =
    dados.listaProdutoServico.slice(quantidadeItemPrimeiraPagina).length > 0;
  inicio = quantidadeItemPrimeiraPagina;

  while (possuiItensInserir) {
    let fim = inicio + quantidadeItemPorPagina;

    let lista = dados.listaProdutoServico.slice(inicio, fim);

    const quantidadeLinha = fim - inicio;
    let numeroLinhasRestante = 0;
    let numeroItensRestante = 0;

    for (let j = 0; j < lista.length; j++) {
      const sizeCodigo = CalculateSize(lista[j].produtoServico.codigo, {
        font: 'Tinos',
        fontSize: '6px',
        fontWeight: 'bold',
      });

      const sizeDescricao = CalculateSize(lista[j].produtoServico.descricao, {
        font: 'Tinos',
        fontSize: '6px',
        fontWeight: 'bold',
      });

      const numeroLinhasCodigo = Math.ceil(sizeCodigo.width / tamanhoCodigo);
      const numeroLinhasDescricao = Math.ceil(
        sizeDescricao.width / tamanhoDescricao
      );

      numeroLinhasRestante +=
        numeroLinhasCodigo > numeroLinhasDescricao
          ? numeroLinhasCodigo
          : numeroLinhasDescricao;

      if (numeroLinhasRestante > quantidadeLinha) break;
      numeroItensRestante += 1;
    }

    if (numeroItensRestante < lista.length) {
      fim = inicio + numeroItensRestante;
      lista = dados.listaProdutoServico.slice(inicio, fim);
    }

    listaProdutoServico.push(lista as INotaFiscalProdutoServicoImpressao[]);

    let itensInseridos = 0;
    for (let i = 0; i < listaProdutoServico.length; i++) {
      itensInseridos += listaProdutoServico[i].length;
    }

    inicio = itensInseridos;

    if (itensInseridos === dados.listaProdutoServico.length) {
      possuiItensInserir = false;
    }
  }

  return listaProdutoServico;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerAssinatura: {
    flexDirection: 'row',
    height: 35,
    fontSize: 5,
    fontWeight: 'bold',
    justifyContent: 'center',
  },

  headerGrupo: {
    marginTop: '5px',
    fontSize: 6,
    fontWeight: 'bold',
  },
});

Font.registerHyphenationCallback((word) => [word]);

const NotaFiscalImpressaoPDF: React.FC<INotaFiscalImpressaoPDF> = ({
  dados,
}) => {
  const fatura = ObterFatura(dados.listaFatura);
  const listaProdutoServico = ObterListaProdutoServico(
    dados,
    fatura.quantidadeLinhas
  );

  const listaDadosAdicionais = ObterListaDadosAdicionais(
    dados.informacaoComplementares
  );

  const quantidadePaginas = Math.max(
    listaProdutoServico.length,
    listaDadosAdicionais.length
  );

  const listaPagina = Array.from({ length: quantidadePaginas }, (_, k) => k);

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerAssinatura}>
          <View style={{ width: '85%' }}>
            <View style={{ width: '100%', flexDirection: 'column' }}>
              <View
                style={{
                  height: '30%',
                  justifyContent: 'center',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ marginLeft: '5px' }}>
                  <Text>
                    RECEBIDO DE {dados.empresa.nomeRazaoSocial.toUpperCase()} OS
                    PRODUTOS E/OU SERVIÇOS CONSTANTES DA NOTA FISCAL ELETRÔNICA
                    INDICADA AO LADO
                  </Text>
                </View>
              </View>

              <View style={{ height: '70%' }}>
                <View style={{ height: '100%', flexDirection: 'row' }}>
                  <View
                    style={{
                      width: '20%',
                      border: '1 solid black',
                      borderRadius: 5,
                    }}
                  >
                    <View style={{ marginLeft: '5px' }}>
                      <Text>DATA DE RECEBIMENTO</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: '80%',
                      border: '1 solid black',
                      borderRadius: 5,
                    }}
                  >
                    <View style={{ marginLeft: '5px' }}>
                      <Text>IDENTIFICAÇÃO E ASSINATURA DO RECEBEDOR</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              width: '15%',
              fontSize: 9,
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1 solid black',
              borderRadius: 5,
            }}
          >
            <Text>NF-e</Text>
            <Text>Nº {dados.numero}</Text>
            <Text>SÉRIE: {dados.serie.codigo}</Text>
          </View>
        </View>

        <View
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            border: '1 dotted black',
          }}
        />

        <NotaFiscalImpressaoCabecalhoPDF dados={dados} />

        <View>
          <View style={styles.headerGrupo}>
            <Text>DESTINATÁRIO REMETENTE</Text>
          </View>

          <View style={{ flexDirection: 'row', height: 70 }}>
            <View style={{ width: '85%' }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '80%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>NOME / RAZÃO SOCIAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.destinatario?.nomeRazaoSocial}</Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CNPJ / CPF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCnpjCpf(dados.destinatario?.cnpjCpf)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '34%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '60%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>ENDEREÇO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {dados.destinatario?.destinatarioEndereco?.logradouro}
                          {dados.destinatario?.destinatarioEndereco?.numero &&
                            `, ${dados.destinatario.destinatarioEndereco.numero}`}
                          {dados.destinatario?.destinatarioEndereco
                            ?.complemento &&
                            `, ${dados.destinatario.destinatarioEndereco.complemento}`}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>BAIRRO / DISTRITO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {dados.destinatario?.destinatarioEndereco?.bairro}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CEP</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCep(
                            dados.destinatario?.destinatarioEndereco?.cep
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '45%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>MUNICÍPIO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {
                            dados.destinatario?.destinatarioEndereco?.cidade
                              .nome
                          }
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '25%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>FONE / FAX</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarTelefone(
                            dados.destinatario?.destinatarioEndereco?.fone
                          )}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '5%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>UF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {
                            dados.destinatario?.destinatarioEndereco?.cidade
                              .estado.sigla
                          }
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '25%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>INSCRIÇÃO ESTADUAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.destinatario?.inscricaoEstadual}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ width: '14%', marginLeft: '5px' }}>
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    height: '33%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>DATA DE EMISSÃO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{FormatarDataParaPtBr(dados.dataHoraEmissao)}</Text>
                  </View>
                </View>

                <View
                  style={{
                    height: '34%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>DATA DE ENTR. / SAÍDA</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {FormatarDataParaPtBr(dados.dataHoraEntradaSaida)}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    height: '33%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>HORA DA SAÍDA</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {FormatarHoraParaPtBr(dados.dataHoraEntradaSaida)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {dados.localRetirada && (
          <View>
            <View style={styles.headerGrupo}>
              <Text>INFORMAÇÕES DO LOCAL DE RETIRADA</Text>
            </View>

            <View style={{ flexDirection: 'row', height: 70 }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '60%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>NOME / RAZÃO SOCIAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.localRetirada.nomeRazaoSocial}</Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CNPJ / CPF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCnpjCpf(dados.localRetirada.cnpjCpf)}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>INSCRIÇÃO ESTADUAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.localRetirada.inscricaoEstadual}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '34%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '60%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>ENDEREÇO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {`${dados.localRetirada.localRetiradaEndereco.logradouro}, ${dados.localRetirada.localRetiradaEndereco.numero}`}
                          {dados.localRetirada.localRetiradaEndereco
                            .complemento &&
                            `, ${dados.localRetirada.localRetiradaEndereco.complemento}`}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>BAIRRO / DISTRITO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {dados.localRetirada.localRetiradaEndereco.bairro}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CEP</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCep(
                            dados.localRetirada?.localRetiradaEndereco.cep
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '75%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>MUNICÍPIO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {
                            dados.localRetirada.localRetiradaEndereco.cidade
                              .nome
                          }
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '5%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>UF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {
                            dados.localRetirada.localRetiradaEndereco.cidade
                              .estado.sigla
                          }
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>FONE / FAX</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarTelefone(dados.localRetirada.fone)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}

        {dados.localEntrega && (
          <View>
            <View style={styles.headerGrupo}>
              <Text>INFORMAÇÕES DO LOCAL DE ENTREGA</Text>
            </View>

            <View style={{ flexDirection: 'row', height: 70 }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '60%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>NOME / RAZÃO SOCIAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.localEntrega.nomeRazaoSocial}</Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CNPJ / CPF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCnpjCpf(dados.localEntrega.cnpjCpf)}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>INSCRIÇÃO ESTADUAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{dados.localEntrega.inscricaoEstadual}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '34%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '60%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>ENDEREÇO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {`${dados.localEntrega.localEntregaEndereco.logradouro}, ${dados.localEntrega.localEntregaEndereco.numero}`}
                          {dados.localEntrega.localEntregaEndereco
                            .complemento &&
                            `, ${dados.localEntrega.localEntregaEndereco.complemento}`}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>BAIRRO / DISTRITO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {dados.localEntrega.localEntregaEndereco.bairro}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>CEP</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {FormatarCep(
                            dados.localEntrega?.localEntregaEndereco.cep
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: '33%' }}>
                  <View style={{ flexDirection: 'row', height: '100%' }}>
                    <View
                      style={{
                        width: '75%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>MUNICÍPIO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          marginLeft: '5px',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {dados.localEntrega.localEntregaEndereco.cidade.nome}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '5%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>UF</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>
                          {
                            dados.localEntrega.localEntregaEndereco.cidade
                              .estado.sigla
                          }
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        width: '20%',
                        border: '1 solid black',
                        borderRadius: 5,
                      }}
                    >
                      <View style={{ marginLeft: '5px', fontSize: 6 }}>
                        <Text>FONE / FAX</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 9,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{FormatarTelefone(dados.localEntrega.fone)}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}

        {fatura.listaFatura && fatura.listaFatura.length > 0 && (
          <View>
            <View style={styles.headerGrupo}>
              <Text>FATURA</Text>
            </View>

            <View
              style={{
                flexDirection: 'column',
                border: '1 solid black',
                borderRadius: 5,
                height: fatura.tamanho,
              }}
            >
              <View style={{ height: 8 }}>
                <View style={{ flexDirection: 'row', fontSize: 6 }}>
                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>DUPLICATA</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>VENCIMENTO</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>VALOR</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>DUPLICATA</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>VENCIMENTO</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>VALOR</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>DUPLICATA</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                      borderRight: '1 solid black',
                    }}
                  >
                    <Text>VENCIMENTO</Text>
                  </View>

                  <View
                    style={{
                      width: '11.10%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderBottom:
                        fatura.listaFatura.length > 0
                          ? '1 solid black'
                          : undefined,
                    }}
                  >
                    <Text>VALOR</Text>
                  </View>
                </View>
              </View>

              <View>
                <View style={{ flexDirection: 'row', fontSize: 6 }}>
                  <View style={{ width: '100%' }}>
                    {(() => {
                      const listaRetorno: any = [];

                      for (let i = 0; i < fatura.listaFatura.length; i += 3) {
                        const retorno = (
                          <View style={{ flexDirection: 'row' }}>
                            <View
                              style={{
                                justifyContent: 'center',
                                width: '33.32%',
                                borderRight: '1 solid black',
                                borderBottom:
                                  i + 3 >= fatura.listaFatura.length
                                    ? undefined
                                    : '1 solid black',
                              }}
                            >
                              <NotaFiscalImpressaoFaturaPDF
                                fatura={fatura.listaFatura[i]}
                              />
                            </View>

                            <View
                              style={{
                                justifyContent: 'center',
                                width: '33.31%',
                                borderRight: '1 solid black',
                                borderBottom:
                                  i + 3 >= fatura.listaFatura.length
                                    ? '0'
                                    : '1 solid black',
                              }}
                            >
                              <NotaFiscalImpressaoFaturaPDF
                                fatura={fatura.listaFatura[i + 1]}
                              />
                            </View>

                            <View
                              style={{
                                justifyContent: 'center',
                                width: '33.31%',
                                borderBottom:
                                  i + 3 >= fatura.listaFatura.length
                                    ? '0'
                                    : '1 solid black',
                              }}
                            >
                              <NotaFiscalImpressaoFaturaPDF
                                fatura={fatura.listaFatura[i + 2]}
                              />
                            </View>
                          </View>
                        );

                        listaRetorno.push(retorno);
                      }

                      return listaRetorno;
                    })()}
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}

        <View>
          <View style={styles.headerGrupo}>
            <Text>CÁLCULO DO IMPOSTO</Text>
          </View>

          <View style={{ flexDirection: 'column', height: 50 }}>
            <View style={{ height: '50%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>BASE DE CÁLCULO ICMS</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.baseCalculoIcms).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR DO ICMS</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.valorIcms).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>BASE DE CÁLCULO ICMS ST.</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(
                        dados.imposto.baseCalculoIcmsSubstituicao
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR DO ICMS SUBSTITUIÇÃO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(
                        dados.imposto.valorIcmsSubstituicao
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR TOTAL DOS PRODUTOS</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(
                        dados.imposto.valorTotalProdutos
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ height: '50%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '16.3%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR DO FRETE</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.valorFrete).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.3%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR DO SEGURO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.valorSeguro).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.3%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>DESCONTO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.desconto).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '18.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>OUTRAS DESPESAS ACESSÓRIAS</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(
                        dados.imposto.outrasDespesasAcessorias
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.3%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR DO IPI</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.valorIpi).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.3%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR TOTAL DA NOTA</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {Number(dados.imposto.valorTotalNota).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View>
          <View style={styles.headerGrupo}>
            <Text>TRANSPORTADOR / VOLUMES TRANSPORTADOS</Text>
          </View>

          <View style={{ flexDirection: 'column', height: 70 }}>
            <View style={{ height: '33%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '40%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text> RAZÃO SOCIAL</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {dados.transporte.transportador?.nomeRazaoSocial}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '15%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>FRETE POR CONTA</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.fretePorConta}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '10%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>CÓDIGO ANTT</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.veiculo?.codigoAntt}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '13%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>PLACA DO VEÍCULO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.veiculo?.placa}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>UF</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.veiculo?.estado.sigla}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '17%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>CNPJ / CPF</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {FormatarCnpjCpf(dados.transporte.transportador?.cnpjCpf)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ height: '34%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '40%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>ENDEREÇO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {
                        dados.transporte.transportador?.transpotadorEndereco
                          ?.endereco
                      }
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '35%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>MUNICÍPIO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {
                        dados.transporte.transportador?.transpotadorEndereco
                          ?.cidade?.nome
                      }
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>UF</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {
                        dados.transporte.transportador?.transpotadorEndereco
                          ?.cidade?.estado?.sigla
                      }
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '20%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>INSCRIÇÃO ESTADUAL</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      {dados.transporte.transportador?.inscricaoEstadual}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ height: '33%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '16.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>QUANTIDADE</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.volume.quantidade}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>ESPÉCIE</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.volume.especie}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>MARCA</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.volume.marca}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>NÚMERAÇÃO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginLeft: '5px',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{dados.transporte.volume.numeracao}</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '16.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>PESO BRUTO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.transporte.volume.pesoBruto && (
                      <Text>
                        {Number(
                          dados.transporte.volume.pesoBruto
                        ).FormatarParaPtBr()}
                        {' Kg'}
                      </Text>
                    )}
                  </View>
                </View>

                <View
                  style={{
                    width: '17.5%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>PESO LÍQUIDO</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    {dados.transporte.volume.pesoLiquido && (
                      <Text>
                        {Number(
                          dados.transporte.volume.pesoLiquido
                        ).FormatarParaPtBr()}
                        {' Kg'}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <NotaFiscalImpressaoProdutoServicoPDF
          listaProdutoServico={listaProdutoServico[0]}
        />

        <View>
          <View style={styles.headerGrupo}>
            <Text>CÁLCULO DO ISSQN</Text>
          </View>

          <View style={{ flexDirection: 'row', height: 25 }}>
            <View
              style={{
                width: '25%',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ marginLeft: '5px', fontSize: 6 }}>
                <Text>INSCRIÇÃO MUNICIPAL</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>{dados.icmsIssqn?.inscricaoMunicipal}</Text>
              </View>
            </View>

            <View
              style={{
                width: '25%',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ marginLeft: '5px', fontSize: 6 }}>
                <Text>VALOR TOTAL DOS SERVIÇOS</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <Text>
                  {dados.icmsIssqn?.valorTotalServicos &&
                    Number(
                      dados.icmsIssqn.valorTotalServicos
                    ).FormatarParaPtBr()}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: '25%',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ marginLeft: '5px', fontSize: 6 }}>
                <Text>BASE DE CÁLCULO ISSQN</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <Text>
                  {dados.icmsIssqn?.baseCalculoIssqn &&
                    Number(dados.icmsIssqn.baseCalculoIssqn).FormatarParaPtBr()}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: '25%',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ marginLeft: '5px', fontSize: 6 }}>
                <Text>VALOR DO ISSQN</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <Text>
                  {dados.icmsIssqn?.valorIssqn &&
                    Number(dados.icmsIssqn.valorIssqn).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <NotaFiscalImpressaoDadosAdicionaisPDF
          dadosAdicionais={listaDadosAdicionais[0]}
        />

        <View
          fixed
          style={{
            fontSize: 9,
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text>https://www.elogestor.com.br</Text>
        </View>

        {(dados.situacao === SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
          dados.situacao === SituacaoNotaFiscalEntradaEnum.naoTransmitida ||
          dados.situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao ||
          dados.situacao === SituacaoNotaFiscalEntradaEnum.emTransmissao ||
          dados.situacao === SituacaoNotaFiscalSaidaEnum.rejeitada ||
          dados.situacao === SituacaoNotaFiscalEntradaEnum.rejeitada) && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.1,
              }}
            >
              <Text>VISUALIZAÇÃO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
            </View>
          </View>
        )}

        {(dados.situacao === SituacaoNotaFiscalSaidaEnum.denegada ||
          dados.situacao === SituacaoNotaFiscalEntradaEnum.denegada) && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.1,
              }}
            >
              <Text>USO DENEGADO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
            </View>
          </View>
        )}

        {(dados.situacao === SituacaoNotaFiscalSaidaEnum.cancelada ||
          dados.situacao === SituacaoNotaFiscalEntradaEnum.cancelada) && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.1,
              }}
            >
              <Text>CANCELADA</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
            </View>
          </View>
        )}
      </Page>

      {listaPagina.map((index) => {
        if (index === 0) return <></>;

        return (
          <Page
            key={String(index)}
            style={styles.pagina}
            orientation="portrait"
          >
            <NotaFiscalImpressaoCabecalhoPDF dados={dados} />

            <NotaFiscalImpressaoProdutoServicoPDF
              listaProdutoServico={listaProdutoServico[index]}
            />

            <NotaFiscalImpressaoDadosAdicionaisPDF
              dadosAdicionais={listaDadosAdicionais[index]}
            />

            <View
              fixed
              style={{
                fontSize: 9,
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Text>https://www.elogestor.com.br</Text>
            </View>

            {(dados.situacao === SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
              dados.situacao === SituacaoNotaFiscalEntradaEnum.naoTransmitida ||
              dados.situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao ||
              dados.situacao === SituacaoNotaFiscalEntradaEnum.emTransmissao ||
              dados.situacao === SituacaoNotaFiscalSaidaEnum.rejeitada ||
              dados.situacao === SituacaoNotaFiscalEntradaEnum.rejeitada) && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.1,
                  }}
                >
                  <Text>VISUALIZAÇÃO</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
                </View>
              </View>
            )}

            {(dados.situacao === SituacaoNotaFiscalSaidaEnum.denegada ||
              dados.situacao === SituacaoNotaFiscalEntradaEnum.denegada) && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.1,
                  }}
                >
                  <Text>USO DENEGADO</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
                </View>
              </View>
            )}

            {(dados.situacao === SituacaoNotaFiscalSaidaEnum.cancelada ||
              dados.situacao === SituacaoNotaFiscalEntradaEnum.cancelada) && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.1,
                  }}
                >
                  <Text>CANCELADA</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JÚRIDICA</Text>
                </View>
              </View>
            )}
          </Page>
        );
      })}
    </Document>
  );
};

export default NotaFiscalImpressaoPDF;
