import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

export default function ToastAviso(
  mensagem: string,
  opcoes?: ToastOptions
): void {
  const mensagemComIcone = (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '	#363636',
      }}
    >
      {mensagem}
    </span>
  );
  toast.warn(mensagemComIcone, opcoes);
}
