/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputInteiroProps {
  valorAnterior: string;
}

export interface IInputInteiroRef {
  focus(): void;
}

interface IInputInteiroProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputInteiroProps
  ) => void | Promise<void>;
}

const InputInteiro: React.ForwardRefRenderFunction<
  IInputInteiroRef,
  IInputInteiroProps
> = ({ name, label, onFocus, onChange, onBlur, ...rest }, ref) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';
      if (onFocus) onFocus(event);

      const tamanho = event.target.value.length;

      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus]
  );
  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      setIsFocused(false);

      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<number>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        if (inputRef.current?.value) {
          return Number(inputRef.current.value);
        }
        return 0;
      },

      setValue(_, value = 0) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;

        if (!value) {
          inputRef.current.value = '0';
        } else {
          inputRef.current.value = String(value);
        }
      },

      setSemExecutarEvento(_, value = 0) {
        if (!inputRef.current) return;
        valorAnterior.current = inputRef.current.value;

        if (!value) {
          inputRef.current.value = '0';
        } else {
          inputRef.current.value = String(value);
        }
      },

      clearValue(_, valorInicial = 0) {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = 0) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        style={{ textAlign: 'right' }}
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={(event) => {
          setErro('');
          const valor = event.target.value.replace(/\D+/g, '');

          event.target.value = valor || '0';
          if (onChange)
            onChange(event, { valorAnterior: valorAnterior.current });
        }}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputInteiro);
