import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { ISituacaoLista } from '@elogestor/util';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import PesquisaAvancada from './PesquisaAvançada';
import Lista from '../../../../../Componentes/Lista';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import SituacaoComunicador from '../../../../../Comunicador/Servicos/OrdensServicos/Situacao/Comunicador/SituacaoComunicador';
import SituacaoLogComunicador from '../../../../../Comunicador/Servicos/OrdensServicos/Situacao/Comunicador/Log/Comunicador/SituacaoLogComunicador';

const SituacaoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);
  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await SituacaoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: ISituacaoLista) => {
          return {
            id: data.id,
            descricao: <div className="lista-texto">{data.descricao}</div>,
            ativo: (
              <div
                className="lista-booleano"
                style={{ color: data.ativo ? 'green' : 'red' }}
              >
                {data.ativo ? 'Sim' : 'Não'}
              </div>
            ),
            permiteGerarNota: (
              <div
                className="lista-booleano"
                style={{ color: data.permiteGerarNota ? 'green' : 'red' }}
              >
                {data.permiteGerarNota ? 'Sim' : 'Não'}
              </div>
            ),
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await SituacaoComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = SituacaoLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    permissoes.ServicoOrdensServicosSituacao?.exclui
                  }
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ServicoOrdensServicosSituacao]
  );
  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'servicosSituacao' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Situacação"
          permissao={permissoes.ServicoOrdensServicosSituacao}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return SituacaoLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default SituacaoLista;
