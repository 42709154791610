import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario';

interface IUsuarioComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IUsuarioComunicadorShow {
  id: string;
}

interface IUsuarioComunicadorStore {
  params: any;
}

interface IUsuarioComunicadorUpdate {
  id: string;
  params: any;
}

interface IUsuarioComunicadorDeletar {
  id: string;
}

class UsuarioComunicador {
  public async index(params: IUsuarioComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IUsuarioComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IUsuarioComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IUsuarioComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IUsuarioComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new UsuarioComunicador();
