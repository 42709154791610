import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/origem-icms-auto-complete';

interface IOrigemIcmsAutoCompleteComunicadorIndex {
  params: any;
}

class OrigemIcmsAutoCompleteComunicador {
  public async index(
    params: IOrigemIcmsAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OrigemIcmsAutoCompleteComunicador();
