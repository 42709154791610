"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegraEscolhaTipoEnum;
(function (RegraEscolhaTipoEnum) {
    RegraEscolhaTipoEnum["padrao"] = "Padr\u00E3o";
    RegraEscolhaTipoEnum["contabilidade"] = "Contabilidade";
    RegraEscolhaTipoEnum["empresa"] = "Empresa";
})(RegraEscolhaTipoEnum || (RegraEscolhaTipoEnum = {}));
exports.default = RegraEscolhaTipoEnum;
