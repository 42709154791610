"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegistroC140IndicadorTipoTituloCreditoEnum;
(function (RegistroC140IndicadorTipoTituloCreditoEnum) {
    RegistroC140IndicadorTipoTituloCreditoEnum["duplicata"] = "00";
    RegistroC140IndicadorTipoTituloCreditoEnum["cheque"] = "01";
    RegistroC140IndicadorTipoTituloCreditoEnum["promissoria"] = "02";
    RegistroC140IndicadorTipoTituloCreditoEnum["recibo"] = "03";
    RegistroC140IndicadorTipoTituloCreditoEnum["outros"] = "04";
})(RegistroC140IndicadorTipoTituloCreditoEnum || (RegistroC140IndicadorTipoTituloCreditoEnum = {}));
exports.default = RegistroC140IndicadorTipoTituloCreditoEnum;
