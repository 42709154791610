import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

const ItemFunruralTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const { calcularImpostosPorCst } = UseItemTab();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const calcularFunrural = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemFunrural.calcular'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                disabled={situacaoBloquearCampos}
                label="Calcular Funrural"
                name="notaFiscalEntradaItemFunrural.calcular"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={6}>
            <InputDecimalNulavel
              disabled={situacaoBloquearCampos || calcularFunrural}
              label="Base Cálculo"
              name="notaFiscalEntradaItemFunrural.baseCalculo"
              placeholder="Base Cálculo"
              casasInteiras={15}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
          <Col lg={4} md={4} sm={6}>
            <InputDecimalNulavel
              disabled={situacaoBloquearCampos || calcularFunrural}
              label="Alíquota"
              name="notaFiscalEntradaItemFunrural.aliquota"
              placeholder="Alíquota"
              casasInteiras={5}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
          <Col lg={4} md={4} sm={6}>
            <InputDecimalNulavel
              disabled={situacaoBloquearCampos || calcularFunrural}
              label="Valor"
              name="notaFiscalEntradaItemFunrural.valor"
              placeholder="Valor"
              casasInteiras={15}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemFunruralTab;
