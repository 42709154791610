import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

export default function ToastErro(
  mensagem: string,
  opcoes?: ToastOptions
): void {
  const mensagemSeparada = mensagem.split('\n');

  const mensagemFinal = (
    <span style={{ display: 'block' }}>
      {mensagemSeparada.map((linha, index) => (
        <React.Fragment key={index}>
          {linha}
          {index < mensagemSeparada.length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  );

  toast.error(mensagemFinal, opcoes);
}
