import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-pedido-venda/obter-pedido-venda-item';

interface INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicadorIndex {
  listaIdPedidoVenda: string[];
}

class NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaItemComunicador();
