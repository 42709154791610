const AdiantamentosPorLiquidacaoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'chave',
  },

  {
    titulo: 'Número documento',
    valor: 'listaConta.numeroDocumento',
  },

  {
    titulo: 'Cliente/Fornecedor',
    valor: 'listaConta.cliente',
  },

  {
    titulo: 'Tipo Adiantamento',
    valor: 'listaConta.tipoAdiantamento',
  },

  {
    titulo: 'Valor Liquidação',
    valor: 'listaConta.valorLiquidacao',
  },
];

export default AdiantamentosPorLiquidacaoPlanilha;
