const PessoaExportacaoPlanilha = [
  {
    titulo: 'ativo',
    valor: 'ativo',
  },
  {
    titulo: 'codigo',
    valor: 'codigo',
  },
  {
    titulo: 'nomeRazaoSocial',
    valor: 'nomeRazaoSocial',
  },
  {
    titulo: 'nomeFantasia',
    valor: 'nomeFantasia',
  },
  {
    titulo: 'cnpj',
    valor: 'cnpj',
  },
  {
    titulo: 'cpf',
    valor: 'cpf',
  },
  {
    titulo: 'inscricaoEstadual',
    valor: 'inscricaoEstadual',
  },
  {
    titulo: 'inscricaoMunicipal',
    valor: 'inscricaoMunicipal',
  },
  {
    titulo: 'contribuinteIcms',
    valor: 'contribuinteIcms',
  },
  {
    titulo: 'cliente',
    valor: 'cliente',
  },
  {
    titulo: 'fornecedor',
    valor: 'fornecedor',
  },
  {
    titulo: 'transportador',
    valor: 'transportador',
  },
  {
    titulo: 'representante',
    valor: 'representante',
  },
  {
    titulo: 'produtor',
    valor: 'produtor',
  },
  {
    titulo: 'motorista',
    valor: 'motorista',
  },
  {
    titulo: 'tecnico',
    valor: 'tecnico',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.cep',
    valor: 'pessoaEnderecoPrincipal.cep',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.cidade.nome',
    valor: 'pessoaEnderecoPrincipal.cidade.nome',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.cidade.estado.sigla',
    valor: 'pessoaEnderecoPrincipal.cidade.estado.sigla',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.cidade.ibge',
    valor: 'pessoaEnderecoPrincipal.cidade.ibge',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.logradouro',
    valor: 'pessoaEnderecoPrincipal.logradouro',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.numero',
    valor: 'pessoaEnderecoPrincipal.numero',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.bairro',
    valor: 'pessoaEnderecoPrincipal.bairro',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.complemento',
    valor: 'pessoaEnderecoPrincipal.complemento',
  },
  {
    titulo: 'pessoaEnderecoPrincipal.site',
    valor: 'pessoaEnderecoPrincipal.site',
  },
  {
    titulo: 'listaPessoaTelefone.numero',
    valor: 'listaPessoaTelefone.dddNumeroVirtual',
  },
  {
    titulo: 'listaPessoaEmail.email',
    valor: 'listaPessoaEmail.email',
  },
  {
    titulo: 'ramoAtividadeProfissao.descricao',
    valor: 'ramoAtividadeProfissao.descricao',
  },
  {
    titulo: 'registroMinisterioAgricultura',
    valor: 'registroMinisterioAgricultura',
  },
  {
    titulo: 'inscricaoSuframa',
    valor: 'inscricaoSuframa',
  },
  {
    titulo: 'regimeTributario',
    valor: 'regimeTributario',
  },
  {
    titulo: 'observacoes',
    valor: 'observacoes',
  },
  {
    titulo: 'dataCadastro',
    valor: 'dataCadastro',
  },
];

export default PessoaExportacaoPlanilha;
