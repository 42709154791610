"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidarInscricao = ValidarInscricao;
exports.default = ValidarInscricaoEstadual;
/* eslint-disable no-constant-condition */
/* eslint-disable no-cond-assign */
/* eslint-disable no-case-declarations */
function ValidarInscricao(ie, uf) {
    ie = ie
        .replace(/\./g, '')
        .replace(/\\/g, '')
        .replace(/-/g, '')
        .replace(/\//g, '');
    if (ie === 'ISENTO')
        return true;
    let b = 0;
    let soma = 0;
    let i = 0;
    let dig = 0;
    const nro = [];
    switch (uf) {
        // #region Acre
        case 'AC':
            if (ie.length !== 13)
                return false;
            b = 4;
            soma = 0;
            for (i = 0; i <= 10; i++) {
                soma += Number(ie.charAt(i)) * b;
                --b;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            if (dig !== Number(ie.charAt(11)))
                return false;
            b = 5;
            soma = 0;
            for (i = 0; i <= 11; i++) {
                soma += Number(ie.charAt(i)) * b;
                --b;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            return dig === Number(ie.charAt(12));
        // #endregion Acre
        // #region Alagoas
        case 'AL':
            if (ie.length !== 9)
                return false;
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += Number(ie.charAt(i)) * b;
                --b;
            }
            soma *= 10;
            dig = soma - Math.floor(soma / 11) * 11;
            if (dig === 10)
                dig = 0;
            return dig === Number(ie.charAt(8));
        // #endregion Alagoas
        // #region Amapa
        case 'AP':
            if (ie.length !== 9)
                return false;
            if (ie.substring(0, 2) !== '03')
                return false;
            let p = 0;
            let d = 0;
            i = Number(ie.substring(1, 8));
            if (i >= 3000001 && i <= 3017000) {
                p = 5;
                d = 0;
            }
            else if (i >= 3017001 && i <= 3019022) {
                p = 9;
                d = 1;
            }
            b = 9;
            soma = p;
            for (i = 0; i <= 7; i++) {
                soma += Number(ie.charAt(i)) * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig === 10) {
                dig = 0;
            }
            else if (dig === 11) {
                dig = d;
            }
            return dig === Number(ie.charAt(8));
        // #endregion Amapa
        // #region Amazonas
        case 'AM':
            if (ie.length !== 9)
                return false;
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += Number(ie.charAt(i)) * b;
                b--;
            }
            if (soma < 11) {
                dig = 11 - soma;
            }
            else {
                i = soma % 11;
                if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = 11 - i;
                }
            }
            return dig === Number(ie.charAt(8));
        // #endregion Amazonas
        // #region Bahia
        case 'BA':
            if (ie.length === 8) {
                const die = ie.substring(0, 8);
                dig = -1;
                for (i = 0; i <= 7; i++)
                    nro[i] = Number(die.charAt(i));
                let NumMod = 0;
                if (String(nro[0]).match(/[0123458]/))
                    NumMod = 10;
                else
                    NumMod = 11;
                b = 7;
                soma = 0;
                for (i = 0; i <= 5; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                i = soma % NumMod;
                if (NumMod === 10) {
                    if (i === 0) {
                        dig = 0;
                    }
                    else {
                        dig = NumMod - i;
                    }
                }
                else if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = NumMod - i;
                }
                const resultado = dig === nro[7];
                if (!resultado)
                    return false;
                b = 8;
                soma = 0;
                for (i = 0; i <= 5; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                soma += nro[7] * 2;
                i = soma % NumMod;
                if (NumMod === 10) {
                    if (i === 0) {
                        dig = 0;
                    }
                    else {
                        dig = NumMod - i;
                    }
                }
                else if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = NumMod - i;
                }
                return dig === nro[6];
            }
            if (ie.length === 9) {
                const die = ie.substring(0, 9);
                dig = -1;
                for (i = 0; i <= 8; i++)
                    nro[i] = Number(die.charAt(i));
                let NumMod = 0;
                if (String(nro[1]).match(/[0123458]/))
                    NumMod = 10;
                else
                    NumMod = 11;
                b = 8;
                soma = 0;
                for (i = 0; i <= 6; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                i = soma % NumMod;
                if (NumMod === 10) {
                    if (i === 0) {
                        dig = 0;
                    }
                    else {
                        dig = NumMod - i;
                    }
                }
                else if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = NumMod - i;
                }
                const resultado = dig === nro[8];
                if (!resultado)
                    return false;
                b = 9;
                soma = 0;
                for (i = 0; i <= 6; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                soma += nro[8] * 2;
                i = soma % NumMod;
                if (NumMod === 10) {
                    if (i === 0) {
                        dig = 0;
                    }
                    else {
                        dig = NumMod - i;
                    }
                }
                else if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = NumMod - i;
                }
                return dig === nro[7];
            }
            return false;
        // #endregion Bahia
        // #region Ceara
        case 'CE':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie[i]);
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            return dig === nro[8];
        // #endregion Ceara
        // #region Distrito Federal
        case 'DF':
            if (ie.length !== 13)
                return false;
            for (i = 0; i <= 12; i++)
                nro[i] = Number(ie.charAt(i));
            b = 4;
            soma = 0;
            for (i = 0; i <= 10; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            if (dig !== nro[11])
                return false;
            b = 5;
            soma = 0;
            for (i = 0; i <= 11; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            return dig === nro[12];
        // #endregion Distrito Federal
        // #region Espirito Santo
        case 'ES':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i < 2) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[8];
        // #endregion Espirito Santo
        // #region Goias
        case 'GO':
            if (ie.length !== 9)
                return false;
            const s = ie.substring(0, 2);
            if (s === '10' || s === '11' || s === '15' || s === '20') {
                for (i = 0; i <= 8; i++)
                    nro[i] = Number(ie.charAt(i));
                let n = Number(ie.substring(0, 7));
                if ((n = 11094402)) {
                    if (nro[8] === 0 || nro[8] === 1)
                        return true;
                }
                b = 9;
                soma = 0;
                for (i = 0; i <= 7; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                i = soma % 11;
                if (i === 0)
                    dig = 0;
                else if (i === 1) {
                    if (n >= 10103105 && n <= 10119997) {
                        dig = 1;
                    }
                    else {
                        dig = 0;
                    }
                }
                else
                    dig = 11 - i;
                return dig === nro[8];
            }
            return false;
        // #endregion Goias
        // #region Maranhao
        case 'MA':
            if (ie.length !== 9)
                return false;
            if (ie.substring(0, 2) !== '12')
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[8];
        // #endregion Maranhao
        // #region Minas Gerais
        case 'MG':
            if (ie.length !== 13)
                return false;
            const dig1 = ie.substring(11, 12);
            const dig2 = ie.substring(12, 13);
            const inscC = `${ie.substring(0, 3)}0${ie.substring(3, 11)}`;
            const insc = inscC.split('');
            i = 1;
            let npos = 11;
            let ptotal = 0;
            let psoma = 0;
            while (npos >= 0) {
                i++;
                psoma = Number(insc[npos]) * i;
                if (psoma >= 10)
                    psoma -= 9;
                ptotal += psoma;
                if (i === 2)
                    i = 0;
                npos--;
            }
            let nresto = ptotal % 10;
            if (nresto === 0)
                nresto = 10;
            nresto = 10 - nresto;
            if (nresto !== Number(dig1))
                return false;
            npos = 11;
            i = 1;
            ptotal = 0;
            const is = ie.split('');
            while (npos >= 0) {
                i++;
                if (i === 12)
                    i = 2;
                ptotal += Number(is[npos]) * i;
                npos--;
            }
            nresto = ptotal % 11;
            if (nresto === 0 || nresto === 1)
                nresto = 11;
            nresto = 11 - nresto;
            return nresto === Number(dig2);
        // #endregion Minas Gerais
        // #region Mato Grosso do Sul
        case 'MS':
            if (ie.length !== 9)
                return false;
            if (ie.substring(0, 2) !== '28')
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[8];
        // #endregion Mato Grosso do Sul
        // #region Mato Grosso
        case 'MT':
            if (ie.length !== 11)
                return false;
            const dieMT = ie;
            for (i = 0; i <= 10; i++)
                nro[i] = Number(dieMT[i]);
            b = 3;
            soma = 0;
            for (i = 0; i <= 9; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 9;
            }
            i = soma % 11;
            if (i <= 1) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[10];
        // #endregion Mato Grosso
        // #region Para
        case 'PA':
            if (ie.length !== 9)
                return false;
            if (ie.substring(0, 2) !== '15')
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1)
                dig = 0;
            else
                dig = 11 - i;
            return dig === nro[8];
        // #endregion Para
        // #region Paraiba
        case 'PB':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1)
                dig = 0;
            else
                dig = 11 - i;
            return dig === nro[8];
        // #endregion Paraiba
        // #region Pernanbuco
        case 'PE':
            // IE antiga com 14 digitos
            if (ie.length === 14) {
                for (i = 0; i <= 13; i++)
                    nro[i] = Number(ie.charAt(i));
                b = 5;
                soma = 0;
                for (i = 0; i <= 12; i++) {
                    soma += nro[i] * b;
                    b--;
                    if (b === 0)
                        b = 9;
                }
                dig = 11 - (soma % 11);
                if (dig > 9)
                    dig -= 10;
                return dig === nro[13];
            }
            // IE nova com 9 digitos
            if (ie.length === 9) {
                for (i = 0; i <= 8; i++)
                    nro[i] = Number(ie.charAt(i));
                b = 8;
                soma = 0;
                for (i = 0; i <= 6; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                i = soma % 11;
                if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = 11 - i;
                }
                if (dig !== nro[7])
                    return false;
                b = 9;
                soma = 0;
                for (i = 0; i <= 7; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                i = soma % 11;
                if (i <= 1) {
                    dig = 0;
                }
                else {
                    dig = 11 - i;
                }
                return dig === nro[8];
            }
            return false;
        // #endregion Pernanbuco
        // #region Piaui
        case 'PI':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[8];
        // #endregion Piaui
        // #region Parana
        case 'PR':
            if (ie.length !== 10)
                return false;
            for (i = 0; i <= 9; i++)
                nro[i] = Number(ie.charAt(i));
            b = 3;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 7;
            }
            i = soma % 11;
            if (i <= 1)
                dig = 0;
            else
                dig = 11 - i;
            if (dig !== nro[8])
                return false;
            b = 4;
            soma = 0;
            for (i = 0; i <= 8; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 7;
            }
            i = soma % 11;
            if (i <= 1)
                dig = 0;
            else
                dig = 11 - i;
            return dig === nro[9];
        // #endregion Parana
        // #region Rio de Janeiro
        case 'RJ':
            if (ie.length !== 8)
                return false;
            for (i = 0; i <= 7; i++)
                nro[i] = Number(ie.charAt(i));
            b = 2;
            soma = 0;
            for (i = 0; i <= 6; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 7;
            }
            i = soma % 11;
            if (i <= 1) {
                dig = 0;
            }
            else {
                dig = 11 - i;
            }
            return dig === nro[7];
        // #endregion Rio de Janeiro
        // #region Rio Grande do Norte
        case 'RN':
            if (ie.substring(0, 2) !== '20')
                return false;
            // IE com 9 digitos
            if (ie.length === 9) {
                for (i = 0; i <= 8; i++)
                    nro[i] = Number(ie.charAt(i));
                b = 9;
                soma = 0;
                for (i = 0; i <= 7; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                soma *= 10;
                dig = soma % 11;
                if (dig === 10)
                    dig = 0;
                return dig === nro[8];
            }
            // IE com 10 digitos
            if (ie.length === 10) {
                for (i = 0; i <= 9; i++)
                    nro[i] = Number(ie.charAt(i));
                b = 10;
                soma = 0;
                for (i = 0; i <= 8; i++) {
                    soma += nro[i] * b;
                    b--;
                }
                soma *= 10;
                dig = soma % 11;
                if (dig === 10)
                    dig = 0;
                return dig === nro[9];
            }
            return false;
        // #endregion Rio Grande do Norte
        // #region Rondonia
        case 'RO':
            if (ie.length !== 14)
                return false;
            b = 6;
            soma = 0;
            for (i = 0; i <= 12; i++) {
                nro[i] = Number(ie.charAt(i));
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig -= 10;
            return dig === Number(ie.charAt(13));
        // #endregion Rondonia
        // #region Roraima
        case 'RR':
            if (ie.length !== 9)
                return false;
            if (ie.substring(0, 2) !== '24')
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            soma = 0;
            let n = 0;
            for (i = 0; i <= 7; i++)
                soma += nro[i] * ++n;
            dig = soma % 9;
            return dig === nro[8];
        // #endregion Roraima
        // #region Rio Grande do Sul
        case 'RS':
            if (ie.length !== 10)
                return false;
            for (i = 0; i <= 9; i++)
                nro[i] = Number(ie.charAt(i));
            b = 2;
            soma = 0;
            for (i = 0; i <= 8; i++) {
                soma += nro[i] * b;
                b--;
                if (b === 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            return dig === nro[9];
        // #endregion Rio Grande do Sul
        // #region Santa Catarina
        case 'SC':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1)
                dig = 0;
            else
                dig = 11 - i;
            return dig === nro[8];
        // #endregion Santa Catarina
        // #region Sergipe
        case 'SE':
            if (ie.length !== 9)
                return false;
            for (i = 0; i <= 8; i++)
                nro[i] = Number(ie.charAt(i));
            b = 9;
            soma = 0;
            for (i = 0; i <= 7; i++) {
                soma += nro[i] * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
                dig = 0;
            return dig === nro[8];
        // #endregion  Sergipe
        // #region Sao Paulo
        case 'SP':
            if (ie.length !== 12)
                return false;
            for (i = 0; i <= 11; i++)
                nro[i] = Number(ie.charAt(i));
            soma =
                nro[0] * 1 +
                    nro[1] * 3 +
                    nro[2] * 4 +
                    nro[3] * 5 +
                    nro[4] * 6 +
                    nro[5] * 7 +
                    nro[6] * 8 +
                    nro[7] * 10;
            dig = soma % 11;
            if (dig >= 10)
                dig = 0;
            if (dig !== nro[8])
                return false;
            soma =
                nro[0] * 3 +
                    nro[1] * 2 +
                    nro[2] * 10 +
                    nro[3] * 9 +
                    nro[4] * 8 +
                    nro[5] * 7 +
                    nro[6] * 6 +
                    nro[7] * 5 +
                    nro[8] * 4 +
                    nro[9] * 3 +
                    nro[10] * 2;
            dig = soma % 11;
            if (dig >= 10)
                dig = 0;
            return dig === nro[11];
        // #endregion Sao Paulo
        // #region Tocantins
        case 'TO':
            if (ie.length === 11) {
                const sTO = ie.substring(2, 4);
                if (sTO !== '01' && sTO !== '02' && sTO !== '03' && sTO !== '99')
                    return false;
                b = 9;
                soma = 0;
                for (i = 0; i <= 9; i++) {
                    nro[i] = Number(ie.charAt(i));
                    if (i !== 2 && i !== 3) {
                        soma += nro[i] * b;
                        b--;
                    }
                }
                const resto = soma % 11;
                if (resto < 2) {
                    dig = 0;
                }
                else {
                    dig = 11 - resto;
                }
                return dig === Number(ie.charAt(10));
            }
            if (ie.length === 9) {
                let total = 0;
                for (let j = 0; j < 8; j++) {
                    const digito = Number(ie[j]) * (9 - j);
                    total += digito;
                }
                const intResto = total % 11;
                const strDigito1 = (intResto < 2 ? '0' : String(11 - intResto)).substring((intResto < 2 ? '0' : String(11 - intResto)).length - 1);
                return ie[8] === strDigito1;
            }
            return false;
        // #endregion Tocantins
        default:
            return false;
    }
}
function ValidarInscricaoEstadual(ie, uf, desconsiderarZeroEsquerdaValidacao = true) {
    ie = ie.trim();
    let ieOriginal = ie;
    for (let i = 0; i < 8; i++) {
        const validacao = ValidarInscricao(ie, uf);
        if (validacao)
            return validacao;
        ie = `0${ie}`;
    }
    if (desconsiderarZeroEsquerdaValidacao) {
        for (let i = 0; i < 8; i++) {
            const validacao = ValidarInscricao(ieOriginal, uf);
            if (validacao)
                return validacao;
            if (ieOriginal.charAt(0) === '0')
                ieOriginal = ieOriginal.substring(1, ieOriginal.length);
        }
    }
    return false;
}
