import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/item';
const rotaNovo = 'comercial/vendas/pedido-venda';

interface IPedidoVendaItemComunicadorShow {
  id: string;
}

interface IPedidoVendaItemComunicadorStore {
  idPai: string;
  params: any;
}

interface IPedidoVendaItemComunicadorUpdate {
  id: string;
  params: any;
}

interface IPedidoVendaItemComunicadorDelete {
  id: string;
}

class PedidoVendaItemComunicador {
  public async show(params: IPedidoVendaItemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPedidoVendaItemComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(params: IPedidoVendaItemComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IPedidoVendaItemComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaItemComunicador();
