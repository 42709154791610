"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = AtualizarInformacoesBoletoConformeVariaveis;
function AtualizarInformacoesBoletoConformeVariaveis({ texto, numeroNota, percentualMulta, valorJurosCalculado, valorMultaCalculado, prazoNegativacao, prazoProtesto, }) {
    let textoFormatado = texto;
    if (textoFormatado.includes('<#NUMERONOTA#>') && numeroNota)
        textoFormatado = textoFormatado.replace('<#NUMERONOTA#>', numeroNota);
    if (textoFormatado.includes('<#PERCENTUALMULTA#>') && percentualMulta)
        textoFormatado = textoFormatado.replace('<#PERCENTUALMULTA#>', String(percentualMulta));
    if (textoFormatado.includes('<#VALORJUROSCALCULADO#>'))
        textoFormatado = textoFormatado.replace('<#VALORJUROSCALCULADO#>', Number(valorJurosCalculado || 0).FormatarParaPtBr());
    if (textoFormatado.includes('<#VALORMULTACALCULADO#>'))
        textoFormatado = textoFormatado.replace('<#VALORMULTACALCULADO#>', Number(valorMultaCalculado || 0).FormatarParaPtBr());
    if (textoFormatado.includes('<#PRAZONEGATIVACAO#>') && prazoNegativacao)
        textoFormatado = textoFormatado.replace('<#PRAZONEGATIVACAO#>', String(prazoNegativacao));
    if (textoFormatado.includes('<#PRAZOPROTESTO#>') && prazoProtesto)
        textoFormatado = textoFormatado.replace('<#PRAZOPROTESTO#>', String(prazoProtesto));
    return textoFormatado;
}
