import React from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import IPesquisaPessoaNomeRazaoSocialDetalheRef from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial/PesquisaPessoaNomeRazaoSocialDetalhe/Interface/IPesquisaPessoaNomeRazaoSocialDetalheRef';
import IPessoa from '../../../../../../Componentes/Inputs/InputBuscaNomeRazaoSocial/PesquisaPessoaNomeRazaoSocialDetalhe/Interface/IPessoa';

export const PesquisaNomeRazaoSocial = async (
  formRef: React.RefObject<IFormCiaHandles>,
  janelaNomeRazaoSocialRef: React.RefObject<IPesquisaPessoaNomeRazaoSocialDetalheRef>
): Promise<void> => {
  const dados = formRef.current?.getDataPesquisa();
  const cidade = formRef.current?.getFieldValueNomeObjeto(
    'pessoaEnderecoPrincipal.cidade'
  );

  await janelaNomeRazaoSocialRef.current?.pesquisarNomeRazaoSocial({
    cnpjCpf: dados.cnpjCpfVirtual,
    inscricaoEstadual: dados.inscricaoEstadual,
    nomeRazaoSocial: dados.nomeRazaoSocial,
    nomeFantasia: dados.nomeFantasia,
    cep: dados.pessoaEnderecoPrincipal.cep,

    cidade: {
      id: cidade?.id || '',
      nome: cidade?.nome,
      estado: {
        sigla: cidade?.estado.sigla,
      },
    },
    logradouro: dados.pessoaEnderecoPrincipal.logradouro,
    bairro: dados.pessoaEnderecoPrincipal.bairro,
    complemento: dados.pessoaEnderecoPrincipal.complemento,
    numero: dados.pessoaEnderecoPrincipal.numero,

    listaTelefone: dados.listaPessoaTelefone.listaValor,
    listaEmail: dados.listaPessoaEmail.listaValor,
  });
};

export const SelecionarPessoa = async (
  formRef: React.RefObject<IFormCiaHandles>,
  pessoa: IPessoa
): Promise<void> => {
  formRef.current?.setFieldValue('cnpjCpfVirtual', pessoa.cnpjCpf);
  formRef.current?.setFieldValue('nomeRazaoSocial', pessoa.nomeRazaoSocial);
  formRef.current?.setFieldValue('inscricaoEstadual', pessoa.inscricaoEstadual);
  formRef.current?.setFieldValue('nomeFantasia', pessoa.nomeFantasia);
  formRef.current?.setFieldValue('pessoaEnderecoPrincipal.cep', pessoa.cep);
  formRef.current?.setFieldValue(
    'pessoaEnderecoPrincipal.cidade',
    pessoa.cidade
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoPrincipal.logradouro',
    pessoa.logradouro
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoPrincipal.bairro',
    pessoa.bairro
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoPrincipal.complemento',
    pessoa.complemento
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoPrincipal.numero',
    pessoa.numero
  );
  formRef.current?.setFieldValue('listaPessoaTelefone', pessoa.listaTelefone);
  formRef.current?.setFieldValue('listaPessoaEmail', pessoa.listaEmail);
};

export const PesquisarNomeRazaoSocialPessoaEnderecoEntrega = async (
  formRef: React.RefObject<IFormCiaHandles>,
  janelaNomeRazaoSocialEnderecoEntregaRef: React.RefObject<IPesquisaPessoaNomeRazaoSocialDetalheRef>
): Promise<void> => {
  const dados = formRef.current?.getDataPesquisa();
  const cidade = formRef.current?.getFieldValueNomeObjeto(
    'pessoaEnderecoEntrega.cidade'
  );

  await janelaNomeRazaoSocialEnderecoEntregaRef.current?.pesquisarNomeRazaoSocial(
    {
      cnpjCpf: dados.pessoaEnderecoEntrega.cnpjCpfVirtual || '',
      inscricaoEstadual: dados.pessoaEnderecoEntrega.inscricaoEstadual || '',
      nomeRazaoSocial: dados.pessoaEnderecoEntrega.nomeRazaoSocial || '',
      nomeFantasia: dados.pessoaEnderecoEntrega.nomeFantasia || '',
      cep: dados.pessoaEnderecoEntrega.cep || '',

      cidade: {
        id: cidade?.id || '',
        nome: cidade?.nome || '',
        estado: {
          sigla: cidade?.estado.sigla || '',
        },
      },
      logradouro: dados.pessoaEnderecoEntrega.logradouro || '',
      bairro: dados.pessoaEnderecoEntrega.bairro || '',
      complemento: dados.pessoaEnderecoEntrega.complemento || '',
      numero: dados.pessoaEnderecoEntrega.numero || '',
      listaTelefone: dados.listaPessoaTelefone.listaValor || [],
      listaEmail: dados.listaPessoaEmail.listaValor || [],
    }
  );
};

export const SelecionarPessoaEnderecoEntrega = async (
  formRef: React.RefObject<IFormCiaHandles>,
  enderecoEntrega: IPessoa
): Promise<void> => {
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.cnpjCpfVirtual',
    enderecoEntrega.cnpjCpf
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.cidade',
    enderecoEntrega.cidade
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.logradouro',
    enderecoEntrega.logradouro
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.bairro',
    enderecoEntrega.bairro
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.complemento',
    enderecoEntrega.complemento
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.numero',
    enderecoEntrega.numero
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.cep',
    enderecoEntrega.cep
  );
  formRef.current?.setFieldValue(
    'pessoaEnderecoEntrega.nomeRazaoSocial',
    enderecoEntrega.nomeRazaoSocial
  );
};
