/* eslint-disable no-nested-ternary */
import {
  AppErro,
  CalculoBaseComissao,
  ConverterParaNumberOrNull,
  DestinadoEnum,
  IItensRateioImpostoCalculoPorCstRetorno,
  IOrdemServicoComercialRepresentanteLista,
  ItensRateioImpostoCalculoPorCst,
  RateioComCasaDecimalCalculo,
  TipoMercadoEnum,
  ValorTotalDocumentoCalculo,
} from '@elogestor/util';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaItem } from './ListaItemContext';
import SelecaoEnderecoPessoaDetalhe from '../PessoaTab/SelecaoEnderecoPessoaDetalhe';
import IEnderecoPessoa from '../PessoaTab/SelecaoEnderecoPessoaDetalhe/Interface/IEnderecoPessoa';
import PessoaObterDadosCadastroComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosCadastroComunicador';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PessoaObterDadosPadraoComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosPadraoComunicador';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';
import ObterOrdemServicoItensParaCalculoRateiosComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Comunicador/ObterOrdemServicoItensParaCalculoRateiosComunicador';

interface IOrdemServicoContext {
  validador: boolean;
  setFormaRateioValorFrete(value: boolean): void;
  setFormaRateioValorSeguro(value: boolean): void;
  setFormaRateioValorOutrasDespesas(value: boolean): void;
  setFormaRateioValorDesconto(value: boolean): void;
  atualizarRateiosListaItem(): void;
  atualizarDadosClienteConformeCadastro(): void;
  obterDadosPadraoCliente(): void;

  listaRepresentante: IOrdemServicoComercialRepresentanteLista[];
  setListaRepresentante: React.Dispatch<
    React.SetStateAction<IOrdemServicoComercialRepresentanteLista[]>
  >;
}

const OrdemServicoContext = createContext<IOrdemServicoContext>(
  {} as IOrdemServicoContext
);

const OrdemServicoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaItem } = UseListaItem();
  const { formRef, getIdDetalheRegistro, setLoading } = UseForm();
  const { abrirJanela } = UseAlerta();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [showModalSelecaoEndereco, setShowModalSelecaoEndereco] =
    useState(false);
  const [listaEndereco, setListaEndereco] = useState<IEnderecoPessoa[]>([]);
  const [listaRepresentante, setListaRepresentante] = useState<
    IOrdemServicoComercialRepresentanteLista[]
  >([]);

  const handleSetFormaRateioValorFrete = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('freteFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorSeguro = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('seguroFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorOutrasDespesas = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue(
        'outrasDespesasFormaCalculoPorRateio',
        value
      );
    },
    [formRef]
  );

  const handleSetFormaRateioValorDesconto = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('descontoFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleAtualizarTotais = useCallback(() => {
    let totalProdutos = 0;
    let totalBruto = 0;

    if (listaItem.length === 0) {
      const valorFrete = formRef.current?.getFieldValue('valorFrete');
      const valorSeguro = formRef.current?.getFieldValue('valorSeguro');
      const valorOutrasDespesas = formRef.current?.getFieldValue(
        'valorOutrasDespesas'
      );
      const valorDesconto = formRef.current?.getFieldValue('valorDesconto');

      const valorTotal =
        valorFrete + valorSeguro + valorOutrasDespesas - valorDesconto;

      totalProdutos = valorTotal;
      totalBruto = valorTotal;
    } else {
      [totalProdutos, totalBruto] = listaItem.reduce(
        (acumulador, item) => {
          acumulador[0] += Number(item.valorTotalProduto) || 0;
          acumulador[1] += Number(item.valorTotalBruto) || 0;
          return acumulador;
        },
        [0, 0]
      );
    }

    formRef.current?.setFieldValue('valorTotalProdutos', totalProdutos);
    formRef.current?.setFieldValue('valorTotalBruto', totalBruto);
  }, [formRef, listaItem]);

  const handleAtualizarRateiosListaItem = useCallback(async () => {
    if (!idDetalheRegistro) return;

    try {
      const response =
        await ObterOrdemServicoItensParaCalculoRateiosComunicador.index({
          id: idDetalheRegistro,
        });

      if (
        !response.listaOrdemServicoItem ||
        response.listaOrdemServicoItem.length !== listaItem.length
      ) {
        throw new AppErro({
          mensagem: 'A ordem de serviço esta desatualizada!',
        });
      }

      const valorFreteAtual = formRef.current?.getFieldValue('valorFrete') ?? 0;
      const valorSeguroAtual =
        formRef.current?.getFieldValue('valorSeguro') ?? 0;
      const valorOutrasDespesasAtual =
        formRef.current?.getFieldValue('valorOutrasDespesas') ?? 0;
      const valorDescontoAtual =
        formRef.current?.getFieldValue('valorDesconto') ?? 0;

      if (listaItem.length === 0) {
        const valorTotal =
          valorFreteAtual +
          valorSeguroAtual +
          valorOutrasDespesasAtual -
          valorDescontoAtual;

        formRef.current?.setFieldValue('valorTotalProdutos', valorTotal);
        formRef.current?.setFieldValue('valorTotalBruto', valorTotal);
        return;
      }

      const possuiItemGerarFaturamento = listaItem.some(
        (item) => item.ordemServicoItemComercial?.gerarFaturamento
      );

      const listaItemValorTotalProduto = listaItem.map((item) => {
        if (item.ordemServicoItemComercial?.gerarFaturamento)
          return Number(item.valorTotalProduto);

        return 0;
      });

      const listaValorFrete = RateioComCasaDecimalCalculo({
        valor: valorFreteAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorSeguro = RateioComCasaDecimalCalculo({
        valor: valorSeguroAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorOutrasDespesas = RateioComCasaDecimalCalculo({
        valor: valorOutrasDespesasAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorDesconto = RateioComCasaDecimalCalculo({
        valor: valorDescontoAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaItensRateio =
        response.listaOrdemServicoItem?.map((item, index) => {
          return {
            id: item.id,
            gerarFaturamento: item.ordemServicoItemComercial?.gerarFaturamento,
            valorFreteItem:
              (possuiItemGerarFaturamento &&
                item.ordemServicoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorFrete[index]
                : item.valorFrete
                  ? Number(item.valorFrete)
                  : null,
            valorSeguroItem:
              (possuiItemGerarFaturamento &&
                item.ordemServicoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorSeguro[index]
                : item.valorSeguro
                  ? Number(item.valorSeguro)
                  : null,
            valorOutrasDespesasItem:
              (possuiItemGerarFaturamento &&
                item.ordemServicoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorOutrasDespesas[index]
                : item.valorOutrasDespesas
                  ? Number(item.valorOutrasDespesas)
                  : null,
            valorDescontoItem:
              (possuiItemGerarFaturamento &&
                item.ordemServicoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorDesconto[index]
                : item.valorDesconto
                  ? Number(item.valorDesconto)
                  : null,
            quantidadeItem: ConverterParaNumberOrNull(item.quantidade),
            valorUnitarioItem: ConverterParaNumberOrNull(item.valorUnitario),

            icms: {
              valorIpiItem: null,
              destinadoItem: item.destinado as DestinadoEnum,

              cstCsosn: item.ordemServicoItemImpostoIcms?.situacaoTributaria
                ?.codigo
                ? item.ordemServicoItemImpostoIcms?.situacaoTributaria?.codigo
                : '',
              tipoMercado: item.operacaoFiscal?.tipoMercado as TipoMercadoEnum,

              // #region ICMS

              calcularIcms: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularIcms
              ),
              modalidadeBaseCalculoIcms: item.ordemServicoItemImpostoIcms
                ?.modalidadeBaseCalculoIcms
                ? item.ordemServicoItemImpostoIcms?.modalidadeBaseCalculoIcms
                : null,
              aliquotaInterestadualIcms: null,

              percentualReducaoBaseCalculoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcms
              ),
              baseCalculoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoIcms
              ),
              aliquotaIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaIcms
              ),
              valorIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorIcms
              ),

              baseCalculoFcpIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoFcpIcms
              ),
              aliquotaFcpIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaFcpIcms
              ),
              valorFcpIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorFcpIcms
              ),

              percentualDiferimentoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.percentualDiferimentoIcms
              ),
              valorSemDiferimentoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorSemDiferimentoIcms
              ),
              valorDiferidoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorDiferidoIcms
              ),

              valorUnitarioPautaIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorUnitarioPautaIcms
              ),
              valorTotalPautaIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorTotalPautaIcms
              ),

              calcularIcmsEfetivo: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularIcmsEfetivo
              ),
              percentualReducaoBaseCalculoIcmsEfetivo:
                ConverterParaNumberOrNull(
                  item.ordemServicoItemImpostoIcms
                    ?.percentualReducaoBaseCalculoIcmsEfetivo
                ),
              baseCalculoIcmsEfetivo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoIcmsEfetivo
              ),
              aliquotaIcmsEfetivo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaIcmsEfetivo
              ),
              valorIcmsEfetivo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorIcmsEfetivo
              ),

              calcularIcmsSubstituto: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularIcmsSubstituto
              ),
              valorUnitarioIcmsSubstituto: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorUnitarioIcmsSubstituto
              ),
              valorIcmsSubstituto: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorIcmsSubstituto
              ),

              motivoDesoneracaoIcms: item.ordemServicoItemImpostoIcms
                ?.motivoDesoneracaoIcms
                ? item.ordemServicoItemImpostoIcms?.motivoDesoneracaoIcms
                : null,

              baseCalculoSemReducaoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoSemReducaoIcms
              ),
              valorSemReducaoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorSemReducaoIcms
              ),
              baseCalculoReducaoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoReducaoIcms
              ),
              valorReducaoIcms: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorReducaoIcms
              ),

              // #endregion ICMS

              // #region ICMS ST

              calcularIcmsSt: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularIcmsSt
              ),
              modalidadeBaseCalculoIcmsSt: item.ordemServicoItemImpostoIcms
                ?.modalidadeBaseCalculoIcmsSt
                ? item.ordemServicoItemImpostoIcms?.modalidadeBaseCalculoIcmsSt
                : null,

              percentualMvaIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.percentualMvaIcmsSt
              ),
              percentualReducaoMvaIcmsSt: null,
              percentualReducaoBaseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcmsSt
              ),
              aliquotaInterestadualIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaInterestadualIcmsSt
              ),
              aliquotaInternaIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaInternaIcmsSt
              ),
              baseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoIcmsSt
              ),
              valorIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorIcmsSt
              ),

              baseCalculoFcpIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoFcpIcmsSt
              ),
              aliquotaFcpIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaFcpIcmsSt
              ),
              valorFcpIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorFcpIcmsSt
              ),

              calcularIcmsStRetido: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularIcmsStRetido
              ),
              baseCalculoIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoIcmsStRetido
              ),
              baseCalculoUnitariaProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms
                  ?.baseCalculoUnitariaProdutoIcmsStRetido
              ),
              aliquotaIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaIcmsStRetido
              ),
              valorIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorIcmsStRetido
              ),
              valorUnitarioProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms
                  ?.valorUnitarioProdutoIcmsStRetido
              ),

              baseCalculoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.baseCalculoFcpIcmsStRetido
              ),
              baseCalculoUnitariaProdutoFcpIcmsStRetido:
                ConverterParaNumberOrNull(
                  item.ordemServicoItemImpostoIcms
                    ?.baseCalculoUnitariaProdutoFcpIcmsStRetido
                ),
              aliquotaFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.aliquotaFcpIcmsStRetido
              ),
              valorFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorFcpIcmsStRetido
              ),
              valorUnitarioProdutoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms
                  ?.valorUnitarioProdutoFcpIcmsStRetido
              ),

              valorUnitarioPautaIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorUnitarioPautaIcmsSt
              ),
              valorTotalPautaIcmsSt: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorTotalPautaIcmsSt
              ),

              // #endregion ICMS ST

              // #region ICMS SN

              calcularCreditoIcmsSn: Boolean(
                item.ordemServicoItemImpostoIcms?.calcularCreditoIcmsSn
              ),
              percentualCreditoIcmsSn: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.percentualCreditoIcmsSn
              ),
              valorCreditoIcmsSn: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIcms?.valorCreditoIcmsSn
              ),

              // #endregion ICMS SN
            },

            ipi: {
              calcular: Boolean(item.ordemServicoItemImpostoIpi?.calcular),
              tipoCalculo: item.ordemServicoItemImpostoIpi?.tipoCalculo
                ? item.ordemServicoItemImpostoIpi?.tipoCalculo
                : null,
              cst: item.ordemServicoItemImpostoIpi?.situacaoTributaria?.codigo
                ? item.ordemServicoItemImpostoIpi?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadeIpi: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIpi?.quantidadeUnidade
              ),
              valorUnidadeIpi: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIpi?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIpi?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIpi?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoIpi?.valor
              ),
            },

            pis: {
              calcular: Boolean(item.ordemServicoItemImpostoPis?.calcular),
              tipoCalculo: item.ordemServicoItemImpostoPis?.tipoCalculo
                ? item.ordemServicoItemImpostoPis?.tipoCalculo
                : null,
              cst: item.ordemServicoItemImpostoPis?.situacaoTributaria?.codigo
                ? item.ordemServicoItemImpostoPis?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadePis: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.quantidadeUnidade
              ),
              valorUnidadePis: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.valor
              ),

              calcularRetido: Boolean(
                item.ordemServicoItemImpostoPis?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoPis?.valorRetido
              ),
            },

            cofins: {
              calcular: Boolean(item.ordemServicoItemImpostoCofins?.calcular),
              tipoCalculo: item.ordemServicoItemImpostoCofins?.tipoCalculo
                ? item.ordemServicoItemImpostoCofins?.tipoCalculo
                : null,
              cst: item.ordemServicoItemImpostoCofins?.situacaoTributaria
                ?.codigo
                ? item.ordemServicoItemImpostoCofins?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadeCofins: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.quantidadeUnidade
              ),
              valorUnidadeCofins: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.valor
              ),

              calcularRetido: Boolean(
                item.ordemServicoItemImpostoCofins?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.ordemServicoItemImpostoCofins?.valorRetido
              ),
            },
          };
        }) || [];

      const listaItemRateioComImpostos =
        ItensRateioImpostoCalculoPorCst(listaItensRateio);

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        const itemRateio = listaItensRateio[i];
        const itemRateioComImpostos = listaItemRateioComImpostos[i];

        const valorTotalBrutoItem = ValorTotalDocumentoCalculo({
          valorSubtotalItem: Number(listaItem[i].valorTotalProduto),
          valorFreteItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorFreteItem)
              : item.valorFrete
                ? Number(item.valorFrete)
                : 0,
          valorSeguroItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorSeguroItem)
              : item.valorSeguro
                ? Number(item.valorSeguro)
                : 0,

          valorDescontoItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorDescontoItem)
              : item.valorDesconto
                ? Number(item.valorDesconto)
                : 0,

          valorOutrasDespesasItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorOutrasDespesasItem)
              : item.valorOutrasDespesas
                ? Number(item.valorOutrasDespesas)
                : 0,
          valorIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorIcmsSt)
            : 0,
          valorFcpIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorFcpIcmsSt)
            : 0,
          valorIpi: itemRateioComImpostos.ipi
            ? Number(itemRateioComImpostos.ipi?.valor)
            : 0,

          valorImpostoImportacao: null,
          valorServicos: null,
        });

        listaItem[i].valorTotalBruto = valorTotalBrutoItem;
      }

      // #region ICMS

      const valorTotalBaseCalculoIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcms);
          return acumulador;
        },
        0
      );

      const valorTotalIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcms);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcms);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcms',
        valorTotalBaseCalculoIcms
      );
      formRef.current?.setFieldValue('valorTotalIcms', valorTotalIcms);
      formRef.current?.setFieldValue('valorTotalFcpIcms', valorTotalFcpIcms);

      // #endregion ICMS

      // #region ICMS ST

      const valorTotalBaseCalculoIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcmsSt);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcmsSt',
        valorTotalBaseCalculoIcmsSt
      );
      formRef.current?.setFieldValue('valorTotalIcmsSt', valorTotalIcmsSt);
      formRef.current?.setFieldValue(
        'valorTotalFcpIcmsSt',
        valorTotalFcpIcmsSt
      );

      // #endregion ICMS ST

      // #region IPI

      const valorTotalBaseCalculoIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIpi',
        valorTotalBaseCalculoIpi
      );
      formRef.current?.setFieldValue('valorTotalIpi', valorTotalIpi);

      // #endregion IPI

      // #region PIS/COFINS

      const valorTotalBaseCalculoPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPis',
        valorTotalBaseCalculoPis
      );
      formRef.current?.setFieldValue('valorTotalPis', valorTotalPis);

      const valorTotalBaseCalculoCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofins',
        valorTotalBaseCalculoCofins
      );
      formRef.current?.setFieldValue('valorTotalCofins', valorTotalCofins);

      // #endregion PIS

      // #region PIS/COFINS Retido

      const valorTotalBaseCalculoPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculoRetido);
          return acumulador;
        },
        0
      );

      const valorTotalPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPisRetido',
        valorTotalBaseCalculoPisRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalPisRetido',
        valorTotalPisRetido
      );

      const valorTotalBaseCalculoCofinsRetido =
        listaItemRateioComImpostos.reduce(
          (
            acumulador: number,
            item: IItensRateioImpostoCalculoPorCstRetorno
          ) => {
            acumulador += Number(item.cofins?.baseCalculoRetido);
            return acumulador;
          },
          0
        );

      const valorTotalCofinsRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofinsRetido',
        valorTotalBaseCalculoCofinsRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalCofinsRetido',
        valorTotalCofinsRetido
      );

      // #endregion COFINS

      // #region Item Comercial Representante

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        if (
          item.ordemServicoItemComercial &&
          item.ordemServicoItemComercial.gerarComissao &&
          item.ordemServicoItemComercial
            .listaOrdemServicoItemComercialRepresentante
        ) {
          const baseCalculo = CalculoBaseComissao({
            valorTotalBruto: item.valorTotalBruto,
            valorImpostoIcms: item.ordemServicoItemImpostoIcms
              ?.valorIcmsStEValorFcpIcmsSt
              ? item.ordemServicoItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt
              : 0,
            valorImpostoIpi: item.ordemServicoItemImpostoIpi?.valor
              ? item.ordemServicoItemImpostoIpi?.valor
              : 0,
            valorFrete: valorFreteAtual,
            valorOutrasDespesas: valorSeguroAtual,
            valorSeguro: valorSeguroAtual,
          });

          if (
            item.ordemServicoItemComercial &&
            item.ordemServicoItemComercial
              ?.listaOrdemServicoItemComercialRepresentante
          ) {
            for (
              let j = 0;
              j <
              item.ordemServicoItemComercial
                ?.listaOrdemServicoItemComercialRepresentante?.length;
              j++
            ) {
              const valor =
                item.ordemServicoItemComercial
                  ?.listaOrdemServicoItemComercialRepresentante[j];
              valor.baseCalculo = baseCalculo;
              valor.valor = baseCalculo * (Number(valor.aliquota) / 100);
            }
          }
        }
      }

      // #endregion Item Comercial Representante

      // #region Comercial Representante

      const listaComercialRepresentante = formRef.current?.getFieldValue(
        'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
      );

      if (
        listaComercialRepresentante &&
        listaComercialRepresentante.length > 0
      ) {
        const idPessoa = formRef.current?.getFieldValue(
          'ordemServicoPessoa.idPessoa'
        );
        const dataHoraEmissao =
          formRef.current?.getFieldValue('dataHoraEmissao');
        listaComercialRepresentante.map(async (comercialRepresentante: any) => {
          let baseCalculo = 0;
          let valor = 0;

          if (listaItem && listaItem.length > 0) {
            await Promise.all(
              listaItem.map(async (item) => {
                if (
                  item &&
                  item.ordemServicoItemComercial &&
                  item.ordemServicoItemComercial
                    .listaOrdemServicoItemComercialRepresentante
                ) {
                  item.ordemServicoItemComercial?.listaOrdemServicoItemComercialRepresentante.map(
                    async (itemComercialRepresentante) => {
                      if (
                        comercialRepresentante.idPessoaRepresentante ===
                          itemComercialRepresentante.idPessoaRepresentante &&
                        comercialRepresentante.formaCalculoPorRegra ===
                          itemComercialRepresentante.formaCalculoPorRegra
                      ) {
                        baseCalculo += Number(
                          itemComercialRepresentante.baseCalculo
                        );
                        valor += Number(itemComercialRepresentante.valor);
                      }
                    }
                  );
                }

                if (!comercialRepresentante.id) {
                  const baseCalculoRepresentate = CalculoBaseComissao({
                    valorTotalBruto: item.valorTotalBruto,
                    valorImpostoIcms: item.ordemServicoItemImpostoIcms
                      ?.valorIcmsStEValorFcpIcmsSt
                      ? item.ordemServicoItemImpostoIcms
                          ?.valorIcmsStEValorFcpIcmsSt
                      : 0,
                    valorImpostoIpi: item.ordemServicoItemImpostoIpi?.valor
                      ? item.ordemServicoItemImpostoIpi?.valor
                      : 0,
                    valorFrete: valorFreteAtual,
                    valorOutrasDespesas: valorOutrasDespesasAtual,
                    valorSeguro: valorSeguroAtual,
                  });
                  baseCalculo += baseCalculoRepresentate;

                  const regraCalculoComissao =
                    await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                      {
                        params: {
                          idPessoa,
                          idPessoaRepresentante:
                            comercialRepresentante.idPessoaRepresentante,
                          idProduto: item.idProduto,
                          idGrupoProdutoServico: item.produto
                            ? item.produto.idGrupoProdutoServico
                            : null,
                          dataPesquisaVigencia: new Date(dataHoraEmissao),
                          percentualDesconto: 0,
                        },
                      }
                    );

                  valor +=
                    baseCalculoRepresentate *
                    (Number(
                      regraCalculoComissao.regraCalculoComissao
                        .percentualComissao
                    ) /
                      100);
                }
              })
            );
          }

          const aliquota = valor / (baseCalculo === 0 ? 1 : baseCalculo);
          comercialRepresentante.baseCalculo = baseCalculo;
          comercialRepresentante.valor = valor;

          if (comercialRepresentante.formaCalculoPorRegra) {
            comercialRepresentante.aliquota = aliquota * 100;
          }
        });
      }

      // #endregion Comercial Representante

      handleAtualizarTotais();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  }, [formRef, handleAtualizarTotais, idDetalheRegistro, listaItem]);

  useEffect(() => {
    handleAtualizarTotais();
  }, [formRef, handleAtualizarTotais]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaOrdemServicoItem = formRef.current?.getFieldValue(
      'listaOrdemServicoItem'
    );

    if (listaOrdemServicoItem && listaOrdemServicoItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração da UF do Cliente, as informações tributárias e
            valores podem estar incorretos. Salve para atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  const handleAtualizarDadosClienteConformeCadastro = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );
      const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoaEndereco.cidade.estado.sigla'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosCadastroComunicador.show({
        id: pessoa.id,
      });

      await formRef.current?.setSemExecutarEvento({
        ordemServicoPessoa: {
          cnpjCpfVirtual: response.cnpjCpfVirtual,
          inscricaoEstadual: response.inscricaoEstadual,
          inscricaoSuframa: response.inscricaoSuframa,
          documentoIdentificacaoEstrangeiro:
            response.documentoIdentificacaoEstrangeiro,
          nomeRazaoSocial: response.nomeRazaoSocial,
          nomeFantasia: response.nomeFantasia,
        },
      });

      if (response.pessoaEnderecoEntrega.outroEnderecoEntrega) {
        await formRef.current?.setSemExecutarEvento({
          ordemServicoPessoaEnderecoEntrega: {
            cnpjCpfVirtual: response.pessoaEnderecoEntrega.cnpjCpfVirtual,
            inscricaoEstadual: response.pessoaEnderecoEntrega.inscricaoEstadual,
            nomeRazaoSocial: response.pessoaEnderecoEntrega.nomeRazaoSocial,
            outroEnderecoEntrega:
              response.pessoaEnderecoEntrega.outroEnderecoEntrega,
            cep: response.pessoaEnderecoEntrega.cep,
            cidade: response.pessoaEnderecoEntrega.cidade,
            logradouro: response.pessoaEnderecoEntrega.logradouro,
            numero: response.pessoaEnderecoEntrega.numero,
            bairro: response.pessoaEnderecoEntrega.bairro,
            complemento: response.pessoaEnderecoEntrega.complemento,
          },
        });
      } else {
        await formRef.current?.setSemExecutarEvento({
          ordemServicoPessoaEnderecoEntrega: {
            cnpjCpfVirtual: null,
            inscricaoEstadual: null,
            nomeRazaoSocial: null,
            outroEnderecoEntrega: null,
            cep: null,
            cidade: null,
            logradouro: null,
            numero: null,
            bairro: null,
            complemento: null,
          },
        });
      }

      if (response.listaPessoaEndereco.length > 1) {
        setShowModalSelecaoEndereco(true);
        setListaEndereco(response.listaPessoaEndereco);
      } else if (response.listaPessoaEndereco.length === 1) {
        await formRef.current?.setSemExecutarEvento({
          ordemServicoPessoaEndereco: {
            cep: response.listaPessoaEndereco[0].cep,
            cidade: response.listaPessoaEndereco[0].cidade,
            logradouro: response.listaPessoaEndereco[0].logradouro,
            numero: response.listaPessoaEndereco[0].numero,
            bairro: response.listaPessoaEndereco[0].bairro,
            complemento: response.listaPessoaEndereco[0].complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'ordemServicoPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }

      if (response.pessoaCliente) {
        const listaOrdemServicoComercialRepresentante =
          formRef.current?.getFieldValue(
            'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
          );

        if (
          response.pessoaCliente.listaRepresentante &&
          response.pessoaCliente.listaRepresentante.length > 0
        ) {
          response.pessoaCliente.listaRepresentante.forEach((valor: any) => {
            const valoresInserir = {
              pessoaRepresentante: {
                id: valor.pessoaRepresentante.id,
                nomeRazaoSocial: valor.pessoaRepresentante.nomeRazaoSocial,
              },
              idPessoaRepresentante: valor.pessoaRepresentante.id,
              baseCalculo: 0,
              aliquota: 0,
              valor: 0,
              formaCalculoPorRegra: true,
            };

            const representanteExiste =
              listaOrdemServicoComercialRepresentante.find(
                (e: any) =>
                  e.idPessoaRepresentante === valor.pessoaRepresentante.id
              );

            if (!representanteExiste) {
              listaOrdemServicoComercialRepresentante.push(valoresInserir);
            }
          });
        } else {
          formRef.current?.clearField(
            'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
          );
        }

        if (
          listaOrdemServicoComercialRepresentante &&
          listaOrdemServicoComercialRepresentante.length > 0
        ) {
          listaOrdemServicoComercialRepresentante.forEach((val: any) => {
            if (!val.formaCalculoPorRegra) {
              listaOrdemServicoComercialRepresentante.push(val);
            }
          });
        }

        formRef.current?.setFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante',
          listaOrdemServicoComercialRepresentante
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleExibirMensagemAtualizacao, setLoading]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosPadraoComunicador.show({
        id: pessoa.id,
      });

      if (response.modalidadeFrete) {
        await formRef.current?.setFieldValue(
          'ordemServicoTransporteTransportadora.modalidadeFrete',
          response.modalidadeFrete
        );
      }

      if (response.pessoaTransportadora) {
        await formRef.current?.setFieldValue(
          'ordemServicoTransporteTransportadora.pessoaTransportadora',
          response.pessoaTransportadora
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading]);

  const handleOnSelecionarEndereco = useCallback(
    async (enderecoSelecionado: IEnderecoPessoa) => {
      if (enderecoSelecionado) {
        const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
          'ordemServicoPessoaEndereco.cidade.estado.sigla'
        );

        await formRef.current?.setSemExecutarEvento({
          ordemServicoPessoaEndereco: {
            cep: enderecoSelecionado.cep,
            cidade: enderecoSelecionado.cidade,
            logradouro: enderecoSelecionado.logradouro,
            numero: enderecoSelecionado.numero,
            bairro: enderecoSelecionado.bairro,
            complemento: enderecoSelecionado.complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'ordemServicoPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }
    },
    [formRef, handleExibirMensagemAtualizacao]
  );

  return (
    <OrdemServicoContext.Provider
      value={{
        setFormaRateioValorFrete: handleSetFormaRateioValorFrete,
        setFormaRateioValorSeguro: handleSetFormaRateioValorSeguro,
        setFormaRateioValorOutrasDespesas:
          handleSetFormaRateioValorOutrasDespesas,
        setFormaRateioValorDesconto: handleSetFormaRateioValorDesconto,
        atualizarRateiosListaItem: handleAtualizarRateiosListaItem,
        atualizarDadosClienteConformeCadastro:
          handleAtualizarDadosClienteConformeCadastro,
        obterDadosPadraoCliente: handleObterDadosPadraoCliente,
        listaRepresentante,
        setListaRepresentante,
        validador: true,
      }}
    >
      {children}
      {showModalSelecaoEndereco && (
        <SelecaoEnderecoPessoaDetalhe
          listaEndereco={listaEndereco}
          onSelecionarEndereco={(item) => {
            handleOnSelecionarEndereco(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoEndereco(false);
          }}
        />
      )}
    </OrdemServicoContext.Provider>
  );
};

function UseOrdemServico(): Omit<IOrdemServicoContext, 'validador'> {
  const context = useContext(OrdemServicoContext);

  if (!context.validador) {
    throw new Error(
      'UseOrdemServico deve ser usado com um OrdemServicoProvider'
    );
  }

  return context;
}

export { OrdemServicoHook, UseOrdemServico };
