import React, { createContext, useContext, useEffect, useState } from 'react';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import IPadraoProps from '../Comum/Interface/IPadraoProps';

interface IReactSizeMeGlobalHookContextData {
  size: {
    width: number | null;
    height: number | null;
  };
  telaPequena: boolean;
  telaGrande: boolean;
}

interface ISizeMeProps extends SizeMeProps, IPadraoProps {}

const ReactSizeMeGlobalHookContext =
  createContext<IReactSizeMeGlobalHookContextData>(
    {} as IReactSizeMeGlobalHookContextData
  );

const ReactSizeMeHook: React.FC<ISizeMeProps> = ({ children, size }) => {
  const [telaPequena, setTelaPequena] = useState(
    !!(size.width && size.width < 800)
  );
  const [telaGrande, setTelaGrande] = useState(
    !!(size.width && size.width > 800)
  );

  useEffect(() => {
    setTelaPequena(!!(size.width && size.width < 800));
    setTelaGrande(!!(size.width && size.width > 800));
  }, [size.width]);

  return (
    <ReactSizeMeGlobalHookContext.Provider
      value={{ size, telaPequena, telaGrande }}
    >
      {children}
    </ReactSizeMeGlobalHookContext.Provider>
  );
};
const ReactSizeMeGlobalHookProvider = sizeMe({ monitorHeight: true })(
  ReactSizeMeHook
);

function UseReactSizeMeGlobalHook(): IReactSizeMeGlobalHookContextData {
  const context = useContext(ReactSizeMeGlobalHookContext);

  if (!context) {
    throw new Error(
      'UseReactSizeMeGlobalHook must be used within an ReactSizeMeGlobalHookProvider'
    );
  }

  return context;
}

export { ReactSizeMeGlobalHookProvider, UseReactSizeMeGlobalHook };
