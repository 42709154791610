import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/local-estoque-auto-complete-tag';

interface ILocalEstoqueAutoCompleteTagComunicadorIndex {
  params: any;
}

class LocalEstoqueAutoCompleteTagComunicador {
  public async index(
    params: ILocalEstoqueAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new LocalEstoqueAutoCompleteTagComunicador();
