import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/obter-numero';

interface INotaFiscalEntradaObterNumeroComunicadorShow {
  idNotaFiscalEntrada: string;
  idSerie: string;
}

class NotaFiscalEntradaObterNumeroComunicador {
  public async show(
    params: INotaFiscalEntradaObterNumeroComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params });

    return response.data;
  }
}

export default new NotaFiscalEntradaObterNumeroComunicador();
