import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra/cancelar';

interface ICotacaoCompraCancelarComunicadorUpdate {
  id: string;
}

class CotacaoCompraCancelarComunicador {
  public async update(
    params: ICotacaoCompraCancelarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CotacaoCompraCancelarComunicador();
