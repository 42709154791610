import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/impressao-etiquetas-nota-fiscal/obter-informacoes-nota-fiscal';

interface IImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicadorShow {
  params: any;
}

class ImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicador {
  public async index(
    params: IImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicador();
