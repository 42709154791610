import api from '../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/estrutura-produto/estrutura';

interface IEstruturaProdutoDetalheComunicadorShow {
  id: string;
}

interface IEstruturaProdutoDetalheComunicadorStore {
  params: any;
}

interface IEstruturaProdutoDetalheComunicadorUpdate {
  id: string;
  params: any;
}

interface IEstruturaProdutoDetalheComunicadorDeletar {
  id: string;
}

class EstruturaProdutoDetalheComunicador {
  public async show(
    params: IEstruturaProdutoDetalheComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IEstruturaProdutoDetalheComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IEstruturaProdutoDetalheComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEstruturaProdutoDetalheComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EstruturaProdutoDetalheComunicador();
