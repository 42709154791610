import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/demonstrativo-resultado/log';

interface IDemonstrativoResultadolLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IDemonstrativoResultadolLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class DemonstrativoResultadolLogComunicador {
  public async index(
    params: IDemonstrativoResultadolLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IDemonstrativoResultadolLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new DemonstrativoResultadolLogComunicador();
