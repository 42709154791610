import React, { useMemo } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import { UseItemNavegacao } from '../../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao/Hooks/NavegacaoHook';
import { UseListaDraggable } from '../Hook/ListaDraggableHook';

const Paginacao: React.FC = () => {
  const { paginaAtual, totalPaginas, setPaginaAtual, limite, setLimite } =
    UseListaDraggable();
  const { handleAnterior, handlePrimeiro, handleProximo, handleUltimo } =
    UseItemNavegacao();

  const paginacao = useMemo(() => {
    const retorno = [];
    let i = 1;
    let fimFor = totalPaginas;

    if (paginaAtual - 1 > 1) {
      if (paginaAtual === totalPaginas) i = totalPaginas - 2;
      else i = paginaAtual - 1;
    }

    if (paginaAtual + 1 < totalPaginas) {
      if (paginaAtual === 1) fimFor = paginaAtual + 2;
      else fimFor = paginaAtual + 1;
    }

    if (paginaAtual + 2 > totalPaginas && totalPaginas > 3) {
      retorno.push(
        <Pagination.Item
          key={1}
          onClick={() => {
            setPaginaAtual(1);
          }}
        >
          1
        </Pagination.Item>
      );
      retorno.push(<Pagination.Ellipsis key="Ellipsis" />);
    }

    for (i; i <= fimFor; i += 1) {
      const iAtual = i;

      retorno.push(
        <Pagination.Item
          key={i}
          active={i === paginaAtual}
          onClick={() => {
            setPaginaAtual(iAtual);
          }}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (paginaAtual + 1 < totalPaginas) {
      if (totalPaginas > 3) {
        retorno.push(<Pagination.Ellipsis key="Ellipsis" />);

        retorno.push(
          <Pagination.Item
            key={totalPaginas}
            onClick={() => {
              setPaginaAtual(totalPaginas);
            }}
          >
            {totalPaginas}
          </Pagination.Item>
        );
      }
    }

    return retorno;
  }, [paginaAtual, setPaginaAtual, totalPaginas]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <Pagination>
          <Pagination.Item
            onClick={handlePrimeiro}
            disabled={paginaAtual === 1}
          >
            Primeiro
          </Pagination.Item>
          <Pagination.Item
            onClick={handleAnterior}
            disabled={paginaAtual === 1}
          >
            Anterior
          </Pagination.Item>

          {paginacao}

          <Pagination.Item
            onClick={handleProximo}
            disabled={paginaAtual === totalPaginas || totalPaginas === 0}
          >
            Próximo
          </Pagination.Item>
          <Pagination.Item
            onClick={handleUltimo}
            disabled={paginaAtual === totalPaginas || totalPaginas === 0}
          >
            Último
          </Pagination.Item>
        </Pagination>
      </div>
      <div className="float-right">
        <Form.Control
          value={limite}
          as="select"
          onChange={(event) => {
            const limiteFormatado = Number(event.currentTarget.value);

            setPaginaAtual(1);
            setLimite(limiteFormatado);
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Form.Control>
      </div>
    </div>
  );
};

export default Paginacao;
