import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/relatorios/producao/ordem-producao/ordem-producao-resumo-por-produto-produzido';

interface IOrdemProducaoResumoProdutoProduzidosRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrdemProducaoResumoProdutoProduzidosRelatorioComunicador {
  public async index(
    params: IOrdemProducaoResumoProdutoProduzidosRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrdemProducaoResumoProdutoProduzidosRelatorioComunicador();
