"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SisDevinTipoOperacaoCultivarEnum;
(function (SisDevinTipoOperacaoCultivarEnum) {
    SisDevinTipoOperacaoCultivarEnum["venda"] = "01 - Venda";
    SisDevinTipoOperacaoCultivarEnum["compra"] = "05 - Compra";
    SisDevinTipoOperacaoCultivarEnum["saidaIndustrializacao"] = "17 - Sa\u00EDda Industrializa\u00E7\u00E3o";
    SisDevinTipoOperacaoCultivarEnum["entradaIndustrializacao"] = "18 - Entrada Industrializa\u00E7\u00E3o";
    SisDevinTipoOperacaoCultivarEnum["compraUvasOutrasEmpresas"] = "27 - Compra Uvas Outras Empresas";
})(SisDevinTipoOperacaoCultivarEnum || (SisDevinTipoOperacaoCultivarEnum = {}));
exports.default = SisDevinTipoOperacaoCultivarEnum;
