import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPedidoVendaRetratoComSemValoresItensImpressao,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPedidoVendaRetratoComValoresItensImpressaoPDF {
  dados: IPedidoVendaRetratoComSemValoresItensImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const PedidoVendaRetratoComValoresItensImpressaoPDF: React.FC<
  IPedidoVendaRetratoComValoresItensImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                style={{
                  width: 120,
                  borderRadius: 0,
                }}
              />
            )}
          </View>
          <View style={{ width: '50%', alignItems: 'center' }}>
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                PEDIDO VENDA
              </Text>

              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>

              <View style={{ fontSize: 10, flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold' }}>CNPJ/CPF: </Text>
                <Text>{dados.empresa.cnpjCpf}</Text>

                <View style={{ width: '1%' }} />
                <Text style={{ fontWeight: 'bold' }}>IE: </Text>
                <Text>{dados.empresa.inscricaoEstadual}</Text>
              </View>

              <View style={{ fontSize: 10, flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold' }}>End.: </Text>
                <Text>
                  {dados.empresa.empresaEndereco.logradouro}
                  {`, ${dados.empresa.empresaEndereco.numero}`}
                  {`, ${dados.empresa.empresaEndereco.bairro}`}
                  {`, ${dados.empresa.empresaEndereco.complemento}`}
                </Text>
              </View>

              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>

              {dados.empresa.telefone && (
                <View style={{ fontSize: 10, flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Telefone: </Text>
                  <Text>{dados.empresa.telefone}</Text>
                </View>
              )}

              {dados.empresa.email && (
                <View style={{ fontSize: 10, flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>E-mail: </Text>
                  <Text>{dados.empresa.email}</Text>
                </View>
              )}
            </View>
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: '10pt',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {`Número: `}
                  </Text>
                </View>
                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {dados.numero}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Data Emissão: `}
                  </Text>
                </View>

                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 10 }}>
                    {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'Cliente: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {`${dados.pedidoVendaPessoa.pessoa.codigo} - ${dados.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', width: '60%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '20%' }}
                  >
                    {`CNPJ/CPF: `}
                  </Text>
                  <Text style={{ fontSize: 10, width: '80%' }}>
                    {dados.pedidoVendaPessoa.pessoa.cnpjCpf}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', width: '40%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '20%' }}
                  >
                    {'Telefone: '}
                  </Text>
                  <Text style={{ fontSize: 10, width: '80%' }}>
                    {dados.pedidoVendaPessoa.pessoa.telefone}
                  </Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {`Endereço: `}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.pedidoVendaPessoaEndereco.logradouro &&
                    `${dados.pedidoVendaPessoaEndereco.logradouro}, `}
                  {dados.pedidoVendaPessoaEndereco.numero &&
                    `${dados.pedidoVendaPessoaEndereco.numero}, `}
                  {dados.pedidoVendaPessoaEndereco.bairro &&
                    `${dados.pedidoVendaPessoaEndereco.bairro}, `}
                  {dados.pedidoVendaPessoaEndereco.complemento &&
                    dados.pedidoVendaPessoaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'Cidade: '}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.pedidoVendaPessoaEndereco?.cidade &&
                    `${dados.pedidoVendaPessoaEndereco?.cidade?.nome} - ${dados.pedidoVendaPessoaEndereco?.cidade?.estado.sigla}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{ fontSize: 10, fontWeight: 'bold', width: '12%' }}
                >
                  {'CEP: '}
                </Text>
                <Text style={{ fontSize: 10, width: '88%' }}>
                  {dados.pedidoVendaPessoaEndereco.cep}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              backgroundColor: '#cdcdcd',
              fontSize: 9,
            }}
          >
            <View style={{ width: '4%', alignItems: 'flex-end' }}>
              <Text>SEQ</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%', alignItems: 'flex-end' }}>
              <Text>CÓDIGO</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '50%' }}>
              <Text>DESCRIÇÃO DO PRODUTO</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Qtde.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Unit. (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Val. Total (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          {dados.listaPedidoVendaItem?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>{dado.ordem}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '8%', alignItems: 'flex-end' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '50%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>{dado.unidadeMedida.sigla}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />
              </View>
            );
          })}

          <View
            style={{
              marginTop: '10pt',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '85%', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Valor Sub. Total: `}
                </Text>
              </View>
              <View
                style={{
                  width: '15%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.valorTotalProdutos).FormatarParaPtBr()}
                </Text>
              </View>
            </View>

            {dados.valorFrete && dados.valorFrete > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Frete: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorFrete).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorSeguro && dados.valorSeguro > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Seguro: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorSeguro).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorOutrasDespesas && dados.valorOutrasDespesas > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Outras Desp.: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorOutrasDespesas).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorDesconto && dados.valorDesconto > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Desconto: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorDesconto).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorTotalIcmsSt && dados.valorTotalIcmsSt > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Total ICMS ST.: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalIcmsSt).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            {dados.valorTotalIpi && dados.valorTotalIpi > 0 && (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '85%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Valor Total IPI: `}
                  </Text>
                </View>
                <View
                  style={{
                    width: '15%',
                    alignItems: 'flex-end',
                    backgroundColor: '#cdcdcd',
                  }}
                >
                  <Text style={{ fontSize: 10 }}>
                    {Number(dados.valorTotalIpi).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            )}

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '85%', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Valor Total: `}
                </Text>
              </View>

              <View
                style={{
                  width: '15%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>

          {dados.observacoesComerciais && (
            <View
              style={{
                marginTop: '10pt',
                padding: '4pt',
                backgroundColor: '#eeeeee',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                  {dados.observacoesComerciais
                    ? 'OBSERVAÇÕES COMERCIAIS: '
                    : ''}
                </Text>
                <Text style={{ fontSize: 8, width: '100%', marginTop: '5pt' }}>
                  {dados.observacoesComerciais
                    ? dados.observacoesComerciais
                    : ''}
                </Text>
              </View>
            </View>
          )}
        </View>

        <View
          style={{
            marginTop: '5pt',
            marginBottom: '5pt',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 8,
          }}
        >
          <Text style={{ width: '3%' }}>ASS: </Text>
          <View style={{ width: '35%', borderBottom: '1pt dotted black' }} />
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaRetratoComValoresItensImpressaoPDF;
