/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { ValidarCnpj, ValidarCpf, FormatarCnpjCpf } from '@elogestor/util';
import { FaSearch } from 'react-icons/fa/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, SpanErro } from '../Styles';
import { ContainerCpf } from './styles';
import { Sleep } from '../../Padrao/MenuPrincipal/Scripts';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputCnpjCpfProps {
  valorAnterior: string;
}

export interface IOnChangeInputCnpjCpfValidoEvent {
  valor: string;
  isCpf: boolean;
  isZero: boolean;
}

export interface IOnBlurInputCnpjCpfValidoEvent {
  valor: string;
  isCpf: boolean;
  isZero: boolean;
  mudou: boolean;
}

export interface IInputCnpjCpfRef {
  focus(): void;
}

interface IInputCnpjCpfProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  loading?: boolean;
  permitirPesquisar?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputCnpjCpfProps
  ) => void | Promise<void>;
  onChangeValido?: (event: IOnChangeInputCnpjCpfValidoEvent) => Promise<void>;
  onBlurValido?: (event: IOnBlurInputCnpjCpfValidoEvent) => Promise<void>;
  onPesquisarCnpjCpf?: () => Promise<void>;
}

const InputCnpjCpf: React.ForwardRefRenderFunction<
  IInputCnpjCpfRef,
  IInputCnpjCpfProps
> = (
  {
    name,
    label,
    loading = false,
    permitirPesquisar = true,

    onFocus,
    onChange,
    onChangeValido,
    onBlur,
    onBlurValido,
    onPesquisarCnpjCpf,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [mudou, setMudou] = useState(false);
  const [erro, setErro] = useState(erroUnform);
  const [desabilitado, setDesabilitado] = useState(true);
  const [carregando, setCarregando] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
    if (inputRef.current?.value) {
      setDesabilitado(loading);
    }
  }, [desabilitado, erroUnform, loading]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let valor = event.target.value;

      if (valor.replace(/\D/g, '').length > 14) {
        valor = valor.slice(0, -1);
      }

      const isCpf = ValidarCpf(valor);
      if (ValidarCnpj(valor) || isCpf) {
        setDesabilitado(false);
        setMudou(true);

        if (onChangeValido) {
          let isZero = false;
          if (valor === '00000000000' || valor === '00000000000000') {
            isZero = true;
          }

          if (
            inputRef.current &&
            (inputRef.current.value.length === 11 ||
              inputRef.current.value.length === 14)
          ) {
            setDesabilitado(false);
          } else {
            setDesabilitado(true);
          }

          onChangeValido({ isCpf, isZero, valor });
        }
      } else {
        setDesabilitado(true);
      }

      valor = FormatarCnpjCpf(valor);
      event.target.value = valor;

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange, onChangeValido]
  );

  const handleInputBlur = useCallback(
    async (event: any) => {
      setCarregando(true);
      setIsFocused(false);

      const valor = inputRef.current?.value.replace(/\D/g, '');
      const isCpf = ValidarCpf(valor || '');

      if (valor && !ValidarCnpj(valor) && !isCpf) {
        setErro('CPF ou CNPJ inválido!');
      } else {
        setIsFilled(!!valor);
        if (onBlur) onBlur(event);
        if (onBlurValido) {
          let isZero = false;
          if (valor === '00000000000' || valor === '00000000000000') {
            isZero = true;
          }

          onBlurValido({ isCpf, isZero, valor: valor || '', mudou });
        }
      }

      await Sleep(100);

      setCarregando(false);
      setMudou(false);
    },
    [mudou, onBlur, onBlurValido]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        let valor = inputRef.current?.value || '';
        valor = valor.replace(/\D/g, '');

        return valor;
      },

      setValue(_, value = '') {
        value = FormatarCnpjCpf(value);
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (ValidarCnpj(value) || ValidarCpf(value)) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      setSemExecutarEvento(_, value = '') {
        value = FormatarCnpjCpf(value);
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (ValidarCnpj(value) || ValidarCpf(value)) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerCpf>
      {label && <label>{label}</label>}
      <div>
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="text"
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          {...rest}
          ref={inputRef}
          maxLength={18}
        />
        {permitirPesquisar && (
          <button
            type="button"
            className="btn-editar"
            onClick={async () => {
              if (carregando) return;
              if (onPesquisarCnpjCpf) {
                onPesquisarCnpjCpf();
              }
            }}
            disabled={desabilitado}
          >
            <FaSearch />
          </button>
        )}
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </ContainerCpf>
  );
};

export default forwardRef(InputCnpjCpf);
