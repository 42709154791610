/* eslint-disable jsx-a11y/label-has-associated-control */
import { UseFieldCia } from '@elogestor/unformcia';
import { FormatarEnum, MesesEnum, NomeMesParaNumeroMes } from '@elogestor/util';
// import { isBefore } from 'date-fns';
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import SelectSemRegister from './SelectSemRegister';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputDateSemAnoProps {
  valorAnterior: string | null;
}

export interface IInputDateSemAnoRef {
  inputMesRef: React.RefObject<HTMLSelectElement | null>;
  inputDiaRef: React.RefObject<HTMLSelectElement | null>;
}
interface IInputDateSemAnoProps {
  labelDia: string;
  labelMes: string;
  nome: string;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLSelectElement>,
    props: IOnChangeInputDateSemAnoProps
  ) => void | Promise<void>;
}

function FormatarParaNomeCompleto(mesNumero: number): string {
  return [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ][mesNumero - 1];
}

const InputDateSemAno: React.ForwardRefRenderFunction<
  IInputDateSemAnoRef,
  IInputDateSemAnoProps
> = ({ labelDia, labelMes, nome, error: errorPai, onChange }, ref) => {
  const { fieldName, error, registerField } = UseFieldCia(nome);
  const { terminouCarregarDados } = UseForm();

  const inputMesRef = useRef<HTMLSelectElement | null>(null);
  const inputDiaRef = useRef<HTMLSelectElement | null>(null);
  const valorAnterior = useRef<string | null>('');

  const [listaMes] = useState(
    FormatarEnum({
      enumObj: MesesEnum,
      nullavel: true,
    })
  );
  const [listaDias, setListaDias] = useState<any[]>([]);
  const [erro, setErro] = useState(error);

  const numeroDiasPorMes = useCallback((mesPeriodo: string) => {
    let numeroDias: number;
    if (
      mesPeriodo === MesesEnum.abril ||
      mesPeriodo === MesesEnum.junho ||
      mesPeriodo === MesesEnum.setembro ||
      mesPeriodo === MesesEnum.novembro
    ) {
      numeroDias = 30;
    } else if (
      mesPeriodo === MesesEnum.janeiro ||
      mesPeriodo === MesesEnum.marco ||
      mesPeriodo === MesesEnum.maio ||
      mesPeriodo === MesesEnum.julho ||
      mesPeriodo === MesesEnum.agosto ||
      mesPeriodo === MesesEnum.outubro ||
      mesPeriodo === MesesEnum.dezembro
    ) {
      numeroDias = 31;
    } else {
      numeroDias = 28;
    }
    const listaDiasBle = [];
    for (let i = 1; i <= numeroDias; i++) {
      const j = `0${i}`.slice(-2);
      listaDiasBle.push({ value: j, title: `${j}` });
    }
    setListaDias(listaDiasBle);
  }, []);

  useEffect(() => {
    if (inputMesRef.current?.value && inputDiaRef.current?.value) {
      valorAnterior.current = `${NomeMesParaNumeroMes(
        inputMesRef.current.value as MesesEnum
      )}-${inputDiaRef.current.value}`;
    }
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(error);
  }, [error]);

  useEffect(() => {
    setErro(errorPai);
  }, [errorPai]);

  useEffect(() => {
    registerField<string | null>({
      name: fieldName,
      ref: {
        inputMesRef: inputMesRef.current,
        inputDiaRef: inputDiaRef.current,
      },

      getValue() {
        if (inputMesRef.current?.value && inputDiaRef.current?.value) {
          return `${NomeMesParaNumeroMes(
            inputMesRef.current.value as MesesEnum
          )}-${inputDiaRef.current.value}`;
        }
        return null;
      },

      setValue(_, novoValor) {
        setErro('');
        valorAnterior.current = this.getValue(_);
        if (!inputMesRef.current || !inputDiaRef.current) return;

        if (!novoValor) {
          inputMesRef.current.value = '';
          inputDiaRef.current.value = '';
          return;
        }
        const [mm, dd] = novoValor.split('-');

        inputMesRef.current.value = FormatarParaNomeCompleto(Number(mm));
        inputDiaRef.current.value = dd;
      },

      setSemExecutarEvento(_, novoValor) {
        setErro('');
        valorAnterior.current = this.getValue(_);
        if (!inputMesRef.current || !inputDiaRef.current) return;

        if (!novoValor) {
          inputMesRef.current.value = '';
          inputDiaRef.current.value = '';
          return;
        }
        const [mm, dd] = novoValor.split('-');

        inputMesRef.current.value = FormatarParaNomeCompleto(Number(mm));
        inputDiaRef.current.value = dd;
      },

      clearValue(_, valorInicial = null) {
        setErro('');
        this.setValue(_, valorInicial || '');
      },

      validarSeAlterou(_, valorInicial = null) {
        const valor = this.getValue(_);
        return valor !== valorInicial;
      },

      setDisabled(valor) {
        if (!inputMesRef.current || !inputDiaRef.current) return;

        inputMesRef.current.disabled = valor;
        inputDiaRef.current.disabled = valor;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => {
    return {
      inputMesRef,
      inputDiaRef,
    };
  });

  const handleInputFocus = useCallback(() => {
    if (inputMesRef.current?.value && inputDiaRef.current?.value) {
      valorAnterior.current = `${NomeMesParaNumeroMes(
        inputMesRef.current.value as MesesEnum
      )}-${inputDiaRef.current.value}`;
    }
  }, []);

  return (
    <Row>
      <Col sm={7} xs={7}>
        <SelectSemRegister
          erro={erro}
          ref={inputMesRef}
          label={labelMes}
          options={listaMes}
          onFocus={handleInputFocus}
          onChange={(event) => {
            if (!event.target.value) {
              setListaDias([]);
              return;
            }
            numeroDiasPorMes(event.target.value);
            if (onChange)
              onChange(event, { valorAnterior: valorAnterior.current });
          }}
        />
      </Col>
      <Col sm={5} xs={5}>
        <SelectSemRegister
          erro={erro}
          ref={inputDiaRef}
          disabled={!listaDias.length}
          label={labelDia}
          options={listaDias}
          onFocus={handleInputFocus}
          onChange={(event) => {
            if (onChange)
              onChange(event, { valorAnterior: valorAnterior.current });
          }}
        />
      </Col>
    </Row>
  );
};

export default forwardRef(InputDateSemAno);
