import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/marca-produto-auto-complete';

interface IMarcaProdutoAutoCompleteComunicadorIndex {
  params: any;
}

class MarcaProdutoAutoCompleteComunicador {
  public async index(
    params: IMarcaProdutoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new MarcaProdutoAutoCompleteComunicador();
