/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Container, Row, Modal, Table } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  IoIosCloseCircleOutline,
  IoMdAddCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraCompletaParaPtBr,
  IAberturaFechamentoCaixaValoresInserir,
  SituacaoAberturaFechamentoCaixaEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import AberturaFechamentoCaixaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Comunicador/AberturaFechamentoCaixaComunicador';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import AberturaFechamentoCaixaHook from '../../../../AberturaFechamentoCaixa/Detalhe/Hooks';
import Detalhe from '../../../../../../../Componentes/Detalhe';
import Main from '../../../../AberturaFechamentoCaixa/Detalhe/Main';

interface ISelecionarCaixaParametros {
  dataHoraAbertura?: Date;
}
interface ISelecaoCaixaContext {
  selecionarCaixa(dados: ISelecionarCaixaParametros): Promise<any>;
}

const SelecaoCaixaContext = createContext<ISelecaoCaixaContext>(
  {} as ISelecaoCaixaContext
);

const SelecaoCaixaHook: React.FC<IPadraoProps> = ({ children }) => {
  const formRef = useRef<IFormCiaHandles>(null);

  const resolveRef = useRef<any>();

  const [aberto, setAberto] = useState(false);
  const [showNovo, setShowNovo] = useState(false);
  const [listaValor, setListaValor] = useState<
    IAberturaFechamentoCaixaValoresInserir[]
  >([]);

  const handleSelecionarCaixa = useCallback(
    async ({ dataHoraAbertura }: ISelecionarCaixaParametros): Promise<any> => {
      try {
        setAberto(true);

        const response = await AberturaFechamentoCaixaComunicador.index({
          params: {
            pesquisaAvancada: {
              dataAberturaInicial: dataHoraAbertura,
              situacao: SituacaoAberturaFechamentoCaixaEnum.aberto,
            },
            order: 'dataHoraAbertura',
            descAsc: 'desc',
          },
        });

        setListaValor(response.dados[0]);

        return new Promise<any>((resolve) => {
          resolveRef.current = resolve;
        });
      } catch (error) {
        TratarErros(error);
        setAberto(false);
        return null;
      }
    },
    []
  );

  const handleClickSelecionar = useCallback(
    (index: number) => {
      const item = listaValor[index];

      localStorage.setItem(
        '@EloGestorle:turno',
        JSON.stringify({
          id: item.id,
          terminalCaixa: item.terminalCaixa,
          usuarioAbertura: item.usuarioAbertura,
          dataHoraAbertura: item.dataHoraAbertura,
        })
      );

      setAberto(false);
      resolveRef.current(item);
    },
    [listaValor]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleNovo = useCallback(() => {
    setShowNovo(true);
  }, []);

  return (
    <>
      <SelecaoCaixaContext.Provider
        value={{
          selecionarCaixa: handleSelecionarCaixa,
        }}
      >
        <Modal show={aberto} size="xl" ti>
          <Modal.Header
            style={{
              background: '#3397c4',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Modal.Title>{'Selecione o Caixa'}</Modal.Title>
            <BtnCloseButton type="button" onClick={handleFechar}>
              <IoIosCloseCircleOutline />
            </BtnCloseButton>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <FormCia ref={formRef}>
                <Tabela style={{ overflow: 'auto', height: 500 }}>
                  <Table striped bordered variant="light">
                    <thead>
                      <tr>
                        <th className="lista-texto" style={{ width: '20%' }}>
                          <span>Terminal Caixa</span>
                        </th>
                        <th className="lista-data" style={{ width: '20%' }}>
                          <span>Data Hora Abertura</span>
                        </th>
                        <th className="lista-texto" style={{ width: '20%' }}>
                          <span>Usuário Abertura</span>
                        </th>
                        <th className="lista-valor" style={{ width: '20%' }}>
                          <span>Valor em Dinheiro</span>
                        </th>
                        <th className="lista-texto" style={{ width: '20%' }}>
                          <span>Situação</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaValor &&
                        listaValor.map((item, index) => {
                          return (
                            <tr
                              key={v4()}
                              onClick={async () => {
                                await handleClickSelecionar(index);
                              }}
                            >
                              <td className="lista-texto">
                                <div>{item.terminalCaixa?.descricao}</div>
                              </td>
                              <td className="lista-data">
                                <div>
                                  {FormatarDataHoraCompletaParaPtBr(
                                    item.dataHoraAbertura
                                  )}
                                </div>
                              </td>
                              <td className="lista-texto">
                                <div>{item.usuarioAbertura?.nome}</div>
                              </td>
                              <td className="lista-valor">
                                <div>
                                  {Number(
                                    item.valorEmDinheiro
                                  ).FormatarParaPtBr()}
                                </div>
                              </td>
                              <td
                                className="lista-texto"
                                style={{
                                  fontWeight: 'bold',
                                  color:
                                    item.situacao ===
                                    SituacaoAberturaFechamentoCaixaEnum.aberto
                                      ? 'green'
                                      : 'red',
                                }}
                              >
                                <div>{item.situacao}</div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tabela>
              </FormCia>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <div>
              <Container>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleNovo}
                      style={{ marginLeft: 10 }}
                    >
                      <IoMdAddCircleOutline />
                      <span style={{ marginLeft: 10 }}>Novo</span>
                    </button>
                  </div>
                </Row>
              </Container>
            </div>
          </Modal.Footer>
        </Modal>
        {children}
      </SelecaoCaixaContext.Provider>

      {showNovo && (
        <JanelaDetalhe
          titulo={'Abertura / Fechamento do Caixa'}
          tamanho="xl"
          onFecharFormModal={() => {
            setShowNovo(false);
          }}
        >
          <Modal.Body>
            <Container>
              <AberturaFechamentoCaixaHook>
                <Detalhe>
                  <Main
                    idEditar={null}
                    dadosRecuperados={null}
                    dadosDuplicados={null}
                    onClickSalvar={(data) => {
                      setShowNovo(false);
                      setAberto(false);
                      resolveRef.current(data);
                    }}
                  />
                </Detalhe>
              </AberturaFechamentoCaixaHook>
            </Container>
          </Modal.Body>
        </JanelaDetalhe>
      )}
    </>
  );
};

function UseSelecaoCaixa(): ISelecaoCaixaContext {
  const context = useContext(SelecaoCaixaContext);

  if (!context) {
    throw new Error(
      'UseSelecaoCaixa deve ser usado com um SelecaoCaixaProvider'
    );
  }

  return context;
}

export { SelecaoCaixaHook, UseSelecaoCaixa };
