"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ZeroEsquerdaInscricaoEstadual;
const ValidarInscricaoEstadual_1 = require("./ValidarInscricaoEstadual");
function ZeroEsquerdaInscricaoEstadual(ie, uf) {
    ie = ie.trim();
    let ieOriginal = ie;
    for (let i = 0; i < 3; i++) {
        const validacao = (0, ValidarInscricaoEstadual_1.ValidarInscricao)(ie, uf);
        if (validacao)
            return ie;
        ie = `0${ie}`;
    }
    for (let i = 0; i < 8; i++) {
        const validacao = (0, ValidarInscricaoEstadual_1.ValidarInscricao)(ieOriginal, uf);
        if (validacao) {
            return ieOriginal;
        }
        if (ieOriginal.charAt(0) === '0')
            ieOriginal = ieOriginal.substring(1, ieOriginal.length);
    }
    return ie;
}
