import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Detalhe from '../../../../../Componentes/Detalhe';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import AcoesConhecimentoTransporteTerceiro from './AcoesConhecimentoTransporteTerceiro';
import ConhecimentoTransporteEntradaTerceiroHook from './Hooks';
import Main from './Main/index';
import { Container } from './styles';

const ConhecimentoTransporteEntradaTerceiroDetalhe: React.FC = () => {
  const params: IParametroRotaDetalhe = useParams();
  const { permissoes } = UsePermissoes();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <ConhecimentoTransporteEntradaTerceiroHook>
        <SubHeader
          titulo={
            params.id
              ? 'Editar Conhecimento de Transporte de Ent. Terc.'
              : 'Conhecimento de Transporte de Ent. Terc.'
          }
          permissao={
            permissoes.SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro
          }
          botoes={<AcoesConhecimentoTransporteTerceiro />}
          isConhecimentoTransporteTerceiro
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </ConhecimentoTransporteEntradaTerceiroHook>
    </Container>
  );
};

export default ConhecimentoTransporteEntradaTerceiroDetalhe;
