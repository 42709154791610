import { createContext, useContext } from 'react';

interface IRotasContext {
  carregar: string;
  novo: string;
  alterar: string;
  excluir: string;
}

const rotasContext = createContext<IRotasContext>({} as IRotasContext);
const ListaDetalheRotasProvider = rotasContext.Provider;

function UseListaDetalheRota(): IRotasContext {
  const context = useContext(rotasContext);

  return context;
}

export { ListaDetalheRotasProvider, UseListaDetalheRota, rotasContext };
