import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/item';
const rotaNovo = 'comercial/vendas/nota-fiscal-saida';

interface INotaFiscalSaidaItemComunicadorShow {
  id: string;
}

interface INotaFiscalSaidaItemComunicadorStore {
  idPai: string;
  params: any;
}

interface INotaFiscalSaidaItemComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalSaidaItemComunicadorDelete {
  id: string;
}

class NotaFiscalSaidaItemComunicador {
  public async show(params: INotaFiscalSaidaItemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalSaidaItemComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: INotaFiscalSaidaItemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalSaidaItemComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaItemComunicador();
