import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  BooleanEnum,
  FormatarEnum,
  IProdutoFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import Input from '../../../../Componentes/Inputs/Input';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import Select from '../../../../Componentes/Select';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import { IOnChangeListaItemAtualEvent } from '../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import InputAutoCompleteTagUnidadeMedida from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagUnidadeMedida';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagNcm from '../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputAutoCompleteTagCest from '../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCest';
import InputAutoCompleteTagSpedTipoProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputDateTime from '../../../../Componentes/Inputs/InputDateTime';
import TextoLoading from '../../../../Componentes/TextoLoading';
import InputAutoCompleteTagLocalEstoque from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLocalEstoque';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';
import InputAutoCompleteTagMarcaProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagMarcaProduto';

interface IProdutoRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const ProdutoRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] =
    useState('ProdutoPorGrupo');
  const [loading, setLoading] = useState(false);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        codigo: Yup.string().nullable().max(60, 'Código inválido'),
        descricao: Yup.string().nullable().max(120, 'Descrição inválida'),
        tipoBuscaCodigoDescricao: Yup.string().required(
          'Tipo de Busca não pode ser nulo!'
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
          'ncm',
          'cest',
          'localEstoque',
        ].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
          'ncm',
          'cest',
          'localEstoque',
        ].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
          'ncm',
          'cest',
          'localEstoque',
        ].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IProdutoRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IProdutoRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IProdutoRelatorioDados;

      handleValidar(dados);

      const produto = formRef.current?.getFieldValueNomeObjeto('produto');
      const unidadeMedida =
        formRef.current?.getFieldValueNomeObjeto('unidadeMedida');
      const grupoProdutoServico = formRef.current?.getFieldValueNomeObjeto(
        'grupoProdutoServico'
      );
      const ncm = formRef.current?.getFieldValueNomeObjeto('ncm');
      const cest = formRef.current?.getFieldValueNomeObjeto('cest');
      const tipoSped = formRef.current?.getFieldValueNomeObjeto('tipoSped');
      const localEstoque =
        formRef.current?.getFieldValueNomeObjeto('localEstoque');
      const ativo = formRef.current?.getFieldValueNomeObjeto('ativo');

      const dadosPesquisa = {
        ...dados,
        produto,
        unidadeMedida,
        grupoProdutoServico,
        ncm,
        cest,
        tipoSped,
        localEstoque,
        ativo,
      };

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dadosPesquisa as IProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      opcaoRelatorio: 'ProdutoPorGrupo',
      ativo: true,
    });
  }, []);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Produtos
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Grupo',
                        value: 'ProdutoPorGrupo',
                      },
                      {
                        title: 'Por NCM',
                        value: 'ProdutoPorNcm',
                      },
                      {
                        title: 'Por NCM - com NCM fora de vigência',
                        value: 'ProdutoPorNcmForaVigencia',
                      },
                      {
                        title: 'Por CEST',
                        value: 'ProdutoPorCest',
                      },
                      {
                        title: 'Por Tipo Sped',
                        value: 'ProdutoPorTipoSped',
                      },
                      {
                        title: 'Por Local de Estoque',
                        value: 'ProdutoPorLocalEstoque',
                      },
                      {
                        title: 'Por Grupo Com Referências',
                        value: 'ProdutoPorGrupoComReferencias',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.currentTarget.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                {inputOpcaoRelatorio === 'ProdutoPorNcmForaVigencia' && (
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <InputDateTime
                        label="Data Vigência"
                        name="dataVigencia"
                        dataAtualPadrao
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl={12} lg={12}>
                    <InputAutoCompleteTagProduto
                      label="Produtos"
                      name="listaProduto"
                      onChangeListaItemAtual={handleChangeProduto}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Código"
                      name="codigo"
                      placeholder="Código"
                      maxLength={60}
                    />
                  </Col>
                  <Col lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={120}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Filtro Codigo/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      name="ativo"
                      label="Ativo"
                      options={FormatarEnum({
                        enumObj: BooleanEnum,
                        todos: true,
                      })}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagUnidadeMedida
                      label="Unidade de Medida"
                      name="listaUnidadeMedida"
                    />
                  </Col>

                  <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      label="Grupos"
                      name="listaGrupoProdutoServico"
                      utilizavelProdutoServico
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagNcm
                      label="NCM (Nomenclatura Comum do Mercosul)"
                      name="listaNcm"
                      utilizavelProduto
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCest
                      label="CEST (Código Especificador da Substituição Tributária)"
                      name="listaCest"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSpedTipoProduto
                      label="Tipo SPED"
                      name="listaTipoSped"
                    />
                  </Col>

                  <Col xl={6} lg={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagMarcaProduto
                      label="Marcas"
                      name="listaMarca"
                      nomeObjeto="marca"
                    />
                  </Col>

                  {inputOpcaoRelatorio === 'ProdutoPorLocalEstoque' && (
                    <Col xl={12} lg={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagLocalEstoque
                        label="Locais de estoque"
                        name="listaLocalEstoque"
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default ProdutoRelatorio;
