import { RegimeTributarioEnum } from '@elogestor/util';
import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/regime-tributario-vigente';

interface IEmpresaRegimeTributarioVigenteComunicadorIndex {
  params?: {
    dataFinalVigencia?: Date;
  };
}

interface IEmpresaRegimeTributarioVigenteComunicadorIndexRetorno {
  id: string;
  dataInicioVigencia: string;
  regimeTributario: RegimeTributarioEnum;
}

class EmpresaRegimeTributarioVigenteComunicador {
  public async index(
    params: IEmpresaRegimeTributarioVigenteComunicadorIndex
  ): Promise<IEmpresaRegimeTributarioVigenteComunicadorIndexRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new EmpresaRegimeTributarioVigenteComunicador();
