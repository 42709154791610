import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa';

interface IPessoaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IPessoaComunicadorShow {
  id: string;
}

interface IPessoaComunicadorStore {
  params: any;
}

interface IPessoaComunicadorUpdate {
  id: string;
  params: any;
}

interface IPessoaComunicadorDeletar {
  id: string;
}

class PessoaComunicador {
  public async index(params: IPessoaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IPessoaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPessoaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IPessoaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IPessoaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaComunicador();
