import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-observacao-cliente-consignado';

interface IFrenteCaixaPdvObterObservacaoClienteConsignadoComunicadorShow {
  id: string;
}

class FrenteCaixaPdvObterObservacaoClienteConsignadoComunicador {
  public async show(
    params: IFrenteCaixaPdvObterObservacaoClienteConsignadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new FrenteCaixaPdvObterObservacaoClienteConsignadoComunicador();
