import React from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { ListaVeiculoMotoristaDraggableHook } from './ListaVeiculoMotoristaDraggableHook';
import RotasHook from './RotasHook';

const VeiculoMotoristaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <FormHook>
        <ListaVeiculoMotoristaDraggableHook>
          {children}
        </ListaVeiculoMotoristaDraggableHook>
      </FormHook>
    </RotasHook>
  );
};

export default VeiculoMotoristaHook;
