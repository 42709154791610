const TabelaPrecoProdutosValoresPlanilha = [
  {
    titulo: 'Tabela Preço',
    valor: 'descricao',
  },
  {
    titulo: 'Data Início Vigência',
    valor: 'dataInicioVigencia',
  },
  {
    titulo: 'Data Final Vigência',
    valor: 'dataFinalVigencia',
  },
  {
    titulo: 'Código',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.produto.codigo',
  },
  {
    titulo: 'Descrição',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.produto.descricao',
  },
  {
    titulo: 'Unidade',
    valor:
      'listaProdutoRegraEscolhaTabelaPreco.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.valorUnitarioVenda',
  },
];

export default TabelaPrecoProdutosValoresPlanilha;
