/* eslint-disable default-case */
import {
  IAtualizacaoEstoqueProdutoLista,
  IAtualizacaoEstoqueProdutoListaValoresAlterar,
  IImpressaoEtiquetasProdutosTransferirLista,
  ValidarCodigoBarrasGtin,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { BtnContainer, Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AtualizacaoEstoqueProdutoComunicador from '../../../../../Comunicador/Suprimentos/Produtos/AtualizacaoEstoqueProduto/Comunicador/AtualizacaoEstoqueProdutoComunicador';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import InputTabela from '../../../../../Componentes/Inputs/InputTabela/InputTabela';
import InputTabelaDecimal from '../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import InputTabelaSwitch from '../../../../../Componentes/Inputs/InputTabela/InputTabelaSwitch';
import InputTabelaAutoCompleteNcm from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteNcm';
import InputTabelaAutoCompleteCest from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteCest';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseAtualizacaoEstoqueProdutoLista } from '../Hooks/AtualizacaoEstoqueProdutoListaHook';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import AcoesAtualizacaoEstoqueProduto from '../AcoesAtualizacaoEstoqueProduto';

interface IControlarFocusParametros {
  index: number;
  nomeCampo: string;
  listaTh: ITh[];
  listaItens: IAtualizacaoEstoqueProdutoLista[];
}

interface IControlarFocusRetorno {
  linhaIndexAnterior: number;
  linhaIndexProxima: number;
  listaRefAnterior: any;
  listaRefProxima: any;
}

type IProps = {
  dadosRecuperados: any;
};

const AtualizacaoEstoqueProdutoLista: React.FC<IProps> = ({
  dadosRecuperados,
}) => {
  const { abrirJanela } = UseConfirmacao();
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosAtualizacaoEstoqueProduto: permissao } =
    permissoes;
  const { setParametros } = UseTableDraggable();

  const [jaRecuperouDados, setJaRecuperouDados] = useState(false);

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    listaDescricaoRef,
    listaCodigoBarrasRef,
    listaAtivoRef,
    listaEstoqueQuantidadeRef,
    listaNcmRef,
    listaCestRef,
  } = UseAtualizacaoEstoqueProdutoLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [listaColunas, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (dadosRecuperados)
      setParametros({
        pagina: dadosRecuperados.pagina,
        limite: dadosRecuperados.limite,
      });
  }, [dadosRecuperados, setParametros]);

  const handleControlarFocus = useCallback(
    ({
      index,
      nomeCampo,
      listaTh,
      listaItens,
    }: IControlarFocusParametros): IControlarFocusRetorno | null => {
      const listaColunasSelecionaveis = listaTh.filter(
        (coluna) =>
          coluna.visivel &&
          (coluna.nomeCampo === 'descricao' ||
            coluna.nomeCampo === 'produtoEngenharia.codigoBarras' ||
            coluna.nomeCampo === 'estoqueCombinacao.quantidade')
      );
      if (
        !listaColunasSelecionaveis ||
        listaColunasSelecionaveis.length === 0
      ) {
        return null;
      }

      const colunaIndexAtual = listaColunasSelecionaveis.findIndex(
        (coluna) => coluna.nomeCampo === nomeCampo
      );

      let linhaIndexAnterior = index;
      let colunaIndexAnterior = colunaIndexAtual;
      let listaRefAnterior: any;

      do {
        colunaIndexAnterior -= 1;
        if (colunaIndexAnterior < 0) {
          linhaIndexAnterior -= 1;
          if (linhaIndexAnterior < 0) {
            linhaIndexAnterior = listaItens.length - 1;
          }

          colunaIndexAnterior = listaColunasSelecionaveis.length - 1;
        }

        switch (listaColunasSelecionaveis[colunaIndexAnterior].nomeCampo) {
          case 'descricao':
            listaRefAnterior = listaDescricaoRef;
            break;

          case 'produtoEngenharia.codigoBarras':
            listaRefAnterior = listaCodigoBarrasRef;
            break;

          case 'estoqueCombinacao.quantidade':
            listaRefAnterior = listaEstoqueQuantidadeRef;
            break;
        }
      } while (!listaRefAnterior.current[linhaIndexAnterior]);

      let linhaIndexProxima = index;
      let colunaIndexProxima = colunaIndexAtual;
      let listaRefProxima: any;

      do {
        colunaIndexProxima += 1;
        if (colunaIndexProxima >= listaColunasSelecionaveis.length) {
          linhaIndexProxima += 1;
          if (linhaIndexProxima >= listaItens.length) {
            linhaIndexProxima = 0;
          }

          colunaIndexProxima = 0;
        }

        switch (listaColunasSelecionaveis[colunaIndexProxima].nomeCampo) {
          case 'descricao':
            listaRefProxima = listaDescricaoRef;
            break;

          case 'produtoEngenharia.codigoBarras':
            listaRefProxima = listaCodigoBarrasRef;
            break;

          case 'estoqueCombinacao.quantidade':
            listaRefProxima = listaEstoqueQuantidadeRef;
            break;
        }
      } while (!listaRefProxima.current[linhaIndexProxima]);

      return {
        linhaIndexAnterior,
        linhaIndexProxima,
        listaRefAnterior,
        listaRefProxima,
      };
    },
    [listaCodigoBarrasRef, listaDescricaoRef, listaEstoqueQuantidadeRef]
  );

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
    if (itensSelecionados?.length === 0 && dadosRecuperados)
      setJaRecuperouDados(true);
  }, [dadosRecuperados, listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef]
  );

  const handleObterItensSelecionados =
    useCallback((): IAtualizacaoEstoqueProdutoListaValoresAlterar[] => {
      const listaIndexItemSelecionado: number[] = [];
      const listaItemSelecionado: IAtualizacaoEstoqueProdutoListaValoresAlterar[] =
        [];

      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item && item.checked) {
          listaIndexItemSelecionado.push(index);
        }
      });

      listaIndexItemSelecionado.forEach((index) => {
        if (listaValor[index].exibirRegistro) {
          const retorno: IAtualizacaoEstoqueProdutoListaValoresAlterar = {
            id: listaValor[index].id,
            codigo: listaValor[index].codigo,
            descricao:
              listaDescricaoRef.current && listaDescricaoRef.current[index]
                ? listaDescricaoRef.current[index].value
                : undefined,
            ativo:
              listaAtivoRef.current && listaAtivoRef.current[index]
                ? Boolean(listaAtivoRef.current[index].checked)
                : undefined,

            produtoEngenharia: {
              ...listaValor[index].produtoEngenharia,
              codigoBarras:
                listaCodigoBarrasRef.current &&
                listaCodigoBarrasRef.current[index]
                  ? listaCodigoBarrasRef.current[index].value
                  : undefined,
            },

            produtoFiscal: {
              ...listaValor[index].produtoFiscal,
              ncm:
                listaNcmRef.current && listaNcmRef.current[index]
                  ? listaNcmRef.current[index].getItemAtual()
                  : undefined,
              cest:
                listaCestRef.current && listaCestRef.current[index]
                  ? listaCestRef.current[
                      index
                    ].autoCompleteRef.current?.getItemAtual()
                  : undefined,
            },

            estoqueCombinacao: [],
          };

          listaItemSelecionado.push(retorno);
        }

        const registro = listaItemSelecionado.find(
          (e) => e.id === listaValor[index].id
        );

        if (registro) {
          let adicionarEstoqueCombinacao = false;
          const estoqueCombinacao = {
            ...listaValor[index].estoqueCombinacao[0],
          };

          if (
            listaEstoqueQuantidadeRef.current &&
            listaEstoqueQuantidadeRef.current[index] &&
            listaValor[index].estoqueCombinacao.length > 0
          ) {
            const valorQuantidadeRef =
              listaEstoqueQuantidadeRef.current[
                index
              ].value.ConverterParaNumber();

            if (
              valorQuantidadeRef !==
              Number(listaValor[index].estoqueCombinacao[0].quantidadeOriginal)
            ) {
              estoqueCombinacao.quantidade = valorQuantidadeRef;
              adicionarEstoqueCombinacao = true;
            }
          }

          if (adicionarEstoqueCombinacao) {
            registro.estoqueCombinacao.push(estoqueCombinacao);
          }
        }
      });

      return listaItemSelecionado;
    }, [
      listaAtivoRef,
      listaCestRef,
      listaCodigoBarrasRef,
      listaDescricaoRef,
      listaEstoqueQuantidadeRef,
      listaItemSelecionadoRef,
      listaNcmRef,
      listaValor,
    ]);

  const handleObterItensSelecionadosParaImpressao =
    useCallback(async (): Promise<
      IImpressaoEtiquetasProdutosTransferirLista[]
    > => {
      const listaIndexItemSelecionado: number[] = [];
      const listaIdItemSelecionado: string[] = [];
      const listaItemSelecionado: IImpressaoEtiquetasProdutosTransferirLista[] =
        [];

      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (
          item &&
          (item.checked ||
            listaIdItemSelecionado.includes(listaValor[index].id))
        ) {
          listaIndexItemSelecionado.push(index);

          if (!listaIdItemSelecionado.includes(listaValor[index].id))
            listaIdItemSelecionado.push(listaValor[index].id);
        }
      });

      listaIndexItemSelecionado.forEach((index) => {
        if (listaValor[index].exibirRegistro) {
          const retorno: IImpressaoEtiquetasProdutosTransferirLista = {
            selecionado: true,
            quantidade: 0,

            produto: {
              id: listaValor[index].id,
              codigo: listaValor[index].codigo,
              descricao:
                listaDescricaoRef.current && listaDescricaoRef.current[index]
                  ? listaDescricaoRef.current[index].value
                  : '',
            },
          };

          listaItemSelecionado.push(retorno);
        }

        const registro = listaItemSelecionado.find(
          (e) => e.produto.id === listaValor[index].id
        );

        if (registro) {
          if (
            listaEstoqueQuantidadeRef.current &&
            listaEstoqueQuantidadeRef.current[index] &&
            listaValor[index].estoqueCombinacao.length > 0
          ) {
            const valorQuantidadeRef =
              listaEstoqueQuantidadeRef.current[
                index
              ].value.ConverterParaNumber();

            registro.quantidade += valorQuantidadeRef;
          }
        }
      });

      listaItemSelecionado.forEach((itemSelecionado) => {
        if (itemSelecionado.quantidade === 0) {
          itemSelecionado.quantidade = 1;
        }
      });

      return listaItemSelecionado;
    }, [
      listaDescricaoRef,
      listaEstoqueQuantidadeRef,
      listaItemSelecionadoRef,
      listaValor,
    ]);

  const atualizar = useCallback(() => {
    if (selecionarTodosRef.current) {
      selecionarTodosRef.current.checked = false;
    }

    setJaRecuperouDados(true);
    handleSelecionarTodos(false);
    setRefresh((state) => !state);
  }, [handleSelecionarTodos]);

  const handleValidar = useCallback(
    async (
      data: IAtualizacaoEstoqueProdutoListaValoresAlterar[]
    ): Promise<boolean> => {
      try {
        const listaMensagem: string[] = [];

        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          if (item.produtoEngenharia.codigoBarras) {
            if (!ValidarCodigoBarrasGtin(item.produtoEngenharia.codigoBarras)) {
              listaMensagem.push(
                `O Código de Barras do Produto: ${item.codigo} - ${item.descricao} é inválido!`
              );
            }
          }

          if (
            listaColunas.find((e) => e.nomeCampo === 'produtoFiscal.ncm')
              ?.visivel
          ) {
            if (!item.produtoFiscal.ncm || !item.produtoFiscal.ncm.id) {
              listaMensagem.push(
                `Obrigatório informar o NCM do Produto: ${item.codigo} - ${item.descricao}!`
              );
            }
          }
        }

        if (listaMensagem.length > 0) {
          TratarErros({ listaMensagem });
          return false;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [listaColunas]
  );

  const handleClickSalvar = useCallback(async (): Promise<boolean> => {
    try {
      setLoading(true);

      const data = handleObterItensSelecionados();
      if (!(await handleValidar(data))) {
        setLoading(false);
        return false;
      }

      await AtualizacaoEstoqueProdutoComunicador.update({ params: data });

      atualizar();
      ToastSucesso('Registro Salvo!');
      setLoading(false);
      return true;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return false;
    }
  }, [atualizar, handleObterItensSelecionados, handleValidar]);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);

        let listaDados: IAtualizacaoEstoqueProdutoLista[];
        let totalPaginas: number;

        if (dadosRecuperados && !jaRecuperouDados) {
          listaDados = dadosRecuperados.listaDados;
          totalPaginas = dadosRecuperados.totalPaginas;

          const itensSelecionados = listaDados.filter(
            (item: IAtualizacaoEstoqueProdutoLista) => {
              return item.selecionado;
            }
          );

          setPossuiItemSelecionado(itensSelecionados.length > 0);
        } else {
          const response = await AtualizacaoEstoqueProdutoComunicador.index({
            params: {
              pesquisaAvancada,
              order,
              descAsc,
              limite,
              pagina,
              textoPesquisa,
            },
          });

          listaDados = response.dados[0].map(
            (data: IAtualizacaoEstoqueProdutoLista) => {
              return { ...data, selecionado: false };
            }
          );

          totalPaginas = Number(response.dados[1]);
        }

        setListaValor(listaDados);
        const dados = listaDados.map(
          (data: IAtualizacaoEstoqueProdutoLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: data.exibirRegistro ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              codigo: (
                <div>
                  {data.exibirRegistro && (
                    <div className="lista-texto">{data.codigo}</div>
                  )}
                </div>
              ),
              descricao: (
                <div
                  className="lista-texto"
                  style={{ display: data.exibirRegistro ? 'flex' : 'none' }}
                >
                  {data.exibirRegistro && (
                    <InputTabela
                      valorPadrao={data.descricao}
                      maxLength={120}
                      obterRef={(instance) => {
                        if (instance.current && listaDescricaoRef.current) {
                          listaDescricaoRef.current[index] = instance.current;
                          listaDescricaoRef.current[index].value =
                            data.descricao;
                        }
                      }}
                      onKeyDown={(e) => {
                        const controleFocus = handleControlarFocus({
                          index,
                          nomeCampo: 'descricao',
                          listaTh: listaColunas,
                          listaItens: listaValor,
                        });

                        if (!controleFocus) return;

                        const {
                          linhaIndexAnterior,
                          linhaIndexProxima,
                          listaRefAnterior,
                          listaRefProxima,
                        } = controleFocus;

                        if (e.shiftKey && e.key === 'Tab') {
                          e.preventDefault();
                          listaRefAnterior?.current[
                            linhaIndexAnterior
                          ]?.focus();
                        } else if (e.key === 'Enter') {
                          e.preventDefault();

                          let proximo = index;

                          do {
                            proximo += 1;
                            if (proximo >= listaValor.length) proximo = 0;
                          } while (!listaDescricaoRef.current?.[proximo]);

                          listaDescricaoRef.current?.[proximo]?.focus();
                        } else if (e.key === 'Tab') {
                          e.preventDefault();
                          listaRefProxima?.current[linhaIndexProxima]?.focus();
                        }
                      }}
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'produtoEstoque.unidadeMedida.sigla': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">
                        {data.produtoEstoque.unidadeMedida.sigla}
                      </div>
                    )}
                  </div>
                </div>
              ),

              'produtoEngenharia.codigoBarras': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabela
                      valorPadrao={data.produtoEngenharia.codigoBarras}
                      maxLength={14}
                      obterRef={(instance) => {
                        if (instance.current && listaCodigoBarrasRef.current) {
                          listaCodigoBarrasRef.current[index] =
                            instance.current;
                          listaCodigoBarrasRef.current[index].value = String(
                            data.produtoEngenharia.codigoBarras
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        const controleFocus = handleControlarFocus({
                          index,
                          nomeCampo: 'produtoEngenharia.codigoBarras',
                          listaTh: listaColunas,
                          listaItens: listaValor,
                        });

                        if (!controleFocus) return;

                        const {
                          linhaIndexAnterior,
                          linhaIndexProxima,
                          listaRefAnterior,
                          listaRefProxima,
                        } = controleFocus;

                        if (e.shiftKey && e.key === 'Tab') {
                          e.preventDefault();
                          listaRefAnterior?.current[
                            linhaIndexAnterior
                          ]?.focus();
                        } else if (e.key === 'Enter') {
                          e.preventDefault();

                          let proximo = index;

                          do {
                            proximo += 1;
                            if (proximo >= listaValor.length) proximo = 0;
                          } while (!listaCodigoBarrasRef.current?.[proximo]);

                          listaCodigoBarrasRef.current?.[proximo]?.focus();
                        } else if (e.key === 'Tab') {
                          e.preventDefault();
                          listaRefProxima?.current[linhaIndexProxima]?.focus();
                        }
                      }}
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'produtoFiscal.ncm': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabelaAutoCompleteNcm
                      style={{ height: 25 }}
                      obterRef={(instance) => {
                        if (instance.current && listaNcmRef.current) {
                          listaNcmRef.current[index] = instance.current;
                          listaNcmRef.current[index].selecionarItemSemEvento(
                            data.produtoFiscal.ncm
                          );
                        }

                        if (
                          listaCestRef.current &&
                          listaCestRef.current[index]
                        ) {
                          listaCestRef.current[index].setIdNcm(
                            data.produtoFiscal.ncm?.id ?? ''
                          );
                        }
                      }}
                      onChangeItemAtual={(item) => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }

                        if (
                          listaCestRef.current &&
                          listaCestRef.current[index]
                        ) {
                          listaCestRef.current[
                            index
                          ].autoCompleteRef.current?.selecionarItem({});
                          listaCestRef.current[index].setIdNcm(
                            item?.itemAtual?.id ?? ''
                          );
                        }
                      }}
                    />
                  )}
                </div>
              ),
              'produtoFiscal.cest': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabelaAutoCompleteCest
                      style={{ height: 25 }}
                      obterRef={(instance, setIdNcm) => {
                        if (instance.current && listaCestRef.current) {
                          listaCestRef.current[index] = {
                            autoCompleteRef: instance,
                            setIdNcm,
                          };

                          listaCestRef.current[
                            index
                          ].autoCompleteRef.current?.selecionarItemSemEvento(
                            data.produtoFiscal.cest
                          );
                        }
                      }}
                      onChangeItemAtual={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              ativo: (
                <div
                  className="lista-booleano"
                  style={{ color: data.ativo ? 'green' : 'red' }}
                >
                  {data.exibirRegistro && (
                    <InputTabelaSwitch
                      valorPadrao={data.ativo}
                      obterRef={(instance) => {
                        if (
                          instance &&
                          instance.current &&
                          listaAtivoRef.current
                        ) {
                          listaAtivoRef.current[index] = instance.current;
                          listaAtivoRef.current[index].value = data.ativo
                            ? 'true'
                            : 'false';
                        }
                      }}
                      ativo="Sim"
                      inativo="Não"
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'estoqueCombinacao.localEstoque.descricao': (
                <div className="lista-texto">
                  {data.estoqueCombinacao.length > 0
                    ? data.estoqueCombinacao[0].localEstoque.descricao
                    : ''}
                </div>
              ),

              'estoqueCombinacao.lote.codigo': (
                <div className="lista-texto">
                  {data.estoqueCombinacao.length > 0
                    ? data.estoqueCombinacao[0].lote?.codigo
                    : ''}
                </div>
              ),

              'estoqueCombinacao.quantidade': (
                <div className="lista-valor">
                  {data.estoqueCombinacao.length > 0 &&
                    data.produtoEstoque.controlaEstoque && (
                      <InputTabelaDecimal
                        valorPadrao={data.estoqueCombinacao[0].quantidade}
                        obterRef={(instance) => {
                          if (
                            instance &&
                            instance.current &&
                            listaEstoqueQuantidadeRef.current
                          ) {
                            listaEstoqueQuantidadeRef.current[index] =
                              instance.current;
                            listaEstoqueQuantidadeRef.current[index].value =
                              Number(
                                data.estoqueCombinacao[0].quantidade
                              ).FormatarParaPtBr();
                          }
                        }}
                        casasDecimais={2}
                        onKeyDown={(e) => {
                          const controleFocus = handleControlarFocus({
                            index,
                            nomeCampo: 'estoqueCombinacao.quantidade',
                            listaTh: listaColunas,
                            listaItens: listaValor,
                          });

                          if (!controleFocus) return;

                          const {
                            linhaIndexAnterior,
                            linhaIndexProxima,
                            listaRefAnterior,
                            listaRefProxima,
                          } = controleFocus;

                          if (e.shiftKey && e.key === 'Tab') {
                            e.preventDefault();
                            listaRefAnterior?.current[
                              linhaIndexAnterior
                            ]?.focus();
                          } else if (e.key === 'Enter') {
                            e.preventDefault();

                            let proximo = index;

                            do {
                              proximo += 1;
                              if (proximo >= listaValor.length) proximo = 0;
                            } while (
                              !listaEstoqueQuantidadeRef.current?.[proximo]
                            );

                            listaEstoqueQuantidadeRef.current?.[
                              proximo
                            ]?.focus();
                          } else if (e.key === 'Tab') {
                            e.preventDefault();
                            listaRefProxima?.current[
                              linhaIndexProxima
                            ]?.focus();
                          }
                        }}
                        onChange={() => {
                          const indexRegistroPrincipal = listaValor.findIndex(
                            (e) => e.id === data.id && e.exibirRegistro
                          );

                          const itemPrincipalRef =
                            listaItemSelecionadoRef.current?.[
                              indexRegistroPrincipal
                            ];

                          if (itemPrincipalRef && !itemPrincipalRef.checked) {
                            itemPrincipalRef.checked = true;
                          }

                          const itemSelecionadoRef =
                            listaItemSelecionadoRef.current?.[index];

                          if (
                            itemSelecionadoRef &&
                            !itemSelecionadoRef.checked
                          ) {
                            itemSelecionadoRef.checked = true;
                          }

                          handleAlterouSelecionado();
                        }}
                      />
                    )}
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dadosRecuperados,
      handleAlterouSelecionado,
      handleControlarFocus,
      setListaValor,
      listaValor.length,
      jaRecuperouDados,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosAtualizacaoEstoqueProduto' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (jaRecuperouDados) return true;

    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    if (itensSelecionados?.length !== 0) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <div>
            <div style={{ fontSize: 20 }}>
              Atenção, caso clique em OK as alterações não serão salvas!
            </div>
            <div style={{ fontSize: 20 }}>
              Deseja continuar sem salvar as alterações?
            </div>
          </div>
        ),
      });

      if (resposta) {
        if (selecionarTodosRef.current)
          selecionarTodosRef.current.checked = false;

        handleSelecionarTodos(false);
      }

      return resposta;
    }

    return true;
  }, [
    abrirJanela,
    handleSelecionarTodos,
    listaItemSelecionadoRef,
    jaRecuperouDados,
  ]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Atualização de Estoque dos Produtos"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          permitirAtualizar={handlePermitirAtualizar}
          botoes={
            <>
              <AcoesAtualizacaoEstoqueProduto
                loading={loading}
                setLoading={setLoading}
                possuiItemSelecionado={possuiItemSelecionado}
                handleObterItensSelecionados={
                  handleObterItensSelecionadosParaImpressao
                }
                handleSalvar={handleClickSalvar}
              />
              <BtnContainer>
                <button
                  type="button"
                  onClick={handleClickSalvar}
                  className="btn-padrao btn-verde"
                  disabled={
                    loading || !possuiItemSelecionado || !permissao?.altera
                  }
                >
                  <TextoLoadingSalvar loading={loading} />
                </button>
              </BtnContainer>
            </>
          }
        />
        <PesquisaAvancada permitirAtualizar={handlePermitirAtualizar} />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          permitirAtualizar={handlePermitirAtualizar}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default AtualizacaoEstoqueProdutoLista;
