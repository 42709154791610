import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/padronizacao-descricao';

interface IPadronizacaoDescricaoAtributoOrdemComunicadorUpdate {
  id: string;
  params: {
    listaAtributo: Array<{
      id: string;
      ordem: number;
    }>;
  };
}

class PadronizacaoDescricaoAtributoOrdemComunicador {
  public async update(
    params: IPadronizacaoDescricaoAtributoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(
      `${rota}/${params.id}/atributo/ordem`,
      params.params
    );

    return response.data;
  }
}

export default new PadronizacaoDescricaoAtributoOrdemComunicador();
