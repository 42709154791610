import React from 'react';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { useLocation } from 'react-router-dom';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import TreeView from '../../../../../Componentes/TreeView';
import {
  ActionBox,
  ActionItem,
} from '../../../../../Componentes/TreeView/TreeViewActionButton/styles';
import ContaContabilDetalhe from '../Detalhe';
import ContaContabilHook from './Hooks';
import { Container } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import { IListaItem, UseTreeContext } from '../Hooks/TreeContext';
import ContaContabilDetalheHook from '../Detalhe/Hooks';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ContaContabilLogComunicador from '../../../../../Comunicador/Contabil/ContasContabeis/ContaContabil/Log/Comunicador/ContaContabilLogComunicador';
import PesquisaAvancada from './PesquisaAvancada';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';

interface IActionProps {
  handleExcluir: () => Promise<void>;
  handleAdicionarFilho: () => void;
  handleShowLog: () => Promise<void>;
}

const Action: React.FC<IActionProps> = ({
  handleExcluir,
  handleAdicionarFilho,
  handleShowLog,
}) => {
  return (
    <ActionBox>
      <ActionItem onClick={handleAdicionarFilho}>
        <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
          <IoMdAddCircleOutline style={{ marginRight: 5 }} />
          Adicionar Filho
        </span>
      </ActionItem>
      <ActionItem onClick={handleExcluir}>
        <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
          <AiOutlineDelete style={{ marginRight: 5 }} />
          Excluir
        </span>
      </ActionItem>
      <ActionItem onClick={handleShowLog}>
        <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
          <CgPlayListSearch style={{ marginRight: 5 }} />
          Logs
        </span>
      </ActionItem>
    </ActionBox>
  );
};

interface IRenderTreeProps {
  item: IListaItem;
  handleClickTitle?: (id?: string) => void;
}

const RenderTreeItem: React.FC<IRenderTreeProps> = ({ item }) => {
  const { idShowItem, setIdShowItem, excluirItem, setIdItemPai } =
    UseTreeContext();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <div style={{ fontWeight: idShowItem === item.id ? 'bold' : 'normal' }}>
      <TreeView
        title={`${item.codigoConta} - ${item.descricao}`}
        open={item.isOpen}
        actions={
          <Action
            handleExcluir={async () => {
              if (state && state.dadosRecuperados) {
                delete (state as any).dadosRecuperados;
              }

              await excluirItem({
                id: item.id,
                hasFilhos: !!item.filhos?.length,
              });
            }}
            handleAdicionarFilho={() => {
              setIdShowItem({ id: '' });
              setIdItemPai(item.id);
            }}
            handleShowLog={async () => {
              try {
                abrirJanelaLog(async (params) => {
                  const response = await ContaContabilLogComunicador.show({
                    params,
                    id: item.id,
                  });

                  return response;
                });
              } catch (error) {
                TratarErros(error);
              }
            }}
          />
        }
        onClickTitle={() => {
          if ((location.state as any)?.dadosRecuperados) {
            delete (location.state as any).dadosRecuperados;
          }

          setIdShowItem({ id: item.id });
          setIdItemPai('');
        }}
      >
        {item.filhos && item.filhos.length !== 0 && (
          <>
            {item.filhos.map((registro) => (
              <RenderTreeItem item={registro} key={registro.id} />
            ))}
          </>
        )}
      </TreeView>
    </div>
  );
};

const ContaContabilLista: React.FC = () => {
  const { buscarDados, listaDados } = UseTreeContext();

  return (
    <ContaContabilHook>
      <ListaProvider>
        <Container>
          <div className="principal">
            <Divisor className="tree-container">
              <div>
                {listaDados.length ? (
                  listaDados.map((item) => (
                    <RenderTreeItem item={item} key={item.id} />
                  ))
                ) : (
                  <h6 style={{ textAlign: 'center', marginTop: '18px' }}>
                    Nenhuma Conta Contábil encontrada
                  </h6>
                )}
              </div>
            </Divisor>

            <div style={{ width: '100%', marginLeft: 'auto' }}>
              <ContaContabilDetalheHook>
                <ContaContabilDetalhe refreshLista={buscarDados} />
              </ContaContabilDetalheHook>
            </div>
          </div>
        </Container>

        <PesquisaAvancada />
      </ListaProvider>
    </ContaContabilHook>
  );
};

export default ContaContabilLista;
