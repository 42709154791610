const RetiradaConsignadoPorClienteComFinalidadeUsoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaPedidoVendaItem.pedidoVendaDataHoraEmissao',
  },

  {
    titulo: 'Número do Orçamento',
    valor: 'listaPedidoVendaItem.pedidoVendaNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaPedidoVendaItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaPedidoVendaItem.produtoDescricao',
  },
  {
    titulo: 'Informações Adicionais do Produto',
    valor: 'listaPedidoVendaItem.informacoesAdicionaisProduto',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaPedidoVendaItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaPedidoVendaItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaPedidoVendaItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaPedidoVendaItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaPedidoVendaItem.valorTotal',
  },
  {
    titulo: 'Finalidade de Uso',
    valor: 'listaPedidoVendaItem.finalidadeUsoCliente',
  },
];

export default RetiradaConsignadoPorClienteComFinalidadeUsoPlanilha;
