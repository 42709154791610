import { IMovimentoPortadorLista } from '@elogestor/util';
import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IContaListaMovimentoPortadorContext {
  validador: boolean;

  listaMovimentoPortador: IMovimentoPortadorLista[];
  setListaMovimentoPortador(listaValor: IMovimentoPortadorLista[]): void;
}

const ContaListaMovimentoPortadorContext =
  createContext<IContaListaMovimentoPortadorContext>(
    {} as IContaListaMovimentoPortadorContext
  );

const ContaListaMovimentoPortadorHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaMovimentoPortador, setListaMovimentoPortador] = useState<
    IMovimentoPortadorLista[]
  >([]);

  return (
    <ContaListaMovimentoPortadorContext.Provider
      value={{
        listaMovimentoPortador,
        setListaMovimentoPortador,

        validador: true,
      }}
    >
      {children}
    </ContaListaMovimentoPortadorContext.Provider>
  );
};

function UseMovimentoPortador(): Omit<
  IContaListaMovimentoPortadorContext,
  'validador'
> {
  const context = useContext(ContaListaMovimentoPortadorContext);

  if (!context.validador) {
    throw new Error(
      'UseContaParcelaBoleto deve ser usado com um ContaListaMovimentoPortadorHook'
    );
  }

  return context;
}

export { ContaListaMovimentoPortadorHook, UseMovimentoPortador };
