import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/engenharia-obter-lista-referencia';

interface IProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicadorShow {
  id: string;
}

class ProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicador {
  public async show(
    params: IProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}
export default new ProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicador();
