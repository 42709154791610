import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/codigo-rastreabilidade-auto-complete';

interface ICodigoRastreabilidadeAutoCompleteComunicadorIndex {
  params: any;
}

class CodigoRastreabilidadeAutoCompleteComunicador {
  public async index(
    params: ICodigoRastreabilidadeAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoRastreabilidadeAutoCompleteComunicador();
