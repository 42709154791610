import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/padronizacao-descricao/padronizacao-cadastrada';

interface IPadronizacaoDescricaoAtributoCadastradoComunicadorShow {
  id: string;
}

class PadronizacaoDescricaoAtributoCadastradoComunicador {
  public async show(
    params: IPadronizacaoDescricaoAtributoCadastradoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PadronizacaoDescricaoAtributoCadastradoComunicador();
