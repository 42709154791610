import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UseTableDraggable } from '../../TableDraggable/HooksTable/TableDraggableContext';
import Switch from '../../Switch';
import BtnCloseButton from '../../Buttons/BtnCloseButton';

interface IOverlayConfiguracoes {
  visivel: boolean;
  fechar(): void;
  quantidadeColunaIncrementar?: number;
}

const OverlayConfiguracoes: React.FC<IOverlayConfiguracoes> = ({
  visivel,
  fechar,
  quantidadeColunaIncrementar,
}) => {
  const { ths, alterarVisibilidade, alterarLargura } = UseTableDraggable();

  if (!visivel) return <></>;

  return (
    <Modal show={visivel} onHide={fechar} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Configuração da Listagem</Modal.Title>
        <BtnCloseButton type="button" onClick={fechar}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover responsive="md">
          <thead>
            <tr>
              <th>Campo</th>
              <th>Visível</th>
              <th>Tamanho</th>
            </tr>
          </thead>

          <tbody>
            {ths &&
              ths
                .filter((th) => th.arrastavel)
                .map((th, index) => {
                  if (quantidadeColunaIncrementar) {
                    index += quantidadeColunaIncrementar;
                  }

                  return (
                    <tr key={v4()}>
                      <td align="justify">{th.titulo}</td>
                      <td>
                        <Switch inativo="Não" ativo="Sim" largura={65}>
                          <input
                            type="checkbox"
                            checked={th.visivel}
                            onChange={() => {
                              alterarVisibilidade(index, !th.visivel);
                            }}
                            disabled={!th.permitirEsconder}
                          />
                        </Switch>
                      </td>

                      <td>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            style={{
                              width: 100,
                              height: 30,
                              marginRight: 5,
                            }}
                            type="text"
                            value={th.tamanho || ''}
                            onChange={(event) => {
                              const valor = parseInt(
                                event.currentTarget.value,
                                10
                              );
                              alterarLargura(index, valor ?? null);
                            }}
                          />
                          <Switch inativo="Man." ativo="Auto" largura={70}>
                            <input
                              type="checkbox"
                              checked={th.tamanho === null}
                              onChange={(event) => {
                                alterarLargura(
                                  index,
                                  event.currentTarget.checked ? null : 300
                                );
                              }}
                            />
                          </Switch>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default OverlayConfiguracoes;
