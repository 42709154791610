/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import { Item } from '../../AutoCompleteBase/styles';
import NcmAutoCompleteComunicador from '../../../../../Comunicador/Fiscal/Ncm/AutoComplete/NcmAutoComplete/NcmAutoCompleteComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface INcm {
  id: string;
  codigo: string;
  descricao: string;
  associacao: string[];
}

export interface IOnChangeItemAtualAutoCompleteNcmEvent {
  itemAtual: INcm | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteNcmEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteNcmEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteNcmProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  utilizavelProduto?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteNcmEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteNcmEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteNcmEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteNcmEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteNcmRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteNcm: React.ForwardRefRenderFunction<
  IInputAutoCompleteNcmRef,
  IInputAutoCompleteNcmProps
> = (
  {
    name,
    nomeObjeto,
    label,
    utilizavelProduto,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    onBlurInput,
    onChangeItemAtualAposCarregarSemClear,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const {
    autoCompleteRef,

    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<INcm | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);
  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset }) => {
              setPesquisando(true);

              try {
                const response = await NcmAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite: 5,
                    utilizavelProduto,
                    texto: valor,
                    ativo,
                    listaIdParaRemover,
                  },
                });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: INcm) => {
              return item.id;
            }}
            obterLabel={(item: INcm) => {
              if (!item.codigo && !item.descricao) return '';

              return `${item.codigo} - ${item.descricao}`;
            }}
            personalizarItem={({ selecionado, navegacao, item }) => {
              return (
                <Item $selecionado={selecionado} $navegacao={navegacao}>
                  <strong>{`${item.codigo} - ${item.descricao}`}</strong>
                  <br />

                  <div>
                    {item.associacao
                      ? item.associacao.map((valor: any) => {
                          return (
                            <div key={valor} style={{ fontSize: 'small' }}>
                              {valor}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                </Item>
              );
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteNcm);
