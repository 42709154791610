import { IAnaliseCustoVendaLista } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/custo/analise-custo';

interface IAnaliseCustoComunicadorIndex {
  listaItemVenda: IAnaliseCustoVendaLista[];
}

class AnaliseCustoComunicador {
  public async index(params: IAnaliseCustoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new AnaliseCustoComunicador();
