import { BooleanEnum, FormatarEnum } from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../Componentes/Select';
import { Modal } from './styles';
import Divisor from '../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import InputEmailTag from '../../../../Componentes/Inputs/TagsInput/InputEmailTag';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const [, setListaIdEmail] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaEmail = parametros.pesquisaAvancada?.listaEmail;

      if (listaEmail.listaValor?.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'E-mails',
          valor: listaEmail.listaValor.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.email}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeListaItemAtualEmail = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaEmail = listaItemAtual?.map((value) => value.email) || [];
      setListaIdEmail(listaEmail);
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={12} md={12}>
                <InputEmailTag
                  name="listaEmail"
                  label="E-mails"
                  onChangeListaItemAtual={({ listaItemAtual }) => {
                    handleChangeListaItemAtualEmail(listaItemAtual);
                  }}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
