/* eslint-disable jsx-a11y/control-has-associated-label */
import { IPedidoCompraItemLista, SituacaoItemEnum } from '@elogestor/util';
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import Detalhe from '../Detalhe';
import { UltimaTr } from './styles';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TrDraggable from '../../../../../../../../Componentes/TrDraggable';
import { UseListaPedidoCompraDraggable } from '../Hook/ListaPedidoCompraDraggableHook';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import PaginacaoPedidoCompra from './PaginacaoPedidoCompra';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheItemProps {
  name: string;
}

const PedidoCompraItemLista: React.FC<IListaDetalheItemProps> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { isJanelaAutoComplete } = UseAutoComplete();

  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasPedidoCompra: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);
  const { telaGrande } = UseReactSizeMeBodyHook();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IPedidoCompraItemLista[]>([]);

  const {
    listaValor,
    handleAtualizarOrdem,
    handleMover,
    setListaValor,
    limite,
    paginaAtual,
  } = UseListaPedidoCompraDraggable();

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPedidoCompraItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataNomeObjeto() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [handleSalvarContinuar, isJanelaAutoComplete, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const dataPrincipal = formPrincipal.formRef.current?.getData();
      const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
      if (erro) return;

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
        formPrincipal.handleCarregarDados();
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
      formPrincipal.handleCarregarDados();
    }
  }, [
    abrirRecuperarFormulario,
    formPrincipal,
    formRefDetalhe,
    setIdDetalheRegistro,
  ]);

  const handleLimparFormModal = useCallback(async (): Promise<void> => {
    setIdDetalheRegistro('');

    await formRefDetalhe.current?.setSemExecutarEvento({
      situacao: SituacaoItemEnum.pendente,
    });
  }, [formRefDetalhe, setIdDetalheRegistro]);

  const listaValorPaginaAtual = useMemo(() => {
    const offset = (paginaAtual - 1) * limite;
    const valores = listaValor.slice(offset, offset + limite);
    return valores;
  }, [limite, listaValor, paginaAtual]);

  return (
    <>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            style={{ maxWidth: telaGrande ? 250 : '' }}
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '5%' }}>
                <span>Ordem</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Código</span>
              </th>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>Descrição Produto/Serviço</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>UN</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Qtde.</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Val. Unitário</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Sub. Total</span>
              </th>
              <th className="lista-valor" style={{ width: '10%' }}>
                <span>Val. Total</span>
              </th>
              <th className="lista-texto" style={{ width: '5%' }}>
                <span>CFOP</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValorPaginaAtual &&
              listaValorPaginaAtual.map((item, index) => {
                return (
                  <TrDraggable
                    idDrag={v4()}
                    key={v4()}
                    index={index}
                    onMover={handleMover}
                    onClick={async () => {
                      if (formPrincipal.loading || loading) return;
                      handleEditar(item.id);
                    }}
                    onDragEnd={() => {
                      if (formPrincipal.loading || loading) return;
                      handleAtualizarOrdem();
                    }}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-valor">
                      <div>{item.ordem}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.produto.codigo}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.produto.descricao}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.unidadeMedida.sigla}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidade).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorUnitario).FormatarParaPtBr({
                          minimoCasasDecimais: 10,
                          maximoCasasDecimais: 10,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorTotalProduto).FormatarParaPtBr()}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorTotalBruto).FormatarParaPtBr()}
                      </div>
                    </td>
                    <td className="lista-texto">
                      <div>{item?.operacaoFiscal?.cfop || ''}</div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(item.id);
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </TrDraggable>
                );
              })}
          </tbody>

          <tfoot>
            <UltimaTr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ fontSize: '13px' }}>V. Sub. Tot.</div>
                  <div style={{ alignSelf: 'flex-end', fontSize: '13px' }}>
                    {Number(
                      formPrincipal.formRef.current?.getFieldValue(
                        'valorTotalProdutos'
                      )
                    ).FormatarParaPtBr()}
                  </div>
                </div>
              </th>
              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ fontSize: '13px' }}>Valor Total</div>
                  <div style={{ alignSelf: 'flex-end', fontSize: '13px' }}>
                    {Number(
                      formPrincipal.formRef.current?.getFieldValue(
                        'valorTotalBruto'
                      )
                    ).FormatarParaPtBr()}
                  </div>
                </div>
              </th>
              <th />
              <th />
            </UltimaTr>
          </tfoot>
        </Table>
      </Tabela>
      <PaginacaoPedidoCompra />

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </>
  );
};

export default PedidoCompraItemLista;
