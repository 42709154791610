import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IPesquisaPessoaNomeRazaoSocialContext {
  paginaAtual: number;
  setPaginaAtual(value: number): void;
  totalPaginas: number;
  setTotalPaginas(value: number): void;
  limite: number;
  setLimite(value: number): void;
}

const PesquisaPessoaNomeRazaoSocialContext =
  createContext<IPesquisaPessoaNomeRazaoSocialContext>(
    {} as IPesquisaPessoaNomeRazaoSocialContext
  );

const PesquisaPessoaNomeRazaoSocialProvider: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  // const handleAbrirJanela = useCallback((novaFuncao) => {
  //   if (!novaFuncao) {
  //     throw new AppErro({
  //       mensagem: `Uma função valida é necessaria! Função recebida: ${novaFuncao}`,
  //     });
  //   }

  //   setPaginaAtual(1);
  //   setBuscarDados(() => novaFuncao);
  //   setAberta(true);
  // }, []);

  // const handleFecharJanela = useCallback(() => {
  //   setBuscarDados(null);
  //   setAberta(false);
  //   setListaPessoa([]);
  // }, []);

  return (
    <PesquisaPessoaNomeRazaoSocialContext.Provider
      value={{
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
      }}
    >
      {children}
    </PesquisaPessoaNomeRazaoSocialContext.Provider>
  );
};

function UsePesquisaPessoaNomeRazaoSocial(): Omit<
  IPesquisaPessoaNomeRazaoSocialContext,
  'validador'
> {
  const context = useContext(PesquisaPessoaNomeRazaoSocialContext);

  if (!context) {
    throw new Error(
      'UsePesquisaPessoaNomeRazaoSocial deve ser usado com um PesquisaPessoaNomeRazaoSocialProvider'
    );
  }

  return context;
}

export {
  PesquisaPessoaNomeRazaoSocialProvider,
  UsePesquisaPessoaNomeRazaoSocial,
};
