import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa/index.mjs';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { v4 } from 'uuid';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { UseForm } from '../Hooks/FormContext';
import { UseSubHeader } from '../Hooks/SubHeaderContext';
import { IRotasContext, UseRota } from '../Hooks/RotasContext';
import { Container } from './styles';
import TextoLoading from '../../TextoLoading';
import SplitSalvar from '../../SubHeaderGeral/SplitSalvar';
import Dropdown from '../../SubHeaderGeral/Dropdown';
import BtnVoltar from '../../SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../SubHeaderGeral/ContainerButtons';
import IPermissao from '../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';
import BtnDuplicar from '../../SubHeaderGeral/BtnDuplicar';
import BtnDeletar from '../../SubHeaderGeral/BtnDeletar';
import BtnDuplicarSalvar from '../../SubHeaderGeral/BtnDuplicarSalvar';
import UseFuncoesPadrao from '../Hooks/UseFuncoesPadrao';
import { UseButtonsSubHeader } from '../Hooks/UseButtonsSubHeader';

interface ISubHeader {
  titulo: string;
  permissao?: IPermissao;
  botoes?: JSX.Element;
  rotasAtualizadas?: IRotasContext;

  isAberturaFechamentoCaixa?: boolean;
  isTerminalCaixa?: boolean;
  isOrcamento?: boolean;
  isPedidoVenda?: boolean;
  isNotaFiscalSaida?: boolean;
  isNotaFiscalEntrada?: boolean;
  isConhecimentoTransporte?: boolean;
  isConhecimentoTransporteTerceiro?: boolean;
  isOrdemServico?: boolean;
}

const SubHeader: React.FC<ISubHeader> = ({
  titulo,
  permissao,
  botoes,
  rotasAtualizadas,

  isAberturaFechamentoCaixa = false,
  isTerminalCaixa = false,
  isOrcamento = false,
  isPedidoVenda = false,
  isNotaFiscalSaida = false,
  isNotaFiscalEntrada = false,
  isConhecimentoTransporte = false,
  isConhecimentoTransporteTerceiro = false,
  isOrdemServico = false,
}) => {
  const { telaPequena, telaGrande } = UseReactSizeMeBodyHook();
  const navigate = useNavigate();
  const { loading, getIdDetalheRegistro } = UseForm();
  const {
    disableExcluir,
    disableDuplicar,
    disableDuplicarSalvar,
    disableSalvar,
  } = UseButtonsSubHeader();

  const {
    subHeaderButtonsVisivel: {
      ExcluirVisivel,
      DuplicarVisivel,
      DuplicarSalvarVisivel,
      SalvarContinuarVisivel,
      SalvareNovoVisivel,
      SalvareFecharVisivel,
      VoltarVisivel,
      SalvarVisivel,
    },
    subHeaderButtonsHabilitado: {
      ExcluirHabilitado,
      DuplicarHabilitado,
      DuplicarSalvarHabilitado,
      SalvarContinuarHabilitado,
      SalvareNovoHabilitado,
      SalvareFecharHabilitado,
      SalvareHabilitado,
    },
  } = UseSubHeader();

  const rotas = UseRota();
  useEffect(() => {
    if (rotasAtualizadas) {
      rotas.backend = rotasAtualizadas.backend;
      rotas.detalhes = rotasAtualizadas.detalhes;
      rotas.listagem = rotasAtualizadas.listagem;
    }
  }, [rotas, rotasAtualizadas]);

  const {
    handleSalvar,
    handleSalvarContinuar,
    handleSalvarNovo,
    handleDuplicar,
    handleDuplicarSalvar,
    handleExcluir,
  } = UseFuncoesPadrao(permissao);

  return (
    <Container $telaPequena={telaPequena}>
      <h1>{titulo}</h1>
      <ContainerButtons>
        {VoltarVisivel && (
          <BtnVoltar
            onClick={() => {
              navigate(rotas.listagem);
            }}
            disabled={loading}
          >
            <TextoLoading loading={loading}>
              <IoMdReturnLeft />
              {telaGrande && <span style={{ marginLeft: 10 }}>Voltar</span>}
            </TextoLoading>
          </BtnVoltar>
        )}

        {ExcluirVisivel && getIdDetalheRegistro() && (
          <BtnDeletar
            onClick={() =>
              handleExcluir({
                isAberturaFechamentoCaixa,
                isTerminalCaixa,
                isOrcamento,
                isPedidoVenda,
                isNotaFiscalSaida,
                isNotaFiscalEntrada,
                isConhecimentoTransporte,
                isConhecimentoTransporteTerceiro,
                isOrdemServico,
              })
            }
            disabled={
              !permissao?.exclui ||
              !ExcluirHabilitado ||
              loading ||
              disableExcluir
            }
          >
            <TextoLoading loading={loading}>
              <RiDeleteBin6Line />
              {telaGrande && <span style={{ marginLeft: 10 }}>Excluir</span>}
            </TextoLoading>
          </BtnDeletar>
        )}

        {DuplicarVisivel && (
          <BtnDuplicar
            onClick={handleDuplicar}
            disabled={
              !permissao?.inclui ||
              !DuplicarHabilitado ||
              loading ||
              disableDuplicar
            }
          >
            <TextoLoading loading={loading}>
              <FaRegCopy />
              {telaGrande && <span style={{ marginLeft: 10 }}>Duplicar</span>}
            </TextoLoading>
          </BtnDuplicar>
        )}

        {DuplicarSalvarVisivel && (
          <BtnDuplicarSalvar
            onClick={handleDuplicarSalvar}
            disabled={
              !permissao?.inclui ||
              !DuplicarSalvarHabilitado ||
              loading ||
              disableDuplicarSalvar
            }
          >
            <TextoLoading loading={loading}>
              <FaRegCopy />
              {telaGrande && (
                <span style={{ marginLeft: 10 }}>Duplicar e Salvar</span>
              )}
            </TextoLoading>
          </BtnDuplicarSalvar>
        )}

        {botoes && botoes}

        {SalvarVisivel && (
          <SplitSalvar
            id={v4()}
            disabled={(() => {
              const id = getIdDetalheRegistro();
              return (
                (id && !permissao?.altera) ||
                (!id && !permissao?.inclui) ||
                disableSalvar ||
                !SalvareHabilitado
              );
            })()}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextoLoading loading={loading}>
                  <BiSave />
                  {telaGrande && <span style={{ marginLeft: 10 }}>Salvar</span>}
                </TextoLoading>
              </div>
            }
            onClick={handleSalvar}
          >
            {SalvarContinuarVisivel && (
              <Dropdown
                onClick={() => {
                  handleSalvarContinuar();
                }}
                disabled={!SalvarContinuarHabilitado || loading}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VscSaveAs />
                  <span style={{ marginLeft: 10 }}>Salvar e Continuar</span>
                </div>
              </Dropdown>
            )}

            {SalvareNovoVisivel && (
              <Dropdown
                onClick={handleSalvarNovo}
                disabled={
                  !permissao?.inclui || !SalvareNovoHabilitado || loading
                }
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VscSaveAll />
                  <span style={{ marginLeft: 10 }}>Salvar e Novo</span>
                </div>
              </Dropdown>
            )}

            {SalvareFecharVisivel && (
              <Dropdown
                disabled={!SalvareFecharHabilitado || loading}
                onClick={handleSalvar}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <BiSave />
                  <span style={{ marginLeft: 10 }}>Salvar e Fechar</span>
                </div>
              </Dropdown>
            )}
          </SplitSalvar>
        )}
      </ContainerButtons>
    </Container>
  );
};

export default SubHeader;
