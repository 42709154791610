import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/relatorios/producao/ordem-producao/ordem-producao-resumo-por-produto-produzido-com-local-estoque';

interface IOrdemProducaoResumoProdutoProduzidosLocalEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrdemProducaoResumoProdutoProduzidosLocalEstoqueRelatorioComunicador {
  public async index(
    params: IOrdemProducaoResumoProdutoProduzidosLocalEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrdemProducaoResumoProdutoProduzidosLocalEstoqueRelatorioComunicador();
