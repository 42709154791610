"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var UnidadeMedidaCargaEnum;
(function (UnidadeMedidaCargaEnum) {
    UnidadeMedidaCargaEnum["M3"] = "0 - M3";
    UnidadeMedidaCargaEnum["Kg"] = "1 - Kg";
    UnidadeMedidaCargaEnum["Ton"] = "2 - Ton";
    UnidadeMedidaCargaEnum["Unidade"] = "3 - Unidade";
    UnidadeMedidaCargaEnum["Litros"] = "4 - Litros";
    UnidadeMedidaCargaEnum["Mmbtu"] = "5 - Mmbtu";
})(UnidadeMedidaCargaEnum || (UnidadeMedidaCargaEnum = {}));
exports.default = UnidadeMedidaCargaEnum;
