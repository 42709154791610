import {
  FormatarDataHoraParaPtBr,
  IAdiantamentosFiltroRelatorioBackend,
  IAdiantamentosFiltroRelatorioFrontend,
  IAdiantamentosPorEmissaoCabecalhoRelatorio,
  IAdiantamentosPorEmissaoRelatorio,
} from '@elogestor/util';
import React from 'react';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import AdiantamentosPorEmissaoPDF from './AdiantamentosPorEmissaoPDF';
import AdiantamentosPorEmissaoPlanilha from './AdiantamentosPorEmissaoPlanilha';
import AdiantamentosPorEmissaoRelatorioComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/Adiantamento/AdiantamentosPorEmissaoRelatorioComunicador/AdiantamentosPorEmissaoRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';

interface IRelatorios {
  handleExportCSV(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const AdiantamentosPorEmissaoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IAdiantamentosFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IAdiantamentosPorEmissaoRelatorio | null> => {
    const filtroBack: IAdiantamentosFiltroRelatorioBackend = {
      dataHoraInicial: filtros.dataHoraInicial,
      dataHoraFinal: filtros.dataHoraFinal,
    };

    if (filtros?.listaSituacaoAdiantamento) {
      filtroBack.listaSituacaoAdiantamento =
        filtros.listaSituacaoAdiantamento.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaTipoConta) {
      filtroBack.listaTipoConta = filtros.listaTipoConta.listaValor.map(
        (value) => value.descricao
      );
    }

    if (filtros?.listaClienteFornecedores) {
      filtroBack.listaIdClienteFornecedores =
        filtros.listaClienteFornecedores.listaValor.map((value) => value.id);
    }

    if (filtros?.listaTipoAdiantamento) {
      filtroBack.listaTipoAdiantamento =
        filtros.listaTipoAdiantamento.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaClienteFornecedores) {
      filtroBack.listaIdClienteFornecedores =
        filtros.listaClienteFornecedores.listaValor.map(
          (value: any) => value.id
        );
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    const response = await AdiantamentosPorEmissaoRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IAdiantamentosPorEmissaoRelatorio;

    return retorno;
  };
  function FormatarCabecalho(
    filtros: IAdiantamentosFiltroRelatorioFrontend,
    cabecalho: IAdiantamentosPorEmissaoCabecalhoRelatorio
  ): IAdiantamentosPorEmissaoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraInicial || filtros.dataHoraFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataHoraEmissaoInicial = '--/--/--';
      cabecalho.filtroDataHoraEmissaoFinal = '--/--/--';

      if (filtros.dataHoraInicial) {
        cabecalho.filtroDataHoraEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraInicial
        );
      }
      if (filtros.dataHoraFinal) {
        cabecalho.filtroDataHoraEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraFinal
        );
      }
    }

    if (
      filtros.listaTipoConta &&
      filtros.listaTipoConta.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTipoConta = filtros.listaTipoConta.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaSituacaoAdiantamento &&
      filtros.listaSituacaoAdiantamento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoAdiantamento =
        filtros.listaSituacaoAdiantamento.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (
      filtros.listaTipoConta &&
      filtros.listaTipoConta.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoAdiantamento = filtros.listaTipoConta.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaClienteFornecedores &&
      filtros.listaClienteFornecedores.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.nomeCliente = filtros.listaClienteFornecedores.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: AdiantamentosPorEmissaoPlanilha,
      nomeArquivo: 'AdiantamentosPorEmissao',
      valores: dados.listaDados,
      unwind: ['listaConta'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: AdiantamentosPorEmissaoPlanilha,
      nomeArquivo: 'AdiantamentosPorEmissao',
      valores: dados.listaDados as any,
      unwind: ['listaConta'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <AdiantamentosPorEmissaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IAdiantamentosFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <AdiantamentosPorEmissaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default AdiantamentosPorEmissaoRelatorio();
