"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoInscricaoBoletoBanrisulRemessaEnum;
(function (TipoInscricaoBoletoBanrisulRemessaEnum) {
    TipoInscricaoBoletoBanrisulRemessaEnum["cpf"] = "01";
    TipoInscricaoBoletoBanrisulRemessaEnum["cnpj"] = "02";
    TipoInscricaoBoletoBanrisulRemessaEnum["outros"] = "99";
})(TipoInscricaoBoletoBanrisulRemessaEnum || (TipoInscricaoBoletoBanrisulRemessaEnum = {}));
exports.default = TipoInscricaoBoletoBanrisulRemessaEnum;
