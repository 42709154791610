import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ContaListaCategoriaHook } from '../../Conta/Detalhe/Hooks/ContaListaCategoriaHook';
import { LiquidacaoContasEmLoteListaHook } from './LiquidacaoContasEmLoteListaHook';

const LiquidacaoContasEmLoteHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <LiquidacaoContasEmLoteListaHook>
      <ContaListaCategoriaHook>{children}</ContaListaCategoriaHook>
    </LiquidacaoContasEmLoteListaHook>
  );
};

export default LiquidacaoContasEmLoteHook;
