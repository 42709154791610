import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/sped-genero-item-servico-auto-complete';

interface ISpedGeneroItemServicoAutoCompleteComunicadorIndex {
  params: any;
}

class SpedGeneroItemServicoAutoCompleteComunicador {
  public async index(
    params: ISpedGeneroItemServicoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SpedGeneroItemServicoAutoCompleteComunicador();
