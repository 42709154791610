"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoIntermedioImportacaoEnum;
(function (TipoIntermedioImportacaoEnum) {
    TipoIntermedioImportacaoEnum["importacaoPorContaPropria"] = "1 - Importa\u00E7\u00E3o por Conta Pr\u00F3pria";
    TipoIntermedioImportacaoEnum["importacaoPorContaOrdem"] = "2 - Importa\u00E7\u00E3o por Conta e Ordem";
    TipoIntermedioImportacaoEnum["importacaoPorEncomenda"] = "3 - Importa\u00E7\u00E3o por Encomenda";
})(TipoIntermedioImportacaoEnum || (TipoIntermedioImportacaoEnum = {}));
exports.default = TipoIntermedioImportacaoEnum;
