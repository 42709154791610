import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/obter-nota-fiscal-saida';

interface IFrenteCaixaPdvObterNotaFiscalSaidaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFrenteCaixaPdvObterNotaFiscalSaidaComunicadorShow {
  id: string;
}

class FrenteCaixaPdvObterNotaFiscalSaidaComunicador {
  public async index(
    params: IFrenteCaixaPdvObterNotaFiscalSaidaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IFrenteCaixaPdvObterNotaFiscalSaidaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterNotaFiscalSaidaComunicador();
