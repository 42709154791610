import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-nota-fiscal-saida/curva-abc-nota-fiscal-saida-sub-grupo-grupo-com-produto-por-valor';

interface ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicador();
