import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/notas-servicos-emitidas/upload-xml';

interface IImportacaoXmlServicosEmitidosUploadXmlComunicadorUpdate {
  params: any;
}

class ImportacaoXmlServicosEmitidosUploadXmlComunicador {
  public async update(
    params: IImportacaoXmlServicosEmitidosUploadXmlComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlServicosEmitidosUploadXmlComunicador();
