import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/unidade-medida';

interface IUnidadeMedidaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IUnidadeMedidaComunicadorShow {
  id: string;
}

interface IUnidadeMedidaComunicadorStore {
  params: any;
}

interface IUnidadeMedidaComunicadorUpdate {
  id: string;
  params: any;
}

interface IUnidadeMedidaComunicadorDeletar {
  id: string;
}

class UnidadeMedidaComunicador {
  public async index(params: IUnidadeMedidaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IUnidadeMedidaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IUnidadeMedidaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IUnidadeMedidaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IUnidadeMedidaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new UnidadeMedidaComunicador();
