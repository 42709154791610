import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa/endereco';
const rotaPessoa = 'comercial/pessoas/pessoa';

interface IPessoaEnderecoComunidadorIndex {
  idDetalheRegistro: string;
}

interface IPessoaEnderecoComunidadorShow {
  id: string;
}

interface IPessoaEnderecoComunidadorStore {
  idDetalheRegistro: string;
  params: any;
}

interface IPessoaEnderecoComunidadorUpdate {
  id: string;
  params: any;
}

interface IPessoaEnderecoComunidadorDelete {
  id: string;
}

class PessoaEnderecoComunidador {
  public async index(params: IPessoaEnderecoComunidadorIndex): Promise<any> {
    const response = await api.get(
      `${rotaPessoa}/${params.idDetalheRegistro}/endereco`
    );

    return response.data;
  }

  public async show(params: IPessoaEnderecoComunidadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPessoaEnderecoComunidadorStore): Promise<any> {
    const response = await api.post(
      `${rotaPessoa}/${params.idDetalheRegistro}/endereco`,
      params.params
    );

    return response.data;
  }

  public async update(params: IPessoaEnderecoComunidadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IPessoaEnderecoComunidadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaEnderecoComunidador();
