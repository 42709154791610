/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CompararStringsIguaisIgnoraAcentuacaoMinusculas } from '@elogestor/util';
import { ContainerListaItem, Item } from './styles';
import { DefaultInput, SpanErro } from '../../Styles';
import { Sleep } from '../../../Padrao/MenuPrincipal/Scripts';

interface IOnChange {
  valorAnterior: any;
  key: string;
}

interface IItem {
  chave: string | number;
  label: string;
  valor: any;
}

interface IFiltrarItens {
  valor: string;
  offset: number;
  limite: number;
}

export interface IPersonalizarItem {
  item: any;
  index: number;
  selecionado: boolean;
  navegacao: boolean;
}

export interface IOnChangeItemAtualEvent {
  itemAtual: any | null;
  input: HTMLInputElement | null;
  mudou: boolean;
}

export interface IOnChangeTextoEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputEvent {
  input: HTMLInputElement | Element | null;
}

interface IInputAutoCompleteBase {
  personalizarItem?: React.FC<IPersonalizarItem>;
  personalizarCarregando?: React.FC;
  filtrarItens({ valor }: IFiltrarItens): Promise<any[]>;
  obterChaveUnica(item: any): string | number;
  obterLabel(item: any): string;

  limite?: number;
  error?: string;

  onChangeItemAtual?: (
    evento: IOnChangeItemAtualEvent,
    valorAnterior: IOnChange
  ) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;
  onBlurInput?: (evento: IOnBlurInputEvent) => void;
  inputProps?: React.HtmlHTMLAttributes<HTMLInputElement>;

  setPesquisando?: (valor: boolean) => void;
  habilitaEnterBlur?: boolean;
  registroSeraCadastradoAutomaticamente?: boolean;
  obterNovoItem?: (valor: string) => any;
  tempoDebounce?: number;
}

export interface IInputAutoCompleteBaseRef {
  getItemAtual(): any;
  getInput(): HTMLInputElement | null;
  selecionarItem(item: any): void;
  selecionarItemSemEvento(item: any): void;
  focus(): void;
  className?: string;
}

export interface IInputAutoCompletePadraoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const AutoCompleteBase: React.ForwardRefRenderFunction<
  IInputAutoCompleteBaseRef,
  IInputAutoCompleteBase
> = (
  {
    personalizarItem: ItemPersonalizado,
    personalizarCarregando: CarregandoPersonalizado,
    filtrarItens,
    obterChaveUnica,
    obterLabel,
    limite = 20,
    error,
    onChangeItemAtual,
    onBlurInput,
    onChangeTexto,
    inputProps,
    setPesquisando,
    registroSeraCadastradoAutomaticamente = false,
    obterNovoItem,
    habilitaEnterBlur = true,
    tempoDebounce = 400,
  },
  ref
) => {
  const [loading, setLoading] = useState(false);

  const [listaItem, setListaItem] = useState<IItem[]>([]);
  const [indexItemNavegacao, setIndexItemNavegacao] = useState(-1);
  const itemAtualRef = useRef<IItem | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [carregandoMaisRegistros, setCarregandoMaisRegistros] = useState(false);
  const debounceMaisRegistrosRef = useRef<number | null>(null);

  const cliqueDentro = useRef(false);

  const listaItemContainerRef = useRef<HTMLUListElement>(null);
  const itemNavegacaoContainerRef = useRef<any>(null);

  const debounceChangeRef = useRef<number | null>(null);
  const requestIdRef = useRef(0);

  const formatarItem = useCallback(
    (valor: any) => {
      return {
        chave: obterChaveUnica(valor),
        label: obterLabel(valor),
        valor,
      };
    },
    [obterChaveUnica, obterLabel]
  );

  const formatarListaItem = useCallback(
    (lista: any[]) => {
      if (lista && lista.length > 0) {
        return lista.map(formatarItem);
      }

      return lista;
    },
    [formatarItem]
  );

  const carregarMaisItens = useCallback(
    async (offset: number) => {
      if (debounceMaisRegistrosRef.current) {
        return;
      }

      setCarregandoMaisRegistros(true);
      debounceMaisRegistrosRef.current = window.setTimeout(
        async () => {
          const listaValor = await filtrarItens({
            valor: inputRef.current?.value || '',
            offset,
            limite,
          });

          const listaValorFormatado = formatarListaItem(listaValor);
          setListaItem((state) => [...state, ...listaValorFormatado]);

          if (debounceMaisRegistrosRef.current) {
            clearTimeout(debounceMaisRegistrosRef.current);
            debounceMaisRegistrosRef.current = null;
          }
          setCarregandoMaisRegistros(false);
        },
        500 * (offset / 20)
      );
    },
    [filtrarItens, formatarListaItem, inputRef, limite]
  );

  const selecionarItem = useCallback(
    (item: IItem | null, key: string) => {
      if (registroSeraCadastradoAutomaticamente && item && !item.chave) {
        setErroInterno(
          'Registro não encontrado! O Cadastro será realizado automaticamente!'
        );
      } else {
        setErroInterno('');
      }

      const valorAnterior = itemAtualRef.current;
      itemAtualRef.current = item;

      if (inputRef.current && item) {
        inputRef.current.value = item?.label || '';
      }

      if (onChangeItemAtual) {
        onChangeItemAtual(
          {
            input: inputRef.current,
            itemAtual: item?.valor ?? null,
            mudou: true,
          },
          {
            valorAnterior: valorAnterior?.valor,
            key,
          }
        );
      }
    },
    [onChangeItemAtual, registroSeraCadastradoAutomaticamente]
  );

  const selecionarItemSemEvento = useCallback(
    (item: IItem | null) => {
      if (registroSeraCadastradoAutomaticamente && item && !item.chave) {
        setErroInterno(
          'Registro não encontrado! O Cadastro será realizado automaticamente!'
        );
      } else {
        setErroInterno('');
      }

      itemAtualRef.current = item;

      if (inputRef.current && item) {
        inputRef.current.value = item?.label || '';
      }
    },
    [registroSeraCadastradoAutomaticamente]
  );

  const procurarItemPorLabel = useCallback(
    (valor: string) => {
      if (loading) return;

      const itemAchado = listaItem.find((item) => {
        return CompararStringsIguaisIgnoraAcentuacaoMinusculas(
          item.label,
          valor
        );
      });

      if (itemAchado) {
        selecionarItem(itemAchado, '');
      } else if (!registroSeraCadastradoAutomaticamente)
        setErroInterno('Registro não encontrado!');
      else {
        let novoItem: any;
        if (obterNovoItem) {
          novoItem = obterNovoItem(valor);
        }

        const itemCadastrar: IItem = {
          chave: '',
          label: valor,
          valor: novoItem,
        };

        selecionarItem(itemCadastrar, '');

        setErroInterno(
          'Registro não encontrado! O Cadastro será realizado automaticamente!'
        );
      }
    },
    [
      listaItem,
      loading,
      obterNovoItem,
      registroSeraCadastradoAutomaticamente,
      selecionarItem,
    ]
  );

  const alterarIndexNavegacao = useCallback(
    (valor: number) => {
      setIndexItemNavegacao((state) => {
        const soma = state + valor;
        const tamanhoLista = listaItem.length - 1;

        if (soma < 0) {
          return 0;
        }

        if (soma > tamanhoLista) {
          carregarMaisItens(tamanhoLista + 1);
          return tamanhoLista;
        }

        return soma;
      });
    },
    [carregarMaisItens, listaItem.length]
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setIsFocused(true);
      setIsOpen(true);
      setIndexItemNavegacao(0);

      const input = event.target;
      setErroInterno('');

      if (onChangeTexto) {
        onChangeTexto({
          input,
        });
      }

      if (itemAtualRef.current?.valor) {
        selecionarItem(null, '');
      }

      if (debounceChangeRef.current) {
        clearTimeout(debounceChangeRef.current);
      }

      debounceChangeRef.current = window.setTimeout(async () => {
        const currentRequestId = ++requestIdRef.current;

        const listaValor = await filtrarItens({
          valor: input.value,
          offset: 0,
          limite,
        });

        if (currentRequestId === requestIdRef.current) {
          const listaValorFormatado = formatarListaItem(listaValor);

          setListaItem(listaValorFormatado || []);
          setLoading(false);
          inputRef.current?.focus();
          setIsFocused(true);
          setIsOpen(true);
        }
      }, tempoDebounce);
    },

    [
      filtrarItens,
      formatarListaItem,
      limite,
      onChangeTexto,
      selecionarItem,
      tempoDebounce,
    ]
  );

  const mapeamentoDeNavegacao = useMemo<{ [key: string]: any }>(() => {
    async function selecionar(key: string): Promise<void> {
      if (loading) return;

      setIsFocused(false);
      setIsOpen(false);

      if (indexItemNavegacao >= 0 && listaItem[indexItemNavegacao]) {
        selecionarItem(listaItem[indexItemNavegacao], key);
        setListaItem([]);
        setIndexItemNavegacao(0);
      }
    }

    return {
      ArrowDown: () => {
        alterarIndexNavegacao(1);
      },
      ArrowUp: () => {
        alterarIndexNavegacao(-1);
      },

      PageDown: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(4);
      },
      PageUp: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(-4);
      },
      Tab: () => {
        selecionar('Tab');
      },
      Enter: async (event: any) => {
        event.preventDefault();

        if (listaItem.length === 0 && event.target.value) {
          const listaValor = await filtrarItens({
            valor: event.target.value,
            offset: 0,
            limite,
          });

          const listaValorFormatado = formatarListaItem(listaValor);

          if (
            indexItemNavegacao >= 0 &&
            listaValorFormatado[indexItemNavegacao]
          ) {
            selecionarItem(listaValorFormatado[indexItemNavegacao], 'Enter');

            await Sleep(1000);

            setListaItem([]);
            setIndexItemNavegacao(0);
            setIsFocused(false);
            setIsOpen(false);
            setLoading(false);
          }
        } else {
          selecionar('Enter');
        }

        if (habilitaEnterBlur) inputRef.current?.blur();
      },
    };
  }, [
    alterarIndexNavegacao,
    filtrarItens,
    formatarListaItem,
    habilitaEnterBlur,
    indexItemNavegacao,
    limite,
    listaItem,
    loading,
    selecionarItem,
  ]);

  const handleFocus = useCallback(async () => {
    setIsFocused(true);

    const listaValor = await filtrarItens({
      valor: inputRef.current?.value || '',
      offset: 0,
      limite,
    });

    const listaValorFormatado = formatarListaItem(listaValor);
    setListaItem(listaValorFormatado || []);
  }, [filtrarItens, formatarListaItem, inputRef, limite]);

  const handleBlur = useCallback(
    async (event?: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsOpen(false);

      if (onBlurInput) {
        if (event) {
          const input = event.target;

          onBlurInput({ input });
        }
      }

      setIsFilled(!!inputRef.current?.value);
      if (!itemAtualRef.current && inputRef.current?.value) {
        procurarItemPorLabel(inputRef.current.value);
      }
    },
    [onBlurInput, procurarItemPorLabel]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const chave = event.key;
      const funcao = mapeamentoDeNavegacao[chave];

      if (funcao) {
        funcao(event);
      }
    },
    [mapeamentoDeNavegacao]
  );

  const handleWheel = useCallback(
    (event: React.WheelEvent<HTMLUListElement>) => {
      if (event.deltaY >= 0) {
        alterarIndexNavegacao(1);
      } else {
        alterarIndexNavegacao(-1);
      }
    },
    [alterarIndexNavegacao]
  );

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  useEffect(() => {
    function handleValidarClick(): void {
      if (!cliqueDentro.current) {
        setIndexItemNavegacao(0);
        setIsFocused(false);
        setIsOpen(false);
      } else {
        cliqueDentro.current = false;
      }
    }

    document.addEventListener('click', handleValidarClick);

    return () => {
      document.removeEventListener('click', handleValidarClick);
    };
  }, []);

  useEffect(() => {
    listaItemContainerRef.current?.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    function ScrollSeNecessario(): void {
      if (!itemNavegacaoContainerRef.current || !listaItemContainerRef.current)
        return;

      listaItemContainerRef.current.scrollTo(
        0,
        itemNavegacaoContainerRef.current.offsetTop +
          itemNavegacaoContainerRef.current.offsetHeight -
          listaItemContainerRef.current.offsetHeight
      );
    }

    ScrollSeNecessario();
  }, [indexItemNavegacao]);

  useImperativeHandle(ref, () => ({
    getItemAtual: () => {
      return itemAtualRef.current?.valor ?? null;
    },

    getInput: () => {
      return inputRef.current;
    },

    selecionarItem: (item: any) => {
      if (item) {
        const itemFormatado = formatarItem(item);
        selecionarItem(itemFormatado, '');
      } else {
        selecionarItem(null, '');
        if (inputRef.current) inputRef.current.value = '';
      }
    },

    selecionarItemSemEvento: (item: any) => {
      if (item) {
        const itemFormatado = formatarItem(item);
        selecionarItemSemEvento(itemFormatado);
      } else {
        selecionarItemSemEvento(null);
        if (inputRef.current) inputRef.current.value = '';
      }
    },

    focus() {
      inputRef.current?.focus();
    },
  }));

  const handleScrolled = useCallback(() => {
    if (!listaItemContainerRef.current) return;

    if (
      listaItemContainerRef.current.offsetHeight +
        listaItemContainerRef.current.scrollTop >=
      listaItemContainerRef.current.scrollHeight
    ) {
      carregarMaisItens(listaItem.length);
    }
  }, [carregarMaisItens, listaItem.length]);

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      onMouseDown={() => {
        if (inputRef.current?.disabled) return;
        cliqueDentro.current = true;
        setIsFocused(true);
        setIsOpen(true);
      }}
    >
      <DefaultInput
        type="text"
        ref={inputRef}
        $isFocused={isFocused}
        $isFilled={isFilled}
        $isErrored={!!erroInterno}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        {...inputProps}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
      <ContainerListaItem
        ref={listaItemContainerRef}
        onWheel={handleWheel}
        onScroll={handleScrolled}
      >
        {isOpen &&
          (!loading ? (
            listaItem?.map((item, index) => {
              const selecionado = item.chave === itemAtualRef.current?.chave;
              const navegacao = indexItemNavegacao === index;

              if (index >= listaItem.length - 1 && carregandoMaisRegistros) {
                return (
                  <li
                    key="carregando"
                    ref={navegacao ? itemNavegacaoContainerRef : undefined}
                  >
                    <button
                      type="button"
                      style={{
                        minHeight: listaItemContainerRef?.current?.offsetHeight,
                      }}
                    >
                      {CarregandoPersonalizado ? (
                        <CarregandoPersonalizado />
                      ) : (
                        <Item $selecionado={false} $navegacao={false}>
                          Carregando...
                        </Item>
                      )}
                    </button>
                  </li>
                );
              }
              return (
                <li
                  key={item.chave}
                  ref={navegacao ? itemNavegacaoContainerRef : undefined}
                >
                  <button
                    type="button"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      setIsFocused(false);
                      setIsOpen(false);
                      selecionarItem(item, '');
                    }}
                  >
                    {ItemPersonalizado ? (
                      <ItemPersonalizado
                        index={index}
                        item={item.valor}
                        navegacao={navegacao}
                        selecionado={selecionado}
                      />
                    ) : (
                      <Item $selecionado={selecionado} $navegacao={navegacao}>
                        {item.label}
                      </Item>
                    )}
                  </button>
                </li>
              );
            })
          ) : (
            <li>
              <button type="button">
                {CarregandoPersonalizado ? (
                  <CarregandoPersonalizado />
                ) : (
                  <Item $selecionado={false} $navegacao={false}>
                    Carregando...
                  </Item>
                )}
              </button>
            </li>
          ))}
      </ContainerListaItem>
    </div>
  );
};

export default forwardRef(AutoCompleteBase);
