import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPessoaPorEstadoCidadeRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPessoaPorEstadoCidadePDF {
  dados: IPessoaPorEstadoCidadeRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PessoaPorEstadoCidadePDF: React.FC<IPessoaPorEstadoCidadePDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina}>
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {!dados.cabecalho.filtroTipoPessoa?.IsNullOrEmpty() && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tipo: '}</Text>
                      {dados.cabecalho.filtroTipoPessoa}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRepresentante && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Representante: '}
                      </Text>
                      {dados.cabecalho.filtroRepresentante}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroTag && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tags: '}</Text>
                      {dados.cabecalho.filtroTag}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '24%' }}>
              <Text>Nome/RazaoSocial</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>CNPJ/CPF</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text>Inscr. Estadual</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Telefone</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '20%' }}>
              <Text>Email</Text>
            </View>
          </View>

          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%' }} />
            <View style={{ width: '40%' }}>
              <Text>Logradouro/Número</Text>
            </View>

            <View style={{ width: '5%' }} />
            <View style={{ width: '30%' }}>
              <Text>Complemento</Text>
            </View>

            <View style={{ width: '5%' }} />

            <View style={{ width: '10%' }}>
              <Text>CEP</Text>
            </View>
          </View>

          {dados.listaDados.map((grupoEstado) => {
            const uf = grupoEstado.listaAgrupadaPorCidade[0].listaPessoa[0]
              .pessoaEnderecoPrincipal.cidade
              ? grupoEstado.listaAgrupadaPorCidade[0].listaPessoa[0]
                  .pessoaEnderecoPrincipal.cidade?.estado.sigla
              : 'SEM - ESTADO';

            return (
              <View key={grupoEstado.chave}>
                <Text style={{ marginTop: 5, fontWeight: 'bold' }}>
                  Estado: {uf}
                </Text>

                {grupoEstado.listaAgrupadaPorCidade.map((grupoCidade) => {
                  return (
                    <View key={grupoCidade.chave}>
                      <Text style={{ marginTop: 5, fontWeight: 'bold' }}>
                        {'Cidade: '}
                        {grupoCidade.listaPessoa[0].pessoaEnderecoPrincipal
                          ?.cidade
                          ? `${grupoCidade.listaPessoa[0].pessoaEnderecoPrincipal?.cidade.nome}`
                          : 'SEM - CIDADE'}
                      </Text>

                      {grupoCidade.listaPessoa.map((pessoa, index) => {
                        return (
                          <View
                            key={pessoa.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? '#E4E4E4' : '#fff',
                            }}
                          >
                            <View style={{ flexDirection: 'row' }}>
                              <View style={{ width: '5%', textAlign: 'right' }}>
                                <Text>{pessoa.codigo}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '24%' }}>
                                <Text>{pessoa.nomeRazaoSocial}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '14%' }}>
                                <Text>{pessoa.cnpjCpfVirtual}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '13%' }}>
                                <Text>{pessoa.inscricaoEstadual}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '14%' }}>
                                <Text>{pessoa.telefone}</Text>
                              </View>
                              <View style={{ width: '1%' }} />

                              <View style={{ width: '25%' }}>
                                <Text>{pessoa.email}</Text>
                              </View>
                            </View>

                            <View fixed style={{ flexDirection: 'row' }}>
                              <View style={{ width: '10%' }} />
                              <View style={{ width: '40%' }}>
                                <Text>
                                  {pessoa.pessoaEnderecoPrincipal?.logradouro}
                                  {pessoa.pessoaEnderecoPrincipal?.logradouro &&
                                    pessoa.pessoaEnderecoPrincipal?.numero &&
                                    ', '}
                                  {pessoa.pessoaEnderecoPrincipal?.numero}
                                </Text>
                              </View>

                              <View style={{ width: '5%' }} />
                              <View style={{ width: '30%' }}>
                                <Text>
                                  {pessoa.pessoaEnderecoPrincipal?.complemento}
                                </Text>
                              </View>

                              <View style={{ width: '5%' }} />

                              <View style={{ width: '10%' }}>
                                <Text>
                                  {FormatarCep(
                                    pessoa.pessoaEnderecoPrincipal?.cep
                                  )}
                                </Text>
                              </View>
                            </View>

                            {pessoa.listaPessoaContato.map((contato) => {
                              return (
                                <View key={contato.id}>
                                  <View fixed style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '32%' }}>
                                      <Text>Contato: {contato.nome}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View style={{ width: '32%' }}>
                                      <Text>Telefone: {contato.telefone}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />

                                    <View style={{ width: '32%' }}>
                                      <Text>Email: {contato.email}</Text>
                                    </View>
                                    <View style={{ width: '1%' }} />
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        );
                      })}

                      <View
                        style={{
                          borderBottom: '1pt dashed black',
                          width: '100%',
                        }}
                      />
                    </View>
                  );
                })}

                <View
                  style={{ borderBottom: '1pt dashed black', width: '100%' }}
                />
              </View>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PessoaPorEstadoCidadePDF;
