import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/impressao/retrato-sem-foto-produto';

interface IOrcamentoRetratoSemFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class OrcamentoRetratoSemFotoProdutoImpressaoComunicador {
  public async show(
    params: IOrcamentoRetratoSemFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoRetratoSemFotoProdutoImpressaoComunicador();
