import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/obter-lista-id-item-consignado';

interface IFrenteCaixaPdvObterListaIdItemConsignadoComunicadorShow {
  id: string;
}

class FrenteCaixaPdvObterListaIdItemConsignadoComunicador {
  public async show(
    params: IFrenteCaixaPdvObterListaIdItemConsignadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterListaIdItemConsignadoComunicador();
