/* eslint-disable jsx-a11y/label-has-associated-control */
import { isBefore } from 'date-fns';
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import InputDateTime, { IInputDateTimeRef } from '../InputDateTime';

export interface IInputPeriodoDataHoraRef {
  focus(): void;
  getPeriodo(): string;
  getDataFinal(): Date | null;
  getHoraFinal(): string | null;
  getDataInicial(): Date | null;
  getHoraInicial(): string | null;
}

interface IInputPeriodoDataHoraProps {
  nomeDataHoraInicial: string;
  nomeDataHoraFinal: string;
  labelDataHoraInicial?: string;
  labelDataHoraFinal?: string;

  onChange?: (event: {
    inputDataInicial: IInputDateTimeRef;
    inputDataFinal: IInputDateTimeRef;
  }) => void;
  onBlur?: (event: {
    inputDataInicial: IInputDateTimeRef | null;
    inputDataFinal: IInputDateTimeRef | null;
  }) => void;
}

const InputPeriodoDataHora: React.ForwardRefRenderFunction<
  IInputPeriodoDataHoraRef,
  IInputPeriodoDataHoraProps
> = (
  {
    nomeDataHoraInicial,
    nomeDataHoraFinal,
    labelDataHoraInicial,
    labelDataHoraFinal,

    onChange,
    onBlur,
  },
  ref
) => {
  const dataHoraInicioRef = useRef<IInputDateTimeRef>(null);
  const dataHoraFimRef = useRef<IInputDateTimeRef>(null);

  const validarDatas = useCallback(() => {
    if (!dataHoraInicioRef.current || !dataHoraFimRef.current) return;

    dataHoraInicioRef.current?.setErro('');
    dataHoraFimRef.current?.setErro('');

    const dataHoraInicio = dataHoraInicioRef.current?.value();
    const dataHoraFim = dataHoraFimRef.current?.value();

    if (dataHoraInicio && dataHoraFim) {
      if (isBefore(dataHoraFim, dataHoraInicio)) {
        dataHoraInicioRef.current?.setErro(
          'Data hora final menor que data hora inicial'
        );
        dataHoraFimRef.current?.setErro(
          'Data hora final menor que data hora inicial'
        );
      }
    }

    if (
      dataHoraInicioRef.current?.getHora() &&
      !dataHoraInicioRef.current?.getData()
    ) {
      dataHoraInicioRef.current?.setErro('Informe a data inicial');
    }

    if (
      !dataHoraInicioRef.current?.getHora() &&
      dataHoraInicioRef.current?.getData()
    ) {
      dataHoraInicioRef.current?.setErro('Informe a hora inicial');
    }

    if (
      dataHoraFimRef.current?.getHora() &&
      !dataHoraFimRef.current?.getData()
    ) {
      dataHoraFimRef.current?.setErro('Informe a data final');
    }

    if (
      !dataHoraFimRef.current?.getHora() &&
      dataHoraFimRef.current?.getData()
    ) {
      dataHoraFimRef.current?.setErro('Informe a hora final');
    }

    if (onChange)
      onChange({
        inputDataInicial: dataHoraInicioRef.current,
        inputDataFinal: dataHoraFimRef.current,
      });
  }, [onChange]);

  const getDataFinal = useCallback(() => {
    if (!dataHoraFimRef.current?.getData()) return null;

    const data = dataHoraFimRef.current?.getData();
    return data;
  }, []);

  const getHoraFinal = useCallback(() => {
    if (!dataHoraFimRef.current?.getHora()) return null;

    const hora = dataHoraFimRef.current?.getHora();
    return hora;
  }, []);

  const getDataInicial = useCallback(() => {
    if (!dataHoraInicioRef.current?.getData()) return null;

    const data = dataHoraInicioRef.current?.getData();
    return data;
  }, []);

  const getHoraInicial = useCallback(() => {
    if (!dataHoraInicioRef.current?.getHora()) return null;

    const hora = dataHoraInicioRef.current?.getHora();
    return hora;
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      dataHoraInicioRef.current?.focus();
    },

    getDataFinal() {
      return getDataFinal();
    },
    getHoraFinal() {
      return getHoraFinal();
    },

    getDataInicial() {
      return getDataInicial();
    },
    getHoraInicial() {
      return getHoraInicial();
    },

    getPeriodo() {
      const dataHoraInicio = dataHoraInicioRef.current?.getValueFormatado();
      const dataHoraFim = dataHoraFimRef.current?.getValueFormatado();

      if (
        (!dataHoraInicio && dataHoraFim) ||
        (dataHoraInicio && !dataHoraFim)
      ) {
        return dataHoraInicio || (dataHoraFim as string);
      }

      return `${dataHoraInicio} até ${dataHoraFim}`;
    },
  }));

  const handleChangeDataInicio = useCallback(() => {
    if (!dataHoraInicioRef.current?.getHora()) {
      dataHoraInicioRef.current?.setHora('00:00');
    }
  }, []);

  const handleChangeDataFim = useCallback(() => {
    if (!dataHoraFimRef.current?.getHora()) {
      dataHoraFimRef.current?.setHora('23:59');
    }
  }, []);

  const handleInputBlur = useCallback(() => {
    if (onBlur) {
      onBlur({
        inputDataInicial: dataHoraInicioRef.current,
        inputDataFinal: dataHoraFimRef.current,
      });
    }
  }, [onBlur]);

  return (
    <>
      <Row>
        <Col>
          <InputDateTime
            ref={dataHoraInicioRef}
            name={nomeDataHoraInicial}
            label={labelDataHoraInicial}
            onChange={validarDatas}
            // onChangeHora={handleChangeHoraInicio}
            onChangeData={handleChangeDataInicio}
            onBlur={handleInputBlur}
          />
        </Col>
        <Col>
          <InputDateTime
            ref={dataHoraFimRef}
            name={nomeDataHoraFinal}
            label={labelDataHoraFinal}
            onChange={validarDatas}
            // onChangeHora={handleChangeHoraFim}
            onChangeData={handleChangeDataFim}
            onBlur={handleInputBlur}
          />
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(InputPeriodoDataHora);
