import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import CategoriaComunicador from '../../../../../../Comunicador/Financeiro/CategoriaDRE/Categoria/Comunicador/CategoriaComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';

const CategoriaDetalheHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: CategoriaComunicador }}>
      <RotasHook>
        <FormHook>{children}</FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default CategoriaDetalheHook;
