"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarCep;
function FormatarCep(value) {
    if (!value)
        return '';
    const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    if (value)
        return value.replace(re, '$1.$2-$3');
    return '';
}
