import React from 'react';
import DemonstrativoResultadoLista from './Lista';
import { TreeContextProvider } from './Hooks/TreeContext';

const DemonstrativoResultado: React.FC = () => {
  return (
    <TreeContextProvider>
      <DemonstrativoResultadoLista />
    </TreeContextProvider>
  );
};

export default DemonstrativoResultado;
