import React from 'react';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import { UseReactSizeMeBodyHook } from '../../Hooks/ReactSizeMeBodyHook';
import { Container } from './styles';

const SubHeaderGeral: React.FC<IPadraoProps> = ({ children }) => {
  const { telaPequena } = UseReactSizeMeBodyHook();

  return <Container $telaPequena={telaPequena}>{children}</Container>;
};

export default SubHeaderGeral;
