"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MarkupCalculo;
function MarkupCalculo(dados) {
    const markupDivisor = 100 - dados.markupVenda;
    let multiplicador = 0;
    if (markupDivisor > 0)
        multiplicador = 100 / markupDivisor;
    return multiplicador;
}
