import React from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import IEndereco from '../../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';

export const PesquisarCep = async (
  formRef: React.RefObject<IFormCiaHandles>,
  janelaCepRef: React.RefObject<IPesquisaEnderecoCepDetalheRef>
): Promise<void> => {
  const dados = formRef.current?.getDataPesquisa();

  const cidade = formRef.current?.getFieldValueNomeObjeto('cidade');

  await janelaCepRef.current?.pesquisarCep({
    cep: dados.cep,
    logradouro: dados.logradouro,
    bairro: dados.bairro,
    idCidade: cidade?.id || '',
    nomeCidade: cidade?.nome || '',
    siglaEstado: cidade?.estado.sigla || '',
  });
};

export const SelecionarEndereco = async (
  formRef: React.RefObject<IFormCiaHandles>,
  endereco: IEndereco
): Promise<void> => {
  formRef.current?.setFieldValue('logradouro', endereco.logradouro);
  formRef.current?.setFieldValue('bairro', endereco.bairro);
  formRef.current?.setFieldValue('cidade', endereco.cidade);
};
