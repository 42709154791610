import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { CheckSwitch, Container } from './styles';
import { UseForm } from '../../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputTabelaSwitchProps {
  valorAnterior: boolean;
}

export interface IInputTabelaSwitchRef {
  focus(): void;
}

interface IInputTabelaSwitchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valorPadrao: boolean;
  valueRef?: React.RefObject<HTMLInputElement>;
  ativo?: string;
  inativo?: string;
  largura?: number;
  error?: string;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTabelaSwitchProps
  ) => void;
  obterRef?: (inputRef: React.RefObject<HTMLInputElement>) => void;
}

const InputTabelaSwitch: React.ForwardRefRenderFunction<
  IInputTabelaSwitchRef,
  IInputTabelaSwitchProps
> = (
  {
    valorPadrao,
    valueRef,
    ativo = 'Ativo',
    inativo = 'Inativo',
    largura = 80,
    error,

    onFocus,
    onChange,
    onBlur,
    obterRef,
    ...rest
  },
  _ref
) => {
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef<boolean>(false);

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        if (valueRef?.current) {
          valorAnterior.current = valueRef.current.checked;
          valueRef.current.checked = valorPadrao;
        }

        obterRef(valueRef);
      } else {
        if (inputRef?.current) {
          valorAnterior.current = inputRef.current.checked;
          inputRef.current.checked = valorPadrao;
        }

        obterRef(inputRef);
      }
    }
  }, [obterRef, valorPadrao, valueRef]);

  useEffect(() => {
    if (valueRef) {
      valorAnterior.current = valueRef.current?.checked || false;
    } else {
      valorAnterior.current = inputRef.current?.checked || false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  const handleInputFocus = useCallback(
    (event: any) => {
      if (valueRef) {
        valorAnterior.current = valueRef.current?.checked || false;
      } else {
        valorAnterior.current = inputRef.current?.checked || false;
      }

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus, valueRef]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (valueRef) {
        setIsFilled(!!valueRef.current?.value);
      } else {
        setIsFilled(!!inputRef.current?.value);
      }

      if (onBlur) onBlur(event);
    },
    [onBlur, valueRef]
  );

  return (
    <Container
      $isErrored={!!erroInterno}
      $isFocused={isFocused}
      $isFilled={isFilled}
      style={{ minWidth: largura }}
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <CheckSwitch
          $ativo={ativo}
          $inativo={inativo}
          $largura={largura}
          style={{ width: largura }}
        >
          <input
            ref={valueRef || inputRef}
            type="checkbox"
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            {...rest}
          />
          <div />
        </CheckSwitch>
      </div>
    </Container>
  );
};

export default forwardRef(InputTabelaSwitch);
