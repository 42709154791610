import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import CodigoRastreabilidadeComunicador from '../../../../../../Comunicador/Suprimentos/Sisdevin/GrupoProdutoSisdevin/Comunicador/GrupoProdutoSisdevinComunicador';
import FormHook from './FormHook';
import SubHeaderHook from './SubHeaderHook';
import RotasHook from './RotasHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { CodigoRastreabilidadeSisdevinHook } from './DuplicarHook';

const GrupoProdutoSisdevinHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: CodigoRastreabilidadeComunicador }}
    >
      <RotasHook>
        <CodigoRastreabilidadeSisdevinHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </CodigoRastreabilidadeSisdevinHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default GrupoProdutoSisdevinHook;
