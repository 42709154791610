"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoOrcamentoOrdemServicoEnum;
(function (SituacaoOrcamentoOrdemServicoEnum) {
    SituacaoOrcamentoOrdemServicoEnum["atendido"] = "Atendido";
    SituacaoOrcamentoOrdemServicoEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoOrcamentoOrdemServicoEnum["naoAtendido"] = "N\u00E3o Atendido";
})(SituacaoOrcamentoOrdemServicoEnum || (SituacaoOrcamentoOrdemServicoEnum = {}));
exports.default = SituacaoOrcamentoOrdemServicoEnum;
