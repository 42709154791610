import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-romaneio-carga';

interface INotaFiscalSaidaRomaneioCargaComunicadorShow {
  id: string;
}

class NotaFiscalSaidaRomaneioCargaComunicador {
  public async show(
    params: INotaFiscalSaidaRomaneioCargaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaRomaneioCargaComunicador();
