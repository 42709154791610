import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  IComissoesNotaFiscalFiltroRelatorioFrontend,
  SituacaoNotaFiscalSaidaEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputAutoCompleteTagEstado from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagSituacaoNotaFiscalSaidaEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoNotaFiscalSaidaEnum';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import InputAutoCompleteTagRepresentante from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRepresentante';
import InputAutoCompleteTagEmpresa from '../../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import { UseAuth } from '../../../../../Hooks/Auth';
import PessoaObterPessoaRepresentantePorUsuarioComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPessoaRepresentantePorUsuarioComunicador';

interface IComissoesNotaFiscalRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const ComissoesNotaFiscalRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraEmissaoRef = useRef<IInputPeriodoDataHoraRef>(null);

  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [RepresentantesDisabled, setRepresentantesDisabled] = useState(false);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data inicial',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora final',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComissoesNotaFiscalRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IComissoesNotaFiscalFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComissoesNotaFiscalRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IComissoesNotaFiscalFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IComissoesNotaFiscalRelatorioDados;

      const pesquisaValida = await handleValidar(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];

        await template.handleVisualizarPdf(
          dados as IComissoesNotaFiscalFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleObterListaRepresentante = useCallback(async () => {
    const response =
      await PessoaObterPessoaRepresentantePorUsuarioComunicador.show();

    if (response.length > 0) {
      formRef.current?.setFieldValue('listaRepresentante', response);
      setRepresentantesDisabled(true);
    } else {
      setRepresentantesDisabled(false);
    }
  }, []);

  useEffect(() => {
    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);

    handleObterListaRepresentante();

    formRef.current?.setDataInicial({
      opcaoRelatorio: 'ComissoesNotaFiscalPorFaturamento',
      dataEmissaoInicial: primeiroDia,
      dataEmissaoFinal: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
      ),
      listaSituacaoItem: [
        { descricao: SituacaoNotaFiscalSaidaEnum.autorizada },
      ],
      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],
    });
  }, [
    empresa.id,
    empresa.nomeRazaoSocial,
    formRef,
    handleObterListaRepresentante,
  ]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Comissões (Nota Fiscal)
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Faturamento',
                        value: 'ComissoesNotaFiscalPorFaturamento',
                      },
                      {
                        title: 'Por Faturamento C/ Cliente',
                        value: 'ComissoesNotaFiscalPorFaturamentoComCliente',
                      },
                      {
                        title: 'Por Faturamento e Por Item',
                        value:
                          'ComissoesNotaFiscalPorFaturamentoPorItemRelatorio',
                      },
                      {
                        title: 'Por Faturamento e Por Item C/ Cliente',
                        value:
                          'ComissoesNotaFiscalPorFaturamentoPorItemComClienteRel',
                      },
                      {
                        title: 'Por Liquidez',
                        value: 'ComissoesNotaFiscalPorLiquidez',
                      },
                      {
                        title: 'Por Liquidez C/ Cliente',
                        value: 'ComissoesNotaFiscalPorLiquidezComCliente',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Emissão Inicial"
                      labelDataHoraFinal="Data Emissão Final"
                      nomeDataHoraInicial="dataEmissaoInicial"
                      nomeDataHoraFinal="dataEmissaoFinal"
                      ref={periodoDataHoraEmissaoRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSituacaoNotaFiscalSaidaEnum
                      label="Situação do Item"
                      name="listaSituacaoItem"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagModeloDocumento
                      label="Modelo Documento"
                      name="listaModeloDocumento"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagRepresentante
                      disabled={RepresentantesDisabled}
                      label="Representantes"
                      name="listaRepresentante"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Clientes"
                      name="listaCliente"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagRamoAtividadeProfissao
                      label="Ramo Atividade/Profissão"
                      name="listaRamoAtividadeProfissao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEstado
                      label="Estados"
                      name="listaEstado"
                      onChangeListaItemAtual={({ listaItemAtual }) => {
                        handleChangeListaItemAtualEstado(listaItemAtual);
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCidade
                      label="Cidades"
                      name="listaCidade"
                      listaIdEstado={listaIdEstado}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default ComissoesNotaFiscalRelatorio;
