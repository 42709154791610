import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import OrcamentoHooks from './Hook';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import AcoesOrcamento from './AcoesOrcamento';

const OrcamentoDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <OrcamentoHooks>
        <SubHeader
          titulo={params.id ? 'Editar Orçamento' : 'Orçamento'}
          permissao={permissoes.ComercialVendasOrcamento}
          botoes={
            <AcoesOrcamento permissao={permissoes.ComercialVendasOrcamento} />
          }
          isOrcamento
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </OrcamentoHooks>
    </Container>
  );
};

export default OrcamentoDetalhe;
