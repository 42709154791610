import { DestinadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/obter-nota-fiscal-saida-item-estoque';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemEstoqueComunicadorIndex {
  dataHoraEmissao: Date;
  idNotaFiscalSaidaItem: string;
  quantidade: number;
  idTipoNota: string;
  idTipoNotaMotivo: string;
  destinado: DestinadoEnum;
  idOperacaoFiscal: string;
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemEstoqueComunicador {
  public async index(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaObterNotaFiscalSaidaItemEstoqueComunicador();
