"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEmpresaSisdevinEnum;
(function (TipoEmpresaSisdevinEnum) {
    TipoEmpresaSisdevinEnum["cantinaCentral"] = "1 - Cantina Central";
    TipoEmpresaSisdevinEnum["cantinaRural"] = "2 - Cantina Rural";
    TipoEmpresaSisdevinEnum["adegadeVinhosFinos"] = "3 - Adega de Vinhos Finos";
    TipoEmpresaSisdevinEnum["engarrafador"] = "4 - Engarrafador";
    TipoEmpresaSisdevinEnum["estandizador"] = "5 - Estandizador";
    TipoEmpresaSisdevinEnum["postodeVinificacao"] = "6 - Posto de Vinifica\u00E7\u00E3o";
    TipoEmpresaSisdevinEnum["cantinaCentralCooperativa"] = "7 - Cantina Central (Cooperativa)";
})(TipoEmpresaSisdevinEnum || (TipoEmpresaSisdevinEnum = {}));
exports.default = TipoEmpresaSisdevinEnum;
