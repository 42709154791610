import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/orcamento/orcamento-por-data-emissao';

interface IOrcamentoPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrcamentoPorDataEmissaoRelatorioComunicador {
  public async index(
    params: IOrcamentoPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrcamentoPorDataEmissaoRelatorioComunicador();
