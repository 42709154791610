import {
  EstoqueIndicadorTipoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/lote/obter-por-estoque-combinacao';

interface ILoteObterPorEstoqueCombinacaComunicadorIndex {
  idPessoa: string;
  idProduto: string;
  idLocalEstoque: string;
  indicadorTipoEstoque: EstoqueIndicadorTipoEnum;
  dataHoraEmissao: Date;
  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum;
  reservarEstoque?: boolean;
}

class LoteObterPorEstoqueCombinacaComunicador {
  public async index(
    params: ILoteObterPorEstoqueCombinacaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new LoteObterPorEstoqueCombinacaComunicador();
