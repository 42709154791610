import api from '../../../../Comum/Services/Api';

const rota = 'fiscal/sped-genero-item-servico';

interface ISpedGeneroItemServicoComunicadorIndex {
  params: any;
}

class SpedGeneroItemServicoComunicador {
  public async show(
    params: ISpedGeneroItemServicoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SpedGeneroItemServicoComunicador();
