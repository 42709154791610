"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoBoletoEnum;
(function (SituacaoBoletoEnum) {
    SituacaoBoletoEnum["naoGerado"] = "N\u00E3o Gerado";
    SituacaoBoletoEnum["registrado"] = "Registrado";
    SituacaoBoletoEnum["baixado"] = "Baixado";
    SituacaoBoletoEnum["liquidado"] = "Liquidado";
    SituacaoBoletoEnum["negativado"] = "Negativado";
    SituacaoBoletoEnum["protestado"] = "Protestado";
})(SituacaoBoletoEnum || (SituacaoBoletoEnum = {}));
exports.default = SituacaoBoletoEnum;
