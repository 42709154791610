import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/portador-auto-complete';

interface IPortadorAutoCompleteComunicadorIndex {
  params: any;
}

class PortadorAutoCompleteComunicador {
  public async index(
    params: IPortadorAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PortadorAutoCompleteComunicador();
