import api from '../../../../../Comum/Services/Api';

const rota = '/suprimentos/produtos/atualizacao-estoque-produto';

interface IAtualizacaoEstoqueProdutoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoDocumento?: string;
  };
}

interface IAtualizacaoEstoqueProdutoComunicadorUpdate {
  params: any;
}

class AtualizacaoEstoqueProdutoComunicador {
  public async index(
    params: IAtualizacaoEstoqueProdutoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async update(
    params: IAtualizacaoEstoqueProdutoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new AtualizacaoEstoqueProdutoComunicador();
