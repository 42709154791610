const PedidoCompraTotalPorModeloDocumentoPlanilha = [
  { titulo: 'Modelo de Documento', valor: 'valores.chaveFormatada' },

  { titulo: 'Numero', valor: 'listaPedidoCompraItem.pedidoCompraNumero' },

  {
    titulo: 'Data de Emissão',
    valor: 'listaPedidoCompraItem.pedidoCompraDataHoraEmissao',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaPedidoCompraItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaPedidoCompraItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaPedidoCompraItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaPedidoCompraItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaPedidoCompraItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaPedidoCompraItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaPedidoCompraItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaPedidoCompraItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaPedidoCompraItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaPedidoCompraItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaPedidoCompraItem.somatorioValorTotal',
  },
];

export default PedidoCompraTotalPorModeloDocumentoPlanilha;
