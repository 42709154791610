import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/geral/forma-pagamento-auto-complete';

interface IFormaPagamentoAutoCompleteComunicadorIndex {
  params: any;
}

class FormaPagamentoAutoCompleteComunicador {
  public async index(
    params: IFormaPagamentoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FormaPagamentoAutoCompleteComunicador();
