"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ObterRegraIndicadorTipoEstoqueSaida;
const Enum_1 = require("../../Enum");
function ObterRegraIndicadorTipoEstoqueSaida({ indicadorTipoEstoque, tipoEntradaSaida, }) {
    if (indicadorTipoEstoque === Enum_1.EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro) {
        return tipoEntradaSaida === Enum_1.TipoMovimentoEntradaSaidaEnum.saida
            ? Enum_1.EstoqueIndicadorTipoEnum.proprioEmPoderProprio
            : Enum_1.EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;
    }
    if (indicadorTipoEstoque === Enum_1.EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro) {
        return tipoEntradaSaida === Enum_1.TipoMovimentoEntradaSaidaEnum.saida
            ? Enum_1.EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
            : Enum_1.EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro;
    }
    return indicadorTipoEstoque;
}
