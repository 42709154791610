import React, { useEffect, useRef, useState } from 'react';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PiArrowFatRightFill } from 'react-icons/pi/index.mjs';

type Props = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onItemSelecionado?: (item: any) => void;
  onItemRemoval?: (item: any) => void;
  listaOpcoesCsvIniciais: any[];
  listaOpcoesCsv: any[];
  tooltip: string;
  opcaoInterna: {
    id: number;
    nome: string;
  };
};

const EstoqueImportacaoDeParaManualItem: React.FC<Props> = ({
  onItemSelecionado,
  onItemRemoval,
  listaOpcoesCsvIniciais,
  listaOpcoesCsv,
  opcaoInterna,
  tooltip,
}) => {
  // #region estados

  const [inputValue, setInputValue] = useState('');
  const [listItemSearch, setListItemSearch] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const listGroupRef = useRef<HTMLDivElement>(null);

  // #endregion estados

  // #region Funções

  const handleOutsideClick = (e: MouseEvent): void => {
    if (
      listGroupRef.current &&
      !listGroupRef.current.contains(e.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setListItemSearch(e.target.value);
    if (listaOpcoesCsv.includes(e.target.value)) {
      setListItemSearch('');
      setInputValue(e.target.value);
    }
  };

  const filteredOptions = listaOpcoesCsv.filter((option) =>
    option.toLowerCase().includes(listItemSearch.toLowerCase())
  );

  const onFocus = (): void => {
    if (inputValue === '') {
      setShowOptions(true);
    }
  };

  const handleInputFeedback = (): string | undefined => {
    if (inputValue) {
      return inputValue;
    }
    return listItemSearch;
  };

  const handleSelectOption = (
    option: {
      field: string;
      value: string;
    },
    e: React.MouseEvent<Element, MouseEvent>
  ): any => {
    e.stopPropagation();

    if (onItemSelecionado) {
      onItemSelecionado(option);
    }

    setInputValue(option.value);
    setListItemSearch('');
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (
      listaOpcoesCsvIniciais &&
      listaOpcoesCsvIniciais.includes(opcaoInterna.nome)
    ) {
      setInputValue(opcaoInterna.nome);

      if (onItemSelecionado) {
        onItemSelecionado({
          field: opcaoInterna.nome,
          value: opcaoInterna.nome,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion Funções

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row', marginBottom: '5pt' }}
      >
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
        >
          <div
            className="form-control"
            style={{
              backgroundColor: '#f8f9fa',
              cursor: 'help',
              minHeight: '36px',
              padding: '0pt 0 0pt 5pt',
              width: '48%',
              display: 'flex',
              wordBreak: 'break-word',
              alignItems: 'center',
            }}
          >
            <span>{opcaoInterna.nome}</span>
          </div>
        </OverlayTrigger>

        <div
          style={{
            color: inputValue ? '#28a745' : '#a0a0a0',
            transition: 'color 0.5s ease-in-out',
            minHeight: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 0,
            width: '4%',
          }}
        >
          <PiArrowFatRightFill size={20} />
        </div>

        <div
          className="form-control"
          onClick={onFocus}
          style={{
            width: '48%',
            minHeight: '36px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            wordBreak: 'break-word',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              wordBreak: 'break-word',
            }}
          >
            <input
              type="text"
              value={handleInputFeedback()}
              onChange={handleOnChange}
              placeholder="Selecione a equivalência"
              className={`${!inputValue && 'text-danger'}`}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                margin: '0',
                padding: '0',
                width: '100%',
                wordBreak: 'break-word',
              }}
            />
            {showOptions && (
              <div ref={listGroupRef}>
                <ListGroup
                  style={{
                    position: 'absolute',
                    padding: '0',
                    marginTop: '18px',
                    boxShadow: '0px 0px 11px -5px #000000',
                  }}
                  onMouseLeave={() => setShowOptions(false)}
                >
                  {filteredOptions.map((option, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      onClick={(e) =>
                        handleSelectOption(
                          {
                            field: opcaoInterna.nome,
                            value: option,
                          },
                          e
                        )
                      }
                    >
                      {option}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}
          </div>

          {inputValue && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Limpar Campo</Tooltip>}
            >
              <button
                type="button"
                className="text-dark"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  fontSize: '12pt',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  margin: '0',
                  padding: '0',
                }}
                onClick={() => {
                  if (onItemRemoval) {
                    onItemRemoval(inputValue);
                    setInputValue('');
                  }
                }}
              >
                ✖
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </>
  );
};

export default EstoqueImportacaoDeParaManualItem;
