"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ComparaDoisArraysComId;
function ComparaDoisArraysComId(dados) {
    const diferencaArray1 = dados.array1.filter((element) => {
        const aux = dados.array2.find((e) => {
            return e.id === element.id;
        });
        return aux === -1;
    });
    const diferencaArray2 = dados.array2.filter((element) => {
        const aux = dados.array1.find((e) => {
            return e.id === element.id;
        });
        return aux === -1;
    });
    return { diferencaArray1, diferencaArray2 };
}
