import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/impressao-etiquetas-pedido-venda';

interface IImpressaoEtiquetasPedidoVendaComunicadorIndex {
  params: {
    idPedidoVendaEtiquetaSelecionada?: string | null;
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IImpressaoEtiquetasPedidoVendaComunicadorShow {
  id: string;
}

interface IImpressaoEtiquetasPedidoVendaComunicadorStore {
  params: any;
}

interface IImpressaoEtiquetasPedidoVendaComunicadorUpdate {
  id: string;
  params: any;
}

interface IImpressaoEtiquetasPedidoVendaComunicadorDeletar {
  id: string;
}

class ImpressaoEtiquetasPedidoVendaComunicador {
  public async index(
    params: IImpressaoEtiquetasPedidoVendaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IImpressaoEtiquetasPedidoVendaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IImpressaoEtiquetasPedidoVendaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IImpressaoEtiquetasPedidoVendaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IImpressaoEtiquetasPedidoVendaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ImpressaoEtiquetasPedidoVendaComunicador();
