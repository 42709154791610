const ProdutoPorNcmPlanilha = [
  { titulo: 'Código', valor: 'listaProduto.codigo' },
  { titulo: 'Descrição', valor: 'listaProduto.descricao' },
  { titulo: 'Unidade de Medida', valor: 'listaProduto.unidadeMedida' },
  {
    titulo: 'Estoque mínimo',
    valor: 'listaProduto.produtoEstoque.quantidadeEstoqueMinimo',
  },
  {
    titulo: 'Estoque máximo',
    valor: 'listaProduto.produtoEstoque.quantidadeEstoqueMaximo',
  },
  {
    titulo: 'Controla estoques',
    valor: 'listaProduto.produtoEstoque.controlarEstoque',
  },
  {
    titulo: 'Controla lotes',
    valor: 'listaProduto.produtoEstoque.controlarLote',
  },
];

export default ProdutoPorNcmPlanilha;
