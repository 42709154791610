import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { IBoletosFiltroRelatorioFrontend } from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import { UseAuth } from '../../../../Hooks/Auth';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../Componentes/Inputs/InputPeriodoDataHora';
import { IInputAutoCompleteClienteRef } from '../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import TextoLoading from '../../../../Componentes/TextoLoading';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import { OpcoesRelatorio } from '../Movimentacoes/ContasReceberPagar/styles';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import { Container, DivisorRelatorio } from './styles';
import Select from '../../../../Componentes/Select';
import InputAutoCompleteTagPortador from '../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import * as ListaTemplate from './Template';
import InputAutoCompleteTagEmpresa from '../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import InputAutoCompleteTagCliente from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';

interface IBoletosRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const Boletos: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraRef = useRef<IInputPeriodoDataHoraRef>(null);
  const inputAutoCompleteClienteRef =
    useRef<IInputAutoCompleteClienteRef>(null);

  const [loading, setLoading] = useState(false);

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataHoraInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataInicial() &&
                  !periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data inicial',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataInicial() &&
                  !!periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataHoraFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora final',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataFinal() &&
                  !periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data final',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataFinal() &&
                  !!periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IBoletosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IBoletosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IBoletosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IBoletosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IBoletosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IBoletosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const dataInicial = new Date().AddDays(-30).PrimeiraHoraDia();

    formRef.current?.setDataInicial({
      dataSaldo: new Date().UltimaHoraDia(),
      opcaoRelatorio: 'BoletosResumo',
      dataHoraInicial: dataInicial,
      dataHoraFinal: dataFinal,
      tipoData: 'Data Vencimento',

      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],
    });
  }, [empresa.id, empresa.nomeRazaoSocial, formRef]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>Boletos</h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Resumo de Boletos',
                        value: 'BoletosResumo',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Inicial"
                      labelDataHoraFinal="Data Final"
                      nomeDataHoraInicial="dataHoraInicial"
                      nomeDataHoraFinal="dataHoraFinal"
                      ref={periodoDataHoraRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} sm={6} style={{ marginTop: 10 }}>
                    <Select
                      name="tipoData"
                      label="Tipo de Data"
                      options={[
                        {
                          title: 'Data Vencimento',
                          value: 'Data Vencimento',
                        },
                        {
                          title: 'Data Emissão',
                          value: 'Data Emissão',
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Liquidação)"
                      name="listaPortadoresLiquidacao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Clientes"
                      name="listaClientes"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default Boletos;
