const PedidoVendaComNotasVinculadasRelatorioPlanilha = [
  { titulo: 'Série', valor: 'serie' },
  { titulo: 'Numero', valor: 'numero' },
  { titulo: 'Cliente', valor: 'nomeRazaoSocial' },

  { titulo: 'Código', valor: 'listaItens.produto.codigo' },
  { titulo: 'Descrição', valor: 'listaItens.produto.descricao' },
  { titulo: 'Quantidade', valor: 'listaItens.quantidade' },

  { titulo: 'Qntd Atendida', valor: 'listaItens.quantidadeAtendida' },
  { titulo: 'Saldo', valor: 'listaItens.saldo' },
  { titulo: 'Situação', valor: 'listaItens.situacao' },

  {
    titulo: 'Num Nota',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.notaFiscalSaidaItem.notaFiscalSaida.numero',
  },
  {
    titulo: 'Serie',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.notaFiscalSaidaItem.notaFiscalSaida.serie.codigo',
  },
  {
    titulo: 'Dt Emissao',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.notaFiscalSaidaItem.notaFiscalSaida.dataEmissao',
  },
  {
    titulo: 'Qtd Atendida',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.quantidade',
  },
];

export default PedidoVendaComNotasVinculadasRelatorioPlanilha;
