/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CompararStringsIguaisIgnoraAcentuacaoMinusculas } from '@elogestor/util';
import { ContainerListaItem, Item } from './styles';
import { DefaultInput, SpanErro } from '../../Styles';

interface IOnChange {
  valorAnterior: any;
}

interface IFiltrarItens {
  valor: string;
  offset: number;
  limite: number;
}

export interface IPersonalizarItem {
  item: any;
  index: number;
  selecionado: boolean;
  navegacao: boolean;
}

export interface IOnChangeItemAtualEvent {
  itemAtual: any | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteBaseString {
  personalizarItem?: React.FC<IPersonalizarItem>;
  personalizarCarregando?: React.FC;
  filtrarItens({ valor }: IFiltrarItens): Promise<any[]>;

  limite?: number;
  error?: string;

  onChangeItemAtual?: (
    evento: IOnChangeItemAtualEvent,
    valorAnterior: IOnChange
  ) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;

  inputProps?: React.HtmlHTMLAttributes<HTMLInputElement>;

  setPesquisando?: (vaor: boolean) => void;
}

export interface IInputAutoCompleteBaseStringRef {
  getItemAtual(): any;
  getInput(): HTMLInputElement | null;
  selecionarItem(item: any): void;
  selecionarItemSemEvento(item: any): void;
  focus(): void;
}

const AutoCompleteBaseString: React.ForwardRefRenderFunction<
  IInputAutoCompleteBaseStringRef,
  IInputAutoCompleteBaseString
> = (
  {
    personalizarItem: ItemPersonalizado,
    personalizarCarregando: CarregandoPersonalizado,
    filtrarItens,
    limite = 20,
    error,
    onChangeItemAtual,
    onChangeTexto,
    inputProps,
    setPesquisando,
  },
  ref
) => {
  const [loading, setLoading] = useState(false);

  const [listaItem, setListaItem] = useState<string[]>([]);
  const [indexItemNavegacao, setIndexItemNavegacao] = useState(-1);
  const itemAtualRef = useRef<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [carregandoMaisRegistros, setCarregandoMaisRegistros] = useState(false);
  const debounceMaisRegistrosRef = useRef<number | null>(null);
  const cliqueDentro = useRef(false);

  const listaItemContainerRef = useRef<HTMLUListElement>(null);
  const itemNavegacaoContainerRef = useRef<any>(null);

  const debounceChangeRef = useRef<number | null>(null);
  const requestIdRef = useRef(0);

  const carregarMaisItens = useCallback(
    async (offset: number) => {
      if (debounceMaisRegistrosRef.current) {
        return;
      }

      setCarregandoMaisRegistros(true);
      debounceMaisRegistrosRef.current = window.setTimeout(
        async () => {
          const listaValor = await filtrarItens({
            valor: inputRef.current?.value || '',
            offset,
            limite,
          });

          setListaItem((state) => [...state, ...listaValor]);
          if (debounceMaisRegistrosRef.current) {
            clearTimeout(debounceMaisRegistrosRef.current);
            debounceMaisRegistrosRef.current = null;
          }

          setCarregandoMaisRegistros(false);
        },
        500 * (offset / 20)
      );
    },
    [filtrarItens, inputRef, limite]
  );

  const selecionarItem = useCallback(
    (item: string | null) => {
      setErroInterno('');
      const valorAnterior = itemAtualRef.current;
      itemAtualRef.current = item;

      if (inputRef.current && item) {
        inputRef.current.value = item || '';
      }

      if (onChangeItemAtual) {
        onChangeItemAtual(
          {
            input: inputRef.current,
            itemAtual: item ?? null,
          },
          {
            valorAnterior,
          }
        );
      }
    },
    [onChangeItemAtual]
  );

  const selecionarItemSemEvento = useCallback((item: string | null) => {
    setErroInterno('');
    itemAtualRef.current = item;

    if (inputRef.current && item) {
      inputRef.current.value = item || '';
    }
  }, []);

  const procurarItemPorLabel = useCallback(
    (valor: string) => {
      if (loading) return;

      const itemAchado = listaItem.find((item) => {
        return CompararStringsIguaisIgnoraAcentuacaoMinusculas(item, valor);
      });

      if (itemAchado) {
        selecionarItem(itemAchado);
      } else {
        setErroInterno('');
        const valorAnterior = itemAtualRef.current;
        itemAtualRef.current = valor;

        if (inputRef.current && valor) {
          inputRef.current.value = valor || '';
        }

        if (onChangeItemAtual) {
          onChangeItemAtual(
            {
              input: inputRef.current,
              itemAtual: valor ?? null,
            },
            {
              valorAnterior,
            }
          );
        }
        // setErroInterno('Registro não encontrado!');
      }
    },
    [listaItem, loading, onChangeItemAtual, selecionarItem]
  );

  const alterarIndexNavegacao = useCallback(
    (valor: number) => {
      setIndexItemNavegacao((state) => {
        const soma = state + valor;
        const tamanhoLista = listaItem.length - 1;

        if (soma < 0) {
          return 0;
        }

        if (soma > tamanhoLista) {
          carregarMaisItens(tamanhoLista + 1);
          return tamanhoLista;
        }

        return soma;
      });
    },
    [carregarMaisItens, listaItem.length]
  );

  const mapeamentoDeNavegacao = useMemo<{ [key: string]: any }>(() => {
    function selecionar(): void {
      if (loading) return;
      setIsFocused(false);
      setIsOpen(false);

      if (indexItemNavegacao >= 0 && listaItem[indexItemNavegacao]) {
        selecionarItem(listaItem[indexItemNavegacao]);
      }
    }

    return {
      ArrowDown: () => {
        alterarIndexNavegacao(1);
      },
      ArrowUp: () => {
        alterarIndexNavegacao(-1);
      },
      PageDown: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(4);
      },
      PageUp: (event: any) => {
        event.preventDefault();
        alterarIndexNavegacao(-4);
      },
      Tab: () => {
        selecionar();
      },
      Enter: (event: any) => {
        event.preventDefault();
        selecionar();
        inputRef.current?.blur();
      },
    };
  }, [
    alterarIndexNavegacao,
    indexItemNavegacao,
    listaItem,
    loading,
    selecionarItem,
  ]);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setIsFocused(true);
      setIsOpen(true);
      setIndexItemNavegacao(0);

      const input = event.target;
      setErroInterno('');

      if (onChangeTexto) {
        onChangeTexto({
          input,
        });
      }

      if (itemAtualRef.current) {
        selecionarItem(null);
      }

      if (debounceChangeRef.current) {
        clearTimeout(debounceChangeRef.current);
      }

      debounceChangeRef.current = window.setTimeout(async () => {
        const currentRequestId = ++requestIdRef.current;

        const listaValor = await filtrarItens({
          valor: input.value,
          offset: 0,
          limite,
        });

        if (currentRequestId === requestIdRef.current) {
          setListaItem(listaValor);
          setLoading(false);
          inputRef.current?.focus();
          setIsFocused(true);
          setIsOpen(true);

          if (setPesquisando) setPesquisando(false);
        }
      }, 400);
    },
    [filtrarItens, limite, onChangeTexto, selecionarItem, setPesquisando]
  );

  const handleFocus = useCallback(async () => {
    setIsFocused(true);

    const listaValor = await filtrarItens({
      valor: inputRef.current?.value || '',
      offset: 0,
      limite,
    });

    setListaItem(listaValor);
  }, [filtrarItens, inputRef, limite]);

  const handleBlur = useCallback(async () => {
    setIsFocused(false);
    setIsOpen(false);
    setIsFilled(!!inputRef.current?.value);
    if (!itemAtualRef.current && inputRef.current?.value) {
      procurarItemPorLabel(inputRef.current.value);
    }
  }, [inputRef, itemAtualRef, procurarItemPorLabel]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const chave = event.key;
      const funcao = mapeamentoDeNavegacao[chave];
      if (funcao) {
        funcao(event);
      }
    },
    [mapeamentoDeNavegacao]
  );

  const handleWheel = useCallback(
    (event: React.WheelEvent<HTMLUListElement>) => {
      if (event.deltaY >= 0) {
        alterarIndexNavegacao(1);
      } else {
        alterarIndexNavegacao(-1);
      }
    },
    [alterarIndexNavegacao]
  );

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  useEffect(() => {
    function handleValidarClick(): void {
      if (!cliqueDentro.current) {
        setIndexItemNavegacao(0);
        setIsFocused(false);
        setIsOpen(false);
      } else {
        cliqueDentro.current = false;
      }
    }
    document.addEventListener('click', handleValidarClick);

    return () => {
      document.removeEventListener('click', handleValidarClick);
    };
  }, []);

  useEffect(() => {
    listaItemContainerRef.current?.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    function ScrollSeNecessario(): void {
      if (!itemNavegacaoContainerRef.current || !listaItemContainerRef.current)
        return;

      listaItemContainerRef.current.scrollTo(
        0,
        itemNavegacaoContainerRef.current.offsetTop +
          itemNavegacaoContainerRef.current.offsetHeight -
          listaItemContainerRef.current.offsetHeight
      );
    }

    ScrollSeNecessario();
  }, [indexItemNavegacao]);

  useImperativeHandle(ref, () => ({
    getItemAtual: () => {
      if (itemAtualRef.current) return itemAtualRef.current;
      if (inputRef.current?.value) return inputRef.current?.value;
      return null;
    },

    getInput: () => {
      return inputRef.current;
    },

    selecionarItem: (item: any) => {
      if (item) {
        selecionarItem(item);
      } else {
        selecionarItem(null);
        if (inputRef.current) inputRef.current.value = '';
      }
    },

    selecionarItemSemEvento: (item: any) => {
      if (item) {
        selecionarItemSemEvento(item);
      } else {
        selecionarItemSemEvento(null);
        if (inputRef.current) inputRef.current.value = '';
      }
    },

    focus() {
      inputRef.current?.focus();
    },
  }));

  const handleScrolled = useCallback(() => {
    if (!listaItemContainerRef.current) return;

    if (
      listaItemContainerRef.current.offsetHeight +
        listaItemContainerRef.current.scrollTop >=
      listaItemContainerRef.current.scrollHeight
    ) {
      carregarMaisItens(listaItem.length);
    }
  }, [carregarMaisItens, listaItem.length]);

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      onMouseDown={() => {
        if (inputRef.current?.disabled) return;
        cliqueDentro.current = true;
        setIsFocused(true);
        setIsOpen(true);
      }}
    >
      <DefaultInput
        type="text"
        ref={inputRef}
        $isFocused={isFocused}
        $isFilled={isFilled}
        $isErrored={!!erroInterno}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        {...inputProps}
      />
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
      <ContainerListaItem
        ref={listaItemContainerRef}
        onWheel={handleWheel}
        onScroll={handleScrolled}
      >
        {isOpen &&
          (!loading ? (
            listaItem.map((item, index) => {
              const selecionado = item === itemAtualRef.current;
              const navegacao = indexItemNavegacao === index;

              if (index >= listaItem.length - 1 && carregandoMaisRegistros) {
                return (
                  <li
                    key="carregando"
                    ref={navegacao ? itemNavegacaoContainerRef : undefined}
                  >
                    <button type="button">
                      {CarregandoPersonalizado ? (
                        <CarregandoPersonalizado />
                      ) : (
                        <Item $selecionado={false} $navegacao={false}>
                          Carregando...
                        </Item>
                      )}
                    </button>
                  </li>
                );
              }
              return (
                <li
                  key={item}
                  ref={navegacao ? itemNavegacaoContainerRef : undefined}
                >
                  <button
                    type="button"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      setIsFocused(false);
                      setIsOpen(false);
                      selecionarItem(item);
                    }}
                  >
                    {ItemPersonalizado ? (
                      <ItemPersonalizado
                        index={index}
                        item={item}
                        navegacao={navegacao}
                        selecionado={selecionado}
                      />
                    ) : (
                      <Item $selecionado={selecionado} $navegacao={navegacao}>
                        {item}
                      </Item>
                    )}
                  </button>
                </li>
              );
            })
          ) : (
            <li>
              <button type="button">
                {CarregandoPersonalizado ? (
                  <CarregandoPersonalizado />
                ) : (
                  <Item $selecionado={false} $navegacao={false}>
                    Carregando...
                  </Item>
                )}
              </button>
            </li>
          ))}
      </ContainerListaItem>
    </div>
  );
};

export default forwardRef(AutoCompleteBaseString);
