"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var BlocoHFormaGeracaoValoresEnum;
(function (BlocoHFormaGeracaoValoresEnum) {
    BlocoHFormaGeracaoValoresEnum["cstCompraValoresCompra"] = "CST Compra e Valores da Compra";
    BlocoHFormaGeracaoValoresEnum["cstCompraValoresCompraeZeradosAliqOrigCST00"] = "CST Compra e Val. Compra e Zerados Aliq Orig. e CST 00";
    BlocoHFormaGeracaoValoresEnum["cstUltimaCompraValoresCompraeZeradosAliqOrigCST00"] = "CST Ultima Compra e Val. Compra e Zerados Aliq Orig. e CST 00";
})(BlocoHFormaGeracaoValoresEnum || (BlocoHFormaGeracaoValoresEnum = {}));
exports.default = BlocoHFormaGeracaoValoresEnum;
