/* eslint-disable default-case */
import React, { useEffect, useCallback } from 'react';
import { Modal, Container, Col, Row, Table } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import ListaProvider from '../../../../../../../../Componentes/Lista/ListaProvider';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import MovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicador from '../../../../../../../../Comunicador/Financeiro/Movimentacoes/MovimentoPortador/Comunicador/MovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicador';
import { UseMovimentoPortador } from '../Hook/ContaListaMovimentoPortadorHook';

interface IMovimentoPortadorListaModal {
  onFecharFormModal(): void;
  idConta: string;
  salvarFormPrincipal?: boolean;
}

const MovimentoPortadorLista: React.FC<IMovimentoPortadorListaModal> = ({
  onFecharFormModal,
  idConta,
}) => {
  const { formRefLista, setLoading, loading, getIdDetalheRegistro } =
    UseListaDetalheForm();

  const { listaMovimentoPortador, setListaMovimentoPortador } =
    UseMovimentoPortador();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleCarregarDadosLista = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response =
        await MovimentoPortadorObterDadosUtilizacaoAdiantamentoComunicador.show(
          {
            params: {
              id: String(idConta),
            },
          }
        );

      if (!response) return;

      setListaMovimentoPortador(response);

      setLoading(false);
    } catch (error) {
      TratarErros(error);

      setLoading(false);
    }
  }, [idConta, setListaMovimentoPortador, setLoading]);

  useEffect(() => {
    handleCarregarDadosLista();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDetalheRegistro]);

  return (
    <>
      <JanelaDetalhe
        titulo="Utilização do Adiantamento"
        tamanho="xl"
        onFecharFormModal={onFecharFormModal}
      >
        <LoadingDiv isLoading={loading} isToggleJanela />

        <FormCia ref={formRefLista}>
          <Modal.Body>
            <Row>
              <Col
                xl={4}
                lg={4}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              />
            </Row>

            <ListaProvider>
              <Tabela
                style={{ display: 'flex', overflow: 'auto', minHeight: 300 }}
              >
                <Table
                  striped
                  hover
                  bordered
                  variant="light"
                  style={{ height: 50 }}
                >
                  <thead>
                    <tr>
                      <th className="lista-texto" style={{ width: '3%' }}>
                        <span>Número</span>
                      </th>
                      <th className="lista-texto" style={{ width: '3%' }}>
                        <span>Parcela</span>
                      </th>
                      <th className="lista-valor" style={{ width: '4%' }}>
                        <span>Val. Parcela</span>
                      </th>
                      <th className="lista-valor" style={{ width: '6%' }}>
                        <span>Val. Utilizado</span>
                      </th>
                      <th className="lista-texto" style={{ width: '4%' }}>
                        <span>Cliente/Fornecedor</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listaMovimentoPortador.length > 0 &&
                      listaMovimentoPortador?.map((item) => {
                        return (
                          <>
                            <tr key={v4()}>
                              <td className="lista-texto">
                                {item.contaParcela.numeroDocumento}
                              </td>
                              <td className="lista-texto">
                                {`${item.contaParcela.sequencia}/${item.contaParcela.conta.quantidadeParcelas}`}
                              </td>
                              <td className="lista-valor">
                                {`R$ ${Number(
                                  item.contaParcela.valorTotalParcela
                                ).FormatarParaPtBr()}`}
                              </td>
                              <td className="lista-valor">
                                {`R$ ${Number(item.valor).FormatarParaPtBr()}`}
                              </td>
                              <td className="lista-texto">
                                {item.contaParcela.conta.pessoa.nomeRazaoSocial}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </Tabela>
            </ListaProvider>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={onFecharFormModal}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <IoMdReturnLeft />
                    <span style={{ marginLeft: 10 }}>Voltar</span>
                  </TextoLoading>
                </button>
              </Container>
            </div>
          </Modal.Footer>
        </FormCia>
      </JanelaDetalhe>
    </>
  );
};

export default MovimentoPortadorLista;
