"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoProcessoEmissaoNfeEnum;
(function (TipoProcessoEmissaoNfeEnum) {
    TipoProcessoEmissaoNfeEnum["aplicativoContribuinte"] = "0 - Emiss\u00E3o de NF-e com aplicativo do contribuinte";
    TipoProcessoEmissaoNfeEnum["avulsaFisco"] = "1 - Emiss\u00E3o de NF-e avulsa pelo Fisco";
    TipoProcessoEmissaoNfeEnum["avulsaContribuinteComSeuCertificadoDigitalAtravesSiteFisco"] = "2 - Emiss\u00E3o de NF-e avulsa, contri. com cert. site do Fisco";
    TipoProcessoEmissaoNfeEnum["contribuinteComAaplicativoFornecidoPeloFisco"] = "3 - Emiss\u00E3o de NF-e, contribuinte pelo aplicativo do Fisco";
})(TipoProcessoEmissaoNfeEnum || (TipoProcessoEmissaoNfeEnum = {}));
exports.default = TipoProcessoEmissaoNfeEnum;
