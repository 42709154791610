import { IRegraCalculoComissaoValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagRepresentante from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRepresentante';
import InputAutoCompleteTagCliente from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteTagRegraEscolhaTabelaPreco from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRegraEscolhaTabelaPreco';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';

type IProps = IMain<IRegraCalculoComissaoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialComissaoRegraCalculoComissao: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (
      formRef.current?.getFieldValue(
        'listaRegraCalculoComissaoPessoaRepresentante'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraCalculoComissaoPessoa')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraCalculoComissaoGrupoProdutoServico'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraCalculoComissaoProduto')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraCalculoComissaoRegraEscolhaTabelaPreco'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('percentualDescontoInicial')) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('percentualDescontoFinal')) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHidden name="ordem" />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                ref={inputRefFocus}
                label="Data Vigência Inicial"
                name="dataInicioVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraCalculoComissaoEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagRepresentante
                label="Representantes"
                placeholder="Representantes"
                name="listaRegraCalculoComissaoPessoaRepresentante"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagCliente
                label="Clientes"
                name="listaRegraCalculoComissaoPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagGrupoProdutoServico
                label="Grupos de Produto"
                name="listaRegraCalculoComissaoGrupoProdutoServico"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                name="listaRegraCalculoComissaoProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagRegraEscolhaTabelaPreco
                label="Regra de Escolha da Tabela de Preço"
                name="listaRegraCalculoComissaoRegraEscolhaTabelaPreco"
                filtrarPorRegras={false}
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Percentual Desconto Inicial"
                placeholder="Percentual Desconto Inicial"
                name="percentualDescontoInicial"
                onBlur={handleAtualizarQuantidadeCriterios}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Percentual Desconto Final"
                placeholder="Percentual Desconto Final"
                name="percentualDescontoFinal"
                onBlur={handleAtualizarQuantidadeCriterios}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputDecimalNulavel
                label="Percentual de Comissão"
                placeholder="Percentual de Comissão"
                name="regraCalculoComissaoResultado.percentualComissao"
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
