import React, { useEffect, useCallback, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IFormaPagamentoValoresAlterar } from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import GeralTab from '../GeralTab';
import EcommerceTab from '../EcommerceTab';

type IProps = IMain<IFormaPagamentoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroGeralFormaPagamento: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
  } = UseForm();

  const { setDisableExcluir, setDisableDuplicar } = UseButtonsSubHeader();

  const idDetalheRegistro = getIdDetalheRegistro();
  const inputRefDescricao = useRef<HTMLInputElement>(null);

  const isPadrao = formRef.current?.getFieldValue('padraoSistema');

  useEffect(() => {
    setDisableExcluir(isPadrao);
    setDisableDuplicar(isPadrao);
  }, [setDisableDuplicar, setDisableExcluir, isPadrao]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    if (inputRefDescricao.current) inputRefDescricao.current.focus();
  }, []);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    formRef.current?.setFieldDisabled('meioPagamento', isPadrao);

    formRef.current?.setFieldDisabled('tipoTituloSped', isPadrao);
  }, [formRef, isPadrao]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <Tabs id={v4()} defaultActiveKey="geral">
        <Tab eventKey="geral" title="Geral">
          <GeralTab />
        </Tab>

        <Tab eventKey="eCommerce" title="E-commerce">
          <EcommerceTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
