import styled from 'styled-components';

const BtnCloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: flex-start;
  background: transparent;
  color: #fff;
  font-size: 30px;

  height: 30px;
  width: 30px;
  &:hover {
    background: red;
    border-radius: 100%;
  }

  svg {
    font-size: 30px;
  }
`;

export default BtnCloseButton;
