import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IEmpresaTributacaoVigenciaAliquotaCreditoSnModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const EmpresaTributacaoVigenciaAliquotaCreditoSnDetalhe: React.FC<
  IEmpresaTributacaoVigenciaAliquotaCreditoSnModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Código ou Referência para Pesquisa"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              <Divisor>
                <Row>
                  <Col md={6} sm={12} style={{ marginTop: 8 }}>
                    <InputDate
                      ref={inputRefFocus}
                      label="Vigente a partir de:"
                      name="dataInicioVigencia"
                    />
                  </Col>
                  <Col md={6} sm={12} style={{ marginTop: 8 }}>
                    <InputDecimalNulavel
                      label="Alíq. Aprov. Créd. ICMS SN."
                      name="aliquotaCreditoIcmsSimplesNacional"
                      placeholder="Alíq. Aprov. Créd. ICMS SN."
                      casasInteiras={3}
                      casasDecimais={2}
                    />
                  </Col>
                </Row>
              </Divisor>
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default EmpresaTributacaoVigenciaAliquotaCreditoSnDetalhe;
