import {
  BooleanEnum,
  FormatarEnum,
  SituacaoNotaFiscalSaidaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select/index';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import Input from '../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import InputAutoCompleteTagSerie from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputAutoCompleteTagTransportador from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagTransportador';
import InputAutoCompleteTipoNota from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataEmissaoRef = useRef<any>(null);
  const periodoDataSaidaRef = useRef<any>(null);

  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const situacao = parametros.pesquisaAvancada?.situacao;
      if (!situacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: situacao,
        });
      }

      const numeroNota = parametros.pesquisaAvancada?.numeroNota;
      if (numeroNota) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Nota',
          valor: numeroNota,
        });
      }

      const listaSerie = parametros.pesquisaAvancada?.listaSerie;
      if (listaSerie.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Série',
          valor: listaSerie.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCliente = parametros.pesquisaAvancada?.listaCliente;
      if (listaCliente.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Clientes',
          valor: listaCliente.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.nomeRazaoSocial}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaTransportador =
        parametros.pesquisaAvancada?.listaTransportador;
      if (listaTransportador.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Transportadoras',
          valor: listaTransportador.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.nomeRazaoSocial}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaCidade = parametros.pesquisaAvancada?.listaCidade;
      if (listaCidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cidades',
          valor: listaCidade.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.cidadeUf}; `;
            return acumulador;
          }, ''),
        });
      }

      const pedidoCompraNumero =
        parametros.pesquisaAvancada?.pedidoCompraNumero;
      if (pedidoCompraNumero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Núm. do Pedido de Compra',
          valor: pedidoCompraNumero,
        });
      }
      const pedidoCompraNumeroItem =
        parametros.pesquisaAvancada?.pedidoCompraNumeroItem;
      if (pedidoCompraNumeroItem) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Item do Pedido de Compra',
          valor: pedidoCompraNumeroItem,
        });
      }

      if (periodoDataEmissaoRef.current) {
        const periodoDataEmissao = periodoDataEmissaoRef.current.getPeriodo();
        if (periodoDataEmissao) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Emissão',
            valor: periodoDataEmissao,
          });
        }
      }

      if (periodoDataSaidaRef.current) {
        const periodoDataSaida = periodoDataSaidaRef.current.getPeriodo();
        if (periodoDataSaida) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Saída',
            valor: periodoDataSaida,
          });
        }
      }

      const vendaPdv = parametros.pesquisaAvancada?.vendaPdv;
      if (!vendaPdv) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Venda PDV',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Venda PDV',
          valor: vendaPdv === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        vendaPdv: false,
        tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={4} md={12}>
                <Select
                  name="situacao"
                  label="Situação"
                  options={FormatarEnum({
                    enumObj: SituacaoNotaFiscalSaidaEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={4} md={12}>
                <Input
                  name="numeroNota"
                  label="Número da Nota"
                  placeholder="Número da Nota"
                />
              </Col>

              <Col lg={4} md={12}>
                <InputAutoCompleteTagSerie label="Serie" name="listaSerie" />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagCliente
                  label="Clientes"
                  name="listaCliente"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagProduto
                  name="listaProduto"
                  label="Produtos"
                  onChangeListaItemAtual={handleChangeProduto}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Filtro Código/Descrição"
                  name="tipoBuscaCodigoDescricao"
                  options={FormatarEnum({
                    enumObj: TipoBuscaCodigoDescricaoEnum,
                    nullavel: false,
                    todos: false,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Input name="codigo" label="Código" placeholder="Código" />
              </Col>

              <Col lg={6} md={12}>
                <Input
                  name="descricao"
                  label="Descrição"
                  placeholder="Descrição"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTipoNota
                  label="Tipo de Nota"
                  name="tipoNota"
                  placeholder="Tipo de Nota"
                  nomeObjeto="tipoNota"
                  onChangeItemAtual={() => {
                    refresh();
                  }}
                  permitirAdicionar={false}
                  filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.saida}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTipoNotaMotivo
                  label="Motivo"
                  name="tipoNotaMotivo"
                  placeholder="Motivo"
                  nomeObjeto="tipoNotaMotivo"
                  disabled={!formRef.current?.getFieldValue('tipoNota')}
                  idTipoNota={formRef.current?.getFieldValue('tipoNota')}
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagTransportador
                  name="listaTransportador"
                  label="Transportadoras"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCidade
                  label="Cidades"
                  name="listaCidade"
                />
              </Col>
              <Col lg={6} md={12}>
                <Input
                  name="pedidoCompraNumero"
                  label="Núm. do Pedido de Compra"
                  maxLength={15}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputInteiroNulavel
                  name="pedidoCompraNumeroItem"
                  label="Item do Pedido de Compra"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Dt. Saída Inicial"
                  labelDataFinal="Dt. Saída Final"
                  nomeDataInicial="dataSaidaInicial"
                  nomeDataFinal="dataSaidaFinal"
                  ref={periodoDataSaidaRef}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="vendaPdv"
                  label="Venda PDV"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
