import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Dropdown, Row, Tab, Table, Tabs } from 'react-bootstrap';
import * as Yup from 'yup';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import {
  DestinadoEnum,
  FormatarEnum,
  TipoEntradaSaidaOperacaoFiscalEnum,
} from '@elogestor/util';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputLabel from '../../../../../../../Componentes/Inputs/InputLabel';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputAutoCompleteServico from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteServico';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import InputHidden from '../../../../../../../Componentes/Inputs/InputHidden';
import Select from '../../../../../../../Componentes/Select';

export interface IImportacaoCteTerceiroEtapa1ServicoItem {
  id: string;
  codigo: string;
  descricao: string;
  valor: number;
}

export interface IImportacaoCteTerceiroEtapa1Ref {
  handleSetarListaServico(listaValor: any[]): void;
  handleObterListaServico(): IImportacaoCteTerceiroEtapa1ServicoItem[];
  handleValidar(): Promise<boolean>;
}

interface IImportacaoCteTerceiroEtapa1Props {
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoCteTerceiroEtapa1: React.ForwardRefRenderFunction<
  IImportacaoCteTerceiroEtapa1Ref,
  IImportacaoCteTerceiroEtapa1Props
> = ({ formRef }, ref) => {
  const [listaServico, setListaServico] = useState<
    IImportacaoCteTerceiroEtapa1ServicoItem[]
  >([]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const data = formRef.current?.getData() as any;
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        conhecimentoTransporteTerceiroImpostoIcmsFiscal: Yup.object().shape({
          cfop: Yup.string().nullable().required('CFOP é obrigatória!'),

          // conhecimentoTransporteTerceiroImpostoIcmsFiscalSn: Yup.object().shape(
          //   {
          //     idSituacaoTributaria: Yup.string()
          //       .nullable()
          //       .required('CST é obrigatória!'),
          //   }
          // ),

          // conhecimentoTransporteTerceiroImpostoIcmsFiscalSnSub:
          //   Yup.object().shape({
          //     idSituacaoTributaria: Yup.string()
          //       .nullable()
          //       .required('CST é obrigatória!'),
          //   }),

          // conhecimentoTransporteTerceiroImpostoIcmsFiscalReal:
          //   Yup.object().shape({
          //     idSituacaoTributaria: Yup.string()
          //       .nullable()
          //       .required('CST é obrigatória!'),
          //   }),

          // conhecimentoTransporteTerceiroImpostoIcmsFiscalPresu:
          //   Yup.object().shape({
          //     idSituacaoTributaria: Yup.string()
          //       .nullable()
          //       .required('CST é obrigatória!'),
          //   }),
        }),

        destinado: Yup.mixed()
          .nullable()
          .test({
            message: 'Destinado é obrigatório!',
            test: () => {
              return data.destinado;
            },
          }),

        listaServico: Yup.mixed()
          .nullable()
          .test({
            message:
              'A soma do valor dos Serviços deve ser igual ao Valor Total dos Serviços!',
            test: () => {
              const somaServicos = listaServico.reduce(
                (acumulador: number, current: any) =>
                  acumulador + current.valor,
                0
              );

              if (Number(somaServicos.toFixed(2)) !== data.valorTotalServico) {
                return false;
              }

              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errosValidacao = GetValidationErrors(error as any);
      formRef.current?.setErrors(errosValidacao);
      return false;
    }
  }, [formRef, listaServico]);

  const handleSetarListaServico = useCallback((listaValor: any[]): void => {
    setListaServico(listaValor);
  }, []);

  const handleObterListaServico =
    useCallback((): IImportacaoCteTerceiroEtapa1ServicoItem[] => {
      return listaServico;
    }, [listaServico]);

  useImperativeHandle(ref, () => {
    return {
      handleSetarListaServico,
      handleObterListaServico,
      handleValidar,
    };
  });

  const handleValidarAdicionar = useCallback(async (): Promise<boolean> => {
    try {
      const data = formRef.current?.getData() as any;
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idServicoAdicionar: Yup.string()
          .nullable()
          .required('O Serviço é obrigatório!'),

        valorAdicionar: Yup.mixed()
          .nullable()
          .test({
            message: 'O Valor Serviço é obrigatório!',
            test: () => {
              if (data?.valorAdicionar === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef]);

  const handleAdicionarServico = useCallback(async (): Promise<void> => {
    if (!(await handleValidarAdicionar())) return;

    const servico =
      formRef.current?.getFieldValueNomeObjeto('servicoAdicionar');
    const valor = formRef.current?.getFieldValue('valorAdicionar');

    const novaListaServico = [...listaServico, { ...servico, valor }];

    setListaServico(novaListaServico);

    formRef.current?.clearField('servicoAdicionar');
    formRef.current?.clearField('valorAdicionar');

    const totalListaServico = listaServico.reduce(
      (acumulador: number, item: IImportacaoCteTerceiroEtapa1ServicoItem) => {
        acumulador += Number(item.valor);
        return acumulador;
      },
      0
    );

    const valorTotalServico =
      formRef.current?.getFieldValue('valorTotalServico');

    const valorFinal =
      Number(valorTotalServico) -
      Number(totalListaServico + valor).Arredondar(2);

    if (valorFinal > 0)
      formRef.current?.setFieldValue('valorAdicionar', valorFinal);
  }, [formRef, handleValidarAdicionar, listaServico]);

  const handleExcluirServico = useCallback(
    (indice: number): void => {
      const novaListaServico = listaServico.filter(
        (_, index) => index !== indice
      );
      setListaServico(novaListaServico);

      const totalListaServico = novaListaServico.reduce(
        (acumulador: number, item: IImportacaoCteTerceiroEtapa1ServicoItem) => {
          acumulador += Number(item.valor);
          return acumulador;
        },
        0
      );

      const valorTotalServico =
        formRef.current?.getFieldValue('valorTotalServico');

      const valorFinal =
        Number(valorTotalServico) - Number(totalListaServico).Arredondar(2);

      if (valorFinal > 0)
        formRef.current?.setFieldValue('valorAdicionar', valorFinal);
    },
    [formRef, listaServico]
  );

  const tipoMercado = InputHidden({
    name: 'tipoMercado',
  });

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <Divisor>
        <Row>
          <Col lg={2}>
            <InputDate name="dataLancamento" label="Data Lançamento" />
          </Col>
          <Col lg={2}>
            <InputDate
              name="dataAquisicaoPrestacaoServico"
              label="Dt. Aq./Prest. Serviço"
            />
          </Col>
          <Col lg={5}>
            <InputLabel name="chaveAcesso" label="Chave de Acesso" />
          </Col>
          <Col lg={3}>
            <InputLabel
              name="valorTotalServico"
              label="Total Serviço"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr();
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row style={{ alignItems: 'flex-end' }}>
          <Col lg={8}>
            <InputAutoCompleteServico
              name="idServicoAdicionar"
              nomeObjeto="servicoAdicionar"
              label="Serviço"
              placeholder="Serviço"
            />
          </Col>
          <Col lg={2}>
            <InputDecimal name="valorAdicionar" label="Valor Serviço" />
          </Col>
          <Col lg={2}>
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionarServico}
              className="btn-padrao btn-verde-claro btn-adicionar"
            >
              <IoMdAdd />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </BtnPadraoButton>
          </Col>
        </Row>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '70%' }}>
                  Serviço
                </th>
                <th className="lista-valor" style={{ width: '30%' }}>
                  Valor Serviço
                </th>
                <th className="lista-texto">Ações</th>
              </tr>
            </thead>

            <tbody>
              {listaServico?.map((servico, index) => (
                <tr key={v4()}>
                  <td className="lista-texto">{servico.descricao}</td>
                  <td className="lista-valor">
                    {servico.valor.FormatarParaPtBr()}
                  </td>
                  <td className="tdButton lista-valor">
                    <div>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={async () => {
                            handleExcluirServico(index);
                          }}
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>

      <Divisor>
        <Row>
          <Col lg={4}>
            <Select
              name="destinado"
              label="Destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
              })}
            />
          </Col>
          <Col lg={8}>
            <InputAutoCompleteOperacaoFiscal
              name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.cfop"
              label="CFOP"
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.entrada}
              tipoMercado={tipoMercado}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default forwardRef(ImportacaoCteTerceiroEtapa1);
