import {
  BooleanEnum,
  FormatarEnum,
  SituacaoAutorizacaoEnum,
  SituacaoImportacaoEnum,
  SituacaoManifestacaoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../../Componentes/Select';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataEmissaoRef = useRef<any>(null);
  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const nomeRazaoSocial = parametros.pesquisaAvancada?.nomeRazaoSocial;
      if (nomeRazaoSocial) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Nome Razão Social',
          valor: nomeRazaoSocial,
        });
      }

      const cnpjCpf = parametros.pesquisaAvancada?.cnpjCpf;
      if (cnpjCpf) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'CNPJ/CPF',
          valor: cnpjCpf,
        });
      }

      const situacaoAutorizacao =
        parametros.pesquisaAvancada?.situacaoAutorizacao;
      if (!situacaoAutorizacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Autorização',
          valor: situacaoAutorizacao,
        });
      }

      const situacaoManifestacao =
        parametros.pesquisaAvancada?.situacaoManifestacao;
      if (!situacaoManifestacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Manifestação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Manifestação',
          valor: situacaoManifestacao,
        });
      }

      const situacaoImportacao =
        parametros.pesquisaAvancada?.situacaoImportacao;
      if (!situacaoImportacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Importação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação Importação',
          valor: situacaoImportacao,
        });
      }

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const serie = parametros.pesquisaAvancada?.serie;
      if (serie) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Série',
          valor: serie,
        });
      }

      const chaveAcesso = parametros.pesquisaAvancada?.chaveAcesso;
      if (chaveAcesso) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Chave Acesso',
          valor: chaveAcesso,
        });
      }

      const periodoDataEmissao = periodoDataEmissaoRef.current?.getPeriodo();
      if (periodoDataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Emissão',
          valor: periodoDataEmissao,
        });
      }

      const valorInicial = parametros.pesquisaAvancada?.valorInicial;
      if (valorInicial) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Valor Inicial',
          valor: valorInicial,
        });
      }

      const valorFinal = parametros.pesquisaAvancada?.valorFinal;
      if (valorFinal) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Valor Final',
          valor: valorFinal,
        });
      }

      const somenteServicosTomados =
        parametros.pesquisaAvancada?.somenteServicosTomados;
      if (!somenteServicosTomados) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pesquisar Somente CT-e de Serviços Tomados',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pesquisar Somente CT-e de Serviços Tomados',
          valor: somenteServicosTomados === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        situacaoImportacao: SituacaoImportacaoEnum.importar,
        somenteServicosTomados: 'true',
        somenteNotasQueEmpresaDestinatario: 'true',
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Input
                  name="nomeRazaoSocial"
                  label="Nome Razao Social"
                  type="text"
                  maxLength={60}
                />
              </Col>
              <Col lg={6} md={12}>
                <Input
                  name="cnpjCpf"
                  label="CNPJ/CPF"
                  type="text"
                  maxLength={14}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="situacaoAutorizacao"
                  label="Situação Autorização"
                  options={FormatarEnum({
                    enumObj: SituacaoAutorizacaoEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="situacaoManifestacao"
                  label="Situação Manifestação"
                  options={FormatarEnum({
                    enumObj: SituacaoManifestacaoEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="situacaoImportacao"
                  label="Situação Importação"
                  options={FormatarEnum({
                    enumObj: SituacaoImportacaoEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={3} md={6}>
                <InputInteiroNulavel name="numero" label="Número" />
              </Col>
              <Col lg={3} md={6}>
                <InputInteiroNulavel name="serie" label="Série" />
              </Col>

              <Col lg={12} md={12}>
                <Input
                  name="chaveAcesso"
                  label="Chave de acesso"
                  maxLength={44}
                  type="text"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputPeriodoData
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
              <Col lg={3} md={6}>
                <InputDecimalNulavel
                  name="valorInicial"
                  label="Valor Inicial"
                />
              </Col>
              <Col lg={3} md={6}>
                <InputDecimalNulavel name="valorFinal" label="Valor Final" />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="somenteServicosTomados"
                  label="Somente CT-e de Serviços Tomados"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="somenteNotasQueEmpresaDestinatario"
                  label="Somente Notas que a Empresa é Destinatário"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
