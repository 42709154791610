import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/sisdevin-estoque-movimento';

interface ISisdevinEstoqueMovimentoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ISisdevinEstoqueMovimentoComunicadorShow {
  id: string;
  params: any;
}

interface ISisdevinEstoqueMovimentoComunicadorUpdate {
  id: string;
  params: any;
}

interface ISisdevinEstoqueMovimentoComunicadorDelete {
  id: string;
  params: any;
}

class SisdevinEstoqueMovimentoComunicador {
  public async index(
    params: ISisdevinEstoqueMovimentoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ISisdevinEstoqueMovimentoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }

  public async update(
    params: ISisdevinEstoqueMovimentoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: ISisdevinEstoqueMovimentoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new SisdevinEstoqueMovimentoComunicador();
