import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-portador';

interface IRegraEscolhaPortadorComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaPortadorComunicadorShow {
  id: string;
}

interface IRegraEscolhaPortadorComunicadorStore {
  params: any;
}

interface IRegraEscolhaPortadorComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaPortadorComunicadorDeletar {
  id: string;
}

class RegraEscolhaPortadorComunicador {
  public async index(
    params: IRegraEscolhaPortadorComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaPortadorComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaPortadorComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaPortadorComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaPortadorComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaPortadorComunicador();
