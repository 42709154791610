import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/upload-pdf';

interface IConhecimentoTransporteTerceiroUploadPdfComunicadorUpdate {
  params: any;
}

class ConhecimentoTransporteTerceiroUploadPdfComunicador {
  public async update(
    params: IConhecimentoTransporteTerceiroUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroUploadPdfComunicador();
