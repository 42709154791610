import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TipoTomadorEnum } from '@elogestor/util';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompletePessoa from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import { UseParticipantes } from '../Hooks/ParticipantesHook';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IPrincipalTab {
  tipoTomador: TipoTomadorEnum | null;
  handleCarregarTipoNotaMotivo: () => Promise<void>;
  handleCarregarConfiguracaoTributaria: () => Promise<void>;
}

const PrincipalTab: React.FC<IPrincipalTab> = ({
  tipoTomador,
  handleCarregarTipoNotaMotivo,
  handleCarregarConfiguracaoTributaria,
}) => {
  const { handleAtualizarDadosParticipantesConformeCadastro } =
    UseParticipantes();
  const { setLoading } = UseForm();

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaRemetente"
              nomeObjeto="pessoaRemetente"
              label="Remetente"
              placeholder="Remetente"
              informacaoAdicional
              onChangeItemAtualAposCarregarSemClear={async ({ itemAtual }) => {
                if (!itemAtual) return;
                setLoading(true);
                await handleCarregarTipoNotaMotivo();
                await handleCarregarConfiguracaoTributaria();
                setLoading(false);
              }}
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('remetente');
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaExpedidor"
              nomeObjeto="pessoaExpedidor"
              label="Expedidor"
              placeholder="Expedidor"
              informacaoAdicional
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('expedidor');
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaRecebedor"
              nomeObjeto="pessoaRecebedor"
              label="Recebedor"
              placeholder="Recebedor"
              informacaoAdicional
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('recebedor');
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaDestinatario"
              nomeObjeto="pessoaDestinatario"
              label="Destinatário"
              placeholder="Destinatário"
              informacaoAdicional
              onChangeItemAtualAposCarregarSemClear={async ({ itemAtual }) => {
                if (!itemAtual) return;
                setLoading(true);
                await handleCarregarTipoNotaMotivo();
                await handleCarregarConfiguracaoTributaria();
                setLoading(false);
              }}
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro(
                  'destinatario'
                );
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            display: `${
              tipoTomador === TipoTomadorEnum.outros ? 'flex' : 'none'
            } `,
          }}
        >
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaTomadorOutro"
              nomeObjeto="pessoaTomadorOutro"
              label="Tomador Outros"
              placeholder="Tomador Outros"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro(
                  'tomadorOutro'
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputSwitch
              name="retira"
              label="Recebedor retira no Aeroporto, Filial, Porto ou Estação de Destino"
              ativo="Sim"
              inativo="Não"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PrincipalTab;
