"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RegistroH005MotivoInventario;
(function (RegistroH005MotivoInventario) {
    RegistroH005MotivoInventario["finalPeriodo"] = "01 - No final do per\u00EDodo";
    RegistroH005MotivoInventario["mudancaFormaTributacaoMercadoria"] = "02 - Na mudan\u00E7a de forma de tributa\u00E7\u00E3o da mercadoria (ICMS)";
    RegistroH005MotivoInventario["solicitacaoBaixaCadastral"] = "03 - Na solicita\u00E7\u00E3o da baixa cadastral, paraliza\u00E7\u00E3o tempor\u00E1ria e outras situa\u00E7\u00F5es";
    RegistroH005MotivoInventario["alteracaoRegimePagamentoCondicaoContribuinte"] = "04 - Na altera\u00E7\u00E3o de regime de pagamento - condi\u00E7\u00E3o do contribuinte";
    RegistroH005MotivoInventario["solicitacaoFiscalizacao"] = "05 - Por determina\u00E7\u00E3o dos fiscos";
    RegistroH005MotivoInventario["controleMercadoriasSujeitasAoRegimeSubstituicaoTributario"] = "06 - Para controle das mercadorias sujeitas ao regime de substitui\u00E7\u00E3o tribut\u00E1ria - restitui\u00E7\u00E3o/ ressarcimento/complementa\u00E7\u00E3o";
})(RegistroH005MotivoInventario || (RegistroH005MotivoInventario = {}));
exports.default = RegistroH005MotivoInventario;
