import React from 'react';
import ListaDetalheNotaReferenciada from './ListaDetalheNotaReferenciada/Lista';
import Divisor from '../../../../../../Componentes/Divisor';
import ConhecimentoTransporteTerceiroNotaReferenciadaHook from './ListaDetalheNotaReferenciada/Hooks';

interface INotaReferenciadaTab {
  bloquearCamposPorImportacao: boolean;
}

const NotaReferenciadaTab: React.FC<INotaReferenciadaTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <Divisor>
      <ConhecimentoTransporteTerceiroNotaReferenciadaHook>
        <ListaDetalheNotaReferenciada
          name="listaConhecimentoTransporteNotaReferenciada"
          bloquearCamposPorImportacao={bloquearCamposPorImportacao}
        />
      </ConhecimentoTransporteTerceiroNotaReferenciadaHook>
    </Divisor>
  );
};

export default NotaReferenciadaTab;
