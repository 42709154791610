/* eslint-disable default-case */
import React, {
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  EstoqueIndicadorTipoEnum,
  IContaValoresAlterar,
  IImportacaoNfeTerceiroEtapa1ItemLista,
  IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista,
  IImportacaoNfeTerceiroEtapa2ItemLista,
  IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista,
  IImportacaoNfeTerceiroEtapa3ItemDesmembradoLista,
  IImportacaoNfeTerceiroEtapa3ItemLista,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import UseStep from '../../../../../../Hooks/UseStep';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { NfeInfoBox, Step, StepBox, StepContainer, StepNome } from './styles';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import BtnCloseButton from '../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import ImportacaoXmlTerceiroImportarComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroImportarComunicador';
import ImportacaoNfeTerceiroEtapa1, {
  IImportacaoNfeTerceiroEtapa1Ref,
} from './Etapa1';
import ImportacaoNfeTerceiroEtapa2, {
  IImportacaoNfeTerceiroEtapa2Ref,
} from './Etapa2';
import ImportacaoNfeTerceiroEtapa3, {
  IImportacaoNfeTerceiroEtapa3Ref,
} from './Etapa3';
import ImportacaoNfeTerceiroEtapa4, {
  IImportacaoNfeTerceiroEtapa4Ref,
} from './Etapa4';
import ImportacaoXmlTerceiroNfeSalvarImportadoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeSalvarImportadoComunicador';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TratarInfo from '../../../../../../Util/Info/TratarInfo';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import ImportacaoXmlTerceiroNfeObterItemFiscalComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterItemFiscalComunicador';
import ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ImportacaoXmlTerceiroNfeObterFinanceiroComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterFinanceiroComunicador';

export interface IAbrirImportacaoNfeTerceiro {
  idImportacaoXmlAutorizacao: string;
}

interface IImportacaoNfeTerceiroContext {
  abrirImportacaoNfeTerceiro({
    idImportacaoXmlAutorizacao,
  }: IAbrirImportacaoNfeTerceiro): Promise<any>;
  fecharImportacaoNfeTerceiro(): void;
}

const ImportacaoNfeTerceiroContext =
  createContext<IImportacaoNfeTerceiroContext>(
    {} as IImportacaoNfeTerceiroContext
  );

const ImportacaoNfeTerceiroHook: React.FC<IPadraoProps> = ({ children }) => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { proximoStep, stepAnterior, step, setStep } = UseStep(3);

  const resolveRef = useRef<any>();

  const [idImportacaoXml, setIdImportacaoXml] = useState('');
  const [aberto, setAberto] = useState(false);

  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Etapa 1 - Itens

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref = useRef<IImportacaoNfeTerceiroEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(
    async (idImportacaoXmlAutorizacao: string) => {
      try {
        setLoading(true);

        const response = await ImportacaoXmlTerceiroImportarComunicador.update({
          id: idImportacaoXmlAutorizacao,
        });

        setNumero(response.numero);
        setSerie(response.serie);

        etapa1Ref.current?.handleSetarListaItem(response.listaItem);

        await formRefEtapa1.current?.setDataInicial({
          ...response,
          listaEtapa1: response.listaItem,
        });

        await Sleep(50);
        await formRefEtapa1.current?.setDataInicial({
          ...response,
          listaEtapa1: response.listaItem,
        });

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 1 - Itens

  // #region Etapa 2 - Fiscal

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref = useRef<IImportacaoNfeTerceiroEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa1 =
        etapa1Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa1ItemLista[];

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa2ItemLista[];

      const listaItemEtapa2Novo: IImportacaoNfeTerceiroEtapa2ItemLista[] = [];

      if (listaItemEtapa2.length > 0) {
        for (let i = 0; i < listaItemEtapa1.length; i++) {
          const itemEtapa1 = listaItemEtapa1[i];
          const itemEtapa2 = listaItemEtapa2[i];

          const listaItemDesmembradoEtapa2Novo: IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista[] =
            [];

          for (let j = 0; j < itemEtapa1.listaItemDesmembrado.length; j++) {
            const itemDesmembradoEtapa1 = itemEtapa1.listaItemDesmembrado[j];
            const itemDesmembradoEtapa2 = itemEtapa2.listaItemDesmembrado[j];

            const produtoCodigoDescricao = `${itemDesmembradoEtapa1.produtoCodigo} - ${itemDesmembradoEtapa1.produto?.descricao}`;

            if (
              itemDesmembradoEtapa2 &&
              itemDesmembradoEtapa1.produto?.id ===
                itemDesmembradoEtapa2.produto?.id &&
              produtoCodigoDescricao ===
                itemDesmembradoEtapa2.produtoCodigoDescricao &&
              itemDesmembradoEtapa1.codigoBarras ===
                itemDesmembradoEtapa2.codigoBarras
            ) {
              listaItemDesmembradoEtapa2Novo.push({
                ...itemDesmembradoEtapa2,
                quantidadeEntradaEstoque:
                  itemDesmembradoEtapa1.quantidadeEntradaEstoque,
              });
            } else {
              const itemDesmembradoEtapa2Novo: IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista =
                {
                  ...itemDesmembradoEtapa1,
                  produtoCodigoDescricao,
                  ncm: itemEtapa1.itemNf.ncmNf,
                  origemIcms: itemEtapa1.itemNf.origemIcmsNf,
                  atualizarNcm: false,
                  atualizarOrigemIcms: !!(
                    itemEtapa1.itemNf.origemIcmsNf.codigo === 1 ||
                    itemEtapa1.itemNf.origemIcmsNf.codigo === 6
                  ),
                  calcularDiferencialAliquota: false,
                };

              if (
                itemDesmembradoEtapa1.produto &&
                itemDesmembradoEtapa1.produto?.id
              ) {
                const response =
                  await ImportacaoXmlTerceiroNfeObterItemFiscalComunicador.index(
                    {
                      params: {
                        idProduto: String(itemDesmembradoEtapa1.produto?.id),
                        cfop: itemEtapa1.itemNf.cfop,
                      },
                    }
                  );

                itemDesmembradoEtapa2Novo.spedTipoProduto =
                  response.produto.produtoFiscal.spedTipoProduto;
                itemDesmembradoEtapa2Novo.operacaoFiscal =
                  response.produto.produtoFiscal.operacaoFiscal;
                itemDesmembradoEtapa2Novo.ncm =
                  response.produto.produtoFiscal.ncm;
                itemDesmembradoEtapa2Novo.origemIcms =
                  response.produto.produtoFiscal.origemIcms;
                itemDesmembradoEtapa2Novo.sujeitoASt =
                  response.produto.produtoFiscal.sujeitoASt;

                if (
                  itemEtapa1.itemNf.ncmNf?.id !==
                  response.produto.produtoFiscal.ncm.id
                ) {
                  itemDesmembradoEtapa2Novo.atualizarNcm = true;
                }

                if (
                  (response.produto.produtoFiscal.origemIcms.codigo === 1 &&
                    itemEtapa1.itemNf.origemIcmsNf.codigo === 1) ||
                  (response.produto.produtoFiscal.origemIcms.codigo === 6 &&
                    itemEtapa1.itemNf.origemIcmsNf.codigo === 6)
                ) {
                  itemDesmembradoEtapa2Novo.atualizarOrigemIcms = true;
                }

                if (
                  itemEtapa1.itemNf.origemIcmsNf?.id !==
                  response.produto.produtoFiscal.origemIcms.id
                ) {
                  itemDesmembradoEtapa2Novo.atualizarOrigemIcms = true;
                }
              }

              listaItemDesmembradoEtapa2Novo.push(itemDesmembradoEtapa2Novo);
            }
          }

          const itemValorSelecionado = itemEtapa1.itemNf
            .entradaPeloProdutoTributado
            ? itemEtapa1.itemNf.itemTributado
            : itemEtapa1.itemNf.itemComercial;

          listaItemEtapa2Novo.push({
            pessoaNf: { ...itemEtapa1.pessoaNf },
            itemNf: {
              ...itemEtapa1.itemNf,
              produtoCodigo: itemValorSelecionado?.produto.codigo ?? '',
              produtoDescricao: itemValorSelecionado?.produto.descricao ?? '',
            },
            listaItemDesmembrado: listaItemDesmembradoEtapa2Novo,
          });
        }
      } else {
        for (let i = 0; i < listaItemEtapa1.length; i++) {
          const itemEtapa1 = listaItemEtapa1[i];

          const listaItemDesmembradoEtapa2Novo: IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista[] =
            [];

          for (let j = 0; j < itemEtapa1.listaItemDesmembrado.length; j++) {
            const itemDesmembradoEtapa1 = itemEtapa1.listaItemDesmembrado[j];

            const itemDesmembradoEtapa2Novo: IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista =
              {
                ...itemDesmembradoEtapa1,
                produtoCodigoDescricao: `${itemDesmembradoEtapa1.produtoCodigo} - ${itemDesmembradoEtapa1.produto?.descricao}`,
                ncm: itemEtapa1.itemNf.ncmNf,
                origemIcms: itemEtapa1.itemNf.origemIcmsNf,
                atualizarNcm: false,
                atualizarOrigemIcms: !!(
                  itemEtapa1.itemNf.origemIcmsNf.codigo === 1 ||
                  itemEtapa1.itemNf.origemIcmsNf.codigo === 6
                ),
                calcularDiferencialAliquota: false,
              };

            if (
              itemDesmembradoEtapa1.produto &&
              itemDesmembradoEtapa1.produto?.id
            ) {
              const response =
                await ImportacaoXmlTerceiroNfeObterItemFiscalComunicador.index({
                  params: {
                    idProduto: String(itemDesmembradoEtapa1.produto?.id),
                    cfop: itemEtapa1.itemNf.cfop,
                  },
                });

              itemDesmembradoEtapa2Novo.spedTipoProduto =
                response.produto.produtoFiscal.spedTipoProduto;
              itemDesmembradoEtapa2Novo.operacaoFiscal =
                response.produto.produtoFiscal.operacaoFiscal;
              itemDesmembradoEtapa2Novo.ncm =
                response.produto.produtoFiscal.ncm;
              itemDesmembradoEtapa2Novo.origemIcms =
                response.produto.produtoFiscal.origemIcms;
              itemDesmembradoEtapa2Novo.sujeitoASt =
                response.produto.produtoFiscal.sujeitoASt;

              if (
                itemEtapa1.itemNf.ncmNf?.id !==
                response.produto.produtoFiscal.ncm.id
              ) {
                itemDesmembradoEtapa2Novo.atualizarNcm = true;
              }

              if (
                (response.produto.produtoFiscal.origemIcms.codigo === 1 &&
                  itemEtapa1.itemNf.origemIcmsNf.codigo === 1) ||
                (response.produto.produtoFiscal.origemIcms.codigo === 6 &&
                  itemEtapa1.itemNf.origemIcmsNf.codigo === 6)
              ) {
                itemDesmembradoEtapa2Novo.atualizarOrigemIcms = true;
              }

              if (
                itemEtapa1.itemNf.origemIcmsNf?.id !==
                response.produto.produtoFiscal.origemIcms.id
              ) {
                itemDesmembradoEtapa2Novo.atualizarOrigemIcms = true;
              }
            }

            listaItemDesmembradoEtapa2Novo.push(itemDesmembradoEtapa2Novo);
          }

          const itemValorSelecionado = itemEtapa1.itemNf
            .entradaPeloProdutoTributado
            ? itemEtapa1.itemNf.itemTributado
            : itemEtapa1.itemNf.itemComercial;

          listaItemEtapa2Novo.push({
            pessoaNf: { ...itemEtapa1.pessoaNf },
            itemNf: {
              ...itemEtapa1.itemNf,
              produtoCodigo: itemValorSelecionado?.produto.codigo ?? '',
              produtoDescricao: itemValorSelecionado?.produto.descricao ?? '',
            },
            listaItemDesmembrado: listaItemDesmembradoEtapa2Novo,
          });
        }
      }

      etapa2Ref.current?.handleSetarListaItem(listaItemEtapa2Novo);

      await formRefEtapa2.current?.setDataInicialSemExecutarEvento({
        listaEtapa2: listaItemEtapa2Novo,
      });
      await Sleep(50);
      await formRefEtapa2.current?.setDataInicialSemExecutarEvento({
        listaEtapa2: listaItemEtapa2Novo,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 2 - Fiscal

  // #region Etapa 3 - Estoque

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref = useRef<IImportacaoNfeTerceiroEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const dataLancamento =
        formRefEtapa1.current?.getFieldValue('dataLancamento');

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa2ItemLista[];
      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa3ItemLista[];

      const listaItemEtapa3Novo: IImportacaoNfeTerceiroEtapa3ItemLista[] = [];

      if (listaItemEtapa3.length > 0) {
        for (let i = 0; i < listaItemEtapa2.length; i++) {
          const itemEtapa2 = listaItemEtapa2[i];
          const itemEtapa3 = listaItemEtapa3[i];

          const listaItemDesmembradoEtapa3Novo: IImportacaoNfeTerceiroEtapa3ItemDesmembradoLista[] =
            [];

          for (let j = 0; j < itemEtapa2.listaItemDesmembrado.length; j++) {
            const itemDesmembradoEtapa2 = itemEtapa2.listaItemDesmembrado[j];
            const itemDesmembradoEtapa3 = itemEtapa3.listaItemDesmembrado[j];

            if (
              itemDesmembradoEtapa3 &&
              itemDesmembradoEtapa2.produto?.id ===
                itemDesmembradoEtapa3.produto?.id &&
              itemDesmembradoEtapa2.produtoCodigoDescricao ===
                itemDesmembradoEtapa3.produtoCodigoDescricao &&
              itemDesmembradoEtapa2.codigoBarras ===
                itemDesmembradoEtapa3.codigoBarras
            ) {
              if (
                itemDesmembradoEtapa3.quantidadeEntradaEstoque !==
                itemDesmembradoEtapa2.quantidadeEntradaEstoque
              ) {
                const itemDesmembradoEtapa3Atualizado = {
                  ...itemDesmembradoEtapa3,
                  quantidadeEntradaEstoque:
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                  estoque: {
                    ...itemDesmembradoEtapa3.estoque,
                    quantidadeEntradaEstoque:
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                  },
                };

                if (
                  itemDesmembradoEtapa3.estoque.listaEstoqueLocalEstoque
                    .length === 1
                ) {
                  itemDesmembradoEtapa3Atualizado.estoque.listaEstoqueLocalEstoque[0].quantidade =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  itemDesmembradoEtapa3Atualizado.estoque.quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;
                }

                if (
                  itemDesmembradoEtapa3Atualizado.estoque
                    .estoqueTipoEntradaSaida ===
                  TipoMovimentoEntradaSaidaEnum.entrada
                ) {
                  const totalEntrada =
                    itemDesmembradoEtapa3.estoque.listaEstoqueLocalEstoque
                      ?.filter(
                        (
                          estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                        ) => {
                          return (
                            estoque.tipoEntradaSaida ===
                            TipoMovimentoEntradaSaidaEnum.entrada
                          );
                        }
                      )
                      .reduce(
                        (
                          acumulador: number,
                          estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                        ) => {
                          acumulador += Number(estoque.quantidade);
                          return acumulador;
                        },
                        0
                      );

                  let estoqueQuantidade =
                    itemDesmembradoEtapa3Atualizado.quantidadeEntradaEstoque -
                    totalEntrada;
                  if (estoqueQuantidade < 0) estoqueQuantidade = 0;

                  itemDesmembradoEtapa3Atualizado.estoque.estoqueQuantidade =
                    estoqueQuantidade;
                } else {
                  const totalSaida =
                    itemDesmembradoEtapa3.estoque.listaEstoqueLocalEstoque
                      ?.filter(
                        (
                          estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                        ) => {
                          return (
                            estoque.tipoEntradaSaida ===
                            TipoMovimentoEntradaSaidaEnum.saida
                          );
                        }
                      )
                      .reduce(
                        (
                          acumulador: number,
                          estoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista
                        ) => {
                          acumulador += Number(estoque.quantidade);
                          return acumulador;
                        },
                        0
                      );

                  let estoqueQuantidade =
                    itemDesmembradoEtapa3Atualizado.quantidadeEntradaEstoque -
                    totalSaida;
                  if (estoqueQuantidade < 0) estoqueQuantidade = 0;

                  itemDesmembradoEtapa3Atualizado.estoque.estoqueQuantidade =
                    estoqueQuantidade;
                }

                listaItemDesmembradoEtapa3Novo.push(
                  itemDesmembradoEtapa3Atualizado
                );
              } else {
                listaItemDesmembradoEtapa3Novo.push(itemDesmembradoEtapa3);
              }
            } else {
              const itemDesmembradoEtapa3Novo: IImportacaoNfeTerceiroEtapa3ItemDesmembradoLista =
                {
                  ...itemDesmembradoEtapa2,
                  estoque: {
                    movimentarEstoque: true,
                    movimentarEstoqueFiscal: false,
                    controlarLote: false,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                    quantidadeEntradaEstoque:
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    porContaOrdemTerceiro: false,

                    quantidadeTotalEstoqueLocalEstoque: 0,
                    estoqueTipoEntradaSaida:
                      TipoMovimentoEntradaSaidaEnum.entrada,
                    estoqueIndicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                    estoqueQuantidade: 0,

                    listaEstoqueLocalEstoque: [],
                  },

                  comercial: {
                    gerarFinanceiro: true,
                  },
                };

              const response =
                await ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador.index(
                  {
                    params: {
                      idPessoa: itemEtapa2.pessoaNf.id,
                      idProduto: itemDesmembradoEtapa2.produto?.id,
                      dataHoraEmissao: dataLancamento,

                      idSpedTipoProduto: String(
                        itemDesmembradoEtapa2.spedTipoProduto?.id
                      ),
                      idOperacaoFiscal: String(
                        itemDesmembradoEtapa2.operacaoFiscal?.id
                      ),
                      idNcm: itemDesmembradoEtapa2.atualizarNcm
                        ? String(itemDesmembradoEtapa2.ncm?.id)
                        : String(itemEtapa2.itemNf.ncmNf?.id),
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    },
                  }
                );

              itemDesmembradoEtapa3Novo.estoque.movimentarEstoque =
                response.produto.estoque.movimentarEstoque;
              itemDesmembradoEtapa3Novo.estoque.movimentarEstoqueFiscal =
                response.produto.estoque.movimentarEstoqueFiscal;
              itemDesmembradoEtapa3Novo.estoque.controlarLote =
                response.produto.estoque.controlarLote;
              itemDesmembradoEtapa3Novo.estoque.indicadorTipoEstoque =
                response.produto.estoque.indicadorTipoEstoque;
              itemDesmembradoEtapa3Novo.estoque.porContaOrdemTerceiro =
                response.produto.estoque.porContaOrdemTerceiro;
              itemDesmembradoEtapa3Novo.comercial.gerarFinanceiro =
                response.produto.comercial.gerarFinanceiro;

              let estoqueTipoEntradaSaida =
                TipoMovimentoEntradaSaidaEnum.entrada;
              let estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
              let estoqueQuantidade =
                itemDesmembradoEtapa2.quantidadeEntradaEstoque;
              let quantidadeTotalEstoqueLocalEstoque = 0;

              const listaEstoqueLocalEstoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista[] =
                [];

              switch (response.produto.estoque.indicadorTipoEstoque) {
                case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
                  estoqueTipoEntradaSaida =
                    TipoMovimentoEntradaSaidaEnum.entrada;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length > 0
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push({
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                    });
                  }
                  break;

                case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
                  estoqueTipoEntradaSaida =
                    TipoMovimentoEntradaSaidaEnum.entrada;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length >
                      0 &&
                    itemEtapa2.pessoaNf.id
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push(
                      {
                        ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                        quantidade:
                          itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                        tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                        indicadorTipoEstoque:
                          EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                      },
                      {
                        ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                        quantidade:
                          itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                        tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                        indicadorTipoEstoque:
                          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                        pessoaTerceiro: itemEtapa2.pessoaNf,
                      }
                    );
                  }
                  break;

                case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
                  estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length >
                      0 &&
                    itemEtapa2.pessoaNf.id
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push({
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    });
                  }
                  break;

                case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
                  estoqueTipoEntradaSaida =
                    TipoMovimentoEntradaSaidaEnum.entrada;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length >
                      0 &&
                    itemEtapa2.pessoaNf.id
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push({
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    });
                  }
                  break;

                case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
                  estoqueTipoEntradaSaida =
                    TipoMovimentoEntradaSaidaEnum.entrada;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length >
                      0 &&
                    itemEtapa2.pessoaNf.id
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push({
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    });
                  }
                  break;

                case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
                  estoqueTipoEntradaSaida =
                    TipoMovimentoEntradaSaidaEnum.entrada;
                  estoqueIndicadorTipoEstoque =
                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

                  if (
                    response.produto.estoque.listaEstoqueLocalEstoque.length >
                      0 &&
                    itemEtapa2.pessoaNf.id
                  ) {
                    estoqueQuantidade = 0;
                    quantidadeTotalEstoqueLocalEstoque =
                      itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                    listaEstoqueLocalEstoque.push(
                      {
                        ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                        quantidade:
                          itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                        tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                        indicadorTipoEstoque:
                          EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                        pessoaTerceiro: itemEtapa2.pessoaNf,
                      },
                      {
                        ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                        quantidade:
                          itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                        tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                        indicadorTipoEstoque:
                          EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                        pessoaTerceiro: itemEtapa2.pessoaNf,
                      }
                    );
                  }
                  break;
              }

              itemDesmembradoEtapa3Novo.estoque.listaEstoqueLocalEstoque =
                listaEstoqueLocalEstoque;
              itemDesmembradoEtapa3Novo.estoque.estoqueTipoEntradaSaida =
                estoqueTipoEntradaSaida;
              itemDesmembradoEtapa3Novo.estoque.estoqueIndicadorTipoEstoque =
                estoqueIndicadorTipoEstoque;
              itemDesmembradoEtapa3Novo.estoque.estoqueQuantidade =
                estoqueQuantidade;
              itemDesmembradoEtapa3Novo.estoque.quantidadeTotalEstoqueLocalEstoque =
                quantidadeTotalEstoqueLocalEstoque;

              listaItemDesmembradoEtapa3Novo.push(itemDesmembradoEtapa3Novo);
            }
          }

          listaItemEtapa3Novo.push({
            ...itemEtapa2,
            dataLancamento,
            listaItemDesmembrado: listaItemDesmembradoEtapa3Novo,
          });
        }
      } else {
        for (let i = 0; i < listaItemEtapa2.length; i++) {
          const itemEtapa2 = listaItemEtapa2[i];

          const listaItemDesmembradoEtapa3: IImportacaoNfeTerceiroEtapa3ItemDesmembradoLista[] =
            [];

          for (let j = 0; j < itemEtapa2.listaItemDesmembrado.length; j++) {
            const itemDesmembradoEtapa2 = itemEtapa2.listaItemDesmembrado[j];

            const itemDesmembradoEtapa3: IImportacaoNfeTerceiroEtapa3ItemDesmembradoLista =
              {
                ...itemDesmembradoEtapa2,
                estoque: {
                  movimentarEstoque: true,
                  movimentarEstoqueFiscal: false,
                  controlarLote: false,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  quantidadeEntradaEstoque:
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                  porContaOrdemTerceiro: false,

                  quantidadeTotalEstoqueLocalEstoque: 0,
                  estoqueTipoEntradaSaida:
                    TipoMovimentoEntradaSaidaEnum.entrada,
                  estoqueIndicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  estoqueQuantidade: 0,

                  listaEstoqueLocalEstoque: [],
                },

                comercial: {
                  gerarFinanceiro: true,
                },
              };

            const response =
              await ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador.index({
                params: {
                  idPessoa: itemEtapa2.pessoaNf.id,
                  idProduto: itemDesmembradoEtapa2.produto?.id,
                  dataHoraEmissao: dataLancamento,

                  idSpedTipoProduto: String(
                    itemDesmembradoEtapa2.spedTipoProduto?.id
                  ),
                  idOperacaoFiscal: String(
                    itemDesmembradoEtapa2.operacaoFiscal?.id
                  ),
                  idNcm: itemDesmembradoEtapa2.atualizarNcm
                    ? String(itemDesmembradoEtapa2.ncm?.id)
                    : String(itemEtapa2.itemNf.ncmNf?.id),
                  quantidade: itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                },
              });

            itemDesmembradoEtapa3.estoque.movimentarEstoque =
              response.produto.estoque.movimentarEstoque;
            itemDesmembradoEtapa3.estoque.movimentarEstoqueFiscal =
              response.produto.estoque.movimentarEstoqueFiscal;
            itemDesmembradoEtapa3.estoque.controlarLote =
              response.produto.estoque.controlarLote;
            itemDesmembradoEtapa3.estoque.indicadorTipoEstoque =
              response.produto.estoque.indicadorTipoEstoque;
            itemDesmembradoEtapa3.estoque.porContaOrdemTerceiro =
              response.produto.estoque.porContaOrdemTerceiro;
            itemDesmembradoEtapa3.comercial.gerarFinanceiro =
              response.produto.comercial.gerarFinanceiro;

            let estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
            let estoqueIndicadorTipoEstoque =
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
            let estoqueQuantidade =
              itemDesmembradoEtapa2.quantidadeEntradaEstoque;
            let quantidadeTotalEstoqueLocalEstoque = 0;

            const listaEstoqueLocalEstoque: IImportacaoNfeTerceiroEtapa3ItemDesmembradoEstoqueLocalEstoqueLista[] =
              [];

            switch (response.produto.estoque.indicadorTipoEstoque) {
              case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length > 0
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push({
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  });
                }
                break;

              case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length >
                    0 &&
                  itemEtapa2.pessoaNf.id
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push(
                    {
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                    },
                    {
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    }
                  );
                }
                break;

              case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length >
                    0 &&
                  itemEtapa2.pessoaNf.id
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push({
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoaNf,
                  });
                }
                break;

              case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length >
                    0 &&
                  itemEtapa2.pessoaNf.id
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push({
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa2.pessoaNf,
                  });
                }
                break;

              case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length > 0
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push({
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: itemEtapa2.pessoaNf,
                  });
                }
                break;

              case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
                estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.entrada;
                estoqueIndicadorTipoEstoque =
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

                if (
                  response.produto.estoque.listaEstoqueLocalEstoque.length >
                    0 &&
                  itemEtapa2.pessoaNf.id
                ) {
                  estoqueQuantidade = 0;
                  quantidadeTotalEstoqueLocalEstoque =
                    itemDesmembradoEtapa2.quantidadeEntradaEstoque;

                  listaEstoqueLocalEstoque.push(
                    {
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    },
                    {
                      ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                      quantidade:
                        itemDesmembradoEtapa2.quantidadeEntradaEstoque,
                      tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                      indicadorTipoEstoque:
                        EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                      pessoaTerceiro: itemEtapa2.pessoaNf,
                    }
                  );
                }
                break;
            }

            itemDesmembradoEtapa3.estoque.listaEstoqueLocalEstoque =
              listaEstoqueLocalEstoque;
            itemDesmembradoEtapa3.estoque.estoqueTipoEntradaSaida =
              estoqueTipoEntradaSaida;
            itemDesmembradoEtapa3.estoque.estoqueIndicadorTipoEstoque =
              estoqueIndicadorTipoEstoque;
            itemDesmembradoEtapa3.estoque.estoqueQuantidade = estoqueQuantidade;
            itemDesmembradoEtapa3.estoque.quantidadeTotalEstoqueLocalEstoque =
              quantidadeTotalEstoqueLocalEstoque;

            listaItemDesmembradoEtapa3.push(itemDesmembradoEtapa3);
          }

          listaItemEtapa3Novo.push({
            ...itemEtapa2,
            dataLancamento,
            listaItemDesmembrado: listaItemDesmembradoEtapa3,
          });
        }
      }

      etapa3Ref.current?.handleSetarListaItem(listaItemEtapa3Novo);

      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaItemEtapa3Novo,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaItemEtapa3Novo,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 3 - Estoque

  // #region Etapa 4 - Financeiro

  const formRefEtapa4 = useRef<IFormCiaHandles>(null);
  const etapa4Ref = useRef<IImportacaoNfeTerceiroEtapa4Ref>(null);

  const handleCarregarDadosEtapa4 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa1 =
        etapa1Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa1ItemLista[];

      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa3ItemLista[];

      const possuiFinanceiro =
        await etapa4Ref.current?.handlePossuiFinanceiro();

      const gerarFinanceiro = listaItemEtapa3.some((e) =>
        e.listaItemDesmembrado.some((f) => f.comercial.gerarFinanceiro)
      );

      // Inicialize uma variável para armazenar a soma
      let somaTotalFinanceiroItens = 0;

      // Itere sobre os objetos na lista
      listaItemEtapa3.forEach((item) => {
        // Verifique se o campo 'gerarFinanceiro' dentro de 'comercial' é true
        if (
          item.listaItemDesmembrado &&
          Array.isArray(item.listaItemDesmembrado)
        ) {
          item.listaItemDesmembrado.forEach((subItem) => {
            if (
              subItem.comercial &&
              subItem.comercial.gerarFinanceiro === true
            ) {
              // Some o valor total ao total acumulado
              somaTotalFinanceiroItens +=
                Number(subItem.valorTotal) * (Number(subItem.percentual) / 100);
            }
          });
        }
      });

      if (!possuiFinanceiro && gerarFinanceiro) {
        const response =
          await ImportacaoXmlTerceiroNfeObterFinanceiroComunicador.index({
            params: { idImportacaoXml, somaTotalFinanceiroItens },
          });

        let dadosPadrao: IContaValoresAlterar | null = null;
        if (response) {
          dadosPadrao = {
            ...response,
            idPessoa:
              listaItemEtapa1.length > 0 && listaItemEtapa1[0].pessoaNf.id
                ? listaItemEtapa1[0].pessoaNf.id
                : undefined,
            pessoa:
              listaItemEtapa1.length > 0 && listaItemEtapa1[0].pessoaNf
                ? listaItemEtapa1[0].pessoaNf
                : undefined,
          };
        }

        await etapa4Ref.current?.handleSetarDadosPadrao(dadosPadrao);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idImportacaoXml]);

  // #endregion Etapa 4 - Financeiro

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!(await etapa1Ref.current?.handleValidar())) return;

        proximoStep();
        handleCarregarDadosEtapa2();

        break;

      case 2:
        if (!(await etapa2Ref.current?.handleValidar())) return;

        proximoStep();
        handleCarregarDadosEtapa3();
        break;

      case 3:
        if (!(await etapa3Ref.current?.handleValidar())) return;

        proximoStep();
        handleCarregarDadosEtapa4();
        break;
    }
  }, [
    handleCarregarDadosEtapa2,
    handleCarregarDadosEtapa3,
    handleCarregarDadosEtapa4,
    proximoStep,
    step,
  ]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleAbrir = useCallback(
    async ({
      idImportacaoXmlAutorizacao,
    }: IAbrirImportacaoNfeTerceiro): Promise<any> => {
      setAberto(true);
      setIdImportacaoXml(idImportacaoXmlAutorizacao);

      setStep(1);
      handleCarregarDadosEtapa1(idImportacaoXmlAutorizacao);

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const dadosEtapa4 = await etapa4Ref.current?.handleObterValidarDados();

      if (!dadosEtapa4?.valido) {
        setLoading(false);
        return;
      }

      const idImportacaoXmlAutorizacao = formRefEtapa1.current?.getFieldValue(
        'idImportacaoXmlAutorizacao'
      );
      const dataEntradaEstabelecimento = formRefEtapa1.current?.getFieldValue(
        'dataEntradaEstabelecimento'
      );
      const dataLancamento =
        formRefEtapa1.current?.getFieldValue('dataLancamento');

      const chaveAcesso = formRefEtapa1.current?.getFieldValue('chaveAcesso');

      const serieImportar = formRefEtapa1.current?.getFieldValue('serie');

      const numeroImportar = formRefEtapa1.current?.getFieldValue('numero');

      const valorTotalNota =
        formRefEtapa1.current?.getFieldValue('valorTotalNota');

      const copiarCodigoDescricaoNovosProdutos =
        formRefEtapa1.current?.getFieldValue(
          'copiarCodigoDescricaoNovosProdutos'
        );

      const pessoaCliente =
        formRefEtapa1.current?.getFieldValue('pessoaCliente');

      const listaItemEtapa1 =
        etapa1Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa1ItemLista[];

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa2ItemLista[];

      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaItem() as IImportacaoNfeTerceiroEtapa3ItemLista[];

      const { id: idNotaFiscalEntradaTerceiro, listaMensagem } =
        await ImportacaoXmlTerceiroNfeSalvarImportadoComunicador.store({
          params: {
            idImportacaoXmlAutorizacao,
            dataEntradaEstabelecimento,
            dataLancamento,
            chaveAcesso,
            serie: serieImportar,
            numero: numeroImportar,
            valorTotalNota,
            copiarCodigoDescricaoNovosProdutos,
            pessoaCliente,
            listaItemEtapa1,
            listaItemEtapa2,
            listaItemEtapa3,
            dadosEtapa4: dadosEtapa4.dados,
          },
          idXmlNfeImportado: idImportacaoXml,
        });

      ToastSucesso('NF-e Importado!');
      TratarInfo(listaMensagem);

      if (resolveRef.current) {
        resolveRef.current(idNotaFiscalEntradaTerceiro);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idImportacaoXml]);

  return (
    <ImportacaoNfeTerceiroContext.Provider
      value={{
        abrirImportacaoNfeTerceiro: handleAbrir,
        fecharImportacaoNfeTerceiro: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>
            Importar Nota Fiscal de Entrada de Terceiros
          </Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <NfeInfoBox>
                <p>NF-e: {numero}</p>
                <p>Série: {serie}</p>
              </NfeInfoBox>

              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Fiscal</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 4}>
                  4
                </StepBox>
                <StepNome>Financeiro</StepNome>
              </Step>
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1}>
              <ImportacaoNfeTerceiroEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <ImportacaoNfeTerceiroEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <ImportacaoNfeTerceiroEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 4 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa4}>
              <ImportacaoNfeTerceiroEtapa4
                formRef={formRefEtapa4}
                ref={etapa4Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 4 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </ImportacaoNfeTerceiroContext.Provider>
  );
};

function UseImportacaoNfeTerceiro(): IImportacaoNfeTerceiroContext {
  const context = useContext(ImportacaoNfeTerceiroContext);

  if (!context) {
    throw new Error(
      'UseImportacaoNfeTerceiro deve ser usado com um ImportacaoNfeTerceiroProvider'
    );
  }

  return context;
}

export { ImportacaoNfeTerceiroHook, UseImportacaoNfeTerceiro };
