import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Modal, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { BiCopy } from 'react-icons/bi/index.mjs';
import BtnCloseButton from '../../../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import InputAutoCompleteProduto from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IParametroRotaDetalhe from '../../../../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import EstruturaProdutoCopiarDeComunicador from '../../../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/CopiarDe/Comunicador/EstruturaProdutoCopiarDeComunicador';
import { UseTreeContext } from '../../../Hooks/TreeContext';

interface ICopiarDe {
  aberto: boolean;
  fecharFormModal(): void;
}

const CopiarDe: React.FC<ICopiarDe> = ({ aberto, fecharFormModal }) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const formPrincipal = UseForm();

  const { abrirJanela } = UseConfirmacao();
  const params: IParametroRotaDetalhe = useParams();
  const {
    buscarDados,
    setIdShowItem,
    setEstruturaSelecionada,
    setIdSelecionado,
    setIdItemPai,
    setIdProdutoPaiCopiado,
  } = UseTreeContext();

  const { refresh, setIdDetalheRegistro } = UseForm();

  const handleValidarDados = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idProdutoPaiOrigem: Yup.string()
            .required('Selecione ao menos um Produto de Origem!')
            .nullable(),
          idProdutoPai: Yup.string()
            .required('Selecione ao menos um Produto de Destino!')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        formPrincipal.formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formPrincipal.formRef]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as any;
      const idProdutoPai: string =
        formPrincipal.formRef.current?.getFieldValue('idProdutoPai');
      setIdProdutoPaiCopiado(idProdutoPai);
      if (data) {
        data.idProdutoPai = idProdutoPai;
      }

      if (!(await handleValidarDados(data))) {
        return { erro: true };
      }

      const produtoPaiOrigem =
        formRef.current?.getFieldValueNomeObjeto('produtoPaiOrigem');
      const produtoPaiPrincipal =
        formPrincipal.formRef.current?.getFieldValueNomeObjeto('produtoPai');

      let produtoPaiOrigemDescricao = '';
      let produtoPaiPrincipalDescricao = '';

      if (produtoPaiOrigem && produtoPaiPrincipal) {
        produtoPaiOrigemDescricao = produtoPaiOrigem.descricao;
        produtoPaiPrincipalDescricao = produtoPaiPrincipal.descricao;
      }

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            {` Você irá substituir toda a estrutura do Produto (${produtoPaiPrincipalDescricao}) atual pela estrutura
            do Produto (${produtoPaiOrigemDescricao}) deseja continuar ?`}
          </span>
        ),
      });

      if (!resposta) {
        return { erro: false };
      }

      const response = await EstruturaProdutoCopiarDeComunicador.update({
        id: data ? data.idProdutoPaiOrigem : '',
        params: { idPai: params.id ?? idProdutoPai },
      });

      ToastSucesso('Dados copiados com sucesso!');
      await buscarDados(params.id ?? idProdutoPai);

      setIdDetalheRegistro(params.id ? idProdutoPai : '');

      setIdItemPai('');
      setIdShowItem({ id: response.produtoFilho.id });
      setIdSelecionado(response.produtoFilho.id);
      setEstruturaSelecionada(response.produtoFilho.produtoFilho.descricao);

      formRef.current?.atualizarDataInicial();

      fecharFormModal();
      return { erro: false };
    } catch (error) {
      TratarErros(error);
      return { erro: true };
    }
  }, [
    formPrincipal.formRef,
    handleValidarDados,
    abrirJanela,
    params.id,
    buscarDados,
    setIdDetalheRegistro,
    setIdProdutoPaiCopiado,
    setIdItemPai,
    setIdShowItem,
    setIdSelecionado,
    setEstruturaSelecionada,
    fecharFormModal,
  ]);

  return (
    <Modal show={aberto} onHide={fecharFormModal} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Copiar dados de outro Produto</Modal.Title>
        <BtnCloseButton type="button" onClick={fecharFormModal}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <Divisor>
            <InputAutoCompleteProduto
              label="Produto (Origem dos Dados a Serem Copiados)"
              name="idProdutoPaiOrigem"
              nomeObjeto="produtoPaiOrigem"
              permitirAdicionar={false}
              somenteProdutosComEstrutura
              onChangeItemAtual={() => {
                refresh();
              }}
            />
            <span style={{ fontSize: 15, fontWeight: 'bold' }}>
              Atenção: A estrutura atual será substituida pela do produto
              selecionado acima!
            </span>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div>
          <Container>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={fecharFormModal}
                >
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </button>

                <button
                  style={{ marginLeft: 10 }}
                  type="button"
                  className="btn-padrao btn-azul-claro"
                  onClick={handleSubmit}
                >
                  <BiCopy />
                  <span style={{ marginLeft: 10 }}>Copiar</span>
                </button>
              </div>
            </Row>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CopiarDe;
