import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/obter-origem-icms';

interface IObterOrigemIcmsComunicadorIndex {
  params: any;
}

class ObterOrigemIcmsComunicador {
  public async index(params: IObterOrigemIcmsComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ObterOrigemIcmsComunicador();
