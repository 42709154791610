import api from '../../../../../Comum/Services/Api';

const rota = 'geral/menu-auto-complete';

interface IMenuAutoCompleteComunicadorIndex {
  params: any;
}

class MenuAutoCompleteComunicador {
  public async index(params: IMenuAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new MenuAutoCompleteComunicador();
