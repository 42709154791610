const CotacaoCompraPorProdutoPlanilha = [
  {
    titulo: 'Codigo do Produto',
    valor: 'listaCotacaoCompraItem.produtoCodigo',
  },

  {
    titulo: 'Nome do Produto',
    valor: 'listaCotacaoCompraItem.produtoDescricao',
  },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaCotacaoCompraItem.cotacaoCompraDataHoraEmissao',
  },

  {
    titulo: 'Numero da Cotação de Compra',
    valor: 'listaCotacaoCompraItem.cotacaoCompraNumero',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaCotacaoCompraItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaCotacaoCompraItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaCotacaoCompraItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaCotacaoCompraItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaCotacaoCompraItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaCotacaoCompraItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaCotacaoCompraItem.valorTotal',
  },
];

export default CotacaoCompraPorProdutoPlanilha;
