const CurvaABCPedidoVendaSubGrupoGrupoComProdutoPorValorPlanilha = [
  {
    titulo: 'Sub Grupo',
    valor: 'valoresSubGrupo.subGrupoDescricao',
  },
  {
    titulo: 'Grupo',
    valor: 'listaAgrupadaPorGrupo.valoresGrupo.grupoDescricao',
  },
  {
    titulo: 'Sequencia',
    valor: 'listaAgrupadaPorGrupo.listaProduto.sequenciaProduto',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaAgrupadaPorGrupo.listaProduto.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaAgrupadaPorGrupo.listaProduto.produtoDescricao',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaAgrupadaPorGrupo.listaProduto.unidadeMedida',
  },

  {
    titulo: 'Valor do Produto',
    valor: 'listaAgrupadaPorGrupo.listaProduto.valorProduto',
  },
  {
    titulo: 'Perc(%)',
    valor: 'listaAgrupadaPorGrupo.listaProduto.percentualTotalGeral',
  },
  {
    titulo: 'Perc(%) Acum',
    valor: 'listaAgrupadaPorGrupo.listaProduto.percentualAcumuladoTotalGeral',
  },
  {
    titulo: 'Perc(%) Grupo',
    valor: 'listaAgrupadaPorGrupo.listaProduto.percentualGrupo',
  },
  {
    titulo: 'Perc(%) Acum Grupo',
    valor: 'listaAgrupadaPorGrupo.listaProduto.percentualAcumuladoGrupo',
  },
];

export default CurvaABCPedidoVendaSubGrupoGrupoComProdutoPorValorPlanilha;
