import React from 'react';
import { ComunicadorProvider } from '../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import VeiculoComunicador from '../../../../../Comunicador/Transporte/Veiculo/Comunicador/VeiculoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { VeiculoHookDuplicar } from './DuplicarHook';

const VeiculoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: VeiculoComunicador }}>
      <RotasHook>
        <VeiculoHookDuplicar>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </VeiculoHookDuplicar>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default VeiculoHook;
