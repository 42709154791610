import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import RoteiroFabricacaoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/RoteiroFabricacao/Comunicador/RoteiroFabricacaoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const RoteiroFabricacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: RoteiroFabricacaoComunicador }}>
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default RoteiroFabricacaoHook;
