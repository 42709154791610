import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/gerar-nota-fiscal-saida-importacao-retirada-consignado';

interface IFrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador();
