import { IOrdemProducaoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import OrdemProducaoImpressaoPDF from './OrdemProducaoImpressaoPDF';

interface IImpressaoOrdemProducao {
  handleVisualizarPdf(dados: IOrdemProducaoImpressao): Promise<void>;
  handleObterBlobPdf(dados: IOrdemProducaoImpressao): Promise<Blob | undefined>;
}

const ImpressaoOrdemProducao = (): IImpressaoOrdemProducao => {
  async function handleVisualizarPdf(
    dados: IOrdemProducaoImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <OrdemProducaoImpressaoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: IOrdemProducaoImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <OrdemProducaoImpressaoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return { handleVisualizarPdf, handleObterBlobPdf };
};

export default ImpressaoOrdemProducao();
