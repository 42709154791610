import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { IContaEmailLista } from '@elogestor/util';
import IPesquisa from '../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../Componentes/Lista/Interface/ITh';
import PersonalizacaoListaCamposComunicador from '../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import Acoes from '../../../../Componentes/Lista/Acoes';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import { UseLog } from '../../../../Componentes/LogModal/Hooks/LogProvider';
import SubHeader from '../../../../Componentes/Lista/SubHeader';
import { Container } from './styles';
import PesquisaAvancada from './PesquisaAvançada';
import Lista from '../../../../Componentes/Lista';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import ContaEmailComunicador from '../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailComunicador';
import ContaEmailLogComunicador from '../../../../Comunicador/Configuracao/ContaEmail/Log/Comunicador/ContaEmailLogComunicador';

const ContaEmailLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ContaEmailComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: IContaEmailLista) => {
          return {
            id: data.id,

            email: <div className="lista-texto">{data.email}</div>,
            direcionarEmail: (
              <>
                <div>{data.direcionarBoletos ? 'Boletos;' : ''}</div>
                <div>
                  {data.direcionarDocumentosFiscais
                    ? 'Documentos Fiscais;'
                    : ''}
                </div>
                <div>{data.direcionarOrcamento ? 'Orçamento;' : ''}</div>
                <div>
                  {data.direcionarPedidoVenda ? 'Pedido de Venda;' : ''}
                </div>
                <div>
                  {data.direcionarCotacaoCompra ? 'Cotação de Compra;' : ''}
                </div>
                <div>
                  {data.direcionarPedidoCompra ? 'Pedido de Compra;' : ''}
                </div>
                <div>
                  {data.direcionarOrdemServico ? 'Ordem de Serviço;' : ''}
                </div>
              </>
            ),
            verificado: (
              <div
                className="lista-booleano"
                style={{ color: data.verificado ? 'green' : 'red' }}
              >
                {data.verificado ? 'Sim' : 'Não'}
              </div>
            ),
            ativo: (
              <div
                className="lista-booleano"
                style={{ color: data.ativo ? 'green' : 'red' }}
              >
                {data.ativo ? 'Sim' : 'Não'}
              </div>
            ),
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await ContaEmailComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = ContaEmailLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={permissoes.ConfiguracaoContaDeEmail?.exclui}
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ConfiguracaoContaDeEmail]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'configuracaoContaEmail' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Conta de e-mail"
          permissao={permissoes.ConfiguracaoContaDeEmail}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ContaEmailLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ContaEmailLista;
