interface IVisualizarPDF {
  dados: any;
}

export default function VisualizarPDF({ dados }: IVisualizarPDF): void {
  const data = new Uint8Array(dados.data);
  const blob = new Blob([data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  window.open(url);
}
