import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IDocumentoEntradaPorCFOPRelatorio,
  TipoDataEnum,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IDocumentosEntradaPorCfopPDF {
  dados: IDocumentoEntradaPorCFOPRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const DocumentosEntradaPorCfopPDF: React.FC<IDocumentosEntradaPorCfopPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Data: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroFornecedor && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroFornecedor}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>
        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '3%' }}>
              <Text>Série</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%' }}>
              <Text>
                {dados.cabecalho.filtroTipoData === TipoDataEnum.emissao
                  ? 'Dt.Emissão'
                  : 'Dt.Lançamento'}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '17%' }}>
              <Text>Código Pessoa/Estabelecimento</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Produtos</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. ICMS</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Subst. Trib</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Frete</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Desconto</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Outr. Desp.</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Val. Tot.Item</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dotted black' : '',
                        padding: '6pt 0pt 0pt 0pt',
                      }}
                    >
                      <View
                        style={{
                          width: '100%',
                        }}
                      >
                        <Text>{dado.chave}</Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaDocumentosEntrada.map(
                        (documento, documentoIndex) => {
                          return (
                            <View key={String(documentoIndex)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  marginTop: 1,
                                  backgroundColor:
                                    documentoIndex % 2 === 0
                                      ? '#E4E4E4'
                                      : '#fff',
                                }}
                              >
                                <View style={{ width: '3%' }}>
                                  <Text>{documento.serie}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '5%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.numero}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View style={{ width: '8%' }}>
                                  <Text>{documento.dataHora}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '17%' }}>
                                  <Text>{`${documento.documentosEntradaPessoa.pessoa.codigo} - ${documento.documentosEntradaPessoa.pessoa.nomeRazaoSocial}`}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorTotalProduto}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorIcms}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorIpi}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {documento.valorSubstituicaoTributaria}
                                  </Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorFrete}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorDesconto}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorOutrasDespesa}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{documento.valorTotalItem}</Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: '1pt dotted black',
                        padding: '6pt 0pt 0pt 0pt',
                      }}
                    >
                      <View
                        style={{
                          width: '36%',
                        }}
                      >
                        <Text>Totais pela CFOP</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>{dado.valores.somatorioValorTotalProduto}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>{dado.valores.somatorioValorTotalIcms}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>{dado.valores.somatorioValorTotalIpi}</Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>
                          {
                            dado.valores
                              .somatorioValorTotalSubstuitcaoTributaria
                          }
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>
                          {dado.valores.somatorioValorTotalValorFrete}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>
                          {dado.valores.somatorioValorTotalValorDesconto}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>
                          {dado.valores.somatorioValorTotalValorOutrasDespesas}
                        </Text>
                      </View>

                      <View style={{ width: '1%' }} />

                      <View
                        style={{
                          width: '7%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text>
                          {dado.valores.somatorioValorTotalValorTotalItem}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default DocumentosEntradaPorCfopPDF;
