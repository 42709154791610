import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/exportar-xml';

interface IManifestoTransporteExportarXmlComunicadorShow {
  id: string;
}

class ManifestoTransporteExportarXmlComunicador {
  public async show(
    params: IManifestoTransporteExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteExportarXmlComunicador();
