import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/movimentacoes/conta/obter-dados-conta-parcela';

interface IContaObterDadosContaParcelaBoletoComunicadorShow {
  params: any;
}

class ContaObterDadosContaParcelaBoletoComunicador {
  public async show(
    params: IContaObterDadosContaParcelaBoletoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.params.id}`);

    return response.data;
  }
}

export default new ContaObterDadosContaParcelaBoletoComunicador();
