import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = '/suprimentos/pesagens/pesagem/log';

interface IPesagemLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IPesagemLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class PesagemLogComunicador {
  public async index(
    params: IPesagemLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IPesagemLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new PesagemLogComunicador();
