import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PadronizacaoDescricaoComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/PadronizacaoDescricao/Comunicador/PadronizacaoDescricaoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import { ListaAtributoDraggableHook } from './ListaAtributoDraggableHook';

const PadronizacaoDescricaoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: PadronizacaoDescricaoComunicador }}
      >
        <RotasHook>
          <ListaAtributoDraggableHook>
            <FormHook>
              <SubHeaderHook>{children}</SubHeaderHook>
            </FormHook>
          </ListaAtributoDraggableHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default PadronizacaoDescricaoHook;
