/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  ModeloImpressaoPedidoCompraEnum,
  SituacaoPedidoCompraEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoPedidoCompraRetratoSemFotoProduto from './Impressao/Template/PedidoCompraRetratoSemFotoProdutoImpressao';
import ImpressaoPedidoCompraPaisagemSemFotoProduto from './Impressao/Template/PedidoCompraPaisagemSemFotoProdutoImpressao';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import PedidoCompraRetratoSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Impressao/PedidoCompraRetratoSemFotoProdutoImpressao/PedidoCompraRetratoSemFotoProdutoImpressaoComunicador';
import PedidoCompraPaisagemSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Impressao/PedidoCompraPaisagemSemFotoProdutoImpressao/PedidoCompraPaisagemSemFotoProdutoImpressaoComunicador';
import PedidoCompraAprovarComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Comunicador/PedidoCompraAprovarComunicador';
import PedidoCompraCancelarComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Comunicador/PedidoCompraCancelarComunicador';
import { UsePedidoCompraImportacaoCotacaoCompra } from '../../../PedidoCompraImportacaoCotacaoCompraHook';
import { UseNotaFiscalEntradaImportacaoPedidoCompra } from '../../../NotaFiscalEntradaImportacaoPedidoCompraHook';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import PedidoCompraRetratoComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Impressao/PedidoCompraRetratoComFotoProdutoImpressao/PedidoCompraRetratoComFotoProdutoImpressaoComunicador';
import PedidoCompraPaisagemComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Impressao/PedidoCompraPaisagemComFotoProdutoImpressao/PedidoCompraPaisagemComFotoProdutoImpressaoComunicador';
import ImpressaoPedidoCompraRetratoComFotoProduto from './Impressao/Template/PedidoCompraRetratoComFotoProdutoImpressao';
import ImpressaoPedidoCompraPaisagemComFotoProduto from './Impressao/Template/PedidoCompraPaisagemComFotoProdutoImpressao';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/PedidoCompra/Impressao/PedidoCompraPaisagemPrevisaoRecebimentoImpressao/PedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicador';
import ImpressaoPedidoCompraPaisagemPrevisaoRecebimento from './Impressao/Template/PedidoCompraPaisagemPrevisaoRecebimentoImpressao';

interface IAcoesPedidoCompra {
  permissao?: IPermissao;
}

const AcoesPedidoCompra: React.FC<IAcoesPedidoCompra> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();

  const idRegistroDetalhe = getIdDetalheRegistro();
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const {
    setTitulo: setTituloPedidoCompraImportacaoCotacaoCompra,
    abrirPedidoCompraImportacaoCotacaoCompra,
  } = UsePedidoCompraImportacaoCotacaoCompra();

  const {
    setTitulo: setTituloNotaFiscalEntradaImportacaoPedidoCompra,
    abrirNotaFiscalEntradaImportacaoPedidoCompra,
  } = UseNotaFiscalEntradaImportacaoPedidoCompra();

  const situacaoPedidoCompra = formRef.current?.getFieldValue('situacao');
  const idPessoa = formRef.current?.getFieldValue(
    'pedidoCompraPessoa.idPessoa'
  );
  const numero = formRef.current?.getFieldValue('numero');
  const listaPedidoCompraItem = formRef.current?.getFieldValue(
    'listaPedidoCompraItem'
  );

  const handleValidar = useCallback(
    async (
      situacaoPedidoCompraValidar?: SituacaoPedidoCompraEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoPedidoCompraValidar &&
          situacaoPedidoCompraValidar !== SituacaoPedidoCompraEnum.pendente
        )
          return true;

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef]
  );

  const handleSalvar = useCallback(
    async (
      situacaoPedidoCompraValidar?: SituacaoPedidoCompraEnum
    ): Promise<boolean> => {
      try {
        if (!handleValidar(situacaoPedidoCompraValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const idRegistro = getIdDetalheRegistro() || '';

      if (listaPedidoCompraItem && listaPedidoCompraItem.length === 0) {
        ToastInfo('Informe pelo menos um item para realizar a impressão!');
      } else {
        switch (parametros.ModeloParaImpressaoPedidoCompra) {
          case ModeloImpressaoPedidoCompraEnum.retratoSemFotoProduto:
            {
              const response =
                await PedidoCompraRetratoSemFotoProdutoImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoPedidoCompraRetratoSemFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoPedidoCompraRetratoSemFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoPedidoCompraEnum.paisagemSemFotoProduto: {
            const response =
              await PedidoCompraPaisagemSemFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoPedidoCompraPaisagemSemFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoCompraPaisagemSemFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
          case ModeloImpressaoPedidoCompraEnum.retratoComFotoProduto:
            {
              const response =
                await PedidoCompraRetratoComFotoProdutoImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoPedidoCompraRetratoComFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoPedidoCompraRetratoComFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoPedidoCompraEnum.paisagemComFotoProduto: {
            const response =
              await PedidoCompraPaisagemComFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoPedidoCompraPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoCompraPaisagemComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoCompraEnum.paisagemPrevisaoRecebimento: {
            const response =
              await PedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoPedidoCompraPaisagemPrevisaoRecebimento.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoCompraPaisagemPrevisaoRecebimento.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
        }
      }
      return undefined;
    },
    [
      getIdDetalheRegistro,
      listaPedidoCompraItem,
      parametros.ModeloParaImpressaoPedidoCompra,
    ]
  );

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoPedidoCompra))) {
        return;
      }

      setLoading(true);

      await handleVisualizarOuObterBlob(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    handleVisualizarOuObterBlob,
    setLoading,
    situacaoPedidoCompra,
  ]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: true,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: true,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      const anexo = await handleVisualizarOuObterBlob(false);

      await formRefEmail.current?.setDataInicial({
        assunto: `Pedido de Compra número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo: [
          {
            nomeArquivo: `Pedido Compra ${numero}.pdf`,
            file: anexo,
            permitirExcluir: false,
          },
        ],
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numero]
  );

  // #endregion Email

  const handleAprovar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente aprovar esse Pedido de Compra?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await PedidoCompraAprovarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  const handleCancelar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente cancelar esse Pedido de Compra?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await PedidoCompraCancelarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  const handleImportarCotacaoCompras = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloPedidoCompraImportacaoCotacaoCompra(
        'Importar Cotação de Compra'
      );
      const response = await abrirPedidoCompraImportacaoCotacaoCompra({
        idPessoa: String(idPessoa),
        idPedidoCompra: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirPedidoCompraImportacaoCotacaoCompra,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloPedidoCompraImportacaoCotacaoCompra,
  ]);

  const handleGerarNotaFiscalEntrada = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalEntradaImportacaoPedidoCompra(
        'Gerar Nota Fiscal de Entrada'
      );
      const response = await abrirNotaFiscalEntradaImportacaoPedidoCompra({
        idPessoa: String(idPessoa),
        idPedidoCompra: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/suprimentos/compras/nota-fiscal-entrada/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    setLoading,
    handleSalvar,
    getIdDetalheRegistro,
    setTituloNotaFiscalEntradaImportacaoPedidoCompra,
    abrirNotaFiscalEntradaImportacaoPedidoCompra,
    idPessoa,
    navigate,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoCompra === SituacaoPedidoCompraEnum.aprovado ||
            !listaPedidoCompraItem ||
            listaPedidoCompraItem.length === 0
          }
          onClick={handleAprovar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Aprovar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoCompra === SituacaoPedidoCompraEnum.cancelado
          }
          onClick={handleImportarCotacaoCompras}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Cotação de Compra
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoCompra === SituacaoPedidoCompraEnum.pendente ||
            situacaoPedidoCompra === SituacaoPedidoCompraEnum.cancelado
          }
          onClick={handleGerarNotaFiscalEntrada}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Nota Fiscal de Entrada
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoCompra === SituacaoPedidoCompraEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Pedido de Compra por E-mail"
          mensagemPadrao={`Segue em anexo o Pedido de Compra número: ${numero}`}
          direcionarPedidoCompra
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}
    </>
  );
};

export default AcoesPedidoCompra;
