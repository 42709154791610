import React, { createContext, useContext, RefObject } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';

export interface ISubmitProps {
  id: string;
  erro: boolean;
}

export interface IHandleCarregarDadosParametros {
  dadosDuplicados?: any;
  dadosRecuperados?: any;
  dadosPadrao?: any;
  dadosObrigatorios?: any;
}

interface IFormContext {
  formRef: RefObject<IFormCiaHandles>;
  handleDuplicarSalvar?: () => Promise<void>;
  handleSubmit(data: any): Promise<ISubmitProps>;
  handleValidar(data: any): Promise<boolean>;
  handleDuplicar?: () => Promise<void>;

  handleCarregarDados(data?: IHandleCarregarDadosParametros): Promise<void>;

  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  refresh: () => void;

  getIdDetalheRegistro(): null | string;
  setIdDetalheRegistro(valor: null | string): void;

  terminouCarregarDados?: boolean;
  inputRefFocus?: React.RefObject<any>;
  handleSetarFocus?: () => Promise<void>;
}

const formContext = createContext<IFormContext>({} as IFormContext);
const FormProvider = formContext.Provider;

function UseForm(): IFormContext {
  const context = useContext(formContext);

  return context;
}

export { FormProvider, UseForm };
