import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/inutilizacao-numeracao/upload-xml';

interface IImportacaoXmlInutilizacaoUploadXmlComunicadorUpdate {
  params: any;
}

class ImportacaoXmlInutilizacaoUploadXmlComunicador {
  public async update(
    params: IImportacaoXmlInutilizacaoUploadXmlComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlInutilizacaoUploadXmlComunicador();
