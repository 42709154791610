import { IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoVendaRetratoComValoresItensPDF from './PedidoVendaRetratoComFotoProdutoComValoresItensImpressaoPDF';
import ImpressaoPedidoVendaRetratoSemValoresItensPDF from './PedidoVendaRetratoComFotoProdutoSemValoresItensImpressaoPDF';

interface IImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens {
  handleVisualizarPdf(
    visualizarComValores: boolean,
    data: IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    visualizarComValores: boolean,
    dados: IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao
  ): Promise<Blob | undefined>;
}
const ImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens =
  (): IImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens => {
    async function handleVisualizarPdf(
      visualizarComValores: boolean,
      dados: IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao
    ): Promise<void> {
      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoComValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      } else {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoSemValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      }
    }

    async function handleObterBlobPdf(
      visualizarComValores: boolean,
      dados: IPedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoComValoresItensPDF dados={dados} />
          ),
        });

        return relatorio.GetBlob();
      }

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoComValoresItensPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens();
