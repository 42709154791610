"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegistroD100IndicadorFreteEnum;
(function (RegistroD100IndicadorFreteEnum) {
    RegistroD100IndicadorFreteEnum[RegistroD100IndicadorFreteEnum["porContaEmitente"] = 0] = "porContaEmitente";
    RegistroD100IndicadorFreteEnum[RegistroD100IndicadorFreteEnum["porContaRemetente"] = 1] = "porContaRemetente";
    RegistroD100IndicadorFreteEnum[RegistroD100IndicadorFreteEnum["porContaTerceiros"] = 2] = "porContaTerceiros";
    RegistroD100IndicadorFreteEnum[RegistroD100IndicadorFreteEnum["semCobrancaFrete"] = 3] = "semCobrancaFrete";
})(RegistroD100IndicadorFreteEnum || (RegistroD100IndicadorFreteEnum = {}));
exports.default = RegistroD100IndicadorFreteEnum;
