import React from 'react';
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai/index.mjs';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';
import { UseItemNavegacao } from './Hooks/NavegacaoHook';
import { Button } from './styles';

interface IJanelaNavegacao {
  containerStyle?: React.CSSProperties;
}

const JanelaNavegacao: React.FC<IJanelaNavegacao> = ({ containerStyle }) => {
  const {
    totalNavegacao,
    mostrarNavegacao,
    posicaoNavegacao,
    handleAnterior,
    handlePrimeiro,
    handleProximo,
    handleUltimo,
  } = UseItemNavegacao();

  const { telaGrande } = UseReactSizeMeBodyHook();

  return (
    <>
      {mostrarNavegacao && (
        <div style={containerStyle}>
          <Button
            disabled={posicaoNavegacao === 1}
            onClick={handlePrimeiro}
            type="button"
          >
            <AiOutlineDoubleLeft style={{ verticalAlign: 'text-top' }} />
            {telaGrande && <span style={{ paddingLeft: '3px' }}>Primeiro</span>}
          </Button>

          <Button
            disabled={posicaoNavegacao === 1}
            onClick={handleAnterior}
            type="button"
          >
            <AiOutlineLeft style={{ verticalAlign: 'text-top' }} />
            {telaGrande && <span style={{ paddingLeft: '3px' }}>Anterior</span>}
          </Button>

          <span
            style={{
              fontSize: 16,
              padding: '5px 10px',
              border: 'solid #dee2e6',
              borderWidth: '1px 0px',
            }}
          >
            {posicaoNavegacao}/{totalNavegacao}
          </span>

          <Button
            disabled={posicaoNavegacao === totalNavegacao}
            onClick={handleProximo}
            type="button"
          >
            {telaGrande && <span style={{ paddingRight: '3px' }}>Próximo</span>}
            <AiOutlineRight style={{ verticalAlign: 'text-top' }} />
          </Button>

          <Button
            disabled={posicaoNavegacao === totalNavegacao}
            onClick={handleUltimo}
            type="button"
          >
            {telaGrande && <span style={{ paddingRight: '3px' }}>Último</span>}
            <AiOutlineDoubleRight style={{ verticalAlign: 'text-top' }} />
          </Button>
        </div>
      )}
    </>
  );
};

export default JanelaNavegacao;
