import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra/aprovar';

interface ICotacaoCompraAprovarComunicadorUpdate {
  id: string;
  params: any;
}

class CotacaoCompraAprovarComunicador {
  public async update(
    params: ICotacaoCompraAprovarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new CotacaoCompraAprovarComunicador();
