import { BooleanEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/obter-nota-fiscal-entrada';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicadorIndex {
  idPessoa: string;
  idNotaFiscalEntrada?: string;
  movimentarEstoqueFiscal?: BooleanEnum;
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicador();
