import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import Main from './Main';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import ReprocessamentoProducaoHook from './Hooks';
import AcoesReprocessamentoProducao from './AcoesReprocessamentoProducao';
// import AcoesReprocessamentoProducao from './AcoesReprocessamentoProducao';

const ReprocessamentoProducaoDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <ReprocessamentoProducaoHook>
        <SubHeader
          titulo={
            params.id
              ? 'Editar Reprocessamento de Produção'
              : 'Reprocessamento de Produção'
          }
          permissao={permissoes.ManufaturaProducaoReprocessamentoProducao}
          botoes={<AcoesReprocessamentoProducao />}
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </ReprocessamentoProducaoHook>
    </Container>
  );
};

export default ReprocessamentoProducaoDetalhe;
