import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  CalculateSize,
  ConverterMilimitroParaPonto,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
  FormatarTelefone,
  INotaFiscalConsumidorImpressao,
  MeioPagamentoEnum,
  SituacaoNotaFiscalSaidaEnum,
  StringConverterParaEnumContemValor,
} from '@elogestor/util';
import QRCode from 'qrcode';

const Document: any = _document;
const Page: any = _page;

interface INotaFiscalConsumidorImpressaoPDF {
  dados: INotaFiscalConsumidorImpressao;
}

function ObterAlturaTotal(dados: INotaFiscalConsumidorImpressao): number {
  let alturaTotal = ConverterMilimitroParaPonto(170);
  let numeroLinhas = 0;

  if (dados.listaProdutoServico.length > 0) {
    dados.listaProdutoServico.forEach((it) => {
      const sizeDescricao = CalculateSize(it.produtoServico.descricao, {
        font: 'Tinos',
        fontSize: '8px',
      });

      const linhasDescricao = Math.ceil(sizeDescricao.width / 130);
      numeroLinhas += linhasDescricao;
    });
  }

  if (
    dados.informacoesPagamento.listaDetalhesPagamento &&
    dados.informacoesPagamento.listaDetalhesPagamento.length > 0
  ) {
    numeroLinhas += dados.informacoesPagamento.listaDetalhesPagamento.length;
  }

  alturaTotal += ConverterMilimitroParaPonto(6) * numeroLinhas;

  return alturaTotal;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: 8,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const NotaFiscalConsumidorImpressaoPDF: React.FC<
  INotaFiscalConsumidorImpressaoPDF
> = ({ dados }) => {
  const alturaTotal = ObterAlturaTotal(dados);
  const size = {
    width: ConverterMilimitroParaPonto(80),
    height: alturaTotal,
  };

  const canvasQrCode = document.createElement('canvas');
  QRCode.toCanvas(canvasQrCode, dados.chaveAcesso);
  const qrCode = canvasQrCode.toDataURL();

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait" size={size}>
        <View>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1pt dotted gray',
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 8, fontWeight: 'bold' }}>
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 8 }}>
                {dados.empresa.empresaEndereco.logradouro}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`- ${dados.empresa.empresaEndereco.bairro}`}
              </Text>
              <Text style={{ fontSize: 8 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>
              <Text style={{ fontSize: 8 }}>
                {dados.empresa.empresaEndereco.fone &&
                  `Telefone: ${FormatarTelefone(
                    dados.empresa.empresaEndereco.fone
                  )}`}
              </Text>
              <Text style={{ fontSize: 8, fontWeight: 'bold' }}>
                {`CNPJ/CPF: ${FormatarCnpjCpf(dados.empresa.cnpjCpf)} `}
                {dados.empresa.inscricaoEstadual &&
                  `/ IE: ${dados.empresa.inscricaoEstadual}`}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View
            style={{
              justifyContent: 'center',
              borderBottom: '1pt dotted gray',
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 9 }}>
                DANFE NFC-e - Documento Auxiliar
              </Text>
              <Text style={{ fontSize: 9 }}>
                da Nota Fiscal de Consumidor Eletrônica
              </Text>
              <Text style={{ fontSize: 7, fontWeight: 'bold' }}>
                Não permite o aproveitamento de crédito de ICMS
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1pt dotted gray',
              fontSize: 8,
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '10%' }}>
                <Text>CÓDIGO</Text>
              </View>

              <View style={{ width: '10%' }} />
              <View style={{ width: '80%' }}>
                <Text>DESCRIÇÃO</Text>
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '15%' }}>
                <Text>QTD.</Text>
              </View>
              <View style={{ width: '15%' }}>
                <Text>UN.</Text>
              </View>
              <View style={{ width: '35%', alignItems: 'flex-end' }}>
                <Text>VL. UNITÁRIO (R$)</Text>
              </View>
              <View style={{ width: '5%' }} />
              <View style={{ width: '25%', alignItems: 'flex-end' }}>
                <Text>VL. ITEM (R$)</Text>
              </View>
            </View>
          </View>

          <View style={{ borderBottom: '1pt dotted gray' }}>
            {dados.listaProdutoServico?.map((dado) => {
              return (
                <View
                  key={dado.ordem}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: 8,
                    }}
                  >
                    <View style={{ width: '10%' }}>
                      <Text>{dado.produtoServico.codigo}</Text>
                    </View>

                    <View style={{ width: '10%' }} />

                    <View style={{ width: '80%' }}>
                      <Text>{dado.produtoServico.descricao}</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: 8,
                    }}
                  >
                    <View style={{ width: '15%' }}>
                      <Text>{dado.quantidade}</Text>
                    </View>

                    <View style={{ width: '15%' }}>
                      <Text>{dado.unidadeMedida.sigla}</Text>
                    </View>

                    <View style={{ width: '35%', alignItems: 'flex-end' }}>
                      <Text>
                        {Number(dado.valorUnitario).FormatarParaPtBr()}
                      </Text>
                    </View>

                    <View style={{ width: '5%' }} />

                    <View style={{ width: '25%', alignItems: 'flex-end' }}>
                      <Text>{dado.valorTotal}</Text>
                    </View>
                  </View>

                  {dado.valorDesconto && dado.valorDesconto !== 0 && (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: 7,
                      }}
                    >
                      <View style={{ width: '65%' }}>
                        <Text>{`Desconto de R$ ${Number(
                          dado.valorDesconto
                        ).FormatarParaPtBr()}`}</Text>
                      </View>

                      <View style={{ width: '35%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorDesconto).FormatarParaPtBr()}
                        </Text>
                      </View>
                    </View>
                  )}

                  {dado.valorAcrescimo && dado.valorAcrescimo !== 0 && (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: 7,
                      }}
                    >
                      <View style={{ width: '65%' }}>
                        <Text>{`Acréscimo de R$ ${Number(
                          dado.valorAcrescimo
                        ).FormatarParaPtBr()}`}</Text>
                      </View>

                      <View style={{ width: '35%', alignItems: 'flex-end' }}>
                        <Text>
                          {Number(dado.valorAcrescimo).FormatarParaPtBr()}
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              );
            })}

            <View
              style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}
            >
              <View style={{ width: '30%' }}>
                <Text>SUBTOTAL</Text>
              </View>

              <View style={{ width: '5%' }} />

              <View style={{ width: '10%' }}>
                <Text>R$</Text>
              </View>

              <View style={{ width: '30%' }} />

              <View
                style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  borderTop: '1pt dotted gray',
                }}
              >
                <Text>
                  {Number(dados.imposto.valorTotalProdutos).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1pt dotted gray',
          }}
        >
          {dados.imposto.valorFrete && Number(dados.imposto.valorFrete) > 0 && (
            <View
              style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}
            >
              <View style={{ width: '50%' }}>
                <Text>FRETE</Text>
              </View>

              <View style={{ width: '10%' }}>
                <Text>R$</Text>
              </View>

              <View style={{ width: '10%' }} />

              <View style={{ width: '30%', alignItems: 'flex-end' }}>
                <Text>
                  {Number(dados.imposto.valorFrete).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          )}

          <View style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}>
            <View style={{ width: '60%' }}>
              <Text>QTD. TOTAL DE ITENS</Text>
            </View>

            <View style={{ width: '10%' }} />
            <View style={{ width: '30%', alignItems: 'flex-end' }}>
              <Text>{dados.listaProdutoServico.length}</Text>
            </View>
          </View>

          {dados.imposto.desconto && Number(dados.imposto.desconto) > 0 && (
            <View
              style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}
            >
              <View style={{ width: '50%' }}>
                <Text>VALOR DESCONTOS</Text>
              </View>

              <View style={{ width: '10%' }}>
                <Text>R$</Text>
              </View>

              <View style={{ width: '10%' }} />
              <View style={{ width: '30%', alignItems: 'flex-end' }}>
                <Text>{Number(dados.imposto.desconto).FormatarParaPtBr()}</Text>
              </View>
            </View>
          )}

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '50%' }}>
              <Text>VALOR A PAGAR</Text>
            </View>

            <View style={{ width: '10%' }}>
              <Text>R$</Text>
            </View>

            <View style={{ width: '10%' }} />

            <View style={{ width: '30%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.imposto.valorTotalNota).FormatarParaPtBr()}
              </Text>
            </View>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}>
            <View style={{ width: '60%' }}>
              <Text>FORMA DE PAGAMENTO</Text>
            </View>

            <View style={{ width: '10%' }} />

            <View style={{ width: '30%', alignItems: 'flex-end' }}>
              <Text>Valor Pago</Text>
            </View>
          </View>

          {dados.informacoesPagamento.listaDetalhesPagamento?.map(
            (dado, index) => {
              const tipoPagamento = StringConverterParaEnumContemValor({
                valor: dado.tPag,
                enumObj: MeioPagamentoEnum,
                valorPadrao: null,
              });

              return (
                <View
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 8,
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '60%' }}>
                      <Text>{tipoPagamento.split('-')[1].trim()}</Text>
                    </View>

                    <View style={{ width: '10%' }} />

                    <View style={{ width: '30%', alignItems: 'flex-end' }}>
                      <Text>{Number(dado.vPag).FormatarParaPtBr()}</Text>
                    </View>
                  </View>
                </View>
              );
            }
          )}

          <View style={{ display: 'flex', flexDirection: 'row', fontSize: 9 }}>
            <View style={{ width: '60%' }}>
              <Text>TROCO</Text>
            </View>

            <View style={{ width: '10%' }} />
            <View style={{ width: '30%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.informacoesPagamento.troco).FormatarParaPtBr()}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View style={{ borderBottom: '1pt dotted gray' }}>
            <Text style={{ fontSize: 8 }}>
              Informações dos Tributos Totais Incidentes
            </Text>

            <Text style={{ fontSize: 8 }}>
              {dados.informacaoComplementares ?? ''}
            </Text>
          </View>
        </View>

        <View style={{ borderBottom: '1pt dotted gray', marginTop: 3 }}>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              fontSize: 8,
            }}
          >
            <View>
              <Text>{`Número: ${dados.numero}`}</Text>
            </View>

            <View style={{ width: '5%' }} />

            <View>
              <Text>{`Série: ${dados.serie.codigo}`}</Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 8,
            }}
          >
            <Text>{`Emissão: ${FormatarDataHoraCompletaParaPtBr(
              dados.dataHoraEmissao
            )}`}</Text>
          </View>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 5,
            }}
          >
            <Text style={{ fontSize: 9 }}>Via Consumidor</Text>
            <Text style={{ fontSize: 9 }}>
              Consulte pela Chave de Acesso em:
            </Text>
            <Text style={{ fontSize: 8 }}>
              https://www.sefaz.rs.gov.br/NFCE/NFCE-COM.aspx:
            </Text>

            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 5,
              }}
            >
              <Text style={{ fontSize: 9 }}>CHAVE DE ACESSO</Text>
              <Text style={{ fontSize: 7 }}>
                {FormatarChaveAcesso(dados.chaveAcesso)}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View
            style={{
              borderBottom: '1pt dotted gray',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={{ fontSize: 9 }}>CONSUMIDOR</Text>
            {dados.destinatario ? (
              <View style={{ alignItems: 'center' }}>
                <Text style={{ fontSize: 8 }}>
                  {`CPF: ${FormatarCnpjCpf(dados.destinatario.cnpjCpf)} ${
                    dados.destinatario.nomeRazaoSocial
                  }`}
                </Text>
                {dados.destinatario.destinatarioEndereco && (
                  <Text style={{ fontSize: 8 }}>
                    {dados.destinatario.destinatarioEndereco?.logradouro}
                    {`, ${dados.destinatario.destinatarioEndereco?.numero}`}
                    {`- ${dados.destinatario.destinatarioEndereco?.complemento}`}
                    {`- ${dados.destinatario.destinatarioEndereco?.bairro}`}
                    {`- ${dados.destinatario.destinatarioEndereco?.cidade.nome}`}
                    {`- ${dados.destinatario.destinatarioEndereco?.cidade.estado.sigla}`}
                  </Text>
                )}
              </View>
            ) : (
              <Text style={{ fontSize: 8 }}>COSUMIDOR NÃO IDENTIFICADO</Text>
            )}
          </View>
        </View>

        <View>
          <View
            style={{
              borderBottom: '1pt dotted gray',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <View>
              <Text style={{ fontSize: 9 }}>Consulta via leitor QR Code</Text>
            </View>
            <View style={{ marginTop: 2 }}>
              <Image style={{ height: 100, width: 100 }} src={qrCode} />
            </View>
            <View>
              <Text style={{ fontSize: 9 }}>Protocolo de Autorização</Text>
            </View>
            <View
              style={{ display: 'flex', flexDirection: 'row', fontSize: 8 }}
            >
              <View>
                <Text>{dados.numeroProtocolo}</Text>
              </View>

              <View style={{ width: '5%' }} />

              <View>
                <Text>
                  {FormatarDataHoraCompletaParaPtBr(dados.dataHoraRetorno)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {(dados.situacao === SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
          dados.situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao ||
          dados.situacao === SituacaoNotaFiscalSaidaEnum.rejeitada) && (
          <View
            fixed
            style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 40,
                opacity: 0.3,
              }}
            >
              <Text>VISUALIZAÇÃO</Text>
              <Text>DOCUMENTO SEM VALIDADE</Text>
            </View>
          </View>
        )}

        {dados.situacao === SituacaoNotaFiscalSaidaEnum.denegada && (
          <View
            fixed
            style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 40,
                opacity: 0.3,
              }}
            >
              <Text>USO DENEGADO</Text>
              <Text>DOCUMENTO SEM VALIDADE</Text>
            </View>
          </View>
        )}

        {dados.situacao === SituacaoNotaFiscalSaidaEnum.cancelada && (
          <View
            fixed
            style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 40,
                opacity: 0.3,
              }}
            >
              <Text>CANCELADA</Text>
              <Text>DOCUMENTO SEM VALIDADE</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default NotaFiscalConsumidorImpressaoPDF;
