import { TipoPropriaTerceiroNotaFiscalEntradaEnum } from '@elogestor/util';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import ListaDetalheAutorizacao from './ListaDetalheAutorizacao/Lista';

const AutorizacaoTab: React.FC = () => {
  const { formRef } = UseForm();

  const tipoNotaFiscalEntrada = formRef.current?.getFieldValue(
    'tipoNotaFiscalEntrada'
  );

  InputHidden({
    name: 'transmissao.idImportacaoXmlAutorizacao',
  });

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Input
              label="Chave de Acesso"
              name="transmissao.chaveAcesso"
              maxLength={44}
              disabled={
                tipoNotaFiscalEntrada ===
                TipoPropriaTerceiroNotaFiscalEntradaEnum.propria
              }
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Ambiente"
              name="transmissao.ambiente"
              formatarValor={(ambiente = '') => {
                return (
                  <div style={{ fontWeight: 'bold' }}>
                    <span>{ambiente}</span>
                  </div>
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Input
              label="Protocolo"
              name="transmissao.numeroProtocolo"
              maxLength={15}
              disabled={
                tipoNotaFiscalEntrada ===
                TipoPropriaTerceiroNotaFiscalEntradaEnum.propria
              }
            />
          </Col>
          <Col xl={3} lg={6} md={6} sm={12}>
            <InputDateTime
              name="transmissao.dataHoraRetorno"
              label="Data e Hora"
              disabled={
                tipoNotaFiscalEntrada ===
                TipoPropriaTerceiroNotaFiscalEntradaEnum.propria
              }
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <ListaDetalheAutorizacao name="listaNotaFiscalEntradaTransmissaoEvento" />
      </Divisor>
    </div>
  );
};

export default AutorizacaoTab;
