import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Input from '../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../Componentes/Divisor';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputAutoCompleteMarcaProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteMarcaProduto';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteFamilia from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteFamilia';
import InputAutoCompleteColecao from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteColecao';

const ComercialTab: React.FC = () => {
  const { formRef } = UseForm();

  const isProduto =
    formRef.current?.getFieldValue('tipoProdutoServico') === 'Produto';

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Disponível para Venda"
                name="produtoComercial.comercializado"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteFamilia
              label="Família"
              name="produtoComercial.idFamilia"
              placeholder="Família"
              nomeObjeto="produtoComercial.familia"
              utilizavelProdutoServico
            />
          </Col>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteColecao
              label="Coleção"
              name="produtoComercial.idColecao"
              nomeObjeto="produtoComercial.colecao"
              placeholder="Coleção"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteMarcaProduto
              label="Marca"
              name="produtoComercial.idMarcaProduto"
              placeholder="Marca"
              nomeObjeto="produtoComercial.marcaProduto"
            />
          </Col>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <Input
              label="Modelo"
              name="produtoComercial.modeloProduto"
              placeholder="Modelo"
              maxLength={50}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} hidden={!isProduto}>
            <Input
              label="Número do Pedido de Compra"
              name="produtoComercial.pedidoCompraNumero"
              placeholder="Número do Pedido de Compra"
              maxLength={15}
            />
          </Col>
          <Col lg={3} md={6} sm={12} hidden={!isProduto}>
            <InputInteiroNulavel
              label="Item do Pedido de Compra"
              name="produtoComercial.pedidoCompraNumeroItem"
              placeholder="Item do Pedido de Compra"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ComercialTab;
