import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/upload-pdf';

interface IImportacaoXmlTerceiroUploadPdfComunicadorUpdate {
  params: any;
}

class ImportacaoXmlTerceiroUploadPdfComunicador {
  public async update(
    params: IImportacaoXmlTerceiroUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroUploadPdfComunicador();
