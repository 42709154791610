import React from 'react';
import LocalEstoqueHook from './ListaDetalheLocalEstoque/Hooks';
import ListaDetalheLocalEstoque from './ListaDetalheLocalEstoque/Lista';

const EstoqueTab: React.FC = () => {
  return (
    <>
      <LocalEstoqueHook>
        <ListaDetalheLocalEstoque name="listaOrdemDesmontagemItemLocalEstoque" />
      </LocalEstoqueHook>
    </>
  );
};

export default EstoqueTab;
