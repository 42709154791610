import React, { useCallback, useEffect } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../../../Componentes/Divisor';
import InputAutoCompleteServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteServico';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

interface IConhecimentoTransporteTerceiroServicoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  bloquearCamposPorImportacao: boolean;
}

const ConhecimentoTransporteTerceiroServicoDetalhe: React.FC<
  IConhecimentoTransporteTerceiroServicoModal
> = ({ onFecharFormModal, onSalvarFormModal, bloquearCamposPorImportacao }) => {
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseListaDetalheForm();

  const { permissoes } = UsePermissoes();
  const { TransporteConhecimentoTransporte: permissao } = permissoes;

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe titulo="Serviço" onFecharFormModal={onFecharFormModal}>
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={8} md={6} sm={12}>
                  <InputAutoCompleteServico
                    name="idProduto"
                    nomeObjeto="produto"
                    label="Serviço"
                    placeholder="Serviço"
                    disabled={bloquearCamposPorImportacao}
                    permitirAdicionar={!bloquearCamposPorImportacao}
                  />
                </Col>

                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    name="valor"
                    label="Valor"
                    placeholder="Valor"
                    casasDecimais={2}
                    casasInteiras={13}
                    maxLength={15}
                    disabled={bloquearCamposPorImportacao}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                bloquearCamposPorImportacao
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ConhecimentoTransporteTerceiroServicoDetalhe;
