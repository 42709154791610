import api from '../../../../../../Comum/Services/Api';

const rota = '/manufatura/engenharia/estrutura-produto/copiar-de';

interface IEstruturaProdutoCopiarDeComunicadorUpdate {
  id: string;
  params: any;
}

class EstruturaProdutoCopiarDeComunicador {
  public async update(
    params: IEstruturaProdutoCopiarDeComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new EstruturaProdutoCopiarDeComunicador();
