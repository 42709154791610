import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/impressao-etiquetas-pedido-venda/obter-dados-padrao';

class ImpressaoEtiquetasPedidoVendaObterDadosPadraoComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new ImpressaoEtiquetasPedidoVendaObterDadosPadraoComunicador();
