import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import {
  BooleanEnum,
  FormatarEnum,
  IPessoaFiltroRelatorioFrontend,
} from '@elogestor/util';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import InputPeriodoSemAno from '../../../../Componentes/Inputs/InputPeriodoSemAno';
import InputAutoCompleteTagEstado from '../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagCidade from '../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import InputAutoCompleteTagRepresentante from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRepresentante';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import Select from '../../../../Componentes/Select';
import InputTag from '../../../../Componentes/Inputs/TagsInput/InputTag';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';

interface IPessoaRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const PessoaRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const formRef = useRef<IFormCiaHandles>(null);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState('PessoaGeral');
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPessoaRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(dados as IPessoaFiltroRelatorioFrontend);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPessoaRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IPessoaFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IPessoaRelatorioDados;

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IPessoaFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [redirecionar]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      opcaoRelatorio: 'PessoaGeral',
      ativo: true,
    });
  }, []);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Pessoas
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Geral',
                        value: 'PessoaGeral',
                      },
                      {
                        title: 'Por Cidade',
                        value: 'PessoaPorCidade',
                      },
                      {
                        title: 'Por Estado e Cidade',
                        value: 'PessoaPorEstadoCidade',
                      },
                      {
                        title: 'Por Data de Aniversário',
                        value: 'PessoaPorDataAniversario',
                      },
                      {
                        title: 'Por Representante',
                        value: 'PessoaPorRepresentante',
                      },
                      {
                        title: 'Com Tags',
                        value: 'PessoaComTags',
                      },
                      {
                        title: 'Produtor - Com Cadastro Vitícolas',
                        value: 'PessoaProdutorComCadastroViticolas',
                      },
                    ]}
                    onChange={(event) => {
                      if (
                        event.target.value ===
                        'PessoaProdutorComCadastroViticolas'
                      ) {
                        formRef.current?.setFieldValue('produtor', true);
                      } else {
                        formRef.current?.setFieldValue('produtor', null);
                      }
                      setInputOpcaoRelatorio(event.target.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>
              <Col>
                <div
                  style={{
                    display: `${
                      inputOpcaoRelatorio === 'PessoaPorRepresentante'
                        ? 'none'
                        : 'flex'
                    }`,
                    flexDirection: 'column',
                  }}
                >
                  <Row>
                    <Col lg={3} md={5}>
                      <Select
                        name="cliente"
                        label="Cliente"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                    <Col lg={3} md={5}>
                      <Select
                        name="fornecedor"
                        label="Fornecedor"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                    <Col lg={3} md={5}>
                      <Select
                        name="transportador"
                        label="Transportador"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                    <Col lg={3} md={5}>
                      <Select
                        name="representante"
                        label="Representante"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3} md={5}>
                      <Select
                        name="produtor"
                        label="Produtor"
                        disabled={
                          inputOpcaoRelatorio ===
                          'PessoaProdutorComCadastroViticolas'
                        }
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                    <Col lg={3} md={5}>
                      <Select
                        name="motorista"
                        label="Motorista"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                    <Col lg={3} md={5}>
                      <Select
                        name="tecnico"
                        label="Tecnico"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>

                    <Col lg={3} md={5}>
                      <Select
                        name="ativo"
                        label="Ativo"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                  </Row>
                </div>
                <Col lg={12} md={12}>
                  {inputOpcaoRelatorio === 'PessoaPorDataAniversario' && (
                    <Row>
                      <Col style={{ marginTop: 10 }}>
                        <p
                          style={{
                            fontSize: '15px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '8px',
                            color: '#000000',
                          }}
                        >
                          Datas de aniversário:
                        </p>
                        {inputOpcaoRelatorio === 'PessoaPorDataAniversario' && (
                          <InputPeriodoSemAno
                            labelDiaInicial="Dia Inicial"
                            labelMesInicial="Mês Inicial"
                            labelDiaFinal="Dia Final"
                            labelMesFinal="Mês Final"
                            nomeInicial="mesDiaInicial"
                            nomeFinal="mesDiaFinal"
                          />
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row
                    style={{
                      marginTop: `${
                        inputOpcaoRelatorio === 'PessoaPorRepresentante'
                          ? '0'
                          : '15px'
                      }`,
                    }}
                  >
                    <Col lg={12} md={12} sm={12}>
                      <InputAutoCompleteTagEstado
                        label="Estados"
                        name="listaEstado"
                        onChangeListaItemAtual={({ listaItemAtual }) => {
                          handleChangeListaItemAtualEstado(listaItemAtual);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagCidade
                        label="Cidades"
                        name="listaCidade"
                        listaIdEstado={listaIdEstado}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagRepresentante
                        label="Representantes"
                        name="listaRepresentante"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputTag label="Tags" name="listaTags" />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default PessoaRelatorio;
