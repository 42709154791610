const MovimentacaoPorPortadorPlanilha = [
  {
    titulo: 'Código',
    valor: 'codigo',
  },
  {
    titulo: 'Descrição',
    valor: 'descricao',
  },

  {
    titulo: 'Valor Realizado',
    valor: 'valorRealizado',
  },
  {
    titulo: 'Percentual Cat Pai',
    valor: 'percentualCategoriaPaiRealizado',
  },
  {
    titulo: 'Percentual Saidas',
    valor: 'percentualSaidaRealizado',
  },
  {
    titulo: 'Percentual Entradas',
    valor: 'percentualEntradaRealizado',
  },
  {
    titulo: 'Valor Previsto',
    valor: 'valorPrevisto',
  },
  {
    titulo: 'Percentual Cat Pai',
    valor: 'percentualCategoriaPaiPrevisto',
  },

  {
    titulo: 'Percentual Saida',
    valor: 'percentualSaidaPrevisto',
  },
  {
    titulo: 'Percentual Entrada',
    valor: 'percentualEntradaPrevisto',
  },
];

export default MovimentacaoPorPortadorPlanilha;
