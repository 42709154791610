import api from '../../../../Comum/Services/Api';

const rotaShow = 'configuracao/empresa/selecionada-usuario';
const rota = 'configuracao/empresa';

interface IEmpresaComunicadorStore {
  params: any;
}

interface IEmpresaComunicadorUpdate {
  id: string;
  params: any;
}

interface IEmpresaComunicadorDeletar {
  id: string;
}

class EmpresaComunicador {
  public async index(): Promise<any> {
    return [];
  }

  public async show(): Promise<any> {
    const response = await api.get(rotaShow);

    return response.data;
  }

  public async store(params: IEmpresaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IEmpresaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IEmpresaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmpresaComunicador();
