"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ValidarCodigoBarrasGtin;
function ValidarCodigoBarrasGtin(codigoBarras) {
    if (codigoBarras === 'SEM GTIN')
        return true;
    codigoBarras = codigoBarras.RemoverNaoNumericos();
    if (codigoBarras.length === 14 ||
        codigoBarras.length === 13 ||
        codigoBarras.length === 12 ||
        codigoBarras.length === 8)
        codigoBarras = codigoBarras.padStart(14, '0');
    else
        return false;
    const numeros = codigoBarras.split('').map((valor) => Number(valor));
    const digito = numeros[numeros.length - 1];
    const somaPares = numeros[1] +
        numeros[3] +
        numeros[5] +
        numeros[7] +
        numeros[9] +
        numeros[11];
    const somaImpares = numeros[0] +
        numeros[2] +
        numeros[4] +
        numeros[6] +
        numeros[8] +
        numeros[10] +
        numeros[12];
    const resultado = somaPares + somaImpares * 3;
    const digitoVerificador = (10 - (resultado % 10)) % 10;
    return digitoVerificador === digito;
}
