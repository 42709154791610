import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/servico-auto-complete-tag';

interface IServicoAutoCompleteTagComunicadorIndex {
  params: any;
}

class ServicoAutoCompleteTagComunicador {
  public async index(
    params: IServicoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ServicoAutoCompleteTagComunicador();
