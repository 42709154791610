import api from '../../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico/impressao/retrato-com-produtos-servicos-pagamentos';

interface IOrdemServicoRetratoComFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class OrdemServicoRetratoComFotoProdutoImpressaoComunicador {
  public async show(
    params: IOrdemServicoRetratoComFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoRetratoComFotoProdutoImpressaoComunicador();
