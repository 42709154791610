"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoOrdemServicoNotaFiscalSaidaEnum;
(function (SituacaoOrdemServicoNotaFiscalSaidaEnum) {
    SituacaoOrdemServicoNotaFiscalSaidaEnum["atendido"] = "Atendido";
    SituacaoOrdemServicoNotaFiscalSaidaEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoOrdemServicoNotaFiscalSaidaEnum["naoAtendido"] = "N\u00E3o Atendido";
    SituacaoOrdemServicoNotaFiscalSaidaEnum["ordemCancelada"] = "Ord. Cancelada";
})(SituacaoOrdemServicoNotaFiscalSaidaEnum || (SituacaoOrdemServicoNotaFiscalSaidaEnum = {}));
exports.default = SituacaoOrdemServicoNotaFiscalSaidaEnum;
