import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/grupo-produto-sidevin/grupo-produto-codigo-rastreabiliadade-sisdevin';

interface IGrupoProdutoCodigoRastreabilidadeOrdemComunicadorUpdate {
  id: string;
  params: any;
}

class GrupoProdutoCodigoRastreabilidadeOrdemComunicador {
  public async update(
    params: IGrupoProdutoCodigoRastreabilidadeOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}/ordem`, params.params);

    return response.data;
  }
}

export default new GrupoProdutoCodigoRastreabilidadeOrdemComunicador();
