import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '',
        novo: '/suprimentos/sisdevin/grupo-produto-sidevin',
        alterar: '',
        excluir: '/suprimentos/sisdevin/grupo-produto-sidevin',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
