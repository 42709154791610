import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/obter-permissoes-padrao';

class UsuarioPermissaoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new UsuarioPermissaoComunicador();
