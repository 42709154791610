import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IContasParcelaReversaoLista } from '@elogestor/util';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IContasParcelaReversaoListaContext {
  validador: boolean;

  listaValor: IContasParcelaReversaoLista[];
  setListaValor(lista: IContasParcelaReversaoLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
}

const ContasParcelaReversaoListaContext =
  createContext<IContasParcelaReversaoListaContext>(
    {} as IContasParcelaReversaoListaContext
  );

const ContasParcelaReversaoListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<IContasParcelaReversaoLista[]>(
    []
  );

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleSetListaValor = useCallback(
    async (lista: IContasParcelaReversaoLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <ContasParcelaReversaoListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,

        validador: true,
      }}
    >
      {children}
    </ContasParcelaReversaoListaContext.Provider>
  );
};

function UseContasParcelaReversaoLista(): Omit<
  IContasParcelaReversaoListaContext,
  'validador'
> {
  const context = useContext(ContasParcelaReversaoListaContext);

  if (!context.validador) {
    throw new Error(
      'UseContasParcelaReversaoLista deve ser usado com um ContasParcelaReversaoListaHook'
    );
  }

  return context;
}

export { ContasParcelaReversaoListaHook, UseContasParcelaReversaoLista };
