/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  FormatarEnum,
  IAdiantamentosFiltroRelatorioFrontend,
  TipoContaEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import Select from '../../../../../Componentes/Select';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputAutoCompleteTagEmpresa from '../../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import { UseAuth } from '../../../../../Hooks/Auth';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagCategoria from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagCategoria';
import InputDate from '../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteTagFormaPagamento from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagBandeiraCartao from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagBandeiraCartao';
import InputAutoCompleteTagSituacaoContaEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoContaEnum';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import { IInputAutoCompleteClienteFornecedorRef } from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteClienteFornecedor';
import InputAutoCompleteTagClienteFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagClienteFornecedor';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputAutoCompleteTagFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFornecedor';
import InputAutoCompleteTagTipoAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoAdiantamentoEnum';
import InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum';
import InputAutoCompleteTagTipoContaAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoContaAdiantamentoEnum';
import InputAutoCompleteTagSituacaoAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoAdiantamentoEnum';
import InputAutoCompleteTagPessoa from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';

interface IAdiantamentosRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const Adiantamentos: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraRef = useRef<IInputPeriodoDataHoraRef>(null);

  const [listaIdEmpresa, setListaIdEmpresa] = useState<string[]>([]);
  const [isTipoContaReceber, setIsTipoContaReceber] = useState(true);

  const [loading, setLoading] = useState(false);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState(
    'AdiantamentosPorEmissao'
  );

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataHoraInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataInicial() &&
                  !periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data inicial',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataInicial() &&
                  !!periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Inicial!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelasPorEmissao' && !value
                );
              },
            }),

          dataHoraFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora final',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataFinal() &&
                  !periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data final',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataFinal() &&
                  !!periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Final!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio === 'MovimentacaoPorPortador' && !value
                );
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef, inputOpcaoRelatorio]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IAdiantamentosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IAdiantamentosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IAdiantamentosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IAdiantamentosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IAdiantamentosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IAdiantamentosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      opcaoRelatorio: 'AdiantamentosPorEmissao',

      dataHoraInicial: dataInicial,
      dataHoraFinal: dataFinal,

      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],

      listaTipoConta: [{ descricao: 'Receber' }, { descricao: 'Pagar' }],

      listaSituacaoAdiantamento: [
        { descricao: 'Disponível' },
        { descricao: 'Parcialmente Utilizado' },
        { descricao: 'Utilizado' },
      ],
    });
  }, [empresa.id, empresa.nomeRazaoSocial, formRef]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Adiantamentos
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Emissão',
                        value: 'AdiantamentosPorEmissao',
                      },
                      {
                        title: 'Por Emissão com Utilização',
                        value: 'AdiantamentosPorEmissaoComUtilizacao',
                      },
                      {
                        title: 'Por Liquidação',
                        value: 'AdiantamentosPorLiquidacao',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.currentTarget.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'AdiantamentosPorEmissao' ||
                      inputOpcaoRelatorio === 'AdiantamentosPorLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'AdiantamentosPorEmissaoComUtilizacao'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Emissão Inicial"
                      labelDataHoraFinal="Data Emissão Final"
                      nomeDataHoraInicial="dataHoraInicial"
                      nomeDataHoraFinal="dataHoraFinal"
                      ref={periodoDataHoraRef}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col
                    xl={6}
                    lg={4}
                    style={{
                      display:
                        inputOpcaoRelatorio === 'MUDAR' ? 'flex' : 'none',
                    }}
                  >
                    <Select
                      label="Tipo"
                      name="tipoConta"
                      options={FormatarEnum({
                        enumObj: TipoContaEnum,
                      })}
                      onChange={(event) => {
                        setIsTipoContaReceber(
                          event.target.value === TipoContaEnum.receber
                        );
                      }}
                    />
                  </Col>

                  <Col
                    xl={6}
                    lg={4}
                    style={{
                      display:
                        inputOpcaoRelatorio === 'AdiantamentosPorEmissao' ||
                        inputOpcaoRelatorio === 'AdiantamentosPorLiquidacao' ||
                        inputOpcaoRelatorio ===
                          'AdiantamentosPorEmissaoComUtilizacao'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <InputAutoCompleteTagTipoContaAdiantamentoEnum
                      label="Tipos de Conta"
                      name="listaTipoConta"
                    />
                  </Col>

                  <Col xl={6} lg={8}>
                    <InputAutoCompleteTagSituacaoAdiantamentoEnum
                      label="Situação"
                      name="listaSituacaoAdiantamento"
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col xl={12} lg={4}>
                    <InputAutoCompleteTagTipoAdiantamentoEnum
                      label="Tipo de Adiantamento"
                      name="listaTipoAdiantamento"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPessoa
                      label="Clientes / Fornecedores"
                      placeholder="Clientes / Fornecedores"
                      name="listaClienteFornecedores"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: inputOpcaoRelatorio === 'Mudar' ? 'flex' : 'none',
                  }}
                >
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Parcela)"
                      name="listaPortadoresParcela"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Liquidação)"
                      name="listaPortadoresLiquidacao"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: inputOpcaoRelatorio === 'Mudar' ? 'flex' : 'none',
                  }}
                >
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Parcela)"
                      name="listaFormasPagamentoParcela"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Liquidação)"
                      name="listaFormasPagamentoLiquidacao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                      onChangeListaItemAtual={(ev) => {
                        if (ev.listaItemAtual) {
                          if (ev.listaItemAtual.length === 0) {
                            setListaIdEmpresa([]);
                          } else {
                            ev.listaItemAtual.forEach((it) =>
                              setListaIdEmpresa((prevState) => {
                                const lista = prevState.filter(
                                  (item) => item !== it.id
                                );
                                lista.push(it.id);

                                return lista;
                              })
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default Adiantamentos;
