/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormatarDataParaPtBr,
  IEstoqueMovimentoSisdevinLista,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AcoesSisdevinEstoqueMovimento from '../AcoesSisdevinEstoqueMovimento';
import { UseSisdevinEstoqueMovimentoLista } from '../Hooks/SisdevinEstoqueMovimentoListaHook';
import SisdevinEstoqueMovimentoComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/SisdevinEstoqueMovimento/Comunicador/SisdevinEstoqueMovimentoComunicador';
import EditarNotaFiscalItemSisdevin from '../EditarNotaItemSisdevin';

const SisdevinEstoqueMovimentoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosSisdevinManutencaoMovimentosSisdevin: permissao } =
    permissoes;

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    possuiItemSelecionado,
    setPossuiItemSelecionado,
  } = UseSisdevinEstoqueMovimentoLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [showEditarItensSisdevin, setShowEditarItensSisdevin] = useState(false);
  const [indexSelecionado, setIndexSelecionado] = useState<number | any>();
  const [, setListaColunas] = useState<ITh[]>([]);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleLimparListaValor = useCallback(() => {
    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (item) item.checked = false;
    });
    setPossuiItemSelecionado(false);
  }, [listaItemSelecionadoRef, setPossuiItemSelecionado]);

  const handleAlterouSelecionado = useCallback(async () => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item) {
          listaValor[index].selecionado = checked;
          item.checked = checked;
        }
      });
      setListaValor(listaValor);
      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef, listaValor]
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);
        let listaDados: IEstoqueMovimentoSisdevinLista[] = [];

        const itensSelecionados = listaDados.filter(
          (item: IEstoqueMovimentoSisdevinLista) => {
            return item.selecionado;
          }
        );

        setPossuiItemSelecionado(itensSelecionados.length > 0);

        const response = await SisdevinEstoqueMovimentoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });
        listaDados = response.dados[0].map(
          (data: IEstoqueMovimentoSisdevinLista) => {
            return { ...data, selecionado: false };
          }
        );

        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);

        const dados = listaDados.map(
          (data: IEstoqueMovimentoSisdevinLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={(ev) => {
                      handleAlterouSelecionado();
                      listaDados[index].selecionado = ev.target.checked;
                      listaValor[index].selecionado = ev.target.checked;
                    }}
                  />
                </div>
              ),

              tipoMovimento: (
                <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                  {data.tipoMovimento}
                </div>
              ),

              numeroNota: <div className="lista-valor">{data.numeroNota}</div>,
              serieDocumento: (
                <div className="lista-valor">{data.serieDocumento ?? ''}</div>
              ),

              dataMovimento: (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.dataMovimento)}
                </div>
              ),

              ordemItem: <div className="lista-valor">{data.ordemItem}</div>,

              'notaFiscalItemSisdevin.notaFiscalItem.produto.codigoDescricao': (
                <div className="lista-texto">
                  {`${data.notaFiscalItemSisdevin.notaFiscalItem.produto.codigo} - ${data.notaFiscalItemSisdevin.notaFiscalItem.produto.descricao}`}
                </div>
              ),

              'notaFiscalItemSisdevin.notaFiscalItem.quantidade': (
                <div className="lista-valor">
                  {data.notaFiscalItemSisdevin.notaFiscalItem.quantidade
                    ? Number(
                        data.notaFiscalItemSisdevin.notaFiscalItem.quantidade
                      ).FormatarParaPtBr()
                    : ''}
                </div>
              ),

              'codigoRastreabilidadeSisdevin.codigoRastreabilidadeDescricao': (
                <div className="lista-texto">
                  {data.codigoRastreabilidadeSisdevin
                    ? `${data.codigoRastreabilidadeSisdevin.codigoRastreabilidade} - ${data.codigoRastreabilidadeSisdevin.descricao}`
                    : ''}
                </div>
              ),

              litragem: (
                <div className="lista-valor">
                  {data.litragem
                    ? Number(data.litragem).FormatarParaPtBr()
                    : ''}
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAlterouSelecionado, setListaValor, listaValor.length]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosSisdevinEstoqueMovimento' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  const handleClickLinha = useCallback(async (index: number) => {
    setIndexSelecionado(index);
    setRefresh(false);
    setShowEditarItensSisdevin(true);
  }, []);

  const handleFecharSalvarEditarNotaItemSisdevinModal =
    useCallback(async () => {
      setShowEditarItensSisdevin(false);
      setRefresh(true);

      handleLimparListaValor();
    }, [setRefresh]);

  useEffect(() => {
    if (refresh) handlePesquisarDados({});
  }, [refresh]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Manutenção de Movimentos do Sisdevin"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesSisdevinEstoqueMovimento
                loading={loading}
                setLoading={setLoading}
                setRefresh={setRefresh}
                possuiItemSelecionado={possuiItemSelecionado}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onClickLinhaModal={handleClickLinha}
          detalhe
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>

      {showEditarItensSisdevin && (
        <EditarNotaFiscalItemSisdevin
          onFecharFormModal={handleFecharSalvarEditarNotaItemSisdevinModal}
          onSalvarFormModal={handleFecharSalvarEditarNotaItemSisdevinModal}
          index={indexSelecionado}
        />
      )}
    </>
  );
};

export default SisdevinEstoqueMovimentoLista;
