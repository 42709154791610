import { FormatarEnum, ModalidadeBaseCalculoIcmsStEnum } from '@elogestor/util';
import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsTab } from '../../../Hook/ItemIcmsTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputHidden from '../../../../../../../../../../Componentes/Inputs/InputHidden';
import ProdutoObterImpostoComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterImpostoComunicador';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';

const ItemImpostoIcmsStTab: React.FC = () => {
  const { formRefDetalhe, refresh, setLoading } = UseListaDetalheForm();
  const {
    obterVisibilidadeBloqueioCamposPorIcmsCst,
    camposEscondidosIcms,
    camposBloqueadosIcms,
  } = UseItemIcmsTab();
  const { calcularImpostosPorCst } = UseItemTab();

  const calcularSt = formRefDetalhe.current?.getFieldValue(
    'cotacaoCompraItemImpostoIcms.calcularSt'
  );

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterImpostosProduto = useCallback(async () => {
    try {
      setLoading(true);

      const idProduto = formRefDetalhe.current?.getFieldValue('idProduto');

      const response = await ProdutoObterImpostoComunicador.show({
        id: idProduto,
      });

      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido',
        response.baseCalculoIcmsStRetido
      );
      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.aliquotaIcmsStRetido',
        response.aliquotaIcmsStRetido
      );
      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.valorUnitarioProdutoIcmsStRetido',
        response.valorTotalIcmsStRetido
      );

      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido',
        response.baseCalculoFcpIcmsStRetido
      );
      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.aliquotaFcpIcmsStRetido',
        response.aliquotaFcpIcmsStRetido
      );
      formRefDetalhe.current?.setFieldValue(
        'cotacaoCompraItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido',
        response.valorFcpIcmsStRetido
      );

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst, formRefDetalhe, setLoading]);

  useEffect(() => {
    obterVisibilidadeBloqueioCamposPorIcmsCst();
  }, [obterVisibilidadeBloqueioCamposPorIcmsCst, calcularSt]);

  InputHidden({
    name: 'cotacaoCompraItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido',
  });
  InputHidden({
    name: 'cotacaoCompraItemImpostoIcms.valorUnitarioProdutoIcmsStRetido',
  });
  InputHidden({
    name: 'cotacaoCompraItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido',
  });
  InputHidden({
    name: 'cotacaoCompraItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido',
  });

  return (
    <div>
      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsSt &&
          camposEscondidosIcms.modalidadeBaseCalculoIcmsSt &&
          camposEscondidosIcms.percentualMvaIcmsSt &&
          camposEscondidosIcms.percentualReducaoBaseCalculoIcmsSt &&
          camposEscondidosIcms.aliquotaInterestadualIcmsSt &&
          camposEscondidosIcms.aliquotaInternaIcmsSt &&
          camposEscondidosIcms.baseCalculoIcmsSt &&
          camposEscondidosIcms.valorIcmsSt
        }
      >
        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Calcular ICMS ST."
                name="cotacaoCompraItemImpostoIcms.calcularIcmsSt"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) {
                    handleObterImpostosProduto();
                    handleCalcularImpostosPorCst();
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            hidden={camposEscondidosIcms.modalidadeBaseCalculoIcmsSt}
          >
            <Select
              disabled={camposBloqueadosIcms.modalidadeBaseCalculoIcmsSt}
              label="Modalidade da Base de Cálculo ST."
              name="cotacaoCompraItemImpostoIcms.modalidadeBaseCalculoIcmsSt"
              options={FormatarEnum({
                enumObj: ModalidadeBaseCalculoIcmsStEnum,
                nullavel: true,
              })}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualMvaIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualMvaIcmsSt}
              label="MVA"
              name="cotacaoCompraItemImpostoIcms.percentualMvaIcmsSt"
              placeholder="MVA"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualReducaoBaseCalculoIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualReducaoBaseCalculoIcmsSt}
              label="Percentual Redução ST."
              name="cotacaoCompraItemImpostoIcms.percentualReducaoBaseCalculoIcmsSt"
              placeholder="Percentual Redução ST."
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaInterestadualIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaInterestadualIcmsSt}
              label="Alíquota Interestadual"
              name="cotacaoCompraItemImpostoIcms.aliquotaInterestadualIcmsSt"
              placeholder="Alíquota Interestadual"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaInternaIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaInternaIcmsSt}
              label="Alíquota ST."
              name="cotacaoCompraItemImpostoIcms.aliquotaInternaIcmsSt"
              placeholder="Alíquota ST."
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcmsSt}
              label="Base de Cálculo ST."
              name="cotacaoCompraItemImpostoIcms.baseCalculoIcmsSt"
              placeholder="Base de Cálculo ST."
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorIcmsSt}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsSt}
              label="Valor Total ST."
              name="cotacaoCompraItemImpostoIcms.valorIcmsSt"
              placeholder="Valor Total ST."
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.baseCalculoFcpIcmsSt &&
          camposEscondidosIcms.aliquotaFcpIcmsSt &&
          camposEscondidosIcms.valorFcpIcmsSt &&
          camposEscondidosIcms.valorIcmsStEValorFcpIcmsSt
        }
      >
        <h5>Fundo de Combate a Pobreza</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoFcpIcmsSt}
              label="Base de Cálculo"
              name="cotacaoCompraItemImpostoIcms.baseCalculoFcpIcmsSt"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaFcpIcmsSt}
              label="Alíquota"
              name="cotacaoCompraItemImpostoIcms.aliquotaFcpIcmsSt"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorFcpIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorFcpIcmsSt}
              label="Valor"
              name="cotacaoCompraItemImpostoIcms.valorFcpIcmsSt"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsStEValorFcpIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsStEValorFcpIcmsSt}
              label="Valor e Valor ST."
              name="cotacaoCompraItemImpostoIcms.valorIcmsStEValorFcpIcmsSt"
              placeholder="Valor e Valor ST."
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsStRetido &&
          camposEscondidosIcms.baseCalculoIcmsStRetido &&
          camposEscondidosIcms.aliquotaIcmsStRetido &&
          camposEscondidosIcms.valorIcmsStRetido
        }
      >
        <h5>Retido</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsStRetido}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcmsStRetido}
                label="Calcular ICMS ST. Retido"
                name="cotacaoCompraItemImpostoIcms.calcularIcmsStRetido"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcmsStRetido}
              label="Base de Cálculo ST. Retido"
              name="cotacaoCompraItemImpostoIcms.baseCalculoIcmsStRetido"
              placeholder="Base de Cálculo ST. Retido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcmsStRetido}
              label="Alíquota"
              name="cotacaoCompraItemImpostoIcms.aliquotaIcmsStRetido"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsStRetido}
              label="Valor ST. Retido"
              name="cotacaoCompraItemImpostoIcms.valorIcmsStRetido"
              placeholder="Valor ST. Retido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.baseCalculoFcpIcmsStRetido &&
          camposEscondidosIcms.aliquotaFcpIcmsStRetido &&
          camposEscondidosIcms.valorFcpIcmsStRetido
        }
      >
        <h5>Fundo Combate a Pobreza Retido</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoFcpIcmsStRetido}
              label="Base de Cálculo FCP ST. Retido"
              name="cotacaoCompraItemImpostoIcms.baseCalculoFcpIcmsStRetido"
              placeholder="Base de Cálculo FCP ST. Retido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaFcpIcmsStRetido}
              label="Alíquota"
              name="cotacaoCompraItemImpostoIcms.aliquotaFcpIcmsStRetido"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorFcpIcmsStRetido}
              label="Valor FCP ST. Retido"
              name="cotacaoCompraItemImpostoIcms.valorFcpIcmsStRetido"
              placeholder="Valor FCP ST. Retido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorUnitarioPautaIcmsSt &&
          camposEscondidosIcms.valorTotalPautaIcmsSt
        }
      >
        <h5>Pauta</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorUnitarioPautaIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorUnitarioPautaIcmsSt}
              label="Valor Unitário"
              name="cotacaoCompraItemImpostoIcms.valorUnitarioPautaIcmsSt"
              placeholder="Valor Unitário"
              casasInteiras={8}
              casasDecimais={10}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorTotalPautaIcmsSt}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorTotalPautaIcmsSt}
              label="Total"
              name="cotacaoCompraItemImpostoIcms.valorTotalPautaIcmsSt"
              placeholder="Total"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoIcmsStTab;
