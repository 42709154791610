"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloImpressaoPedidoVendaEnum;
(function (ModeloImpressaoPedidoVendaEnum) {
    ModeloImpressaoPedidoVendaEnum["retratoComRepresentante"] = "Retrato Com Representante";
    ModeloImpressaoPedidoVendaEnum["retratoSemFotoProduto"] = "Retrato (sem foto produto)";
    ModeloImpressaoPedidoVendaEnum["retratoComFotoProduto"] = "Retrato (com foto produto)";
    ModeloImpressaoPedidoVendaEnum["paisagemSemFotoProduto"] = "Paisagem (sem foto produto)";
    ModeloImpressaoPedidoVendaEnum["paisagemComFotoProduto"] = "Paisagem (com foto produto)";
    ModeloImpressaoPedidoVendaEnum["retratoComSemValoresItens"] = "Retrato Com e Sem Valores nos Itens";
    ModeloImpressaoPedidoVendaEnum["retratoComFotoProdutoComSemValoresItens"] = "Retrato Com e Sem Valores e Foto do Produto";
    ModeloImpressaoPedidoVendaEnum["retratoComFinanceiroTransporte"] = "Retrato Com Financeiro e Transporte";
    ModeloImpressaoPedidoVendaEnum["retratoComSemValoresItensTransporteFoto"] = "Retrato Com e Sem Valores, Transporte e Foto do Produto";
})(ModeloImpressaoPedidoVendaEnum || (ModeloImpressaoPedidoVendaEnum = {}));
exports.default = ModeloImpressaoPedidoVendaEnum;
