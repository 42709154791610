import React from 'react';
import { Spinner } from 'react-bootstrap';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import { LoadingContainer } from './styles';

interface ITextoLoading extends IPadraoProps {
  loading: boolean;
}

const TextoLoading: React.FC<ITextoLoading> = ({ loading, children }) => {
  if (loading) {
    return (
      <div style={{ position: 'relative' }}>
        <span style={{ opacity: 0 }}>{children}</span>
        <LoadingContainer>
          <Spinner
            className="spinner"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{
              width: '22px',
              height: '22px',
              fontSize: '10px',
              marginRight: '5px',
            }}
          />
        </LoadingContainer>

        {/* <LoadingContainer>
          <AiOutlineLoading3Quarters />
        </LoadingContainer> */}
      </div>
    );
  }
  return <>{children}</>;
};

export default TextoLoading;
