import styled from 'styled-components';

export const ThContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 7px;

  button {
    position: absolute;
    right: 7px;
    top: 0;
    /* width: 40px; */
    height: 100%;

    background: transparent;
    border: 0;
  }
`;

export const ArrowDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  svg:first-child {
    transform: rotate(180deg);
  }
`;
