import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-regra-escolha-tabela-preco-por-cliente';

interface IFrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicadorIndex {
  params: {
    idPessoa?: string;
  };
}

class FrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicador {
  public async show(
    params: IFrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicador();
