import React from 'react';
import { useLocation } from 'react-router-dom';
import AtualizacaoPrecoCustoProdutoLista from './Lista';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import AtualizacaoPrecoCustoProdutoHook from './Hooks';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';

const AtualizacaoPrecoCustoProdutoListagem: React.FC = () => {
  const location = useLocation();

  const state = location.state as ILocation;

  return (
    <ListaProvider>
      <AtualizacaoPrecoCustoProdutoHook>
        <AtualizacaoPrecoCustoProdutoLista
          dadosRecuperados={state?.dadosRecuperados ?? null}
        />
      </AtualizacaoPrecoCustoProdutoHook>
    </ListaProvider>
  );
};

export default AtualizacaoPrecoCustoProdutoListagem;
