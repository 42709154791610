import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import ConfiguracaoCREnologicoCultivarComunicador from '../../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Comunicador/ConfiguracaCREnologicoCultivarComunicador';
import FormHook from './FormHook';
import SubHeaderHook from './SubHeaderHook';
import RotasHook from './RotasHook';
import { UnidadeMedidaHook } from './UnidadeMedidaHook';
import { ProdutosEmUsoHook } from './ProdutosEmUsoHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const GrupoProdutoSisdevinHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: ConfiguracaoCREnologicoCultivarComunicador }}
    >
      <RotasHook>
        <FormHook>
          <UnidadeMedidaHook>
            <ProdutosEmUsoHook>
              <SubHeaderHook>{children}</SubHeaderHook>
            </ProdutosEmUsoHook>
          </UnidadeMedidaHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default GrupoProdutoSisdevinHook;
