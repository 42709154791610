import React from 'react';
import { v4 } from 'uuid';
import { Tab, Tabs } from 'react-bootstrap';
import ObservacoesTab from './ObservacoesTab';
import InformacaoFiscoTab from './InformacaoFiscoTab';

interface IInformacaoAdicionalTab {
  bloquearCamposPorImportacao: boolean;
}

const InformacaoAdicionalTab: React.FC<IInformacaoAdicionalTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <div>
      <Tabs id={v4()} defaultActiveKey="observacoes">
        <Tab eventKey="observacoes" title="Observações">
          <ObservacoesTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="informacaoFisco" title="Informações Fisco">
          <InformacaoFiscoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default InformacaoAdicionalTab;
