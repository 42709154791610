import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import NotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaComunicador';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { NotaFiscalEntradaImportacaoNotaFiscalSaidaHook } from '../../../../../Suprimentos/Compras/NotaFiscalEntradaImportacaoNotaFiscalSaidaHook';
import { NotaFiscalSaidaImportacaoNotaFiscalEntradaHook } from '../../../NotaFiscalSaidaImportacaoNotaFiscalEntradaHook';
import { NotaFiscalSaidaImportacaoPedidoVendaHook } from '../../../NotaFiscalSaidaImportacaoPedidoVendaHook';
import { NotaFiscalSaidaDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { NotaFiscalSaidaHook } from './NotaFiscalSaidaHook';
import RotasHook from './RotasHook';
import { SituacaoBloquearCamposHook } from './SituacaoBloquearCamposHook';
import SubHeaderHook from './SubHeaderHook';
import { NotaFiscalSaidaImportacaoOrdemServicoHook } from '../../../NotaFiscalSaidaImportacaoOrdemServicoHook';
import { SiTefHook } from '../../../FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';

const NotaFiscalSaidaHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: NotaFiscalSaidaComunicador }}>
      <RotasHook>
        <SiTefHook>
          <RegimeTributarioHook>
            <NotaFiscalSaidaDuplicarHook>
              <NotaFiscalSaidaImportacaoPedidoVendaHook>
                <NotaFiscalSaidaImportacaoNotaFiscalEntradaHook>
                  <NotaFiscalEntradaImportacaoNotaFiscalSaidaHook>
                    <NotaFiscalSaidaImportacaoOrdemServicoHook>
                      <FormHook>
                        <SituacaoBloquearCamposHook>
                          <SubHeaderHook>
                            <NotaFiscalSaidaHook>
                              {children}
                            </NotaFiscalSaidaHook>
                          </SubHeaderHook>
                        </SituacaoBloquearCamposHook>
                      </FormHook>
                    </NotaFiscalSaidaImportacaoOrdemServicoHook>
                  </NotaFiscalEntradaImportacaoNotaFiscalSaidaHook>
                </NotaFiscalSaidaImportacaoNotaFiscalEntradaHook>
              </NotaFiscalSaidaImportacaoPedidoVendaHook>
            </NotaFiscalSaidaDuplicarHook>
          </RegimeTributarioHook>
        </SiTefHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default NotaFiscalSaidaHooks;
