import React from 'react';

interface ILiDrag extends React.LiHTMLAttributes<HTMLLIElement> {
  index: number;
  id: string;
  mover(from: number, to: number): void;
}

let dragIndex: number;
let dragId: string;
const LiDrag: React.FC<ILiDrag> = ({
  children,
  index,
  mover,
  id,
  onDragStart,
  onDragOver,
  style,
  className = '',
  ...rest
}) => {
  return (
    <li
      onDragStart={(event) => {
        if (onDragStart) onDragStart(event);
        dragIndex = index;
        dragId = id;
      }}
      onDragOver={(event) => {
        if (onDragOver) onDragOver(event);
        if (index === dragIndex || dragId !== id) return;

        const alvoTamanho = event.currentTarget.getBoundingClientRect();

        const alvoCentro = (alvoTamanho.right - alvoTamanho.left) / 2;
        const draggedLeft = event.clientX - alvoTamanho.left;

        if (dragIndex < index && draggedLeft < alvoCentro) return;
        if (dragIndex > index && draggedLeft > alvoCentro) return;

        mover(dragIndex, index);
        dragIndex = index;
      }}
      draggable
      style={{
        cursor: 'grab',
        height: 'auto',
        minHeight: 30,
        padding: '0 5px',
        margin: '2px 5px 2px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...style,
      }}
      className={`li-valor ${className}`}
      {...rest}
    >
      {children}
    </li>
  );
};

export default LiDrag;
