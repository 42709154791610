import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/conta-contabil/log';

interface IContaContabilLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IContaContabilLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ContaContabilLogComunicador {
  public async index(
    params: IContaContabilLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IContaContabilLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ContaContabilLogComunicador();
