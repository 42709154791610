import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/ramo-atividade-profissao-auto-complete-tag';

interface IRamoAtividadeProfissaoAutoCompleteTagComunicadorIndex {
  params: any;
}

class RamoAtividadeProfissaoAutoCompleteTagComunicador {
  public async index(
    params: IRamoAtividadeProfissaoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RamoAtividadeProfissaoAutoCompleteTagComunicador();
