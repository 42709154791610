import { IProdutoEngenhariaCodigoReferenciaLista } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import { Container } from './styles';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheEngenhariaCodigoReferenciaProps {
  name: string;
}

const ProdutoEngenhariaCodigoReferenciaLista: React.FC<
  IListaDetalheEngenhariaCodigoReferenciaProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosProduto: permissao } = permissoes;
  const { isJanelaAutoComplete } = UseAutoComplete();

  const formPrincipal = UseForm();
  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IProdutoEngenhariaCodigoReferenciaLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IProdutoEngenhariaCodigoReferenciaLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IProdutoEngenhariaCodigoReferenciaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
      if (erro) return;

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [handleSalvarContinuar, isJanelaAutoComplete, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  useEffect(() => {
    formRefLista.current?.setDataInicial({});
  }, [formRefLista]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <div style={{ width: '100%' }}>
          <Row>
            <Col lg={10} md={8} sm={12} style={{ marginTop: 8 }}>
              <Input
                label="Código ou Referência para Pesquisa"
                name="codigoReferencia"
                placeholder="Código/Referência"
                maxLength={20}
              />
            </Col>
            <Col
              lg={2}
              md={4}
              sm={12}
              style={{ alignSelf: 'flex-end', marginTop: 8 }}
            >
              <BtnPadraoButton
                style={{}}
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </div>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '100%' }}>
                <span>Código ou Referência</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor.map((codigoReferenciaEngenharia) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(codigoReferenciaEngenharia.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{codigoReferenciaEngenharia.codigoReferencia}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(codigoReferenciaEngenharia.id);
                          }}
                          disabled={
                            !permissoes.SuprimentosProdutosProduto?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </Container>
  );
};

export default ProdutoEngenhariaCodigoReferenciaLista;
