import { IPedidoVendaRetratoComFinanceiroTransporteImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import PedidoVendaRetratoComFinanceiroTransporteImpressaoPDF from './PedidoVendaRetratoComFinanceiroTransporteImpressaoPDF';

interface IImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao {
  handleVisualizarPdf(
    data: IPedidoVendaRetratoComFinanceiroTransporteImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IPedidoVendaRetratoComFinanceiroTransporteImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao =
  (): IImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao => {
    async function handleVisualizarPdf(
      dados: IPedidoVendaRetratoComFinanceiroTransporteImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <PedidoVendaRetratoComFinanceiroTransporteImpressaoPDF
            dados={dados}
          />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IPedidoVendaRetratoComFinanceiroTransporteImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <PedidoVendaRetratoComFinanceiroTransporteImpressaoPDF
            dados={dados}
          />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao();
