"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoMovimentoEnum;
(function (TipoMovimentoEnum) {
    TipoMovimentoEnum["implantacaoEstoque"] = "Implanta\u00E7\u00E3o de Estoque";
    TipoMovimentoEnum["nfEntrada"] = "NF Entrada";
    TipoMovimentoEnum["nfSaida"] = "NF Sa\u00EDda";
    TipoMovimentoEnum["estornoNfEntradaAbertura"] = "Estorno NF Entrada (Abertura)";
    TipoMovimentoEnum["estornoNfEntradaDenegada"] = "Estorno NF Entrada (Denegada)";
    TipoMovimentoEnum["estornoNfEntradaCancelada"] = "Estorno NF Entrada (Cancelada)";
    TipoMovimentoEnum["estornoNfSaidaAbertura"] = "Estorno NF Saida (Abertura)";
    TipoMovimentoEnum["estornoNfSaidaDenegada"] = "Estorno NF Saida (Denegada)";
    TipoMovimentoEnum["estornoNfSaidaCancelada"] = "Estorno NF Saida (Cancelada)";
    TipoMovimentoEnum["exclusaoEstornoNfEntrada"] = "Exclus\u00E3o de Estorno NF Entrada";
    TipoMovimentoEnum["exclusaoEstornoNfSaida"] = "Exclus\u00E3o de Estorno NF Sa\u00EDda";
    TipoMovimentoEnum["ordemProducaoProdutoProduzido"] = "Ordem Produ\u00E7\u00E3o Produto Produzido";
    TipoMovimentoEnum["ordemProducaoConsumo"] = "Ordem Produ\u00E7\u00E3o Produto Consumido";
    TipoMovimentoEnum["ordemProducaoPerda"] = "Ordem Produ\u00E7\u00E3o Perda";
    TipoMovimentoEnum["reprocessamentoProducaoRetirada"] = "Reprocessamento Produ\u00E7\u00E3o Retirada";
    TipoMovimentoEnum["reprocessamentoProducaoRetorno"] = "Reprocessamento Produ\u00E7\u00E3o Retorno";
    TipoMovimentoEnum["estornoReprocessamentoProducaoRetirada"] = "Estorno Reprocessamento Produ\u00E7\u00E3o Retirada";
    TipoMovimentoEnum["estornoReprocessamentoProducaoRetorno"] = "Estorno Reprocessamento Produ\u00E7\u00E3o Retorno";
    TipoMovimentoEnum["reprocessamentoProducaoSaida"] = "Reprocessamento Produ\u00E7\u00E3o Sa\u00EDda";
    TipoMovimentoEnum["reprocessamentoProducaoEntrada"] = "Reprocessamento Produ\u00E7\u00E3o Entrada";
    TipoMovimentoEnum["estornoReprocessamentoProducaoSaida"] = "Estorno Reprocessamento Produ\u00E7\u00E3o Sa\u00EDda";
    TipoMovimentoEnum["estornoReprocessamentoProducaoEntrada"] = "Estorno Reprocessamento Produ\u00E7\u00E3o Entrada";
    TipoMovimentoEnum["nfSaidaEmTerceiro"] = "NF Sa\u00EDda (Em Terceiro)";
    TipoMovimentoEnum["estornoNfSaidaEmTerceiro"] = "Estorno NF Sa\u00EDda (Em Terceiro)";
    TipoMovimentoEnum["exclusaoEstornoNfSaidaEmTerceiro"] = "Exclus\u00E3o Estorno NF Sa\u00EDda (Em Terceiro)";
    TipoMovimentoEnum["nfEntradaEmTerceiro"] = "NF Entrada (Em Terceiro)";
    TipoMovimentoEnum["estornoNfEntradaEmTerceiro"] = "Estorno NF Entrada (Em Terceiro)";
    TipoMovimentoEnum["exclusaoEstornoNfEntradaEmTerceiro"] = "Exclus\u00E3o Estorno NF Entrada (Em Terceiro)";
    TipoMovimentoEnum["geracaoLoteRetirada"] = "Gera\u00E7\u00E3o Lote Retirada";
    TipoMovimentoEnum["geracaoLoteRetorno"] = "Gera\u00E7\u00E3o Lote Retorno";
    TipoMovimentoEnum["consignadoSaida"] = "Consignado - Sa\u00EDda";
    TipoMovimentoEnum["consignadoDevolucao"] = "Consignado - Devolu\u00E7\u00E3o";
    TipoMovimentoEnum["consignadoSaidaTerceiro"] = "Consignado - Sa\u00EDda (Terceiro)";
    TipoMovimentoEnum["consignadoDevolucaoTerceiro"] = "Consignado - Devolu\u00E7\u00E3o (Terceiro)";
    TipoMovimentoEnum["movimentoManualEntrada"] = "Movimento Manual de Entrada";
    TipoMovimentoEnum["movimentoManualSaida"] = "Movimento Manual de Sa\u00EDda";
    TipoMovimentoEnum["ordemDesmontagemEntrada"] = "Ordem Desmontagem Entrada";
    TipoMovimentoEnum["ordemDesmontagemSaida"] = "Ordem Desmontagem Sa\u00EDda";
    TipoMovimentoEnum["movimentacaoInternaEntreMercadoriasEntrada"] = "Movimenta\u00E7\u00E3o Interna Entre Mercadorias - Entrada";
    TipoMovimentoEnum["movimentacaoInternaEntreMercadoriasSaida"] = "Movimenta\u00E7\u00E3o Interna Entre Mercadorias - Sa\u00EDda";
    TipoMovimentoEnum["transferenciaEntreLocalEstoqueEntrada"] = "Transfer\u00EAncia Entre Locais de Estoque - Entrada";
    TipoMovimentoEnum["transferenciaEntreLocalEstoqueSaida"] = "Transfer\u00EAncia Entre Locais de Estoque - Sa\u00EDda";
    TipoMovimentoEnum["reservaOrdemServicoSaida"] = "Reserva Ordem Servi\u00E7o - Sa\u00EDda";
    TipoMovimentoEnum["reservaOrdemServico"] = "Reserva Ordem Servi\u00E7o";
    TipoMovimentoEnum["reservaOrdemServicoRetorno"] = "Reserva Ordem Servi\u00E7o - Retorno";
    TipoMovimentoEnum["reservaOrdemServicoCancelamento"] = "Reserva Ordem Servi\u00E7o - Cancelamento";
    TipoMovimentoEnum["reservaOrdemServicoExclusao"] = "Reserva Ordem Servi\u00E7o - Exclus\u00E3o";
    TipoMovimentoEnum["reservaPedidoVenda"] = "Reserva Pedido de Venda";
    TipoMovimentoEnum["disponibilizarReservaPedidoVenda"] = "Disponibilizar Reserva Pedido de Venda";
    TipoMovimentoEnum["reservaPorAberturaNf"] = "Reserva por Abertura da NF";
    TipoMovimentoEnum["reservaPorCancelamentoNf"] = "Reserva por Cancelamento da NF";
    TipoMovimentoEnum["reservaPorDenegacaoNf"] = "Reserva por Denega\u00E7\u00E3o da NF";
    TipoMovimentoEnum["reservaPorEstornoNf"] = "Reserva por Estorno da NF";
    TipoMovimentoEnum["nfEntradaEntradaEmTerceiro"] = "NF Entrada (Entrada em Terceiro)";
    TipoMovimentoEnum["estornoNfEntradaEntradaEmTerceiro"] = "Estorno NF Entrada (Entrada em Terceiro)";
    TipoMovimentoEnum["exclusaoEstornoNfEntradaEntradaEmTerceiro"] = "Exclus\u00E3o Estorno NF Entrada (Entrada em Terceiro)";
})(TipoMovimentoEnum || (TipoMovimentoEnum = {}));
exports.default = TipoMovimentoEnum;
