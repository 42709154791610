import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IConhecimentoTransporteTerceiroPorTomadorRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IConhecimentoTransporteTerceiroPorTomadorPDF {
  dados: IConhecimentoTransporteTerceiroPorTomadorRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ConhecimentoTransporteTerceiroPorTomadorPDF: React.FC<
  IConhecimentoTransporteTerceiroPorTomadorPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataInicial &&
                  dados.cabecalho.filtroDataFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataInicial} até ${dados.cabecalho.filtroDataFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo de Data: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData.ToLowerCasePrimeiraMaiuscula()}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTomadores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tomadores: '}
                      </Text>

                      {dados.cabecalho.filtroTomadores}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroEmitentes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Emitentes: '}
                      </Text>
                      {dados.cabecalho.filtroEmitentes}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRemetentes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Remetentes: '}
                      </Text>
                      {dados.cabecalho.filtroRemetentes}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDestinatarios && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Destinatários: '}
                      </Text>
                      {dados.cabecalho.filtroDestinatarios}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRecebedores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Recebedores: '}
                      </Text>
                      {dados.cabecalho.filtroRecebedores}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroExpedidores && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Expedidores: '}
                      </Text>
                      {dados.cabecalho.filtroExpedidores}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNumerosNotas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Números das Notas: '}
                      </Text>
                      {dados.cabecalho.filtroNumerosNotas}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroChavesAcesso && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Chaves de Acesso: '}
                      </Text>
                      {dados.cabecalho.filtroChavesAcesso}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%' }}>
              <Text>Série</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>Dt.Emissão</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text>Notas Fiscais</Text>
            </View>
            <View style={{ width: '2%' }} />

            <View style={{ width: '25%' }}>
              <Text>Remetente</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '25%' }}>
              <Text>Destinatário</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '11%', alignItems: 'flex-end' }}>
              <Text>Valor</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View>
                      <Text
                        style={{
                          borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                          flexDirection: 'row',
                          padding: '5pt 0',
                          fontWeight: 'bold',
                          marginLeft: 2,
                          fontSize: 9,
                        }}
                      >
                        Tomador: {dado.chave}
                      </Text>
                    </View>

                    <View>
                      {dado.listaConhecimentoTransporteTerceiros.map(
                        (
                          conhecimentoTransporteTerceiros,
                          conhecimentoTransporteTerceirosIndex
                        ) => {
                          return (
                            <View key={String(2)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  fontSize: 9,
                                  backgroundColor:
                                    conhecimentoTransporteTerceirosIndex % 2 ===
                                    0
                                      ? '#E4E4E4'
                                      : '#fff',
                                }}
                              >
                                <View style={{ width: '5%' }}>
                                  <Text>
                                    {conhecimentoTransporteTerceiros.serie}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '7%' }}>
                                  <Text>
                                    {conhecimentoTransporteTerceiros.numero}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '7%' }}>
                                  <Text>
                                    {
                                      conhecimentoTransporteTerceiros.dataEmissao
                                    }
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '13%' }}>
                                  <Text>
                                    {
                                      conhecimentoTransporteTerceiros.numeroNotasFiscais
                                    }
                                  </Text>
                                </View>
                                <View style={{ width: '2%' }} />

                                <View style={{ width: '25%' }}>
                                  <Text>
                                    {conhecimentoTransporteTerceiros.remetente}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '25%' }}>
                                  <Text>
                                    {
                                      conhecimentoTransporteTerceiros.destinatario
                                    }
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '11%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {conhecimentoTransporteTerceiros.valorTotal}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}

                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 9,
                        }}
                      >
                        <View style={{ width: '92%' }}>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginLeft: 2,
                            }}
                          >
                            Total do Tomador: {dado.valores.total}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View
                          style={{
                            width: '7%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioValor}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
            }}
          >
            <View style={{ width: '90%' }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                  marginLeft: 2,
                }}
              >
                Total Geral: {dados.totalGeral}
              </Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '9%',
                alignItems: 'flex-end',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>
                {dados.somatorioGeralValorTotal}
              </Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ConhecimentoTransporteTerceiroPorTomadorPDF;
