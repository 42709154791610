import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoContatoEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompletePessoaContato from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoaContato';
import ListaDetalheComercialRepresentante from './ListaDetalheComercialRepresentante/Lista';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import PedidoVendaComercialRepresentanteHook from './ListaDetalheComercialRepresentante/Hooks';

const InformacaoComercialTab: React.FC = () => {
  const { formRef } = UseForm();
  const idPessoa = formRef.current?.getFieldValue('pedidoVendaPessoa.idPessoa');

  useEffect(() => {
    if (!idPessoa) {
      formRef.current?.clearField('pedidoVendaComercial.pessoaContato', {
        setValorInicial: false,
      });
    }
  }, [idPessoa, formRef]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompletePessoaContato
              label="Contato"
              name="pedidoVendaComercial.idPessoaContato"
              placeholder="Contato"
              nomeObjeto="pedidoVendaComercial.pessoaContato"
              idPessoa={idPessoa}
              disabled={!idPessoa}
              tipoContato={TipoContatoEnum.pedidoVenda}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <PedidoVendaComercialRepresentanteHook>
              <ListaDetalheComercialRepresentante name="pedidoVendaComercial.listaPedidoVendaComercialRepresentante" />
            </PedidoVendaComercialRepresentanteHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default InformacaoComercialTab;
