import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-ordem-servico/importar';

interface INotaFiscalSaidaImportacaoOrdemServicoImportarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoOrdemServicoImportarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoOrdemServicoImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoOrdemServicoImportarComunicador();
