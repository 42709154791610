"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IsPagamentoTef;
const Enum_1 = require("../Enum");
function IsPagamentoTef(meioPagamento, pagamentoIntegradoTef) {
    const meioPagamentoTef = meioPagamento === Enum_1.MeioPagamentoEnum.cartaoCredito ||
        meioPagamento === Enum_1.MeioPagamentoEnum.cartaoDebito ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeAlimentaca ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeRefeicao ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeCombustivel ||
        meioPagamento === Enum_1.MeioPagamentoEnum.pagamentoInstantaneoPix;
    return meioPagamentoTef && pagamentoIntegradoTef;
}
