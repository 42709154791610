import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  AppErro,
  IOrdemDesmontagemLocalEstoqueValoresInserir,
  SituacaoOrdemDesmontagemEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { UseOrdemDesmontagem } from '../../Hooks/OrdemDesmontagemHook';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { handleAlterarSituacao } = UseOrdemDesmontagem();

  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleAlterarSituacaoDesmontagem = useCallback(
    (
      listaOrdemDesmontagemLocalEstoque: IOrdemDesmontagemLocalEstoqueValoresInserir[]
    ) => {
      const listaOrdemDesmontagemItem =
        formPrincipal.formRef.current?.getFieldValue(
          'listaOrdemDesmontagemItem'
        );
      const dataHoraInicio =
        formPrincipal.formRef.current?.getFieldValue('dataHoraInicio');
      const dataHoraTermino =
        formPrincipal.formRef.current?.getFieldValue('dataHoraTermino');
      const quantidade =
        formPrincipal.formRef.current?.getFieldValue('quantidade');

      const alterarSituacao = handleAlterarSituacao({
        listaOrdemDesmontagemItem,
        listaOrdemDesmontagemLocalEstoque,
        dataHoraInicio,
        dataHoraTermino,
        quantidade,
      });

      if (alterarSituacao) {
        formPrincipal.formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemDesmontagemEnum.concluida
        );
      } else {
        formPrincipal.formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemDesmontagemEnum.emAndamento
        );
      }
    },
    [formPrincipal.formRef, handleAlterarSituacao]
  );

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      isOrdemDesmontagemCadastrado?: boolean
    ): Promise<boolean> => {
      try {
        const produtoObjeto =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto('produto');
        const dataHoraInicio =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'dataHoraInicio'
          );
        const dataHoraTermino =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'dataHoraTermino'
          );

        const showLote =
          produtoObjeto && produtoObjeto.produtoEstoque
            ? produtoObjeto.produtoEstoque.controlarLote
            : false;
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idLocalEstoque: Yup.string()
            .required('Local Estoque é Obrigatório')
            .nullable(),
          idLote: Yup.string()
            .nullable()
            .test({
              message: 'Lote deve ser Informado!',
              test: (value: any) => {
                return !(showLote && !value);
              },
            }),
          quantidade: Yup.number()
            .required('Quantidade é Obrigatória!')
            .positive('Quantidade é Obrigatória!'),
          dataHoraConsumo: Yup.date()
            .nullable()
            .test({
              message: 'Data Hora Consumo é Obrigatória!',
              test: (value: any) => {
                return !!value;
              },
            })
            .test({
              message:
                'Data Hora Consumo deve ser maior que a Data e Hora de Início!',
              test: (value: any) => {
                if (value && dataHoraInicio && value < dataHoraInicio)
                  return false;

                return true;
              },
            })
            .test({
              message:
                'Data Hora Consumo deve ser menor que a Data e Hora de Térrmino!',
              test: (value: any) => {
                if (value && dataHoraTermino && value > dataHoraTermino)
                  return false;

                return true;
              },
            }),
        });
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isOrdemDesmontagemCadastrado !== false) {
          const errors = GetValidationErrors(error as any);
          formRef?.setErrors(errors);
        }
        return false;
      }
    },
    [formPrincipal.formRef]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        const id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'listaOrdemDesmontagemLocalEstoque'
        );

        formPrincipal.formRef.current?.setFieldValue(
          'listaOrdemDesmontagemLocalEstoque',
          [...listaDados, data]
        );

        handleAlterarSituacaoDesmontagem([...listaDados, data]);

        formPrincipal.formRef.current?.setFieldValue('listaEstoqueMudou', true);

        await formRef?.reset();
        setLoading(false);
        formPrincipal.setLoading(false);
        formPrincipal.refresh();
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleAlterarSituacaoDesmontagem,
      handleValidar,
      redirecionar,
    ]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (!resposta) return;

      setLoading(true);
      formPrincipal.setLoading(true);

      const listaDados = formPrincipal.formRef.current?.getFieldValue(
        'listaOrdemDesmontagemLocalEstoque'
      );
      listaDados.splice(Number(index), 1);

      formPrincipal.formRef.current?.setFieldValue(
        'listaOrdemDesmontagemLocalEstoque',
        listaDados
      );

      handleAlterarSituacaoDesmontagem(listaDados);

      formPrincipal.formRef.current?.setFieldValue('listaEstoqueMudou', true);

      setLoading(false);
      formPrincipal.setLoading(false);
      formPrincipal.refresh();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
