import { IOrdemServicoValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico/obter-itens-calculo-rateios';

interface IObterOrdemServicoItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterOrdemServicoItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterOrdemServicoItensParaCalculoRateiosComunicadorIndex
  ): Promise<IOrdemServicoValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterOrdemServicoItensParaCalculoRateiosComunicador();
