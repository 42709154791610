import {
  FormatarDataHoraParaPtBr,
  ICurvaABCNotaFiscalEntradaFiltroRelatorioBackend,
  ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend,
  ICurvaABCNotaFiscalEntradaProdutoPorValorCabecalhoRelatorio,
  ICurvaABCNotaFiscalEntradaProdutoPorValorRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import CurvaABCNotaFiscalEntradaProdutoPorValorRelatorioComunicador from '../../../../../../../Comunicador/Suprimentos/Relatorios/Compras/CurvaABCNFEnt/CurvaABCNotaFiscalEntradaProdutoPorValorRelatorio/CurvaABCNotaFiscalEntradaProdutoPorValorRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import CurvaABCNotaFiscalEntradaProdutoPorValorPDF from './CurvaABCNotaFiscalEntradaProdutoPorValorPDF';
import CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha from './CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const CurvaABCNotaFiscalEntradaProdutoPorValorRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<ICurvaABCNotaFiscalEntradaProdutoPorValorRelatorio | null> => {
    const filtroBack: ICurvaABCNotaFiscalEntradaFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCliente) {
      filtroBack.listaIdCliente =
        filtros.listaCliente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupo =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    const response =
      await CurvaABCNotaFiscalEntradaProdutoPorValorRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno =
      response as ICurvaABCNotaFiscalEntradaProdutoPorValorRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        return {
          ...dados,
          valor: Number(dados.valor).FormatarParaPtBr(),
          percentual: Number(dados.percentual).FormatarParaPtBr(),
          percentualAcumulado: Number(
            dados.percentualAcumulado
          ).FormatarParaPtBr(),
        };
      })
    );

    return {
      ...retorno,
      valorTotal: Number(retorno.valorTotal).FormatarParaPtBr(),
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend,
    cabecalho: ICurvaABCNotaFiscalEntradaProdutoPorValorCabecalhoRelatorio
  ): ICurvaABCNotaFiscalEntradaProdutoPorValorCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCliente = filtros.listaCliente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha,
      nomeArquivo: 'CurvaABCNotaFiscalEntradaProdutoPorValor',
      valores: dados.listaDados,
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await Planilha({
      campos: CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha,
      nomeArquivo: 'CurvaABCNotaFiscalEntradaProdutoPorValor',
      valores: dados.listaDados as any,
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <CurvaABCNotaFiscalEntradaProdutoPorValorPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: ICurvaABCNotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <CurvaABCNotaFiscalEntradaProdutoPorValorPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default CurvaABCNotaFiscalEntradaProdutoPorValorRelatorio();
