import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import Textarea from '../../../../../Componentes/Inputs/Textarea';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import InputIntervaloValores, {
  IInputIntervaloValoresRef,
} from '../../../../../Componentes/Inputs/InputIntervaloValores';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataRef = useRef<any>(null);
  const intervaloValoresRef = useRef<IInputIntervaloValoresRef>(null);
  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const numeroDocumento = parametros.pesquisaAvancada?.numeroDocumento;
      if (numeroDocumento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Documento',
          valor: numeroDocumento,
        });
      }

      const listaMovimentoPortadorOrigem =
        parametros.pesquisaAvancada?.listaMovimentoPortadorOrigem;
      if (listaMovimentoPortadorOrigem.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: '(-) Portador de Origem',
          valor: listaMovimentoPortadorOrigem.reduce(
            (acc: string, item: any) => {
              acc += `${item.descricao}; `;
              return acc;
            },
            ''
          ),
        });
      }

      const listaMovimentoPortadorDestino =
        parametros.pesquisaAvancada?.listaMovimentoPortadorDestino;
      if (listaMovimentoPortadorDestino.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: '(+) Portador Destino',
          valor: listaMovimentoPortadorDestino.reduce(
            (acc: string, item: any) => {
              acc += `${item.descricao}; `;
              return acc;
            },
            ''
          ),
        });
      }

      const periodoData = periodoDataRef.current?.getPeriodo();
      if (periodoData) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data',
          valor: periodoData,
        });
      }

      const intervaloValores = intervaloValoresRef.current?.getIntervalo();
      if (intervaloValores) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Valores',
          valor: intervaloValores,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={4}>
                <Input name="numeroDocumento" label="Número Documento" />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagPortador
                  name="listaMovimentoPortadorOrigem"
                  label="(-) Portador de Origem"
                  placeholder="Portador de Origem"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPortador
                  name="listaMovimentoPortadorDestino"
                  label="(+) Portador de Destino"
                  placeholder="Portador de Destino"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12}>
                <Textarea name="observacoes" label="Observações" />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <InputPeriodoData
                  labelDataInicial="Data Inicial"
                  labelDataFinal="Data Final"
                  nomeDataInicial="dataInicial"
                  nomeDataFinal="dataFinal"
                  ref={periodoDataRef}
                />
              </Col>

              <Col lg={6}>
                <InputIntervaloValores
                  nomeValorInicial="valorInicial"
                  nomeValorFinal="valorFinal"
                  labelValorInicial="Valor Inicial"
                  labelValorFinal="Valor Final"
                  ref={intervaloValoresRef}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
