import { TipoPedidoVendaEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/obter-pedido-venda';

interface IFrenteCaixaPdvObterPedidoVendaComunicadorIndex {
  params: {
    tipoPedidoVenda?: TipoPedidoVendaEnum;
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFrenteCaixaPdvObterPedidoVendaComunicadorShow {
  id: string;
}

class FrenteCaixaPdvObterPedidoVendaComunicador {
  public async index(
    params: IFrenteCaixaPdvObterPedidoVendaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IFrenteCaixaPdvObterPedidoVendaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterPedidoVendaComunicador();
