import ToastInfo from '../Toasts/ToastInfo';

export default function TratarInfo(listaInfo: string[]): void {
  if (listaInfo.length > 0) {
    for (let i = 0; i < listaInfo.length; i += 1) {
      const mensagem = listaInfo[i];
      ToastInfo(mensagem, { autoClose: 4000 + i * 1000 });
    }
  }
}
