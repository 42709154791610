import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/consultar-protocolo';

interface INotaFiscalEntradaConsultarProtocoloComunicadorUpdate {
  id: string;
}

class NotaFiscalEntradaConsultarProtocoloComunicador {
  public async update(
    params: INotaFiscalEntradaConsultarProtocoloComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaConsultarProtocoloComunicador();
