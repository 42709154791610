"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CabecalhoArquivo01SistemaEnum;
(function (CabecalhoArquivo01SistemaEnum) {
    CabecalhoArquivo01SistemaEnum["contabilidade"] = "1";
    CabecalhoArquivo01SistemaEnum["caixa"] = "2";
    CabecalhoArquivo01SistemaEnum["outro"] = "0";
})(CabecalhoArquivo01SistemaEnum || (CabecalhoArquivo01SistemaEnum = {}));
exports.default = CabecalhoArquivo01SistemaEnum;
