"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ModeloImpressaoOrcamentoEnum;
(function (ModeloImpressaoOrcamentoEnum) {
    ModeloImpressaoOrcamentoEnum["retratoSemFotoProduto"] = "Retrato (sem foto produto)";
    ModeloImpressaoOrcamentoEnum["paisagemSemFotoProduto"] = "Paisagem (sem foto produto)";
    ModeloImpressaoOrcamentoEnum["retratoComFotoProduto"] = "Retrato (com foto produto)";
    ModeloImpressaoOrcamentoEnum["paisagemComFotoProduto"] = "Paisagem (com foto produto)";
    ModeloImpressaoOrcamentoEnum["retratoComSemValoresItens"] = "Retrato com e sem Valores nos Itens";
    ModeloImpressaoOrcamentoEnum["paisagemComNcmCst"] = "Paisagem com NCM e CST";
    ModeloImpressaoOrcamentoEnum["retratoComFinanceiroTransporte"] = "Retrato Com Financeiro e Transporte";
    ModeloImpressaoOrcamentoEnum["paisagemComFinanceiroPrevisaoEntrega"] = "Paisagem Com Financeiro e Previs\u00E3o de Entrega";
    ModeloImpressaoOrcamentoEnum["paisagemComFinanceiroPrevisaoEntregaRepresentante"] = "Paisagem Com Financeiro, Previs\u00E3o de Entrega e Representante";
    ModeloImpressaoOrcamentoEnum["retratoComPrevisaoEntregaEmDias"] = "Retrato com Prev. Entrega em Dias";
})(ModeloImpressaoOrcamentoEnum || (ModeloImpressaoOrcamentoEnum = {}));
exports.default = ModeloImpressaoOrcamentoEnum;
