const ComissoesNotaFiscalPorFaturamentoPorItemComClientePlanilha = [
  {
    titulo: 'Representante',
    valor: 'listaComissoesNotaFiscalItem.representanteNomeRazaoSocial',
  },
  {
    titulo: 'Data Emissão',
    valor: 'listaComissoesNotaFiscalItem.notaFiscalSaidaDataHoraEmissao',
  },

  {
    titulo: 'Tipo ',
    valor: 'listaComissoesNotaFiscalItem.tipo',
  },

  {
    titulo: 'Número',
    valor: 'listaComissoesNotaFiscalItem.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Produto',
    valor: 'listaComissoesNotaFiscalItem.produto',
  },

  {
    titulo: 'Cliente',
    valor: 'listaComissoesNotaFiscalItem.clienteNomeRazaoSocial',
  },

  {
    titulo: 'Base Comissão',
    valor: 'listaComissoesNotaFiscalItem.baseCalculo',
  },

  {
    titulo: 'Percentual',
    valor: 'listaComissoesNotaFiscalItem.aliquota',
  },
  {
    titulo: 'Valor',
    valor: 'listaComissoesNotaFiscalItem.valor',
  },
];

export default ComissoesNotaFiscalPorFaturamentoPorItemComClientePlanilha;
