import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import {
  IOrdemProducaoItemLocalEstoqueLista,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import { VscSaveAs } from 'react-icons/vsc/index.mjs';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import EstoqueTab from './EstoqueTab';
import CustoTab from './CustosTab';
import GeralTab from './GeralTab';
import OutrasInformacoesTab from './OutrasInformacoesTab';

interface IOrdemProducaoItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const OrdemProducaoItemDetalhe: React.FC<IOrdemProducaoItemModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemProducao: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefDetalhe,
    loading,
    refresh,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const { telaPequena } = UseReactSizeMeBodyHook();
  const [tabSelecionada, setTabSelecionada] = useState('estoques');
  const [tabSelecionadaPrincipal, setTabSelecionadaPrincipal] =
    useState('geral');

  const handleValidarItemConsumido = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const listaItemLocalEstoque =
      formRefDetalhe.current?.getFieldValue(
        'listaOrdemProducaoItemLocalEstoque'
      ) ?? [];

    const quantidadeDemandaTotalDetalhe = formRefDetalhe.current?.getFieldValue(
      'quantidadeDemandaTotal'
    );

    const somaQuantidadeLocalEstoque = listaItemLocalEstoque.reduce(
      (acumulador: number, item: IOrdemProducaoItemLocalEstoqueLista) => {
        acumulador += Number(item.quantidade) || 0;
        return acumulador;
      },
      0
    );

    if (
      produto?.tipoProdutoServico === TipoProdutoServicoEnum.servico ||
      (listaItemLocalEstoque.length > 0 &&
        somaQuantidadeLocalEstoque === quantidadeDemandaTotalDetalhe)
    ) {
      formRefDetalhe.current?.setFieldValue('consumido', true);
    } else {
      formRefDetalhe.current?.setFieldValue('consumido', false);
    }
  }, [formRefDetalhe]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    await handleValidarItemConsumido();

    refresh();
    const data = formRefDetalhe.current?.getDataDuplicar();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    formRefDetalhe,
    handleSubmit,
    handleValidarItemConsumido,
    onSalvarFormModal,
    refresh,
  ]);

  const handleClickSalvarContinuar = useCallback(async (): Promise<void> => {
    await handleValidarItemConsumido();

    const data = formRefDetalhe.current?.getDataDuplicar();

    const { id } = await handleSubmit(data, formRefDetalhe.current);

    const listaDados = formPrincipal.formRef.current?.getFieldValue(
      'listaOrdemProducaoItem'
    );

    setIdDetalheRegistro(id);

    formRefDetalhe.current?.setDataInicial(listaDados[Number(id)]);
  }, [
    formPrincipal.formRef,
    formRefDetalhe,
    handleSubmit,
    handleValidarItemConsumido,
    setIdDetalheRegistro,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Itens Consumidos"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <JanelaNavegacao
                  containerStyle={
                    telaPequena
                      ? {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                      : {
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          marginBottom: 10,
                        }
                  }
                />
              </Col>
            </Row>

            <Tabs
              id={v4()}
              defaultActiveKey="itens"
              activeKey={tabSelecionadaPrincipal}
              onSelect={(k) => setTabSelecionadaPrincipal(k || '')}
            >
              <Tab eventKey="geral" title="Geral">
                <GeralTab />
              </Tab>

              <Tab eventKey="outrasInformacoes" title="Outras Informações">
                <OutrasInformacoesTab />
              </Tab>
            </Tabs>

            <Tabs
              id={v4()}
              defaultActiveKey="itens"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="estoques" title="Estoques">
                <EstoqueTab />
              </Tab>

              <Tab eventKey="custos" title="Custos">
                <CustoTab />
              </Tab>
            </Tabs>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvarContinuar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default OrdemProducaoItemDetalhe;
