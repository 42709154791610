import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/documento-referenciado';

interface IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorUpdate {
  params: any;
  id: string;
}

interface IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorDeletar {
  id: string;
}

interface IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorShow {
  id: string;
}

class ConhecimentoTransporteTerceiroDocumentoReferenciadoComunicador {
  public async store(
    params: IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async show(
    params: IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroDocumentoReferenciadoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroDocumentoReferenciadoComunicador();
