import api from '../../../../../Comum/Services/Api';

const rota = '/suprimentos/pesagens/item-avaliacao-pesagem/ordem';

interface IItemAvaliacaoPesagemOrdemComunicadorUpdate {
  params: any;
}

class ItemAvaliacaoPesagemOrdemComunicador {
  public async update(
    params: IItemAvaliacaoPesagemOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ItemAvaliacaoPesagemOrdemComunicador();
