import api from '../../../../Comum/Services/Api';

const rota = 'documentos-eletronicos/salvar-pdf';

interface ISalvarPDFComunicadorIndex {
  params: {
    salvarTudo: boolean;

    dataEmissaoInicial?: Date;
    dataEmissaoFinal?: Date;

    notaFiscalSaidaPdf?: boolean;
    notaFiscalSaidaEventoPdf?: boolean;

    notaFiscalEntradaPropriaPdf?: boolean;
    notaFiscalEntradaPropriaEventoPdf?: boolean;

    notaFiscalEntradaTerceiroPdf?: boolean;

    conhecimentoTransportePdf?: boolean;
    conhecimentoTransporteEventoPdf?: boolean;

    conhecimentoTransporteTerceiroPdf?: boolean;
  };
}

class SalvarPDFComunicador {
  public async index(params: ISalvarPDFComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SalvarPDFComunicador();
