import {
  BooleanEnum,
  FormatarEnum,
  TipoBuscaCodigoDescricaoEnum,
  FormatarDataHoraParaPtBr,
  TipoOrdenacaoEnum,
  TipoDataAtualizacaoProdutoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import Select from '../../../../../Componentes/Select';
import { CheckboxContainer, Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputCheckbox from '../../../../../Componentes/Inputs/InputCheckbox';
import InputDateTime from '../../../../../Componentes/Inputs/InputDateTime';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteTagFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFornecedor';
import InputPeriodoDataHora from '../../../../../Componentes/Inputs/InputPeriodoDataHora';

interface IPesquisaAvancada {
  permitirAtualizar?: () => Promise<boolean>;
}

const PesquisaAvancada: React.FC<IPesquisaAvancada> = ({
  permitirAtualizar,
}) => {
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handleSubmit = useCallback(async () => {
    if (await handlePermitirAtualizar()) {
      const data = formRef.current?.getDataPesquisa();
      setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

      fecharPesquisaAvancada();
    }
  }, [fecharPesquisaAvancada, handlePermitirAtualizar, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const dataHoraReferencia =
        parametros.pesquisaAvancada?.dataHoraReferencia;
      if (dataHoraReferencia) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data de Referência',
          valor: FormatarDataHoraParaPtBr(dataHoraReferencia),
        });
      }

      const codigoBarras = parametros.pesquisaAvancada?.codigoBarras;
      if (codigoBarras) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código de Barras',
          valor: codigoBarras,
        });
      }

      const ordenacaoPesquisa = parametros.pesquisaAvancada?.ordenacaoPesquisa;
      if (ordenacaoPesquisa) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ordenação da Pesquisa',
          valor: ordenacaoPesquisa,
        });
      }

      const somenteProdutoComSaldo =
        parametros.pesquisaAvancada?.somenteProdutoComSaldo;
      if (somenteProdutoComSaldo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Som. Prod. com Saldo',
          valor: 'Sim',
        });
      }

      const somenteProdutoSemGrupo =
        parametros.pesquisaAvancada?.somenteProdutoSemGrupo;
      if (somenteProdutoSemGrupo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Som. Prod. sem Grupo',
          valor: 'Sim',
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const produtoCodigo = parametros.pesquisaAvancada?.produtoCodigo;
      if (produtoCodigo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código',
          valor: produtoCodigo,
        });
      }

      const produtoDescricao = parametros.pesquisaAvancada?.produtoDescricao;
      if (produtoDescricao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Descrição',
          valor: produtoDescricao,
        });
      }

      const produtoFiltroCodigoDescricao =
        parametros.pesquisaAvancada?.produtoFiltroCodigoDescricao;
      if (produtoFiltroCodigoDescricao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Filtro Código/Descrição',
          valor: produtoFiltroCodigoDescricao,
        });
      }

      const produtoAtivo = parametros.pesquisaAvancada?.produtoAtivo;
      if (!produtoAtivo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produto Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produto Ativo',
          valor: produtoAtivo === 'true' ? 'Sim' : 'Não',
        });
      }

      const listaGrupoProdutoServico =
        parametros.pesquisaAvancada?.listaGrupoProdutoServico;
      if (listaGrupoProdutoServico.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Grupos',
          valor: listaGrupoProdutoServico.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const numeroNota = parametros.pesquisaAvancada?.numeroNota;
      if (numeroNota) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Nota',
          valor: numeroNota,
        });
      }

      const ultimosCadastrados =
        parametros.pesquisaAvancada?.ultimosCadastrados;
      if (ultimosCadastrados) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Últimos Cadastrados',
          valor: ultimosCadastrados,
        });
      }

      const ultimosComprados = parametros.pesquisaAvancada?.ultimosComprados;
      if (ultimosComprados) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Últimos Comprados',
          valor: ultimosComprados,
        });
      }

      const listaFornecedor = parametros.pesquisaAvancada?.listaFornecedor;
      if (listaFornecedor.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Fornecedores',
          valor: listaFornecedor.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo} - ${valorAtual.nomeRazaoSocial}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const dataInicial = parametros.pesquisaAvancada?.dataInicial;
      if (dataInicial) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data Inicial',
          valor: FormatarDataHoraParaPtBr(dataInicial),
        });
      }

      const dataFinal = parametros.pesquisaAvancada?.dataFinal;
      if (dataFinal) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data Final',
          valor: FormatarDataHoraParaPtBr(dataFinal),
        });
      }

      const tipoData = parametros.pesquisaAvancada?.tipoData;
      if (tipoData) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Data',
          valor: tipoData,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ordenacaoPesquisa: TipoOrdenacaoEnum.codigo,
        produtoFiltroCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
        produtoAtivo: 'true',
        tipoData: TipoDataAtualizacaoProdutoEnum.emissaoNota,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <Divisor>
            <Row>
              <Col lg={5} md={12}>
                <InputDateTime
                  name="dataHoraReferencia"
                  label="Data de Referência"
                />
              </Col>
              <Col lg={4} md={12}>
                <Input
                  name="codigoBarras"
                  label="Código de Barras"
                  maxLength={14}
                />
              </Col>
              <Col lg={3} md={12}>
                <Select
                  name="ordenacaoPesquisa"
                  label="Ordenação da Pesquisa"
                  options={FormatarEnum({
                    enumObj: TipoOrdenacaoEnum,
                  })}
                />
              </Col>

              <Col
                lg={6}
                md={12}
                className="div-checkbox"
                style={{ marginTop: 15 }}
              >
                <CheckboxContainer>
                  <InputCheckbox
                    label="Somente Produtos com Saldo"
                    name="somenteProdutoComSaldo"
                  />
                </CheckboxContainer>
              </Col>
              <Col
                lg={6}
                md={12}
                className="div-checkbox"
                style={{ marginTop: 15 }}
              >
                <CheckboxContainer>
                  <InputCheckbox
                    label="Somente Produtos sem Grupo"
                    name="somenteProdutoSemGrupo"
                  />
                </CheckboxContainer>
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagProduto
                  label="Produtos"
                  name="listaProduto"
                />
              </Col>

              <Col lg={4} md={6}>
                <Input name="produtoCodigo" label="Código" />
              </Col>
              <Col lg={8} md={6}>
                <Input name="produtoDescricao" label="Descrição" />
              </Col>

              <Col lg={6} md={6}>
                <Select
                  name="produtoFiltroCodigoDescricao"
                  label="Filtro Código/Descrição"
                  options={FormatarEnum({
                    enumObj: TipoBuscaCodigoDescricaoEnum,
                  })}
                />
              </Col>
              <Col lg={6} md={6}>
                <Select
                  name="produtoAtivo"
                  label="Produto Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagGrupoProdutoServico
                  name="listaGrupoProdutoServico"
                  label="Grupos"
                />
              </Col>
            </Row>
          </Divisor>

          <Divisor>
            <h5>Entradas</h5>
            <Row>
              <Col lg={4} md={12}>
                <InputInteiroNulavel name="numeroNota" label="Número Nota" />
              </Col>
              <Col lg={4} md={12}>
                <InputInteiroNulavel
                  name="ultimosCadastrados"
                  label="Últimos Cadastrados"
                />
              </Col>
              <Col lg={4} md={12}>
                <InputInteiroNulavel
                  name="ultimosComprados"
                  label="Últimos Comprados"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagFornecedor
                  label="Fornecedores"
                  name="listaFornecedor"
                />
              </Col>

              <Col lg={12} sm={12}>
                <InputPeriodoDataHora
                  labelDataHoraInicial="Data Inicial"
                  labelDataHoraFinal="Data Final"
                  nomeDataHoraInicial="dataInicial"
                  nomeDataHoraFinal="dataFinal"
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="tipoData"
                  label="Tipo de Data"
                  options={FormatarEnum({
                    enumObj: TipoDataAtualizacaoProdutoEnum,
                  })}
                />
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
