/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as Yup from 'yup';
import {
  FormatarDataHoraCompletaParaPtBr,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista,
} from '@elogestor/util';
import { Table } from 'react-bootstrap';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { UltimaTr } from '../styles';
import { Tabela } from '../../../../../Componentes/Tabela/styles';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';

export interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Ref {
  handleSelecionarTodosEtapa(valor: boolean): void;
  handleValidar(): Promise<boolean>;
  handleObterListaValorSelecionados(): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[];
}

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Ref,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Props
> = ({ name, formRef }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { size } = UseReactSizeMeBodyHook();
  const selecionarTodosRef = useRef<HTMLInputElement>(null);
  const listaSelecionadoRef = useRef<HTMLInputElement[]>([]);
  const valorRef = useRef<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[]
  >([]);

  const maxHeigthVariavel = size.width && size.width <= 731 ? '36%' : '70%';

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[]
  >([]);
  const [valorSubTotal, setValorSubTotal] = useState<number>(0);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterListaValorSelecionados =
    useCallback((): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[] => {
      return listaValor.filter((item) => {
        return item?.selecionado;
      });
    }, [listaValor]);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const listaSelecionados = handleObterListaValorSelecionados();

    const valorTotal = listaSelecionados.reduce(
      (
        acumulador: number,
        item: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista
      ) => {
        acumulador += Number(item.valorTotal) || 0;
        return acumulador;
      },
      0
    );

    setValorSubTotal(valorTotal);
  }, [handleObterListaValorSelecionados]);

  const handleSelecionarTodos = useCallback(
    (valor: boolean) => {
      listaSelecionadoRef.current.forEach((e) => {
        e.checked = valor;
      });
      listaValor.forEach((e) => {
        e.selecionado = valor;
      });

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleSelecionarTodosEtapa = useCallback(
    (valor: boolean) => {
      if (selecionarTodosRef.current) {
        selecionarTodosRef.current.checked = valor;
      }

      handleSelecionarTodos(valor);
    },
    [handleSelecionarTodos]
  );

  const handleAlterarSelecionado = useCallback(
    (valor: boolean, index: number) => {
      listaValor[index].selecionado = valor;

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const listaMensagemErro: string[] = [];
      let etapaValida = true;

      const listaSelecionados = handleObterListaValorSelecionados();

      const data = formRef.current?.getData();

      formRef.current?.setErrors({});

      if (listaSelecionados.length === 0) {
        listaMensagemErro.push(
          'Selecione pelo menos um item para poder prosseguir!'
        );
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        etapaValida = false;
      }

      const schema = Yup.object().shape({
        idTipoNota: Yup.string()
          .nullable()
          .required('Tipo de Nota é obrigatória!'),
        idTipoNotaMotivo: Yup.string()
          .nullable()
          .required('Motivo é obrigatório!'),
        destinado: Yup.string().nullable().required('Destinado é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });

      return etapaValida;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef, handleObterListaValorSelecionados]);

  useEffect(() => {
    handleAtualizarValorSubTotal();
  }, [handleAtualizarValorSubTotal]);

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
      handleSelecionarTodosEtapa,
      handleObterListaValorSelecionados,
    };
  });

  return (
    <Tabela style={{ overflow: 'auto', maxHeight: maxHeigthVariavel }}>
      <Table striped bordered variant="light">
        <thead>
          <tr>
            <th
              className="lista-texto"
              style={{
                width: '5%',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <input
                type="checkbox"
                ref={selecionarTodosRef}
                onChange={(event) => {
                  handleSelecionarTodos(event.target.checked);
                }}
              />
            </th>
            <th className="lista-texto" style={{ width: '10%' }}>
              <span>Número</span>
            </th>
            <th className="lista-texto" style={{ width: '10%' }}>
              <span>Série</span>
            </th>
            <th className="lista-data" style={{ width: '20%' }}>
              <span>Data Emissão</span>
            </th>
            <th className="lista-valor" style={{ width: '20%' }}>
              <span>Valor Total</span>
            </th>
            <th className="lista-texto" style={{ width: '35%' }}>
              <span>Cliente</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {listaValor &&
            listaValor.map(
              (
                item: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista,
                index
              ) => {
                return (
                  <tr key={v4()}>
                    <td
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={item.selecionado}
                        ref={(instance) => {
                          if (instance)
                            listaSelecionadoRef.current[index] = instance;
                        }}
                        onChange={(event) => {
                          handleAlterarSelecionado(event.target.checked, index);
                        }}
                      />
                    </td>
                    <td className="lista-texto">
                      <div>{item.numero}</div>
                    </td>
                    <td className="lista-texto">
                      <div>
                        {!item.serie.id
                          ? `${item.serie.codigo}`
                          : `${item.serie.codigo} - ${item.serie.descricao}`}
                      </div>
                    </td>
                    <td className="lista-data">
                      <div>
                        {FormatarDataHoraCompletaParaPtBr(item.dataHoraEmissao)}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>{Number(item.valorTotal).FormatarParaPtBr()}</div>
                    </td>
                    <td className="lista-texto">
                      <div>
                        {`${item.notaFiscalEntradaPessoa.pessoa.codigo} - ${item.notaFiscalEntradaPessoa.pessoa.nomeRazaoSocial}`}
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>

        <tfoot>
          <UltimaTr>
            <th />
            <th />
            <th />
            <th />
            <th>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>Valor Sub. Total</div>
                <div style={{ alignSelf: 'flex-end' }}>
                  {valorSubTotal.FormatarParaPtBr()}
                </div>
              </div>
            </th>
            <th />
          </UltimaTr>
        </tfoot>
      </Table>
    </Tabela>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1);
