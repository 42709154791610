import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/conta-de-email';

interface IContaEmailComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IContaEmailComunicadorShow {
  id: string;
}

interface IContaEmailComunicadorStore {
  params: any;
}

interface IContaEmailComunicadorUpdate {
  id: string;
  params: any;
}

interface IContaEmailComunicadorDeletar {
  id: string;
}

class ContaEmailComunicador {
  public async index(params: IContaEmailComunicadorIndex): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }

  public async show(params: IContaEmailComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IContaEmailComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IContaEmailComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IContaEmailComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ContaEmailComunicador();
