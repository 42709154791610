"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = QuebrarLinhaPorTamanho;
function QuebrarLinhaPorTamanho(texto, comprimentoMaximo) {
    const linhas = [];
    const palavras = texto.split(' ');
    let linhaAtual = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const palavra of palavras) {
        if ((linhaAtual + 1 + palavra).length <= comprimentoMaximo) {
            linhaAtual += (linhaAtual === '' ? '' : ' ') + palavra;
        }
        else {
            linhas.push(linhaAtual);
            linhaAtual = palavra;
        }
    }
    if (linhaAtual !== '') {
        linhas.push(linhaAtual);
    }
    return linhas;
}
