import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async ({ dadosPadrao } = {} as IHandleCarregarDadosParametros) => {
      async function SetarDadosPadrao(): Promise<void> {
        await formRefLista.current?.setDataInicial({
          formaCalculoPorRegra: true,

          ...dadosPadrao,
        });
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          SetarDadosPadrao();

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idPessoaRepresentante: Yup.string()
            .nullable()
            .required('Representante é obrigatório!'),
          baseCalculo: Yup.number().required('Base de Cálculo é Obrigatório!'),
          aliquota: Yup.number()
            .required('Alíquota é Obrigatória!')
            .max(100, 'Alíquota deve ser menor ou Igual a 100%!'),
          valor: Yup.number().required('Valor é Obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const id = getIdDetalheRegistro() || '';

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
        );

        formPrincipal.formRef.current?.setFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante',
          [...listaDados, data]
        );
        formPrincipal.formRef.current?.setFieldValue(
          'atualizarTabelaPrecoItens',
          true
        );

        await formRef?.reset();

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formPrincipal, getIdDetalheRegistro, handleValidar, redirecionar]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const listaDados = formPrincipal.formRef.current?.getFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
        );

        listaDados.splice(Number(index), 1);

        formPrincipal.formRef.current?.setFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante',
          listaDados
        );
        formPrincipal.formRef.current?.setFieldValue(
          'atualizarTabelaPrecoItens',
          true
        );

        setLoading(false);
        formPrincipal.setLoading(false);

        refresh();
        formPrincipal.refresh();
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
