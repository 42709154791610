import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/produto/produto-obter-movimentacoes/estoques';

interface IProdutoObterMovimentacoesComunicadorIndex {
  params: {
    idProduto?: string;
  };
}

class ProdutoObterMovimentacoesComunicador {
  public async index(
    params: IProdutoObterMovimentacoesComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ProdutoObterMovimentacoesComunicador();
