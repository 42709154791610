"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StringConverterParaEnum;
/* eslint-disable no-restricted-syntax */
function TextoFormatadoParaValorStringEnum(stringEnum, value) {
    for (const k of Object.keys(stringEnum)) {
        if (stringEnum[k] === value)
            return k;
    }
    if (stringEnum[value])
        return value;
    return undefined;
}
function StringConverterParaEnum(enumObj, str) {
    const textoEnum = String(TextoFormatadoParaValorStringEnum(enumObj, str));
    return enumObj[textoEnum];
}
