import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';
import InputAutoCompleteMarcaVeiculo from '../../../../../../Componentes/Inputs/AutoComplete/Servicos/OrdensServicos/InputAutoCompleteMarcaVeiculo';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';

const VeiculoTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <h5>Veículo</h5>

        <Row>
          <Col lg={7} md={6} sm={12}>
            <InputAutoCompleteMarcaVeiculo
              label="Marca do Veículo"
              name="ordemServicoVeiculo.idMarcaVeiculo"
              nomeObjeto="ordemServicoVeiculo.marcaVeiculo"
              placeholder="Marca do Veículo"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <Input
              name="ordemServicoVeiculo.modelo"
              label="Modelo"
              placeholder="Modelo"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={3} sm={12}>
            <InputInteiroNulavel
              name="ordemServicoVeiculo.ano"
              label="Ano"
              placeholder="Ano"
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2} md={4} sm={12}>
            <Input
              name="ordemServicoVeiculo.placa"
              label="Placa"
              placeholder="Placa"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={4} sm={12}>
            <Input
              name="ordemServicoVeiculo.chassi"
              label="Chassi"
              placeholder="Chassi"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputInteiroNulavel
              name="ordemServicoVeiculo.quilometragem"
              label="KM"
              placeholder="Quilometragem"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <Input
              name="ordemServicoVeiculo.numeroFrota"
              label="Número da Frota"
              placeholder="Número da Frota"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={4} sm={12}>
            <Input
              label="Combustível"
              name="ordemServicoVeiculo.combustivel"
              placeholder="Combustível"
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default VeiculoTab;
