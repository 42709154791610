import { IFormCiaHandles } from '@elogestor/unformcia';
import React from 'react';
import ItemDeclaracaoImportacaoHook from './Hook';
import ListaDetalheDeclaracaoImportacao from './Lista';

interface IItemDeclaracaoImportacaoTab {
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const ItemDeclaracaoImportacaoTab: React.FC<IItemDeclaracaoImportacaoTab> = ({
  formRefItem,
}) => {
  return (
    <>
      <ItemDeclaracaoImportacaoHook formRefItem={formRefItem}>
        <ListaDetalheDeclaracaoImportacao name="listaNotaFiscalEntradaItemDeclaracaoImportacao" />
      </ItemDeclaracaoImportacaoHook>
    </>
  );
};

export default ItemDeclaracaoImportacaoTab;
