import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-nota-fiscal-saida/curva-abc-nota-fiscal-saida-grupo-por-quantidade';

interface ICurvaABCNotaFiscalSaidaGrupoPorQuantidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalSaidaGrupoPorQuantidadeRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalSaidaGrupoPorQuantidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalSaidaGrupoPorQuantidadeRelatorioComunicador();
