"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line no-shadow
var PermiteLancarContaSemFormaPagamentoEnum;
(function (PermiteLancarContaSemFormaPagamentoEnum) {
    PermiteLancarContaSemFormaPagamentoEnum["permitir"] = "Permitir";
    PermiteLancarContaSemFormaPagamentoEnum["permitirGerarAviso"] = "Permitir, mas Gerar Aviso";
    PermiteLancarContaSemFormaPagamentoEnum["naoPermitir"] = "N\u00E3o Permitir";
})(PermiteLancarContaSemFormaPagamentoEnum || (PermiteLancarContaSemFormaPagamentoEnum = {}));
exports.default = PermiteLancarContaSemFormaPagamentoEnum;
