import { createContext, useContext } from 'react';

interface IComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IComunicadorShow {
  id: string;
}

interface IComunicadorStore {
  params: any;
}

interface IComunicadorUpdate {
  id: string;
  params: any;
}

interface IComunicadorDeletar {
  id: string;
}

interface IComunicadorContext {
  comunicador: {
    index(params: IComunicadorIndex): Promise<any>;
    show(params: IComunicadorShow): Promise<any>;
    store(params: IComunicadorStore): Promise<any>;
    update(params: IComunicadorUpdate): Promise<any>;
    delete(params: IComunicadorDeletar): Promise<any>;
  };
}

const comunicadorContext = createContext<IComunicadorContext>(
  {} as IComunicadorContext
);

const ComunicadorProvider = comunicadorContext.Provider;

function UseComunicador(): IComunicadorContext {
  const context = useContext(comunicadorContext);

  return context;
}

export { ComunicadorProvider, UseComunicador, comunicadorContext };
