import { IImportacaoXmlTerceiroLista } from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Row, Col, Dropdown, Spinner } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { TiInputChecked } from 'react-icons/ti';
import { FiFile } from 'react-icons/fi/index.mjs';
import { RiCheckboxMultipleLine } from 'react-icons/ri/index.mjs';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import ImportacaoXmlTerceiroBaixarComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroBaixarComunicador';
import ImportacaoXmlTerceiroCienciaOperacaoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroCienciaOperacaoComunicador';
import ImportacaoXmlTerceiroConfirmarOperacaoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroConfirmarOperacaoComunicador';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { LoadingContainer } from './styles';
import SelecionarXmlTerceiroArmazenarLista from './SelecionarXmlTerceiroArmazenarLista';
import ImportarCteSelecionadosLote from './ImportarCteSelecionadosLote';

interface IAcoesImportacaoXmlTerceiro {
  permissao?: IPermissao;
  obterItensSelecionados: () => IImportacaoXmlTerceiroLista[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  atualizar: () => void;
  possuiItemSelecionado: boolean;
}

const AcoesImportacaoXmlTerceiro: React.FC<IAcoesImportacaoXmlTerceiro> = ({
  permissao,
  obterItensSelecionados,
  loading,
  setLoading,
  atualizar,
  possuiItemSelecionado,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const [showFormModalSelecaoXml, setShowFormModalSelecaoXml] = useState(false);
  const [showFormModalSelecionadosLote, setShowFormModalSelecionadosLote] =
    useState(false);
  const [listaCTeSelecionados, setListaCTeSelecionados] = useState<
    IImportacaoXmlTerceiroLista[]
  >([]);

  const handleCienciaOperacao = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemSelecionado = obterItensSelecionados();
      const listaItemId = listaItemSelecionado.map((item) => {
        return item.id;
      });

      const response =
        await ImportacaoXmlTerceiroCienciaOperacaoComunicador.update({
          params: listaItemId,
        });

      if (response.listaSucesso) {
        response.listaSucesso.forEach((mensagem: string) => {
          ToastSucesso(mensagem);
        });
      }
      if (response.listaAviso) {
        response.listaAviso.forEach((mensagem: string) => {
          ToastInfo(mensagem);
        });
      }
      if (response.listaErro) {
        response.listaErro.forEach((mensagem: string) => {
          ToastErro(mensagem);
        });
      }

      setLoading(false);
      atualizar();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [atualizar, obterItensSelecionados, setLoading]);

  const handleBaixarXml = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemSelecionado = obterItensSelecionados();
      const listaItemId = listaItemSelecionado.map((item) => {
        return item.id;
      });

      const response = await ImportacaoXmlTerceiroBaixarComunicador.store({
        params: listaItemId,
      });

      if (response.listaSucesso) {
        response.listaSucesso.forEach((mensagem: string) => {
          ToastSucesso(mensagem);
        });
      }
      if (response.listaAviso) {
        response.listaAviso.forEach((mensagem: string) => {
          ToastInfo(mensagem);
        });
      }
      if (response.listaErro) {
        response.listaErro.forEach((mensagem: string) => {
          ToastErro(mensagem);
        });
      }
      setLoading(false);
      atualizar();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [atualizar, obterItensSelecionados, setLoading]);

  const handleConfirmarOperacao = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemSelecionado = obterItensSelecionados();
      const listaItemId = listaItemSelecionado.map((item) => {
        return item.id;
      });

      const response =
        await ImportacaoXmlTerceiroConfirmarOperacaoComunicador.update({
          params: listaItemId,
        });

      if (response.listaSucesso) {
        response.listaSucesso.forEach((mensagem: string) => {
          ToastSucesso(mensagem);
        });
      }
      if (response.listaAviso) {
        response.listaAviso.forEach((mensagem: string) => {
          ToastInfo(mensagem);
        });
      }
      if (response.listaErro) {
        response.listaErro.forEach((mensagem: string) => {
          ToastErro(mensagem);
        });
      }

      setLoading(false);
      atualizar();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [atualizar, obterItensSelecionados, setLoading]);

  const handleSelecionarXmlsParaArmazenar = useCallback(async () => {
    setShowFormModalSelecaoXml(true);
  }, []);

  const handleSelecionarCteSelecionadosEmLote = useCallback(async () => {
    const listaSelecionados = obterItensSelecionados().filter(
      (item) => item.modeloDocumentoFiscal.codigo === '57'
    );

    if (listaSelecionados && listaSelecionados.length === 0) {
      ToastErro('Nenhum Documento, com o Tipo CT-e, Foi Selecionado!');
      return;
    }
    setListaCTeSelecionados(listaSelecionados);
    setShowFormModalSelecionadosLote(true);
  }, [obterItensSelecionados]);

  const handleFecharFormModalSelecaoXml =
    useCallback(async (): Promise<void> => {
      setShowFormModalSelecaoXml(false);
      atualizar();
    }, [atualizar]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        className="btn-group"
        title={
          <Row>
            <Col>
              {loading ? (
                <LoadingContainer style={{ display: 'flex' }}>
                  <Spinner
                    className="spinner"
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={{
                      width: '22px',
                      height: '22px',
                      fontSize: '10px',
                      marginRight: '5px',
                    }}
                  />
                </LoadingContainer>
              ) : (
                <div>
                  <CgMoreO />
                  {telaGrande && (
                    <span style={{ marginLeft: 10, marginRight: 10 }}>
                      Ações
                    </span>
                  )}
                </div>
              )}
            </Col>
          </Row>
        }
      >
        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.inclui ||
            !permissao?.altera ||
            !possuiItemSelecionado
          }
          onClick={handleCienciaOperacao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <TiInputChecked style={{ marginRight: 5 }} />
            Ciência da Operação (Selecionados)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.inclui ||
            !permissao?.altera ||
            !possuiItemSelecionado
          }
          onClick={handleBaixarXml}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Baixar XML do Governo (Selecionados)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.inclui ||
            !permissao?.altera ||
            !possuiItemSelecionado
          }
          onClick={handleConfirmarOperacao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <TiInputChecked style={{ marginRight: 5 }} />
            Confirmar Operação (Selecionados)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading || !permissao?.inclui || !permissao?.altera}
          onClick={handleSelecionarXmlsParaArmazenar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
            Selecionar XMLs para Armazenar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.inclui ||
            !permissao?.altera ||
            !possuiItemSelecionado
          }
          onClick={handleSelecionarCteSelecionadosEmLote}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
            Importar CT-e Selecionados (Em Lote)
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFormModalSelecaoXml && (
        <SelecionarXmlTerceiroArmazenarLista
          onFecharFormModalLista={handleFecharFormModalSelecaoXml}
        />
      )}

      {showFormModalSelecionadosLote && (
        <ImportarCteSelecionadosLote
          onFecharFormModalLista={() => {
            setShowFormModalSelecionadosLote(false);
            atualizar();
          }}
          obterItensSelecionados={obterItensSelecionados}
          listaCTeSelecionados={listaCTeSelecionados}
        />
      )}
    </>
  );
};

export default AcoesImportacaoXmlTerceiro;
