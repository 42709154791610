/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { v4 } from 'uuid';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { Dropdown } from 'react-bootstrap';
import { SituacaoAberturaFechamentoCaixaEnum } from '@elogestor/util';
import { IoMdLock, IoMdUnlock } from 'react-icons/io/index.mjs';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import AberturaFechamentoCaixaSituacaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Comunicador/AberturaFechamentoCaixaSituacaoComunicador';

const AcoesAberturaFechamentoCaixa: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
  } = UseForm();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const situacao = formRef.current?.getFieldValue('situacao');

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      if (formRef.current?.validarSeAlterou()) {
        const data = formRef.current?.getData();
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleAbrirCaixa = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      await AberturaFechamentoCaixaSituacaoComunicador.update({
        id: String(idRegistroDetalhe),
        params: { situacao: SituacaoAberturaFechamentoCaixaEnum.aberto },
      });

      await handleCarregarDados();

      const terminalCaixa =
        formRef.current?.getFieldValueNomeObjeto('terminalCaixa');
      const usuarioAbertura =
        formRef.current?.getFieldValueNomeObjeto('usuarioAbertura');
      const dataHoraAbertura =
        formRef.current?.getFieldValue('dataHoraAbertura');

      localStorage.setItem(
        '@EloGestorle:turno',
        JSON.stringify({
          id: String(idRegistroDetalhe),
          terminalCaixa,
          usuarioAbertura,
          dataHoraAbertura,
        })
      );

      ToastSucesso('Caixa Aberto!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleCarregarDados,
    handleSalvar,
    idRegistroDetalhe,
    setLoading,
  ]);

  const handleFecharCaixa = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      await AberturaFechamentoCaixaSituacaoComunicador.update({
        id: String(idRegistroDetalhe),
        params: { situacao: SituacaoAberturaFechamentoCaixaEnum.fechado },
      });

      await handleCarregarDados();

      localStorage.removeItem('@EloGestorle:turno');

      ToastSucesso('Caixa Fechado!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleCarregarDados, handleSalvar, idRegistroDetalhe, setLoading]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={
            loading || situacao === SituacaoAberturaFechamentoCaixaEnum.aberto
          }
          onClick={handleAbrirCaixa}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <IoMdUnlock style={{ marginRight: 5 }} />
            Abrir Caixa
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading || situacao === SituacaoAberturaFechamentoCaixaEnum.fechado
          }
          onClick={handleFecharCaixa}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <IoMdLock style={{ marginRight: 5 }} />
            Fechar Caixa
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesAberturaFechamentoCaixa;
