import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-integracao-pagamento/ordem';

interface IRegraEscolhaIntegracaoPagamentoOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaIntegracaoPagamentoOrdemComunicador {
  public async update(
    params: IRegraEscolhaIntegracaoPagamentoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaIntegracaoPagamentoOrdemComunicador();
