import React, { useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputCnpjCpf from '../../../../../../../Componentes/Inputs/InputCnpjCpf';
import InputInscricaoEstadual from '../../../../../../../Componentes/Inputs/InputInscricaoEstadual';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IEndereco from '../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import InputCep from '../../../../../../../Componentes/Inputs/InputCep';
import PesquisaEnderecoCepDetalhe from '../../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';

interface IExpedidorTab {
  bloquearCamposPorImportacao: boolean;
}

const ExpedidorTab: React.FC<IExpedidorTab> = ({
  bloquearCamposPorImportacao,
}) => {
  const { formRef, refresh, loading } = UseForm();

  const janelaCepEnderecoRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);

  const isCnpj =
    formRef.current?.getFieldValue(
      'conhecimentoTransporteTerceiroExpedidor.cnpjCpfVirtual'
    ).length === 14;
  const isExterior =
    formRef.current?.getFieldValue(
      'conhecimentoTransporteTerceiroExpedidor.documentoIdentificacaoEstrangeiro'
    ).length > 0;

  const handlePesquisarCepEndereco = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();

    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'conhecimentoTransporteTerceiroExpedidorEndereco.cidade'
    );

    await janelaCepEnderecoRef.current?.pesquisarCep({
      cep: dados.conhecimentoTransporteTerceiroExpedidorEndereco.cep,
      logradouro:
        dados.conhecimentoTransporteTerceiroExpedidorEndereco.logradouro,
      bairro: dados.conhecimentoTransporteTerceiroExpedidorEndereco.bairro,
      idCidade: cidade?.id || '',
      nomeCidade: cidade?.nome || '',
      siglaEstado: cidade?.estado.sigla || '',
    });
  }, [formRef]);

  const handleOnSelecionarEndereco = useCallback(
    async (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroExpedidorEndereco.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroExpedidorEndereco.bairro',
        endereco.bairro
      );
      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroExpedidorEndereco.cidade',
        endereco.cidade
      );
    },
    [formRef]
  );

  const handleChangeCidade = useCallback(
    ({ itemAtual }: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'conhecimentoTransporteTerceiroExpedidorEndereco.cidade.estado.sigla',
        itemAtual?.estado?.sigla || ''
      );
    },
    [formRef]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <InputCnpjCpf
              label="CNPJ/CPF"
              name="conhecimentoTransporteTerceiroExpedidor.cnpjCpfVirtual"
              placeholder="CNPJ/CPF"
              disabled
              permitirPesquisar={false}
            />
          </Col>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <InputInscricaoEstadual
              label="Inscrição Estadual"
              name="conhecimentoTransporteTerceiroExpedidor.inscricaoEstadual"
              placeholder="Inscrição Estadual"
              disabled
            />
          </Col>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <Input
              label="Inscrição Suframa"
              name="conhecimentoTransporteTerceiroExpedidor.inscricaoSuframa"
              placeholder="Inscrição Suframa"
              disabled
            />
          </Col>

          <Col lg={6} md={12} className={isExterior ? '' : 'hidden'}>
            <Input
              label="Número do Passaporte, ou outro documento legal do Estrangeiro"
              name="conhecimentoTransporteTerceiroExpedidor.documentoIdentificacaoEstrangeiro"
              placeholder="Número do Passaporte, ou outro documento legal do Estrangeiro"
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={isCnpj || isExterior ? 6 : 12} md={12}>
            <Input
              label={isCnpj ? 'Nome Razão Social' : 'Nome'}
              name="conhecimentoTransporteTerceiroExpedidor.nomeRazaoSocial"
              placeholder={isCnpj ? 'Nome Razão Social' : 'Nome'}
              disabled
            />
          </Col>
          <Col lg={6} md={12} className={isCnpj || isExterior ? '' : 'hidden'}>
            <Input
              label="Nome Fantasia"
              name="conhecimentoTransporteTerceiroExpedidor.nomeFantasia"
              placeholder="Nome Fantasia"
              disabled
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Endereço</h5>

        <Row>
          <Col lg={3} md={12}>
            <InputCep
              name="conhecimentoTransporteTerceiroExpedidorEndereco.cep"
              label="CEP"
              placeholder="CEP"
              loading={loading}
              onPesquisarCep={handlePesquisarCepEndereco}
              disabled={bloquearCamposPorImportacao}
            />
            <PesquisaEnderecoCepDetalhe
              handleOnSelecionarEndereco={handleOnSelecionarEndereco}
              ref={janelaCepEnderecoRef}
            />
          </Col>
          <Col lg={7} md={12}>
            <InputAutoCompleteCidadeUf
              label="Cidade"
              placeholder="Cidade"
              name="conhecimentoTransporteTerceiroExpedidorEndereco.idCidade"
              nomeObjeto="conhecimentoTransporteTerceiroExpedidorEndereco.cidade"
              onChangeItemAtual={handleChangeCidade}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              name="conhecimentoTransporteTerceiroExpedidorEndereco.cidade.estado.sigla"
              label="UF"
              disabled
              type="text"
              placeholder="UF"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12}>
            <Input
              label="Logradouro"
              name="conhecimentoTransporteTerceiroExpedidorEndereco.logradouro"
              placeholder="Logradouro"
              maxLength={60}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              label="Número"
              name="conhecimentoTransporteTerceiroExpedidorEndereco.numero"
              placeholder="Número"
              maxLength={60}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
          <Col lg={4} md={12}>
            <Input
              label="Bairro"
              name="conhecimentoTransporteTerceiroExpedidorEndereco.bairro"
              type="text"
              placeholder="Bairro"
              maxLength={60}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <Input
              label="Complemento"
              name="conhecimentoTransporteTerceiroExpedidorEndereco.complemento"
              type="text"
              placeholder="Complemento"
              maxLength={60}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ExpedidorTab;
