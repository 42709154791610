import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/cte-complementado';

interface IConhecimentoTransporteTerceiroCteComplementadoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroCteComplementadoComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteTerceiroCteComplementadoComunicador {
  public async store(
    params: IConhecimentoTransporteTerceiroCteComplementadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroCteComplementadoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroCteComplementadoComunicador();
