import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import ItemHook from './ListaDetalheItem/Hook';
import NotaFiscalEntradaItemLista from './ListaDetalheItem/Lista';

const ItemTab: React.FC = () => {
  return (
    <Divisor>
      <Row>
        <Col lg={12} md={12}>
          <ItemHook>
            <NotaFiscalEntradaItemLista name="listaNotaFiscalEntradaItem" />
          </ItemHook>
        </Col>
      </Row>
    </Divisor>
  );
};

export default ItemTab;
