import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IProdutoPorGrupoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IProdutoPorGrupoPDF {
  dados: IProdutoPorGrupoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ProdutoPorGrupoPDF: React.FC<IProdutoPorGrupoPDF> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroMarca && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Marca: '}</Text>
                      {dados.cabecalho.filtroMarca}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroAtivo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Ativo: '}</Text>
                      {dados.cabecalho.filtroAtivo === 'false' ? 'Não' : 'Sim'}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroUnidadeMedida && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Unidade de Medida: '}
                      </Text>
                      {dados.cabecalho.filtroUnidadeMedida}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNcm && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'NCM: '}</Text>
                      {dados.cabecalho.filtroNcm}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCest && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'CEST: '}</Text>
                      {dados.cabecalho.filtroCest}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoSped && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Sped: '}
                      </Text>
                      {dados.cabecalho.filtroTipoSped}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '15%', alignItems: 'center' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '30%' }}>
              <Text>Produto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '3%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>NCM</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>CEST</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%' }}>
              <Text>Cód. Barras</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>P. Líquido</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>P. Bruto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Tipo de Produto (SPED)</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '4%' }}>
              <Text>Origem</Text>
            </View>
          </View>

          {dados.listaDados.map((dado) => {
            return (
              <View key={dado.chave} style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    marginTop: 5,
                    fontWeight: 'bold',
                    fontSize: 10,
                    marginBottom: 5,
                  }}
                >
                  <Text>
                    Grupo: {dado.listaProduto[0].grupoProdutoServicoDescricao}
                  </Text>
                </View>

                {dado.listaProduto.map((produto, index) => {
                  return (
                    <View>
                      <View
                        key={produto.codigo}
                        style={{
                          flexDirection: 'row',
                          backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                        }}
                      >
                        <View style={{ width: '15%', alignItems: 'center' }}>
                          <Text>{produto.codigo}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '30%' }}>
                          <Text>{produto.descricao}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '4%' }}>
                          <Text>{produto.unidadeMedida}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '7%' }}>
                          <Text>{produto.produtoFiscal.ncm}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '7%' }}>
                          <Text>{produto.produtoFiscal.cest}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '7%' }}>
                          <Text>{produto.produtoEngenharia.codigoBarras}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '6%', alignItems: 'flex-end' }}>
                          <Text>{produto.produtoEngenharia.pesoLiquido}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '6%', alignItems: 'flex-end' }}>
                          <Text>{produto.produtoEngenharia.pesoBruto}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }}>
                          <Text>{produto.produtoFiscal.tipoProdutoSped}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '4%' }}>
                          <Text>{produto.produtoFiscal.origemIcms}</Text>
                        </View>
                      </View>
                      {!!produto.tags && (
                        <View
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <Text>Tags: {produto.tags}</Text>
                        </View>
                      )}
                    </View>
                  );
                })}
                <View
                  style={{ borderBottom: '1pt dashed black', width: '100%' }}
                />
              </View>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ProdutoPorGrupoPDF;
