/* eslint-disable prettier/prettier */
import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/retirada-consignado/retirada-consignado-por-cliente-com-qtd-faturada-faturar';

interface IRetiradaConsignadoRelComunicadorIndex {
  params: {
    filtro: any;
  };
}

class RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador {
  public async index(
    params: IRetiradaConsignadoRelComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new RetiradaConsignadoPorClienteComQtdFaturadaFaturarRelComunicador();
