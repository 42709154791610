import { IUnformFieldCiaUserProps, UseFieldCia } from '@elogestor/unformcia';
import { useEffect, useRef } from 'react';
import { IListaAcao } from '../../../../AutoCompleteTag/AutoCompleteTagBase/Hooks/UseListaAcao';
import { IInputTagInputBaseRef } from '../..';

interface IUseRegisterTagsInput<T> {
  nome: string;
  handleObterChaveUnica: (itemAtual: T) => string | number;
}

function UseRegisterTagsInput<T = any>(
  { handleObterChaveUnica, nome }: IUseRegisterTagsInput<T>,
  UnformCiaField: Partial<IUnformFieldCiaUserProps<any>> = {}
): {
  tagInputRef: React.RefObject<IInputTagInputBaseRef>;
  error: string | undefined;
} {
  const tagInputRef = useRef<IInputTagInputBaseRef>(null);
  const { fieldName, error, registerField } = UseFieldCia(nome);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: tagInputRef,

      getValue() {
        const listaAcao = tagInputRef.current?.obterListaAcao() || [];
        const listaValor = tagInputRef.current?.obterListaItemAtual() || [];
        return {
          listaAcao,
          listaValor,
        };
      },

      setValue(_, value = []) {
        tagInputRef.current?.alterarListaItemAtualListaAcao(value);
      },

      setSemExecutarEvento(_, value = []) {
        tagInputRef.current?.alterarListaItemAtualListaAcaoSemEvento(value);
      },

      getDataRecuperarFormulario() {
        return {
          listaItemAtual: tagInputRef.current?.obterListaItemAtual() || [],
          listaAcao: tagInputRef.current?.obterListaAcao(),
        };
      },

      setDataRecuperarFormulario(_, value) {
        tagInputRef.current?.alterarListaItemAtualListaAcao(
          value.listaItemAtual || [],
          value.listaAcao || []
        );
      },

      getDataDuplicar(): { listaItemAtual: any[]; listaAcao: IListaAcao } {
        return {
          listaItemAtual: tagInputRef.current?.obterListaItemAtual() || [],
          listaAcao: {
            listaDeletar: [],
            listaMover: [],
            listaInserir:
              tagInputRef.current
                ?.obterListaItemAtual()
                .map((itemAtual, index) => {
                  delete itemAtual.id;
                  return {
                    chave: handleObterChaveUnica(itemAtual),
                    ordem: index,
                    valor: itemAtual,
                  };
                }) || [],
          },
        };
      },

      setDataDuplicar(_, value) {
        tagInputRef.current?.alterarListaItemAtualListaAcao(
          value.listaItemAtual || [],
          value.listaAcao || []
        );
      },

      clearValue(_, valorInicial = { listaValor: [] }) {
        tagInputRef.current?.alterarListaItemAtualListaAcao(
          valorInicial.listaValor
        );
      },

      onAtualizarValorInicial() {
        tagInputRef.current?.alterarListaAcao({
          listaDeletar: [],
          listaInserir: [],
          listaMover: [],
        });
      },

      validarSeAlterou() {
        const listaAcao = tagInputRef.current?.obterListaAcao();
        if (
          listaAcao?.listaInserir.length ||
          listaAcao?.listaDeletar.length ||
          listaAcao?.listaMover.length
        ) {
          return true;
        }
        return false;
      },

      setDisabled(valor) {
        const inputRef = tagInputRef.current?.obterInput();

        if (inputRef) {
          inputRef.disabled = valor;
        }
      },
      ...UnformCiaField,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tagInputRef,
    error,
  };
}

export default UseRegisterTagsInput;
