import api from '../../../../../Comum/Services/Api';

const rota =
  '/suprimentos/relatorio/devolucao-notas-entrada/devolucao-notas-entrada-por-itens';

interface IDevolucaoNotasEntradasPorItensRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class DevolucaoNotasEntradasPorItensRelatorioComunicador {
  public async index(
    params: IDevolucaoNotasEntradasPorItensRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new DevolucaoNotasEntradasPorItensRelatorioComunicador();
