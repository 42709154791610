import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda-importacao-orcamento/obter-orcamento-item-estoque';

interface IPedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicadorIndex {
  dataHoraEmissao: Date;
  idOrcamentoItem: string;
  quantidade: number;
}

class PedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador {
  public async index(
    params: IPedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoObterOrcamentoItemEstoqueComunicador();
