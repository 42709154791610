/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import {
  EstoqueIndicadorTipoEnum,
  FormatarRemovendoEnum,
  INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista,
} from '@elogestor/util';
import { Tabela } from '../../../../../Componentes/Tabela/styles';
import InputDecimal from '../../../../../Componentes/Inputs/InputDecimal';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteUnidadeMedida from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UltimaTr } from '../styles';
import InputAutoCompletePessoa from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';

export interface INotaFiscalSaidaImportacaoPedidoVendaEtapa3Ref {
  handleValidar(): boolean;
  handleObterListaValorSelecionados(): INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista[];
}

interface INotaFiscalSaidaImportacaoPedidoVendaEtapa3Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const NotaFiscalSaidaImportacaoPedidoVendaEtapa3: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoPedidoVendaEtapa3Ref,
  INotaFiscalSaidaImportacaoPedidoVendaEtapa3Props
> = ({ name, formRef }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);

  const valorRef = useRef<INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista[]>(
    []
  );

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterListaValorSelecionados =
    useCallback((): INotaFiscalSaidaImportacaoPedidoVendaEtapa3Lista[] => {
      return listaValor;
    }, [listaValor]);

  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
      handleObterListaValorSelecionados,
    };
  });

  // #region Obter

  const handleObterMovimentarEstoque = useCallback(
    (index: number): string => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].pedidoVendaItemEstoque.movimentarEstoque`
      );
    },
    [formRef]
  );

  const handleObterPorContaOrdemTerceiro = useCallback(
    (index: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa3[${index}].pedidoVendaItemEstoque.porContaOrdemTerceiro`
      );
    },
    [formRef]
  );

  // #endregion Obter

  return (
    <>
      <Tabela style={{ overflow: 'auto', height: 500 }}>
        <Table striped bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '2%' }}>
                <span>Ord.</span>
              </th>
              <th className="lista-texto" style={{ width: '28%' }}>
                <span>Produto</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>UN</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Quantidade</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Qtde. Transferir</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Valor Unitário</span>
              </th>
              <th className="lista-valor" style={{ width: '14%' }}>
                <span>Sub. Total</span>
              </th>
            </tr>
          </thead>

          <tbody className="tabela-desabilitada">
            {listaValor.map((item, index) => {
              return (
                <>
                  <tr>
                    <td className="lista-valor">
                      <div>{item.ordem}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{`${item.produto.codigo} - ${item.produto.descricao}`}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.unidadeMedida.sigla}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidade).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidadeTransferir).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorUnitario).FormatarParaPtBr({
                          minimoCasasDecimais: 10,
                          maximoCasasDecimais: 10,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>{Number(item.valorTotal).FormatarParaPtBr()}</div>
                    </td>
                  </tr>

                  {item.produto.produtoEstoque.controlarEstoque && (
                    <tr>
                      <td colSpan={7} style={{ padding: 0, paddingBottom: 20 }}>
                        <Divisor style={{ borderRadius: 0, marginBottom: 0 }}>
                          <Row>
                            <Col lg={2} md={3} sm={12}>
                              <InputSwitch
                                label="Movimentar Estoque"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.movimentarEstoque`}
                                ativo="Sim"
                                inativo="Não"
                                disabled
                              />
                            </Col>

                            <Col
                              lg={10}
                              md={9}
                              sm={12}
                              style={{
                                display: !handleObterMovimentarEstoque(index)
                                  ? 'none'
                                  : '',
                              }}
                            >
                              <InputAutoCompleteUnidadeMedida
                                label="Unidade de Medida de Estoque"
                                placeholder="Unidade de Medida de Estoque"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.unidadeMedida.id`}
                                nomeObjeto={`listaEtapa3[${index}].pedidoVendaItemEstoque.unidadeMedida`}
                                permitirAdicionar={false}
                                disabled
                              />
                            </Col>
                          </Row>

                          <Row
                            style={{
                              display: !handleObterMovimentarEstoque(index)
                                ? 'none'
                                : '',
                            }}
                          >
                            <Col lg={4} md={6} sm={12}>
                              <Select
                                label="Tipo de Estoque"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.indicadorTipoEstoque`}
                                options={FormatarRemovendoEnum({
                                  enumObj: EstoqueIndicadorTipoEnum,
                                  enumRemover: [
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                                  ],
                                })}
                                disabled
                              />
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <InputDecimal
                                label="Fator Conversao"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.fatorConversao`}
                                casasInteiras={11}
                                casasDecimais={10}
                                disabled
                              />
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <InputDecimal
                                label="Qtde. Estoque (Qtde. * Fat. Conv.)"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.quantidadeEstoque`}
                                casasInteiras={11}
                                casasDecimais={4}
                                disabled
                              />
                            </Col>
                          </Row>

                          <Row
                            style={{
                              display: !handleObterMovimentarEstoque(index)
                                ? 'none'
                                : '',
                            }}
                          >
                            <Col lg={2} md={3} sm={12}>
                              <InputSwitch
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.porContaOrdemTerceiro`}
                                label="Por Conta e Ord. Terc."
                                ativo="Sim"
                                inativo="Não"
                                disabled
                              />
                            </Col>
                            <Col
                              lg={10}
                              md={9}
                              sm={12}
                              style={{
                                display: !handleObterPorContaOrdemTerceiro(
                                  index
                                )
                                  ? 'none'
                                  : '',
                              }}
                            >
                              <InputAutoCompletePessoa
                                label="Pessoa Terceiro"
                                name={`listaEtapa3[${index}].pedidoVendaItemEstoque.pessoaTerceiro.id`}
                                nomeObjeto={`listaEtapa3[${index}].pedidoVendaItemEstoque.pessoaTerceiro`}
                                placeholder="Pessoa Terceiro"
                                permitirAdicionar={false}
                                disabled
                              />
                            </Col>
                          </Row>

                          <Tabela style={{ overflow: 'auto' }}>
                            <Table striped hover bordered variant="light">
                              <thead>
                                <tr>
                                  <th
                                    className="lista-texto"
                                    style={{ width: '25%' }}
                                  >
                                    <span>Tipo de Estoque</span>
                                  </th>
                                  <th
                                    className="lista-texto"
                                    style={{ width: '10%' }}
                                  >
                                    <span>Ent./Saída</span>
                                  </th>
                                  <th
                                    className="lista-texto"
                                    style={{
                                      width: item.produto.produtoEstoque
                                        .controlarLote
                                        ? '35%'
                                        : '50%',
                                    }}
                                  >
                                    <span>Local de Estoque</span>
                                  </th>

                                  {item.produto.produtoEstoque
                                    .controlarLote && (
                                    <th
                                      className="lista-texto"
                                      style={{ width: '15%' }}
                                    >
                                      <span>Lote</span>
                                    </th>
                                  )}

                                  <th
                                    className="lista-valor"
                                    style={{ width: '15%' }}
                                  >
                                    <span>Quantidade</span>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {item.pedidoVendaItemEstoque
                                  .listaEstoqueLocalEstoque &&
                                  item.pedidoVendaItemEstoque.listaEstoqueLocalEstoque.map(
                                    (
                                      estoqueLocalEstoque,
                                      indexEstoqueLocalEstoque
                                    ) => {
                                      return (
                                        <>
                                          <tr
                                            style={{
                                              backgroundColor:
                                                indexEstoqueLocalEstoque % 2 ===
                                                0
                                                  ? '#f0f0f1'
                                                  : '#fff',
                                            }}
                                          >
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque.indicadorTipoEstoque
                                                }
                                              </div>
                                            </td>
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque.tipoEntradaSaida
                                                }
                                              </div>
                                            </td>
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque
                                                    .localEstoque.descricao
                                                }
                                              </div>
                                            </td>

                                            {item.produto.produtoEstoque
                                              .controlarLote && (
                                              <td className="lista-texto">
                                                <div>
                                                  {
                                                    estoqueLocalEstoque.lote
                                                      ?.codigo
                                                  }
                                                </div>
                                              </td>
                                            )}

                                            <td className="lista-valor">
                                              <div>
                                                {Number(
                                                  estoqueLocalEstoque.quantidade
                                                )?.FormatarParaPtBr({
                                                  minimoCasasDecimais: 4,
                                                  maximoCasasDecimais: 4,
                                                })}
                                              </div>
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              backgroundColor:
                                                indexEstoqueLocalEstoque % 2 ===
                                                0
                                                  ? '#f0f0f1'
                                                  : '#fff',
                                              display:
                                                estoqueLocalEstoque.indicadorTipoEstoque ===
                                                EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                                                  ? 'none'
                                                  : '',
                                            }}
                                          >
                                            <td
                                              colSpan={7}
                                              style={{ paddingTop: 0 }}
                                            >
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {`PESSOA: `}
                                              </span>
                                              <span>
                                                {
                                                  estoqueLocalEstoque
                                                    .pessoaTerceiro
                                                    ?.nomeRazaoSocial
                                                }
                                              </span>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </tbody>

                              <UltimaTr
                                style={{ borderTop: '2px solid lightgray' }}
                              >
                                <th />
                                <th />
                                <th />
                                {item.produto.produtoEstoque.controlarLote && (
                                  <th />
                                )}
                                <th>
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div style={{ fontSize: '13px' }}>
                                      Qtde. Total
                                    </div>
                                    <div
                                      style={{
                                        alignSelf: 'flex-end',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {Number(
                                        item.pedidoVendaItemEstoque
                                          .quantidadeTotalEstoqueLocalEstoque
                                      )
                                        .Arredondar(4)
                                        .FormatarParaPtBr({
                                          minimoCasasDecimais: 4,
                                          maximoCasasDecimais: 4,
                                        })}
                                    </div>
                                  </div>
                                </th>
                              </UltimaTr>
                            </Table>
                          </Tabela>
                        </Divisor>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoPedidoVendaEtapa3);
