import {
  DesestruturarChaveAcesso,
  FormatarEnum,
  ModeloNotaReferenciaEnum,
  TipoNotaReferenciaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Select from '../../../../../../../../Componentes/Select';
import InputDate from '../../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteProdutor from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteProdutor';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompletePessoa from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';

interface IDocumentosReferenciadosDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const DocumentosReferenciadosDetalhe: React.FC<
  IDocumentosReferenciadosDetalheModal
> = ({ onSalvarFormModal, onFecharFormModal }) => {
  const { permissoes: permissao } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada } = permissao;
  const {
    handleCarregarDados,
    formRefLista,
    loading,
    getIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();

  const tipoNotaReferencia = formRefLista.current?.getFieldValue('tipo');

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (onSalvarFormModal) onSalvarFormModal();
  }, [onSalvarFormModal]);

  const handleAlterarCampos = useCallback(
    (tipoNota: string) => {
      if (
        tipoNota !== TipoNotaReferenciaEnum.cte &&
        tipoNota !== TipoNotaReferenciaEnum.nfe
      ) {
        formRefLista.current?.setFieldValue('chaveAcesso', null);
      }

      if (
        tipoNota !== TipoNotaReferenciaEnum.notaProdutor &&
        tipoNota !== TipoNotaReferenciaEnum.notaModelo1Aou2
      ) {
        formRefLista.current?.setFieldValue('serieCodigo', null);
        formRefLista.current?.setFieldValue('numero', null);
        formRefLista.current?.setFieldValue('dataEmissao', null);
      }

      if (tipoNota !== TipoNotaReferenciaEnum.notaProdutor) {
        formRefLista.current?.setFieldValue('modeloNotaReferencia', null);
        formRefLista.current?.setFieldValue('lerCodigoBarras', null);
        formRefLista.current?.setFieldValue('idProdutor', null);
        formRefLista.current?.setFieldValue('produtor', null);
      }

      if (tipoNota !== TipoNotaReferenciaEnum.notaModelo1Aou2) {
        formRefLista.current?.setFieldValue('pessoa', null);
        formRefLista.current?.setFieldValue('idPessoa', null);
      }
      if (tipoNota === TipoNotaReferenciaEnum.notaProdutor) {
        formRefLista.current?.setFieldValue(
          'modeloNotaReferencia',
          ModeloNotaReferenciaEnum.nfProdutor
        );
      }
    },
    [formRefLista]
  );

  return (
    <>
      <JanelaDetalhe
        titulo="Documentos Referenciados"
        tamanho="xl"
        onFecharFormModal={onFecharFormModal}
      >
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Body>
          <Container>
            <Divisor>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Select
                    label="Tipo"
                    name="tipo"
                    options={FormatarEnum({
                      enumObj: TipoNotaReferenciaEnum,
                    })}
                    onChange={(ev) => {
                      handleAlterarCampos(ev.currentTarget.value);
                      formRefLista.current?.setFieldValue('chaveAcesso', null);
                      refresh();
                    }}
                  />
                </Col>
                <Col
                  lg={8}
                  md={6}
                  sm={12}
                  style={{
                    display:
                      tipoNotaReferencia === TipoNotaReferenciaEnum.nfe ||
                      tipoNotaReferencia === TipoNotaReferenciaEnum.cte
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <InputInteiroNulavel
                    style={{ textAlign: 'left' }}
                    ref={inputRefFocus}
                    label="Chave de Acesso"
                    name="chaveAcesso"
                    placeholder="Chave de Acesso"
                    maxLength={44}
                    onBlur={(event) => {
                      if (event.currentTarget.value) {
                        try {
                          const chaveAcessoDesestruturada =
                            DesestruturarChaveAcesso({
                              chaveAcesso: event.currentTarget.value,
                            });

                          formRefLista.current?.setFieldValue(
                            'numero',
                            chaveAcessoDesestruturada.numero
                          );
                          formRefLista.current?.setFieldValue(
                            'serieCodigo',
                            chaveAcessoDesestruturada.serie
                          );
                        } catch (error) {
                          formRefLista.current?.setFieldValue('numero', null);
                          formRefLista.current?.setFieldValue(
                            'serieCodigo',
                            null
                          );
                          if (error?.listaMensagem) {
                            error?.listaMensagem?.forEach((item: string) => {
                              ToastErro(item);
                            });
                          }
                        }
                      }
                    }}
                  />
                </Col>
                <Col
                  lg={8}
                  md={6}
                  sm={12}
                  style={{
                    display:
                      tipoNotaReferencia === TipoNotaReferenciaEnum.notaProdutor
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Input
                    label="Ler Código de Barras (Opcional)"
                    name="lerCodigoBarras"
                    placeholder="Código de Barras (Opcional)"
                    maxLength={44}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12}>
                  <Input
                    label="Série"
                    name="serieCodigo"
                    placeholder="Série"
                    maxLength={3}
                    disabled={
                      !!(
                        tipoNotaReferencia === TipoNotaReferenciaEnum.nfe ||
                        tipoNotaReferencia === TipoNotaReferenciaEnum.cte
                      )
                    }
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputInteiroNulavel
                    label="Número"
                    name="numero"
                    placeholder="Número"
                    disabled={
                      !!(
                        tipoNotaReferencia === TipoNotaReferenciaEnum.nfe ||
                        tipoNotaReferencia === TipoNotaReferenciaEnum.cte
                      )
                    }
                  />
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  style={{
                    display:
                      tipoNotaReferencia ===
                        TipoNotaReferenciaEnum.notaProdutor ||
                      tipoNotaReferencia ===
                        TipoNotaReferenciaEnum.notaModelo1Aou2
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <InputDate label="Data Emissão" name="dataEmissao" />
                </Col>
              </Row>

              <Row
                style={{
                  display:
                    tipoNotaReferencia === TipoNotaReferenciaEnum.notaProdutor
                      ? 'flex'
                      : 'none',
                }}
              >
                <Col lg={4} md={6} sm={12}>
                  <Select
                    label="Modelo"
                    name="modeloNotaReferencia"
                    options={FormatarEnum({
                      enumObj: ModeloNotaReferenciaEnum,
                    })}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <InputAutoCompleteProdutor
                    label="Produtor"
                    name="idProdutor"
                    nomeObjeto="produtor"
                    permitirAdicionar={false}
                  />
                </Col>
              </Row>

              <Row
                style={{
                  display:
                    tipoNotaReferencia ===
                    TipoNotaReferenciaEnum.notaModelo1Aou2
                      ? 'flex'
                      : 'none',
                }}
              >
                <Col lg={10} md={10} sm={10}>
                  <InputAutoCompletePessoa
                    label="Pessoa"
                    name="idPessoa"
                    nomeObjeto="pessoa"
                    permitirAdicionar={false}
                  />
                </Col>
              </Row>
            </Divisor>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={onFecharFormModal}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                type="button"
                onClick={handleClickSalvar}
                disabled={
                  (idDetalheRegistro !== ''
                    ? !SuprimentosComprasNotaFiscalEntrada?.altera
                    : !SuprimentosComprasNotaFiscalEntrada?.inclui) || loading
                }
              >
                <TextoLoading loading={loading}>
                  <BiSave />
                  <span style={{ marginLeft: 10 }}>Salvar</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </JanelaDetalhe>
    </>
  );
};

export default DocumentosReferenciadosDetalhe;
