import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/importacao-exportacao-csv';

interface IImportacaoExportacaoCSVPessoasComunicadorIndex {
  params?: {
    buscarPessoasAtivas?: boolean;
  };
}

interface IImportacaoExportacaoCSVPessoasComunicadorStore {
  params: any;
}

class ImportacaoExportacaoCSVPessoasComunicador {
  public async index({
    params,
  }: IImportacaoExportacaoCSVPessoasComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });
    return response.data;
  }

  public async store({
    params,
  }: IImportacaoExportacaoCSVPessoasComunicadorStore): Promise<any> {
    const response = await api.post(rota, { params });
    return response.data;
  }
}

export default new ImportacaoExportacaoCSVPessoasComunicador();
