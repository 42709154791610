import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/etiqueta/update-imagens';

interface IEtiquetaUpdateImagensComunicadorUpdate {
  params: any;
}

class EtiquetaUpdateImagensComunicador {
  public async update(
    params: IEtiquetaUpdateImagensComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new EtiquetaUpdateImagensComunicador();
