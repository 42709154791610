"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = JurosComposto;
function JurosComposto(dados) {
    const taxaDia = dados.taxaMensal / 30;
    let valor = dados.valorPagar;
    if (dados.diasAtraso >= 30) {
        while (dados.diasAtraso >= 30) {
            valor += valor * (dados.taxaMensal / 100);
            dados.diasAtraso -= 30;
        }
    }
    let totalPagar = valor;
    let juros = totalPagar - dados.valorPagar;
    if (dados.diasAtraso > 0) {
        totalPagar = valor + valor * ((taxaDia * dados.diasAtraso) / 100);
        juros = totalPagar - dados.valorPagar;
    }
    return juros;
}
