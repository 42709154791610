import {
  FormatarEnum,
  SituacaoManifestoTransporteEnum,
  TipoCalculoManifestoTransporteEnum,
  UnidadeMedidaPesoBrutoEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { IoIosCalculator } from 'react-icons/io/index.mjs';
import BtnPadraoButton from '../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../Componentes/Inputs/InputDecimal';
import InputHidden from '../../../../../Componentes/Inputs/InputHidden';
import Select from '../../../../../Componentes/Select';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ManifestoTransporteCalcularValorTotalComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteCalcularValorTotalComunicador';
import ManifestoTransporteCalcularPesoBrutoTotalComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteCalcularPesoBrutoTotalComunicador';

const TotaisTab: React.FC = () => {
  const { formRef, refresh, loading, setLoading, getIdDetalheRegistro } =
    UseForm();

  const situacao = formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const handleCalcularValorTotal = useCallback(async () => {
    try {
      setLoading(true);

      const id = getIdDetalheRegistro() || '';
      const response =
        await ManifestoTransporteCalcularValorTotalComunicador.show({
          id,
        });

      await formRef.current?.setSemExecutarEvento({
        valorTotalMercadoriaTransportada:
          response.valorTotalMercadoriaTransportada,
        tipoCalculoValorTotalMercadoriaTransportada:
          TipoCalculoManifestoTransporteEnum.calculado,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, getIdDetalheRegistro, setLoading]);

  const handleCalcularPesoBruto = useCallback(async () => {
    try {
      setLoading(true);

      const id = getIdDetalheRegistro() || '';
      const response =
        await ManifestoTransporteCalcularPesoBrutoTotalComunicador.show({
          id,
        });

      await formRef.current?.setSemExecutarEvento({
        pesoBrutoTotalCarga: response.pesoBrutoTotalCarga,
        tipoCalculoPesoBrutoTotalCarga:
          TipoCalculoManifestoTransporteEnum.calculado,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, getIdDetalheRegistro, setLoading]);

  const handleAtualizarTipoCalculaValorTotal = useCallback(async () => {
    await formRef.current?.setSemExecutarEvento({
      tipoCalculoValorTotalMercadoriaTransportada:
        TipoCalculoManifestoTransporteEnum.informado,
    });
  }, [formRef]);

  const handleAtualizarTipoCalculaPesoBruto = useCallback(async () => {
    await formRef.current?.setSemExecutarEvento({
      tipoCalculoPesoBrutoTotalCarga:
        TipoCalculoManifestoTransporteEnum.informado,
    });
  }, [formRef]);

  InputHidden({ name: 'tipoCalculoValorTotalMercadoriaTransportada' });
  InputHidden({ name: 'tipoCalculoPesoBrutoTotalCarga' });

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              label="Valor Total Merc. Transp."
              name="valorTotalMercadoriaTransportada"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleAtualizarTipoCalculaValorTotal}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              style={{ height: '35px', marginBottom: '5px', minWidth: '200px' }}
              className="btn-padrao btn-azul-escuro"
              onClick={handleCalcularValorTotal}
              disabled={loading || situacaoBloquearCampos}
            >
              <IoIosCalculator />
              <span style={{ marginLeft: 10 }}>Calcular Valor Total</span>
            </BtnPadraoButton>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="UN. Med. Peso Bruto"
              name="unidadeMedidaPesoBruto"
              options={FormatarEnum({
                enumObj: UnidadeMedidaPesoBrutoEnum,
              })}
              onChange={() => {
                refresh();
              }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              label="Peso Bruto Total Carga"
              name="pesoBrutoTotalCarga"
              casasInteiras={7}
              casasDecimais={4}
              onChange={handleAtualizarTipoCalculaPesoBruto}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              type="button"
              style={{ height: '35px', marginBottom: '5px', minWidth: '200px' }}
              className="btn-padrao btn-azul-escuro"
              onClick={handleCalcularPesoBruto}
              disabled={loading || situacaoBloquearCampos}
            >
              <IoIosCalculator />
              <span style={{ marginLeft: 10 }}>Calcular Peso Bruto</span>
            </BtnPadraoButton>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TotaisTab;
