import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Container, Retangulo } from './styles';
import { UseTableDraggable } from '../HooksTable/TableDraggableContext';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';

let movimentoX = 0;
let inicioWidth = 0;
let inicioX = 0;

let dragElement: number | null = null;
let alterarandoLargura = false;

interface IThDraggable extends IPadraoProps {
  idDrag: string;
  width: number | null;
  index: number;
  style?: React.CSSProperties;
}

let idDragAtual: string;

const ThDraggable: React.FC<IThDraggable> = ({
  idDrag,
  children,
  width: widthInicial,
  index,
  style,
}) => {
  const refTh = useRef<HTMLTableHeaderCellElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const [width, setWidth] = useState(widthInicial);

  const { mover, alterarLargura } = UseTableDraggable();

  const handleDragStart = useCallback(() => {
    idDragAtual = idDrag;
    if (alterarandoLargura) return;
    dragElement = index;
  }, [idDrag, index]);

  const handleDrag = useCallback(() => {
    if (alterarandoLargura) return;
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    idDragAtual = '';
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLTableHeaderCellElement>) => {
      if (alterarandoLargura || idDragAtual !== idDrag) return;
      const draggedIndex = dragElement;
      const alvoIndex = index;

      if (
        draggedIndex === alvoIndex ||
        !refTh.current ||
        (!draggedIndex && draggedIndex !== 0)
      )
        return;

      const alvoTamanho = refTh.current.getBoundingClientRect();

      const alvoCentro = (alvoTamanho.right - alvoTamanho.left) / 2;
      const clienteOffset = { x: event.clientX };
      const draggedLeft = clienteOffset.x - alvoTamanho.left;
      if (draggedIndex < alvoIndex && draggedLeft < alvoCentro) return;
      if (draggedIndex > alvoIndex && draggedLeft > alvoCentro) return;
      mover(draggedIndex, alvoIndex);
      dragElement = alvoIndex;
    },
    [idDrag, index, mover]
  );

  useEffect(() => {
    setWidth(widthInicial);
  }, [widthInicial]);

  return (
    <Container
      draggable
      data-is-dragging={isDragging ? 1 : 0}
      width={width}
      ref={refTh}
      style={style}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDrop={() => {
        dragElement = null;
      }}
    >
      <>
        <Retangulo
          draggable
          onDragStart={(e) => {
            alterarandoLargura = true;
            const realWidth = refTh.current?.clientWidth;

            if (!realWidth) return;
            if (width === null) {
              setWidth(realWidth);
              inicioWidth = realWidth;
            } else {
              inicioWidth = width;
            }
            movimentoX = 0;
            inicioX = e.pageX;
          }}
          onDrag={(e) => {
            let x = 0;
            x = e.pageX - inicioX;

            if (movimentoX !== x) {
              movimentoX = x;
              setWidth(inicioWidth + movimentoX);
            }
          }}
          onDragEnd={(e) => {
            let x = 0;
            x = e.pageX - inicioX;
            let valor = inicioWidth + x;
            if (valor < 0) valor = 0;

            setWidth(valor);
            alterarLargura(index, valor);

            alterarandoLargura = false;
          }}
          onTouchStart={(e) => {
            movimentoX = 0;
            inicioWidth = width || 0;
            inicioX = e.touches[0].pageX;
          }}
          onTouchMove={(e) => {
            const x = e.touches[0].pageX - inicioX;
            if (movimentoX !== x) {
              movimentoX = x;
              setWidth(inicioWidth + movimentoX);
            }
          }}
        />
        {children}
      </>
    </Container>
  );
};

export default ThDraggable;
