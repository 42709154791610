/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import { BuscaPessoasCadastradasEnum } from '@elogestor/util';
import React, { useRef, useState, useCallback } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  ProgressBar,
  Alert,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import Papa from 'papaparse';
import { BiInfoCircle } from 'react-icons/bi/index.mjs';
import dottie from 'dottie';
import { PiArrowFatRightFill } from 'react-icons/pi/index.mjs';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import PessoaImportacaoDeParaManual from './PessoaImportacaoDeParaManual/PessoaImportacaoDeParaManual';
import ImportacaoExportacaoCSVPessoasComunicador from '../../../../../../../Comunicador/Comercial/Pessoas/ImportacaoExportacaoCSVPessoas/Comunicador/ImportacaoExportacaoCSVPessoasComunicador';

interface ISelecionarPessoasCSVImportacaoProps {
  onFecharFormModalLista(): void;
  setListaErros: (value: any) => void;
  filtro?: {
    buscaPessoasCadastradas: BuscaPessoasCadastradasEnum;
    atualizarPessoasExistentes: boolean;
  };
}

const SelecionarPessoasCSVImportacao: React.FC<
  ISelecionarPessoasCSVImportacaoProps
> = ({ onFecharFormModalLista, filtro, setListaErros }) => {
  // #region Estados

  const [loading, setLoading] = useState(false);
  const [fileDataArray, setFileDataArray] = useState<any[]>([]);
  const [etapaCarregamento, setEtapaCarregamento] = useState(0);
  const [progressBarOpen, setProgressBarOpen] = useState(false);
  const [showMensagemAjuda, setShowMensagemAjuda] = useState(false);
  const [listaDePara, setListaDePara] = useState<any[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);

  // #endregion Estados

  // #region Funções

  const mapearCampos = useCallback(() => {
    if (fileDataArray[0]?.data && listaDePara.length > 0) {
      const listaMapeada = fileDataArray[0].data.map((item: any) => {
        const itemMapeado: any = {};

        listaDePara.forEach((dePara) => {
          itemMapeado[dePara.field] = item[dePara.value];
        });

        return itemMapeado;
      });

      return listaMapeada;
    }

    return [];
  }, [fileDataArray, listaDePara]);

  const handleOnSelecionarCSV = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files) return;

    try {
      setLoading(true);

      const isObjectEmpty = (obj: Record<string, string>): boolean => {
        return Object.keys(obj).every((key) => obj[key] === '');
      };

      const convertSimToTrue = (
        data: Record<string, string>[]
      ): Record<string, any>[] => {
        return data.map((row) => {
          const newRow: Record<string, any> = {};
          Object.keys(row).forEach((key) => {
            const valor = row[key].toUpperCase();
            newRow[key] =
              valor === 'SIM'
                ? true
                : valor === 'NAO' || valor === 'NÃO'
                  ? false
                  : row[key];
          });
          return newRow;
        });
      };

      for (let i = 0; i < inputFileRef.current.files.length; i++) {
        const file = inputFileRef.current.files[i];

        const parseResult = await new Promise<{ data: Record<string, any>[] }>(
          (resolve, reject) => {
            Papa.parse<Record<string, string>>(file, {
              delimiter: ';',
              dynamicTyping: false,
              transform: (value) => {
                const regex = /^\d+([,.]\d+)?[Ee][+-]?\d+$/;
                if (regex.test(value)) {
                  const numericValue = Number(value.replace(',', '.'));
                  if (!isNaN(numericValue)) {
                    return numericValue.toString();
                  }
                }
                return value.trim();
              },
              complete: (result) => {
                const convertedData = convertSimToTrue(
                  result.data as Record<string, string>[]
                );
                const nonEmptyLines = convertedData.filter(
                  (line) => !isObjectEmpty(line)
                );

                resolve({ ...result, data: nonEmptyLines });
              },
              header: true,
              error: (error) => {
                reject(error);
              },
            });
          }
        );

        setFileDataArray((oldArray) => [...oldArray, parseResult]);
      }

      setLoading(false);
    } catch (error) {
      setFileDataArray([]);
      TratarErros(error);
      setLoading(false);
    }
  }, [setLoading, setFileDataArray, inputFileRef]);

  const handleConcluir = useCallback(async (): Promise<void> => {
    try {
      if (fileDataArray[0]?.data && fileDataArray[0]?.data.length > 0) {
        const dados = mapearCampos();

        const dadosSemCamposVazios = dados.map((objeto: any) => {
          const novoObjeto: any = {};

          Object.entries(objeto).forEach(([chave, valor]) => {
            if (valor !== '') {
              novoObjeto[chave] = valor;
            }
          });

          return novoObjeto;
        });

        const dadosFormatados = dottie.transform(dadosSemCamposVazios);

        const lotes = [];

        for (let i = 0; i < dadosFormatados.length; i += 50) {
          const lote = dadosFormatados.slice(i, i + 50);
          lotes.push(lote);
        }

        setProgressBarOpen(true);

        for (let index = 0; index < lotes.length; index++) {
          const listaItens = lotes[index];

          const response =
            await ImportacaoExportacaoCSVPessoasComunicador.store({
              params: { listaItens, filtro },
            });

          if (response) {
            setEtapaCarregamento(
              Math.round(((index + 1) / lotes.length) * 100)
            );

            if (response.listaMensagemErro) {
              setListaErros((value: any) => [
                ...value,
                ...response.listaMensagemErro,
              ]);
            }
          }
        }

        ToastSucesso('Pessoas importadas com sucesso!');
        setProgressBarOpen(false);
      }

      setProgressBarOpen(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      setProgressBarOpen(false);
    }

    if (onFecharFormModalLista) onFecharFormModalLista();
  }, [
    fileDataArray,
    filtro,
    mapearCampos,
    onFecharFormModalLista,
    setListaErros,
  ]);

  // #endregion Funções

  return (
    <JanelaDetalhe
      titulo="Assistente de Importação de Pessoas"
      tamanho="xl"
      onFecharFormModal={!progressBarOpen ? onFecharFormModalLista : () => null}
      backdrop="static"
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body style={{ maxHeight: 500, overflow: 'auto' }}>
        <ProgressBar
          style={{
            height: progressBarOpen ? '20px' : '0',
            width: '100%',
            marginBottom: '5pt',
            marginTop: progressBarOpen ? '20pt' : '0',
            opacity: progressBarOpen ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
          animated
          striped
          now={etapaCarregamento}
        />
        <span
          style={{
            opacity: progressBarOpen ? 1 : 0,
            height: progressBarOpen ? '20px' : '0',
            transition: 'opacity 0.5s ease-in-out',
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            color: '#1f7dc4',
          }}
        >
          Por favor aguarde {etapaCarregamento}%
        </span>

        {!progressBarOpen && (
          <div>
            <Row>
              <Col>
                <Alert show={showMensagemAjuda} variant="warning">
                  <Alert.Heading>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="fw-bold" style={{ fontSize: '20px' }}>
                        <BiInfoCircle size={30} /> Informação
                      </p>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">Fechar mensagem</Tooltip>
                        }
                      >
                        <button
                          type="button"
                          onClick={() => setShowMensagemAjuda(false)}
                          style={{
                            border: 'none',
                            marginRight: '10pt',
                            backgroundColor: 'transparent',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            position: 'absolute',
                            right: 0,
                            top: 8,
                          }}
                        >
                          ✖
                        </button>
                      </OverlayTrigger>
                    </div>
                  </Alert.Heading>

                  <p
                    className="text-muted fw-bold"
                    style={{ fontSize: '16px' }}
                  >
                    Este é o Assistente de Importação de Pessoas. Aqui você pode
                    selecionar o seu arquivo CSV e mapear os campos do seu
                    arquivo para os campos compreendidos pelo Elo Gestor. Os
                    itens importados que tem a nomenclatura correta serão
                    mapeados automaticamente e marcados com uma{' '}
                    <span style={{ color: '#28a745' }}>
                      <PiArrowFatRightFill size={20} />
                    </span>
                    .
                  </p>
                </Alert>
              </Col>
            </Row>

            {!fileDataArray[0]?.data && (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={6} md={12} sm={12}>
                  <input
                    type="file"
                    accept=".csv"
                    multiple
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={handleOnSelecionarCSV}
                  />
                  <button
                    type="button"
                    style={{
                      border: '0',
                      borderRadius: '5px',
                      color: '#fff',
                      cursor: 'pointer',
                      padding: '6px 20px',
                      height: '35px',
                    }}
                    className="btn-azul-escuro"
                    onClick={() => {
                      inputFileRef.current?.click();
                    }}
                    disabled={loading}
                  >
                    <TextoLoading loading={loading}>
                      Selecionar arquivos CSV &#187;
                    </TextoLoading>
                  </button>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    opacity: !showMensagemAjuda ? 1 : 0,
                    alignItems: 'center',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">Abrir ajuda</Tooltip>}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setShowMensagemAjuda(!showMensagemAjuda);
                      }}
                    >
                      <BiInfoCircle className="m-0 p-0" size={25} />
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            )}

            {fileDataArray[0]?.meta?.fields && (
              <PessoaImportacaoDeParaManual
                listaOpcoesCSV={fileDataArray[0]?.meta?.fields}
                onConfirmarImportacao={(dados) => setListaDePara(dados)}
              />
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModalLista}
              disabled={loading || progressBarOpen}
              style={{
                opacity: progressBarOpen ? 0 : 1,
                transition: 'opacity 0.5s ease',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                <TextoLoading loading={loading}>
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </TextoLoading>
              </span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
                opacity: progressBarOpen ? 0 : 1,
                transition: 'opacity 0.5s ease',
              }}
              type="button"
              onClick={handleConcluir}
              disabled={loading || progressBarOpen}
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>Concluir</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default SelecionarPessoasCSVImportacao;
