import api from '../../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/item/impostos-fiscais/icms';

interface INotaFiscalEntradaItemImpostoIcmsComunicadorShow {
  id: string;
  params: any;
}

class NotaFiscalEntradaItemImpostoIcmsComunicador {
  public async show(
    params: INotaFiscalEntradaItemImpostoIcmsComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalEntradaItemImpostoIcmsComunicador();
