import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/sped-tipo-produto-auto-complete';

interface ISpedTipoProdutoAutoCompleteComunicadorIndex {
  params: any;
}

class SpedTipoProdutoAutoCompleteComunicador {
  public async index(
    params: ISpedTipoProdutoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SpedTipoProdutoAutoCompleteComunicador();
