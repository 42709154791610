const PedidoVendaTotalPorModeloDocumentoPlanilha = [
  { titulo: 'Modelo de Documento', valor: 'valores.chaveFormatada' },

  { titulo: 'Numero', valor: 'listaPedidoVendaItem.pedidoVendaNumero' },

  {
    titulo: 'Data de Emissão',
    valor: 'listaPedidoVendaItem.pedidoVendaDataHoraEmissao',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaPedidoVendaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaPedidoVendaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaPedidoVendaItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaPedidoVendaItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaPedidoVendaItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaPedidoVendaItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaPedidoVendaItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaPedidoVendaItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaPedidoVendaItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaPedidoVendaItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaPedidoVendaItem.somatorioValorTotal',
  },
];

export default PedidoVendaTotalPorModeloDocumentoPlanilha;
