import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/cotacao-compra/cotacao-compra-resumo-por-mes-ano-emissao';

interface ICotacaoCompraResumoPorMesAnoEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CotacaoCompraResumoPorMesAnoEmissaoRelatorioComunicador {
  public async index(
    params: ICotacaoCompraResumoPorMesAnoEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CotacaoCompraResumoPorMesAnoEmissaoRelatorioComunicador();
