import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda';

interface IPedidoVendaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IPedidoVendaComunicadorShow {
  id: string;
}

interface IPedidoVendaComunicadorStore {
  params: any;
}

interface IPedidoVendaComunicadorUpdate {
  id: string;
  params: any;
}

interface IPedidoVendaComunicadorDeletar {
  id?: string;
  idPedidoVendaItem?: string;
}

class PedidoVendaComunicador {
  public async index(params: IPedidoVendaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IPedidoVendaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPedidoVendaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IPedidoVendaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete({
    id,
    idPedidoVendaItem,
  }: IPedidoVendaComunicadorDeletar): Promise<any> {
    const response = await api.delete(rota, {
      params: { id, idPedidoVendaItem },
    });

    return response.data;
  }
}

export default new PedidoVendaComunicador();
