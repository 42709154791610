"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DesestruturarPesquisa;
/* eslint-disable prefer-destructuring */
const Validar_1 = require("../../Validar");
function DesestruturarPesquisa({ texto, removerZerosEsquerda, valorUnitarioProduto, }) {
    let textoPesquisa = '';
    let quantidade = 1;
    let valorUnitario = 0;
    let valorTotal = 0;
    if (texto.includes('*')) {
        const listaTexto = texto.split('*');
        if (listaTexto.length === 2) {
            if (listaTexto[0].includes(',')) {
                quantidade = String(listaTexto[0]).ConverterParaNumber();
            }
            else {
                quantidade = Number(listaTexto[0]);
            }
            textoPesquisa = listaTexto[1];
            if (valorUnitarioProduto) {
                valorUnitario = valorUnitarioProduto;
                valorTotal = quantidade * valorUnitario;
            }
        }
        else if (listaTexto.length === 3) {
            if (listaTexto[0].includes(',')) {
                quantidade = String(listaTexto[0]).ConverterParaNumber();
            }
            else {
                quantidade = Number(listaTexto[0]);
            }
            if (listaTexto[1].includes(',')) {
                valorUnitario = String(listaTexto[1]).ConverterParaNumber();
            }
            else {
                valorUnitario = Number(listaTexto[1]);
            }
            textoPesquisa = listaTexto[2];
            valorTotal = quantidade * valorUnitario;
        }
        if (removerZerosEsquerda) {
            textoPesquisa = textoPesquisa.RemoverZerosEsquerda();
        }
    }
    else if ((0, Validar_1.ValidarCodigoBarrasGtin)(texto)) {
        if (texto.startsWith('2')) {
            const codigoProduto = texto.substring(1, 5);
            const valorProduto = texto.substring(5, 12);
            textoPesquisa = codigoProduto;
            const inteiro = valorProduto.substring(0, 5);
            const decimal = valorProduto.substring(5, 7);
            valorTotal = Number(`${inteiro}.${decimal}`);
            if (valorUnitarioProduto) {
                valorUnitario = valorUnitarioProduto;
                quantidade = Number(valorTotal / valorUnitario).Arredondar(4);
            }
            if (removerZerosEsquerda) {
                textoPesquisa = textoPesquisa.RemoverZerosEsquerda();
            }
        }
        else {
            textoPesquisa = texto;
            if (valorUnitarioProduto) {
                valorUnitario = valorUnitarioProduto;
                valorTotal = quantidade * valorUnitario;
            }
        }
    }
    else {
        textoPesquisa = texto;
        if (valorUnitarioProduto) {
            valorUnitario = valorUnitarioProduto;
            valorTotal = quantidade * valorUnitario;
        }
        if (removerZerosEsquerda) {
            textoPesquisa = textoPesquisa.RemoverZerosEsquerda();
        }
    }
    return {
        textoPesquisa,
        quantidade,
        valorUnitario,
        valorTotal,
    };
}
