import { pdf } from '@react-pdf/renderer';
import DownloadArquivo from '../Arquivos/DownloadArquivo';
import IRelatorio from './Interface/IRelatorio';

interface IPDF {
  documento: Parameters<typeof pdf>[0];
}

export default async function PDF({ documento }: IPDF): Promise<IRelatorio> {
  const documentoPdf = pdf(documento);
  const blob = await documentoPdf.toBlob();

  return {
    AbrirArquivo: () => {
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    },
    DownloadArquivo: () => {
      DownloadArquivo(blob, 'nome_pdf.pdf');
    },
    GetBlob: () => {
      return blob;
    },
  };
}
