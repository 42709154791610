import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ImpressaoEtiquetasPedidoVendaItemListaHook } from './ImpressaoEtiquetasPedidoVendaItemListaHook';

const ImpressaoEtiquetasPedidoVendaItemHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ImpressaoEtiquetasPedidoVendaItemListaHook>
      {children}
    </ImpressaoEtiquetasPedidoVendaItemListaHook>
  );
};

export default ImpressaoEtiquetasPedidoVendaItemHook;
