import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/empresa-auto-complete';

interface IEmpresaAutoCompleteComunicadorIndex {
  params: any;
}

class EmpresaAutoCompleteComunicador {
  public async index(
    params: IEmpresaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new EmpresaAutoCompleteComunicador();
