import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/upload-pdf';

interface IManifestoTransporteUploadPdfComunicadorUpdate {
  params: any;
}

class ManifestoTransporteUploadPdfComunicador {
  public async update(
    params: IManifestoTransporteUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ManifestoTransporteUploadPdfComunicador();
