import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  IManifestoTransporteSeguroAverbacaoLista,
  SituacaoManifestoTransporteEnum,
} from '@elogestor/util';
import { Table, Dropdown, Container, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRecuperarFormulario } from '../../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { TBody } from '../../../../../../../../../Componentes/Styles/TBody';
import { UseFormSeguro } from '../Hooks/FormSeguroHook';
import Input from '../../../../../../../../../Componentes/Inputs/Input';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import BtnAcoesButton from '../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheSeguroAverbacaoProps {
  name: string;
  className?: string;
}

const ManifestoTransporteSeguroAverbacaoLista: React.FC<
  IListaDetalheSeguroAverbacaoProps
> = ({ name, className }) => {
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const formSeguro = UseFormSeguro();

  const {
    formRefLista,
    formRefDetalhe,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IManifestoTransporteSeguroAverbacaoLista[]>([]);

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const [listaValor, setListaValor] = useState<
    IManifestoTransporteSeguroAverbacaoLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IManifestoTransporteSeguroAverbacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const idDetalheRegistroPrincipal = formSeguro.getIdDetalheRegistro();
    if (!idDetalheRegistroPrincipal) {
      const formSeguroData = formSeguro.formRefDetalhe.current?.getData();
      await formSeguro.handleSubmit(
        formSeguroData,
        formSeguro.formRefDetalhe.current
      );
    }

    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [formRefLista, formSeguro, handleSubmit, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const idDetalheRegistroPrincipal = formSeguro.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formSeguro.formRefLista.current?.getData();

        const { erro } = await formSeguro.handleSubmit(
          dataPrincipal,
          formRefLista.current
        );
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formRefLista, formSeguro, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formSeguro.handleCarregarDados();
  }, [formSeguro, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formSeguro.handleCarregarDados();
  }, [formSeguro, setIdDetalheRegistro]);

  return (
    <Container
      className={className}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <FormCia ref={formRefLista}>
        <div style={{ width: '100%' }}>
          <Row>
            <Col lg={9} md={7} sm={12}>
              <Input
                label="Número da Averbação"
                name="averbacao"
                placeholder="Número da Averbação"
                maxLength={40}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={3} md={5} sm={12} style={{ alignSelf: 'flex-end' }}>
              <BtnPadraoButton
                style={{}}
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui ||
                  situacaoBloquearCampos ||
                  formSeguro.loading ||
                  loading
                }
              >
                <TextoLoading loading={formSeguro.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </div>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '100%' }}>
                <span>Número da Averbação</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor.map((valor, index) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formSeguro.loading || loading) return;
                    handleEditar(valor.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{valor.averbacao}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(valor.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            situacaoBloquearCampos ||
                            formSeguro.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </Container>
  );
};

export default ManifestoTransporteSeguroAverbacaoLista;
