import { useCallback, useRef } from 'react';

interface IHandleAdicionarAcao {
  valor: any;
  chave: string | number;
}

export interface IAcao {
  chave: string | number;
  ordem: number | null;
  valor: any;
}

type IHandleAdicionarAcaoDeletar = IHandleAdicionarAcao;

interface IHandleAdicionarAcaoInserir extends IHandleAdicionarAcao {
  ordem: number;
}

interface IHandleAdicionarAcaoMover extends IHandleAdicionarAcao {
  ordem: number;
}

export interface IListaAcao {
  listaInserir: IAcao[];
  listaMover: IAcao[];
  listaDeletar: IAcao[];
}

interface IUseListaAcao {
  handleAdicionarAcaoInserir: ({
    valor,
    chave,
  }: IHandleAdicionarAcaoInserir) => void;
  handleAdicionarAcaoMover: ({
    valor,
    chave,
    ordem,
  }: IHandleAdicionarAcaoMover) => void;
  handleAdicionarAcaoDeletar: ({
    valor,
    chave,
  }: IHandleAdicionarAcaoDeletar) => void;
  obterListaAcao: () => IListaAcao;
  setListaAcao: (novaLista: IListaAcao) => void;
}

const UseListaAcao = (): IUseListaAcao => {
  const listaInserir = useRef<IAcao[]>([]);
  const listaMover = useRef<IAcao[]>([]);
  const listaDeletar = useRef<IAcao[]>([]);

  const handleAdicionarAcaoInserir = useCallback(
    ({ valor, chave, ordem }: IHandleAdicionarAcaoInserir) => {
      const indexDeletar = listaDeletar.current.findIndex((acaoFindIndex) => {
        return acaoFindIndex.chave === chave;
      });
      if (indexDeletar >= 0) {
        const acao: IAcao = {
          chave,
          valor,
          ordem,
        };
        listaMover.current.push(acao);
        listaDeletar.current.splice(indexDeletar, 1);
      } else {
        const acao: IAcao = {
          chave,
          valor,
          ordem,
        };
        listaInserir.current.push(acao);
      }
    },
    []
  );

  const handleAdicionarAcaoMover = useCallback(
    ({ valor, chave, ordem }: IHandleAdicionarAcaoMover) => {
      const indexInserir = listaInserir.current.findIndex((acaoFindIndex) => {
        return acaoFindIndex.chave === chave;
      });
      if (indexInserir >= 0) {
        listaInserir.current[indexInserir].ordem = ordem;
      } else {
        const indexMover = listaMover.current.findIndex((acaoFindIndex) => {
          return acaoFindIndex.chave === chave;
        });
        if (indexMover >= 0) {
          listaMover.current[indexMover].ordem = ordem;
        } else {
          const acao: IAcao = {
            chave,
            valor,
            ordem,
          };
          listaMover.current.push(acao);
        }
      }
    },
    []
  );

  const handleAdicionarAcaoDeletar = useCallback(
    ({ valor, chave }: IHandleAdicionarAcaoDeletar) => {
      const indexInserir = listaInserir.current.findIndex((acaoFindIndex) => {
        return acaoFindIndex.chave === chave;
      });
      if (indexInserir >= 0) {
        listaInserir.current.splice(indexInserir, 1);
      } else {
        const indexMover = listaMover.current.findIndex((acaoFindIndex) => {
          return acaoFindIndex.chave === chave;
        });
        if (indexMover >= 0) listaMover.current.splice(indexMover, 1);

        const acao: IAcao = {
          chave,
          valor,
          ordem: null,
        };
        listaDeletar.current.push(acao);
      }
    },
    []
  );

  const obterListaAcao = useCallback(() => {
    return {
      listaInserir: listaInserir.current,
      listaMover: listaMover.current,
      listaDeletar: listaDeletar.current,
    };
  }, []);

  const setListaAcao = useCallback((novaLista: IListaAcao) => {
    listaInserir.current = novaLista.listaInserir;
    listaMover.current = novaLista.listaMover;
    listaDeletar.current = novaLista.listaDeletar;
  }, []);

  return {
    handleAdicionarAcaoInserir,
    handleAdicionarAcaoMover,
    handleAdicionarAcaoDeletar,
    obterListaAcao,
    setListaAcao,
  };
};

export default UseListaAcao;
