import api from '../../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/transmissao/evento';

interface IConhecimentoTransporteTransmissaoEventoComunicadorIndex {
  params: {
    idValor: string;
  };
}

interface IConhecimentoTransporteTransmissaoEventoComunicadorShow {
  id: string;
}

interface IConhecimentoTransporteTransmissaoEventoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTransmissaoEventoComunicadorUpdate {
  id: string;
  params: any;
}

interface IConhecimentoTransporteTransmissaoEventoComunicadorDelete {
  id: string;
}

class ConhecimentoTransporteTransmissaoEventoComunicador {
  public async index(
    params: IConhecimentoTransporteTransmissaoEventoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: IConhecimentoTransporteTransmissaoEventoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IConhecimentoTransporteTransmissaoEventoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteTransmissaoEventoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTransmissaoEventoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTransmissaoEventoComunicador();
