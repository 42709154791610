import React, { useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import IPop from '../Interface/IPop';

const Confirmacao: React.FC<IPop> = ({
  cb,
  mensagem,
  titulo,
  cancelar = 'Cancelar',
  confimar = 'OK',
  fecharJanela,
  padraoSelecionadoSim = true,
}) => {
  const buttonConfirmarRef = useRef<HTMLButtonElement>(null);
  const buttonCancelarRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (padraoSelecionadoSim) {
      buttonConfirmarRef.current?.focus();
    } else {
      buttonCancelarRef.current?.focus();
    }
  }, [padraoSelecionadoSim]);

  return (
    <Modal
      show
      onHide={async () => {
        fecharJanela();
        await cb(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{mensagem}</Modal.Body>

      <Modal.Footer>
        <Button
          style={{ width: 95 }}
          variant="secondary"
          onClick={async () => {
            fecharJanela();
            await cb(false);
          }}
          ref={buttonCancelarRef}
        >
          {cancelar}
        </Button>

        <Button
          style={{ width: 95 }}
          variant="primary"
          onClick={async () => {
            fecharJanela();
            await cb(true);
          }}
          ref={buttonConfirmarRef}
        >
          {confimar}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirmacao;
