import React, { useState, useEffect } from 'react';
import { IOrdemProducaoItem } from '..';
import InputTabelaDecimal from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';

interface IGerarOrdemProducaoItem {
  item: IOrdemProducaoItem;
  onChange: (item: IOrdemProducaoItem) => void;
  index: number;
  marcarTodos?: boolean;
}

const GerarOrdemProducaoItem: React.FC<IGerarOrdemProducaoItem> = ({
  item,
  onChange,
  index,
  marcarTodos,
}) => {
  const [selecionado, setSelecionado] = useState<boolean>(item.selecionado);
  const [quantidade, setQuantidade] = useState<number>(
    item.quantidadeDisponivel
  );

  useEffect(() => {
    onChange({
      ...item,
      selecionado: marcarTodos || selecionado,
      quantidade,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantidade, selecionado]);

  useEffect(() => {
    if (marcarTodos === true) {
      onChange({
        ...item,
        selecionado: true,
        quantidade,
      });
    } else {
      onChange({
        ...item,
        selecionado,
        quantidade,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcarTodos]);

  return (
    <tr>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <input
          disabled={marcarTodos}
          type="checkbox"
          checked={marcarTodos || selecionado}
          onChange={(e) => {
            setSelecionado(e.target.checked);
          }}
        />
      </td>
      <td className="lista-texto">
        <div>{item.codigo}</div>
      </td>
      <td className="lista-texto">
        <div>{item.descricao}</div>
      </td>
      <td className="lista-texto">
        <div>{item.unidadeMedida}</div>
      </td>

      <td className="lista-valor">
        <div>
          {Number(item.quantidadePedido).FormatarParaPtBr({
            minimoCasasDecimais: 10,
            maximoCasasDecimais: 10,
          })}
        </div>
      </td>
      <td className="lista-valor">
        <div>
          {Number(item?.quantidadeDisponivel).FormatarParaPtBr({
            minimoCasasDecimais: 10,
            maximoCasasDecimais: 10,
          })}
        </div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <div>
          <InputTabelaDecimal
            valorPadrao={item.quantidadeDisponivel}
            casasDecimais={10}
            onChange={(e) => {
              setQuantidade(String(e.target.value).ConverterParaNumber());
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default GerarOrdemProducaoItem;
