import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { IConhecimentoTransporteTerceiroServicoLista } from '@elogestor/util';
import { Dropdown, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { UltimaTr } from './styles';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputHidden from '../../../../../../../../Componentes/Inputs/InputHidden';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Detalhe from '../Detalhe';

interface IListaConhecimentoTransporteTerceiroServicoProps {
  name: string;
  bloquearCamposPorImportacao: boolean;
}

const ConhecimentoTransporteTerceiroServicoLista: React.FC<
  IListaConhecimentoTransporteTerceiroServicoProps
> = ({ name, bloquearCamposPorImportacao }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const formPrincipal = UseForm();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);
  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IConhecimentoTransporteTerceiroServicoLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IConhecimentoTransporteTerceiroServicoLista[]
  >([]);

  const handleAdicionar = useCallback(async () => {
    const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();

    if (!idDetalheRegistroPrincipal) {
      const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
      if (erro) return;
    }

    setIdDetalheRegistro('');
    setShowFormModal(true);
  }, [
    formPrincipal,
    handleSalvarContinuar,
    isJanelaAutoComplete,
    setIdDetalheRegistro,
  ]);

  const handleEditar = useCallback(
    async (id: string) => {
      const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formPrincipal.formRef.current?.getData();

        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback(() => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IConhecimentoTransporteTerceiroServicoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidarSeAlterouInputHidden = useCallback(
    (ref: any, valorInicial: any): boolean => {
      if (Number(ref.current) === Number(valorInicial)) {
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  InputHidden({
    name: 'valorTotalServico',
    setValue: (valor) => valor ?? 0,
    validarSeMudou: handleValidarSeAlterouInputHidden,
  });

  return (
    <>
      <BtnPadraoButton
        className="btn-padrao btn-verde btn-adicionar"
        style={{ maxWidth: 250 }}
        type="button"
        onClick={handleAdicionar}
        disabled={
          !permissao?.inclui ||
          bloquearCamposPorImportacao ||
          formPrincipal.loading ||
          loading
        }
      >
        <TextoLoading loading={formPrincipal.loading || loading}>
          <IoMdAddCircleOutline />
          <span style={{ marginLeft: 10 }}>Adicionar</span>
        </TextoLoading>
      </BtnPadraoButton>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '60%' }}>
                <span>Serviço</span>
              </th>
              <th className="lista-valor" style={{ width: '35%' }}>
                <span>Valor</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(item.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">{item.produto.descricao}</td>
                  <td className="lista-valor">
                    {Number(item.valor).FormatarParaPtBr()}
                  </td>

                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(item.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            bloquearCamposPorImportacao ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <UltimaTr>
              <th />
              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>Valor Total</div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    {Number(
                      formPrincipal.formRef.current?.getFieldValue(
                        'valorTotalServico'
                      )
                    ).FormatarParaPtBr()}
                  </div>
                </div>
              </th>
              <th />
            </UltimaTr>
          </tfoot>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onFecharFormModal={handleFecharFormModal}
          onSalvarFormModal={handleSalvarFormModal}
          bloquearCamposPorImportacao={bloquearCamposPorImportacao}
        />
      )}
    </>
  );
};

export default ConhecimentoTransporteTerceiroServicoLista;
