/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { BiReset } from 'react-icons/bi/index.mjs';
import Divisor from '../../../../../Componentes/Divisor';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import EmpresaLogotipoComunicador from '../../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaLogotipoComunicador';
import Button from '../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import EmpresaPlanoFundoComunicador from '../../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaPlanoFundoComunicador';
import EmpresaPlanoFundoRedefinirComunicador from '../../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaPlanoFundoRedefinirComunicador';
import TextoLoading from '../../../../../Componentes/TextoLoading';

const LogotipoTab: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { getIdDetalheRegistro, setLoading, loading } = UseForm();
  const idDetalheRegistro = getIdDetalheRegistro();
  const { abrirJanela } = UseConfirmacao();

  const [timeLogo, setTimeLogo] = useState('');
  const [timePlanoFundo, setTimePlanoFundo] = useState('');
  const [urlLogo, setUrlLogo] = useState('');
  const [urlPlanoFundo, setUrlPlanoFundo] = useState('');

  const inputLogotipoRef = useRef<HTMLInputElement>(null);
  const inputPlanoFundoRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function buscarLogo(): Promise<void> {
      try {
        const response = await EmpresaLogotipoComunicador.show();
        setUrlLogo(response);
      } catch (error) {
        TratarErros(error);
      }
    }

    buscarLogo();
  }, [timeLogo]);

  useEffect(() => {
    async function buscarPlanoFundo(): Promise<void> {
      try {
        const response = await EmpresaPlanoFundoComunicador.show();
        setUrlPlanoFundo(response);
      } catch (error) {
        TratarErros(error);
      }
    }

    buscarPlanoFundo();
  }, [timePlanoFundo]);

  // #region Logo

  const handleOnSelecionarLogotipo = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const formData = new FormData();
        const file = event.target.files[0];

        const options = {
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          setLoading(true);

          const compressedFile = await imageCompression(file, options);
          const convertedBlobFile = new File([compressedFile], file.name, {
            type: compressedFile.type,
            lastModified: Date.now(),
          });

          formData.append('logotipo', convertedBlobFile);

          await EmpresaLogotipoComunicador.update({
            params: formData,
          });

          setTimeLogo(`?${new Date().getTime()}`);
          setLoading(false);
        } catch (error) {
          ToastErro(error);
          setLoading(false);
        }
      }
    },
    [setLoading]
  );

  const handleExcluirLogotipo = useCallback(async () => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja excluir o logotipo?</span>
        ),
      });

      if (resposta) {
        setLoading(true);

        const response = await EmpresaLogotipoComunicador.delete();

        if (inputLogotipoRef.current && inputLogotipoRef.current.files) {
          inputLogotipoRef.current.value = '';
        }

        setLoading(false);
        setTimeLogo(`?${new Date().getTime()}`);
        ToastSucesso(response.mensagem);
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, setLoading]);

  // #endregion Logo

  // #region Plano de Fundo

  const handleOnSelecionarPlanoFundo = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const formData = new FormData();
        const file = event.target.files[0];

        const options = {
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          setLoading(true);

          const compressedFile = await imageCompression(file, options);
          const convertedBlobFile = new File([compressedFile], file.name, {
            type: compressedFile.type,
            lastModified: Date.now(),
          });

          formData.append('planoFundo', convertedBlobFile);

          await EmpresaPlanoFundoComunicador.update({
            params: formData,
          });

          setTimePlanoFundo(`?${new Date().getTime()}`);
          setLoading(false);
        } catch (error) {
          ToastErro(error);
          setLoading(false);
        }
      }
    },
    [setLoading]
  );

  const handleExcluirPlanoFundo = useCallback(async () => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja excluir o Plano de Fundo?</span>
        ),
      });

      if (resposta) {
        setLoading(true);

        const response = await EmpresaPlanoFundoComunicador.delete();

        if (inputPlanoFundoRef.current && inputPlanoFundoRef.current.files) {
          inputPlanoFundoRef.current.value = '';
        }

        setLoading(false);
        setTimePlanoFundo(`?${new Date().getTime()}`);
        ToastSucesso(response.mensagem);
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, setLoading]);

  const handleRedefinirPlanoFundo = useCallback(async () => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja redefinir o Plano de Fundo?
          </span>
        ),
      });

      if (resposta) {
        setLoading(true);

        const response = await EmpresaPlanoFundoRedefinirComunicador.update();

        if (inputPlanoFundoRef.current && inputPlanoFundoRef.current.files) {
          inputPlanoFundoRef.current.value = '';
        }

        setLoading(false);
        setTimePlanoFundo(`?${new Date().getTime()}`);
        ToastSucesso(response.mensagem);
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, setLoading]);

  // #endregion Plano de Fundo

  return (
    <div>
      <Divisor>
        <div style={{ display: telaPequena ? 'block' : 'flex' }}>
          <div style={{ width: 300 }}>
            <h5>Logotipo</h5>
            <div
              style={{
                position: 'relative',
                width: 225,
                height: 150,
                margin: 0,
                border: '1px solid #d3d3d3',
                borderRadius: 5,
              }}
            >
              {idDetalheRegistro && (
                <div
                  onClick={() => {
                    if (loading) return;
                    inputLogotipoRef.current?.click();
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    margin: 0,
                    height: '100%',
                    width: '100%',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{
                      maxWidth: '4cm',
                      maxHeight: '3cm',
                      borderRadius: 0,
                      zIndex: 2,
                    }}
                    src={urlLogo}
                    alt=""
                  />
                </div>
              )}
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              ref={inputLogotipoRef}
              onChange={handleOnSelecionarLogotipo}
            />

            <button
              type="button"
              style={{
                border: '0',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                marginTop: '5px',
                padding: '6px 20px',
                height: '35px',
              }}
              className="btn-azul-escuro"
              onClick={() => {
                inputLogotipoRef.current?.click();
              }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                Selecionar um arquivo &#187;
              </TextoLoading>
            </button>

            <Button
              type="button"
              style={{ marginLeft: 5, marginBottom: 2, height: 35 }}
              onClick={handleExcluirLogotipo}
              disabled={!urlLogo || loading}
            >
              <TextoLoading loading={loading}>
                <RiDeleteBin6Line />
              </TextoLoading>
            </Button>
          </div>

          <div style={{ width: 300, marginLeft: telaPequena ? 0 : 50 }}>
            <h5>Plano de fundo marca d'agua</h5>
            <div
              style={{
                position: 'relative',
                width: 225,
                height: 150,
                margin: 0,
                border: '1px solid #d3d3d3',
                borderRadius: 5,
              }}
            >
              {idDetalheRegistro && (
                <div
                  onClick={() => {
                    if (loading) return;
                    inputPlanoFundoRef.current?.click();
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    margin: 0,
                    height: '100%',
                    width: '100%',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{
                      maxWidth: '4cm',
                      maxHeight: '3cm',
                      borderRadius: 5,
                      zIndex: 2,
                      opacity: 0.2,
                    }}
                    src={urlPlanoFundo}
                    alt=""
                  />
                </div>
              )}
            </div>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{
                display: 'none',
              }}
              ref={inputPlanoFundoRef}
              onChange={handleOnSelecionarPlanoFundo}
            />

            <button
              type="button"
              style={{
                border: '0',
                borderRadius: '5px',
                color: '#fff',
                cursor: 'pointer',
                marginTop: '5px',
                padding: '6px 20px',
                height: '35px',
                marginBottom: '2px',
              }}
              className="btn-azul-escuro"
              onClick={() => {
                inputPlanoFundoRef.current?.click();
              }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                Selecionar um arquivo &#187;
              </TextoLoading>
            </button>

            <Button
              type="button"
              style={{ marginLeft: 5, marginBottom: 2, height: 35 }}
              onClick={handleExcluirPlanoFundo}
              disabled={!urlPlanoFundo || loading}
            >
              <TextoLoading loading={loading}>
                <RiDeleteBin6Line />
              </TextoLoading>
            </Button>

            <button
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 225,
                height: 35,
              }}
              className="btn-padrao btn-azul-escuro"
              onClick={handleRedefinirPlanoFundo}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <BiReset />
                <span style={{ marginLeft: 10 }}>Redefinir</span>
              </TextoLoading>
            </button>
          </div>
        </div>
      </Divisor>
    </div>
  );
};

export default LogotipoTab;
