import {
  FormatarEnum,
  PisCofinsObterVisibilidadeBloqueioCamposPorCst,
  TipoCalculoEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaPisCofins from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaPisCofins';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import Select from '../../../../../../../../../../Componentes/Select';
import NotaFiscalEntradaItemImpostoPisComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/Imposto/NotaFiscalEntradaItemImpostoPisComunicador';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';

const camposEscondidosPisPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const camposBloqueadosPisPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const PISTab: React.FC = () => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, setLoading } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();

  const [camposEscondidosPis, setCamposEscondidosPis] = useState(
    camposEscondidosPisPadrao
  );

  const [camposBloqueadosPis, setCamposBloqueadosPis] = useState(
    camposBloqueadosPisPadrao
  );

  const calcularPis = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoPisFiscalReal.carregarCreditarPisConformeDocumento'
  );

  const tipoCalculoPis = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoPisFiscalReal.tipoCalculo'
  );

  const cstPis =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoPisFiscalReal.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorPisCst = useCallback(() => {
    const retorno = PisCofinsObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularPis,
      calcularRetido: false,
      tipoCalculo: tipoCalculoPis,
      cst: cstPis,
    });

    setCamposEscondidosPis(retorno.escondido);
    setCamposBloqueadosPis(retorno.bloqueado);
  }, [calcularPis, cstPis, tipoCalculoPis]);

  const handleObterDadosItemImpostoPis = useCallback(
    async (creditarPis: boolean) => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() ?? '';
        const retorno = await NotaFiscalEntradaItemImpostoPisComunicador.show({
          id,
          params: {
            creditarPis,
            lucroReal: true,
          },
        });
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.tipoCalculo',
          retorno.tipoCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.aliquota',
          retorno.aliquota
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.baseCalculo',
          retorno.baseCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.quantidadeUnidade',
          retorno.quantidadeUnidade
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.valor',
          retorno.valor
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoPisFiscalReal.valorUnidade',
          retorno.valorUnidade
        );
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [formPrincipal, formRefDetalhe, redirecionar, setLoading]
  );

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorPisCst();
  }, [handleObterVisibilidadeBloqueioCamposPorPisCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="notaFiscalEntradaItemImpostoPisFiscalReal.id" />
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaPisCofins
              label="CST"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="notaFiscalEntradaItemImpostoPisFiscalReal.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                handleObterVisibilidadeBloqueioCamposPorPisCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosPis.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosPis.calcular}
                label="Carregar e Creditar Conforme Documento"
                name="notaFiscalEntradaItemImpostoPisFiscalReal.carregarCreditarPisConformeDocumento"
                onChange={async (event) => {
                  handleObterVisibilidadeBloqueioCamposPorPisCst();
                  if (event.target.checked) {
                    await handleObterDadosItemImpostoPis(event.target.checked);
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.tipoCalculo}>
            <Select
              disabled={camposBloqueadosPis.tipoCalculo}
              label="Tipo de Cálculo"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                handleObterVisibilidadeBloqueioCamposPorPisCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.baseCalculo}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.baseCalculo}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.aliquota}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosPis.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.quantidadeUnidade}
              label="Quantidade Unidade"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.valorUnidade}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.valorUnidade}
              label="Valor Unidade"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.valor}
              label="Valor"
              name="notaFiscalEntradaItemImpostoPisFiscalReal.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PISTab;
