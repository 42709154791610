import { BuscaCnpjCpfEnum, IContabilistaValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { BtnContainer } from '../../../../../Suprimentos/Produtos/UnidadeMedida/Detalhe/Main/styles';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCpf from '../../../../../../Componentes/Inputs/InputCpf';
import InputCep from '../../../../../../Componentes/Inputs/InputCep';
import PesquisaEnderecoCepDetalhe from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';
import IEndereco from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputAutoCompleteCidade, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputVisualSemValor from '../../../../../../Componentes/Inputs/InputVisualSemValor';
import InputEmailTag from '../../../../../../Componentes/Inputs/TagsInput/InputEmailTag';
import InputTelefone from '../../../../../../Componentes/Inputs/TagsInput/InputTelefone';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { IModalPessoaRef } from '../../../../../Comercial/Pessoas/Pessoa/Detalhe/GeralTab/ListaPessoasRepetidas';
import IPesquisaPessoaCnpjDetalheRef from '../../../../../../Componentes/Inputs/InputBuscaCnpj/PesquisaPessoaCnpjDetalhe/Interface/IPesquisaPessoaCnpjDetalheRef';
import IPessoa from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPessoa';
import InputBuscaCnpj, {
  IOnBlurInputBuscaCnpjValidoEvent,
} from '../../../../../../Componentes/Inputs/InputBuscaCnpj';
import PesquisaPessoaCnpjDetalhe from '../../../../../../Componentes/Inputs/InputBuscaCnpj/PesquisaPessoaCnpjDetalhe';
import PessoaObterPorCnpjCpfComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorCnpjCpfComunicador';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';

type IProps = IMain<IContabilistaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ContabilGeralContabilista: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const janelaCepRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const janelaCnpjRef = useRef<IPesquisaPessoaCnpjDetalheRef>(null);

  const modalPessoaRef = useRef<IModalPessoaRef>(null);

  const isCpf = formRef.current?.getFieldValue('cnpjCpfVirtual').length !== 14;

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    if (!isCpf) {
      formRef.current?.setFieldValue('contabilista', false);
    }
  });

  const handlePesquisarCnpj = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();
    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'contabilistaEndereco.cidade'
    );
    await janelaCnpjRef.current?.pesquisarCnpj({
      cnpjCpf: dados.cnpj,
      nomeRazaoSocial: dados.nome,
      cep: dados.contabilistaEndereco.cep,

      cidade: {
        id: cidade?.id || 0,
        nome: cidade?.nome,
        estado: {
          sigla: cidade?.estado.sigla,
        },
      },
      logradouro: dados.contabilistaEndereco.logradouro,
      bairro: dados.contabilistaEndereco.bairro,
      complemento: dados.contabilistaEndereco.complemento,
      numero: dados.contabilistaEndereco.numero,

      listaTelefone: dados.listaContabilistaTelefone.listaValor,
      listaEmail: dados.listaContabilistaEmail.listaValor,
    });
  }, [formRef]);

  const handleOnSelecionarPessoa = useCallback(
    (pessoa: IPessoa) => {
      formRef.current?.setFieldValue('cnpj', pessoa.cnpjCpf);
      formRef.current?.setFieldValue('nome', pessoa.nomeRazaoSocial);

      formRef.current?.setFieldValue('nomeFantasia', null);
      formRef.current?.setFieldValue('contabilistaEndereco.cep', pessoa.cep);
      formRef.current?.setFieldValue(
        'contabilistaEndereco.cidade',
        pessoa.cidade
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.logradouro',
        pessoa.logradouro
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.bairro',
        pessoa.bairro
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.complemento',
        pessoa.complemento
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.numero',
        pessoa.numero
      );
      formRef.current?.setFieldValue(
        'listaContabilistaTelefone',
        pessoa.listaTelefone
      );
      formRef.current?.setFieldValue(
        'listaContabilistaEmail',
        pessoa.listaEmail
      );
    },
    [formRef]
  );

  const handleChangeCnpj = useCallback(
    async (event: IOnBlurInputBuscaCnpjValidoEvent) => {
      try {
        if (!event.isZero && event.mudou && event.valor) {
          const response = await PessoaObterPorCnpjCpfComunicador.index({
            params: {
              cnpjCpf: event.valor,
              listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
            },
          });
          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  const handlePesquisarCep = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();

    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'contabilistaEndereco.cidade'
    );

    await janelaCepRef.current?.pesquisarCep({
      cep: dados.contabilistaEndereco.cep,
      logradouro: dados.contabilistaEndereco.logradouro,
      bairro: dados.contabilistaEndereco.bairro,
      idCidade: cidade?.id || '',
      nomeCidade: cidade?.nome || '',
      siglaEstado: cidade?.estado?.sigla || '',
    });
  }, [formRef]);

  const handleSelecionarEndereco = useCallback(
    (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'contabilistaEndereco.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.bairro',
        endereco.bairro
      );
      formRef.current?.setFieldValue(
        'contabilistaEndereco.cidade',
        endereco.cidade
      );
    },
    [formRef]
  );

  const handleChangeCidade = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'contabilistaEndereco.cidade.estado.sigla',
        event.itemAtual?.estado.sigla || ''
      );
    },
    [formRef]
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputDateSemTimeZone label="Data Inicial" name="dataInicial" />
            </Col>
            <Col lg={9} md={6} sm={12}>
              <Input
                ref={inputRefFocus}
                label="Nome do Contabilista"
                name="nome"
                placeholder="Nome do Contabilista"
                maxLength={100}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <InputCpf label="CPF" name="cpf" placeholder="CPF" />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <InputBuscaCnpj
                label="CNPJ"
                name="cnpj"
                placeholder="CNPJ"
                onBlurValido={async (event) => {
                  refresh();
                  await handleChangeCnpj(event);
                }}
                onPesquisarCnpj={handlePesquisarCnpj}
                loading={loading}
              />
              <PesquisaPessoaCnpjDetalhe
                handleOnSelecionarPessoa={handleOnSelecionarPessoa}
                tipoBusca={BuscaCnpjCpfEnum.contabilista}
                ref={janelaCnpjRef}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Input label="CRC" name="crc" placeholder="CRC" maxLength={15} />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={3} md={12}>
              <InputCep
                label="CEP"
                name="contabilistaEndereco.cep"
                placeholder="CEP"
                onPesquisarCep={handlePesquisarCep}
                loading={loading}
              />
              <PesquisaEnderecoCepDetalhe
                handleOnSelecionarEndereco={handleSelecionarEndereco}
                ref={janelaCepRef}
              />
            </Col>
            <Col lg={7} md={12}>
              <InputAutoCompleteCidade
                label="Cidade"
                name="contabilistaEndereco.idCidade"
                nomeObjeto="contabilistaEndereco.cidade"
                placeholder="Cidade"
                onChangeItemAtual={handleChangeCidade}
              />
            </Col>
            <Col lg={2} md={12}>
              <InputVisualSemValor
                name="contabilistaEndereco.cidade.estado.sigla"
                label="UF"
                disabled
                type="text"
                placeholder="UF"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={12}>
              <Input
                label="Logradouro"
                name="contabilistaEndereco.logradouro"
                placeholder="Logradouro"
                maxLength={60}
              />
            </Col>
            <Col lg={2} md={12}>
              <Input
                label="Número"
                name="contabilistaEndereco.numero"
                placeholder="Número"
                maxLength={60}
              />
            </Col>
            <Col lg={4} md={12}>
              <Input
                label="Bairro"
                name="contabilistaEndereco.bairro"
                placeholder="Bairro"
                maxLength={60}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={12}>
              <Input
                label="Complemento"
                name="contabilistaEndereco.complemento"
                placeholder="Complemento"
                maxLength={60}
              />
            </Col>
            <Col lg={6} md={12}>
              <Input
                label="Site"
                name="contabilistaEndereco.site"
                placeholder="Site"
                maxLength={60}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputTelefone
                label="Telefones"
                name="listaContabilistaTelefone"
                maxLength={15}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputEmailTag label="E-mails" name="listaContabilistaEmail" />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Input
                type="password"
                name="xIntegrationKeyContabilistaDominio"
                label="Chave de Integração com a Dominio (x-IntegrationKey)"
                placeholder="Chave de Integração"
                maxLength={50}
                maiuscula={false}
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
