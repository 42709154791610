/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { v4 } from 'uuid';
import { FormatarDataParaPtBr } from '@elogestor/util';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputPeriodoData from '../../../../../../../../Componentes/Inputs/InputPeriodoData';
import { UltimaTr } from './styles';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import Paginacao from './Paginacao';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ProdutoObterMovimentacoesComprasComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterMovimentacoesComprasComunicador';

const ComprasTab: React.FC = () => {
  const { getIdDetalheRegistro, setLoading } = UseForm();
  const formRefLista = useRef<IFormCiaHandles>(null);
  const periodoDataEmissaoRef = useRef<any>(null);

  const [valores, setValores] = useState<any | null>(null);

  const { listaValor, setListaValor, paginaAtual, limite, setTotalPaginas } =
    UseListaDraggable();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleCarregarDados = useCallback(async () => {
    try {
      setLoading(true);

      const dataEmissaoInicialCompras = formRefLista.current?.getFieldValue(
        'dataEmissaoInicialCompras'
      );
      const dataEmissaoFinalCompras = formRefLista.current?.getFieldValue(
        'dataEmissaoFinalCompras'
      );

      const response = await ProdutoObterMovimentacoesComprasComunicador.index({
        params: {
          idProduto: String(idDetalheRegistro),
          limite,
          pagina: paginaAtual,
          dataEmissaoInicialCompras,
          dataEmissaoFinalCompras,
        },
      });

      setValores(response.valores);
      setTotalPaginas(response.totalPaginas);
      setListaValor(response.listaRegistro);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [
    idDetalheRegistro,
    limite,
    paginaAtual,
    setListaValor,
    setLoading,
    setTotalPaginas,
  ]);

  useEffect(() => {
    handleCarregarDados();
  }, [paginaAtual, limite, handleCarregarDados]);

  return (
    <div>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Row>
            <Col lg={8} md={8} sm={8}>
              <InputPeriodoData
                labelDataInicial="Data Emissao Inicial Compras"
                labelDataFinal="Data Emissao Final Compras"
                nomeDataInicial="dataEmissaoInicialCompras"
                nomeDataFinal="dataEmissaoFinalCompras"
                ref={periodoDataEmissaoRef}
              />
            </Col>

            <Col
              lg={2}
              md={2}
              sm={4}
              style={{
                display: 'flex',
                alignItems: 'end',
                marginBottom: '5px',
              }}
            >
              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                }}
                type="button"
                onClick={handleCarregarDados}
              >
                <MdFilterTiltShift />
                <span style={{ marginLeft: 10 }}>Filtrar</span>
              </Button>
            </Col>
          </Row>
        </Divisor>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '5%' }}>
                <span>Número</span>
              </th>
              <th className="lista-valor" style={{ width: '5%' }}>
                <span>Série</span>
              </th>
              <th className="lista-data" style={{ width: '15%' }}>
                <span>Data Emissão</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Fornecedor</span>
              </th>
              <th className="lista-data" style={{ width: '15%' }}>
                <span>Quantidade</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Valor Unitário</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Valor Total Produtos</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Valor Total Bruto</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor &&
              listaValor.map((item) => {
                return (
                  <tr key={v4()}>
                    <td className="lista-valor">
                      {item.notaFiscalEntrada.numero}
                    </td>
                    <td className="lista-valor">
                      {item.notaFiscalEntrada.serieCodigo}
                    </td>
                    <td className="lista-data">
                      {FormatarDataParaPtBr(
                        item.notaFiscalEntrada.dataHoraEmissao
                      )}
                    </td>
                    <td className="lista-texto">{`${item.notaFiscalEntrada.notaFiscalEntradaPessoa.pessoa.codigo} - ${item.notaFiscalEntrada.notaFiscalEntradaPessoa.pessoa.nomeRazaoSocial}`}</td>
                    <td className="lista-valor">
                      {Number(item.quantidade).FormatarParaPtBr({
                        maximoCasasDecimais: 4,
                        minimoCasasDecimais: 4,
                      })}
                    </td>
                    <td className="lista-valor">
                      {Number(item.valorUnitario).FormatarParaPtBr()}
                    </td>
                    <td className="lista-valor">
                      {Number(item.valorTotalProduto).FormatarParaPtBr()}
                    </td>
                    <td className="lista-valor">
                      {Number(item.valorTotalBruto).FormatarParaPtBr()}
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tfoot>
            <UltimaTr>
              <th />
              <th />
              <th />
              <th />

              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ alignSelf: 'flex-end' }}>Qtd. Total</div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    {Number(
                      valores?.somatorioValorTotalQuantidadeCompras ?? 0
                    ).FormatarParaPtBr({
                      maximoCasasDecimais: 4,
                      minimoCasasDecimais: 4,
                    })}
                  </div>
                </div>
              </th>

              <th />

              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '13px',
                  }}
                >
                  <div style={{ alignSelf: 'flex-end' }}>
                    Val. Total Produtos
                  </div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    {Number(
                      valores?.somatorioValorTotalProdutoCompras ?? 0
                    ).FormatarParaPtBr()}
                  </div>
                </div>
              </th>

              <th>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '13px',
                  }}
                >
                  <div style={{ alignSelf: 'flex-end' }}>Val. Total Bruto</div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    {Number(
                      valores?.somatorioValorTotalBrutoCompras ?? 0
                    ).FormatarParaPtBr()}
                  </div>
                </div>
              </th>
            </UltimaTr>
          </tfoot>
        </Table>
      </Tabela>

      <Paginacao />
    </div>
  );
};

export default ComprasTab;
