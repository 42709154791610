import { ModalidadeFreteEnum, FormatarEnum } from '@elogestor/util';
import React, { useEffect } from 'react';
import Select from '../../../../../../../../Componentes/Select';
import ITipoDados from '../../../../Main/Interface/ITipoDados';
import { UseParametro } from '../../../../Main/Hooks/ParametroHook';

const SelectModalidadeFrete: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  const { permiteNulavel } = UseParametro();

  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return (
    <Select
      name={nome}
      options={FormatarEnum({
        enumObj: ModalidadeFreteEnum,
        nullavel: permiteNulavel,
      })}
    />
  );
};

export default SelectModalidadeFrete;
