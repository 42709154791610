import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/impressao';

interface IConhecimentoTransporteImpressaoComunicadorShow {
  id: string;
}

class ConhecimentoTransporteImpressaoComunicador {
  public async show(
    params: IConhecimentoTransporteImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteImpressaoComunicador();
