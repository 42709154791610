import { IPessoaContatoLista, FormatarTelefone } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Col, Row } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheContatoProps {
  name: string;
}

const PessoaContatoLista: React.FC<IListaDetalheContatoProps> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { isJanelaAutoComplete } = UseAutoComplete();

  const { ComercialPessoasPessoa: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);
  const { telaGrande } = UseReactSizeMeBodyHook();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IPessoaContatoLista[]>([]);

  const [listaValor, setListaValor] = useState<IPessoaContatoLista[]>([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPessoaContatoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [handleSalvarContinuar, isJanelaAutoComplete, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formPrincipal.formRef.current?.getData();

        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  return (
    <>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            style={{ maxWidth: telaGrande ? 250 : '' }}
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Nome</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Telefone</span>
              </th>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>E-mail</span>
              </th>
              <th className="lista-texto" style={{ width: '15%' }}>
                <span>Tipo/Direcionar E-mail</span>
              </th>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>Observação</span>
              </th>
              <th className="lista-acoes">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor.map((contato, index) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(contato.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{contato.nome}</div>
                  </td>
                  <td className="lista-texto">
                    <div>
                      {contato.listaTelefone.map((telefone) => (
                        <div key={telefone.id}>
                          {`${FormatarTelefone(
                            `${telefone.ddd}${telefone.numero}`
                          )};`}
                          <br />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>
                      {contato.listaEmail.map((email) => (
                        <div key={email.id}>
                          {`${email.email};`}
                          <br />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{contato.direcionarBoletos ? 'Boletos;' : ''}</div>
                    <div>
                      {contato.direcionarDocumentosFiscais
                        ? 'Documentos Fiscais;'
                        : ''}
                    </div>
                    <div>{contato.direcionarOrcamento ? 'Orçamento;' : ''}</div>
                    <div>
                      {contato.direcionarPedidoVenda ? 'Pedido de Venda;' : ''}
                    </div>
                    <div>
                      {contato.direcionarCotacaoCompra
                        ? 'Cotação de Compra;'
                        : ''}
                    </div>
                    <div>
                      {contato.direcionarPedidoCompra
                        ? 'Pedido de Compra;'
                        : ''}
                    </div>
                    <div>
                      {contato.direcionarOrdemServico
                        ? 'Ordem de Serviço;'
                        : ''}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{contato.observacao}</div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(contato.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </>
  );
};

export default PessoaContatoLista;
