import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/gerar-nota-fiscal-saida';

interface IFrenteCaixaPdvGerarNotaFiscalSaidaComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarNotaFiscalSaidaComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarNotaFiscalSaidaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarNotaFiscalSaidaComunicador();
