import api from '../../../../../../../Comum/Services/Api';

const rota = 'fiscal/tipo-nota-motivo-auto-complete-tag';

interface ITipoNotaMotivoAutoCompleteTagComunicadorIndex {
  params: any;
}

class TipoNotaMotivoAutoCompleteTagComunicador {
  public async index(
    params: ITipoNotaMotivoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TipoNotaMotivoAutoCompleteTagComunicador();
