"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegistroC100SituacaoDocumentoEnum;
(function (RegistroC100SituacaoDocumentoEnum) {
    RegistroC100SituacaoDocumentoEnum["documentoRegular"] = "00 - Documento Regular";
    RegistroC100SituacaoDocumentoEnum["escrituracaoExtemporaneaDocumentoRegular"] = "01 - Escritura\u00E7\u00E3o Extempor\u00E2nea de Documento Regular";
    RegistroC100SituacaoDocumentoEnum["documentoCancelado"] = "02 - Documento Cancelado";
    RegistroC100SituacaoDocumentoEnum["escrituracaoExtermporaneaDocumentoCancelado"] = "03 - Escritura\u00E7\u00E3o Extermpor\u00E2nea de Documento Cancelado";
    RegistroC100SituacaoDocumentoEnum["nfeNfceCteDenegado"] = "04 - NF-e, NFC-e ou CT-e Denegado";
    RegistroC100SituacaoDocumentoEnum["nfeNfceCteNumeracaoInutilizada"] = "05 - NF-e, NFC-e ou CT-e Numera\u00E7\u00E3o Inutilizada";
    RegistroC100SituacaoDocumentoEnum["documentoFiscalComplementar"] = "06 - Documento Fiscal Complementar";
    RegistroC100SituacaoDocumentoEnum["escrituracaoExtemporaneaDocumentoComplementar"] = "07 - Escritura\u00E7\u00E3o Extempor\u00E2nea de Documento Complementar";
    RegistroC100SituacaoDocumentoEnum["documentoFiscalEmitidoComBaseRegimeEspecialOuNormaEspecifica"] = "08 - Documento Fiscal Emitido com Base em Regime Especial ou Norma Espec\u00EDfica";
})(RegistroC100SituacaoDocumentoEnum || (RegistroC100SituacaoDocumentoEnum = {}));
exports.default = RegistroC100SituacaoDocumentoEnum;
