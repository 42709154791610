import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/conta-de-email-reenviar-comfirmacao-aws';

interface IContaEmailReenviarConfirmacaoAwsComunicadorUpdate {
  id: string;
  params: any;
}

class ContaEmailReenviarConfirmacaoAwsComunicador {
  public async update(
    params: IContaEmailReenviarConfirmacaoAwsComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ContaEmailReenviarConfirmacaoAwsComunicador();
