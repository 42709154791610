"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoConfiguracaoCREnologicoCultivarEnum;
(function (TipoConfiguracaoCREnologicoCultivarEnum) {
    TipoConfiguracaoCREnologicoCultivarEnum["bebida"] = "Bebida";
    TipoConfiguracaoCREnologicoCultivarEnum["cultivar"] = "Cultivar";
    TipoConfiguracaoCREnologicoCultivarEnum["enologico"] = "Enol\u00F3gico";
})(TipoConfiguracaoCREnologicoCultivarEnum || (TipoConfiguracaoCREnologicoCultivarEnum = {}));
exports.default = TipoConfiguracaoCREnologicoCultivarEnum;
