import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/pedido-compra/pedido-compra-resumo-por-data-emissao';

interface IPedidoCompraResumoPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoCompraResumoPorDataEmissaoRelatorioComunicador {
  public async index(
    params: IPedidoCompraResumoPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoCompraResumoPorDataEmissaoRelatorioComunicador();
