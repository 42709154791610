import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/impressao/retirada-consignado';

interface IPedidoVendaRetratoRetiradaConsignadoImpressaoComunicadorShow {
  params: {
    id?: string;
    idPedidoVendaItem?: string;
  };
}

class PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador {
  public async show(
    params: IPedidoVendaRetratoRetiradaConsignadoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, params);

    return response.data;
  }
}

export default new PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador();
