import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import CotacaoCompraComunicador from '../../../../../../Comunicador/Suprimentos/Compras/CotacaoCompra/Comunicador/CotacaoCompraComunicador';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { CotacaoCompraAtualizarSituacaoHook } from './AtualizarSitucaoHook';
import { CotacaoCompraDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { CotacaoCompraHook } from './CotacaoCompraHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { PedidoCompraImportacaoCotacaoCompraHook } from '../../../PedidoCompraImportacaoCotacaoCompraHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const CotacaoCompraHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: CotacaoCompraComunicador }}>
      <RotasHook>
        <RegimeTributarioHook>
          <CotacaoCompraDuplicarHook>
            <CotacaoCompraAtualizarSituacaoHook>
              <PedidoCompraImportacaoCotacaoCompraHook>
                <FormHook>
                  <SubHeaderHook>
                    <CotacaoCompraHook>{children}</CotacaoCompraHook>
                  </SubHeaderHook>
                </FormHook>
              </PedidoCompraImportacaoCotacaoCompraHook>
            </CotacaoCompraAtualizarSituacaoHook>
          </CotacaoCompraDuplicarHook>
        </RegimeTributarioHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default CotacaoCompraHooks;
