"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoServicoEnum;
(function (TipoServicoEnum) {
    TipoServicoEnum["normal"] = "0 - Normal";
    TipoServicoEnum["subcontratacao"] = "1 - Subcontrata\u00E7\u00E3o";
    TipoServicoEnum["redespacho"] = "2 - Redespacho";
    TipoServicoEnum["redespachoIntermediario"] = "3 - Redespacho Intermedi\u00E1rio";
})(TipoServicoEnum || (TipoServicoEnum = {}));
exports.default = TipoServicoEnum;
