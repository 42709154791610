"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var IndicadorTipoEstoqueEnum;
(function (IndicadorTipoEstoqueEnum) {
    IndicadorTipoEstoqueEnum[IndicadorTipoEstoqueEnum["propriedadeInformanteEmPoderProprio"] = 0] = "propriedadeInformanteEmPoderProprio";
    IndicadorTipoEstoqueEnum[IndicadorTipoEstoqueEnum["propriedadeInformanteEmPosseTerceiros"] = 1] = "propriedadeInformanteEmPosseTerceiros";
    IndicadorTipoEstoqueEnum[IndicadorTipoEstoqueEnum["propriedadeTerceirosEmPosseInformante"] = 2] = "propriedadeTerceirosEmPosseInformante";
})(IndicadorTipoEstoqueEnum || (IndicadorTipoEstoqueEnum = {}));
exports.default = IndicadorTipoEstoqueEnum;
