import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/tipo-nota/motivo';
const rotaNovo = 'fiscal/tributacao/tipo-nota';

interface ITipoNotaMotivoComunicadorShow {
  id: string;
}

interface ITipoNotaMotivoComunicadorStore {
  idPai: string;
  params: any;
}

interface ITipoNotaMotivoComunicadorUpdate {
  id: string;
  params: any;
}

interface ITipoNotaMotivoComunicadorDelete {
  id: string;
}

class TipoNotaMotivoComunicador {
  public async show(params: ITipoNotaMotivoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ITipoNotaMotivoComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/motivo`,
      params.params
    );

    return response.data;
  }

  public async update(params: ITipoNotaMotivoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ITipoNotaMotivoComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new TipoNotaMotivoComunicador();
