import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-lista-boleto';

interface INotaFiscalSaidaObterDadosContaParcelaBoletoComunicadorShow {
  id: string;
}

class NotaFiscalSaidaObterDadosContaParcelaBoletoComunicador {
  public async show(
    params: INotaFiscalSaidaObterDadosContaParcelaBoletoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaObterDadosContaParcelaBoletoComunicador();
