import styled from 'styled-components';

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-top: 15px;
  }
`;
