"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CabecalhoArquivo01TipoNotaEnum;
(function (CabecalhoArquivo01TipoNotaEnum) {
    CabecalhoArquivo01TipoNotaEnum["contabilidade"] = "01";
    CabecalhoArquivo01TipoNotaEnum["entradas"] = "02";
    CabecalhoArquivo01TipoNotaEnum["saidas"] = "03";
    CabecalhoArquivo01TipoNotaEnum["servicos"] = "04";
    CabecalhoArquivo01TipoNotaEnum["contabilidadeLancamentosEmLote"] = "05";
})(CabecalhoArquivo01TipoNotaEnum || (CabecalhoArquivo01TipoNotaEnum = {}));
exports.default = CabecalhoArquivo01TipoNotaEnum;
