import { IFormCiaHandles } from '@elogestor/unformcia';
import { DestinadoEnum } from '@elogestor/util';
import React from 'react';

interface IObterImpostoFiscalConformeImpostoDocumentoRetorno {
  percentualReducaoBaseCalculo: number | null;
  baseCalculo: number | null;
  aliquota: number | null;
  valor: number | null;
  destinado: DestinadoEnum | null;
}

export function ObterImpostoFiscalConformeImpostoDocumento(
  formRef: React.RefObject<IFormCiaHandles>
): IObterImpostoFiscalConformeImpostoDocumentoRetorno {
  const impostoIcmsPath = 'conhecimentoTransporteTerceiroImpostoIcms';
  const percentualReducaoBaseCalculo = formRef.current?.getFieldValue(
    `${impostoIcmsPath}.percentualReducaoBaseCalculo`
  );

  const baseCalculo = formRef.current?.getFieldValue(
    `${impostoIcmsPath}.baseCalculo`
  );

  const aliquota = formRef.current?.getFieldValue(
    `${impostoIcmsPath}.aliquota`
  );

  const valor = formRef.current?.getFieldValue(`${impostoIcmsPath}.valor`);

  const destinado = formRef.current?.getFieldValue(
    `${impostoIcmsPath}.destinado`
  );

  return {
    percentualReducaoBaseCalculo,
    baseCalculo,
    aliquota,
    valor,
    destinado,
  };
}
