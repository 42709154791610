import React from 'react';
import ItemHook from './ListaDetalheItem/Hooks';
import ListaDetalheItem from './ListaDetalheItem/Lista';

const RoteiroTab: React.FC = () => {
  return (
    <>
      <ItemHook>
        <ListaDetalheItem name="listaReprocessamentoProducaoRoteiroFabricacaoOperacao" />
      </ItemHook>
    </>
  );
};

export default RoteiroTab;
