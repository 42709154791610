import { TipoRecipienteBebidaSisdevinEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/configuracao-cr-enologico-cultivar';

interface ITipoBebidaStore {
  id?: string;
  litragem: number;
  tipoRecipiente: TipoRecipienteBebidaSisdevinEnum;
}

interface ITipoEnologicoStore {
  id?: string;
  idProdutoEnologicoSisdevin: string;
  fatorConversao: number;
}

interface ITipoCultivarStore {
  id?: string;
  idCultivarSisdevin: string;
  grau: number;
}

interface IConfiguracaCREnologicoCultivarComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IConfiguracaoCREnologicoCultivarComunicadorStore {
  tipo: string;
  params: {
    id: string;
    produtoConfiguracaoBebidaSisdevin: ITipoBebidaStore | null;
    produtoConfiguracaoCultivarSisdevin: ITipoCultivarStore | null;
    produtoConfiguracaoProdutoEnologicoSisdevin: ITipoEnologicoStore | null;
  };
}

interface IConfiguracaoCREnologicoCultivarComunicadorUpdate {
  tipo: string;
  id: string;
  params: {
    produtoConfiguracaoBebidaSisdevin: Partial<ITipoBebidaStore>;
    produtoConfiguracaoCultivarSisdevin: Partial<ITipoCultivarStore>;
    produtoConfiguracaoProdutoEnologicoSisdevin: Partial<ITipoEnologicoStore>;
  };
}

interface IConfiguracaoCREnologicoCultivarComunicadorDelete {
  id: string;
}

interface IConfiguracaoCREnologicoCultivarComunicadorShow {
  id: string;
}

class ConfiguracaoCREnologicoCultivarComunicador {
  public async index(
    params: IConfiguracaCREnologicoCultivarComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IConfiguracaoCREnologicoCultivarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async delete(
    params: IConfiguracaoCREnologicoCultivarComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }

  public async update(
    params: IConfiguracaoCREnologicoCultivarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, {
      ...params.params,
      tipo: params.tipo,
    });

    return response.data.data;
  }

  public async store(
    params: IConfiguracaoCREnologicoCultivarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, {
      ...params.params,
      tipo: params.tipo,
    });

    return response.data;
  }
}

export default new ConfiguracaoCREnologicoCultivarComunicador();
