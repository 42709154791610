import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda-importacao-orcamento/gerar';

interface IPedidoVendaImportacaoOrcamentoGerarComunicadorStore {
  params: any;
}

class PedidoVendaImportacaoOrcamentoGerarComunicador {
  public async store(
    params: IPedidoVendaImportacaoOrcamentoGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoGerarComunicador();
