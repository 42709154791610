import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/estoques/extrato-de-movimentos-com-custo';

interface IExtratoDeMovimentosComCustoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ExtratoDeMovimentosComCustoRelatorioComunicador {
  public async index(
    params: IExtratoDeMovimentosComCustoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ExtratoDeMovimentosComCustoRelatorioComunicador();
