import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/cotacao-compra/impressao/retrato-com-foto-produto';

interface ICotacaoCompraRetratoComFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class CotacaoCompraRetratoComFotoProdutoImpressaoComunicador {
  public async show(
    params: ICotacaoCompraRetratoComFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CotacaoCompraRetratoComFotoProdutoImpressaoComunicador();
