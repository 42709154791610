import {
  EstoqueIndicadorTipoEnum,
  IMovimentoManualEstoqueValoresAlterar,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import MovimentoManualEstoqueComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Comunicador/MovimentoManualEstoqueComunicador';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const isEstoqueTerceiro = useCallback((str: string) => {
    return /terceiro/i.test(str);
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IMovimentoManualEstoqueValoresAlterar
          >;
          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await MovimentoManualEstoqueComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          tipoMovimento: TipoMovimentoEntradaSaidaEnum.entrada,
          indicadorTipoEstoque: EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
          dataHora: new Date(),
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const produto = formRef.current?.getFieldValueNomeObjeto('produto');

        const schema = Yup.object().shape({
          numero: Yup.number().nullable(),
          dataHora: Yup.date()
            .nullable()
            .required('A Data e hora são obrigatórias!'),
          tipoMovimento: Yup.string().required(
            'O Tipo de Movimento é obrigatório!'
          ),
          indicadorTipoEstoque: Yup.string().required(
            'O Tipo de Estoque é obrigatório!'
          ),
          idProduto: Yup.string()
            .nullable()
            .required('O Produto é obrigatório!'),
          idPessoaEstabelecimento: Yup.mixed()
            .nullable()
            .test({
              message: 'A Pessoa (terceiro) é obrigatória!',
              test: (value: any) => {
                if (!value && isEstoqueTerceiro(data.indicadorTipoEstoque)) {
                  return false;
                }
                return true;
              },
            }),
          idLocalEstoque: Yup.string()
            .nullable()
            .required('O Local de Estoque é obrigatório!'),
          idLote: Yup.mixed()
            .nullable()
            .test({
              message: 'O Lote é obrigatório!',
              test: (value: any) => {
                if (!value && produto?.produtoEstoque?.controlarLote) {
                  return false;
                }
                return true;
              },
            }),
          quantidade: Yup.number()
            .nullable()
            .required('A Quantidade é obrigatória!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const erro = error as any;
        const errors = GetValidationErrors(erro);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [isEstoqueTerceiro]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          const valoresUpdatePermitidos = { observacoes: data?.observacoes };

          await MovimentoManualEstoqueComunicador.update({
            id,
            params: valoresUpdatePermitidos,
          });
        } else {
          const response = await MovimentoManualEstoqueComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
