const NotaFiscalSaidaItensPorClientePlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaDataHoraEmissao',
  },

  {
    titulo: 'Número do Orçamento',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaNotaFiscalSaidaItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaNotaFiscalSaidaItem.produtoDescricao',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaNotaFiscalSaidaItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaNotaFiscalSaidaItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaNotaFiscalSaidaItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaNotaFiscalSaidaItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalSaidaItem.valorTotal',
  },
];

export default NotaFiscalSaidaItensPorClientePlanilha;
