import React from 'react';
import { Popover } from 'react-bootstrap';
import IOverlayPesquisaAvancada from '../Interface/IOverlayPesquisaAvancada';

const OverlayPesquisaAvancada: React.FC<IOverlayPesquisaAvancada> = ({
  pesquisaAvancada,
}) => {
  return (
    <>
      <Popover.Header>Pesquisa Avançada</Popover.Header>
      <Popover.Body style={{ display: 'flex', flexDirection: 'column' }}>
        {pesquisaAvancada.map((valor) => {
          return (
            <span key={valor.titulo}>
              <strong>{valor.titulo}:</strong> {valor.valor}
            </span>
          );
        })}
      </Popover.Body>
    </>
  );
};

export default OverlayPesquisaAvancada;
