import {
  FormatarDataParaPtBr,
  FormatarEnum,
  MovimentacaoEntreProdutosTipoEstoqueEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagLocalEstoque from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLocalEstoque';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagLote from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLote';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';
import Select from '../../../../../../Componentes/Select';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const [
    listaIdRemoverLocalEstoqueDestino,
    setListaIdRemoverLocalEstoqueDestino,
  ] = useState<string[]>([]);

  const [
    listaIdRemoverLocalEstoqueOrigem,
    setListaIdRemoverLocalEstoqueOrigem,
  ] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  const handleChangeLocalEstoqueOrigem = useCallback(
    (listaId?: string[]) => {
      if (!listaId) return;

      setListaIdRemoverLocalEstoqueDestino(listaId);
    },
    [setListaIdRemoverLocalEstoqueDestino]
  );

  const handleChangeLocalEstoqueDestino = useCallback(
    (listaId?: string[]) => {
      if (!listaId) return;

      setListaIdRemoverLocalEstoqueOrigem(listaId);
    },
    [setListaIdRemoverLocalEstoqueOrigem]
  );

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaProdutoOrigem =
        parametros.pesquisaAvancada?.listaProdutoOrigem;
      if (listaProdutoOrigem.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos (origem)',
          valor: listaProdutoOrigem.reduce((acc: string, item: any) => {
            acc += `${item.codigoDescricao}; `;
            return acc;
          }, ''),
        });
      }

      const listaProdutoDestino =
        parametros.pesquisaAvancada?.listaProdutoDestino;
      if (listaProdutoDestino.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos (destino)',
          valor: listaProdutoDestino.reduce((acc: string, item: any) => {
            acc += `${item.codigoDescricao}; `;
            return acc;
          }, ''),
        });
      }

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const listaLocalEstoqueOrigem =
        parametros.pesquisaAvancada?.listaLocalEstoqueOrigem;
      if (listaLocalEstoqueOrigem.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Locais de Estoque (origem)',
          valor: listaLocalEstoqueOrigem.reduce((acc: string, item: any) => {
            acc += `${item.descricao}; `;
            return acc;
          }, ''),
        });
      }

      const listaLocalEstoqueDestino =
        parametros.pesquisaAvancada?.listaLocalEstoqueDestino;
      if (listaLocalEstoqueDestino.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Locais de Estoque (destino)',
          valor: listaLocalEstoqueDestino.reduce((acc: string, item: any) => {
            acc += `${item.descricao}; `;
            return acc;
          }, ''),
        });
      }

      const listaPessoa = parametros.pesquisaAvancada?.listaPessoa;
      if (listaPessoa.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pessoas (terceiros)',
          valor: listaPessoa.reduce((acc: string, item: any) => {
            acc += `${item.nomeRazaoSocialCodigo}; `;
            return acc;
          }, ''),
        });
      }

      const listaLoteOrigem = parametros.pesquisaAvancada?.listaLoteOrigem;
      if (listaLoteOrigem.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Lotes (origem)',
          valor: listaLoteOrigem.reduce((acc: string, item: any) => {
            acc += `${item.codigo}; `;
            return acc;
          }, ''),
        });
      }

      const listaLoteDestino = parametros.pesquisaAvancada?.listaLoteDestino;
      if (listaLoteDestino.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Lotes (destino)',
          valor: listaLoteDestino.reduce((acc: string, item: any) => {
            acc += `${item.codigo}; `;
            return acc;
          }, ''),
        });
      }

      const tipoDeEstoque = parametros.pesquisaAvancada?.tipoEstoque;
      if (tipoDeEstoque) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Estoque',
          valor: tipoDeEstoque,
        });
      }

      const dataInicial = parametros.pesquisaAvancada?.dataInicial;
      if (dataInicial) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data Inicial',
          valor: FormatarDataParaPtBr(dataInicial),
        });
      }

      const dataFinal = parametros.pesquisaAvancada?.dataFinal;
      if (dataFinal) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data Final',
          valor: FormatarDataParaPtBr(dataFinal),
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={4} md={12}>
                <Input name="numero" label="Número" type="number" />
              </Col>

              <Col lg={8} md={12}>
                <Select
                  name="tipoEstoque"
                  label="Tipo de Estoque"
                  options={FormatarEnum({
                    enumObj: MovimentacaoEntreProdutosTipoEstoqueEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <InputAutoCompleteTagProduto
                  name="listaProdutoOrigem"
                  label="Produtos (origem)"
                  nomeObjeto="produtoOrigem"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagLocalEstoque
                  name="listaLocalEstoqueOrigem"
                  label="Locais de Estoque (origem)"
                  nomeObjeto="localEstoqueOrigem"
                  listaIdParaRemover={listaIdRemoverLocalEstoqueOrigem}
                  onChangeListaItemAtual={(event) => {
                    handleChangeLocalEstoqueOrigem(
                      event.listaItemAtual?.map((item) => item.id)
                    );
                  }}
                />
              </Col>

              <Col lg={12}>
                <InputAutoCompleteTagLote
                  name="listaLoteOrigem"
                  label="Lotes (origem)"
                  nomeObjeto="loteOrigem"
                />
              </Col>

              <Col lg={12}>
                <InputAutoCompleteTagProduto
                  name="listaProdutoDestino"
                  label="Produtos (destino)"
                  nomeObjeto="produtoDestino"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagLocalEstoque
                  name="listaLocalEstoqueDestino"
                  label="Locais de Estoque (destino)"
                  nomeObjeto="localEstoqueDestino"
                  listaIdParaRemover={listaIdRemoverLocalEstoqueDestino}
                  onChangeListaItemAtual={(event) => {
                    handleChangeLocalEstoqueDestino(
                      event.listaItemAtual?.map((item) => item.id)
                    );
                  }}
                />
              </Col>

              <Col lg={12}>
                <InputAutoCompleteTagLote
                  name="listaLoteDestino"
                  label="Lotes (destino)"
                  nomeObjeto="loteDestino"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputAutoCompleteTagPessoa
                  name="listaPessoa"
                  label="Pessoas (terceiros)"
                  nomeObjeto="pessoa"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <InputDate name="dataInicial" label="Data Inicial" />
              </Col>
              <Col lg={6}>
                <InputDate name="dataFinal" label="Data Final" />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
