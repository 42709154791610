import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/cancelar';

interface IPedidoVendaCancelarComunicadorUpdate {
  id: string;
}

class PedidoVendaCancelarComunicador {
  public async update(
    params: IPedidoVendaCancelarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaCancelarComunicador();
