import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const RelatorioNotaFiscalSaidaTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox name="relNotaFiscalSaidaResumo" label="Resumo" />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalSaidaPorCfopCsosnCstPlanilha"
                label="Por CFOP e CSONSN/CST (Planilha)"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalSaidaTotalModeloDocumento"
                label="Totais por Modelo Documento"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalSaidaResumoPorCfop"
                label="Resumo Por CFOP"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="relNotaFiscalSaidaPorCfopCsosnCst"
                label="Por CFOP e CSOSN/CST"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RelatorioNotaFiscalSaidaTab;
