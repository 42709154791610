import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-auto-complete-tag';

interface IPessoaAutoCompleteTagComunicadorIndex {
  params: any;
}

class PessoaAutoCompleteTagComunicador {
  public async index(
    params: IPessoaAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaAutoCompleteTagComunicador();
