import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/cte-complementado';

interface IConhecimentoTransporteCteComplementadoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteCteComplementadoComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteCteComplementadoComunicador {
  public async store(
    params: IConhecimentoTransporteCteComplementadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteCteComplementadoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteCteComplementadoComunicador();
