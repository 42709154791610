import styled from 'styled-components';
import { FormCia } from '@elogestor/unformcia';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 400px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 30px;
    margin: 80px auto;

    h1 {
      font-size: 25px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
`;

export const FormCiaLogin = styled(FormCia)`
  display: flex;
  flex-direction: column;
  /* label {
    margin-top: 15px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    button {
      font-size: 15px;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  } */
  /* span {
    margin-top: 3px;
    color: red;
    font-weight: 700;
  }
  input {
    height: 35px;
    background-color: #eee;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 10px;
  } */
`;

export const Button = styled.button`
  margin-top: 15px;
  height: 35px;
  background-color: #333333;
  border: 0;
  border-radius: 5px;
  color: #fff;
`;
