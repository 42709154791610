import { DestinadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/obter-nota-fiscal-entrada-item-estoque';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicadorIndex {
  dataHoraEmissao: Date;
  idNotaFiscalEntradaItem: string;
  quantidade: number;
  idTipoNota: string;
  idTipoNotaMotivo: string;
  destinado: DestinadoEnum;
  idOperacaoFiscal: string;
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicador();
