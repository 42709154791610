import styled from 'styled-components';

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin: 0 0 0 5px;
  }
`;

export const UltimaTr = styled.tr`
  background-color: white;
  border-color: transparent;

  th,
  div {
    background-color: white;
  }
`;
