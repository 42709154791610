import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-tipo-mercado';

interface IPessoaObterTipoMercadoComunicadorShow {
  id: string;
}

class PessoaObterTipoMercadoComunicador {
  public async show(
    params: IPessoaObterTipoMercadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaObterTipoMercadoComunicador();
