import React from 'react';
import ListaDetalheNotaReferenciada from './ListaDetalheNotaReferenciada/Lista';
import Divisor from '../../../../../Componentes/Divisor';
import ConhecimentoTransporteNotaReferenciadaHook from './ListaDetalheNotaReferenciada/Hooks';

const NotaReferenciadaTab: React.FC = () => {
  return (
    <Divisor>
      <ConhecimentoTransporteNotaReferenciadaHook>
        <ListaDetalheNotaReferenciada name="listaConhecimentoTransporteNotaReferenciada" />
      </ConhecimentoTransporteNotaReferenciadaHook>
    </Divisor>
  );
};

export default NotaReferenciadaTab;
