"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarEnum;
function FormatarEnum({ enumObj, nullavel, todos, }) {
    const enumFormatado = [];
    if (nullavel)
        enumFormatado.push({ title: '', value: '' });
    if (todos)
        enumFormatado.push({ title: 'Todos', value: '' });
    for (const k of Object.keys(enumObj)) {
        let value = enumObj[k];
        if (value === 'Sim')
            value = 'true';
        else if (value === 'Não')
            value = 'false';
        enumFormatado.push({ title: enumObj[k], value });
    }
    return enumFormatado;
}
