import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  IoMdAddCircleOutline,
  IoMdRemoveCircleOutline,
} from 'react-icons/io/index.mjs';
import {
  DesestruturarChaveAcesso,
  DesestruturarCodigoBarrasProdutor,
  FormatarDataParaPtBr,
  FormatarEnum,
  IPesagemPessoaLista,
  ModeloNotaProdutorEnum,
  TipoPesagemEnum,
  ValidarChaveAcesso,
} from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompletePessoa from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Select from '../../../../../../../../Componentes/Select';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDate from '../../../../../../../../Componentes/Inputs/InputDate';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import SelecaoCadastroViticolaDetalhe from '../SelecaoCadastroViticolaDetalhe';
import ISelecaoCadastroViticola from '../SelecaoCadastroViticolaDetalhe/Interface/ISelecaoCadastroViticola';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import PessoaProdutorComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Produtor/Comunicador/PessoaProdutorComunicador';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IListaDetalhePessoa {
  name: string;
}

const ListaDetalhePessoa: React.FC<IListaDetalhePessoa> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosPesagensPesagem: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setLoading,
    setIdDetalheRegistro,
    refresh,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IPesagemPessoaLista[]>([]);
  const [listaValor, setListaValor] = useState<IPesagemPessoaLista[]>([]);

  const [
    showModalSelecaoCadastroViticola,
    setShowModalSelecaoCadastroViticola,
  ] = useState(false);

  const { abrirJanela } = UseConfirmacao();

  const tipoPesagem = formPrincipal.formRef.current?.getFieldValue('tipo');
  const percentual = formRefLista.current?.getFieldValue('percentual');
  const pesoLiquidoPesagem =
    formPrincipal.formRef.current?.getFieldValue('pesoLiquido');
  const listaPesagemPessoa =
    formPrincipal.formRef.current?.getFieldValue('listaPesagemPessoa');

  function validarChaveAcessoIndustrializacao(chaveAcesso: string): boolean {
    const tamanhoCodigoBarrasEntradaIndustrializacao =
      formRefLista.current?.getFieldValue(
        'codigoBarrasChaveAcessoEntradaIndustrializacao'
      ).length;
    formRefLista.current?.setFieldError(
      'codigoBarrasChaveAcessoEntradaIndustrializacao',
      ''
    );
    if (tamanhoCodigoBarrasEntradaIndustrializacao === 44) {
      const retornoValidarChaveAcesso = ValidarChaveAcesso(chaveAcesso);
      if (retornoValidarChaveAcesso === false) {
        formRefLista.current?.setFieldError(
          'codigoBarrasChaveAcessoEntradaIndustrializacao',
          'Chave de Acesso Invalida'
        );
        formRefLista.current?.setFieldValue(
          'modeloEntradaIndustrializacao',
          null
        );
        formRefLista.current?.clearField('serieEntradaIndustrializacao');
        formRefLista.current?.clearField('numeroEntradaIndustrializacao');
        return false;
      }
    }
    return true;
  }

  function validarChaveAcessoCompra(chaveAcesso: string): boolean {
    const tamanhoCodigoBarrasEntrada = formRefLista.current?.getFieldValue(
      'codigoBarrasChaveAcesso'
    ).length;
    formRefLista.current?.setFieldError('codigoBarrasChaveAcesso', '');
    if (tamanhoCodigoBarrasEntrada === 44) {
      const retornoValidarChaveAcesso = ValidarChaveAcesso(chaveAcesso);
      if (retornoValidarChaveAcesso === false) {
        formRefLista.current?.setFieldError(
          'codigoBarrasChaveAcesso',
          'Chave de Acesso Invalida'
        );

        formRefLista.current?.setFieldValue('modelo', null);

        formRefLista.current?.clearField('serie');
        formRefLista.current?.clearField('numero');
        return false;
      }
    }
    return true;
  }

  const handleDesestruturarChaveAcessoEntradaIndustrializacao = useCallback(
    (chaveAcesso: string): void => {
      const tamanhoCodigoBarrasEntradaIndustrializacao =
        formRefLista.current?.getFieldValue(
          'codigoBarrasChaveAcessoEntradaIndustrializacao'
        ).length;

      const serieEntradaIndustrializacao = formRefLista.current?.getFieldValue(
        'serieEntradaIndustrializacao'
      );
      const numeroEntradaIndustrializacao = formRefLista.current?.getFieldValue(
        'numeroEntradaIndustrializacao'
      );
      const modeloEntradaIndustrializacao = formRefLista.current?.getFieldValue(
        'modeloEntradaIndustrializacao'
      );

      if (
        tamanhoCodigoBarrasEntradaIndustrializacao === 44 &&
        !serieEntradaIndustrializacao &&
        !numeroEntradaIndustrializacao
      ) {
        const informacoesChaveAcesso = DesestruturarChaveAcesso({
          chaveAcesso,
        });

        formRefLista.current?.setFieldValue(
          'modeloEntradaIndustrializacao',
          ModeloNotaProdutorEnum.nfe
        );

        formRefLista.current?.setFieldValue(
          'serieEntradaIndustrializacao',
          informacoesChaveAcesso.serie
        );
        formRefLista.current?.setFieldValue(
          'numeroEntradaIndustrializacao',
          informacoesChaveAcesso.numero
        );
        if (modeloEntradaIndustrializacao === null) {
          formRefLista.current?.setFieldValue(
            'modeloEntradaIndustrializacao',
            ModeloNotaProdutorEnum.nfe
          );
        }
      }

      if (
        tamanhoCodigoBarrasEntradaIndustrializacao < 44 &&
        !serieEntradaIndustrializacao &&
        !numeroEntradaIndustrializacao
      ) {
        const informacoesChaveAcesso = DesestruturarCodigoBarrasProdutor({
          codigoBarrasNotaProdutor: chaveAcesso,
        });
        formRefLista.current?.setFieldValue(
          'serieEntradaIndustrializacao',
          informacoesChaveAcesso.serie
        );
        formRefLista.current?.setFieldValue(
          'numeroEntradaIndustrializacao',
          informacoesChaveAcesso.numero
        );
        if (modeloEntradaIndustrializacao === null) {
          formRefLista.current?.setFieldValue(
            'modeloEntradaIndustrializacao',
            ModeloNotaProdutorEnum.notaFiscalProdutor
          );
        }
      }
    },
    [formRefLista]
  );

  const handleDesestruturarChaveAcesso = useCallback(
    (chaveAcesso: string): void => {
      const tamanhoCodigoBarras = formRefLista.current?.getFieldValue(
        'codigoBarrasChaveAcesso'
      ).length;

      const serie = formRefLista.current?.getFieldValue('serie');
      const numero = formRefLista.current?.getFieldValue('numero');
      const modelo = formRefLista.current?.getFieldValue('modelo');

      if (tamanhoCodigoBarras === 44 && !serie && !numero) {
        const informacoesChaveAcesso = DesestruturarChaveAcesso({
          chaveAcesso,
        });
        formRefLista.current?.setFieldValue(
          'modeloEntradaIndustrializacao',
          ModeloNotaProdutorEnum.nfe
        );

        formRefLista.current?.setFieldValue(
          'serie',
          informacoesChaveAcesso.serie
        );
        formRefLista.current?.setFieldValue(
          'numero',
          informacoesChaveAcesso.numero
        );
        if (modelo === null) {
          formRefLista.current?.setFieldValue(
            'modelo',
            ModeloNotaProdutorEnum.nfe
          );
        }
      }

      if (tamanhoCodigoBarras < 44 && !serie && !numero) {
        const informacoesChaveAcesso = DesestruturarCodigoBarrasProdutor({
          codigoBarrasNotaProdutor: chaveAcesso,
        });

        formRefLista.current?.setFieldValue(
          'serie',
          informacoesChaveAcesso.serie
        );
        formRefLista.current?.setFieldValue(
          'numero',
          informacoesChaveAcesso.numero
        );
        if (modelo === null) {
          formRefLista.current?.setFieldValue(
            'modelo',
            ModeloNotaProdutorEnum.notaFiscalProdutor
          );
        }
      }
    },
    [formRefLista]
  );

  // #region Cadastro Viticola

  const [listaCadastroViticola, setListaCadastroViticola] = useState<
    ISelecaoCadastroViticola[]
  >([]);

  const handleCarregarCadastroViticola = useCallback(
    async (idPessoa?: string): Promise<void> => {
      try {
        setLoading(true);

        if (!idPessoa) {
          formRefLista.current?.clearField('numeroCadastroViticola');
          setLoading(false);
          return;
        }

        const response = await PessoaProdutorComunicador.index({
          idPessoa,
        });

        if (!response || response.length === 0) {
          formRefLista.current?.clearField('numeroCadastroViticola');
        } else if (response.length === 1) {
          formRefLista.current?.setFieldValue(
            'numeroCadastroViticola',
            response[0].numeroCadastroViticola
          );
        } else if (response.length > 1) {
          setListaCadastroViticola(response);
          setShowModalSelecaoCadastroViticola(true);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRefLista, setLoading]
  );

  const handleCarregarPercentualPadrao = useCallback((): void => {
    if (
      listaPesagemPessoa &&
      listaPesagemPessoa.length === 0 &&
      percentual === null
    ) {
      formRefLista.current?.setFieldValue('percentual', 100);
    }
  }, [formRefLista, listaPesagemPessoa, percentual]);

  const handleOnSelecionarCadastroViticola = useCallback(
    async (cadastroViticolaSelecionado: ISelecaoCadastroViticola) => {
      formRefLista.current?.setFieldValue(
        'numeroCadastroViticola',
        cadastroViticolaSelecionado.numeroCadastroViticola
      );
    },
    [formRefLista]
  );

  // #endregion Cadastro Viticola

  const handleAdicionar = useCallback(async () => {
    if (listaPesagemPessoa) {
      if (
        listaPesagemPessoa.length === 1 &&
        percentual < 100 &&
        listaPesagemPessoa[0].percentual === 100
      ) {
        listaPesagemPessoa[0].percentual -= percentual;
        listaPesagemPessoa[0].pesoLiquido =
          (listaPesagemPessoa[0].percentual / 100) * pesoLiquidoPesagem;
      }
      if (
        listaPesagemPessoa.length === 1 &&
        listaPesagemPessoa[0].percentual === 100 &&
        formRefLista.current?.getFieldValue('percentual') < 100
      ) {
        listaPesagemPessoa[0].percentual -= Number(
          formRefLista.current?.getFieldValue('percentual')
        );
      }
    }

    const data = formRefLista.current?.getDataDuplicar();
    data.pesoLiquido = (data.percentual / 100) * pesoLiquidoPesagem;

    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [
    formRefLista,
    handleSubmit,
    listaPesagemPessoa,
    percentual,
    pesoLiquidoPesagem,
    setIdDetalheRegistro,
  ]);

  const handleSugerirPercentual = useCallback(async () => {
    let somaPercentualPessoa = 0;
    let diferencaPercentualPessoa = 0;
    if (listaPesagemPessoa) {
      for (let index = 0; index < listaPesagemPessoa.length; index++) {
        const valor = listaPesagemPessoa[index];
        somaPercentualPessoa += valor.percentual;

        if (percentual < 100) {
          diferencaPercentualPessoa = 100 - somaPercentualPessoa;
        }
      }

      if (listaPesagemPessoa.length > 0 && somaPercentualPessoa < 100) {
        formRefLista.current?.setFieldValue(
          'percentual',
          diferencaPercentualPessoa
        );
      }
    }
  }, [formRefLista, listaPesagemPessoa, percentual]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPesagemPessoaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterInformacaoEntradaParaIndustrializacao = useCallback(
    (item: IPesagemPessoaLista): string => {
      let retorno = '';

      if (item.serieEntradaIndustrializacao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `SÉRIE: ${item.serieEntradaIndustrializacao}`;
      }
      if (item.numeroEntradaIndustrializacao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `NÚMERO: ${item.numeroEntradaIndustrializacao}`;
      }
      if (item.dataEmissaoEntradaIndustrializacao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `EMISSÃO: ${FormatarDataParaPtBr(
          item.dataEmissaoEntradaIndustrializacao
        )}`;
      }
      if (item.codigoBarrasChaveAcessoEntradaIndustrializacao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `CHAVE DE ACESSO: ${item.codigoBarrasChaveAcessoEntradaIndustrializacao}`;
      }
      if (item.modeloEntradaIndustrializacao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `MODELO: ${item.modeloEntradaIndustrializacao}`;
      }

      return retorno;
    },
    []
  );

  const handleObterInformacaoCompra = useCallback(
    (item: IPesagemPessoaLista): string => {
      let retorno = '';

      if (item.serie) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `SÉRIE: ${item.serie}`;
      }
      if (item.numero) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `NÚMERO: ${item.numero}`;
      }
      if (item.dataEmissao) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `EMISSÃO: ${FormatarDataParaPtBr(item.dataEmissao)}`;
      }
      if (item.codigoBarrasChaveAcesso) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `CHAVE DE ACESSO: ${item.codigoBarrasChaveAcesso}`;
      }
      if (item.modelo) {
        if (!retorno.IsNullOrEmpty()) retorno += `  `;
        retorno += `MODELO: ${item.modelo}`;
      }

      return retorno;
    },
    []
  );

  const handleRemoverPessoaProdutor = async (index: string): Promise<void> => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        listaPesagemPessoa.splice(Number(index), 1);
      }

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Divisor
        style={{
          marginTop: 5,
          marginBottom: 8,
          display: tipoPesagem === TipoPesagemEnum.terceiros ? 'block' : 'none',
        }}
      >
        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaTerceiroProprietario"
              label="Pessoa (Terceiro Proprietário)"
              nomeObjeto="pessoa"
              placeholder="Pessoa (Terceiro Proprietário)"
            />
          </Col>
        </Row>
      </Divisor>

      <FormCia ref={formRefLista}>
        <Divisor>
          <h6 style={{ fontWeight: 'bold' }}>Entrada para Industrializacão</h6>

          <Row>
            <Col xl={6} lg={12}>
              <Input
                name="codigoBarrasChaveAcessoEntradaIndustrializacao"
                label="Cód Barras / Chave Acesso"
                placeholder="Cód Barras / Chave Acesso"
                maxLength={44}
                onBlur={(event) => {
                  const resultadoValidarChaveAcesso =
                    validarChaveAcessoIndustrializacao(
                      event.currentTarget.value
                    );

                  if (resultadoValidarChaveAcesso)
                    handleDesestruturarChaveAcessoEntradaIndustrializacao(
                      event.currentTarget.value
                    );
                }}
                onChange={(event) => {
                  validarChaveAcessoIndustrializacao(event.currentTarget.value);
                }}
              />
            </Col>
            <Col xl={3} lg={12} md={12} sm={12}>
              <Select
                name="modeloEntradaIndustrializacao"
                label="Modelo"
                options={FormatarEnum({
                  enumObj: ModeloNotaProdutorEnum,
                  nullavel: true,
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputInteiroNulavel
                name="serieEntradaIndustrializacao"
                label="Série"
                placeholder="Série"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputInteiroNulavel
                name="numeroEntradaIndustrializacao"
                label="Número"
                placeholder="Número"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputDate
                name="dataEmissaoEntradaIndustrializacao"
                label="Data Emissão"
              />
            </Col>
          </Row>

          <h6 style={{ fontWeight: 'bold', paddingTop: 20 }}>Compra</h6>
          <Row>
            <Col xl={6} lg={12}>
              <Input
                name="codigoBarrasChaveAcesso"
                label="Cód Barras / Chave Acesso"
                placeholder="Cód Barras / Chave Acesso"
                maxLength={44}
                onBlur={(event) => {
                  const resultadoValidarChaveAcesso = validarChaveAcessoCompra(
                    event.currentTarget.value
                  );

                  if (resultadoValidarChaveAcesso)
                    handleDesestruturarChaveAcesso(event.currentTarget.value);
                }}
                onChange={(event) => {
                  validarChaveAcessoCompra(event.currentTarget.value);
                }}
              />
            </Col>
            <Col xl={3} lg={12} md={12} sm={12}>
              <Select
                name="modelo"
                label="Modelo"
                options={FormatarEnum({
                  enumObj: ModeloNotaProdutorEnum,
                  nullavel: true,
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputInteiroNulavel
                name="serie"
                label="Série"
                placeholder="Série"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputInteiroNulavel
                name="numero"
                label="Número"
                placeholder="Número"
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputDate name="dataEmissao" label="Data Emissão" />
            </Col>
          </Row>

          <h6 style={{ fontWeight: 'bold', paddingTop: 20 }}>Proprietário</h6>
          <Row>
            <Col xl={6} lg={12}>
              <InputAutoCompletePessoa
                name="idPessoa"
                label="Pessoa / Produtor"
                placeholder="Pessoa / Produtor"
                nomeObjeto="pessoa"
                onChangeItemAtual={(event) => {
                  handleCarregarCadastroViticola(event.itemAtual?.id);
                  handleCarregarPercentualPadrao();
                }}
                onBlurInput={() => {
                  handleSugerirPercentual();
                }}
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <InputDecimalNulavel
                name="percentual"
                label="Percentual"
                placeholder="Percentual"
                casasInteiras={3}
              />
            </Col>
            <Col xl={2} lg={4} md={6} sm={12}>
              <Input
                name="numeroCadastroViticola"
                label="Núm. Cad. Vitícola"
                placeholder="Núm. Cad. Vitícola"
                maxLength={11}
              />
            </Col>
            <Col
              xl={2}
              lg={4}
              md={12}
              sm={12}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <BtnPadraoButton
                type="button"
                className="btn-padrao btn-verde-claro btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '45%' }}>
                    <span>Pessoa / Produtor</span>
                  </th>
                  <th className="lista-texto" style={{ width: '15%' }}>
                    <span>Percentual</span>
                  </th>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Peso Líquido (KG)</span>
                  </th>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Num. Cad. Víticola</span>
                  </th>
                  <th className="lista-texto">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor?.map((item, index) => {
                  return (
                    <>
                      <tr
                        style={{
                          flexDirection: 'row',
                          backgroundColor: index % 2 === 0 ? '#f0f0f1' : '#fff',
                        }}
                      >
                        <td className="lista-texto">
                          {item.pessoa.nomeRazaoSocial}
                        </td>
                        <td className="lista-texto">
                          {item.percentual &&
                            Number(item.percentual).FormatarParaPtBr()}
                        </td>
                        <td className="lista-texto">
                          {item.pesoLiquido &&
                            Number(item.pesoLiquido).FormatarParaPtBr()}
                        </td>

                        <td className="lista-texto">
                          {item.numeroCadastroViticola &&
                            item.numeroCadastroViticola}
                        </td>

                        <td className="tdButton lista-valor">
                          <div>
                            <BtnAcoesButton>
                              <Dropdown.Item
                                onClick={async () => {
                                  await handleRemoverPessoaProdutor(
                                    String(index)
                                  );
                                }}
                                disabled={
                                  !permissao?.exclui ||
                                  formPrincipal.loading ||
                                  loading
                                }
                              >
                                <span style={{ display: 'flex', fontSize: 16 }}>
                                  <IoMdRemoveCircleOutline
                                    style={{ marginRight: 5 }}
                                  />
                                  Remover
                                </span>
                              </Dropdown.Item>
                            </BtnAcoesButton>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#f0f0f1' : '#fff',
                          }}
                        >
                          {(item.serieEntradaIndustrializacao ||
                            item.numeroEntradaIndustrializacao ||
                            item.dataEmissaoEntradaIndustrializacao ||
                            item.codigoBarrasChaveAcessoEntradaIndustrializacao ||
                            item.modeloEntradaIndustrializacao) && (
                            <>
                              <Row>
                                <span
                                  style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  ENTRADA PARA INDUSTRIALIZAÇÃO:
                                </span>
                              </Row>

                              <Row>
                                <pre
                                  style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingTop: 5,
                                    fontSize: 12,
                                    fontFamily: 'sans-serif',
                                  }}
                                >
                                  {handleObterInformacaoEntradaParaIndustrializacao(
                                    item
                                  )}
                                </pre>
                              </Row>
                            </>
                          )}

                          {(item.serie ||
                            item.numero ||
                            item.dataEmissao ||
                            item.codigoBarrasChaveAcesso ||
                            item.modelo) && (
                            <>
                              <Row>
                                <span
                                  style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingTop: 5,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  COMPRA:
                                </span>
                              </Row>

                              <Row>
                                <pre
                                  style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingTop: 5,
                                    fontSize: 12,
                                    fontFamily: 'sans-serif',
                                  }}
                                >
                                  {handleObterInformacaoCompra(item)}
                                </pre>
                              </Row>
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>

        {showModalSelecaoCadastroViticola && (
          <SelecaoCadastroViticolaDetalhe
            listaCadastroViticola={listaCadastroViticola}
            onSelecionarCadastroViticola={(item) => {
              handleOnSelecionarCadastroViticola(item);
            }}
            onFecharFormModal={() => {
              setShowModalSelecaoCadastroViticola(false);
            }}
          />
        )}
      </FormCia>
    </Container>
  );
};

export default ListaDetalhePessoa;
