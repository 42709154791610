"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = RateioCalculo;
function RateioCalculo(dados) {
    const vetor = [];
    let total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    if (total === 0) {
        for (let i = 0; i < dados.valores.length; i++)
            dados.valores.splice(i, 1, 1);
        total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    }
    for (let i = 0; i < dados.valores.length; i++) {
        const peso = (dados.valores[i] / total) * 100;
        const valorPercentual = dados.valor.CalcularPercentual(peso);
        vetor.push(valorPercentual.Arredondar());
    }
    const somatorio = vetor.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    let diferenca = (dados.valor - somatorio).Arredondar();
    if (diferenca > 0) {
        let j = vetor.length - 1;
        while (diferenca > 0) {
            const valor = vetor[j] + 0.01;
            vetor.splice(j, 1, valor);
            diferenca -= 0.01;
            j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    else if (diferenca < 0) {
        let j = vetor.length - 1;
        while (diferenca < 0) {
            const valor = vetor[j] - 0.01;
            vetor.splice(j, 1, valor);
            diferenca += 0.01;
            j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    return vetor;
}
