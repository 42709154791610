import React from 'react';
import { useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import EmpresaHook from './Hooks';
import AcoesJuntarProdutos from './AcoesJuntarProdutos';

const JuncaoProdutos: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <EmpresaHook>
        <SubHeader
          titulo="Junção de Produtos"
          permissao={permissoes.SuprimentosProdutosJuncaoProdutos}
          botoes={
            <AcoesJuntarProdutos
              permissao={permissoes.SuprimentosProdutosJuncaoProdutos}
            />
          }
        />
        <Detalhe>
          <Main
            idEditar={null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </EmpresaHook>
    </Container>
  );
};

export default JuncaoProdutos;
