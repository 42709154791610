const OrdemServicoVeiculoPorItensPlanilha = [
  {
    titulo: 'Ordem de Serviço',
    valor: 'listaOrdemServicoVeiculoItem.ordemServicoVeiculoNumero',
  },
  {
    titulo: 'Código do Cliente',
    valor: 'listaOrdemServicoVeiculoItem.pessoaCodigo',
  },
  {
    titulo: 'Nome do Cliente',
    valor: 'listaOrdemServicoVeiculoItem.pessoaNomeRazaoSocial',
  },
  {
    titulo: 'Placa',
    valor: 'listaOrdemServicoVeiculoItem.placa',
  },
  {
    titulo: 'KM (Veículo)',
    valor: 'listaOrdemServicoVeiculoItem.quilometragem',
  },
  {
    titulo: 'Ano',
    valor: 'listaOrdemServicoVeiculoItem.ano',
  },
  {
    titulo: 'Modelo',
    valor: 'listaOrdemServicoVeiculoItem.modelo',
  },
  {
    titulo: 'Marca',
    valor: 'listaOrdemServicoVeiculoItem.marca',
  },
  {
    titulo: 'Data Hora Emissao',
    valor: 'listaOrdemServicoVeiculoItem.ordemServicoVeiculoDataHoraEmissao',
  },
  {
    titulo: 'Codigo do Produto',
    valor: 'listaOrdemServicoVeiculoItem.produtoCodigo',
  },

  {
    titulo: 'Nome do Produto',
    valor: 'listaOrdemServicoVeiculoItem.produtoDescricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor: 'listaOrdemServicoVeiculoItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaOrdemServicoVeiculoItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaOrdemServicoVeiculoItem.valorUnitario',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaOrdemServicoVeiculoItem.valorTotal',
  },
];

export default OrdemServicoVeiculoPorItensPlanilha;
