import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/duplicar';

interface IConhecimentoTransporteTerceiroDuplicarComunicadorStore {
  idRegistroDuplicar: string;
}

class ConhecimentoTransporteTerceiroDuplicarComunicador {
  public async store({
    idRegistroDuplicar,
  }: IConhecimentoTransporteTerceiroDuplicarComunicadorStore): Promise<any> {
    const response = await api.post(`${rota}/${idRegistroDuplicar}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroDuplicarComunicador();
