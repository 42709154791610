"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ValidarCondicaoPagamento;
const String_1 = require("../String");
function ValidarCondicaoPagamento(textoCondicaoPagamento) {
    if ((0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)(textoCondicaoPagamento, 'a vista') ||
        (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)(textoCondicaoPagamento, 'avista') ||
        (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)(textoCondicaoPagamento, 'ultimo dia do ano') ||
        (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)(textoCondicaoPagamento, 'apresentacao') ||
        (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)(textoCondicaoPagamento, 'em carteira')) {
        return true;
    }
    if (textoCondicaoPagamento.includes('/')) {
        if (textoCondicaoPagamento.includes('//')) {
            return false;
        }
        const splitBarra = textoCondicaoPagamento.toLowerCase().split('/');
        if (Number.isNaN(splitBarra[0]) &&
            !(0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('a vista', splitBarra[0]) &&
            !(0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('avista', splitBarra[0]) &&
            !(0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('apresentacao', splitBarra[0]) &&
            !(0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('em carteira', splitBarra[0])) {
            return false;
        }
        if ((0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('a vista', splitBarra[0].toLowerCase()) ||
            (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('avista', splitBarra[0].toLowerCase()) ||
            (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('apresentacao', splitBarra[0].toLowerCase()) ||
            (0, String_1.CompararStringsIguaisIgnoraAcentuacaoMinusculas)('em carteira', splitBarra[0].toLowerCase())) {
            splitBarra.shift();
        }
        const splitBarraUltimoItem = splitBarra.pop();
        if (splitBarraUltimoItem) {
            splitBarraUltimoItem.toLowerCase();
            const splitBarraUltimoItemSplit = splitBarraUltimoItem.split('dias');
            splitBarraUltimoItemSplit[0] = splitBarraUltimoItemSplit[0].trim();
            if (Number.isNaN(Number(splitBarraUltimoItemSplit[0])) ||
                Number(splitBarraUltimoItemSplit[0]) <= 0) {
                return false;
            }
            if (splitBarraUltimoItemSplit[1]) {
                return false;
            }
            return !splitBarra.find((valor) => {
                return Number.isNaN(Number(valor)) || Number(valor) <= 0;
            });
        }
        if (!splitBarra[0]) {
            return false;
        }
        return !splitBarra.find((valor) => {
            return Number.isNaN(Number(valor)) || Number(valor) <= 0;
        });
    }
    if (textoCondicaoPagamento.toLowerCase().includes('parcelas') !==
        textoCondicaoPagamento.toLowerCase().includes('dias')) {
        const splitParcelas = textoCondicaoPagamento
            .toLowerCase()
            .split(/(parcelas|dias)/);
        if (splitParcelas[2]) {
            return false;
        }
        splitParcelas[0] = splitParcelas[0].trim();
        let splitGerar = splitParcelas[0]
            .split('gerar')
            .join(',')
            .split('geradas')
            .join(',')
            .split(',');
        splitGerar = splitGerar.map((split) => {
            return split.trim();
        });
        if (!/^\d+$/.test(splitGerar[0]) && !/^\d+$/.test(splitGerar[1])) {
            return false;
        }
        if (/^\d+$/.test(splitGerar[1]) && splitParcelas[2] === '') {
            return true;
        }
        if (Number(splitParcelas[0]) > 0 && splitParcelas[2] === '') {
            return true;
        }
    }
    if (textoCondicaoPagamento.toLowerCase().includes('parcelas')) {
        const splitParcelas = textoCondicaoPagamento
            .toLowerCase()
            .split('parcelas');
        splitParcelas[0] = splitParcelas[0].trim();
        let splitGerar = splitParcelas[0]
            .split('gerar')
            .join(',')
            .split('geradas')
            .join(',')
            .split(',');
        splitGerar = splitGerar.map((split) => {
            return split.trim();
        });
        if (!/^\d+$/.test(splitGerar[0]) && !/^\d+$/.test(splitGerar[1])) {
            return false;
        }
        if (Number(splitParcelas[0]) > 0 && splitParcelas[1] === '') {
            return true;
        }
        if (splitParcelas[1] !== '') {
            const splitCada = splitParcelas[1].toLowerCase().split('cada');
            if (splitCada[0].trim() !== 'a') {
                return false;
            }
            if (!splitCada[1].includes('dias')) {
                return false;
            }
            const splitDias = splitCada[1].toLowerCase().split('dias');
            splitDias[0] = splitDias[0].trim();
            if (splitDias[1]) {
                return false;
            }
            const numero = Number(splitDias[0]);
            return !Number.isNaN(numero) && numero > 0;
        }
    }
    return false;
}
