import { IOrcamentoRetratoComSemValoresItensImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrcamentoRetratoComValoresItensPDF from './OrcamentoRetratoComValoresItensImpressaoPDF';
import ImpressaoOrcamentoRetratoSemValoresItensPDF from './OrcamentoRetratoSemValoresItensImpressaoPDF';

interface IImpressaoOrcamentoRetratoComSemValoresItens {
  handleVisualizarPdf(
    visualizarComValores: boolean,
    data: IOrcamentoRetratoComSemValoresItensImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    visualizarComValores: boolean,
    dados: IOrcamentoRetratoComSemValoresItensImpressao
  ): Promise<Blob | undefined>;
}
const ImpressaoOrcamentoRetratoComSemValoresItens =
  (): IImpressaoOrcamentoRetratoComSemValoresItens => {
    async function handleVisualizarPdf(
      visualizarComValores: boolean,
      dados: IOrcamentoRetratoComSemValoresItensImpressao
    ): Promise<void> {
      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrcamentoRetratoComValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      } else {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrcamentoRetratoSemValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      }
    }

    async function handleObterBlobPdf(
      visualizarComValores: boolean,
      dados: IOrcamentoRetratoComSemValoresItensImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrcamentoRetratoComValoresItensPDF dados={dados} />
          ),
        });

        return relatorio.GetBlob();
      }

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrcamentoRetratoComValoresItensPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrcamentoRetratoComSemValoresItens();
