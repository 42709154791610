"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegimeTributarioPlanoEnum;
(function (RegimeTributarioPlanoEnum) {
    RegimeTributarioPlanoEnum["mei"] = "MEI";
    RegimeTributarioPlanoEnum["simplesNacional"] = "Simples Nacional";
    RegimeTributarioPlanoEnum["lucroPresumido"] = "Lucro Presumido";
    RegimeTributarioPlanoEnum["lucroReal"] = "Lucro Real";
})(RegimeTributarioPlanoEnum || (RegimeTributarioPlanoEnum = {}));
exports.default = RegimeTributarioPlanoEnum;
