const OrcamentoPorClientePlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaOrcamentoItem.orcamentoDataHoraEmissao',
  },

  {
    titulo: 'Número do Orçamento',
    valor: 'listaOrcamentoItem.orcamentoNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaOrcamentoItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaOrcamentoItem.produtoDescricao',
  },
  {
    titulo: 'Informações Adicionais',
    valor: 'listaOrcamentoItem.informacoesAdicionaisProduto',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaOrcamentoItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaOrcamentoItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaOrcamentoItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaOrcamentoItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaOrcamentoItem.valorTotal',
  },
];

export default OrcamentoPorClientePlanilha;
