import React, { createContext, useCallback, useContext, useState } from 'react';
import { AppErro, IOrdemDesmontagemItemLista } from '@elogestor/util';
import { produce } from 'immer';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IOrdemDesmontagemItemContext {
  validador: boolean;
  listaValor: IOrdemDesmontagemItemLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IOrdemDesmontagemItemLista[]>
  >;
  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(lista?: IOrdemDesmontagemItemLista[]): Promise<void>;
  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const OrdemDesmontagemItemContext = createContext<IOrdemDesmontagemItemContext>(
  {} as IOrdemDesmontagemItemContext
);

const ListaOrdemDesmontagemItemDraggableHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<IOrdemDesmontagemItemLista[]>(
    []
  );

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(() => {
    throw new AppErro({
      mensagem:
        'A função "handleAtualizarOrdem" não foi implementada no "ListaOrdemDesmontagemItemDraggableHook"',
    });
  }, []);

  return (
    <OrdemDesmontagemItemContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </OrdemDesmontagemItemContext.Provider>
  );
};

function UseListaOrdemDesmontagemItemDraggable(): Omit<
  IOrdemDesmontagemItemContext,
  'validador'
> {
  const context = useContext(OrdemDesmontagemItemContext);

  if (!context.validador) {
    throw new Error(
      `UseListaOrdemDesmotagemItemDraggable deve ser usado
       com um ListaOrdemDesmontagemItemDraggableHook`
    );
  }

  return context;
}

export {
  ListaOrdemDesmontagemItemDraggableHook,
  UseListaOrdemDesmontagemItemDraggable,
};
