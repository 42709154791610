"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDocumentoConhecimentoTransporteEnum;
(function (TipoDocumentoConhecimentoTransporteEnum) {
    TipoDocumentoConhecimentoTransporteEnum["declaracao"] = "0 - Declara\u00E7\u00E3o";
    TipoDocumentoConhecimentoTransporteEnum["dutoviario"] = "10 - Dutovi\u00E1rio";
    TipoDocumentoConhecimentoTransporteEnum["cteSat"] = "59 - CF-e SAT";
    TipoDocumentoConhecimentoTransporteEnum["nfce"] = "65 - NFC-e";
    TipoDocumentoConhecimentoTransporteEnum["outros"] = "99 - Outros";
})(TipoDocumentoConhecimentoTransporteEnum || (TipoDocumentoConhecimentoTransporteEnum = {}));
exports.default = TipoDocumentoConhecimentoTransporteEnum;
