import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/contas-receber-pagar/contas-receber-pagar-por-liquidacao-contabil-juros';

interface IContasReceberPagarPorLiquidacaoContabilComJurosRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ContasReceberPagarPorLiquidacaoContabilComJurosComunicador {
  public async index(
    params: IContasReceberPagarPorLiquidacaoContabilComJurosRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ContasReceberPagarPorLiquidacaoContabilComJurosComunicador();
