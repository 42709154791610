import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro';

interface IImportacaoXmlTerceiroComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoDocumento?: string;
  };
}

class ImportacaoXmlTerceiroComunicador {
  public async index(
    params: IImportacaoXmlTerceiroComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroComunicador();
