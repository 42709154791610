import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';

interface IPesquisaAvancada {
  permitirAtualizar?: () => Promise<boolean>;
}

const PesquisaAvancada: React.FC<IPesquisaAvancada> = ({
  permitirAtualizar,
}) => {
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();
  const periodoDataEmissaoRef = useRef<any>(null);
  const periodoDataVencimentoRef = useRef<any>(null);

  const formRef = useRef<IFormCiaHandles>(null);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handleSubmit = useCallback(async () => {
    if (await handlePermitirAtualizar()) {
      const data = formRef.current?.getDataPesquisa();
      setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

      fecharPesquisaAvancada();
    }
  }, [fecharPesquisaAvancada, handlePermitirAtualizar, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const numeroDocumento = parametros.pesquisaAvancada?.numeroDocumento;
      if (numeroDocumento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número do Documento',
          valor: numeroDocumento,
        });
      }

      const listaCliente = parametros.pesquisaAvancada?.listaCliente;
      if (listaCliente.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Clientes',
          valor: listaCliente.reduce((acc: string, item: any) => {
            acc += `${item.nomeRazaoSocial}; `;
            return acc;
          }, ''),
        });
      }

      const listaPortador = parametros.pesquisaAvancada?.listaPortador;
      if (listaPortador.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Portadores',
          valor: listaPortador.reduce((acc: string, item: any) => {
            acc += `${item.descricao}; `;
            return acc;
          }, ''),
        });
      }

      if (periodoDataEmissaoRef.current) {
        const periodoDataEmissao = periodoDataEmissaoRef.current.getPeriodo();
        if (periodoDataEmissao) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Emissão',
            valor: periodoDataEmissao,
          });
        }
      }

      if (periodoDataVencimentoRef.current) {
        const periodoDataVencimento =
          periodoDataVencimentoRef.current.getPeriodo();
        if (periodoDataVencimento) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Vencimento',
            valor: periodoDataVencimento,
          });
        }
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <Divisor>
            <Row>
              <Col lg={5} md={12}>
                <Input
                  name="numeroDocumento"
                  label="Número Documento"
                  placeholder="Número Documento"
                />
              </Col>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagCliente
                  label="Clientes"
                  placeholder="Clientes"
                  name="listaCliente"
                  nomeObjeto="pessoa"
                />
              </Col>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagPortador
                  label="Portadores"
                  placeholder="Portadores"
                  name="listaPortador"
                  nomeObjeto="portador"
                />
              </Col>

              <Col lg={6}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Vencimento Inicial"
                  labelDataFinal="Data Vencimento Final"
                  nomeDataInicial="dataVencimentoInicial"
                  nomeDataFinal="dataVencimentoFinal"
                  ref={periodoDataVencimentoRef}
                />
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
