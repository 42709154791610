import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/cancelar';

interface IPedidoCompraCancelarComunicadorUpdate {
  id: string;
}

class PedidoCompraCancelarComunicador {
  public async update(
    params: IPedidoCompraCancelarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoCompraCancelarComunicador();
