import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraParaPtBr,
  IReprocessamentoProducaoLocalEstoqueOrigemLista,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompleteLote from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheLocalEstoqueOrigem: React.FC<IListaDetalheGeral> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoReprocessamentoProducao: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IReprocessamentoProducaoLocalEstoqueOrigemLista[]>(
    []
  );
  const [listaValor, setListaValor] = useState<
    IReprocessamentoProducaoLocalEstoqueOrigemLista[]
  >([]);

  const [showLotes, setShowLotes] = useState(true);

  const produtoObjeto =
    formPrincipal.formRef.current?.getFieldValueNomeObjeto('produto');

  const listaReprocessamentoProducaoLocalEstoqueOrigem =
    formPrincipal.formRef.current?.getFieldValue(
      'listaReprocessamentoProducaoLocalEstoqueOrigem'
    );

  const handleAdicionar = useCallback(async () => {
    const idProduto = formPrincipal.formRef.current?.getFieldValue('idProduto');
    if (!idProduto) {
      ToastErro('Informe o Produto a ser Produzido!');
      formPrincipal.formRef.current?.setFieldError(
        'idProduto',
        'Informe o Produto a ser Produzido!'
      );

      return;
    }
    const data = formRefLista.current?.getDataDuplicar();

    if (!showLotes) {
      data.lote = null;
      data.idLote = null;
    } else {
      data.idLote = data.lote ? data.lote.id : null;
    }

    data.idLocalEstoque = data.localEstoque ? data.localEstoque.id : null;

    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [
    formPrincipal.formRef,
    formRefLista,
    handleSubmit,
    setIdDetalheRegistro,
    showLotes,
  ]);

  const handleSugerirQuantidade = useCallback(async () => {
    let somaQuantidadeLocalEstoque = 0;
    let diferencaQuantidadeLocalEstoque = 0;

    const quantidadePrincipal =
      formPrincipal.formRef.current?.getFieldValue('quantidadeRetirada');

    if (listaReprocessamentoProducaoLocalEstoqueOrigem) {
      somaQuantidadeLocalEstoque +=
        listaReprocessamentoProducaoLocalEstoqueOrigem.reduce(
          (
            acumulador: number,
            item: IReprocessamentoProducaoLocalEstoqueOrigemLista
          ) => {
            acumulador += Number(item.quantidade);
            return acumulador;
          },
          0
        );
    }
    if (quantidadePrincipal > 0) {
      diferencaQuantidadeLocalEstoque =
        quantidadePrincipal - somaQuantidadeLocalEstoque;
    }

    if (
      listaReprocessamentoProducaoLocalEstoqueOrigem.length > 0 &&
      quantidadePrincipal > 0 &&
      diferencaQuantidadeLocalEstoque > 0
    ) {
      formRefLista.current?.setFieldValue(
        'quantidade',
        diferencaQuantidadeLocalEstoque
      );
    } else if (
      listaReprocessamentoProducaoLocalEstoqueOrigem.length === 0 &&
      quantidadePrincipal > 0
    ) {
      formRefLista.current?.setFieldValue('quantidade', quantidadePrincipal);
    }

    formRefLista.current?.setFieldValue('dataHoraConsumo', new Date());
  }, [
    formPrincipal.formRef,
    formRefLista,
    listaReprocessamentoProducaoLocalEstoqueOrigem,
  ]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<
      IReprocessamentoProducaoLocalEstoqueOrigemLista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (produtoObjeto) {
      setShowLotes(produtoObjeto.produtoEstoque.controlarLote);
    } else {
      setShowLotes(true);
    }
  }, [produtoObjeto]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Row>
          <Col xl={showLotes ? 3 : 5} lg={12}>
            <InputAutoCompleteLocalEstoque
              name="idLocalEstoque"
              label="Local de Estoque"
              nomeObjeto="localEstoque"
              placeholder="Local de Estoque"
              onBlurInput={handleSugerirQuantidade}
            />
          </Col>
          <Col xl={2} lg={4} style={{ display: showLotes ? '' : 'none' }}>
            <InputAutoCompleteLote
              name="idLote"
              label="Lote"
              nomeObjeto="lote"
              placeholder="Lote"
              permitirAdicionar={false}
            />
          </Col>
          <Col xl={2} lg={4}>
            <InputDecimal
              label="Quantidade"
              placeholder="Quantidade"
              name="quantidade"
              casasDecimais={4}
              casasInteiras={15}
            />
          </Col>
          <Col xl={3} lg={4}>
            <InputDateTime
              label="Data e Hora de Consumo"
              name="dataHoraConsumo"
            />
          </Col>
          <Col
            xl={2}
            lg={4}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionar}
              className="btn-padrao btn-verde-claro btn-adicionar"
              disabled={!permissao?.inclui || formPrincipal.loading || loading}
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAdd />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th
                  className="lista-texto"
                  style={{ width: showLotes ? '40%' : '60%' }}
                >
                  <span>Local de Estoque</span>
                </th>
                {showLotes && (
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Lote</span>
                  </th>
                )}
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Quantidade</span>
                </th>
                <th className="lista-data" style={{ width: '20%' }}>
                  <span>Data Hora Consumo</span>
                </th>
                <th className="lista-texto">
                  <span>Ações</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaValor.map((item, index) => {
                return (
                  <tr key={v4()}>
                    <td className="lista-texto">
                      {item.localEstoque?.descricao}
                    </td>
                    {showLotes && (
                      <td className="lista-texto">
                        {item.lote && item.lote.codigo}
                      </td>
                    )}
                    <td className="lista-valor">
                      {Number(item.quantidade).FormatarParaPtBr({
                        maximoCasasDecimais: 4,
                        minimoCasasDecimais: 4,
                      })}
                    </td>
                    <td className="lista-data">
                      {FormatarDataHoraParaPtBr(item.dataHoraConsumo)}
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleExcluir(String(index));
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <IoMdRemoveCircleOutline
                                style={{ marginRight: 5 }}
                              />
                              Remover
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheLocalEstoqueOrigem;
