"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CodigoOcorrenciaBanrisulRetornoEnum;
(function (CodigoOcorrenciaBanrisulRetornoEnum) {
    CodigoOcorrenciaBanrisulRetornoEnum["confirmacaoEntrada"] = "02";
    CodigoOcorrenciaBanrisulRetornoEnum["entradaRejeitada"] = "03";
    CodigoOcorrenciaBanrisulRetornoEnum["baixaTituloLiquidadoEdital"] = "04";
    CodigoOcorrenciaBanrisulRetornoEnum["liquidacaoNormal"] = "06";
    CodigoOcorrenciaBanrisulRetornoEnum["liquidacaoParcial"] = "07";
    CodigoOcorrenciaBanrisulRetornoEnum["baixaPagamentoLiquidacaoSaldo"] = "08";
    CodigoOcorrenciaBanrisulRetornoEnum["devolucaoAutomatica"] = "09";
    CodigoOcorrenciaBanrisulRetornoEnum["baixadoConformeInstrucoes"] = "10";
    CodigoOcorrenciaBanrisulRetornoEnum["arquivoLevantamento"] = "11";
    CodigoOcorrenciaBanrisulRetornoEnum["concessaoAbatimento"] = "12";
    CodigoOcorrenciaBanrisulRetornoEnum["cancelamentoAbatimento"] = "13";
    CodigoOcorrenciaBanrisulRetornoEnum["vencimentoAlterado"] = "14";
    CodigoOcorrenciaBanrisulRetornoEnum["pagamentoCartorio"] = "15";
    CodigoOcorrenciaBanrisulRetornoEnum["alteracaoDados"] = "16";
    CodigoOcorrenciaBanrisulRetornoEnum["alteracaoInstrucoes"] = "18";
    CodigoOcorrenciaBanrisulRetornoEnum["confirmacaoInstrucaoProtesto"] = "19";
    CodigoOcorrenciaBanrisulRetornoEnum["confirmacaoInstrucaoParaSustarProtesto"] = "20";
    CodigoOcorrenciaBanrisulRetornoEnum["aguardandoAutorizacaoParaProtestoEdital"] = "21";
    CodigoOcorrenciaBanrisulRetornoEnum["protestoSustadoAlteracaoVencimentoPrazoCartorio"] = "22";
    CodigoOcorrenciaBanrisulRetornoEnum["confirmacaoEntradaCartorio"] = "23";
    CodigoOcorrenciaBanrisulRetornoEnum["devolucaoLiquidadoAnteriormente"] = "25";
    CodigoOcorrenciaBanrisulRetornoEnum["devolvidoPeloCartorioErroInformacao"] = "26";
    CodigoOcorrenciaBanrisulRetornoEnum["cobrancaCreditar"] = "30";
    CodigoOcorrenciaBanrisulRetornoEnum["tituloTransitoPagoCartorio"] = "31";
    CodigoOcorrenciaBanrisulRetornoEnum["reembolsoTransferenciaDescontoVendorCarteiraGarantia"] = "32";
    CodigoOcorrenciaBanrisulRetornoEnum["reembolsoDevolucaoDescontoVendor"] = "33";
    CodigoOcorrenciaBanrisulRetornoEnum["reembolsoNaoEfetuadoFaltaSaldo"] = "34";
    CodigoOcorrenciaBanrisulRetornoEnum["baixaTituloProtestado"] = "40";
    CodigoOcorrenciaBanrisulRetornoEnum["despesaAponte"] = "41";
    CodigoOcorrenciaBanrisulRetornoEnum["alteracaoTitulo"] = "42";
    CodigoOcorrenciaBanrisulRetornoEnum["relacaoTitulo"] = "43";
    CodigoOcorrenciaBanrisulRetornoEnum["manutencaoMensal"] = "44";
    CodigoOcorrenciaBanrisulRetornoEnum["sustacaoCartorioEnvioTituloCartorio"] = "45";
    CodigoOcorrenciaBanrisulRetornoEnum["fornecimentoFormularioPreimpresso"] = "46";
    CodigoOcorrenciaBanrisulRetornoEnum["confirmacaoEntradaPagadorDDA"] = "47";
    CodigoOcorrenciaBanrisulRetornoEnum["acertoDadosRaterioCredito"] = "68";
    CodigoOcorrenciaBanrisulRetornoEnum["cancelamentoDadosRateio"] = "69";
})(CodigoOcorrenciaBanrisulRetornoEnum || (CodigoOcorrenciaBanrisulRetornoEnum = {}));
exports.default = CodigoOcorrenciaBanrisulRetornoEnum;
