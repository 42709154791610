import React, { useCallback, useState } from 'react';
import {
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaTabelaPrecoLista,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaTabelaPrecoComunicador from '../../../../../Comunicador/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Comunicador/RegraEscolhaTabelaPrecoComunicador';
import RegraEscolhaTabelaPrecoLogComunicador from '../../../../../Comunicador/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Log/Comunicador/RegraEscolhaTabelaPrecoLogComunicador';
import RegraEscolhaTabelaPrecoOrdemComunicador from '../../../../../Comunicador/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Comunicador/RegraEscolhaTabelaPrecoOrdemComunicador';

const RegraEscolhaTabelaPrecoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaTabelaPrecoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IRegraEscolhaTabelaPrecoLista) => {
            return {
              id: data.id,
              quantidadeCriterios: (
                <div className="lista-valor">{data.quantidadeCriterios}</div>
              ),
              descricao: <div className="lista-texto">{data.descricao}</div>,

              'listaRegraEscolhaTabelaPrecoPessoa.pessoa.codigoNomeRazaoSocial':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaTabelaPrecoPessoa
                      ? data.listaRegraEscolhaTabelaPrecoPessoa.map((valor) => {
                          return (
                            <div key={valor.pessoa.codigo}>
                              {`${valor.pessoa.codigo} - ${valor.pessoa.nomeRazaoSocial};`}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                ),
              'listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao.ramoAtividadeProfissao.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao
                      ? data.listaRegraEscolhaTabelaPrecoRamoAtividadeProfissao.map(
                          (valor) => {
                            return (
                              <div key={valor.ramoAtividadeProfissao.descricao}>
                                {`${valor.ramoAtividadeProfissao.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),
              'listaRegraEscolhaTabelaPrecoEstado.estado.sigla': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaTabelaPrecoEstado
                    ? data.listaRegraEscolhaTabelaPrecoEstado.map((valor) => {
                        return (
                          <div key={valor.estado.sigla}>
                            {`${valor.estado.sigla};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),
              contribuinte:
                data.contribuinte != null ? (
                  <div
                    className="lista-booleano"
                    style={{ color: data.contribuinte ? 'green' : 'red' }}
                  >
                    {data.contribuinte ? 'Sim' : 'Não'}
                  </div>
                ) : (
                  ''
                ),

              'listaRegraEscolhaTabelaPrecoDestinado.destinado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaTabelaPrecoDestinado
                    ? data.listaRegraEscolhaTabelaPrecoDestinado.map(
                        (valor) => {
                          return (
                            <div key={valor.destinado}>
                              {`${valor.destinado};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),
              'listaRegraEscolhaTabelaPrecoPessoaRepresentante.pessoa.codigoNomeRazaoSocial':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaTabelaPrecoPessoaRepresentante
                      ? data.listaRegraEscolhaTabelaPrecoPessoaRepresentante.map(
                          (valor) => {
                            return (
                              <div key={valor.pessoa.codigo}>
                                {`${valor.pessoa.codigo} - ${valor.pessoa.nomeRazaoSocial};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),
              'listaRegraEscolhaTabelaPrecoGrupoProdutoServico.grupoProdutoServico.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaTabelaPrecoGrupoProdutoServico
                      ? data.listaRegraEscolhaTabelaPrecoGrupoProdutoServico.map(
                          (valor) => {
                            return (
                              <div key={valor.grupoProdutoServico.descricao}>
                                {`${valor.grupoProdutoServico.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),
              'listaRegraEscolhaTabelaPrecoProduto.produto.codigoDescricao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaTabelaPrecoProduto
                    ? data.listaRegraEscolhaTabelaPrecoProduto.map((valor) => {
                        return (
                          <div key={valor.produto.codigo}>
                            {`${valor.produto.codigo} - ${valor.produto.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),
              casasDecimais: (
                <div className="lista-valor">{data.casasDecimais}</div>
              ),

              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaTabelaPrecoEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaTabelaPrecoEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaTabelaPrecoEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaTabelaPrecoComunicador.delete({
                          id: String(data.id),
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaTabelaPrecoLogComunicador.show({
                            id: String(data.id),
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.ComercialTabelaPrecoRegraEscolhaTabelaPreco
                        ?.exclui && !data.vinculadaAoCadastroProduto
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.ComercialTabelaPrecoRegraEscolhaTabelaPreco]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialRegraEscolhaTabelaPreco' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaTabelaPrecoOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha da Tabela de Preço"
          permissao={permissoes.ComercialTabelaPrecoRegraEscolhaTabelaPreco}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaTabelaPrecoLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaTabelaPrecoLista;
