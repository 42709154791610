import {
  FormatarEnum,
  IpiObterVisibilidadeBloqueioCamposPorCst,
  TipoCalculoEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIpi from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIpi';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import Select from '../../../../../../../../../../Componentes/Select';
import NotaFiscalEntradaItemImpostoIpiComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/Imposto/NotaFiscalEntradaItemImpostoIpiComunicador';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';

const camposEscondidosIpiPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const camposBloqueadosIpiPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,
};

const IPITab: React.FC = () => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, setLoading } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();

  const [camposEscondidosIpi, setCamposEscondidosIpi] = useState(
    camposEscondidosIpiPadrao
  );

  const [camposBloqueadosIpi, setCamposBloqueadosIpi] = useState(
    camposBloqueadosIpiPadrao
  );

  const calcularIpi = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIpiFiscalReal.carregarCreditarIpiConformeDocumento'
  );

  const tipoCalculoIpi = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIpiFiscalReal.tipoCalculo'
  );

  const cstIpi =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoIpiFiscalReal.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorIpiCst = useCallback(() => {
    const retorno = IpiObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularIpi,
      tipoCalculo: tipoCalculoIpi,
      cst: cstIpi,
    });

    setCamposEscondidosIpi(retorno.escondido);
    setCamposBloqueadosIpi(retorno.bloqueado);
  }, [calcularIpi, cstIpi, tipoCalculoIpi]);

  const handleObterDadosItemImpostoIpi = useCallback(
    async (creditarIpi: boolean) => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() ?? '';
        const retorno = await NotaFiscalEntradaItemImpostoIpiComunicador.show({
          id,
          params: {
            creditarIpi,
          },
        });

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.tipoCalculo',
          retorno.tipoCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.aliquota',
          retorno.aliquota
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.baseCalculo',
          retorno.baseCalculo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.quantidadeUnidade',
          retorno.quantidadeUnidade
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.valor',
          retorno.valor
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIpiFiscalReal.valorUnidade',
          retorno.valorUnidade
        );

        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [formPrincipal, formRefDetalhe, redirecionar, setLoading]
  );

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorIpiCst();
  }, [handleObterVisibilidadeBloqueioCamposPorIpiCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="notaFiscalEntradaItemImpostoIpiFiscalReal.id" />
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaIpi
              label="CST"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="notaFiscalEntradaItemImpostoIpiFiscalReal.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                handleObterVisibilidadeBloqueioCamposPorIpiCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIpi.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIpi.calcular}
                label="Carregar e Creditar Conforme Documento"
                name="notaFiscalEntradaItemImpostoIpiFiscalReal.carregarCreditarIpiConformeDocumento"
                onChange={async (event) => {
                  handleObterVisibilidadeBloqueioCamposPorIpiCst();
                  if (event.target.checked) {
                    await handleObterDadosItemImpostoIpi(event.target.checked);
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.tipoCalculo}>
            <Select
              disabled={camposBloqueadosIpi.tipoCalculo}
              label="Tipo de Cálculo"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                handleObterVisibilidadeBloqueioCamposPorIpiCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.baseCalculo}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.baseCalculo}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.aliquota}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIpi.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.quantidadeUnidade}
              label="Quantidade Unidade"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.valorUnidade}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.valorUnidade}
              label="Valor Unidade"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.valor}
              label="Valor"
              name="notaFiscalEntradaItemImpostoIpiFiscalReal.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default IPITab;
