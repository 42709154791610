import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .autocomplete-menu {
    border: 0;
    border-color: none;
    box-shadow: none;
    background: #5d6166;
    color: #fff;
    width: 90%;
    max-width: 300px;
    &::placeholder {
      color: #ffffff;
    }
  }
`;
