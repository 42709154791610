import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-nota-fiscal-saida/curva-abc-nota-fiscal-saida-estado-por-produto-valor';

interface ICurvaABCNotaFiscalSaidaEstadoPorProdutoValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalSaidaEstadoPorProdutoValorRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalSaidaEstadoPorProdutoValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalSaidaEstadoPorProdutoValorRelatorioComunicador();
