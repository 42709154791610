"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var LayoutCobrancaEnum;
(function (LayoutCobrancaEnum) {
    LayoutCobrancaEnum["cnab400"] = "CNAB 400";
    LayoutCobrancaEnum["cnab240"] = "CNAB 240";
    LayoutCobrancaEnum["api"] = "API";
})(LayoutCobrancaEnum || (LayoutCobrancaEnum = {}));
exports.default = LayoutCobrancaEnum;
