import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/notas-emitidas/upload-pdf';

interface IImportacaoXmlEmitidosUploadPdfComunicadorUpdate {
  params: any;
}

class ImportacaoXmlEmitidosUploadPdfComunicador {
  public async update(
    params: IImportacaoXmlEmitidosUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }

  // insert
  public async insert(
    params: IImportacaoXmlEmitidosUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlEmitidosUploadPdfComunicador();
