import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  FormatarEnum,
  ICurvaABCPedidoVendaFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputAutoCompleteTagEstado from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import InputAutoCompleteTagSituacaoItemEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoItemEnum';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import Input from '../../../../../Componentes/Inputs/Input';
import Select from '../../../../../Componentes/Select';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import InputAutoCompletePadronizacaoDescricaoAtributo from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompletePadronizacaoDescricaoAtributo';
import InputAutoCompleteTagColecao from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagColecao';

interface IVendasCurvaAbcPedidoVendaRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const VendasCurvaABCPedidoVenda: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraEmissaoRef = useRef<IInputPeriodoDataHoraRef>(null);
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const inputRefFocus = useRef<any>(null);

  const opcaoRelatorioSelecionada =
    formRef.current?.getFieldValue('opcaoRelatorio');

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          idCampoAtributoParaAgrupar: Yup.mixed()
            .nullable({
              message: 'Selecione o campo para agrupar',
              test: () => {
                return (
                  data.opcaoRelatorio ===
                    'CurvaABCPedidoVendaAtributoProdutoPorQuantidade' ||
                  data.opcaoRelatorio ===
                    'CurvaABCPedidoVendaAtributoProdutoPorValor'
                );
              },
            })
            .test({
              message: 'Campo Atributo para Agrupar é necessário',
              test: () => {
                if (
                  data.opcaoRelatorio ===
                    'CurvaABCPedidoVendaAtributoProdutoPorQuantidade' ||
                  data.opcaoRelatorio ===
                    'CurvaABCPedidoVendaAtributoProdutoPorValor'
                ) {
                  if (!data.idCampoAtributoParaAgrupar) {
                    return false;
                  }
                }

                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data inicial',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora final',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const campoAtributoParaAgrupar = formRef.current?.getFieldValueNomeObjeto(
        'campoAtributoParaAgrupar'
      );

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IVendasCurvaAbcPedidoVendaRelatorioDados;

      const dadosFinal = {
        campoAtributoParaAgrupar,
        ...dados,
        opcaoRelatorio,
      };

      const pesquisaValida = await handleValidarDataHora(dadosFinal);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dadosFinal as ICurvaABCPedidoVendaFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      await TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const campoAtributoParaAgrupar = formRef.current?.getFieldValueNomeObjeto(
        'campoAtributoParaAgrupar'
      );

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IVendasCurvaAbcPedidoVendaRelatorioDados;

      const dadosFinal = {
        campoAtributoParaAgrupar,
        ...dados,
        opcaoRelatorio,
      };

      const pesquisaValida = await handleValidarDataHora(dadosFinal);

      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dadosFinal as ICurvaABCPedidoVendaFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      await TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const campoAtributoParaAgrupar = formRef.current?.getFieldValueNomeObjeto(
        'campoAtributoParaAgrupar'
      );

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IVendasCurvaAbcPedidoVendaRelatorioDados;

      const dadosFinal = {
        campoAtributoParaAgrupar,
        ...dados,
        opcaoRelatorio,
      };

      const pesquisaValida = await handleValidarDataHora(dadosFinal);

      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dadosFinal as ICurvaABCPedidoVendaFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      await TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      opcaoRelatorio: 'CurvaABCPedidoVendaClientePorValor',
      dataEmissaoInicial: dataInicial,
      dataEmissaoFinal: dataFinal,
    });
  }, [formRef]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Curva ABC de Pedidos
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    onChange={() => {
                      refresh();
                    }}
                    options={[
                      {
                        title: 'Cliente por Valor',
                        value: 'CurvaABCPedidoVendaClientePorValor',
                      },
                      {
                        title: 'Cliente por Quantidade',
                        value: 'CurvaABCPedidoVendaClientePorQuantidade',
                      },
                      {
                        title: 'Produtos por Valor',
                        value: 'CurvaABCPedidoVendaProdutoPorValor',
                      },
                      {
                        title: 'Produtos por Quantidade',
                        value: 'CurvaABCPedidoVendaProdutoPorQuantidade',
                      },
                      {
                        title: 'Estado por Valor',
                        value: 'CurvaABCPedidoVendaEstadoPorValor',
                      },
                      {
                        title: 'Estado por Quantidade',
                        value: 'CurvaABCPedidoVendaEstadoPorQuantidade',
                      },
                      {
                        title: 'Grupo por Valor',
                        value: 'CurvaABCPedidoVendaGrupoPorValor',
                      },
                      {
                        title: 'Grupo por Quantidade',
                        value: 'CurvaABCPedidoVendaGrupoPorQuantidade',
                      },
                      {
                        title: 'Grupo de Produto com Produto por Valor',
                        value:
                          'CurvaABCPedidoVendaGrupoProdutoComProdutoPorValor',
                      },
                      {
                        title: 'Grupo de Produto com Produto por Quantidade',
                        value:
                          'CurvaABCPedidoVendaGrupoProdutoComProdutoPorQuantidade',
                      },
                      {
                        title:
                          'Sub Grupo e Grupo de Produto com Produto por Valor',
                        value:
                          'CurvaABCPedidoVendaSubGrupoGrupoProdutoComProdutoPorValor',
                      },
                      {
                        title:
                          'Sub Grupo e Grupo de Produto com Produto por Quantidade',
                        value:
                          'CurvaABCPedidoVendaSubGrupoGrupoProdutoComProdutoPorQuantidade',
                      },
                      {
                        title: 'Ramo de Atividade / Profissão por Valor',
                        value:
                          'CurvaABCPedidoVendaRamoAtividadeProfissaoPorValor',
                      },
                      {
                        title: 'Ramo de Atividade / Profissão por Quantidade',
                        value:
                          'CurvaABCPedidoVendaRamoAtividadeProfissaoPorQuantidade',
                      },
                      {
                        title: 'Estado com Produto por Quantidade',
                        value: 'CurvaABCPedidoVendaEstadoPorProdutoQuantidade',
                      },
                      {
                        title: 'Estado com Produto por Valor',
                        value: 'CurvaABCPedidoVendaEstadoPorProdutoValor',
                      },
                      {
                        title: 'Atributo com Produto por Valor',
                        value: 'CurvaABCPedidoVendaAtributoProdutoPorValor',
                      },
                      {
                        title: 'Atributo com Produto por Quantidade',
                        value:
                          'CurvaABCPedidoVendaAtributoProdutoPorQuantidade',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row
                  style={{
                    marginBottom: 10,
                    display:
                      opcaoRelatorioSelecionada ===
                        'CurvaABCPedidoVendaAtributoProdutoPorQuantidade' ||
                      opcaoRelatorioSelecionada ===
                        'CurvaABCPedidoVendaAtributoProdutoPorValor'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputAutoCompletePadronizacaoDescricaoAtributo
                      ref={inputRefFocus}
                      label="Campo Atributo para Agrupar"
                      name="idCampoAtributoParaAgrupar"
                      nomeObjeto="campoAtributoParaAgrupar"
                      permitirAdicionar={false}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Emissão Inicial"
                      labelDataHoraFinal="Data Emissão Final"
                      nomeDataHoraInicial="dataEmissaoInicial"
                      nomeDataHoraFinal="dataEmissaoFinal"
                      ref={periodoDataHoraEmissaoRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSituacaoItemEnum
                      label="Situação do Item"
                      name="listaSituacaoItem"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagModeloDocumento
                      label="Modelo Documento"
                      name="listaModeloDocumento"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Clientes"
                      name="listaCliente"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagRamoAtividadeProfissao
                      label="Ramo Atividade/Profissão"
                      name="listaRamoAtividadeProfissao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEstado
                      label="Estados"
                      name="listaEstado"
                      onChangeListaItemAtual={({ listaItemAtual }) => {
                        handleChangeListaItemAtualEstado(listaItemAtual);
                      }}
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCidade
                      label="Cidades"
                      name="listaCidade"
                      listaIdEstado={listaIdEstado}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} lg={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagProduto
                      label="Produtos"
                      name="listaProduto"
                      onChangeListaItemAtual={handleChangeProduto}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={3} lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Código"
                      name="codigo"
                      placeholder="Código"
                      maxLength={60}
                    />
                  </Col>

                  <Col xl={5} lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={120}
                    />
                  </Col>

                  <Col xl={4} lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Filtro Codigo/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      label="Grupos"
                      name="listaGrupo"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagColecao
                      label="Coleções"
                      name="listaColecao"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default VendasCurvaABCPedidoVenda;
