import { FormatarEnum, TipoEmpresaSisdevinEnum } from '@elogestor/util';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputEmail from '../../../../../Componentes/Inputs/InputEmail';
import Select from '../../../../../Componentes/Select';

const SisdevinTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={12}>
            <Input
              label="Registro M.A."
              name="empresaSisdevin.registroMinisterioAgricultura"
              placeholder="Registro M.A."
              maxLength={8}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Select
              label="Tipo de Empresa"
              name="empresaSisdevin.tipoEmpresaSisdevin"
              options={FormatarEnum({
                enumObj: TipoEmpresaSisdevinEnum,
                nullavel: true,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={12}>
            <Input
              label="Diretor"
              name="empresaSisdevin.diretor"
              placeholder="Diretor"
              maxLength={30}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={12}>
            <Input
              label="Responsável Técnico"
              name="empresaSisdevin.responsavelTecnico"
              placeholder="Responsável Técnico"
              maxLength={30}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <Input
              label="CRQ/CREA"
              name="empresaSisdevin.crqCrea"
              placeholder="CRQ/CREA"
              maxLength={10}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={12}>
            <InputEmail
              label="Endereço de E-mail"
              name="empresaSisdevin.email"
              placeholder="Endereço de E-mail"
              maxLength={60}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default SisdevinTab;
