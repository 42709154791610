import React from 'react';
import Divisor from '../../../../../../Componentes/Divisor';
import ListaDetalheAvaliacao from './ListaDetalheAvaliacao/Lista';

const AvaliacaoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <ListaDetalheAvaliacao name="listaPesagemAvaliacao" />
      </Divisor>
    </div>
  );
};

export default AvaliacaoTab;
