import { FormatarEnum, TipoCalculoEnum } from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaPisCofins from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaPisCofins';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../../../../../Componentes/Select/index';
import { UseItemPisTab } from '../../../Hook/ItemPisTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';

const ItemImpostoPisTab: React.FC = () => {
  const { refresh } = UseListaDetalheForm();
  const { camposEscondidosPis, camposBloqueadosPis } = UseItemPisTab();
  const { calcularImpostosPorCst } = UseItemTab();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaPisCofins
              label="CST"
              name="notaFiscalEntradaItemImpostoPis.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="notaFiscalEntradaItemImpostoPis.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosPis.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosPis.calcular || situacaoBloquearCampos
                }
                label="Calcular PIS"
                name="notaFiscalEntradaItemImpostoPis.calcular"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.tipoCalculo}>
            <Select
              disabled={
                camposBloqueadosPis.tipoCalculo || situacaoBloquearCampos
              }
              label="Tipo de Cálculo"
              name="notaFiscalEntradaItemImpostoPis.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.baseCalculo}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.baseCalculo || situacaoBloquearCampos
              }
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoPis.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.aliquota || situacaoBloquearCampos}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoPis.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosPis.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.quantidadeUnidade || situacaoBloquearCampos
              }
              label="Quantidade Unidade"
              name="notaFiscalEntradaItemImpostoPis.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.valorUnidade}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.valorUnidade || situacaoBloquearCampos
              }
              label="Valor Unidade"
              name="notaFiscalEntradaItemImpostoPis.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosPis.valor || situacaoBloquearCampos}
              label="Valor"
              name="notaFiscalEntradaItemImpostoPis.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Retido</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosPis.calcularRetido}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosPis.calcularRetido || situacaoBloquearCampos
                }
                label="Calcular PIS Retido"
                name="notaFiscalEntradaItemImpostoPis.calcularRetido"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosPis.baseCalculoRetido}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.baseCalculoRetido || situacaoBloquearCampos
              }
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoPis.baseCalculoRetido"
              placeholder="Base de Cálculo "
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosPis.aliquotaRetido}
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.aliquotaRetido || situacaoBloquearCampos
              }
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoPis.aliquotaRetido"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosPis.valorRetido}>
            <InputDecimalNulavel
              disabled={
                camposBloqueadosPis.valorRetido || situacaoBloquearCampos
              }
              label="Valor"
              name="notaFiscalEntradaItemImpostoPis.valorRetido"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoPisTab;
