import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Col, Row, Button } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteModeloDocumento from '../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import FrenteCaixaPdvGerarPedidoVendaComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarPedidoVendaComunicador';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { UseFrenteCaixaPdvCalculo } from '../../../Hooks/FrenteCaixaPdvCalculoHook';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';

interface IGerarPreVendaDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const GerarPreVendaDetalhe: React.FC<IGerarPreVendaDetalheModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  loading,
  setLoading,
}) => {
  const { formRef: formRefPrincipal } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const formRef = useRef<IFormCiaHandles>(null);
  const parametros = UseParametros();

  const { listaItem, setIdPreVenda } = UseFrenteCaixaPdv();

  const { liberar } = UseFrenteCaixaPdvCalculo();

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      formRef.current?.setDataInicial({
        modeloDocumento: parametros.ModeloDocumentoPadraoPreVenda,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [parametros.ModeloDocumentoPadraoPreVenda, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGerarPreVenda = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRefPrincipal.current?.getData() as any;

      const idModeloDocumento =
        formRef.current?.getFieldValue('idModeloDocumento');

      const dados = {
        ...data,
        listaItem,
        idModeloDocumento,
        gerarPedidoVenda: false,
      };

      const response = await FrenteCaixaPdvGerarPedidoVendaComunicador.store({
        params: dados,
      });

      setIdPreVenda(response.id);

      ToastSucesso(response.mensagem);
      await liberar();
      if (onSalvarFormModal) onSalvarFormModal();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRefPrincipal,
    liberar,
    listaItem,
    onSalvarFormModal,
    setIdPreVenda,
    setLoading,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  return (
    <JanelaDetalhe
      titulo="Gerar Pré Venda"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={12} md={6} sm={12}>
                <InputAutoCompleteModeloDocumento
                  label="Modelo Documento"
                  name="idModeloDocumento"
                  nomeObjeto="modeloDocumento"
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleGerarPreVenda}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default GerarPreVendaDetalhe;
