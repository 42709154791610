import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IPesquisaPessoaNomeFantasiaContext {
  paginaAtual: number;
  setPaginaAtual(value: number): void;
  totalPaginas: number;
  setTotalPaginas(value: number): void;
  limite: number;
  setLimite(value: number): void;
}

const PesquisaPessoaNomeFantasiaContext =
  createContext<IPesquisaPessoaNomeFantasiaContext>(
    {} as IPesquisaPessoaNomeFantasiaContext
  );

const PesquisaPessoaNomeFantasiaProvider: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  // const handleAbrirJanela = useCallback((novaFuncao) => {
  //   if (!novaFuncao) {
  //     throw new AppErro({
  //       mensagem: `Uma função valida é necessaria! Função recebida: ${novaFuncao}`,
  //     });
  //   }

  //   setPaginaAtual(1);
  //   setBuscarDados(() => novaFuncao);
  //   setAberta(true);
  // }, []);

  // const handleFecharJanela = useCallback(() => {
  //   setBuscarDados(null);
  //   setAberta(false);
  //   setListaPessoa([]);
  // }, []);

  return (
    <PesquisaPessoaNomeFantasiaContext.Provider
      value={{
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
      }}
    >
      {children}
    </PesquisaPessoaNomeFantasiaContext.Provider>
  );
};

function UsePesquisaPessoaNomeFantasia(): Omit<
  IPesquisaPessoaNomeFantasiaContext,
  'validador'
> {
  const context = useContext(PesquisaPessoaNomeFantasiaContext);

  if (!context) {
    throw new Error(
      'UsePesquisaPessoaNomeFantasia deve ser usado com um PesquisaPessoaNomeFantasiaProvider'
    );
  }

  return context;
}

export { PesquisaPessoaNomeFantasiaProvider, UsePesquisaPessoaNomeFantasia };
