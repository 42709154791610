import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida-importacao-pedido-venda/gerar';

interface INotaFiscalSaidaImportacaoPedidoVendaGerarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoPedidoVendaGerarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoPedidoVendaGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoPedidoVendaGerarComunicador();
