const OrcamentoResumoPlanilha = [
  { titulo: 'Número', valor: 'numero' },
  { titulo: 'Data Emissão', valor: 'dataHoraEmissao' },
  { titulo: 'Cliente', valor: 'nomeRazaoSocial' },
  { titulo: 'CFOPS', valor: 'cfops' },
  { titulo: 'Valor Subtotal', valor: 'valorSubtotal' },
  { titulo: 'Valor Total', valor: 'valorTotalBruto' },
];

export default OrcamentoResumoPlanilha;
