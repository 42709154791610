/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import {
  AppErro,
  IFrenteCaixaPdvFinalizarVendaPagamentoLista,
  IsPagamentoTef,
  MeioPagamentoEnum,
  RateioComCasaDecimalCalculo,
  StatusTefEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { MdCancel } from 'react-icons/md/index.mjs';
import InputTabelaDecimal from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import InputTabelaInteiro from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaInteiro';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputTabelaAutoCompleteBandeiraCartao from '../../../../../../../../Componentes/Inputs/InputTabela/AutoComplete/Financeiro/InputTabelaAutoCompleteBandeiraCartao';
import { IInputAutoCompleteBaseRef } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import { UseParcelas } from '../Hooks/ParcelasHook';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import FrenteCaixaPdvCancelarPagamentoSiTefComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvCancelarPagamentoSiTefComunicador';
import { UseSiTef } from '../Hooks/TefHook/SiTefHook';

interface IItemPagamento {
  index: number;
  item: IFrenteCaixaPdvFinalizarVendaPagamentoLista;
  onChange: () => void;
  onClick?: () => void;
  onKeyDownValor?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlurValor: (value: number) => void;
  loading: boolean;
  setLoading(value: boolean): void;
  refresh: () => void;
}

export interface IItemPagamentoRef {
  handleObterValores(): IFrenteCaixaPdvFinalizarVendaPagamentoLista;
  handleSetFocusValor(): void;
}

const ItemPagamento: React.ForwardRefRenderFunction<
  IItemPagamentoRef,
  IItemPagamento
> = (
  {
    index,
    item,
    onChange,
    onClick,
    onKeyDownValor,
    onBlurValor,
    loading,
    setLoading,
    refresh,
  },
  ref
) => {
  const { formRef: formPrincipal } = UseForm();
  const { abrirJanela } = UseConfirmacao();
  const { setTitulo, abrirParcelas, carregarDados } = UseParcelas();
  const { continuarPagamentoSiTef, realizarCancelamentoSiTef } = UseSiTef();

  const valorRef = useRef<HTMLInputElement>(null);
  const quantidadeParcelasRef = useRef<HTMLInputElement>(null);
  let bandeiraCartaoRef = useRef<IInputAutoCompleteBaseRef>(null);

  const handleObterValores =
    useCallback((): IFrenteCaixaPdvFinalizarVendaPagamentoLista => {
      const valor = valorRef.current?.value.ConverterParaNumber();
      const quantidadeParcelas =
        quantidadeParcelasRef.current?.value.ConverterParaNumber();
      return {
        ...item,
        valor: Number(valor),
        quantidadeParcelas: Number(quantidadeParcelas),
      };
    }, [item]);

  const handleSetFocusValor = useCallback(() => {
    if (valorRef.current) {
      valorRef.current.focus();
    }
  }, []);

  const handleOnKeyDownValor = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDownValor) onKeyDownValor(event);
    },
    [onKeyDownValor]
  );

  useImperativeHandle(ref, () => {
    return {
      handleObterValores,
      handleSetFocusValor,
    };
  });

  const handleEditarItemPagamento = useCallback(async () => {
    const valoresItem = handleObterValores();

    let titulo = '';
    switch (valoresItem.formaPagamento.meioPagamento) {
      case MeioPagamentoEnum.dinheiro:
        titulo = 'Parcelas do Dinheiro';
        break;
      case MeioPagamentoEnum.cheque:
        titulo = 'Parcelas do Cheque';
        break;
      case MeioPagamentoEnum.cartaoCredito:
        titulo = 'Parcelas do Cartão Crédito';
        break;
      case MeioPagamentoEnum.cartaoDebito:
        titulo = 'Parcelas do Cartão Débito';
        break;
      case MeioPagamentoEnum.creditoLoja:
        titulo = 'Parcelas do Crédiario';
        break;
      case MeioPagamentoEnum.valeAlimentaca:
        titulo = 'Parcelas do Vale Alimentação';
        break;
      case MeioPagamentoEnum.valeRefeicao:
        titulo = 'Parcelas do Vale Refeição';
        break;
      case MeioPagamentoEnum.valePresente:
        titulo = 'Parcelas do Vale Presente';
        break;
      case MeioPagamentoEnum.valeCombustivel:
        titulo = 'Parcelas do Vale Combustível';
        break;
      case MeioPagamentoEnum.boletoBancario:
        titulo = 'Parcelas dos Boletos';
        break;
      case MeioPagamentoEnum.depositoBancario:
        titulo = 'Parcelas do Depósito Bancá  rio';
        break;
      case MeioPagamentoEnum.pagamentoInstantaneoPix:
        titulo = 'Parcelas do PIX';
        break;
      case MeioPagamentoEnum.transferenciaBancariaCarteiraDigital:
        titulo = 'Parcelas da Transferência Bancária';
        break;
      case MeioPagamentoEnum.programaFidelidadeCashbackCreditoVirtual:
        titulo = 'Parcelas do Cashback';
        break;
      case MeioPagamentoEnum.semPagamento:
        titulo = 'Parcelas Sem Pagamento';
        break;
      case MeioPagamentoEnum.outro:
        titulo = 'Parcelas do Outros';
        break;
    }

    setTitulo(titulo);
    await abrirParcelas();
    const retorno = await carregarDados(valoresItem);

    if (retorno) {
      item.quantidadeParcelas = retorno.pagamento.quantidadeParcelas;
      item.dataPrimeiraParcela = retorno.pagamento.dataPrimeiraParcela
        ? retorno.pagamento.dataPrimeiraParcela
        : retorno.pagamento.dataHoraEmissao;
      item.valorPrimeiraParcela = retorno.pagamento.valorPrimeiraParcela;
      item.condicaoPagamento = retorno.pagamento.condicaoPagamento;
      item.portador = retorno.pagamento.portador;
      item.listaParcelas = retorno.pagamento.listaParcelas;

      if (quantidadeParcelasRef.current)
        quantidadeParcelasRef.current.value = String(
          retorno.pagamento.quantidadeParcelas
        );
    }
  }, [abrirParcelas, carregarDados, handleObterValores, item, setTitulo]);

  const handleOnAlterarValor = useCallback(() => {
    if (item.valor > 0) {
      if (item.quantidadeParcelas === 0) {
        item.quantidadeParcelas = 1;
      }

      if (item.listaParcelas.length > 0) {
        const listaParcelaRateios: number[] = [];
        let { valor } = item;

        let incioParcelas = 0;
        if (item.valorPrimeiraParcela > 0) {
          valor -= item.valorPrimeiraParcela;
          incioParcelas = 1;
        }

        for (let i = incioParcelas; i < item.quantidadeParcelas; i++) {
          listaParcelaRateios.push(0);
        }

        const listaValorRateio = RateioComCasaDecimalCalculo({
          valor,
          valores: listaParcelaRateios,
          casasDecimais: 2,
        });

        if (item.valorPrimeiraParcela > 0) {
          listaValorRateio.unshift(item.valorPrimeiraParcela);
        }

        for (let i = 0; i < item.listaParcelas.length; i++) {
          item.listaParcelas[i].valor = listaValorRateio[i];
        }
      } else {
        item.listaParcelas.push({
          sequencia: item.quantidadeParcelas,
          quantidadeParcelas: item.quantidadeParcelas,
          dataVencimento: item.dataPrimeiraParcela,
          valor: item.valor,
        });
      }
    } else {
      item.quantidadeParcelas = 0;
      item.listaParcelas = [];
    }

    if (quantidadeParcelasRef.current) {
      quantidadeParcelasRef.current.value = String(item.quantidadeParcelas);
    }
    if (onChange) onChange();
  }, [item, onChange]);

  const handleOnAlterarQuantidadeParcelas = useCallback(() => {
    item.condicaoPagamento = undefined;
    item.listaParcelas = [];

    const listaParcelaRateios: number[] = [];
    let { valor } = item;

    let incioParcelas = 0;
    if (item.valorPrimeiraParcela > 0) {
      valor -= item.valorPrimeiraParcela;
      incioParcelas = 1;
    }

    for (let i = incioParcelas; i < item.quantidadeParcelas; i++) {
      listaParcelaRateios.push(0);
    }

    const listaValorRateio = RateioComCasaDecimalCalculo({
      valor,
      valores: listaParcelaRateios,
      casasDecimais: 2,
    });

    if (item.valorPrimeiraParcela > 0) {
      listaValorRateio.unshift(item.valorPrimeiraParcela);
    }

    for (let i = 0; i < item.quantidadeParcelas; i++) {
      item.listaParcelas.push({
        sequencia: i + 1,
        quantidadeParcelas: item.quantidadeParcelas,
        dataVencimento: item.dataPrimeiraParcela.AddDays(30 * i),
        valor: listaValorRateio[i],
      });
    }

    if (onChange) onChange();
  }, [item, onChange]);

  const handleContinuarItemPagamento = useCallback(
    async (cancelar: boolean) => {
      try {
        setLoading(true);

        const itemPagamento = handleObterValores();

        if (!itemPagamento.idPagamentoTef) {
          ToastErro('Pagamento não esta vinculado a um venda TEF');
          setLoading(false);
          return;
        }

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              {`Deseja realmente ${cancelar ? 'cancelar' : 'continuar'} o pagamento?`}
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (!resposta) {
          setLoading(false);
          return;
        }

        if (itemPagamento.autorizacao) {
          const dataHoraEmissao =
            formPrincipal.current?.getFieldValue('dataHoraEmissao');
          const terminalCaixa =
            formPrincipal.current?.getFieldValue('terminalCaixa');
          const usuarioAbertura =
            formPrincipal.current?.getFieldValue('usuarioAbertura');

          const responseContinuarSiTef = await continuarPagamentoSiTef({
            idTerminalCaixa: terminalCaixa.id,
            valorTotal: itemPagamento.valor,
            numeroVenda: itemPagamento.idPagamentoTef,
            dataHoraEmissao,
            operador: usuarioAbertura.nome,
            cancelar,
          });

          if (responseContinuarSiTef) {
            await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
              params: {
                idPagamentoTef: itemPagamento.idPagamentoTef,
              },
            });

            item.idPagamentoTef = undefined;
            item.idTef = undefined;
            item.situacaoPagamento = undefined;
          }
        } else {
          await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
            params: {
              idPagamentoTef: itemPagamento.idPagamentoTef,
            },
          });

          item.idPagamentoTef = undefined;
          item.idTef = undefined;
          item.situacaoPagamento = undefined;
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      abrirJanela,
      continuarPagamentoSiTef,
      formPrincipal,
      handleObterValores,
      item,
      setLoading,
    ]
  );

  const handleCancelarItemPagamento = useCallback(async () => {
    try {
      setLoading(true);

      const itemPagamento = handleObterValores();

      if (!itemPagamento.idTef && !itemPagamento.autorizacao) {
        ToastErro('Pagamento não esta vinculado a um venda TEF');
        setLoading(false);
        return;
      }

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente cancelar o pagamento?
          </span>
        ),
        confimar: 'Sim',
        cancelar: 'Não',
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      if (itemPagamento.idTef) {
        const numero = formPrincipal.current?.getFieldValue('numero');
        const dataHoraEmissao =
          formPrincipal.current?.getFieldValue('dataHoraEmissao');
        const terminalCaixa =
          formPrincipal.current?.getFieldValue('terminalCaixa');
        const usuarioAbertura =
          formPrincipal.current?.getFieldValue('usuarioAbertura');

        const responseCancelamentoSiTef = await realizarCancelamentoSiTef({
          idTerminalCaixa: terminalCaixa.id,
          meioPagamento: itemPagamento.formaPagamento.meioPagamento,
          valorTotal: itemPagamento.valor,
          numeroVenda: numero,
          dataHoraEmissao,
          operador: usuarioAbertura.nome,
          nsuHost: itemPagamento.idTef,
        });

        if (responseCancelamentoSiTef) {
          const comprovanteClienteCancelamento = responseCancelamentoSiTef.find(
            (e) => e.fieldId === 121
          );
          const comprovanteEstabelecimentoCancelamento =
            responseCancelamentoSiTef.find((e) => e.fieldId === 122);

          await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
            params: {
              idPagamentoTef: itemPagamento.idPagamentoTef,
              comprovanteClienteCancelamento:
                comprovanteClienteCancelamento?.data,
              comprovanteEstabelecimentoCancelamento:
                comprovanteEstabelecimentoCancelamento?.data,
            },
          });

          item.idPagamentoTef = undefined;
          item.idTef = undefined;
          item.situacaoPagamento = undefined;

          refresh();
        }
      } else {
        await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
          params: {
            idPagamentoTef: itemPagamento.idPagamentoTef,
          },
        });

        item.idPagamentoTef = undefined;
        item.idTef = undefined;
        item.situacaoPagamento = undefined;

        refresh();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    formPrincipal,
    handleObterValores,
    item,
    realizarCancelamentoSiTef,
    refresh,
    setLoading,
  ]);

  return (
    <tr
      key={v4()}
      style={{ backgroundColor: index % 2 === 0 ? '#edecee' : '#f9f8fa' }}
    >
      <td
        className="lista-texto"
        onClick={async () => {
          if (!item.situacaoPagamento) {
            if (onClick) onClick();
            refresh();

            await Sleep(50);

            handleOnAlterarValor();
          }
        }}
      >
        <div>
          <span>{item.formaPagamento.descricao}</span>
          {item.situacaoPagamento && (
            <span
              style={{
                color:
                  item.situacaoPagamento === StatusTefEnum.pago
                    ? 'green'
                    : item.situacaoPagamento ===
                          StatusTefEnum.cancelamentoIniciado ||
                        item.situacaoPagamento ===
                          StatusTefEnum.emCancelamento ||
                        item.situacaoPagamento === StatusTefEnum.cancelado
                      ? 'red'
                      : 'darkgoldenrod',
                fontWeight: 'bold',
              }}
            >{` (${item.situacaoPagamento})`}</span>
          )}
        </div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <div>
          <InputTabelaDecimal
            valorPadrao={item.valor}
            valueRef={valorRef}
            casasDecimais={2}
            onChange={(event) => {
              item.valor = event.currentTarget.value.ConverterParaNumber();
              handleOnAlterarValor();
            }}
            onBlur={(event) => {
              onBlurValor(
                String(event.currentTarget.value).ConverterParaNumber()
              );
            }}
            onKeyDown={handleOnKeyDownValor}
            disabled={!!item.situacaoPagamento}
          />
        </div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <div>
          <InputTabelaInteiro
            valorPadrao={item.quantidadeParcelas}
            valueRef={quantidadeParcelasRef}
            onChange={(event) => {
              item.quantidadeParcelas =
                event.currentTarget.value.ConverterParaNumber();
              refresh();
              handleOnAlterarQuantidadeParcelas();
            }}
            disabled={
              !!item.situacaoPagamento ||
              !item?.formaPagamento.permiteGerarParcelas
            }
          />
        </div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <InputTabelaAutoCompleteBandeiraCartao
          style={{
            height: 25,
            display:
              item.formaPagamento.meioPagamento ===
                MeioPagamentoEnum.cartaoCredito ||
              item.formaPagamento.meioPagamento ===
                MeioPagamentoEnum.cartaoDebito
                ? ''
                : 'none',
          }}
          obterRef={(instance) => {
            bandeiraCartaoRef = instance;

            bandeiraCartaoRef.current?.selecionarItemSemEvento(
              item.bandeiraCartao
            );
          }}
          onChangeItemAtual={(event) => {
            if (event.itemAtual) {
              item.bandeiraCartao = event.itemAtual;
            } else {
              item.bandeiraCartao = undefined;
            }
            refresh();
          }}
          disabled={!!item.situacaoPagamento}
        />
      </td>
      <td className="tdButton lista-acoes">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BtnAcoesButton>
            <Dropdown.Item
              onClick={() => {
                handleEditarItemPagamento();
              }}
              disabled={loading || item.valor === 0 || !!item.situacaoPagamento}
            >
              <span style={{ display: 'flex', fontSize: 16 }}>
                <AiOutlineEdit style={{ marginRight: 5 }} />
                Editar
              </span>
            </Dropdown.Item>

            {/* {IsPagamentoTef(item.formaPagamento.meioPagamento) && (
              <Dropdown.Item
                onClick={() => {
                  handleContinuarItemPagamento(false);
                }}
                disabled={
                  loading ||
                  item.valor === 0 ||
                  !item.situacaoPagamento ||
                  item.situacaoPagamento === StatusTefEnum.pago ||
                  item.situacaoPagamento === StatusTefEnum.cancelado
                }
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <MdRestartAlt style={{ marginRight: 5 }} />
                  Continuar Pagamento
                </span>
              </Dropdown.Item>
            )} */}

            {IsPagamentoTef(
              item.formaPagamento.meioPagamento,
              item.formaPagamento.pagamentoIntegradoTef
            ) && (
              <Dropdown.Item
                onClick={() => {
                  if (item.situacaoPagamento === StatusTefEnum.pago) {
                    handleCancelarItemPagamento();
                  } else {
                    handleContinuarItemPagamento(true);
                  }
                }}
                disabled={
                  loading ||
                  item.valor === 0 ||
                  !item.situacaoPagamento ||
                  item.situacaoPagamento === StatusTefEnum.cancelado
                }
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <MdCancel style={{ marginRight: 5 }} />
                  Cancelar Pagamento
                </span>
              </Dropdown.Item>
            )}
          </BtnAcoesButton>
        </div>
      </td>
    </tr>
  );
};

export default forwardRef(ItemPagamento);
