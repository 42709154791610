import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  AppErro,
  IOrdemDesmontagemItemLocalEstoqueLista,
} from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
  UseListaDetalheForm,
} from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseOrdemDesmontagem } from '../../../../../../Hooks/OrdemDesmontagemHook';
import IPadraoProps from '../../../../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { handleValidarExclusao } = UseOrdemDesmontagem();
  const formPrincipal = UseForm();

  const { formRefDetalhe } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const produtoObjeto =
    formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

  const showLote =
    produtoObjeto && produtoObjeto.produtoEstoque
      ? produtoObjeto.produtoEstoque.controlarLote
      : false;

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleCalcularQuantidade = useCallback(
    (listaDados: IOrdemDesmontagemItemLocalEstoqueLista[]) => {
      const quantidadeRetornoDetalhe =
        formRefDetalhe.current?.getFieldValue('quantidadeRetorno');

      const somaQuantidadeLocalEstoque = listaDados.reduce(
        (acumulador: number, item: IOrdemDesmontagemItemLocalEstoqueLista) => {
          acumulador += Number(item.quantidade) || 0;
          return acumulador;
        },
        0
      );

      if (somaQuantidadeLocalEstoque === quantidadeRetornoDetalhe) {
        formRefDetalhe.current?.setFieldValue('desmontado', true);
      } else {
        formRefDetalhe.current?.setFieldValue('desmontado', false);
      }
    },
    [formRefDetalhe]
  );

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      isOrdemDesmontagemCadastrado?: boolean
    ): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const dataHoraInicio =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'dataHoraInicio'
          );
        const dataHoraTermino =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'dataHoraTermino'
          );

        const schema = Yup.object().shape({
          idLocalEstoque: Yup.string()
            .required('Local Estoque é Obrigatório!')
            .nullable(),
          idProduto: Yup.string()
            .required('Informe o Produto Desmontado!')
            .nullable(),
          quantidade: Yup.number()
            .required('Quantidade é Obrigatória!')
            .positive('Quantidade é Obrigatória!'),
          idLote: Yup.string()
            .nullable()
            .test({
              message: 'Lote deve ser Informado!',
              test: (value: any) => {
                return !(showLote && !value);
              },
            }),
          dataHoraReporte: Yup.date()
            .nullable()
            .test({
              message: 'Data Hora Reporte é Obrigatória!',
              test: (value: any) => {
                return !!value;
              },
            })
            .test({
              message:
                'Data Hora Reporte deve ser maior que a Data e Hora de Início!',
              test: (value: any) => {
                if (value && dataHoraInicio && value < dataHoraInicio)
                  return false;

                return true;
              },
            })
            .test({
              message:
                'Data Hora Reporte deve ser menor que a Data e Hora de Térrmino!',
              test: (value: any) => {
                if (value && dataHoraTermino && value > dataHoraTermino)
                  return false;

                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (isOrdemDesmontagemCadastrado !== false) {
          const errors = GetValidationErrors(error as any);
          formRef?.setErrors(errors);
          if (errors.idProduto) {
            formRefDetalhe.current?.setFieldError(
              'idProduto',
              errors.idProduto
            );
          }
        }
        return false;
      }
    },
    [formPrincipal.formRef, formRefDetalhe, showLote]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        const idProduto = formRefDetalhe.current?.getFieldValue('idProduto');

        data.idLocalEstoque = data.localEstoque ? data.localEstoque.id : null;
        data.idLote = data.lote ? data.lote.id : null;
        data.idProduto = idProduto ?? null;

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        if (!showLote) {
          data.lote = null;
        }

        const listaDados = formRefDetalhe.current?.getFieldValue(
          'listaOrdemDesmontagemItemLocalEstoque'
        );

        const listaItemLocalEstoque = [...listaDados, data];

        formRefDetalhe.current?.setFieldValue('listaItemEstoqueMudou', true);

        handleCalcularQuantidade(listaItemLocalEstoque);

        formRefDetalhe.current?.setFieldValue(
          'listaOrdemDesmontagemItemLocalEstoque',
          listaItemLocalEstoque
        );

        await formRef?.reset();

        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      formRefDetalhe,
      getIdDetalheRegistro,
      handleCalcularQuantidade,
      handleValidar,
      redirecionar,
      showLote,
    ]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const listaDados = formRefDetalhe.current?.getFieldValue(
          'listaOrdemDesmontagemItemLocalEstoque'
        );

        if (listaDados[index].id) {
          const response = await handleValidarExclusao(
            listaDados[index].idEstoqueMovimento
          );
          if (response) {
            listaDados.splice(Number(index), 1);

            formRefDetalhe.current?.setFieldValue(
              'listaOrdemDesmontagemItemLocalEstoque',
              listaDados
            );
            setLoading(false);
            formPrincipal.setLoading(false);
          } else {
            setLoading(false);
            formPrincipal.setLoading(false);
            return;
          }
        } else {
          listaDados.splice(Number(index), 1);

          formRefDetalhe.current?.setFieldValue(
            'listaOrdemDesmontagemItemLocalEstoque',
            listaDados
          );

          setLoading(false);
          formPrincipal.setLoading(false);
        }

        handleCalcularQuantidade(listaDados);

        formRefDetalhe.current?.setFieldValue('listaItemEstoqueMudou', true);
      }

      refresh();
      formPrincipal.refresh();
    } catch (error) {
      TratarErros(error, { redirecionar: false });

      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
