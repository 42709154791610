/* eslint-disable consistent-return */
import { IOrdemDesmontagemItemLista } from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdRemoveCircleOutline, IoMdAdd } from 'react-icons/io/index.mjs';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Detalhe from '../Detalhe';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import TrDraggable from '../../../../../../../../Componentes/TrDraggable';
import { UseListaOrdemDesmontagemItemDraggable } from '../Hooks/ListaOrdemDesmontagemItemDraggableHook';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheItem: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemDesmontagem: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const { listaValor, handleMover, setListaValor } =
    UseListaOrdemDesmontagemItemDraggable();

  const valorRef = useRef<IOrdemDesmontagemItemLista[]>([]);
  const [showFormModal, setShowFormModal] = useState(false);

  const handleSalvarFormModal = useCallback(async (): Promise<void> => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleAbrirModal = useCallback((): any => {
    const idProduto = formPrincipal.formRef.current?.getFieldValue('idProduto');
    if (!idProduto) {
      ToastErro('Informe o Produto a ser Desmontado!');
      formPrincipal.formRef.current?.setFieldError(
        'idProduto',
        'Informe o Produto a ser Desmontado!'
      );
      return { error: true };
    }
    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [formPrincipal.formRef, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (index: string): Promise<void> => {
      setIdDetalheRegistro(index);

      setShowFormModal(true);
    },
    [setIdDetalheRegistro]
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IOrdemDesmontagemItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Divisor>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde-claro btn-adicionar"
            onClick={handleAbrirModal}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAdd />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '5%' }}>
                <span>Ordem</span>
              </th>
              <th className="lista-texto" style={{ width: '18%' }}>
                <span>Código</span>
              </th>
              <th className="lista-texto" style={{ width: '35%' }}>
                <span>Descrição Produto/Serviço</span>
              </th>
              <th className="lista-texto" style={{ width: '13%' }}>
                <span>UN</span>
              </th>
              <th className="lista-valor" style={{ width: '19%' }}>
                <span>Qtde de Retorno</span>
              </th>
              <th className="lista-booleano" style={{ width: '10%' }}>
                <span>Desmontado</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor.map((item, index) => {
              return (
                <TrDraggable
                  idDrag={v4()}
                  index={index}
                  onMover={handleMover}
                  onClick={async () => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(String(index));
                  }}
                  key={v4()}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-valor">
                    <div>{item.ordem}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{item.produto.codigo}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{item.produto.descricao}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{item.unidadeMedida && item.unidadeMedida.sigla}</div>
                  </td>
                  <td className="lista-valor">
                    <div>
                      {Number(item.quantidadeRetorno).FormatarParaPtBr({
                        maximoCasasDecimais: 4,
                        minimoCasasDecimais: 4,
                      })}
                    </div>
                  </td>
                  <td className="lista-booleano">
                    <div style={{ color: item.desmontado ? 'green' : 'red' }}>
                      {item.desmontado ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={async () => {
                            await handleExcluir(String(index));
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <IoMdRemoveCircleOutline
                              style={{ marginRight: 5 }}
                            />
                            Remover
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </TrDraggable>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </Divisor>
  );
};

export default ListaDetalheItem;
