import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IImpressaoEtiquetasPedidoVendaItemLista } from '@elogestor/util';
import { IInputAutoCompleteBaseRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IImpressaoEtiquetasPedidoVendaItemListaContext {
  validador: boolean;

  listaValor: IImpressaoEtiquetasPedidoVendaItemLista[];
  setListaValor(lista: IImpressaoEtiquetasPedidoVendaItemLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaQuantidadeRef: RefObject<HTMLInputElement[]>;
  listaTransportadoraRef: RefObject<IInputAutoCompleteBaseRef[]>;
}

const ImpressaoEtiquetasPedidoVendaItemListaContext =
  createContext<IImpressaoEtiquetasPedidoVendaItemListaContext>(
    {} as IImpressaoEtiquetasPedidoVendaItemListaContext
  );

const ImpressaoEtiquetasPedidoVendaItemListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IImpressaoEtiquetasPedidoVendaItemLista[]
  >([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaQuantidadeRef = useRef<HTMLInputElement[]>([]);
  const listaTransportadoraRef = useRef<IInputAutoCompleteBaseRef[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IImpressaoEtiquetasPedidoVendaItemLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <ImpressaoEtiquetasPedidoVendaItemListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaQuantidadeRef,

        listaTransportadoraRef,

        validador: true,
      }}
    >
      {children}
    </ImpressaoEtiquetasPedidoVendaItemListaContext.Provider>
  );
};

function UseImpressaoEtiquetasPedidoVendaItemLista(): Omit<
  IImpressaoEtiquetasPedidoVendaItemListaContext,
  'validador'
> {
  const context = useContext(ImpressaoEtiquetasPedidoVendaItemListaContext);

  if (!context.validador) {
    throw new Error(
      'UseImpressaoEtiquetasPedidoVendaItemLista deve ser usado com um ImpressaoEtiquetasPedidoVendaItemListaHook'
    );
  }

  return context;
}

export {
  ImpressaoEtiquetasPedidoVendaItemListaHook,
  UseImpressaoEtiquetasPedidoVendaItemLista,
};
