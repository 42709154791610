import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/portador/certificado';

interface IPortadorBoletoCertificadoComunicadorShow {
  id: string;
}

interface IPortadorBoletoCertificadoComunicadorUpdate {
  id: string;
  params: any;
}

interface IPortadorBoletoCertificadoComunicadorDelete {
  id: string;
}

class PortadorBoletoCertificadoComunicador {
  public async show(
    params: IPortadorBoletoCertificadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async update(
    params: IPortadorBoletoCertificadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IPortadorBoletoCertificadoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PortadorBoletoCertificadoComunicador();
