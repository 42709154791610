import {
  BooleanEnum,
  FormatarEnum,
  RegraEscolhaTipoEnum,
  IRegraEscolhaCstValoresAlterar,
  RegimeTributarioEnum,
  ModalidadeBaseCalculoIcmsEnum,
  ModalidadeBaseCalculoIcmsStEnum,
  MotivoDesoneracaoIcmsEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Input from '../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTipoNota from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagNcm from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputAutoCompleteTagCest from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCest';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputCnpj from '../../../../../../Componentes/Inputs/InputCnpj';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagModeloDocumento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagSerie from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputAutoCompleteTagEstado from '../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputAutoCompleteTagCidade from '../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputAutoCompleteCodigoSituacaoTributariaIpi from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIpi';
import InputAutoCompleteIpiCodigoEnquadramentoLegal from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteIpiCodigoEnquadramentoLegal';
import InputAutoCompleteCodigoSituacaoTributariaPisCofins from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaPisCofins';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputAutoCompleteTagRegimeTributarioEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagRegimeTributarioEnum';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import { UseRegraEscolhaCstResultado } from '../Hooks/RegraEscolhaCstResultadoHook';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import BntVariaveisButton from '../../../../../../Componentes/Buttons/BntVariaveisButton';

type IProps = IMain<IRegraEscolhaCstValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { FiscalTributacaoRegraEscolhaCst: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const { setDisableExcluir } = UseButtonsSubHeader();

  const {
    camposEscondidosResultadoIcms,
    camposBloqueadosResultadoIcms,
    camposEscondidosResultadoIpi,
    camposBloqueadosResultadoIpi,
    camposEscondidosResultadoPisCofins,
    camposBloqueadosResultadoPisCofins,
    obterVisibilidadeBloqueioCampos,
  } = UseRegraEscolhaCstResultado();

  const idDetalheRegistro = getIdDetalheRegistro();
  const regraEscolhaTipo = formRef.current?.getFieldValue('tipo');

  const [listaCodigoModeloDocumento, setListaCodigoModeloDocumento] = useState<
    ModeloDocumentoTipoEnum[]
  >([]);
  const [listaRegimeTributario, setListaRegimeTributario] = useState<string[]>(
    []
  );
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [listaTipoDocumentoFiscal, setListaTipoDocumentoFiscal] = useState<
    ModeloDocumentoTipoEnum[]
  >([]);

  const [
    utilizaIcmsOperacaoInterestadual,
    setUtilizaIcmsOperacaoInterestadual,
  ] = useState<boolean>(true);

  useEffect(() => {
    if (tipoUsuario !== 'Elo Gestor' && regraEscolhaTipo === 'Padrão') {
      setDisableExcluir(regraEscolhaTipo);
    }
  }, [regraEscolhaTipo, setDisableExcluir, tipoUsuario]);

  const handleAlterarUtilizaIcmsOperacaoInterestadual = useCallback(
    (value: boolean) => {
      setUtilizaIcmsOperacaoInterestadual(value);

      if (!value)
        formRef.current?.clearField(
          'regraEscolhaCstResultado.calcularIcmsOperacaoInterestadual'
        );
    },
    [formRef]
  );

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  const handleChangeListaItemAtualModeloDocumento = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaTipoDocumento =
        listaItemAtual?.map((value) => {
          if (value.tipoDocumentoFiscal) return value.tipoDocumentoFiscal;

          return value.valor.tipoDocumentoFiscal;
        }) || [];

      setListaCodigoModeloDocumento(listaTipoDocumento);

      formRef.current?.clearField(
        'regraEscolhaCstResultado.codigoSituacaoTributariaIcms'
      );
    },
    [formRef]
  );

  const handleChangeListaItemAtualRegimeTributario = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaDescricao =
        listaItemAtual?.map((value) => {
          if (value.descricao) return value.descricao;

          return value.valor.descricao;
        }) || [];

      setListaRegimeTributario(listaDescricao);

      if (listaDescricao.length === 0)
        formRef.current?.clearField(
          'regraEscolhaCstResultado.codigoSituacaoTributariaIcms'
        );
    },
    [formRef]
  );

  // Não Remover os await - são necessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (formRef.current?.getFieldValue('idTipoNota')) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('idTipoNotaMotivo')) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCstRegimeTributarioEmitente'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstDestinado').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstEstadoEmpresaLogada')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstOperacaoFiscal')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstSped').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstNcm').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCest').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (formRef.current?.getFieldValue('temMva') !== null) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('temCest') !== null) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('sujeitoASt') !== null) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('contribuinte') !== null) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('temCodigoAnp') !== null) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstProduto').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstPessoa').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCstRamoAtividadeProfissao'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstEstado').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstCidade').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstModeloDocumento')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCstSerie').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  const handleObterVisibilidadeBloqueioCampos = useCallback(() => {
    obterVisibilidadeBloqueioCampos();
  }, [obterVisibilidadeBloqueioCampos]);

  const handleAdicionarVariaveisLegislacao = useCallback(
    async (valor: string, campo: string) => {
      let valorCampo = formRef.current?.getFieldValue(campo);
      valorCampo += ` ${valor}`;
      formRef.current?.setFieldValue(campo, valorCampo.RemoverExcessoEspacos());
    },
    [formRef]
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
                options={
                  (FormatarEnum({
                    enumObj: RegraEscolhaTipoEnum,
                  }),
                  [
                    {
                      title: 'Contabilidade',
                      value: RegraEscolhaTipoEnum.contabilidade,
                    },
                    { title: 'Empresa', value: RegraEscolhaTipoEnum.empresa },
                    {
                      title: 'Padrão',
                      value: RegraEscolhaTipoEnum.padrao,
                      hidden: tipoUsuario !== 'Elo Gestor',
                    },
                  ])
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputCnpj
                ref={inputRefFocus}
                label="CNPJ Contabilidade"
                name="cnpjContabilidade"
                placeholder="CNPJ Contabilidade"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaCstEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTipoNota
                label="Tipo de Nota"
                name="idTipoNota"
                placeholder="Tipo de Nota"
                nomeObjeto="tipoNota"
                onChangeItemAtual={() => {
                  formRef.current?.clearField('tipoNotaMotivo', {
                    setValorInicial: false,
                  });
                  refresh();
                }}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTipoNotaMotivo
                label="Motivo"
                name="idTipoNotaMotivo"
                placeholder="Motivo"
                nomeObjeto="tipoNotaMotivo"
                disabled={
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor') ||
                  !formRef.current?.getFieldValue('idTipoNota')
                }
                idTipoNota={formRef.current?.getFieldValue('idTipoNota')}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaCstDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>

            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagRegimeTributarioEnum
                label="Regime Tributário"
                name="listaRegraEscolhaCstRegimeTributarioEmitente"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                onChangeListaItemAtual={({ listaItemAtual }) => {
                  handleChangeListaItemAtualRegimeTributario(listaItemAtual);
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagEstado
                label="Estados (UF) da Empresa Logada"
                name="listaRegraEscolhaCstEstadoEmpresaLogada"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagOperacaoFiscal
                label="Lista CFOP"
                name="listaRegraEscolhaCstOperacaoFiscal"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSpedTipoProduto
                label="Tipos SPED"
                name="listaRegraEscolhaCstSped"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagNcm
                label="NCMs"
                name="listaRegraEscolhaCstNcm"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagCest
                label="CESTs"
                name="listaRegraEscolhaCstCest"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={6} sm={6}>
              <Select
                label="Tem MVA"
                name="temMva"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <Select
                label="Tem CEST"
                name="temCest"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <Select
                label="Sujeito a ST. (Cad. Produtos)"
                name="sujeitoASt"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <Select
                label="Contribuinte"
                name="contribuinte"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <Select
                label="Tem Código ANP"
                name="temCodigoAnp"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>

            <Col lg={2} md={6} sm={6}>
              <Select
                label="Tem IPI Aliquota Zero"
                name="temIpiAliquotaZero"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                name="listaRegraEscolhaCstProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagPessoa
                label="Pessoas"
                name="listaRegraEscolhaCstPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagRamoAtividadeProfissao
                label="Ramo Atividade/Profissão"
                name="listaRegraEscolhaCstRamoAtividadeProfissao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagEstado
                label="Estados"
                name="listaRegraEscolhaCstEstado"
                onChangeListaItemAtual={({ listaItemAtual }) => {
                  handleChangeListaItemAtualEstado(listaItemAtual);
                }}
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagCidade
                label="Cidades"
                name="listaRegraEscolhaCstCidade"
                listaIdEstado={listaIdEstado}
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagModeloDocumento
                label="Modelos de Documento"
                name="listaRegraEscolhaCstModeloDocumento"
                onChangeListaItemAtualAposCarregarSemClear={() => {
                  handleAtualizarQuantidadeCriterios();
                }}
                onChangeListaItemAtual={({ listaItemAtual }) => {
                  const listaModeloDocumento: ModeloDocumentoTipoEnum[] = [];
                  if (
                    listaItemAtual?.some(
                      (item) =>
                        item.tipoDocumentoFiscal ===
                        ModeloDocumentoTipoEnum.conhecimentoTransporte
                    )
                  ) {
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.conhecimentoTransporte
                    );
                  } else if (
                    listaItemAtual?.some(
                      (item) =>
                        item.tipoDocumentoFiscal ===
                          ModeloDocumentoTipoEnum.notaFiscal ||
                        item.tipoDocumentoFiscal ===
                          ModeloDocumentoTipoEnum.cupomFiscal
                    )
                  ) {
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.notaFiscal
                    );
                    listaModeloDocumento.push(
                      ModeloDocumentoTipoEnum.cupomFiscal
                    );
                  }

                  setListaTipoDocumentoFiscal(listaModeloDocumento);
                  handleChangeListaItemAtualModeloDocumento(listaItemAtual);
                  handleObterVisibilidadeBloqueioCampos();
                }}
                listaTipoDocumentoFiscal={listaTipoDocumentoFiscal}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagSerie
                label="Séries"
                name="listaRegraEscolhaCstSerie"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteCodigoSituacaoTributariaIcms
                label="Código CST. ICMS"
                name="regraEscolhaCstResultado.idCodigoSituacaoTributariaIcms"
                placeholder="Código CST. ICMS"
                nomeObjeto="regraEscolhaCstResultado.codigoSituacaoTributariaIcms"
                icms={
                  listaRegimeTributario.includes(
                    RegimeTributarioEnum.simplesNacionalExcessoSublimiteReceitaBruta
                  ) ||
                  listaRegimeTributario.includes(
                    RegimeTributarioEnum.lucroPresumidoRegimeNormal
                  ) ||
                  listaRegimeTributario.includes(
                    RegimeTributarioEnum.lucroRealRegimeNormal
                  )
                }
                icmsSimplesNacional={
                  listaRegimeTributario.includes(
                    RegimeTributarioEnum.simplesNacionalMicroEmpresa
                  ) ||
                  listaRegimeTributario.includes(
                    RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte
                  )
                }
                icmsConhecimentoTransporte={listaCodigoModeloDocumento.includes(
                  ModeloDocumentoTipoEnum.conhecimentoTransporte
                )}
                onChangeItemAtual={() => {
                  handleObterVisibilidadeBloqueioCampos();
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIcms.calcularIcms}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularIcms ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular ICMS"
                  name="regraEscolhaCstResultado.calcularIcms"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={
                camposEscondidosResultadoIcms.calcularCreditoIcmsSn ||
                (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor')
              }
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularCreditoIcmsSn ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular Aproveitamento Crédito ICMS SN."
                  name="regraEscolhaCstResultado.calcularCreditoIcmsSn"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIcms.calcularSt}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularSt ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular ST."
                  name="regraEscolhaCstResultado.calcularSt"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIcms.calcularIcmsStRetido}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularIcmsStRetido ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular ICMS ST. Retido"
                  name="regraEscolhaCstResultado.calcularIcmsStRetido"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIcms.calcularIcmsEfetivo}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularIcmsEfetivo ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular ICMS Efetivo"
                  name="regraEscolhaCstResultado.calcularIcmsEfetivo"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIcms.calcularIcmsSubstituto}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIcms.calcularIcmsSubstituto ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular ICMS Substituto"
                  name="regraEscolhaCstResultado.calcularIcmsSubstituto"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={
                camposEscondidosResultadoIcms.utilizaIcmsOperacaoInterestadual
              }
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Utiliza ICMS Operação Interestadual"
                  name="regraEscolhaCstResultado.utilizaIcmsOperacaoInterestadual"
                  onChangeValue={(value) => {
                    handleAlterarUtilizaIcmsOperacaoInterestadual(
                      value.valorAtual
                    );
                  }}
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={
                camposEscondidosResultadoIcms.calcularIcmsOperacaoInterestadual
              }
            >
              <div className="checkbox-container">
                <InputCheckbox
                  label="Calcular ICMS Operação Interestadual"
                  name="regraEscolhaCstResultado.calcularIcmsOperacaoInterestadual"
                  disabled={
                    !utilizaIcmsOperacaoInterestadual ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              lg={4}
              md={6}
              sm={6}
              hidden={camposEscondidosResultadoIcms.modalidadeBaseCalculoIcms}
            >
              <Select
                disabled={
                  // camposBloqueadosResultadoIcms.modalidadeBaseCalculoIcms ||
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
                label="Modalidade da Base de Cálculo"
                name="regraEscolhaCstResultado.modalidadeBaseCalculoIcms"
                options={FormatarEnum({
                  enumObj: ModalidadeBaseCalculoIcmsEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={6}
              hidden={camposEscondidosResultadoIcms.modalidadeBaseCalculoIcmsSt}
            >
              <Select
                disabled={
                  camposBloqueadosResultadoIcms.modalidadeBaseCalculoIcmsSt ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="Modalidade da Base de Cálculo CST."
                name="regraEscolhaCstResultado.modalidadeBaseCalculoIcmsSt"
                options={FormatarEnum({
                  enumObj: ModalidadeBaseCalculoIcmsStEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={6}
              hidden={camposEscondidosResultadoIcms.motivoDesoneracaoIcms}
            >
              <Select
                disabled={
                  camposBloqueadosResultadoIcms.motivoDesoneracaoIcms ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="Motivo Desoneração"
                name="regraEscolhaCstResultado.motivoDesoneracaoIcms"
                options={FormatarEnum({
                  enumObj: MotivoDesoneracaoIcmsEnum,
                  nullavel: true,
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              hidden={camposEscondidosResultadoIpi.codigoSituacaoTributariaIpi}
            >
              <InputAutoCompleteCodigoSituacaoTributariaIpi
                disabled={
                  camposBloqueadosResultadoIpi.codigoSituacaoTributariaIpi ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="Código CST. IPI"
                name="regraEscolhaCstResultado.idCodigoSituacaoTributariaIpi"
                placeholder="Código CST. IPI"
                nomeObjeto="regraEscolhaCstResultado.codigoSituacaoTributariaIpi"
                onChangeItemAtual={() => {
                  handleObterVisibilidadeBloqueioCampos();
                }}
              />
            </Col>
            <Col
              lg={12}
              md={12}
              sm={12}
              hidden={camposEscondidosResultadoIpi.ipiCodigoEnquadramentoLegal}
            >
              <InputAutoCompleteIpiCodigoEnquadramentoLegal
                disabled={
                  camposBloqueadosResultadoIpi.ipiCodigoEnquadramentoLegal ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="IPI Código Enquadramento Legal"
                name="regraEscolhaCstResultado.idIpiCodigoEnquadramentoLegal"
                placeholder="IPI Código Enquadramento Legal"
                nomeObjeto="regraEscolhaCstResultado.ipiCodigoEnquadramentoLegal"
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={camposEscondidosResultadoIpi.calcularIpi}
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoIpi.calcularIpi ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular IPI"
                  name="regraEscolhaCstResultado.calcularIpi"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              hidden={
                camposEscondidosResultadoPisCofins.codigoSituacaoTributariaPisCofins
              }
            >
              <InputAutoCompleteCodigoSituacaoTributariaPisCofins
                disabled={
                  camposBloqueadosResultadoPisCofins.codigoSituacaoTributariaPisCofins ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="Código CST. PIS COFINS"
                name="regraEscolhaCstResultado.idCodigoSituacaoTributariaPisCofins"
                placeholder="Código CST. PIS COFINS"
                nomeObjeto="regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins"
                onChangeItemAtual={() => {
                  handleObterVisibilidadeBloqueioCampos();
                }}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={
                camposEscondidosResultadoPisCofins.calcularPisCofins ||
                (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor')
              }
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoPisCofins.calcularPisCofins ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular PIS COFINS"
                  name="regraEscolhaCstResultado.calcularPisCofins"
                />
              </div>
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="div-checkbox"
              hidden={
                camposEscondidosResultadoPisCofins.calcularPisCofinsRetido
              }
            >
              <div className="checkbox-container">
                <InputCheckbox
                  disabled={
                    camposBloqueadosResultadoPisCofins.calcularPisCofinsRetido ||
                    (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor')
                  }
                  label="Calcular PIS COFINS Retido"
                  name="regraEscolhaCstResultado.calcularPisCofinsRetido"
                />
              </div>
            </Col>

            <Col lg={3} md={4} sm={6} xs={12} className="div-checkbox">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Calcular FUNRURAL"
                  name="regraEscolhaCstResultado.calcularFunrural"
                />
              </div>
            </Col>

            <Col lg={3} md={4} sm={6} xs={12} className="div-checkbox">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Calcular Valor Aprox. de Tributos"
                  name="regraEscolhaCstResultado.calcularImpostoAproximado"
                />
              </div>
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={4} md={8} sm={12}>
              <Input
                label="Código Beneficiário Fiscal SPED"
                name="regraEscolhaCstResultado.codigoBeneficiarioFiscalSped"
                placeholder="Código Beneficiário Fiscal SPED"
                maxLength={10}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={9} md={9} sm={9}>
              <Textarea
                label="Base Legal ICMS"
                name="regraEscolhaCstResultado.baseLegalIcms"
                placeholder="Base Legal ICMS"
                maxLength={500}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={3} md={3} sm={3}>
              <BntVariaveisButton
                onClick={(valor) => {
                  handleAdicionarVariaveisLegislacao(
                    valor,
                    'regraEscolhaCstResultado.baseLegalIcms'
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col
              lg={9}
              md={9}
              sm={9}
              hidden={camposEscondidosResultadoIpi.baseLegalIpi}
            >
              <Textarea
                disabled={
                  camposBloqueadosResultadoIpi.baseLegalIpi ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                label="Base Legal IPI"
                name="regraEscolhaCstResultado.baseLegalIpi"
                placeholder="Base Legal IPI"
                maxLength={500}
              />
            </Col>

            <Col
              lg={3}
              md={3}
              sm={3}
              hidden={camposEscondidosResultadoIpi.baseLegalIpi}
            >
              <BntVariaveisButton
                onClick={(valor) => {
                  handleAdicionarVariaveisLegislacao(
                    valor,
                    'regraEscolhaCstResultado.baseLegalIpi'
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={9} md={9} sm={9}>
              <Textarea
                label="Outra Base Legal"
                name="regraEscolhaCstResultado.outraBaseLegal"
                placeholder="Outra Base Legal"
                maxLength={500}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>

            <Col lg={3} md={3} sm={3}>
              <BntVariaveisButton
                onClick={(valor) => {
                  handleAdicionarVariaveisLegislacao(
                    valor,
                    'regraEscolhaCstResultado.outraBaseLegal'
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <Textarea
                label="Informações do Contribuinte"
                name="regraEscolhaCstResultado.informacoesContribuinte"
                placeholder="Informações do Contribuinte"
                maxLength={500}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
