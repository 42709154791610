import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ContaComunicador from '../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaComunicador';
import { AntecipacaoPrevisaoHook } from './AntecipacaoPrevisaoHook';
import { ContaListaParcelaBoletoHook } from '../AcoesConta/ContaParcelaBoleto/Hook/ContaListaParcelaBoletoHook';
import { ContaListaCategoriaHook } from './ContaListaCategoriaHook';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import { ContaCategoriaHook } from './ContaCategoriaHook';
import { ContaListaMovimentoPortadorHook } from '../AcoesConta/UtilizacaoAdiantamento/Hook/ContaListaMovimentoPortadorHook';

const ContaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider value={{ comunicador: ContaComunicador }}>
        <RotasHook>
          <ContaListaMovimentoPortadorHook>
            <ContaListaParcelaBoletoHook>
              <ContaListaCategoriaHook>
                <FormHook>
                  <ContaCategoriaHook>
                    <AntecipacaoPrevisaoHook>
                      <SubHeaderHook>{children}</SubHeaderHook>
                    </AntecipacaoPrevisaoHook>
                  </ContaCategoriaHook>
                </FormHook>
              </ContaListaCategoriaHook>
            </ContaListaParcelaBoletoHook>
          </ContaListaMovimentoPortadorHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default ContaHook;
