import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/centro-trabalho-auto-complete';

interface ICentroTrabalhoAutoCompleteComunicadorIndex {
  params: any;
}

class CentroTrabalhoAutoCompleteComunicador {
  public async index(
    params: ICentroTrabalhoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CentroTrabalhoAutoCompleteComunicador();
