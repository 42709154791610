import React from 'react';
import Divisor from '../../../../../../Componentes/Divisor';
import PessoaProdutorHook from './ListaDetalheProdutor/Hooks';
import ListaDetalheProdutor from './ListaDetalheProdutor/Lista';

const ProdutorTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <PessoaProdutorHook>
          <ListaDetalheProdutor name="listaPessoaProdutor" />
        </PessoaProdutorHook>
      </Divisor>
    </div>
  );
};

export default ProdutorTab;
