import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/orcamento/orcamento-resumo';

interface IOrcamentoResumoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrcamentoResumoRelatorioComunicador {
  public async index(
    params: IOrcamentoResumoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrcamentoResumoRelatorioComunicador();
