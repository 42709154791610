const DevolucaoNotasEntradaPorItensRelatorioPlanilha = [
  { titulo: 'Série', valor: 'serie' },
  { titulo: 'Numero', valor: 'numero' },
  { titulo: 'Cliente', valor: 'nomeRazaoSocial' },

  { titulo: 'Código', valor: 'listaItens.produto.codigo' },
  { titulo: 'Descrição', valor: 'listaItens.produto.descricao' },
  { titulo: 'Quantidade', valor: 'listaItens.quantidade' },

  { titulo: 'Qntd Devolvida', valor: 'listaItens.quantidadeDevolvida' },
  { titulo: 'Saldo', valor: 'listaItens.saldo' },
  { titulo: 'Situação', valor: 'listaItens.situacao' },

  {
    titulo: 'Num Nota',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoNotaFiscalEntradaItem.notaFiscalSaidaItem.notaFiscalSaida.numero',
  },
  {
    titulo: 'Serie',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoNotaFiscalEntradaItem.notaFiscalSaidaItem.notaFiscalSaida.serie.codigo',
  },
  {
    titulo: 'Dt Emissao',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoNotaFiscalEntradaItem.notaFiscalSaidaItem.notaFiscalSaida.dataEmissao',
  },
  {
    titulo: 'Qtd Devolvida',
    valor:
      'listaItens.listaNotaFiscalSaidaItemImportacaoNotaFiscalEntradaItem.quantidade',
  },
];

export default DevolucaoNotasEntradaPorItensRelatorioPlanilha;
