import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/geral/regra-escolha-integracao-pagamento-auto-complete';

interface IRegraEscolhaIntegracaoPagamentoAutoCompleteComunicadorIndex {
  params: any;
}

class RegraEscolhaIntegracaoPagamentoAutoCompleteComunicador {
  public async index(
    params: IRegraEscolhaIntegracaoPagamentoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaIntegracaoPagamentoAutoCompleteComunicador();
