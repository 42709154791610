import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import CentroCustoComunicador from '../../../../../../Comunicador/Contabil/ContasContabeis/CentroCusto/Comunicador/CentroCustoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';

const CentroCustoDetalheHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: CentroCustoComunicador }}>
      <RotasHook>
        <FormHook>{children}</FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default CentroCustoDetalheHook;
