import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { SituacaoNotaFiscalEntradaEnum } from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import ContaRevertidaComunicador from '../../../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface ICancelarModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const CancelarDetalhe: React.FC<ICancelarModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { abrirJanela } = UseConfirmacao();

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoNotaFiscalEntradaEnum.cancelada;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const idRegistro = formPrincipal.getIdDetalheRegistro() || '';

    const retorno = await ContaRevertidaComunicador.index({
      params: {
        idNotaFiscalEntrada: idRegistro,
      },
    });

    if (retorno.possuiContaParcelaRevertida) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Essa conta possui uma reversão. Deseja excluir a conta revertida?
          </span>
        ),
        confimar: 'Sim',
        cancelar: 'Não',
      });

      if (!resposta) {
        return;
      }
    }

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    abrirJanela,
    formPrincipal,
    formRefDetalhe,
    handleSubmit,
    onSalvarFormModal,
  ]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Cancelar NF-e"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              <Divisor>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <Textarea
                      ref={inputRefFocus}
                      style={{ minHeight: 250 }}
                      label="Motivo do Cancelamento"
                      name="motivoCancelamento"
                      maxLength={255}
                    />
                  </Col>
                </Row>
              </Divisor>
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading || situacaoBloquearCampos}
            >
              <MdCancel />
              <span style={{ marginLeft: 10 }}>Cancelar NF-e</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default CancelarDetalhe;
