const NotaFiscalSaidaPorDataEmissaoPlanilha = [
  { titulo: 'Data de Emissao', valor: 'chave' },
  {
    titulo: 'Série',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaSerie',
  },

  {
    titulo: 'Número',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Hora',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaHoraEmissao',
  },

  {
    titulo: 'Situação Item',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaSituacaoItem',
  },

  {
    titulo: 'Cliente',
    valor: 'listaNotaFiscalSaidaItem.clienteNomeRazaoSocial',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaNotaFiscalSaidaItem.valorSubtotal',
  },
  {
    titulo: 'Desconto',
    valor: 'listaNotaFiscalSaidaItem.valorDesconto',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalSaidaItem.valorTotal',
  },
];

export default NotaFiscalSaidaPorDataEmissaoPlanilha;
