import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/relatorios/engenharia/estrutura-produto/primeiro-nivel';

interface IEstruturaProdutoPrimeiroNivelRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstruturaProdutoPrimeiroNivelRelatorioComunicador {
  public async index(
    params: IEstruturaProdutoPrimeiroNivelRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstruturaProdutoPrimeiroNivelRelatorioComunicador();
