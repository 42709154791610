import { AppErro } from '@elogestor/util';
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import LogModal from '..';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import { usuarioLogado } from '../../../Hooks/Auth';
import TratarErros from '../../../Util/Erro/TratarErros';
import ILog from '../Interfaces/ILog';
import ILogPesquisaParametro from '../Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../Interfaces/ILogPesquisaRetorno';
import IPesquisaAvancadaDadosLog from '../Interfaces/IPesquisaAvancadaDadosLog';
import IPesquisaAvancadaDadosLogCompleto from '../Interfaces/IPesquisaAvancadaDadosLogCompleto';
import PesquisaAvancadaLog from '../PesquisaAvancadaLog';

interface IUsuarioLog {
  nome: string;
  id: string;
}

type IFuncao = (params: ILogPesquisaParametro) => Promise<ILogPesquisaRetorno>;

interface ILogContext {
  abrirJanela(funcao: IFuncao): void;
  fecharJanela(): void;

  paginaAtual: number;
  setPaginaAtual(value: number): void;
  totalPaginas: number;
  setTotalPaginas(value: number): void;
  limite: number;
  setLimite(value: number): void;

  usuarioFiltro: IUsuarioLog | null;
  setUsuarioFiltro(value: IUsuarioLog | null): void;
  dataHoraInicial: Date | null;
  setDataHoraInicial(value: Date | null): void;
  dataHoraFinal: Date | null;
  setDataHoraFinal(value: Date | null): void;
}

const LogContext = createContext<ILogContext>({} as ILogContext);

const LogProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [aberta, setAberta] = useState(false);
  const [buscarDados, setBuscarDados] = useState<
    null | ((params: any) => Promise<any>)
  >(null);
  const [listaLog, setListaLog] = useState<ILog[]>([]);
  const [usuarioFiltro, setUsuarioFiltro] = useState<IUsuarioLog | null>(null);
  const [dataHoraInicial, setDataHoraInicial] = useState<Date | null>(null);
  const [dataHoraFinal, setDataHoraFinal] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const [filtrosPesquisaAvancada, setFiltrosPesquisaAvancada] =
    useState<IPesquisaAvancadaDadosLog | null>(null);

  const [filtroAtual, setFiltroAtual] =
    useState<IPesquisaAvancadaDadosLogCompleto | null>(null);
  const [pesquisaAvancadaAberta, setPesquisaAvancadaAberta] = useState(false);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [limite, setLimite] = useState(10);

  const handleAbrirJanela = useCallback((novaFuncao: IFuncao) => {
    if (!novaFuncao) {
      throw new AppErro({
        mensagem: `Uma função valida é necessaria! Função recebida: ${novaFuncao}`,
      });
    }

    setUsuarioFiltro({
      id: usuarioLogado.id ?? '',
      nome: usuarioLogado.nome ?? '',
    });
    const dataInicial = new Date();

    dataInicial.setHours(0, 0, 0);

    setDataHoraInicial(dataInicial);
    setDataHoraFinal(new Date());

    setPaginaAtual(1);
    setTotalPaginas(0);
    setBuscarDados(() => novaFuncao);
    setAberta(true);
  }, []);

  const handleLimparPesquisaAvancada = useCallback(() => {
    setFiltrosPesquisaAvancada(null);
    setFiltroAtual(null);
  }, []);

  const handleFecharJanela = useCallback(() => {
    setBuscarDados(null);
    setAberta(false);
    setUsuarioFiltro(null);
    setListaLog([]);
    handleLimparPesquisaAvancada();
  }, [handleLimparPesquisaAvancada]);

  const handleAbrirJanelaPesquisaAvancada = useCallback(() => {
    setPesquisaAvancadaAberta(true);
    setPesquisaAvancadaAberta(true);
  }, []);

  const handleFecharJanelaPesquisaAvancada = useCallback(() => {
    setPesquisaAvancadaAberta(false);
  }, []);

  const handleAlterarFiltrosPesquisaAvancada = useCallback(
    (data: IPesquisaAvancadaDadosLog | null) => {
      setFiltrosPesquisaAvancada(data);
      setUsuarioFiltro(null);
    },
    []
  );

  useEffect(() => {
    async function buscarListaLog(): Promise<void> {
      try {
        setLoading(true);

        if (buscarDados) {
          const filtro = !filtrosPesquisaAvancada
            ? {
                idUsuarioFiltro: usuarioFiltro?.id,
                dataInicial: dataHoraInicial,
                dataFinal: dataHoraFinal,
              }
            : filtrosPesquisaAvancada;

          const response = await buscarDados({
            filtro,
            limite,

            paginaAtual,
          });
          setListaLog(response.dados);

          setTotalPaginas(response.totalPaginas);
          setLoading(false);
        }
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }

    buscarListaLog();
  }, [
    buscarDados,
    dataHoraFinal,
    dataHoraInicial,
    filtrosPesquisaAvancada,
    limite,
    paginaAtual,
    usuarioFiltro,
  ]);

  return (
    <LogContext.Provider
      value={{
        abrirJanela: handleAbrirJanela,
        fecharJanela: handleFecharJanela,

        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,

        usuarioFiltro,
        setUsuarioFiltro,
        dataHoraInicial,
        setDataHoraInicial,
        dataHoraFinal,
        setDataHoraFinal,
      }}
    >
      {children}

      {aberta && (
        <LogModal
          listaLog={listaLog}
          fecharJanela={handleFecharJanela}
          abrirPesquisaAvancada={handleAbrirJanelaPesquisaAvancada}
          filtrosPesquisaAvancada={filtroAtual}
          limparPesquisaAvancada={handleLimparPesquisaAvancada}
          loading={loading}
        />
      )}

      <PesquisaAvancadaLog
        aberta={pesquisaAvancadaAberta}
        fechar={handleFecharJanelaPesquisaAvancada}
        alterarFiltroPesquisaAvancada={handleAlterarFiltrosPesquisaAvancada}
        filtroAtual={filtroAtual}
        setFiltroAtual={setFiltroAtual}
      />
    </LogContext.Provider>
  );
};

function UseLog(): Omit<ILogContext, 'validador'> {
  const context = useContext(LogContext);

  if (!context) {
    throw new Error('UseLog deve ser usado com um LogProvider');
  }

  return context;
}

export { LogProvider, UseLog };
