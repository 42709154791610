import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/produtos/produto-por-ncm';

interface IProdutoPorNcmRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ProdutoPorNcmRelatorioComunicador {
  public async index(
    params: IProdutoPorNcmRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ProdutoPorNcmRelatorioComunicador();
