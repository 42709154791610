import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-sitef-pagamentos-pendentes';

class FrenteCaixaPdvObterPagamentoSiTefPendenteComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterPagamentoSiTefPendenteComunicador();
