import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda-importacao-orcamento/importar';

interface IPedidoVendaImportacaoOrcamentoImportarComunicadorStore {
  params: any;
}

class PedidoVendaImportacaoOrcamentoImportarComunicador {
  public async store(
    params: IPedidoVendaImportacaoOrcamentoImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new PedidoVendaImportacaoOrcamentoImportarComunicador();
