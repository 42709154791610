import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import {
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineFileSearch,
} from 'react-icons/ai/index.mjs';
import { IoMdAddCircleOutline, IoIosSettings } from 'react-icons/io/index.mjs';
import {
  OverlayTrigger,
  Button,
  Tooltip,
  Popover,
  Dropdown,
} from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaRegListAlt } from 'react-icons/fa/index.mjs';
import OverlayConfiguracoes from './OverlayConfiguracoes';
import { UseLista } from '../ListaHooks';
import { UseTableDraggable } from '../../TableDraggable/HooksTable/TableDraggableContext';
import OverlayPesquisaAvancada from '../OverlayPesquisaAvancada';
import {
  Container,
  Botoes,
  ContainerPersonalizacaoLista,
  BotoesDireita,
  PesquisaGrande,
  DropdownConfiguracao,
} from './styles';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';

interface ISubHeader {
  titulo: string;
  permissao?: any;
  botaoNovo?: boolean;
  configuracaoItens?: JSX.Element;
  botoes?: JSX.Element;
  quantidadeColunaIncrementar?: number;
  onRemoverFiltro?: () => any;
  onClickBotaoNovo?: () => any;
  permitirAtualizar?: () => Promise<boolean>;
  hasCamposDaListagem?: boolean;
}

const SubHeader: React.FC<ISubHeader> = ({
  titulo,
  // permissao = {},
  botaoNovo = true,
  configuracaoItens,
  botoes,
  quantidadeColunaIncrementar,
  onRemoverFiltro,
  onClickBotaoNovo = undefined,
  permitirAtualizar,
  hasCamposDaListagem = true,
}) => {
  const { telaPequena, telaGrande } = UseReactSizeMeBodyHook();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  const { abrirPesquisaAvancada } = UseLista();
  const { parametros, setParametros, overlayPesquisaAvancadaValores } =
    UseTableDraggable();
  const [personalizacaoListaVisivel, setPersonalizacaoListaVisivel] =
    useState(false);
  const [mostrarPesquisaPequena, setMostrarPesquisaPequena] = useState(true);

  const [campoPesquisa, setCampoPesquisa] = useState('');

  useEffect(() => {
    setCampoPesquisa(parametros?.textoPesquisa || '');
  }, [parametros, parametros.textoPesquisa]);

  const toggleMostrarPesquisaPequena = useCallback(() => {
    setMostrarPesquisaPequena((state) => !state);
  }, []);

  const mostrarPesquisaPequenaComTamanhoMaior = useMemo(() => {
    return mostrarPesquisaPequena || telaGrande;
  }, [mostrarPesquisaPequena, telaGrande]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handleSetarParametros = useCallback(
    async (textoPesquisa: string): Promise<void> => {
      const permiteAtualizar = await handlePermitirAtualizar();
      if (permiteAtualizar) {
        setParametros({
          pagina: 1,
          textoPesquisa,
        });
      }
    },
    [handlePermitirAtualizar, setParametros]
  );

  useEffect(() => {
    const pesquisaAvancada = sessionStorage.getItem(
      '@EloGestorle:pesquisaAvancada'
    );

    if (pesquisaAvancada) {
      const pesquisaAvancadaParse = JSON.parse(pesquisaAvancada);

      if (path === pesquisaAvancadaParse.path) {
        setParametros({
          pagina: 1,
          pesquisaAvancada: pesquisaAvancadaParse,
          textoPesquisa: '',
        });
      } else {
        sessionStorage.removeItem('@EloGestorle:pesquisaAvancada');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, setParametros]);

  return (
    <Container $telaPequena={telaPequena}>
      <h1>{titulo}</h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        {!parametros.pesquisaAvancada && (
          <Botoes $telaPequena={telaPequena}>
            <div className="pesquisa">
              <div>
                {(!mostrarPesquisaPequena || telaGrande) && (
                  <input
                    type="text"
                    value={campoPesquisa}
                    onChange={(event) => {
                      handleSetarParametros(event.target.value);
                    }}
                  />
                )}

                <button
                  style={{
                    position:
                      !mostrarPesquisaPequena || telaGrande
                        ? 'absolute'
                        : 'initial',
                  }}
                  type="button"
                  onClick={toggleMostrarPesquisaPequena}
                >
                  {mostrarPesquisaPequena || telaGrande ? (
                    <AiOutlineSearch />
                  ) : (
                    <AiOutlineClose />
                  )}
                </button>
              </div>
            </div>
          </Botoes>
        )}

        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Popover id={v4()}>
              <OverlayPesquisaAvancada
                pesquisaAvancada={overlayPesquisaAvancadaValores || []}
              />
            </Popover>
          }
        >
          <PesquisaGrande
            style={{ display: parametros.pesquisaAvancada ? 'flex' : 'none' }}
          >
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                fontSize: 22,
                height: 38,
                width: 38,
              }}
              variant="secondary"
              onClick={abrirPesquisaAvancada}
            >
              <AiOutlineFileSearch />
            </Button>
            <div>
              <span>Pesquisa Avançada</span>
              <button
                type="button"
                onClick={async () => {
                  if (await handlePermitirAtualizar()) {
                    onRemoverFiltro && onRemoverFiltro();
                    setParametros({ pagina: 1, pesquisaAvancada: undefined });
                    sessionStorage.removeItem('@EloGestorle:pesquisaAvancada');
                  }
                }}
              >
                Remover Filtro
              </button>
            </div>
          </PesquisaGrande>
        </OverlayTrigger>

        {mostrarPesquisaPequenaComTamanhoMaior && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id={v4()}>Pesquisa Avançada</Tooltip>}
          >
            <Button
              variant="secondary"
              style={{
                display: parametros.pesquisaAvancada ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                fontSize: 22,
                height: 38,
                width: 38,
                marginRight: 15,
                marginLeft: telaPequena ? 10 : 0,
              }}
              onClick={() => {
                abrirPesquisaAvancada();
              }}
            >
              <AiOutlineFileSearch />
            </Button>
          </OverlayTrigger>
        )}

        {mostrarPesquisaPequenaComTamanhoMaior && (
          <BotoesDireita>
            {botoes && botoes}

            <ContainerPersonalizacaoLista>
              <DropdownConfiguracao>
                <Dropdown.Toggle id={v4()}>
                  <IoIosSettings fontSize={19} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {hasCamposDaListagem && (
                    <Dropdown.Item
                      onClick={() => {
                        setPersonalizacaoListaVisivel(
                          !personalizacaoListaVisivel
                        );
                      }}
                    >
                      <span style={{ display: 'flex' }}>
                        <FaRegListAlt
                          style={{ marginRight: 5, fontSize: 16 }}
                        />
                        Campos da listagem
                      </span>
                    </Dropdown.Item>
                  )}
                  {configuracaoItens}
                </Dropdown.Menu>
              </DropdownConfiguracao>

              <OverlayConfiguracoes
                visivel={personalizacaoListaVisivel}
                fechar={() => {
                  setPersonalizacaoListaVisivel(false);
                }}
                quantidadeColunaIncrementar={quantidadeColunaIncrementar}
              />
            </ContainerPersonalizacaoLista>

            {botaoNovo && (
              <button
                style={{ width: 'max-content' }}
                className="btn-padrao btn-azul"
                type="button"
                onClick={() => {
                  if (onClickBotaoNovo) {
                    onClickBotaoNovo();
                  } else {
                    navigate(`${path}/detalhe`);
                  }
                }}
              >
                <IoMdAddCircleOutline fontSize={19} />
                {telaGrande && <span style={{ marginLeft: 10 }}>Novo</span>}
              </button>
            )}
          </BotoesDireita>
        )}
      </div>
    </Container>
  );
};

export default SubHeader;
