import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/retorno-consignado';

interface IFrenteCaixaPdvRetornoConsignadoComunicadorUpdate {
  params: any;
}

class FrenteCaixaPdvRetornoConsignadoComunicador {
  public async update(
    params: IFrenteCaixaPdvRetornoConsignadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvRetornoConsignadoComunicador();
