import {
  IOrdemProducaoLocalEstoqueLista,
  IOrdemProducaoValoresAlterar,
  SituacaoOrdemProducaoEnum,
  TipoProducaoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import OrdemProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/OrdemProducaoComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import { UseOrdemProducao } from './OrdemProducaoHook';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { setPermiteCarregarEstruturaFilhos } = UseOrdemProducao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);
  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IOrdemProducaoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        setPermiteCarregarEstruturaFilhos(false);
        await Sleep(100);

        dadosDuplicados.ativo = true;
        dadosDuplicados.numero = undefined;
        if (dadosDuplicados.listaCustos) {
          dadosDuplicados.listaCustos.map(async (item: any) => {
            item.id = undefined;
          });
        }

        if (dadosDuplicados.listaOrdemProducaoLocalEstoque) {
          dadosDuplicados.listaOrdemProducaoLocalEstoque.map(
            async (item: any) => {
              item.id = undefined;
            }
          );
        }

        if (dadosDuplicados.listaOrdemProducaoItem) {
          dadosDuplicados.listaOrdemProducaoItem.map(async (item: any) => {
            item.id = undefined;

            if (item.listaCusto) {
              item.listaCusto.map(async (valor: any) => {
                valor.id = undefined;
              });
            }

            if (item.listaOrdemProducaoItemLocalEstoque) {
              item.listaOrdemProducaoItemLocalEstoque.map(
                async (valor: any) => {
                  valor.id = undefined;
                }
              );
            }
          });
        }

        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const { response, listaCustos } = await OrdemProducaoComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicialSemExecutarEvento({
          ...response,
          listaCustos,
          listaEstoqueMudou: false,
          listaItemMudou: false,
        });
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicialSemExecutarEvento({
          dataLancamento: new Date(),
          situacao: SituacaoOrdemProducaoEnum.emAndamento,
          tipoProducao: TipoProducaoEnum.propria,
          calcularQuantidadeConsumoItens: true,
          dataHoraInicio: new Date().SetarSegundosMilisegundosZero(),
          quantidade: 1,
          listaEstoqueMudou: false,
          listaItemMudou: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
      setPermiteCarregarEstruturaFilhos,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        dataLancamento: Yup.date().required(
          'Data de Lançamento é Obrigatória!'
        ),
        idProduto: Yup.string().required('Produto é Obrigatório!'),
        tipoProducao: Yup.mixed().required('Tipo Producao é Obrigatório!'),
        idPessoa: Yup.mixed()
          .nullable()
          .test({
            message:
              'Informe a Pessoa, quando Tipo Produção for Diferente de Própria!',
            test: (value: any) => {
              if (data.tipoProducao !== TipoProducaoEnum.propria && !value) {
                return false;
              }

              return true;
            },
          }),

        quantidade: Yup.number()
          .required('Quantidade é Obrigatória!')
          .positive('Quantidade é Obrigatória!'),
        calcularQuantidadeConsumoItens: Yup.boolean().required(
          'Calcular a Quantidade de Demanda é Obrigatório'
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleValidarHorasDatas = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];

      const {
        dataHoraInicio,
        dataHoraTermino,
        listaOrdemProducaoLocalEstoque,
        listaOrdemProducaoItem,
      } = data;

      for (let i = 0; i < listaOrdemProducaoLocalEstoque.length; i++) {
        const ordemProducaoLocalEstoque = listaOrdemProducaoLocalEstoque[i];

        if (
          dataHoraInicio > new Date(ordemProducaoLocalEstoque.dataHoraReporte)
        ) {
          listaErro.push(
            `Data e Hora de Início maior que a Data de Reporte do Local de Estoque de Ordem ${
              i + 1
            }!`
          );
          formRef.current?.setFieldError(
            'dataHoraInicio',
            'Data e Hora de Início maior que a Data de Reporte!'
          );
        }

        if (
          dataHoraTermino &&
          dataHoraTermino.EhMenorDataDesconsiderandoSegundos(
            new Date(
              ordemProducaoLocalEstoque.dataHoraReporte
            ).SetarSegundosMilisegundosZero()
          )
        ) {
          listaErro.push(
            `Data e Hora de Término menor que a Data de Reporte do Local de Estoque de Ordem ${
              i + 1
            }!`
          );
          formRef.current?.setFieldError(
            'dataHoraTermino',
            'Data e Hora de Término menor que a Data de Reporte!'
          );
        }
      }

      for (let i = 0; i < listaOrdemProducaoItem.length; i++) {
        const ordemProducaoItem = listaOrdemProducaoItem[i];

        if (ordemProducaoItem.listaOrdemProducaoItemLocalEstoque) {
          for (
            let j = 0;
            j < ordemProducaoItem.listaOrdemProducaoItemLocalEstoque.length;
            j++
          ) {
            const ordemProducaoItemLocalEstoque =
              ordemProducaoItem.listaOrdemProducaoItemLocalEstoque[j];

            if (
              dataHoraInicio >
              new Date(ordemProducaoItemLocalEstoque.dataHoraConsumo)
            ) {
              listaErro.push(
                `Data e Hora de Início maior que a Data de Consumo do Item de Ordem ${ordemProducaoItem.ordem}!`
              );
              formRef.current?.setFieldError(
                'dataHoraInicio',
                'Data e Hora de Início maior que a Data de Consumo!'
              );
            }

            if (
              dataHoraTermino &&
              dataHoraTermino.EhMenorDataDesconsiderandoSegundos(
                new Date(
                  ordemProducaoItemLocalEstoque.dataHoraConsumo
                ).SetarSegundosMilisegundosZero()
              )
            ) {
              listaErro.push(
                `Data e Hora de Término menor que a Data de Consumo do Item de Ordem ${ordemProducaoItem.ordem}!`
              );
              formRef.current?.setFieldError(
                'dataHoraTermino',
                'Data e Hora de Término menor que a Data de Consumo!'
              );
            }
          }
        }
      }

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleValidarTotais = useCallback(
    async (data: any): Promise<boolean> => {
      const listaErro: string[] = [];
      const { listaOrdemProducaoLocalEstoque, quantidade } = data;

      const somaQuantidadeLocalEstoque = listaOrdemProducaoLocalEstoque.reduce(
        (acumulador: number, item: IOrdemProducaoLocalEstoqueLista) => {
          acumulador += Number(item.quantidade) || 0;
          return acumulador;
        },
        0
      );
      if (
        somaQuantidadeLocalEstoque !== quantidade &&
        listaOrdemProducaoLocalEstoque.length > 0
      ) {
        listaErro.push(
          'A Quantidade dos Locais de Estoque é Diferente da Quantidade'
        );
      }

      if (listaErro.length > 0) {
        TratarErros({ listaMensagem: listaErro });
        return false;
      }

      return true;
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';
        if (!(await handleValidarTotais(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!(await handleValidarHorasDatas(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await OrdemProducaoComunicador.update({ id, params: data });
        } else {
          const response = await OrdemProducaoComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);

        return { id: '', erro: true };
      }
    },
    [
      getIdDetalheRegistro,
      handleValidar,
      handleValidarHorasDatas,
      handleValidarTotais,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
