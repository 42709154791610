import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa-auto-complete';

interface ITerminalCaixaAutoCompleteComunicadorIndex {
  params: any;
}

class TerminalCaixaAutoCompleteComunicador {
  public async index(
    params: ITerminalCaixaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TerminalCaixaAutoCompleteComunicador();
