import React from 'react';
import ItemHook from './ListaDetalheItem/Hooks';
import ListaDetalheItem from './ListaDetalheItem/Lista';

const ItensTab: React.FC = () => {
  return (
    <>
      <ItemHook>
        <ListaDetalheItem name="listaOrdemProducaoItem" />
      </ItemHook>
    </>
  );
};

export default ItensTab;
