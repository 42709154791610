import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-nota-fiscal-saida/importar';

interface INotaFiscalEntradaImportacaoNotaFiscalSaidaImportarComunicadorStore {
  params: any;
}

class NotaFiscalEntradaImportacaoNotaFiscalSaidaImportarComunicador {
  public async store(
    params: INotaFiscalEntradaImportacaoNotaFiscalSaidaImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoNotaFiscalSaidaImportarComunicador();
