"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EnumObterValue;
/* eslint-disable no-restricted-syntax */
function EnumObterValue(enumObj, enumOpc) {
    for (const k of Object.keys(enumObj)) {
        if (enumObj[k] === enumOpc)
            return k;
    }
    return '';
}
