import api from '../../../../../Comum/Services/Api';

const rota = '/configuracao/personalizacao-lista';

interface IPersonalizacaoListaCamposComunicadorIndex {
  params: {
    nomeTabela: string;
  };
}

interface IPersonalizacaoListaCamposComunicadorUpdate {
  id: string;
  params: {
    titulo: string;
    nomeCampo: string;
    arrastavel: boolean;
    visivel: boolean;
    ordenar: boolean;
    tamanho: number | null;
  };
}

class PersonalizacaoListaCamposComunicador {
  public async index(
    params: IPersonalizacaoListaCamposComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async update(
    params: IPersonalizacaoListaCamposComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new PersonalizacaoListaCamposComunicador();
