import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import InputCheckbox from '../../../../../../../Componentes/Inputs/InputCheckbox';

const GeralTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Row style={{ marginBottom: '10px' }}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Disponível no Ecommerce"
                  name="produtoEcommerce.disponivelEcommerce"
                />
              </div>
            </Row>

            <Row style={{ marginBottom: '10px' }}>
              <Textarea
                label="Descricão (Resumida)"
                name="produtoEcommerce.descricaoResumida"
                maxLength={500}
              />
            </Row>

            <Row>
              <Textarea
                label="Descricão (Completa)"
                name="produtoEcommerce.descricaoCompleta"
                maxLength={4800}
              />
            </Row>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default GeralTab;
