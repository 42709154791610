"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoSinteticaAnaliticaEnum = void 0;
/* eslint-disable no-shadow */
var TipoSinteticaAnaliticaEnum;
(function (TipoSinteticaAnaliticaEnum) {
    TipoSinteticaAnaliticaEnum["sintetica"] = "Sint\u00E9tica (Totalizadora)";
    TipoSinteticaAnaliticaEnum["analitica"] = "Anal\u00EDtica";
})(TipoSinteticaAnaliticaEnum || (exports.TipoSinteticaAnaliticaEnum = TipoSinteticaAnaliticaEnum = {}));
exports.default = TipoSinteticaAnaliticaEnum;
