import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/obter-liberacoes';

interface IUsuarioLiberacoesComunicadorShow {
  id: string;
}

class UsuarioLiberacoesComunicador {
  public async show(params: IUsuarioLiberacoesComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new UsuarioLiberacoesComunicador();
