import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  Table,
  Modal,
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io/index.mjs';
import {
  FormatarDataParaPtBr,
  IFrenteCaixaPdvFinalizarVendaPagamentoLista,
  IFrenteCaixaPdvFinalizarVendaParcelaLista,
  MeioPagamentoEnum,
  ObterParcelasCondicaoPagamento,
  RateioComCasaDecimalCalculo,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import BtnCloseButton from '../../../../../../../../../Componentes/Buttons/BtnCloseButton';
import ItemParcela, { IItemParcelaRef } from './ItemParcela';
import { Tabela } from '../../../../../../../../../Componentes/Tabela/styles';
import TratarErros from '../../../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../../../Componentes/LoadingDiv';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';
import InputDecimal from '../../../../../../../../../Componentes/Inputs/InputDecimal';
import { Divisor } from '../../../../../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import InputLabel from '../../../../../../../../../Componentes/Inputs/InputLabel';
import InputInteiro from '../../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDate from '../../../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteCondicaoPagamentoSugestao from '../../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCondicaoPagamentoSugestao';
import InputAutoCompletePortador from '../../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import BtnPadraoButton from '../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { Sleep } from '../../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import GetValidationErrors from '../../../../../../../../../Util/Erro/GetValidationErrors';
import InputHiddenHtml from '../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import RegraEscolhaPortadorObterConformeCriteriosComunicador from '../../../../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorObterConformeCriteriosComunicador';
import Input from '../../../../../../../../../Componentes/Inputs/Input';

interface IParcelasContext {
  setTitulo(titulo: string): void;
  abrirParcelas(): Promise<void>;
  carregarDados(
    pagamento: IFrenteCaixaPdvFinalizarVendaPagamentoLista
  ): Promise<{
    pagamento: IFrenteCaixaPdvFinalizarVendaPagamentoLista;
  }>;
  fecharParcelas(): void;
}

const ParcelasContext = createContext<IParcelasContext>({} as IParcelasContext);

const ParcelasHook: React.FC<IPadraoProps> = ({ children }) => {
  const { telaGrande, telaPequena } = UseReactSizeMeBodyHook();
  const formRef = useRef<IFormCiaHandles>(null);

  const resolveRef = useRef<any>();
  const listaItemParcelaRef = useRef<Array<IItemParcelaRef | null>>([]);

  const [listaValor, setListaValor] = useState<
    IFrenteCaixaPdvFinalizarVendaParcelaLista[]
  >([]);
  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const permiteAlterar = formRef.current?.getFieldValue('permiteAlterar');

  // const permiteAlterarQuantidadeParcelas = formRef.current?.getFieldValue(
  //   'permiteAlterarQuantidadeParcelas'
  // );

  const permiteGerarParcelas = formRef.current?.getFieldValue(
    'permiteGerarParcelas'
  );

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleCarregarDados = useCallback(
    async (
      pagamento: IFrenteCaixaPdvFinalizarVendaPagamentoLista
    ): Promise<any> => {
      let permiteAlterarCampos = true;
      if (
        pagamento.formaPagamento.meioPagamento !==
          MeioPagamentoEnum.boletoBancario &&
        pagamento.formaPagamento.meioPagamento !==
          MeioPagamentoEnum.creditoLoja &&
        pagamento.formaPagamento.meioPagamento !==
          MeioPagamentoEnum.pagamentoInstantaneoPix
      ) {
        permiteAlterarCampos = false;
      }

      // let permiteAlterarQuantidadeParcelasCampos = true;
      // if (
      //   pagamento.formaPagamento.meioPagamento !==
      //     MeioPagamentoEnum.boletoBancario &&
      //   pagamento.formaPagamento.meioPagamento !==
      //     MeioPagamentoEnum.cartaoCredito &&
      //   pagamento.formaPagamento.meioPagamento !==
      //     MeioPagamentoEnum.creditoLoja &&
      //   pagamento.formaPagamento.meioPagamento !==
      //     MeioPagamentoEnum.pagamentoInstantaneoPix
      // ) {
      //   permiteAlterarQuantidadeParcelasCampos = false;
      // }

      setListaValor([]);
      await Sleep(50);
      setListaValor(pagamento.listaParcelas);
      formRef.current?.setDataInicial({
        ...pagamento,
        permiteGerarParcelas: pagamento.formaPagamento.permiteGerarParcelas,
        permiteAlterar: permiteAlterarCampos,
        // permiteAlterarQuantidadeParcelas:
        //   permiteAlterarQuantidadeParcelasCampos,
      });

      const idPortador = formRef.current?.getFieldValue('idPortador');
      if (!idPortador) {
        const response =
          await RegraEscolhaPortadorObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: pagamento.dataHoraEmissao,
              idFormaPagamento: pagamento.formaPagamento.id,
            },
          });

        if (response && response.regraEscolhaPortador) {
          formRef.current?.setFieldValue('portador', {
            id: response.regraEscolhaPortador.idPortador,
            descricao: response.regraEscolhaPortador.descricao,

            portadorBoleto: {
              utilizaBoleto: response.regraEscolhaPortador.utilizaBoleto,
            },
          });
        }
      }

      refresh();
      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [refresh]
  );

  const handleAbrir = useCallback(async (): Promise<void> => {
    setAberto(true);
  }, []);

  const handleFechar = useCallback(() => {
    setListaValor([]);
    setAberto(false);

    resolveRef.current(null);
  }, []);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idPortador: Yup.string().nullable().required('Portador é obrigatória!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSalvar = useCallback(async () => {
    let data = formRef.current?.getData();
    const condicaoPagamento =
      formRef.current?.getFieldValueNomeObjeto('condicaoPagamento');
    const portador = formRef.current?.getFieldValueNomeObjeto('portador');

    data = { ...data, condicaoPagamento, portador };

    const listaItemParcela: IFrenteCaixaPdvFinalizarVendaParcelaLista[] = [];
    for (let i = 0; i < listaItemParcelaRef.current?.length; i++) {
      const itemParcelaRef = listaItemParcelaRef.current[i];
      if (itemParcelaRef) {
        listaItemParcela.push(itemParcelaRef?.handleObterValores());
      }
    }

    const listaMensagem: string[] = [];
    if (!listaItemParcela || listaItemParcela.length === 0) {
      listaMensagem.push(`Obrigatório informar ao menos uma Parcela!`);
    } else {
      for (let i = 0; i < listaItemParcela.length; i++) {
        const itemParcela = listaItemParcela[i];

        if (itemParcela && !itemParcela.dataVencimento) {
          listaMensagem.push(
            `Data de Vencimento: ${itemParcela.sequencia}/${itemParcela.quantidadeParcelas} é obrigatória!`
          );
        }

        if (itemParcela && Number(itemParcela.valor).Arredondar() === 0) {
          listaMensagem.push(
            `Valor da Parcela: ${itemParcela.sequencia}/${itemParcela.quantidadeParcelas} deve se maior que Zero!`
          );
        }
      }

      const valorParcelas = listaItemParcela?.reduce(
        (acumulador: number, item: any) => {
          acumulador += Number(item.valor);
          return acumulador;
        },
        0
      );

      if (
        Number(valorParcelas).Arredondar() !== Number(data.valor).Arredondar()
      ) {
        listaMensagem.push(
          `A soma dos Valores das Parcelas deve ser o mesmo do Valor!`
        );
      }
    }

    if (!(await handleValidar(data)) || listaMensagem.length > 0) {
      TratarErros({ listaMensagem });
      return;
    }

    setAberto(false);
    if (resolveRef.current) {
      resolveRef.current({
        pagamento: {
          ...data,
          listaParcelas: listaItemParcela,
        },
      });
    }
  }, [handleValidar]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  // #region Item

  const handleAdicionarItem = useCallback(async (): Promise<void> => {
    let dataPrimeiraParcela = formRef.current?.getFieldValue(
      'dataPrimeiraParcela'
    );
    if (!dataPrimeiraParcela) {
      dataPrimeiraParcela = formRef.current?.getFieldValue('dataHoraEmissao');
    }

    const dataVencimento = dataPrimeiraParcela.AddDays(30 * listaValor.length);

    const parcelaItem: IFrenteCaixaPdvFinalizarVendaParcelaLista = {
      sequencia: listaValor.length + 1,
      quantidadeParcelas: listaValor.length + 1,
      dataVencimento,
      valor: 0,
    };

    const novaLista = [...listaValor, parcelaItem];
    const quantidadeParcelas = novaLista.length;

    for (let i = 0; i < novaLista.length; i++) {
      const item = novaLista[i];
      item.quantidadeParcelas = quantidadeParcelas;
    }

    setListaValor(novaLista);
    formRef.current?.setFieldValorInicialSemExecutarEvento(
      'quantidadeParcelas',
      quantidadeParcelas
    );
  }, [listaValor]);

  const handleRemoverItem = useCallback(
    async (index: number): Promise<void> => {
      listaValor.splice(Number(index), 1);
      const novaLista = [...listaValor];

      const quantidadeParcelas = novaLista.length;
      for (let i = 0; i < novaLista.length; i++) {
        const item = novaLista[i];
        item.sequencia = i + 1;
        item.quantidadeParcelas = quantidadeParcelas;
      }

      setListaValor([...novaLista]);
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'quantidadeParcelas',
        quantidadeParcelas
      );
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'condicaoPagamento',
        null
      );
    },
    [listaValor]
  );

  // #endregion Item

  const handleAtualizarParcelasConformeCondicaoPagamento =
    useCallback(async () => {
      let listaContaParcelaNova: IFrenteCaixaPdvFinalizarVendaParcelaLista[] =
        [];

      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');
      let valor = formRef.current?.getFieldValue('valor');
      const condicaoPagamento =
        formRef.current?.getFieldValue('condicaoPagamento');
      const valorPrimeiraParcela = formRef.current?.getFieldValue(
        'valorPrimeiraParcela'
      );

      if (!dataHoraEmissao || !valor || valor <= 0 || !condicaoPagamento) {
        return;
      }

      const listaRetornoParcelas = ObterParcelasCondicaoPagamento(
        Number(valor),
        dataHoraEmissao,
        condicaoPagamento
      );

      const listaParcelaRateios: number[] = [];
      let incioParcelas = 0;
      if (valorPrimeiraParcela > 0) {
        valor -= valorPrimeiraParcela;
        incioParcelas = 1;
      }

      for (let i = incioParcelas; i < listaRetornoParcelas.length; i++) {
        listaParcelaRateios.push(0);
      }

      const listaValorRateio = RateioComCasaDecimalCalculo({
        valor,
        valores: listaParcelaRateios,
        casasDecimais: 2,
      });

      if (valorPrimeiraParcela > 0) {
        listaValorRateio.unshift(valorPrimeiraParcela);
      }

      if (listaRetornoParcelas.length > 0) {
        listaContaParcelaNova = listaRetornoParcelas.map((item, index) => {
          const parcelaItem: IFrenteCaixaPdvFinalizarVendaParcelaLista = {
            sequencia: index + 1,
            quantidadeParcelas: listaRetornoParcelas.length,
            dataVencimento: item.data,
            valor: listaValorRateio[index],
          };

          return parcelaItem;
        });

        setListaValor([]);
        await Sleep(50);
        setListaValor(listaContaParcelaNova);

        formRef.current?.setFieldValorInicialSemExecutarEvento(
          'quantidadeParcelas',
          listaRetornoParcelas.length
        );
      }
    }, []);

  const handleAtualizarParcelasConformQuantidadeParcelas =
    useCallback(async () => {
      const listaParcelaRateios: number[] = [];

      let valor = formRef.current?.getFieldValue('valor');
      const quantidadeParcelas =
        formRef.current?.getFieldValue('quantidadeParcelas');

      let dataPrimeiraParcela = formRef.current?.getFieldValue(
        'dataPrimeiraParcela'
      );
      if (!dataPrimeiraParcela) {
        dataPrimeiraParcela = formRef.current?.getFieldValue('dataHoraEmissao');
      }

      const valorPrimeiraParcela = formRef.current?.getFieldValue(
        'valorPrimeiraParcela'
      );

      let incioParcelas = 0;
      if (valorPrimeiraParcela > 0) {
        valor -= valorPrimeiraParcela;
        incioParcelas = 1;
      }

      for (let i = incioParcelas; i < quantidadeParcelas; i++) {
        listaParcelaRateios.push(0);
      }

      const listaValorRateio = RateioComCasaDecimalCalculo({
        valor,
        valores: listaParcelaRateios,
        casasDecimais: 2,
      });

      if (valorPrimeiraParcela > 0) {
        listaValorRateio.unshift(valorPrimeiraParcela);
      }

      const novaLista: IFrenteCaixaPdvFinalizarVendaParcelaLista[] = [];
      for (let i = 0; i < quantidadeParcelas; i++) {
        novaLista.push({
          sequencia: i + 1,
          quantidadeParcelas,
          dataVencimento: dataPrimeiraParcela.AddDays(30 * i),
          valor: listaValorRateio[i],
        });
      }

      setListaValor([]);
      await Sleep(50);
      setListaValor(novaLista);
    }, []);

  const handleOnAlterarQuantidadeParcelas = useCallback(async () => {
    formRef.current?.setFieldValorInicialSemExecutarEvento(
      'condicaoPagamento',
      null
    );

    await handleAtualizarParcelasConformQuantidadeParcelas();
  }, [handleAtualizarParcelasConformQuantidadeParcelas]);

  const handleOnAlterarDataPrimeiraParcela = useCallback(async () => {
    const condicaoPagamento =
      formRef.current?.getFieldValue('condicaoPagamento');

    if (condicaoPagamento) {
      await handleAtualizarParcelasConformeCondicaoPagamento();
    } else {
      await handleAtualizarParcelasConformQuantidadeParcelas();
    }
  }, [
    handleAtualizarParcelasConformQuantidadeParcelas,
    handleAtualizarParcelasConformeCondicaoPagamento,
  ]);

  const handleOnAlterarValorPrimeiraParcela = useCallback(async () => {
    const condicaoPagamento =
      formRef.current?.getFieldValue('condicaoPagamento');

    if (condicaoPagamento) {
      await handleAtualizarParcelasConformeCondicaoPagamento();
    } else {
      await handleAtualizarParcelasConformQuantidadeParcelas();
    }
  }, [
    handleAtualizarParcelasConformQuantidadeParcelas,
    handleAtualizarParcelasConformeCondicaoPagamento,
  ]);

  return (
    <ParcelasContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirParcelas: handleAbrir,
        carregarDados: handleCarregarDados,
        fecharParcelas: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <FormCia ref={formRef}>
          <InputHiddenHtml name="permiteAlterar" />
          <InputHiddenHtml name="permiteAlterarQuantidadeParcelas" />
          <InputHiddenHtml name="permiteGerarParcelas" />

          <Modal.Header
            style={{
              background: '#3397c4',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Modal.Title>{titulo}</Modal.Title>
            <BtnCloseButton type="button" onClick={handleFechar}>
              <IoIosCloseCircleOutline />
            </BtnCloseButton>
          </Modal.Header>

          <Modal.Body style={{ minHeight: 610 }}>
            <Divisor>
              <Row>
                <Col lg={2} md={6} sm={6}>
                  <InputLabel
                    label="Data Emissão"
                    name="dataHoraEmissao"
                    formatarValor={(value) => {
                      return <div>{FormatarDataParaPtBr(value)}</div>;
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={6}>
                  <InputLabel
                    label="Valor"
                    name="valor"
                    formatarValor={(value) => {
                      return <div>{Number(value).FormatarParaPtBr()}</div>;
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <InputInteiro
                    label="Qtde. Parcelas"
                    name="quantidadeParcelas"
                    placeholder="Qtde. Parcelas"
                    onChange={handleOnAlterarQuantidadeParcelas}
                    disabled={
                      // !permiteAlterarQuantidadeParcelas ||
                      !permiteGerarParcelas
                    }
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDate
                    label="Data 1º Parcela"
                    name="dataPrimeiraParcela"
                    placeholder="Data 1º Parcela"
                    onChange={handleOnAlterarDataPrimeiraParcela}
                    disabled={!permiteAlterar}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Val. 1º Parcela"
                    name="valorPrimeiraParcela"
                    placeholder="Val. 1º Parcela"
                    casasDecimais={2}
                    onChange={handleOnAlterarValorPrimeiraParcela}
                    disabled={!permiteAlterar}
                  />
                </Col>
              </Row>

              <Row>
                <OverlayTrigger
                  placement={telaPequena ? 'top' : 'bottom'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Popover
                      style={{ width: telaPequena ? 'auto' : 225 }}
                      id={v4()}
                    >
                      <Popover.Header>
                        <strong>Exemplos:</strong>
                      </Popover.Header>
                      <Popover.Body>
                        <ul>
                          <li>A VISTA</li>
                          <li>A VISTA/28/56 DIAS</li>
                          <li>15/30 DIAS</li>
                          <li>10 PARCELAS A CADA 20 DIAS</li>
                          <li>10 PARCELAS (Serão geradas a cada 30 dias)</li>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Col lg={4} md={6} sm={12}>
                    <InputAutoCompleteCondicaoPagamentoSugestao
                      label="Condição de Pagamento"
                      name="condicaoPagamento"
                      placeholder="Condição de Pagamento"
                      maxLength={200}
                      onBlur={async () => {
                        await handleAtualizarParcelasConformeCondicaoPagamento();
                      }}
                      disabled={!permiteAlterar}
                    />
                  </Col>
                </OverlayTrigger>

                <Col lg={5} md={6} sm={12}>
                  <InputAutoCompletePortador
                    label="Portador"
                    name="idPortador"
                    nomeObjeto="portador"
                    placeholder="Portador"
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <Input
                    label="Autorização"
                    name="autorizacao"
                    placeholder="Autorização"
                    maxLength={70}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <Row>
                <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
                  <BtnPadraoButton
                    style={{ maxWidth: telaGrande ? 250 : '' }}
                    type="button"
                    className="btn-padrao btn-verde btn-adicionar"
                    onClick={handleAdicionarItem}
                    disabled={loading || !permiteAlterar}
                  >
                    <TextoLoading loading={loading}>
                      <IoMdAddCircleOutline />
                      <span style={{ marginLeft: 10 }}>Adicionar</span>
                    </TextoLoading>
                  </BtnPadraoButton>
                </Col>
              </Row>

              <Tabela style={{ overflow: 'auto', height: 310 }}>
                <Table striped bordered variant="light">
                  <thead>
                    <tr>
                      <th className="lista-valor" style={{ width: '20%' }}>
                        <span>Parcela</span>
                      </th>
                      <th className="lista-data" style={{ width: '50%' }}>
                        <span>Data Vencimento</span>
                      </th>
                      <th className="lista-valor" style={{ width: '30%' }}>
                        <span>Val. da Parcela</span>
                      </th>
                      <th className="lista-acoes">
                        <span>Ações</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listaValor &&
                      listaValor.map(
                        (
                          item: IFrenteCaixaPdvFinalizarVendaParcelaLista,
                          index
                        ) => {
                          return (
                            <ItemParcela
                              item={item}
                              key={item.sequencia}
                              ref={(instance) => {
                                listaItemParcelaRef.current[index] = instance;
                              }}
                              onRemoverItem={() => {
                                handleRemoverItem(index);
                              }}
                              permiteAlterar={permiteAlterar}
                              loading={loading}
                              setLoading={setLoading}
                              refresh={refresh}
                            />
                          );
                        }
                      )}
                  </tbody>
                </Table>
              </Tabela>
            </Divisor>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={handleFechar}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <MdCancel />
                    <span style={{ marginLeft: 10 }}>Cancelar</span>
                  </span>
                </button>

                <Button
                  style={{
                    fontWeight: 'bold',
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type="button"
                  onClick={handleSalvar}
                >
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </Button>
              </Container>
            </div>
          </Modal.Footer>
        </FormCia>
      </Modal>

      {children}
    </ParcelasContext.Provider>
  );
};

function UseParcelas(): IParcelasContext {
  const context = useContext(ParcelasContext);

  if (!context) {
    throw new Error('UseParcelas deve ser usado com um OrcamentoProvider');
  }

  return context;
}

export { ParcelasHook, UseParcelas };
