import { BooleanEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/obter-nota-fiscal-entrada-item';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicadorIndex {
  idPessoa: string;
  movimentarEstoqueFiscal?: BooleanEnum;
  listaIdNotaFiscalEntrada: string[];
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicador();
