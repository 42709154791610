import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/geracao-envio-boletos';

interface IGeracaoEnvioBoletosComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoDocumento?: string;
  };
}

interface IGeracaoEnvioBoletosComunicadorUpdate {
  params: any;
}

class GeracaoEnvioBoletosComunicador {
  public async index(
    params: IGeracaoEnvioBoletosComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async update(
    params: IGeracaoEnvioBoletosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new GeracaoEnvioBoletosComunicador();
