import React, { useEffect, useCallback, useState } from 'react';
import {
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  FormatarEnum,
  TipoProdutoServicoEnum,
  IProdutoValoresAlterar,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteGrupoProdutoServico from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteGrupoProdutoServico';
import InputAutoCompleteUnidadeMedida from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch/index';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import FiscalTab from '../FiscalTab/index';
import EngenhariaTab from '../EngenhariaTab/index';
import EstoqueTab from '../EstoqueTab/index';
import ComercialTab from '../ComercialTab/index';
import TagsTab from '../TagsTab';
import CustoTab from '../CustoTab';
import Detalhe from '../PadronizacaoDescricaoAtributoDetalhe/Detalhe';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import EcommerceTab from '../EcommerceTab';
import VeiculoTab from '../VeiculoTab';

type IProps = IMain<IProdutoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosProduto: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const parametros = UseParametros();
  const [showFormModal, setShowFormModal] = useState(false);

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const [tabSelecionada, setTabSelecionada] = useState('fiscal');

  const isProduto =
    formRef.current?.getFieldValue('tipoProdutoServico') === 'Produto';

  const listaProdutoPadronizacaoDescricaoAtributo =
    formRef.current?.getFieldValue('listaProdutoPadronizacaoDescricaoAtributo');
  let isProdutoPadronizacaoDescricaoAtributo = false;
  if (listaProdutoPadronizacaoDescricaoAtributo) {
    isProdutoPadronizacaoDescricaoAtributo =
      listaProdutoPadronizacaoDescricaoAtributo.length > 0;
  }
  useEffect(() => {
    if (!isProduto) {
      setTabSelecionada((state) => {
        if (state === 'engenharia' || state === 'estoque') return 'fiscal';

        return state;
      });
    }
  }, [isProduto]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleAbrirModal = useCallback(async (): Promise<void> => {
    setShowFormModal(true);
  }, []);

  const handleSalvarFormModal = useCallback((): void => {
    setShowFormModal(false);
  }, []);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    setShowFormModal(false);
  }, []);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />
      <div>
        <Divisor>
          <Row>
            <Col lg={3} md={12} sm={12}>
              <Input
                label="Código"
                name="codigo"
                placeholder="Código"
                maxLength={60}
              />
            </Col>

            <Col lg={6} md={12} sm={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Input
                  ref={inputRefFocus}
                  disabled={isProdutoPadronizacaoDescricaoAtributo}
                  label="Descrição"
                  name="descricao"
                  placeholder="Descrição"
                  maxLength={120}
                />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={v4()}>
                      {isProdutoPadronizacaoDescricaoAtributo
                        ? 'Editar'
                        : 'Adicionar'}
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      marginLeft: 10,
                      marginBottom: 5,
                      marginTop: 25,
                      height: 35,
                      width: 48,
                      background: `${
                        isProdutoPadronizacaoDescricaoAtributo
                          ? '#483D8B	'
                          : '#007bff'
                      }`,
                      borderColor: `${
                        isProdutoPadronizacaoDescricaoAtributo
                          ? '#483D8B	'
                          : '#007bff'
                      }`,
                    }}
                    onClick={handleAbrirModal}
                  >
                    PD
                  </Button>
                </OverlayTrigger>
              </div>
            </Col>

            <Col lg={3} md={12} sm={12}>
              <Select
                label="Tipo"
                name="tipoProdutoServico"
                options={FormatarEnum({
                  enumObj: TipoProdutoServicoEnum,
                })}
                onChange={(event) => {
                  refresh();
                  if (
                    event.currentTarget.value === TipoProdutoServicoEnum.produto
                  ) {
                    formRef.current?.setFieldValue(
                      'produtoCusto.tipoCusto',
                      parametros.TipoCustoPadraoNovosProdutos
                    );
                    formRef.current?.setFieldValue(
                      'produtoCusto.tipoCalculoCustoBlocoH',
                      parametros.TipoCalculoCustoBlocoHNovosProdutos
                    );
                  }
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={12} sm={12}>
              <InputAutoCompleteUnidadeMedida
                label="Unidade de Medida"
                name="produtoEstoque.idUnidadeMedidaEstoque"
                placeholder="Unidade de Medida"
                nomeObjeto="produtoEstoque.unidadeMedida"
              />
            </Col>
            <Col lg={3} md={12} sm={12}>
              <InputDecimalNulavel
                label="Preço de Venda"
                name="produtoComercial.valorUnitarioVenda"
                placeholder="Preço de Venda"
                casasInteiras={11}
                casasDecimais={10}
              />
            </Col>
            <Col lg={5} md={12} sm={12}>
              <InputAutoCompleteGrupoProdutoServico
                label="Grupo"
                name="idGrupoProdutoServico"
                placeholder="Grupo"
                nomeObjeto="grupoProdutoServico"
                utilizavelProdutoServico
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="fiscal"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="fiscal" title="Fiscal">
            <FiscalTab />
          </Tab>

          <Tab
            eventKey="engenharia"
            title="Engenharia"
            tabClassName={isProduto ? '' : 'hidden'}
          >
            <EngenhariaTab />
          </Tab>

          <Tab
            eventKey="estoque"
            title="Estoque"
            tabClassName={isProduto ? '' : 'hidden'}
          >
            <EstoqueTab />
          </Tab>

          <Tab
            eventKey="custo"
            title="Custo"
            tabClassName={isProduto ? '' : 'hidden'}
          >
            <CustoTab />
          </Tab>

          <Tab eventKey="comercial" title="Comercial">
            <ComercialTab />
          </Tab>

          {/* <Tab eventKey="fotos" title="Fotos">
            <ComercialTab />
          </Tab> */}

          <Tab eventKey="tags" title="Tags">
            <TagsTab />
          </Tab>

          <Tab eventKey="ecommerce" title="E-commerce">
            <EcommerceTab />
          </Tab>

          <Tab eventKey="veiculo" title="Veículos Novos">
            <VeiculoTab />
          </Tab>
        </Tabs>
      </div>
      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
      <InputHiddenHtml name="listaProdutoPadronizacaoDescricaoAtributo" />
      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
        />
      )}
    </FormCia>
  );
};

export default Main;
