import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/aliquota-credito';

interface IEmpresaVigenciaAliquotaCreditoSnComunicadorShow {
  id: string;
}

interface IEmpresaVigenciaAliquotaCreditoSnComunicadorStore {
  params: any;
}

interface IEmpresaVigenciaAliquotaCreditoSnComunicadorUpdate {
  id: string;
  params: any;
}

interface IEmpresaVigenciaAliquotaCreditoSnComunicadorDelete {
  id: string;
}

class EmpresaVigenciaAliquotaCreditoSnComunicador {
  public async show(
    params: IEmpresaVigenciaAliquotaCreditoSnComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IEmpresaVigenciaAliquotaCreditoSnComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}`, params.params);

    return response.data;
  }

  public async update(
    params: IEmpresaVigenciaAliquotaCreditoSnComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IEmpresaVigenciaAliquotaCreditoSnComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EmpresaVigenciaAliquotaCreditoSnComunicador();
