"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = RateioFretePorDentroComIpiObterValorItemSemIpiCalculo;
const calcularValores = (items, valorFreteGeral, iteracao = 0) => {
    let totalValorX = 0;
    // Calcular os valores iniciais de x
    items.forEach((item) => {
        const ipiFactor = item.aliquotaIpi / 100;
        const valorX = (item.valorAtingir - (item.proporcaoFrete || 0) * ipiFactor) /
            (1 + ipiFactor);
        item.valorXTotalItem = valorX;
        item.valorXUnitarioItem = valorX / item.quantideItem;
        totalValorX += valorX;
    });
    let convergiu = true;
    let novoTotalValorX = 0;
    const precisao = 0.00001;
    const maxIteracoes = 1000;
    // Calcular a proporção do frete para cada item e ajustar os valores
    items.forEach((item) => {
        const valorX = item.valorXTotalItem || 0;
        const proporcaoFrete = (valorX / totalValorX) * valorFreteGeral;
        const valorComFrete = valorX + proporcaoFrete;
        const valorIPI = valorComFrete * (item.aliquotaIpi / 100);
        const valorFinal = valorComFrete + valorIPI;
        novoTotalValorX += valorX;
        item.proporcaoFrete = proporcaoFrete;
        item.valorIPI = valorIPI;
        item.valorFinal = valorFinal;
        if (Math.abs(valorFinal - item.valorAtingir) > precisao) {
            convergiu = false;
        }
    });
    if (!convergiu && iteracao < maxIteracoes) {
        return calcularValores(items, valorFreteGeral, iteracao + 1);
    }
    return items;
};
function RateioFretePorDentroComIpiObterValorItemSemIpiCalculo(dados) {
    const resultado = calcularValores(dados.listaValores, dados.valorTotalFrete);
    return resultado;
}
