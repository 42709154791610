import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoReprocessamentoProducao from './Impressao/Template/ReprocessamentoProducaoImpressao';
import ReprocessamentoProducaoImpressaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/ReprocessamentoProducao/Impressao/ReprocessamentoProducaoImpressaoComunicador';

const AcoesReprocessamentoProducao: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, loading, setLoading, getIdDetalheRegistro, handleSubmit } =
    UseForm();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      const data: any = formRef.current?.getData();

      if (formRef.current?.validarSeAlterou()) {
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const id = getIdDetalheRegistro();
      if (!id) return;

      const response = await ReprocessamentoProducaoImpressaoComunicador.show({
        id,
      });
      await ImpressaoReprocessamentoProducao.handleVisualizarPdf(response);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  return (
    <SplitAcoes
      id={v4()}
      disabled={loading || !idRegistroDetalhe}
      className="btn-group"
      title={
        <div>
          <TextoLoading loading={loading}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item disabled={loading} onClick={handleImprimir}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <AiOutlinePrinter style={{ marginRight: 5 }} />
          Imprimir
        </span>
      </Dropdown.Item>
    </SplitAcoes>
  );
};

export default AcoesReprocessamentoProducao;
