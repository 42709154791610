"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = SepararCnpjCpf;
function SepararCnpjCpf(cnpjCpf) {
    let cnpj = '';
    let cpf = '';
    if (cnpjCpf) {
        if (cnpjCpf.length === 11) {
            cpf = cnpjCpf;
        }
        if (cnpjCpf.length === 14) {
            cnpj = cnpjCpf;
        }
    }
    return [cnpj, cpf];
}
