import React, { useCallback } from 'react';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { Dropdown } from 'react-bootstrap';
import { BiMailSend } from 'react-icons/bi/index.mjs';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseAlerta } from '../../../../../Componentes/Alerta/HooksAlerta';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ContaEmailReenviarConfirmacaoAwsComunicador from '../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailReenviarConfirmacaoAwsComunicador';

const AcoesContaEmail: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { loading, formRef, setLoading, getIdDetalheRegistro } = UseForm();
  const { abrirJanela } = UseAlerta();

  const verificado = formRef.current?.getFieldValue('verificado');
  const idRegistro = getIdDetalheRegistro();
  const handleReenviarEmailConfirmacao = useCallback(async () => {
    try {
      const id = getIdDetalheRegistro();
      if (!id) return;
      const email = formRef.current?.getFieldValue('email');

      setLoading(true);

      await ContaEmailReenviarConfirmacaoAwsComunicador.update({
        id: String(id),
        params: {
          email: String(email),
        },
      });

      setLoading(false);

      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Você recebera um email de confirmação!
          </span>
        ),
      });
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, formRef, getIdDetalheRegistro, setLoading]);

  return (
    <SplitAcoes
      id={v4()}
      className="btn-group"
      title={
        <div>
          <TextoLoading loading={loading}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item
        disabled={loading || verificado || !idRegistro}
        onClick={handleReenviarEmailConfirmacao}
      >
        <span style={{ display: 'flex', fontSize: 16 }}>
          <BiMailSend style={{ marginRight: 5 }} />
          Reenviar e-mail de confirmação
        </span>
      </Dropdown.Item>
    </SplitAcoes>
  );
};

export default AcoesContaEmail;
