import React, { createContext, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IUsuarioTipoContext {
  alterouTipo: boolean;
  setAlterouTipo: React.Dispatch<React.SetStateAction<boolean>>;
}

const UsuarioTipoContext = createContext({} as IUsuarioTipoContext);

const UsuarioTipoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [alterouTipo, setAlterouTipo] = useState(false);

  return (
    <UsuarioTipoContext.Provider
      value={{
        alterouTipo,
        setAlterouTipo,
      }}
    >
      {children}
    </UsuarioTipoContext.Provider>
  );
};

function UseUsuarioTipo(): IUsuarioTipoContext {
  const context = useContext(UsuarioTipoContext);
  return context;
}

export { UsuarioTipoHook, UseUsuarioTipo };
