import { TipoDocumentoManutencaoDocumentoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/documentos-eletronicos/manutencao-documentos-fiscais';

interface IManutencaoDocumentosFiscaisComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoDocumento: TipoDocumentoManutencaoDocumentoEnum;
  };
}

interface IManutencaoDocumentosFiscaisComunicadorShow {
  id: string;
}

interface IManutencaoDocumentosFiscaisComunicadorUpdate {
  id: string;
  params: any;
}

interface IManutencaoDocumentosFiscaisComunicadorStore {
  params: any;
}

interface IManutencaoDocumentosFiscaisComunicadorDelete {
  id: string;
}

class ManutencaoDocumentosFiscaisComunicador {
  public async index(
    params: IManutencaoDocumentosFiscaisComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IManutencaoDocumentosFiscaisComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(
    params: IManutencaoDocumentosFiscaisComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(
    params: IManutencaoDocumentosFiscaisComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IManutencaoDocumentosFiscaisComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new ManutencaoDocumentosFiscaisComunicador();
