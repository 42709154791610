import api from '../../../../Comum/Services/Api';

const rota = 'financeiro/boleto/excluir-baixa';

interface IBoletoExcluirBaixaComunicadorUpdate {
  params: { idBoleto: string };
}

class BoletoExcluirBaixaComunicador {
  public async update(
    params: IBoletoExcluirBaixaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new BoletoExcluirBaixaComunicador();
