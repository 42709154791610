import api from '../../../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida/transmissao/evento/impressao/carta-correcao';

interface INotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow {
  id: string;
}

class NotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicador {
  public async show(
    params: INotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicador();
