import React from 'react';
import { Container, Descricao, Conteudo, DivisorDescricao } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import IParametroTab from './Interface/IParametroTab';

const Parametro: React.FC<IParametroTab> = ({ parametro, visivel }) => {
  return (
    <Container style={{ display: visivel ? 'block' : 'none' }}>
      <>
        <Descricao>
          <Divisor style={{ marginBottom: 15 }}>
            <DivisorDescricao>
              <span>Parâmetro</span>
              <p>{parametro.descricao}</p>
            </DivisorDescricao>
          </Divisor>

          <Divisor>
            <DivisorDescricao>
              <span>Observações</span>

              {parametro.observacao.split('.').length > 2 && (
                <p>{parametro.observacao}</p>
              )}
              {parametro.observacao.split('.').length === 2 && (
                <p>{parametro.observacao}</p>
              )}
            </DivisorDescricao>
          </Divisor>
        </Descricao>

        <Conteudo>
          <Divisor>
            <DivisorDescricao>
              <span>Valor</span>
              {parametro.render()}
            </DivisorDescricao>
          </Divisor>
        </Conteudo>
      </>
    </Container>
  );
};

export default Parametro;
