"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarTelefone;
function FormatarTelefone(value) {
    if (!value)
        return '';
    value = value.replace(/\D/g, '');
    const { length } = value;
    if (length >= 11) {
        value = `(${value.slice(0, 2)}) ${value.slice(2, 3)} ${value.slice(3, -4)}-${value.slice(-4)}`;
    }
    else if (length > 6) {
        value = `(${value.slice(0, 2)}) ${value.slice(2, -4)}-${value.slice(-4)}`;
    }
    else if (length >= 3) {
        value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    }
    return value;
}
