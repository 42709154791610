import api from '../../../../../../Comum/Services/Api';

const rota =
  'manufatura/relatorios/producao/ordem-producao/ordem-producao-resumo-de-produto-produzido-por-local-estoque';

interface IOrdemProducaoResumoProdutoProduzidosPorLocalEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrdemProducaoResumoProdutoProduzidosPorLocalEstoqueRelatorioComunicador {
  public async index(
    params: IOrdemProducaoResumoProdutoProduzidosPorLocalEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrdemProducaoResumoProdutoProduzidosPorLocalEstoqueRelatorioComunicador();
