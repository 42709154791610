import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PesagemComunicador from '../../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Comunicador/PesagemComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const PesagemHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: PesagemComunicador }}>
      <RotasHook>
        <FormHook>
          <SubHeaderHook>{children}</SubHeaderHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default PesagemHook;
