import {
  BooleanEnum,
  FormatarEnum,
  IRegraEscolhaCategoriaValoresAlterar,
  TipoContaEnum,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagModeloDocumento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagFormaPagamento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteCategoria from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCategoria';
import InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputAutoCompleteTagTipoLancamentoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoLancamentoEnum';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

type IProps = IMain<IRegraEscolhaCategoriaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroCategoriaDreRegraEscolhaCategoria: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // Não Remover os await - são nescessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaFormaPagamento')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCategoriaGrupoProdutoServico'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaSped')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaProduto')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaPessoa')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCategoriaModeloDocumento'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue(
        'listaRegraEscolhaCategoriaRamoAtividadeProfissao'
      ).listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaOperacaoFiscal')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaTipoTransacao')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaCategoriaTipoLancamento')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('avista') !== null) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('tipoConta') !== null) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                ref={inputRefFocus}
                label="Data Vigência Incial"
                name="dataInicioVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaCategoriaEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo (Receber / Pagar)"
                name="tipoConta"
                options={FormatarEnum({
                  enumObj: TipoContaEnum,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
              />
            </Col>
            <Col lg={3} md={6} sm={6}>
              <Select
                label="A Vista"
                name="avista"
                options={FormatarEnum({
                  enumObj: BooleanEnum,
                  nullavel: true,
                })}
                onChange={handleAtualizarQuantidadeCriterios}
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <InputAutoCompleteTagTipoLancamentoEnum
                label="Tipos de Lançamento"
                name="listaRegraEscolhaCategoriaTipoLancamento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={6} sm={12}>
              <InputAutoCompleteTagFormaPagamento
                label="Formas de Pagamento"
                name="listaRegraEscolhaCategoriaFormaPagamento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagPessoa
                label="Pessoas"
                name="listaRegraEscolhaCategoriaPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagRamoAtividadeProfissao
                label="Ramos de Atividade"
                name="listaRegraEscolhaCategoriaRamoAtividadeProfissao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagModeloDocumento
                label="Modelos de Documento"
                name="listaRegraEscolhaCategoriaModeloDocumento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSpedTipoProduto
                label="Tipos SPED"
                name="listaRegraEscolhaCategoriaSped"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum
                label="Tipo de Transação da Operação Fiscal (CFOP)"
                name="listaRegraEscolhaCategoriaTipoTransacao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagOperacaoFiscal
                label="Lista CFOP"
                name="listaRegraEscolhaCategoriaOperacaoFiscal"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagProduto
                label="Produtos"
                name="listaRegraEscolhaCategoriaProduto"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagGrupoProdutoServico
                label="Grupos de Produto"
                name="listaRegraEscolhaCategoriaGrupoProdutoServico"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteCategoria
                label="Categorias"
                name="regraEscolhaCategoriaResultado.idCategoria"
                nomeObjeto="regraEscolhaCategoriaResultado.categoria"
                filtroTipoSinteticaAnalitica={
                  TipoSinteticaAnaliticaEnum.analitica
                }
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
