import api from '../../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico/impressao/retrato-com-foto-produto-com-sem-valor-itens';

interface IOrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicadorShow {
  id: string;
}

class OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicador {
  public async show(
    params: IOrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicador();
