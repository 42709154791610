import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IAtualizacaoPrecoCustoProdutoLista } from '@elogestor/util';
import { IInputAutoCompleteBaseRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import { IInputTabelaAutoCompleteCestRef } from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteCest';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IAtualizacaoPrecoCustoProdutoListaContext {
  validador: boolean;

  listaValor: IAtualizacaoPrecoCustoProdutoLista[];
  setListaValor(lista: IAtualizacaoPrecoCustoProdutoLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaDescricaoRef: RefObject<HTMLInputElement[]>;
  listaValorUnitarioVendaRef: RefObject<HTMLInputElement[]>;
  listaAumentandoEmPercentualRef: RefObject<HTMLInputElement[]>;
  listaAplicandoMarkupRef: RefObject<HTMLInputElement[]>;
  listaDiminuindoEmPercentualRef: RefObject<HTMLInputElement[]>;
  listaValorUnitarioBaseRef: RefObject<HTMLInputElement[]>;
  listaValorUnitarioCustoReposicaoRef: RefObject<HTMLInputElement[]>;
  listaCodigoBarrasRef: RefObject<HTMLInputElement[]>;
  listaAtivoRef: RefObject<HTMLInputElement[]>;

  listaNcmRef: RefObject<IInputAutoCompleteBaseRef[]>;
  listaCestRef: RefObject<IInputTabelaAutoCompleteCestRef[]>;
}

const AtualizacaoPrecoCustoProdutoListaContext =
  createContext<IAtualizacaoPrecoCustoProdutoListaContext>(
    {} as IAtualizacaoPrecoCustoProdutoListaContext
  );

const AtualizacaoPrecoCustoProdutoListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IAtualizacaoPrecoCustoProdutoLista[]
  >([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaDescricaoRef = useRef<HTMLInputElement[]>([]);
  const listaValorUnitarioVendaRef = useRef<HTMLInputElement[]>([]);
  const listaAumentandoEmPercentualRef = useRef<HTMLInputElement[]>([]);
  const listaAplicandoMarkupRef = useRef<HTMLInputElement[]>([]);
  const listaDiminuindoEmPercentualRef = useRef<HTMLInputElement[]>([]);
  const listaValorUnitarioBaseRef = useRef<HTMLInputElement[]>([]);
  const listaValorUnitarioCustoReposicaoRef = useRef<HTMLInputElement[]>([]);
  const listaCodigoBarrasRef = useRef<HTMLInputElement[]>([]);
  const listaAtivoRef = useRef<HTMLInputElement[]>([]);

  const listaNcmRef = useRef<IInputAutoCompleteBaseRef[]>([]);
  const listaCestRef = useRef<IInputTabelaAutoCompleteCestRef[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IAtualizacaoPrecoCustoProdutoLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <AtualizacaoPrecoCustoProdutoListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaDescricaoRef,
        listaValorUnitarioVendaRef,
        listaAumentandoEmPercentualRef,
        listaAplicandoMarkupRef,
        listaDiminuindoEmPercentualRef,
        listaValorUnitarioBaseRef,
        listaValorUnitarioCustoReposicaoRef,
        listaCodigoBarrasRef,
        listaAtivoRef,

        listaNcmRef,
        listaCestRef,

        validador: true,
      }}
    >
      {children}
    </AtualizacaoPrecoCustoProdutoListaContext.Provider>
  );
};

function UseAtualizacaoPrecoCustoProdutoLista(): Omit<
  IAtualizacaoPrecoCustoProdutoListaContext,
  'validador'
> {
  const context = useContext(AtualizacaoPrecoCustoProdutoListaContext);

  if (!context.validador) {
    throw new Error(
      'UseAtualizacaoPrecoCustoProdutoLista deve ser usado com um AtualizacaoPrecoCustoProdutoListaHook'
    );
  }

  return context;
}

export {
  AtualizacaoPrecoCustoProdutoListaHook,
  UseAtualizacaoPrecoCustoProdutoLista,
};
