/* eslint-disable no-nested-ternary */
import {
  AmbienteEnum,
  EnumObterValue,
  SituacaoEventoEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { AiOutlineCheck, AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiMailSend, BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import NotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Transmissao/Evento/Impressao/CartaCorrecao/NotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicador';
import NotaFiscalSaidaTransmissaoEventoTransmitirComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Transmissao/Evento/NotaFiscalSaidaTransmissaoEventoTransmitirComunicador';
import NotaFiscalImpressaoCartaCorrecao from '../../../../../../../DocumentosEletronicos/NFe/Eventos/CartaCorrecao/Impressao';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import VisualizarPDF from '../../../../../../../../Util/Relatorios/VisualizarPDF';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import NotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Transmissao/Evento/Impressao/CartaCorrecao/NotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicador';
import JanelaEnvioEmail from '../../../../../../../../Componentes/JanelaEnvioEmail';
import PessoaContatoObterEmailComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ContaEmailObterDadosComunicador from '../../../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import NotaFiscalSaidaTransmissaoEventoExportarXmlComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Transmissao/Evento/NotaFiscalSaidaTransmissaoEventoExportarXmlComunicador';
import TratarValidadeCertificado from '../../../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';

interface ICartaCorrecaoDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const CartaCorrecaoDetalhe: React.FC<ICartaCorrecaoDetalheModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
}) => {
  const { permissoes: permissao } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida } = permissao;
  const {
    formRefLista,
    handleCarregarDados,
    handleValidar,
    loading,
    getIdDetalheRegistro,
    refresh,
    setIdDetalheRegistro,
    setLoading,
    inputRefFocus,
  } = UseListaDetalheForm();
  const { abrirJanela } = UseConfirmacao();
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const formPrincipal = UseForm();
  const situacaoCartaCorrecao = formRefLista.current?.getFieldValue('situacao');
  const numeroNotaFiscal =
    formPrincipal.formRef.current?.getFieldValue('numero');
  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (onSalvarFormModal) onSalvarFormModal();
  }, [onSalvarFormModal]);

  // #region Impressão

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response =
        await NotaFiscalSaidaTransmissaoEventoCartaCorrecaoImpressaoComunicador.show(
          { id: idRegistro }
        );
      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await NotaFiscalImpressaoCartaCorrecao.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf = await NotaFiscalImpressaoCartaCorrecao.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [getIdDetalheRegistro]
  );

  const handleImprimir = useCallback(async () => {
    try {
      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formPrincipal.formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formPrincipal.formRef.current?.getFieldValue(
          'transmissao.ambiente'
        );

        const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
        const numeroSequencialEvento = formRefLista.current?.getFieldValue(
          'numeroSequencialEvento'
        );

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#ambiente:${ambienteFormatado}#numeroSequencial:${numeroSequencialEvento}`
        );

        await NotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicador.update(
          { params: formData }
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formPrincipal.formRef,
    formRefLista,
    handleVisualizarObterBlobPdf,
    setLoading,
  ]);

  // #endregion Impressão

  // #region Transmitir

  const handleClickTransmitir = useCallback(async () => {
    const idNotaFiscalSaida = formPrincipal.getIdDetalheRegistro();
    const idCartaCorrecao = getIdDetalheRegistro() ?? null;
    if (!idNotaFiscalSaida) return;
    setLoading(true);

    const data = formRefLista.current?.getData();

    if (!(await handleValidar(data, formRefLista.current))) {
      setLoading(false);
      formPrincipal.setLoading(false);
      return;
    }

    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja Transmitir a Carta de Correção?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) {
      setLoading(false);
      return;
    }

    try {
      await TratarValidadeCertificado();

      const { id, mensagemErro } =
        await NotaFiscalSaidaTransmissaoEventoTransmitirComunicador.update({
          id: idNotaFiscalSaida,
          params: {
            idCartaCorrecao,
            condicaoUsoCartaCorrecao: data?.condicaoUsoCartaCorrecao,
            descricaoCorrecao: data?.descricaoCorrecao,
          },
        });

      setIdDetalheRegistro(id);

      ToastSucesso('Registro Transmitido!');
      await handleCarregarDados();
      if (id) {
        await handleImprimir();
        setLoading(false);
      }
      refresh();
      formPrincipal.refresh();

      if (mensagemErro && mensagemErro !== '') ToastErro(mensagemErro);
    } catch (error) {
      TratarErros(error);
      handleCarregarDados();
      setLoading(false);
    }
  }, [
    abrirJanela,
    formPrincipal,
    formRefLista,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleValidar,
    refresh,
    setIdDetalheRegistro,
    setLoading,
  ]);

  // #endregion Transmitir

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    setShowEnviarEmail(true);
  }, []);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idRegistro = getIdDetalheRegistro() || '';

      const idPessoa = formPrincipal.formRef.current?.getFieldValue(
        'notaFiscalSaidaPessoa.idPessoa'
      );

      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: true,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const numeroSequencialEvento = formRefLista.current?.getFieldValue(
        'numeroSequencialEvento'
      );
      const listaAnexo: any[] = [];
      const anexo = await handleVisualizarObterBlobPdf(false);
      const data = new Uint8Array((anexo as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `CartaCorrecao${numeroSequencialEvento}.pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const responseXml =
        await NotaFiscalSaidaTransmissaoEventoExportarXmlComunicador.show({
          id: idRegistro,
        });

      const utf8Content = new TextEncoder().encode(responseXml.xml as any);
      const blobXml = new Blob([utf8Content], {
        type: 'text/xml;charset=UTF-8',
      });

      listaAnexo.push({
        nomeArquivo: `${responseXml.nomeArquivo}.xml`,
        file: blobXml,
        permitirExcluir: false,
      });

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Carta de Correção de Número: ${numeroSequencialEvento}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [
      formPrincipal.formRef,
      formRefLista,
      getIdDetalheRegistro,
      handleVisualizarObterBlobPdf,
    ]
  );

  // #endregion Email

  return (
    <>
      <JanelaDetalhe
        titulo="Carta Correção"
        tamanho="xl"
        onFecharFormModal={onFecharFormModal}
      >
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Body>
          <InputHiddenHtml name="numeroSequencialEvento" />
          <Container>
            <Divisor>
              <Row>
                <Col lg={8} md={8} sm={12}>
                  <Input label="Chave de Acesso" name="chaveAcesso" disabled />
                </Col>
              </Row>

              <Row>
                <Col lg={2} md={4} sm={6}>
                  <InputLabel
                    name="numero"
                    label="Número"
                    valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
                    formatarValor={(numero = '') => {
                      return (
                        <div
                          className="lista-texto"
                          style={{
                            fontWeight: 'bold',
                            alignItems: 'flex-start',
                          }}
                        >
                          {numero}
                        </div>
                      );
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6}>
                  <InputLabel
                    name="codigoSerie"
                    label="Série"
                    valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
                    formatarValor={(codigoSerie = '') => {
                      return (
                        <div
                          className="lista-texto"
                          style={{
                            fontWeight: 'bold',
                            alignItems: 'flex-start',
                          }}
                        >
                          {codigoSerie}
                        </div>
                      );
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6}>
                  <InputLabel
                    name="ambiente"
                    label="Ambiente"
                    valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
                    formatarValor={(ambiente = '') => {
                      let producao = false;

                      if (ambiente.includes('1 - Produção')) producao = true;

                      return (
                        <div
                          className="lista-texto"
                          style={{
                            fontWeight: 'bold',
                            alignItems: 'flex-start',
                            color: producao ? 'green' : 'black',
                          }}
                        >
                          {ambiente.split('-')[1]}
                        </div>
                      );
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6}>
                  <InputLabel
                    name="situacao"
                    label="Situação"
                    valorStyle={{ alignItems: 'flex-start', marginTop: 5 }}
                    formatarValor={(situacao = '') => {
                      let autorizada = false;
                      let naoTransmitida = false;

                      if (situacao.includes('Autorizada')) autorizada = true;
                      if (situacao.includes('Não Transmitida'))
                        naoTransmitida = true;

                      return (
                        <div
                          className="lista-texto"
                          style={{
                            fontWeight: 'bold',
                            alignItems: 'flex-start',
                            color: autorizada
                              ? 'green'
                              : naoTransmitida
                                ? 'black'
                                : 'red',
                          }}
                        >
                          {situacao}
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Textarea
                    style={{ minHeight: 125 }}
                    label="Condições de Uso da Carta de Correção"
                    name="condicaoUsoCartaCorrecao"
                    maxLength={700}
                    disabled
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Textarea
                    ref={inputRefFocus}
                    style={{ minHeight: 150 }}
                    label="Descrição da Correção"
                    name="descricaoCorrecao"
                    maxLength={1000}
                    disabled={
                      situacaoCartaCorrecao === SituacaoEventoEnum.autorizada
                    }
                  />
                </Col>
              </Row>
            </Divisor>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={onFecharFormModal}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                type="button"
                onClick={handleClickSalvar}
                disabled={
                  (idDetalheRegistro !== ''
                    ? !ComercialVendasNotaFiscalSaida?.altera
                    : !ComercialVendasNotaFiscalSaida?.inclui) || loading
                }
              >
                <TextoLoading loading={loading}>
                  <BiSave />
                  <span style={{ marginLeft: 10 }}>Salvar</span>
                </TextoLoading>
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-azul"
                type="button"
                onClick={handleClickTransmitir}
                disabled={
                  !ComercialVendasNotaFiscalSaida?.altera ||
                  !ComercialVendasNotaFiscalSaida?.inclui ||
                  loading ||
                  situacaoCartaCorrecao === SituacaoEventoEnum.autorizada
                }
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>Transmitir</span>
                </TextoLoading>
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                }}
                className="btn-padrao btn-info"
                type="button"
                onClick={handleImprimir}
                disabled={
                  loading ||
                  situacaoCartaCorrecao !== SituacaoEventoEnum.autorizada
                }
              >
                <TextoLoading loading={loading}>
                  <AiOutlinePrinter />
                  <span style={{ marginLeft: 10 }}>Imprimir</span>
                </TextoLoading>
              </Button>

              <Button
                style={{
                  marginLeft: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                }}
                className="btn-padrao btn-info"
                type="button"
                onClick={handleEnviarPorEmail}
                disabled={
                  loading ||
                  situacaoCartaCorrecao !== SituacaoEventoEnum.autorizada
                }
              >
                <TextoLoading loading={loading}>
                  <BiMailSend />
                  <span style={{ marginLeft: 10 }}>Enviar E-mail</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </JanelaDetalhe>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Carta de Correção por E-mail"
          mensagemPadrao={`Segue em anexo a Carta de Correção da Nota Fiscal de: ${numeroNotaFiscal}`}
          direcionarDocumentosFiscais
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}
    </>
  );
};

export default CartaCorrecaoDetalhe;
