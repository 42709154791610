import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { ILiquidacaoContasEmLoteLista } from '@elogestor/util';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface ILiquidacaoContasEmLoteListaContext {
  validador: boolean;

  listaValor: ILiquidacaoContasEmLoteLista[];
  setListaValor(lista: ILiquidacaoContasEmLoteLista[]): void;

  possuiItemSelecionado: boolean;
  setPossuiItemSelecionado(possui: boolean): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
}

const LiquidacaoContasEmLoteListaContext =
  createContext<ILiquidacaoContasEmLoteListaContext>(
    {} as ILiquidacaoContasEmLoteListaContext
  );

const LiquidacaoContasEmLoteListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<ILiquidacaoContasEmLoteLista[]>(
    []
  );
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleSetListaValor = useCallback(
    async (lista: ILiquidacaoContasEmLoteLista[]) => {
      setListaValor(lista);
    },
    []
  );

  const handleSetPossuiItemSelecionado = useCallback(
    async (possui: boolean) => {
      setPossuiItemSelecionado(possui);
    },
    []
  );

  return (
    <LiquidacaoContasEmLoteListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        possuiItemSelecionado,
        setPossuiItemSelecionado: handleSetPossuiItemSelecionado,

        listaItemSelecionadoRef,

        validador: true,
      }}
    >
      {children}
    </LiquidacaoContasEmLoteListaContext.Provider>
  );
};

function UseLiquidacaoContasEmLoteLista(): Omit<
  ILiquidacaoContasEmLoteListaContext,
  'validador'
> {
  const context = useContext(LiquidacaoContasEmLoteListaContext);

  if (!context.validador) {
    throw new Error(
      'UseLiquidacaoContasEmLoteLista deve ser usado com um LiquidacaoContasEmLoteListaHook'
    );
  }

  return context;
}

export { LiquidacaoContasEmLoteListaHook, UseLiquidacaoContasEmLoteLista };
