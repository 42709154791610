import { AppErro } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseContaListaParcelas } from './ContaListaParcelasHook';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaIntegracaoPagamento/Comunicador/RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { UseAntecipacaoPrevisao } from '../../../Hooks/AntecipacaoPrevisaoHook';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaValor } = UseContaListaParcelas();
  const { abrirJanela } = UseConfirmacao();
  const { atualizaAntecipacaoPrevisao } = UseAntecipacaoPrevisao();

  const formPrincipal = UseForm();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada na "Conta Parcela" ',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          numeroDocumento: Yup.string().required(
            'Número do Documento é Obrigatório!'
          ),

          dataVencimento: Yup.date()
            .required('Data do Vencimento é Obrigatória!')
            .nullable(),
          valorParcela: Yup.number().required(
            'Valor da Parcela é Obrigatório!'
          ),
          valorTotalParcela: Yup.number().required(
            'Valor Total da Parcela é Obrigatório!'
          ),
          valorTotalMovimentos: Yup.number().required(
            'Valor Total da Parcela é Obrigatório!'
          ),
          valorTotalAberto: Yup.number().required(
            'Valor Total em Aberto é Obrigatório!'
          ),
          valorJurosSoma: Yup.number().required('(+) Juros é Obrigatório!'),
          valorDescontoSubtrai: Yup.number().required(
            '(-) Desconto é Obrigatório!'
          ),
          valorMultaSoma: Yup.number().required('(+) Multa é Obrigatória!'),
          valorOutrasDespesasSoma: Yup.number().required(
            '(+) Outras Despesas é Obrigatória!'
          ),
          valorOutrasDespesasSubtrai: Yup.number().required(
            '(-) Outras Despesas é Obrigatória!'
          ),
          valorDespesasCartaoSubtrai: Yup.number().required(
            '(-) Despesas de Cartão é Obrigatória!'
          ),
          valorDespesasCartorioSoma: Yup.number().required(
            '(+) Despesas de Cartório é Obrigatória!'
          ),
          valorDespesasEnvioSoma: Yup.number().required(
            '(+) Despesas de Envio é Obrigatória!'
          ),
          valorComissoesSoma: Yup.number().required(
            '(+) Comissões Pagas Obrigatória!'
          ),
          valorDespesasEnvioSubtrai: Yup.number().required(
            '(-) Despesas de Envio é Obrigatória!'
          ),
          valorComissoesSubtrai: Yup.number().required(
            '(-) Comissões Pagas Obrigatória!'
          ),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleObterRegraEscolhaIntegradorPagamento = useCallback(async () => {
    const listaContaParcela =
      formPrincipal.formRef.current?.getFieldValue('listaContaParcela');
    const quantidadeParcelas =
      formPrincipal.formRef.current?.getFieldValue('quantidadeParcelas');
    const dataHoraEmissao =
      formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

    try {
      setLoading(true);
      formPrincipal.setLoading(true);

      if (listaContaParcela) {
        await Promise.all(
          listaContaParcela.map(async (item: any) => {
            const response =
              await RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador.show(
                {
                  params: {
                    idBandeiraCartao: item.idBandeiraCartao,
                    idFormaPagamento: item.idFormaPagamento,
                    quantidadeParcelas,
                    dataPesquisaVigencia: new Date(dataHoraEmissao),
                  },
                }
              );

            if (response && response.regraEscolhaIntegracaoPagamento) {
              item.idRegraEscolhaIntegracaoPagamento =
                response.regraEscolhaIntegracaoPagamento.id;
              item.regraEscolhaIntegracaoPagamento = {
                id: response.regraEscolhaIntegracaoPagamento.id,
                regraEscolhaIntegracaoPagamentoResultado: {
                  cnpjInstituicaoPagamento:
                    response.regraEscolhaIntegracaoPagamento
                      .cnpjInstituicaoPagamento,
                },
              };
            }
          })
        );
      }

      setLoading(false);
      formPrincipal.setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  }, [formPrincipal]);

  const handleSubmit = useCallback(async (): Promise<ISubmitProps> => {
    try {
      setLoading(true);

      const listaDados =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');
      const numeroDocumentoConta =
        formPrincipal.formRef.current?.getFieldValue('numeroDocumento');
      const valorTotalConta =
        formPrincipal.formRef.current?.getFieldValue('valor');

      const valorTotalParcelas = listaDados
        .reduce((acc: any, item: any) => {
          acc += Number(item.valorParcela);
          return acc;
        }, 0)
        .Arredondar();

      const portador = listaValor[0].portador ?? undefined;
      const formaPagamento = listaValor[0].formaPagamento ?? undefined;

      const listaContaParcelaAdicionada = [
        ...listaDados,
        {
          sequencia: listaValor.length + 1,
          idBandeiraCartao: null,
          idFormaPagamento: formaPagamento?.id,
          formaPagamento,
          situacao: 'Aberta',
          idPortador: portador?.id,
          portador,
          valorTotalParcela: 0,
          valorTotalAberto: 0,
          valorTotalMovimentos: 0,
          valorParcela:
            valorTotalConta - valorTotalParcelas >= 0
              ? valorTotalConta - valorTotalParcelas
              : 0,
          valorJurosSoma: 0,
          valorDescontoSubtrai: 0,
          valorMultaSoma: 0,
          valorOutrasDespesasSoma: 0,
          valorOutrasDespesasSubtrai: 0,
          valorDespesasCartaoSubtrai: 0,
          valorDespesasCartorioSoma: 0,
          valorDespesasEnvioSoma: 0,
          valorComissoesSoma: 0,
          valorDespesasEnvioSubtrai: 0,
          valorComissoesSubtrai: 0,
          dataVencimento: null,
          avista: false,
          numeroDocumento: numeroDocumentoConta || '',
          listaContaParcelaLiquidacao: [],
          listaContaParcelaCategoria: [],
        },
      ];

      formPrincipal.formRef.current?.setFieldValorInicialSemExecutarEvento(
        'listaContaParcela',
        listaContaParcelaAdicionada
      );

      formPrincipal.formRef.current?.setFieldValue(
        'quantidadeParcelas',
        listaContaParcelaAdicionada.length
      );

      await handleObterRegraEscolhaIntegradorPagamento();

      setIdDetalheRegistro(String(listaContaParcelaAdicionada.length - 1));
      await atualizaAntecipacaoPrevisao();

      setLoading(false);
      refresh();
      return { id: '', erro: false };
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return { id: '', erro: true };
    }
  }, [
    atualizaAntecipacaoPrevisao,
    formPrincipal.formRef,
    handleObterRegraEscolhaIntegradorPagamento,
    listaValor,
    refresh,
    setIdDetalheRegistro,
  ]);

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const listaDados =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja Remover a Parcela?</span>
        ),
      });

      if (!resposta) return;

      setLoading(true);
      formPrincipal.setLoading(true);

      await Promise.all(
        listaDados.map(async (item: any) => {
          if (item.sequencia >= listaDados[Number(index)].sequencia) {
            item.sequencia -= 1;
          }
        })
      );

      listaDados.splice(Number(index), 1);

      formPrincipal.formRef.current?.setFieldValue(
        'listaContaParcela',
        listaDados
      );

      formPrincipal.formRef.current?.setFieldValue('condicaoPagamento', null);

      formPrincipal.formRef.current?.setFieldValue(
        'quantidadeParcelas',
        listaDados.length
      );

      if (listaDados.length > 0) {
        setIdDetalheRegistro(String(listaDados.length - 1));
      } else {
        setIdDetalheRegistro('');
      }

      await handleObterRegraEscolhaIntegradorPagamento();
      await atualizaAntecipacaoPrevisao();

      setLoading(false);
      formPrincipal.setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
