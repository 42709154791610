import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  FormatarDataParaPtBr,
  IManifestoTransporteDocumentoReferenciadoLista,
  IManifestoTransportePesquisaDocumentosEmitidosLista,
  TipoDocumentoEnum,
  TipoEmitenteEnum,
} from '@elogestor/util';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { AiOutlineFileSearch } from 'react-icons/ai/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import { Tr } from './styles';
import JanelaDetalhe from '../../../../../../../../../Componentes/JanelaDetalhe';
import { TBody } from '../../../../../../../../../Componentes/Styles/TBody';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../../Util/Erro/TratarErros';
import InputAutoCompletePessoa from '../../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputPeriodoData from '../../../../../../../../../Componentes/Inputs/InputPeriodoData';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador from '../../../../../../../../../Comunicador/Transporte/ManifestoTransporte/DocumentoReferenciado/Comunicador/ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador';
import BtnPadraoButton from '../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../../Componentes/Tabela/styles';
import LoadingDiv from '../../../../../../../../../Componentes/LoadingDiv';
import ToastInfo from '../../../../../../../../../Util/Toasts/ToastInfo';

interface IListaPesquisaDocumentosEmitidosProps {
  tipoEmitente: TipoEmitenteEnum;
  listaManifestoTransporteDocumentoReferenciado: IManifestoTransporteDocumentoReferenciadoLista[];
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const ListaPesquisaDocumentosEmitidos: React.FC<
  IListaPesquisaDocumentosEmitidosProps
> = ({
  onSalvarFormModal,
  onFecharFormModal,
  tipoEmitente,
  listaManifestoTransporteDocumentoReferenciado,
}) => {
  const { setLoading, handleSubmit, formRefDetalhe, formRefLista, loading } =
    UseListaDetalheForm();

  const [listaDocumentoIndexSelecionado, setListaDocumentoIndexSelecionado] =
    useState<number[]>([]);

  const [tipoDocumento, setTipoDocumento] = useState<TipoDocumentoEnum>();

  const [listaDocumento, setListaDocumento] = useState<
    IManifestoTransportePesquisaDocumentosEmitidosLista[]
  >([]);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleCarregar = useCallback(async () => {
    try {
      setLoading(true);

      const dataInicial =
        formRefLista.current?.getFieldValue('dataEmissaoInicial');
      const dataFinal = formRefLista.current?.getFieldValue('dataEmissaoFinal');
      const idPessoa = formRefLista.current?.getFieldValue('idPessoa');

      const listaChaveAcessoReferenciada =
        listaManifestoTransporteDocumentoReferenciado.map(
          (documento) => documento.chaveAcesso
        );

      const response =
        await ManifestoTransporteDocumentoReferenciadoListaDocumentoComunicador.index(
          {
            params: {
              dataInicial,
              dataFinal,
              idPessoa,
              tipoDocumento,
              listaChaveAcessoReferenciada,
            },
          }
        );

      if (!response || response.length === 0) {
        setListaDocumento([]);
        setListaDocumentoIndexSelecionado([]);

        if (selecionarTodosRef.current)
          selecionarTodosRef.current.checked = false;

        ToastInfo('Não foram localizados documentos!');
        setLoading(false);
        return;
      }

      setListaDocumento(response);
      setListaDocumentoIndexSelecionado([]);

      if (selecionarTodosRef.current)
        selecionarTodosRef.current.checked = false;

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRefLista,
    listaManifestoTransporteDocumentoReferenciado,
    setLoading,
    tipoDocumento,
  ]);

  const handleSalvar = useCallback(async () => {
    if (!listaDocumentoIndexSelecionado) return;

    const listaDocumentoSelecionado = listaDocumento.filter((_, index) =>
      listaDocumentoIndexSelecionado.includes(index)
    );

    const listaDocumentoIdSelecionado = listaDocumentoSelecionado.map(
      (documento) => documento.id
    );

    const data = {
      listaDocumentoIdSelecionado,
      tipoDocumento,
    };

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [
    formRefDetalhe,
    handleSubmit,
    listaDocumento,
    listaDocumentoIndexSelecionado,
    onSalvarFormModal,
    tipoDocumento,
  ]);

  useEffect(() => {
    if (tipoEmitente === TipoEmitenteEnum.transportadorCargaPropria) {
      setTipoDocumento(TipoDocumentoEnum.nfe);
    } else {
      setTipoDocumento(TipoDocumentoEnum.cte);
    }

    const dataInicial = new Date();
    dataInicial.setDate(dataInicial.getDate() - 30);
    const dataFinal = new Date();

    formRefLista.current?.setFieldValue('dataEmissaoInicial', dataInicial);
    formRefLista.current?.setFieldValue('dataEmissaoFinal', dataFinal);
  }, [formRefLista, handleCarregar, tipoDocumento, tipoEmitente]);

  return (
    <JanelaDetalhe
      titulo="Seleção de Documentos Fiscais Emitidos"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Header>
        <FormCia ref={formRefLista} style={{ width: '100%' }}>
          <Divisor>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputAutoCompletePessoa
                  label="Pessoa/Estabelecimento"
                  name="idPessoa"
                  nomeObjeto="pessoa"
                  placeholder="Pessoa/Estabelecimento"
                  permitirAdicionar={false}
                />
              </Col>
              <Col lg={2} md={6} sm={12} style={{ alignSelf: 'flex-end' }}>
                <BtnPadraoButton
                  type="button"
                  className="btn-padrao btn-verde btn-adicionar"
                  onClick={() => {
                    handleCarregar();
                  }}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <AiOutlineFileSearch />
                    <span style={{ marginLeft: 10 }}>Pesquisar</span>
                  </TextoLoading>
                </BtnPadraoButton>
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Header>

      <Modal.Body style={{ height: 500, display: 'flex' }}>
        <Tabela style={{ overflow: 'auto', width: '100%' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      type="checkbox"
                      name="selecionarTodos"
                      ref={selecionarTodosRef}
                      onChange={(event) => {
                        if (event.target.checked) {
                          const listaDocumentoIndex = listaDocumento.map(
                            (_, index) => {
                              return index;
                            }
                          );
                          setListaDocumentoIndexSelecionado(
                            listaDocumentoIndex
                          );
                        } else {
                          setListaDocumentoIndexSelecionado([]);
                        }
                      }}
                    />
                  </div>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Série</span>
                </th>
                <th className="lista-valor" style={{ width: '10%' }}>
                  <span>Número</span>
                </th>
                <th className="lista-data" style={{ width: '15%' }}>
                  <span>Data Emissão</span>
                </th>
                <th className="lista-texto" style={{ width: '30%' }}>
                  <span>Pessoa</span>
                </th>
                <th className="lista-valor" style={{ width: '15%' }}>
                  <span>Valor Total</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaDocumento.map((documento, index) => {
                return (
                  <Tr
                    key={v4()}
                    onClick={(event) => {
                      if (loading) return;
                      const input = event.currentTarget.querySelector('input');
                      input?.click();
                    }}
                    className={loading ? 'tabela-desabilitada' : ''}
                  >
                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-texto`}
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          name="itemSelecionado"
                          checked={listaDocumentoIndexSelecionado.includes(
                            index
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setListaDocumentoIndexSelecionado((state) => {
                                return [...state, index];
                              });
                            } else {
                              setListaDocumentoIndexSelecionado((state) => {
                                return state.filter((item) => item !== index);
                              });
                            }
                          }}
                        />
                      </div>
                    </td>

                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-texto`}
                    >
                      {`${documento.serie.codigo} - ${documento.serie.descricao}`}
                    </td>
                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-valor`}
                    >
                      {documento.numero}
                    </td>
                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-data`}
                    >
                      {FormatarDataParaPtBr(documento.dataHoraEmissao)}
                    </td>
                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-texto`}
                    >
                      {`${documento.pessoa.codigo} - ${documento.pessoa.nomeRazaoSocial}`}
                    </td>
                    <td
                      className={`${
                        listaDocumentoIndexSelecionado.includes(index)
                          ? 'selecionado '
                          : ''
                      }lista-valor`}
                    >
                      {Number(documento.valorTotal).FormatarParaPtBr()}
                    </td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <button
            type="button"
            onClick={onFecharFormModal}
            style={{ marginRight: 15, fontWeight: 'bold' }}
            className="btn-padrao btn-cinza-claro"
            disabled={loading}
          >
            <TextoLoading loading={loading}>
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </TextoLoading>
          </button>

          <Button
            style={{ fontWeight: 'bold' }}
            type="button"
            onClick={handleSalvar}
            disabled={listaDocumentoIndexSelecionado.length === 0 || loading}
          >
            <TextoLoading loading={loading}>
              <BiSave />
              <span style={{ marginLeft: 10 }}>Salvar</span>
            </TextoLoading>
          </Button>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ListaPesquisaDocumentosEmitidos;
