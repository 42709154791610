import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/transportador-auto-complete';

interface IPessoaTransportadorAutoCompleteComunicadorIndex {
  params: any;
}

class PessoaTransportadorAutoCompleteComunicador {
  public async index(
    params: IPessoaTransportadorAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaTransportadorAutoCompleteComunicador();
