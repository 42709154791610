import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/configurador/caracteristica-auto-complete';

interface ICaracteristicaAutoCompleteComunicadorIndex {
  params: any;
}

class CaracteristicaAutoCompleteComunicador {
  public async index(
    params: ICaracteristicaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CaracteristicaAutoCompleteComunicador();
