import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/operacao-fiscal-auto-complete-tag';

interface IOperacaoFiscalAutoCompleteTagComunicadorIndex {
  params: any;
}

class OperacaoFiscalAutoCompleteTagComunicador {
  public async index(
    params: IOperacaoFiscalAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new OperacaoFiscalAutoCompleteTagComunicador();
