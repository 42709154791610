import {
  DestinadoEnum,
  FinalidadeEmissaoEnum,
  FormatarEnum,
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputAutoCompleteTipoNota from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsOperacaoInterestadualTab } from '../../../Hook/ItemIcmsOperacaoInterestadualTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseAuth } from '../../../../../../../../../../Hooks/Auth';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';
import ToastInfo from '../../../../../../../../../../Util/Toasts/ToastInfo';

const ItemImpostoConfiguracaoTributacaoTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const {
    calcularImpostosPorCst,
    obterConfiguracaoTributaria,
    obterRegraEscolhaParametros,
    limparConfiguracaoTributaria,
  } = UseItemTab();
  const { obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();
  const { formRef: formPrincipal } = UseForm();
  const { empresa } = UseAuth();

  const indicadorPresenca =
    formPrincipal.current?.getFieldValue('indicadorPresenca');

  const finalidadeEmissao =
    formPrincipal.current?.getFieldValue('finalidadeEmissao');

  const ufPessoa =
    formPrincipal.current?.getFieldValue(
      'ordemServicoPessoaEndereco.cidade.estado.sigla'
    ) || null;

  const ufPessoaEntrega =
    formPrincipal.current?.getFieldValue(
      'ordemServicoPessoaEnderecoEntrega.cidade.estado.sigla'
    ) || null;

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual =
    useCallback(() => {
      obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
    }, [obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual]);

  const handleObterConfiguracaoTributaria = useCallback(() => {
    obterConfiguracaoTributaria();
  }, [obterConfiguracaoTributaria]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleObterRegraEscolhaParametros = useCallback(() => {
    obterRegraEscolhaParametros();
  }, [obterRegraEscolhaParametros]);

  const handleAtualizarNaturezaOperacao = useCallback(
    (descricao?: string) => {
      if (!descricao) return;

      const naturezaOperacao =
        formRefDetalhe.current?.getFieldValue('naturezaOperacao');
      if (!naturezaOperacao) {
        formRefDetalhe.current?.setFieldValue(
          'naturezaOperacao',
          String(descricao.substring(0, 60))
        );
      }
    },
    [formRefDetalhe]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNota
              label="Tipo de Nota"
              name="idTipoNota"
              placeholder="Tipo de Nota"
              filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.saida}
              nomeObjeto="tipoNota"
              onChangeItemAtualAposCarregarSemClear={(ev) => {
                formRefDetalhe.current?.clearField('tipoNotaMotivo', {
                  setValorInicial: false,
                });

                if (ev.itemAtual) {
                  if (
                    ev.itemAtual.descricao.RemoverAcentos().toUpperCase() ===
                      'DEVOLUCAO' &&
                    finalidadeEmissao !== FinalidadeEmissaoEnum.devolucao
                  ) {
                    ToastInfo(
                      'Altere a Finalidade de Emissão da Nota Fiscal para Devolução a fim de prosseguir com a Devolução!'
                    );
                  }
                }

                refresh();
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNotaMotivo
              label="Motivo"
              name="idTipoNotaMotivo"
              placeholder="Motivo"
              nomeObjeto="tipoNotaMotivo"
              disabled={!formRefDetalhe.current?.getFieldValue('idTipoNota')}
              idTipoNota={formRefDetalhe.current?.getFieldValue('idTipoNota')}
              onChangeItemAtualAposCarregarSemClear={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Select
              label="Destinado"
              name="destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
                nullavel: true,
              })}
              onChange={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
              }}
            />
          </Col>
          <Col lg={8} md={12} sm={12}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="idOperacaoFiscal"
              placeholder="CFOP"
              nomeObjeto="operacaoFiscal"
              indicadorPresenca={indicadorPresenca}
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.saida}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem: empresa.uf,
                ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
                indicadorPresenca,
              })}
              onChangeItemAtualAposCarregarSemClear={(event) => {
                handleObterRegraEscolhaParametros();
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
                handleAtualizarNaturezaOperacao(event.itemAtual?.descricao);
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Input
              label="Natureza Operação"
              name="naturezaOperacao"
              placeholder="Natureza Operação"
              maxLength={60}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoConfiguracaoTributacaoTab;
