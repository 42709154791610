import { AmbienteEnum } from '@elogestor/util';
import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/obter-proximo-numero-sequencial-evento';

interface ObterProximoNumeroSequencialEventoComunicadorIndex {
  params: {
    idValor: string;
    ambiente: AmbienteEnum;
  };
}

class ObterProximoNumeroSequencialEventoComunicador {
  public async index(
    params: ObterProximoNumeroSequencialEventoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ObterProximoNumeroSequencialEventoComunicador();
