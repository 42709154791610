import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/pedido-compra-importacao-cotacao-compra/importar';

interface IPedidoCompraImportacaoCotacaoCompraImportarComunicadorStore {
  params: any;
}

class PedidoCompraImportacaoCotacaoCompraImportarComunicador {
  public async store(
    params: IPedidoCompraImportacaoCotacaoCompraImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new PedidoCompraImportacaoCotacaoCompraImportarComunicador();
