"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCarteiraBoletoEnum;
(function (TipoCarteiraBoletoEnum) {
    TipoCarteiraBoletoEnum["carteiraSimples"] = "A";
    TipoCarteiraBoletoEnum["carteiraCaucionada"] = "B";
    TipoCarteiraBoletoEnum["carteiraDescontada"] = "C";
    TipoCarteiraBoletoEnum["carteiraVinculada"] = "D";
})(TipoCarteiraBoletoEnum || (TipoCarteiraBoletoEnum = {}));
exports.default = TipoCarteiraBoletoEnum;
