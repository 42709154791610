import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque-saldo-proprio-terceiro-lote';

interface IEstoqueSaldoProprioTerceiroLoteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoProprioTerceiroLoteRelatorioComunicador {
  public async index(
    params: IEstoqueSaldoProprioTerceiroLoteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoProprioTerceiroLoteRelatorioComunicador();
