import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/obter-retirada-consignado';

interface IPedidoVendaObterRetiradaConsignadoComunicadorShow {
  id: string;
}

class PedidoVendaObterRetiradaConsignadoComunicador {
  public async show(
    params: IPedidoVendaObterRetiradaConsignadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaObterRetiradaConsignadoComunicador();
