import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail } from 'react-icons/bi/index.mjs';
import { SituacaoOrdemDesmontagemEnum } from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoOrdemDesmontagem from './Impressao/Template/OrdemDesmontagemImpressao';
import OrdemDesmontagemImpressaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemDesmontagem/Impressao/OrdemDesmontagemImpressaoComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import { UseOrdemDesmontagem } from '../Hooks/OrdemDesmontagemHook';
import OrdemDesmontagemReportarRetornoDesmontagemComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemDesmontagem/Comunicador/OrdemDesmontagemReportarRetornoDesmontagemComunicador';

const AcoesOrdemDesmontagem: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    handleSubmit,
    setLoading,
    getIdDetalheRegistro,
    refresh,
  } = UseForm();
  const { handleAlterarSituacao } = UseOrdemDesmontagem();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      const data: any = formRef.current?.getData();

      if (formRef.current?.validarSeAlterou()) {
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const id = getIdDetalheRegistro();
      if (!id) return;

      const response = await OrdemDesmontagemImpressaoComunicador.show({
        id,
      });
      await ImpressaoOrdemDesmontagem.handleVisualizarPdf(response);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  const handleReportarRetornoDesmontagem = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const response =
        await OrdemDesmontagemReportarRetornoDesmontagemComunicador.show({
          id: String(idRegistroDetalhe),
        });
      if (response.ordemDesmontagemProduzidaConsumida) {
        ToastInfo('Todas os Consumos e Produções já Foram Reportados!');
        setLoading(false);

        return;
      }
      const listaOrdemDesmontagemLocalEstoque =
        formRef.current?.getFieldValue('listaOrdemDesmontagemLocalEstoque') ??
        [];

      const listaOrdemDesmontagemItem = formRef.current?.getFieldValue(
        'listaOrdemDesmontagemItem'
      );
      if (
        response.listaOrdemDesmontagemLocalEstoque &&
        response.listaOrdemDesmontagemLocalEstoque.length > 0
      ) {
        listaOrdemDesmontagemLocalEstoque.push(
          response.listaOrdemDesmontagemLocalEstoque[0]
        );

        formRef.current?.setFieldValue(
          'listaOrdemDesmontagemLocalEstoque',
          listaOrdemDesmontagemLocalEstoque
        );
      }

      if (
        response.listaOrdemDesmontagemItem &&
        response.listaOrdemDesmontagemItem
      ) {
        for (let i = 0; i < response.listaOrdemDesmontagemItem.length; i++) {
          const item = response.listaOrdemDesmontagemItem[i];

          if (
            item.listaOrdemDesmontagemItemLocalEstoque &&
            item.listaOrdemDesmontagemItemLocalEstoque.length > 0
          ) {
            const itemCadastrado = listaOrdemDesmontagemItem.find(
              (it: any) => it.id === item.id
            );
            itemCadastrado.desmontado = true;
            itemCadastrado.listaOrdemDesmontagemItemLocalEstoque.push(
              item.listaOrdemDesmontagemItemLocalEstoque[0]
            );
          }
        }
      }

      const alterarSituacao = handleAlterarSituacao({
        listaOrdemDesmontagemItem,
        listaOrdemDesmontagemLocalEstoque,
        dataHoraInicio: formRef.current?.getFieldValue('dataHoraInicio'),
        dataHoraTermino: formRef.current?.getFieldValue('dataHoraTermino'),
        quantidade: formRef.current?.getFieldValue('quantidade'),
      });

      if (alterarSituacao) {
        formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemDesmontagemEnum.concluida
        );
      } else {
        formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemDesmontagemEnum.emAndamento
        );
      }

      refresh();
      setLoading(false);
    } catch (err) {
      TratarErros(err);
      setLoading(false);
    }
  }, [
    formRef,
    handleAlterarSituacao,
    handleSalvar,
    idRegistroDetalhe,
    refresh,
    setLoading,
  ]);

  return (
    <SplitAcoes
      id={v4()}
      disabled={loading || !idRegistroDetalhe}
      className="btn-group"
      title={
        <div>
          <TextoLoading loading={loading}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item disabled={loading} onClick={handleImprimir}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <AiOutlinePrinter style={{ marginRight: 5 }} />
          Imprimir
        </span>
      </Dropdown.Item>

      <Dropdown.Item
        disabled={loading}
        onClick={handleReportarRetornoDesmontagem}
      >
        <span style={{ display: 'flex', fontSize: 16 }}>
          <BiDetail style={{ marginRight: 5 }} />
          Reportar Retorno e Desmontagem
        </span>
      </Dropdown.Item>
    </SplitAcoes>
  );
};

export default AcoesOrdemDesmontagem;
