import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/impressao';

interface IImportacaoXmlTerceiroImpressaoComunicadorShow {
  id: string;
}

class ImportacaoXmlTerceiroImpressaoComunicador {
  public async show(
    params: IImportacaoXmlTerceiroImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroImpressaoComunicador();
