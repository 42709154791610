import React from 'react';
import { v4 } from 'uuid';
import { Tab, Tabs } from 'react-bootstrap';
import DocumentoTab from './DocumentoTab';
import FiscalTab from './FiscalTab';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { ImpostoIcmsHook } from './Hooks/ImpostoIcmsHook';
import { AtualizarImpostoFiscalConformeImpostoDocumento } from '../Hooks/AtualizarImpostoFiscalConformeImpostoDocumento';

interface IImpostoTab {
  bloquearCamposPorImportacao: boolean;
}

const ImpostoTab: React.FC<IImpostoTab> = ({ bloquearCamposPorImportacao }) => {
  const { formRef } = UseForm();

  return (
    <ImpostoIcmsHook>
      <Tabs id={v4()} defaultActiveKey="documento">
        <Tab eventKey="documento" title="Documento">
          <DocumentoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab
          eventKey="fiscal"
          title="Fiscal / Contábil"
          onEnter={() => {
            if (
              !formRef.current?.getFieldValue(
                'conhecimentoTransporteTerceiroImpostoIcmsFiscal.creditoConformeDocumento'
              )
            ) {
              return;
            }

            AtualizarImpostoFiscalConformeImpostoDocumento(formRef);
          }}
        >
          <FiscalTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>
      </Tabs>
    </ImpostoIcmsHook>
  );
};

export default ImpostoTab;
