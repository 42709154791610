import React from 'react';
import { ComunicadorProvider } from '../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import UsuarioComunicador from '../../../../../Comunicador/Configuracao/Usuario/Comunicador/UsuarioComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { UsuarioTipoHook } from './UsuarioTipoHook';

const UsuarioHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: UsuarioComunicador }}>
      <RotasHook>
        <UsuarioTipoHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </UsuarioTipoHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default UsuarioHook;
