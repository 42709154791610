const EstruturaProdutoPrimeiroNivelPlanilha = [
  { titulo: 'Produto Pai Código', valor: 'produtoPai.codigo' },
  { titulo: 'Produto Pai Descrição', valor: 'produtoPai.descricao' },
  {
    titulo: 'Unidade Pai',
    valor: 'produtoPai.produtoEstoque.unidadeMedida.sigla',
  },

  {
    titulo: 'Produto Filho Código',
    valor: 'listaEstruturaProduto.produtoFilho.codigo',
  },
  {
    titulo: 'Produto Filho Descrição',
    valor: 'listaEstruturaProduto.produtoFilho.descricao',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaEstruturaProduto.quantidade',
  },
  {
    titulo: 'Unidade Filho',
    valor:
      'listaEstruturaProduto.produtoFilho.produtoEstoque.unidadeMedida.sigla',
  },
];

export default EstruturaProdutoPrimeiroNivelPlanilha;
