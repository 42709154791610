import {
  IcmsCteCalculoPorCst,
  IcmsCteObterVisibilidadeBloqueioCamposPorCst,
} from '@elogestor/util';
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { UseRegimeTributario } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';

interface ICampoState {
  calcularIcmsOpInterestadual: boolean;
  percentualReducaoBaseCalculoIcmsOpInterestadual: boolean;
  baseCalculoIcmsOpInterestadual: boolean;
  aliquotaIcmsOpInterestadual: boolean;
  valorIcmsOpInterestadual: boolean;
}

interface IImpostoIcmsOpInterestadualHookProps {
  handleCalcularIcmsOpInterestadual: () => Promise<void>;
  obterVisivbilidadeBloqueioCamposIcmsOpInterestadual: () => Promise<void>;
  camposBloqueadosIcmsOpInterestadual: ICampoState;
  camposEscondidosIcmsOpInterestadual: ICampoState;
}

const camposEscondidosIcmsOpInterestadualPadrao: ICampoState = {
  aliquotaIcmsOpInterestadual: false,
  baseCalculoIcmsOpInterestadual: false,
  calcularIcmsOpInterestadual: false,
  percentualReducaoBaseCalculoIcmsOpInterestadual: false,
  valorIcmsOpInterestadual: false,
};

const camposBloqueadosIcmsOpInterestadualPadrao: ICampoState = {
  aliquotaIcmsOpInterestadual: false,
  baseCalculoIcmsOpInterestadual: false,
  calcularIcmsOpInterestadual: false,
  percentualReducaoBaseCalculoIcmsOpInterestadual: false,
  valorIcmsOpInterestadual: false,
};

const ImpostoIcmsOpInterestadualContext = createContext(
  {} as IImpostoIcmsOpInterestadualHookProps
);

const ImpostoIcmsOpInterestadualHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const { formRef } = UseForm();
  const { regimeTributario } = UseRegimeTributario();

  const [
    camposBloqueadosIcmsOpInterestadual,
    setCamposBloqueadosIcmsOpInterestadual,
  ] = useState(camposBloqueadosIcmsOpInterestadualPadrao);
  const [
    camposEscondidosIcmsOpInterestadual,
    setCamposEscondidosIcmsOpInterestadual,
  ] = useState(camposEscondidosIcmsOpInterestadualPadrao);

  const obterVisivbilidadeBloqueioCamposIcmsOpInterestadual =
    useCallback(async (): Promise<void> => {
      const { bloqueado, visivel } =
        IcmsCteObterVisibilidadeBloqueioCamposPorCst({
          calcularIcms: formRef.current?.getFieldValue(
            'conhecimentoTransporteImpostoIcms.calcularIcmsOpInterestadual'
          ),
          regimeTributario,
          cst:
            formRef.current?.getFieldValueNomeObjeto(
              'conhecimentoTransporteImpostoIcms.situacaoTributaria'
            )?.codigo || '',
        });

      setCamposBloqueadosIcmsOpInterestadual({
        aliquotaIcmsOpInterestadual: bloqueado.aliquotaIcms,
        baseCalculoIcmsOpInterestadual: bloqueado.baseCalculoIcms,
        calcularIcmsOpInterestadual: bloqueado.calcularIcms,
        percentualReducaoBaseCalculoIcmsOpInterestadual:
          bloqueado.percentualReducaoBaseCalculoIcms,
        valorIcmsOpInterestadual: bloqueado.valorIcms,
      });
      setCamposEscondidosIcmsOpInterestadual({
        aliquotaIcmsOpInterestadual: visivel.aliquotaIcms,
        baseCalculoIcmsOpInterestadual: visivel.baseCalculoIcms,
        calcularIcmsOpInterestadual: visivel.calcularIcms,
        percentualReducaoBaseCalculoIcmsOpInterestadual:
          visivel.percentualReducaoBaseCalculoIcms,
        valorIcmsOpInterestadual: visivel.valorIcms,
      });
    }, [formRef, regimeTributario]);

  const handleCalcularIcmsOpInterestadual = useCallback(async () => {
    const calcularIcmsOpInterestadual = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.calcularIcmsOpInterestadual'
    );

    const cst =
      formRef.current?.getFieldValueNomeObjeto(
        'conhecimentoTransporteImpostoIcms.situacaoTributaria'
      )?.codigo ?? '';

    const percentualReducaoBaseCalculoIcmsOpInterestadual =
      formRef.current?.getFieldValue(
        'conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculoIcmsOpInterestadual'
      );

    const baseCalculoIcmsOpInterestadual = calcularIcmsOpInterestadual
      ? formRef.current?.getFieldValue('valorTotalServico')
      : formRef.current?.getFieldValue(
          'conhecimentoTransporteImpostoIcms.baseCalculoIcmsOpInterestadual'
        );

    const valorTotal = formRef.current?.getFieldValue('valorTotalServico');

    const aliquotaIcmsOpInterestadual = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.aliquotaIcmsOpInterestadual'
    );

    const valorIcmsOpInterestadual = formRef.current?.getFieldValue(
      'conhecimentoTransporteImpostoIcms.valorIcmsOpInterestadual'
    );

    const retorno = IcmsCteCalculoPorCst({
      cst,
      aliquota: Number(aliquotaIcmsOpInterestadual),
      baseCalculo: Number(baseCalculoIcmsOpInterestadual),
      calcular: calcularIcmsOpInterestadual,
      percentualReducaoBaseCalculo: Number(
        percentualReducaoBaseCalculoIcmsOpInterestadual
      ),
      valor: Number(valorIcmsOpInterestadual),
      valorTotal: Number(valorTotal),
    });

    await formRef.current?.setSemExecutarEvento({
      conhecimentoTransporteImpostoIcms: {
        valorIcmsOpInterestadual: retorno.valor,
        baseCalculoIcmsOpInterestadual: retorno.baseCalculo,
      },
    });

    obterVisivbilidadeBloqueioCamposIcmsOpInterestadual();
  }, [formRef, obterVisivbilidadeBloqueioCamposIcmsOpInterestadual]);

  const calcularIcmsOpInterestadual = formRef.current?.getFieldValue(
    'conhecimentoTransporteImpostoIcms.calcularIcmsOpInterestadual'
  );

  const cst =
    formRef.current?.getFieldValueNomeObjeto(
      'conhecimentoTransporteImpostoIcms.situacaoTributaria'
    )?.codigo || '';

  useEffect(() => {
    (async () => {
      await obterVisivbilidadeBloqueioCamposIcmsOpInterestadual();
    })();
  }, [
    cst,
    obterVisivbilidadeBloqueioCamposIcmsOpInterestadual,
    calcularIcmsOpInterestadual,
  ]);

  return (
    <ImpostoIcmsOpInterestadualContext.Provider
      value={{
        obterVisivbilidadeBloqueioCamposIcmsOpInterestadual,
        camposBloqueadosIcmsOpInterestadual,
        camposEscondidosIcmsOpInterestadual,
        handleCalcularIcmsOpInterestadual,
      }}
    >
      {children}
    </ImpostoIcmsOpInterestadualContext.Provider>
  );
};

function UseImpostoIcmsOpInterestadual(): IImpostoIcmsOpInterestadualHookProps {
  const context = useContext(ImpostoIcmsOpInterestadualContext);
  return context;
}

export { ImpostoIcmsOpInterestadualHook, UseImpostoIcmsOpInterestadual };
