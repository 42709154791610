import {
  FormatarCep,
  FormatarCnpj,
  FormatarCnpjCpf,
  FormatarCpf,
  FormatarTelefone,
  IPessoaFiltroRelatorioBackend,
  IPessoaPorRepresentanteCabecalhoRelatorio,
  IPessoaPorRepresentanteRelatorio,
  IPessoaFiltroRelatorioFrontend,
} from '@elogestor/util';
import React from 'react';
import PessoaPorRepresentanteRelatorioComunicador from '../../../../../../Comunicador/Comercial/Relatorios/Pessoas/PessoaPorRepresentanteRelatorio/PessoaPorRepresentanteRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PessoaPorRepresentantePDF from './PessoaPorRepresentantePDF';
import PessoaPorRepresentantePlanilha from './PessoaPorRepresentantePlanilha';

interface IRelatorios {
  handleExportCSV(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PessoaPorRepresentanteRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPessoaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPessoaPorRepresentanteRelatorio | null> => {
    const filtroBack: IPessoaFiltroRelatorioBackend = {
      mesDiaInicial: filtros.mesDiaInicial,
      mesDiaFinal: filtros.mesDiaFinal,
    };

    if (filtros.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaRepresentante) {
      filtroBack.listaIdRepresentante =
        filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaTags) {
      filtroBack.listaTextoTags =
        filtros.listaTags.listaValor.map((value) => value.textoTag) || [];
    }

    if (filtros?.cliente === 'true' || filtros?.cliente === 'false') {
      filtroBack.cliente = filtros?.cliente === 'true';
    }

    if (filtros?.fornecedor === 'true' || filtros?.fornecedor === 'false') {
      filtroBack.fornecedor = filtros?.fornecedor === 'true';
    }

    if (
      filtros?.transportador === 'true' ||
      filtros?.transportador === 'false'
    ) {
      filtroBack.transportador = filtros?.transportador === 'true';
    }

    if (
      filtros?.representante === 'true' ||
      filtros?.representante === 'false'
    ) {
      filtroBack.representante = filtros?.representante === 'true';
    }

    if (filtros?.produtor === 'true' || filtros?.produtor === 'false') {
      filtroBack.produtor = filtros?.produtor === 'true';
    }

    if (filtros?.motorista === 'true' || filtros?.motorista === 'false') {
      filtroBack.motorista = filtros?.motorista === 'true';
    }

    if (filtros?.tecnico === 'true' || filtros?.tecnico === 'false') {
      filtroBack.tecnico = filtros?.tecnico === 'true';
    }

    if (filtros?.ativo === 'true' || filtros?.ativo === 'false') {
      filtroBack.ativo = filtros?.ativo === 'true';
    }

    const response = await PessoaPorRepresentanteRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }
    const retorno = response as IPessoaPorRepresentanteRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dado) => {
        const listaPessoa = dado.listaPessoa.map((pessoa) => {
          const listaPessoaContato = pessoa.listaPessoaContato.map(
            (contato) => {
              return {
                ...contato,
                telefone: FormatarTelefone(contato.telefone),
              };
            }
          );

          const pessoaClientePessoa = {
            ...pessoa.pessoaCliente.pessoa,

            cnpjCpfVirtual: FormatarCnpjCpf(
              pessoa.pessoaCliente.pessoa.cnpjCpfVirtual
            ),
            cpf: FormatarCpf(pessoa.pessoaCliente.pessoa.cpf),
            cpnj: FormatarCnpj(pessoa.pessoaCliente.pessoa.cnpj),

            pessoaEnderecoPrincipal: {
              ...pessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal,

              cep: FormatarCep(
                pessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal.cep
              ),
            },
          };

          const pessoaCliente = {
            ...pessoa.pessoaCliente,

            pessoa: pessoaClientePessoa,
          };

          return {
            ...pessoa,
            telefone: FormatarTelefone(pessoa.telefone),
            pessoaCliente,
            listaPessoaContato,
          };
        });

        return {
          ...dado,
          listaPessoa,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IPessoaFiltroRelatorioFrontend,
    cabecalho: IPessoaPorRepresentanteCabecalhoRelatorio
  ): IPessoaPorRepresentanteCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaRepresentante &&
      filtros.listaRepresentante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.nomeRazaoSocial}`;
        })
        .join(', ');
    }

    if (filtros.listaTags && filtros.listaTags.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTag = filtros.listaTags.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PessoaPorRepresentantePlanilha,
      valores: dados.listaDados,
      nomeArquivo: 'PessoaPorRepresentante',
      unwind: ['listaPessoa', 'listaPessoa.listaPessoaContato'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PessoaPorRepresentantePlanilha,
      valores: dados.listaDados as any,
      nomeArquivo: 'PessoaPorRepresentante',
      unwind: ['listaPessoa', 'listaPessoa.listaPessoaContato'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorRepresentantePDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorRepresentantePDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PessoaPorRepresentanteRelatorio();
