import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-conta';

interface INotaFiscalSaidaObterContaComunicadorShow {
  id: string;
}

class NotaFiscalSaidaObterContaComunicador {
  public async show(
    params: INotaFiscalSaidaObterContaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaObterContaComunicador();
