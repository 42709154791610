import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import JSZip from 'jszip';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { UseReactSizeMeBodyHook } from '../../Hooks/ReactSizeMeBodyHook';
import { Divisor } from '../../Paginas/Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import InputEmailTag, { IInputEmail } from '../Inputs/TagsInput/InputEmailTag';
import InputArquivoTag from '../Inputs/TagsInput/InputArquivoTag';
import TextoLoading from '../TextoLoading';
import JanelaDetalhe from '../JanelaDetalhe';
import Input from '../Inputs/Input';
import InputSwitch from '../Inputs/InputSwitch';
import LoadingDiv from '../LoadingDiv';
import JanelaNavegacao from '../JanelaDetalhe/JanelaNavegacao';
import TratarErros from '../../Util/Erro/TratarErros';
import GetValidationErrors from '../../Util/Erro/GetValidationErrors';
import InputHiddenHtml from '../Inputs/InputHiddenHtml';
import { UseConfirmacao } from '../Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../Util/Toasts/ToastSucesso';
import EnviarEmailComunicador from '../../Comunicador/Comum/Comunicador/EnviarEmailComunicador';
import InputTextEditor, {
  IInputTextEditorRef,
} from '../Inputs/InputTextEditor';
import InputAutoCompleteContaEmail from '../Inputs/AutoComplete/Configuracao/InputAutoCompleteContaEmail';

interface IJanelaEnvioEmailProps {
  titulo?: string;
  mensagemPadrao?: string;
  ativo?: boolean;
  verificado?: boolean;
  direcionarBoletos?: boolean;
  direcionarDocumentosFiscais?: boolean;
  direcionarOrcamento?: boolean;
  direcionarPedidoVenda?: boolean;
  direcionarCotacaoCompra?: boolean;
  direcionarPedidoCompra?: boolean;
  direcionarOrdemServico?: boolean;
  isEnvioEmailBoleto?: boolean;
  onCarregarDadosInciais: (
    formRefEmail: React.RefObject<IFormCiaHandles>
  ) => Promise<void>;
  onAlterarDocumentosFiscais?: (
    formRefEmail: React.RefObject<IFormCiaHandles>
  ) => Promise<void>;
  onValidarEmail?: (
    formRefEmail: React.RefObject<IFormCiaHandles>
  ) => Promise<void>;
  onEnviarEmail?: (data: FormData) => Promise<void>;
  onFecharFormModal: () => void;
}

const JanelaEnvioEmail: React.FC<IJanelaEnvioEmailProps> = ({
  titulo = 'Envio de E-mail',
  mensagemPadrao,
  verificado = true,
  ativo = true,
  direcionarBoletos,
  direcionarDocumentosFiscais,
  direcionarOrcamento,
  direcionarPedidoVenda,
  direcionarCotacaoCompra,
  direcionarPedidoCompra,
  direcionarOrdemServico,
  isEnvioEmailBoleto = false,
  onCarregarDadosInciais,
  onAlterarDocumentosFiscais,
  onValidarEmail,
  onEnviarEmail,
  onFecharFormModal,
}) => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const formRef = useRef<IFormCiaHandles>(null);
  const [loading, setLoading] = useState(false);
  const inputTextoEditorRef = useRef<IInputTextEditorRef>(null);

  const handleCarregarDadosIniciais = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (onCarregarDadosInciais) await onCarregarDadosInciais(formRef);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [onCarregarDadosInciais]);

  const handleOnAlterarDocumentosFiscais =
    useCallback(async (): Promise<void> => {
      try {
        setLoading(true);

        if (onAlterarDocumentosFiscais)
          await onAlterarDocumentosFiscais(formRef);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [onAlterarDocumentosFiscais]);

  useEffect(() => {
    handleCarregarDadosIniciais();
  }, [handleCarregarDadosIniciais]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});
      const data = formRef.current?.getDataPesquisa() as any;

      const schema = Yup.object().shape({
        remetente: Yup.mixed().required('Remetente é Obrigatório!'),
        listaDestinatario: Yup.mixed()
          .nullable()
          .test({
            message: 'E-mail é obrigatório!',
            test: (value: any) => {
              if (value.listaValor.length === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });

      if (onValidarEmail) await onValidarEmail(formRef);

      if (!data.assunto) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja enviar o e-mail sem Assunto?
            </span>
          ),
        });

        if (!resposta) return false;
      }

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [abrirJanela, onValidarEmail]);

  const handleEnviarEmail = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleValidar())) {
        setLoading(false);
        return;
      }

      const formData = new FormData();

      const listaAnexos = formRef.current?.getFieldValue('listaAnexo');
      const compactarAnexos = formRef.current?.getFieldValue('compactarAnexos');
      if (
        listaAnexos &&
        listaAnexos.listaValor &&
        listaAnexos.listaValor.length > 0
      ) {
        if (!compactarAnexos) {
          for (let index = 0; index < listaAnexos.listaValor.length; index++) {
            const anexo = listaAnexos.listaValor[index];
            formData.append(anexo.nomeArquivo, anexo.file);
          }
        } else {
          const zip = new JSZip();

          for (let index = 0; index < listaAnexos.listaValor.length; index++) {
            const anexo = listaAnexos.listaValor[index];
            zip.file(anexo.nomeArquivo, anexo.file, { binary: true });
          }

          const arquivoZip = await zip.generateAsync({ type: 'blob' });
          formData.append('arquivos.zip', arquivoZip);
        }
      }

      formData.append('assunto', formRef.current?.getFieldValue('assunto'));
      const mensagem = inputTextoEditorRef.current?.obterValor() ?? '';
      formData.append('mensagem', mensagem);
      formData.append(
        'copiaEmail',
        formRef.current?.getFieldValue('copiaEmail')
      );

      formData.append(
        'remetente',
        formRef.current?.getFieldValueNomeObjeto('remetente').email
      );

      let nomeArquivo = formRef.current?.getFieldValue('nomeArquivo');
      if (!nomeArquivo) nomeArquivo = 'anexos';
      formData.append('nomeArquivo', nomeArquivo);

      const listaDestinatario =
        formRef.current?.getFieldValue('listaDestinatario');
      formData.append(
        'listaDestinatario',
        listaDestinatario.listaValor.map((valor: IInputEmail) => {
          return valor.email;
        })
      );

      const listaComCopia = formRef.current?.getFieldValue('listaComCopia');
      formData.append(
        'listaComCopia',
        listaComCopia.listaValor.map((valor: IInputEmail) => {
          return valor.email;
        })
      );

      const listaComCopiaOculta = formRef.current?.getFieldValue(
        'listaComCopiaOculta'
      );
      formData.append(
        'listaComCopiaOculta',
        listaComCopiaOculta.listaValor.map((valor: IInputEmail) => {
          return valor.email;
        })
      );

      await EnviarEmailComunicador.store({
        params: formData,
      });

      if (onEnviarEmail) await onEnviarEmail(formData);
      if (onFecharFormModal) onFecharFormModal();

      ToastSucesso('E-mail enviado com Sucesso!');
      setLoading(false);
    } catch (error) {
      if (!error.mensagem || !error.listaMensagem) {
        TratarErros({ mensagem: 'Erro ao enviar E-mail!' });
      } else {
        TratarErros(error);
      }

      setLoading(false);
    }
  }, [handleValidar, onEnviarEmail, onFecharFormModal]);

  return (
    <JanelaDetalhe
      onFecharFormModal={onFecharFormModal}
      titulo={titulo}
      tamanho="xl"
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <JanelaNavegacao
                containerStyle={{
                  display: 'flex',
                  justifyContent: telaPequena ? 'center' : 'flex-end',
                  alignItems: 'flex-end',
                  marginBottom: 10,
                }}
              />
            </Col>
          </Row>

          <FormCia ref={formRef}>
            <InputHiddenHtml name="nomeArquivo" />

            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputEmailTag
                    name="listaDestinatario"
                    label="Para: (destinatários para quem deseja enviar o e-mail)"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12}>
                  <InputEmailTag name="listaComCopia" label="CC: (com cópia)" />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <InputEmailTag
                    name="listaComCopiaOculta"
                    label="CCO: (com cópia oculta)"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={2} md={12} sm={12}>
                  <InputSwitch
                    label="Receber cópia"
                    name="copiaEmail"
                    ativo="Sim"
                    inativo="Não"
                  />
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <InputSwitch
                    label="Compactar Anexos"
                    name="compactarAnexos"
                    ativo="Sim"
                    inativo="Não"
                  />
                </Col>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  style={{ display: isEnvioEmailBoleto ? '' : 'none' }}
                >
                  <InputSwitch
                    label="Incluir Doc. Fiscal"
                    name="incluirDocumentoFiscal"
                    ativo="Sim"
                    inativo="Não"
                    onChange={handleOnAlterarDocumentosFiscais}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <InputArquivoTag label="Anexos" name="listaAnexo" />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputAutoCompleteContaEmail
                    label="De: (remetente)"
                    name="remetente"
                    nomeObjeto="remetente"
                    ativo={ativo}
                    verificado={verificado}
                    direcionarBoletos={direcionarBoletos}
                    direcionarDocumentosFiscais={direcionarDocumentosFiscais}
                    direcionarOrcamento={direcionarOrcamento}
                    direcionarPedidoVenda={direcionarPedidoVenda}
                    direcionarCotacaoCompra={direcionarCotacaoCompra}
                    direcionarPedidoCompra={direcionarPedidoCompra}
                    direcionarOrdemServico={direcionarOrdemServico}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Input label="Assunto" name="assunto" />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12}>
                  <InputTextEditor
                    ref={inputTextoEditorRef}
                    label="Mensagem"
                    defaultValue={mensagemPadrao}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleEnviarEmail}
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>Enviar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default JanelaEnvioEmail;
