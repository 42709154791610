import {
  FormatarEnum,
  SituacaoNotaFiscalEntradaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoMovimentoEntradaSaidaEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select/index';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFornecedor';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import Input from '../../../../../Componentes/Inputs/Input';
import InputAutoCompleteTipoNota from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();

  const periodoDataEmissaoRef = useRef<any>(null);
  const periodoDataEntradaRef = useRef<any>(null);
  const periodoDataLancamentoRef = useRef<any>(null);
  const periodoDataEntradaEstabelecimentoRef = useRef<any>(null);

  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const situacao = parametros.pesquisaAvancada?.situacao;
      if (!situacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: situacao,
        });
      }

      const numeroNota = parametros.pesquisaAvancada?.numeroNota;
      if (numeroNota) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Nota',
          valor: numeroNota,
        });
      }

      const tipoNotaFiscalEntrada =
        parametros.pesquisaAvancada?.tipoNotaFiscalEntrada;
      if (!tipoNotaFiscalEntrada) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Entrada',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Entrada',
          valor: tipoNotaFiscalEntrada,
        });
      }

      const listaFornecedor = parametros.pesquisaAvancada?.listaFornecedor;
      if (listaFornecedor.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Fornecedores',
          valor: listaFornecedor.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.nomeRazaoSocial}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCidade = parametros.pesquisaAvancada?.listaCidade;
      if (listaCidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cidades',
          valor: listaCidade.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.cidadeUf}; `;
            return acumulador;
          }, ''),
        });
      }

      const periodoDataEmissao = periodoDataEmissaoRef.current?.getPeriodo();
      if (periodoDataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Emissão',
          valor: periodoDataEmissao,
        });
      }

      const periodoDataEntrada = periodoDataEntradaRef.current?.getPeriodo();
      if (periodoDataEntrada) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Saída',
          valor: periodoDataEntrada,
        });
      }

      const periodoDataLancamento =
        periodoDataLancamentoRef.current?.getPeriodo();
      if (periodoDataLancamento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Lançamento',
          valor: periodoDataLancamento,
        });
      }

      const periodoDataEntradaEstabelecimento =
        periodoDataEntradaEstabelecimentoRef.current?.getPeriodo();
      if (periodoDataEntradaEstabelecimento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Ent. Est.',
          valor: periodoDataEntradaEstabelecimentoRef,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Select
                  name="situacao"
                  label="Situação"
                  options={FormatarEnum({
                    enumObj: SituacaoNotaFiscalEntradaEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Input
                  name="numeroNota"
                  label="Número da Nota"
                  placeholder="Número da Nota"
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Tipo de Entrada"
                  name="tipoNotaFiscalEntrada"
                  options={FormatarEnum({
                    enumObj: TipoPropriaTerceiroNotaFiscalEntradaEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagFornecedor
                  label="Fornecedores"
                  name="listaFornecedor"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCidade
                  label="Cidades"
                  name="listaCidade"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagProduto
                  name="listaProduto"
                  label="Produtos"
                  onChangeListaItemAtual={handleChangeProduto}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Filtro Código/Descrição"
                  name="tipoBuscaCodigoDescricao"
                  options={FormatarEnum({
                    enumObj: TipoBuscaCodigoDescricaoEnum,
                    nullavel: false,
                    todos: false,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Input name="codigo" label="Código" placeholder="Código" />
              </Col>

              <Col lg={12} md={12}>
                <Input
                  name="descricao"
                  label="Descrição"
                  placeholder="Descrição"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTipoNota
                  label="Tipo de Nota"
                  name="tipoNota"
                  placeholder="Tipo de Nota"
                  nomeObjeto="tipoNota"
                  permitirAdicionar={false}
                  onChangeItemAtual={() => {
                    refresh();
                  }}
                  filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.entrada}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTipoNotaMotivo
                  label="Motivo"
                  name="tipoNotaMotivo"
                  placeholder="Motivo"
                  nomeObjeto="tipoNotaMotivo"
                  disabled={!formRef.current?.getFieldValue('tipoNota')}
                  idTipoNota={formRef.current?.getFieldValue('tipoNota')}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Dt. Entrada Inicial"
                  labelDataFinal="Dt. Entrada Final"
                  nomeDataInicial="dataEntradaInicial"
                  nomeDataFinal="dataEntradaFinal"
                  ref={periodoDataEntradaRef}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Lançamento Inicial"
                  labelDataFinal="Data Lançamento Final"
                  nomeDataInicial="dataLancamentoInicial"
                  nomeDataFinal="dataLancamentoFinal"
                  ref={periodoDataLancamentoRef}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Dt. Ent. Est. Inicial"
                  labelDataFinal="Dt. Ent. Est. Final"
                  nomeDataInicial="dataEntradaEstabelecimentoInicial"
                  nomeDataFinal="dataEntradaEstabelecimentoFinal"
                  ref={periodoDataEntradaEstabelecimentoRef}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
