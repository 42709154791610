import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/upload-xml';

interface IImportacaoXmlTerceiroUploadXmlComunicadorUpdate {
  params: any;
}

class ImportacaoXmlTerceiroUploadXmlComunicador {
  public async update(
    params: IImportacaoXmlTerceiroUploadXmlComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroUploadXmlComunicador();
