import styled from 'styled-components';
import DivisorPadrao from '../../../../../../../Componentes/Divisor';

export const InputArquivo = styled.button`
  border: 0;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 5px 0;
  padding: 6px 20px;
  height: 35px;
  width: 100%;
  max-width: 300px;
`;

export const Divisor = styled(DivisorPadrao)`
  .carousel .control-next.control-arrow:before {
    border-left: 15px solid black;
  }

  .carousel .control-arrow:before {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 15px solid black;
  }

  .carousel .slide img {
    pointer-events: all;
  }
`;
