import {
  FormatarEnum,
  FormatarRemovendoEnum,
  ICustoConfiguracaoValoresAlterar,
  ModalidadeCalculoCustoEnum,
  RegimeTributarioCustoEnum,
  TipoCustoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import Select from '../../../../../../Componentes/Select';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import CustoConfiguracaoItemCreditarHook from '../ListaDetalheItemCreditar/Hooks';
import CustoConfiguracaoItemCreditarLista from '../ListaDetalheItemCreditar/Lista';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';

type IProps = IMain<ICustoConfiguracaoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosCustoConfiguracao: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleOnAlterarTipoCusto = useCallback(
    async (tipoCusto: string) => {
      if (
        tipoCusto === TipoCustoEnum.contabilIcmsIpi ||
        tipoCusto === TipoCustoEnum.contabilIr ||
        tipoCusto === TipoCustoEnum.valorCustoMedio
      ) {
        formRef.current?.setFieldValue(
          'modalidadeCalculo',
          ModalidadeCalculoCustoEnum.custoMedio
        );
      } else {
        formRef.current?.setFieldValue(
          'modalidadeCalculo',
          ModalidadeCalculoCustoEnum.ultimaCompra
        );
      }
    },
    [formRef]
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Regime Tributário"
                name="regimeTributario"
                options={FormatarEnum({
                  enumObj: RegimeTributarioCustoEnum,
                })}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Tipo de Custo"
                name="tipoCusto"
                options={FormatarRemovendoEnum({
                  enumObj: TipoCustoEnum,
                  enumRemover: [TipoCustoEnum.reposicao],
                })}
                onChange={(event) => {
                  handleOnAlterarTipoCusto(event.currentTarget.value);
                }}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <InputLabel
                label="Modalidade de Cálculo"
                name="modalidadeCalculo"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(modalidadeCalculo = '') => {
                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      <span
                        style={{
                          color:
                            modalidadeCalculo ===
                            ModalidadeCalculoCustoEnum.custoMedio
                              ? 'blue'
                              : 'green',
                        }}
                      >
                        {modalidadeCalculo}
                      </span>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col md={12}>
              <CustoConfiguracaoItemCreditarHook>
                <CustoConfiguracaoItemCreditarLista name="listaCustoConfiguracaoItemCreditar" />
              </CustoConfiguracaoItemCreditarHook>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
