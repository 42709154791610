"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoUsuarioEnum;
(function (TipoUsuarioEnum) {
    TipoUsuarioEnum["eloGestor"] = "Elo Gestor";
    TipoUsuarioEnum["gestor"] = "Gestor";
    TipoUsuarioEnum["colaborador"] = "Colaborador";
    TipoUsuarioEnum["contabilidade"] = "Contabilidade";
    TipoUsuarioEnum["representante"] = "Representante";
})(TipoUsuarioEnum || (TipoUsuarioEnum = {}));
exports.default = TipoUsuarioEnum;
