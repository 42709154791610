import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { IConfiguracaoCREnologicoCultivarLista } from '@elogestor/util';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ConfiguracaoCREnologicoCultivarComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Comunicador/ConfiguracaCREnologicoCultivarComunicador';
import ConfiguracaoCREnologicoCultivarLogComunicador from '../../../../../Comunicador/Suprimentos/Sisdevin/ConfiguracaoCREnologicoCultivar/Log/Comunicador/ConfiguracaoCREnologicoCultivarLogComunicador';

const GrupoCodigoRastreabilidadeLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ConfiguracaoCREnologicoCultivarComunicador.index(
          {
            params: {
              pesquisaAvancada,
              order,
              descAsc,
              limite,
              pagina,
              textoPesquisa,
            },
          }
        );

        const dados = response.dados[0].map(
          (data: IConfiguracaoCREnologicoCultivarLista) => {
            const { produtoConfiguracaoProdutoEnologicoSisdevin: enologico } =
              data;

            const { produtoConfiguracaoCultivarSisdevin: cultivar } = data;

            const { produtoConfiguracaoBebidaSisdevin: bebida } = data;

            return {
              id: data.id,
              codigo: <div className="lista-valor">{data.codigo}</div>,
              descricao: <div className="lista-texto">{data.descricao}</div>,
              tipo: <div className="lista-texto">{data.tipo}</div>,
              crCodigoCultivar: (
                <div className="lista-texto">
                  {bebida &&
                    bebida.listaProdutoConfiguracaoBebidaRastreabilidadeSisdevin.map(
                      (item) => {
                        if (item.codigoRastreabilidadeSisdevin) {
                          return (
                            <div
                              key={
                                item.codigoRastreabilidadeSisdevin
                                  .codigoRastreabilidade
                              }
                            >
                              {
                                item.codigoRastreabilidadeSisdevin
                                  .codigoRastreabilidade
                              }
                              ;
                            </div>
                          );
                        }

                        if (item.grupoProdutoSisdevin) {
                          const listaCr =
                            item.grupoProdutoSisdevin.listaGrupoProdutoCodigoRastreabilidade.reduce(
                              (acumulador, valorAtual) => {
                                acumulador += `${valorAtual.codigoRastreabilidadeSisdevin.codigoRastreabilidade}; `;
                                return acumulador;
                              },
                              ''
                            );

                          return (
                            <div key={`${item.grupoProdutoSisdevin.id}-lista`}>
                              {listaCr}
                            </div>
                          );
                        }

                        return null;
                      }
                    )}
                  {cultivar && cultivar.cultivarSisdevin.codigo}
                  {enologico && enologico.produtoEnologico.codigo}
                </div>
              ),
              crEnologicoCultivar: (
                <div className="lista-texto">
                  {bebida &&
                    bebida.listaProdutoConfiguracaoBebidaRastreabilidadeSisdevin.map(
                      (item) => {
                        if (item.codigoRastreabilidadeSisdevin) {
                          return (
                            <div key={item.codigoRastreabilidadeSisdevin.id}>
                              {item.codigoRastreabilidadeSisdevin.descricao};
                            </div>
                          );
                        }

                        if (item.grupoProdutoSisdevin) {
                          return (
                            <div key={item.grupoProdutoSisdevin.id}>
                              {item.grupoProdutoSisdevin.descricao};
                            </div>
                          );
                        }

                        return null;
                      }
                    )}
                  {cultivar && cultivar.cultivarSisdevin.descricao}
                  {enologico && enologico.produtoEnologico.descricao}
                </div>
              ),
              litragemGrauFator: (
                <div className="lista-texto">
                  {bebida &&
                    Number(bebida.litragem).FormatarParaPtBr({
                      minimoCasasDecimais: 3,
                      maximoCasasDecimais: 3,
                    })}
                  {cultivar && cultivar.grau}
                  {enologico &&
                    Number(enologico.fatorConversao).FormatarParaPtBr({
                      minimoCasasDecimais: 8,
                      maximoCasasDecimais: 8,
                    })}
                </div>
              ),
              'produtoConfiguracaoBebidaSisdevin.tipoRecipiente': (
                <div
                  className="lista-texto"
                  style={!bebida ? { textAlign: 'center' } : {}}
                >
                  {bebida && bebida.tipoRecipiente}
                  {cultivar && ''}
                  {enologico && ''}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await ConfiguracaoCREnologicoCultivarComunicador.delete(
                          {
                            id: data.id,
                          }
                        );

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          ConfiguracaoCREnologicoCultivarLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      !permissoes.SuprimentosSisdevinConfiguracaoCrEnologicoCultivar
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.SuprimentosSisdevinConfiguracaoCrEnologicoCultivar]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosConfiguracaoCREnologicoCultivar' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Configuração de CR / enológico / cultivar"
          permissao={
            permissoes.SuprimentosSisdevinConfiguracaoCrEnologicoCultivar
          }
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ConfiguracaoCREnologicoCultivarLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default GrupoCodigoRastreabilidadeLista;
