import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarCpf,
  FormatarDataHoraCompletaParaPtBr,
  IManifestoTransporteImpressao,
  SituacaoManifestoTransporteEnum,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { usuarioLogado } from '../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IManifestoTransporteImpressaoPDF {
  dados: IManifestoTransporteImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    height: 95,
    width: '100%',
    flexDirection: 'row',
    border: '1 solid black',
    borderRadius: 5,
  },

  data: {
    border: '1 solid black',
    borderRadius: 5,
    flex: 1,
  },
});

const ManifestoTransporteImpressaoPDF: React.FC<
  IManifestoTransporteImpressaoPDF
> = ({ dados }) => {
  const canvasBarCode = document.createElement('canvas');
  const canvasQrCode = document.createElement('canvas');

  JsBarcode(canvasBarCode, dados.chaveAcesso, {
    fontSize: 0,
    format: 'CODE128C',
  });
  QRCode.toCanvas(canvasQrCode, dados.qrCode);

  const barCode = canvasBarCode.toDataURL();
  const qrCode = canvasQrCode.toDataURL();

  const Imagem: React.FC<{ path?: string }> = ({ path }) => {
    if (!path) return null;

    return <Image src={path} style={{ width: 120, borderRadius: 7 }} />;
  };

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            <Imagem path={dados.caminhoLogo} />
          </View>

          <View style={{ width: '75%', alignItems: 'flex-start' }}>
            <View style={{ marginTop: '5pt', alignItems: 'flex-start' }}>
              <Text
                style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 8 }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '12%' }}>
                  CNPJ/CPF:
                </Text>
                <Text style={{ fontSize: 9, width: '30%' }}>
                  {FormatarCnpjCpf(dados.empresa.cnpjCpf)}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '4%' }}>
                  IE:
                </Text>
                <Text style={{ fontSize: 9, width: '30%' }}>
                  {dados.empresa.inscricaoEstadual}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '9%' }}>
                  RNTRC:
                </Text>
                <Text style={{ fontSize: 9, width: '15%' }}>
                  {dados.empresa.rntrc}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '18%' }}>
                  RAZÃO SOCIAL:
                </Text>
                <Text style={{ fontSize: 9, width: '82%' }}>
                  {dados.empresa.nomeRazaoSocial}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '17%' }}>
                  LOGRADOURO:
                </Text>
                <Text style={{ fontSize: 9, width: '59%' }}>
                  {dados.empresa.empresaEndereco.logradouro}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '4%' }}>
                  Nº:
                </Text>
                <Text style={{ fontSize: 9, width: '20%' }}>
                  {dados.empresa.empresaEndereco.numero}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '19%' }}>
                  COMPLEMENTO:
                </Text>
                <Text style={{ fontSize: 9, width: '81%' }}>
                  {dados.empresa.empresaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '10%' }}>
                  BAIRRO:
                </Text>
                <Text style={{ fontSize: 9, width: '59%' }}>
                  {dados.empresa.empresaEndereco.bairro}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '14%' }}>
                  MUNICÍPIO:
                </Text>
                <Text style={{ fontSize: 9, width: '62%' }}>
                  {`${dados.empresa.empresaEndereco.cidade.nome} - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 'bold', width: '6%' }}>
                  CEP:
                </Text>
                <Text style={{ fontSize: 9, width: '18%' }}>
                  {FormatarCep(dados.empresa.empresaEndereco.cep)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            border: '1 solid black',
            borderRadius: 5,
            height: 25,
          }}
        >
          <View
            style={{
              width: '25%',
              fontSize: 10,
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>DAMDFE</Text>
          </View>

          <View
            style={{
              fontSize: 10,
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>
              Documento Auxiliar de Manifesto Eletrônico de Documento Fiscal
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
            height: 130,
          }}
        >
          <View style={{ height: '80%', borderBottom: '1 solid black' }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '84%', borderRight: '1 solid black' }}>
                <View style={{ flexDirection: 'column' }}>
                  <View
                    style={{
                      height: '75%',
                      fontSize: 8,
                      borderBottom: '1 solid black',
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={{
                        width: '80%',
                        height: 55,
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{ marginTop: '5px' }}>
                        CONTROLE DO FISCO
                      </Text>
                      <Image src={barCode} />
                    </View>
                  </View>

                  <View style={{ height: '25%', fontSize: 8 }}>
                    <Text style={{ marginLeft: '5px' }}>CHAVE DE ACESSO</Text>

                    <View style={{ fontSize: 10, alignItems: 'center' }}>
                      <Text>{FormatarChaveAcesso(dados.chaveAcesso)}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: '16%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <View style={{ width: '100%' }}>
                  <Image style={{ height: 90, width: 90 }} src={qrCode} />
                </View>
              </View>
            </View>
          </View>

          <View style={{ height: '20%', fontSize: 8 }}>
            <Text style={{ marginLeft: '5px' }}>PROTOCOLO DE AUTORIZAÇÃO</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              {dados.numeroProtocolo && dados.dataHoraRetorno && (
                <Text>
                  {`${
                    dados.numeroProtocolo
                  } - ${FormatarDataHoraCompletaParaPtBr(
                    dados.dataHoraRetorno
                  )}`}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{
              width: '10%',
              fontSize: 8,
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>Modelo</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>{dados.serie.modeloDocumento.codigo}</Text>
            </View>
          </View>

          <View
            style={{
              width: '10%',
              fontSize: 8,
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>Série</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>{dados.serie.codigo}</Text>
            </View>
          </View>

          <View
            style={{
              fontSize: 8,
              width: '10%',
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>Número</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>{dados.numero}</Text>
            </View>
          </View>

          <View
            style={{
              width: '10%',
              fontSize: 8,
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>F/L</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text
                render={({ pageNumber, totalPages }) => {
                  return `${pageNumber}/${totalPages}`;
                }}
                fixed
              />
            </View>
          </View>

          <View
            style={{
              width: '40%',
              fontSize: 8,
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>Data e Hora de Emissão</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>
                {FormatarDataHoraCompletaParaPtBr(dados.dataHoraEmissao)}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '10%',
              fontSize: 8,
              borderRight: '1 solid black',
            }}
          >
            <Text style={{ marginLeft: '5px' }}>UF Carrega</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>{dados.estadoCarregaSigla}</Text>
            </View>
          </View>

          <View style={{ width: '10%', fontSize: 8 }}>
            <Text style={{ marginLeft: '5px' }}>UF Descarrega</Text>

            <View style={{ fontSize: 10, alignItems: 'center' }}>
              <Text>{dados.estadoDescarregaSigla}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
            height: 180,
          }}
        >
          <View
            style={{
              height: '10%',
              fontSize: 8,
              borderBottom: '1 solid black',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>Modal Rodoviário de Carga</Text>
          </View>

          <View
            style={{
              height: '15%',
              flexDirection: 'row',
              borderBottom: '1 solid black',
            }}
          >
            <View
              style={{
                width: '16.5%',
                fontSize: 8,
                borderRight: '1 solid black',
              }}
            >
              <Text style={{ marginLeft: '5px' }}>Qtde. CT-e</Text>

              <View style={{ fontSize: 10, alignItems: 'center' }}>
                <Text>{dados.quantidadeCte}</Text>
              </View>
            </View>

            <View
              style={{
                width: '17%',
                fontSize: 8,
                borderRight: '1 solid black',
              }}
            >
              <Text style={{ marginLeft: '5px' }}>Qtde. NF-e</Text>

              <View style={{ fontSize: 10, alignItems: 'center' }}>
                <Text>{dados.quantidadeNfe}</Text>
              </View>
            </View>

            <View
              style={{
                width: '16.5%',
                fontSize: 8,
                borderRight: '1 solid black',
              }}
            >
              <Text style={{ marginLeft: '5px' }}>Peso Total</Text>

              <View style={{ fontSize: 10, alignItems: 'center' }}>
                <Text>
                  {Number(dados.pesoBrutoTotalCarga).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ height: '75%', flexDirection: 'row' }}>
            <View
              style={{
                width: '50%',
                fontSize: 8,
                borderRight: '1 solid black',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    height: '15%',
                    fontSize: 8,
                    borderBottom: '1 solid black',
                    justifyContent: 'center',
                  }}
                >
                  <Text style={{ marginLeft: '5px' }}>Veículo</Text>
                </View>

                <View
                  style={{
                    height: '20%',
                    fontSize: 8,
                    borderBottom: '1 solid #050404',
                  }}
                >
                  <View style={{ height: '100%', flexDirection: 'row' }}>
                    <View
                      style={{
                        width: '50%',
                        borderRight: '1 solid black',
                      }}
                    >
                      <Text style={{ marginLeft: '5px' }}>Placa</Text>

                      <View style={{ fontSize: 10, alignItems: 'center' }}>
                        <Text>{dados.veiculoTracao.placa}</Text>
                      </View>
                    </View>

                    <View style={{ width: '50%' }}>
                      <Text style={{ marginLeft: '5px' }}>RTRNC</Text>

                      <View style={{ fontSize: 10, alignItems: 'center' }}>
                        <Text>{dados.rntrc}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    height: '15%',
                    fontSize: 8,
                    borderBottom: '1 solid black',
                    justifyContent: 'center',
                  }}
                >
                  <Text style={{ marginLeft: '5px' }}>Vale Pedágios(s)</Text>
                </View>

                <View style={{ height: '50%', flexDirection: 'row' }}>
                  <View
                    style={{ width: '33.5%', borderRight: '1 solid black' }}
                  />
                  <View
                    style={{ width: '34%', borderRight: '1 solid black' }}
                  />
                  <View style={{ width: '33.5%' }} />
                </View>
              </View>
            </View>

            <View style={{ width: '50%', fontSize: 8 }}>
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    height: '15%',
                    justifyContent: 'center',
                    borderBottom: '1 solid black',
                  }}
                >
                  <Text style={{ marginLeft: '5px' }}>Condutor</Text>
                </View>

                <View style={{ height: '10%', borderBottom: '1 solid black' }}>
                  <View style={{ height: '100%', flexDirection: 'row' }}>
                    <View
                      style={{
                        width: '35%',
                        justifyContent: 'center',
                        borderRight: '1 solid black',
                      }}
                    >
                      <Text style={{ marginLeft: '5px' }}>CPF</Text>
                    </View>

                    <View style={{ width: '65%', justifyContent: 'center' }}>
                      <Text style={{ marginLeft: '5px' }}>Nome</Text>
                    </View>
                  </View>
                </View>

                <View style={{ height: '75%' }}>
                  <View style={{ height: '100%', flexDirection: 'row' }}>
                    <View
                      style={{
                        width: '35%',
                        borderRight: '1 solid black',
                        alignItems: 'center',
                      }}
                    >
                      {dados.listaManifestoTransporteMotorista?.map(
                        (dado, index) => {
                          return (
                            <View key={index}>
                              <View
                                style={{ width: '35%', alignItems: 'center' }}
                              >
                                <Text>{FormatarCpf(dado.pessoa.cpf)}</Text>
                              </View>
                              <View style={{ width: '1%' }} />
                            </View>
                          );
                        }
                      )}
                    </View>

                    <View style={{ width: '65%' }}>
                      {dados.listaManifestoTransporteMotorista?.map(
                        (dado, index) => {
                          return (
                            <View key={index}>
                              <View style={{ width: '65%', marginLeft: '5px' }}>
                                <Text>{dado.pessoa.nomeRazaoSocial}</Text>
                              </View>
                              <View style={{ width: '1%' }} />
                            </View>
                          );
                        }
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View style={{ fontSize: 8, marginLeft: '5px' }}>
            <Text>Observação</Text>
            <Text>{dados.observacoes}</Text>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em {dados.dataAtual} às
            {` ${dados.horaAtual}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>

        {(dados.situacao === SituacaoManifestoTransporteEnum.naoTransmitido ||
          dados.situacao === SituacaoManifestoTransporteEnum.rejeitado) && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.3,
              }}
            >
              <Text>VISUALIZAÇÃO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
            </View>
          </View>
        )}

        {dados.situacao === SituacaoManifestoTransporteEnum.cancelado && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.3,
              }}
            >
              <Text>CANCELADO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default ManifestoTransporteImpressaoPDF;
