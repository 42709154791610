import { IPessoaEnderecoLista, FormatarCep } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Modal } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import PessoaEnderecoComunidador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Endereco/Comunicador/PessoaEnderecoComunidador';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';

interface IListaDetalheEnderecoProps {
  onFecharFormModalLista(): void;
}

const PessoaEnderecoLista: React.FC<IListaDetalheEnderecoProps> = ({
  onFecharFormModalLista,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const {
    getIdDetalheRegistro,
    setLoading,
    loading: formPrincipalLoading,
  } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IPessoaEnderecoLista[]>([]);

  const [listaValor, setListaValor] = useState<IPessoaEnderecoLista[]>([]);

  const handleCarregarListaDados = useCallback(async () => {
    const idDetalheRegistro = getIdDetalheRegistro();
    if (!idDetalheRegistro) return;

    try {
      setLoading(true);

      const response = await PessoaEnderecoComunidador.index({
        idDetalheRegistro,
      });
      setListaValor(response.listaPessoaEndereco);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, setLoading]);

  useEffect(() => {
    handleCarregarListaDados();
  }, [getIdDetalheRegistro, handleCarregarListaDados]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    const enderecoPrincipal =
      formRefDetalhe.current?.getFieldValue('enderecoPrincipal');
    setIdDetalheRegistro('');
    setShowFormModal(false);

    if (!enderecoPrincipal) {
      handleCarregarListaDados();
    } else {
      onFecharFormModalLista();
    }
  }, [
    formRefDetalhe,
    handleCarregarListaDados,
    onFecharFormModalLista,
    setIdDetalheRegistro,
  ]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  return (
    <JanelaDetalhe
      titulo="Lista de Endereços"
      tamanho="xl"
      onFecharFormModal={onFecharFormModalLista}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <BtnPadraoButton
          style={{ maxWidth: 250 }}
          type="button"
          className="btn-padrao btn-verde btn-adicionar"
          onClick={handleAdicionar}
          disabled={!permissao?.inclui || formPrincipalLoading || loading}
        >
          <TextoLoading loading={formPrincipalLoading || loading}>
            <IoMdAddCircleOutline />
            <span style={{ marginLeft: 10 }}>Adicionar</span>
          </TextoLoading>
        </BtnPadraoButton>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>CEP</span>
                </th>
                <th className="lista-texto" style={{ width: '15%' }}>
                  <span>Cidade</span>
                </th>
                <th className="lista-texto" style={{ width: '5%' }}>
                  <span>UF</span>
                </th>
                <th className="lista-texto" style={{ width: '25%' }}>
                  <span>Logradouro</span>
                </th>
                <th className="lista-texto" style={{ width: '5%' }}>
                  <span>Numero</span>
                </th>
                <th className="lista-texto" style={{ width: '15%' }}>
                  <span>Bairro</span>
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Complemento</span>
                </th>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Site</span>
                </th>
                <th className="lista-booleano" style={{ width: '5%' }}>
                  <span>Principal</span>
                </th>
                <th className="lista-acoes">
                  <span>Ações</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaValor.map((endereco, index) => {
                return (
                  <tr
                    key={v4()}
                    onClick={() => {
                      if (formPrincipalLoading || loading) return;
                      handleEditar(endereco.id);
                    }}
                    className={
                      formPrincipalLoading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>{FormatarCep(String(endereco.cep))}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.cidade?.nome}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.cidade?.estado.sigla}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.logradouro}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.numero}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.bairro}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.complemento}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{endereco.site}</div>
                    </td>
                    <td className="lista-booleano">
                      <div
                        className="lista-booleano"
                        style={{
                          color: endereco.enderecoPrincipal ? 'green' : 'red',
                        }}
                      >
                        {endereco.enderecoPrincipal ? 'Sim' : 'Não'}
                      </div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleExcluir(endereco.id);
                              handleCarregarListaDados();
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipalLoading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>

        {showFormModal && (
          <Detalhe
            onSalvarFormModal={handleSalvarFormModal}
            onFecharFormModal={handleFecharFormModal}
            onLimparFormModal={handleLimparFormModal}
          />
        )}
      </Modal.Body>
    </JanelaDetalhe>
  );
};

export default PessoaEnderecoLista;
