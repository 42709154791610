import React from 'react';
import { IImpressaoRomaneioCarga } from '@elogestor/util';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import NotaFiscalSaidaRomaneioCargaImpressaoPDF from './NotaFiscalSaidaRomaneioCargaImpressaoPDF';

interface IImpressaoNotaFiscalSaidaRomaneioCarga {
  handleVisualizarPdf(data: IImpressaoRomaneioCarga): Promise<void>;
  handleObterBlobPdf(dados: IImpressaoRomaneioCarga): Promise<Blob | undefined>;
}

const ImpressaoNotaFiscalSaidaRomaneioCarga =
  (): IImpressaoNotaFiscalSaidaRomaneioCarga => {
    async function handleVisualizarPdf(
      dados: IImpressaoRomaneioCarga
    ): Promise<void> {
      const relatorio = await PDF({
        documento: <NotaFiscalSaidaRomaneioCargaImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IImpressaoRomaneioCarga
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <NotaFiscalSaidaRomaneioCargaImpressaoPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoNotaFiscalSaidaRomaneioCarga();
