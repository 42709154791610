"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoNomeArquivoXmlEnum;
(function (TipoNomeArquivoXmlEnum) {
    TipoNomeArquivoXmlEnum["den"] = "den";
    TipoNomeArquivoXmlEnum["nfe"] = "nfe";
    TipoNomeArquivoXmlEnum["procNFe"] = "procNFe";
    TipoNomeArquivoXmlEnum["procEventoNFe"] = "procEventoNFe";
    TipoNomeArquivoXmlEnum["procInutNFe"] = "procInutNFe";
    TipoNomeArquivoXmlEnum["cte"] = "cte";
    TipoNomeArquivoXmlEnum["procCTe"] = "procCTe";
    TipoNomeArquivoXmlEnum["procEventoCTe"] = "procEventoCTe";
    TipoNomeArquivoXmlEnum["procInutCTe"] = "procInutCTe";
    TipoNomeArquivoXmlEnum["mdfe"] = "mdfe";
    TipoNomeArquivoXmlEnum["procMDFe"] = "procMDFe";
    TipoNomeArquivoXmlEnum["procEventoMDFe"] = "procEventoMDFe";
})(TipoNomeArquivoXmlEnum || (TipoNomeArquivoXmlEnum = {}));
exports.default = TipoNomeArquivoXmlEnum;
