import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/tabela-preco/regra-escolha-tabela-preco/obter-conforme-criterios';

interface IRegraEscolhaTabelaPrecoObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia: Date;
    idProduto: string;
    idPessoa: string;
    listaIdPessoaRepresentante: string[];
    destinado: string;
  };
}

class RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador {
  public async show(
    params: IRegraEscolhaTabelaPrecoObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador();
