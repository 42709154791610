import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/impressao/paisagem-com-foto-produto';

interface IOrcamentoPaisagemComFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class OrcamentoPaisagemComFotoProdutoImpressaoComunicador {
  public async show(
    params: IOrcamentoPaisagemComFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoPaisagemComFotoProdutoImpressaoComunicador();
