import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/documentos-referenciados';

interface INotaFiscalEntradaDocumentosReferenciadosComunicadorIndex {
  params: {
    idValor: string;
  };
}

interface INotaFiscalEntradaDocumentosReferenciadosComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaDocumentosReferenciadosComunicadorStore {
  params: any;
}

interface INotaFiscalEntradaDocumentosReferenciadosComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalEntradaDocumentosReferenciadosComunicadorDelete {
  id: string;
}

class NotaFiscalEntradaDocumentosReferenciadosComunicador {
  public async index(
    params: INotaFiscalEntradaDocumentosReferenciadosComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: INotaFiscalEntradaDocumentosReferenciadosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalEntradaDocumentosReferenciadosComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaDocumentosReferenciadosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalEntradaDocumentosReferenciadosComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaDocumentosReferenciadosComunicador();
