import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/obter-conta';

interface IOrcamentoObterContaComunicadorShow {
  id: string;
}

class OrcamentoObterContaComunicador {
  public async show(params: IOrcamentoObterContaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoObterContaComunicador();
