import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import {
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import SimplesNacionalTab from './SimplesNacionalTab';
import SimplesExcessoSubLimiteTab from './SimplesExcessoSubLimiteTab';
import LucroPresumidoTab from './LucroPresumidoTab';
import LucroRealTab from './LucroRealTab';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IImpostoDocumentoTab {
  bloquearCamposPorImportacao: boolean;
}

const ImpostoDocumentoTab: React.FC<IImpostoDocumentoTab> = () => {
  const { formRef } = UseForm();

  const ufEstadoOrigem = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroRemetenteEndereco.cidade.estado.sigla'
  );
  const ufEstadoDestino = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroDestinatarioEndereco.cidade.estado.sigla'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="conhecimentoTransporteTerceiroImpostoIcmsFiscal.idOperacaoFiscal"
              nomeObjeto="conhecimentoTransporteTerceiroImpostoIcmsFiscal.operacaoFiscal"
              placeholder="CFOP"
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.entrada}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem,
                ufEstadoDestino,
              })}
            />
          </Col>
        </Row>
      </Divisor>

      <Tabs id={v4()} defaultActiveKey="simplesNacional">
        <Tab eventKey="simplesNacional" title="Simples Nacional">
          <SimplesNacionalTab />
        </Tab>

        <Tab
          eventKey="simplesNacionalExcessoSubLimite"
          title="Simples Nacional Excesso do Sublimite"
        >
          <SimplesExcessoSubLimiteTab />
        </Tab>

        <Tab eventKey="lucroPresumido" title="Lucro Presumido">
          <LucroPresumidoTab />
        </Tab>

        <Tab eventKey="lucroReal" title="Lucro Real">
          <LucroRealTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ImpostoDocumentoTab;
