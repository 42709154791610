import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/demonstrativo-resultado';

interface IDemonstrativoResultadoComunicadorIndex {
  params?: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IDemonstrativoResultadoComunicadorShow {
  id: string;
}

interface IDemonstrativoResultadoComunicadorDelete {
  id: string;
}

interface IDemonstrativoResultadoComunicadorStore {
  params: any;
}

interface IDemonstrativoResultadoComunicadorUpdate {
  id: string;
  params: any;
}

class DemonstrativoResultadoComunicador {
  public async index(
    params: IDemonstrativoResultadoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IDemonstrativoResultadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(
    params: IDemonstrativoResultadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(
    params: IDemonstrativoResultadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IDemonstrativoResultadoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new DemonstrativoResultadoComunicador();
