import {
  FormatarDataHoraCompletaParaPtBr,
  FormatarDataParaPtBr,
  IContaParcelaLista,
  TipoContaEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ContaComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaComunicador';
import ContaLogComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Log/Comunicador/ContaLogComunicador';

const ContaLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ContaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
            isAdiantamentoAntecipacao: false,
          },
        });

        const dados = response.dados[0].map((data: IContaParcelaLista) => {
          return {
            query: {
              idParcela: data.id,
            },
            id: data.id,
            'conta.tipo': (
              <div
                className="lista-texto"
                style={{
                  color:
                    data.conta.tipo === TipoContaEnum.receber ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {data.conta.tipo}
              </div>
            ),
            numeroDocumento: (
              <div className="lista-valor">{data.numeroDocumento}</div>
            ),
            sequencia: (
              <div className="lista-texto">{`${data.sequencia}/${data.conta.quantidadeParcelas}`}</div>
            ),

            'conta.dataHoraEmissao': (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(data.conta.dataHoraEmissao)}
              </div>
            ),
            dataVencimento: (
              <div className="lista-data">
                {FormatarDataParaPtBr(data.dataVencimento)}
              </div>
            ),
            valorParcela: (
              <div className="lista-valor">
                {Number(data.valorParcela).FormatarParaPtBr()}
              </div>
            ),
            valorTotalParcela: (
              <div className="lista-valor">
                {Number(data.valorTotalParcela).FormatarParaPtBr()}
              </div>
            ),
            valorTotalMovimentos: (
              <div className="lista-valor">
                {Number(data.valorTotalMovimentos).FormatarParaPtBr()}
              </div>
            ),
            valorTotalAberto: (
              <div className="lista-valor">
                {Number(data.valorTotalAberto).FormatarParaPtBr()}
              </div>
            ),

            'conta.pessoa.codigoNomeRazaoSocial': (
              <div className="lista-texto">
                {data.conta.pessoa &&
                  `${data.conta.pessoa.codigo} - ${data.conta.pessoa.nomeRazaoSocial}`}
              </div>
            ),

            'conta.observacoes': (
              <div className="lista-texto">{data.conta.observacoes}</div>
            ),

            'conta.revertida': (
              <div
                className="lista-texto"
                style={{
                  color: data.conta.revertida ? 'green' : 'black',
                  fontWeight: 'bold',
                }}
              >
                {data.conta.revertida ? 'Sim' : 'Não'}
              </div>
            ),

            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  disableDeletar
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = ContaLogComunicador.show({
                        id: data.idConta,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    []
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'financeiroContaParcela' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Contas (Receber/Pagar)"
          permissao={permissoes.FinanceiroMovimentacoesContasReceberPagar}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ContaLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ContaLista;
