import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/padronizacao-descricao-auto-complete';

interface IPadronizacaoDescricaoAutoCompleteComunicadorIndex {
  params: any;
}

class PadronizacaoDescricaoAutoCompleteComunicador {
  public async index(
    params: IPadronizacaoDescricaoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PadronizacaoDescricaoAutoCompleteComunicador();
