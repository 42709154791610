/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Table, Modal, Button, Container } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { IPedidoCompraItemAtualizarSituacaoLista } from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { UltimaTr } from './styles';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import ItemAtualizarSituacao, {
  IItemAtualizarSituacaoRef,
} from './ItemAtualizarSituacao';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IPedidoCompraAtualizarSituacaoContext {
  setTitulo(titulo: string): void;
  abrirPedidoCompraAtualizarSituacao(
    listaParaAtualizarSituacao: IPedidoCompraItemAtualizarSituacaoLista[]
  ): Promise<IPedidoCompraItemAtualizarSituacaoLista[]>;
  fecharPedidoCompraAtualizarSituacao(): void;
}

const PedidoCompraAtualizarSituacaoContext =
  createContext<IPedidoCompraAtualizarSituacaoContext>(
    {} as IPedidoCompraAtualizarSituacaoContext
  );

const PedidoCompraAtualizarSituacaoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const resolveRef = useRef<any>();
  const selecionarTodosRef = useRef<HTMLInputElement>(null);
  const listaItemAtualizarSituacaoRef = useRef<
    Array<IItemAtualizarSituacaoRef | null>
  >([]);

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);
  const [listaValor, setListaValor] = useState<
    IPedidoCompraItemAtualizarSituacaoLista[]
  >([]);
  const [valorSubTotal, setValorSubTotal] = useState<number>(0);

  const handleObterListaValoresSelecionados = useCallback(() => {
    return listaItemAtualizarSituacaoRef.current
      .map((item) => {
        return item?.handleObterValores();
      })
      .filter((item) => {
        return item?.selecionado;
      }) as IPedidoCompraItemAtualizarSituacaoLista[];
  }, []);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const listaItensSelecionados = handleObterListaValoresSelecionados();

    const valor = listaItensSelecionados.reduce(
      (acumulador: number, item: IPedidoCompraItemAtualizarSituacaoLista) => {
        acumulador += Number(item.valorTotalProduto) || 0;
        return acumulador;
      },
      0
    );

    setValorSubTotal(valor);
  }, [handleObterListaValoresSelecionados]);

  const handleSelecionarTodos = useCallback(
    (valor: boolean) => {
      listaItemAtualizarSituacaoRef.current.forEach((item) => {
        item?.setSelecionado(valor);
      });

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal]
  );

  const handleAbrir = useCallback(
    async (
      novaLista: IPedidoCompraItemAtualizarSituacaoLista[]
    ): Promise<any> => {
      setListaValor(novaLista);
      setAberto(true);

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    []
  );

  useEffect(() => {
    if (aberto) {
      selecionarTodosRef.current?.click();
    }
  }, [aberto, handleSelecionarTodos]);

  const handleFechar = useCallback(() => {
    setListaValor([]);
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleSalvar = useCallback(() => {
    setAberto(false);
    if (resolveRef.current) {
      resolveRef.current(handleObterListaValoresSelecionados());
    }
  }, [handleObterListaValoresSelecionados]);

  useEffect(() => {
    handleAtualizarValorSubTotal();
  }, [handleAtualizarValorSubTotal, listaValor]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  return (
    <PedidoCompraAtualizarSituacaoContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirPedidoCompraAtualizarSituacao: handleAbrir,
        fecharPedidoCompraAtualizarSituacao: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          <Tabela style={{ overflow: 'auto' }}>
            <Table striped bordered variant="light">
              <thead>
                <tr>
                  <th
                    className="lista-texto"
                    style={{
                      width: '5%',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    <input
                      type="checkbox"
                      ref={selecionarTodosRef}
                      onChange={(event) => {
                        handleSelecionarTodos(event.target.checked);
                      }}
                    />
                  </th>
                  <th className="lista-texto" style={{ width: '10%' }}>
                    <span>Código</span>
                  </th>
                  <th className="lista-texto" style={{ width: '25%' }}>
                    <span>Descrição Produto/Serviço</span>
                  </th>
                  <th className="lista-texto" style={{ width: '10%' }}>
                    <span>UN</span>
                  </th>
                  <th className="lista-valor" style={{ width: '5%' }}>
                    <span>Qtde.</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Val. Unitário</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Sub. Total</span>
                  </th>
                  <th className="lista-texto" style={{ width: '10%' }}>
                    <span>Situação Atual</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor &&
                  listaValor.map(
                    (item: IPedidoCompraItemAtualizarSituacaoLista, index) => {
                      return (
                        <ItemAtualizarSituacao
                          item={item}
                          key={item.id}
                          ref={(instance) => {
                            listaItemAtualizarSituacaoRef.current[index] =
                              instance;
                          }}
                          onChange={handleAtualizarValorSubTotal}
                        />
                      );
                    }
                  )}
              </tbody>

              <tfoot>
                <UltimaTr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th>
                    <div
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Valor Sub. Total</div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        {valorSubTotal.FormatarParaPtBr()}
                      </div>
                    </div>
                  </th>
                  <th />
                </UltimaTr>
              </tfoot>
            </Table>
          </Tabela>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleFechar}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 16,
                  }}
                >
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSalvar}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </PedidoCompraAtualizarSituacaoContext.Provider>
  );
};

function UsePedidoCompraAtualizarSituacao(): IPedidoCompraAtualizarSituacaoContext {
  const context = useContext(PedidoCompraAtualizarSituacaoContext);

  if (!context) {
    throw new Error(
      'UsePedidoCompraAtualizarSituacao deve ser usado com um PedidoCompraProvider'
    );
  }

  return context;
}

export { PedidoCompraAtualizarSituacaoHook, UsePedidoCompraAtualizarSituacao };
