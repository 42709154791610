import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-operacao-fiscal/ordem';

interface IRegraEscolhaOperacaoFiscalOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaOperacaoFiscalOrdemComunicador {
  public async update(
    params: IRegraEscolhaOperacaoFiscalOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaOperacaoFiscalOrdemComunicador();
