import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const XmlPdfConhecimentoTransporteEmitidosTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="xmlCteProprioEmitido"
                label="Xml CT-e Proprio Emitidos (Autorizados/Denegados)"
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                name="dacteCteProprioPdf"
                label="Dacte CT-e (PDF)"
              />
            </div>
            <div className="checkbox-container">
              <InputCheckbox
                name="incluirCteProprioCancelados"
                label="Incluir CT-e (Cancelados/ Cartas de Correção)"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default XmlPdfConhecimentoTransporteEmitidosTab;
