import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/pesagem/com-produtores';

interface IPesagemComProdutoresRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PesagemComProdutoresRelatorioComunicador {
  public async index(
    params: IPesagemComProdutoresRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PesagemComProdutoresRelatorioComunicador();
