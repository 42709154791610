import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/impressao-recibo';

interface IImpressaoReciboComunicadorIndex {
  params: {
    idRecebedor: string;
    formaBusca: string;
  };
}

class ImpressaReciboComunicador {
  public async show(params: IImpressaoReciboComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new ImpressaReciboComunicador();
