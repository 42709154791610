import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  ITabelaPrecoProdutosValoresRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface ITabelaPrecoProdutosValoresPDF {
  dados: ITabelaPrecoProdutosValoresRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const TabelaPrecoProdutosValoresPDF: React.FC<
  ITabelaPrecoProdutosValoresPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTabelaPreco && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tabela Preço: '}
                      </Text>
                      {dados.cabecalho.filtroTabelaPreco}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProdutosAtivos && (
                  <View>
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.cabecalho.filtroProdutosAtivos}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupos: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroNumeroNotaFiscalTerceiro && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Numero: '}</Text>
                      {dados.cabecalho.filtroNumeroNotaFiscalTerceiro}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSerieNotaFiscalTerceiro && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Serie: '}</Text>
                      {dados.cabecalho.filtroSerieNotaFiscalTerceiro}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroFornecedor && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Fornecedores: '}
                      </Text>
                      {dados.cabecalho.filtroFornecedor}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComValorVenda && (
                  <View>
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.cabecalho.filtroSomenteProdutoComValorVenda}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComValorVendaZerado && (
                  <View>
                    <Text style={{ fontWeight: 'bold' }}>
                      {dados.cabecalho.filtroSomenteProdutoComValorVendaZerado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSerieNotaFiscalTerceiro && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Serie: '}</Text>
                      {dados.cabecalho.filtroSerieNotaFiscalTerceiro}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1, marginTop: 10 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              fontSize: 9,
              fontWeight: 'bold',
              borderBottom: '1pt solid black',
            }}
          >
            <View style={{ width: '12%', alignItems: 'flex-start' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '57%' }}>
              <Text>Descrição do Produto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text>Unidade</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '15%', alignItems: 'flex-end' }}>
              <Text>Valor Unitário</Text>
            </View>
          </View>

          {dados.listaDados.map((dado) => {
            return (
              <>
                <View key={dado.id}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 5,
                      fontSize: 10,
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <Text style={{ fontWeight: 'bold' }}>
                        {`Tabela de Preço: `}
                        {dado.descricao}
                      </Text>
                    </View>

                    <View style={{ width: '5%' }} />

                    <View style={{ width: '45%' }}>
                      <Text style={{ fontWeight: 'bold' }}>
                        {`Validade: `}
                        {`${dado.dataInicioVigencia} à ${dado.dataFinalVigencia}`}
                      </Text>
                    </View>
                  </View>
                  {dado.listaProdutoRegraEscolhaTabelaPreco.map(
                    (item, index) => {
                      return (
                        <View
                          key={item.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#E4E4E4' : '#fff',
                          }}
                        >
                          <View style={{ flexDirection: 'row' }}>
                            <View
                              style={{ width: '12%', alignItems: 'flex-start' }}
                            >
                              <Text>{item.produto.codigo}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View style={{ width: '57%' }}>
                              <Text>{item.produto.descricao}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View style={{ width: '13%' }}>
                              <Text>
                                {
                                  item.produto.produtoEstoque.unidadeMedida
                                    .sigla
                                }
                              </Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '15%', alignItems: 'flex-end' }}
                            >
                              <Text>{item.valorUnitarioVenda}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    }
                  )}

                  <View
                    style={{ borderBottom: '1pt dashed black', width: '100%' }}
                  />
                </View>
              </>
            );
          })}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 11,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default TabelaPrecoProdutosValoresPDF;
