import React from 'react';
import { Spinner } from 'react-bootstrap';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import { LoadingContainer } from './styles';

interface ILoadingDiv extends IPadraoProps {
  isLoading: boolean;
  isToggleJanela?: boolean;
}

const LoadingDiv: React.FC<ILoadingDiv> = ({
  isLoading = true,
  isToggleJanela = false,
  children,
}) => {
  if (isLoading) {
    return (
      <LoadingContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        $isToggleJanela={isToggleJanela}
      >
        <Spinner
          className="spinner"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      </LoadingContainer>
    );
  }
  return <>{children}</>;
};

export default LoadingDiv;
