import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataHoraParaArquivo,
  IGeracaoArquivosContabeisValoresAlterar,
} from '@elogestor/util';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import GeracaoArquivosContabeisComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IGeracaoArquivosContabeisValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);

        formRef.current?.setFieldValue('dataHoraTransmissao', null);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await GeracaoArquivosContabeisComunicador.show({
          id: idEditar,
        });
        const date = new Date();
        const dataEmissaoInicial = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        ).SetarSegundosMilisegundosZero();
        const dataEmissaoFinal = new Date(
          date.getFullYear(),
          date.getMonth(),
          0,
          23,
          59
        );
        dataEmissaoFinal.setSeconds(59);
        dataEmissaoFinal.setMilliseconds(0);

        const nomeArquivo = `${
          response.descricao
        }_${FormatarDataHoraParaArquivo(
          dataEmissaoInicial
        )}_a_${FormatarDataHoraParaArquivo(dataEmissaoFinal)}`;

        await formRef.current?.setDataInicial({
          ...response,
          nomeArquivo,
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const date = new Date();
        const dataEmissaoInicial = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        ).SetarSegundosMilisegundosZero();
        const dataEmissaoFinal = new Date(
          date.getFullYear(),
          date.getMonth(),
          0,
          23,
          59
        );
        dataEmissaoFinal.setSeconds(59);
        dataEmissaoFinal.setMilliseconds(0);

        const nomeArquivo = `arquivo_${FormatarDataHoraParaArquivo(
          dataEmissaoInicial
        )}_a_${FormatarDataHoraParaArquivo(dataEmissaoFinal)}`;

        await formRef.current?.setDataInicial({
          nomeArquivo,
          dataEmissaoInicial,
          dataEmissaoFinal,
          ativo: true,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        descricao: Yup.string().required('Descrição é obrigatória!'),
        dataEmissaoInicial: Yup.date()
          .nullable()
          .required('Data incial é Obrigatoria!'),
        dataEmissaoFinal: Yup.date()
          .nullable()
          .required('Data Final é Obrigatoria!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await GeracaoArquivosContabeisComunicador.update({
            id,
            params: data,
          });
        } else {
          const response = await GeracaoArquivosContabeisComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');

        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
