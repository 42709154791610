import React, { useEffect, useMemo, useRef } from 'react';
import {
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Tooltip,
  Table,
} from 'react-bootstrap';
import {
  FormatarPeriodoEntreDuasDatasFormatadas,
  FormatarDataHoraCompletaParaPtBr,
} from '@elogestor/util';
import { AiOutlineFileSearch } from 'react-icons/ai/index.mjs';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../Buttons/BtnCloseButton';
import InputAutoCompleteUsuario from '../Inputs/AutoComplete/Configuracao/InputAutoCompleteUsuario';
import OverlayPesquisaAvancada from '../Lista/OverlayPesquisaAvancada';
import IPesquisaAvancada from '../Lista/Interface/IPesquisaAvancada';
import { PesquisaGrande } from '../Lista/SubHeader/styles';
import { Tabela } from '../Styles/Tabela';
import { TBody } from '../Styles/TBody';
import ILog from './Interfaces/ILog';
import { Container, Header, Body, FundoPreto } from './styles';
import IPesquisaAvancadaDadosLogCompleto from './Interfaces/IPesquisaAvancadaDadosLogCompleto';
import PaginacaoLog from './PaginacaoLog';
import { UseLog } from './Hooks/LogProvider';
import InputPeriodoDataHora from '../Inputs/InputPeriodoDataHora';

interface ILogModal {
  listaLog: ILog[];
  filtrosPesquisaAvancada: IPesquisaAvancadaDadosLogCompleto | null;
  fecharJanela(): void;
  abrirPesquisaAvancada(): void;
  limparPesquisaAvancada(): void;
  loading?: boolean;
}

const LogModal: React.FC<ILogModal> = ({
  listaLog,
  fecharJanela,
  abrirPesquisaAvancada,
  limparPesquisaAvancada,
  filtrosPesquisaAvancada,
  loading = false,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const {
    usuarioFiltro,
    setUsuarioFiltro,
    dataHoraInicial,
    setDataHoraInicial,
    dataHoraFinal,
    setDataHoraFinal,
  } = UseLog();

  useEffect(() => {
    if (usuarioFiltro) {
      formRef.current?.setFieldValue('usuario', usuarioFiltro);
    }
    if (dataHoraInicial) {
      formRef.current?.setFieldValue('dataInicial', dataHoraInicial);
    }
    if (dataHoraFinal) {
      formRef.current?.setFieldValue('dataFinal', dataHoraFinal);
    }
  }, [dataHoraFinal, dataHoraInicial, usuarioFiltro]);

  const filtroPesquisaAvancada = useMemo<IPesquisaAvancada[]>(() => {
    const lista: IPesquisaAvancada[] = [];
    if (filtrosPesquisaAvancada?.idRegistro) {
      lista.push({
        titulo: 'Identificação',
        valor: String(filtrosPesquisaAvancada.idRegistro),
      });
    }

    const periodoFormatado = FormatarPeriodoEntreDuasDatasFormatadas(
      FormatarDataHoraCompletaParaPtBr(filtrosPesquisaAvancada?.dataInicial),
      FormatarDataHoraCompletaParaPtBr(filtrosPesquisaAvancada?.dataFinal)
    );

    if (periodoFormatado) {
      lista.push({
        titulo: 'Data e Hora',
        valor: periodoFormatado,
      });
    }

    if (filtrosPesquisaAvancada?.usuario?.nome) {
      lista.push({
        titulo: 'Usuário',
        valor: filtrosPesquisaAvancada.usuario.nome,
      });
    }

    lista.push({
      titulo: 'Ação',
      valor: filtrosPesquisaAvancada?.acao || 'Todos',
    });

    if (filtrosPesquisaAvancada?.descricaoLog) {
      lista.push({
        titulo: 'Descrição Log',
        valor: filtrosPesquisaAvancada?.descricaoLog,
      });
    }

    return lista;
  }, [filtrosPesquisaAvancada]);

  useEffect(() => {
    function handleKeyDown(event: globalThis.KeyboardEvent): void {
      if (event.key === 'Escape') {
        fecharJanela();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <FundoPreto onClick={fecharJanela}>
      <Container
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Header>
          <h2>Logs (Histórico de Eventos)</h2>
          <BtnCloseButton type="button" onClick={fecharJanela}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Header>

        <Body>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <FormCia ref={formRef} style={{ marginBottom: 15, flex: 1 }}>
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <InputAutoCompleteUsuario
                    name="usuario"
                    label="Usuário"
                    mostrarEloGestor
                    onChangeItemAtual={({ itemAtual }) => {
                      setUsuarioFiltro(itemAtual ?? null);
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputPeriodoDataHora
                    nomeDataHoraInicial="dataInicial"
                    nomeDataHoraFinal="dataFinal"
                    labelDataHoraInicial="Data Inicial"
                    labelDataHoraFinal="Data Final"
                    onBlur={({ inputDataInicial, inputDataFinal }) => {
                      if (inputDataInicial && inputDataFinal) {
                        setDataHoraInicial(inputDataInicial.value());
                        setDataHoraFinal(inputDataFinal.value());
                      }
                    }}
                  />
                </Col>

                <Col style={{ height: 40, marginTop: 22, marginLeft: 10 }}>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={v4()}>Pesquisa Avançada</Tooltip>}
                  >
                    <Button
                      variant="secondary"
                      style={{
                        display: filtrosPesquisaAvancada ? 'none' : 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        fontSize: 22,
                        height: 38,
                        width: 38,
                        marginRight: 15,
                      }}
                      onClick={() => {
                        abrirPesquisaAvancada();
                      }}
                    >
                      <AiOutlineFileSearch />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Popover id={v4()}>
                        <OverlayPesquisaAvancada
                          pesquisaAvancada={filtroPesquisaAvancada}
                        />
                      </Popover>
                    }
                  >
                    <PesquisaGrande
                      style={{
                        display: filtrosPesquisaAvancada ? 'flex' : 'none',
                      }}
                    >
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          fontSize: 22,
                          height: 38,
                          width: 38,
                        }}
                        variant="secondary"
                        onClick={abrirPesquisaAvancada}
                      >
                        <AiOutlineFileSearch />
                      </Button>
                      <div>
                        <span>Pesquisa Avançada</span>
                        <button type="button" onClick={limparPesquisaAvancada}>
                          Remover Filtro
                        </button>
                      </div>
                    </PesquisaGrande>
                  </OverlayTrigger>
                </Col>
              </Row>
            </FormCia>
          </div>

          <div style={{ overflow: 'auto', width: '100%' }}>
            <Tabela>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th className="lista-booleano" style={{ width: '5%' }}>
                      <span>Identificação</span>
                    </th>
                    <th className="lista-booleano" style={{ width: '10%' }}>
                      <span>Data e Hora</span>
                    </th>
                    <th className="lista-texto" style={{ width: '10%' }}>
                      <span>Usuário</span>
                    </th>
                    <th className="lista-booleano" style={{ width: '5%' }}>
                      <span>Ação</span>
                    </th>
                    <th className="lista-texto" style={{ width: '10%' }}>
                      <span>Módulo</span>
                    </th>
                    <th className="lista-booleano" style={{ width: '60%' }}>
                      <span>Descricao</span>
                    </th>
                  </tr>
                </thead>

                <TBody>
                  {listaLog.map((log) => {
                    return (
                      <tr key={v4()}>
                        <td>
                          <div>{log.idRegistro}</div>
                        </td>
                        <td>
                          <div>
                            {FormatarDataHoraCompletaParaPtBr(log.dataHora)}
                          </div>
                        </td>
                        <td>
                          <div>{log.usuario.nome}</div>
                        </td>
                        <td>
                          <div>{log.acao}</div>
                        </td>
                        <td>
                          <div>{log.tabela}</div>
                        </td>
                        <td>
                          <div>
                            <pre style={{ padding: 0, margin: 0 }}>
                              {log.dadosLog}
                            </pre>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </TBody>
              </Table>
            </Tabela>
          </div>

          <PaginacaoLog />
        </Body>
      </Container>
    </FundoPreto>
  );
};

export default LogModal;
