import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/transmissao/evento';

interface INotaFiscalEntradaTransmissaoEventoComunicadorIndex {
  params: {
    idValor: string;
  };
}

interface INotaFiscalEntradaTransmissaoEventoComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaTransmissaoEventoComunicadorStore {
  params: any;
}

interface INotaFiscalEntradaTransmissaoEventoComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalEntradaTransmissaoEventoComunicadorDelete {
  id: string;
}

class NotaFiscalEntradaTransmissaoEventoComunicador {
  public async index(
    params: INotaFiscalEntradaTransmissaoEventoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: INotaFiscalEntradaTransmissaoEventoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalEntradaTransmissaoEventoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaTransmissaoEventoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalEntradaTransmissaoEventoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoComunicador();
