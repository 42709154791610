import React, { useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UseForm } from './Hooks/FormContext';
import IOnSairDoFormulario from './Interface/IOnSairDoFormulario';
import { UseRecuperarFormulario } from '../RecuperarFormulario/HooksRecuperarFormulario';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';

interface IDetalhe extends IPadraoProps {
  onSairDoFormulario?: (event: IOnSairDoFormulario) => void | Promise<void>;
  onRecuperarFormulario?: (dadosParaRecuperar: any) => void;
  onDescartarFormulario?: () => void;
  style?: React.CSSProperties;
}

const Detalhe: React.FC<IDetalhe> = ({
  children,
  onSairDoFormulario,
  onRecuperarFormulario,
  onDescartarFormulario,
  ...rest
}) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { formRef, getIdDetalheRegistro } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  useLayoutEffect(() => {
    async function ExecutaHandleChange(): Promise<void> {
      const data = formRef.current?.getDataRecuperarFormulario();

      if (!data.naoRecuperarFormulario && formRef.current?.validarSeAlterou()) {
        const resposta = await abrirRecuperarFormulario({});
        if (resposta) {
          if (onRecuperarFormulario) {
            onRecuperarFormulario(data);
          } else {
            navigate(pathname, {
              state: {
                dadosRecuperados: data,
              },
            } as any);
          }
        } else if (onDescartarFormulario) {
          onDescartarFormulario();
        }
      }
    }

    return () => {
      if (onSairDoFormulario) {
        onSairDoFormulario({
          id: getIdDetalheRegistro(),
          formRef,
          abrirRecuperarFormulario,
        });
      } else {
        ExecutaHandleChange();
      }
    };
  }, [
    abrirRecuperarFormulario,
    formRef,
    getIdDetalheRegistro,
    navigate,
    onDescartarFormulario,
    onRecuperarFormulario,
    onSairDoFormulario,
    pathname,
    state,
  ]);

  return (
    <div
      style={{
        padding: '50px 0px 15px',
        height: '100%',
        width: '100%',
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Detalhe;
