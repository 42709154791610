"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoTomadorEnum;
(function (TipoTomadorEnum) {
    TipoTomadorEnum["remetente"] = "0 - Remetente";
    TipoTomadorEnum["expedidor"] = "1 - Expedidor";
    TipoTomadorEnum["recebedor"] = "2 - Recebedor";
    TipoTomadorEnum["destinatario"] = "3 - Destinat\u00E1rio";
    TipoTomadorEnum["outros"] = "4 - Outros";
})(TipoTomadorEnum || (TipoTomadorEnum = {}));
exports.default = TipoTomadorEnum;
