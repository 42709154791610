"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ComplementoAtividadeSpedFiscalEnum;
(function (ComplementoAtividadeSpedFiscalEnum) {
    ComplementoAtividadeSpedFiscalEnum["industrialTransformacao"] = "00 - Industrial - Transforma\u00E7\u00E3o";
    ComplementoAtividadeSpedFiscalEnum["industrialBeneficiamento"] = "01 - Industrial - Beneficiamento";
    ComplementoAtividadeSpedFiscalEnum["industrialMontagem"] = "02 - Industrial - Montagem";
    ComplementoAtividadeSpedFiscalEnum["industrialAcondicionamentoReacondicionamento"] = "03 - Industrial - Acondicionamento ou Reacondicionamento";
    ComplementoAtividadeSpedFiscalEnum["industrialRenovacaoRecondicionamento"] = "04 - Industrial - Renova\u00E7\u00E3o ou Recondicionamento";
    ComplementoAtividadeSpedFiscalEnum["equiparadoIndustrialPorOpcao"] = "05 - Equiparado Industrial - Por Op\u00E7\u00E3o";
    ComplementoAtividadeSpedFiscalEnum["equiparadoIndustrialImportacaoDireta"] = "06 - Equiparado Industrial - Importa\u00E7\u00E3o Direta";
    ComplementoAtividadeSpedFiscalEnum["equiparadoIndustrialPorLeiEspecifica"] = "07 - Equiparado Industrial - Por lei espec\u00EDfica";
    ComplementoAtividadeSpedFiscalEnum["equiparadoIndustrialNaoEnquadrado"] = "08 - Equiparado Industrial - N\u00E3o enquadrado nos cod. 5, 6 ou 7";
    ComplementoAtividadeSpedFiscalEnum["outros"] = "09 - Outros";
})(ComplementoAtividadeSpedFiscalEnum || (ComplementoAtividadeSpedFiscalEnum = {}));
exports.default = ComplementoAtividadeSpedFiscalEnum;
