import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { AtualizacaoEstoqueProdutoListaHook } from './AtualizacaoEstoqueProdutoListaHook';
import AtualizacaoEstoqueProdutoListaRecuperarHook from './AtualizacaoEstoqueProdutoListaRecuperarHook';

const AtualizacaoEstoqueProdutoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <AtualizacaoEstoqueProdutoListaHook>
      <AtualizacaoEstoqueProdutoListaRecuperarHook>
        {children}
      </AtualizacaoEstoqueProdutoListaRecuperarHook>
    </AtualizacaoEstoqueProdutoListaHook>
  );
};

export default AtualizacaoEstoqueProdutoHook;
