import ILogPesquisaParametro from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/log';

interface IConhecimentoTransporteLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IConhecimentoTransporteLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ConhecimentoTransporteLogComunicador {
  public async index(
    params: IConhecimentoTransporteLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IConhecimentoTransporteLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ConhecimentoTransporteLogComunicador();
