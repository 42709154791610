import { CalcularValidarExpressaoMatematica } from '@elogestor/util';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Button, Modal, Container, Dropdown } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import {
  IoIosCloseCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import ProdutoComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoComunicador';
import SplitAcoes from '../../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { InputFormula } from './styles';

interface ISelecionarPdm {
  onFecharModal?: () => void;
}

export interface IFormulaObjeto {
  id: string;
  nome: string;
  valorVariavel: string | undefined;
}

const SelecionarFormulaPdm: React.FC<ISelecionarPdm> = ({ onFecharModal }) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [listaPadronizacoes, setListaPadronizacoes] = useState<any[]>([]);
  const [formula, setFormula] = useState<string>();
  const [stringFormula, setStringFormula] = useState<any>();
  const [formulaFormatada, setFormulaFormatada] = useState<string>();

  const { formRef: formRefPrincipal, loading, refresh, setLoading } = UseForm();

  useEffect(() => {
    setLoading(true);

    const obterDadosIniciais = async (): Promise<void> => {
      const formulaPrincipal =
        formRefPrincipal.current?.getFieldValue('formula');

      setFormula(formulaPrincipal);

      const response = await ProdutoComunicador.show({
        id: formRefPrincipal.current?.getFieldValue('idProdutoPai'),
      });

      if (
        response &&
        response.listaProdutoPadronizacaoDescricaoAtributo &&
        response.listaProdutoPadronizacaoDescricaoAtributo.length > 0
      ) {
        setListaPadronizacoes(
          response.listaProdutoPadronizacaoDescricaoAtributo
        );
      } else {
        setLoading(false);
      }

      refresh();
    };

    obterDadosIniciais();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temp = formula;

    listaPadronizacoes.forEach((item: any) => {
      temp = temp?.replace(
        new RegExp(item.padronizacaoDescricaoAtributo.descricao, 'g'),
        item.conteudo
      );
    });

    setStringFormula(temp);
  }, [formula, listaPadronizacoes]);

  useEffect(() => {
    let temp = formula;

    listaPadronizacoes.forEach((item: any) => {
      temp = temp?.replace(
        new RegExp(item.padronizacaoDescricaoAtributo.descricao, 'g'),
        `<#${item.padronizacaoDescricaoAtributo.id}#>`
      );
    });

    setFormulaFormatada(temp);
  }, [formula, listaPadronizacoes]);

  useEffect(() => {
    setFormula((prevFormula) => {
      let temp = prevFormula;

      listaPadronizacoes.forEach((item: any) => {
        temp = temp?.replace(
          new RegExp(`<#${item.padronizacaoDescricaoAtributo.id}#>`, 'g'),
          item.padronizacaoDescricaoAtributo.descricao
        );
      });

      return temp;
    });

    setLoading(false);
  }, [formula, listaPadronizacoes, setLoading]);

  const handleInserirVariavel = (item: any): void => {
    const inputField = inputRef.current;
    const valorSelecionado = item.padronizacaoDescricaoAtributo.descricao;

    if (inputField) {
      const selectionStart = inputField.selectionStart ?? 0;
      const selectionEnd = inputField.selectionEnd ?? selectionStart;

      inputField.value =
        inputField.value.slice(0, selectionStart) +
        valorSelecionado +
        inputField.value.slice(selectionEnd);

      setFormula(inputField.value);

      const novoCursorPos = selectionStart + valorSelecionado.length;

      inputField.selectionStart = novoCursorPos;
      inputField.selectionEnd = novoCursorPos;
    }
  };

  const handleClickSalvar = useCallback(async () => {
    const validacao = CalcularValidarExpressaoMatematica(
      stringFormula.replace(/,/, '.')
    );

    if (validacao === false) {
      ToastErro('Expressão inválida');
      return;
    }

    formRefPrincipal.current?.setFieldValue(
      'quantidade',
      CalcularValidarExpressaoMatematica(stringFormula)
    );
    formRefPrincipal.current?.setFieldValue('formula', formulaFormatada);

    onFecharModal && onFecharModal();

    ToastSucesso('Expressão Válida');
  }, [formRefPrincipal, formulaFormatada, onFecharModal, stringFormula]);

  const handleMostrarResultado = (
    _formula: string
  ): number | boolean | string => {
    const resultado = CalcularValidarExpressaoMatematica(_formula);

    if (typeof resultado === 'object') {
      return 'erro';
    }

    if (resultado === false) {
      return 'erro';
    }

    return Number(resultado).FormatarParaPtBr({
      minimoCasasDecimais: 10,
      maximoCasasDecimais: 10,
    });
  };

  return (
    <FormCia ref={formRef}>
      <Modal
        show
        onHide={() => {
          onFecharModal && onFecharModal();
        }}
        size={'lg'}
      >
        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>Fórmula</Modal.Title>
          <BtnCloseButton
            type="button"
            onClick={() => {
              onFecharModal && onFecharModal();
            }}
            id="btnClose"
          >
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="form-control"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  padding: '0',
                  minHeight: '30pt',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  border: 'none',
                }}
              >
                <TextoLoading loading={loading}>
                  <InputFormula
                    ref={inputRef}
                    autoComplete="off"
                    name="formula"
                    placeholder="Inserir na Fórmula"
                    onChange={(e) =>
                      setFormula(e.target.value.replace(',', '.'))
                    }
                    onBlur={(e) => setFormula(e.target.value.replace(',', '.'))}
                    value={formula}
                  />
                </TextoLoading>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SplitAcoes
                    height="35px"
                    className="m-1"
                    id={v4()}
                    title={<IoMdAddCircleOutline size={20} />}
                  >
                    {listaPadronizacoes &&
                      listaPadronizacoes.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item.id}
                            onClick={() => handleInserirVariavel(item)}
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              {item.padronizacaoDescricaoAtributo.descricao}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                  </SplitAcoes>
                </div>
              </div>
            </div>

            <hr />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {stringFormula && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: stringFormula ? 1 : 0.5,
                    transition: 'opacity 0.5s ease-in-out',
                    fontWeight: 'bold',
                    fontSize: 18,
                    wordBreak: 'break-all',
                    whiteSpace: 'wrap',
                  }}
                >
                  {!loading && (
                    <TextoLoading loading={loading}>
                      {stringFormula.replace(/,/g, '.')} ={' '}
                      {handleMostrarResultado(stringFormula.replace(/,/g, '.'))}
                    </TextoLoading>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={() => {
                  onFecharModal && onFecharModal();
                }}
                disabled={loading}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 16,
                  }}
                >
                  <TextoLoading loading={loading}>
                    <MdCancel />
                    <span style={{ marginLeft: 10 }}>Cancelar</span>
                  </TextoLoading>
                </span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickSalvar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>Confirmar</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
    </FormCia>
  );
};

export default SelecionarFormulaPdm;
