import { IImportacaoXmlCancelamentoCartaCorrecaoListaDadosImpressao } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/cancelamento-carta-correcao/salvar-importado';

interface IImportacaoXmlCancelamentoCartaCorrecaoSalvarImportadoComunicadorStore {
  params: any;
}

class ImportacaoXmlCancelamentoCartaCorrecaoSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlCancelamentoCartaCorrecaoSalvarImportadoComunicadorStore
  ): Promise<{
    id: string;
    listaImpressao: IImportacaoXmlCancelamentoCartaCorrecaoListaDadosImpressao[];
    listaMensagemSucesso: string[];
    listaMensagemAviso: string[];
    listaMensagemErro: string[];
  }> {
    const { params: body } = params;

    const response = await api.post(rota, body);

    return response.data;
  }
}

export default new ImportacaoXmlCancelamentoCartaCorrecaoSalvarImportadoComunicador();
