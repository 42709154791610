import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/item/auto-complete-tag';

interface IPedidoCompraItemCompleteTagComunicadorIndex {
  params: any;
}

class PedidoCompraItemCompleteTagComunicador {
  public async index(
    params: IPedidoCompraItemCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PedidoCompraItemCompleteTagComunicador();
