"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoPedidoVendaEnum;
(function (TipoPedidoVendaEnum) {
    TipoPedidoVendaEnum["normal"] = "Normal";
    TipoPedidoVendaEnum["preVenda"] = "Pre Venda";
    TipoPedidoVendaEnum["consignado"] = "Consignado";
})(TipoPedidoVendaEnum || (TipoPedidoVendaEnum = {}));
exports.default = TipoPedidoVendaEnum;
