/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { TipoEtiquetaEnum } from '@elogestor/util';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import EtiquetaAutoCompleteComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/AutoComplete/EtiquetaAutoCompleteComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IEtiqueta {
  id: string;
  descricaoModeloEtiqueta: string;
  tipoEtiqueta: TipoEtiquetaEnum | null;
}

export interface IOnChangeItemAtualAutoCompleteEtiquetaEvent {
  itemAtual: IEtiqueta | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteEtiquetaEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteEtiquetaEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteEtiquetaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  listaIdParaRemover?: string[];
  listaTipoEtiqueta?: TipoEtiquetaEnum[];
  mostrarEloGestor?: boolean;
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteEtiquetaEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteEtiquetaEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteEtiquetaEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteEtiquetaEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteEtiquetaRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteEtiqueta: React.ForwardRefRenderFunction<
  IInputAutoCompleteEtiquetaRef,
  IInputAutoCompleteEtiquetaProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaIdParaRemover = [],
    listaTipoEtiqueta = [],
    onBlurInput,
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<IEtiqueta | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );

  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response = await EtiquetaAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite,
                    texto: valor,
                    listaIdParaRemover,
                    listaTipoEtiqueta,
                  },
                });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: IEtiqueta) => {
              return item.id;
            }}
            obterLabel={(item: IEtiqueta) => {
              if (!item.descricaoModeloEtiqueta) return '';

              return item.descricaoModeloEtiqueta;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteEtiqueta);
