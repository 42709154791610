import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/reprocessamento-producao/validar-estoque';

interface IReprocessamentoProducaoComunicadorStore {
  params: any[];
}

class ValidarEstoqueMovimentoProducaoComunicador {
  public async store(
    params: IReprocessamentoProducaoComunicadorStore
  ): Promise<boolean> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ValidarEstoqueMovimentoProducaoComunicador();
