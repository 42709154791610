import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IConhecimentoTransporteTransmissaoEventoValoresAlterar,
  IConhecimentoTransporteTransmissaoEventoValoresInserir,
  SituacaoEventoEnum,
  TipoEventoCteEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRedirecionar } from '../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { ListaDetalheFormProvider } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import ConhecimentoTransporteTransmissaoEventoComunicador from '../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/Transmissao/Evento/ConhecimentoTransporteTransmissaoEventoComunicador';
import ObterProximoNumeroSequencialEventoComunicador from '../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/Transmissao/Evento/ObterProximoNumeroSequencialEventoComunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const condicaoUsoCartaCorrecao =
    'A Carta de Correção e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não esteja relacionado com: I - as variáveis que determinam o valor do imposto tais como: base de calculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação; II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário; III - a data de emissão ou de saída.';

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IConhecimentoTransporteTransmissaoEventoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response =
          await ConhecimentoTransporteTransmissaoEventoComunicador.show({
            id: idEditar,
          });

        await formRefLista.current?.setDataInicial({
          ...response,
          condicaoUsoCartaCorrecao,
        });

        SetarDadosObrigatorios();
      }
      async function SetarDadosPadrao(): Promise<void> {
        const chaveAcesso = formPrincipal.formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formPrincipal.formRef.current?.getFieldValue(
          'transmissao.ambiente'
        );

        const numero = formPrincipal.formRef.current?.getFieldValue('numero');
        const serie =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto('serie');

        await formRefLista.current?.setDataInicial({
          chaveAcesso,
          numero,
          codigoSerie: serie.codigo,
          situacao: SituacaoEventoEnum.naoTransmitida,
          ambiente,
          condicaoUsoCartaCorrecao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      formPrincipal.formRef,
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
    ]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          valorAlterado: Yup.string()
            .nullable()
            .max(150, 'Valor Alterado é inválido! Máximo de 150 caracteres!'),
          grupoAlterado: Yup.string()
            .nullable()
            .max(50, 'Grupo Alterado é inválido! Máximo de 50 caracteres!'),
          campoAlterado: Yup.string()
            .nullable()
            .max(50, 'Campo Alterado é inválido! Máximo de 50 caracteres!'),
          numeroItemAlterado: Yup.string()
            .nullable()
            .max(
              10,
              'Número Item Alterado é inválido! Máximo de 10 caracteres!'
            ),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);

        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<{
      id: string;
      erro: boolean;
    }> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);
        let id = getIdDetalheRegistro() || '';
        const idValor = formPrincipal.getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        if (!id) {
          const proximoNumeroSequencialEvento =
            await ObterProximoNumeroSequencialEventoComunicador.index({
              params: {
                idValor,
                ambiente: data.ambiente,
              },
            });

          const dados: IConhecimentoTransporteTransmissaoEventoValoresInserir =
            {
              ...data,
              idConhecimentoTransporte: idValor,
              numeroSequencialEvento: proximoNumeroSequencialEvento,
              dataHoraEvento: new Date(),
              tipoEvento: TipoEventoCteEnum.cartaCorrecao,
              versaoEvento: '4.00',
              versaoLayout: '4.00',
            };

          const response =
            await ConhecimentoTransporteTransmissaoEventoComunicador.store({
              params: dados,
            });
          setIdDetalheRegistro(response.id);

          id = response.id;
        } else {
          await ConhecimentoTransporteTransmissaoEventoComunicador.update({
            id,
            params: data,
          });
        }

        await formRef?.reset();
        await formPrincipal.handleCarregarDados();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        formPrincipal.setLoading(false);
        formPrincipal.refresh();
        refresh();
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
      refresh,
      setIdDetalheRegistro,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja Remover a Carta de Correção?
          </span>
        ),
      });

      if (!resposta) return;

      formPrincipal.setLoading(true);

      await ConhecimentoTransporteTransmissaoEventoComunicador.delete({ id });

      formPrincipal.setLoading(false);
      formPrincipal.refresh();

      await formPrincipal.handleCarregarDados();

      ToastSucesso('Carta de Correção Deletada!');
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
