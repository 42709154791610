import React from 'react';
import ImpressaoEtiquetasNotaFiscalLista from './Lista';
import ImpressaoEtiquetasNotaFiscalHook from './Hooks';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';

const ImpressaoEtiquetasNotaFiscalListagem: React.FC = () => {
  const storage = localStorage.getItem('@EloGestorle:etiquetaNotaFiscal');

  return (
    <ListaProvider>
      <ImpressaoEtiquetasNotaFiscalHook>
        <ImpressaoEtiquetasNotaFiscalLista
          idNotaFiscalEtiquetaSelecionada={storage}
        />
      </ImpressaoEtiquetasNotaFiscalHook>
    </ListaProvider>
  );
};

export default ImpressaoEtiquetasNotaFiscalListagem;
