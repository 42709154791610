const NotaFiscalEntradaTotalPorModeloDocumentoPlanilha = [
  { titulo: 'Modelo de Documento', valor: 'valores.chaveFormatada' },

  {
    titulo: 'Numero',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaNumero',
  },

  {
    titulo: 'Data de Emissão',
    valor: 'listaNotaFiscalEntradaItem.notaFiscalEntradaDataHoraEmissao',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaNotaFiscalEntradaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaNotaFiscalEntradaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaNotaFiscalEntradaItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalEntradaItem.somatorioValorTotal',
  },
];

export default NotaFiscalEntradaTotalPorModeloDocumentoPlanilha;
