import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/configuracao-cr-enologico-cultivar/log';

interface IConfiguracaoCRENologicoCultivarLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IConfiguracaoCRENologicoCultivarLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ConfiguracaoCREnologicoCultivarLogComunicador {
  public async index(
    params: IConfiguracaoCRENologicoCultivarLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: IConfiguracaoCRENologicoCultivarLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ConfiguracaoCREnologicoCultivarLogComunicador();
