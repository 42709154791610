import api from '../../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/centro-custo-auto-complete';

interface ICentroCustoAutoCompleteComunicadorIndex {
  params: any;
}

class CentroCustoAutoCompleteComunicador {
  public async index(
    params: ICentroCustoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CentroCustoAutoCompleteComunicador();
