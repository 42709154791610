import { AcaoEnum, FormatarEnum } from '@elogestor/util';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import BtnCloseButton from '../../Buttons/BtnCloseButton';
import Divisor from '../../Divisor';
import InputAutoCompleteUsuario from '../../Inputs/AutoComplete/Configuracao/InputAutoCompleteUsuario';
import Input from '../../Inputs/Input';
import InputPeriodoDataHora from '../../Inputs/InputPeriodoDataHora';
import Select from '../../Select';
import IPesquisaAvancadaDadosLog from '../Interfaces/IPesquisaAvancadaDadosLog';
import IPesquisaAvancadaDadosLogCompleto from '../Interfaces/IPesquisaAvancadaDadosLogCompleto';
import { UseLog } from '../Hooks/LogProvider';

interface IPesquisaAvancadaLog {
  aberta: boolean;
  fechar(): void;
  alterarFiltroPesquisaAvancada(dados: IPesquisaAvancadaDadosLog | null): void;
  filtroAtual: IPesquisaAvancadaDadosLogCompleto | null;
  setFiltroAtual(data: any): void;
}

const PesquisaAvancadaLog: React.FC<IPesquisaAvancadaLog> = ({
  aberta,
  fechar,
  alterarFiltroPesquisaAvancada,
  filtroAtual,
  setFiltroAtual,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const {
    usuarioFiltro,
    setUsuarioFiltro,
    dataHoraInicial,
    setDataHoraInicial,
    dataHoraFinal,
    setDataHoraFinal,
  } = UseLog();

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getData() as any;
    alterarFiltroPesquisaAvancada(data);
    setFiltroAtual(formRef.current?.getDataRecuperarFormulario());

    setUsuarioFiltro(formRef.current?.getFieldValueNomeObjeto('usuario'));
    setDataHoraInicial(formRef.current?.getFieldValueNomeObjeto('dataInicial'));
    setDataHoraFinal(formRef.current?.getFieldValueNomeObjeto('dataFinal'));
    fechar();
  }, [
    alterarFiltroPesquisaAvancada,
    fechar,
    setDataHoraFinal,
    setDataHoraInicial,
    setFiltroAtual,
    setUsuarioFiltro,
  ]);

  useEffect(() => {
    if (aberta) {
      formRef.current?.setData({
        ...filtroAtual,
        usuario: usuarioFiltro,
        dataInicial: dataHoraInicial,
        dataFinal: dataHoraFinal,
      });
    }
  }, [aberta, dataHoraFinal, dataHoraInicial, filtroAtual, usuarioFiltro]);

  return (
    <Modal show={aberta} onHide={fechar} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={fechar}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteUsuario
                  name="idUsuarioFiltro"
                  nomeObjeto="usuario"
                  label="Usuário"
                  mostrarEloGestor
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="acao"
                  label="Ação"
                  options={FormatarEnum({
                    enumObj: AcaoEnum,
                    todos: true,
                  })}
                />
              </Col>
              {/* COMENTADO, POIS ESSE CAMPO AO SER UTILIZADO ELE DERRUBA O BACK-END E O BANCO DOS CLIENTES */}
              {/* COMENTADO ATÉ ENCONTRAR UMA SOLUÇÃO EFICIENTE, ATIVIDADE 2241 */}
              {/* <Col lg={6} md={12}>
                <Input name="descricaoLog" label="Descrição Log" />
              </Col> */}
              <Col lg={6} md={12}>
                <Input name="idRegistro" label="Identificação" />
              </Col>

              <Col lg={12} md={12}>
                <InputPeriodoDataHora
                  nomeDataHoraInicial="dataInicial"
                  nomeDataHoraFinal="dataFinal"
                  labelDataHoraInicial="Data Inicial"
                  labelDataHoraFinal="Data Final"
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={fechar}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancadaLog;
