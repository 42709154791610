import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimal from '../../../../../../../../../Componentes/Inputs/InputDecimal';
import InputLabel from '../../../../../../../../../Componentes/Inputs/InputLabel';
import { CheckboxContainer } from '../../../../styles';

const ItemInformacaoXmlNFFornecedorTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Código NF."
              name="notaFiscalEntradaItemXmlNFFornecedor.codigo"
            />
          </Col>
          <Col lg={9} md={6} sm={12}>
            <InputLabel
              label="Descrição NF."
              name="notaFiscalEntradaItemXmlNFFornecedor.descricao"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Unid. Comercial"
              name="notaFiscalEntradaItemXmlNFFornecedor.unidadeComercial"
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Qtde. Comercial"
              name="notaFiscalEntradaItemXmlNFFornecedor.quantidadeComercial"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr({
                  minimoCasasDecimais: 4,
                  maximoCasasDecimais: 4,
                });
              }}
              valorStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Val. Unit. Comercial"
              name="notaFiscalEntradaItemXmlNFFornecedor.valorUnitarioComercial"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr({
                  minimoCasasDecimais: 10,
                  maximoCasasDecimais: 10,
                });
              }}
              valorStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Código de Barras Comercial"
              name="notaFiscalEntradaItemXmlNFFornecedor.codigoBarrasComercial"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Unid. Tributado"
              name="notaFiscalEntradaItemXmlNFFornecedor.unidadeTributado"
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Qtde. Tributado"
              name="notaFiscalEntradaItemXmlNFFornecedor.quantidadeTributado"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr({
                  minimoCasasDecimais: 4,
                  maximoCasasDecimais: 4,
                });
              }}
              valorStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Val. Unit. Tributado"
              name="notaFiscalEntradaItemXmlNFFornecedor.valorUnitarioTributado"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr({
                  minimoCasasDecimais: 10,
                  maximoCasasDecimais: 10,
                });
              }}
              valorStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputLabel
              label="Código de Barras Tributado"
              name="notaFiscalEntradaItemXmlNFFornecedor.codigoBarrasTributado"
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <CheckboxContainer>
              <InputCheckbox
                label="Entrada pelo Tributado"
                name="notaFiscalEntradaItemXmlNFFornecedor.entradaPeloTributado"
                disabled
              />
            </CheckboxContainer>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              label="Conversão (NF. Entrada x Estoque)"
              name="notaFiscalEntradaItemXmlNFFornecedor.conversaoEntradaEstoque"
              casasInteiras={11}
              casasDecimais={10}
              disabled
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              label="Percentual (Valor do Item)"
              name="notaFiscalEntradaItemXmlNFFornecedor.percentual"
              casasInteiras={11}
              casasDecimais={10}
              disabled
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemInformacaoXmlNFFornecedorTab;
