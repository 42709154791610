/* eslint-disable consistent-return */
import { IPadronizacaoDescricaoAtributoLista } from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Dropdown, Row, Col, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { UseFieldCia } from '@elogestor/unformcia';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import { Container } from './styles';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TrDraggable from '../../../../../../../Componentes/TrDraggable';
import { UseListaAtributoDraggable } from '../../Hooks/ListaAtributoDraggableHook';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';

interface IListaDetalheAtributoProps {
  name: string;
}

const PadronizacaoDescricaoAtributoLista: React.FC<
  IListaDetalheAtributoProps
> = ({ name }) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosPadronizacaoDescricao: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { handleAtualizarOrdem, handleMover, listaValor, setListaValor } =
    UseListaAtributoDraggable();

  const { formRefDetalhe, loading, setIdDetalheRegistro, handleExcluir } =
    UseListaDetalheForm();
  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IPadronizacaoDescricaoAtributoLista[]>([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPadronizacaoDescricaoAtributoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formPrincipal.formRef.current?.getData();

        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleAbrirModal = useCallback((): any => {
    const descricao = formPrincipal.formRef.current?.getFieldValue('descricao');
    if (!descricao) {
      ToastErro('Informe a Descrição!');
      formPrincipal.formRef.current?.setFieldError(
        'descricao',
        'Informe a Descrição!'
      );
      return { error: true };
    }
    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [formPrincipal.formRef, setIdDetalheRegistro]);

  return (
    <Container>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAbrirModal}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAdd />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Espaço</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Texto Anterior</span>
              </th>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Espaço</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Descrição</span>
              </th>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Espaço</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Texto Posterior</span>
              </th>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Espaço</span>
              </th>
              <th className="lista-valor" style={{ width: '5%' }}>
                <span>Tamanho</span>
              </th>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Obrigatório</span>
              </th>
              <th className="lista-booleano" style={{ width: '5%' }}>
                <span>Variação Ecommerce</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor.map((atributo, index) => {
              return (
                <TrDraggable
                  idDrag={v4()}
                  key={v4()}
                  index={index}
                  onMover={handleMover}
                  onDragEnd={() => {
                    if (formPrincipal.loading || loading) return;
                    handleAtualizarOrdem({ id: atributo.id });
                  }}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(atributo.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-booleano">
                    <div
                      style={{
                        color: atributo.espacoAntesTextoAnterior
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {atributo.espacoAntesTextoAnterior ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{atributo.textoAnterior}</div>
                  </td>
                  <td className="lista-booleano">
                    <div
                      style={{
                        color: atributo.espacoDepoisTextoAnterior
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {atributo.espacoDepoisTextoAnterior ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{atributo.descricao}</div>
                  </td>
                  <td className="lista-booleano">
                    <div
                      style={{
                        color: atributo.espacoAntesTextoPosterior
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {atributo.espacoAntesTextoPosterior ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="lista-texto">
                    <div>{atributo.textoPosterior}</div>
                  </td>
                  <td className="lista-booleano">
                    <div
                      style={{
                        color: atributo.espacoDepoisTextoPosterior
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {atributo.espacoDepoisTextoPosterior ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="lista-valor">
                    <div>{atributo.tamanho}</div>
                  </td>
                  <td className="lista-booleano">
                    <div
                      style={{ color: atributo.obrigatorio ? 'green' : 'red' }}
                    >
                      {atributo.obrigatorio ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="lista-booleano">
                    <div style={{ color: atributo.variacao ? 'green' : 'red' }}>
                      {atributo.variacao ? 'Sim' : 'Não'}
                    </div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(atributo.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </TrDraggable>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </Container>
  );
};

export default PadronizacaoDescricaoAtributoLista;
