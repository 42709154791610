import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-valores-abertos';

interface IPessoaObterValoresEmAbertoComunicadorIndex {
  idCliente: string;
}

class PessoaObterValoresEmAbertoComunicador {
  public async show(
    params: IPessoaObterValoresEmAbertoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.idCliente}`);
    return response.data;
  }
}

export default new PessoaObterValoresEmAbertoComunicador();
