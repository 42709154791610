import React from 'react';
import ListaDetalheDocumentoAnterior from './ListaDetalheDocumentoAnterior/Lista';
import Divisor from '../../../../../Componentes/Divisor';
import DocumentoAnteriorHook from './ListaDetalheDocumentoAnterior/Hooks';

const DocumentoAnteriorTab: React.FC = () => {
  return (
    <Divisor>
      <DocumentoAnteriorHook>
        <ListaDetalheDocumentoAnterior name="listaConhecimentoTransporteDocumentoAnterior" />
      </DocumentoAnteriorHook>
    </Divisor>
  );
};

export default DocumentoAnteriorTab;
