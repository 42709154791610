"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var FormaBuscaReciboEnum;
(function (FormaBuscaReciboEnum) {
    FormaBuscaReciboEnum["cadClienteFornTransp"] = "Cad. Cliente/Forn./Transp.";
    FormaBuscaReciboEnum["empresa"] = "Cad. Empresa";
    FormaBuscaReciboEnum["manual"] = "Manual";
})(FormaBuscaReciboEnum || (FormaBuscaReciboEnum = {}));
exports.default = FormaBuscaReciboEnum;
