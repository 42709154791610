import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PermissoesProvider } from './Permissoes';
import { AuthProvider } from './Auth';
import { ConfirmacaoProvider } from '../Componentes/Confirmacao/HooksConfirmacao';
import { RecuperarFormularioProvider } from '../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { RedirecionarProvider } from './RedirecionarContext';
import { ParametrosProvider } from './ParametrosHook';
import { ReactSizeMeGlobalHookProvider } from './ReactSizeMeGlobalHook';
import { MenuPrincipalProvider } from './MenuPrincipalHook';
import { LogProvider } from '../Componentes/LogModal/Hooks/LogProvider';
import { AlertaProvider } from '../Componentes/Alerta/HooksAlerta';
import JanelasHook from './JanelasHook';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import { LiberacoesProvider } from './LiberacoesHook';

const AppProvider: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ParametrosProvider>
      <LiberacoesProvider>
        <RedirecionarProvider value={{ redirecionar: true }}>
          <AuthProvider>
            <PermissoesProvider>
              <RecuperarFormularioProvider>
                <ConfirmacaoProvider>
                  <AlertaProvider>
                    <ReactSizeMeGlobalHookProvider>
                      <MenuPrincipalProvider>
                        <LogProvider>
                          <JanelasHook>{children}</JanelasHook>
                        </LogProvider>
                      </MenuPrincipalProvider>
                    </ReactSizeMeGlobalHookProvider>
                    <ToastContainer autoClose={3000} theme="colored" />
                  </AlertaProvider>
                </ConfirmacaoProvider>
              </RecuperarFormularioProvider>
            </PermissoesProvider>
          </AuthProvider>
        </RedirecionarProvider>
      </LiberacoesProvider>
    </ParametrosProvider>
  );
};

export default AppProvider;
