import {
  IOrdemProducaoItemValoresInserir,
  IOrdemProducaoLocalEstoqueValoresInserir,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ValidarEstoqueMovimentoProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/ValidarEstoqueMovimentoProducaoComunicador/ValidarEstoqueMovimentoProducaoComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IProps {
  listaOrdemProducaoItem: IOrdemProducaoItemValoresInserir[];
  listaOrdemProducaoLocalEstoque: IOrdemProducaoLocalEstoqueValoresInserir[];
  quantidade: number;
  dataHoraInicio: any;
  dataHoraTermino: any;
}

interface IOrdemProducaoContext {
  validador: boolean;

  listaLocalEstoqueRemover: string[];
  setListaLocalEstoqueRemover(lista: string[]): string[];
  listaIdEstoqueMovimentoRemover: string[];
  setListaIdEstoqueMovimentoRemover(lista: string[]): void;
  handleValidarExclusao(valor: string): Promise<boolean>;
  handleValidarExclusaoLista(lista: string[]): Promise<boolean>;
  handleAlterarSituacao({
    listaOrdemProducaoItem,
    listaOrdemProducaoLocalEstoque,
    quantidade,
    dataHoraInicio,
    dataHoraTermino,
  }: IProps): boolean;
  permiteCarregarEstruturaFilhos: boolean;
  setPermiteCarregarEstruturaFilhos: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const OrdemProducaoContext = createContext<IOrdemProducaoContext>(
  {} as IOrdemProducaoContext
);

const OrdemProducaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaLocalEstoqueRemover, setListaLocalEstoqueRemover] = useState<
    string[]
  >([]);

  const [listaIdEstoqueMovimentoRemover, setListaIdEstoqueMovimentoRemover] =
    useState<string[]>([]);

  const [permiteCarregarEstruturaFilhos, setPermiteCarregarEstruturaFilhos] =
    useState<boolean>(true);

  const handleSetListaLocalEstoqueRemover = useCallback(
    (lista: string[]): string[] => {
      const listaValor = [...lista];
      setListaLocalEstoqueRemover(lista);
      return listaValor;
    },
    []
  );

  const handleValidarExclusaoLista = useCallback(
    async (lista: string[]): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaIdEstoqueMovimentoRemover, ...lista];
        const response = await ValidarEstoqueMovimentoProducaoComunicador.store(
          {
            params: lista,
          }
        );

        if (response) {
          setListaIdEstoqueMovimentoRemover(listaIdRemover);
          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaIdEstoqueMovimentoRemover]
  );

  const handleValidarExclusao = useCallback(
    async (valor: string): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaLocalEstoqueRemover, valor];
        const response = await ValidarEstoqueMovimentoProducaoComunicador.store(
          {
            params: listaIdRemover,
          }
        );

        if (response) {
          listaIdRemover.forEach((it) => {
            setListaIdEstoqueMovimentoRemover((prevState) => {
              return [...prevState, it];
            });
          });

          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaLocalEstoqueRemover]
  );

  const handleAlterarSituacao = useCallback(
    ({
      listaOrdemProducaoItem,
      listaOrdemProducaoLocalEstoque,
      quantidade,
      dataHoraInicio,
      dataHoraTermino,
    }: IProps): boolean => {
      if (
        listaOrdemProducaoItem.length === 0 ||
        listaOrdemProducaoLocalEstoque.length === 0 ||
        !quantidade ||
        !dataHoraInicio ||
        !dataHoraTermino
      ) {
        return false;
      }

      let totaisMarcados = 0;
      listaOrdemProducaoItem.forEach((valor: any) => {
        if (valor.consumido) {
          totaisMarcados++;
        }
      });

      const somaQuantidadeLocalEstoque = listaOrdemProducaoLocalEstoque.reduce(
        (acumulador: number, item) => {
          acumulador += Number(item.quantidade) || 0;
          return acumulador;
        },
        0
      );
      if (
        totaisMarcados === listaOrdemProducaoItem.length &&
        quantidade === somaQuantidadeLocalEstoque &&
        dataHoraInicio &&
        dataHoraTermino
      ) {
        return true;
      }
      return false;
    },
    []
  );

  return (
    <OrdemProducaoContext.Provider
      value={{
        listaLocalEstoqueRemover,
        setListaLocalEstoqueRemover: handleSetListaLocalEstoqueRemover,
        listaIdEstoqueMovimentoRemover,
        setListaIdEstoqueMovimentoRemover,
        handleValidarExclusao,
        handleValidarExclusaoLista,
        handleAlterarSituacao,
        permiteCarregarEstruturaFilhos,
        setPermiteCarregarEstruturaFilhos,

        validador: true,
      }}
    >
      {children}
    </OrdemProducaoContext.Provider>
  );
};

function UseOrdemProducao(): Omit<IOrdemProducaoContext, 'validador'> {
  const context = useContext(OrdemProducaoContext);

  if (!context.validador) {
    throw new Error('UseOrdemProducao deve ser usado com um OrdemProducaoHook');
  }

  return context;
}

export { OrdemProducaoHook, UseOrdemProducao };
