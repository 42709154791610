import {
  FormatarDataHoraParaPtBr,
  IOrdemServicoVeiculoFiltroRelatorioFrontend,
  IOrdemServicoVeiculoPorItensRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  IOrdemServicoVeiculoFiltroRelatorioBackend,
  IOrdemServicoVeiculoPorItensCabecalhoRelatorio,
} from '@elogestor/util';
import React from 'react';
import OrdemServicoVeiculoPorItensPlanilha from './OrdemServicoVeiculoPorItensPlanilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import PDF from '../../../../../../Util/Relatorios/PDF';
import OrdemServicoVeiculoPorItensRelatorioComunicador from '../../../../../../Comunicador/Servicos/Relatorios/OrdemServicoVeiculo/OrdemServicoVeiculoPorItensRelatorio/OrdemServicoVeiculoPorItensRelatorioComunicador';
import OrdemServicoVeiculoPorItensPDF from './OrdemServicoVeiculoPorItensPDF';

interface IRelatorios {
  handleExportCSV(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const OrdemServicoVeiculoPorItensRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IOrdemServicoVeiculoPorItensRelatorio | null> => {
    const filtroBack: IOrdemServicoVeiculoFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,
      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCliente) {
      filtroBack.listaIdCliente =
        filtros.listaCliente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaNumeroOrdemServico) {
      filtroBack.listaNumeroOrdemServico =
        filtros.listaNumeroOrdemServico.listaValor.map(
          (value) => value.textoTag
        ) || [];
    }

    if (filtros?.listaPlacaVeiculos) {
      filtroBack.listaPlacaVeiculos =
        filtros.listaPlacaVeiculos.listaValor.map((value) => value.textoTag) ||
        [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    const response =
      await OrdemServicoVeiculoPorItensRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IOrdemServicoVeiculoPorItensRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValorSubtotal: Number(
            dados.valores.somatorioValorSubtotal
          ).FormatarParaPtBr(),
          somatorioValorTotal: Number(
            dados.valores.somatorioValorTotal
          ).FormatarParaPtBr(),
          somatorioQuantidade: Number(
            dados.valores.somatorioQuantidade
          ).FormatarParaPtBr({
            maximoCasasDecimais: 4,
            minimoCasasDecimais: 4,
          }),
        };

        const listaOrdemServicoVeiculoItem =
          dados.listaOrdemServicoVeiculoItem.map((ordemServicoVeiculo) => {
            return {
              ...ordemServicoVeiculo,
              valorQuantidade: Number(
                ordemServicoVeiculo.valorQuantidade
              ).FormatarParaPtBr({
                maximoCasasDecimais: 4,
                minimoCasasDecimais: 4,
              }),
              valorUnitario: Number(
                ordemServicoVeiculo.valorUnitario
              ).FormatarParaPtBr(),
              valorSubtotal: Number(
                ordemServicoVeiculo.valorSubtotal
              ).FormatarParaPtBr(),
              valorTotal: Number(
                ordemServicoVeiculo.valorTotal
              ).FormatarParaPtBr(),

              ordemServicoVeiculoDataHoraEmissao: FormatarDataHoraParaPtBr(
                ordemServicoVeiculo.ordemServicoVeiculoDataHoraEmissao
              ),
            };
          });

        return {
          ...dados,
          valores,
          listaOrdemServicoVeiculoItem,
        };
      })
    );

    return {
      ...retorno,
      somatorioGeralQuantidade: Number(
        retorno.somatorioGeralQuantidade
      ).FormatarParaPtBr({ maximoCasasDecimais: 4, minimoCasasDecimais: 4 }),
      somatorioGeralValorSubtotal: Number(
        retorno.somatorioGeralValorSubtotal
      ).FormatarParaPtBr(),
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend,
    cabecalho: IOrdemServicoVeiculoPorItensCabecalhoRelatorio
  ): IOrdemServicoVeiculoPorItensCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (
      filtros.listaNumeroOrdemServico &&
      filtros.listaNumeroOrdemServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroNumeroOrdemServico =
        filtros.listaNumeroOrdemServico.listaValor
          .map((valor: any) => {
            return valor.textoTag;
          })
          .join(', ');
    }

    if (
      filtros.listaPlacaVeiculos &&
      filtros.listaPlacaVeiculos.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPlacaVeiculos = filtros.listaPlacaVeiculos.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCliente = filtros.listaCliente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: OrdemServicoVeiculoPorItensPlanilha,
      nomeArquivo: 'OrdemServicoVeiculoPorItens',
      valores: dados.listaDados,
      unwind: ['listaOrdemServicoVeiculoItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: OrdemServicoVeiculoPorItensPlanilha,
      nomeArquivo: 'OrdemServicoVeiculoPorItens',
      valores: dados.listaDados as any,
      unwind: ['listaOrdemServicoVeiculoItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemServicoVeiculoPorItensPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemServicoVeiculoPorItensPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default OrdemServicoVeiculoPorItensRelatorio();
