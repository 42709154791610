import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';

interface ISubstitutoTab {
  bloquearCamposPorImportacao: boolean;
}

const SubstitutoTab: React.FC<ISubstitutoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  const { formRef } = UseForm();

  function clearSubstitutoErrors(input: boolean): void {
    if (!input) return;

    const fields = [
      'substitutoChaveAcessoCteOriginal',
      'substitutoChaveAcessoCteAnulacao',
      'substitutoChaveAcessoNfe',
      'substitutoChaveAcessoCteEmitTomador',
    ];

    fields.forEach((field) => {
      formRef.current?.setFieldError(field, '');
    });
  }

  return (
    <Divisor>
      <Row>
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e a ser Substituído (original)"
            name="substitutoChaveAcessoCteOriginal"
            placeholder="Chave de Acesso do CT-e a ser Substituído (original)"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
            disabled={bloquearCamposPorImportacao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e de Anulação"
            name="substitutoChaveAcessoCteAnulacao"
            placeholder="Chave de Acesso do CT-e de Anulação"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
            disabled={bloquearCamposPorImportacao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Input
            label="Chave de acesso da NF-e emitida pelo Tomador"
            name="substitutoChaveAcessoNfe"
            placeholder="Chave de acesso da NF-e emitida pelo Tomador"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
            disabled={bloquearCamposPorImportacao}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e emitido pelo Tomador"
            name="substitutoChaveAcessoCteEmitTomador"
            placeholder="Chave de Acesso do CT-e emitido pelo Tomador"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
            disabled={bloquearCamposPorImportacao}
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default SubstitutoTab;
