import { TipoDocumentoManutencaoDocumentoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  '/fiscal/documentos-eletronicos/manutencao-documentos-fiscais/pdf-download';

interface IManutencaoDocumentosFiscaisBaixarPdfComunicadorShow {
  params: {
    listaIdDocumentosFiscais: string[];
    tipoDocumento: TipoDocumentoManutencaoDocumentoEnum;
  };
}

class ManutencaoDocumentosFiscaisBaixarPdfComunicador {
  public async show(
    params: IManutencaoDocumentosFiscaisBaixarPdfComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ManutencaoDocumentosFiscaisBaixarPdfComunicador();
