/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  IConhecimentoTransporteLista,
  FormatarDataHoraCompletaParaPtBr,
  SituacaoConhecimentoTransporteEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../Componentes/Lista';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import SubHeader from '../../../../Componentes/Lista/SubHeader';
import { UseLog } from '../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import IPesquisa from '../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../Componentes/Lista/Interface/ITh';
import { Container } from './styles';
import TratarErros from '../../../../Util/Erro/TratarErros';
import ConhecimentoTransporteComunicador from '../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteComunicador';
import PesquisaAvancada from './PesquisaAvancada';
import Acoes from '../../../../Componentes/Lista/Acoes';
import ConhecimentoTransporteLogComunicador from '../../../../Comunicador/Transporte/ConhecimentoTransporte/Log/Comunicador/ConhecimentoTransporteLogComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import ContaRevertidaComunicador from '../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';

const ConhecimentoTransporteLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { abrirJanela } = UseConfirmacao();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      descAsc,
      limite,
      order,
      pagina,
      pesquisaAvancada,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ConhecimentoTransporteComunicador.index({
          params: {
            descAsc,
            limite,
            order,
            pagina,
            pesquisaAvancada,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IConhecimentoTransporteLista) => {
            return {
              id: data.id,
              'serie.codigoDescricao': (
                <div className="lista-valor">{data.serie.codigo}</div>
              ),
              numero: <div className="lista-valor">{data.numero}</div>,
              dataHoraEmissao: (
                <div className="lista-data">
                  {FormatarDataHoraCompletaParaPtBr(data.dataHoraEmissao)}
                </div>
              ),
              'conhecimentoTransporteImpostoIcms.operacaoFiscal.cfop': (
                <div className="lista-texto">
                  {data.conhecimentoTransporteImpostoIcms.operacaoFiscal.cfop}
                </div>
              ),
              'pessoaDestinatario.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoaDestinatario.nomeRazaoSocial}
                </div>
              ),
              'pessoaRemetente.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoaRemetente?.nomeRazaoSocial}
                </div>
              ),
              'pessoaExpedidor.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoaExpedidor?.nomeRazaoSocial}
                </div>
              ),
              'pessoaRecebedor.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoaRecebedor?.nomeRazaoSocial}
                </div>
              ),
              'pessoaTomadorOutro.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoaTomadorOutro?.nomeRazaoSocial}
                </div>
              ),
              tipoTomador: (
                <div className="lista-texto">{data.tipoTomador}</div>
              ),
              valorTotalServico: (
                <div className="lista-valor">
                  {Number(data.valorTotalServico).FormatarParaPtBr({
                    maximoCasasDecimais: 2,
                    minimoCasasDecimais: 2,
                  })}
                </div>
              ),
              situacao: (
                <div
                  className="lista-valor"
                  style={{
                    fontWeight: 'bold',
                    color:
                      data.situacao ===
                      SituacaoConhecimentoTransporteEnum.autorizado
                        ? 'green'
                        : data.situacao ===
                              SituacaoConhecimentoTransporteEnum.denegado ||
                            data.situacao ===
                              SituacaoConhecimentoTransporteEnum.emProcessamento
                          ? 'blue'
                          : data.situacao ===
                              SituacaoConhecimentoTransporteEnum.cancelado
                            ? 'red'
                            : data.situacao ===
                                SituacaoConhecimentoTransporteEnum.rejeitado
                              ? 'goldenrod'
                              : 'black',
                  }}
                >
                  {data.situacao}
                </div>
              ),
              retira: (
                <div
                  className="lista-booleano"
                  style={{ color: data.retira ? 'green' : 'red' }}
                >
                  {data.retira ? 'Sim' : 'Não'}
                </div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        const retorno = await ContaRevertidaComunicador.index({
                          params: {
                            idConhecimentoTransporte: data.id,
                          },
                        });

                        if (retorno.possuiContaParcelaRevertida) {
                          const resposta = await abrirJanela({
                            titulo: <h2>Confirmação</h2>,
                            mensagem: (
                              <span style={{ fontSize: 20 }}>
                                Essa conta possui uma reversão. Deseja excluir a
                                conta revertida?
                              </span>
                            ),
                            confimar: 'Sim',
                            cancelar: 'Não',
                          });

                          if (!resposta) {
                            setLoading(false);
                            return;
                          }
                        }

                        await ConhecimentoTransporteComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          ConhecimentoTransporteLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.TransporteConhecimentoTransporte?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );
        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [abrirJanela, permissoes.TransporteConhecimentoTransporte?.exclui]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'transporteConhecimentoTransporte' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Conhecimento de Transporte"
          permissao={permissoes.TransporteConhecimentoTransporte}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ConhecimentoTransporteLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ConhecimentoTransporteLista;
