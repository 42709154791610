import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/duplicar';

interface IConhecimentoTransporteDuplicarComunicadorStore {
  idRegistroDuplicar: string;
}

class ConhecimentoTransporteDuplicarComunicador {
  public async store({
    idRegistroDuplicar,
  }: IConhecimentoTransporteDuplicarComunicadorStore): Promise<any> {
    const response = await api.post(`${rota}/${idRegistroDuplicar}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteDuplicarComunicador();
