import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IComissoesNotaFiscalPorFaturamentoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IComissoesNotaFiscalPorFaturamentoPDF {
  dados: IComissoesNotaFiscalPorFaturamentoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ComissoesNotaFiscalPorFaturamentoPDF: React.FC<
  IComissoesNotaFiscalPorFaturamentoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRepresentante && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Representantes: '}
                      </Text>
                      {dados.cabecalho.filtroRepresentante}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresa && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresa}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '16%' }}>
              <Text>Data Emissão</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '16%' }}>
              <Text>Tipo</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '16%', alignItems: 'flex-end' }}>
              <Text>Número</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '16%', alignItems: 'flex-end' }}>
              <Text>Base Comissão</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '16%', alignItems: 'flex-end' }}>
              <Text>Percentual</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '16%', alignItems: 'flex-end' }}>
              <Text>Valor</Text>
            </View>
          </View>

          <View style={{ borderBottom: '1pt solid black' }}>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View>
                      <Text
                        style={{
                          borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                          flexDirection: 'row',
                          fontWeight: 'bold',
                          marginLeft: 5,
                          fontSize: 9,
                        }}
                      >
                        Representante: {dado.chave}
                      </Text>
                    </View>

                    <View>
                      {dado.listaComissoesNotaFiscalItem.map(
                        (itemComercialRepresentante, index) => {
                          return (
                            <View key={String(index)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor:
                                    index % 2 === 0 ? '#E4E4E4' : '#fff',
                                }}
                              >
                                <View style={{ width: '16%' }}>
                                  <Text>
                                    {
                                      itemComercialRepresentante.notaFiscalSaidaDataHoraEmissao
                                    }
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '16%' }}>
                                  <Text>{itemComercialRepresentante.tipo}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '16%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {
                                      itemComercialRepresentante.notaFiscalSaidaNumero
                                    }
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '16%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {itemComercialRepresentante.baseCalculo}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '16%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {itemComercialRepresentante.aliquota}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '16%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {itemComercialRepresentante.valor}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}

                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 9,
                        }}
                      >
                        <View style={{ width: '50%' }}>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                          >
                            Total do Representante:
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '16%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somaTotalBaseCalculoPorRepresentante}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '16%' }} />
                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '16%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somaTotalValorPorRepresentante}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            >
              <View style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                    marginLeft: 2,
                  }}
                >
                  Total Geral:
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View style={{ width: '16%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somaTotalGeralBaseCalculo}
                </Text>
              </View>
              <View style={{ width: '1%' }} />
              <View style={{ width: '16%' }} />
              <View style={{ width: '1%' }} />

              <View style={{ width: '16%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somaTotalGeralValor}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ComissoesNotaFiscalPorFaturamentoPDF;
