import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-ordem-servico/gerar';

interface INotaFiscalSaidaImportacaoOrdemServicoGerarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoOrdemServicoGerarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoOrdemServicoGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoOrdemServicoGerarComunicador();
