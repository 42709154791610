import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/nota-fiscal-saida/nota-fiscal-saida-total-por-modelo-documento';

interface INotaFiscalSaidaTotalPorModeloDocumentoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalSaidaTotalPorModeloDocumentoRelatorioComunicador {
  public async index(
    params: INotaFiscalSaidaTotalPorModeloDocumentoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalSaidaTotalPorModeloDocumentoRelatorioComunicador();
