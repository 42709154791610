"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDataAtualizacaoProdutoEnum;
(function (TipoDataAtualizacaoProdutoEnum) {
    TipoDataAtualizacaoProdutoEnum["emissaoNota"] = "Emiss\u00E3o da Nota";
    TipoDataAtualizacaoProdutoEnum["entradaNota"] = "Entrada da Nota";
    TipoDataAtualizacaoProdutoEnum["lancamento"] = "Lan\u00E7amento";
    TipoDataAtualizacaoProdutoEnum["entradaEstabelecimento"] = "Entrada no Estabelecimento";
    TipoDataAtualizacaoProdutoEnum["entradaEstoque"] = "Ent. no Estoque (Desc. c/ Ent. Fora do Per\u00EDodo)";
})(TipoDataAtualizacaoProdutoEnum || (TipoDataAtualizacaoProdutoEnum = {}));
exports.default = TipoDataAtualizacaoProdutoEnum;
