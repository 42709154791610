"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CalculoBaseComissao;
function CalculoBaseComissao(dados) {
    const valorTotalBruto = dados.valorTotalBruto
        ? Number(dados.valorTotalBruto)
        : 0;
    const valorImpostoIcms = dados.valorImpostoIcms
        ? Number(dados.valorImpostoIcms)
        : 0;
    const valorImpostoIpi = dados.valorImpostoIpi
        ? Number(dados.valorImpostoIpi)
        : 0;
    const valorFrete = dados.valorFrete ? Number(dados.valorFrete) : 0;
    const valorOutrasDespesas = dados.valorOutrasDespesas
        ? Number(dados.valorOutrasDespesas)
        : 0;
    const valorSeguro = dados.valorSeguro ? Number(dados.valorSeguro) : 0;
    let baseCalculo = valorTotalBruto -
        (valorImpostoIcms +
            valorImpostoIpi +
            valorFrete +
            valorOutrasDespesas +
            valorSeguro);
    if (baseCalculo < 0)
        baseCalculo = 0;
    return baseCalculo;
}
