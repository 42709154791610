import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import RegraEscolhaCstCreditoIcmsComunicador from '../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoIcms/Comunicador/RegraEscolhaCstCreditoIcmsComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const RegraEscolhaCstCreditoIcmsHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: RegraEscolhaCstCreditoIcmsComunicador }}
    >
      <ButtonsSubHeaderProvider>
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ButtonsSubHeaderProvider>
    </ComunicadorProvider>
  );
};

export default RegraEscolhaCstCreditoIcmsHook;
