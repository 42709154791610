"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ValidarCpf;
function ValidarCpf(cpf) {
    if (!cpf)
        return false;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '' || cpf.length !== 11)
        return false;
    if (cpf === '00000000000')
        return true;
    let numeros;
    let digitos;
    let soma;
    let resultado;
    let digitosIguais = 1;
    for (let i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            digitosIguais = 0;
            break;
        }
    if (!digitosIguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (let i = 10; i > 1; i--)
            soma += Number(numeros.charAt(10 - i)) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== Number(digitos.charAt(0)))
            return false;
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (let i = 11; i > 1; i--)
            soma += Number(numeros.charAt(11 - i)) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== Number(digitos.charAt(1)))
            return false;
        return true;
    }
    return false;
}
