"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoElementoEnum;
(function (TipoElementoEnum) {
    TipoElementoEnum["campo"] = "Campo";
    TipoElementoEnum["cnpjCpf"] = "CNPJ/CPF";
    TipoElementoEnum["cep"] = "CEP";
    TipoElementoEnum["data"] = "Data";
    TipoElementoEnum["dataHora"] = "Data Hora";
    TipoElementoEnum["chaveAcesso"] = "Chave de Acesso";
    TipoElementoEnum["telefone"] = "Telefone";
    TipoElementoEnum["numeroInteiro"] = "N\u00FAmero Inteiro";
    TipoElementoEnum["numeroDecimal"] = "N\u00FAmero Decimal";
    TipoElementoEnum["textoLivre"] = "Texto Livre";
    TipoElementoEnum["formaLinha"] = "Forma Linha";
    TipoElementoEnum["formaQuadradoRetangulo"] = "Forma Quadrado/Retangulo";
    TipoElementoEnum["formaCirculo"] = "Forma C\u00EDrculo";
    TipoElementoEnum["codigoBarras"] = "C\u00F3digo de Barras";
    TipoElementoEnum["codigoBarrasCodigoProduto"] = "C\u00F3digo de Barras (C\u00F3digo do Produto)";
    TipoElementoEnum["imagem"] = "Imagem";
    TipoElementoEnum["codigoBarrasInicioCodigoProduto"] = "C\u00F3digo de Barras (Inicio C\u00F3digo do Produto)";
})(TipoElementoEnum || (TipoElementoEnum = {}));
exports.default = TipoElementoEnum;
