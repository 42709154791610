import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { SisdevinEstoqueMovimentoListaHook } from './SisdevinEstoqueMovimentoListaHook';

const SisdevinEstoqueMovimentoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <SisdevinEstoqueMovimentoListaHook>
      {children}
    </SisdevinEstoqueMovimentoListaHook>
  );
};

export default SisdevinEstoqueMovimentoHook;
