import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/importacao-exportacao-csv-estoques';

interface IImportacaoExportacaoCSVEstoquesComunicadorIndex {
  params: {
    buscarProdutosAtivos: boolean;
    buscarProdutosComSaldo: boolean;
  };
}

interface IImportacaoExportacaoCSVEstoquesComunicadortore {
  params: any;
}

class ImportacaoExportacaoCSVEstoquesComunicador {
  public async index({
    params,
  }: IImportacaoExportacaoCSVEstoquesComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });
    return response.data;
  }

  public async store({
    params,
  }: IImportacaoExportacaoCSVEstoquesComunicadortore): Promise<any> {
    const response = await api.post(rota, { params });
    return response.data;
  }
}

export default new ImportacaoExportacaoCSVEstoquesComunicador();
