import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/usuario-auto-complete';

interface IUsuarioAutoCompleteComunicadorIndex {
  params: any;
}

class UsuarioAutoCompleteComunicador {
  public async index(
    params: IUsuarioAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new UsuarioAutoCompleteComunicador();
