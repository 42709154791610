import {
  DestinadoEnum,
  FormatarEnum,
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputAutoCompleteTipoNota from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsOperacaoInterestadualTab } from '../../../Hook/ItemIcmsOperacaoInterestadualTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseAuth } from '../../../../../../../../../../Hooks/Auth';

const ItemImpostoConfiguracaoTributacaoTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const {
    calcularImpostosPorCst,
    obterConfiguracaoTributaria,
    limparConfiguracaoTributaria,
  } = UseItemTab();
  const { obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();
  const { formRef: formPrincipal } = UseForm();
  const { empresa } = UseAuth();

  const indicadorPresenca =
    formPrincipal.current?.getFieldValue('indicadorPresenca');

  const ufPessoa =
    formPrincipal.current?.getFieldValue(
      'pedidoCompraPessoaEndereco.cidade.estado.sigla'
    ) || null;
  const ufPessoaEntrega =
    formPrincipal.current?.getFieldValue(
      'pedidoCompraPessoaEnderecoEntrega.cidade.estado.sigla'
    ) || null;

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual =
    useCallback(() => {
      obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
    }, [obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual]);

  const handleObterConfiguracaoTributaria = useCallback(() => {
    obterConfiguracaoTributaria();
  }, [obterConfiguracaoTributaria]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNota
              label="Tipo de Nota"
              name="idTipoNota"
              placeholder="Tipo de Nota"
              nomeObjeto="tipoNota"
              filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.entrada}
              onChangeItemAtualAposCarregarSemClear={() => {
                formRefDetalhe.current?.clearField('tipoNotaMotivo', {
                  setValorInicial: false,
                });
                refresh();
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteTipoNotaMotivo
              label="Motivo"
              name="idTipoNotaMotivo"
              placeholder="Motivo"
              nomeObjeto="tipoNotaMotivo"
              disabled={!formRefDetalhe.current?.getFieldValue('idTipoNota')}
              idTipoNota={formRefDetalhe.current?.getFieldValue('idTipoNota')}
              onChangeItemAtualAposCarregarSemClear={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Select
              label="Destinado"
              name="destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
                nullavel: true,
              })}
              onChange={() => {
                handleLimparConfiguracaoTributaria();
                handleObterConfiguracaoTributaria();
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
              }}
            />
          </Col>
          <Col lg={8} md={12} sm={12}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="idOperacaoFiscal"
              placeholder="CFOP"
              nomeObjeto="operacaoFiscal"
              indicadorPresenca={indicadorPresenca}
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.entrada}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem: empresa.uf,
                ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
                indicadorPresenca,
              })}
              onChangeItemAtualAposCarregarSemClear={() => {
                handleCalcularImpostosPorCst();
                handleObterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
              }}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoConfiguracaoTributacaoTab;
