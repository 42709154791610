"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StringConverterParaEnumContemValor;
function StringConverterParaEnumContemValor({ enumObj, valorPadrao, valor, }) {
    let resultado = valorPadrao;
    if (valor === undefined)
        return resultado;
    Object.entries(enumObj).forEach(([key, value]) => {
        if (value.includes(String(valor))) {
            resultado = enumObj[key];
        }
    });
    return resultado;
}
