import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/cultivar-auto-complete';

interface ICultivarAutoCompleteComunicadorIndex {
  params: any;
}

class CultivarAutoCompleteComunicador {
  public async index(
    params: ICultivarAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CultivarAutoCompleteComunicador();
