const OrcamentoPorDataEmissaoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'chave',
  },

  {
    titulo: 'Modelo Documento',
    valor: 'listaOrcamentoItem.orcamentoModeloDocumento',
  },

  {
    titulo: 'Número',
    valor: 'listaOrcamentoItem.orcamentoNumero',
  },

  {
    titulo: 'Hora',
    valor: 'listaOrcamentoItem.orcamentoHoraEmissao',
  },
  {
    titulo: 'Situação do Item',
    valor: 'listaOrcamentoItem.orcamentoSituacaoItem',
  },

  {
    titulo: 'Cliente',
    valor: 'listaOrcamentoItem.clienteNomeRazaoSocial',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaOrcamentoItem.valorSubtotal',
  },
  {
    titulo: 'Desconto',
    valor: 'listaOrcamentoItem.valorDesconto',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaOrcamentoItem.valorTotal',
  },
];

export default OrcamentoPorDataEmissaoPlanilha;
