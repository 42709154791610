/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { BiEdit } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import {
  EstoqueIndicadorTipoEnum,
  FormatarDataHoraParaPtBr,
  IListaEstoqueUtilizadoAutoComplete,
  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum,
  TipoLocalEstoqueEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import Janela, { IJanelaRef } from '../../../../JanelaAutocomplete';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import Detalhe from '../../../../Detalhe';
import Main from '../../../../../Paginas/Suprimentos/Produtos/Estoque/LocalEstoque/Detalhe/Main';
import { RedirecionarProvider } from '../../../../../Hooks/RedirecionarContext';
import { Item } from '../../AutoCompleteBase/styles';
import LocalEstoqueHook from '../../../../../Paginas/Suprimentos/Produtos/Estoque/LocalEstoque/Detalhe/Hooks';
import LocalEstoqueAutoCompleteComunicador from '../../../../../Comunicador/Suprimentos/Produtos/Estoque/LocalEstoque/AutoComplete/LocalEstoqueAutoComplete/LocalEstoqueAutoCompleteComunicador';
import { AutoCompleteProvider } from '../../AutoCompleteBase/Hooks/AutoCompleteHook';
import { ContainerAutoComplete } from './styles';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface ILocalEstoque {
  id: string;
  descricao: string;
  associacao: string;
  disponivelParaVenda: boolean;

  saldoEstoque?: {
    saldo: number;
    unidadeMedidaSigla: string;
    dataHoraSaldo: Date;
  };
}

interface IDadosPadrao {
  descricao: string;
}

export interface IOnChangeItemAtualAutoCompleteLocalEstoqueEvent {
  itemAtual: ILocalEstoque | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteLocalEstoqueEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteLocalEstoqueEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteLocalEstoqueProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  utilizavelLancamentoEstoque?: boolean;
  permitirAdicionar?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];

  saldoEstoque?: {
    somenteProdutoComSaldoEstoque: boolean;
    idPessoa?: string;
    idProduto?: string;
    dataHoraEmissao?: Date;
    indicadorTipoEstoque: EstoqueIndicadorTipoEnum;
    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum;

    localUtilizado: LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum;
    idItemEstoque?: string;
    listaEstoqueUtilizado?: IListaEstoqueUtilizadoAutoComplete[];
    exibirSaldoEstoque: boolean;
  };

  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteLocalEstoqueEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteLocalEstoqueEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteLocalEstoqueEvent
  ) => void | Promise<void>;

  onBlurInput?: (
    event: IOnBlurInputAutoCompleteLocalEstoqueEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteLocalEstoqueRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteLocalEstoque: React.ForwardRefRenderFunction<
  IInputAutoCompleteLocalEstoqueRef,
  IInputAutoCompleteLocalEstoqueProps
> = (
  {
    name,
    nomeObjeto,
    label,
    utilizavelLancamentoEstoque,
    permitirAdicionar = true,
    ativo = true,
    listaIdParaRemover = [],
    saldoEstoque,
    onBlurInput,
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    ...rest
  },
  ref
) => {
  const { permissoes } = UsePermissoes();
  const [idRegistro, setIdRegistro] = useState('');

  const janelaRef = useRef<IJanelaRef>(null);

  const quantidadeAlterado = useRef(0);

  const [dadosRecuperados, setDadosRecuperados] = useState(null);
  const [dadosPadrao, setDadosPadrao] = useState<IDadosPadrao | null>(null);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<ILocalEstoque | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        if (value) setIdRegistro(value.id);
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (itemAtual) {
        setIdRegistro(itemAtual?.id);
        setDadosPadrao(null);
      } else {
        setIdRegistro('');
      }
      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      setDadosPadrao({
        descricao: input?.value || '',
      });
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleSalva = useCallback(
    (data: any) => {
      janelaRef.current?.toggleJanela();
      autoCompleteRef.current?.selecionarItem(data);
    },
    [autoCompleteRef]
  );

  const handleRecuperarFormulario = useCallback((dadosParaRecuparar: any) => {
    setDadosRecuperados(dadosParaRecuparar);
    janelaRef.current?.toggleJanela();
  }, []);

  const handleDescartarFormulario = useCallback(() => {
    setDadosRecuperados(null);
  }, []);

  const permiteAdicionarAlterar = useMemo<boolean>(() => {
    const itemAtual = autoCompleteRef.current?.getItemAtual();

    return (
      permitirAdicionar &&
      ((permissoes?.SuprimentosProdutosEstoqueLocalEstoque?.altera &&
        itemAtual?.id) ||
        (permissoes?.SuprimentosProdutosEstoqueLocalEstoque?.inclui &&
          !itemAtual?.id))
    );
  }, [autoCompleteRef, permissoes, permitirAdicionar]);

  const dadosObrigatorios = useMemo(() => {
    return {
      tipo: TipoLocalEstoqueEnum.interno,
      disponivelParaVenda: true,
      ativo: true,
    };
  }, []);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);
              try {
                const response =
                  await LocalEstoqueAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite,
                      utilizavelLancamentoEstoque,
                      texto: valor,
                      ativo,
                      listaIdParaRemover,
                      saldoEstoque,
                    },
                  });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: ILocalEstoque) => {
              return item.id;
            }}
            obterLabel={(item: ILocalEstoque) => {
              if (!item.descricao) return '';

              return item.descricao;
            }}
            personalizarItem={({ selecionado, navegacao, item }) => {
              return (
                <Item $selecionado={selecionado} $navegacao={navegacao}>
                  <strong>{item.descricao}</strong>
                  <span>
                    {saldoEstoque &&
                    saldoEstoque.exibirSaldoEstoque &&
                    item.saldoEstoque
                      ? ` ⬌ Saldo: ${Number(
                          item.saldoEstoque.saldo
                        ).FormatarParaPtBr()} - ${
                          item.saldoEstoque.unidadeMedidaSigla
                        } - Dt: ${FormatarDataHoraParaPtBr(
                          item.saldoEstoque.dataHoraSaldo
                        )}`
                      : ''}
                  </span>
                  <br />
                  <span>{item.associacao}</span>
                  <span>
                    Disponível para venda:
                    {item.disponivelParaVenda ? ' Sim' : ' Não'}
                  </span>
                </Item>
              );
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />

          {permiteAdicionarAlterar && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={v4()}>
                  {idRegistro ? 'Editar' : 'Adicionar'}
                </Tooltip>
              }
            >
              <ContainerAutoComplete>
                <div>
                  <Button
                    onClick={() => {
                      janelaRef.current?.toggleJanela();
                    }}
                    style={{ marginLeft: 10, marginBottom: 5, height: 35 }}
                  >
                    {idRegistro ? <BiEdit /> : <IoMdAddCircleOutline />}
                  </Button>
                </div>
              </ContainerAutoComplete>
            </OverlayTrigger>
          )}
        </div>

        {permiteAdicionarAlterar && (
          <Janela titulo="Local de Estoque" ref={janelaRef} tamanho="xl">
            <RedirecionarProvider value={{ redirecionar: false }}>
              <LocalEstoqueHook>
                <AutoCompleteProvider>
                  <Detalhe
                    style={{}}
                    onRecuperarFormulario={handleRecuperarFormulario}
                    onDescartarFormulario={handleDescartarFormulario}
                  >
                    <Main
                      idEditar={idRegistro ?? null}
                      dadosRecuperados={dadosRecuperados}
                      dadosDuplicados={null}
                      dadosObrigatorios={dadosObrigatorios}
                      dadosPadrao={dadosPadrao}
                      onClickSalvar={handleSalva}
                    />
                  </Detalhe>
                </AutoCompleteProvider>
              </LocalEstoqueHook>
            </RedirecionarProvider>
          </Janela>
        )}
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteLocalEstoque);
