import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/serie/obter-proximo-numero';

interface ISerieObterProximoNumeroIncrementarComunicadorShow {
  id: string;
}

class SerieObterProximoNumeroIncrementarComunicador {
  public async show(
    params: ISerieObterProximoNumeroIncrementarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new SerieObterProximoNumeroIncrementarComunicador();
