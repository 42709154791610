import api from '../../../../Comum/Services/Api';

const rota = 'comercial/comissao/regra-calculo-comissao/ordem';

interface IRegraCalculoComissaoOrdemComunicadorUpdate {
  params: any;
}

class RegraCalculoComissaoOrdemComunicador {
  public async update(
    params: IRegraCalculoComissaoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraCalculoComissaoOrdemComunicador();
