"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Modulo11Calculo;
function Modulo11Calculo(dados) {
    if (dados.tipoSomar == null)
        dados.tipoSomar = true;
    let tamanho = dados.chave.length;
    let multiplicador = dados.inicio;
    let total = 0;
    let numero = 0;
    while (tamanho >= 1) {
        numero = Number(dados.chave.substring(tamanho - 1, tamanho));
        numero *= multiplicador;
        if (multiplicador === dados.fim) {
            multiplicador = dados.inicio;
        }
        else if (dados.tipoSomar) {
            multiplicador++;
        }
        else {
            multiplicador--;
        }
        total += numero;
        tamanho--;
    }
    total %= 11;
    let digito = 11 - total;
    if (digito > 9)
        digito = 0;
    return digito;
}
