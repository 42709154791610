import api from '../../../../../Comum/Services/Api';

const rota =
  '/fiscal/documentos-eletronicos/geracao-de-arquivo-contabeis/sisdevin';

interface IGeracaoArquivosContabeisSisdevinComunicadorShow {
  params: {
    dataEmissaoInicial: Date;
    dataEmissaoFinal: Date;
    sisdevinEngarrafadoseGranel: boolean;
    sisdevinNotasCompraUva: boolean;
    sisdevinCortes: boolean;
    sisdevinDeclaracaoProducao: boolean;
    sisdevinEnologicos: boolean;
    sisdevinComunicacaoPerdas: boolean;
    sisdevinDeclaracaoEstoque: boolean;
  };
}

class GeracaoArquivosContabeisSisdevinComunicador {
  public async show(
    params: IGeracaoArquivosContabeisSisdevinComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
    });

    return response.data;
  }
}

export default new GeracaoArquivosContabeisSisdevinComunicador();
