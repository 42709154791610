import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/impressao-etiquetas-pedido-venda-item';

interface IImpressaoEtiquetasPedidoVendaItemComunicadorIndex {
  params: {
    idPedidoVendaEtiquetaSelecionada?: string | null;
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

class ImpressaoEtiquetasPedidoVendaItemComunicador {
  public async index(
    params: IImpressaoEtiquetasPedidoVendaItemComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImpressaoEtiquetasPedidoVendaItemComunicador();
