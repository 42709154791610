import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import {
  BuscaProdutosCadastradosEnum,
  FormatarEnum,
  StringConverterParaEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { RiCheckboxMultipleLine } from 'react-icons/ri/index.mjs';
import { Container, ContainerDados, LoadingContainer } from './styles';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Divisor } from '../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../Componentes/Select';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import SelecionarXmlEmitidosArmazenarLista from './SelecionarXmlEmitidosArmazenarLista';
import { UseImportacaoXmlEmitidos } from '../Hook/ImportacaoXmlEmitidosHook';
import SelecionarXmlCancelamentoCartaCorrecaoArmazenarLista from './SelecionarXmlCancelamentoCartaCorrecaoLista';
import SelecionarXmlConhecimentosEmitidosArmazenarLista from './SelecionarXmlConhecimentosEmitidosLista';
import SelecionarXmlServicosEmitidosArmazenarLista from './SelecionarXmlServicosEmitidosArmazenarLista';
import InputAutoCompleteServico from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteServico';
import SelecionarXmlInutilizacaoNumeracaoArmazenarLista from './SelecionarXmlInutilizacaoNumeracaoArmazenarLista';

const ImportacaoXmlEmitidosMain: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const formRef = useRef<IFormCiaHandles>(null);
  const { permissoes } = UsePermissoes();
  const { FiscalDocumentosEletronicosImportacaoXmlEmitidos: permissao } =
    permissoes;
  const { listaMensagemErro, setListaMensagemErro } =
    UseImportacaoXmlEmitidos();

  const [loading, setLoading] = useState(false);
  const [atualizarProdutos, setAtualizarProdutos] = useState(true);
  const [buscarProdutosAtivos, setBuscarProdutosAtivos] = useState(true);
  const [ativoNovosProdutos, setAtivoNovosProdutos] = useState(true);
  const [gerarFinanceiro, setGerarFinanceiro] = useState(true);
  const [movimentarEstoque, setMovimentarEstoque] = useState(true);
  const [buscaProdutosCadastrados, setBuscaProdutosCadastrados] = useState(
    BuscaProdutosCadastradosEnum.codigoDescricao
  );
  const [idServico, setIdServico] = useState<null | string>(null);

  const [showFormModalEmitSelecaoXml, setShowFormModalEmitSelecaoXml] =
    useState(false);
  const [showFormModalServiEmitSelecaoXml, setShowFormModalServEmitSelecaoXml] =
    useState(false);
  const [
    showFormModalConhecEmitSelecaoXml,
    setShowFormModalConhecEmitSelecaoXml,
  ] = useState(false);
  const [showFormModalCanCartSelecaoXml, setShowFormModalCanCartSelecaoXml] =
    useState(false);

  const [
    showFormModalInutilizacaoSelecaoXml,
    setShowFormModalInutilizacaoSelecaoXml,
  ] = useState(false);

  // #region Notas Emitidas

  const handleSelecionarXmlsEmitidosParaArmazenar = useCallback(async () => {
    setShowFormModalEmitSelecaoXml(true);

    setListaMensagemErro([]);
  }, [setListaMensagemErro]);

  const handleFecharFormModalSelecaoXmlEmit =
    useCallback(async (): Promise<void> => {
      setShowFormModalEmitSelecaoXml(false);
    }, []);

  // #endregion Notas Emitidas

  // #region Notas Servico Emitidas

  const handleSelecionarXmlsServicosEmitidosParaArmazenar =
    useCallback(async () => {
      setShowFormModalServEmitSelecaoXml(true);

      setListaMensagemErro([]);
    }, [setListaMensagemErro]);

  const handleFecharFormModalSelecaoXmlServicosEmit =
    useCallback(async (): Promise<void> => {
      setShowFormModalServEmitSelecaoXml(false);
    }, []);

  // #endregion Notas Servico Emitidas

  // #region Conhecimentos Emitidos

  const handleSelecionarXmlConhecEmitParaArmazenar = useCallback(async () => {
    setShowFormModalConhecEmitSelecaoXml(true);

    setListaMensagemErro([]);
  }, [setListaMensagemErro]);

  const handleFecharFormXmlConhecEmitParaArmazenar = useCallback(async () => {
    setShowFormModalConhecEmitSelecaoXml(false);
  }, []);

  // #endregion Conhecimentos Emitidos

  // #region Inutilizações

  const handleSelecionarXmlInutilizacoesParaArmazenar =
    useCallback(async () => {
      setShowFormModalInutilizacaoSelecaoXml(true);

      setListaMensagemErro([]);
    }, [setListaMensagemErro]);

  const handleFecharmFormXmlInutilizacaoParaArmazenar =
    useCallback(async () => {
      setShowFormModalInutilizacaoSelecaoXml(false);
    }, []);

  // #endregion Inutilizações

  // #region Cancelamento / Carta de Correcao

  const handleSelecionarXmlCanCartParaArmazenar = useCallback(async () => {
    setShowFormModalCanCartSelecaoXml(true);

    setListaMensagemErro([]);
  }, [setListaMensagemErro]);

  const handleFecharFormXmlCanCartParaArmazenar = useCallback(async () => {
    setShowFormModalCanCartSelecaoXml(false);
  }, []);

  // #endregion Cancelamento / Carta de Correcao

  useEffect(() => {
    formRef.current?.setDataInicial({
      atualizarProdutos: true,
      buscarProdutosAtivos: true,
      ativoNovosProdutos: true,
      gerarFinanceiro: true,
      movimentarEstoque: true,
      buscaProdutosCadastrados: BuscaProdutosCadastradosEnum.codigoDescricao,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <LoadingDiv isLoading={loading} />

        <SubHeaderGeral>
          <h1>Importação de XMLs Emitidos</h1>

          <ContainerButtons>
            <SplitAcoes
              id={v4()}
              className="btn-group"
              title={
                <Row>
                  <Col>
                    {loading ? (
                      <LoadingContainer style={{ display: 'flex' }}>
                        <Spinner
                          className="spinner"
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          style={{
                            width: '22px',
                            height: '22px',
                            fontSize: '10px',
                            marginRight: '5px',
                          }}
                        />
                      </LoadingContainer>
                    ) : (
                      <div>
                        <CgMoreO />
                        {telaGrande && (
                          <span style={{ marginLeft: 10, marginRight: 10 }}>
                            Ações
                          </span>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              }
            >
              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleSelecionarXmlsEmitidosParaArmazenar}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
                  Importar Notas Emitidas
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleSelecionarXmlsServicosEmitidosParaArmazenar}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
                  Importar Notas de Serviços Emitidas
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleSelecionarXmlConhecEmitParaArmazenar}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
                  Importar Conhecimentos Emitidos
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleSelecionarXmlInutilizacoesParaArmazenar}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
                  Importar Inutilizações
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                disabled={loading || !permissao?.inclui || !permissao?.altera}
                onClick={handleSelecionarXmlCanCartParaArmazenar}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <RiCheckboxMultipleLine style={{ marginRight: 5 }} />
                  Imp. Canc. e Carta de Correc.
                </span>
              </Dropdown.Item>
            </SplitAcoes>
          </ContainerButtons>
        </SubHeaderGeral>

        <FormCia ref={formRef}>
          <ContainerDados style={{ margin: '50px 0px 0px 0px' }}>
            <Divisor>
              <h6 style={{ fontWeight: 'bold', paddingTop: 10 }}>
                Configuração para Importação de Notas Emitidas
              </h6>
              <Row>
                <Col xl={2} lg={4}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Atualizar Produtos"
                    name="atualizarProdutos"
                    largura={90}
                    onChange={(event) =>
                      setAtualizarProdutos(event.currentTarget.checked)
                    }
                  />
                </Col>
                <Col xl={2} lg={3}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Buscar Somente Produtos Ativos"
                    name="buscarProdutosAtivos"
                    largura={90}
                    onChange={(event) => {
                      setBuscarProdutosAtivos(event.currentTarget.checked);
                    }}
                  />
                </Col>

                <Col xl={2} lg={3}>
                  <InputSwitch
                    label="Ativo Para Novos Produtos"
                    name="ativoNovosProdutos"
                    largura={90}
                    onChange={(event) => {
                      setAtivoNovosProdutos(event.currentTarget.checked);
                    }}
                  />
                </Col>
                <Col xl={2} lg={3}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Gerar Financeiro Conforme XML"
                    name="gerarFinanceiro"
                    largura={90}
                    onChange={(event) => {
                      setGerarFinanceiro(event.currentTarget.checked);
                    }}
                  />
                </Col>

                <Col xl={2} lg={3}>
                  <InputSwitch
                    ativo="Sim"
                    inativo="Não"
                    label="Movimentar Estoque"
                    name="movimentarEstoque"
                    largura={90}
                    onChange={(event) => {
                      setMovimentarEstoque(event.currentTarget.checked);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={8} lg={8}>
                  <Select
                    label="Busca de Produtos Cadastrados"
                    name="buscaProdutosCadastrados"
                    options={FormatarEnum({
                      enumObj: BuscaProdutosCadastradosEnum,
                    })}
                    onChange={(event) => {
                      setBuscaProdutosCadastrados(
                        StringConverterParaEnum<
                          typeof BuscaProdutosCadastradosEnum,
                          BuscaProdutosCadastradosEnum
                        >(
                          BuscaProdutosCadastradosEnum,
                          String(event.currentTarget.value)
                        )
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={8} lg={8}>
                  <InputAutoCompleteServico
                    label="Serviço (Padrão para Importação de Notas de Serviços)"
                    name="idServicoPadrao"
                    placeholder="Serviço"
                    onChangeItemAtualAposCarregarSemClear={(ev) => {
                      if (ev.itemAtual) {
                        setIdServico(ev.itemAtual.id);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Divisor>

            {listaMensagemErro.length > 0 && (
              <Divisor>
                {listaMensagemErro.map((valor) => {
                  return (
                    <Row>
                      <h6 style={{ color: 'red', fontWeight: 'bold' }}>
                        {valor}
                      </h6>
                    </Row>
                  );
                })}
              </Divisor>
            )}
          </ContainerDados>
        </FormCia>
      </Container>

      {showFormModalEmitSelecaoXml && (
        <SelecionarXmlEmitidosArmazenarLista
          onFecharFormModalLista={handleFecharFormModalSelecaoXmlEmit}
          atualizarProdutos={atualizarProdutos}
          movimentarEstoque={movimentarEstoque}
          buscaProdutosCadastrados={buscaProdutosCadastrados}
          ativoNovosProdutos={ativoNovosProdutos}
          buscarProdutosAtivos={buscarProdutosAtivos}
          gerarFinanceiro={gerarFinanceiro}
        />
      )}

      {showFormModalCanCartSelecaoXml && (
        <SelecionarXmlCancelamentoCartaCorrecaoArmazenarLista
          onFecharFormModalLista={handleFecharFormXmlCanCartParaArmazenar}
        />
      )}

      {showFormModalConhecEmitSelecaoXml && (
        <SelecionarXmlConhecimentosEmitidosArmazenarLista
          onFecharFormModalLista={handleFecharFormXmlConhecEmitParaArmazenar}
        />
      )}

      {showFormModalInutilizacaoSelecaoXml && (
        <SelecionarXmlInutilizacaoNumeracaoArmazenarLista
          onFecharFormModalLista={handleFecharmFormXmlInutilizacaoParaArmazenar}
        />
      )}

      {showFormModalServiEmitSelecaoXml && (
        <SelecionarXmlServicosEmitidosArmazenarLista
          onFecharFormModalLista={handleFecharFormModalSelecaoXmlServicosEmit}
          ativoNovosProdutos={ativoNovosProdutos}
          buscarProdutosAtivos={buscarProdutosAtivos}
          gerarFinanceiro={gerarFinanceiro}
          idServico={idServico}
        />
      )}
    </>
  );
};

export default ImportacaoXmlEmitidosMain;
