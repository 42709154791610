import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/upload-pdf';

interface INotaFiscalSaidaUploadPdfComunicadorUpdate {
  params: any;
}

class NotaFiscalSaidaUploadPdfComunicador {
  public async update(
    params: INotaFiscalSaidaUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaUploadPdfComunicador();
