/* eslint-disable react/jsx-no-undef */
/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  DesestruturarPesquisa,
  DestinadoEnum,
  EstoqueIndicadorTipoEnum,
  FormatarDataHoraParaPtBr,
  IAnaliseCustoVendaLista,
  IFrenteCaixaPdvItemLista,
  IndicadorPresencaPadraoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Dropdown,
  Row,
  Col,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { MdAssignmentReturn, MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineEdit } from 'react-icons/ai/index.mjs';
import { BsBoxSeam } from 'react-icons/bs/index.mjs';
import { v4 } from 'uuid';
import { FiTrendingUp } from 'react-icons/fi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import Divisor from '../../../../../../Componentes/Divisor';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputDecimal, {
  IInputDecimalRef,
} from '../../../../../../Componentes/Inputs/InputDecimal';
import InputCnpjCpf from '../../../../../../Componentes/Inputs/InputCnpjCpf';
import InputAutoCompleteCliente from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import InputAutoCompleteRegraEscolhaTabelaPreco, {
  IRegraEscolhaTabelaPreco,
} from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRegraEscolhaTabelaPreco';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import ContainerButtons from '../../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import AcoesFrenteCaixaPdv from '../AcoesFrenteCaixaPdv';
import AcoesFinalizarVenda, {
  IAcoesFinalizarVendaRef,
} from '../AcoesFinalizarVenda';
import AcoesPesquisarVenda from '../AcoesPesquisarVenda';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';
import BtnAcoesButton from '../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ItemDetalhe from '../Item/Detalhe';
import BtnDeletar from '../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import PessoaObterPorCnpjCpfComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorCnpjCpfComunicador';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseFrenteCaixaPdv } from '../Hooks/FrenteCaixaPdvHook';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import { UseFrenteCaixaPdvCalculo } from '../Hooks/FrenteCaixaPdvCalculoHook';
import FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador';
import InputAutoCompleteProdutoFrenteCaixaPdv, {
  IInputAutoCompleteProdutoFrenteCaixaPdvRef,
  IProdutoFrenteCaixaPdv,
} from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoFrenteCaixaPdv';
import LocalEstoqueObterPorEstoqueCombinacaComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/LocalEstoque/Comunicador/LocalEstoqueObterPorEstoqueCombinacaComunicador';
import LoteObterPorEstoqueCombinacaComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/Lote/Comunicador/LoteObterPorEstoqueCombinacaComunicador';
import ISelecaoItemEstoqueLocalEstoque, {
  UseSelecaoItemEstoqueLocalEstoque,
} from '../Hooks/SelecaoItemEstoqueLocalEstoqueHook';
import ISelecaoItemEstoqueLote, {
  UseSelecaoItemEstoqueLote,
} from '../Hooks/SelecaoItemEstoqueLoteHook';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';
import FrenteCaixaPdvObterRegraEscolhaTabelaPrecoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterRegraEscolhaTabelaPrecoComunicador';
import DevolucaoItemDetalhe from '../Item/Devolucao/Detalhe';
import FrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicador';
import ConsultaEstoqueListaHook from '../../../../../Suprimentos/Produtos/ConsultaEstoque/Hook';
import ConsultaEstoqueLista from '../../../../../Suprimentos/Produtos/ConsultaEstoque';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import FrenteCaixaPdvObterPagamentoSiTefPendenteComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPagamentoSiTefPendenteComunicador';
import FrenteCaixaPdvCancelarPagamentoSiTefComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvCancelarPagamentoSiTefComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import { UseSiTef } from '../AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import AnaliseCusto from '../../../../../Suprimentos/Custo/AnaliseCusto/Hook';

type IProps = IMain<any>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { permissoes } = UsePermissoes();
  const { ComercialFrenteCaixaPdv: permissao } = permissoes;
  const liberacoes = UseLiberacoes();
  const parametros = UseParametros();

  const { abrirJanela } = UseConfirmacao();
  const {
    loading,
    handleCarregarDados,
    formRef,
    refresh,
    setLoading,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
    terminouCarregarDados,
  } = UseForm();
  const {
    listaItem,
    setListaItem,
    itemSelecionado,
    setItemSelecionado,
    idNotaFiscalSaida,
    idPreVenda,
    listaIdItemConsignado,
  } = UseFrenteCaixaPdv();
  const { calcularRateiosItens, calcularTotais, liberar } =
    UseFrenteCaixaPdvCalculo();

  const { abrirSelecaoItemEstoqueLocalEstoque } =
    UseSelecaoItemEstoqueLocalEstoque();
  const { abrirSelecaoItemEstoqueLote } = UseSelecaoItemEstoqueLote();
  const { continuarPagamentoSiTef } = UseSiTef();

  const [showItemDetalhe, setShowItemDetalhe] = useState(false);
  const [showDevolucaoItemDetalhe, setShowDevolucaoItemDetalhe] =
    useState(false);
  const [showConsultaEstoque, setShowConsultaEstoque] = useState(false);
  const [showAnaliseCusto, setShowAnaliseCusto] = useState(false);

  const numero = formRef.current?.getFieldValue('numero');
  const serieCodigo = formRef.current?.getFieldValue('serieCodigo');
  const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');
  const situacao = formRef.current?.getFieldValue('situacao');
  const idCliente = formRef.current?.getFieldValue('idCliente');

  const terminalCaixa = formRef.current?.getFieldValue('terminalCaixa');
  const usuarioAbertura = formRef.current?.getFieldValue('usuarioAbertura');

  const bloquearCampos = useMemo(() => {
    if (idNotaFiscalSaida) return true;

    return false;
  }, [idNotaFiscalSaida]);

  const valorDescontoGeral =
    formRef.current?.getFieldValue('valorDescontoGeral');
  const valorAcrescimoGeral = formRef.current?.getFieldValue(
    'valorAcrescimoGeral'
  );

  const finalizarVendaRef = useRef<IAcoesFinalizarVendaRef>(null);
  const inputRefProduto =
    useRef<IInputAutoCompleteProdutoFrenteCaixaPdvRef>(null);
  const inputRefQuantidade = useRef<IInputDecimalRef>(null);

  const handleSetarFocus = useCallback(async () => {
    if (inputRefProduto.current) {
      inputRefProduto.current.autoCompleteRef.current?.focus();
    }
  }, []);

  const handleSetarFocusQuantidade = useCallback(async () => {
    if (inputRefQuantidade.current) {
      inputRefQuantidade.current?.focus();
    }
  }, []);

  useEffect(() => {
    handleSetarFocus();
  }, [handleSetarFocus]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  const handleVerificarPagamentosSitTefPendentes =
    useCallback(async (): Promise<void> => {
      try {
        setLoading(true);

        const terminal = formRef.current?.getFieldValue('terminalCaixa');
        const usuario = formRef.current?.getFieldValue('usuarioAbertura');

        if (terminal && usuario) {
          const response =
            await FrenteCaixaPdvObterPagamentoSiTefPendenteComunicador.index();

          if (response && response.length > 0) {
            // const pagamentoCancelado = false;

            for (let i = 0; i < response.length; i++) {
              const pagamentoSiTef = response[i];

              const responseContinuarSiTef = await continuarPagamentoSiTef({
                idTerminalCaixa: terminal.id,
                valorTotal: pagamentoSiTef.valor,
                numeroVenda: pagamentoSiTef.idPagamentoTef,
                dataHoraEmissao: pagamentoSiTef.dataHoraEmissao,
                operador: usuario.nome,
                cancelar: true,
              });

              await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
                params: { idPagamentoTef: pagamentoSiTef.idPagamentoTef },
              });

              ToastInfo(
                'Os Pagamentos Tef que estava "Aguardando Pagamento" foram cancelados!'
              );

              // if (responseContinuarSiTef) {
              // await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
              //   params: { idPagamentoTef: pagamentoSiTef.idPagamentoTef },
              // });

              //   pagamentoCancelado = true;
              // } else {
              //   await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
              //     params: { idPagamentoTef: pagamentoSiTef.idPagamentoTef },
              //   });
              // }
            }

            // if (pagamentoCancelado) {
            //   ToastInfo(
            //     'Os Pagamentos Tef que estava "Aguardando Pagamento" foram cancelados!'
            //   );
            // }
          }
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [continuarPagamentoSiTef, formRef, setLoading]);

  useEffect(() => {
    handleVerificarPagamentosSitTefPendentes();
  }, [handleVerificarPagamentosSitTefPendentes, terminouCarregarDados]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  // #region Item

  const handleSetarItemSelecionado = useCallback(
    async (item?: IFrenteCaixaPdvItemLista) => {
      if (item) {
        setItemSelecionado(item);

        formRef.current?.setSemExecutarEvento({
          quantidade: item.quantidade,
          valorUnitario: item.valorUnitario,
          subTotal: item.subTotal,
          regraEscolhaTabelaPreco: item.regraEscolhaTabelaPreco
            ? item.regraEscolhaTabelaPreco
            : null,
        });
      } else {
        setItemSelecionado(null);
        formRef.current?.setSemExecutarEvento({
          quantidade: 0,
          valorUnitario: 0,
          subTotal: 0,
          regraEscolhaTabelaPreco: null,
        });
      }
    },
    [formRef, setItemSelecionado]
  );

  const handleAtualizarValorItemSelecionado = useCallback(async () => {
    if (itemSelecionado) {
      const subTotal =
        itemSelecionado.quantidade * itemSelecionado.valorUnitario;
      const valorDesconto =
        subTotal * (itemSelecionado.percentualDesconto / 100);
      const valorAcrescimo =
        subTotal * (itemSelecionado.percentualAcrescimo / 100);
      const valorTotalItem = subTotal - valorDesconto + valorAcrescimo;

      itemSelecionado.subTotal = subTotal;
      itemSelecionado.valorDesconto = valorDesconto;
      itemSelecionado.valorAcrescimo = valorAcrescimo;
      itemSelecionado.valorTotalItem = valorTotalItem;

      formRef.current?.setFieldValue('subTotal', subTotal);

      const index = listaItem.findIndex(
        (e) => e.sequencia === itemSelecionado.sequencia
      );

      if (index >= 0) {
        listaItem[index] = itemSelecionado;
      }

      await calcularRateiosItens(listaItem);
      await calcularTotais(listaItem);
    }
  }, [
    calcularRateiosItens,
    calcularTotais,
    formRef,
    itemSelecionado,
    listaItem,
  ]);

  const handleAdicionarItem = useCallback(
    async (
      texto: string,
      produtoAtual: IProdutoFrenteCaixaPdv
    ): Promise<boolean> => {
      try {
        setLoading(true);

        const cliente = formRef.current?.getFieldValueNomeObjeto('cliente');

        let itemEstoqueLocalEstoqueSelecionado:
          | ISelecaoItemEstoqueLocalEstoque
          | undefined;
        let itemEstoqueLoteSelecionado: ISelecaoItemEstoqueLote | undefined;

        if (produtoAtual.produtoEstoque.controlarEstoque) {
          const listaLocalEstoque =
            await LocalEstoqueObterPorEstoqueCombinacaComunicador.index({
              idPessoa: cliente ? cliente.id : '',
              idProduto: produtoAtual.id,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              dataHoraEmissao: new Date(),
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
            });

          for (let i = 0; i < listaLocalEstoque.length; i++) {
            const localEstoque = listaLocalEstoque[i];

            const quantidadeUtilizada = listaItem
              .filter(
                (item) =>
                  !item.id &&
                  item.localEstoque &&
                  item.localEstoque.id === localEstoque.id
              )
              .reduce((acumulador: number, item: IFrenteCaixaPdvItemLista) => {
                acumulador += Number(item.quantidade);
                return acumulador;
              }, 0);

            localEstoque.saldo =
              Number(localEstoque.saldo) - quantidadeUtilizada;
          }

          if (listaLocalEstoque.length === 1) {
            itemEstoqueLocalEstoqueSelecionado = listaLocalEstoque[0];
          } else if (listaLocalEstoque.length > 1) {
            const itemEstoqueLocalEstoque =
              await abrirSelecaoItemEstoqueLocalEstoque(listaLocalEstoque);

            if (
              !itemEstoqueLocalEstoque ||
              !itemEstoqueLocalEstoque.localEstoque
            ) {
              setLoading(false);
              return false;
            }

            itemEstoqueLocalEstoqueSelecionado =
              itemEstoqueLocalEstoque.localEstoque;
          }
        }

        if (
          produtoAtual.produtoEstoque.controlarLote &&
          itemEstoqueLocalEstoqueSelecionado
        ) {
          const listaLote = await LoteObterPorEstoqueCombinacaComunicador.index(
            {
              idPessoa: cliente ? cliente.id : '',
              idProduto: produtoAtual.id,
              idLocalEstoque: itemEstoqueLocalEstoqueSelecionado.id,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              dataHoraEmissao: new Date(),
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
            }
          );

          for (let i = 0; i < listaLote.length; i++) {
            const lote = listaLote[i];

            const quantidadeUtilizada = listaItem
              .filter(
                (item) =>
                  !item.id &&
                  item.lote &&
                  item.lote.id === lote.id &&
                  item.localEstoque &&
                  item.localEstoque.id ===
                    itemEstoqueLocalEstoqueSelecionado?.id
              )
              .reduce((acumulador: number, item: IFrenteCaixaPdvItemLista) => {
                acumulador += Number(item.quantidade);
                return acumulador;
              }, 0);

            lote.saldo = Number(lote.saldo) - quantidadeUtilizada;
          }

          if (listaLote.length === 1) {
            itemEstoqueLoteSelecionado = listaLote[0];
          } else if (listaLote.length > 1) {
            const itemEstoqueLote =
              await abrirSelecaoItemEstoqueLote(listaLote);

            if (!itemEstoqueLote || !itemEstoqueLote.lote) {
              setLoading(false);
              return false;
            }

            itemEstoqueLoteSelecionado = itemEstoqueLote.lote;
          }
        }

        let valorUnitarioTabelaPreco: number | null = null;

        // #region Obter Tabela Preco

        const idPessoa = formRef.current?.getFieldValue('idCliente');

        const regraEscolhaTabelaPreco =
          await FrenteCaixaPdvObterRegraEscolhaTabelaPrecoComunicador.show({
            params: {
              idProduto: produtoAtual.id,
              idPessoa,
            },
          });

        if (regraEscolhaTabelaPreco) {
          valorUnitarioTabelaPreco = Number(
            regraEscolhaTabelaPreco.valorUnitario
          );
        }

        // #endregion Obter Tabela Preco

        const textoValor = texto.split('⬅')[0];

        const informacoes = DesestruturarPesquisa({
          texto: textoValor,
          removerZerosEsquerda: parametros.RemoverZerosAEsquerda
            ? parametros.RemoverZerosAEsquerda
            : false,
          valorUnitarioProduto: valorUnitarioTabelaPreco,
        });

        const novoItem: IFrenteCaixaPdvItemLista = {
          sequencia: listaItem.length + 1,

          produto: produtoAtual,
          quantidade: informacoes.quantidade,
          valorUnitario: informacoes.valorUnitario,
          subTotal: informacoes.valorTotal,
          percentualDesconto: 0,
          valorDesconto: 0,
          percentualAcrescimo: 0,
          valorAcrescimo: 0,
          valorTotalItem: informacoes.valorTotal,

          regraEscolhaTabelaPreco: regraEscolhaTabelaPreco
            ? regraEscolhaTabelaPreco.regraEscolhaTabelaPreco
            : undefined,
          valorUnitarioTabelaPreco,
          utilizarValorTabelaPreco:
            informacoes.valorUnitario === valorUnitarioTabelaPreco,

          localEstoque: itemEstoqueLocalEstoqueSelecionado,
          lote: itemEstoqueLoteSelecionado,

          informacoesAdicionaisProduto: '',
        };

        if (!parametros.PermiteAdicionarProdutosSemSaldo) {
          const partesDetalhesEstoque =
            produtoAtual.detalhesEstoque?.split('\n');

          const somaEstoque = partesDetalhesEstoque
            ? partesDetalhesEstoque?.reduce(
                (acumulador: number, detalheEstoque: any) => {
                  const partes = detalheEstoque.split(', Qtde: ');
                  acumulador += Number(partes[1]);
                  return acumulador;
                },
                0
              )
            : 0;

          if (novoItem.quantidade > somaEstoque) {
            ToastErro('Nao permite adicionar produto sem saldo!');
            setLoading(false);
            return false;
          }
        }

        const novaListaItem = [...listaItem, novoItem];
        await calcularRateiosItens(novaListaItem);

        setListaItem(novaListaItem);
        await handleSetarItemSelecionado(novoItem);
        await calcularTotais(novaListaItem);

        refresh();
        setLoading(false);
        return true;
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return false;
      }
    },
    [
      abrirSelecaoItemEstoqueLocalEstoque,
      abrirSelecaoItemEstoqueLote,
      calcularRateiosItens,
      calcularTotais,
      formRef,
      handleSetarItemSelecionado,
      listaItem,
      parametros.PermiteAdicionarProdutosSemSaldo,
      parametros.RemoverZerosAEsquerda,
      refresh,
      setListaItem,
      setLoading,
    ]
  );

  const handleEditarItem = useCallback(
    async (index: number) => {
      await handleSetarItemSelecionado(listaItem[index]);
      setShowItemDetalhe(true);
    },
    [handleSetarItemSelecionado, listaItem]
  );

  const handleEditarItemSalvarFormModal = useCallback(
    async (item: IFrenteCaixaPdvItemLista) => {
      try {
        setLoading(true);

        const index = listaItem.findIndex(
          (e) => e.sequencia === item.sequencia
        );

        if (index >= 0) {
          listaItem[index] = item;
        }

        await calcularRateiosItens(listaItem);
        await handleSetarItemSelecionado(item);
        await calcularTotais(listaItem);
        setShowItemDetalhe(false);

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularRateiosItens,
      calcularTotais,
      handleSetarItemSelecionado,
      listaItem,
      refresh,
      setLoading,
    ]
  );

  const handleEditarItemFecharFormModal = useCallback(async () => {
    setShowItemDetalhe(false);
  }, []);

  const handleRemoverItem = useCallback(
    async (index: number) => {
      try {
        setLoading(true);

        if (
          itemSelecionado &&
          itemSelecionado.sequencia === listaItem[index].sequencia
        ) {
          await handleSetarItemSelecionado(undefined);
        }

        listaItem.splice(index, 1);
        const novaListaItem = [...listaItem];

        let sequencia = novaListaItem.length;
        for (let i = 0; i < novaListaItem.length; i++) {
          novaListaItem[i].sequencia = sequencia;
          sequencia -= 1;
        }

        await calcularRateiosItens(novaListaItem);

        setListaItem(novaListaItem);
        await calcularTotais(novaListaItem);

        handleSetarFocus();

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularRateiosItens,
      calcularTotais,
      handleSetarFocus,
      handleSetarItemSelecionado,
      itemSelecionado,
      listaItem,
      refresh,
      setListaItem,
      setLoading,
    ]
  );

  const handleDevolucaoItem = useCallback(
    async (index: number) => {
      if (!liberacoes.permiteDevolucaoConsignado) {
        ToastErro(
          'Usuário sem permissão para realizar devolução de Consignado!'
        );

        setShowDevolucaoItemDetalhe(false);
      } else {
        await handleSetarItemSelecionado(listaItem[index]);
        setShowDevolucaoItemDetalhe(true);
      }
    },
    [
      handleSetarItemSelecionado,
      liberacoes.permiteDevolucaoConsignado,
      listaItem,
    ]
  );

  const handleDevolucaoItemSalvarFormModal = useCallback(
    async (sequencia: number, quantidade: number) => {
      try {
        setLoading(true);

        const item = listaItem.find((e) => e.sequencia === sequencia);
        if (item) {
          const novaQuantidade = Number(item.quantidade) - quantidade;
          if (novaQuantidade > 0) {
            item.quantidade = novaQuantidade;
            item.subTotal = novaQuantidade * item.valorUnitario;
            item.valorTotalItem =
              item.subTotal - item.valorDesconto + item.valorAcrescimo;
          } else {
            const index = listaItem.findIndex((e) => e.sequencia === sequencia);
            await handleRemoverItem(index);
          }
        }

        setShowDevolucaoItemDetalhe(false);
        refresh();

        await calcularRateiosItens(listaItem);
        await handleSetarItemSelecionado(item);
        await calcularTotais(listaItem);
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularRateiosItens,
      calcularTotais,
      handleRemoverItem,
      handleSetarItemSelecionado,
      listaItem,
      refresh,
      setLoading,
    ]
  );

  const handleDevolucaoItemFecharFormModal = useCallback(async () => {
    setShowDevolucaoItemDetalhe(false);
  }, []);

  const handleAtualizarValoresRegraEscolhaTabelaPreco = useCallback(
    async (itemAtual: IRegraEscolhaTabelaPreco | null) => {
      if (!itemSelecionado) return;

      try {
        setLoading(true);

        if (itemAtual) {
          const regraEscolhaTabelaPreco =
            await FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador.show({
              params: {
                idRegraEscolhaTabelaPreco: itemAtual.id,
                idProduto: itemSelecionado.produto.id,
              },
            });

          if (regraEscolhaTabelaPreco) {
            const valorUnitarioTabelaPreco = Number(
              regraEscolhaTabelaPreco.valorUnitario
            );

            const subTotal =
              Number(itemSelecionado.quantidade) *
              Number(valorUnitarioTabelaPreco);
            const valorDesconto =
              subTotal * (Number(itemSelecionado.percentualDesconto) / 100);
            const valorAcrescimo =
              subTotal * (Number(itemSelecionado.percentualAcrescimo) / 100);
            const valorTotalItem = subTotal - valorDesconto + valorAcrescimo;

            itemSelecionado.valorUnitario = valorUnitarioTabelaPreco;
            itemSelecionado.subTotal = subTotal;
            itemSelecionado.valorDesconto = valorDesconto;
            itemSelecionado.valorAcrescimo = valorAcrescimo;
            itemSelecionado.valorTotalItem = valorTotalItem;

            itemSelecionado.valorUnitarioTabelaPreco = valorUnitarioTabelaPreco;
            itemSelecionado.utilizarValorTabelaPreco = true;
          }
        } else {
          itemSelecionado.valorUnitarioTabelaPreco = null;
          itemSelecionado.utilizarValorTabelaPreco = false;
        }

        const itemSelecionadoNovo = listaItem.find(
          (e) => e.sequencia === itemSelecionado?.sequencia
        );
        if (itemSelecionadoNovo) {
          setItemSelecionado(itemSelecionadoNovo);
        } else {
          setItemSelecionado(null);
        }

        formRef.current?.setSemExecutarEvento({
          quantidade: itemSelecionadoNovo?.quantidade,
          valorUnitario: itemSelecionadoNovo?.valorUnitario,
          subTotal: itemSelecionadoNovo?.subTotal,
        });

        await calcularTotais(listaItem);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularTotais,
      formRef,
      itemSelecionado,
      listaItem,
      setItemSelecionado,
      setLoading,
    ]
  );

  const handleConsultarEstoques = useCallback(async () => {
    setShowConsultaEstoque(true);
  }, []);

  const handleConsultarEstoqueFecharFormModal = useCallback(async () => {
    setShowConsultaEstoque(false);
  }, []);

  // #region Analise Custo

  const handleAnaliseCusto = useCallback(async () => {
    setShowAnaliseCusto(true);
  }, []);

  const handleAnaliseCustoFecharFormModal = useCallback(async () => {
    setShowAnaliseCusto(false);
  }, []);

  const handleAnaliseCustoSalvarFormModal = useCallback(
    async (valorUnitarioVenda: number) => {
      formRef.current.setSemExecutarEvento({
        valorUnitario: valorUnitarioVenda,
      });

      if (itemSelecionado) {
        itemSelecionado.valorUnitario = valorUnitarioVenda;

        if (itemSelecionado.valorUnitarioTabelaPreco !== valorUnitarioVenda) {
          itemSelecionado.utilizarValorTabelaPreco = false;
        }

        await handleAtualizarValorItemSelecionado();

        refresh();
      }

      setShowAnaliseCusto(false);
    },
    [formRef, handleAtualizarValorItemSelecionado, itemSelecionado, refresh]
  );

  const handleListaItemAnalisCustos =
    useCallback((): IAnaliseCustoVendaLista[] => {
      const cliente = formRef.current.getFieldValueNomeObjeto('cliente');

      const listaItemVenda = listaItem.map((item) => {
        const itemVenda: IAnaliseCustoVendaLista = {
          isFrenteCaixa: true,
          recalcularImpostos: true,

          id: item.id,
          sequencia: item.sequencia,
          dataHoraMovimento: new Date(),

          produto: {
            id: item.produto.id,
            codigo: item.produto.codigo,
            descricao: item.produto.descricao,
          },

          pessoa: cliente
            ? {
                id: cliente.id,
                codigo: cliente.codigo,
                nomeRazaoSocial: cliente.nomeRazaoSocial,
              }
            : undefined,

          destinado: DestinadoEnum.usoeConsumo,
          indicadorPresenca: IndicadorPresencaPadraoEnum.operacaoPresencial,

          quantidade: item.quantidade,
          valorTotalBruto: 0,
          valorTotalImpostosOutros: 0,
          valorUnitariaCusto: 0,
          valorTotalCusto: 0,
          valorUnitarioVenda: item.valorUnitario,
          valorTotalProduto:
            Number(item.quantidade) * Number(item.valorUnitario),

          valorFrete: 0,
          valorSeguro: 0,
          valorOutrasDespesas: item.valorAcrescimo,
          valorDesconto: item.valorDesconto,

          valorIcms: 0,
          valorIcmsStEValorFcpIcmsSt: 0,
          valorIpi: 0,
          valorPis: 0,
          valorCofins: 0,
          valorIrpj: 0,
          valorCsll: 0,
          valorIcmsSn: 0,
          valorOutros: 0,

          margemLiquidaValor: 0,
          margemLiquidaPercentual: 0,
        };

        return itemVenda;
      });

      return listaItemVenda;
    }, [formRef, listaItem]);

  // #endregion Analise Custo

  // #endregion Item

  // #region Obter

  const handleObterIndexItemSelecionado = useCallback(():
    | number
    | undefined => {
    if (itemSelecionado) {
      const index = listaItem.findIndex(
        (e) => e.sequencia === itemSelecionado.sequencia
      );

      if (index >= 0) {
        return index;
      }
    }

    return undefined;
  }, [itemSelecionado, listaItem]);

  // #endregion Obter

  const handleAtualizarTabelaPrecoProdutos = useCallback(async () => {
    if (listaItem.length === 0) return;

    try {
      setLoading(true);

      const respostaDeveMostrarMensagem =
        await FrenteCaixaPdvObterRegraEscolhaTabelaPrecoPorClienteComunicador.show(
          {
            params: {
              idPessoa: formRef.current?.getFieldValue('idCliente'),
            },
          }
        );

      if (respostaDeveMostrarMensagem.possuiRegraEscolhaTabelaPreco === false) {
        setLoading(false);
        return;
      }

      const mensagem = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja atualizar a Tabela de Preço de todos os produtos?
          </span>
        ),
        confimar: 'Sim',
        cancelar: 'Não',
        padraoSelecionadoSim: false,
      });

      if (!mensagem) {
        setLoading(false);
        return;
      }

      const idPessoa = formRef.current?.getFieldValue('idCliente');

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];

        const regraEscolhaTabelaPreco =
          await FrenteCaixaPdvObterRegraEscolhaTabelaPrecoComunicador.show({
            params: {
              idProduto: item.produto.id,
              idPessoa,
            },
          });

        if (regraEscolhaTabelaPreco) {
          const valorUnitarioTabelaPreco = Number(
            regraEscolhaTabelaPreco.valorUnitario
          );

          const subTotal =
            Number(item.quantidade) * Number(valorUnitarioTabelaPreco);
          const valorDesconto =
            subTotal * (Number(item.percentualDesconto) / 100);
          const valorAcrescimo =
            subTotal * (Number(item.percentualAcrescimo) / 100);
          const valorTotalItem = subTotal - valorDesconto + valorAcrescimo;

          item.valorUnitario = valorUnitarioTabelaPreco;
          item.subTotal = subTotal;
          item.valorDesconto = valorDesconto;
          item.valorAcrescimo = valorAcrescimo;
          item.valorTotalItem = valorTotalItem;

          item.regraEscolhaTabelaPreco =
            regraEscolhaTabelaPreco.regraEscolhaTabelaPreco;
          item.valorUnitarioTabelaPreco = valorUnitarioTabelaPreco;
          item.utilizarValorTabelaPreco = true;
        }

        const itemSelecionadoNovo = listaItem.find(
          (e) => e.sequencia === itemSelecionado?.sequencia
        );
        if (itemSelecionadoNovo) {
          formRef.current?.setSemExecutarEvento({
            quantidade: itemSelecionadoNovo?.quantidade,
            valorUnitario: itemSelecionadoNovo?.valorUnitario,
            subTotal: itemSelecionadoNovo?.subTotal,
            regraEscolhaTabelaPreco:
              itemSelecionadoNovo?.regraEscolhaTabelaPreco,
          });
        }
      }

      await calcularTotais(listaItem);

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    calcularTotais,
    formRef,
    itemSelecionado?.sequencia,
    listaItem,
    refresh,
    setLoading,
  ]);

  const handleLiberarCaixa = useCallback(async () => {
    try {
      setLoading(true);

      const idDetalheRegistro = getIdDetalheRegistro();

      if (!idDetalheRegistro && listaItem.length > 0) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja liberar o Frente de Caixa (PDV) sem Finalizar? Todos os
              itens serão removidos!
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Não',
          padraoSelecionadoSim: false,
        });

        if (!resposta) {
          setLoading(false);
          handleSetarFocus();
          return;
        }
      }

      await liberar();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleSetarFocus,
    liberar,
    listaItem.length,
    setLoading,
  ]);

  const handleCarregarCliente = useCallback(
    async (cnpjCpf: string, mudou: boolean) => {
      try {
        setLoading(true);

        const response = await PessoaObterPorCnpjCpfComunicador.index({
          params: {
            cnpjCpf,
            listaIdRemover: [],
          },
        });

        if (response) {
          const listaCliente = response.filter((e: any) => e.cliente);

          if (listaCliente && listaCliente.length === 1) {
            formRef.current?.setFieldValue('cliente', listaCliente[0]);
          }
        }

        if (mudou) {
          await handleAtualizarTabelaPrecoProdutos();
        }

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, handleAtualizarTabelaPrecoProdutos, refresh, setLoading]
  );

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'F6':
            e.preventDefault();
            e.stopPropagation();

            finalizarVendaRef.current?.handleFinalizarVenda();
            break;

          case 'F7':
            e.preventDefault();
            e.stopPropagation();

            handleLiberarCaixa();
            break;
        }
      }}
    >
      <FormCia ref={formRef}>
        <LoadingDiv isLoading={loading} />

        <InputHiddenHtml name="financeiroConferido" />

        <InputHiddenHtml name="numero" />
        <InputHiddenHtml name="serieCodigo" />
        <InputHiddenHtml name="dataHoraEmissao" />
        <InputHiddenHtml name="situacao" />
        <InputHiddenHtml name="chaveAcesso" />

        <InputHiddenHtml name="descontoFormaCalculoPorRateio" />
        <InputHiddenHtml name="outrasDespesasFormaCalculoPorRateio" />
        <InputHiddenHtml name="percentualDescontoGeral" />
        <InputHiddenHtml name="percentualAcrescimoGeral" />

        <InputHiddenHtml name="listaRepresentante" />
        <InputHiddenHtml name="listaPagamentoSalvo" />

        <InputHiddenHtml name="idAberturaFechamentoCaixa" />
        <InputHiddenHtml name="terminalCaixa" />
        <InputHiddenHtml name="usuarioAbertura" />

        <Row>
          <Col lg={6} md={12}>
            <Divisor style={{ padding: 0, paddingBottom: 10 }}>
              <h4
                style={{
                  backgroundColor: 'orange',
                  fontStyle: 'italic',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Produto / Código / Código de Barras
              </h4>

              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col lg={12} md={12} sm={12}>
                  <InputAutoCompleteProdutoFrenteCaixaPdv
                    ref={inputRefProduto}
                    label=""
                    name="idProduto"
                    nomeObjeto="produto"
                    placeholder="Produto / Código / Código de Barras"
                    onFocus={async () => {
                      formRef.current?.setFieldValue('produto', null);
                      refresh();
                    }}
                    onChangeItemAtual={async (event, props) => {
                      if (event.itemAtual) {
                        const adicionado = await handleAdicionarItem(
                          String(event.input?.value),
                          event.itemAtual
                        );

                        if (!adicionado) return;

                        formRef.current?.setFieldValue('produto', null);
                        refresh();

                        if (props.key === 'Enter') {
                          await handleSetarFocus();
                        } else {
                          await handleSetarFocusQuantidade();
                        }
                      }

                      refresh();
                    }}
                    disabled={bloquearCampos}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor style={{ padding: 0, paddingBottom: 10 }}>
              <h4
                style={{
                  backgroundColor: 'orange',
                  fontStyle: 'italic',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Produto
              </h4>

              <Row
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  display: itemSelecionado ? '' : 'none',
                }}
              >
                <Col lg={1} md={3} sm={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <label>Séq.</label>
                    <label
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        marginLeft: 10,
                      }}
                    >
                      {itemSelecionado ? itemSelecionado.sequencia : ''}
                    </label>
                  </div>
                </Col>
                <Col lg={8} md={9} sm={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <label>Produto</label>
                    <label
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        marginLeft: 10,
                      }}
                    >
                      {itemSelecionado
                        ? `${itemSelecionado.produto.codigo} - ${itemSelecionado.produto.descricao}`
                        : ''}
                    </label>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <label>Unidade</label>
                    <label
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        marginLeft: 10,
                      }}
                    >
                      {itemSelecionado
                        ? `${itemSelecionado.produto.produtoEstoque.unidadeMedida.sigla}`
                        : ''}
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    ref={inputRefQuantidade}
                    label="Quantidade"
                    name="quantidade"
                    placeholder="Quantidade"
                    casasDecimais={4}
                    onBlur={async (event) => {
                      const quantidade = String(
                        event.currentTarget.value
                      ).ConverterParaNumber();

                      if (quantidade === 0) {
                        ToastInfo('Atenção, a quantidade do item está zerada!');
                      }
                    }}
                    onChange={async (event) => {
                      const quantidade = String(
                        event.currentTarget.value
                      ).ConverterParaNumber();

                      if (itemSelecionado) {
                        itemSelecionado.quantidade = quantidade;
                        await handleAtualizarValorItemSelecionado();

                        refresh();
                      }
                    }}
                    disabled={bloquearCampos}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Unitário"
                    name="valorUnitario"
                    placeholder="Valor Unitário"
                    casasDecimais={10}
                    onChange={async (event) => {
                      const valorUnitario = String(
                        event.currentTarget.value
                      ).ConverterParaNumber();

                      if (itemSelecionado) {
                        itemSelecionado.valorUnitario = valorUnitario;

                        if (
                          itemSelecionado.valorUnitarioTabelaPreco !==
                          valorUnitario
                        ) {
                          itemSelecionado.utilizarValorTabelaPreco = false;
                        }

                        await handleAtualizarValorItemSelecionado();

                        refresh();
                      }
                    }}
                    disabled={
                      bloquearCampos ||
                      !liberacoes.permiteAlterarValorUnitarioVendaTabelaPrecoInformada
                    }
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimal
                    label="Sub. Total"
                    name="subTotal"
                    placeholder="Sub. Total"
                    casasDecimais={2}
                    disabled
                  />
                </Col>
              </Row>

              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col lg={!itemSelecionado ? 12 : 8} md={12} sm={12}>
                  <InputAutoCompleteRegraEscolhaTabelaPreco
                    label="Tabela de Preço"
                    name="idRegraEscolhaTabelaPreco"
                    nomeObjeto="regraEscolhaTabelaPreco"
                    placeholder="Tabela de Preço"
                    onChangeItemAtual={async (event) => {
                      if (itemSelecionado) {
                        itemSelecionado.regraEscolhaTabelaPreco =
                          event.itemAtual ? event.itemAtual : undefined;
                      }

                      await handleAtualizarValoresRegraEscolhaTabelaPreco(
                        event.itemAtual
                      );

                      refresh();
                    }}
                    idPessoa={idCliente}
                    idProduto={itemSelecionado?.produto.id}
                    contribuinte={false}
                    disabled={bloquearCampos}
                  />
                </Col>

                <Col
                  lg={2}
                  md={6}
                  sm={6}
                  style={{
                    display: itemSelecionado ? 'flex' : 'none',
                    alignItems: 'end',
                    marginBottom: 5,
                    justifyContent: 'center',
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={v4()}>Consultar Estoque</Tooltip>}
                  >
                    <div>
                      <Button
                        style={{ height: 35 }}
                        onClick={handleConsultarEstoques}
                      >
                        <BsBoxSeam />
                      </Button>
                    </div>
                  </OverlayTrigger>
                </Col>
                <Col
                  lg={2}
                  md={6}
                  sm={6}
                  style={{
                    display: itemSelecionado ? 'flex' : 'none',
                    alignItems: 'end',
                    marginBottom: 5,
                    justifyContent: 'center',
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={v4()}>Analise de Custos</Tooltip>}
                  >
                    <div>
                      <Button
                        style={{ height: 35 }}
                        onClick={handleAnaliseCusto}
                      >
                        <FiTrendingUp />
                      </Button>
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Divisor>

            <Divisor style={{ padding: 0, paddingBottom: 10 }}>
              <h4
                style={{
                  backgroundColor: 'orange',
                  fontStyle: 'italic',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                CNPJ / CPF - Cliente e Tabela de Preço
              </h4>

              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col lg={4} md={6} sm={12}>
                  <InputCnpjCpf
                    label="CNPJ / CPF"
                    name="cnpjCpf"
                    placeholder="CNPJ / CPF"
                    permitirPesquisar={false}
                    onBlurValido={async (event) => {
                      if (event.valor) {
                        const cnpjCpf = event.valor.RemoverNaoNumericos();
                        await handleCarregarCliente(cnpjCpf, event.mudou);

                        if (
                          cnpjCpf &&
                          cnpjCpf !== '00000000000' &&
                          cnpjCpf !== '00000000000000'
                        ) {
                          formRef.current?.setFieldValue(
                            'cnpjCpfNoDocumentoFiscal',
                            true
                          );
                        } else {
                          formRef.current?.setFieldValue(
                            'cnpjCpfNoDocumentoFiscal',
                            false
                          );
                        }
                      } else {
                        formRef.current?.setFieldValue(
                          'cnpjCpfNoDocumentoFiscal',
                          false
                        );
                      }
                    }}
                    disabled={bloquearCampos}
                  />
                </Col>
                <Col lg={8} md={6} sm={12}>
                  <InputAutoCompleteCliente
                    label="Cliente"
                    name="idCliente"
                    nomeObjeto="cliente"
                    placeholder="Cliente"
                    onChangeItemAtual={async (event, props) => {
                      if (event.itemAtual) {
                        const cnpjCpf = event.itemAtual.cnpjCpfVirtual;

                        formRef.current?.setFieldValue('cnpjCpf', cnpjCpf);

                        if (
                          cnpjCpf &&
                          cnpjCpf !== '00000000000' &&
                          cnpjCpf !== '00000000000000'
                        ) {
                          formRef.current?.setFieldValue(
                            'cnpjCpfNoDocumentoFiscal',
                            true
                          );
                        } else {
                          formRef.current?.setFieldValue(
                            'cnpjCpfNoDocumentoFiscal',
                            false
                          );
                        }

                        if (
                          (!props.valorAnterior && event.itemAtual) ||
                          (props.valorAnterior &&
                            props.valorAnterior.id !== event.itemAtual.id)
                        ) {
                          await handleAtualizarTabelaPrecoProdutos();
                        }
                      }

                      refresh();
                    }}
                    disabled={bloquearCampos}
                  />
                </Col>
              </Row>

              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col lg={12} md={12} sm={12}>
                  <div className="checkbox-container">
                    <InputCheckbox
                      label="CNPJ/CPF no Documento Fiscal"
                      name="cnpjCpfNoDocumentoFiscal"
                      disabled={bloquearCampos}
                    />
                  </div>
                </Col>
              </Row>
            </Divisor>
          </Col>

          <Col lg={6} md={12}>
            <Divisor style={{ padding: 0, height: 480, marginBottom: 10 }}>
              <h4
                style={{
                  backgroundColor: 'orange',
                  fontStyle: 'italic',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                Itens
              </h4>

              <div style={{ height: '90%', overflow: 'auto' }}>
                <Tabela>
                  <Table bordered style={{ border: 'transparent' }}>
                    <thead>
                      <tr>
                        <th
                          className="lista-valor"
                          style={{
                            width: '5%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Séq.</span>
                        </th>
                        <th
                          className="lista-texto"
                          style={{
                            width: '35%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Produto</span>
                        </th>
                        <th
                          className="lista-valor"
                          style={{
                            width: '20%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Qtde.</span>
                        </th>
                        <th
                          className="lista-valor"
                          style={{
                            width: '20%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>V. Unit.</span>
                        </th>
                        <th
                          className="lista-valor"
                          style={{
                            width: '20%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>V. Item</span>
                        </th>

                        <th
                          className="lista-acoes"
                          style={{
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Ações</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaItem?.map((item, index) => {
                        return (
                          <>
                            <tr
                              style={{
                                width: '4%',
                                textAlign: 'center',
                                backgroundColor:
                                  handleObterIndexItemSelecionado() === index
                                    ? '#b2f3d3'
                                    : index % 2 === 0
                                      ? '#edecee'
                                      : '#f9f8fa',
                              }}
                              onClick={async (event) => {
                                if (event.detail === 1) {
                                  await handleSetarItemSelecionado(
                                    listaItem[index]
                                  );
                                } else if (event.detail === 2) {
                                  await handleEditarItem(index);
                                }

                                refresh();
                              }}
                            >
                              <td className="lista-valor">{item.sequencia}</td>
                              <td className="lista-texto">
                                {`${item.produto.codigo} - ${item.produto.descricao}`}
                              </td>
                              <td className="lista-valor">
                                {`${Number(item.quantidade).FormatarParaPtBr({
                                  minimoCasasDecimais: 3,
                                  maximoCasasDecimais: 3,
                                })} ${
                                  item.produto.produtoEstoque.unidadeMedida
                                    .sigla
                                }`}
                              </td>
                              <td className="lista-valor">
                                {`R$ ${Number(
                                  item.valorUnitario
                                ).FormatarParaPtBr({
                                  minimoCasasDecimais: 3,
                                  maximoCasasDecimais: 3,
                                })}`}
                              </td>
                              <td className="lista-valor">
                                {`R$ ${Number(
                                  item.valorTotalItem
                                ).FormatarParaPtBr()}`}
                              </td>

                              <td className="tdButton lista-acoes">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <BtnAcoesButton>
                                    <Dropdown.Item
                                      onClick={() => {
                                        handleEditarItem(index);
                                      }}
                                      disabled={loading}
                                    >
                                      <span
                                        style={{
                                          display: 'flex',
                                          fontSize: 16,
                                        }}
                                      >
                                        <AiOutlineEdit
                                          style={{ marginRight: 5 }}
                                        />
                                        Editar
                                      </span>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        handleRemoverItem(index);
                                      }}
                                      disabled={loading || bloquearCampos}
                                    >
                                      <span
                                        style={{
                                          display: 'flex',
                                          fontSize: 16,
                                        }}
                                      >
                                        <IoMdRemoveCircleOutline
                                          style={{ marginRight: 5 }}
                                        />
                                        Remover
                                      </span>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        handleDevolucaoItem(index);
                                      }}
                                      disabled={loading || bloquearCampos}
                                      hidden={
                                        !listaIdItemConsignado ||
                                        listaIdItemConsignado.length === 0
                                      }
                                    >
                                      <span
                                        style={{
                                          display: 'flex',
                                          fontSize: 16,
                                        }}
                                      >
                                        <MdAssignmentReturn
                                          style={{ marginRight: 5 }}
                                        />
                                        Devolver
                                      </span>
                                    </Dropdown.Item>
                                  </BtnAcoesButton>
                                </div>
                              </td>
                            </tr>

                            <tr
                              style={{
                                width: '4%',
                                textAlign: 'end',
                                backgroundColor:
                                  handleObterIndexItemSelecionado() === index
                                    ? '#b2f3d3'
                                    : index % 2 === 0
                                      ? '#edecee'
                                      : '#f9f8fa',
                                display:
                                  item.valorDesconto > 0 ||
                                  item.valorAcrescimo > 0
                                    ? ''
                                    : 'none',
                              }}
                              onClick={async (event) => {
                                if (event.detail === 1) {
                                  await handleSetarItemSelecionado(
                                    listaItem[index]
                                  );
                                } else if (event.detail === 2) {
                                  await handleEditarItem(index);
                                }

                                refresh();
                              }}
                            >
                              <td colSpan={3}>
                                {`Desconto: R$ ${Number(
                                  item.valorDesconto
                                ).FormatarParaPtBr()}`}
                              </td>
                              <td colSpan={2}>
                                {`Acréscimo: R$ ${Number(
                                  item.valorAcrescimo
                                ).FormatarParaPtBr()}`}
                              </td>
                              <td colSpan={1} />
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tabela>
              </div>
            </Divisor>

            <Divisor style={{ padding: 0 }}>
              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col
                  lg={valorDescontoGeral > 0 && valorAcrescimoGeral > 0 ? 3 : 6}
                  md={valorDescontoGeral > 0 && valorAcrescimoGeral > 0 ? 3 : 6}
                  sm={12}
                  style={{
                    display:
                      valorDescontoGeral > 0 || valorAcrescimoGeral > 0
                        ? 'flex'
                        : 'none',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <InputLabel
                    label="Sub. Total"
                    name="valorSubTotalGeral"
                    labelStyle={{ fontSize: '16px' }}
                    formatarValor={(value) => {
                      return (
                        <div style={{ fontSize: '16px', fontWeight: 'normal' }}>
                          {`R$ ${Number(value).FormatarParaPtBr()}`}
                        </div>
                      );
                    }}
                    valorStyle={{ justifyContent: 'flex-end' }}
                  />
                </Col>

                <Col
                  lg={3}
                  md={3}
                  sm={12}
                  style={{
                    display: valorDescontoGeral > 0 ? 'flex' : 'none',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <InputLabel
                    label="Val. Desconto"
                    name="valorDescontoGeral"
                    labelStyle={{ fontSize: '16px' }}
                    formatarValor={(value) => {
                      return (
                        <div style={{ fontSize: '16px', fontWeight: 'normal' }}>
                          {`R$ ${Number(value).FormatarParaPtBr()}`}
                        </div>
                      );
                    }}
                    valorStyle={{ justifyContent: 'flex-end' }}
                  />
                </Col>

                <Col
                  lg={3}
                  md={3}
                  sm={12}
                  style={{
                    display: valorAcrescimoGeral > 0 ? 'flex' : 'none',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <InputLabel
                    label="Val. Acréscimo"
                    name="valorAcrescimoGeral"
                    labelStyle={{ fontSize: '16px' }}
                    formatarValor={(value) => {
                      return (
                        <div style={{ fontSize: '16px', fontWeight: 'normal' }}>
                          {`R$ ${Number(value).FormatarParaPtBr()}`}
                        </div>
                      );
                    }}
                    valorStyle={{ justifyContent: 'flex-end' }}
                  />
                </Col>

                <Col
                  lg={
                    valorDescontoGeral > 0 || valorAcrescimoGeral > 0 ? 3 : 12
                  }
                  md={
                    valorDescontoGeral > 0 || valorAcrescimoGeral > 0 ? 3 : 12
                  }
                  sm={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <InputLabel
                    label="Valor Total"
                    name="valorTotalGeral"
                    labelStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                    formatarValor={(value) => {
                      return (
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {`R$ ${Number(value).FormatarParaPtBr()}`}
                        </div>
                      );
                    }}
                    valorStyle={{ justifyContent: 'flex-end' }}
                  />
                </Col>
              </Row>
            </Divisor>
          </Col>
        </Row>

        <Divisor
          style={{
            display:
              idNotaFiscalSaida || idPreVenda || listaIdItemConsignado
                ? ''
                : 'none',
          }}
        >
          <Row style={{ paddingBottom: 10 }}>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display:
                  idNotaFiscalSaida || idPreVenda || listaIdItemConsignado
                    ? ''
                    : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '16px' }}>
                  {`Número ${
                    idNotaFiscalSaida
                      ? 'NFC-e'
                      : idPreVenda
                        ? 'Pré Venda'
                        : listaIdItemConsignado
                          ? 'Consignado'
                          : ''
                  }:`}
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingLeft: 10,
                  }}
                >
                  {numero}
                </div>
              </div>
            </Col>
            <Col
              lg={2}
              md={6}
              sm={12}
              style={{
                display:
                  (idNotaFiscalSaida || idPreVenda) && serieCodigo
                    ? ''
                    : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '16px' }}>
                  {`Série ${
                    idNotaFiscalSaida ? 'NFC-e' : idPreVenda ? 'Pré Venda' : ''
                  }:`}
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingLeft: 10,
                  }}
                >
                  {serieCodigo}
                </div>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: idNotaFiscalSaida || idPreVenda ? '' : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '16px' }}>
                  {`Data Emissão ${
                    idNotaFiscalSaida ? 'NFC-e' : idPreVenda ? 'Pré Venda' : ''
                  }:`}
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingLeft: 10,
                  }}
                >
                  {FormatarDataHoraParaPtBr(dataHoraEmissao)}
                </div>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{
                display: idNotaFiscalSaida || idPreVenda ? '' : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '16px' }}>
                  {`Situação ${
                    idNotaFiscalSaida ? 'NFC-e' : idPreVenda ? 'Pré Venda' : ''
                  }:`}
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingLeft: 10,
                    color:
                      situacao && situacao.includes('Em Transmissão')
                        ? 'gray'
                        : (situacao && situacao.includes('Cancelada')) ||
                            (situacao && situacao.includes('Não Atendido'))
                          ? 'red'
                          : (situacao && situacao.includes('Autorizada')) ||
                              (situacao && situacao.includes('Atendido'))
                            ? 'green'
                            : situacao &&
                                situacao &&
                                situacao.includes('Denegada')
                              ? 'blue'
                              : (situacao && situacao.includes('Rejeitada')) ||
                                  (situacao &&
                                    situacao.includes('Parcialmente Atendido'))
                                ? 'goldenrod'
                                : 'black',
                  }}
                >
                  {situacao}
                </div>
              </div>
            </Col>
          </Row>
        </Divisor>

        {terminalCaixa && usuarioAbertura && (
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {`Terminal: ${terminalCaixa?.descricao} - Usuário: ${usuarioAbertura?.nome}`}
              </div>
            </Col>
          </Row>
        )}

        <ContainerButtons
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 50,
          }}
        >
          <AcoesFinalizarVenda permissao={permissao} ref={finalizarVendaRef} />

          <BtnDeletar
            onClick={handleLiberarCaixa}
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 190,
              minWidth: 50,
            }}
            disabled={
              loading ||
              permissao?.exclui ||
              (listaItem.length === 0 &&
                !idNotaFiscalSaida &&
                !idPreVenda &&
                !listaIdItemConsignado)
            }
          >
            <TextoLoading loading={loading}>
              <MdCancel />
              {telaGrande && (
                <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                  Liberar (F7)
                </span>
              )}
            </TextoLoading>
          </BtnDeletar>

          <AcoesFrenteCaixaPdv permissao={permissao} />

          <AcoesPesquisarVenda permissao={permissao} />
        </ContainerButtons>

        {showItemDetalhe && (
          <ItemDetalhe
            item={{ ...(itemSelecionado as IFrenteCaixaPdvItemLista) }}
            onSalvarFormModal={handleEditarItemSalvarFormModal}
            onFecharFormModal={handleEditarItemFecharFormModal}
            bloquearCampos={bloquearCampos}
          />
        )}

        {showDevolucaoItemDetalhe && (
          <DevolucaoItemDetalhe
            item={{ ...(itemSelecionado as IFrenteCaixaPdvItemLista) }}
            onSalvarFormModal={handleDevolucaoItemSalvarFormModal}
            onFecharFormModal={handleDevolucaoItemFecharFormModal}
          />
        )}

        {showConsultaEstoque && (
          <ConsultaEstoqueListaHook>
            <ConsultaEstoqueLista
              titulo="Consulta Estoque"
              onFecharFormModal={handleConsultarEstoqueFecharFormModal}
              loading={loading}
              setLoading={setLoading}
              idProdutoInicial={itemSelecionado?.produto.id}
            />
          </ConsultaEstoqueListaHook>
        )}

        {showAnaliseCusto && (
          <AnaliseCusto
            onFecharFormModal={handleAnaliseCustoFecharFormModal}
            onSalvarFormModal={handleAnaliseCustoSalvarFormModal}
            sequenciaItemSelecionado={itemSelecionado.sequencia}
            listaItem={handleListaItemAnalisCustos()}
          />
        )}
      </FormCia>
    </div>
  );
};

export default Main;
