"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FunruralCalculo;
function FunruralCalculo(dados) {
    const retorno = Object.assign({}, dados);
    const baseCalculoFunrural = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
        Number(dados.valorFreteItem) +
        Number(dados.valorSeguroItem) +
        Number(dados.valorOutrasDespesasItem) -
        Number(dados.valorDescontoItem);
    retorno.baseCalculo = Number(dados.baseCalculo);
    retorno.aliquota = Number(dados.aliquota);
    retorno.valor = Number(dados.valor);
    if (dados.calcular) {
        retorno.baseCalculo = baseCalculoFunrural.Arredondar();
        const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
        retorno.valor = valorPercentual.Arredondar();
        if (retorno.aliquota === 0 && retorno.valor === 0)
            retorno.baseCalculo = 0;
        if (retorno.baseCalculo === 0 && retorno.valor === 0)
            retorno.aliquota = 0;
    }
    else if (retorno.baseCalculo > 0 && retorno.aliquota > 0) {
        const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
        retorno.valor = valorPercentual.Arredondar();
    }
    return retorno;
}
