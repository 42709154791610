import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-producao/reportar-consumo-producao';

interface IOrdemProducaoReportarConsumoProducaoComunicadorShow {
  id: string;
}

class OrdemProducaoReportarConsumoProducaoComunicador {
  public async show(
    params: IOrdemProducaoReportarConsumoProducaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemProducaoReportarConsumoProducaoComunicador();
