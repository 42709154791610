import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/sisdevin/sisdevin-estoque-movimento/atualizar-conforme-regras';

interface ISisdevinEstoqueMovimentoAtualizarConformeRegraUpdate {
  id: string;
  params: any;
}

class SisdevinEstoqueMovimentoAtualizarConformeRegraComunicador {
  public async update(
    params: ISisdevinEstoqueMovimentoAtualizarConformeRegraUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new SisdevinEstoqueMovimentoAtualizarConformeRegraComunicador();
