import api from '../../../../Comum/Services/Api';

const rota = 'esqueceu-senha/validar-token';

interface IUsuarioEsqueceuSenhaValidarTokenComunicadorShow {
  id: string;
}

class UsuarioEsqueceuSenhaValidarTokenComunicador {
  public async show(
    params: IUsuarioEsqueceuSenhaValidarTokenComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new UsuarioEsqueceuSenhaValidarTokenComunicador();
