import { FormCia } from '@elogestor/unformcia';
import {
  FormatarEnum,
  IDemonstrativoResultadoValoresAlterar,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import Select from '../../../../../../Componentes/Select';
import { BtnContainer } from '../styles';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseTreeContext } from '../../Hooks/TreeContext';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import DemonstrativoResultadoComunicador from '../../../../../../Comunicador/Financeiro/CategoriaDRE/DemonstrativoResultado/Comunicador/DemonstrativoResultadoComunicador';
import InputAutoCompleteDemonstrativoResultado, {
  IOnChangeItemAtualAutoCompleteDemonstrativoResultadoEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteDemonstrativoResultado';

type IProps = IMain<IDemonstrativoResultadoValoresAlterar>;

const Main: React.FC<IProps> = ({
  dadosDuplicados,
  dadosRecuperados,
  idEditar,
  dadosObrigatorios,
  dadosPadrao,
  onClickSalvar,
}) => {
  const {
    formRef,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    setLoading,
  } = UseForm();
  const { permissoes } = UsePermissoes();
  const { FinanceiroCategoriaDreDemonstrativoResultado: permissao } =
    permissoes;
  const idDetalheRegistro = getIdDetalheRegistro();

  const inputRefDescricao = useRef<HTMLInputElement>(null);

  const { idShowItem, idItemPai, setIdItemPai } = UseTreeContext();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    setIdItemPai('');
    onClickSalvar && onClickSalvar({ ...data, id });
  }, [formRef, handleSubmit, onClickSalvar, setIdItemPai]);

  const handleAdicionarDemonstrativoResultadoPai = useCallback(
    async (
      event: IOnChangeItemAtualAutoCompleteDemonstrativoResultadoEvent
    ) => {
      try {
        setLoading(true);

        const response = await DemonstrativoResultadoComunicador.show({
          id: event.itemAtual?.id || idItemPai,
        });

        if (
          idItemPai &&
          formRef.current?.getFieldValueNomeObjeto('demonstrativoResultadoPai')
        ) {
          return;
        }

        if (idItemPai) {
          const dadosAtuais = formRef.current?.getDataDuplicar();

          await formRef.current?.setSemExecutarEvento({
            ...dadosAtuais,
            demonstrativoResultadoPai: { ...response },
          });
        }
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    },
    [formRef, idItemPai, setLoading]
  );

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    (async () => {
      if (idItemPai) {
        await formRef.current?.setDataInicial({
          ativo: true,
          tipoSinteticaAnalitica: TipoSinteticaAnaliticaEnum.analitica,
        });

        return;
      }

      try {
        setLoading(true);
        await handleCarregarDados({
          dadosDuplicados,
          dadosObrigatorios,
          dadosPadrao,
          dadosRecuperados,
        });
      } catch (error) {
        TratarErros(error);
      } finally {
        setLoading(false);
      }
    })();

    inputRefDescricao.current?.focus();
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idItemPai,
    idShowItem,
    setLoading,
  ]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHiddenHtml
        name="id"
        validarSeMudou={() => {
          return false;
        }}
      />
      <InputHiddenHtml name="codigo" />

      <Divisor>
        <Row>
          <Col lg={12}>
            <InputAutoCompleteDemonstrativoResultado
              label="Conta Pai"
              name="idDemonstrativoResultadoPai"
              nomeObjeto="demonstrativoResultadoPai"
              placeholder="Conta Pai"
              disabled={!!idItemPai}
              codigoCompletoAtual={formRef.current?.getFieldValue('codigo')}
              listaIdParaRemover={
                formRef.current?.getFieldValue('id')
                  ? [formRef.current?.getFieldValue('id')]
                  : undefined
              }
              onChangeItemAtual={async (event) => {
                await handleAdicionarDemonstrativoResultadoPai(event);
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ padding: '15px', marginBottom: '-0px' }}>
        <Row>
          <Col lg={12}>
            <Input
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              ref={inputRefDescricao}
              maxLength={60}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={9}>
            <Select
              label="Tipo de Conta"
              name="tipoSinteticaAnalitica"
              options={FormatarEnum({
                enumObj: TipoSinteticaAnaliticaEnum,
                todos: false,
              })}
            />
          </Col>

          <Col lg={3}>
            <InputSwitch name="ativo" label="Ativo" />
          </Col>
        </Row>

        <BtnContainer>
          <button
            type="button"
            onClick={handleClickSalvar}
            className="btn-padrao btn-verde"
            disabled={
              loading ||
              (idDetalheRegistro && !permissao?.altera) ||
              (!idDetalheRegistro && !permissao?.inclui)
            }
          >
            <TextoLoadingSalvar loading={loading} />
          </button>
        </BtnContainer>
      </Divisor>
    </FormCia>
  );
};

export default Main;
