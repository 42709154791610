import styled, { css } from 'styled-components';

interface IContainer {
  $telaPequena: boolean;
}

export const Container = styled.div<IContainer>`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 60px;
  padding: 0 15px;

  display: flex;
  align-items: center;

  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 20px;
    margin: 0;
    color: #434349;
    margin: 0px 15px;
  }

  ${({ $telaPequena }) =>
    $telaPequena &&
    css`
      flex-direction: column;

      & > *:last-child {
        padding-bottom: 5px;
      }

      h1 {
        padding-top: 5px;
        width: 100%;
        font-size: 14px;
      }
    `}
`;
