import { INotaFiscalEntradaItemValoresAlterar } from '@elogestor/util';
import { createContext, useContext } from 'react';

interface IListaItemContext {
  listaItem: INotaFiscalEntradaItemValoresAlterar[];
}

const listaItemContext = createContext<IListaItemContext>(
  {} as IListaItemContext
);
const ListaItemProvider = listaItemContext.Provider;

function UseListaItem(): IListaItemContext {
  const context = useContext(listaItemContext);

  return context;
}

export { ListaItemProvider, UseListaItem };
