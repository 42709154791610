/* eslint-disable jsx-a11y/label-has-associated-control */
import { MesesEnum, NomeMesParaNumeroMes } from '@elogestor/util';
// import { isBefore } from 'date-fns';
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import InputDateSemAno, { IInputDateSemAnoRef } from '../InputDateSemAno';

export interface IInputPeriodoSemAnoRef {
  inputInicioRef: React.MutableRefObject<IInputDateSemAnoRef | null>;
  inputFimRef: React.MutableRefObject<IInputDateSemAnoRef | null>;
}

interface IInputPeriodoSemAnoProps {
  labelDiaInicial: string;
  labelMesInicial: string;
  labelDiaFinal: string;
  labelMesFinal: string;
  nomeInicial: string;
  nomeFinal: string;
}

const InputPeriodoSemAno: React.ForwardRefRenderFunction<
  IInputPeriodoSemAnoRef,
  IInputPeriodoSemAnoProps
> = (
  {
    labelDiaInicial,
    labelMesInicial,
    labelDiaFinal,
    labelMesFinal,
    nomeInicial,
    nomeFinal,
  },
  ref
) => {
  const inputInicioRef = useRef<IInputDateSemAnoRef | null>(null);
  const inputFimRef = useRef<IInputDateSemAnoRef | null>(null);

  const [error, setError] = useState('');

  const validarDatas = useCallback(() => {
    setError('');

    const inputMesInicial = NomeMesParaNumeroMes(
      inputInicioRef.current?.inputMesRef.current?.value as MesesEnum
    );
    const inputDiaInicial = inputInicioRef.current?.inputDiaRef.current?.value;

    const inputMesFinal = NomeMesParaNumeroMes(
      inputFimRef.current?.inputMesRef.current?.value as MesesEnum
    );
    const inputDiaFinal = inputFimRef.current?.inputDiaRef.current?.value;

    if (inputMesFinal < inputMesInicial) {
      setError('Data final menor que data inicial');
    }

    if (inputDiaFinal && inputDiaInicial) {
      if (
        inputMesFinal === inputMesInicial &&
        inputDiaFinal < inputDiaInicial
      ) {
        setError('Data final menor que data inicial');
      }
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      inputFimRef,
      inputInicioRef,
    };
  });

  return (
    <>
      <Row>
        <Col xs={12} sm={12} lg={6}>
          <InputDateSemAno
            labelDia={labelDiaInicial}
            labelMes={labelMesInicial}
            nome={nomeInicial}
            onChange={validarDatas}
            error={error}
            ref={inputInicioRef}
          />
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <InputDateSemAno
            labelDia={labelDiaFinal}
            labelMes={labelMesFinal}
            nome={nomeFinal}
            onChange={validarDatas}
            error={error}
            ref={inputFimRef}
          />
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(InputPeriodoSemAno);
