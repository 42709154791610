import api from '../../../../../../Comum/Services/Api';

const rota = '/comercial/pessoas/pessoa/dados-bancarios';
const rotaNovo = '/comercial/pessoas/pessoa';

interface IPessoaDadosBancariosComunicadorShow {
  id: string;
}

interface IPessoaDadosBancariosComunicadorStore {
  idDetalheRegistro: string;
  params: any;
}

interface IPessoaDadosBancariosComunicadorUpdate {
  id: string;
  params: any;
}

interface IPessoaDadosBancariosComunicadorDelete {
  id: string;
}

class PessoaDadosBancariosComunicador {
  public async show(
    params: IPessoaDadosBancariosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IPessoaDadosBancariosComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idDetalheRegistro}/dados-bancarios`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IPessoaDadosBancariosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IPessoaDadosBancariosComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaDadosBancariosComunicador();
