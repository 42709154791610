"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoEspecieEnum;
(function (VeiculoEspecieEnum) {
    VeiculoEspecieEnum["passageiro"] = "1 - Passageiro";
    VeiculoEspecieEnum["carga"] = "2 - Carga";
    VeiculoEspecieEnum["misto"] = "3 - Misto";
    VeiculoEspecieEnum["corrida"] = "4 - Corrida";
    VeiculoEspecieEnum["tracao"] = "5 - Tra\u00E7\u00E3o";
    VeiculoEspecieEnum["especial"] = "6 -Especial";
})(VeiculoEspecieEnum || (VeiculoEspecieEnum = {}));
exports.default = VeiculoEspecieEnum;
