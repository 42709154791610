import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/ipi-codigo-enquadramento-legal-auto-complete';

interface IIpiCodigoEnquadramentoLegalAutoCompleteComunicadorIndex {
  params: any;
}

class IpiCodigoEnquadramentoLegalAutoCompleteComunicador {
  public async index(
    params: IIpiCodigoEnquadramentoLegalAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new IpiCodigoEnquadramentoLegalAutoCompleteComunicador();
