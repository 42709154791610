import { EstoqueIndicadorTipoEnum } from '@elogestor/util';
import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-item-estoque-combinacao';

interface IImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicadorIndex {
  params: {
    idPessoa?: string;
    idProduto?: string;
    dataHoraEmissao: Date;
    indicadorTipoEstoque: EstoqueIndicadorTipoEnum;
    quantidade: number;
    movimentarEstoque: boolean;
    controlarLote: boolean;
  };
}

class ImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicador {
  public async index(
    params: IImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterItemEstoqueCombinacaoComunicador();
