import { TipoUsuarioEnum } from '@elogestor/util';
import React, { useCallback, useRef } from 'react';
import { Container, Modal, Row, Table } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { BiCopy } from 'react-icons/bi/index.mjs';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteUsuario from '../../../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteUsuario';
import InputCheckbox from '../../../../../../../Componentes/Inputs/InputCheckbox';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { CheckboxContainer } from './styles';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import UsuarioCopiarDeComunicador from '../../../../../../../Comunicador/Configuracao/Usuario/CopiarDe/Comunicador/UsuarioCopiarDeComunicador';

interface ICopiarDe {
  aberto: boolean;
  fecharFormModal(): void;
}

const CopiarDe: React.FC<ICopiarDe> = ({ aberto, fecharFormModal }) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const formPrincipal = UseForm();
  const idUsuario = formPrincipal.getIdDetalheRegistro();
  const { tipoUsuario } = UsePermissoes();

  const handleValidarDados = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = await Yup.mixed()
          .nullable()
          .test({
            message: 'Selecione um usuário de origem!',
            test: () => {
              return data.idUsuario;
            },
          })
          .test({
            message: 'Marque pelo menos um dado a ser copiado!',
            test: () => {
              return !(
                !data.tipo &&
                !data.ativo &&
                !data.permissao &&
                !data.liberacoes
              );
            },
          });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData();

      if (!(await handleValidarDados(data))) {
        return { erro: true };
      }

      const response = await UsuarioCopiarDeComunicador.show({
        params: data,
      });

      if (response.listaPermissoes) {
        response.listaPermissoes.foreach((permissao: any) => {
          delete permissao.id;
        });
      }

      await formPrincipal.formRef.current?.setDataIgnorarUndefined({
        ...response,
      });

      ToastSucesso('Dados copiados com sucesso!');
      fecharFormModal();
      return { erro: false };
    } catch (error) {
      TratarErros(error);
      return { erro: true };
    }
  }, [fecharFormModal, formPrincipal.formRef, handleValidarDados]);

  return (
    <Modal show={aberto} onHide={fecharFormModal} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Copiar dados de outro Usuário</Modal.Title>
        <BtnCloseButton type="button" onClick={fecharFormModal}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>
      <Modal.Body>
        <FormCia ref={formRef}>
          <InputAutoCompleteUsuario
            label="Usuário (origem dos dados a serem copiados)"
            name="idUsuario"
            mostrarEloGestor={tipoUsuario === TipoUsuarioEnum.eloGestor}
            listaIdParaRemover={idUsuario ? [idUsuario] : []}
          />

          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th style={{ width: '10%' }} className="lista-texto">
                  <CheckboxContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 0,
                    }}
                  >
                    <InputCheckbox
                      name="copiarTodos"
                      onChange={async (event) => {
                        await formRef.current?.setDataIgnorarUndefined({
                          copiarTodos: event.target.checked,
                          tipo: event.target.checked,
                          ativo: event.target.checked,
                          permissoes: event.target.checked,
                        });
                      }}
                    />
                  </CheckboxContainer>
                </th>
                <th className="lista-texto">
                  <span>Dados a serem copiados</span>
                </th>
              </tr>
            </thead>

            <TBody>
              <tr>
                <td className="lista-texto">
                  <CheckboxContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 0,
                    }}
                  >
                    <InputCheckbox name="tipo" />
                  </CheckboxContainer>
                </td>
                <td className="lista-texto">
                  <div>Tipo</div>
                </td>
              </tr>
              <tr>
                <td className="lista-texto">
                  <CheckboxContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 0,
                    }}
                  >
                    <InputCheckbox name="ativo" />
                  </CheckboxContainer>
                </td>
                <td className="lista-texto">
                  <div>Ativo</div>
                </td>
              </tr>
              <tr>
                <td className="lista-texto">
                  <CheckboxContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 0,
                    }}
                  >
                    <InputCheckbox name="permissoes" />
                  </CheckboxContainer>
                </td>
                <td className="lista-texto">
                  <div>Permissões</div>
                </td>
              </tr>
              <tr>
                <td className="lista-texto">
                  <CheckboxContainer
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 0,
                    }}
                  >
                    <InputCheckbox name="liberacoes" />
                  </CheckboxContainer>
                </td>
                <td className="lista-texto">
                  <div>Liberações</div>
                </td>
              </tr>
            </TBody>
          </Table>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div>
          <Container>
            <Row>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={fecharFormModal}
              >
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </button>

              <button
                style={{
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                className="btn-padrao btn-azul-claro"
                onClick={handleSubmit}
              >
                <BiCopy />
                <span style={{ marginLeft: 10 }}>Copiar</span>
              </button>
            </Row>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CopiarDe;
