import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-pedido-venda/obter-pedido-venda';

interface INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicadorIndex {
  idPessoa: string;
  idNotaFiscalSaida?: string;
}

class NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoPedidoVendaObterPedidoVendaComunicador();
