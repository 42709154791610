import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/reprocessamento-producao';

interface IReprocessamentoProducaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IReprocessamentoProducaoComunicadorShow {
  id: string;
}

interface IReprocessamentoProducaoComunicadorStore {
  params: any;
}

interface IReprocessamentoProducaoComunicadorUpdate {
  id: string;
  params: any;
}

interface IReprocessamentoProducaoComunicadorDeletar {
  id: string;
}

class ReprocessamentoProducaoComunicador {
  public async index(
    params: IReprocessamentoProducaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IReprocessamentoProducaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IReprocessamentoProducaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IReprocessamentoProducaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IReprocessamentoProducaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ReprocessamentoProducaoComunicador();
