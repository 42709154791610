import api from '../../../../../../Comum/Services/Api';

const rota =
  'financeiro/relatorio/movimentacoes/movimentacoes-financeiras-portadores/categorias-por-liquidacao';

interface ICategoriasPorLiquidacaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CategoriasPorLiquidacaoRelatorioComunicador {
  public async index(
    params: ICategoriasPorLiquidacaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CategoriasPorLiquidacaoRelatorioComunicador();
