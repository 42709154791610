import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  DestinadoEnum,
  FormatarEnum,
  IsSimplesNacional,
  ObterTipoMercado,
  TipoEntradaSaidaOperacaoFiscalEnum,
} from '@elogestor/util';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteOperacaoFiscal from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputAutoCompleteTipoNota from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputCheckbox from '../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import Input from '../../../../../Componentes/Inputs/Input';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import { UseImpostoIcms } from '../Hooks/ImpostoIcmsHook';
import { UseRegimeTributario } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import Select from '../../../../../Componentes/Select';
import { UseImpostoIcmsOpInterestadual } from '../Hooks/ImpostoIcmsOpInterestadualHook';

interface IImpostoTabProps {
  handleCarregarConfiguracaoTributaria: () => Promise<void>;
}

const ImpostoTab: React.FC<IImpostoTabProps> = ({
  handleCarregarConfiguracaoTributaria,
}) => {
  const { formRef, getIdDetalheRegistro, refresh } = UseForm();
  const { camposBloqueadosIcms, camposEscondidosIcms, handleCalcularIcms } =
    UseImpostoIcms();
  const {
    camposBloqueadosIcmsOpInterestadual,
    camposEscondidosIcmsOpInterestadual,
    handleCalcularIcmsOpInterestadual,
  } = UseImpostoIcmsOpInterestadual();

  const { regimeTributario } = UseRegimeTributario();
  const [isEmitenteSimplesNacional, setIsEmitenteSimplesNacional] =
    useState(false);

  const ufEstadoOrigem = formRef.current?.getFieldValue(
    'conhecimentoTransporteRemetenteEndereco.cidade.estado.sigla'
  );
  const ufEstadoDestino = formRef.current?.getFieldValue(
    'conhecimentoTransporteDestinatarioEndereco.cidade.estado.sigla'
  );
  const utilizaIcmsOpInterestadual = formRef.current?.getFieldValue(
    'conhecimentoTransporteImpostoIcms.utilizaIcmsOpInterestadual'
  );

  const handleShowEmitenteSimplesNacional = (): boolean => {
    if (getIdDetalheRegistro()) {
      return !!formRef.current?.getFieldValue(
        'conhecimentoTransporteImpostoIcms.emitenteSimplesNacional'
      );
    }

    return isEmitenteSimplesNacional;
  };

  useEffect(() => {
    if (regimeTributario) {
      if (IsSimplesNacional(regimeTributario)) {
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.emitenteSimplesNacional',
          true
        );
        setIsEmitenteSimplesNacional(true);
      } else {
        formRef.current?.setFieldValue(
          'conhecimentoTransporteImpostoIcms.emitenteSimplesNacional',
          false
        );
        setIsEmitenteSimplesNacional(false);
      }
    }
  }, [
    formRef,
    getIdDetalheRegistro,
    isEmitenteSimplesNacional,
    regimeTributario,
  ]);

  return (
    <div>
      <Divisor>
        <InputHiddenHtml
          name="conhecimentoTransporteImpostoIcms.emitenteSimplesNacional"
          validarSeMudou={() => false}
        />

        <Row>
          <Col lg={6}>
            <InputAutoCompleteTipoNota
              label="Tipo de Nota / Conhecimento"
              name="conhecimentoTransporteImpostoIcms.idTipoNota"
              nomeObjeto="conhecimentoTransporteImpostoIcms.tipoNota"
              placeholder="Tipo de Nota / Conhecimento"
              permitirAdicionar={false}
              onChangeItemAtualAposCarregarSemClear={async ({ itemAtual }) => {
                if (!itemAtual) return;

                formRef.current?.clearField(
                  'conhecimentoTransporteImpostoIcms.tipoNotaMotivo',
                  { setValorInicial: false }
                );
                refresh();

                await handleCarregarConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={6}>
            <InputAutoCompleteTipoNotaMotivo
              label="Motivo"
              name="conhecimentoTransporteImpostoIcms.idTipoNotaMotivo"
              nomeObjeto="conhecimentoTransporteImpostoIcms.tipoNotaMotivo"
              placeholder="Motivo"
              idTipoNota={formRef.current?.getFieldValue(
                'conhecimentoTransporteImpostoIcms.idTipoNota'
              )}
              disabled={
                !formRef.current?.getFieldValue(
                  'conhecimentoTransporteImpostoIcms.idTipoNota'
                )
              }
              onChangeItemAtualAposCarregarSemClear={async ({ itemAtual }) => {
                if (!itemAtual) return;
                await handleCarregarConfiguracaoTributaria();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Select
              name="conhecimentoTransporteImpostoIcms.destinado"
              label="Destinado"
              options={FormatarEnum({
                enumObj: DestinadoEnum,
              })}
              onChange={async () => {
                await handleCarregarConfiguracaoTributaria();
              }}
            />
          </Col>
          <Col lg={4}>
            <InputAutoCompleteOperacaoFiscal
              label="CFOP"
              name="conhecimentoTransporteImpostoIcms.idOperacaoFiscal"
              nomeObjeto="conhecimentoTransporteImpostoIcms.operacaoFiscal"
              placeholder="CFOP"
              tipoEntradaSaida={TipoEntradaSaidaOperacaoFiscalEnum.saida}
              tipoMercado={ObterTipoMercado({
                ufEstadoOrigem,
                ufEstadoDestino,
              })}
            />
          </Col>
          <Col lg={4}>
            <Input
              label="Natureza da Operação"
              name="conhecimentoTransporteImpostoIcms.naturezaOperacao"
              placeholder="Natureza da Operação"
              maxLength={60}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CST"
              placeholder="CST"
              name="conhecimentoTransporteImpostoIcms.idSituacaoTributaria"
              nomeObjeto="conhecimentoTransporteImpostoIcms.situacaoTributaria"
              icmsConhecimentoTransporte
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularIcms();
                handleCalcularIcmsOpInterestadual();
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        style={{
          display: `${handleShowEmitenteSimplesNacional() ? 'none' : 'block'}`,
        }}
      >
        <h5>ICMS</h5>

        <Row
          style={{
            display: `${camposEscondidosIcms.calcularIcms ? 'flex' : 'none'}`,
          }}
        >
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcms}
                label="Calcular ICMS"
                name="conhecimentoTransporteImpostoIcms.calcularIcms"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) {
                    handleCalcularIcms();
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            style={{
              display: `${
                camposEscondidosIcms.percentualReducaoBaseCalculoIcms
                  ? 'flex'
                  : 'none'
              }`,
            }}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualReducaoBaseCalculoIcms}
              label="Percentual de Redução da Base de Cálculo"
              casasDecimais={6}
              casasInteiras={5}
              placeholder="Percentual de Redução da Base de Cálculo"
              name="conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculo"
              onChange={handleCalcularIcms}
            />
          </Col>
          <Col
            lg={3}
            style={{
              display: `${
                camposEscondidosIcms.baseCalculoIcms ? 'flex' : 'none'
              }`,
            }}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcms}
              label="Base de Cálculo"
              casasDecimais={2}
              casasInteiras={13}
              placeholder="Base de Cálculo"
              name="conhecimentoTransporteImpostoIcms.baseCalculo"
              onChange={handleCalcularIcms}
            />
          </Col>
          <Col
            lg={3}
            style={{
              display: `${camposEscondidosIcms.aliquotaIcms ? 'flex' : 'none'}`,
            }}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcms}
              label="Alíquota"
              casasDecimais={2}
              casasInteiras={3}
              placeholder="Alíquota"
              name="conhecimentoTransporteImpostoIcms.aliquota"
              onChange={handleCalcularIcms}
            />
          </Col>
          <Col
            lg={3}
            style={{
              display: `${camposEscondidosIcms.valorIcms ? 'flex' : 'none'}`,
            }}
          >
            <InputDecimalNulavel
              label="Valor Total"
              casasDecimais={2}
              casasInteiras={13}
              placeholder="Valor Total"
              name="conhecimentoTransporteImpostoIcms.valor"
              disabled={camposBloqueadosIcms.valorIcms}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>ICMS Interestadual</h5>

        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Utiliza ICMS Op. Interestadual"
                name="conhecimentoTransporteImpostoIcms.utilizaIcmsOpInterestadual"
                onChange={() => {
                  refresh();
                  handleCalcularIcmsOpInterestadual();
                }}
              />
            </div>
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={!utilizaIcmsOpInterestadual}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposEscondidosIcmsOpInterestadual.calcularIcmsOpInterestadual
                }
                label="Calcular ICMS Op. Interestadual"
                name="conhecimentoTransporteImpostoIcms.calcularIcmsOpInterestadual"
                onChange={() => {
                  refresh();
                  handleCalcularIcmsOpInterestadual();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row hidden={!utilizaIcmsOpInterestadual}>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOpInterestadual.percentualReducaoBaseCalculoIcmsOpInterestadual
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOpInterestadual.percentualReducaoBaseCalculoIcmsOpInterestadual
              }
              label="% de Redução da Base de Cálculo"
              name="conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculoIcmsOpInterestadual"
              casasInteiras={3}
              casasDecimais={2}
              onBlur={handleCalcularIcmsOpInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOpInterestadual.baseCalculoIcmsOpInterestadual
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOpInterestadual.baseCalculoIcmsOpInterestadual
              }
              label="Base de Cálculo"
              name="conhecimentoTransporteImpostoIcms.baseCalculoIcmsOpInterestadual"
              casasInteiras={3}
              casasDecimais={2}
              onBlur={handleCalcularIcmsOpInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOpInterestadual.aliquotaIcmsOpInterestadual
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOpInterestadual.aliquotaIcmsOpInterestadual
              }
              label="Alíquota"
              name="conhecimentoTransporteImpostoIcms.aliquotaIcmsOpInterestadual"
              casasInteiras={3}
              casasDecimais={2}
              onBlur={handleCalcularIcmsOpInterestadual}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcmsOpInterestadual.valorIcmsOpInterestadual
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcmsOpInterestadual.valorIcmsOpInterestadual
              }
              label="Valor Total"
              name="conhecimentoTransporteImpostoIcms.valorIcmsOpInterestadual"
              casasInteiras={3}
              casasDecimais={2}
              onChange={() => {
                refresh();
                handleCalcularIcmsOpInterestadual();
              }}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ImpostoTab;
