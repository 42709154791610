import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/codigo-situacao-tributaria-icms-auto-complete-tag';

interface ICodigoSituacaoTributariaIcmsAutoCompleteTagComunicadorIndex {
  params: any;
}

class CodigoSituacaoTributariaIcmsAutoCompleteTagComunicador {
  public async index(
    params: ICodigoSituacaoTributariaIcmsAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoSituacaoTributariaIcmsAutoCompleteTagComunicador();
