import React, { useCallback, useEffect, useState } from 'react';
import { Row, Table } from 'react-bootstrap';
import { IProdutoMovimentacoesEstoquesLista } from '@elogestor/util';
import { v4 } from 'uuid';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ProdutoObterMovimentacoesEstoquesComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterMovimentacoesEstoquesComunicador';

const EstoqueTab: React.FC = () => {
  const { setLoading, getIdDetalheRegistro } = UseForm();
  const [listaValor, setListaValor] = useState<
    IProdutoMovimentacoesEstoquesLista[]
  >([]);
  const idDetalheRegistro = getIdDetalheRegistro();

  const handleCarregarDados = useCallback(async () => {
    try {
      setLoading(true);

      const response = await ProdutoObterMovimentacoesEstoquesComunicador.index(
        {
          params: {
            idProduto: String(idDetalheRegistro),
          },
        }
      );
      setListaValor(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [idDetalheRegistro, setLoading]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <div>
      <Divisor>
        <Row>
          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Local de Estoque</span>
                  </th>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Tipo de Estoque</span>
                  </th>
                  <th className="lista-texto" style={{ width: '30%' }}>
                    <span>Pessoa Terceiro</span>
                  </th>
                  <th className="lista-texto" style={{ width: '15%' }}>
                    <span>Lote</span>
                  </th>
                  <th className="lista-valor" style={{ width: '15%' }}>
                    <span>Saldo de Estoque</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor &&
                  listaValor.map((item) => {
                    return (
                      <tr key={v4()}>
                        <td className="lista-valor">
                          {item.estoqueCombinacao.localEstoque
                            ? item.estoqueCombinacao.localEstoque.descricao
                            : ''}
                        </td>
                        <td className="lista-valor">
                          {item.estoqueCombinacao.estoqueIndicadorTipo}
                        </td>
                        <td className="lista-data">
                          {item.estoqueCombinacao.pessoa
                            ? `${item.estoqueCombinacao.pessoa?.codigo} - ${item.estoqueCombinacao.pessoa?.nomeRazaoSocial}`
                            : ''}
                        </td>
                        <td className="lista-texto">
                          {item.estoqueCombinacao.lote
                            ? item.estoqueCombinacao.lote.codigo
                            : ''}
                        </td>
                        <td className="lista-valor">
                          {Number(item.saldo).FormatarParaPtBr({
                            maximoCasasDecimais: 4,
                            minimoCasasDecimais: 4,
                          })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Tabela>
        </Row>
      </Divisor>
    </div>
  );
};

export default EstoqueTab;
