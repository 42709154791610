import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/contas-receber-pagar';

interface IContaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    isAdiantamentoAntecipacao?: boolean;
  };
}

interface IContaComunicadorShow {
  id: string;
}

interface IContaComunicadorStore {
  params: any;
}

interface IContaComunicadorUpdate {
  id: string;
  params: any;
}

interface IContaComunicadorDeletar {
  id: string;
}

class ContaComunicador {
  public async index(params: IContaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IContaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(params: IContaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IContaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IContaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ContaComunicador();
