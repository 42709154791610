"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloImpressaoCotacaoCompraEnum;
(function (ModeloImpressaoCotacaoCompraEnum) {
    ModeloImpressaoCotacaoCompraEnum["retratoSemFotoProduto"] = "Retrato (sem foto produto)";
    ModeloImpressaoCotacaoCompraEnum["retratoComFotoProduto"] = "Retrato (com foto produto)";
    ModeloImpressaoCotacaoCompraEnum["paisagemSemFotoProduto"] = "Paisagem (sem foto produto)";
    ModeloImpressaoCotacaoCompraEnum["paisagemComFotoProduto"] = "Paisagem (com foto produto)";
})(ModeloImpressaoCotacaoCompraEnum || (ModeloImpressaoCotacaoCompraEnum = {}));
exports.default = ModeloImpressaoCotacaoCompraEnum;
