import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { SituacaoManifestoTransporteEnum } from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiXCircle } from 'react-icons/bi/index.mjs';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import Input from '../../../../../../../Componentes/Inputs/Input';

interface IEncerrarModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const EncerrarDetalhe: React.FC<IEncerrarModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.encerrado;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleChangeCidadeEncerramento = useCallback(
    ({ itemAtual }: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRefDetalhe.current?.setFieldValue(
        'cidadeEncerramento.estado.sigla',
        itemAtual?.estado?.sigla || ''
      );
    },
    [formRefDetalhe]
  );

  return (
    <JanelaDetalhe
      titulo="Encerrar MDF-e"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <div style={{ width: '100%' }}>
              <Divisor>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <InputDate
                      ref={inputRefFocus}
                      label="Data"
                      name="dataEncerramento"
                    />
                  </Col>

                  <Col lg={10} md={12}>
                    <InputAutoCompleteCidadeUf
                      label="Cidade de Encerramento"
                      placeholder="Cidade de Encerramento"
                      name="idCidadeEncerramento"
                      nomeObjeto="cidadeEncerramento"
                      onChangeItemAtual={handleChangeCidadeEncerramento}
                    />
                  </Col>
                  <Col lg={2} md={12}>
                    <Input
                      name="cidadeEncerramento.estado.sigla"
                      label="UF"
                      placeholder="UF"
                      disabled
                    />
                  </Col>
                </Row>
              </Divisor>
            </div>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{ marginLeft: 15, display: 'flex', alignItems: 'center' }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading || situacaoBloquearCampos}
            >
              <BiXCircle />
              <span style={{ marginLeft: 10 }}>Encerrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default EncerrarDetalhe;
