/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import DemonstrativoResultadoAutoCompleteComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/DemonstrativoResultado/AutoComplete/DemonstrativoResultadoAutoComplete/DemonstrativoResultadoAutoCompleteComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IDemonstrativoResultado {
  id: string;
  descricao: string;
  idDemonstrativoResultadoPai: string;
  pontos: number;
}

export interface IOnChangeItemAtualAutoCompleteDemonstrativoResultadoEvent {
  itemAtual: IDemonstrativoResultado | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteDemonstrativoResultadoEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteDemonstrativoResultadoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteDemonstrativoResultadoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  codigoCompletoAtual?: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  ativo?: boolean;
  listaIdParaRemover?: string[];

  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteDemonstrativoResultadoEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteDemonstrativoResultadoEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteDemonstrativoResultadoEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteDemonstrativoResultadoEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteDemonstrativoResultadoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteDemonstrativoResultado: React.ForwardRefRenderFunction<
  IInputAutoCompleteDemonstrativoResultadoRef,
  IInputAutoCompleteDemonstrativoResultadoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaIdParaRemover = [],
    ativo = true,
    codigoCompletoAtual,
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<IDemonstrativoResultado | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response =
                  await DemonstrativoResultadoAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite,
                      texto: valor,
                      ativo,
                      codigoCompletoAtual,
                      listaIdParaRemover,
                    },
                  });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: IDemonstrativoResultado) => {
              return item.id;
            }}
            obterLabel={(item: IDemonstrativoResultado) => {
              if (!item.descricao) return '';

              return item.descricao;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteDemonstrativoResultado);
