"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEmissaoCteEnum;
(function (TipoEmissaoCteEnum) {
    TipoEmissaoCteEnum["normal"] = "1 - Normal";
    TipoEmissaoCteEnum["epecpelaSVC"] = "4 - EPEC pela SVC";
    TipoEmissaoCteEnum["contingenciaFSDA"] = "5 - Conting\u00EAncia FSDA";
    TipoEmissaoCteEnum["autorizacaopelaSVCRS"] = "7 - Autoriza\u00E7\u00E3o pela SVC-RS";
    TipoEmissaoCteEnum["autorizacaopelaSVCSP"] = "8 - Autoriza\u00E7\u00E3o pela SVC-SP";
})(TipoEmissaoCteEnum || (TipoEmissaoCteEnum = {}));
exports.default = TipoEmissaoCteEnum;
