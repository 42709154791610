import api from '../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte';

interface IConhecimentoTransporteComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IConhecimentoTransporteComunicadorShow {
  id: string;
}

interface IConhecimentoTransporteComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteComunicadorUpdate {
  id: string;
  params: any;
}

interface IConhecimentoTransporteComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteComunicador {
  public async index(
    params: IConhecimentoTransporteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IConhecimentoTransporteComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IConhecimentoTransporteComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteComunicador();
