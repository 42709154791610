import React, { useLayoutEffect, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { UseRecuperarFormulario } from '../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import IParametroFilhoFormatado from '../Main/Interface/IParametroFilhoFormatado';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IParametroDetalhe extends IPadraoProps {
  listaTab: IParametroFilhoFormatado[];
  formRef: React.RefObject<IFormCiaHandles>;
}

const Detalhe: React.FC<IParametroDetalhe> = ({
  children,
  listaTab,
  formRef,
}) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const navigate = useNavigate();

  const listaTabRef = useRef(listaTab);

  useEffect(() => {
    listaTabRef.current = listaTab;
  }, [listaTab]);

  useLayoutEffect(() => {
    async function ExecutaHandleChange(): Promise<void> {
      const data = (formRef.current?.getData() as any) || {};
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        navigate('/configuracao/parametro/detalhe', {
          state: {
            dadosRecuperados: listaTabRef.current.map((tab) => {
              return {
                id: tab.id,
                idParametro: tab.idParametro,
                descricao: tab.descricao,
                identificacao: tab.identificacao,
                observacao: tab.observacao,
                tipo: tab.tipo,
                valorPadrao: tab.valorPadrao,
                valorRecuperado: data[tab.identificacao],
              };
            }),
          },
        });
      }
    }

    return () => {
      const mudou = listaTabRef.current.find((tab) => tab.valorMudou());
      if (mudou) ExecutaHandleChange();
    };
  }, [abrirRecuperarFormulario, formRef, navigate]);

  return (
    <div
      style={{
        padding: '50px 10px 15px',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

export default Detalhe;
