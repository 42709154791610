import {
  FormatarEnum,
  IMovimentoManualEstoqueValoresAlterar,
  TipoMovimentoEntradaSaidaEnum,
  EstoqueIndicadorTipoEnum,
  StringConverterParaEnum,
  FormatarRemovendoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseRota } from '../../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';
import IMain from '../../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../../Componentes/TextoLoadingSalvar';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';
import Select from '../../../../../../../Componentes/Select';
import InputAutoCompleteProduto, {
  IOnChangeItemAtualAutoCompleteProdutoEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompletePessoa from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputAutoCompleteLocalEstoque from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ObterValorUnitarioCustoSaidaComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Comunicador/ObterValorUnitarioCustoSaidaComunicador';
import InputAutoCompleteLote, {
  IOnChangeItemAtualAutoCompleteLoteEvent,
} from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ProdutoObterLocalEstoquePadraoComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoObterLocalEstoquePadraoComunicador';

type IProps = IMain<IMovimentoManualEstoqueValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosEstoqueMovimentoManualEstoque: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const [tipoLocalEstoqueValue, setTipoLocalEstoqueValue] = useState(
    EstoqueIndicadorTipoEnum.proprioEmPoderProprio
  );
  const [showLotes, setShowLotes] = useState(true);
  const [desabilitarValorUnitario, setDesabilitarValorUnitario] =
    useState(false);

  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleChangeProduto = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteProdutoEvent) => {
      if (!event.itemAtual) {
        formRef.current?.clearField(
          'produto.produtoEstoque.unidadeMedida.sigla'
        );

        setShowLotes(true);
      } else {
        formRef.current?.setFieldValue(
          'produto.produtoEstoque.unidadeMedida.sigla',
          event.itemAtual.produtoEstoque.unidadeMedida.sigla
        );

        setShowLotes(event.itemAtual.produtoEstoque.controlarLote);

        try {
          setLoading(true);

          const localEstoquePadrao =
            await ProdutoObterLocalEstoquePadraoComunicador.show({
              id: event.itemAtual.id,
            });

          formRef.current?.setFieldValue('localEstoque', localEstoquePadrao);

          setLoading(false);
        } catch (error) {
          TratarErros(error);
          setLoading(false);
        }
      }
    },
    [formRef, setLoading]
  );

  const handleChangeLote = useCallback(
    async (event: IOnChangeItemAtualAutoCompleteLoteEvent) => {
      if (!event.itemAtual) {
        formRef.current?.clearField('lote.dataValidade');
      } else {
        formRef.current?.setFieldValue(
          'lote.dataValidade',
          event.itemAtual.dataValidade
        );
      }
    },
    [formRef]
  );

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const currentTipoLocalEstoque = formRef.current?.getFieldValue(
    'indicadorTipoEstoque'
  );

  useEffect(() => {
    const valor = StringConverterParaEnum<
      typeof EstoqueIndicadorTipoEnum,
      EstoqueIndicadorTipoEnum
    >(EstoqueIndicadorTipoEnum, currentTipoLocalEstoque);

    setTipoLocalEstoqueValue(valor);
  }, [currentTipoLocalEstoque]);

  const handleCarregarCustosSaida = useCallback(async () => {
    const tipoMovimento = formRef.current?.getFieldValue('tipoMovimento');
    const dataHora = formRef.current?.getFieldValue('dataHora');
    const produto = formRef.current?.getFieldValueNomeObjeto('produto');
    const pessoa = formRef.current?.getFieldValueNomeObjeto(
      'pessoaEstabelecimento'
    );
    const indicadorTipoEstoque = formRef.current?.getFieldValue(
      'indicadorTipoEstoque'
    );

    if (
      !tipoMovimento ||
      tipoMovimento !== TipoMovimentoEntradaSaidaEnum.saida ||
      !dataHora ||
      !produto ||
      !indicadorTipoEstoque
    )
      return;

    try {
      const response = await ObterValorUnitarioCustoSaidaComunicador.index({
        params: {
          dataMovimento: dataHora,
          idProduto: produto.id,
          idPessoa: pessoa ? pessoa.id : undefined,
          indicadorTipoEstoque,
        },
      });

      formRef.current?.setFieldValue(
        'valorUnitarioCustoUltimaCompra',
        response.custos.valorUnitarioCustoUltimaCompra
      );
      formRef.current?.setFieldValue(
        'valorUnitarioCustoMedio',
        response.custos.valorUnitarioCustoMedio
      );
      formRef.current?.setFieldValue(
        'valorUnitarioCustoContabilIr',
        response.custos.valorUnitarioCustoContabilIr
      );
      formRef.current?.setFieldValue(
        'valorUnitarioCustoContabilIcmsIpi',
        response.custos.valorUnitarioCustoContabilIcmsIpi
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data.listaMensagem &&
        error.response.data.listaMensagem.includes(
          'A Empresa não possui um Regime Tributário na data de movimento!'
        )
      ) {
        return;
      }

      TratarErros(error);
      throw error;
    }
  }, [formRef]);

  const handleSetarValores = useCallback(async () => {
    const valorUnitarioCustoUltimaCompra = formRef.current?.getFieldValue(
      'valorUnitarioCustoUltimaCompra'
    );

    const valorUnitarioCustoMedio = formRef.current?.getFieldValue(
      'valorUnitarioCustoMedio'
    );
    if (valorUnitarioCustoMedio === 0) {
      formRef.current?.setFieldValue(
        'valorUnitarioCustoMedio',
        valorUnitarioCustoUltimaCompra
      );
    }

    const valorUnitarioCustoContabilIr = formRef.current?.getFieldValue(
      'valorUnitarioCustoContabilIr'
    );
    if (valorUnitarioCustoContabilIr === 0) {
      formRef.current?.setFieldValue(
        'valorUnitarioCustoContabilIr',
        valorUnitarioCustoUltimaCompra
      );
    }

    const valorUnitarioCustoContabilIcmsIpi = formRef.current?.getFieldValue(
      'valorUnitarioCustoContabilIcmsIpi'
    );
    if (valorUnitarioCustoContabilIcmsIpi === 0) {
      formRef.current?.setFieldValue(
        'valorUnitarioCustoContabilIcmsIpi',
        valorUnitarioCustoUltimaCompra
      );
    }
  }, [formRef]);

  const handleAlterarValorUnitarioCustoUltimaCompra = useCallback(async () => {
    const quantidade = formRef.current?.getFieldValue('quantidade');
    const valorUnitarioCustoUltimaCompra = formRef.current?.getFieldValue(
      'valorUnitarioCustoUltimaCompra'
    );

    formRef.current?.setFieldValue(
      'valorTotalCustoUltimaCompra',
      quantidade * valorUnitarioCustoUltimaCompra
    );
  }, [formRef]);

  const handleAlterarValorUnitarioCustoMedio = useCallback(async () => {
    const quantidade = formRef.current?.getFieldValue('quantidade');
    const valorUnitarioCustoMedio = formRef.current?.getFieldValue(
      'valorUnitarioCustoMedio'
    );

    formRef.current?.setFieldValue(
      'valorTotalCustoMedio',
      quantidade * valorUnitarioCustoMedio
    );
  }, [formRef]);

  const handleAlterarValorUnitarioCustoContabilIr = useCallback(async () => {
    const quantidade = formRef.current?.getFieldValue('quantidade');
    const valorUnitarioCustoContabilIr = formRef.current?.getFieldValue(
      'valorUnitarioCustoContabilIr'
    );

    formRef.current?.setFieldValue(
      'valorTotalCustoContabilIr',
      quantidade * valorUnitarioCustoContabilIr
    );
  }, [formRef]);

  const handleAlterarValorUnitarioCustoContabilIcmsIpi =
    useCallback(async () => {
      const quantidade = formRef.current?.getFieldValue('quantidade');
      const valorUnitarioCustoContabilIcmsIpi = formRef.current?.getFieldValue(
        'valorUnitarioCustoContabilIcmsIpi'
      );

      formRef.current?.setFieldValue(
        'valorTotalCustoContabilIcmsIpi',
        quantidade * valorUnitarioCustoContabilIcmsIpi
      );
    }, [formRef]);

  const handleAlterarQuantidade = useCallback(async () => {
    handleAlterarValorUnitarioCustoUltimaCompra();
    handleAlterarValorUnitarioCustoMedio();
    handleAlterarValorUnitarioCustoContabilIr();
    handleAlterarValorUnitarioCustoContabilIcmsIpi();
  }, [
    handleAlterarValorUnitarioCustoContabilIcmsIpi,
    handleAlterarValorUnitarioCustoContabilIr,
    handleAlterarValorUnitarioCustoMedio,
    handleAlterarValorUnitarioCustoUltimaCompra,
  ]);

  useEffect(() => {
    formRef.current?.setFieldDisabled('dataHora', !!idEditar);
  }, [formRef, idEditar]);

  const currentLote = formRef.current?.getFieldValue('idLote');

  useEffect(() => {
    if (idEditar) {
      if (currentLote) {
        setShowLotes(true);
      } else {
        setShowLotes(false);
      }
    }
  }, [currentLote, idEditar]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col xl={2} lg={6} sm={12}>
              <InputInteiroNulavel
                label="Número"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col xl={3} lg={6} sm={12}>
              <InputDateTime
                label="Data e hora"
                name="dataHora"
                disabled={!!idEditar}
                onChange={handleCarregarCustosSaida}
              />
            </Col>
            <Col xl={2} lg={6} sm={12}>
              <Select
                label="Tipo"
                name="tipoMovimento"
                disabled={!!idEditar}
                options={FormatarEnum({
                  enumObj: TipoMovimentoEntradaSaidaEnum,
                })}
                onChange={(event) => {
                  if (
                    event.currentTarget.value ===
                    TipoMovimentoEntradaSaidaEnum.entrada
                  ) {
                    setDesabilitarValorUnitario(false);
                  } else {
                    setDesabilitarValorUnitario(true);
                  }
                  handleCarregarCustosSaida();
                }}
              />
            </Col>
            <Col xl={3} lg={6} sm={12}>
              <Select
                label="Tipo de Estoque"
                name="indicadorTipoEstoque"
                disabled={!!idEditar}
                options={FormatarRemovendoEnum({
                  enumObj: EstoqueIndicadorTipoEnum,
                  enumRemover: [
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                  ],
                })}
                onChange={(event) => {
                  formRef.current?.setErrors({});

                  const valor = StringConverterParaEnum<
                    typeof EstoqueIndicadorTipoEnum,
                    EstoqueIndicadorTipoEnum
                  >(EstoqueIndicadorTipoEnum, event.target.value);

                  if (
                    valor === EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                  ) {
                    if (formRef.current)
                      formRef.current.setSemExecutarEvento({
                        pessoaEstabelecimento: null,
                      });
                  }

                  setTipoLocalEstoqueValue(valor);
                  handleCarregarCustosSaida();
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={5} lg={8}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                name="idProduto"
                nomeObjeto="produto"
                label="Produto"
                placeholder="Produto"
                onChangeItemAtual={(event) => {
                  if (idEditar) return;
                  handleChangeProduto(event);
                  handleCarregarCustosSaida();
                }}
                disabled={!!idEditar}
                permitirAdicionar={false}
                controlaEstoque
              />
            </Col>
            <Col xl={2} lg={4}>
              <Input
                name="produto.produtoEstoque.unidadeMedida.sigla"
                label="Unidade"
                placeholder="Unidade"
                disabled
              />
            </Col>
            <Col
              xl={2}
              lg={6}
              sm={6}
              xs={12}
              style={{ display: `${showLotes ? '' : 'none'}` }}
            >
              <InputAutoCompleteLote
                name="idLote"
                nomeObjeto="lote"
                label="Lote"
                placeholder="Lote"
                disabled={!!idEditar}
                permitirAdicionar={!idEditar}
                onChangeItemAtual={(event) => {
                  handleChangeLote(event);
                }}
              />
            </Col>
            <Col
              xl={3}
              lg={6}
              sm={6}
              xs={12}
              style={{ display: `${showLotes ? '' : 'none'}` }}
            >
              <InputDate name="lote.dataValidade" label="Validade" disabled />
            </Col>
          </Row>

          <Row
            style={{
              display: `${
                /terceiro/i.test(tipoLocalEstoqueValue) ? 'flex' : 'none'
              }`,
            }}
          >
            <Col xl={5}>
              <InputAutoCompletePessoa
                name="idPessoaEstabelecimento"
                label="Pessoa (terceiro)"
                placeholder="Pessoa (terceiro)"
                nomeObjeto="pessoaEstabelecimento"
                disabled={!!idEditar}
                permitirAdicionar={!idEditar}
                onChangeItemAtual={handleCarregarCustosSaida}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={5}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoque"
                label="Local Estoque"
                placeholder="Local Estoque"
                nomeObjeto="localEstoque"
                disabled={!!idEditar}
                permitirAdicionar={!idEditar}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={6}>
              <InputDecimalNulavel
                name="quantidade"
                label="Quantidade"
                placeholder="Quantidade"
                casasDecimais={4}
                casasInteiras={11}
                disabled={!!idEditar}
                onChange={handleAlterarQuantidade}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorUnitarioCustoUltimaCompra"
                label="Val. unit. custo Última Compra"
                casasDecimais={10}
                casasInteiras={11}
                disabled={!!idEditar || desabilitarValorUnitario}
                onChange={handleAlterarValorUnitarioCustoUltimaCompra}
                onChangeValue={handleAlterarValorUnitarioCustoUltimaCompra}
                onBlur={handleSetarValores}
              />
            </Col>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorTotalCustoUltimaCompra"
                label="Val. total custo Última Compra"
                casasDecimais={2}
                casasInteiras={14}
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorUnitarioCustoMedio"
                label="Val. unit. Custo Médio"
                casasDecimais={10}
                casasInteiras={11}
                disabled={!!idEditar || desabilitarValorUnitario}
                onChange={handleAlterarValorUnitarioCustoMedio}
                onChangeValue={handleAlterarValorUnitarioCustoMedio}
              />
            </Col>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorTotalCustoMedio"
                label="Val. total Custo Médio"
                casasDecimais={2}
                casasInteiras={14}
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorUnitarioCustoContabilIr"
                label="Val. unit. custo Contábil IR"
                casasDecimais={10}
                casasInteiras={11}
                disabled={!!idEditar || desabilitarValorUnitario}
                onChange={handleAlterarValorUnitarioCustoContabilIr}
                onChangeValue={handleAlterarValorUnitarioCustoContabilIr}
              />
            </Col>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorTotalCustoContabilIr"
                label="Val. total custo Contábil IR"
                casasDecimais={2}
                casasInteiras={14}
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorUnitarioCustoContabilIcmsIpi"
                label="Val. unit. custo Contábil ICMS/IPI"
                casasDecimais={10}
                casasInteiras={11}
                disabled={!!idEditar || desabilitarValorUnitario}
                onChange={handleAlterarValorUnitarioCustoContabilIcmsIpi}
                onChangeValue={handleAlterarValorUnitarioCustoContabilIcmsIpi}
              />
            </Col>
            <Col xl={3} lg={6}>
              <InputDecimal
                name="valorTotalCustoContabilIcmsIpi"
                label="Val. total custo Contábil ICMS/IPI"
                casasDecimais={2}
                casasInteiras={14}
                disabled
              />
            </Col>
            <Col lg={12}>
              <Textarea
                name="observacoes"
                label="Observação"
                placeholder="Observação"
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
