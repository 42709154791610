"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDocumentoAnteriorEnum;
(function (TipoDocumentoAnteriorEnum) {
    TipoDocumentoAnteriorEnum["atre"] = "07 - ATRE";
    TipoDocumentoAnteriorEnum["dtaDespachodeTransitoAduaneiro"] = "08 - DTA (Despacho de Transito Aduaneiro)";
    TipoDocumentoAnteriorEnum["conhecimentoAereoInternacional"] = "09 - Conhecimento A\u00E9reo Internacional";
    TipoDocumentoAnteriorEnum["conhecimentoCartadePorteInternacional"] = "10 \u2013 Conhecimento - Carta de Porte Internacional";
    TipoDocumentoAnteriorEnum["conhecimentoAvulso"] = "11 \u2013 Conhecimento Avulso";
    TipoDocumentoAnteriorEnum["tifTransporteInternacionalFerrovi\u00E1rio"] = "12 - TIF (Transporte Internacional Ferrovi\u00E1rio)";
    TipoDocumentoAnteriorEnum["blBillofLading"] = "13 - BL (Bill of Lading)";
})(TipoDocumentoAnteriorEnum || (TipoDocumentoAnteriorEnum = {}));
exports.default = TipoDocumentoAnteriorEnum;
