import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-icms';

interface IRegraEscolhaCstCreditoIcmsComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaCstCreditoIcmsComunicadorShow {
  id: string;
}

interface IRegraEscolhaCstCreditoIcmsComunicadorStore {
  params: any;
}

interface IRegraEscolhaCstCreditoIcmsComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaCstCreditoIcmsComunicadorDeletar {
  id: string;
}

class RegraEscolhaCstCreditoIcmsComunicador {
  public async index(
    params: IRegraEscolhaCstCreditoIcmsComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCstCreditoIcmsComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaCstCreditoIcmsComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaCstCreditoIcmsComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaCstCreditoIcmsComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoIcmsComunicador();
