"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ComparaBancoComFrontId;
function ComparaBancoComFrontId(dados) {
    if (!dados.front)
        dados.front = [];
    if (!dados.banco)
        dados.banco = [];
    const inserir = dados.front.filter((element) => {
        return !element.id || element.id === 0;
    });
    const alterar = dados.front.filter((element) => {
        if (!dados.banco)
            dados.banco = [];
        const aux = dados.banco.find((e) => {
            return e.id === element.id;
        });
        return !!aux;
    });
    const excluir = dados.banco.filter((element) => {
        if (!dados.front)
            dados.front = [];
        const aux = dados.front.find((e) => e.id === element.id);
        return !aux;
    });
    return { inserir, alterar, excluir };
}
