import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/engenharia/centro-trabalho-auto-complete-tag';

interface ICentroTrabalhoAutoCompleteTagComunicadorIndex {
  params: any;
}

class CentroTrabalhoAutoCompleteTagComunicador {
  public async index(
    params: ICentroTrabalhoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CentroTrabalhoAutoCompleteTagComunicador();
