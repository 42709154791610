/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable default-case */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { UseFieldCia, FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import {
  EstoqueIndicadorTipoEnum,
  FormatarEnum,
  IListaEstoqueUtilizadoAutoComplete,
  IPedidoVendaItemEstoqueLocalEstoqueLista,
  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum,
  TipoMovimentoEntradaSaidaEnum,
  ObterRegraIndicadorTipoEstoqueSaida,
  FormatarRemovendoEnum,
  TipoPedidoVendaEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../../../../Hooks/Permissoes';
import { UseReactSizeMeBodyHook } from '../../../../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseListaDetalheForm } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Tabela } from '../../../../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../../../Componentes/TextoLoading';
import InputAutoCompleteLocalEstoque from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import InputAutoCompleteLote from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import { UltimaTr } from '../../../../Lista/styles';
import { UseItemPedidoVenda } from '../../../../Hook/ItemPedidoVendaHook';
import InputDecimal from '../../../../../../../../../../../Componentes/Inputs/InputDecimal';
import { UseItemTab } from '../../../../Hook/ItemTabHook';
import Select from '../../../../../../../../../../../Componentes/Select';

interface IListaDetalheItemEstoqueLocalEstoqueProps {
  name: string;
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const PedidoVendaItemEstoqueLocalEstoqueLista: React.FC<
  IListaDetalheItemEstoqueLocalEstoqueProps
> = ({ name, formRefItem }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const formPrincipal = UseForm();
  const { produtoControlaLote, listaLocalEstoque, setListaLocalEstoque } =
    UseItemPedidoVenda();

  const { permissoes } = UsePermissoes();
  const { ComercialVendasPedidoVenda: permissao } = permissoes;
  const { telaGrande } = UseReactSizeMeBodyHook();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
    refresh,
  } = UseListaDetalheForm();

  const { idDetalheRegistro } = UseItemTab();

  const valorRef = useRef<IPedidoVendaItemEstoqueLocalEstoqueLista[]>([]);
  const [quantidadeTotal, setQuantidadeTotal] = useState<number>(0);

  const dataHoraEmissao =
    formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');
  const tipoPedidoVenda =
    formPrincipal.formRef.current?.getFieldValue('tipoPedidoVenda');

  const produto = formRefItem.current?.getFieldValueNomeObjeto('produto');
  const reservarEstoque = formRefItem.current?.getFieldValue(
    'pedidoVendaItemEstoque.reservarEstoque'
  );
  const indicadorTipoEstoqueItem = formRefItem.current?.getFieldValue(
    'pedidoVendaItemEstoque.indicadorTipoEstoque'
  );
  const fatorConversaoItem = formRefItem.current?.getFieldValue(
    'pedidoVendaItemEstoque.fatorConversao'
  );
  const quantidadeEstoqueItem = formRefItem.current?.getFieldValue(
    'pedidoVendaItemEstoque.quantidadeEstoque'
  );
  const porContaOrdemTerceiroItem = formRefItem.current?.getFieldValue(
    'pedidoVendaItemEstoque.porContaOrdemTerceiro'
  );
  const pessoaTerceiroItem = formRefItem.current?.getFieldValueNomeObjeto(
    'pedidoVendaItemEstoque.pessoaTerceiro'
  );

  const estoqueTipoEntradaSaida =
    formRefLista.current?.getFieldValue('tipoEntradaSaida');
  const estoqueIndicadorTipoEstoque = formRefLista.current?.getFieldValue(
    'indicadorTipoEstoque'
  );

  const listaEstoqueUtilizado = valorRef.current.map((valor) => {
    const retorno: IListaEstoqueUtilizadoAutoComplete = {
      idItemEstoqueLocalEstoque: valor.id,
      idLocalEstoque: valor.localEstoque?.id,
      idLote: valor.lote?.id,
      quantidade: valor.quantidade,
      tipoEntradaSaida: valor.tipoEntradaSaida,
      indicadorTipoEstoque: valor.indicadorTipoEstoque,
    };

    return retorno;
  });

  useEffect(() => {
    valorRef.current = listaLocalEstoque;
  }, [listaLocalEstoque]);

  useEffect(() => {
    registerField<IPedidoVendaItemEstoqueLocalEstoqueLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) {
          setListaLocalEstoque([]);
        } else {
          setListaLocalEstoque(value);
        }
      },

      setSemExecutarEvento(_, value) {
        if (!value) {
          setListaLocalEstoque([]);
        } else {
          setListaLocalEstoque(value);
        }
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (indicadorTipoEstoqueItem) {
      case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
        formRefLista.current?.setDataInicial({
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          indicadorTipoEstoque: EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
        });
        break;

      case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
        formRefLista.current?.setDataInicial({
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          indicadorTipoEstoque: EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
        });
        break;

      case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
        formRefLista.current?.setDataInicial({
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          indicadorTipoEstoque: EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
        });
        break;

      case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
        formRefLista.current?.setDataInicial({
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          indicadorTipoEstoque: EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
        });
        break;
    }
  }, [formRefLista, indicadorTipoEstoqueItem, listaLocalEstoque]);

  const handleAtualizarEstoqueQuantidade =
    useCallback(async (): Promise<void> => {
      const estoqueListaLocalEstoque = valorRef.current;
      const tipoEntradaSaida =
        formRefLista.current?.getFieldValue('tipoEntradaSaida');

      if (tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada) {
        const totalEntrada = estoqueListaLocalEstoque
          ?.filter((estoque: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
            return (
              estoque.tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada
            );
          })
          .reduce(
            (
              acumulador: number,
              estoque: IPedidoVendaItemEstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(estoque.quantidade);
              return acumulador;
            },
            0
          );

        const total = totalEntrada || 0;
        let quantidade = quantidadeEstoqueItem - total;
        if (quantidade < 0) quantidade = 0;

        formRefLista.current?.setFieldValue('quantidade', quantidade);
      } else {
        const totalSaida = estoqueListaLocalEstoque
          ?.filter((estoque: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
            return (
              estoque.tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.saida
            );
          })
          .reduce(
            (
              acumulador: number,
              estoque: IPedidoVendaItemEstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(estoque.quantidade);
              return acumulador;
            },
            0
          );

        const total = totalSaida || 0;
        let quantidade = quantidadeEstoqueItem - total;
        if (quantidade < 0) quantidade = 0;

        formRefLista.current?.setFieldValue('quantidade', quantidade);
      }
    }, [formRefLista, quantidadeEstoqueItem]);

  const handleAtualizarQuantidadeTotalEstoqueLocalEstoque =
    useCallback(async (): Promise<void> => {
      const estoqueListaLocalEstoque = valorRef.current;

      const total = estoqueListaLocalEstoque.reduce(
        (
          acumulador: number,
          item: IPedidoVendaItemEstoqueLocalEstoqueLista
        ) => {
          acumulador += Number(item.quantidade);
          return acumulador;
        },
        0
      );

      setQuantidadeTotal(total);
      await handleAtualizarEstoqueQuantidade();
    }, [handleAtualizarEstoqueQuantidade]);

  useEffect(() => {
    handleAtualizarQuantidadeTotalEstoqueLocalEstoque();
  }, [
    handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
    listaLocalEstoque,
    indicadorTipoEstoqueItem,
    quantidadeEstoqueItem,
    fatorConversaoItem,
    porContaOrdemTerceiroItem,
    pessoaTerceiroItem,
  ]);

  const handleObterPessoaTerceiroFormatada = useCallback(():
    | any
    | undefined => {
    const pessoa = formPrincipal.formRef.current?.getFieldValueNomeObjeto(
      'pedidoVendaPessoa.pessoa'
    );
    const pessoaTerceiro = formRefItem.current?.getFieldValueNomeObjeto(
      'pedidoVendaItemEstoque.pessoaTerceiro'
    );
    const porContaOrdemTerceiro = formRefItem.current?.getFieldValueNomeObjeto(
      'pedidoVendaItemEstoque.porContaOrdemTerceiro'
    );
    const tipoEntradaSaida =
      formRefLista.current?.getFieldValue('tipoEntradaSaida');

    if (
      indicadorTipoEstoqueItem ===
      EstoqueIndicadorTipoEnum.proprioEmPoderProprio
    )
      return undefined;

    if (porContaOrdemTerceiro && pessoaTerceiro) {
      if (
        indicadorTipoEstoqueItem ===
          EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro &&
        tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada
      )
        return pessoa;

      return pessoaTerceiro;
    }

    return pessoa;
  }, [
    formPrincipal.formRef,
    formRefItem,
    formRefLista,
    indicadorTipoEstoqueItem,
  ]);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const localEstoque =
      formRefLista.current?.getFieldValueNomeObjeto('localEstoque');
    const lote = formRefLista.current?.getFieldValueNomeObjeto('lote');
    const data = formRefLista.current?.getData();

    const dados: any = {
      id: '',
      localEstoque,
      lote,
      ...data,
    };

    const pessoaTerceiroFormatada = handleObterPessoaTerceiroFormatada();
    if (pessoaTerceiroFormatada) {
      dados.pessoaTerceiro = pessoaTerceiroFormatada;
    }

    await handleSubmit(dados, formRefLista.current);
    setIdDetalheRegistro('');

    await handleAtualizarQuantidadeTotalEstoqueLocalEstoque();
  }, [
    formRefLista,
    handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
    handleObterPessoaTerceiroFormatada,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  return (
    <>
      <FormCia ref={formRefLista}>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo Entrada/Saída"
              name="tipoEntradaSaida"
              options={FormatarEnum({
                enumObj: TipoMovimentoEntradaSaidaEnum,
              })}
              onChangeValue={() => {
                refresh();
              }}
              onChange={async (event) => {
                refresh();
                formRefLista.current?.clearField('localEstoque');
                formRefLista.current?.clearField('lote');

                const indicadorEstoqueAtualizado =
                  ObterRegraIndicadorTipoEstoqueSaida({
                    indicadorTipoEstoque: indicadorTipoEstoqueItem,
                    tipoEntradaSaida: event.currentTarget
                      .value as TipoMovimentoEntradaSaidaEnum,
                  });

                formRefLista.current?.setFieldValue(
                  'indicadorTipoEstoque',
                  indicadorEstoqueAtualizado
                );

                await handleAtualizarEstoqueQuantidade();
              }}
              disabled={
                indicadorTipoEstoqueItem ===
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                indicadorTipoEstoqueItem ===
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio ||
                tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
              }
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo de Estoque"
              name="indicadorTipoEstoque"
              options={FormatarRemovendoEnum({
                enumObj: EstoqueIndicadorTipoEnum,
                enumRemover: [
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                ],
              })}
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={produtoControlaLote ? 5 : 8} md={12}>
            <InputAutoCompleteLocalEstoque
              label="Local de Estoque"
              name="idLocalEstoque"
              nomeObjeto="localEstoque"
              permitirAdicionar={
                estoqueTipoEntradaSaida ===
                TipoMovimentoEntradaSaidaEnum.entrada
              }
              saldoEstoque={{
                somenteProdutoComSaldoEstoque:
                  estoqueTipoEntradaSaida ===
                    TipoMovimentoEntradaSaidaEnum.saida && reservarEstoque,
                idPessoa: handleObterPessoaTerceiroFormatada()?.id,
                idProduto: produto?.id,
                indicadorTipoEstoque: estoqueIndicadorTipoEstoque,
                dataHoraEmissao: dataHoraEmissao ?? undefined,
                tipoEntradaSaida: estoqueTipoEntradaSaida,

                localUtilizado:
                  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.pedidoVenda,
                idItemEstoque: idDetalheRegistro,
                listaEstoqueUtilizado,
                exibirSaldoEstoque: true,
              }}
              onChangeItemAtual={() => {
                refresh();
                formRefLista.current?.clearField('lote');
              }}
              disabled={
                !produto || tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
              }
            />
          </Col>
          <Col
            lg={3}
            md={12}
            style={{ display: produtoControlaLote ? '' : 'none' }}
          >
            <InputAutoCompleteLote
              label="Lote"
              name="idLote"
              nomeObjeto="lote"
              permitirAdicionar={
                estoqueTipoEntradaSaida ===
                TipoMovimentoEntradaSaidaEnum.entrada
              }
              saldoEstoque={{
                somenteProdutoComSaldoEstoque:
                  estoqueTipoEntradaSaida ===
                    TipoMovimentoEntradaSaidaEnum.saida && reservarEstoque,
                idPessoa: handleObterPessoaTerceiroFormatada()?.id,
                idProduto: produto?.id,
                indicadorTipoEstoque: estoqueIndicadorTipoEstoque,
                idLocalEstoque:
                  formRefLista.current?.getFieldValue('idLocalEstoque'),
                dataHoraEmissao: dataHoraEmissao ?? undefined,
                tipoEntradaSaida: estoqueTipoEntradaSaida,

                localUtilizado:
                  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.notaFiscalEntrada,
                idItemEstoque: idDetalheRegistro,
                listaEstoqueUtilizado,
                exibirSaldoEstoque: true,
              }}
              disabled={
                !produto || tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
              }
            />
          </Col>
          <Col lg={2} md={12}>
            <InputDecimal
              label="Quantidade"
              name="quantidade"
              placeholder="Quantidade"
              casasInteiras={11}
              casasDecimais={4}
              disabled={
                !produto || tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
              }
            />
          </Col>
          <Col
            lg={2}
            md={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <BtnPadraoButton
              style={{ maxWidth: telaGrande ? 250 : '' }}
              type="button"
              className="btn-padrao btn-verde-claro btn-adicionar"
              onClick={handleAdicionar}
              disabled={
                !permissao?.inclui ||
                formPrincipal.loading ||
                loading ||
                !produto ||
                tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
              }
            >
              <TextoLoading loading={formPrincipal.loading || loading}>
                <IoMdAdd />
                <span style={{ marginLeft: 10 }}>Adicionar</span>
              </TextoLoading>
            </BtnPadraoButton>
          </Col>
        </Row>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '25%' }}>
                <span>Tipo de Estoque</span>
              </th>
              <th className="lista-texto" style={{ width: '10%' }}>
                <span>Ent./Saída</span>
              </th>
              <th
                className="lista-texto"
                style={{ width: produtoControlaLote ? '35%' : '50%' }}
              >
                <span>Local de Estoque</span>
              </th>

              {produtoControlaLote && (
                <th className="lista-texto" style={{ width: '15%' }}>
                  <span>Lote</span>
                </th>
              )}

              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Quantidade</span>
              </th>

              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaLocalEstoque &&
              listaLocalEstoque.map(
                (pedidoVendaItemEstoqueLocalEstoque, index) => {
                  return (
                    <>
                      <tr
                        key={v4()}
                        className={
                          formPrincipal.loading || loading
                            ? 'tabela-desabilitada'
                            : ''
                        }
                        style={{
                          backgroundColor: index % 2 === 0 ? '#f0f0f1' : '#fff',
                        }}
                      >
                        <td className="lista-texto">
                          <div>
                            {
                              pedidoVendaItemEstoqueLocalEstoque.indicadorTipoEstoque
                            }
                          </div>
                        </td>
                        <td className="lista-texto">
                          <div>
                            {
                              pedidoVendaItemEstoqueLocalEstoque.tipoEntradaSaida
                            }
                          </div>
                        </td>

                        <td className="lista-texto">
                          <div>
                            {
                              pedidoVendaItemEstoqueLocalEstoque.localEstoque
                                .descricao
                            }
                          </div>
                        </td>

                        {produtoControlaLote && (
                          <td className="lista-texto">
                            <div>
                              {pedidoVendaItemEstoqueLocalEstoque.lote?.codigo}
                            </div>
                          </td>
                        )}

                        <td className="lista-valor">
                          <div>
                            {Number(
                              pedidoVendaItemEstoqueLocalEstoque.quantidade
                            )?.FormatarParaPtBr({
                              minimoCasasDecimais: 4,
                              maximoCasasDecimais: 4,
                            })}
                          </div>
                        </td>

                        <td className="tdButton lista-acoes">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <BtnAcoesButton>
                              <Dropdown.Item
                                onClick={async () => {
                                  await handleExcluir(String(index));
                                  await handleAtualizarQuantidadeTotalEstoqueLocalEstoque();
                                }}
                                disabled={
                                  !permissao?.exclui ||
                                  formPrincipal.loading ||
                                  loading ||
                                  tipoPedidoVenda ===
                                    TipoPedidoVendaEnum.preVenda
                                }
                              >
                                <span style={{ display: 'flex', fontSize: 16 }}>
                                  <IoMdRemoveCircleOutline
                                    style={{ marginRight: 5 }}
                                  />
                                  Remover
                                </span>
                              </Dropdown.Item>
                            </BtnAcoesButton>
                          </div>
                        </td>
                      </tr>

                      <tr
                        key={v4()}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#f0f0f1' : '#fff',
                          display:
                            pedidoVendaItemEstoqueLocalEstoque.indicadorTipoEstoque ===
                            EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                              ? 'none'
                              : '',
                        }}
                      >
                        <td colSpan={6} style={{ paddingTop: 0 }}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`PESSOA: `}
                          </span>
                          <span>
                            {
                              pedidoVendaItemEstoqueLocalEstoque.pessoaTerceiro
                                ?.nomeRazaoSocial
                            }
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
          </tbody>

          <UltimaTr style={{ borderTop: '2px solid lightgray' }}>
            <th />
            <th />
            <th />
            {produtoControlaLote && <th />}
            <th>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ fontSize: '13px' }}>Qtde. Total</div>
                <div style={{ alignSelf: 'flex-end', fontSize: '13px' }}>
                  {Number(quantidadeTotal).Arredondar(4).FormatarParaPtBr({
                    minimoCasasDecimais: 4,
                    maximoCasasDecimais: 4,
                  })}
                </div>
              </div>
            </th>
            <th />
          </UltimaTr>
        </Table>
      </Tabela>
    </>
  );
};

export default PedidoVendaItemEstoqueLocalEstoqueLista;
