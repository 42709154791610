import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida/obter-pessoa-padrao-transferencia-nota-fiscal-entrada';

interface INotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicadorShow {
  id: string;
}

class NotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicador {
  public async show(
    params: INotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaObterPessoaPadraoTransferenciaNotaFiscalEntradaComunicador();
