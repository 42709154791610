import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/conhecimento-transporte-terceiros/conhecimento-transporte-terceiro-por-emitente';

interface IConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicador {
  public async index(
    params: IConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ConhecimentoTransporteTerceirosPorEmitenteRelatorioComunicador();
