/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useRef, useState } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseSisdevinEstoqueMovimentoLista } from '../../../Hooks/SisdevinEstoqueMovimentoListaHook';
import Divisor from '../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import InputAutoCompleteCodigoRastreabilidade from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteCodigoRastreabilidade';
import SisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeComunicador from '../../../../../../../Comunicador/Suprimentos/Sisdevin/SisdevinEstoqueMovimento/Comunicador/SisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeComunicador';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';

interface ISisdevinEstoqueMovimentoEditarModal {
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
}

const SisdevinEstoqueMovimentoEditarDetalhe: React.FC<
  ISisdevinEstoqueMovimentoEditarModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { abrirJanela } = UseConfirmacao();

  const formRef = useRef<IFormCiaHandles>(null);

  const { listaValor, listaItemSelecionadoRef } =
    UseSisdevinEstoqueMovimentoLista();

  const [loading, setLoading] = useState(false);

  const handleClickVoltar = useCallback(async (): Promise<any> => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja fechar a Atualização do Código de Rastreabilidade sem Salvar?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) return;
    if (onFecharFormModal) onFecharFormModal();
  }, [abrirJanela, onFecharFormModal]);

  const handleValidar = useCallback((data: any): boolean => {
    if (!data.idCodigoRastreabilidadeSisdevin) {
      formRef.current?.setFieldError(
        'idCodigoRastreabilidadeSisdevin',
        'Código de Rastreabilidade Sisdevin (CR) é Obrigatório!'
      );

      ToastErro('Código de Rastreabilidade Sisdevin (CR) é Obrigatório!');
      return false;
    }

    return true;
  }, []);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const dados = formRef.current?.getData();
      const listaDadosFiltrados: any[] = [];
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item?.checked)
          listaDadosFiltrados.push({ ...listaValor[index], selecionado: true });
      });
      if (!dados || !handleValidar(dados)) {
        setLoading(false);
        return;
      }

      await SisdevinEstoqueMovimentoAtualizarCodigoRastreabilidadeComunicador.update(
        {
          id: dados?.idCodigoRastreabilidadeSisdevin,
          params: {
            listaDados: listaDadosFiltrados,
          },
        }
      );

      if (onSalvarFormModal) onSalvarFormModal();

      setLoading(false);
      ToastSucesso('Código de Rastreabilidade Atualizado!');
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleValidar, listaItemSelecionadoRef, listaValor, onSalvarFormModal]);

  return (
    <JanelaDetalhe
      titulo="Atualizar Código da Rastreabilidade (CR)"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRef}>
            <Divisor>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <InputAutoCompleteCodigoRastreabilidade
                    name="idCodigoRastreabilidadeSisdevin"
                    label="Código de Rastreabilidade (CR)"
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleClickVoltar}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Atualizar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default SisdevinEstoqueMovimentoEditarDetalhe;
