"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoInutilizacaoEnum;
(function (SituacaoInutilizacaoEnum) {
    SituacaoInutilizacaoEnum["naoTransmitida"] = "N\u00E3o Transmitida";
    SituacaoInutilizacaoEnum["transmitida"] = "Transmitida";
    SituacaoInutilizacaoEnum["inutilizadaAnteriormente"] = "Inutilizada Anteriormente";
    SituacaoInutilizacaoEnum["rejeitada"] = "Rejeitada";
})(SituacaoInutilizacaoEnum || (SituacaoInutilizacaoEnum = {}));
exports.default = SituacaoInutilizacaoEnum;
