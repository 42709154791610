"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoManifestoTransporteEnum;
(function (SituacaoManifestoTransporteEnum) {
    SituacaoManifestoTransporteEnum["naoTransmitido"] = "N\u00E3o Transmitido";
    SituacaoManifestoTransporteEnum["autorizado"] = "Autorizado";
    SituacaoManifestoTransporteEnum["encerrado"] = "Encerrado";
    SituacaoManifestoTransporteEnum["cancelado"] = "Cancelado";
    SituacaoManifestoTransporteEnum["rejeitado"] = "Rejeitado";
})(SituacaoManifestoTransporteEnum || (SituacaoManifestoTransporteEnum = {}));
exports.default = SituacaoManifestoTransporteEnum;
