import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  IOrdemProducaoItemLocalEstoqueLista,
  TipoPerdaEstruturaEnum,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import InputHiddenHtml from '../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteProdutoServico from '../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputInteiro from '../../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimal from '../../../../../../../../../Componentes/Inputs/InputDecimal';
import InputLabel from '../../../../../../../../../Componentes/Inputs/InputLabel';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';

const GeralTab: React.FC = () => {
  const formPrincipal = UseForm();

  const { formRefDetalhe, handleCarregarDados, refresh, inputRefFocus } =
    UseListaDetalheForm();

  const produtoObjetoPrincipal =
    formPrincipal.formRef.current?.getFieldValueNomeObjeto('produto');

  const idProdutoRemover = produtoObjetoPrincipal
    ? [produtoObjetoPrincipal.id]
    : [];

  const quantidadePerda = formRefDetalhe.current?.getFieldValue(
    'quantidadePerdaTotal'
  );

  const quantidadeDemanda =
    formRefDetalhe.current?.getFieldValue('quantidadeDemanda');

  const handleOnChangeCalcularDemandaUnitaria = useCallback(async () => {
    const quantidadePrincipal =
      formPrincipal.formRef.current?.getFieldValue('quantidade');

    const _quantidadeDemanda =
      formRefDetalhe.current?.getFieldValue('quantidadeDemanda');

    if (!quantidadePrincipal || !_quantidadeDemanda) return;

    const quantidadeDemandaPorUnidade =
      _quantidadeDemanda / quantidadePrincipal;

    formRefDetalhe.current?.setFieldValue(
      'quantidadeDemandaPorUnidade',
      quantidadeDemandaPorUnidade
    );
  }, [formPrincipal.formRef, formRefDetalhe]);

  const handleChangeCalcularQuantidadeDemandaTotal = useCallback(async () => {
    refresh();

    const _quantidadeDemanda =
      formRefDetalhe.current?.getFieldValue('quantidadeDemanda');

    const _quantidadePerda = formRefDetalhe.current?.getFieldValue(
      'quantidadePerdaTotal'
    );

    const quantidadeDemandaTotal =
      Number(_quantidadeDemanda || 0) + Number(_quantidadePerda || 0);

    formRefDetalhe.current?.setFieldValue(
      'quantidadeDemandaTotal',
      quantidadeDemandaTotal
    );

    refresh();

    formRefDetalhe.current?.setFieldValue('perdaCalcConfEstrutura', false);
  }, [formRefDetalhe, refresh]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <Divisor>
      <InputHiddenHtml name="id" />

      <InputHiddenHtml name="listaItemEstoqueMudou" />

      <Row>
        <Col lg={6} md={12} sm={12}>
          <InputAutoCompleteProdutoServico
            ref={inputRefFocus}
            label="Produto/Serviço"
            name="idProduto"
            nomeObjeto="produto"
            controlaEstoque
            placeholder="Produto/Serviço"
            listaIdParaRemover={idProdutoRemover}
            onChangeItemAtualAposCarregarSemClear={async (ev) => {
              if (ev.itemAtual?.id) {
                formRefDetalhe.current?.setFieldValue(
                  'unidadeMedida',
                  ev.itemAtual.produtoEstoque.unidadeMedida
                );

                if (
                  ev.itemAtual.tipoProdutoServico ===
                  TipoProdutoServicoEnum.servico
                ) {
                  formRefDetalhe.current?.setFieldValue('consumido', true);
                }
              } else {
                formRefDetalhe.current?.setFieldValue('consumido', false);

                formRefDetalhe.current?.clearField('unidadeMedida');
              }

              // Inicializa as perdas (Pois o backend não permite que os campos sejam vazios)
              formRefDetalhe.current?.setFieldValue('percentualPerda', 0);
              formRefDetalhe.current?.setFieldValue('percentualPerdaSetup', 0);
              formRefDetalhe.current?.setFieldValue('quantidadePerda', 0);
              formRefDetalhe.current?.setFieldValue('quantidadePerdaSetup', 0);
              formRefDetalhe.current?.setFieldValue(
                'tipoPerda',
                TipoPerdaEstruturaEnum.quantidade
              );
              formRefDetalhe.current?.setFieldValue(
                'tipoPerdaSetup',
                TipoPerdaEstruturaEnum.quantidade
              );

              refresh();
            }}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputAutoCompleteUnidadeMedida
            label="Unidade de Medida"
            name="idUnidadeMedida"
            nomeObjeto="unidadeMedida"
            placeholder="Unidade de Medida"
            disabled
            permitirAdicionar={false}
          />
        </Col>

        <Col lg={2} md={6} sm={12}>
          <InputInteiro label="Ordem" name="ordem" disabled />
        </Col>
      </Row>

      <Row>
        <Col xl={3} lg={3} md={6} sm={12}>
          <InputDecimal
            label="Qtde de Demanda"
            name="quantidadeDemanda"
            onChange={() => {
              handleChangeCalcularQuantidadeDemandaTotal();
              handleOnChangeCalcularDemandaUnitaria();
            }}
            casasInteiras={15}
            casasDecimais={4}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={12}>
          <InputDecimal
            label="Qtde de Perda Total"
            name="quantidadePerdaTotal"
            onChange={handleChangeCalcularQuantidadeDemandaTotal}
            casasInteiras={15}
            casasDecimais={4}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={12}>
          <InputDecimal
            label="Qtde Demanda Total"
            name="quantidadeDemandaTotal"
            value={(
              Number(quantidadeDemanda || 0) + Number(quantidadePerda || 0)
            ).FormatarParaPtBr({
              maximoCasasDecimais: 10,
              minimoCasasDecimais: 4,
            })}
            casasInteiras={15}
            casasDecimais={4}
            disabled
          />
        </Col>

        <Col xl={2} lg={3} md={6} sm={12}>
          <InputLabel
            name="consumido"
            label="Consumido"
            valorStyle={{ alignItems: 'center', marginTop: 5 }}
            formatarValor={(consumido = false) => {
              return (
                <div
                  className="lista-texto"
                  style={{
                    fontWeight: 'bold',
                    alignItems: 'flex-center',
                  }}
                >
                  <span style={{ color: consumido ? 'green' : 'red' }}>
                    {consumido ? 'Sim' : 'Não'}
                  </span>
                </div>
              );
            }}
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default GeralTab;
