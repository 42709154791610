import React from 'react';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import LiquidacaoContasEmLoteHook from './Hooks';
import LiquidacaoContasEmLoteLista from './Lista';

const LiquidacaoContasEmLoteListagem: React.FC = () => {
  return (
    <ListaProvider>
      <LiquidacaoContasEmLoteHook>
        <LiquidacaoContasEmLoteLista />
      </LiquidacaoContasEmLoteHook>
    </ListaProvider>
  );
};

export default LiquidacaoContasEmLoteListagem;
