import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormatarEnum,
  VeiculoCodigoCorEnum,
  VeiculoRestricaoEnum,
  VeiculoTipoOperacaoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import Select from '../../../../../../../../../Componentes/Select';
import Input from '../../../../../../../../../Componentes/Inputs/Input';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputInteiroNulavel from '../../../../../../../../../Componentes/Inputs/InputInteiroNulavel';

const ItemVeiculoTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();

  const tipoOperacao = formRefDetalhe.current?.getFieldValue(
    'orcamentoItemVeiculo.tipoOperacao'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Select
              label="Tipo de Opeação"
              name="orcamentoItemVeiculo.tipoOperacao"
              options={FormatarEnum({
                enumObj: VeiculoTipoOperacaoEnum,
                nullavel: true,
              })}
              onChange={refresh}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Chassi"
              name="orcamentoItemVeiculo.chassi"
              placeholder="Chassi"
              maxLength={17}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Número do Motor"
              name="orcamentoItemVeiculo.numeroMotor"
              placeholder="Número do Motor"
              maxLength={21}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Cor (Montadora)"
              name="orcamentoItemVeiculo.corMontadora"
              placeholder="Cor (Montadora)"
              maxLength={4}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Descrição da Cor"
              name="orcamentoItemVeiculo.descricaoCor"
              placeholder="Descrição da Cor"
              maxLength={40}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Select
              label="Código da Cor"
              name="orcamentoItemVeiculo.codigoCorDenatran"
              options={FormatarEnum({
                enumObj: VeiculoCodigoCorEnum,
                nullavel: true,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Tip de Pintura"
              name="orcamentoItemVeiculo.tipoPintura"
              placeholder="Tip de Pintura"
              maxLength={1}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <InputInteiroNulavel
              label="Ano Modelo"
              name="orcamentoItemVeiculo.anoModelo"
              placeholder="Ano Modelo"
              maxLength={4}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <InputInteiroNulavel
              label="Ano Fabricação"
              name="orcamentoItemVeiculo.anoFabricacao"
              placeholder="Ano Fabricação"
              maxLength={4}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Serial (Série)"
              name="orcamentoItemVeiculo.serie"
              placeholder="Serial (Série)"
              maxLength={9}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Select
              label="Restrição"
              name="orcamentoItemVeiculo.restricao"
              options={FormatarEnum({
                enumObj: VeiculoRestricaoEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Código Marca Modelo"
              name="orcamentoItemVeiculo.codigoMarcaModelo"
              placeholder="Código Marca Modelo"
              maxLength={6}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Placa do Veículo"
              name="orcamentoItemVeiculo.placa"
              placeholder="Placa do Veículo"
              maxLength={7}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Renavam"
              name="orcamentoItemVeiculo.renavam"
              placeholder="Renavam"
              maxLength={11}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemVeiculoTab;
