import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-producao/impressao';

interface IOrdemProducaoImpressaoComunicadorShow {
  id: string;
}

class OrdemProducaoImpressaoComunicador {
  public async show(
    params: IOrdemProducaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemProducaoImpressaoComunicador();
