import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoUsuarioEnum } from '@elogestor/util';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTagRepresentante from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRepresentante';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';

const RepresentantesTab: React.FC = () => {
  const { tipoUsuario: tipoUsuarioLogado } = UsePermissoes();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagRepresentante
              name="listaUsuarioEmpresaRepresentante"
              label="Representantes"
              placeholder="Representantes"
              disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RepresentantesTab;
