import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import BandeiraCartaoAutoCompleteComunicador from '../../../../../../Comunicador/Financeiro/Geral/BandeiraCartao/AutoComplete/BandeiraCartaoAutoCompleteComunicador';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../../../AutoComplete/AutoCompleteBase';
import { Item } from '../../../../AutoComplete/AutoCompleteBase/styles';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IBandeiraCartao {
  id: string;
  descricao: string;
}

export interface IOnChangeItemAtualAutoCompleteBandeiraCartaoEvent {
  itemAtual: IBandeiraCartao | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteBandeiraCartaoEvent {
  input: HTMLInputElement | null;
}

interface IInputTabelaAutoCompleteBandeiraCartaoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valueRef?: React.RefObject<IInputAutoCompleteBaseRef>;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteBandeiraCartaoEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteBandeiraCartaoEvent
  ) => void | Promise<void>;
  obterRef?: (inputRef: React.RefObject<IInputAutoCompleteBaseRef>) => void;
}

export interface IInputTabelaAutoCompleteBandeiraCartaoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputTabelaAutoCompleteBandeiraCartao: React.ForwardRefRenderFunction<
  IInputTabelaAutoCompleteBandeiraCartaoRef,
  IInputTabelaAutoCompleteBandeiraCartaoProps
> = (
  {
    valueRef,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    obterRef,
    ...rest
  },
  ref
) => {
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef>(null);
  const quantidadeAlterado = useRef(0);

  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    if (obterRef) {
      if (valueRef) {
        obterRef(valueRef);
      } else {
        obterRef(autoCompleteRef);
      }
    }
  }, [obterRef, valueRef]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });
    },
    [onChangeItemAtual]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div
        style={{ width: '100%', display: 'flex' }}
        onKeyDown={(e) => {
          if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
          }
        }}
      >
        <AutoCompleteBase
          ref={valueRef || autoCompleteRef}
          setPesquisando={(valor) => {
            setPesquisando(valor);
          }}
          filtrarItens={async ({ valor, offset }) => {
            setPesquisando(true);

            try {
              const response =
                await BandeiraCartaoAutoCompleteComunicador.index({
                  params: {
                    offset,
                    limite: 5,
                    texto: valor,
                    ativo,
                    listaIdParaRemover,
                  },
                });

              setPesquisando(false);
              return response;
            } catch (err) {
              TratarErros(err);
              setPesquisando(false);
              return false;
            }
          }}
          obterChaveUnica={(item: IBandeiraCartao) => {
            return item.id;
          }}
          obterLabel={(item: IBandeiraCartao) => {
            if (!item.descricao) return '';

            return item.descricao;
          }}
          personalizarItem={({ selecionado, navegacao, item }) => {
            return (
              <Item $selecionado={selecionado} $navegacao={navegacao}>
                {item.descricao}
              </Item>
            );
          }}
          onChangeItemAtual={handleChangeItemAtual}
          onChangeTexto={handleChangeTexto}
          inputProps={rest}
        />
      </div>
    </div>
  );
};

export default forwardRef(InputTabelaAutoCompleteBandeiraCartao);
