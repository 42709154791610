import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar: '/suprimentos/produtos/produto/engenharia-codigo-referencia',
        novo: '/suprimentos/produtos/produto',
        alterar: '/suprimentos/produtos/produto/engenharia-codigo-referencia',
        excluir: '/suprimentos/produtos/produto/engenharia-codigo-referencia',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
