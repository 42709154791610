/* eslint-disable default-case */
import {
  EstoqueIndicadorTipoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import LocalEstoqueObterPorEstoqueCombinacaComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/LocalEstoque/Comunicador/LocalEstoqueObterPorEstoqueCombinacaComunicador';
import LoteObterPorEstoqueCombinacaComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/Lote/Comunicador/LoteObterPorEstoqueCombinacaComunicador';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import SelecaoItemEstoqueLocalEstoqueDetalhe from '../Detalhe/ItemEstoqueTab/SelecaoItemEstoqueLocalEstoqueDetalhe';
import ISelecaoItemEstoqueLocalEstoque from '../Detalhe/ItemEstoqueTab/SelecaoItemEstoqueLocalEstoqueDetalhe/Interface/ISelecaoItemEstoqueLocalEstoque';
import SelecaoItemEstoqueLoteDetalhe from '../Detalhe/ItemEstoqueTab/SelecaoItemEstoqueLoteDetalhe';
import ISelecaoItemEstoqueLote from '../Detalhe/ItemEstoqueTab/SelecaoItemEstoqueLoteDetalhe/Interface/ISelecaoItemEstoqueLote';
import { UseItemOrdemServico } from './ItemOrdemServicoHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemEstoqueContext {
  validador: boolean;

  selecionarItemEstoqueLocalEstoque(limparLista?: boolean): void;
}

const ItemEstoqueContext = createContext<IItemEstoqueContext>(
  {} as IItemEstoqueContext
);

const ItemEstoqueHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, setLoading } = UseListaDetalheForm();
  const { listaLocalEstoque, setListaLocalEstoque } = UseItemOrdemServico();
  const { redirecionar } = UseRedirecionar();
  const parametros = UseParametros();

  const [
    showModalSelecaoItemEstoqueLocalEstoque,
    setShowModalSelecaoItemEstoqueLocalEstoque,
  ] = useState(false);

  const [listaItemEstoqueLocalEstoque, setListaItemEstoqueLocalEstoque] =
    useState<ISelecaoItemEstoqueLocalEstoque[]>([]);

  const [
    itemEstoqueLocalEstoqueSelecionado,
    setItemEstoqueLocalEstoqueSelecionado,
  ] = useState<ISelecaoItemEstoqueLocalEstoque | null>(null);

  const [showModalSelecaoItemEstoqueLote, setShowModalSelecaoItemEstoqueLote] =
    useState(false);

  const [listaItemEstoqueLote, setListaItemEstoqueLote] = useState<
    ISelecaoItemEstoqueLote[]
  >([]);

  const handleOnSelecionarLote = useCallback(
    async (
      loteSelecionado: ISelecaoItemEstoqueLote,
      localEstoqueSelecionado?: ISelecaoItemEstoqueLocalEstoque
    ) => {
      const pessoa = formPrincipal.formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );

      const quantidadeEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque'
      );
      const indicadorTipoEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.indicadorTipoEstoque'
      );
      const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.porContaOrdemTerceiro'
      );
      const pessoaTerceiro = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemEstoque.pessoaTerceiro'
      );

      const localEstoque =
        localEstoqueSelecionado || itemEstoqueLocalEstoqueSelecionado;

      let quantidade = quantidadeEstoque;
      if (!parametros.PermitirEstoqueNegativo) {
        if (loteSelecionado.saldo <= 0) return;

        if (loteSelecionado.saldo < quantidadeEstoque)
          quantidade = loteSelecionado.saldo;
      }

      switch (indicadorTipoEstoque) {
        case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
          setListaLocalEstoque([
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
            },
          ]);
          break;

        case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
          setListaLocalEstoque([
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
            },
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
              pessoaTerceiro: {
                id:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.id
                    : pessoa.id,
                codigo:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.codigo
                    : pessoa.codigo,
                nomeRazaoSocial:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.nomeRazaoSocial
                    : pessoa.nomeRazaoSocial,
              },
            },
          ]);
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
          setListaLocalEstoque([
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
              pessoaTerceiro: {
                id:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.id
                    : pessoa.id,
                codigo:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.codigo
                    : pessoa.codigo,
                nomeRazaoSocial:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.nomeRazaoSocial
                    : pessoa.nomeRazaoSocial,
              },
            },
          ]);
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
          setListaLocalEstoque([
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
              pessoaTerceiro: {
                id:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.id
                    : pessoa.id,
                codigo:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.codigo
                    : pessoa.codigo,
                nomeRazaoSocial:
                  porContaOrdemTerceiro && pessoaTerceiro
                    ? pessoaTerceiro.nomeRazaoSocial
                    : pessoa.nomeRazaoSocial,
              },
            },
            {
              id: '',
              localEstoque: {
                id: String(localEstoque?.id),
                descricao: String(localEstoque?.descricao),
              },
              lote: {
                id: loteSelecionado.id,
                codigo: loteSelecionado.codigo,
              },
              quantidade,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
              indicadorTipoEstoque:
                EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
              pessoaTerceiro: {
                id: pessoa.id,
                codigo: pessoa.codigo,
                nomeRazaoSocial: pessoa.nomeRazaoSocial,
              },
            },
          ]);
          break;
      }
    },
    [
      formPrincipal.formRef,
      formRefDetalhe,
      itemEstoqueLocalEstoqueSelecionado,
      parametros.PermitirEstoqueNegativo,
      setListaLocalEstoque,
    ]
  );

  const handleSelecionarItemEstoqueLote = useCallback(
    async (localEstoqueSelecionado: ISelecaoItemEstoqueLocalEstoque) => {
      const pessoa = formPrincipal.formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );
      const dataHoraEmissao =
        formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const indicadorTipoEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.indicadorTipoEstoque'
      );
      const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.porContaOrdemTerceiro'
      );
      const pessoaTerceiro = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemEstoque.pessoaTerceiro'
      );

      try {
        setLoading(true);

        let indicadorEstoqueAtualizado = indicadorTipoEstoque;
        if (
          indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
        ) {
          indicadorEstoqueAtualizado =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
        }
        if (
          indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
        ) {
          indicadorEstoqueAtualizado =
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
        }

        const response = await LoteObterPorEstoqueCombinacaComunicador.index({
          idPessoa:
            porContaOrdemTerceiro && pessoaTerceiro
              ? pessoaTerceiro.id
              : pessoa.id,
          idProduto: produto.id,
          idLocalEstoque: localEstoqueSelecionado.id,
          indicadorTipoEstoque: indicadorEstoqueAtualizado,
          dataHoraEmissao,
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
        });

        if (response.length === 1) {
          setListaItemEstoqueLote(response);
          handleOnSelecionarLote(response[0], localEstoqueSelecionado);
        } else if (response.length > 1) {
          setListaItemEstoqueLote(response);
          setShowModalSelecaoItemEstoqueLote(true);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [
      formPrincipal.formRef,
      formRefDetalhe,
      handleOnSelecionarLote,
      redirecionar,
      setLoading,
    ]
  );

  const handleOnSelecionarLocalEstoque = useCallback(
    async (localEstoqueSelecionado: ISelecaoItemEstoqueLocalEstoque) => {
      setItemEstoqueLocalEstoqueSelecionado(localEstoqueSelecionado);

      const pessoa = formPrincipal.formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );

      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const quantidadeEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque'
      );
      const indicadorTipoEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.indicadorTipoEstoque'
      );
      const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.porContaOrdemTerceiro'
      );
      const pessoaTerceiro = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemEstoque.pessoaTerceiro'
      );

      if (produto.produtoEstoque.controlarLote) {
        handleSelecionarItemEstoqueLote(localEstoqueSelecionado);
      } else {
        let quantidade = quantidadeEstoque;
        if (!parametros.PermitirEstoqueNegativo) {
          if (localEstoqueSelecionado.saldo <= 0) return;

          if (localEstoqueSelecionado.saldo < quantidadeEstoque)
            quantidade = localEstoqueSelecionado.saldo;
        }

        switch (indicadorTipoEstoque) {
          case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
            setListaLocalEstoque([
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              },
            ]);
            break;

          case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
            setListaLocalEstoque([
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
              },
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                pessoaTerceiro: {
                  id:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.id
                      : pessoa.id,
                  codigo:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.codigo
                      : pessoa.codigo,
                  nomeRazaoSocial:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.nomeRazaoSocial
                      : pessoa.nomeRazaoSocial,
                },
              },
            ]);
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
            setListaLocalEstoque([
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                pessoaTerceiro: {
                  id:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.id
                      : pessoa.id,
                  codigo:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.codigo
                      : pessoa.codigo,
                  nomeRazaoSocial:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.nomeRazaoSocial
                      : pessoa.nomeRazaoSocial,
                },
              },
            ]);
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
            setListaLocalEstoque([
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                pessoaTerceiro: {
                  id:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.id
                      : pessoa.id,
                  codigo:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.codigo
                      : pessoa.codigo,
                  nomeRazaoSocial:
                    porContaOrdemTerceiro && pessoaTerceiro
                      ? pessoaTerceiro.nomeRazaoSocial
                      : pessoa.nomeRazaoSocial,
                },
              },
              {
                id: '',
                localEstoque: {
                  id: localEstoqueSelecionado.id,
                  descricao: localEstoqueSelecionado.descricao,
                },
                quantidade,
                tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                indicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,

                pessoaTerceiro: {
                  id: pessoa.id,
                  codigo: pessoa.codigo,
                  nomeRazaoSocial: pessoa.nomeRazaoSocial,
                },
              },
            ]);
            break;
        }
      }
    },
    [
      formPrincipal.formRef,
      formRefDetalhe,
      handleSelecionarItemEstoqueLote,
      parametros.PermitirEstoqueNegativo,
      setListaLocalEstoque,
    ]
  );

  const handleSelecionarItemEstoqueLocalEstoque = useCallback(
    async (limparLista?: boolean) => {
      const pessoa = formPrincipal.formRef.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );
      const dataHoraEmissao =
        formPrincipal.formRef.current?.getFieldValue('dataHoraEmissao');

      const movimentarEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.movimentarEstoque'
      );
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const quantidadeEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.quantidadeEstoque'
      );
      const indicadorTipoEstoque = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.indicadorTipoEstoque'
      );
      const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemEstoque.porContaOrdemTerceiro'
      );
      const pessoaTerceiro = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemEstoque.pessoaTerceiro'
      );

      if (!movimentarEstoque) return;

      if (listaLocalEstoque.length === 1 && !limparLista) {
        if (quantidadeEstoque !== 0)
          setListaLocalEstoque([
            {
              ...listaLocalEstoque[0],
              id: '',
              quantidade: quantidadeEstoque,
            },
          ]);
        else setListaLocalEstoque([]);
      } else if (
        produto &&
        quantidadeEstoque > 0 &&
        indicadorTipoEstoque &&
        (listaLocalEstoque.length === 0 || limparLista)
      ) {
        try {
          setLoading(true);

          let indicadorEstoqueAtualizado = indicadorTipoEstoque;
          if (
            indicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
          ) {
            indicadorEstoqueAtualizado =
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          }
          if (
            indicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
          ) {
            indicadorEstoqueAtualizado =
              EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
          }

          const response =
            await LocalEstoqueObterPorEstoqueCombinacaComunicador.index({
              idPessoa:
                porContaOrdemTerceiro && pessoaTerceiro
                  ? pessoaTerceiro.id
                  : pessoa.id,
              idProduto: produto.id,
              indicadorTipoEstoque: indicadorEstoqueAtualizado,
              dataHoraEmissao,
              tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
            });

          if (response.length === 1) {
            setListaItemEstoqueLocalEstoque(response);
            handleOnSelecionarLocalEstoque(response[0]);
          } else if (response.length > 1) {
            setListaItemEstoqueLocalEstoque(response);
            setShowModalSelecaoItemEstoqueLocalEstoque(true);
          }

          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }
    },
    [
      formPrincipal.formRef,
      formRefDetalhe,
      handleOnSelecionarLocalEstoque,
      listaLocalEstoque,
      redirecionar,
      setListaLocalEstoque,
      setLoading,
    ]
  );

  return (
    <ItemEstoqueContext.Provider
      value={{
        selecionarItemEstoqueLocalEstoque:
          handleSelecionarItemEstoqueLocalEstoque,

        validador: true,
      }}
    >
      {children}

      {showModalSelecaoItemEstoqueLocalEstoque && (
        <SelecaoItemEstoqueLocalEstoqueDetalhe
          listaLocalEstoque={listaItemEstoqueLocalEstoque}
          onSelecionarLocalEstoque={(item) => {
            handleOnSelecionarLocalEstoque(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoItemEstoqueLocalEstoque(false);
          }}
        />
      )}

      {showModalSelecaoItemEstoqueLote && (
        <SelecaoItemEstoqueLoteDetalhe
          listaLote={listaItemEstoqueLote}
          onSelecionarLote={(item) => {
            handleOnSelecionarLote(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoItemEstoqueLote(false);
          }}
        />
      )}
    </ItemEstoqueContext.Provider>
  );
};

function UseItemEstoque(): Omit<IItemEstoqueContext, 'validador'> {
  const context = useContext(ItemEstoqueContext);

  if (!context.validador) {
    throw new Error('UseItemEstoque deve ser usado com um ItemEstoqueHook');
  }

  return context;
}

export { ItemEstoqueHook, UseItemEstoque };
