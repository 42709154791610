"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarDataSemTimeZone;
function FormatarDataSemTimeZone(value) {
    const dataCompleta = value.toString();
    if (dataCompleta.match(/T*Z$/)) {
        const data = dataCompleta.split('T')[0];
        const parts = data.split('-');
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
    const parts = dataCompleta.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
}
