import React from 'react';
import { SubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/SubHeaderContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const SubHeaderHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <SubHeaderProvider
      value={{
        subHeaderButtonsVisivel: {
          ExcluirVisivel: true,
          DuplicarVisivel: true,
          DuplicarSalvarVisivel: false,
          SalvarContinuarVisivel: true,
          SalvareFecharVisivel: true,
          SalvareNovoVisivel: true,
          VoltarVisivel: true,
          SalvarVisivel: true,
        },
        subHeaderButtonsHabilitado: {
          ExcluirHabilitado: true,
          DuplicarHabilitado: true,
          DuplicarSalvarHabilitado: true,
          SalvarContinuarHabilitado: true,
          SalvareFecharHabilitado: true,
          SalvareNovoHabilitado: true,
          SalvareHabilitado: true,
        },
      }}
    >
      {children}
    </SubHeaderProvider>
  );
};

export default SubHeaderHook;
