import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/gerar';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicadorStore {
  params: any;
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicador {
  public async store(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicador();
