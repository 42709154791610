/* eslint-disable default-case */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import {
  FormatarEnum,
  IContaCategoriaLista,
  TipoContaEnum,
  TipoLancamentoEnum,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonAtualizarDados, Container, UltimaTr } from './styles';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Select from '../../../../../../../../Componentes/Select';
import InputDecimal from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseContaListaCategoria } from '../../../Hooks/ContaListaCategoriaHook';
import InputAutoCompleteCategoria from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCategoria';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseContaCategoria } from '../../../Hooks/ContaCategoriaHook';
import InputSwitch from '../../../../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagFormaPagamento from '../../../../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheCategoria: React.FC<IListaDetalheGeral> = ({ name }) => {
  const formPrincipal = UseForm();
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { adicionarListaCategoria } = UseContaCategoria();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();
  const location = useLocation();

  const isAdiantamentoAntecipacao =
    formPrincipal.formRef.current?.getFieldValue('isAdiantamentoAntecipacao');

  const revertida = formPrincipal.formRef.current?.getFieldValue('revertida');

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
    handleCarregarDados,
  } = UseListaDetalheForm();
  const { calcularValorTotalCategoria, valorTotalCategoria } =
    UseContaListaCategoria();

  const valorRef = useRef<IContaCategoriaLista[]>([]);
  const [listaValor, setListaValor] = useState<IContaCategoriaLista[]>([]);

  const handleObterValorSugerido = useCallback(async () => {
    const listaContaParcela =
      formPrincipal.formRef?.current?.getFieldValue('listaContaParcela');
    const listaContaCategoria = formPrincipal.formRef?.current?.getFieldValue(
      'listaContaCategoria'
    );
    const tipoLancamento =
      formRefLista.current?.getFieldValue('tipoLancamento');

    const somaDosValoresDosTipos: number = listaContaCategoria
      .reduce((acc: number, item: any) => {
        if (tipoLancamento === item.tipoLancamento) {
          acc += Number(item.valor);
        }
        return acc;
      }, 0)
      .Arredondar();

    function calcularValoresTotais(campo: string): void {
      const valorTotal: number = listaContaParcela
        .reduce((acc: number, item: any) => {
          acc += Number(item[`${campo}`]);
          return acc;
        }, 0)
        .Arredondar();

      const valorSugerido = valorTotal - somaDosValoresDosTipos;
      formRefLista.current?.setFieldValue(
        'valor',
        valorSugerido > 0 ? valorSugerido : 0
      );
    }

    switch (tipoLancamento) {
      case TipoLancamentoEnum.geral: {
        const valorConta =
          formPrincipal.formRef.current?.getFieldValue('valor');

        const valorSugerido = valorConta - somaDosValoresDosTipos;
        formRefLista.current?.setFieldValue(
          'valor',
          valorSugerido > 0 ? valorSugerido : 0
        );
        break;
      }

      case TipoLancamentoEnum.jurosSoma: {
        calcularValoresTotais('valorJurosSoma');
        break;
      }

      case TipoLancamentoEnum.descontosSubtrai: {
        calcularValoresTotais('valorDescontoSubtrai');
        break;
      }

      case TipoLancamentoEnum.multaSoma: {
        calcularValoresTotais('valorMultaSoma');
        break;
      }

      case TipoLancamentoEnum.outrasDespesasSoma: {
        calcularValoresTotais('valorOutrasDespesasSoma');
        break;
      }

      case TipoLancamentoEnum.outrasDespesasSubtrai: {
        calcularValoresTotais('valorOutrasDespesasSubtrai');
        break;
      }

      case TipoLancamentoEnum.despesasCartaoSubtrai: {
        calcularValoresTotais('valorDespesasCartaoSubtrai');
        break;
      }

      case TipoLancamentoEnum.despesasCartorioSoma: {
        calcularValoresTotais('valorDespesasCartorioSoma');
        break;
      }

      case TipoLancamentoEnum.despesasEnvioSoma: {
        calcularValoresTotais('valorDespesasEnvioSoma');
        break;
      }

      case TipoLancamentoEnum.comissoesPagasSoma: {
        calcularValoresTotais('valorComissoesSoma');
        break;
      }

      case TipoLancamentoEnum.despesasEnvioSubtrai: {
        calcularValoresTotais('valorDespesasEnvioSubtrai');
        break;
      }

      case TipoLancamentoEnum.comissoesPagasSubtrai: {
        calcularValoresTotais('valorComissoesSubtrai');
        break;
      }
    }
  }, [formPrincipal.formRef, formRefLista]);

  const handleObterTipoCategoria = useCallback(async () => {
    const tipo = formPrincipal.formRef.current?.getFieldValue('tipo');
    const tipoConta = tipo === TipoContaEnum.receber ? 1 : -1;

    const tipoLancamentoCategoria =
      formRefLista.current?.getFieldValue('tipoLancamento')[1] === '-' ? -1 : 1;

    const tipoCategoria = tipoConta * tipoLancamentoCategoria;
    formRefLista.current?.setFieldValue('tipo', tipoCategoria);
  }, [formPrincipal.formRef, formRefLista]);

  const handleCalcularPercentualCategoria = useCallback(
    (valorCategoria: number) => {
      const valorConta = formPrincipal.formRef.current?.getFieldValue('valor');

      let percentualCategoria = 0;
      if (valorConta > 0) {
        percentualCategoria =
          (Number(valorCategoria) / Number(valorConta)) * 100;
      }

      return percentualCategoria;
    },
    [formPrincipal.formRef]
  );

  const handleAdicionar = useCallback(async () => {
    handleObterTipoCategoria();
    const data = formRefLista.current?.getDataDuplicar();

    const listaContaCategoriaFormaPagamento =
      formRefLista.current?.getFieldValue('listaContaCategoriaFormaPagamento');

    const percentualCategoria = handleCalcularPercentualCategoria(data.valor);

    data.percentualCategoria = percentualCategoria;
    data.listaContaCategoriaFormaPagamento.listaValor =
      listaContaCategoriaFormaPagamento.listaValor;

    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [
    formRefLista,
    handleCalcularPercentualCategoria,
    handleObterTipoCategoria,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IContaCategoriaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleCarregarDados({});
  }, [handleCarregarDados]);

  useEffect(() => {
    if (listaValor) calcularValorTotalCategoria(listaValor);
  }, [calcularValorTotalCategoria, listaValor]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <InputHiddenHtml name="tipo" />

        <Divisor>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <ButtonAtualizarDados
                type="button"
                style={{
                  minWidth: telaGrande ? 450 : '',
                  height: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                onClick={async () => {
                  let atualizar = false;

                  // #region Remover Categorias

                  const listaContaCategoriaRemover =
                    formPrincipal.formRef.current?.getFieldValue(
                      'listaContaCategoria'
                    );
                  const listaContaParcelaRemover =
                    formPrincipal.formRef.current?.getFieldValue(
                      'listaContaParcela'
                    );

                  if (
                    listaContaCategoriaRemover &&
                    listaContaCategoriaRemover.length > 0
                  ) {
                    const resposta = await abrirJanela({
                      titulo: <h2>Confirmação</h2>,
                      mensagem: (
                        <span style={{ fontSize: 20 }}>
                          Deseja remover todas as categorias e salvar?
                        </span>
                      ),
                    });

                    if (resposta) {
                      atualizar = true;

                      for (
                        let i = 0;
                        i < listaContaCategoriaRemover.length;
                        i++
                      ) {
                        const contaCategoria = listaContaCategoriaRemover[i];

                        if (listaContaParcelaRemover.length > 0) {
                          listaContaParcelaRemover.forEach((parcela: any) => {
                            if (parcela.listaContaParcelaCategoria.length > 0) {
                              const indexArrayParcelaCategoria =
                                parcela.listaContaParcelaCategoria
                                  .map((e: any) => e.idCategoria)
                                  .indexOf(contaCategoria.idCategoria);

                              if (indexArrayParcelaCategoria > -1) {
                                parcela.listaContaParcelaCategoria.splice(
                                  indexArrayParcelaCategoria,
                                  1
                                );
                              }
                            }

                            if (
                              parcela.listaContaParcelaLiquidacao.length > 0
                            ) {
                              parcela.listaContaParcelaLiquidacao.forEach(
                                (liquidacao: any) => {
                                  const indexArrayLiquidacaoCategoria =
                                    liquidacao.listaMovimentoPortadorCategoria
                                      .map((e: any) => e.idCategoria)
                                      .indexOf(contaCategoria.idCategoria);

                                  if (indexArrayLiquidacaoCategoria > -1) {
                                    liquidacao.listaMovimentoPortadorCategoria.splice(
                                      indexArrayLiquidacaoCategoria,
                                      1
                                    );
                                  }
                                }
                              );
                            }
                          });
                        }
                      }

                      formPrincipal.formRef.current?.setFieldValue(
                        'listaContaCategoria',
                        []
                      );
                      formPrincipal.formRef.current?.setFieldValue(
                        'listaCategoriaMudou',
                        true
                      );
                    }
                  } else {
                    atualizar = true;
                  }

                  // #endregion Remover Categorias

                  formPrincipal.refresh();
                  await Sleep(50);

                  if (atualizar) {
                    const data =
                      formPrincipal.formRef.current?.getData() as any;
                    data.naoValidaCategoriaInformada = true;

                    const { erro, id } = await formPrincipal.handleSubmit(data);
                    if (erro) return;

                    if (
                      location.pathname.includes(
                        'contas-receber-pagar/detalhe'
                      ) &&
                      !location.pathname.includes(id)
                    ) {
                      navigate(
                        `${
                          location.pathname
                        }/${formPrincipal.getIdDetalheRegistro()}`,
                        { replace: true }
                      );

                      await Sleep(500);
                    }

                    await adicionarListaCategoria();
                  }
                }}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <FiRefreshCcw />
                  <span style={{ marginLeft: 10 }}>
                    Salvar e Atualizar Categorias Conforme Regras
                  </span>
                </TextoLoading>
              </ButtonAtualizarDados>
            </Col>
          </Row>

          <Row>
            <Col xl={4} lg={4} sm={12}>
              <InputAutoCompleteCategoria
                name="idCategoria"
                label="Categoria"
                placeholder="Categoria"
                nomeObjeto="categoria"
                onBlurInput={() => {
                  handleObterValorSugerido();
                }}
                filtroTipoSinteticaAnalitica={
                  TipoSinteticaAnaliticaEnum.analitica
                }
              />
            </Col>
            <Col xl={4} lg={4} sm={12}>
              <Select
                name="tipoLancamento"
                label="Tipo Lançamento"
                options={FormatarEnum({
                  enumObj: TipoLancamentoEnum,
                })}
                onChange={() => {
                  handleObterValorSugerido();
                }}
              />
            </Col>
            <Col xl={4} lg={4} sm={12}>
              <InputDecimal
                name="valor"
                label="Valor"
                casasDecimais={2}
                casasInteiras={18}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={2} md={3} sm={12}>
              <InputSwitch
                name="avista"
                label="A Vista"
                ativo="Sim"
                inativo="Não"
              />
            </Col>
            <Col xl={8} lg={6} md={4} sm={12}>
              <InputAutoCompleteTagFormaPagamento
                label="Formas de Pagamento"
                name="listaContaCategoriaFormaPagamento"
              />
            </Col>
            <Col
              xl={2}
              lg={4}
              md={5}
              sm={12}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <BtnPadraoButton
                type="button"
                onClick={handleAdicionar}
                className="btn-padrao btn-verde-claro btn-adicionar"
                disabled={
                  !permissao?.inclui ||
                  formPrincipal.loading ||
                  loading ||
                  revertida
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAdd />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '30%' }}>
                    <span>Categoria</span>
                  </th>
                  <th className="lista-texto" style={{ width: '15%' }}>
                    <span>Tipo Lançamento</span>
                  </th>
                  <th className="lista-valor" style={{ width: '15%' }}>
                    <span>Valor</span>
                  </th>
                  <th className="lista-booleano" style={{ width: '15%' }}>
                    <span>Obtida por Regra</span>
                  </th>
                  <th className="lista-booleano" style={{ width: '10%' }}>
                    <span>A Vista</span>
                  </th>
                  <th className="lista-booleano" style={{ width: '15%' }}>
                    <span>Forma Pagamento</span>
                  </th>
                  <th className="lista-texto">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item, index) => {
                  return (
                    <tr key={v4()}>
                      <td className="liSsta-texto">
                        {`${item.categoria.codigo} - ${item.categoria.descricao}`}
                      </td>
                      <td className="lista-texto">{item.tipoLancamento}</td>
                      <td className="lista-valor">
                        {Number(item.valor).FormatarParaPtBr()}
                      </td>
                      <td
                        className="lista-booleano"
                        style={{ color: item.obtidaPorRegra ? 'green' : 'red' }}
                      >
                        {item.obtidaPorRegra ? 'Sim' : 'Não'}
                      </td>
                      <td
                        className="lista-booleano"
                        style={{ color: item.avista ? 'green' : 'red' }}
                      >
                        {item.avista === true ? 'Sim' : 'Não'}
                      </td>
                      <td className="lista-texto">
                        <div className="lista-texto">
                          {item.listaContaCategoriaFormaPagamento
                            ? item.listaContaCategoriaFormaPagamento.listaValor.map(
                                (valor: any) => {
                                  return (
                                    <div key={valor.descricao}>
                                      {`${valor.descricao};`}
                                    </div>
                                  );
                                }
                              )
                            : ''}
                        </div>
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleExcluir(String(index));
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <IoMdRemoveCircleOutline
                                  style={{ marginRight: 5 }}
                                />
                                Remover
                              </span>
                            </Dropdown.Item>
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot>
                <UltimaTr>
                  <th />
                  <th />
                  <th>
                    <div
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Valor Total</div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        {valorTotalCategoria.FormatarParaPtBr()}
                      </div>
                    </div>
                  </th>
                  <th />
                  <th />
                  <th />
                  <th />
                </UltimaTr>
              </tfoot>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheCategoria;
