import {
  IEstruturaProdutoFiltroRelatorioBackend,
  IEstruturaProdutoFiltroRelatorioFrontend,
  IEstruturaProdutoPrimeiroNivelRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  IEstruturaProdutoPrimeiroNivelCabecalhoRelatorio,
} from '@elogestor/util';
import React from 'react';
import EstruturaProdutoPrimeiroNivelRelatorioComunicador from '../../../../../../../Comunicador/Manufatura/Relatorios/Engenharia/EstruturaProduto/EstruturaProdutoPrimeiroNivelRelatorio/EstruturaProdutoPrimeiroNivelRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import EstruturaProdutoPrimeiroNivelPDF from './EstruturaProdutoPrimeiroNivelPDF';
import EstruturaProdutoPrimeiroNivelPlanilha from './EstruturaProdutoPrimeiroNivelPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const EstruturaProdutoPrimeiroNivelRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IEstruturaProdutoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IEstruturaProdutoPrimeiroNivelRelatorio | null> => {
    const filtroBack: IEstruturaProdutoFiltroRelatorioBackend = {
      codigo: filtros.codigo,
      descricao: filtros.descricao,
    };

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    const response =
      await EstruturaProdutoPrimeiroNivelRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    return response as IEstruturaProdutoPrimeiroNivelRelatorio;
  };

  function FormatarCabecalho(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend,
    cabecalho: IEstruturaProdutoPrimeiroNivelCabecalhoRelatorio
  ): IEstruturaProdutoPrimeiroNivelCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }

      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (
      filtros.listaUnidadeMedida &&
      filtros.listaUnidadeMedida.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroUnidadeMedida = filtros.listaUnidadeMedida.listaValor
        .map((valor: any) => {
          return valor.sigla;
        })
        .join(', ');
    }

    if (
      filtros.listaGrupoProdutoServico &&
      filtros.listaGrupoProdutoServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupoProdutoServico =
        filtros.listaGrupoProdutoServico.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: EstruturaProdutoPrimeiroNivelPlanilha,
      nomeArquivo: 'EstruturaProdutoPrimeiroNivel',
      valores: dados.listaDados,
      unwind: ['listaEstruturaProduto'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: EstruturaProdutoPrimeiroNivelPlanilha,
      nomeArquivo: 'EstruturaProdutoPrimeiroNivel',
      valores: dados.listaDados as any,
      unwind: ['listaEstruturaProduto'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <EstruturaProdutoPrimeiroNivelPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IEstruturaProdutoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <EstruturaProdutoPrimeiroNivelPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default EstruturaProdutoPrimeiroNivelRelatorio();
