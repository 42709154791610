"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FormatarNumberParaXml;
function FormatarNumberParaXml({ valor, casasDecimais = 2, }) {
    if (!valor) {
        const valorString = Number(0).FormatarParaPtBr({
            maximoCasasDecimais: casasDecimais,
            minimoCasasDecimais: casasDecimais,
        });
        const retorno = valorString.replace(',', '.');
        return retorno;
    }
    const valorString = Number(valor).FormatarParaPtBr({
        maximoCasasDecimais: casasDecimais,
        minimoCasasDecimais: casasDecimais,
    });
    const retorno = valorString.replace(/[.]+/g, '').replace(',', '.');
    return retorno;
}
