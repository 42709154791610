"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoMovimentoEstoqueSisdevinEnum;
(function (TipoMovimentoEstoqueSisdevinEnum) {
    TipoMovimentoEstoqueSisdevinEnum["movimentoManualEntrada"] = "Movimento Manual de Entrada";
    TipoMovimentoEstoqueSisdevinEnum["movimentoManualSaida"] = "Movimento Manual de Sa\u00EDda";
    TipoMovimentoEstoqueSisdevinEnum["nfEntrada"] = "NF Entrada";
    TipoMovimentoEstoqueSisdevinEnum["nfSaida"] = "NF Sa\u00EDda";
})(TipoMovimentoEstoqueSisdevinEnum || (TipoMovimentoEstoqueSisdevinEnum = {}));
exports.default = TipoMovimentoEstoqueSisdevinEnum;
