import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/importacao-exportacao-csv-produtos';

interface IImportacaoExportacaoCSVProdutosComunicadorIndex {
  params: {
    buscarProdutosAtivos: boolean;
  };
}

interface IImportacaoExportacaoCSVProdutosComunicadortore {
  params: any;
}

class ImportacaoExportacaoCSVProdutosComunicador {
  public async index({
    params,
  }: IImportacaoExportacaoCSVProdutosComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });
    return response.data;
  }

  public async store({
    params,
  }: IImportacaoExportacaoCSVProdutosComunicadortore): Promise<any> {
    const response = await api.post(rota, { params });
    return response.data;
  }
}

export default new ImportacaoExportacaoCSVProdutosComunicador();
