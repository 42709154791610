import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/encerrar';

interface IManifestoTransporteEncerrrarComunicadorShow {
  id: string;
}

interface IManifestoTransporteEncerrarComunicadorStore {
  id: string;
  params: any;
}

class ManifestoTransporteEncerrarComunicador {
  public async show(
    params: IManifestoTransporteEncerrrarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IManifestoTransporteEncerrarComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ManifestoTransporteEncerrarComunicador();
