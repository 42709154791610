import {
  FormatarDataHoraParaPtBr,
  ICurvaABCNotaFiscalSaidaFiltroRelatorioBackend,
  ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend,
  ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorCabecalhoRelatorio,
  ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicador from '../../../../../../../Comunicador/Comercial/Relatorios/Vendas/CurvaABCNFSaida/CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorio/CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPDF from './CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPDF';
import CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPlanilha from './CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const CurvaABCNotaFiscalSaidaSubGrupoProdutoComProdutoPorValorRelatorio =
  (): IRelatorios => {
    const ObterRelatorio = async (
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend,
      exibirToastPeriodoSemDados = true
    ): Promise<ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorio | null> => {
      const filtroBack: ICurvaABCNotaFiscalSaidaFiltroRelatorioBackend = {
        dataEmissaoInicial: filtros.dataEmissaoInicial,
        dataEmissaoFinal: filtros.dataEmissaoFinal,

        descricao: filtros.descricao,
        codigo: filtros.codigo,
      };

      if (filtros?.listaCidade) {
        filtroBack.listaIdCidade =
          filtros.listaCidade.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaSituacaoItem) {
        filtroBack.listaDescricaoSituacaoItem =
          await filtros.listaSituacaoItem.listaValor.map(
            (value) => value.descricao
          );
      }

      if (filtros?.listaColecao) {
        filtroBack.listaIdColecao =
          filtros.listaColecao.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaEstado) {
        filtroBack.listaIdEstado =
          filtros.listaEstado.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaCliente) {
        filtroBack.listaIdCliente =
          filtros.listaCliente.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaModeloDocumento) {
        filtroBack.listaIdModeloDocumento =
          filtros.listaModeloDocumento.listaValor.map((value) => value.id) ||
          [];
      }

      if (filtros?.listaProduto) {
        filtroBack.listaIdProduto =
          filtros.listaProduto.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.tipoBuscaCodigoDescricao) {
        filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
          typeof TipoBuscaCodigoDescricaoEnum,
          TipoBuscaCodigoDescricaoEnum
        >(
          TipoBuscaCodigoDescricaoEnum,
          String(filtros.tipoBuscaCodigoDescricao)
        );
      }

      if (filtros?.listaGrupo) {
        filtroBack.listaIdGrupo =
          filtros.listaGrupo.listaValor.map((value) => value.id) || [];
      }

      if (filtros?.listaRamoAtividadeProfissao) {
        filtroBack.listaIdRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor.map(
            (value) => value.id
          ) || [];
      }

      const response =
        await CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorioComunicador.index(
          { params: { filtro: filtroBack } }
        );

      if (!response.listaDados || response.listaDados.length === 0) {
        if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
        return null;
      }

      const retorno =
        response as ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorRelatorio;
      const listaDados = await Promise.all(
        retorno.listaDados.map((dados) => {
          const valoresSubGrupo = {
            ...dados.valoresSubGrupo,
            somatorioValorTotalSubGrupo: Number(
              dados.valoresSubGrupo.somatorioValorTotalSubGrupo
            ).FormatarParaPtBr(),
            somatorioPercentualSubTotalGrupo: Number(
              dados.valoresSubGrupo.somatorioPercentualSubTotalGrupo
            ).FormatarParaPtBr(),
            somatorioPercentualAcumuladoTotalSubGrupo: Number(
              dados.valoresSubGrupo.somatorioPercentualAcumuladoTotalSubGrupo
            ).FormatarParaPtBr(),
          };

          const listaAgrupadaPorGrupo = dados.listaAgrupadaPorGrupo.map(
            (grupo) => {
              const valoresGrupo = {
                ...grupo.valoresGrupo,
                somatorioValorTotalGrupo: Number(
                  grupo.valoresGrupo.somatorioValorTotalGrupo
                ).FormatarParaPtBr(),
                somatorioPercentualTotalGrupo: Number(
                  grupo.valoresGrupo.somatorioPercentualTotalGrupo
                ).FormatarParaPtBr(),
                somatorioPercentualAcumuladoTotalGrupo: Number(
                  grupo.valoresGrupo.somatorioPercentualAcumuladoTotalGrupo
                ).FormatarParaPtBr(),
              };

              const listaProduto = grupo.listaProduto.map((produto) => {
                return {
                  ...produto,
                  valorProduto: Number(produto.valorProduto).FormatarParaPtBr(),
                  percentualTotalGeral: Number(
                    produto.percentualTotalGeral
                  ).FormatarParaPtBr(),
                  percentualAcumuladoTotalGeral: Number(
                    produto.percentualAcumuladoTotalGeral
                  ).FormatarParaPtBr(),
                  percentualGrupo: Number(
                    produto.percentualGrupo
                  ).FormatarParaPtBr(),
                  percentualAcumuladoGrupo: Number(
                    produto.percentualAcumuladoGrupo
                  ).FormatarParaPtBr(),
                };
              });

              return {
                ...grupo,
                valoresGrupo,
                listaProduto,
              };
            }
          );

          return {
            ...dados,
            valoresSubGrupo,
            listaAgrupadaPorGrupo,
          };
        })
      );

      return {
        ...retorno,
        valorTotal: Number(retorno.valorTotal).FormatarParaPtBr(),
        listaDados,
      };
    };

    function FormatarCabecalho(
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend,
      cabecalho: ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorCabecalhoRelatorio
    ): ICurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorCabecalhoRelatorio {
      cabecalho.possuiFiltro = false;
      cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

      if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
        cabecalho.possuiFiltro = true;
        cabecalho.filtroDataEmissaoInicial = '--/--/--';
        cabecalho.filtroDataEmissaoFinal = '--/--/--';

        if (filtros.dataEmissaoInicial) {
          cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoInicial
          );
        }

        if (filtros.dataEmissaoFinal) {
          cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
            filtros.dataEmissaoFinal
          );
        }
      }

      if (
        filtros.listaSituacaoItem &&
        filtros.listaSituacaoItem.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      if (
        filtros.listaModeloDocumento &&
        filtros.listaModeloDocumento.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroModeloDocumento =
          filtros.listaModeloDocumento.listaValor
            .map((valor: any) => {
              return valor.codigoDescricao;
            })
            .join(', ');
      }

      if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCliente = filtros.listaCliente.listaValor
          .map((valor: any) => {
            return valor.nomeRazaoSocialCodigo;
          })
          .join(', ');
      }

      if (
        filtros.listaRamoAtividadeProfissao &&
        filtros.listaRamoAtividadeProfissao.listaValor.length > 0
      ) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroRamoAtividadeProfissao =
          filtros.listaRamoAtividadeProfissao.listaValor
            .map((valor: any) => {
              return valor.descricao;
            })
            .join(', ');
      }

      if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroEstado = filtros.listaEstado.listaValor
          .map((valor: any) => {
            return valor.siglaNome;
          })
          .join(', ');
      }

      if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCidade = filtros.listaCidade.listaValor
          .map((valor: any) => {
            return valor.cidadeUf;
          })
          .join(', ');
      }

      if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroProduto = filtros.listaProduto.listaValor
          .map((valor: any) => {
            return `${valor.codigo} - ${valor.descricao}`;
          })
          .join(', ');
      } else {
        if (filtros.codigo) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroCodigo = filtros.codigo;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }
        if (filtros.descricao) {
          cabecalho.possuiFiltro = true;

          cabecalho.filtroDescricao = filtros.descricao;
          cabecalho.filtroTipoBuscaCodigoDescricao =
            filtros.tipoBuscaCodigoDescricao;
        }
      }

      if (filtros.listaGrupo && filtros.listaGrupo.listaValor.length > 0) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroGrupo = filtros.listaGrupo.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
      }

      return cabecalho;
    }

    async function handleExportCSV(
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await CSV({
        campos: CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPlanilha,
        nomeArquivo: 'CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValor',
        valores: dados.listaDados,
        unwind: ['listaAgrupadaPorGrupo', 'listaAgrupadaPorGrupo.listaProduto'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleExportPlanilha(
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      const relatorio = await Planilha({
        campos: CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPlanilha,
        valores: dados.listaDados as any,
        nomeArquivo: 'CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValor',
        unwind: ['listaAgrupadaPorGrupo', 'listaAgrupadaPorGrupo.listaProduto'],
      });

      relatorio.AbrirArquivo();
    }

    async function handleVisualizarPdf(
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<void> {
      const dados = await ObterRelatorio(filtros);
      if (!dados) return;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPDF
            dados={dados}
          />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      filtros: ICurvaABCNotaFiscalSaidaFiltroRelatorioFrontend
    ): Promise<Blob | undefined> {
      const dados = await ObterRelatorio(filtros, false);
      if (!dados) return undefined;

      dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

      const relatorio = await PDF({
        documento: (
          <CurvaABCNotaFiscalSaidaSubGrupoGrupoComProdutoPorValorPDF
            dados={dados}
          />
        ),
      });

      return relatorio.GetBlob();
    }

    return {
      handleExportCSV,
      handleExportPlanilha,
      handleVisualizarPdf,
      handleObterBlobPdf,
    };
  };

export default CurvaABCNotaFiscalSaidaSubGrupoProdutoComProdutoPorValorRelatorio();
