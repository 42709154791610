import {
  IOrcamentoLista,
  FormatarDataHoraCompletaParaPtBr,
  SituacaoOrcamentoPedidoVendaEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import OrcamentoComunicador from '../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoComunicador';
import OrcamentoLogComunicador from '../../../../../Comunicador/Comercial/Vendas/Orcamento/Log/Comunicador/OrcamentoLogComunicador';
import ContaRevertidaComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';

const OrcamentoLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { abrirJanela } = UseConfirmacao();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await OrcamentoComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: IOrcamentoLista) => {
          let pendente = false;
          let aprovado = false;
          let rejeitado = false;
          let cancelado = false;

          if (data.situacao.includes('Pendente')) pendente = true;
          if (data.situacao.includes('Aprovado')) aprovado = true;
          if (data.situacao.includes('Rejeitado')) rejeitado = true;
          if (data.situacao.includes('Cancelado')) cancelado = true;

          return {
            id: data.id,
            numero: <div className="lista-valor">{data.numero}</div>,
            dataHoraEmissao: (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(data.dataHoraEmissao)}
              </div>
            ),
            'orcamentoPessoa.pessoa.codigoNomeRazaoSocial': (
              <div className="lista-texto">{`${data.orcamentoPessoa.pessoa.codigo} - ${data.orcamentoPessoa.pessoa.nomeRazaoSocial}`}</div>
            ),
            valorTotalProdutos: (
              <div className="lista-valor">
                {data.valorTotalProdutos
                  ? Number(data.valorTotalProdutos).FormatarParaPtBr()
                  : ''}
              </div>
            ),
            valorTotalBruto: (
              <div className="lista-valor">
                {data.valorTotalBruto
                  ? Number(data.valorTotalBruto).FormatarParaPtBr({
                      minimoCasasDecimais: 2,
                      maximoCasasDecimais: 2,
                    })
                  : ''}
              </div>
            ),
            situacao: (
              <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                {pendente && (
                  <span style={{ color: 'darkgoldenrod' }}>Pendente</span>
                )}
                {aprovado && (
                  <span>
                    {pendente && !rejeitado && <span>{' e '}</span>}
                    {pendente && rejeitado && <span>{', '}</span>}
                    <span style={{ color: 'green' }}>Aprovado</span>
                  </span>
                )}
                {rejeitado && (
                  <span>
                    {(pendente || aprovado) && <span>{' e '}</span>}
                    <span style={{ color: 'orange' }}>Rejeitado</span>
                  </span>
                )}
                {cancelado && <span style={{ color: 'red' }}>Cancelado</span>}
              </div>
            ),
            situacaoOrcamentoPedidoVenda: (
              <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                {data.situacaoOrcamentoPedidoVenda ===
                  'Parcialmente Atendido' && (
                  <span style={{ color: 'goldenrod' }}>
                    {SituacaoOrcamentoPedidoVendaEnum.parcialmenteAtendido}
                  </span>
                )}
                {data.situacaoOrcamentoPedidoVenda === 'Atendido' && (
                  <span>
                    <span style={{ color: 'green' }}>
                      {SituacaoOrcamentoPedidoVendaEnum.atendido}
                    </span>
                  </span>
                )}
                {data.situacaoOrcamentoPedidoVenda === 'Não Atendido' && (
                  <span style={{ color: 'red' }}>
                    {SituacaoOrcamentoPedidoVendaEnum.naoAtendido}
                  </span>
                )}
              </div>
            ),

            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      const retorno = await ContaRevertidaComunicador.index({
                        params: {
                          idOrcamento: data.id,
                        },
                      });

                      if (retorno.possuiContaParcelaRevertida) {
                        const resposta = await abrirJanela({
                          titulo: <h2>Confirmação</h2>,
                          mensagem: (
                            <span style={{ fontSize: 20 }}>
                              Essa conta possui uma reversão. Deseja excluir a
                              conta revertida?
                            </span>
                          ),
                          confimar: 'Sim',
                          cancelar: 'Não',
                        });

                        if (!resposta) {
                          setLoading(false);
                          return;
                        }
                      }

                      await OrcamentoComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = OrcamentoLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={permissoes.ComercialVendasOrcamento?.exclui}
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [abrirJanela, permissoes.ComercialVendasOrcamento?.exclui]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialOrcamento' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Orçamento"
          permissao={permissoes.ComercialVendasOrcamento}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return OrcamentoLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default OrcamentoLista;
