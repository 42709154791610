/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteTagBase, {
  IInputAutoCompleteTagBaseRef,
  IOnChangeListaItemAtualEvent,
  IOnAdicionarEvent,
  IOnMoverEvent,
  IOnRemoverEvent,
  IOnChangeTextoEvent,
  IFiltrarItens,
} from '../../AutoCompleteTagBase';
import UseRegisterAutoCompleteTag from '../../AutoCompleteTagBase/Hooks/UseRegisterAutoCompleteTag';
import RegraEscolhaTabelaPrecoAutoCompleteTagComunicador from '../../../../../Comunicador/Comercial/TabelaPreco/AutoCompleteTag/RegraEscolhaTabelaPrecoAutoCompleteTag/RegraEscolhaTabelaPrecoAutoCompleteTagComunicador';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IRegraEscolhaTabelaPreco {
  id: string;
  descricao: string;

  produtoRegraEscolhaTabelaPreco?: {
    aumentandoEmPercentual?: number;
    aplicandoMarkup?: number;
    diminuindoEmPercentual?: number;
    valorUnitarioBase: number;
    valorUnitarioVenda: number;
  };
}

interface IInputAutoCompleteTagRegraEscolhaTabelaPrecoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  ativo?: boolean;
  filtrarPorRegras?: boolean;
  idProduto?: string;
  listaIdParaRemover?: Array<string>;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualEvent,
    props: IOnChange
  ) => void;
  onChangeListaItemAtualAposCarregarSemClear?: (
    evento: IOnChangeListaItemAtualEvent
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarEvent) => void;
  onRemoverItem?: (evento: IOnRemoverEvent) => void;
  onMoverItem?: (evento: IOnMoverEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;
}

export interface IInputAutoCompleteTagRegraEscolhaTabelaPrecoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteTagBaseRef>;
}

const InputAutoCompleteTagRegraEscolhaTabelaPreco: React.ForwardRefRenderFunction<
  IInputAutoCompleteTagRegraEscolhaTabelaPrecoRef,
  IInputAutoCompleteTagRegraEscolhaTabelaPrecoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    ativo = true,
    filtrarPorRegras = true,
    idProduto,
    listaIdParaRemover = [],
    onChangeListaItemAtual,
    onChangeListaItemAtualAposCarregarSemClear,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    onChangeTexto,
    ...rest
  },
  ref
) => {
  const handleObterChave = useCallback((item: IRegraEscolhaTabelaPreco) => {
    return item.id;
  }, []);

  const quantidadeAlterado = useRef(0);

  const {
    autoCompleteRef,
    flags,
    error: erroUnform,
  } = UseRegisterAutoCompleteTag(
    {
      nome: name,
      handleObterChaveUnica: handleObterChave,
    },
    {
      nomeObjeto,

      setSemExecutarEvento() {
        quantidadeAlterado.current++;
      },
    }
  );

  const [erro, setErro] = useState(erroUnform);

  const handleObterLabel = useCallback((item: IRegraEscolhaTabelaPreco) => {
    if (!item.descricao) return '';

    return `${item.descricao}`;
  }, []);

  const handleChangeListaItemAtual = useCallback(
    (event: IOnChangeListaItemAtualEvent, { valorAnterior }: IOnChange) => {
      quantidadeAlterado.current++;
      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(event, { valorAnterior });
      }
      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeListaItemAtualAposCarregarSemClear
      ) {
        onChangeListaItemAtualAposCarregarSemClear(event);
      }
    },
    [flags, onChangeListaItemAtual, onChangeListaItemAtualAposCarregarSemClear]
  );

  const handleFiltrarItens = useCallback(
    async ({ valor, offset, limite, listaValorAtual }: IFiltrarItens) => {
      try {
        const response =
          await RegraEscolhaTabelaPrecoAutoCompleteTagComunicador.index({
            params: {
              offset,
              limite,
              texto: valor,
              ativo,
              idProduto,
              filtrarPorRegras,
              listaIdParaRemover: [
                ...listaIdParaRemover,
                ...listaValorAtual.map(
                  (valorAtual: IRegraEscolhaTabelaPreco) => valorAtual.id
                ),
              ],
            },
          });

        return response;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [ativo, filtrarPorRegras, idProduto, listaIdParaRemover]
  );

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    if (rest.disabled) {
      autoCompleteRef.current?.setDisabled(true);
    } else {
      autoCompleteRef.current?.setDisabled(false);
    }
  }, [autoCompleteRef, erroUnform, rest, rest.disabled]);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div style={{ display: 'flex' }}>
          <AutoCompleteTagBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            error={erro}
            filtrarItens={handleFiltrarItens}
            obterChaveUnica={handleObterChave}
            obterLabel={handleObterLabel}
            onChangeListaItemAtual={handleChangeListaItemAtual}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeTexto={onChangeTexto}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteTagRegraEscolhaTabelaPreco);
