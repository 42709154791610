const PedidoCompraPorFornecedorPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaPedidoCompraItem.pedidoCompraDataHoraEmissao',
  },

  {
    titulo: 'Número do Orçamento',
    valor: 'listaPedidoCompraItem.pedidoCompraNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaPedidoCompraItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaPedidoCompraItem.produtoDescricao',
  },

  {
    titulo: 'Informações Adicionais do Produto',
    valor: 'listaPedidoCompraItem.informacoesAdicionaisProduto',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaPedidoCompraItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaPedidoCompraItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaPedidoCompraItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaPedidoCompraItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaPedidoCompraItem.valorTotal',
  },
];

export default PedidoCompraPorFornecedorPlanilha;
