"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEtiquetaEnum;
(function (TipoEtiquetaEnum) {
    TipoEtiquetaEnum["produto"] = "Produto";
    TipoEtiquetaEnum["notaFiscal"] = "Nota Fiscal";
    TipoEtiquetaEnum["pedidoVenda"] = "Pedido de Venda";
    TipoEtiquetaEnum["pedidoVendaItem"] = "Ped. de Venda Por Item";
})(TipoEtiquetaEnum || (TipoEtiquetaEnum = {}));
exports.default = TipoEtiquetaEnum;
