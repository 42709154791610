import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IPessoaClienteRepresentanteLista } from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { UseFieldCia, FormCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputAutoCompleteRepresentante from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRepresentante';
import { TBody } from '../../../../../../../../Componentes/Styles/TBody';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheRepresentanteProps {
  name: string;
}

const PessoaClienteLista: React.FC<IListaDetalheRepresentanteProps> = ({
  name,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IPessoaClienteRepresentanteLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IPessoaClienteRepresentanteLista[]
  >([]);

  const listaId = useMemo(
    () => listaValor.map((valor) => valor.pessoaRepresentante.id),
    [listaValor]
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IPessoaClienteRepresentanteLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;
    await handleSubmit(
      { idPessoaRepresentante: data.idPessoaRepresentante },
      formRefLista.current
    );

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  return (
    <>
      <Row>
        <Col lg={10} md={12} sm={12}>
          <FormCia ref={formRefLista}>
            <InputAutoCompleteRepresentante
              label="Representante"
              name="idPessoaRepresentante"
              permitirAdicionar
              listaIdParaRemover={listaId}
            />
          </FormCia>
        </Col>

        <Col
          lg={2}
          md={12}
          sm={12}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'start',
          }}
        >
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '100%' }}>
                <span>Representante</span>
              </th>
              <th className="lista-acoes">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor &&
              listaValor.map((representante) => {
                const { pessoaRepresentante } = representante;
                return (
                  <tr
                    key={v4()}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>{pessoaRepresentante.nomeRazaoSocial}</div>
                    </td>
                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(representante.id);
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </TBody>
        </Table>
      </Tabela>
    </>
  );
};

export default PessoaClienteLista;
