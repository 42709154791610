import api from '../../../Comum/Services/Api';

const rota = 'pessoa/buscar-cnpjCpf';

interface IPessoaCnpjCpfComunicadorShow {
  params: {
    cnpjCpf: any;
    inscricaoEstadual: any;
    nomeRazaoSocial: any;
    nomeFantasia: any;
    cep: any;
    cidade: any;
    logradouro: any;
    bairro: any;
    complemento: any;
    numero: any;
    listaEmail: any;
    listaTelefone: any;
  };
}

class PessoaCnpjCpfComunicador {
  public async show(params: IPessoaCnpjCpfComunicadorShow): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaCnpjCpfComunicador();
