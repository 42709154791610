import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../../Componentes/Detalhe';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import IParametroRotaDetalhe from '../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import TerminalCaixaHook from './Hooks';
import AcoesTerminalCaixa from './AcoesTerminalCaixa';

const TerminalCaixaDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <TerminalCaixaHook>
        <SubHeader
          titulo={params.id ? 'Editar Terminal do Caixa' : 'Terminal do Caixa'}
          permissao={permissoes.ComercialVendasTerminalCaixa}
          botoes={
            <AcoesTerminalCaixa
              permissao={permissoes.ComercialVendasTerminalCaixa}
            />
          }
          isTerminalCaixa
        />
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </TerminalCaixaHook>
    </Container>
  );
};

export default TerminalCaixaDetalhe;
