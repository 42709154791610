import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import { IFrenteCaixaPdvFinalizarVendaParcelaLista } from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import InputTabelaDecimal from '../../../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import BtnAcoesButton from '../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputTabelaDate from '../../../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDate';

interface IItemParcela {
  item: IFrenteCaixaPdvFinalizarVendaParcelaLista;
  onRemoverItem: () => void;
  permiteAlterar: boolean;
  loading: boolean;
  setLoading(value: boolean): void;
  refresh: () => void;
}

export interface IItemParcelaRef {
  handleObterValores(): IFrenteCaixaPdvFinalizarVendaParcelaLista;
}

const ItemParcela: React.ForwardRefRenderFunction<
  IItemParcelaRef,
  IItemParcela
> = ({ item, onRemoverItem, permiteAlterar, loading }, ref) => {
  const valorRef = useRef<HTMLInputElement>(null);
  const dataVencimentoRef = useRef<HTMLInputElement>(null);

  const handleObterValores =
    useCallback((): IFrenteCaixaPdvFinalizarVendaParcelaLista => {
      const dataVencimento = dataVencimentoRef.current?.value;

      return {
        ...item,
        dataVencimento,
      } as any;
    }, [item]);

  useImperativeHandle(ref, () => {
    return {
      handleObterValores,
    };
  });

  const handleRemoverItem = useCallback((): void => {
    if (onRemoverItem) onRemoverItem();
  }, [onRemoverItem]);

  return (
    <tr key={v4()}>
      <td className="lista-valor">
        <div>{`${item.sequencia}/${item.quantidadeParcelas}`}</div>
      </td>
      <td className="lista-data">
        <div>
          <InputTabelaDate
            valorPadrao={item.dataVencimento}
            valueRef={dataVencimentoRef}
            style={{ height: 25 }}
            disabled={!permiteAlterar}
          />
        </div>
      </td>
      <td className="lista-valor">
        <div>
          <InputTabelaDecimal
            valorPadrao={item.valor}
            valueRef={valorRef}
            casasDecimais={2}
            onChange={(event) => {
              item.valor = event.currentTarget.value.ConverterParaNumber();
            }}
            disabled={!permiteAlterar}
          />
        </div>
      </td>
      <td className="lista-acoes">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BtnAcoesButton>
            <Dropdown.Item
              onClick={() => {
                handleRemoverItem();
              }}
              disabled={loading || !permiteAlterar}
            >
              <span style={{ display: 'flex', fontSize: 16 }}>
                <IoMdRemoveCircleOutline style={{ marginRight: 5 }} />
                Remover
              </span>
            </Dropdown.Item>
          </BtnAcoesButton>
        </div>
      </td>
    </tr>
  );
};

export default forwardRef(ItemParcela);
