/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Modal,
  Container,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { IFrenteCaixaPdvItemLista } from '@elogestor/util';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { BsBoxSeam } from 'react-icons/bs/index.mjs';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import Divisor from '../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import { UseLiberacoes } from '../../../../../../../Hooks/LiberacoesHook';
import InputAutoCompleteRegraEscolhaTabelaPreco from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRegraEscolhaTabelaPreco';
import FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador';
import ConsultaEstoqueListaHook from '../../../../../../Suprimentos/Produtos/ConsultaEstoque/Hook';
import ConsultaEstoqueLista from '../../../../../../Suprimentos/Produtos/ConsultaEstoque';

interface IItemModal {
  item: IFrenteCaixaPdvItemLista;
  onSalvarFormModal(item: IFrenteCaixaPdvItemLista): void;
  onFecharFormModal(): void;
  bloquearCampos: boolean;
}

const ItemDetalhe: React.FC<IItemModal> = ({
  item,
  onFecharFormModal,
  onSalvarFormModal,
  bloquearCampos,
}) => {
  const { formRef: formRefPrincipal } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const liberacoes = UseLiberacoes();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const [alterouDesconto, setAlterouDesconto] = useState(false);
  const [alterouAcrescimo, setAlterouAcrescimo] = useState(false);

  const [showConsultaEstoque, setShowConsultaEstoque] = useState(false);

  const idCliente = formRefPrincipal.current?.getFieldValue('idCliente');

  const regraEscolhaTabelaPreco = formRef.current?.getFieldValueNomeObjeto(
    'regraEscolhaTabelaPreco'
  );

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        quantidade: Yup.mixed()
          .nullable()
          .test({
            message: 'Quantidade deve se maior que Zero!',
            test: (value: any) => {
              if (value === 0) {
                return false;
              }
              return true;
            },
          }),
        valorUnitario: Yup.mixed()
          .nullable()
          .test({
            message: 'Valor Unitário deve se maior que Zero!',
            test: (value: any) => {
              if (value === 0) {
                return false;
              }
              return true;
            },
          }),
        valorTotalItem: Yup.mixed()
          .nullable()
          .test({
            message: 'Valor Total do Item deve se maior que Zero!',
            test: (value: any) => {
              if (value === 0) {
                return false;
              }
              return true;
            },
          }),
      });

      await schema.validate(item, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef, item]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (!(await handleValidar())) {
      return;
    }

    if (alterouDesconto) {
      formRefPrincipal.current?.setFieldValue(
        'descontoFormaCalculoPorRateio',
        false
      );
    }
    if (alterouAcrescimo) {
      formRefPrincipal.current?.setFieldValue(
        'outrasDespesasFormaCalculoPorRateio',
        false
      );
    }

    if (onSalvarFormModal) onSalvarFormModal(item);
  }, [
    alterouAcrescimo,
    alterouDesconto,
    formRefPrincipal,
    handleValidar,
    item,
    onSalvarFormModal,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, formRef, onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const percentualAcrescimo = item.subTotal
        ? (item.valorAcrescimo * 100) / item.subTotal
        : 0;
      const percentualDesconto = item.subTotal
        ? (item.valorDesconto * 100) / item.subTotal
        : 0;

      item.percentualDesconto = percentualDesconto;
      item.percentualAcrescimo = percentualAcrescimo;

      formRef.current?.setDataInicialSemExecutarEvento({
        ...item,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [item]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtualizarValores = useCallback(async () => {
    const quantidade = formRef.current?.getFieldValue('quantidade');
    const valorUnitario = formRef.current?.getFieldValue('valorUnitario');
    const percentualDesconto =
      formRef.current?.getFieldValue('percentualDesconto');
    const percentualAcrescimo = formRef.current?.getFieldValue(
      'percentualAcrescimo'
    );

    const subTotal = Number(quantidade) * Number(valorUnitario);
    const valorDesconto = (
      subTotal *
      (Number(percentualDesconto) / 100)
    ).Arredondar(2);
    const valorAcrescimo = (
      subTotal *
      (Number(percentualAcrescimo) / 100)
    ).Arredondar(2);
    const valorTotalItem = subTotal - valorDesconto + valorAcrescimo;

    item.subTotal = subTotal;
    item.valorDesconto = valorDesconto;
    item.valorAcrescimo = valorAcrescimo;
    item.valorTotalItem = valorTotalItem;

    formRef.current?.setFieldValue('subTotal', subTotal);
    formRef.current?.setFieldValue('valorDesconto', valorDesconto);
    formRef.current?.setFieldValue('valorAcrescimo', valorAcrescimo);
    formRef.current?.setFieldValue('valorTotalItem', valorTotalItem);
  }, [formRef, item]);

  const handleAtualizarValoresRegraEscolhaTabelaPreco =
    useCallback(async () => {
      try {
        setLoading(true);

        if (item.regraEscolhaTabelaPreco?.id) {
          const regraEscolhaTabelaPrecoRetorno =
            await FrenteCaixaPdvObterValorUnitarioTabelaPrecoComunicador.show({
              params: {
                idRegraEscolhaTabelaPreco: String(
                  item.regraEscolhaTabelaPreco?.id
                ),
                idProduto: item.produto.id,
              },
            });

          if (regraEscolhaTabelaPrecoRetorno) {
            const valorUnitarioTabelaPreco = Number(
              regraEscolhaTabelaPrecoRetorno.valorUnitario
            );

            item.valorUnitario = valorUnitarioTabelaPreco;
            item.valorUnitarioTabelaPreco = valorUnitarioTabelaPreco;
            item.utilizarValorTabelaPreco = true;

            formRef.current?.setSemExecutarEvento({
              valorUnitario: valorUnitarioTabelaPreco,
              valorUnitarioTabelaPreco,
              utilizarValorTabelaPreco: true,
            });
          } else {
            item.valorUnitarioTabelaPreco = 0;
            item.utilizarValorTabelaPreco = false;

            formRef.current?.setSemExecutarEvento({
              valorUnitarioTabelaPreco: 0,
              utilizarValorTabelaPreco: false,
            });
          }
        } else {
          item.valorUnitarioTabelaPreco = 0;
          item.utilizarValorTabelaPreco = false;

          formRef.current?.setSemExecutarEvento({
            valorUnitarioTabelaPreco: 0,
            utilizarValorTabelaPreco: false,
          });
        }

        await handleAtualizarValores();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [handleAtualizarValores, item]);

  const handleConsultarEstoques = useCallback(async () => {
    setShowConsultaEstoque(true);
  }, []);

  const handleConsultarEstoqueFecharFormModal = useCallback(async () => {
    setShowConsultaEstoque(false);
  }, []);

  return (
    <JanelaDetalhe
      titulo="Editar Item"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={8} md={5} sm={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <label>Produto</label>
                  <label
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      marginLeft: 10,
                    }}
                  >
                    {`${item.produto.codigo} - ${item.produto.descricao}`}
                  </label>
                </div>
              </Col>
              <Col lg={3} md={4} sm={8}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <label>Unidade</label>
                  <label
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      marginLeft: 10,
                    }}
                  >
                    {item?.produto.produtoEstoque.unidadeMedida.sigla}
                  </label>
                </div>
              </Col>
              <Col
                lg={1}
                md={3}
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id={v4()}>Consultar Estoque</Tooltip>}
                >
                  <div>
                    <Button
                      style={{ height: 35 }}
                      onClick={handleConsultarEstoques}
                    >
                      <BsBoxSeam />
                    </Button>
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Quantidade"
                  name="quantidade"
                  placeholder="Quantidade"
                  casasDecimais={4}
                  onChange={async (event) => {
                    setAlterouDesconto(true);
                    setAlterouAcrescimo(true);

                    const quantidade = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.quantidade = quantidade;
                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Valor Unitário"
                  name="valorUnitario"
                  placeholder="Valor Unitário"
                  casasDecimais={10}
                  onChange={async (event) => {
                    setAlterouDesconto(true);
                    setAlterouAcrescimo(true);

                    const valorUnitario = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.valorUnitario = valorUnitario;
                    item.utilizarValorTabelaPreco = false;
                    formRef.current?.setFieldValorInicialSemExecutarEvento(
                      'utilizarValorTabelaPreco',
                      false
                    );

                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={
                    bloquearCampos ||
                    !liberacoes.permiteAlterarValorUnitarioVendaTabelaPrecoInformada
                  }
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Sub. Total"
                  name="subTotal"
                  placeholder="Sub. Total"
                  casasDecimais={2}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Desconto (%)"
                  name="percentualDesconto"
                  placeholder="Desconto (%)"
                  casasDecimais={6}
                  onChange={async (event) => {
                    setAlterouDesconto(true);

                    const percentualDesconto = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.percentualDesconto = percentualDesconto;
                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Desconto (R$)"
                  name="valorDesconto"
                  placeholder="Desconto (R$)"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouDesconto(true);

                    const valorDesconto = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.valorDesconto = valorDesconto;

                    const percentualDesconto = item.subTotal
                      ? (valorDesconto * 100) / item.subTotal
                      : 0;
                    item.percentualDesconto = percentualDesconto;
                    formRef.current?.setFieldValue(
                      'percentualDesconto',
                      percentualDesconto
                    );

                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Acréscimo (%)"
                  name="percentualAcrescimo"
                  placeholder="Acréscimo (%)"
                  casasDecimais={6}
                  onChange={async (event) => {
                    setAlterouAcrescimo(true);

                    const percentualAcrescimo = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.percentualAcrescimo = percentualAcrescimo;
                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Acréscimo (R$)"
                  name="valorAcrescimo"
                  placeholder="Acréscimo (R$)"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouAcrescimo(true);

                    const valorAcrescimo = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.valorAcrescimo = valorAcrescimo;

                    const percentualAcrescimo = item.subTotal
                      ? (valorAcrescimo * 100) / item.subTotal
                      : 0;
                    item.percentualAcrescimo = percentualAcrescimo;
                    formRef.current?.setFieldValue(
                      'percentualAcrescimo',
                      percentualAcrescimo
                    );

                    await handleAtualizarValores();

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Total do Item"
                  name="valorTotalItem"
                  placeholder="Total do Item"
                  casasDecimais={2}
                  onChange={async (event) => {
                    setAlterouDesconto(true);
                    setAlterouAcrescimo(true);

                    const valorTotalItem = String(
                      event.currentTarget.value
                    ).ConverterParaNumber();

                    item.valorTotalItem = valorTotalItem;

                    if (valorTotalItem > item.subTotal) {
                      item.percentualDesconto = 0;
                      item.valorDesconto = 0;

                      formRef.current?.setFieldValue('percentualDesconto', 0);
                      formRef.current?.setFieldValue('valorDesconto', 0);

                      const valorAcrescimo = valorTotalItem - item.subTotal;

                      const percentualAcrescimo = item.subTotal
                        ? (valorAcrescimo * 100) / item.subTotal
                        : 0;
                      item.percentualAcrescimo = percentualAcrescimo;
                      item.valorAcrescimo = valorAcrescimo;

                      formRef.current?.setFieldValue(
                        'percentualAcrescimo',
                        percentualAcrescimo
                      );
                      formRef.current?.setFieldValue(
                        'valorAcrescimo',
                        valorAcrescimo
                      );
                    } else if (valorTotalItem < item.subTotal) {
                      item.percentualAcrescimo = 0;
                      item.valorAcrescimo = 0;

                      formRef.current?.setFieldValue('percentualAcrescimo', 0);
                      formRef.current?.setFieldValue('valorAcrescimo', 0);

                      const valorDesconto = item.subTotal - valorTotalItem;

                      const percentualDesconto = item.subTotal
                        ? (valorDesconto * 100) / item.subTotal
                        : 0;
                      item.percentualDesconto = percentualDesconto;
                      item.valorDesconto = valorDesconto;

                      formRef.current?.setFieldValue(
                        'percentualDesconto',
                        percentualDesconto
                      );
                      formRef.current?.setFieldValue(
                        'valorDesconto',
                        valorDesconto
                      );
                    } else {
                      item.percentualDesconto = 0;
                      item.valorDesconto = 0;
                      item.percentualAcrescimo = 0;
                      item.valorAcrescimo = 0;

                      formRef.current?.setFieldValue('percentualDesconto', 0);
                      formRef.current?.setFieldValue('valorDesconto', 0);
                      formRef.current?.setFieldValue('percentualAcrescimo', 0);
                      formRef.current?.setFieldValue('valorAcrescimo', 0);
                    }

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={6} sm={6}>
                <InputAutoCompleteRegraEscolhaTabelaPreco
                  label="Tabela de Preço"
                  name="idRegraEscolhaTabelaPreco"
                  nomeObjeto="regraEscolhaTabelaPreco"
                  placeholder="Tabela de Preço"
                  onChangeItemAtual={async (event) => {
                    item.regraEscolhaTabelaPreco = event.itemAtual
                      ? event.itemAtual
                      : undefined;

                    await handleAtualizarValoresRegraEscolhaTabelaPreco();
                    refresh();
                  }}
                  idPessoa={idCliente}
                  idProduto={item.produto.id}
                  contribuinte={false}
                  disabled={bloquearCampos}
                />
              </Col>
              <Col lg={4} md={6} sm={6}>
                <InputDecimalNulavel
                  label="Val. Unit. Tab. Preço"
                  placeholder="Val. Unit. Tab. Preço"
                  name="valorUnitarioTabelaPreco"
                  casasDecimais={10}
                  casasInteiras={11}
                  disabled
                />
              </Col>
              <Col lg={4} md={6} sm={6}>
                <InputSwitch
                  label="Util. Val. Tab. Preço"
                  ativo="Sim"
                  inativo="Não"
                  name="utilizarValorTabelaPreco"
                  onChange={async (event) => {
                    const utilizarValorTabelaPreco =
                      event.currentTarget.checked;

                    item.utilizarValorTabelaPreco = utilizarValorTabelaPreco;

                    const valorUnitarioTabelaPreco =
                      formRef.current?.getFieldValue(
                        'valorUnitarioTabelaPreco'
                      );

                    if (
                      utilizarValorTabelaPreco &&
                      regraEscolhaTabelaPreco &&
                      valorUnitarioTabelaPreco
                    ) {
                      formRef.current?.setFieldValue(
                        'valorUnitario',
                        valorUnitarioTabelaPreco
                      );
                      item.valorUnitario = valorUnitarioTabelaPreco;

                      await handleAtualizarValores();
                      refresh();
                    }
                  }}
                  disabled={!regraEscolhaTabelaPreco || bloquearCampos}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={6} sm={6}>
                <div
                  style={{
                    display: item?.localEstoque ? 'flex' : 'none',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <label>Local de Estoque</label>
                  <label style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    {item?.localEstoque?.descricao}
                  </label>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6}>
                <div
                  style={{
                    display: item?.lote ? 'flex' : 'none',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <label>Lote</label>
                  <label style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    {item?.lote?.codigo}
                  </label>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12}>
                <Textarea
                  style={{ minHeight: 125, marginBottom: 5 }}
                  label="Informações Adicionais do Produto"
                  name="informacoesAdicionaisProduto"
                  maxLength={1500}
                  onChange={async (event) => {
                    item.informacoesAdicionaisProduto =
                      event.currentTarget.value;

                    refresh();
                  }}
                  disabled={bloquearCampos}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickSalvar}
                disabled={bloquearCampos}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>

      {showConsultaEstoque && (
        <ConsultaEstoqueListaHook>
          <ConsultaEstoqueLista
            titulo="Consulta Estoque"
            onFecharFormModal={handleConsultarEstoqueFecharFormModal}
            loading={loading}
            setLoading={setLoading}
            idProdutoInicial={item.produto.id}
          />
        </ConsultaEstoqueListaHook>
      )}
    </JanelaDetalhe>
  );
};

export default ItemDetalhe;
