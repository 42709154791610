import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { Aside, Body, Container, Pesquisar } from './styles';
import Parametro from '../Parametro';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import * as Tipos from '../Tipos';
import Detalhe from '../Detalhe';
import Menu from '../Menu';
import MenuPai from '../Menu/MenuPai';
import MenuFilho from '../Menu/MenuFilho';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import BtnSalvar from '../../../../../Componentes/SubHeaderGeral/BtnSalvar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import Divisor from '../../../../../Componentes/Divisor';
import IParametroDados from './Interface/IParametroDados';
import IParametroPaiFormatado from './Interface/IParametroPaiFormatado';
import IParametroFilhoFormatado from './Interface/IParametroFilhoFormatado';
import IDadosRecuperados from './Interface/IDadosRecuperados';
import { UseParametros } from '../../../../../Hooks/ParametrosHook';
import ParametroComunicador from '../../../../../Comunicador/Configuracao/Parametro/Comunicador/ParametroComunicador';
import ParametroValorComunicador from '../../../../../Comunicador/Configuracao/Parametro/Comunicador/ParametroValorComunicador';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { ParametroProvider } from './Hooks/ParametroHook';

const Main: React.FC<IDadosRecuperados> = ({
  dadosRecuperados: dadosAlterados,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const [tabAtivaId, setTabAtivaId] = useState('');
  const [listaTab, setListaTab] = useState<IParametroFilhoFormatado[]>([]);
  const [parametrosLista, setParametrosLista] = useState<IParametroDados[]>([]);
  const [dadosRecuperados, setDadosRecuperados] = useState(dadosAlterados);
  const parametros = UseParametros();
  const { telaPequena, telaGrande } = UseReactSizeMeBodyHook();

  const [loading, setLoading] = useState(false);

  const handleAdicionarItemListaTab = useCallback(
    (parametro: IParametroFilhoFormatado) => {
      setListaTab((state) => {
        const parametroAchado = state.find((tab) => tab.id === parametro.id);
        if (!parametroAchado) {
          state.push(parametro);
          setTabAtivaId(parametro.id);
        } else {
          setTabAtivaId(parametroAchado.id);
        }
        return state;
      });
    },
    []
  );

  const handleCriarParametroFilhoFormatado = useCallback(
    (parametro: IParametroDados): IParametroFilhoFormatado => {
      const parametroFormatado: IParametroFilhoFormatado = {
        ...parametro,

        valorMudou() {
          const valorAtual = formRef.current?.getFieldValue(
            parametro.identificacao
          );

          const valorRef = formRef.current?.getFieldRef(
            parametro.identificacao
          );

          if (!valorRef) return false;

          return String(valorAtual) !== String(parametro.valorPadrao);
        },

        render() {
          const Componente = Tipos[parametro.tipo];
          let valor = parametro.valorPadrao;
          if (
            Object.prototype.hasOwnProperty.call(parametro, 'valorRecuperado')
          ) {
            valor = parametro.valorRecuperado;
          }

          return (
            <ParametroProvider parametroValores={parametro}>
              <Componente
                nome={parametro.identificacao}
                formRef={formRef}
                valor={valor}
              />
            </ParametroProvider>
          );
        },

        RenderMenu({ totalMargin = 0 }) {
          const valorMudou = parametroFormatado.valorMudou();

          return (
            <MenuFilho
              parametro={parametroFormatado}
              adicionarItemListaTab={handleAdicionarItemListaTab}
              valorMudou={valorMudou}
              totalMargin={totalMargin}
            />
          );
        },
      };

      return parametroFormatado;
    },
    [handleAdicionarItemListaTab]
  );

  const handleCriarParametroPaiFormatado = useCallback(
    (parametro: IParametroDados): IParametroPaiFormatado => {
      const { subParametros, ...valores } = parametro;
      const listaFilho = subParametros.map((subParametro) => {
        if (subParametro.tipo)
          return handleCriarParametroFilhoFormatado(subParametro);

        return handleCriarParametroPaiFormatado(subParametro);
      });

      const parametroFormatado: IParametroPaiFormatado = {
        ...valores,
        listaFilho,
        RenderMenu({ totalMargin = 0 }) {
          return (
            <MenuPai parametro={parametroFormatado} totalMargin={totalMargin} />
          );
        },
      };

      return parametroFormatado;
    },
    [handleCriarParametroFilhoFormatado]
  );

  const handleCriarListaParametroFormatado = useCallback(
    (listaParametrosPai: IParametroDados[]): IParametroPaiFormatado[] => {
      const listaPaiFormatado = listaParametrosPai.map(
        handleCriarParametroPaiFormatado
      );

      return listaPaiFormatado;
    },
    [handleCriarParametroPaiFormatado]
  );

  const handlePesquisarParametro = useCallback(async (texto: string) => {
    try {
      const response = await ParametroComunicador.index({
        params: { filtro: texto },
      });

      setParametrosLista(response);
    } catch (error) {
      TratarErros(error);
    }
  }, []);

  const handleSetParametros = useCallback(async () => {
    const response = await ParametroComunicador.index();

    setParametrosLista(response);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current?.getData();
      const response = await ParametroValorComunicador.update({ params: data });
      ToastSucesso(response.mensagem);

      handleSetParametros();
      parametros.carregarParametros();

      setDadosRecuperados(null);
      setListaTab([]);
      setTabAtivaId('');
      await formRef.current?.reset();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleSetParametros, parametros]);

  useEffect(() => {
    if (dadosRecuperados) {
      const dadosRecuperadosFormatados = dadosRecuperados.map(
        handleCriarParametroFilhoFormatado
      );

      setListaTab(dadosRecuperadosFormatados);
    }
  }, [dadosRecuperados, handleCriarParametroFilhoFormatado]);

  useEffect(() => {
    handleSetParametros();
  }, [handleSetParametros]);

  const listaParametroFormatado = useMemo(() => {
    return handleCriarListaParametroFormatado(parametrosLista);
  }, [handleCriarListaParametroFormatado, parametrosLista]);

  return (
    <Detalhe listaTab={listaTab} formRef={formRef}>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <h1>Parâmetro</h1>
        <ContainerButtons>
          <BtnVoltar disabled={loading}>
            <TextoLoading loading={loading}>
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </TextoLoading>
          </BtnVoltar>

          <BtnSalvar onClick={handleSubmit} disabled={loading}>
            <TextoLoading loading={loading}>
              <BiSave />
              <span style={{ marginLeft: 10, fontWeight: 'bold' }}>Salvar</span>
            </TextoLoading>
          </BtnSalvar>
        </ContainerButtons>
      </SubHeaderGeral>

      <Container $telaPequena={telaPequena} $telaGrande={telaGrande}>
        <Pesquisar>
          <Divisor style={{ margin: 0 }}>
            <span>Pesquisar Parâmetro</span>
            <input
              type="text"
              onChange={(event) => {
                handlePesquisarParametro(event.target.value);
              }}
              disabled={loading}
            />
          </Divisor>
        </Pesquisar>

        <Aside>
          <Divisor
            style={{ margin: 0, padding: 0, height: 'auto', minHeight: '100%' }}
          >
            <Menu listaMenu={listaParametroFormatado} />
          </Divisor>
        </Aside>

        <Body>
          {listaTab.length > 0 && (
            <FormCia ref={formRef}>
              {listaTab.map((tab) => {
                return (
                  <Parametro
                    key={tab.id}
                    parametro={tab}
                    visivel={tab.id === tabAtivaId}
                  />
                );
              })}
            </FormCia>
          )}
        </Body>
      </Container>
    </Detalhe>
  );
};

export default Main;
