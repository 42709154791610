import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import TrDraggable from '../TrDraggable';
import { UseTableDraggable } from '../TableDraggable/HooksTable/TableDraggableContext';
import { ThContent } from '../TableDraggable/styles';
import ThDraggable from '../TableDraggable/ThDraggable';
import { TBody } from '../Styles/TBody';
import ToastErro from '../../Util/Toasts/ToastErro';

interface ITh {
  titulo: string;
  nomeCampo: string;
  arrastavel: boolean;
  visivel: boolean;
  ordenar: boolean;
  tamanho: number | null;
  permitirEsconder: boolean;
  style?: React.CSSProperties;
  campoPersonalizado?: any;
}

interface IPesquisa {
  pesquisaAvancada?: object;
  order?: string;
  descAsc?: string;
  limite?: number;
  pagina?: number;
  textoPesquisa?: string;
}

interface ITableDraggableComDragTr {
  listaTh: ITh[];
  listaDado: any[];
  getKey(item: any): string | number;
  onChangeOrdem(novaListaThs: ITh[]): Promise<void>;
  onChangeTamanho(novaTh: ITh): Promise<void>;
  onChangeVisivel(novaTh: ITh): Promise<void>;
  onClickLinha(item: any): void;
  onPesquisar(parametros: IPesquisa): Promise<void>;
  onMover(de: number, para: number): Promise<void>;
  onAtualizarOrdem(paginaAtual: number): Promise<void>;
}

const TableDraggableComDragTr: React.FC<ITableDraggableComDragTr> = ({
  listaTh,
  listaDado,
  getKey,
  onChangeOrdem,
  onChangeTamanho,
  onChangeVisivel,
  onClickLinha,
  onPesquisar,
  onMover,
  onAtualizarOrdem,
}) => {
  const [idTh] = useState(v4());
  const [idTr] = useState(v4());

  const { setListaTh, ths, setFuncoes, parametros } = UseTableDraggable();

  useEffect(() => {
    setListaTh(listaTh);
  }, [listaTh, setListaTh]);

  useEffect(() => {
    setFuncoes({
      onChangeOrdem,
      onChangeTamanho,
      onChangeVisivel,
      pesquisarDados: onPesquisar,
    });
  }, [
    onChangeOrdem,
    onChangeTamanho,
    onChangeVisivel,
    onPesquisar,
    setFuncoes,
  ]);

  const handleDragEnd = useCallback(() => {
    onAtualizarOrdem(parametros.pagina || 1);
  }, [onAtualizarOrdem, parametros.pagina]);

  const permitirDrag = useCallback(() => {
    const permitir = !(parametros.pesquisaAvancada || parametros.textoPesquisa);
    if (!permitir) {
      ToastErro(
        'Não é possivel alterar a ordem se algum filtro estiver ativo!'
      );
    }
    return permitir;
  }, [parametros.pesquisaAvancada, parametros.textoPesquisa]);

  return (
    <Table striped bordered hover style={{ width: 'max-content' }}>
      <thead>
        <tr>
          {ths.map((th, index) => {
            if (!th.visivel) return <React.Fragment key={getKey(th)} />;
            return th.arrastavel ? (
              <ThDraggable
                key={getKey(th)}
                width={th.tamanho}
                index={index}
                style={th.style}
                idDrag={idTh}
              >
                <ThContent>{th.titulo}</ThContent>
              </ThDraggable>
            ) : (
              <th key={getKey(th)} style={th.style}>
                {th.titulo}
              </th>
            );
          })}
        </tr>
      </thead>

      <TBody>
        {listaDado.map((dado, index) => {
          return (
            <TrDraggable
              key={String(index)}
              index={index}
              onMover={onMover}
              onClick={() => {
                onClickLinha(dado);
              }}
              onDragEnd={handleDragEnd}
              idDrag={idTr}
              permitirDrag={permitirDrag}
            >
              {ths.map((th) => {
                if (!th.visivel) return <React.Fragment key={getKey(th)} />;

                const valor = dado[th.nomeCampo];
                return <td key={getKey(th)}>{valor}</td>;
              })}
            </TrDraggable>
          );
        })}
      </TBody>
    </Table>
  );
};

export default TableDraggableComDragTr;
