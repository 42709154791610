import {
  BooleanEnum,
  FormatarEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select/index';
import InputAutoCompleteTagNcm from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagCest from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCest';
import InputAutoCompleteTagOrigemIcms from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOrigemIcms';
import InputAutoCompleteTagMarcaProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagMarcaProduto';
import InputAutoCompleteTagUnidadeMedida from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagUnidadeMedida';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const codigo = parametros.pesquisaAvancada?.codigo;
      if (codigo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código',
          valor: codigo,
        });
      }

      const descricao = parametros.pesquisaAvancada?.descricao;
      if (descricao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Descrição',
          valor: descricao,
        });
      }

      const tipoBuscaCodigoDescricao =
        parametros.pesquisaAvancada?.tipoBuscaCodigoDescricao;

      if (tipoBuscaCodigoDescricao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Filtro Código/Descrição',
          valor: tipoBuscaCodigoDescricao,
        });
      }

      const listaUnidadeMedida =
        parametros.pesquisaAvancada?.listaUnidadeMedida;
      if (listaUnidadeMedida.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Unidade Medida',
          valor: listaUnidadeMedida.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.sigla}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaGrupoProdutoServico =
        parametros.pesquisaAvancada?.listaGrupoProdutoServico;
      if (listaGrupoProdutoServico.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Grupo',
          valor: listaGrupoProdutoServico.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaOrigemIcms = parametros.pesquisaAvancada?.listaOrigemIcms;
      if (listaOrigemIcms.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Origem',
          valor: listaOrigemIcms.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaNcm = parametros.pesquisaAvancada?.listaNcm;
      if (listaNcm.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'NCM',
          valor: listaNcm.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCest = parametros.pesquisaAvancada?.listaCest;
      if (listaCest.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'CEST',
          valor: listaCest.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaSpedTipoProduto =
        parametros.pesquisaAvancada?.listaSpedTipoProduto;
      if (listaSpedTipoProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Sped',
          valor: listaSpedTipoProduto.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaMarcaProduto = parametros.pesquisaAvancada?.listaMarcaProduto;
      if (listaMarcaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Marca',
          valor: listaMarcaProduto.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const modelo = parametros.pesquisaAvancada?.modelo;
      if (modelo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Modelo',
          valor: modelo,
        });
      }

      const codigoBarras = parametros.pesquisaAvancada?.codigoBarras;
      if (codigoBarras) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código de Barras',
          valor: codigoBarras,
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Input name="codigo" label="Código" type="text" />
              </Col>
              <Col lg={6} md={12} />

              <Col lg={6} md={12}>
                <Input name="descricao" label="Descrição" type="text" />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="tipoBuscaCodigoDescricao"
                  label="Filtro Código/Descrição"
                  options={FormatarEnum({
                    enumObj: TipoBuscaCodigoDescricaoEnum,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagUnidadeMedida
                  label="Unidade de Medida"
                  name="listaUnidadeMedida"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagGrupoProdutoServico
                  label="Grupo"
                  name="listaGrupoProdutoServico"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagOrigemIcms
                  label="Origem"
                  name="listaOrigemIcms"
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <InputAutoCompleteTagNcm label="NCM" name="listaNcm" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCest label="CEST" name="listaCest" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagSpedTipoProduto
                  label="Tipo SPED"
                  name="listaSpedTipoProduto"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagMarcaProduto
                  label="Marca"
                  name="listaMarcaProduto"
                />
              </Col>
              <Col lg={6} md={12}>
                <Input name="modeloProduto" label="Modelo" type="text" />
              </Col>
              <Col lg={6} md={12}>
                <Input
                  name="codigoBarras"
                  label="Código de Barras"
                  type="text"
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
