import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IImpressaoEtiquetasNotaFiscalLista } from '@elogestor/util';
import { IInputAutoCompleteBaseRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IImpressaoEtiquetasNotaFiscalListaContext {
  validador: boolean;

  listaValor: IImpressaoEtiquetasNotaFiscalLista[];
  setListaValor(lista: IImpressaoEtiquetasNotaFiscalLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaQuantidadeRef: RefObject<HTMLInputElement[]>;
  listaTransportadoraRef: RefObject<IInputAutoCompleteBaseRef[]>;
}

const ImpressaoEtiquetasNotaFiscalListaContext =
  createContext<IImpressaoEtiquetasNotaFiscalListaContext>(
    {} as IImpressaoEtiquetasNotaFiscalListaContext
  );

const ImpressaoEtiquetasNotaFiscalListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IImpressaoEtiquetasNotaFiscalLista[]
  >([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaQuantidadeRef = useRef<HTMLInputElement[]>([]);
  const listaTransportadoraRef = useRef<IInputAutoCompleteBaseRef[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IImpressaoEtiquetasNotaFiscalLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <ImpressaoEtiquetasNotaFiscalListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaQuantidadeRef,

        listaTransportadoraRef,

        validador: true,
      }}
    >
      {children}
    </ImpressaoEtiquetasNotaFiscalListaContext.Provider>
  );
};

function UseImpressaoEtiquetasNotaFiscalLista(): Omit<
  IImpressaoEtiquetasNotaFiscalListaContext,
  'validador'
> {
  const context = useContext(ImpressaoEtiquetasNotaFiscalListaContext);

  if (!context.validador) {
    throw new Error(
      'UseImpressaoEtiquetasNotaFiscalLista deve ser usado com um ImpressaoEtiquetasNotaFiscalListaHook'
    );
  }

  return context;
}

export {
  ImpressaoEtiquetasNotaFiscalListaHook,
  UseImpressaoEtiquetasNotaFiscalLista,
};
