import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  MoverItensPosicaoArray,
  IProdutoFotoValoresAlterar,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { BiSave } from 'react-icons/bi/index.mjs';
import {
  IoMdRemoveCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import imageCompression from 'browser-image-compression';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import Input from '../../../../../../../Componentes/Inputs/Input';
import { InputArquivo, Divisor } from './styles';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import ToastAviso from '../../../../../../../Util/Toasts/ToastAviso';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ProdutoImagemComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoImagemComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import InputInteiroIncremental from '../../../../../../../Componentes/Inputs/InputInteiroIncremental';

export interface IImagemItem {
  url: string;
  descricao: string;
  imagem?: File;
  ordem?: number;
  id?: string;
}

interface IImagemDetalheProps {
  onFecharFormModal: () => void;
  onSalvarFormModal: () => void;
  listaImagemInicial: IImagemItem[];
}

const removerUuidFilename = (filename: string): string => {
  const [, ...partesFilename] = filename.split('_');
  return partesFilename.join('_');
};

const ImagemDetalhe: React.FC<IImagemDetalheProps> = ({
  onFecharFormModal,
  onSalvarFormModal,
  listaImagemInicial,
}) => {
  const {
    permissoes: { SuprimentosProdutosProduto: permissao },
  } = UsePermissoes();
  const { getIdDetalheRegistro } = UseForm();

  const [loading, setLoading] = useState(listaImagemInicial.length > 0);
  const [listaImagem, setListaImagem] =
    useState<IImagemItem[]>(listaImagemInicial);
  const [indexImagemAtual, setIndexImagemAtual] = useState(0);

  const [, setRefresh] = useState(0);
  const refresh = useCallback((): void => {
    setRefresh((state) => state + 1);
  }, []);

  const formRef = useRef<IFormCiaHandles>(null);
  const inputArquivoRef = useRef<HTMLInputElement>(null);

  const handleOnSelecionarArquivos = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) return;

      const { files } = event.target;

      const listaArquivosBloqueados: string[] = [];
      const tiposArquivosPermitidos = ['jpeg', 'png'];

      const fileList = Array.from(files).filter((file) => {
        const fileType = file.type.split('/')?.[1] || '';

        if (tiposArquivosPermitidos.includes(fileType)) {
          return true;
        }

        listaArquivosBloqueados.push(file.name);
        return false;
      });

      listaArquivosBloqueados.forEach((arquivo) => {
        ToastAviso(`O arquivo ${arquivo} não é uma imagem e foi removido!`, {
          autoClose: 6000,
        });
      });

      try {
        setLoading(true);

        const options = { maxWidthOrHeight: 1920, useWebWorker: true };

        const listaArquivos = await Promise.all(
          fileList.map(async (file) => {
            const compressedFile = await imageCompression(file, options);
            const convertedBlobFile = new File(
              [compressedFile],
              `${v4()}_${file.name}`,
              {
                type: compressedFile.type,
                lastModified: Date.now(),
              }
            );

            return convertedBlobFile;
          })
        );

        setListaImagem((prevState) => {
          const listaNovosArquivos = listaArquivos.map((arq) => ({
            url: window.URL.createObjectURL(arq),
            descricao: removerUuidFilename(arq.name),
            imagem: arq,
          }));

          if (prevState.length === 0) {
            formRef.current?.setDataInicial({
              descricao: listaNovosArquivos[0]?.descricao,
              ordem: 1,
            });
          }

          return [...prevState, ...listaNovosArquivos];
        });
      } catch (error) {
        ToastErro(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleShowInfoImagem = useCallback(
    async (itemIndex: any) => {
      const item = listaImagem[itemIndex];

      if (!item) return;

      await formRef.current?.setDataInicial({
        descricao: item.descricao,
        ordem: itemIndex + 1,
      });
      refresh();
    },
    [listaImagem, refresh]
  );

  const handleChangeDescricao = useCallback(
    (descricao: string) => {
      if (!descricao) {
        formRef.current?.setFieldError('descricao', 'Descrição é obrigatória!');
        return;
      }

      setListaImagem((prevState) => {
        const itemEditar = prevState[indexImagemAtual];

        if (itemEditar) {
          itemEditar.descricao = descricao;
        }

        return [...prevState];
      });
    },
    [indexImagemAtual]
  );

  const handleRemoverImagem = useCallback(() => {
    setListaImagem((prevState) => {
      if (prevState.length === 1) {
        setIndexImagemAtual(0);

        formRef.current?.setDataInicial({ ordem: null, descricao: '' });
        return [];
      }

      const lista = prevState.filter((_, idx) => idx !== indexImagemAtual);

      const ultimoItemRemovido = indexImagemAtual === listaImagem.length - 1;

      let ordem: string | number = indexImagemAtual + 1;
      let indexImagemAtualCalculado = indexImagemAtual;

      if (ultimoItemRemovido) {
        ordem = indexImagemAtual;
        indexImagemAtualCalculado -= 1;
        setIndexImagemAtual(indexImagemAtualCalculado);
      }

      formRef.current?.setDataInicial({
        ordem,
        descricao: lista[indexImagemAtualCalculado]?.descricao || '',
      });

      return lista;
    });
  }, [indexImagemAtual, listaImagem.length]);

  const handleChangeOrdem = useCallback(
    (ordemDestino: number) => {
      const listaOrdenada = MoverItensPosicaoArray({
        array: listaImagem,
        indexItemMovimentar: indexImagemAtual,
        indexDestino: ordemDestino - 1,
      });

      setIndexImagemAtual(ordemDestino - 1);
      setListaImagem(listaOrdenada);
    },
    [indexImagemAtual, listaImagem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const idProduto = getIdDetalheRegistro();

      if (!idProduto) return;

      setLoading(true);

      const listaArquivos: IProdutoFotoValoresAlterar[] = [];
      const formData = new FormData();

      listaImagem.forEach((item, index) => {
        const descricao = item.descricao || item.imagem?.name || '';
        const tipoArquivo = item.imagem?.name.split('.')[1];
        const nomeArquivo = item.imagem?.name
          .RemoverCaracteresEspeciais()
          .RemoverTodosEspacos();
        listaArquivos.push({
          descricao,

          idProduto,
          nomeImagem: `${nomeArquivo}.${tipoArquivo}`,
          ordem: index + 1,
          id: item.id,
        });

        if (item.imagem) formData.append('imagem-produto', item.imagem);
      });

      formData.append('listaProdutoFoto', JSON.stringify(listaArquivos));
      formData.append('idProduto', idProduto);

      await ProdutoImagemComunicador.store({
        params: formData,
      });

      ToastSucesso('Alterações salvas!');
      setLoading(false);
      onSalvarFormModal();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, listaImagem, onSalvarFormModal]);

  useEffect(() => {
    const primeiroItem = listaImagemInicial[0];

    formRef.current?.setDataInicial({
      descricao: primeiroItem?.descricao,
      ordem: primeiroItem ? 1 : null,
    });
  }, [listaImagemInicial]);

  return (
    <JanelaDetalhe
      onFecharFormModal={onFecharFormModal}
      tamanho="xl"
      titulo="Fotos / Imagens"
    >
      <LoadingDiv isToggleJanela isLoading={loading} />

      <Modal.Body>
        <Divisor>
          <Carousel
            selectedItem={indexImagemAtual}
            showStatus={false}
            onClickThumb={async (itemIndex) => {
              setIndexImagemAtual(itemIndex);

              if (listaImagem.length === 1 && itemIndex === 0) {
                await formRef.current?.setDataInicial({
                  ordem: 1,
                  descricao: listaImagem[0].descricao,
                });
              }
            }}
            onChange={(itemIndex) => {
              handleShowInfoImagem(itemIndex);
              setIndexImagemAtual(itemIndex);
            }}
          >
            {listaImagem.map((item) => {
              return (
                <div
                  key={item.descricao}
                  style={{
                    maxHeight: '500px',
                    height: '100%',
                  }}
                >
                  <img
                    src={item.url}
                    alt={item.descricao}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'scale-down',
                    }}
                    onLoad={() => {
                      setLoading(false);
                    }}
                  />
                  <p
                    className="legend"
                    style={{
                      display: 'block',
                      width: 'fit-content',
                      margin: '0 auto',
                      transform: 'translateX(-50%)',
                      fontSize: 16,
                    }}
                  >
                    {item.descricao}
                  </p>
                </div>
              );
            })}
          </Carousel>
          {listaImagem.length === 0 && (
            <p style={{ textAlign: 'center', margin: 0, padding: 10 }}>
              Sem Imagens Adicionadas.
            </p>
          )}
        </Divisor>

        <FormCia ref={formRef}>
          <Divisor>
            <Row style={{ alignItems: 'flex-end' }}>
              <Col xl={3}>
                <input
                  type="file"
                  multiple
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  ref={inputArquivoRef}
                  onChange={handleOnSelecionarArquivos}
                />

                <InputArquivo
                  type="button"
                  className="btn-azul-escuro"
                  onClick={() => {
                    inputArquivoRef.current?.click();
                  }}
                >
                  <TextoLoading loading={false}>
                    Selecionar arquivos &#187;
                  </TextoLoading>
                </InputArquivo>
              </Col>

              <Col xl={4} lg={6}>
                <Input
                  label="Descrição Imagem / Foto Selecionada"
                  name="descricao"
                  placeholder="Descrição"
                  onChange={(event) => {
                    handleChangeDescricao(event.target.value);
                  }}
                />
              </Col>

              <Col xl={3} lg={6}>
                <InputInteiroIncremental
                  label="Ordem"
                  name="ordem"
                  placeholder="Ordem"
                  min={1}
                  max={listaImagem.length}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    if (value > listaImagem.length) return;

                    handleChangeOrdem(value);
                  }}
                />
              </Col>

              <Col lg={2}>
                <BtnPadraoButton
                  type="button"
                  className="btn-padrao btn-vermelho-claro btn-adicionar"
                  onClick={handleRemoverImagem}
                  disabled={!permissao?.inclui || loading}
                >
                  <TextoLoading loading={loading}>
                    <IoMdRemoveCircleOutline />
                    <span style={{ marginLeft: 10 }}>Remover</span>
                  </TextoLoading>
                </BtnPadraoButton>
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              style={{ width: '160px' }}
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 15,
                display: 'flex',
                alignItems: 'center',
                width: '160px',
              }}
              type="button"
              onClick={handleSubmit}
              disabled={!permissao?.altera}
            >
              <BiSave style={{ marginLeft: 30 }} />
              <span style={{ marginLeft: 10 }}>Salvar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ImagemDetalhe;
