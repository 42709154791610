import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/obter-dados-padrao';

class NotaFiscalEntradaObterDadosPadraoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(`${rota}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaObterDadosPadraoComunicador();
