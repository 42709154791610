/* eslint-disable no-nested-ternary */
import {
  FormatarDataHoraCompletaParaPtBr,
  IManifestoTransporteLista,
  SituacaoManifestoTransporteEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../Componentes/Lista';
import IPesquisa from '../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../Hooks/Permissoes';
import { UseLog } from '../../../../Componentes/LogModal/Hooks/LogProvider';
import ManifestoTransporteComunicador from '../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteComunicador';
import PersonalizacaoListaCamposComunicador from '../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ManifestoTransporteLogComunicador from '../../../../Comunicador/Transporte/ManifestoTransporte/Log/Comunicador/ManifestoTransporteLogComunicador';

const ManifestoTransporteLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ManifestoTransporteComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IManifestoTransporteLista) => {
            return {
              id: data.id,
              'serie.codigoDescricao': (
                <div className="lista-texto">{`${data.serie.codigo} - ${data.serie.descricao}`}</div>
              ),
              numero: <div className="lista-valor">{data.numero}</div>,
              dataHoraEmissao: (
                <div className="lista-data">
                  {FormatarDataHoraCompletaParaPtBr(data.dataHoraEmissao)}
                </div>
              ),
              dataHoraInicioViagem: (
                <div className="lista-data">
                  {FormatarDataHoraCompletaParaPtBr(data.dataHoraInicioViagem)}
                </div>
              ),
              placa: (
                <div className="lista-valor">{data.veiculoTracao.placa}</div>
              ),
              pesoBrutoTotalCarga: (
                <div className="lista-valor">
                  {data.pesoBrutoTotalCarga
                    ? Number(data.pesoBrutoTotalCarga).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),
              valorTotalMercadoriaTransportada: (
                <div className="lista-valor">
                  {data.valorTotalMercadoriaTransportada
                    ? Number(
                        data.valorTotalMercadoriaTransportada
                      ).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),
              situacao: (
                <div
                  className="lista-valor"
                  style={{
                    fontWeight: 'bold',
                    color:
                      data.situacao ===
                      SituacaoManifestoTransporteEnum.autorizado
                        ? 'green'
                        : data.situacao ===
                            SituacaoManifestoTransporteEnum.encerrado
                          ? 'blue'
                          : data.situacao ===
                              SituacaoManifestoTransporteEnum.cancelado
                            ? 'red'
                            : data.situacao ===
                                SituacaoManifestoTransporteEnum.rejeitado
                              ? 'goldenrod'
                              : 'black',
                  }}
                >
                  {data.situacao}
                </div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await ManifestoTransporteComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          ManifestoTransporteLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.TransporteManifestoTransporte?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.TransporteManifestoTransporte]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'transporteManifestoTransporte' },
      });
      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Manifesto de Transporte"
          permissao={permissoes.TransporteManifestoTransporte}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return ManifestoTransporteLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default ManifestoTransporteLista;
