import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/obter-conta';

interface IPedidoVendaObterContaComunicadorShow {
  id: string;
}

class PedidoVendaObterContaComunicador {
  public async show(
    params: IPedidoVendaObterContaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaObterContaComunicador();
