import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseNotaFiscalSaida } from '../../../../Hook/NotaFiscalSaidaHook';
import { UseSituacaoBloquearCampos } from '../../../../Hook/SituacaoBloquearCamposHook';
import { UseItemTab } from '../../Hook/ItemTabHook';

const ItemOutrosValoresTab: React.FC = () => {
  const {
    setFormaRateioValorFrete,
    setFormaRateioValorSeguro,
    setFormaRateioValorOutrasDespesas,
    setFormaRateioValorDesconto,
  } = UseNotaFiscalSaida();
  const { calcularImpostosPorCst } = UseItemTab();

  const { formRefDetalhe } = UseListaDetalheForm();

  const { situacaoBloquearCampos, situacaoBloquearCamposContaRevertida } =
    UseSituacaoBloquearCampos();

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleSetFormaRateioValorFrete = useCallback(() => {
    setFormaRateioValorFrete(false);
  }, [setFormaRateioValorFrete]);

  const handleSetFormaRateioValorSeguro = useCallback(() => {
    setFormaRateioValorSeguro(false);
  }, [setFormaRateioValorSeguro]);

  const handleSetFormaRateioValorOutrasDespesas = useCallback(() => {
    setFormaRateioValorOutrasDespesas(false);
  }, [setFormaRateioValorOutrasDespesas]);

  const handleSetFormaRateioValorDesconto = useCallback(() => {
    setFormaRateioValorDesconto(false);
  }, [setFormaRateioValorDesconto]);

  const handleCalcularValorDesconto = useCallback(() => {
    const percentualDesconto =
      formRefDetalhe.current?.getFieldValue('percentualDesconto');

    const valorSubTotal =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const valorDesconto =
      Number(valorSubTotal) * (Number(percentualDesconto) / 100);

    if (valorDesconto > 0) {
      handleSetFormaRateioValorDesconto();
      formRefDetalhe.current?.setFieldValue('valorDesconto', valorDesconto);
      handleCalcularImpostosPorCst();
    }
  }, [
    formRefDetalhe,
    handleCalcularImpostosPorCst,
    handleSetFormaRateioValorDesconto,
  ]);

  const handleCalcularValorOutrasDespesas = useCallback(() => {
    const percentualOutrasDespesas = formRefDetalhe.current?.getFieldValue(
      'percentualOutrasDespesas'
    );

    const valorSubTotal =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const valorOutrasDespesas =
      Number(valorSubTotal) * (Number(percentualOutrasDespesas) / 100);

    if (valorOutrasDespesas > 0) {
      handleSetFormaRateioValorOutrasDespesas();
      formRefDetalhe.current?.setFieldValue(
        'valorOutrasDespesas',
        valorOutrasDespesas
      );
      handleCalcularImpostosPorCst();
    }
  }, [
    formRefDetalhe,
    handleCalcularImpostosPorCst,
    handleSetFormaRateioValorOutrasDespesas,
  ]);

  const handleCalcularPercentualDesconto = useCallback(() => {
    const valorDesconto =
      formRefDetalhe.current?.getFieldValue('valorDesconto');
    const valorSubTotal =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const percentualCalculado = valorSubTotal
      ? (Number(valorDesconto) / Number(valorSubTotal)) * 100
      : 0;

    formRefDetalhe.current?.setFieldValue(
      'percentualDesconto',
      percentualCalculado
    );
  }, [formRefDetalhe]);

  const handleCalcularPercentualOutrasDespesas = useCallback(() => {
    const valorOutrasDespesas = formRefDetalhe.current?.getFieldValue(
      'valorOutrasDespesas'
    );
    const valorSubTotal =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const percentualCalculado = valorSubTotal
      ? (Number(valorOutrasDespesas) / Number(valorSubTotal)) * 100
      : 0;

    formRefDetalhe.current?.setFieldValue(
      'percentualOutrasDespesas',
      percentualCalculado
    );
  }, [formRefDetalhe]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="Valor Frete"
              name="valorFrete"
              casasInteiras={13}
              casasDecimais={2}
              onChange={() => {
                handleSetFormaRateioValorFrete();
                handleCalcularImpostosPorCst();
              }}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="Valor Seguro"
              name="valorSeguro"
              casasInteiras={13}
              casasDecimais={2}
              onChange={() => {
                handleSetFormaRateioValorSeguro();
                handleCalcularImpostosPorCst();
              }}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="Valor Outras Despesas"
              name="valorOutrasDespesas"
              casasInteiras={13}
              casasDecimais={2}
              onBlur={() => {
                handleCalcularPercentualOutrasDespesas();
              }}
              onChange={() => {
                handleSetFormaRateioValorOutrasDespesas();
                handleCalcularImpostosPorCst();
              }}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="% Outras Despesas"
              name="percentualOutrasDespesas"
              casasInteiras={11}
              casasDecimais={6}
              onBlur={handleCalcularValorOutrasDespesas}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="Valor Desconto"
              name="valorDesconto"
              casasInteiras={13}
              casasDecimais={2}
              onChange={() => {
                handleSetFormaRateioValorDesconto();
                handleCalcularImpostosPorCst();
              }}
              onBlur={() => {
                handleCalcularPercentualDesconto();
              }}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
          <Col lg={2} md={4} sm={6}>
            <InputDecimalNulavel
              label="% Desconto"
              name="percentualDesconto"
              casasInteiras={11}
              casasDecimais={6}
              onBlur={handleCalcularValorDesconto}
              disabled={
                situacaoBloquearCampos || situacaoBloquearCamposContaRevertida
              }
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemOutrosValoresTab;
