import {
  BooleanEnum,
  FormatarEnum,
  SituacaoPedidoVendaEnum,
  SituacaoPedidoVendaNotaFiscalSaidaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoPedidoVendaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select/index';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import Input from '../../../../../Componentes/Inputs/Input';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataEmissaoRef = useRef<any>(null);
  const periodoDataPrevisaoFaturamentoRef = useRef<any>(null);

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const situacao = parametros.pesquisaAvancada?.situacao;
      if (!situacao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação',
          valor: situacao,
        });
      }
      const situacaoNota = parametros.pesquisaAvancada?.situacaoNota;
      if (!situacaoNota) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação da Nota',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Situação da Nota',
          valor: situacaoNota,
        });
      }

      const listaCliente = parametros.pesquisaAvancada?.listaCliente;
      if (listaCliente.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Clientes',
          valor: listaCliente.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.nomeRazaoSocial}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCidade = parametros.pesquisaAvancada?.listaCidade;
      if (listaCidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cidades',
          valor: listaCidade.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.cidadeUf}; `;
            return acumulador;
          }, ''),
        });
      }

      const pedidoCompraNumero =
        parametros.pesquisaAvancada?.pedidoCompraNumero;
      if (pedidoCompraNumero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Núm. do Pedido de Compra',
          valor: pedidoCompraNumero,
        });
      }
      const pedidoCompraNumeroItem =
        parametros.pesquisaAvancada?.pedidoCompraNumeroItem;
      if (pedidoCompraNumeroItem) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Item do Pedido de Compra',
          valor: pedidoCompraNumeroItem,
        });
      }
      const numeroPedidoIntegracao =
        parametros.pesquisaAvancada?.numeroPedidoIntegracao;
      if (numeroPedidoIntegracao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número do Pedido de Integração',
          valor: numeroPedidoIntegracao,
        });
      }

      const periodoDataEmissao = periodoDataEmissaoRef.current?.getPeriodo();
      if (periodoDataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Emissão',
          valor: periodoDataEmissao,
        });
      }

      const periodoDataPrevisaoFaturamento =
        periodoDataPrevisaoFaturamentoRef.current?.getPeriodo();
      if (periodoDataPrevisaoFaturamento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Previsão Faturamento',
          valor: periodoDataPrevisaoFaturamento,
        });
      }

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const tipoPedidoVenda = parametros.pesquisaAvancada?.tipoPedidoVenda;
      if (!tipoPedidoVenda) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Pedido Venda',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Pedido Venda',
          valor: tipoPedidoVenda,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        tipoPedidoVenda: null,
        tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Select
                  name="situacao"
                  label="Situação"
                  options={FormatarEnum({
                    enumObj: SituacaoPedidoVendaEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Input
                  name="numero"
                  label="Número do Pedido"
                  placeholder="Número do Pedido"
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="situacaoNota"
                  label="Situação da Nota"
                  options={FormatarEnum({
                    enumObj: SituacaoPedidoVendaNotaFiscalSaidaEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCliente
                  label="Clientes"
                  name="listaCliente"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagProduto
                  name="listaProduto"
                  label="Produtos"
                  onChangeListaItemAtual={handleChangeProduto}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Filtro Código/Descrição"
                  name="tipoBuscaCodigoDescricao"
                  options={FormatarEnum({
                    enumObj: TipoBuscaCodigoDescricaoEnum,
                    nullavel: false,
                    todos: false,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Input name="codigo" label="Código" placeholder="Código" />
              </Col>

              <Col lg={6} md={12}>
                <Input
                  name="descricao"
                  label="Descrição"
                  placeholder="Descrição"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCidade
                  label="Cidades"
                  name="listaCidade"
                />
              </Col>
              <Col lg={6} md={12}>
                <Input
                  name="pedidoCompraNumero"
                  label="Núm. do Pedido de Compra"
                  maxLength={15}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputInteiroNulavel
                  name="pedidoCompraNumeroItem"
                  label="Item do Pedido de Compra"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputInteiroNulavel
                  name="numeroPedidoIntegracao"
                  label="Número do Pedido de Integração"
                />
              </Col>
              <Row>
                <Col lg={6} md={12}>
                  <InputPeriodoData
                    labelDataInicial="Data Emissão Inicial"
                    labelDataFinal="Data Emissão Final"
                    nomeDataInicial="dataEmissaoInicial"
                    nomeDataFinal="dataEmissaoFinal"
                    ref={periodoDataEmissaoRef}
                  />
                </Col>

                <Col lg={6} md={12}>
                  <InputPeriodoData
                    labelDataInicial="Dt. Prev. Fat. Inicial"
                    labelDataFinal="Dt. Prev. Fat. Final"
                    nomeDataInicial="dataPrevisaoFaturamentoInicial"
                    nomeDataFinal="dataPrevisaoFaturamentoFinal"
                    ref={periodoDataPrevisaoFaturamentoRef}
                  />
                </Col>
              </Row>

              <Col lg={6} md={12}>
                <Select
                  name="tipoPedidoVenda"
                  label="Tipo Pedido Venda"
                  options={FormatarEnum({
                    enumObj: TipoPedidoVendaEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
