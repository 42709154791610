import { TipoVeiculoEnum } from '@elogestor/util';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteVeiculo from '../../../../../Componentes/Inputs/AutoComplete/Transporte/InputAutoCompleteVeiculo';
import InputAutoCompleteTagMotorista from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagMotorista';
import InputAutoCompleteTagVeiculo from '../../../../../Componentes/Inputs/AutoCompleteTag/Transporte/InputAutoCompleteTagVeiculo';
import Input from '../../../../../Componentes/Inputs/Input';

const RodoviarioTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputAutoCompleteVeiculo
              label="Placa Veículo Tração"
              name="idVeiculoTracao"
              nomeObjeto="veiculoTracao"
              placeholder="Placa Veículo Tração"
              tipoVeiculo={TipoVeiculoEnum.tracao}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Input label="RNTRC" name="rntrc" maxLength={8} />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagVeiculo
              label="Placa Reboque"
              name="listaManifestoTransporteReboque"
              tipoVeiculo={TipoVeiculoEnum.reboque}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteTagMotorista
              label="Motorista"
              name="listaManifestoTransporteMotorista"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default RodoviarioTab;
