import api from '../../../../../../Comum/Services/Api';

const rota = '/suprimentos/sisdevin/codigo-rastreabilidade-auto-complete-tag';

interface ICodigoRastreabilidadeAutoCompleteTagComunicadorIndex {
  params: any;
}

class CodigoRastreabilidadeAutoCompleteTagComunicador {
  public async index(
    params: ICodigoRastreabilidadeAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CodigoRastreabilidadeAutoCompleteTagComunicador();
