import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { MdPhotoLibrary, MdOutlineArticle } from 'react-icons/md/index.mjs';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import ProdutoImagemComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoImagemComunicador';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImagemDetalhe, { IImagemItem } from './Imagem';
import ProdutosMovimentacoesLista from './Movimentacoes';

const AcoesProduto: React.FC = () => {
  const { getIdDetalheRegistro, setLoading, loading } = UseForm();
  const { telaGrande } = UseReactSizeMeBodyHook();

  const [showImagemModal, setShowImagemModal] = useState(false);
  const [showMovimentacoes, setShowMovimentacoes] = useState(false);
  const [listaInicialImagem, setListaInicialImagem] = useState<IImagemItem[]>(
    []
  );

  const handleClickFotosImagens = useCallback(async () => {
    try {
      setLoading(true);

      const idProduto = getIdDetalheRegistro();

      if (!idProduto) return;

      const listaProdutoFoto = await ProdutoImagemComunicador.index({
        params: { idProduto },
      });

      setListaInicialImagem(listaProdutoFoto);
      setShowImagemModal(true);
    } catch (error) {
      TratarErros(error);
    } finally {
      setLoading(false);
    }
  }, [getIdDetalheRegistro, setLoading]);

  const handleClickMovimentacoes = useCallback(async () => {
    setShowMovimentacoes(true);
  }, []);

  return (
    <SplitAcoes
      id={v4()}
      className="btn-group"
      disabled={!getIdDetalheRegistro()}
      title={
        <div>
          <TextoLoading loading={false}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginRight: 10, marginLeft: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item onClick={handleClickFotosImagens}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <MdPhotoLibrary style={{ marginRight: 5 }} />
          Fotos / Imagens
        </span>
      </Dropdown.Item>

      <Dropdown.Item onClick={handleClickMovimentacoes}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <MdOutlineArticle style={{ marginRight: 5 }} />
          Movimentações
        </span>
      </Dropdown.Item>

      {showImagemModal && (
        <ImagemDetalhe
          listaImagemInicial={listaInicialImagem}
          onFecharFormModal={() => setShowImagemModal(false)}
          onSalvarFormModal={() => setShowImagemModal(false)}
        />
      )}

      {showMovimentacoes && (
        <ProdutosMovimentacoesLista
          onFecharFormModal={() => setShowMovimentacoes(false)}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </SplitAcoes>
  );
};

export default AcoesProduto;
