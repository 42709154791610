import api from '../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/centro-custo/obter-dados-padrao';

interface ICentroCustoObterDadosPadraoComunicadorShow {
  idCentroCustoPai?: string;
}

class CentroCustoObterDadosPadraoComunicador {
  public async show(
    params?: ICentroCustoObterDadosPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params?.idCentroCustoPai || ''}`);
    return response.data;
  }
}

export default new CentroCustoObterDadosPadraoComunicador();
