import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { MdCancel, MdOutlineAttachEmail } from 'react-icons/md/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { v4 } from 'uuid';
import { FiFile } from 'react-icons/fi/index.mjs';
import {
  EnumObterValue,
  SituacaoConhecimentoTransporteEnum,
  AmbienteEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { GiMoneyStack } from 'react-icons/gi';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import ConhecimentoTransporteImpressaoComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Impressao/ConhecimentoTransporteImpressao/ConhecimentoTransporteImpressaoComunicador';
import ImpressaoConhecimentoTransporte from '../../../../DocumentosEletronicos/CTe/ImpressaoModalRodoviario/index';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ConhecimentoTransporteTransmitirComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteTransmitirComunicador';
import CancelarDetalhe from './Cancelar/Detalhe';
import CancelarHook from './Cancelar/Hooks';
import JanelaEnvioEmail from '../../../../../Componentes/JanelaEnvioEmail';
import PessoaContatoObterEmailComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ContaEmailObterDadosComunicador from '../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import ConhecimentoTransporteExportarXmlComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteExportarXmlComunicador';
import VisualizarPDF from '../../../../../Util/Relatorios/VisualizarPDF';
import ConhecimentoTransporteUploadPdfComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteUploadPdfComunicador';
import TratarValidadeCertificado from '../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import ConhecimentoTransporteObterDadosContaParcelaBoletoComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteObterDadosContaParcelaBoletoComunicador';
import { ContaListaParcelaBoletoHook } from '../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Hook/ContaListaParcelaBoletoHook';
import ContaParcelaBoletoHook from '../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Hook';
import ContaParcelaBoletoLista from '../../../../Financeiro/Movimentacoes/Conta/Detalhe/AcoesConta/ContaParcelaBoleto/Lista';
import CartaCorrecaoHook from './CartaCorrecao/Hook';
import CartaCorrecaoLista from './CartaCorrecao/Lista';
import TratarSucesso from '../../../../../Util/Sucesso/TratarSucesso';
import ConhecimentoTransporteConsultarProtocoloComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteConsultarProtocoloComunicador';

interface IAcoesConhecimentoTransporteProps {
  permissao: IPermissao | undefined;
}

const AcoesConhecimentoTransporte: React.FC<
  IAcoesConhecimentoTransporteProps
> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const {
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    formRef,
    handleSubmit,
  } = UseForm();

  const [showCancelarModal, setShowCancelarModal] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [transmitirNotaFiscal, setTransmitirNotaFiscal] = useState(false);
  const [showContaParcelaBoleto, setShowContaParcelaBoleto] = useState(false);
  const [showCartaCorrecao, setShowCartaCorrecao] = useState(false);
  const idRegistroDetalhe = getIdDetalheRegistro();
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [idConta, setIdConta] = useState('');
  const [idPessoaConta, setIdPessoaConta] = useState('');
  const [idConhecimentoTransporte, setIdConhecimentoTransporte] = useState('');
  const [idNotaFiscalSaida, setIdNotaFiscalSaida] = useState('');

  const situacao = formRef.current?.getFieldValue('situacao');
  const numero = formRef.current?.getFieldValue('numero');
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      if (formRef.current?.validarSeAlterou()) {
        const data = formRef.current?.getData();
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await ConhecimentoTransporteImpressaoComunicador.show({
        id: idRegistro,
      });

      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoConhecimentoTransporte.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf = await ImpressaoConhecimentoTransporte.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [getIdDetalheRegistro]
  );

  const handleVisualizarImpressao = useCallback(async () => {
    try {
      setLoading(true);

      await handleVisualizarObterBlobPdf(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleVisualizarObterBlobPdf, setLoading]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formRef.current?.getFieldValue('transmissao.ambiente');

        const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
        const situacaoFormatado = EnumObterValue(
          SituacaoConhecimentoTransporteEnum,
          situacao
        );

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#ambiente:${ambienteFormatado}#situacao:${situacaoFormatado}`
        );

        await ConhecimentoTransporteUploadPdfComunicador.update({
          params: formData,
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error as any);
      setLoading(false);
    }
  }, [
    formRef,
    handleSalvar,
    handleVisualizarObterBlobPdf,
    setLoading,
    situacao,
  ]);

  const handleExportarXml = useCallback(async (): Promise<void> => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const listaResponse =
        await ConhecimentoTransporteExportarXmlComunicador.show({
          id: idRegistro,
        });

      for (let i = 0; i < listaResponse.length; i++) {
        const response = listaResponse[i];

        await ImpressaoConhecimentoTransporte.handleExportarXml(
          response.xml,
          response.nomeArquivo
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idRegistro = getIdDetalheRegistro() || '';

      const listaIdContratante: string[] = [];
      const idPessoaRemetente =
        formRef.current?.getFieldValue('idPessoaRemetente');
      if (idPessoaRemetente) listaIdContratante.push(idPessoaRemetente);

      const idPessoaExpedidor =
        formRef.current?.getFieldValue('idPessoaExpedidor');
      if (idPessoaExpedidor) listaIdContratante.push(idPessoaExpedidor);

      const idPessoaRecebedor =
        formRef.current?.getFieldValue('idPessoaRecebedor');
      if (idPessoaRecebedor) listaIdContratante.push(idPessoaRecebedor);

      const idPessoaDestinatario = formRef.current?.getFieldValue(
        'idPessoaDestinatario'
      );
      if (idPessoaDestinatario) listaIdContratante.push(idPessoaDestinatario);

      const idPessoaTomadorOutro = formRef.current?.getFieldValue(
        'idPessoaTomadorOutro'
      );
      if (idPessoaTomadorOutro) listaIdContratante.push(idPessoaTomadorOutro);

      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: listaIdContratante,
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: true,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarObterBlobPdf(false);

      const data = new Uint8Array((anexo as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `Conhecimento de Transporte ${numero}.pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml =
        await ConhecimentoTransporteExportarXmlComunicador.show({
          id: idRegistro,
        });

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml.xml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Conhecimento de Transporte número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [formRef, getIdDetalheRegistro, handleVisualizarObterBlobPdf, numero]
  );

  // #endregion Email

  // #region Cancelar

  const handleCancelar = useCallback(async (): Promise<void> => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowCancelarModal(true);
  }, [handleSalvar]);

  const handleCancelarSalvarFormModal = useCallback(async (): Promise<void> => {
    setShowCancelarModal(false);

    await handleImprimir();
    await handleCarregarDados();
  }, [handleCarregarDados, handleImprimir]);

  const handleCancelarFecharFormModal = useCallback(async () => {
    setShowCancelarModal(false);
  }, []);

  // #endregion Cancelar

  const handleConsultarProtocolo = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response =
        await ConhecimentoTransporteConsultarProtocoloComunicador.update({
          id: idRegistro,
        });

      if (response.mensagemErro) {
        TratarErros({ mensagem: response.mensagemErro });
      } else {
        TratarSucesso([response.mensagem]);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(true);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleSalvar,
    setLoading,
  ]);

  const handleTransmitirConhecimentoTransporte = useCallback(async () => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente transmitir esse Conhecimento de Transporte?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response = await ConhecimentoTransporteTransmitirComunicador.update(
        { id: idRegistro }
      );

      if (response.mensagemErro) {
        TratarErros({ mensagem: response.mensagemErro });
      } else {
        TratarSucesso([response.mensagem]);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      handleCarregarDados();
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    setLoading,
  ]);

  // #region Carta correção

  const handleCartaCorrecao = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowCartaCorrecao(true);
  }, [handleSalvar]);

  const handleCartaCorrecaoFecharFormModal = useCallback(async () => {
    setShowCartaCorrecao(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Carta Correção

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();
    await handleSubmit(data);

    setShowFinanceiro(false);

    if (transmitirNotaFiscal) {
      await handleTransmitirConhecimentoTransporte();
      setTransmitirNotaFiscal(false);
    }
  }, [
    formRef,
    handleSubmit,
    handleTransmitirConhecimentoTransporte,
    transmitirNotaFiscal,
  ]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleTransmitir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const situacaoCTe = formRef.current?.getFieldValue('situacao');
      if (situacaoCTe === SituacaoConhecimentoTransporteEnum.emProcessamento) {
        await handleConsultarProtocolo();
        setLoading(false);
        return;
      }

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setTransmitirNotaFiscal(true);
        return;
      }

      // #endregion Financeiro

      await handleTransmitirConhecimentoTransporte();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleConsultarProtocolo,
    handleFinanceiro,
    handleSalvar,
    handleTransmitirConhecimentoTransporte,
    setLoading,
    valorFinanceiro,
  ]);

  // #region Boletos

  const handleContaParcelaBoleto = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const response =
        await ConhecimentoTransporteObterDadosContaParcelaBoletoComunicador.show(
          { id: idRegistro }
        );

      setShowContaParcelaBoleto(true);
      setNumeroDocumento(response.numeroDocumento);
      setIdConta(response.id);
      setIdPessoaConta(response.idPessoa);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, setLoading]);

  const handleContaParcelaBoletoFecharFormModal = useCallback(async () => {
    setShowContaParcelaBoleto(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Boletos

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginRight: 10, marginLeft: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacao !== SituacaoConhecimentoTransporteEnum.autorizado ||
            Number(valorFinanceiro) === 0
          }
          onClick={handleContaParcelaBoleto}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Boletos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoConhecimentoTransporteEnum.naoTransmitido &&
              situacao !== SituacaoConhecimentoTransporteEnum.rejeitado &&
              situacao !== SituacaoConhecimentoTransporteEnum.emProcessamento)
          }
          onClick={handleVisualizarImpressao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Visualizar Impressão
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoConhecimentoTransporteEnum.autorizado &&
              situacao !== SituacaoConhecimentoTransporteEnum.denegado &&
              situacao !== SituacaoConhecimentoTransporteEnum.cancelado)
          }
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleExportarXml}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Exportar XML (Download)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoConhecimentoTransporteEnum.autorizado &&
              situacao !== SituacaoConhecimentoTransporteEnum.denegado &&
              situacao !== SituacaoConhecimentoTransporteEnum.cancelado)
          }
          onClick={handleEnviarPorEmail}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.altera ||
            situacao === SituacaoConhecimentoTransporteEnum.cancelado ||
            situacao === SituacaoConhecimentoTransporteEnum.autorizado
          }
          onClick={handleTransmitir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Transmitir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            (situacao !== SituacaoConhecimentoTransporteEnum.autorizado &&
              situacao !== SituacaoConhecimentoTransporteEnum.cancelado)
          }
          onClick={handleCartaCorrecao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <MdOutlineAttachEmail style={{ marginRight: 5 }} />
            Carta de Correção
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacao === SituacaoConhecimentoTransporteEnum.emProcessamento ||
            situacao === SituacaoConhecimentoTransporteEnum.naoTransmitido ||
            situacao === SituacaoConhecimentoTransporteEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacao === SituacaoConhecimentoTransporteEnum.denegado ||
            situacao === SituacaoConhecimentoTransporteEnum.cancelado
          }
          onClick={handleConsultarProtocolo}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Consultar Protocolo CT-e
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showCancelarModal && (
        <CancelarHook>
          <CancelarDetalhe
            onFecharFormModal={handleCancelarFecharFormModal}
            onSalvarFormModal={handleCancelarSalvarFormModal}
          />
        </CancelarHook>
      )}

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Conhecimento de Transporte por E-mail"
          mensagemPadrao={`Segue em anexo o Conhecimento de Transporte número: ${numero}`}
          direcionarOrcamento
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}

      {showContaParcelaBoleto && (
        <ContaListaParcelaBoletoHook>
          <ContaParcelaBoletoHook>
            <ContaParcelaBoletoLista
              onFecharFormModal={handleContaParcelaBoletoFecharFormModal}
              numeroDocumento={numeroDocumento}
              idConta={idConta}
              idPessoa={idPessoaConta}
              idConhecimentoTransporte={idConhecimentoTransporte}
              idNotaFiscalSaida={idNotaFiscalSaida}
              salvarFormPrincipal={false}
            />
          </ContaParcelaBoletoHook>
        </ContaListaParcelaBoletoHook>
      )}

      {showCartaCorrecao && (
        <CartaCorrecaoHook>
          <CartaCorrecaoLista
            onFecharFormModal={handleCartaCorrecaoFecharFormModal}
          />
        </CartaCorrecaoHook>
      )}
    </>
  );
};

export default AcoesConhecimentoTransporte;
