import { FormatarEnum, TipoCalculoEnum } from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIpi from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIpi';
import InputAutoCompleteIpiCodigoEnquadramentoLegal from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteIpiCodigoEnquadramentoLegal';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';
import Select from '../../../../../../../../../../Componentes/Select/index';
import InputCnpj from '../../../../../../../../../../Componentes/Inputs/InputCnpj';
import InputInteiroNulavel from '../../../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseItemIpiTab } from '../../../Hook/ItemIpiTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

const ItemImpostoIpiTab: React.FC = () => {
  const { refresh } = UseListaDetalheForm();
  const { camposEscondidosIpi, camposBloqueadosIpi } = UseItemIpiTab();
  const { calcularImpostosPorCst } = UseItemTab();

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaIpi
              label="CST"
              name="orcamentoItemImpostoIpi.idSituacaoTributaria"
              placeholder="CST"
              nomeObjeto="orcamentoItemImpostoIpi.situacaoTributaria"
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIpi.calcular}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIpi.calcular}
                label="Calcular IPI"
                name="orcamentoItemImpostoIpi.calcular"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.tipoCalculo}>
            <Select
              disabled={camposBloqueadosIpi.tipoCalculo}
              label="Tipo de Cálculo"
              name="orcamentoItemImpostoIpi.tipoCalculo"
              options={FormatarEnum({ enumObj: TipoCalculoEnum })}
              onChange={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.baseCalculo}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.baseCalculo}
              label="Base de Cálculo"
              name="orcamentoItemImpostoIpi.baseCalculo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.aliquota}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.aliquota}
              label="Alíquota"
              name="orcamentoItemImpostoIpi.aliquota"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIpi.quantidadeUnidade}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.quantidadeUnidade}
              label="Quantidade Unidade"
              name="orcamentoItemImpostoIpi.quantidadeUnidade"
              placeholder="Quantidade Unidade"
              casasInteiras={12}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.valorUnidade}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.valorUnidade}
              label="Valor Unidade"
              name="orcamentoItemImpostoIpi.valorUnidade"
              placeholder="Valor Unidade"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.valor}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIpi.valor}
              label="Valor"
              name="orcamentoItemImpostoIpi.valor"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIpi.ipiCodigoEnquadramentoLegal &&
          camposEscondidosIpi.classeEnquadramento &&
          camposEscondidosIpi.cnpjProdutor &&
          camposEscondidosIpi.codigoSelo &&
          camposEscondidosIpi.quantidadeSelo
        }
      >
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            hidden={camposEscondidosIpi.ipiCodigoEnquadramentoLegal}
          >
            <InputAutoCompleteIpiCodigoEnquadramentoLegal
              disabled={camposBloqueadosIpi.ipiCodigoEnquadramentoLegal}
              label="Código Enquadramento Legal"
              name="orcamentoItemImpostoIpi.idIpiCodigoEnquadramentoLegal"
              placeholder="Código Enquadramento Legal"
              nomeObjeto="orcamentoItemImpostoIpi.ipiCodigoEnquadramentoLegal"
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIpi.classeEnquadramento}
          >
            <Input
              disabled={camposBloqueadosIpi.classeEnquadramento}
              label="Classe de Enquadramento"
              name="orcamentoItemImpostoIpi.classeEnquadramento"
              placeholder="Classe de Enquadramento"
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.cnpjProdutor}>
            <InputCnpj
              disabled={camposBloqueadosIpi.cnpjProdutor}
              label="CNPJ Produtor"
              name="orcamentoItemImpostoIpi.cnpjProdutor"
              placeholder="CNPJ Produtor"
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIpi.codigoSelo}>
            <Input
              disabled={camposBloqueadosIpi.codigoSelo}
              label="Código do Selo"
              name="orcamentoItemImpostoIpi.codigoSelo"
              placeholder="Código do Selo"
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIpi.quantidadeSelo}
          >
            <InputInteiroNulavel
              disabled={camposBloqueadosIpi.quantidadeSelo}
              label="Quantidade de Selo"
              name="orcamentoItemImpostoIpi.quantidadeSelo"
              placeholder="Quantidade de Selo"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoIpiTab;
