import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'manufatura/configurador/conjunto/log';

interface IConjuntoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IConjuntoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ConjuntoLogComunicador {
  public async index(
    params: IConjuntoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IConjuntoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ConjuntoLogComunicador();
