import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';

interface IAnulacaoTab {
  bloquearCamposPorImportacao: boolean;
}

const AnulacaoTab: React.FC<IAnulacaoTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <Divisor>
      <Row>
        <Col xl={6} lg={12}>
          <Input
            label="Chave de Acesso CT-e a ser Substituído (original)"
            placeholder="Chave de Acesso CT-e a ser Substituído (original)"
            name="anulacaoChaveAcesso"
            maxLength={44}
            disabled={bloquearCamposPorImportacao}
          />
        </Col>

        <Col xl={6} lg={12}>
          <InputDate
            label="Data de emissão de declaração do tomador não contribuinte do ICMS"
            name="anulacaoDataEmissao"
            disabled={bloquearCamposPorImportacao}
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default AnulacaoTab;
