import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../../../../Componentes/Inputs/Textarea';
import { UseSituacaoBloquearCampos } from '../../../../Hook/SituacaoBloquearCamposHook';

const ItemInformacaoAdicionalTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 125, marginBottom: 5 }}
              label="Informações Adicionais do Produto"
              name="informacoesAdicionaisProduto"
              maxLength={1500}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemInformacaoAdicionalTab;
