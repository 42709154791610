import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/categorias';

interface ICategoriaComunicadorIndex {
  params?: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ICategoriaComunicadorShow {
  id: string;
}

interface ICategoriaComunicadorDelete {
  id: string;
}

interface ICategoriaComunicadorStore {
  params: any;
}

interface ICategoriaComunicadorUpdate {
  id: string;
  params: any;
}

class CategoriaComunicador {
  public async index(params: ICategoriaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ICategoriaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(params: ICategoriaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(params: ICategoriaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(params: ICategoriaComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new CategoriaComunicador();
