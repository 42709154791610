import React from 'react';
import {
  Text,
  Image,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarCnpjCpf,
  FormatarDataParaPtBrSemTimeZone,
  IBoleto,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';

const Document: any = _document;
const Page: any = _page;

interface IImpressaoBoletoPDF {
  dados: IBoleto;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    fontSize: 9,
    padding: '0mm 5mm 12mm 5mm',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  reciboPagador: {
    borderBottom: '1pt dotted black',
    paddingBottom: 30,
    marginBottom: 10,
  },

  linha: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1pt solid black',
  },

  blockBorderLeft: {
    height: '100%',
    borderLeft: '1pt solid black',
    padding: '0 5',
  },

  blockNoBorder: {
    height: '100%',
    padding: '0 5',
  },

  descricaoCampo: {
    fontSize: 8,
    marginBottom: 0.5,
  },
});

const ImpressaoBoletoPDF: React.FC<IImpressaoBoletoPDF> = ({ dados }) => {
  const canvasBarCode = document.createElement('canvas');

  JsBarcode(canvasBarCode, String(dados.codigoBarras), {
    fontSize: 0,
    format: 'ITF',
  });

  const barCode = canvasBarCode.toDataURL();

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View>
          <View style={styles.reciboPagador}>
            <View style={styles.linha}>
              <View style={{ maxHeight: '25pt', width: '15%' }}>
                <Image src={`${dados.logotipoUrl}`} />
              </View>

              <View
                style={[
                  styles.blockBorderLeft,
                  {
                    alignItems: 'center',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    fontSize: 13,
                  },
                ]}
              >
                <Text>{dados.codigoBanco || ' '}</Text>
              </View>

              <View
                style={[
                  styles.blockBorderLeft,
                  {
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  },
                ]}
              >
                <Text>{dados.textoBancoLinha1 || ' '}</Text>
                <Text>{dados.textoBancoLinha2 || ' '}</Text>
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  paddingRight: '20pt',
                  fontSize: 9,
                  height: '100%',
                }}
              >
                <Text>Recibo do Pagador</Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Local Pagamento</Text>
                <Text>{dados.localPagamento || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Vencimento</Text>
                <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataVencimento) || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Beneficiário</Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.beneficiario.nome || ' '}</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    CNPJ: {FormatarCnpjCpf(dados.beneficiario.cnpjCpf) || ' '}
                  </Text>
                </View>

                <Text>
                  {dados.beneficiario.endereco?.logradouro || ' '},{' '}
                  {dados.beneficiario.endereco?.numero},{' '}
                  {dados.beneficiario.endereco?.bairro}
                </Text>

                <Text>
                  {dados.beneficiario.endereco?.cep || ' '},{' '}
                  {dados.beneficiario.endereco?.nomeCidade},{' '}
                  {dados.beneficiario.endereco?.siglaEstado}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={styles.descricaoCampo}>
                  Agência/Código Beneficiário
                </Text>
                <Text style={{ textAlign: 'right' }}>
                  {`${
                    dados.agencia
                  }/${dados.codigoBeneficiario.CompletaCaracteres(
                    13,
                    '0',
                    false
                  )}` || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Data do documento</Text>
                <Text>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataDocumento) || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Número do Documento</Text>
                <Text>{dados.numeroDocumento || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Espécie Doc</Text>
                <Text style={{ textAlign: 'center' }}>
                  {dados.especieDocumento || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Aceite</Text>
                <Text style={{ textAlign: 'center' }}>
                  {dados.aceite || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Data do documento</Text>
                <Text>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataProcessamento) ||
                    ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Nosso Número</Text>
                <Text style={{ textAlign: 'right' }}>
                  {dados.nossoNumero || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Uso do banco</Text>
                <Text>{dados.usoBanco || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Carteira</Text>
                <Text>{dados.carteira || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Espécie</Text>
                <Text>R$ {dados.especie || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Quantidade</Text>
                <Text>{dados.quantidade || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Valor</Text>
                <Text>{dados.valor || ' '}</Text>
              </View>

              <View
                style={[styles.blockBorderLeft, { width: '20%', padding: 0 }]}
              >
                <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                  (=) Valor do Documento
                </Text>
                <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {dados.valorDocumento || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>
                  Instruções (Todas as informações deste boleto são de exclusiva
                  responsabilidade do beneficiário)
                </Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.instrucoes || ' '}</Text>
                </View>
              </View>

              <View
                style={[styles.blockBorderLeft, { width: '20%', padding: 0 }]}
              >
                <View style={{ borderBottom: '1pt solid black' }}>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (-) Desconto / Abatimento
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.desconto || ' '}
                  </Text>
                </View>

                <View style={{ borderBottom: '1pt solid black' }}>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (+) Mora / Multa
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.multa || ' '}
                  </Text>
                </View>

                <View>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (=) Valor Cobrado
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.valorCobrado || ' '}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Nome do Pagador</Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.pagador.nome || ' '}</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    CNPJ/CPF: {FormatarCnpjCpf(dados.pagador.cnpjCpf) || ' '}
                  </Text>
                </View>

                <Text>
                  {dados.pagador.endereco?.logradouro || ' '},{' '}
                  {dados.pagador.endereco?.numero},{' '}
                  {dados.pagador.endereco?.bairro}
                </Text>

                <Text>
                  {dados.pagador.endereco?.cep || ' '},{' '}
                  {dados.pagador.endereco?.nomeCidade},{' '}
                  {dados.pagador.endereco?.siglaEstado}
                </Text>
              </View>
            </View>

            <View style={[styles.linha, { paddingTop: 5 }]}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text>Sacador/Avalista</Text>
              </View>
            </View>
            <Text style={{ marginLeft: 'auto', paddingRight: 15 }}>
              Autentificação mecânica
            </Text>
          </View>

          <View>
            <View style={styles.linha}>
              <View style={{ width: '15%', height: '100%' }}>
                <Image src={`${dados.logotipoUrl}`} />
              </View>

              <View
                style={[
                  styles.blockBorderLeft,
                  {
                    alignItems: 'center',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    fontSize: 13,
                  },
                ]}
              >
                <Text>{dados.codigoBanco || ' '}</Text>
              </View>

              <View
                style={[
                  styles.blockBorderLeft,
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                ]}
              >
                <Text style={{ fontSize: 12 }}>
                  {dados.codigoBoleto || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Local Pagamento</Text>
                <Text>{dados.localPagamento || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Vencimento</Text>
                <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataVencimento) || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Beneficiário</Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.beneficiario.nome || ' '}</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    CNPJ: {FormatarCnpjCpf(dados.beneficiario.cnpjCpf) || ' '}
                  </Text>
                </View>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={styles.descricaoCampo}>
                  Agência/Código Beneficiário
                </Text>
                <Text style={{ textAlign: 'right' }}>
                  {`${
                    dados.agencia
                  }/${dados.codigoBeneficiario.CompletaCaracteres(
                    13,
                    '0',
                    false
                  )}` || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Data do documento</Text>
                <Text>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataDocumento) || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Número do Documento</Text>
                <Text>{dados.numeroDocumento || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Espécie Doc</Text>
                <Text style={{ textAlign: 'center' }}>
                  {dados.especieDocumento || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Aceite</Text>
                <Text style={{ textAlign: 'center' }}>
                  {dados.aceite || ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Data do documento</Text>
                <Text>
                  {FormatarDataParaPtBrSemTimeZone(dados.dataProcessamento) ||
                    ' '}
                </Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Nosso Número</Text>
                <Text style={{ textAlign: 'right' }}>
                  {dados.nossoNumero || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Uso do banco</Text>
                <Text>{dados.usoBanco || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Carteira</Text>
                <Text>{dados.carteira || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '10%' }]}>
                <Text style={[styles.descricaoCampo]}>Espécie</Text>
                <Text>R$ {dados.especie || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Quantidade</Text>
                <Text>{dados.quantidade || ' '}</Text>
              </View>

              <View style={[styles.blockBorderLeft, { width: '20%' }]}>
                <Text style={[styles.descricaoCampo]}>Valor</Text>
                <Text>{dados.valor || ' '}</Text>
              </View>

              <View
                style={[styles.blockBorderLeft, { width: '20%', padding: 0 }]}
              >
                <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                  (=) Valor do Documento
                </Text>
                <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {dados.valorDocumento || ' '}
                </Text>
              </View>
            </View>

            <View style={styles.linha}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>
                  Instruções (Todas as informações deste boleto são de exclusiva
                  responsabilidade do beneficiário)
                </Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.instrucoes || ' '}</Text>
                </View>
              </View>

              <View
                style={[styles.blockBorderLeft, { width: '20%', padding: 0 }]}
              >
                <View style={{ borderBottom: '1pt solid black' }}>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (-) Desconto / Abatimento
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.desconto || ' '}
                  </Text>
                </View>

                <View style={{ borderBottom: '1pt solid black' }}>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (+) Mora / Multa
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.multa || ' '}
                  </Text>
                </View>

                <View>
                  <Text style={[styles.descricaoCampo, { padding: '0 2' }]}>
                    (=) Valor Cobrado
                  </Text>
                  <Text style={{ textAlign: 'right' }}>
                    {dados.valorCobrado || ' '}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text style={[styles.descricaoCampo]}>Nome do Pagador</Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>{dados.pagador.nome || ' '}</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    CNPJ/CPF: {FormatarCnpjCpf(dados.pagador.cnpjCpf) || ' '}
                  </Text>
                </View>

                <Text>
                  {dados.pagador.endereco?.logradouro || ' '},{' '}
                  {dados.pagador.endereco?.numero},{' '}
                  {dados.pagador.endereco?.bairro},{' '}
                  {dados.pagador.endereco?.complemento}
                </Text>

                <View style={{ flexDirection: 'row', paddingRight: 15 }}>
                  <Text>
                    {dados.pagador.endereco?.cep || ' '},{' '}
                    {dados.pagador.endereco?.nomeCidade},{' '}
                    {dados.pagador.endereco?.siglaEstado}
                  </Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    Código de Baixa: {dados.codigoBaixa}
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.linha, { paddingTop: 5 }]}>
              <View style={[styles.blockNoBorder, { width: '80%' }]}>
                <Text>Sacador/Avalista</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Image style={{ height: '15mm', width: '105mm' }} src={barCode} />
              <Text style={{ marginLeft: 'auto', paddingRight: 15 }}>
                Autentificação mecânica / FICHA DE COMPENSAÇÃO
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ImpressaoBoletoPDF;
