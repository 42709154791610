import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import {
  DestinadoEnum,
  FormatarDataParaPtBr,
  FormatarEnum,
  IImportacaoXmlTerceiroLista,
  TipoEntradaSaidaOperacaoFiscalEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import Select from '../../../../../../../Componentes/Select';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import Divisor from '../../../../../../../Componentes/Divisor';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoCteTerceiro/Comunicador/ImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicador';
import TratarInfo from '../../../../../../../Util/Info/TratarInfo';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';

interface IImportarCteSelecionadosLoteProps {
  onFecharFormModalLista(): void;
  obterItensSelecionados: () => IImportacaoXmlTerceiroLista[];
  listaCTeSelecionados: IImportacaoXmlTerceiroLista[];
}

const ImportarCteSelecionadosLote: React.FC<
  IImportarCteSelecionadosLoteProps
> = ({
  onFecharFormModalLista,
  obterItensSelecionados,
  listaCTeSelecionados,
}) => {
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);

  const [listaValor, setListaValor] = useState<IImportacaoXmlTerceiroLista[]>(
    []
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          destinado: Yup.mixed()
            .oneOf(
              Object.values(DestinadoEnum),
              'Destinado deve ser um desses Valores: Industrialização, Comercialização, Uso e Consumo, Ativo Imobilizado'
            )
            .required('Destinado é obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });

        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);

        formRefDetalhe.current?.setErrors(errors);
        return false;
      }
    },
    [formRefDetalhe]
  );

  const handleConcluir = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRefDetalhe.current?.getData();
      if (!(await handleValidar(data))) {
        setLoading(false);
        return;
      }

      const { listaMensagem, listaMensagemErro, mensagem } =
        await ImportacaoXmlTerceiroCteSelecionadosSalvarImportadoComunicador.update(
          {
            params: {
              listaValor,
              data,
            },
          }
        );

      if (listaMensagemErro) {
        TratarErros({ listaMensagem: listaMensagemErro });
      }

      if (listaMensagem.length > 0) {
        ToastSucesso(mensagem);
        TratarInfo(listaMensagem);
      }

      onFecharFormModalLista();
      setLoading(false);
    } catch (err) {
      TratarErros(err);
      setLoading(false);
    }
  }, [handleValidar, listaValor, onFecharFormModalLista]);

  useEffect(() => {
    if (listaCTeSelecionados && listaCTeSelecionados.length > 0)
      setListaValor(listaCTeSelecionados);
  }, [listaCTeSelecionados, obterItensSelecionados]);

  return (
    <JanelaDetalhe
      titulo="Importar Conhecimentos de Transporte de Terceiros"
      tamanho="xl"
      onFecharFormModal={onFecharFormModalLista}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body style={{ maxHeight: 500, overflow: 'auto' }}>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={3} md={12} sm={12}>
                  <InputDate name="dataLancamento" label="Data Lançamento" />
                </Col>
                <Col lg={3} md={12} sm={12}>
                  <InputDate
                    name="dataAquisicaoPrestacaoServico"
                    label="Dt. Aq./Prest. Serviço"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Select
                    label="Destinado"
                    name="destinado"
                    options={FormatarEnum({
                      enumObj: DestinadoEnum,
                      nullavel: true,
                    })}
                  />
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <InputAutoCompleteOperacaoFiscal
                    label="CFOP"
                    name="idOperacaoFiscal"
                    nomeObjeto="operacaoFiscal"
                    placeholder="CFOP"
                    tipoEntradaSaida={
                      TipoEntradaSaidaOperacaoFiscalEnum.entrada
                    }
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Número</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Série</span>
                  </th>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>CNPJ/CPF</span>
                  </th>
                  <th className="lista-texto" style={{ width: '35%' }}>
                    <span>Nome Razão Social</span>
                  </th>
                  <th className="lista-data" style={{ width: '15%' }}>
                    <span>Data Emissão</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Total</span>
                  </th>
                </tr>
              </thead>

              <TBody>
                {listaValor &&
                  listaValor.length &&
                  listaValor?.map((valor) => {
                    return (
                      <tr
                        key={v4()}
                        className={loading ? 'tabela-desabilitada' : ''}
                      >
                        <td className="lista-valor">
                          <div>{valor.numero}</div>
                        </td>
                        <td className="lista-valor">
                          <div>{valor.serie}</div>
                        </td>
                        <td className="lista-texto">
                          <div>{valor.cnpjCpfVirtual}</div>
                        </td>
                        <td className="lista-texto">
                          <div>{valor.nomeRazaoSocial}</div>
                        </td>
                        <td className="lista-data">
                          <div>
                            {FormatarDataParaPtBr(valor.dataHoraEmissao)}
                          </div>
                        </td>
                        <td className="lista-valor">
                          <div>
                            {Number(valor.valorTotal).FormatarParaPtBr()}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </TBody>
            </Table>
          </Tabela>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModalLista}
              disabled={loading}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                <TextoLoading loading={loading}>
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </TextoLoading>
              </span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleConcluir}
              disabled={listaValor.length === 0 || loading}
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>Confirmar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ImportarCteSelecionadosLote;
