import api from '../../../../../Comum/Services/Api';

const rota = 'geral/cidade-auto-complete-tag';

interface ICidadeAutoCompleteTagComunicadorIndex {
  params: any;
}

class CidadeAutoCompleteTagComunicador {
  public async index(
    params: ICidadeAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CidadeAutoCompleteTagComunicador();
