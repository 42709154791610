/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Detalhe from '../../../../../Componentes/Detalhe';
import ILocation from '../../../../../Componentes/Detalhe/Interface/ILocation';
import Main from './Main';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseTreeContext } from '../Hooks/TreeContext';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import DemonstrativoResultadoLogComunicador from '../../../../../Comunicador/Financeiro/CategoriaDRE/DemonstrativoResultado/Log/Comunicador/DemonstrativoResultadoLogComunicador';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';

interface IDemonstrativoResultadoDetalheProps {
  refreshLista: () => Promise<void>;
}

const DemonstrativoResultadoDetalhe: React.FC<
  IDemonstrativoResultadoDetalheProps
> = ({ refreshLista }) => {
  const { formRef } = UseForm();
  const { permissoes } = UsePermissoes();
  const location = useLocation();
  const state = location.state as ILocation;

  const { setIdShowItem, idShowItem, setIdItemPai, idItemPai, buscarDados } =
    UseTreeContext();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { setFuncoes: setFuncoesPesquisa } = UseTableDraggable();

  useEffect(() => {
    setFuncoesPesquisa({
      pesquisarDados: buscarDados,
      onChangeOrdem: () => {},
      onChangeVisivel: () => {},
      onChangeTamanho: () => {},
    });
  }, [buscarDados, setFuncoesPesquisa]);

  useEffect(() => {
    formRef.current?.setErrors({});
  }, [formRef, idShowItem]);

  return (
    <div>
      <SubHeader
        titulo={
          idShowItem
            ? 'Editar Demonstrativo de Resultado'
            : 'Demonstrativo de Resultado'
        }
        permissao={permissoes.FinanceiroCategoriaDreDemonstrativoResultado}
        botaoNovo={!!(idShowItem || idItemPai)}
        onClickBotaoNovo={() => {
          if (state && state.dadosRecuperados) {
            delete (state as any).dadosRecuperados;
          }

          setIdShowItem({ id: '' });
          setIdItemPai('');
        }}
        hasCamposDaListagem={false}
        configuracaoItens={
          <>
            <Dropdown.Item
              onClick={() => {
                abrirJanelaLog(async (params) => {
                  return DemonstrativoResultadoLogComunicador.index({
                    params,
                  });
                });
              }}
            >
              <span style={{ display: 'flex', fontSize: 16 }}>
                <CgPlayListSearch style={{ marginRight: 5 }} />
                Logs
              </span>
            </Dropdown.Item>
          </>
        }
      />

      <Detalhe style={{ padding: 0 }}>
        <Main
          idEditar={idShowItem ?? null}
          dadosRecuperados={state?.dadosRecuperados ?? null}
          dadosDuplicados={state?.dadosDuplicados ?? null}
          onClickSalvar={refreshLista}
        />
      </Detalhe>
    </div>
  );
};

export default DemonstrativoResultadoDetalhe;
