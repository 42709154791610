import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { ListaCodigoRastreabilidadeGrupoProdutoDraggableHook } from './ListaCodigoRastreabilidadeGrupoProdutoDraggableHook';
import RotasHook from './RotasHook';

const GrupoProdutoCodigoRastreabilidadeHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <RotasHook>
      <FormHook>
        <ListaCodigoRastreabilidadeGrupoProdutoDraggableHook>
          {children}
        </ListaCodigoRastreabilidadeGrupoProdutoDraggableHook>
      </FormHook>
    </RotasHook>
  );
};

export default GrupoProdutoCodigoRastreabilidadeHook;
