import React from 'react';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar:
          '/transporte/manifesto-transporte/documento-referenciado/lista-documentos',
        novo: '/transporte/manifesto-transporte',
        alterar: '',
        excluir: '',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
