import { IOrdemDesmontagemItemCustoLista } from '@elogestor/util';
import React, { useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../../../../../../Componentes/Tabela/styles';
import Divisor from '../../../../../../../../../../../Componentes/Divisor';

interface IListaDetalheGeral {
  name: string;
}

const ListaCusto: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const formPrincipal = UseForm();

  const { formRefLista } = UseListaDetalheForm();

  const valorRef = useRef<IOrdemDesmontagemItemCustoLista[]>([]);
  const [listaValor, setListaValor] = useState<
    IOrdemDesmontagemItemCustoLista[]
  >([]);
  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IOrdemDesmontagemItemCustoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '20%' }}>
                    <span>Regime Tributario</span>
                  </th>
                  <th className="lista-texto" style={{ width: '15%' }}>
                    <span>Tipo Custo</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Quantidade</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Valor Unit. Custo</span>
                  </th>
                  <th className="lista-valor" style={{ width: '10%' }}>
                    <span>Valor Tot. Custo</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">{item.regimeTributario}</td>
                      <td className="lista-texto">{item.tipoCusto}</td>
                      <td className="lista-valor">{item.quantidade}</td>
                      <td className="lista-valor">{item.valorUnitarioCusto}</td>
                      <td className="lista-valor">{item.valorTotalCusto}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaCusto;
