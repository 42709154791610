const PedidoCompraPorProdutoPlanilha = [
  { titulo: 'Codigo do Produto', valor: 'listaPedidoCompraItem.produtoCodigo' },

  {
    titulo: 'Nome do Produto',
    valor: 'listaPedidoCompraItem.produtoDescricao',
  },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaPedidoCompraItem.pedidoCompraDataHoraEmissao',
  },

  {
    titulo: 'Numero do Orçamento',
    valor: 'listaPedidoCompraItem.pedidoCompraNumero',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaPedidoCompraItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaPedidoCompraItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaPedidoCompraItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaPedidoCompraItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaPedidoCompraItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaPedidoCompraItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaPedidoCompraItem.valorTotal',
  },
];

export default PedidoCompraPorProdutoPlanilha;
