import React, { createContext, useContext } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import IParametroDados from '../Interface/IParametroDados';

type IParametroContext = IParametroDados;
interface IParametroProvider extends IPadraoProps {
  parametroValores: IParametroContext;
}

const ParametroContext = createContext({} as IParametroContext);
const ParametroProvider: React.FC<IParametroProvider> = ({
  children,
  parametroValores,
}) => {
  return (
    <ParametroContext.Provider value={{ ...parametroValores }}>
      {children}
    </ParametroContext.Provider>
  );
};

function UseParametro(): IParametroContext {
  const context = useContext(ParametroContext);
  return context;
}

export { ParametroProvider, UseParametro };
