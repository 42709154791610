import api from '../../../../Comum/Services/Api';

const rota = '/configuracao/conta-de-email/conta-de-email-auto-complete';

interface IContaEmailAutoCompleteComunicadorIndex {
  params: any;
}

class ContaEmailAutoCompleteComunicador {
  public async index(
    params: IContaEmailAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new ContaEmailAutoCompleteComunicador();
