import React from 'react';
import { Ul } from './styles';
import IMenuDados from './Interface/IMenuDados';

const Menu: React.FC<IMenuDados> = ({ listaMenu }) => {
  return (
    <Ul>
      {listaMenu.map((menu) => {
        return <li key={menu.id}>{menu.RenderMenu({ totalMargin: 0 })}</li>;
      })}
    </Ul>
  );
};

export default Menu;
