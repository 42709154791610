import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import DemonstrativoResultadoComunicador from '../../../../../../Comunicador/Financeiro/CategoriaDRE/DemonstrativoResultado/Comunicador/DemonstrativoResultadoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';

const DemonstrativoResultadoDetalheHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: DemonstrativoResultadoComunicador }}
    >
      <RotasHook>
        <FormHook>{children}</FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default DemonstrativoResultadoDetalheHook;
