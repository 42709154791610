/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { InputGroup } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from 'react-icons/bs/index.mjs';
import {
  CustomDefaultInput,
  CustomInputGroupText,
  DefaultInput,
  InputContainer,
  SpanErro,
} from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputSenhaProps {
  valorAnterior: string;
}

export interface IInputSenhaRef {
  focus(): void;
}

interface IInputSenhaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  ocultarExibirSenhaButton?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputSenhaProps
  ) => void | Promise<void>;
}

const InputSenha: React.ForwardRefRenderFunction<
  IInputSenhaRef,
  IInputSenhaProps
> = (
  { name, label, ocultarExibirSenhaButton, onChange, onFocus, onBlur, ...rest },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);
  const [mostrarSenha, setMostrarSenha] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      setIsFocused(false);

      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.value || '';
      },

      setValue(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      setSemExecutarEvento(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ocultarExibirSenhaButton ? (
    <InputContainer>
      {label && <label>{label}</label>}
      <InputGroup
        className="d-flex align-items-flex-start"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }}
      >
        <CustomDefaultInput
          style={{ textAlign: 'right', width: '90%' }}
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type={mostrarSenha ? 'text' : 'password'}
          onFocus={handleInputFocus}
          onChange={(event) => {
            setErro('');

            if (onChange)
              onChange(event, { valorAnterior: valorAnterior.current });
          }}
          onBlur={handleInputBlur}
          {...rest}
          ref={inputRef}
        />
        {erro && <SpanErro>{erro}</SpanErro>}
        <CustomInputGroupText
          $isFilled={isFilled}
          $isErrored={!!erro}
          $isFocused={isFocused}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMostrarSenha(!mostrarSenha)}
        >
          {mostrarSenha ? <BsEyeSlash /> : <BsEye />}
        </CustomInputGroupText>
      </InputGroup>
    </InputContainer>
  ) : (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="password"
        name={fieldName}
        onFocus={handleInputFocus}
        onChange={(event) => {
          setErro('');

          if (onChange)
            onChange(event, { valorAnterior: valorAnterior.current });
        }}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputSenha);
