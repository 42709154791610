import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import { ListaRoteiroFabricacaoPadraoOperacaoDraggableHook } from './ListaRoteiroFabricacaoPadraoOperacaoDraggableHook';
import RotasHook from './RotasHook';

const RoteiroFabricacaoPadraoOperacaoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <RotasHook>
      <ListaRoteiroFabricacaoPadraoOperacaoDraggableHook>
        <FormHook>{children}</FormHook>
      </ListaRoteiroFabricacaoPadraoOperacaoDraggableHook>
    </RotasHook>
  );
};

export default RoteiroFabricacaoPadraoOperacaoHook;
