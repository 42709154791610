import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/orcamento/duplicar';

interface IOrcamentoDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class OrcamentoDuplicarComunicador {
  public async store(params: IOrcamentoDuplicarComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new OrcamentoDuplicarComunicador();
