import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/bandeira-cartao-auto-complete-tag';

interface IBandeiraCartaoAutoCompleteTagComunicadorIndex {
  params: any;
}

class BandeiraCartaoAutoCompleteTagComunicador {
  public async index(
    params: IBandeiraCartaoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new BandeiraCartaoAutoCompleteTagComunicador();
