import React, { createContext, useState, useContext } from 'react';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';

interface IButtonsSubHeaderContextData {
  disableExcluir: boolean;
  disableDuplicar: boolean;
  disableDuplicarSalvar: boolean;
  disableSalvar: boolean;
  setDisableExcluir: (valor: boolean) => void;
  setDisableDuplicar: (valor: boolean) => void;
  setDisableDuplicarSalvar: (valor: boolean) => void;
  setDisableSalvar: (valor: boolean) => void;
}

const ButtonsSubHeaderContext = createContext<IButtonsSubHeaderContextData>(
  {} as IButtonsSubHeaderContextData
);

const ButtonsSubHeaderProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [disableExcluir, setDisableExcluir] = useState<boolean>(false);
  const [disableDuplicar, setDisableDuplicar] = useState<boolean>(false);
  const [disableDuplicarSalvar, setDisableDuplicarSalvar] =
    useState<boolean>(false);
  const [disableSalvar, setDisableSalvar] = useState<boolean>(false);

  return (
    <ButtonsSubHeaderContext.Provider
      value={{
        disableExcluir,
        disableDuplicar,
        disableDuplicarSalvar,
        disableSalvar,
        setDisableExcluir,
        setDisableDuplicar,
        setDisableDuplicarSalvar,
        setDisableSalvar,
      }}
    >
      {children}
    </ButtonsSubHeaderContext.Provider>
  );
};

function UseButtonsSubHeader(): IButtonsSubHeaderContextData {
  const context = useContext(ButtonsSubHeaderContext);

  if (!context) {
    throw new Error(
      'UseButtonsSubHeader must be used within an ButtonsSubHeaderProvider'
    );
  }

  return context;
}

export { ButtonsSubHeaderProvider, UseButtonsSubHeader };
