"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var BandeiraCartaoEnum;
(function (BandeiraCartaoEnum) {
    BandeiraCartaoEnum["visa"] = "01 - Visa";
    BandeiraCartaoEnum["mastercard"] = "02 - Mastercard";
    BandeiraCartaoEnum["americanExpress"] = "03 - American Express";
    BandeiraCartaoEnum["sorocred"] = "04 - Sorocred";
    BandeiraCartaoEnum["dinersClub"] = "05 - Diners Club";
    BandeiraCartaoEnum["elo"] = "06 - Elo";
    BandeiraCartaoEnum["hipercard"] = "07 - Hipercard";
    BandeiraCartaoEnum["aura"] = "08 - Aura";
    BandeiraCartaoEnum["cabal"] = "09 - Cabal";
    BandeiraCartaoEnum["outros"] = "99 - Outros";
})(BandeiraCartaoEnum || (BandeiraCartaoEnum = {}));
exports.default = BandeiraCartaoEnum;
