import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-pedido-compra/importar';

interface INotaFiscalEntradaImportacaoPedidoCompraImportarComunicadorStore {
  params: any;
}

class NotaFiscalEntradaImportacaoPedidoCompraImportarComunicador {
  public async store(
    params: INotaFiscalEntradaImportacaoPedidoCompraImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoPedidoCompraImportarComunicador();
