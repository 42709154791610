/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { IoMdArrowDropdown } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UseTableDraggable } from './HooksTable/TableDraggableContext';
import ThDraggable from './ThDraggable';
import { ThContent, ArrowDiv } from './styles';
import { TBody } from '../Styles/TBody';

interface ITh {
  titulo: string;
  nomeCampo: string;
  arrastavel: boolean;
  visivel: boolean;
  ordenar: boolean;
  tamanho: number | null;
  permitirEsconder: boolean;
  style?: React.CSSProperties;
  campoPersonalizado?: any;
}

interface IPesquisa {
  pesquisaAvancada?: object;
  order?: string;
  descAsc?: string;
  limite?: number;
  pagina?: number;
  textoPesquisa?: string;
}

interface ITableDraggable {
  listaTh: ITh[];
  listaDado: any[];
  getKey(item: any): string | number;
  onChangeOrdem(novaListaThs: ITh[]): Promise<void>;
  onChangeTamanho(novaTh: ITh): Promise<void>;
  onChangeVisivel(novaTh: ITh): Promise<void>;
  onChangeOrdemPesquisa(novoCampo: string, descAsc: 'asc' | 'desc'): void;
  onClickLinha(
    item: any,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    index: number
  ): void;
  onPesquisar(parametros: IPesquisa): Promise<void>;
  permitirAtualizar?: () => Promise<boolean>;
  detalhe: boolean;
  refresh?: boolean;
}

const TableDraggable: React.FC<ITableDraggable> = ({
  listaTh,
  listaDado,
  getKey,
  onChangeOrdem,
  onChangeTamanho,
  onChangeVisivel,
  onChangeOrdemPesquisa,
  onClickLinha,
  onPesquisar,
  permitirAtualizar,
  detalhe,
  refresh,
}) => {
  const [idTh] = useState(v4());
  const { setListaTh, ths, setFuncoes } = UseTableDraggable();

  const [descAsc, setDescAsc] = useState('desc');
  const [campoAtual, setCampoAtual] = useState('');

  useEffect(() => {
    setListaTh(listaTh);
  }, [listaTh, setListaTh]);

  useEffect(() => {
    setFuncoes({
      onChangeOrdem,
      onChangeTamanho,
      onChangeVisivel,
      pesquisarDados: onPesquisar,
    });
  }, [
    onChangeOrdem,
    onChangeTamanho,
    onChangeVisivel,
    onPesquisar,
    setFuncoes,
    refresh,
  ]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handleChangeOrdemPesquisa = useCallback(
    async (th: ITh) => {
      if (await handlePermitirAtualizar()) {
        let auxDescAsc = descAsc as 'asc' | 'desc' | null;
        if (campoAtual === th.nomeCampo) {
          if (descAsc === 'desc') {
            auxDescAsc = 'asc';
          } else {
            auxDescAsc = 'desc';
          }
        } else {
          setCampoAtual(th.nomeCampo);
          auxDescAsc = 'desc';
        }

        setDescAsc(auxDescAsc);
        onChangeOrdemPesquisa(th.nomeCampo, auxDescAsc);
      }
    },
    [campoAtual, descAsc, handlePermitirAtualizar, onChangeOrdemPesquisa]
  );

  const renderCabecalho = useMemo(() => {
    return ths.map((th, index) => {
      if (!th.visivel) return <React.Fragment key={getKey(th)} />;
      let opacityDesc = 1;
      let opacityAsc = 1;
      if (campoAtual === th.nomeCampo) {
        if (descAsc === 'desc') {
          opacityAsc = 0;
        } else {
          opacityDesc = 0;
        }
      }

      return th.arrastavel ? (
        <ThDraggable
          key={getKey(th)}
          width={th.tamanho}
          index={index}
          style={th.style}
          idDrag={idTh}
        >
          <ThContent>
            {th.titulo}
            {th.ordenar && (
              <button
                type="button"
                onClick={async () => {
                  await handleChangeOrdemPesquisa(th);
                }}
              >
                <ArrowDiv>
                  <IoMdArrowDropdown style={{ opacity: opacityAsc }} />
                  <IoMdArrowDropdown style={{ opacity: opacityDesc }} />
                </ArrowDiv>
              </button>
            )}
          </ThContent>
        </ThDraggable>
      ) : th.campoPersonalizado ? (
        <th key={getKey(th)} style={th.style}>
          {th.campoPersonalizado}
        </th>
      ) : (
        <th key={getKey(th)} style={th.style}>
          {th.titulo}
        </th>
      );
    });
  }, [campoAtual, descAsc, getKey, handleChangeOrdemPesquisa, idTh, ths]);

  const renderDados = useMemo(() => {
    return listaDado.map((dado, index) => {
      return (
        <tr
          key={index}
          onMouseDown={(event) => {
            if (!detalhe) return;
            if ((event as any).target.type !== 'checkbox')
              onClickLinha(dado, event, index);
          }}
        >
          {ths.map((th) => {
            if (!th.visivel) return <React.Fragment key={getKey(th)} />;
            const valor = dado[th.nomeCampo];

            return <td key={getKey(th)}>{valor}</td>;
          })}
        </tr>
      );
    });
  }, [detalhe, getKey, listaDado, onClickLinha, ths]);

  return (
    <Table striped bordered hover style={{ width: 'max-content' }}>
      <thead>
        <tr>{renderCabecalho}</tr>
      </thead>
      <TBody>{renderDados}</TBody>
    </Table>
  );
};

export default TableDraggable;
