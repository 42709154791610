import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/produtos/estoque/custo/obter-valor-unitario-custo-saida';

interface IObterValorUnitarioCustoSaidaComunicadorIndex {
  params: {
    dataMovimento: Date;
    idProduto: string;
    idPessoa?: string;
    indicadorTipoEstoque: string;
  };
}

class ObterValorUnitarioCustoSaidaComunicador {
  public async index(
    params: IObterValorUnitarioCustoSaidaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ObterValorUnitarioCustoSaidaComunicador();
