import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/gerar-pedido-venda';

interface IFrenteCaixaPdvGerarPedidoVendaComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarPedidoVendaComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarPedidoVendaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarPedidoVendaComunicador();
