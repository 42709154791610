import React from 'react';
import { Button } from './styles';
import IMenuFilhoDados from './Interface/IMenuFilhoDados';

const MenuFilho: React.FC<IMenuFilhoDados> = ({
  parametro,
  adicionarItemListaTab,
  valorMudou,
  totalMargin,
}) => {
  return (
    <Button
      $valorMudou={valorMudou}
      type="button"
      onClick={() => {
        adicionarItemListaTab(parametro);
      }}
    >
      <span
        style={{
          marginLeft: totalMargin,
          textAlign: 'initial',
          fontSize: '14px',
        }}
      >
        {parametro.descricao}
      </span>
    </Button>
  );
};

export default MenuFilho;
