"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var InstrucaoBoletoSicrediEnum;
(function (InstrucaoBoletoSicrediEnum) {
    InstrucaoBoletoSicrediEnum["cadastroTitulos"] = "01";
    InstrucaoBoletoSicrediEnum["pedidoBaixa"] = "02";
    InstrucaoBoletoSicrediEnum["concessaoAbatimento"] = "04";
    InstrucaoBoletoSicrediEnum["cancelamentoAbatimento"] = "05";
    InstrucaoBoletoSicrediEnum["alteracaoVencimento"] = "06";
    InstrucaoBoletoSicrediEnum["pedidoProtesto"] = "09";
    InstrucaoBoletoSicrediEnum["sustarProtestoBaixarTitulo"] = "18";
    InstrucaoBoletoSicrediEnum["sustarProtestoManterEmCarteira"] = "19";
    InstrucaoBoletoSicrediEnum["alteracaoOutrosDados"] = "31";
    InstrucaoBoletoSicrediEnum["incluirNegativacao"] = "45";
    InstrucaoBoletoSicrediEnum["excluirNegativacaoMmanterNaCarteira"] = "75";
    InstrucaoBoletoSicrediEnum["excluirNegativacaoBaixarTitulos"] = "76";
})(InstrucaoBoletoSicrediEnum || (InstrucaoBoletoSicrediEnum = {}));
exports.default = InstrucaoBoletoSicrediEnum;
