import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-itens-pedidos-compra';

interface ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    listaPedidosComprasVinculadosFront?: any[];
  };
}

class ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicador {
  public async index(
    params: ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterItensPedidosComprasComunicador();
