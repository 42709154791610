const EstoqueSaldoProprioEmPoderProprioMinimoMaximoPlanilha = [
  { titulo: 'Código', valor: 'estoqueCombinacao.produto.codigo' },
  { titulo: 'Descrição', valor: 'estoqueCombinacao.produto.descricao' },
  {
    titulo: 'Unidade de Medida',
    valor: 'estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'saldo' },
  { titulo: 'Mínimo', valor: 'minimo' },
  { titulo: 'Máximo', valor: 'maximo' },
  {
    titulo: 'Quantidade Necessária p/ Estoque Mínimo',
    valor: 'necessarioParaEstoqueMinimo',
  },
  {
    titulo: 'Quantidade Necessária p/ Estoque Máximo',
    valor: 'necessarioParaEstoqueMaximo',
  },
];

export default EstoqueSaldoProprioEmPoderProprioMinimoMaximoPlanilha;
