import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/reprocessamento-producao/impressao';

interface IReprocessamentoProducaoImpressaoComunicadorShow {
  id: string;
}

class ReprocessamentoProducaoImpressaoComunicador {
  public async show(
    params: IReprocessamentoProducaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ReprocessamentoProducaoImpressaoComunicador();
