import {
  IndicadorPresencaPadraoEnum,
  TipoMercadoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/configuracao-tributaria';

interface IConfiguracaoTributariaComunicadorShow {
  params: {
    dataPesquisaVigencia: Date;
    idPessoa?: string;
    idProduto?: string;
    idTipoNota: string;
    idSerie?: string;
    idTipoNotaMotivo: string;
    destinado?: string;
    finalidadeEmissao?: string;
    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum;
    idPessoaDestinatario?: string;
    idPessoaRemetente?: string;
    tipoMercado?: TipoMercadoEnum;
    idModeloDocumento?: string;
    idOrigemIcms?: string;
  };
}

class ConfiguracaoTributariaComunicador {
  public async show(
    params: IConfiguracaoTributariaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new ConfiguracaoTributariaComunicador();
