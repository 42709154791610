import { IOrcamentoRetratoComPrevisaoEntregaEmDiasImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoPDF from './OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoPDF';

interface IImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias {
  handleVisualizarPdf(
    data: IOrcamentoRetratoComPrevisaoEntregaEmDiasImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IOrcamentoRetratoComPrevisaoEntregaEmDiasImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias =
  (): IImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias => {
    async function handleVisualizarPdf(
      dados: IOrcamentoRetratoComPrevisaoEntregaEmDiasImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IOrcamentoRetratoComPrevisaoEntregaEmDiasImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias();
