import React, { useCallback, useState } from 'react';
import {
  FormatarCnpj,
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaAliquotaLista,
  RegraEscolhaTipoEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaAliquotaComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaComunicador';
import RegraEscolhaAliquotaOrdemComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaOrdemComunicador';
import RegraEscolhaAliquotaLogComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Log/Comunicador/RegraEscolhaAliquotaLogComunicador';

const RegraEscolhaAliquotaLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaAliquotaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IRegraEscolhaAliquotaLista) => {
            return {
              id: data.id,

              'listaRegraEscolhaAliquotaRegimeTributarioEmitente.regimeTributario':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaAliquotaRegimeTributarioEmitente
                      ? data.listaRegraEscolhaAliquotaRegimeTributarioEmitente.map(
                          (valor) => {
                            return (
                              <div key={valor.regimeTributario}>
                                {`${valor.regimeTributario};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaAliquotaDestinado.destinado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaDestinado
                    ? data.listaRegraEscolhaAliquotaDestinado.map((valor) => {
                        return (
                          <div key={valor.destinado}>
                            {`${valor.destinado};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              quantidadeCriterios: (
                <div className="lista-valor">{data.quantidadeCriterios}</div>
              ),

              'tipoNota.descricao': (
                <div className="lista-texto">
                  {data.tipoNota?.descricao || ''}
                </div>
              ),
              'tipoNotaMotivo.descricao': (
                <div className="lista-texto">
                  {data.tipoNotaMotivo?.descricao || ''}
                </div>
              ),

              contribuinte:
                data.contribuinte != null ? (
                  <div
                    className="lista-booleano"
                    style={{ color: data.contribuinte ? 'green' : 'red' }}
                  >
                    {data.contribuinte ? 'Sim' : 'Não'}
                  </div>
                ) : (
                  ''
                ),
              tipo: <div className="lista-texto">{data.tipo}</div>,
              cnpjContabilidade: (
                <div className="lista-texto">
                  {FormatarCnpj(String(data.cnpjContabilidade))}
                </div>
              ),
              descricao: <div className="lista-texto">{data.descricao}</div>,
              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaAliquotaNcm.codigo': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaNcm
                    ? data.listaRegraEscolhaAliquotaNcm.map((valor) => {
                        return (
                          <div key={valor.ncm.codigo}>
                            {`${valor.ncm.codigo};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaCest.codigo': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaCest
                    ? data.listaRegraEscolhaAliquotaCest.map((valor) => {
                        return (
                          <div key={valor.cest.codigo}>
                            {`${valor.cest.codigo};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaCstIcms.codigo': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaCstIcms
                    ? data.listaRegraEscolhaAliquotaCstIcms.map((valor) => {
                        return (
                          <div key={valor.codigoSituacaoTributaria.codigo}>
                            {`${valor.codigoSituacaoTributaria.codigo};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaProduto.produto.codigoDescricao': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaProduto
                    ? data.listaRegraEscolhaAliquotaProduto.map((valor) => {
                        return (
                          <div key={valor.produto.codigo}>
                            {`${valor.produto.codigo} - ${valor.produto.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaModeloDocumento.modeloDocumento.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaAliquotaModeloDocumento
                      ? data.listaRegraEscolhaAliquotaModeloDocumento.map(
                          (valor) => {
                            return (
                              <div key={valor.modeloDocumento.codigo}>
                                {`${valor.modeloDocumento.codigo} - ${valor.modeloDocumento.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaAliquotaEstado.estado.sigla': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaEstado
                    ? data.listaRegraEscolhaAliquotaEstado.map((valor) => {
                        return (
                          <div key={valor.estado.sigla}>
                            {`${valor.estado.sigla};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaCidade.cidade.nome': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaCidade
                    ? data.listaRegraEscolhaAliquotaCidade.map((valor) => {
                        return (
                          <div key={valor.cidade.nome}>
                            {`${valor.cidade.nome};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

              'listaRegraEscolhaAliquotaEstadoEmpresaLogada.estado.sigla': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaAliquotaEstadoEmpresaLogada
                    ? data.listaRegraEscolhaAliquotaEstadoEmpresaLogada.map(
                        (valor) => {
                          return (
                            <div key={valor.estado.sigla}>
                              {`${valor.estado.sigla};`}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcms':
                (
                  <div className="lista-valor">
                    {
                      data.regraEscolhaAliquotaResultado
                        ?.percentualReducaoBaseCalculoIcms
                    }
                  </div>
                ),
              'regraEscolhaAliquotaResultado.aliquotaIcms': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaIcms}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaFcpIcms': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaFcpIcms}
                </div>
              ),

              'regraEscolhaAliquotaResultado.modalidadeBaseCalculoIcmsSt': (
                <div className="lista-texto">
                  {
                    data.regraEscolhaAliquotaResultado
                      ?.modalidadeBaseCalculoIcmsSt
                  }
                </div>
              ),

              'regraEscolhaAliquotaResultado.aliquotaInternaIcmsSt': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaInternaIcmsSt}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaInterestadualIcmsSt': (
                <div className="lista-valor">
                  {
                    data.regraEscolhaAliquotaResultado
                      ?.aliquotaInterestadualIcmsSt
                  }
                </div>
              ),
              'regraEscolhaAliquotaResultado.mvaSubstituicaoTributaria': (
                <div className="lista-valor">
                  {
                    data.regraEscolhaAliquotaResultado
                      ?.mvaSubstituicaoTributaria
                  }
                </div>
              ),
              'regraEscolhaAliquotaResultado.mvaSubstituicaoTributariaSimplesNacional':
                (
                  <div className="lista-valor">
                    {
                      data.regraEscolhaAliquotaResultado
                        ?.mvaSubstituicaoTributariaSimplesNacional
                    }
                  </div>
                ),
              'regraEscolhaAliquotaResultado.valorUnitarioPautaIcmsSt': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.valorUnitarioPautaIcmsSt}
                </div>
              ),
              'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStRegimeNormal':
                (
                  <div className="lista-valor">
                    {
                      data.regraEscolhaAliquotaResultado
                        ?.percentualReducaoBaseCalculoIcmsStRegimeNormal
                    }
                  </div>
                ),
              'regraEscolhaAliquotaResultado.percentualReducaoBaseCalculoIcmsStSimplesNacional':
                (
                  <div className="lista-valor">
                    {
                      data.regraEscolhaAliquotaResultado
                        ?.percentualReducaoBaseCalculoIcmsStSimplesNacional
                    }
                  </div>
                ),
              'regraEscolhaAliquotaResultado.aliquotaIpi': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaIpi}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaPis': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaPis}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaPisRetido': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaPisRetido}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaCofins': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaCofins}
                </div>
              ),
              'regraEscolhaAliquotaResultado.aliquotaCofinsRetido': (
                <div className="lista-valor">
                  {data.regraEscolhaAliquotaResultado?.aliquotaCofinsRetido}
                </div>
              ),

              'listaRegraEscolhaAliquotaEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaAliquotaEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaAliquotaEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaAliquotaComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaAliquotaLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      !permissoes.FiscalTributacaoRegraEscolhaAliquota
                        ?.exclui ||
                      !(
                        data.tipo === RegraEscolhaTipoEnum.padrao &&
                        tipoUsuario !== 'Elo Gestor'
                      )
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.FiscalTributacaoRegraEscolhaAliquota?.exclui, tipoUsuario]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'fiscalRegraEscolhaAliquota' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaAliquotaOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha das Alíquotas"
          permissao={permissoes.FiscalTributacaoRegraEscolhaAliquota}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaAliquotaLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaAliquotaLista;
