import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/relatorios/ordem-servico-veiculo/ordem-servico-veiculo-resumo';

interface IOrdemServicoVeiculoResumoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrdemServicoVeiculoResumoRelatorioComunicador {
  public async index(
    params: IOrdemServicoVeiculoResumoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrdemServicoVeiculoResumoRelatorioComunicador();
