"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoPedidoVendaEnum;
(function (SituacaoPedidoVendaEnum) {
    SituacaoPedidoVendaEnum["pendente"] = "Pendente";
    SituacaoPedidoVendaEnum["aprovado"] = "Aprovado";
    SituacaoPedidoVendaEnum["cancelado"] = "Cancelado";
    SituacaoPedidoVendaEnum["aprovadoPendente"] = "Pendente e Aprovado";
})(SituacaoPedidoVendaEnum || (SituacaoPedidoVendaEnum = {}));
exports.default = SituacaoPedidoVendaEnum;
