import React from 'react';
import ListaCusto from './ListaCusto/Lista';

const CustoTab: React.FC = () => {
  return (
    <>
      <ListaCusto name="listaCusto" />
    </>
  );
};

export default CustoTab;
