const ConhecimentoTransporteTerceiroPorTomadorPlanilha = [
  { titulo: 'Tomador', valor: 'chave' },
  { titulo: 'Série', valor: 'listaConhecimentoTransporteTerceiros.serie' },
  { titulo: 'Número', valor: 'listaConhecimentoTransporteTerceiros.numero' },
  {
    titulo: 'Data Emissão',
    valor: 'listaConhecimentoTransporteTerceiros.dataEmissao',
  },
  {
    titulo: 'Notas Fiscais',
    valor: 'listaConhecimentoTransporteTerceiros.numeroNotaFiscal',
  },
  {
    titulo: 'Remetente',
    valor: 'listaConhecimentoTransporteTerceiros.remetente',
  },
  {
    titulo: 'Destinatário',
    valor: 'listaConhecimentoTransporteTerceiros.destinatario',
  },
  { titulo: 'Valor', valor: 'listaConhecimentoTransporteTerceiros.valorTotal' },
];

export default ConhecimentoTransporteTerceiroPorTomadorPlanilha;
