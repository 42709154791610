"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEmissaoNfeEnum;
(function (TipoEmissaoNfeEnum) {
    TipoEmissaoNfeEnum["emissaoNormal"] = "1 - Emiss\u00E3o Normal";
    TipoEmissaoNfeEnum["contingenciaFSIA"] = "2 - Conting\u00EAncia FS-IA";
    TipoEmissaoNfeEnum["contingenciaSCAN"] = "3 - Conting\u00EAncia SCAN";
    TipoEmissaoNfeEnum["contingenciaDPEC"] = "4 - Conting\u00EAncia DPEC";
    TipoEmissaoNfeEnum["contingenciaFSDA"] = "5 - Conting\u00EAncia FS-DA";
    TipoEmissaoNfeEnum["contingenciaSVCAN"] = "6 - Conting\u00EAncia SVC-AN";
    TipoEmissaoNfeEnum["contingenciaSVCRS"] = "7 - Conting\u00EAncia SVC-RS";
    TipoEmissaoNfeEnum["contingenciaOfflineNFCe"] = "9 - Conting\u00EAncia off-line da NFC-e";
})(TipoEmissaoNfeEnum || (TipoEmissaoNfeEnum = {}));
exports.default = TipoEmissaoNfeEnum;
