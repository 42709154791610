"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoRecipienteBebidaSisdevinEnum;
(function (TipoRecipienteBebidaSisdevinEnum) {
    TipoRecipienteBebidaSisdevinEnum["engarrafados"] = "01 - Engarrafados";
    TipoRecipienteBebidaSisdevinEnum["garrafoes"] = "02 - Garraf\u00F5es";
    TipoRecipienteBebidaSisdevinEnum["barril"] = "03 - Barril";
    TipoRecipienteBebidaSisdevinEnum["carroTanque"] = "04 - Carro Tanque";
    TipoRecipienteBebidaSisdevinEnum["bagInBox"] = "05 - Bag-in-Box";
    TipoRecipienteBebidaSisdevinEnum["tambor"] = "10 - Tambor";
    TipoRecipienteBebidaSisdevinEnum["vinhoduto"] = "14 - Vinhoduto";
    TipoRecipienteBebidaSisdevinEnum["lata"] = "21 - Lata";
})(TipoRecipienteBebidaSisdevinEnum || (TipoRecipienteBebidaSisdevinEnum = {}));
exports.default = TipoRecipienteBebidaSisdevinEnum;
