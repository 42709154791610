import { FormatarEnum, ModalidadeFreteEnum } from '@elogestor/util';
import React from 'react';
import { Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import { v4 } from 'uuid';
import InputAutoCompleteTransportador from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteTransportador';
import Divisor from '../../../../../../Componentes/Divisor';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import Select from '../../../../../../Componentes/Select';
import InputAutoCompleteCondicaoPagamentoSugestao from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCondicaoPagamentoSugestao';

const PadroesTab: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();

  return (
    <div>
      <Divisor>
        <Row>
          <OverlayTrigger
            placement={telaPequena ? 'top' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ width: telaPequena ? 'auto' : 225 }} id={v4()}>
                <Popover.Header>
                  <strong>Exemplos:</strong>
                </Popover.Header>
                <Popover.Body>
                  <ul>
                    <li>A VISTA</li>
                    <li>A VISTA/28/56 DIAS</li>
                    <li>15/30 DIAS</li>
                    <li>10 PARCELAS A CADA 20 DIAS</li>
                    <li>10 PARCELAS (Serão geradas a cada 30 dias)</li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <Col xl={9} lg={9} sm={12}>
              <InputAutoCompleteCondicaoPagamentoSugestao
                label="Condição de Pagamento"
                name="pessoaPadrao.condicaoPagamentoPadrao"
                maxLength={200}
              />
            </Col>
          </OverlayTrigger>
        </Row>

        <Row>
          <Col xl={6} lg={6}>
            <InputAutoCompleteTransportador
              label="Transportadora Padrão"
              name="pessoaPadrao.idPessoaTransportadora"
              nomeObjeto="pessoaPadrao.pessoaTransportadora"
              placeholder="Transportadora Padrão"
            />
          </Col>
          <Col xl={3} lg={3}>
            <Select
              label="Frete Conta"
              name="pessoaPadrao.modalidadeFrete"
              options={FormatarEnum({
                enumObj: ModalidadeFreteEnum,
                nullavel: true,
              })}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PadroesTab;
