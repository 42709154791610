/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { BiEdit } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import Janela, { IJanelaRef } from '../../../../JanelaAutocomplete';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import Detalhe from '../../../../Detalhe';
import Main from '../../../../../Paginas/Suprimentos/Produtos/UnidadeMedida/Detalhe/Main';
import { RedirecionarProvider } from '../../../../../Hooks/RedirecionarContext';
import UnidadeMedidaHook from '../../../../../Paginas/Suprimentos/Produtos/UnidadeMedida/Detalhe/Hooks';
import UnidadeMedidaAutoCompleteComunicador from '../../../../../Comunicador/Suprimentos/Produtos/UnidadeMedida/AutoComplete/UnidadeMedidaAutoComplete/UnidadeMedidaAutoCompleteComunicador';
import { AutoCompleteProvider } from '../../AutoCompleteBase/Hooks/AutoCompleteHook';
import { ContainerAutoComplete } from './styles';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IUnidadeInputAutoCompleteUnidadeMedida {
  id: string;
  descricao: string;
  siglaDescricao?: string;
  sigla: string;
}

interface IDadosPadrao {
  sigla: string;
}

export interface IOnChangeItemAtualAutoCompleteUnidadeInputAutoCompleteUnidadeMedidaEvent {
  itemAtual: IUnidadeInputAutoCompleteUnidadeMedida | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteUnidadeInputAutoCompleteUnidadeMedidaEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteUnidadeMedidaEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteUnidadeMedidaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  permitirAdicionar?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteUnidadeInputAutoCompleteUnidadeMedidaEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteUnidadeInputAutoCompleteUnidadeMedidaEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteUnidadeInputAutoCompleteUnidadeMedidaEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteUnidadeMedidaEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteUnidadeMedidaRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteUnidadeMedida: React.ForwardRefRenderFunction<
  IInputAutoCompleteUnidadeMedidaRef,
  IInputAutoCompleteUnidadeMedidaProps
> = (
  {
    name,
    nomeObjeto,
    label,
    permitirAdicionar = true,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    ...rest
  },
  ref
) => {
  const { permissoes } = UsePermissoes();
  const [idRegistro, setIdRegistro] = useState('');

  const janelaRef = useRef<IJanelaRef>(null);

  const quantidadeAlterado = useRef(0);

  const [dadosRecuperados, setDadosRecuperados] = useState(null);
  const [dadosPadrao, setDadosPadrao] = useState<IDadosPadrao | null>(null);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<IUnidadeInputAutoCompleteUnidadeMedida | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        if (value) setIdRegistro(value.id);
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (itemAtual) {
        setIdRegistro(itemAtual?.id);
        setDadosPadrao(null);
      } else {
        setIdRegistro('');
      }
      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      setDadosPadrao({
        sigla: input?.value.substr(0, 6) || '',
      });
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleSalva = useCallback(
    (data: any) => {
      janelaRef.current?.toggleJanela();
      autoCompleteRef.current?.selecionarItem(data);
    },
    [autoCompleteRef]
  );

  const handleRecuperarFormulario = useCallback((dadosParaRecuparar: any) => {
    setDadosRecuperados(dadosParaRecuparar);
    janelaRef.current?.toggleJanela();
  }, []);

  const handleDescartarFormulario = useCallback(() => {
    setDadosRecuperados(null);
  }, []);

  const permiteAdicionarAlterar = useMemo<boolean>(() => {
    const itemAtual = autoCompleteRef.current?.getItemAtual();

    return (
      permitirAdicionar &&
      ((permissoes?.SuprimentosProdutosUnidadeMedida?.altera &&
        itemAtual?.id) ||
        (permissoes?.SuprimentosProdutosUnidadeMedida?.inclui &&
          !itemAtual?.id))
    );
  }, [autoCompleteRef, permissoes, permitirAdicionar]);

  const dadosObrigatorios = useMemo(() => {
    return {
      UnidadeInputAutoCompleteUnidadeMedida: true,
      ativo: true,
    };
  }, []);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response =
                  await UnidadeMedidaAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite,
                      texto: valor,
                      ativo,
                      listaIdParaRemover,
                    },
                  });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: IUnidadeInputAutoCompleteUnidadeMedida) => {
              return item.id;
            }}
            obterLabel={(item: IUnidadeInputAutoCompleteUnidadeMedida) => {
              if (!item.descricao) return '';
              return `${item.sigla} - ${item.descricao}`;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />

          {permiteAdicionarAlterar && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={v4()}>
                  {idRegistro ? 'Editar' : 'Adicionar'}
                </Tooltip>
              }
            >
              <ContainerAutoComplete>
                <div>
                  <Button
                    onClick={() => {
                      janelaRef.current?.toggleJanela();
                    }}
                    style={{ marginLeft: 10, marginBottom: 5, height: 35 }}
                  >
                    {idRegistro ? <BiEdit /> : <IoMdAddCircleOutline />}
                  </Button>
                </div>
              </ContainerAutoComplete>
            </OverlayTrigger>
          )}
        </div>

        {permiteAdicionarAlterar && (
          <Janela titulo="Unidade de Medida" ref={janelaRef} tamanho="xl">
            <RedirecionarProvider value={{ redirecionar: false }}>
              <UnidadeMedidaHook>
                <AutoCompleteProvider>
                  <Detalhe
                    style={{}}
                    onRecuperarFormulario={handleRecuperarFormulario}
                    onDescartarFormulario={handleDescartarFormulario}
                  >
                    <Main
                      idEditar={idRegistro ?? null}
                      dadosRecuperados={dadosRecuperados}
                      dadosDuplicados={null}
                      dadosObrigatorios={dadosObrigatorios}
                      onClickSalvar={handleSalva}
                      dadosPadrao={dadosPadrao}
                    />
                  </Detalhe>
                </AutoCompleteProvider>
              </UnidadeMedidaHook>
            </RedirecionarProvider>
          </Janela>
        )}
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteUnidadeMedida);
