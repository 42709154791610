import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/documentos-referenciados';

interface INotaFiscalSaidaDocumentosReferenciadosComunicadorIndex {
  params: {
    idValor: string;
  };
}

interface INotaFiscalSaidaDocumentosReferenciadosComunicadorShow {
  id: string;
}

interface INotaFiscalSaidaDocumentosReferenciadosComunicadorStore {
  params: any;
}

interface INotaFiscalSaidaDocumentosReferenciadosComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalSaidaDocumentosReferenciadosComunicadorDelete {
  id: string;
}

class NotaFiscalSaidaDocumentosReferenciadosComunicador {
  public async index(
    params: INotaFiscalSaidaDocumentosReferenciadosComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: INotaFiscalSaidaDocumentosReferenciadosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalSaidaDocumentosReferenciadosComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: INotaFiscalSaidaDocumentosReferenciadosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalSaidaDocumentosReferenciadosComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaDocumentosReferenciadosComunicador();
