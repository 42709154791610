import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/ncm-auto-complete';

interface INcmAutoCompleteComunicadorIndex {
  params: any;
}

class NcmAutoCompleteComunicador {
  public async index(params: INcmAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new NcmAutoCompleteComunicador();
