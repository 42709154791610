import React, { useEffect } from 'react';
import Input from '../../../../../../../Componentes/Inputs/Input';
import ITipoDados from '../../../Main/Interface/ITipoDados';

const InputMascaraCentroCusto: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.replace(/[^9.]*/g, '');

    if (!value) {
      event.target.value = '';
      return;
    }

    const arrayValue: Array<string | null> = Array.from(value);

    if (arrayValue[0] === '.') {
      arrayValue[0] = null;
    }

    for (let i = 1; i < arrayValue.length; i++) {
      if (arrayValue[i] === '.' && arrayValue[i - 1] === '.') {
        arrayValue[i] = null;
      }
    }

    event.target.value = arrayValue.filter(Boolean).join('');
  };

  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return (
    <Input
      autoComplete="off"
      name={nome}
      onChange={handleChange}
      maxLength={50}
      onBlur={(event) => {
        const mascara = event.target.value;

        if (mascara[mascara.length - 1] === '.') {
          formRef.current?.setFieldValue(
            'MascaraCentroCusto',
            mascara.slice(0, mascara.length - 1)
          );
        }
      }}
    />
  );
};

export default InputMascaraCentroCusto;
