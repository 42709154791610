const CurvaABCPedidoVendaProdutoPorQuantidadePlanilha = [
  { titulo: 'Sequência', valor: 'sequencia' },
  { titulo: 'Código', valor: 'codigo' },
  { titulo: 'Descrição', valor: 'descricao' },
  { titulo: 'Unidade', valor: 'unidade' },
  { titulo: 'Quantidade', valor: 'quantidade' },
  { titulo: 'Perc(%)', valor: 'percentual' },
  { titulo: 'Perc(%) Acum', valor: 'percentualAcumulado' },
];

export default CurvaABCPedidoVendaProdutoPorQuantidadePlanilha;
