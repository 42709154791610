/* eslint-disable default-case */
import React, { RefObject, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  IEtiquetaElementoImpressao,
  IEtiquetaImpressao,
  IImpressaoEtiquetasNotaFiscalImpressao,
  INotaFiscalSaidaValoresInserir,
  IPessoaValoresInserir,
  TipoElementoEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import EtiquetaImpressaoPDF from '../../../../Configuracao/Etiqueta/Detalhe/Impressao/EtiquetaImpressaoPDF';
import ImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicador from '../../../../../Comunicador/Comercial/Vendas/ImpressaoEtiquetasNotaFiscal/Comunicador/ImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicador';
import PDF from '../../../../../Util/Relatorios/PDF';
import EtiquetaObterInformacoesModeloEtiquetaComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/EtiquetaObterInformacoesModeloEtiquetaComunicador';

interface IAcoesImpressaoEtiquetasNotaFiscal {
  formRef: RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading: (value: boolean) => void;
  possuiItemSelecionado: boolean;
  handleObterItensSelecionados: () => Promise<
    IImpressaoEtiquetasNotaFiscalImpressao[]
  >;
}

const AcoesImpressaoEtiquetasNotaFiscal: React.FC<
  IAcoesImpressaoEtiquetasNotaFiscal
> = ({
  formRef,
  loading,
  setLoading,
  possuiItemSelecionado,
  handleObterItensSelecionados,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idEtiqueta: Yup.string()
            .nullable()
            .required('Modelo de Etiqueta é Obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);

        return false;
      }
    },
    [formRef]
  );

  const handleImprimir = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current?.getData() as any;

      const listaEtiquetasNotaFiscalSelecionadas =
        await handleObterItensSelecionados();
      if (
        !listaEtiquetasNotaFiscalSelecionadas ||
        listaEtiquetasNotaFiscalSelecionadas.length === 0
      ) {
        TratarErros({
          mensagem:
            'Nenhuma Nota Fiscal com quantidade maior que 0 selecionado!',
        });
        setLoading(false);
        return;
      }

      if (!(await handleValidar(data))) {
        setLoading(false);
        return;
      }

      const response =
        await EtiquetaObterInformacoesModeloEtiquetaComunicador.show({
          id: data.idEtiqueta,
        });

      const listaIdNotaFiscal = listaEtiquetasNotaFiscalSelecionadas.map(
        (item) => {
          return item.id;
        }
      );

      const listaNotaFiscalRetorno =
        await ImpressaoEtiquetasNotaFiscalObterInformacoesNotaFiscalComunicador.index(
          { params: { listaIdNotaFiscal } }
        );

      const listaNotaFiscalImprimir: INotaFiscalSaidaValoresInserir[] = [];

      for (let i = 0; i < listaEtiquetasNotaFiscalSelecionadas.length; i++) {
        const notaFiscalEtiqueta = listaEtiquetasNotaFiscalSelecionadas[i];
        const notaFiscalRetorno = listaNotaFiscalRetorno[i];

        const valor: INotaFiscalSaidaValoresInserir = {
          ...notaFiscalRetorno,
        };

        if (valor.notaFiscalSaidaTransporteVolume) {
          valor.notaFiscalSaidaTransporteVolume = {
            ...valor.notaFiscalSaidaTransporteVolume,
            quantidade: notaFiscalEtiqueta.notaFiscalSaidaTransporteVolume
              .quantidade
              ? notaFiscalEtiqueta.notaFiscalSaidaTransporteVolume.quantidade
              : 1,
          };
        }

        if (valor.notaFiscalSaidaTransporteTransportadora) {
          valor.notaFiscalSaidaTransporteTransportadora = {
            ...valor.notaFiscalSaidaTransporteTransportadora,
            pessoaTransportadora: notaFiscalEtiqueta
              .notaFiscalSaidaTransporteTransportadora.pessoaTransportadora
              ? (notaFiscalEtiqueta.notaFiscalSaidaTransporteTransportadora
                  .pessoaTransportadora as IPessoaValoresInserir)
              : undefined,
          };
        }

        listaNotaFiscalImprimir.push(valor);
      }

      const dados: IEtiquetaImpressao = {
        ...response,
        listaEtiqueta: [],
      };

      for (let i = 0; i < listaNotaFiscalImprimir.length; i++) {
        const notaFiscalImprimir = listaNotaFiscalImprimir[i];

        const listaEtiquetaElementoImpressao: IEtiquetaElementoImpressao[] = [];

        for (let j = 0; j < response.listaEtiquetaElemento.length; j++) {
          const etiquetaElemento = response.listaEtiquetaElemento[j];

          let texto: any;
          switch (etiquetaElemento.etiquetaElementoDisponivel?.identificador) {
            case 'codigoBarrasChaveAcesso':
              if (notaFiscalImprimir.notaFiscalSaidaTransmissao) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissao.chaveAcesso;
              } else if (
                notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
              ) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
                    .chaveAcesso;
              }
              break;

            case 'numeroNota':
              texto = notaFiscalImprimir.numero;
              break;

            case 'serie':
              texto = (notaFiscalImprimir as any).serie.codigo;
              break;

            case 'DataHoraEmissao':
              texto = notaFiscalImprimir.dataHoraEmissao;
              break;

            case 'chaveAcesso':
              if (notaFiscalImprimir.notaFiscalSaidaTransmissao) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissao.chaveAcesso;
              } else if (
                notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
              ) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
                    .chaveAcesso;
              }
              break;

            case 'protocoloAutorizacao':
              if (notaFiscalImprimir.notaFiscalSaidaTransmissao) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissao.numeroProtocolo;
              } else if (
                notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
              ) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
                    .processoEmissao;
              }
              break;

            case 'dataHoraAutorizacao':
              if (notaFiscalImprimir.notaFiscalSaidaTransmissao) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissao.dataHoraRetorno;
              } else if (
                notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
              ) {
                texto =
                  notaFiscalImprimir.notaFiscalSaidaTransmissaoHomologacao
                    .dataHoraRetorno;
              }
              break;

            case 'informacoesAdicionaisNotaFiscal':
              texto = notaFiscalImprimir.informacoesContribuinte;
              break;

            case 'nomeRazaoSocialCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoa?.nomeRazaoSocial;
              break;

            case 'nomeFantasiaCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoa?.nomeFantasia;
              break;

            case 'cnpjCpfCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoa?.cnpjCpfVirtual;
              break;

            case 'inscricaoEstadualCliente':
              texto =
                notaFiscalImprimir.notaFiscalSaidaPessoa?.inscricaoEstadual;
              break;

            case 'cepCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.cep;
              break;

            case 'cidadeCliente':
              texto =
                notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.cidade?.nome;
              break;

            case 'ufCliente':
              texto =
                notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.cidade?.estado
                  ?.sigla;
              break;

            case 'logradouroCliente':
              texto =
                notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.logradouro;
              break;

            case 'numeroCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.numero;
              break;

            case 'bairroCliente':
              texto = notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.bairro;
              break;

            case 'complementoCliente':
              texto =
                notaFiscalImprimir.notaFiscalSaidaPessoaEndereco?.complemento;
              break;

            case 'telefoneCliente':
              {
                const listaTelefone = notaFiscalImprimir.notaFiscalSaidaPessoa
                  ?.pessoa?.listaPessoaTelefone as any;

                if (listaTelefone && listaTelefone.length > 0) {
                  texto = listaTelefone[0].dddNumeroVirtual;
                }
              }
              break;

            case 'emailCliente':
              {
                const listaEmail = notaFiscalImprimir.notaFiscalSaidaPessoa
                  ?.pessoa?.listaPessoaEmail as any;

                if (listaEmail && listaEmail.length > 0) {
                  texto = listaEmail[0].email;
                }
              }
              break;

            case 'nomeRazaoSocialTransportadora':
              texto =
                notaFiscalImprimir.notaFiscalSaidaTransporteTransportadora
                  ?.pessoaTransportadora?.nomeRazaoSocial;
              break;

            case 'nomeRazaoSocialEmitente':
              texto = (notaFiscalImprimir as any).empresa.nomeRazaoSocial;
              break;

            case 'nomeFantasiaEmitente':
              texto = (notaFiscalImprimir as any).empresa.nomeFantasia;
              break;

            case 'cnpjCpfEmitente':
              texto = (notaFiscalImprimir as any).empresa.cnpjCpfVirtual;
              break;

            case 'inscricaoEstadualEmitente':
              texto = (notaFiscalImprimir as any).empresa.inscricaoEstadual;
              break;

            case 'cepEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco.cep;
              break;

            case 'cidadeEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco.cidade
                ?.nome;
              break;

            case 'ufEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco.cidade
                ?.estado?.sigla;
              break;

            case 'logradouroEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco
                .logradouro;
              break;

            case 'numeroEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco
                .numero;
              break;

            case 'bairroEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco
                .bairro;
              break;

            case 'complementoEmitente':
              texto = (notaFiscalImprimir as any).empresa.empresaEndereco
                .complemento;
              break;

            case 'telefoneEmitente':
              {
                const listaTelefone = (notaFiscalImprimir as any).empresa
                  .listaEmpresaTelefone as any;

                if (listaTelefone && listaTelefone.length > 0) {
                  texto = listaTelefone[0].dddNumeroVirtual;
                }
              }
              break;

            case 'emaiEmitente':
              {
                const listaEmail = (notaFiscalImprimir as any).empresa
                  .listaEmpresaEmail as any;

                if (listaEmail && listaEmail.length > 0) {
                  texto = listaEmail[0].email;
                }
              }
              break;

            default:
              texto = String(etiquetaElemento.texto);
              break;
          }

          const etiquetaElementoImpressao: IEtiquetaElementoImpressao = {
            ...etiquetaElemento,
            tipoElemento:
              etiquetaElemento.etiquetaElementoDisponivel?.tipoElemento ??
              TipoElementoEnum.campo,
            texto,
          };

          listaEtiquetaElementoImpressao.push(etiquetaElementoImpressao);
        }

        if (dados.listaEtiqueta) {
          for (
            let k = 0;
            k <
            Number(
              notaFiscalImprimir.notaFiscalSaidaTransporteVolume?.quantidade
            );
            k++
          ) {
            const listaElemento: any[] = [];
            for (let l = 0; l < listaEtiquetaElementoImpressao.length; l++) {
              const elementoImpressao = listaEtiquetaElementoImpressao[l];

              if (
                (elementoImpressao as any).etiquetaElementoDisponivel
                  .identificador === 'volumeXdeY'
              ) {
                listaElemento.push({
                  ...elementoImpressao,
                  texto: `Volume ${k + 1} de ${Number(
                    notaFiscalImprimir.notaFiscalSaidaTransporteVolume
                      ?.quantidade
                  )}`,
                });
              } else {
                listaElemento.push(elementoImpressao);
              }
            }

            dados.listaEtiqueta.push({
              listaElemento,
            });
          }
        }
      }

      const relatorio = await PDF({
        documento: <EtiquetaImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleObterItensSelecionados, handleValidar, setLoading]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesImpressaoEtiquetasNotaFiscal;
