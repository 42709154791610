import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-item-fiscal';

interface IImportacaoXmlTerceiroNfeObterItemFiscalComunicadorIndex {
  params: {
    idProduto: string;
    cfop: string;
  };
}

class ImportacaoXmlTerceiroNfeObterItemFiscalComunicador {
  public async index(
    params: IImportacaoXmlTerceiroNfeObterItemFiscalComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterItemFiscalComunicador();
