import React from 'react';
import ListaDetalheGeral from './ListaDetalheLocalEstoque/ListaDetalhe';
import PesagemGeralHook from './ListaDetalheLocalEstoque/Hooks';
import PesagemPessoaHook from './ListaDetalhePessoa/Hooks';
import ListaDetalhePessoa from './ListaDetalhePessoa/ListaDetalhe';

const GeralTab: React.FC = () => {
  return (
    <>
      <PesagemPessoaHook>
        <ListaDetalhePessoa name="listaPesagemPessoa" />
      </PesagemPessoaHook>
      <PesagemGeralHook>
        <ListaDetalheGeral name="listaPesagemLocalEstoque" />
      </PesagemGeralHook>
    </>
  );
};

export default GeralTab;
