import { RegimeTributarioEnum, FormatarEnum } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Select from '../../../../../../../Componentes/Select';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import InputDateSemTimeZone from '../../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import InputDecimalNulavelSufixo from '../../../../../../../Componentes/Inputs/InputDecimalNulavelSufixo';

interface IEmpresaTributacaoVigenciaImpostoCustoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const EmpresaTributacaoVigenciaImpostoCustoDetalhe: React.FC<
  IEmpresaTributacaoVigenciaImpostoCustoModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Imposto p/ Custo"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={3} md={12}>
                  <InputDateSemTimeZone
                    ref={inputRefFocus}
                    label="Vigente a partir de:"
                    name="dataInicioVigencia"
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Select
                    label="Regime Tributário"
                    name="regimeTributario"
                    options={FormatarEnum({
                      enumObj: RegimeTributarioEnum,
                    })}
                    onChange={(event) => {
                      if (
                        event.currentTarget.value ===
                        'Lucro Presumido (Regime Normal)'
                      ) {
                        formRefDetalhe.current?.setFieldValue(
                          'aliquotaPis',
                          0.65
                        );
                        formRefDetalhe.current?.setFieldValue(
                          'aliquotaCofins',
                          3
                        );
                      } else if (
                        event.currentTarget.value ===
                        'Lucro Real (Regime Normal)'
                      ) {
                        formRefDetalhe.current?.setFieldValue(
                          'aliquotaPis',
                          1.65
                        );
                        formRefDetalhe.current?.setFieldValue(
                          'aliquotaCofins',
                          7.6
                        );
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota IRPJ Produtos"
                    name="aliquotaIrpjProdutos"
                    placeholder="Alíquota IRPJ Produtos"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota IRPJ Serviços"
                    name="aliquotaIrpjServicos"
                    placeholder="Alíquota IRPJ Serviços"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota CSLL Produtos"
                    name="aliquotaCsllProdutos"
                    placeholder="Alíquota CSLL Produtos"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota CSLL Serviços"
                    name="aliquotaCsllServicos"
                    placeholder="Alíquota CSLL Serviços"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota Simples Nacional"
                    name="aliquotaSimplesNacional"
                    placeholder="Alíquota Simples Nacional"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <InputDecimalNulavelSufixo
                    label="Alíquota Outros"
                    name="aliquotaOutros"
                    placeholder="Alíquota Outros"
                    casasDecimais={2}
                    casasInteiras={3}
                    sufixo={'%'}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default EmpresaTributacaoVigenciaImpostoCustoDetalhe;
