import React, { useEffect } from 'react';
import InputAutoCompleteLocalEstoque from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import ITipoDados from '../../../../Main/Interface/ITipoDados';

const AutoCompleteLocalEstoque: React.FC<ITipoDados> = ({
  nome,
  valor,
  formRef,
}) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return (
    <InputAutoCompleteLocalEstoque
      name={nome}
      nomeObjeto={nome}
      permitirAdicionar={false}
    />
  );
};

export default AutoCompleteLocalEstoque;
