/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  TextareaHTMLAttributes,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { UseParametros } from '../../../Hooks/ParametrosHook';
import { DefaultTextArea, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeTextAreaProps {
  valorAnterior: string;
}

export interface ITextareaRef {
  focus(): void;
}

interface ITextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  maiuscula?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    props: IOnChangeTextAreaProps
  ) => void;
}

const Textarea: React.ForwardRefRenderFunction<ITextareaRef, ITextareaProps> = (
  { name, label, maiuscula, onFocus, onChange, onBlur, style, ...rest },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();
  const parametros = UseParametros();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const valorAnterior = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (typeof maiuscula === 'boolean') {
        if (maiuscula) {
          const pontoInicialSelecionado = event.target.selectionStart;
          const pontoFinalSelecionado = event.target.selectionEnd;
          event.target.value = event.target.value.toUpperCase();
          event.target.selectionStart = pontoInicialSelecionado;
          event.target.selectionEnd = pontoFinalSelecionado;
        }
      } else if (parametros.CamposLetraMaiuscula) {
        const pontoInicialSelecionado = event.target.selectionStart;
        const pontoFinalSelecionado = event.target.selectionEnd;
        event.target.value = event.target.value.toUpperCase();
        event.target.selectionStart = pontoInicialSelecionado;
        event.target.selectionEnd = pontoFinalSelecionado;
      }

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });

      // event.target.style.overflow = 'hidden'; // Esconde a barra de rolagem
      // event.target.style.height = 'auto'; // Reseta a altura antes de calcular
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
    [maiuscula, onChange, parametros.CamposLetraMaiuscula]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      setIsFilled(!!inputRef.current?.value);
      setIsFocused(false);
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.value || '';
      },

      setValue(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;

          inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
      },

      setSemExecutarEvento(_, valor = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = valor;

          inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        const valor = this.getValue(_);
        return valor !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultTextArea
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          minHeight: 40,
          overflow: 'auto',
          ...style,
        }}
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        ref={inputRef}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(Textarea);
