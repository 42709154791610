import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-ordem-servico/obter-ordem-servico-item';

interface INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicadorIndex {
  listaIdOrdemServico: string[];
}

class NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicador {
  public async index(
    params: INotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoOrdemServicoObterOrdemServicoItemComunicador();
