"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoNotaFiscalEntradaEnum;
(function (SituacaoNotaFiscalEntradaEnum) {
    SituacaoNotaFiscalEntradaEnum["naoTransmitida"] = "N\u00E3o Transmitida";
    SituacaoNotaFiscalEntradaEnum["emTransmissao"] = "Em Transmiss\u00E3o";
    SituacaoNotaFiscalEntradaEnum["autorizada"] = "Autorizada";
    SituacaoNotaFiscalEntradaEnum["denegada"] = "Denegada";
    SituacaoNotaFiscalEntradaEnum["cancelada"] = "Cancelada";
    SituacaoNotaFiscalEntradaEnum["rejeitada"] = "Rejeitada";
})(SituacaoNotaFiscalEntradaEnum || (SituacaoNotaFiscalEntradaEnum = {}));
exports.default = SituacaoNotaFiscalEntradaEnum;
