import React from 'react';
import { RotasProvider } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasProvider
      value={{
        backend:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro',
        detalhes:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro/detalhe',
        listagem:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro',
      }}
    >
      {children}
    </RotasProvider>
  );
};

export default RotasHook;
