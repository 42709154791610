"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoLancamentoEnum;
(function (TipoLancamentoEnum) {
    TipoLancamentoEnum["geral"] = "Geral";
    TipoLancamentoEnum["jurosSoma"] = "(+) Juros";
    TipoLancamentoEnum["descontosSubtrai"] = "(-) Descontos";
    TipoLancamentoEnum["multaSoma"] = "(+) Multa";
    TipoLancamentoEnum["outrasDespesasSoma"] = "(+) Outras Despesas";
    TipoLancamentoEnum["outrasDespesasSubtrai"] = "(-) Outras Despesas";
    TipoLancamentoEnum["despesasCartaoSubtrai"] = "(-) Despesas de Cart\u00E3o";
    TipoLancamentoEnum["despesasCartorioSoma"] = "(+) Despesas de Cart\u00F3rio";
    TipoLancamentoEnum["despesasEnvioSoma"] = "(+) Despesas de Envio";
    TipoLancamentoEnum["despesasEnvioSubtrai"] = "(-) Despesas de Envio";
    TipoLancamentoEnum["comissoesPagasSoma"] = "(+) Comiss\u00F5es Pagas";
    TipoLancamentoEnum["comissoesPagasSubtrai"] = "(-) Comiss\u00F5es Pagas";
})(TipoLancamentoEnum || (TipoLancamentoEnum = {}));
exports.default = TipoLancamentoEnum;
