import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;

  tr.selecionado {
    background-color: #b2f3d3 !important;
  }

  td {
    cursor: pointer;
    white-space: nowrap;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        flex: 1;
      }
    }
  }

  td.selecionado {
    background-color: #b2f3d3 !important;
  }

  input {
    appearance: none;
    height: 14px;
    width: 14px;
    background: #cbd1d8;
    color: #fff;
    border: none;
    padding: 0px;
  }
  input:hover {
    background: #9faab7;
  }
  input:checked {
    background: #3397c4;
  }
  input:checked::before {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    content: '✔';
    font-size: 10px;
  }
  input:checked::after {
    content: '';
    display: flex;
  }
  input {
    border-radius: 50%;
  }
  input::after {
    border-radius: 50%;
  }
`;
