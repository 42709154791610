import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-operacao-fiscal';

interface IRegraEscolhaOperacaoFiscalComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaOperacaoFiscalComunicadorShow {
  id: string;
}

interface IRegraEscolhaOperacaoFiscalComunicadorStore {
  params: any;
}

interface IRegraEscolhaOperacaoFiscalComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaOperacaoFiscalComunicadorDeletar {
  id: string;
}

class RegraEscolhaOperacaoFiscalComunicador {
  public async index(
    params: IRegraEscolhaOperacaoFiscalComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaOperacaoFiscalComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaOperacaoFiscalComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaOperacaoFiscalComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaOperacaoFiscalComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaOperacaoFiscalComunicador();
