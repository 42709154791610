import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/juncao-pessoas';

interface IJuncaoPessoasComunicadorUpdate {
  params: {
    idPessoaBase: string;
    idPessoaJuntarExcluir: string;
  };
}

class JuncaoPessoasComunicador {
  public async update(params: IJuncaoPessoasComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}`, params.params);

    return response.data;
  }
}

export default new JuncaoPessoasComunicador();
