import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IEstoqueSaldoComCustoConferenciaBlocoHRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IEstoqueSaldoComCustoConferenciaBlocoHPDF {
  dados: IEstoqueSaldoComCustoConferenciaBlocoHRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const EstoqueSaldoComCustoConferenciaBlocoHRelatorioPDF: React.FC<
  IEstoqueSaldoComCustoConferenciaBlocoHPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoCusto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo Custo: '}
                      </Text>
                      {dados.cabecalho.filtroTipoCusto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRegimeTributario && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Regime Tributario: '}
                      </Text>
                      {dados.cabecalho.filtroRegimeTributario}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroLocalEstoque && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Local de Estoque: '}
                      </Text>
                      {dados.cabecalho.filtroLocalEstoque}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoOrdenacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ordenação: '}
                      </Text>
                      {dados.cabecalho.filtroTipoOrdenacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Somente Produtos com Saldo
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '7%' }}>
              <Text>Código</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '21%' }}>
              <Text>Produto</Text>
            </View>

            <View style={{ width: '3%' }} />

            <View style={{ width: '5%' }}>
              <Text>Unidade</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Saldo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Unit. Custo ICMS IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Custo Est. ICMS IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Unit. Custo IR</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>V. Custo Est. IR</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '10%' }}>
              <Text>Tipo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '19%' }}>
              <Text>Terceiro</Text>
            </View>
          </View>

          {dados.listaDados.map((dado, index) => {
            return (
              <View>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    marginBottom: '5pt',
                    marginTop: '5pt',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>
                    {`Tipo de Sped: ${dado.chave} `}
                  </Text>
                </View>

                {dado.listaSped.map((item, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: i % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '7%' }}>
                      <Text>
                        {item.estoqueCombinacao.produto.codigo.InserirEspacoRepetindoPorNumeroCaracteres(
                          17
                        )}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '21%' }}>
                      <Text>{item.estoqueCombinacao.produto.descricao}</Text>
                    </View>

                    <View style={{ width: '3%' }} />

                    <View style={{ width: '5%' }}>
                      <Text>
                        {
                          item.estoqueCombinacao.produto.produtoEstoque
                            .unidadeMedida.sigla
                        }
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '10%', alignItems: 'flex-end' }}>
                      <Text>{item.saldo}</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>
                        {Number(
                          item.valorUnitarioCustoIcmsIpiCalculado
                        ).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>
                        {String(item.valorCustoEstoqueICMSIPI)
                          .ConverterParaNumber()
                          .FormatarParaPtBr({
                            minimoCasasDecimais: 2,
                            maximoCasasDecimais: 2,
                          })}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>
                        {Number(
                          item.valorUnitarioCustoIrCalculado
                        ).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>
                        {String(item.valorCustoEstoqueIR)
                          .ConverterParaNumber()
                          .FormatarParaPtBr({
                            minimoCasasDecimais: 2,
                            maximoCasasDecimais: 2,
                          })}
                      </Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '10%' }}>
                      <Text>{item.estoqueCombinacao.estoqueIndicadorTipo}</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '19%' }}>
                      <Text>
                        {item.estoqueCombinacao.pessoa.codigo &&
                        item.estoqueCombinacao.pessoa.nomeRazaoSocial
                          ? `${item.estoqueCombinacao.pessoa.codigo} - ${item.estoqueCombinacao.pessoa.nomeRazaoSocial}`
                          : ''}
                      </Text>
                    </View>
                  </View>
                ))}

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'flex-start',
                    marginBottom: '5pt',
                    marginTop: '5pt',
                    borderBottom: '1px dashed black',
                  }}
                >
                  <View style={{ width: '55%' }}>
                    <Text style={{ fontWeight: 'bold' }}>
                      Total Por Tipo Sped
                    </Text>
                  </View>
                  <View
                    style={{
                      alignItems: 'flex-start',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                      }}
                    >{`${String(dado.somatorioItensICMSIPI)
                      .ConverterParaNumber()
                      .FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })}`}</Text>
                  </View>
                  <View style={{ width: '7%' }} />
                  <View>
                    <Text
                      style={{
                        fontWeight: 'bold',
                      }}
                    >{`${String(dado.somatorioItensIR)
                      .ConverterParaNumber()
                      .FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })}`}</Text>
                  </View>
                  <View style={{ width: '29%' }} />
                </View>
              </View>
            );
          })}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-start',
              marginBottom: '5pt',
              marginTop: '5pt',
            }}
          >
            <View style={{ width: '55%' }}>
              <Text style={{ fontWeight: 'bold' }}>Total Geral</Text>
            </View>
            <View
              style={{
                alignItems: 'flex-start',
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >{`${String(dados.totais.totalGeralICMSIPI)
                .ConverterParaNumber()
                .FormatarParaPtBr({
                  minimoCasasDecimais: 2,
                  maximoCasasDecimais: 2,
                })}`}</Text>
            </View>
            <View style={{ width: '7%' }} />
            <View>
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >{`${String(dados.totais.totalGeralIR)
                .ConverterParaNumber()
                .FormatarParaPtBr({
                  minimoCasasDecimais: 2,
                  maximoCasasDecimais: 2,
                })}`}</Text>
            </View>
            <View style={{ width: '29%' }} />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default EstoqueSaldoComCustoConferenciaBlocoHRelatorioPDF;
