import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/relatorio/adiantamentos/adiantamentos-por-liquidacao';

interface IAdiantamentosPorLiquidacaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class AdiantamentosPorLiquidacaoRelatorioComunicador {
  public async index(
    params: IAdiantamentosPorLiquidacaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new AdiantamentosPorLiquidacaoRelatorioComunicador();
