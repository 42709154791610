import { IAtualizacaoEstoqueProdutoLista } from '@elogestor/util';
import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { UseRecuperarFormulario } from '../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { UseAtualizacaoEstoqueProdutoLista } from './AtualizacaoEstoqueProdutoListaHook';

const AtualizacaoEstoqueProdutoListaRecuperarHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { parametros } = UseTableDraggable();

  const {
    listaValor,
    listaItemSelecionadoRef,
    listaDescricaoRef,
    listaCodigoBarrasRef,
    listaAtivoRef,
    listaEstoqueQuantidadeRef,
    listaNcmRef,
    listaCestRef,
  } = UseAtualizacaoEstoqueProdutoLista();
  const { paginacao } = UseLista();

  const handlePedirParaRecuperarFormulario = useCallback((): boolean => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    return itensSelecionados?.length !== 0;
  }, [listaItemSelecionadoRef]);

  const handleObterItens =
    useCallback((): IAtualizacaoEstoqueProdutoLista[] => {
      const listaItem: IAtualizacaoEstoqueProdutoLista[] = [];

      listaValor.forEach((valor, index) => {
        const retorno: IAtualizacaoEstoqueProdutoLista = {
          ...valor,
          selecionado:
            listaItemSelecionadoRef.current &&
            listaItemSelecionadoRef.current[index]
              ? Boolean(listaItemSelecionadoRef.current[index]?.checked)
              : false,
          descricao:
            listaDescricaoRef.current && listaDescricaoRef.current[index]
              ? listaDescricaoRef.current[index].value
              : '',
          ativo:
            listaAtivoRef.current && listaAtivoRef.current[index]
              ? Boolean(listaAtivoRef.current?.[index].checked)
              : false,

          produtoEngenharia: {
            ...valor.produtoEngenharia,
            codigoBarras:
              listaCodigoBarrasRef.current &&
              listaCodigoBarrasRef.current[index]
                ? listaCodigoBarrasRef.current?.[index].value
                : undefined,
          },

          produtoFiscal: {
            ...valor.produtoFiscal,
            ncm:
              listaNcmRef.current && listaNcmRef.current[index]
                ? listaNcmRef.current?.[index].getItemAtual()
                : null,

            cest:
              listaCestRef.current && listaCestRef.current[index]
                ? listaCestRef.current[
                    index
                  ].autoCompleteRef.current?.getItemAtual()
                : null,
          },

          estoqueCombinacao: [],
        };

        if (valor.estoqueCombinacao.length > 0) {
          retorno.estoqueCombinacao.push({
            ...valor.estoqueCombinacao[0],
            quantidade:
              listaEstoqueQuantidadeRef.current &&
              listaEstoqueQuantidadeRef.current[index]
                ? listaEstoqueQuantidadeRef.current[
                    index
                  ].value.ConverterParaNumber()
                : 0,
          });
        }

        listaItem.push(retorno);
      });

      return listaItem;
    }, [
      listaAtivoRef,
      listaCestRef,
      listaCodigoBarrasRef,
      listaDescricaoRef,
      listaEstoqueQuantidadeRef,
      listaItemSelecionadoRef,
      listaNcmRef,
      listaValor,
    ]);

  useLayoutEffect(() => {
    async function ExecutaHandleChange(): Promise<void> {
      const listaItem = handleObterItens();

      if (handlePedirParaRecuperarFormulario()) {
        const resposta = await abrirRecuperarFormulario({});
        if (resposta) {
          navigate(pathname, {
            state: {
              dadosRecuperados: {
                listaDados: listaItem,
                totalPaginas: paginacao.totalPaginas,
                limite: parametros.limite,
                pagina: parametros.pagina,
              },
            },
          });
        }
      }
    }

    return () => {
      ExecutaHandleChange();
    };
  }, [
    abrirRecuperarFormulario,
    handleObterItens,
    handlePedirParaRecuperarFormulario,
    navigate,
    listaItemSelecionadoRef,
    listaValor,
    paginacao.totalPaginas,
    parametros.limite,
    parametros.pagina,
    pathname,
    state,
  ]);

  return <div>{children}</div>;
};

export default AtualizacaoEstoqueProdutoListaRecuperarHook;
