import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/tipo-nota-auto-complete';

interface ITipoNotaAutoCompleteComunicadorIndex {
  params: any;
}

class TipoNotaAutoCompleteComunicador {
  public async index(
    params: ITipoNotaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new TipoNotaAutoCompleteComunicador();
