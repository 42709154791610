/* eslint-disable default-case */
import {
  IImpressaoEtiquetasNotaFiscalLista,
  IImpressaoEtiquetasNotaFiscalImpressao,
  FormatarDataParaPtBr,
  TipoEtiquetaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import { UseImpressaoEtiquetasNotaFiscalLista } from '../Hooks/ImpressaoEtiquetasNotaFiscalListaHook';
import AcoesImpressaoEtiquetasNotaFiscal from '../AcoesImpressaoEtiquetasNotaFiscal';
import ImpressaoEtiquetasNotaFiscalComunicador from '../../../../../Comunicador/Comercial/Vendas/ImpressaoEtiquetasNotaFiscal/Comunicador/ImpressaoEtiquetasNotaFiscalComunicador';
import InputTabelaInteiro from '../../../../../Componentes/Inputs/InputTabela/InputTabelaInteiro';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteEtiqueta from '../../../../../Componentes/Inputs/AutoComplete/Configuracao/InputAutoCompleteEtiqueta';
import InputTabelaAutoCompleteTransportador from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Comercial/InputTabelaAutoCompleteTransportador';
import ImpressaoEtiquetasNotaFiscalObterDadosPadraoComunicador from '../../../../../Comunicador/Comercial/Vendas/ImpressaoEtiquetasNotaFiscal/Comunicador/ImpressaoEtiquetasNotaFiscalObterDadosPadraoComunicador';

interface IControlarFocusParametros {
  index: number;
  nomeCampo: string;
  listaTh: ITh[];
  listaItens: IImpressaoEtiquetasNotaFiscalLista[];
}

interface IControlarFocusRetorno {
  linhaIndexAnterior: number;
  linhaIndexProxima: number;
  listaRefAnterior: any;
  listaRefProxima: any;
}

type IProps = {
  idNotaFiscalEtiquetaSelecionada?: string | null;
};

const ImpressaoEtiquetasNotaFiscalLista: React.FC<IProps> = ({
  idNotaFiscalEtiquetaSelecionada,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasImpressaoEtiquetasNotaFiscal: permissao } = permissoes;

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    listaQuantidadeRef,
    listaTransportadoraRef,
  } = UseImpressaoEtiquetasNotaFiscalLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [carregarDados, setCarregarDados] = useState(false);
  const [listaColunas, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const formRef = useRef<IFormCiaHandles>(null);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const handleCarregarDadosPadrao = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response =
        await ImpressaoEtiquetasNotaFiscalObterDadosPadraoComunicador.index();

      formRef.current?.setDataInicial({
        etiqueta: response.etiqueta,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleCarregarDadosPadrao();
  }, [handleCarregarDadosPadrao]);

  const handleControlarFocus = useCallback(
    ({
      index,
      nomeCampo,
      listaTh,
      listaItens,
    }: IControlarFocusParametros): IControlarFocusRetorno | null => {
      const listaColunasSelecionaveis = listaTh.filter(
        (coluna) =>
          coluna.visivel &&
          coluna.nomeCampo === 'notaFiscalSaidaTransporteVolume.quantidade'
      );

      if (
        !listaColunasSelecionaveis ||
        listaColunasSelecionaveis.length === 0
      ) {
        return null;
      }

      const colunaIndexAtual = listaColunasSelecionaveis.findIndex(
        (coluna) => coluna.nomeCampo === nomeCampo
      );

      let linhaIndexAnterior = index;
      let colunaIndexAnterior = colunaIndexAtual;
      let listaRefAnterior: any;

      do {
        colunaIndexAnterior -= 1;
        if (colunaIndexAnterior < 0) {
          linhaIndexAnterior -= 1;
          if (linhaIndexAnterior < 0) {
            linhaIndexAnterior = listaItens.length - 1;
          }

          colunaIndexAnterior = listaColunasSelecionaveis.length - 1;
        }

        switch (listaColunasSelecionaveis[colunaIndexAnterior].nomeCampo) {
          case 'notaFiscalSaidaTransporteVolume.quantidade':
            listaRefAnterior = listaQuantidadeRef;
            break;
        }
      } while (!listaRefAnterior.current[linhaIndexAnterior]);

      let linhaIndexProxima = index;
      let colunaIndexProxima = colunaIndexAtual;
      let listaRefProxima: any;

      do {
        colunaIndexProxima += 1;
        if (colunaIndexProxima >= listaColunasSelecionaveis.length) {
          linhaIndexProxima += 1;
          if (linhaIndexProxima >= listaItens.length) {
            linhaIndexProxima = 0;
          }

          colunaIndexProxima = 0;
        }

        switch (listaColunasSelecionaveis[colunaIndexProxima].nomeCampo) {
          case 'notaFiscalSaidaTransporteVolume.quantidade':
            listaRefProxima = listaQuantidadeRef;
            break;
        }
      } while (!listaRefProxima.current[linhaIndexProxima]);

      return {
        linhaIndexAnterior,
        linhaIndexProxima,
        listaRefAnterior,
        listaRefProxima,
      };
    },
    [listaQuantidadeRef]
  );

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (
          item &&
          checked &&
          listaQuantidadeRef.current &&
          listaQuantidadeRef.current[index].value.ConverterParaNumber() > 0
        ) {
          item.checked = checked;
        } else if (item && !checked) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef, listaQuantidadeRef]
  );

  const handleObterItensSelecionados = useCallback(async (): Promise<
    IImpressaoEtiquetasNotaFiscalImpressao[]
  > => {
    const listaIndexItemSelecionado: number[] = [];
    const listaItemSelecionado: IImpressaoEtiquetasNotaFiscalImpressao[] = [];

    const etiqueta = formRef.current?.getFieldValueNomeObjeto('etiqueta');

    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (
        item &&
        item.checked &&
        listaQuantidadeRef.current &&
        listaQuantidadeRef.current[index] &&
        listaQuantidadeRef.current[index].value.ConverterParaNumber() > 0
      ) {
        listaIndexItemSelecionado.push(index);
      }
    });

    listaIndexItemSelecionado.forEach((index) => {
      const retorno: IImpressaoEtiquetasNotaFiscalImpressao = {
        selecionado: true,
        id: listaValor[index].id,
        numero: listaValor[index].numero,
        dataHoraEmissao: listaValor[index].dataHoraEmissao,
        notaFiscalSaidaPessoa: listaValor[index].notaFiscalSaidaPessoa,
        notaFiscalSaidaTransporteVolume: {
          quantidade:
            listaQuantidadeRef.current && listaQuantidadeRef.current[index]
              ? listaQuantidadeRef.current[index].value.ConverterParaNumber()
              : 0,
        },
        notaFiscalSaidaTransporteTransportadora: {
          pessoaTransportadora:
            listaTransportadoraRef.current &&
            listaTransportadoraRef.current[index]
              ? listaTransportadoraRef.current[index].getItemAtual()
              : undefined,
        },
        etiqueta,
      };

      listaItemSelecionado.push(retorno);
    });

    return listaItemSelecionado;
  }, [
    listaItemSelecionadoRef,
    listaQuantidadeRef,
    listaTransportadoraRef,
    listaValor,
  ]);

  const atualizar = useCallback(() => {
    if (selecionarTodosRef.current) {
      selecionarTodosRef.current.checked = false;
    }

    handleSelecionarTodos(false);
    setRefresh((state) => !state);
  }, [handleSelecionarTodos]);

  const handleValidar = useCallback(
    async (
      data: IImpressaoEtiquetasNotaFiscalImpressao[]
    ): Promise<boolean> => {
      try {
        const listaMensagem: string[] = [];

        if (listaMensagem.length > 0) {
          TratarErros({ listaMensagem });
          return false;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    []
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);

        const response = await ImpressaoEtiquetasNotaFiscalComunicador.index({
          params: {
            idNotaFiscalEtiquetaSelecionada,
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        localStorage.removeItem('@EloGestorle:etiquetaNotaFiscal');

        const listaDados = response.dados[0].map(
          (data: IImpressaoEtiquetasNotaFiscalLista) => {
            return {
              ...data,
              selecionado: true,
              notaFiscalSaidaTransporteVolume: {
                quantidade:
                  data.notaFiscalSaidaTransporteVolume.quantidade &&
                  data.notaFiscalSaidaTransporteVolume.quantidade > 0
                    ? data.notaFiscalSaidaTransporteVolume.quantidade
                    : 0,
              },
            };
          }
        );
        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);
        if (listaDados.length > 0) {
          if (selecionarTodosRef.current) {
            selecionarTodosRef.current.checked = true;
          }

          listaItemSelecionadoRef.current?.forEach((item, index) => {
            if (
              item &&
              listaDados[index] &&
              listaDados[index].notaFiscalSaidaTransporteVolume.quantidade === 0
            ) {
              item.checked = false;
            }
          });
        }
        handleAlterouSelecionado();

        const dados = listaDados.map(
          (data: IImpressaoEtiquetasNotaFiscalLista, index: number) => {
            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              numero: (
                <div>
                  <div className="lista-valor">{data.numero}</div>
                </div>
              ),
              dataHoraEmissao: (
                <div>
                  <div className="lista-data">
                    {FormatarDataParaPtBr(data.dataHoraEmissao)}
                  </div>
                </div>
              ),
              'notaFiscalSaidaPessoa.pessoa.codigoNomeRazaoSocial': (
                <div>
                  <div className="lista-texto">
                    {`${data.notaFiscalSaidaPessoa.pessoa.codigo} - ${data.notaFiscalSaidaPessoa.pessoa.nomeRazaoSocial}`}
                  </div>
                </div>
              ),

              'notaFiscalSaidaTransporteVolume.quantidade': (
                <div className="lista-valor">
                  <InputTabelaInteiro
                    valorPadrao={
                      data.notaFiscalSaidaTransporteVolume.quantidade
                    }
                    obterRef={(instance) => {
                      if (instance.current && listaQuantidadeRef.current) {
                        listaQuantidadeRef.current[index] = instance.current;
                        listaQuantidadeRef.current[index].value = String(
                          data.notaFiscalSaidaTransporteVolume.quantidade
                        );
                      }
                    }}
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo: 'notaFiscalSaidaTransporteVolume.quantidade',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (!listaQuantidadeRef.current?.[proximo]);

                        listaQuantidadeRef.current?.[proximo]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event) => {
                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];
                      listaValor[
                        index
                      ].notaFiscalSaidaTransporteVolume.quantidade =
                        event.currentTarget.value.ConverterParaNumber();

                      if (
                        Number(event.currentTarget.value) === 0 &&
                        itemSelecionadoRef &&
                        itemSelecionadoRef.checked
                      ) {
                        itemSelecionadoRef.checked = false;
                        handleAlterouSelecionado();
                      } else if (
                        Number(event.currentTarget.value) > 0 &&
                        itemSelecionadoRef &&
                        !itemSelecionadoRef.checked
                      ) {
                        itemSelecionadoRef.checked = true;
                        handleAlterouSelecionado();
                      }
                    }}
                  />
                </div>
              ),
              'notaFiscalSaidaTransporteTransportadora.pessoaTransportadora.codigoNomeRazaoSocial':
                (
                  <div className="lista-texto">
                    <InputTabelaAutoCompleteTransportador
                      style={{ height: 25 }}
                      obterRef={(instance) => {
                        if (
                          instance.current &&
                          listaTransportadoraRef.current
                        ) {
                          listaTransportadoraRef.current[index] =
                            instance.current;
                          listaTransportadoraRef.current[
                            index
                          ].selecionarItemSemEvento(
                            data.notaFiscalSaidaTransporteTransportadora
                              .pessoaTransportadora
                          );
                        }
                      }}
                      onChangeItemAtual={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  </div>
                ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleAlterouSelecionado,
      handleControlarFocus,
      setListaValor,
      listaValor.length,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialImpressaoEtiquetasNotaFiscal' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Impressão de Etiquetas da Nota Fiscal"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesImpressaoEtiquetasNotaFiscal
                formRef={formRef}
                loading={loading}
                setLoading={setLoading}
                possuiItemSelecionado={possuiItemSelecionado}
                handleObterItensSelecionados={handleObterItensSelecionados}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          headerLista={
            <Container style={{ padding: 0 }}>
              <Divisor>
                <FormCia
                  ref={formRef}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Row style={{ width: '100%' }}>
                    <Col lg={6} md={6} sm={12} style={{ minWidth: '230px' }}>
                      <InputAutoCompleteEtiqueta
                        label="Modelo de Etiqueta"
                        nomeObjeto="etiqueta"
                        placeholder="Modelo de Etiqueta"
                        name="idEtiqueta"
                        listaTipoEtiqueta={[TipoEtiquetaEnum.notaFiscal]}
                      />
                    </Col>
                  </Row>
                </FormCia>
              </Divisor>
            </Container>
          }
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default ImpressaoEtiquetasNotaFiscalLista;
