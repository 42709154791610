"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EnumObterValor;
const Erros_1 = require("../Erros");
function EnumObterValor(valor) {
    if (!valor) {
        throw new Erros_1.AppErro({
            mensagem: 'Não foi possivel localizar um valor no enum',
        });
    }
    return String(valor);
}
