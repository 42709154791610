const PedidoVendaPorDataEmissaoPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'chave',
  },

  {
    titulo: 'Modelo Documento',
    valor: 'listaPedidoVendaItem.pedidoVendaModeloDocumento',
  },

  {
    titulo: 'Número',
    valor: 'listaPedidoVendaItem.pedidoVendaNumero',
  },

  {
    titulo: 'Hora',
    valor: 'listaPedidoVendaItem.pedidoVendaHoraEmissao',
  },
  {
    titulo: 'Situação Item',
    valor: 'listaPedidoVendaItem.pedidoVendaSituacaoItem',
  },

  {
    titulo: 'Cliente',
    valor: 'listaPedidoVendaItem.clienteNomeRazaoSocial',
  },
  {
    titulo: 'Desconto',
    valor: 'listaPedidoVendaItem.valorDesconto',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaPedidoVendaItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaPedidoVendaItem.valorTotal',
  },
];

export default PedidoVendaPorDataEmissaoPlanilha;
