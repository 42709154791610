const EstoqueSaldoComCustoConferenciaBlocoHRelatorioPlanilha = [
  {
    titulo: 'Tipo Sped',
    valor:
      'listaSped.estoqueCombinacao.produto.produtoFiscal.spedTipoProduto.descricao',
  },
  { titulo: 'Código', valor: 'listaSped.estoqueCombinacao.produto.codigo' },
  {
    titulo: 'Descrição',
    valor: 'listaSped.estoqueCombinacao.produto.descricao',
  },
  {
    titulo: 'Unidade de Medida',
    valor:
      'listaSped.estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'listaSped.saldo' },
  {
    titulo: 'V Unit Custo ICMS IPI',
    valor: 'listaSped.valorUnitarioCustoIcmsIpiCalculado',
  },
  {
    titulo: 'V Custo Estoque ICMS IPI',
    valor: 'listaSped.valorCustoEstoqueICMSIPI',
  },
  {
    titulo: 'V Unit Custo IR',
    valor: 'listaSped.valorUnitarioCustoIrCalculado',
  },
  {
    titulo: 'V Custo Estoque IR',
    valor: 'listaSped.valorCustoEstoqueIR',
  },

  { titulo: 'Tipo', valor: 'listaSped.estoqueCombinacao.estoqueIndicadorTipo' },

  {
    titulo: 'Terceiro Código',
    valor: 'listaSped.estoqueCombinacao.pessoa.codigo',
  },
  {
    titulo: 'Terceiro Nome Razão Social',
    valor: 'listaSped.estoqueCombinacao.pessoa.nomeRazaoSocial',
  },
];

export default EstoqueSaldoComCustoConferenciaBlocoHRelatorioPlanilha;
