import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import OrcamentoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoComunicador';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { PedidoVendaImportacaoOrcamentoHook } from '../../../PedidoVendaImportacaoOrcamentoHook';
import { OrcamentoAtualizarSituacaoHook } from './AtualizarSitucaoHook';
import { OrcamentoDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { OrcamentoHook } from './OrcamentoHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { OrdemServicoImportacaoOrcamentoHook } from '../../../../../Servicos/OrdensServicos/OrdemServicoImportacaoOrcamentoHook';

const OrcamentoHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: OrcamentoComunicador }}>
      <RotasHook>
        <RegimeTributarioHook>
          <OrcamentoDuplicarHook>
            <OrcamentoAtualizarSituacaoHook>
              <PedidoVendaImportacaoOrcamentoHook>
                <OrdemServicoImportacaoOrcamentoHook>
                  <FormHook>
                    <SubHeaderHook>
                      <OrcamentoHook>{children}</OrcamentoHook>
                    </SubHeaderHook>
                  </FormHook>
                </OrdemServicoImportacaoOrcamentoHook>
              </PedidoVendaImportacaoOrcamentoHook>
            </OrcamentoAtualizarSituacaoHook>
          </OrcamentoDuplicarHook>
        </RegimeTributarioHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default OrcamentoHooks;
