import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/movimento-manual-estoque';

interface IMovimentoManualEstoqueComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IMovimentoManualEstoqueComunicadorShow {
  id: string;
}

interface IMovimentoManualEstoqueComunicadorStore {
  params: any;
}

interface IMovimentoManualEstoqueComunicadorUpdate {
  id: string;
  params: any;
}

interface IMovimentoManualEstoqueComunicadorDeletar {
  id: string;
}

class MovimentoManualEstoqueComunicador {
  public async index(
    params: IMovimentoManualEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IMovimentoManualEstoqueComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IMovimentoManualEstoqueComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IMovimentoManualEstoqueComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IMovimentoManualEstoqueComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new MovimentoManualEstoqueComunicador();
