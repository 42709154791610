import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import PessoaClienteRepresentanteHook from './ListaDetalheRepresentante/Hooks';
import ListaDetalheRepresentante from './ListaDetalheRepresentante/Lista';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';

const ClienteTab: React.FC = () => {
  const liberacoes = UseLiberacoes();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Limite Crédito"
              name="pessoaCliente.limiteCredito"
              placeholder="Limite Crédito"
              disabled={!liberacoes.permiteAlteraValorLimiteCreditoNoCliente}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              label="Limite Crédito Cond."
              name="pessoaCliente.limiteCreditoConsignado"
              placeholder="Limite Crédito Cond."
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <PessoaClienteRepresentanteHook>
              <ListaDetalheRepresentante name="pessoaCliente.listaRepresentante" />
            </PessoaClienteRepresentanteHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ClienteTab;
