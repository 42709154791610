import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PisCofinsObterVisibilidadeBloqueioCamposPorCst } from '@elogestor/util';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const camposEscondidosPisPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,

  calcularRetido: true,
  baseCalculoRetido: true,
  aliquotaRetido: true,
  valorRetido: true,
};

const camposBloqueadosPisPadrao = {
  calcular: true,
  tipoCalculo: true,
  baseCalculo: true,
  aliquota: true,
  quantidadeUnidade: true,
  valorUnidade: true,
  valor: true,

  calcularRetido: true,
  baseCalculoRetido: true,
  aliquotaRetido: true,
  valorRetido: true,
};

type ICamposEscondidosPis = typeof camposEscondidosPisPadrao;
type ICamposBloqueadosPis = typeof camposBloqueadosPisPadrao;

interface IItemPisTabContext {
  validador: boolean;
  camposEscondidosPis: ICamposEscondidosPis;
  camposBloqueadosPis: ICamposBloqueadosPis;
  obterVisibilidadeBloqueioCamposPorPisCst(): void;
}

const ItemPisTabContext = createContext<IItemPisTabContext>(
  {} as IItemPisTabContext
);

const ItemPisTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe } = UseListaDetalheForm();

  const [camposEscondidosPis, setCamposEscondidosPis] = useState(
    camposEscondidosPisPadrao
  );

  const [camposBloqueadosPis, setCamposBloqueadosPis] = useState(
    camposBloqueadosPisPadrao
  );

  const calcular = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoPis.calcular'
  );
  const calcularRetido = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoPis.calcularRetido'
  );

  const tipoCalculo = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoPis.tipoCalculo'
  );

  const cst =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoPis.situacaoTributaria'
    )?.codigo || '';

  const handleObterVisibilidadeBloqueioCamposPorPisCst = useCallback(() => {
    const calcularPis = formRefDetalhe.current?.getFieldValue(
      'notaFiscalEntradaItemImpostoPis.calcular'
    );
    const calcularPisRetido = formRefDetalhe.current?.getFieldValue(
      'notaFiscalEntradaItemImpostoPis.calcularRetido'
    );

    const tipoCalculoPis = formRefDetalhe.current?.getFieldValue(
      'notaFiscalEntradaItemImpostoPis.tipoCalculo'
    );

    const cstPis =
      formRefDetalhe.current?.getFieldValueNomeObjeto(
        'notaFiscalEntradaItemImpostoPis.situacaoTributaria'
      )?.codigo || '';

    const retorno = PisCofinsObterVisibilidadeBloqueioCamposPorCst({
      calcular: calcularPis,
      calcularRetido: calcularPisRetido,
      tipoCalculo: tipoCalculoPis,
      cst: cstPis,
    });

    setCamposEscondidosPis(retorno.escondido);
    setCamposBloqueadosPis(retorno.bloqueado);
  }, [formRefDetalhe]);

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorPisCst();
  }, [
    calcular,
    calcularRetido,
    tipoCalculo,
    cst,
    handleObterVisibilidadeBloqueioCamposPorPisCst,
  ]);

  return (
    <ItemPisTabContext.Provider
      value={{
        camposEscondidosPis,
        camposBloqueadosPis,
        obterVisibilidadeBloqueioCamposPorPisCst:
          handleObterVisibilidadeBloqueioCamposPorPisCst,
        validador: true,
      }}
    >
      {children}
    </ItemPisTabContext.Provider>
  );
};

function UseItemPisTab(): Omit<IItemPisTabContext, 'validador'> {
  const context = useContext(ItemPisTabContext);

  if (!context.validador) {
    throw new Error('UseItemPisTab deve ser usado com um ItemPisTabHook');
  }

  return context;
}

export { ItemPisTabHook, UseItemPisTab };
