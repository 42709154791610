import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormatarEnum,
  VeiculoCodigoCorEnum,
  VeiculoRestricaoEnum,
  VeiculoTipoOperacaoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import Select from '../../../../../../../../../Componentes/Select';
import Input from '../../../../../../../../../Componentes/Inputs/Input';
import { UseSituacaoBloquearCampos } from '../../../../Hook/SituacaoBloquearCamposHook';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputInteiroNulavel from '../../../../../../../../../Componentes/Inputs/InputInteiroNulavel';

const ItemVeiculoTab: React.FC = () => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const tipoOperacao = formRefDetalhe.current?.getFieldValue(
    'notaFiscalSaidaItemVeiculo.tipoOperacao'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Select
              label="Tipo de Opeação"
              name="notaFiscalSaidaItemVeiculo.tipoOperacao"
              options={FormatarEnum({
                enumObj: VeiculoTipoOperacaoEnum,
                nullavel: true,
              })}
              onChange={refresh}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Chassi"
              name="notaFiscalSaidaItemVeiculo.chassi"
              placeholder="Chassi"
              maxLength={17}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Número do Motor"
              name="notaFiscalSaidaItemVeiculo.numeroMotor"
              placeholder="Número do Motor"
              maxLength={21}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Cor (Montadora)"
              name="notaFiscalSaidaItemVeiculo.corMontadora"
              placeholder="Cor (Montadora)"
              maxLength={4}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Descrição da Cor"
              name="notaFiscalSaidaItemVeiculo.descricaoCor"
              placeholder="Descrição da Cor"
              maxLength={40}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Select
              label="Código da Cor"
              name="notaFiscalSaidaItemVeiculo.codigoCorDenatran"
              options={FormatarEnum({
                enumObj: VeiculoCodigoCorEnum,
                nullavel: true,
              })}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Tip de Pintura"
              name="notaFiscalSaidaItemVeiculo.tipoPintura"
              placeholder="Tip de Pintura"
              maxLength={1}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <InputInteiroNulavel
              label="Ano Modelo"
              name="notaFiscalSaidaItemVeiculo.anoModelo"
              placeholder="Ano Modelo"
              maxLength={4}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <InputInteiroNulavel
              label="Ano Fabricação"
              name="notaFiscalSaidaItemVeiculo.anoFabricacao"
              placeholder="Ano Fabricação"
              maxLength={4}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Serial (Série)"
              name="notaFiscalSaidaItemVeiculo.serie"
              placeholder="Serial (Série)"
              maxLength={9}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Select
              label="Restrição"
              name="notaFiscalSaidaItemVeiculo.restricao"
              options={FormatarEnum({
                enumObj: VeiculoRestricaoEnum,
                nullavel: true,
              })}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Código Marca Modelo"
              name="notaFiscalSaidaItemVeiculo.codigoMarcaModelo"
              placeholder="Código Marca Modelo"
              maxLength={6}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Placa do Veículo"
              name="notaFiscalSaidaItemVeiculo.placa"
              placeholder="Placa do Veículo"
              maxLength={7}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            style={{ display: !tipoOperacao ? 'none' : '' }}
          >
            <Input
              label="Renavam"
              name="notaFiscalSaidaItemVeiculo.renavam"
              placeholder="Renavam"
              maxLength={11}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemVeiculoTab;
