import api from '../../../../Comum/Services/Api';

const rota = 'login';

interface ILoginComunicadorStore {
  params: any;
}

class LoginComunicador {
  public async store(params: ILoginComunicadorStore): Promise<any> {
    const response = await api.post(`${rota}`, params.params);
    return response.data;
  }
}

export default new LoginComunicador();
