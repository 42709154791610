"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var IndicadorTipoTituloSpedEnum;
(function (IndicadorTipoTituloSpedEnum) {
    IndicadorTipoTituloSpedEnum["duplicata"] = "00 - Duplicata";
    IndicadorTipoTituloSpedEnum["cheque"] = "01 - Cheque";
    IndicadorTipoTituloSpedEnum["promissoria"] = "02 - Promissoria";
    IndicadorTipoTituloSpedEnum["recibo"] = "03 - Recibo";
    IndicadorTipoTituloSpedEnum["outro"] = "99 - Outros";
})(IndicadorTipoTituloSpedEnum || (IndicadorTipoTituloSpedEnum = {}));
exports.default = IndicadorTipoTituloSpedEnum;
