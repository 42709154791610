import { TipoDocumentoManutencaoDocumentoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  '/fiscal/documentos-eletronicos/manutencao-documentos-fiscais/pdf-xml-download-zip';

interface IManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicadorShow {
  params: {
    listaIdDocumentosFiscais: string[];
    baixarPdf: boolean;
    baixarXml: boolean;
    tipoDocumento: TipoDocumentoManutencaoDocumentoEnum;
  };
}

class ManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicador {
  public async show(
    params: IManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
      responseType: 'blob',
    });

    return response.data;
  }
}

export default new ManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicador();
