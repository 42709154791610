import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/familia-auto-complete';

interface IFamiliaAutoCompleteComunicadorIndex {
  params: any;
}

class FamiliaAutoCompleteComunicador {
  public async index(
    params: IFamiliaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FamiliaAutoCompleteComunicador();
