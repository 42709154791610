import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/pessoas/pessoa-com-tags';

interface IPessoaComTagsRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PessoaComTagsRelatorioComunicador {
  public async index(
    params: IPessoaComTagsRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PessoaComTagsRelatorioComunicador();
