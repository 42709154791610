const OrcamentoPorProdutoPlanilha = [
  { titulo: 'Codigo do Produto', valor: 'listaOrcamentoItem.produtoCodigo' },

  { titulo: 'Nome do Produto', valor: 'listaOrcamentoItem.produtoDescricao' },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaOrcamentoItem.orcamentoDataHoraEmissao',
  },

  {
    titulo: 'Numero do Orçamento',
    valor: 'listaOrcamentoItem.orcamentoNumero',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaOrcamentoItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaOrcamentoItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaOrcamentoItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaOrcamentoItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaOrcamentoItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaOrcamentoItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaOrcamentoItem.valorTotal',
  },
];

export default OrcamentoPorProdutoPlanilha;
