"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ControlPayFormaPagamentoEnum;
(function (ControlPayFormaPagamentoEnum) {
    ControlPayFormaPagamentoEnum["tefCredito"] = "21 - TEF Cr\u00E9dito";
    ControlPayFormaPagamentoEnum["tefDebito"] = "22 - TEF D\u00E9bito";
    ControlPayFormaPagamentoEnum["tefVoucher"] = "23 - TEF Voucher (VER)";
    ControlPayFormaPagamentoEnum["tefPix"] = "25 - TEF Pix";
})(ControlPayFormaPagamentoEnum || (ControlPayFormaPagamentoEnum = {}));
exports.default = ControlPayFormaPagamentoEnum;
