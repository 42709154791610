import styled, { css } from 'styled-components';

interface IButton {
  $valorMudou: boolean;
}

export const Button = styled.button<IButton>`
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: none;
  border: none;

  ${({ $valorMudou }) =>
    $valorMudou &&
    css`
      background-color: #8abece;
    `}

  &:hover {
    background: #d3d3d3;
  }
`;
