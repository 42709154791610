import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/geral/forma-pagamento-auto-complete-tag';

interface IFormaPagamentoAutoCompleteTagComunicadorIndex {
  params: any;
}

class FormaPagamentoAutoCompleteTagComunicador {
  public async index(
    params: IFormaPagamentoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FormaPagamentoAutoCompleteTagComunicador();
