import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IManifestoTransportePorResumoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IManifestoTransportePorResumoPDF {
  dados: IManifestoTransportePorResumoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const ManifestoTransportePorResumoPDF: React.FC<
  IManifestoTransportePorResumoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.listaSituacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.listaSituacao}
                    </Text>
                  </View>
                )}

                {(dados.cabecalho.dataHoraEmissaoInicial ||
                  dados.cabecalho.dataHoraEmissaoFinal) && (
                  <View>
                    <Text>
                      <>
                        <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                        {dados.cabecalho.dataHoraEmissaoInicial} até{' '}
                        {dados.cabecalho.dataHoraEmissaoFinal}
                      </>
                    </Text>
                  </View>
                )}
                {dados.cabecalho.listaPlacas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Placa: '}</Text>
                      {dados.cabecalho.listaPlacas}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.listaContratante && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Lista Contratantes: '}
                      </Text>
                      {dados.cabecalho.listaContratante}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.listaChavesAcesso && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Chave de Acesso : '}
                      </Text>
                      {dados.cabecalho.listaChavesAcesso}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.listaNumerosCtes && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Lista Numeros Ctes : '}
                      </Text>
                      {dados.cabecalho.listaNumerosCtes}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.listaNumerosNotas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Lista Numeros Notas : '}
                      </Text>
                      {dados.cabecalho.listaNumerosNotas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '8%' }}>
              <Text>Série</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '5%' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '10%' }}>
              <Text>Dt. Emissão</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View style={{ width: '15%' }}>
              <Text>Data hora inicio Viagem</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View
              style={{ display: 'flex', alignItems: 'center', width: '6%' }}
            >
              <Text>Placa</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View
              style={{ display: 'flex', alignItems: 'flex-end', width: '16%' }}
            >
              <Text>Peso Bruto Total Carga</Text>
            </View>
            <View style={{ width: '4%' }} />
            <View
              style={{ display: 'flex', alignItems: 'flex-end', width: '21%' }}
            >
              <Text>Valor total produtos</Text>
            </View>
            <View style={{ width: '1%' }} />
            <View
              style={{ display: 'flex', alignItems: 'center', width: '9%' }}
            >
              <Text>Situação</Text>
            </View>
          </View>

          <View>
            <View>
              <View>
                <View key={String(2)}>
                  {dados.listaDados.map((listaMap, index) => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 9,
                          backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                        }}
                      >
                        <View style={{ width: '8%' }}>
                          <Text>{listaMap.serie}</Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '5%' }}>
                          <Text>{listaMap.numero}</Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '10%' }}>
                          <Text>
                            <>{listaMap.dataHoraEmissao}</>
                          </Text>
                        </View>

                        <View style={{ width: '1%' }} />
                        <View style={{ width: '15%' }}>
                          <Text>
                            <>{listaMap.dataHoraInicioViagem}</>
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '6%',
                          }}
                        >
                          <Text>{listaMap.placa}</Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            width: '16%',
                          }}
                        >
                          <Text>{listaMap.pesoBrutoTotalCarga}</Text>
                        </View>
                        <View style={{ width: '4%' }} />
                        <View
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            width: '21%',
                          }}
                        >
                          <Text>
                            {listaMap.valorTotalMercadoriaTransportada}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '9%',
                          }}
                        >
                          <Text>{listaMap.situacao}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              borderTop: '1pt solid black',
              marginTop: '2pt',
              paddingTop: '2pt',
              flexDirection: 'row',
              fontSize: 9,
            }}
          >
            <View style={{ width: '90%' }}>
              <Text
                style={{ fontWeight: 'bold', marginBottom: 5, marginLeft: 2 }}
              >
                Total Geral: {dados.quantidadeTotal}
              </Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '9%', alignItems: 'flex-end' }}>
              <Text style={{ fontWeight: 'bold', marginRight: '114pt' }}>
                {dados.valorTotalProdutos}
              </Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ManifestoTransportePorResumoPDF;
