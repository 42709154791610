import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/cancelar-pagamento-sitef';

interface IFrenteCaixaPdvCancelarPagamentoSiTefComunicadorUpdate {
  params: any;
}

class FrenteCaixaPdvCancelarPagamentoSiTefComunicador {
  public async update(
    params: IFrenteCaixaPdvCancelarPagamentoSiTefComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvCancelarPagamentoSiTefComunicador();
