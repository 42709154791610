import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra/rejeitar';

interface ICotacaoCompraRejeitarComunicadorUpdate {
  id: string;
  params: any;
}

class CotacaoCompraRejeitarComunicador {
  public async update(
    params: ICotacaoCompraRejeitarComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new CotacaoCompraRejeitarComunicador();
