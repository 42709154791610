import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/cotacao-compra/cotacao-compra-total-por-data-emissao';

interface ICotacaoCompraTotalPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CotacaoCompraTotalPorDataEmissaoRelatorioComunicador {
  public async index(
    params: ICotacaoCompraTotalPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CotacaoCompraTotalPorDataEmissaoRelatorioComunicador();
