import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-csts-creditos-icms/ordem';

interface IRegraEscolhaCstCreditoIcmsOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaCstCreditoIcmsOrdemComunicador {
  public async update(
    params: IRegraEscolhaCstCreditoIcmsOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaCstCreditoIcmsOrdemComunicador();
