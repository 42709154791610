import React from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import { ImpressaoEtiquetasPedidoVendaListaHook } from './ImpressaoEtiquetasPedidoVendaListaHook';

const ImpressaoEtiquetasPedidoVendaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ImpressaoEtiquetasPedidoVendaListaHook>
      {children}
    </ImpressaoEtiquetasPedidoVendaListaHook>
  );
};

export default ImpressaoEtiquetasPedidoVendaHook;
