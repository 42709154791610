"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IsPagamentoCartaoTef;
const Enum_1 = require("../Enum");
function IsPagamentoCartaoTef(meioPagamento) {
    return (meioPagamento === Enum_1.MeioPagamentoEnum.cartaoCredito ||
        meioPagamento === Enum_1.MeioPagamentoEnum.cartaoDebito ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeAlimentaca ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeRefeicao ||
        meioPagamento === Enum_1.MeioPagamentoEnum.valeCombustivel);
}
