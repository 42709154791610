import { IImportacaoXmlEmitidosListaDadosImpressao } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/notas-emitidas/salvar-importado';

interface IImportacaoXmlEmitidosSalvarImportadoComunicadorStore {
  params: any;
}

class ImportacaoXmlEmitidosSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlEmitidosSalvarImportadoComunicadorStore
  ): Promise<{
    id: string;
    listaImpressao: IImportacaoXmlEmitidosListaDadosImpressao[];
    listaMensagemSucesso: string[];
    listaMensagemAviso: string[];
    listaMensagemErro: string[];
  }> {
    const { params: body } = params;

    const response = await api.post(rota, body);

    return response.data;
  }
}

export default new ImportacaoXmlEmitidosSalvarImportadoComunicador();
