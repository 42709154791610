import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isAfter, parseISO } from 'date-fns';
import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { RiDeleteBin6Line, RiPassValidLine } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';

import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';

import ButtonDeletar from '../../../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TerminalCaixaEmpresaCertificadoUploadComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaCertificadoUploadComunicador';
import TerminalCaixaEmpresaCertificadoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaCertificadoComunicador';

interface IEmpresaCertificadoModal {
  onFecharFormModal(): void;
}

const TerminalCaixaEmpresaCertificadoDetalhe: React.FC<
  IEmpresaCertificadoModal
> = ({ onFecharFormModal }) => {
  const { formRefDetalhe, loading, handleSubmit, setLoading, refresh } =
    UseListaDetalheForm();

  const formPrincipal = UseForm();

  const idTerminalCaixa = formPrincipal.getIdDetalheRegistro();

  const [certificadoValido, setCertificadValido] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const permiteValidar =
    inputFileRef.current?.files && inputFileRef.current.files[0];

  const certificadoVencido = useCallback(() => {
    if (
      !formRefDetalhe.current?.getFieldValue('dataHoraValidadeFinal') ||
      !isAfter(
        parseISO(
          formRefDetalhe.current?.getFieldValue('dataHoraValidadeFinal')
        ),
        new Date()
      )
    ) {
      return false;
    }

    return true;
  }, [formRefDetalhe]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    if (!idTerminalCaixa) return;
    try {
      setLoading(true);

      const response = await TerminalCaixaEmpresaCertificadoComunicador.show({
        idTerminalCaixa,
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        nomeCertificado: response.nomeCertificado,
        emitidoPor: response.emitidoPor,
        dataHoraValidadeInicial: response.dataHoraValidadeInicial,
        dataHoraValidadeFinal: response.dataHoraValidadeFinal,
      });

      setCertificadValido(true);
      setLoading(false);
    } catch (error) {
      setCertificadValido(false);
      setLoading(false);
    }
  }, [formRefDetalhe, idTerminalCaixa, setLoading]);

  const handleClickValidar = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) return;

    try {
      setLoading(true);
      const formData = new FormData();

      const arquivo = inputFileRef.current.files[0] as any;

      formData.append('certificadoTEF', arquivo);
      formData.append('idTerminalCaixa', idTerminalCaixa || '');

      const response =
        await TerminalCaixaEmpresaCertificadoUploadComunicador.update({
          params: formData,
        });

      await formRefDetalhe.current?.setSemExecutarEvento({
        nomeCertificado: response.informacoesCertificado.nomeCertificado,
        emitidoPor: response.informacoesCertificado.nome,
        dataHoraValidadeInicial:
          response.informacoesCertificado.validade.inicio,
        dataHoraValidadeFinal: response.informacoesCertificado.validade.fim,
      });

      if (
        !isAfter(
          parseISO(
            formRefDetalhe.current?.getFieldValue('dataHoraValidadeFinal')
          ),
          new Date()
        )
      ) {
        ToastErro('Certificado Vencido!');
      } else {
        ToastSucesso('Certificado Válido!');
      }

      setCertificadValido(true);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setCertificadValido(false);
      setLoading(false);
    }
  }, [formRefDetalhe, idTerminalCaixa, setLoading]);

  const handleOnSelecionarCertificado = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) return;

    formRefDetalhe.current?.setFieldValue(
      'arquivo',
      inputFileRef.current.files[0].name
    );

    refresh();
  }, [formRefDetalhe, refresh]);

  const handleExcluirCertificado = useCallback(async (): Promise<void> => {
    formRefDetalhe.current?.clearField('arquivo');

    if (inputFileRef.current && inputFileRef.current.files) {
      inputFileRef.current.value = '';
    }
  }, [formRefDetalhe]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await handleSubmit({ idTerminalCaixa });

      if (response) {
        onFecharFormModal();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleSubmit, idTerminalCaixa, onFecharFormModal, setLoading]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Selecione o arquivo do certificado digital"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <input
                    type="file"
                    accept=".pem"
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={handleOnSelecionarCertificado}
                  />
                  <button
                    type="button"
                    style={{
                      border: '0',
                      borderRadius: '5px',
                      color: '#fff',
                      cursor: 'pointer',
                      marginBottom: '25px',
                      padding: '6px 20px',
                      height: '35px',
                    }}
                    className="btn-azul-escuro"
                    onClick={() => {
                      inputFileRef.current?.click();
                    }}
                    disabled={loading}
                  >
                    <TextoLoading loading={loading}>
                      Selecionar um arquivo &#187;
                    </TextoLoading>
                  </button>
                </Col>
              </Row>

              <Row className="d-flex flex-row">
                <Col lg={11} xs={12} className="pe-lg-0">
                  <Input
                    label="Arquivo"
                    name="arquivo"
                    placeholder="Arquivo"
                    style={{ marginBottom: 0 }}
                    disabled
                  />
                </Col>
                <Col
                  lg={1}
                  xs={12}
                  className="d-flex align-items-end justify-content-end"
                >
                  <ButtonDeletar
                    type="button"
                    onClick={handleExcluirCertificado}
                    disabled={loading}
                    style={{
                      height: '35px',
                    }}
                  >
                    <TextoLoading loading={loading}>
                      <RiDeleteBin6Line />
                    </TextoLoading>
                  </ButtonDeletar>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    type="button"
                    style={{
                      height: '35px',
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="btn-azul-escuro"
                    onClick={handleClickValidar}
                    disabled={!permiteValidar || loading}
                  >
                    <TextoLoading loading={loading}>
                      <RiPassValidLine />
                      <span style={{ marginLeft: 10 }}>Validar</span>
                    </TextoLoading>
                  </Button>
                </Col>
              </Row>

              <div
                style={{
                  display: certificadoValido ? 'block' : 'none',
                  marginTop: '10pt',
                }}
              >
                <Card
                  style={{
                    borderColor: `${certificadoVencido() ? 'green' : 'red'}`,
                  }}
                >
                  <CardHeader>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: `${certificadoVencido() ? 'green' : 'red'}`,
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Certificado do Terminal
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {certificadoVencido() ? 'Válido' : 'Inválido'}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>
                      <span style={{ marginRight: 5 }}>Emitido Por:</span>
                      <InputLabel name="emitidoPor" label="" />
                    </div>

                    <div style={{ fontWeight: 'bold', display: 'flex' }}>
                      <span style={{ marginRight: 5 }}>Valido de</span>
                      <InputLabel
                        name="dataHoraValidadeInicial"
                        label=""
                        formatarValor={(value) => {
                          if (!value) return '';
                          return FormatarDataHoraCompletaParaPtBr(value);
                        }}
                      />
                      <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                      <InputLabel
                        name="dataHoraValidadeFinal"
                        label=""
                        formatarValor={(value) => {
                          if (!value) return '';
                          return FormatarDataHoraCompletaParaPtBr(value);
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                // display: !certificadoValido ? 'flex' : 'none',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!certificadoValido}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span
                  style={{
                    marginLeft: 10,
                  }}
                >
                  Salvar
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default TerminalCaixaEmpresaCertificadoDetalhe;
