import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import {
  FormatarEnum,
  IConhecimentoTransporteTerceirosFiltroRelatorioFrontend,
  TipoDataConhecimentoTransporteTerceiroEnum,
} from '@elogestor/util';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagCliente from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagSituacaoConhecimentoTransporteEnum from '../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoConhecimentoTransporteEnum';
import InputInteiroTag from '../../../../Componentes/Inputs/TagsInput/InputInteiroTag';
import InputChaveAcessoTag from '../../../../Componentes/Inputs/TagsInput/InputChaveAcessoTag';
import Select from '../../../../Componentes/Select';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';

interface IConhecimentoTransporteTerceirosRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const SuprimentoConhecimentoTransporteTerceiros: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraRef = useRef<IInputPeriodoDataHoraRef>(null);

  const [loading, setLoading] = useState(false);

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataInicial() &&
                  !periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data inicial',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataInicial() &&
                  !!periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            }),

          dataEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a hora final',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataFinal() &&
                  !periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a data final',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataFinal() &&
                  !!periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IConhecimentoTransporteTerceirosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IConhecimentoTransporteTerceirosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IConhecimentoTransporteTerceirosRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IConhecimentoTransporteTerceirosFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      dataHoraInicial: dataInicial,
      dataHoraFinal: dataFinal,
      opcaoRelatorio: 'ConhecimentoTransporteTerceirosPorTomador',
      tipoData: 'Emissao',
    });
  }, [formRef]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Conhecimento de Transporte de Terceiro
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Tomador',
                        value: 'ConhecimentoTransporteTerceirosPorTomador',
                      },
                      {
                        title: 'Por Emitente',
                        value: 'ConhecimentoTransporteTerceirosPorEmitente',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={8} sm={8}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Inicial"
                      labelDataHoraFinal="Data Final"
                      nomeDataHoraInicial="dataHoraInicial"
                      nomeDataHoraFinal="dataHoraFinal"
                      ref={periodoDataHoraRef}
                    />
                  </Col>
                  <Col xl={3} sm={3}>
                    <Select
                      name="tipoData"
                      label="Tipo Data"
                      options={FormatarEnum({
                        enumObj: TipoDataConhecimentoTransporteTerceiroEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagSituacaoConhecimentoTransporteEnum
                      label="Situação"
                      name="listaSituacao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Tomadores"
                      name="listaTomador"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Emitentes"
                      name="listaEmitente"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Remetentes"
                      name="listaRemetente"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Destinatários"
                      name="listaDestinatario"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Recebedores"
                      name="listaRecebedor"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCliente
                      label="Expedidores"
                      name="listaExpedidor"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} lg={12} sm={12} style={{ marginTop: 10 }}>
                    <InputInteiroTag
                      label="Números das Notas"
                      name="listaNumerosNotas"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputChaveAcessoTag
                      label="Chaves de Acesso"
                      name="listaChavesAcesso"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default SuprimentoConhecimentoTransporteTerceiros;
