import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import {
  FormatarDataHoraParaPtBr,
  FormatarEnum,
  IOrdemProducaoItemLocalEstoqueLista,
  TipoEstoqueOrdemProducaoEnum,
  TipoProducaoEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import InputAutoCompleteLocalEstoque from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import Divisor from '../../../../../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompleteLote from '../../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import InputDateTime from '../../../../../../../../../../../Componentes/Inputs/InputDateTime';
import Select from '../../../../../../../../../../../Componentes/Select';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheLocalEstoque: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemProducao: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    formRefDetalhe,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<IOrdemProducaoItemLocalEstoqueLista[]>([]);
  const [listaValor, setListaValor] = useState<
    IOrdemProducaoItemLocalEstoqueLista[]
  >([]);
  const [showLotes, setShowLotes] = useState<boolean | undefined>(true);

  const produtoObjeto =
    formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

  const isDiferenteProducao =
    formPrincipal.formRef.current?.getFieldValue('tipoProducao') !==
    TipoProducaoEnum.industrializacaoParaTerceiros;

  const handleOnBlurSugerirQuantidade = useCallback(async () => {
    let somaQuantidadeLocalEstoque = 0;
    let diferencaQuantidadeLocalEstoque = 0;

    const dataHoraTermino =
      formPrincipal.formRef?.current?.getFieldValue('dataHoraTermino');

    const quantidadeDetalhe = formRefDetalhe.current?.getFieldValue(
      'quantidadeDemandaTotal'
    );

    const listaOrdemProducaoItemLocalEstoque =
      formRefDetalhe.current?.getFieldValue(
        'listaOrdemProducaoItemLocalEstoque'
      );

    if (listaOrdemProducaoItemLocalEstoque) {
      somaQuantidadeLocalEstoque += listaOrdemProducaoItemLocalEstoque.reduce(
        (acumulador: number, item: IOrdemProducaoItemLocalEstoqueLista) => {
          acumulador += Number(item.quantidade);
          return acumulador;
        },
        0
      );
    }

    if (quantidadeDetalhe > 0) {
      diferencaQuantidadeLocalEstoque =
        quantidadeDetalhe - somaQuantidadeLocalEstoque;
    }

    if (
      listaOrdemProducaoItemLocalEstoque.length > 0 &&
      quantidadeDetalhe > 0 &&
      diferencaQuantidadeLocalEstoque > 0
    ) {
      formRefLista.current?.setFieldValue(
        'quantidade',
        diferencaQuantidadeLocalEstoque
      );
    } else if (
      listaOrdemProducaoItemLocalEstoque.length === 0 &&
      quantidadeDetalhe > 0
    ) {
      formRefLista.current?.setFieldValue('quantidade', quantidadeDetalhe);
    }

    if (dataHoraTermino && dataHoraTermino.getTime() < new Date().getTime()) {
      formRefLista.current?.setFieldValue(
        'dataHoraConsumo',
        new Date(dataHoraTermino).setMilliseconds(0)
      );
    } else {
      formRefLista.current?.setFieldValue('dataHoraConsumo', new Date());
    }
  }, [formPrincipal.formRef, formRefDetalhe, formRefLista]);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getDataDuplicar();

    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor, produtoObjeto]);

  useEffect(() => {
    registerField<IOrdemProducaoItemLocalEstoqueLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (produtoObjeto) {
      if (produtoObjeto.produtoEstoque) {
        setShowLotes(produtoObjeto.produtoEstoque.controlarLote);
      }
    }
  }, [produtoObjeto, showLotes]);

  useEffect(() => {
    formRefLista.current?.setDataInicial({
      tipoEstoque: TipoEstoqueOrdemProducaoEnum.proprioEmPoderProprio,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRefLista.current]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <Select
                name="tipoEstoque"
                label="Tipo Estoque"
                options={FormatarEnum({
                  enumObj: TipoEstoqueOrdemProducaoEnum,
                })}
                disabled={isDiferenteProducao}
              />
            </Col>
            <Col lg={showLotes ? 6 : 9} md={6} sm={12}>
              <InputAutoCompleteLocalEstoque
                name="idLocalEstoque"
                label="Local de Estoque"
                nomeObjeto="localEstoque"
                placeholder="Local de Estoque"
                onBlurInput={handleOnBlurSugerirQuantidade}
              />
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              style={{ display: showLotes ? '' : 'none' }}
            >
              <InputAutoCompleteLote
                name="idLote"
                label="Lote"
                nomeObjeto="lote"
                placeholder="Lote"
                permitirAdicionar={false}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={6} sm={4}>
              <InputDecimal
                label="Quantidade"
                placeholder="Quantidade"
                name="quantidade"
                casasDecimais={4}
                casasInteiras={15}
              />
            </Col>
            <Col lg={5} md={5} sm={4}>
              <InputDateTime
                label="Data e Hora de Consumo"
                name="dataHoraConsumo"
              />
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <BtnPadraoButton
                type="button"
                onClick={handleAdicionar}
                className="btn-padrao btn-verde-claro btn-adicionar"
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAdd />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th
                    className="lista-texto"
                    style={{ width: showLotes ? '40%' : '60%' }}
                  >
                    <span>Local de Estoque</span>
                  </th>
                  {showLotes && (
                    <th className="lista-texto" style={{ width: '20%' }}>
                      <span>Lote</span>
                    </th>
                  )}
                  <th className="lista-valor" style={{ width: '20%' }}>
                    <span>Quantidade</span>
                  </th>
                  <th className="lista-data" style={{ width: '20%' }}>
                    <span>Data Hora Consumo</span>
                  </th>
                  <th className="lista-texto">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item, index) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">
                        {item.localEstoque?.descricao}
                      </td>
                      {showLotes && (
                        <td className="lista-texto">
                          {item.lote && item.lote.codigo}
                        </td>
                      )}
                      <td className="lista-valor">
                        {Number(item.quantidade).FormatarParaPtBr({
                          maximoCasasDecimais: 4,
                          minimoCasasDecimais: 4,
                        })}
                      </td>
                      <td className="lista-data">
                        {FormatarDataHoraParaPtBr(item.dataHoraConsumo)}
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleExcluir(String(index));
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <IoMdRemoveCircleOutline
                                  style={{ marginRight: 5 }}
                                />
                                Remover
                              </span>
                            </Dropdown.Item>
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheLocalEstoque;
