"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsStRetidoCalculo;
function IcmsStRetidoCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { baseCalculoIcmsStRetido: null, valorIcmsStRetido: null, baseCalculoFcpIcmsStRetido: null, valorFcpIcmsStRetido: null });
    if (dados.aliquotaIcmsStRetido != null && dados.quantidadeItem != null) {
        retorno.baseCalculoIcmsStRetido =
            Number(dados.quantidadeItem) *
                Number(dados.baseCalculoUnitariaProdutoIcmsStRetido);
        retorno.valorIcmsStRetido =
            Number(dados.quantidadeItem) *
                Number(dados.valorUnitarioProdutoIcmsStRetido);
    }
    if (dados.aliquotaFcpIcmsStRetido != null &&
        dados.aliquotaFcpIcmsStRetido > 0 &&
        dados.quantidadeItem != null) {
        retorno.baseCalculoFcpIcmsStRetido =
            Number(dados.quantidadeItem) *
                Number(dados.baseCalculoUnitariaProdutoFcpIcmsStRetido);
        retorno.valorFcpIcmsStRetido =
            Number(dados.quantidadeItem) *
                Number(dados.valorUnitarioProdutoFcpIcmsStRetido);
    }
    return retorno;
}
