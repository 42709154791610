"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = isDate;
function isDate(value) {
    if (!(value instanceof Date) && typeof value !== 'string') {
        return false;
    }
    const regex = /^\d\d\d\d-\d\d-\d\d$/;
    if (Object.prototype.toString.call(value) === '[object Date]') {
        return true;
    }
    if (value.toString().match(regex)) {
        return true;
    }
    return false;
}
