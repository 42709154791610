import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { Col, Container, Dropdown, Row, Table } from 'react-bootstrap';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import { INotaFiscalSaidaItemExportacaoLista } from '@elogestor/util';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../../../../Componentes/Inputs/Input';
import InputDecimal from '../../../../../../../../../../../Componentes/Inputs/InputDecimal';
import BtnPadraoButton from '../../../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../../../../Componentes/Styles/Tabela';
import { UseForm } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import BtnAcoesButton from '../../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';

interface IListaDetalheGeral {
  name: string;
}

const ListaDetalheItemExportacao: React.FC<IListaDetalheGeral> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleSubmit,
    handleExcluir,
  } = UseListaDetalheForm();

  const valorRef = useRef<INotaFiscalSaidaItemExportacaoLista[]>([]);
  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaItemExportacaoLista[]
  >([]);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getData();

    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<INotaFiscalSaidaItemExportacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <Divisor>
          <Row>
            <Col xl={3} lg={3}>
              <Input
                name="numeroDrawback"
                label="Número Drawback"
                placeholder="Número Drawback"
                max={11}
              />
            </Col>

            <Col xl={3} lg={3}>
              <Input
                name="numeroRegistroExportacao"
                label="Núm. Registro Exportação"
                placeholder="Núm. Registro Exportação"
                max={12}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6}>
              <Input
                name="chaveAcesso"
                label="Chave de Acesso da Nota do Fornecedor"
                placeholder="Chave de Acesso"
                max={44}
              />
            </Col>

            <Col xl={3} lg={3}>
              <InputDecimal
                name="quantidadeExportacao"
                label="Quantidade Exportada"
                placeholder="Quantidade Exportada"
                casasDecimais={4}
                casasInteiras={15}
              />
            </Col>

            <Col
              xl={3}
              lg={3}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <BtnPadraoButton
                type="button"
                onClick={handleAdicionar}
                className="btn-padrao btn-verde-claro btn-adicionar"
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAdd />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>

          <Tabela style={{ overflow: 'auto' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th className="lista-texto" style={{ width: '25%' }}>
                    <span>Núm. Drawback</span>
                  </th>
                  <th className="lista-texto" style={{ width: '25%' }}>
                    <span>Núm. Registro</span>
                  </th>
                  <th className="lista-texto" style={{ width: '25%' }}>
                    <span>Chave Acesso da Nota</span>
                  </th>
                  <th className="lista-valor" style={{ width: '25%' }}>
                    <span>Quantidade Exportada</span>
                  </th>
                  <th className="lista-acoes">
                    <span>Ações</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listaValor.map((item, index) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">{item.numeroDrawback}</td>
                      <td className="lista-texto">
                        {item.numeroRegistroExportacao}
                      </td>
                      <td className="lista-texto">{item.chaveAcesso}</td>
                      <td className="lista-valor">
                        {Number(item.quantidadeExportacao).FormatarParaPtBr({
                          maximoCasasDecimais: 4,
                          minimoCasasDecimais: 4,
                        })}
                      </td>
                      <td className="tdButton lista-acoes">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <BtnAcoesButton>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleExcluir(String(index));
                              }}
                              disabled={
                                !permissao?.exclui ||
                                formPrincipal.loading ||
                                loading
                              }
                            >
                              <span style={{ display: 'flex', fontSize: 16 }}>
                                <IoMdRemoveCircleOutline
                                  style={{ marginRight: 5 }}
                                />
                                Remover
                              </span>
                            </Dropdown.Item>
                          </BtnAcoesButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tabela>
        </Divisor>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheItemExportacao;
