import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IMovimentacoesFinanceirasMovimentacoesPorPortadorRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IMovimentacaoPorPortadorRelatorioPDF {
  dados: IMovimentacoesFinanceirasMovimentacoesPorPortadorRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});
const MovimentacaoPorPortadorPDF: React.FC<
  IMovimentacaoPorPortadorRelatorioPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataHoraEmissaoInicial &&
                  dados.cabecalho.filtroDataHoraEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataHoraEmissaoInicial} até ${dados.cabecalho.filtroDataHoraEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroTipoData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Tipo de Data de Liquidação: '}
                      </Text>
                      {dados.cabecalho.filtroTipoData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroPortadoresLiquidacao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Portadores (Liquidacao): '}
                      </Text>
                      {dados.cabecalho.filtroPortadoresLiquidacao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSaldoInicial && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Saldo Inicial: '}
                      </Text>
                      {Number(
                        dados.cabecalho.filtroSaldoInicial
                      ).FormatarParaPtBr()}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              fontWeight: 'bold',
              marginTop: 5,
            }}
          >
            <View style={{ width: '8%' }}>
              <Text>Num. Documento</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '8%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Data Liquidação</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
              }}
            >
              <Text>Data Contábil</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '22%',
              }}
            >
              <Text>Cliente/Fornecedor</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '16%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Observações</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '12%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Forma Pagamento</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '5%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Tipo</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',
                alignItems: 'flex-end',
              }}
            >
              <Text>Valor</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View
              style={{
                width: '7%',

                alignItems: 'flex-end',
              }}
            >
              <Text>Saldo</Text>
            </View>
          </View>

          <View>
            <View>
              {dados.listaDados.map((dado: any, dadoIndex: number) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        fontWeight: 'bold',
                        borderTop: dadoIndex !== 0 ? '1pt dotted black' : '',
                        padding: '6pt 0pt 0pt 0pt',
                        marginBottom: 2,
                      }}
                    >
                      <View
                        style={{
                          width: '1%',
                        }}
                      />
                      <View style={{ width: '40%' }}>
                        <Text>{`Portador: ${dado.chave}`}</Text>
                      </View>
                      <View style={{ width: '60%' }}>
                        <Text>{`Saldo Anterior: ${Number(
                          dado.listaMovimentacaoPortador[0].saldoAnterior
                        ).FormatarParaPtBr()}`}</Text>
                      </View>
                    </View>

                    <View>
                      {dado.listaMovimentacaoPortador.map(
                        (movimentacao: any, movimentacaoIndex: number) => {
                          const campo =
                            dados.cabecalho.filtroTipoData === 'Data Liquidação'
                              ? 'data'
                              : 'dataContabil';

                          const isDatasIgualAnterior = !!(
                            movimentacaoIndex > 0 &&
                            movimentacao[campo] !==
                              dado.listaMovimentacaoPortador[
                                movimentacaoIndex - 1
                              ][campo]
                          );

                          return (
                            <View key={String(movimentacaoIndex)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor:
                                    movimentacaoIndex % 2 === 0
                                      ? '#E4E4E4'
                                      : '#fff',
                                  borderTop: isDatasIgualAnterior
                                    ? '1px dotted black'
                                    : undefined,
                                }}
                              >
                                <View
                                  style={{
                                    width: '8%',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <Text>{movimentacao.numeroDocumento}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '8%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{movimentacao.data}</Text>
                                </View>

                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{movimentacao.dataContabil}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '22%' }}>
                                  <Text>{movimentacao.nomeRazaoSocial}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '16%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{movimentacao.observacoes}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '12%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{movimentacao.formaPagamento}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '5%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{movimentacao.tipo}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {Number(
                                      movimentacao.valor
                                    ).FormatarParaPtBr()}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '7%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {Number(
                                      movimentacao.saldo
                                    ).FormatarParaPtBr()}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        marginTop: '2pt',
                        paddingTop: '2pt',
                        flexDirection: 'row',
                        fontSize: 9,
                      }}
                    />
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MovimentacaoPorPortadorPDF;
