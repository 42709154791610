import {
  IOrdemDesmontagemItemValoresInserir,
  IOrdemDesmontagemLocalEstoqueValoresInserir,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ValidarEstoqueMovimentoDesmontagemComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemDesmontagem/ValidarEstoqueMovimentoDesmontagemComunicador/ValidarEstoqueMovimentoDesmontagemComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';

interface IProps {
  listaOrdemDesmontagemItem: IOrdemDesmontagemItemValoresInserir[];
  listaOrdemDesmontagemLocalEstoque: IOrdemDesmontagemLocalEstoqueValoresInserir[];
  quantidade: number;
  dataHoraInicio: any;
  dataHoraTermino: any;
}

interface IOrdemDesmontagemContext {
  validador: boolean;

  listaItemLocalEstoqueRemover: string[];
  setListaItemLocalEstoqueRemover(lista: string[]): string[];
  listaIdEstoqueMovimentoRemover: string[];
  setListaIdEstoqueMovimentoRemover(lista: string[]): void;
  handleValidarExclusao(valor: string): Promise<boolean>;
  handleValidarExclusaoLista(lista: string[]): Promise<boolean>;
  handleAlterarSituacao({
    listaOrdemDesmontagemItem,
    listaOrdemDesmontagemLocalEstoque,
    quantidade,
    dataHoraInicio,
    dataHoraTermino,
  }: IProps): boolean;
}

const OrdemDesmontagemContext = createContext<IOrdemDesmontagemContext>(
  {} as IOrdemDesmontagemContext
);

const OrdemDesmontagemHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaItemLocalEstoqueRemover, setListaItemLocalEstoqueRemover] =
    useState<string[]>([]);

  const [listaIdEstoqueMovimentoRemover, setListaIdEstoqueMovimentoRemover] =
    useState<string[]>([]);

  const handleSetListaItemLocalEstoqueRemover = useCallback(
    (lista: string[]): string[] => {
      const listaValor = [...lista];
      setListaItemLocalEstoqueRemover(lista);
      return listaValor;
    },
    []
  );

  const handleValidarExclusaoLista = useCallback(
    async (lista: string[]): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaIdEstoqueMovimentoRemover, ...lista];
        const response =
          await ValidarEstoqueMovimentoDesmontagemComunicador.store({
            params: lista,
          });

        if (response) {
          setListaIdEstoqueMovimentoRemover(listaIdRemover);
          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaIdEstoqueMovimentoRemover]
  );

  const handleValidarExclusao = useCallback(
    async (valor: string): Promise<boolean> => {
      try {
        const listaIdRemover = [...listaItemLocalEstoqueRemover, valor];
        const response =
          await ValidarEstoqueMovimentoDesmontagemComunicador.store({
            params: listaIdRemover,
          });

        if (response) {
          listaIdRemover.forEach((it) => {
            setListaIdEstoqueMovimentoRemover((prevState) => {
              return [...prevState, it];
            });
          });

          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        return false;
      }
    },
    [listaItemLocalEstoqueRemover]
  );

  const handleAlterarSituacao = useCallback(
    ({
      listaOrdemDesmontagemItem,
      listaOrdemDesmontagemLocalEstoque,
      quantidade,
      dataHoraInicio,
      dataHoraTermino,
    }: IProps): boolean => {
      if (
        listaOrdemDesmontagemItem.length === 0 ||
        listaOrdemDesmontagemLocalEstoque.length === 0 ||
        !quantidade ||
        !dataHoraInicio ||
        !dataHoraTermino
      ) {
        return false;
      }

      let totaisMarcados = 0;
      listaOrdemDesmontagemItem.forEach((valor: any) => {
        if (valor.desmontado) {
          totaisMarcados++;
        }
      });

      const somaQuantidadeLocalEstoque =
        listaOrdemDesmontagemLocalEstoque.reduce((acumulador: number, item) => {
          acumulador += Number(item.quantidade) || 0;
          return acumulador;
        }, 0);
      if (
        totaisMarcados === listaOrdemDesmontagemItem.length &&
        quantidade === somaQuantidadeLocalEstoque &&
        dataHoraInicio &&
        dataHoraTermino
      ) {
        return true;
      }
      return false;
    },
    []
  );

  return (
    <OrdemDesmontagemContext.Provider
      value={{
        listaItemLocalEstoqueRemover,
        setListaItemLocalEstoqueRemover: handleSetListaItemLocalEstoqueRemover,
        listaIdEstoqueMovimentoRemover,
        setListaIdEstoqueMovimentoRemover,
        handleValidarExclusao,
        handleValidarExclusaoLista,
        handleAlterarSituacao,
        validador: true,
      }}
    >
      {children}
    </OrdemDesmontagemContext.Provider>
  );
};

function UseOrdemDesmontagem(): Omit<IOrdemDesmontagemContext, 'validador'> {
  const context = useContext(OrdemDesmontagemContext);

  if (!context.validador) {
    throw new Error(
      'UseOrdemDesmontagem deve ser usado com um OrdemDesmontagemHook'
    );
  }

  return context;
}

export { OrdemDesmontagemHook, UseOrdemDesmontagem };
