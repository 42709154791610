import React, { useEffect, useMemo, useState } from 'react';
import { ComunicadorProvider } from '../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import ConhecimentoTransporteComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteComunicador';
import FormHook from './FormHook';
import { UseImpostoIcms, ImpostoIcmsHook } from './ImpostoIcmsHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { SituacaoBloquearCamposHook } from './SituacaoBloquearCamposHook';
import { ShowTabsHook } from './ShowTabsHook';
import { ParticipantesHook } from './ParticipantesHook';
import { RegimeTributarioHook } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import {
  ImpostoIcmsOpInterestadualHook,
  UseImpostoIcmsOpInterestadual,
} from './ImpostoIcmsOpInterestadualHook';

const ConhecimentoTransporteHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: ConhecimentoTransporteComunicador }}
      >
        <RotasHook>
          <ShowTabsHook>
            <RegimeTributarioHook>
              <ImpostoIcmsFomHook>
                <ImpostoIcmsOpInterestadualFomHook>
                  <ParticipantesHook>
                    <SituacaoBloquearCamposHook>
                      <SubHeaderHook>{children}</SubHeaderHook>
                    </SituacaoBloquearCamposHook>
                  </ParticipantesHook>
                </ImpostoIcmsOpInterestadualFomHook>
              </ImpostoIcmsFomHook>
            </RegimeTributarioHook>
          </ShowTabsHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

const ImpostoIcmsFomHook: React.FC<IPadraoProps> = ({ children }) => {
  const [atualizarCampos, setAtualizarCampos] = useState<any>();

  const AtualizarCamposHook = useMemo(() => {
    return () => {
      const params = UseImpostoIcms();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        setAtualizarCampos(() => params.obterVisivbilidadeBloqueioCamposIcms);
      }, [params.obterVisivbilidadeBloqueioCamposIcms]);

      return <></>;
    };
  }, []);

  return (
    <FormHook atualizarCampos={atualizarCampos}>
      <ImpostoIcmsHook>
        <AtualizarCamposHook />
        {children}
      </ImpostoIcmsHook>
    </FormHook>
  );
};

const ImpostoIcmsOpInterestadualFomHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [atualizarCampos, setAtualizarCampos] = useState<any>();

  const AtualizarCamposHook = useMemo(() => {
    return () => {
      const params = UseImpostoIcmsOpInterestadual();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        setAtualizarCampos(
          () => params.obterVisivbilidadeBloqueioCamposIcmsOpInterestadual
        );
      }, [params.obterVisivbilidadeBloqueioCamposIcmsOpInterestadual]);

      return <></>;
    };
  }, []);

  return (
    <FormHook atualizarCampos={atualizarCampos}>
      <ImpostoIcmsOpInterestadualHook>
        <AtualizarCamposHook />
        {children}
      </ImpostoIcmsOpInterestadualHook>
    </FormHook>
  );
};

export default ConhecimentoTransporteHook;
