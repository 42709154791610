"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoCotacaoCompraPedidoCompraEnum;
(function (SituacaoCotacaoCompraPedidoCompraEnum) {
    SituacaoCotacaoCompraPedidoCompraEnum["atendido"] = "Atendido";
    SituacaoCotacaoCompraPedidoCompraEnum["parcialmenteAtendido"] = "Parcialmente Atendido";
    SituacaoCotacaoCompraPedidoCompraEnum["naoAtendido"] = "N\u00E3o Atendido";
})(SituacaoCotacaoCompraPedidoCompraEnum || (SituacaoCotacaoCompraPedidoCompraEnum = {}));
exports.default = SituacaoCotacaoCompraPedidoCompraEnum;
