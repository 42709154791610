import styled from 'styled-components';
import { Modal as ModalB } from 'react-bootstrap';

export const Container = styled.div`
  padding: 20px;
  position: relative;
`;

export const Modal = styled(ModalB)`
  label {
    margin-top: 10px;
  }

  input,
  select {
    background: #fff;
  }
`;

export const UltimaTr = styled.tr`
  background-color: white;
  border-color: transparent;

  th,
  div {
    background-color: white;
  }
`;
