import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import TerminalCaixaComunicador from '../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { SiTefHook } from '../../../FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';

const TerminalCaixaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: TerminalCaixaComunicador }}>
      <RotasHook>
        <FormHook>
          <SiTefHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </SiTefHook>
        </FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default TerminalCaixaHook;
