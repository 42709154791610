"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModalidadeFreteEnum;
(function (ModalidadeFreteEnum) {
    ModalidadeFreteEnum["porContaRemetenteCif"] = "0 - Contrata\u00E7\u00E3o do Frete por conta do Remetente (CIF)";
    ModalidadeFreteEnum["porContaDestinatarioFob"] = "1 - Contrata\u00E7\u00E3o do Frete por conta do Destinat\u00E1rio (FOB)";
    ModalidadeFreteEnum["porContaTerceiros"] = "2 - Contrata\u00E7\u00E3o do Frete por conta de Terceiros";
    ModalidadeFreteEnum["transporteProprioPorContaRemetente"] = "3 - Transporte Pr\u00F3prio por conta do Remetente";
    ModalidadeFreteEnum["transporteProprioPorContaDestinatario"] = "4 - Transporte Pr\u00F3prio por conta do Destinat\u00E1rio";
    ModalidadeFreteEnum["semFrete"] = "9 - Sem Ocorr\u00EAncia de Transporte";
})(ModalidadeFreteEnum || (ModalidadeFreteEnum = {}));
exports.default = ModalidadeFreteEnum;
