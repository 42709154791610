import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';

const SubstitutoTab: React.FC = () => {
  const { formRef } = UseForm();

  const transmissaoVersaoLayout = formRef.current?.getFieldValue(
    'transmissao.versaoLayout'
  );

  function clearSubstitutoErrors(input: boolean): void {
    if (!input) return;

    const fields = [
      'substitutoChaveAcessoCteOriginal',
      'substitutoChaveAcessoCteAnulacao',
      'substitutoChaveAcessoNfe',
      'substitutoChaveAcessoCteEmitTomador',
    ];

    fields.forEach((field) => {
      formRef.current?.setFieldError(field, '');
    });
  }

  return (
    <Divisor>
      <Row>
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e a ser Substituído (original)"
            name="substitutoChaveAcessoCteOriginal"
            placeholder="Chave de Acesso do CT-e a ser Substituído (original)"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
          />
        </Col>
      </Row>

      <Row
        style={{
          display:
            !transmissaoVersaoLayout || transmissaoVersaoLayout === '4.00'
              ? 'none'
              : '',
        }}
      >
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e de Anulação"
            name="substitutoChaveAcessoCteAnulacao"
            placeholder="Chave de Acesso do CT-e de Anulação"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Input
            label="Chave de acesso da NF-e emitida pelo Tomador"
            name="substitutoChaveAcessoNfe"
            placeholder="Chave de acesso da NF-e emitida pelo Tomador"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Input
            label="Chave de Acesso do CT-e emitido pelo Tomador"
            name="substitutoChaveAcessoCteEmitTomador"
            placeholder="Chave de Acesso do CT-e emitido pelo Tomador"
            maxLength={44}
            onBlur={(event) => {
              clearSubstitutoErrors(!!event.target.value);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InputSwitch
            name="tomadorAlteradoSubstituto"
            label="Tomador Alterado no CT-e Substituto"
            ativo="Sim"
            inativo="Não"
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default SubstitutoTab;
