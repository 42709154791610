import React, {
  useCallback,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  IUsuarioMenuPermissaoValoresAlterar,
  TipoUsuarioEnum,
} from '@elogestor/util';
import MenuComponent, { IMenuComponentRef } from './MenuComponent';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseUsuarioTipo } from '../../Hooks/UsuarioTipoHook';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

interface IListaMenuComponentProps {
  listaMenu: IUsuarioMenuPermissaoValoresAlterar[];
  margin?: number;
  marcarPai?: (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => void;
  desmarcarPai?: (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => void;
}

export interface IListaMenuComponentRef {
  marcarTodos(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  desmarcarTodos(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  mostrarValores(): IUsuarioMenuPermissaoValoresAlterar[];
}

const ListaMenuComponent: React.ForwardRefRenderFunction<
  IListaMenuComponentRef,
  IListaMenuComponentProps
> = ({ listaMenu, margin = 0, marcarPai, desmarcarPai }, ref) => {
  const { formRef, getIdDetalheRegistro, refresh } = UseForm();
  const { alterouTipo } = UseUsuarioTipo();

  const listaMenuRef = useRef<IMenuComponentRef[]>([]);

  const marcarTodos = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => {
      listaMenuRef.current.forEach((menuRef) => menuRef.marcarTodos(tipo));
    },
    []
  );

  const desmarcarTodos = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => {
      listaMenuRef.current.forEach((menuRef) => menuRef.desmarcarTodos(tipo));
    },
    []
  );

  const mostrarValores = useCallback(() => {
    const valor = listaMenuRef.current.map((menuRef) => {
      return menuRef.mostrarValor();
    });

    return valor;
  }, []);

  useImperativeHandle(ref, () => ({
    marcarTodos,
    desmarcarTodos,
    mostrarValores,
  }));

  const atualizarPermissoes = useCallback(async (): Promise<void> => {
    const tipoUsuario = formRef.current?.getFieldValue('tipo');

    if (!getIdDetalheRegistro()) {
      if (tipoUsuario === TipoUsuarioEnum.representante) {
        if (listaMenuRef.current.length > 0) {
          for (let i = 0; i < listaMenuRef.current.length; i++) {
            const menuRef = listaMenuRef.current[i];
            const valores = menuRef.mostrarValor();

            if (
              (valores.descricao === 'Comercial' && !valores.rota) ||
              (valores.descricao === 'Pessoas' && !valores.rota) ||
              (valores.descricao === 'Cliente/Fornecedor/Transp.(Pessoa)' &&
                valores.rota === '/comercial/pessoas/pessoa') ||
              //

              (valores.descricao === 'Vendas' &&
                !valores.rota &&
                valores.ordem === 20) ||
              (valores.descricao === 'Pedido de Venda' &&
                valores.rota === '/comercial/vendas/pedido-venda') ||
              //

              (valores.descricao === 'Relatórios' &&
                !valores.rota &&
                valores.ordem === 999) ||
              (valores.descricao === 'Comissões' && !valores.rota) ||
              (valores.descricao === 'Comissões (NF. Fiscal)' &&
                valores.rota ===
                  '/comercial/relatorios/comissoes/comissoes-nota-fiscal') ||
              //

              (valores.descricao === 'Usuários' &&
                valores.rota === '/configuracao/usuario') ||
              (valores.descricao === 'Configuração' && !valores.rota)
            ) {
              menuRef.desmarcar('acessa');
              menuRef.marcar('inclui');
              menuRef.marcar('altera');
              menuRef.desmarcar('exclui');

              listaMenu[i].acessa = false;
              listaMenu[i].inclui = true;
              listaMenu[i].altera = true;
              listaMenu[i].exclui = false;
            } else if (valores.descricao === 'Ramo Atividade/Profissão') {
              menuRef.marcar('inclui');
              menuRef.marcar('altera');

              menuRef.desmarcar('acessa');
              menuRef.desmarcar('exclui');

              listaMenu[i].inclui = true;
              listaMenu[i].altera = true;

              listaMenu[i].acessa = false;
              listaMenu[i].exclui = false;
            } else if (
              (valores.descricao === 'Financeiro' && !valores.rota) ||
              (valores.descricao === 'Adiantamento / Previsão' &&
                valores.rota === '/financeiro/adiantamento-previsao')
            ) {
              menuRef.marcar('inclui');
              menuRef.marcar('altera');

              menuRef.desmarcar('acessa');
              menuRef.desmarcar('exclui');

              listaMenu[i].inclui = true;
              listaMenu[i].altera = true;

              listaMenu[i].acessa = false;
              listaMenu[i].exclui = false;
            } else {
              menuRef.desmarcar('acessa');
              menuRef.desmarcar('inclui');
              menuRef.desmarcar('altera');
              menuRef.desmarcar('exclui');

              listaMenu[i].acessa = false;
              listaMenu[i].inclui = false;
              listaMenu[i].altera = false;
              listaMenu[i].exclui = false;
            }

            await Sleep(0.1);
          }
        }

        refresh();
      } else if (
        tipoUsuario === TipoUsuarioEnum.colaborador ||
        tipoUsuario === TipoUsuarioEnum.contabilidade ||
        tipoUsuario === TipoUsuarioEnum.eloGestor ||
        tipoUsuario === TipoUsuarioEnum.gestor
      ) {
        if (listaMenuRef.current.length > 0) {
          for (let i = 0; i < listaMenuRef.current.length; i++) {
            const menuRef = listaMenuRef.current[i];

            menuRef.marcar('acessa');
            menuRef.marcar('inclui');
            menuRef.marcar('altera');
            menuRef.marcar('exclui');

            listaMenu[i].acessa = true;
            listaMenu[i].inclui = true;
            listaMenu[i].altera = true;
            listaMenu[i].exclui = true;

            await Sleep(1);
          }
        }

        refresh();
      }
    }
  }, [formRef, getIdDetalheRegistro, listaMenu, refresh]);

  useEffect(() => {
    atualizarPermissoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alterouTipo]);

  return (
    <>
      {listaMenu.map((menu, index) => {
        return (
          <MenuComponent
            key={menu.id}
            menu={menu}
            margin={margin}
            marcarPai={marcarPai}
            desmarcarPai={desmarcarPai}
            ref={(element) => {
              if (element) {
                listaMenuRef.current[index] = element;
              }
            }}
          />
        );
      })}
    </>
  );
};

export default forwardRef(ListaMenuComponent);
