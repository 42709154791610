import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  CalcularValorTotalParcela,
  FormatarDataParaPtBrSemTimeZone,
  IMovimentoPortadorValoresAlterar,
} from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
  UseListaDetalheForm,
} from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../../../Util/Erro/GetValidationErrors';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../../../../Comum/Interface/IPadraoProps';
import { UseAntecipacaoPrevisao } from '../../../../../Hooks/AntecipacaoPrevisaoHook';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { formRefLista } = UseListaDetalheForm();
  const { atualizaAntecipacaoPrevisao } = UseAntecipacaoPrevisao();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idContaAdiantamento = formRefDetalhe.current?.getFieldValue(
    'idContaAdiantamento'
  );

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IMovimentoPortadorValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const listaContaParcelaLiquidacao = formRefLista.current?.getFieldValue(
          'listaContaParcelaLiquidacao'
        );

        const response = listaContaParcelaLiquidacao[Number(idEditar)];
        response.percentualLiquidado = Number(response.percentualLiquidado);

        await formRefDetalhe.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const valorTotalAberto =
          formRefLista.current?.getFieldValue('valorTotalAberto');
        const valorTotalParcela =
          formRefLista.current?.getFieldValue('valorTotalParcela');
        const dataVencimento =
          formRefLista.current?.getFieldValue('dataVencimento');
        const contaAdiantamento =
          formRefLista.current?.getFieldValueNomeObjeto('contaAdiantamento');
        const portador =
          formRefLista.current?.getFieldValueNomeObjeto('portador');
        const formaPagamento =
          formRefLista.current?.getFieldValueNomeObjeto('formaPagamento');
        const percentualLiquidado = valorTotalParcela
          ? (Number(valorTotalAberto) / Number(valorTotalParcela)) * 100
          : 0;

        await formRefDetalhe.current?.setDataInicial({
          valor: valorTotalAberto,
          percentualLiquidado,
          data: dataVencimento,
          dataContabil: dataVencimento,
          portador: portador ?? null,
          formaPagamento: formaPagamento ?? null,
          contaAdiantamento: contaAdiantamento ?? null,

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      formRefLista,
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
    ]
  );

  const handleValidarTotais = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      const listaMensagemErro: String[] = [];
      const id = getIdDetalheRegistro() || '';
      const listaContaParcelaLiquidacao = formRefLista.current?.getFieldValue(
        'listaContaParcelaLiquidacao'
      );
      let listaContaParcelaLiquidacaoFicticia: any = [];

      const valorParcela = formRefLista.current?.getFieldValue('valorParcela');

      if (!id) {
        listaContaParcelaLiquidacaoFicticia = [
          ...listaContaParcelaLiquidacao,
          data,
        ];
      } else {
        listaContaParcelaLiquidacaoFicticia[id] = data;
      }

      const somaValoresGeraisLiquidacao = listaContaParcelaLiquidacaoFicticia
        .reduce((acc: any, item: any) => {
          acc += Number(item.valorGeral);
          return acc;
        }, 0)
        .Arredondar();

      if (somaValoresGeraisLiquidacao > valorParcela) {
        listaMensagemErro.push(
          'Soma dos Valores Gerais das Liquidações é maior que o Valor da Parcela!'
        );
        formRef?.setErrors({
          valorGeral:
            'Soma dos Valores Gerais das Liquidações é maior que o Valor da Parcela!',
        });
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        return false;
      }

      return true;
    },
    [formRefLista, getIdDetalheRegistro]
  );

  const handleObterValorTotalLiquidacao = useCallback(
    async (data: any): Promise<number> => {
      const {
        valorGeral,
        valorJurosSoma,
        valorDescontoSubtrai,
        valorMultaSoma,
        valorOutrasDespesasSoma,
        valorOutrasDespesasSubtrai,
        valorDespesasCartaoSubtrai,
        valorDespesasCartorioSoma,
        valorDespesasEnvioSoma,
        valorComissoesSoma,
        valorDespesasEnvioSubtrai,
        valorComissoesSubtrai,
      } = data;

      const valorTotalLiquidacao = CalcularValorTotalParcela({
        valorParcela: Number(valorGeral),
        valorJurosSoma: Number(valorJurosSoma),
        valorDescontoSubtrai: Number(valorDescontoSubtrai),
        valorMultaSoma: Number(valorMultaSoma),
        valorOutrasDespesasSoma: Number(valorOutrasDespesasSoma),
        valorOutrasDespesasSubtrai: Number(valorOutrasDespesasSubtrai),
        valorDespesasCartaoSubtrai: Number(valorDespesasCartaoSubtrai),
        valorDespesasCartorioSoma: Number(valorDespesasCartorioSoma),
        valorDespesasEnvioSoma: Number(valorDespesasEnvioSoma),
        valorComissoesSoma: Number(valorComissoesSoma),
        valorDespesasEnvioSubtrai: Number(valorDespesasEnvioSubtrai),
        valorComissoesSubtrai: Number(valorComissoesSubtrai),
      });

      return valorTotalLiquidacao.Arredondar();
    },
    []
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});
        let retornoValido = true;
        const errors: any = {};

        const avista = formRefLista.current?.getFieldValue('avista');
        const dataVencimento = FormatarDataParaPtBrSemTimeZone(
          formRefLista.current?.getFieldValue('dataVencimento')
        );

        const listaMensagemErro: String[] = [];

        const schema = Yup.object().shape({
          idPortador: Yup.mixed()
            .nullable()
            .test({
              message: 'Portador é Obrigatório',
              test: (value: any) => {
                if (!value && !data.idContaAdiantamento) {
                  return false;
                }
                return true;
              },
            }),
          idFormaPagamento: Yup.mixed()
            .nullable()
            .test({
              message: 'Forma de Pagamento é Obrigatório',
              test: (value: any) => {
                if (!value && !data.idContaAdiantamento) {
                  return false;
                }
                return true;
              },
            }),
          valor: Yup.number().required('Valor de Liquidação é Obrigatório!'),
          // .test({
          //   message: 'Valor de Liquidação deve ser maior ou igual a Zero!',
          //   test: (value: any) => {
          //     if (Number(value) < 0) return false;

          //     return true;
          //   },
          // }),
          data: Yup.date()
            .required('Data da Liquidação é Obrigatória!')
            .nullable(),
          dataContabil: Yup.date()
            .required('Data Contábil é Obrigatória!')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        if (idContaAdiantamento) {
          if (data.contaAdiantamento.valorDisponivelAdiantamento <= 0) {
            listaMensagemErro.push('Não há valor disponível no adiantamento!');
            errors.idContaAdiantamento =
              'Não há valor disponível no adiantamento!';
          }
        }

        if (avista) {
          if (FormatarDataParaPtBrSemTimeZone(data.data) !== dataVencimento) {
            listaMensagemErro.push(
              'Data de Liquidacao não pode ser Diferente da Data de Vencimento, por ser A vista!'
            );
            errors.data =
              'Data de Liquidacao não pode ser Diferente da Data de Vencimento, por ser A vista!';
          }

          if (
            FormatarDataParaPtBrSemTimeZone(data.dataContabil) !==
            dataVencimento
          ) {
            listaMensagemErro.push(
              'Data Contábil não pode ser Diferente da Data de Vencimento, por ser A vista!'
            );
            errors.dataContabil =
              'Data Contábil não pode ser Diferente da Data de Vencimento, por ser A vista!';
          }
        }

        const valorTotalLiquidacao =
          await handleObterValorTotalLiquidacao(data);

        if (data.valor !== valorTotalLiquidacao) {
          listaMensagemErro.push(
            'Soma dos Campos da Liquidação não pode ser Diferente do Valor da Liquidação!'
          );
          errors.valor =
            'Soma dos Campos da Liquidação não pode ser Diferente do Valor da Liquidação!';
        }

        if (data.valorDisponivelAdiantamento < 0) {
          listaMensagemErro.push('erro');
        }

        if (listaMensagemErro.length > 0) {
          TratarErros({ listaMensagem: listaMensagemErro });
          formRef?.setErrors(errors);
          retornoValido = false;
        }

        return retornoValido;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);

        return false;
      }
    },
    [formRefLista, handleObterValorTotalLiquidacao, idContaAdiantamento]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        const { indexParcela, portador, formaPagamento, contaAdiantamento } =
          data;
        data.idPortador = portador ? portador.id : null;
        data.idFormaPagamento = formaPagamento ? formaPagamento.id : null;
        data.idContaAdiantamento = contaAdiantamento
          ? contaAdiantamento.id
          : null;

        setLoading(true);

        const id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (!handleValidarTotais(data, formRef)) {
          setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formRefLista.current?.getFieldValue(
          'listaContaParcelaLiquidacao'
        );

        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        if (!id) {
          formRefLista.current?.setFieldValue('listaContaParcelaLiquidacao', [
            ...listaDados,
            data,
          ]);

          listaContaParcela[indexParcela].listaContaParcelaLiquidacao = [
            ...listaDados,
            data,
          ];
        } else {
          listaDados[id] = data;
          listaContaParcela[indexParcela].listaContaParcelaLiquidacao[id] =
            data;
        }

        formPrincipal.formRef.current?.setFieldValue(
          'listaContaParcela',
          listaContaParcela
        );

        formPrincipal.formRef.current?.setFieldValue('listaParcelaMudou', true);
        await formRef?.reset();

        await atualizaAntecipacaoPrevisao();

        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      atualizaAntecipacaoPrevisao,
      formPrincipal.formRef,
      formRefLista,
      getIdDetalheRegistro,
      handleValidar,
      handleValidarTotais,
      redirecionar,
    ]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const indexLiquidacao = index.split(' ')[0];
      const indexParcela = index.split(' ')[1];

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja Remover a Liquidação?</span>
        ),
      });
      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const listaContaParcela =
          formPrincipal.formRef.current?.getFieldValue('listaContaParcela');

        const listaDados = formRefLista.current?.getFieldValue(
          'listaContaParcelaLiquidacao'
        );
        listaDados.splice(Number(indexLiquidacao), 1);

        listaContaParcela[Number(indexParcela)].listaContaParcelaLiquidacao =
          listaDados;

        formRefLista.current?.setFieldValue(
          'listaContaParcelaLiquidacao',
          listaDados
        );

        formPrincipal.formRef.current?.setFieldValue('listaParcelaMudou', true);
        await atualizaAntecipacaoPrevisao();

        refresh();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
