import React from 'react';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar:
          '/manufatura/configurador/config-caracteristicas-produto-item/item',
        novo: '/manufatura/configurador/config-caracteristicas-produto',
        alterar:
          '/manufatura/configurador/config-caracteristicas-produto-item/item',
        excluir:
          '/manufatura/configurador/config-caracteristicas-produto-item/item',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
