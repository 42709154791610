import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/conhecimento-transporte/documento-referenciado';

interface IConhecimentoTransporteDocumentoReferenciadoComunicadorShow {
  id: string;
}

interface IConhecimentoTransporteDocumentoReferenciadoComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteDocumentoReferenciadoComunicadorUpdate {
  params: any;
  id: string;
}

interface IConhecimentoTransporteDocumentoReferenciadoComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteDocumentoReferenciadoComunicador {
  public async show(
    params: IConhecimentoTransporteDocumentoReferenciadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(
    params: IConhecimentoTransporteDocumentoReferenciadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteDocumentoReferenciadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteDocumentoReferenciadoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteDocumentoReferenciadoComunicador();
