import api from '../../../Comum/Services/Api';

const rota = 'pessoa/buscar-nomeRazaoSocial';

interface IPessoaNomeRazaoSocialComunicadorShow {
  params: {
    cnpjCpf: any;
    inscricaoEstadual: any;
    nomeRazaoSocial: any;
    nomeFantasia: any;
    cep: any;
    cidade: any;
    logradouro: any;
    bairro: any;
    complemento: any;
    numero: any;
    listaEmail: any;
    listaTelefone: any;

    limite: number;
    paginaAtual: number;
  };
}

class PessoaNomeRazaoSocialComunicador {
  public async show(
    params: IPessoaNomeRazaoSocialComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaNomeRazaoSocialComunicador();
