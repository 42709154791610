import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormatarEnum,
  TipoCalculoCustoBlocoHEnum,
  TipoCustoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../Componentes/Select';

const CustoTab: React.FC = () => {
  const { formRef } = UseForm();

  const isProduto =
    formRef.current?.getFieldValue('tipoProdutoServico') === 'Produto';

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={4} sm={12} hidden={!isProduto}>
            <InputDecimalNulavel
              label="Valor Unitário do Custo de Reposição"
              name="produtoCusto.valorUnitarioCustoReposicao"
              placeholder="Valor Unitário"
            />
          </Col>
          <Col lg={4} md={4} sm={12} hidden={!isProduto}>
            <Select
              label="Tipo de custo"
              name="produtoCusto.tipoCusto"
              options={FormatarEnum({
                enumObj: TipoCustoEnum,
              })}
            />
          </Col>
          <Col lg={4} md={4} sm={12} hidden={!isProduto}>
            <Select
              label="Tipo cálculo de custo para bloco H"
              name="produtoCusto.tipoCalculoCustoBlocoH"
              options={FormatarEnum({
                enumObj: TipoCalculoCustoBlocoHEnum,
                nullavel: true,
              })}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default CustoTab;
