import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io/index.mjs';
import { Ul, Button } from './styles';
import IMenuPaiDados from './Interface/IMenuPaiDados';

const MenuPai: React.FC<IMenuPaiDados> = ({ parametro, totalMargin = 0 }) => {
  const [mostra, setMostra] = useState(false);

  return (
    <div>
      <Button
        type="button"
        onClick={() => {
          setMostra((state) => !state);
        }}
        $mostra={mostra}
      >
        <span
          style={{
            marginLeft: totalMargin,
            textAlign: 'initial',
            fontSize: '14px',
          }}
        >
          {parametro.descricao}
        </span>

        <IoIosArrowForward />
      </Button>

      {mostra && (
        <Ul>
          {parametro.listaFilho.map((filho) => {
            return (
              <li key={filho.id}>
                {filho.RenderMenu({ totalMargin: totalMargin + 10 })}
              </li>
            );
          })}
        </Ul>
      )}
    </div>
  );
};

export default MenuPai;
