import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import ItemImpostoIcmsTab from './ItemImpostoIcmsTab/index';
import ItemImpostoIpiTab from './ItemImpostoIpiTab/index';
import ItemImpostoPisTab from './ItemImpostoPisTab/index';
import ItemImpostoCofinsTab from './ItemImpostoCofinsTab/index';
import ItemImpostoIcmsStTab from './ItemImpostoIcmsStTab/index';
import ItemImpostoIcmsOperacaoInterestadualTab from './ItemImpostoIcmsOperacaoInterestadualTab/index';
import ItemProdutoTributadoTab from './ItemProdutoTributadoTab';
import { UseItemIcmsOperacaoInterestadualTab } from '../../Hook/ItemIcmsOperacaoInterestadualTabHook';
import { UseItemIcmsTab } from '../../Hook/ItemIcmsTabHook';
import ItemImpostoConfiguracaoTributacaoTab from './ItemImpostoConfiguracaoTributacaoTab';
import InputHiddenHtml from '../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import ItemImpostoAproximadoTab from './ItemImpostoAproximadoTab';
import ItemInformacaoSpedTab from './ItemInformacaoSpedTab';
import ItemImpostoRetidoTab from './ItemImpostoRetido';
import ItemFunruralTab from './ItemFunruralTab';

const ItemImpostoTab: React.FC = () => {
  const [tabSelecionada, setTabSelecionada] = useState(
    'configuracaoTributacao'
  );

  const { camposEscondidosIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();

  const { camposEscondidosIcms } = UseItemIcmsTab();

  useEffect(() => {
    if (
      camposEscondidosIcmsOperacaoInterestadual.abaIcmsOperacaoInterestadual
    ) {
      setTabSelecionada((state) => {
        if (state === 'impostoIcmsOperacaoInterestadual')
          return 'configuracaoTributacao';
        return state;
      });
    }

    if (camposEscondidosIcms.abaIcmsSt) {
      setTabSelecionada((state) => {
        if (state === 'impostoIcmsSt') return 'configuracaoTributacao';
        return state;
      });
    }
  }, [
    camposEscondidosIcms.abaIcmsSt,
    camposEscondidosIcmsOperacaoInterestadual.abaIcmsOperacaoInterestadual,
  ]);

  return (
    <div>
      <InputHiddenHtml name="baseLegalIcms" />
      <InputHiddenHtml name="baseLegalIpi" />
      <InputHiddenHtml name="outraBaseLegal" />
      <InputHiddenHtml name="informacoesContribuinte" />

      <Tabs
        id={v4()}
        defaultActiveKey="configuracaoTributacao"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="configuracaoTributacao" title="Config. Tributação">
          <ItemImpostoConfiguracaoTributacaoTab />
        </Tab>

        <Tab eventKey="impostoIcms" title="ICMS">
          <ItemImpostoIcmsTab />
        </Tab>

        <Tab
          eventKey="impostoIcmsSt"
          title="ICMS ST."
          tabClassName={camposEscondidosIcms.abaIcmsSt ? 'hidden' : ''}
        >
          <ItemImpostoIcmsStTab />
        </Tab>

        <Tab
          eventKey="impostoIcmsOperacaoInterestadual"
          title="ICMS Op. Interestadual"
          tabClassName={
            camposEscondidosIcmsOperacaoInterestadual.abaIcmsOperacaoInterestadual
              ? 'hidden'
              : ''
          }
        >
          <ItemImpostoIcmsOperacaoInterestadualTab />
        </Tab>

        <Tab eventKey="impostoIpi" title="IPI">
          <ItemImpostoIpiTab />
        </Tab>

        <Tab eventKey="impostoPis" title="PIS">
          <ItemImpostoPisTab />
        </Tab>

        <Tab eventKey="impostoCofins" title="COFINS">
          <ItemImpostoCofinsTab />
        </Tab>

        <Tab eventKey="impostoRetido" title="Retenções">
          <ItemImpostoRetidoTab />
        </Tab>

        <Tab eventKey="produtoTributado" title="Produto Tributado">
          <ItemProdutoTributadoTab />
        </Tab>

        <Tab eventKey="impostoAproximado" title="Imposto Aprox.">
          <ItemImpostoAproximadoTab />
        </Tab>

        <Tab eventKey="informacaoSped" title="Inf. SPED">
          <ItemInformacaoSpedTab />
        </Tab>

        <Tab eventKey="funrural" title="Funrural">
          <ItemFunruralTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ItemImpostoTab;
