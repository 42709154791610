"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IpiCalculo;
const Enum_1 = require("../../Enum");
function IpiCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { baseCalculoSpedCompleto: null });
    const baseCalculoIpiNormal = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
        Number(dados.valorFreteItem) +
        Number(dados.valorSeguroItem) +
        Number(dados.valorOutrasDespesasItem) -
        Number(dados.valorDescontoItem);
    retorno.baseCalculoSpedCompleto = baseCalculoIpiNormal.Arredondar();
    if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.valor) {
        retorno.baseCalculo = null;
        retorno.aliquota = null;
        retorno.quantidadeUnidadeIpi = Number(dados.quantidadeUnidadeIpi);
        retorno.valorUnidadeIpi = Number(dados.valorUnidadeIpi);
        if (dados.calcular ||
            (retorno.quantidadeUnidadeIpi > 0 && retorno.valorUnidadeIpi > 0)) {
            const valorTotal = retorno.quantidadeUnidadeIpi * retorno.valorUnidadeIpi;
            retorno.valor = valorTotal.Arredondar();
            if (dados.calcular) {
                if (retorno.valorUnidadeIpi === 0 && retorno.valor === 0)
                    retorno.quantidadeUnidadeIpi = 0;
                if (retorno.quantidadeUnidadeIpi === 0 && retorno.valor === 0)
                    retorno.valorUnidadeIpi = 0;
            }
        }
        return retorno;
    }
    if (dados.tipoCalculo === Enum_1.TipoCalculoEnum.percentual) {
        retorno.quantidadeUnidadeIpi = null;
        retorno.valorUnidadeIpi = null;
        retorno.baseCalculo = Number(dados.baseCalculo);
        retorno.aliquota = Number(dados.aliquota);
        retorno.valor = Number(dados.valor);
        if (dados.calcular) {
            retorno.baseCalculo = baseCalculoIpiNormal.Arredondar();
            const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
            retorno.valor = valorPercentual.Arredondar();
            if (retorno.aliquota === 0 && retorno.valor === 0)
                retorno.baseCalculo = 0;
            if (retorno.baseCalculo === 0 && retorno.valor === 0)
                retorno.aliquota = 0;
        }
        else if (retorno.baseCalculo > 0 && retorno.aliquota > 0) {
            const valorPercentual = retorno.baseCalculo.CalcularPercentual(retorno.aliquota);
            retorno.valor = valorPercentual.Arredondar();
        }
        if (retorno.baseCalculoSpedCompleto === 0 && retorno.baseCalculo > 0)
            retorno.baseCalculoSpedCompleto = retorno.baseCalculo;
        return retorno;
    }
    return retorno;
}
