const NotaFiscalSaidaTotalPorDataEmissaoPlanilha = [
  { titulo: 'Data de Emissao', valor: 'chave' },

  { titulo: 'Numero', valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaNumero' },

  {
    titulo: 'Modelo de Documento',
    valor: 'listaNotaFiscalSaidaItem.modeloDocumento',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaNotaFiscalSaidaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaNotaFiscalSaidaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaNotaFiscalSaidaItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalSaidaItem.somatorioValorTotal',
  },
];

export default NotaFiscalSaidaTotalPorDataEmissaoPlanilha;
