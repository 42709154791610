import {
  FormatarDataHoraParaPtBr,
  IConhecimentoTransportePorTomadorRelatorio,
  IConhecimentoTransportePorTomadorCabecalhoRelatorio,
  FormatarDataParaPtBr,
  IConhecimentoTransporteFiltroRelatorioFrontend,
  IConhecimentoTransporteFiltroRelatorioBackend,
} from '@elogestor/util';
import React from 'react';
import ConhecimentoTransportePorTomadorRelatorioComunicador from '../../../../../../Comunicador/Transporte/Relatorios/ConhecimentoTransporte/ConhecimentoTransportePorTomadorRelatorio/ConhecimentoTransportePorTomadorRelatorioComunicador';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ConhecimentoTransportePorTomadorPDF from './ConhecimentoTransportePorTomadorPDF';
import ConhecimentoTransportePorTomadorPlanilha from './ConhecimentoTransportePorTomadorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const ConhecimentoTransportePorTomadorRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IConhecimentoTransportePorTomadorRelatorio | null> => {
    const filtroBack: IConhecimentoTransporteFiltroRelatorioBackend = {
      dataHoraEmissaoInicial: filtros.dataHoraEmissaoInicial,
      dataHoraEmissaoFinal: filtros.dataHoraEmissaoFinal,
    };

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao =
        filtros.listaSituacao.listaValor.map((value) => value.descricao) || [];
    }

    if (filtros?.listaTomador) {
      filtroBack.listaIdTomador =
        filtros.listaTomador.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRemetente) {
      filtroBack.listaIdRemetente =
        filtros.listaRemetente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaDestinatario) {
      filtroBack.listaIdDestinatario =
        filtros.listaDestinatario.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRecebedor) {
      filtroBack.listaIdRecebedor =
        filtros.listaRecebedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaExpedidor) {
      filtroBack.listaIdExpedidor =
        filtros.listaExpedidor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaNumerosNotas) {
      filtroBack.listaTextoNumerosNotas =
        filtros.listaNumerosNotas.listaValor.map((value) => value.textoTag) ||
        [];
    }

    if (filtros?.listaChavesAcesso) {
      filtroBack.listaTextoChavesAcesso =
        filtros.listaChavesAcesso.listaValor.map((value) => value.textoTag) ||
        [];
    }

    const response =
      await ConhecimentoTransportePorTomadorRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno = response as IConhecimentoTransportePorTomadorRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValor: Number(
            dados.valores.somatorioValor
          ).FormatarParaPtBr(),
        };

        const listaConhecimentoTransporte =
          dados.listaConhecimentoTransporte.map((transporte) => {
            return {
              ...transporte,
              dataEmissao: FormatarDataParaPtBr(transporte.dataEmissao),
              valorTotal: Number(transporte.valorTotal).FormatarParaPtBr(),
            };
          });

        return {
          ...dados,
          listaConhecimentoTransporte,
          valores,
        };
      })
    );

    return {
      ...retorno,
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend,
    cabecalho: IConhecimentoTransportePorTomadorCabecalhoRelatorio
  ): IConhecimentoTransportePorTomadorCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraEmissaoInicial || filtros.dataHoraEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataHoraEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraEmissaoInicial
        );
      }

      if (filtros.dataHoraEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraEmissaoFinal
        );
      }
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaTomador && filtros.listaTomador.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTomadores = filtros.listaTomador.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRemetente &&
      filtros.listaRemetente.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRemetentes = filtros.listaRemetente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaDestinatario &&
      filtros.listaDestinatario.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroDestinatarios = filtros.listaDestinatario.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRecebedor &&
      filtros.listaRecebedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRecebedores = filtros.listaRecebedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaExpedidor &&
      filtros.listaExpedidor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroExpedidores = filtros.listaExpedidor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaNumerosNotas &&
      filtros.listaNumerosNotas.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroNumerosNotas = filtros.listaNumerosNotas.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    if (
      filtros.listaChavesAcesso &&
      filtros.listaChavesAcesso.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroChavesAcesso = filtros.listaChavesAcesso.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: ConhecimentoTransportePorTomadorPlanilha,
      nomeArquivo: 'ConhecimentoTransportePorTomador',
      valores: dados.listaDados,
      unwind: ['listaConhecimentoTransporte'],
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await Planilha({
      campos: ConhecimentoTransportePorTomadorPlanilha,
      nomeArquivo: 'ConhecimentoTransportePorTomador',
      valores: dados.listaDados as any,
      unwind: ['listaConhecimentoTransporte'],
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ConhecimentoTransportePorTomadorPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IConhecimentoTransporteFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <ConhecimentoTransportePorTomadorPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default ConhecimentoTransportePorTomadorRelatorio();
