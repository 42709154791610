import api from '../../../../Comum/Services/Api';

const rota = '/fiscal/situacao-tributaria/obter-por-cst';

interface ISituacaoTributariaObterPorCstComunicadorShow {
  params: {
    cst: string;
  };
}

class SituacaoTributariaObterPorCstComunicador {
  public async show({
    params,
  }: ISituacaoTributariaObterPorCstComunicadorShow): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new SituacaoTributariaObterPorCstComunicador();
