import {
  IPedidoVendaLista,
  FormatarDataHoraCompletaParaPtBr,
  SituacaoPedidoVendaNotaFiscalSaidaEnum,
  TipoPedidoVendaEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import PedidoVendaComunicador from '../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import PedidoVendaLogComunicador from '../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Log/Comunicador/PedidoVendaLogComunicador';
import ContaRevertidaComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import { UseLiberacoes } from '../../../../../Hooks/LiberacoesHook';

const PedidoVendaLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const liberacoes = UseLiberacoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { abrirJanela } = UseConfirmacao();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await PedidoVendaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: IPedidoVendaLista) => {
          let pendente = false;
          let aprovado = false;
          let cancelado = false;

          if (data.situacao.includes('Pendente')) pendente = true;
          if (data.situacao.includes('Aprovado')) aprovado = true;
          if (data.situacao.includes('Cancelado')) cancelado = true;

          return {
            id: data.id,
            numero: <div className="lista-valor">{data.numero}</div>,
            dataHoraEmissao: (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(data.dataHoraEmissao)}
              </div>
            ),
            dataPrevistaFaturamento: (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(data.dataPrevistaFaturamento)}
              </div>
            ),
            'pedidoVendaPessoa.pessoa.codigoNomeRazaoSocial': (
              <div className="lista-texto">{`${data.pedidoVendaPessoa.pessoa.codigo} - ${data.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}</div>
            ),
            valorTotalProdutos: (
              <div className="lista-valor">
                {data.valorTotalProdutos
                  ? Number(data.valorTotalProdutos).FormatarParaPtBr()
                  : ''}
              </div>
            ),
            valorTotalBruto: (
              <div className="lista-valor">
                {data.valorTotalBruto
                  ? Number(data.valorTotalBruto).FormatarParaPtBr({
                      minimoCasasDecimais: 2,
                      maximoCasasDecimais: 2,
                    })
                  : ''}
              </div>
            ),
            situacao: (
              <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                {pendente && (
                  <span style={{ color: 'darkgoldenrod' }}>Pendente</span>
                )}
                {aprovado && (
                  <span>
                    {pendente && <span>{' e '}</span>}
                    <span style={{ color: 'green' }}>Aprovado</span>
                  </span>
                )}
                {cancelado && <span style={{ color: 'red' }}>Cancelado</span>}
              </div>
            ),
            situacaoPedidoVendaNotaFiscalSaida: (
              <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                {data.situacaoPedidoVendaNotaFiscalSaida ===
                  'Parcialmente Atendido' && (
                  <span style={{ color: 'goldenrod' }}>
                    {
                      SituacaoPedidoVendaNotaFiscalSaidaEnum.parcialmenteAtendido
                    }
                  </span>
                )}
                {data.situacaoPedidoVendaNotaFiscalSaida === 'Atendido' && (
                  <span>
                    <span style={{ color: 'green' }}>
                      {SituacaoPedidoVendaNotaFiscalSaidaEnum.atendido}
                    </span>
                  </span>
                )}
                {data.situacaoPedidoVendaNotaFiscalSaida === 'Não Atendido' && (
                  <span style={{ color: 'red' }}>
                    {SituacaoPedidoVendaNotaFiscalSaidaEnum.naoAtendido}
                  </span>
                )}
                {data.situacaoPedidoVendaNotaFiscalSaida ===
                  'Ped. Cancelado' && (
                  <span style={{ color: 'red' }}>
                    {SituacaoPedidoVendaNotaFiscalSaidaEnum.pedidoCancelado}
                  </span>
                )}
              </div>
            ),

            numeroPedidoIntegracao: (
              <div className="lista-valor">
                {data.numeroPedidoIntegracao ?? ''}
              </div>
            ),
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      if (
                        !liberacoes.permiteExcluirConsignado &&
                        data.tipoPedidoVenda === TipoPedidoVendaEnum.consignado
                      ) {
                        ToastErro(
                          'Usuário sem permissão para excluir Consignado!'
                        );
                        setLoading(false);
                        return false;
                      }

                      const retorno = await ContaRevertidaComunicador.index({
                        params: {
                          idPedidoVenda: data.id,
                        },
                      });

                      if (retorno.possuiContaParcelaRevertida) {
                        const resposta = await abrirJanela({
                          titulo: <h2>Confirmação</h2>,
                          mensagem: (
                            <span style={{ fontSize: 20 }}>
                              Essa conta possui uma reversão. Deseja excluir a
                              conta revertida?
                            </span>
                          ),
                          confimar: 'Sim',
                          cancelar: 'Não',
                        });

                        if (!resposta) {
                          setLoading(false);
                          return false;
                        }
                      }

                      await PedidoVendaComunicador.delete({ id: data.id });

                      setLoading(false);
                      return true;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = PedidoVendaLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={permissoes.ComercialVendasPedidoVenda?.exclui}
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      abrirJanela,
      liberacoes.permiteExcluirConsignado,
      permissoes.ComercialVendasPedidoVenda?.exclui,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'comercialPedidoVenda' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Pedido de Venda"
          permissao={permissoes.ComercialVendasPedidoVenda}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return PedidoVendaLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default PedidoVendaLista;
