import React from 'react';
import { SubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/SubHeaderContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const SubHeaderHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <SubHeaderProvider
      value={{
        subHeaderButtonsVisivel: {
          ExcluirVisivel: true,
          DuplicarVisivel: false,
          DuplicarSalvarVisivel: false,
          SalvarContinuarVisivel: false,
          SalvareFecharVisivel: false,
          SalvareNovoVisivel: false,
          VoltarVisivel: false,
          SalvarVisivel: true,
        },
        subHeaderButtonsHabilitado: {
          ExcluirHabilitado: true,
          DuplicarHabilitado: false,
          DuplicarSalvarHabilitado: false,
          SalvarContinuarHabilitado: false,
          SalvareFecharHabilitado: false,
          SalvareNovoHabilitado: false,
          SalvareHabilitado: true,
        },
      }}
    >
      {children}
    </SubHeaderProvider>
  );
};

export default SubHeaderHook;
