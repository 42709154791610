import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IItemMenuRef } from '../Componentes/Padrao/MenuPrincipal/Interfaces/IItemMenuRef';
import IPadraoProps from '../Comum/Interface/IPadraoProps';
import { UseReactSizeMeGlobalHook } from './ReactSizeMeGlobalHook';

interface IMenuPrincipalHookContext {
  validador: boolean;
  menuMinimizado: boolean;
  handleToggleMenu: () => void;
  handleMinimizarMenuSeTelaForPequena: () => void;
  setItemMenuSelecionado: (valor: IItemMenuRef) => void;
  getItemMenuSelecionado: () => IItemMenuRef | null;
  mouseDentro: boolean;
  handleMouseEntrou(): void;
  handleMouseSaiu(): void;
}

const MenuPrincipalHookContext = createContext<IMenuPrincipalHookContext>(
  {} as IMenuPrincipalHookContext
);

const MenuPrincipalProvider: React.FC<IPadraoProps> = ({ children }) => {
  const { telaPequena } = UseReactSizeMeGlobalHook();

  const [menuMinimizado, setMenuMinimizado] = useState(false);
  const [mouseDentro, setMouseDentro] = useState(false);
  const itemMenuSelecionado = useRef<IItemMenuRef | null>(null);

  const getItemMenuSelecionado = useCallback(() => {
    return itemMenuSelecionado.current;
  }, []);

  const setItemMenuSelecionado = useCallback((valor: IItemMenuRef): void => {
    itemMenuSelecionado.current = valor;
  }, []);

  const handleToggleMenu = useCallback((): void => {
    setMenuMinimizado((state) => !state);
  }, []);

  const handleMinimizarMenuSeTelaForPequena = useCallback((): void => {
    if (telaPequena) {
      setMenuMinimizado(false);
    }
  }, [telaPequena]);

  const handleMouseEntrou = useCallback(() => {
    setMouseDentro(true);
  }, []);

  const handleMouseSaiu = useCallback(() => {
    setMouseDentro(false);
  }, []);

  return (
    <MenuPrincipalHookContext.Provider
      value={{
        menuMinimizado,
        handleToggleMenu,
        handleMinimizarMenuSeTelaForPequena,
        mouseDentro,
        handleMouseEntrou,
        handleMouseSaiu,
        getItemMenuSelecionado,
        setItemMenuSelecionado,
        validador: true,
      }}
    >
      {children}
    </MenuPrincipalHookContext.Provider>
  );
};

function UseMenuPrincipal(): Omit<IMenuPrincipalHookContext, 'validador'> {
  const context = useContext(MenuPrincipalHookContext);

  if (!context.validador) {
    throw new Error(
      'UseMenuPrincipal deve ser usado com um MenuPrincipalProvider'
    );
  }

  return context;
}

export { MenuPrincipalProvider, UseMenuPrincipal };
