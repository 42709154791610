import React, { useCallback, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { v4 } from 'uuid';
import { FaRegCopy } from 'react-icons/fa/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import { CgCopy } from 'react-icons/cg/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { useNavigate } from 'react-router-dom';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SubHeaderGeral from '../../../../../../../Componentes/SubHeaderGeral';
import BtnDeletar from '../../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import ContainerButtons from '../../../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import SplitSalvar from '../../../../../../../Componentes/SubHeaderGeral/SplitSalvar';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseReactSizeMeBodyHook } from '../../../../../../../Hooks/ReactSizeMeBodyHook';
import Dropdown from '../../../../../../../Componentes/SubHeaderGeral/Dropdown';
import BtnVoltar from '../../../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import UseFuncoesPadrao from '../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { SplitDuplicar } from './styles';
import CopiarDe from './CopiarDe';
import ReplicarPara from './ReplicarPara';
import UseModalAberta from '../../../../../../../Hooks/UseModalAberta';
import { UseRota } from '../../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseTreeContext } from '../../Hooks/TreeContext';

const SubheaderUsuario: React.FC = () => {
  const {
    formRef,
    handleSubmit,
    handleCarregarDados,
    setIdDetalheRegistro,
    loading,
    getIdDetalheRegistro,
  } = UseForm();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { permissoes } = UsePermissoes();
  const { ManufaturaEngenhariaEstruturaProduto: permissao } = permissoes;
  const rotas = UseRota();
  const id = getIdDetalheRegistro();
  const navigate = useNavigate();
  const { listaDados, buscarDados } = UseTreeContext();

  const { handleExcluir } = UseFuncoesPadrao(permissao);
  const [idDuplicar] = useState(v4());
  const [idSalvar] = useState(v4());

  const [copiarDeAberto, dispatchCopiarDe] = UseModalAberta();

  const handleSalvar = useCallback(async (): Promise<void> => {
    if (loading) return;

    const data = formRef.current?.getDataDuplicar();

    data.idProdutoFilho = data.produtoFilho ? data.produtoFilho.id : null;
    data.idProdutoPai = data.produtoPai ? data.produtoPai.id : null;

    const { erro } = await handleSubmit(data);
    if (erro) return;

    navigate(rotas.listagem);
  }, [formRef, handleSubmit, loading, navigate, rotas.listagem]);

  const handleSalvarContinuar = useCallback(async (): Promise<{
    erro: boolean;
  }> => {
    if (loading) return { erro: false };
    const data = formRef.current?.getDataDuplicar();

    data.idProdutoFilho = data.produtoFilho ? data.produtoFilho.id : null;
    data.idProdutoPai = data.produtoPai ? data.produtoPai.id : null;

    const idProdutoPaiRoot = listaDados.length
      ? listaDados[0].idProdutoPai
      : data.idProdutoPai;

    const { erro } = await handleSubmit(data);

    if (erro) {
      return { erro: true };
    }

    navigate(`${rotas.detalhes}/${idProdutoPaiRoot}`);
    await buscarDados(idProdutoPaiRoot);

    return { erro: false };
  }, [
    loading,
    formRef,
    handleSubmit,
    listaDados,
    navigate,
    rotas.detalhes,
    buscarDados,
  ]);

  const handleSalvarNovo = useCallback(async (): Promise<void> => {
    if (loading) return;
    const data = formRef.current?.getData();
    const { erro } = await handleSubmit(data);

    if (erro) return;

    navigate(rotas.detalhes);
    setIdDetalheRegistro('');
    handleCarregarDados();
  }, [
    loading,
    formRef,
    handleSubmit,
    navigate,
    rotas.detalhes,
    setIdDetalheRegistro,
    handleCarregarDados,
  ]);

  const handleAbrirCopiarDe = useCallback(() => {
    dispatchCopiarDe({ tipo: 'abrir' });
  }, [dispatchCopiarDe]);

  const handleFecharCopiarDe = useCallback(() => {
    dispatchCopiarDe({ tipo: 'fechar' });
  }, [dispatchCopiarDe]);

  const [replicarParaAberto, dispatchReplicarPara] = UseModalAberta();

  const handleAbrirReplicarPara = useCallback(() => {
    dispatchReplicarPara({ tipo: 'abrir' });
  }, [dispatchReplicarPara]);

  const handleFecharReplicarPara = useCallback(() => {
    dispatchReplicarPara({ tipo: 'fechar' });
  }, [dispatchReplicarPara]);

  return (
    <SubHeaderGeral>
      <h1>{id ? 'Editar Estrutura de Produto' : 'Estrutura de Produto'}</h1>

      <ContainerButtons>
        <BtnVoltar
          disabled={loading}
          onClick={() => {
            navigate('/manufatura/engenharia/estrutura-produto');
          }}
        >
          <TextoLoading loading={loading}>
            <IoMdReturnLeft />
            {telaGrande && <span style={{ marginLeft: 10 }}>Voltar</span>}
          </TextoLoading>
        </BtnVoltar>

        {permissao?.exclui && id && (
          <BtnDeletar
            onClick={() =>
              handleExcluir({
                isAberturaFechamentoCaixa: false,
                isTerminalCaixa: false,
                isOrcamento: false,
                isPedidoVenda: false,
                isNotaFiscalSaida: false,
                isNotaFiscalEntrada: false,
                isConhecimentoTransporte: false,
                isConhecimentoTransporteTerceiro: false,
                isOrdemServico: false,
              })
            }
            disabled={loading}
          >
            <TextoLoading loading={loading}>
              <RiDeleteBin6Line />
              {telaGrande && <span style={{ marginLeft: 10 }}>Excluir</span>}
            </TextoLoading>
          </BtnDeletar>
        )}

        <SplitDuplicar
          id={idDuplicar}
          title={
            <TextoLoading loading={loading}>
              <FaRegCopy />
              {telaGrande && <span style={{ marginLeft: 10 }}>Duplicar</span>}
            </TextoLoading>
          }
          onClick={handleAbrirCopiarDe}
          type="button"
        >
          <Dropdown onClick={handleAbrirCopiarDe} disabled={loading}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CgCopy />
              <span style={{ marginLeft: 10 }}>Copiar de:</span>
            </div>
          </Dropdown>

          <Dropdown
            disabled={loading || !permissao?.altera || !id}
            onClick={handleAbrirReplicarPara}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CgCopy />
              <span style={{ marginLeft: 10 }}>
                Replicar Para Outros Produtos
              </span>
            </div>
          </Dropdown>
        </SplitDuplicar>

        <SplitSalvar
          id={idSalvar}
          disabled={(() => {
            const idDetalhe = getIdDetalheRegistro();
            return (
              (idDetalhe && !permissao?.altera) ||
              (!idDetalhe && !permissao?.inclui)
            );
          })()}
          title={
            <>
              <TextoLoading loading={loading}>
                <BiSave />
                {telaGrande && <span style={{ marginLeft: 10 }}>Salvar</span>}
              </TextoLoading>
            </>
          }
          onClick={handleSalvar}
        >
          {permissao?.altera && (
            <Dropdown
              onClick={() => {
                handleSalvarContinuar();
              }}
              disabled={loading}
            >
              <div style={{ display: 'flex' }}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>Salvar e Continuar</span>
              </div>
            </Dropdown>
          )}

          {permissao?.altera && (
            <Dropdown onClick={handleSalvarNovo} disabled={loading}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <VscSaveAll />
                <span style={{ marginLeft: 10 }}>Salvar e Novo</span>
              </div>
            </Dropdown>
          )}

          {permissao?.altera && (
            <Dropdown disabled={loading} onClick={handleSalvar}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Salvar e Fechar</span>
              </div>
            </Dropdown>
          )}
        </SplitSalvar>
      </ContainerButtons>

      <CopiarDe
        aberto={copiarDeAberto}
        fecharFormModal={handleFecharCopiarDe}
      />

      <ReplicarPara
        aberto={replicarParaAberto}
        fecharFormModal={handleFecharReplicarPara}
      />
    </SubHeaderGeral>
  );
};

export default SubheaderUsuario;
