import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/logs-integracao-pedidos';

interface IPedidoVendaImportacaoLogComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

class PedidoVendaImportacaoLogComunicador {
  public async index(
    params: IPedidoVendaImportacaoLogComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PedidoVendaImportacaoLogComunicador();
