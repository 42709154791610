const PedidoVendaPorProdutoPlanilha = [
  { titulo: 'Codigo do Produto', valor: 'listaPedidoVendaItem.produtoCodigo' },

  { titulo: 'Nome do Produto', valor: 'listaPedidoVendaItem.produtoDescricao' },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaPedidoVendaItem.pedidoVendaDataHoraEmissao',
  },

  {
    titulo: 'Numero do Orçamento',
    valor: 'listaPedidoVendaItem.pedidoVendaNumero',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaPedidoVendaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaPedidoVendaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaPedidoVendaItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaPedidoVendaItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaPedidoVendaItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaPedidoVendaItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaPedidoVendaItem.valorTotal',
  },
];

export default PedidoVendaPorProdutoPlanilha;
