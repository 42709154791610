"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsCteObterVisibilidadeBloqueioCamposPorCst;
function IcmsCteObterVisibilidadeBloqueioCamposPorCst({ calcularIcms, regimeTributario, cst, }) {
    const retorno = {
        visivel: {
            calcularIcms: true,
            percentualReducaoBaseCalculoIcms: true,
            baseCalculoIcms: true,
            aliquotaIcms: true,
            valorIcms: true,
        },
        bloqueado: {
            calcularIcms: false,
            percentualReducaoBaseCalculoIcms: true,
            baseCalculoIcms: true,
            aliquotaIcms: true,
            valorIcms: true,
        },
    };
    if (!calcularIcms) {
        retorno.bloqueado.aliquotaIcms = false;
        retorno.bloqueado.percentualReducaoBaseCalculoIcms = false;
        retorno.bloqueado.baseCalculoIcms = false;
        retorno.bloqueado.valorIcms = false;
    }
    // const regimeSimplesNacional =
    //   regimeTributario ===
    //     RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte ||
    //   regimeTributario === RegimeTributarioEnum.simplesNacionalMicroEmpresa;
    if (cst === '00') {
        retorno.visivel.calcularIcms = true;
        retorno.visivel.percentualReducaoBaseCalculoIcms = false;
        retorno.visivel.baseCalculoIcms = true;
        retorno.visivel.aliquotaIcms = true;
        retorno.visivel.valorIcms = true;
    }
    if (cst === '20' || cst === '90') {
        retorno.visivel.calcularIcms = true;
        retorno.visivel.percentualReducaoBaseCalculoIcms = true;
        retorno.visivel.baseCalculoIcms = true;
        retorno.visivel.aliquotaIcms = true;
        retorno.visivel.valorIcms = true;
    }
    if (
    // regimeSimplesNacional ||
    cst === '40' ||
        cst === '41' ||
        cst === '45' ||
        cst === '51' ||
        cst === '60' ||
        cst === '90') {
        retorno.visivel.calcularIcms = false;
        retorno.visivel.percentualReducaoBaseCalculoIcms = false;
        retorno.visivel.baseCalculoIcms = false;
        retorno.visivel.aliquotaIcms = false;
        retorno.visivel.valorIcms = false;
    }
    return retorno;
}
