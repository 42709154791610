"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoAvaliacaoPesagemValorEnum;
(function (TipoAvaliacaoPesagemValorEnum) {
    TipoAvaliacaoPesagemValorEnum["sim"] = "Sim";
    TipoAvaliacaoPesagemValorEnum["nao"] = "N\u00E3o";
    TipoAvaliacaoPesagemValorEnum["otimo"] = "\u00D3timo";
    TipoAvaliacaoPesagemValorEnum["bom"] = "Bom";
    TipoAvaliacaoPesagemValorEnum["razoavel"] = "Razo\u00E1vel";
    TipoAvaliacaoPesagemValorEnum["ruim"] = "Ruim";
})(TipoAvaliacaoPesagemValorEnum || (TipoAvaliacaoPesagemValorEnum = {}));
exports.default = TipoAvaliacaoPesagemValorEnum;
