import api from '../../../../../../Comum/Services/Api';

const rota = '/comercial/pessoas/pessoa/contato';
const rotaNovo = '/comercial/pessoas/pessoa';

interface IPessoaContatoComunicadorShow {
  id: string;
}

interface IPessoaContatoComunicadorStore {
  idDetalheRegistro: string;
  params: any;
}

interface IPessoaContatoComunicadorUpdate {
  id: string;
  params: any;
}

interface IPessoaContatoComunicadorDelete {
  id: string;
}

class PessoaContatoComunicador {
  public async show(params: IPessoaContatoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPessoaContatoComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idDetalheRegistro}/contato`,
      params.params
    );

    return response.data;
  }

  public async update(params: IPessoaContatoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IPessoaContatoComunicadorDelete): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PessoaContatoComunicador();
