import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import api from '../../../../../Comum/Services/Api';

const rota = '/configuracao/personalizacao-lista/todos';

interface IPersonalizacaoListaCamposSalvarTodosComunicadorUpdate {
  params: ITh[];
}
class PersonalizacaoListaCamposSalvarTodosComunicador {
  public async update(
    params: IPersonalizacaoListaCamposSalvarTodosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);
    return response.data;
  }
}

export default new PersonalizacaoListaCamposSalvarTodosComunicador();
