import { ModeloDocumentoTipoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = '/fiscal/tributacao/serie/obter-primeira-serie';

interface ISerieObterPrimeiraSerieComunicadorShow {
  listaTipoDocumentoFiscal?: ModeloDocumentoTipoEnum[];
}

class SerieObterPrimeiraSerieComunicador {
  public async show(
    params: ISerieObterPrimeiraSerieComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new SerieObterPrimeiraSerieComunicador();
