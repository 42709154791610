import api from '../../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/ordem-servico/item';
const rotaNovo = 'servicos/ordens-servicos/ordem-servico';

interface IOrdemServicoItemComunicadorShow {
  id: string;
}

interface IOrdemServicoItemComunicadorStore {
  idPai: string;
  params: any;
}

interface IOrdemServicoItemComunicadorUpdate {
  id: string;
  params: any;
}

interface IOrdemServicoItemComunicadorDelete {
  id: string;
}

class OrdemServicoItemComunicador {
  public async show(params: IOrdemServicoItemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IOrdemServicoItemComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IOrdemServicoItemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IOrdemServicoItemComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoItemComunicador();
