import { IPessoaValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import GeralTab from '../GeralTab';
import ContatoTab from '../ContatoTab';
import TrabalhoConjugeTab from '../TrabalhoConjugeTab';
import { BtnContainer } from './styles';
import ClienteTab from '../ClienteTab';
import ProdutorTab from '../ProdutorTab';
import PadroesTab from '../PadroesTab';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import DadosBancariosTab from '../DadosBancariosTab';
import TagsTab from '../TagsTab';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import EcommerceTab from '../ECommerceTab';

type IProps = IMain<IPessoaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const isCpf = formRef.current?.getFieldValue('cnpjCpfVirtual').length === 11;
  const cliente = formRef.current?.getFieldValue('cliente');
  const produtor = formRef.current?.getFieldValue('produtor');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosRecuperados,
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const inputRef = useRef<HTMLButtonElement | null>(null);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <InputHiddenHtml name="naoRecuperarFormulario" />

      <Tabs id={v4()} defaultActiveKey="geral">
        <Tab eventKey="geral" title="Geral">
          <GeralTab />
        </Tab>

        <Tab eventKey="contatos" title="Contatos">
          <ContatoTab />
        </Tab>

        <Tab
          eventKey="trabalhoConjuge"
          title="Trabalho/Cônjuge"
          tabClassName={isCpf ? '' : 'hidden'}
        >
          <TrabalhoConjugeTab />
        </Tab>

        <Tab
          eventKey="cliente"
          title="Cliente"
          tabClassName={cliente ? '' : 'hidden'}
        >
          <ClienteTab />
        </Tab>

        <Tab
          eventKey="produtor"
          title="Produtor"
          tabClassName={produtor ? '' : 'hidden'}
        >
          <ProdutorTab />
        </Tab>

        <Tab eventKey="padroes" title="Padrões">
          <PadroesTab />
        </Tab>

        <Tab eventKey="dadosBancarios" title="Dados Bancários">
          <DadosBancariosTab />
        </Tab>

        <Tab eventKey="tags" title="Tags">
          <TagsTab />
        </Tab>

        <Tab eventKey="ecommerce" title="E-Commerce">
          <EcommerceTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          ref={inputRef}
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
