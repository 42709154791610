import React, { createContext, useCallback, useContext, useState } from 'react';
import { INotaFiscalEntradaItemEstoqueLocalEstoqueLista } from '@elogestor/util';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemNotaFiscalEntradaContext {
  validador: boolean;

  produtoControlaLote: boolean;
  setProdutoControlaLote(controleLote: boolean): void;

  listaLocalEstoque: INotaFiscalEntradaItemEstoqueLocalEstoqueLista[];
  setListaLocalEstoque(
    lista: INotaFiscalEntradaItemEstoqueLocalEstoqueLista[]
  ): void;
  adicionarLocalEstoque(
    valor: INotaFiscalEntradaItemEstoqueLocalEstoqueLista
  ): void;
  removerLocalEstoque(index: string): void;
}

const ItemNotaFiscalEntradaContext =
  createContext<IItemNotaFiscalEntradaContext>(
    {} as IItemNotaFiscalEntradaContext
  );

const ItemNotaFiscalEntradaHook: React.FC<IPadraoProps> = ({ children }) => {
  const [produtoControlaLote, setProdutoControlaLote] = useState(false);

  const [listaLocalEstoque, setListaLocalEstoque] = useState<
    INotaFiscalEntradaItemEstoqueLocalEstoqueLista[]
  >([]);

  const handleSetProdutoControlaLote = useCallback(
    async (controlaLote: boolean) => {
      setProdutoControlaLote(controlaLote);
    },
    []
  );

  const handleSetListaLocalEstoque = useCallback(
    async (lista: INotaFiscalEntradaItemEstoqueLocalEstoqueLista[]) => {
      setListaLocalEstoque(lista);
    },
    []
  );

  const handleAdicionarLocalEstoque = useCallback(
    async (valor: INotaFiscalEntradaItemEstoqueLocalEstoqueLista) => {
      setListaLocalEstoque((state) => {
        return [...state, valor];
      });
    },
    []
  );

  const handleRemoverLocalEstoque = useCallback(
    async (index: string) => {
      listaLocalEstoque.splice(Number(index), 1);

      const novaLista = listaLocalEstoque;

      setListaLocalEstoque([]);
      setListaLocalEstoque(novaLista);
    },
    [listaLocalEstoque]
  );

  return (
    <ItemNotaFiscalEntradaContext.Provider
      value={{
        produtoControlaLote,
        setProdutoControlaLote: handleSetProdutoControlaLote,

        listaLocalEstoque,
        setListaLocalEstoque: handleSetListaLocalEstoque,
        adicionarLocalEstoque: handleAdicionarLocalEstoque,
        removerLocalEstoque: handleRemoverLocalEstoque,

        validador: true,
      }}
    >
      {children}
    </ItemNotaFiscalEntradaContext.Provider>
  );
};

function UseItemNotaFiscalEntrada(): Omit<
  IItemNotaFiscalEntradaContext,
  'validador'
> {
  const context = useContext(ItemNotaFiscalEntradaContext);

  if (!context.validador) {
    throw new Error(
      'UseItemNotaFiscalEntrada deve ser usado com um ItemNotaFiscalEntradaHook'
    );
  }

  return context;
}

export { ItemNotaFiscalEntradaHook, UseItemNotaFiscalEntrada };
