"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoPesagemEnum;
(function (SituacaoPesagemEnum) {
    SituacaoPesagemEnum["naoUtilizada"] = "N\u00E3o Utilizada";
    SituacaoPesagemEnum["parcialmenteUtilizada"] = "Parcialmente Utilizada";
    SituacaoPesagemEnum["utilizada"] = "Utilizada";
    SituacaoPesagemEnum["cancelada"] = "Cancelada";
})(SituacaoPesagemEnum || (SituacaoPesagemEnum = {}));
exports.default = SituacaoPesagemEnum;
