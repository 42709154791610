/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
import {
  CampoAtualizarTabelaPrecoEnum,
  IAtualizacaoPrecoCustoProdutoLista,
  IAtualizacaoPrecoCustoProdutoListaValoresAlterar,
  IImpressaoEtiquetasProdutosTransferirLista,
  OrigemValorBaseTabelaPrecoEnum,
  TipoCustoEnum,
  ValidarCodigoBarrasGtin,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { BtnContainer, Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AtualizacaoPrecoCustoProdutoComunicador from '../../../../../Comunicador/Suprimentos/Produtos/AtualizacaoPrecoCustoProduto/Comunicador/AtualizacaoPrecoCustoProdutoComunicador';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import InputTabela from '../../../../../Componentes/Inputs/InputTabela/InputTabela';
import InputTabelaDecimal from '../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import InputTabelaSwitch from '../../../../../Componentes/Inputs/InputTabela/InputTabelaSwitch';
import InputTabelaAutoCompleteNcm from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteNcm';
import InputTabelaAutoCompleteCest from '../../../../../Componentes/Inputs/InputTabela/AutoComplete/Fiscal/InputTabelaAutoCompleteCest';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseAtualizacaoPrecoCustoProdutoLista } from '../Hooks/AtualizacaoPrecoCustoProdutoListaHook';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import InputTabelaDecimalNulavel from '../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimalNulavel';
import AcoesAtualizacaoPrecoCustoProduto from '../AcoesAtualizacaoPrecoCustoProduto';
import ToastInfo from '../../../../../Util/Toasts/ToastInfo';

interface IControlarFocusParametros {
  index: number;
  nomeCampo: string;
  listaTh: ITh[];
  listaItens: IAtualizacaoPrecoCustoProdutoLista[];
}

interface IControlarFocusRetorno {
  linhaIndexAnterior: number;
  linhaIndexProxima: number;
  listaRefAnterior: any;
  listaRefProxima: any;
}

type IProps = {
  dadosRecuperados: any;
};

const AtualizacaoPrecoCustoProdutoLista: React.FC<IProps> = ({
  dadosRecuperados,
}) => {
  const { abrirJanela } = UseConfirmacao();
  const { permissoes } = UsePermissoes();
  const { SuprimentosProdutosAtualizacaoPrecoCustoProduto: permissao } =
    permissoes;
  const { setParametros } = UseTableDraggable();

  const [jaRecuperouDados, setJaRecuperouDados] = useState(false);

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    listaDescricaoRef,
    listaValorUnitarioVendaRef,
    listaAumentandoEmPercentualRef,
    listaAplicandoMarkupRef,
    listaDiminuindoEmPercentualRef,
    listaValorUnitarioBaseRef,
    listaValorUnitarioCustoReposicaoRef,
    listaCodigoBarrasRef,
    listaAtivoRef,
    listaNcmRef,
    listaCestRef,
  } = UseAtualizacaoPrecoCustoProdutoLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [listaColunas, setListaColunas] = useState<ITh[]>([]);
  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const pesquisaAvancadaLocalStorage = localStorage.getItem(
    '@EloGestorle:pesquisaAvancada'
  );

  const pesquisaAvancadaLocalStorageJSON = pesquisaAvancadaLocalStorage
    ? JSON.parse(pesquisaAvancadaLocalStorage)
    : null;

  useEffect(() => {
    if (pesquisaAvancadaLocalStorageJSON) {
      ToastInfo('Mostrando resultados da nota fiscal selecionada.');
    }

    if (dadosRecuperados && !pesquisaAvancadaLocalStorageJSON)
      setParametros({
        pagina: dadosRecuperados.pagina,
        limite: dadosRecuperados.limite,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosRecuperados, setParametros]);

  const handleControlarFocus = useCallback(
    ({
      index,
      nomeCampo,
      listaTh,
      listaItens,
    }: IControlarFocusParametros): IControlarFocusRetorno | null => {
      const listaColunasSelecionaveis = listaTh.filter(
        (coluna) =>
          coluna.visivel &&
          (coluna.nomeCampo === 'descricao' ||
            coluna.nomeCampo ===
              'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda' ||
            coluna.nomeCampo ===
              'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual' ||
            coluna.nomeCampo ===
              'produtoRegraEscolhaTabelaPreco.aplicandoMarkup' ||
            coluna.nomeCampo ===
              'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual' ||
            coluna.nomeCampo === 'produtoCusto.valorUnitarioCustoReposicao' ||
            coluna.nomeCampo === 'produtoEngenharia.codigoBarras')
      );
      if (
        !listaColunasSelecionaveis ||
        listaColunasSelecionaveis.length === 0
      ) {
        return null;
      }

      const colunaIndexAtual = listaColunasSelecionaveis.findIndex(
        (coluna) => coluna.nomeCampo === nomeCampo
      );

      let linhaIndexAnterior = index;
      let colunaIndexAnterior = colunaIndexAtual;
      let listaRefAnterior: any;

      do {
        colunaIndexAnterior -= 1;
        if (colunaIndexAnterior < 0) {
          linhaIndexAnterior -= 1;
          if (linhaIndexAnterior < 0) {
            linhaIndexAnterior = listaItens.length - 1;
          }

          colunaIndexAnterior = listaColunasSelecionaveis.length - 1;
        }

        switch (listaColunasSelecionaveis[colunaIndexAnterior].nomeCampo) {
          case 'descricao':
            listaRefAnterior = listaDescricaoRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda':
            listaRefAnterior = listaValorUnitarioVendaRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual':
            listaRefAnterior = listaAumentandoEmPercentualRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.aplicandoMarkup':
            listaRefAnterior = listaAplicandoMarkupRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual':
            listaRefAnterior = listaDiminuindoEmPercentualRef;
            break;

          case 'produtoCusto.valorUnitarioCustoReposicao':
            listaRefAnterior = listaValorUnitarioCustoReposicaoRef;
            break;

          case 'produtoEngenharia.codigoBarras':
            listaRefAnterior = listaCodigoBarrasRef;
            break;
        }
      } while (!listaRefAnterior.current[linhaIndexAnterior]);

      let linhaIndexProxima = index;
      let colunaIndexProxima = colunaIndexAtual;
      let listaRefProxima: any;

      do {
        colunaIndexProxima += 1;
        if (colunaIndexProxima >= listaColunasSelecionaveis.length) {
          linhaIndexProxima += 1;
          if (linhaIndexProxima >= listaItens.length) {
            linhaIndexProxima = 0;
          }

          colunaIndexProxima = 0;
        }

        switch (listaColunasSelecionaveis[colunaIndexProxima].nomeCampo) {
          case 'descricao':
            listaRefProxima = listaDescricaoRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda':
            listaRefProxima = listaValorUnitarioVendaRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual':
            listaRefProxima = listaAumentandoEmPercentualRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.aplicandoMarkup':
            listaRefProxima = listaAplicandoMarkupRef;
            break;

          case 'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual':
            listaRefProxima = listaDiminuindoEmPercentualRef;
            break;

          case 'produtoCusto.valorUnitarioCustoReposicao':
            listaRefProxima = listaValorUnitarioCustoReposicaoRef;
            break;

          case 'produtoEngenharia.codigoBarras':
            listaRefProxima = listaCodigoBarrasRef;
            break;
        }
      } while (!listaRefProxima.current[linhaIndexProxima]);

      return {
        linhaIndexAnterior,
        linhaIndexProxima,
        listaRefAnterior,
        listaRefProxima,
      };
    },
    [
      listaAplicandoMarkupRef,
      listaAumentandoEmPercentualRef,
      listaCodigoBarrasRef,
      listaDescricaoRef,
      listaDiminuindoEmPercentualRef,
      listaValorUnitarioCustoReposicaoRef,
      listaValorUnitarioVendaRef,
    ]
  );

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
    if (itensSelecionados?.length === 0 && dadosRecuperados)
      setJaRecuperouDados(true);
  }, [dadosRecuperados, listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef]
  );

  const handleObterItensSelecionados = useCallback(async (): Promise<
    IAtualizacaoPrecoCustoProdutoListaValoresAlterar[]
  > => {
    const listaIndexItemSelecionado: number[] = [];
    const listaItemSelecionado: IAtualizacaoPrecoCustoProdutoListaValoresAlterar[] =
      [];

    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (item && item.checked) {
        listaIndexItemSelecionado.push(index);
      }
    });

    listaIndexItemSelecionado.forEach((index) => {
      if (listaValor[index].exibirRegistro) {
        const retorno: IAtualizacaoPrecoCustoProdutoListaValoresAlterar = {
          id: listaValor[index].id,
          codigo: listaValor[index].codigo,
          descricao:
            listaDescricaoRef.current && listaDescricaoRef.current[index]
              ? listaDescricaoRef.current[index].value
              : undefined,
          ativo:
            listaAtivoRef.current && listaAtivoRef.current[index]
              ? Boolean(listaAtivoRef.current[index].checked)
              : undefined,

          produtoCusto: {
            valorUnitarioCustoReposicao:
              listaValorUnitarioCustoReposicaoRef.current &&
              listaValorUnitarioCustoReposicaoRef.current[index]
                ? listaValorUnitarioCustoReposicaoRef.current[
                    index
                  ].value.ConverterParaNumber()
                : undefined,
          },

          produtoEngenharia: {
            codigoBarras:
              listaCodigoBarrasRef.current &&
              listaCodigoBarrasRef.current[index]
                ? listaCodigoBarrasRef.current[index].value
                : undefined,
          },

          produtoFiscal: {
            ncm:
              listaNcmRef.current && listaNcmRef.current[index]
                ? listaNcmRef.current[index].getItemAtual()
                : undefined,
            cest:
              listaCestRef.current && listaCestRef.current[index]
                ? listaCestRef.current[
                    index
                  ].autoCompleteRef.current?.getItemAtual()
                : undefined,
          },

          produtoRegraEscolhaTabelaPreco: [],
        };

        listaItemSelecionado.push(retorno);
      }

      const registro = listaItemSelecionado.find(
        (e) => e.id === listaValor[index].id
      );

      if (registro) {
        let adicionarProdutoRegraEscolhaTabelaPreco = false;
        const produtoRegraEscolhaTabelaPreco: any = {
          id: listaValor[index].produtoRegraEscolhaTabelaPreco[0].id,
          regraEscolhaTabelaPreco:
            listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .regraEscolhaTabelaPreco,
        };

        if (
          listaValorUnitarioVendaRef.current &&
          listaValorUnitarioVendaRef.current[index] &&
          listaValor[index].produtoRegraEscolhaTabelaPreco.length > 0
        ) {
          const valorUnitarioVendaRef =
            listaValorUnitarioVendaRef.current[
              index
            ].value.ConverterParaNumber();

          if (
            valorUnitarioVendaRef !==
            Number(
              listaValor[index].produtoRegraEscolhaTabelaPreco[0]
                .valorUnitarioVenda
            )
          ) {
            produtoRegraEscolhaTabelaPreco.valorUnitarioVenda =
              valorUnitarioVendaRef;
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          }
        }

        if (
          listaAumentandoEmPercentualRef.current &&
          listaAumentandoEmPercentualRef.current[index] &&
          listaValor[index].produtoRegraEscolhaTabelaPreco.length > 0
        ) {
          const aumentandoEmPercentualRef =
            listaAumentandoEmPercentualRef.current[index].value;

          if (
            listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .aumentandoEmPercentual &&
            !aumentandoEmPercentualRef
          ) {
            produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual = null;
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            !listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .aumentandoEmPercentual &&
            aumentandoEmPercentualRef
          ) {
            produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual =
              aumentandoEmPercentualRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            aumentandoEmPercentualRef.ConverterParaNumber() !==
            Number(
              listaValor[index].produtoRegraEscolhaTabelaPreco[0]
                .aumentandoEmPercentual
            )
          ) {
            produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual =
              aumentandoEmPercentualRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          }
        }

        if (
          listaAplicandoMarkupRef.current &&
          listaAplicandoMarkupRef.current[index] &&
          listaValor[index].produtoRegraEscolhaTabelaPreco.length > 0
        ) {
          const aplicandoMarkupRef =
            listaAplicandoMarkupRef.current[index].value;

          if (
            listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .aplicandoMarkup &&
            !aplicandoMarkupRef
          ) {
            produtoRegraEscolhaTabelaPreco.aplicandoMarkup = null;
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            !listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .aplicandoMarkup &&
            aplicandoMarkupRef
          ) {
            produtoRegraEscolhaTabelaPreco.aplicandoMarkup =
              aplicandoMarkupRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            aplicandoMarkupRef.ConverterParaNumber() !==
            Number(
              listaValor[index].produtoRegraEscolhaTabelaPreco[0]
                .aplicandoMarkup
            )
          ) {
            produtoRegraEscolhaTabelaPreco.aplicandoMarkup =
              aplicandoMarkupRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          }
        }

        if (
          listaDiminuindoEmPercentualRef.current &&
          listaDiminuindoEmPercentualRef.current[index] &&
          listaValor[index].produtoRegraEscolhaTabelaPreco.length > 0
        ) {
          const diminuindoEmPercentualRef =
            listaDiminuindoEmPercentualRef.current[index].value;

          if (
            listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .diminuindoEmPercentual &&
            !diminuindoEmPercentualRef
          ) {
            produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual = null;
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            !listaValor[index].produtoRegraEscolhaTabelaPreco[0]
              .diminuindoEmPercentual &&
            diminuindoEmPercentualRef
          ) {
            produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual =
              diminuindoEmPercentualRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          } else if (
            diminuindoEmPercentualRef.ConverterParaNumber() !==
            Number(
              listaValor[index].produtoRegraEscolhaTabelaPreco[0]
                .diminuindoEmPercentual
            )
          ) {
            produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual =
              diminuindoEmPercentualRef.ConverterParaNumber();
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          }
        }

        if (
          listaValorUnitarioBaseRef.current &&
          listaValorUnitarioBaseRef.current[index] &&
          listaValor[index].produtoRegraEscolhaTabelaPreco.length > 0
        ) {
          const valorUnitarioBaseRef =
            listaValorUnitarioBaseRef.current[
              index
            ].value.ConverterParaNumber();

          if (
            valorUnitarioBaseRef !==
            Number(
              listaValor[index].produtoRegraEscolhaTabelaPreco[0]
                .valorUnitarioBase
            )
          ) {
            produtoRegraEscolhaTabelaPreco.valorUnitarioBase =
              valorUnitarioBaseRef;
            adicionarProdutoRegraEscolhaTabelaPreco = true;
          }
        }

        if (adicionarProdutoRegraEscolhaTabelaPreco) {
          registro.produtoRegraEscolhaTabelaPreco.push(
            produtoRegraEscolhaTabelaPreco
          );
        }
      }
    });

    return listaItemSelecionado;
  }, [
    listaAplicandoMarkupRef,
    listaAtivoRef,
    listaAumentandoEmPercentualRef,
    listaCestRef,
    listaCodigoBarrasRef,
    listaDescricaoRef,
    listaDiminuindoEmPercentualRef,
    listaItemSelecionadoRef,
    listaNcmRef,
    listaValor,
    listaValorUnitarioBaseRef,
    listaValorUnitarioCustoReposicaoRef,
    listaValorUnitarioVendaRef,
  ]);

  const handleObterItensSelecionadosParaImpressao =
    useCallback(async (): Promise<
      IImpressaoEtiquetasProdutosTransferirLista[]
    > => {
      const listaIndexItemSelecionado: number[] = [];
      const listaItemSelecionado: IImpressaoEtiquetasProdutosTransferirLista[] =
        [];

      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item && item.checked) {
          listaIndexItemSelecionado.push(index);
        }
      });

      listaIndexItemSelecionado.forEach((index) => {
        if (listaValor[index].exibirRegistro) {
          const retorno: IImpressaoEtiquetasProdutosTransferirLista = {
            selecionado: true,
            quantidade: 1,

            produto: {
              id: listaValor[index].id,
              codigo: listaValor[index].codigo,
              descricao:
                listaDescricaoRef.current && listaDescricaoRef.current[index]
                  ? listaDescricaoRef.current[index].value
                  : '',
            },
          };

          listaItemSelecionado.push(retorno);
        }
      });

      return listaItemSelecionado;
    }, [listaDescricaoRef, listaItemSelecionadoRef, listaValor]);

  const atualizar = useCallback(() => {
    if (selecionarTodosRef.current) {
      selecionarTodosRef.current.checked = false;
    }

    setJaRecuperouDados(true);
    handleSelecionarTodos(false);
    setRefresh((state) => !state);
  }, [handleSelecionarTodos]);

  const handleValidar = useCallback(
    async (
      data: IAtualizacaoPrecoCustoProdutoListaValoresAlterar[]
    ): Promise<boolean> => {
      try {
        const listaMensagem: string[] = [];

        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          if (item.produtoEngenharia.codigoBarras) {
            if (!ValidarCodigoBarrasGtin(item.produtoEngenharia.codigoBarras)) {
              listaMensagem.push(
                `O Código de Barras do Produto: ${item.codigo} - ${item.descricao} é inválido!`
              );
            }
          }

          if (
            listaColunas.find((e) => e.nomeCampo === 'produtoFiscal.ncm')
              ?.visivel
          ) {
            if (!item.produtoFiscal.ncm || !item.produtoFiscal.ncm.id) {
              listaMensagem.push(
                `Obrigatório informar o NCM do Produto: ${item.codigo} - ${item.descricao}!`
              );
            }
          }
        }

        if (listaMensagem.length > 0) {
          TratarErros({ listaMensagem });
          return false;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [listaColunas]
  );

  const handleClickSalvar = useCallback(async (): Promise<boolean> => {
    try {
      setLoading(true);

      const data = await handleObterItensSelecionados();
      if (!(await handleValidar(data))) {
        setLoading(false);
        return false;
      }
      await AtualizacaoPrecoCustoProdutoComunicador.update({ params: data });

      atualizar();
      ToastSucesso('Registro Salvo!');
      setLoading(false);
      return true;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return false;
    }
  }, [atualizar, handleObterItensSelecionados, handleValidar]);

  const handleOnAlterarValorUnitarioVendaRecalcularPrecoProduto = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      const valorUnitarioVenda = listaValorUnitarioVendaRef.current
        ? listaValorUnitarioVendaRef.current[index].value.ConverterParaNumber()
        : 0;

      if (
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto
      ) {
        for (let indice = 0; indice < listaDados.length; indice++) {
          const item = listaDados[indice];

          if (
            item.id === listaDados[index].id &&
            item.produtoRegraEscolhaTabelaPreco[0].regraEscolhaTabelaPreco
              .origemValoresTabela ===
              OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos
          ) {
            if (listaValorUnitarioBaseRef.current) {
              listaValorUnitarioBaseRef.current[indice].value =
                valorUnitarioVenda.FormatarParaPtBr({
                  minimoCasasDecimais: 10,
                  maximoCasasDecimais: 10,
                });
            }

            // #region Selecionar Registro

            const registroSelecionadoRef =
              listaItemSelecionadoRef.current?.[indice];

            if (registroSelecionadoRef && !registroSelecionadoRef.checked) {
              registroSelecionadoRef.checked = true;
              handleAlterouSelecionado();
            }

            // #endregion Selecionar Registro

            if (
              listaDados[indice].produtoRegraEscolhaTabelaPreco[0]
                .regraEscolhaTabelaPreco.campoAtualizar ===
              CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda
            ) {
              const valorUnitarioBaseItem = listaValorUnitarioBaseRef.current
                ? listaValorUnitarioBaseRef.current[
                    indice
                  ].value.ConverterParaNumber()
                : 0;
              const aplicandoMarkupItem = listaAplicandoMarkupRef.current
                ? listaAplicandoMarkupRef.current[
                    indice
                  ].value.ConverterParaNumber()
                : null;
              const casasDecimaisItem = Number(
                listaDados[indice].produtoRegraEscolhaTabelaPreco[0]
                  .regraEscolhaTabelaPreco.casasDecimais
              );

              let valorUnitarioVendaItem = 0;
              if (aplicandoMarkupItem && aplicandoMarkupItem !== 0) {
                valorUnitarioVendaItem = Number(
                  valorUnitarioBaseItem * (100 / (100 - aplicandoMarkupItem))
                ).Arredondar(casasDecimaisItem);
              }

              if (
                listaValorUnitarioVendaRef.current &&
                listaValorUnitarioVendaRef.current[indice]
              ) {
                if (
                  !listaDados[indice].produtoRegraEscolhaTabelaPreco[0]
                    .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto
                ) {
                  listaValorUnitarioVendaRef.current[indice].value =
                    valorUnitarioVendaItem.FormatarParaPtBr({
                      minimoCasasDecimais: casasDecimaisItem,
                      maximoCasasDecimais: casasDecimaisItem,
                    });
                }
              }
            } else {
              const valorUnitarioBaseItem = listaValorUnitarioBaseRef.current
                ? listaValorUnitarioBaseRef.current[
                    indice
                  ].value.ConverterParaNumber()
                : 0;
              const valorUnitarioVendaItem = listaValorUnitarioVendaRef.current
                ? listaValorUnitarioVendaRef.current[
                    indice
                  ].value.ConverterParaNumber()
                : 0;

              let aumentandoEmPercentualItem = null;
              let aplicandoMarkupItem = null;
              let diminuindoEmPercentualItem = null;

              if (valorUnitarioBaseItem > 0 && valorUnitarioVendaItem > 0) {
                aplicandoMarkupItem = Number(
                  (1 - valorUnitarioBaseItem / valorUnitarioVendaItem) * 100
                ).Arredondar(4);

                if (valorUnitarioVendaItem > valorUnitarioBaseItem) {
                  aumentandoEmPercentualItem = Number(
                    (100 * valorUnitarioVendaItem) / valorUnitarioBaseItem - 100
                  ).Arredondar(4);
                } else {
                  diminuindoEmPercentualItem = Number(
                    100 - (100 * valorUnitarioVendaItem) / valorUnitarioBaseItem
                  ).Arredondar(4);
                }
              }

              if (
                listaAumentandoEmPercentualRef.current &&
                listaAumentandoEmPercentualRef.current[indice]
              ) {
                listaAumentandoEmPercentualRef.current[indice].value =
                  aumentandoEmPercentualItem
                    ? aumentandoEmPercentualItem.FormatarParaPtBr({
                        minimoCasasDecimais: 4,
                        maximoCasasDecimais: 4,
                      })
                    : '';
              }

              if (
                listaAplicandoMarkupRef.current &&
                listaAplicandoMarkupRef.current[indice]
              ) {
                listaAplicandoMarkupRef.current[indice].value =
                  aplicandoMarkupItem
                    ? aplicandoMarkupItem.FormatarParaPtBr({
                        minimoCasasDecimais: 4,
                        maximoCasasDecimais: 4,
                      })
                    : '';
              }

              if (
                listaDiminuindoEmPercentualRef.current &&
                listaDiminuindoEmPercentualRef.current[indice]
              ) {
                listaDiminuindoEmPercentualRef.current[indice].value =
                  diminuindoEmPercentualItem
                    ? diminuindoEmPercentualItem.FormatarParaPtBr({
                        minimoCasasDecimais: 4,
                        maximoCasasDecimais: 4,
                      })
                    : '';
              }
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnAlterarValorUnitarioVenda = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      if (
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto &&
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.origemValoresTabela ===
          OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos
      ) {
        if (
          listaValorUnitarioBaseRef.current &&
          listaValorUnitarioVendaRef.current &&
          listaValorUnitarioVendaRef.current[index].value
        ) {
          listaValorUnitarioBaseRef.current[index].value =
            listaValorUnitarioVendaRef.current[index].value
              .ConverterParaNumber()
              .FormatarParaPtBr({
                minimoCasasDecimais: 10,
                maximoCasasDecimais: 10,
              });
        }
      }
      const valorUnitarioBase = listaValorUnitarioBaseRef.current
        ? listaValorUnitarioBaseRef.current[index].value.ConverterParaNumber()
        : 0;
      const valorUnitarioVenda = listaValorUnitarioVendaRef.current
        ? listaValorUnitarioVendaRef.current[index].value.ConverterParaNumber()
        : 0;

      let aumentandoEmPercentual = null;
      let aplicandoMarkup = null;
      let diminuindoEmPercentual = null;

      if (valorUnitarioBase > 0 && valorUnitarioVenda > 0) {
        aplicandoMarkup = Number(
          (1 - valorUnitarioBase / valorUnitarioVenda) * 100
        ).Arredondar(4);

        if (valorUnitarioVenda > valorUnitarioBase) {
          aumentandoEmPercentual = Number(
            (100 * valorUnitarioVenda) / valorUnitarioBase - 100
          ).Arredondar(4);
        } else {
          diminuindoEmPercentual = Number(
            100 - (100 * valorUnitarioVenda) / valorUnitarioBase
          ).Arredondar(4);
        }
      }

      if (
        listaAumentandoEmPercentualRef.current &&
        listaAumentandoEmPercentualRef.current[index]
      ) {
        listaAumentandoEmPercentualRef.current[index].value =
          aumentandoEmPercentual
            ? aumentandoEmPercentual.FormatarParaPtBr({
                minimoCasasDecimais: 4,
                maximoCasasDecimais: 4,
              })
            : '';
      }

      if (
        listaAplicandoMarkupRef.current &&
        listaAplicandoMarkupRef.current[index]
      ) {
        listaAplicandoMarkupRef.current[index].value = aplicandoMarkup
          ? aplicandoMarkup.FormatarParaPtBr({
              minimoCasasDecimais: 4,
              maximoCasasDecimais: 4,
            })
          : '';
      }

      if (
        listaDiminuindoEmPercentualRef.current &&
        listaDiminuindoEmPercentualRef.current[index]
      ) {
        listaDiminuindoEmPercentualRef.current[index].value =
          diminuindoEmPercentual
            ? diminuindoEmPercentual.FormatarParaPtBr({
                minimoCasasDecimais: 4,
                maximoCasasDecimais: 4,
              })
            : '';
      }

      handleOnAlterarValorUnitarioVendaRecalcularPrecoProduto(
        listaDados,
        index
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnAlterarAumentandoEmPercentual = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      const valorUnitarioBase = listaValorUnitarioBaseRef.current
        ? listaValorUnitarioBaseRef.current[index].value.ConverterParaNumber()
        : 0;
      const aumentandoEmPercentual = listaAumentandoEmPercentualRef.current
        ? listaAumentandoEmPercentualRef.current[
            index
          ].value.ConverterParaNumber()
        : null;
      const casasDecimais = Number(
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.casasDecimais
      );

      let valorUnitarioVenda = 0;
      let aplicandoMarkup = null;

      if (aumentandoEmPercentual && aumentandoEmPercentual > 0) {
        valorUnitarioVenda = Number(
          valorUnitarioBase + valorUnitarioBase * (aumentandoEmPercentual / 100)
        ).Arredondar(casasDecimais);
      }

      if (
        listaValorUnitarioVendaRef.current &&
        listaValorUnitarioVendaRef.current[index]
      ) {
        listaValorUnitarioVendaRef.current[index].value =
          valorUnitarioVenda.FormatarParaPtBr({
            minimoCasasDecimais: casasDecimais,
            maximoCasasDecimais: casasDecimais,
          });
      }

      if (valorUnitarioBase > 0 && valorUnitarioVenda > 0) {
        aplicandoMarkup = Number(
          (1 - valorUnitarioBase / valorUnitarioVenda) * 100
        ).Arredondar(4);
      }

      if (
        listaAplicandoMarkupRef.current &&
        listaAplicandoMarkupRef.current[index]
      ) {
        listaAplicandoMarkupRef.current[index].value = aplicandoMarkup
          ? aplicandoMarkup.FormatarParaPtBr({
              minimoCasasDecimais: 4,
              maximoCasasDecimais: 4,
            })
          : '';
      }

      if (
        listaDiminuindoEmPercentualRef.current &&
        listaDiminuindoEmPercentualRef.current[index]
      ) {
        listaDiminuindoEmPercentualRef.current[index].value = '';
      }

      handleOnAlterarValorUnitarioVendaRecalcularPrecoProduto(
        listaDados,
        index
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnAlterarAplicandoMarkup = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      const valorUnitarioBase = listaValorUnitarioBaseRef.current
        ? listaValorUnitarioBaseRef.current[index].value.ConverterParaNumber()
        : 0;
      const aplicandoMarkup = listaAplicandoMarkupRef.current
        ? listaAplicandoMarkupRef.current[index].value.ConverterParaNumber()
        : null;
      const casasDecimais = Number(
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.casasDecimais
      );

      let valorUnitarioVenda = 0;
      let aumentandoEmPercentual = null;
      let diminuindoEmPercentual = null;

      if (aplicandoMarkup && aplicandoMarkup !== 0) {
        valorUnitarioVenda = Number(
          valorUnitarioBase * (100 / (100 - aplicandoMarkup))
        ).Arredondar(casasDecimais);
      }

      if (
        listaValorUnitarioVendaRef.current &&
        listaValorUnitarioVendaRef.current[index]
      ) {
        listaValorUnitarioVendaRef.current[index].value =
          valorUnitarioVenda.FormatarParaPtBr({
            minimoCasasDecimais: casasDecimais,
            maximoCasasDecimais: casasDecimais,
          });
      }

      if (valorUnitarioVenda > 0 && valorUnitarioBase > 0) {
        if (valorUnitarioVenda > valorUnitarioBase) {
          aumentandoEmPercentual = Number(
            (100 * valorUnitarioVenda) / valorUnitarioBase - 100
          ).Arredondar(4);
        } else {
          diminuindoEmPercentual = Number(
            100 - (100 * valorUnitarioVenda) / valorUnitarioBase
          ).Arredondar(4);
        }
      }

      if (
        listaAumentandoEmPercentualRef.current &&
        listaAumentandoEmPercentualRef.current[index]
      ) {
        listaAumentandoEmPercentualRef.current[index].value =
          aumentandoEmPercentual
            ? aumentandoEmPercentual.FormatarParaPtBr({
                minimoCasasDecimais: 4,
                maximoCasasDecimais: 4,
              })
            : '';
      }

      if (
        listaDiminuindoEmPercentualRef.current &&
        listaDiminuindoEmPercentualRef.current[index]
      ) {
        listaDiminuindoEmPercentualRef.current[index].value =
          diminuindoEmPercentual
            ? diminuindoEmPercentual.FormatarParaPtBr({
                minimoCasasDecimais: 4,
                maximoCasasDecimais: 4,
              })
            : '';
      }

      handleOnAlterarValorUnitarioVendaRecalcularPrecoProduto(
        listaDados,
        index
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnAlterarDiminuindoEmPercentual = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      const valorUnitarioBase = listaValorUnitarioBaseRef.current
        ? listaValorUnitarioBaseRef.current[index].value.ConverterParaNumber()
        : 0;
      const diminuindoEmPercentual = listaDiminuindoEmPercentualRef.current
        ? listaDiminuindoEmPercentualRef.current[
            index
          ].value.ConverterParaNumber()
        : null;
      const casasDecimais = Number(
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.casasDecimais
      );

      let valorUnitarioVenda = 0;
      let aplicandoMarkup = null;

      if (diminuindoEmPercentual && diminuindoEmPercentual > 0) {
        valorUnitarioVenda = Number(
          valorUnitarioBase - valorUnitarioBase * (diminuindoEmPercentual / 100)
        ).Arredondar(casasDecimais);
      }

      if (
        listaValorUnitarioVendaRef.current &&
        listaValorUnitarioVendaRef.current[index]
      ) {
        listaValorUnitarioVendaRef.current[index].value =
          valorUnitarioVenda.FormatarParaPtBr({
            minimoCasasDecimais: casasDecimais,
            maximoCasasDecimais: casasDecimais,
          });
      }

      if (valorUnitarioBase > 0 && valorUnitarioVenda > 0) {
        aplicandoMarkup = Number(
          (1 - valorUnitarioBase / valorUnitarioVenda) * 100
        ).Arredondar(4);
      }

      if (
        listaAplicandoMarkupRef.current &&
        listaAplicandoMarkupRef.current[index]
      ) {
        listaAplicandoMarkupRef.current[index].value = aplicandoMarkup
          ? aplicandoMarkup.FormatarParaPtBr({
              minimoCasasDecimais: 4,
              maximoCasasDecimais: 4,
            })
          : '';
      }

      if (
        listaAumentandoEmPercentualRef.current &&
        listaAumentandoEmPercentualRef.current[index]
      ) {
        listaAumentandoEmPercentualRef.current[index].value = '';
      }

      handleOnAlterarValorUnitarioVendaRecalcularPrecoProduto(
        listaDados,
        index
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnAlterarValorUnitarioBase = useCallback(
    (listaDados: IAtualizacaoPrecoCustoProdutoLista[], index: number): void => {
      if (
        listaDados[index].produtoRegraEscolhaTabelaPreco[0]
          .regraEscolhaTabelaPreco.campoAtualizar ===
        CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda
      ) {
        handleOnAlterarAplicandoMarkup(listaDados, index);
      } else {
        handleOnAlterarValorUnitarioVenda(listaDados, index);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);

        let listaDados: IAtualizacaoPrecoCustoProdutoLista[];
        let totalPaginas: number;

        if (dadosRecuperados && !jaRecuperouDados) {
          listaDados = dadosRecuperados.listaDados;
          totalPaginas = dadosRecuperados.totalPaginas;

          const itensSelecionados = listaDados.filter(
            (item: IAtualizacaoPrecoCustoProdutoLista) => {
              return item.selecionado;
            }
          );

          setPossuiItemSelecionado(itensSelecionados.length > 0);
        }

        const response = await AtualizacaoPrecoCustoProdutoComunicador.index({
          params: {
            pesquisaAvancada: {
              ...pesquisaAvancada,
            },
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        listaDados = response.dados[0].map(
          (data: IAtualizacaoPrecoCustoProdutoLista) => {
            return { ...data, selecionado: false };
          }
        );

        totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);

        const dados = listaDados.map(
          (data: IAtualizacaoPrecoCustoProdutoLista, index: number) => {
            return {
              id: data.id,
              possuiPercentuaisInformados: (
                <div>{data.possuiPercentuaisInformados}</div>
              ),

              selecionado: (
                <div
                  style={{
                    display: data.exibirRegistro ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              codigo: (
                <div>
                  {data.exibirRegistro && (
                    <div className="lista-texto">{data.codigo}</div>
                  )}
                </div>
              ),
              descricao: (
                <div
                  className="lista-texto"
                  style={{ display: data.exibirRegistro ? 'flex' : 'none' }}
                >
                  {data.exibirRegistro && (
                    <InputTabela
                      valorPadrao={data.descricao}
                      maxLength={120}
                      obterRef={(instance) => {
                        if (instance.current && listaDescricaoRef.current) {
                          listaDescricaoRef.current[index] = instance.current;
                          listaDescricaoRef.current[index].value =
                            data.descricao;
                        }
                      }}
                      onKeyDown={(e) => {
                        const controleFocus = handleControlarFocus({
                          index,
                          nomeCampo: 'descricao',
                          listaTh: listaColunas,
                          listaItens: listaValor,
                        });

                        if (!controleFocus) return;

                        const {
                          linhaIndexAnterior,
                          linhaIndexProxima,
                          listaRefAnterior,
                          listaRefProxima,
                        } = controleFocus;

                        if (e.shiftKey && e.key === 'Tab') {
                          e.preventDefault();
                          listaRefAnterior?.current[
                            linhaIndexAnterior
                          ]?.focus();
                        } else if (e.key === 'Enter') {
                          e.preventDefault();

                          let proximo = index;

                          do {
                            proximo += 1;
                            if (proximo >= listaValor.length) proximo = 0;
                          } while (!listaDescricaoRef.current?.[proximo]);

                          listaDescricaoRef.current?.[proximo]?.focus();
                        } else if (e.key === 'Tab') {
                          e.preventDefault();
                          listaRefProxima?.current[linhaIndexProxima]?.focus();
                        }
                      }}
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'produtoEstoque.unidadeMedida.sigla': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">
                        {data.produtoEstoque.unidadeMedida.sigla}
                      </div>
                    )}
                  </div>
                </div>
              ),

              'produtoFiscal.sujeitoASt': (
                <div className="lista-texto">
                  <div>
                    {data.exibirRegistro && (
                      <div className="lista-texto">
                        {data.produtoFiscal.sujeitoASt
                          ? 'Sim'
                          : data.produtoFiscal.sujeitoASt === false
                            ? 'Não'
                            : ''}
                      </div>
                    )}
                  </div>
                </div>
              ),

              'produtoRegraEscolhaTabelaPreco.regraEscolhaTabelaPreco.descricao':
                (
                  <div className="lista-texto">
                    <div>
                      <div className="lista-texto">
                        {data.produtoRegraEscolhaTabelaPreco.length > 0
                          ? data.produtoRegraEscolhaTabelaPreco[0]
                              .regraEscolhaTabelaPreco.descricao
                          : ''}
                      </div>
                    </div>
                  </div>
                ),
              'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda': (
                <div className="lista-valor">
                  <InputTabelaDecimal
                    style={{
                      opacity:
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        data.possuiPercentuaisInformados
                          ? 0.6
                          : 1,
                      height: 25,
                      textAlign: 'end',
                    }}
                    valorPadrao={
                      data.produtoRegraEscolhaTabelaPreco.length > 0
                        ? data.produtoRegraEscolhaTabelaPreco[0]
                            .valorUnitarioVenda
                        : 0
                    }
                    obterRef={(instance) => {
                      if (
                        instance &&
                        instance.current &&
                        listaValorUnitarioVendaRef.current
                      ) {
                        listaValorUnitarioVendaRef.current[index] =
                          instance.current;
                        listaValorUnitarioVendaRef.current[index].value =
                          Number(
                            data.produtoRegraEscolhaTabelaPreco[0]
                              .valorUnitarioVenda
                          ).FormatarParaPtBr({
                            maximoCasasDecimais:
                              data.produtoRegraEscolhaTabelaPreco.length > 0
                                ? data.produtoRegraEscolhaTabelaPreco[0]
                                    .regraEscolhaTabelaPreco.casasDecimais
                                : 2,
                            minimoCasasDecimais:
                              data.produtoRegraEscolhaTabelaPreco.length > 0
                                ? data.produtoRegraEscolhaTabelaPreco[0]
                                    .regraEscolhaTabelaPreco.casasDecimais
                                : 2,
                          });
                      }
                    }}
                    casasDecimais={
                      data.produtoRegraEscolhaTabelaPreco.length > 0
                        ? data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.casasDecimais
                        : 2
                    }
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo:
                          'produtoRegraEscolhaTabelaPreco.valorUnitarioVenda',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (
                          !listaValorUnitarioVendaRef.current?.[proximo]
                        );

                        listaValorUnitarioVendaRef.current?.[proximo]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event, props) => {
                      if (
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        data.possuiPercentuaisInformados
                      ) {
                        if (listaValorUnitarioVendaRef.current) {
                          listaValorUnitarioVendaRef.current[index].value =
                            props.valorAnteriorOnChange;
                        }

                        return;
                      }

                      // #region Selecionar Item
                      const indexRegistroPrincipal = listaDados.findIndex(
                        (e) => e.id === data.id && e.exibirRegistro
                      );

                      const itemPrincipalRef =
                        listaItemSelecionadoRef.current?.[
                          indexRegistroPrincipal
                        ];

                      if (itemPrincipalRef && !itemPrincipalRef.checked) {
                        itemPrincipalRef.checked = true;
                      }

                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];

                      if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                        itemSelecionadoRef.checked = true;
                      }

                      handleAlterouSelecionado();

                      // #endregion Selecionar Item

                      handleOnAlterarValorUnitarioVenda(listaDados, index);
                    }}
                  />
                </div>
              ),
              'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual': (
                <div className="lista-valor">
                  <InputTabelaDecimalNulavel
                    style={{
                      opacity:
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                          ? 0.6
                          : 1,
                      height: 25,
                      textAlign: 'end',
                    }}
                    valorPadrao={
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .aumentandoEmPercentual
                    }
                    obterRef={(instance) => {
                      if (
                        instance &&
                        instance.current &&
                        listaAumentandoEmPercentualRef.current
                      ) {
                        listaAumentandoEmPercentualRef.current[index] =
                          instance.current;
                        if (
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .aumentandoEmPercentual !== undefined
                        ) {
                          listaAumentandoEmPercentualRef.current[index].value =
                            Number(
                              data.produtoRegraEscolhaTabelaPreco[0]
                                .aumentandoEmPercentual
                            ).FormatarParaPtBr({
                              maximoCasasDecimais: 4,
                              minimoCasasDecimais: 4,
                            });
                        }
                      }
                    }}
                    casasInteiras={7}
                    casasDecimais={4}
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo:
                          'produtoRegraEscolhaTabelaPreco.aumentandoEmPercentual',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (
                          !listaAumentandoEmPercentualRef.current?.[proximo]
                        );

                        listaAumentandoEmPercentualRef.current?.[
                          proximo
                        ]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event, props) => {
                      if (
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                      ) {
                        if (listaAumentandoEmPercentualRef.current) {
                          listaAumentandoEmPercentualRef.current[index].value =
                            props.valorAnteriorOnChange;
                        }

                        return;
                      }

                      // #region Selecionar Item

                      const indexRegistroPrincipal = listaDados.findIndex(
                        (e) => e.id === data.id && e.exibirRegistro
                      );

                      const itemPrincipalRef =
                        listaItemSelecionadoRef.current?.[
                          indexRegistroPrincipal
                        ];

                      if (itemPrincipalRef && !itemPrincipalRef.checked) {
                        itemPrincipalRef.checked = true;
                      }

                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];

                      if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                        itemSelecionadoRef.checked = true;
                      }

                      handleAlterouSelecionado();

                      // #endregion Selecionar Item

                      handleOnAlterarAumentandoEmPercentual(listaDados, index);
                    }}
                    disabled={
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.origemValoresTabela ===
                        OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos &&
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto
                    }
                  />
                </div>
              ),
              'produtoRegraEscolhaTabelaPreco.aplicandoMarkup': (
                <div className="lista-valor">
                  <InputTabelaDecimalNulavel
                    style={{
                      opacity:
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                          ? 0.6
                          : 1,
                      height: 25,
                      textAlign: 'end',
                    }}
                    valorPadrao={
                      data.produtoRegraEscolhaTabelaPreco[0].aplicandoMarkup
                    }
                    obterRef={(instance) => {
                      if (
                        instance &&
                        instance.current &&
                        listaAplicandoMarkupRef.current
                      ) {
                        listaAplicandoMarkupRef.current[index] =
                          instance.current;
                        if (
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .aplicandoMarkup !== undefined
                        ) {
                          listaAplicandoMarkupRef.current[index].value = Number(
                            data.produtoRegraEscolhaTabelaPreco[0]
                              .aplicandoMarkup
                          ).FormatarParaPtBr({
                            maximoCasasDecimais: 4,
                            minimoCasasDecimais: 4,
                          });
                        }
                      }
                    }}
                    casasDecimais={4}
                    casasInteiras={2}
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo:
                          'produtoRegraEscolhaTabelaPreco.aplicandoMarkup',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (!listaAplicandoMarkupRef.current?.[proximo]);

                        listaAplicandoMarkupRef.current?.[proximo]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event, props) => {
                      if (
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                      ) {
                        if (listaAplicandoMarkupRef.current) {
                          listaAplicandoMarkupRef.current[index].value =
                            props.valorAnteriorOnChange;
                        }

                        return;
                      }

                      // #region Selecionar Item

                      const indexRegistroPrincipal = listaDados.findIndex(
                        (e) => e.id === data.id && e.exibirRegistro
                      );

                      const itemPrincipalRef =
                        listaItemSelecionadoRef.current?.[
                          indexRegistroPrincipal
                        ];

                      if (itemPrincipalRef && !itemPrincipalRef.checked) {
                        itemPrincipalRef.checked = true;
                      }

                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];

                      if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                        itemSelecionadoRef.checked = true;
                      }

                      handleAlterouSelecionado();

                      // #endregion Selecionar Item

                      handleOnAlterarAplicandoMarkup(listaDados, index);
                    }}
                    disabled={
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.origemValoresTabela ===
                        OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos &&
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto
                    }
                  />
                </div>
              ),
              'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual': (
                <div className="lista-valor">
                  <InputTabelaDecimalNulavel
                    style={{
                      opacity:
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                          ? 0.6
                          : 1,
                      height: 25,
                      textAlign: 'end',
                    }}
                    valorPadrao={
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .diminuindoEmPercentual
                    }
                    obterRef={(instance) => {
                      if (
                        instance &&
                        instance.current &&
                        listaDiminuindoEmPercentualRef.current
                      ) {
                        listaDiminuindoEmPercentualRef.current[index] =
                          instance.current;

                        if (
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .diminuindoEmPercentual !== undefined
                        ) {
                          listaDiminuindoEmPercentualRef.current[index].value =
                            Number(
                              data.produtoRegraEscolhaTabelaPreco[0]
                                .diminuindoEmPercentual
                            ).FormatarParaPtBr({
                              maximoCasasDecimais: 4,
                              minimoCasasDecimais: 4,
                            });
                        }
                      }
                    }}
                    casasDecimais={4}
                    casasInteiras={2}
                    onKeyDown={(e) => {
                      const controleFocus = handleControlarFocus({
                        index,
                        nomeCampo:
                          'produtoRegraEscolhaTabelaPreco.diminuindoEmPercentual',
                        listaTh: listaColunas,
                        listaItens: listaValor,
                      });

                      if (!controleFocus) return;

                      const {
                        linhaIndexAnterior,
                        linhaIndexProxima,
                        listaRefAnterior,
                        listaRefProxima,
                      } = controleFocus;

                      if (e.shiftKey && e.key === 'Tab') {
                        e.preventDefault();
                        listaRefAnterior?.current[linhaIndexAnterior]?.focus();
                      } else if (e.key === 'Enter') {
                        e.preventDefault();

                        let proximo = index;

                        do {
                          proximo += 1;
                          if (proximo >= listaValor.length) proximo = 0;
                        } while (
                          !listaDiminuindoEmPercentualRef.current?.[proximo]
                        );

                        listaDiminuindoEmPercentualRef.current?.[
                          proximo
                        ]?.focus();
                      } else if (e.key === 'Tab') {
                        e.preventDefault();
                        listaRefProxima?.current[linhaIndexProxima]?.focus();
                      }
                    }}
                    onChange={(event, props) => {
                      if (
                        data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.campoAtualizar ===
                          CampoAtualizarTabelaPrecoEnum.valorUnitarioVenda &&
                        (data.produtoRegraEscolhaTabelaPreco[0]
                          .regraEscolhaTabelaPreco.aumentandoEmPercentual ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.aplicandoMarkup ||
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .regraEscolhaTabelaPreco.diminuindoEmPercentual)
                      ) {
                        if (listaDiminuindoEmPercentualRef.current) {
                          listaDiminuindoEmPercentualRef.current[index].value =
                            props.valorAnteriorOnChange;
                        }

                        return;
                      }

                      // #region Selecionar Item

                      const indexRegistroPrincipal = listaDados.findIndex(
                        (e) => e.id === data.id && e.exibirRegistro
                      );

                      const itemPrincipalRef =
                        listaItemSelecionadoRef.current?.[
                          indexRegistroPrincipal
                        ];

                      if (itemPrincipalRef && !itemPrincipalRef.checked) {
                        itemPrincipalRef.checked = true;
                      }

                      const itemSelecionadoRef =
                        listaItemSelecionadoRef.current?.[index];

                      if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                        itemSelecionadoRef.checked = true;
                      }

                      handleAlterouSelecionado();

                      // #endregion Selecionar Item

                      handleOnAlterarDiminuindoEmPercentual(listaDados, index);
                    }}
                    disabled={
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.origemValoresTabela ===
                        OrigemValorBaseTabelaPrecoEnum.precoVendaCadastroProdutos &&
                      data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.vinculadaAoCadastroProduto
                    }
                  />
                </div>
              ),
              'produtoRegraEscolhaTabelaPreco.valorUnitarioBase': (
                <div className="lista-valor">
                  <InputTabelaDecimal
                    style={{
                      backgroundColor: 'transparent',
                      border: 0,
                      color: 'black',
                      opacity: 1,
                      textAlign: 'end',
                    }}
                    valorPadrao={
                      data.produtoRegraEscolhaTabelaPreco.length > 0
                        ? data.produtoRegraEscolhaTabelaPreco[0]
                            .valorUnitarioBase
                        : 0
                    }
                    obterRef={(instance) => {
                      if (
                        instance &&
                        instance.current &&
                        listaValorUnitarioBaseRef.current
                      ) {
                        listaValorUnitarioBaseRef.current[index] =
                          instance.current;
                        listaValorUnitarioBaseRef.current[index].value = Number(
                          data.produtoRegraEscolhaTabelaPreco[0]
                            .valorUnitarioBase
                        ).FormatarParaPtBr({
                          maximoCasasDecimais: 10,
                          minimoCasasDecimais: 10,
                        });
                      }
                    }}
                    casasDecimais={10}
                    disabled
                  />
                </div>
              ),
              'produtoRegraEscolhaTabelaPreco.regraEscolhaTabelaPreco.origemValoresTabela':
                (
                  <div className="lista-texto">
                    <div>
                      <div className="lista-texto">
                        {data.produtoRegraEscolhaTabelaPreco.length > 0
                          ? data.produtoRegraEscolhaTabelaPreco[0]
                              .regraEscolhaTabelaPreco.origemValoresTabela
                          : ''}
                      </div>
                      {data.produtoRegraEscolhaTabelaPreco[0]
                        .regraEscolhaTabelaPreco.origemValoresTabela ===
                        OrigemValorBaseTabelaPrecoEnum.custoPadraoProduto && (
                        <div
                          className="lista-texto"
                          style={{ fontWeight: 'bold' }}
                        >
                          {`(${data.produtoCusto.tipoCusto})`}
                        </div>
                      )}
                    </div>
                  </div>
                ),

              'produtoCusto.valorUnitarioCustoReposicao': (
                <div className="lista-valor">
                  {data.exibirRegistro && (
                    <InputTabelaDecimal
                      valorPadrao={
                        data.produtoCusto?.valorUnitarioCustoReposicao || 0
                      }
                      obterRef={(instance) => {
                        if (
                          instance &&
                          instance.current &&
                          listaValorUnitarioCustoReposicaoRef.current
                        ) {
                          listaValorUnitarioCustoReposicaoRef.current[index] =
                            instance.current;
                          listaValorUnitarioCustoReposicaoRef.current[
                            index
                          ].value = Number(
                            data.produtoCusto.valorUnitarioCustoReposicao
                          ).FormatarParaPtBr({
                            maximoCasasDecimais: 10,
                            minimoCasasDecimais: 10,
                          });
                        }
                      }}
                      casasDecimais={10}
                      onKeyDown={(e) => {
                        const controleFocus = handleControlarFocus({
                          index,
                          nomeCampo: 'produtoCusto.valorUnitarioCustoReposicao',
                          listaTh: listaColunas,
                          listaItens: listaValor,
                        });

                        if (!controleFocus) return;

                        const {
                          linhaIndexAnterior,
                          linhaIndexProxima,
                          listaRefAnterior,
                          listaRefProxima,
                        } = controleFocus;

                        if (e.shiftKey && e.key === 'Tab') {
                          e.preventDefault();
                          listaRefAnterior?.current[
                            linhaIndexAnterior
                          ]?.focus();
                        } else if (e.key === 'Enter') {
                          e.preventDefault();

                          let proximo = index;

                          do {
                            proximo += 1;
                            if (proximo >= listaValor.length) proximo = 0;
                          } while (
                            !listaValorUnitarioCustoReposicaoRef.current?.[
                              proximo
                            ]
                          );

                          listaValorUnitarioCustoReposicaoRef.current?.[
                            proximo
                          ]?.focus();
                        } else if (e.key === 'Tab') {
                          e.preventDefault();
                          listaRefProxima?.current[linhaIndexProxima]?.focus();
                        }
                      }}
                      onChange={(event) => {
                        // #region Selecionar Item

                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }

                        // #endregion Selecionar Item

                        const valorUnitarioCustoReposicao =
                          event.currentTarget.value
                            .ConverterParaNumber()
                            .FormatarParaPtBr({
                              minimoCasasDecimais: 10,
                              maximoCasasDecimais: 10,
                            });

                        for (
                          let indice = 0;
                          indice < listaDados.length;
                          indice++
                        ) {
                          const item = listaDados[indice];

                          if (
                            item.id === data.id &&
                            (item.produtoRegraEscolhaTabelaPreco[0]
                              .regraEscolhaTabelaPreco.origemValoresTabela ===
                              OrigemValorBaseTabelaPrecoEnum.custoReposicao ||
                              (item.produtoRegraEscolhaTabelaPreco[0]
                                .regraEscolhaTabelaPreco.origemValoresTabela ===
                                OrigemValorBaseTabelaPrecoEnum.custoPadraoProduto &&
                                item.produtoCusto.tipoCusto ===
                                  TipoCustoEnum.reposicao))
                          ) {
                            if (listaValorUnitarioBaseRef.current) {
                              listaValorUnitarioBaseRef.current[indice].value =
                                valorUnitarioCustoReposicao;
                            }

                            // #region Selecionar Registro

                            const registroSelecionadoRef =
                              listaItemSelecionadoRef.current?.[indice];

                            if (
                              registroSelecionadoRef &&
                              !registroSelecionadoRef.checked
                            ) {
                              registroSelecionadoRef.checked = true;
                              handleAlterouSelecionado();
                            }

                            // #endregion Selecionar Registro

                            handleOnAlterarValorUnitarioBase(
                              listaDados,
                              indice
                            );
                          }
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'produtoEngenharia.codigoBarras': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabela
                      valorPadrao={data.produtoEngenharia.codigoBarras}
                      maxLength={14}
                      obterRef={(instance) => {
                        if (instance.current && listaCodigoBarrasRef.current) {
                          listaCodigoBarrasRef.current[index] =
                            instance.current;
                          listaCodigoBarrasRef.current[index].value = String(
                            data.produtoEngenharia.codigoBarras
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        const controleFocus = handleControlarFocus({
                          index,
                          nomeCampo: 'produtoEngenharia.codigoBarras',
                          listaTh: listaColunas,
                          listaItens: listaValor,
                        });

                        if (!controleFocus) return;

                        const {
                          linhaIndexAnterior,
                          linhaIndexProxima,
                          listaRefAnterior,
                          listaRefProxima,
                        } = controleFocus;

                        if (e.shiftKey && e.key === 'Tab') {
                          e.preventDefault();
                          listaRefAnterior?.current[
                            linhaIndexAnterior
                          ]?.focus();
                        } else if (e.key === 'Enter') {
                          e.preventDefault();

                          let proximo = index;

                          do {
                            proximo += 1;
                            if (proximo >= listaValor.length) proximo = 0;
                          } while (!listaCodigoBarrasRef.current?.[proximo]);

                          listaCodigoBarrasRef.current?.[proximo]?.focus();
                        } else if (e.key === 'Tab') {
                          e.preventDefault();
                          listaRefProxima?.current[linhaIndexProxima]?.focus();
                        }
                      }}
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              'produtoFiscal.ncm': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabelaAutoCompleteNcm
                      style={{ height: 25 }}
                      obterRef={(instance) => {
                        if (instance.current && listaNcmRef.current) {
                          listaNcmRef.current[index] = instance.current;
                          listaNcmRef.current[index].selecionarItemSemEvento(
                            data.produtoFiscal.ncm
                          );
                        }

                        if (
                          listaCestRef.current &&
                          listaCestRef.current[index]
                        ) {
                          listaCestRef.current[index].setIdNcm(
                            data.produtoFiscal.ncm?.id ?? ''
                          );
                        }
                      }}
                      onChangeItemAtual={(item) => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }

                        if (
                          listaCestRef.current &&
                          listaCestRef.current[index]
                        ) {
                          listaCestRef.current[
                            index
                          ].autoCompleteRef.current?.selecionarItem({});
                          listaCestRef.current[index].setIdNcm(
                            item?.itemAtual?.id ?? ''
                          );
                        }
                      }}
                    />
                  )}
                </div>
              ),
              'produtoFiscal.cest': (
                <div className="lista-texto">
                  {data.exibirRegistro && (
                    <InputTabelaAutoCompleteCest
                      style={{ height: 25 }}
                      obterRef={(instance, setIdNcm) => {
                        if (instance.current && listaCestRef.current) {
                          listaCestRef.current[index] = {
                            autoCompleteRef: instance,
                            setIdNcm,
                          };

                          listaCestRef.current[
                            index
                          ].autoCompleteRef.current?.selecionarItemSemEvento(
                            data.produtoFiscal.cest
                          );
                        }
                      }}
                      onChangeItemAtual={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),

              ativo: (
                <div
                  className="lista-booleano"
                  style={{ color: data.ativo ? 'green' : 'red' }}
                >
                  {data.exibirRegistro && (
                    <InputTabelaSwitch
                      valorPadrao={data.ativo}
                      obterRef={(instance) => {
                        if (
                          instance &&
                          instance.current &&
                          listaAtivoRef.current
                        ) {
                          listaAtivoRef.current[index] = instance.current;
                          listaAtivoRef.current[index].value = data.ativo
                            ? 'true'
                            : 'false';
                        }
                      }}
                      ativo="Sim"
                      inativo="Não"
                      onChange={() => {
                        const itemSelecionadoRef =
                          listaItemSelecionadoRef.current?.[index];

                        if (itemSelecionadoRef && !itemSelecionadoRef.checked) {
                          itemSelecionadoRef.checked = true;
                          handleAlterouSelecionado();
                        }
                      }}
                    />
                  )}
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dadosRecuperados,
      handleAlterouSelecionado,
      handleControlarFocus,
      setListaValor,
      listaValor.length,
      jaRecuperouDados,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosAtualizacaoPrecoCustoProduto' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (jaRecuperouDados) return true;

    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );

    if (itensSelecionados?.length !== 0) {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <div>
            <div style={{ fontSize: 20 }}>
              Atenção, caso clique em OK as alterações não serão salvas!
            </div>
            <div style={{ fontSize: 20 }}>
              Deseja continuar sem salvar as alterações?
            </div>
          </div>
        ),
      });

      if (resposta) {
        if (selecionarTodosRef.current)
          selecionarTodosRef.current.checked = false;

        handleSelecionarTodos(false);
      }

      return resposta;
    }

    return true;
  }, [
    abrirJanela,
    handleSelecionarTodos,
    listaItemSelecionadoRef,
    jaRecuperouDados,
  ]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Atualização de Preço e Custo dos Produtos"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          permitirAtualizar={handlePermitirAtualizar}
          botoes={
            <>
              <AcoesAtualizacaoPrecoCustoProduto
                loading={loading}
                setLoading={setLoading}
                possuiItemSelecionado={possuiItemSelecionado}
                handleObterItensSelecionados={
                  handleObterItensSelecionadosParaImpressao
                }
                handleSalvar={handleClickSalvar}
              />
              <BtnContainer>
                <button
                  type="button"
                  onClick={handleClickSalvar}
                  className="btn-padrao btn-verde"
                  disabled={
                    loading || !possuiItemSelecionado || !permissao?.altera
                  }
                >
                  <TextoLoadingSalvar loading={loading} />
                </button>
              </BtnContainer>
            </>
          }
        />
        <PesquisaAvancada permitirAtualizar={handlePermitirAtualizar} />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          permitirAtualizar={handlePermitirAtualizar}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
        />
      </Container>
    </>
  );
};

export default AtualizacaoPrecoCustoProdutoLista;
