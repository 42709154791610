import React, { useState, useCallback } from 'react';
import { Table, Col, Button, Row } from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import { v4 } from 'uuid';
import { Container } from './styles';
import ISelecaoItemEstoqueLocalEstoqueDetalhe from './Interface/ISelecaoItemEstoqueLocalEstoqueDetalhe';
import JanelaDetalhe from '../../../../../../../../../../Componentes/JanelaDetalhe';
import { Tabela } from '../../../../../../../../../../Componentes/Tabela/styles';
import { TBody } from '../../../../../../../../../../Componentes/Styles/TBody';
import ISelecaoItemEstoqueLocalEstoque from './Interface/ISelecaoItemEstoqueLocalEstoque';

const SelecaoItemEstoqueLocalEstoqueDetalhe: React.FC<
  ISelecaoItemEstoqueLocalEstoqueDetalhe
> = ({ onSelecionarLocalEstoque, onFecharFormModal, listaLocalEstoque }) => {
  const [selecionadoIndex, setSelecionadoIndex] = useState(0);

  const handleSubmit = useCallback(async () => {
    const itemSelecionado = listaLocalEstoque[
      selecionadoIndex
    ] as ISelecaoItemEstoqueLocalEstoque;
    onSelecionarLocalEstoque(itemSelecionado);
    onFecharFormModal();
  }, [
    listaLocalEstoque,
    onFecharFormModal,
    onSelecionarLocalEstoque,
    selecionadoIndex,
  ]);

  return (
    <JanelaDetalhe
      titulo="Selecione o Local de Estoque"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <Container>
        <Tabela style={{ overflow: 'auto', maxHeight: 500 }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th style={{ width: '5%' }}>
                  <span />
                </th>
                <th className="lista-texto" style={{ width: '40%' }}>
                  <span>Descrição</span>
                </th>
                <th className="lista-valor" style={{ width: '30%' }}>
                  <span>Saldo</span>
                </th>
                <th className="lista-data" style={{ width: '25%' }}>
                  <span>Data</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaLocalEstoque.map((localEstoque, index) => {
                return (
                  <tr
                    key={v4()}
                    onClick={(event) => {
                      const input = event.currentTarget.querySelector('input');
                      input?.click();
                    }}
                  >
                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <input
                          type="radio"
                          name="origem"
                          checked={index === selecionadoIndex}
                          onChange={() => {
                            setSelecionadoIndex(index);
                          }}
                        />
                      </div>
                    </td>

                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <span>
                          <strong>{localEstoque.descricao}</strong>
                          <br />
                          <span>{localEstoque.associacao}</span>
                        </span>
                      </div>
                    </td>

                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-valor`}
                    >
                      <div>
                        <span>
                          {Number(localEstoque.saldo).FormatarParaPtBr()}
                        </span>
                      </div>
                    </td>

                    <td
                      className={`${
                        selecionadoIndex === index ? 'selecionado ' : ''
                      }lista-data`}
                    >
                      <div>
                        <span>
                          {FormatarDataHoraCompletaParaPtBr(
                            localEstoque.dataHoraSaldo
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>

        <Row>
          <Col lg={12}>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              className="espacamento-interno-para-esquerda-15"
            >
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={() => {
                  onFecharFormModal();
                }}
              >
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSubmit}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </JanelaDetalhe>
  );
};

export default SelecaoItemEstoqueLocalEstoqueDetalhe;
