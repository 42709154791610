import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';

const InformacaoFiscoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 350 }}
              label=""
              name="informacoesInteresseFisco"
              maxLength={2000}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default InformacaoFiscoTab;
