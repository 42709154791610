import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/parametro';

interface IParametroComunicadorIndex {
  params: any;
}

class ParametroComunicador {
  public async index(params?: IParametroComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params?.params });

    return response.data;
  }
}

export default new ParametroComunicador();
