import React, { useEffect, useRef, useState } from 'react';
import { IContaParcelaLiquidacaoCategoriaLista } from '@elogestor/util';
import { Table } from 'react-bootstrap';
import { UseFieldCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { Tabela } from '../../../../../../../../../../../../Componentes/Styles/Tabela';
import Divisor from '../../../../../../../../../../../../Componentes/Divisor';

interface IListaDetalheComercialRepresentanteProps {
  name: string;
}

const ListaCategoria: React.FC<IListaDetalheComercialRepresentanteProps> = ({
  name,
}) => {
  const valorRef = useRef<IContaParcelaLiquidacaoCategoriaLista[]>([]);

  const { fieldName, registerField } = UseFieldCia(name);

  const [listaValor, setListaValor] = useState<
    IContaParcelaLiquidacaoCategoriaLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IContaParcelaLiquidacaoCategoriaLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) {
          setListaValor([]);
        } else {
          setListaValor(value);
        }
      },

      setSemExecutarEvento(_, value) {
        if (!value) {
          setListaValor([]);
        } else {
          setListaValor(value);
        }
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Divisor>
      <Tabela style={{ overflow: 'auto', marginTop: 12 }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '40%' }}>
                <span>Categoria</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Tipo</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Valor</span>
              </th>
              <th className="lista-booleano" style={{ width: '20%' }}>
                <span>Obtida por Regra</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor &&
              listaValor.map((item, index) => {
                return (
                  <tr key={v4()}>
                    <td className="lista-texto">
                      <div>{`${item.categoria.codigo} - ${item.categoria.descricao}`}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.tipoLancamento}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valor).FormatarParaPtBr({
                          maximoCasasDecimais: 2,
                          minimoCasasDecimais: 2,
                        })}
                      </div>
                    </td>
                    <td
                      className="lista-booleano"
                      style={{ color: item.obtidaPorRegra ? 'green' : 'red' }}
                    >
                      {item.obtidaPorRegra ? 'Sim' : 'Não'}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Tabela>
    </Divisor>
  );
};

export default ListaCategoria;
