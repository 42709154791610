import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/duplicar';

interface INotaFiscalSaidaDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class NotaFiscalSaidaDuplicarComunicador {
  public async store(
    params: INotaFiscalSaidaDuplicarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new NotaFiscalSaidaDuplicarComunicador();
