import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/obter-permissoes';

class UsuarioPermissoesComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new UsuarioPermissoesComunicador();
