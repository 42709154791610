import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  TipoContaEnum,
} from '@elogestor/util';
import { BiMailSend } from 'react-icons/bi/index.mjs';
import JSZip from 'jszip';
import * as Yup from 'yup';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import { UseAuth } from '../../../../../../Hooks/Auth';
import EstoqueSaldoProprioTerceiroRelatorio from '../../../../../Suprimentos/Relatorios/Estoques/Template/EstoqueSaldoProprioTerceiroRelatorio';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import NotaFiscalSaidaTotalPorModeloDocumentoRelatorio from '../../../../../Comercial/Relatorios/Vendas/NotaFiscalSaida/Template/NotaFiscalSaidaTotalPorModeloDocumentoRelatorio';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ConhecimentoTransportePorTomadorRelatorio from '../../../../../Transporte/Relatorios/ConhecimentoTransporte/Template/ConhecimentoTransportePorTomadorRelatorio';
import ManifestoTransporteResumoRelatorio from '../../../../../Transporte/Relatorios/ManifestoTransporte/Template/ManifestoTransporteResumoRelatorio';
import NotaFiscalSaidaResumoRelatorio from '../../../../../Comercial/Relatorios/Vendas/NotaFiscalSaida/Template/NotaFiscalSaidaResumoRelatorio';
import NotaFiscalSaidaResumoPorCfopRelatorio from '../../../../../Comercial/Relatorios/Vendas/NotaFiscalSaida/Template/NotaFiscalSaidaResumoPorCfopRelatorio';
import NotaFiscalSaidaPorCfopCsosnCstIcmsRelatorio from '../../../../../Comercial/Relatorios/Vendas/NotaFiscalSaida/Template/NotaFiscalSaidaPorCfopCsosnCstIcmsRelatorio';
import ContasReceberPagarParcelaPorEmissaoRelatorio from '../../../../../Financeiro/Relatorios/Movimentacoes/ContasReceberPagar/Template/ContasReceberPagarParcelasPorEmissaoRelatorio';
import GeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicador';
import GeracaoArquivosContabeisNfeCteTerceirosBaixarCompactadoComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisNfeCteTerceirosBaixarCompactadoComunicador';
import GeracaoArquivosContabeisSpedFiscalComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisSpedFiscalComunicador';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import GeracaoArquivosContabeisSisdevinComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisSisdevinComunicador';
import NotaFiscalEntradaResumoRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaResumoRelatorio';
import NotaFiscalEntradaResumoPorCfopRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaResumoPorCfopRelatorio';
import NotaFiscalEntradaPorFornecedorRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaPorFornecedorRelatorio';
import NotaFiscalEntradaPorCfopCsosnCstIcmsRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaPorCfopCsosnCstIcmsRelatorio';
import NotaFiscalEntradaPorProdutoRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaPorProdutoRelatorio';
import NotaFiscalEntradaTotalPorModeloDocumentoRelatorio from '../../../../../Suprimentos/Relatorios/Compras/NotaFiscalEntrada/Template/NotaFiscalEntradaTotalPorModeloDocumentoRelatorio';
import DocumentosEntradaPorCfopRelatorio from '../../../../../Suprimentos/Relatorios/Compras/DocumentosEntrada/Template/DocumentosEntradaPorCFOP';
import GeracaoArquivosContabeisXmlPdfConhecimentoTransporteEmitidosComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/GeracaoArquivosContabeis/Comunicador/GeracaoArquivosContabeisXmlPdfConhecimentoTransporteEmitidosComunicador';
import EstoqueSaldoProprioEmPoderProprioComCustoRelatorio from '../../../../../Suprimentos/Relatorios/Estoques/Template/EstoqueSaldoProprioEmPoderProprioComCustoRelatorio';
import { UseSalvarPDF } from '../../../../../DocumentosEletronicos/SalvarPDF/SalvarPDFHook';

const AcoesGeracaoArquivosContabeis: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { loading, setLoading, formRef } = UseForm();
  const { empresa } = UseAuth();
  const { salvarPDF } = UseSalvarPDF();

  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const ValidarCheckList = useCallback((): boolean => {
    const data = formRef.current?.getData() as any;
    if (!data) return false;
    if (
      !data.xmlNfeTerceiros &&
      !data.xmlNfePropriaEmitidas &&
      !data.danfeNfeTerceiros &&
      !data.danfeNfePropriaPdf &&
      !data.xmlCteTerceiros &&
      !data.incluirNfePropriaCanceladas &&
      !data.incluirNfeCanceladasTerceiros &&
      !data.incluirCteCanceladasTerceiros &&
      !data.dacteCteTerceiros &&
      !data.relEstoqueSaldoProprioTerceiro &&
      !data.relEstoqueSaldoProprioPoderProprioComCusto &&
      !data.relEstoqueSaldoProprioPoderProprioComCustoTipoCusto &&
      !data.relNotaFiscalSaidaResumo &&
      !data.relNotaFiscalSaidaTotalModeloDocumento &&
      !data.relNotaFiscalSaidaResumoPorCfop &&
      !data.relNotaFiscalSaidaPorCfopCsosnCst &&
      !data.relConhecimentoTransporteEmitidosTomador &&
      !data.relManifestoTransporteResumo &&
      !data.relContasReceberParcelaPorEmissao &&
      !data.relContasPagarParcelaPorEmissao &&
      !data.spedFiscal &&
      !data.spedFiscalCompleto &&
      !data.spedFiscalTodosProdutos &&
      !data.spedFiscalComBlocoH &&
      !data.sisdevinComunicacaoPerdas &&
      !data.sisdevinCortes &&
      !data.sisdevinDeclaracaoEstoque &&
      !data.sisdevinDeclaracaoProducao &&
      !data.sisdevinEngarrafadoseGranel &&
      !data.sisdevinEnologicos &&
      !data.sisdevinNotasCompraUva &&
      !data.relNotaFiscalEntradaResumo &&
      !data.relNotaFiscalEntradaResumoPorCfop &&
      !data.relNotaFiscalEntradaPorFornecedor &&
      !data.relNotaFiscalEntradaPorCfopCsosnCst &&
      !data.relNotaFiscalEntradaPorProduto &&
      !data.relNotaFiscalEntradaTotalModeloDocumento &&
      !data.relDocumentosEntradaPorCfop &&
      !data.xmlCteProprioEmitidao &&
      !data.dacteCteProprioPdf &&
      !data.incluirCteProprioCancelados &&
      !data.relNotaFiscalSaidaPorCfopCsosnCstPlanilha &&
      !data.relDocumentosEntradaPorCfopPlanilha
    ) {
      return false;
    }

    return true;
  }, [formRef]);

  const handleObterXmlPdfEmitidos = useCallback(async (): Promise<any> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    const xmlNfePropriaEmitidas = formRef.current?.getFieldValue(
      'xmlNfePropriaEmitidas'
    );
    const danfeNfePropriaPdf =
      formRef.current?.getFieldValue('danfeNfePropriaPdf');

    const incluirNfePropriaCanceladas = formRef.current?.getFieldValue(
      'incluirNfePropriaCanceladas'
    );

    if (
      xmlNfePropriaEmitidas ||
      danfeNfePropriaPdf ||
      incluirNfePropriaCanceladas
    ) {
      const response =
        await GeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicador.show(
          {
            params: {
              dataEmissaoInicial,
              dataEmissaoFinal,
              danfeNfePropriaPdf,
              xmlNfePropriaEmitidas,
              incluirNfePropriaCanceladas,
            },
          }
        );

      if (response) {
        zip = {
          nomeArquivo: `Xml Pdf Emitidos.zip`,
          file: response,
          permitirExcluir: false,
        };
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Xml e Pdf Emitidos foi Encontrado!'
        );
      }
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterXmlPdfTerceiro = useCallback(async (): Promise<{
    zip: any | undefined;
    listaMensagem: string[];
  }> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    const xmlNfeTerceiros = formRef.current?.getFieldValue('xmlNfeTerceiros');

    const incluirNfeCanceladasTerceiros = formRef.current?.getFieldValue(
      'incluirNfeCanceladasTerceiros'
    );

    const danfeNfeTerceiros =
      formRef.current?.getFieldValue('danfeNfeTerceiros');

    const xmlCteTerceiros = formRef.current?.getFieldValue('xmlCteTerceiros');

    const incluirCteCanceladasTerceiros = formRef.current?.getFieldValue(
      'incluirCteCanceladasTerceiros'
    );
    const dacteCteTerceiros =
      formRef.current?.getFieldValue('dacteCteTerceiros');

    if (
      xmlNfeTerceiros ||
      danfeNfeTerceiros ||
      incluirNfeCanceladasTerceiros ||
      xmlCteTerceiros ||
      dacteCteTerceiros ||
      incluirCteCanceladasTerceiros
    ) {
      const response =
        await GeracaoArquivosContabeisNfeCteTerceirosBaixarCompactadoComunicador.show(
          {
            params: {
              dataEmissaoFinal,
              dataEmissaoInicial,
              xmlNfeTerceiros,
              danfeNfeTerceiros,
              xmlCteTerceiros,
              dacteCteTerceiros,
              incluirCteCanceladasTerceiros,
              incluirNfeCanceladasTerceiros,
            },
          }
        );

      if (response.size !== 0) {
        zip = {
          nomeArquivo: `Xml Pdf Terceiro.zip`,
          file: response,
          permitirExcluir: false,
        };
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Xml e Pdf Terceiros foi Encontrado!'
        );
      }
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterXmlPdfConhecimentoTransporteEmitidos =
    useCallback(async (): Promise<{
      zip: any | undefined;
      listaMensagem: string[];
    }> => {
      let zip: any | undefined;
      const listaMensagem: string[] = [];

      const dataEmissaoInicial =
        formRef.current?.getFieldValue('dataEmissaoInicial');

      const dataEmissaoFinal =
        formRef.current?.getFieldValue('dataEmissaoFinal');

      const xmlCteProprioEmitido = formRef.current?.getFieldValue(
        'xmlCteProprioEmitido'
      );
      const dacteCteProprioPdf =
        formRef.current?.getFieldValue('dacteCteProprioPdf');

      const incluirCteProprioCancelados = formRef.current?.getFieldValue(
        'incluirCteProprioCancelados'
      );

      const response =
        await GeracaoArquivosContabeisXmlPdfConhecimentoTransporteEmitidosComunicador.show(
          {
            params: {
              dataEmissaoInicial,
              dataEmissaoFinal,
              xmlCteProprioEmitido,
              dacteCteProprioPdf,
              incluirCteProprioCancelados,
            },
          }
        );

      if (response.size !== 0) {
        zip = {
          nomeArquivo: `Xml Pdf Cte.zip`,
          file: response,
          permitirExcluir: false,
        };
      } else {
        listaMensagem.push('Nenhum Arquivo de Xml e Pdf CTE foi Encontrado!');
      }

      return { zip, listaMensagem };
    }, [formRef]);

  const handleObterRelatoriosEstoque = useCallback(async (): Promise<{
    zip: any | undefined;
    listaMensagem: string[];
  }> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipEstoques = new JSZip();

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    const relEstoqueSaldoProprioTerceiro = formRef.current?.getFieldValue(
      'relEstoqueSaldoProprioTerceiro'
    );

    const relEstoqueSaldoProprioPoderProprioComCusto =
      formRef.current?.getFieldValue(
        'relEstoqueSaldoProprioPoderProprioComCusto'
      );

    const relEstoqueSaldoProprioPoderProprioComCustoTipoCusto =
      formRef.current?.getFieldValue(
        'relEstoqueSaldoProprioPoderProprioComCustoTipoCusto'
      );

    if (relEstoqueSaldoProprioTerceiro) {
      const blobEstoqueSaldoProprioTerceiroRelatorio =
        await EstoqueSaldoProprioTerceiroRelatorio.handleObterBlobPdf({
          data: dataEmissaoFinal,
          somenteProdutosComSaldo: true,
        });

      if (blobEstoqueSaldoProprioTerceiroRelatorio) {
        zipEstoques.file(
          'Estoque Saldo Proprio Terceiro.pdf',
          blobEstoqueSaldoProprioTerceiroRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push('Nenhum Arquivo de Estoque foi Encontrado!');
      }
    }

    if (relEstoqueSaldoProprioPoderProprioComCusto) {
      const blobEstoqueSaldoProprioPoderProprioComCustoRelatorio =
        await EstoqueSaldoProprioEmPoderProprioComCustoRelatorio.handleObterBlobPdf(
          {
            data: dataEmissaoFinal,
            tipoCusto: relEstoqueSaldoProprioPoderProprioComCustoTipoCusto,
            somenteProdutosComSaldo: true,
          }
        );

      if (blobEstoqueSaldoProprioPoderProprioComCustoRelatorio) {
        zipEstoques.file(
          'Estoque Saldo Proprio Em Poder Proprio Com Custo.pdf',
          blobEstoqueSaldoProprioPoderProprioComCustoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push('Nenhum Arquivo de Estoque foi Encontrado!');
      }
    }

    if (Object.keys(zipEstoques.files).length > 0) {
      //  Pesquisar forma de verificar vazio do JSzip
      const estoqueZip = await zipEstoques.generateAsync({ type: 'blob' });

      zip = {
        nomeArquivo: `Relatorios Estoque.zip`,
        file: estoqueZip,
        permitirExcluir: false,
      };
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterRelatorioNotaFiscalSaida = useCallback(async (): Promise<{
    zip: any | undefined;
    listaMensagem: string[];
  }> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipNotaFiscalSaida = new JSZip();

    const relNotaFiscalSaidaResumo = formRef.current?.getFieldValue(
      'relNotaFiscalSaidaResumo'
    );

    const relNotaFiscalSaidaPorCfopCsosnCstPlanilha =
      formRef.current?.getFieldValue(
        'relNotaFiscalSaidaPorCfopCsosnCstPlanilha'
      );

    const relNotaFiscalSaidaTotalModeloDocumento =
      formRef.current?.getFieldValue('relNotaFiscalSaidaTotalModeloDocumento');

    const relNotaFiscalSaidaResumoPorCfop = formRef.current?.getFieldValue(
      'relNotaFiscalSaidaResumoPorCfop'
    );

    const relNotaFiscalSaidaPorCfopCsosnCst = formRef.current?.getFieldValue(
      'relNotaFiscalSaidaPorCfopCsosnCst'
    );

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    if (relNotaFiscalSaidaResumo) {
      const blobNotaFiscalSaidaResumoRelatorio =
        await NotaFiscalSaidaResumoRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
          listaSituacaoItem: {
            listaAcao: [],
            listaValor: [
              {
                descricao: 'Autorizada',
              },
            ],
          },
        });

      if (blobNotaFiscalSaidaResumoRelatorio) {
        zipNotaFiscalSaida.file(
          'Nota Fiscal (Saida) Resumo.pdf',
          blobNotaFiscalSaidaResumoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (relNotaFiscalSaidaTotalModeloDocumento) {
      const blobNotaFiscalSaidaTotalModeloDocumentoRelatorio =
        await NotaFiscalSaidaTotalPorModeloDocumentoRelatorio.handleObterBlobPdf(
          {
            dataEmissaoInicial,
            dataEmissaoFinal,
            listaSituacaoItem: {
              listaAcao: [],
              listaValor: [
                {
                  descricao: 'Autorizada',
                },
              ],
            },
          }
        );

      if (blobNotaFiscalSaidaTotalModeloDocumentoRelatorio) {
        zipNotaFiscalSaida.file(
          'Nota Fiscal (Saída) Total por Modelo Documento.pdf',
          blobNotaFiscalSaidaTotalModeloDocumentoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (relNotaFiscalSaidaResumoPorCfop) {
      const blobNotaFiscalSaidaResumoPorCfopRelatorio =
        await NotaFiscalSaidaResumoPorCfopRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
          listaSituacaoItem: {
            listaAcao: [],
            listaValor: [
              {
                descricao: 'Autorizada',
              },
            ],
          },
        });

      if (blobNotaFiscalSaidaResumoPorCfopRelatorio) {
        zipNotaFiscalSaida.file(
          'Nota Fiscal (Saída) Resumo Por CFOP.pdf',
          blobNotaFiscalSaidaResumoPorCfopRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (relNotaFiscalSaidaPorCfopCsosnCst) {
      const blobNotaFiscalSaidaPorCfopCsosnCstRelatorio =
        await NotaFiscalSaidaPorCfopCsosnCstIcmsRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
          listaSituacaoItem: {
            listaAcao: [],
            listaValor: [
              {
                descricao: 'Autorizada',
              },
            ],
          },
        });

      if (blobNotaFiscalSaidaPorCfopCsosnCstRelatorio) {
        zipNotaFiscalSaida.file(
          'Nota Fiscal (Saída) Por CFOP e CSOSN CST.pdf',
          blobNotaFiscalSaidaPorCfopCsosnCstRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (relNotaFiscalSaidaPorCfopCsosnCstPlanilha) {
      const blobNotaFiscalSaidaPorCfopCsosnCstPlanilhaRelatorio =
        await NotaFiscalSaidaPorCfopCsosnCstIcmsRelatorio.handleObterBlobPlanilha(
          {
            dataEmissaoInicial,
            dataEmissaoFinal,
            listaSituacaoItem: {
              listaAcao: [],
              listaValor: [
                {
                  descricao: 'Autorizada',
                },
              ],
            },
          }
        );

      if (blobNotaFiscalSaidaPorCfopCsosnCstPlanilhaRelatorio) {
        zipNotaFiscalSaida.file(
          'Nota Fiscal (Saída) Por CFOP e CSOSN CST Planilha.xlsx',
          blobNotaFiscalSaidaPorCfopCsosnCstPlanilhaRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (Object.keys(zipNotaFiscalSaida.files).length > 0) {
      const notaFiscalZip = await zipNotaFiscalSaida.generateAsync({
        type: 'blob',
      });

      zip = {
        nomeArquivo: 'Relatórios Nota Fiscal(Saída).zip',
        file: notaFiscalZip,
        permitirExcluir: false,
      };
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterRelatorioDocumentosEntrada = useCallback(async (): Promise<{
    zip: any | undefined;
    listaMensagem: string[];
  }> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipDocumentosEntrada = new JSZip();

    const relDocumentosEntradaPorCfop = formRef.current?.getFieldValue(
      'relDocumentosEntradaPorCfop'
    );

    const relDocumentosEntradaPorCfopPlanilha = formRef.current?.getFieldValue(
      'relDocumentosEntradaPorCfopPlanilha'
    );

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    if (relDocumentosEntradaPorCfop) {
      const blobDocumentosEntradaPorCfopRelatorio =
        await DocumentosEntradaPorCfopRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobDocumentosEntradaPorCfopRelatorio) {
        zipDocumentosEntrada.file(
          'Documentos Entrada por CFOP.pdf',
          blobDocumentosEntradaPorCfopRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (relDocumentosEntradaPorCfopPlanilha) {
      const blobDocumentosEntradaPorCfopPlanilhaRelatorio =
        await DocumentosEntradaPorCfopRelatorio.handleObterBlobPlanilha({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobDocumentosEntradaPorCfopPlanilhaRelatorio) {
        zipDocumentosEntrada.file(
          'Documentos Entrada por CFOP Planilha.xlsx',
          blobDocumentosEntradaPorCfopPlanilhaRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Saída foi encontrado!'
        );
      }
    }

    if (Object.keys(zipDocumentosEntrada.files).length > 0) {
      const documentosEntradaZip = await zipDocumentosEntrada.generateAsync({
        type: 'blob',
      });

      zip = {
        nomeArquivo: 'Relatórios Documentos Entrada.zip',
        file: documentosEntradaZip,
        permitirExcluir: false,
      };
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterRelatorioNotaFiscalEntrada = useCallback(async (): Promise<{
    zip: any | undefined;
    listaMensagem: string[];
  }> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipNotaFiscalEntrada = new JSZip();

    const relNotaFiscalEntradaResumo = formRef.current?.getFieldValue(
      'relNotaFiscalEntradaResumo'
    );

    const relNotaFiscalEntradaResumoPorCfop = formRef.current?.getFieldValue(
      'relNotaFiscalEntradaResumoPorCfop'
    );

    const relNotaFiscalEntradaPorFornecedor = formRef.current?.getFieldValue(
      'relNotaFiscalEntradaPorFornecedor'
    );

    const relNotaFiscalEntradaPorCfopCsosnCst = formRef.current?.getFieldValue(
      'relNotaFiscalEntradaPorCfopCsosnCst'
    );

    const relNotaFiscalEntradaPorProduto = formRef.current?.getFieldValue(
      'relNotaFiscalEntradaPorProduto'
    );

    const relNotaFiscalEntradaTotalModeloDocumento =
      formRef.current?.getFieldValue(
        'relNotaFiscalEntradaTotalModeloDocumento'
      );

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');

    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    if (relNotaFiscalEntradaResumo) {
      const blobNotaFiscalEntradaResumoRelatorio =
        await NotaFiscalEntradaResumoRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobNotaFiscalEntradaResumoRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Resumo.pdf',
          blobNotaFiscalEntradaResumoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (relNotaFiscalEntradaResumoPorCfop) {
      const blobNotaFiscalEntradaResumoPorCfopRelatorio =
        await NotaFiscalEntradaResumoPorCfopRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobNotaFiscalEntradaResumoPorCfopRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Resumo Por CFOP.pdf',
          blobNotaFiscalEntradaResumoPorCfopRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (relNotaFiscalEntradaPorFornecedor) {
      const blobNotaFiscalEntradaPorFornecedorRelatorio =
        await NotaFiscalEntradaPorFornecedorRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobNotaFiscalEntradaPorFornecedorRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Por Fornecedor.pdf',
          blobNotaFiscalEntradaPorFornecedorRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (relNotaFiscalEntradaPorCfopCsosnCst) {
      const blobNotaFiscalEntradaPorCfopCsosnCstRelatorio =
        await NotaFiscalEntradaPorCfopCsosnCstIcmsRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobNotaFiscalEntradaPorCfopCsosnCstRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Por CFOP e CSOSN CST.pdf',
          blobNotaFiscalEntradaPorCfopCsosnCstRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (relNotaFiscalEntradaPorProduto) {
      const blobNotaFiscalEntradaPorProdutoRelatorio =
        await NotaFiscalEntradaPorProdutoRelatorio.handleObterBlobPdf({
          dataEmissaoInicial,
          dataEmissaoFinal,
        });

      if (blobNotaFiscalEntradaPorProdutoRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Por Produto.pdf',
          blobNotaFiscalEntradaPorProdutoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (relNotaFiscalEntradaTotalModeloDocumento) {
      const blobNotaFiscalEntradaTotalModeloDocumentoRelatorio =
        await NotaFiscalEntradaTotalPorModeloDocumentoRelatorio.handleObterBlobPdf(
          {
            dataEmissaoInicial,
            dataEmissaoFinal,
          }
        );

      if (blobNotaFiscalEntradaTotalModeloDocumentoRelatorio) {
        zipNotaFiscalEntrada.file(
          'Nota Fiscal (Entrada) Total por Modelo Documento.pdf',
          blobNotaFiscalEntradaTotalModeloDocumentoRelatorio,
          { binary: true }
        );
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Nota Fiscal de Entrada foi encontrado!'
        );
      }
    }

    if (Object.keys(zipNotaFiscalEntrada.files).length > 0) {
      const notaFiscalZip = await zipNotaFiscalEntrada.generateAsync({
        type: 'blob',
      });

      zip = {
        nomeArquivo: 'Relatórios Nota Fiscal(Entrada).zip',
        file: notaFiscalZip,
        permitirExcluir: false,
      };
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleObterRelatorioConhecimentoTransporte =
    useCallback(async (): Promise<{
      zip: any | undefined;
      listaMensagem: string[];
    }> => {
      let zip: any | undefined;
      const listaMensagem: string[] = [];
      const zipConhecimentoTransporte = new JSZip();

      const relConhecimentoTransporteEmitidosTomador =
        formRef.current?.getFieldValue(
          'relConhecimentoTransporteEmitidosTomador'
        );

      const dataEmissaoInicial =
        formRef.current?.getFieldValue('dataEmissaoInicial');

      const dataEmissaoFinal =
        formRef.current?.getFieldValue('dataEmissaoFinal');

      if (relConhecimentoTransporteEmitidosTomador) {
        const blobConhecimentoTransporteEmitidosTomadorRelatorio =
          await ConhecimentoTransportePorTomadorRelatorio.handleObterBlobPdf({
            dataHoraEmissaoInicial: dataEmissaoInicial,
            dataHoraEmissaoFinal: dataEmissaoFinal,
          });

        if (blobConhecimentoTransporteEmitidosTomadorRelatorio) {
          zipConhecimentoTransporte.file(
            'Conhecimento Transporte Emitidos Por Tomador Document.pdf',
            blobConhecimentoTransporteEmitidosTomadorRelatorio,
            { binary: true }
          );
        } else {
          listaMensagem.push(
            'Nenhum Arquivo de Conhecimento Transporte foi Encontrado!'
          );
        }
      }

      if (Object.keys(zipConhecimentoTransporte.files).length > 0) {
        const conhecimentoTransporteZip =
          await zipConhecimentoTransporte.generateAsync({
            type: 'blob',
          });

        zip = {
          nomeArquivo: 'Relatórios de Conhecimento Transporte.zip',
          file: conhecimentoTransporteZip,
          permitirExcluir: false,
        };
      }

      return { zip, listaMensagem };
    }, [formRef]);

  const handleObterRelatorioManifestoTransporte =
    useCallback(async (): Promise<{
      zip: any | undefined;
      listaMensagem: string[];
    }> => {
      let zip: any | undefined;
      const listaMensagem: string[] = [];
      const zipManifestoTransporte = new JSZip();

      const relManifestoTransporteResumo = formRef.current?.getFieldValue(
        'relManifestoTransporteResumo'
      );

      const dataEmissaoInicial =
        formRef.current?.getFieldValue('dataEmissaoInicial');

      const dataEmissaoFinal =
        formRef.current?.getFieldValue('dataEmissaoFinal');

      if (relManifestoTransporteResumo) {
        const blobManifestoTransporteResumoRelatorio =
          await ManifestoTransporteResumoRelatorio.handleObterBlobPdf({
            dataHoraEmissaoInicial: dataEmissaoInicial,
            dataHoraEmissaoFinal: dataEmissaoFinal,
          });

        if (blobManifestoTransporteResumoRelatorio) {
          zipManifestoTransporte.file(
            'Manifesto Transporte Por Resumo.pdf',
            blobManifestoTransporteResumoRelatorio,
            { binary: true }
          );
        } else {
          listaMensagem.push(
            'Nenhum Arquivo de Manifesto Transporte foi encontrado!'
          );
        }
      }

      if (Object.keys(zipManifestoTransporte.files).length > 0) {
        const manifestoTransporteZip =
          await zipManifestoTransporte.generateAsync({
            type: 'blob',
          });

        zip = {
          nomeArquivo: 'Relatórios de Manifesto Transporte.zip',
          file: manifestoTransporteZip,
          permitirExcluir: false,
        };
      }

      return { zip, listaMensagem };
    }, [formRef]);

  const handleObterRelatorioContasReceberPagarParcelaPorEmissao =
    useCallback(async (): Promise<{
      zip: any | undefined;
      listaMensagem: string[];
    }> => {
      let zip: any | undefined;
      const listaMensagem: string[] = [];
      const zipContasReceberPagarParcelaPorEmissao = new JSZip();

      const relContasReceberParcelaPorEmissao = formRef.current?.getFieldValue(
        'relContasReceberParcelaPorEmissao'
      );

      const relContasPagarParcelaPorEmissao = formRef.current?.getFieldValue(
        'relContasPagarParcelaPorEmissao'
      );

      const dataEmissaoInicial =
        formRef.current?.getFieldValue('dataEmissaoInicial');

      const dataEmissaoFinal =
        formRef.current?.getFieldValue('dataEmissaoFinal');

      if (relContasReceberParcelaPorEmissao) {
        const blobContasReceberParcelaPorEmissao =
          await ContasReceberPagarParcelaPorEmissaoRelatorio.handleObterBlobPdf(
            {
              tipoConta: TipoContaEnum.receber,
              dataHoraEmissaoInicial: dataEmissaoInicial,
              dataHoraEmissaoFinal: dataEmissaoFinal,
              listaTiposLancamento: {
                listaAcao: [],
                listaValor: [
                  {
                    descricao: 'Conta',
                  },
                ],
              },
            }
          );

        if (blobContasReceberParcelaPorEmissao) {
          zipContasReceberPagarParcelaPorEmissao.file(
            'Contas (Receber).pdf',
            blobContasReceberParcelaPorEmissao,
            { binary: true }
          );
        }
      } else {
        listaMensagem.push(
          'Nenhum Arquivo de Contas a Receber foi encontrado!'
        );
      }

      if (relContasPagarParcelaPorEmissao) {
        const blobContasPagarParcelaPorEmissao =
          await ContasReceberPagarParcelaPorEmissaoRelatorio.handleObterBlobPdf(
            {
              tipoConta: TipoContaEnum.pagar,
              dataHoraEmissaoInicial: dataEmissaoInicial,
              dataHoraEmissaoFinal: dataEmissaoFinal,
              listaTiposLancamento: {
                listaAcao: [],
                listaValor: [
                  {
                    descricao: 'Conta',
                  },
                ],
              },
            }
          );

        if (blobContasPagarParcelaPorEmissao) {
          zipContasReceberPagarParcelaPorEmissao.file(
            'Contas (Pagar).pdf',
            blobContasPagarParcelaPorEmissao,
            { binary: true }
          );
        } else {
          listaMensagem.push(
            'Nenhum Arquivo de Contas a Receber foi encontrado!'
          );
        }
      }

      if (
        Object.keys(zipContasReceberPagarParcelaPorEmissao.files).length > 0
      ) {
        const contasReceberPagarParcelaPorEmissaoZip =
          await zipContasReceberPagarParcelaPorEmissao.generateAsync({
            type: 'blob',
          });

        zip = {
          nomeArquivo: 'Relatórios Contas (Receber-Pagar).zip',
          file: contasReceberPagarParcelaPorEmissaoZip,
          permitirExcluir: false,
        };
      }

      return { zip, listaMensagem };
    }, [formRef]);

  const handleValidarSpedFiscal = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          spedFiscalMotivoInventario: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe o Motivo do Inventário para o Bloco H do Sped Fiscal!',
              test: () => {
                if (
                  !data.spedFiscalMotivoInventario &&
                  data.spedFiscalComBlocoH
                ) {
                  return false;
                }
                return true;
              },
            }),
          spedFiscalPeriodoBlocoH: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe o Período para o Bloco H do Sped Fiscal!',
              test: () => {
                if (!data.spedFiscalPeriodoBlocoH && data.spedFiscalComBlocoH) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Período para o Bloco H do Sped Fiscal inválido!',
              test: () => {
                if (!data.spedFiscalComBlocoH) return true;

                if (!data.spedFiscalPeriodoBlocoH) return false;
                const [mes, ano] = data.spedFiscalPeriodoBlocoH.split('/');

                if (
                  !mes ||
                  !ano ||
                  Number(mes) > 12 ||
                  Number(mes) < 1 ||
                  ano.length !== 4
                )
                  return false;

                return true;
              },
            }),
          spedFiscalFormatoGeracaoValoresH20: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe o Formato de Geraçäo de Valores para o Bloco H20 do Sped Fiscal!',
              test: () => {
                if (
                  !data.spedFiscalFormatoGeracaoValoresH20 &&
                  data.spedFiscalComBlocoH
                ) {
                  return false;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleObterSpedFiscal = useCallback(async (): Promise<any> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipSpedFiscal = new JSZip();

    if (!(await handleValidarSpedFiscal(formRef.current?.getData()))) {
      return { zip, listaMensagem };
    }

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');
    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    const spedFiscal = formRef.current?.getFieldValue('spedFiscal');
    const spedFiscalCompleto =
      formRef.current?.getFieldValue('spedFiscalCompleto');
    const spedFiscalTodosProdutos = formRef.current?.getFieldValue(
      'spedFiscalTodosProdutos'
    );
    const spedFiscalComBlocoH = formRef.current?.getFieldValue(
      'spedFiscalComBlocoH'
    );

    const spedFiscalMotivoInventario = formRef.current?.getFieldValue(
      'spedFiscalMotivoInventario'
    );
    const spedFiscalPeriodoBlocoH = formRef.current?.getFieldValue(
      'spedFiscalPeriodoBlocoH'
    );
    const spedFiscalFormatoGeracaoValoresH20 = formRef.current?.getFieldValue(
      'spedFiscalFormatoGeracaoValoresH20'
    );

    if (
      spedFiscal ||
      spedFiscalCompleto ||
      spedFiscalTodosProdutos ||
      spedFiscalComBlocoH
    ) {
      const response = await GeracaoArquivosContabeisSpedFiscalComunicador.show(
        {
          params: {
            dataEmissaoInicial,
            dataEmissaoFinal,
            spedFiscal,
            spedFiscalCompleto,
            spedFiscalTodosProdutos,
            spedFiscalComBlocoH,
            spedFiscalMotivoInventario,
            spedFiscalPeriodoBlocoH,
            spedFiscalFormatoGeracaoValoresH20,
          },
        }
      );

      if (response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          const { spedCompleto } = response[i];
          const nomeArquivo = `Sped Fiscal ${
            spedCompleto ? 'Completo ' : ''
          }${FormatarDataParaPtBr(
            response[i].dataInicial
          )}_a_${FormatarDataParaPtBr(response[i].dataFinal)}.txt`.replaceAll(
            '/',
            '-'
          );

          zipSpedFiscal.file(nomeArquivo, response[i].arquivo, {
            binary: true,
          });
        }

        if (Object.keys(zipSpedFiscal.files).length > 0) {
          const estoqueZip = await zipSpedFiscal.generateAsync({
            type: 'blob',
          });

          zip = {
            nomeArquivo: `Sped Fiscal.zip`,
            file: estoqueZip,
            permitirExcluir: false,
          };
        }
      } else {
        listaMensagem.push('Nenhum Arquivo de Sped Fiscal Gerado!');
      }
    }

    return { zip, listaMensagem };
  }, [formRef, handleValidarSpedFiscal]);

  const handleObterSisdevin = useCallback(async (): Promise<any> => {
    let zip: any | undefined;
    const listaMensagem: string[] = [];
    const zipSisdevin = new JSZip();

    const dataEmissaoInicial =
      formRef.current?.getFieldValue('dataEmissaoInicial');
    const dataEmissaoFinal = formRef.current?.getFieldValue('dataEmissaoFinal');

    const sisdevinEngarrafadoseGranel = formRef.current?.getFieldValue(
      'sisdevinEngarrafadoseGranel'
    );
    const sisdevinNotasCompraUva = formRef.current?.getFieldValue(
      'sisdevinNotasCompraUva'
    );
    const sisdevinDeclaracaoProducao = formRef.current?.getFieldValue(
      'sisdevinDeclaracaoProducao'
    );
    const sisdevinEnologicos =
      formRef.current?.getFieldValue('sisdevinEnologicos');

    const sisdevinDeclaracaoEstoque = formRef.current?.getFieldValue(
      'sisdevinDeclaracaoEstoque'
    );
    const sisdevinComunicacaoPerdas = formRef.current?.getFieldValue(
      'sisdevinComunicacaoPerdas'
    );
    const sisdevinCortes = formRef.current?.getFieldValue('sisdevinCortes');

    if (
      sisdevinEngarrafadoseGranel ||
      sisdevinNotasCompraUva ||
      sisdevinDeclaracaoProducao ||
      sisdevinEnologicos ||
      sisdevinDeclaracaoEstoque ||
      sisdevinComunicacaoPerdas ||
      sisdevinCortes
    ) {
      const response = await GeracaoArquivosContabeisSisdevinComunicador.show({
        params: {
          dataEmissaoInicial,
          dataEmissaoFinal,
          sisdevinEngarrafadoseGranel,
          sisdevinNotasCompraUva,
          sisdevinEnologicos,
          sisdevinDeclaracaoEstoque,
          sisdevinComunicacaoPerdas,
          sisdevinDeclaracaoProducao,
          sisdevinCortes,
        },
      });
      if (response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          const nomeArquivo = `Sisdevin${FormatarDataParaPtBr(
            response[i].dataInicial
          )}_a_${FormatarDataParaPtBr(response[i].dataFinal)}.txt`.replaceAll(
            '/',
            '-'
          );

          zipSisdevin.file(nomeArquivo, response[i].arquivo, {
            binary: true,
          });
        }

        if (Object.keys(zipSisdevin.files).length > 0) {
          const estoqueZip = await zipSisdevin.generateAsync({
            type: 'blob',
          });

          zip = {
            nomeArquivo: `Sisdevin.zip`,
            file: estoqueZip,
            permitirExcluir: false,
          };
        }
      } else {
        listaMensagem.push('Nenhum Arquivo de Sisdevin Gerado!');
      }
    }

    return { zip, listaMensagem };
  }, [formRef]);

  const handleEnviarPorEmail = useCallback(async () => {
    if (!ValidarCheckList()) {
      ToastErro('Nenhuma Opção Selecionada!');
      return;
    }

    const data = formRef.current?.getData() as any;

    await salvarPDF({
      salvarTudo: false,
      exibirMensagens: false,

      dataEmissaoInicial: data.dataEmissaoInicial,
      dataEmissaoFinal: data.dataEmissaoFinal,

      notaFiscalSaidaPdf: !!data.danfeNfePropriaPdf,
      notaFiscalSaidaEventoPdf: !!data.incluirNfePropriaCanceladas,

      notaFiscalEntradaPropriaPdf: !!data.danfeNfePropriaPdf,
      notaFiscalEntradaPropriaEventoPdf: !!data.incluirNfePropriaCanceladas,

      notaFiscalEntradaTerceiroPdf: !!data.danfeNfeTerceiros,

      conhecimentoTransportePdf: !!data.dacteCteProprioPdf,
      conhecimentoTransporteEventoPdf: !!data.incluirCteProprioCancelados,

      conhecimentoTransporteTerceiroPdf: !!data.dacteCteTerceiros,
    });

    setShowEnviarEmail(true);
  }, [ValidarCheckList, formRef, salvarPDF]);

  const handleBaixarCompactado = useCallback(async () => {
    const listaResponse: any[] = [];
    if (!ValidarCheckList()) {
      ToastErro('Nenhuma Opção Selecionada!');
      return;
    }

    try {
      setLoading(true);

      const data = formRef.current?.getData() as any;

      await salvarPDF({
        salvarTudo: false,
        exibirMensagens: false,

        dataEmissaoInicial: data.dataEmissaoInicial,
        dataEmissaoFinal: data.dataEmissaoFinal,

        notaFiscalSaidaPdf: !!data.danfeNfePropriaPdf,
        notaFiscalSaidaEventoPdf: !!data.incluirNfePropriaCanceladas,

        notaFiscalEntradaPropriaPdf: !!data.danfeNfePropriaPdf,
        notaFiscalEntradaPropriaEventoPdf: !!data.incluirNfePropriaCanceladas,

        notaFiscalEntradaTerceiroPdf: !!data.danfeNfeTerceiros,

        conhecimentoTransportePdf: !!data.dacteCteProprioPdf,
        conhecimentoTransporteEventoPdf: !!data.incluirCteProprioCancelados,

        conhecimentoTransporteTerceiroPdf: !!data.dacteCteTerceiros,
      });

      const zip = new JSZip();
      const nomeArquivo = formRef.current?.getFieldValue('nomeArquivo');

      // #region Xml Pdf Terceiros

      const retornoXmlPdfTerceiro = await handleObterXmlPdfTerceiro();

      if (retornoXmlPdfTerceiro.zip) {
        zip.file(
          retornoXmlPdfTerceiro.zip.nomeArquivo,
          retornoXmlPdfTerceiro.zip.file,
          { binary: true }
        );
      }

      // #endregion Xml Pdf Terceiros

      // #region Xml Pdf Emitidos

      const retornoXmlPdfEmitidos = await handleObterXmlPdfEmitidos();

      if (retornoXmlPdfEmitidos && retornoXmlPdfEmitidos.zip) {
        zip.file(
          retornoXmlPdfEmitidos.zip.nomeArquivo,
          retornoXmlPdfEmitidos.zip.file,
          { binary: true }
        );
      }

      // #endregion Xml Pdf Emitidos

      // #region Xml Pdf Conhecimento Transporte Emitidos

      const retornoXmlPdfConhecimentoTransporteEmitidos =
        await handleObterXmlPdfConhecimentoTransporteEmitidos();

      if (
        retornoXmlPdfConhecimentoTransporteEmitidos &&
        retornoXmlPdfConhecimentoTransporteEmitidos.zip
      ) {
        zip.file(
          retornoXmlPdfConhecimentoTransporteEmitidos.zip.nomeArquivo,
          retornoXmlPdfConhecimentoTransporteEmitidos.zip.file,
          { binary: true }
        );
      }

      // #endregion Xml Pdf Conhecimento Transporte Emitidos

      // #region Relatorios Estoque

      const retornoRelatoriosEstoque = await handleObterRelatoriosEstoque();

      if (retornoRelatoriosEstoque.zip) {
        zip.file(
          retornoRelatoriosEstoque.zip.nomeArquivo,
          retornoRelatoriosEstoque.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatorios Estoque

      // #region Relatórios Notas Fiscais

      const retornoRelatoriosNotaFiscalSaida =
        await handleObterRelatorioNotaFiscalSaida();

      if (retornoRelatoriosNotaFiscalSaida.zip) {
        zip.file(
          retornoRelatoriosNotaFiscalSaida.zip.nomeArquivo,
          retornoRelatoriosNotaFiscalSaida.zip.file,
          { binary: true }
        );
      }

      const retornoRelatoriosNotaFiscalEntrada =
        await handleObterRelatorioNotaFiscalEntrada();

      if (retornoRelatoriosNotaFiscalEntrada.zip) {
        zip.file(
          retornoRelatoriosNotaFiscalEntrada.zip.nomeArquivo,
          retornoRelatoriosNotaFiscalEntrada.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatórios Notas Fiscais

      // #region Relatórios Documentos de Entrada

      const retornoRelatoriosDocumentosEntrada =
        await handleObterRelatorioDocumentosEntrada();

      if (retornoRelatoriosDocumentosEntrada.zip) {
        zip.file(
          retornoRelatoriosDocumentosEntrada.zip.nomeArquivo,
          retornoRelatoriosDocumentosEntrada.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatórios Documentos de Entrada

      // #region Relatórios Conhecimento Transporte

      const retornoRelatoriosConhecimentoTransporte =
        await handleObterRelatorioConhecimentoTransporte();

      if (retornoRelatoriosConhecimentoTransporte.zip) {
        zip.file(
          retornoRelatoriosConhecimentoTransporte.zip.nomeArquivo,
          retornoRelatoriosConhecimentoTransporte.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatórios Conhecimento Transporte

      // #region Relatórios Manifesto Transporte

      const retornoRelatoriosManifestoTransporte =
        await handleObterRelatorioManifestoTransporte();

      if (retornoRelatoriosManifestoTransporte.zip) {
        zip.file(
          retornoRelatoriosManifestoTransporte.zip.nomeArquivo,
          retornoRelatoriosManifestoTransporte.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatórios Manifesto Transporte

      // #region Relatórios Contas a Pagar e Receber

      const retornoRelatoriosContasReceberPagar =
        await handleObterRelatorioContasReceberPagarParcelaPorEmissao();

      if (retornoRelatoriosContasReceberPagar.zip) {
        zip.file(
          retornoRelatoriosContasReceberPagar.zip.nomeArquivo,
          retornoRelatoriosContasReceberPagar.zip.file,
          { binary: true }
        );
      }

      // #endregion Relatórios Contas a Pagar e Receber

      // #region Sped Fiscal

      const retornoSpedFiscal = await handleObterSpedFiscal();

      if (retornoSpedFiscal.zip) {
        zip.file(
          retornoSpedFiscal.zip.nomeArquivo,
          retornoSpedFiscal.zip.file,
          { binary: true }
        );
      }

      // #endregion Sped Fiscal

      // #region Sped Fiscal

      const retornoSisdevin = await handleObterSisdevin();

      if (retornoSisdevin.zip) {
        zip.file(retornoSisdevin.zip.nomeArquivo, retornoSisdevin.zip.file, {
          binary: true,
        });
      }

      // #endregion Sped Fiscal

      if (Object.keys(zip.files).length > 0) {
        const arquivoZip = await zip.generateAsync({ type: 'blob' });
        listaResponse.push(arquivoZip);

        const url = window.URL.createObjectURL(new Blob(listaResponse));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${nomeArquivo}.zip`);

        link.click();

        ToastSucesso('Arquivo Baixado com Sucesso!');
        setLoading(false);
      } else {
        ToastInfo('Nenhum Documento Encontrado no Período Selecionado!');
        setLoading(false);
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    ValidarCheckList,
    setLoading,
    formRef,
    salvarPDF,
    handleObterXmlPdfTerceiro,
    handleObterXmlPdfEmitidos,
    handleObterXmlPdfConhecimentoTransporteEmitidos,
    handleObterRelatoriosEstoque,
    handleObterRelatorioNotaFiscalSaida,
    handleObterRelatorioNotaFiscalEntrada,
    handleObterRelatorioDocumentosEntrada,
    handleObterRelatorioConhecimentoTransporte,
    handleObterRelatorioManifestoTransporte,
    handleObterRelatorioContasReceberPagarParcelaPorEmissao,
    handleObterSpedFiscal,
    handleObterSisdevin,
  ]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const listaAnexo: any[] = [];

      try {
        setLoading(true);

        const dataEmissaoInicial =
          formRef.current?.getFieldValue('dataEmissaoInicial');
        const dataEmissaoFinal =
          formRef.current?.getFieldValue('dataEmissaoFinal');

        const assuntoPadrao = `Arquivos Contábeis de ${FormatarDataHoraParaPtBr(
          dataEmissaoInicial
        )} até ${FormatarDataHoraParaPtBr(dataEmissaoFinal)}`;

        // #region Xml Pdf Terceiros

        const retornoXmlPdfTerceiro = await handleObterXmlPdfTerceiro();
        if (retornoXmlPdfTerceiro.zip) {
          listaAnexo.push(retornoXmlPdfTerceiro.zip);
        }

        // #endregion Xml Pdf Terceiros

        // #region Xml Pdf Emitidos

        const retornoXmlPdfEmitidos = await handleObterXmlPdfEmitidos();
        if (retornoXmlPdfEmitidos.zip) {
          listaAnexo.push(retornoXmlPdfEmitidos.zip);
        }

        // #region Xml Pdf Conhecimento Transporte Emitidos

        const retornoXmlPdfConhecimentoTransporteEmitidos =
          await handleObterXmlPdfConhecimentoTransporteEmitidos();

        if (retornoXmlPdfConhecimentoTransporteEmitidos.zip) {
          listaAnexo.push(retornoXmlPdfConhecimentoTransporteEmitidos.zip);
        }

        // #endregion Xml Pdf Conhecimento Transporte Emitidos

        // #endregion Xml Pdf Emitidos

        // #region Relatorios Estoque

        const retornoRelatoriosEstoque = await handleObterRelatoriosEstoque();
        if (retornoRelatoriosEstoque.zip) {
          listaAnexo.push(retornoRelatoriosEstoque.zip);
        }

        // #endregion Relatorios Estoque

        // #region Relatórios Nota Fiscal

        const retornoRelatoriosNotaFiscalSaida =
          await handleObterRelatorioNotaFiscalSaida();

        if (retornoRelatoriosNotaFiscalSaida.zip) {
          listaAnexo.push(retornoRelatoriosNotaFiscalSaida.zip);
        }

        const retornoRelatoriosNotaFiscalEntrada =
          await handleObterRelatorioNotaFiscalEntrada();

        if (retornoRelatoriosNotaFiscalEntrada.zip) {
          listaAnexo.push(retornoRelatoriosNotaFiscalEntrada.zip);
        }

        // #endregion Relatórios Nota Fiscal

        // #region Documentos Entrada

        const retornoRelatoriosDocumentosEntrada =
          await handleObterRelatorioDocumentosEntrada();

        if (retornoRelatoriosDocumentosEntrada.zip) {
          listaAnexo.push(retornoRelatoriosDocumentosEntrada.zip);
        }

        // #endregion Documentos Entrada

        // #region Relatório Conhecimento Transporte

        const retornoRelatorioConhecimentoTransporte =
          await handleObterRelatorioConhecimentoTransporte();

        if (retornoRelatorioConhecimentoTransporte.zip) {
          listaAnexo.push(retornoRelatorioConhecimentoTransporte.zip);
        }

        // #endregion Relatório Conhecimento Transporte

        // #region Relatórios Manifesto Transporte

        const retornoRelatoriosManifestoTransporte =
          await handleObterRelatorioManifestoTransporte();

        if (retornoRelatoriosManifestoTransporte.zip) {
          listaAnexo.push(retornoRelatoriosManifestoTransporte.zip);
        }

        // #endregion Relatórios Manifesto Transporte

        // #region Relatórios Contas a Pagar e Receber

        const retornoRelatoriosContasReceberPagar =
          await handleObterRelatorioContasReceberPagarParcelaPorEmissao();

        if (retornoRelatoriosContasReceberPagar.zip) {
          listaAnexo.push(retornoRelatoriosContasReceberPagar.zip);
        }

        // #endregion Relatórios Contas a Pagar e Receber

        // #region Sped Fiscal

        const retornoSpedFiscal = await handleObterSpedFiscal();
        if (retornoSpedFiscal.zip) {
          listaAnexo.push(retornoSpedFiscal.zip);
        }

        // #endregion Sped Fiscal

        if (listaAnexo && listaAnexo.length > 0) {
          await formRefEmail.current?.setDataInicial({
            assunto: assuntoPadrao,
            copiaEmail: true,
            listaAnexo,
          });
        } else {
          ToastInfo('Nenhum Documento Encontrado no Período Selecionado!');
          setLoading(false);
          return;
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      formRef,
      handleObterRelatorioConhecimentoTransporte,
      handleObterRelatorioContasReceberPagarParcelaPorEmissao,
      handleObterRelatorioDocumentosEntrada,
      handleObterRelatorioManifestoTransporte,
      handleObterRelatorioNotaFiscalEntrada,
      handleObterRelatorioNotaFiscalSaida,
      handleObterRelatoriosEstoque,
      handleObterSpedFiscal,
      handleObterXmlPdfConhecimentoTransporteEmitidos,
      handleObterXmlPdfEmitidos,
      handleObterXmlPdfTerceiro,
      setLoading,
    ]
  );

  return (
    <>
      <SplitAcoes
        id={v4()}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginRight: 10, marginLeft: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item onClick={handleBaixarCompactado}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Baixar Compactado
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Arquivos Contábeis por E-mail"
          mensagemPadrao={`Segue os Arquivos Contábeis da Empresa ${empresa.nomeRazaoSocial}`}
          direcionarDocumentosFiscais
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}
    </>
  );
};

export default AcoesGeracaoArquivosContabeis;
