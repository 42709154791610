import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/categoria-dre/regra-escolha-categoria';

interface IRegraEscolhaCategoriaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaCategoriaComunicadorShow {
  id: string;
}

interface IRegraEscolhaCategoriaComunicadorStore {
  params: any;
}

interface IRegraEscolhaCategoriaComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaCategoriaComunicadorDeletar {
  id: string;
}

class RegraEscolhaCategoriaComunicador {
  public async index(
    params: IRegraEscolhaCategoriaComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaCategoriaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaCategoriaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaCategoriaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaCategoriaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaCategoriaComunicador();
