import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-desmontagem/impressao';

interface IOrdemDesmontagemImpressaoComunicadorShow {
  id: string;
}

class OrdemDesmontagemImpressaoComunicador {
  public async show(
    params: IOrdemDesmontagemImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemDesmontagemImpressaoComunicador();
