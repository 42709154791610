"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = RateioComCasaDecimalCalculo;
function RateioComCasaDecimalCalculo(dados) {
    const vetor = [];
    let total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    if (total === 0) {
        for (let i = 0; i < dados.valores.length; i++)
            dados.valores.splice(i, 1, 1);
        total = dados.valores.reduce((totalAcumulado, currentElement) => totalAcumulado + currentElement, 0);
    }
    for (let i = 0; i < dados.valores.length; i++) {
        const peso = (dados.valores[i] / total) * 100;
        const valorPercentual = dados.valor.CalcularPercentual(peso);
        vetor.push({
            valor: valorPercentual.Arredondar(dados.casasDecimais),
            identificacao: dados.identificacao,
        });
    }
    const somatorio = vetor.reduce((totalAcumulado, currentElement) => Number(totalAcumulado) + Number(currentElement.valor), 0);
    let diferenca = (dados.valor - somatorio).Arredondar(dados.casasDecimais);
    const fator = 1 / Math.pow(10, dados.casasDecimais);
    if (diferenca > 0) {
        let j = vetor.length - 1;
        while (diferenca > 0) {
            const valor = vetor[j].valor + fator;
            vetor.splice(j, 1, { valor, identificacao: dados.identificacao });
            diferenca -= fator;
            j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    else if (diferenca < 0) {
        let j = vetor.length - 1;
        while (diferenca < 0) {
            const valor = vetor[j].valor - fator;
            vetor.splice(j, 1, { valor, identificacao: dados.identificacao });
            diferenca += fator;
            j--;
            if (j < 0)
                j = vetor.length - 1;
        }
    }
    return vetor;
}
