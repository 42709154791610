import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import InutilizacaoNumeracaoComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/InutilizacaoNumeracao/Comunicador/InutilizacaoNumeracaoComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const InutilizacaoNumeracaoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ButtonsSubHeaderProvider>
      <ComunicadorProvider
        value={{ comunicador: InutilizacaoNumeracaoComunicador }}
      >
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ComunicadorProvider>
    </ButtonsSubHeaderProvider>
  );
};

export default InutilizacaoNumeracaoHook;
