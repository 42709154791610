import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarEnum,
  IEstruturaProdutoFiltroRelatorioFrontend,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import Input from '../../../../../Componentes/Inputs/Input';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import Select from '../../../../../Componentes/Select';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import { IOnChangeListaItemAtualEvent } from '../../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import InputAutoCompleteTagUnidadeMedida from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagUnidadeMedida';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IEstruturaProdutoRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const EstruturaProdutoRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        codigo: Yup.string().nullable().max(60, 'Código inválido'),
        descricao: Yup.string().nullable().max(120, 'Descrição inválida'),
        tipoBuscaCodigoDescricao: Yup.string().required(
          'Tipo de Busca não pode ser nulo!'
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleChangeEstruturaProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
        ].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
        ].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        [
          'codigo',
          'descricao',
          'tipoBuscaCodigoDescricao',
          'unidadeMedida',
          'grupoProdutoServico',
        ].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstruturaProdutoRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IEstruturaProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstruturaProdutoRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IEstruturaProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstruturaProdutoRelatorioDados;

      handleValidar(dados);

      const produto = formRef.current?.getFieldValueNomeObjeto('produto');
      const unidadeMedida =
        formRef.current?.getFieldValueNomeObjeto('unidadeMedida');
      const grupoProdutoServico = formRef.current?.getFieldValueNomeObjeto(
        'grupoProdutoServico'
      );

      const dadosPesquisa = {
        ...dados,
        produto,
        unidadeMedida,
        grupoProdutoServico,
      };

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dadosPesquisa as IEstruturaProdutoFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      opcaoRelatorio: 'EstruturaProdutoPrimeiroNivel',
    });
  }, []);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Estrutura de Produto
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Primeiro Nível da Estrutura',
                        value: 'EstruturaProdutoPrimeiroNivel',
                      },
                    ]}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col xl={12} lg={12}>
                    <InputAutoCompleteTagProduto
                      label="Produtos (pais)"
                      name="listaProduto"
                      onChangeListaItemAtual={handleChangeEstruturaProduto}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Código"
                      name="codigo"
                      placeholder="Código"
                      maxLength={60}
                    />
                  </Col>
                  <Col lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Input
                      label="Descrição"
                      name="descricao"
                      placeholder="Descrição"
                      maxLength={120}
                    />
                  </Col>
                  <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                    <Select
                      label="Filtro Codigo/Descrição"
                      name="tipoBuscaCodigoDescricao"
                      options={FormatarEnum({
                        enumObj: TipoBuscaCodigoDescricaoEnum,
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagUnidadeMedida
                      label="Unidade de Medida (pais)"
                      name="listaUnidadeMedida"
                    />
                  </Col>
                  <Col lg={6} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagGrupoProdutoServico
                      label="Grupos (pais)"
                      name="listaGrupoProdutoServico"
                      utilizavelProdutoServico
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default EstruturaProdutoRelatorio;
