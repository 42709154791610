/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import {
  FormatarCnpjCpf,
  FormatarDataParaPtBr,
  FormatarEnum,
  IImportacaoXmlTerceiroLista,
  IImportacaoXmlTerceiroListaDadosImpressao,
  ImportacaoXmlTerceiroTipoEnum,
  SituacaoAutorizacaoEnum,
  SituacaoImportacaoEnum,
  SituacaoManifestacaoEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import React, { useCallback, useRef, useState } from 'react';
import { Col, Dropdown, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { CgPlayListSearch, CgNotes } from 'react-icons/cg/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { TiInputChecked } from 'react-icons/ti';
import { VscClearAll } from 'react-icons/vsc/index.mjs';
import { MdHighlightOff } from 'react-icons/md/index.mjs';
import { FiFile } from 'react-icons/fi/index.mjs';
import { BsTrash } from 'react-icons/bs/index.mjs';
import { AiOutlineFileAdd } from 'react-icons/ai/index.mjs';
import { FaFileImport } from 'react-icons/fa/index.mjs';
import { useNavigate } from 'react-router-dom';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ImportacaoXmlTerceiroLogComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Log/Comunicador/ImportacaoXmlTerceiroLogComunicador';
import AcoesImportacaoXmlTerceiro from './AcoesImportacaoXmlTerceiro';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import ImportacaoXmlTerceiroComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroComunicador';
import ImportacaoXmlTerceiroConfirmarOperacaoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroConfirmarOperacaoComunicador';
import ImportacaoXmlTerceiroDesconhecerOperacaoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroDesconhecerOperacaoComunicador';
import ImportacaoXmlTerceiroOperacaoNaoRealizadaComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroOperacaoNaoRealizadaComunicador';
import ImportacaoXmlTerceiroNaoSeraImportadoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroNaoSeraImportadoComunicador';
import ImportacaoXmlTerceiroConsultarGovernoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroConsultarGovernoComunicador';
import ImportacaoXmlTerceiroCienciaOperacaoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroCienciaOperacaoComunicador';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import ImportacaoXmlTerceiroBaixarComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroBaixarComunicador';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import ToastInfo from '../../../../../Util/Toasts/ToastInfo';
import OperacaoNaoRealizada from './OperacaoNaoRealizada';
import ImportacaoXmlTerceiroSeraImportadoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroSeraImportadoComunicador';
import ImportacaoXmlTerceiroImpressaoComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroImpressaoComunicador';
import ImpressaoNotaFiscal from '../../../../DocumentosEletronicos/NFe/Impressao/index';
import ImpressaoConhecimentoTransporteModalRodoviario from '../../../../DocumentosEletronicos/CTe/ImpressaoModalRodoviario/index';
import ImpressaoConhecimentoTransporteModalAereo from '../../../../DocumentosEletronicos/CTe/ImpressaoModalAereo/index';
import ImportacaoXmlTerceiroExportarXmlComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroExportarXmlComunicador';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseImportacaoCteTerceiro } from './ImportacaoCteTerceiro';
import ImportacaoXmlTerceiroUploadPdfComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroUploadPdfComunicador';
import VisualizarPDF from '../../../../../Util/Relatorios/VisualizarPDF';
import { UseImportacaoNfeTerceiro } from './ImportacaoNfeTerceiro';
import ImportacaoXmlTerceiroConsultarNSUFaltantesComunicador from '../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroConsultarNSUFaltantesComunicador';

const ImportacaoXmlTerceiroLista: React.FC = () => {
  const navigate = useNavigate();
  const { permissoes } = UsePermissoes();
  const { SuprimentosNotaFiscalConhecimentoImportacaoXmlTerceiro: permissao } =
    permissoes;
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirImportacaoCteTerceiro } = UseImportacaoCteTerceiro();
  const { abrirImportacaoNfeTerceiro } = UseImportacaoNfeTerceiro();
  const { abrirJanela } = UseConfirmacao();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [showTriggerSincronizarGoverno, setShowTriggerSincronizarGoverno] =
    useState(false);
  const [
    showTriggerConsultarNSUFaltantes,
    setShowTriggerConsultarNSUFaltantes,
  ] = useState(false);
  const [listaValor, setListaValor] = useState<IImportacaoXmlTerceiroLista[]>(
    []
  );

  const [possuiItemSelecionado, setPossuiItemSelecionado] = useState(false);

  const [idOperacaoNaoRealizada, setIdOperacaoNaoRealizada] = useState('');
  const [showOperacaoNaoRealizada, setShowOperacaoNaoRealizada] =
    useState(false);

  const formRef = useRef<IFormCiaHandles>(null);
  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleAlterouSelecionado = useCallback((): void => {
    const itensSelecionados = listaItemSelecionadoRef.current.filter(
      (item, index) => {
        return item?.checked;
      }
    );
    setPossuiItemSelecionado(itensSelecionados.length !== 0);
  }, []);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado]
  );

  const handleObterItensSelecionados =
    useCallback((): IImportacaoXmlTerceiroLista[] => {
      const listaIndexItemSelecionado: number[] = [];
      const listaItemSelecionado: IImportacaoXmlTerceiroLista[] = [];

      listaItemSelecionadoRef.current.forEach((item, index) => {
        if (item && item.checked) {
          listaIndexItemSelecionado.push(index);
        }
      });

      listaIndexItemSelecionado.forEach((index) => {
        listaItemSelecionado.push(listaValor[index]);
      });

      return listaItemSelecionado;
    }, [listaValor]);

  const atualizar = useCallback(() => {
    handleSelecionarTodos(false);
    setRefresh((state) => !state);
  }, [handleSelecionarTodos]);

  const handleConsultarGoverno = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setShowTriggerSincronizarGoverno(false);

      const tipoDocumento = formRef.current?.getFieldValue('tipoDocumento');
      const response =
        await ImportacaoXmlTerceiroConsultarGovernoComunicador.index({
          params: { tipoDocumento },
        });

      // #region Fazer Upload dos PDF

      if (response.listaImpressao && response.listaImpressao.length > 0) {
        const quantidadeVezesInserir = Math.ceil(
          response.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.listaImpressao.length) {
            fim = response.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlTerceiroListaDadosImpressao =
              response.listaImpressao[i];

            if (
              impressao.tipoImportacaoXmlTerceiro ===
              ImportacaoXmlTerceiroTipoEnum.cte
            ) {
              if (impressao.dados.rodo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalRodoviario.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              } else if (impressao.dados.aereo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalAereo.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              }
            } else {
              const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                impressao.dados
              );

              formData.append(
                'bloblPdf',
                blobPdf as any,
                impressao.chaveAcesso
              );
            }
          }

          await ImportacaoXmlTerceiroUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF

      if (response.listaSucesso) {
        response.listaSucesso.forEach((mensagem: string) => {
          ToastSucesso(mensagem);
        });
      }
      if (response.listaAviso) {
        response.listaAviso.forEach((mensagem: string) => {
          ToastInfo(mensagem);
        });
      }
      if (response.listaErro) {
        response.listaErro.forEach((mensagem: string) => {
          ToastErro(mensagem);
        });
      }

      setLoading(false);
      atualizar();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [atualizar]);

  const handleConsultarNSUFaltantes = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setShowTriggerConsultarNSUFaltantes(false);

      const tipoDocumento = formRef.current?.getFieldValue('tipoDocumento');
      const response =
        await ImportacaoXmlTerceiroConsultarNSUFaltantesComunicador.index({
          params: { tipoDocumento },
        });

      // #region Fazer Upload dos PDF

      if (response.listaImpressao && response.listaImpressao.length > 0) {
        const quantidadeVezesInserir = Math.ceil(
          response.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.listaImpressao.length) {
            fim = response.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlTerceiroListaDadosImpressao =
              response.listaImpressao[i];

            if (
              impressao.tipoImportacaoXmlTerceiro ===
              ImportacaoXmlTerceiroTipoEnum.cte
            ) {
              if (impressao.dados.rodo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalRodoviario.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              } else if (impressao.dados.aereo) {
                const blobPdf =
                  await ImpressaoConhecimentoTransporteModalAereo.handleObterBlobPdf(
                    impressao.dados
                  );

                formData.append(
                  'bloblPdf',
                  blobPdf as any,
                  impressao.chaveAcesso
                );
              }
            } else {
              const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                impressao.dados
              );

              formData.append(
                'bloblPdf',
                blobPdf as any,
                impressao.chaveAcesso
              );
            }
          }

          await ImportacaoXmlTerceiroUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF

      if (response.listaSucesso) {
        response.listaSucesso.forEach((mensagem: string) => {
          ToastSucesso(mensagem);
        });
      }
      if (response.listaAviso) {
        response.listaAviso.forEach((mensagem: string) => {
          ToastInfo(mensagem);
        });
      }
      if (response.listaErro) {
        response.listaErro.forEach((mensagem: string) => {
          ToastErro(mensagem);
        });
      }

      setLoading(false);
      atualizar();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [atualizar]);

  const handleOperacaoes = useCallback(
    async (
      id: string,
      situacaoManifestacao: SituacaoManifestacaoEnum,
      modeloDocumentoFiscalCodigo: string,
      nomeXml: string
    ): Promise<void> => {
      try {
        setLoading(true);
        if (
          (nomeXml && !nomeXml.IsNullOrEmpty()) ||
          situacaoManifestacao ===
            SituacaoManifestacaoEnum.operacaoDesconhecida ||
          situacaoManifestacao ===
            SituacaoManifestacaoEnum.operacaoNaoRealizada ||
          situacaoManifestacao === SituacaoManifestacaoEnum.servicoEmDesacordo
        ) {
          if (modeloDocumentoFiscalCodigo === '55') {
            const response = await abrirImportacaoNfeTerceiro({
              idImportacaoXmlAutorizacao: id,
            });

            if (response) {
              const resposta = await abrirJanela({
                titulo: <h2>Confirmação</h2>,
                mensagem: (
                  <span style={{ fontSize: 20 }}>
                    Deseja abrir o NF-e importado?
                  </span>
                ),
                padraoSelecionadoSim: false,
                cancelar: 'Não',
                confimar: 'Sim',
              });

              if (resposta) {
                navigate({
                  pathname: `/suprimentos/compras/nota-fiscal-entrada/detalhe/${response}`,
                });
              }
            }
          } else if (
            modeloDocumentoFiscalCodigo === '57' ||
            modeloDocumentoFiscalCodigo === '67'
          ) {
            const response = await abrirImportacaoCteTerceiro({
              idImportacaoXmlAutorizacao: id,
            });

            if (response) {
              const resposta = await abrirJanela({
                titulo: <h2>Confirmação</h2>,
                mensagem: (
                  <span style={{ fontSize: 20 }}>
                    Deseja abrir o CT-e importado?
                  </span>
                ),
                padraoSelecionadoSim: false,
                cancelar: 'Não',
                confimar: 'Sim',
              });

              if (resposta) {
                navigate({
                  pathname: `/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro/detalhe/${response}`,
                });
              }
            }
          }
        } else {
          switch (situacaoManifestacao) {
            case SituacaoManifestacaoEnum.naoManifestado:
              {
                const response =
                  await ImportacaoXmlTerceiroCienciaOperacaoComunicador.update({
                    params: [id],
                  });

                if (response.listaSucesso) {
                  response.listaSucesso.forEach((mensagem: string) => {
                    ToastSucesso(mensagem);
                  });
                }
                if (response.listaAviso) {
                  response.listaAviso.forEach((mensagem: string) => {
                    ToastInfo(mensagem);
                  });
                }
                if (response.listaErro) {
                  response.listaErro.forEach((mensagem: string) => {
                    ToastErro(mensagem);
                  });
                }
              }
              break;

            case SituacaoManifestacaoEnum.cienciaDaOperacao:
            case SituacaoManifestacaoEnum.operacaoConfirmada:
              {
                const response =
                  await ImportacaoXmlTerceiroBaixarComunicador.store({
                    params: [id],
                  });

                if (response.listaSucesso) {
                  response.listaSucesso.forEach((mensagem: string) => {
                    ToastSucesso(mensagem);
                  });
                }
                if (response.listaAviso) {
                  response.listaAviso.forEach((mensagem: string) => {
                    ToastInfo(mensagem);
                  });
                }
                if (response.listaErro) {
                  response.listaErro.forEach((mensagem: string) => {
                    ToastErro(mensagem);
                  });
                }
              }
              break;
          }
        }

        setLoading(false);
        atualizar();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleConfirmarOperacao = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);

        const response =
          await ImportacaoXmlTerceiroConfirmarOperacaoComunicador.update({
            params: [id],
          });

        if (response.listaSucesso) {
          response.listaSucesso.forEach((mensagem: string) => {
            ToastSucesso(mensagem);
          });
        }
        if (response.listaAviso) {
          response.listaAviso.forEach((mensagem: string) => {
            ToastInfo(mensagem);
          });
        }
        if (response.listaErro) {
          response.listaErro.forEach((mensagem: string) => {
            ToastErro(mensagem);
          });
        }

        setLoading(false);
        atualizar();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDesconhecimentoOperacao = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);

        const response =
          await ImportacaoXmlTerceiroDesconhecerOperacaoComunicador.update({
            params: [id],
          });

        if (response.listaSucesso) {
          response.listaSucesso.forEach((mensagem: string) => {
            ToastSucesso(mensagem);
          });
        }
        if (response.listaAviso) {
          response.listaAviso.forEach((mensagem: string) => {
            ToastInfo(mensagem);
          });
        }
        if (response.listaErro) {
          response.listaErro.forEach((mensagem: string) => {
            ToastErro(mensagem);
          });
        }

        setLoading(false);
        atualizar();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOperacaoNaoRealizada = useCallback(
    async (id: string): Promise<void> => {
      setIdOperacaoNaoRealizada(id);
      setShowOperacaoNaoRealizada(true);
    },
    []
  );

  const handleVisualizarDanfeDacte = useCallback(
    async (id: string, modeloDocumentoFiscalCodigo: string): Promise<void> => {
      try {
        setLoading(true);

        const response = await ImportacaoXmlTerceiroImpressaoComunicador.show({
          id,
        });

        if (response.pdf) {
          VisualizarPDF({ dados: response.pdf });
        } else {
          const formData = new FormData();

          if (modeloDocumentoFiscalCodigo === '55') {
            await ImpressaoNotaFiscal.handleVisualizarPdf(response.impressao);
            const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
              response.impressao
            );
            formData.append(
              'bloblPdf',
              blobPdf as any,
              response.impressao.chaveAcesso
            );
          } else if (
            modeloDocumentoFiscalCodigo === '57' ||
            modeloDocumentoFiscalCodigo === '67'
          ) {
            if (response.impressao.rodo) {
              await ImpressaoConhecimentoTransporteModalRodoviario.handleVisualizarPdf(
                response.impressao
              );

              const blobPdf =
                await ImpressaoConhecimentoTransporteModalRodoviario.handleObterBlobPdf(
                  response.impressao
                );

              formData.append(
                'bloblPdf',
                blobPdf as any,
                response.impressao.chaveAcesso
              );
            } else if (response.impressao.aereo) {
              await ImpressaoConhecimentoTransporteModalAereo.handleVisualizarPdf(
                response.impressao
              );

              const blobPdf =
                await ImpressaoConhecimentoTransporteModalAereo.handleObterBlobPdf(
                  response.impressao
                );

              formData.append(
                'bloblPdf',
                blobPdf as any,
                response.impressao.chaveAcesso
              );
            }
          }

          // await ImportacaoXmlTerceiroUploadPdfComunicador.update({
          //   params: formData,
          // });
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleExportarXml = useCallback(
    async (id: string, modeloDocumentoFiscalCodigo: string): Promise<void> => {
      try {
        setLoading(true);

        const response = await ImportacaoXmlTerceiroExportarXmlComunicador.show(
          { id }
        );

        if (response) {
          if (modeloDocumentoFiscalCodigo === '55')
            ImpressaoNotaFiscal.handleExportarXml(
              response.xml,
              response.nomeArquivo
            );
          else if (
            modeloDocumentoFiscalCodigo === '57' ||
            modeloDocumentoFiscalCodigo === '67'
          )
            ImpressaoConhecimentoTransporteModalRodoviario.handleExportarXml(
              response.xml,
              response.nomeArquivo
            );
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleMarcarComoNaoSeraImportado = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);

        const response =
          await ImportacaoXmlTerceiroNaoSeraImportadoComunicador.update({ id });

        ToastSucesso(response.mensagem);
        setLoading(false);
        atualizar();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleMarcarComoSeraImportado = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);

        const response =
          await ImportacaoXmlTerceiroSeraImportadoComunicador.update({ id });

        ToastSucesso(response.mensagem);
        setLoading(false);
        atualizar();
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);
        const tipoDocumento = formRef.current?.getFieldValue('tipoDocumento');

        const response = await ImportacaoXmlTerceiroComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
            tipoDocumento,
          },
        });

        const listaDados = response.dados[0].map(
          (data: IImportacaoXmlTerceiroLista) => {
            return { ...data, selecionado: false };
          }
        );

        setListaValor(listaDados);
        const dados = listaDados.map(
          (data: IImportacaoXmlTerceiroLista, index: number) => {
            let textoOperacao = '';

            if (
              (data.nomeXml && !data.nomeXml.IsNullOrEmpty()) ||
              data.situacaoManifestacao ===
                SituacaoManifestacaoEnum.operacaoDesconhecida ||
              data.situacaoManifestacao ===
                SituacaoManifestacaoEnum.operacaoNaoRealizada ||
              data.situacaoManifestacao ===
                SituacaoManifestacaoEnum.servicoEmDesacordo
            ) {
              textoOperacao = 'Importar';

              if (data.modeloDocumentoFiscal.codigo === '55') {
                textoOperacao += ' NF-e';
              } else if (
                data.modeloDocumentoFiscal.codigo === '57' ||
                data.modeloDocumentoFiscal.codigo === '67'
              ) {
                textoOperacao += ' CT-e';
              }
            } else if (
              data.situacaoManifestacao ===
              SituacaoManifestacaoEnum.naoManifestado
            ) {
              textoOperacao = 'Ciência Operação';
            } else if (
              data.situacaoManifestacao ===
                SituacaoManifestacaoEnum.cienciaDaOperacao ||
              data.situacaoManifestacao ===
                SituacaoManifestacaoEnum.operacaoConfirmada
            ) {
              textoOperacao = 'Baixar XML do Governo';
            }

            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={handleAlterouSelecionado}
                  />
                </div>
              ),

              operacoes: (
                <div className="lista-texto">
                  <button
                    style={{
                      height: '26px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    type="button"
                    className="btn-padrao btn-azul"
                    onClick={() => {
                      handleOperacaoes(
                        data.id,
                        data.situacaoManifestacao,
                        data.modeloDocumentoFiscal.codigo,
                        data.nomeXml
                      );
                    }}
                    disabled={
                      data.situacaoAutorizacao ===
                      SituacaoAutorizacaoEnum.cancelado
                    }
                  >
                    {(textoOperacao === 'Importar NF-e' ||
                      textoOperacao === 'Importar CT-e') && <FaFileImport />}

                    {textoOperacao === 'Ciência Operação' && <TiInputChecked />}

                    {textoOperacao === 'Baixar XML do Governo' && <FiFile />}

                    <span style={{ marginLeft: 10 }}>{textoOperacao}</span>
                  </button>
                </div>
              ),

              numero: <div className="lista-valor">{data.numero}</div>,
              serie: <div className="lista-valor">{data.serie}</div>,
              'modeloDocumento.codigo': (
                <div className="lista-texto">
                  {data.modeloDocumentoFiscal.codigo === '55'
                    ? 'NF-e'
                    : data.modeloDocumentoFiscal.codigo === '57' ||
                        data.modeloDocumentoFiscal.codigo === '67'
                      ? 'CT-e'
                      : ''}
                </div>
              ),
              cnpjCpfVirtual: (
                <div className="lista-texto">
                  {FormatarCnpjCpf(data.cnpjCpfVirtual)}
                </div>
              ),
              nomeRazaoSocial: (
                <div className="lista-texto">{data.nomeRazaoSocial}</div>
              ),
              dataHoraEmissao: (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.dataHoraEmissao)}
                </div>
              ),
              valorTotal: (
                <div className="lista-valor">
                  {Number(data.valorTotal).FormatarParaPtBr()}
                </div>
              ),
              chaveAcesso: (
                <div className="lista-valor">{data.chaveAcesso}</div>
              ),
              situacaoAutorizacao: (
                <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                  {data.situacaoAutorizacao}
                </div>
              ),
              situacaoManifestacao: (
                <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                  {data.situacaoManifestacao}
                </div>
              ),
              situacaoImportacao: (
                <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                  {data.situacaoImportacao}
                </div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Acoes
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          ImportacaoXmlTerceiroLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={permissao?.exclui}
                    botoes={
                      <div>
                        {data.modeloDocumentoFiscal.codigo === '55' && (
                          <Dropdown.Item
                            onClick={() => {
                              handleConfirmarOperacao(data.id);
                            }}
                            disabled={
                              data.situacaoAutorizacao ===
                              SituacaoAutorizacaoEnum.cancelado
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <TiInputChecked style={{ marginRight: 5 }} />
                              Confirmar Operação
                            </span>
                          </Dropdown.Item>
                        )}

                        {data.modeloDocumentoFiscal.codigo === '55' && (
                          <Dropdown.Item
                            onClick={() => {
                              handleDesconhecimentoOperacao(data.id);
                            }}
                            disabled={
                              data.situacaoAutorizacao ===
                              SituacaoAutorizacaoEnum.cancelado
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <VscClearAll style={{ marginRight: 5 }} />
                              Desconhecimento Operação
                            </span>
                          </Dropdown.Item>
                        )}

                        {data.modeloDocumentoFiscal.codigo === '55' && (
                          <Dropdown.Item
                            onClick={() => {
                              handleOperacaoNaoRealizada(data.id);
                            }}
                            disabled={
                              data.situacaoAutorizacao ===
                              SituacaoAutorizacaoEnum.cancelado
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <MdHighlightOff style={{ marginRight: 5 }} />
                              Operação não Realizada
                            </span>
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            handleVisualizarDanfeDacte(
                              data.id,
                              data.modeloDocumentoFiscal.codigo
                            );
                          }}
                          disabled={data.nomeXml === null}
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <CgNotes style={{ marginRight: 5 }} />
                            {`Visualizar ${
                              data.modeloDocumentoFiscal.codigo === '55'
                                ? 'Danfe'
                                : data.modeloDocumentoFiscal.codigo === '57' ||
                                    data.modeloDocumentoFiscal.codigo === '67'
                                  ? 'Dacte'
                                  : ''
                            }`}
                          </span>
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => {
                            handleExportarXml(
                              data.id,
                              data.modeloDocumentoFiscal.codigo
                            );
                          }}
                          disabled={data.nomeXml === null}
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <FiFile style={{ marginRight: 5 }} />
                            Exportar XML (Download)
                          </span>
                        </Dropdown.Item>

                        {data.situacaoImportacao ===
                          SituacaoImportacaoEnum.importar && (
                          <Dropdown.Item
                            onClick={() => {
                              handleMarcarComoNaoSeraImportado(data.id);
                            }}
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <BsTrash style={{ marginRight: 5 }} />
                              Marcar como não será importado
                            </span>
                          </Dropdown.Item>
                        )}

                        {data.situacaoImportacao ===
                          SituacaoImportacaoEnum.naoSeraImportado && (
                          <Dropdown.Item
                            onClick={() => {
                              handleMarcarComoSeraImportado(data.id);
                            }}
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineFileAdd style={{ marginRight: 5 }} />
                              Marcar como será importado
                            </span>
                          </Dropdown.Item>
                        )}
                      </div>
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        throw error;
      }
    },
    [
      handleAlterouSelecionado,
      handleConfirmarOperacao,
      handleDesconhecimentoOperacao,
      handleExportarXml,
      handleMarcarComoNaoSeraImportado,
      handleMarcarComoSeraImportado,
      handleOperacaoNaoRealizada,
      handleOperacaoes,
      handleVisualizarDanfeDacte,
      permissao,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosImportacaoXmlTerceiro' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      listaTh.splice(1, 0, {
        id: 'operacoes-key',
        arrastavel: false,
        nomeCampo: 'operacoes',
        ordenar: false,
        tamanho: null,
        titulo: 'Operações',
        visivel: true,
        permitirEsconder: true,
        style: { width: 250 },
      });

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  const handleTransmitirOperacaoNaoRealizada = useCallback(
    async (justificativa: string, id: string): Promise<void> => {
      setShowOperacaoNaoRealizada(false);

      try {
        setLoading(true);

        const response =
          await ImportacaoXmlTerceiroOperacaoNaoRealizadaComunicador.update({
            params: { listaId: [id], justificativa },
          });

        if (response.listaSucesso) {
          response.listaSucesso.forEach((mensagem: string) => {
            ToastSucesso(mensagem);
          });
        }
        if (response.listaAviso) {
          response.listaAviso.forEach((mensagem: string) => {
            ToastInfo(mensagem);
          });
        }
        if (response.listaErro) {
          response.listaErro.forEach((mensagem: string) => {
            ToastErro(mensagem);
          });
        }

        setLoading(false);
        atualizar();
        setIdOperacaoNaoRealizada('');
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setIdOperacaoNaoRealizada('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFecharOperacaoNaoRealizada =
    useCallback(async (): Promise<void> => {
      setShowOperacaoNaoRealizada(false);
      setIdOperacaoNaoRealizada('');
    }, []);

  return (
    <>
      <LoadingDiv isLoading={loading} />
      <Container>
        <ListaProvider>
          <SubHeader
            titulo="Importação de XML de Terceiro"
            permissao={permissao}
            botaoNovo={false}
            quantidadeColunaIncrementar={2}
            botoes={
              <AcoesImportacaoXmlTerceiro
                permissao={permissao}
                obterItensSelecionados={handleObterItensSelecionados}
                loading={loading}
                setLoading={setLoading}
                atualizar={atualizar}
                possuiItemSelecionado={possuiItemSelecionado}
              />
            }
            configuracaoItens={
              <>
                <Dropdown.Item
                  onClick={() => {
                    abrirJanelaLog(async (params) => {
                      return ImportacaoXmlTerceiroLogComunicador.index({
                        params,
                      });
                    });
                  }}
                >
                  <span style={{ display: 'flex', fontSize: 16 }}>
                    <CgPlayListSearch style={{ marginRight: 5 }} />
                    Logs
                  </span>
                </Dropdown.Item>
              </>
            }
          />
          <PesquisaAvancada />

          <Lista
            headerLista={
              <Container style={{ padding: 0 }}>
                <Divisor>
                  <FormCia
                    ref={formRef}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col lg={2} md={6} sm={12} style={{ minWidth: '230px' }}>
                        <Select
                          name="tipoDocumento"
                          label="Tipo Documento"
                          options={FormatarEnum({
                            enumObj: ImportacaoXmlTerceiroTipoEnum,
                            todos: true,
                          })}
                          onChange={atualizar}
                        />
                      </Col>

                      <Col
                        lg={2}
                        md={6}
                        sm={12}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                          paddingBottom: '7px',
                          minWidth: '270px',
                        }}
                      >
                        <OverlayTrigger
                          trigger={['focus', 'hover']}
                          placement={telaPequena ? 'bottom' : 'right'}
                          delay={{ show: 250, hide: 400 }}
                          show={showTriggerSincronizarGoverno}
                          overlay={
                            <Popover style={{ minWidth: 400 }} id={v4()}>
                              <Popover.Header>
                                <strong>Atenção!</strong>
                              </Popover.Header>
                              <Popover.Body style={{ textAlign: 'justify' }}>
                                <ul>
                                  <li>
                                    Consultas em curto intervalo de tempo geram
                                    consumo indevido, sendo assim o governo
                                    bloqueara o serviço por algum tempo!
                                  </li>
                                  <li>
                                    Quando as notas não forem baixadas na
                                    sincronização aguarde alguns minutos para
                                    sincronizar novamente para que o governo
                                    reconheça a Ciência da Operação e
                                    disponibilize para download sem gerar
                                    consumo indevido!
                                  </li>
                                </ul>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button
                            type="button"
                            onClick={handleConsultarGoverno}
                            onMouseEnter={() =>
                              setShowTriggerSincronizarGoverno(true)
                            }
                            onMouseLeave={() =>
                              setShowTriggerSincronizarGoverno(false)
                            }
                            className="btn-padrao btn-azul-escuro"
                            style={{
                              height: '35px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              whiteSpace: 'nowrap',
                            }}
                            disabled={loading}
                          >
                            <TextoLoading loading={loading}>
                              <CgPlayListSearch style={{ minWidth: '10%' }} />
                              <span style={{ marginLeft: 5, fontSize: '90%' }}>
                                Sincronizar com Governo
                              </span>
                            </TextoLoading>
                          </button>
                        </OverlayTrigger>
                      </Col>

                      <Col
                        lg={2}
                        md={6}
                        sm={12}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                          paddingBottom: '7px',
                          minWidth: '270px',
                        }}
                      >
                        <OverlayTrigger
                          trigger={['focus', 'hover']}
                          placement={telaPequena ? 'bottom' : 'right'}
                          delay={{ show: 250, hide: 400 }}
                          show={showTriggerConsultarNSUFaltantes}
                          overlay={
                            <Popover style={{ minWidth: 400 }} id={v4()}>
                              <Popover.Header>
                                <strong>Atenção!</strong>
                              </Popover.Header>
                              <Popover.Body style={{ textAlign: 'justify' }}>
                                <ul>
                                  <li>
                                    Consultas em curto intervalo de tempo geram
                                    consumo indevido, sendo assim o governo
                                    bloqueara o serviço por algum tempo!
                                  </li>
                                  <li>
                                    Consulta as NSU faltantes, podendo somente
                                    serem consultadas 20 por hora!
                                  </li>
                                </ul>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button
                            type="button"
                            onClick={handleConsultarNSUFaltantes}
                            onMouseEnter={() =>
                              setShowTriggerConsultarNSUFaltantes(true)
                            }
                            onMouseLeave={() =>
                              setShowTriggerConsultarNSUFaltantes(false)
                            }
                            className="btn-padrao btn-azul-escuro"
                            style={{
                              height: '35px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              whiteSpace: 'nowrap',
                            }}
                            disabled={loading}
                          >
                            <TextoLoading loading={loading}>
                              <CgPlayListSearch style={{ minWidth: '10%' }} />
                              <span style={{ marginLeft: 5, fontSize: '90%' }}>
                                Consultar NSU Faltantes
                              </span>
                            </TextoLoading>
                          </button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </FormCia>
                </Divisor>
              </Container>
            }
            pesquisarDados={handlePesquisarDados}
            pesquisarListaTh={handlePesquisarListaTh}
            detalhe={false}
            refresh={refresh}
            loading={carregarDados}
            styleLista={{ minHeight: 450 }}
          />
        </ListaProvider>
      </Container>

      {showOperacaoNaoRealizada && (
        <OperacaoNaoRealizada
          onSalvarFormModal={(justificativa) => {
            handleTransmitirOperacaoNaoRealizada(
              justificativa,
              idOperacaoNaoRealizada
            );
          }}
          onFecharFormModal={handleFecharOperacaoNaoRealizada}
        />
      )}
    </>
  );
};

export default ImportacaoXmlTerceiroLista;
