const DownloadArquivo = (() => {
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';

  return (blob: Blob, nomeArquivo: string) => {
    const url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = nomeArquivo;

    element.click();
  };
})();

export default DownloadArquivo;
