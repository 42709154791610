/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable default-case */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import {
  EstoqueIndicadorTipoEnum,
  FormatarEnum,
  FormatarRemovendoEnum,
  IListaEstoqueUtilizadoAutoComplete,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista,
  LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum,
  ObterRegraIndicadorTipoEstoqueSaida,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { IoMdAdd, IoMdRemoveCircleOutline } from 'react-icons/io/index.mjs';
import * as Yup from 'yup';
import { Tabela } from '../../../../../Componentes/Tabela/styles';
import InputDecimal from '../../../../../Componentes/Inputs/InputDecimal';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteUnidadeMedida from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import BtnPadraoButton from '../../../../../Componentes/Buttons/BtnPadraoButton';
import InputAutoCompleteLote from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLote';
import InputAutoCompleteLocalEstoque from '../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import BtnAcoesButton from '../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import { UltimaTr } from '../styles';
import TratarAvisos from '../../../../../Util/Aviso/TratarAvisos';
import InputAutoCompletePessoa from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';

export interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Ref {
  handleValidar(): Promise<boolean>;
  handleObterListaValorSelecionados(): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[];
}

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4: React.ForwardRefRenderFunction<
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Ref,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Props
> = ({ name, formRef, loading, setLoading, refresh }, ref) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();

  const valorRef = useRef<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[]
  >([]);

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<
      INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[] | undefined
    >({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterListaValorSelecionados =
    useCallback((): INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[] => {
      const data = formRef.current?.getData() as any;
      const retorno: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[] =
        [];

      listaValor.forEach((item, index) => {
        retorno.push({
          ...data.listaEtapa4[index],
          notaFiscalEntradaItemEstoque: {
            ...data.listaEtapa4[index].notaFiscalEntradaItemEstoque,
            listaEstoqueLocalEstoque:
              item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque,
          },
        });
      });

      return retorno;
    }, [formRef, listaValor]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    const listaMensagemAviso: string[] = [];
    const listaMensagemErro: string[] = [];

    const listaSelecionados = handleObterListaValorSelecionados();
    listaSelecionados.forEach((item, index) => {
      if (
        item.notaFiscalEntradaItemEstoque.porContaOrdemTerceiro &&
        !item.notaFiscalEntradaItemEstoque.pessoaTerceiro?.id
      ) {
        listaMensagemErro.push('Pessoa Terceiro é obrigatória!');
      }

      const totalEntrada =
        item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
          ?.filter(
            (
              estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
            ) => {
              return (
                estoque.tipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.saida
              );
            }
          )
          .reduce(
            (
              acumulador: number,
              estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(estoque.quantidade);
              return acumulador;
            },
            0
          );

      if (
        item.notaFiscalEntradaItemEstoque.movimentarEstoque &&
        Number(totalEntrada) >
          item.notaFiscalEntradaItemEstoque.quantidadeEstoque
      ) {
        listaMensagemErro.push(
          `Soma da quantidade dos locais de estoque das saídas é maior que a quantidade de estoque do Item: ${item.ordem}, Produto: ${item.produto.codigo} - ${item.produto.descricao}!`
        );
      }

      if (
        item.notaFiscalEntradaItemEstoque.movimentarEstoque &&
        Number(totalEntrada) <
          item.notaFiscalEntradaItemEstoque.quantidadeEstoque
      ) {
        listaMensagemErro.push(
          `Soma da quantidade dos locais de estoque das saídas é diferente da quantidade do estoque do Item: ${item.ordem}, Produto: ${item.produto.codigo} - ${item.produto.descricao}!`
        );
      }

      const indicadorTipoEstoque = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.indicadorTipoEstoque`
      );
      if (
        indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro ||
        indicadorTipoEstoque ===
          EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
      ) {
        const totalSaida =
          item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
            ?.filter(
              (
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                return (
                  estoque.tipoEntradaSaida ===
                  TipoMovimentoEntradaSaidaEnum.entrada
                );
              }
            )
            .reduce(
              (
                acumulador: number,
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                acumulador += Number(estoque.quantidade);
                return acumulador;
              },
              0
            );

        if (
          item.notaFiscalEntradaItemEstoque.movimentarEstoque &&
          Number(totalSaida) >
            item.notaFiscalEntradaItemEstoque.quantidadeEstoque
        ) {
          listaMensagemErro.push(
            `Soma da quantidade dos locais de estoque das entradas é maior que a quantidade de estoque do Item: ${item.ordem}, Produto: ${item.produto.codigo} - ${item.produto.descricao}!`
          );
        }

        if (
          item.notaFiscalEntradaItemEstoque.movimentarEstoque &&
          Number(totalSaida) <
            item.notaFiscalEntradaItemEstoque.quantidadeEstoque
        ) {
          listaMensagemErro.push(
            `Soma da quantidade dos locais de estoque das entradas é diferente da quantidade do estoque do Item: ${item.ordem}, Produto: ${item.produto.codigo} - ${item.produto.descricao}!`
          );
        }
      }
    });

    if (listaMensagemAviso.length > 0) {
      TratarAvisos(listaMensagemAviso);
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, [formRef, handleObterListaValorSelecionados]);

  useImperativeHandle(ref, () => {
    return {
      handleValidar,
      handleObterListaValorSelecionados,
    };
  });

  const handleAtualizarEstoqueQuantidade = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];

      const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`
      );
      const quantidadeEstoque = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.quantidadeEstoque`
      );

      if (estoqueTipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada) {
        const totalEntrada =
          itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
            ?.filter(
              (
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                return (
                  estoque.tipoEntradaSaida ===
                  TipoMovimentoEntradaSaidaEnum.entrada
                );
              }
            )
            .reduce(
              (
                acumulador: number,
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                acumulador += Number(estoque.quantidade);
                return acumulador;
              },
              0
            );

        const total = totalEntrada || 0;
        let estoqueQuantidade = quantidadeEstoque - total;
        if (estoqueQuantidade < 0) estoqueQuantidade = 0;

        formRef.current?.setFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueQuantidade`,
          estoqueQuantidade
        );
        itemValor.notaFiscalEntradaItemEstoque.estoqueQuantidade =
          estoqueQuantidade;
      } else {
        const totalSaida =
          itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
            ?.filter(
              (
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                return (
                  estoque.tipoEntradaSaida ===
                  TipoMovimentoEntradaSaidaEnum.saida
                );
              }
            )
            .reduce(
              (
                acumulador: number,
                estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
              ) => {
                acumulador += Number(estoque.quantidade);
                return acumulador;
              },
              0
            );

        const total = totalSaida || 0;
        let estoqueQuantidade = quantidadeEstoque - total;
        if (estoqueQuantidade < 0) estoqueQuantidade = 0;

        formRef.current?.setFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueQuantidade`,
          estoqueQuantidade
        );
        itemValor.notaFiscalEntradaItemEstoque.estoqueQuantidade =
          estoqueQuantidade;
      }
    },
    [formRef, listaValor]
  );

  // #region Obter

  const handleObterMovimentarEstoque = useCallback(
    (index: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.movimentarEstoque`
      );
    },
    [formRef]
  );

  const handleObterMovimentarEstoqueFiscal = useCallback(
    (index: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal`
      );
    },
    [formRef]
  );

  const handleObterIndicadorTipoEstoque = useCallback(
    (index: number): EstoqueIndicadorTipoEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.indicadorTipoEstoque`
      );
    },
    [formRef]
  );

  const handleObterPorContaOrdemTerceiro = useCallback(
    (index: number): boolean => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.porContaOrdemTerceiro`
      );
    },
    [formRef]
  );

  const handleObterPessoaTerceiroFormatada = useCallback(
    (index: number): any | undefined => {
      const { pessoa } = listaValor[index];
      const porContaOrdemTerceiro = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.porContaOrdemTerceiro`
      );
      const pessoaTerceiro = formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.pessoaTerceiro`
      );

      const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`
      );
      const estoqueIndicadorTipoEstoque = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`
      );

      if (
        estoqueIndicadorTipoEstoque ===
        EstoqueIndicadorTipoEnum.proprioEmPoderProprio
      )
        return undefined;

      if (porContaOrdemTerceiro && pessoaTerceiro) {
        if (
          estoqueIndicadorTipoEstoque ===
            EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro &&
          estoqueTipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.saida
        )
          return pessoa;

        return pessoaTerceiro;
      }

      return pessoa;
    },
    [formRef, listaValor]
  );

  const handleObterEstoqueTipoEntradaSaida = useCallback(
    (index: number): TipoMovimentoEntradaSaidaEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`
      );
    },
    [formRef]
  );

  const handleObterEstoqueIndicadorTipoEstoque = useCallback(
    (index: number): EstoqueIndicadorTipoEnum => {
      return formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`
      );
    },
    [formRef]
  );

  const handleObterEstoqueLocalEstoque = useCallback(
    (index: number): any | undefined => {
      return formRef.current?.getFieldValueNomeObjeto(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`
      );
    },
    [formRef]
  );

  const handleObterListaEstoqueUtilizado = useCallback(
    (index: number): IListaEstoqueUtilizadoAutoComplete[] => {
      const itemValor = listaValor[index];

      if (!itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque)
        return [];

      const retorno =
        itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque.map(
          (valor) => {
            return {
              idItemEstoqueLocalEstoque: '',
              idLocalEstoque: valor.localEstoque?.id,
              idLote: valor.lote?.id,
              quantidade: valor.quantidade,
              tipoEntradaSaida: valor.tipoEntradaSaida,
              indicadorTipoEstoque: valor.indicadorTipoEstoque,
            };
          }
        );

      return retorno;
    },
    [listaValor]
  );

  // #endregion Obter

  // #region Estoque

  const handleAtualizarQuantidadeTotalEstoqueLocalEstoque = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];

      const total =
        itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque?.reduce(
          (acumulador: number, item: any) => {
            acumulador += Number(item.quantidade);
            return acumulador;
          },
          0
        );

      itemValor.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque =
        Number(total);

      await handleAtualizarEstoqueQuantidade(index);
      refresh();
    },
    [handleAtualizarEstoqueQuantidade, listaValor, refresh]
  );

  const handleOnAlterarIndicadorTipoEstoque = useCallback(
    async (index: number) => {
      const itemValor = listaValor[index];
      itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque = [];
      itemValor.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque = 0;

      const indicadorTipoEstoque = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.indicadorTipoEstoque`
      );

      if (
        indicadorTipoEstoque !== EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
      ) {
        formRef.current?.setFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal`,
          false
        );
      }

      switch (indicadorTipoEstoque) {
        case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio
          );

          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.porContaOrdemTerceiro`,
            false
          );

          itemValor.notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.saida;
          itemValor.notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          itemValor.notaFiscalEntradaItemEstoque.porContaOrdemTerceiro = false;
          break;

        case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio
          );

          itemValor.notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.saida;
          itemValor.notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
          );

          itemValor.notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.saida;
          itemValor.notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`,
            TipoMovimentoEntradaSaidaEnum.saida
          );
          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
          );

          formRef.current?.setFieldValue(
            `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.porContaOrdemTerceiro`,
            true
          );

          itemValor.notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida =
            TipoMovimentoEntradaSaidaEnum.saida;
          itemValor.notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;
          itemValor.notaFiscalEntradaItemEstoque.porContaOrdemTerceiro = true;
          break;
      }

      formRef.current?.clearField(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`
      );
      formRef.current?.clearField(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`
      );

      await handleAtualizarEstoqueQuantidade(index);
    },
    [formRef, handleAtualizarEstoqueQuantidade, listaValor]
  );

  const handleOnAlterarFatorConversao = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];
      const fatorConversao = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.fatorConversao`
      );

      const quantidadeEstoque = (
        Number(itemValor.quantidadeTransferir) * Number(fatorConversao)
      ).Arredondar(4);
      formRef.current?.setFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.quantidadeEstoque`,
        quantidadeEstoque
      );
      itemValor.notaFiscalEntradaItemEstoque.quantidadeEstoque =
        quantidadeEstoque;
    },
    [formRef, listaValor]
  );

  const handleOnAlterarQuantidadeEstoque = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];
      const quantidadeEstoque = formRef.current?.getFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.quantidadeEstoque`
      );

      const fatorConversao = (
        itemValor.quantidadeTransferir
          ? Number(quantidadeEstoque) / Number(itemValor.quantidadeTransferir)
          : 0
      ).Arredondar(10);
      formRef.current?.setFieldValue(
        `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.fatorConversao`,
        fatorConversao
      );
      itemValor.notaFiscalEntradaItemEstoque.fatorConversao = fatorConversao;
    },
    [formRef, listaValor]
  );

  const handleAtualizarQuantidadeEstoqueLocalEstoque = useCallback(
    async (index: number): Promise<void> => {
      const itemValor = listaValor[index];

      if (
        itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
          ?.length === 1
      ) {
        const quantidadeEstoque = formRef.current?.getFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.quantidadeEstoque`
        );

        itemValor.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque[0].quantidade =
          Number(quantidadeEstoque);
        itemValor.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque =
          Number(quantidadeEstoque);
      }

      await handleAtualizarEstoqueQuantidade(index);
      refresh();
    },
    [formRef, handleAtualizarEstoqueQuantidade, listaValor, refresh]
  );

  const handleValidarEstoqueLocalEstoque = useCallback(
    async (data: any, index: number): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const produtoControlaLote =
          listaValor[index].produto.produtoEstoque.controlarLote;

        const schema = Yup.object().shape({
          localEstoque: Yup.mixed()
            .nullable()
            .test({
              message: 'Local de Estoque é obrigatório!',
              test: () => {
                return !!data.localEstoque?.id;
              },
            }),

          lote: Yup.mixed()
            .nullable()
            .test({
              message: 'Lote é obrigatório!',
              test: () => {
                return !(!data.lote?.id && produtoControlaLote);
              },
            }),

          quantidade: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade deve ser maior que zero!',
              test: () => {
                return !(data.quantidade === 0);
              },
            }),

          tipoEntradaSaida: Yup.string()
            .oneOf(Object.values(TipoMovimentoEntradaSaidaEnum))
            .required('Tipo Entrada/Saída é obrigatório!'),

          indicadorTipoEstoque: Yup.string()
            .oneOf(Object.values(EstoqueIndicadorTipoEnum))
            .required('Tipo de Estoque é obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef, listaValor]
  );

  const handleAdicionarEstoqueLocalEstoque = useCallback(
    async (index: number): Promise<void> => {
      try {
        setLoading(true);

        const item = listaValor[index];

        const indicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.indicadorTipoEstoque`
        );

        const estoqueTipoEntradaSaida = formRef.current?.getFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`
        );
        const estoqueIndicadorTipoEstoque = formRef.current?.getFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`
        );
        const estoqueLocalEstoque = formRef.current?.getFieldValueNomeObjeto(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`
        );
        const estoqueLote = formRef.current?.getFieldValueNomeObjeto(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`
        );
        const estoqueQuantidade = formRef.current?.getFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueQuantidade`
        );

        const dados: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista =
          {
            localEstoque: estoqueLocalEstoque,
            lote: estoqueLote ?? undefined,
            quantidade: Number(estoqueQuantidade),
            tipoEntradaSaida: estoqueTipoEntradaSaida,
            indicadorTipoEstoque: estoqueIndicadorTipoEstoque,
          };

        const pessoaTerceiroFormatada =
          handleObterPessoaTerceiroFormatada(index);
        if (pessoaTerceiroFormatada) {
          dados.pessoaTerceiro = pessoaTerceiroFormatada;
        }

        if (!(await handleValidarEstoqueLocalEstoque(dados, index))) {
          setLoading(false);
          return;
        }

        item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque?.push(dados);

        switch (indicadorTipoEstoque) {
          case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
            formRef.current?.setFieldValue(
              `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
            formRef.current?.setFieldValue(
              `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
            formRef.current?.setFieldValue(
              `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
            );
            break;

          case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
            formRef.current?.setFieldValue(
              `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
              EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
            );
            break;
        }

        formRef.current?.setFieldValue(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`,
          TipoMovimentoEntradaSaidaEnum.saida
        );
        formRef.current?.clearField(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`
        );
        formRef.current?.clearField(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`
        );
        formRef.current?.clearField(
          `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueQuantidade`
        );

        handleAtualizarQuantidadeTotalEstoqueLocalEstoque(index);

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [
      formRef,
      handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
      handleObterPessoaTerceiroFormatada,
      handleValidarEstoqueLocalEstoque,
      listaValor,
      refresh,
      setLoading,
    ]
  );

  const handleExcluirEstoqueLocalEstoque = useCallback(
    async (index: number, indexEstoqueLocalEstoque: number): Promise<void> => {
      try {
        setLoading(true);

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>Deseja remover o item?</span>
          ),
        });

        if (resposta) {
          const item = listaValor[index];

          item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque?.splice(
            indexEstoqueLocalEstoque,
            1
          );

          handleAtualizarQuantidadeTotalEstoqueLocalEstoque(index);
        }

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    },
    [
      abrirJanela,
      handleAtualizarQuantidadeTotalEstoqueLocalEstoque,
      listaValor,
      refresh,
      setLoading,
    ]
  );

  // #endregion Estoque

  return (
    <>
      <Tabela style={{ overflow: 'auto', height: 500 }}>
        <Table striped bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '9%' }}>
                <span>Núm. Nota</span>
              </th>
              <th className="lista-valor" style={{ width: '2%' }}>
                <span>Ord.</span>
              </th>
              <th className="lista-texto" style={{ width: '23%' }}>
                <span>Produto</span>
              </th>
              <th className="lista-texto" style={{ width: '9%' }}>
                <span>UN</span>
              </th>
              <th className="lista-valor" style={{ width: '12%' }}>
                <span>Quantidade</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Qtde. Transferir</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Valor Unitário</span>
              </th>
              <th className="lista-valor" style={{ width: '15%' }}>
                <span>Sub. Total</span>
              </th>
            </tr>
          </thead>

          <tbody className="tabela-desabilitada">
            {listaValor.map((item, index) => {
              return (
                <>
                  <tr>
                    <td className="lista-texto">
                      <div>{item.numero}</div>
                    </td>
                    <td className="lista-valor">
                      <div>{item.ordem}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{`${item.produto.codigo} - ${item.produto.descricao}`}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{item.unidadeMedida.sigla}</div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidade).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.quantidadeTransferir).FormatarParaPtBr({
                          minimoCasasDecimais: 4,
                          maximoCasasDecimais: 4,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>
                        {Number(item.valorUnitario).FormatarParaPtBr({
                          minimoCasasDecimais: 10,
                          maximoCasasDecimais: 10,
                        })}
                      </div>
                    </td>
                    <td className="lista-valor">
                      <div>{Number(item.valorTotal).FormatarParaPtBr()}</div>
                    </td>
                  </tr>

                  {!item.notaFiscalEntradaItemEstoque.permiteTransferirItem ? (
                    <tr>
                      <td colSpan={8} style={{ padding: 0, paddingBottom: 20 }}>
                        <Divisor style={{ borderRadius: 0, marginBottom: 0 }}>
                          <h6
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              margin: 0,
                              padding: 5,
                            }}
                          >
                            ITEM NÃO POSSUI ESTOQUE FISCAL E NÃO SERÁ
                            TRANSFERIDO PARA A NOTA
                          </h6>
                        </Divisor>
                      </td>
                    </tr>
                  ) : item.produto.produtoEstoque.controlarEstoque ? (
                    <tr>
                      <td colSpan={8} style={{ padding: 0, paddingBottom: 20 }}>
                        <Divisor style={{ borderRadius: 0, marginBottom: 0 }}>
                          <Row>
                            <Col lg={4} md={6} sm={12}>
                              <Select
                                label="Tipo de Estoque"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.indicadorTipoEstoque`}
                                options={FormatarRemovendoEnum({
                                  enumObj: EstoqueIndicadorTipoEnum,
                                  enumRemover: [
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                                  ],
                                })}
                                onChange={() => {
                                  refresh();
                                  handleOnAlterarIndicadorTipoEstoque(index);
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={3} md={6} sm={12}>
                              <InputSwitch
                                label="Movimentar Estoque"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.movimentarEstoque`}
                                ativo="Sim"
                                inativo="Não"
                                onChange={() => {
                                  refresh();
                                }}
                              />
                            </Col>
                            <Col
                              lg={3}
                              md={6}
                              sm={12}
                              style={{
                                display:
                                  handleObterIndicadorTipoEstoque(index) !==
                                  EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
                                    ? 'none'
                                    : '',
                              }}
                            >
                              <InputSwitch
                                label="Movimentar Estoque Fiscal"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal`}
                                ativo="Sim"
                                inativo="Não"
                                onChange={() => {
                                  refresh();
                                }}
                              />
                            </Col>
                          </Row>

                          <Row
                            style={{
                              display: !handleObterMovimentarEstoque(index)
                                ? 'none'
                                : '',
                            }}
                          >
                            <Col lg={4} md={12} sm={12}>
                              <InputAutoCompleteUnidadeMedida
                                label="Unidade de Medida de Estoque"
                                placeholder="Unidade de Medida de Estoque"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.unidadeMedida.id`}
                                nomeObjeto={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.unidadeMedida`}
                                permitirAdicionar={false}
                              />
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <InputDecimal
                                label="Fator Conversao"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.fatorConversao`}
                                casasInteiras={11}
                                casasDecimais={10}
                                onChange={() => {
                                  handleOnAlterarFatorConversao(index);
                                }}
                                onBlur={() => {
                                  handleAtualizarQuantidadeEstoqueLocalEstoque(
                                    index
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <InputDecimal
                                label="Qtde. Estoque (Qtde. * Fat. Conv.)"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.quantidadeEstoque`}
                                casasInteiras={11}
                                casasDecimais={4}
                                onChange={() => {
                                  handleOnAlterarQuantidadeEstoque(index);
                                }}
                                onBlur={() => {
                                  handleAtualizarQuantidadeEstoqueLocalEstoque(
                                    index
                                  );
                                }}
                              />
                            </Col>
                          </Row>

                          <Row
                            style={{
                              display:
                                !handleObterMovimentarEstoque(index) &&
                                !handleObterMovimentarEstoqueFiscal(index)
                                  ? 'none'
                                  : '',
                            }}
                          >
                            <Col lg={2} md={3} sm={12}>
                              <InputSwitch
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.porContaOrdemTerceiro`}
                                label="Por Conta e Ord. Terc."
                                ativo="Sim"
                                inativo="Não"
                                onChange={async () => {
                                  refresh();
                                  item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque =
                                    [];
                                  item.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque = 0;
                                  await handleAtualizarEstoqueQuantidade(index);
                                }}
                                disabled={
                                  handleObterIndicadorTipoEstoque(index) ===
                                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                                  handleObterIndicadorTipoEstoque(index) ===
                                    EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
                                }
                              />
                            </Col>
                            <Col
                              lg={10}
                              md={9}
                              sm={12}
                              style={{
                                display: !handleObterPorContaOrdemTerceiro(
                                  index
                                )
                                  ? 'none'
                                  : '',
                              }}
                            >
                              <InputAutoCompletePessoa
                                label="Pessoa Terceiro"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.pessoaTerceiro.id`}
                                nomeObjeto={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.pessoaTerceiro`}
                                placeholder="Pessoa Terceiro"
                                permitirAdicionar={false}
                                onChangeItemAtual={async () => {
                                  refresh();
                                  item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque =
                                    [];
                                  item.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque = 0;
                                  await handleAtualizarEstoqueQuantidade(index);
                                }}
                              />
                            </Col>
                          </Row>
                        </Divisor>

                        <Divisor
                          style={{
                            borderRadius: 0,
                            display: !handleObterMovimentarEstoque(index)
                              ? 'none'
                              : '',
                          }}
                        >
                          <Row>
                            <Col lg={3} md={6} sm={12}>
                              <Select
                                label="Tipo Entrada/Saída"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida`}
                                options={FormatarEnum({
                                  enumObj: TipoMovimentoEntradaSaidaEnum,
                                })}
                                onChangeValue={() => {
                                  refresh();
                                }}
                                onChange={async (event) => {
                                  refresh();

                                  formRef.current?.clearField(
                                    `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`
                                  );
                                  formRef.current?.clearField(
                                    `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`
                                  );

                                  const indicadorEstoqueAtualizado =
                                    ObterRegraIndicadorTipoEstoqueSaida({
                                      indicadorTipoEstoque:
                                        handleObterIndicadorTipoEstoque(index),
                                      tipoEntradaSaida: event.currentTarget
                                        .value as TipoMovimentoEntradaSaidaEnum,
                                    });

                                  formRef.current?.setFieldValue(
                                    `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`,
                                    indicadorEstoqueAtualizado
                                  );

                                  await handleAtualizarEstoqueQuantidade(index);
                                }}
                                disabled={
                                  handleObterIndicadorTipoEstoque(index) ===
                                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                                  handleObterIndicadorTipoEstoque(index) ===
                                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
                                }
                              />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                              <Select
                                label="Tipo de Estoque"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque`}
                                options={FormatarRemovendoEnum({
                                  enumObj: EstoqueIndicadorTipoEnum,
                                  enumRemover: [
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemRetornoProprio,
                                    EstoqueIndicadorTipoEnum.proprioEmPoderTerceiroSemTransicaoAdquirinte,
                                  ],
                                })}
                                disabled
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              lg={
                                item.produto.produtoEstoque.controlarLote
                                  ? 5
                                  : 8
                              }
                              md={12}
                            >
                              <InputAutoCompleteLocalEstoque
                                label="Local de Estoque"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIdLocalEstoque`}
                                nomeObjeto={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLocalEstoque`}
                                permitirAdicionar={
                                  handleObterEstoqueTipoEntradaSaida(index) ===
                                  TipoMovimentoEntradaSaidaEnum.entrada
                                }
                                saldoEstoque={{
                                  somenteProdutoComSaldoEstoque:
                                    handleObterEstoqueTipoEntradaSaida(
                                      index
                                    ) === TipoMovimentoEntradaSaidaEnum.saida,
                                  idPessoa:
                                    handleObterPessoaTerceiroFormatada(index)
                                      ?.id,
                                  idProduto: item.produto?.id,
                                  indicadorTipoEstoque:
                                    handleObterEstoqueIndicadorTipoEstoque(
                                      index
                                    ),
                                  dataHoraEmissao: new Date(),
                                  tipoEntradaSaida:
                                    handleObterEstoqueTipoEntradaSaida(index),

                                  localUtilizado:
                                    LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.notaFiscalEntrada,
                                  listaEstoqueUtilizado:
                                    handleObterListaEstoqueUtilizado(index),
                                  exibirSaldoEstoque: true,
                                }}
                                onChangeItemAtual={() => {
                                  refresh();
                                  formRef.current?.clearField(
                                    `listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`
                                  );
                                }}
                              />
                            </Col>
                            <Col
                              lg={3}
                              md={12}
                              style={{
                                display: item.produto.produtoEstoque
                                  .controlarLote
                                  ? ''
                                  : 'none',
                              }}
                            >
                              <InputAutoCompleteLote
                                label="Lote"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueIdLote`}
                                nomeObjeto={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueLote`}
                                permitirAdicionar={
                                  handleObterEstoqueTipoEntradaSaida(index) ===
                                  TipoMovimentoEntradaSaidaEnum.entrada
                                }
                                saldoEstoque={{
                                  somenteProdutoComSaldoEstoque:
                                    handleObterEstoqueTipoEntradaSaida(
                                      index
                                    ) === TipoMovimentoEntradaSaidaEnum.saida,
                                  idPessoa:
                                    handleObterPessoaTerceiroFormatada(index)
                                      ?.id,
                                  idProduto: item.produto?.id,
                                  indicadorTipoEstoque:
                                    handleObterEstoqueIndicadorTipoEstoque(
                                      index
                                    ),
                                  idLocalEstoque:
                                    handleObterEstoqueLocalEstoque(index)?.id,
                                  dataHoraEmissao: new Date(),
                                  tipoEntradaSaida:
                                    handleObterEstoqueTipoEntradaSaida(index),

                                  localUtilizado:
                                    LocalUtilizadoAutoCompleteLocalEstoqueLoteEnum.notaFiscalEntrada,
                                  listaEstoqueUtilizado:
                                    handleObterListaEstoqueUtilizado(index),
                                  exibirSaldoEstoque: true,
                                }}
                              />
                            </Col>
                            <Col lg={2} md={12}>
                              <InputDecimal
                                label="Quantidade"
                                name={`listaEtapa4[${index}].notaFiscalEntradaItemEstoque.estoqueQuantidade`}
                                casasInteiras={11}
                                casasDecimais={4}
                              />
                            </Col>
                            <Col
                              lg={2}
                              md={12}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              <BtnPadraoButton
                                style={{ maxWidth: telaGrande ? 250 : '' }}
                                type="button"
                                onClick={() => {
                                  handleAdicionarEstoqueLocalEstoque(index);
                                }}
                                className="btn-padrao btn-verde-claro btn-adicionar"
                              >
                                <TextoLoading loading={loading}>
                                  <IoMdAdd />
                                  <span style={{ marginLeft: 10 }}>
                                    Adicionar
                                  </span>
                                </TextoLoading>
                              </BtnPadraoButton>
                            </Col>
                          </Row>

                          <Tabela style={{ overflow: 'auto' }}>
                            <Table striped hover bordered variant="light">
                              <thead>
                                <tr>
                                  <th
                                    className="lista-texto"
                                    style={{ width: '25%' }}
                                  >
                                    <span>Tipo de Estoque</span>
                                  </th>
                                  <th
                                    className="lista-texto"
                                    style={{ width: '10%' }}
                                  >
                                    <span>Ent./Saída</span>
                                  </th>
                                  <th
                                    className="lista-texto"
                                    style={{
                                      width: item.produto.produtoEstoque
                                        .controlarLote
                                        ? '35%'
                                        : '50%',
                                    }}
                                  >
                                    <span>Local de Estoque</span>
                                  </th>

                                  {item.produto.produtoEstoque
                                    .controlarLote && (
                                    <th
                                      className="lista-texto"
                                      style={{ width: '15%' }}
                                    >
                                      <span>Lote</span>
                                    </th>
                                  )}

                                  <th
                                    className="lista-valor"
                                    style={{ width: '15%' }}
                                  >
                                    <span>Quantidade</span>
                                  </th>
                                  <th className="lista-acoes">
                                    <span>Ações</span>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {item.notaFiscalEntradaItemEstoque
                                  .listaEstoqueLocalEstoque &&
                                  item.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque.map(
                                    (
                                      estoqueLocalEstoque,
                                      indexEstoqueLocalEstoque
                                    ) => {
                                      return (
                                        <>
                                          <tr
                                            style={{
                                              backgroundColor:
                                                indexEstoqueLocalEstoque % 2 ===
                                                0
                                                  ? '#f0f0f1'
                                                  : '#fff',
                                            }}
                                          >
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque.indicadorTipoEstoque
                                                }
                                              </div>
                                            </td>
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque.tipoEntradaSaida
                                                }
                                              </div>
                                            </td>
                                            <td className="lista-texto">
                                              <div>
                                                {
                                                  estoqueLocalEstoque
                                                    .localEstoque.descricao
                                                }
                                              </div>
                                            </td>

                                            {item.produto.produtoEstoque
                                              .controlarLote && (
                                              <td className="lista-texto">
                                                <div>
                                                  {
                                                    estoqueLocalEstoque.lote
                                                      ?.codigo
                                                  }
                                                </div>
                                              </td>
                                            )}

                                            <td className="lista-valor">
                                              <div>
                                                {Number(
                                                  estoqueLocalEstoque.quantidade
                                                )?.FormatarParaPtBr({
                                                  minimoCasasDecimais: 4,
                                                  maximoCasasDecimais: 4,
                                                })}
                                              </div>
                                            </td>
                                            <td className="tdButton lista-acoes">
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <BtnAcoesButton>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      handleExcluirEstoqueLocalEstoque(
                                                        index,
                                                        indexEstoqueLocalEstoque
                                                      );
                                                    }}
                                                    disabled={loading}
                                                  >
                                                    <span
                                                      style={{
                                                        display: 'flex',
                                                        fontSize: 16,
                                                      }}
                                                    >
                                                      <IoMdRemoveCircleOutline
                                                        style={{
                                                          marginRight: 5,
                                                        }}
                                                      />
                                                      Remover
                                                    </span>
                                                  </Dropdown.Item>
                                                </BtnAcoesButton>
                                              </div>
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              backgroundColor:
                                                indexEstoqueLocalEstoque % 2 ===
                                                0
                                                  ? '#f0f0f1'
                                                  : '#fff',
                                              display:
                                                estoqueLocalEstoque.indicadorTipoEstoque ===
                                                EstoqueIndicadorTipoEnum.proprioEmPoderProprio
                                                  ? 'none'
                                                  : '',
                                            }}
                                          >
                                            <td
                                              colSpan={7}
                                              style={{ paddingTop: 0 }}
                                            >
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {`PESSOA: `}
                                              </span>
                                              <span>
                                                {
                                                  estoqueLocalEstoque
                                                    .pessoaTerceiro
                                                    ?.nomeRazaoSocial
                                                }
                                              </span>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </tbody>

                              <UltimaTr
                                style={{ borderTop: '2px solid lightgray' }}
                              >
                                <th />
                                <th />
                                <th />
                                {item.produto.produtoEstoque.controlarLote && (
                                  <th />
                                )}
                                <th>
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div style={{ fontSize: '13px' }}>
                                      Qtde. Total
                                    </div>
                                    <div
                                      style={{
                                        alignSelf: 'flex-end',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {Number(
                                        item.notaFiscalEntradaItemEstoque
                                          .quantidadeTotalEstoqueLocalEstoque
                                      )
                                        .Arredondar(4)
                                        .FormatarParaPtBr({
                                          minimoCasasDecimais: 4,
                                          maximoCasasDecimais: 4,
                                        })}
                                    </div>
                                  </div>
                                </th>
                                <th />
                              </UltimaTr>
                            </Table>
                          </Tabela>
                        </Divisor>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={8} style={{ padding: 0, paddingBottom: 20 }}>
                        <Divisor
                          style={{
                            borderRadius: 0,
                            marginBottom: 0,
                            padding: 0,
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </Tabela>
    </>
  );
};

export default forwardRef(NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4);
