import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DestinadoEnum,
  FormatarEnum,
  ICustoConfiguracaoItemCreditarLista,
  TipoImpostoCustosEnum,
} from '@elogestor/util';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Select from '../../../../../../../Componentes/Select';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheRepresentanteProps {
  name: string;
}

const CustoConfiguracaoItemCreditarLista: React.FC<
  IListaDetalheRepresentanteProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { SuprimentosCustoConfiguracao: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();

  const valorRef = useRef<ICustoConfiguracaoItemCreditarLista[]>([]);

  const [listaValor, setListaValor] = useState<
    ICustoConfiguracaoItemCreditarLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<ICustoConfiguracaoItemCreditarLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const data = formRefLista.current?.getData() as any;
    await handleSubmit(data, formRefLista.current);

    setIdDetalheRegistro('');
  }, [formRefLista, handleSubmit, setIdDetalheRegistro]);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <div style={{ width: '100%' }}>
          <h5>Configuração dos Créditos</h5>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Imposto"
                name="tipoImposto"
                options={FormatarEnum({
                  enumObj: TipoImpostoCustosEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Select
                label="Destinado à"
                name="destinado"
                options={FormatarEnum({
                  enumObj: DestinadoEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col
              lg={2}
              md={12}
              sm={12}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'start',
              }}
            >
              <BtnPadraoButton
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </div>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '50%' }}>
                <span>Imposto</span>
              </th>
              <th className="lista-texto" style={{ width: '50%' }}>
                <span>Destinado à</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor &&
              listaValor.map((itemCreditar) => {
                return (
                  <tr
                    key={v4()}
                    className={
                      formPrincipal.loading || loading
                        ? 'tabela-desabilitada'
                        : ''
                    }
                  >
                    <td className="lista-texto">
                      <div>{itemCreditar.tipoImposto}</div>
                    </td>
                    <td className="lista-texto">
                      <div>{itemCreditar.destinado}</div>
                    </td>

                    <td className="tdButton lista-acoes">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <BtnAcoesButton>
                          <Dropdown.Item
                            onClick={() => {
                              handleExcluir(itemCreditar.id);
                            }}
                            disabled={
                              !permissao?.exclui ||
                              formPrincipal.loading ||
                              loading
                            }
                          >
                            <span style={{ display: 'flex', fontSize: 16 }}>
                              <AiOutlineDelete style={{ marginRight: 5 }} />
                              Excluir
                            </span>
                          </Dropdown.Item>
                        </BtnAcoesButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </TBody>
        </Table>
      </Tabela>
    </Container>
  );
};

export default CustoConfiguracaoItemCreditarLista;
