import {
  SituacaoConhecimentoTransporteEnum,
  FormatarEnum,
  IConhecimentoTransporteTerceiroValoresAlterar,
  StringConverterParaEnum,
  TipoCteEnum,
  TipoServicoEnum,
  TipoTomadorEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { v4 } from 'uuid';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import Divisor from '../../../../../../Componentes/Divisor';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import Select from '../../../../../../Componentes/Select';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseShowTabs } from '../Hooks/ShowTabsHook';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteModeloDocumento from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import ParticipantesTab from '../ParticipantesTab';
import ServicoTab from '../ServicoTab';
import CargaTab from '../CargaTab';
import NotaReferenciadaTab from '../NotaReferenciadaTab';
import DocumentoReferenciadoTab from '../DocumentoReferenciadoTab';
import InformacaoAdicionalTab from '../InformacaoAdicionalTab';
import ImpostoTab from '../ImpostoTab';
import CteComplementadoTab from '../CteComplementadoTab';
import AnulacaoTab from '../AnulacaoTab';
import SubstitutoTab from '../SubstitutoTab';
import DocumentoAnteriorTab from '../DocumentoAnteriorTab';
import PrincipalTab from '../PrincipalTab';
import AutorizacaoTab from '../AutorizacaoTab';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';

type IProps = IMain<IConhecimentoTransporteTerceiroValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const { setRegimeTributarioPorData } = UseRegimeTributario();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
    setLoading,
    inputRefFocus,
  } = UseForm();

  const { showNotaReferenciadaTab } = UseShowTabs();

  const [bloquearCamposPorImportacao, setBloquearCamposPorImportacao] =
    useState(false);

  const [tabSelecionada, setTabSelecionada] = useState('principal');
  const [tipoTomador, setTipoTomador] = useState<TipoTomadorEnum | null>(null);
  const [tipoCte, setTipoCte] = useState<TipoCteEnum>(TipoCteEnum.normal);
  const [tipoServico, setTipoServico] = useState<TipoServicoEnum>(
    TipoServicoEnum.normal
  );

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleChangeTipoTomador = useCallback((value?: string | null) => {
    if (!value) return;

    const tipoTomadorValue = StringConverterParaEnum<
      typeof TipoTomadorEnum,
      TipoTomadorEnum
    >(TipoTomadorEnum, value);

    setTipoTomador(tipoTomadorValue);
  }, []);

  const handleChangeTipoServico = useCallback(
    (value: string) => {
      const tipoServicoValue = StringConverterParaEnum<
        typeof TipoServicoEnum,
        TipoServicoEnum
      >(TipoServicoEnum, value);

      if (
        tipoServicoValue !== TipoServicoEnum.subcontratacao &&
        tipoServicoValue !== TipoServicoEnum.redespacho &&
        tipoServicoValue !== TipoServicoEnum.redespachoIntermediario
      ) {
        if (tabSelecionada === 'documentosAnteriores')
          setTabSelecionada('principal');
      }

      setTipoServico(tipoServicoValue);
    },
    [tabSelecionada]
  );

  const handleChangeTipoCte = useCallback(
    (value: string) => {
      const tipoCteValue = StringConverterParaEnum<
        typeof TipoCteEnum,
        TipoCteEnum
      >(TipoCteEnum, value);

      if (tipoCteValue !== TipoCteEnum.complementoValores) {
        if (tabSelecionada === 'complemento') setTabSelecionada('principal');
      }

      if (tipoCteValue !== TipoCteEnum.anulacao) {
        formRef.current?.setFieldError('anulacaoChaveAcesso', '');
        formRef.current?.setFieldError('anulacaoDataEmissao', '');

        if (tabSelecionada === 'anulacao') setTabSelecionada('principal');
      }

      if (tipoCteValue !== TipoCteEnum.substituto) {
        formRef.current?.setFieldError('substitutoChaveAcessoCteOriginal', '');
        formRef.current?.setFieldError('substitutoChaveAcessoCteAnulacao', '');
        formRef.current?.setFieldError('substitutoChaveAcessoNfe', '');
        formRef.current?.setFieldError(
          'substitutoChaveAcessoCteEmitTomador',
          ''
        );

        if (tabSelecionada === 'substituto') setTabSelecionada('principal');
      }

      setTipoCte(tipoCteValue);
    },
    [formRef, tabSelecionada]
  );

  const handleOnAlterarDataHoraEmissao = useCallback(async () => {
    try {
      setLoading(true);

      const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');

      await setRegimeTributarioPorData(new Date(dataHoraEmissao));

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading, setRegimeTributarioPorData]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  const currentTipoTomador = formRef.current?.getFieldValue('tipoTomador');

  useEffect(() => {
    handleChangeTipoTomador(currentTipoTomador);
  }, [currentTipoTomador, handleChangeTipoTomador]);

  const currentTipoCte = formRef.current?.getFieldValue('tipoCte');
  useEffect(() => {
    handleChangeTipoCte(currentTipoCte);
  }, [currentTipoCte, handleChangeTipoCte]);

  const currentTipoServico = formRef.current?.getFieldValue('tipoServico');
  useEffect(() => {
    handleChangeTipoServico(currentTipoServico);
  }, [currentTipoServico, handleChangeTipoServico]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
    setLoading,
  ]);

  const idImportacaoXmlAutorizacao = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroTransmissao.idImportacaoXmlAutorizacao'
  );

  useEffect(() => {
    if (idImportacaoXmlAutorizacao) {
      setBloquearCamposPorImportacao(true);
    }
  }, [bloquearCamposPorImportacao, idImportacaoXmlAutorizacao]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <InputHiddenHtml name="financeiroConferido" />
        <InputHiddenHtml name="valorFinanceiro" />

        <Divisor>
          <Row>
            <Col xl={3} md={12}>
              <InputAutoCompleteModeloDocumento
                ref={inputRefFocus}
                label="Modelo de Documento"
                name="idModeloDocumento"
                placeholder="Modelo de Documento"
                nomeObjeto="modeloDocumento"
                disabled={bloquearCamposPorImportacao}
                listaTipoDocumentoFiscal={[
                  ModeloDocumentoTipoEnum.conhecimentoTransporte,
                ]}
              />
            </Col>
            <Col lg={2} md={6}>
              <InputInteiroNulavel
                label="Série"
                name="serie"
                placeholder="Série"
                disabled={bloquearCamposPorImportacao}
              />
            </Col>
            <Col xl={2} lg={3} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled={bloquearCamposPorImportacao}
              />
            </Col>
            <Col xl={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Emissão"
                name="dataHoraEmissao"
                onChange={async () => {
                  setLoading(true);
                  await handleOnAlterarDataHoraEmissao();
                  setLoading(false);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </Col>
            <Col xl={2} md={6} sm={12}>
              <Select
                name="situacao"
                label="Situação"
                options={FormatarEnum({
                  enumObj: SituacaoConhecimentoTransporteEnum,
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={2} lg={6}>
              <Select
                name="tipoCte"
                label="Tipo"
                options={FormatarEnum({
                  enumObj: TipoCteEnum,
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoCte(event.target.value);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </Col>

            <Col xl={2} lg={6}>
              <Select
                name="tipoServico"
                label="Tipo de Serviço"
                options={FormatarEnum({
                  enumObj: TipoServicoEnum,
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoServico(event.target.value);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </Col>

            <Col xl={2} lg={6}>
              <Select
                name="tipoTomador"
                label="Tomador"
                options={FormatarEnum({
                  enumObj: TipoTomadorEnum,
                  todos: false,
                })}
                onChange={(event) => {
                  handleChangeTipoTomador(event.target.value);
                }}
                disabled={bloquearCamposPorImportacao}
              />
            </Col>

            <Col xl={2} lg={6}>
              <InputInteiroNulavel
                name="rntrc"
                label="RNTRC"
                placeholder="RNTRC"
                maxLength={8}
                disabled={bloquearCamposPorImportacao}
              />
            </Col>
            <Col xl={2} lg={6}>
              <InputDateSemTimeZone
                label="Data lançamento"
                name="dataLancamento"
              />
            </Col>
            <Col xl={2} lg={6}>
              <InputDateSemTimeZone
                label="Dt. Aq/Prest. Serviço"
                name="dataAquisicaoPrestacaoServico"
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <Tabs
        id={v4()}
        defaultActiveKey="principal"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="principal" title="Principal">
          <PrincipalTab
            tipoTomador={tipoTomador}
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="imposto" title="Imposto">
          <ImpostoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="carga" title="Carga">
          <CargaTab bloquearCamposPorImportacao={bloquearCamposPorImportacao} />
        </Tab>

        <Tab eventKey="servico" title="Serviços">
          <ServicoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        {typeof showNotaReferenciadaTab !== 'boolean' ||
        (typeof showNotaReferenciadaTab === 'boolean' &&
          showNotaReferenciadaTab) ? (
          <Tab eventKey="notaReferenciada" title="Notas Referenciadas">
            <NotaReferenciadaTab
              bloquearCamposPorImportacao={bloquearCamposPorImportacao}
            />
          </Tab>
        ) : null}

        {typeof showNotaReferenciadaTab !== 'boolean' ||
        (typeof showNotaReferenciadaTab === 'boolean' &&
          !showNotaReferenciadaTab) ? (
          <Tab eventKey="documentoReferenciado" title="Outros Documentos">
            <DocumentoReferenciadoTab
              bloquearCamposPorImportacao={bloquearCamposPorImportacao}
            />
          </Tab>
        ) : null}

        <Tab
          eventKey="documentosAnteriores"
          title="Doc. Anteriores"
          tabClassName={
            tipoServico === TipoServicoEnum.subcontratacao ||
            tipoServico === TipoServicoEnum.redespacho ||
            tipoServico === TipoServicoEnum.redespachoIntermediario
              ? ''
              : 'hidden'
          }
        >
          <DocumentoAnteriorTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab
          eventKey="complemento"
          title="Complemento"
          tabClassName={
            tipoCte === TipoCteEnum.complementoValores ? '' : 'hidden'
          }
        >
          <CteComplementadoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab
          eventKey="anulacao"
          title="Anulação"
          tabClassName={tipoCte === TipoCteEnum.anulacao ? '' : 'hidden'}
        >
          <AnulacaoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab
          eventKey="substituto"
          title="Substituto"
          tabClassName={tipoCte === TipoCteEnum.substituto ? '' : 'hidden'}
        >
          <SubstitutoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="informacoesAdicionais" title="Informações Adicionais">
          <InformacaoAdicionalTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="participantes" title="Participantes">
          <ParticipantesTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>

        <Tab eventKey="autorizacao" title="Autorização">
          <AutorizacaoTab
            bloquearCamposPorImportacao={bloquearCamposPorImportacao}
          />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
