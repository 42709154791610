import {
  FormatarDataHoraCompletaParaPtBr,
  ITransferenciaLocalEstoqueLista,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import Lista from '../../../../../../Componentes/Lista';
import IPesquisa from '../../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import TransferenciaLocalEstoqueComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/TransferenciaLocalEstoque/Comunicador/TransferenciaLocalEstoqueComunicador';
import TransferenciaLocalEstoqueLogComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Estoque/TransferenciaLocalEstoque/Log/Comunicador/TransferenciaLocalEstoqueLogComunicador';

const TransferenciaLocalEstoqueLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await TransferenciaLocalEstoqueComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: ITransferenciaLocalEstoqueLista) => {
            return {
              id: data.id,
              numero: <div className="lista-valor">{data.numero}</div>,
              dataHora: (
                <div className="lista-data">
                  {FormatarDataHoraCompletaParaPtBr(data.dataHora)}
                </div>
              ),
              'produto.codigo': (
                <div className="lista-valor">{data.produto.codigo}</div>
              ),
              'produto.descricao': (
                <div className="lista-texto">{data.produto.descricao}</div>
              ),

              quantidade: (
                <div className="lista-valor">
                  {Number(data.quantidade).FormatarParaPtBr({
                    maximoCasasDecimais: 4,
                    minimoCasasDecimais: 4,
                  })}
                </div>
              ),
              indicadorTipoEstoque: (
                <div className="lista-texto">{data.indicadorTipoEstoque}</div>
              ),
              'localEstoqueOrigem.descricao': (
                <div className="lista-texto">
                  {data.localEstoqueOrigem.descricao}
                </div>
              ),
              'localEstoqueDestino.descricao': (
                <div className="lista-texto">
                  {data.localEstoqueDestino.descricao}
                </div>
              ),
              'lote.codigo': (
                <div className="lista-texto">{data.lote?.codigo}</div>
              ),

              'pessoa.nomeRazaoSocial': (
                <div className="lista-texto">
                  {data.pessoa?.nomeRazaoSocial}
                </div>
              ),
              observacoes: (
                <div className="lista-texto">{data?.observacoes}</div>
              ),

              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await TransferenciaLocalEstoqueComunicador.delete({
                          id: data.id,
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          TransferenciaLocalEstoqueLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes
                        .SuprimentosProdutosEstoqueTransferenciaLocalEstoque
                        ?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.SuprimentosProdutosEstoqueTransferenciaLocalEstoque]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosTransferenciaLocalEstoque' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Transferência de Local de Estoque"
          permissao={
            permissoes.SuprimentosProdutosEstoqueTransferenciaLocalEstoque
          }
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return TransferenciaLocalEstoqueLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default TransferenciaLocalEstoqueLista;
