import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/pedido-compra/pedido-compra-resumo-por-mes-ano-emissao';

interface IPedidoCompraResumoPorMesAnoEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoCompraResumoPorMesAnoEmissaoRelatorioComunicador {
  public async index(
    params: IPedidoCompraResumoPorMesAnoEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoCompraResumoPorMesAnoEmissaoRelatorioComunicador();
