"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoTransporteImportacaoEnum;
(function (TipoTransporteImportacaoEnum) {
    TipoTransporteImportacaoEnum["maritima"] = "1 - Mar\u00EDtima";
    TipoTransporteImportacaoEnum["fluvial"] = "2 - Fluvial";
    TipoTransporteImportacaoEnum["lacustre"] = "3 - Lacustre";
    TipoTransporteImportacaoEnum["aerea"] = "4 - A\u00E9rea";
    TipoTransporteImportacaoEnum["postal"] = "5 - Postal";
    TipoTransporteImportacaoEnum["ferroviaria"] = "6 - Ferrovi\u00E1ria";
    TipoTransporteImportacaoEnum["rodoviaria"] = "7 - Rodovi\u00E1ria";
    TipoTransporteImportacaoEnum["condutoRedeTransmissao"] = "8 - Conduto / Rede Transmiss\u00E3o";
    TipoTransporteImportacaoEnum["meiosProprios"] = "9 - Meios Pr\u00F3prios";
    TipoTransporteImportacaoEnum["entradaSaidaFicta"] = "10 - Entrada / Sa\u00EDda Ficta";
    TipoTransporteImportacaoEnum["courier"] = "11 - Courier";
    TipoTransporteImportacaoEnum["handcarry"] = "12-Handcarry";
})(TipoTransporteImportacaoEnum || (TipoTransporteImportacaoEnum = {}));
exports.default = TipoTransporteImportacaoEnum;
