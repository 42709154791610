/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputDecimalProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnChangeValueInputDecimalEvent {
  valor: number;
  valorFormatado: string;
}

export interface IOnChangeValueInputDecimalProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnBlurCiaInputDecimalEvent {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
  mudou: boolean;
}

export interface IInputDecimalRef {
  focus(): void;
}

interface IInputDecimalProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  casasDecimais?: number;
  casasInteiras?: number;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDecimalProps
  ) => void | Promise<void>;
  onChangeValue?: (
    event: IOnChangeValueInputDecimalEvent,
    props: IOnChangeValueInputDecimalProps
  ) => void | Promise<void>;
  onBlurCia?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnBlurCiaInputDecimalEvent
  ) => void | Promise<void>;
}

const InputDecimal: React.ForwardRefRenderFunction<
  IInputDecimalRef,
  IInputDecimalProps
> = (
  {
    name,
    label,
    casasDecimais = 2,
    casasInteiras = 15,

    onFocus,
    onChange,
    onChangeValue,
    onBlur,
    onBlurCia,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnteriorOnBlur.current = inputRef.current?.value || '';
    valorAnteriorOnChange.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);

      const tamanho = event.target.value.length;
      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let posicaoDoCursor = event.currentTarget.selectionStart || 0;
      const quantidadeDePontosAntes = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      let valor: string | number = event.target.value
        .replace(/\./g, '')
        .replace(',', '.');

      if (valor.includes(',')) {
        valor = valor.replace(/[^\d.-]/g, '');

        valor = Number(valor).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        event.target.value = valor;

        event.currentTarget.selectionStart = posicaoDoCursor;
        event.currentTarget.selectionEnd = posicaoDoCursor;

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        return;
      }

      valor = valor.replace(/[^\d.-]/g, '');

      if (!valor.includes('.') && valor.length > 1) {
        const val = event.target.value;
        const inicio = val.substr(0, val.length - casasDecimais);
        const fim = val.substr(casasDecimais * -1);
        event.target.value = `${inicio},${fim}`;

        posicaoDoCursor = val.length - casasDecimais;
        event.currentTarget.selectionStart = posicaoDoCursor;
        event.currentTarget.selectionEnd = posicaoDoCursor;

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        return;
      }

      const [inteirosValor, decimaisValor = ''.padEnd(casasDecimais, '0')] =
        String(valor).split('.');

      if (inteirosValor.length > casasInteiras) {
        setErro('Valor maior que o permitido!');
      }

      valor = Number(
        `${inteirosValor}.${decimaisValor.substr(0, casasDecimais)}`
      );

      valor = valor.FormatarParaPtBr({
        minimoCasasDecimais: casasDecimais,
        maximoCasasDecimais: casasDecimais,
      });

      event.target.value = String(valor);

      const quantidadeDePontosDepois = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      if (valor[posicaoDoCursor - 1] === ',') {
        posicaoDoCursor -= 1;
      } else {
        posicaoDoCursor -= quantidadeDePontosAntes - quantidadeDePontosDepois;
      }

      event.currentTarget.selectionStart = posicaoDoCursor;
      event.currentTarget.selectionEnd = posicaoDoCursor;

      if (onChange) {
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [casasDecimais, casasInteiras, onChange]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(event);

      if (onBlurCia) {
        const mudou = event.target.value !== valorAnteriorOnBlur.current;

        onBlurCia(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
          mudou,
        });
      }

      let valor: string | number = event.target.value
        .replace(/\./g, '')
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

      valor = Number(valor).FormatarParaPtBr({
        minimoCasasDecimais: casasDecimais,
        maximoCasasDecimais: casasDecimais,
      });

      event.target.value = valor;
      setIsFilled(!!inputRef.current?.value);
      setIsFocused(false);
    },
    [casasDecimais, onBlur, onBlurCia]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = '0,'.padEnd(2 + casasDecimais, '0');
    }
  }, [casasDecimais]);

  useEffect(() => {
    registerField<number>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        const value = inputRef.current?.value.ConverterParaNumber() || 0;
        const parsedValue = Number(value);
        return parsedValue;
      },

      setValue(_, value = 0) {
        setErro('');
        if (!inputRef.current) return;

        if (!value) {
          inputRef.current.value = '0,'.padEnd(2 + casasDecimais, '0');

          onChangeValue &&
            onChangeValue(
              {
                valor: value,
                valorFormatado: inputRef.current.value,
              },
              {
                valorAnteriorOnBlur: valorAnteriorOnBlur.current,
                valorAnteriorOnChange: valorAnteriorOnChange.current,
              }
            );

          return;
        }

        const parsedValue = Number(value).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        valorAnteriorOnBlur.current = inputRef.current.value;
        valorAnteriorOnChange.current = inputRef.current.value;
        inputRef.current.value = parsedValue;

        onChangeValue &&
          onChangeValue(
            {
              valor: value,
              valorFormatado: parsedValue,
            },
            {
              valorAnteriorOnBlur: valorAnteriorOnBlur.current,
              valorAnteriorOnChange: valorAnteriorOnChange.current,
            }
          );
      },

      setSemExecutarEvento(_, value = 0) {
        setErro('');
        if (!inputRef.current) return;

        if (!value) {
          inputRef.current.value = '0,'.padEnd(2 + casasDecimais, '0');
          return;
        }

        const parsedValue = Number(value).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        valorAnteriorOnBlur.current = inputRef.current.value;
        inputRef.current.value = parsedValue;
      },

      clearValue(_, valorInicial = 0) {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = 0) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        style={{ textAlign: 'right' }}
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputDecimal);
