const EstoqueSaldoProprioTerceiroPlanilha = [
  { titulo: 'Código', valor: 'estoqueCombinacao.produto.codigo' },
  { titulo: 'Descrição', valor: 'estoqueCombinacao.produto.descricao' },
  {
    titulo: 'Unidade de Medida',
    valor: 'estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  { titulo: 'Saldo', valor: 'saldo' },
  { titulo: 'Tipo', valor: 'estoqueCombinacao.estoqueIndicadorTipo' },
  { titulo: 'Terceiro Código', valor: 'estoqueCombinacao.pessoa.codigo' },
  {
    titulo: 'Terceiro Nome Razão Social',
    valor: 'estoqueCombinacao.pessoa.nomeRazaoSocial',
  },
];

export default EstoqueSaldoProprioTerceiroPlanilha;
