import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { FormatarEnum, TipoContaEnum } from '@elogestor/util';
import { useLocation } from 'react-router-dom';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import InputIntervaloValores, {
  IInputIntervaloValoresRef,
} from '../../../../../Componentes/Inputs/InputIntervaloValores';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteTagClienteFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagClienteFornecedor';
import InputAutoCompleteTagFormaPagamento from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagSituacaoContaEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoContaEnum';

interface IPesquisaAvancada {
  permitirAtualizar?: () => Promise<boolean>;
}

const PesquisaAvancada: React.FC<IPesquisaAvancada> = ({
  permitirAtualizar,
}) => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataVencimentoRef = useRef<any>(null);
  const periodoDataEmissaoRef = useRef<any>(null);

  const intervaloValoresParcelaRef = useRef<IInputIntervaloValoresRef>(null);

  const formRef = useRef<IFormCiaHandles>(null);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handleSubmit = useCallback(async () => {
    if (await handlePermitirAtualizar()) {
      const data = formRef.current?.getDataPesquisa();

      setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

      sessionStorage.setItem(
        '@EloGestorle:pesquisaAvancada',
        JSON.stringify({ path, ...data })
      );

      fecharPesquisaAvancada();
    }
  }, [fecharPesquisaAvancada, handlePermitirAtualizar, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const tipo = parametros.pesquisaAvancada?.tipo;
      if (tipo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo',
          valor: tipo,
        });
      }

      const numeroDocumento = parametros.pesquisaAvancada?.numeroDocumento;
      if (numeroDocumento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número Documento',
          valor: numeroDocumento,
        });
      }

      const listaClienteFornecedor =
        parametros.pesquisaAvancada?.listaClienteFornecedor;
      if (listaClienteFornecedor.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Clientes/Fornecedores',
          valor: listaClienteFornecedor.reduce((acc: string, item: any) => {
            acc += `${item.nomeRazaoSocial}; `;
            return acc;
          }, ''),
        });
      }

      const listaFormaPagamento =
        parametros.pesquisaAvancada?.listaFormaPagamento;
      if (listaFormaPagamento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Forma de Pagamento',
          valor: listaFormaPagamento.reduce((acc: string, item: any) => {
            acc += `${item.descricao}; `;
            return acc;
          }, ''),
        });
      }

      const listaPortadorParcela =
        parametros.pesquisaAvancada?.listaPortadorParcela;
      if (listaPortadorParcela.length > 0) {
        listaPortadorParcela.push({
          titulo: 'Portador Parcela',
          valor: listaPortadorParcela.reduce((acc: string, item: any) => {
            acc += `${item.descricao}; `;
            return acc;
          }, ''),
        });
      }

      const periodoDataVencimento =
        periodoDataVencimentoRef.current?.getPeriodo();
      if (periodoDataVencimento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Vencimento',
          valor: periodoDataVencimento,
        });
      }

      const periodoDataEmissao = periodoDataEmissaoRef.current?.getPeriodo();
      if (periodoDataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Emissão',
          valor: periodoDataEmissao,
        });
      }

      const intervaloValoresParcela =
        intervaloValoresParcelaRef.current?.getIntervalo();
      if (intervaloValoresParcela) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Valores Parcela',
          valor: intervaloValoresParcela,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={4} md={4}>
                <Select
                  name="tipo"
                  label="Tipo"
                  options={FormatarEnum({
                    enumObj: TipoContaEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={4} md={4}>
                <InputAutoCompleteTagSituacaoContaEnum
                  label="Situação"
                  name="listaSituacaoConta"
                />
              </Col>
              <Col lg={4} md={4}>
                <Input
                  name="numeroDocumento"
                  label="Número Documento"
                  placeholder="Número Documento"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagClienteFornecedor
                  name="listaClienteFornecedor"
                  label="Cliente / Fornecedor (Pessoa)"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagFormaPagamento
                  name="listaFormaPagamento"
                  label="Forma Pagamento"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagPortador
                  name="listaPortadorParcela"
                  label="Portador (Parcela)"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <InputPeriodoData
                  labelDataInicial="Data Vencimento Inicial"
                  labelDataFinal="Data Vencimento Final"
                  nomeDataInicial="dataVencimentoInicial"
                  nomeDataFinal="dataVencimentoFinal"
                  ref={periodoDataVencimentoRef}
                />
              </Col>

              <Col lg={6}>
                <InputIntervaloValores
                  nomeValorInicial="valorParcelaInicial"
                  nomeValorFinal="valorParcelaFinal"
                  labelValorInicial="Valor Parcela Inicial"
                  labelValorFinal="Valor Parcela Final"
                  ref={intervaloValoresParcelaRef}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
