import {
  IEmpresaVigenciaAliquotaCreditoSnLista,
  FormatarDataParaPtBr,
} from '@elogestor/util';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table, Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { FormCia, UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { Container } from './styles';
import Detalhe from '../Detalhe';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import InputDecimalNulavel from '../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import UseFuncoesPadrao from '../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseAutoComplete } from '../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';

interface IListaDetalheVigenciaAliquotaCreditoSnProps {
  name: string;
  className?: string;
}

const EmpresaTributacaoVigenciaAliquotaCreditoSnLista: React.FC<
  IListaDetalheVigenciaAliquotaCreditoSnProps
> = ({ name, className }) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { isJanelaAutoComplete } = UseAutoComplete();

  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const formPrincipal = UseForm();

  const {
    formRefLista,
    formRefDetalhe,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    handleSubmit,
  } = UseListaDetalheForm();
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [showFormModal, setShowFormModal] = useState(false);

  const valorRef = useRef<IEmpresaVigenciaAliquotaCreditoSnLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IEmpresaVigenciaAliquotaCreditoSnLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IEmpresaVigenciaAliquotaCreditoSnLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
    if (!idDetalheRegistroPrincipal) {
      const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
      if (erro) return;
    }

    const data = formRefLista.current?.getData();
    await handleSubmit(data, formRefLista.current);
    setIdDetalheRegistro('');
  }, [
    formPrincipal,
    handleSalvarContinuar,
    isJanelaAutoComplete,
    formRefLista,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  const handleEditar = useCallback(
    async (id: string): Promise<void> => {
      const idDetalheRegistroPrincipal = formPrincipal.getIdDetalheRegistro();
      if (!idDetalheRegistroPrincipal) {
        const dataPrincipal = formPrincipal.formRef.current?.getData();

        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;
      }

      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [formPrincipal, setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  useEffect(() => {
    formRefLista.current?.setDataInicial({});
  }, [formRefLista]);

  return (
    <Container className={className}>
      <FormCia ref={formRefLista}>
        <div style={{ width: '100%' }}>
          <h5>Vigências</h5>

          <Row>
            <Col lg={3} md={4} sm={12} style={{ marginTop: 8 }}>
              <InputDate
                label="Vigente a partir de:"
                name="dataInicioVigencia"
              />
            </Col>
            <Col lg={3} md={4} sm={12} style={{ marginTop: 8 }}>
              <InputDecimalNulavel
                label="Alíq. Aprov. Créd. ICMS SN"
                name="aliquotaCreditoIcmsSimplesNacional"
                placeholder="Alíq. Aprov. Créd. ICMS SN"
                casasInteiras={3}
                casasDecimais={2}
              />
            </Col>
            <Col
              lg={2}
              md={3}
              sm={12}
              style={{ alignSelf: 'flex-end', marginTop: 8 }}
            >
              <BtnPadraoButton
                style={{}}
                type="button"
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleAdicionar}
                disabled={
                  !permissao?.inclui || formPrincipal.loading || loading
                }
              >
                <TextoLoading loading={formPrincipal.loading || loading}>
                  <IoMdAddCircleOutline />
                  <span style={{ marginLeft: 10 }}>Adicionar</span>
                </TextoLoading>
              </BtnPadraoButton>
            </Col>
          </Row>
        </div>
      </FormCia>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-data" style={{ width: '20%' }}>
                <span>Vigente a partir de:</span>
              </th>
              <th className="lista-valor" style={{ width: '80%' }}>
                <span>Alíq. Aprov. Créd. ICMS SN</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((valor) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(valor.id);
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-data">
                    <div>{FormatarDataParaPtBr(valor.dataInicioVigencia)}</div>
                  </td>
                  <td className="lista-valor">
                    <div>
                      {valor?.aliquotaCreditoIcmsSimplesNacional
                        ? Number(
                            valor?.aliquotaCreditoIcmsSimplesNacional
                          ).FormatarParaPtBrPercentual()
                        : ''}
                    </div>
                  </td>
                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(valor.id);
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
        />
      )}
    </Container>
  );
};

export default EmpresaTributacaoVigenciaAliquotaCreditoSnLista;
