import { IFormCiaHandles } from '@elogestor/unformcia';
import { DesestruturarChaveAcesso, ValidarChaveAcesso } from '@elogestor/util';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Row, Col, Table, Dropdown } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';

interface IImportacaoCteTerceiroEtapa2NfeItem {
  id?: string;
  chaveAcesso: string;
  numeroNota: number | null;
  serie: number | null;
}

export interface IImportacaoCteTerceiroEtapa2Ref {
  handleSetarListaNfe(listaValor: any[]): void;
  handleObterListaNfe(): IImportacaoCteTerceiroEtapa2NfeItem[];
  handleValidar(): Promise<boolean>;
}

interface IImportacaoCteTerceiroEtapa2Props {
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoCteTerceiroEtapa2: React.ForwardRefRenderFunction<
  IImportacaoCteTerceiroEtapa2Ref,
  IImportacaoCteTerceiroEtapa2Props
> = ({ formRef, setLoading }, ref) => {
  const { abrirJanela } = UseConfirmacao();

  const [listaNfe, setListaNfe] = useState<
    IImportacaoCteTerceiroEtapa2NfeItem[]
  >([]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const data = formRef.current?.getData() as any;
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({});

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errosValidacao = GetValidationErrors(error as any);
      formRef.current?.setErrors(errosValidacao);
      return false;
    }
  }, [formRef]);

  const handleSetarListaNfe = useCallback((listaValor: any[]): void => {
    setListaNfe(listaValor);
  }, []);

  const handleObterListaNfe =
    useCallback((): IImportacaoCteTerceiroEtapa2NfeItem[] => {
      return listaNfe;
    }, [listaNfe]);

  useImperativeHandle(ref, () => {
    return {
      handleSetarListaNfe,
      handleObterListaNfe,
      handleValidar,
    };
  });

  const handleValidarAdicionar = useCallback(async (): Promise<boolean> => {
    try {
      const data = formRef.current?.getData() as any;
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        chaveAcessoNfe: Yup.mixed()
          .nullable()
          .test({
            message: 'Chave de Acesso inválida!',
            test: () => {
              const chaveAcessoValida = ValidarChaveAcesso(data.chaveAcessoNfe);
              if (!chaveAcessoValida) {
                return false;
              }

              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, [formRef]);

  const handleAdicionarNfe = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleValidarAdicionar())) {
        setLoading(false);
        return;
      }

      const chaveAcesso = formRef.current?.getFieldValue('chaveAcessoNfe');

      const { chaveDuplicada: isChaveDuplicada } =
        await ConhecimentoTransporteTerceiroValidarNotasUtilizadasComunicador.index(
          { chaveAcesso }
        );

      if (
        isChaveDuplicada ||
        listaNfe.some((nfe) => nfe.chaveAcesso === chaveAcesso)
      ) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 18 }}>
              Chaves de acesso: {chaveAcesso} já utilizada em um conhecimento de
              transporte, deseja continuar?
            </span>
          ),
        });

        if (!resposta) {
          formRef.current?.clearField('chaveAcessoNfe');
          setLoading(false);
          return;
        }
      }

      const dadosNf = DesestruturarChaveAcesso({ chaveAcesso });
      const novoItem: IImportacaoCteTerceiroEtapa2NfeItem = {
        chaveAcesso,
        numeroNota: dadosNf.numero,
        serie: dadosNf.serie,
      };

      setListaNfe([...listaNfe, novoItem]);

      formRef.current?.clearField('chaveAcessoNfe');

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, formRef, handleValidarAdicionar, listaNfe, setLoading]);

  const handleExcluirNFe = useCallback(
    (indice: number) => {
      const novaListaNfe = listaNfe.filter((_, index) => index !== indice);
      setListaNfe(novaListaNfe);
    },
    [listaNfe]
  );

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <Divisor>
        <Row style={{ alignItems: 'flex-end' }}>
          <Col>
            <Input
              name="chaveAcessoNfe"
              maxLength={44}
              label="Chave de acesso da NF-e transportada"
            />
          </Col>
          <Col lg={2}>
            <BtnPadraoButton
              type="button"
              onClick={handleAdicionarNfe}
              className="btn-padrao btn-verde-claro btn-adicionar"
            >
              <IoMdAdd />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </BtnPadraoButton>
          </Col>
        </Row>

        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '60%' }}>
                  Chave de acesso da NF-e transportada
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  Núm. Nota
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  Série
                </th>
                <th className="lista-texto">Ações</th>
              </tr>
            </thead>

            <tbody>
              {listaNfe?.map((item, index) => (
                <tr key={v4()}>
                  <td className="lista-texto">{item.chaveAcesso}</td>
                  <td className="lista-valor">{item.numeroNota}</td>
                  <td className="lista-valor">{item.serie}</td>
                  <td className="tdButton lista-valor">
                    <div>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluirNFe(index);
                          }}
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <AiOutlineDelete style={{ marginRight: 5 }} />
                            Excluir
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>
    </div>
  );
};

export default forwardRef(ImportacaoCteTerceiroEtapa2);
