"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var EstoqueIndicadorTipoEnum;
(function (EstoqueIndicadorTipoEnum) {
    EstoqueIndicadorTipoEnum["proprioEmPoderProprio"] = "Pr\u00F3prio em poder pr\u00F3prio";
    EstoqueIndicadorTipoEnum["proprioEmPoderTerceiro"] = "Pr\u00F3prio em poder de terceiro";
    EstoqueIndicadorTipoEnum["proprioEmPoderTerceiroSemRetornoProprio"] = "Pr\u00F3p. em poder de terc. sem ret. prop.";
    EstoqueIndicadorTipoEnum["proprioEmPoderTerceiroSemTransicaoAdquirinte"] = "Pr\u00F3p. em poder de terc. sem trans. adq.";
    EstoqueIndicadorTipoEnum["terceiroEmPoderProprio"] = "Terceiro em poder pr\u00F3prio";
    EstoqueIndicadorTipoEnum["terceiroEmPoderTerceiro"] = "Terceiro em poder de terceiro";
})(EstoqueIndicadorTipoEnum || (EstoqueIndicadorTipoEnum = {}));
exports.default = EstoqueIndicadorTipoEnum;
