import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/notas-emitidas/upload-xml';

interface IImportacaoXmlEmitidosUploadXmlComunicadorUpdate {
  params: any;
}

class ImportacaoXmlEmitidosUploadXmlComunicador {
  public async update(
    params: IImportacaoXmlEmitidosUploadXmlComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlEmitidosUploadXmlComunicador();
