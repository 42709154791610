import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/gerar-nota-fiscal-saida-importacao-pre-venda';

interface IFrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador();
