import { IPesagemTicket80mmImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPesagemTicket80mmPDF from './PesagemTicket80mmImpressaoPDF';

interface IImpressaoPesagem {
  handleVisualizarPdf(dados: IPesagemTicket80mmImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: IPesagemTicket80mmImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPesagemTicket80mm = (): IImpressaoPesagem => {
  async function handleVisualizarPdf(
    dados: IPesagemTicket80mmImpressao
  ): Promise<void> {
    const relatorio = await PDF({
      documento: <ImpressaoPesagemTicket80mmPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    dados: IPesagemTicket80mmImpressao
  ): Promise<Blob | undefined> {
    if (!dados) return undefined;

    const relatorio = await PDF({
      documento: <ImpressaoPesagemTicket80mmPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return { handleVisualizarPdf, handleObterBlobPdf };
};

export default ImpressaoPesagemTicket80mm();
