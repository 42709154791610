import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/exportar-xml';

interface IImportacaoXmlTerceiroExportarXmlComunicadorShow {
  id: string;
}

class ImportacaoXmlTerceiroExportarXmlComunicador {
  public async show(
    params: IImportacaoXmlTerceiroExportarXmlComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroExportarXmlComunicador();
