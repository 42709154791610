import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/orcamento/orcamento-por-produto';

interface IOrcamentoPorProdutoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class OrcamentoPorProdutoRelatorioComunicador {
  public async index(
    params: IOrcamentoPorProdutoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new OrcamentoPorProdutoRelatorioComunicador();
