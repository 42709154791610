import styled from 'styled-components';

export const Container = styled.div`
  z-index: 100;
  width: 500px;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  position: fixed;
  left: calc(50% - 250px);
  margin-top: 195px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: 100vw;
    height: auto;
    left: calc(50% - 50vw);
  }

  background: rgba(0, 0, 0, 0.8);
  color: #fff;

  svg {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  button {
    font-weight: 600;
  }
  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    align-items: flex-start;
    h3 {
      grid-column-start: 2;
    }
  }
`;
