import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro';

interface IConhecimentoTransporteTerceiroComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IConhecimentoTransporteTerceiroComunicadorShow {
  id: string;
}

interface IConhecimentoTransporteTerceiroComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroComunicadorUpdate {
  id: string;
  params: any;
}

interface IConhecimentoTransporteTerceiroComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteTerceiroComunicador {
  public async index(
    params: IConhecimentoTransporteTerceiroComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IConhecimentoTransporteTerceiroComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IConhecimentoTransporteTerceiroComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IConhecimentoTransporteTerceiroComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroComunicador();
