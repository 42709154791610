import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/cest-auto-complete';

interface ICestAutoCompleteComunicadorIndex {
  params: any;
}

class CestAutoCompleteComunicador {
  public async index(params: ICestAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CestAutoCompleteComunicador();
