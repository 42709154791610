import { IAnaliseCustoVendaLista } from '@elogestor/util';
import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import { IFormCiaHandles } from '@elogestor/unformcia';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';

interface IAnaliseCustoVendaTabProps {
  formRef: React.MutableRefObject<IFormCiaHandles>;
  refresh: () => void;
}

const AnaliseCustoVendaTab: React.FC<IAnaliseCustoVendaTabProps> = ({
  formRef,
  refresh,
}) => {
  const [listaItemVenda, setListaItemVenda] = useState<
    IAnaliseCustoVendaLista[]
  >([]);

  const novaListaItemVenda = formRef.current?.getFieldValue('listaItemVenda');

  useEffect(() => {
    setListaItemVenda([]);

    if (novaListaItemVenda) {
      setListaItemVenda(novaListaItemVenda);
    }
  }, [novaListaItemVenda]);

  return (
    <div>
      <Row>
        <Tabela style={{ overflow: 'auto', maxHeight: 500 }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '10%' }}>
                  <span>Séq</span>
                </th>
                <th className="lista-texto" style={{ width: '15%' }}>
                  <span>Código</span>
                </th>
                <th className="lista-texto" style={{ width: '35%' }}>
                  <span>Descrição</span>
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Margem Liq.</span>
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Margem Perc.</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaItemVenda.map((item) => {
                return (
                  <tr key={v4()}>
                    <td className={`lista-texto`}>
                      <div>
                        <span>{item.sequencia}</span>
                      </div>
                    </td>
                    <td className={`lista-texto`}>
                      <div>
                        <span>{item.produto.codigo}</span>
                      </div>
                    </td>
                    <td className={`lista-texto`}>
                      <div>
                        <span>{item.produto.descricao}</span>
                      </div>
                    </td>
                    <td className={`lista-valor`}>
                      <div>
                        <span>
                          {Number(item.margemLiquidaValor).FormatarParaPtBr({
                            maximoCasasDecimais: 2,
                            minimoCasasDecimais: 2,
                          })}
                        </span>
                      </div>
                    </td>
                    <td className={`lista-valor`}>
                      <div>
                        <span>
                          {Number(
                            item.margemLiquidaPercentual
                          ).FormatarParaPtBr({
                            maximoCasasDecimais: 2,
                            minimoCasasDecimais: 2,
                          })}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </Row>

      <Row>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Margem Líquida"
            name="margemLiquidaValorTotal"
            placeholder="Margem Líquida"
            casasDecimais={2}
            disabled
          />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <InputDecimal
            label="Margem Líquida %"
            name="margemLiquidaPercentualTotal"
            placeholder="Margem Líquida %"
            casasDecimais={2}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default AnaliseCustoVendaTab;
