import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/tipo';

class UsuarioTipoComunicador {
  public async index(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new UsuarioTipoComunicador();
