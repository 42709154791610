import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormatarEnum, TipoPerdaEstruturaEnum } from '@elogestor/util';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../../../Componentes/Inputs/InputDecimal';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Select from '../../../../../../../../../Componentes/Select';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';

const OutrasInformacoesTab: React.FC = () => {
  const { formRef: formPrincipal } = UseForm();

  const { formRefDetalhe, refresh } = UseListaDetalheForm();

  const { tipoPerda, tipoPerdaSetup, perdaCalcConfEstrutura } =
    formRefDetalhe.current?.getData() || {};

  const calcularPerda = useCallback(async (): Promise<void> => {
    const perdaCalcConfEstruturaHabilitado =
      formRefDetalhe.current?.getFieldValue('perdaCalcConfEstrutura');

    const data = formRefDetalhe.current?.getData();

    const dataPrincipal = formPrincipal.current?.getData() || {};

    if (
      perdaCalcConfEstruturaHabilitado &&
      (tipoPerdaSetup || tipoPerda) &&
      data &&
      (data.quantidadePerdaSetup ||
        data.quantidadePerda ||
        data.percentualPerdaSetup ||
        data.percentualPerda)
    ) {
      let percentualPerda = 0;
      let quantidadePerda = 0;
      let percentualPerdaSetup = 0;
      let quantidadePerdaSetup = 0;
      if (
        data.tipoPerda === TipoPerdaEstruturaEnum.percentual &&
        data.percentualPerda !== undefined
      ) {
        percentualPerda = data.percentualPerda;

        quantidadePerda =
          (data.quantidadeDemanda || 0) * (data.percentualPerda / 100) || 0;
      }

      if (
        data.tipoPerda === TipoPerdaEstruturaEnum.quantidade &&
        data.quantidadePerda !== undefined
      ) {
        quantidadePerda = data.quantidadePerda;

        percentualPerda =
          (data.quantidadePerda / (data.quantidadeDemanda || 1)) * 100 || 0;
      }

      if (
        data.tipoPerdaSetup === TipoPerdaEstruturaEnum.percentual &&
        data.percentualPerdaSetup !== undefined
      ) {
        percentualPerdaSetup = data.percentualPerdaSetup;

        quantidadePerdaSetup =
          (data.quantidadeEstrutura || data.quantidadeDemanda || 0) *
            (data.percentualPerdaSetup / 100) || 0;
      }

      if (
        data.tipoPerdaSetup === TipoPerdaEstruturaEnum.quantidade &&
        data.quantidadePerdaSetup !== undefined
      ) {
        quantidadePerdaSetup = data.quantidadePerdaSetup;
        percentualPerdaSetup =
          (data.quantidadePerdaSetup / (data.quantidadeEstrutura || 1)) * 100 ||
          0;
      }

      formRefDetalhe.current?.setFieldValue('percentualPerda', percentualPerda);

      formRefDetalhe.current?.setFieldValue('quantidadePerda', quantidadePerda);

      formRefDetalhe.current?.setFieldValue(
        'percentualPerdaSetup',
        percentualPerdaSetup
      );

      formRefDetalhe.current?.setFieldValue(
        'quantidadePerdaSetup',
        quantidadePerdaSetup
      );

      const demandaTotal =
        (data.quantidadeDemandaPorUnidade || 0) *
          (dataPrincipal.quantidade || 0) +
        quantidadePerda +
        quantidadePerdaSetup;

      formRefDetalhe.current?.setFieldValue(
        'quantidadeDemandaTotal',
        demandaTotal
      );

      formRefDetalhe.current?.setFieldValue(
        'quantidadePerdaTotal',
        quantidadePerda + quantidadePerdaSetup
      );

      refresh();
    }
  }, [formPrincipal, formRefDetalhe, refresh, tipoPerda, tipoPerdaSetup]);

  useEffect(() => {
    calcularPerda();
  }, [calcularPerda]);

  return (
    <Divisor>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <InputDecimal
            label="Qtde Demanda por Unidade"
            name="quantidadeDemandaPorUnidade"
            disabled
            casasInteiras={15}
            casasDecimais={4}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimal
            label="Qtde da Estrutura"
            name="quantidadeEstrutura"
            disabled
            casasInteiras={15}
            casasDecimais={4}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimal
            label="Qtde da Estrutura por Unidade"
            name="quantidadeEstruturaPorUnidade"
            casasInteiras={15}
            casasDecimais={4}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6} sm={12}>
          <Select
            name="tipoPerda"
            label="Tipo Perda"
            disabled={!perdaCalcConfEstrutura}
            options={FormatarEnum({
              enumObj: TipoPerdaEstruturaEnum,
            })}
            onChange={() => {
              refresh();
            }}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimalNulavel
            name="percentualPerda"
            label="Percentual Perda"
            casasInteiras={15}
            casasDecimais={4}
            disabled={
              !perdaCalcConfEstrutura ||
              tipoPerda !== TipoPerdaEstruturaEnum.percentual
            }
            onChangeValue={(e) => {
              refresh();
              calcularPerda();
            }}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimalNulavel
            name="quantidadePerda"
            label="Quantidade de Perda"
            casasInteiras={15}
            casasDecimais={4}
            disabled={
              !perdaCalcConfEstrutura ||
              tipoPerda !== TipoPerdaEstruturaEnum.quantidade
            }
            onChangeValue={(e) => {
              refresh();
              calcularPerda();
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6} sm={12}>
          <Select
            name="tipoPerdaSetup"
            label="Tipo Perda Setup"
            disabled={!perdaCalcConfEstrutura}
            options={FormatarEnum({
              enumObj: TipoPerdaEstruturaEnum,
            })}
            onChange={() => {
              refresh();
            }}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimalNulavel
            name="percentualPerdaSetup"
            label="Percentual Perda Setup"
            casasInteiras={15}
            casasDecimais={4}
            disabled={
              !perdaCalcConfEstrutura ||
              tipoPerdaSetup !== TipoPerdaEstruturaEnum.percentual
            }
            onChangeValue={(e) => {
              refresh();
              calcularPerda();
            }}
          />
        </Col>

        <Col lg={4} md={6} sm={12}>
          <InputDecimalNulavel
            name="quantidadePerdaSetup"
            label="Quantidade Perda Setup"
            disabled={
              !perdaCalcConfEstrutura ||
              tipoPerdaSetup !== TipoPerdaEstruturaEnum.quantidade
            }
            casasInteiras={15}
            casasDecimais={4}
            onChangeValue={(e) => {
              refresh();
              calcularPerda();
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6} sm={12}>
          <InputSwitch
            name="perdaCalcConfEstrutura"
            ativo="Sim"
            inativo="Não"
            label="Perda Calc. Conf. Estrutura"
            onChange={(e) => {
              if (e.currentTarget.checked === true) {
                calcularPerda();
              }

              refresh();
            }}
          />
        </Col>
      </Row>
    </Divisor>
  );
};

export default OutrasInformacoesTab;
