import api from '../../../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/transmissao/evento/upload-pdf';

interface INotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate {
  params: any;
}

class NotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicador {
  public async update(
    params: INotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalSaidaTransmissaoEventoCartaCorrecaoUploadPdfComunicador();
