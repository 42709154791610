import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  ConverterMilimitroParaPonto,
  FormatarDataParaPtBr,
  IPedidoVendaRetratoRetiradaConsignadoImpressao,
} from '@elogestor/util';

const Document: any = _document;
const Page: any = _page;

interface IPedidoVendaRetratoRetiradaConsignadoImpressaoPDF {
  dados: IPedidoVendaRetratoRetiradaConsignadoImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    fontSize: 6,
    padding: '10 15 10 10',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'column',
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 6,
    width: '100%',
    flex: 1,
  },
});
const size = {
  width: ConverterMilimitroParaPonto(80),
  height: 'auto',
};

const PedidoVendaRetratoRetiradaConsignadoImpressaoPDF: React.FC<
  IPedidoVendaRetratoRetiradaConsignadoImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} size={size} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`CNPJ: `}
                </Text>

                <Text style={{ fontSize: 6 }}>{dados.empresa?.cnpjCpf}</Text>
              </View>

              <View style={{ flexDirection: 'row', paddingLeft: '5pt' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`IE: `}
                </Text>
                <Text style={{ fontSize: 6 }}>
                  {dados.empresa?.inscricaoEstadual}
                </Text>
              </View>
            </View>

            <View style={{ width: '100%' }}>
              <Text
                style={{
                  fontSize: 6,
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                {dados.empresa?.empresaEndereco?.logradouro}
                {` - `}
                {dados.empresa?.empresaEndereco?.numero}
                {` - `}
                {dados.empresa?.empresaEndereco?.bairro}
              </Text>
            </View>

            <View style={{ width: '100%' }}>
              <Text
                style={{
                  fontSize: 6,
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                {dados.empresa?.empresaEndereco?.cep}
                {` - `}
                {dados.empresa?.empresaEndereco?.cidade?.nome}
                {` - `}
                {dados.empresa?.empresaEndereco?.cidade?.estado?.sigla}
              </Text>
            </View>

            <View style={{ flexDirection: 'row', width: '100%' }}>
              {dados.empresa?.telefone && (
                <View
                  style={{
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 6,
                      fontWeight: 'bold',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {`Telefone: `}
                  </Text>
                </View>
              )}
              <View style={{ flexDirection: 'row', width: '50%' }}>
                <Text style={{ fontSize: 6 }}>{dados.empresa?.telefone}</Text>
              </View>
            </View>

            <View style={{ width: '100%', alignItems: 'center' }}>
              <Text
                style={{
                  width: '100%',
                  fontSize: 8,
                  marginTop: '10pt',
                  alignItems: 'center',
                  borderTop: '1pt solid black',
                  textAlign: 'center',
                }}
              >
                RETIRADA DE VENDA CONSIGNADA
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                borderBottom: '1pt solid black',
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`Número: `}
                </Text>

                <Text style={{ fontSize: 6 }}>{dados.numero}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`Data Emissão: `}
                </Text>
                <Text style={{ fontSize: 6 }}>
                  {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {'Cliente: '}
                </Text>
                <Text style={{ fontSize: 6, maxWidth: '90%' }}>
                  {`${dados.pedidoVendaPessoa.pessoa.codigo} - ${dados.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`Cnpj/Cpf: `}
                </Text>
                <Text style={{ fontSize: 6, maxWidth: '90%' }}>
                  {dados.pedidoVendaPessoa.pessoa.cnpjCpf}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {`Endereço: `}
                </Text>
                <Text style={{ fontSize: 6, maxWidth: '90%' }}>
                  {dados.pedidoVendaPessoaEndereco.logradouro &&
                    `${dados.pedidoVendaPessoaEndereco.logradouro}, `}
                  {dados.pedidoVendaPessoaEndereco.numero &&
                    `${dados.pedidoVendaPessoaEndereco.numero}, `}
                  {dados.pedidoVendaPessoaEndereco.bairro &&
                    `${dados.pedidoVendaPessoaEndereco.bairro}, `}
                  {dados.pedidoVendaPessoaEndereco.complemento &&
                    dados.pedidoVendaPessoaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {'Cidade: '}
                </Text>
                <Text style={{ fontSize: 6, maxWidth: '90%' }}>
                  {dados.pedidoVendaPessoaEndereco?.cidade &&
                    `${dados.pedidoVendaPessoaEndereco?.cidade?.nome} - ${dados.pedidoVendaPessoaEndereco?.cidade?.estado.sigla}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                  {'Cep: '}
                </Text>
                <Text style={{ fontSize: 6, maxWidth: '90%' }}>
                  {dados.pedidoVendaPessoaEndereco.cep}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                    {'E-mail: '}
                  </Text>
                  <Text style={{ fontSize: 6, maxWidth: '40%' }}>
                    {dados.pedidoVendaPessoa.pessoa.email}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontSize: 6, fontWeight: 'bold' }}>
                    {'Telefone: '}
                  </Text>
                  <Text style={{ fontSize: 6 }}>
                    {dados.pedidoVendaPessoa.pessoa.telefone}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              backgroundColor: '#cdcdcd',
              fontSize: 6,
            }}
          >
            <View style={{ width: '4%', alignItems: 'flex-end' }}>
              <Text>Seq.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '10%', alignItems: 'flex-end' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '48%' }}>
              <Text>Descrição</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Qtde.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '21%', alignItems: 'flex-end' }}>
              <Text>Val. Unit. (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          {dados.listaPedidoVendaItem?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>{dado.ordem}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '10%', alignItems: 'flex-end' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '48%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>{dado.unidadeMedida.sigla}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '21%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />
              </View>
            );
          })}

          <View
            style={{
              marginTop: '10pt',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '85%', alignItems: 'flex-end' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Valor Total: `}
                </Text>
              </View>

              <View
                style={{
                  width: '15%',
                  alignItems: 'flex-end',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.valorTotalBruto).FormatarParaPtBr()}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: '50pt', borderTop: '1pt solid black' }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '100%', alignItems: 'center' }}>
              <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                Assinatura
              </Text>
            </View>
          </View>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <Text
              style={{
                width: '100%',
                fontSize: 8,
                marginBottom: '10pt',
                marginTop: '10pt',
                alignItems: 'center',
              }}
            >
              A MERCADORIA DEVERÁ SER DEVOLVIDA NO PRAZO DE 24 HORAS, CASO
              CONTRÁRIO ELA SERÁ DADA COMO VENDIDA
            </Text>
            <Text>
              Atendido por:
              {
                dados.pedidoVendaComercial
                  ?.listaPedidoVendaComercialRepresentante?.[0]
                  ?.pessoaRepresentante?.nomeRazaoSocial
              }
            </Text>
          </View>
        </View>
        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 6,
          }}
        >
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaRetratoRetiradaConsignadoImpressaoPDF;
