import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Tab, Tabs } from 'react-bootstrap';
import InformacaoCOntribuinteTab from './InformacaoContribuinteTab';
import InformacaoFiscoTab from './InformacaoFiscoTab';

const InformacaoAdicionalTab: React.FC = () => {
  const [tabSelecionada, setTabSelecionada] = useState(
    'informacaoContribuinte'
  );

  return (
    <div>
      <Tabs
        id={v4()}
        defaultActiveKey="itens"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="informacaoContribuinte" title="Informações Contribuinte">
          <InformacaoCOntribuinteTab />
        </Tab>

        <Tab eventKey="informacaoFisco" title="Informações Fisco">
          <InformacaoFiscoTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default InformacaoAdicionalTab;
