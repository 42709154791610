/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import {
  IImportacaoNfeTerceiroEtapa2ItemLista,
  IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista,
  TipoEntradaSaidaOperacaoFiscalEnum,
  FormatarEnum,
  BooleanEnum,
  TipoTransacaoOperacaoFiscalEnum,
} from '@elogestor/util';
import InputLabel from '../../../../../../../Componentes/Inputs/InputLabel';
import Divisor from '../../../../../../../Componentes/Divisor';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import TratarAvisos from '../../../../../../../Util/Aviso/TratarAvisos';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import InputAutoCompleteSpedTipoProduto from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSpedTipoProduto';
import InputAutoCompleteOperacaoFiscal from '../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOperacaoFiscal';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador from '../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/ImportacaoNfeTerceiro/Comunicador/ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador';
import Select from '../../../../../../../Componentes/Select';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseAuth } from '../../../../../../../Hooks/Auth';

export interface IImportacaoNfeTerceiroEtapa2Ref {
  handleSetarListaItem(listaValor: any[]): void;
  handleObterListaItem(): IImportacaoNfeTerceiroEtapa2ItemLista[];
  handleValidar(): Promise<boolean>;
}

interface IImportacaoNfeTerceiroEtapa2Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoNfeTerceiroEtapa2: React.ForwardRefRenderFunction<
  IImportacaoNfeTerceiroEtapa2Ref,
  IImportacaoNfeTerceiroEtapa2Props
> = ({ name, formRef, setLoading, refresh }, ref) => {
  const [listaItem, setListaItem] = useState<
    IImportacaoNfeTerceiroEtapa2ItemLista[]
  >([]);

  const { fieldName, registerField } = UseFieldCia(name);
  const valorRef = useRef<IImportacaoNfeTerceiroEtapa2ItemLista[]>([]);
  const { abrirJanela } = UseConfirmacao();

  const { empresa } = UseAuth();

  const [listaValor, setListaValor] = useState<
    IImportacaoNfeTerceiroEtapa2ItemLista[]
  >([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IImportacaoNfeTerceiroEtapa2ItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const listaMensagemAviso: string[] = [];
      const listaMensagemErro: string[] = [];
      let etapaValida = true;

      const data = formRef.current?.getData() as any;

      formRef.current?.setErrors({});
      const listaAtualizarSpedTipoProdutoDiferente: string[] = [];
      const listaAtualizarNcmDiferente: string[] = [];
      const listaAtualizarOrigemIcmsDiferente: string[] = [];

      data.listaEtapa2.forEach(
        (item: IImportacaoNfeTerceiroEtapa2ItemLista) => {
          item.listaItemDesmembrado.forEach(
            (
              itemDesmembrado: IImportacaoNfeTerceiroEtapa2ItemDesmembradoLista
            ) => {
              for (let i = 0; i < data.listaEtapa2.length; i++) {
                const itemValidarDiferente = data.listaEtapa2[i];

                for (
                  let j = 0;
                  j < itemValidarDiferente.listaItemDesmembrado.length;
                  j++
                ) {
                  const itemDesmembradoValidarDiferente =
                    itemValidarDiferente.listaItemDesmembrado[j];

                  if (
                    itemDesmembrado.sequencia !==
                      itemDesmembradoValidarDiferente.sequencia &&
                    itemDesmembrado.produtoCodigoDescricao ===
                      itemDesmembradoValidarDiferente.produtoCodigoDescricao &&
                    itemDesmembrado.produto?.id ===
                      itemDesmembradoValidarDiferente.produto?.id &&
                    itemDesmembrado.produto?.codigo ===
                      itemDesmembradoValidarDiferente.produto?.codigo &&
                    itemDesmembrado.produto?.descricao ===
                      itemDesmembradoValidarDiferente.produto?.descricao &&
                    itemDesmembrado.codigoBarras ===
                      itemDesmembradoValidarDiferente.codigoBarras
                  ) {
                    if (
                      itemDesmembrado.spedTipoProduto?.id !==
                        itemDesmembradoValidarDiferente.spedTipoProduto?.id &&
                      !listaAtualizarSpedTipoProdutoDiferente.includes(
                        itemDesmembrado.sequencia
                      )
                    ) {
                      listaAtualizarSpedTipoProdutoDiferente.push(
                        itemDesmembrado.sequencia
                      );
                    }

                    if (
                      itemDesmembrado.atualizarNcm !==
                        itemDesmembradoValidarDiferente.atualizarNcm &&
                      !listaAtualizarNcmDiferente.includes(
                        itemDesmembrado.sequencia
                      )
                    ) {
                      listaAtualizarNcmDiferente.push(
                        itemDesmembrado.sequencia
                      );
                    }

                    if (
                      itemDesmembrado.atualizarOrigemIcms !==
                        itemDesmembradoValidarDiferente.atualizarOrigemIcms &&
                      !listaAtualizarOrigemIcmsDiferente.includes(
                        itemDesmembrado.sequencia
                      )
                    ) {
                      listaAtualizarOrigemIcmsDiferente.push(
                        itemDesmembrado.sequencia
                      );
                    }
                  }
                }
              }

              if (!itemDesmembrado.spedTipoProduto?.id) {
                listaMensagemErro.push(
                  `Tipo SPED do Item Desmembrado: ${itemDesmembrado.sequencia} é obrigatório!`
                );
              }

              if (!itemDesmembrado.produto?.id && !item.itemNf.ncmNf?.id) {
                listaMensagemErro.push(
                  `NCM NF. (${item.itemNf.ncmNf?.codigoXml}) do Item Desmembrado: ${itemDesmembrado.sequencia} não foi Encontrado!`
                );
              }

              if (
                itemDesmembrado.produto?.id &&
                !item.itemNf.ncmNf?.id &&
                itemDesmembrado.atualizarNcm
              ) {
                listaMensagemErro.push(
                  `NCM NF. (${item.itemNf.ncmNf?.codigoXml}) do Item Desmembrado: ${itemDesmembrado.sequencia} não foi Encontrado!`
                );
              }

              if (!itemDesmembrado.operacaoFiscal?.id) {
                listaMensagemErro.push(
                  `CFOP (Entrada) do Item Desmembrado: ${itemDesmembrado.sequencia} é obrigatória!`
                );
              }
            }
          );
        }
      );

      if (listaAtualizarSpedTipoProdutoDiferente.length > 0) {
        const sequenciaFormatada =
          listaAtualizarSpedTipoProdutoDiferente.reduce(
            (acumulador: string, sequencia: string) => {
              acumulador += `${sequencia}, `;
              return acumulador;
            },
            ''
          );

        listaMensagemErro.push(
          `Os Iten Desmembrados: ${sequenciaFormatada}possuem Itens em comuns com o campo Tipo SPED diferentes!`
        );
      }

      if (listaAtualizarNcmDiferente.length > 0) {
        const sequenciaFormatada = listaAtualizarNcmDiferente.reduce(
          (acumulador: string, sequencia: string) => {
            acumulador += `${sequencia}, `;
            return acumulador;
          },
          ''
        );

        listaMensagemErro.push(
          `Os Iten Desmembrados: ${sequenciaFormatada}possuem Itens em comuns com o campo Atualizar NCM diferentes!`
        );
      }

      if (listaAtualizarOrigemIcmsDiferente.length > 0) {
        const sequenciaFormatada = listaAtualizarOrigemIcmsDiferente.reduce(
          (acumulador: string, sequencia: string) => {
            acumulador += `${sequencia}, `;
            return acumulador;
          },
          ''
        );

        listaMensagemErro.push(
          `Os Iten Desmembrados: ${sequenciaFormatada}possuem Itens em comuns com o campo Atualizar Origem diferentes!`
        );
      }

      if (listaMensagemAviso.length > 0) {
        TratarAvisos(listaMensagemAviso);
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        etapaValida = false;
      }

      const schema = Yup.object().shape({
        listaEtapa2: Yup.array().of(
          Yup.object().shape({
            listaItemDesmembrado: Yup.array().of(
              Yup.object().shape({
                idSpedTipoProduto: Yup.string()
                  .nullable()
                  .required('Tipo SPED é obrigatório!'),
                idOperacaoFiscal: Yup.string()
                  .nullable()
                  .required('CFOP (Entrada) é obrigatória!'),
              })
            ),
          })
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return etapaValida;
    } catch (error) {
      const errosValidacao = GetValidationErrors(error as any);
      formRef.current?.setErrors(errosValidacao);
      return false;
    }
  }, [formRef]);

  const handleSetarListaItem = useCallback((listaValorItem: any[]): void => {
    setListaItem(listaValorItem);
  }, []);

  const handleObterListaItem =
    useCallback((): IImportacaoNfeTerceiroEtapa2ItemLista[] => {
      return listaItem;
    }, [listaItem]);

  useImperativeHandle(ref, () => {
    return {
      handleSetarListaItem,
      handleObterListaItem,
      handleValidar,
    };
  });

  const handleOnAlterarSpedTipoProduto = useCallback(
    async (
      spedTipoProduto: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      try {
        setLoading(true);

        if (spedTipoProduto) {
          const response =
            await ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador.show(
              {
                params: {
                  codigoSpedTipoProduto: spedTipoProduto.codigo,
                  cfop: listaItem[index].itemNf.cfop,
                },
              }
            );

          listaItem[index].listaItemDesmembrado[
            indexItemDesmembrado
          ].operacaoFiscal = response.operacaoFiscal;

          formRef.current?.setFieldValue(
            `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].operacaoFiscal`,
            response.operacaoFiscal
          );

          // #region Atualizar todos os sped do mesmo produto

          const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
            listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

          for (let i = 0; i < listaItem.length; i++) {
            const itemLista = listaItem[i];

            for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
              const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

              if (
                itemDesmembradoLista.sequencia !== sequencia &&
                itemDesmembradoLista.produtoCodigoDescricao ===
                  produtoCodigoDescricao &&
                itemDesmembradoLista.produto?.id === produto?.id &&
                itemDesmembradoLista.produto?.codigo === produto?.codigo &&
                itemDesmembradoLista.produto?.descricao ===
                  produto?.descricao &&
                itemDesmembradoLista.codigoBarras === codigoBarras
              ) {
                itemDesmembradoLista.spedTipoProduto = spedTipoProduto;

                formRef.current?.setFieldValorInicialSemExecutarEvento(
                  `listaEtapa2[${i}].listaItemDesmembrado[${j}].spedTipoProduto`,
                  spedTipoProduto
                );
              }
            }
          }
        }
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, listaItem, setLoading]
  );

  const handleOnReplicarSpedTipoProduto = useCallback(
    async (
      spedTipoProduto: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      try {
        setLoading(true);

        let todosPossuemSped = true;

        listaItem.forEach((item) => {
          item.listaItemDesmembrado.forEach((desmembrado) => {
            if (!desmembrado.spedTipoProduto) todosPossuemSped = false;
          });
        });

        if (!todosPossuemSped) {
          if (spedTipoProduto) {
            const resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 20 }}>
                  {`Deseja carregar o Tipo Sped: ${spedTipoProduto.codigo} - ${spedTipoProduto.descricao}
                          para todos os itens sem Tipo Sped informado?`}
                </span>
              ),
            });

            if (resposta) {
              for (let i = 0; i < listaItem.length; i++) {
                const itemLista = listaItem[i];

                for (
                  let j = 0;
                  j < itemLista.listaItemDesmembrado.length;
                  j++
                ) {
                  const itemDesmembradoLista =
                    itemLista.listaItemDesmembrado[j];

                  if (!itemDesmembradoLista.spedTipoProduto) {
                    itemDesmembradoLista.spedTipoProduto = spedTipoProduto;

                    formRef.current?.setFieldValorInicialSemExecutarEvento(
                      `listaEtapa2[${i}].listaItemDesmembrado[${j}].spedTipoProduto`,
                      spedTipoProduto
                    );

                    const response =
                      await ImportacaoXmlTerceiroNfeObterItemOperacaoFiscalComunicador.show(
                        {
                          params: {
                            codigoSpedTipoProduto: spedTipoProduto.codigo,
                            cfop: listaItem[index].itemNf.cfop,
                          },
                        }
                      );

                    listaItem[index].listaItemDesmembrado[
                      indexItemDesmembrado
                    ].operacaoFiscal = response.operacaoFiscal;

                    formRef.current?.setFieldValue(
                      `listaEtapa2[${i}].listaItemDesmembrado[${j}].operacaoFiscal`,
                      response.operacaoFiscal
                    );
                  }
                }
              }
            }
          }
        }
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [abrirJanela, formRef, listaItem, setLoading]
  );

  const handleOnAlterarOperacaoFiscal = useCallback(
    async (
      operacaoFiscal: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      for (let i = 0; i < listaItem.length; i++) {
        const itemLista = listaItem[i];

        for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
          const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

          if (
            itemDesmembradoLista.sequencia !== sequencia &&
            itemDesmembradoLista.produtoCodigoDescricao ===
              produtoCodigoDescricao &&
            itemDesmembradoLista.produto?.id === produto?.id &&
            itemDesmembradoLista.produto?.codigo === produto?.codigo &&
            itemDesmembradoLista.produto?.descricao === produto?.descricao &&
            itemDesmembradoLista.codigoBarras === codigoBarras
          ) {
            itemDesmembradoLista.operacaoFiscal = operacaoFiscal;

            formRef.current?.setFieldValorInicialSemExecutarEvento(
              `listaEtapa2[${i}].listaItemDesmembrado[${j}].operacaoFiscal`,
              operacaoFiscal
            );
          }
        }
      }

      if (
        !listaItem[index].listaItemDesmembrado[indexItemDesmembrado].produto
          ?.id &&
        operacaoFiscal &&
        (String(operacaoFiscal.cfop).startsWith('14') ||
          String(operacaoFiscal.cfop).startsWith('24') ||
          String(operacaoFiscal.cfop).startsWith('34'))
      ) {
        formRef.current?.setFieldValorInicialSemExecutarEvento(
          `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sujeitoASt`,
          true
        );
      }
    },
    [formRef, listaItem]
  );

  const handleOnAlterarAtualizarNcm = useCallback(
    async (
      atualizarNcm: boolean,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      for (let i = 0; i < listaItem.length; i++) {
        const itemLista = listaItem[i];

        for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
          const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

          if (
            itemDesmembradoLista.sequencia !== sequencia &&
            itemDesmembradoLista.produtoCodigoDescricao ===
              produtoCodigoDescricao &&
            itemDesmembradoLista.produto?.id === produto?.id &&
            itemDesmembradoLista.produto?.codigo === produto?.codigo &&
            itemDesmembradoLista.produto?.descricao === produto?.descricao &&
            itemDesmembradoLista.codigoBarras === codigoBarras
          ) {
            itemDesmembradoLista.atualizarNcm = atualizarNcm;

            formRef.current?.setFieldValorInicialSemExecutarEvento(
              `listaEtapa2[${i}].listaItemDesmembrado[${j}].atualizarNcm`,
              atualizarNcm
            );
          }
        }
      }
    },
    [formRef, listaItem]
  );

  const handleOnAlterarAtualizarOrigemIcms = useCallback(
    async (
      atualizarOrigemIcms: boolean,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const { produto, produtoCodigoDescricao, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      for (let i = 0; i < listaItem.length; i++) {
        const itemLista = listaItem[i];

        for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
          const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

          if (
            itemDesmembradoLista.sequencia !== sequencia &&
            itemDesmembradoLista.produtoCodigoDescricao ===
              produtoCodigoDescricao &&
            itemDesmembradoLista.produto?.id === produto?.id &&
            itemDesmembradoLista.produto?.codigo === produto?.codigo &&
            itemDesmembradoLista.produto?.descricao === produto?.descricao &&
            itemDesmembradoLista.codigoBarras === codigoBarras
          ) {
            itemDesmembradoLista.atualizarOrigemIcms = atualizarOrigemIcms;

            formRef.current?.setFieldValorInicialSemExecutarEvento(
              `listaEtapa2[${i}].listaItemDesmembrado[${j}].atualizarOrigemIcms`,
              atualizarOrigemIcms
            );
          }
        }
      }
    },
    [formRef, listaItem]
  );

  // #region Obter

  const handleObterProdutoJaCadastrado = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { produto } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (produto && produto.id) return true;

      return false;
    },
    [listaItem]
  );

  const handleObterNcmAtualDiferenteNcmNf = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const idNcm =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado].ncm?.id;
      const idNcmNf = listaItem[index].itemNf.ncmNf?.id;

      if (idNcm !== idNcmNf) return true;

      return false;
    },
    [listaItem]
  );

  const handleObterCalcularDiferencialAliquota = useCallback(
    (index: number): boolean => {
      const ufPessoa = listaItem[index].pessoaNf.uf;
      return ufPessoa !== empresa.uf;
    },
    [empresa, listaItem]
  );

  const handleObterSpedCfop = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { spedTipoProduto, operacaoFiscal } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (handleObterCalcularDiferencialAliquota(index)) {
        if (!!spedTipoProduto && !!operacaoFiscal) {
          const operacaoFiscalField = formRef.current?.getFieldValueNomeObjeto(
            `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].operacaoFiscal`
          );
          const sujeitoASt = formRef.current?.getFieldValue(
            `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sujeitoASt`
          );
          if (
            spedTipoProduto.codigo === '07' ||
            spedTipoProduto.codigo === '09' ||
            spedTipoProduto.codigo === '11' ||
            spedTipoProduto.codigo === '08'
          ) {
            if (
              operacaoFiscalField.tipoTransacao ===
                TipoTransacaoOperacaoFiscalEnum.compra &&
              operacaoFiscal.cfop !== '2407' &&
              operacaoFiscal.cfop !== '2406' &&
              (!sujeitoASt || sujeitoASt === 'false')
            ) {
              listaItem[index].listaItemDesmembrado[
                indexItemDesmembrado
              ].calcularDiferencialAliquota = true;

              formRef.current?.setFieldValue(
                `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].calcularDiferencialAliquota`,
                true
              );
            }
          } else if (
            operacaoFiscalField.tipoTransacao ===
              TipoTransacaoOperacaoFiscalEnum.compra &&
            operacaoFiscal.cfop !== '2401' &&
            operacaoFiscal.cfop !== '2403' &&
            (!sujeitoASt || sujeitoASt === 'false')
          ) {
            listaItem[index].listaItemDesmembrado[
              indexItemDesmembrado
            ].calcularDiferencialAliquota = true;

            formRef.current?.setFieldValue(
              `listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].calcularDiferencialAliquota`,
              true
            );
          }
        }
      }

      return !!spedTipoProduto && !!operacaoFiscal;
    },
    [formRef, handleObterCalcularDiferencialAliquota, listaItem]
  );

  const handleObterOrigemAtualDiferenteOrigemNf = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { origemIcms } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];
      const { origemIcmsNf } = listaItem[index].itemNf;

      if (
        (origemIcms.codigo === 1 && origemIcmsNf.codigo === 1) ||
        (origemIcms.codigo === 6 && origemIcmsNf.codigo === 6)
      ) {
        return true;
      }

      if (origemIcms.id !== origemIcmsNf.id) return true;

      return false;
    },
    [listaItem]
  );

  const handlePermiteAlterarAtualizacaoOrigem = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const { origemIcms } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];
      const { origemIcmsNf } = listaItem[index].itemNf;

      if (
        (origemIcms.codigo === 1 && origemIcmsNf.codigo === 1) ||
        (origemIcms.codigo === 6 && origemIcmsNf.codigo === 6)
      ) {
        return false;
      }

      return true;
    },
    [listaItem]
  );

  // #endregion Obter

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <Divisor>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <tbody>
              {listaItem?.map((item, index) => (
                <>
                  <tr>
                    <td colSpan={4} style={{ padding: 0 }}>
                      <Divisor style={{ marginBottom: 0 }}>
                        <Row>
                          <Col
                            lg={1}
                            md={6}
                            sm={6}
                            style={{ fontWeight: 'normal' }}
                          >
                            <InputLabel
                              label="Seq."
                              name={`listaEtapa2[${index}].itemNf.sequencia`}
                            />
                          </Col>
                          <Col lg={3} md={6} sm={6}>
                            <InputLabel
                              label="Cód. (NF.)"
                              name={`listaEtapa2[${index}].itemNf.produtoCodigo`}
                            />
                          </Col>
                          <Col lg={7} md={6} sm={12}>
                            <InputLabel
                              label="Descrição (NF.)"
                              name={`listaEtapa2[${index}].itemNf.produtoDescricao`}
                            />
                          </Col>
                          <Col lg={1} md={6} sm={12}>
                            <InputLabel
                              label="CFOP"
                              name={`listaEtapa2[${index}].itemNf.cfop`}
                            />
                          </Col>
                        </Row>
                      </Divisor>
                    </td>
                  </tr>

                  {item.listaItemDesmembrado.map((_, indexItemDesmembrado) => (
                    <tr>
                      <td colSpan={4} style={{ padding: 0, paddingBottom: 0 }}>
                        <Divisor
                          style={{
                            borderRadius: 0,
                            marginBottom: 0,
                            backgroundColor:
                              indexItemDesmembrado % 2 === 0
                                ? '#fff'
                                : '#EEEEEE',
                          }}
                        >
                          <Row>
                            <Col
                              lg={1}
                              md={2}
                              sm={2}
                              style={{ fontWeight: 'normal' }}
                            >
                              <InputLabel
                                label="Seq."
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sequencia`}
                                labelStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  color: 'darkBlue',
                                  fontWeight: 'bold',
                                }}
                                valorStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  color: 'darkBlue',
                                  fontWeight: 'bold',
                                  fontSize: 22,
                                }}
                              />
                            </Col>
                            <Col lg={11} md={10} sm={10}>
                              <InputLabel
                                label={`Produto ${
                                  handleObterProdutoJaCadastrado(
                                    index,
                                    indexItemDesmembrado
                                  )
                                    ? 'Cadastrado'
                                    : 'a ser Cadastrado'
                                }`}
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].produtoCodigoDescricao`}
                                labelStyle={{
                                  color: !handleObterProdutoJaCadastrado(
                                    index,
                                    indexItemDesmembrado
                                  )
                                    ? 'red'
                                    : '',
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={3} md={6} sm={12}>
                              <InputAutoCompleteSpedTipoProduto
                                label="Tipo SPED"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].idSpedTipoProduto`}
                                nomeObjeto={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].spedTipoProduto`}
                                placeholder="Tipo SPED"
                                disabled={handleObterProdutoJaCadastrado(
                                  index,
                                  indexItemDesmembrado
                                )}
                                onChangeItemAtual={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].spedTipoProduto =
                                    event.itemAtual ?? undefined;

                                  handleOnAlterarSpedTipoProduto(
                                    event.itemAtual,
                                    index,
                                    indexItemDesmembrado
                                  );
                                  handleOnReplicarSpedTipoProduto(
                                    event.itemAtual,
                                    index,
                                    indexItemDesmembrado
                                  );

                                  refresh();
                                }}
                              />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <InputAutoCompleteOperacaoFiscal
                                label="CFOP (Entrada)"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].idOperacaoFiscal`}
                                nomeObjeto={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].operacaoFiscal`}
                                placeholder="CFOP (Entrada)"
                                tipoEntradaSaida={
                                  TipoEntradaSaidaOperacaoFiscalEnum.entrada
                                }
                                onChangeItemAtual={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].operacaoFiscal =
                                    event.itemAtual ?? undefined;

                                  handleOnAlterarOperacaoFiscal(
                                    event.itemAtual,
                                    index,
                                    indexItemDesmembrado
                                  );

                                  refresh();
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                              <Select
                                label="Sujeito a ST."
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sujeitoASt`}
                                options={FormatarEnum({
                                  enumObj: BooleanEnum,
                                  nullavel: true,
                                })}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={3} md={3} sm={6}>
                              <InputLabel
                                label="NCM Atual"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].ncm.codigo`}
                              />
                            </Col>
                            <Col lg={3} md={3} sm={6}>
                              <InputLabel
                                label="NCM NF."
                                name={`listaEtapa2[${index}].itemNf.ncmNf.codigo`}
                              />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              style={{
                                display: !handleObterNcmAtualDiferenteNcmNf(
                                  index,
                                  indexItemDesmembrado
                                )
                                  ? 'none'
                                  : '',
                              }}
                            >
                              <InputSwitch
                                label="Atualizar o NCM do cadastro conforme o NCM da Nota Fiscal"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].atualizarNcm`}
                                ativo="Sim"
                                inativo="Não"
                                onChange={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].atualizarNcm = event.currentTarget.checked;

                                  handleOnAlterarAtualizarNcm(
                                    event.currentTarget.checked,
                                    index,
                                    indexItemDesmembrado
                                  );
                                  refresh();
                                }}
                              />
                            </Col>

                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              style={{
                                display:
                                  !handleObterCalcularDiferencialAliquota(index)
                                    ? 'none'
                                    : '',
                              }}
                            >
                              <InputSwitch
                                label="Calcular Diferencial Alíquota"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].calcularDiferencialAliquota`}
                                ativo="Sim"
                                inativo="Não"
                                disabled={
                                  !handleObterSpedCfop(
                                    index,
                                    indexItemDesmembrado
                                  )
                                }
                                onChange={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].calcularDiferencialAliquota =
                                    event.currentTarget.checked;

                                  refresh();
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={3} md={3} sm={6}>
                              <InputLabel
                                label="Origem Atual"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].origemIcms.codigo`}
                              />
                            </Col>
                            <Col lg={3} md={3} sm={6}>
                              <InputLabel
                                label="Origem NF."
                                name={`listaEtapa2[${index}].itemNf.origemIcmsNf.codigo`}
                              />
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={12}
                              style={{
                                display:
                                  !handleObterOrigemAtualDiferenteOrigemNf(
                                    index,
                                    indexItemDesmembrado
                                  )
                                    ? 'none'
                                    : '',
                              }}
                            >
                              <InputSwitch
                                label="Atualizar a Origem do cadastro conforme a Origem sugerida"
                                name={`listaEtapa2[${index}].listaItemDesmembrado[${indexItemDesmembrado}].atualizarOrigemIcms`}
                                ativo="Sim"
                                inativo="Não"
                                onChange={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].atualizarOrigemIcms =
                                    event.currentTarget.checked;

                                  handleOnAlterarAtualizarOrigemIcms(
                                    event.currentTarget.checked,
                                    index,
                                    indexItemDesmembrado
                                  );
                                  refresh();
                                }}
                                disabled={
                                  !handlePermiteAlterarAtualizacaoOrigem(
                                    index,
                                    indexItemDesmembrado
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Divisor>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        padding: 0,
                        paddingBottom: 20,
                        backgroundColor: '#3397C4',
                      }}
                    />
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>
    </div>
  );
};

export default forwardRef(ImportacaoNfeTerceiroEtapa2);
