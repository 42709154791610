import { IRoteiroFabricacaoValoresAlterar } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import RoteiroFabricacaoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/RoteiroFabricacao/Comunicador/RoteiroFabricacaoComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const rota = UseRota();
  const { abrirJanela } = UseConfirmacao();
  const { permissoes } = UsePermissoes();
  const navigate = useNavigate();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IRoteiroFabricacaoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await RoteiroFabricacaoComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial({
          ...response,
          listaMudou: false,
        });

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          ativo: true,
          listaMudou: false,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idProduto: Yup.string().nullable().required('Produto é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        data.listaRoteiroFabricacaoOperacao =
          data.listaRoteiroFabricacaoOperacao.map(
            (item: any, index: number) => {
              return { ...item, ordem: index + 1 };
            }
          );

        if (id) {
          await RoteiroFabricacaoComunicador.update({
            id,
            params: data,
          });
        } else {
          const response = await RoteiroFabricacaoComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Roteiro de Fabricação Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  const handleDuplicar = useCallback(async (): Promise<void> => {
    if (loading) return;
    const data = formRef.current?.getDataDuplicar() as any;
    data.listaRoteiroFabricacaoOperacao =
      data.listaRoteiroFabricacaoOperacao.map((valor: any) => {
        return {
          ...valor,
          id: undefined,
          idRoteiroFabricacao: undefined,
        };
      });

    if (permissoes.ManufaturaEngenhariaRoteiroDeFabricacao?.altera) {
      if (formRef.current?.validarSeAlterou()) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: <span style={{ fontSize: 20 }}>Deseja salvar o item?</span>,
        });
        if (resposta) {
          const { erro } = await handleSubmit(formRef.current.getData());
          if (erro) return;
        } else {
          await formRef.current.atualizarDataInicial();
        }
      }
    }

    delete data?.id;

    navigate('/home', { replace: true });
    navigate(rota.detalhes, {
      state: {
        dadosDuplicados: data,
      },
    });

    ToastInfo('Registro Duplicado!');
  }, [
    abrirJanela,
    handleSubmit,
    navigate,
    loading,
    permissoes.ManufaturaEngenhariaRoteiroDeFabricacao,
    rota.detalhes,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicar,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
