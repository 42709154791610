import React from 'react';
import { ListaProvider } from '../../../../../../../../../Componentes/Lista/ListaHooks';
import { TableDraggableProvider } from '../../../../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import IPadraoProps from '../../../../../../../../../Comum/Interface/IPadraoProps';
import { ItemNavegacaoHook } from './ItemNavegacaoHook';
import { ListaDraggableHook } from './ListaDraggableHook';

const ComprasListaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <TableDraggableProvider>
      <ListaProvider>
        <ListaDraggableHook>
          <ItemNavegacaoHook>{children}</ItemNavegacaoHook>
        </ListaDraggableHook>
      </ListaProvider>
    </TableDraggableProvider>
  );
};

export default ComprasListaHook;
