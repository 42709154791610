import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import BtnCloseButton from '../Buttons/BtnCloseButton';

interface IJanela {
  titulo: string;
  tamanho?: 'lg' | 'sm' | 'xl';
  children: any;
  onHide?: () => void;
}

export interface IJanelaRef {
  toggleJanela(valor?: boolean): void;
}

const Janela: React.ForwardRefRenderFunction<IJanelaRef, IJanela> = (
  { children, titulo, tamanho = 'lg', onHide },
  ref
) => {
  const [mostrar, setMostrar] = useState(false);

  const toggleJanela = useCallback((valor?: boolean) => {
    if (typeof valor === 'boolean') {
      setMostrar(valor);
    } else {
      setMostrar((state) => !state);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    toggleJanela,
  }));

  const handleHide = useCallback(() => {
    if (onHide) onHide();
    toggleJanela(false);
  }, [onHide, toggleJanela]);

  return (
    <ModalBootstrap size={tamanho} show={mostrar} onHide={handleHide}>
      <ModalBootstrap.Header
        style={{
          background: 'rgb(51, 151, 196)',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ModalBootstrap.Title>{titulo}</ModalBootstrap.Title>
        <BtnCloseButton type="button" onClick={handleHide}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </ModalBootstrap.Header>

      <ModalBootstrap.Body>{children}</ModalBootstrap.Body>
    </ModalBootstrap>
  );
};

export default forwardRef(Janela);
