import { AppErro, FormatarDataHoraParaPtBr } from '@elogestor/util';
import EmpresaCertificadoComunicador from '../../../Comunicador/Configuracao/Empresa/Certificado/Comunicador/EmpresaCertificadoComunicador';
import ToastInfo from '../../Toasts/ToastInfo';

export default async function TratarValidadeCertificado(): Promise<void> {
  const empresaCertificado = await EmpresaCertificadoComunicador.show();

  if (empresaCertificado && empresaCertificado.dataHoraValidadeFinal) {
    const dataVencimento = new Date(empresaCertificado.dataHoraValidadeFinal);
    const dataAtual = new Date();

    if (dataVencimento < dataAtual) {
      throw new AppErro({
        mensagem: 'Certificado digital esta vencido!',
      });
    } else {
      const diffTime = Math.abs(dataVencimento.valueOf() - dataAtual.valueOf());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 30) {
        ToastInfo(
          `Certificado digital valido até ${FormatarDataHoraParaPtBr(
            dataVencimento
          )}! Vence em ${diffDays} dias!`
        );
      }
    }
  }
}
