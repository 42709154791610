import React from 'react';
import { useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';

const ParametroDetalhe: React.FC = () => {
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <Main dadosRecuperados={(state?.dadosRecuperados as any) ?? null} />
    </Container>
  );
};

export default ParametroDetalhe;
