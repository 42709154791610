/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UseFieldCia } from '@elogestor/unformcia';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { BiEdit } from 'react-icons/bi/index.mjs';
import { v4 } from 'uuid';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../../AutoCompleteBase';
import Janela, { IJanelaRef } from '../../../../../JanelaAutocomplete';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import Detalhe from '../../../../../Detalhe';
import Main from '../../../../../../Paginas/Servicos/OrdensServicos/MarcaVeiculo/Detalhe/Main';
import { RedirecionarProvider } from '../../../../../../Hooks/RedirecionarContext';
import { AutoCompleteProvider } from '../../../AutoCompleteBase/Hooks/AutoCompleteHook';
import { ContainerAutoComplete } from './styles';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import MarcaVeiculoHook from '../../../../../../Paginas/Servicos/OrdensServicos/MarcaVeiculo/Detalhe/Hooks';
import MarcaVeiculoAutoCompleteComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/MarcaVeiculo/AutoComplete/MarcaVeiculoAutoComplete/MarcaVeiculoAutoCompleteComunicador';

interface IOnChange {
  valorAnterior: any;
}

interface IMarcaVeiculo {
  id: string;
  descricao: string;
}

interface IDadosPadrao {
  descricao: string;
}

export interface IOnChangeItemAtualAutoCompleteMarcaVeiculoEvent {
  itemAtual: IMarcaVeiculo | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteMarcaVeiculoEvent {
  input: HTMLInputElement | null;
}

export interface IOnBlurInputAutoCompleteMarcaVeiculoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteMarcaVeiculoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  permitirAdicionar?: boolean;
  ativo?: boolean;
  listaIdParaRemover?: string[];
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteMarcaVeiculoEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteMarcaVeiculoEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteMarcaVeiculoEvent
  ) => void | Promise<void>;
  onBlurInput?: (
    event: IOnBlurInputAutoCompleteMarcaVeiculoEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteMarcaVeiculoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteMarcaVeiculo: React.ForwardRefRenderFunction<
  IInputAutoCompleteMarcaVeiculoRef,
  IInputAutoCompleteMarcaVeiculoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    permitirAdicionar = true,
    ativo = true,
    listaIdParaRemover = [],
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    onBlurInput,
    ...rest
  },
  ref
) => {
  const { permissoes } = UsePermissoes();
  const [idRegistro, setIdRegistro] = useState('');

  const janelaRef = useRef<IJanelaRef>(null);
  const autoCompleteRef = useRef<IInputAutoCompleteBaseRef | null>(null);

  const [disabled, setDisabled] = useState(false);

  const flags = useRef({
    clearValue: false,
  });
  const quantidadeAlterado = useRef(0);

  const [dadosRecuperados, setDadosRecuperados] = useState(null);
  const [dadosPadrao, setDadosPadrao] = useState<IDadosPadrao | null>(null);
  const [pesquisando, setPesquisando] = useState(false);

  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    registerField<IMarcaVeiculo | null>({
      name: fieldName,
      ref: autoCompleteRef,
      nomeObjeto,

      getValue() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual?.id ?? null;
      },

      getDataDuplicar() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataPesquisa() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataRecuperarFormulario() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      getDataNomeObjeto() {
        const itemAtual = autoCompleteRef.current?.getItemAtual();
        return itemAtual;
      },

      setValue(_, value) {
        autoCompleteRef.current?.selecionarItem(value);
      },

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        if (value) setIdRegistro(value.id);
        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },

      clearValue(_, valorInicial) {
        flags.current.clearValue = true;
        this.setValue(_, valorInicial ?? null);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        const inputRef = autoCompleteRef.current?.getInput();

        if (inputRef) {
          inputRef.disabled = valor;
          setDisabled(valor);
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (itemAtual) {
        setDadosPadrao(null);
        setIdRegistro(itemAtual?.id);
      } else {
        setIdRegistro('');
      }
      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleBlurInput = useCallback(
    ({ input }: any) => {
      if (onBlurInput) onBlurInput({ input });
    },
    [onBlurInput]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      setDadosPadrao({
        descricao: input?.value || '',
      });
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  const handleSalva = useCallback((data: any) => {
    janelaRef.current?.toggleJanela();
    autoCompleteRef.current?.selecionarItem(data);
  }, []);

  const handleRecuperarFormulario = useCallback((dadosParaRecuparar: any) => {
    setDadosRecuperados(dadosParaRecuparar);
    janelaRef.current?.toggleJanela();
  }, []);

  const handleDescartarFormulario = useCallback(() => {
    setDadosRecuperados(null);
  }, []);

  const permiteAdicionarAlterar = useMemo<boolean>(() => {
    const itemAtual = autoCompleteRef.current?.getItemAtual();

    return (
      permitirAdicionar &&
      ((permissoes?.ServicoOrdensServicosMarcaVeiculo?.altera &&
        itemAtual?.id) ||
        (permissoes?.ServicoOrdensServicosMarcaVeiculo?.inclui &&
          !itemAtual?.id))
    );
  }, [permissoes, permitirAdicionar]);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response =
                  await MarcaVeiculoAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite,
                      texto: valor,
                      ativo,
                      listaIdParaRemover,
                    },
                  });

                return response;
              } catch (err) {
                TratarErros(err);
                return false;
              }
            }}
            obterChaveUnica={(item: IMarcaVeiculo) => {
              return item.id;
            }}
            obterLabel={(item: IMarcaVeiculo) => {
              if (!item.descricao) return '';

              return item.descricao;
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            onBlurInput={handleBlurInput}
            inputProps={rest}
          />

          {permiteAdicionarAlterar && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={v4()}>
                  {idRegistro ? 'Editar' : 'Adicionar'}
                </Tooltip>
              }
            >
              <ContainerAutoComplete>
                <div>
                  <Button
                    onClick={() => {
                      janelaRef.current?.toggleJanela();
                    }}
                    style={{ marginLeft: 10, marginBottom: 5, height: 35 }}
                  >
                    {idRegistro ? <BiEdit /> : <IoMdAddCircleOutline />}
                  </Button>
                </div>
              </ContainerAutoComplete>
            </OverlayTrigger>
          )}
        </div>

        {permiteAdicionarAlterar && (
          <Janela titulo="Marca do Veículo" ref={janelaRef} tamanho="xl">
            <RedirecionarProvider value={{ redirecionar: false }}>
              <MarcaVeiculoHook>
                <AutoCompleteProvider>
                  <Detalhe
                    style={{}}
                    onRecuperarFormulario={handleRecuperarFormulario}
                    onDescartarFormulario={handleDescartarFormulario}
                  >
                    <Main
                      idEditar={idRegistro ?? null}
                      dadosRecuperados={dadosRecuperados}
                      dadosDuplicados={null}
                      dadosPadrao={dadosPadrao}
                      onClickSalvar={handleSalva}
                    />
                  </Detalhe>
                </AutoCompleteProvider>
              </MarcaVeiculoHook>
            </RedirecionarProvider>
          </Janela>
        )}
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteMarcaVeiculo);
