import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  position: relative;
`;

export const ContainerDados = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin: 50px -10px 15px -10px;
  overflow: auto;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin: 0 0 0 5px;
  }
`;

export const LoadingContainer = styled.div`
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .spinner {
    border: 3px solid white;
    font-size: 8;
    border-radius: 50%;
    border-top-color: #0e8276;
    animation: spin 1s ease-in-out infinite;
  }
`;
