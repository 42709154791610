import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/transmitir';

interface INotaFiscalEntradaTransmissaoEventoTransmitirComunicadorUpdate {
  id: string;
  params: any;
}

class NotaFiscalEntradaTransmissaoEventoTransmitirComunicador {
  public async update(
    params: INotaFiscalEntradaTransmissaoEventoTransmitirComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoTransmitirComunicador();
