import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  SituacaoConhecimentoTransporteEnum,
  IConhecimentoTransporteValoresAlterar,
  TipoCteEnum,
  DestinadoEnum,
  TipoServicoEnum,
  TipoTomadorEnum,
  RegimeTributarioEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import ConhecimentoTransporteComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteComunicador';
import ConhecimentoTransporteObterDadosPadraoComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteObterDadosPadraoComunicador';
import ConhecimentoTransporteDuplicarComunicador from '../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteDuplicarComunicador';
import { UseShowTabs } from './ShowTabsHook';
import EmpresaRegimeTributarioVigenteComunicador from '../../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaRegimeTributarioVigenteComunicador';
import { UseRegimeTributario } from '../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { IInputAutoCompletePadraoRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<any> = ({ children, atualizarCampos }) => {
  const rota = UseRota();
  const { redirecionar } = UseRedirecionar();
  const { setShowNotaReferenciadaTab } = UseShowTabs();
  const navigate = useNavigate();
  const { setRegimeTributarioPorData, regimeTributario } =
    UseRegimeTributario();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback(
    (valor: string | null) => {
      idDetalheRegistro.current = valor;
      refresh();
    },
    [refresh]
  );

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IConhecimentoTransporteValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        setLoading(true);

        const response = await ConhecimentoTransporteComunicador.show({
          id: idEditar,
        });

        await setRegimeTributarioPorData(response.dataHoraEmissao);

        if (response.listaConhecimentoTransporteNotaReferenciada?.length) {
          setShowNotaReferenciadaTab(true);
        }

        if (response.listaConhecimentoTransporteDocumentoReferenciado?.length) {
          setShowNotaReferenciadaTab(false);
        }

        if (
          !response.listaConhecimentoTransporteDocumentoReferenciado?.length &&
          !response.listaConhecimentoTransporteNotaReferenciada?.length
        ) {
          setShowNotaReferenciadaTab(null);
        }

        await formRef.current?.setSemExecutarEvento(response);
        await formRef.current?.atualizarDataInicial();
        atualizarCampos && (await atualizarCampos());

        setLoading(false);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        setLoading(true);

        const response =
          await ConhecimentoTransporteObterDadosPadraoComunicador.show();

        let dataHoraEmissao = new Date();
        const dataHoraAtualSetada =
          formRef.current?.getFieldValue('dataHoraEmissao');

        if (dataHoraAtualSetada) {
          dataHoraEmissao = dataHoraAtualSetada;
        }

        await setRegimeTributarioPorData(dataHoraEmissao);

        await formRef.current?.setDataInicial({
          ...response,
          tipoCte: TipoCteEnum.normal,
          tipoServico: TipoServicoEnum.normal,
          situacao: SituacaoConhecimentoTransporteEnum.naoTransmitido,
          retira: false,
          dataHoraEmissao,
          tipoTomador: TipoTomadorEnum.remetente,

          financeiroConferido: false,
          valorFinanceiro: 0,
          tomadorAlteradoSubstituto: false,

          conhecimentoTransporteImpostoIcms: {
            calcularIcms: false,
            destinado: DestinadoEnum.industrializacao,
          },

          ...dadosPadrao,
        });

        if (atualizarCampos) {
          await atualizarCampos();
        }

        setLoading(false);

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      atualizarCampos,
      getIdDetalheRegistro,
      handleSetarFocus,
      redirecionar,
      refresh,
      setRegimeTributarioPorData,
      setShowNotaReferenciadaTab,
    ]
  );

  const makeSchemaDadosPessoa = (pessoa: string, condicao: boolean): any => {
    if (!condicao) {
      return Yup.object().optional();
    }

    return Yup.object({
      cnpjCpfVirtual: Yup.string().required(
        `O CNPJ/CPF do ${pessoa} é obrigatório!`
      ),
    });
  };

  const makeSchemaDadosEnderecoPessoa = (
    pessoa: string,
    condicao: boolean
  ): any => {
    if (!condicao) {
      return Yup.object().optional();
    }

    return Yup.object({
      idCidade: Yup.string()
        .nullable()
        .required(`A Cidade do ${pessoa} é obrigatória!`),
      logradouro: Yup.string().required(
        `O Logradouro do ${pessoa} é obrigatório!`
      ),
      numero: Yup.string().required(
        `O Número do Endereço do ${pessoa} é obrigatório!`
      ),
      bairro: Yup.string().required(`O Bairro do ${pessoa} é obrigatório!`),
    });
  };

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      const tipoTomador = formRef.current?.getFieldValue('tipoTomador');
      const tipoCte = formRef.current?.getFieldValue('tipoCte');

      // const listaCamposSubstituto: Array<string | undefined> = [
      //   data.substitutoChaveAcessoNfe,
      //   data.substitutoChaveAcessoCteAnulacao,
      //   data.substitutoChaveAcessoCteEmitTomador,
      // ];

      // const minimoUmCampoInformado =
      //   listaCamposSubstituto.filter(Boolean).length > 0;

      // const errorMessage = 'Informar no mínimo um campo!';
      // const chavesSubstitutoErrors = {
      //   substitutoChaveAcessoNfe: errorMessage,
      //   substitutoChaveAcessoCteAnulacao: errorMessage,
      //   substitutoChaveAcessoCteEmitTomador: errorMessage,
      // };
      // const hasErroSubstituto =
      //   tipoCte === TipoCteEnum.substituto && !minimoUmCampoInformado;

      const cst =
        formRef.current?.getFieldValueNomeObjeto(
          'conhecimentoTransporteImpostoIcms.situacaoTributaria'
        )?.codigo ?? '';

      const regimeSimplesNacional =
        regimeTributario ===
          RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte ||
        regimeTributario === RegimeTributarioEnum.simplesNacionalMicroEmpresa;

      try {
        formRef.current?.setErrors({});
        // if (hasErroSubstituto) {
        //   TratarErros(
        //     new AppErro({
        //       mensagem:
        //         'Quando Tipo CTe - Substituto: Informar no mínimo uma chave de acesso do CTe!',
        //     })
        //   );

        //   formRef.current?.setErrors({ ...chavesSubstitutoErrors });
        // }

        const schema = Yup.object().shape({
          idSerie: Yup.string().nullable().required('Série é obrigatória!'),

          numero: Yup.number().required('Número é obrigatório!'),

          dataHoraEmissao: Yup.date()
            .nullable()
            .required('Data de Emissão é obrigatória!'),

          tipoCte: Yup.mixed()
            .oneOf(Object.values(TipoCteEnum))
            .required('Tipo de CTe é obrigatório!'),

          tipoServico: Yup.mixed()
            .oneOf(Object.values(TipoServicoEnum))
            .required('Tipo de Serviço é obrigatório!'),

          tipoTomador: Yup.mixed()
            .oneOf(
              Object.values(TipoTomadorEnum),
              'Tipo de Tomador é obrigatório!'
            )
            .required('Tipo de Tomador é obrigatório!'),

          rntrc: Yup.string()
            .nullable()
            .length(8, 'RNTRC deve conter 8 dígitos!')
            .required('RNTRC não informado!'),

          idPessoaRemetente: Yup.string()
            .nullable()
            .required('Remetente é obrigatório!'),
          idPessoaDestinatario: Yup.string()
            .nullable()
            .required('Destinatario é obrigatório!'),

          idPessoaExpedidor: Yup.string()
            .nullable()
            .test({
              message:
                'Quanto Tipo de Tomador: Expedidor - Informar o Expedidor!',
              test: (value: any) => {
                return !(tipoTomador === TipoTomadorEnum.expedidor && !value);
              },
            }),

          idPessoaRecebedor: Yup.string()
            .nullable()
            .test({
              message:
                'Quanto Tipo de Tomador: Recebedor - Informar o Recebedor!',
              test: (value: any) => {
                return !(tipoTomador === TipoTomadorEnum.recebedor && !value);
              },
            }),

          idPessoaTomadorOutro: Yup.string()
            .nullable()
            .test({
              message: 'Quanto Tipo de Tomador: Outros - Informar o tomador!',
              test: (value: any) => {
                return !(tipoTomador === TipoTomadorEnum.outros && !value);
              },
            }),

          valorTotalServico: Yup.number().required(
            'Insira ao menos um Serviço!'
          ),

          valorCarga: Yup.number()
            .nullable()
            .required('Valor da Carga não informado!'),

          observacoes: Yup.string()
            .nullable()
            .max(2000, 'As Observações devem ter no máximo 2000 caracteres!'),

          informacoesInteresseFisco: Yup.string()
            .nullable()
            .max(
              2000,
              'As Informações do Interesse Fisco devem ter no máximo 2000 caracteres!'
            ),

          produtoPredominante: Yup.string()
            .nullable()
            .max(60, 'O Produto Predominante deve ter no máximo 60 caracteres!')
            .required('O Produto Predominante é obrigatório!'),

          conhecimentoTransporteImpostoIcms: Yup.object({
            idTipoNota: Yup.string()
              .nullable()
              .required('O Tipo de Nota é obrigatório!'),
            idTipoNotaMotivo: Yup.string()
              .nullable()
              .required('O Motivo é obrigatório!'),
            idOperacaoFiscal: Yup.string()
              .nullable()
              .required('O CFOP é obrigatório!'),
            destinado: Yup.mixed()
              .oneOf(Object.values(DestinadoEnum), 'Destinado é obrigatório!')
              .required('Destinado é obrigatório!'),
            naturezaOperacao: Yup.string()
              .nullable()
              .max(
                60,
                'A Natureza da Operação deve ter no máximo 60 caracteres!'
              )
              .required('A Natureza da Operação é obrigatória!'),
            idSituacaoTributaria: Yup.string()
              .nullable()
              .test({
                message: 'A CST é obrigatória!',
                test: (val) => {
                  if (
                    data.conhecimentoTransporteImpostoIcms
                      .emitenteSimplesNacional ||
                    regimeTributario ===
                      RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte ||
                    regimeTributario ===
                      RegimeTributarioEnum.simplesNacionalExcessoSublimiteReceitaBruta ||
                    regimeTributario ===
                      RegimeTributarioEnum.simplesNacionalMicroEmpresa
                  ) {
                    return true;
                  }

                  return !!val;
                },
              }),
            percentualReducaoBaseCalculo: Yup.mixed()
              .nullable()
              .test({
                message:
                  'Para CST 20 é obrigatório a Redução da Base de Cálculo!',
                test: (value: any) => {
                  if (cst === '20' && !value) return false;
                  return true;
                },
              }),
            aliquota: Yup.mixed()
              .nullable()
              .test({
                message:
                  'Para o CST escolhido é obrigatório a Alíquota do ICMS!',
                test: (value: any) => {
                  if (
                    (cst === '20' ||
                      cst === '00' ||
                      (cst === '90' && !regimeSimplesNacional)) &&
                    !value
                  ) {
                    return false;
                  }
                  return true;
                },
              }),
          }),

          conhecimentoTransporteDestinatarioEndereco:
            makeSchemaDadosEnderecoPessoa('Destinatário', true),
          conhecimentoTransporteDestinatario: makeSchemaDadosPessoa(
            'Destinatário',
            true
          ),

          conhecimentoTransporteRemetenteEndereco:
            makeSchemaDadosEnderecoPessoa('Remetente', true),
          conhecimentoTransporteRemetente: makeSchemaDadosPessoa(
            'Remetente',
            true
          ),

          conhecimentoTransporteExpedidor: makeSchemaDadosPessoa(
            'Expedidor',
            data.idPessoaExpedidor
          ),
          conhecimentoTransporteExpedidorEndereco:
            makeSchemaDadosEnderecoPessoa('Expedidor', data.idPessoaExpedidor),

          conhecimentoTransporteRecebedor: makeSchemaDadosPessoa(
            'Recebedor',
            data.idPessoaRecebedor
          ),
          conhecimentoTransporteRecebedorEndereco:
            makeSchemaDadosEnderecoPessoa('Recebedor', data.idPessoaRecebedor),
          conhecimentoTransporteTomadorOutro: makeSchemaDadosPessoa(
            'Tomador',
            data.idPessoaTomadorOutro
          ),
          conhecimentoTransporteTomadorOutroEndereco:
            makeSchemaDadosEnderecoPessoa('Tomador', data.idPessoaTomadorOutro),

          percentualReducaoBaseCalculo: Yup.mixed()
            .nullable()
            .test({
              message:
                'Para CST 20 é obrigatório a Redução da Base de Cálculo!',
              test: (value: any) => {
                if (cst === '20' && !value) return false;
                return true;
              },
            }),

          anulacaoChaveAcesso: Yup.string()
            .nullable()
            .test({
              message: 'A Chave de Acesso do CT-e de anulação obrigatória!',
              test: (value: any) => {
                return !(tipoCte === TipoCteEnum.anulacao && !value);
              },
            })
            .min(44, 'Chave de Acesso inválida!'),

          anulacaoDataEmissao: Yup.string()
            .nullable()
            .test({
              message: 'A Data de Emissão do CT-e de anulação é obrigatória!',
              test: (value: any) => {
                return !(tipoCte === TipoCteEnum.anulacao && !value);
              },
            }),

          substitutoChaveAcessoCteOriginal: Yup.string()
            .nullable()
            .test({
              message:
                'A Chave de Acesso do CT-e a ser Substituído (original) é obrigatória!',
              test: (value: any) => {
                if (tipoCte === TipoCteEnum.substituto && !value) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (tipoCte === TipoCteEnum.substituto && value) {
                  return value.length === 44;
                }
                return true;
              },
            }),

          substitutoChaveAcessoCteAnulacao: Yup.string()
            .nullable()
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (tipoCte === TipoCteEnum.substituto && value) {
                  return value.length === 44;
                }
                return true;
              },
            }),

          substitutoChaveAcessoNfe: Yup.string()
            .nullable()
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (tipoCte === TipoCteEnum.substituto && value) {
                  return value.length === 44;
                }
                return true;
              },
            }),

          substitutoChaveAcessoCteEmitTomador: Yup.string()
            .nullable()
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (tipoCte === TipoCteEnum.substituto && value) {
                  return value.length === 44;
                }
                return true;
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });

        // if (hasErroSubstituto) return false;

        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);

        // if (hasErroSubstituto) {
        //   formRef.current?.setErrors({ ...chavesSubstitutoErrors });
        // }

        // const erroSubstituto = hasErroSubstituto
        //   ? { ...chavesSubstitutoErrors }
        //   : {};

        formRef.current?.setErrors({ ...errors });
        // formRef.current?.setErrors({ ...errors, ...erroSubstituto });
        return false;
      }
    },
    [regimeTributario]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const item = await EmpresaRegimeTributarioVigenteComunicador.index({
          params: {
            dataFinalVigencia: new Date(data.dataHoraEmissao),
          },
        });

        data.conhecimentoTransporteImpostoIcms.emitenteSimplesNacional =
          item?.regimeTributario
            ? item.regimeTributario.toLowerCase().includes('simples nacional')
            : false;

        let id = getIdDetalheRegistro() || '';

        if (data.tipoCte !== TipoCteEnum.anulacao) {
          data.anulacaoChaveAcesso = null;
          data.anulacaoDataEmissao = null;
        }

        if (data.tipoCte !== TipoCteEnum.substituto) {
          data.substitutoChaveAcessoCteOriginal = null;
          data.substitutoChaveAcessoCteAnulacao = null;
          data.substitutoChaveAcessoNfe = null;
          data.substitutoChaveAcessoCteEmitTomador = null;
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        data.conhecimentoTransporteImpostoIcms.emitenteSimplesNacional =
          data.conhecimentoTransporteImpostoIcms.emitenteSimplesNacional ??
          false;

        data.conhecimentoTransporteTomadorOutro = data.idPessoaTomadorOutro
          ? data.conhecimentoTransporteTomadorOutro
          : null;
        data.conhecimentoTransporteTomadorOutroEndereco =
          data.idPessoaTomadorOutro
            ? data.conhecimentoTransporteTomadorOutroEndereco
            : null;

        data.conhecimentoTransporteExpedidor = data.idPessoaExpedidor
          ? data.conhecimentoTransporteExpedidor
          : null;
        data.conhecimentoTransporteExpedidorEndereco = data.idPessoaExpedidor
          ? data.conhecimentoTransporteExpedidorEndereco
          : null;

        data.conhecimentoTransporteRecebedor = data.idPessoaRecebedor
          ? data.conhecimentoTransporteRecebedor
          : null;
        data.conhecimentoTransporteRecebedorEndereco = data.idPessoaRecebedor
          ? data.conhecimentoTransporteRecebedorEndereco
          : null;

        data.rntrc = String(data.rntrc).padStart(8, '0');

        if (data.conhecimentoTransporteImpostoIcms.emitenteSimplesNacional) {
          data.conhecimentoTransporteImpostoIcms.calcularIcms = false;
          data.conhecimentoTransporteImpostoIcms.baseCalculo = null;
          data.conhecimentoTransporteImpostoIcms.percentualReducaoBaseCalculo =
            null;
          data.conhecimentoTransporteImpostoIcms.aliquota = null;
          data.conhecimentoTransporteImpostoIcms.valor = null;
        }

        if (id) {
          await ConhecimentoTransporteComunicador.update({
            id,
            params: data,
          });
        } else {
          const response = await ConhecimentoTransporteComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Conhecimento Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const id = getIdDetalheRegistro() || '';

      if (id) {
        const response = await ConhecimentoTransporteDuplicarComunicador.store({
          idRegistroDuplicar: id,
        });
        setIdDetalheRegistro(response.id);

        navigate({
          pathname: `${rota.detalhes}/${response.id}`,
        });

        await formRef.current?.setDisableAll(false);
        await handleCarregarDados();

        const serie = formRef.current?.getFieldValueNomeObjeto('serie');
        formRef.current?.setFieldDisabled(
          'numero',
          serie ? serie.incrementarAutomaticamente : true
        );

        ToastSucesso('Registro Duplicado e Salvo!');
      }

      setLoading(false);
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    navigate,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
