/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../Util/Erro/GetValidationErrors';
import Input from '../../Componentes/Inputs/Input';
import { Container, FormCiaLogin, Button } from './style';
import TratarErros from '../../Util/Erro/TratarErros';
import ToastSucesso from '../../Util/Toasts/ToastSucesso';
import UsuarioEsqueceuSenhaComunicador from '../../Comunicador/Configuracao/UsuarioEsqueceuSenha/Comunicador/UsuarioEsqueceuSenhaComunicador';

const EsqueceuSenha: React.FC = () => {
  const navigate = useNavigate();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nomeSubdominio: Yup.string().required(
            'CNPJ/CPF ou Subdomínio é obrigatório'
          ),
          nomeUsuario: Yup.string().required('Usuário é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleSubmit = useCallback(async () => {
    const data = formRef.current?.getData();

    try {
      if (!(await handleValidar(data))) return;

      const response = await UsuarioEsqueceuSenhaComunicador.store({
        params: data,
      });
      ToastSucesso(response.mensagem);

      navigate('/');
    } catch (error) {
      TratarErros(error);
    }
  }, [handleValidar, navigate]);

  return (
    <Container>
      <div>
        <h1>Esqueceu a Senha</h1>
        <FormCiaLogin ref={formRef}>
          <Input
            name="nomeSubdominio"
            placeholder="CNPJ/CPF ou Subdomínio"
            label="CNPJ/CPF ou Subdomínio *"
            tabIndex={1}
            maiuscula={false}
          />
          <Input
            name="nomeUsuario"
            placeholder="Usuário"
            label="Usuário *"
            tabIndex={2}
            maiuscula={false}
          />

          <Button
            style={{ marginTop: 15 }}
            type="button"
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </FormCiaLogin>
      </div>
    </Container>
  );
};

export default EsqueceuSenha;
