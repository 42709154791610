import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico-importacao-orcamento/obter-orcamento';

interface IOrdemServicoImportacaoOrcamentoObterOrcamentoComunicadorIndex {
  idPessoa: string;
}

class OrdemServicoImportacaoOrcamentoObterOrcamentoComunicador {
  public async index(
    params: IOrdemServicoImportacaoOrcamentoObterOrcamentoComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new OrdemServicoImportacaoOrcamentoObterOrcamentoComunicador();
