import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada-importacao-pedido-compra/gerar';

interface INotaFiscalEntradaImportacaoPedidoCompraGerarComunicadorStore {
  params: any;
}

class NotaFiscalEntradaImportacaoPedidoCompraGerarComunicador {
  public async store(
    params: INotaFiscalEntradaImportacaoPedidoCompraGerarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaImportacaoPedidoCompraGerarComunicador();
