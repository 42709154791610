import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const SisdevinTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <h6>Seleção de Registros Mensais a Serem Gerados</h6>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <h6>Seleção de Registros Anuais a Serem Gerados</h6>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinEngarrafadoseGranel"
                label="Guias Engarrafados e Granel"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinNotasCompraUva"
                label="Notas de Compras de Uvas"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinCortes"
                label="Comunicação de Cortes"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinDeclaracaoProducao"
                label="Declaração de Produção"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox name="sisdevinEnologicos" label="Enológicos" />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinDeclaracaoEstoque"
                label="Declaração de Estoque"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="sisdevinComunicacaoPerdas"
                label="Declaração de Perdas"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default SisdevinTab;
