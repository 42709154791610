/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  IEtiquetaImpressao,
  IEtiquetaElementoImpressao,
  TipoElementoEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import * as Yup from 'yup';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import EtiquetaImpressaoPDF from '../../../../../Configuracao/Etiqueta/Detalhe/Impressao/EtiquetaImpressaoPDF';
import PDF from '../../../../../../Util/Relatorios/PDF';
import { UseImpressaoEtiqueta } from '../Hooks/ImpressaoEtiquetaHook';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import ImpressaoEtiquetasProdutosObterInformacoesProdutoComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/ImpressaoEtiquetasProdutos/Comunicador/ImpressaoEtiquetasProdutosObterInformacoesProdutoComunicador';
import EtiquetaObterInformacoesModeloEtiquetaComunicador from '../../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/EtiquetaObterInformacoesModeloEtiquetaComunicador';

interface IAcoesImpressaoEtiquetasProdutos {
  permissao?: IPermissao;
}

const AcoesImpressaoEtiquetasProdutos: React.FC<
  IAcoesImpressaoEtiquetasProdutos
> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, loading, setLoading } = UseForm();
  const { listaEtiqueta: listaProdutoEtiqueta } = UseImpressaoEtiqueta();
  const idEtiqueta = formRef.current?.getFieldValue('idEtiqueta');

  const handleObterListaProdutoEtiquetaSelecionado = useCallback((): any[] => {
    return listaProdutoEtiqueta.filter((item) => item.selecionado);
  }, [listaProdutoEtiqueta]);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idEtiqueta: Yup.string()
            .nullable()
            .required('Modelo de Etiqueta é Obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef.current?.setErrors(errors);

        return false;
      }
    },
    [formRef]
  );

  const handleImprimir = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current?.getData();

      const listaProdutoEtiquetaSelecionada =
        handleObterListaProdutoEtiquetaSelecionado();

      if (!(await handleValidar(data))) {
        setLoading(false);
        return;
      }

      const response =
        await EtiquetaObterInformacoesModeloEtiquetaComunicador.show({
          id: idEtiqueta,
        });

      const listaIdProduto = listaProdutoEtiquetaSelecionada.map((item) => {
        return item.produto.id;
      });

      const listaProdutoRetorno =
        await ImpressaoEtiquetasProdutosObterInformacoesProdutoComunicador.index(
          { params: { listaIdProduto } }
        );

      const listaProdutoImprimir: any[] = [];

      for (let i = 0; i < listaProdutoEtiquetaSelecionada.length; i++) {
        const produtoEtiqueta = listaProdutoEtiquetaSelecionada[i];
        const produtoRetorno = listaProdutoRetorno[i];

        listaProdutoImprimir.push({
          ...produtoEtiqueta,

          produto: {
            ...produtoEtiqueta.produto,
            ...produtoRetorno.produto,
          },

          empresa: produtoRetorno.empresa,
        });
      }

      const dados: IEtiquetaImpressao = {
        ...response,
        listaEtiqueta: [],
      };

      for (let i = 0; i < listaProdutoImprimir.length; i++) {
        const produtoImprimir = listaProdutoImprimir[i];

        const listaEtiquetaElementoImpressao: IEtiquetaElementoImpressao[] = [];

        for (let j = 0; j < response.listaEtiquetaElemento.length; j++) {
          const etiquetaElemento = response.listaEtiquetaElemento[j];

          let arrayCodigo: string[] = produtoImprimir.produto.codigo.split('/');
          let codigoProduto = '';
          let texto: any;

          switch (etiquetaElemento.etiquetaElementoDisponivel?.identificador) {
            case 'codigoBarras':
              texto = produtoImprimir.produto.produtoEngenharia?.codigoBarras;
              break;

            case 'codigoBarrasCodigoProduto':
              texto = produtoImprimir.produto.codigo;
              break;

            case 'codigoBarrasProduto':
              texto = produtoImprimir.produto.produtoEngenharia?.codigoBarras;
              break;

            case 'codigoBarrasInicioCodigoProduto':
              if (arrayCodigo.length > 0) {
                codigoProduto = String(arrayCodigo[0]);
              } else {
                arrayCodigo = produtoImprimir.produto.codigo.split('-');
                if (arrayCodigo.length > 0) {
                  codigoProduto = String(arrayCodigo[0]);
                }
              }
              texto = codigoProduto;
              break;

            case 'nomeRazaoSocialEmpresa':
              texto = produtoImprimir.empresa.nomeRazaoSocial;
              break;

            case 'nomeFantasiaEmpresa':
              texto = produtoImprimir.empresa.nomeFantasia;
              break;

            case 'codigoProduto':
              texto = produtoImprimir.produto.codigo;
              break;

            case 'descricaoProduto':
              texto = produtoImprimir.produto.descricao;
              break;

            case 'valorUnitario':
              texto = produtoImprimir.valorUnitarioVenda;
              break;

            case 'siglaUn':
              texto =
                produtoImprimir.produto.produtoEstoque?.unidadeMedida.sigla;
              break;

            case 'marca':
              texto =
                produtoImprimir.produto.produtoComercial?.marcaProduto
                  .descricao;
              break;

            case 'modelo':
              texto = produtoImprimir.produto.produtoComercial?.modeloProduto;
              break;

            case 'percentualDesconto':
              texto = produtoImprimir.percentualDescontoAVista;
              break;

            case 'valorUnitarioVendaProdutoComDesconto':
              texto = String(
                (Number(produtoImprimir.percentualDescontoAVista) / 100) *
                  Number(produtoImprimir.valorUnitarioVenda)
              );
              break;

            default:
              texto = String(etiquetaElemento.texto);
              break;
          }

          const etiquetaElementoImpressao: IEtiquetaElementoImpressao = {
            ...etiquetaElemento,
            tipoElemento:
              etiquetaElemento.etiquetaElementoDisponivel?.tipoElemento ??
              TipoElementoEnum.campo,
            texto,
          };

          listaEtiquetaElementoImpressao.push(etiquetaElementoImpressao);
        }

        if (dados.listaEtiqueta) {
          for (let k = 0; k < produtoImprimir.quantidade; k++) {
            dados.listaEtiqueta.push({
              listaElemento: listaEtiquetaElementoImpressao,
            });
          }
        }
      }

      const relatorio = await PDF({
        documento: <EtiquetaImpressaoPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleObterListaProdutoEtiquetaSelecionado,
    handleValidar,
    idEtiqueta,
    setLoading,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={
            loading || handleObterListaProdutoEtiquetaSelecionado().length === 0
          }
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesImpressaoEtiquetasProdutos;
