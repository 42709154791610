/* eslint-disable array-callback-return */
/* eslint-disable no-continue */
/* eslint-disable default-case */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Col, Row, Button, Table } from 'react-bootstrap';
import {
  IFrenteCaixaPdvFinalizarVendaPagamentoLista,
  IFrenteCaixaPdvFinalizarVendaValoresInserir,
  IsPagamentoTef,
  MeioPagamentoEnum,
  StatusTefEnum,
  TipoContaEnum,
} from '@elogestor/util';
import { IoMdAddCircleOutline, IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import Divisor from '../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import InputAutoCompleteContaTipoAdiantamentoEnum from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteContaTipoAdiantamentoEnum';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import ItemPagamento, { IItemPagamentoRef } from './ItemPagamento';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputLabel from '../../../../../../../Componentes/Inputs/InputLabel';
import FormaPagamentoFrenteCaixaPdvComunicador from '../../../../../../../Comunicador/Financeiro/Geral/FormaPagamento/Comunicador/FormaPagamentoFrenteCaixaPdvComunicador';
import { UseParcelas } from './Hooks/ParcelasHook';
import InputHiddenHtml from '../../../../../../../Componentes/Inputs/InputHiddenHtml';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import { UseFrenteCaixaPdv } from '../../Hooks/FrenteCaixaPdvHook';
import FrenteCaixaPdvGerarNotaFiscalSaidaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaComunicador';
import { UseAlerta } from '../../../../../../../Componentes/Alerta/HooksAlerta';
import InputAutoCompleteFormaPagamento from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import FrenteCaixaPdvConfirmarPagamentoSiTefComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvConfirmarPagamentoSiTefComunicador';
import FrenteCaixaPdvCancelarPagamentoSiTefComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvCancelarPagamentoSiTefComunicador';
import FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador';
import FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador';
import { UseSiTef } from './Hooks/TefHook/SiTefHook';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import { Sleep } from '../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import InputAutoCompleteTagContaTipoAdiantamentoEnum from '../../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagContaTipoAdiantamentoEnum';

interface IFinalizarVendaModal {
  onFinalizarFormModal(
    dados: IFrenteCaixaPdvFinalizarVendaValoresInserir,
    idNotaFiscal?: string | null
  ): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
  gerarNotaFiscalSaida: boolean;
}

const FinalizarVenda: React.FC<IFinalizarVendaModal> = ({
  onFinalizarFormModal,
  onFecharFormModal,
  loading,
  setLoading,
  gerarNotaFiscalSaida,
}) => {
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const {
    formRef: formPrincipal,
    handleCarregarDados: handleCarregarDadosPrincipal,
  } = UseForm();
  const {
    listaItem,
    idNotaFiscalSaida,
    idPreVenda,
    listaIdItemConsignado,
    setIdNotaFiscalSaida,
    possuiTerminalCaixaComIntegracaoTef,
  } = UseFrenteCaixaPdv();
  const { abrirJanela } = UseAlerta();
  const { setTitulo, abrirParcelas, carregarDados } = UseParcelas();
  const { realizarPagamentoSiTef, continuarPagamentoSiTef } = UseSiTef();

  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const listaItemPagamentoRef = useRef<Array<IItemPagamentoRef | null>>([]);
  const [listaItemPagamento, setListaItemPagamento] = useState<
    IFrenteCaixaPdvFinalizarVendaPagamentoLista[]
  >([]);

  const idCliente = formPrincipal.current?.getFieldValue('idCliente');

  const handleVerificarPagamentosPendentes = useCallback(
    async (
      lista?: IFrenteCaixaPdvFinalizarVendaPagamentoLista[]
    ): Promise<void> => {
      let pagamentoCancelado = false;
      for (let i = 0; i < listaItemPagamentoRef.current.length; i++) {
        const itemPagamentoRef =
          listaItemPagamentoRef.current[i]?.handleObterValores();
        const itemPagamento = lista ? lista[i] : listaItemPagamento[i];

        if (
          itemPagamentoRef &&
          itemPagamento &&
          itemPagamentoRef.situacaoPagamento &&
          itemPagamentoRef.situacaoPagamento ===
            StatusTefEnum.aguardandoPagamento &&
          itemPagamentoRef.idPagamentoTef
        ) {
          const dataHoraEmissao =
            formPrincipal.current?.getFieldValue('dataHoraEmissao');
          const terminalCaixa =
            formPrincipal.current?.getFieldValue('terminalCaixa');
          const usuarioAbertura =
            formPrincipal.current?.getFieldValue('usuarioAbertura');

          const responseContinuarSiTef = await continuarPagamentoSiTef({
            idTerminalCaixa: terminalCaixa.id,
            valorTotal: itemPagamentoRef.valor,
            numeroVenda: itemPagamentoRef.idPagamentoTef,
            dataHoraEmissao,
            operador: usuarioAbertura.nome,
            cancelar: true,
          });

          if (responseContinuarSiTef) {
            await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
              params: {
                idPagamentoTef: itemPagamentoRef.idPagamentoTef,
              },
            });

            itemPagamento.idPagamentoTef = undefined;
            itemPagamento.idTef = undefined;
            itemPagamento.situacaoPagamento = undefined;

            pagamentoCancelado = true;
          }
        }
      }

      if (pagamentoCancelado) {
        ToastInfo(
          'Os Pagamentos Tef que estava "Aguardando Pagamento" foram cancelados!'
        );
      }

      refresh();
    },
    [continuarPagamentoSiTef, formPrincipal, listaItemPagamento, refresh]
  );

  const handleClickFinalizarPagamentoInstantaneo = useCallback(
    async (
      index: number,
      idNotaFiscal?: string
    ): Promise<{ idNotaFiscal: string | undefined; finalizado: boolean }> => {
      try {
        setLoading(true);

        const terminalCaixa =
          formPrincipal.current?.getFieldValue('terminalCaixa');
        const usuarioAbertura =
          formPrincipal.current?.getFieldValue('usuarioAbertura');

        const data = formRef.current?.getData() as any;

        const listaMensagemErro: string[] = [];
        const listaFormaPagamentoDinheiro = listaItemPagamento.filter(
          (e) => e.formaPagamento.meioPagamento === MeioPagamentoEnum.dinheiro
        );

        const formaPagamentoDinheiro = listaFormaPagamentoDinheiro.reduce(
          (
            acumulador: number,
            item: IFrenteCaixaPdvFinalizarVendaPagamentoLista
          ) => {
            acumulador += Number(item.valor);
            return acumulador;
          },
          0
        );

        if (
          Number(formaPagamentoDinheiro).Arredondar() <
          Number(data.valorTroco).Arredondar()
        ) {
          listaMensagemErro.push(
            'O Troco é maior que o valor pago em Dinheiro!'
          );
        }

        if (listaMensagemErro.length > 0) {
          TratarErros({ listaMensagem: listaMensagemErro });
          setLoading(false);
          return {
            idNotaFiscal: undefined,
            finalizado: false,
          };
        }

        const listaValor: IFrenteCaixaPdvFinalizarVendaPagamentoLista[] = [];

        const itemPagamento = listaItemPagamento[index];
        const itemPagamentoRef = listaItemPagamentoRef.current[index];
        if (itemPagamentoRef) {
          const valoresItem = itemPagamentoRef.handleObterValores();

          if (valoresItem.valor > 0) {
            let titulo = '';
            switch (valoresItem.formaPagamento.meioPagamento) {
              case MeioPagamentoEnum.cartaoCredito:
                titulo = 'Parcelas do Cartão Crédito';
                break;
              case MeioPagamentoEnum.cartaoDebito:
                titulo = 'Parcelas do Cartão Débito';
                break;
              case MeioPagamentoEnum.valeAlimentaca:
                titulo = 'Parcelas do Vale Alimentação';
                break;
              case MeioPagamentoEnum.valeRefeicao:
                titulo = 'Parcelas do Vale Refeição';
                break;
              case MeioPagamentoEnum.valeCombustivel:
                titulo = 'Parcelas do Vale Combustível';
                break;
              case MeioPagamentoEnum.pagamentoInstantaneoPix:
                titulo = 'Parcelas do PIX';
                break;
            }

            setTitulo(titulo);
            await abrirParcelas();
            const retorno = await carregarDados(valoresItem);

            if (!retorno) {
              setLoading(false);
              return {
                idNotaFiscal: undefined,
                finalizado: false,
              };
            }

            valoresItem.condicaoPagamento = retorno.pagamento.condicaoPagamento;
            valoresItem.portador = retorno.pagamento.portador;
            valoresItem.quantidadeParcelas =
              retorno.pagamento.quantidadeParcelas;
            itemPagamento.quantidadeParcelas =
              retorno.pagamento.quantidadeParcelas;
            valoresItem.listaParcelas = retorno.pagamento.listaParcelas;
            valoresItem.autorizacao = retorno.pagamento.autorizacao;
            itemPagamento.autorizacao = retorno.pagamento.autorizacao;

            listaValor.push(valoresItem);
          }
        }

        const listaContaAdiantamento = [];
        for (let i = 0; i < data.idContaAdiantamento.listaValor.length; i++) {
          const contaAtual = data.idContaAdiantamento.listaValor[i];
          listaContaAdiantamento.push({
            idContaAdiantamento: contaAtual.id,
            valorValePresenteAdiantamento:
              contaAtual.valorDisponivelAdiantamento,
          });
        }

        const dataPrincipal = formPrincipal.current?.getData() as any;

        const dados: IFrenteCaixaPdvFinalizarVendaValoresInserir = {
          ...data,
          ...dataPrincipal,
          listaItem,
          listaPagamento: listaValor,
          listaContaAdiantamento,
          gerarNotaFiscalSaida,
        };

        if (idNotaFiscalSaida) dados.idNotaFiscalSaida = idNotaFiscalSaida;
        else if (idNotaFiscal) dados.idNotaFiscalSaida = idNotaFiscal;

        let response;
        if (idPreVenda && !idNotaFiscalSaida) {
          dados.idPedidoVenda = idPreVenda;

          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoPreVendaComunicador.store(
              { params: dados }
            );
        } else if (listaIdItemConsignado && !idNotaFiscalSaida) {
          response =
            await FrenteCaixaPdvGerarNotaFiscalSaidaImportacaoRetiradaConsignadoComunicador.store(
              { params: dados }
            );
        } else {
          response = await FrenteCaixaPdvGerarNotaFiscalSaidaComunicador.store({
            params: dados,
          });
        }

        idNotaFiscal = response.id;
        setIdNotaFiscalSaida(response.id);

        await handleCarregarDadosPrincipal();

        // #region Controle TEF

        if (possuiTerminalCaixaComIntegracaoTef) {
          itemPagamento.idPagamentoTef = response.idPagamentoTef;
          itemPagamento.situacaoPagamento = StatusTefEnum.aguardandoPagamento;

          if (!itemPagamento.autorizacao) {
            const responsePagamentoSiTef = await realizarPagamentoSiTef({
              idTerminalCaixa: terminalCaixa.id,
              meioPagamento: itemPagamento.formaPagamento.meioPagamento,
              valorTotal: itemPagamento.valor,
              numeroVenda: response.idPagamentoTef,
              dataHoraEmissao: response.registro.dataHoraEmissao,
              operador: usuarioAbertura.nome,
              quantidadeParcelas: itemPagamento.quantidadeParcelas,
              diasCredito: 1,
            });

            if (responsePagamentoSiTef) {
              const comprovanteCliente = responsePagamentoSiTef.find(
                (e) => e.fieldId === 121
              );
              const comprovanteEstabelecimento = responsePagamentoSiTef.find(
                (e) => e.fieldId === 122
              );
              const autorizacao = responsePagamentoSiTef.find(
                (e) => e.fieldId === 2620
              );
              const bandeira = responsePagamentoSiTef.find(
                (e) => e.fieldId === 951
              );
              const nsuHost = responsePagamentoSiTef.find(
                (e) => e.fieldId === 134
              );

              const responseConfirmarPagamento =
                await FrenteCaixaPdvConfirmarPagamentoSiTefComunicador.update({
                  params: {
                    idPagamentoTef: itemPagamento.idPagamentoTef,
                    idTef: nsuHost?.data,
                    comprovanteCliente: comprovanteCliente?.data,
                    comprovanteEstabelecimento:
                      comprovanteEstabelecimento?.data,
                    autorizacao: autorizacao?.data,
                    bandeira: bandeira?.data,
                  },
                });

              itemPagamento.idTef = nsuHost?.data;
              itemPagamento.situacaoPagamento = StatusTefEnum.pago;

              if (responseConfirmarPagamento.bandeiraCartao) {
                itemPagamento.bandeiraCartao =
                  responseConfirmarPagamento.bandeiraCartao;
              }
            } else {
              await FrenteCaixaPdvCancelarPagamentoSiTefComunicador.update({
                params: {
                  idPagamentoTef: itemPagamento.idPagamentoTef,
                },
              });

              itemPagamento.situacaoPagamento = undefined;

              ToastErro('Pagamento não realizado!');
            }
          } else {
            await FrenteCaixaPdvConfirmarPagamentoSiTefComunicador.update({
              params: {
                idPagamentoTef: itemPagamento.idPagamentoTef,
                autorizacao: itemPagamento.autorizacao,
              },
            });

            itemPagamento.situacaoPagamento = StatusTefEnum.pago;
          }
        }

        // #endregion Controle TEF

        setLoading(false);

        return {
          idNotaFiscal,
          finalizado: false,
        };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return {
          idNotaFiscal: undefined,
          finalizado: false,
        };
      }
    },
    [
      abrirParcelas,
      carregarDados,
      formPrincipal,
      gerarNotaFiscalSaida,
      handleCarregarDadosPrincipal,
      idNotaFiscalSaida,
      idPreVenda,
      listaIdItemConsignado,
      listaItem,
      listaItemPagamento,
      possuiTerminalCaixaComIntegracaoTef,
      realizarPagamentoSiTef,
      setIdNotaFiscalSaida,
      setLoading,
      setTitulo,
    ]
  );

  const handleClickFinalizar = useCallback(async (): Promise<void> => {
    await handleVerificarPagamentosPendentes();

    const data = formRef.current?.getData() as any;

    const listaMensagemErro: string[] = [];
    if (
      Number(data.valorTotalPago).Arredondar(2) <
      Number(data.valorTotalPagar).Arredondar(2)
    ) {
      listaMensagemErro.push(
        'O Valor Pago deve ser igual ou maior que o Valor a Pagar!'
      );
    }

    const listaFormaPagamentoDinheiro = listaItemPagamento.filter(
      (e) => e.formaPagamento.meioPagamento === MeioPagamentoEnum.dinheiro
    );

    const formaPagamentoDinheiro = listaFormaPagamentoDinheiro.reduce(
      (
        acumulador: number,
        item: IFrenteCaixaPdvFinalizarVendaPagamentoLista
      ) => {
        acumulador += Number(item.valor);
        return acumulador;
      },
      0
    );

    if (
      Number(formaPagamentoDinheiro).Arredondar() <
      Number(data.valorTroco).Arredondar()
    ) {
      listaMensagemErro.push('O Troco é maior que o valor pago em Dinheiro!');
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return;
    }

    const listaItemPagamentoComValor: Array<{
      valoresItem: IFrenteCaixaPdvFinalizarVendaPagamentoLista;
      index: number;
    }> = [];

    for (let i = 0; i < listaItemPagamentoRef.current.length; i++) {
      const itemPagamentoRef = listaItemPagamentoRef.current[i];

      if (itemPagamentoRef) {
        const valoresItem = itemPagamentoRef.handleObterValores();

        if (valoresItem.valor > 0) {
          listaItemPagamentoComValor.push({
            valoresItem,
            index: i,
          });
        }
      }
    }

    let idNotaFiscal: string | undefined;
    const listaValor: IFrenteCaixaPdvFinalizarVendaPagamentoLista[] = [];

    for (let i = 0; i < listaItemPagamentoComValor.length; i++) {
      const { valoresItem, index } = listaItemPagamentoComValor[i];

      if (valoresItem.valor > 0) {
        if (
          possuiTerminalCaixaComIntegracaoTef &&
          IsPagamentoTef(
            valoresItem.formaPagamento.meioPagamento,
            valoresItem.formaPagamento.pagamentoIntegradoTef
          ) &&
          (!valoresItem.situacaoPagamento ||
            (valoresItem.situacaoPagamento !== StatusTefEnum.pago &&
              valoresItem.situacaoPagamento !== StatusTefEnum.cancelado))
        ) {
          const retornoPagamentoInstantaneo =
            await handleClickFinalizarPagamentoInstantaneo(index, idNotaFiscal);

          idNotaFiscal = retornoPagamentoInstantaneo.idNotaFiscal;
        } else if (
          !IsPagamentoTef(
            valoresItem.formaPagamento.meioPagamento,
            valoresItem.formaPagamento.pagamentoIntegradoTef
          ) ||
          !possuiTerminalCaixaComIntegracaoTef
        ) {
          let titulo = '';
          switch (valoresItem.formaPagamento.meioPagamento) {
            case MeioPagamentoEnum.dinheiro:
              titulo = 'Parcelas do Dinheiro';
              break;
            case MeioPagamentoEnum.cheque:
              titulo = 'Parcelas do Cheque';
              break;
            case MeioPagamentoEnum.cartaoCredito:
              titulo = 'Parcelas do Cartão de Crédito';
              break;
            case MeioPagamentoEnum.cartaoDebito:
              titulo = 'Parcelas do Cartão de Débito';
              break;
            case MeioPagamentoEnum.creditoLoja:
              titulo = 'Parcelas do Crédiario';
              break;
            case MeioPagamentoEnum.valeAlimentaca:
              titulo = 'Parcelas do Vale Alimentação';
              break;
            case MeioPagamentoEnum.valeRefeicao:
              titulo = 'Parcelas do Vale Refeição';
              break;
            case MeioPagamentoEnum.valePresente:
              titulo = 'Parcelas do Vale Presente';
              break;
            case MeioPagamentoEnum.valeCombustivel:
              titulo = 'Parcelas do Vale Combustível';
              break;
            case MeioPagamentoEnum.boletoBancario:
              titulo = 'Parcelas dos Boletos';
              break;
            case MeioPagamentoEnum.depositoBancario:
              titulo = 'Parcelas do Depósito Bancário';
              break;
            case MeioPagamentoEnum.pagamentoInstantaneoPix:
              titulo = 'Parcelas do PIX';
              break;
            case MeioPagamentoEnum.transferenciaBancariaCarteiraDigital:
              titulo = 'Parcelas da Transferência Bancária';
              break;
            case MeioPagamentoEnum.programaFidelidadeCashbackCreditoVirtual:
              titulo = 'Parcelas do Cashback';
              break;
            case MeioPagamentoEnum.semPagamento:
              titulo = 'Parcelas Sem Pagamento';
              break;
            case MeioPagamentoEnum.outro:
              titulo = 'Parcelas do Outros';
              break;
          }

          setTitulo(titulo);
          await abrirParcelas();
          const retorno = await carregarDados(valoresItem);

          if (!retorno) {
            return;
          }

          valoresItem.autorizacao = retorno.pagamento.autorizacao;
          valoresItem.condicaoPagamento = retorno.pagamento.condicaoPagamento;
          valoresItem.portador = retorno.pagamento.portador;
          valoresItem.quantidadeParcelas = retorno.pagamento.quantidadeParcelas;
          valoresItem.listaParcelas = retorno.pagamento.listaParcelas;

          listaValor.push(valoresItem);
        }
      }
    }

    const listaContaAdiantamento = [];
    for (let i = 0; i < data.idContaAdiantamento.listaValor.length; i++) {
      const contaAtual = data.idContaAdiantamento.listaValor[i];
      listaContaAdiantamento.push({
        idContaAdiantamento: contaAtual.id,
        valorValePresenteAdiantamento: contaAtual.valorDisponivelAdiantamento,
      });
    }

    const dados: IFrenteCaixaPdvFinalizarVendaValoresInserir = {
      ...data,
      listaPagamento: listaValor,
      listaContaAdiantamento,
    };

    const valorTotalPagar = Number(
      formRef.current?.getFieldValue('valorTotalPagar')
    ).Arredondar();
    const valorTotalPago = Number(
      formRef.current?.getFieldValue('valorTotalPago')
    ).Arredondar();

    const possuiPagamentoPendente = !possuiTerminalCaixaComIntegracaoTef
      ? false
      : listaItemPagamento.some(
          (e) =>
            e.valor > 0 &&
            (e.situacaoPagamento === StatusTefEnum.pendente ||
              e.situacaoPagamento === StatusTefEnum.aguardandoPagamento ||
              e.situacaoPagamento === StatusTefEnum.cancelamentoIniciado ||
              e.situacaoPagamento === StatusTefEnum.emCancelamento ||
              (!e.situacaoPagamento &&
                IsPagamentoTef(
                  e.formaPagamento.meioPagamento,
                  e.formaPagamento.pagamentoIntegradoTef
                )))
        );

    if (
      Number(valorTotalPago) >= Number(valorTotalPagar) &&
      !possuiPagamentoPendente
    ) {
      if (onFinalizarFormModal) onFinalizarFormModal(dados, idNotaFiscal);
    }
  }, [
    abrirParcelas,
    carregarDados,
    handleClickFinalizarPagamentoInstantaneo,
    handleVerificarPagamentosPendentes,
    listaItemPagamento,
    onFinalizarFormModal,
    possuiTerminalCaixaComIntegracaoTef,
    setTitulo,
  ]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (idNotaFiscalSaida) {
      await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            A Nota Fiscal de Saída ja foi gerada, não é mais possivel voltar!
          </span>
        ),
        confimar: 'Ok',
      });

      return;
    }

    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [
    abrirJanela,
    abrirRecuperarFormulario,
    idNotaFiscalSaida,
    onFecharFormModal,
  ]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const listaPagamentoSalvo = formPrincipal.current?.getFieldValue(
        'listaPagamentoSalvo'
      );

      const valorTotal =
        formPrincipal.current?.getFieldValue('valorTotalGeral');

      const response = await FormaPagamentoFrenteCaixaPdvComunicador.index();

      const lista: IFrenteCaixaPdvFinalizarVendaPagamentoLista[] = [];
      for (let i = 0; i < response.length; i++) {
        const formaPagamento = response[i];

        const listaPagamento = listaPagamentoSalvo?.filter(
          (e: any) => e.formaPagamento.id === formaPagamento.id
        );

        if (listaPagamento && listaPagamento.length > 0) {
          for (let j = 0; j < listaPagamento.length; j++) {
            lista.push(listaPagamento[j]);
          }

          continue;
        }

        const data = new Date();
        let dataPrimeiraParcela = data;
        if (
          formaPagamento.meioPagamento === MeioPagamentoEnum.boletoBancario ||
          formaPagamento.meioPagamento === MeioPagamentoEnum.creditoLoja ||
          formaPagamento.meioPagamento === MeioPagamentoEnum.cartaoCredito
        ) {
          dataPrimeiraParcela = dataPrimeiraParcela.AddDays(30);
        }

        const itemPagamento: IFrenteCaixaPdvFinalizarVendaPagamentoLista = {
          formaPagamento,
          dataHoraEmissao: data,
          valor: 0,
          quantidadeParcelas: 0,
          dataPrimeiraParcela,
          valorPrimeiraParcela: 0,
          listaParcelas: [],
        };

        lista.push(itemPagamento);
      }

      setListaItemPagamento(lista);

      const valorTotalPago = lista.reduce((acumulador: number, item: any) => {
        acumulador += Number(item.valor);
        return acumulador;
      }, 0);

      formRef.current?.setDataInicial({
        valorTotal,
        valorTotalPagar: valorTotal,
        valorTotalPago,
        valorTroco: 0,
      });

      refresh();
      await Sleep(100);
      await handleVerificarPagamentosPendentes(lista);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formPrincipal, handleVerificarPagamentosPendentes, refresh, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAtualizarValores = useCallback(async () => {
    const valorTotalPagar = Number(
      formRef.current?.getFieldValue('valorTotalPagar')
    );

    let valorPago = 0;
    for (let index = 0; index < listaItemPagamentoRef.current.length; index++) {
      const itemPagamentoRef = listaItemPagamentoRef.current[index];

      const valoresItem = itemPagamentoRef?.handleObterValores();
      valorPago += Number(valoresItem?.valor);
    }

    formRef.current?.setFieldValue('valorTotalPago', valorPago);

    if (valorPago > valorTotalPagar) {
      const troco = valorPago - valorTotalPagar;
      formRef.current?.setFieldValue('valorTroco', troco);
    } else {
      formRef.current?.setFieldValue('valorTroco', 0);
    }
  }, []);

  const handleAdicionarFormaPagamento = useCallback(async (): Promise<void> => {
    const formaPagamento =
      formRef.current?.getFieldValueNomeObjeto('formaPagamento');

    const data = new Date();
    let dataPrimeiraParcela = data;
    if (
      formaPagamento.meioPagamento === MeioPagamentoEnum.boletoBancario ||
      formaPagamento.meioPagamento === MeioPagamentoEnum.creditoLoja ||
      formaPagamento.meioPagamento === MeioPagamentoEnum.cartaoCredito
    ) {
      dataPrimeiraParcela = dataPrimeiraParcela.AddDays(30);
    }

    const itemPagamento: IFrenteCaixaPdvFinalizarVendaPagamentoLista = {
      formaPagamento,
      dataHoraEmissao: data,
      valor: 0,
      quantidadeParcelas: 0,
      dataPrimeiraParcela,
      valorPrimeiraParcela: 0,
      listaParcelas: [],
    };

    setListaItemPagamento([...listaItemPagamento, itemPagamento]);

    formRef.current?.clearField('formaPagamento');
  }, [listaItemPagamento]);

  const handleSugerirValorPago = useCallback((item: any) => {
    const valorTotalPagar = formRef.current?.getFieldValue('valorTotalPagar');
    const valorTotalPago =
      Number(formRef.current?.getFieldValue('valorTotalPago')) -
      Number(item.valor);

    const diferencaValor = Number(valorTotalPagar) - Number(valorTotalPago);
    const somaValorTotalPagoDiferenca =
      Number(diferencaValor) + Number(valorTotalPago);

    if (diferencaValor > 0) {
      item.valor = Number(diferencaValor);
    }

    formRef.current?.setFieldValue(
      'valorTotalPago',
      somaValorTotalPagoDiferenca
    );
  }, []);

  return (
    <JanelaDetalhe
      titulo="Finalizar Venda"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body style={{ minHeight: 600 }}>
          <Divisor>
            <Row>
              <Col lg={8} md={6} sm={12}>
                <InputAutoCompleteTagContaTipoAdiantamentoEnum
                  label="Adiantamento"
                  placeholder="Adiantamento"
                  name="idContaAdiantamento"
                  nomeObjeto="contaAdiantamento"
                  listaTipoContaAdiantamento={[TipoContaEnum.receber]}
                  idPessoa={idCliente ?? undefined}
                  onChangeListaItemAtual={async (event) => {
                    let valorDisponivelAdiantamento = 0;
                    event.listaItemAtual.forEach((item) => {
                      valorDisponivelAdiantamento += Number(
                        item.valorDisponivelAdiantamento ??
                          item.valor.valorDisponivelAdiantamento
                      );
                    });

                    let valorTotalPagar = 0;
                    const valorTotalGeral = Number(
                      formPrincipal.current?.getFieldValue('valorTotalGeral')
                    );

                    if (valorDisponivelAdiantamento < valorTotalGeral) {
                      valorTotalPagar =
                        valorTotalGeral - valorDisponivelAdiantamento;
                    } else {
                      valorDisponivelAdiantamento = valorTotalGeral;
                    }

                    formRef.current?.setFieldValue(
                      'valorTotalPagar',
                      valorTotalPagar
                    );

                    formRef.current?.setFieldValue(
                      'valorValePresenteAdiantamento',
                      valorDisponivelAdiantamento
                    );

                    await handleAtualizarValores();
                  }}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <InputDecimal
                  label="Valor Vale Pres. / Adiant."
                  name="valorValePresenteAdiantamento"
                  placeholder="Valor Vale Pres. / Adiant."
                  casasDecimais={2}
                  disabled
                />
              </Col>
            </Row>
          </Divisor>

          <Divisor>
            <Row>
              <InputHiddenHtml name="valorTotal" />

              <Col
                lg={9}
                md={12}
                sm={12}
                style={{ overflow: 'auto', height: 310, marginBottom: 10 }}
              >
                <Tabela>
                  <Table
                    striped
                    hover
                    bordered
                    style={{ border: 'transparent' }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="lista-texto"
                          style={{
                            width: '40%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Forma Pagamento</span>
                        </th>
                        <th
                          className="lista-valor"
                          style={{
                            width: '20%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Valor</span>
                        </th>
                        <th
                          className="lista-valor"
                          style={{
                            width: '10%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Parcelas</span>
                        </th>
                        <th
                          className="lista-texto"
                          style={{
                            width: '30%',
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Bandeira</span>
                        </th>

                        <th
                          className="lista-acoes"
                          style={{
                            textAlign: 'start',
                            backgroundColor: 'white',
                          }}
                        >
                          <span>Ações</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaItemPagamento?.map((item, index) => {
                        return (
                          <ItemPagamento
                            index={index}
                            item={item}
                            ref={(instance) => {
                              listaItemPagamentoRef.current[index] = instance;
                            }}
                            onKeyDownValor={(e) => {
                              if (e.shiftKey && e.key === 'Tab') {
                                e.preventDefault();

                                let proximaLinha = index - 1;
                                if (proximaLinha < 0)
                                  proximaLinha = listaItemPagamento.length - 1;

                                listaItemPagamentoRef.current[
                                  proximaLinha
                                ]?.handleSetFocusValor();
                              } else if (e.key === 'Enter' || e.key === 'Tab') {
                                e.preventDefault();

                                let proximaLinha = index + 1;
                                if (proximaLinha >= listaItemPagamento.length)
                                  proximaLinha = 0;

                                listaItemPagamentoRef.current[
                                  proximaLinha
                                ]?.handleSetFocusValor();
                              }
                            }}
                            onBlurValor={async (value) => {
                              if (
                                possuiTerminalCaixaComIntegracaoTef &&
                                value > 0 &&
                                IsPagamentoTef(
                                  item.formaPagamento.meioPagamento,
                                  item.formaPagamento.pagamentoIntegradoTef
                                )
                              ) {
                                await handleVerificarPagamentosPendentes();

                                handleClickFinalizarPagamentoInstantaneo(index);
                              }
                            }}
                            onChange={() => {
                              handleAtualizarValores();
                            }}
                            onClick={() => {
                              handleSugerirValorPago(item);
                            }}
                            loading={loading}
                            setLoading={setLoading}
                            refresh={refresh}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </Tabela>
              </Col>

              <Col
                lg={3}
                md={12}
                sm={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      backgroundColor: 'lightgray',
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <InputLabel
                      label="Valor a Pagar"
                      name="valorTotalPagar"
                      labelStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                      formatarValor={(value) => {
                        return (
                          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                            {`R$ ${Number(value).FormatarParaPtBr()}`}
                          </div>
                        );
                      }}
                      valorStyle={{ justifyContent: 'flex-end' }}
                    />
                  </div>

                  <div
                    style={{
                      backgroundColor: 'lightgray',
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <InputLabel
                      label="Valor Pago"
                      name="valorTotalPago"
                      labelStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                      formatarValor={(value) => {
                        return (
                          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                            {`R$ ${Number(value).FormatarParaPtBr()}`}
                          </div>
                        );
                      }}
                      valorStyle={{ justifyContent: 'flex-end' }}
                    />
                  </div>

                  <div
                    style={{
                      backgroundColor: 'lightgray',
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <InputLabel
                      label="Troco"
                      name="valorTroco"
                      labelStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                      formatarValor={(value) => {
                        return (
                          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                            {`R$ ${Number(value).FormatarParaPtBr()}`}
                          </div>
                        );
                      }}
                      valorStyle={{ justifyContent: 'flex-end' }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Divisor>

          <Divisor>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <InputAutoCompleteFormaPagamento
                  label="Forma Pagamento"
                  name="idFormaPagamento"
                  nomeObjeto="formaPagamento"
                  placeholder="Forma Pagamento"
                  onChangeItemAtual={() => {
                    refresh();
                  }}
                />
              </Col>
              <Col
                lg={2}
                md={3}
                sm={12}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <BtnPadraoButton
                  type="button"
                  className="btn-padrao btn-verde btn-adicionar"
                  onClick={handleAdicionarFormaPagamento}
                  disabled={
                    loading ||
                    !formRef.current?.getFieldValue('idFormaPagamento')
                  }
                >
                  <TextoLoading loading={loading}>
                    <IoMdAddCircleOutline />
                    <span style={{ marginLeft: 10 }}>Adicionar</span>
                  </TextoLoading>
                </BtnPadraoButton>
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickFinalizar}
              >
                <TextoLoading loading={loading}>
                  <BiSave />
                  <span style={{ marginLeft: 10 }}>Finalizar</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default FinalizarVenda;
