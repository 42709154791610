import api from '../../../../Comum/Services/Api';

const rota = 'financeiro/boleto/pedido-baixa';

interface IBoletoPedidoBaixaComunicadorUpdate {
  params: { idBoleto: string };
}

class BoletoPedidoBaixaComunicador {
  public async update(
    params: IBoletoPedidoBaixaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new BoletoPedidoBaixaComunicador();
