import api from '../../../../../Comum/Services/Api';

const rota =
  '/fiscal/documentos-eletronicos/geracao-de-arquivo-contabeis/nfe-emitidas-download-zip';

interface IGeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicadorShow {
  params: {
    dataEmissaoInicial: Date;
    dataEmissaoFinal: Date;
    xmlNfePropriaEmitidas: boolean;
    danfeNfePropriaPdf: boolean;
    incluirNfePropriaCanceladas: boolean;
  };
}

class GeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicador {
  public async show(
    params: IGeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, {
      params: params.params,
      responseType: 'blob',
    });

    return response.data;
  }
}

export default new GeracaoArquivosContabeisNfeEmitidasBaixarCompactadoComunicador();
