import api from '../../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/documento-referenciado';
const rotaNovo = 'transporte/manifesto-transporte';

interface IManifestoTransporteDocumentoReferenciadoComunicadorShow {
  id: string;
}

interface IManifestoTransporteDocumentoReferenciadoComunicadorStore {
  idPai: string;
  params: any;
}

interface IManifestoTransporteDocumentoReferenciadoComunicadorUpdate {
  id: string;
  params: any;
}

interface IManifestoTransporteDocumentoReferenciadoComunicadorDelete {
  id: string;
}

class ManifestoTransporteDocumentoReferenciadoComunicador {
  public async show(
    params: IManifestoTransporteDocumentoReferenciadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IManifestoTransporteDocumentoReferenciadoComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/documento-referenciado`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IManifestoTransporteDocumentoReferenciadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IManifestoTransporteDocumentoReferenciadoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteDocumentoReferenciadoComunicador();
