import { IContaEmailValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import InputEmail from '../../../../../Componentes/Inputs/InputEmail';
import InputCheckbox from '../../../../../Componentes/Inputs/InputCheckbox';
import { UseContaEmailVerificacao } from '../Hooks/ContaEmailVerificacaoHook';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';

type IProps = IMain<IContaEmailValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoContaDeEmail: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const { emailInicial, verificadoInicial } = UseContaEmailVerificacao();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  function handleValidarAlteracao(): void {
    const emailAtual = formRef.current?.getFieldValue('email');
    const verificadoAtual = formRef.current?.getFieldValue('verificado');

    if (idDetalheRegistro && emailAtual !== emailInicial) {
      formRef.current?.setFieldValue('verificado', false);
    }

    if (
      idDetalheRegistro &&
      emailAtual === emailInicial &&
      !verificadoAtual &&
      verificadoInicial
    ) {
      formRef.current?.setFieldValue('verificado', true);
    }
  }

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row style={{ width: '100%', marginTop: 10 }}>
            <Col lg={6} md={12} sm={12}>
              <InputEmail
                ref={inputRefFocus}
                label="E-mail"
                name="email"
                maxLength={60}
                onBlur={() => {
                  handleValidarAlteracao();
                }}
              />
            </Col>
            <Col lg={4} md={6} sm={12} style={{ paddingLeft: 50 }}>
              <InputLabel
                name="verificado"
                label="Verificado"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(verificado) => {
                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      <span style={{ color: verificado ? 'green' : 'red' }}>
                        {verificado ? 'Sim' : 'Não'}
                      </span>
                    </div>
                  );
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputSwitch name="ativo" label="Ativo" />
            </Col>
            <Col />
          </Row>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Documentos Fiscais"
                  name="direcionarDocumentosFiscais"
                />
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Pedido de Venda"
                  name="direcionarPedidoVenda"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox label="Boletos" name="direcionarBoletos" />
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Cotação de Compra"
                  name="direcionarCotacaoCompra"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox label="Orçamento" name="direcionarOrcamento" />
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Pedido de Compra"
                  name="direcionarPedidoCompra"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} sm={12}>
              <div className="checkbox-container">
                <InputCheckbox
                  label="Ordem de Serviço (Veículos)"
                  name="direcionarOrdemServico"
                />
              </div>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
