import {
  FormatarDataParaPtBr,
  INotaFiscalEntradaPedidosComprasVinculadosLista,
} from '@elogestor/util';
import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import NotaFiscalEntradaObterPedidosComprasVinculadosComunicador from '../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterPedidosComprasVinculadosComunicador';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';

interface PedidosComprasVinculadosLista {
  onFecharFormModal(): void;
  setLoading(loading: boolean): void;
  loading: boolean;
}

const PedidosComprasVinculadosLista: React.FC<
  PedidosComprasVinculadosLista
> = ({ onFecharFormModal, loading, setLoading }) => {
  const formPrincipal = UseForm();

  const [listaValor, setListaValor] = useState<
    INotaFiscalEntradaPedidosComprasVinculadosLista[]
  >([]);

  const handleCarregarDados = useCallback(async () => {
    try {
      setLoading(true);

      const listaNotaFiscalEntradaItem =
        formPrincipal.formRef.current?.getFieldValue(
          'listaNotaFiscalEntradaItem'
        );

      const listaIdNotaFiscalEntradaItem = listaNotaFiscalEntradaItem.map(
        (item: any) => item.id
      );

      const response =
        await NotaFiscalEntradaObterPedidosComprasVinculadosComunicador.show({
          params: listaIdNotaFiscalEntradaItem,
        });

      setListaValor(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [formPrincipal.formRef, setLoading]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Pedidos de Compra Vinculados"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Divisor>
          <div style={{ overflow: 'auto', marginBottom: 10 }}>
            <Tabela>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th
                      className="lista-valor"
                      style={{
                        width: '5%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Número</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '5%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Ordem</span>
                    </th>
                    <th
                      className="lista-data"
                      style={{
                        width: '15%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Data Hora Emissão</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Código</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '25%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Descrição</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Qtde Utilizada</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Unid.</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaValor?.map((item, index) => {
                    return (
                      <>
                        <tr key={v4()}>
                          <td className="lista-valor">
                            {item.pedidoCompraItem.pedidoCompra.numero}
                          </td>
                          <td className="lista-valor">
                            {item.pedidoCompraItem.ordem}
                          </td>
                          <td className="lista-data">
                            {FormatarDataParaPtBr(
                              item.pedidoCompraItem.pedidoCompra.dataHoraEmissao
                            )}
                          </td>
                          <td className="lista-valor">
                            {item.pedidoCompraItem.produto.codigo}
                          </td>
                          <td className="lista-texto">
                            {item.pedidoCompraItem.produto.descricao}
                          </td>
                          <td className="lista-valor">
                            {Number(item.quantidade).FormatarParaPtBr({
                              maximoCasasDecimais: 4,
                              minimoCasasDecimais: 4,
                            })}
                          </td>

                          <td className="lista-texto">
                            {item.pedidoCompraItem.unidadeMedida.sigla}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </Tabela>
          </div>
        </Divisor>
      </Modal.Body>
    </JanelaDetalhe>
  );
};

export default PedidosComprasVinculadosLista;
