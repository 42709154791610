import api from '../../../../Comum/Services/Api';

const rota = 'transporte/manifesto-transporte/calcular-peso-bruto-total';

interface IManifestoTransporteCalcularPesoBrutoTotalComunicadorShow {
  id: string;
}

class ManifestoTransporteCalcularPesoBrutoTotalComunicador {
  public async show(
    params: IManifestoTransporteCalcularPesoBrutoTotalComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ManifestoTransporteCalcularPesoBrutoTotalComunicador();
