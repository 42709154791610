/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  Image,
} from '@react-pdf/renderer';
import {
  ConverterMilimitroParaPixel,
  ConverterMilimitroParaPonto,
  FormatarCep,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraParaPtBr,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  FormatarTelefone,
  IEtiquetaElementoImpressao,
  IEtiquetaImpressao,
  TipoDataHoraEnum,
  TipoElementoEnum,
  TipoPapelEnum,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';

const Document: any = _document;
const Page: any = _page;

interface IEtiquetaImpressaoPDF {
  dados: IEtiquetaImpressao;
  visualizarImpressao?: boolean;
}

const EtiquetaImpressaoPDF: React.FC<IEtiquetaImpressaoPDF> = ({
  dados,
  visualizarImpressao = false,
}) => {
  let etiquetaColuna = 0;
  let larguraEntreEtiquetas = 0;
  let alturaEntreEtiquetas = 0;

  let larguraTotal = 0;
  let alturaTotal = 0;

  const larguraEtiqueta = ConverterMilimitroParaPonto(
    dados.larguraEtiqueta
  ).Arredondar(4);
  const alturaEtiqueta = ConverterMilimitroParaPonto(
    dados.alturaEtiqueta
  ).Arredondar(4);

  if (dados.larguraEntreEtiquetas) {
    if (dados.tipoPapel === TipoPapelEnum.a4) {
      larguraEntreEtiquetas = ConverterMilimitroParaPixel(
        dados.larguraEntreEtiquetas
      ).Arredondar(4);
    } else {
      larguraEntreEtiquetas = ConverterMilimitroParaPonto(
        dados.larguraEntreEtiquetas
      ).Arredondar(4);
    }
  }

  if (dados.alturaEntreEtiquetas) {
    if (dados.tipoPapel === TipoPapelEnum.a4) {
      alturaEntreEtiquetas = ConverterMilimitroParaPixel(
        dados.alturaEntreEtiquetas
      ).Arredondar(4);
    } else {
      alturaEntreEtiquetas = ConverterMilimitroParaPonto(
        dados.alturaEntreEtiquetas
      ).Arredondar(4);
    }
  }

  if (dados.tipoPapel === TipoPapelEnum.a4) {
    larguraTotal = ConverterMilimitroParaPonto(dados.larguraTotal).Arredondar(
      4
    );
    alturaTotal = ConverterMilimitroParaPonto(dados.alturaTotal).Arredondar(4);
  } else {
    larguraTotal = ConverterMilimitroParaPonto(dados.larguraTotal).Arredondar(
      4
    );
    alturaTotal = Number(alturaEtiqueta + alturaEntreEtiquetas).Arredondar(4);
  }

  const size = { width: larguraTotal, height: alturaTotal };

  const handleObterElemento = (elemento: IEtiquetaElementoImpressao): any => {
    if (elemento.tipoElemento === TipoElementoEnum.imagem) {
      if (elemento.urlImagem) {
        return (
          <Image
            src={elemento.urlImagem}
            style={{
              width: elemento.largura,
              height: elemento.altura,
              borderRadius: elemento.arredondamentoBorda,
            }}
          />
        );
      }

      return <></>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.cnpjCpf) {
      return <Text>{FormatarCnpjCpf(elemento.texto)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.cep) {
      return <Text>{FormatarCep(elemento.texto)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.chaveAcesso) {
      return <Text>{FormatarChaveAcesso(elemento.texto)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.telefone) {
      return <Text>{FormatarTelefone(elemento.texto)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.numeroInteiro) {
      return <Text>{Number(elemento.texto).Arredondar(0)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.numeroDecimal) {
      return (
        <Text>
          {Number(elemento.texto).FormatarParaPtBr({
            minimoCasasDecimais: Number(elemento.casasDecimais),
            maximoCasasDecimais: Number(elemento.casasDecimais),
          })}
        </Text>
      );
    }

    if (elemento.tipoElemento === TipoElementoEnum.dataHora) {
      if (!elemento.tipoDataHora) {
        return <></>;
      }

      if (elemento.tipoDataHora === TipoDataHoraEnum.dataHora) {
        return <Text>{FormatarDataHoraParaPtBr(elemento.texto)}</Text>;
      }

      if (elemento.tipoDataHora === TipoDataHoraEnum.data) {
        return <Text>{FormatarDataParaPtBr(elemento.texto)}</Text>;
      }

      if (elemento.tipoDataHora === TipoDataHoraEnum.hora) {
        return <Text>{FormatarHoraParaPtBr(elemento.texto)}</Text>;
      }
    }

    if (elemento.tipoElemento === TipoElementoEnum.data) {
      return <Text>{FormatarDataParaPtBr(elemento.texto)}</Text>;
    }

    if (elemento.tipoElemento === TipoElementoEnum.codigoBarras) {
      const canvasBarCode = document.createElement('canvas');

      JsBarcode(canvasBarCode, String(elemento.texto), {
        fontSize: 2,
        format: 'CODE128',
      });
      const barCode = canvasBarCode.toDataURL();

      return (
        <Image
          src={barCode}
          style={{ width: elemento.largura, height: elemento.altura }}
        />
      );
    }

    if (elemento.tipoElemento === TipoElementoEnum.codigoBarrasCodigoProduto) {
      const canvasBarCode = document.createElement('canvas');

      JsBarcode(
        canvasBarCode,
        String(elemento.texto).CompletaCaracteres(
          Number(elemento.numeroDigitos),
          '0',
          false
        ),
        { fontSize: 0, format: 'CODE128' }
      );

      const barCode = canvasBarCode.toDataURL();

      return (
        <Image
          src={barCode}
          style={{ width: elemento.largura, height: elemento.altura }}
        />
      );
    }

    if (
      elemento.tipoElemento === TipoElementoEnum.codigoBarrasInicioCodigoProduto
    ) {
      const canvasBarCode = document.createElement('canvas');

      JsBarcode(
        canvasBarCode,
        String(elemento.texto).CompletaCaracteres(
          Number(elemento.numeroDigitos),
          '0',
          false
        ),
        { fontSize: 0, format: 'CODE128' }
      );

      const barCode = canvasBarCode.toDataURL();

      return (
        <Image
          src={barCode}
          style={{ width: elemento.largura, height: elemento.altura }}
        />
      );
    }

    return <Text>{elemento.texto}</Text>;
  };

  return (
    <Document>
      <Page
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginLeft: dados.margemEsquerda
            ? ConverterMilimitroParaPixel(dados.margemEsquerda)
            : 0,
          marginRight: dados.margemDireita
            ? ConverterMilimitroParaPixel(dados.margemDireita)
            : 0,
          marginTop: dados.margemSuperior
            ? ConverterMilimitroParaPixel(dados.margemSuperior)
            : 0,
          marginBottom: dados.margemInferior
            ? ConverterMilimitroParaPixel(dados.margemInferior)
            : 0,
        }}
        size={size}
        orientation="portrait"
      >
        <>
          {dados.listaEtiqueta &&
            dados.listaEtiqueta.map((etiqueta) => {
              if (etiquetaColuna < dados.quantidadeEtiquetasLinha)
                etiquetaColuna += 1;
              else etiquetaColuna = 1;

              return (
                <>
                  <View
                    style={{
                      width: larguraEtiqueta,
                      height: alturaEtiqueta,
                      border: visualizarImpressao ? '1 solid black' : undefined,
                      borderRadius: visualizarImpressao ? '5' : undefined,
                    }}
                  >
                    <>
                      {etiqueta.listaElemento?.map((elemento) => {
                        return (
                          <View
                            style={{
                              left: elemento.posicaoX,
                              top: elemento.posicaoY,
                              width: elemento.largura,
                              height: elemento.altura,
                              position: 'absolute',

                              display: 'flex',
                              justifyContent: elemento.justificarSuperior
                                ? 'flex-start'
                                : elemento.justificarInferior
                                  ? 'flex-end'
                                  : 'center',
                              textAlign: elemento.alinharEsquerda
                                ? 'left'
                                : elemento.alinharDireita
                                  ? 'right'
                                  : 'center',
                              alignItems: elemento.alinharCentro
                                ? 'center'
                                : elemento.alinharDireita
                                  ? 'flex-end'
                                  : 'flex-start',

                              fontSize: `${elemento.tamanhoFonte}pt`,
                              fontWeight: elemento.negrito ? 'bold' : 'normal',
                              fontStyle: elemento.italico ? 'italic' : 'normal',
                              textDecoration: elemento.sublinhado
                                ? 'underline'
                                : 'none',

                              borderRight: `${elemento.espessuraBordaDireita} solid black`,
                              borderLeft: `${elemento.espessuraBordaEsquerda} solid black`,
                              borderTop: `${elemento.espessuraBordaSuperior} solid black`,
                              borderBottom: `${elemento.espessuraBordaInferior} solid black`,
                              borderRadius: elemento.arredondamentoBorda,

                              backgroundColor: elemento.inverterCores
                                ? 'black'
                                : 'white',
                              color: elemento.inverterCores ? 'white' : 'black',
                              fontFamily: elemento.fonte,
                            }}
                          >
                            {handleObterElemento(elemento)}
                          </View>
                        );
                      })}
                    </>
                  </View>

                  {etiquetaColuna < dados.quantidadeEtiquetasLinha && (
                    <>
                      {larguraEntreEtiquetas > 0 && (
                        <View
                          style={{
                            width: larguraEntreEtiquetas,
                            height: alturaEtiqueta,
                          }}
                        />
                      )}
                    </>
                  )}

                  {etiquetaColuna === dados.quantidadeEtiquetasLinha && (
                    <>
                      {alturaEntreEtiquetas > 0 && (
                        <View
                          style={{
                            width: '100%',
                            height: alturaEntreEtiquetas,
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              );
            })}
        </>
      </Page>
    </Document>
  );
};

export default EtiquetaImpressaoPDF;
