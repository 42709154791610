import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/upload-pdf';

interface INotaFiscalEntradaUploadPdfComunicadorUpdate {
  params: any;
}

class NotaFiscalEntradaUploadPdfComunicador {
  public async update(
    params: INotaFiscalEntradaUploadPdfComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaUploadPdfComunicador();
