import { ValidationError } from 'yup';
import ToastErro from '../Toasts/ToastErro';
import IErrors from './Interface/IErros';

export default function GetValidationErrors(err: ValidationError): IErrors {
  const validationErrors: IErrors = {};
  let time = 4000;
  err.inner.forEach((error, index) => {
    validationErrors[String(error.path)] = error.message;

    const validar =
      err.inner.findIndex((erroRepetido) => {
        return error.message === erroRepetido.message;
      }) === index;

    if (validar) {
      ToastErro(error.message, { autoClose: time });
      time += 1000;
    }
  });

  return validationErrors;
}
