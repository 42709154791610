const PessoaPorRepresentantePlanilha = [
  {
    titulo: 'Código do representante',
    valor: 'listaPessoa.pessoaRepresentante.codigo',
  },
  {
    titulo: 'Representante',
    valor: 'listaPessoa.pessoaRepresentante.nomeRazaoSocial',
  },
  {
    titulo: 'Código Cliente',
    valor: 'listaPessoa.pessoaCliente.pessoa.codigo',
  },
  {
    titulo: 'Cliente Nome/Razão Social',
    valor: 'listaPessoa.pessoaCliente.pessoa.nomeRazaoSocial',
  },
  {
    titulo: 'Cnpj/Cpf',
    valor: 'listaPessoa.cnpjCpfVirtual',
  },
  {
    titulo: 'Telefone',
    valor: 'listaPessoa.telefone',
  },
  {
    titulo: 'Inscr Estadual',
    valor: 'listaPessoa.inscricaoEstadual',
  },
  {
    titulo: 'Email',
    valor: 'listaPessoa.email',
  },
  {
    titulo: 'Logradouro',
    valor:
      'listaPessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal.logradouro',
  },
  {
    titulo: 'Número',
    valor: 'listaPessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal.numero',
  },
  {
    titulo: 'Complemento',
    valor:
      'listaPessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal.complemento',
  },
  {
    titulo: 'CEP',
    valor: 'listaPessoa.pessoaCliente.pessoa.pessoaEnderecoPrincipal.cep',
  },
  {
    titulo: 'Contato',
    valor: 'listaPessoa.listaPessoaContato.nome',
  },
  {
    titulo: 'Contato Telefone',
    valor: 'listaPessoa.listaPessoaContato.telefone',
  },
  {
    titulo: 'Contato Email',
    valor: 'listaPessoa.listaPessoaContato.email',
  },
];

export default PessoaPorRepresentantePlanilha;
