/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable default-case */
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Container, Col, Row, Modal, Button } from 'react-bootstrap';
import {
  AppErro,
  EnumObterValor,
  FormatarDataParaBoleto,
  FormatarDataParaPtBr,
  FormatarHoraParaBoleto,
  ITerminalCaixaValoresInserir,
  MeioPagamentoEnum,
  SiTefContinuacaoEnum,
} from '@elogestor/util';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdCancel } from 'react-icons/md/index.mjs';
import axios, { AxiosRequestConfig } from 'axios';
import { v4 } from 'uuid';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { UseConfirmacao } from '../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import InputLabel from '../../../../../../../../../../Componentes/Inputs/InputLabel';
import { Sleep } from '../../../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import BtnDeletar from '../../../../../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import IPadraoProps from '../../../../../../../../../../Comum/Interface/IPadraoProps';
import TerminalCaixaComunicador from '../../../../../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaComunicador';
import TerminalCaixaEmpresaObterCertificadoComunicador from '../../../../../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaEmpresaObterCertificadoComunicador';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';
import ToastErro from '../../../../../../../../../../Util/Toasts/ToastErro';
import ToastInfo from '../../../../../../../../../../Util/Toasts/ToastInfo';
import { UseAuth } from '../../../../../../../../../../Hooks/Auth';

const https = require('https-browserify');

export interface ICertificadoTEF {
  pem: string;

  dados: {
    cidade: string;
    estado: string;
    pais: string;
    email: string;
    nome: string;
    nomeFantasia: string;
    nomeRazaoSocial: string;

    validade: {
      inicio: Date;
      fim: Date;
    };
  };
}

export interface ISiTefCriarSessaoParametros {
  certificado: ICertificadoTEF;
  terminalCaixa: ITerminalCaixaValoresInserir;
}

export interface ISiTefCriarSessaoRetorno {
  clisitefStatus: number;
  serviceStatus: number;
  sessionId: string;
}

export interface ISiTefFinalizarSessaoParametros {
  certificado: ICertificadoTEF;
}

export interface ISiTefFinalizarSessaoRetorno {
  clisitefStatus: number;
  serviceStatus: number;
}

export interface ISiTefCriarTransacaoParametros {
  certificado: ICertificadoTEF;
  terminalCaixa: ITerminalCaixaValoresInserir;
  sessionId?: string;
  codigoFuncao: number;
  valorTotal: number;
  numeroVenda: string;
  dataHoraEmissao: Date;
  operador: string;
}

export interface ISiTefCriarTransacaoRetorno {
  clisitefStatus: number;
  serviceStatus: number;
  sessionId: string;
}

export interface ISiTefContinuarTransacaoParametros {
  certificado: ICertificadoTEF;
  sessionId: string;
  dados: string;
  continuacao: SiTefContinuacaoEnum;
}

export interface ISiTefContinuarTransacaoRetorno {
  clisitefStatus: number;
  commandId: number;
  data: string;
  fieldId: number;
  fieldMaxLength: number;
  fieldMinLength: number;
  serviceStatus: number;
  sessionId: string;
}

export interface ISiTefFinalizarTransacaoParametros {
  certificado: ICertificadoTEF;
  sessionId: string;
  numeroVenda: string;
  dataHoraEmissao: Date;
  confirmar: boolean;
}

export interface ISiTefFinalizarTransacaoRetorno {
  serviceStatus: number;
  clisitefStatus: number;
}

export interface IRealizarPagamentoSiTef {
  idTerminalCaixa: string;
  meioPagamento: MeioPagamentoEnum;
  valorTotal: number;
  numeroVenda: string;
  dataHoraEmissao: Date;
  operador: string;
  quantidadeParcelas: number;
  diasCredito: number;
}

export interface IContinuarPagamentoSiTef {
  idTerminalCaixa: string;
  valorTotal: number;
  numeroVenda: string;
  dataHoraEmissao: Date;
  operador: string;
  cancelar: boolean;
}

export interface IRealizarCancelamentoSiTef {
  idTerminalCaixa: string;
  meioPagamento: MeioPagamentoEnum;
  valorTotal: number;
  numeroVenda: string;
  dataHoraEmissao: Date;
  operador: string;
  nsuHost: string;
}

export interface IVerificarPresencaPinPad {
  idTerminalCaixa: string;
}

interface ISiTefContext {
  criarSessao(
    dados: ISiTefCriarSessaoParametros
  ): Promise<ISiTefCriarSessaoRetorno | null>;
  finalizarSessao(
    dados: ISiTefFinalizarSessaoParametros
  ): Promise<ISiTefFinalizarSessaoRetorno | null>;
  criarTransacao(
    dados: ISiTefCriarTransacaoParametros
  ): Promise<ISiTefCriarTransacaoRetorno | null>;
  continuarTransacao(
    dados: ISiTefContinuarTransacaoParametros
  ): Promise<ISiTefContinuarTransacaoRetorno | null>;
  finalizarTransacao(
    dados: ISiTefFinalizarTransacaoParametros
  ): Promise<ISiTefFinalizarTransacaoRetorno | null>;

  realizarPagamentoSiTef(
    dados: IRealizarPagamentoSiTef
  ): Promise<ISiTefContinuarTransacaoRetorno[] | null>;
  realizarCancelamentoSiTef(
    dados: IRealizarCancelamentoSiTef
  ): Promise<ISiTefContinuarTransacaoRetorno[] | null>;
  continuarPagamentoSiTef(
    dados: IContinuarPagamentoSiTef
  ): Promise<ISiTefContinuarTransacaoRetorno[] | null>;

  verificarPresencaPinPad(
    dados: IVerificarPresencaPinPad
  ): Promise<boolean | null>;
}

const SiTefContext = createContext<ISiTefContext>({} as ISiTefContext);

const SiTefHook: React.FC<IPadraoProps> = ({ children }) => {
  const { abrirJanela } = UseConfirmacao();
  const { empresa } = UseAuth();
  const formRef = useRef<IFormCiaHandles>(null);

  const [aberto, setAberto] = useState(false);

  const [permiteCancelar, setPermiteCancelar] = useState(false);
  const [cancelado, setCancelado] = useState(false);
  const canceladoRef = useRef(cancelado);

  const [aguardar, setAguardar] = useState(false);
  const aguardarRef = useRef(aguardar);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const handleCriarSessao = useCallback(
    async ({
      certificado,
      terminalCaixa,
    }: ISiTefCriarSessaoParametros): Promise<ISiTefCriarSessaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL('https://127.0.0.1/agente/clisitef/session');

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sitefIp: '127.0.0.1',
          storeId: terminalCaixa.storeIdSiTef,
          terminalId: terminalCaixa.terminalIdSiTef,
          sessionParameters: `[ParmsClient=1=${empresa.cnpjCpf};2=20854502000197]`,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    [empresa.cnpjCpf]
  );

  const handleFinalizarSessao = useCallback(
    async ({
      certificado,
    }: ISiTefFinalizarSessaoParametros): Promise<ISiTefFinalizarSessaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL('https://127.0.0.1/agente/clisitef/session');

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        const response = await axios.delete(url.toString(), config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleCriarTransacao = useCallback(
    async ({
      certificado,
      terminalCaixa,
      sessionId,
      codigoFuncao,
      valorTotal,
      numeroVenda,
      dataHoraEmissao,
      operador,
    }: ISiTefCriarTransacaoParametros): Promise<ISiTefCriarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/startTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sitefIp: '127.0.0.1',
          storeId: terminalCaixa.storeIdSiTef,
          terminalId: terminalCaixa.terminalIdSiTef,
          sessionId: sessionId || undefined,

          functionId: codigoFuncao,
          trnAmount: valorTotal.FormatarParaPtBr(),
          taxInvoiceNumber: numeroVenda,
          taxInvoiceDate: FormatarDataParaBoleto(dataHoraEmissao),
          taxInvoiceTime: FormatarHoraParaBoleto(dataHoraEmissao),
          cashierOperator: operador,

          trnInitParameters: `[ParmsClient=1=${empresa.cnpjCpf};2=20854502000197]`,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    [empresa.cnpjCpf]
  );

  const handleContinuarTransacao = useCallback(
    async ({
      certificado,
      sessionId,
      dados,
      continuacao,
    }: ISiTefContinuarTransacaoParametros): Promise<ISiTefContinuarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/continueTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sessionId,
          data: dados,
          continue: Number(EnumObterValor(continuacao)),
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        // if (mensagemRetorno.serviceStatus === 1) {
        //   throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        // }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleFinalizarTransacao = useCallback(
    async ({
      certificado,
      sessionId,
      numeroVenda,
      dataHoraEmissao,
      confirmar,
    }: ISiTefFinalizarTransacaoParametros): Promise<ISiTefFinalizarTransacaoRetorno | null> => {
      try {
        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/finishTransaction'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sessionId,
          taxInvoiceNumber: numeroVenda,
          taxInvoiceDate: FormatarDataParaBoleto(dataHoraEmissao),
          taxInvoiceTime: FormatarHoraParaBoleto(dataHoraEmissao),
          confirm: confirmar ? 1 : 0,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.serviceStatus === 1) {
          throw new AppErro({ mensagem: mensagemRetorno.serviceMessage });
        }

        return mensagemRetorno;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    []
  );

  const handleRealizarPagamentoSiTef = useCallback(
    async ({
      idTerminalCaixa,
      meioPagamento,
      valorTotal,
      numeroVenda,
      dataHoraEmissao,
      operador,
      quantidadeParcelas,
      diasCredito,
    }: IRealizarPagamentoSiTef): Promise<
      ISiTefContinuarTransacaoRetorno[] | null
    > => {
      setAberto(true);
      setCancelado(false);

      let sessionId: string | undefined;
      let certificado: ICertificadoTEF | undefined;
      let sucesso = false;

      try {
        const listaDados: ISiTefContinuarTransacaoRetorno[] = [];

        if (!idTerminalCaixa) {
          ToastErro('Náo existe Terminal configurado!');
          setAberto(false);
          return null;
        }

        const terminalCaixa = await TerminalCaixaComunicador.show({
          id: idTerminalCaixa,
        });

        certificado =
          await TerminalCaixaEmpresaObterCertificadoComunicador.show({
            idTerminalCaixa,
          });

        let codigoFuncao = 0;

        switch (meioPagamento) {
          case MeioPagamentoEnum.cartaoDebito:
            codigoFuncao = 2;
            break;

          case MeioPagamentoEnum.cartaoCredito:
            codigoFuncao = 3;
            break;

          case MeioPagamentoEnum.valeCombustivel:
            codigoFuncao = 7;
            break;

          case MeioPagamentoEnum.valeRefeicao:
            codigoFuncao = 10;
            break;

          case MeioPagamentoEnum.valeAlimentaca:
            codigoFuncao = 11;
            break;

          case MeioPagamentoEnum.pagamentoInstantaneoPix:
            codigoFuncao = 122;
            break;
        }

        const transacao = await handleCriarTransacao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
          codigoFuncao,
          valorTotal,
          numeroVenda,
          dataHoraEmissao,
          operador,
        });

        if (!transacao) {
          ToastErro('Erro ao Iniciar venda!');
          setAberto(false);
          return null;
        }

        sessionId = transacao.sessionId;
        setPermiteCancelar(true);
        refresh;

        let dados = '';
        let statusTransacao = 10000;

        formRef.current?.setFieldValue('situacao', 'Aguarde...');
        refresh();

        while (statusTransacao === 10000 && !canceladoRef.current) {
          const continuar = await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados,
            continuacao: SiTefContinuacaoEnum.continuar,
          });

          if (!continuar) {
            statusTransacao = 0;
            continue;
          }

          statusTransacao = continuar.clisitefStatus;
          listaDados.push(continuar);

          if (
            statusTransacao !== 0 &&
            continuar.data &&
            (continuar.fieldId === -1 ||
              continuar.commandId === 21 ||
              continuar.commandId === 30)
          ) {
            const mensagem = continuar.data.toUpperCase();

            if (continuar.commandId === 21) {
              if (mensagem.includes('1:A VISTA;2:PRE-DATADO;3:PARCELADO')) {
                if (
                  quantidadeParcelas > 1 &&
                  meioPagamento === MeioPagamentoEnum.cartaoDebito &&
                  diasCredito > 1
                )
                  dados = '3';
                else {
                  dados =
                    quantidadeParcelas === 1 && diasCredito === 1 ? '1' : '2';
                }
              } else {
                dados = quantidadeParcelas > 1 ? '2' : '1';
              }
            } else if (continuar.commandId === 30) {
              if (
                mensagem.includes('FORNECA A DATA DO PRE-DATADO') ||
                mensagem.includes('FORNECA A DATA DA PRIMEIRA PARCELA')
              ) {
                const dataAtual = new Date().AddDays(diasCredito);
                dados =
                  FormatarDataParaPtBr(dataAtual).RemoverCaracteresEspeciais();
              } else {
                dados = String(quantidadeParcelas);
              }
            } else {
              dados = '';
              formRef.current?.setFieldValue('situacao', continuar.data);
            }

            if (continuar.commandId === 22) {
              setAguardar(true);
              await Sleep(50);
              let cont = 0;

              while (aguardarRef.current && cont < 60) {
                await Sleep(500);
                cont += 1;
              }

              setAguardar(false);
            } else if (
              mensagem === 'TRANSACAO APROV.' ||
              mensagem === 'TRANSACAO AUTORIZADA' ||
              mensagem === 'TRANSACAO OK' ||
              mensagem === 'TRANSACAO OK!' ||
              mensagem === 'LOJISTA OK' ||
              mensagem === 'TRANSACAO APROVADA' ||
              mensagem === 'AUTORIZADA' ||
              mensagem === 'PAGAMENTO EFETUADO' ||
              mensagem === 'OPERAÇÃO CONCLUÍDA' ||
              mensagem === 'PAGAMENTO REALIZADO' ||
              mensagem === 'TRANSACAO CONFIRMADA' ||
              mensagem === 'COMPRA FINALIZADA' ||
              mensagem === 'COMPRA APROVADA' ||
              mensagem === 'VENDA APROVADA' ||
              mensagem === 'TRANSACAO REALIZADA COM SUCESSO' ||
              mensagem === 'OPERAÇÃO APROVADA' ||
              mensagem === 'TRANSACAO EFETIVADA' ||
              mensagem.startsWith('APROVADA')
            ) {
              sucesso = true;
              await Sleep(2000);
            }
            // else if (
            //   mensagem === 'TRANSACAO NAO ACEITA, RETIRE O CARTAO' ||
            //   mensagem === '13 - OPERACAO CANCELADA?' ||
            //   mensagem === 'CARTAO NAO CONFIGURADO' ||
            //   mensagem.includes('ERRO')
            // ) {
            //   statusTransacao = 0;
            //   await Sleep(2000);
            // } else if (
            //   mensagem === 'SEM CONEXAO SERVIDOR' ||
            //   mensagem === 'NAO EXISTE CONF.' ||
            //   mensagem === 'TRANSACAO NAO AUTORIZADA PELO CARTAO'
            // ) {
            //   await Sleep(2000);
            // }
          } else {
            dados = '';
          }

          refresh();
        }

        setPermiteCancelar(false);
        refresh();

        if (!canceladoRef.current && sucesso) {
          await handleFinalizarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            numeroVenda,
            dataHoraEmissao,
            confirmar: true,
          });
        } else {
          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.encerar,
          });

          await Sleep(1000);

          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '0',
            continuacao: SiTefContinuacaoEnum.continuar,
          });

          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.continuar,
          });
        }

        setAberto(false);

        if (sucesso) {
          return listaDados;
        }

        return null;
      } catch (error) {
        TratarErros(error);

        if (sessionId && certificado) {
          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.encerar,
          });
        }

        setAberto(false);
        return null;
      }
    },
    [
      handleContinuarTransacao,
      handleCriarTransacao,
      handleFinalizarTransacao,
      refresh,
    ]
  );

  const handleContinuarPagamentoSiTef = useCallback(
    async ({
      idTerminalCaixa,
      valorTotal,
      numeroVenda,
      dataHoraEmissao,
      operador,
      cancelar,
    }: IContinuarPagamentoSiTef): Promise<
      ISiTefContinuarTransacaoRetorno[] | null
    > => {
      setAberto(true);
      setCancelado(false);

      let sessionId: string | undefined;
      let certificado: ICertificadoTEF | undefined;
      let sucesso = false;

      try {
        const listaDados: ISiTefContinuarTransacaoRetorno[] = [];

        if (!idTerminalCaixa) {
          ToastErro('Náo existe Terminal configurado!');
          setAberto(false);
          return null;
        }

        const terminalCaixa = await TerminalCaixaComunicador.show({
          id: idTerminalCaixa,
        });

        certificado =
          await TerminalCaixaEmpresaObterCertificadoComunicador.show({
            idTerminalCaixa,
          });

        const sessao = await handleCriarSessao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
        });

        if (!sessao) {
          ToastErro('Erro ao Continuar Pagamento!');
          setAberto(false);
          return null;
        }

        sessionId = sessao.sessionId;

        const transacao = await handleCriarTransacao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
          sessionId,
          codigoFuncao: 130,
          valorTotal,
          numeroVenda,
          dataHoraEmissao,
          operador,
        });

        if (!transacao) {
          ToastErro('Erro ao Continuar Pagamento!');
          setAberto(false);
          return null;
        }

        setPermiteCancelar(true);
        refresh;

        const dados = '';
        let statusTransacao = 10000;

        formRef.current?.setFieldValue('situacao', 'Aguarde...');
        refresh();

        while (statusTransacao === 10000 && !canceladoRef.current) {
          const continuar = await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados,
            continuacao: SiTefContinuacaoEnum.continuar,
          });

          if (!continuar) {
            statusTransacao = 0;
            continue;
          }

          statusTransacao = continuar.clisitefStatus;
          listaDados.push(continuar);

          if (statusTransacao !== 0 && continuar.data) {
            if (continuar.fieldId === 160 && continuar.data === numeroVenda) {
              for (let i = 0; i < 4; i++) {
                if (statusTransacao !== 0) {
                  const continuacao = await handleContinuarTransacao({
                    certificado: certificado as ICertificadoTEF,
                    sessionId,
                    dados,
                    continuacao: SiTefContinuacaoEnum.continuar,
                  });

                  statusTransacao = continuacao
                    ? continuacao.clisitefStatus
                    : 0;

                  listaDados.push(continuar);
                }
              }

              if (statusTransacao !== 0) {
                const continuacao = await handleContinuarTransacao({
                  certificado: certificado as ICertificadoTEF,
                  sessionId,
                  dados,
                  continuacao: cancelar
                    ? SiTefContinuacaoEnum.cancelar
                    : SiTefContinuacaoEnum.encerar,
                });

                statusTransacao = continuacao ? continuacao.clisitefStatus : 0;
                listaDados.push(continuar);

                await handleFinalizarTransacao({
                  certificado: certificado as ICertificadoTEF,
                  sessionId,
                  numeroVenda,
                  dataHoraEmissao,
                  confirmar: !cancelar,
                });

                sucesso = true;
              }
            }
          }

          refresh();
        }

        setPermiteCancelar(false);
        refresh();

        if (!canceladoRef.current && sucesso) {
          await handleFinalizarSessao({
            certificado: certificado as ICertificadoTEF,
          });
        } else {
          await handleFinalizarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            numeroVenda,
            dataHoraEmissao,
            confirmar: false,
          });

          await handleFinalizarSessao({
            certificado: certificado as ICertificadoTEF,
          });

          // ToastInfo(
          //   `Não foi localizado nenhum pagamento ${cancelar ? 'para cancelar' : 'para dar continuidade'}!`
          // );
        }

        setAberto(false);

        if (sucesso) {
          return listaDados;
        }

        return null;
      } catch (error) {
        TratarErros(error);

        if (sessionId && certificado) {
          await handleFinalizarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            numeroVenda,
            dataHoraEmissao,
            confirmar: false,
          });

          await handleFinalizarSessao({
            certificado: certificado as ICertificadoTEF,
          });
        }

        setAberto(false);
        return null;
      }
    },
    [
      handleContinuarTransacao,
      handleCriarSessao,
      handleCriarTransacao,
      handleFinalizarSessao,
      handleFinalizarTransacao,
      refresh,
    ]
  );

  const handleRealizarCancelamentoSiTef = useCallback(
    async ({
      idTerminalCaixa,
      meioPagamento,
      valorTotal,
      numeroVenda,
      dataHoraEmissao,
      operador,
      nsuHost,
    }: IRealizarCancelamentoSiTef): Promise<
      ISiTefContinuarTransacaoRetorno[] | null
    > => {
      setAberto(true);
      setCancelado(false);

      let sessionId: string | undefined;
      let certificado: ICertificadoTEF | undefined;
      let sucesso = false;

      const novoIdPagamentoTef = v4().substring(0, 20);

      try {
        const listaDados: ISiTefContinuarTransacaoRetorno[] = [];

        if (!idTerminalCaixa) {
          ToastErro('Náo existe Terminal configurado!');
          setAberto(false);
          return null;
        }

        const terminalCaixa = await TerminalCaixaComunicador.show({
          id: idTerminalCaixa,
        });

        certificado =
          await TerminalCaixaEmpresaObterCertificadoComunicador.show({
            idTerminalCaixa,
          });

        const sessao = await handleCriarSessao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
        });

        if (!sessao) {
          ToastErro('Erro ao Iniciar Cancelamento!');
          setAberto(false);
          return null;
        }

        sessionId = sessao.sessionId;

        const transacao = await handleCriarTransacao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
          sessionId,
          codigoFuncao:
            meioPagamento === MeioPagamentoEnum.pagamentoInstantaneoPix
              ? 123
              : 110,
          valorTotal,
          numeroVenda: novoIdPagamentoTef,
          dataHoraEmissao,
          operador,
        });

        if (!transacao) {
          ToastErro('Erro ao Iniciar Cancelamento!');
          setAberto(false);
          return null;
        }

        setPermiteCancelar(true);
        refresh;

        let dados = '';
        let statusTransacao = 10000;

        formRef.current?.setFieldValue('situacao', 'Aguarde...');
        refresh();

        while (statusTransacao === 10000 && !canceladoRef.current) {
          const continuar = await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados,
            continuacao: SiTefContinuacaoEnum.continuar,
          });

          if (!continuar) {
            statusTransacao = 0;
            continue;
          }

          statusTransacao = continuar.clisitefStatus;
          listaDados.push(continuar);

          if (statusTransacao !== 0 && continuar.data) {
            if (
              continuar.data.includes(
                '1:Teste de comunicacao;2:Reimpressao de comprovante;3:Cancelamento de transacao;'
              )
            ) {
              dados = '3';
            } else if (
              continuar.data.includes(
                '1:Cancelamento de Cartao de Debito;2:Cancelamento de Cartao de Credito;'
              )
            ) {
              dados =
                meioPagamento === MeioPagamentoEnum.cartaoCredito ? '2' : '1';
            } else if (
              continuar.data === 'Digite o valor da transacao' ||
              continuar.data === 'Forneca o valor da transacao original'
            ) {
              dados = valorTotal
                .FormatarParaPtBr()
                .RemoverCaracteresEspeciais();
            } else if (continuar.data === 'Data da transacao (DDMMAAAA)') {
              dados =
                FormatarDataParaPtBr(
                  dataHoraEmissao
                ).RemoverCaracteresEspeciais();
            } else if (continuar.data === 'Data da transacao (DDMM)') {
              dados = FormatarDataParaPtBr(dataHoraEmissao)
                .RemoverCaracteresEspeciais()
                .substring(0, 4);
            } else if (
              continuar.data === 'Forneca o numero do documento a ser cancelado'
            ) {
              dados = nsuHost;
            } else {
              dados = '';
              if (
                continuar.fieldId === -1 &&
                continuar.commandId !== 4 &&
                continuar.commandId !== 21
              ) {
                formRef.current?.setFieldValue('situacao', continuar.data);
              }
            }

            const mensagem = continuar.data.toUpperCase();

            if (
              mensagem === 'TRANSACAO APROV.' ||
              mensagem === 'TRANSACAO AUTORIZADA' ||
              mensagem === 'TRANSACAO OK' ||
              mensagem === 'TRANSACAO OK!' ||
              mensagem === 'LOJISTA OK' ||
              mensagem === 'TRANSACAO APROVADA' ||
              mensagem === 'AUTORIZADA' ||
              mensagem === 'PAGAMENTO EFETUADO' ||
              mensagem === 'OPERAÇÃO CONCLUÍDA' ||
              mensagem === 'PAGAMENTO REALIZADO' ||
              mensagem === 'TRANSACAO CONFIRMADA' ||
              mensagem === 'COMPRA FINALIZADA' ||
              mensagem === 'COMPRA APROVADA' ||
              mensagem === 'VENDA APROVADA' ||
              mensagem === 'TRANSACAO REALIZADA COM SUCESSO' ||
              mensagem === 'OPERAÇÃO APROVADA' ||
              mensagem === 'TRANSACAO EFETIVADA' ||
              mensagem.startsWith('APROVADA')
            ) {
              sucesso = true;
              await Sleep(2000);
            } else if (
              mensagem === 'TRANSACAO NAO ACEITA, RETIRE O CARTAO' ||
              mensagem === '13 - OPERACAO CANCELADA?' ||
              mensagem.includes('ERRO')
            ) {
              statusTransacao = 0;
              await Sleep(2000);
            }
          }

          refresh();
        }

        setPermiteCancelar(false);
        refresh();

        if (!canceladoRef.current && sucesso) {
          await handleFinalizarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            numeroVenda: novoIdPagamentoTef,
            dataHoraEmissao,
            confirmar: true,
          });

          await handleFinalizarSessao({
            certificado: certificado as ICertificadoTEF,
          });
        } else {
          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.encerar,
          });

          await Sleep(1000);

          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '0',
            continuacao: SiTefContinuacaoEnum.continuar,
          });

          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.continuar,
          });
        }

        setAberto(false);

        if (sucesso) {
          return listaDados;
        }

        return null;
      } catch (error) {
        TratarErros(error);

        if (sessionId && certificado) {
          await handleContinuarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            dados: '',
            continuacao: SiTefContinuacaoEnum.encerar,
          });

          await handleFinalizarTransacao({
            certificado: certificado as ICertificadoTEF,
            sessionId,
            numeroVenda: novoIdPagamentoTef,
            dataHoraEmissao,
            confirmar: false,
          });

          await handleFinalizarSessao({
            certificado: certificado as ICertificadoTEF,
          });
        }

        setAberto(false);
        return null;
      }
    },
    [
      handleContinuarTransacao,
      handleCriarSessao,
      handleCriarTransacao,
      handleFinalizarSessao,
      handleFinalizarTransacao,
      refresh,
    ]
  );

  const handleVerificarPresencaPinPad = useCallback(
    async ({
      idTerminalCaixa,
    }: IVerificarPresencaPinPad): Promise<boolean | null> => {
      try {
        if (!idTerminalCaixa) {
          ToastErro('Náo existe Terminal configurado!');
          return null;
        }

        const terminalCaixa = await TerminalCaixaComunicador.show({
          id: idTerminalCaixa,
        });

        const certificado =
          await TerminalCaixaEmpresaObterCertificadoComunicador.show({
            idTerminalCaixa,
          });

        const sessao = await handleCriarSessao({
          certificado: certificado as ICertificadoTEF,
          terminalCaixa,
        });

        // #region URL

        const url = new URL(
          'https://127.0.0.1/agente/clisitef/pinpad/isPresent'
        );

        // #endregion URL

        // #region Config

        const config: AxiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          httpsAgent: new https.Agent({
            ca: certificado.pem,
          }),
        };

        // #endregion Config

        // #region Data

        const data = {
          sessionId: sessao?.sessionId,
        };

        // #endregion Data

        const response = await axios.post(url.toString(), data, config);

        const mensagemRetorno = response.data;

        if (mensagemRetorno.clisitefStatus === 1) {
          return true;
        }

        return false;
      } catch (error) {
        TratarErros(error);
        return null;
      }
    },
    [handleCriarSessao]
  );

  useEffect(() => {
    canceladoRef.current = cancelado;
    aguardarRef.current = aguardar;
  }, [aguardar, cancelado]);

  return (
    <SiTefContext.Provider
      value={{
        criarSessao: handleCriarSessao,
        finalizarSessao: handleFinalizarSessao,
        criarTransacao: handleCriarTransacao,
        continuarTransacao: handleContinuarTransacao,
        finalizarTransacao: handleFinalizarTransacao,

        realizarPagamentoSiTef: handleRealizarPagamentoSiTef,
        realizarCancelamentoSiTef: handleRealizarCancelamentoSiTef,
        continuarPagamentoSiTef: handleContinuarPagamentoSiTef,

        verificarPresencaPinPad: handleVerificarPresencaPinPad,
      }}
    >
      <Modal show={aberto} size="lg">
        <Modal.Body>
          <Container>
            <FormCia ref={formRef}>
              <Row>
                <Col lg={12} md={12} sm={12} style={{ margin: 20 }}>
                  <InputLabel
                    label=""
                    name="situacao"
                    formatarValor={(value) => {
                      return <div style={{ fontSize: 22 }}>{value}</div>;
                    }}
                  />
                </Col>
              </Row>

              <Row
                style={{
                  display: aguardar ? 'none' : 'flex',
                  justifyContent: 'center',
                }}
              >
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                >
                  <BtnDeletar
                    type="button"
                    className="btn-padrao btn-cinza-claro"
                    onClick={async () => {
                      const resposta = await abrirJanela({
                        titulo: <h2>Confirmação</h2>,
                        mensagem: (
                          <span style={{ fontSize: 20 }}>
                            Deseja cancelar a operação?
                          </span>
                        ),
                        confimar: 'Sim',
                        cancelar: 'Não',
                      });

                      if (resposta) {
                        setCancelado(true);
                      }
                    }}
                    disabled={!permiteCancelar}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 16,
                      }}
                    >
                      <MdCancel />
                      <span style={{ marginLeft: 10 }}>Cancelar</span>
                    </span>
                  </BtnDeletar>
                </Col>
              </Row>

              <Row
                style={{
                  display: !aguardar ? 'none' : 'flex',
                  justifyContent: 'center',
                }}
              >
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                >
                  <Button
                    type="button"
                    className="btn-padrao btn-azul-escuro"
                    onClick={async () => {
                      setAguardar(false);
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 16,
                      }}
                    >
                      <AiOutlineCheck />
                      <span style={{ marginLeft: 10 }}>OK</span>
                    </span>
                  </Button>
                </Col>
              </Row>
            </FormCia>
          </Container>
        </Modal.Body>
      </Modal>
      {children}
    </SiTefContext.Provider>
  );
};

function UseSiTef(): ISiTefContext {
  const context = useContext(SiTefContext);

  if (!context) {
    throw new Error('UseSiTef deve ser usado com um SiTefProvider');
  }

  return context;
}

export { SiTefHook, UseSiTef };
