/* eslint-disable no-nested-ternary */
import {
  EstoqueIndicadorTipoEnum,
  FinalidadeEmissaoEnum,
  IPedidoVendaItemEstoqueLocalEstoqueLista,
  IPedidoVendaItemValoresAlterar,
  SituacaoItemEnum,
  SituacaoPadraoPedidoVendaEnum,
  TipoMovimentoEntradaSaidaEnum,
  TipoPedidoVendaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import {
  ListaDetalheFormProvider,
  ISubmitProps,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import PedidoVendaItemComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Item/Comunicador/PedidoVendaItemComunicador';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import { UseItemPedidoVenda } from './ItemPedidoVendaHook';
import TratarAvisos from '../../../../../../../../Util/Aviso/TratarAvisos';
import { IInputAutoCompletePadraoRef } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import { UseLiberacoes } from '../../../../../../../../Hooks/LiberacoesHook';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';

const FormHook: React.FC<any> = ({ children, atualizarCampos }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();
  const parametros = UseParametros();
  const liberacoes = UseLiberacoes();
  const { setProdutoControlaLote, setListaLocalEstoque } = UseItemPedidoVenda();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback(
    (valor: string) => {
      idDetalheRegistro.current = valor;
      refresh();
    },
    [refresh]
  );

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IPedidoVendaItemValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const finalidadeEmissao =
          formPrincipal.formRef.current?.getFieldValue('finalidadeEmissao');

        const response = await PedidoVendaItemComunicador.show({
          id: idEditar,
        });

        setProdutoControlaLote(response.produto.produtoEstoque.controlarLote);
        setListaLocalEstoque(
          response.pedidoVendaItemEstoque
            .listaPedidoVendaItemEstoqueLocalEstoque
        );
        await formRefDetalhe.current?.setSemExecutarEvento({
          ...response,
          filtrarProdutosDisponiveisVenda:
            finalidadeEmissao === FinalidadeEmissaoEnum.normal,
        });
        await formRefDetalhe.current?.atualizarDataInicial();
        atualizarCampos && atualizarCampos();

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        const finalidadeEmissao =
          formPrincipal.formRef.current?.getFieldValue('finalidadeEmissao');
        const tipoPedidoVenda =
          formPrincipal.formRef.current?.getFieldValue('tipoPedidoVenda');

        const situacaoPadrao =
          tipoPedidoVenda === TipoPedidoVendaEnum.consignado
            ? SituacaoItemEnum.aprovado
            : parametros.SituacaoPadraoPedidoVenda ===
                SituacaoPadraoPedidoVendaEnum.aprovado
              ? SituacaoItemEnum.aprovado
              : SituacaoItemEnum.pendente;

        await formRefDetalhe.current?.setDataInicial({
          situacao: situacaoPadrao,
          quantidadeUnitariaTributado: 1,
          filtrarProdutosDisponiveisVenda:
            finalidadeEmissao === FinalidadeEmissaoEnum.normal,
          valorFrete: 0,
          valorSeguro: 0,
          valorOutrasDespesas: 0,
          valorDesconto: 0,

          quantidadeRetiradaConsignado: 0,

          pedidoVendaItemEstoque: {
            movimentarEstoque: true,
            reservarEstoque:
              tipoPedidoVenda === TipoPedidoVendaEnum.consignado
                ? true
                : parametros.ReservaEstoquePadraoPedidoVenda,
            fatorConversao: 1,
            quantidadeEstoque: 0,
            indicadorTipoEstoque:
              tipoPedidoVenda === TipoPedidoVendaEnum.consignado
                ? EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro
                : EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
          },

          pedidoVendaItemComercial: {
            utilizarValorTabelaPreco: true,
          },

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      atualizarCampos,
      formPrincipal.formRef,
      getIdDetalheRegistro,
      handleSetarFocus,
      parametros.ReservaEstoquePadraoPedidoVenda,
      parametros.SituacaoPadraoPedidoVenda,
      redirecionar,
      refresh,
      setListaLocalEstoque,
      setProdutoControlaLote,
    ]
  );

  const handleValidarAvisos = useCallback(
    async (data: any): Promise<void> => {
      try {
        const listaAvisos: string[] = [];

        const total =
          data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque.reduce(
            (
              acumulador: number,
              item: IPedidoVendaItemEstoqueLocalEstoqueLista
            ) => {
              acumulador += Number(item.quantidade);
              return acumulador;
            },
            0
          );

        if (
          data.pedidoVendaItemEstoque.movimentarEstoque &&
          data.pedidoVendaItemEstoque.reservarEstoque &&
          total < data.pedidoVendaItemEstoque.quantidadeEstoque
        )
          listaAvisos.push(
            'Soma da quantidade dos locais de estoque é diferente da quantidade do estoque do item!'
          );

        if (listaAvisos.length > 0) TratarAvisos(listaAvisos);
      } catch (error) {
        TratarErros(error, { redirecionar });
      }
    },
    [redirecionar]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const tipoPedidoVenda =
          formPrincipal.formRef.current?.getFieldValue('tipoPedidoVenda');

        const schema = Yup.object().shape({
          idProduto: Yup.string().nullable().required('Produto é obrigatório!'),
          idUnidadeMedida: Yup.string()
            .nullable()
            .required('Unidade de Medida é obrigatória!'),
          idProdutoTributado: Yup.string()
            .nullable()
            .required('Produto Tributado é obrigatório!'),
          idUnidadeMedidaTributado: Yup.string()
            .nullable()
            .required('Unidade de Medida Tributada é obrigatória!'),
          idTipoNota: Yup.string()
            .nullable()
            .required('Tipo de Nota é obrigatório!'),
          idTipoNotaMotivo: Yup.string()
            .nullable()
            .required('Motivo é obrigatório!'),
          idOperacaoFiscal: Yup.string()
            .nullable()
            .required('Operação Fiscal é obrigatório!'),

          ordem: Yup.number().nullable().required('Ordem é obrigatória!'),
          quantidade: Yup.number()
            .nullable()
            .required('Quantidade é obrigatória!'),
          valorUnitario: Yup.number()
            .nullable()
            .required('Valor Unitário é obrigatório!'),
          valorTotalProduto: Yup.number()
            .nullable()
            .required('Valor Total do Produto é obrigatório!'),
          valorTotalBruto: Yup.number()
            .nullable()
            .required('Valor Total Bruto é obrigatório!'),

          quantidadeUnitariaTributado: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade Unitária Tributada deve ser maior que Zero!',
              test: () => {
                return !(
                  data.quantidade > 0 && data.quantidadeUnitariaTributado === 0
                );
              },
            }),

          quantidadeTributado: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade Tributada deve ser maior que Zero!',
              test: () => {
                return !(data.quantidade > 0 && data.quantidadeTributado === 0);
              },
            }),

          valorUnitarioTributado: Yup.number()
            .nullable()
            .required('Valor Unitário Tributado é obrigatório!'),

          naturezaOperacao: Yup.mixed()
            .nullable()
            .test({
              message: 'Natureza da Operação é obrigatória!',
              test: (value: any) => {
                const dados = formPrincipal.formRef.current?.getDataDuplicar();

                if (
                  dados.modeloDocumento &&
                  dados.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),

          pedidoVendaItemImpostoIcms: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do ICMS é obrigatória!'),

            idOrigemIcms: Yup.string()
              .nullable()
              .required('Origem do ICMS é obrigatória!'),
          }),

          pedidoVendaItemImpostoIpi: Yup.object()
            .shape({
              idIpiCodigoEnquadramentoLegal: Yup.mixed()
                .nullable()
                .test({
                  message: 'Código Enquadramento Legal é obrigatória!',
                  test: (value: any) => {
                    if (
                      data.pedidoVendaItemImpostoIpi.idSituacaoTributaria &&
                      !value
                    ) {
                      return false;
                    }

                    return true;
                  },
                }),
            })
            .nullable(),

          pedidoVendaItemImpostoPis: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do PIS é obrigatória!'),
          }),

          pedidoVendaItemImpostoCofins: Yup.object().shape({
            idSituacaoTributaria: Yup.string()
              .nullable()
              .required('CST do COFINS é obrigatória!'),
          }),

          pedidoVendaItemEstoque: Yup.object().shape({
            idPessoaTerceiro: Yup.mixed()
              .nullable()
              .test({
                message: 'Pessoa Terceiro é obrigatória!',
                test: () => {
                  if (
                    data.pedidoVendaItemEstoque.porContaOrdemTerceiro &&
                    !data.pedidoVendaItemEstoque.idPessoaTerceiro
                  )
                    return false;

                  return true;
                },
              })
              .nullable(),

            listaPedidoVendaItemEstoqueLocalEstoque: Yup.mixed()
              .nullable()
              .test({
                message:
                  'Soma da quantidade dos locais de estoque das saídas é maior que a quantidade de estoque do item!',
                test: () => {
                  const total =
                    data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque
                      .filter(
                        (item: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
                          return (
                            item.tipoEntradaSaida ===
                            TipoMovimentoEntradaSaidaEnum.saida
                          );
                        }
                      )
                      .reduce(
                        (
                          acumulador: number,
                          item: IPedidoVendaItemEstoqueLocalEstoqueLista
                        ) => {
                          acumulador += Number(item.quantidade);
                          return acumulador;
                        },
                        0
                      );

                  return !(
                    data.pedidoVendaItemEstoque.movimentarEstoque &&
                    total > data.pedidoVendaItemEstoque.quantidadeEstoque
                  );
                },
              })
              .test({
                message:
                  'Soma da quantidade dos locais de estoque das saídas é diferente da quantidade do estoque do item!',
                test: () => {
                  const total =
                    data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque
                      .filter(
                        (item: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
                          return (
                            item.tipoEntradaSaida ===
                            TipoMovimentoEntradaSaidaEnum.saida
                          );
                        }
                      )
                      .reduce(
                        (
                          acumulador: number,
                          item: IPedidoVendaItemEstoqueLocalEstoqueLista
                        ) => {
                          acumulador += Number(item.quantidade);
                          return acumulador;
                        },
                        0
                      );

                  return !(
                    data.pedidoVendaItemEstoque.movimentarEstoque &&
                    total < data.pedidoVendaItemEstoque.quantidadeEstoque
                  );
                },
              })
              .test({
                message:
                  'Soma da quantidade dos locais de estoque das entradas é maior que a quantidade de estoque do item!',
                test: () => {
                  if (
                    data.pedidoVendaItemEstoque.indicadorTipoEstoque ===
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro ||
                    data.pedidoVendaItemEstoque.indicadorTipoEstoque ===
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
                  ) {
                    const total =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque
                        .filter(
                          (item: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
                            return (
                              item.tipoEntradaSaida ===
                              TipoMovimentoEntradaSaidaEnum.entrada
                            );
                          }
                        )
                        .reduce(
                          (
                            acumulador: number,
                            item: IPedidoVendaItemEstoqueLocalEstoqueLista
                          ) => {
                            acumulador += Number(item.quantidade);
                            return acumulador;
                          },
                          0
                        );

                    return !(
                      data.pedidoVendaItemEstoque.movimentarEstoque &&
                      total > data.pedidoVendaItemEstoque.quantidadeEstoque
                    );
                  }

                  return true;
                },
              })
              .test({
                message:
                  'Soma da quantidade dos locais de estoque das entradas é diferente da quantidade do estoque do item!',
                test: () => {
                  if (
                    data.pedidoVendaItemEstoque.indicadorTipoEstoque ===
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro ||
                    data.pedidoVendaItemEstoque.indicadorTipoEstoque ===
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
                  ) {
                    const total =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque
                        .filter(
                          (item: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
                            return (
                              item.tipoEntradaSaida ===
                              TipoMovimentoEntradaSaidaEnum.entrada
                            );
                          }
                        )
                        .reduce(
                          (
                            acumulador: number,
                            item: IPedidoVendaItemEstoqueLocalEstoqueLista
                          ) => {
                            acumulador += Number(item.quantidade);
                            return acumulador;
                          },
                          0
                        );

                    return !(
                      data.pedidoVendaItemEstoque.movimentarEstoque &&
                      total < data.pedidoVendaItemEstoque.quantidadeEstoque
                    );
                  }

                  return true;
                },
              })
              .test({
                message: 'Existem Locais de Estoques divergentes!',
                test: () => {
                  if (tipoPedidoVenda === TipoPedidoVendaEnum.consignado) {
                    const listaId =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque.map(
                        (item: any) => item.idLocalEstoque
                      );

                    const todosIguais = listaId.every(
                      (valor: string) => valor === listaId[0]
                    );

                    return !!todosIguais;
                  }

                  return true;
                },
              })
              .test({
                message: 'Existem Lotes divergentes!',
                test: () => {
                  if (tipoPedidoVenda === TipoPedidoVendaEnum.consignado) {
                    const listaId =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque.map(
                        (item: any) => item.idLote
                      );

                    const todosIguais = listaId.every(
                      (valor: string) => valor === listaId[0]
                    );

                    return !!todosIguais;
                  }

                  return true;
                },
              })
              .test({
                message: 'Existem mais do que um Estoque de Entrada!',
                test: () => {
                  if (tipoPedidoVenda === TipoPedidoVendaEnum.consignado) {
                    const listaEstoqueEntrada =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque.filter(
                        (item: IPedidoVendaItemEstoqueLocalEstoqueLista) =>
                          item.tipoEntradaSaida ===
                          TipoMovimentoEntradaSaidaEnum.entrada
                      );

                    return !(listaEstoqueEntrada.length > 1);
                  }

                  return true;
                },
              })
              .test({
                message: 'Existem mais do que um Estoque de Saída!',
                test: () => {
                  if (tipoPedidoVenda === TipoPedidoVendaEnum.consignado) {
                    const listaEstoqueSaida =
                      data.pedidoVendaItemEstoque.listaPedidoVendaItemEstoqueLocalEstoque.filter(
                        (item: IPedidoVendaItemEstoqueLocalEstoqueLista) =>
                          item.tipoEntradaSaida ===
                          TipoMovimentoEntradaSaidaEnum.saida
                      );

                    return !(listaEstoqueSaida.length > 1);
                  }

                  return true;
                },
              }),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef?.setErrors(errors);
        return false;
      }
    },
    [formPrincipal.formRef]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = getIdDetalheRegistro() || '';
        const idDetalheRegistroPrincipal =
          formPrincipal.getIdDetalheRegistro() || '';

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id, erro: true };
        }

        await handleValidarAvisos(data);

        data.pedidoVenda = {
          freteFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'freteFormaCalculoPorRateio'
            ),
          seguroFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'seguroFormaCalculoPorRateio'
            ),
          outrasDespesasFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'outrasDespesasFormaCalculoPorRateio'
            ),
          descontoFormaCalculoPorRateio:
            formPrincipal.formRef.current?.getFieldValue(
              'descontoFormaCalculoPorRateio'
            ),
        };

        if (!data.pedidoVendaItemImpostoIpi.idSituacaoTributaria) {
          data.pedidoVendaItemImpostoIpi = {
            ...data.pedidoVendaItemImpostoIpi,
            calcular: false,
            tipoCalculo: null,
            baseCalculo: null,
            aliquota: null,
            quantidadeUnidade: null,
            valorUnidade: null,
            valor: null,
          };
        }

        if (id) {
          await PedidoVendaItemComunicador.update({ id, params: data });
        } else {
          const response = await PedidoVendaItemComunicador.store({
            idPai: idDetalheRegistroPrincipal,
            params: data,
          });
          id = response.id;
        }

        formPrincipal.handleCarregarDados();
        formRef?.atualizarDataInicial();
        ToastSucesso('Item Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleValidar,
      handleValidarAvisos,
      redirecionar,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        const tipoPedidoVenda =
          formPrincipal.formRef.current?.getFieldValue('tipoPedidoVenda');

        if (
          !liberacoes.permiteExcluirConsignado &&
          tipoPedidoVenda === TipoPedidoVendaEnum.consignado
        ) {
          ToastErro('Usuário sem permissão para excluir Consignado!');
          return;
        }

        setLoading(true);
        formPrincipal.setLoading(true);

        const dataPrincipal = formPrincipal.formRef.current?.getData();
        const { erro } = await formPrincipal.handleSubmit(dataPrincipal);
        if (erro) return;

        await PedidoVendaItemComunicador.delete({ id });

        ToastSucesso('Item Deletado!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
