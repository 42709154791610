import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/pedido-venda/impressao/retrato-com-financeiro-transporte';

interface IPedidoVendaRetratoComFinanceiroTransporteImpressaoComunicadorShow {
  id: string;
}

class PedidoVendaRetratoComFinanceiroTransporteImpressaoComunicador {
  public async show(
    params: IPedidoVendaRetratoComFinanceiroTransporteImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaRetratoComFinanceiroTransporteImpressaoComunicador();
