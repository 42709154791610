import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/cancelar';

interface INotaFiscalEntradaCancelarComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaCancelarComunicadorStore {
  id: string;
  params: any;
}

class NotaFiscalEntradaCancelarComunicador {
  public async show(
    params: INotaFiscalEntradaCancelarComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalEntradaCancelarComunicadorStore
  ): Promise<any> {
    const response = await api.post(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaCancelarComunicador();
