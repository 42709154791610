import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/grupo-produto-sidevin';

interface IGrupoProdutoSisdevinComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IGrupoProdutoSisdevinComunicadorShow {
  id: string;
}

interface IGrupoProdutoSisdevinComunicadorStore {
  params: any;
}

interface IGrupoProdutoSisdevinComunicadorUpdate {
  id: string;
  params: {
    descricao?: string;
    ativo?: boolean;
  };
}

interface IGrupoProdutoSisdevinComunicadorDelete {
  id: string;
}

class GrupoProdutoSisdevinComunicador {
  public async index(
    params: IGrupoProdutoSisdevinComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IGrupoProdutoSisdevinComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(
    params: IGrupoProdutoSisdevinComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(
    params: IGrupoProdutoSisdevinComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IGrupoProdutoSisdevinComunicadorDelete
  ): Promise<void> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new GrupoProdutoSisdevinComunicador();
