const EstoqueSaldoProprioTerceiroPorLocalEstoqueLotePlanilha = [
  { titulo: 'Local de Estoque', valor: 'chave' },
  { titulo: 'Lote', valor: 'listaLote.chave' },
  {
    titulo: 'Código',
    valor: 'listaLote.listaProduto.estoqueCombinacao.produto.codigo',
  },
  {
    titulo: 'Produto',
    valor: 'listaLote.listaProduto.estoqueCombinacao.produto.descricao',
  },
  {
    titulo: 'Unidade',
    valor:
      'listaLote.listaProduto.estoqueCombinacao.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Saldo',
    valor: 'listaLote.listaProduto.saldo',
  },
  {
    titulo: 'Tipo',
    valor: 'listaLote.listaProduto.estoqueCombinacao.estoqueIndicadorTipo',
  },
  {
    titulo: 'Terceiro',
    valor: 'listaLote.listaProduto.estoqueCombinacao.pessoa.nomeRazaoSocial',
  },
];

export default EstoqueSaldoProprioTerceiroPorLocalEstoqueLotePlanilha;
