import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  ConverterPixelParaPonto,
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IEtiquetaElementoImpressao,
  IEtiquetaElementoValoresInserir,
  IEtiquetaImpressao,
  IEtiquetaValoresInserir,
  TipoElementoEnum,
} from '@elogestor/util';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import EtiquetaImpressaoPDF from '../Impressao/EtiquetaImpressaoPDF';
import PDF from '../../../../../Util/Relatorios/PDF';
import { UseEtiquetaElemento } from '../Hooks/EtiquetaElementoHook';

const AcoesEtiqueta: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, handleSubmit, getIdDetalheRegistro, loading, setLoading } =
    UseForm();

  const { listaEtiquetaElemento } = UseEtiquetaElemento();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const handleVisualizar = useCallback(async () => {
    try {
      setLoading(true);

      const dataForm = formRef.current?.getData();
      const { erro } = await handleSubmit(dataForm);
      if (erro) return;

      const data = formRef.current?.getData() as IEtiquetaValoresInserir;
      const dataAtual = new Date();

      const dados: IEtiquetaImpressao = {
        dataAtual: FormatarDataParaPtBr(dataAtual),
        horaAtual: FormatarHoraParaPtBr(dataAtual),

        tipoPapel: data.tipoPapel,
        quantidadeEtiquetasLinha: data.quantidadeEtiquetasLinha,

        margemEsquerda: data.margemEsquerda,
        margemDireita: data.margemDireita,
        margemSuperior: data.margemSuperior,
        margemInferior: data.margemInferior,

        larguraEtiqueta: data.larguraEtiqueta,
        alturaEtiqueta: data.alturaEtiqueta,

        larguraTotal: data.larguraTotal,
        alturaTotal: data.alturaTotal,

        larguraEntreEtiquetas: data.larguraEntreEtiquetas,
        alturaEntreEtiquetas: data.alturaEntreEtiquetas,

        listaEtiqueta: [],
      };

      const listaEtiquetaElementoImpressao = listaEtiquetaElemento.map(
        (etiquetaElemento: IEtiquetaElementoValoresInserir) => {
          let texto = '';
          const produtoCodigo = '85010/C2';
          const arrayCodigo = produtoCodigo.split('/');
          switch (etiquetaElemento.etiquetaElementoDisponivel?.identificador) {
            case 'codigoBarras':
              texto = '123456789012';
              break;

            case 'codigoBarrasCodigoProduto':
              texto = 'PROD001';
              break;

            case 'codigoBarrasInicioCodigoProduto':
              texto = String(arrayCodigo[0]);
              break;

            case 'codigoBarrasChaveAcesso':
              texto = '43210930128919000126570010000004031613757922';
              break;

            case 'nomeRazaoSocialEmpresa':
              texto = 'CIA Sistemas Razão Social';
              break;

            case 'nomeFantasiaEmpresa':
              texto = 'CIA Sistemas';
              break;

            case 'codigoProduto':
              texto = 'PROD001';
              break;

            case 'descricaoProduto':
              texto = 'VINHO TINTO SECO FINO ENVELHECIDO EM BARIL DE CARVALHO';
              break;

            case 'valorUnitario':
              texto = '85.99';
              break;

            case 'siglaUn':
              texto = 'UN';
              break;

            case 'marca':
              texto = 'CIA';
              break;

            case 'modelo':
              texto = 'CIA 2021';
              break;

            case 'codigoBarrasProduto':
              texto = '123456789012';
              break;

            case 'numeroNota':
              texto = '1254';
              break;

            case 'serie':
              texto = '1';
              break;

            case 'DataHoraEmissao':
              texto = new Date('2021/01/01 14:15:00').toString();
              break;

            case 'chaveAcesso':
              texto = '43210930128919000126570010000004031613757922';
              break;

            case 'protocoloAutorizacao':
              texto = '943210000047041';
              break;

            case 'dataHoraAutorizacao':
              texto = new Date('2021/01/01 14:15:20').toString();
              break;

            case 'informacoesAdicionaisNotaFiscal':
              texto = '2 CAIXAS';
              break;

            case 'volumeXdeY':
              texto = 'Volume 1 de 2';
              break;

            case 'volumeXdeYPedido':
              texto = 'Volume 1 de 2';
              break;

            case 'nomeRazaoSocialCliente':
              texto = 'Restaurante Teste LTDA';
              break;

            case 'nomeFantasiaCliente':
              texto = 'Restaurante Teste';
              break;

            case 'cnpjCpfCliente':
              texto = '00532266000185';
              break;

            case 'inscricaoEstadualCliente':
              texto = '0835938158';
              break;

            case 'cepCliente':
              texto = '95050000';
              break;

            case 'cidadeCliente':
              texto = 'CAXIAS DO SUL';
              break;

            case 'ufCliente':
              texto = 'RS';
              break;

            case 'logradouroCliente':
              texto = 'AVENIDA BRASIL';
              break;

            case 'numeroCliente':
              texto = '1245';
              break;

            case 'bairroCliente':
              texto = 'JARDIM AMÉRICA';
              break;

            case 'complementoCliente':
              texto = 'SALA COMERCIAL';
              break;

            case 'telefoneCliente':
              texto = '54985632145';
              break;

            case 'emailCliente':
              texto = 'cliente@gmail.com';
              break;

            case 'nomeRazaoSocialTransportadora':
              texto = 'Transportadora Teste';
              break;

            case 'nomeRazaoSocialEmitente':
              texto = 'CIA Sistemas Razão Social';
              break;

            case 'nomeFantasiaEmitente':
              texto = 'CIA Sistemas';
              break;

            case 'cnpjCpfEmitente':
              texto = '64554026000164';
              break;

            case 'inscricaoEstadualEmitente':
              texto = '6220346410';
              break;

            case 'cepEmitente':
              texto = '95034000';
              break;

            case 'cidadeEmitente':
              texto = 'CAXIAS DO SUL';
              break;

            case 'ufEmitente':
              texto = 'RS';
              break;

            case 'logradouroEmitente':
              texto = 'RUA MOREIRA CÉSAR';
              break;

            case 'numeroEmitente':
              texto = '6895';
              break;

            case 'bairroEmitente':
              texto = 'PIO X';
              break;

            case 'complementoEmitente':
              texto = 'SALA 15';
              break;

            case 'telefoneEmitente':
              texto = '54987326451';
              break;

            case 'emaiEmitente':
              texto = 'emitente@gmail.com';
              break;

            case 'percentualDesconto':
              texto = '5';
              break;

            case 'valorUnitarioVendaProdutoComDesconto':
              texto = '105';
              break;

            case 'quantidadeMaximaPorVolumeCalculada':
              texto = '5';
              break;

            default:
              texto = String(etiquetaElemento.texto);
              break;
          }

          const retorno: IEtiquetaElementoImpressao = {
            index: etiquetaElemento.index,
            tipoElemento:
              etiquetaElemento.etiquetaElementoDisponivel?.tipoElemento ??
              TipoElementoEnum.campo,

            posicaoX: ConverterPixelParaPonto(
              etiquetaElemento.posicaoX
            ).Arredondar(0),
            posicaoY: ConverterPixelParaPonto(
              etiquetaElemento.posicaoY
            ).Arredondar(0),
            largura: ConverterPixelParaPonto(
              etiquetaElemento.largura
            ).Arredondar(0),
            altura: ConverterPixelParaPonto(etiquetaElemento.altura).Arredondar(
              0
            ),

            texto,
            fonte: etiquetaElemento.fonte,
            tamanhoFonte: etiquetaElemento.tamanhoFonte,

            negrito: etiquetaElemento.negrito,
            italico: etiquetaElemento.italico,
            sublinhado: etiquetaElemento.sublinhado,

            alinharEsquerda: etiquetaElemento.alinharEsquerda,
            alinharCentro: etiquetaElemento.alinharCentro,
            alinharDireita: etiquetaElemento.alinharDireita,

            justificarSuperior: etiquetaElemento.justificarSuperior,
            justificarCentro: etiquetaElemento.justificarCentro,
            justificarInferior: etiquetaElemento.justificarInferior,

            inverterCores: etiquetaElemento.inverterCores,

            espessuraBordaDireita: etiquetaElemento.espessuraBordaDireita,
            espessuraBordaEsquerda: etiquetaElemento.espessuraBordaEsquerda,
            espessuraBordaSuperior: etiquetaElemento.espessuraBordaSuperior,
            espessuraBordaInferior: etiquetaElemento.espessuraBordaInferior,
            espessura: etiquetaElemento.espessura,
            arredondamentoBorda: etiquetaElemento.arredondamentoBorda,

            casasDecimais: etiquetaElemento.casasDecimais,
            numeroDigitos: etiquetaElemento.numeroDigitos,
            tipoDataHora: etiquetaElemento.tipoDataHora,

            urlImagem: etiquetaElemento.urlImagem,
          };

          return retorno;
        }
      );

      if (dados.listaEtiqueta) {
        dados.listaEtiqueta.push({
          listaElemento: listaEtiquetaElementoImpressao,
        });
        dados.listaEtiqueta.push({
          listaElemento: listaEtiquetaElementoImpressao,
        });
        dados.listaEtiqueta.push({
          listaElemento: listaEtiquetaElementoImpressao,
        });
        dados.listaEtiqueta.push({
          listaElemento: listaEtiquetaElementoImpressao,
        });
      }

      const relatorio = await PDF({
        documento: <EtiquetaImpressaoPDF dados={dados} visualizarImpressao />,
      });

      relatorio.AbrirArquivo();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleSubmit, listaEtiquetaElemento, setLoading]);

  return (
    <SplitAcoes
      id={v4()}
      disabled={loading || !idRegistroDetalhe}
      className="btn-group"
      title={
        <div>
          <TextoLoading loading={loading}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item disabled={loading} onClick={handleVisualizar}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <CgNotes style={{ marginRight: 5 }} />
          Visualizar
        </span>
      </Dropdown.Item>
    </SplitAcoes>
  );
};

export default AcoesEtiqueta;
