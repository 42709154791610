import {} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteProduto from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimal from '../../../../../../../../../../Componentes/Inputs/InputDecimal';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';

const ItemProdutoTributadoTab: React.FC = () => {
  const {
    calcularQuantidadeTributadoProdutoTributado,
    calcularQuantidadeUnitariaTributadoProdutoTributado,
  } = UseItemTab();

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const handleCalcularQuantidadeTributadoProdutoTributado = useCallback(() => {
    calcularQuantidadeTributadoProdutoTributado();
  }, [calcularQuantidadeTributadoProdutoTributado]);

  const handleCalcularQuantidadeUnitariaTributadoProdutoTributado =
    useCallback(() => {
      calcularQuantidadeUnitariaTributadoProdutoTributado();
    }, [calcularQuantidadeUnitariaTributadoProdutoTributado]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={8} md={6} sm={12} className="div-checkbox">
            <InputAutoCompleteProduto
              label="Produto Tributado"
              name="idProdutoTributado"
              placeholder="Produto Tributado"
              nomeObjeto="produtoTributado"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="div-checkbox">
            <InputAutoCompleteUnidadeMedida
              label="Unidade Medida"
              name="idUnidadeMedidaTributado"
              placeholder="Unidade Medida"
              nomeObjeto="unidadeMedidaTributado"
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={12} sm={12}>
            <InputDecimal
              label="Qtde. Trib."
              name="quantidadeTributado"
              casasInteiras={11}
              casasDecimais={4}
              onChange={
                handleCalcularQuantidadeUnitariaTributadoProdutoTributado
              }
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <InputDecimal
              label="Qtde. Unit. Trib."
              name="quantidadeUnitariaTributado"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleCalcularQuantidadeTributadoProdutoTributado}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <InputDecimal
              label="Valor Unit. Trib."
              name="valorUnitarioTributado"
              casasInteiras={11}
              casasDecimais={10}
              disabled
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemProdutoTributadoTab;
