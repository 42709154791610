import { IOrdemServicoRetratoComSemValoresItensImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoOrdemServicoRetratoComValoresItensPDF from './OrdemServicoRetratoComValoresItensImpressaoPDF';
import ImpressaoOrdemServicoRetratoSemValoresItensPDF from './OrdemServicoRetratoSemValoresItensImpressaoPDF';

interface IImpressaoOrdemServicoRetratoComSemValoresItens {
  handleVisualizarPdf(
    visualizarComValores: boolean,
    data: IOrdemServicoRetratoComSemValoresItensImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    visualizarComValores: boolean,
    dados: IOrdemServicoRetratoComSemValoresItensImpressao
  ): Promise<Blob | undefined>;
}
const ImpressaoOrdemServicoRetratoComSemValoresItens =
  (): IImpressaoOrdemServicoRetratoComSemValoresItens => {
    async function handleVisualizarPdf(
      visualizarComValores: boolean,
      dados: IOrdemServicoRetratoComSemValoresItensImpressao
    ): Promise<void> {
      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrdemServicoRetratoComValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      } else {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrdemServicoRetratoSemValoresItensPDF dados={dados} />
          ),
        });

        relatorio.AbrirArquivo();
      }
    }

    async function handleObterBlobPdf(
      visualizarComValores: boolean,
      dados: IOrdemServicoRetratoComSemValoresItensImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoOrdemServicoRetratoComValoresItensPDF dados={dados} />
          ),
        });

        return relatorio.GetBlob();
      }

      const relatorio = await PDF({
        documento: (
          <ImpressaoOrdemServicoRetratoComValoresItensPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoOrdemServicoRetratoComSemValoresItens();
