import {
  ICentroCustoValoresAlterar,
  TipoSinteticaAnaliticaEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import React, { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CentroCustoComunicador from '../../../../../../Comunicador/Contabil/ContasContabeis/CentroCusto/Comunicador/CentroCustoComunicador';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import CentroCustoObterDadosPadraoComunicador from '../../../../../../Comunicador/Contabil/ContasContabeis/CentroCusto/Comunicador/CentroCustoObterDadosPadraoComunicador';
import { UseTreeContext } from '../../Hooks/TreeContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const { setIdShowItem, idShowItem } = UseTreeContext();
  const location = useLocation();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);
  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof ICentroCustoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        await formRef.current?.setDataDuplicar(dadosDuplicados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();

        if (dadosRecuperados.id === undefined) {
          dadosRecuperados.id = null;
        }

        await formRef.current?.setSemExecutarEvento(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar && idShowItem.IsNullOrEmpty()) return;

        setLoading(true);

        const response = await CentroCustoComunicador.show({
          id: idEditar || idShowItem,
        });

        await formRef.current?.setDataInicialSemExecutarEvento(response);

        SetarDadosObrigatorios();
        setLoading(false);
      }

      async function SetarDadosPadrao(): Promise<void> {
        setLoading(true);

        const valores = await CentroCustoObterDadosPadraoComunicador.show();

        await formRef.current?.setDataInicial({
          ...valores,
          ativo: true,
          tipoSinteticaAnalitica: TipoSinteticaAnaliticaEnum.analitica,
          ...dadosPadrao,
        });
        setLoading(false);

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar || !idShowItem.IsNullOrEmpty()) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await await SelecionarDadosIniciais();
    },
    [getIdDetalheRegistro, handleSetarFocus, idShowItem, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        descricao: Yup.string()
          .nullable()
          .required('A Descrição é obrigatória!'),
        codigoAtual: Yup.string().nullable().required('A Código é obrigatório'),
        codigo: Yup.string()
          .nullable()
          .required('O Código Completo é obrigatório!'),
        tipoSinteticaAnalitica: Yup.mixed()
          .oneOf(
            Object.values(TipoSinteticaAnaliticaEnum),
            'O Tipo de Conta é obrigatório!'
          )
          .required('O Tipo de Conta é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);

      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        let id = formRef.current?.getFieldValue('id');

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await CentroCustoComunicador.update({
            id,
            params: data,
          });
        } else {
          if (data.id === null) {
            data.id = undefined;
          }

          const response = await CentroCustoComunicador.store({
            params: data,
          });

          setIdDetalheRegistro(response.id);
          id = response.id;
        }

        if (location.state) {
          delete (location.state as any).dadosRecuperados;
        }

        await formRef.current?.atualizarDataInicial();
        setIdShowItem({ id });

        ToastSucesso('Centro de Custo Salvo!');
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        return { id: '', erro: true };
      } finally {
        setLoading(false);
      }
    },
    [
      handleValidar,
      location.state,
      redirecionar,
      setIdDetalheRegistro,
      setIdShowItem,
    ]
  );

  // const idDetalhe = idDetalheRegistro.current;
  // const isFirstRender = useRef(true);

  // Deixar comentado, porque pode ser que possa vir a necessitar, porém, agora não sabemos a utilização

  // useEffect(() => {
  //   setIdShowItem({ id: idDetalhe || '' });
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return;
  //   }

  //   // validar alteração
  //   const dataRecuperarFormulario =
  //     formRef.current?.getDataRecuperarFormulario();

  //   if (formRef.current?.validarSeAlterou()) {
  //     navigate(location.pathname, {
  //       state: {
  //         dadosRecuperados: dataRecuperarFormulario,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setIdShowItem, idDetalhe]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        handleCarregarDados,
        handleSubmit,
        handleValidar,
        loading,
        refresh,
        setIdDetalheRegistro,
        setLoading,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
