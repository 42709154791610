import React, { useState, useEffect, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { IReprocessamentoProducaoCustoRetiradaLista } from '@elogestor/util';
import { v4 } from 'uuid';
import Divisor from '../../../../../../Componentes/Divisor';
import InputHidden from '../../../../../../Componentes/Inputs/InputHidden';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { Tabela } from '../../../../../../Componentes/Styles/Tabela';

const CustosRetiradaTab: React.FC = () => {
  const formPrincipal = UseForm();
  const [listaDados, setListaDados] = useState<
    IReprocessamentoProducaoCustoRetiradaLista[]
  >([]);
  InputHidden({ name: 'listaCustosRetirada' });
  const listaCustos: IReprocessamentoProducaoCustoRetiradaLista[] =
    formPrincipal.formRef.current?.getFieldValue('listaCustosRetirada');

  const handleCarregarDados = useCallback((lista: any[]) => {
    if (!lista) setListaDados([]);
    else setListaDados(lista);
  }, []);

  useEffect(() => {
    if (listaCustos) {
      handleCarregarDados(listaCustos);
    }
  }, [handleCarregarDados, listaCustos]);

  return (
    <div>
      <Divisor>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto" style={{ width: '20%' }}>
                  <span>Regime Tributario</span>
                </th>
                <th className="lista-texto" style={{ width: '15%' }}>
                  <span>Tipo Custo</span>
                </th>
                <th className="lista-valor" style={{ width: '10%' }}>
                  <span>Quantidade</span>
                </th>
                <th className="lista-valor" style={{ width: '10%' }}>
                  <span>Valor Unit. Custo</span>
                </th>
                <th className="lista-valor" style={{ width: '10%' }}>
                  <span>Valor Tot. Custo</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {listaDados.length > 0 &&
                listaDados.map((item) => {
                  return (
                    <tr key={v4()}>
                      <td className="lista-texto">{item.regimeTributario}</td>
                      <td className="lista-texto">{item.tipoCusto}</td>
                      <td className="lista-valor">{item.quantidade}</td>
                      <td className="lista-valor">{item.valorUnitarioCusto}</td>
                      <td className="lista-valor">{item.valorTotalCusto}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>
    </div>
  );
};

export default CustosRetiradaTab;
