/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { format } from 'date-fns';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputDateTimeProps {
  dateValorAnterior: string;
  timeValorAnterior: string;
}

export interface IInputDateTimeRef {
  focus(): void;
  setErro(error: string): void;
  value(): Date | null;
  getValueFormatado(): string | null;
  getData(): Date | null;
  getHora(): string | null;
  setData(valor: Date | null): void;
  setHora(valor: string | null): void;
}

interface IInputDateTimeProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  dataAtualPadrao?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateTimeProps
  ) => void | Promise<void>;
  onChangeData?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateTimeProps
  ) => void | Promise<void>;
  onChangeHora?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDateTimeProps
  ) => void | Promise<void>;
}

const InputDateTime: React.ForwardRefRenderFunction<
  IInputDateTimeRef,
  IInputDateTimeProps
> = (
  {
    name,
    label,
    dataAtualPadrao,
    onChange,
    onChangeData,
    onChangeHora,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputDateRef = useRef<HTMLInputElement>(null);
  const inputTimeRef = useRef<HTMLInputElement>(null);
  const inputDateValorAnterior = useRef('');
  const inputTimeValorAnterior = useRef('');

  useEffect(() => {
    inputDateValorAnterior.current = inputDateRef.current?.value || '';
    inputTimeValorAnterior.current = inputTimeRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    if (dataAtualPadrao) {
      if (inputDateRef.current && inputTimeRef.current) {
        const data = new Date();
        inputDateRef.current.value = format(data, 'yyyy-MM-dd');
        inputTimeRef.current.value = format(data, 'HH:mm');
      }
    }
  }, [dataAtualPadrao]);

  const handleInputFocus = useCallback(() => {
    inputDateValorAnterior.current = inputDateRef.current?.value || '';
    inputTimeValorAnterior.current = inputTimeRef.current?.value || '';

    setIsFocused(true);
  }, []);

  const handleInputChangeDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      if (onChangeData)
        onChangeData(event, {
          dateValorAnterior: inputDateValorAnterior.current,
          timeValorAnterior: inputTimeValorAnterior.current,
        });

      if (onChange)
        onChange(event, {
          dateValorAnterior: inputDateValorAnterior.current,
          timeValorAnterior: inputTimeValorAnterior.current,
        });
    },
    [onChange, onChangeData]
  );

  const handleInputChangeTime = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      if (onChangeHora)
        onChangeHora(event, {
          dateValorAnterior: inputDateValorAnterior.current,
          timeValorAnterior: inputTimeValorAnterior.current,
        });

      if (onChange)
        onChange(event, {
          dateValorAnterior: inputDateValorAnterior.current,
          timeValorAnterior: inputTimeValorAnterior.current,
        });
    },
    [onChange, onChangeHora]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsFilled(!!event.target.value);
    },
    []
  );

  const getData = useCallback(() => {
    if (!inputDateRef.current?.value) return null;
    const data = new Date(inputDateRef.current.value);
    return data;
  }, []);

  const getHora = useCallback(() => {
    if (!inputTimeRef.current?.value) return null;

    const hora = inputTimeRef.current.value;
    return hora;
  }, []);

  const setData = useCallback((data: Date | null) => {
    if (!inputDateRef.current) return;
    if (!data) {
      inputDateRef.current.value = '';
    } else {
      inputDateRef.current.value = format(data, 'yyyy-MM-dd');
    }
  }, []);

  const setHora = useCallback((hora: string | null) => {
    if (!inputTimeRef.current) return;
    if (!hora) {
      inputTimeRef.current.value = '';
    } else {
      inputTimeRef.current.value = hora;
    }
  }, []);

  const getValor = useCallback(() => {
    if (!inputDateRef.current?.value) {
      return null;
    }

    let hora = '00:00';
    if (inputTimeRef.current?.value) {
      hora = inputTimeRef.current.value;
    }

    const data = `${inputDateRef.current.value} ${hora}`.replace(/-/g, '/');
    const timestamp = Date.parse(data);
    if (isNaN(timestamp) === false) {
      return new Date(data);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registerField<Date | null | string>({
      name: fieldName,
      getValue: getValor,

      setValue(_, valor = '') {
        if (!valor || !inputDateRef.current || !inputTimeRef.current) return;

        const data = new Date(valor);

        inputDateValorAnterior.current = inputDateRef.current.value;
        inputTimeValorAnterior.current = inputTimeRef.current.value;
        inputDateRef.current.value = format(data, 'yyyy-MM-dd');
        inputTimeRef.current.value = format(data, 'HH:mm');
      },

      setSemExecutarEvento(_, valor = '') {
        if (!valor || !inputDateRef.current || !inputTimeRef.current) return;

        const data = new Date(valor);

        inputDateValorAnterior.current = inputDateRef.current.value;
        inputTimeValorAnterior.current = inputTimeRef.current.value;
        inputDateRef.current.value = format(data, 'yyyy-MM-dd');
        inputTimeRef.current.value = format(data, 'HH:mm');
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_)?.toString() !== valorInicial?.toString();
      },

      setDisabled(valor) {
        if (!inputDateRef.current || !inputTimeRef.current) return;

        inputDateRef.current.disabled = valor;
        inputTimeRef.current.disabled = valor;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      inputDateRef.current?.focus();
    },

    value() {
      return getValor();
    },

    getValueFormatado() {
      const data = getValor();
      if (!data) return null;
      const dataFormatada = format(data, 'dd/MM/yyyy HH:mm');
      return dataFormatada;
    },

    getData() {
      return getData();
    },

    getHora() {
      return getHora();
    },

    setData(valor) {
      setData(valor);
    },

    setHora(valor) {
      setHora(valor);
    },

    setErro(error: string) {
      setErro(error);
    },
  }));

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="date"
          onFocus={handleInputFocus}
          onChange={handleInputChangeDate}
          onBlur={handleInputBlur}
          style={{
            marginRight: 10,
            width: '70%',
            minWidth: 155,
          }}
          {...rest}
          ref={inputDateRef}
        />
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="time"
          onChange={handleInputChangeTime}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          style={{
            width: '20%',
            minWidth: 90,
          }}
          {...rest}
          ref={inputTimeRef}
        />
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputDateTime);
