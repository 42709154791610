import { IPedidoVendaRetratoComSemValoresItensTranspFotoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoVendaRetratoComValoresItensTranspFotoPDF from './PedidoVendaRetratoComValoresItensTranspFotoImpressaoPDF';
import ImpressaoPedidoVendaRetratoSemValoresItensTranspFotoPDF from './PedidoVendaRetratoSemValoresItensTranspFotoImpressaoPDF';

interface IImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto {
  handleVisualizarPdf(
    visualizarComValores: boolean,
    data: IPedidoVendaRetratoComSemValoresItensTranspFotoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    visualizarComValores: boolean,
    dados: IPedidoVendaRetratoComSemValoresItensTranspFotoImpressao
  ): Promise<Blob | undefined>;
}
const ImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto =
  (): IImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto => {
    async function handleVisualizarPdf(
      visualizarComValores: boolean,
      dados: IPedidoVendaRetratoComSemValoresItensTranspFotoImpressao
    ): Promise<void> {
      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoComValoresItensTranspFotoPDF
              dados={dados}
            />
          ),
        });

        relatorio.AbrirArquivo();
      } else {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoSemValoresItensTranspFotoPDF
              dados={dados}
            />
          ),
        });

        relatorio.AbrirArquivo();
      }
    }

    async function handleObterBlobPdf(
      visualizarComValores: boolean,
      dados: IPedidoVendaRetratoComSemValoresItensTranspFotoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      if (visualizarComValores) {
        const relatorio = await PDF({
          documento: (
            <ImpressaoPedidoVendaRetratoComValoresItensTranspFotoPDF
              dados={dados}
            />
          ),
        });

        return relatorio.GetBlob();
      }

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoVendaRetratoComValoresItensTranspFotoPDF
            dados={dados}
          />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto();
