import React, { useCallback, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import {
  FormatarEnum,
  TipoIntermedioImportacaoEnum,
  TipoTransporteImportacaoEnum,
} from '@elogestor/util';
import JanelaDetalhe from '../../../../../../../../../../Componentes/JanelaDetalhe';
import LoadingDiv from '../../../../../../../../../../Componentes/LoadingDiv';
import TextoLoading from '../../../../../../../../../../Componentes/TextoLoading';
import { UsePermissoes } from '../../../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteEstado from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import Select from '../../../../../../../../../../Componentes/Select';
import InputCnpj from '../../../../../../../../../../Componentes/Inputs/InputCnpj';
import DeclaracaoImportacaoAdicaoHook from './ListaDetalheDeclaracaoImportacaoAdicao/Hooks';
import ListaDetalheDeclaracaoImportacaoAdicao from './ListaDetalheDeclaracaoImportacaoAdicao/Lista';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';

interface IContaParcelaLiquidacaoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const ContaParcelaLiquidacaoDetalhe: React.FC<IContaParcelaLiquidacaoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();

  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;

  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataDuplicar();

    data.idEstadoDesembaraco = data.estadoDesembaraco
      ? data.estadoDesembaraco.id
      : null;

    data.idEstadoTerceiro = data.estadoTerceiro ? data.estadoTerceiro.id : null;

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    refresh();

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal, refresh]);

  return (
    <JanelaDetalhe
      titulo="Declaração de Importação"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <InputHiddenHtml name="id" />
          <Container>
            <Divisor>
              <Row>
                <Col lg={4} md={3} sm={3}>
                  <Input
                    ref={inputRefFocus}
                    label="Número da DI"
                    name="numeroDeclaracaoImportacao"
                    placeholder="Número da Declaração de Importação"
                    max={12}
                  />
                </Col>

                <Col lg={4} md={3} sm={3}>
                  <InputDate
                    label="Data da DI"
                    name="dataDeclaracaoImportacao"
                  />
                </Col>

                <Col lg={4} md={3} sm={3}>
                  <InputDate
                    label="Data do Desembaraço"
                    name="dataDesembaraco"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12}>
                  <InputAutoCompleteEstado
                    label="UF do Desembaraço"
                    placeholder="Estado Desembaraço"
                    name="idEstadoDesembaraco"
                    nomeObjeto="estadoDesembaraco"
                  />
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <Input
                    label="Local do Desembaraço"
                    placeholder="Local do Desembaraço"
                    name="localDesembaraco"
                    max={60}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={6}>
                  <Select
                    label="Tipo de Transporte"
                    name="tipoTransporte"
                    options={FormatarEnum({
                      enumObj: TipoTransporteImportacaoEnum,
                    })}
                  />
                </Col>

                <Col lg={3} md={6} sm={6}>
                  <Select
                    label="Forma Imp. (Tipo Intermediário)"
                    name="tipoIntermedio"
                    options={FormatarEnum({
                      enumObj: TipoIntermedioImportacaoEnum,
                    })}
                  />
                </Col>

                <Col lg={3} md={6} sm={6}>
                  <InputDecimalNulavel
                    label="Valor AFRMM"
                    name="valorAFRMM"
                    placeholder="Valor AFRMM"
                    casasDecimais={2}
                    casasInteiras={15}
                  />
                </Col>

                <Col lg={3} md={6} sm={6}>
                  <Input
                    label="Código Exportador"
                    name="codigoExportador"
                    placeholder="Valor AFRMM"
                    maxLength={60}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12}>
                  <InputAutoCompleteEstado
                    label="UF do Entermediário"
                    placeholder="Estado Entermediário"
                    name="idEstadoTerceiro"
                    nomeObjeto="estadoTerceiro"
                  />
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <InputCnpj
                    label="CNPJ Adquirinte"
                    placeholder="CNPJ Adquirinte"
                    name="cnpjAdquirinte"
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <DeclaracaoImportacaoAdicaoHook>
                <ListaDetalheDeclaracaoImportacaoAdicao name="listaNotaFiscalEntradaItemDeclaracaoImportacaoAdicao" />
              </DeclaracaoImportacaoAdicaoHook>
            </Divisor>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ContaParcelaLiquidacaoDetalhe;
