import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IContaListaParcelasContext {
  validador: boolean;

  listaValor: any[];
  setListaValor(lista: any[]): void;
  setListaContaParcela(lista: string[]): String[];
}

const ContaListaParcelasContext = createContext<IContaListaParcelasContext>(
  {} as IContaListaParcelasContext
);

const ContaListaParcelasHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaValor, setListaValor] = useState<any>([]);

  const handleSetListaContaParcela = useCallback(
    (lista: string[]): string[] => {
      const listaDados = [...lista];
      setListaValor(lista);
      return listaDados;
    },
    []
  );

  return (
    <ContaListaParcelasContext.Provider
      value={{
        listaValor,
        setListaValor,
        setListaContaParcela: handleSetListaContaParcela,
        validador: true,
      }}
    >
      {children}
    </ContaListaParcelasContext.Provider>
  );
};

function UseContaListaParcelas(): Omit<
  IContaListaParcelasContext,
  'validador'
> {
  const context = useContext(ContaListaParcelasContext);

  if (!context.validador) {
    throw new Error(
      'UseContaParcelasLista deve ser usado com um ContaParcelasListaHook'
    );
  }

  return context;
}

export { ContaListaParcelasHook, UseContaListaParcelas };
