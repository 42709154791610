import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/pesagens/pesagem/impressao/ticket-80mm';

interface IPesagemTicket80mmImpressaoComunicadorShow {
  id: string;
}

class PesagemTicket80mmImpressaoComunicador {
  public async show(
    params: IPesagemTicket80mmImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PesagemTicket80mmImpressaoComunicador();
