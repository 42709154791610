import { UseFieldCia } from '@elogestor/unformcia';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  IoMdAddCircleOutline,
  IoMdRemoveCircleOutline,
} from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { ICaracteristicaProdutoItemLista } from '@elogestor/util';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRecuperarFormulario } from '../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import { UseReactSizeMeBodyHook } from '../../../../../../../Hooks/ReactSizeMeBodyHook';
import Detalhe from '../Detalhe';

interface IListaDetalheCaracteristicaProdutoItemProps {
  name: string;
}

const CaracteristicaprodutoItemLista: React.FC<
  IListaDetalheCaracteristicaProdutoItemProps
> = ({ name }) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { permissoes } = UsePermissoes();
  const { ManufaturaConfiguradorConjuntoVariaveis: permissao } = permissoes;
  const formPrincipal = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const valorRef = useRef<ICaracteristicaProdutoItemLista[]>([]);
  const {
    formRefDetalhe,
    loading,
    setIdDetalheRegistro,
    handleExcluir,
    listaValor,
    setListaValor,
  } = UseListaDetalheForm<ICaracteristicaProdutoItemLista>();
  const [showFormModal, setShowFormModal] = useState(false);

  const handleAdicionar = useCallback(async (): Promise<void> => {
    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (id: string) => {
      setIdDetalheRegistro(id);
      setShowFormModal(true);
    },
    [setIdDetalheRegistro]
  );

  const handleSalvarFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  useEffect(() => {
    valorRef.current = listaValor || [];
  }, [listaValor]);

  useEffect(() => {
    registerField<ICaracteristicaProdutoItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor?.([]);
        else setListaValor?.(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor?.([]);
        else setListaValor?.(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            style={{ maxWidth: telaGrande ? 250 : '' }}
            type="button"
            className="btn-padrao btn-verde-claro btn-adicionar"
            onClick={handleAdicionar}
            disabled={!permissao?.inclui || formPrincipal.loading || loading}
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-texto" style={{ width: '50%' }}>
                <span>Característica</span>
              </th>
              <th className="lista-booleano" style={{ width: '30%' }}>
                <span>Característica Pai</span>
              </th>
              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <TBody>
            {listaValor?.map((item, index) => {
              return (
                <tr
                  key={v4()}
                  onClick={async () => {
                    if (formPrincipal.loading || loading) return;
                    handleEditar(String(index));
                  }}
                  className={
                    formPrincipal.loading || loading
                      ? 'tabela-desabilitada'
                      : ''
                  }
                >
                  <td className="lista-texto">
                    <div>{item.caracteristica.descricao}</div>
                  </td>
                  <td className="lista-texto">
                    <div>{item.caracteristicaPai?.descricao}</div>
                  </td>

                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={() => {
                            handleExcluir(String(index));
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <IoMdRemoveCircleOutline
                              style={{ marginRight: 5 }}
                            />
                            Remover
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </TBody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
        />
      )}
    </>
  );
};

export default CaracteristicaprodutoItemLista;
