import React from 'react';
import IPadraoProps from '../../../../../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';

const OrdemServicoItemEstoqueLocalEstoqueHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return <FormHook>{children}</FormHook>;
};

export default OrdemServicoItemEstoqueLocalEstoqueHook;
