const NotaFiscalEntradaResumoPorCfopPlanilha = [
  { titulo: 'CFOP', valor: 'chave' },
  // { titulo: 'CFOP', valor: 'operacaoFiscal.cfop' },
  { titulo: 'Série', valor: 'listaDocumentosEntrada.numero' },
  { titulo: 'Numero', valor: 'listaDocumentosEntrada.serie' },
  {
    titulo: 'Código',
    valor: 'listaDocumentosEntrada.documentosEntradaPessoa.pessoa.codigo',
  },
  {
    titulo: 'Nome',
    valor:
      'listaDocumentosEntrada.documentosEntradaPessoa.pessoa.nomeRazaoSocial',
  },
  {
    titulo: 'Valor Produtos',
    valor: 'listaDocumentosEntrada.valorTotalProduto',
  },
  { titulo: 'Valor ICMS', valor: 'listaDocumentosEntrada.valorIcms' },
  { titulo: 'Valor IPI', valor: 'listaDocumentosEntrada.valorIpi' },
  {
    titulo: 'Valor Subst. Trib',
    valor: 'listaDocumentosEntrada.valorSubstituicaoTributaria',
  },
  {
    titulo: 'Valor Frete',
    valor: 'listaDocumentosEntrada.valorFrete',
  },
  { titulo: 'Valor Desconto', valor: 'listaDocumentosEntrada.valorDesconto' },
  {
    titulo: 'Valor Outras Despesas',
    valor: 'listaDocumentosEntrada.valorOutrasDespesa',
  },
  {
    titulo: 'Valor Total Item',
    valor: 'listaDocumentosEntrada.valorTotalItem',
  },
];

export default NotaFiscalEntradaResumoPorCfopPlanilha;
