const CurvaABCNotaFiscalSaidaEstadorPorProdutoPorQuantidadePlanilha = [
  {
    titulo: 'Grupo',
    valor: 'valores.grupoDescricao',
  },
  {
    titulo: 'Sequencia',
    valor: 'listaProduto.sequenciaProduto',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaProduto.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaProduto.produtoDescricao',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaProduto.unidadeMedida',
  },

  {
    titulo: 'Quantidade do Produto',
    valor: 'listaProduto.quantidadeProduto',
  },
  {
    titulo: 'Perc(%)',
    valor: 'listaProduto.percentualTotalGeral',
  },
  {
    titulo: 'Perc(%) Acum',
    valor: 'listaProduto.percentualAcumuladoTotalGeral',
  },
  {
    titulo: 'Perc(%) Estado',
    valor: 'listaProduto.percentualEstado',
  },
  {
    titulo: 'Perc(%) Acum Estado',
    valor: 'listaProduto.percentualAcumuladoEstado',
  },
];

export default CurvaABCNotaFiscalSaidaEstadorPorProdutoPorQuantidadePlanilha;
