"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDocumentoManutencaoDocumentoEnum;
(function (TipoDocumentoManutencaoDocumentoEnum) {
    TipoDocumentoManutencaoDocumentoEnum["notaFiscalTerceiros"] = "Nota fiscal de terceiros";
    TipoDocumentoManutencaoDocumentoEnum["notaFiscalPropriaEntrada"] = "Nota fiscal pr\u00F3pria de Entrada";
    TipoDocumentoManutencaoDocumentoEnum["notaFiscalPropriaSaida"] = "Nota fiscal pr\u00F3pria de Sa\u00EDda";
    TipoDocumentoManutencaoDocumentoEnum["notaFiscalConsumidorPropria"] = "Nota fiscal de consumidor pr\u00F3pria";
    TipoDocumentoManutencaoDocumentoEnum["conhecimentoTransporte"] = "Conhecimento de transporte";
    TipoDocumentoManutencaoDocumentoEnum["manifestoTransporte"] = "Manifesto de transporte";
})(TipoDocumentoManutencaoDocumentoEnum || (TipoDocumentoManutencaoDocumentoEnum = {}));
exports.default = TipoDocumentoManutencaoDocumentoEnum;
