"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PisCofinsRetidoCalculo;
function PisCofinsRetidoCalculo(dados) {
    const retorno = Object.assign({}, dados);
    const baseCalculoPisCofinsRetidoCalculada = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
        Number(dados.valorFreteItem) +
        Number(dados.valorSeguroItem) +
        Number(dados.valorOutrasDespesasItem) -
        Number(dados.valorDescontoItem) -
        Number(dados.valorIcms) -
        Number(dados.estadoDestinoValorIcmsSemFcp);
    retorno.baseCalculoRetido = Number(dados.baseCalculoRetido);
    retorno.aliquotaRetido = Number(dados.aliquotaRetido);
    retorno.valorRetido = Number(dados.valorRetido);
    if (dados.calcularPisCofinsRetido) {
        retorno.baseCalculoRetido =
            baseCalculoPisCofinsRetidoCalculada.Arredondar();
        const valorPercentual = retorno.baseCalculoRetido.CalcularPercentual(retorno.aliquotaRetido);
        retorno.valorRetido = valorPercentual.Arredondar();
        if (retorno.aliquotaRetido === 0 && retorno.valorRetido === 0)
            retorno.baseCalculoRetido = 0;
        if (retorno.baseCalculoRetido === 0 && retorno.valorRetido === 0)
            retorno.aliquotaRetido = 0;
    }
    return retorno;
}
