import { IConhecimentoTransporteImpressao } from '@elogestor/util';
import React from 'react';
import DownloadArquivo from '../../../../Util/Arquivos/DownloadArquivo';
import PDF from '../../../../Util/Relatorios/PDF';
import ImpressaoConhecimentoTransporteModalRodoviarioPDF from './ConhecimentoTransporteImpressaoModalRodoviarioPDF';

interface IImpressaoConhecimentoTransporteModalRodoviario {
  handleExportarXml(
    dados: IConhecimentoTransporteImpressao,
    nomeArquivo: string
  ): Promise<void>;
  handleVisualizarPdf(dados: IConhecimentoTransporteImpressao): Promise<void>;
  handleObterBlobPdf(
    dados: IConhecimentoTransporteImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoConhecimentoTransporteModalRodoviario =
  (): IImpressaoConhecimentoTransporteModalRodoviario => {
    async function handleExportarXml(
      dados: IConhecimentoTransporteImpressao,
      nomeArquivo: string
    ): Promise<void> {
      const utf8Content = new TextEncoder().encode(dados as any);
      const blob = new Blob([utf8Content], { type: 'text/xml;charset=UTF-8' });

      DownloadArquivo(blob, `${nomeArquivo}.xml`);
    }

    async function handleVisualizarPdf(
      dados: IConhecimentoTransporteImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoConhecimentoTransporteModalRodoviarioPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IConhecimentoTransporteImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoConhecimentoTransporteModalRodoviarioPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleExportarXml, handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoConhecimentoTransporteModalRodoviario();
