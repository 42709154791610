const CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha = [
  { titulo: 'Sequência', valor: 'sequencia' },
  { titulo: 'Unidade', valor: 'unidadeMedida' },
  { titulo: 'Descrição Produto', valor: 'descricaoProduto' },
  { titulo: 'Código', valor: 'codigo' },
  { titulo: 'Valor', valor: 'valor' },
  { titulo: 'Perc(%)', valor: 'percentual' },
  { titulo: 'Perc(%) Acum', valor: 'percentualAcumulado' },
];

export default CurvaABCNotaFiscalEntradaProdutoPorValorPlanilha;
