import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoContatoEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompletePessoaContato from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoaContato';
import ListaDetalheComercialRepresentante from './ListaDetalheComercialRepresentante/Lista';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import OrcamentoComercialRepresentanteHook from './ListaDetalheComercialRepresentante/Hooks';

const InformacaoComercialTab: React.FC = () => {
  const { formRef } = UseForm();
  const idPessoa = formRef.current?.getFieldValue('orcamentoPessoa.idPessoa');

  useEffect(() => {
    if (!idPessoa) {
      formRef.current?.clearField('orcamentoComercial.pessoaContato', {
        setValorInicial: false,
      });
    }
  }, [idPessoa, formRef]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompletePessoaContato
              label="Contato"
              name="orcamentoComercial.idPessoaContato"
              placeholder="Contato"
              nomeObjeto="orcamentoComercial.pessoaContato"
              idPessoa={idPessoa}
              disabled={!idPessoa}
              tipoContato={TipoContatoEnum.orcamento}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <OrcamentoComercialRepresentanteHook>
              <ListaDetalheComercialRepresentante name="orcamentoComercial.listaOrcamentoComercialRepresentante" />
            </OrcamentoComercialRepresentanteHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default InformacaoComercialTab;
