import React, { createContext, useCallback, useContext, useState } from 'react';
import { IPedidoVendaItemEstoqueLocalEstoqueLista } from '@elogestor/util';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemPedidoVendaContext {
  validador: boolean;

  produtoControlaLote: boolean;
  setProdutoControlaLote(controleLote: boolean): void;

  listaLocalEstoque: IPedidoVendaItemEstoqueLocalEstoqueLista[];
  setListaLocalEstoque(lista: IPedidoVendaItemEstoqueLocalEstoqueLista[]): void;
  adicionarLocalEstoque(valor: IPedidoVendaItemEstoqueLocalEstoqueLista): void;
  removerLocalEstoque(index: string): void;
}

const ItemPedidoVendaContext = createContext<IItemPedidoVendaContext>(
  {} as IItemPedidoVendaContext
);

const ItemPedidoVendaHook: React.FC<IPadraoProps> = ({ children }) => {
  const [produtoControlaLote, setProdutoControlaLote] = useState(false);

  const [listaLocalEstoque, setListaLocalEstoque] = useState<
    IPedidoVendaItemEstoqueLocalEstoqueLista[]
  >([]);

  const handleSetProdutoControlaLote = useCallback(
    async (controlaLote: boolean) => {
      setProdutoControlaLote(controlaLote);
    },
    []
  );

  const handleSetListaLocalEstoque = useCallback(
    async (lista: IPedidoVendaItemEstoqueLocalEstoqueLista[]) => {
      setListaLocalEstoque(lista);
    },
    []
  );

  const handleAdicionarLocalEstoque = useCallback(
    async (valor: IPedidoVendaItemEstoqueLocalEstoqueLista) => {
      setListaLocalEstoque((state) => {
        return [...state, valor];
      });
    },
    []
  );

  const handleRemoverLocalEstoque = useCallback(
    async (index: string) => {
      listaLocalEstoque.splice(Number(index), 1);

      const novaLista = listaLocalEstoque;

      setListaLocalEstoque([]);
      setListaLocalEstoque(novaLista);
    },
    [listaLocalEstoque]
  );

  return (
    <ItemPedidoVendaContext.Provider
      value={{
        produtoControlaLote,
        setProdutoControlaLote: handleSetProdutoControlaLote,

        listaLocalEstoque,
        setListaLocalEstoque: handleSetListaLocalEstoque,
        adicionarLocalEstoque: handleAdicionarLocalEstoque,
        removerLocalEstoque: handleRemoverLocalEstoque,

        validador: true,
      }}
    >
      {children}
    </ItemPedidoVendaContext.Provider>
  );
};

function UseItemPedidoVenda(): Omit<IItemPedidoVendaContext, 'validador'> {
  const context = useContext(ItemPedidoVendaContext);

  if (!context.validador) {
    throw new Error(
      'UseItemPedidoVenda deve ser usado com um ItemPedidoVendaHook'
    );
  }

  return context;
}

export { ItemPedidoVendaHook, UseItemPedidoVenda };
