import React from 'react';
import { Button } from './styles';

type IBtnDeletar = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const BtnDeletar: React.FC<IBtnDeletar> = ({ children, ...rest }) => {
  return (
    <Button type="button" {...(rest as any)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </Button>
  );
};

export default BtnDeletar;
