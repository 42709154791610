import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  IOrcamentoValoresAlterar,
  FormatarEnum,
  FinalidadeEmissaoEnum,
  IndicadorPresencaPadraoEnum,
  ModeloDocumentoTipoEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import TotalTab from '../TotaisTab/index';
import TransporteTab from '../TransporteTab/index';
import InformacaoComercialTab from '../InformacaoComercialTab/index';
import ObservacaoComercialTab from '../ObservacaoComercialTab/index';
import ObservacaoInternaTab from '../ObservacaoInternaTab/index';
import ItemTab from '../ItemTab/index';
import InputAutoCompleteCliente from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputAutoCompleteModeloDocumento from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteModeloDocumento';
import Select from '../../../../../../Componentes/Select';
import PessoaTab from '../PessoaTab';
import { UseOrcamento } from '../Hook/OrcamentoHook';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';

type IProps = IMain<IOrcamentoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasOrcamento: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { atualizarDadosClienteConformeCadastro, obterDadosPadraoCliente } =
    UseOrcamento();
  const { setRegimeTributarioPorData } = UseRegimeTributario();
  const { abrirJanela } = UseAlerta();

  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [tabSelecionada, setTabSelecionada] = useState('itens');
  const [idClienteAnterior, setIdClienteAnterior] = useState('');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    formRef,
    handleCarregarDados,
    idEditar,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarDadosClienteConformeCadastro = useCallback(async () => {
    atualizarDadosClienteConformeCadastro();
  }, [atualizarDadosClienteConformeCadastro]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    obterDadosPadraoCliente();
  }, [obterDadosPadraoCliente]);

  const handleOnAlterarDataHoraEmissao = useCallback(async () => {
    try {
      setLoading(true);

      const dataHoraEmissao = formRef.current?.getFieldValue('f');
      await setRegimeTributarioPorData(new Date(dataHoraEmissao));

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading, setRegimeTributarioPorData]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaOrcamentoItem =
      formRef.current?.getFieldValue('listaOrcamentoItem');

    if (listaOrcamentoItem && listaOrcamentoItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração do Cliente ou Indicador de Presença, as
            informações tributárias e valores podem estar incorretos. Salve para
            atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <InputHiddenHtml name="atualizarTabelaPrecoItens" />
        <InputHiddenHtml name="financeiroConferido" />
        <InputHiddenHtml name="valorFinanceiro" />

        <Divisor>
          <Row>
            <InputHiddenHtml name="situacaoOrcamentoPedidoVenda" />
            <InputHiddenHtml name="situacaoOrcamentoOrdemServico" />

            <Col lg={2} md={6} sm={12}>
              <InputAutoCompleteModeloDocumento
                label="Modelo de Documento"
                name="idModeloDocumento"
                nomeObjeto="modeloDocumento"
                placeholder="Modelo de Documento"
                listaTipoDocumentoFiscal={[ModeloDocumentoTipoEnum.notaFiscal]}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Emissão"
                name="dataHoraEmissao"
                onChange={handleOnAlterarDataHoraEmissao}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Prevista Faturamento"
                name="dataPrevistaFaturamento"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                valorStyle={{
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}
                formatarValor={(situacao = '') => {
                  let pendente = false;
                  let aprovado = false;
                  let rejeitado = false;
                  let cancelado = false;

                  if (situacao.includes('Pendente')) pendente = true;
                  if (situacao.includes('Aprovado')) aprovado = true;
                  if (situacao.includes('Rejeitado')) rejeitado = true;
                  if (situacao.includes('Cancelado')) cancelado = true;

                  return (
                    <div
                      className="lista-texto"
                      style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                    >
                      {pendente && (
                        <span style={{ color: 'darkgoldenrod' }}>Pendente</span>
                      )}
                      {aprovado && (
                        <span>
                          {pendente && !rejeitado && <span>{' e '}</span>}
                          {pendente && rejeitado && <span>{', '}</span>}
                          <span style={{ color: 'green' }}>Aprovado</span>
                        </span>
                      )}
                      {rejeitado && (
                        <span>
                          {(pendente || aprovado) && <span>{' e '}</span>}
                          <span style={{ color: 'orange' }}>Rejeitado</span>
                        </span>
                      )}
                      {cancelado && (
                        <span style={{ color: 'red' }}>Cancelado</span>
                      )}
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={7} md={12} sm={12}>
              <InputAutoCompleteCliente
                ref={inputRefFocus}
                label="Cliente"
                name="orcamentoPessoa.idPessoa"
                nomeObjeto="orcamentoPessoa.pessoa"
                placeholder="Cliente"
                informacaoAdicional
                onChangeItemAtual={(event) => {
                  if (event.itemAtual) {
                    setIdClienteAnterior(event.itemAtual.id);
                  } else {
                    setIdClienteAnterior('');
                  }
                }}
                onChangeItemAtualAposCarregarSemClear={async (event) => {
                  refresh();

                  if (
                    event.itemAtual &&
                    event.itemAtual.id !== idClienteAnterior
                  ) {
                    handleAtualizarDadosClienteConformeCadastro();
                    handleExibirMensagemAtualizacao();
                    formRef.current?.setFieldValue(
                      'atualizarTabelaPrecoItens',
                      true
                    );
                    handleObterDadosPadraoCliente();
                  }
                }}
              />
            </Col>
            <Col lg={2} md={4} sm={8}>
              <Select
                label="Finalidade Emissão"
                name="finalidadeEmissao"
                options={FormatarEnum({
                  enumObj: FinalidadeEmissaoEnum,
                  nullavel: true,
                })}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Indicador de Presença"
                name="indicadorPresenca"
                options={FormatarEnum({
                  enumObj: IndicadorPresencaPadraoEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                  handleExibirMensagemAtualizacao();
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="itens" title="Itens">
            <ItemTab />
          </Tab>

          <Tab eventKey="totais" title="Totais">
            <TotalTab />
          </Tab>

          <Tab eventKey="transporte" title="Transporte">
            <TransporteTab />
          </Tab>

          <Tab eventKey="pessoa" title="Cliente">
            <PessoaTab />
          </Tab>

          <Tab eventKey="informacoesComerciais" title="Inf. Comerciais">
            <InformacaoComercialTab />
          </Tab>

          <Tab eventKey="observacoesComerciais" title="Obs. Comerciais">
            <ObservacaoComercialTab />
          </Tab>

          <Tab eventKey="observacoesInternas" title="Obs. Internas">
            <ObservacaoInternaTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
