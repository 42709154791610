import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table, Col, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import { Container } from './styles';
import Modal, { IJanelaRef } from '../../../JanelaAutocomplete';
import TratarErros from '../../../../Util/Erro/TratarErros';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';
import IPesquisaEnderecoCepDetalhe from './Interface/IPesquisaEnderecoCepDetalhe';
import IPesquisaEnderecoCepDetalheRef from './Interface/IPesquisaEnderecoCepDetalheRef';
import IEndereco from './Interface/IEndereco';
import { TBody } from '../../../Styles/TBody';
import EnderecoCepComunicador from '../../../../Comunicador/Comum/Comunicador/EnderecoCepComunicador';
import { Tabela } from '../../../Tabela/styles';
import TextoLoading from '../../../TextoLoading';

const PesquisaEnderecoCepDetalhe: React.ForwardRefRenderFunction<
  IPesquisaEnderecoCepDetalheRef,
  IPesquisaEnderecoCepDetalhe
> = ({ handleOnSelecionarEndereco }, ref) => {
  const janelaRef = useRef<IJanelaRef>(null);
  const [listaEndereco, setListaEndereco] = useState<IEndereco[]>([]);
  const listaUfRef = useRef<Array<HTMLInputElement | null>>([]);
  const [origemIndex, setOrigemIndex] = useState(0);
  const [cepIndex, setCepIndex] = useState(0);
  const [logradouroIndex, setLogradouroIndex] = useState(0);
  const [bairroIndex, setBairroIndex] = useState(0);
  const [cidadeIndex, setCidadeIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const pesquisarCep = useCallback(
    async ({
      cep,
      logradouro,
      bairro,
      idCidade,
      nomeCidade,
      siglaEstado,
    }: any) => {
      setLoading(true);

      try {
        const response = await EnderecoCepComunicador.show({
          params: {
            cep,
            logradouro,
            bairro,
            idCidade,
            nomeCidade,
            siglaEstado,
          },
        });

        if (response.listaEndereco.length === 0 && response.mensagem) {
          ToastInfo(response.mensagem);
        } else if (response.listaEndereco.length === 1) {
          handleOnSelecionarEndereco(response.listaEndereco[0]);
        } else {
          janelaRef.current?.toggleJanela();
          setListaEndereco(response.listaEndereco);
        }

        setLoading(false);
      } catch (erro) {
        TratarErros(erro);
        setLoading(false);
      }
    },
    [handleOnSelecionarEndereco]
  );

  useImperativeHandle(ref, () => ({
    pesquisarCep,
  }));

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      const schema = Yup.object().shape({
        cep: Yup.string().nullable().max(8),
        logradouro: Yup.string().nullable().max(60),
        bairro: Yup.string().nullable().max(60),

        cidade: Yup.object().shape({
          id: Yup.string().required(),
          nome: Yup.string().max(60).required(),
          estado: Yup.object().shape({
            sigla: Yup.string().required().max(2),
          }),
        }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const itensSelecionados = {
      cidade: listaEndereco[cidadeIndex]?.cidade as any,
      logradouro: listaEndereco[logradouroIndex]?.logradouro || '',
      bairro: listaEndereco[bairroIndex]?.bairro || '',
      cep: listaEndereco[cepIndex]?.cep || '',
    } as IEndereco;

    const pessoaValida = await handleValidar(itensSelecionados);
    if (!pessoaValida) {
      return;
    }

    handleOnSelecionarEndereco(itensSelecionados);
    janelaRef.current?.toggleJanela();

    setLoading(false);
  }, [
    bairroIndex,
    cepIndex,
    cidadeIndex,
    handleOnSelecionarEndereco,
    handleValidar,
    listaEndereco,
    logradouroIndex,
  ]);

  return (
    <Modal titulo="Selecione o Endereço Desejado" ref={janelaRef} tamanho="xl">
      <Container>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <thead>
              <tr>
                <th className="lista-texto">
                  <span>Origem</span>
                </th>
                <th className="lista-texto">
                  <span>CEP</span>
                </th>
                <th className="lista-texto">
                  <span>Cidade</span>
                </th>
                <th className="lista-texto">
                  <span>UF</span>
                </th>
                <th className="lista-texto">
                  <span>Logradouro</span>
                </th>
                <th className="lista-texto">
                  <span>Bairro</span>
                </th>
              </tr>
            </thead>

            <TBody>
              {listaEndereco.map((endereco, index) => {
                return (
                  <tr key={v4()}>
                    <td
                      className={`${
                        origemIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                      onClick={(event) => {
                        if (loading) return;
                        const input =
                          event.currentTarget.querySelector('input');
                        input?.click();
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="origem"
                          value={endereco.origem}
                          checked={index === origemIndex}
                          onChange={() => {
                            setOrigemIndex(index);
                            setCepIndex(index);
                            setCidadeIndex(index);
                            setLogradouroIndex(index);
                            setBairroIndex(index);
                          }}
                        />
                        <span>{endereco.origem}</span>
                      </div>
                    </td>

                    <td
                      className={`${
                        cepIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                      onClick={() => {
                        setCepIndex(index);
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="cep"
                          checked={index === cepIndex}
                          value={endereco.cep}
                          onChange={() => {
                            setCepIndex(index);
                          }}
                        />
                        <span>{endereco.cep}</span>
                      </div>
                    </td>

                    <td
                      className={`${
                        cidadeIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                      onClick={() => {
                        setCidadeIndex(index);
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="cidade"
                          checked={index === cidadeIndex}
                          onChange={() => {
                            setCidadeIndex(index);
                          }}
                        />
                        <span>{endereco.cidade.nome}</span>
                      </div>
                    </td>

                    <td
                      className={`${
                        cidadeIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                    >
                      <div>
                        <input
                          type="radio"
                          name="cidadeEstadoSigla"
                          checked={index === cidadeIndex}
                          value={endereco.cidade.estado.sigla}
                          disabled
                          ref={(instance) => {
                            listaUfRef.current[index] = instance;
                          }}
                        />
                        <span>{endereco.cidade.estado.sigla}</span>
                      </div>
                    </td>

                    <td
                      className={`${
                        logradouroIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                      onClick={() => {
                        setLogradouroIndex(index);
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="logradouro"
                          checked={index === logradouroIndex}
                          value={endereco.logradouro}
                          onChange={() => {
                            setLogradouroIndex(index);
                          }}
                        />
                        <span>{endereco.logradouro}</span>
                      </div>
                    </td>

                    <td
                      className={`${
                        bairroIndex === index ? 'selecionado ' : ''
                      }lista-texto`}
                      onClick={() => {
                        setBairroIndex(index);
                      }}
                    >
                      <div>
                        <input
                          type="radio"
                          name="bairro"
                          checked={index === bairroIndex}
                          value={endereco.bairro}
                          onChange={() => {
                            setBairroIndex(index);
                          }}
                        />
                        <span>{endereco.bairro}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Tabela>

        <Col lg={12} style={{ padding: 0 }}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className="espacamento-interno-para-esquerda-15"
          >
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={() => {
                janelaRef.current?.toggleJanela();
              }}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </TextoLoading>
            </Button>
          </div>
        </Col>
      </Container>
    </Modal>
  );
};

export default forwardRef(PesquisaEnderecoCepDetalhe);
