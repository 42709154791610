import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { AppErro, TipoDocumentoAnteriorEnum } from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
} from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import { UseAutoComplete } from '../../../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import UseFuncoesPadrao from '../../../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador from '../../../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/DocumentoAnterior/Comunicador/ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formPrincipal = UseForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const { isJanelaAutoComplete } = UseAutoComplete();
  const { permissoes } = UsePermissoes();
  const {
    SuprimentosNotaFiscalConhecimentoConhecimentoTransporteEntradaTerceiro:
      permissao,
  } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const [loading, setLoading] = useState(false);
  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheServico"',
    });
  }, []);

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const tipoDocumentoAnteriorPapel = formRefLista.current?.getFieldValue(
          'documentoAnteriorPapel'
        );

        const schemaTipoDocumentoPapel = Yup.object().shape({
          tipoDocumentoAnterior: Yup.mixed()
            .oneOf(
              Object.values(TipoDocumentoAnteriorEnum),
              'Tipo de Documento é obrigatório!'
            )
            .required('Tipo de Documento é obrigatório!'),
          documentoAnteriorSerie: Yup.string()
            .nullable()
            .required('Série é obrigatória!'),
          subSerie: Yup.string().nullable(),
          numeroDocumento: Yup.string()
            .nullable()
            .required('Número do Documento é obrigatório!'),
          dataEmissao: Yup.date()
            .nullable()
            .required('Data de Emissão é obrigatória!'),
          idPessoa: Yup.string()
            .nullable()
            .required('O Expedidor do Documento Anterior é obrigatório!'),
        });

        const schemaTipoCTe = Yup.object().shape({
          chaveAcessoCte: Yup.string()
            .test({
              message: 'Chave de Acesso inválida!',
              test: (value: any) => {
                if (!value) return true;
                return value.length === 44;
              },
            })
            .required('Chave de Acesso é obrigatória!'),
          idPessoa: Yup.string()
            .nullable()
            .required('O Expedidor do Documento Anterior é obrigatório!'),
        });

        if (tipoDocumentoAnteriorPapel) {
          await schemaTipoDocumentoPapel.validate(data, { abortEarly: false });
        } else {
          await schemaTipoCTe.validate(data, { abortEarly: false });
        }

        return true;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formPrincipal.setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          const id = getIdDetalheRegistro() || '';

          setLoading(false);
          formPrincipal.setLoading(false);
          return { id, erro: true };
        }

        let id = getIdDetalheRegistro() || '';
        const idConhecimentoTransporteTerceiro =
          formPrincipal.getIdDetalheRegistro() || '';

        data.serie = data.documentoAnteriorSerie;
        delete data.documentoAnteriorSerie;

        const params = {
          ...data,
          idConhecimentoTransporteTerceiro,
        };

        const response =
          await ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador.store(
            { params }
          );

        setIdDetalheRegistro(response.id);
        id = response.id;

        formPrincipal.handleCarregarDados();
        await formRef?.reset();
        ToastSucesso('Documento Anterior Salvo!');
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      formPrincipal,
      getIdDetalheRegistro,
      handleSalvarContinuar,
      handleValidar,
      isJanelaAutoComplete,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja excluir o item?</span>,
      });

      if (resposta) {
        setLoading(false);
        formPrincipal.setLoading(false);

        const { erro: erroFormPrincipal } =
          await handleSalvarContinuar(isJanelaAutoComplete);
        if (erroFormPrincipal) {
          setLoading(false);
          formPrincipal.setLoading(false);
          return;
        }

        await ConhecimentoTransporteTerceiroDocumentoAnteriorComunicador.delete(
          { id }
        );

        ToastSucesso('Documento Anterior Deletado!');
        formPrincipal.handleCarregarDados();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
