import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';

const ObservacaoInternaTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 250 }}
              label="Observações Internas"
              name="observacoesInternas"
              maxLength={1500}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ObservacaoInternaTab;
