import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico-importacao-orcamento/obter-orcamento-item';

interface IOrdemServicoImportacaoOrcamentoObterOrcamentoItemComunicadorIndex {
  listaIdOrcamento: string[];
}

class OrdemServicoImportacaoOrcamentoObterOrcamentoItemComunicador {
  public async index(
    params: IOrdemServicoImportacaoOrcamentoObterOrcamentoItemComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new OrdemServicoImportacaoOrcamentoObterOrcamentoItemComunicador();
