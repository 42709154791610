import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { NavegacaoProvider } from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao/Hooks/NavegacaoHook';
import { UseContaListaParcelas } from './ContaListaParcelasHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const ParcelasNavegacaoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaValor } = UseContaListaParcelas();
  const { getIdDetalheRegistro, setIdDetalheRegistro, formRefLista, refresh } =
    UseListaDetalheForm();
  const idDetalheRegistro = getIdDetalheRegistro();
  const [posicaoNavegacao, setPosicaoNavegacao] = useState(0);
  const [mostrarNavegacao, setMostrarNavegacao] = useState(false);

  const totalNavegacao = useMemo(() => {
    return listaValor.length;
  }, [listaValor.length]);

  const handleSetPosicaoNavegacao = useCallback(
    async (novaPosicao: number) => {
      setPosicaoNavegacao(novaPosicao);
      setIdDetalheRegistro(String(novaPosicao));

      if (listaValor[novaPosicao]) {
        await formRefLista.current?.setDataInicial(listaValor[novaPosicao]);
      } else if (listaValor.length > 0) {
        setIdDetalheRegistro('0');
        setPosicaoNavegacao(0);
      } else {
        setIdDetalheRegistro('0');
      }

      refresh();
    },
    [setIdDetalheRegistro, listaValor, refresh, formRefLista]
  );

  useEffect(() => {
    if (idDetalheRegistro !== '') {
      if (Number(idDetalheRegistro) >= 0) {
        handleSetPosicaoNavegacao(Number(idDetalheRegistro));
      }
      setMostrarNavegacao(true);
    } else {
      setMostrarNavegacao(false);
    }
  }, [idDetalheRegistro, handleSetPosicaoNavegacao]);

  const handleAnterior = useCallback(async () => {
    if (posicaoNavegacao - 1 <= 0) {
      handleSetPosicaoNavegacao(0);
      return;
    }
    handleSetPosicaoNavegacao(posicaoNavegacao - 1);
  }, [posicaoNavegacao, handleSetPosicaoNavegacao]);

  const handlePrimeiro = useCallback(async () => {
    handleSetPosicaoNavegacao(0);
  }, [handleSetPosicaoNavegacao]);

  const handleProximo = useCallback(async () => {
    if (posicaoNavegacao + 1 >= totalNavegacao) {
      handleSetPosicaoNavegacao(totalNavegacao - 1);
      return;
    }
    handleSetPosicaoNavegacao(posicaoNavegacao + 1);
  }, [posicaoNavegacao, totalNavegacao, handleSetPosicaoNavegacao]);

  const handleUltimo = useCallback(async () => {
    handleSetPosicaoNavegacao(totalNavegacao - 1);
  }, [handleSetPosicaoNavegacao, totalNavegacao]);

  return (
    <NavegacaoProvider
      value={{
        totalNavegacao,
        posicaoNavegacao: posicaoNavegacao + 1,
        mostrarNavegacao,
        handleAnterior,
        handlePrimeiro,
        handleProximo,
        handleUltimo,
      }}
    >
      {children}
    </NavegacaoProvider>
  );
};

export { ParcelasNavegacaoHook };
