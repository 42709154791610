const CotacaoCompraPorFornecedorPlanilha = [
  {
    titulo: 'Data Emissão',
    valor: 'listaCotacaoCompraItem.cotacaoCompraDataHoraEmissao',
  },

  {
    titulo: 'Número da Cotação de Compra',
    valor: 'listaCotacaoCompraItem.cotacaoCompraNumero',
  },

  {
    titulo: 'Código do Produto',
    valor: 'listaCotacaoCompraItem.produtoCodigo',
  },

  {
    titulo: 'Descrição do Produto',
    valor: 'listaCotacaoCompraItem.produtoDescricao',
  },

  {
    titulo: 'Informações Adicionais do Produto',
    valor: 'listaCotacaoCompraItem.informacoesAdicionaisProduto',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaCotacaoCompraItem.produtoUnidadeMedidaSigla',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaCotacaoCompraItem.valorQuantidade',
  },
  {
    titulo: 'Valor Unitário',
    valor: 'listaCotacaoCompraItem.valorUnitario',
  },
  {
    titulo: 'Valor Subtotal',
    valor: 'listaCotacaoCompraItem.valorSubtotal',
  },
  {
    titulo: 'Valor Total',
    valor: 'listaCotacaoCompraItem.valorTotal',
  },
];

export default CotacaoCompraPorFornecedorPlanilha;
