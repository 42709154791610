"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ParcelasCalculo;
function ParcelasCalculo(dados) {
    const listaParcelasRetorno = [];
    let somatorio = 0;
    for (let i = 0; i < dados.parcelas; i++) {
        const valorParcela = Number(dados.valor / dados.parcelas).Arredondar(dados.casasDecimais);
        somatorio += valorParcela;
        listaParcelasRetorno.push(valorParcela);
    }
    listaParcelasRetorno[0] = Number(listaParcelasRetorno[0] + (dados.valor - somatorio)).Arredondar(dados.casasDecimais);
    return listaParcelasRetorno;
}
