import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import GetValidationErrors from '../../../Util/Erro/GetValidationErrors';
import { Container, FormCiaLogin, Button } from './style';
import TratarErros from '../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import InputSenha from '../../../Componentes/Inputs/InputSenha';
import ToastErro from '../../../Util/Toasts/ToastErro';
import UsuarioEsqueceuSenhaValidarTokenComunicador from '../../../Comunicador/Configuracao/UsuarioEsqueceuSenha/Comunicador/UsuarioEsqueceuSenhaValidarTokenComunicador';
import UsuarioEsqueceuSenhaComunicador from '../../../Comunicador/Configuracao/UsuarioEsqueceuSenha/Comunicador/UsuarioEsqueceuSenhaComunicador';
import IParametroRotaDetalhe from '../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';

const RecuperarSenha: React.FC = () => {
  const params: IParametroRotaDetalhe = useParams();
  const navigate = useNavigate();

  const formRef = useRef<IFormCiaHandles>(null);

  useEffect(() => {
    async function validarToken(): Promise<void> {
      try {
        const response = await UsuarioEsqueceuSenhaValidarTokenComunicador.show(
          { id: String(params.id) }
        );

        if (!response.valido) {
          ToastErro('Link inválido ou expirado!');
          navigate('/');
        }
      } catch (error) {
        TratarErros(error);
      }
    }

    validarToken();
  }, [navigate, params.id]);

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha: Yup.string().min(6, 'No mínimo 6 caracteres!'),
          repetirSenha: Yup.string().min(6, 'No mínimo 6 caracteres!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef]
  );

  const handleSubmit = useCallback(async () => {
    const data = formRef.current?.getData();

    try {
      if (!(await handleValidar(data))) return;

      const response = await UsuarioEsqueceuSenhaComunicador.update({
        id: String(params.id),
        params: data,
      });
      ToastSucesso(response.mensagem);

      navigate('/');
    } catch (error) {
      TratarErros(error);
    }
  }, [handleValidar, navigate, params.id]);

  return (
    <Container>
      <div>
        <h1>Esqueceu a Senha</h1>
        <FormCiaLogin ref={formRef}>
          <input type="password" style={{ opacity: 0, position: 'absolute' }} />
          <InputSenha
            autoComplete="off"
            label="Senha"
            name="senha"
            placeholder="Senha"
            maxLength={200}
          />
          <InputSenha
            label="Repita a Senha"
            name="repetirSenha"
            placeholder="Repita a Senha"
            maxLength={200}
          />
          <Button
            style={{ marginTop: 15 }}
            type="button"
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </FormCiaLogin>
      </div>
    </Container>
  );
};

export default RecuperarSenha;
