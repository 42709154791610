import React, { useEffect, useCallback, useState } from 'react';
import { Modal, Container, Col, Row, Table } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import {
  FormatarChaveAcesso,
  FormatarDataParaPtBr,
  INotaFiscalSaidaDocumentosReferenciadosLista,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import Divisor from '../../../../../../../../Componentes/Divisor';
import Acoes from '../../../../../../../../Componentes/Lista/Acoes';
import ListaProvider from '../../../../../../../../Componentes/Lista/ListaProvider';
import DocumentosReferenciadosDetalhe from '../Detalhe';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import NotaFiscalSaidaDocumentosReferenciadosComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/DocumentosReferenciados/NotaFiscalSaidaDocumentosReferenciadosComunicador';

interface IDocumentosReferenciadosListaModal {
  onFecharFormModal(): void;
}

const DocumentosReferenciadosLista: React.FC<
  IDocumentosReferenciadosListaModal
> = ({ onFecharFormModal }) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida: permissao } = permissoes;

  const {
    formRefLista,
    setLoading,
    loading,
    handleSubmit,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
  } = UseListaDetalheForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const [listaValor, setListaValor] = useState<
    INotaFiscalSaidaDocumentosReferenciadosLista[]
  >([]);
  const [
    showDetalheDocumentosReferenciados,
    setShowDetalheDocumentosReferenciados,
  ] = useState(false);

  const idDetalheRegistro = getIdDetalheRegistro();
  const idPrincipal = formPrincipal.getIdDetalheRegistro();

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      formPrincipal.setLoading(true);

      const response =
        await NotaFiscalSaidaDocumentosReferenciadosComunicador.index({
          params: {
            idValor: String(idPrincipal),
          },
        });

      if (!response) return;

      setListaValor(response);

      formPrincipal.setLoading(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      formPrincipal.setLoading(false);
      setLoading(false);
    }
  }, [formPrincipal, idPrincipal, setLoading]);

  const handleAdicionarDocumentosReferenciados = useCallback(async () => {
    setShowDetalheDocumentosReferenciados(true);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleDocumentosReferenciadosFecharFormModal = useCallback(async () => {
    if (formRefLista.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowDetalheDocumentosReferenciados(true);
      } else {
        setIdDetalheRegistro('');
        setShowDetalheDocumentosReferenciados(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowDetalheDocumentosReferenciados(false);
    }
  }, [abrirRecuperarFormulario, formRefLista, setIdDetalheRegistro]);

  const handleDocumentosReferenciadosSalvarFormModal = useCallback(async () => {
    const data = formRefLista.current?.getData();
    const { erro } = await handleSubmit(data, formRefLista.current);

    if (!erro) {
      await handleCarregarDados();
      setShowDetalheDocumentosReferenciados(false);
      setIdDetalheRegistro('');
    }
  }, [formRefLista, handleCarregarDados, handleSubmit, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (index: string): Promise<void> => {
      setIdDetalheRegistro(index);
      setShowDetalheDocumentosReferenciados(true);
    },
    [setIdDetalheRegistro]
  );

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDetalheRegistro]);

  return (
    <JanelaDetalhe
      titulo="Documentos Referenciados"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefLista}>
        <Modal.Body>
          <Divisor>
            <Container>
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <BtnPadraoButton
                    type="button"
                    onClick={handleAdicionarDocumentosReferenciados}
                    className="btn-padrao btn-verde btn-adicionar"
                    disabled={!permissao?.inclui || formPrincipal.loading}
                    style={{ width: '80%' }}
                  >
                    <TextoLoading loading={formPrincipal.loading}>
                      <IoMdAddCircleOutline />
                      <span style={{ marginLeft: 10 }}>Adicionar</span>
                    </TextoLoading>
                  </BtnPadraoButton>
                </Col>
              </Row>

              <ListaProvider>
                <Tabela style={{ overflow: 'auto' }}>
                  <Table striped hover bordered variant="light">
                    <thead>
                      <tr>
                        <th className="lista-texto" style={{ width: '15%' }}>
                          <span>Tipo</span>
                        </th>
                        <th className="lista-texto" style={{ width: '35%' }}>
                          <span>Chave Acesso</span>
                        </th>
                        <th className="lista-texto" style={{ width: '10%' }}>
                          <span>Série</span>
                        </th>
                        <th className="lista-valor" style={{ width: '5%' }}>
                          <span>Número</span>
                        </th>
                        <th className="lista-data" style={{ width: '15%' }}>
                          <span>Data Emissão</span>
                        </th>
                        <th className="lista-texto" style={{ width: '10%' }}>
                          <span>Modelo</span>
                        </th>
                        <th className="lista-texto" style={{ width: '10%' }}>
                          <span>Produtor</span>
                        </th>
                        <th className="lista-acoes">
                          <span>Ações</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {listaValor.map((item) => {
                        return (
                          <tr key={v4()} onClick={() => handleEditar(item.id)}>
                            <td className="lista-texto">{item.tipo}</td>
                            <td className="lista-texto">
                              {FormatarChaveAcesso(item.chaveAcesso)}
                            </td>
                            <td className="lista-texto">{item.serieCodigo}</td>
                            <td className="lista-valor">{item.numero}</td>
                            <td className="lista-data">
                              {FormatarDataParaPtBr(item.dataEmissao)}
                            </td>
                            <td className="lista-texto">
                              {item.modeloNotaReferencia}
                            </td>
                            <td className="lista-texto">
                              {item.pessoa ? item.pessoa?.nomeRazaoSocial : ''}
                            </td>
                            <td className="tdButton lista-acoes">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Acoes
                                  deletarFuncao={async () => {
                                    try {
                                      setLoading(true);

                                      await NotaFiscalSaidaDocumentosReferenciadosComunicador.delete(
                                        { id: item.id }
                                      );
                                      formPrincipal.setLoading(false);
                                      formPrincipal.refresh();

                                      await formPrincipal.handleCarregarDados();
                                      handleCarregarDados();

                                      setLoading(false);
                                    } catch (error) {
                                      setLoading(false);
                                      throw error;
                                    }
                                  }}
                                  disableDeletar={
                                    permissoes.ComercialVendasNotaFiscalSaida
                                      ?.exclui
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tabela>
              </ListaProvider>
            </Container>
          </Divisor>
        </Modal.Body>

        {showDetalheDocumentosReferenciados && (
          <DocumentosReferenciadosDetalhe
            onFecharFormModal={handleDocumentosReferenciadosFecharFormModal}
            onSalvarFormModal={handleDocumentosReferenciadosSalvarFormModal}
          />
        )}
      </FormCia>
    </JanelaDetalhe>
  );
};

export default DocumentosReferenciadosLista;
