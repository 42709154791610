import { IOrcamentoItemComercialRepresentanteLista } from '@elogestor/util';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteRegraEscolhaTabelaPreco from '../../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRegraEscolhaTabelaPreco';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseItemTab } from '../../Hook/ItemTabHook';
import OrcamentoItemComercialRepresentanteLista from './ListaItemComercialRepresentante/Lista';

const ItemComercialTab: React.FC = () => {
  const { formRef: formRefPrincipal } = UseForm();
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const {
    obterDadosItemComercialRepresentante,
    limparDadosItemComercialRepresentante,
    obterRegraEscolhaTabelaPreco,
  } = UseItemTab();

  const handleFormatarListaRepresentante = useCallback(async () => {
    const gerarComissao = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemComercial.gerarComissao'
    );
    if (!gerarComissao) {
      await limparDadosItemComercialRepresentante();
    } else {
      await obterDadosItemComercialRepresentante();
    }

    obterRegraEscolhaTabelaPreco();
  }, [
    formRefDetalhe,
    limparDadosItemComercialRepresentante,
    obterDadosItemComercialRepresentante,
    obterRegraEscolhaTabelaPreco,
  ]);

  const camposDesabilitados =
    !formRefDetalhe.current?.getFieldValue('idProduto');

  const idPessoa = formRefPrincipal.current?.getFieldValue(
    'orcamentoPessoa.idPessoa'
  );
  const dataHoraEmissao =
    formRefPrincipal.current?.getFieldValue('dataHoraEmissao');

  const idProduto = formRefDetalhe.current?.getFieldValue('idProduto');
  const destinado = formRefDetalhe.current?.getFieldValue('destinado');

  const listaIdPessoaRepresentante = formRefDetalhe.current
    ?.getFieldValue(
      'orcamentoItemComercial.listaOrcamentoItemComercialRepresentante'
    )
    .map((representante: IOrcamentoItemComercialRepresentanteLista) => {
      return representante.pessoaRepresentante.id;
    });

  const gerarComissao = formRefDetalhe.current?.getFieldValue(
    'orcamentoItemComercial.gerarComissao'
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={4}>
            <InputSwitch
              label="Gerar Faturamento"
              ativo="Sim"
              inativo="Não"
              name="orcamentoItemComercial.gerarFaturamento"
              disabled={camposDesabilitados}
            />
          </Col>
          <Col lg={2} md={4} sm={4}>
            <InputSwitch
              label="Gerar Financeiro"
              ativo="Sim"
              inativo="Não"
              name="orcamentoItemComercial.gerarFinanceiro"
              disabled={camposDesabilitados}
            />
          </Col>
          <Col lg={2} md={4} sm={4}>
            <InputSwitch
              label="Gerar Comissão"
              ativo="Sim"
              inativo="Não"
              name="orcamentoItemComercial.gerarComissao"
              disabled={camposDesabilitados}
              onChange={() => {
                refresh();
                handleFormatarListaRepresentante();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={6}>
            <InputAutoCompleteRegraEscolhaTabelaPreco
              label="Regra de Escolha da Tab. Preço"
              placeholder="Regra de Escolha da Tab. Preço"
              name="orcamentoItemComercial.idRegraEscolhaTabelaPreco"
              nomeObjeto="orcamentoItemComercial.regraEscolhaTabelaPreco"
              permitirAdicionar={false}
              dataPesquisaVigencia={dataHoraEmissao}
              destinado={destinado}
              idPessoa={idPessoa}
              idProduto={idProduto}
              listaIdPessoaRepresentante={listaIdPessoaRepresentante}
              onChangeItemAtual={(event) => {
                formRefDetalhe.current?.setFieldValue(
                  'orcamentoItemComercial.valorUnitarioTabelaPreco',
                  event.itemAtual?.produtoRegraEscolhaTabelaPreco
                    ?.valorUnitarioVenda
                );

                const utilizarValorTabelaPreco =
                  formRefDetalhe.current?.getFieldValue(
                    'orcamentoItemComercial.utilizarValorTabelaPreco'
                  );

                if (
                  event.itemAtual?.produtoRegraEscolhaTabelaPreco
                    ?.valorUnitarioVenda &&
                  utilizarValorTabelaPreco
                ) {
                  formRefDetalhe.current?.setFieldValue(
                    'valorUnitario',
                    event.itemAtual?.produtoRegraEscolhaTabelaPreco
                      ?.valorUnitarioVenda
                  );
                }
              }}
              disabled={camposDesabilitados}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Val. Unit. Tab. Preço"
              placeholder="Val. Unit. Tab. Preço"
              name="orcamentoItemComercial.valorUnitarioTabelaPreco"
              casasDecimais={10}
              casasInteiras={11}
              disabled
            />
          </Col>
          <Col lg={2} md={4} sm={4}>
            <InputSwitch
              label="Util. Val. Tab. Preço"
              ativo="Sim"
              inativo="Não"
              name="orcamentoItemComercial.utilizarValorTabelaPreco"
              onChange={(event) => {
                const regraEscolhaTabelaPreco =
                  formRefDetalhe.current?.getFieldValueNomeObjeto(
                    'orcamentoItemComercial.regraEscolhaTabelaPreco'
                  );

                const valorUnitarioTabelaPreco =
                  formRefDetalhe.current?.getFieldValue(
                    'orcamentoItemComercial.valorUnitarioTabelaPreco'
                  );

                if (
                  event.currentTarget.checked &&
                  regraEscolhaTabelaPreco &&
                  valorUnitarioTabelaPreco
                ) {
                  formRefDetalhe.current?.setFieldValue(
                    'valorUnitario',
                    valorUnitarioTabelaPreco
                  );
                }
              }}
            />
          </Col>
        </Row>

        <Row style={{ display: gerarComissao ? 'flex' : 'none' }}>
          <OrcamentoItemComercialRepresentanteLista name="orcamentoItemComercial.listaOrcamentoItemComercialRepresentante" />
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemComercialTab;
