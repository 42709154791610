import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/impressao/a4';

interface INotaFiscalEntradaA4ImpressaoComunicadorShow {
  id: string;
}

class NotaFiscalEntradaA4ImpressaoComunicador {
  public async show(
    params: INotaFiscalEntradaA4ImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaA4ImpressaoComunicador();
