import styled, { css } from 'styled-components';

interface IContainer {
  $telaPequena: boolean;
  $menuMinimizado: boolean;
}

interface IAside {
  $menuMinimizado: boolean;
  $telaGrande: boolean;
  $telaPequena: boolean;
}

interface IHeader {
  $menuMinimizado: boolean;
}

export const Container = styled.div<IContainer>`
  background: rgb(45, 50, 62);

  display: grid;
  grid-template-columns: 1fr 30fr;
  grid-template-rows: 66px calc(100vh - 66px);
  grid-template-areas:
    'logo h'
    'a m';
  transition: all 0.5s;

  ${({ $telaPequena }) =>
    $telaPequena &&
    css`
      grid-template-columns: 0fr 30fr;
      overflow-x: hidden;
    `}

  .logo {
    padding-left: 10px;
    align-self: center;
    grid-area: logo;
    display: block;
    height: 75%;
    img {
      max-width: 100%;
      height: 100%;
      //filter: brightness(0) invert(1);
    }
  }

  ${({ $menuMinimizado, $telaPequena }) =>
    !$menuMinimizado &&
    $telaPequena &&
    css`
      .logo {
        display: none !important;
      }
    `}
`;

export const Header = styled.header<IHeader>`
  grid-area: h;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 100%;
  grid-template-areas: 'p s';
  .primeiro {
    grid-area: p;
    display: flex;
    align-items: center;
    .menuToggle > svg {
      transition: all 0.5s;
      transform: rotate(0deg);
      ${({ $menuMinimizado }) =>
        $menuMinimizado &&
        css`
          transform: rotate(180deg);
        `}
    }
  }

  .segundo {
    grid-area: s;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

export const ButtonHeader = styled.button`
  background-color: Transparent;
  border: none;
  cursor: pointer;
  outline: none;
  height: 100%;
  width: auto;
  padding: 10px;
  svg {
    height: 25px;
    width: auto;
    color: white;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Main = styled.main`
  /* padding: 15px; */
  height: 100%;
  width: 100%;
  background: #f2f3f8;
  grid-area: m;
  overflow: auto;
  padding-bottom: 80px;
`;

export const Aside = styled.aside<IAside>`
  grid-area: a;
  display: flex;
  flex-direction: column;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s;

  width: 260px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(45, 50, 62);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 0;
  }

  ul {
    width: 260px;
  }

  ${({ $telaGrande, $menuMinimizado }) =>
    $telaGrande &&
    $menuMinimizado &&
    css`
      width: 80px;
      display: flex;
      ul {
        width: 100%;
        overflow: hidden;
      }

      h3 {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 0;
      }

      &:hover {
        width: 260px;
        padding-left: 0px;

        ul {
          width: 260px;
        }

        h3 {
          justify-content: flex-start;
        }
      }
    `}

  ${({ $telaPequena, $menuMinimizado }) =>
    $telaPequena &&
    css`
      width: 0px;

      ul {
        width: 0px;
      }

      ${$menuMinimizado &&
      css`
        width: 100vw;
        ul {
          width: 100vw !important;
        }
      `}
    `}
`;

interface IMensagem {
  $mostrarMensagem: boolean;
}

export const Mensagem = styled.div<IMensagem>`
  z-index: 100;
  width: 500px;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  position: fixed;
  left: calc(50% - 250px);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: 100vw;
    height: auto;
    left: calc(50% - 50vw);
  }

  background: rgba(0, 0, 0, 0.8);
  color: #fff;

  ${({ $mostrarMensagem }) =>
    !$mostrarMensagem &&
    css`
      display: none;
    `}

  p {
    font-size: 20px;
  }
  button {
    font-weight: 600;
  }
  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    align-items: flex-start;
    h3 {
      grid-column-start: 2;
    }
  }
`;

export const Notificacoes = styled.div`
  button {
    padding: 0;
    svg {
      width: 25px;
      height: 25px;
    }
    &:hover {
      background: none;
    }
  }
`;

export const EmpresaPessoa = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 15px;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;

    padding: 1px 10px;
    color: #fff;
  }
`;

interface IModal {
  $visivel: boolean;
}

export const DivTodaTela = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const Modal = styled.div<IModal>`
  ${({ $visivel }) =>
    !$visivel &&
    css`
      display: none;
    `}
`;

export const Pessoa = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 160px;

  color: #fff;
  font-weight: bold;
  background: rgb(54, 64, 74);
  padding: 20px;
  font-size: 15px;
  z-index: 100;

  > ul {
    margin: 0;
    > li {
      display: flex;
      align-items: center;

      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;

      button {
        padding: 0;
        width: 100%;
        text-align: left;
        vertical-align: middle;

        font-size: 15px;
        color: #fff;
        font-weight: bold;
        color: #fff;
        svg {
          height: auto;
        }
        &:hover {
          background: none;
        }
      }

      & + li {
        margin-top: 10px;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
`;

export const Empresa = styled.div`
  position: absolute;
  right: 0;
  width: 280px;
  top: 100%;

  color: #fff;
  font-weight: bold;
  background: rgb(54, 64, 74);
  padding: 20px;
  font-size: 15px;
  z-index: 100;

  > ul {
    margin: 0;
    > li {
      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;

      button {
        padding: 0;
        text-align: left;
        &:hover {
          background: none;
        }
      }

      & + li {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
`;

export const Usuario = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 15px;
  > button {
    height: 50px;
    width: 50px;
    border: 2px solid #fff;
    border-radius: 50px;
    background: #000;
  }
`;
