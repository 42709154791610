/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ValidarEmail } from '@elogestor/util';
import TagInputBase, {
  IOnChangeListaItemAtualTagEvent,
  IOnAdicionarTagEvent,
  IOnChangeTextoTagEvent,
  IOnMoverTagEvent,
  IOnRemoverTagEvent,
} from '../TagInputBase';
import UseRegisterTagsInput from '../TagInputBase/Hooks/UseRegisterTagsInput';

interface IOnChange {
  valorAnterior: any;
}

interface IInputEmailProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualTagEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarTagEvent) => void;
  onRemoverItem?: (evento: IOnRemoverTagEvent) => void;
  onMoverItem?: (evento: IOnMoverTagEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoTagEvent) => void;
}

export interface IInputEmail {
  id?: string;
  email: string;
}

const InputEmail: React.FC<IInputEmailProps> = ({
  name,
  label,
  onChangeListaItemAtual,
  onAdicionarItem,
  onRemoverItem,
  onMoverItem,
  onChangeTexto,
}) => {
  const handleObterChaveUnica = useCallback((item: IInputEmail) => {
    return item.email;
  }, []);

  const { tagInputRef, error: erroUnform } = UseRegisterTagsInput({
    nome: name,
    handleObterChaveUnica,
  });

  const [error, setError] = useState(erroUnform);

  useEffect(() => {
    setError(erroUnform);
  }, [erroUnform]);

  const handleObterLabel = useCallback((item: IInputEmail) => {
    return item.email;
  }, []);

  const handleFormatarValidarString = useCallback((valor: any): IInputEmail => {
    if (!ValidarEmail(valor)) {
      throw new Error('E-mail inválido!');
    }
    return {
      email: valor,
    };
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div style={{ display: 'flex' }}>
          <TagInputBase
            ref={tagInputRef}
            error={error}
            obterChaveUnica={handleObterChaveUnica}
            obterLabel={handleObterLabel}
            formatarValidarString={handleFormatarValidarString}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeListaItemAtual={onChangeListaItemAtual}
            onChangeTexto={onChangeTexto}
          />
        </div>
      </div>
    </div>
  );
};

export default InputEmail;
