import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-parametro';

interface IRegraEscolhaParametroComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaParametroComunicadorShow {
  id: string;
}

interface IRegraEscolhaParametroComunicadorStore {
  params: any;
}

interface IRegraEscolhaParametroComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaParametroComunicadorDeletar {
  id: string;
}

class RegraEscolhaParametroComunicador {
  public async index(
    params: IRegraEscolhaParametroComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaParametroComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaParametroComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaParametroComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaParametroComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaParametroComunicador();
