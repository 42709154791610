import ILogPesquisaParametro from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/log';

interface INotaFiscalEntradaTransmissaoEventoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class NotaFiscalEntradaTransmissaoEventoLogComunicador {
  public async show(
    params: INotaFiscalEntradaTransmissaoEventoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoLogComunicador();
