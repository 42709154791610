import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra/item';
const rotaNovo = 'suprimentos/compras/pedido-compra';

interface IPedidoCompraItemComunicadorShow {
  id: string;
}

interface IPedidoCompraItemComunicadorStore {
  idPai: string;
  params: any;
}

interface IPedidoCompraItemComunicadorUpdate {
  id: string;
  params: any;
}

interface IPedidoCompraItemComunicadorDelete {
  id: string;
}

class PedidoCompraItemComunicador {
  public async show(params: IPedidoCompraItemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPedidoCompraItemComunicadorStore): Promise<any> {
    const response = await api.post(
      `${rotaNovo}/${params.idPai}/item`,
      params.params
    );

    return response.data;
  }

  public async update(
    params: IPedidoCompraItemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IPedidoCompraItemComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoCompraItemComunicador();
