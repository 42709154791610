/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { produce } from 'immer';
import { MdClose } from 'react-icons/md/index.mjs';
import { CalculateSize } from '@elogestor/util';
import { ContainerListaItemAtual } from './styles';
import { DefaultInputDiv, SpanErro } from '../../Styles';
import LiDrag from '../Componentes/LiDrag';
import UseListaAcao, { IListaAcao } from './Hooks/UseListaAcao';

interface IOnChange {
  valorAnterior: any;
}

interface IItem {
  chave: string | number;
  label: string;
  valor: string | number;
  permitirExcluir: boolean;
}

export interface IOnChangeListaItemAtualTagEvent {
  listaItemAtual: any[] | null;
  input: HTMLInputElement | null;
}

export interface IOnAdicionarTagEvent extends IOnChangeListaItemAtualTagEvent {
  itemAdicionado: any;
}

export interface IOnRemoverTagEvent extends IOnChangeListaItemAtualTagEvent {
  itemRemovido: any;
}

export interface IOnMoverTagEvent extends IOnChangeListaItemAtualTagEvent {
  to: number;
  from: number;
}

export interface IOnChangeTextoTagEvent {
  input: HTMLInputElement | null;
}

interface IInputTagInputBase {
  obterChaveUnica(item: any): string | number;
  obterLabel(item: any): string;
  formatarValidarString(valor: string): any;

  error?: string;

  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualTagEvent,
    props: IOnChange
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarTagEvent) => void;
  onRemoverItem?: (evento: IOnRemoverTagEvent) => void;
  onMoverItem?: (evento: IOnMoverTagEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoTagEvent) => void;
  permitirDigitar?: boolean;
}

export interface IInputTagInputBaseRef {
  obterListaItemAtual(): any[];
  obterListaAcao: () => IListaAcao;
  obterInput(): HTMLInputElement | null;
  alterarListaItemAtualListaAcao(
    listaItem: any[],
    listaAcao?: IListaAcao
  ): void;
  alterarListaItemAtualListaAcaoSemEvento(
    listaItem: any[],
    listaAcao?: IListaAcao
  ): void;
  alterarListaAcao(listaAcao: IListaAcao): void;
  adicionarItem(item: any): void;
  removerItem(item: any): void;
  moverItem(from: number, to: number): void;
}

const TagInputBase: React.ForwardRefRenderFunction<
  IInputTagInputBaseRef,
  IInputTagInputBase
> = (
  {
    error,
    onChangeTexto,
    onChangeListaItemAtual,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    obterChaveUnica,
    obterLabel,
    formatarValidarString,
    permitirDigitar = true,
  },
  ref
) => {
  const [id] = useState(v4());
  const {
    handleAdicionarAcaoDeletar,
    handleAdicionarAcaoInserir,
    handleAdicionarAcaoMover,
    obterListaAcao,
    setListaAcao,
  } = UseListaAcao();

  const inputRef = useRef<HTMLInputElement>(null);

  const [listaItemAtual, setListaItemAtual] = useState<IItem[]>([]);
  const [erroInterno, setErroInterno] = useState<string | undefined>('');
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const formatarItem = useCallback(
    (valor: any) => {
      return {
        chave: obterChaveUnica(valor),
        label: obterLabel(valor),
        valor,
        permitirExcluir: valor.permitirExcluir ?? true,
      };
    },
    [obterChaveUnica, obterLabel]
  );

  const formatarListaItem = useCallback(
    (lista: any[]) => {
      if (lista && lista.length > 0) {
        return lista.map(formatarItem);
      }

      return lista;
    },
    [formatarItem]
  );

  const adicionarItem = useCallback(
    (item: IItem) => {
      if (inputRef.current) {
        inputRef.current.style.width = '1ch';
      }

      setErroInterno('');
      setListaItemAtual((state) => {
        const itemExiste = state.find((val) => val.chave === item.chave);
        if (itemExiste) return state;

        const novoValor = [...state, item];
        if (onChangeListaItemAtual) {
          onChangeListaItemAtual(
            {
              input: inputRef.current,
              listaItemAtual: novoValor.map((valor) => valor.valor),
            },
            {
              valorAnterior: state.map((valor) => valor.valor),
            }
          );
        }
        if (onAdicionarItem) {
          onAdicionarItem({
            input: inputRef.current,
            listaItemAtual: novoValor.map((valor) => valor.valor),
            itemAdicionado: item.valor,
          });
        }
        handleAdicionarAcaoInserir({
          chave: item.chave,
          valor: item.valor,
          ordem: state.length,
        });

        return novoValor;
      });
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [handleAdicionarAcaoInserir, onAdicionarItem, onChangeListaItemAtual]
  );

  const moverItem = useCallback(
    (from: number, to: number) => {
      if (inputRef.current?.disabled) return;

      setListaItemAtual((state) =>
        produce(state, (draft) => {
          const valorDe = JSON.parse(JSON.stringify(draft[from]));
          const valorPara = JSON.parse(JSON.stringify(draft[to]));

          draft.splice(from, 1, valorPara);
          draft.splice(to, 1, valorDe);

          handleAdicionarAcaoMover({
            chave: valorDe.chave,
            valor: valorDe.valor,
            ordem: to,
          });
          handleAdicionarAcaoMover({
            chave: valorPara.chave,
            valor: valorPara.valor,
            ordem: from,
          });

          if (onChangeListaItemAtual) {
            onChangeListaItemAtual(
              {
                input: inputRef.current,
                listaItemAtual: draft.map((valor) => valor.valor),
              },
              {
                valorAnterior: state.map((valor) => valor.valor),
              }
            );
          }
          if (onMoverItem) {
            onMoverItem({
              input: inputRef.current,
              listaItemAtual: draft.map((valor) => valor.valor),
              from,
              to,
            });
          }
        })
      );
    },
    [handleAdicionarAcaoMover, onChangeListaItemAtual, onMoverItem]
  );

  const removerItem = useCallback(
    (index: number) => {
      if (inputRef.current?.disabled) return;

      setIsFocused(false);
      setListaItemAtual((state) =>
        produce(state, (draft) => {
          const [itemProxy] = draft.splice(index, 1);
          const item = JSON.parse(JSON.stringify(itemProxy));

          if (onChangeListaItemAtual) {
            onChangeListaItemAtual(
              {
                input: inputRef.current,
                listaItemAtual: draft.map((valor) => valor.valor),
              },
              {
                valorAnterior: state.map((valor) => valor.valor),
              }
            );
          }
          if (onRemoverItem) {
            onRemoverItem({
              input: inputRef.current,
              listaItemAtual: draft.map((valor) => valor.valor),
              itemRemovido: item.valor,
            });
          }
          handleAdicionarAcaoDeletar({ chave: item.chave, valor: item.valor });
          for (let i = index; i < draft.length; i++) {
            const itemMover = JSON.parse(JSON.stringify(draft[i]));

            handleAdicionarAcaoMover({
              chave: itemMover.chave,
              valor: itemMover.valor,
              ordem: i,
            });
          }
        })
      );
    },
    [
      handleAdicionarAcaoDeletar,
      handleAdicionarAcaoMover,
      onChangeListaItemAtual,
      onRemoverItem,
    ]
  );

  const limparListaItemAtual = useCallback(() => {
    listaItemAtual.forEach((item) => {
      handleAdicionarAcaoDeletar(item);
    });
  }, [handleAdicionarAcaoDeletar, listaItemAtual]);

  const alterarListaItemAtualListaAcao = useCallback(
    (novaLista: any[], novaListaAcao?: IListaAcao) => {
      setErroInterno('');
      const novaListaFormatada = formatarListaItem(novaLista);

      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(
          {
            input: inputRef.current,
            listaItemAtual: novaLista,
          },
          {
            valorAnterior: listaItemAtual.map((valor) => valor.valor),
          }
        );
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (novaListaAcao) {
        setListaAcao(novaListaAcao);
      } else {
        limparListaItemAtual();

        novaListaFormatada.forEach((item, index) => {
          handleAdicionarAcaoInserir({
            chave: item.chave,
            valor: item.valor,
            ordem: index,
          });
        });
      }
      setListaItemAtual(novaListaFormatada);
    },
    [
      formatarListaItem,
      handleAdicionarAcaoInserir,
      limparListaItemAtual,
      listaItemAtual,
      onChangeListaItemAtual,
      setListaAcao,
    ]
  );

  const alterarListaItemAtualListaAcaoSemEvento = useCallback(
    (novaLista: any[], novaListaAcao?: IListaAcao) => {
      setErroInterno('');
      const novaListaFormatada = formatarListaItem(novaLista);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (novaListaAcao) {
        setListaAcao(novaListaAcao);
      } else {
        limparListaItemAtual();
        novaListaFormatada.forEach((item, index) => {
          handleAdicionarAcaoInserir({
            chave: item.chave,
            valor: item.valor,
            ordem: index,
          });
        });
      }

      setListaItemAtual(novaListaFormatada);
    },
    [
      formatarListaItem,
      handleAdicionarAcaoInserir,
      limparListaItemAtual,
      setListaAcao,
    ]
  );

  const adicionarString = useCallback(
    (valor: string) => {
      try {
        const item = formatarValidarString(valor);
        const itemFormatado = formatarItem(item);
        adicionarItem(itemFormatado);
      } catch (err) {
        if (err instanceof Error) {
          setErroInterno(err.message);
        }
      }
    },
    [adicionarItem, formatarItem, formatarValidarString]
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsFocused(true);

      const input = event.target;
      setErroInterno('');

      if (onChangeTexto) {
        onChangeTexto({
          input,
        });
      }

      const size = CalculateSize(input.value, {
        font: '"Roboto", sans-serif',
        fontSize: '14px',
      });

      input.style.width = `${size.width}px`;
      // input.style.width = `${1.5 * input.value.length + 1}ch`;
    },
    [onChangeTexto]
  );

  const handleFocus = useCallback(async () => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(async () => {
    if (inputRef.current?.value) {
      adicionarString(inputRef.current.value);
    }
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, [adicionarString]);

  const handleClickDivInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const mapeamentoDeNavegacao = useMemo<{ [key: string]: any }>(() => {
    return {
      Tab: () => {
        if (inputRef.current?.value) {
          adicionarString(inputRef.current?.value);
        }
      },
      Enter: (event: any) => {
        event.preventDefault();
        if (inputRef.current?.value) {
          adicionarString(inputRef.current?.value);
        }
      },
    };
  }, [adicionarString]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const chave = event.key;
      const funcao = mapeamentoDeNavegacao[chave];
      if (funcao) {
        funcao(event);
      }
    },
    [mapeamentoDeNavegacao]
  );

  useEffect(() => {
    setErroInterno(error);
  }, [error]);

  useImperativeHandle(ref, () => ({
    obterListaItemAtual() {
      return listaItemAtual.map((valor) => valor.valor);
    },
    obterListaAcao,
    obterInput() {
      return inputRef.current;
    },
    alterarListaItemAtualListaAcao(listaItemAtualNova, novaListaAcao) {
      alterarListaItemAtualListaAcao(listaItemAtualNova, novaListaAcao);
    },
    alterarListaItemAtualListaAcaoSemEvento(listaItemAtualNova, novaListaAcao) {
      alterarListaItemAtualListaAcaoSemEvento(
        listaItemAtualNova,
        novaListaAcao
      );
    },
    alterarListaAcao(listaAcao) {
      setListaAcao(listaAcao);
    },
    adicionarItem: (item) => {
      if (item) {
        const itemFormatado = formatarItem(item);
        adicionarItem(itemFormatado);
      }
    },
    removerItem(index: number) {
      removerItem(index);
    },
    moverItem(from: number, to: number) {
      moverItem(from, to);
    },
  }));

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <DefaultInputDiv
        style={{ height: 'auto' }}
        onClick={handleClickDivInput}
        $isFocused={isFocused}
        $isFilled={isFilled}
        $isErrored={!!erroInterno}
        onKeyDown={handleKeyDown}
      >
        <ContainerListaItemAtual>
          {listaItemAtual.map((valor, index) => {
            return (
              <LiDrag
                onFocus={(event) => {
                  event.stopPropagation();
                }}
                onBlur={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.currentTarget.draggable = true;
                  event.stopPropagation();
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onDoubleClick={(event) => {
                  event.stopPropagation();
                  if (event.currentTarget) {
                    event.currentTarget.draggable = false;
                    const span = event.currentTarget.querySelector('span');
                    if (!span) return;
                    const selection = window.getSelection();
                    const range = document.createRange();

                    range.selectNodeContents(span);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                  }
                }}
                id={id}
                key={valor.chave}
                index={index}
                mover={moverItem}
              >
                <span
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {valor.label}
                </span>
                <button
                  type="button"
                  style={{ height: '100%' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    removerItem(index);
                  }}
                  disabled={!valor.permitirExcluir}
                >
                  <span
                    style={{
                      background: valor.permitirExcluir ? 'red' : 'gray',
                      height: 16,
                      width: 16,
                      borderRadius: '50%',
                      border: '1px solid #000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MdClose color="#fff" />
                  </span>
                </button>
              </LiDrag>
            );
          })}
          <li>
            {permitirDigitar ? (
              <input
                style={{ margin: 0 }}
                type="text"
                ref={inputRef}
                onChange={handleChange}
              />
            ) : (
              <input style={{ margin: 0 }} type="text" />
            )}
          </li>
        </ContainerListaItemAtual>
      </DefaultInputDiv>
      {erroInterno && <SpanErro>{erroInterno}</SpanErro>}
    </div>
  );
};

export default forwardRef(TagInputBase);
