"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = SepararPalavrasPorLetraMaiuscula;
const hasUpper = (str) => {
    return /[A-Z]/.test(str);
};
function SepararPalavrasPorLetraMaiuscula(valor) {
    let valorFormatado = '';
    for (let i = 0; i < valor.length; i++) {
        if (i > 0 && hasUpper(valor[i])) {
            valorFormatado += ' ';
        }
        valorFormatado += valor[i];
    }
    return valorFormatado;
}
