import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/log';

interface IPedidoVendaLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IPedidoVendaLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class PedidoVendaLogComunicador {
  public async index(
    params: IPedidoVendaLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IPedidoVendaLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new PedidoVendaLogComunicador();
