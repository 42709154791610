"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CodigoInstrucaoCobrancaBanrisulRemessaEnum;
(function (CodigoInstrucaoCobrancaBanrisulRemessaEnum) {
    CodigoInstrucaoCobrancaBanrisulRemessaEnum["protestarCasoImpago"] = "09";
    CodigoInstrucaoCobrancaBanrisulRemessaEnum["devolverSeImpago"] = "15";
    CodigoInstrucaoCobrancaBanrisulRemessaEnum["aposDiasVencimentoCobrarMulta"] = "18";
    CodigoInstrucaoCobrancaBanrisulRemessaEnum["aposDiasVencimentoCobrarMultaAoMesOuFracao"] = "20";
    CodigoInstrucaoCobrancaBanrisulRemessaEnum["naoProtestar"] = "23";
})(CodigoInstrucaoCobrancaBanrisulRemessaEnum || (CodigoInstrucaoCobrancaBanrisulRemessaEnum = {}));
exports.default = CodigoInstrucaoCobrancaBanrisulRemessaEnum;
