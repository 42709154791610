import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/liquidacao-contas-em-lote';

interface ILiquidacaoContasEmLoteComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ILiquidacaoContasEmLoteComunicadorStore {
  params: any;
}

class LiquidacaoContasEmLoteComunicador {
  public async index(
    params: ILiquidacaoContasEmLoteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async store(
    params: ILiquidacaoContasEmLoteComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new LiquidacaoContasEmLoteComunicador();
