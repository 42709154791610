import api from '../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico-importacao-orcamento/importar';

interface IOrdemServicoImportacaoOrcamentoImportarComunicadorStore {
  params: any;
}

class OrdemServicoImportacaoOrcamentoImportarComunicador {
  public async store(
    params: IOrdemServicoImportacaoOrcamentoImportarComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new OrdemServicoImportacaoOrcamentoImportarComunicador();
