import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-portador/ordem';

interface IRegraEscolhaPortadorOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaPortadorOrdemComunicador {
  public async update(
    params: IRegraEscolhaPortadorOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaPortadorOrdemComunicador();
