import api from '../../../../../Comum/Services/Api';

const rota = '/contabil/contas-contabeis/conta-contabil/obter-dados-padrao';

interface IContaContabilObterDadosPadraoComunicadorShow {
  idContaContabilPai?: string;
}

class ContaContabilObterDadosPadraoComunicador {
  public async show(
    params?: IContaContabilObterDadosPadraoComunicadorShow
  ): Promise<any> {
    const response = await api.get(
      `${rota}/${params?.idContaContabilPai || ''}`
    );
    return response.data;
  }
}

export default new ContaContabilObterDadosPadraoComunicador();
