import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/motorista-auto-complete-tag';

interface IPessoaMotoristaAutoCompleteTagComunicadorIndex {
  params: any;
}

class PessoaMotoristaAutoCompleteTagComunicador {
  public async index(
    params: IPessoaMotoristaAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaMotoristaAutoCompleteTagComunicador();
