"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoImportacaoEnum;
(function (SituacaoImportacaoEnum) {
    SituacaoImportacaoEnum["importar"] = "Importar";
    SituacaoImportacaoEnum["importado"] = "Importado";
    SituacaoImportacaoEnum["naoSeraImportado"] = "N\u00E3o Ser\u00E1 Importado";
})(SituacaoImportacaoEnum || (SituacaoImportacaoEnum = {}));
exports.default = SituacaoImportacaoEnum;
