/* eslint-disable default-case */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import {
  BooleanEnum,
  DestinadoEnum,
  EstoqueIndicadorTipoEnum,
  FinalidadeEmissaoEnum,
  FormatarEnum,
  IndicadorPresencaPadraoEnum,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista,
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista,
  ModalidadeFreteEnum,
  TipoMercadoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import { MdCancel } from 'react-icons/md/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import BtnCloseButton from '../../../../Componentes/Buttons/BtnCloseButton';
import UseStep from '../../../../Hooks/UseStep';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicador';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicador';
import TratarErros from '../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicador';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicador';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1, {
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Ref,
} from './Etapa1';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2, {
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Ref,
} from './Etapa2';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3, {
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Ref,
} from './Etapa3';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicador';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4, {
  INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Ref,
} from './Etapa4';
import { Sleep } from '../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import IPadraoProps from '../../../../Comum/Interface/IPadraoProps';
import TratarInfo from '../../../../Util/Info/TratarInfo';
import Select from '../../../../Componentes/Select';
import InputAutoCompleteTipoNotaMotivo from '../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputAutoCompleteTipoNota from '../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import Divisor from '../../../../Componentes/Divisor';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador';
import NotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicador from '../../../../Comunicador/Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntrada/Comunicador/NotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicador';
import InputSwitch from '../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompletePessoa from '../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import PessoaObterTipoMercadoComunicador from '../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterTipoMercadoComunicador';
import { UseConfirmacao } from '../../../../Componentes/Confirmacao/HooksConfirmacao';
import NotaFiscalEntradaComunicador from '../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaComunicador';
import ToastInfo from '../../../../Util/Toasts/ToastInfo';

export interface IAbrirNotaFiscalSaidaImportacaoNotaFiscalEntrada {
  idPessoa: string;
  pessoa?: any;
  idNotaFiscalEntrada?: string;
  idNotaFiscalSaida?: string;
  indicadorPresenca?: IndicadorPresencaPadraoEnum;
  tipoMercado?: TipoMercadoEnum;
  finalidadeEmissao?: FinalidadeEmissaoEnum;
}

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaContext {
  setTitulo(titulo: string): void;
  abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada({
    idPessoa,
    pessoa,
    idNotaFiscalEntrada,
    idNotaFiscalSaida,
    indicadorPresenca,
    tipoMercado,
    finalidadeEmissao,
  }: IAbrirNotaFiscalSaidaImportacaoNotaFiscalEntrada): Promise<any>;
  fecharNotaFiscalSaidaImportacaoNotaFiscalEntrada(): void;
}

const NotaFiscalSaidaImportacaoNotaFiscalEntradaContext =
  createContext<INotaFiscalSaidaImportacaoNotaFiscalEntradaContext>(
    {} as INotaFiscalSaidaImportacaoNotaFiscalEntradaContext
  );

const NotaFiscalSaidaImportacaoNotaFiscalEntradaHook: React.FC<
  IPadraoProps
> = ({ children }) => {
  const { proximoStep, stepAnterior, step, setStep } = UseStep(4);
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { abrirJanela } = UseConfirmacao();
  const resolveRef = useRef<any>();

  const [titulo, setTitulo] = useState('');
  const [aberto, setAberto] = useState(false);

  const [idPessoaNotaFiscal, setIdPessoaNotaFiscal] = useState<
    string | undefined
  >();
  const [pessoaNotaFiscal, setPessoaNotaFiscal] = useState<any | undefined>();
  const [idNotaFiscalEntradaOrigem, setIdNotaFiscalEntradaOrigem] = useState<
    string | undefined
  >();
  const [idNotaFiscalSaidaOrigem, setIdNotaFiscalSaidaOrigem] = useState<
    string | undefined
  >();
  const [indicadorPresencaPadrao, setIndicadorPresencaPadrao] = useState<
    IndicadorPresencaPadraoEnum | undefined
  >();
  const [tipoMercadoPadrao, setTipoMercadoPadrao] = useState<
    TipoMercadoEnum | undefined
  >();
  const [origemNotaFiscalEntrada, setOrigemNotaFiscalEntrada] = useState(true);

  const [loading, setLoading] = useState(false);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  // #region Etapa 1 - Nota Fiscal Entrada

  const formRefEtapa1 = useRef<IFormCiaHandles>(null);
  const etapa1Ref =
    useRef<INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Ref>(null);

  const handleCarregarDadosEtapa1 = useCallback(
    async (idPessoa?: string, idNotaFiscalEntrada?: string, pessoa?: any) => {
      try {
        setLoading(true);

        const tipoNota =
          formRefEtapa1.current?.getFieldValueNomeObjeto('tipoNota');
        const tipoNotaMotivo =
          formRefEtapa1.current?.getFieldValueNomeObjeto('tipoNotaMotivo');
        const destinado = formRefEtapa1.current?.getFieldValue('destinado');
        const movimentarEstoqueFiscal = formRefEtapa1.current?.getFieldValue(
          'movimentarEstoqueFiscal'
        );
        const finalidadeEmissao =
          formRefEtapa1.current?.getFieldValue('finalidadeEmissao');
        const retornoDevolucao =
          formRefEtapa1.current?.getFieldValue('retornoDevolucao');

        let listaOrdenada: any[] = [];
        if (idPessoa) {
          const response =
            await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaComunicador.index(
              {
                idPessoa,
                idNotaFiscalEntrada,
                movimentarEstoqueFiscal,
              }
            );

          listaOrdenada = response.listaNotaFiscalEntrada.Ordenar([
            { campo: 'numero', tipo: 'desc' },
          ]);
        }

        await formRefEtapa1.current?.setDataInicialSemExecutarEvento({
          listaEtapa1: listaOrdenada,
          tipoNota,
          tipoNotaMotivo,
          destinado,
          movimentarEstoqueFiscal,
          retornoDevolucao,
          finalidadeEmissao,
          cliente: pessoa,
        });

        if (idPessoa) {
          etapa1Ref.current?.handleSelecionarTodosEtapa(true);
        } else {
          etapa1Ref.current?.handleSelecionarTodosEtapa(false);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  const handleObterRegrasEtapa1 = useCallback(async () => {
    try {
      setLoading(true);

      const idTipoNota = formRefEtapa1.current?.getFieldValue('idTipoNota');
      const idTipoNotaMotivo =
        formRefEtapa1.current?.getFieldValue('idTipoNotaMotivo');
      const destinado = formRefEtapa1.current?.getFieldValue('destinado');
      const movimentarEstoqueFiscal = formRefEtapa1.current?.getFieldValue(
        'movimentarEstoqueFiscal'
      );

      if (
        !idTipoNota ||
        !idTipoNotaMotivo ||
        !destinado ||
        !tipoMercadoPadrao
      ) {
        setLoading(false);
        return;
      }

      const response =
        await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicador.index(
          {
            idPessoa: idPessoaNotaFiscal,
            idNotaFiscalSaida: idNotaFiscalSaidaOrigem,
            idNotaFiscalEntrada: idNotaFiscalEntradaOrigem,
            dataHoraEmissao: new Date(),
            idTipoNota,
            idTipoNotaMotivo,
            destinado,
            tipoMercado: tipoMercadoPadrao,
          }
        );

      formRefEtapa1.current?.setFieldValue(
        'finalidadeEmissao',
        response.regraEscolhaOperacaoFiscal?.finalidadeEmissao
      );

      const movimentarEstoqueFiscalNovo = response.regraEscolhaParametro
        ? response.regraEscolhaParametro.movimentarEstoqueFiscal
        : '';

      if (movimentarEstoqueFiscal !== movimentarEstoqueFiscalNovo) {
        formRefEtapa1.current?.setFieldValue(
          'movimentarEstoqueFiscal',
          movimentarEstoqueFiscalNovo
        );

        await handleCarregarDadosEtapa1(
          idPessoaNotaFiscal,
          idNotaFiscalEntradaOrigem,
          pessoaNotaFiscal
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleCarregarDadosEtapa1,
    idNotaFiscalEntradaOrigem,
    idNotaFiscalSaidaOrigem,
    idPessoaNotaFiscal,
    pessoaNotaFiscal,
    tipoMercadoPadrao,
  ]);

  const handleObterTipoMercadoEtapa1 = useCallback(
    async (idPessoa?: string) => {
      try {
        setLoading(true);

        if (!idPessoa) {
          setLoading(false);
          return;
        }

        const response = await PessoaObterTipoMercadoComunicador.show({
          id: idPessoa,
        });

        setTipoMercadoPadrao(response);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  // #endregion Etapa 1 - Nota Fiscal Entrada

  // #region Etapa 2 - Itens

  const formRefEtapa2 = useRef<IFormCiaHandles>(null);
  const etapa2Ref =
    useRef<INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Ref>(null);

  const handleCarregarDadosEtapa2 = useCallback(
    async (idNotaFiscalEntrada?: string) => {
      try {
        setLoading(true);

        const movimentarEstoqueFiscal = formRefEtapa1.current?.getFieldValue(
          'movimentarEstoqueFiscal'
        );

        const listaItemEtapa1 =
          etapa1Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1Lista[];
        const listaItemEtapa2 =
          etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista[];

        let listaIdNotaFiscalEntradaSelecionados: string[] = [];
        if (idNotaFiscalEntrada) {
          listaIdNotaFiscalEntradaSelecionados.push(idNotaFiscalEntrada);
        } else {
          listaIdNotaFiscalEntradaSelecionados = listaItemEtapa1.map(
            (item) => item.id
          );
        }

        const response =
          await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemComunicador.index(
            {
              idPessoa: String(idPessoaNotaFiscal),
              movimentarEstoqueFiscal,
              listaIdNotaFiscalEntrada: listaIdNotaFiscalEntradaSelecionados,
            }
          );

        if (listaItemEtapa2) {
          response.listaNotaFiscalEntradaItem =
            response.listaNotaFiscalEntradaItem.map(
              (
                itemEtapa2Novo: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista
              ) => {
                const itemExistente = listaItemEtapa2.find(
                  (
                    itemEtapa2Atual: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista
                  ) => itemEtapa2Atual.id === itemEtapa2Novo.id
                );

                if (itemExistente) {
                  itemExistente.ordem = itemEtapa2Novo.ordem;
                  return itemExistente;
                }

                return itemEtapa2Novo;
              }
            );
        }

        const listaOrdenada = response.listaNotaFiscalEntradaItem.Ordenar([
          { campo: 'numero', tipo: 'desc' },
          { campo: 'ordem', tipo: 'desc' },
        ]);

        await formRefEtapa2.current?.setDataInicialSemExecutarEvento({
          listaEtapa2: listaOrdenada,
        });
        etapa2Ref.current?.handleSelecionarTodosEtapa(true);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [idPessoaNotaFiscal]
  );

  // #endregion Etapa 2 - Itens

  // #region Etapa 3 - Tipo Nota/Motivo

  const formRefEtapa3 = useRef<IFormCiaHandles>(null);
  const etapa3Ref =
    useRef<INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Ref>(null);

  const handleCarregarDadosEtapa3 = useCallback(async () => {
    try {
      setLoading(true);

      const dadosEtapa1 = formRefEtapa1.current?.getDataDuplicar();
      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista[];
      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[];

      const listaItemEtapa3Novo: any[] = [];

      const listaItemEtapa3Selecionado = listaItemEtapa2.map((item) => {
        const retorno: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista =
          {
            ...item,
            tipoNota: dadosEtapa1?.tipoNota,
            tipoNotaMotivo: dadosEtapa1?.tipoNotaMotivo,
            destinado: dadosEtapa1?.destinado,
          };

        return retorno;
      });

      const response =
        await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemOperacaoFiscalComunicador.index(
          {
            idPessoa: String(idPessoaNotaFiscal),
            idNotaFiscalSaida: idNotaFiscalSaidaOrigem,
            idNotaFiscalEntrada: idNotaFiscalEntradaOrigem,
            finalidadeEmissao: dadosEtapa1.finalidadeEmissao,
            tipoMercado: tipoMercadoPadrao,
            listaItemSelecionado: listaItemEtapa3Selecionado,
          }
        );

      for (let i = 0; i < response.listaItem.length; i++) {
        const itemRetorno = response.listaItem[i];

        const itemEtapa3Novo = listaItemEtapa3Selecionado.find(
          (element) => element.id === itemRetorno.id
        );

        const itemEtapa3Atual = listaItemEtapa3.find(
          (element) => element.id === itemRetorno.id
        );

        if (itemEtapa3Novo) {
          if (itemEtapa3Atual) {
            itemEtapa3Novo.tipoNota = itemEtapa3Atual.tipoNota;
            itemEtapa3Novo.tipoNotaMotivo = itemEtapa3Atual.tipoNotaMotivo;
            itemEtapa3Novo.destinado = itemEtapa3Atual.destinado;
            itemEtapa3Novo.operacaoFiscal = itemEtapa3Atual.operacaoFiscal;
            itemEtapa3Novo.naturezaOperacao = itemEtapa3Atual.naturezaOperacao;
          } else {
            itemEtapa3Novo.operacaoFiscal = itemRetorno.operacaoFiscal;
            itemEtapa3Novo.naturezaOperacao = itemRetorno.naturezaOperacao;
          }

          listaItemEtapa3Novo.push(itemEtapa3Novo);
        }
      }

      const listaOrdenada = listaItemEtapa3Novo.Ordenar([
        { campo: 'numero', tipo: 'desc' },
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa3.current?.setDataInicialSemExecutarEvento({
        listaEtapa3: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    idNotaFiscalEntradaOrigem,
    idNotaFiscalSaidaOrigem,
    idPessoaNotaFiscal,
    tipoMercadoPadrao,
  ]);

  // #endregion Etapa 3 - Tipo Nota/Motivo

  // #region Etapa 4 - Estoque

  const formRefEtapa4 = useRef<IFormCiaHandles>(null);
  const etapa4Ref =
    useRef<INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Ref>(null);

  const handleCarregarDadosEtapa4 = useCallback(async () => {
    try {
      setLoading(true);

      const listaItemEtapa2 =
        etapa2Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2Lista[];
      const listaItemEtapa3 =
        etapa3Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3Lista[];
      const listaItemEtapa4 =
        etapa4Ref.current?.handleObterListaValorSelecionados() as INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[];

      const listaItemEtapa4Novo: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista[] =
        [];

      for (let index = 0; index < listaItemEtapa3.length; index++) {
        const itemEtapa2 = listaItemEtapa2[index];
        const itemEtapa3 = listaItemEtapa3[index];

        const itemExistente = listaItemEtapa4.find(
          (
            itemEtapa4Atual: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista
          ) => itemEtapa4Atual.id === itemEtapa3.id
        );

        if (
          itemExistente &&
          itemEtapa3.tipoNota &&
          itemExistente.tipoNota &&
          itemEtapa3.tipoNota.id === itemExistente.tipoNota.id &&
          itemEtapa3.tipoNotaMotivo &&
          itemExistente.tipoNotaMotivo &&
          itemEtapa3.tipoNotaMotivo.id === itemExistente.tipoNotaMotivo.id &&
          itemEtapa3.destinado === itemExistente.destinado
        ) {
          // Retorna o item da etapa 4 que ja existe ajustando as quantidades

          itemExistente.quantidadeTransferir = itemEtapa3.quantidadeTransferir;
          itemExistente.valorTotal = itemEtapa3.valorTotal;

          const quantidadeEstoque =
            Number(itemEtapa3.quantidadeTransferir) *
            Number(itemExistente.notaFiscalEntradaItemEstoque.fatorConversao);

          itemExistente.notaFiscalEntradaItemEstoque.quantidadeEstoque =
            quantidadeEstoque;

          if (
            itemExistente.notaFiscalEntradaItemEstoque
              .listaEstoqueLocalEstoque &&
            itemExistente.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
              .length === 1
          ) {
            itemExistente.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque[0].quantidade =
              quantidadeEstoque;
            itemExistente.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque =
              quantidadeEstoque;
          }

          if (
            itemExistente.notaFiscalEntradaItemEstoque
              .estoqueTipoEntradaSaida === TipoMovimentoEntradaSaidaEnum.entrada
          ) {
            const totalEntrada =
              itemExistente.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.entrada
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalEntrada || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.notaFiscalEntradaItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          } else {
            const totalSaida =
              itemExistente.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque
                ?.filter(
                  (
                    estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
                  ) => {
                    return (
                      estoque.tipoEntradaSaida ===
                      TipoMovimentoEntradaSaidaEnum.saida
                    );
                  }
                )
                .reduce(
                  (
                    acumulador: number,
                    estoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista
                  ) => {
                    acumulador += Number(estoque.quantidade);
                    return acumulador;
                  },
                  0
                );

            const total = totalSaida || 0;
            let estoqueQuantidade = quantidadeEstoque - total;
            if (estoqueQuantidade < 0) estoqueQuantidade = 0;

            itemExistente.notaFiscalEntradaItemEstoque.estoqueQuantidade =
              estoqueQuantidade;
          }

          listaItemEtapa4Novo.push(itemExistente);
        } else {
          // Cria um novo item apartir da etapa 3

          const response =
            await NotaFiscalSaidaImportacaoNotaFiscalEntradaObterNotaFiscalEntradaItemEstoqueComunicador.index(
              {
                dataHoraEmissao: new Date(),
                idNotaFiscalEntradaItem: itemEtapa3.id,
                quantidade: itemEtapa3.quantidadeTransferir,
                idTipoNota: String(itemEtapa3.tipoNota?.id),
                idTipoNotaMotivo: String(itemEtapa3.tipoNotaMotivo?.id),
                destinado: itemEtapa3.destinado as DestinadoEnum,
                idOperacaoFiscal: String(itemEtapa3.operacaoFiscal?.id),
              }
            );

          const retorno: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4Lista =
            {
              id: itemEtapa3.id,
              numero: itemEtapa3.numero,
              ordem: itemEtapa3.ordem,
              pessoa: itemEtapa3.pessoa,
              produto: itemEtapa3.produto,
              unidadeMedida: itemEtapa3.unidadeMedida,
              quantidade: itemEtapa3.quantidade,
              quantidadeTransferir: itemEtapa3.quantidadeTransferir,
              valorUnitario: itemEtapa3.valorUnitario,
              valorTotal: itemEtapa3.valorTotal,

              tipoNota: itemEtapa3.tipoNota,
              tipoNotaMotivo: itemEtapa3.tipoNotaMotivo,
              destinado: itemEtapa3.destinado,
              modalidadeFrete: itemEtapa3.modalidadeFrete,

              notaFiscalEntradaItemEstoque: {
                movimentarEstoque: response.produto.estoque.movimentarEstoque,
                movimentarEstoqueFiscal:
                  response.produto.estoque.movimentarEstoqueFiscal,
                gerarComissao: response.produto.estoque.gerarComissao,
                gerarFaturamento: response.produto.estoque.gerarFaturamento,
                gerarFinanceiro: response.produto.estoque.gerarFinanceiro,
                unidadeMedida: itemEtapa3.unidadeMedida,
                indicadorTipoEstoque:
                  response.produto.estoque.indicadorTipoEstoque,
                fatorConversao: 1,
                quantidadeEstoque: itemEtapa3.quantidadeTransferir,
                quantidadeTotalEstoqueLocalEstoque: 0,
                porContaOrdemTerceiro:
                  response.produto.estoque.porContaOrdemTerceiro,
                permiteTransferirItem: !(
                  !itemEtapa2.movimentarEstoqueFiscal &&
                  response.produto.estoque.movimentarEstoqueFiscal
                ),

                estoqueTipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                estoqueIndicadorTipoEstoque:
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                estoqueQuantidade: 0,

                listaEstoqueLocalEstoque: [],
              },
            };

          let estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
          let estoqueIndicadorTipoEstoque =
            EstoqueIndicadorTipoEnum.proprioEmPoderProprio;
          let estoqueQuantidade = itemEtapa3.quantidadeTransferir;
          let quantidadeTotalEstoqueLocalEstoque = 0;

          const listaEstoqueLocalEstoque: INotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4EstoqueLocalEstoqueLista[] =
            [];

          switch (response.produto.estoque.indicadorTipoEstoque) {
            case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa3.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa3.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.proprioEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa3.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa3.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa3.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.proprioEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa3.pessoa,
                  }
                );
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderProprio:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa3.quantidadeTransferir;

                listaEstoqueLocalEstoque.push({
                  ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                  quantidade: itemEtapa3.quantidadeTransferir,
                  tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                  indicadorTipoEstoque:
                    EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                  pessoaTerceiro: itemEtapa3.pessoa,
                });
              }
              break;

            case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
              estoqueTipoEntradaSaida = TipoMovimentoEntradaSaidaEnum.saida;
              estoqueIndicadorTipoEstoque =
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio;

              if (
                response.produto.estoque.listaEstoqueLocalEstoque.length > 0
              ) {
                estoqueQuantidade = 0;
                quantidadeTotalEstoqueLocalEstoque =
                  itemEtapa3.quantidadeTransferir;

                listaEstoqueLocalEstoque.push(
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa3.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderProprio,
                    pessoaTerceiro: itemEtapa3.pessoa,
                  },
                  {
                    ...response.produto.estoque.listaEstoqueLocalEstoque[0],
                    quantidade: itemEtapa3.quantidadeTransferir,
                    tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.entrada,
                    indicadorTipoEstoque:
                      EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro,
                    pessoaTerceiro: itemEtapa3.pessoa,
                  }
                );
              }
              break;
          }

          retorno.notaFiscalEntradaItemEstoque.listaEstoqueLocalEstoque =
            listaEstoqueLocalEstoque;
          retorno.notaFiscalEntradaItemEstoque.estoqueTipoEntradaSaida =
            estoqueTipoEntradaSaida;
          retorno.notaFiscalEntradaItemEstoque.estoqueIndicadorTipoEstoque =
            estoqueIndicadorTipoEstoque;
          retorno.notaFiscalEntradaItemEstoque.estoqueQuantidade =
            estoqueQuantidade;
          retorno.notaFiscalEntradaItemEstoque.quantidadeTotalEstoqueLocalEstoque =
            quantidadeTotalEstoqueLocalEstoque;

          listaItemEtapa4Novo.push(retorno);
        }
      }

      const listaOrdenada = listaItemEtapa4Novo.Ordenar([
        { campo: 'numero', tipo: 'desc' },
        { campo: 'ordem', tipo: 'desc' },
      ]);

      await formRefEtapa4.current?.setDataInicialSemExecutarEvento({
        listaEtapa4: listaOrdenada,
      });
      await Sleep(50);
      await formRefEtapa4.current?.setDataInicialSemExecutarEvento({
        listaEtapa4: listaOrdenada,
      });

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  // #endregion Etapa 4 - Estoque

  const handleProximoPasso = useCallback(async (): Promise<void> => {
    switch (step) {
      case 1:
        if (!(await etapa1Ref.current?.handleValidar())) return;

        proximoStep();
        await handleCarregarDadosEtapa2();
        break;

      case 2:
        if (!(await etapa2Ref.current?.handleValidar())) return;

        proximoStep();
        await handleCarregarDadosEtapa3();
        break;

      case 3:
        if (!(await etapa3Ref.current?.handleValidar())) return;

        proximoStep();
        await handleCarregarDadosEtapa4();
        break;
    }
  }, [
    handleCarregarDadosEtapa2,
    handleCarregarDadosEtapa3,
    handleCarregarDadosEtapa4,
    proximoStep,
    step,
  ]);

  const handlePassoAnterior = useCallback((): void => {
    stepAnterior();
  }, [stepAnterior]);

  const handleSetTitulo = useCallback((tituloJanela: string): void => {
    setTitulo(tituloJanela);
  }, []);

  const handleAbrir = useCallback(
    async ({
      idPessoa,
      pessoa,
      idNotaFiscalEntrada,
      idNotaFiscalSaida,
      indicadorPresenca,
      tipoMercado,
      finalidadeEmissao,
    }: IAbrirNotaFiscalSaidaImportacaoNotaFiscalEntrada): Promise<any> => {
      setAberto(true);
      setIdPessoaNotaFiscal(idPessoa);
      setPessoaNotaFiscal(pessoa);
      setIdNotaFiscalEntradaOrigem(idNotaFiscalEntrada);
      setIdNotaFiscalSaidaOrigem(idNotaFiscalSaida);
      setIndicadorPresencaPadrao(indicadorPresenca);
      setTipoMercadoPadrao(tipoMercado);

      if (!idNotaFiscalEntrada) {
        setOrigemNotaFiscalEntrada(false);
      } else {
        setOrigemNotaFiscalEntrada(true);
      }

      setStep(1);
      await handleCarregarDadosEtapa1(idPessoa, idNotaFiscalEntrada, pessoa);

      formRefEtapa1.current?.setFieldValue(
        'finalidadeEmissao',
        finalidadeEmissao
      );

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDadosEtapa1, setStep]
  );

  const handleFechar = useCallback(() => {
    setAberto(false);
    resolveRef.current(null);
  }, []);

  const handleValidarModalidadeFreteNotaFiscalEntrada = useCallback(
    async (
      valoresEtapa3: any
    ): Promise<{
      resposta: boolean | undefined;
      isModalidadeDiferente: boolean | undefined;
    }> => {
      let isModalidadeFreteDiferente = false;
      let modalidadeFreteNotaFiscalEntrada: ModalidadeFreteEnum | null = null;

      if (valoresEtapa3) {
        if (valoresEtapa3.listaEtapa3.length > 1) {
          valoresEtapa3.listaEtapa3.forEach((item: any, index: number) => {
            if (index > 0) {
              if (
                valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete &&
                item.modalidadeFrete
              ) {
                if (
                  valoresEtapa3.listaEtapa3[index - 1].modalidadeFrete !==
                  item.modalidadeFrete
                ) {
                  isModalidadeFreteDiferente = true;
                  modalidadeFreteNotaFiscalEntrada = null;
                } else {
                  modalidadeFreteNotaFiscalEntrada = item.modalidadeFrete;
                }
              } else {
                isModalidadeFreteDiferente = true;
                modalidadeFreteNotaFiscalEntrada = null;
              }
            }
          });
        } else {
          modalidadeFreteNotaFiscalEntrada =
            valoresEtapa3.listaEtapa3[0].modalidadeFrete;
        }
      }

      if (
        !isModalidadeFreteDiferente &&
        modalidadeFreteNotaFiscalEntrada &&
        idNotaFiscalEntradaOrigem
      ) {
        const notaFiscalEntrada = await NotaFiscalEntradaComunicador.show({
          id: String(idNotaFiscalEntradaOrigem),
        });

        if (
          modalidadeFreteNotaFiscalEntrada !==
          notaFiscalEntrada.notaFiscalEntradaTransporteTransportadora
            .modalidadeFrete
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span
                style={{ fontSize: 20 }}
              >{`Modalidade de Frete da Nota Fiscal de Saída: ${notaFiscalEntrada.notaFiscalEntradaTransporteTransportadora.modalidadeFrete} e da Nota Fiscal de Entrada ${modalidadeFreteNotaFiscalEntrada}, deseja atualizar a Nota Fiscal para ${modalidadeFreteNotaFiscalEntrada}? `}</span>
            ),
          });

          return { resposta, isModalidadeDiferente: false };
        }
      }

      return {
        resposta: false,
        isModalidadeDiferente: isModalidadeFreteDiferente,
      };
    },
    [abrirJanela, idNotaFiscalEntradaOrigem]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (!(await etapa4Ref.current?.handleValidar())) return;

      setLoading(true);

      const retornoDevolucao =
        formRefEtapa1.current?.getFieldValue('retornoDevolucao');
      const finalidadeEmissao =
        formRefEtapa1.current?.getFieldValue('finalidadeEmissao');

      const valoresEtapa3 = formRefEtapa3.current?.getData();
      const valoresEtapa4 = formRefEtapa4.current?.getData();

      const valoresEtapa4Transferir = valoresEtapa4?.listaEtapa4.filter(
        (e: any) => e.notaFiscalEntradaItemEstoque.permiteTransferirItem
      );

      if (!valoresEtapa4Transferir || valoresEtapa4Transferir.length === 0) {
        TratarErros({
          mensagem: 'Nenhum item possui Estoque Fiscal para ser transferido!',
        });
        setLoading(false);
        return;
      }

      let response: any = null;
      if (origemNotaFiscalEntrada) {
        response =
          await NotaFiscalSaidaImportacaoNotaFiscalEntradaGerarComunicador.store(
            {
              params: {
                retornoDevolucao,
                finalidadeEmissao,
                pessoa: pessoaNotaFiscal,
                valoresEtapa3,
                valoresEtapa4: {
                  listaEtapa4: [...valoresEtapa4Transferir],
                },
                idNotaFiscalEntradaOrigem,
              },
            }
          );
      } else {
        const { resposta, isModalidadeDiferente } =
          await handleValidarModalidadeFreteNotaFiscalEntrada(valoresEtapa3);

        if (isModalidadeDiferente) {
          ToastInfo(
            'Atenção as Notas de Entradas estão com modalidade de frete diferente entre si, favor verificar a modalidade de frete da Nota de Saída!'
          );
        }
        response =
          await NotaFiscalSaidaImportacaoNotaFiscalEntradaImportarComunicador.store(
            {
              params: {
                retornoDevolucao,
                finalidadeEmissao,
                alterarModalidadeFrete: resposta,
                valoresEtapa3,
                valoresEtapa4: {
                  listaEtapa4: [...valoresEtapa4Transferir],
                },
                idNotaFiscalSaidaOrigem,
              },
            }
          );
      }

      if (
        response.listaMensagemAviso &&
        response.listaMensagemAviso.length > 0
      ) {
        TratarInfo(response.listaMensagemAviso);
      }

      if (resolveRef.current) {
        resolveRef.current(response);
      }

      setAberto(false);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    origemNotaFiscalEntrada,
    pessoaNotaFiscal,
    idNotaFiscalEntradaOrigem,
    handleValidarModalidadeFreteNotaFiscalEntrada,
    idNotaFiscalSaidaOrigem,
  ]);

  return (
    <NotaFiscalSaidaImportacaoNotaFiscalEntradaContext.Provider
      value={{
        setTitulo: handleSetTitulo,
        abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada: handleAbrir,
        fecharNotaFiscalSaidaImportacaoNotaFiscalEntrada: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="xl">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Modal.Title>{titulo}</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body>
          {!telaPequena && (
            <StepContainer>
              <Step>
                <StepBox type="button" $selecionado={step === 1}>
                  1
                </StepBox>
                <StepNome>Notas de Entrada</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 2}>
                  2
                </StepBox>
                <StepNome>Itens</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 3}>
                  3
                </StepBox>
                <StepNome>Tipo Nota/Motivo</StepNome>
              </Step>

              <Step>
                <StepBox type="button" $selecionado={step === 4}>
                  4
                </StepBox>
                <StepNome>Estoque</StepNome>
              </Step>
            </StepContainer>
          )}

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa1} style={{ height: 650 }}>
              <Divisor>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <InputAutoCompleteTipoNota
                      label="Tipo de Nota"
                      name="idTipoNota"
                      placeholder="Tipo de Nota"
                      nomeObjeto="tipoNota"
                      filtroTipoMovimento={TipoMovimentoEntradaSaidaEnum.saida}
                      onChangeItemAtualAposCarregarSemClear={(event) => {
                        formRefEtapa1.current?.clearField('tipoNotaMotivo', {
                          setValorInicial: false,
                        });
                        refresh();
                        handleObterRegrasEtapa1();

                        if (
                          event.itemAtual &&
                          (event.itemAtual.descricao
                            .RemoverCaracteresEspeciais()
                            .toLowerCase()
                            .includes('retorno') ||
                            event.itemAtual.descricao
                              .RemoverCaracteresEspeciais()
                              .toLowerCase()
                              .includes('devolucao'))
                        ) {
                          formRefEtapa1.current?.setFieldValue(
                            'retornoDevolucao',
                            true
                          );
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <InputAutoCompleteTipoNotaMotivo
                      label="Motivo"
                      name="idTipoNotaMotivo"
                      placeholder="Motivo"
                      nomeObjeto="tipoNotaMotivo"
                      idTipoNota={formRefEtapa1.current?.getFieldValue(
                        'idTipoNota'
                      )}
                      onChangeItemAtualAposCarregarSemClear={(event) => {
                        refresh();
                        handleObterRegrasEtapa1();

                        if (
                          event.itemAtual &&
                          (event.itemAtual.descricao
                            .RemoverCaracteresEspeciais()
                            .toLowerCase()
                            .includes('retorno') ||
                            event.itemAtual.descricao
                              .RemoverCaracteresEspeciais()
                              .toLowerCase()
                              .includes('devolucao'))
                        ) {
                          formRefEtapa1.current?.setFieldValue(
                            'retornoDevolucao',
                            true
                          );
                        }
                      }}
                    />
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <Select
                      label="Destinado"
                      name="destinado"
                      options={FormatarEnum({
                        enumObj: DestinadoEnum,
                        nullavel: true,
                      })}
                      onChange={() => {
                        refresh();
                        handleObterRegrasEtapa1();
                      }}
                    />
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <Select
                      label="Movimentar Estoque Fiscal"
                      name="movimentarEstoqueFiscal"
                      options={FormatarEnum({
                        enumObj: BooleanEnum,
                        todos: true,
                      })}
                      onChange={() => {
                        refresh();
                        handleCarregarDadosEtapa1(
                          idPessoaNotaFiscal,
                          idNotaFiscalEntradaOrigem,
                          pessoaNotaFiscal
                        );
                      }}
                    />
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <InputSwitch
                      label="É Retorno ou Devolução"
                      name="retornoDevolucao"
                      ativo="Sim"
                      inativo="Não"
                    />
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <Select
                      label="Finalidade Emissão"
                      name="finalidadeEmissao"
                      options={FormatarEnum({
                        enumObj: FinalidadeEmissaoEnum,
                        nullavel: true,
                      })}
                    />
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ display: !origemNotaFiscalEntrada ? 'none' : '' }}
                  >
                    <InputAutoCompletePessoa
                      label="Cliente"
                      name="idCliente"
                      placeholder="Cliente"
                      nomeObjeto="cliente"
                      onChangeItemAtualAposCarregarSemClear={(event) => {
                        setIdPessoaNotaFiscal(event.itemAtual?.id);
                        setPessoaNotaFiscal(event.itemAtual);
                        refresh();

                        handleObterTipoMercadoEtapa1(event.itemAtual?.id);
                        handleCarregarDadosEtapa1(
                          event.itemAtual?.id,
                          idNotaFiscalEntradaOrigem,
                          event.itemAtual
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Divisor>

              <NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa1
                name="listaEtapa1"
                formRef={formRefEtapa1}
                ref={etapa1Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa2}>
              <NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa2
                name="listaEtapa2"
                formRef={formRefEtapa2}
                ref={etapa2Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 3 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa3}>
              <NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa3
                name="listaEtapa3"
                formRef={formRefEtapa3}
                ref={etapa3Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
                idPessoaNotaFiscal={idPessoaNotaFiscal}
                idNotaFiscalSaidaOrigem={idNotaFiscalSaidaOrigem}
                idNotaFiscalEntradaOrigem={idNotaFiscalEntradaOrigem}
                indicadorPresenca={indicadorPresencaPadrao}
                finalidadeEmissao={formRefEtapa1.current?.getFieldValue(
                  'finalidadeEmissao'
                )}
                tipoMercado={tipoMercadoPadrao}
              />
            </FormCia>
          </div>

          <div style={{ display: step === 4 ? 'block' : 'none' }}>
            <FormCia ref={formRefEtapa4}>
              <NotaFiscalSaidaImportacaoNotaFiscalEntradaEtapa4
                name="listaEtapa4"
                formRef={formRefEtapa4}
                ref={etapa4Ref}
                loading={loading}
                setLoading={setLoading}
                refresh={refresh}
              />
            </FormCia>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Container>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: step === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handleFechar}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 16,
                        }}
                      >
                        <MdCancel />
                        <span style={{ marginLeft: 10 }}>Cancelar</span>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 2 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleProximoPasso}
                      style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImArrowRight />
                      <span style={{ marginLeft: 10 }}>Próximo passo</span>
                    </button>
                  </div>
                </div>

                <div style={{ display: step === 4 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="btn-padrao btn-cinza-claro"
                      onClick={handlePassoAnterior}
                    >
                      <IoMdReturnLeft />
                      <span style={{ marginLeft: 10 }}>Voltar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-padrao btn-azul-claro"
                      onClick={handleSubmit}
                      style={{ marginLeft: 10 }}
                    >
                      <FiRefreshCcw />
                      <span style={{ marginLeft: 10 }}>Concluir</span>
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </NotaFiscalSaidaImportacaoNotaFiscalEntradaContext.Provider>
  );
};

function UseNotaFiscalSaidaImportacaoNotaFiscalEntrada(): INotaFiscalSaidaImportacaoNotaFiscalEntradaContext {
  const context = useContext(NotaFiscalSaidaImportacaoNotaFiscalEntradaContext);

  if (!context) {
    throw new Error(
      'UseNotaFiscalSaidaImportacaoNotaFiscalEntrada deve ser usado com um NotaFiscalEntradaProvider'
    );
  }

  return context;
}

export {
  NotaFiscalSaidaImportacaoNotaFiscalEntradaHook,
  UseNotaFiscalSaidaImportacaoNotaFiscalEntrada,
};
