import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/movimentacoes/conta/conta-parcela-obter-data-vencimento';

interface IContaParcelaObterDataVencimentoComunicadorShow {
  params: any;
}

interface IContaParcelaObterDataVencimentoComunicadorUpdate {
  id: string;
  params: any;
}

class ContaParcelaObterDataVencimentoComunicador {
  public async index(
    params: IContaParcelaObterDataVencimentoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async update(
    params: IContaParcelaObterDataVencimentoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ContaParcelaObterDataVencimentoComunicador();
