import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/impressao/retrato-sem-foto-produto';

interface IPedidoVendaRetratoSemFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class PedidoVendaRetratoSemFotoProdutoImpressaoComunicador {
  public async show(
    params: IPedidoVendaRetratoSemFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoVendaRetratoSemFotoProdutoImpressaoComunicador();
