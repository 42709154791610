import { createContext, useContext } from 'react';

export interface IRotasContext {
  backend: string;
  listagem: string;
  detalhes: string;
}

const rotasContext = createContext<IRotasContext>({} as IRotasContext);
const RotasProvider = rotasContext.Provider;

function UseRota(): IRotasContext {
  const context = useContext(rotasContext);

  return context;
}

export { RotasProvider, UseRota, rotasContext };
