/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeValueInputDecimalNulavelEvent {
  valor: number;
  valorFormatado: string;
}

export interface IOnChangeInputDecimalNulavelProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnBlurCiaInputDecimalNulavelEvent {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
  mudou: boolean;
  valor: string;
}

export interface IInputDecimalNulavelRef {
  focus(): void;
  value(): number | null;
  setErro(error: string): void;
  getValueFormatado(): number | null;
}

interface IInputDecimalNulavelProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  casasDecimais?: number;
  casasInteiras?: number;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputDecimalNulavelProps
  ) => void | Promise<void>;
  onChangeValue?: (
    event: IOnChangeValueInputDecimalNulavelEvent
  ) => void | Promise<void>;
  onBlurCia?: (event: IOnBlurCiaInputDecimalNulavelEvent) => void;
}

const InputDecimalNulavel: React.ForwardRefRenderFunction<
  IInputDecimalNulavelRef,
  IInputDecimalNulavelProps
> = (
  {
    name,
    label,
    casasDecimais = 2,
    casasInteiras = 15,

    onChange,
    onFocus,
    onChangeValue,
    onBlur,
    onBlurCia,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },

    value() {
      return inputRef.current?.value
        ? inputRef.current.value.ConverterParaNumber()
        : null;
    },

    setErro(error: string) {
      setErro(error);
    },

    getValueFormatado() {
      return inputRef.current?.value
        ? inputRef.current.value.ConverterParaNumber()
        : null;
    },
  }));

  useEffect(() => {
    valorAnteriorOnBlur.current = inputRef.current?.value || '';
    valorAnteriorOnChange.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);

      const tamanho = event.target.value.length;
      event.currentTarget.selectionStart = 0;
      event.currentTarget.selectionEnd = tamanho;

      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let posicaoDoCursor = event.currentTarget.selectionStart || 0;
      const quantidadeDePontosAntes = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      let valor: string | number = event.target.value
        .replace(/\./g, '')
        .replace(',', '.');

      const valorNaoFormatado = Number(valor);
      if (valor.includes(',')) {
        valor = valor.replace(/[^\d.-]/g, '');

        valor = Number(valor).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        event.target.value = valor;
        const posicao = Number(valor.split(',')[0].length) + 1;

        if (posicaoDoCursor <= posicao) {
          event.currentTarget.selectionStart = posicao;
          event.currentTarget.selectionEnd = posicao;
        } else {
          event.currentTarget.selectionStart = posicaoDoCursor - 1;
          event.currentTarget.selectionEnd = posicaoDoCursor - 1;
        }

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        if (onChangeValue) {
          onChangeValue({
            valor: valorNaoFormatado,
            valorFormatado: event.target.value,
          });
        }

        return;
      }

      valor = valor.replace(/[^\d.-]/g, '');

      if (valor === '0' || valor === '.') {
        event.target.value = '0,'.padEnd(casasDecimais + 2, '0');
        event.currentTarget.selectionStart = 2;
        event.currentTarget.selectionEnd = 2;

        if (onChange)
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });

        if (onChangeValue) {
          onChangeValue({
            valor: valorNaoFormatado,
            valorFormatado: event.target.value,
          });
        }

        return;
      }

      if (!valor.includes('.') && valor.length > 1) {
        const val = event.target.value;
        const inicio = val.substr(0, val.length - casasDecimais);
        const fim = val.substr(casasDecimais * -1);
        event.target.value = `${inicio},${fim}`;

        posicaoDoCursor = val.length - casasDecimais;
        event.currentTarget.selectionStart = posicaoDoCursor;
        event.currentTarget.selectionEnd = posicaoDoCursor;

        if (onChange) {
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });
        }

        if (onChangeValue) {
          onChangeValue({
            valor: valorNaoFormatado,
            valorFormatado: event.target.value,
          });
        }

        return;
      }

      if (!valor && valor !== '0,'.padEnd(casasDecimais + 2, '0')) {
        event.target.value = '';

        if (onChange)
          onChange(event, {
            valorAnteriorOnBlur: valorAnteriorOnBlur.current,
            valorAnteriorOnChange: valorAnteriorOnChange.current,
          });

        if (onChangeValue)
          onChangeValue({
            valor: valorNaoFormatado,
            valorFormatado: event.target.value,
          });

        return;
      }

      const [inteirosValor, decimalValor = ''.padEnd(casasDecimais, '0')] =
        valor.split('.');

      if (inteirosValor.length > casasInteiras) {
        setErro('Valor maior que o permitido!');
      }

      valor = Number(
        `${inteirosValor}.${decimalValor.substr(0, casasDecimais)}`
      );

      valor = valor.FormatarParaPtBr({
        minimoCasasDecimais: casasDecimais,
        maximoCasasDecimais: casasDecimais,
      });

      event.target.value = String(valor);

      const quantidadeDePontosDepois = event.target.value.replace(
        /[^.]/g,
        ''
      ).length;

      if (valor[posicaoDoCursor - 1] === ',') {
        posicaoDoCursor -= 1;
      } else {
        posicaoDoCursor -= quantidadeDePontosAntes - quantidadeDePontosDepois;
      }

      event.currentTarget.selectionStart = posicaoDoCursor;
      event.currentTarget.selectionEnd = posicaoDoCursor;

      if (onChange)
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });

      if (onChangeValue)
        onChangeValue({
          valor: valorNaoFormatado,
          valorFormatado: event.target.value,
        });

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [casasDecimais, casasInteiras, onChange, onChangeValue]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      setIsFilled(!!inputRef.current?.value);
      setIsFocused(false);

      if (onBlur) onBlur(event);

      if (onBlurCia) {
        const mudou = inputRef.current?.value !== valorAnteriorOnBlur.current;

        onBlurCia({
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
          mudou,
          valor: inputRef.current?.value || '',
        });
      }
    },
    [onBlur, onBlurCia]
  );

  useEffect(() => {
    registerField<number | null>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        const value = inputRef.current?.value
          ? inputRef.current?.value.ConverterParaNumber()
          : null;

        if (!value && value !== 0) {
          return null;
        }

        const parsedValue = Number(value);

        return parsedValue;
      },

      setValue(_, value = null) {
        setErro('');
        if (!inputRef.current) return;

        if (!value && value !== 0) {
          inputRef.current.value = '';
          return;
        }

        const parsedValue = Number(value).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        valorAnteriorOnBlur.current = inputRef.current.value;
        valorAnteriorOnChange.current = inputRef.current.value;
        inputRef.current.value = parsedValue;

        onChangeValue &&
          onChangeValue({ valor: value, valorFormatado: parsedValue });
      },

      setSemExecutarEvento(_, value = null) {
        setErro('');
        if (!inputRef.current) return;

        if (!value && value !== 0) {
          inputRef.current.value = '';
          return;
        }

        const parsedValue = Number(value).FormatarParaPtBr({
          minimoCasasDecimais: casasDecimais,
          maximoCasasDecimais: casasDecimais,
        });

        valorAnteriorOnBlur.current = inputRef.current.value;
        valorAnteriorOnChange.current = inputRef.current.value;
        inputRef.current.value = parsedValue;
      },

      clearValue(_, valorInicial = null) {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        style={{ textAlign: 'right' }}
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputDecimalNulavel);
