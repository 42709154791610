import api from '../../../../../Comum/Services/Api';

const rota = 'servicos/ordens-servicos/equipamento';

interface IEquipamentoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IEquipamentoComunicadorShow {
  id: string;
}

interface IEquipamentoComunicadorStore {
  params: any;
}

interface IEquipamentoComunicadorUpdate {
  id: string;
  params: any;
}

interface IEquipamentoComunicadorDeletar {
  id: string;
}

class EquipamentoComunicador {
  public async index(params: IEquipamentoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IEquipamentoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IEquipamentoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IEquipamentoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IEquipamentoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EquipamentoComunicador();
