import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { OrdemServicoDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { OrdemServicoHook } from './OrdemServicoHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { SituacaoBloquearCamposHook } from './SituacaoBloquearCamposHook';
import { NotaFiscalSaidaImportacaoOrdemServicoHook } from '../../../../../Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServicoHook';
import { OrdemServicoImportacaoOrcamentoHook } from '../../../OrdemServicoImportacaoOrcamentoHook';
import OrdemServicoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Comunicador/OrdemServicoComunicador';

const OrdemServicoHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: OrdemServicoComunicador }}>
      <RotasHook>
        <RegimeTributarioHook>
          <OrdemServicoDuplicarHook>
            <OrdemServicoImportacaoOrcamentoHook>
              <NotaFiscalSaidaImportacaoOrdemServicoHook>
                <FormHook>
                  <SituacaoBloquearCamposHook>
                    <OrdemServicoHook>
                      <SubHeaderHook>{children}</SubHeaderHook>
                    </OrdemServicoHook>
                  </SituacaoBloquearCamposHook>
                </FormHook>
              </NotaFiscalSaidaImportacaoOrdemServicoHook>
            </OrdemServicoImportacaoOrcamentoHook>
          </OrdemServicoDuplicarHook>
        </RegimeTributarioHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default OrdemServicoHooks;
