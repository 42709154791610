import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  SituacaoNotaFiscalSaidaEnum,
  INotaFiscalSaidaValoresAlterar,
  INotaFiscalSaidaItemValoresAlterar,
  INotaFiscalSaidaItemDuplicarLista,
  FinalidadeEmissaoEnum,
  TipoPesoEnum,
} from '@elogestor/util';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { ListaItemProvider } from './ListaItemContext';
import { UseNotaFiscalSaidaDuplicar } from './DuplicarHook';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import NotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaComunicador';
import NotaFiscalSaidaObterDadosPadraoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaObterDadosPadraoComunicador';
import { UseRegimeTributario } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { IInputAutoCompletePadraoRef } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const rota = UseRota();
  const { redirecionar } = UseRedirecionar();
  const { abrirNotaFiscalSaidaDuplicar, fecharNotaFiscalSaidaDuplicar } =
    UseNotaFiscalSaidaDuplicar();
  const navigate = useNavigate();
  const parametros = UseParametros();
  const { setRegimeTributarioPorData } = UseRegimeTributario();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<IInputAutoCompletePadraoRef>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.autoCompleteRef.current?.getInput()?.focus();
    }
  }, []);

  const [listaItem, setListaItem] = useState<
    INotaFiscalSaidaItemValoresAlterar[]
  >([]);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof INotaFiscalSaidaValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        const dados = {
          ...dadosDuplicados,
          dataHoraEmissao: new Date(),
          dataHoraSaida: dadosDuplicados.dataHoraSaida ? new Date() : null,
        };

        await formRef.current?.setDataDuplicar(dados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;
        const response = await NotaFiscalSaidaComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial(response);

        SetarDadosObrigatorios();
        setListaItem(response.listaNotaFiscalSaidaItem || []);
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response = await NotaFiscalSaidaObterDadosPadraoComunicador.show({
          params: undefined,
        });

        await formRef.current?.setDataInicial({
          ...response,
          situacao: SituacaoNotaFiscalSaidaEnum.naoTransmitida,
          dataHoraEmissao: new Date(),
          dataHoraSaida: parametros.DataHoraSaidaSugerirDataAtual
            ? new Date()
            : undefined,
          freteFormaCalculoPorRateio: true,
          seguroFormaCalculoPorRateio: true,
          outrasDespesasFormaCalculoPorRateio: true,
          descontoFormaCalculoPorRateio: true,
          finalidadeEmissao: FinalidadeEmissaoEnum.normal,
          indicadorPresenca: parametros.IndicadorPresencaPadraoNotaFiscalSaida,
          informacoesContribuinte:
            parametros.TextoPadraoInformacaoContribuinteNotaFiscalSaida,
          observacoesInternas:
            parametros.TextoPadraoObservacaoInternaNotaFiscalSaida,

          financeiroConferido: false,
          valorFinanceiro: 0,
          vendaPdv: false,

          notaFiscalSaidaTransporteTransportadora: {
            modalidadeFrete: parametros.ModalidadeFreteNotaFiscalSaida,
          },

          notaFiscalSaidaPessoa: {
            cnpjCpfNoDocumentoFiscal: true,
          },

          notaFiscalSaidaTransporteVolume: {
            tipoCalculoPesos: TipoPesoEnum.calculado,
            quantidadeFormaCalculoPorItem: true,
            metroCubicoFormaCalculoPorItem: true,
            metroQuadradoFormaCalculoPorItem: true,
          },

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          const dataHoraEmissao =
            formRef.current?.getFieldValue('dataHoraEmissao');
          await setRegimeTributarioPorData(dataHoraEmissao);

          const storageSelecionado = localStorage.getItem('@EloGestorle:turno');
          if (storageSelecionado) {
            const turnoSelecionado = JSON.parse(String(storageSelecionado));

            formRef.current?.setFieldValue(
              'terminalCaixa',
              turnoSelecionado.terminalCaixa
            );
            formRef.current?.setFieldValue(
              'usuarioAbertura',
              turnoSelecionado.usuarioAbertura
            );
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      parametros.DataHoraSaidaSugerirDataAtual,
      parametros.IndicadorPresencaPadraoNotaFiscalSaida,
      parametros.ModalidadeFreteNotaFiscalSaida,
      parametros.TextoPadraoInformacaoContribuinteNotaFiscalSaida,
      parametros.TextoPadraoObservacaoInternaNotaFiscalSaida,
      redirecionar,
      refresh,
      setRegimeTributarioPorData,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});
      const dados = formRef.current?.getDataDuplicar();

      const schema = Yup.object().shape({
        idSerie: Yup.string().nullable().required('Série é obrigatória!'),

        dataHoraEmissao: Yup.date()
          .nullable()
          .required('Data Hora Emissão é obrigatória!'),

        numero: Yup.number().test({
          message: 'Número é obrigatório',
          test: (value: any) => {
            if (
              dados.serie &&
              !dados.serie.incrementarAutomaticamente &&
              Number(value) === 0
            ) {
              return false;
            }

            return true;
          },
        }),

        localEmbarque: Yup.string().test({
          message: 'Local de Embarque é Obrigatório, se Informado a UF!',
          test: (value: any) => {
            if (data.idEstadoEmbarque && !value) {
              return false;
            }

            return true;
          },
        }),

        idEstadoEmbarque: Yup.string()
          .test({
            message:
              'UF de Embarque é Obrigatória, se Informado o Local de Embarque!',
            test: (value: any) => {
              if (data.localEmbarque && !value) {
                return false;
              }

              return true;
            },
          })
          .nullable(),

        valorFrete: Yup.number()
          .nullable()
          .required('Valor do Frete é obrigatório!'),
        valorSeguro: Yup.number()
          .nullable()
          .required('Valor do Seguro é obrigatório!'),
        valorOutrasDespesas: Yup.number()
          .nullable()
          .required('Valor das outras Despesas é obrigatório!'),
        valorDesconto: Yup.number()
          .nullable()
          .required('Valor do Desconto é obrigatório!'),

        valorTotalIcms: Yup.number()
          .nullable()
          .required('Valor Total do ICMS é obrigatório!'),
        valorTotalBaseCalculoIpi: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do IPI é obrigatório!'),
        valorTotalIpi: Yup.number()
          .nullable()
          .required('Valor Total do IPI é obrigatório!'),
        valorTotalBaseCalculoPis: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do PIS é obrigatório!'),
        valorTotalPis: Yup.number()
          .nullable()
          .required('Valor Total do PIS é obrigatório!'),
        valorTotalBaseCalculoCofins: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo do COFINS é obrigatório!'),
        valorTotalCofins: Yup.number()
          .nullable()
          .required('Valor Total do COFINS é obrigatório!'),
        valorTotalBaseCalculoPisRetido: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo Retido do PIS é obrigatório!'),
        valorTotalPisRetido: Yup.number()
          .nullable()
          .required('Valor Total Retido do PIS é obrigatório!'),
        valorTotalBaseCalculoCofinsRetido: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo Retido do COFINS é obrigatório!'),
        valorTotalCofinsRetido: Yup.number()
          .nullable()
          .required('Valor Total Retido do COFINS é obrigatório!'),
        valorTotalBaseCalculoIcmsSt: Yup.number()
          .nullable()
          .required('Valor da Base de Calculo da ST. é obrigatório!'),
        valorTotalIcmsSt: Yup.number()
          .nullable()
          .required('Valor Total da ST. é obrigatório!'),

        valorTotalBruto: Yup.number()
          .nullable()
          .required('Valor Total Bruto é obrigatório!'),
        valorTotalProdutos: Yup.number()
          .nullable()
          .required('Valor Total dos Produto é obrigatório!'),

        notaFiscalSaidaPessoa: Yup.object().shape({
          idPessoa: Yup.string().nullable().required('Cliente é obrigatório!'),
          cnpjCpfVirtual: Yup.mixed()
            .nullable()
            .test({
              message: 'CNPJ/CPF do Cliente é obrigatório!',
              test: (value: any) => {
                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  dados.notaFiscalSaidaPessoaEndereco.cidade.estado.sigla !==
                    'EX' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
        }),

        notaFiscalSaidaPessoaEndereco: Yup.object().shape({
          idCidade: Yup.mixed()
            .nullable()
            .test({
              message: 'Cidade do Cliente é obrigatório!',
              test: (value: any) => {
                if (data.vendaPdv) {
                  return true;
                }

                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
          logradouro: Yup.mixed()
            .nullable()
            .test({
              message: 'Logradouro do Cliente é obrigatório!',
              test: (value: any) => {
                if (data.vendaPdv) {
                  return true;
                }

                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
          numero: Yup.mixed()
            .nullable()
            .test({
              message: 'Número do Cliente é obrigatório!',
              test: (value: any) => {
                if (data.vendaPdv) {
                  return true;
                }

                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
          bairro: Yup.mixed()
            .nullable()
            .test({
              message: 'Bairro do Cliente é obrigatório!',
              test: (value: any) => {
                if (data.vendaPdv) {
                  return true;
                }

                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
        }),

        notaFiscalSaidaTransporteTransportadora: Yup.object().shape({
          modalidadeFrete: Yup.mixed()
            .nullable()
            .test({
              message: 'Modalidade de Frete é obrigatorio!',
              test: (value: any) => {
                if (
                  dados.serie &&
                  dados.serie.modeloDocumento &&
                  dados.serie.modeloDocumento.codigo === '55' &&
                  !value
                ) {
                  return false;
                }

                return true;
              },
            }),
          placa: Yup.mixed()
            .nullable()
            .test({
              message:
                'Informe a Placa no Transporte ou remova a UF referente a Placa!',
              test: (value: any) => {
                if (
                  dados.notaFiscalSaidaTransporteTransportadora.estadoPlaca &&
                  !value
                ) {
                  return false;
                }
                return true;
              },
            }),
        }),
      });

      await schema.validate(data, { abortEarly: false });

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';

        const atualizarTabelaPrecoItens = formRef.current?.getFieldValue(
          'atualizarTabelaPrecoItens'
        );

        if (
          atualizarTabelaPrecoItens &&
          data.listaNotaFiscalSaidaItem &&
          data.listaNotaFiscalSaidaItem.length > 0
        ) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span style={{ fontSize: 20 }}>
                Deseja atualizar a Tabela de Preço dos Itens?
              </span>
            ),
            cancelar: 'Não',
            confimar: 'Sim',
          });

          data = {
            ...data,
            atualizarTabelaPrecoItens: resposta,
          };

          formRef.current?.setFieldValue('atualizarTabelaPrecoItens', false);
        }

        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        if (id) {
          await NotaFiscalSaidaComunicador.update({ id, params: data });
        } else {
          const response = await NotaFiscalSaidaComunicador.store({
            params: data,
          });
          setIdDetalheRegistro(response.id);
          formRef.current?.setFieldValue('numero', response.numero);
          id = response.id;
        }

        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [
      abrirJanela,
      getIdDetalheRegistro,
      handleValidar,
      redirecionar,
      setIdDetalheRegistro,
    ]
  );

  const handleDuplicarSalvar = useCallback(async (): Promise<void> => {
    try {
      const id = getIdDetalheRegistro();
      if (!id) return;

      setLoading(true);

      const idDuplicado = await abrirNotaFiscalSaidaDuplicar(
        id,
        listaItem as unknown as INotaFiscalSaidaItemDuplicarLista[]
      );

      if (!idDuplicado) {
        setLoading(false);
        fecharNotaFiscalSaidaDuplicar();
        return;
      }

      setIdDetalheRegistro(idDuplicado);
      navigate({
        pathname: `${rota.detalhes}/${idDuplicado}`,
      });

      handleCarregarDados();

      ToastSucesso('Registro Duplicado e Salvo!');
      setLoading(false);
      fecharNotaFiscalSaidaDuplicar();
      await formRef.current?.atualizarDataInicial();
    } catch (error) {
      TratarErros(error, { redirecionar });
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaDuplicar,
    fecharNotaFiscalSaidaDuplicar,
    getIdDetalheRegistro,
    handleCarregarDados,
    navigate,
    listaItem,
    redirecionar,
    rota.detalhes,
    setIdDetalheRegistro,
  ]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        handleDuplicarSalvar,
        refresh,
      }}
    >
      <ListaItemProvider value={{ listaItem }}>{children}</ListaItemProvider>
    </FormProvider>
  );
};

export default FormHook;
