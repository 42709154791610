import api from '../../../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/nota-fiscal-entrada/transmissao/evento/impressao/carta-correcao';

interface INotaFiscalEntradaTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow {
  id: string;
}

class NotaFiscalEntradaTransmissaoEventoCartaCorrecaoImpressaoComunicador {
  public async show(
    params: INotaFiscalEntradaTransmissaoEventoCartaCorrecaoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaTransmissaoEventoCartaCorrecaoImpressaoComunicador();
