"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Modulo10Calculo;
function Modulo10Calculo(dados) {
    let soma = 0;
    let resto;
    let peso = 2;
    for (let i = dados.chave.length - 1; i >= 0; i--) {
        const numero = Number(dados.chave.charAt(i));
        const result = numero * peso > 9 ? numero * peso - 9 : numero * peso;
        soma += result;
        peso = peso === 2 ? 1 : 2;
    }
    if (soma < 10)
        resto = soma;
    else
        resto = soma % 10;
    const digito = resto === 0 ? 0 : 10 - resto;
    return digito;
}
