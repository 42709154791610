import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/portador-auto-complete-tag';

interface IPortadorAutoCompleteTagComunicadorIndex {
  params: any;
}

class PortadorAutoCompleteTagComunicador {
  public async index(
    params: IPortadorAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PortadorAutoCompleteTagComunicador();
