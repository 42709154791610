"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoImpostoCustosEnum;
(function (TipoImpostoCustosEnum) {
    TipoImpostoCustosEnum["icms"] = "ICMS";
    TipoImpostoCustosEnum["ipi"] = "IPI";
    TipoImpostoCustosEnum["pis"] = "PIS";
    TipoImpostoCustosEnum["cofins"] = "COFINS";
    TipoImpostoCustosEnum["diferencialAliquota"] = "Diferencial de Al\u00EDquota (Debitar)";
    TipoImpostoCustosEnum["icmsSubstituicaoTributaria"] = "ICMS Substitui\u00E7\u00E3o Tribut\u00E1ria";
})(TipoImpostoCustosEnum || (TipoImpostoCustosEnum = {}));
exports.default = TipoImpostoCustosEnum;
