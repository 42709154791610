import React, { useCallback, useState } from 'react';
import {
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaPortadorLista,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaPortadorComunicador from '../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorComunicador';
import RegraEscolhaPortadorOrdemComunicador from '../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Comunicador/RegraEscolhaPortadorOrdemComunicador';
import RegraEscolhaPortadorLogComunicador from '../../../../../Comunicador/Financeiro/Geral/RegraEscolhaPortador/Log/Comunicador/RegraEscolhaPortadorLogComunicador';

const RegraEscolhaPortadorLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaPortadorComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IRegraEscolhaPortadorLista) => {
            return {
              id: data.id,

              'listaRegraEscolhaPortadorFormaPagamento.formaPagamento.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaPortadorFormaPagamento
                      ? data.listaRegraEscolhaPortadorFormaPagamento.map(
                          (valor) => {
                            return (
                              <div key={valor.formaPagamento.descricao}>
                                {`${valor.formaPagamento.descricao};`}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'regraEscolhaPortadorResultado.portador.descricao': (
                <div className="lista-texto">
                  {data.regraEscolhaPortadorResultado?.portador
                    ? `${data.regraEscolhaPortadorResultado?.portador.descricao}`
                    : ''}
                </div>
              ),

              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaPortadorEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaPortadorEmpresa[0]?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaPortadorEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div
                  className="lista-acoes"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaPortadorComunicador.delete({
                          id: String(data.id),
                        });

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaPortadorLogComunicador.show({
                            id: String(data.id),
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      permissoes.FinanceiroGeralRegraEscolhaPortador?.exclui
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.FinanceiroGeralRegraEscolhaPortador]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'financeiroRegraEscolhaPortador',
        },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaPortadorOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha do Portador"
          permissao={permissoes.FinanceiroGeralRegraEscolhaPortador}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaPortadorLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaPortadorLista;
