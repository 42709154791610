import styled from 'styled-components';
import { InputContainer } from '../Styles';

export const ContainerCpf = styled(InputContainer)`
  > div {
    display: flex;
    align-items: flex-end;
    width: 100%;

    input {
      height: 35px;
      flex: 1;
    }

    button {
      height: 35px;
      width: 35px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(0, 123, 255);
      border: none;
      border-radius: 50%;
      align-self: flex-start;
      svg {
        margin: 0;
        color: #fff;
        width: 20px;
        height: 20px;
      }

      &:disabled {
        background: #dddddd;
      }
    }
  }
`;
