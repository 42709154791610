import {
  FormatarEnum,
  ICaracteristicaValoresAlterar,
  TipoCaracteristicaConfiguradorEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Select from '../../../../../../Componentes/Select';
import InputAutoCompleteConjunto from '../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteConjunto';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';

type IProps = IMain<ICaracteristicaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaConfiguradorCaracteristica: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const tipoCaracteristica = formRef.current?.getFieldValue('tipo');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <Divisor>
        <Row>
          <Col lg={8} md={12} sm={12}>
            <Input
              ref={inputRefFocus}
              label="Descrição"
              name="descricao"
              placeholder="Descrição"
              maxLength={100}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} sm={6}>
            <Input
              label="Máscara"
              name="mascara"
              placeholder="Máscara"
              maxLength={100}
            />
          </Col>
          <Col lg={4} md={6} sm={6}>
            <Select
              name="tipo"
              label="Tipo"
              options={FormatarEnum({
                enumObj: TipoCaracteristicaConfiguradorEnum,
              })}
              onChange={() => {
                refresh();
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            display:
              tipoCaracteristica ===
              TipoCaracteristicaConfiguradorEnum.escolhaConjunto
                ? 'flex'
                : 'none',
          }}
        >
          <Col lg={8} md={12} sm={12}>
            <InputAutoCompleteConjunto
              label="Conjunto"
              name="idConjunto"
              nomeObjeto="conjunto"
              placeholder="Conjunto"
            />
          </Col>
        </Row>

        <Row
          style={{
            display:
              tipoCaracteristica === TipoCaracteristicaConfiguradorEnum.numerica
                ? 'flex'
                : 'none',
          }}
        >
          <Col lg={4} md={6} sm={6}>
            <Input label="Intervalo" name="intervalo" placeholder="Intervalo" />
          </Col>
          <Col lg={4} md={6} sm={6}>
            <InputDecimalNulavel
              label="Múltiplo"
              name="multiplo"
              placeholder="Múltiplo"
              casasDecimais={6}
              casasInteiras={15}
            />
          </Col>
        </Row>

        <Row
          style={{
            display:
              tipoCaracteristica ===
              TipoCaracteristicaConfiguradorEnum.opcaoSimNao
                ? 'flex'
                : 'none',
          }}
        >
          <Col lg={4} md={6} sm={6}>
            <Input
              label="Opção Sim"
              name="opcaoSim"
              placeholder="Opção Sim"
              maxLength={20}
            />
          </Col>
          <Col lg={4} md={6} sm={6}>
            <Input
              label="Opção Não"
              name="opcaoNao"
              placeholder="Opção Não"
              maxLength={20}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2} md={6} sm={6} xs={6}>
            <InputSwitch name="ativo" label="Ativo" />
          </Col>
        </Row>
      </Divisor>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
