import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/transmissao/evento';

interface INotaFiscalSaidaTransmissaoEventoComunicadorIndex {
  params: {
    idValor: string;
  };
}

interface INotaFiscalSaidaTransmissaoEventoComunicadorShow {
  id: string;
}

interface INotaFiscalSaidaTransmissaoEventoComunicadorStore {
  params: any;
}

interface INotaFiscalSaidaTransmissaoEventoComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalSaidaTransmissaoEventoComunicadorDelete {
  id: string;
}

class NotaFiscalSaidaTransmissaoEventoComunicador {
  public async index(
    params: INotaFiscalSaidaTransmissaoEventoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }

  public async show(
    params: INotaFiscalSaidaTransmissaoEventoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: INotaFiscalSaidaTransmissaoEventoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: INotaFiscalSaidaTransmissaoEventoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalSaidaTransmissaoEventoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalSaidaTransmissaoEventoComunicador();
