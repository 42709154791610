import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/portador/certificado/upload';

interface IApiCertificadoUploadComunicadorUpdate {
  id: string;
  params: any;
}

class ApiCertificadoUploadComunicador {
  public async update(
    params: IApiCertificadoUploadComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new ApiCertificadoUploadComunicador();
