import styled from 'styled-components';

export const LoadingContainer = styled.div`
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  animation: spin 3s linear infinite;

  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
`;
