import { ICotacaoCompraRetratoComFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoCotacaoCompraRetratoComFotoProdutoPDF from './CotacaoCompraRetratoComFotoProdutoImpressaoPDF';

interface IImpressaoCotacaoCompraRetratoComFotoProduto {
  handleVisualizarPdf(
    data: ICotacaoCompraRetratoComFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: ICotacaoCompraRetratoComFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoCotacaoCompraRetratoComFotoProduto =
  (): IImpressaoCotacaoCompraRetratoComFotoProduto => {
    async function handleVisualizarPdf(
      dados: ICotacaoCompraRetratoComFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoCotacaoCompraRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: ICotacaoCompraRetratoComFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoCotacaoCompraRetratoComFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoCotacaoCompraRetratoComFotoProduto();
