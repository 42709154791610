import React, { useCallback, useRef, useState } from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import LoadingDiv from '../../../Componentes/LoadingDiv';
import TesteAndreComunicador from '../../../Comunicador/Comum/Teste/TesteAndre/Comunicador/TesteAndreComunicador';
import TratarErros from '../../../Util/Erro/TratarErros';
import TratarInfo from '../../../Util/Info/TratarInfo';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import { BtnContainer } from './styles';
import TratarSucesso from '../../../Util/Sucesso/TratarSucesso';
import { UseReactSizeMeBodyHook } from '../../../Hooks/ReactSizeMeBodyHook';
import InputAutoCompleteProdutoServico from '../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import Input from '../../../Componentes/Inputs/Input';
import { UseSalvarPDF } from '../../../Paginas/DocumentosEletronicos/SalvarPDF/SalvarPDFHook';

const TesteAndre: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { salvarPDF } = UseSalvarPDF();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);

  const handleClickAtualizarPdfNotasEmitidas = useCallback(async () => {
    try {
      setLoading(true);

      await salvarPDF({ salvarTudo: true, exibirMensagens: true });

      ToastSucesso('Registros Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [salvarPDF]);

  const handleClickAtualizarServicoTomadoCteTerceiro = useCallback(async () => {
    try {
      setLoading(true);

      const response = await TesteAndreComunicador.index({
        atualizarServicoTomadoCteTerceiro: true,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Registros Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickAjustarMovimentoEstoqueNotaSaida = useCallback(async () => {
    try {
      setLoading(true);

      const descricaoProduto =
        formRef.current?.getFieldValue('descricaoProduto');
      const response = await TesteAndreComunicador.index({
        ajustarMovimentoEstoqueNotaSaida: true,
        descricaoProduto,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Registros Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickRecalcularCustoProduto = useCallback(async () => {
    try {
      setLoading(true);

      const idProduto = formRef.current?.getFieldValue('idProduto');

      const descricaoProduto =
        formRef.current?.getFieldValue('descricaoProduto');

      if (!idProduto) {
        setLoading(false);
        return;
      }

      const response = await TesteAndreComunicador.index({
        recalcularCustoProduto: true,
        idProdutoRecalcularCusto: idProduto,
        descricaoProduto,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Custos Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickRecalcularCustoProdutoTodos = useCallback(async () => {
    try {
      setLoading(true);

      const response = await TesteAndreComunicador.index({
        recalcularCustoProdutoTodos: true,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Custos Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickAjustarContasCteTerceiro = useCallback(async () => {
    try {
      setLoading(true);

      const response = await TesteAndreComunicador.index({
        ajustarContasCteTerceiro: true,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Contas Atualizadas!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickAtualizarImportacaoAutorizacaoXmlNfe =
    useCallback(async () => {
      try {
        setLoading(true);

        const response = await TesteAndreComunicador.index({
          atualizarImportacaoAutorizacaoXmlNfe: true,
        });

        if (response && response.listaMensagemErro) {
          TratarInfo(response.listaMensagemErro);
        }
        if (response && response.listaMensagemSucesso) {
          TratarSucesso(response.listaMensagemSucesso);
        }

        ToastSucesso('Importação Autorização XML Atualizados!');
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, []);

  const handleClickAtualizarProdutoFci = useCallback(async () => {
    try {
      setLoading(true);

      const response = await TesteAndreComunicador.index({
        atualizarProdutoConformeXmlNotasFiscais: true,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Importação Autorização XML Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  const handleClickAtualizarProdutoEstoqueCombinacao = useCallback(async () => {
    try {
      setLoading(true);

      const response = await TesteAndreComunicador.index({
        atualizarProdutoEstoqueCombinacao: true,
      });

      if (response && response.listaMensagemErro) {
        TratarInfo(response.listaMensagemErro);
      }
      if (response && response.listaMensagemSucesso) {
        TratarSucesso(response.listaMensagemSucesso);
      }

      ToastSucesso('Estoque Combinação dos Produtos Atualizados!');
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, []);

  return (
    <div style={{ backgroundColor: 'lightgray', margin: 50, padding: 50 }}>
      <LoadingDiv isLoading={loading} />

      <div>
        <h1>Ajustes</h1>
      </div>

      <FormCia ref={formRef}>
        <BtnContainer style={{ justifyContent: 'flex-start' }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Gera e salva o pdf para todos os registros que não tiverem
                      a impressão salva!
                    </li>
                    <li> - Nota Fiscal Saída</li>
                    <li> - Nota Fiscal Saída Carta Correcao</li>
                    <li> - Nota Fiscal Entrada</li>
                    <li> - Nota Fiscal Entrada Carta Correcao</li>
                    <li> - Conhecimento Transporte</li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAtualizarPdfNotasEmitidas}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Atualizar PDF Notas Emitidas
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Atualiza o campo servicoTomado da tabela
                      suprimentosImportacaoXmlAutorizacao para os CT-e de
                      Terceiro!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAtualizarServicoTomadoCteTerceiro}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Atualizar Serviço Tomado dos CT-e de Terceiro
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <Row style={{ marginTop: 30 }}>
          <Col>
            <Input
              name="descricaoProduto"
              label="Descrição do Produto (Para ajuste do estoque, usar %descricao% para like)"
              placeholder="Descrição do Produto"
            />
          </Col>

          <Col>
            <BtnContainer
              style={{ justifyContent: 'flex-start', marginTop: 20 }}
            >
              <OverlayTrigger
                trigger={['focus', 'hover']}
                placement={telaPequena ? 'bottom' : 'right'}
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Popover style={{ minWidth: 400 }} id={v4()}>
                    <Popover.Header>
                      <strong>Descrição!</strong>
                    </Popover.Header>
                    <Popover.Body style={{ textAlign: 'justify' }}>
                      <ul>
                        <li>
                          Ajusta os movimentos de estoque das notas de saida as
                          quais não foi gravado o idEstoqueMovimento
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  onClick={handleClickAjustarMovimentoEstoqueNotaSaida}
                  className="btn-padrao btn-verde"
                  disabled={loading}
                  style={{ minWidth: 400 }}
                >
                  Ajustar Movimento Estoque Nota Fiscal de Saida
                </button>
              </OverlayTrigger>
            </BtnContainer>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col>
            <InputAutoCompleteProdutoServico
              label="Produto/Serviço"
              name="idProduto"
              placeholder="Produto/Serviço"
              nomeObjeto="produto"
              permitirAdicionar={false}
              permitirConsultar={false}
            />
          </Col>

          <Col>
            <BtnContainer
              style={{ justifyContent: 'flex-start', marginTop: 20 }}
            >
              <OverlayTrigger
                trigger={['focus', 'hover']}
                placement={telaPequena ? 'bottom' : 'right'}
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Popover style={{ minWidth: 400 }} id={v4()}>
                    <Popover.Header>
                      <strong>Descrição!</strong>
                    </Popover.Header>
                    <Popover.Body style={{ textAlign: 'justify' }}>
                      <ul>
                        <li>
                          Recalcula o custo de todas as combinacoes para um
                          produto especifico!
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  type="button"
                  onClick={handleClickRecalcularCustoProduto}
                  className="btn-padrao btn-verde"
                  disabled={loading}
                  style={{ minWidth: 200 }}
                >
                  Recalcular Custo Produto
                </button>
              </OverlayTrigger>
            </BtnContainer>
          </Col>
        </Row>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Recalcula o custo de todas as combinacoes para todos os
                      produtos!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickRecalcularCustoProdutoTodos}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Recalcular Custo de Todos os Produtos
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Ajusta as contas dos CT-e de Terceiros que foram
                      importadas duplicadas!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAjustarContasCteTerceiro}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Ajustar Contas dos CT-e de Terceiro
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Atualiza as infomações do Destinatario e Transportadora na
                      importação autorização de acordo com o XML para as NF-e de
                      terceiros!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAtualizarImportacaoAutorizacaoXmlNfe}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Atualizar Importação Autorização XML NF-e
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Atualiza o campo FCI do Produto conforme os XMLs das Notas
                      Fiscais!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAtualizarProdutoFci}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Atualizar Produto FCI
            </button>
          </OverlayTrigger>
        </BtnContainer>

        <BtnContainer style={{ justifyContent: 'flex-start', marginTop: 30 }}>
          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement={telaPequena ? 'bottom' : 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ minWidth: 400 }} id={v4()}>
                <Popover.Header>
                  <strong>Descrição!</strong>
                </Popover.Header>
                <Popover.Body style={{ textAlign: 'justify' }}>
                  <ul>
                    <li>
                      Cria o estoque combinação para todos os produtos que
                      movimentarem estoque!
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={handleClickAtualizarProdutoEstoqueCombinacao}
              className="btn-padrao btn-verde"
              disabled={loading}
              style={{ minWidth: 400 }}
            >
              Atualizar Produto Estoque Combinacao
            </button>
          </OverlayTrigger>
        </BtnContainer>
      </FormCia>
    </div>
  );
};

export default TesteAndre;
