/* eslint-disable default-case */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Modal,
  Container,
  Col,
  Row,
  Table,
  Button,
  Dropdown,
} from 'react-bootstrap';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import {
  FormatarDataParaPtBr,
  IContaParcelaBoletoLista,
  ModeloImpressaoNotaFiscalSaidaEnum,
  SituacaoBoletoEnum,
  SituacaoNotaFiscalSaidaEnum,
} from '@elogestor/util';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlinePrinter,
} from 'react-icons/ai/index.mjs';
import { BiMailSend, BiRefresh } from 'react-icons/bi/index.mjs';
import PDFMerger from 'pdf-merger-js';
import { v4 } from 'uuid';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import ListaProvider from '../../../../../../../../Componentes/Lista/ListaProvider';
import ContaObterDadosContaParcelaBoletoComunicador from '../../../../../../../../Comunicador/Financeiro/Movimentacoes/Conta/ContaParcelaBoleto/ContaObterDadosContaParcelaBoletoComunicador';
import { UseContaParcelaBoleto } from '../Hook/ContaListaParcelaBoletoHook';
import JanelaEnvioEmail from '../../../../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import PessoaContatoObterEmailComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import { Tabela } from '../../../../../../../../Componentes/Styles/Tabela';
import ContaParcelaBoletoDetalhe from '../Detalhe';
import BoletoGerarImprimirComunicador from '../../../../../../../../Comunicador/Financeiro/Boleto/Comunicador/BoletoGerarImprimirComunicador';
import VisualizarPDF from '../../../../../../../../Util/Relatorios/VisualizarPDF';
import NotaFiscalSaidaA4ImpressaoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalSaidaA4Impressao/NotaFiscalSaidaA4ImpressaoComunicador';
import ImpressaoNotaFiscal from '../../../../../../../DocumentosEletronicos/NFe/Impressao';
import ImpressaoNotaFiscalConsumidor from '../../../../../../../DocumentosEletronicos/NFCe/Impressao';
import ImpressaoNotaFiscalSaidaEtiqueta from '../../../../../../../Comercial/Vendas/NotaFiscalSaida/Detalhe/AcoesNotaFiscalSaida/Impressao/Template/NotaFiscalSaidaEtiquetaImpressao';
import NotaFiscalSaidaExportarXmlComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaExportarXmlComunicador';
import ConhecimentoTransporteExportarXmlComunicador from '../../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Comunicador/ConhecimentoTransporteExportarXmlComunicador';
import ConhecimentoTransporteImpressaoComunicador from '../../../../../../../../Comunicador/Transporte/ConhecimentoTransporte/Impressao/ConhecimentoTransporteImpressao/ConhecimentoTransporteImpressaoComunicador';
import ImpressaoConhecimentoTransporte from '../../../../../../../DocumentosEletronicos/CTe/ImpressaoModalRodoviario';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import NotaFiscalSaidaEtiquetaImpressaoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalSaidaEtiquetaImpressao/NotaFiscalSaidaEtiquetaImpressaoComunicador';
import NotaFiscalConsumidorImpressaoComunicador from '../../../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalConsumidorImpressao/NotaFiscalConsumidorImpressaoComunicador';
import BoletoPedidoBaixaComunicador from '../../../../../../../../Comunicador/Financeiro/Boleto/Comunicador/BoletoPedidoBaixaComunicador';
import BoletoConsultarComunicador from '../../../../../../../../Comunicador/Financeiro/Boleto/Comunicador/BoletoConsultarComunicador';
import ImpressaoBoleto from '../../../../../../Boleto/ImpressaoBoleto/Template/ImpressaoBoleto';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarInfo from '../../../../../../../../Util/Info/TratarInfo';
import BoletoComunicador from '../../../../../../../../Comunicador/Financeiro/Boleto/Comunicador/BoletoComunicador';
import BoletoExcluirBaixaComunicador from '../../../../../../../../Comunicador/Financeiro/Boleto/Comunicador/BoletoExcluirBaixaComunicador';

interface IContaParcelaBoletoListaModal {
  onFecharFormModal(): void;
  numeroDocumento: string;
  idConta: string;
  idPessoa: string;
  idNotaFiscalSaida: string;
  idConhecimentoTransporte: string;
  salvarFormPrincipal?: boolean;
}

const ContaParcelaBoletoLista: React.FC<IContaParcelaBoletoListaModal> = ({
  onFecharFormModal,
  numeroDocumento,
  idConta,
  idPessoa,
  idConhecimentoTransporte,
  idNotaFiscalSaida,
  salvarFormPrincipal = true,
}) => {
  const {
    formRefLista,
    formRefDetalhe,

    setLoading,
    loading,
    refresh,
    handleSubmit,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
    handleCarregarDados,
  } = UseListaDetalheForm();
  const formPrincipal = UseForm();
  const parametros = UseParametros();
  const { listaParcela, listaParcelaSelecionadaRef, setListaParcela } =
    UseContaParcelaBoleto();
  const { abrirJanela } = UseConfirmacao();

  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const [showDetalheContaParcelaBoleto, setShowDetalheContaParcelaBoleto] =
    useState(false);
  const [situacao, setSituacao] = useState<SituacaoBoletoEnum>();

  const permiteImprimir = listaParcela.some((e) => {
    return (
      e.situacao === SituacaoBoletoEnum.registrado ||
      e.situacao === SituacaoBoletoEnum.liquidado ||
      e.situacao === SituacaoBoletoEnum.protestado ||
      e.situacao === SituacaoBoletoEnum.negativado ||
      e.situacao === SituacaoBoletoEnum.naoGerado
    );
  });

  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showEnviarEmailNotaBoleto, setShowEnviarEmailNotaBoleto] =
    useState(false);

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleCarregarDadosLista = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await ContaObterDadosContaParcelaBoletoComunicador.show({
        params: {
          id: String(idConta),
        },
      });

      if (!response) return;

      setListaParcela(response);

      setLoading(false);
    } catch (error) {
      TratarErros(error);

      setLoading(false);
    }
  }, [idConta, setListaParcela, setLoading]);

  const handleContaParcelaBoletoFecharFormModal = useCallback(async () => {
    setShowDetalheContaParcelaBoleto(false);
    setIdDetalheRegistro('');
  }, [setIdDetalheRegistro]);

  const handleContaParcelaBoletoSalvarFormModal = useCallback(async () => {
    const data = formRefDetalhe.current?.getData();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);

    if (!erro) {
      await handleCarregarDadosLista();
      setShowDetalheContaParcelaBoleto(false);
      setIdDetalheRegistro('');
    }
  }, [
    formRefDetalhe,
    handleCarregarDadosLista,
    handleSubmit,
    setIdDetalheRegistro,
  ]);

  const handleEditar = useCallback(
    async (item: IContaParcelaBoletoLista): Promise<void> => {
      setIdDetalheRegistro(item.id);
      handleCarregarDados();

      setShowDetalheContaParcelaBoleto(true);
      setSituacao(item.situacao);
    },
    [handleCarregarDados, setIdDetalheRegistro]
  );

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaParcelaSelecionadaRef.current?.forEach((item) => {
        if (item) {
          item.checked = checked;
        }
      });

      for (let i = 0; i < listaParcela.length; i++) {
        listaParcela[i].selecionado = checked;
      }
    },
    [listaParcela, listaParcelaSelecionadaRef]
  );

  const handleObterListaBoletosSelecionados = useCallback((): any[] => {
    return listaParcela.filter((item) => item.selecionado);
  }, [listaParcela]);

  const handleObterNotaFiscalSaidaPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await NotaFiscalSaidaA4ImpressaoComunicador.show({
        id: idNotaFiscalSaida,
      });

      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoNotaFiscal.handleVisualizarPdf(response.impressao);
        }

        blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [idNotaFiscalSaida]
  );

  const handleObterConhecimentoTransportePdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await ConhecimentoTransporteImpressaoComunicador.show({
        id: idConhecimentoTransporte,
      });

      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoConhecimentoTransporte.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf = await ImpressaoConhecimentoTransporte.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [idConhecimentoTransporte]
  );

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      if (!salvarFormPrincipal) return true;

      const data = formPrincipal.formRef.current?.getData();

      const { erro } = await formPrincipal.handleSubmit(data);
      if (erro) return false;

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formPrincipal, salvarFormPrincipal]);

  // #region Impressão

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const listaBoletosSelecionados = handleObterListaBoletosSelecionados();
      const listaIdBoleto = listaBoletosSelecionados.map(
        (boletoSelecionado) => {
          return boletoSelecionado.id;
        }
      );

      const listaBoletosImprimir: any =
        await BoletoGerarImprimirComunicador.update({
          params: {
            listaIdBoleto,
          },
        });

      if (
        listaBoletosImprimir.listaMensagem &&
        listaBoletosImprimir.listaMensagem.length > 0
      ) {
        TratarInfo(listaBoletosImprimir.listaMensagem);
      }

      if (
        listaBoletosImprimir &&
        listaBoletosImprimir.listaImpressao.length > 0
      ) {
        const merger = new PDFMerger();

        for (
          let index = 0;
          index < listaBoletosImprimir.listaImpressao.length;
          index++
        ) {
          const impressao = listaBoletosImprimir.listaImpressao[index];
          const buffer = Buffer.from(impressao, 'base64');
          await merger.add(buffer);
        }

        const mergedPdf = await merger.saveAsBuffer();

        if (visualizarPdf) {
          VisualizarPDF({ dados: { data: mergedPdf } });
        } else {
          const data = new Uint8Array(mergedPdf);
          const blob = new Blob([data], { type: 'application/pdf' });

          return blob;
        }
      }

      if (
        listaBoletosImprimir &&
        listaBoletosImprimir.listaDadosImpressao.length > 0
      ) {
        const merger = new PDFMerger();

        for (
          let index = 0;
          index < listaBoletosImprimir.listaDadosImpressao.length;
          index++
        ) {
          const dados = listaBoletosImprimir.listaDadosImpressao[index];

          const impressao: any =
            await ImpressaoBoleto.handleObterBlobPdf(dados);

          await merger.add(impressao);
        }

        const mergedPdf = await merger.saveAsBuffer();

        if (visualizarPdf) {
          VisualizarPDF({ dados: { data: mergedPdf } });
        } else {
          const data = new Uint8Array(mergedPdf);
          const blob = new Blob([data], { type: 'application/pdf' });

          return blob;
        }
      }

      return undefined;
    },
    [handleObterListaBoletosSelecionados]
  );

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const isNotaFiscalConsumidor =
        formPrincipal.formRef.current?.getFieldValue('vendaPdv');

      if (!isNotaFiscalConsumidor) {
        switch (parametros.ModeloParaImpressaoNotaFiscalSaida) {
          case ModeloImpressaoNotaFiscalSaidaEnum.a4:
            {
              const response = await NotaFiscalSaidaA4ImpressaoComunicador.show(
                { id: idNotaFiscalSaida }
              );

              if (response.pdf) {
                blobPdf = response.pdf;
                pdfSalvo = true;
              } else {
                blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                  response.impressao
                );
                pdfSalvo = false;
              }
            }
            break;

          case ModeloImpressaoNotaFiscalSaidaEnum.etiqueta:
            {
              const response =
                await NotaFiscalSaidaEtiquetaImpressaoComunicador.show({
                  id: idNotaFiscalSaida,
                });
              if (response.pdf) {
                blobPdf = response.pdf;
                pdfSalvo = true;
              } else {
                blobPdf =
                  await ImpressaoNotaFiscalSaidaEtiqueta.handleObterBlobPdf(
                    response.impressao
                  );
                pdfSalvo = false;
              }
            }
            break;
        }
      } else {
        const response = await NotaFiscalConsumidorImpressaoComunicador.show({
          id: idNotaFiscalSaida,
        });

        if (response.pdf) {
          if (visualizarPdf) {
            VisualizarPDF({ dados: response.pdf });
          }

          blobPdf = response.pdf;
          pdfSalvo = true;
        } else {
          if (visualizarPdf) {
            await ImpressaoNotaFiscalConsumidor.handleVisualizarPdf(
              response.impressao
            );
          }

          blobPdf = await ImpressaoNotaFiscalConsumidor.handleObterBlobPdf(
            response.impressao
          );
          pdfSalvo = false;
        }
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [
      formPrincipal.formRef,
      idNotaFiscalSaida,
      parametros.ModeloParaImpressaoNotaFiscalSaida,
    ]
  );

  const handleImprimir = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      await handleVisualizarOuObterBlob(true);

      ToastSucesso('Registro Salvo!');
      setLoading(false);
      await handleCarregarDadosLista();
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleCarregarDadosLista,
    handleSalvar,
    handleVisualizarOuObterBlob,
    setLoading,
  ]);

  // #endregion Impressão

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: true,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: true,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarOuObterBlob(false);

      if (anexo) {
        listaAnexo.push({
          nomeArquivo: `Boleto(s) ${numeroDocumento}.pdf`,
          file: anexo,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Boleto(s) ${numeroDocumento}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numeroDocumento]
  );

  const handleOnAlterarDocumentosFiscais = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const incluirDocumentoFiscal = formRefEmail.current?.getFieldValue(
        'incluirDocumentoFiscal'
      );
      const listaAnexoAtual = formRefEmail.current?.getFieldValue('listaAnexo');

      // #region Anexos

      const listaAnexo: any[] = [];
      let listaResponseXml: any[] = [];

      if (incluirDocumentoFiscal) {
        if (idNotaFiscalSaida) {
          const anexoNotaFiscalSaida =
            await handleObterNotaFiscalSaidaPdf(false);

          const data = new Uint8Array(
            (anexoNotaFiscalSaida as any).blobPdf.data
          );
          const blobPdf = new Blob([data], {
            type: 'application/pdf',
          });

          listaAnexo.push({
            nomeArquivo: `Nota Fiscal (Saida) ${numeroDocumento}.pdf`,
            file: blobPdf,
            permitirExcluir: false,
            boletoDocumentoFiscal: true,
          });

          listaResponseXml = await NotaFiscalSaidaExportarXmlComunicador.show({
            id: idNotaFiscalSaida,
          });
        } else if (idConhecimentoTransporte) {
          const anexoConhecimentoTransporte =
            await handleObterConhecimentoTransportePdf(false);

          const data = new Uint8Array(
            (anexoConhecimentoTransporte as any).blobPdf.data
          );
          const blobPdf = new Blob([data], {
            type: 'application/pdf',
          });

          listaAnexo.push({
            nomeArquivo: `Conhecimento Transporte ${numeroDocumento}.pdf`,
            file: blobPdf,
            permitirExcluir: false,
            boletoDocumentoFiscal: true,
          });

          listaResponseXml =
            await ConhecimentoTransporteExportarXmlComunicador.show({
              id: idConhecimentoTransporte,
            });
        }
        for (let i = 0; i < listaResponseXml.length; i++) {
          const responseXml = listaResponseXml[i];

          const utf8Content = new TextEncoder().encode(responseXml.xml as any);
          const blobXml = new Blob([utf8Content], {
            type: 'text/xml;charset=UTF-8',
          });

          listaAnexo.push({
            nomeArquivo: `${responseXml.nomeArquivo}.xml`,
            file: blobXml,
            permitirExcluir: false,
            boletoDocumentoFiscal: true,
          });
        }
      } else {
        for (let i = 0; i < listaAnexoAtual.listaValor.length; i++) {
          const anexo = listaAnexoAtual.listaValor[i];

          if (!anexo.boletoDocumentoFiscal) listaAnexo.push(anexo);
        }
      }

      // #endregion Anexos

      await formRefEmail.current?.setFieldValue('listaAnexo', listaAnexo);
    },
    [
      handleObterConhecimentoTransportePdf,
      handleObterNotaFiscalSaidaPdf,
      idConhecimentoTransporte,
      idNotaFiscalSaida,
      numeroDocumento,
    ]
  );

  const handleEnviarPorEmailNotaBoleto = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    const situacaoNotaFiscal =
      formPrincipal.formRef.current?.getFieldValue('situacao');

    if (situacaoNotaFiscal !== SituacaoNotaFiscalSaidaEnum.autorizada) {
      ToastErro(
        'A Situacao da Nota Fiscal de Saída Vinculada não Foi Transmitida!'
      );
      return;
    }

    setShowEnviarEmailNotaBoleto(true);
  }, [formPrincipal, handleSalvar]);

  const handleOnCarregarDadosIniciaisEmailNotaBoleto = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: true,
            direcionarDocumentosFiscais: true,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: true,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarOuObterBlob(false);

      if (anexo) {
        listaAnexo.push({
          nomeArquivo: `Boleto(s) ${numeroDocumento}.pdf`,
          file: anexo,
          permitirExcluir: false,
        });
      }

      const anexoNotaFiscal = await handleVisualizarObterBlobPdf(false);

      const data = new Uint8Array((anexoNotaFiscal as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `Nota Fiscal (Saida).pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml = await NotaFiscalSaidaExportarXmlComunicador.show(
        { id: idNotaFiscalSaida }
      );

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml.xml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Boleto(s) ${numeroDocumento}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [
      handleVisualizarObterBlobPdf,
      handleVisualizarOuObterBlob,
      idNotaFiscalSaida,
      idPessoa,
      numeroDocumento,
    ]
  );

  // #endregion Email

  const handleBaixarBoleto = useCallback(
    async (index: number): Promise<void> => {
      try {
        setLoading(true);

        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja realmente baixar o boleto? Se baixado não será mais
              possível realizar o pagamento do mesmo!
            </span>
          ),
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (!resposta) {
          setLoading(false);
          return;
        }

        const response = await BoletoPedidoBaixaComunicador.update({
          params: {
            idBoleto: listaParcela[index].id,
          },
        });

        if (response && response.mensagem) {
          ToastSucesso(response.mensagem);
        }

        await handleCarregarDadosLista();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [abrirJanela, handleCarregarDadosLista, listaParcela, setLoading]
  );

  const handleConsultar = useCallback(
    async (index: number): Promise<void> => {
      try {
        setLoading(true);

        const response = await BoletoConsultarComunicador.update({
          params: {
            idBoleto: listaParcela[index].id,
          },
        });

        if (response && response.mensagem) {
          ToastSucesso(response.mensagem);
        }

        await handleCarregarDadosLista();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDadosLista, listaParcela, setLoading]
  );

  const handleExcluir = useCallback(
    async (index: number): Promise<void> => {
      try {
        setLoading(true);

        const response = await BoletoExcluirBaixaComunicador.update({
          params: {
            idBoleto: listaParcela[index].id,
          },
        });

        if (response && response.mensagem) {
          ToastSucesso(response.mensagem);
        }

        await handleCarregarDadosLista();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDadosLista, listaParcela, setLoading]
  );

  const handleObterSituacaoBoleto = useCallback(
    (index: number): SituacaoBoletoEnum | null => {
      if (listaParcela && listaParcela[index]) {
        return listaParcela[index].situacao;
      }

      return null;
    },
    [listaParcela]
  );

  useEffect(() => {
    handleCarregarDadosLista();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDetalheRegistro]);

  return (
    <>
      <JanelaDetalhe
        titulo="Boletos"
        tamanho="xl"
        onFecharFormModal={onFecharFormModal}
      >
        <LoadingDiv isLoading={loading} isToggleJanela />

        <FormCia ref={formRefLista}>
          <Modal.Body>
            <Row>
              <Col
                xl={4}
                lg={4}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              />
            </Row>

            <ListaProvider>
              <Tabela
                style={{ display: 'flex', overflow: 'auto', minHeight: 300 }}
              >
                <Table
                  striped
                  hover
                  bordered
                  variant="light"
                  style={{ height: 50 }}
                >
                  <thead>
                    <tr>
                      <th
                        className="lista-texto"
                        style={{
                          width: '4%',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        <span>
                          <div
                            className="checkbox-container"
                            style={{ alignItems: 'center' }}
                          >
                            <input
                              ref={selecionarTodosRef}
                              name="selecionado"
                              type="checkbox"
                              defaultChecked
                              onChange={(event) => {
                                handleSelecionarTodos(event.target.checked);
                                refresh();
                              }}
                            />
                          </div>
                        </span>
                      </th>
                      <th className="lista-texto" style={{ width: '3%' }}>
                        <span>Número</span>
                      </th>
                      <th className="lista-texto" style={{ width: '3%' }}>
                        <span>Parcela</span>
                      </th>
                      <th className="lista-data" style={{ width: '4%' }}>
                        <span>Data Vencimento</span>
                      </th>
                      <th className="lista-valor" style={{ width: '4%' }}>
                        <span>Val. Parcela</span>
                      </th>
                      <th className="lista-valor" style={{ width: '6%' }}>
                        <span>Val. Total Parcela</span>
                      </th>
                      <th className="lista-texto" style={{ width: '4%' }}>
                        <span>Nosso Número</span>
                      </th>
                      <th className="lista-texto" style={{ width: '4%' }}>
                        <span>Situação</span>
                      </th>
                      <th
                        className="lista-acoes"
                        style={{ width: '4%', textAlign: 'center' }}
                      >
                        <span>Ações</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listaParcela.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={v4()}
                            onClick={() => {
                              handleEditar(item);
                            }}
                          >
                            <td
                              className="checkbox-container"
                              style={{
                                width: '4%',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <input
                                name="selecionado"
                                type="checkbox"
                                defaultChecked={item.selecionado}
                                ref={(instance) => {
                                  if (
                                    listaParcelaSelecionadaRef &&
                                    listaParcelaSelecionadaRef.current
                                  ) {
                                    listaParcelaSelecionadaRef.current[index] =
                                      instance;
                                  }
                                }}
                                onChange={(event) => {
                                  listaParcela[index].selecionado =
                                    event.currentTarget.checked;
                                  refresh();
                                }}
                              />
                            </td>
                            <td className="lista-texto">
                              {item.numeroDocumento}
                            </td>
                            <td className="lista-texto">
                              {`${item.sequencia}/${item.quantidadeParcelas}`}
                            </td>
                            <td className="lista-texto">
                              {FormatarDataParaPtBr(item.dataVencimento)}
                            </td>
                            <td className="lista-valor">
                              {Number(item.valorParcela).FormatarParaPtBr()}
                            </td>
                            <td className="lista-valor">
                              {Number(
                                item.valorTotalParcela
                              ).FormatarParaPtBr()}
                            </td>

                            <td className="lista-texto">{item.nossoNumero}</td>
                            <td className="lista-texto">{item.situacao}</td>
                            <td className="tdButton lista-acoes">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <BtnAcoesButton>
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleBaixarBoleto(index);
                                    }}
                                    disabled={
                                      loading ||
                                      item.situacao !==
                                        SituacaoBoletoEnum.registrado
                                    }
                                  >
                                    <span
                                      style={{ display: 'flex', fontSize: 16 }}
                                    >
                                      <AiOutlineCheck
                                        style={{ marginRight: 5 }}
                                      />
                                      Baixar Boleto
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleConsultar(index);
                                    }}
                                    disabled={loading}
                                  >
                                    <span
                                      style={{ display: 'flex', fontSize: 16 }}
                                    >
                                      <BiRefresh style={{ marginRight: 5 }} />
                                      Consultar
                                    </span>
                                  </Dropdown.Item>

                                  {handleObterSituacaoBoleto(index) ===
                                    SituacaoBoletoEnum.baixado && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        handleExcluir(index);
                                      }}
                                      disabled={loading}
                                    >
                                      <span
                                        style={{
                                          display: 'flex',
                                          fontSize: 16,
                                        }}
                                      >
                                        <AiOutlineDelete
                                          style={{ marginRight: 5 }}
                                        />
                                        Excluir
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                </BtnAcoesButton>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Tabela>
            </ListaProvider>
          </Modal.Body>

          <Modal.Footer>
            <div className="alinhar-direita espacamento-interno-para-esquerda-15">
              <Container style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={onFecharFormModal}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <IoMdReturnLeft />
                    <span style={{ marginLeft: 10 }}>Voltar</span>
                  </TextoLoading>
                </button>

                <Button
                  style={{
                    marginLeft: 15,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                  }}
                  className="btn-padrao btn-info"
                  type="button"
                  onClick={handleImprimir}
                  disabled={
                    loading ||
                    handleObterListaBoletosSelecionados().length === 0 ||
                    !permiteImprimir
                  }
                >
                  <TextoLoading loading={loading}>
                    <AiOutlinePrinter />
                    <span style={{ marginLeft: 10 }}>Imprimir</span>
                  </TextoLoading>
                </Button>

                <Button
                  style={{
                    marginLeft: 15,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                  }}
                  className="btn-padrao btn-info"
                  type="button"
                  onClick={handleEnviarPorEmail}
                  disabled={
                    loading ||
                    handleObterListaBoletosSelecionados().length === 0 ||
                    !permiteImprimir
                  }
                >
                  <TextoLoading loading={loading}>
                    <BiMailSend />
                    <span style={{ marginLeft: 10 }}>Enviar E-mail</span>
                  </TextoLoading>
                </Button>

                {idNotaFiscalSaida && (
                  <Button
                    style={{
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                    }}
                    className="btn-padrao btn-info"
                    type="button"
                    onClick={handleEnviarPorEmailNotaBoleto}
                    disabled={loading || !idNotaFiscalSaida}
                  >
                    <TextoLoading loading={loading}>
                      <BiMailSend />
                      <span style={{ marginLeft: 10 }}>
                        Enviar Nota e Boleto Por E-mail
                      </span>
                    </TextoLoading>
                  </Button>
                )}
              </Container>
            </div>
          </Modal.Footer>

          {showDetalheContaParcelaBoleto && (
            <ContaParcelaBoletoDetalhe
              onFecharFormModal={handleContaParcelaBoletoFecharFormModal}
              onSalvarFormModal={handleContaParcelaBoletoSalvarFormModal}
              situacao={situacao}
            />
          )}
        </FormCia>
      </JanelaDetalhe>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Boleto(s) por E-mail"
          isEnvioEmailBoleto
          mensagemPadrao={`Segue em anexo Boleto(s) de número: ${numeroDocumento}`}
          direcionarBoletos
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onAlterarDocumentosFiscais={handleOnAlterarDocumentosFiscais}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showEnviarEmailNotaBoleto && (
        <JanelaEnvioEmail
          titulo="Envio de Boleto(s) por E-mail"
          isEnvioEmailBoleto
          mensagemPadrao={`Segue em anexo Boleto(s) de número: ${numeroDocumento} e a Nota Físcal de Saída Vinculada`}
          direcionarBoletos
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmailNotaBoleto}
          onAlterarDocumentosFiscais={handleOnAlterarDocumentosFiscais}
          onFecharFormModal={() => {
            setShowEnviarEmailNotaBoleto(false);
          }}
        />
      )}
    </>
  );
};

export default ContaParcelaBoletoLista;
