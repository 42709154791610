import React, { createContext, useContext, useEffect, useState } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

interface ISituacaoBloquearCamposContext {
  situacaoBloquearCampos: boolean;
  setSituacaoBloquearCampos: React.Dispatch<React.SetStateAction<boolean>>;
}

const SituacaoBloquearCamposContext = createContext(
  {} as ISituacaoBloquearCamposContext
);

const SituacaoBloquearCamposHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  const [situacaoBloquearCampos, setSituacaoBloquearCampos] = useState(false);

  const listaConta = formRef.current?.getFieldValue('listaConta');

  useEffect(() => {
    const bloquear =
      listaConta && listaConta.length > 0 && listaConta[0].revertida;
    if (bloquear) {
      setSituacaoBloquearCampos(true);
    } else {
      setSituacaoBloquearCampos(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef, listaConta]);

  return (
    <SituacaoBloquearCamposContext.Provider
      value={{
        situacaoBloquearCampos,
        setSituacaoBloquearCampos,
      }}
    >
      {children}
    </SituacaoBloquearCamposContext.Provider>
  );
};

function UseSituacaoBloquearCampos(): ISituacaoBloquearCamposContext {
  const context = useContext(SituacaoBloquearCamposContext);
  return context;
}

export { SituacaoBloquearCamposHook, UseSituacaoBloquearCampos };
