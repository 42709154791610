import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../../Componentes/Inputs/Textarea';

interface IObservacoesTab {
  bloquearCamposPorImportacao: boolean;
}

const ObservacoesTab: React.FC<IObservacoesTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              style={{ minHeight: 350 }}
              label=""
              name="observacoes"
              maxLength={2000}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ObservacoesTab;
