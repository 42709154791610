import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/frente-caixa-pdv/possui-pagamento-tef-pendente';

interface IFrenteCaixaPdvPossuiPagamentoTefPendenteComunicadorShow {
  id: string;
}

class FrenteCaixaPdvPossuiPagamentoTefPendenteComunicador {
  public async show(
    params: IFrenteCaixaPdvPossuiPagamentoTefPendenteComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FrenteCaixaPdvPossuiPagamentoTefPendenteComunicador();
