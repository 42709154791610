import React, { useEffect } from 'react';
import ITipoDados from '../../../Main/Interface/ITipoDados';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';

const Texto: React.FC<ITipoDados> = ({ nome, valor, formRef }) => {
  useEffect(() => {
    formRef.current?.setFieldValue(nome, valor);
  }, [formRef, nome, valor]);

  return <InputDecimal name={nome} casasDecimais={2} />;
};

export default Texto;
