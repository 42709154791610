import React, { useCallback, useState } from 'react';
import {
  FormatarCnpj,
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaCstCreditoPisCofinsLista,
  RegraEscolhaTipoEnum,
  TipoUsuarioEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaCstCreditoPisCofinsComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoPisCofins/Comunicador/RegraEscolhaCstCreditoPisCofinsComunicador';
import RegraEscolhaCstCreditoPisCofinsLogComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoPisCofins/Log/Comunicador/RegraEscolhaCstCreditoPisCofinsLogComunicador';
import RegraEscolhaCstCreditoPisCofinsOrdemComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCstCreditoPisCofins/Comunicador/RegraEscolhaCstCreditoPisCofinsOrdemComunicador';

const RegraEscolhaCstCreditoPisCofinsLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const [loading, setLoading] = useState(false);
  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaCstCreditoPisCofinsComunicador.index(
          {
            params: {
              pesquisaAvancada,
              order,
              descAsc,
              limite,
              pagina,
              textoPesquisa,
            },
          }
        );

        const dados = response.dados[0].map(
          (data: IRegraEscolhaCstCreditoPisCofinsLista) => {
            return {
              id: data.id,

              quantidadeCriterios: (
                <div className="lista-valor">{data.quantidadeCriterios}</div>
              ),

              'listaRegraEscolhaCstCreditoPisCofinsCst.codigoSituacaoTributaria.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoPisCofinsCst
                      ? data.listaRegraEscolhaCstCreditoPisCofinsCst?.map(
                          (valor) => {
                            return (
                              <div key={valor.codigoSituacaoTributaria.codigo}>
                                {`${valor.codigoSituacaoTributaria.codigo} - ${valor.codigoSituacaoTributaria.descricao}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoPisCofinsRegimeTributario.regimeTributario':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoPisCofinsRegimeTributario
                      ? data.listaRegraEscolhaCstCreditoPisCofinsRegimeTributario.map(
                          (valor) => {
                            return (
                              <div key={valor.regimeTributario}>
                                {`${valor.regimeTributario}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoPisCofinsDestinado.destinado': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstCreditoPisCofinsDestinado
                    ? data.listaRegraEscolhaCstCreditoPisCofinsDestinado.map(
                        (valor) => {
                          return (
                            <div key={valor.destinado}>
                              {`${valor.destinado}; `}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'listaRegraEscolhaCstCreditoPisCofinsSped.spedTipoProduto.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoPisCofinsSped
                      ? data.listaRegraEscolhaCstCreditoPisCofinsSped.map(
                          (valor) => {
                            return (
                              <div key={valor.spedTipoProduto.codigo}>
                                {`${valor.spedTipoProduto.codigo} - ${valor.spedTipoProduto.descricao}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoPisCofinsTipoTransacao.tipoTransacao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoPisCofinsTipoTransacao
                      ? data.listaRegraEscolhaCstCreditoPisCofinsTipoTransacao.map(
                          (valor) => {
                            return (
                              <div key={valor.tipoTransacao}>
                                {`${valor.tipoTransacao}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoPisCofinsGrupoProdutoServico.grupoProdutoServico.descricao':
                (
                  <div className="lista-texto">
                    {data.listaRegraEscolhaCstCreditoPisCofinsGrupoProdutoServico
                      ? data.listaRegraEscolhaCstCreditoPisCofinsGrupoProdutoServico.map(
                          (valor) => {
                            return (
                              <div key={valor.grupoProdutoServico.descricao}>
                                {`${valor.grupoProdutoServico.descricao}; `}
                              </div>
                            );
                          }
                        )
                      : ''}
                  </div>
                ),

              'listaRegraEscolhaCstCreditoPisCofinsNcm.ncm.codigo': (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstCreditoPisCofinsNcm
                    ? data.listaRegraEscolhaCstCreditoPisCofinsNcm.map(
                        (valor) => {
                          return (
                            <div key={valor.ncm.codigo}>
                              {`${valor.ncm.codigo}; `}
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ),

              'regraEscolhaCstCreditoPisCofinsResultado.codigoSituacaoTributaria.codigoDescricao':
                (
                  <div className="lista-texto">
                    {data.regraEscolhaCstCreditoPisCofinsResultado &&
                    data.regraEscolhaCstCreditoPisCofinsResultado
                      .codigoSituacaoTributaria
                      ? `${data.regraEscolhaCstCreditoPisCofinsResultado.codigoSituacaoTributaria.codigo} - ${data.regraEscolhaCstCreditoPisCofinsResultado.codigoSituacaoTributaria.descricao}`
                      : ''}
                  </div>
                ),

              'regraEscolhaCstCreditoPisCofinsResultado.creditarPisCofins': (
                <div
                  className="lista-booleano"
                  style={{
                    color:
                      data.regraEscolhaCstCreditoPisCofinsResultado &&
                      data.regraEscolhaCstCreditoPisCofinsResultado
                        .creditarPisCofins
                        ? 'green'
                        : 'reed',
                  }}
                >
                  {data.regraEscolhaCstCreditoPisCofinsResultado &&
                  data.regraEscolhaCstCreditoPisCofinsResultado
                    .creditarPisCofins
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),

              cnpjContabilidade: (
                <div className="lista-texto">
                  {FormatarCnpj(String(data.cnpjContabilidade))}
                </div>
              ),
              dataInicioVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataInicioVigencia || ''
                  )}
                </div>
              ),
              dataFinalVigencia: (
                <div className="lista-data">
                  {FormatarDataParaPtBrSemTimeZone(
                    data.dataFinalVigencia || ''
                  )}
                </div>
              ),

              'listaRegraEscolhaCstCreditoPisCofinsEmpresa.ativo': (
                <div
                  className="lista-booleano"
                  style={{
                    color: data.listaRegraEscolhaCstCreditoPisCofinsEmpresa[0]
                      ?.ativo
                      ? 'green'
                      : 'red',
                  }}
                >
                  {data.listaRegraEscolhaCstCreditoPisCofinsEmpresa[0]?.ativo
                    ? 'Sim'
                    : 'Não'}
                </div>
              ),
              acoes: (
                <div className="lista-texto">
                  <Acoes
                    deletarFuncao={async () => {
                      try {
                        setLoading(true);

                        await RegraEscolhaCstCreditoPisCofinsComunicador.delete(
                          {
                            id: data.id,
                          }
                        );

                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    buscarLogs={async (params: any) => {
                      try {
                        setLoading(true);

                        const responseLog =
                          RegraEscolhaCstCreditoPisCofinsLogComunicador.show({
                            id: data.id,
                            params,
                          });

                        setLoading(false);
                        return responseLog;
                      } catch (error) {
                        setLoading(false);
                        throw error;
                      }
                    }}
                    disableDeletar={
                      (permissoes
                        .FiscalTributacaoRegraEscolhaCstsCreditosPisCofins
                        ?.exclui &&
                        tipoUsuario === TipoUsuarioEnum.eloGestor) ||
                      (tipoUsuario === TipoUsuarioEnum.contabilidade &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao) ||
                      (tipoUsuario === TipoUsuarioEnum.colaborador &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao) ||
                      (tipoUsuario === TipoUsuarioEnum.gestor &&
                        data.tipo !== RegraEscolhaTipoEnum.padrao)
                    }
                  />
                </div>
              ),
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      permissoes.FiscalTributacaoRegraEscolhaCstsCreditosPisCofins?.exclui,
      tipoUsuario,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'fiscalRegraCstCreditoPisCofins',
        },
      });
      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaCstCreditoPisCofinsOrdemComunicador.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra dos CSTs/Créditos de PIS e COFINS das Entradas"
          permissao={
            permissoes.FiscalTributacaoRegraEscolhaCstsCreditosPisCofins
          }
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaCstCreditoPisCofinsLogComunicador.index({
                      params,
                    });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaCstCreditoPisCofinsLista;
