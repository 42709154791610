import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/sisdevin/movimento-manual-sisdevin';

interface IMovimentoManualComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IMovimentoManualComunicadorShow {
  id: string;
}

interface IMovimentoManualComunicadorStore {
  params: {
    descricao: string;
    ativo: boolean;
  };
}

interface IMovimentoManualComunicadorUpdate {
  id: string;
  params: {
    descricao?: string;
    ativo?: boolean;
  };
}

interface IMovimentoManualComunicadorDelete {
  id: string;
}

class MovimentoManualComunicador {
  public async index(params: IMovimentoManualComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IMovimentoManualComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);
    return response.data;
  }

  public async store(params: IMovimentoManualComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }

  public async update(params: IMovimentoManualComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }

  public async delete(
    params: IMovimentoManualComunicadorDelete
  ): Promise<void> {
    const response = await api.delete(`${rota}/${params.id}`);
    return response.data;
  }
}

export default new MovimentoManualComunicador();
