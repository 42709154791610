import {
  FormatarEnum,
  ResponsavelSeguroEnum,
  SituacaoManifestoTransporteEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Select from '../../../../../../../Componentes/Select';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import InputCnpj from '../../../../../../../Componentes/Inputs/InputCnpj';
import InputCnpjCpf from '../../../../../../../Componentes/Inputs/InputCnpjCpf';
import Input from '../../../../../../../Componentes/Inputs/Input';
import ManifestoTransporteSeguroAverbacaoHook from './ListaDetalheSeguroAverbacao/Hooks';
import ListaDetalheSeguroAverbacao from './ListaDetalheSeguroAverbacao/Lista';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IManifestoTransporteSeguroModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const ManifestoTransporteSeguroDetalhe: React.FC<
  IManifestoTransporteSeguroModal
> = ({ onFecharFormModal, onSalvarFormModal }) => {
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const situacao = formPrincipal.formRef.current?.getFieldValue('situacao');
  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Seguro"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Select
                    label="Responsavel pelo Seguro"
                    name="responsavelSeguro"
                    options={FormatarEnum({
                      enumObj: ResponsavelSeguroEnum,
                    })}
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <InputCnpjCpf
                    ref={inputRefFocus}
                    label="CNPJ/CPF"
                    name="responsavelSeguroCnpjCpfVirtual"
                    placeholder="CNPJ/CPF"
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <h5>Informações da Seguradora</h5>

              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Input
                    label="Nome"
                    name="seguradoraNome"
                    placeholder="Nome"
                    maxLength={30}
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <InputCnpj
                    label="CNPJ"
                    name="seguradoraCnpj"
                    placeholder="CNPJ"
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Input
                    label="Número da Apólice"
                    name="numeroApolice"
                    placeholder="Número da Apólice"
                    maxLength={20}
                    disabled={situacaoBloquearCampos}
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <Row>
                <Col md={12}>
                  <ManifestoTransporteSeguroAverbacaoHook>
                    <ListaDetalheSeguroAverbacao name="listaManifestoTransporteSeguroAverbacao" />
                  </ManifestoTransporteSeguroAverbacaoHook>
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading ||
                situacaoBloquearCampos
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ManifestoTransporteSeguroDetalhe;
