import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/pedido-compra-importacao-cotacao-compra/obter-cotacao-compra';

interface IPedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicadorIndex {
  idPessoa: string;
}

class PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicador {
  public async index(
    params: IPedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new PedidoCompraImportacaoCotacaoCompraObterCotacaoCompraComunicador();
