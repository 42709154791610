"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var EmpresaVinculoEmpresaTipoVinculoEnum;
(function (EmpresaVinculoEmpresaTipoVinculoEnum) {
    EmpresaVinculoEmpresaTipoVinculoEnum["usuario"] = "usuario";
    EmpresaVinculoEmpresaTipoVinculoEnum["pessoa"] = "pessoa";
    EmpresaVinculoEmpresaTipoVinculoEnum["produto"] = "produto";
    EmpresaVinculoEmpresaTipoVinculoEnum["localEstoque"] = "localEstoque";
    EmpresaVinculoEmpresaTipoVinculoEnum["tributacao"] = "tributacao";
    EmpresaVinculoEmpresaTipoVinculoEnum["financeiro"] = "financeiro";
    EmpresaVinculoEmpresaTipoVinculoEnum["roteiroCentroTrabalhoOperacoes"] = "roteiroCentroTrabalhoOperacoes";
    EmpresaVinculoEmpresaTipoVinculoEnum["equipamentoAcessoriosOrdemServico"] = "equipamentoAcessoriosOrdemServico";
})(EmpresaVinculoEmpresaTipoVinculoEnum || (EmpresaVinculoEmpresaTipoVinculoEnum = {}));
exports.default = EmpresaVinculoEmpresaTipoVinculoEnum;
