import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../../../../Componentes/Inputs/Input';
import InputSwitch from '../../../../../../../../../../Componentes/Inputs/InputSwitch';
import InputDecimal from '../../../../../../../../../../Componentes/Inputs/InputDecimal';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';

const ItemImpostoAproximadoTab: React.FC = () => {
  const { refresh, formRefDetalhe } = UseListaDetalheForm();
  const { obterAliquotasImpostoAproximado } = UseItemTab();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const handleObterAliquotasImpostoAproximado = useCallback(async () => {
    const calcularValorImpostoAproximado =
      formRefDetalhe.current?.getFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.calcularImpostoAproximado'
      );
    if (calcularValorImpostoAproximado) {
      obterAliquotasImpostoAproximado();
    } else {
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.aliquotaFederal',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.aliquotaEstadual',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.aliquotaMunicipal',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.valorFederal',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.valorEstadual',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.valorMunicipal',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.fonte',
        null
      );
      formRefDetalhe.current?.setFieldValue(
        'notaFiscalSaidaItemImpostoAproximado.chave',
        null
      );
    }
  }, [formRefDetalhe, obterAliquotasImpostoAproximado]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputSwitch
              label="Calcular Imposto Aproximado"
              name="notaFiscalSaidaItemImpostoAproximado.calcularImpostoAproximado"
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                refresh();
                handleObterAliquotasImpostoAproximado();
              }}
              onChangeValue={() => {
                refresh();
                handleObterAliquotasImpostoAproximado();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              disabled
              label="Alíquota Federal"
              name="notaFiscalSaidaItemImpostoAproximado.aliquotaFederal"
              placeholder="Alíquota Federal"
              casasInteiras={5}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              disabled
              label="Valor Federal"
              name="notaFiscalSaidaItemImpostoAproximado.valorFederal"
              placeholder="Valor Federal"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              disabled
              label="Alíquota Estadual"
              name="notaFiscalSaidaItemImpostoAproximado.aliquotaEstadual"
              placeholder="Alíquota Estadual"
              casasInteiras={5}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimal
              disabled
              label="Valor Estadual"
              name="notaFiscalSaidaItemImpostoAproximado.valorEstadual"
              placeholder="Valor Unidade"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={12} sm={12}>
            <InputDecimal
              disabled
              label="Alíquota Municipal"
              name="notaFiscalSaidaItemImpostoAproximado.aliquotaMunicipal"
              placeholder="Alíquota Municipal"
              casasInteiras={5}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <InputDecimal
              disabled
              label="Valor Municipal"
              name="notaFiscalSaidaItemImpostoAproximado.valorMunicipal"
              placeholder="Valor Municipal"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Input
              disabled
              label="Fonte"
              name="notaFiscalSaidaItemImpostoAproximado.fonte"
              placeholder="Fonte"
              maxLength={30}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Input
              disabled
              label="Chave"
              name="notaFiscalSaidaItemImpostoAproximado.chave"
              placeholder="Chave"
              maxLength={10}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoAproximadoTab;
