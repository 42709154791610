import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/integracao/empresa-crm-simples';

interface IEmpresaCrmSimplesStore {
  params: any;
}

class EmpresaCrmSimplesComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async store(params: IEmpresaCrmSimplesStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaCrmSimplesComunicador();
