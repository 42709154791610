import React from 'react';
import ImprimirDiretoPDF from '../../../../Util/Relatorios/ImprimirDiretoPDF';
import PDF from '../../../../Util/Relatorios/PDF';
import NotaFiscalImpressaoComprovanteTefPDF from './NotaFiscalImpressaoComprovanteTefPDF';

interface IImpressaoNotaFiscalComprovanteTef {
  handleVisualizarPdf(data: any): Promise<void>;
  handleObterBlobPdf(dados: any): Promise<Blob | undefined>;
  handleImprimirDireto(dados: any): Promise<void>;
}

const ImpressaoNotaFiscalComprovanteTef =
  (): IImpressaoNotaFiscalComprovanteTef => {
    async function handleVisualizarPdf(dados: any): Promise<void> {
      const relatorio = await PDF({
        documento: <NotaFiscalImpressaoComprovanteTefPDF dados={dados} />,
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(dados: any): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: <NotaFiscalImpressaoComprovanteTefPDF dados={dados} />,
      });

      return relatorio.GetBlob();
    }

    async function handleImprimirDireto(dados: any): Promise<void> {
      if (!dados) return;

      const relatorio = await PDF({
        documento: <NotaFiscalImpressaoComprovanteTefPDF dados={dados} />,
      });

      const blob = relatorio.GetBlob();

      await ImprimirDiretoPDF({ blob });
    }

    return { handleVisualizarPdf, handleObterBlobPdf, handleImprimirDireto };
  };

export default ImpressaoNotaFiscalComprovanteTef();
