import styled, { css } from 'styled-components';

interface IContainerProps {
  $isFocused: boolean;
  $isFilled: boolean;
  $isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 10px;

  width: 100%;
  color: #000;

  & + div {
    margin-top: 15px;
  }

  label {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  select {
    /* background: transparent; */
    background: #fff;

    border: 0;

    color: #000;
    height: 35px;
    width: 100%;

    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0 10px;

    &::placeholder {
      color: #666360;
    }

    ${(props) =>
      props.$isErrored &&
      css`
        border-color: #c53030;
      `}

    ${(props) =>
      props.$isFocused &&
      css`
        border-color: rgb(0, 123, 255);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      `}

    ${(props) =>
      props.$isFilled &&
      css`
        border-color: rgb(0, 123, 255);
      `}
  }
  svg {
    margin-right: 16px;
  }
`;

export const SpanErro = styled.span`
  color: #ff0000;
  font-weight: bold;
  margin-top: 5px;
`;
