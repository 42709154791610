import api from '../../../../../../Comum/Services/Api';

const rota =
  'servicos/ordens-servicos/ordem-servico/impressao/paisagem-com-foto-produto';

interface IOrdemServicoPaisagemComFotoProdutoImpressaoComunicadorShow {
  id: string;
}

class OrdemServicoPaisagemComFotoProdutoImpressaoComunicador {
  public async show(
    params: IOrdemServicoPaisagemComFotoProdutoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrdemServicoPaisagemComFotoProdutoImpressaoComunicador();
