import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/motorista-auto-complete';

interface IPessoaMotoristaAutoCompleteComunicadorIndex {
  params: any;
}

class PessoaMotoristaAutoCompleteComunicador {
  public async index(
    params: IPessoaMotoristaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaMotoristaAutoCompleteComunicador();
