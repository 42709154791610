import api from '../../../../../../Comum/Services/Api';

const rota = 'fiscal/sped-tipo-produto-auto-complete-tag';

interface ISpedTipoProdutoAutoCompleteTagComunicadorIndex {
  params: any;
}

class SpedTipoProdutoAutoCompleteTagComunicador {
  public async index(
    params: ISpedTipoProdutoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new SpedTipoProdutoAutoCompleteTagComunicador();
