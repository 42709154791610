import {
  FormatarDataParaPtBr,
  IConsultaAliquotaIpiPorNcmLista,
  TipoUsuarioEnum,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { CgImport, CgMoreO } from 'react-icons/cg/index.mjs';
import { Dropdown, Modal } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi/index.mjs';
import { BsArrowRight } from 'react-icons/bs/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import Lista from '../../../../../Componentes/Lista';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import ConsultaAliquotaIpiPorNcmComunicador from '../../../../../Comunicador/Fiscal/Tributacao/ConsultaAliquotaIpiPorNcm/Comunicador/ConsultaAliquotaIpiPorNcmComunicador';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import ImportacaoTipi from './ImportacaoTipi';

const ConsultaAliquotaIpiPorNcmLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { FiscalTributacaoConsultaAliquotaIpiPorNcm: permissao } = permissoes;
  const { telaGrande } = UseReactSizeMeBodyHook();

  const [loading, setLoading] = useState(false);
  const [showModalImpostacao, setShowModalImpostacao] = useState(false);
  const [listaErros, setListaErros] = useState<any[]>([]);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await ConsultaAliquotaIpiPorNcmComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map(
          (data: IConsultaAliquotaIpiPorNcmLista) => {
            return {
              id: data.id,
              dataInicioVigencia: (
                <div className="lista-texto">
                  {FormatarDataParaPtBr(data.dataInicioVigencia)}
                </div>
              ),
              'ncm.codigoDescricao': (
                <div className="lista-texto">
                  {data.ncm.codigo} - {data.ncm.descricao}
                </div>
              ),
              percentual: <div className="lista-texto">{data.percentual}</div>,
            };
          }
        );

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    []
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: {
          nomeTabela: 'fiscalConsultaAliquotaIpiPorNcm',
        },
      });

      const listaTh: ITh[] = response.campos;

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      {showModalImpostacao && (
        <ImportacaoTipi
          onFecharFormModalLista={() => setShowModalImpostacao(false)}
          key={v4()}
          setListaErros={setListaErros}
        />
      )}
      <ListaProvider>
        <SubHeader
          titulo="Consulta Alíquota de IPI por NCM"
          permissao={permissoes.FiscalTributacaoConsultaAliquotaIpiPorNcm}
          botaoNovo={false}
          botoes={
            <SplitAcoes
              id={v4()}
              className="btn-group"
              title={
                <div>
                  <TextoLoading loading={loading}>
                    <CgMoreO />
                    {telaGrande && (
                      <span style={{ marginLeft: 10, marginRight: 10 }}>
                        Ações
                      </span>
                    )}
                  </TextoLoading>
                </div>
              }
            >
              <Dropdown.Item
                disabled={
                  loading ||
                  !permissao?.inclui ||
                  !permissao?.altera ||
                  tipoUsuario !== TipoUsuarioEnum.eloGestor
                }
                onClick={() => {
                  setShowModalImpostacao(true);
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgImport style={{ marginRight: 5 }} />
                  Importar Planilha TIPI
                </span>
              </Dropdown.Item>
            </SplitAcoes>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          loading={loading}
        />
      </ListaProvider>

      <Modal
        show={listaErros.length > 0}
        size="lg"
        onHide={() => setListaErros([])}
        scrollable
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: 10 }}>
                <BiErrorCircle />
              </span>
              <span>Linhas com erro</span>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {listaErros.map((item, index) => (
            <div key={index}>
              <p>
                {item.identificador}
                <BsArrowRight className="mx-2" style={{ color: 'red' }} />
                {item.erro}
              </p>
            </div>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn-padrao btn-cinza-claro"
            onClick={() => {
              setListaErros([]);
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 16,
              }}
            >
              OK
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ConsultaAliquotaIpiPorNcmLista;
