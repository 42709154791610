import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/etiqueta';

interface IEtiquetaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IEtiquetaComunicadorShow {
  id: string;
}

interface IEtiquetaComunicadorStore {
  params: any;
}

interface IEtiquetaComunicadorUpdate {
  id: string;
  params: any;
}

interface IEtiquetaComunicadorDeletar {
  id: string;
}

class EtiquetaComunicador {
  public async index(params: IEtiquetaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IEtiquetaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IEtiquetaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IEtiquetaComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IEtiquetaComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new EtiquetaComunicador();
