const DevolucaoNotasEntradaPorItensRelatorioPlanilha = [
  { titulo: 'Serie', valor: 'serie' },
  { titulo: 'Numero', valor: 'numero' },
  { titulo: 'Cliente', valor: 'nomeRazaoSocial' },

  { titulo: 'Código', valor: 'listaItens.produto.codigo' },
  { titulo: 'Descrição', valor: 'listaItens.produto.descricao' },
  { titulo: 'Quantidade', valor: 'listaItens.quantidade' },

  { titulo: 'Qntd Devolvida', valor: 'listaItens.quantidadeDevolvida' },
  { titulo: 'Saldo', valor: 'listaItens.saldo' },
  { titulo: 'Situação', valor: 'listaItens.situacao' },
];

export default DevolucaoNotasEntradaPorItensRelatorioPlanilha;
