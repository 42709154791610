import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/empresa/certificado';

interface IEmpresaCertificadoComunicadorUpdate {
  params: any;
}

class EmpresaCertificadoComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async update(
    params: IEmpresaCertificadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaCertificadoComunicador();
