import React from 'react';
import ListaCategoria from './ListaCategoria';

const CategoriaTab: React.FC = () => {
  return (
    <>
      <ListaCategoria name="listaMovimentoPortadorCategoria" />
    </>
  );
};

export default CategoriaTab;
