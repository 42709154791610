import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ListaDetalheRotasProvider } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheRotasContext';

const RotasHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ListaDetalheRotasProvider
      value={{
        carregar:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/servico',
        novo: '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-entrada-terceiro',
        alterar:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/servico',
        excluir:
          '/suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/servico',
      }}
    >
      {children}
    </ListaDetalheRotasProvider>
  );
};

export default RotasHook;
