import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormCia, IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  IoMdAddCircleOutline,
  IoMdRemoveCircleOutline,
} from 'react-icons/io/index.mjs';
import { IEstoqueMovimentoSisdevinEditarLista } from '@elogestor/util';
import { v4 } from 'uuid';
import { Container } from './styles';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import BtnAcoesButton from '../../../../../../../Componentes/Buttons/BtnAcoesButton';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputAutoCompleteCodigoRastreabilidade from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteCodigoRastreabilidade';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import SisdevinEstoqueMovimentoComunicador from '../../../../../../../Comunicador/Suprimentos/Sisdevin/SisdevinEstoqueMovimento/Comunicador/SisdevinEstoqueMovimentoComunicador';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseSisdevinEstoqueMovimentoLista } from '../../../Hooks/SisdevinEstoqueMovimentoListaHook';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';

interface IListaDetalheGeral {
  name: string;
  index: number;
  onHandleCarregarDados(): Promise<void>;
}

const ListaDetalheNotaFiscalSaidaItemSisdevin: React.FC<IListaDetalheGeral> = ({
  name,
  index,
  onHandleCarregarDados,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const { abrirJanela } = UseConfirmacao();

  const { listaValor } = UseSisdevinEstoqueMovimentoLista();
  const formRefLista = useRef<IFormCiaHandles>(null);
  const valorRef = useRef<IEstoqueMovimentoSisdevinEditarLista[]>([]);
  const [listaDados, setListaDados] = useState<
    IEstoqueMovimentoSisdevinEditarLista[]
  >([]);

  const [loading, setLoading] = useState(false);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRefLista.current?.setErrors({});
      const schema = Yup.object().shape({
        idCodigoRastreabilidadeSisdevin: Yup.string()
          .required('Código Rastreabilidade (CR) é Obrigatório')
          .nullable(),
        quantidadeSisdevin: Yup.number()
          .positive('Quantidade deve ser Maior do Que Zero!')
          .test({
            message: 'Quantidade deve ser Maior do Que Zero!',
            test: () => {
              if (data.quantidadeSisdevin < 0) return false;

              return true;
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRefLista.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleAdicionar = useCallback(async () => {
    const data = formRefLista.current?.getData();

    try {
      setLoading(true);
      if (!(await handleValidar(data)) || !data) {
        setLoading(false);
        return;
      }

      await SisdevinEstoqueMovimentoComunicador.update({
        id: String(listaValor[index].notaFiscalItemSisdevin.notaFiscalItem.id),
        params: {
          quantidade: data.quantidadeSisdevin,
          idCodigoRastreabilidadeSisdevin: data.idCodigoRastreabilidadeSisdevin,
          tipoMovimento: listaValor[index].tipoMovimento,
          dataMovimento: listaValor[index].dataMovimento,
        },
      });
      await formRefLista.current?.atualizarDataInicial();
      if (onHandleCarregarDados) onHandleCarregarDados();

      ToastSucesso('Código de Rastreabilidade Adicionado!');
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [handleValidar, index, listaValor, onHandleCarregarDados]);

  const handleExcluir = useCallback(
    async (
      idSisdevinEstoqueMovimento: string,
      idNotaFiscalSisdevin: string
    ) => {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja Remover o Vínculo do Código de Rastreabilidade?
          </span>
        ),
      });

      try {
        if (resposta) {
          await SisdevinEstoqueMovimentoComunicador.delete({
            id: idSisdevinEstoqueMovimento,
            params: {
              tipoMovimento: listaValor[index].tipoMovimento,
              idNotaFiscalItem:
                listaValor[index].notaFiscalItemSisdevin.notaFiscalItem.id,
              idNotaFiscalItemSisdevin: idNotaFiscalSisdevin,
            },
          });

          ToastSucesso('Código de Rastreabilidade Deletado!');

          if (onHandleCarregarDados) onHandleCarregarDados();
        }
      } catch (error) {
        TratarErros(error, { redirecionar: false });
      }
    },
    [abrirJanela, index, listaValor, onHandleCarregarDados]
  );

  useEffect(() => {
    valorRef.current = listaDados;
  }, [listaDados]);

  useEffect(() => {
    registerField<IEstoqueMovimentoSisdevinEditarLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaDados([]);
        else setListaDados(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaDados([]);
        else setListaDados(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormCia ref={formRefLista}>
        <LoadingDiv isLoading={loading}>
          <Divisor>
            <Row>
              <Col xl={5} lg={5} md={5} sm={6}>
                <InputAutoCompleteCodigoRastreabilidade
                  name="idCodigoRastreabilidadeSisdevin"
                  label="Código de Rastreabilidade (CR)"
                  permitirAdicionar={false}
                />
              </Col>
              <Col xl={5} lg={5} md={5} sm={6}>
                <InputDecimal name="quantidadeSisdevin" label="Quantidade" />
              </Col>

              <Col
                lg={2}
                md={12}
                sm={12}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <BtnPadraoButton
                  type="button"
                  className="btn-padrao btn-verde btn-adicionar"
                  onClick={handleAdicionar}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <IoMdAddCircleOutline />
                    <span style={{ marginLeft: 10 }}>Adicionar</span>
                  </TextoLoading>
                </BtnPadraoButton>
              </Col>
            </Row>

            <Tabela style={{ overflow: 'auto' }}>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '20%' }}>
                      <span>Código</span>
                    </th>

                    <th className="lista-texto" style={{ width: '60%' }}>
                      <span>Descrição</span>
                    </th>
                    <th className="lista-valor" style={{ width: '20%' }}>
                      <span>Quantidade</span>
                    </th>
                    <th className="lista-acoes">
                      <span>Ações</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaDados.map((item) => {
                    return (
                      <tr key={v4()}>
                        <td className="lista-texto">
                          {item.sisdevinEstoqueMovimento
                            .codigoRastreabilidadeSisdevin
                            ? item.sisdevinEstoqueMovimento
                                .codigoRastreabilidadeSisdevin
                                .codigoRastreabilidade
                            : ''}
                        </td>

                        <td className="lista-texto">
                          {item.sisdevinEstoqueMovimento
                            .codigoRastreabilidadeSisdevin
                            ? item.sisdevinEstoqueMovimento
                                .codigoRastreabilidadeSisdevin.descricao
                            : 'NÃO POSSUI CÓDIGO DE RASTREABILIDADE VINCULADO'}
                        </td>

                        <td className="lista-valor">
                          {Number(
                            item.sisdevinEstoqueMovimento.quantidade
                          ).FormatarParaPtBr({
                            maximoCasasDecimais: 4,
                            minimoCasasDecimais: 4,
                          })}
                        </td>

                        <td className="tdButton lista-acoes">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <BtnAcoesButton>
                              <Dropdown.Item
                                onClick={async () => {
                                  await handleExcluir(
                                    String(item.sisdevinEstoqueMovimento.id),
                                    String(item.id)
                                  );
                                }}
                                disabled={
                                  loading ||
                                  !item.sisdevinEstoqueMovimento
                                    .codigoRastreabilidadeSisdevin
                                }
                              >
                                <span style={{ display: 'flex', fontSize: 16 }}>
                                  <IoMdRemoveCircleOutline
                                    style={{ marginRight: 5 }}
                                  />
                                  Remover
                                </span>
                              </Dropdown.Item>
                            </BtnAcoesButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tabela>
          </Divisor>
        </LoadingDiv>
      </FormCia>
    </Container>
  );
};

export default ListaDetalheNotaFiscalSaidaItemSisdevin;
