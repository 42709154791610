import React, {
  forwardRef,
  useCallback,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IBlocoSped from './Interfaces/IBlocoSped';
import ISpedBlocoRegistro from './Interfaces/ISpedBlocoRegistro';
import ItemSpedBlocoRegistro, {
  ISpedBlocoRegistroRef,
} from './ItemSpedBlocoRegistro';

interface IListaSpedBlocoRegistro {
  bloco: IBlocoSped;
}

export interface IListaSpedBlocoRegistroRef {
  obterValorPai(): IBlocoSped;
  setFilhosMudou(valor: boolean): void;
  obterMarcado(): IBlocoSped | undefined;
}

const ListaSpedBlocoRegistro: React.ForwardRefRenderFunction<
  IListaSpedBlocoRegistroRef,
  IListaSpedBlocoRegistro
> = ({ bloco }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listaItemRef = useRef<Array<ISpedBlocoRegistroRef>>([]);
  const formPrincipal = UseForm();

  useEffect(() => {
    if (inputRef.current?.checked) {
      inputRef.current.checked = bloco.gerar;
    }
  }, [bloco.gerar]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const valor = event.target.checked;

      listaItemRef.current.forEach((itemRef) => {
        itemRef?.alterarValorInput(valor);
      });
    },
    []
  );

  const obterListaValorFilho = useCallback((): ISpedBlocoRegistro[] => {
    const listaValorFilho = listaItemRef.current.map((itemRef) => {
      return itemRef.obterValor();
    });

    return listaValorFilho;
  }, []);

  const obterListaFilhoMarcado = useCallback((): ISpedBlocoRegistro[] => {
    const listaValorFilho = listaItemRef.current
      .map((itemRef) => {
        return itemRef.obterMarcado();
      })
      .filter((filho) => filho);

    return listaValorFilho as ISpedBlocoRegistro[];
  }, []);

  const setFilhosMudou = useCallback((valor: boolean): void => {
    listaItemRef.current.forEach((itemRef) => {
      itemRef.setMudouRef(valor);
    });
  }, []);

  const alterarInputPai = useCallback(
    (valor: boolean) => {
      if (inputRef.current) {
        const aberturaFechamento: {
          registro: ISpedBlocoRegistro;
          ref: ISpedBlocoRegistroRef;
        }[] = [];

        listaItemRef.current.every((itemRef) => {
          const itemValor = itemRef.obterValor();
          if (/([a-z | 0-9](001|990))/i.test(itemValor.registro)) {
            aberturaFechamento.push({ registro: itemValor, ref: itemRef });
          }

          if (aberturaFechamento.length >= 2) {
            return false;
          }

          return true;
        });

        const [abertura, encerramento] = aberturaFechamento;

        const desmarcaTudo =
          abertura.registro.gerar !== encerramento.registro.gerar;

        if (valor) {
          inputRef.current.checked = true;

          abertura.ref.alterarValorInput(true);
          encerramento.ref.alterarValorInput(true);
        } else if (desmarcaTudo) {
          inputRef.current.checked = false;

          listaItemRef.current.forEach((itemRef) => {
            itemRef?.alterarValorInput(false);
          });
        } else {
          const listaValorFilho = obterListaValorFilho();

          const existeValorFilho = listaValorFilho.find((valorFilho) => {
            return valorFilho.gerar;
          });
          if (!existeValorFilho) {
            inputRef.current.checked = false;
          }
        }
      }
    },
    [obterListaValorFilho]
  );

  const obterValorPai = useCallback((): IBlocoSped => {
    return {
      ...bloco,
      listaSpedBlocoRegistro: obterListaValorFilho(),
      gerar: inputRef.current?.checked || false,
    };
  }, [bloco, obterListaValorFilho]);

  const obterMarcado = useCallback((): IBlocoSped | undefined => {
    if (!inputRef.current?.checked) return undefined;

    return {
      ...bloco,
      listaSpedBlocoRegistro: obterListaFilhoMarcado(),
      gerar: inputRef.current.checked,
    };
  }, [bloco, obterListaFilhoMarcado]);

  useImperativeHandle(ref, () => {
    return {
      obterValorPai,
      setFilhosMudou,
      obterMarcado,
    };
  });

  return (
    <>
      <tr key={v4()}>
        <td>
          <input
            style={{ height: 30 }}
            ref={(instance) => {
              if (instance) {
                inputRef.current = instance;
              }
            }}
            defaultChecked={bloco.gerar}
            type="checkbox"
            onChange={handleChange}
            disabled={bloco.desabilitado || formPrincipal.loading}
          />
        </td>

        <td>{`${bloco.bloco} - ${bloco.descricao}`}</td>
      </tr>

      {bloco.listaSpedBlocoRegistro.map((blocoRegistro, index) => {
        return (
          <ItemSpedBlocoRegistro
            key={blocoRegistro.id}
            blocoRegistro={blocoRegistro}
            alterarInputPai={alterarInputPai}
            ref={(instance) => {
              if (instance) {
                listaItemRef.current[index] = instance;
              }
            }}
          />
        );
      })}
    </>
  );
};

export default forwardRef(ListaSpedBlocoRegistro);
