import React from 'react';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import { UseReactSizeMeGlobalHook } from '../../../Hooks/ReactSizeMeGlobalHook';
import { Container } from './styles';

interface IContainerButtons extends IPadraoProps {
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const ContainerButtons: React.FC<IContainerButtons> = ({
  children,
  containerStyle,
  ...rest
}) => {
  const { telaGrande } = UseReactSizeMeGlobalHook();

  return (
    <Container style={containerStyle}>
      <div style={{ marginLeft: telaGrande ? 'auto' : 0 }} {...rest}>
        {children}
      </div>
    </Container>
  );
};

export default ContainerButtons;
