const ComissoesNotaFiscalPorLiquidezComClientePlanilha = [
  {
    titulo: 'Representante',
    valor: 'listaComissoesNotaFiscal.representanteNomeRazaoSocial',
  },
  {
    titulo: 'Data Emissão',
    valor: 'listaComissoesNotaFiscal.dataLiquidacao',
  },

  {
    titulo: 'Tipo ',
    valor: 'listaComissoesNotaFiscal.tipo',
  },

  {
    titulo: 'Número',
    valor: 'listaComissoesNotaFiscal.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Base Comissão',
    valor: 'listaComissoesNotaFiscal.baseCalculo',
  },

  {
    titulo: 'Percentual',
    valor: 'listaComissoesNotaFiscal.aliquota',
  },
  {
    titulo: 'Valor',
    valor: 'listaComissoesNotaFiscal.valor',
  },
];

export default ComissoesNotaFiscalPorLiquidezComClientePlanilha;
