import React from 'react';
import IPadraoProps from '../../Comum/Interface/IPadraoProps';
import { CheckSwitch } from './styles';

interface ISwitch extends IPadraoProps {
  ativo: string;
  inativo: string;
  largura?: number;
}

const Switch: React.FC<ISwitch> = ({
  children,
  ativo = 'Ativo',
  inativo = 'Inativo',
  largura = 80,
  ...rest
}) => {
  return (
    <CheckSwitch $ativo={ativo} $inativo={inativo} $largura={largura} {...rest}>
      {children}
      <div />
    </CheckSwitch>
  );
};

export default Switch;
