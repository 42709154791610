import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import ManifestoTransporteSeguroHook from './ListaDetalheSeguro/Hooks';
import ListaDetalheSeguro from './ListaDetalheSeguro/Lista';

const SeguroTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col md={12}>
            <ManifestoTransporteSeguroHook>
              <ListaDetalheSeguro name="listaManifestoTransporteSeguro" />
            </ManifestoTransporteSeguroHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default SeguroTab;
