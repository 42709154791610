import { IUsuarioValoresAlterar, TipoUsuarioEnum } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import GeralTab from '../GeralTab';
import PermissoesTab from '../PermissoesTab';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import SubheaderUsuario from './SubheaderUsuario';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import LiberacoesTab from '../LiberacoesTab';
import RepresentantesTab from '../RepresentantesTab';

type IProps = IMain<IUsuarioValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoUsuario: permissao } = permissoes;

  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <SubheaderUsuario />
      <Tabs id={v4()} defaultActiveKey="geral">
        <Tab eventKey="geral" title="Geral">
          <GeralTab />
        </Tab>

        <Tab eventKey="permissoes" title="Permissões">
          <PermissoesTab />
        </Tab>

        <Tab eventKey="liberacoes" title="Liberações">
          <LiberacoesTab />
        </Tab>

        <Tab eventKey="representantes" title="Representantes">
          <RepresentantesTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
