import api from '../../../../../Comum/Services/Api';

const rota = '/manufatura/producao/ordem-desmontagem/validar-estoque';

interface IOrdemDesmontagemComunicadorStore {
  params: any[];
}

class ValidarEstoqueMovimentoComunicador {
  public async store(
    params: IOrdemDesmontagemComunicadorStore
  ): Promise<boolean> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ValidarEstoqueMovimentoComunicador();
