import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/nfe/obter-item-estoque';

interface IImportacaoXmlTerceiroNfeObterItemEstoqueComunicadorIndex {
  params: {
    idPessoa?: string;
    idProduto?: string;
    dataHoraEmissao: Date;
    idSpedTipoProduto: string;
    idOperacaoFiscal: string;
    idNcm: string;
    quantidade: number;
  };
}

class ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador {
  public async index(
    params: IImportacaoXmlTerceiroNfeObterItemEstoqueComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroNfeObterItemEstoqueComunicador();
