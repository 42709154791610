import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import InputAutoCompleteLocalEstoque from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteLocalEstoque';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const EstoqueTab: React.FC = () => {
  const { formRef } = UseForm();
  const [showCamposEstoque, setShowCamposEstoque] = useState(true);
  const inputControlarLote = formRef.current?.getFieldValue(
    'produtoEstoque.controlarLote'
  );

  const handleChangeControlarEstoque = useCallback(
    async (isControlarEstoque: boolean) => {
      setShowCamposEstoque(!isControlarEstoque);
    },
    []
  );

  const currentControlarEstoque = formRef.current?.getFieldValue(
    'produtoEstoque.controlarEstoque'
  );

  useEffect(() => {
    handleChangeControlarEstoque(currentControlarEstoque);
  }, [currentControlarEstoque, handleChangeControlarEstoque]);

  const handleChangeControlarLote = useCallback(
    async (isControlarLote: boolean) => {
      if (!isControlarLote) {
        formRef.current?.setFieldDisabled(
          'produtoEstoque.controlarEstoque',
          false
        );
      }
      if (isControlarLote) {
        formRef.current?.setFieldValue('produtoEstoque.controlarEstoque', true);
        formRef.current?.setFieldDisabled(
          'produtoEstoque.controlarEstoque',
          true
        );
      }
    },
    [formRef]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Controlar Estoques"
                name="produtoEstoque.controlarEstoque"
                onChangeValue={(event) => {
                  handleChangeControlarEstoque(event.valorAtual);
                }}
                disabled={inputControlarLote}
              />
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} className="div-checkbox">
            <div className="checkbox-container">
              <InputCheckbox
                label="Controlar Lotes"
                name="produtoEstoque.controlarLote"
                onChangeValue={(event) => {
                  handleChangeControlarLote(event.valorAtual);
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            style={{ display: `${showCamposEstoque ? 'none' : 'flex'}` }}
          >
            <InputDecimalNulavel
              label="Estoque Mínimo"
              name="produtoEstoque.quantidadeEstoqueMinimo"
              placeholder="Estoque Mínimo"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            style={{ display: `${showCamposEstoque ? 'none' : 'flex'}` }}
          >
            <InputDecimalNulavel
              label="Estoque Máximo"
              name="produtoEstoque.quantidadeEstoqueMaximo"
              placeholder="Estoque Máximo"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
        </Row>

        <Row style={{ display: `${showCamposEstoque ? 'none' : 'flex'}` }}>
          <Col lg={6} md={12} sm={12}>
            <InputAutoCompleteLocalEstoque
              label="Local Estoque"
              name="produtoEstoque.listaProdutoEstoqueLocalEstoqueEmpresa[0].idLocalEstoque"
              placeholder="Local Estoque"
              nomeObjeto="produtoEstoque.listaProdutoEstoqueLocalEstoqueEmpresa[0].localEstoque"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default EstoqueTab;
