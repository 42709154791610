"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SituacaoCotacaoCompraEnum;
(function (SituacaoCotacaoCompraEnum) {
    SituacaoCotacaoCompraEnum["pendente"] = "Pendente";
    SituacaoCotacaoCompraEnum["aprovado"] = "Aprovado";
    SituacaoCotacaoCompraEnum["rejeitado"] = "Rejeitado";
    SituacaoCotacaoCompraEnum["cancelado"] = "Cancelado";
    SituacaoCotacaoCompraEnum["aprovadoPendente"] = "Pendente e Aprovado";
    SituacaoCotacaoCompraEnum["rejeitadoPendente"] = "Pendente e Rejeitado";
    SituacaoCotacaoCompraEnum["aprovadoRejeitado"] = "Aprovado e Rejeitado";
    SituacaoCotacaoCompraEnum["pendenteAprovadoRejeitado"] = "Pendente, Aprovado e Rejeitado";
})(SituacaoCotacaoCompraEnum || (SituacaoCotacaoCompraEnum = {}));
exports.default = SituacaoCotacaoCompraEnum;
