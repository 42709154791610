import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/portador/log';

interface IPortadorLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IPortadorLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class PortadorLogComunicador {
  public async index(
    params: IPortadorLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IPortadorLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new PortadorLogComunicador();
