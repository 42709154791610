"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var AcaoEnum;
(function (AcaoEnum) {
    AcaoEnum["inserir"] = "Inserir";
    AcaoEnum["alterar"] = "Alterar";
    AcaoEnum["excluir"] = "Excluir";
})(AcaoEnum || (AcaoEnum = {}));
exports.default = AcaoEnum;
