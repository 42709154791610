import {
  FormatarDataHoraParaPtBr,
  IMovimentacoesFinanceirasMovimentacoesPorPortadorCabecalhoRelatorio,
  IMovimentacoesFinanceirasMovimentacoesPorPortadorRelatorio,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
} from '@elogestor/util';
import React from 'react';
import MovimentacaoPorPortadorRelatorioComunicador from '../../../../../../../Comunicador/Financeiro/Relatorios/Movimentacoes/MovimentacoesFinanceirasPortadores/MovimentacaoPorPortadorRelatorioComunicador/MovimentacaoPorPortadorRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import MovimentacaoPorPortadorPDF from './MovimentacaoPorPortadorPDF';
import MovimentacaoPorPortadorPlanilha from './MovimentacaoPorPortadorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const MovimentacaoPorPortadorRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IMovimentacoesFinanceirasMovimentacoesPorPortadorRelatorio | null> => {
    const filtroBack: IMovimentacoesFinanceirasPortadoresFiltroRelatorioBackend =
      {
        dataHoraInicial: filtros.dataHoraInicial,
        dataHoraFinal: filtros.dataHoraFinal,
        saldoInicial: filtros.saldoInicial,
      };

    if (filtros?.listaPortadoresLiquidacao) {
      filtroBack.listaIdPortadoresLiquidacao =
        filtros.listaPortadoresLiquidacao.listaValor.map((value) => value.id) ||
        [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = filtros.tipoData;
    }

    const response = await MovimentacaoPorPortadorRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno =
      response as IMovimentacoesFinanceirasMovimentacoesPorPortadorRelatorio;

    const listaDados = await Promise.all(
      retorno.listaDados.map((dados: any) => {
        const valores = {
          ...dados.valores,
        };

        const listaMovimentacaoPortador = dados.listaMovimentacaoPortador.map(
          (movimentacao: any) => {
            return {
              ...movimentacao,
            };
          }
        );

        return {
          ...dados,
          valores,
          listaMovimentacaoPortador,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
    cabecalho: IMovimentacoesFinanceirasMovimentacoesPorPortadorCabecalhoRelatorio
  ): IMovimentacoesFinanceirasMovimentacoesPorPortadorCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraInicial || filtros.dataHoraFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataHoraEmissaoInicial = '--/--/--';
      cabecalho.filtroDataHoraEmissaoFinal = '--/--/--';

      if (filtros.dataHoraInicial) {
        cabecalho.filtroDataHoraEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraInicial
        );
      }
      if (filtros.dataHoraFinal) {
        cabecalho.filtroDataHoraEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraFinal
        );
      }
    }

    if (filtros.tipoData) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoData = filtros.tipoData;
    }

    if (
      filtros.listaPortadoresLiquidacao &&
      filtros.listaPortadoresLiquidacao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPortadoresLiquidacao =
        filtros.listaPortadoresLiquidacao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.saldoInicial) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSaldoInicial = filtros.saldoInicial;
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: MovimentacaoPorPortadorPlanilha,
      nomeArquivo: 'MovimentacaoPorPortador',
      valores: dados.listaDados,
      unwind: ['listaMovimentacaoPortador'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: MovimentacaoPorPortadorPlanilha,
      nomeArquivo: 'MovimentacaoPorPortador',
      valores: dados.listaDados as any,
      unwind: ['listaMovimentacaoPortador'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);
    const relatorio = await PDF({
      documento: <MovimentacaoPorPortadorPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <MovimentacaoPorPortadorPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default MovimentacaoPorPortadorRelatorio();
