/* eslint-disable no-nested-ternary */
import {
  FormatarEnum,
  IManifestoTransporteValoresAlterar,
  SituacaoManifestoTransporteEnum,
  TipoEmitenteEnum,
  TipoTransportadorEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../Componentes/Divisor';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import Select from '../../../../../Componentes/Select';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import InputAutoCompleteSerie from '../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import InputInteiro from '../../../../../Componentes/Inputs/InputInteiro';
import InputDateTime from '../../../../../Componentes/Inputs/InputDateTime';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';
import InputAutoCompleteEstado from '../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteEstado';
import DocumentoReferenciadoTab from '../DocumentoReferenciadoTab';
import RodoviarioTab from '../RodoviarioTab';
import ContratanteTab from '../ContratanteTab';
import PercursoTab from '../PercursoTab';
import SeguroTab from '../SeguroTab';
import ProdutoPredominanteLocacaoTab from '../ProdutoPredominanteLocacaoTab';
import InformacaoAdicionalTab from '../InformacaoAdicionalTab';
import TotaisTab from '../TotaisTab';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import SerieObterProximoNumeroIncrementarComunicador from '../../../../../Comunicador/Fiscal/Tributacao/Serie/Comunicador/SerieObterProximoNumeroIncrementarComunicador';
import AutorizacaoTab from '../AutorizacaoTab';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';

type IProps = IMain<IManifestoTransporteValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { TransporteManifestoTransporte: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const serie = formRef.current?.getFieldValueNomeObjeto('serie');

  const possuiDocumentoReferenciado =
    formRef.current?.getFieldValueNomeObjeto(
      'listaManifestoTransporteDocumentoReferenciado'
    )?.length > 0;

  const [situacao, setSituacao] =
    useState<SituacaoManifestoTransporteEnum | null>(null);

  const situacaoBloquearCampos =
    situacao === SituacaoManifestoTransporteEnum.autorizado ||
    situacao === SituacaoManifestoTransporteEnum.encerrado ||
    situacao === SituacaoManifestoTransporteEnum.cancelado;

  const [tabSelecionada, setTabSelecionada] = useState('rodoviario');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarNumero = useCallback(
    async (event: any) => {
      if (idDetalheRegistro) return;

      try {
        if (!event.itemAtual?.id) {
          await formRef.current?.setSemExecutarEvento({
            numero: 0,
          });

          return;
        }

        setLoading(true);

        const proximoNumero =
          await SerieObterProximoNumeroIncrementarComunicador.show({
            id: event.itemAtual.id,
          });

        await formRef.current?.setSemExecutarEvento({
          numero: proximoNumero,
        });

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [formRef, idDetalheRegistro, setLoading]
  );

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteSerie
                ref={inputRefFocus}
                label="Série"
                name="idSerie"
                placeholder="Série"
                nomeObjeto="serie"
                codigoModeloDocumento="58"
                onChangeItemAtual={handleAtualizarNumero}
              />
            </Col>
            <Col lg={1} md={6} sm={12}>
              <InputInteiro
                label="Numero"
                name="numero"
                placeholder="Número"
                disabled={serie ? serie.incrementarAutomaticamente : true}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime label="Data Hora Emissão" name="dataHoraEmissao" />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputDateTime
                label="Data Hora Inicio Viagem"
                name="dataHoraInicioViagem"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                name="situacao"
                label="Situação"
                formatarValor={(valor) => {
                  setSituacao(valor);
                  return (
                    <div className="lista-texto" style={{ fontWeight: 'bold' }}>
                      <span
                        style={{
                          color:
                            valor === SituacaoManifestoTransporteEnum.autorizado
                              ? 'green'
                              : valor ===
                                  SituacaoManifestoTransporteEnum.encerrado
                                ? 'blue'
                                : valor ===
                                    SituacaoManifestoTransporteEnum.cancelado
                                  ? 'red'
                                  : valor ===
                                      SituacaoManifestoTransporteEnum.rejeitado
                                    ? 'goldenrod'
                                    : 'black',
                        }}
                      >
                        {valor}
                      </span>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={12} sm={12}>
              <InputAutoCompleteEstado
                label="UF Emissão"
                name="idEstadoEmissao"
                placeholder="UF Emissão"
                nomeObjeto="estadoEmissao"
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo Emitente"
                name="tipoEmitente"
                options={FormatarEnum({
                  enumObj: TipoEmitenteEnum,
                })}
                onChange={() => {
                  refresh();
                }}
                disabled={possuiDocumentoReferenciado}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <Select
                label="Tipo Transportador"
                name="tipoTransportador"
                options={FormatarEnum({
                  enumObj: TipoTransportadorEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <Tabs
          id={v4()}
          defaultActiveKey="itens"
          activeKey={tabSelecionada}
          onSelect={(k) => setTabSelecionada(k || '')}
        >
          <Tab eventKey="rodoviario" title="Rodoviário">
            <RodoviarioTab />
          </Tab>

          <Tab eventKey="percurso" title="Percurso">
            <PercursoTab />
          </Tab>

          <Tab eventKey="documentoReferenciado" title="Documentos Fiscais">
            <DocumentoReferenciadoTab />
          </Tab>

          <Tab eventKey="contratante" title="Contrantante">
            <ContratanteTab />
          </Tab>

          <Tab eventKey="seguro" title="Seguro">
            <SeguroTab />
          </Tab>

          <Tab
            eventKey="produtoPredominanteLocacao"
            title="Produto Predominante e Locação"
          >
            <ProdutoPredominanteLocacaoTab />
          </Tab>

          <Tab eventKey="informacaoAdicional" title="Informações Adicionais">
            <InformacaoAdicionalTab />
          </Tab>

          <Tab eventKey="totais" title="Totais">
            <TotaisTab />
          </Tab>

          <Tab eventKey="autorizacao" title="Autorização">
            <AutorizacaoTab />
          </Tab>
        </Tabs>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui) ||
            situacaoBloquearCampos
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
