import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/demonstrativo-resultado-auto-complete';

interface IDemonstrativoResultadoAutoCompleteComunicadorIndex {
  params: any;
}

class DemonstrativoResultadoAutoCompleteComunicador {
  public async index(
    params: IDemonstrativoResultadoAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new DemonstrativoResultadoAutoCompleteComunicador();
