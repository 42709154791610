import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/conhecimento-transporte-terceiro/nota-referenciada';

interface IConhecimentoTransporteTerceiroNotaReferenciadaComunicadorStore {
  params: any;
}

interface IConhecimentoTransporteTerceiroNotaReferenciadaComunicadorDeletar {
  id: string;
}

class ConhecimentoTransporteTerceiroNotaReferenciadaComunicador {
  public async store(
    params: IConhecimentoTransporteTerceiroNotaReferenciadaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(
    params: IConhecimentoTransporteTerceiroNotaReferenciadaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ConhecimentoTransporteTerceiroNotaReferenciadaComunicador();
