import { IUnformFieldCiaUserProps, UseFieldCia } from '@elogestor/unformcia';
import { useEffect, useRef } from 'react';
import { IInputAutoCompleteTagBaseRef } from '../..';
import { IListaAcao } from '../UseListaAcao';

interface IUseRegisterAutoCompleteTag<T> {
  nome: string;
  handleObterChaveUnica: (itemAtual: T) => string | number;
}

function UseRegisterAutoCompleteTag<T = any>(
  { handleObterChaveUnica, nome }: IUseRegisterAutoCompleteTag<T>,
  UnformCiaField: Partial<IUnformFieldCiaUserProps<any>> = {}
): {
  autoCompleteRef: React.MutableRefObject<IInputAutoCompleteTagBaseRef | null>;
  error: string | undefined;
  flags: React.MutableRefObject<{ clearValue: boolean }>;
} {
  const autoCompleteRef = useRef<IInputAutoCompleteTagBaseRef | null>(null);
  const flags = useRef({
    clearValue: false,
  });

  const { fieldName, error, registerField } = UseFieldCia(nome);

  useEffect(() => {
    registerField<any>({
      name: fieldName,
      ref: autoCompleteRef,

      getValue() {
        const listaAcao = autoCompleteRef.current?.obterListaAcao() || [];
        const listaValor = autoCompleteRef.current?.obterListaItemAtual() || [];
        return {
          listaAcao,
          listaValor,
        };
      },

      setValue(_, value) {
        autoCompleteRef.current?.alterarListaItemAtualListaAcao(value || []);
      },

      setSemExecutarEvento(_, value) {
        autoCompleteRef.current?.alterarListaItemAtualListaAcaoSemEvento(
          value || []
        );
      },

      getDataRecuperarFormulario() {
        return {
          listaItemAtual: autoCompleteRef.current?.obterListaItemAtual() || [],
          listaAcao: autoCompleteRef.current?.obterListaAcao(),
        };
      },

      setDataRecuperarFormulario(_, value) {
        autoCompleteRef.current?.alterarListaItemAtualListaAcao(
          value.listaItemAtual || [],
          value.listaAcao || []
        );
      },

      getDataDuplicar(): { listaItemAtual: any[]; listaAcao: IListaAcao } {
        return {
          listaItemAtual: autoCompleteRef.current?.obterListaItemAtual() || [],
          listaAcao: {
            listaDeletar: [],
            listaMover: [],
            listaInserir:
              autoCompleteRef.current
                ?.obterListaItemAtual()
                .map((itemAtual, index) => {
                  return {
                    chave: handleObterChaveUnica(itemAtual),
                    ordem: index,
                    valor: itemAtual,
                  };
                }) || [],
          },
        };
      },

      getDataPesquisa() {
        return autoCompleteRef.current?.obterListaItemAtual() || [];
      },

      setDataDuplicar(_, value) {
        autoCompleteRef.current?.alterarListaItemAtualListaAcao(
          value.listaItemAtual || [],
          value.listaAcao || []
        );
      },

      clearValue() {
        flags.current.clearValue = true;
        autoCompleteRef.current?.alterarListaItemAtualListaAcao([]);
      },

      onAtualizarValorInicial() {
        autoCompleteRef.current?.alterarListaAcao({
          listaDeletar: [],
          listaInserir: [],
          listaMover: [],
        });
      },

      validarSeAlterou() {
        const listaAcao = autoCompleteRef.current?.obterListaAcao();
        if (
          listaAcao?.listaInserir.length ||
          listaAcao?.listaDeletar.length ||
          listaAcao?.listaMover.length
        ) {
          return true;
        }
        return false;
      },

      setDisabled(valor) {
        if (autoCompleteRef.current) {
          autoCompleteRef.current.setDisabled(valor);
        }
      },
      ...UnformCiaField,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    autoCompleteRef,
    flags,
    error,
  };
}

export default UseRegisterAutoCompleteTag;
