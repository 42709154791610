"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoCondicaoEnum;
(function (VeiculoCondicaoEnum) {
    VeiculoCondicaoEnum["acabado"] = "1 - Acabado";
    VeiculoCondicaoEnum["inacabado"] = "2 - Inacabado";
    VeiculoCondicaoEnum["semiacabado"] = "3 - Semiacabado";
})(VeiculoCondicaoEnum || (VeiculoCondicaoEnum = {}));
exports.default = VeiculoCondicaoEnum;
