import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import ManifestoTransporteDocumentoReferenciadoHook from './ListaDetalheDocumentoReferenciado/Hooks';
import ListaDetalheDocumentoReferenciado from './ListaDetalheDocumentoReferenciado/Lista';

const DocumentoReferenciadoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col md={12}>
            <ManifestoTransporteDocumentoReferenciadoHook>
              <ListaDetalheDocumentoReferenciado name="listaManifestoTransporteDocumentoReferenciado" />
            </ManifestoTransporteDocumentoReferenciadoHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default DocumentoReferenciadoTab;
