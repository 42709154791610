import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/modelo-documento-auto-complete-tag';

interface IModeloDocumentoAutoCompleteTagComunicadorIndex {
  params: any;
}

class ModeloDocumentoAutoCompleteTagComunicador {
  public async index(
    params: IModeloDocumentoAutoCompleteTagComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new ModeloDocumentoAutoCompleteTagComunicador();
