"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CalcularValorTotalParcela;
function CalcularValorTotalParcela(dados) {
    const valorTotalSoma = Number(dados.valorParcela) +
        Number(dados.valorJurosSoma) +
        Number(dados.valorMultaSoma) +
        Number(dados.valorOutrasDespesasSoma) +
        Number(dados.valorDespesasCartorioSoma) +
        Number(dados.valorDespesasEnvioSoma) +
        Number(dados.valorComissoesSoma);
    const valorTotalSubtrai = Number(dados.valorDescontoSubtrai) +
        Number(dados.valorOutrasDespesasSubtrai) +
        Number(dados.valorDespesasCartaoSubtrai) +
        Number(dados.valorDespesasEnvioSubtrai) +
        Number(dados.valorComissoesSubtrai);
    const valorTotalParcela = valorTotalSoma - valorTotalSubtrai;
    return valorTotalParcela;
}
