/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { ValidarCnpj, FormatarCnpj } from '@elogestor/util';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputCnpjProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IInputCnpjRef {
  focus(): void;
}

interface IInputCnpjProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputCnpjProps
  ) => Promise<void>;
}

const InputCnpj: React.ForwardRefRenderFunction<
  IInputCnpjRef,
  IInputCnpjProps
> = ({ name, label, onFocus, onChange, onBlur, ...rest }, ref) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    valorAnteriorOnBlur.current = inputRef.current?.value || '';
    valorAnteriorOnChange.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');

      let valor = event.target.value;

      if (valor.replace(/\D/g, '').length > 14) {
        valor = valor.slice(0, -1);
      }

      valor = FormatarCnpj(valor);
      event.target.value = valor;

      if (onChange) {
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: any) => {
      setIsFocused(false);

      const valor = inputRef.current?.value.replace(/\D/g, '');

      if (valor && !ValidarCnpj(valor)) {
        setErro('CNPJ inválido!');
      } else {
        setIsFilled(!!valor);
        if (onBlur) onBlur(event);
      }
    },
    [onBlur]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        let valor = inputRef.current?.value || '';
        valor = valor.replace(/\D/g, '');

        return valor;
      },

      setValue(_, value = '') {
        value = FormatarCnpj(value);
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = value;
        }
      },

      setSemExecutarEvento(_, value = '') {
        value = FormatarCnpj(value);
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = value;
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        type="text"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
        maxLength={18}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputCnpj);
