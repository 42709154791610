import { IPedidoVendaValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/obter-itens-calculo-rateios';

interface IObterPedidoVendaItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterPedidoVendaItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterPedidoVendaItensParaCalculoRateiosComunicadorIndex
  ): Promise<IPedidoVendaValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterPedidoVendaItensParaCalculoRateiosComunicador();
