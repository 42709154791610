import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPedidoVendaPorProdutoRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPedidoVendaPorProdutoPDF {
  dados: IPedidoVendaPorProdutoRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PedidoVendaPorProdutoPDF: React.FC<IPedidoVendaPorProdutoPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroDataEmissaoInicial &&
                  dados.cabecalho.filtroDataEmissaoFinal && (
                    <View>
                      <Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {'Período: '}
                        </Text>
                        {`${dados.cabecalho.filtroDataEmissaoInicial} até ${dados.cabecalho.filtroDataEmissaoFinal}`}
                      </Text>
                    </View>
                  )}

                {dados.cabecalho.filtroSituacaoItem && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Situação: '}</Text>
                      {dados.cabecalho.filtroSituacaoItem}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroModeloDocumento && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Modelo Documento: '}
                      </Text>
                      {dados.cabecalho.filtroModeloDocumento}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCliente && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Clientes: '}</Text>
                      {dados.cabecalho.filtroCliente}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRamoAtividadeProfissao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Ramo Atividade/Profissão: '}
                      </Text>
                      {dados.cabecalho.filtroRamoAtividadeProfissao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroProduto && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Produto: '}</Text>
                      {dados.cabecalho.filtroProduto}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCodigo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Código: '}</Text>
                      {dados.cabecalho.filtroCodigo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroTipoBuscaCodigoDescricao && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Filtro Código/Descrição: '}
                      </Text>
                      {dados.cabecalho.filtroTipoBuscaCodigoDescricao}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroGrupo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Grupo: '}</Text>
                      {dados.cabecalho.filtroGrupo}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroOperacaoFiscal && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Operacao Fiscal: '}
                      </Text>
                      {dados.cabecalho.filtroOperacaoFiscal}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEmpresas && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Empresas: '}</Text>
                      {dados.cabecalho.filtroEmpresas}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '1pt solid black',
              marginTop: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '11%' }}>
              <Text>Data</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '9%', alignItems: 'flex-end' }}>
              <Text>Número</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '30%' }}>
              <Text>Cliente</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Qtde.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Val. Unitário</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Val. Tot. Prod.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%', alignItems: 'flex-end' }}>
              <Text>Val. Tot. Bruto</Text>
            </View>
          </View>

          <View style={{ borderBottom: '1pt solid black' }}>
            <View>
              {dados.listaDados.map((dado, dadoIndex) => {
                return (
                  <View key={dado.chave}>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingTop: 5,
                        borderTop: dadoIndex !== 0 ? '1pt dashed black' : '',
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: 'bold',
                          marginLeft: 2,
                          width: '50%',
                          fontSize: 9,
                        }}
                      >
                        Item: {dado.valores.chaveFormatada}
                      </Text>

                      <Text
                        style={{
                          fontWeight: 'bold',
                          fontSize: 9,
                          textAlign: 'right',
                          paddingRight: 35,
                          width: '50%',
                        }}
                      >
                        Unidade:{' '}
                        {dado.listaPedidoVendaItem[0].produtoUnidadeMedidaSigla}
                      </Text>
                    </View>

                    <View>
                      {dado.listaPedidoVendaItem.map(
                        (pedidoVendaItem, pedidoVendaItemIndex) => {
                          return (
                            <View key={String(pedidoVendaItemIndex)}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor:
                                    pedidoVendaItemIndex % 2 === 0
                                      ? '#E4E4E4'
                                      : '#fff',
                                }}
                              >
                                <View style={{ width: '11%' }}>
                                  <Text>
                                    {pedidoVendaItem.pedidoVendaDataHoraEmissao}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '9%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>
                                    {pedidoVendaItem.pedidoVendaNumero}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View style={{ width: '30%' }}>
                                  <Text>
                                    {`${pedidoVendaItem.pessoaCodigo} - ${pedidoVendaItem.pessoaNomeRazaoSocial}`}
                                  </Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '5%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{pedidoVendaItem.valorQuantidade}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '13%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{pedidoVendaItem.valorUnitario}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '13%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{pedidoVendaItem.valorSubtotal}</Text>
                                </View>
                                <View style={{ width: '1%' }} />

                                <View
                                  style={{
                                    width: '13%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text>{pedidoVendaItem.valorTotal}</Text>
                                </View>
                              </View>
                            </View>
                          );
                        }
                      )}

                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 9,
                        }}
                      >
                        <View style={{ width: '50%' }}>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                          >
                            Total do Item: {dado.valores.total}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View
                          style={{
                            width: '7%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioQuantidade}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }} />

                        <View
                          style={{
                            width: '13%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioValorSubtotal}
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View
                          style={{
                            width: '13%',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Text style={{ fontWeight: 'bold' }}>
                            {dado.valores.somatorioValorTotal}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                display: 'flex',
                borderTop: '1pt solid black',
                marginTop: '2pt',
                paddingTop: '2pt',
                flexDirection: 'row',
                fontSize: 9,
              }}
            >
              <View style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                    marginLeft: 2,
                  }}
                >
                  Total Geral: {dados.totalGeral}
                </Text>
              </View>
              <View style={{ width: '1%' }} />
              <View style={{ width: '7%', alignItems: 'flex-end' }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralQuantidade}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View style={{ width: '14%' }} />

              <View
                style={{
                  width: '13%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorSubtotal}
                </Text>
              </View>
              <View style={{ width: '1%' }} />

              <View
                style={{
                  width: '13%',
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  {dados.somatorioGeralValorTotal}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaPorProdutoPDF;
