"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModalidadeBaseCalculoIcmsEnum;
(function (ModalidadeBaseCalculoIcmsEnum) {
    ModalidadeBaseCalculoIcmsEnum["margemValorAgregado"] = "0 - Margem Valor Agregado(%)";
    ModalidadeBaseCalculoIcmsEnum["pauta"] = "1 - Pauta (Valor)";
    ModalidadeBaseCalculoIcmsEnum["pre\u00E7oTabeladoMaximo"] = "2 - Pre\u00E7o Tabelado M\u00E1ximo (Valor)";
    ModalidadeBaseCalculoIcmsEnum["valorOperacao"] = "3 - Valor Opera\u00E7\u00E3o";
})(ModalidadeBaseCalculoIcmsEnum || (ModalidadeBaseCalculoIcmsEnum = {}));
exports.default = ModalidadeBaseCalculoIcmsEnum;
