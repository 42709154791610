import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/compras/nota-fiscal-entrada/nota-fiscal-entrada-resumo-por-data-emissao';

interface INotaFiscalEntradaResumoPorDataEmissaoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalEntradaResumoPorDataEmissaoRelatorioComunicador {
  public async index(
    params: INotaFiscalEntradaResumoPorDataEmissaoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalEntradaResumoPorDataEmissaoRelatorioComunicador();
