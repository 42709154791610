import React, { useCallback, useRef } from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import { useParams } from 'react-router-dom';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import BtnCloseButton from '../../../../../../../../Componentes/Buttons/BtnCloseButton';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import InputAutoCompleteTagProduto from '../../../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import { Divisor } from '../../../../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import IParametroRotaDetalhe from '../../../../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import EstruturaProdutoReplicarParaComunicador from '../../../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/ReplicarPara/Comunicador/EstruturaProdutoReplicarParaComunicador';
import { UseConfirmacao } from '../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IReplicarPara {
  aberto: boolean;
  fecharFormModal(): void;
}

const ReplicarPara: React.FC<IReplicarPara> = ({ aberto, fecharFormModal }) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const formPrincipal = UseForm();
  const { abrirJanela } = UseConfirmacao();
  const params: IParametroRotaDetalhe = useParams();

  const handleValidarDados = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          listaProdutoDestino: Yup.object().shape({
            listaValor: Yup.array().required(
              'Selecione ao menos um Produto de Destino!'
            ),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors: any = GetValidationErrors(error);

        formRef.current?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleFechar = useCallback(() => {
    fecharFormModal();
  }, [fecharFormModal]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as any;
      if (data) {
        data.idProdutoPaiOrigem = params.id;
      }

      if (!(await handleValidarDados(data))) {
        return { erro: true };
      }

      const produtoPaiOrigem =
        formPrincipal.formRef.current?.getFieldValueNomeObjeto('produtoPai');

      let produtoPaiOrigemDescricao = '';
      if (produtoPaiOrigem) {
        produtoPaiOrigemDescricao = produtoPaiOrigem.descricao;
      }

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            {`Você irá substituir toda a estrutura dos Produtos Selecionados pela estrutura
            do Produto de Origem, ${produtoPaiOrigemDescricao}, deseja continuar ?`}
          </span>
        ),
      });

      if (!resposta) {
        return { erro: false };
      }

      await EstruturaProdutoReplicarParaComunicador.update({ params: data });

      ToastSucesso('Dados replicados com sucesso!');
      handleFechar();
      return { erro: false };
    } catch (error) {
      TratarErros(error);
      return { erro: true };
    }
  }, [
    handleValidarDados,
    handleFechar,
    params.id,
    abrirJanela,
    formPrincipal.formRef,
  ]);

  return (
    <Modal show={aberto} onHide={handleFechar} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Replicar Para Outros Produtos</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFechar}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <Divisor>
            <InputAutoCompleteTagProduto
              name="listaProdutoDestino"
              label="Produtos"
              listaIdParaRemover={params.id ? [params.id] : []}
            />
            <span style={{ fontSize: 15, fontWeight: 'bold' }}>
              Atenção: A estruturas dos produtos selecionados acima serão
              substituidas pela do produto de origem da replicação!
            </span>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div>
          <Container>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={fecharFormModal}
                >
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </button>

                <button
                  type="button"
                  className="btn-padrao btn-azul-claro"
                  onClick={handleSubmit}
                  style={{ marginLeft: 10 }}
                >
                  <FiRefreshCcw />
                  <span style={{ marginLeft: 10 }}>Replicar</span>
                </button>
              </div>
            </Row>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplicarPara;
