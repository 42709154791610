import styled from 'styled-components';
import { darken } from 'polished';

export const Button = styled.button`
  padding: 0.375rem 0.75rem;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;

  background: #d12c38;
  font-weight: 600;
  color: #fff;

  height: 38px;
  &:hover {
    background: ${darken(0.05, '#d12c38')};
  }

  &:active {
    background: #d12c38;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 44, 56, 0.25);
  }

  &:disabled {
    color: #b9b9b9;
    background: rgba(209, 44, 56, 0.25);
  }
`;
