import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-nota-fiscal-saida/curva-abc-nota-fiscal-saida-ramo-atividade-profissao-por-valor';

interface ICurvaABCNotaFiscalSaidaRamoAtividadeProfissaoPorValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCNotaFiscalSaidaRamoAtividadeProfissaoPorValorRelatorioComunicador {
  public async index(
    params: ICurvaABCNotaFiscalSaidaRamoAtividadeProfissaoPorValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCNotaFiscalSaidaRamoAtividadeProfissaoPorValorRelatorioComunicador();
