"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var CanalPagamentoBanrisulRetornoEnum;
(function (CanalPagamentoBanrisulRetornoEnum) {
    CanalPagamentoBanrisulRetornoEnum["guicheCaixa"] = "1";
    CanalPagamentoBanrisulRetornoEnum["terminalAutoatendimento"] = "2";
    CanalPagamentoBanrisulRetornoEnum["internet"] = "3";
    CanalPagamentoBanrisulRetornoEnum["correspondente"] = "5";
    CanalPagamentoBanrisulRetornoEnum["telefone"] = "6";
    CanalPagamentoBanrisulRetornoEnum["arquivoEletronico"] = "7";
})(CanalPagamentoBanrisulRetornoEnum || (CanalPagamentoBanrisulRetornoEnum = {}));
exports.default = CanalPagamentoBanrisulRetornoEnum;
