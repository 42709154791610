import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/orcamento/impressao/paisagem-com-financeiro-previsao-entrega';

interface IOrcamentopaisagemComFinanceiroPrevisaoEntregaShow {
  id: string;
}

class OrcamentoPaisagemComFinanceiroPrevisaoEntregaComunicador {
  public async show(
    params: IOrcamentopaisagemComFinanceiroPrevisaoEntregaShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new OrcamentoPaisagemComFinanceiroPrevisaoEntregaComunicador();
