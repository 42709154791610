const OrdemProducaoResumoProdutosProduzidosPorLocalEstoquePlanilha = [
  {
    titulo: 'Local de Estoque',
    valor: 'chave',
  },
  {
    titulo: 'Situação',
    valor: 'listaOrdemProducaoLocalEstoque.ordemProducao.situacao',
  },

  {
    titulo: 'Data Lançamento',
    valor: 'listaOrdemProducaoLocalEstoque.ordemProducao.dataLancamento',
  },

  {
    titulo: 'Data Início',
    valor: 'listaOrdemProducaoLocalEstoque.ordemProducao.dataHoraInicio',
  },

  {
    titulo: 'Data de Término',
    valor: 'listaOrdemProducaoLocalEstoque.ordemProducao.dataHoraTermino',
  },

  {
    titulo: 'Produto',
    valor: 'listaOrdemProducaoLocalEstoque.ordemProducao.produto.descricao',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaOrdemProducaoLocalEstoque.quantidade',
  },
];

export default OrdemProducaoResumoProdutosProduzidosPorLocalEstoquePlanilha;
