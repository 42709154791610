import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import { UseSituacaoBloquearCampos } from '../../../../../Hook/SituacaoBloquearCamposHook';
import InputDecimal from '../../../../../../../../../../Componentes/Inputs/InputDecimal';

const ItemImpostoImportacaoTab: React.FC = () => {
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={3} sm={3}>
            <InputDecimal
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoImportacao.baseCalculo"
              placeholder="Base de Cálculo"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={3} sm={3}>
            <InputDecimal
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoImportacao.aliquota"
              placeholder="Alíquota"
              disabled={situacaoBloquearCampos}
            />
          </Col>

          <Col lg={3} md={3} sm={3}>
            <InputDecimal
              label="Valor Tot. Imposto de Importação"
              name="notaFiscalEntradaItemImpostoImportacao.valorTotal"
              placeholder="Valor"
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={3}>
            <InputDecimal
              label="Val. Desp. Aduaneiras"
              name="notaFiscalEntradaItemImpostoImportacao.valorDespesasAduaneiras"
              placeholder="Val. Desp. Aduaneiras"
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={3} md={3} sm={3}>
            <InputDecimal
              label="Valor IOF"
              name="notaFiscalEntradaItemImpostoImportacao.valorImpostoOperacoesFinanceiras"
              placeholder="Valor IOF"
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoImportacaoTab;
