import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-auto-complete';

interface IPessoaAutoCompleteComunicadorIndex {
  params: any;
}

class PessoaAutoCompleteComunicador {
  public async index(
    params: IPessoaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaAutoCompleteComunicador();
