import React, { useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputCnpjCpf from '../../../../../../Componentes/Inputs/InputCnpjCpf';
import InputInscricaoEstadual from '../../../../../../Componentes/Inputs/InputInscricaoEstadual';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IEndereco from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import IPesquisaEnderecoCepDetalheRef from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import InputCep from '../../../../../../Componentes/Inputs/InputCep';
import PesquisaEnderecoCepDetalhe from '../../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';
import IPesquisaPessoaCnpjCpfDetalheRef from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPesquisaPessoaCnpjCpfDetalheRef';
import PesquisaPessoaCnpjCpfDetalhe from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe';
import IPessoa from '../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPessoa';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseNotaFiscalSaida } from '../Hook/NotaFiscalSaidaHook';
import { ButtonAtualizarDados } from '../styles';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseSituacaoBloquearCampos } from '../Hook/SituacaoBloquearCamposHook';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';

const PessoaTab: React.FC = () => {
  const { formRef, refresh, loading } = UseForm();
  const { permissoes } = UsePermissoes();
  const { ComercialVendasNotaFiscalSaida: permissao } = permissoes;
  const { atualizarDadosClienteConformeCadastro } = UseNotaFiscalSaida();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  const janelaCepEnderecoRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const janelaCepEnderecoEntregaRef =
    useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const janelaCnpjCpfPessoaEnderecoEntregaRef =
    useRef<IPesquisaPessoaCnpjCpfDetalheRef>(null);

  const [ufEnderecoEntrega, setUfEnderecoEntrega] = useState('');

  const isCnpj =
    formRef.current?.getFieldValue('notaFiscalSaidaPessoa.cnpjCpfVirtual')
      .length === 14;
  const isExterior =
    formRef.current?.getFieldValue(
      'notaFiscalSaidaPessoa.documentoIdentificacaoEstrangeiro'
    ).length > 0;

  const isOutroEnderecoEntrega = formRef.current?.getFieldValue(
    'notaFiscalSaidaPessoaEnderecoEntrega.outroEnderecoEntrega'
  );

  const handleAtualizarDadosClienteConformeCadastro = useCallback(async () => {
    atualizarDadosClienteConformeCadastro();
  }, [atualizarDadosClienteConformeCadastro]);

  // #region Pesquisa CEP e Endereco

  const handlePesquisarCepEndereco = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();

    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'notaFiscalSaidaPessoaEndereco.cidade'
    );

    await janelaCepEnderecoRef.current?.pesquisarCep({
      cep: dados.notaFiscalSaidaPessoaEndereco.cep,
      logradouro: dados.notaFiscalSaidaPessoaEndereco.logradouro,
      bairro: dados.notaFiscalSaidaPessoaEndereco.bairro,
      idCidade: cidade?.id || '',
      nomeCidade: cidade?.nome || '',
      siglaEstado: cidade?.estado.sigla || '',
    });
  }, [formRef]);

  const handleOnSelecionarEndereco = useCallback(
    async (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEndereco.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEndereco.bairro',
        endereco.bairro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEndereco.cidade',
        endereco.cidade
      );
    },
    [formRef]
  );

  const handleChangeCidade = useCallback(
    ({ itemAtual }: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla',
        itemAtual?.estado?.sigla || ''
      );
    },
    [formRef]
  );

  // #endregion Pesquisa CEP e Endereco

  // #region Pesquisa CEP Endereco Entrega e CPF/CNPJ Endereco Entrega

  const handlePesquisarCepEnderecoEntrega =
    useCallback(async (): Promise<void> => {
      const dados = formRef.current?.getDataPesquisa();

      const cidade = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalSaidaPessoaEnderecoEntrega.cidade'
      );

      await janelaCepEnderecoEntregaRef.current?.pesquisarCep({
        cep: dados.notaFiscalSaidaPessoaEnderecoEntrega.cep,
        logradouro: dados.notaFiscalSaidaPessoaEnderecoEntrega.logradouro,
        bairro: dados.notaFiscalSaidaPessoaEnderecoEntrega.bairro,
        idCidade: cidade?.id || '',
        nomeCidade: cidade?.nome || '',
        siglaEstado: cidade?.estado.sigla || '',
      });
    }, [formRef]);

  const handleOnSelecionarEnderecoEntrega = useCallback(
    (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.bairro',
        endereco.bairro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.cidade',
        endereco.cidade
      );
    },
    [formRef]
  );

  const handleChangeCidadeEnderecoEntrega = useCallback(
    ({ itemAtual }: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.cidade.estado.sigla',
        itemAtual?.estado?.sigla || ''
      );

      setUfEnderecoEntrega(itemAtual?.estado.sigla || '');
    },
    [formRef]
  );

  const handlePesquisarCnpjCpfPessoaEnderecoEntrega = useCallback(async () => {
    const dados = formRef.current?.getDataPesquisa();
    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'notaFiscalSaidaPessoaEnderecoEntrega.cidade'
    );

    await janelaCnpjCpfPessoaEnderecoEntregaRef.current?.pesquisarCnpjCpf({
      cnpjCpf: dados.notaFiscalSaidaPessoaEnderecoEntrega.cnpjCpfVirtual || '',
      inscricaoEstadual:
        dados.notaFiscalSaidaPessoaEnderecoEntrega.inscricaoEstadual || '',
      nomeRazaoSocial:
        dados.notaFiscalSaidaPessoaEnderecoEntrega.nomeRazaoSocial || '',
      nomeFantasia:
        dados.notaFiscalSaidaPessoaEnderecoEntrega.nomeFantasia || '',
      cep: dados.notaFiscalSaidaPessoaEnderecoEntrega.cep || '',

      cidade: {
        id: cidade?.id || '',
        nome: cidade?.nome || '',
        estado: {
          sigla: cidade?.estado.sigla || '',
        },
      },
      logradouro: dados.notaFiscalSaidaPessoaEnderecoEntrega.logradouro || '',
      bairro: dados.notaFiscalSaidaPessoaEnderecoEntrega.bairro || '',
      complemento: dados.notaFiscalSaidaPessoaEnderecoEntrega.complemento || '',
      numero: dados.notaFiscalSaidaPessoaEnderecoEntrega.numero || '',
    });
  }, [formRef]);

  const handleOnSelecionarCnpjCpfPessoaEnderecoEntrega = useCallback(
    async (enderecoEntrega: IPessoa) => {
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.cnpjCpfVirtual',
        enderecoEntrega.cnpjCpf
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.cidade',
        enderecoEntrega.cidade
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.logradouro',
        enderecoEntrega.logradouro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.bairro',
        enderecoEntrega.bairro
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.complemento',
        enderecoEntrega.complemento
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.numero',
        enderecoEntrega.numero
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.cep',
        enderecoEntrega.cep
      );
      formRef.current?.setFieldValue(
        'notaFiscalSaidaPessoaEnderecoEntrega.nomeRazaoSocial',
        enderecoEntrega.nomeRazaoSocial
      );
    },
    [formRef]
  );

  // #endregion Pesquisa CEP Endereco Entrega e CPF/CNPJ Endereco Entrega

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={8} sm={12}>
            <ButtonAtualizarDados
              type="button"
              style={{
                minWidth: telaGrande ? 350 : '',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde btn-adicionar"
              onClick={handleAtualizarDadosClienteConformeCadastro}
              disabled={!permissao?.altera || loading || situacaoBloquearCampos}
            >
              <TextoLoading loading={loading}>
                <FiRefreshCcw />
                <span style={{ marginLeft: 10 }}>
                  Atualizar Dados Conforme Cadastro
                </span>
              </TextoLoading>
            </ButtonAtualizarDados>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <InputCnpjCpf
              label="CNPJ/CPF"
              name="notaFiscalSaidaPessoa.cnpjCpfVirtual"
              placeholder="CNPJ/CPF"
              disabled
              permitirPesquisar={false}
            />
          </Col>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <InputSwitch
              label="CNPJ/CPF no Documento Fiscal"
              name="notaFiscalSaidaPessoa.cnpjCpfNoDocumentoFiscal"
              placeholder="CNPJ/CPF no Documento Fiscal"
              ativo="Sim"
              inativo="Não"
              disabled
            />
          </Col>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <InputInscricaoEstadual
              label="Inscrição Estadual"
              name="notaFiscalSaidaPessoa.inscricaoEstadual"
              placeholder="Inscrição Estadual"
              disabled
            />
          </Col>
          <Col lg={3} md={4} sm={12} className={isExterior ? 'hidden' : ''}>
            <Input
              label="Inscrição Suframa"
              name="notaFiscalSaidaPessoa.inscricaoSuframa"
              placeholder="Inscrição Suframa"
              disabled
            />
          </Col>

          <Col lg={6} md={12} className={isExterior ? '' : 'hidden'}>
            <Input
              label="Número do Passaporte, ou outro documento legal do Estrangeiro"
              name="notaFiscalSaidaPessoa.documentoIdentificacaoEstrangeiro"
              placeholder="Número do Passaporte, ou outro documento legal do Estrangeiro"
              disabled
            />
          </Col>
        </Row>

        <Row>
          <Col lg={isCnpj || isExterior ? 6 : 12} md={12}>
            <Input
              label={isCnpj ? 'Nome Razão Social' : 'Nome'}
              name="notaFiscalSaidaPessoa.nomeRazaoSocial"
              placeholder={isCnpj ? 'Nome Razão Social' : 'Nome'}
              disabled
            />
          </Col>
          <Col lg={6} md={12} className={isCnpj || isExterior ? '' : 'hidden'}>
            <Input
              label="Nome Fantasia"
              name="notaFiscalSaidaPessoa.nomeFantasia"
              placeholder="Nome Fantasia"
              disabled
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <h5>Endereço</h5>

        <Row>
          <Col lg={3} md={12}>
            <InputCep
              name="notaFiscalSaidaPessoaEndereco.cep"
              label="CEP"
              placeholder="CEP"
              loading={loading}
              onPesquisarCep={handlePesquisarCepEndereco}
              disabled={situacaoBloquearCampos}
            />
            <PesquisaEnderecoCepDetalhe
              handleOnSelecionarEndereco={handleOnSelecionarEndereco}
              ref={janelaCepEnderecoRef}
            />
          </Col>
          <Col lg={7} md={12}>
            <InputAutoCompleteCidadeUf
              label="Cidade"
              placeholder="Cidade"
              name="notaFiscalSaidaPessoaEndereco.idCidade"
              nomeObjeto="notaFiscalSaidaPessoaEndereco.cidade"
              onChangeItemAtual={handleChangeCidade}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
              }}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              name="notaFiscalSaidaPessoaEndereco.cidade.estado.sigla"
              label="UF"
              disabled
              type="text"
              placeholder="UF"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12}>
            <Input
              label="Logradouro"
              name="notaFiscalSaidaPessoaEndereco.logradouro"
              placeholder="Logradouro"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              label="Número"
              name="notaFiscalSaidaPessoaEndereco.numero"
              placeholder="Número"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={12}>
            <Input
              label="Bairro"
              name="notaFiscalSaidaPessoaEndereco.bairro"
              type="text"
              placeholder="Bairro"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <Input
              label="Complemento"
              name="notaFiscalSaidaPessoaEndereco.complemento"
              type="text"
              placeholder="Complemento"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row className={isExterior ? 'hidden' : ''}>
          <Col lg={4} md={6} sm={12} className="bottom">
            <div className="checkbox-container">
              <InputCheckbox
                label="Outro endereço de entrega"
                name="notaFiscalSaidaPessoaEnderecoEntrega.outroEnderecoEntrega"
                onChange={() => {
                  refresh();
                }}
                disabled={situacaoBloquearCampos}
              />
            </div>
          </Col>
        </Row>
      </Divisor>

      <Divisor
        className={isOutroEnderecoEntrega && !isExterior ? '' : 'hidden'}
      >
        <h6>Dados do Endereço de Entrega</h6>

        <Row>
          <Col lg={3} md={12}>
            <InputCnpjCpf
              label="CNPJ/CPF"
              name="notaFiscalSaidaPessoaEnderecoEntrega.cnpjCpfVirtual"
              type="text"
              placeholder="CNPJ/CPF"
              loading={loading}
              onPesquisarCnpjCpf={handlePesquisarCnpjCpfPessoaEnderecoEntrega}
              disabled={situacaoBloquearCampos}
            />
            <PesquisaPessoaCnpjCpfDetalhe
              handleOnSelecionarPessoa={
                handleOnSelecionarCnpjCpfPessoaEnderecoEntrega
              }
              ref={janelaCnpjCpfPessoaEnderecoEntregaRef}
            />
          </Col>

          <Col lg={3} md={12}>
            <InputInscricaoEstadual
              label="Inscrição Estadual"
              name="notaFiscalSaidaPessoaEnderecoEntrega.inscricaoEstadual"
              type="text"
              uf={ufEnderecoEntrega}
              placeholder="Inscrição Estadual"
              maxLength={14}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={6} md={12}>
            <Input
              label="Nome Razão Social"
              name="notaFiscalSaidaPessoaEnderecoEntrega.nomeRazaoSocial"
              type="text"
              placeholder="Nome Razão Social"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={12}>
            <InputCep
              name="notaFiscalSaidaPessoaEnderecoEntrega.cep"
              label="CEP"
              placeholder="CEP"
              loading={loading}
              onPesquisarCep={handlePesquisarCepEnderecoEntrega}
              disabled={situacaoBloquearCampos}
            />
            <PesquisaEnderecoCepDetalhe
              handleOnSelecionarEndereco={handleOnSelecionarEnderecoEntrega}
              ref={janelaCepEnderecoEntregaRef}
            />
          </Col>
          <Col lg={7} md={12}>
            <InputAutoCompleteCidadeUf
              label="Cidade"
              placeholder="Cidade"
              name="notaFiscalSaidaPessoaEnderecoEntrega.idCidade"
              nomeObjeto="notaFiscalSaidaPessoaEnderecoEntrega.cidade"
              onChangeItemAtual={handleChangeCidadeEnderecoEntrega}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              name="notaFiscalSaidaPessoaEnderecoEntrega.cidade.estado.sigla"
              label="UF"
              disabled
              type="text"
              placeholder="UF"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12}>
            <Input
              label="Logradouro"
              name="notaFiscalSaidaPessoaEnderecoEntrega.logradouro"
              placeholder="Logradouro"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={2} md={12}>
            <Input
              label="Número"
              name="notaFiscalSaidaPessoaEnderecoEntrega.numero"
              placeholder="Número"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
          <Col lg={4} md={12}>
            <Input
              label="Bairro"
              name="notaFiscalSaidaPessoaEnderecoEntrega.bairro"
              type="text"
              placeholder="Bairro"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <Input
              label="Complemento"
              name="notaFiscalSaidaPessoaEnderecoEntrega.complemento"
              type="text"
              placeholder="Complemento"
              maxLength={60}
              disabled={situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PessoaTab;
