/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { v4 } from 'uuid';
import { UseFieldCia } from '@elogestor/unformcia';
import { SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputCheckboxProps {
  valorAnterior: boolean;
}

export interface IOnChangeValueInputCheckboxProps {
  valorAnterior: boolean;
}

export interface IOnChangeValueCheckbox {
  valorAtual: boolean;
  input: HTMLInputElement;
}

export interface IInputCheckboxRef {
  focus(): void;
}

interface IInputCheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputCheckboxProps
  ) => void | Promise<void>;
  onChangeValue?: (
    event: IOnChangeValueCheckbox,
    props: IOnChangeValueInputCheckboxProps
  ) => void | Promise<void>;
}

const InputCheckbox: React.ForwardRefRenderFunction<
  IInputCheckboxRef,
  IInputCheckboxProps
> = ({ name, label, id: defaultId, onChange, onChangeValue, ...rest }, ref) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [erro, setErro] = useState(erroUnform);
  const [id] = useState(defaultId || v4());

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnterior = useRef<boolean>(false);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    if (inputRef.current) {
      valorAnterior.current = inputRef.current.checked;
    }
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    registerField<boolean>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.checked || false;
      },

      setValue(_, value = false) {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.checked;
          inputRef.current.checked = value;

          if (onChangeValue) {
            onChangeValue(
              { valorAtual: value, input: inputRef.current },
              { valorAnterior: valorAnterior.current }
            );
          }
        }
      },

      setSemExecutarEvento(_, value = false) {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.checked;
          inputRef.current.checked = value;
        }
      },

      clearValue(_, valorInicial = false) {
        this.setValue(_, !!valorInicial);
      },

      validarSeAlterou(_, valorInicial = false) {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <input
          name={name}
          type="checkBox"
          data-tipo="checkBox"
          ref={inputRef}
          id={id}
          onChange={(event) => {
            if (onChange) {
              onChange(event, { valorAnterior: valorAnterior.current });
            }

            if (onChangeValue) {
              onChangeValue(
                {
                  valorAtual: event.target.checked,
                  input: event.currentTarget,
                },
                { valorAnterior: valorAnterior.current }
              );
            }
          }}
          {...rest}
        />
      </div>
      <div>
        {label && (
          <label style={{ width: '100%' }} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      {erro && <SpanErro style={{ marginLeft: '5px' }}>{erro}</SpanErro>}
    </>
  );
};

export default forwardRef(InputCheckbox);
