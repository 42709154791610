import {
  FormatarDataHoraParaPtBr,
  IOrdemServicoVeiculoFiltroRelatorioBackend,
  IOrdemServicoVeiculoFiltroRelatorioFrontend,
  IOrdemServicoVeiculoResumoCabecalhoRelatorio,
  IOrdemServicoVeiculoResumoRelatorio,
} from '@elogestor/util';
import React from 'react';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import PDF from '../../../../../../Util/Relatorios/PDF';
import OrdemServicoVeiculoResumoRelatorioComunicador from '../../../../../../Comunicador/Servicos/Relatorios/OrdemServicoVeiculo/OrdemServicoVeiculoResumoRelatorio/OrdemServicoVeiculoResumoRelatorioComunicador';
import OrdemServicoVeiculoResumoPlanilha from './OrdemServicoVeiculoResumoPlanilha';
import OrdemServicoVeiculoResumoPDF from './OrdemServicoVeiculoResumoPDF';

interface IRelatorios {
  handleExportCSV(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const OrdemServicoVeiculoResumoRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IOrdemServicoVeiculoResumoRelatorio | null> => {
    const filtroBack: IOrdemServicoVeiculoFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCliente) {
      filtroBack.listaIdCliente =
        filtros.listaCliente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaNumeroOrdemServico) {
      filtroBack.listaNumeroOrdemServico =
        filtros.listaNumeroOrdemServico.listaValor.map(
          (value) => value.textoTag
        ) || [];
    }

    const response = await OrdemServicoVeiculoResumoRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IOrdemServicoVeiculoResumoRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        return {
          ...dados,
          dataHoraEmissao: FormatarDataHoraParaPtBr(dados.dataHoraEmissao),
          valorSubtotal: Number(dados.valorSubtotal).FormatarParaPtBr(),
          valorTotalBruto: Number(dados.valorTotalBruto).FormatarParaPtBr(),
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      valorSubtotalSomatorio: Number(
        retorno.valorSubtotalSomatorio
      ).FormatarParaPtBr(),
      valorTotalBrutoSomatorio: Number(
        retorno.valorTotalBrutoSomatorio
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend,
    cabecalho: IOrdemServicoVeiculoResumoCabecalhoRelatorio
  ): IOrdemServicoVeiculoResumoCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCliente = filtros.listaCliente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaNumeroOrdemServico &&
      filtros.listaNumeroOrdemServico.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroNumeroOrdemServico =
        filtros.listaNumeroOrdemServico.listaValor
          .map((valor: any) => {
            return valor.textoTag;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: OrdemServicoVeiculoResumoPlanilha,
      nomeArquivo: 'OrdemServicoVeiculoResumo',
      valores: dados.listaDados as any,
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: OrdemServicoVeiculoResumoPlanilha,
      nomeArquivo: 'OrdemServicoVeiculoResumo',
      valores: dados.listaDados as any,
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemServicoVeiculoResumoPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IOrdemServicoVeiculoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemServicoVeiculoResumoPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default OrdemServicoVeiculoResumoRelatorio();
