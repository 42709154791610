import api from '../../../../../Comum/Services/Api';

const rota = 'fiscal/tributacao/regra-escolha-parametro/ordem';

interface IRegraEscolhaParametroOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaParametroOrdemComunicador {
  public async update(
    params: IRegraEscolhaParametroOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaParametroOrdemComunicador();
