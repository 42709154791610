"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ModeloDocumentoTipoEnum;
(function (ModeloDocumentoTipoEnum) {
    ModeloDocumentoTipoEnum["notaFiscal"] = "Nota Fiscal";
    ModeloDocumentoTipoEnum["conhecimentoTransporte"] = "Conhecimento de Transporte";
    ModeloDocumentoTipoEnum["cupomFiscal"] = "Cupom Fiscal";
    ModeloDocumentoTipoEnum["notaFiscalServico"] = "Nota Fiscal de Servi\u00E7o";
    ModeloDocumentoTipoEnum["manifestoTransporte"] = "Manifesto de Transporte";
})(ModeloDocumentoTipoEnum || (ModeloDocumentoTipoEnum = {}));
exports.default = ModeloDocumentoTipoEnum;
