import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/ramo-atividade-profissao/log';

interface IRamoAtividadeProfissaoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRamoAtividadeProfissaoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RamoAtividadeProfissaoLogComunicador {
  public async index(
    params: IRamoAtividadeProfissaoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRamoAtividadeProfissaoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RamoAtividadeProfissaoLogComunicador();
