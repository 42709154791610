import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmailVerificadoComunicador from '../../../../Comunicador/Configuracao/ContaEmail/Comunicador/EmailVerificadoComunicador';
import { Container, Image } from './styles';
import IParametroRotaDetalhe from '../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import logo from '../../../../Assets/logo.svg';

const VerificarEmail: React.FC = () => {
  const navigate = useNavigate();
  const params: IParametroRotaDetalhe = useParams();
  const [, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const emailVerificado = useCallback(async () => {
    try {
      setLoading(true);
      if (params.id) {
        await EmailVerificadoComunicador.update({
          id: String(params.id),
        });

        setMensagem('Seu e-mail foi verificado com sucesso!');
      }

      setLoading(false);
    } catch (error) {
      setMensagem('Ocorreu um erro ao verificar se e-mail!');
      setLoading(false);
    }
  }, [params.id]);

  useEffect(() => {
    emailVerificado();
  }, [emailVerificado]);

  return (
    <Container>
      <div className="body-email">
        <div>
          <Image src={logo} alt="" style={{ width: '100', height: '100' }} />
          <h3>{mensagem}</h3>
        </div>
        <div>
          <button
            type="button"
            className="btn-padrao btn-cinza-claro"
            onClick={() => {
              navigate(`/configuracao/conta-de-email/detalhe/${params.id}`);
            }}
          >
            <span style={{ marginLeft: 10, marginRight: 10 }}>
              Acessar a pagina inicial
            </span>
          </button>
        </div>
      </div>
    </Container>
  );
};

export default VerificarEmail;
