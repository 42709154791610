import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Table, Modal, Button, Container } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import { v4 } from 'uuid';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';

export default interface ISelecaoItemEstoqueLote {
  id: string;
  codigo: string;
  saldo: number;
  dataHoraSaldo: Date;
}

interface ISelecaoItemEstoqueLoteContext {
  abrirSelecaoItemEstoqueLote(
    listaLote: ISelecaoItemEstoqueLote[]
  ): Promise<{ lote: ISelecaoItemEstoqueLote }>;
  fecharSelecaoItemEstoqueLote(): void;
}

const SelecaoItemEstoqueLoteContext =
  createContext<ISelecaoItemEstoqueLoteContext>(
    {} as ISelecaoItemEstoqueLoteContext
  );

const SelecaoItemEstoqueLoteHook: React.FC<IPadraoProps> = ({ children }) => {
  const resolveRef = useRef<any>();

  const [selecionadoIndex, setSelecionadoIndex] = useState(0);

  const [aberto, setAberto] = useState(false);
  const [loading, setLoading] = useState(false);

  const [listaValor, setListaValor] = useState<ISelecaoItemEstoqueLote[]>([]);

  const handleSalvar = useCallback(() => {
    setAberto(false);

    if (resolveRef.current) {
      const itemSelecionado = listaValor[selecionadoIndex];
      resolveRef.current({ lote: itemSelecionado });
    }
  }, [listaValor, selecionadoIndex]);

  const handleCarregarDados = useCallback(
    async (listaLote: ISelecaoItemEstoqueLote[]): Promise<void> => {
      try {
        setLoading(true);

        setListaValor(listaLote);

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    []
  );

  const handleAbrir = useCallback(
    async (listaLote: ISelecaoItemEstoqueLote[]): Promise<any> => {
      await handleCarregarDados(listaLote);
      setSelecionadoIndex(0);
      setAberto(true);

      return new Promise<any>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [handleCarregarDados]
  );

  const handleFechar = useCallback(() => {
    setListaValor([]);
    setAberto(false);

    resolveRef.current(null);
  }, []);

  return (
    <SelecaoItemEstoqueLoteContext.Provider
      value={{
        abrirSelecaoItemEstoqueLote: handleAbrir,
        fecharSelecaoItemEstoqueLote: handleFechar,
      }}
    >
      <Modal show={aberto} onHide={handleFechar} size="lg">
        <LoadingDiv isLoading={loading} isToggleJanela />

        <Modal.Header
          style={{
            background: '#3397c4',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Modal.Title>Selecione o Local de Estoque</Modal.Title>
          <BtnCloseButton type="button" onClick={handleFechar}>
            <IoIosCloseCircleOutline />
          </BtnCloseButton>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: 500 }}>
          <Tabela style={{ overflow: 'auto', maxHeight: 500 }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>
                    <span />
                  </th>
                  <th className="lista-texto" style={{ width: '40%' }}>
                    <span>Código</span>
                  </th>
                  <th className="lista-valor" style={{ width: '30%' }}>
                    <span>Saldo</span>
                  </th>
                  <th className="lista-data" style={{ width: '25%' }}>
                    <span>Data</span>
                  </th>
                </tr>
              </thead>

              <TBody>
                {listaValor.map((lote, index) => {
                  return (
                    <tr
                      key={v4()}
                      onClick={(event) => {
                        const input =
                          event.currentTarget.querySelector('input');
                        input?.click();
                      }}
                    >
                      <td
                        className={`${
                          selecionadoIndex === index ? 'selecionado ' : ''
                        }lista-texto`}
                      >
                        <div>
                          <input
                            type="radio"
                            name="origem"
                            checked={index === selecionadoIndex}
                            onChange={() => {
                              setSelecionadoIndex(index);
                            }}
                          />
                        </div>
                      </td>

                      <td
                        className={`${
                          selecionadoIndex === index ? 'selecionado ' : ''
                        }lista-texto`}
                      >
                        <div>
                          <span>{lote.codigo}</span>
                        </div>
                      </td>

                      <td
                        className={`${
                          selecionadoIndex === index ? 'selecionado ' : ''
                        }lista-valor`}
                      >
                        <div>
                          <span>{Number(lote.saldo).FormatarParaPtBr()}</span>
                        </div>
                      </td>

                      <td
                        className={`${
                          selecionadoIndex === index ? 'selecionado ' : ''
                        }lista-data`}
                      >
                        <div>
                          <span>
                            {FormatarDataHoraCompletaParaPtBr(
                              lote.dataHoraSaldo
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </TBody>
            </Table>
          </Tabela>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleFechar}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 16,
                  }}
                >
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </span>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleSalvar}
              >
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </Modal>
      {children}
    </SelecaoItemEstoqueLoteContext.Provider>
  );
};

function UseSelecaoItemEstoqueLote(): ISelecaoItemEstoqueLoteContext {
  const context = useContext(SelecaoItemEstoqueLoteContext);

  if (!context) {
    throw new Error(
      'UseSelecaoItemEstoqueLote deve ser usado com um FrenteCaixaPdvProvider'
    );
  }

  return context;
}

export { SelecaoItemEstoqueLoteHook, UseSelecaoItemEstoqueLote };
