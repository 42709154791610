import React, { createContext, useCallback, useState, useContext } from 'react';
import Confirmacao from '..';
import IConfirmacaoState from '../../Interface/IConfirmacaoState';
import IConfirmacaoContextData from '../../Interface/IConfirmacaoContextData';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';

const confirmacaoContext = createContext<IConfirmacaoContextData>(
  {} as IConfirmacaoContextData
);

const ConfirmacaoProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [janela, setJanela] = useState<IConfirmacaoState>();

  const abrirJanela = useCallback(
    ({
      titulo,
      mensagem,
      cancelar,
      confimar,
      padraoSelecionadoSim = true,
    }: Omit<IConfirmacaoState, 'cb'>): Promise<boolean> => {
      let retorno: boolean;
      const p1 = new Promise((resolve) => {
        const cb = (resposta: boolean): void => {
          retorno = resposta;
          resolve(undefined);
        };

        setJanela({
          titulo,
          mensagem,
          cancelar,
          confimar,
          padraoSelecionadoSim,
          cb,
        });
      });

      return p1.then(() => {
        return retorno || false;
      });
    },
    []
  );

  const fecharJanela = useCallback(() => {
    setJanela(undefined);
  }, []);

  return (
    <confirmacaoContext.Provider value={{ abrirJanela, fecharJanela }}>
      {children}

      {janela && (
        <Confirmacao
          fecharJanela={() => {
            fecharJanela();
          }}
          confimar={janela.confimar}
          cancelar={janela.cancelar}
          titulo={janela.titulo}
          mensagem={janela.mensagem}
          padraoSelecionadoSim={janela.padraoSelecionadoSim}
          cb={janela.cb}
        />
      )}
    </confirmacaoContext.Provider>
  );
};

function UseConfirmacao(): IConfirmacaoContextData {
  const context = useContext(confirmacaoContext);

  if (!context) {
    throw new Error(
      'UseConfirmacao must be used within an ConfirmacaoProvider'
    );
  }

  return context;
}

export { ConfirmacaoProvider, UseConfirmacao };
