import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  ModeloImpressaoPesagemEnum,
  SituacaoPesagemEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoPesagemA4Avaliacao from './Impressao/Template/PesagemA4AvaliacaoImpressao';
import ImpressaoPesagemTicket80mm from './Impressao/Template/PesagemTicket80mmImpressao';
import PesagemA4AvaliacaoImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Impressao/PesagemA4AvaliacaoImpressao/PesagemA4AvaliacaoImpressaoComunicador';
import PesagemTicket80mmImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Pesagens/Pesagem/Impressao/PesagemTicket80mmImpressao/PesagemTicket80mmImpressaoComunicador';

const AcoesPesagem: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, loading, setLoading, getIdDetalheRegistro } = UseForm();
  const parametros = UseParametros();

  const idRegistroDetalhe = getIdDetalheRegistro();
  const situacaoPesagem = formRef.current?.getFieldValue('situacao');

  const handleValidar = useCallback(
    async (situacaoPesagemValidar?: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoPesagemValidar &&
          situacaoPesagemValidar !== SituacaoPesagemEnum.cancelada
        )
          return true;

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef]
  );

  const handleImprimir = useCallback(async () => {
    try {
      setLoading(true);

      switch (parametros.ModeloParaImpressaoPesagem) {
        case ModeloImpressaoPesagemEnum.a4Avaliacao:
          {
            const id = getIdDetalheRegistro();
            if (!id) return;

            const response = await PesagemA4AvaliacaoImpressaoComunicador.show({
              id,
            });
            if (await handleValidar(situacaoPesagem)) {
              await ImpressaoPesagemA4Avaliacao.handleVisualizarPdf(response);
            }
          }
          break;

        case ModeloImpressaoPesagemEnum.ticket80mm:
          {
            const id = getIdDetalheRegistro();
            if (!id) return;
            const response = await PesagemTicket80mmImpressaoComunicador.show({
              id,
            });
            if (await handleValidar(situacaoPesagem)) {
              await ImpressaoPesagemTicket80mm.handleVisualizarPdf(response);
            }
          }
          break;

        default:
          break;
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleValidar,
    parametros.ModeloParaImpressaoPesagem,
    setLoading,
    situacaoPesagem,
  ]);

  return (
    <SplitAcoes
      id={v4()}
      disabled={loading || !idRegistroDetalhe}
      className="btn-group"
      title={
        <div>
          <TextoLoading loading={loading}>
            <CgMoreO />
            {telaGrande && (
              <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
            )}
          </TextoLoading>
        </div>
      }
    >
      <Dropdown.Item disabled={loading} onClick={handleImprimir}>
        <span style={{ display: 'flex', fontSize: 16 }}>
          <AiOutlinePrinter style={{ marginRight: 5 }} />
          Imprimir
        </span>
      </Dropdown.Item>
    </SplitAcoes>
  );
};

export default AcoesPesagem;
