const CotacaoCompraTotalPorModeloDocumentoPlanilha = [
  { titulo: 'Modelo de Documento', valor: 'valores.chaveFormatada' },

  { titulo: 'Numero', valor: 'listaCotacaoCompraItem.cotacaoCompraNumero' },

  {
    titulo: 'Data de Emissão',
    valor: 'listaCotacaoCompraItem.cotacaoCompraDataHoraEmissao',
  },

  {
    titulo: 'Código do Fornecedor',
    valor: 'listaCotacaoCompraItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Fornecedor',
    valor: 'listaCotacaoCompraItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'CFOPS',
    valor: 'listaCotacaoCompraItem.cfops',
  },

  {
    titulo: 'Val Sub Total',
    valor: 'listaCotacaoCompraItem.somatorioValorSubtotal',
  },

  {
    titulo: 'Val Frete',
    valor: 'listaCotacaoCompraItem.somatorioValorFrete',
  },

  {
    titulo: 'Val Seguro',
    valor: 'listaCotacaoCompraItem.somatorioValorSeguro',
  },

  {
    titulo: 'Val Out Desp',
    valor: 'listaCotacaoCompraItem.somatorioValorOutrasDespesas',
  },

  {
    titulo: 'Val Desconto',
    valor: 'listaCotacaoCompraItem.somatorioValorDesconto',
  },

  {
    titulo: 'Valor Ipi',
    valor: 'listaCotacaoCompraItem.somatorioValorIpi',
  },

  {
    titulo: 'Valor St',
    valor: 'listaCotacaoCompraItem.somatorioValorSt',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaCotacaoCompraItem.somatorioValorTotal',
  },
];

export default CotacaoCompraTotalPorModeloDocumentoPlanilha;
