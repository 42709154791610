import api from '../../../../../Comum/Services/Api';

const rota = '/suprimentos/pesagens/pesagem';

interface IPesagemComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IPesagemComunicadorShow {
  id: string;
}

interface IPesagemComunicadorStore {
  params: any;
}

interface IPesagemComunicadorUpdate {
  id: string;
  params: any;
}

interface IPesagemComunicadorDeletar {
  id: string;
}

class PesagemComunicador {
  public async index(params: IPesagemComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IPesagemComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IPesagemComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IPesagemComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IPesagemComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PesagemComunicador();
