import { IImportacaoXmlConhecimentosEmitidosListaDadosImpressao } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/conhecimentos-emitidos/salvar-importado';

interface IImportacaoXmlConhecimentosEmitidosSalvarImportadoComunicadorStore {
  params: any;
}

class ImportacaoXmlConhecimentosEmitidosSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlConhecimentosEmitidosSalvarImportadoComunicadorStore
  ): Promise<{
    id: string;
    listaImpressao: IImportacaoXmlConhecimentosEmitidosListaDadosImpressao[];
    listaMensagemSucesso: string[];
    listaMensagemAviso: string[];
    listaMensagemErro: string[];
  }> {
    const { params: body } = params;

    const response = await api.post(rota, body);

    return response.data;
  }
}

export default new ImportacaoXmlConhecimentosEmitidosSalvarImportadoComunicador();
