import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import RotasHook from './RotasHook';

const ApiCertificadoHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <FormHook>{children}</FormHook>
    </RotasHook>
  );
};

export default ApiCertificadoHook;
