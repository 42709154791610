import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/unidade-medida-auto-complete';

interface IUnidadeMedidaAutoCompleteComunicadorIndex {
  params: any;
}

class UnidadeMedidaAutoCompleteComunicador {
  public async index(
    params: IUnidadeMedidaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new UnidadeMedidaAutoCompleteComunicador();
