import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/retirada-consignado/retirada-consignado-por-cliente';

interface IRetiradaConsignadoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class RetiradaConsignadoPorClienteRelatorioComunicador {
  public async index(
    params: IRetiradaConsignadoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new RetiradaConsignadoPorClienteRelatorioComunicador();
