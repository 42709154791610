/* eslint-disable no-continue */
import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarCep,
  FormatarChaveAcesso,
  FormatarCnpjCpf,
  FormatarDataHoraCompletaParaPtBr,
  FormatarDataParaPtBr,
  FormatarTelefone,
  IConhecimentoTransporteDocumentoImpressao,
  IConhecimentoTransporteImpressao,
  IConhecimentoTransporteServicoImpressao,
  SituacaoConhecimentoTransporteEnum,
} from '@elogestor/util';
import ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF from './ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF';

const Document: any = _document;
const Page: any = _page;

export interface IConhecimentoTransporteImpressaoModalAereoPDF {
  dados: IConhecimentoTransporteImpressao;
}

function ObterListaServico(dados: IConhecimentoTransporteImpressao): {
  listaServico1: IConhecimentoTransporteServicoImpressao[];
  listaServico2: IConhecimentoTransporteServicoImpressao[];
  listaServico3: IConhecimentoTransporteServicoImpressao[];
} {
  const quantidadeServicoLista = 4;

  const listaServico1 = dados.listaServico.slice(0, quantidadeServicoLista);
  const listaServico2 = dados.listaServico.slice(
    quantidadeServicoLista,
    quantidadeServicoLista * 2
  );
  const listaServico3 = dados.listaServico.slice(
    quantidadeServicoLista * 2,
    quantidadeServicoLista * 3
  );

  return { listaServico1, listaServico2, listaServico3 };
}

function ObterListaDocumento(dados: IConhecimentoTransporteImpressao): {
  listaDocumento1: any[];
  listaDocumento2: any[];
} {
  let listaDocumentos = dados.listaDocumento;

  const quantidadePrimeiraPagina = 5;
  const quantidadeOutraPagina = 67;

  const listaDocumento1: any[] = [];
  const listaDocumento2: any[] = [];

  listaDocumento1.push(listaDocumentos.slice(0, quantidadePrimeiraPagina));
  listaDocumento2.push(
    listaDocumentos.slice(
      quantidadePrimeiraPagina,
      quantidadePrimeiraPagina * 2
    )
  );

  listaDocumentos = listaDocumentos.slice(quantidadePrimeiraPagina * 2);

  let possuiDocumentos = true;
  while (possuiDocumentos) {
    if (listaDocumentos.length === 0) {
      possuiDocumentos = false;
      continue;
    }

    if (listaDocumentos.length > 0) {
      listaDocumento1.push(listaDocumentos.slice(0, quantidadeOutraPagina));
      listaDocumento2.push(
        listaDocumentos.slice(quantidadeOutraPagina, quantidadeOutraPagina * 2)
      );
    }

    listaDocumentos = listaDocumentos.slice(quantidadeOutraPagina * 2);
  }

  return { listaDocumento1, listaDocumento2 };
}

function ObterListaObservacoes(observacoes?: string): string[] {
  if (!observacoes) return [];

  const tamanhoObservacoes = 690;
  const quantidadePaginas = Math.ceil(observacoes.length / tamanhoObservacoes);

  const listaPagina: string[] = [];
  let inicio = 0;

  for (let i = 0; i < quantidadePaginas; i++) {
    const fim = inicio + tamanhoObservacoes;

    const dados = observacoes.slice(inicio, fim);
    listaPagina.push(dados);

    inicio = fim;
  }

  return listaPagina;
}

function ObterListaUsoExclusivoEmissor(usoExclusivoEmissor?: string): string[] {
  if (!usoExclusivoEmissor) return [];

  const tamanhoUsoExclusivoEmissor = 470;
  const quantidadePaginas = Math.ceil(
    usoExclusivoEmissor.length / tamanhoUsoExclusivoEmissor
  );

  const listaPagina: string[] = [];
  let inicio = 0;

  for (let i = 0; i < quantidadePaginas; i++) {
    const fim = inicio + tamanhoUsoExclusivoEmissor;

    const dados = usoExclusivoEmissor.slice(inicio, fim);
    listaPagina.push(dados);

    inicio = fim;
  }

  return listaPagina;
}

function ObterListaUsoExclusivoFisco(usoExclusivoFisco?: string): string[] {
  if (!usoExclusivoFisco) return [];

  const tamanhoUsoExclusivoFisco = 185;
  const quantidadePaginas = Math.ceil(
    usoExclusivoFisco.length / tamanhoUsoExclusivoFisco
  );

  const listaPagina: string[] = [];
  let inicio = 0;

  for (let i = 0; i < quantidadePaginas; i++) {
    const fim = inicio + tamanhoUsoExclusivoFisco;

    const dados = usoExclusivoFisco.slice(inicio, fim);
    listaPagina.push(dados);

    inicio = fim;
  }

  return listaPagina;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerAssinatura: {
    flexDirection: 'row',
    height: 65,
    fontSize: 5,
    fontWeight: 'bold',
    justifyContent: 'center',
  },
});

const ConhecimentoTransporteImpressaoModalAereoPDF: React.FC<
  IConhecimentoTransporteImpressaoModalAereoPDF
> = ({ dados }) => {
  const { listaServico1, listaServico2, listaServico3 } =
    ObterListaServico(dados);
  const { listaDocumento1, listaDocumento2 } = ObterListaDocumento(dados);

  const listaDocumentoPimeiraPagina1 = listaDocumento1[0];
  const listaDocumentoPimeiraPagina2 = listaDocumento2[0];

  const listaDocumentoRestante1 = listaDocumento1.slice(1);
  const listaDocumentoRestante2 = listaDocumento2.slice(1);

  const listaObservacoes = ObterListaObservacoes(dados.observacoes);

  const observacoesPimeiraPagina = listaObservacoes[0];
  const listaObservacoesRestante = listaObservacoes.slice(1);

  const listaUsoExclusivoEmissor = ObterListaUsoExclusivoEmissor(
    dados.usoExclusivoEmissor
  );

  const listaUsoExclusivoFisco = ObterListaUsoExclusivoFisco(
    dados.usoExclusivoFisco
  );

  const usoExclusivoEmissorPimeiraPagina = listaUsoExclusivoEmissor[0];
  const listaUsoExclusivoEmissorRestante = listaUsoExclusivoEmissor.slice(1);

  const usoExclusivoFiscoPimeiraPagina = listaUsoExclusivoFisco[0];
  const listaUsoExclusivoFiscoRestante = listaUsoExclusivoFisco.slice(1);

  const quantidadePaginas = Math.max(
    listaDocumentoRestante1.length,
    listaObservacoesRestante.length,
    listaUsoExclusivoEmissorRestante.length,
    listaUsoExclusivoFiscoRestante.length
  );

  const listaPagina = Array.from({ length: quantidadePaginas }, (_, k) => k);

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerAssinatura}>
          <View style={{ width: '100%', flexDirection: 'column' }}>
            <View
              style={{
                height: '15%',
                justifyContent: 'center',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ marginLeft: '5px', alignItems: 'center' }}>
                <Text>
                  DECLARO QUE RECEBEMOS OS VOLUMES DESTE CONHECIMENTO EM
                  PERFEITO ESTADO PELO QUE DOU POR CUMPRIDO O PRESENTE CONTATO
                  DE TRANSPORTE
                </Text>
              </View>
            </View>

            <View
              style={{
                height: '25%',
                justifyContent: 'center',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View style={{ alignItems: 'center', textAlign: 'center' }}>
                <Text style={{ marginLeft: '20px', marginRight: '20px' }}>
                  O transporte coberto por este conhecimento de transporte
                  rege-se pelo Código Brasileiro de Aeronáutica - Lei nº 7.565
                  de 19/12/1986 - e pelas regulamentações da ANAC, de cujo teor
                  o expedidor/remetente declara ter plena ciência. O
                  expedidor/remetente aceita como corretas todas as
                  expecificações contidas neste documento, certificando que a
                  carga, incluindo o artigo perigoso, foi devidamente declarada
                  e acondicionada, para o transporte aéreo.
                </Text>
              </View>
            </View>

            <View style={{ height: '60%' }}>
              <View style={{ flexDirection: 'row', height: '100%' }}>
                <View
                  style={{
                    width: '25%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ flexDirection: 'column' }}>
                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>EXPEDIDOR / REMETENTE</Text>
                      </View>
                    </View>

                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>DESTINATARIO / RECEBEDOR</Text>
                      </View>
                    </View>

                    <View style={{ height: '36%' }}>
                      <View style={{ marginLeft: '5px' }}>
                        <Text>NÚMERO OPERACIONAL</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 6,
                          marginLeft: '5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Text>
                          {dados.aereo?.numeroOperacionalConhecimentoAereo}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: '50%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ flexDirection: 'column' }}>
                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>NOME</Text>
                      </View>
                    </View>

                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>DATA / HORA</Text>
                      </View>
                    </View>

                    <View style={{ height: '36%' }}>
                      <View style={{ marginLeft: '5px' }}>
                        <Text>CHAVE ACESSO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 6,
                          marginLeft: '5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Text>{FormatarChaveAcesso(dados.chaveAcesso)}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: '25%',
                    border: '1 solid black',
                    borderRadius: 5,
                  }}
                >
                  <View style={{ flexDirection: 'column' }}>
                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>ASSINATURA</Text>
                      </View>
                    </View>

                    <View
                      style={{ height: '32%', borderBottom: '1 solid black' }}
                    >
                      <View style={{ marginLeft: '5px' }}>
                        <Text>RG</Text>
                      </View>
                    </View>

                    <View style={{ height: '36%' }}>
                      <View style={{ marginLeft: '5px' }}>
                        <Text>DATA E HORA EMISSÃO</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          fontSize: 6,
                          marginLeft: '5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Text>
                          {FormatarDataHoraCompletaParaPtBr(
                            dados.dataHoraEmissao
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            border: '1 dotted black',
          }}
        />

        <ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF dados={dados} />

        <View style={{ flexDirection: 'row' }}>
          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>ORIGEM DA PRESTAÇÃO</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginLeft: '5px',
                justifyContent: 'center',
              }}
            >
              <Text>{`${dados.origemPrestacao.cidade.nome} - ${dados.origemPrestacao.cidade.estado.sigla}`}</Text>
            </View>
          </View>

          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>DESTINO DA PRESTAÇÃO</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginLeft: '5px',
                justifyContent: 'center',
              }}
            >
              <Text>{`${dados.destinoPrestacao.cidade.nome} - ${dados.destinoPrestacao.cidade.estado.sigla}`}</Text>
            </View>
          </View>
        </View>

        <View style={{ height: 75, flexDirection: 'row' }}>
          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>REMETENTE:</Text>
              </View>

              <View style={{ width: '86%', fontSize: 9 }}>
                <Text>{dados.remetente?.nomeRazaoSocial}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>ENDEREÇO:</Text>
              </View>

              <View style={{ width: '86%', fontSize: 9 }}>
                <Text>
                  {dados.remetente?.remetenteEndereco.logradouro}
                  {dados.remetente?.remetenteEndereco.numero &&
                    `, ${dados.remetente?.remetenteEndereco.numero}`}
                  {dados.remetente?.remetenteEndereco.complemento &&
                    `, ${dados.remetente?.remetenteEndereco.complemento}`}
                  {dados.remetente?.remetenteEndereco.bairro &&
                    `, ${dados.remetente?.remetenteEndereco.bairro}`}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>MUNICÍPIO:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>
                  {dados.remetente &&
                    `${dados.remetente?.remetenteEndereco.cidade.nome} - ${dados.remetente?.remetenteEndereco.cidade.estado.sigla}`}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>CEP:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>
                  {FormatarCep(dados.remetente?.remetenteEndereco.cep)}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>CNPJ / CPF:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>{FormatarCnpjCpf(dados.remetente?.cnpjCpf)}</Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>IE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{dados.remetente?.inscricaoEstadual}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>PAIS:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>
                  {dados.remetente?.remetenteEndereco.cidade.estado.pais?.nome}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>FONE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{FormatarTelefone(dados.remetente?.fone)}</Text>
              </View>
            </View>
          </View>

          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>DESTINATARIO:</Text>
              </View>

              <View style={{ width: '83%', fontSize: 9 }}>
                <Text>{dados.destinatario?.nomeRazaoSocial}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>ENDEREÇO:</Text>
              </View>

              <View style={{ width: '83%', fontSize: 9 }}>
                <Text>
                  {dados.destinatario?.destinatarioEndereco.logradouro}
                  {dados.destinatario?.destinatarioEndereco.numero &&
                    `, ${dados.destinatario?.destinatarioEndereco.numero}`}
                  {dados.destinatario?.destinatarioEndereco.complemento &&
                    `, ${dados.destinatario?.destinatarioEndereco.complemento}`}
                  {dados.destinatario?.destinatarioEndereco.bairro &&
                    `, ${dados.destinatario?.destinatarioEndereco.bairro}`}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>MUNICÍPIO:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>
                  {dados.destinatario &&
                    `${dados.destinatario?.destinatarioEndereco.cidade.nome} - ${dados.destinatario?.destinatarioEndereco.cidade.estado.sigla}`}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>CEP:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>
                  {FormatarCep(dados.remetente?.remetenteEndereco.cep)}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>CNPJ / CPF:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>{FormatarCnpjCpf(dados.destinatario?.cnpjCpf)}</Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>IE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{dados.destinatario?.inscricaoEstadual}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>PAIS:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>
                  {
                    dados.destinatario?.destinatarioEndereco.cidade.estado.pais
                      ?.nome
                  }
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>FONE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{FormatarTelefone(dados.destinatario?.fone)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ height: 75, flexDirection: 'row' }}>
          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>EXPEDIDOR:</Text>
              </View>

              <View style={{ width: '86%', fontSize: 9 }}>
                <Text>{dados.expedidor?.nomeRazaoSocial}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>ENDEREÇO:</Text>
              </View>

              <View style={{ width: '86%', fontSize: 9 }}>
                <Text>
                  {dados.expedidor?.expedidorEndereco.logradouro}
                  {dados.expedidor?.expedidorEndereco.numero &&
                    `, ${dados.expedidor?.expedidorEndereco.numero}`}
                  {dados.expedidor?.expedidorEndereco.complemento &&
                    `, ${dados.expedidor?.expedidorEndereco.complemento}`}
                  {dados.expedidor?.expedidorEndereco.bairro &&
                    `, ${dados.expedidor?.expedidorEndereco.bairro}`}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>MUNICÍPIO:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>
                  {dados.expedidor &&
                    `${dados.expedidor?.expedidorEndereco.cidade.nome} - ${dados.expedidor?.expedidorEndereco.cidade.estado.sigla}`}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>CEP:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>
                  {FormatarCep(dados.expedidor?.expedidorEndereco.cep)}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>CNPJ / CPF:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>{FormatarCnpjCpf(dados.expedidor?.cnpjCpf)}</Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>IE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{dados.expedidor?.inscricaoEstadual}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '14%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>PAIS:</Text>
              </View>

              <View style={{ width: '50%', fontSize: 9 }}>
                <Text>
                  {dados.expedidor?.expedidorEndereco.cidade.estado.pais?.nome}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>FONE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{FormatarTelefone(dados.expedidor?.fone)}</Text>
              </View>
            </View>
          </View>

          <View
            style={{ width: '50%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>RECEBEDOR:</Text>
              </View>

              <View style={{ width: '83%', fontSize: 9 }}>
                <Text>{dados.recebedor?.nomeRazaoSocial}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>ENDEREÇO:</Text>
              </View>

              <View style={{ width: '83%', fontSize: 9 }}>
                <Text>
                  {dados.recebedor?.recebedorEndereco.logradouro}
                  {dados.recebedor?.recebedorEndereco.numero &&
                    `, ${dados.recebedor?.recebedorEndereco.numero}`}
                  {dados.recebedor?.recebedorEndereco.complemento &&
                    `, ${dados.recebedor?.recebedorEndereco.complemento}`}
                  {dados.recebedor?.recebedorEndereco.bairro &&
                    `, ${dados.recebedor?.recebedorEndereco.bairro}`}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>MUNICÍPIO:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>
                  {dados.recebedor &&
                    `${dados.recebedor?.recebedorEndereco.cidade.nome} - ${dados.recebedor?.recebedorEndereco.cidade.estado.sigla}`}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>CEP:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>
                  {FormatarCep(dados.recebedor?.recebedorEndereco.cep)}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>CNPJ / CPF:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>{FormatarCnpjCpf(dados.recebedor?.cnpjCpf)}</Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>IE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{dados.recebedor?.inscricaoEstadual}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '17%',
                  marginLeft: '5px',
                  marginTop: '2px',
                  fontSize: 6,
                }}
              >
                <Text>PAIS:</Text>
              </View>

              <View style={{ width: '47%', fontSize: 9 }}>
                <Text>
                  {dados.recebedor?.recebedorEndereco.cidade.estado.pais?.nome}
                </Text>
              </View>

              <View
                style={{
                  width: '7%',
                  fontSize: 6,
                  marginTop: '2px',
                  alignItems: 'flex-end',
                }}
              >
                <Text>FONE:</Text>
              </View>

              <View style={{ width: '29%', marginLeft: '5px', fontSize: 9 }}>
                <Text>{FormatarTelefone(dados.recebedor?.fone)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ height: 45, border: '1 solid black', borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '7%',
                marginLeft: '5px',
                marginTop: '2px',
                fontSize: 6,
              }}
            >
              <Text>TOMADOR:</Text>
            </View>

            <View style={{ width: '93%', fontSize: 9 }}>
              <Text>{dados.tomador?.nomeRazaoSocial}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '7%',
                marginLeft: '5px',
                marginTop: '2px',
                fontSize: 6,
              }}
            >
              <Text>ENDEREÇO:</Text>
            </View>

            <View style={{ width: '46%', fontSize: 9 }}>
              <Text>
                {dados.tomador?.tomadorEndereco.logradouro}
                {dados.tomador?.tomadorEndereco.numero &&
                  `, ${dados.tomador?.tomadorEndereco.numero}`}
                {dados.tomador?.tomadorEndereco.complemento &&
                  `, ${dados.tomador?.tomadorEndereco.complemento}`}
                {dados.tomador?.tomadorEndereco.bairro &&
                  `, ${dados.tomador?.tomadorEndereco.bairro}`}
              </Text>
            </View>

            <View
              style={{
                width: '7%',
                marginTop: '2px',
                fontSize: 6,
                alignItems: 'flex-end',
              }}
            >
              <Text>MUNICÍPIO:</Text>
            </View>

            <View style={{ width: '20%', fontSize: 9, marginLeft: '5px' }}>
              <Text>
                {dados.tomador &&
                  `${dados.tomador?.tomadorEndereco.cidade.nome} - ${dados.tomador?.tomadorEndereco.cidade.estado.sigla}`}
              </Text>
            </View>

            <View
              style={{
                width: '5%',
                fontSize: 6,
                marginTop: '2px',
                alignItems: 'flex-end',
              }}
            >
              <Text>CEP:</Text>
            </View>

            <View style={{ width: '15%', fontSize: 9, marginLeft: '5px' }}>
              <Text>{FormatarCep(dados.tomador?.tomadorEndereco.cep)}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '7%',
                marginLeft: '5px',
                marginTop: '2px',
                fontSize: 6,
              }}
            >
              <Text>CNPJ / CPF:</Text>
            </View>

            <View style={{ width: '25%', fontSize: 9 }}>
              <Text>{FormatarCnpjCpf(dados.tomador?.cnpjCpf)}</Text>
            </View>

            <View
              style={{
                width: '3%',
                fontSize: 6,
                marginTop: '2px',
                alignItems: 'flex-end',
              }}
            >
              <Text>IE:</Text>
            </View>

            <View style={{ width: '17%', marginLeft: '5px', fontSize: 9 }}>
              <Text>{dados.tomador?.inscricaoEstadual}</Text>
            </View>

            <View
              style={{
                width: '7%',
                marginTop: '2px',
                alignItems: 'flex-end',
                fontSize: 6,
              }}
            >
              <Text>PAIS:</Text>
            </View>

            <View style={{ width: '18%', fontSize: 9, marginLeft: '5px' }}>
              <Text>
                {dados.tomador?.tomadorEndereco.cidade.estado.pais?.nome}
              </Text>
            </View>

            <View
              style={{
                width: '7%',
                fontSize: 6,
                marginTop: '2px',
                alignItems: 'flex-end',
              }}
            >
              <Text>FONE:</Text>
            </View>

            <View style={{ width: '13%', marginLeft: '5px', fontSize: 9 }}>
              <Text>{FormatarTelefone(dados.tomador?.fone)}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View style={{ width: '33%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>PRODUTO PREDOMINANTE</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginLeft: '5px',
                justifyContent: 'center',
              }}
            >
              <Text>{dados.produtoPredominante}</Text>
            </View>
          </View>

          <View style={{ width: '34%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>OUTRAS CARACTERÍSTICAS DA CARGA</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginLeft: '5px',
                justifyContent: 'center',
              }}
            >
              <Text>{dados.outrasCaracteristicasCarga}</Text>
            </View>
          </View>

          <View style={{ width: '33%' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>VALOR TOTAL DA MERCADORIA</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 9,
                marginRight: '5px',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Text>
                {Number(dados.valorTotalMercadorio).FormatarParaPtBr()}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[0] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[0].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[0].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[1] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[1].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[1].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[2] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[2].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[2].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[3] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[3].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[3].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[4] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[4].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[4].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[5] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[5].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[5].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%', borderRight: '1 solid black' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[6] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[6].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[6].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>

          <View style={{ width: '12.5%' }}>
            <View style={{ marginLeft: '5px', fontSize: 6 }}>
              <Text>QTDE. / UN. MEDIDA</Text>
            </View>

            {dados.listaCarga[7] && (
              <View
                style={{
                  flex: 1,
                  fontSize: 9,
                  marginRight: '5px',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Text>
                  {Number(dados.listaCarga[7].quantidade).FormatarParaPtBr()}
                </Text>
                <Text>{dados.listaCarga[7].unidadeMedida.sigla}</Text>
              </View>
            )}
          </View>
        </View>

        <View
          style={{
            height: 52,
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{
              height: '20%',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
              fontSize: 6,
            }}
          >
            <Text>COMPONENTES DO VALOR DA PRESTAÇÃO DO SERVIÇO</Text>
          </View>

          <View style={{ flexDirection: 'row', height: '80%' }}>
            <View style={{ width: '26.5%', borderRight: '1 solid black' }}>
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 6,
                  fontWeight: 'bold',
                }}
              >
                <View style={{ width: '60%', marginLeft: '5px' }}>
                  <Text>NOME</Text>
                </View>

                <View style={{ width: '40%', marginLeft: '5px' }}>
                  <Text>VALOR</Text>
                </View>
              </View>

              {listaServico1.map((dado) => {
                return (
                  <View style={{ flexDirection: 'row', fontSize: 7 }}>
                    <View style={{ width: '60%' }}>
                      <Text style={{ marginLeft: '5px' }}>{dado.nome}</Text>
                    </View>

                    <View style={{ width: '40%', alignItems: 'flex-end' }}>
                      <Text style={{ marginRight: '5px' }}>
                        {Number(dado.valor).FormatarParaPtBr()}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>

            <View style={{ width: '27%', borderRight: '1 solid black' }}>
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 6,
                  fontWeight: 'bold',
                }}
              >
                <View style={{ width: '60%', marginLeft: '5px' }}>
                  <Text>NOME</Text>
                </View>

                <View style={{ width: '40%', marginLeft: '5px' }}>
                  <Text>VALOR</Text>
                </View>
              </View>

              {listaServico2.map((dado) => {
                return (
                  <View style={{ flexDirection: 'row', fontSize: 7 }}>
                    <View style={{ width: '60%' }}>
                      <Text style={{ marginLeft: '5px' }}>{dado.nome}</Text>
                    </View>

                    <View style={{ width: '40%', alignItems: 'flex-end' }}>
                      <Text style={{ marginRight: '5px' }}>
                        {Number(dado.valor).FormatarParaPtBr()}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>

            <View style={{ width: '26.5%', borderRight: '1 solid black' }}>
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 6,
                  fontWeight: 'bold',
                }}
              >
                <View style={{ width: '60%', marginLeft: '5px' }}>
                  <Text>NOME</Text>
                </View>

                <View style={{ width: '40%', marginLeft: '5px' }}>
                  <Text>VALOR</Text>
                </View>
              </View>

              {listaServico3.map((dado) => {
                return (
                  <View style={{ flexDirection: 'row', fontSize: 7 }}>
                    <View style={{ width: '60%' }}>
                      <Text style={{ marginLeft: '5px' }}>{dado.nome}</Text>
                    </View>

                    <View style={{ width: '40%', alignItems: 'flex-end' }}>
                      <Text style={{ marginRight: '5px' }}>
                        {Number(dado.valor).FormatarParaPtBr()}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>

            <View style={{ width: '20%' }}>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '50%', borderBottom: '1 solid black' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR TOTAL DO SERVIÇO</Text>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text>
                      {Number(dados.valorTotalServico).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>

                <View style={{ height: '50%' }}>
                  <View style={{ marginLeft: '5px', fontSize: 6 }}>
                    <Text>VALOR A RECEBER</Text>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      fontSize: 9,
                      marginRight: '5px',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text>
                      {Number(dados.valorReceberServico).FormatarParaPtBr()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
            height: 30,
          }}
        >
          <View
            style={{
              height: '30%',
              fontSize: 6,
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
            }}
          >
            <Text>INFORMAÇÕES RELATIVAS AO IMPOSTO</Text>
          </View>

          <View style={{ height: '70%' }}>
            <View style={{ flexDirection: 'row', height: '100%' }}>
              <View style={{ width: '50%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>SITUAÇÃO TRIBUTÁRIA</Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    marginLeft: '5px',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.imposto.situacaoTributaria}</Text>
                </View>
              </View>

              <View style={{ width: '13%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>BASE DE CÁLCULO</Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    marginRight: '5px',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>
                    {dados.imposto.baseCalculo &&
                      Number(dados.imposto.baseCalculo).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>

              <View style={{ width: '11%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>ALIQ. ICMS</Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    marginRight: '5px',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>
                    {dados.imposto.aliquotaIcms &&
                      Number(dados.imposto.aliquotaIcms).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>

              <View style={{ width: '13%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>VALOR ICMS</Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    marginRight: '5px',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>
                    {dados.imposto.valorIcms &&
                      Number(dados.imposto.valorIcms).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>

              <View style={{ width: '13%' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>% RED. BC.</Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    fontSize: 9,
                    marginRight: '5px',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text>
                    {dados.imposto.percentualReducaoBaseCalculo &&
                      Number(
                        dados.imposto.percentualReducaoBaseCalculo
                      ).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            height: 60,
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{
              height: '14%',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
              fontSize: 6,
            }}
          >
            <Text>DOCUMENTOS ORIGINÁRIOS</Text>
          </View>

          <View style={{ flexDirection: 'row', height: '86%' }}>
            <View style={{ width: '50%', borderRight: '1 solid black' }}>
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 6,
                  fontWeight: 'bold',
                }}
              >
                <View style={{ width: '13%', marginLeft: '5px' }}>
                  <Text>TIPO DOC.</Text>
                </View>

                <View style={{ width: '57%', marginLeft: '5px' }}>
                  <Text>CHAVE DE ACESSO</Text>
                </View>

                <View style={{ width: '25%', marginLeft: '5px' }}>
                  <Text>SÉRIE / NÚMERO</Text>
                </View>
              </View>

              {listaDocumentoPimeiraPagina1 &&
                listaDocumentoPimeiraPagina1.map(
                  (dado: IConhecimentoTransporteDocumentoImpressao) => {
                    return (
                      <View style={{ flexDirection: 'row', fontSize: 7 }}>
                        <View style={{ width: '13%', marginLeft: '5px' }}>
                          <Text>{dado.tipoDocumento}</Text>
                        </View>

                        <View style={{ width: '57%', marginLeft: '5px' }}>
                          <Text>{dado.chaveAcesso}</Text>
                        </View>

                        <View style={{ width: '25%', marginLeft: '5px' }}>
                          <Text>
                            {`${dado.serie.padStart(
                              3,
                              '0'
                            )} / ${dado.numero.padStart(9, '0')}`}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                )}
            </View>

            <View style={{ width: '50%' }}>
              <View
                style={{
                  flexDirection: 'row',
                  fontSize: 6,
                  fontWeight: 'bold',
                }}
              >
                <View style={{ width: '13%', marginLeft: '5px' }}>
                  <Text>TIPO DOC.</Text>
                </View>

                <View style={{ width: '57%', marginLeft: '5px' }}>
                  <Text>CHAVE DE ACESSO</Text>
                </View>

                <View style={{ width: '25%', marginLeft: '5px' }}>
                  <Text>SÉRIE / NÚMERO</Text>
                </View>
              </View>

              {listaDocumentoPimeiraPagina2 &&
                listaDocumentoPimeiraPagina2.map(
                  (dado: IConhecimentoTransporteDocumentoImpressao) => {
                    return (
                      <View style={{ flexDirection: 'row', fontSize: 7 }}>
                        <View style={{ width: '13%', marginLeft: '5px' }}>
                          <Text>{dado.tipoDocumento}</Text>
                        </View>

                        <View style={{ width: '57%', marginLeft: '5px' }}>
                          <Text>{dado.chaveAcesso}</Text>
                        </View>

                        <View style={{ width: '25%', marginLeft: '5px' }}>
                          <Text>
                            {`${dado.serie.padStart(
                              3,
                              '0'
                            )} / ${dado.numero.padStart(9, '0')}`}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                )}
            </View>
          </View>
        </View>

        <View
          style={{
            height: 40,
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{
              height: '25%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
              fontSize: 6,
            }}
          >
            <Text>OBSERVAÇÕES</Text>
          </View>

          <View
            style={{
              height: '80%',
              fontSize: 8,
              marginLeft: '5px',
              marginRight: '5px',
              textAlign: 'justify',
            }}
          >
            <Text>{observacoesPimeiraPagina}</Text>
          </View>
        </View>

        <View
          style={{
            height: 65,
            flexDirection: 'column',
            border: '1 solid black',
            borderRadius: 5,
          }}
        >
          <View
            style={{
              height: '15%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1 solid black',
              fontSize: 6,
            }}
          >
            <Text>INFORMAÇÕES ESPECÍFICAS DO MODAL AÉREO</Text>
          </View>

          <View style={{ height: '85%', flexDirection: 'column' }}>
            <View
              style={{
                height: '33%',
                borderBottom: '1 solid black',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '25%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>NÚMERO OPERACIONAL DO CT-e</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.numeroOperacionalConhecimentoAereo}</Text>
                </View>
              </View>

              <View style={{ width: '25%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>CLASSE</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.classe}</Text>
                </View>
              </View>

              <View style={{ width: '25%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>CÓDIGO DA TARIFA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.codigoTarifa}</Text>
                </View>
              </View>

              <View style={{ width: '25%' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>VALOR DA TARIFA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    marginRight: '5px',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.valorTarifa}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                height: '34%',
                borderBottom: '1 solid black',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '15%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>NÚMERO DA MINUTA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.numeroMinuta}</Text>
                </View>
              </View>

              <View style={{ width: '15%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>RETIRADA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <Text>{dados.aereo?.retira}</Text>
                </View>
              </View>

              <View style={{ width: '70%' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>DADOS RELATIVOS A RETIRADA DA CARGA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.dadosRelativosRetiradaCarga}</Text>
                </View>
              </View>
            </View>

            <View style={{ height: '33%', flexDirection: 'row' }}>
              <View style={{ width: '21%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>CARAC. ADICIONAIS DO SERVIÇO</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.característicaAdicionalServiço}</Text>
                </View>
              </View>

              <View style={{ width: '21%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>DATA PREVISTA DA ENTREGA</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>
                    {FormatarDataParaPtBr(dados.aereo?.dataPrevistaEntrega)}
                  </Text>
                </View>
              </View>

              <View style={{ width: '21%', borderRight: '1 solid black' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>DIMENSÃO (NATUREZA CARGA)</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.dimensao}</Text>
                </View>
              </View>

              <View style={{ width: '37%' }}>
                <View style={{ marginLeft: '5px', fontSize: 6 }}>
                  <Text>INFORMAÇÕES DE MANUSEIO (NATUREZA CARGA)</Text>
                </View>

                <View
                  style={{
                    fontSize: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text>{dados.aereo?.informacoesManuseio?.toString()}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ height: 35, flexDirection: 'row' }}>
          <View
            style={{ width: '70%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'column', height: '100%' }}>
              <View
                style={{
                  height: '30%',
                  width: '100%',
                  borderBottom: '1 solid black',
                  fontSize: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>USO EXCLUSIVO DO EMISSOR CT-E</Text>
              </View>

              <View
                style={{
                  height: '70%',
                  fontSize: 6,
                  marginLeft: '5px',
                  marginRight: '5px',
                  textAlign: 'justify',
                }}
              >
                <Text>{usoExclusivoEmissorPimeiraPagina}</Text>
              </View>
            </View>
          </View>

          <View
            style={{ width: '30%', border: '1 solid black', borderRadius: 5 }}
          >
            <View style={{ flexDirection: 'column', height: '100%' }}>
              <View
                style={{
                  height: '30%',
                  width: '100%',
                  borderBottom: '1 solid black',
                  fontSize: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>RESERVADO AO FISCO</Text>
              </View>

              <View
                style={{
                  height: '70%',
                  fontSize: 6,
                  marginLeft: '5px',
                  marginRight: '5px',
                  textAlign: 'justify',
                }}
              >
                <Text>{usoExclusivoFiscoPimeiraPagina}</Text>
              </View>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            fontSize: 9,
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text>https://www.elogestor.com.br</Text>
        </View>

        {(dados.situacao ===
          SituacaoConhecimentoTransporteEnum.naoTransmitido ||
          dados.situacao === SituacaoConhecimentoTransporteEnum.rejeitado ||
          dados.situacao ===
            SituacaoConhecimentoTransporteEnum.emProcessamento) && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.3,
              }}
            >
              <Text>VISUALIZAÇÃO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
            </View>
          </View>
        )}

        {dados.situacao === SituacaoConhecimentoTransporteEnum.denegado && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.3,
              }}
            >
              <Text>USO DENEGADO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
            </View>
          </View>
        )}

        {dados.situacao === SituacaoConhecimentoTransporteEnum.cancelado && (
          <View
            fixed
            style={{
              position: 'absolute',
              transform: 'rotate(-45deg)',
            }}
          >
            <View
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                opacity: 0.3,
              }}
            >
              <Text>CANCELADO</Text>
              <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
            </View>
          </View>
        )}
      </Page>

      {listaPagina.map((index) => {
        const listaDocumentoA = listaDocumentoRestante1[index];
        const listaDocumentoB = listaDocumentoRestante2[index];
        const observacoes = listaObservacoesRestante[index];
        const usoExclusivoEmissor = listaUsoExclusivoEmissorRestante[index];
        const usoExclusivoFisco = listaUsoExclusivoFiscoRestante[index];

        return (
          <Page
            key={String(index)}
            style={styles.pagina}
            orientation="portrait"
          >
            <ConhecimentoTransporteImpressaoModalAereoCabecalhoPDF
              dados={dados}
            />

            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View
                style={{
                  height: '1.5%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottom: '1 solid black',
                  fontSize: 6,
                }}
              >
                <Text>DOCUMENTOS ORIGINÁRIOS</Text>
              </View>

              <View style={{ flexDirection: 'row', height: '98.5%' }}>
                <View style={{ width: '50%', borderRight: '1 solid black' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      fontSize: 6,
                      fontWeight: 'bold',
                    }}
                  >
                    <View style={{ width: '13%', marginLeft: '5px' }}>
                      <Text>TIPO DOC.</Text>
                    </View>

                    <View style={{ width: '57%', marginLeft: '5px' }}>
                      <Text>CHAVE DE ACESSO</Text>
                    </View>

                    <View style={{ width: '25%', marginLeft: '5px' }}>
                      <Text>SÉRIE / NÚMERO</Text>
                    </View>
                  </View>

                  {listaDocumentoA &&
                    listaDocumentoA.map(
                      (dado: IConhecimentoTransporteDocumentoImpressao) => {
                        return (
                          <View style={{ flexDirection: 'row', fontSize: 7 }}>
                            <View style={{ width: '13%', marginLeft: '5px' }}>
                              <Text>{dado.tipoDocumento}</Text>
                            </View>

                            <View style={{ width: '57%', marginLeft: '5px' }}>
                              <Text>{dado.chaveAcesso}</Text>
                            </View>

                            <View style={{ width: '25%', marginLeft: '5px' }}>
                              <Text>
                                {`${dado.serie.padStart(
                                  3,
                                  '0'
                                )} / ${dado.numero.padStart(9, '0')}`}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    )}
                </View>

                <View style={{ width: '50%' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      fontSize: 6,
                      fontWeight: 'bold',
                    }}
                  >
                    <View style={{ width: '13%', marginLeft: '5px' }}>
                      <Text>TIPO DOC.</Text>
                    </View>

                    <View style={{ width: '57%', marginLeft: '5px' }}>
                      <Text>CHAVE DE ACESSO</Text>
                    </View>

                    <View style={{ width: '25%', marginLeft: '5px' }}>
                      <Text>SÉRIE / NÚMERO</Text>
                    </View>
                  </View>

                  {listaDocumentoB &&
                    listaDocumentoB.map(
                      (dado: IConhecimentoTransporteDocumentoImpressao) => {
                        return (
                          <View style={{ flexDirection: 'row', fontSize: 7 }}>
                            <View style={{ width: '13%', marginLeft: '5px' }}>
                              <Text>{dado.tipoDocumento}</Text>
                            </View>

                            <View style={{ width: '57%', marginLeft: '5px' }}>
                              <Text>{dado.chaveAcesso}</Text>
                            </View>

                            <View style={{ width: '25%', marginLeft: '5px' }}>
                              <Text>
                                {`${dado.serie.padStart(
                                  3,
                                  '0'
                                )} / ${dado.numero.padStart(9, '0')}`}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    )}
                </View>
              </View>
            </View>

            <View
              style={{
                height: 40,
                flexDirection: 'column',
                border: '1 solid black',
                borderRadius: 5,
              }}
            >
              <View
                style={{
                  height: '25%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottom: '1 solid black',
                  fontSize: 6,
                }}
              >
                <Text>OBSERVAÇÕES</Text>
              </View>

              <View
                style={{
                  height: '80%',
                  fontSize: 8,
                  marginLeft: '5px',
                  marginRight: '5px',
                  textAlign: 'justify',
                }}
              >
                <Text>{observacoes}</Text>
              </View>
            </View>

            <View style={{ height: 35, flexDirection: 'row' }}>
              <View
                style={{
                  width: '70%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ flexDirection: 'column', height: '100%' }}>
                  <View
                    style={{
                      height: '30%',
                      width: '100%',
                      borderBottom: '1 solid black',
                      fontSize: 6,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>USO EXCLUSIVO DO EMISSOR CT-E</Text>
                  </View>

                  <View
                    style={{
                      height: '70%',
                      fontSize: 6,
                      marginLeft: '5px',
                      marginRight: '5px',
                      textAlign: 'justify',
                    }}
                  >
                    <Text>{usoExclusivoEmissor}</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: '30%',
                  border: '1 solid black',
                  borderRadius: 5,
                }}
              >
                <View style={{ flexDirection: 'column', height: '100%' }}>
                  <View
                    style={{
                      height: '30%',
                      width: '100%',
                      borderBottom: '1 solid black',
                      fontSize: 6,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>RESERVADO AO FISCO</Text>
                  </View>

                  <View
                    style={{
                      height: '70%',
                      fontSize: 6,
                      marginLeft: '5px',
                      marginRight: '5px',
                      textAlign: 'justify',
                    }}
                  >
                    <Text>{usoExclusivoFisco}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              fixed
              style={{
                fontSize: 9,
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Text>https://www.elogestor.com.br</Text>
            </View>

            {(dados.situacao ===
              SituacaoConhecimentoTransporteEnum.naoTransmitido ||
              dados.situacao === SituacaoConhecimentoTransporteEnum.rejeitado ||
              dados.situacao ===
                SituacaoConhecimentoTransporteEnum.emProcessamento) && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.3,
                  }}
                >
                  <Text>VISUALIZAÇÃO</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
                </View>
              </View>
            )}

            {dados.situacao === SituacaoConhecimentoTransporteEnum.denegado && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.3,
                  }}
                >
                  <Text>USO DENEGADO</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
                </View>
              </View>
            )}

            {dados.situacao ===
              SituacaoConhecimentoTransporteEnum.cancelado && (
              <View
                fixed
                style={{
                  position: 'absolute',
                  transform: 'rotate(-45deg)',
                }}
              >
                <View
                  style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 48,
                    opacity: 0.3,
                  }}
                >
                  <Text>CANCELADO</Text>
                  <Text>ESTE DOCUMENTO NÃO TEM VALIDADE JURÍDICA</Text>
                </View>
              </View>
            )}
          </Page>
        );
      })}
    </Document>
  );
};

export default ConhecimentoTransporteImpressaoModalAereoPDF;
