import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ImportacaoTipiDeParaManualItem from './ImportacaoTipiDeParaManualItem';

type Props = {
  listaOpcoesCSV: any[];
  onConfirmarImportacao?: (listaDePara: any[]) => void;
};

const ImportacaoTipiDeParaManual: React.FC<Props> = ({
  listaOpcoesCSV,
  onConfirmarImportacao,
}) => {
  // #region Opções internas

  const opcoesInternas = [
    {
      id: 0,
      nome: 'NCM',
      tooltip: 'Código NCM',
    },
    {
      id: 1,
      nome: 'EX',
      tooltip: 'Excessao',
    },
    {
      id: 2,
      nome: 'DESCRICAO',
      tooltip: 'Descricão',
    },
    {
      id: 3,
      nome: 'ALIQUOTA',
      tooltip: 'Aliquota',
    },
    {
      id: 4,
      nome: 'DATA_VIGENCIA',
      tooltip: 'Data de vigência da alíquota, determinada pelo governo',
    },
  ];

  // #endregion Opções internas

  // #region Estados

  const [itensSelecionados, setItensSelecionados] = useState<any[]>([]);
  const [opcoesCSVFiltradas, setOpcoesCSVFiltradas] = useState<any[]>([]);

  // #endregion Estados

  // #region Funções

  const handleItemSelecionado = (item: any): any => {
    setItensSelecionados((prevItems) => [...prevItems, item]);
  };

  const handleItemRemoval = (item: any): void => {
    const updatedItensSelecionados = itensSelecionados.filter(
      (selectedItem) => {
        return selectedItem.value !== item;
      }
    );
    setItensSelecionados(updatedItensSelecionados);
  };

  useEffect(() => {
    const resposta = listaOpcoesCSV.filter(
      (item) =>
        !itensSelecionados.some((selecionado) => selecionado.value === item)
    );

    setOpcoesCSVFiltradas(resposta);
    onConfirmarImportacao && onConfirmarImportacao(itensSelecionados);
  }, [itensSelecionados, listaOpcoesCSV, onConfirmarImportacao]);

  // #endregion Funções

  return (
    <>
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos aceitos pelo Elo Gestor</b>
          </div>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos do seu arquivo CSV</b>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          {opcoesInternas.map((itemInterno, i) => (
            <ImportacaoTipiDeParaManualItem
              key={i}
              listaOpcoesCsv={opcoesCSVFiltradas}
              listaOpcoesCsvIniciais={listaOpcoesCSV}
              tooltip={itemInterno.tooltip}
              opcaoInterna={itemInterno}
              onItemSelecionado={handleItemSelecionado}
              onItemRemoval={handleItemRemoval}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default ImportacaoTipiDeParaManual;
