"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoTipoCombustivelEnum;
(function (VeiculoTipoCombustivelEnum) {
    VeiculoTipoCombustivelEnum["alcool"] = "01 - \u00C1lcool";
    VeiculoTipoCombustivelEnum["gasolina"] = "02 - Gasolina";
    VeiculoTipoCombustivelEnum["diesel"] = "03 - Diesel";
    VeiculoTipoCombustivelEnum["gasogenio"] = "04 - Gasog\u00EAnio";
    VeiculoTipoCombustivelEnum["gasMetano"] = "05 - G\u00E1s Metano";
    VeiculoTipoCombustivelEnum["eletricoFonteInterna"] = "06 - El\u00E9trico/Fonte Interna";
    VeiculoTipoCombustivelEnum["eletricoFonteExterna"] = "07 - El\u00E9trico/Fonte Externa";
    VeiculoTipoCombustivelEnum["gasolinaGasNaturalCombustivel"] = "08 - Gasolina/G\u00E1s Natural Combust\u00EDvel";
    VeiculoTipoCombustivelEnum["alcoolGasNaturalCombustivel"] = "09 - \u00C1lcool/G\u00E1s Natural Combust\u00EDvel";
    VeiculoTipoCombustivelEnum["dieselGasNaturalCombustivel"] = "10 - Diesel/G\u00E1s Natural Combust\u00EDvel";
    VeiculoTipoCombustivelEnum["videCampoObserva\u00E7\u00E3o"] = "11 - Vide/Campo/Observa\u00E7\u00E3o";
    VeiculoTipoCombustivelEnum["alcoolGasNaturalVeicular"] = "12 - \u00C1lcool/G\u00E1s Natural Veicular";
    VeiculoTipoCombustivelEnum["gasolinaGasNaturalVeicular"] = "13 - Gasolina/G\u00E1s Natural Veicular";
    VeiculoTipoCombustivelEnum["dieselGasNaturalVeicular"] = "14 - Diesel/G\u00E1s Natural Veicular";
    VeiculoTipoCombustivelEnum["gasNaturalVeicular"] = "15 - G\u00E1s Natural Veicular";
    VeiculoTipoCombustivelEnum["alcoolGasolina"] = "16 - \u00C1lcool/Gasolina";
    VeiculoTipoCombustivelEnum["gasolinaAlcoolGasNaturalVeicular"] = "17 - Gasolina/\u00C1lcool/G\u00E1s Natural Veicular";
    VeiculoTipoCombustivelEnum["gasolinaEletrico"] = "18 - Gasolina/el\u00E9trico";
})(VeiculoTipoCombustivelEnum || (VeiculoTipoCombustivelEnum = {}));
exports.default = VeiculoTipoCombustivelEnum;
