import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/terminal-caixa/certificado';

interface ITerminalCaixaEmpresaCertificadoComunicadorShow {
  idTerminalCaixa: string;
}

interface ITerminalCaixaEmpresaCertificadoComunicadorUpdate {
  idTerminalCaixa: string;
}

interface ITerminalCaixaEmpresaCertificadoComunicadorDelete {
  nomeCertificado: string;
}

class TerminalCaixaEmpresaCertificadoComunicador {
  public async show(
    params: ITerminalCaixaEmpresaCertificadoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.idTerminalCaixa}`);

    return response.data;
  }

  public async store(
    params: ITerminalCaixaEmpresaCertificadoComunicadorUpdate
  ): Promise<any> {
    const response = await api.post(rota, params.idTerminalCaixa);

    return response.data;
  }

  public async delete(
    params: ITerminalCaixaEmpresaCertificadoComunicadorDelete
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.nomeCertificado}`);

    return response.data;
  }
}

export default new TerminalCaixaEmpresaCertificadoComunicador();
