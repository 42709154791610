import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoContatoEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompletePessoaContato from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoaContato';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';

const InformacaoComercialTab: React.FC = () => {
  const { formRef } = UseForm();
  const idPessoa = formRef.current?.getFieldValue(
    'cotacaoCompraPessoa.idPessoa'
  );

  useEffect(() => {
    if (!idPessoa) {
      formRef.current?.clearField('cotacaoCompraComercial.pessoaContato', {
        setValorInicial: false,
      });
    }
  }, [idPessoa, formRef]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompletePessoaContato
              label="Contato"
              name="cotacaoCompraComercial.idPessoaContato"
              placeholder="Contato"
              nomeObjeto="cotacaoCompraComercial.pessoaContato"
              idPessoa={idPessoa}
              disabled={!idPessoa}
              tipoContato={TipoContatoEnum.cotacaoCompra}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default InformacaoComercialTab;
