import { INotaFiscalSaidaValoresAlterar } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-itens-calculo-rateios';

interface IObterNotaFiscalSaidaItensParaCalculoRateiosComunicadorIndex {
  id: string;
}

class ObterNotaFiscalSaidaItensParaCalculoRateiosComunicador {
  public async index(
    params: IObterNotaFiscalSaidaItensParaCalculoRateiosComunicadorIndex
  ): Promise<INotaFiscalSaidaValoresAlterar> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ObterNotaFiscalSaidaItensParaCalculoRateiosComunicador();
