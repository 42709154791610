const CurvaABCPedidoVendaProdutoPorValorPlanilha = [
  { titulo: 'Sequência', valor: 'sequencia' },
  { titulo: 'Código', valor: 'codigo' },
  { titulo: 'Descrição do Produto', valor: 'descricaoProduto' },
  { titulo: 'Unidade', valor: 'unidade' },
  { titulo: 'Valor', valor: 'valor' },
  { titulo: 'Perc(%)', valor: 'percentual' },
  { titulo: 'Perc(%) Acum', valor: 'percentualAcumulado' },
];

export default CurvaABCPedidoVendaProdutoPorValorPlanilha;
