"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ImpostoAproximadoCalculo;
function ImpostoAproximadoCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { valorTotalAliquotaImpostoAproximado: null, valorImpostoAproximado: null, textoImpostoAproximado: '' });
    if (dados.valorTotalImpostoAproximado != null ||
        dados.valorTotalBruto != null) {
        retorno.valorTotalAliquotaImpostoAproximado = ((Number(dados.valorTotalImpostoAproximado) /
            Number(dados.valorTotalBruto)) *
            100).Arredondar();
    }
    if (dados.aliquotaImpostoAproximado != null) {
        retorno.valorImpostoAproximado = Number(dados.valorTotalDocumentoFiscal)
            .CalcularPercentual(dados.aliquotaImpostoAproximado)
            .Arredondar();
    }
    if ((dados.valorFederal != null && dados.valorFederal > 0) ||
        (dados.valorEstadual != null && dados.valorEstadual > 0) ||
        (dados.valorMunicipal != null && dados.valorMunicipal > 0)) {
        if (dados.valorFederal != null && dados.valorFederal > 0)
            retorno.textoImpostoAproximado += `R$ ${dados.valorFederal
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+,)/g, '$1.')} Federal`;
        if (dados.valorEstadual != null && dados.valorEstadual > 0) {
            if (retorno.textoImpostoAproximado !== '')
                retorno.textoImpostoAproximado += ', ';
            retorno.textoImpostoAproximado += `R$ ${dados.valorEstadual
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+,)/g, '$1.')} Estadual`;
        }
        if (dados.valorMunicipal != null && dados.valorMunicipal > 0) {
            if (retorno.textoImpostoAproximado !== '')
                retorno.textoImpostoAproximado += ', ';
            retorno.textoImpostoAproximado += `R$ ${dados.valorMunicipal
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+,)/g, '$1.')} Municipal`;
        }
        retorno.textoImpostoAproximado = `Trib Aprox: ${retorno.textoImpostoAproximado} Fonte: ${dados.fonte} ${dados.chave}`;
    }
    return retorno;
}
