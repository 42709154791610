/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  FormatarEnum,
  IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend,
  TipoContaEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import Select from '../../../../../Componentes/Select';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputAutoCompleteTagEmpresa from '../../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import { UseAuth } from '../../../../../Hooks/Auth';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagCategoria from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagCategoria';
import InputDate from '../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteTagFormaPagamento from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagBandeiraCartao from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagBandeiraCartao';
import InputAutoCompleteTagSituacaoContaEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoContaEnum';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import { IInputAutoCompleteClienteFornecedorRef } from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteClienteFornecedor';
import InputAutoCompleteTagClienteFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagClienteFornecedor';
import InputDecimalNulavel from '../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum';

interface IMovimentacoesFinanceirasPortadoresRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const MovimentacoesFinanceirasPortadores: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraRef = useRef<IInputPeriodoDataHoraRef>(null);

  const inputAutoCompleteClienteFornecedorRef =
    useRef<IInputAutoCompleteClienteFornecedorRef>(null);

  const [isTipoContaPagar, setIsTipoContaPagar] = useState(true);
  const [listaIdEmpresa, setListaIdEmpresa] = useState<string[]>([]);
  const [, setIsTipoContaReceber] = useState(true);
  const [, setIsTipoContaReceberPagar] = useState(true);

  const [loading, setLoading] = useState(false);
  const isTipoContaTodos = formRef.current?.getFieldValue('tipoConta') === null;

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] =
    useState('SaldoPorPortador');

  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataHoraInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataInicial() &&
                  !periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data inicial',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataInicial() &&
                  !!periodoDataHoraRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Inicial!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelasPorEmissao' && !value
                );
              },
            }),

          dataHoraFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora final',
              test: () => {
                if (
                  !!periodoDataHoraRef.current?.getDataFinal() &&
                  !periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data final',
              test: () => {
                if (
                  !periodoDataHoraRef.current?.getDataFinal() &&
                  !!periodoDataHoraRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Final!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio === 'MovimentacaoPorPortador' && !value
                );
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [formRef, inputOpcaoRelatorio]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesFinanceirasPortadoresRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesFinanceirasPortadoresRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesFinanceirasPortadoresRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IMovimentacoesFinanceirasPortadoresFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [formRef, handleValidarDataHora, redirecionar, setLoading]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      dataSaldo: new Date().UltimaHoraDia(),
      opcaoRelatorio: 'SaldoPorPortador',
      dataHoraInicial: dataInicial,
      dataHoraFinal: dataFinal,
      dataInicial,
      dataFinal,
      tipoData: 'Data Liquidação',
      saldoInicialPortador: false,
      ativoPortador: false,

      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],

      listaTiposLancamento: [
        { descricao: 'Conta' },
        { descricao: 'Adiantamento' },
        { descricao: 'Previsão' },
      ],

      listaSituacaoParcela: [
        { descricao: 'Aberta' },
        { descricao: 'Parcialmente Liquidada' },
        { descricao: 'Liquidada' },
      ],
    });
  }, [empresa.id, empresa.nomeRazaoSocial, formRef]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Movimentação (Receber/Pagar)
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Saldo Por Portador',
                        value: 'SaldoPorPortador',
                      },
                      {
                        title: 'Resumo Diario Previsto e Realizado',
                        value: 'ResumoDiarioPrevistoRealizado',
                      },
                      {
                        title: 'Movimentação Por Portador',
                        value: 'MovimentacaoPorPortador',
                      },
                      {
                        title: 'Categorias por Emissão',
                        value: 'CategoriasPorEmissao',
                      },
                      {
                        title: 'Categorias por Vencimento',
                        value: 'CategoriasPorVencimento',
                      },
                      {
                        title: 'Categorias por Liquidação',
                        value: 'CategoriasPorLiquidacao',
                      },
                      {
                        title: 'Plano de Categorias - Por Regime de Caixa',
                        value: 'PlanoCategoriasPorRegimeCaixa',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.currentTarget.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'MovimentacaoPorPortador' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento' ||
                      inputOpcaoRelatorio === 'ResumoDiarioPrevistoRealizado'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial={
                        inputOpcaoRelatorio === 'MovimentacaoPorPortador'
                          ? 'Data Inicial'
                          : inputOpcaoRelatorio === 'CategoriasPorVencimento'
                            ? 'Data Vencimento Inicial'
                            : 'Data Emissão Inicial'
                      }
                      labelDataHoraFinal={
                        inputOpcaoRelatorio === 'MovimentacaoPorPortador'
                          ? 'Data Final'
                          : inputOpcaoRelatorio === 'CategoriasPorVencimento'
                            ? 'Data Vencimento Final'
                            : 'Data Emissão Final'
                      }
                      nomeDataHoraInicial="dataHoraInicial"
                      nomeDataHoraFinal="dataHoraFinal"
                      ref={periodoDataHoraRef}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'PlanoCategoriasPorRegimeCaixa'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoData
                      labelDataInicial="Data Inicial"
                      labelDataFinal="Data Final"
                      nomeDataInicial="dataInicial"
                      nomeDataFinal="dataFinal"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={8}
                    md={6}
                    sm={6}
                    style={{ marginTop: 10 }}
                    hidden={
                      !(
                        inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                        inputOpcaoRelatorio === 'CategoriasPorVencimento' ||
                        inputOpcaoRelatorio ===
                          'PlanoCategoriasPorRegimeCaixa' ||
                        inputOpcaoRelatorio === 'CategoriasPorLiquidacao'
                      )
                    }
                  >
                    <InputDate
                      label="Data Final Posição Liquidação"
                      name="dataFinalPosicaoLiquidacao"
                    />
                  </Col>

                  <Col
                    xl={4}
                    sm={6}
                    style={{ marginTop: 10 }}
                    hidden={
                      !(
                        inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                        inputOpcaoRelatorio === 'CategoriasPorVencimento' ||
                        inputOpcaoRelatorio ===
                          'PlanoCategoriasPorRegimeCaixa' ||
                        inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                        inputOpcaoRelatorio === 'MovimentacaoPorPortador' ||
                        inputOpcaoRelatorio === 'ResumoDiarioPrevistoRealizado'
                      )
                    }
                  >
                    <Select
                      name="tipoData"
                      label="Tipo de Data"
                      options={[
                        {
                          title: 'Data Liquidação',
                          value: 'Data Liquidação',
                        },
                        {
                          title: 'Data Contábil',
                          value: 'Data Contábil',
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col
                    xl={8}
                    lg={8}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <InputAutoCompleteTagSituacaoContaEnum
                      label="Situação"
                      name="listaSituacaoParcela"
                    />
                  </Col>
                  <Col xl={4} sm={4} style={{ marginTop: 10 }}>
                    <Select
                      name="tipoConta"
                      label="Tipo da Conta"
                      onChange={(event) => {
                        setIsTipoContaReceber(
                          event.target.value === TipoContaEnum.receber
                        );

                        setIsTipoContaPagar(
                          event.target.value === TipoContaEnum.pagar
                        );
                        setIsTipoContaReceberPagar(isTipoContaTodos);
                      }}
                      options={FormatarEnum({
                        enumObj: TipoContaEnum,
                        todos: true,
                      })}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Parcela)"
                      name="listaPortadoresParcela"
                    />
                  </Col>

                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Liquidação)"
                      name="listaPortadoresLiquidacao"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'MovimentacaoPorPortador'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={8} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Liquidação)"
                      name="listaPortadoresLiquidacao"
                    />
                  </Col>

                  <Col xl={4} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputDecimalNulavel
                      label="Saldo Inicial"
                      placeholder="Saldo Inicial"
                      name="saldoInicial"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'SaldoPorPortador'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={4} md={4} sm={4} style={{ marginTop: 10 }}>
                    <InputDate label="Data do Saldo" name="dataSaldo" />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'SaldoPorPortador' ||
                      inputOpcaoRelatorio === 'ResumoDiarioPrevistoRealizado'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores"
                      name="listaPortadores"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'ResumoDiarioPrevistoRealizado'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum
                      label="Tipos Lançamento"
                      name="listaTiposLancamento"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagBandeiraCartao
                      label="Cartões"
                      name="listaCartoes"
                    />
                  </Col>

                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCategoria
                      label="Categorias"
                      name="listaCategorias"
                      listaIdEmpresa={listaIdEmpresa}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Parcela)"
                      name="listaFormasPagamentoParcela"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Liquidação)"
                      name="listaFormasPagamentoLiquidacao"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'CategoriasPorEmissao' ||
                      inputOpcaoRelatorio === 'CategoriasPorLiquidacao' ||
                      inputOpcaoRelatorio === 'CategoriasPorVencimento'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagClienteFornecedor
                      label="Cliente / Fornecedor"
                      placeholder="Cliente / Fornecedor"
                      name="listaClientesFornecedores"
                      // ref={inputAutoCompleteClienteFornecedorRef}
                      // fornecedor={isTipoContaTodos || isTipoContaPagar}
                      // cliente={isTipoContaTodos || !isTipoContaPagar}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                      onChangeListaItemAtual={(ev) => {
                        if (ev.listaItemAtual) {
                          if (ev.listaItemAtual.length === 0) {
                            setListaIdEmpresa([]);
                          } else {
                            ev.listaItemAtual.forEach((it) =>
                              setListaIdEmpresa((prevState) => {
                                const lista = prevState.filter(
                                  (item) => item !== it.id
                                );
                                lista.push(it.id);

                                return lista;
                              })
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'SaldoPorPortador'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={4} md={12} sm={12} style={{ marginTop: 12 }}>
                    <InputSwitch
                      label="Somente Portadores Ativos"
                      name="portadorAtivo"
                      ativo="Sim"
                      inativo="Não"
                    />
                  </Col>
                  <Col xl={4} md={4} sm={4} style={{ marginTop: 12 }}>
                    <InputSwitch
                      label="Somente Portadores com Saldo"
                      name="somentePortadorComSaldo"
                      ativo="Sim"
                      inativo="Não"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio === 'PlanoCategoriasPorRegimeCaixa'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={4} md={4} sm={4} style={{ marginTop: 12 }}>
                    <InputSwitch
                      label="Somente Categorias com Valor"
                      name="somenteCategoriasComValor"
                      ativo="Sim"
                      inativo="Não"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default MovimentacoesFinanceirasPortadores;
