/* eslint-disable consistent-return */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IParametroRotaDetalhe from '../../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import EstruturaProdutoComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoComunicador';
import EstruturaProdutoDetalheComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoDetalheComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';

export interface IListaItem {
  codigo: string;
  descricao: string;
  id: string;
  idProdutoFilho: string;
  idProdutoPai: string;
  idPai: string;
  ordem: number;

  produtoFilho: {
    codigo: string;
    descricao: string;
    id: string;
  };

  produtoPai: {
    codigo: string;
    descricao: string;
    id: string;
  };

  produtoEstoque: {
    id: string;
  };

  filhos: IListaItem[];
  isOpen?: boolean;
}

interface IExcluirItemParametros {
  item: IListaItem;
  hasFilhos: boolean;
}

interface IEncontrarLimiteOrdem {
  item: IListaItem;
  listaEstrutura: IListaItem[];
}

interface ITreeContextProps {
  idShowItem: string;
  setIdShowItem: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  idSelecionado: string;
  setIdSelecionado: React.Dispatch<React.SetStateAction<string>>;
  setIdItemPai: React.Dispatch<React.SetStateAction<string>>;
  idItemPai: string;
  setIdProdutoPaiCopiado: React.Dispatch<React.SetStateAction<string>>;
  idProdutoPaiCopiado: string;
  excluirItem: ({ item, hasFilhos }: IExcluirItemParametros) => Promise<void>;
  encontrarLimiteOrdem: ({
    item,
    listaEstrutura,
  }: IEncontrarLimiteOrdem) => number;
  listaDados: IListaItem[];
  setListaDados: React.Dispatch<React.SetStateAction<any[]>>;
  estruturaSelecionada: string;
  setEstruturaSelecionada: React.Dispatch<React.SetStateAction<string>>;
  ordemMaxima: number;
  setOrdemMaxima: React.Dispatch<React.SetStateAction<number>>;
  buscarDados: (id?: string) => Promise<any>;
  refresh: () => void;
}

const TreeContext = createContext({} as ITreeContextProps);

export const TreeContextProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [listaDados, setListaDados] = useState<IListaItem[]>([]);
  const [idShowItem, setIdShowItem] = useState({ id: '' });
  const [idSelecionado, setIdSelecionado] = useState('');
  const [idItemPai, setIdItemPai] = useState('');
  const [idProdutoPaiCopiado, setIdProdutoPaiCopiado] = useState('');
  const [estruturaSelecionada, setEstruturaSelecionada] = useState('');
  const [ordemMaxima, setOrdemMaxima] = useState(1);
  const { abrirJanela } = UseConfirmacao();
  const params: IParametroRotaDetalhe = useParams();

  const navigate = useNavigate();
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const encontrarLimiteOrdem = useCallback(
    ({ item, listaEstrutura }: IEncontrarLimiteOrdem): number => {
      function findPai(idProdutoPai: string, listaDadosPai: any[]): any {
        for (let i = 0; i < listaDadosPai.length; i++) {
          const valor = listaDadosPai[i];

          if (valor.idProdutoPai === idProdutoPai) return valor;

          for (let j = 0; j < valor.filhos.length; j++) {
            const filho = valor.filhos[j];

            const pai = findPai(idProdutoPai, [filho]);
            if (pai) return pai;
          }
        }
      }

      const pai = findPai(item.idPai, listaEstrutura);
      return pai.filhos.length;
    },
    []
  );

  const buscarDados = useCallback(async (id?: string): Promise<any> => {
    if (id) {
      const { listaRegistro } = await EstruturaProdutoComunicador.show({
        id,
      });
      if (listaRegistro && listaRegistro.length > 0) {
        setListaDados(listaRegistro);

        return listaRegistro;
      }
    }
  }, []);

  const excluirItem = useCallback(
    async ({ item, hasFilhos }: IExcluirItemParametros): Promise<void> => {
      if (!item.id) return;
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            {hasFilhos &&
              'Ao excluir esse registro todos os registros filhos também serão excluídos. '}
            Deseja excluir o item?
          </span>
        ),
      });

      if (!resposta) {
        return;
      }

      try {
        if (hasFilhos && item.idProdutoFilho) {
          await EstruturaProdutoComunicador.delete({ id: item.idProdutoPai });
          navigate('/manufatura/engenharia/estrutura-produto');
        } else if (listaDados[0].filhos.length === 1) {
          await EstruturaProdutoDetalheComunicador.delete({ id: item.id });
          navigate('/manufatura/engenharia/estrutura-produto');
        } else {
          await EstruturaProdutoDetalheComunicador.delete({ id: item.id });
        }

        const listaEstrutura = await buscarDados(
          params.id || idProdutoPaiCopiado
        );

        if (listaEstrutura && listaEstrutura.length > 0) {
          setOrdemMaxima(encontrarLimiteOrdem({ item, listaEstrutura }));
          setIdShowItem({ id: listaEstrutura[0].filhos[0].id });
          setIdSelecionado(listaEstrutura[0].filhos[0].id);
          setEstruturaSelecionada(listaEstrutura[0].filhos[0].descricao);
        }

        ToastSucesso('Registro Deletado!');
      } catch (error) {
        TratarErros(error);
      }

      refresh();
    },
    [
      abrirJanela,
      refresh,
      listaDados,
      buscarDados,
      params.id,
      idProdutoPaiCopiado,
      navigate,
      encontrarLimiteOrdem,
    ]
  );

  useEffect(() => {
    (async () => {
      await buscarDados();
    })();
  }, [buscarDados]);

  return (
    <TreeContext.Provider
      value={{
        idShowItem: idShowItem.id,
        setIdShowItem,
        idSelecionado,
        idProdutoPaiCopiado,
        setIdProdutoPaiCopiado,
        setIdSelecionado,
        excluirItem,
        listaDados,
        setListaDados,
        buscarDados,
        encontrarLimiteOrdem,
        setIdItemPai,
        idItemPai,
        estruturaSelecionada,
        setEstruturaSelecionada,
        ordemMaxima,
        setOrdemMaxima,
        refresh,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export const UseTreeContext = (): ITreeContextProps => {
  const context = useContext(TreeContext);
  return context;
};
