import React, { useEffect, useState } from 'react';
import { MdNetworkWifi } from 'react-icons/md/index.mjs';
import { Container, Conteundo } from './styles';

const Conexao: React.FC = () => {
  const [online, setOnline] = useState(false);

  useEffect(() => {
    const turnOnline = (): void => {
      setOnline(false);
    };

    const turnOffline = (): void => {
      setOnline(true);
    };

    window.addEventListener('online', turnOnline);
    window.addEventListener('offline', turnOffline);

    return () => {
      window.removeEventListener('online', turnOnline);
      window.removeEventListener('offline', turnOffline);
    };
  }, []);

  return (
    <>
      {online && (
        <Container>
          <MdNetworkWifi />
          <Conteundo>
            <h3>Falha de conexão com o sistema</h3>
            <p>Verifique sua conexão com a internet e tente novamente</p>
          </Conteundo>
        </Container>
      )}
    </>
  );
};

export default Conexao;
