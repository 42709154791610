import React, { useCallback, useEffect, useMemo } from 'react';
import { Pagination, Form } from 'react-bootstrap';
import { UseLista } from './ListaHooks';
import { UseTableDraggable } from '../TableDraggable/HooksTable/TableDraggableContext';
import { UseReactSizeMeBodyHook } from '../../Hooks/ReactSizeMeBodyHook';

interface IPaginacao {
  opcao1000?: boolean;
  opcao100?: boolean;
  paginacaoPadrao?: number;
  refresh?: boolean;
  permitirAtualizar?: () => Promise<boolean>;
}

const Paginacao: React.FC<IPaginacao> = ({
  opcao1000 = false,
  paginacaoPadrao = 10,
  refresh,
  permitirAtualizar,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    paginacao: { limite, totalPaginas, alterarLimite },
  } = UseLista();

  const {
    setParametros,
    parametros: { pagina: paginaAtual = 1 },
  } = UseTableDraggable();

  useEffect(() => {
    setParametros({ pagina: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    alterarLimite(paginacaoPadrao);
    setParametros({ pagina: 1, limite: paginacaoPadrao });
  }, [alterarLimite, paginacaoPadrao, setParametros]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const paginacao = useMemo(() => {
    const retorno = [];
    let i = 1;
    let fimFor = totalPaginas;

    if (paginaAtual - 1 > 1) {
      if (paginaAtual === totalPaginas) i = totalPaginas - 2;
      else i = paginaAtual - 1;
    }

    if (paginaAtual + 1 < totalPaginas) {
      if (paginaAtual === 1) fimFor = paginaAtual + 2;
      else fimFor = paginaAtual + 1;
    }

    if (paginaAtual + 2 > totalPaginas && totalPaginas > 3) {
      retorno.push(
        <Pagination.Item
          key={1}
          onClick={async () => {
            if (await handlePermitirAtualizar()) {
              setParametros({ pagina: 1 });
            }
          }}
        >
          1
        </Pagination.Item>
      );
      retorno.push(<Pagination.Ellipsis key="Ellipsis" />);
    }

    for (i; i <= fimFor; i += 1) {
      const iAtual = i;

      retorno.push(
        <Pagination.Item
          key={i}
          active={i === paginaAtual}
          onClick={async () => {
            if (await handlePermitirAtualizar()) {
              setParametros({ pagina: iAtual });
            }
          }}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (paginaAtual + 1 < totalPaginas) {
      if (totalPaginas > 3) {
        retorno.push(<Pagination.Ellipsis key="Ellipsis" />);

        retorno.push(
          <Pagination.Item
            key={totalPaginas}
            onClick={async () => {
              if (await handlePermitirAtualizar()) {
                setParametros({ pagina: totalPaginas });
              }
            }}
          >
            {totalPaginas}
          </Pagination.Item>
        );
      }
    }

    return retorno;
  }, [handlePermitirAtualizar, paginaAtual, setParametros, totalPaginas]);

  if (telaGrande)
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Pagination>
            <Pagination.Item
              onClick={async () => {
                if (await handlePermitirAtualizar()) {
                  setParametros({ pagina: 1 });
                }
              }}
              disabled={paginaAtual === 1}
            >
              Primeiro
            </Pagination.Item>
            <Pagination.Item
              onClick={async () => {
                if (paginaAtual - 1 >= 1) {
                  if (await handlePermitirAtualizar()) {
                    setParametros({ pagina: paginaAtual - 1 });
                  }
                }
              }}
              disabled={paginaAtual === 1}
            >
              Anterior
            </Pagination.Item>

            {paginacao}

            <Pagination.Item
              onClick={async () => {
                if (paginaAtual + 1 <= totalPaginas) {
                  if (await handlePermitirAtualizar()) {
                    setParametros({ pagina: paginaAtual + 1 });
                  }
                }
              }}
              disabled={paginaAtual === totalPaginas || totalPaginas === 0}
            >
              Próximo
            </Pagination.Item>
            <Pagination.Item
              onClick={async () => {
                if (await handlePermitirAtualizar()) {
                  setParametros({ pagina: totalPaginas });
                }
              }}
              disabled={paginaAtual === totalPaginas || totalPaginas === 0}
            >
              Último
            </Pagination.Item>
          </Pagination>
        </div>

        <div className="float-right">
          <Form.Control
            value={limite}
            as="select"
            onChange={async (event) => {
              const limiteFormatado = Number(event.currentTarget.value);

              if (await handlePermitirAtualizar()) {
                setParametros({ pagina: 1, limite: limiteFormatado });
                alterarLimite(limiteFormatado);
              }
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            {opcao1000 && <option value="1000">1000</option>}
          </Form.Control>
        </div>
      </div>
    );

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <Pagination className="flex-wrap">
          <Pagination.First
            onClick={async () => {
              if (await handlePermitirAtualizar()) {
                setParametros({ pagina: 1 });
              }
            }}
            disabled={paginaAtual === 1}
          />

          <Pagination.Prev
            onClick={async () => {
              if (paginaAtual - 1 >= 1) {
                if (await handlePermitirAtualizar()) {
                  setParametros({ pagina: paginaAtual - 1 });
                }
              }
            }}
            disabled={paginaAtual === 1}
          />

          {paginacao}

          <Pagination.Next
            onClick={async () => {
              if (paginaAtual + 1 <= totalPaginas) {
                if (await handlePermitirAtualizar()) {
                  setParametros({ pagina: paginaAtual + 1 });
                }
              }
            }}
            disabled={paginaAtual === totalPaginas || totalPaginas === 0}
          />
          <Pagination.Last
            onClick={async () => {
              if (await handlePermitirAtualizar()) {
                setParametros({ pagina: totalPaginas });
              }
            }}
            disabled={paginaAtual === totalPaginas || totalPaginas === 0}
          />
        </Pagination>
      </div>

      <div className="float-right">
        <Form.Control
          value={limite}
          as="select"
          onChange={async (event) => {
            const limiteFormatado = Number(event.currentTarget.value);

            if (await handlePermitirAtualizar()) {
              setParametros({ pagina: 1, limite: limiteFormatado });
              alterarLimite(limiteFormatado);
            }
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
          {opcao1000 && <option value="1000">1000</option>}
        </Form.Control>
      </div>
    </div>
  );
};

export default Paginacao;
