import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import RegraEscolhaOperacaoFiscalComunicador from '../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaOperacaoFiscal/Comunicador/RegraEscolhaOperacaoFiscalComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const RegraEscolhaOperacaoFiscalHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: RegraEscolhaOperacaoFiscalComunicador }}
    >
      <ButtonsSubHeaderProvider>
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ButtonsSubHeaderProvider>
    </ComunicadorProvider>
  );
};

export default RegraEscolhaOperacaoFiscalHook;
