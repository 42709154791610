import React from 'react';
import RotasHook from './RotasHook';
import FormHook from './FormHook';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

const ConhecimentoTransporteCargaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <RotasHook>
      <FormHook>{children}</FormHook>
    </RotasHook>
  );
};

export default ConhecimentoTransporteCargaHook;
