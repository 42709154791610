import api from '../../../../../../Comum/Services/Api';

const rota = '/financeiro/categoria-dre/categorias-auto-complete';

interface ICategoriaAutoCompleteComunicadorIndex {
  params: any;
}

class CategoriaAutoCompleteComunicador {
  public async index(
    params: ICategoriaAutoCompleteComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new CategoriaAutoCompleteComunicador();
