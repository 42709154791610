import { IPedidoCompraRetratoSemFotoProdutoImpressao } from '@elogestor/util';
import React from 'react';
import PDF from '../../../../../../../../../Util/Relatorios/PDF';
import ImpressaoPedidoCompraRetratoSemFotoProdutoPDF from './PedidoCompraRetratoSemFotoProdutoImpressaoPDF';

interface IImpressaoPedidoCompraRetratoSemFotoProduto {
  handleVisualizarPdf(
    data: IPedidoCompraRetratoSemFotoProdutoImpressao
  ): Promise<void>;
  handleObterBlobPdf(
    dados: IPedidoCompraRetratoSemFotoProdutoImpressao
  ): Promise<Blob | undefined>;
}

const ImpressaoPedidoCompraRetratoSemFotoProduto =
  (): IImpressaoPedidoCompraRetratoSemFotoProduto => {
    async function handleVisualizarPdf(
      dados: IPedidoCompraRetratoSemFotoProdutoImpressao
    ): Promise<void> {
      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoCompraRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });

      relatorio.AbrirArquivo();
    }

    async function handleObterBlobPdf(
      dados: IPedidoCompraRetratoSemFotoProdutoImpressao
    ): Promise<Blob | undefined> {
      if (!dados) return undefined;

      const relatorio = await PDF({
        documento: (
          <ImpressaoPedidoCompraRetratoSemFotoProdutoPDF dados={dados} />
        ),
      });

      return relatorio.GetBlob();
    }

    return { handleVisualizarPdf, handleObterBlobPdf };
  };

export default ImpressaoPedidoCompraRetratoSemFotoProduto();
