import React, { createContext, useCallback, useContext, useState } from 'react';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IImportacaoXmlEmitidosContext {
  validador: boolean;
  listaMensagemErro: string[];
  setListaMensagemErro: (lista: string[]) => string[];
}

const ImportacaoXmlEmitidos = createContext<IImportacaoXmlEmitidosContext>(
  {} as IImportacaoXmlEmitidosContext
);

const ImportacaoXmlEmitidosHook: React.FC<IPadraoProps> = ({ children }) => {
  const [listaMensagemErro, setListaMensagemErro] = useState<string[]>([]);

  const handleSetListaMensagemErro = useCallback(
    (lista: string[]): string[] => {
      const listaValor = [...lista];
      setListaMensagemErro(lista);
      return listaValor;
    },
    []
  );

  return (
    <ImportacaoXmlEmitidos.Provider
      value={{
        listaMensagemErro,
        setListaMensagemErro: handleSetListaMensagemErro,
        validador: true,
      }}
    >
      {children}
    </ImportacaoXmlEmitidos.Provider>
  );
};

function UseImportacaoXmlEmitidos(): Omit<
  IImportacaoXmlEmitidosContext,
  'validador'
> {
  const context = useContext(ImportacaoXmlEmitidos);

  if (!context.validador) {
    throw new Error(
      'UseImportacaoXmlEmitidos deve ser usado com um ImportacaoXmlEmitidosHook'
    );
  }

  return context;
}

export { ImportacaoXmlEmitidosHook, UseImportacaoXmlEmitidos };
