import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  IReprocessamentoProducaoImpressao,
} from '@elogestor/util';
import JsBarcode from 'jsbarcode';

const Document: any = _document;
const Page: any = _page;

interface IReprocessamentoDesmontagemImpressaoPDF {
  dados: IReprocessamentoProducaoImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const ReprocessamentoDesmontagemImpressaoPDF: React.FC<
  IReprocessamentoDesmontagemImpressaoPDF
> = ({ dados }) => {
  const canvasBarCode = document.createElement('canvas');
  JsBarcode(
    canvasBarCode,
    String(dados.numero).CompletaCaracteres(4, '0', false),
    {
      fontSize: 2,
      format: 'CODE128C',
    }
  );
  const barCode = canvasBarCode.toDataURL();

  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'flex-start',
              marginTop: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                source={dados.caminhoLogo}
                style={{
                  marginLeft: 5,
                  width: 100,
                  borderRadius: 0,
                }}
              />
            )}
          </View>

          <View
            style={{
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                REPROCESSAMENTO DE PRODUÇÃO
              </Text>
            </View>
            <View style={{ marginTop: '5pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                {dados.empresa.nomeRazaoSocial}
              </Text>
            </View>
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <View>
              <Text
                style={{ fontSize: 9 }}
                render={({ pageNumber, totalPages }) => {
                  return `Página ${pageNumber} de ${totalPages}`;
                }}
                fixed
              />
            </View>

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: '10pt',
              }}
            >
              <View style={{ width: '80%', height: 85 }}>
                <Image src={barCode} />
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            fontSize: 10,
            marginBottom: 10,
          }}
        >
          <View style={{ width: '35%' }}>
            <Text>{`Data Retirada: ${
              dados.dataHoraRetirada
                ? FormatarDataParaPtBr(dados.dataHoraRetirada)
                : ''
            }`}</Text>
          </View>

          <View style={{ width: '35%' }}>
            <Text>{`Data Retorno: ${
              dados.dataHoraRetorno
                ? FormatarDataParaPtBr(dados.dataHoraRetorno)
                : ''
            }`}</Text>
          </View>

          <View style={{ width: '35%', alignItems: 'flex-end' }}>
            <Text>{`Numero: ${dados.numero}`}</Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            fontSize: 10,
            marginBottom: 10,
            fontWeight: 'bold',
          }}
        >
          <View style={{ width: '100%' }}>
            <Text>{`Produto: ${dados.produto.codigo} - ${dados.produto.descricao}`}</Text>
          </View>
        </View>

        {dados.listaReprocessamentoProducaoLocalEstoqueOrigem &&
          dados.listaReprocessamentoProducaoLocalEstoqueOrigem.length > 0 && (
            <View
              style={{
                justifyContent: 'center',
                marginBottom: 10,
                width: '100%',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: '1pt solid black',
                  marginTop: 5,
                  fontSize: 10,
                  fontWeight: 'bold',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <View style={{ width: '49%' }}>
                  <Text>Local de Estoque de Origem</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '49%', alignItems: 'flex-end' }}>
                  <Text>Quantidade</Text>
                </View>
              </View>

              <View style={{ borderBottom: '1pt solid black' }}>
                <View>
                  {dados.listaReprocessamentoProducaoLocalEstoqueOrigem.map(
                    (dado, index) => {
                      return (
                        <View key={dado.id}>
                          <View
                            style={{
                              flexDirection: 'row',
                              paddingTop: 5,
                              fontSize: 10,
                              backgroundColor:
                                index % 2 === 0 ? '#E4E4E4' : '#fff',
                            }}
                          >
                            <View style={{ width: '49%' }}>
                              <Text>{dado.localEstoque.descricao}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '49%', alignItems: 'flex-end' }}
                            >
                              <Text>{dado.quantidade}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    }
                  )}
                </View>
              </View>
            </View>
          )}

        {dados.listaReprocessamentoProducaoLocalEstoqueDestino &&
          dados.listaReprocessamentoProducaoLocalEstoqueDestino.length > 0 && (
            <View
              style={{
                justifyContent: 'center',
                marginBottom: 10,
                width: '100%',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  borderBottom: '1pt solid black',
                  marginTop: 5,
                  fontSize: 10,
                  fontWeight: 'bold',
                  backgroundColor: '#cdcdcd',
                }}
              >
                <View style={{ width: '49%' }}>
                  <Text>Local de Estoque de Destino</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '49%', alignItems: 'flex-end' }}>
                  <Text>Quantidade</Text>
                </View>
              </View>

              <View style={{ borderBottom: '1pt solid black' }}>
                <View>
                  {dados.listaReprocessamentoProducaoLocalEstoqueDestino.map(
                    (dado, index) => {
                      return (
                        <View key={dado.id}>
                          <View
                            style={{
                              flexDirection: 'row',
                              paddingTop: 5,
                              backgroundColor:
                                index % 2 === 0 ? '#E4E4E4' : '#fff',
                              fontSize: 10,
                            }}
                          >
                            <View style={{ width: '49%' }}>
                              <Text>{dado.localEstoque.descricao}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '49%', alignItems: 'flex-end' }}
                            >
                              <Text>{dado.quantidade}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    }
                  )}
                </View>
              </View>
            </View>
          )}

        {dados.listaReprocessamentoProducaoItem &&
          dados.listaReprocessamentoProducaoItem.length > 0 && (
            <View
              style={{
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <View style={{ width: '100%', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11 }}>
                  Itens Consumidos
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  borderBottom: '1pt solid black',
                  backgroundColor: '#cdcdcd',
                  fontSize: 9,
                }}
              >
                <View style={{ width: '5%', alignItems: 'flex-end' }}>
                  <Text>Seq.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '15%', alignItems: 'flex-end' }}>
                  <Text>Código</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '60%' }}>
                  <Text>Descrição</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '1%' }} />

                <View style={{ width: '5%' }}>
                  <Text>UN</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '12%', alignItems: 'flex-end' }}>
                  <Text>Qtde.</Text>
                </View>
              </View>

              {dados.listaReprocessamentoProducaoItem?.map((dado, index) => {
                if (
                  dado.listaReprocessamentoProducaoItemLocalEstoque &&
                  dado.listaReprocessamentoProducaoItemLocalEstoque.length > 0
                ) {
                  const div =
                    dado.listaReprocessamentoProducaoItemLocalEstoque.map(
                      (item) => {
                        return (
                          <View
                            key={dado.ordem}
                            style={{
                              flexDirection: 'row',
                              backgroundColor:
                                index % 2 === 0 ? '#E4E4E4' : '#fff',
                            }}
                          >
                            <View
                              style={{ width: '5%', alignItems: 'flex-end' }}
                            >
                              <Text>{dado.ordem}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '15%', alignItems: 'flex-end' }}
                            >
                              <Text>{dado.produto.codigo}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View style={{ width: '60%' }}>
                              <Text>{dado.produto.descricao}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View style={{ width: '5%' }}>
                              <Text>{dado.unidadeMedida?.sigla}</Text>
                            </View>
                            <View style={{ width: '1%' }} />

                            <View
                              style={{ width: '12%', alignItems: 'flex-end' }}
                            >
                              <Text>
                                {Number(dado.quantidade).FormatarParaPtBr()}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    );

                  return div;
                }
                return (
                  <View
                    key={dado.ordem}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '5%', alignItems: 'flex-end' }}>
                      <Text>{dado.ordem}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '15%', alignItems: 'flex-end' }}>
                      <Text>{dado.produto.codigo}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '50%' }}>
                      <Text>{dado.produto.descricao}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '11%' }} />

                    <View style={{ width: '5%' }}>
                      <Text>{dado.unidadeMedida?.sigla}</Text>
                    </View>
                    <View style={{ width: '1%' }} />

                    <View style={{ width: '12%', alignItems: 'flex-end' }}>
                      <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}

        {dados.observacoes && dados.observacoes !== '' && (
          <View
            style={{
              marginTop: 10,
              minHeight: 70,
              fontSize: 10,
              width: '100%',
              borderBottom: '1pt solid black',
              borderTop: '1pt solid black',
            }}
          >
            <View>
              <Text>{`Observações: ${dados.observacoes}`}</Text>
            </View>
          </View>
        )}

        {dados.listaReprocessamentoProducaoRoteiroFabricacaoOperacao &&
          dados.listaReprocessamentoProducaoRoteiroFabricacaoOperacao.length >
            0 && (
            <View
              style={{
                fontSize: 10,
                marginTop: 10,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <View style={{ width: '100%', alignItems: 'center' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11 }}>
                  Processos
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  borderBottom: '1pt solid black',
                  backgroundColor: '#cdcdcd',
                  fontSize: 9,
                }}
              >
                <View style={{ width: '9%', alignItems: 'flex-end' }}>
                  <Text>Seq.</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '44%' }}>
                  <Text>Descrição</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '45%' }}>
                  <Text>Observações</Text>
                </View>
              </View>

              {dados.listaReprocessamentoProducaoRoteiroFabricacaoOperacao?.map(
                (dado, index) => {
                  return (
                    <View
                      key={dado.ordem}
                      style={{
                        flexDirection: 'row',
                        backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ width: '9%', alignItems: 'flex-end' }}>
                        <Text>{dado.ordem}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '44%' }}>
                        <Text>{dado.operacaoRoteiroFabricacao.descricao}</Text>
                      </View>
                      <View style={{ width: '1%' }} />

                      <View style={{ width: '45%' }}>
                        <Text>{dado.observacao}</Text>
                      </View>
                    </View>
                  );
                }
              )}
            </View>
          )}
      </Page>
    </Document>
  );
};

export default ReprocessamentoDesmontagemImpressaoPDF;
