import api from '../../../../../Comum/Services/Api';

const rota =
  '/suprimentos/relatorio/devolucao-notas-entrada/devolucao-notas-entrada-com-notas-devolvidas';

interface IDevolucaoNotasEntradasComNotasDevolvidasRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class DevolucaoNotasEntradasComNotasDevolvidasRelatorioComunicador {
  public async index(
    params: IDevolucaoNotasEntradasComNotasDevolvidasRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new DevolucaoNotasEntradasComNotasDevolvidasRelatorioComunicador();
