"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoCargaEnum;
(function (TipoCargaEnum) {
    TipoCargaEnum["granelSolido"] = "1 - Granel S\u00F3lido";
    TipoCargaEnum["granelLiquido"] = "2 - Granel L\u00EDquido";
    TipoCargaEnum["frigorificada"] = "3 - Frigorificada";
    TipoCargaEnum["conteinerizada"] = "4 - Conteinerizada";
    TipoCargaEnum["cargaGeral"] = "5 - Carga Geral";
    TipoCargaEnum["neogranel"] = "6 - Neogranel";
    TipoCargaEnum["perigosaGranelSolido"] = "7 - Perigosa (Granel S\u00F3lido)";
    TipoCargaEnum["perigosaGranelLiquido"] = "8 - Perigosa (Granel L\u00EDquido)";
    TipoCargaEnum["perigosaCargaFrigorificada"] = "9 - Perigosa (Carga Frigorificada)";
    TipoCargaEnum["perigosaConteinerizada"] = "10 - Perigosa (Conteinerizada)";
    TipoCargaEnum["PerigosaCargaGeral"] = "11 - Perigosa (Carga Geral)";
})(TipoCargaEnum || (TipoCargaEnum = {}));
exports.default = TipoCargaEnum;
