import {
  FormatarEnum,
  IRegraEscolhaSisdevinValoresAlterar,
  RegraEscolhaTipoEnum,
  SisDevinTipoOperacaoCultivarEnum,
  SisDevinTipoOperacaoEngarrafadosEnum,
  SisDevinTipoOperacaoGranelEnum,
  TipoEntradaSaidaOperacaoFiscalEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import Divisor from '../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import Select from '../../../../../../Componentes/Select/index';
import InputAutoCompleteTipoNota from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNota';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTipoNotaMotivo from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteTipoNotaMotivo';
import InputAutoCompleteTagModeloDocumento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagSerie from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputAutoCompleteTagDestinadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagDestinadoEnum';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import InputAutoCompleteTagTipoMercadoEnum from '../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoMercadoEnum';

type IProps = IMain<IRegraEscolhaSisdevinValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { SuprimentosSisdevinRegraEscolhaParametrosSisdevin: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();
  const { setDisableExcluir } = UseButtonsSubHeader();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const tipoMovimento = formRef.current?.getFieldValue('tipoMovimento');
  const regraEscolhaTipo = formRef.current?.getFieldValue('tipo');

  useEffect(() => {
    if (tipoUsuario !== 'Elo Gestor' && regraEscolhaTipo === 'Padrão') {
      setDisableExcluir(regraEscolhaTipo);
    }
  }, [regraEscolhaTipo, setDisableExcluir, tipoUsuario]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  // Não Remover os await - são nescessarios
  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (formRef.current?.getFieldValue('idTipoNota')) {
      await quantidadeCriterios++;
    }
    if (formRef.current?.getFieldValue('idTipoNotaMotivo')) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinDestinado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinOperacaoFiscal')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinSped').listaValor
        .length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinModeloDocumento')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinSerie')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinTipoTransacao')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    if (
      formRef.current?.getFieldValue('listaRegraEscolhaSisdevinTipoMercado')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo"
                name="tipo"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
                options={
                  (FormatarEnum({
                    enumObj: RegraEscolhaTipoEnum,
                  }),
                  [
                    {
                      title: 'Contabilidade',
                      value: RegraEscolhaTipoEnum.contabilidade,
                    },
                    { title: 'Empresa', value: RegraEscolhaTipoEnum.empresa },
                    {
                      title: 'Padrão',
                      value: RegraEscolhaTipoEnum.padrao,
                      hidden: tipoUsuario !== 'Elo Gestor',
                    },
                  ])
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                ref={inputRefFocus}
                label="Data Vigência Incial"
                name="dataInicioVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaSisdevinEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={5} md={6} sm={12}>
              <InputAutoCompleteTipoNota
                label="Tipo de Nota"
                name="idTipoNota"
                placeholder="Tipo de Nota"
                nomeObjeto="tipoNota"
                onChangeItemAtual={() => {
                  formRef.current?.clearField('tipoNotaMotivo', {
                    setValorInicial: false,
                  });
                  refresh();
                }}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={5} md={6} sm={12}>
              <InputAutoCompleteTipoNotaMotivo
                label="Motivo"
                name="idTipoNotaMotivo"
                placeholder="Motivo"
                nomeObjeto="tipoNotaMotivo"
                disabled={
                  !formRef.current?.getFieldValue('idTipoNota') ||
                  (regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor')
                }
                idTipoNota={formRef.current?.getFieldValue('idTipoNota')}
                onChangeItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                label="Tipo Entrada/Saída"
                name="tipoMovimento"
                options={FormatarEnum({
                  enumObj: TipoMovimentoEntradaSaidaEnum,
                })}
                onChange={() => {
                  refresh();
                  formRef.current?.clearField(
                    'listaRegraEscolhaSisdevinOperacaoFiscal'
                  );
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagDestinadoEnum
                label="Destinado"
                name="listaRegraEscolhaSisdevinDestinado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagOperacaoFiscal
                label="Lista CFOP"
                name="listaRegraEscolhaSisdevinOperacaoFiscal"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                tipoEntradaSaida={
                  tipoMovimento === TipoMovimentoEntradaSaidaEnum.entrada
                    ? TipoEntradaSaidaOperacaoFiscalEnum.entrada
                    : TipoEntradaSaidaOperacaoFiscalEnum.saida
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum
                label="Tipo de Transação da Operação Fiscal (CFOP)"
                name="listaRegraEscolhaSisdevinTipoTransacao"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>

            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagTipoMercadoEnum
                label="Tipos de Mercado"
                name="listaRegraEscolhaSisdevinTipoMercado"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSpedTipoProduto
                label="Tipos SPED"
                name="listaRegraEscolhaSisdevinSped"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagModeloDocumento
                label="Modelos de Documento"
                name="listaRegraEscolhaSisdevinModeloDocumento"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteTagSerie
                label="Séries"
                name="listaRegraEscolhaSisdevinSerie"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>

          <Row>
            <Col lg={4} md={6} sm={12} className="div-checkbox">
              <div className="checkbox-container">
                <InputCheckbox
                  label="Movimentar Sisdevin"
                  name="regraEscolhaSisdevinResultado.movimentarSisdevin"
                  disabled={
                    regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                    tipoUsuario !== 'Elo Gestor'
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={5} md={5} sm={5}>
              <Select
                label="Operação (Engarrafados)"
                name="regraEscolhaSisdevinResultado.operacaoEngarrafados"
                options={FormatarEnum({
                  enumObj: SisDevinTipoOperacaoEngarrafadosEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
            <Col lg={5} md={5} sm={5}>
              <Select
                label="Operação (Cultivar)"
                name="regraEscolhaSisdevinResultado.operacaoCultivar"
                options={FormatarEnum({
                  enumObj: SisDevinTipoOperacaoCultivarEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={5} md={5} sm={5}>
              <Select
                label="Operação (Granel)"
                name="regraEscolhaSisdevinResultado.operacaoGranel"
                options={FormatarEnum({
                  enumObj: SisDevinTipoOperacaoGranelEnum,
                  nullavel: true,
                })}
                onChange={() => {
                  refresh();
                }}
                disabled={
                  regraEscolhaTipo === RegraEscolhaTipoEnum.padrao &&
                  tipoUsuario !== 'Elo Gestor'
                }
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
