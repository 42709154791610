import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/nota-fiscal-saida/obter-dados-padrao';

interface INotaFiscalSaidaObterDadosPadraoComunicadorIndex {
  params?: {
    idModeloDocumento?: string;
  };
}

class NotaFiscalSaidaObterDadosPadraoComunicador {
  public async show(
    params: INotaFiscalSaidaObterDadosPadraoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new NotaFiscalSaidaObterDadosPadraoComunicador();
