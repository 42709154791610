import { TipoContaAdiantamentoPrevisaoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/contas-parcela-reversao';

interface IContasParcelaReversaoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
    tipoContaAdiantamentoPrevisao: TipoContaAdiantamentoPrevisaoEnum;
  };
}

interface IContasParcelaReversaoComunicadorShow {
  id: string;
  params?: any;
}

interface IContasParcelaReversaoComunicadorStore {
  params: any;
}

interface IContasParcelaReversaoComunicadorUpdate {
  params: any;
}

interface IContasParcelaReversaoComunicadorDeletar {
  id: string;
}

class ContasParcelaReversaoComunicador {
  public async index(
    params: IContasParcelaReversaoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IContasParcelaReversaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });
    return response.data;
  }

  public async store(
    params: IContasParcelaReversaoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IContasParcelaReversaoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}`, params.params);

    return response.data;
  }

  public async delete(
    params: IContasParcelaReversaoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ContasParcelaReversaoComunicador();
