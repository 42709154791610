"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var VeiculoCodigoCorEnum;
(function (VeiculoCodigoCorEnum) {
    VeiculoCodigoCorEnum["amarelo"] = "01 - Amarelo";
    VeiculoCodigoCorEnum["azul"] = "02 - Azul";
    VeiculoCodigoCorEnum["bege"] = "03 - Bege";
    VeiculoCodigoCorEnum["branca"] = "04 - Branca";
    VeiculoCodigoCorEnum["cinza"] = "05 - Cinza";
    VeiculoCodigoCorEnum["dourada"] = "06 - Dourada";
    VeiculoCodigoCorEnum["grena"] = "07 - Gren\u00E1";
    VeiculoCodigoCorEnum["laranja"] = "08 - Laranja";
    VeiculoCodigoCorEnum["marrom"] = "09 - Marrom";
    VeiculoCodigoCorEnum["prata"] = "10 - Prata";
    VeiculoCodigoCorEnum["preta"] = "11 - Preta";
    VeiculoCodigoCorEnum["rosa"] = "12 - Rosa,";
    VeiculoCodigoCorEnum["roxa"] = "13 - Roxa";
    VeiculoCodigoCorEnum["verde"] = "14 - Verde";
    VeiculoCodigoCorEnum["vermelha"] = "15 - Vermelha";
    VeiculoCodigoCorEnum["fantasia"] = "16 - Fantasia";
})(VeiculoCodigoCorEnum || (VeiculoCodigoCorEnum = {}));
exports.default = VeiculoCodigoCorEnum;
