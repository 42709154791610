import api from '../../../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/estoque/lote-auto-complete';

interface ILoteAutoCompleteComunicadorIndex {
  params: any;
}

class LoteAutoCompleteComunicador {
  public async index(params: ILoteAutoCompleteComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new LoteAutoCompleteComunicador();
