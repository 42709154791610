import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import InputPermissoes from './InputPermissoes';

const PermissoesTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col md={12}>
            <InputPermissoes
              name="listaPermissao"
              validarSeMudou={() => {
                return false;
              }}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PermissoesTab;
