const MargensPorClientePlanilha = [
  {
    titulo: 'Codigo do Produto',
    valor: 'listaNotaFiscalSaidaItem.produtoCodigo',
  },

  {
    titulo: 'Nome do Produto',
    valor: 'listaNotaFiscalSaidaItem.produtoDescricao',
  },

  {
    titulo: 'Data Hora Emissao',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaDataHoraEmissao',
  },

  {
    titulo: 'Numero do Orçamento',
    valor: 'listaNotaFiscalSaidaItem.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaNotaFiscalSaidaItem.pessoaCodigo',
  },

  {
    titulo: 'Nome do Cliente',
    valor: 'listaNotaFiscalSaidaItem.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Unidade de Medida',
    valor: 'listaNotaFiscalSaidaItem.produtoUnidadeMedidaSigla',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaNotaFiscalSaidaItem.valorQuantidade',
  },

  {
    titulo: 'Valor Unitario',
    valor: 'listaNotaFiscalSaidaItem.valorUnitario',
  },

  {
    titulo: 'Valor Subtotal',
    valor: 'listaNotaFiscalSaidaItem.valorSubtotal',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalSaidaItem.valorTotal',
  },

  {
    titulo: 'Valor Unitario Custo',
    valor: 'listaNotaFiscalSaidaItem.valorUnitarioCusto',
  },
  {
    titulo: 'Valor Total Custo',
    valor: 'listaNotaFiscalSaidaItem.valorTotalCusto',
  },
  {
    titulo: 'Valor Impostos Outros',
    valor: 'listaNotaFiscalSaidaItem.valorImpostosOutros',
  },
  {
    titulo: 'Valor Margem',
    valor: 'listaNotaFiscalSaidaItem.valorMargem',
  },
  {
    titulo: 'Percentual Margem',
    valor: 'listaNotaFiscalSaidaItem.percentualMargem',
  },
];

export default MargensPorClientePlanilha;
