"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var OrigemValorBaseTabelaPrecoEnum;
(function (OrigemValorBaseTabelaPrecoEnum) {
    OrigemValorBaseTabelaPrecoEnum["precoVendaCadastroProdutos"] = "Pre\u00E7o Venda do Cadastro de Produtos";
    OrigemValorBaseTabelaPrecoEnum["custoUltimaCompra"] = "Custo \u00DAltima Compra";
    OrigemValorBaseTabelaPrecoEnum["custoMedio"] = "Custo M\u00E9dio";
    OrigemValorBaseTabelaPrecoEnum["custoPadraoProduto"] = "Custo Padr\u00E3o do Produto";
    OrigemValorBaseTabelaPrecoEnum["custoReposicao"] = "Custo Reposi\u00E7\u00E3o";
})(OrigemValorBaseTabelaPrecoEnum || (OrigemValorBaseTabelaPrecoEnum = {}));
exports.default = OrigemValorBaseTabelaPrecoEnum;
