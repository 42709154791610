import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 5px;
  }

  label {
    margin: 0 0 0 5px;
  }

  input {
    height: 20px;
    width: auto;
    margin: 0;
  }
`;
