import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/documentos-eletronicos/importacao-xml-emitidos/notas-servicos-emitidas/salvar-importado';

interface IImportacaoXmlServicosEmitidosSalvarImportadoComunicadorStore {
  params: any;
}

class ImportacaoXmlServicosEmitidosSalvarImportadoComunicador {
  public async store(
    params: IImportacaoXmlServicosEmitidosSalvarImportadoComunicadorStore
  ): Promise<{
    id: string;
    listaValor: string[];
    listaMensagemSucesso: string[];
    listaMensagemAviso: string[];
    listaMensagemErro: string[];
  }> {
    const { params: body } = params;

    const response = await api.post(rota, body);

    return response.data;
  }
}

export default new ImportacaoXmlServicosEmitidosSalvarImportadoComunicador();
