import React from 'react';
import ListaProvider from '../../../../Componentes/Lista/ListaProvider';
import SisdevinEstoqueMovimentoHook from './Hooks';
import SisdevinEstoqueMovimentoLista from './Lista';

const SisdevinEstoqueMovimentoListagem: React.FC = () => {
  return (
    <ListaProvider>
      <SisdevinEstoqueMovimentoHook>
        <SisdevinEstoqueMovimentoLista />
      </SisdevinEstoqueMovimentoHook>
    </ListaProvider>
  );
};

export default SisdevinEstoqueMovimentoListagem;
