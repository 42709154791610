import {
  FormatarDataHoraParaPtBr,
  IOrdemProducaoFiltroRelatorioBackend,
  IOrdemProducaoFiltroRelatorioFrontend,
  IOrdemProducaoResumoProdutosProduzidosCabecalhoRelatorio,
  IOrdemProducaoResumoProdutosProduzidosRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import OrdemProducaoResumoProdutosProduzidosPDF from './OrdemProducaoResumoProdutosProduzidosPDF';
import OrdemProducaoResumoProdutosProduzidosPlanilha from './OrdemProducaoResumoProdutosProduzidosPlanilha';
import OrdemProducaoResumoProdutoProduzidosRelatorioComunicador from '../../../../../../../Comunicador/Manufatura/Relatorios/Producao/OrdemProducao/OrdemProducaoResumoProdutoProduzidosRelatorio/OrdemProducaoResumoProdutoProduzidosRelatorioComunicador';

interface IRelatorios {
  handleExportCSV(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const OrdemProducaoResumoProdutosProduzidosRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IOrdemProducaoFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IOrdemProducaoResumoProdutosProduzidosRelatorio | null> => {
    const filtroBack: IOrdemProducaoFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao = filtros.listaSituacao.listaValor.map(
        (value) => value.descricao
      );
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupoProdutoServico =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = filtros.tipoData;
    }

    const response =
      await OrdemProducaoResumoProdutoProduzidosRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IOrdemProducaoResumoProdutosProduzidosRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValorQuantidade: Number(
            dados.valores.somatorioValorQuantidade
          ).FormatarParaPtBr({
            maximoCasasDecimais: 4,
            minimoCasasDecimais: 4,
          }),
        };

        const listaOrdemProducao = dados.listaOrdemProducao.map((ordem) => {
          return {
            ...ordem,
            dataHoraInicio: FormatarDataHoraParaPtBr(ordem.dataHoraInicio),
            dataHoraTermino: FormatarDataHoraParaPtBr(ordem.dataHoraTermino),
            dataLancamento: FormatarDataHoraParaPtBr(ordem.dataLancamento),
            quantidade: Number(ordem.quantidade).FormatarParaPtBr({
              maximoCasasDecimais: 4,
              minimoCasasDecimais: 4,
            }),
          };
        });

        return {
          ...dados,
          valores,
          listaOrdemProducao,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IOrdemProducaoFiltroRelatorioFrontend,
    cabecalho: IOrdemProducaoResumoProdutosProduzidosCabecalhoRelatorio
  ): IOrdemProducaoResumoProdutosProduzidosCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (filtros.listaGrupo && filtros.listaGrupo.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGrupo.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.tipoData) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoData = filtros.tipoData;
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: OrdemProducaoResumoProdutosProduzidosPlanilha,
      nomeArquivo: 'OrdemProducaoResumoProdutosProduzidos',
      valores: dados.listaDados,
      unwind: ['listaOrdemProducao'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: OrdemProducaoResumoProdutosProduzidosPlanilha,
      nomeArquivo: 'OrdemProducaoResumoProdutosProduzidos',
      valores: dados.listaDados as any,
      unwind: ['listaOrdemProducao'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemProducaoResumoProdutosProduzidosPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IOrdemProducaoFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <OrdemProducaoResumoProdutosProduzidosPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default OrdemProducaoResumoProdutosProduzidosRelatorio();
