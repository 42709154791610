"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var RegimeTributarioPessoaEnum;
(function (RegimeTributarioPessoaEnum) {
    RegimeTributarioPessoaEnum["mei"] = "MEI";
    RegimeTributarioPessoaEnum["simplesNacional"] = "Simples Nacional";
    RegimeTributarioPessoaEnum["regimeNormal"] = "Regime Normal";
})(RegimeTributarioPessoaEnum || (RegimeTributarioPessoaEnum = {}));
exports.default = RegimeTributarioPessoaEnum;
