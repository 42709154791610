import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque-saldo-custo-conferencia-bloco-h-notas';

interface IEstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioComunicador {
  public async index(
    params: IEstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoComCustoConferenciaBlocoHComNotasRelatorioComunicador();
