import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import InputCheckbox from '../../../../../../Componentes/Inputs/InputCheckbox';

const XmlPdfTerceiroTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="xmlNfeTerceiros"
                label="Xml NF-e Terceiro (Autorizados/Denegados)"
                placeholder="Xml Nfe Terceiro"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="xmlCteTerceiros"
                label="Xml CT-e (Autorizados/Denegados)"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="danfeNfeTerceiros"
                label="Danfe NF-e (PDF)"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="dacteCteTerceiros"
                label="Dacte CT-e (PDF)"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="incluirNfeCanceladasTerceiros"
                label="Incluir NF-e Canceladas (Canceladas/Cartas de Correção)"
              />
            </div>
          </Col>
          <Col lg={6} md={4} sm={6}>
            <div className="checkbox-container">
              <InputCheckbox
                name="incluirCteCanceladasTerceiros"
                label="Incluir CT-e Cancelados"
              />
            </div>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default XmlPdfTerceiroTab;
