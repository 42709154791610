import React, { useEffect, useMemo, useState } from 'react';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import { ItemCofinsTabHook } from './ItemCofinsTabHook';
import { ItemIcmsOperacaoInterestadualTabHook } from './ItemIcmsOperacaoInterestadualTabHook';
import { ItemIcmsTabHook } from './ItemIcmsTabHook';
import { ItemIpiTabHook } from './ItemIpiTabHook';
import { ItemPisTabHook } from './ItemPisTabHook';
import { ItemTabHook, UseItemTab } from './ItemTabHook';
import { ListaOrcamentoDraggableHook } from './ListaOrcamentoDraggableHook';
import { ItemNavegacaoHook } from './ItemNavegacaoHook';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

const ItensTabHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ItemIpiTabHook>
      <ItemPisTabHook>
        <ItemCofinsTabHook>
          <ItemIcmsTabHook>
            <ItemIcmsOperacaoInterestadualTabHook>
              <ItemTabHook>{children}</ItemTabHook>
            </ItemIcmsOperacaoInterestadualTabHook>
          </ItemIcmsTabHook>
        </ItemCofinsTabHook>
      </ItemPisTabHook>
    </ItemIpiTabHook>
  );
};

const ItemMesclarFormHook: React.FC<IPadraoProps> = ({ children }) => {
  const [atualizarCampos, setAtualizarCampos] = useState<any>();

  const AtualizarCamposHook = useMemo(() => {
    return () => {
      const params = UseItemTab();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        setAtualizarCampos(() => params.atualizarCampos);
      }, [params.atualizarCampos]);

      return <></>;
    };
  }, []);

  return (
    <FormHook atualizarCampos={atualizarCampos}>
      <ItensTabHook>
        <AtualizarCamposHook />
        {children}
      </ItensTabHook>
    </FormHook>
  );
};

const ItemHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <ItemMesclarFormHook>
        <ListaOrcamentoDraggableHook>
          <ItemNavegacaoHook>{children}</ItemNavegacaoHook>
        </ListaOrcamentoDraggableHook>
      </ItemMesclarFormHook>
    </RotasHook>
  );
};

export default ItemHook;
