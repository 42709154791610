import React, { createContext, useCallback, useContext } from 'react';
import {
  IImportacaoXmlEmitidosListaDadosImpressao,
  IImportacaoXmlCancelamentoCartaCorrecaoListaDadosImpressao,
  TipoEventoNfeEnum,
  IImportacaoXmlTerceiroListaDadosImpressao,
} from '@elogestor/util';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';
import TratarInfo from '../../../Util/Info/TratarInfo';
import TratarSucesso from '../../../Util/Sucesso/TratarSucesso';
import ImportacaoXmlConhecimentosEmitidosUploadPdfComunicador from '../../../Comunicador/Fiscal/DocumentosEletronicos/ImportacaoXmlEmitidos/ConhecimentosEmitidos/ImportacaoXmlConhecimentosEmitidosUploadPdfComunicador';
import ImportacaoXmlEmitidosUploadPdfComunicador from '../../../Comunicador/Fiscal/DocumentosEletronicos/ImportacaoXmlEmitidos/NotasEmitidas/ImportacaoXmlEmitidosUploadPdfComunicador';
import TratarErros from '../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../Util/Toasts/ToastSucesso';
import ImpressaoNotaFiscal from '../NFe/Impressao/index';
import ImportacaoXmlCancelamentoCartaoCorrecaoUploadPdfComunicador from '../../../Comunicador/Fiscal/DocumentosEletronicos/ImportacaoXmlEmitidos/CancelamentoCartaCorrecao/ImportacaoXmCancelamentoCartaCorrecaoUploadPdfComunicador';
import ImpressaoCartaCorrecao from '../NFe/Eventos/CartaCorrecao/Impressao/index';
import ImpressaoConhecimentoTransporteRodoviario from '../CTe/ImpressaoModalRodoviario/index';
import ImpressaoConhecimentoTransporteAereo from '../CTe/ImpressaoModalAereo/index';
import SalvarPDFComunicador from '../../../Comunicador/DocumentosEletronicos/SalvarPDF/Comunicador/SalvarPDFComunicador';
import ImportacaoXmlTerceiroUploadPdfComunicador from '../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ImportacaoXmlTerceiro/Comunicador/ImportacaoXmlTerceiroUploadPdfComunicador';
import ConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicador from '../../../Comunicador/Transporte/ConhecimentoTransporte/Transmissao/Evento/Impressao/CartaCorrecao/ConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicador';
import ConhecimentoTransporteImpressaoCartaCorrecao from '../CTe/Eventos/CartaCorrecao/Impressao';

interface ISalvarPDF {
  salvarTudo: boolean;
  exibirMensagens: boolean;

  dataEmissaoInicial?: Date;
  dataEmissaoFinal?: Date;

  notaFiscalSaidaPdf?: boolean;
  notaFiscalSaidaEventoPdf?: boolean;

  notaFiscalEntradaPropriaPdf?: boolean;
  notaFiscalEntradaPropriaEventoPdf?: boolean;

  notaFiscalEntradaTerceiroPdf?: boolean;

  conhecimentoTransportePdf?: boolean;
  conhecimentoTransporteEventoPdf?: boolean;

  conhecimentoTransporteTerceiroPdf?: boolean;
}

interface ISalvarPDFContext {
  salvarPDF: (options: ISalvarPDF) => Promise<void>;
}

const SalvarPDFContext = createContext({} as ISalvarPDFContext);

const SalvarPDFHook: React.FC<IPadraoProps> = ({ children }) => {
  const handleSalvarPDF = useCallback(async (options: ISalvarPDF) => {
    try {
      const response = await SalvarPDFComunicador.index({
        params: {
          salvarTudo: options?.salvarTudo,
          dataEmissaoInicial: options?.dataEmissaoInicial,
          dataEmissaoFinal: options?.dataEmissaoFinal,
          notaFiscalSaidaPdf: options?.notaFiscalSaidaPdf,
          notaFiscalSaidaEventoPdf: options?.notaFiscalSaidaEventoPdf,
          notaFiscalEntradaPropriaPdf: options?.notaFiscalEntradaPropriaPdf,
          notaFiscalEntradaPropriaEventoPdf:
            options?.notaFiscalEntradaPropriaEventoPdf,
          notaFiscalEntradaTerceiroPdf: options?.notaFiscalEntradaTerceiroPdf,
          conhecimentoTransportePdf: options?.conhecimentoTransportePdf,
          conhecimentoTransporteEventoPdf:
            options?.conhecimentoTransporteEventoPdf,
          conhecimentoTransporteTerceiroPdf:
            options?.conhecimentoTransporteTerceiroPdf,
        },
      });

      // #region Fazer Upload dos PDF Nota Fiscal Emitida

      if (
        options?.exibirMensagens &&
        response.notaFiscalEmitida &&
        response.notaFiscalEmitida.listaMensagemErro
      ) {
        TratarInfo(response.notaFiscalEmitida.listaMensagemErro);
      }
      if (
        options?.exibirMensagens &&
        response.notaFiscalEmitida &&
        response.notaFiscalEmitida.listaMensagemSucesso
      ) {
        TratarSucesso(response.notaFiscalEmitida.listaMensagemSucesso);
      }

      if (
        response.notaFiscalEmitida &&
        response.notaFiscalEmitida.listaImpressao &&
        response.notaFiscalEmitida.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.notaFiscalEmitida.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.notaFiscalEmitida.listaImpressao.length) {
            fim = response.notaFiscalEmitida.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlEmitidosListaDadosImpressao =
              response.notaFiscalEmitida.listaImpressao[i];

            const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
              impressao.dados
            );

            formData.append(
              'bloblPdf',
              blobPdf as any,
              `${impressao.chaveAcesso}#ambiente:${impressao.ambiente}#tipoEntradaSaida:${impressao.tipoEntradaSaida}`
            );
          }

          await ImportacaoXmlEmitidosUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF Nota Fiscal Emitida

      // #region Fazer Upload dos PDF Nota Fiscal Emitida Evento

      if (
        options?.exibirMensagens &&
        response.notaFiscalEmitidaEvento &&
        response.notaFiscalEmitidaEvento.listaMensagemErro
      ) {
        TratarInfo(response.notaFiscalEmitidaEvento.listaMensagemErro);
      }
      if (
        options?.exibirMensagens &&
        response.notaFiscalEmitidaEvento &&
        response.notaFiscalEmitidaEvento.listaMensagemSucesso
      ) {
        TratarSucesso(response.notaFiscalEmitidaEvento.listaMensagemSucesso);
      }

      if (
        response.notaFiscalEmitidaEvento &&
        response.notaFiscalEmitidaEvento.listaImpressao &&
        response.notaFiscalEmitidaEvento.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.notaFiscalEmitidaEvento.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.notaFiscalEmitidaEvento.listaImpressao.length) {
            fim = response.notaFiscalEmitidaEvento.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlCancelamentoCartaCorrecaoListaDadosImpressao =
              response.notaFiscalEmitidaEvento.listaImpressao[i];

            const blobPdf = await ImpressaoCartaCorrecao.handleObterBlobPdf(
              impressao.dados
            );
            formData.append(
              'bloblPdf',
              blobPdf as any,
              `${impressao.chaveAcesso}#ambiente:${impressao.ambiente}#tipoEntradaSaida:${impressao.tipoEntradaSaida}#tipoEvento:${TipoEventoNfeEnum.cartaCorrecao}#numeroSequencialEvento:${impressao.numeroSequencialEvento}`
            );
          }

          await ImportacaoXmlCancelamentoCartaoCorrecaoUploadPdfComunicador.update(
            { params: formData }
          );
        }
      }

      // #endregion Fazer Upload dos PDF Nota Fiscal Emitida Evento

      // #region Fazer Upload dos PDF Nota Fiscal Terceiro

      if (
        options?.exibirMensagens &&
        response.notaFiscalTerceiro &&
        response.notaFiscalTerceiro.listaMensagemErro
      ) {
        TratarInfo(response.notaFiscalTerceiro.listaMensagemErro);
      }
      if (
        options?.exibirMensagens &&
        response.notaFiscalTerceiro &&
        response.notaFiscalTerceiro.listaMensagemSucesso
      ) {
        TratarSucesso(response.notaFiscalTerceiro.listaMensagemSucesso);
      }

      if (
        response.notaFiscalTerceiro &&
        response.notaFiscalTerceiro.listaImpressao &&
        response.notaFiscalTerceiro.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.notaFiscalTerceiro.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (fim > response.notaFiscalTerceiro.listaImpressao.length) {
            fim = response.notaFiscalTerceiro.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlTerceiroListaDadosImpressao =
              response.notaFiscalTerceiro.listaImpressao[i];

            const blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
              impressao.dados
            );

            formData.append('bloblPdf', blobPdf as any, impressao.chaveAcesso);
          }

          await ImportacaoXmlTerceiroUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF Nota Fiscal Terceiro

      // #region Fazer Upload dos PDF Conhecimento Transporte Emitido

      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteEmitido &&
        response.conhecimentoTransporteEmitido.listaMensagemErro
      ) {
        TratarInfo(response.conhecimentoTransporteEmitido.listaMensagemErro);
      }
      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteEmitido &&
        response.conhecimentoTransporteEmitido.listaMensagemSucesso
      ) {
        TratarSucesso(
          response.conhecimentoTransporteEmitido.listaMensagemSucesso
        );
      }

      if (
        response.conhecimentoTransporteEmitido &&
        response.conhecimentoTransporteEmitido.listaImpressao &&
        response.conhecimentoTransporteEmitido.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.conhecimentoTransporteEmitido.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (
            fim > response.conhecimentoTransporteEmitido.listaImpressao.length
          ) {
            fim = response.conhecimentoTransporteEmitido.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlEmitidosListaDadosImpressao =
              response.conhecimentoTransporteEmitido.listaImpressao[i];

            let blobPdf: Blob | undefined;
            if (impressao.dados.rodo) {
              blobPdf =
                await ImpressaoConhecimentoTransporteRodoviario.handleObterBlobPdf(
                  impressao.dados
                );
            } else {
              blobPdf =
                await ImpressaoConhecimentoTransporteAereo.handleObterBlobPdf(
                  impressao.dados
                );
            }

            formData.append(
              'bloblPdf',
              blobPdf as any,
              `${impressao.chaveAcesso}#ambiente:${impressao.ambiente}#tipoEntradaSaida:${impressao.tipoEntradaSaida}`
            );
          }

          await ImportacaoXmlConhecimentosEmitidosUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF Conhecimento Transporte Emitido

      // #region Fazer Upload dos PDF Conhecimento Transporte Emitida Evento

      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteEmitidoEvento &&
        response.conhecimentoTransporteEmitidoEvento.listaMensagemErro
      ) {
        TratarInfo(
          response.conhecimentoTransporteEmitidoEvento.listaMensagemErro
        );
      }
      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteEmitidoEvento &&
        response.conhecimentoTransporteEmitidoEvento.listaMensagemSucesso
      ) {
        TratarSucesso(
          response.conhecimentoTransporteEmitidoEvento.listaMensagemSucesso
        );
      }

      if (
        response.conhecimentoTransporteEmitidoEvento &&
        response.conhecimentoTransporteEmitidoEvento.listaImpressao &&
        response.conhecimentoTransporteEmitidoEvento.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.conhecimentoTransporteEmitidoEvento.listaImpressao.length /
            50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (
            fim >
            response.conhecimentoTransporteEmitidoEvento.listaImpressao.length
          ) {
            fim =
              response.conhecimentoTransporteEmitidoEvento.listaImpressao
                .length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlCancelamentoCartaCorrecaoListaDadosImpressao =
              response.conhecimentoTransporteEmitidoEvento.listaImpressao[i];

            const blobPdf =
              await ConhecimentoTransporteImpressaoCartaCorrecao.handleObterBlobPdf(
                impressao.dados
              );

            formData.append(
              'bloblPdf',
              blobPdf as any,
              `${impressao.chaveAcesso}#ambiente:${impressao.ambiente}#numeroSequencial:${impressao.numeroSequencialEvento}`
            );
          }

          await ConhecimentoTransporteTransmissaoEventoCartaCorrecaoUploadPdfComunicador.update(
            { params: formData }
          );
        }
      }

      // #endregion Fazer Upload dos PDF Conhecimento Transporte Emitida Evento

      // #region Fazer Upload dos PDF Conhecimento Transporte Terceiro

      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteTerceiro &&
        response.conhecimentoTransporteTerceiro.listaMensagemErro
      ) {
        TratarInfo(response.conhecimentoTransporteTerceiro.listaMensagemErro);
      }
      if (
        options?.exibirMensagens &&
        response.conhecimentoTransporteTerceiro &&
        response.conhecimentoTransporteTerceiro.listaMensagemSucesso
      ) {
        TratarSucesso(
          response.conhecimentoTransporteTerceiro.listaMensagemSucesso
        );
      }

      if (
        response.conhecimentoTransporteTerceiro &&
        response.conhecimentoTransporteTerceiro.listaImpressao &&
        response.conhecimentoTransporteTerceiro.listaImpressao.length > 0
      ) {
        const quantidadeVezesInserir = Math.ceil(
          response.conhecimentoTransporteTerceiro.listaImpressao.length / 50
        );

        for (let index = 0; index < quantidadeVezesInserir; index++) {
          const inicio = index * 50;
          let fim = inicio + 50;

          if (
            fim > response.conhecimentoTransporteTerceiro.listaImpressao.length
          ) {
            fim = response.conhecimentoTransporteTerceiro.listaImpressao.length;
          }

          const formData = new FormData();

          for (let i = inicio; i < fim; i++) {
            const impressao: IImportacaoXmlTerceiroListaDadosImpressao =
              response.conhecimentoTransporteTerceiro.listaImpressao[i];

            let blobPdf: Blob | undefined;
            if (impressao.dados.rodo) {
              blobPdf =
                await ImpressaoConhecimentoTransporteRodoviario.handleObterBlobPdf(
                  impressao.dados
                );
            } else {
              blobPdf =
                await ImpressaoConhecimentoTransporteAereo.handleObterBlobPdf(
                  impressao.dados
                );
            }

            formData.append('bloblPdf', blobPdf as any, impressao.chaveAcesso);
          }

          await ImportacaoXmlTerceiroUploadPdfComunicador.update({
            params: formData,
          });
        }
      }

      // #endregion Fazer Upload dos PDF Conhecimento Transporte Terceiro

      ToastSucesso('Registros Atualizados!');
    } catch (error) {
      TratarErros(error);
    }
  }, []);

  return (
    <SalvarPDFContext.Provider
      value={{
        salvarPDF: handleSalvarPDF,
      }}
    >
      {children}
    </SalvarPDFContext.Provider>
  );
};

function UseSalvarPDF(): ISalvarPDFContext {
  const context = useContext(SalvarPDFContext);
  return context;
}

export { SalvarPDFHook, UseSalvarPDF };
