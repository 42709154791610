import {
  FormatarDataHoraParaPtBr,
  INotaFiscalEntradaFiltroRelatorioBackend,
  INotaFiscalEntradaFiltroRelatorioFrontend,
  INotaFiscalEntradaPorFornecedorCabecalhoRelatorio,
  INotaFiscalEntradaPorFornecedorRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoDataEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
} from '@elogestor/util';
import React from 'react';
import NotaFiscalEntradaPorFornecedorRelatorioComunicador from '../../../../../../../Comunicador/Suprimentos/Relatorios/Compras/NotaFiscalEntrada/NotaFiscalEntradaPorFornecedorRelatorio/NotaFiscalEntradaPorFornecedorRelatorioComunicador';
import { empresaLogada } from '../../../../../../../Hooks/Auth';
import CSV from '../../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../../Util/Toasts/ToastInfo';
import NotaFiscalEntradaPorFornecedorPDF from './NotaFiscalEntradaPorFornecedorPDF';
import NotaFiscalEntradaPorFornecedorPlanilha from './NotaFiscalEntradaPorFornecedorPlanilha';

interface IRelatorios {
  handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void>;
  handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const NotaFiscalEntradaPorFornecedorRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<INotaFiscalEntradaPorFornecedorRelatorio | null> => {
    const filtroBack: INotaFiscalEntradaFiltroRelatorioBackend = {
      dataEmissaoInicial: filtros.dataEmissaoInicial,
      dataEmissaoFinal: filtros.dataEmissaoFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaSituacaoItem) {
      filtroBack.listaDescricaoSituacaoItem =
        await filtros.listaSituacaoItem.listaValor.map(
          (value) => value.descricao
        );
    }

    if (filtros?.tipoData) {
      filtroBack.tipoData = StringConverterParaEnum<
        typeof TipoDataEnum,
        TipoDataEnum
      >(TipoDataEnum, String(filtros.tipoData));
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaFornecedor) {
      filtroBack.listaIdFornecedor =
        filtros.listaFornecedor.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaModeloDocumento) {
      filtroBack.listaIdModeloDocumento =
        filtros.listaModeloDocumento.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoNotaFiscalEntrada) {
      filtroBack.tipoNotaFiscalEntrada = StringConverterParaEnum<
        typeof TipoPropriaTerceiroNotaFiscalEntradaEnum,
        TipoPropriaTerceiroNotaFiscalEntradaEnum
      >(
        TipoPropriaTerceiroNotaFiscalEntradaEnum,
        String(filtros.tipoNotaFiscalEntrada)
      );
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupo =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaOperacaoFiscal) {
      filtroBack.listaIdOperacaoFiscal =
        filtros.listaOperacaoFiscal.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaEmpresas) {
      filtroBack.listaIdEmpresas = filtros.listaEmpresas.listaValor.map(
        (value: any) => value.id
      );
    }

    const response =
      await NotaFiscalEntradaPorFornecedorRelatorioComunicador.index({
        params: { filtro: filtroBack },
      });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as INotaFiscalEntradaPorFornecedorRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dados) => {
        const valores = {
          ...dados.valores,
          somatorioValorSubtotal: Number(
            dados.valores.somatorioValorSubtotal
          ).FormatarParaPtBr(),
          somatorioValorTotal: Number(
            dados.valores.somatorioValorTotal
          ).FormatarParaPtBr(),
          somatorioQuantidade: Number(
            dados.valores.somatorioQuantidade
          ).FormatarParaPtBr({
            maximoCasasDecimais: 4,
            minimoCasasDecimais: 4,
          }),
          total: Number(dados.valores.total).FormatarParaPtBr(),
        };
        const listaNotaFiscalEntradaItem = dados.listaNotaFiscalEntradaItem.map(
          (notaFiscal) => {
            return {
              ...notaFiscal,
              valorQuantidade: Number(
                notaFiscal.valorQuantidade
              ).FormatarParaPtBr({
                maximoCasasDecimais: 4,
                minimoCasasDecimais: 4,
              }),
              valorUnitario: Number(notaFiscal.valorUnitario).FormatarParaPtBr({
                maximoCasasDecimais: 4,
                minimoCasasDecimais: 4,
              }),
              valorSubtotal: Number(
                notaFiscal.valorSubtotal
              ).FormatarParaPtBr(),
              valorTotal: Number(notaFiscal.valorSubtotal).FormatarParaPtBr(),

              notaFiscalEntradaDataHoraEmissao: FormatarDataHoraParaPtBr(
                notaFiscal.notaFiscalEntradaDataHoraEmissao
              ),
            };
          }
        );

        return {
          ...dados,
          valores,
          listaNotaFiscalEntradaItem,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
      somatorioGeralQuantidade: Number(
        retorno.somatorioGeralQuantidade
      ).FormatarParaPtBr({ maximoCasasDecimais: 4, minimoCasasDecimais: 4 }),
      somatorioGeralValorSubtotal: Number(
        retorno.somatorioGeralValorSubtotal
      ).FormatarParaPtBr(),
      somatorioGeralValorTotal: Number(
        retorno.somatorioGeralValorTotal
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend,
    cabecalho: INotaFiscalEntradaPorFornecedorCabecalhoRelatorio
  ): INotaFiscalEntradaPorFornecedorCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataEmissaoInicial || filtros.dataEmissaoFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataEmissaoInicial = '--/--/--';
      cabecalho.filtroDataEmissaoFinal = '--/--/--';

      if (filtros.dataEmissaoInicial) {
        cabecalho.filtroDataEmissaoInicial = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoInicial
        );
      }

      if (filtros.dataEmissaoFinal) {
        cabecalho.filtroDataEmissaoFinal = FormatarDataHoraParaPtBr(
          filtros.dataEmissaoFinal
        );
      }
    }
    if (filtros.tipoNotaFiscalEntrada) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoNotaFiscalEntrada = filtros.tipoNotaFiscalEntrada;
    }

    if (
      filtros.listaSituacaoItem &&
      filtros.listaSituacaoItem.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroSituacaoItem = filtros.listaSituacaoItem.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaModeloDocumento &&
      filtros.listaModeloDocumento.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroModeloDocumento = filtros.listaModeloDocumento.listaValor
        .map((valor: any) => {
          return valor.codigoDescricao;
        })
        .join(', ');
    }

    if (
      filtros.listaFornecedor &&
      filtros.listaFornecedor.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroFornecedor = filtros.listaFornecedor.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocialCodigo;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (filtros.listaGrupo && filtros.listaGrupo.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupo = filtros.listaGrupo.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaOperacaoFiscal &&
      filtros.listaOperacaoFiscal.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroOperacaoFiscal = filtros.listaOperacaoFiscal.listaValor
        .map((valor: any) => {
          return valor.cfopDescricao;
        })
        .join(', ');
    }

    if (filtros.listaEmpresas && filtros.listaEmpresas.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEmpresas = filtros.listaEmpresas.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: NotaFiscalEntradaPorFornecedorPlanilha,
      nomeArquivo: 'NotaFiscalEntradaPorFornecedor',
      valores: dados.listaDados,
      unwind: ['listaNotaFiscalEntradaItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: NotaFiscalEntradaPorFornecedorPlanilha,
      nomeArquivo: 'NotaFiscalEntradaPorFornecedor',
      valores: dados.listaDados as any,
      unwind: ['listaNotaFiscalEntradaItem'],
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <NotaFiscalEntradaPorFornecedorPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: INotaFiscalEntradaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <NotaFiscalEntradaPorFornecedorPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default NotaFiscalEntradaPorFornecedorRelatorio();
