import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PedidoVendaComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import { RegimeTributarioHook } from '../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';
import { NotaFiscalSaidaImportacaoPedidoVendaHook } from '../../../NotaFiscalSaidaImportacaoPedidoVendaHook';
import { PedidoVendaImportacaoOrcamentoHook } from '../../../PedidoVendaImportacaoOrcamentoHook';
import { PedidoVendaAtualizarSituacaoHook } from './AtualizarSitucaoHook';
import { PedidoVendaDuplicarHook } from './DuplicarHook';
import FormHook from './FormHook';
import { PedidoVendaHook } from './PedidoVendaHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import { SituacaoBloquearCamposHook } from './SituacaoBloquearCamposHook';

const PedidoVendaHooks: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: PedidoVendaComunicador }}>
      <RotasHook>
        <RegimeTributarioHook>
          <PedidoVendaDuplicarHook>
            <PedidoVendaAtualizarSituacaoHook>
              <PedidoVendaImportacaoOrcamentoHook>
                <NotaFiscalSaidaImportacaoPedidoVendaHook>
                  <FormHook>
                    <SituacaoBloquearCamposHook>
                      <PedidoVendaHook>
                        <SubHeaderHook>{children}</SubHeaderHook>
                      </PedidoVendaHook>
                    </SituacaoBloquearCamposHook>
                  </FormHook>
                </NotaFiscalSaidaImportacaoPedidoVendaHook>
              </PedidoVendaImportacaoOrcamentoHook>
            </PedidoVendaAtualizarSituacaoHook>
          </PedidoVendaDuplicarHook>
        </RegimeTributarioHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default PedidoVendaHooks;
