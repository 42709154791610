import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProdutoImportacaoDeParaManualItem from './ProdutoImportacaoDeParaManualItem';

type Props = {
  listaOpcoesCSV: any[];
  onConfirmarImportacao?: (listaDePara: any[]) => void;
};

const ProdutoImportacaoDeParaManual: React.FC<Props> = ({
  listaOpcoesCSV,
  onConfirmarImportacao,
}) => {
  // #region Opções internas

  const opcoesInternas = [
    {
      id: 0,
      nome: 'ativo',
      tooltip: 'Indica se o produto está ativo ou inativo',
    },
    { id: 1, nome: 'codigo', tooltip: 'Código único que identifica o produto' },
    { id: 2, nome: 'descricao', tooltip: 'Descrição detalhada do produto' },
    {
      id: 3,
      nome: 'tipoProdutoServico',
      tooltip: 'Tipo do produto ou serviço',
    },
    {
      id: 4,
      nome: 'grupoProdutoServico.descricao',
      tooltip: 'Descrição do grupo ao qual o produto pertence',
    },
    {
      id: 5,
      nome: 'fci',
      tooltip: 'Ficha de Conteúdo de Importação (FCI) do produto',
    },
    {
      id: 6,
      nome: 'produtoCusto.tipoCusto',
      tooltip: 'Tipo de custo associado ao produto',
    },
    {
      id: 7,
      nome: 'produtoComercial.valorUnitarioVenda',
      tooltip: 'Valor unitário de venda do produto',
    },
    {
      id: 8,
      nome: 'produtoComercial.comercializado',
      tooltip: 'Indica se o produto está disponível para venda',
    },
    {
      id: 9,
      nome: 'produtoComercial.marcaProduto.descricao',
      tooltip: 'Descrição da marca do produto',
    },
    {
      id: 10,
      nome: 'produtoEstoque.quantidadeEstoqueMinimo',
      tooltip: 'Quantidade mínima em estoque do produto',
    },
    {
      id: 11,
      nome: 'produtoEstoque.quantidadeEstoqueMaximo',
      tooltip: 'Quantidade máxima em estoque do produto',
    },
    {
      id: 12,
      nome: 'produtoEstoque.controlarEstoque',
      tooltip: 'Indica se o produto deve ter o estoque controlado',
    },
    {
      id: 13,
      nome: 'produtoEstoque.unidadeMedidaEstoque.sigla',
      tooltip: 'Sigla da unidade de medida do produto',
    },
    {
      id: 14,
      nome: 'produtoEngenharia.pesoLiquido',
      tooltip: 'Peso líquido do produto',
    },
    {
      id: 15,
      nome: 'produtoEngenharia.pesoBruto',
      tooltip: 'Peso bruto do produto',
    },
    {
      id: 16,
      nome: 'produtoEngenharia.codigoBarras',
      tooltip: 'Código de barras do produto',
    },
    {
      id: 17,
      nome: 'produtoFiscal.ncm.codigo',
      tooltip: 'Código do NCM (Nomenclatura Comum do Mercosul) do produto',
    },
    {
      id: 18,
      nome: 'produtoFiscal.cest.descricao',
      tooltip:
        'Descrição do CEST (Código Especificador da Substituição Tributária) do produto',
    },
    {
      id: 19,
      nome: 'produtoFiscal.spedTipoProduto.codigo',
      tooltip:
        'Código do tipo de produto para o SPED (Sistema Público de Escrituração Digital)',
    },
    {
      id: 20,
      nome: 'produtoFiscal.origemIcms.codigo',
      tooltip:
        'Código da origem do ICMS (Imposto sobre Circulação de Mercadorias e Serviços) do produto',
    },
    {
      id: 21,
      nome: 'produtoCusto.valorUnitarioCustoReposicao',
      tooltip: 'Valor unitário de custo para reposição do produto',
    },
    {
      id: 22,
      nome: 'produtoEstoque.controlarLote',
      tooltip: 'Indica se o produto deve ter o lote controlado',
    },
    {
      id: 23,
      nome: 'produtoEngenharia.codigoReferencia',
      tooltip: 'Código ou referecia do produto',
    },
    {
      id: 24,
      nome: 'produtoFiscal.sujeitoASt',
      tooltip: 'Sujeito a ST.',
    },
  ];

  // #endregion Opções internas

  // #region Estados

  const [itensSelecionados, setItensSelecionados] = useState<any[]>([]);
  const [opcoesCSVFiltradas, setOpcoesCSVFiltradas] = useState<any[]>([]);

  // #endregion Estados

  // #region Funções

  const handleItemSelecionado = (item: any): any => {
    setItensSelecionados((prevItems) => [...prevItems, item]);
  };

  const handleItemRemoval = (item: any): void => {
    const updatedItensSelecionados = itensSelecionados.filter(
      (selectedItem) => {
        return selectedItem.value !== item;
      }
    );
    setItensSelecionados(updatedItensSelecionados);
  };

  useEffect(() => {
    const resposta = listaOpcoesCSV.filter(
      (item) =>
        !itensSelecionados.some((selecionado) => selecionado.value === item)
    );

    setOpcoesCSVFiltradas(resposta);
    onConfirmarImportacao && onConfirmarImportacao(itensSelecionados);
  }, [itensSelecionados, listaOpcoesCSV, onConfirmarImportacao]);

  // #endregion Funções

  return (
    <>
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos aceitos pelo Elo Gestor</b>
          </div>
          <div
            className="form-control"
            style={{
              padding: '10pt 5pt 10pt 5pt',
              border: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b className="fw-bold text-muted">Campos do seu arquivo CSV</b>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          {opcoesInternas.map((itemInterno, i) => (
            <ProdutoImportacaoDeParaManualItem
              key={i}
              listaOpcoesCsv={opcoesCSVFiltradas}
              listaOpcoesCsvIniciais={listaOpcoesCSV}
              tooltip={itemInterno.tooltip}
              opcaoInterna={itemInterno}
              onItemSelecionado={handleItemSelecionado}
              onItemRemoval={handleItemRemoval}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default ProdutoImportacaoDeParaManual;
