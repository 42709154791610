import api from '../../../../../../Comum/Services/Api';

const rota =
  'suprimentos/compras/pedido-compra/impressao/paisagem-previsao-recebimento';

interface IPedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicadorShow {
  id: string;
}

class PedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicador {
  public async show(
    params: IPedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new PedidoCompraPaisagemPrevisaoRecebimentoImpressaoComunicador();
