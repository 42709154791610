import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/log';

interface IImportacaoXmlTerceiroLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IImportacaoXmlTerceiroLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class ImportacaoXmlTerceiroLogComunicador {
  public async index(
    params: IImportacaoXmlTerceiroLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IImportacaoXmlTerceiroLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroLogComunicador();
