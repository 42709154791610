/* eslint-disable no-nested-ternary */
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import {
  ICotacaoCompraItemAtualizarSituacaoLista,
  SituacaoItemEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';

interface IItemAtualizarSituacao {
  item: ICotacaoCompraItemAtualizarSituacaoLista;
  onChange: () => void;
}

export interface IItemAtualizarSituacaoRef {
  setSelecionado(valor: boolean): void;
  handleObterValores(): ICotacaoCompraItemAtualizarSituacaoLista;
}

const ItemAtualizarSituacao: React.ForwardRefRenderFunction<
  IItemAtualizarSituacaoRef,
  IItemAtualizarSituacao
> = ({ item, onChange }, ref) => {
  const selecionadoRef = useRef<HTMLInputElement>(null);
  const valorTotalDivRef = useRef<HTMLDivElement>(null);

  const handleObterValores =
    useCallback((): ICotacaoCompraItemAtualizarSituacaoLista => {
      return {
        ...item,
        selecionado: !!selecionadoRef.current?.checked,
      };
    }, [item]);

  const setSelecionado = useCallback(
    (valor: boolean) => {
      if (selecionadoRef.current) {
        selecionadoRef.current.checked = valor;
        item.selecionado = valor;
      }
    },
    [item]
  );

  useImperativeHandle(ref, () => {
    return {
      handleObterValores,
      setSelecionado,
    };
  });

  const handleAlterarSelecionado = useCallback(
    (valor: boolean) => {
      setSelecionado(valor);

      if (onChange) onChange();
    },
    [onChange, setSelecionado]
  );

  return (
    <tr key={v4()}>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <input
          type="checkbox"
          defaultChecked={item.selecionado}
          ref={selecionadoRef}
          onChange={(event) => {
            handleAlterarSelecionado(event.target.checked);
          }}
        />
      </td>
      <td className="lista-texto">
        <div>{item.produto.codigo}</div>
      </td>
      <td className="lista-texto">
        <div>{item.produto.descricao}</div>
      </td>
      <td className="lista-texto">
        <div>{item.unidadeMedida.sigla}</div>
      </td>
      <td className="lista-valor">
        <div>
          {Number(item.quantidade).FormatarParaPtBr({
            minimoCasasDecimais: 4,
            maximoCasasDecimais: 4,
          })}
        </div>
      </td>
      <td className="lista-valor">
        <div>
          {Number(item.valorUnitario).FormatarParaPtBr({
            minimoCasasDecimais: 10,
            maximoCasasDecimais: 10,
          })}
        </div>
      </td>
      <td className="lista-valor">
        <div ref={valorTotalDivRef}>
          {Number(item.valorTotalProduto).FormatarParaPtBr()}
        </div>
      </td>
      <td className="lista-texto">
        <div
          style={{
            color:
              item.situacao === SituacaoItemEnum.pendente
                ? 'darkgoldenrod'
                : item.situacao === SituacaoItemEnum.aprovado
                  ? 'green'
                  : item.situacao === SituacaoItemEnum.rejeitado
                    ? 'orange'
                    : item.situacao === SituacaoItemEnum.cancelado
                      ? 'red'
                      : 'black',
            fontWeight: 'bold',
          }}
        >
          {item.situacao}
        </div>
      </td>
    </tr>
  );
};

export default forwardRef(ItemAtualizarSituacao);
