import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { IImpressaoEtiquetasProdutosTransferirLista } from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';

interface IAcoesAtualizacaoEstoqueProduto {
  loading: boolean;
  setLoading: (value: boolean) => void;
  possuiItemSelecionado: boolean;
  handleObterItensSelecionados: () => Promise<
    IImpressaoEtiquetasProdutosTransferirLista[]
  >;
  handleSalvar: () => Promise<boolean>;
}

const AcoesAtualizacaoEstoqueProduto: React.FC<
  IAcoesAtualizacaoEstoqueProduto
> = ({
  loading,
  setLoading,
  possuiItemSelecionado,
  handleObterItensSelecionados,
  handleSalvar,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const handleImprimirEtiquetasProdutos = useCallback(async () => {
    try {
      setLoading(true);

      const listaProdutosSelecionados = await handleObterItensSelecionados();

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      window.open('/suprimentos/produtos/impressao-etiquetas-produtos');
      localStorage.setItem(
        '@EloGestorle:etiquetas',
        JSON.stringify(listaProdutosSelecionados)
      );

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleObterItensSelecionados, handleSalvar, setLoading]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading}
          onClick={handleImprimirEtiquetasProdutos}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir Etiquetas Produtos
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesAtualizacaoEstoqueProduto;
