"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var ServicosSefazEnum;
(function (ServicosSefazEnum) {
    ServicosSefazEnum["autorizacao"] = "autorizacao";
    ServicosSefazEnum["retAutorizacao"] = "retAutorizacao";
    ServicosSefazEnum["consultarStatusServico"] = "consultarStatusServico";
    ServicosSefazEnum["evento"] = "recepcaoEvento";
    ServicosSefazEnum["eventoManifestacao"] = "recepcaoEventoManifestacao";
    ServicosSefazEnum["inutilizacao"] = "inutilizacao";
    ServicosSefazEnum["protocolo"] = "consultarProtocolo";
    ServicosSefazEnum["cadastro"] = "consultarCadastro";
    ServicosSefazEnum["distribuicaoDfe"] = "distribuicaoDfe";
})(ServicosSefazEnum || (ServicosSefazEnum = {}));
exports.default = ServicosSefazEnum;
