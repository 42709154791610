import React, { createContext, useCallback, useContext, useState } from 'react';
import { MovimentacaoEntreProdutosTipoEstoqueEnum } from '@elogestor/util';
import ObterValorUnitarioCustoSaidaComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Comunicador/ObterValorUnitarioCustoSaidaComunicador';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface ICarregarCustoProdutoSaida {
  idProduto: string;
  dataMovimento: Date;
  idPessoa?: string;
  indicadorTipoEstoque: MovimentacaoEntreProdutosTipoEstoqueEnum;
}

interface ICustosUnitariosSaida {
  valorUnitarioCustoMedio: number;
  valorUnitarioCustoUltimaCompra: number;
  valorUnitarioCustoContabilIcmsIpi: number;
  valorUnitarioCustoContabilIr: number;
}

interface ICustosTotaisSaida {
  valorTotalCustoMedio: number;
  valorTotalCustoUltimaCompra: number;
  valorTotalCustoContabilIcmsIpi: number;
  valorTotalCustoContabilIr: number;
}

interface ICustosContext {
  handleSetarCustoProdutoSaida(
    data: ICarregarCustoProdutoSaida
  ): Promise<ICustosUnitariosSaida | undefined>;
  handleClearCustoFields(): void;
  handleChangeQuantidadeSaida(
    quantidadeSaida: number,
    custosUnitariosSaidaAtualizados?: ICustosUnitariosSaida
  ): void;
  handleCalcularCustosEntrada(quantidadeDestino: number): void;
  handleClearCustosTotalSaida(): void;
  handleClearCustosEntrada(): void;
}

const CustosContext = createContext({} as ICustosContext);

const CustosHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();
  const [custosUnitariosSaida, setCustosUnitariosSaida] =
    useState<ICustosUnitariosSaida | null>(null);

  const [custosTotaisSaida, setCustosTotaisSaida] =
    useState<ICustosTotaisSaida | null>(null);

  function verificaAusenciaCustos(
    custos: ICustosUnitariosSaida
  ): ICustosUnitariosSaida | undefined {
    let propriedadesSemCusto = 0;

    const keyValue = Object.entries(custos);

    keyValue.forEach(([_, key]) => {
      if (key === 0) propriedadesSemCusto++;
    });

    if (keyValue.length === propriedadesSemCusto) {
      return undefined;
    }

    return custos;
  }

  const handleCalcularCustosEntrada = useCallback(
    (quantidadeDestino: number) => {
      if (!quantidadeDestino || !custosTotaisSaida) return;

      const valorUnitarioMovimentoCustoMedio =
        custosTotaisSaida.valorTotalCustoMedio / quantidadeDestino;

      const valorUnitarioMovimentoCustoUltimaCompra =
        custosTotaisSaida.valorTotalCustoUltimaCompra / quantidadeDestino;

      const valorUnitarioMovimentoCustoContabilIr =
        custosTotaisSaida.valorTotalCustoContabilIr / quantidadeDestino;

      const valorUnitarioMovimentoCustoContabilIcmsIpi =
        custosTotaisSaida.valorTotalCustoContabilIcmsIpi / quantidadeDestino;

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorUnitarioMovimentoCustoMedio',
        valorUnitarioMovimentoCustoMedio
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorUnitarioMovimentoCustoUltimaCompra',
        valorUnitarioMovimentoCustoUltimaCompra
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorUnitarioMovimentoCustoContabilIr',
        valorUnitarioMovimentoCustoContabilIr
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorUnitarioMovimentoCustoContabilIcmsIpi',
        valorUnitarioMovimentoCustoContabilIcmsIpi
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorTotalMovimentoCustoMedio',
        valorUnitarioMovimentoCustoMedio * quantidadeDestino
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorTotalMovimentoCustoUltimaCompra',
        valorUnitarioMovimentoCustoUltimaCompra * quantidadeDestino
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorTotalMovimentoCustoContabilIr',
        valorUnitarioMovimentoCustoContabilIr * quantidadeDestino
      );

      formRef.current?.setFieldValue(
        'produtoDestinoCustos.valorTotalMovimentoCustoContabilIcmsIpi',
        valorUnitarioMovimentoCustoContabilIcmsIpi * quantidadeDestino
      );
    },
    [custosTotaisSaida, formRef]
  );

  const handleCalcularCustoTotalSaida = useCallback(
    (
      quantidadeSaida: number,
      custosUnitariosSaidaAtualizados?: ICustosUnitariosSaida
    ) => {
      if (!custosUnitariosSaidaAtualizados)
        custosUnitariosSaidaAtualizados = custosUnitariosSaida
          ? { ...custosUnitariosSaida }
          : undefined;

      if (!custosUnitariosSaidaAtualizados) return;

      const valorTotalCustoMedio =
        custosUnitariosSaidaAtualizados.valorUnitarioCustoMedio *
        quantidadeSaida;

      const valorTotalCustoUltimaCompra =
        custosUnitariosSaidaAtualizados.valorUnitarioCustoUltimaCompra *
        quantidadeSaida;

      const valorTotalCustoContabilIr =
        custosUnitariosSaidaAtualizados.valorUnitarioCustoContabilIr *
        quantidadeSaida;

      const valorTotalCustoContabilIcmsIpi =
        custosUnitariosSaidaAtualizados.valorUnitarioCustoContabilIcmsIpi *
        quantidadeSaida;

      formRef.current?.setFieldValue(
        'produtoOrigemCustos.valorTotalCustoMedio',
        valorTotalCustoMedio
      );

      formRef.current?.setFieldValue(
        'produtoOrigemCustos.valorTotalCustoUltimaCompra',
        valorTotalCustoUltimaCompra
      );

      formRef.current?.setFieldValue(
        'produtoOrigemCustos.valorTotalCustoContabilIr',
        valorTotalCustoContabilIr
      );

      formRef.current?.setFieldValue(
        'produtoOrigemCustos.valorTotalCustoContabilIcmsIpi',
        valorTotalCustoContabilIcmsIpi
      );

      setCustosTotaisSaida({
        valorTotalCustoContabilIcmsIpi,
        valorTotalCustoContabilIr,
        valorTotalCustoMedio,
        valorTotalCustoUltimaCompra,
      });
    },
    [custosUnitariosSaida, formRef]
  );

  const handleChangeQuantidadeSaida = useCallback(
    (
      quantidadeSaida: number,
      custosUnitariosSaidaAtualizados?: ICustosUnitariosSaida
    ) => {
      if (
        !quantidadeSaida ||
        (!custosUnitariosSaida && !custosUnitariosSaidaAtualizados)
      )
        return;

      handleCalcularCustoTotalSaida(
        quantidadeSaida,
        custosUnitariosSaidaAtualizados
      );
    },
    [custosUnitariosSaida, handleCalcularCustoTotalSaida]
  );

  const handleSetarCustoProdutoSaida = useCallback(
    async ({
      dataMovimento,
      idProduto,
      indicadorTipoEstoque,
      idPessoa,
    }: ICarregarCustoProdutoSaida): Promise<
      ICustosUnitariosSaida | undefined
    > => {
      try {
        const response = await ObterValorUnitarioCustoSaidaComunicador.index({
          params: {
            dataMovimento,
            idPessoa,
            idProduto,
            indicadorTipoEstoque,
          },
        });

        const {
          valorUnitarioCustoMedio,
          valorUnitarioCustoUltimaCompra,
          valorUnitarioCustoContabilIr,
          valorUnitarioCustoContabilIcmsIpi,
        } = response.custos;

        setCustosUnitariosSaida({
          valorUnitarioCustoMedio: Number(valorUnitarioCustoMedio),
          valorUnitarioCustoUltimaCompra: Number(
            valorUnitarioCustoUltimaCompra
          ),
          valorUnitarioCustoContabilIr: Number(valorUnitarioCustoContabilIr),
          valorUnitarioCustoContabilIcmsIpi: Number(
            valorUnitarioCustoContabilIcmsIpi
          ),
        });

        formRef.current?.setFieldValue(
          'produtoOrigemCustos.valorUnitarioCustoMedio',
          valorUnitarioCustoMedio
        );

        formRef.current?.setFieldValue(
          'produtoOrigemCustos.valorUnitarioCustoUltimaCompra',
          valorUnitarioCustoUltimaCompra
        );

        formRef.current?.setFieldValue(
          'produtoOrigemCustos.valorUnitarioCustoContabilIr',
          valorUnitarioCustoContabilIr
        );

        formRef.current?.setFieldValue(
          'produtoOrigemCustos.valorUnitarioCustoContabilIcmsIpi',
          valorUnitarioCustoContabilIcmsIpi
        );

        return verificaAusenciaCustos({
          valorUnitarioCustoMedio: Number(valorUnitarioCustoMedio),
          valorUnitarioCustoUltimaCompra: Number(
            valorUnitarioCustoUltimaCompra
          ),
          valorUnitarioCustoContabilIr: Number(valorUnitarioCustoContabilIr),
          valorUnitarioCustoContabilIcmsIpi: Number(
            valorUnitarioCustoContabilIcmsIpi
          ),
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data.listaMensagem &&
          error.response.data.listaMensagem.includes(
            'A Empresa não possui um Regime Tributário na data de movimento!'
          )
        ) {
          return undefined;
        }

        TratarErros(error);
        return undefined;
      }
    },
    [formRef]
  );

  const handleClearCustoFields = useCallback(() => {
    const fields = [
      'valorUnitarioMovimentoCustoUltimaCompra',
      'valorTotalMovimentoCustoUltimaCompra',
      'valorUnitarioMovimentoCustoMedio',
      'valorTotalMovimentoCustoMedio',
      'valorUnitarioMovimentoCustoContabilIr',
      'valorTotalMovimentoCustoContabilIr',
      'valorUnitarioMovimentoCustoContabilIcmsIpi',
      'valorTotalMovimentoCustoContabilIcmsIpi',
    ];

    fields.forEach((field) => {
      formRef.current?.clearField(`produtoOrigemCustos.${field}`);
      formRef.current?.clearField(`produtoDestinoCustos.${field}`);
    });
  }, [formRef]);

  const handleClearCustosTotalSaida = useCallback(() => {
    const fields = [
      'valorTotalCustoUltimaCompra',
      'valorTotalCustoMedio',
      'valorTotalCustoContabilIr',
      'valorTotalCustoContabilIcmsIpi',
    ];

    fields.forEach((field) =>
      formRef.current?.clearField(`produtoOrigemCustos.${field}`)
    );
  }, [formRef]);

  const handleClearCustosEntrada = useCallback(() => {
    const fields = [
      'valorUnitarioMovimentoCustoUltimaCompra',
      'valorTotalMovimentoCustoUltimaCompra',
      'valorUnitarioMovimentoCustoMedio',
      'valorTotalMovimentoCustoMedio',
      'valorUnitarioMovimentoCustoContabilIr',
      'valorTotalMovimentoCustoContabilIr',
      'valorUnitarioMovimentoCustoContabilIcmsIpi',
      'valorTotalMovimentoCustoContabilIcmsIpi',
    ];

    fields.forEach((field) =>
      formRef.current?.clearField(`produtoDestinoCustos.${field}`)
    );
  }, [formRef]);

  return (
    <CustosContext.Provider
      value={{
        handleSetarCustoProdutoSaida,
        handleClearCustoFields,
        handleChangeQuantidadeSaida,
        handleCalcularCustosEntrada,
        handleClearCustosTotalSaida,
        handleClearCustosEntrada,
      }}
    >
      {children}
    </CustosContext.Provider>
  );
};

function useCustos(): ICustosContext {
  const context = useContext(CustosContext);

  return context;
}

export { CustosHook, useCustos };
