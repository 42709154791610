import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import ContaContabilComunicador from '../../../../../../Comunicador/Contabil/ContasContabeis/ContaContabil/Comunicador/ContaContabilComunicador';
import FormHook from './FormHook';
import RotasHook from './RotasHook';

const ContaContabilDetalheHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: ContaContabilComunicador }}>
      <RotasHook>
        <FormHook>{children}</FormHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default ContaContabilDetalheHook;
