import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TipoTomadorEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompletePessoa from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import { UseParticipantes } from '../Hooks/ParticipantesHook';

interface IPrincipalTab {
  tipoTomador: TipoTomadorEnum | null;
  bloquearCamposPorImportacao: boolean;
}

const PrincipalTab: React.FC<IPrincipalTab> = ({
  tipoTomador,
  bloquearCamposPorImportacao,
}) => {
  const { handleAtualizarDadosParticipantesConformeCadastro } =
    UseParticipantes();

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaEmitente"
              nomeObjeto="pessoaEmitente"
              label="Emitente"
              placeholder="Emitente"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('emitente');
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaRemetente"
              nomeObjeto="pessoaRemetente"
              label="Remetente"
              placeholder="Remetente"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('remetente');
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaExpedidor"
              nomeObjeto="pessoaExpedidor"
              label="Expedidor"
              placeholder="Expedidor"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('expedidor');
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaRecebedor"
              nomeObjeto="pessoaRecebedor"
              label="Recebedor"
              placeholder="Recebedor"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro('recebedor');
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaDestinatario"
              nomeObjeto="pessoaDestinatario"
              label="Destinatário"
              placeholder="Destinatário"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro(
                  'destinatario'
                );
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row
          style={{
            display: `${
              tipoTomador === TipoTomadorEnum.outros ? 'flex' : 'none'
            } `,
          }}
        >
          <Col xl={6} lg={12}>
            <InputAutoCompletePessoa
              name="idPessoaTomadorOutro"
              nomeObjeto="pessoaTomadorOutro"
              label="Tomador Outros"
              placeholder="Tomador Outros"
              onChangeItemAtual={() => {
                handleAtualizarDadosParticipantesConformeCadastro(
                  'tomadorOutro'
                );
              }}
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputSwitch
              name="retira"
              label="Recebedor retira no Aeroporto, Filial, Porto ou Estação de Destino"
              disabled={bloquearCamposPorImportacao}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default PrincipalTab;
