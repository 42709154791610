import React from 'react';
import { ComunicadorProvider } from '../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import EtiquetaComunicador from '../../../../../Comunicador/Configuracao/Etiqueta/Comunicador/EtiquetaComunicador';
import { EtiquetaElementoHook } from './EtiquetaElementoHook';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';

const EtiquetaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <ComunicadorProvider value={{ comunicador: EtiquetaComunicador }}>
      <RotasHook>
        <EtiquetaElementoHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </EtiquetaElementoHook>
      </RotasHook>
    </ComunicadorProvider>
  );
};

export default EtiquetaHook;
