/* eslint-disable no-nested-ternary */
import {
  FormatarDataHoraCompletaParaPtBr,
  INotaFiscalEntradaLista,
} from '@elogestor/util';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { BiDownload } from 'react-icons/bi/index.mjs';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import NotaFiscalEntradaComunicador from '../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaComunicador';
import NotaFiscalEntradaLogComunicador from '../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Log/Comunicador/NotaFiscalEntradaLogComunicador';
import NotaFiscalEntradaA4ImpressaoComunicador from '../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Impressao/NotaFiscalEntradaA4Impressao/NotaFiscalEntradaA4ImpressaoComunicador';
import DownloadArquivo from '../../../../../Util/Arquivos/DownloadArquivo';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import NotaFiscalEntradaExportarXmlComunicador from '../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaExportarXmlComunicador';
import ImpressaoNotaFiscal from '../../../../DocumentosEletronicos/NFe/Impressao';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ContaRevertidaComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/Comunicador/ContaRevertidaComunicador';

const NotaFiscalEntradaLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { abrirJanela } = UseConfirmacao();

  const [loading, setLoading] = useState(false);

  const handleBaixarPdf = useCallback(
    async (data: INotaFiscalEntradaLista): Promise<void> => {
      try {
        setLoading(true);

        const response = await NotaFiscalEntradaA4ImpressaoComunicador.show({
          id: data.id,
        });

        const pdfData = response.pdf.data;

        const blob = new Blob([new Uint8Array(pdfData)], {
          type: 'application/pdf',
        });

        DownloadArquivo(blob, `${data.numero}.pdf`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        ToastErro(error.message);
      }
    },
    []
  );

  const handleBaixarXml = useCallback(
    async (data: INotaFiscalEntradaLista): Promise<void> => {
      try {
        setLoading(true);
        const listaResponse =
          await NotaFiscalEntradaExportarXmlComunicador.show({
            id: data.id,
          });

        for (let i = 0; i < listaResponse.length; i++) {
          const responseFor = listaResponse[i];

          await ImpressaoNotaFiscal.handleExportarXml(
            responseFor.xml,
            responseFor.nomeArquivo
          );
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        ToastErro(error.message);
      }
    },
    []
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await NotaFiscalEntradaComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: INotaFiscalEntradaLista) => {
          let emTransmissao = false;
          let autorizada = false;
          let denegada = false;
          let cancelada = false;
          let rejeitada = false;

          if (data.situacao.includes('Em Transmissão')) emTransmissao = true;
          if (data.situacao.includes('Autorizada')) autorizada = true;
          if (data.situacao.includes('Denegada')) denegada = true;
          if (data.situacao.includes('Cancelada')) cancelada = true;
          if (data.situacao.includes('Rejeitada')) rejeitada = true;

          return {
            id: data.id,
            numero: <div className="lista-valor">{data.numero}</div>,
            serieCodigo: <div className="lista-valor">{data.serieCodigo}</div>,
            dataHoraEmissao: (
              <div className="lista-data">
                {FormatarDataHoraCompletaParaPtBr(data.dataHoraEmissao)}
              </div>
            ),
            'notaFiscalEntradaPessoa.pessoa.codigoNomeRazaoSocial': (
              <div className="lista-texto">{`${data.notaFiscalEntradaPessoa.pessoa.codigo} - ${data.notaFiscalEntradaPessoa.pessoa.nomeRazaoSocial}`}</div>
            ),
            valorTotalProdutos: (
              <div className="lista-valor">
                {data.valorTotalProdutos
                  ? Number(data.valorTotalProdutos).FormatarParaPtBr()
                  : ''}
              </div>
            ),
            valorTotalBruto: (
              <div className="lista-valor">
                {data.valorTotalBruto
                  ? Number(data.valorTotalBruto).FormatarParaPtBr({
                      minimoCasasDecimais: 2,
                      maximoCasasDecimais: 2,
                    })
                  : ''}
              </div>
            ),
            tipoNotaFiscalEntrada: (
              <div className="lista-texto">{data.tipoNotaFiscalEntrada}</div>
            ),
            situacao: (
              <div
                className="lista-texto"
                style={{
                  fontWeight: 'bold',
                  color: emTransmissao
                    ? 'gray'
                    : autorizada
                      ? 'green'
                      : denegada
                        ? 'blue'
                        : cancelada
                          ? 'red'
                          : rejeitada
                            ? 'goldenrod'
                            : 'black',
                }}
              >
                {data.situacao}
              </div>
            ),

            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      const retorno = await ContaRevertidaComunicador.index({
                        params: {
                          idNotaFiscalEntrada: data.id,
                        },
                      });

                      if (retorno.possuiContaParcelaRevertida) {
                        const resposta = await abrirJanela({
                          titulo: <h2>Confirmação</h2>,
                          mensagem: (
                            <span style={{ fontSize: 20 }}>
                              Essa conta possui uma reversão. Deseja excluir a
                              conta revertida?
                            </span>
                          ),
                          confimar: 'Sim',
                          cancelar: 'Não',
                        });

                        if (!resposta) {
                          setLoading(false);
                          return;
                        }
                      }

                      await NotaFiscalEntradaComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = NotaFiscalEntradaLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    permissoes.SuprimentosComprasNotaFiscalEntrada?.exclui
                  }
                  botoes={
                    <>
                      <Dropdown.Item
                        onClick={() => handleBaixarXml(data)}
                        disabled={data.situacao.includes('Não Transmitida')}
                      >
                        <span style={{ display: 'flex', fontSize: 16 }}>
                          <BiDownload style={{ marginRight: 5 }} />
                          Salvar XML
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          data.situacao.includes('Não Transmitida') ||
                          data.situacao.includes('Em Transmissão') ||
                          data.situacao.includes('Rejeitada')
                        }
                        onClick={() => handleBaixarPdf(data)}
                      >
                        <span style={{ display: 'flex', fontSize: 16 }}>
                          <BiDownload style={{ marginRight: 5 }} />
                          Salvar PDF
                        </span>
                      </Dropdown.Item>
                    </>
                  }
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [
      abrirJanela,
      handleBaixarPdf,
      handleBaixarXml,
      permissoes.SuprimentosComprasNotaFiscalEntrada?.exclui,
    ]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'suprimentosNotaFiscalEntrada' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Nota Fiscal de Entrada"
          permissao={permissoes.SuprimentosComprasNotaFiscalEntrada}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return NotaFiscalEntradaLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default NotaFiscalEntradaLista;
