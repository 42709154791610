import {
  AppErro,
  EstoqueIndicadorTipoEnum,
  TipoMovimentoEntradaSaidaEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { ISubmitProps } from '../../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRedirecionar } from '../../../../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import GetValidationErrors from '../../../../../../../../../../../Util/Erro/GetValidationErrors';
import {
  ListaDetalheFormProvider,
  UseListaDetalheForm,
} from '../../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../../../../Util/Erro/TratarErros';
import { UseItemOrdemServico } from '../../../../Hook/ItemOrdemServicoHook';
import IPadraoProps from '../../../../../../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const formItemPrincipal = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const { produtoControlaLote, adicionarLocalEstoque, removerLocalEstoque } =
    UseItemOrdemServico();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRefLista = useRef<IFormCiaHandles>(null);
  const formRefDetalhe = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string>('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(async () => {
    throw new AppErro({
      mensagem:
        'A função "handleCarregarDados" não foi implementada no "ListaDetalheItemEstoqueLocalEstoque"',
    });
  }, []);

  const handleValidar = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null,
      ordemServicoExiste?: boolean
    ): Promise<boolean> => {
      try {
        formRef?.setErrors({});

        const schema = Yup.object().shape({
          idLocalEstoque: Yup.string()
            .nullable()
            .required('Local de Estoque é obrigatório!'),

          idLote: Yup.mixed()
            .nullable()
            .test({
              message: 'Lote é obrigatório!',
              test: () => {
                return !(!data.idLote && produtoControlaLote);
              },
            }),

          quantidade: Yup.mixed()
            .nullable()
            .test({
              message: 'Quantidade é obrigatória!',
              test: () => {
                return !!data.quantidade;
              },
            })
            .test({
              message: 'Quantidade deve ser maior que zero!',
              test: () => {
                return !(data.quantidade === 0);
              },
            }),

          tipoEntradaSaida: Yup.string()
            .oneOf(Object.values(TipoMovimentoEntradaSaidaEnum))
            .required('Tipo Entrada/Saída é obrigatório!'),

          indicadorTipoEstoque: Yup.string()
            .oneOf(Object.values(EstoqueIndicadorTipoEnum))
            .required('Tipo de Estoque é obrigatório!'),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (ordemServicoExiste !== false) {
          const errors = GetValidationErrors(error);
          formRef?.setErrors(errors);
        }

        return false;
      }
    },
    [produtoControlaLote]
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        formItemPrincipal.setLoading(true);

        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          formItemPrincipal.setLoading(false);
          return { id: '', erro: true };
        }

        adicionarLocalEstoque(data);

        formItemPrincipal.refresh();
        await formRef?.reset();
        setLoading(false);
        formItemPrincipal.setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        formItemPrincipal.setLoading(false);
        return { id: '', erro: true };
      }
    },
    [adicionarLocalEstoque, formItemPrincipal, handleValidar, redirecionar]
  );

  const handleExcluir = async (id: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: <span style={{ fontSize: 20 }}>Deseja remover o item?</span>,
      });

      if (resposta) {
        setLoading(true);
        formItemPrincipal.setLoading(true);

        removerLocalEstoque(id);

        formItemPrincipal.refresh();
        setLoading(false);
        formItemPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formItemPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
