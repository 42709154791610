import api from '../../../../../Comum/Services/Api';

const rota =
  'financeiro/geral/regra-escolha-integracao-pagamento/obter-conforme-criterios';

interface IRegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia?: Date;
    idFormaPagamento?: string | null;
    idBandeiraCartao?: string | null;
    quantidadeParcelas: number;
    operacao?: string | null;
  };
}

class RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador {
  public async show(
    params: IRegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaIntegracaoPagamentoObterConformeCriteriosComunicador();
