import React, { createContext, useCallback, useContext, useState } from 'react';
import { AppErro, IOrdemProducaoItemLista } from '@elogestor/util';
import { produce } from 'immer';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';

interface IOrdemProducaoItemContext {
  validador: boolean;
  listaValor: IOrdemProducaoItemLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IOrdemProducaoItemLista[]>
  >;
  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(lista?: IOrdemProducaoItemLista[]): Promise<void>;
  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const OrdemProducaoItemContext = createContext<IOrdemProducaoItemContext>(
  {} as IOrdemProducaoItemContext
);

const ListaOrdemProducaoItemDraggableHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<IOrdemProducaoItemLista[]>([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(() => {
    throw new AppErro({
      mensagem:
        'A função "handleAtualizarOrdem" não foi implementada no "ListaOrdemProducaoItemDraggableHook"',
    });
  }, []);

  return (
    <OrdemProducaoItemContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </OrdemProducaoItemContext.Provider>
  );
};

function UseListaOrdemProducaoItemDraggable(): Omit<
  IOrdemProducaoItemContext,
  'validador'
> {
  const context = useContext(OrdemProducaoItemContext);

  if (!context.validador) {
    throw new Error(
      `UseListaOrdemDesmotagemItemDraggable deve ser usado
       com um ListaOrdemProducaoItemDraggableHook`
    );
  }

  return context;
}

export {
  ListaOrdemProducaoItemDraggableHook,
  UseListaOrdemProducaoItemDraggable,
};
