const NotaFiscalSaidaPorTransportadoraPlanilha = [
  {
    titulo: 'Transportadora',
    valor: 'valores.chaveFormatada',
  },
  {
    titulo: 'Data Emissão',
    valor: 'listaNotaFiscalSaida.notaFiscalSaidaDataHoraEmissao',
  },

  {
    titulo: 'Número',
    valor: 'listaNotaFiscalSaida.notaFiscalSaidaNumero',
  },

  {
    titulo: 'Cliente',
    valor: 'listaNotaFiscalSaida.pessoaNomeRazaoSocial',
  },

  {
    titulo: 'Valor Total',
    valor: 'listaNotaFiscalSaida.somatorioValorTotalBruto',
  },
  {
    titulo: 'Quantidade',
    valor: 'listaNotaFiscalSaida.somatorioValorQuantidade',
  },

  {
    titulo: 'Peso Bruto',
    valor: 'listaNotaFiscalSaida.somatorioValorPesoBruto',
  },

  {
    titulo: 'Peso Líquido',
    valor: 'listaNotaFiscalSaida.somatorioValorPesoLiquido',
  },
];

export default NotaFiscalSaidaPorTransportadoraPlanilha;
