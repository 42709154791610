import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada';

interface INotaFiscalEntradaComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface INotaFiscalEntradaComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaComunicadorStore {
  params: any;
}

interface INotaFiscalEntradaComunicadorUpdate {
  id: string;
  params: any;
}

interface INotaFiscalEntradaComunicadorDeletar {
  id: string;
}

class NotaFiscalEntradaComunicador {
  public async index(params: INotaFiscalEntradaComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: INotaFiscalEntradaComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: INotaFiscalEntradaComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: INotaFiscalEntradaComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new NotaFiscalEntradaComunicador();
