/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { UseParametros } from '../../../Hooks/ParametrosHook';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputProps {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
}

export interface IOnChangeValueInputEvent {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
  valor: string;
}

export interface IOnBlurCiaInputEvent {
  valorAnteriorOnBlur: string;
  valorAnteriorOnChange: string;
  mudou: boolean;
  valor: string;
}

export interface IInputRef {
  focus(): void;
}

interface IInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  maiuscula?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputProps
  ) => void;
  onChangeValue?: (event: IOnChangeValueInputEvent) => void;
  onBlurCia?: (event: IOnBlurCiaInputEvent) => void;
  formatarValor?: (event: React.ChangeEvent<HTMLInputElement>) => string;
}

const Input: React.ForwardRefRenderFunction<IInputRef, IInputProps> = (
  {
    name,
    label,
    maiuscula,

    onChange,
    onChangeValue,
    onBlur,
    onBlurCia,
    onFocus,
    formatarValor,
    ...rest
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();
  const parametros = UseParametros();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputRef = useRef<HTMLInputElement>(null);
  const valorAnteriorOnBlur = useRef('');
  const valorAnteriorOnChange = useRef('');

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    valorAnteriorOnBlur.current = inputRef.current?.value || '';
    valorAnteriorOnChange.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnteriorOnBlur.current = inputRef.current?.value || '';
      valorAnteriorOnChange.current = inputRef.current?.value || '';

      if (onFocus) onFocus(event);

      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErro('');

      if (formatarValor) {
        event.target.value = formatarValor(event);
      }

      if (typeof maiuscula === 'boolean') {
        if (maiuscula) {
          const pontoInicialSelecionado = event.target.selectionStart;
          const pontoFinalSelecionado = event.target.selectionEnd;

          event.target.value = event.target.value.toUpperCase();
          event.target.selectionStart = pontoInicialSelecionado;
          event.target.selectionEnd = pontoFinalSelecionado;
        }
      } else if (parametros.CamposLetraMaiuscula) {
        const pontoInicialSelecionado = event.target.selectionStart;
        const pontoFinalSelecionado = event.target.selectionEnd;

        event.target.value = event.target.value.toUpperCase();
        event.target.selectionStart = pontoInicialSelecionado;
        event.target.selectionEnd = pontoFinalSelecionado;
      }

      if (onChange) {
        onChange(event, {
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
        });
      }

      if (onChangeValue) {
        onChangeValue({
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
          valor: inputRef.current?.value || '',
        });
      }

      valorAnteriorOnChange.current = inputRef.current?.value || '';
    },
    [
      formatarValor,
      maiuscula,
      onChange,
      onChangeValue,
      parametros.CamposLetraMaiuscula,
    ]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsFilled(!!inputRef.current?.value);

      if (onBlur) onBlur(event);

      if (onBlurCia) {
        const mudou = inputRef.current?.value !== valorAnteriorOnBlur.current;

        onBlurCia({
          valorAnteriorOnBlur: valorAnteriorOnBlur.current,
          valorAnteriorOnChange: valorAnteriorOnChange.current,
          mudou,
          valor: inputRef.current?.value || '',
        });
      }
    },
    [onBlur, onBlurCia]
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.value || '';
      },

      setValue(_, valor = '') {
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      setSemExecutarEvento(_, valor = '') {
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = valor;
        }
      },

      clearValue(_, valorInicial = '') {
        if (inputRef.current) {
          valorAnteriorOnBlur.current = inputRef.current.value;
          valorAnteriorOnChange.current = inputRef.current.value;
          inputRef.current.value = valorInicial;
        }
      },

      validarSeAlterou(_, valorInicial = '') {
        if (inputRef.current) {
          return inputRef.current.value !== valorInicial;
        }

        return false;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <DefaultInput
        $isErrored={!!erro}
        $isFocused={isFocused}
        $isFilled={isFilled}
        name={name}
        type="text"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...rest}
        ref={inputRef}
      />
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(Input);
