import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IImpressaoEtiquetasPedidoVendaLista } from '@elogestor/util';
import { IInputAutoCompleteBaseRef } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';

interface IImpressaoEtiquetasPedidoVendaListaContext {
  validador: boolean;

  listaValor: IImpressaoEtiquetasPedidoVendaLista[];
  setListaValor(lista: IImpressaoEtiquetasPedidoVendaLista[]): void;

  listaItemSelecionadoRef: RefObject<Array<HTMLInputElement | null>>;
  listaQuantidadeRef: RefObject<HTMLInputElement[]>;
  listaTransportadoraRef: RefObject<IInputAutoCompleteBaseRef[]>;
}

const ImpressaoEtiquetasPedidoVendaListaContext =
  createContext<IImpressaoEtiquetasPedidoVendaListaContext>(
    {} as IImpressaoEtiquetasPedidoVendaListaContext
  );

const ImpressaoEtiquetasPedidoVendaListaHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  const [listaValor, setListaValor] = useState<
    IImpressaoEtiquetasPedidoVendaLista[]
  >([]);

  const listaItemSelecionadoRef = useRef<Array<HTMLInputElement | null>>([]);
  const listaQuantidadeRef = useRef<HTMLInputElement[]>([]);
  const listaTransportadoraRef = useRef<IInputAutoCompleteBaseRef[]>([]);

  const handleSetListaValor = useCallback(
    async (lista: IImpressaoEtiquetasPedidoVendaLista[]) => {
      setListaValor(lista);
    },
    []
  );

  return (
    <ImpressaoEtiquetasPedidoVendaListaContext.Provider
      value={{
        listaValor,
        setListaValor: handleSetListaValor,

        listaItemSelecionadoRef,
        listaQuantidadeRef,

        listaTransportadoraRef,

        validador: true,
      }}
    >
      {children}
    </ImpressaoEtiquetasPedidoVendaListaContext.Provider>
  );
};

function UseImpressaoEtiquetasPedidoVendaLista(): Omit<
  IImpressaoEtiquetasPedidoVendaListaContext,
  'validador'
> {
  const context = useContext(ImpressaoEtiquetasPedidoVendaListaContext);

  if (!context.validador) {
    throw new Error(
      'UseImpressaoEtiquetasPedidoVendaLista deve ser usado com um ImpressaoEtiquetasPedidoVendaListaHook'
    );
  }

  return context;
}

export {
  ImpressaoEtiquetasPedidoVendaListaHook,
  UseImpressaoEtiquetasPedidoVendaLista,
};
