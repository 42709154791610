import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/duplicar';

interface IPedidoVendaDuplicarComunicadorStore {
  idRegistroParaDuplicar: string;
  params: any;
}

class PedidoVendaDuplicarComunicador {
  public async store(
    params: IPedidoVendaDuplicarComunicadorStore
  ): Promise<any> {
    const response = await api.post(
      `${rota}/${params.idRegistroParaDuplicar}`,
      params.params
    );

    return response.data;
  }
}

export default new PedidoVendaDuplicarComunicador();
