import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';

const ObservacoesTab: React.FC = () => {
  return (
    <>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Textarea
              label="Observação"
              name="observacoes"
              placeholder="Observação"
              maxLength={500}
            />
          </Col>
        </Row>
      </Divisor>
    </>
  );
};

export default ObservacoesTab;
