"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DesestruturarCodigoBarrasProdutor;
function DesestruturarCodigoBarrasProdutor(dados) {
    const retorno = {
        inscricaoEstadual: null,
        serie: null,
        numero: null,
    };
    if (!dados.codigoBarrasNotaProdutor ||
        dados.codigoBarrasNotaProdutor.length === 0)
        return retorno;
    retorno.inscricaoEstadual = String(dados.codigoBarrasNotaProdutor.substring(0, dados.codigoBarrasNotaProdutor.length - 9));
    retorno.serie = Number(dados.codigoBarrasNotaProdutor.substring(dados.codigoBarrasNotaProdutor.length - 9, dados.codigoBarrasNotaProdutor.length - 6));
    retorno.numero = Number(dados.codigoBarrasNotaProdutor.substring(dados.codigoBarrasNotaProdutor.length - 6, dados.codigoBarrasNotaProdutor.length));
    return retorno;
}
