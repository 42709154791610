import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/cotacao-compra';

interface ICotacaoCompraComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface ICotacaoCompraComunicadorShow {
  id: string;
}

interface ICotacaoCompraComunicadorStore {
  params: any;
}

interface ICotacaoCompraComunicadorUpdate {
  id: string;
  params: any;
}

interface ICotacaoCompraComunicadorDeletar {
  id: string;
}

class CotacaoCompraComunicador {
  public async index(params: ICotacaoCompraComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: ICotacaoCompraComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: ICotacaoCompraComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: ICotacaoCompraComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: ICotacaoCompraComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new CotacaoCompraComunicador();
