/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import InputHidden from '../InputHidden';

export interface IOnChangeInputHiddenHtmlEvent<T = any> {
  valor: T;
}

interface IInputProps {
  name: string;
  valorStyle?: React.CSSProperties;

  onChange?: ({ valor }: IOnChangeInputHiddenHtmlEvent) => void;
  getValue?: ({ valor }: IOnChangeInputHiddenHtmlEvent) => void;
  getDataDuplicar?: ({ valor }: IOnChangeInputHiddenHtmlEvent) => void;
  getDataRecuperarFormulario?: ({
    valor,
  }: IOnChangeInputHiddenHtmlEvent) => void;
  setValue?: (valor: any) => any;
  formatarValor?: (valor: any) => any;
  validarSeMudou?: (ref: any, valorInicial: any) => boolean;
}

const InputHiddenHtml = ({
  name,
  onChange,
  getValue,
  getDataDuplicar,
  getDataRecuperarFormulario,
  validarSeMudou,
  setValue,
}: IInputProps): any => {
  InputHidden({
    name,
    onChange,
    getValue,
    getDataDuplicar,
    getDataRecuperarFormulario,
    validarSeMudou,
    setValue,
  });

  return <></>;
};

export default InputHiddenHtml;
