import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Main from './Main';
import { Container } from './styles';
import ILocation from '../../../../Componentes/Detalhe/Interface/ILocation';
import Detalhe from '../../../../Componentes/Detalhe';
import IParametroRotaDetalhe from '../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import UsuarioHook from './Hooks';

const UsuarioDetalhe: React.FC = () => {
  const params: IParametroRotaDetalhe = useParams();
  const location = useLocation();
  const state = location.state as ILocation;

  return (
    <Container>
      <UsuarioHook>
        <Detalhe>
          <Main
            idEditar={params.id ?? null}
            dadosRecuperados={state?.dadosRecuperados ?? null}
            dadosDuplicados={state?.dadosDuplicados ?? null}
          />
        </Detalhe>
      </UsuarioHook>
    </Container>
  );
};

export default UsuarioDetalhe;
