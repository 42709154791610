import React, { useCallback, useRef, useState } from 'react';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  IMovimentoPortadorValoresAlterar,
  TipoIntermedioImportacaoEnum,
  TipoTransporteImportacaoEnum,
} from '@elogestor/util';
import * as Yup from 'yup';
import {
  ISubmitProps,
  ListaDetalheFormProvider,
  UseListaDetalheForm,
} from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import GetValidationErrors from '../../../../../../../../../../Util/Erro/GetValidationErrors';
import {
  IHandleCarregarDadosParametros,
  UseForm,
} from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import { UseConfirmacao } from '../../../../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import IPadraoProps from '../../../../../../../../../../Comum/Interface/IPadraoProps';

interface IItemDeclaracaoImportacaoHook extends IPadraoProps {
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const FormHook: React.FC<IItemDeclaracaoImportacaoHook> = ({
  children,
  formRefItem,
}) => {
  const formPrincipal = UseForm();
  const { formRefLista } = UseListaDetalheForm();
  const { redirecionar } = UseRedirecionar();
  const { abrirJanela } = UseConfirmacao();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const formRefDetalhe = useRef<IFormCiaHandles>(null);
  const [loading, setLoading] = useState(false);

  const idDetalheRegistro = useRef('');
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IMovimentoPortadorValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRefDetalhe.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRefDetalhe.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRefDetalhe.current?.setDataRecuperarFormulario(
          dadosRecuperados
        );
      }

      async function SetarDadosBackend(): Promise<void> {
        const listaNotaFiscalEntradaItemDeclaracaoImportacao =
          formRefItem.current?.getFieldValue(
            'listaNotaFiscalEntradaItemDeclaracaoImportacao'
          );

        const response =
          listaNotaFiscalEntradaItemDeclaracaoImportacao[Number(idEditar)];

        response.percentualLiquidado = Number(response.percentualLiquidado);
        await formRefDetalhe.current?.setDataInicial(response);
        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRefDetalhe.current?.setDataInicial({
          tipoTransporte: TipoTransporteImportacaoEnum.maritima,
          tipoIntermedio:
            TipoIntermedioImportacaoEnum.importacaoPorContaPropria,
          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [formRefItem, getIdDetalheRegistro, handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(
    async (data: any, formRef: IFormCiaHandles | null): Promise<boolean> => {
      try {
        formRef?.setErrors({});
        let retornoValido = true;
        const errors: any = {};

        const listaMensagemErro: String[] = [];

        const schema = Yup.object().shape({
          idEstadoDesembaraco: Yup.string()
            .required('UF do Desembaraço é Obrigatório!')
            .nullable(),
          numeroDeclaracaoImportacao: Yup.string()
            .required('Número da DI é Obrigatório!')
            .max(12, 'No Máximo 12 Caracteres'),
          dataDeclaracaoImportacao: Yup.date()
            .required('Data da DI é Obrigatória!')
            .nullable(),
          dataDesembaraco: Yup.date()
            .required('Data do Desembaraço é Obrigatória!')
            .nullable(),

          localDesembaraco: Yup.string()
            .required('Local do Desembaraço é Obrigatório!')
            .max(60, 'No Máximo 60 Caracteres'),
        });

        await schema.validate(data, { abortEarly: false });

        if (listaMensagemErro.length > 0) {
          TratarErros({ listaMensagem: listaMensagemErro });
          formRef?.setErrors(errors);
          retornoValido = false;
        }

        return retornoValido;
      } catch (error) {
        const errors = GetValidationErrors(error as any);
        formRef?.setErrors(errors);

        return false;
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data: any,
      formRef: IFormCiaHandles | null
    ): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data, formRef))) {
          setLoading(false);
          return { id, erro: true };
        }

        const listaDados = formRefItem.current?.getFieldValue(
          'listaNotaFiscalEntradaItemDeclaracaoImportacao'
        );

        if (!id) {
          formRefItem.current?.setFieldValue(
            'listaNotaFiscalEntradaItemDeclaracaoImportacao',
            [...listaDados, data]
          );
        } else {
          listaDados[id] = data;
        }

        await formRef?.reset();

        setLoading(false);
        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [formRefItem, getIdDetalheRegistro, handleValidar, redirecionar]
  );

  const handleExcluir = async (index: string): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja Remover a Liquidação?</span>
        ),
      });
      if (resposta) {
        setLoading(true);
        formPrincipal.setLoading(true);

        const listaDados = formRefItem.current?.getFieldValue(
          'listaNotaFiscalEntradaItemDeclaracaoImportacao'
        );
        listaDados.splice(Number(index), 1);

        formRefItem.current?.setFieldValue(
          'listaNotaFiscalEntradaItemDeclaracaoImportacao',
          listaDados
        );

        refresh();
        setLoading(false);
        formPrincipal.setLoading(false);
      }
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
      formPrincipal.setLoading(false);
    }
  };

  return (
    <ListaDetalheFormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRefLista,
        formRefDetalhe,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleCarregarDados,
        handleValidar,
        handleSubmit,
        handleExcluir,
        refresh,
      }}
    >
      {children}
    </ListaDetalheFormProvider>
  );
};

export default FormHook;
