import api from '../../../../../Comum/Services/Api';

const rota = 'teste-andre';

interface ITesteAndreComunicadorIndex {
  atualizarPdfNotasEmitidas?: boolean;
  atualizarServicoTomadoCteTerceiro?: boolean;

  recalcularCustoProduto?: boolean;
  idProdutoRecalcularCusto?: string;

  recalcularCustoProdutoTodos?: boolean;

  ajustarMovimentoEstoqueNotaSaida?: boolean;
  descricaoProduto?: string;

  ajustarContasCteTerceiro?: boolean;

  atualizarImportacaoAutorizacaoXmlNfe?: boolean;

  atualizarProdutoConformeXmlNotasFiscais?: boolean;

  atualizarProdutoEstoqueCombinacao?: boolean;
}

class TesteAndreComunicador {
  public async index(params?: ITesteAndreComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params });

    return response.data;
  }
}

export default new TesteAndreComunicador();
