/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { MdMergeType } from 'react-icons/md/index.mjs';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IAcoesJuntarPessoas {
  permissao?: IPermissao;
}

const AcoesJuntarPessoas: React.FC<IAcoesJuntarPessoas> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const { refresh, loading, handleSubmit } = UseForm();

  const handleJuntarPessoas = useCallback(async (): Promise<void> => {
    try {
      handleSubmit({});

      refresh();
    } catch (error) {
      TratarErros(error);
    }
  }, [handleSubmit, refresh]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || permissao?.altera === false}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />

              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleJuntarPessoas}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdMergeType style={{ marginRight: 5 }} />
            Juntar Pessoas
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesJuntarPessoas;
