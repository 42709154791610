import React from 'react';
import ListaDetalheDocumentoAnterior from './ListaDetalheDocumentoAnterior/Lista';
import Divisor from '../../../../../../Componentes/Divisor';
import DocumentoAnteriorHook from './ListaDetalheDocumentoAnterior/Hooks';

interface IDocumentoAnteriorTab {
  bloquearCamposPorImportacao: boolean;
}

const DocumentoAnteriorTab: React.FC<IDocumentoAnteriorTab> = ({
  bloquearCamposPorImportacao,
}) => {
  return (
    <Divisor>
      <DocumentoAnteriorHook>
        <ListaDetalheDocumentoAnterior
          name="listaConhecimentoTransporteTerceiroDocumentoAnterior"
          bloquearCamposPorImportacao={bloquearCamposPorImportacao}
        />
      </DocumentoAnteriorHook>
    </Divisor>
  );
};

export default DocumentoAnteriorTab;
