import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoContatoEnum } from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputAutoCompletePessoaContato from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoaContato';
import ListaDetalheComercialRepresentante from './ListaDetalheComercialRepresentante/Lista';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import OrdemServicoComercialRepresentanteHook from './ListaDetalheComercialRepresentante/Hooks';

const InformacaoComercialTab: React.FC = () => {
  const { formRef } = UseForm();
  const idPessoa = formRef.current?.getFieldValue(
    'ordemServicoPessoa.idPessoa'
  );

  useEffect(() => {
    if (!idPessoa) {
      formRef.current?.clearField('ordemServicoComercial.pessoaContato', {
        setValorInicial: false,
      });
    }
  }, [idPessoa, formRef]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompletePessoaContato
              label="Contato"
              name="ordemServicoComercial.idPessoaContato"
              placeholder="Contato"
              nomeObjeto="ordemServicoComercial.pessoaContato"
              idPessoa={idPessoa}
              disabled={!idPessoa}
              tipoContato={TipoContatoEnum.ordemServico}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <OrdemServicoComercialRepresentanteHook>
              <ListaDetalheComercialRepresentante name="ordemServicoComercial.listaOrdemServicoComercialRepresentante" />
            </OrdemServicoComercialRepresentanteHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default InformacaoComercialTab;
