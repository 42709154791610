"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEmitenteEnum;
(function (TipoEmitenteEnum) {
    TipoEmitenteEnum["prestadorServicoTransporte"] = "1 - Prestador de servi\u00E7o de transporte";
    TipoEmitenteEnum["transportadorCargaPropria"] = "2 - Transportador de Carga Pr\u00F3pria";
    TipoEmitenteEnum["prestadorServicoTransporteEmitenteCteGlobalizado"] = "3 - Prest. de serv. de transp. que emitir\u00E1 CT-e Globalizado";
})(TipoEmitenteEnum || (TipoEmitenteEnum = {}));
exports.default = TipoEmitenteEnum;
