import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/nota-fiscal-conhecimento/importacao-xml-terceiro/operacao-nao-realizada';

interface IImportacaoXmlTerceiroOperacaoNaoRealizadaComunicadorUpdate {
  params?: any;
}

class ImportacaoXmlTerceiroOperacaoNaoRealizadaComunicador {
  public async update(
    params: IImportacaoXmlTerceiroOperacaoNaoRealizadaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new ImportacaoXmlTerceiroOperacaoNaoRealizadaComunicador();
