import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/nota-fiscal-entrada/item/custos-compras';

interface INotaFiscalEntradaItemCustosCompraComunicadorShow {
  id: string;
}

interface INotaFiscalEntradaItemCustosComunicadorUpdate {
  id: string;
  params: any;
}

class NotaFiscalEntradaItemCustosCompraComunicador {
  public async show(
    params: INotaFiscalEntradaItemCustosCompraComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async update(
    params: INotaFiscalEntradaItemCustosComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }
}

export default new NotaFiscalEntradaItemCustosCompraComunicador();
