import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { produce } from 'immer';
import { validate } from 'uuid';
import IPesquisaAvancada from '../../Lista/Interface/IPesquisaAvancada';
import IPadraoProps from '../../../Comum/Interface/IPadraoProps';

interface ITh {
  id?: string;
  titulo: string;
  nomeCampo: string;
  arrastavel: boolean;
  visivel: boolean;
  ordenar: boolean;
  tamanho: number | null;
  permitirEsconder: boolean;
  style?: React.CSSProperties;
  campoPersonalizado?: any;
}

interface IParametros {
  pesquisaAvancada?: { [key: string]: any };
  order?: string;
  descAsc?: string;
  limite?: number;
  pagina?: number;
  textoPesquisa?: string;
}

interface IFuncoes {
  onChangeOrdem(novaListaThs: ITh[]): void;
  onChangeTamanho(novaTh: ITh): void;
  onChangeVisivel(novaTh: ITh): void;
  pesquisarDados(parametros: IParametros): Promise<void>;
}

interface ITableContex {
  mover(de: number, para: number): void;
  alterarLargura(index: number, valor: number | null): void;
  alterarVisibilidade(index: number, valor: boolean): void;

  setListaTh(listaTh: ITh[]): void;
  ths: ITh[];

  isDragging: boolean;
  setIsDragging(isDraggin: boolean): void;

  hasChanged: boolean;
  setHasChanged(mudou: boolean): void;

  setFuncoes(funcoes: IFuncoes): void;
  validador: boolean;

  parametros: IParametros;
  setParametros(params: IParametros): void;

  selecionarDados(): Promise<void>;

  overlayPesquisaAvancadaValores: IPesquisaAvancada[];
  alterarOverlayPesquisaAvancadaValores(data: IPesquisaAvancada[]): void;
}

const TableContext = createContext<ITableContex>({} as ITableContex);

const TableDraggableProvider: React.FC<IPadraoProps> = ({ children }) => {
  const [ths, setThs] = useState<ITh[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [funcoes, setFuncoes] = useState<IFuncoes>();

  const [parametros, setParametros] = useState<IParametros>({});
  const [textoPesquisa, setTextoPesquisa] = useState(parametros.textoPesquisa);
  const debounceChangeRef = useRef<number | null>(null);

  const [overlayPesquisaAvancadaValores, setOverlayPesquisaAvancadaValores] =
    useState<IPesquisaAvancada[]>([]);

  const alterarOverlayPesquisaAvancadaValores = useCallback(
    (data: IPesquisaAvancada[]) => {
      setOverlayPesquisaAvancadaValores(data);
    },
    []
  );

  const selecionarDados = useCallback(async () => {
    if (textoPesquisa !== parametros.textoPesquisa) {
      if (debounceChangeRef.current) {
        clearTimeout(debounceChangeRef.current);
      }

      debounceChangeRef.current = window.setTimeout(async () => {
        await funcoes?.pesquisarDados(parametros);
      }, 400);
    } else {
      await funcoes?.pesquisarDados(parametros);
    }

    setTextoPesquisa(parametros.textoPesquisa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcoes, parametros]);

  useEffect(() => {
    selecionarDados();
  }, [selecionarDados]);

  const mover = useCallback((de: number, para: number) => {
    setHasChanged(true);
    setThs((valores) =>
      produce(valores, (draft) => {
        const valorDe = draft[de];
        const valorPara = draft[para];

        draft.splice(de, 1, valorPara);
        draft.splice(para, 1, valorDe);
      })
    );
  }, []);

  const alterarLargura = useCallback(
    (index: number, valor: number) => {
      setThs(
        produce(ths, (draft) => {
          draft[index].tamanho = valor;
          funcoes?.onChangeTamanho(draft[index]);
        })
      );
    },
    [funcoes, ths]
  );

  const alterarVisibilidade = useCallback(
    (index: number, valor: boolean) => {
      setThs(
        produce(ths, (draft) => {
          draft[index].visivel = valor;
          funcoes?.onChangeVisivel(draft[index]);
        })
      );
    },
    [funcoes, ths]
  );

  useEffect(() => {
    if (!isDragging && hasChanged) {
      funcoes?.onChangeOrdem(ths.filter((th) => validate(th.id || '')));
      setHasChanged(false);
    }
  }, [isDragging, ths, hasChanged, setHasChanged, funcoes]);

  const setListaTh = useCallback((listaTh: ITh[]) => {
    setThs(listaTh);
  }, []);

  const handleSetParametros = useCallback((params: IParametros) => {
    setParametros((state) => ({ ...state, ...params }));
  }, []);

  return (
    <TableContext.Provider
      value={{
        ths,
        setListaTh,
        isDragging,
        setIsDragging,
        hasChanged,
        setHasChanged,
        setFuncoes,

        mover,
        alterarLargura,
        alterarVisibilidade,
        validador: true,
        parametros,
        setParametros: handleSetParametros,

        selecionarDados,
        overlayPesquisaAvancadaValores,
        alterarOverlayPesquisaAvancadaValores,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

function UseTableDraggable(): Omit<ITableContex, 'validador'> {
  const context = useContext(TableContext);

  if (!context.validador) {
    throw new Error(
      'UseTableDraggable/tableDraggable deve ser usado com um TableDraggableProvider'
    );
  }

  return context;
}

export { TableDraggableProvider, UseTableDraggable };
