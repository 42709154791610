import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/curva-abc-pedido-venda/curva-abc-pedido-venda-estado-por-produto-valor';

interface ICurvaABCPedidoVendaSaidaEstadoPorProdutoValorRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class CurvaABCPedidoVendaSaidaEstadoPorProdutoValorRelatorioComunicador {
  public async index(
    params: ICurvaABCPedidoVendaSaidaEstadoPorProdutoValorRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new CurvaABCPedidoVendaSaidaEstadoPorProdutoValorRelatorioComunicador();
