"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoParametroEnum;
(function (TipoParametroEnum) {
    TipoParametroEnum["texto"] = "Texto";
    TipoParametroEnum["numeroInteiro"] = "Numero Inteiro";
    TipoParametroEnum["decimal2casas"] = "Decimal 2 Casas";
    TipoParametroEnum["decimal4casas"] = "Decimal 4 Casas";
    TipoParametroEnum["simNaoEnum"] = "SimNao";
    TipoParametroEnum["data"] = "Data";
    TipoParametroEnum["senha"] = "Senha";
})(TipoParametroEnum || (TipoParametroEnum = {}));
exports.default = TipoParametroEnum;
