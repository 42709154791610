import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque-saldo-proprio-terceiro-por-local-estoque';

interface IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioComunicador {
  public async index(
    params: IEstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueSaldoProprioTerceiroPorLocalEstoqueRelatorioComunicador();
