import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/juncao-produtos';

interface IJuncaoProdutosComunicadorUpdate {
  params: {
    idProdutoBase: string;
    idProdutoJuntarExcluir: string;
  };
}

class JuncaoProdutosComunicador {
  public async update(params: IJuncaoProdutosComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}`, params.params);

    return response.data;
  }
}

export default new JuncaoProdutosComunicador();
