import { DestinadoEnum, TipoMercadoEnum } from '@elogestor/util';
import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/nota-fiscal-saida-importacao-nota-fiscal-entrada/obter-regras';

interface INotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicadorIndexIndex {
  idPessoa?: string;
  idNotaFiscalSaida?: string;
  idNotaFiscalEntrada?: string;
  dataHoraEmissao: Date;
  idTipoNota: string;
  idTipoNotaMotivo: string;
  destinado: DestinadoEnum;
  tipoMercado: TipoMercadoEnum;
}

class NotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicadorIndex {
  public async index(
    params: INotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicadorIndexIndex
  ): Promise<any> {
    const response = await api.post(rota, params);

    return response.data;
  }
}

export default new NotaFiscalSaidaImportacaoNotaFiscalEntradaObterRegrasComunicadorIndex();
