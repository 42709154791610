import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  position: relative;
`;

export const ButtonAtualizarDados = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2bcebe !important;
  color: #fff;
  font-weight: 600;
  border: 0;
  &:hover {
    background: #26b9ab !important;
  }
  &:active {
    background: #22a498 !important;
  }
  &:focus {
    background: #2bcebe !important;
    box-shadow: 0 0 0 0.2rem rgba(30, 144, 133, 0.25) !important;
  }
  &:disabled {
    opacity: 0.7;
    color: #b9b9b9;
    background: rgba(30, 144, 133, 0.2) !important;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }
  label {
    margin: 0 0 0 5px;
  }
`;
