/* eslint-disable jsx-a11y/label-has-associated-control */
import { SituacaoNotaFiscalSaidaEnum } from '@elogestor/util';
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import AutoCompleteTagBase, {
  IInputAutoCompleteTagBaseRef,
  IOnChangeListaItemAtualEvent,
  IOnAdicionarEvent,
  IOnMoverEvent,
  IOnRemoverEvent,
  IOnChangeTextoEvent,
  IFiltrarItens,
} from '../../AutoCompleteTag/AutoCompleteTagBase';
import UseRegisterAutoCompleteTag from '../../AutoCompleteTag/AutoCompleteTagBase/Hooks/UseRegisterAutoCompleteTag';

interface IOnChange {
  valorAnterior: any;
}

interface ISituacaoNotaFiscalSaidaEnum {
  descricao: string;
}

interface IInputAutoCompleteTagSituacaoNotaFiscalSaidaEnumProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  listaDescricaoParaRemover?: number[];
  onChangeListaItemAtual?: (
    evento: IOnChangeListaItemAtualEvent,
    props: IOnChange
  ) => void;
  onChangeListaItemAtualAposCarregarSemClear?: (
    evento: IOnChangeListaItemAtualEvent
  ) => void;
  onAdicionarItem?: (evento: IOnAdicionarEvent) => void;
  onRemoverItem?: (evento: IOnRemoverEvent) => void;
  onMoverItem?: (evento: IOnMoverEvent) => void;
  onChangeTexto?: (evento: IOnChangeTextoEvent) => void;
}

export interface IInputAutoCompleteTagSituacaoNotaFiscalSaidaEnumRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteTagBaseRef>;
}

const InputAutoCompleteTagSituacaoNotaFiscalSaidaEnum: React.ForwardRefRenderFunction<
  IInputAutoCompleteTagSituacaoNotaFiscalSaidaEnumRef,
  IInputAutoCompleteTagSituacaoNotaFiscalSaidaEnumProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaDescricaoParaRemover = [],

    onChangeListaItemAtual,
    onChangeListaItemAtualAposCarregarSemClear,
    onAdicionarItem,
    onRemoverItem,
    onMoverItem,
    onChangeTexto,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const handleObterChave = useCallback((item: ISituacaoNotaFiscalSaidaEnum) => {
    return item.descricao;
  }, []);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoCompleteTag(
    {
      nome: name,
      handleObterChaveUnica: handleObterChave,
    },
    {
      nomeObjeto,

      setSemExecutarEvento() {
        quantidadeAlterado.current++;
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  const handleObterLabel = useCallback((item: ISituacaoNotaFiscalSaidaEnum) => {
    return item.descricao;
  }, []);

  const handleChangeListaItemAtual = useCallback(
    (event: IOnChangeListaItemAtualEvent, { valorAnterior }: IOnChange) => {
      quantidadeAlterado.current++;

      if (onChangeListaItemAtual) {
        onChangeListaItemAtual(event, { valorAnterior });
      }

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeListaItemAtualAposCarregarSemClear
      ) {
        onChangeListaItemAtualAposCarregarSemClear(event);
      }
    },
    [flags, onChangeListaItemAtual, onChangeListaItemAtualAposCarregarSemClear]
  );

  const handleFiltrarItens = useCallback(
    async ({ valor, listaValorAtual, offset }: IFiltrarItens) => {
      setPesquisando(true);

      if (offset) return [];

      const listaRemover = [
        ...listaDescricaoParaRemover,
        ...listaValorAtual.map(
          (valorAtual: ISituacaoNotaFiscalSaidaEnum) => valorAtual.descricao
        ),
      ];

      const listaValor: any[] = [];
      const listaEnum = Object.values(SituacaoNotaFiscalSaidaEnum);

      const listaExibir = listaEnum.filter((element) => {
        return !listaRemover.includes(element);
      });

      if (listaExibir) {
        const valorFormatado = String(valor)
          .toLowerCase()
          .RemoverTodosEspacos()
          .RemoverCaracteresEspeciais();

        listaExibir.forEach((value: string) => {
          const valueFormatado = value
            .toLowerCase()
            .RemoverTodosEspacos()
            .RemoverCaracteresEspeciais();

          if (valueFormatado.includes(valorFormatado)) {
            listaValor.push({ descricao: value });
          }
        });
      }

      return listaValor;
    },
    [listaDescricaoParaRemover]
  );

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    if (rest.disabled) {
      autoCompleteRef.current?.setDisabled(true);
    } else {
      autoCompleteRef.current?.setDisabled(false);
    }
  }, [autoCompleteRef, erroUnform, rest, rest.disabled]);

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteTagBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={handleFiltrarItens}
            obterChaveUnica={handleObterChave}
            obterLabel={handleObterLabel}
            onChangeListaItemAtual={handleChangeListaItemAtual}
            onAdicionarItem={onAdicionarItem}
            onRemoverItem={onRemoverItem}
            onMoverItem={onMoverItem}
            onChangeTexto={onChangeTexto}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteTagSituacaoNotaFiscalSaidaEnum);
