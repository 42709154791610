import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/relatorio/produtos/produto-por-grupo';

interface IProdutoPorGrupoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ProdutoPorGrupoRelatorioComunicador {
  public async index(
    params: IProdutoPorGrupoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ProdutoPorGrupoRelatorioComunicador();
