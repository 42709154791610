/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import Detalhe from '../../../../../Componentes/Detalhe';
import FrenteCaixaPdvHook from './Hooks';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';

const FrenteCaixaPdvDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();

  return (
    <Container>
      <FrenteCaixaPdvHook>
        <SubHeader
          titulo="PDV (Frente de Caixa)"
          permissao={permissoes.ComercialFrenteCaixaPdv}
        />
        <Detalhe onSairDoFormulario={() => {}}>
          <Main
            idEditar={null}
            dadosDuplicados={null}
            dadosRecuperados={null}
          />
        </Detalhe>
      </FrenteCaixaPdvHook>
    </Container>
  );
};

export default FrenteCaixaPdvDetalhe;
