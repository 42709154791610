import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TipoUsuarioEnum } from '@elogestor/util';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputEmail from '../../../../../Componentes/Inputs/InputEmail';
import Select from '../../../../../Componentes/Select';
import InputSenha from '../../../../../Componentes/Inputs/InputSenha';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import { UseUsuarioTipo } from '../Hooks/UsuarioTipoHook';

const GeralTab: React.FC = () => {
  const { tipoUsuario: tipoUsuarioLogado } = UsePermissoes();
  const { inputRefFocus, refresh } = UseForm();
  const { setAlterouTipo } = UseUsuarioTipo();

  return (
    <fieldset>
      <Divisor>
        <Row>
          <Col lg={8} md={8} sm={12}>
            <Input
              ref={inputRefFocus}
              label="Nome"
              name="nome"
              placeholder="Nome"
              maxLength={125}
              disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Select
              label="Tipo"
              name="tipo"
              disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
              options={[
                { title: 'Gestor', value: 'Gestor' },
                { title: 'Colaborador', value: 'Colaborador' },
                { title: 'Contabilidade', value: 'Contabilidade' },
                { title: 'Representante', value: 'Representante' },
                {
                  title: 'Elo Gestor',
                  value: 'Elo Gestor',
                  hidden: tipoUsuarioLogado !== TipoUsuarioEnum.eloGestor,
                },
              ]}
              onChange={() => {
                refresh();
                setAlterouTipo((state) => !state);
              }}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <InputEmail
              label="E-mail"
              name="email"
              placeholder="E-mail"
              maxLength={60}
              disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <input
              name="loginEscondido"
              placeholder="Nome de Usuário Escondido"
              style={{ opacity: 0, position: 'absolute', width: 10 }}
            />
            <Input
              label="Nome de Usuário"
              name="login"
              placeholder="Nome de Usuário"
              maxLength={50}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <input
              type="password"
              style={{ opacity: 0, position: 'absolute', width: 10 }}
            />
            <InputSenha
              autoComplete="off"
              label="Senha"
              name="senha"
              placeholder="Senha"
              maxLength={200}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <InputSenha
              label="Repita a Senha"
              name="repetirSenha"
              placeholder="Repita a Senha"
              maxLength={200}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <InputSwitch
              name="ativo"
              label="Acesso ao Sistema"
              disabled={tipoUsuarioLogado === TipoUsuarioEnum.representante}
            />
          </Col>
        </Row>
      </Divisor>
    </fieldset>
  );
};

export default GeralTab;
