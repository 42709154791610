/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { IImpressaoEtiquetasProdutosTransferirLista } from '@elogestor/util';
import Main from './Main';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Detalhe/SubHeader';
import Detalhe from '../../../../../Componentes/Detalhe';
import ImpressaoEtiquetasProdutosHook from './Hooks';
import AcoesImpressaoEtiquetasProdutos from './AcoesImpressaoEtiquetasProdutos';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';

const ImpressaoEtiquetasProdutosDetalhe: React.FC = () => {
  const { permissoes } = UsePermissoes();

  const storage = localStorage.getItem('@EloGestorle:etiquetas');
  const listaProdutoEtiquetaSelecionado = JSON.parse(
    String(storage)
  ) as IImpressaoEtiquetasProdutosTransferirLista[];

  return (
    <Container>
      <ImpressaoEtiquetasProdutosHook>
        <SubHeader
          titulo="Impressão de Etiquetas dos Produtos"
          permissao={permissoes.SuprimentosProdutosImpressaoEtiquetasProdutos}
          botoes={<AcoesImpressaoEtiquetasProdutos />}
        />
        <Detalhe onSairDoFormulario={() => {}}>
          <Main
            idEditar={null}
            dadosDuplicados={null}
            dadosRecuperados={null}
            listaProdutoEtiquetaSelecionado={
              storage ? listaProdutoEtiquetaSelecionado : undefined
            }
          />
        </Detalhe>
      </ImpressaoEtiquetasProdutosHook>
    </Container>
  );
};

export default ImpressaoEtiquetasProdutosDetalhe;
