import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseSituacaoBloquearCampos } from '../../../../Hook/SituacaoBloquearCamposHook';

const ItemComercialTab: React.FC = () => {
  const { formRefDetalhe } = UseListaDetalheForm();

  const camposDesabilitados =
    !formRefDetalhe.current?.getFieldValue('idProduto');

  const { situacaoBloquearCampos } = UseSituacaoBloquearCampos();

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={4}>
            <InputSwitch
              label="Gerar Financeiro"
              ativo="Sim"
              inativo="Não"
              name="notaFiscalEntradaItemComercial.gerarFinanceiro"
              disabled={camposDesabilitados || situacaoBloquearCampos}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemComercialTab;
