import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/pedido-venda/pedido-venda-resumo';

interface IPedidoVendaResumoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoVendaResumoRelatorioComunicador {
  public async index(
    params: IPedidoVendaResumoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoVendaResumoRelatorioComunicador();
