import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-template-areas:
    'd'
    'c';
`;

export const Descricao = styled.div`
  grid-area: d;
`;

export const DivisorDescricao = styled.div`
  span {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
`;

export const Conteudo = styled.div`
  grid-area: c;
`;
