import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/alterar-senha';

interface IUsuarioAlterarSenhaComunicadorUpdate {
  id: string;
  params: any;
}

class UsuarioAlterarSenhaComunicador {
  public async update(
    params: IUsuarioAlterarSenhaComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);
    return response.data;
  }
}

export default new UsuarioAlterarSenhaComunicador();
