/* eslint-disable prefer-destructuring */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Modal, Container, Button, Table, Row, Col } from 'react-bootstrap';
import { v4 } from 'uuid';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormatarDataHoraParaPtBr,
  IFrenteCaixaPdvRetiradaConsignadoPesquisarLista,
} from '@elogestor/util';
import { AiOutlineCheck, AiOutlineFileSearch } from 'react-icons/ai/index.mjs';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import Paginacao from './Paginacao';
import { UseTableDraggable } from '../../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import FrenteCaixaPdvObterRetiradaConsignadoComunicador from '../../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterRetiradaConsignadoComunicador';
import { UltimaTr } from '../../Main/styles';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteCliente from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';
import InputTag from '../../../../../../../Componentes/Inputs/TagsInput/InputTag';
import InputAutoCompletePessoaFinalidadeUsoCliente from '../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteFinalidadeUsoCliente';
import InputAutoCompleteTagFinalidadeUsoCliente from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFinalidadeUsoCliente';
import InputAutoCompleteTagProduto from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputPeriodoData from '../../../../../../../Componentes/Inputs/InputPeriodoData';

interface IFaturarRetiradaConsignadoModal {
  titulo: string;
  onSelecionarListaItemFormModal(
    listaIdItemConsignadoSelecionado: string[]
  ): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const FaturarRetiradaConsignado: React.FC<IFaturarRetiradaConsignadoModal> = ({
  titulo,
  onFecharFormModal,
  onSelecionarListaItemFormModal,
  loading,
  setLoading,
}) => {
  const {
    listaValor,
    setListaValor,
    paginaAtual,
    setPaginaAtual,
    limite,
    setLimite,
    setTotalPaginas,
  } = UseListaDraggable();
  const { setParametros } = UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);
  const listaSelecionadoRef = useRef<HTMLInputElement[]>([]);

  const [valorTotalSelecionado, setValorTotalSelecionado] = useState<number>(0);

  const handlePesquisar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      let quantidadePaginas = 1;

      const dataPesquisa = formRef.current?.getData() as any;

      const response =
        await FrenteCaixaPdvObterRetiradaConsignadoComunicador.index({
          params: {
            dataEmissaoInicial: dataPesquisa.dataEmissaoInicial,
            dataEmissaoFinal: dataPesquisa.dataEmissaoFinal,
            idCliente: dataPesquisa.idCliente,
            listaIdFinalidadeUsoCliente:
              dataPesquisa?.listaIdFinalidadeUsoCliente?.listaValor,
            listaIdProduto: dataPesquisa?.listaIdProduto?.listaValor,
            listaNumero: dataPesquisa?.listaNumero?.listaValor,
            order: undefined,
            descAsc: undefined,
            limite,
            pagina: paginaAtual,
          },
        });

      const listaDados = response.dados[0].map((data: any) => {
        const retorno: IFrenteCaixaPdvRetiradaConsignadoPesquisarLista = {
          id: data.id,
          selecionado: false,
          pedidoVenda: {
            numero: data.pedidoVenda.numero,
            dataHoraEmissao: data.pedidoVenda.dataHoraEmissao,

            cliente: {
              id: data.pedidoVenda.pedidoVendaPessoa.pessoa.id,
              codigo: data.pedidoVenda.pedidoVendaPessoa.pessoa.codigo,
              nomeRazaoSocial:
                data.pedidoVenda.pedidoVendaPessoa.pessoa.nomeRazaoSocial,
            },
          },
          produto: {
            id: data.produto.id,
            codigo: data.produto.codigo,
            descricao: data.produto.descricao,
          },
          quantidade: Number(data.quantidadeRestante),
          valorUnitario: Number(data.valorUnitario),
          valorTotalProduto: Number(data.valorTotalProduto),
        };

        return retorno;
      });

      quantidadePaginas = response.dados[1];

      setListaValor(listaDados);
      setTotalPaginas(quantidadePaginas);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [limite, paginaAtual, setListaValor, setLoading, setTotalPaginas]);

  const handleAtualizarPadraoModal = useCallback(async (): Promise<void> => {
    setListaValor([]);
    setPaginaAtual(1);
    setTotalPaginas(1);
    setLimite(10);
    setParametros({
      pagina: 1,
      pesquisaAvancada: undefined,
    });
  }, [
    setLimite,
    setListaValor,
    setPaginaAtual,
    setParametros,
    setTotalPaginas,
  ]);

  const handleClickOk = useCallback(async (): Promise<void> => {
    const listaItemConsignadoSelecionado = listaValor.filter(
      (e) => e.selecionado
    );

    if (
      !listaItemConsignadoSelecionado ||
      listaItemConsignadoSelecionado.length === 0
    ) {
      TratarErros({ mensagem: 'Selecione ao menos 1 item!' });
      return;
    }

    const listaIdCliente = listaItemConsignadoSelecionado.map(
      (e) => e.pedidoVenda.cliente.id
    );

    if (!listaIdCliente.every((elemento) => elemento === listaIdCliente[0])) {
      TratarErros({ mensagem: 'Seleciona Itens somente de um mesmo Cliente!' });
      return;
    }

    const listaIdItemConsignadoSelecionado = listaItemConsignadoSelecionado.map(
      (e) => e.id
    );

    handleAtualizarPadraoModal();
    if (onSelecionarListaItemFormModal)
      onSelecionarListaItemFormModal(listaIdItemConsignadoSelecionado);
  }, [handleAtualizarPadraoModal, listaValor, onSelecionarListaItemFormModal]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    handleAtualizarPadraoModal();

    if (onFecharFormModal) onFecharFormModal();
  }, [handleAtualizarPadraoModal, onFecharFormModal]);

  const handleAtualizarValorSubTotal = useCallback(() => {
    const valor = listaValor
      .filter((item) => {
        return item?.selecionado;
      })
      .reduce(
        (
          acumulador: number,
          item: IFrenteCaixaPdvRetiradaConsignadoPesquisarLista
        ) => {
          acumulador += Number(item.valorTotalProduto) || 0;
          return acumulador;
        },
        0
      );

    setValorTotalSelecionado(valor);
  }, [listaValor]);

  const handleSelecionarTodos = useCallback(
    (valor: boolean) => {
      listaSelecionadoRef.current.forEach((e) => {
        e.checked = valor;
      });
      listaValor.forEach((e) => {
        e.selecionado = valor;
      });
      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  const handleAlterarSelecionado = useCallback(
    (valor: boolean, index: number) => {
      listaValor[index].selecionado = valor;

      handleAtualizarValorSubTotal();
    },
    [handleAtualizarValorSubTotal, listaValor]
  );

  useEffect(() => {
    formRef.current?.setFieldValorInicial('dataEmissaoInicial', new Date());
    formRef.current?.setFieldValorInicial('dataEmissaoFinal', new Date());
  }, []);

  useEffect(() => {
    handlePesquisar();
  }, [paginaAtual, limite, handlePesquisar]);

  return (
    <JanelaDetalhe
      titulo={titulo}
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Header>
          <Divisor style={{ width: '100%' }}>
            <Row>
              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputTag name="listaNumero" label="Números" />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteCliente label="Cliente" name="idCliente" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagFinalidadeUsoCliente
                  label="Finalidades de Uso"
                  name="listaIdFinalidadeUsoCliente"
                />
              </Col>
              <Col lg={10} md={12} sm={12}>
                <InputAutoCompleteTagProduto
                  label="Produtos"
                  name="listaIdProduto"
                />
              </Col>

              <Col
                lg={2}
                md={12}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <Button
                  type="button"
                  className="btn-padrao btn-adicionar"
                  style={{ margin: 0, height: '38px' }}
                  onClick={handlePesquisar}
                  disabled={loading}
                >
                  <TextoLoading loading={loading}>
                    <AiOutlineFileSearch />
                    <span style={{ marginLeft: 10 }}>Pesquisar</span>
                  </TextoLoading>
                </Button>
              </Col>
            </Row>
          </Divisor>
        </Modal.Header>

        <Modal.Body style={{ height: 450, display: 'flex' }}>
          <Divisor style={{ width: '100%', display: 'grid' }}>
            <Tabela style={{ overflow: 'auto', width: '100%' }}>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th
                      className="lista-texto"
                      style={{
                        width: '5%',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                      }}
                    >
                      <input
                        style={{ width: 13, height: 13 }}
                        type="checkbox"
                        ref={selecionarTodosRef}
                        onChange={(event) => {
                          handleSelecionarTodos(event.target.checked);
                        }}
                      />
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '5%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Número</span>
                    </th>
                    <th
                      className="lista-data"
                      style={{
                        width: '15%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Data Hora Emissão</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '25%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Produto</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Quantidade</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Valor</span>
                    </th>
                    <th
                      className="lista-valor"
                      style={{
                        width: '10%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Valor Total</span>
                    </th>
                    <th
                      className="lista-texto"
                      style={{
                        width: '20%',
                        textAlign: 'start',
                        backgroundColor: 'white',
                      }}
                    >
                      <span>Cliente</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaValor?.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={v4()}
                          onClick={async () => {
                            handleAlterarSelecionado(
                              !listaSelecionadoRef.current[index].checked,
                              index
                            );
                          }}
                        >
                          <td
                            className="lista-acoes"
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            <input
                              style={{ width: 13, height: 13 }}
                              type="checkbox"
                              defaultChecked={item.selecionado}
                              ref={(instance) => {
                                if (instance)
                                  listaSelecionadoRef.current[index] = instance;
                              }}
                              onChange={(event) => {
                                handleAlterarSelecionado(
                                  event.target.checked,
                                  index
                                );
                              }}
                            />
                          </td>
                          <td className="lista-valor">
                            {item.pedidoVenda.numero}
                          </td>
                          <td className="lista-data">
                            {FormatarDataHoraParaPtBr(
                              item.pedidoVenda.dataHoraEmissao
                            )}
                          </td>
                          <td className="lista-texto">
                            {`${item.produto.codigo} - ${item.produto.descricao}`}
                          </td>
                          <td className="lista-valor">
                            {item.quantidade.FormatarParaPtBr()}
                          </td>
                          <td className="lista-valor">
                            {item.valorUnitario.FormatarParaPtBr()}
                          </td>
                          <td className="lista-valor">
                            {item.valorTotalProduto.FormatarParaPtBr()}
                          </td>
                          <td className="lista-texto">
                            {`${item.pedidoVenda.cliente.codigo} - ${item.pedidoVenda.cliente.nomeRazaoSocial}`}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>

                <tfoot>
                  <UltimaTr>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th>
                      <div
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>Valor Sub. Total</div>
                        <div style={{ alignSelf: 'flex-end' }}>
                          {valorTotalSelecionado.FormatarParaPtBr()}
                        </div>
                      </div>
                    </th>
                    <th />
                  </UltimaTr>
                </tfoot>
              </Table>
            </Tabela>

            <Paginacao />
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickOk}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default FaturarRetiradaConsignado;
