import React, { createContext, useCallback, useContext, useState } from 'react';
import { produce } from 'immer';
import {
  AppErro,
  IRoteiroFabricacaoPadraoOperacaoLista,
} from '@elogestor/util';
import IPadraoProps from '../../../../../../../Comum/Interface/IPadraoProps';

interface IRoteiroFabricacaoPadraoOperacaoTabContext {
  validador: boolean;

  listaValor: IRoteiroFabricacaoPadraoOperacaoLista[];
  setListaValor: React.Dispatch<
    React.SetStateAction<IRoteiroFabricacaoPadraoOperacaoLista[]>
  >;

  handleMover(de: number, para: number): void;
  handleAtualizarOrdem(
    lista?: IRoteiroFabricacaoPadraoOperacaoLista[]
  ): Promise<void>;

  paginaAtual: number;
  setPaginaAtual: React.Dispatch<React.SetStateAction<number>>;
  totalPaginas: number;
  setTotalPaginas: React.Dispatch<React.SetStateAction<number>>;
  limite: number;
  setLimite: React.Dispatch<React.SetStateAction<number>>;
}

const RoteiroFabricacaoPadraoOperacaoTabContext =
  createContext<IRoteiroFabricacaoPadraoOperacaoTabContext>(
    {} as IRoteiroFabricacaoPadraoOperacaoTabContext
  );

const ListaRoteiroFabricacaoPadraoOperacaoDraggableHook: React.FC<
  IPadraoProps
> = ({ children }) => {
  const [listaValor, setListaValor] = useState<
    IRoteiroFabricacaoPadraoOperacaoLista[]
  >([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [limite, setLimite] = useState(10);

  const handleMover = useCallback((de: number, para: number) => {
    setListaValor((state) =>
      produce(state, (draft) => {
        const aux = draft[de].ordem;
        draft[de].ordem = draft[para].ordem;
        draft[para].ordem = aux;
        const valorDe = JSON.parse(JSON.stringify(draft[de])) as any;
        const valorPara = JSON.parse(JSON.stringify(draft[para])) as any;

        draft[de] = valorPara;
        draft[para] = valorDe;
      })
    );
  }, []);

  const handleAtualizarOrdem = useCallback(() => {
    throw new AppErro({
      mensagem:
        'A função "handleAtualizarOrdem" não foi implementada no "ListaRoteiroFabricacaoOperacaoDraggableHook"',
    });
  }, []);

  return (
    <RoteiroFabricacaoPadraoOperacaoTabContext.Provider
      value={{
        listaValor,
        setListaValor,
        handleMover,
        handleAtualizarOrdem,
        paginaAtual,
        setPaginaAtual,
        totalPaginas,
        setTotalPaginas,
        limite,
        setLimite,
        validador: true,
      }}
    >
      {children}
    </RoteiroFabricacaoPadraoOperacaoTabContext.Provider>
  );
};

function UseListaRoteiroFabricacaoPadraoOperacaoDraggable(): Omit<
  IRoteiroFabricacaoPadraoOperacaoTabContext,
  'validador'
> {
  const context = useContext(RoteiroFabricacaoPadraoOperacaoTabContext);

  if (!context.validador) {
    throw new Error(
      `UseListaRoteiroFabricacaoPadraoOperacaoDraggable deve ser usado
       com um ListaRoteiroFabricacaoPadraoOperacaoDraggableHook`
    );
  }

  return context;
}

export {
  ListaRoteiroFabricacaoPadraoOperacaoDraggableHook,
  UseListaRoteiroFabricacaoPadraoOperacaoDraggable,
};
