/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { EstoqueIndicadorTipoEnum, FormatarEnum } from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../../../../Componentes/Select';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import NotaFiscalEntradaItemEstoqueLocalEstoqueHook from './ListaDetalheItemEstoqueLocalEstoque/Hooks';
import ListaDetalheItemEstoqueLocalEstoque from './ListaDetalheItemEstoqueLocalEstoque/Lista';
import { UseItemEstoque } from '../../Hook/ItemEstoqueHook';
import { UseItemNotaFiscalEntrada } from '../../Hook/ItemNotaFiscalEntradaHook';
import InputAutoCompletePessoa from '../../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompletePessoa';

interface IItemEstoqueTab {
  formRefItem: React.RefObject<IFormCiaHandles>;
}

const ItemEstoqueTab: React.FC<IItemEstoqueTab> = ({ formRefItem }) => {
  const { formRefDetalhe, refresh } = UseListaDetalheForm();
  const { selecionarItemEstoqueLocalEstoque } = UseItemEstoque();
  const { setListaLocalEstoque } = UseItemNotaFiscalEntrada();

  const movimentarEstoque = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemEstoque.movimentarEstoque'
  );
  const movimentarEstoqueFiscal = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal'
  );
  const porContaOrdemTerceiro = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemEstoque.porContaOrdemTerceiro'
  );
  const indicadorTipoEstoque = formRefItem.current?.getFieldValue(
    'notaFiscalEntradaItemEstoque.indicadorTipoEstoque'
  );

  const handleOnAlterarFatorConversao = useCallback(async (): Promise<void> => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const fatorConversao = formRefDetalhe.current?.getFieldValue(
      'notaFiscalEntradaItemEstoque.fatorConversao'
    );

    const quantidadeEstoque = (
      Number(quantidade) * Number(fatorConversao)
    ).Arredondar(4);

    formRefDetalhe.current?.setFieldValue(
      'notaFiscalEntradaItemEstoque.quantidadeEstoque',
      quantidadeEstoque
    );
  }, [formRefDetalhe]);

  const handleOnAlterarQuantidadeEstoque =
    useCallback(async (): Promise<void> => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const quantidadeEstoque = formRefDetalhe.current?.getFieldValue(
        'notaFiscalEntradaItemEstoque.quantidadeEstoque'
      );
      const fatorConversao = (
        Number(quantidadeEstoque) / Number(quantidade)
      ).Arredondar(10);

      formRefDetalhe.current?.setFieldValue(
        'notaFiscalEntradaItemEstoque.fatorConversao',
        fatorConversao
      );
    }, [formRefDetalhe]);

  const handleSelecionarItemEstoqueLocalEstoque = useCallback(
    async (limparLista?: boolean) => {
      selecionarItemEstoqueLocalEstoque(limparLista);
    },
    [selecionarItemEstoqueLocalEstoque]
  );

  const handleSetarPorContaOrdemTerceiro = useCallback(
    async (estoqueIndicadorTipoEstoque: string) => {
      switch (estoqueIndicadorTipoEstoque) {
        case EstoqueIndicadorTipoEnum.proprioEmPoderProprio:
          formRefDetalhe.current?.setFieldValue(
            'notaFiscalEntradaItemEstoque.porContaOrdemTerceiro',
            false
          );
          break;

        case EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro:
          formRefDetalhe.current?.setFieldValue(
            'notaFiscalEntradaItemEstoque.porContaOrdemTerceiro',
            true
          );
          break;
      }
    },
    [formRefDetalhe]
  );

  const handleSetarMovimentarEstoqueFiscal = useCallback(
    async (estoqueIndicadorTipoEstoque: string) => {
      if (
        estoqueIndicadorTipoEstoque !==
        EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
      ) {
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal',
          false
        );
      }
    },
    [formRefDetalhe]
  );

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Select
              label="Tipo de Estoque"
              name="notaFiscalEntradaItemEstoque.indicadorTipoEstoque"
              options={FormatarEnum({
                enumObj: EstoqueIndicadorTipoEnum,
              })}
              onChangeValue={() => {
                refresh();
              }}
              onChange={(event) => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
                handleSetarPorContaOrdemTerceiro(event.currentTarget.value);
                handleSetarMovimentarEstoqueFiscal(event.currentTarget.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputSwitch
              name="notaFiscalEntradaItemEstoque.movimentarEstoque"
              label="Movimentar Estoque"
              ativo="Sim"
              inativo="Não"
              onChange={(event) => {
                refresh();
                if (event.target.checked)
                  handleSelecionarItemEstoqueLocalEstoque(true);
              }}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            style={{
              display:
                indicadorTipoEstoque !==
                EstoqueIndicadorTipoEnum.terceiroEmPoderProprio
                  ? 'none'
                  : '',
            }}
          >
            <InputSwitch
              label="Movimentar Estoque Fiscal"
              name="notaFiscalEntradaItemEstoque.movimentarEstoqueFiscal"
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                refresh();
              }}
            />
          </Col>
        </Row>

        <Row style={{ display: !movimentarEstoque ? 'none' : '' }}>
          <Col lg={4} md={12} sm={12}>
            <InputAutoCompleteUnidadeMedida
              label="Unidade de Medida do Estoque"
              name="notaFiscalEntradaItemEstoque.idUnidadeMedidaEstoque"
              placeholder="Unidade de Medida do Estoque"
              nomeObjeto="notaFiscalEntradaItemEstoque.unidadeMedidaEstoque"
              permitirAdicionar={false}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimalNulavel
              label="Fator de Conversão"
              name="notaFiscalEntradaItemEstoque.fatorConversao"
              casasInteiras={11}
              casasDecimais={10}
              onChange={handleOnAlterarFatorConversao}
              onBlur={() => {
                refresh();
                handleSelecionarItemEstoqueLocalEstoque();
              }}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputDecimalNulavel
              label="Qtde. Estoque (Qtde. * Fat. Conv.)"
              name="notaFiscalEntradaItemEstoque.quantidadeEstoque"
              casasInteiras={11}
              casasDecimais={4}
              onChange={handleOnAlterarQuantidadeEstoque}
              onBlur={() => {
                refresh();
                handleSelecionarItemEstoqueLocalEstoque();
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            display:
              !movimentarEstoque && !movimentarEstoqueFiscal ? 'none' : '',
          }}
        >
          <Col lg={2} md={3} sm={12}>
            <InputSwitch
              name="notaFiscalEntradaItemEstoque.porContaOrdemTerceiro"
              label="Por Conta e Ord. Terc."
              ativo="Sim"
              inativo="Não"
              onChange={() => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
              }}
              disabled={
                indicadorTipoEstoque ===
                  EstoqueIndicadorTipoEnum.proprioEmPoderProprio ||
                indicadorTipoEstoque ===
                  EstoqueIndicadorTipoEnum.terceiroEmPoderTerceiro
              }
            />
          </Col>
          <Col
            lg={10}
            md={9}
            sm={12}
            style={{ display: !porContaOrdemTerceiro ? 'none' : '' }}
          >
            <InputAutoCompletePessoa
              label="Pessoa Terceiro"
              name="notaFiscalEntradaItemEstoque.idPessoaTerceiro"
              placeholder="Pessoa Terceiro"
              nomeObjeto="notaFiscalEntradaItemEstoque.pessoaTerceiro"
              permitirAdicionar={false}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                setListaLocalEstoque([]);
                handleSelecionarItemEstoqueLocalEstoque(true);
              }}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor style={{ display: !movimentarEstoque ? 'none' : '' }}>
        <Row>
          <Col md={12}>
            <NotaFiscalEntradaItemEstoqueLocalEstoqueHook>
              <ListaDetalheItemEstoqueLocalEstoque
                name="notaFiscalEntradaItemEstoque.listaNotaFiscalEntradaItemEstoqueLocalEstoque"
                formRefItem={formRefItem}
              />
            </NotaFiscalEntradaItemEstoqueLocalEstoqueHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemEstoqueTab;
