import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import {
  FinalidadeEmissaoEnum,
  IAnaliseCustoVendaLista,
  TipoProdutoServicoEnum,
} from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimal, {
  IInputDecimalRef,
} from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputDateTime from '../../../../../../../../Componentes/Inputs/InputDateTime';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import Divisor from '../../../../../../../../Componentes/Divisor';
import ItemInformacaoAdicionalTab from './ItemInformacaoAdicionalTab/index';
import InputInteiroNulavel from '../../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseItemTab } from '../Hook/ItemTabHook';
import ItemOutrosValoresTab from './ItemOutrosValoresTab';
import ItemImpostoTab from './ItemImpostoTab';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import ItemComercialTab from './ItemComercialTab';
import InputCheckbox from '../../../../../../../../Componentes/Inputs/InputCheckbox';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import InputAutoCompleteProdutoServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import ObterOrigemIcmsComunicador from '../../../../../../../../Comunicador/Fiscal/Icms/Origem/ObterOrigemIcmsComunicador/ObterOrigemIcmsComunicador';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ItemEngenhariaTab from './ItemEngenhariaTab';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import { UseLiberacoes } from '../../../../../../../../Hooks/LiberacoesHook';
import ItemVeiculoTab from './ItemVeiculoTab';

interface IOrcamentoItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const OrcamentoItemDetalhe: React.FC<IOrcamentoItemModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
  onLimparFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialVendasOrcamento: permissao } = permissoes;
  const liberacoes = UseLiberacoes();
  const parametros = UseParametros();
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    refresh,
    inputRefFocus,
    handleSetarFocus,
  } = UseListaDetalheForm();
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { formRef: formPrincipal } = UseForm();

  const {
    calculaValorTotalProduto,
    calcularRateioItem,
    setItemOrdem,
    obterConfiguracaoTributariaConformeProduto,
    calcularQuantidadeTributadoProdutoTributado,
    calcularValorUnitarioTributadoProdutoTributado,
    calcularBaseCalculoItemComercialRepresentante,
    obterRegraEscolhaAliquota,
    obterRegraEscolhaTabelaPreco,
    calcularItemEngenhariaPesagem,
    calcularItemEngenhariaMetragem,
    limparConfiguracaoTributaria,
  } = UseItemTab();

  const idDetalheRegistro = getIdDetalheRegistro();

  const subtotalRef = useRef<IInputDecimalRef>(null);
  const debounceChangeRef = useRef<number | null>(null);

  const [tabSelecionada, setTabSelecionada] = useState('impostos');

  const [comercializado, setComercializado] = useState(
    formPrincipal.current?.getFieldValue('finalidadeEmissao') ===
      FinalidadeEmissaoEnum.normal
  );

  const handleObterValoresProdutoEngenharia = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

    const produtoEngenharia = produto?.produtoEngenharia;
    const pesoBruto = produtoEngenharia?.pesoBruto;
    const pesoLiquido = produtoEngenharia?.pesoLiquido;
    const quantidadeVolumes =
      produtoEngenharia?.quantidadeVolumes === null &&
      parametros.ConsiderarQuantidadeVolumesUnitarioComo1
        ? 1
        : produtoEngenharia?.quantidadeVolumes;
    const metroQuadrado = produtoEngenharia?.metroQuadrado;
    const metroCubico = produtoEngenharia?.metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.pesoBrutoUnitario',
      pesoBruto
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.pesoLiquidoUnitario',
      pesoLiquido
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.quantidadeVolumesUnitario',
      quantidadeVolumes
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.metroQuadradoUnitario',
      metroQuadrado
    );
    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.metroCubicoUnitario',
      metroCubico
    );
  }, [formRefDetalhe, parametros.ConsiderarQuantidadeVolumesUnitarioComo1]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleClickSalvarENovo = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await formRefDetalhe.current?.reset();
    if (onLimparFormModal) onLimparFormModal();

    handleCarregarDados();

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleCarregarDados,
    handleSetarFocus,
    handleSubmit,
    onLimparFormModal,
  ]);

  const handleClickSalvarEContinuar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro, id } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    setIdDetalheRegistro(id);

    if (handleSetarFocus) {
      await Sleep(1);
      handleSetarFocus();
    }
  }, [formRefDetalhe, handleSetarFocus, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados, idDetalheRegistro]);

  useEffect(() => {
    if (!idDetalheRegistro) setItemOrdem();
  }, [idDetalheRegistro, setItemOrdem]);

  useEffect(() => {
    if (formPrincipal.current) {
      const finalidadeEmissao =
        formPrincipal.current.getFieldValue('finalidadeEmissao');
      if (
        finalidadeEmissao === FinalidadeEmissaoEnum.ajuste ||
        finalidadeEmissao === FinalidadeEmissaoEnum.complemento
      ) {
        const inputRef =
          formRefDetalhe.current?.getFieldRef('valorTotalProduto');
        inputRef.disabled = false;
      }
    }
  }, [formPrincipal, formRefDetalhe]);

  const handleCalcularValorTotalProdutos = useCallback(() => {
    calculaValorTotalProduto();
  }, [calculaValorTotalProduto]);

  const handleCalcularItemEngenhariaPesagem = useCallback(() => {
    calcularItemEngenhariaPesagem();
  }, [calcularItemEngenhariaPesagem]);

  const handleCalcularItemEngenhariaMetragem = useCallback(() => {
    calcularItemEngenhariaMetragem();
  }, [calcularItemEngenhariaMetragem]);

  const handleObterTabelaPreco = useCallback(async () => {
    obterRegraEscolhaTabelaPreco();
  }, [obterRegraEscolhaTabelaPreco]);

  const handleCalcularRateioItem = useCallback(() => {
    calcularRateioItem();
  }, [calcularRateioItem]);

  const handleObterConfiguracaoTributariaConformeProduto = useCallback(() => {
    obterConfiguracaoTributariaConformeProduto();
  }, [obterConfiguracaoTributariaConformeProduto]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleCalcularQuantidadeTributadoProdutoTributado = useCallback(() => {
    calcularQuantidadeTributadoProdutoTributado();
  }, [calcularQuantidadeTributadoProdutoTributado]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(() => {
      calcularValorUnitarioTributadoProdutoTributado();
    }, [calcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota = useCallback(async () => {
    await obterRegraEscolhaAliquota();
  }, [obterRegraEscolhaAliquota]);

  const handleObterOrigemIcms = useCallback(async () => {
    try {
      const origemIcms = await ObterOrigemIcmsComunicador.index({
        params: {
          codigo: 0,
        },
      });
      if (origemIcms) {
        formRefDetalhe.current?.setFieldValue(
          'orcamentoItemImpostoIcms.origemIcms',
          origemIcms
        );
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe]);

  // #region Analise Custo

  const handleListaItemAnalisCustos =
    useCallback((): IAnaliseCustoVendaLista[] => {
      const data = formRefDetalhe.current?.getDataRecuperarFormulario();

      const dataHoraEmissao =
        formPrincipal.current?.getFieldValue('dataHoraEmissao');
      const indicadorPresenca =
        formPrincipal.current?.getFieldValue('indicadorPresenca');
      const modeloDocumentoOrcamento =
        formPrincipal.current?.getFieldValueNomeObjeto('modeloDocumento');
      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'orcamentoPessoa.pessoa'
      );
      const listaOrcamentoItem =
        formPrincipal.current?.getFieldValueNomeObjeto('listaOrcamentoItem');

      const listaItemVenda: IAnaliseCustoVendaLista[] = [];

      if (data && !idDetalheRegistro) {
        let valorIcms = 0;
        if (data.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms) {
          valorIcms += Number(
            data.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms
          );
        }

        if (
          data.orcamentoItemImpostoIcmsOperacaoInterestadual
            ?.estadoDestinoValorIcmsComFcp
        ) {
          valorIcms += Number(
            data.orcamentoItemImpostoIcmsOperacaoInterestadual
              ?.estadoDestinoValorIcmsComFcp
          );
        }

        const itemVenda: IAnaliseCustoVendaLista = {
          isFrenteCaixa: false,
          recalcularImpostos: false,

          id: data.id,
          sequencia: data.ordem,

          dataHoraMovimento: dataHoraEmissao,
          pessoa,
          modeloDocumento: modeloDocumentoOrcamento,

          produto: data.produto,
          destinado: data.destinado,
          indicadorPresenca,
          tipoNota: data.tipoNota,
          tipoNotaMotivo: data.tipoNotaMotivo,

          quantidade: data.quantidade,
          valorTotalBruto: data.valorTotalBruto,
          valorTotalImpostosOutros: 0,
          valorUnitariaCusto: 0,
          valorTotalCusto: 0,
          valorUnitarioVenda: data.valorUnitario,
          valorTotalProduto: data.valorTotalProduto,

          valorFrete: data.valorFrete,
          valorSeguro: data.valorSeguro,
          valorOutrasDespesas: data.valorOutrasDespesas,
          valorDesconto: data.valorDesconto,

          valorIcms,
          valorIcmsStEValorFcpIcmsSt:
            data.orcamentoItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt,
          valorIpi: data.orcamentoItemImpostoIpi?.valor,
          valorPis: data.orcamentoItemImpostoPis?.valor,
          valorCofins: data.orcamentoItemImpostoCofins?.valor,
          valorIrpj: 0,
          valorCsll: 0,
          valorIcmsSn: 0,
          valorOutros: 0,

          margemLiquidaValor: 0,
          margemLiquidaPercentual: 0,
        };

        listaItemVenda.push(itemVenda);
      }

      for (let i = 0; i < listaOrcamentoItem.length; i++) {
        const orcamentoItem = listaOrcamentoItem[i];

        if (
          data &&
          idDetalheRegistro &&
          orcamentoItem.id === idDetalheRegistro
        ) {
          let valorIcms = 0;
          if (data.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms) {
            valorIcms += Number(
              data.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms
            );
          }

          if (
            data.orcamentoItemImpostoIcmsOperacaoInterestadual
              ?.estadoDestinoValorIcmsComFcp
          ) {
            valorIcms += Number(
              data.orcamentoItemImpostoIcmsOperacaoInterestadual
                ?.estadoDestinoValorIcmsComFcp
            );
          }

          const itemVenda: IAnaliseCustoVendaLista = {
            isFrenteCaixa: false,
            recalcularImpostos: false,

            id: data.id,
            sequencia: data.ordem,

            dataHoraMovimento: dataHoraEmissao,
            pessoa,
            modeloDocumento: modeloDocumentoOrcamento,

            produto: data.produto,
            destinado: data.destinado,
            indicadorPresenca,
            tipoNota: data.tipoNota,
            tipoNotaMotivo: data.tipoNotaMotivo,

            quantidade: data.quantidade,
            valorTotalBruto: data.valorTotalBruto,
            valorTotalImpostosOutros: 0,
            valorUnitariaCusto: 0,
            valorTotalCusto: 0,
            valorUnitarioVenda: data.valorUnitario,
            valorTotalProduto: data.valorTotalProduto,

            valorFrete: data.valorFrete,
            valorSeguro: data.valorSeguro,
            valorOutrasDespesas: data.valorOutrasDespesas,
            valorDesconto: data.valorDesconto,

            valorIcms,
            valorIcmsStEValorFcpIcmsSt:
              data.orcamentoItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt,
            valorIpi: data.orcamentoItemImpostoIpi?.valor,
            valorPis: data.orcamentoItemImpostoPis?.valor,
            valorCofins: data.orcamentoItemImpostoCofins?.valor,
            valorIrpj: 0,
            valorCsll: 0,
            valorIcmsSn: 0,
            valorOutros: 0,

            margemLiquidaValor: 0,
            margemLiquidaPercentual: 0,
          };

          listaItemVenda.push(itemVenda);
        } else {
          let valorIcms = 0;
          if (orcamentoItem.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms) {
            valorIcms += Number(
              orcamentoItem.orcamentoItemImpostoIcms?.valorIcmsEValorFcpIcms
            );
          }

          if (
            orcamentoItem.orcamentoItemImpostoIcmsOperacaoInterestadual
              ?.estadoDestinoValorIcmsComFcp
          ) {
            valorIcms += Number(
              orcamentoItem.orcamentoItemImpostoIcmsOperacaoInterestadual
                ?.estadoDestinoValorIcmsComFcp
            );
          }

          const itemVenda: IAnaliseCustoVendaLista = {
            isFrenteCaixa: false,
            recalcularImpostos: false,

            id: orcamentoItem.id,
            sequencia: orcamentoItem.ordem,

            dataHoraMovimento: dataHoraEmissao,
            pessoa,
            modeloDocumento: modeloDocumentoOrcamento,

            produto: orcamentoItem.produto,
            destinado: orcamentoItem.destinado,
            indicadorPresenca,
            tipoNota: orcamentoItem.tipoNota,
            tipoNotaMotivo: orcamentoItem.tipoNotaMotivo,

            quantidade: orcamentoItem.quantidade,
            valorTotalBruto: orcamentoItem.valorTotalBruto,
            valorTotalImpostosOutros: 0,
            valorUnitariaCusto: 0,
            valorTotalCusto: 0,
            valorUnitarioVenda: orcamentoItem.valorUnitario,
            valorTotalProduto: orcamentoItem.valorTotalProduto,

            valorFrete: orcamentoItem.valorFrete,
            valorSeguro: orcamentoItem.valorSeguro,
            valorOutrasDespesas: orcamentoItem.valorOutrasDespesas,
            valorDesconto: orcamentoItem.valorDesconto,

            valorIcms,
            valorIcmsStEValorFcpIcmsSt:
              orcamentoItem.orcamentoItemImpostoIcms
                ?.valorIcmsStEValorFcpIcmsSt,
            valorIpi: orcamentoItem.orcamentoItemImpostoIpi?.valor,
            valorPis: orcamentoItem.orcamentoItemImpostoPis?.valor,
            valorCofins: orcamentoItem.orcamentoItemImpostoCofins?.valor,
            valorIrpj: 0,
            valorCsll: 0,
            valorIcmsSn: 0,
            valorOutros: 0,

            margemLiquidaValor: 0,
            margemLiquidaPercentual: 0,
          };

          listaItemVenda.push(itemVenda);
        }
      }

      return listaItemVenda;
    }, [formPrincipal, formRefDetalhe, idDetalheRegistro]);

  // #endregion Analise Custo

  return (
    <JanelaDetalhe
      titulo="Item"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <JanelaNavegacao
                containerStyle={
                  telaPequena
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                }
              />
            </Col>
          </Row>

          <FormCia ref={formRefDetalhe}>
            <InputHiddenHtml name="situacao" />
            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12} className="div-checkbox">
                  <div className="checkbox-container">
                    <InputCheckbox
                      label="Filtrar Somente itens Disponíveis para Venda"
                      name="filtrarProdutosDisponiveisVenda"
                      onChange={(ev) => {
                        setComercializado(ev.target.checked);
                        refresh();
                      }}
                      onChangeValue={(ev) => {
                        setComercializado(ev.valorAtual);
                        refresh();
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={7} md={12} sm={12}>
                  <InputAutoCompleteProdutoServico
                    ref={inputRefFocus}
                    label="Produto/Serviço"
                    name="idProduto"
                    placeholder="Produto/Serviço"
                    nomeObjeto="produto"
                    onChangeItemAtualAposCarregarSemClear={async (ev) => {
                      handleLimparConfiguracaoTributaria();
                      handleObterConfiguracaoTributariaConformeProduto();
                      handleObterTabelaPreco();
                      handleObterValoresProdutoEngenharia();

                      if (
                        ev.itemAtual &&
                        ev.itemAtual.tipoProdutoServico ===
                          TipoProdutoServicoEnum.servico
                      )
                        handleObterOrigemIcms();
                      refresh();
                    }}
                    comercializado={comercializado}
                    analiseCusto={{
                      sequenciaItem:
                        formRefDetalhe.current?.getFieldValue('ordem'),
                      listaItem: handleListaItemAnalisCustos(),
                      onSalvarFormModalAnaliseCusto: (valorUnitarioVenda) => {
                        formRefDetalhe.current.setFieldValue(
                          'valorUnitario',
                          valorUnitarioVenda
                        );
                      },
                    }}
                  />
                </Col>
                <Col lg={3} md={12} sm={12}>
                  <InputAutoCompleteUnidadeMedida
                    label="Unidade de Medida"
                    name="idUnidadeMedida"
                    placeholder="Unidade de Medida"
                    nomeObjeto="unidadeMedida"
                    permitirAdicionar={false}
                  />
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <InputInteiro
                    label="Ordem"
                    name="ordem"
                    placeholder="Ordem"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Quantidade"
                    name="quantidade"
                    casasInteiras={11}
                    casasDecimais={4}
                    onChange={(event) => {
                      refresh();

                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          handleCalcularItemEngenhariaPesagem();
                          handleCalcularItemEngenhariaMetragem();

                          const valorUnitario =
                            formRefDetalhe.current?.getFieldValue(
                              'valorUnitario'
                            );

                          handleCalcularValorTotalProdutos();
                          handleCalcularQuantidadeTributadoProdutoTributado();

                          if (
                            event.target?.value.ConverterParaNumber() !== 0 &&
                            valorUnitario > 0
                          ) {
                            await handleObterRegraEscolhaAliquota();
                          }
                        },
                        170
                      );
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Unitário"
                    name="valorUnitario"
                    casasInteiras={11}
                    casasDecimais={10}
                    disabled={
                      !liberacoes.permiteAlterarValorUnitarioVendaTabelaPrecoInformada
                    }
                    onChangeValue={(event, props) => {
                      const quantidade =
                        formRefDetalhe.current?.getFieldValue('quantidade');

                      if (
                        props.valorAnteriorOnChange.ConverterParaNumber() ===
                          0 &&
                        event.valorFormatado.ConverterParaNumber() !== 0 &&
                        quantidade > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }

                      handleCalcularValorTotalProdutos();
                      handleCalcularValorUnitarioTributadoProdutoTributado();
                    }}
                    onChange={async (event, props) => {
                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          const quantidade =
                            formRefDetalhe.current?.getFieldValue('quantidade');

                          if (
                            event.target?.value?.ConverterParaNumber() !== 0 &&
                            quantidade > 0
                          ) {
                            await handleObterRegraEscolhaAliquota();
                          }

                          handleCalcularValorTotalProdutos();
                          handleCalcularValorUnitarioTributadoProdutoTributado();

                          if (
                            event.target &&
                            event.target.value !== props.valorAnteriorOnBlur
                          ) {
                            formRefDetalhe.current?.setFieldValue(
                              'orcamentoItemComercial.utilizarValorTabelaPreco',
                              false
                            );
                          }
                        },
                        170
                      );
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Subtotal (Qtde. * Val. Unit.)"
                    name="valorTotalProduto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                    ref={subtotalRef}
                    onChangeValue={(event, { valorAnteriorOnChange }) => {
                      if (
                        valorAnteriorOnChange.ConverterParaNumber() === 0 &&
                        event.valor > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }
                      handleCalcularRateioItem();
                      subtotalRef.current && subtotalRef.current.focus();
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Total Orçamento"
                    name="valorTotalBruto"
                    casasInteiras={13}
                    casasDecimais={2}
                    onChangeValue={() => {
                      calcularBaseCalculoItemComercialRepresentante();
                    }}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputDateTime
                    label="Data Prevista Faturamento"
                    name="dataPrevistaFaturamento"
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Input
                    label="Num. do Pedido de Compra"
                    name="pedidoCompraNumero"
                    placeholder="Num. do Pedido de Compra"
                    maxLength={15}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputInteiroNulavel
                    label="Item do Pedido de Compra"
                    name="pedidoCompraNumeroItem"
                    placeholder="Item do Pedido de Compra"
                  />
                </Col>
              </Row>
            </Divisor>

            <Tabs
              id={v4()}
              defaultActiveKey="impostos"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="impostos" title="Impostos">
                <ItemImpostoTab />
              </Tab>

              <Tab eventKey="outrosValores" title="Outros Valores">
                <ItemOutrosValoresTab />
              </Tab>

              <Tab eventKey="informacoesAdicionais" title="Inf. Adicionais">
                <ItemInformacaoAdicionalTab />
              </Tab>

              <Tab eventKey="comercial" title="Comercial">
                <ItemComercialTab />
              </Tab>

              <Tab eventKey="engenharia" title="Engenharia">
                <ItemEngenhariaTab />
              </Tab>

              <Tab eventKey="veiculo" title="Veículos Novos">
                <ItemVeiculoTab />
              </Tab>
            </Tabs>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer style={telaPequena ? { display: 'block' } : {}}>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
              id="voltar"
              style={telaPequena ? { width: '100%', marginBottom: 5 } : {}}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarENovo}
              id="salvarENovo"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAll />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar e Novo' : 'Adicionar e Novo'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarEContinuar}
              id="salvarEContinuar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvar}
              id="salvar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default OrcamentoItemDetalhe;
