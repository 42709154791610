import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/categoria-dre/regra-escolha-categoria/ordem';

interface IRegraEscolhaCategoriaOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaCategoriaOrdemComunicador {
  public async update(
    params: IRegraEscolhaCategoriaOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaCategoriaOrdemComunicador();
