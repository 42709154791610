import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../Componentes/Divisor';
import PessoaContatoHook from './ListaDetalheContato/Hooks';
import ListaDetalheContato from './ListaDetalheContato/Lista';

const ContatoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12}>
            <PessoaContatoHook>
              <ListaDetalheContato name="listaPessoaContato" />
            </PessoaContatoHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ContatoTab;
