import React from 'react';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { ContaListaParcelasHook } from './ContaListaParcelasHook';
import FormHook from './FormHook';
import { ParcelasNavegacaoHook } from './ParcelasNavegacaoHook';
import RotasHook from './RotasHook';

const ContaParcelaHook: React.FC<IPadraoProps> = ({ children }) => {
  return (
    <RotasHook>
      <ContaListaParcelasHook>
        <FormHook>
          <ParcelasNavegacaoHook>{children}</ParcelasNavegacaoHook>
        </FormHook>
      </ContaListaParcelasHook>
    </RotasHook>
  );
};

export default ContaParcelaHook;
