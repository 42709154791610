import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormatarEnum,
  VeiculoCondicaoEnum,
  VeiculoCondicaoVinEnum,
  VeiculoEspecieEnum,
  VeiculoTipoCombustivelEnum,
  VeiculoTipoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import Select from '../../../../../../Componentes/Select';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';

const VeiculoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputInteiroNulavel
              name="produtoVeiculo.potenciaMotor"
              label="Potência do Motor"
              placeholder="Potência do Motor"
              maxLength={4}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputInteiroNulavel
              name="produtoVeiculo.cilindradas"
              label="Cilindradas"
              placeholder="Cilindradas"
              maxLength={4}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo de Combustível"
              name="produtoVeiculo.tipoCombustivel"
              options={FormatarEnum({
                enumObj: VeiculoTipoCombustivelEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputDecimalNulavel
              name="produtoVeiculo.capacidadeMaximaTracao"
              label="Cap. Max. de Tração"
              placeholder="Cap. Max. de Tração"
              casasInteiras={9}
              casasDecimais={4}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Input
              name="produtoVeiculo.distanciaEntreEixos"
              label="Distância entre Eixos"
              placeholder="Distância entre Eixos"
              maxLength={4}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Tipo de Veículo"
              name="produtoVeiculo.tipoVeiculo"
              options={FormatarEnum({
                enumObj: VeiculoTipoEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Espécie de Veículo"
              name="produtoVeiculo.especieVeiculo"
              options={FormatarEnum({
                enumObj: VeiculoEspecieEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Condição VIN"
              name="produtoVeiculo.condicaoVin"
              options={FormatarEnum({
                enumObj: VeiculoCondicaoVinEnum,
                nullavel: true,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Select
              label="Condição do Veículo"
              name="produtoVeiculo.condicaoVeiculo"
              options={FormatarEnum({
                enumObj: VeiculoCondicaoEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputInteiroNulavel
              name="produtoVeiculo.capacidadeMaximaLotacao"
              label="Cap. Max. de Lotação"
              placeholder="Cap. Max. de Lotação"
              maxLength={3}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default VeiculoTab;
