import {
  DiferencialAliquotaCalculo,
  INotaFiscalEntradaItemValoresInserir,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputSwitch from '../../../../../../../../../Componentes/Inputs/InputSwitch';
import { UseListaDetalheForm } from '../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';

const DiferencialAliquotaTab: React.FC = () => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, refresh } = UseListaDetalheForm();

  const handleCalcularDiferencialAliquota = useCallback(
    (calcularDiferencial: boolean) => {
      if (calcularDiferencial) {
        const id = formPrincipal.getIdDetalheRegistro();
        const listaItem = formPrincipal.formRef.current?.getFieldValue(
          'listaNotaFiscalEntradaItem'
        );

        const codigoSpedTipoProduto = formRefDetalhe.current?.getFieldValue(
          'codigoSpedTipoProduto'
        );
        const valorAliquotaInterna =
          formRefDetalhe.current?.getFieldValue('aliquotaInterna');
        const valorAliquotaInterestadual =
          formRefDetalhe.current?.getFieldValue('aliquotaInterestadual');

        const valorIcms = formRefDetalhe.current?.getFieldValue('valorIcms');
        const valorIpi = formRefDetalhe.current?.getFieldValue('valorIpi');

        const item = listaItem.find(
          (it: INotaFiscalEntradaItemValoresInserir) => it.id === id
        );

        const calculoDiferencialAliquota = DiferencialAliquotaCalculo({
          codigoSpedTipoProduto,
          valorAliquotaInterestadual,
          valorAliquotaInterna,
          valorDesconto: Number(item.valorDesconto),
          valorFrete: Number(item.valorFrete),
          valorIpi,
          valorIcmsOrigem: valorIcms,
          valorOutrasDespesas: Number(item.valorOutrasDespesas),
          valorProduto: Number(item.valorUnitario),
          valorSeguro: Number(item.valorSeguro),
          valorParcial: null,
        });

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.baseCalculo',
          Number(calculoDiferencialAliquota.baseCalculo)
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaInterna',
          Number(calculoDiferencialAliquota.aliquotaInterna)
        );

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaInterestadual',
          Number(calculoDiferencialAliquota.aliquotaInterestadual)
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaDiferencial',
          Number(calculoDiferencialAliquota.aliquotaDiferencial)
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.valorDiferencial',
          Number(calculoDiferencialAliquota.valorDiferencial)
        );
      } else {
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.baseCalculo',
          null
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaInterna',
          null
        );

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaInterestadual',
          null
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.aliquotaDiferencial',
          null
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemDiferencialAliquota.valorDiferencial',
          null
        );
      }
    },
    [formPrincipal, formRefDetalhe]
  );

  return (
    <div>
      <InputHiddenHtml name="notaFiscalEntradaItemDiferencialAliquota.id" />

      <InputHiddenHtml name="codigoSpedTipoProduto" />

      <InputHiddenHtml name="aliquotaInterestadual" />
      <InputHiddenHtml name="aliquotaInterna" />
      <InputHiddenHtml name="valorIpi" />
      <InputHiddenHtml name="valorIcms" />
      <Divisor>
        <Row>
          <Col xl={4} lg={4} md={6}>
            <InputSwitch
              label="Calcular Diferencial Alíquota"
              name="notaFiscalEntradaItemDiferencialAliquota.calcularDiferencialAliquota"
              ativo="Sim"
              inativo="Não"
              onChange={(ev) => {
                handleCalcularDiferencialAliquota(ev.target.checked);
                refresh();
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            display: formRefDetalhe.current?.getFieldValue(
              'notaFiscalEntradaItemDiferencialAliquota.calcularDiferencialAliquota'
            )
              ? ''
              : 'none',
          }}
        >
          <Col xl={2} lg={2}>
            <InputDecimalNulavel
              name="notaFiscalEntradaItemDiferencialAliquota.baseCalculo"
              label="Base Cálculo"
              placeholder="Base Cálculo"
            />
          </Col>

          <Col xl={2} lg={2}>
            <InputDecimalNulavel
              name="notaFiscalEntradaItemDiferencialAliquota.aliquotaInterna"
              label="Aliq. Interna"
              placeholder="Aliq. Interna"
            />
          </Col>

          <Col xl={2} lg={2}>
            <InputDecimalNulavel
              name="notaFiscalEntradaItemDiferencialAliquota.aliquotaInterestadual"
              label="Aliq. Interestadual"
              placeholder="Aliq. Interestadual"
            />
          </Col>

          <Col xl={2} lg={2}>
            <InputDecimalNulavel
              name="notaFiscalEntradaItemDiferencialAliquota.aliquotaDiferencial"
              label="Aliq. Diferencial"
              placeholder="Aliq. Diferencial"
            />
          </Col>

          <Col xl={2} lg={2}>
            <InputDecimalNulavel
              name="notaFiscalEntradaItemDiferencialAliquota.valorDiferencial"
              label="Valor Diferencial"
              placeholder="Valor Diferencial"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default DiferencialAliquotaTab;
