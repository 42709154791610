import api from '../../../../../../Comum/Services/Api';

const rota = 'suprimentos/compras/pedido-compra';

interface IPedidoCompraItemOrdemComunicadorUpdate {
  id: string;
  params: {
    listaItem: Array<{
      id: string;
      ordem: number;
    }>;
  };
}

class PedidoCompraItemOrdemComunicador {
  public async update(
    params: IPedidoCompraItemOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(
      `${rota}/${params.id}/item/ordem`,
      params.params
    );

    return response.data;
  }
}

export default new PedidoCompraItemOrdemComunicador();
