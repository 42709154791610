import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPesagemA4AvaliacaoImpressao,
  TipoAvaliacaoPesagemEnum,
  TipoAvaliacaoPesagemValorEnum,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPesagemA4AvaliacaoImpressaoPDF {
  dados: IPesagemA4AvaliacaoImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const PesagemA4AvaliacaoImpressaoPDF: React.FC<
  IPesagemA4AvaliacaoImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'flex-start',
              marginTop: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                source={dados.caminhoLogo}
                style={{ width: 60, borderRadius: 0 }}
              />
            )}
          </View>
          <View
            style={{
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.logradouro}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`, ${dados.empresa.empresaEndereco.bairro}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.complemento}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.telefone &&
                  `Telefone: ${dados.empresa.telefone}`}
              </Text>
            </View>
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View
          style={{
            width: '100%',
            padding: 2,
            justifyContent: 'center',
            flexDirection: 'row',
            borderBottom: '1pt solid black',
            borderTop: '1pt solid black',
          }}
        >
          <Text style={{ fontSize: 10 }}>COMPROVANTE DE PESAGEM</Text>
        </View>

        <View style={{ flexDirection: 'row', padding: 3 }}>
          <View style={{ width: '20%' }}>
            <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
              {`Número: ${dados.numero}`}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 9 }}>
              {`Data Emissão: ${FormatarDataParaPtBr(dados.dataHoraEmissao)}`}
            </Text>
          </View>
        </View>

        <View
          style={{ width: '100%', borderTop: '1pt solid black', padding: 3 }}
        >
          <Text style={{ fontSize: 9 }}>
            {`Produto: ${dados.produto.codigo} - ${dados.produto.descricao}`}
          </Text>

          <View
            style={{ flexDirection: 'row', marginTop: 2, fontWeight: 'bold' }}
          >
            <Text style={{ fontSize: 9, width: '20%' }}>
              {`Peso Bruto: ${Number(dados.pesoBruto).FormatarParaPtBr()} KG`}
            </Text>
            <Text style={{ fontSize: 9, width: '20%' }}>
              {`Peso Tara: ${Number(dados.pesoTara).FormatarParaPtBr()} KG`}
            </Text>
            <Text style={{ fontSize: 9, width: '20%' }}>
              {`Peso Líquido: ${Number(
                dados.pesoLiquido
              ).FormatarParaPtBr()} KG`}
            </Text>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View
            style={{
              width: '100%',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              padding: 3,
            }}
          >
            {dados.listaPesagemPessoa?.map((pessoas) => {
              return (
                <View style={{ fontSize: 8, marginBottom: 5 }}>
                  <Text>Produtor: {pessoas.pessoa.nomeRazaoSocial}</Text>

                  <Text>
                    {pessoas.pessoa.listaPessoaEndereco[0].logradouro}
                  </Text>

                  {pessoas.pessoa.listaPessoaEndereco[0].complemento !== '' && (
                    <Text>
                      {`${pessoas.pessoa.listaPessoaEndereco[0].complemento} - ${pessoas.pessoa.listaPessoaEndereco[0].numero}`}
                    </Text>
                  )}

                  {pessoas.pessoa.listaPessoaEndereco[0].cep !== '' && (
                    <Text>{`${pessoas.pessoa.listaPessoaEndereco[0].cep} - ${pessoas.pessoa.listaPessoaEndereco[0].cidade.nome} - ${pessoas.pessoa.listaPessoaEndereco[0].cidade.estado.sigla}`}</Text>
                  )}

                  <Text style={{ fontWeight: 'bold' }}>
                    Peso Líquido:{' '}
                    {Number(pessoas.pesoLiquido).FormatarParaPtBr()}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.data}>
          <View fixed style={{ flexDirection: 'row', fontSize: 9 }}>
            <View style={{ width: '30%', fontWeight: 'bold' }}>
              <Text>Locais Estoque</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '20%', alignItems: 'flex-end' }}>
              <Text>Peso Líquido</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>

          {dados.listaPesagemLocalEstoque?.map((dado) => {
            return (
              <View
                key={dado.id}
                style={{ flexDirection: 'row', fontSize: 9, marginBottom: 10 }}
              >
                <View style={{ width: '30%' }}>
                  <Text>{`${dado.sequencia} - ${dado.localEstoque.descricao}`}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '20%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.pesoLiquido).FormatarParaPtBr()}</Text>
                </View>

                <View style={{ width: '1%' }} />
              </View>
            );
          })}

          <View fixed style={{ flexDirection: 'row', fontSize: 9 }}>
            <View style={{ width: '30%', marginBottom: 5 }}>
              <Text>CHECKLIST DE RECEBIMENTO</Text>
            </View>
            <View style={{ width: '1%' }} />
          </View>
          {dados.listaPesagemAvaliacao?.map((item) => {
            return (
              <View key={item.id} style={{ flexDirection: 'row' }}>
                <View style={{ width: '30%' }}>
                  <Text>{item.itemAvaliacaoPesagem.descricao}</Text>
                </View>

                <View style={{ width: '1%' }} />

                {item.itemAvaliacaoPesagem.tipoAvaliacao ===
                  TipoAvaliacaoPesagemEnum.SimNao &&
                  (item.valor === TipoAvaliacaoPesagemValorEnum.sim ? (
                    <View style={{ width: '70%', flexDirection: 'row' }}>
                      <View style={{ width: '10%' }}>
                        <Text>(X) Sim</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>( ) Não</Text>
                      </View>
                    </View>
                  ) : (
                    <View style={{ width: '70%', flexDirection: 'row' }}>
                      <View style={{ width: '10%' }}>
                        <Text>( ) Sim</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>(X) Não</Text>
                      </View>
                    </View>
                  ))}

                {item.itemAvaliacaoPesagem.tipoAvaliacao ===
                  TipoAvaliacaoPesagemEnum.OtimoBomRazoavelRuim &&
                item.valor === TipoAvaliacaoPesagemValorEnum.otimo ? (
                  <View style={{ width: '70%', flexDirection: 'row' }}>
                    <View style={{ width: '10%' }}>
                      <Text>(X) Ótimo</Text>
                    </View>

                    <View style={{ width: '3%' }} />

                    <View style={{ width: '10%' }}>
                      <Text>( ) Bom</Text>
                    </View>
                    <View style={{ width: '3%' }} />

                    <View style={{ width: '11%' }}>
                      <Text>( ) Razoável</Text>
                    </View>

                    <View style={{ width: '3%' }} />

                    <View style={{ width: '10%' }}>
                      <Text>( ) Ruim</Text>
                    </View>
                  </View>
                ) : (
                  item.valor === TipoAvaliacaoPesagemValorEnum.bom && (
                    <View style={{ width: '70%', flexDirection: 'row' }}>
                      <View style={{ width: '10%' }}>
                        <Text>( ) Ótimo</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>(X) Bom</Text>
                      </View>
                      <View style={{ width: '3%' }} />

                      <View style={{ width: '11%' }}>
                        <Text>( ) Razoável</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>( ) Ruim</Text>
                      </View>
                    </View>
                  )
                )}
                {item.itemAvaliacaoPesagem.tipoAvaliacao ===
                  TipoAvaliacaoPesagemEnum.OtimoBomRazoavelRuim &&
                item.valor === TipoAvaliacaoPesagemValorEnum.razoavel ? (
                  <View style={{ width: '70%', flexDirection: 'row' }}>
                    <View style={{ width: '10%' }}>
                      <Text>( ) Ótimo</Text>
                    </View>

                    <View style={{ width: '3%' }} />

                    <View style={{ width: '10%' }}>
                      <Text>( ) Bom</Text>
                    </View>
                    <View style={{ width: '3%' }} />

                    <View style={{ width: '11%' }}>
                      <Text>(X) Razoável</Text>
                    </View>

                    <View style={{ width: '3%' }} />

                    <View style={{ width: '10%' }}>
                      <Text>( ) Ruim</Text>
                    </View>
                  </View>
                ) : (
                  item.valor === TipoAvaliacaoPesagemValorEnum.ruim && (
                    <View style={{ width: '70%', flexDirection: 'row' }}>
                      <View style={{ width: '10%' }}>
                        <Text>( ) Ótimo</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>( ) Bom</Text>
                      </View>
                      <View style={{ width: '3%' }} />

                      <View style={{ width: '11%' }}>
                        <Text>( ) Razoável</Text>
                      </View>

                      <View style={{ width: '3%' }} />

                      <View style={{ width: '10%' }}>
                        <Text>(X) Ruim</Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            );
          })}

          <View
            fixed
            style={{
              flexDirection: 'row',
              fontSize: 9,
              justifyContent: 'space-around',
              marginTop: 60,
            }}
          >
            <View
              style={{
                width: '30%',
                alignItems: 'center',
                borderBottom: '1pt solid black',
              }}
            />

            <View
              style={{
                width: '30%',
                alignItems: 'center',
                borderBottom: '1pt solid black',
              }}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              fontSize: 9,
              justifyContent: 'space-around',
            }}
          >
            <View style={{ width: '30%', alignItems: 'center' }}>
              <Text>Produtor</Text>
            </View>
            <View style={{ width: '30%', alignItems: 'center' }}>
              <Text>Responsável pela Avaliação</Text>
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PesagemA4AvaliacaoImpressaoPDF;
