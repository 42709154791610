import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-dados-padrao-nota-fiscal-saida';

class FrenteCaixaPdvObterDadosPadraoNotaFiscalSaidaComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }
}

export default new FrenteCaixaPdvObterDadosPadraoNotaFiscalSaidaComunicador();
