"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoDocumentoBanrisulRetornoEnum;
(function (TipoDocumentoBanrisulRetornoEnum) {
    TipoDocumentoBanrisulRetornoEnum["CBBsemRegistro"] = "00";
    TipoDocumentoBanrisulRetornoEnum["duplicataFatura"] = "01";
    TipoDocumentoBanrisulRetornoEnum["bilheteSeguro"] = "03";
    TipoDocumentoBanrisulRetornoEnum["cobrancaDireta"] = "04";
    TipoDocumentoBanrisulRetornoEnum["cobrancaEscritural"] = "06";
    TipoDocumentoBanrisulRetornoEnum["CCBcomRegistro"] = "08";
    TipoDocumentoBanrisulRetornoEnum["tituloTerceiros"] = "09";
})(TipoDocumentoBanrisulRetornoEnum || (TipoDocumentoBanrisulRetornoEnum = {}));
exports.default = TipoDocumentoBanrisulRetornoEnum;
