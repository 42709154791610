"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var SisdevinTipoOperacaoEngarrafadosEnum;
(function (SisdevinTipoOperacaoEngarrafadosEnum) {
    SisdevinTipoOperacaoEngarrafadosEnum["venda"] = "01 - Venda";
    SisdevinTipoOperacaoEngarrafadosEnum["saidaTransferenciaMatrizParaFilial"] = "13 - Sa\u00EDda de Transfer\u00EAncia Matriz p/ Filial";
    SisdevinTipoOperacaoEngarrafadosEnum["saidaDevolucaoIndustrializacao"] = "22 - Sa\u00EDda de Devolu\u00E7\u00E3o de Industrializa\u00E7\u00E3o";
    SisdevinTipoOperacaoEngarrafadosEnum["saidaDevolucao"] = "12 - Sa\u00EDda Devolu\u00E7\u00E3o";
    SisdevinTipoOperacaoEngarrafadosEnum["remessaDegusta\u00E7\u00E3o"] = "47 - Remessa de Degusta\u00E7\u00E3o";
    SisdevinTipoOperacaoEngarrafadosEnum["retornoDegusta\u00E7\u00E3o"] = "48 - Retorno de Degusta\u00E7\u00E3o";
    SisdevinTipoOperacaoEngarrafadosEnum["saidaRemessa"] = "44 - Sa\u00EDda de Remessa";
    SisdevinTipoOperacaoEngarrafadosEnum["retornoRemessa"] = "45 - Retorno de Remessa";
    SisdevinTipoOperacaoEngarrafadosEnum["compra"] = "05 - Compra";
    SisdevinTipoOperacaoEngarrafadosEnum["entradaDevolucao"] = "11 - Entrada Devolu\u00E7\u00E3o";
    SisdevinTipoOperacaoEngarrafadosEnum["entradaTransfer\u00EAnciaMatrizParaFilial"] = "14 - Entrada de Transfer\u00EAncia Matriz p/ Filial";
    SisdevinTipoOperacaoEngarrafadosEnum["entradaDevolucaoIndustrializacao"] = "23 - Entrada de Devolu\u00E7\u00E3o de Industrializa\u00E7\u00E3o";
})(SisdevinTipoOperacaoEngarrafadosEnum || (SisdevinTipoOperacaoEngarrafadosEnum = {}));
exports.default = SisdevinTipoOperacaoEngarrafadosEnum;
