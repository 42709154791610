import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/integracao/empresa-tray';

interface IEmpresaTrayStore {
  params: any;
}

class EmpresaTrayComunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async store(params: IEmpresaTrayStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaTrayComunicador();
