import {
  FormatarDataHoraParaPtBr,
  IPesagemFiltroRelatorioBackend,
  IPesagemFiltroRelatorioFrontend,
  IPesagemLocalDeEstoqueCabecalhoRelatorio,
  IPesagemLocalDeEstoqueRelatorio,
  StringConverterParaEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoPesagemEnum,
} from '@elogestor/util';
import React from 'react';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PesagemPorLocalDeEstoqueRelatorioPlanilha from './PesagemPorLocalDeEstoqueRelatorioPlanilha';
import PesagemPorLocalDeEstoqueRelatorioComunicador from '../../../../../../Comunicador/Suprimentos/Relatorios/Pesagem/PesagemPorLocalDeEstoqueRelatorio/PesagemPorLocalDeEstoqueRelatorioComunicador';
import PesagemPorLocalDeEstoqueRelatorioPDF from './PesagemPorLocalDeEstoqueRelatorioPDF';
import { empresaLogada } from '../../../../../../Hooks/Auth';

interface IRelatorios {
  handleExportCSV(filtros: IPesagemFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IPesagemFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IPesagemFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IPesagemFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PesagemPorLocalDeEstoqueRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPesagemFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPesagemLocalDeEstoqueRelatorio | null> => {
    const filtroBack: IPesagemFiltroRelatorioBackend = {
      dataHoraInicial: filtros.dataHoraInicial,
      dataHoraFinal: filtros.dataHoraFinal,

      descricao: filtros.descricao,
      codigo: filtros.codigo,
    };

    if (filtros?.listaSituacao) {
      filtroBack.listaDescricaoSituacao =
        filtros.listaSituacao.listaValor.map((value) => value.descricao) || [];
    }

    if (filtros?.tipo) {
      filtroBack.tipo = StringConverterParaEnum<
        typeof TipoPesagemEnum,
        TipoPesagemEnum
      >(TipoPesagemEnum, String(filtros.tipo));
    }

    if (filtros?.listaCliente) {
      filtroBack.listaIdCliente =
        filtros.listaCliente.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaRamoAtividadeProfissao) {
      filtroBack.listaIdRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor.map(
          (value) => value.id
        ) || [];
    }

    if (filtros?.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaProduto) {
      filtroBack.listaIdProduto =
        filtros.listaProduto.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.tipoBuscaCodigoDescricao) {
      filtroBack.tipoBuscaCodigoDescricao = StringConverterParaEnum<
        typeof TipoBuscaCodigoDescricaoEnum,
        TipoBuscaCodigoDescricaoEnum
      >(TipoBuscaCodigoDescricaoEnum, String(filtros.tipoBuscaCodigoDescricao));
    }

    if (filtros?.listaGrupo) {
      filtroBack.listaIdGrupo =
        filtros.listaGrupo.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaLocaisEstoque) {
      filtroBack.listaIdLocaisEstoque =
        filtros.listaLocaisEstoque.listaValor.map((value) => value.id) || [];
    }

    if (filtros?.listaPessoaTerceiro) {
      filtroBack.listaIdPessoaTerceiro =
        filtros.listaPessoaTerceiro.listaValor.map((value) => value.id) || [];
    }

    const response = await PesagemPorLocalDeEstoqueRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem Dados!');
      return null;
    }

    const retorno = response as IPesagemLocalDeEstoqueRelatorio;

    const listaDados = retorno.listaDados.map((item) => {
      return {
        ...item,
        somatorioPesoLiquido: Number(
          item.somatorioPesoLiquido
        ).FormatarParaPtBr(),
        listaPesagem: item.listaPesagem.map((pesagem) => {
          return {
            ...pesagem,
            pesoLiquido: Number(pesagem.pesoLiquido).FormatarParaPtBr(),
            dataHoraEmissao: FormatarDataHoraParaPtBr(pesagem.dataHoraEmissao),
          };
        }),
      };
    });

    return {
      ...retorno,
      listaDados,
      somatorioPesoLiquidoGeral: Number(
        retorno.somatorioPesoLiquidoGeral
      ).FormatarParaPtBr(),
    };
  };

  function FormatarCabecalho(
    filtros: IPesagemFiltroRelatorioFrontend,
    cabecalho: IPesagemLocalDeEstoqueCabecalhoRelatorio
  ): IPesagemLocalDeEstoqueCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';

    if (filtros.dataHoraInicial || filtros.dataHoraFinal) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataInicial = '--/--/--';
      cabecalho.filtroDataFinal = '--/--/--';

      if (filtros.dataHoraInicial) {
        cabecalho.filtroDataInicial = FormatarDataHoraParaPtBr(
          filtros.dataHoraInicial
        );
      }

      if (filtros.dataHoraFinal) {
        cabecalho.filtroDataFinal = FormatarDataHoraParaPtBr(
          filtros.dataHoraFinal
        );
      }
    }

    if (filtros.listaSituacao && filtros.listaSituacao.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroSituacao = filtros.listaSituacao.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (filtros.tipo) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipo = filtros.tipo;
    }

    if (filtros.listaCliente && filtros.listaCliente.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCliente = filtros.listaCliente.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    if (
      filtros.listaRamoAtividadeProfissao &&
      filtros.listaRamoAtividadeProfissao.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRamoAtividadeProfissao =
        filtros.listaRamoAtividadeProfissao.listaValor
          .map((valor: any) => {
            return valor.descricao;
          })
          .join(', ');
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (filtros.listaProduto && filtros.listaProduto.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroProduto = filtros.listaProduto.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.descricao}`;
        })
        .join(', ');
    } else {
      if (filtros.codigo) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroCodigo = filtros.codigo;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
      if (filtros.descricao) {
        cabecalho.possuiFiltro = true;

        cabecalho.filtroDescricao = filtros.descricao;
        cabecalho.filtroTipoBuscaCodigoDescricao =
          filtros.tipoBuscaCodigoDescricao;
      }
    }

    if (filtros.listaGrupo && filtros.listaGrupo.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroGrupos = filtros.listaGrupo.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaLocaisEstoque &&
      filtros.listaLocaisEstoque.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroLocaisDeEstoque = filtros.listaLocaisEstoque.listaValor
        .map((valor: any) => {
          return valor.descricao;
        })
        .join(', ');
    }

    if (
      filtros.listaPessoaTerceiro &&
      filtros.listaPessoaTerceiro.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroPessoaTerceiro = filtros.listaPessoaTerceiro.listaValor
        .map((valor: any) => {
          return valor.nomeRazaoSocial;
        })
        .join(', ');
    }

    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPesagemFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await CSV({
      campos: PesagemPorLocalDeEstoqueRelatorioPlanilha,
      nomeArquivo: 'PesagemPorLocalDeEstoque',
      valores: dados.listaDados,
      unwind: ['listaPesagens'],
    });

    planilha.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPesagemFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const planilha = await Planilha({
      campos: PesagemPorLocalDeEstoqueRelatorioPlanilha,
      nomeArquivo: 'PesagemPorLocalDeEstoque',
      valores: dados.listaDados as any,
      unwind: ['listaPesagem'],
    });

    planilha.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPesagemFiltroRelatorioFrontend
  ): Promise<void> {
    const dados: any = await ObterRelatorio(filtros);
    if (!dados) return;
    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PesagemPorLocalDeEstoqueRelatorioPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPesagemFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PesagemPorLocalDeEstoqueRelatorioPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PesagemPorLocalDeEstoqueRelatorio();
