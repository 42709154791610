import { AmbienteEnum, FormatarEnum } from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTagSerie from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import Select from '../../../../../Componentes/Select';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataTransmissaoRef = useRef<any>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaSerie = parametros.pesquisaAvancada?.listaSerie;
      if (listaSerie.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cód Série',
          valor: listaSerie.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaModeloDocumento =
        parametros.pesquisaAvancada?.listaModeloDocumento;
      if (listaModeloDocumento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Modelo Documento',
          valor: listaModeloDocumento.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const ambiente = parametros.pesquisaAvancada?.ambiente;
      if (ambiente) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ambiente',
          valor: ambiente,
        });
      }

      const periodoDataTransmissao =
        periodoDataTransmissaoRef.current?.getPeriodo();
      if (periodoDataTransmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Período Data Transmissão',
          valor: periodoDataTransmissao,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagSerie name="listaSerie" label="Séries" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagModeloDocumento
                  name="listaModeloDocumento"
                  label="Modelo Documento"
                />
              </Col>

              <Col>
                <InputPeriodoData
                  labelDataInicial="Data Trans. Inicial"
                  labelDataFinal="Data Trans. Final"
                  nomeDataInicial="dataTransmissaoInicial"
                  nomeDataFinal="dataTransmissaoFinal"
                  ref={periodoDataTransmissaoRef}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="ambiente"
                  label="Ambiente"
                  options={FormatarEnum({
                    enumObj: AmbienteEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
