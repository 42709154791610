import React, {
  useCallback,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  IUsuarioMenuPermissaoValoresAlterar,
  TipoUsuarioEnum,
} from '@elogestor/util';
import { v4 } from 'uuid';
import ListaMenuComponent, {
  IListaMenuComponentRef,
} from './ListaMenuComponent';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';

interface IMenuComponentProps {
  menu: IUsuarioMenuPermissaoValoresAlterar;
  margin?: number;
  marcarPai?: (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => void;
  desmarcarPai?: (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui') => void;
}

export interface IMenuComponentRef {
  marcarTodos(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  desmarcarTodos(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  marcar(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  desmarcar(tipo: 'acessa' | 'inclui' | 'altera' | 'exclui'): void;
  mostrarValor(): IUsuarioMenuPermissaoValoresAlterar;
}

const MenuComponent: React.ForwardRefRenderFunction<
  IMenuComponentRef,
  IMenuComponentProps
> = ({ menu, margin = 0, marcarPai, desmarcarPai }, ref) => {
  const { tipoUsuario: tipoUsuarioLogado } = UsePermissoes();
  const { loading } = UseForm();

  const ListaMenuComponentRef = useRef<IListaMenuComponentRef | null>(null);

  const [acessa, setAcessa] = useState(!!menu.acessa);
  const [inclui, setInclui] = useState(!!menu.inclui);
  const [altera, setAltera] = useState(!!menu.altera);
  const [exclui, setExclui] = useState(!!menu.exclui);

  useEffect(() => {
    setAcessa(!!menu?.acessa);
    setInclui(!!menu?.inclui);
    setAltera(!!menu?.altera);
    setExclui(!!menu?.exclui);
  }, [menu]);

  const marcar = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui' = 'acessa') => {
      const acoes = {
        acessa() {
          setAcessa(true);
        },
        inclui() {
          setInclui(true);
        },
        altera() {
          setAltera(true);
        },
        exclui() {
          setExclui(true);
        },
      };
      if (marcarPai) {
        marcarPai(tipo);
      }
      acoes[tipo]();
    },
    [marcarPai]
  );

  const desmarcar = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui' = 'acessa') => {
      const acoes = {
        acessa() {
          setAcessa(false);
        },
        inclui() {
          setInclui(false);
        },
        altera() {
          setAltera(false);
        },
        exclui() {
          setExclui(false);
        },
      };
      const devoDesmarcar = ListaMenuComponentRef.current
        ?.mostrarValores()
        .filter((valor) => {
          return valor[tipo];
        });

      if (!devoDesmarcar || devoDesmarcar.length < 2) {
        acoes[tipo]();
        if (desmarcarPai) {
          desmarcarPai(tipo);
        }
      }
    },
    [desmarcarPai]
  );

  const marcarTodos = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui' = 'acessa') => {
      const acoes = {
        acessa() {
          setAcessa(true);
        },
        inclui() {
          setInclui(true);
        },
        altera() {
          setAltera(true);
        },
        exclui() {
          setExclui(true);
        },
      };
      acoes[tipo]();
      if (marcarPai) {
        marcarPai(tipo);
      }
      ListaMenuComponentRef.current?.marcarTodos(tipo);
    },
    [marcarPai]
  );

  const desmarcarTodos = useCallback(
    (tipo: 'acessa' | 'inclui' | 'altera' | 'exclui' = 'acessa') => {
      const acoes = {
        acessa() {
          setAcessa(false);
        },
        inclui() {
          setInclui(false);
        },
        altera() {
          setAltera(false);
        },
        exclui() {
          setExclui(false);
        },
      };
      acoes[tipo]();
      if (desmarcarPai) {
        desmarcarPai(tipo);
      }

      ListaMenuComponentRef.current?.desmarcarTodos(tipo);
    },
    [desmarcarPai]
  );

  const mostrarValor = useCallback((): IUsuarioMenuPermissaoValoresAlterar => {
    const listaValorFilhos = ListaMenuComponentRef.current?.mostrarValores();

    return {
      ...menu,
      acessa,
      inclui,
      altera,
      exclui,
      listaFilho: listaValorFilhos,
    };
  }, [acessa, altera, exclui, inclui, menu]);

  useImperativeHandle(ref, () => ({
    marcarTodos,
    desmarcarTodos,
    marcar,
    desmarcar,
    mostrarValor,
  }));

  return (
    <>
      <tr key={v4()}>
        <td>
          <div style={{ marginLeft: margin }}>{menu.descricao}</div>
        </td>
        <td>
          <input
            style={{ height: 30 }}
            type="checkbox"
            checked={acessa}
            onChange={() => {
              if (!acessa) {
                marcarTodos('acessa');
              } else {
                desmarcarTodos('acessa');
              }
            }}
            disabled={
              loading || tipoUsuarioLogado === TipoUsuarioEnum.representante
            }
          />
        </td>
        <td>
          <input
            style={{ height: 30 }}
            type="checkbox"
            checked={inclui}
            onChange={() => {
              if (!inclui) {
                marcarTodos('inclui');
              } else {
                desmarcarTodos('inclui');
              }
            }}
            disabled={
              loading || tipoUsuarioLogado === TipoUsuarioEnum.representante
            }
          />
        </td>
        <td>
          <input
            style={{ height: 30 }}
            type="checkbox"
            checked={altera}
            onChange={() => {
              if (!altera) {
                marcarTodos('altera');
              } else {
                desmarcarTodos('altera');
              }
            }}
            disabled={
              loading || tipoUsuarioLogado === TipoUsuarioEnum.representante
            }
          />
        </td>
        <td>
          <input
            style={{ height: 30 }}
            type="checkbox"
            checked={exclui}
            onChange={() => {
              if (!exclui) {
                marcarTodos('exclui');
              } else {
                desmarcarTodos('exclui');
              }
            }}
            disabled={
              loading || tipoUsuarioLogado === TipoUsuarioEnum.representante
            }
          />
        </td>
      </tr>

      <ListaMenuComponent
        margin={margin + 100}
        listaMenu={menu.listaFilho || []}
        marcarPai={marcar}
        desmarcarPai={desmarcar}
        ref={(instance) => {
          if (instance) {
            ListaMenuComponentRef.current = instance;
          }
        }}
      />
    </>
  );
};

export default forwardRef(MenuComponent);
