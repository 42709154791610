import { FormCia } from '@elogestor/unformcia';
import { IContaValoresAlterar, IContaValoresInserir } from '@elogestor/util';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import ContaTab from '../ContaTab';
import ObservacoesTab from '../ObservacoesTab';
import CategoriaTab from '../CategoriaTab';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import ContasVinculadasTab from '../ContasVinculadasTab';
import Divisor from '../../../../../../Componentes/Divisor';
import { UseButtonsSubHeader } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';

export interface IContaDetalheRef {
  handleObterValidarDadosConta(): Promise<{
    dados: IContaValoresInserir;
    valido: boolean;
  }>;
}

interface IContaDetalheProps extends IMain<IContaValoresAlterar> {
  onClickVoltar?: () => void;
  exibirBotoes?: boolean;
  bloquearCampos?: boolean;
  bloquearCamposReversao?: boolean;
  isAdiantamentoAntecipacao?: boolean;
  isJanela?: boolean;
}

const Main: React.ForwardRefRenderFunction<
  IContaDetalheRef,
  IContaDetalheProps
> = (
  {
    dadosDuplicados,
    dadosRecuperados,
    idEditar,
    dadosObrigatorios,
    dadosPadrao,
    onClickSalvar,
    onClickVoltar,
    exibirBotoes = true,
    bloquearCampos = false,
    bloquearCamposReversao = false,
    isAdiantamentoAntecipacao = false,
    isJanela = false,
  },
  ref
) => {
  const { permissoes } = UsePermissoes();

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const navigate = useNavigate();
  const rotas = UseRota();
  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleValidar,
  } = UseForm();
  const { setDisableExcluir } = UseButtonsSubHeader();
  const idDetalheRegistro = getIdDetalheRegistro();
  const [bloquearCamposVinculo, setBloquearCamposVinculo] = useState(false);
  const revertida = formRef.current?.getFieldValue('revertida');

  const [tabSelecionada, setTabSelecionada] = useState('conta');

  useEffect(() => {
    setDisableExcluir(revertida);
  }, [revertida, setDisableExcluir]);

  const handleObterValidarDadosConta = useCallback(async (): Promise<{
    dados: IContaValoresInserir;
    valido: boolean;
  }> => {
    const data = formRef.current?.getData() as IContaValoresInserir;
    const valido = await handleValidar(data);

    return { dados: data, valido };
  }, [formRef, handleValidar]);

  useImperativeHandle(ref, () => {
    return {
      handleObterValidarDadosConta,
    };
  });

  useEffect(() => {
    if (idEditar) setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao: {
        ...dadosPadrao,
        isAdiantamentoAntecipacao,
      },
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    idEditar,
    handleCarregarDados,
    isAdiantamentoAntecipacao,
  ]);

  const idOrcamento = formRef.current?.getFieldValue('idOrcamento');
  const idPedidoVenda = formRef.current?.getFieldValue('idPedidoVenda');
  const idNotaFiscalSaida = formRef.current?.getFieldValue('idNotaFiscalSaida');
  const idNotaFiscalEntrada = formRef.current?.getFieldValue(
    'idNotaFiscalEntrada'
  );
  const idConhecimentoTransporteTerceiro = formRef.current?.getFieldValue(
    'idConhecimentoTransporteTerceiro'
  );
  const idConhecimentoTransporte = formRef.current?.getFieldValue(
    'idConhecimentoTransporte'
  );

  useEffect(() => {
    setBloquearCamposVinculo(bloquearCampos);

    if (
      idOrcamento ||
      idPedidoVenda ||
      idNotaFiscalSaida ||
      idNotaFiscalEntrada ||
      idConhecimentoTransporteTerceiro ||
      idConhecimentoTransporte
    ) {
      setBloquearCamposVinculo(true);
    }
  }, [
    bloquearCampos,
    idConhecimentoTransporte,
    idConhecimentoTransporteTerceiro,
    idNotaFiscalEntrada,
    idNotaFiscalSaida,
    idOrcamento,
    idPedidoVenda,
    setDisableExcluir,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <InputHiddenHtml name="idOrcamento" />
      <InputHiddenHtml name="idPedidoVenda" />
      <InputHiddenHtml name="idNotaFiscalSaida" />

      <InputHiddenHtml name="idNotaFiscalEntrada" />
      <InputHiddenHtml name="idConhecimentoTransporteTerceiro" />
      <InputHiddenHtml name="idConhecimentoTransporte" />
      <InputHiddenHtml name="idContaAdiantamento" />
      <InputHiddenHtml name="idConta" />
      <InputHiddenHtml name="listaCategoriaMudou" />
      <InputHiddenHtml name="listaParcelaMudou" />
      <InputHiddenHtml name="isJanela" />

      <InputHiddenHtml name="vendaPdv" />
      <InputHiddenHtml name="situacao" />

      <LoadingDiv isLoading={loading} isToggleJanela={isJanela} />

      <Tabs
        id={v4()}
        defaultActiveKey="conta"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="conta" title="Conta">
          <ContaTab
            isAdiantamentoAntecipacao={isAdiantamentoAntecipacao}
            bloquearCampos={bloquearCamposVinculo}
            bloquearCamposReversao={bloquearCamposReversao}
          />
        </Tab>

        <Tab eventKey="categoria" title="Categoria">
          <CategoriaTab />
        </Tab>

        <Tab eventKey="contasVinculadas" title="Contas Vinculadas">
          <Divisor>
            <ContasVinculadasTab />
          </Divisor>
        </Tab>

        <Tab eventKey="observacoes" title="Observações">
          <ObservacoesTab />
        </Tab>
      </Tabs>

      <BtnContainer style={{ display: exibirBotoes ? '' : 'none' }}>
        <button
          type="button"
          className="btn-padrao btn-cinza-claro"
          onClick={() => {
            if (onClickVoltar) onClickVoltar();
          }}
          style={{ display: onClickVoltar ? '' : 'none' }}
        >
          <IoMdReturnLeft />
          <span style={{ marginLeft: 10 }}>Voltar</span>
        </button>

        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default forwardRef(Main);
