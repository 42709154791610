/* eslint-disable jsx-a11y/label-has-associated-control */
import { UseFieldCia } from '@elogestor/unformcia';
import React, {
  InputHTMLAttributes,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import * as Yup from 'yup';
import { FaSearch } from 'react-icons/fa/index.mjs';
import { AppErro } from '@elogestor/util';
import { DefaultInput, SpanErro } from '../Styles';
import { ContainerBuscaNomeFantasia } from './styles';
import TratarErros from '../../../Util/Erro/TratarErros';
import { UseParametros } from '../../../Hooks/ParametrosHook';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputBuscaNomeFantasiaProps {
  valorAnterior: string;
}

export interface IOnChangeInputBuscaNomeFantasiaValidoEvent {
  valor: string;
}

export interface IOnBlurInputBuscaNomeFantasiaValidoEvent {
  valor: string;
  mudou: boolean;
}

export interface IInputBuscaoNomeFantasiaRef {
  focus(): void;
}

interface IInputBuscaoNomeFantasiaProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  loading?: boolean;
  permitirPesquisar?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputBuscaNomeFantasiaProps
  ) => void | Promise<void>;
  onChangeValido?: (
    event: IOnChangeInputBuscaNomeFantasiaValidoEvent
  ) => Promise<void>;
  onBlurValido?: (
    event: IOnBlurInputBuscaNomeFantasiaValidoEvent
  ) => Promise<void>;
  onPesquisarNomeFantasia?: () => Promise<void>;
}

const InputBuscaNomeFantasia: React.ForwardRefRenderFunction<
  IInputBuscaoNomeFantasiaRef,
  IInputBuscaoNomeFantasiaProps
> = (
  {
    name,
    label,
    loading = false,
    permitirPesquisar = true,

    onFocus,
    onChange,
    onBlur,
    onPesquisarNomeFantasia,
  },
  ref
) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();
  const parametros = UseParametros();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);
  const [desabilitado, setDesabilitado] = useState(true);

  const [isFocused, setIsFocused] = useState(false);
  const valorAnterior = useRef('');

  useEffect(() => {
    valorAnterior.current = inputRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
    if (inputRef.current?.value) {
      setDesabilitado(loading);
    }
  }, [desabilitado, erroUnform, loading]);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,

      getValue() {
        return inputRef.current?.value || '';
      },

      setValue(_, value = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (value) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      setSemExecutarEvento(_, value = '') {
        if (inputRef.current) {
          valorAnterior.current = inputRef.current.value;
          inputRef.current.value = value;
        }

        if (value) {
          setDesabilitado(false);
        } else {
          setDesabilitado(true);
        }
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = '') {
        return this.getValue(_) !== valorInicial;
      },

      setDisabled(valor) {
        if (inputRef.current) {
          inputRef.current.disabled = valor;
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
  }));

  const handleInputFocus = useCallback(
    (event: any) => {
      valorAnterior.current = inputRef.current?.value || '';
      if (onFocus) onFocus(event);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleInputChange = useCallback(
    (event: any) => {
      setErro('');
      const valor = event.target.value;

      if (parametros.CamposLetraMaiuscula) {
        const pontoInicialSelecionado = event.target.selectionStart;
        const pontoFinalSelecionado = event.target.selectionEnd;
        event.target.value = event.target.value.toUpperCase();
        event.target.selectionStart = pontoInicialSelecionado;
        event.target.selectionEnd = pontoFinalSelecionado;
      }

      if (valor) {
        setDesabilitado(false);
      } else {
        setDesabilitado(true);
      }

      if (onChange) onChange(event, { valorAnterior: valorAnterior.current });
    },
    [onChange, parametros.CamposLetraMaiuscula]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsFilled(!!inputRef.current?.value);

      if (onBlur) onBlur(event);
    },
    [onBlur]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      const schema = Yup.object().shape({
        nomeFantasia: Yup.string()
          .nullable()
          .required('Nome Fantasia é obrigatório!')
          .min(
            10,
            'Informe pelo menos 10 caracteres para utilizar a busca por nome fantasia!'
          ),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const erroMinChar = new AppErro({ mensagem: error.message });
      TratarErros(erroMinChar);
      return false;
    }
  }, []);

  return (
    <ContainerBuscaNomeFantasia>
      {label && <label>{label}</label>}
      <div>
        <DefaultInput
          $isErrored={!!erro}
          $isFilled={isFilled}
          $isFocused={isFocused}
          type="text"
          ref={inputRef}
          maxLength={60}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        {permitirPesquisar && (
          <button
            type="button"
            className="btn-editar"
            disabled={desabilitado}
            onClick={async () => {
              const pessoaValida = await handleValidar({
                nomeFantasia: inputRef.current?.value,
              });
              if (!pessoaValida) {
                return;
              }

              if (onPesquisarNomeFantasia) {
                onPesquisarNomeFantasia();
              }
            }}
          >
            <FaSearch />
          </button>
        )}
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </ContainerBuscaNomeFantasia>
  );
};

export default forwardRef(InputBuscaNomeFantasia);
