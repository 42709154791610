import React, { useCallback, useEffect } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputAutoCompleteOperacaoRoteiroFabricacao from '../../../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteOperacaoRoteiroFabricacao';
import InputAutoCompleteCentroTrabalho from '../../../../../../../../Componentes/Inputs/AutoComplete/Manufatura/InputAutoCompleteCentroTrabalho';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';

interface IRoteiroModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const RoteiroDetalhe: React.FC<IRoteiroModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaProducaoOrdemProducao: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleSubmit,
    handleCarregarDados,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataDuplicar();

    data.idCentroTrabalho = data.centroTrabalho ? data.centroTrabalho.id : null;
    data.idOperacaoRoteiroFabricacao = data.operacaoRoteiroFabricacao
      ? data.operacaoRoteiroFabricacao.id
      : null;

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);
  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Itens do Roteiro"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Divisor>
              <InputHiddenHtml name="id" />
              <Row>
                <Col lg={8} md={8}>
                  <InputAutoCompleteOperacaoRoteiroFabricacao
                    ref={inputRefFocus}
                    label="Operação"
                    name="idOperacaoRoteiroFabricacao"
                    nomeObjeto="operacaoRoteiroFabricacao"
                    placeholder="Operação"
                  />
                </Col>
                <Col lg={2} md={2} />
                <Col lg={2} md={2}>
                  <InputInteiro
                    label="Ordem"
                    name="ordem"
                    placeholder="Operação"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={8} lg={6} md={6}>
                  <InputAutoCompleteCentroTrabalho
                    label="Centro de Trabalho"
                    name="idCentroTrabalho"
                    nomeObjeto="centroTrabalho"
                    placeholder="Centro de Trabalho"
                  />
                </Col>
              </Row>
            </Divisor>

            <Divisor>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <Textarea
                    style={{ minHeight: 125 }}
                    label="Observações"
                    name="observacao"
                    maxLength={500}
                  />
                </Col>
              </Row>
            </Divisor>
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde-claro btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default RoteiroDetalhe;
